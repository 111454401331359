import React, { Fragment } from 'react';
import PropTypes, { func, object, string } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';

// import LockOutline from "@material-ui/icons/LockOutline";

import LogoAceleradora from 'assets/img/logo-workalove.svg';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Button from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';

import { FormHelperText, TextField } from '@material-ui/core';
import { Formik, FastField } from 'formik';

import { errors } from 'constants/errors';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import SendEmail from 'views/RecoverPassword/SendEmail';
import AuthContext from 'contexts/authentication';

import mergeContextsHelper from 'utils/mergeContextsHelper';
import { login } from '../../services/login/authenticate';

import CompanyBeginRegistrationForm from './components/CompanyBeginRegistrationForm';
import CustomModalDialog from '../../components/CustomModalDialog/CustomModalDialog';
import { createBrowserHistory } from 'history';
import ModalInformation from '../JobOfferRebuild/InformationModal';
import {
  IModalConfigLogin,
  IModalConfigNegativeLogin,
} from './useCases/IModalConfig';
import ModalFail from './components/informationModal/index.jsx';
import { getUniversityLogos } from '../../services/login/authenticate';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: 'cardHidden', // we use this to make the card to appear after the page has been rendered
      recoverPassVisible: false,
      modalOpen: false,
      modalFailOpenState: false,
      initialValues: {
        username: '',
        password: '',
        university: '',
      },
      registrationValues: {
        email: '',
      },
      logo: '',
    };
  }

  async componentDidMount() {
    const resLogo = await getUniversityLogos(
      this.props.match.params.universidade
    );
    this.setState({
      logo: resLogo.data.logo,
    });

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.props.handleIsLoadingState(false);
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({
          cardAnimaton: '',
          initialValues: {
            ...this.state.initialValues,
            university: this.props.match.params.universidade,
          },
        });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  render() {
    const {
      classes,
      match,
      showRegistrationForm,
      showRecoverPassword,
      handleIsLoadingState,
    } = this.props;
    const { recoverPassVisible, logo } = this.state;
    const { path: loginUrl } = match;
    const history = createBrowserHistory();

    const openModalInformation = () => {
      this.setState({ modalOpen: true });
    };

    const closeModalInformation = () => {
      this.setState({ modalOpen: false });
    };

    const closeModalFail = () => {
      this.setState({ modalFailOpenState: false });
    };

    return (
      <div className={classes.loginContainer}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem
              xs={12}
              sm={6}
              md={4}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <img
                src={this.state.logo}
                onError={(e) => (e.target.src = LogoAceleradora)}
                width="80%"
                height="100%"
              />
              <ModalInformation
                config={IModalConfigLogin}
                modalOpenState={this.state.modalOpen}
                buttonFunction={closeModalInformation}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" style={{ marginTop: 20 }}>
            <AuthContext.Consumer>
              {({ routerOnUserLogin, isInvalidAuthenticationToken }) => {
                return (
                  <Fragment>
                    <GridItem xs={12} sm={6} md={4}>
                      <Formik
                        initialValues={this.state.initialValues}
                        enableReinitialize
                        onSubmit={async (values, actions) => {
                          handleIsLoadingState(true);
                          let loginUser = await login(loginUrl, values);
                          if (loginUser.isLogged) {
                            actions.setSubmitting(false);
                            if (
                              match.params &&
                              match.params.universidade &&
                              match.params.id
                            ) {
                              loginUser.data['params'] = match.params;
                            }
                            routerOnUserLogin(loginUser.data);
                          } else {
                            if (loginUser.data.detail) {
                              this.setState({ modalFailOpenState: true });
                              handleIsLoadingState(false);
                              actions.setSubmitting(false);
                              return;
                            }
                            const message = errors.hasOwnProperty(
                              loginUser.data.code
                            )
                              ? errors[loginUser.data.code]
                              : 'Não foi possível realizar o seu login. Tente novamente';
                            handleIsLoadingState(false);

                            this.props.openSnackbar(message, true);
                          }
                          actions.setSubmitting(false);
                        }}
                        render={({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Card
                              login
                              className={classes[this.state.cardAnimaton]}
                            >
                              <CardBody>
                                <h4 className="form-title">Bem-vindo!</h4>
                                <p className="form-subtitle">
                                  Entre com os seus dados para acessar.
                                </p>
                                <FastField
                                  type="hidden"
                                  id="university"
                                  name="university"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <FastField
                                  autoFocus
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Face
                                          className={classes.inputAdornmentIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  fullWidth
                                  name="username"
                                  id="username"
                                  label="Login"
                                  placeholder="09382118921"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.username && touched.username}
                                  value={values.username}
                                  component={TextField}
                                />
                                {errors.username && touched.username ? (
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.username}
                                  </FormHelperText>
                                ) : null}
                                <br />
                                <br />
                                <FastField
                                  className={classes.inputField}
                                  InputProps={{
                                    className: classes.input,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Icon
                                          className={classes.inputAdornmentIcon}
                                        >
                                          lock_outline
                                        </Icon>
                                      </InputAdornment>
                                    ),
                                  }}
                                  InputLabelProps={{
                                    FormLabelClasses: {
                                      focused: classes.inputField,
                                    },
                                  }}
                                  fullWidth
                                  name="password"
                                  id="password"
                                  label="Senha"
                                  type="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={errors.password && touched.password}
                                  value={values.password}
                                  component={TextField}
                                />
                                {errors.password && touched.password ? (
                                  <FormHelperText
                                    className={classes.helperText}
                                  >
                                    {errors.password}
                                  </FormHelperText>
                                ) : null}
                                {showRecoverPassword && (
                                  <div
                                    className={classes.recoverPassLink}
                                    onClick={() =>
                                      this.setState({
                                        recoverPassVisible: true,
                                      })
                                    }
                                  >
                                    Esqueci minha senha
                                  </div>
                                )}
                              </CardBody>

                              <CardFooter
                                className={classes.justifyContentCenter}
                              >
                                <Button
                                  disabled={isSubmitting}
                                  type="submit"
                                  className={classes.saveButton}
                                  round
                                >
                                  Acessar
                                </Button>
                              </CardFooter>
                            </Card>
                          </form>
                        )}
                      />
                    </GridItem>
                    {showRegistrationForm &&
                      !recoverPassVisible &&
                      mergeContextsHelper(
                        <CompanyBeginRegistrationForm
                          openModalInformation={openModalInformation}
                        />
                      )}
                    <ModalFail
                      modalFailOpenState={this.state.modalFailOpenState}
                      config={IModalConfigNegativeLogin}
                      buttonFunction={closeModalFail}
                    />
                    <CustomModalDialog
                      classes={classes}
                      visible={isInvalidAuthenticationToken}
                    >
                      <p>
                        <strong>Ops!</strong>
                      </p>
                      <p>
                        Infelizmente não identificamos os seus dados no ambiente
                        da Workalove. Entre em contato com a sua{' '}
                        <strong>Instituição de Ensino</strong> e solicite a sua
                        inclusão!
                      </p>
                      <p>Nos vemos em breve! :)</p>
                      <br />
                    </CustomModalDialog>
                  </Fragment>
                );
              }}
            </AuthContext.Consumer>
            {recoverPassVisible &&
              mergeContextsHelper(
                <SendEmail
                  isCompanyConfig={false}
                  isStudentLogin={this.props.isStudentLogin}
                  handleClose={() => {
                    this.setState({ recoverPassVisible: false });
                  }}
                />
              )}
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  showRegistrationForm: PropTypes.bool,
  showRecoverPassword: PropTypes.bool,
  handleIsLoadingState: func,
  match: PropTypes.shape({
    path: PropTypes.oneOfType([object, string]),
  }),
  openSnackbar: func,
  resLogo: PropTypes.string,
};

export default withStyles(loginPageStyle)(LoginPage);
