import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import { GraphicCapsule, TooltipLegend } from '../../Styles/StyleGraphics';
import imageTest from 'assets/Oportutinites/info.svg';
import { useState } from 'react';
import { returnAdapter } from '../../Utils/formatDataGraphics';
import { formatNumber } from 'utils/formatNumber';

export function CustomBarChart({ graphicalData }) {
  const adapterData = returnAdapter(graphicalData);

  const [tooltip, setTooltip] = useState(null);

  const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    const offsets = {
      Aprendizagem: 40,
      Vagas: 20,
      Empresas: 30,
    };

    const defaultOffset = 25;
    const offsetX = offsets[payload.value] || defaultOffset;
    const imageOffsetX = offsetX + 5;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={offsetX}
          y={3}
          dy={15}
          textAnchor="end"
          fill="#666"
          style={{ fontSize: 13, fontWeight: 400 }}
        >
          {payload.value}
        </text>
        <image
          xlinkHref={imageTest}
          x={imageOffsetX}
          y={8}
          height="13px"
          width="13px"
          textAnchor="middle"
          fill="#666"
        />
      </g>
    );
  };

  const tooltipMessages = {
    Vagas: 'Aqui estão os estudantes que candidataram-se a pelo menos uma vaga',
    Mercado:
      'Aqui estão os estudantes que favoritaram ao menos uma atuação de Mercado',
    Mentoria: 'Aqui estão os estudantes que realizaram mentoria',
    Empresas: 'Aqui estão os estudantes que favoritaram empresas',
    Aprendizagem:
      'Aqui estão os estudantes que acessaram pelo menos uma aprendizagem',
  };

  const renderTooltip = () => {
    const message = tooltipMessages[tooltip?.value];
    if (message) {
      return (
        <TooltipLegend coordinate={tooltip?.coordinate}>
          <span>{message}</span>
        </TooltipLegend>
      );
    }
  };

  const CustomTickFormatter = (tick) => {
    const number = formatNumber(tick);
    return Number.isInteger(tick) ? number : '';
  };

  return (
    <GraphicCapsule heightGraphic={317}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={adapterData?.result}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="4" />
          <XAxis
            dataKey="label"
            tick={CustomizedXAxisTick}
            interval={0}
            onMouseEnter={(params) => setTooltip(params)}
            onMouseLeave={() => setTooltip(false)}
            axisLine={false}
            tickLine={false}
            tickFormatter={CustomTickFormatter}
          />
          <YAxis
            style={{ fontWeight: 500 }}
            axisLine={false}
            tickLine={false}
            tickFormatter={CustomTickFormatter}
          />
          <Bar dataKey="value" barSize={67} fill="#009291">
            <LabelList
              dataKey="value"
              fill="#606062"
              position="top"
              style={{ fontWeight: 700, fontSize: 12 }}
              formatter={(value) => formatNumber(value)}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {renderTooltip()}
    </GraphicCapsule>
  );
}
