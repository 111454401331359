import React, { useState, useEffect, useContext } from 'react';
import FilterModal from 'components/FilterModal';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, FilterContainer, FilterDiv } from './styled';
import { mentorshipApi } from '../../services/api';
import AuthContext from 'contexts/authentication';
import * as Yup from 'yup';

export function FilterMentorship({
  onApplyFilters,
  onCleanFilters,
  appliedFilters,
  setAppliedFilters,
  filterState,
  setFilterState,
}) {
  const [filterOptions, setFilterOptions] = useState({});
  const [errorDate, setErrorDate] = useState(false);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = !['teacher', 'course_coordinator'].includes(
    staffRole
  );
  useEffect(() => {
    async function fetchFilterOptions() {
      const response = await mentorshipApi.getFilterOptions();
      setFilterOptions(response);
    }
    fetchFilterOptions();
  }, []);

  async function externalIdLoadOptions(value) {
    const response = await mentorshipApi.getStudentExternalIdOptions(value);
    return (
      response.students_external_ids?.map((value) => {
        return {
          label: value,
          value: value,
        };
      }) || []
    );
  }

  const validateDates = async (data) => {
    try {
      const schema = Yup.object().shape({
        end_date: Yup.date().min(
          Yup.ref('start_date'),
          'A data final precisa ser maior do que a data inicial.'
        ),
      });
      await schema.validate(data, { abortEarly: false });
      setErrorDate(false);
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  const handleApplyFilters = async (filters) => {
    const isValid = await validateDates(filters);
    if (!isValid) {
      return false;
    }
    await onApplyFilters(filters);
  };

  const handleCleanFilters = () => {
    setErrorDate(false);
    onCleanFilters();
  };

  return (
    <FilterContainer>
      <FilterModal
        filterState={filterState}
        setFilterState={setFilterState}
        onApply={handleApplyFilters}
        onClean={handleCleanFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        validateDate={true}
        setErrorDate={setErrorDate}
        errorDate={errorDate}
      >
        <FilterGrid>
          <FilterDiv>
            <Filter
              label="Nome"
              name={'name'}
              placeholder={'Digite o nome do estudante'}
              type={FilterType.TEXT}
            />
            <Filter
              label="Código do estudante"
              name={'external_id'}
              type={FilterType.ASYNC_SINGLE_SELECT}
              settings={{ loadOptions: externalIdLoadOptions }}
            />
          </FilterDiv>
          <Filter
            label="Curso"
            name={'course'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.courses}
          />
          <Filter
            name={['start_date', 'end_date']}
            label={'Data da mentoria'}
            type={FilterType.DATE_RANGE}
            settings={{
              placeholder: ['Data inicial', 'Data final'],
              onChange: () => setErrorDate(false),
            }}
          />
          <Filter
            label="Modalidade"
            name={'mentorship_modality'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.modality}
          />
          {shouldRenderForStaff && (
            <Filter
              label="Mentor"
              name={'mentor'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.mentor}
            />
          )}
        </FilterGrid>
      </FilterModal>
    </FilterContainer>
  );
}
