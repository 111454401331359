import React, { useContext, useState, useEffect } from 'react';
import defaultAvatar from 'assets/img/placeholder.jpg';
import { IconEditImage } from 'assets/img/iconsCurriculum/iconEditImage';
import iconChampionProfile from 'assets/img/iconsCurriculum/iconChampionProfile.svg';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

import {
  ButtonImage,
  ButtonEditIcon,
  ImageIcon,
  ButtonGamification,
  ContainerButtonsProfile,
} from './styled';

const ImageButtonStudent = (props) => {
  const {
    profileData,
    setUserData,
    closeModal,
    setProfileData,
  } = props;
  const {
    gamificationLevel,
    universityColor,
    openModalWithContent,
    handleIsLoadingState,
    fetchGamificationData,
    setStatusIcon,
  } = useContext(ProfileContext);
  const gamificationLevelThree = gamificationLevel === 3;
  const [showDisability, setShowDisability] = useState(false);
  const [showGenderIdentity, setShowGenderIdentity] = useState(false);
  const [showColorOrRace, setShowColorOrRace] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [studentProfileState, setStudentProfileState] = useState({});

  const updateAvatarState = (newAvatar) => {
    setAvatar(newAvatar.avatar);
  };

  const handleShowSpecialNeeds = (value) => {
    setShowDisability(value);
    setShowGenderIdentity(value);
    setShowColorOrRace(value);
  };

  const updateProfileState = (newProfile, profileUpdated) => {
    setStudentProfileState(newProfile);
    setUserData(profileUpdated);

    if (newProfile) {
      const personalData = [
        newProfile.zipCode,
        newProfile.mobilePhone,
        newProfile.name,
        newProfile.genderIdentity,
        newProfile.colorOrRace,
        newProfile.birth_date,
        newProfile.email,
      ];

      const requiredFields = personalData.every((field) => !!field);
      setStatusIcon(requiredFields);
    }
  };

  useEffect(() => {
    const personalData = [
      profileData.zip_code,
      profileData.mobile_phone,
      profileData.name,
      profileData.gender_identity?.value,
      profileData.color_or_race?.value,
      profileData.birth_date,
      profileData.email,
    ];
    const requiredFields = personalData.every((field) => !!field);
    setStatusIcon(requiredFields);
  }, []);

  const onClickEdit = () => {
    openModalWithContent(
      <ProfileForm
        handleShowSpecialNeeds={handleShowSpecialNeeds}
        handleIsLoadingState={handleIsLoadingState}
        universityColor={universityColor}
        updateAvatarState={updateAvatarState}
        updateProfileState={updateProfileState}
        closeModal={closeModal}
        profileData={profileData}
        setProfileData={setProfileData}
        fetchGamificationData={fetchGamificationData}
        {...props}
      />,
      'Edição Dados Pessoais'
    );
  };

  return (
    <ContainerButtonsProfile>
      <ButtonImage
        onClick={() => onClickEdit()}
        levelThree={gamificationLevelThree}
      >
        <ImageIcon
          src={profileData?.avatar || defaultAvatar}
          alt={`${profileData?.name}`}
        />
      </ButtonImage>

      <ButtonEditIcon
        onClick={() => onClickEdit()}
        levelThree={gamificationLevelThree}
      >
        <IconEditImage color={universityColor} />
      </ButtonEditIcon>

      <ButtonGamification levelThree={gamificationLevelThree}>
        <img src={iconChampionProfile} />
      </ButtonGamification>
    </ContainerButtonsProfile>
  );
};

export default ImageButtonStudent;
