import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const getValidationSchema = (eventStartDate, eventEndDate) => {
  return Yup.object().shape({
    registration_start_date: Yup.date()
      .typeError('Data inválida')
      .required('Campo obrigatório')
      .min(today, 'A data deve ser igual ou superior à data atual.')
      .max(
        eventStartDate,
        'A data deve ser inferior ou igual à data inicial do evento.'
      ),
    registration_end_date: Yup.date()
      .required('Campo obrigatório')
      .typeError('Data inválida')
      .min(
        Yup.ref('registration_start_date'),
        'A data de término deve ser maior ou igual à data de início.'
      )
      .max(
        eventEndDate,
        'A data de término deve ser inferior ou igual à data de término do evento.'
      ),
  });
};
