import { getFairAndEventsListings } from 'views/Student/Learning/Services';
import { DelaySearchHelper } from '../Helpers/DelaySearchHelper';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';

export default function FilterHandlers(visualizationContextInstance) {
  const handleChangeSearchParam = (searchParam) => {
    const {
      currentTab,
      paginationObject,
      getFavoritedAvaibles,
      typeFinish,
      queryFilterMounted,
    } = visualizationContextInstance;

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;
    const favorited = getFavoritedAvaibles;

    const getWithParam = async () => {
      const response = await getFairAndEventsListings(
        type,
        searchParam,
        favorited,
        typeFinish,
        queryFilterMounted
      );
      setter({ value: response });
    };

    DelaySearchHelper.delay(getWithParam, 500);
  };

  const handleChangeFinishedInsideFilter = async (typeFinish) => {
    const {
      setTypeFinish,
      currentTab,
      paginationObject,
    } = visualizationContextInstance;

    setTypeFinish(typeFinish);

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const searchParam = '';
    const query = '';

    const response = await getFairAndEventsListings(
      type,
      searchParam,
      _,
      typeFinish,
      query
    );

    setter({ value: response });
  };

  const handleChangeFavoritedInsideFilter = async (favorited) => {
    const {
      currentTab,
      paginationObject,
      setGetFavoritedAvaiables,
    } = visualizationContextInstance;

    setGetFavoritedAvaiables(favorited);

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const searchParam = '';
    const query = '';

    const response = await getFairAndEventsListings(
      type,
      searchParam,
      favorited,
      _,
      query
    );

    setter({ value: response });
  };

  const onApplyFilters = async (objectFilter) => {
    const { paginationObject, currentTab } = visualizationContextInstance;

    /**
     * @type {Object} currentPagination
     * @property {string} next
     * @property {string} type
     * @property {function} setter
     *
     */

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const createFilterObjectInstance = new createFilterObject();

    const [query] = createFilterObjectInstance.convert(objectFilter);

    const response = await getFairAndEventsListings(type, _, _, _, query);

    setter((prevs) => ({
      value: {
        ...response,
        results: response.results,
      },
    }));
  };
  const onClearfilter = async () => {
    const { paginationObject, currentTab } = visualizationContextInstance;

    /**
     * @type {Object} currentPagination
     * @property {string} next
     * @property {string} type
     * @property {function} setter
     */
    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const response = await getFairAndEventsListings(type, _, _, _, _);

    setter((prevs) => ({
      value: {
        ...response,
        results: response.results,
      },
    }));
  };

  return {
    handleChangeSearchParam,
    handleChangeFinishedInsideFilter,
    handleChangeFavoritedInsideFilter,
    onApplyFilters,
    onClearfilter,
  };
}
