import React, { Fragment, useEffect } from 'react';
import Challenges from './Challenges';

const ChallengesViews = (props) => {
  const { handleIsLoadingState } = props;

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <Fragment>
      <Challenges />
    </Fragment>
  );
};

export default ChallengesViews;
