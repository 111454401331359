import {
  createNewReport,
  reportAlreadyExists,
} from 'services/university/socioeconomicReport';
import { SocioeconomicReportAdapter } from 'adapters/university/socioeconomicReportAdapter';

import React, { useEffect, useState } from 'react';
import { shape, func } from 'prop-types';
import { Formik, Field } from 'formik';

import Button from 'components/CustomButtons/Button';

import * as Yup from 'yup';
import QuestionGenerator from './QuestionGenerator';
import {
  Container,
  InfoHeader,
  CustomTextField as TextField,
  CustomFormHelperText as FormHelperText,
  InputField,
  InputWithError,
  Searchable,
} from './styles';
import ReportAlreadyExistModal from './ReportAlreadyExistModal';

// VALIDATION SCHEMA LOGIC

const CustomFormSchema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  level: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, 'Você deve selecionar pelo menos 1 opção'),
  macroArea: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, 'Você deve selecionar pelo menos 1 opção'),
  courses: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.number(),
        macroarea: Yup.string(),
      })
    )
    .min(1, 'Você deve selecionar pelo menos 1 opção'),
  status: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, 'Você deve selecionar pelo menos 1 opção'),
  term: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.number(),
      })
    )
    .min(1, 'Você deve selecionar pelo menos 1 opção'),
  questions: Yup.array().of(
    Yup.object().shape({
      type: Yup.string(),
      title: Yup.string().required('Você deve digitar a questão'),
      options: Yup.array()
        .of(Yup.string().required('Você deve digitar a alternativa'))
        .test('Unique', 'As alternativas não podem ter respostas iguais', (values) => {
          return new Set(values).size === values.length;
        }),
    })
  ),
});

const ReportRegisterForm = ({
  targetOptions,
  handleIsLoadingState,
  openSnackbar,
  setView,
}) => {
  const [reportExistTitle, setReportExistTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isExist, setIsExist] = useState(true);

  const {
    course_levels: levels,
    macroareas: macroAreas,
    courses,
    student_statuses: status,
    semesters: terms,
  } = targetOptions;

  const indiferentOptions = { label: 'Indiferente', value: undefined };

  // Status control
  const [statusOptions, setStatusOptions] = useState([
    indiferentOptions,
    ...status,
  ]);

  // Semesters control
  const [termsOptions, setTermsOptions] = useState([
    indiferentOptions,
    ...terms,
  ]);

  // Course Levels control
  const [levelsInd, setLevelsInd] = useState([indiferentOptions, ...levels]);
  const [chosenLevels, setChosenLevels] = useState([]);

  // Macroarea control
  const macroareasInd = [indiferentOptions, ...macroAreas];
  const [macroAreaOptions, setMacroAreaOptions] = useState(macroareasInd);
  const [chosenMacroareas, setChosenMacroareas] = useState([]);
  const [matchedMacroareas, setMatchedMacroareas] = useState([]);

  // Courses control
  const coursesInd = [indiferentOptions, ...courses];
  const [coursesOptions, setCoursesOptions] = useState(coursesInd);
  const [matchedCourses, setMatchedCourses] = useState([]);

  // Handle Form Fields Filled State
  const [targetAudienceFieldFilled, setTargetAudienceFieldFilled] = useState({
    levels: false,
    status: false,
    macroareas: false,
    semesters: false,
    courses: false,
  });

  const initialValues = {
    name: '',
    level: [],
    macroArea: [],
    courses: [],
    status: [],
    term: [],
    questions: [
      {
        type: 'checkbox',
        title: '',
        options: ['', ''],
      },
    ],
  };

  const handleMacroArea = (courseLevels) => {
    const matchMacro = macroAreas.filter((macroarea) =>
      courseLevels.some((level) =>
        macroarea.course_levels.find((obj) => obj === level.value)
      )
    );

    if (courseLevels.length > 0 && courseLevels[0].label === 'Indiferente') {
      setMacroAreaOptions(macroareasInd);
      setMatchedMacroareas(macroAreas);
    } else if (courseLevels.length > 0) {
      setMatchedMacroareas(matchMacro);
      setMacroAreaOptions([indiferentOptions, ...matchMacro]);
    } else {
      setMacroAreaOptions(macroAreas);
      setMatchedMacroareas(macroAreas);
    }
  };

  const handleCourses = (macroareaValue) => {
    const matchMacroCourses = courses.filter((course) =>
      macroareaValue.some((macroarea) => macroarea.value === course.macroarea)
    );

    const matchCourses = matchMacroCourses.filter((course) =>
      chosenLevels.some((level) => level.value === course.course_level)
    );

    if (
      macroareaValue.length > 0 &&
      macroareaValue[0].label === 'Indiferente'
    ) {
      setMatchedCourses(courses);
      setCoursesOptions(coursesInd);
    } else if (macroareaValue.length > 0) {
      setMatchedCourses(matchCourses);
      setCoursesOptions([indiferentOptions, ...matchCourses]);
    } else {
      setCoursesOptions(coursesInd);
      setMatchedCourses(courses);
    }
  };

  const handleCheckExistReports = async (values) => {
    const allNotFilled = Object.keys(targetAudienceFieldFilled).some(
      (field) => targetAudienceFieldFilled[field] === false
    );

    if (!allNotFilled) {
      const response = await reportAlreadyExists(
        SocioeconomicReportAdapter(values)
      );

      setIsExist(response.exists);

      if (response.exists && response.report_title) {
        setReportExistTitle(response.report_title);
        setIsOpen(true);
      }
    }
  };

  const handleTargetAudienceFieldsFilled = (value, field) => {
    if (value.length > 0) {
      setTargetAudienceFieldFilled((prevValues) => ({
        ...prevValues,
        [field]: true,
      }));
    } else {
      setTargetAudienceFieldFilled((prevValues) => ({
        ...prevValues,
        [field]: false,
      }));
    }
  };

  const setClose = () => {
    setIsOpen(false);
  };

  function handleIndiferentChoice(valueArr = []) {
    const hasIndiferent = valueArr.find((item) => item.label === 'Indiferente');

    if (hasIndiferent) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (chosenMacroareas.length > 0) handleCourses(chosenMacroareas);
  }, [chosenMacroareas]);

  useEffect(() => {
    if (chosenLevels.length > 0) handleMacroArea(chosenLevels);
  }, [chosenLevels]);

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={CustomFormSchema}
      onSubmit={async (values, actions) => {
        actions.setSubmitting(true);
        handleIsLoadingState(true);

        const newReport = await createNewReport(
          SocioeconomicReportAdapter(values)
        );

        if (
          newReport.message &&
          newReport.response.data[0] ===
            'O público-alvo já existe em outro relatório socioeconômico'
        ) {
          openSnackbar(
            `O público alvo já existe em outro relatório socioeconômico ativo : ${reportExistTitle}`,
            true
          );
        } else if (newReport.message) {
          openSnackbar(
            `Erro ao cadastrar novo formulário, se persistir, entrar em contato com o suporte`,
            true
          );
        }

        setView(false);
        handleIsLoadingState(false);
      }}
      render={({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Container>
          <InfoHeader>
            <p>
              Este relatório será apresentado para todos alunos relacionados ao
              público alvo no momento do seu primeiro acesso à plataforma.
            </p>
            <p>
              Alunos que já realizaram esse primeiro acesso não responderão ao
              questionário
            </p>
          </InfoHeader>
          <h1>Nome do Relatório</h1>
          <InputWithError>
            <Field
              variant="filled"
              name="name"
              id="name"
              placeholder="Socioeconômico - Graduação"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.name && touched.name}
              value={values.name}
              component={TextField}
              autoFocus
              required
            />
            {errors.name && touched.name ? (
              <FormHelperText style={{ color: 'red' }}>
                {errors.name}
              </FormHelperText>
            ) : null}
          </InputWithError>
          <h1>Público Alvo</h1>
          <InputField>
            <InputWithError>
              <Searchable
                name="level"
                id="level"
                value={values.level}
                isClearable
                isMulti
                InputLabel={{
                  shrink: true,
                }}
                error={errors.level && touched.level}
                placeholder="Nível do Curso"
                isSearchable
                rows={3}
                classNamePrefix="react-select"
                onBlur={() => {
                  handleBlur();
                  handleCheckExistReports(values);
                }}
                onChange={(value) => {
                  const isIndiferent = handleIndiferentChoice(value);
                  handleTargetAudienceFieldsFilled(value, 'levels');
                  if (value.length > 0 && isIndiferent) {
                    setLevelsInd([]);
                    setFieldValue('level', [indiferentOptions]);
                    setChosenLevels(levels);
                  } else {
                    setLevelsInd([indiferentOptions, ...levels]);
                    setFieldValue('level', value);
                    setChosenLevels(value);
                  }
                }}
                options={levelsInd}
              />
              {errors.level && touched.level ? (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.level}
                </FormHelperText>
              ) : null}
            </InputWithError>
            <InputWithError>
              <Searchable
                variant="filled"
                name="macroArea"
                id="macroArea"
                isMulti
                InputLabel={{
                  shrink: true,
                }}
                placeholder="Macro Área"
                onChange={(value) => {
                  const isIndiferent = handleIndiferentChoice(value);
                  handleTargetAudienceFieldsFilled(value, 'macroareas');
                  if (value.length > 0 && isIndiferent) {
                    setChosenMacroareas(macroAreaOptions);
                    setMacroAreaOptions([]);
                    setFieldValue('macroArea', [indiferentOptions]);
                  } else {
                    setMacroAreaOptions([
                      indiferentOptions,
                      ...matchedMacroareas,
                    ]);
                    setFieldValue('macroArea', value);
                    setChosenMacroareas(value);
                  }
                }}
                onBlur={() => {
                  handleBlur();
                  handleCheckExistReports(values);
                }}
                error={errors.macroArea && touched.macroArea}
                component={Searchable}
                options={macroAreaOptions}
                value={values.macroArea}
                autoFocus
                required
              />
              {errors.macroArea && touched.macroArea ? (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.macroArea}
                </FormHelperText>
              ) : null}
            </InputWithError>
          </InputField>
          <InputField>
            <InputWithError>
              <Searchable
                variant="filled"
                name="courses"
                id="courses"
                label="courses"
                isMulti
                InputLabel={{
                  shrink: true,
                }}
                placeholder="Cursos"
                onChange={(value) => {
                  const isIndiferent = handleIndiferentChoice(value);
                  handleTargetAudienceFieldsFilled(value, 'courses');
                  if (value.length > 0 && isIndiferent) {
                    setCoursesOptions([]);
                    setFieldValue('courses', [indiferentOptions]);
                  } else {
                    setCoursesOptions([indiferentOptions, ...matchedCourses]);
                    setFieldValue('courses', value);
                  }
                }}
                onBlur={() => {
                  handleBlur();
                  handleCheckExistReports(values);
                }}
                error={errors.courses && touched.courses}
                value={values.courses}
                component={Searchable}
                autoFocus
                required
                options={coursesOptions}
              />
              {errors.courses && touched.courses ? (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.courses}
                </FormHelperText>
              ) : null}
            </InputWithError>
          </InputField>

          <InputField>
            <InputWithError>
              <Searchable
                name="status"
                id="status"
                value={values.status}
                isClearable
                isMulti
                InputLabel={{
                  shrink: true,
                }}
                placeholder="Status do Aluno"
                isSearchable
                rows={3}
                classNamePrefix="react-select"
                onBlur={() => {
                  handleBlur();
                  handleCheckExistReports(values);
                }}
                error={errors.status && touched.status}
                onChange={(value) => {
                  const isIndiferent = handleIndiferentChoice(value);
                  handleTargetAudienceFieldsFilled(value, 'status');
                  if (value.length > 0 && isIndiferent) {
                    setStatusOptions([]);
                    setFieldValue('status', [indiferentOptions]);
                  } else {
                    setStatusOptions([indiferentOptions, ...status]);
                    setFieldValue('status', value);
                  }
                }}
                options={statusOptions}
              />
              {errors.status && touched.status ? (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.status}
                </FormHelperText>
              ) : null}
            </InputWithError>
            <InputWithError>
              <Searchable
                name="term"
                id="term"
                value={values.term}
                isClearable
                isMulti
                InputLabel={{
                  shrink: true,
                }}
                placeholder="Período"
                isSearchable
                rows={3}
                classNamePrefix="react-select"
                onBlur={() => {
                  handleBlur();
                  handleCheckExistReports(values);
                }}
                onChange={(value) => {
                  const isIndiferent = handleIndiferentChoice(value);
                  handleTargetAudienceFieldsFilled(value, 'semesters');
                  if (value.length > 0 && isIndiferent) {
                    setTermsOptions([]);
                    setFieldValue('term', [indiferentOptions]);
                  } else {
                    setTermsOptions([indiferentOptions, ...terms]);
                    setFieldValue('term', value);
                  }
                }}
                options={termsOptions}
                error={errors.term && touched.term}
              />
              {errors.term && touched.term ? (
                <FormHelperText style={{ color: 'red' }}>
                  {errors.term}
                </FormHelperText>
              ) : null}
            </InputWithError>
          </InputField>
          <h1>Questionário</h1>
          <QuestionGenerator
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            setFieldValue={setFieldValue}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
          />
          <InputField>
            <Button
              disabled={isSubmitting || isExist}
              onClick={handleSubmit}
              type="submit"
              round
              style={{ maxWidth: 300 }}
            >
              Publicar
            </Button>
          </InputField>
          <ReportAlreadyExistModal
            reportTitle={reportExistTitle}
            isOpen={isOpen}
            setClose={setClose}
          />
        </Container>
      )}
    />
  );
};

ReportRegisterForm.propTypes = {
  targetOptions: shape().isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  setView: func.isRequired,
};

export default ReportRegisterForm;
