import { DelaySearchHelper } from '../Helpers/DelaySearchHelper';
import FairAndEventsService from '../Services';

export const VisualizationHandlers = {
  handleClickOpenEditEvent: async (useRegisterModalInstance, eventId) => {
    const { setModalOpen, updateObjectStepsData } = useRegisterModalInstance;

    const detailEvent = await FairAndEventsService.getEventDetail(eventId);

    updateObjectStepsData({ ...detailEvent, edit: true, id: eventId });
    setModalOpen(true);
  },

  handleClickDetails: async (useVisualizationContextInstance, eventId) => {
    const {
      setDetailsModalOpen,
      setDetailsObject,
    } = useVisualizationContextInstance;

    const detailEvent = await FairAndEventsService.getEventDetail(eventId);

    setDetailsObject(detailEvent);
    setDetailsModalOpen(true);
  },
  handleChangeSearchParam: (visualizationContextInstance, searchParam) => {
    const { currentTab, paginationObject } = visualizationContextInstance;

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getWithParam = async () => {
      const response = await getFairAndEventsListings(
        type,
        searchParam,
        signal
      );
      setter({ value: response });
      return;
    };

    DelaySearchHelper.delay(getWithParam, 500, signal);
  },

  handleChangeFinishedType: async (visualizationContextInstance, type) => {
    const { paginationObject, setFinishedType } = visualizationContextInstance;
    const finishedTabNumber = 3;
    const { setter } = paginationObject[finishedTabNumber];
    setFinishedType(type);
    const response = await FairAndEventsService.getListings(
      'finished',
      _,
      type
    );

    setter({ value: response });
  },
};
