import { QrCodeScan } from 'components/QrCodeScan';
import { Container, ContainerInformation } from './styled';
import StudentCheckinCard from '../CheckIn/StudentCheckinCard';
import { useEffect, useRef, useState } from 'react';
import FairAndEventsService from '../../Services';
import Button from '../Button';
import ReactHtmlParser from 'react-html-parser';
import { getCheckInConfigErrors } from '../../StaticOptions/CheckInConfirmationQrCode';
import ModalInformation from 'components/informationModal';

import Tip from 'assets/FairAndEvents/Tip.svg';
import Exclamation from 'assets/FairAndEvents/Exclamation.svg';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';

export default function ScanQrCodeModal({ id, uuid }) {
  const visualizationStudentInstance = useVisualizationContext();

  const { detailsObject } = visualizationStudentInstance;

  const [seeStudentCard, setSeeStudentCard] = useState(false);
  const [studentData, setStudentData] = useState({});
  const [startCamera, setStartCamera] = useState(false);
  const [cameraStatus, setCameraStatus] = useState('denied');
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const [config, setConfig] = useState({});

  const refIds = useRef({});

  const handleResponseScenarios = (response) => {
    if (!response.student) {
      const configObject = getCheckInConfigErrors();
      const currentConfig = configObject['notFound'];
      setConfig(currentConfig);
      setModalInformationOpen(true);
      return false;
    }

    if (response.presence) {
      const studentName = response?.student?.name;
      const configObject = getCheckInConfigErrors(studentName);
      const currentConfig = configObject['hasPresenceConfirmed'];
      setConfig(currentConfig);
      setModalInformationOpen(true);
      return false;
    }

    if (response.event.id !== detailsObject.id) {
      const eventName = detailsObject.name;
      const configObject = getCheckInConfigErrors(null, eventName);
      const currentConfig = configObject['DiferenceQrEvent'];
      setConfig(currentConfig);
      setModalInformationOpen(true);
      return false;
    }

    return true;
  };

  const getDetail = async (eventId, registerId) => {
    const response = await FairAndEventsService.getRegisterStudentEventDetail(
      eventId,
      registerId
    );

    if (!response.student || response.status === 404) {
      const configObject = getCheckInConfigErrors();
      const currentConfig = configObject['notFound'];
      return setConfig(currentConfig);
    }

    const continueProcess = handleResponseScenarios(response);

    if (!continueProcess) {
      return;
    }

    setStudentData(response);
    setSeeStudentCard(true);
  };

  const getDetailAfterConfirm = async (eventId, registerId) => {
    const response = await FairAndEventsService.getRegisterStudentEventDetail(
      eventId,
      registerId
    );

    setStudentData(response);
  };

  useEffect(() => {
    if (id && uuid) {
      refIds.current = {
        registerId: uuid,
        eventId: id,
      };
      getDetail(id, uuid);
    }
  }, [id, uuid, detailsObject]);

  const checkCameraAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStatus('granted');
    } catch (error) {
      if (error.name === 'NotAllowedError') {
        setCameraStatus('denied');
        return;
      }
      if (error.name === 'NotFoundError') {
        setCameraStatus('notAvailable');
        return;
      }
      setCameraStatus('notAvailable');
    }
  };

  useEffect(() => {
    checkCameraAccess();
  }, []);

  const status = {
    granted: {
      message:
        'Para confirmar a presença do estudante, aponte a câmera para o QR Code fornecido por ele.',
      disabled: false,
      icon: Tip,
    },
    denied: {
      message:
        '<p><strong>Atenção: </strong>  Para iniciar a câmera, você deve ajustar as preferências do seu navegador autorizando o uso da mesma.</p>',
      disabled: true,
      icon: Exclamation,
    },
    notAvailable: {
      message:
        '<p><strong>Atenção: </strong>  O seu dispositivo não possui nenhuma câmera vinculada.</p>',
      disabled: true,
      icon: Exclamation,
    },
  };

  if (seeStudentCard) {
    return (
      <Container>
        <StudentCheckinCard
          student={studentData}
          getDetail={getDetailAfterConfirm}
          refIds={refIds}
        />
      </Container>
    );
  }

  const handleScan = (data) => {
    const splitData = data.text.split('/');
    const registerId = splitData[splitData.length - 1];
    const eventId = splitData[splitData.length - 2];
    refIds.current = {
      registerId,
      eventId,
    };
    getDetail(eventId, registerId);
    setStartCamera(false);
  };

  const toggleCamera = () => {
    setStartCamera(!startCamera);
  };

  const labelButton = startCamera ? 'Parar câmera' : 'Iniciar câmera';

  return (
    <Container>
      <ModalInformation
        config={config}
        modalOpenState={modalInformationOpen}
        buttonConfirmFunction={() => setModalInformationOpen(false)}
      />
      <ContainerInformation>
        <span>
          <img src={status[cameraStatus].icon} />{' '}
          {ReactHtmlParser(status[cameraStatus].message)}
        </span>

        <Button
          onClick={toggleCamera}
          theme="dark"
          disabled={status[cameraStatus].disabled}
        >
          {labelButton}
        </Button>
      </ContainerInformation>

      {startCamera && (
        <QrCodeScan onScan={handleScan} style={{ width: '100%' }} />
      )}
    </Container>
  );
}
