export function BookIcon({ color = '#009291', size = '15px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8712 0.877391C13.6747 0.712733 13.4444 0.593477 13.1965 0.528135C12.9486 0.462794 12.6894 0.452978 12.4373 0.499391L8.83233 1.15447C8.31646 1.24749 7.84705 1.51179 7.5 1.90464C7.15321 1.51114 6.68377 1.24621 6.16767 1.15272L2.56267 0.497641C2.31053 0.451856 2.05142 0.462003 1.80364 0.527366C1.55585 0.592728 1.32545 0.711711 1.12871 0.875905C0.931962 1.0401 0.773678 1.2455 0.66504 1.47759C0.556403 1.70968 0.500062 1.9628 0.5 2.21906L0.5 12.0389L7.5 13.3123L14.5 12.0389V2.21906C14.5 1.96299 14.4437 1.71005 14.335 1.47818C14.2264 1.24631 14.068 1.0412 13.8712 0.877391ZM6.91667 12.0191L1.66667 11.0659V2.21906C1.66674 2.13365 1.68556 2.0493 1.72181 1.97197C1.75806 1.89464 1.81085 1.82621 1.87645 1.77152C1.94204 1.71682 2.01885 1.6772 2.10144 1.65545C2.18403 1.6337 2.27039 1.63035 2.35442 1.64564L5.95883 2.30072C6.22765 2.34963 6.47077 2.49134 6.64581 2.70113C6.82085 2.91092 6.91671 3.1755 6.91667 3.44872V12.0191ZM13.3333 11.0647L8.08333 12.0191V3.44872C8.08329 3.1755 8.17915 2.91092 8.35419 2.70113C8.52923 2.49134 8.77235 2.34963 9.04117 2.30072L12.6456 1.64564C12.7296 1.63035 12.816 1.6337 12.8986 1.65545C12.9811 1.6772 13.058 1.71682 13.1236 1.77152C13.1891 1.82621 13.2419 1.89464 13.2782 1.97197C13.3144 2.0493 13.3333 2.13365 13.3333 2.21906V11.0647Z"
        fill={color}
      />
    </svg>
  );
}
