import styled from 'styled-components';
import ModalIconPerson from './components/Modals/ModalIconPerson';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';
import { Elipsis } from 'components/LoadingUpdate/LoadingUptStyles';

export const FilterModal = styled(ModalIconPerson)`
  p.message {
    font-weight: 400;
    font-size: 20px;
    color: #606062;
    margin-bottom: 24px;
  }

  .bubble {
    span {
      font-weight: 400;
      margin-bottom: 0px;
      padding: 0px;
    }
  }

  .inputContainer {
    margin-bottom: 24px;
    label {
      font-size: 1.125rem;
      font-weight: 700;
      color: #606062;
    }
    & > div {
      margin-top: 4px;
    }
    input::placeholder {
      font-weight: 400;
      font-size: 16px;
    }
    input {
      border-color: ${(props) => (props.error ? '#f10909 !important' : '')};
    }
    .error {
      color: #f10909;
      font-size: 12px;
      line-height: 12px;
      margin-left: 8px;
    }
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
    button {
      color: #fff !important;
    }
  }
  .actionButton {
    span {
      color: #ffffff !important;
      font-size: 18px;
      text-align: center;
      padding: 0px;
    }
  }
`;

export const Loading = styled(LoadingUpdate)`
  &${Elipsis} {
    .lds-ellipsis {
      display: flex !important;
      justify-content: center;
      position: relative;
      width: auto;
      height: auto !important;
      gap: 8px;
      padding: 1.5px 2.5px;
    }
    .lds-ellipsis div {
      position: unset;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background: #fff !important;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 0px !important;
      animation: lds-ellipsis1 0.6s infinite;
      margin-right: 0px;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 0px !important;
      animation: lds-ellipsis2 0.6s infinite;
      margin-left: -12px;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 24px;
      animation: lds-ellipsis2 0.6s infinite;
      margin-left: 0px;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 48px;
      animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(12px, 0);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
  }
`;
