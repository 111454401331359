import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';
import { getInternshipAuthData } from 'services/internship';

const dataProductsAPI = axios.create({
  baseURL: URLS.DATA_PRODUCTS_URL,
});

dataProductsAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    Authorization: `Bearer ${getCookieValue(
      'dataproductstoken',
      document.cookie
    )}`,
    ...config.headers,
  };
  return config;
});

dataProductsAPI.interceptors.response.use(undefined, async (error) => {
  if (error.config && error.response && error.response.status === 401) {
    await login();
    const token = getCookieValue('dataproductstoken', document.cookie);
    if (!token) {
      return Promise.reject(error);
    }
    error.config.headers.Authorization = `Bearer ${token}`;
    return dataProductsAPI.request(error.config);
  }
  return Promise.reject(error);
});

async function getUserToken() {
  const response = await getInternshipAuthData();
  const { token } = response;
  return token;
}

function clearLocalToken() {
  document.cookie =
    'dataproductstoken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
}

async function login() {
  clearLocalToken();
  const userToken = await getUserToken();
  const response = dataProductsAPI
    .post('/login/', {
      token: userToken,
    })
    .then((res) => {
      const token = getCookieValue('dataproductstoken', document.cookie);
      if (token === undefined) {
        document.cookie = `dataproductstoken=${res.data.result.token}`;
      }
      return res.data;
    });
  return response;
}

export default dataProductsAPI;
