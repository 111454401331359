import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const WrapperLink = styled.div`
  width: 100%;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.31px;
  margin-top: 10px;
  color: #606062;
`;

export const WrapperForm = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.31px;
  width: 100%;
  margin: 40px 0;

  > p {
    margin: 5px 0;
  }
`;
export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const SubmitButton = styled.button`
  width: 90px;
  height: 44px;
  padding: 10px 20px 10px 20px;
  gap: 10px;
  border-radius: 3px;
  opacity: 0px;
  background-color: #009291;
  border: none;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.88px;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;
