import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';
import BaseLayoutContext from 'contexts/base-layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 500px;
  min-height: 175px;
  padding: 32px;

  background-color: #eeeeee;

  header {
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    margin: 24px 0px 24px 0px;
  }

  p {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 500;
    text-align: center;
  }

  button {
    border-style: none;
    border-radius: 16px;
    padding: 6px 12px 6px 12px;
    margin-top: 16px;

    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};

    color: white;
    font-weight: 500;

    font-size: 16px;

    transition: opacity 0.4s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
`;

export default function FavoritesDialog({ dialogIsOpen, handleDialog }) {
  const { universityColor } = useContext(BaseLayoutContext);
  return (
    <Dialog open={dialogIsOpen} disableBackdropClick>
      <Container universityColor={universityColor}>
        <p>
          Você já possui 3 atuações como Favoritadas, para atualizar é
          necessário desmarcar alguma atuação favoritada anteriormente! :)
        </p>
        <button type="button" onClick={() => handleDialog(false)}>
          OK, ENTENDI!
        </button>
      </Container>
    </Dialog>
  );
}
