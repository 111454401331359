import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext.js';
import { renderItemsAccordion } from 'views/StaffPages/CompaniesManagement/Utils/renderItemsAccordion.js';
import { AccordionContent } from '../../../UIComponents/AccordionContent';
import AccordionSkelleton from '../../../UIComponents/AccordionSkelleton';
import GenericAccordion from '../../../UIComponents/GenericAccordion';
import { useEffect, useContext, useState, Fragment } from 'react';
import Pagination from 'components/CustomPagination';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';
import { WhiteBox } from '../../../Styles/BaseComponent';
import GenericMessage from 'components/GenericMessage';
import {
  getInviteAnsweredFilter,
  postDocumentOneCreateFile,
  postDocumentTwoCreateFile,
  deleteDocument,
} from 'views/StaffPages/CompaniesManagement/Services';
import ModalUploadEvidences from 'components/UploadEvidences';
import DocumentsUploadModal from '../../../UIComponents/Modals/DocumentsUploadModal';

export default function AcceptedInvationsTab({
  setOpenModalConnectedCompanies,
  activeInternalTab,
  setFilterOptions,
  queryFilter,
  setLoading,
  setDetails,
  documents,
  setDocuments,
  setTab,
  scrollToTopDiv,
  getCountTabs,
}) {
  const {
    returnStatus,
    returnIcons,
    returnIconSessions,
  } = renderItemsAccordion(false);

  const {
    inviteAnswered,
    setInviteAnswered,
    inviteAnsweredCount,
    usingModalUpload,
    setUsingModalUpload,
    idPostDocument,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
  } = useContext(CompaniesManagementContext);

  const { getInviteAnswered } = useAPICalls();
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  useEffect(() => {
    if (activeInternalTab === 2) {
      setTab('answered');
      setLoading(true);
      getCountTabs();
      setInviteAnswered(null);

      getInviteAnsweredFilter().then((data) => {
        setFilterOptions(data);
        setLoading(false);
      });
    }
  }, [activeInternalTab]);

  const handlePageChange = async (page) => {
    if (queryFilter === null) {
      const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
      setInviteAnswered(null);
      await getInviteAnswered(newPage);
    }

    if (queryFilter !== null) {
      const query = `${queryFilter}&limit=${limit}&offset=${
        (page - 1) * limit || 0
      }`;
      setInviteAnswered(null);
      await getInviteAnswered(query);
    }
  };

  const onPageChange = (page) => {
    scrollToTopDiv();
    setCurrentPage(page);
    handlePageChange(page);
  };

  const refreshLimit = (page) => {
    handlePageChange(page);
  };

  useEffect(() => {
    refreshLimit(currentPage);
  }, [limit]);

  const renderItems = () => {
    if (inviteAnswered?.length === 0) {
      return (
        <GenericMessage
          title="Nenhuma empresa encontrada"
          subtitle="
            Clique no botão ”convidar empresa”
            para enviar um convite de conexão.
          "
        />
      );
    }

    if (inviteAnswered === null) {
      return Array(8)
        .fill(1)
        .map((_, index) => <AccordionSkelleton />);
    }

    if (inviteAnswered?.length > 0) {
      return inviteAnswered?.map((companies) => (
        <GenericAccordion
          companyId={companies.id}
          data={companies}
          greenRightButton={false}
          grayRightButton={false}
          connectionStatus={true}
          checkbox={false}
          flagStatus={true}
          textStatus={returnStatus}
          iconStatus={returnIcons}
          colorStatus={companies?.agreement_status}
          textTitle={companies?.legal_name || 'Não informado'}
          textSubtitle={`${companies?.organization_number_type}:`}
          contentSubtitle={companies?.organization_number}
          iconSession={() => returnIconSessions(companies, false)}
          secondCTA={true}
          labelSecondCTA={
            companies.documents_quantity === 0
              ? 'Adicionar documentos de estágio'
              : `Visualizar documentos de estágio (${companies.documents_quantity})`
          }
          setDocuments={setDocuments}
        >
          <AccordionContent
            companies={companies}
            setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
            buttonCompanyProfileDown={true}
            responsibleForTheInvitation={true}
            setDetails={setDetails}
            setDocuments={setDocuments}
            setSelectedCompanyId={setSelectedCompanyId}
          />
        </GenericAccordion>
      ));
    }
  };

  const closeModalUpload = () => {
    setUsingModalUpload(false);
  };

  return (
    <Fragment>
      <WhiteBox internalWhiteBox={true}>{renderItems()}</WhiteBox>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={inviteAnsweredCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
      {usingModalUpload && (
        <DocumentsUploadModal
          companyId={idPostDocument}
          closeModal={closeModalUpload}
          handlePageChange={handlePageChange}
          selectedCompanyId={selectedCompanyId}
          setSelectedCompanyId={setSelectedCompanyId}
        />
      )}
    </Fragment>
  );
}
