import React, { useContext, useRef, useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Box,
  CloseButton,
  Container,
  Content,
  WrapperMessage,
  WrapperTitle,
  BubbleDialog,
  ContainerButton,
  GreenButton,
  ErrorMessage,
  GreenButtonFeedback,
} from '../ModalStylingPattern/styles';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { Form } from '@unform/web';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';
import InputDesc from '../../../../../../../../components/Opportunities/Inputs/InputDescription';

import { GrFormClose } from 'react-icons/gr';
import { removeStudent } from 'views/StaffPages/Opportunities/services';
import NoteInput from 'components/UnformFields/noteInput';

export default function ModalSendYourFeedback(props) {
  const {
    modalFeedbackStudent,
    setModalFeedbackStudent,
    extensionProjectId,
  } = useContext(ExtensionProjectsContext);

  const {
    isOpen,
    closeModalFeedbackStudent,
    student,
    getStudents,
    offset,
    limitPerPage,
    finalization,
  } = props;
  const modalUnpublishStudent = useRef();
  const formRef = useRef();

  const [error, setError] = useState('');

  useEffect(() => {
    setModalFeedbackStudent(false);
  }, []);

  const closeModal = () => {
    setModalFeedbackStudent(false);
    closeModalFeedbackStudent(false);
  };

  const closeModalClick = (e) => {
    if (modalUnpublishStudent.current === e.target) {
      closeModalFeedbackStudent(false);
    }
  };

  const closeModalFeedback = async (e) => {
    closeModalFeedbackStudent(false);
    await getStudents({ limitPerPage, offset });
  };

  const handleUpdateFeedback = async (data) => {
    var studentId = student.id;
    try {
      const schema = Yup.object().shape({
        feedback: Yup.string()
          .required('Esse campo é obrigatório!')
          .min(20, 'O mínimo de caracteres é 20'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      if (finalization) {
        data.finalization = finalization;
      }
      await removeStudent(extensionProjectId, studentId, data);
      setModalFeedbackStudent(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = err.errors[0];
        setError(error);
      }
    }
  };

  const memoDescInput = useMemo(() => {
    return (
      <Form ref={formRef} onSubmit={handleUpdateFeedback}>
        <NoteInput name="feedback" maxLength={200} useCount />
        {error ? <ErrorMessage> {error} </ErrorMessage> : null}
        <ContainerButton>
          <GreenButtonFeedback error={error} type="submit">
            REMOVER ESTUDANTE
          </GreenButtonFeedback>
        </ContainerButton>
      </Form>
    );
  }, [error]);

  const FormFeedback = () => {
    return (
      <Container isOpen={isOpen}>
        <Box ref={modalUnpublishStudent} onClick={closeModalClick}>
          <Content size="unpublish">
            <CloseButton onClick={closeModal}>
              <GrFormClose size={18} />
            </CloseButton>

            <WrapperTitle>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog widthBubble="220px" heightBubble="54px">
                <span> Envie seu feedback </span>
              </BubbleDialog>
            </WrapperTitle>
            <WrapperMessage
              fontMessage="18px"
              lineHeigthMessage="1.6rem"
              positionMessage="start"
            >
              Mande um feedback explicando porque o estudante foi removido.
            </WrapperMessage>
            {memoDescInput}
          </Content>
        </Box>
      </Container>
    );
  };

  const FeedbackSent = () => {
    return (
      <Container>
        <Box>
          <Content>
            <WrapperTitle>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog widthBubble="240px" heightBubble="71px">
                <span> Feedback enviado com sucesso! </span>
              </BubbleDialog>
            </WrapperTitle>

            <WrapperMessage
              fontMessage="20px"
              lineHeigthMessage="28px"
              positionMessage="center"
            >
              O estudante conseguirá visualizar o feedback enviado na
              plataforma.
            </WrapperMessage>

            <ContainerButton>
              <GreenButton
                widthButton="211px"
                marginTopButton="10px"
                type="button"
                onClick={closeModalFeedback}
              >
                OK, ENTENDI
              </GreenButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    );
  };

  if (modalFeedbackStudent === false) {
    return <FormFeedback />;
  }

  if (modalFeedbackStudent === true) {
    return <FeedbackSent />;
  }
}
