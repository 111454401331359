export function IconLocalization() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47797)">
        <path
          d="M8.9684 18.2725L8.44559 17.8244C7.72486 17.2209 1.43164 11.7858 1.43164 7.8102C1.43164 3.64777 4.80597 0.273438 8.9684 0.273438C13.1308 0.273438 16.5052 3.64777 16.5052 7.8102C16.5052 11.7858 10.2119 17.2209 9.4942 17.8273L8.9684 18.2725ZM8.9684 1.90311C5.70755 1.9068 3.06504 4.54932 3.06134 7.81016C3.06134 10.3077 6.93317 14.2975 8.9684 16.1237C11.0037 14.2968 14.8755 10.3047 14.8755 7.81016C14.8718 4.54932 12.2293 1.90683 8.9684 1.90311Z"
          fill="#606062"
        />
        <path
          d="M8.96798 10.7973C7.31802 10.7973 5.98047 9.45973 5.98047 7.80977C5.98047 6.15982 7.31802 4.82227 8.96798 4.82227C10.6179 4.82227 11.9555 6.15982 11.9555 7.80977C11.9555 9.45973 10.618 10.7973 8.96798 10.7973ZM8.96798 6.31598C8.143 6.31598 7.47422 6.98476 7.47422 7.80974C7.47422 8.63472 8.143 9.30349 8.96798 9.30349C9.79295 9.30349 10.4617 8.63472 10.4617 7.80974C10.4617 6.98476 9.79299 6.31598 8.96798 6.31598Z"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47797">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.266602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
