import { Form } from '@unform/web';
import InputDesc from 'components/Opportunities/Inputs/InputDescription';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import Input from 'components/UnformFields/inputField';
import { useRef, useEffect } from 'react';
import * as Yup from 'yup';
import {
  H3Header,
  NextButton,
  PrevsButton,
  WrapperBottomActions,
  WrapperSelect,
} from '../styled';
import { schemaStepTwo } from './schemas';

export default function MyProfileModalStepTwo({
  data,
  options,
  handleNext,
  handlePrevs,
  stepsData,
  formRef,
}) {
  useEffect(() => {
    if (data && options && options.company_sizes) {
      formRef.current.setFieldValue('size', data?.size?.value);
      formRef.current.setFieldValue(
        'activities_sectors',
        data?.activities_sectors
      );
      formRef.current.setFieldValue('type', data?.type);
      formRef.current.setFieldValue('website', data?.website);
      formRef.current.setFieldValue('description', data?.description);
    }

    if (Object.keys(stepsData).length > 0) {
      if (stepsData.legal_name) {
        formRef.current.setFieldValue('legal_name', stepsData?.legal_name);
      }
      if (stepsData.size) {
        formRef.current.setFieldValue('size', stepsData?.size);
      }
      if (stepsData.activities_sectors) {
        formRef.current.setFieldValue(
          'activities_sectors',
          stepsData?.activities_sectors
        );
      }
      if (stepsData.type) {
        formRef.current.setFieldValue('type', stepsData?.type);
      }
      if (stepsData.website) {
        formRef.current.setFieldValue('website', stepsData?.website);
      }
      if (stepsData.description) {
        formRef.current.setFieldValue('description', stepsData?.description);
      }
    }
  }, [data, options, stepsData]);

  const handleSubmit = async (data) => {
    formRef.current.setErrors({});
    try {
      await schemaStepTwo.validate(data, { abortEarly: false });
      handleNext(data);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={data}>
      <H3Header>Dados da empresa</H3Header>
      <WrapperSelect>
        <Input name="legal_name" label="Razão social*" />
        <UnformSelect
          name="size"
          label="Porte*"
          options={options?.company_sizes}
        />
        <UnformSelect
          name="activities_sectors"
          label="Tipo de atividade*"
          options={options?.sectors}
          isMulti
        />
        <UnformSelect
          name="type"
          label="Tipo da empresa*"
          options={options?.company_types}
        />
      </WrapperSelect>
      <Input name="website" label="Website" placholder="Digite aqui..." />
      <InputDesc
        name="description"
        label="Descrição da empresa* 
        (escreva aqui em poucas linhas seu perfil, produtos e serviços)"
      />
      <WrapperBottomActions justify="space-between">
        <PrevsButton type="button" onClick={handlePrevs}></PrevsButton>
        <NextButton type="submit"></NextButton>
      </WrapperBottomActions>
    </Form>
  );
}
