import styled from 'styled-components';

export const Container = styled.div`
  > h4 {
    margin-bottom: 5px;
    color: #009291;
    font-weight: bold;
  }

  > span {
    color: #606062;
  }
`;
