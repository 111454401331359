import styled from 'styled-components';

export const Overlay = styled.div`
  width: calc(100% + 259px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const Content = styled.div`
  background-color: #ffff;
  width: 577px;
  max-height: 698px;
  height: auto;
  border-radius: 8px;
  padding: 16px 29px;
  top: 40px;
  bottom: 20px;
  z-index: 2000;
  margin-top: 2%;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;

  .campaignName {
    color: #606062;
    font-size: 17px;
    font-weight: 700;
    margin-bottom: -15px;
  }

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3; 
  border-radius: 6px; 
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-moz-scrollbar {
  width: 5px; 
}

::-moz-scrollbar-thumb {
  background-color: #d3d3d3; 
  border-radius: 6px; 
}

::-moz-scrollbar-track {
  background-color: transparent;
}
`;
export const Box = styled.div`
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonClose = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 40px;
    height: 40px;
    border: none;
    font-weight: 400;
    background-color: #ffffff;
    cursor: pointer;
  }
`;

export const MailContainer = styled.section`
  border: 1px solid gray;
  border-radius: 8px;
  text-align: center;
  margin-top: 30px;
  width: 520px;
  user-select: none;

  > footer {
    > div {
      margin-top: 1px;
      display: flex;
      flex-direction: column;
      align-items: end;
      font-size: 11px;
      margin-right: 21px;
      padding: 28px 0px;

      > span {
        a {
          text-decoration: underline;
          color: ${(props) => props.color};
          font-size: 13px;
          font-weight: 400;
        }
      }
    }
  }
`;

export const MailHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  > img {
    width: 250px;
    padding: 20px 0px;
  }
`;

export const MainContent = styled.main`
  h3 {
    font-weight: bold;
    color: #4b4b4d;
    margin: 20px 0px;
  }

  p {
    margin: 20px 0px;
    padding: 0px 55px;
  }

  .actionButton {
    margin: 40px 0px;
    > button {
      width: 196px;
      height: 34px;
      border-radius: 4px;
      border: none;
      background-color: ${(props) => props.color};
      color: #ffff;
      font-weight: bold;
      cursor: unset;
    }
  }

  .sendBy {
    display: flex;
    font-size: 11px;
    flex-direction: column;
  }

  .spamInfo {
    margin-top: 35px;
    font-size: 11px;
    padding: 0px 55px;
    display: flex;
    flex-direction: column;
    color: #606062;

    > span:nth-child(2) {
      font-size: 12px;
    }
  }
`;
export const SpanContainer = styled.div`
  display: flex;
  flex-direction: column;
  span {
    color: #606062;
    font-weight: 400;
    display: flex;
    width: 246px;
  }
  .rowContainer {
    display: flex;
    gap: 50%;
  }
  .sendDate {
    margin: 10px 0px;
    color: #949494;
    font-size: 15px;
    font-weight: 400;
  }
  .campaignName {
    color: #606062;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .firstRow,
  .secondRow {
    display: flex;
    gap: 40px;
    margin-bottom: 10px;
  }
`;
export const Image = styled.div`
  margin-right: 10px;
  width: 37.03px;
  height: 37.03px;
  padding: 4px;
  border-radius: 6px;
  background-color: #caeaea;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0;
  }
`;
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: #606062;
  p {
    line-height: 17px;
  }
`;
