import React from 'react';
import {
  AbsencesSubTab,
  Container,
  SchedulingSubTab,
  SubTabSelectContainer,
} from './styled';
import { SchedulingTab } from './SchedulingTab';
import { AbsencesTab } from './AbsencesTab';
import { useCreateMentor } from '../../context';

export function CreateMentorStepTwo() {
  const { stepTwoSelectedTab, handleStepTwoChangeTab } = useCreateMentor();

  const tabs = {
    scheduling: <SchedulingTab />,
    absences: <AbsencesTab />,
  };

  return (
    <Container>
      <SubTabSelectContainer>
        <SchedulingSubTab
          selected={stepTwoSelectedTab === 'scheduling'}
          onClick={() => handleStepTwoChangeTab('scheduling')}
        >
          Agendamento
        </SchedulingSubTab>
        <AbsencesSubTab
          selected={stepTwoSelectedTab === 'absences'}
          onClick={() => handleStepTwoChangeTab('absences')}
        >
          Ausências
        </AbsencesSubTab>
      </SubTabSelectContainer>
      {tabs[stepTwoSelectedTab]}
    </Container>
  );
}
