import React from 'react';
import CardAvatar from 'components/Card/CardAvatar';
import ImageButtonStudent from './components/ImageButtonStudent';
import InformationsProfile from './components/informationsProfile';
import Background from './components/Background';
import {
  ContainerAvatar,
  ContainerButtonAndInformations,
} from './components/styled';

const ProfileHeaderStudent = (props) => {
  const {
    professional_status,
    globalState,
    metadata,
    openSnackbar,
    closeModal,
    setUserData,
    setGlobalState,
    profileData,
    setProfileData,
    showRaceOrColor,
    setShowRaceOrColor,
    showDisability,
    setShowDisability,
    showIdentityGender,
    setShowIdentityGender,
  } = props;

  return (
    <ContainerAvatar>
      <Background />
      <ContainerButtonAndInformations>
        <ImageButtonStudent
          profileData={profileData}
          setUserData={setUserData}
          closeModal={closeModal}
          openSnackbar={openSnackbar}
          setProfileData={setProfileData}
          showRaceOrColor={showRaceOrColor}
          setShowRaceOrColor={setShowRaceOrColor}
          showDisability={showDisability}
          setShowDisability={setShowDisability}
          showIdentityGender={showIdentityGender}
          setShowIdentityGender={setShowIdentityGender}
          {...props}
        />

        <InformationsProfile
          profileData={profileData}
          professionalStatus={professional_status}
          globalState={globalState}
          metadata={metadata}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          setGlobalState={setGlobalState}
          setProfileData={setProfileData}
          showRaceOrColor={showRaceOrColor}
          setShowRaceOrColor={setShowRaceOrColor}
          showDisability={showDisability}
          setShowDisability={setShowDisability}
          showIdentityGender={showIdentityGender}
          setShowIdentityGender={setShowIdentityGender}
          {...props}
        />
      </ContainerButtonAndInformations>
    </ContainerAvatar>
  );
};

export default ProfileHeaderStudent;
