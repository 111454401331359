import React, { createContext, useContext, useState } from 'react';
import { mentorshipApi } from 'views/Student/Mentorship/services/api';
import { mentorAdapter } from 'views/StaffPages/Mentorship/screens/MentorsTab/adapters/MentorAdapter';
import { adapterSpecificDates } from 'views/StaffPages/Mentorship/screens/MentorsTab/adapters/SpecificDatesAdapter';

const ScheduledMentorshipContext = createContext();

export const ContextProvider = ({ children }) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [mentorModalIsOpen, setMentorModalIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [mentorships, setMentorships] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const openDetailsModal = async (data) => {
    const mentorshipDetailsResponse = await mentorshipApi.getMentorshipDetails(
      data.id
    );
    setModalData(mentorshipDetailsResponse);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setModalData({});
  };

  const openRescheduleModal = async (data) => {
    const [
      mentorDetailsResponse,
      specificDatesResponse,
      absencesResponse,
      scheduledDays,
    ] = await Promise.all([
      mentorshipApi.getMentorDetails(data?.mentor?.id),
      mentorshipApi.getMentorSpecificDates(data?.mentor?.id),
      mentorshipApi.getMentorAbsences(data?.mentor?.id),
      mentorshipApi.getMentorMentorshipScheduled(data?.mentor?.id),
    ]);
    const newSelectedMentor = {
      ...mentorAdapter(mentorDetailsResponse),
      ...data,
    };
    newSelectedMentor.id = data.id;
    newSelectedMentor.specific_dates = adapterSpecificDates(
      specificDatesResponse
    );
    newSelectedMentor.mentor_id = data?.mentor?.id;
    newSelectedMentor.name = mentorDetailsResponse.name.name;
    newSelectedMentor.absences = absencesResponse;
    newSelectedMentor.scheduled_days = scheduledDays;
    setModalData(newSelectedMentor);
    setRescheduleModalOpen(true);
  };

  const closeRescheduleModal = () => {
    setRescheduleModalOpen(false);
  };

  const openMentorModal = (data) => {
    setModalData(data);
    setMentorModalIsOpen(true);
  };

  const closeMentorModal = () => {
    setMentorModalIsOpen(false);
    setModalData(null);
  };

  return (
    <ScheduledMentorshipContext.Provider
      value={{
        detailsModalOpen,
        setDetailsModalOpen,
        rescheduleModalOpen,
        setRescheduleModalOpen,
        mentorModalIsOpen,
        cancelModalOpen,
        setCancelModalOpen,
        modalData,
        setModalData,
        openDetailsModal,
        closeDetailsModal,
        openRescheduleModal,
        closeRescheduleModal,
        openMentorModal,
        closeMentorModal,
        mentorships,
        setMentorships,
        loading,
        setLoading,
        page,
        setPage,
      }}
    >
      {children}
    </ScheduledMentorshipContext.Provider>
  );
};

export function useScheduledMentorshipContext() {
  return useContext(ScheduledMentorshipContext);
}
