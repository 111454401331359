import {
  ContainerCompany,
  ControlSideTitle,
  SkeletonDescription,
  SkeletonLegend,
  SkeletonTitle,
} from '../../Styles/StyleSkeleton';

export function CompanySkeleton(params) {
  return (
    <ContainerCompany>
      <div className="left-side">
        <ControlSideTitle side="start">
          <SkeletonLegend widthLegend={85} />
        </ControlSideTitle>
        <div className="content">
          <SkeletonTitle subtitle />
          <SkeletonDescription widthLegend={320}/>
        </div>
        <div className="content">
          <SkeletonLegend widthLegend={200} />
          <SkeletonLegend widthLegend={400} />
        </div>
      </div>
      <div className="right-side">
        <ControlSideTitle side="end">
          <SkeletonLegend widthLegend={85} />
        </ControlSideTitle>
        <div className="content">
          <SkeletonTitle subtitle />
          <SkeletonDescription widthLegend={320}/>
        </div>
        <div className="content">
          <SkeletonLegend widthLegend={200} />
          <SkeletonLegend widthLegend={400} />
        </div>
      </div>
    </ContainerCompany>
  );
}
