import { useLearningIcons } from 'views/Student/Learning/Hooks/useLearningIcons';
import {Container, Box, ButtonX} from '../../../Styles/BaseModal'
import { Content, Item, Subtitle, Title, WrapperItem } from './style'


export function ModalCompetencesSpecialities ({ 
  details, 
  controlCompetencesSpecialities, 
  setControlCompetencesSpecialities
}) {

  const { IoClose } = useLearningIcons();

  const handleCloseModal = () => {
    setControlCompetencesSpecialities(false);
  }

  return (
    <Container isOpen={controlCompetencesSpecialities}>
      <Box centerModal>
        <Content>
          <ButtonX onClick={() => handleCloseModal()}>
            <IoClose size={25} color="#4B4B4D"/>
          </ButtonX>
          <Title>Conhecimentos relacionados</Title>
          <Subtitle> 
            Confira abaixo os conhecimentos relacionados 
            a aprendizagem <strong>{details.name}</strong>. 
          </Subtitle>

          <WrapperItem>
            {
              details && details?.competences_specialities.map(item => (
                <Item key={item.id}>{item.label}</Item>
              
              ))
            }
          </WrapperItem>
        </Content>
      </Box>
    </Container>
  )
}