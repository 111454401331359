import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer.jsx';

import StaffHeader from 'components/ViewsHeader/StaffViewsHeader.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import CompanyRegistrationForm from './components/CompanyRegistrationForm/CompanyRegistrationForm';

import BaseLayoutContext from 'contexts/base-layout';

class Company extends React.Component {
  state = {};

  async getCompany() {
    const response = await getCompanyLogged();
    if (!response.message) {
      this.setState({ company: response });
      this.props.handleIsLoadingState(false);
    } else {
      this.props.handleIsLoadingState(false);
    }
  }

  componentDidMount() {
    this.getCompany();
  }
  render() {
    let propsCopy = Object.assign({}, this.props);
    delete propsCopy.classes;
    const { name: componentTitle } = propsCopy;
    return (
      <div>
        <StaffHeader title={componentTitle} />
        <GridContainer>
          <Grid item xs md={12} sm={12}>
            <BaseLayoutContext.Consumer>
              {(values) => {
                return (
                  <CompanyRegistrationForm
                    {...values}
                    companyUpdate={this.state.company}
                  />
                );
              }}
            </BaseLayoutContext.Consumer>
          </Grid>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(Company);
