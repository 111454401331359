import styled, { createGlobalStyle } from 'styled-components';
export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 18px 0 #0000001a;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0rem 3rem 0rem;
  overflow-y: auto;
  max-height: 100vh;
  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
`;

export const BackButton = styled.button`
  width: 35px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 6px;
  border: none;

  font-family: Roboto;
  font-size: 18px;

  margin-top: 1.5rem;
  margin-left: 1.5rem;
  padding: 0.3rem;
  gap: 0.5rem;

  cursor: pointer;
  position: relative;

  > p {
    position: absolute;
    left: 35px;

    margin: 0;
    color: ${(props) => props.color || '#009291'};
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    width: 100px;

    > p {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
    transition: 0.3s;
  }

  > svg {
    fill: ${(props) => props.color || '#009291'};
    width: 25px;
    height: 25px;
    transition: fill 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    > p {
      display: inherit;
      opacity: 1;
    }

    width: 100px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: red;
  gap: 1rem;
`;

export const FilterStyled = styled.div`
  z-index: 3444;
  position: absolute;
  top: 1rem;
  right: 40rem;
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 16px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  > button {
    border: none;
    border-color: none;
    background-color: ${(props) => props.color};
    border-radius: 3px;
    height: 2.6rem;
    width: 8rem;
    color: white;
    font-weight: 700;
    font-size: 20px;
  }
`;

export const FragmentColor = styled.div`
  width: 100%;
  border-radius: 8px;
  min-height: 190px;
  background: linear-gradient(to bottom, ${(props) => props.color}, white);
`;

export const ContainerLogo = styled.div`
  margin-top: -6rem;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #cdcdcd;
  ${(props) => (props.img ? `background-image: url(${props.img});` : '')}
  background-size: cover;
  background-position: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  align-content: center;
`;

export const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  align-content: center;
  font-size: 70px;
  font-family: Roboto;
  font-weight: 400;
  gap: 55px;
  background-color: #f8fafc;

  @media (max-width: 768px) {
    flex-direction: column;
    background-color: #f8fafc;
    gap: 8px;
    align-items: start;
    margin-left: 3px;
  }
`;

export const TextName = styled.div`
  font-size: 23px;
  font-weight: 700;
  font-family: Roboto;
  color: #606062;
  line-height: 30px;
  text-align: center;
  padding: 0.5rem;
  text-transform: capitalize;
  text-decoration: capitalize;

  height: ${(props) => (props.card ? '76px' : 'unset')};

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const ContainerMiniLogo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background: ${(props) => props.color}19;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3px;
`;

export const TextRow = styled.p`
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  color: #606062;
  margin: 0;
  align-items: center;
  align-content: center;
  text-transform: capitalize;
  > b {
    color: ${(props) => props.color || 'transparent'};
    font-weight: 700;
    text-decoration: underline;
  }

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

export const ContainerTabs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 9px;
  border: 1px solid #d9d9d9;
  width: 100%;
  @media (max-width: 475px) {
    margin: 0;
  }
`;

export const Box = styled.div`
  padding: 1.5rem;
  width: 100%;
  @media (max-width: 475px) {
    padding: 0.7rem;
  }
`;

export const ButtonFavorite = styled.button`
  background-color: ${(props) => props.color};
  height: 37px;
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 5px;
  justify-content: space-evenly;
  width: 100%;
  max-width: 225px;

  > svg {
    width: 25px;
    height: 25px;
  }

  @media (min-width: 778px) {
    padding: 0.7rem;
    position: absolute;
    top: 11px;
    right: 11px;
  }

  @media (max-width: 778px) {
    margin-top: 15px;
    height: 3rem;
    max-width: 16rem;
    justify-content: center;
    gap: 8px;
  }
`;

export const BoxTabs = styled.div`
  width: 100%;
  height: 100px;
  position: relative;
`;

export const BoxModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  background-color: #eeeeee;
  z-index: 2000;
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 259px)')};
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;

  @media (max-width: 960px) {
    width: 100%;
    padding: 0rem;
  }
`;
