import React, { useEffect, useRef, Fragment, useReducer } from 'react';
import { useField } from '@unform/core';
import {
  Container,
  InputRadio,
  LabelInput,
  SubTitle,
  TitleLabel,
} from './style';

const Radio = ({ name, options, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = '' } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return (
          (refs.find((ref) => ref.checked) &&
            refs.find((ref) => ref.checked).value) ||
          ''
        );
      },
      setValue: (refs, id) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Fragment>
      {options.map((option, index) => (
        <Container>
          <LabelInput htmlFor={option.id} key={option.id}>
            <InputRadio
              ref={(ref) => ref && (inputRefs.current[index] = ref)}
              id={option.id}
              type={option.type}
              name={name}
              defaultChecked={option.value === defaultValue}
              value={option.value}
              {...rest}
            />

            <TitleLabel>{option.label}</TitleLabel>
          </LabelInput>
          {option.description && <SubTitle>{option.description}</SubTitle>}
        </Container>
      ))}
    </Fragment>
  );
};

export default Radio;
