import React, { useState } from 'react';
import { ContainerLogos, Logos } from '../styles/LogoAnimatedStyle';
import {
  CertificateIcon,
  EbookIcon,
  FolderIcon,
  GraduationCapIcon,
  LampIcon,
} from 'assets/ImgsLogin/IconsLogin';

export default function LogosAnimated({ color = '#009291', size, boxSize }) {
  const Icons = [
    <EbookIcon color={color} size={size} />,
    <CertificateIcon color={color} size={size} />,
    <LampIcon color={color} size={size} />,
    <FolderIcon color={color} size={size} />,
    <GraduationCapIcon color={color} size={size} />,
  ];
  return (
    <Logos style={{ display: 'flex', margin: 50 }}>
      {Icons.map((images, index) => (
        <ContainerLogos
          boxSize={boxSize}
          key={index}
          color={'#fff'}
          delay={index * 0.2}
        >
          {images}
        </ContainerLogos>
      ))}
    </Logos>
  );
}
