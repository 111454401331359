const checkSteps = (stepErrors, errors, setFieldTouched) => {
  const fields = [];
  stepErrors.filter((field) =>
    Object.keys(errors).map((schemaField) => {
      if (field == schemaField) {
        fields.push(schemaField);
      }
    })
  );
  for (const fieldError of fields) {
    setFieldTouched(fieldError);
  }
  return !fields.length;
};

export default checkSteps;
