export const CheckIcon = ({ color = '#009291', size = 17 }) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_31830_24989)">
        <path
          d="M8 -0.000488281C3.58867 -0.000488281 0 3.58818 0 7.99951C0 12.4108 3.58867 15.9995 8 15.9995C12.4113 15.9995 16 12.4108 16 7.99951C16 3.58818 12.4113 -0.000488281 8 -0.000488281ZM7.93933 10.2788C7.68133 10.5368 7.342 10.6655 7.00133 10.6655C6.66067 10.6655 6.318 10.5355 6.05733 10.2755L4.20267 8.47818L5.13133 7.52018L6.99333 9.32485L10.866 5.52418L11.802 6.47418L7.93933 10.2788Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_31830_24989">
          <rect
            width={size}
            height={size}
            fill={color}
            transform="translate(0 -0.000488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
