import React, { useContext, useState } from 'react';
import { EventsECoursesSteps } from '../../Resources/FormCoursesSteps';

import { Form } from '@unform/web';
import { createNewEvent } from '../../../../../services/university/events';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import StepOne from '../Steps/FormCoursesAndEvents/StepOne';
import StepTwo from '../Steps/FormCoursesAndEvents/StepTwo';
import StepTree from '../Steps/FormCoursesAndEvents/StepTree';

export default function FormCoursesAndEvents({ setActiveTab }) {
  const { step, setStep, setFormValues } = useContext(OportunitiesContext);

  const nextFormStep = () => {
    setStep((prevsStep) => prevsStep + 1);
  };

  const prevsFormStep = () => {
    setStep((prevsStep) => prevsStep - 1);
  };

  const handleSubmit = (data) => {
    setFormValues(data);
    nextFormStep();
  };

  return (
    <div>
      {step === 0 && (
        <StepOne
          nextFormStep={nextFormStep}
          prevsFormStep={prevsFormStep}
          handleSubmit={handleSubmit}
        />
      )}
      {step === 1 && (
        <StepTwo
          nextFormStep={nextFormStep}
          prevsFormStep={prevsFormStep}
          handleSubmit={handleSubmit}
        />
      )}
      {step === 2 && (
        <StepTree
          nextFormStep={nextFormStep}
          prevsFormStep={prevsFormStep}
          handleSubmit={handleSubmit}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
}
