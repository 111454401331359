import {
  CardWithStatus,
  ContainerCardsSkeleton,
  Header,
  InformationsJob,
  ParagraphSkeleton,
  InformationSkeleton,
  ButtonSkeleton,
  JobStatus,
  ContentWithStatus,
  ContentButtonsApply,
} from '../../NewCards/styled';

const SkeletonCardsWithStatus = () => {
  const num = 5;

  return (
    <ContainerCardsSkeleton divHeight={'340px'}>
      <div>
        {[...Array(num)].map((_, index) => (
          <div>
            <CardWithStatus className="CardWithStatus">
              <JobStatus />

              <ContentWithStatus>
                <Header>
                  <ParagraphSkeleton />
                  <ParagraphSkeleton
                    style={{
                      marginTop: '5px',
                      width: '145px',
                      marginBottom: '24px',
                    }}
                  />
                </Header>

                <InformationsJob>
                  <InformationSkeleton />
                  <InformationSkeleton />
                </InformationsJob>

                <ContentButtonsApply style={{ marginTop: '65px' }}>
                  <ButtonSkeleton style={{ width: '147px' }} />
                </ContentButtonsApply>
              </ContentWithStatus>
            </CardWithStatus>
          </div>
        ))}
      </div>
    </ContainerCardsSkeleton>
  );
};

export default SkeletonCardsWithStatus;
