import styled from 'styled-components';
import { Document, Page } from 'react-pdf'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
  overflow-y: hidden;
`;

export const Box = styled.div`
  width: calc(100% - 259px);

  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    min-width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #efeeec;
  padding: 30px 60px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: row;
  }

  .WrapperPdf {
    margin-right: 40px;

    .document {      
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;

    .WrapperPdf {
      margin-bottom: 30px;
    }
  }
`;

export const ContainerArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;

  > div {
    background-color: ${({ color }) => color};
    padding: 4px;
    align-items: center;
    display: flex;
    border-radius: 4px;
  }

  > span {
    width: 100%;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-weight: 500;
  }
`;

export const ContainerDownload = styled.div`
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: fit-content;
  border-radius: 8px;
  margin-top: 70px;
  width: 300px;
  max-width: 25vw;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }

  > h4 {
    color: #606062;
    font-weight: 600;
  }

  > .actions {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .disabled {
    cursor: not-allowed;
    filter: brightness(80%) !important;
  }
`;

export const StyledDocument = styled(Document)`

  max-width: calc(70vw - 40px);
  &${Page} {
    max-width: calc(70vw - 40px);
    canvas {
      width:100% !important;
      max-width: calc(70vw - 40px);
      height: auto !important;
    }
  }
`
