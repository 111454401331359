import React, { useRef, useEffect, useContext, Fragment } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { ContainerLabelInput, ErrorWrapper } from '../../Styles/formStyled';
import { UnInputLabel } from '../../Styles/unformComponentsStyled';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function SearchableSelectUn({
  label,
  name,
  options,
  placeholder,
  ...rest
}) {
  const selectRef = useRef(null);
  const { cacheOptions } = useContext(OportunitiesContext);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value
            .filter((value) => !!value)
            .map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value.value;
        }
      },
      setValue: (ref, value) => {
        const currentOptions = ref.props.options;
        if (Array.isArray(value) && currentOptions.length > 0) {
          const newArray = value.map((value) =>
            currentOptions.find(
              (option) => option.value === value.value || option.value === value
            )
          );
          return ref.onChange(newArray);
        }
        ref.onChange(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const selectStyles = {
    control: (base) => ({
      ...base,
      borderColor: error ? '#FFCECE' : '#ced4da',
      backgroundColor: error ? '#FFEDED' : '#fff',
    }),
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#9FD6D6',
        primary50: '#80C9C8',
        primary: '#009291',
      },
    };
  }

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <ReactSelect
        defaultValue={
          defaultValue && Array.isArray(defaultValue)
            ? defaultValue.map((item) =>
                cacheOptions.find(
                  (option) =>
                    option.value === item || option.value === item.value
                )
              )
            : options.find(
                (option) =>
                  String(option.value) === String(defaultValue) ||
                  (defaultValue &&
                    String(option.value) === String(defaultValue.value))
              )
        }
        theme={selectTheme}
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        styles={selectStyles}
        ref={selectRef}
        classNamePrefix="react-select"
        onFocus={clearError}
        {...rest}
      />
      <ErrorWrapper style={{ marginTop: '5px' }}>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
