import {
  ContainerList,
  SpanName,
  Containers,
  ContainerListHeader,
  SpanPopUp,
  CampaignDate,
  SearchInput,
  ContainerSearch,
} from './style';
import editIcon from 'assets/Matriz/edit.svg';
import eye from 'assets/JobOffer/eye.svg';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import moment from 'moment';
import { useEffect } from 'react';

const SearchName = ({ isCampaign, getList }) => {
  return (
    <ContainerSearch>
      {isCampaign && (
        <SearchInput
          placeholder="Buscar pelo nome"
          onChange={(e) => {
            getList && getList(e.target.value, true);
          }}
        />
      )}
    </ContainerSearch>
  );
};
export { SearchName };

const Header = ({ isCampaign }) => {
  const messages = {
    campaign: {
      label: 'NOME DA CAMPANHA',
      date: 'DATA DE CRIAÇÃO',
    },
    list: {
      label: 'NOME DA LISTA',
      date: 'ÚLTIMA EDIÇÃO',
    },
  };

  const currentMessage = isCampaign ? messages.campaign : messages.list;

  return (
    <ContainerListHeader>
      <SpanName>{currentMessage.label}</SpanName>

      <Containers width={'43em'}>
        <span style={{ width: '8.6em', textAlign: 'end' }}>
          {currentMessage.date}
        </span>
      </Containers>

      <Containers>
        {(!isCampaign && <span>AÇÕES</span>) ||
          (isCampaign && <span>DETALHES</span>)}
      </Containers>
    </ContainerListHeader>
  );
};

export { Header };

export default function ListItems({ informations, isCampaign }) {
  const { id, name, updated_at, created_at } = informations;
  const { getDetailsItem, getCampaignMetrics } = useSendEmail();

  const getDate = () => {
    if (isCampaign) {
      return (
        <CampaignDate>
          {moment(created_at).format('DD/MM/YYYY HH:mm')}
        </CampaignDate>
      );
    }
    return moment(updated_at).format('DD/MM/YYYY HH:mm');
  };

  const handleClickEdit = () => {
    getDetailsItem(id, 'edit');
  };

  const handleClickViwer = () => {
    if (isCampaign) {
      getCampaignMetrics(id);
    } else {
      getDetailsItem(id, 'viwerOnly');
    }
  };
  useEffect(() => {
    const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];
    baseScroll.scrollTop = 0;
  }, []);

  return (
    <ContainerList>
      <SpanName>{name}</SpanName>

      <div style={{ width: '37em', display: 'flex', gap: '5px' }}>
        <span> {getDate()} </span>
      </div>

      <Containers>
        <SpanPopUp
          left={'-46px'}
          onClick={handleClickViwer}
          isCampaign={isCampaign}
        >
          <div>{isCampaign ? 'Visualizar detalhes' : 'Visualizar'}</div>
          <img src={eye} />
        </SpanPopUp>

        {!isCampaign && (
          <SpanPopUp left={'-26px'} onClick={handleClickEdit}>
            <div>Editar</div>
            <img src={editIcon} />
          </SpanPopUp>
        )}
      </Containers>
    </ContainerList>
  );
}
