export function IconStatusCourse() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47824)">
        <path
          d="M14.25 0.266602H3.75C1.68225 0.266602 0 1.94885 0 4.0166V14.5166C0 16.5844 1.68225 18.2666 3.75 18.2666H12.4845C13.4497 18.2666 14.3572 17.8909 15.0397 17.2084L16.9417 15.3064C17.6145 14.6344 18 13.7021 18 12.7511V4.0166C18 1.94885 16.3177 0.266602 14.25 0.266602ZM1.5 14.5166V4.0166C1.5 2.7761 2.5095 1.7666 3.75 1.7666H14.25C15.4905 1.7666 16.5 2.7761 16.5 4.0166V11.5166H13.5C12.2595 11.5166 11.25 12.5261 11.25 13.7666V16.7666H3.75C2.5095 16.7666 1.5 15.7571 1.5 14.5166ZM13.9792 16.1479C13.6432 16.4839 13.2142 16.6916 12.75 16.7494V13.7666C12.75 13.3526 13.0868 13.0166 13.5 13.0166H16.482C16.4228 13.4779 16.2113 13.9151 15.8805 14.2459L13.9792 16.1479ZM3.75 5.1416C3.75 4.5206 4.254 4.0166 4.875 4.0166C5.496 4.0166 6 4.5206 6 5.1416C6 5.7626 5.496 6.2666 4.875 6.2666C4.254 6.2666 3.75 5.7626 3.75 5.1416ZM6 9.2666C6 9.8876 5.496 10.3916 4.875 10.3916C4.254 10.3916 3.75 9.8876 3.75 9.2666C3.75 8.6456 4.254 8.1416 4.875 8.1416C5.496 8.1416 6 8.6456 6 9.2666ZM6 13.3916C6 14.0126 5.496 14.5166 4.875 14.5166C4.254 14.5166 3.75 14.0126 3.75 13.3916C3.75 12.7706 4.254 12.2666 4.875 12.2666C5.496 12.2666 6 12.7706 6 13.3916Z"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47824">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.266602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
