import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  align-items: center;
  flex-direction: column;

  position: fixed;
  top: 0;
  right: 0;
  z-index: 1300;
  background: #efeeec;

  color: #fff;
  padding-top: 70px;
  padding: 44px;
  padding-bottom: 0;
  overflow-y: scroll;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  background-color: #fff;
  margin: 27px 0;
  box-shadow: 0px 0px 29px 0px #0000000d;
  border-radius: 8px;

  body::-webkit-scrollbar {
    width: 12px;
  }

  body::-webkit-scrollbar-track {
    background: orange;
  }

  body::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;

  .understand-the-match {
    display: flex;
    align-items: center;
    height: 41px;

    background-color: transparent;
    border: 2px solid #009291;
    border-radius: 4px;
    color: #009291;
    font-weight: 400;
    gap: 4px;
    cursor: pointer;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 44px 44px 21px;

  .application-counter-tag {
    background: #0092911a;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #009291;
    padding: 4px 8px;
  }

  .job-code span {
    font-size: 13px;
    color: #949494;
  }

  .job-name h3 {
    font-size: 24px;
    font-weight: 700;
    color: #606062;
    margin: 0;
  }

  .vacancy-publication span {
    font-size: 16px;
    font-weight: 400;
    color: #606062;
  }

  .job-code,
  .vacancy-publication {
    margin-top: 6px;
  }
`;

export const SearchAndSelection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 21px 44px;

  .left-side {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .right-side {
    display: flex;
    align-items: center;
    gap: 20px;
  }
`;

export const BackButton = styled.button`
  width: 35px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 6px;
  border: none;

  font-family: Roboto;
  font-size: 18px;

  margin-top: 1.5rem;

  padding: 0.3rem;
  gap: 0.5rem;

  cursor: pointer;
  position: relative;

  p {
    position: absolute;
    left: 35px;

    margin: 0;
    color: ${(props) => props.color || '#009291'};
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    width: 100px;
    transition: 0.3s;

    p {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
  }

  @media (max-width: 768px) {
    width: 100px;

    p {
      display: inherit;
      opacity: 1;
    }
  }
`;

export const ContainerCheckbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: #f5f5f5;
  accent-color: #009291;
  gap: 8px;
  row-gap: 8px;
  cursor: pointer;
  white-space: nowrap;

  input[type='checkbox'] {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    margin: 0;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export function selectTheme(theme) {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      primary: '#009291',
    },
  };
}

export const customStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: '#606062',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '400',
  }),
  control: (provided) => ({
    ...provided,
    width: 140,
    boxShadow: 'rgb(255, 255, 255) 0px 3px 8px',
    borderColor: '#c4c4c4',
    height: '34px',
    zIndex: '1',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgb(255, 255, 255) 0px 3px 8px',
      borderColor: '#c4c4c4',
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: '270px',
    marginLeft: '-130px',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#606062',
    padding: 10,
  }),
};

export const customCommonStyles = {
  placeholder: (provided) => ({
    ...provided,
    color: '#606062',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: '400',
  }),
  control: (provided) => ({
    ...provided,
    boxShadow: 'rgb(255, 255, 255) 0px 3px 8px',
    borderColor: '#c4c4c4',
    height: '34px',
    zIndex: '1',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: 'rgb(255, 255, 255) 0px 3px 8px',
      borderColor: '#c4c4c4',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#fff' : '#606062',
    padding: 10,
  }),
};

export const FilterStyled = styled.div``;

export const CallToDownloads = styled.button`
  background-color: ${({ outlined }) => (outlined ? 'transparent' : '#009291')};
  color: ${({ outlined }) => (outlined ? '#009291' : '#fff')};
  border: ${({ outlined }) => (outlined ? '1px solid #009291' : 'none')};
  padding: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  border-radius: 3px;
  font-size: 13px;
  cursor: pointer;
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 0 44px;
  padding-bottom: 21px;
`;
