export function CalendarIcon({ color = '#009291', size = '15px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_31256_4269)">
        <path
          d="M11.4974 1.18717H10.9141V0.603841C10.9141 0.281841 10.6533 0.0205078 10.3307 0.0205078C10.0081 0.0205078 9.7474 0.281841 9.7474 0.603841V1.18717H5.08073V0.603841C5.08073 0.281841 4.81998 0.0205078 4.4974 0.0205078C4.17481 0.0205078 3.91406 0.281841 3.91406 0.603841V1.18717H3.33073C1.72248 1.18717 0.414062 2.49559 0.414062 4.10384V11.1038C0.414062 12.7121 1.72248 14.0205 3.33073 14.0205H11.4974C13.1056 14.0205 14.4141 12.7121 14.4141 11.1038V4.10384C14.4141 2.49559 13.1056 1.18717 11.4974 1.18717ZM3.33073 2.35384H11.4974C12.4622 2.35384 13.2474 3.13901 13.2474 4.10384V4.68717H1.58073V4.10384C1.58073 3.13901 2.3659 2.35384 3.33073 2.35384ZM11.4974 12.8538H3.33073C2.3659 12.8538 1.58073 12.0687 1.58073 11.1038V5.85384H13.2474V11.1038C13.2474 12.0687 12.4622 12.8538 11.4974 12.8538Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_31256_4269">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
