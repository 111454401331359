import Pagination from 'components/CustomPagination';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 65px;
`;

export const Paginate = styled(Pagination)`
  padding-top: 30px;
  margin: 0px;
  background: #eeeeee !important;
  .wrapper {
    background: #eeeeee !important;
  }
`;
