import React from 'react';

function LogoLocationNetwork({ color = '#009291', width = 16, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_20366_5654)">
        <path
          d="M8.13929 16.4621L7.67457 16.0637C7.03391 15.5273 1.43994 10.6961 1.43994 7.16224C1.43994 3.4623 4.43935 0.462891 8.13929 0.462891C11.8392 0.462891 14.8386 3.4623 14.8386 7.16224C14.8386 10.6961 9.24466 15.5273 8.60666 16.0664L8.13929 16.4621ZM8.13929 1.91148C5.24075 1.91477 2.89185 4.26367 2.88857 7.1622C2.88857 9.38227 6.33019 12.9288 8.13929 14.552C9.94841 12.9281 13.39 9.37958 13.39 7.1622C13.3867 4.26367 11.0378 1.9148 8.13929 1.91148Z"
          fill={color}
        />
        <path
          d="M8.13945 9.81698C6.67282 9.81698 5.48389 8.62805 5.48389 7.16142C5.48389 5.6948 6.67282 4.50586 8.13945 4.50586C9.60607 4.50586 10.795 5.6948 10.795 7.16142C10.795 8.62805 9.6061 9.81698 8.13945 9.81698ZM8.13945 5.83361C7.40614 5.83361 6.81167 6.42808 6.81167 7.16139C6.81167 7.8947 7.40614 8.48917 8.13945 8.48917C8.87276 8.48917 9.46723 7.8947 9.46723 7.16139C9.46723 6.42808 8.87279 5.83361 8.13945 5.83361Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_20366_5654">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.16748 0.457031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoLocationNetwork;
