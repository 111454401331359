import { Fragment, useCallback } from 'react';
import { Step, Divider, Stepper, InformationStep, StepStatus } from './style';
import passed from 'assets/academicModeling/passed.svg';
import lock from 'assets/academicModeling/lock.svg';
import progress from 'assets/academicModeling/progress.svg';
import { useAcademicModeling } from '../../contexts/AcademicModelingContext';

export default function StepIndicator() {
  const quantitySteps = Array.from({ length: 4 });
  const {
    currentStep,
    selectedStep,
    setSelectedStep,
    selectedCourse,
  } = useAcademicModeling();

  const labels = {
    0: {
      label: 'Matriz curricular',
      subTitle:
        'Indicação das disciplinas que fazem parte da Matriz Curricular atual.',
    },
    1: {
      label: 'Tags de conteúdos',
      subTitle: 'Descrição dos principais conteúdos abordados nas disciplinas.',
    },
    2: {
      label: 'Perfil de egresso',
      subTitle:
        'Seleção de atuações relacionadas ao perfil de egressos do curso.',
    },
    3: {
      label: 'Análise',
      subTitle:
        'Verificação das associações de tags de conteúdo com os requisitos de mercado.',
    },
  };

  const stepIsDone = useCallback(
    (step) => {
      const { steps, analyzed } = selectedCourse;
      return step === 4 ? analyzed : Object.values(steps)[step - 1];
    },
    [selectedCourse]
  );

  const currentImage = (step) => {
    if (stepIsDone(step)) {
      return passed;
    }
    if (currentStep < step) {
      return lock;
    } else {
      return progress;
    }
  };

  const currentColor = (step) => {
    if (selectedStep === step) {
      return StepStatus.SELECTED;
    }
    if (stepIsDone(step)) {
      return StepStatus.DONE;
    }
    if (currentStep < step) {
      return StepStatus.PENDING;
    } else {
      return StepStatus.CURRENT;
    }
  };

  const stepTitle = useCallback(
    (step) => {
      if (stepIsDone(step)) {
        return 'Concluído';
      }
      if (currentStep < step) {
        return 'Bloqueado, finalize a etapa anterior';
      } else {
        return 'Em andamento';
      }
    },
    [currentStep]
  );

  const createSteps = quantitySteps.map((step, index) => (
    <Fragment>
      <Step
        status={currentColor(index + 1)}
        onClick={() => {
          if (index + 1 <= currentStep) {
            setSelectedStep(index + 1);
          }
        }}
        title={stepTitle(index + 1)}
      >
        {index + 1}
        <InformationStep>
          <h3>
            {labels[index].label} <img src={currentImage(index + 1)} alt={''} />
          </h3>
          <p>{labels[index].subTitle}</p>
        </InformationStep>
      </Step>
      {index + 1 !== 4 && <Divider marginTop={'20px'} />}
    </Fragment>
  ));

  return <Stepper>{createSteps}</Stepper>;
}
