import { useState} from 'react';
import { Container, Box, ButtonX } from '../../../../Styles/BaseModal'
import { NavigationDots } from '../../../NavigationInDots'
import { AreaVideo, Content, Description, Title } from './style';
import { useLearningIcons } from 'views/Student/Learning/Hooks/useLearningIcons';
import { getEmbed } from 'views/Student/Learning/Utils/tools';
import ReactHtmlParser from 'react-html-parser';

export default function SequentialVideoModal ({
  contentAccess, 
  controlOpenModal, 
  setControlOpenModal,
  setContentAccess
}) {

  const [activeSlide, setActiveSlide] = useState(0);
  
  const { IoClose } = useLearningIcons()

  const renderPrincipalTabs = (step) => {
    return contentAccess?.find((tab) => tab.page === step + 1);
  }

  const { url, name, description } = renderPrincipalTabs(activeSlide).content_videos[0]

  const handleCloseModal = () => {
    setContentAccess(null)
    setControlOpenModal(false)
  }

  const handleDotClick = (index) => {
    setActiveSlide(index);
  };

  return (
    <Container isOpen={controlOpenModal}>
      <Box>
        <Content>
          <ButtonX onClick={handleCloseModal}>
            <IoClose size={25} color="#4B4B4D"/>
          </ButtonX>
          <Title> {name} </Title>
          {
            url && (
              <AreaVideo 
                src={getEmbed(url)}
                frameborder="0" 
              />
            )
          }
          
          <Description>
            {ReactHtmlParser( description.replace( /style="[^"]*"/g, ''))}
          </Description>
          <NavigationDots 
            totalSlides={contentAccess?.length}
            activeSlide={activeSlide}
            setActiveSlide={setActiveSlide}
            onDotClick={handleDotClick}
          />
        </Content>
      </Box>
    </Container>
  )
}