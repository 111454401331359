import React from 'react';
import PropTypes, { bool, func, object, array, string } from 'prop-types';
import cx from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';

// core components
import HeaderLinks from './HeaderLinks';
import Button from 'components/CustomButtons/Button.jsx';
import NotificationButton from 'components/Notifications/NotificationButton';

import headerStyle from 'assets/jss/material-dashboard-pro-react/components/headerStyle.jsx';
import HelpButton from '../HelpButton';
import HeaderHelpContent from 'components/Header/HeaderHelpContent.jsx';
import { Grid } from '@material-ui/core';

function Header({ ...props }) {
  function makeBrand() {
    var name;
    props.routes.map((prop) => {
      if (prop.collapse) {
        prop.views.map((prop) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    if (name) {
      const currentRoute = props.routes.find(
        (route) => route.path === props.location.pathname
      );
      if (currentRoute && currentRoute.hideName) {
        return null;
      }
      return name;
    } else {
      return props.name;
    }
  }

  const {
    classes,
    color,
    rtlActive,
    openModalWithContent,
    location,
    metadata,
    isStudent,
    universityColor,
    toggleNotificationDrawer,
  } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    });
  const showHelpContent =
    location.pathname == '/' && metadata.role == 'student';
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown implementation="css">
          {props.sidebarVisible && (
            <div className={sidebarMinimize}>
              {props.miniActive ? (
                <ViewList
                  className={classes.sidebarMiniIcon}
                  onClick={props.sidebarMinimize}
                />
              ) : (
                <MoreVert
                  className={classes.sidebarMiniIcon}
                  onClick={props.sidebarMinimize}
                />
              )}
            </div>
          )}
        </Hidden>
        <Grid container>
          <Grid item>
            {/* Here we create navbar brand, based on route name */}
            {makeBrand() && (
              <Button
                className={classes.title}
                color="transparent"
                disableRipple
              >
                {makeBrand()}
              </Button>
            )}
          </Grid>
          {showHelpContent && (
            <Grid item className={classes.helpButtonWhite}>
              <HelpButton
                helpModalTitle="Currículo do futuro"
                HelpModalContent={HeaderHelpContent}
                openModalWithContent={openModalWithContent}
                whiteIcon={true}
              />
            </Grid>
          )}
        </Grid>
        <Hidden smDown implementation="css">
          <HeaderLinks
            metadata={metadata}
            isStudent={isStudent}
            universityColor={universityColor}
            toggleNotificationDrawer={toggleNotificationDrawer}
          />
        </Hidden>
        <Hidden mdUp implementation="css">
          <div className={classes.headerButtons}>
            <NotificationButton
              toggleNotificationDrawer={props.toggleNotificationDrawer}
            />
            <Button
              className={classes.appResponsive}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu style={{ color: '#fff' }} />
            </Button>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: bool,
  colorScheme: bool,
  openModalWithContent: func,
  location: object,
  metadata: object,
  miniActive: bool,
  sidebarMinimize: func,
  handleDrawerToggle: func,
  routes: array,
  name: string,
  toggleNotificationDrawer: func,
};

const customHeaderStyle = {
  ...headerStyle,
  title: {
    ...headerStyle.title,
    textTransform: 'uppercase',
    fontWeight: 'bolder',
  },
  headerButtons: {
    display: 'flex',
  },
};

export default withStyles(customHeaderStyle)(Header);
