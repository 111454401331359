export const CalendarIcon = ({ color = "#009291", size = 37}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.3315 10.3435C36.3134 6.75759 33.3976 3.53392 29.3046 3.55203C28.9243 3.55203 28.544 3.55203 28.1093 3.55203C28.1093 3.11737 28.1093 2.71894 28.1093 2.32051C28.1093 1.45121 27.5117 0.817334 26.7148 0.817334C25.918 0.817334 25.3384 1.4512 25.3384 2.33862C25.3384 2.73705 25.3384 3.11737 25.3384 3.53391H11.6831C11.6831 3.11737 11.6831 2.71894 11.6831 2.3024C11.6831 1.43309 11.0673 0.799223 10.2705 0.817334C9.47359 0.835445 8.91217 1.4512 8.89406 2.3024C8.89406 2.70083 8.89406 3.09926 8.89406 3.57014C8.26019 3.57014 7.64443 3.53392 7.04678 3.57014C3.80499 3.78746 1.16086 6.28672 0.726204 9.51039C0.726204 9.58283 0.689986 9.63717 0.671875 9.70961V30.247C0.726207 30.5186 0.780537 30.7903 0.834869 31.0619C1.48685 33.9234 3.82311 36.0242 6.7389 36.3864C6.81135 36.3864 6.86567 36.4226 6.93812 36.4408H30.0653C30.3913 36.3683 30.7173 36.314 31.0432 36.2415C34.0858 35.5352 36.3134 32.7824 36.3315 29.6674C36.3496 23.2563 36.3496 16.7908 36.3315 10.3435ZM33.5425 14.9979C33.5425 19.7972 33.5425 24.5965 33.5425 29.4139C33.5425 31.9131 31.7858 33.688 29.2684 33.688C22.0785 33.688 14.9068 33.688 7.71687 33.688C5.21762 33.688 3.44278 31.9131 3.44278 29.4139C3.44278 24.6146 3.44278 19.8153 3.44278 14.9979V14.5813H33.5244V14.9979H33.5425ZM33.5244 11.7561H3.49711C3.00813 8.07966 5.3625 5.9064 8.89406 6.39538C8.89406 7.2828 8.89406 8.17021 8.89406 9.05763C8.91217 9.94504 9.6547 10.5608 10.524 10.434C11.176 10.3254 11.665 9.76394 11.665 9.05763C11.6831 8.29699 11.665 7.51824 11.665 6.75759V6.35916H25.3022C25.3022 6.46782 25.3203 6.59459 25.3203 6.70326C25.3203 7.4639 25.3203 8.24266 25.3203 9.0033C25.3203 9.85449 25.918 10.4521 26.7329 10.4521C27.5298 10.4521 28.1093 9.83638 28.1093 9.02141C28.1093 8.1521 28.1093 7.28279 28.1093 6.41349C31.1519 5.9245 33.9953 7.62689 33.5244 11.7561Z" fill={color}/>
      <path d="M13.0432 28.2366C13.0432 28.9791 12.4637 29.613 11.685 29.6311C10.7432 29.6492 9.80146 29.6492 8.87782 29.6311C8.11718 29.613 7.51953 28.9791 7.51953 28.2366C7.51953 27.4941 8.11718 26.8783 8.89593 26.8602C9.36681 26.8421 9.83768 26.8602 10.3267 26.8602C10.7794 26.8602 11.2322 26.8421 11.685 26.8602C12.4456 26.8783 13.0432 27.476 13.0432 28.2366Z" fill={color}/>
      <path d="M29.4871 28.2547C29.4871 28.9973 28.8895 29.613 28.1107 29.6311C27.1871 29.6492 26.2635 29.6492 25.3217 29.6311C24.543 29.613 23.9634 28.9973 23.9453 28.2547C23.9453 27.4941 24.543 26.8783 25.3398 26.8602C25.8107 26.8421 26.2635 26.8602 26.7343 26.8602C27.1871 26.8602 27.6399 26.8602 28.0926 26.8602C28.8895 26.8783 29.5052 27.4941 29.4871 28.2547Z" fill={color}/>
      <path d="M29.4847 20.0147C29.5028 20.7753 28.887 21.4092 28.1083 21.4273C27.1846 21.4454 26.2791 21.4454 25.3555 21.4273C24.5586 21.4092 23.9609 20.7935 23.9609 20.0328C23.9609 19.2722 24.5767 18.6564 25.3555 18.6383C25.8263 18.6383 26.2791 18.6383 26.75 18.6383C27.2027 18.6383 27.6555 18.6383 28.1083 18.6383C28.8689 18.6745 29.4847 19.2722 29.4847 20.0147Z" fill={color}/>
      <path d="M13.0436 20.0327C13.0436 20.7933 12.446 21.4091 11.6491 21.4272C11.1964 21.4453 10.7436 21.4272 10.2908 21.4272C9.83807 21.4272 9.3853 21.4272 8.93254 21.4272C8.11757 21.4091 7.50181 20.7933 7.51992 20.0146C7.53803 19.2539 8.15379 18.6563 8.93254 18.6382C9.83807 18.6382 10.7436 18.6201 11.6491 18.6382C12.446 18.6563 13.0436 19.272 13.0436 20.0327Z" fill={color}/>
      <path d="M21.262 20.0508C21.262 20.8114 20.6643 21.4272 19.9037 21.4272C18.9801 21.4453 18.0564 21.4453 17.1147 21.4272C16.354 21.409 15.7383 20.7933 15.7383 20.0508C15.7383 19.2901 16.3359 18.6744 17.1328 18.6562C17.6037 18.6381 18.0564 18.6562 18.5273 18.6562C18.9801 18.6562 19.4328 18.6562 19.8856 18.6562C20.6643 18.6562 21.262 19.272 21.262 20.0508Z" fill={color}/>
      <path d="M21.2638 28.2366C21.2638 28.9972 20.6661 29.613 19.8693 29.6311C19.7063 29.6311 19.5433 29.6311 19.3803 29.6311C19.1992 29.6311 19 29.6311 18.8189 29.6311C18.7102 29.6311 18.6015 29.6311 18.4929 29.6311C18.3661 29.6311 18.2212 29.6311 18.0945 29.6311C17.7866 29.6311 17.4787 29.6311 17.1708 29.6311C16.3739 29.613 15.7401 28.9972 15.7582 28.2185C15.7763 27.4578 16.3739 26.8602 17.1708 26.8421C18.0763 26.824 18.9819 26.824 19.8874 26.8421C20.648 26.8783 21.2457 27.4759 21.2638 28.2366Z" fill={color}/>
    </svg>
  )
}