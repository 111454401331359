import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: ${({ formActived }) => (formActived ? 'flex-start' : 'center')};
  justify-content: center;
  z-index: 2000;
  overflow-y: auto;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  overflow-y: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 673px;

  background-color: #ffffff;
  border-radius: 10px;

  position: relative;
`;

export const TopBar = styled.div`
  height: 85px;
  width: 100%;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background-color: #009291;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 46px;

  .title {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 200px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  cursor: pointer;
`;
