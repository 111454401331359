import styled from '@react-pdf/styled-components';

//pdf

export const StyledPage = styled.Page`
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  font-weight: normal;
  font-size: 10px;
  font-family: 'Helvetica';
`;

export const Header = styled.View`
  position: absolute;
  top: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  color: white;
`;

export const BackgroundImage = styled.Image`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const PageTitle = styled.Text`
  font-size: 20px;
  font-family: 'Helvetica-Bold';
`;

export const BottomRightText = styled.Text`
  position: absolute;
  bottom: 6px;
  right: 8px;
  font-size: 10px;
`;

export const AlignRightText = styled.Text`
  margin: 6px -10px 6px auto;
  color: #999;
  font-size: 10px;
`;

export const DefaultSection = styled.View`
  margin-top: 5px;
  border-bottom: 1pt solid #eee;
`;

export const SectionTitle = styled.Text`
  font-size: 14px;
  color: #333;
`;

export const TotalStudents = styled.Text`
  margin-top: 20px
  text-align: center;
  color: #404040;
`;

export const LegendMargin = styled.Text`
  margin-bottom: 10px;
`;

export const FormatLegend = styled.Text`
  text-align: justify;
`;

export const ChartImage = styled.Image`
  margin: 20px 0;
  object-fit: contain;
  word-wrap: break-word;
`;

export const EmptyText = styled.Text`
  margin: 80px 0;
  color: #bbb;
  font-size: 12px;
  text-align: center;
`;

export const Space = styled.View`
  height: 20px;
`;

export const CategorySection = styled.View`
  margin-bottom: 10px;
`;

export const Bold = styled.Text`
  font-family: 'Helvetica-Bold';
  color: #333;
`;

export const Grey = styled.Text`
  color: #696969;
`;

export const Footer = styled.View`
  color: #999;
  position: absolute;
  bottom: 20px
  width: 100vw;
  text-align: center;
`;
