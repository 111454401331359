import React, { useState, useContext } from 'react';
import {
  Container,
  GrayGradient,
  StudentImage,
  TextName,
  Row,
  Tooltip,
  CanceledButton,
  DetailsButton,
  IconWrapper,
  RescheduleButton,
  ButtonContainer,
  ConfirmedButton,
  Flag,
} from './styled';

import userIcon from 'assets/img/userIcon.png';
import { formatDateTime } from 'utils/formatDateTime';
import AuthContext from 'contexts/authentication';
import Icons from 'views/StaffPages/Mentorship/utils/mentorshipIcons';
import { CircleAlert } from 'assets/mentorship/CircleAlert ';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

export function StudentCard({
  item,
  buttonText,
  onOpenPendingDetailsModal,
  onOpenCompletedDetailsModal,
  onOpenCancelMentorshipModal,
  openRescheduleModal,
  modalType,
  detailsButtonText,
  showFlagAlert,
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [loadingReschedule, setLoadingReschedule] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = !['teacher', 'course_coordinator'].includes(
    staffRole
  );

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  const handleDetailsButtonClick = async () => {
    setLoadingDetails(true);
    if (modalType === 'pending') {
      await onOpenPendingDetailsModal(item);
    } else {
      await onOpenCompletedDetailsModal(item);
    }

    setLoadingDetails(false);
  };

  const handleCancelButtonClick = () => onOpenCancelMentorshipModal(item);

  const handleRescheduleButtonClick = async () => {
    setLoadingReschedule(true);
    await openRescheduleModal(item);
    setLoadingReschedule(false);
  };

  const {
    simpleFormattedDate,
    formattedStartHour,
    formattedEndHour,
  } = formatDateTime(item.date, item.start_hour, item.end_hour);

  const careerGoalLabel =
    item.career_goals?.[0]?.[0]?.label || 'Objetivo não especificado';
  const shouldShowTooltip = careerGoalLabel !== 'Objetivo não especificado';

  return (
    <Container>
      <GrayGradient />
      <StudentImage
        src={item?.student_avatar || userIcon}
        onError={(e) => {
          e.target.src = userIcon;
          e.target.onerror = null;
        }}
        margin={'13px auto 0 auto'}
      />

      <TextName onClick={handleDetailsButtonClick}>{item.student}</TextName>

      {shouldRenderForStaff && (
        <Row>
          <IconWrapper>
            <Icons.UserIcon />
          </IconWrapper>
          <span>{item?.mentor || 'Mentor não identificado'}</span>
        </Row>
      )}

      <Row>
        <IconWrapper>
          <Icons.CalendarIcon />
        </IconWrapper>
        <span>{simpleFormattedDate}</span>
      </Row>

      <Row>
        <IconWrapper>
          <Icons.ClockIcon />
        </IconWrapper>
        <span>
          Das {formattedStartHour} às {formattedEndHour}
        </span>
      </Row>

      {!shouldRenderForStaff && (
        <Row>
          <IconWrapper>
            <Icons.HouseLaptopIcon />
          </IconWrapper>
          <span>{item?.mentorship_modality?.label}</span>
        </Row>
      )}

      <Row onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <IconWrapper>
          <Icons.TargetIcon />
        </IconWrapper>
        <span>{careerGoalLabel}</span>
        {shouldShowTooltip && (
          <Tooltip visible={showTooltip}>{careerGoalLabel}</Tooltip>
        )}
      </Row>

      {modalType === 'pending' || !shouldRenderForStaff ? (
        <ButtonContainer>
          <RescheduleButton onClick={handleRescheduleButtonClick}>
            {loadingReschedule ? (
              <FilterLoading color="#009291" />
            ) : (
              'Reagendar'
            )}
          </RescheduleButton>
          <ConfirmedButton onClick={handleDetailsButtonClick}>
            {loadingDetails ? <FilterLoading /> : detailsButtonText}
          </ConfirmedButton>
        </ButtonContainer>
      ) : (
        <DetailsButton onClick={handleDetailsButtonClick}>
          {loadingDetails ? <FilterLoading /> : 'Detalhes da mentoria'}
        </DetailsButton>
      )}

      {showFlagAlert &&
      item?.status === 'reschedule_request' &&
      item?.rescheduled_by !== 'student' ? (
        <Flag>
          <CircleAlert color={'#f09d51'} size={16} />
          Requer resposta do estudante
        </Flag>
      ) : (
        <CanceledButton onClick={handleCancelButtonClick}>
          {buttonText}
        </CanceledButton>
      )}
    </Container>
  );
}
