import React, { Fragment, useRef, useState, useEffect} from "react";
import pdf from '../../assets/img/pdfFile.png';
import DeleteIcon from '@material-ui/icons/Delete';

import {getCompanyInvitesFilesUtils} from '../../services/university/companyInvite.jsx'

import {
  DropFileInputStyled,DropFile, 
  DropFilePreview, 
  ContainerWrapper,
  PreviewItem,
  MessageError,
  ContainerStyled,
  Main
} from './styles/DropFileInputStyles'
import LoadingUpdate from "../LoadingUpdate/LoadigUpdate";


export default function DropFileInput ({ isRead, valueMandatoryInternship, 
  valueNonMandatoryInternship, setFile, company, isEdit,setIsAgreementDocumentOneFileExternal,
  setIsAgreementDocumentTwoFileExternal, requiredDocument, setRequiredDocument, 
  saveDocument, setSaveDocument }) {
    
    const [agreementDocumentOneFile,setAgreementDocumentOneFile] = useState({})
    const [agreementDocumentTwoFile, setAgreementDocumentTwoFile] = useState({})
    const [isAgreementDocumentOneFile, setIsAgreementDocumentOneFile] = useState((agreementDocumentOneFile.file && agreementDocumentOneFile.file !== null) ? true : false)
    const [isAgreementDocumentTwoFile, setIsAgreementDocumentTwoFile] = useState((agreementDocumentTwoFile.file && agreementDocumentTwoFile.file !== null) ? true : false)
    
    const wrapperRef = useRef(null);

    const [alertType, setAlertType] = useState(false);
    const [alertSize, setAlertSize] = useState(false);

    const [loadingPDF, setLoadingPDF] = useState(false)

    useEffect(() => {
      if(isEdit && (!isAgreementDocumentOneFile || !isAgreementDocumentTwoFile)){
        getFiles(company)
      }
    }, []);

    useEffect(() => {
      const isAgreementDocumentOneFile = (agreementDocumentOneFile.file && agreementDocumentOneFile.file !== null) ? true : false
      const isAgreementDocumentTwoFile = (agreementDocumentTwoFile.file && agreementDocumentTwoFile.file !== null) ? true : false 
      setIsAgreementDocumentOneFile(isAgreementDocumentOneFile)
      setIsAgreementDocumentTwoFile(isAgreementDocumentTwoFile)
      setIsAgreementDocumentOneFileExternal && setIsAgreementDocumentOneFileExternal(isAgreementDocumentOneFile)
      setIsAgreementDocumentTwoFileExternal && setIsAgreementDocumentTwoFileExternal(isAgreementDocumentTwoFile)
    }, [agreementDocumentOneFile,agreementDocumentTwoFile]);

    useEffect(() => {
      let files = {
        agreementDocumentOneFile: null,
        agreementDocumentTwoFile: null,
        fileUpdate:{
          agreement_document_one_file:false,
          agreement_document_two_file:false
        }
      };
      if(isAgreementDocumentOneFile){
        files.agreementDocumentOneFile = agreementDocumentOneFile.file
      };
      files.fileUpdate.agreement_document_one_file = agreementDocumentOneFile.is_edit
      if(isAgreementDocumentTwoFile){
        files.agreementDocumentTwoFile = agreementDocumentTwoFile.file        
      };
      files.fileUpdate.agreement_document_two_file = agreementDocumentTwoFile.is_edit

      setFile(files)

    },[isAgreementDocumentOneFile,isAgreementDocumentTwoFile]);

    useEffect(() => {
      if((!valueMandatoryInternship || !valueNonMandatoryInternship) &&
      (!isAgreementDocumentOneFile && !isAgreementDocumentTwoFile)) {
        setAlertType(false)
        setAlertSize(false)
        setRequiredDocument(false)
      }

    }, [valueMandatoryInternship, valueNonMandatoryInternship ])

    const getFiles = async (company) => {
      setLoadingPDF(true)
      let params = `action=download&document=all&companyinviteid=${company.id}` 
      const response = await getCompanyInvitesFilesUtils(params)
      if(response.agreement_document_one_file && response.agreement_document_one_file !== null){
        let newFileAgreementDocumentOneFileTemp = {
          file: new File([response.agreement_document_one_file.value],
                response.agreement_document_one_file.file_name, {type: "application/pdf"}),
          is_edit:false
        }
        setAgreementDocumentOneFile(newFileAgreementDocumentOneFileTemp)
      }
      if(response.agreement_document_two_file && response.agreement_document_two_file !== null){
        let newFileAgreementDocumentTwoFileTemp = {
          file:new File([response.agreement_document_two_file.value],
                response.agreement_document_two_file.file_name, {type: "application/pdf"}),
          is_edit:false
        }
        setAgreementDocumentTwoFile(newFileAgreementDocumentTwoFileTemp)
      }
      setLoadingPDF(false)      
    };

    const onFileDrop = (e, file) => {
      if (requiredDocument == true) {
        setRequiredDocument(false)
      } 

      if (isAgreementDocumentOneFile && isAgreementDocumentTwoFile){
        return;
      }
      const newFile = e.target.files[0];

      if(!newFile) {
        return
      }

      if(newFile.type !== "application/pdf"){
        setAlertType(true);
        return;
      } else {
        setAlertType(false);
      }
      
      let sizeFiles = newFile.size;
      if (isAgreementDocumentOneFile) {
        sizeFiles = sizeFiles + agreementDocumentOneFile.file.size
      } 
      if (isAgreementDocumentTwoFile) {
        sizeFiles = sizeFiles + agreementDocumentTwoFile.file.size
      }   
      if (sizeFiles > 20971520){           
        setAlertSize(true)
        return;         
      } else {
        setAlertSize(false)
      }

    let agreementDocumentOneFileTemp = agreementDocumentOneFile;
    let agreementDocumentTwoFileTemp = agreementDocumentTwoFile;
    if(!isAgreementDocumentOneFile){
      agreementDocumentOneFileTemp = {
        file : newFile,
        is_edit : true
      };
      setAgreementDocumentOneFile(agreementDocumentOneFileTemp)
      return;
    };
    if(!isAgreementDocumentTwoFile){
      agreementDocumentTwoFileTemp = {
        file : newFile,
        is_edit : true
      };
      setAgreementDocumentTwoFile(agreementDocumentTwoFileTemp)
      return;
    };
  }
    
    const handleDelete = (file) => {
      setAlertSize(false);
      setAlertType(false);
      
      let fileDelete = {}
      fileDelete.file = null
      fileDelete.is_edit = true
      if(document.getElementById('upload'))
      document.getElementById('upload').value = null;

      if(saveDocument == true) {
        setSaveDocument(false)
      }
      
      return fileDelete

    };


    const onDragEnter = () => wrapperRef.current.classList.add('dragover')
    const onDragLeave = () => wrapperRef.current.classList.remove('dragover')
    const onDrop = () => wrapperRef.current.classList.remove('dragover')
      
  return(
    <Main>
      {loadingPDF ? <LoadingUpdate /> 
      : (
        <ContainerStyled>
          {
            valueMandatoryInternship == true ||
            valueNonMandatoryInternship == true ?
            (
              <DropFile>
                <strong> Upload do Convênio </strong>
                {!(isAgreementDocumentOneFile && isAgreementDocumentTwoFile) &&(
                <ContainerWrapper
                  ref={wrapperRef}
                  className="drop-file-input"
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}
                >
                <DropFileInputStyled>
                  <div className="drop-file-input_label">
                    <span> Clique aqui ou arraste um arquivo... </span>
                  </div>
                  <input 
                    type="file" 
                    name="pdfFile"
                    id="upload"
                    accept=".pdf"
                    onChange={onFileDrop}
                    disabled={isRead}
                    visible={false}
                  />
                  </DropFileInputStyled>
                </ContainerWrapper>)}
              </DropFile>
            ) : null
          }
          {
            agreementDocumentOneFile.file && agreementDocumentOneFile.file.name ? (  
              <DropFilePreview>         
                <div className="drop-file-preview_title">
                  <img src={pdf} alt="Pdf file" />
                  <PreviewItem>
                    <p> {agreementDocumentOneFile.file.name} </p>
                    <div>
                    {!isRead &&
                      (<DeleteIcon
                        style={{
                          color: '#999999',
                          cursor: 'pointer',
                          marginLeft: '0.5em',
                        }}
                        onClick={() => setAgreementDocumentOneFile(handleDelete(agreementDocumentOneFile))}
                      />)}
                      </div>
                  </PreviewItem>
                </div>
              </DropFilePreview>
            ): null }
            {
              agreementDocumentTwoFile.file && agreementDocumentTwoFile.file.name ? (  
                <DropFilePreview>            
                  <div className="drop-file-preview_title">
                    <img src={pdf} alt="Pdf file" />                          
                    <PreviewItem>                            
                      <p> {agreementDocumentTwoFile.file.name} </p>
                      <div>
                      {!isRead &&
                        (<DeleteIcon
                          style={{
                            color: '#999999',
                            cursor: 'pointer',
                            marginLeft: '0.5em',
                          }}
                          onClick={() => setAgreementDocumentTwoFile(handleDelete(agreementDocumentTwoFile))}
                        />)}
                        </div>
                    </PreviewItem>
                  </div>
                </DropFilePreview>
            ): null }
        </ContainerStyled>
      )}

          {
            alertType == true ? (
              <MessageError>
              <p> Desculpe, somente arquivos do tipo PDF são permitidos. </p>
              </MessageError>
            ) : null
          }
          {
            alertSize == true ? (
              <MessageError>
                <p> 
                  Ops, o tamanho do arquivo é superior ao permitido, selecione um arquivo inferior à 20mb!
                </p>
              </MessageError>

            ) : null
          }
          {
            requiredDocument == true ? (
              <MessageError>
                <p> 
                  Por gentileza, para continuar anexe um documento.
                </p>
              </MessageError>
            ) : null
          }
    </Main>
  )
}