import { SkeletonBox } from '../../../style';
import { ContainerSkeleton } from './style';

export default function SkeletonStepThree() {
  return (
    <ContainerSkeleton>
      <div className="checkboxContainer">
        <span className="checkbox">
          <SkeletonBox width={'14px'} height={'14px'} />
        </span>
        <span className="name">
          <SkeletonBox width={'150px'} height={'14px'} />
        </span>
      </div>
      <div className="checkboxContainer">
        <span className="checkbox">
          <SkeletonBox width={'14px'} height={'14px'} />
        </span>
        <span className="name">
          <SkeletonBox width={'150px'} height={'14px'} />
        </span>
      </div>
    </ContainerSkeleton>
  );
}
