import BaseLayout from 'layouts/BaseLayout';
import { TabButton, FlexContainer } from './style';
import { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import ReactHtmlParser from 'react-html-parser';

export function GenericInternalSwitch(props) {
  const { tabs, handleChange, activeTab, disabled } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <FlexContainer>
      {tabs &&
        tabs?.length > 1 &&
        tabs.map((item, index) => (
          <TabButton
            universityColor={universityColor}
            active={activeTab === index}
            onClick={() => handleChange(index)}
          >
            {ReactHtmlParser(item)}
          </TabButton>
        ))}
    </FlexContainer>
  );
}

GenericInternalSwitch.defaultProps = {
  tabs: ['Aba 1', 'Aba 2'],
  handleChange: () => {},
  activeTab: 0,
};
