import React from 'react';
import { names } from '../constantNamesReports';
import { formatNumber } from 'utils/formatNumber';

import {
  Title,
  SubTitle,
  ContainerInfoStudents,
  Divider,
  ContainerTitleInfos,
  ContainerJob,
  SubTitleInfos,
} from '../styled';

function GenericContainerReportsMarket(props) {
  const {
    title,
    directJobsCourses,
    indirectJobsCourses,
    inviteBySystemCompany,
    inviteByUniversity,
    totalCompanies,
  } = props;

  const titles = {
    companies_connected: 'Vagas das empresas conectadas',
    companies_disconnected: 'Vagas das empresas desconectadas',
  };

  return (
    <div>
      <ContainerTitleInfos>
        <ContainerInfoStudents>
          <Title>{names[title]}</Title>

          <Title>{formatNumber(totalCompanies)}</Title>
        </ContainerInfoStudents>

        <ContainerJob ContainerJob>
          <ContainerInfoStudents>
            <SubTitle subTitle isWidth>
              Convites enviados pela sua instituição:{' '}
              {formatNumber(inviteByUniversity)}
            </SubTitle>
            <SubTitle
              subTitle
              isMarket
              style={{
                marginLeft: '56px',
              }}
            >
              Empresas do sistema: {formatNumber(inviteBySystemCompany)}
            </SubTitle>
          </ContainerInfoStudents>
        </ContainerJob>

        <ContainerInfoStudents>
          <SubTitleInfos>{titles[title]}</SubTitleInfos>
        </ContainerInfoStudents>

        <ContainerJob ContainerJob>
          <ContainerInfoStudents>
            <SubTitle subTitle isWidth>
              Cadastraram vagas diretas para o(s) curso(s) selecionado(s):{' '}
              {formatNumber(directJobsCourses)}
            </SubTitle>
            <SubTitle
              subTitle
              style={{
                marginLeft: '54px',
              }}
            >
              Cadastraram vagas sem definição de curso:{' '}
              {formatNumber(indirectJobsCourses)}
            </SubTitle>
          </ContainerInfoStudents>
        </ContainerJob>
      </ContainerTitleInfos>
      <Divider />
    </div>
  );
}

export default GenericContainerReportsMarket;
