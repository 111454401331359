import React from 'react';
import {
  BoxInformation,
  ContactInformation,
  Container,
  ContainerContact,
  ContainerInformation,
  Divider,
} from '../EnrolledStudents/styled';
import { ImageSkeleton, SkeletonBox } from './styled';

export default function EnrolledStudentsSkeleton() {
  return (
    <Container>
      <div className="wrapper">
        <ImageSkeleton />
        <ContainerInformation>
          <BoxInformation
            style={{
              marginLeft: '2rem',
              height: '100%',
            }}
          >
            <SkeletonBox width="3rem" height={'26px'} />
            <SkeletonBox width="300px" height={'18px'} marginTop="0.5rem" />
            <SkeletonBox width="260px" height={'14px'} marginTop="0.5rem" />
            <ContainerContact>
              <ContactInformation>
                <SkeletonBox width="14px" height={'14px'} marginTop="0.5rem" />
                <SkeletonBox
                  width="90px"
                  height={'14px'}
                  marginTop="0.5rem"
                  marginLeft="0.25rem"
                />
              </ContactInformation>
              <ContactInformation>
                <SkeletonBox width="14px" height={'14px'} marginTop="0.5rem" />
                <SkeletonBox
                  width="150px"
                  height={'14px'}
                  marginTop="0.5rem"
                  marginLeft="0.25rem"
                />
              </ContactInformation>
            </ContainerContact>
          </BoxInformation>
          <SkeletonBox width="148px" height={'34px'} marginRight={'34px'} />
        </ContainerInformation>
      </div>
      <Divider />
    </Container>
  );
}
