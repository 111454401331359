import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;

export const Image = styled.img`
  width: 210px;
`;

export const ContainerInformation = styled.div`
  border-radius: 4px;
  padding: 43px;
  margin-top: -40px;

  h3 {
    font-weight: bold;
    color: #009291;
  }

  p {
    max-width: 220px;
  }
`;
