import React, { useContext, useEffect } from 'react';
import FilterModal from 'components/FilterModal';
import { FilterGrid } from './styled';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';

export function LearningFilters({
  activeInternalTab,
  filterOptions,
  filterState,
  setFilterState,
  setQueryFilter,
  activeTab,
}) {
  const {
    loadingFilters,
    setLearnings,
    setFavoritesLearning,
    setFinalizedLearning,
    appliedFilters,
    setAppliedFilters,
  } = useContext(LearningContext);

  const {
    getAvailableLearnings,
    getFavoritesLearnings,
    getFinalizedLearnings,
  } = useAPICallsLearning();

  useEffect(() => {
    setFilterState({});
  }, [activeInternalTab, activeTab]);

  function renderFilterContent() {
    return [
      <FilterGrid>
        <div>
          <Filter
            label={'Filtrar por nome'}
            placeholder={'Digite aqui...'}
            name={'name'}
            type={FilterType.TEXT}
          />
        </div>
        <div>
          <Filter
            label={'Conhecimento relacionado'}
            name={'competences_specialities'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.competences_specialities}
          />
        </div>
        <div>
          <Filter
            label={'Tipo de Aprendizagem'}
            name={'type'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.type}
          />
        </div>
        <div>
          <Filter
            label={'Exibir somente atividades complementares'}
            name={'validity'}
            type={FilterType.SINGLE_CHECKBOX}
          />
        </div>
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const existFilters = filters && Object.keys(filters).length > 0;

    if (!existFilters) {
      return onClearFilters();
    }

    if (existFilters && activeInternalTab === 0) {
      const queryString = queryParamsFromFilterObject(filters);
      setQueryFilter(queryString);
      setLearnings(null);
      getAvailableLearnings(false, _, queryString);
    }

    if (existFilters && activeInternalTab === 1) {
      const queryString = queryParamsFromFilterObject(filters);
      setQueryFilter(queryString);
      setLearnings(null);
      getAvailableLearnings(false, _, queryString);
    }

    if (existFilters && activeInternalTab === 2) {
      const queryString = queryParamsFromFilterObject(filters);
      setQueryFilter(queryString);
      setFavoritesLearning(null);
      getFavoritesLearnings(false, _, queryString);
    }

    if (existFilters && activeInternalTab === 3) {
      const queryString = queryParamsFromFilterObject(filters);
      setQueryFilter(queryString);
      setFinalizedLearning(null);
      getFinalizedLearnings(false, _, queryString);
    }
  }

  async function onClearFilters() {
    if (activeInternalTab === 1) {
      setQueryFilter(null);
      setLearnings(null);
      getAvailableLearnings(true);
    }

    if (activeInternalTab === 2) {
      setQueryFilter(null);
      setFavoritesLearning(null);
      getFavoritesLearnings(true);
    }

    if (activeInternalTab === 3) {
      setQueryFilter(null);
      setFinalizedLearning(null);
      getFinalizedLearnings(true);
    }
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loadingFilters}
      zIndex={9999999999}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
