import { authenticatedAPI } from 'services/api';

const changePassword = async (data) => {
  const response = await authenticatedAPI
    .post('/api/internal/students/me/change-password', data)
    .then((res) => res)
    .catch((error) => error.response);
  return response;
};

export { changePassword };
