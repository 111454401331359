import React, { Fragment, useEffect, useState } from 'react';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import backgroundMentoring from 'assets/img/mentorship-background.jpg';
import { MentorsTab } from './MentorsTab';
import { NextMentorshipTab } from './NextMentorshipsTab';
import { HistoricTab } from './HistoricMentorshipTab';
import { useMentorship } from '../contexts/MentorshipContext';
import { Content } from '../styles';

export default function MentorshipContent({ handleIsLoadingState }) {
  const [activeTab, setActiveTab] = useState(0);
  const { fetchMentorshipsCount, mentorshipCounts } = useMentorship();

  const handleTabChange = (event, value) => {
    setActiveTab(value);
    fetchMentorshipsCount();
  };

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <Fragment>
      <Content>
        <CompanyViewsHeader
          title="Gestão de Mentoria"
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          backgroundImage={backgroundMentoring}
          tabs={[
            `Próximas Mentorias (${mentorshipCounts.mentorships ?? 0})`,
            `Histórico (${mentorshipCounts.historic ?? 0})`,
            `Mentores (${mentorshipCounts.mentors ?? 0})`,
          ]}
        />
        {activeTab === 0 && <NextMentorshipTab />}
        {activeTab === 1 && <HistoricTab />}
        {activeTab === 2 && (
          <MentorsTab fetchMentorshipsCount={fetchMentorshipsCount} />
        )}
      </Content>
    </Fragment>
  );
}
