import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import RadioButton from './radio';
import { getVisibleAttraction } from 'services/university/events';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function ButtonVisibleLearnings({ learningID, learnings }) {
  const [optionValue, setOptionValue] = useState('');
  const [options, setOptions] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    if (learnings.analysis) {
      setOptionValue(learnings.analysis);
      formRef.current.setFieldValue('analysis', learnings.analysis);
    }
  }, [learnings]);

  const submitForm = async (data) => {
    await getVisibleAttraction(learningID, data);
  };

  const handleChange = async (e) => {
    const newValue = optionValue === 'enabled' ? 'disabled' : 'enabled';
    setOptionValue(newValue);

    formRef.current.setFieldValue('analysis', newValue);
    formRef.current.submitForm();
    if (newValue === 'disabled') {
      customSnackbar('Aprendizagem ocultada com sucesso!', 'confirmation')
    }
    if (newValue === 'enabled') {
      customSnackbar('A Aprendizagem está visível para seus estudantes!', 'confirmation')
    }
  };

  useEffect(() => {
    if (optionValue === 'enabled') {
      setOptions(
        [
          {
            id: Math.floor(Math.random() * 1000),
            value: 'enabled',
            label: 'sim',
            type: 'radio',
            bubble: 'Ocultar para estudante',
          },
        ]
      )
    } else {
      setOptions(
        [
          {
            id: Math.floor(Math.random() * 1000),
            value: 'disabled',
            label: 'não',
            type: 'radio',
            bubble: 'Mostrar para estudante',
          },
        ]
      )
    }
  }, [optionValue]);

  return (
    <Form ref={formRef} onSubmit={submitForm}>
      <div style={{ display: 'flex' }}>
        <RadioButton
          name="analysis"
          options={options}
          onChange={handleChange}
          optionValue={optionValue}
        />
      </div>
    </Form>
  );
}