/**
 * Function that formats a number to the brazilian format.
 * @param  {number} number Number to be formated.
 * @return {string} Number formated.
 */
function formatNumber(number) {
  return new Intl.NumberFormat('pt-BR').format(number);
}

export { formatNumber };
