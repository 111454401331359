import React, { useState, useEffect } from 'react';
import { object, func, bool, number, string, shape } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import {
  getAreasOptions,
  getAreaDetails,
  editArea,
  saveArea,
  checkName,
} from 'services/backoffice/areas';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SearchableSelect from 'react-select';
import Button from 'components/CustomButtons/Button';
import {
  FormHelperText,
  List,
  ListItem,
  TextField,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import AreaDetail from './AreaDetail';

function AreaForm(props) {
  const {
    classes,
    loadingState,
    openSnackbar,
    isEdit,
    areaId,
    closeModal,
    openModal,
    updateData,
    areaType,
    professionId,
    shouldReturnProfessionForm,
    setSaveFormAndOpenProfessions,
    isCourse,
    setsaveFormAndOpenCourses,
  } = props;

  const [initialValues, setInitialValues] = useState({});
  const [options, setOptions] = useState({});
  const [errorMessage, setErrorsMessage] = useState(false);
  const [inputError, setInputError] = useState(false);

  const AreaRegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Você deve informar o nome da área.')
      .test('name', 'Já existe uma área com este nome.', async (value) => {
        const responseCheck = await checkName(value);
        const isNameValid = !(value && responseCheck.exists === true);
        setInputError(!isNameValid);
        return isNameValid;
      }),
    subgroup: Yup.string().required('É obrigatório selecionar o subgrupo.'),
  });

  const handleValues = async ({
    name,
    subgroup,
    description,
    classification,
  }) => {
    return {
      name,
      subgroup: subgroup && subgroup.value,
      description,
      classification_id: classification && classification.value,
    };
  };

  const handleResponseError = (message) => {
    openSnackbar(message, true);
    loadingState(false);
    closeModal();
  };

  const fetchAreaData = async () => {
    const response = await getAreaDetails(areaId);

    if (response.message) {
      handleResponseError(
        'Falha ao carregar as informações! Tente novamente mais tarde.'
      );
    } else {
      // Add class here
      setInitialValues({
        name: response.name,
        subgroup: response.subgroup,
        description: response.description,
        classification: response.classification,
      });
    }
  };

  const fetchOptionsData = async () => {
    const response = await getAreasOptions();

    if (response.message) {
      handleResponseError(
        'Falha ao carregar as informações! Tente novamente mais tarde.'
      );
    } else {
      setOptions(response);
      if (isEdit) {
        await fetchAreaData();
      } else if (areaType) {
        const index = response.subgroup.findIndex(
          (item) => item.value === areaType
        );
        setInitialValues({
          subgroup: response.subgroup[index],
        });
      } else {
        setInitialValues({
          subgroup: response.subgroup[0],
        });
      }
      loadingState(false);
    }
  };

  const handleEdit = async (values) => {
    loadingState(true);
    const response = await editArea(await handleValues(values), areaId);

    if (response.message) {
      loadingState(false);
      handleResponseError(
        'Falha ao tentar editar essa área! Tente novamente mais tarde.'
      );
    } else {
      await updateData(true);
      openSnackbar('Alterações salvas!');
      closeModal();
      openModal(
        <AreaDetail
          loadingState={loadingState}
          openSnackbar={openSnackbar}
          data={response}
        />,
        response.name
      );
    }
  };

  const handleBlurName = async (e) => {
    if (e.target.value === '') {
      setErrorsMessage('Você deve informar o nome da área.');
      setInputError(true);
      return;
    }

    const responseCheck = await checkName(e.target.value);
    if (responseCheck.exists === true) {
      setErrorsMessage('Já existe uma área com este nome.');
      setInputError(true);
      return;
    } else {
      setErrorsMessage(false);
      setInputError(false);
    }
  };

  const handleAreaSave = async (values) => {
    loadingState(true);
    const response = await saveArea(await handleValues(values));

    if (response.message) {
      loadingState(false);
      handleResponseError(
        'Falha ao tentar salvar essa área! Tente novamente mais tarde.'
      );
    } else {
      await updateData(true);
      openSnackbar('Área cadastrada!');

      if (isCourse) {
        if (professionId !== undefined) {
          setsaveFormAndOpenCourses({ id: professionId, response });
        } else {
          setsaveFormAndOpenCourses({ id: null, response });
        }
        closeModal();
        return;
      }

      if (shouldReturnProfessionForm) {
        if (professionId !== undefined) {
          setSaveFormAndOpenProfessions({ id: professionId, response });
        } else {
          setSaveFormAndOpenProfessions({ id: null, response });
        }
      } else {
        closeModal();
      }
      closeModal();
      loadingState(false);
    }
  };

  useEffect(() => {
    loadingState(true);
    fetchOptionsData();
  }, []);

  return (
    <GridContainer justify="center">
      <GridItem>
        {Object.keys(options).length > 0 && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={AreaRegistrationSchema}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              if (initialValues === values) {
                actions.setSubmitting(false);
                closeModal();
              } else if (isEdit) {
                handleEdit(values);
              } else {
                handleAreaSave(values);
              }
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <List className={classes.list}>
                  {/* Name */}
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <FastField
                      autoFocus
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                        shrink: values.name,
                      }}
                      variant="filled"
                      name="name"
                      id="name"
                      label="Nome"
                      placeholder="Ex.: ReactJS"
                      onChange={handleChange}
                      onBlur={(e) => handleBlurName(e)}
                      error={inputError === false}
                      value={values.name}
                      component={TextField}
                      disabled={isSubmitting}
                    />
                    {errorMessage && (
                      <FormHelperText className={classes.helperText}>
                        {errorMessage !== false && errorMessage}
                      </FormHelperText>
                    )}
                  </ListItem>
                  {/* Class */}
                  <ListItem>
                    <FormControl
                      variant="filled"
                      fullWidth
                      className={classes.professionListItem}
                    >
                      <InputLabel
                        htmlFor="class"
                        shrink
                        error={errors.class && touched.class}
                      >
                        Classificação
                      </InputLabel>
                      <SearchableSelect
                        name="classification"
                        id="classification"
                        InputLabel={{ shrink: true }}
                        placeholder="Selecione"
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                          classes.selectSectionCard,
                        ])}
                        onBlur={() => {
                          setFieldTouched('classification');
                        }}
                        onChange={(event) =>
                          setFieldValue('classification', event)
                        }
                        options={options.classification}
                        value={values.classification}
                      />
                    </FormControl>
                    {errors.classification && touched.classification && (
                      <FormHelperText className={classes.helperText}>
                        {errors.classification}
                      </FormHelperText>
                    )}
                  </ListItem>

                  {/* Subgroup */}
                  <ListItem className={classes.professionListItem}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      className={classes.coursesDropdownHolder}
                    >
                      <InputLabel
                        htmlFor="subgroup"
                        shrink
                        error={errors.subgroup && touched.subgroup}
                      >
                        Tipo de Área
                      </InputLabel>
                      <SearchableSelect
                        name="subgroup"
                        id="subgroup"
                        InputLabel={{
                          shrink: true,
                        }}
                        placeholder="Selecione"
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                          classes.selectSectionCard,
                        ])}
                        onBlur={() => {
                          setFieldTouched('subgroup');
                        }}
                        onChange={(event) => setFieldValue('subgroup', event)}
                        options={options.subgroup || []}
                        value={values.subgroup}
                      />
                    </FormControl>
                    {errors.subgroup && touched.subgroup ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.subgroup}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                  {/* Description */}
                  {values.subgroup &&
                    values.subgroup.value === 'market_tendencies' && (
                      <ListItem
                        className={classNames([classes.professionListItem])}
                      >
                        <FastField
                          className={classes.inputField}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            FormLabelClasses: {
                              focused: classes.inputField,
                            },
                            shrink: values.name,
                          }}
                          name="description"
                          label="Descrição"
                          id="description"
                          placeholder="Descrição completa"
                          variant="filled"
                          multiline
                          rowsMax="15"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description}
                          component={TextField}
                          fullWidth
                          disabled={isSubmitting}
                        />
                      </ListItem>
                    )}
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className={classes.saveButton}
                        round
                      >
                        Salvar
                      </Button>
                    </div>
                  </ListItem>
                </List>
              </form>
            )}
          />
        )}
      </GridItem>
    </GridContainer>
  );
}

AreaForm.propTypes = {
  classes: shape({}).isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  closeModal: func.isRequired,
  openModal: func.isRequired,
  professionId: number,
  editProfession: func,
  addProfession: func,
  shouldReturnProfessionForm: bool,
  isEdit: bool,
  areaId: number,
  updateData: func,
  areaType: string,
  setSaveFormAndOpenProfessions: func,
};
export default withStyles(ProfessionalExperienceFormStyles)(AreaForm);
