import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import withStyles from '@material-ui/core/styles/withStyles';
import { element, bool, func, string } from 'prop-types';
import { DialogActions, StyledButton } from './style';

const dnaDialogStyle = () => ({});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const DnaDialog = (props) => {
  /** Function to OpenDialog to make decisions or Warning user for some motive
   *  @param {string} dialogContent
   *  @param {string} dialogTitle
   *  @param {func} dialogClose
   *  @param {func} dialogCallback function to be executed if need to take an action on dialog
   *  @param {func} closeCallback
   *  @param {string} buttonLabel
   *  @param {string} closeButtonLabel
   *  @param {string} universityColor
   */
  const {
    buttonLabel,
    closeButtonLabel,
    dialogCallback,
    closeDialog,
    dialogTitle,
    dialogContent,
    isOpen,
    closeCallback,
    universityColor,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (closeCallback) {
          closeCallback();
          setTimeout(() => closeDialog(), 700);
          return;
        }
        closeDialog();
      }}
      TransitionComponent={Transition}
    >
      <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledButton
          onClick={() => {
            if (closeCallback) {
              closeCallback();
              setTimeout(() => closeDialog(), 700);
              return;
            }
            closeDialog();
          }}
          universityColor={universityColor || '#009291'}
        >
          {closeButtonLabel ? closeButtonLabel : 'Fechar'}
        </StyledButton>
        {buttonLabel && (
          <StyledButton
            onClick={() => {
              dialogCallback();
              setTimeout(() => closeDialog(), 700);
            }}
            universityColor={universityColor || '#009291'}
          >
            {buttonLabel}
          </StyledButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

DnaDialog.propTypes = {
  dialogTitle: string,
  dialogContent: element || string,
  isOpen: bool,
  buttonLabel: string,
  closeButtonLabel: string,
  closeDialog: func.isRequired,
  dialogCallback: func,
  closeCallback: func,
};
export default withStyles(dnaDialogStyle)(DnaDialog);
