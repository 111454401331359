import React from 'react';
import VectorArrowBackSvg from '../../UIComponents/VectorArrowBack';

import {
  ContainerLogin,
  Titles,
  ContainerWorkalove,
  FragmentContainer,
  ContainerTitlesEmail,
  CircleEmail,
  ContainerButtonBack,
  ParagraphEmail,
  ContainerParagraphs,
  ContainerButtonArrow,
} from '../../styles';
import { MessageEmailIcon } from 'assets/img/MessageEmailIcon';

export default function EmailPassword(props) {
  const {
    setContentLogin,
    setModalEmailSent,
    email,
    setEmail,
    universityColor,
  } = props;

  function handleBackLogin() {
    setModalEmailSent(false);
    setContentLogin(true);
    setEmail(null);
  }

  const emailTransform = (email) => {
    if (email) {
      return `${email?.substr(0, 3)}******${email.substring(
        email.indexOf('@')
      )}`;
    } else {
      return 'cadastrado';
    }
  };

  return (
    <ContainerLogin recoverPassword>
      <FragmentContainer>
        <ContainerButtonArrow>
          <ContainerButtonBack onClick={handleBackLogin}>
            <VectorArrowBackSvg color={universityColor || '#009291'} />
          </ContainerButtonBack>
        </ContainerButtonArrow>
        <ContainerTitlesEmail recoverPassword>
          <CircleEmail universityColor={universityColor || '#009291'}>
            <MessageEmailIcon color={universityColor || '#009291'} />
          </CircleEmail>
          <Titles
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              lineHeight: '27px',
            }}
          >
            Tudo certo!
          </Titles>
          <ContainerParagraphs>
            <ParagraphEmail>
              Encaminhamos para o e-mail {emailTransform(email)} as instruções
              para a recuperação da sua senha!
            </ParagraphEmail>
            <ParagraphEmail>
              Caso você não tenha mais acesso a este e-mail, entre em contato
              com suporte@workalove.com e solicite a atualização dos dados no
              sistema!
            </ParagraphEmail>
          </ContainerParagraphs>
        </ContainerTitlesEmail>
      </FragmentContainer>
      <ContainerWorkalove></ContainerWorkalove>
    </ContainerLogin>
  );
}
