import React, { useEffect, useRef, Fragment } from 'react';

import { useField } from '@unform/core';
import { UnInput, UnInputLabel } from '../../Styles/unformComponentsStyled';
import { ContainerLabelInput } from '../../Styles/formStyled';

import { isYoutubeVideo } from "utils/validation";

export default function InputReplaceLink({ label, name, setModalOpen, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        let url = inputRef.current.value; 
        let codVideo = isYoutubeVideo(url); 
        let urlValid = 'https://youtu.be/' + codVideo

        if (url === '') {
          return ''
        } 
        if (codVideo === false ) {
          setModalOpen(true)
        }
        else {
          setModalOpen(false)
          return urlValid
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput ref={inputRef} onFocus={clearError} defaultValue={defaultValue}  {...rest} />
      {error && <span style={{ color: '#F00', marginTop: '-10px' }}>{error}</span>}
    </ContainerLabelInput>
  );
}
