import React, {createContext, useState, useEffect} from 'react'

export const CompaniesManagementContext = createContext();

export default function CompaniesManagementProvider ({children}) {
  const [listConnectedCompanies, setListConnectedCompanies] = useState(null);
  const [listDisconnectCompanies, setListDisconnectCompanies] = useState(null)

  const [inviteCreated, setInviteCreated] = useState(null)
  const [inviteCreatedCount, setInviteCreatedCount] = useState(null)

  const [inviteSent, setInviteSent] = useState(null)
  const [inviteSentCount, setInviteSentCount] = useState(null)

  const [inviteAnswered, setInviteAnswered] = useState(null)
  const [inviteAnsweredCount, setInviteAnsweredCount] = useState(null)

  const [canceledInvite, setCanceledInvite] = useState(null);
  const [canceledInviteCount, setCanceledInviteCount] = useState(null);

  const [modalDisconnect, setModalDisconnect] = useState(false);
  const [usingModalUpload, setUsingModalUpload] = useState(false);

  const [documentFiles, setDocumentFiles] = useState([]);

  const [documentOne, setDocumentOne] = useState({})
  const [documentTwo, setDocumentTwo] = useState({})

  const [idPostDocument, setIdPostDocument] = useState(0);

  const [companiesConnectCount, setCompaniesConnectCount] = useState(0);
  const [companiesDisconnectCount, setCompaniesDisconnectCount] = useState(0)

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const [openModalForm, setOpenModalForm] = useState(false);

  const [formData, setFormData] = useState({})

  const [companiesFilter, setCompaniesFilter] = useState({});

  const [loading, setLoadingFilter] = useState(false)

  return (
    <CompaniesManagementContext.Provider
      value={{
        documentOne, setDocumentOne,
        documentTwo, setDocumentTwo,
        listConnectedCompanies,
        setListConnectedCompanies,
        modalDisconnect, 
        setModalDisconnect,
        usingModalUpload, 
        setUsingModalUpload,
        documentFiles, 
        setDocumentFiles,
        idPostDocument, 
        setIdPostDocument,
        companiesConnectCount, 
        setCompaniesConnectCount,
        
        currentPage,
        setCurrentPage,
        limit, 
        setLimit,
        listDisconnectCompanies, 
        setListDisconnectCompanies,

        companiesDisconnectCount, 
        setCompaniesDisconnectCount,

        openModalForm, 
        setOpenModalForm,
        documentFiles, 
        setDocumentFiles,
        
        inviteCreated, 
        setInviteCreated,
        inviteCreatedCount, 
        setInviteCreatedCount,

        inviteSent, setInviteSent,
        inviteSentCount, setInviteSentCount,

        inviteAnswered, setInviteAnswered,
        inviteAnsweredCount, setInviteAnsweredCount,

        canceledInvite, setCanceledInvite,
        canceledInviteCount, setCanceledInviteCount,
        formData, setFormData,
        companiesFilter, setCompaniesFilter,
        loading, setLoadingFilter
      }}
    >
      {children}
    </CompaniesManagementContext.Provider>
  )
}