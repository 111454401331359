import React, { useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';

import { Input, ContainerLabel, Label, ButtonEye } from './styles/InputStyles';
import { ErrorMessage } from 'components/Challenges/styleForm';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export default function InputPasswordLogin({
  label,
  name,
  universityColor,
  type,
  value,
  buttonShowPassword,
  ...rest
}) {
  const inputRef = useRef(null);

  const {
    fieldName,
    registerField,
    error,
    defaultValue,
    clearError,
  } = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, newValue) => {
        inputRef.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ContainerLabel>
      {label && (
        <Label universityColor={universityColor} for={name}>
          {label || name}
        </Label>
      )}
      <Input
        ref={inputRef}
        type={showPassword ? 'text' : 'password'}
        defaultValue={defaultValue}
        onFocus={clearError}
        valid={defaultValue !== ''}
        {...rest}
      />
      {buttonShowPassword && (
        <ButtonEye type="button" onClick={() => handleShowPassword()}>
          {showPassword ? (
            <AiOutlineEye size={20} />
          ) : (
            <AiOutlineEyeInvisible size={20} />
          )}
        </ButtonEye>
      )}

      {error && (
        <ErrorMessage marginTop={error && '0px'} order={error}>
          {' '}
          {error}
        </ErrorMessage>
      )}
    </ContainerLabel>
  );
}
