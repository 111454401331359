import { authenticatedAPI } from 'services/api';
import axios from 'axios';

class CurriculumApi {
  baseUrl = `/api/internal/students/docs/curriculum`;
  updateCurriculumOrder = async (order, studentId) => {
    const formData = new FormData();
    formData.append('information_order', JSON.stringify(order));
    return authenticatedAPI
      .put(`${this.baseUrl}/ordination/update/${studentId}`, formData, {})
      .then((res) => res)
      .catch((error) => error);
  };
}

export default new CurriculumApi();
