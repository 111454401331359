import React from 'react';

import { IoIosArrowBack } from 'react-icons/io';
import {
  ContainerInfo,
  Header,
  Container,
  BackButton,
  FragmentColor,
  Box,
  BoxModal,
  ContainerTextLoading,
  ContainerLogoLoading,
  ContainerContentLoading,
} from './styled';

export function SkeletonDetails({
  handleCloseModal,
  universityColor,
  hasIcon,
}) {
  return (
    <BoxModal>
      <Container>
        <ContainerInfo>
          <FragmentColor color={universityColor}>
            <BackButton
              onClick={(e) => handleCloseModal(e)}
              color={universityColor}
            >
              <IoIosArrowBack />
              <p>Voltar</p>
            </BackButton>
          </FragmentColor>
          <Header>
            {hasIcon && <ContainerLogoLoading />}
            <ContainerTextLoading />
          </Header>

          <Box>
            <ContainerContentLoading></ContainerContentLoading>
          </Box>
        </ContainerInfo>
      </Container>
    </BoxModal>
  );
}
