import React, { useContext, useEffect, useRef, useState } from 'react';
import TabJobCompany from '../components/Tabs';
import RegisteredByInstitution from './components/Tabs/RegisteredByInstitution';
import RegisteredByPartners from './components/Tabs/RegisteredByPartners';

import { Container, Content } from './styled';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';
import {
  getHistoryInstitution,
  getHistoryPartners,
  getJobsHistoryFilters,
} from '../../../services';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { formatNumber } from '../../../../../utils/formatNumber';

export default function HistoryJobsTabs(props) {
  const {
    countHistoryPartners,
    countHistoryInstitution,
    countJobs,
    role,
    seeMatch,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [jobsCount, setJobsCount] = useState(0);

  const {
    setCurrentJobFilter,
    currentJobFilter,
    appliedFilters,
    filterState,
    setDataFunctions,
    dataFunction,
    listJobs,
    setListJobs,
  } = useContext(JobOfferContext);

  useEffect(() => {
    setCurrentJobFilter(null);
    setListJobs(null);

    getJobsHistoryFilters().then((data) => {
      setFilterOptions(data);
    });
  }, [activeTab]);

  useEffect(() => {
    if (listJobs !== null || currentJobFilter === null) {
      setListJobs(null);
      loadJobsData(`limit=${limit}&offset=${(currentPage - 1) * limit || 0}`);
    }
  }, [activeTab, currentJobFilter, currentPage, limit]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limit, currentJobFilter, activeTab]);

  const handlePageChange = async (page, queryParams) => {
    const newPage = `limit=${limit}&offset=${
      (page - 1) * limit || 0
    }&${queryParams}`;
    await loadJobsData(newPage);
    await countJobs();
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    setDataFunctions({ handlePageChange });
  }, []);

  const loadJobsData = async (page) => {
    let data;
    const existFilters =
      currentJobFilter && Object.keys(currentJobFilter).length > 0;

    if (existFilters) {
      const queryParams = queryParamsFromFilterObject(currentJobFilter);
      if (activeTab === 0) {
        data = await getHistoryInstitution(`${page}&${queryParams}`);
      } else {
        data = await getHistoryPartners(`${page}&${queryParams}`);
      }
    } else {
      if (activeTab === 0) {
        data = await getHistoryInstitution(page);
      } else {
        data = await getHistoryPartners(page);
      }
    }
    const { results, count } = data;
    setListJobs(results);
    setJobsCount(count);
  };

  const handleTabChange = (_, value) => {
    setActiveTab(value);
    setJobsCount(value === 0 ? countHistoryInstitution : countHistoryPartners);
  };

  const refContainer = useRef(null);

  const ScrollToDiv = () => {
    if (refContainer) {
      refContainer.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    ScrollToDiv();
  }, [currentPage]);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <RegisteredByInstitution
          jobs={listJobs}
          setLimit={setLimit}
          limit={limit}
          onPageChange={onPageChange}
          currentPage={currentPage}
          jobsCount={jobsCount}
          setCurrentPage={setCurrentPage}
          loadJobsData={loadJobsData}
          setListJobsCompanyHistory={setListJobs}
          setJobsCount={setJobsCount}
          companyFilters={currentJobFilter}
          dataFunction={dataFunction}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <RegisteredByPartners
          jobs={listJobs}
          setLimit={setLimit}
          limit={limit}
          onPageChange={onPageChange}
          currentPage={currentPage}
          jobsCount={jobsCount}
          setCurrentPage={setCurrentPage}
          loadJobsData={loadJobsData}
          seeMatch={seeMatch}
          setJobsCount={setJobsCount}
          companyFilters={currentJobFilter}
          dataFunction={dataFunction}
        />
      ),
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  const tabName =
    !role || role !== 'company'
      ? 'Cadastradas pela Instituição'
      : 'Minhas Vagas';

  return (
    <Container ref={refContainer}>
      <Content>
        <TabJobCompany
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={[
            `${tabName} (${
              activeTab === 0
                ? formatNumber(jobsCount)
                : formatNumber(countHistoryInstitution)
            })`,
            // Is commented because this functionality is temporarily disabled - Task 4941
            // `Cadastradas por Parceiros (${
            //   activeTab === 1
            //     ? formatNumber(jobsCount)
            //     : formatNumber(countHistoryPartners)
            // })`,
          ]}
          filterOptions={filterOptions}
          showFilter
        />
        {renderPrincipalTabs(activeTab)}
      </Content>
    </Container>
  );
}
