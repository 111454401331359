export const UniversityHatIcon = (props) => {
  const { size = '15', color = '#0092921' } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3669 2.5509L9.06539 0.501651C8.11853 -0.0639268 6.94001 -0.0739621 5.98364 0.475401L1.63374 2.5509C1.61741 2.55908 1.60049 2.56783 1.58474 2.57715C0.552457 3.16739 0.194089 4.48271 0.784332 5.51499C0.984406 5.86494 1.27871 6.15166 1.63374 6.34258L2.83366 6.91425V9.77258C2.83437 11.0507 3.66604 12.1799 4.88641 12.5598C5.73568 12.8054 6.61629 12.9257 7.50034 12.9168C8.38425 12.9267 9.26486 12.8074 10.1143 12.5627C11.3346 12.1828 12.1663 11.0536 12.167 9.7755V6.91308L13.3337 6.3554V11.1667C13.3337 11.4889 13.5949 11.75 13.917 11.75C14.2392 11.75 14.5003 11.4889 14.5003 11.1667V4.16673C14.5042 3.48174 13.9634 2.84895 13.3669 2.5509ZM11.0003 9.7755C11.0006 10.54 10.5046 11.2162 9.77531 11.4456C9.03577 11.6569 8.26941 11.7595 7.50031 11.7501C6.73122 11.7595 5.96485 11.6569 5.22531 11.4456C4.49603 11.2162 4.00001 10.54 4.00031 9.7755V7.47018L5.93524 8.39185C6.41266 8.67535 6.95792 8.82432 7.51316 8.82293C8.04166 8.82667 8.56128 8.68676 9.01641 8.4181L11.0003 7.47015V9.7755ZM12.867 5.28965L8.46749 7.38965C7.85406 7.74684 7.09371 7.73672 6.48999 7.3634L2.18556 5.3159C1.70576 5.05717 1.52655 4.45848 1.78528 3.97871C1.87278 3.81645 2.00397 3.68195 2.16399 3.5904L6.53606 1.50208C7.14968 1.14568 7.90962 1.15577 8.51356 1.52833L12.8151 3.57758C13.1314 3.75323 13.3293 4.08494 13.3336 4.44675C13.3342 4.78948 13.1577 5.1082 12.867 5.28965Z"
        fill={color}
      />
    </svg>
  );
};
