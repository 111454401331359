import React, { useState, useEffect } from 'react';

import {
  GridContainer,
  ButtonVisualization,
  Icon,
  Container,
  Item,
  LastItem,
  SwitchContainer,
} from './styled';

import Eye from './../../../../../../assets/JobOffer/eye.svg';
import Switch from 'components/CustomSwitch';
import { updateConfigEmail } from '../../services/services';

export default function ListEmail({
  item,
  typeList,
  getActivationSendingEmail,
}) {
  const [isChecked, setIsChecked] = useState(item.enabled);

  const handleChange = (checked) => {
    setIsChecked(checked);
    const data = {};
    data[item.control] = checked;
    updateConfigEmail(data);
  };

  useEffect(() => {
    setIsChecked(item.enabled);
  }, []);

  useEffect(() => {
    setIsChecked(item.enabled);
  }, [item, typeList]);

  return (
    <Container active={item.active}>
      <GridContainer>
        <Item>{item.typeEmail}</Item>
        <Item>{item.period}</Item>
        <LastItem>
          <ButtonVisualization>
            <Icon
              onClick={() => {
                item.fetchPreview();
                getActivationSendingEmail();
              }}
              src={Eye}
            />
            <div className="alert-dialog">
              <span>Visualizar</span>
            </div>
          </ButtonVisualization>
          <SwitchContainer>
            <div className="alert-dialog">
              <span>{isChecked ? 'Desativar' : 'Ativar'}</span>
            </div>
            <Switch checked={isChecked} onChange={handleChange} />
          </SwitchContainer>
        </LastItem>
      </GridContainer>
    </Container>
  );
}
