import React, { Fragment, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';


import JobHeaderTab, {
  Container,
  FilterContainer,
  ButtonCreate,
} from './style';
import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

const InsideTabs = (props) => {
  const { classes, activeTab, handleTabChange, tabs, openSnackbar, color } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Fragment>
      <Container>
        <AppBar position="static" className={classes.appBarHolder}>
          {tabs && (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              scrollButtons="on"
              scrollable
              classes={{
                indicator: classes[`${useDefineColor(universityColor)}CardHeader`],
                scrollButtons: classes.scrollButtons,
                flexContainer: classes.borderContainer,
              }}
            >
              {tabs.map((tabName) => (
                <Tab
                  style={{ zIndex: 2 }}
                  key={tabName}
                  label={tabName}
                  className={classes.tabButton}
                />
              ))}
            </Tabs>
          )}
        </AppBar>
      </Container>
    </Fragment>
  );
};

InsideTabs.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
  color: PropTypes.oneOf([
    'yellowIES',
    'redIES',
    'greenIES',
    'darkBlueIES',
    'blueIES',
    'lightBlueIES',
    'orangeIES',
    'dnaColor'
  ]),
};

export default withStyles(JobHeaderTab)(InsideTabs);
