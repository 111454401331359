const getAreas = async (aditionalA, trendA) => {
  const areaAditional = await aditionalA.map((item) => item.value);
  const areaTrend = await trendA.map((item) => item.value);

  return [...areaAditional, ...areaTrend];
};

const courseDataAdapter = async ({
  name,
  aditionalCourses,
  marketTendencies,
  macroArea,
  courseLevel,
  max_daily_internship_workload,
  max_weekly_internship_workload,
}) => {
  const data = {
    name,
    macro_area: macroArea.value,
    course_level: courseLevel.value,
    areas:
      aditionalCourses &&
      marketTendencies &&
      (await getAreas(aditionalCourses, marketTendencies)),
    max_daily_internship_workload: parseInt(max_daily_internship_workload),
    max_weekly_internship_workload: parseInt(max_weekly_internship_workload),
  };
  return data;
};

export { courseDataAdapter };
