import styled from 'styled-components';

export const Wrapper = styled.div`
  .pagination-total-container {
    width: 100%;
    display: ${(props) => (props.visible ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    gap: 10px;
  }

  .pagination-total-arrow-left {
    background-color: #009291;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      transform: scale(0.9);
    }
  }

  .pagination-total-active-page {
    border: 2px solid #009291;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #009291;
  }

  .pagination-total-pipe {
    color: #009291;
  }

  .pagination-total-total {
    color: #009291;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .pagination-total-arrow-right {
    background-color: #009291;
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:active {
      transform: scale(0.9);
    }
  }
`;
