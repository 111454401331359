import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SubContainer = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border-bottom: 2px solid #eef2f4;
`;
export const SearchFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #c4c4c4;
  width: 255px;
  height: 36px;
  margin: 10px 10px 0 0;

  input {
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
  }
  svg {
    margin-left: 15px;
    color: #606062;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const HeaderInfos = styled.div`
  padding: 0px 20px;
  display: flex;
  margin: 30px 0px;
  color: #b8b8b8;
  font-weight: bold;

  .header-name-tag {
    width: 30em;
  }
  .header-data {
    width: 8.3em;
  }
  .header-institution {
    width: 13em;
  }
  .header-associations {
    width: 13em;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  margin: 30px 0px;
  border-bottom: 2px solid #eef2f4;

  .list-name-tag {
    width: 30em;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 400;
    color: #777777;
  }

  .list-data {
    max-height: ${(props) => (props.open ? 'auto' : '36px')};
    width: 8.3em;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 400;
    color: #777777;
  }
  .list-institution {
    max-height: ${(props) => (props.open ? 'auto' : '36px')};
    width: 13em;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 400;
    color: #777777;
  }
  .list-associations {
    max-height: ${(props) => (props.open ? 'auto' : '36px')};
    width: 13em;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 400;
    color: #777777;
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.open ? 'auto' : '35px')};
  overflow: hidden;

  .list-name-tag {
    width: 30em;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 400;
    color: #777777;
  }
`;

export const ItemTitle = styled.p`
  overflow: hidden;
  white-space: ${(props) => (props.open ? 'unset' : 'nowrap')};
  text-overflow: ellipsis;
  max-width: 300px;
`;

export const ActionArrow = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  cursor: pointer;
  align-self: flex-start;

  > img {
    ${(props) =>
      props.open ? 'transform: rotate(-180deg)' : 'transform: rotate(0deg)'};
  }
`;

export const Information = styled.p`
  display: flex;
  gap: 10px;
  color: #606062;
  font-weight: 400;
  line-height: 16.41px;
  max-width: 360px;
`;

export const ButtonConnect = styled.div`
  width: 95px;
  height: 30px;
  border-radius: 4px;
  background-color: #009291;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  margin-left: 9em;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonEdit = styled.div`
  width: 95px;
  height: 30px;
  border-radius: 4px;
  background-color: #009291;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  margin-left: 18.5em;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonAction = styled.span`
  width: 95px;
  height: 27px;
  display: flex;
  align-items: center;
  gap: 10px;

  border-radius: 4px;
  background-color: #009291;
  font-size: 12px;
  color: #ffffff;
  margin-left: 18.5em;
  cursor: pointer;

  .custom-width {
    width: 5rem !important;
  }
`;

export const PaginationStyle = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  margin-top: 10px;
`;
