import { authenticatedAPI } from 'services/api';

const updateExtraCourses = async (data) => {
  const response = await authenticatedAPI
    .patch(`/api/internal/students/me/additional-course/${data.id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateExtraCoursePDF = async (id, file) => {
  const dataFile = new FormData();
  dataFile.append('file', file);
  const response = await authenticatedAPI
    .post(`/api/internal/students/me/additional-course/${id}/file`, dataFile)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getExtraCoursesOptions = async () => {
  const response = await authenticatedAPI
    .options(`/api/internal/students/me/additional-course`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteExtraCourses = async (data) => {
  const response = await authenticatedAPI
    .delete(`/api/internal/students/me/additional-course/${data}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveExtraCourses = async (data) => {
  const response = await authenticatedAPI
    .post(`/api/internal/students/me/additional-course`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getExtraCourses = async () => {
  const response = await authenticatedAPI
    .get(`/api/internal/students/me/additional-course`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getExtraCoursesOptions,
  deleteExtraCourses,
  updateExtraCourses,
  saveExtraCourses,
  updateExtraCoursePDF,
  getExtraCourses,
};
