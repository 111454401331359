import React, { useState, useEffect } from 'react';

import {
  Card,
  CardImg,
  ActivityType,
  StarActivityWrapper,
  StarActivityP,
  TitleH4,
  Informations,
  IconImage,
  MoreInfo,
  Information,
  ContainerButtons,
  ButtonValidation,
  CardTitle,
  Container,
  Wrapper,
  CardSubTitle,
  DivPagination,
  DivButton,
  ButtonPagination,
  BoxReaction,
  WrapperBoxReaction,
  DashedBorder,
} from './styled';

import { FaGraduationCap, FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';
import { HiCheck } from 'react-icons/hi';

import imageBackgroundCardChallenge from 'assets/img/imageBackgroundCardChallenge.png';
import { MdStars } from 'react-icons/md';
import TargetIcon from 'assets/Oportutinites/target.svg';

import SkeletonCardCompany from 'components/Opportunities/SkeletonCard';
import ModalComplementaryActivity from '../Modals/ModalComplementaryActivity';
import ModalMoreInformations from '../Modals/ModalMoreInformations';
import LoadingCards from '../LoadingCards';
import { countChallenges } from '../../services';
import { formatNumber } from 'utils/formatNumber';
import GenericMessage from 'components/GenericMessage';

export default function CardChallenges(props) {
  const {
    challenges,
    setActiveTab,
    setChallenge,
    loadMore,
    getAllChallenges,
    loading,
    ScrollToTopDiv,
    count,
  } = props;

  const [item, setItem] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [countChallengesSum, setCountChallengesSum] = useState(0);
  const [moreInformationsModal, setMoreInformationsModal] = useState(false);

  const getCountChallenges = async () => {
    const data = await countChallenges();
    const { connections_challenges_count } = data;
    setCountChallengesSum(connections_challenges_count);
  };

  useEffect(() => {
    getCountChallenges();
  }, []);

  const publicationFormat = {
    access_link: 'Link de acesso',
    video: 'Vídeos sequenciais',
    attraction_document: 'Arquivo PDF',
  };

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const handleValidationModal = (item) => {
    setItem(item);
    setOpenModal(true);
  };

  const returnImageStatus = (item) => {
    if (item.unpublished_universities.length > 0) {
      return <FaMinusCircle size={18.5} color="#009291" />;
    }
    if (item.unpublished_universities.length < 1 && item.status === null) {
      return <FaCheckCircle size={18.5} color="#009291" />;
    }

    return <FaCheckCircle size={18.5} color="#009291" />;
  };

  const returnLabelStatus = (item) => {
    if (item.unpublished_universities.length > 0) {
      return 'Despublicado';
    }
    if (item.unpublished_universities.length < 1 && item.status === null) {
      return 'Publicado';
    }

    return 'Publicado';
  };

  const handleClickMoreInfo = (item) => {
    setMoreInformationsModal(true);
    setItem(item);
  };

  const returnPaginationButton = () => {
    if (challenges && challenges.length >= 24) {
      return loading ? (
        <LoadingCards />
      ) : (
        <DivPagination>
          <DivButton>
            <ButtonPagination
              disabled={challenges && challenges.length === count}
              onClick={loadMore}
            >
              Ver mais
            </ButtonPagination>
          </DivButton>
        </DivPagination>
      );
    }
  };

  const mapOpportunities = (array) => {
    if (array === null) {
      return <SkeletonCardCompany quantity={randomIntFromInterval(1, 3)} />;
    }

    if (!array.length) {
      return (
        <GenericMessage title="Não identificamos nenhum Desafio publicado!" />
      );
    }

    return array.map((item) => (
      <Card>
        <CardImg
          src={
            item.attraction_image
              ? item.attraction_image
              : imageBackgroundCardChallenge
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageBackgroundCardChallenge;
          }}
        />
        {item && (
          <WrapperBoxReaction>
            <BoxReaction>
              <HiCheck size={40} color="#009291" />
              <span>
                {item.total_reaction && item.total_reaction.total_finished}
              </span>
            </BoxReaction>
            <BoxReaction>
              <BiLike size={40} color="#4B4B4D" />
              <span>
                {item.total_reaction && item.total_reaction.total_liked}
              </span>
            </BoxReaction>
          </WrapperBoxReaction>
        )}
        {item.challenge_validation.activity_type && (
          <ActivityType>
            <StarActivityWrapper>
              <i>
                <MdStars size={20} color="#009291" />
              </i>
              <StarActivityP>
                {' '}
                {item.challenge_validation.activity_type.label}{' '}
              </StarActivityP>
            </StarActivityWrapper>
          </ActivityType>
        )}
        <TitleH4>{item.name}</TitleH4>

        <Informations>
          <Information>
            <IconImage src={TargetIcon} />
            <p>
              {publicationFormat[item.type_access && item.type_access.value] ||
                'Não informado'}
            </p>
          </Information>
          <Information>
            <i>
              <FaGraduationCap size={20} color="#009291" />
            </i>
            <p>{item.created_by.name || 'Não informado'}</p>
          </Information>
          <Information>
            <i>{returnImageStatus(item)}</i>
            <p>{returnLabelStatus(item)}</p>
          </Information>
          <ContainerButtons>
            <div style={{ width: '100%' }}>
              {!item.challenge_validation.activity_type &&
                returnLabelStatus(item) !== 'Despublicado' && (
                  <ButtonValidation onClick={() => handleValidationModal(item)}>
                    Validar desafio
                  </ButtonValidation>
                )}
              {item.challenge_validation.activity_type && (
                <ButtonValidation onClick={() => handleValidationModal(item)}>
                  Revogar validação
                </ButtonValidation>
              )}
            </div>
            <div style={{ width: '100%' }}>
              <MoreInfo onClick={() => handleClickMoreInfo(item)}>
                Mais informações
              </MoreInfo>
            </div>
          </ContainerButtons>
        </Informations>
      </Card>
    ));
  };

  return (
    <Container>
      <CardTitle>Desafios ({formatNumber(countChallengesSum)})</CardTitle>
      <CardSubTitle>
        Veja aqui os desafios publicados pelas empresas
      </CardSubTitle>
      <Wrapper>{mapOpportunities(challenges)}</Wrapper>

      <hr />
      {returnPaginationButton()}

      {Object.keys(item).length > 0 ? (
        <ModalMoreInformations
          item={item}
          setModalOpen={setMoreInformationsModal}
          isOpen={moreInformationsModal}
          setItem={setItem}
          setActiveTab={setActiveTab}
          getAllChallenges={getAllChallenges}
          setChallenge={setChallenge}
          ScrollToTopDiv={ScrollToTopDiv}
        />
      ) : null}

      {Object.keys(item).length > 0 ? (
        <ModalComplementaryActivity
          item={item}
          openModal={openModal}
          setOpenModal={setOpenModal}
          getAllChallenges={getAllChallenges}
          setChallenge={setChallenge}
          ScrollToTopDiv={ScrollToTopDiv}
        />
      ) : null}
    </Container>
  );
}
