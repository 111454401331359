import React from 'react';

import { Background, ContentWrapper, Helper, HelperTwo } from './styled';

function PreviewModal({ html, closeModal }) {
  return (
    <Background onClick={closeModal}>
      <ContentWrapper dangerouslySetInnerHTML={{ __html: html }} />
      <Helper>
        <div className="alert-dialog">
          <span>
            Ao clicar aqui, seus estudantes serão direcionados para a tela de
            login da plataforma
          </span>
        </div>
      </Helper>
      <HelperTwo>
        <div className="alert-dialog">
          <span>
            Ao clicar aqui, seus estudantes serão direcionados para entrar em
            contato com a Instituição no link ou e-mail inserido na
            personalização.
          </span>
        </div>
      </HelperTwo>
    </Background>
  );
}

export default PreviewModal;
