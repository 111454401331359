import { useEffect, useRef, useState } from 'react';
import {
  Divider,
  Container,
  ContainerCourse,
  ContainerHeaderAndCourses,
  Course,
  SearchInput,
  ContainerSearch,
} from './styled';
import { useDebounce } from 'hooks/useDebounce';

import IESCompanyAPI from '../../../../Service/API';

export default function Courses({ id }) {
  const [data, setData] = useState([]);
  const [seeingAllCourses, setSeeingAllCourses] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const valueSearch = useDebounce(searchValue, 500);

  const refDiv = useRef(null);

  useEffect(() => {
    getCourses(`?name=${valueSearch}`);
  }, [valueSearch]);

  const getCourses = async (query = '') => {
    setLoading(true);
    const data = await IESCompanyAPI.getUniversityCourses(id, query);
    const transformToArray = Object.values(data).map((object) => object);
    setData(transformToArray);
    setLoading(false);
  };

  const handleClickSeeAllCourses = (macroArea) => {
    setSeeingAllCourses(true);
    getCourses(`?macro_area=${macroArea}`);
    refDiv?.current?.scrollIntoView();
  };

  const handleClickBack = () => {
    setSeeingAllCourses(false);
    getCourses(``);
  };

  const mapObjects = data.map((item, index) => (
    <ContainerHeaderAndCourses key={index}>
      <h4>
        {item.macro_area_label} ({item.course_count})
      </h4>

      <ContainerCourse>
        {item.courses?.map((course) => (
          <Course key={course.id}>{course.name}</Course>
        ))}
      </ContainerCourse>

      {item.course_count > 6 && !seeingAllCourses
        ? !loading && (
            <span onClick={() => handleClickSeeAllCourses(item.macro_area)}>
              {' '}
              Ver todos os cursos{' '}
            </span>
          )
        : item.course_count > 6 &&
          seeingAllCourses &&
          !loading && <span onClick={handleClickBack}> Voltar </span>}

      <Divider />
    </ContainerHeaderAndCourses>
  ));

  return (
    <Container>
      <ContainerSearch>
        <span ref={refDiv}>Pesquisar por nome</span>
        <SearchInput
          placeholder="Digite aqui"
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </ContainerSearch>

      {mapObjects}
    </Container>
  );
}
