import styled from 'styled-components';

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  > div {
    ${(props) =>
      props.error && 'border: 1px solid #FFD8D8; border-radius: 5px;'};
    background-color: ${(props) => (props.error ? '#ffeeee' : 'transparent')};
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
  .EditJodit
    .jodit-status-bar
    .jodit-status-bar__item.jodit-status-bar__item-right {
    margin-top: 32px;
    right: 0;
    text-align: right;
    font-weight: 400;
    line-height: 16.41px;
    color: #949494;

    @media screen and (max-width: 768px) {
      margin-left: -40px;
      text-align: left;
    }

    &::after {
      font-size: 13px;
      content: '/200';
    }
    > span {
      font-size: 13px !important;
      font-family: Roboto;
    }
  }
  .EditJodit .jodit-placeholder {
    font-family: Roboto;
    font-size: 8px;
    font-weight: 400;
    color: #c4c4c4;
  }
  .EditJodit .jodit-status-bar {
    background-color: transparent;
  }
`;
export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
`;
