import { authenticatedAPI, authenticatedInternshipAPI } from 'services/api';

export async function getIesEmailConfig() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings'
  );
  return response.data;
}

export async function updateConfigEmail(data) {
  const response = await authenticatedAPI.put(
    'api/internal/university/settings/update-permission-email/',
    data
  );
  return response;
}

export async function interviewInvitation() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/student-invite'
  );
  return response.data;
}

export async function internshipMailPreview(type) {
  const response = await authenticatedInternshipAPI.get(
    `api/email_dispatch/dispatch_email/?email_type=${type}`
  );
  return response.data;
}

export async function interviewReschedule() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/student-rescheduling'
  );
  return response.data;
}

export async function interviewCancellation() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/student-canceling'
  );
  return response.data;
}

export async function finishAndFeedbackJob() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/student-feedback'
  );
  return response.data;
}

export async function indicationStudent() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/indication'
  );
  return response.data;
}

export async function tomorrowNotificationInterview() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/student-notification'
  );
  return response.data;
}

export async function notificationJobs() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/student-jobs'
  );
  return response.data;
}

export async function invitationAccess() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/inactive-students'
  );
  return response.data;
}

export async function finishTheCurriculum() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/invite-complete-curriculum'
  );
  return response.data;
}

export async function profileChampion() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/profile-champion'
  );
  return response.data;
}

export async function generatePreview(color) {
  const response = await authenticatedAPI.get(
    `api/internal/university/settings/email-preview?color=%23${color}`
  );
  return response.data;
}

export async function eventRememberOnline() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/event-remember-online'
  );
  return response.data;
}

export async function eventRememberPresential() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/event-remember-presential'
  );
  return response.data;
}

export async function eventCertificate() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/event-certificate'
  );
  return response.data;
}

export async function eventRemoveStudent() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/event-remove-student'
  );
  return response.data;
}

export async function eventCanceled() {
  const response = await authenticatedAPI.get(
    'api/internal/university/settings/email/preview/event-canceled'
  );
  return response.data;
}
