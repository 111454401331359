import React, { Fragment, useState, useContext } from 'react';
import {
  BgColorIcons,
  BgColorIconsFinished,
  BottomDiv,
  ButtonGreen,
  ButtonWhite,
  ContainerButtons,
  ContainerCard,
  Content,
  DataContent,
  DividerCard,
  Image,
  TimeView,
  TimeViewFinished,
  Title,
} from './style';

import {
  BsCalendar4Week,
  BsEmojiFrown,
  BsEmojiSmile,
  BsExclamationLg,
  BsFillCheckCircleFill,
} from 'react-icons/bs';
import ScoreIcon from 'assets/Oportutinites/ScoreIcon';
import { BiTimeFive } from 'react-icons/bi';
import { RiCloseCircleFill } from 'react-icons/ri';
import { AiOutlineCheck, AiOutlineQuestionCircle } from 'react-icons/ai';
import moment from 'moment';
import { findImage } from 'views/Student/Extracurricular/utils/findImage';
import ModalUnsubscribeProject from '../../../Modal/UnsubscribeModal';
import ModalFeedbackExtensionProject from '../../../Modal/ModalViewFeedback';
import ModalUploadEvidences from 'components/UploadEvidences/index.jsx';
import {
  deleteEvidenceStudent,
  getDetails,
  getExtensionProjectFeedback,
  getProjectEvidence,
  postCreateFileEvidenceStudent,
  postCreateLinkEvidenceStudent,
  putEditLinkEvidenceStudent,
} from '../../../../../../../../Extracurricular/services';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';
import { LoadingIcon } from 'assets/Oportutinites/LoadingIcon';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { ExtensionProjectsStudentContext } from 'views/Student/Learning/Context/ExtensionProjectStudentContext';
import ModalShowEvidence from '../../../Modal/ModalShowEvidence';
import Tooltip from 'components/NewTooltip';
import BaseLayoutContext from 'contexts/base-layout';

export default function SubscribeProjectCard(props) {
  const {
    item,
    notSelectedProjects,
    finishedProjects,
    enrolledProjects,
    awaitEvidences,
    images,
    setModalMoreInformation,
    setExtensionProjectId,
    setModalDetailsItem,
    currentList,
  } = props;

  const [modalUnsubscribe, setModalUnsubscribe] = useState(false);
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [uploudingEvidence, setUploudingEvidence] = useState(false);

  const [openShowEvidence, setOpenShowEvidence] = useState(false);

  const [explanationModal, setExplanationModal] = useState('');

  const [count, setCount] = useState(0);

  const {
    getWatingEvidencesEndpoint,
    usingPreview,
    setUsingPreview,
    isStudent,
  } = useContext(ExtensionProjectsStudentContext);

  const { universityColor } = useContext(BaseLayoutContext);

  const [evidenceFiles, setEvidenceFiles] = useState([]);
  const [evidenceLinks, setEvidenceLinks] = useState([]);

  const handleUnsubscribeProject = (item) => {
    setExtensionProjectId(item.extension_project_id);
    setModalUnsubscribe(true);
  };

  const handleClickShowEvidence = async (query) => {
    const { results, count } = await getProjectEvidence(
      item.extension_project_id,
      query
    );
    const files = results.filter(
      (item) => item.type === 'file' || item.type === 'image'
    );
    const links = results.filter(
      (item) => item.type === 'link' || item.type === 'link_unique'
    );
    setCount(count);
    setEvidenceFiles(files);
    setEvidenceLinks(links);
    setOpenShowEvidence(true);
  };

  const handleMoreInfo = async (item, isEvidence) => {
    setLoadingDetails(true);
    const response = await getDetails(item.id);
    setModalDetailsItem({
      ...response,
      setModalDetailsItem,
      isEvidence,
      handleClickShowEvidence,
      handleClickUploadEvidence,
      ...item,
    });
    setModalMoreInformation(true);
    setLoadingDetails(false);
  };

  const handleClickUploadEvidence = async (fromMoreDetails) => {
    if (fromMoreDetails) {
      setModalMoreInformation(false);
    }

    const { results } = await getProjectEvidence(item.extension_project_id);
    const response = await getDetails(item.id);
    setExplanationModal(response.about_students_evidences);
    const files = results.filter(
      (item) => item.type === 'file' || item.type === 'image'
    );
    const links = results.filter(
      (item) => item.type === 'link' || item.type === 'link_unique'
    );
    setEvidenceFiles(files);
    setEvidenceLinks(links);
    setUploudingEvidence(true);
  };

  const handleClickCloseEvidences = () => {
    getWatingEvidencesEndpoint(true);
    setUploudingEvidence(false);
  };

  const findCardImage = (item) => {
    const find = findImage(images, item.id);
    if (find && find.logo) {
      return (
        <Image universityColor={universityColor} image={true}>
          <img src={find.image} />
        </Image>
      );
    } else {
      return (
        <Image universityColor={universityColor} image={true}>
          <img
            src={find && find.image}
            style={{
              width: '100%',
              height: '209px',
              borderTopLeftRadius: '11.5652px',
              borderTopRightRadius: '11.5652px',
              position: 'absolute',
              top: 0,
            }}
          />
        </Image>
      );
    }
  };

  const dateNow = new Date();

  const compareDate = (dateNow, endAt) => {
    const dateNowFormat = moment(dateNow);
    const endAtFormat = moment(endAt);

    if (endAtFormat <= dateNowFormat) return true;
  };

  const handleViewFeedback = async (item) => {
    setLoadingFeedback(true);
    let feedbackResponse;
    const { general_feedback_id, is_published, feedback_id } = item;
    if (general_feedback_id && !is_published) {
      feedbackResponse = await getExtensionProjectFeedback(general_feedback_id);
    } else {
      feedbackResponse = await getExtensionProjectFeedback(feedback_id);
    }
    if (feedbackResponse) {
      setFeedback(feedbackResponse);
      setOpenModalFeedback(true);
    }
    setLoadingFeedback(false);
  };

  function renderIcon() {
    switch (item.status) {
      case 'Aguardando avaliação':
        return <LoadingIcon />;
      case 'Aguardando evidência':
        return <LoadingIcon />;
      case 'Aguardando análise':
        return <LoadingIcon />;
      case 'Evidências analisadas':
        return <AiOutlineCheck size={15} color="#FFFF" />;
      case 'Projeto despublicado':
        return <RiCloseCircleFill size={15} color="#FFFDED" />;
      case 'Parabéns! Você foi aprovado!':
        return <BsEmojiSmile size={15} color="#D9FFE3" />;
      case 'Você foi removido':
        return <BsEmojiFrown size={15} color="#FFEDED" />;
    }
    if (item.status.includes('realizadas')) {
      return <LoadingIcon />;
    }
  }

  return (
    <ContainerCard>
      {item && findCardImage(item)}
      <DataContent>
        {enrolledProjects && (
          <TimeView bgColor="#DEF4F4" viewColor="#009291">
            <BgColorIcons boxBgColor="#009291">
              <BiTimeFive size={18} color="#fff" />
            </BgColorIcons>
            {item.reminder}
          </TimeView>
        )}
        {notSelectedProjects && (
          <TimeView bgColor="#FFF8ED" viewColor="#F09D51">
            <BgColorIcons boxBgColor="#F09D51">
              <BsExclamationLg size={15} color="#fff" />
            </BgColorIcons>
            <p> Ops! Você não foi selecionado </p>
          </TimeView>
        )}
        {finishedProjects && (
          <TimeViewFinished status={item.status}>
            <BgColorIconsFinished status={item.status}>
              {renderIcon()}
            </BgColorIconsFinished>
            <p>{item.status}</p>
          </TimeViewFinished>
        )}
        {awaitEvidences && (
          <TimeViewFinished status={item.status}>
            <BgColorIconsFinished status={item.status}>
              {renderIcon()}
            </BgColorIconsFinished>
            <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {item.status}{' '}
              {item.status.includes('realizadas') && (
                <Tooltip
                  text={`${item.analyzed_evidences} das ${item.evidences_quantity} evidências enviadas por você foram analisadas pela sua instituição`}
                  direction="top"
                  isMultiLine={true}
                  minWidth="200px"
                >
                  <AiOutlineQuestionCircle
                    style={{ marginBottom: '-4px' }}
                    size={16}
                    color={universityColor ? universityColor : '#009291'}
                  />
                </Tooltip>
              )}
            </p>
          </TimeViewFinished>
        )}
        <Title> {item.name} </Title>
        <Content>
          <div className="wrapper-content">
            <BsCalendar4Week size={17} color={universityColor || '#009291'} />
            <p style={{ paddingLeft: '7px' }}>
              De {''}
              {moment(
                item.start_at ? item.start_at : item.evidences_start_at
              ).format('DD/MM/YYYY')}{' '}
              {''}a {''}
              {moment(item.end_at ? item.end_at : item.evidences_end_at).format(
                'DD/MM/YYYY'
              )}{' '}
              {''}
            </p>
          </div>
          <div className="wrapper-content">
            <BiTimeFive size={17} color={universityColor || '#009291'} />
            <p style={{ marginLeft: '-2px' }}>
              {item.workload &&
                `Carga horária de  ${item.workload.replace('.00', 'h')}`}
            </p>
          </div>
          {item.is_finished && item.score ? (
            <div className="wrapper-content">
              <ScoreIcon color={universityColor} />
              <p style={{ marginLeft: '-2px' }}>
                {item.score && `Nota  ${item.score}`}
              </p>
            </div>
          ) : (
            <Fragment>
              {!awaitEvidences && (
                <div className="wrapper-content">
                  <FaMapMarkerAlt
                    size={17}
                    color={universityColor || '#009291'}
                  />
                  <p style={{ marginLeft: '-2px' }}>
                    {item.address &&
                      item.address.street &&
                      `${item.address.street},`}{' '}
                    {item.address &&
                      item.address.number &&
                      `${item.address.number},`}{' '}
                    {item.address &&
                      item.address.complement &&
                      `${item.address.complement},`}{' '}
                    {item.address &&
                      item.address.city &&
                      `${item.address.city},`}{' '}
                    {item.address &&
                      item.address.zip_code &&
                      `${item.address.zip_code}`}
                  </p>
                </div>
              )}
            </Fragment>
          )}
        </Content>
        <ContainerButtons>
          {finishedProjects &&
            item.is_published === true &&
            compareDate(dateNow, item.end_at) === true && (
              <ButtonGreen
                universityColor={universityColor}
                finishedProjects={finishedProjects}
                onClick={() => handleMoreInfo(item)}
              >
                {loadingDetails ? (
                  <Loading color={'#FFF'} />
                ) : (
                  ' Visualizar detalhes'
                )}
              </ButtonGreen>
            )}
          {finishedProjects && item.is_published === false && (
            <ButtonGreen
              universityColor={universityColor}
              finishedProjects={finishedProjects}
              onClick={() => handleViewFeedback(item)}
            >
              {loadingFeedback ? (
                <Loading color={'#FFF'} />
              ) : (
                'Visualizar feedback'
              )}
            </ButtonGreen>
          )}

          {enrolledProjects && (
            <Fragment>
              <ButtonGreen
                universityColor={universityColor}
                finishedProjects={true}
                onClick={() => handleMoreInfo(item)}
              >
                {loadingDetails ? (
                  <Loading color={'#FFF'} />
                ) : (
                  ' Visualizar detalhes'
                )}
              </ButtonGreen>
              {item.can_cancel === true && (
                <ButtonWhite
                  universityColor={universityColor}
                  onClick={() => handleUnsubscribeProject(item)}
                >
                  Cancelar inscrição
                </ButtonWhite>
              )}
            </Fragment>
          )}

          {awaitEvidences && (
            <Fragment>
              <ButtonWhite
                universityColor={universityColor}
                awaitEvidences={awaitEvidences}
                onClick={() => handleMoreInfo(item, true)}
              >
                {loadingDetails ? (
                  <Loading
                    color={universityColor ? universityColor : '#009291'}
                  />
                ) : (
                  ' Visualizar detalhes'
                )}
              </ButtonWhite>

              <ButtonGreen
                universityColor={universityColor}
                onClick={handleClickUploadEvidence}
              >
                Adicionar evidências ({item.evidences_quantity})
              </ButtonGreen>
            </Fragment>
          )}

          {notSelectedProjects && (
            <Fragment>
              <ButtonGreen
                universityColor={universityColor}
                finishedProjects={finishedProjects}
                onClick={() => handleMoreInfo(item)}
              >
                {loadingDetails ? (
                  <Loading color={'#FFF'} />
                ) : (
                  ' Visualizar detalhes'
                )}
              </ButtonGreen>
              <ButtonWhite
                universityColor={universityColor}
                onClick={() => handleViewFeedback(item)}
              >
                {loadingFeedback ? (
                  <Loading
                    color={universityColor ? universityColor : '#009291'}
                  />
                ) : (
                  'Visualizar feedback'
                )}
              </ButtonWhite>
            </Fragment>
          )}

          {modalUnsubscribe && (
            <ModalUnsubscribeProject
              isOpen={modalUnsubscribe}
              currentList={currentList}
              setModalUnsubscribe={setModalUnsubscribe}
              name={item.name}
            />
          )}
        </ContainerButtons>
        {finishedProjects && (
          <Fragment>
            <DividerCard></DividerCard>
            {item.is_published === false && (
              <BottomDiv>
                <RiCloseCircleFill
                  size={18}
                  color={universityColor ? universityColor : '#009291'}
                />
                <span>
                  A atividade de extensão foi despublicada por sua Instituição
                  de Ensino, portanto, não será possível participar do projeto.
                </span>
              </BottomDiv>
            )}
            {(item.status === 'Parabéns! Você foi aprovado!' ||
              item.status === 'Você foi removido') && (
              <BottomDiv>
                <BsFillCheckCircleFill
                  size={15}
                  color={universityColor ? universityColor : '#009291'}
                />
                <span>A atividade de extensão foi finalizada.</span>
              </BottomDiv>
            )}

            {item.status === 'Aguardando avaliação' && (
              <BottomDiv>
                <div>
                  <LoadingIcon size={13} color="#FFF" />
                </div>
                <span>
                  Aguardando a avaliação da sua instituição de ensino.
                </span>
              </BottomDiv>
            )}
          </Fragment>
        )}
      </DataContent>
      {uploudingEvidence && (
        <ModalUploadEvidences
          open={uploudingEvidence}
          verifyStatus={true}
          evidenceFiles={evidenceFiles}
          evidenceLinks={evidenceLinks}
          acceptFormats={['file', 'link']}
          headerTitle={'Upload de evidências'}
          subTitle={explanationModal}
          acceptFileTypes={['PNG', 'JPEG', 'PDF']}
          functionCloseAndUpdate={handleClickCloseEvidences}
          idPost={item.extension_project_id}
          functionPostFile={postCreateFileEvidenceStudent}
          functionDeleteEvidence={deleteEvidenceStudent}
          functionPostLink={postCreateLinkEvidenceStudent}
          functionPutLink={putEditLinkEvidenceStudent}
          buttonLabel={'Salvar Evidência(s)'}
          fromStudent={true}
        />
      )}
      <ModalShowEvidence
        open={openShowEvidence}
        count={count}
        setOpen={setOpenShowEvidence}
        arrayLinks={evidenceLinks}
        arrayFiles={evidenceFiles}
        loadEvidences={handleClickShowEvidence}
        deleteEvidenceStudent={deleteEvidenceStudent}
        id={item.extension_project_id}
        setEvidenceFiles={setEvidenceFiles}
        setEvidenceLinks={setEvidenceLinks}
        setUsingPreview={setUsingPreview}
        usingPreview={usingPreview}
      />
      {openModalFeedback && (
        <ModalFeedbackExtensionProject
          isOpen={openModalFeedback}
          setOpenModalFeedback={setOpenModalFeedback}
          feedback={feedback}
        />
      )}
    </ContainerCard>
  );
}
