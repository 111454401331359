export default function EditAvatarIcon({ color }) {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.44995 23.55V19.75L19.6953 7.50467C20.8068 6.39315 22.6157 6.39989 23.7241 7.50834L23.5222 7.30642C24.6357 8.41996 24.6338 10.2234 23.5215 11.3309L11.25 23.55H7.44995Z"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M18.375 8.82501L22.175 12.625" stroke={color} stroke-width="2" />
    </svg>
  );
}
