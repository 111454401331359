import React, { useEffect, useState } from 'react';
import { ButtonFavorite, ContainerStar } from '../styled';
import { wrapperIconsJobOffers } from '../utils/wrapperIconsJobOffers';

export default function FavoriteJobsButtons({
  onClick,
  universityColor,
  isFavorite,
}) {
  const { IconStar, IconUnliked } = wrapperIconsJobOffers();
  const [isLiked, setIsLiked] = useState(isFavorite);

  useEffect(() => {
    setIsLiked(isFavorite);
  }, [isFavorite]);

  const handleClick = () => {
    onClick();
    setIsLiked(!isLiked);
  };

  return (
    <ContainerStar>
      <ButtonFavorite onClick={handleClick}>
        {isLiked ? <IconStar color={universityColor} /> : <IconUnliked />}
      </ButtonFavorite>
    </ContainerStar>
  );
}
