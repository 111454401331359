import { registerAccessedCurriculum } from 'services/student/registerActivityStudent';
import {
  RatingContainer,
  StarIcon,
  StatusArea,
  WrapperReactions,
} from '../Styles/CardComponent';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import {
  getCompanyCurriculumDetail,
  getCurriculumDetailHistoryPartner,
} from 'views/JobOfferRebuild/services';
import {
  getApplicationOptions,
  returnJobApplicationCompany,
} from 'services/company/jobs';
import { getCurriculumDetail } from 'views/StaffPages/jobOpeningsRebuild/services';
import {
  getUniversityApplicationOptions,
  returnJobApplicationUniversity,
} from 'services/university/jobs';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { BiLike, BiDislike, BiCalendar } from 'react-icons/bi';
import moment from 'moment';
import Tooltip from 'components/NewTooltip';

export const returnRatingStars = (score) => {
  if (score > 0) {
    return (
      <RatingContainer>
        <span>Avaliação:</span>
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index} selected={index < score} />
        ))}
      </RatingContainer>
    );
  }

  return (
    <RatingContainer>
      <span>
        Você não avaliou o estudante
        <Tooltip
          direction="top"
          text={'Clique em visualizar currículo para avaliar o estudante'}
          minWidth={'204px'}
          isMultiLine
          colorSchema="black"
        >
          <IoIosInformationCircleOutline size={18} color="#606062" />
        </Tooltip>
      </span>
    </RatingContainer>
  );
};

export const returnReactionInformation = (
  is_interesting,
  openModalFeedback
) => {
  const returnReactionIcon = {
    true: <BiLike color="#009291" size={16} />,
    false: <BiDislike color="#cf0000" size={16} />,
  };

  const returnReactionLabel = {
    true: 'Currículo marcado como interessante',
    false: 'Currículo marcado como não interessante',
  };
  return (
    <WrapperReactions liked={is_interesting}>
      <div className="box-icon">{returnReactionIcon[is_interesting]}</div>
      <p>
        {returnReactionLabel[is_interesting]}
        {!is_interesting && (
          <button onClick={() => openModalFeedback(true)}>Ver feedback</button>
        )}
      </p>
    </WrapperReactions>
  );
};

export const returnStatusInterview = (interview) => {
  const informationLabel = {
    unscheduled: 'Agendamento solicitado',
    scheduled: `Entrevista agendada: ${moment(interview.date).format(
      'DD/MM/YYYY'
    )}`,
    rescheduled: 'Reagendamento solicitado',
    canceled: 'Entrevista cancelada',
  };

  return (
    <StatusArea color={interview.status}>
      <BiCalendar size={14} /> {informationLabel[interview.status]}
    </StatusArea>
  );
};

// Fetches the curriculum details and job history for a specific candidate.

export const fetchCandidateCurriculumJobHistory = async (
  jobId,
  candidateId,
  handleIsLoadingState,
  setStudentDetails,
  setApplicationsOptions,
  history,
  isPartner,
  setGenerateCurriculumModal
) => {
  handleIsLoadingState(true);
  const candidatesDetailing =
    history && isPartner
      ? await getCurriculumDetailHistoryPartner(jobId, candidateId)
      : await getCompanyCurriculumDetail(jobId, candidateId, isPartner);

  if (candidatesDetailing) {
    await registerAccessedCurriculum([
      candidatesDetailing.student.curriculum_id,
    ]);
  }
  setStudentDetails(candidatesDetailing);

  if (candidatesDetailing.message) {
    handleIsLoadingState(false);
    openSnackbar(
      'Falha ao carregar o currículo! Tente novamente mais tarde.',
      true
    );
  }

  const applicationsOptionsResponse = await getApplicationOptions(
    jobId,
    candidateId
  );

  setApplicationsOptions(applicationsOptionsResponse.reasons);
  setGenerateCurriculumModal(true);
};

export const fetchCandidateCurriculumInternalJob = async (
  jobId,
  candidateId,
  handleIsLoadingState,
  setStudentDetails,
  setApplicationsOptions,
  setGenerateCurriculumModal
) => {
  handleIsLoadingState(true);

  const candidatesDetailing = await getCurriculumDetail(jobId, candidateId);

  if (candidatesDetailing) {
    await registerAccessedCurriculum([
      candidatesDetailing?.student?.curriculum_id,
    ]);
  }
  setStudentDetails(candidatesDetailing);

  const applicationsOptionsResponse = await getUniversityApplicationOptions(
    jobId,
    candidateId
  );
  setApplicationsOptions(applicationsOptionsResponse.reasons);

  if (candidatesDetailing.message) {
    handleIsLoadingState(false);
    customSnackbar(
      'Falha ao carregar o currículo! Tente novamente mais tarde.',
      'error'
    );
  }

  setGenerateCurriculumModal(true);
};

export const isCandidateSuitable = async (
  environmentType,
  handleIsLoadingState,
  jobId,
  id,
  data,
  isPartner
) => {
  const controlReturnJobApplication = {
    company: returnJobApplicationCompany,
    university: returnJobApplicationUniversity,
  };

  const response = await controlReturnJobApplication[environmentType](
    jobId,
    id,
    data,
    isPartner
  );

  if (response.message) {
    handleIsLoadingState(false);
    customSnackbar(
      'Falha ao responder o candidato! Tente novamente mais tarde.',
      'error'
    );
    return;
  }
  customSnackbar('Resposta enviada ao candidato com sucesso!', 'confirmation');
  return response;
};
