import { useContext } from 'react';

import {
  getEventsEducationalGroup,
  getCoursesEducationalGroup,
  getProgramsEducationalGroup,
  getAnnoucementEducationalGroup,
  getTrailsEducationalGroup,
  getEbooksEducationalGroup,
  getArticlesEducationalGroup,
  getChallengesEducationalGroup,
  getSeriesEducationalGroup,
} from 'services/university/events';
import { ListPublishedByTheGroupContext } from '../Context/ListPublishedByTheGroupContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export const useEducationalGroupLearningAPICalls = () => {
  const {
    setRefreshCountGroup,
    setEducationalGroupEvents,
    setEducationalGroupCourses,
    setEducationalGroupPrograms,
    setEducationalGroupCommunicate,
    setEducationalGroupTrails,
    setEducationalGroupEbooks,
    setEducationalGroupArticle,
    setEducationalGroupChallenge,
    setEducationalGroupSerie,
    setEducationalGroupEventCount,
    setEducationalGroupCoursesCount,
    setEducationalGroupProgramsCount,
    setEducationalGroupAnnouncementsCount,
    setEducationalGroupTrailsCount,
    setEducationalGroupEbooksCount,
    setEducationalGroupArticlesCount,
    setEducationalGroupChallengesCount,
    setEducationalGroupSeriesCount,
    setCurrentPageEducationalGroupEvents,
    setCurrentPageEducationalGroupPrograms,
    setCurrentPageEducationalGroupAnnouncements,
    setCurrentPageEducationalGroupCourses,
    setCurrentPageEducationalGroupTrails,
    setCurrentPageEducationalGroupEbooks,
    setCurrentPageEducationalGroupArticles,
    setCurrentPageEducationalGroupChallenges,
    setCurrentPageEducationalGroupSeries,
  } = useContext(ListPublishedByTheGroupContext);

  const getEducationalGroupAllEvents = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getEventsEducationalGroup(page);

    if (response.status === 200) {
      const { results, count } = response.data;

      setEducationalGroupEventCount(count);
      if (reset) {
        setEducationalGroupEvents(null);
        setCurrentPageEducationalGroupEvents(0);
        setEducationalGroupEventCount(0);
        setEducationalGroupEvents(results);
        setEducationalGroupEventCount(count);
        return;
      }

      if (results.length <= 8) {
        setCurrentPageEducationalGroupEvents(currentPage);
        setEducationalGroupEvents((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupEventCount(count);
      } else {
        setEducationalGroupEvents([]);
      }
    }
  };

  const getEducationalGroupAllCourses = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getCoursesEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupCoursesCount(count);
      if (reset) {
        setEducationalGroupCourses(null);
        setCurrentPageEducationalGroupCourses(0);
        setEducationalGroupCoursesCount(0);
        setEducationalGroupCourses(results);
        setEducationalGroupCoursesCount(count);
        return;
      }

      if (results && results?.length <= 8) {
        setCurrentPageEducationalGroupCourses(currentPage);
        setEducationalGroupCourses((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupCoursesCount(count);
      } else {
        setEducationalGroupCourses([]);
      }
    }
  };

  const getEducationalGroupAllProgram = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getProgramsEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupProgramsCount(count);
      if (reset) {
        setEducationalGroupPrograms(null);
        setCurrentPageEducationalGroupPrograms(0);
        setEducationalGroupProgramsCount(0);
        setEducationalGroupPrograms(results);
        setEducationalGroupProgramsCount(count);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupPrograms(currentPage);
        setEducationalGroupPrograms((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupProgramsCount(count);
      } else {
        setEducationalGroupPrograms([]);
      }
    }
  };

  const getEducationalGroupAllAnnouncements = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getAnnoucementEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupAnnouncementsCount(count);
      if (reset) {
        setEducationalGroupCommunicate(null);
        setCurrentPageEducationalGroupAnnouncements(0);
        setEducationalGroupAnnouncementsCount(0);
        setEducationalGroupCommunicate(results);
        setEducationalGroupAnnouncementsCount(count);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupAnnouncements(currentPage);
        setEducationalGroupCommunicate((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupAnnouncementsCount(count);
      } else {
        setEducationalGroupCommunicate([]);
      }
    }
  };

  const getEducationalGroupAllTrails = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getTrailsEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupTrailsCount(count);
      if (reset) {
        setEducationalGroupTrails(null);
        setCurrentPageEducationalGroupTrails(0);
        setEducationalGroupTrailsCount(0);
        setEducationalGroupTrails(results);
        setEducationalGroupTrailsCount(count);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupTrails(currentPage);
        setEducationalGroupTrails((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupTrailsCount(count);
      } else {
        setEducationalGroupTrails([]);
      }
    }
  };

  const getEducationalGroupAllEbooks = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getEbooksEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupEbooksCount(count);
      if (reset) {
        setEducationalGroupEbooks(null);
        setCurrentPageEducationalGroupEbooks(0);
        setEducationalGroupEbooksCount(0);
        setEducationalGroupEbooks(results);
        setEducationalGroupEbooksCount(count);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupEbooks(currentPage);
        setEducationalGroupEbooks((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupEbooksCount(count);
      } else {
        setEducationalGroupEbooks([]);
      }
    }
  };

  const getEducationalGroupAllArticles = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getArticlesEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupArticlesCount(count);
      if (reset) {
        setEducationalGroupArticle(null);
        setCurrentPageEducationalGroupArticles(0);
        setEducationalGroupArticlesCount(0);
        setEducationalGroupArticle(results);
        setEducationalGroupArticlesCount(count);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupArticles(currentPage);
        setEducationalGroupArticle((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupArticlesCount(count);
      } else {
        setEducationalGroupArticle([]);
      }
    }
  };

  const getEducationalGroupAllChallenges = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getChallengesEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupChallengesCount(count);
      if (reset) {
        setEducationalGroupChallenge(null);
        setCurrentPageEducationalGroupChallenges(0);
        setEducationalGroupChallengesCount(0);
        setEducationalGroupChallenge(results);
        setEducationalGroupChallengesCount(count);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupChallenges(currentPage);
        setEducationalGroupChallenge((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupChallengesCount(count);
      }
    } else {
      setEducationalGroupChallenge([]);
    }
  };

  const getEducationalGroupAllSeries = async (
    reset = false,
    currentPage = 0,
    appliedFilters
  ) => {
    let page = `?limit=8&offset=${reset ? 0 : currentPage}`;
    if (appliedFilters) {
      page = `${page}&${queryParamsFromFilterObject(appliedFilters)}`;
    }
    const response = await getSeriesEducationalGroup(page);
    if (response.status === 200) {
      const { results, count } = response.data;
      setEducationalGroupSeriesCount(count);
      if (reset) {
        setEducationalGroupSerie(null);
        setCurrentPageEducationalGroupSeries(0);
        setEducationalGroupSeriesCount(0);
        setEducationalGroupSerie(results);
        setEducationalGroupSeriesCount(count);
        setRefreshCountGroup(true);
        return;
      }

      if (results?.length <= 8) {
        setCurrentPageEducationalGroupSeries(currentPage);
        setEducationalGroupSerie((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setEducationalGroupSeriesCount(count);
      }
    } else {
      setEducationalGroupSerie([]);
    }
  };

  return {
    getEducationalGroupAllEvents,
    getEducationalGroupAllCourses,
    getEducationalGroupAllProgram,
    getEducationalGroupAllAnnouncements,
    getEducationalGroupAllTrails,
    getEducationalGroupAllEbooks,
    getEducationalGroupAllArticles,
    getEducationalGroupAllChallenges,
    getEducationalGroupAllSeries,
  };
};
