import styled from 'styled-components';

export const WrapperMoreInfo = styled.div`
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}
  z-index: 3000;
  width: 512px;
  background-color: #ffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;

  position: absolute;
  top: 35px;
`;
export const DivButtonsFooter = styled.div`
  width: 100%;
  height: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  margin-bottom: 35px;
  margin-top: 35px;
`;

export const WrapperHeader = styled.div`
  width: 100%;
  height: 207px;
  position: relative;
`;

export const Image = styled.div`
  width: 100%;
  height: 209px;

  background-color: #009291;

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 50%;
  }
`;

export const WrapperContent = styled.div`
  margin-top: 20px;
  padding: 25px;
`;

export const ButtonFeedback = styled.button`
  height: 32px;

  border: none;
  background-color: #009291;
  color: #fff;
  font-weight: 600;
  font-size: 12px;

  border-radius: 4px;

  cursor: pointer;
  padding: 0 15px;
`;

export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const StarActivityImg = styled.img`
  width: 10px;
`;

export const Favorite = styled.div`
  width: 100%;
  max-width: 158px;
  height: 32px;

  padding: 3px;

  background-color: #f6f6f6;

  color: #4b4b4b;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  position: absolute;
  right: 38px;

  z-index: 0;

  .yellow-box {
    width: 26px;
    height: 26px;
    border-radius: 3px;
    background: #ffc328;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DivX = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  text-align: center;

  position: absolute;
  top: 20px;
  right: 10px;
`;

export const DivStar = styled.div`
  background-color: #ffff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

export const Wrapper = styled.div`
  width: auto;
  height: 20px;
  padding-right: 10px;
  margin-right: 10px;
  background-color: #009291;
  display: flex;
  align-items: center;
  color: #ffff;
  text-align: center;
  border-radius: 8px;
`;

export const WrapperStatusPublished = styled.div`
  width: auto;
  height: 20px;

  background-color: #f5f5f5;
  border-radius: 8px;

  display: flex;
  align-items: center;

  i {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    padding-left: 5px !important;
  }

  p {
    margin: 0;
    padding-right: 5px;
  }

  span {
    padding: 0px 5px !important;
    font-size: 13px !important;
    color: #000 !important;
    text-align: center !important;
  }
`;

export const ReactionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  top: 20px;
  position: absolute;
  left: 10px;
`;

export const Reaction = styled.div`
  background-color: #ffff;
  width: 45px;
  height: 30px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-right: 15px;
  display: flex;
  align-items: center;
`;
export const WrapperTitles = styled.div``;
export const Title = styled.h3`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;

  text-align: start;
`;
export const SubTitle = styled.p`
  word-break: break-word;
  display: -webkit-box;
  height: auto;
  margin: 0 auto;
  -webkit-line-clamp: 20;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const DivDescription = styled.div`
  width: 460px;
  height: auto;
`;
export const Description = styled.p`
  word-break: break-word;
  font-size: 13px;
  color: #606062;
  height: auto;
  max-height: 207px;
  width: 480px;
  overflow-y: scroll;
  text-align: start;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
`;

export const WrapperInformartions = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  width: 100%;
`;

export const GridSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop};

  width: 50%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  text-align: start;

  .container {
    display: flex;
    width: 100%;
  }

  .container i {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
  }

  .container img {
    width: 34px;
    padding-right: 5px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }

  .text-wrapper strong {
    margin-top: 18px;
    color: #009291;
    width: 100%;
    font-weight: 400;
  }

  .text-wrapper p {
    font-size: 12px;
    width: 100%;
    border: 1px;
  }

  .text-wrapper button {
    width: 100%;
    max-width: 95px;

    padding: 0;
    margin-bottom: 13px;

    color: #7b848e;
    font-size: 12px;
    text-align: start;
    text-decoration: underline;

    background: none;
    border: none;
    cursor: pointer;
  }
`;

export const BottomCards = styled.div`
  display: flex;

  .cards {
    width: 100%;
    max-width: 95px;
    height: 100px;

    border-radius: 8px;

    display: flex;
    align-items: center;
    flex-direction: column;

    padding-top: 10px;
    margin-right: 14px;
    margin-top: 10px;

    background-color: #f5f5f5;
  }

  .cards img {
    width: ${(props) => props.widthImg};
  }

  .cards p {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
  }
`;

export const ButtonViewStudents = styled.button`
  border: none;
  background: transparent;
  color: #009291;
  text-decoration: underline;
  cursor: pointer;
`;

export const WrapperDespublish = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonConfirm = styled.button`
  width: 14em;
  height: 30px;
  color: #009291;
  background-color: #ffff;
  border: 2px solid #009291;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
`;

export const ButtonCancel = styled.button`
  width: 14em;
  height: 30px;
  color: #ffff;
  background-color: #009291;
  border: 2px solid #009291;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  padding: 30px 0;

  .button-wrapper {
    display: flex;
    align-items: center;
  }
`;

export const UnpublishButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: none;
  background-color: #f5f5f5;
  border-radius: 8px;

  color: #4b4b4d;
  padding: 9px 14px;
  cursor: pointer;

  img {
    margin-right: 5px;
  }
`;

export const EditButton = styled.button`
  width: 100%;
  max-width: 68px;
  height: 31px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #009291;
  font-weight: bold;
  margin-left: 14px;

  border: 1px solid #009291;
  border-radius: 5px;
  padding: 6px;

  background: none;

  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}

  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.25);

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .box {
    width: calc(100% - 259px);
    height: 100%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 960px) {
    .box {
      width: 100%;
    }
  }
`;

export const ButtonX = styled.button`
  width: 100%;
  max-width: 25px;
  height: 25px;

  background-color: #ededed;
  border: none;
  border-radius: 200px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperBoxReaction = styled.div`
  width: 100%;
  max-width: 100px;

  display: flex;
  align-items: flex-end;
  flex-direction: row;

  position: absolute;
  top: 8px;
  left: 8px;
  gap: 8px;
`;

export const BoxReaction = styled.div`
  width: 100%;
  max-width: 45px;
  height: 23px;

  color: #4b4b4d;
  border-radius: 3px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px;
  margin-bottom: 10px;

  box-shadow: 0px 0px 10.0697px rgba(0, 0, 0, 0.4);

  span {
    font-size: 13px !important;
    padding: 2px;
  }
`;
