import styled from 'styled-components';

export const TitleDialog = styled.h1`
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 35px;
  text-align: center;

  @media (max-width: 959px) {
    font-size: 1.8em;
    margin-top: 60px;
    margin-left: 16px;
    margin-right: 16px;
    text-align: center;
  }
`;
