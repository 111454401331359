import React, { Fragment } from 'react';
import backgroundMyCareer from 'assets/img/foto_moca_auto_a.jpg';
import CardAvatar from 'components/Card/CardAvatar';
import { object, string, func } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import profileHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/profileHeaderStyle';

const ViewsHeader = (props) => {
  const { classes, title, backgroundImage, customStyle } = props;

  return (
    <div
      className={classes.profileHeaderHolder}
      style={customStyle && customStyle}
    >
      <Fragment>
        <CardAvatar profile>
          <div className={classes.studentInfo}>
            <h1 className={classes.pageTitle}>{title}</h1>
          </div>
        </CardAvatar>
        <div className={classes.backgroundViewsHeaderOverlay} />
        <div className={classes.profileHeaderMask}>
          <div
            className={classes.viewHeaderBackground}
            style={{
              backgroundImage: `url(${backgroundImage || backgroundMyCareer})`,
              backgroundSize: 'cover',
              filter: 'blur(6px)',
            }}
          />
        </div>
      </Fragment>
    </div>
  );
};

ViewsHeader.propTypes = {
  classes: object.isRequired,
  title: string,
  openModalWithContent: func,
  closeModal: func,
  openSnackbar: func,
  updateAvatarState: func,
  updateProfileState: func,
  handleIsLoadingState: func,
  backgroundImage: string,
  customStyle: object,
};

export default withStyles(profileHeaderStyle)(ViewsHeader);
