import React, { Fragment } from 'react';
import { func, shape } from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Edit, History } from '@material-ui/icons';
import TableCell from '@material-ui/core/TableCell';

export default function CourseCells(props) {
  const { loadingState, handleEdit, item, permissions } = props;
  return (
    <Fragment>
      <TableCell component="th" scope="row" padding="none">
        {item.name}
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        {item.macroArea && item.macroArea.label}
      </TableCell>
      <TableCell align="right">{item.createdAt}</TableCell>
      <TableCell align="right">
        {permissions.change && (
          <Tooltip title="Editar" placement="left" enterDelay={300}>
            <IconButton
              arial-label="Editar"
              onClick={() => {
                loadingState(true);
                handleEdit(item);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Histórico" placement="right" enterDelay={300}>
          <IconButton arial-label="Editar">
            <History />
          </IconButton>
        </Tooltip>
      </TableCell>
    </Fragment>
  );
}

CourseCells.propTypes = {
  loadingState: func.isRequired,
  handleEdit: func.isRequired,
  item: shape.isRequired,
  permissions: shape.isRequired,
};
