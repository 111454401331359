import React from 'react';
import PropTypes, { func, bool, object, string } from 'prop-types';
// javascript plugin used to create scrollbars on windows
import { NavLink } from 'react-router-dom';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import Icon from '@material-ui/core/Icon';
import Close from '@material-ui/icons/Close';
// core components
import HeaderLinks from 'components/Header/HeaderLinks';
import SuggestionLink from 'components/Sidebar/SuggestionLink';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle';

import avatar from 'assets/img/faces/avatar.jpg';
import SidebarWrapper from './SidebarWrapper';
import useDefineColor from 'constants/colors';
import LogoMini from 'assets/img/logo-mini';
import { registerActivityMenu } from 'services/student/registerActivityStudent';
import { PremiumTag } from './components/premiumTag';

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute('/components'),
      openForms: this.activeRoute('/forms'),
      openTables: this.activeRoute('/tables'),
      openMaps: this.activeRoute('/maps'),
      openPages: this.activeRoute('-page'),
      miniActive: true,
      openedSubmenus: {},
      openMenuRerender: false,
    };
    this.activeRoute.bind(this);
    this.subMenuActiveRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName, hasSubmenu = false) {
    if (hasSubmenu && this.props.location.pathname.includes(routeName)) {
      return true;
    }
    return this.props.location.pathname === routeName;
  }

  componentDidMount() {
    this.setState({ openMenuRerender: true });
  }

  subMenuActiveRoute(routeName, parentPath) {
    const { openMenuRerender } = this.state;
    if (this.props.location.pathname === routeName) {
      if (openMenuRerender) {
        this.openSubMenu(parentPath, true);
        this.setState({ openMenuRerender: false });
      }
      return true;
    }
    return false;
  }
  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  openSubMenu = (propPath, open = true) => {
    const { openedSubmenus } = this.state;
    if (
      openedSubmenus.hasOwnProperty(propPath) &&
      openedSubmenus[propPath] !== open
    ) {
      openedSubmenus[propPath] = open;
      this.setState(openedSubmenus);
    } else if (!openedSubmenus.hasOwnProperty(propPath)) {
      openedSubmenus[propPath] = true;
      this.setState(openedSubmenus);
    }
  };

  verifyOpenedSubmenu = (propPath) =>
    this.state.openedSubmenus.hasOwnProperty(propPath) &&
    this.state.openedSubmenus[propPath];

  renderSubMenu(subMenu, parentPath, rtlActive, miniActive) {
    const { classes } = this.props;
    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive,
    })}`;

    return (
      <Collapse in={this.state[parentPath]} unmountOnExit>
        <List className={`${classes.list} ${classes.collapseList}`}>
          {subMenu.map((subMenuItem, subKey) => {
            const itemPath = parentPath + subMenuItem.path;
            const itemIcon = `${classes.itemIcon} ${cx({
              [classes.itemIconRTL]: rtlActive,
            })}`;
            const subNavLinkClasses = `${classes.itemLink} ${
              classes.subMenuItem
            } ${cx({
              [`${classes['subMenudnaColor']} `]: this.subMenuActiveRoute(
                itemPath,
                parentPath
              ), //
            })}`;

            return (
              <ListItem key={'sub-' + subKey} className={classes.item}>
                <NavLink to={itemPath} className={subNavLinkClasses}>
                  <ListItemIcon className={itemIcon}>
                    {typeof subMenuItem.icon === 'string' ? (
                      <Icon>{subMenuItem.icon}</Icon>
                    ) : (
                      <subMenuItem.icon />
                    )}
                  </ListItemIcon>

                  <ListItemText
                    primary={subMenuItem.name}
                    disableTypography
                    className={itemText}
                  />
                </NavLink>
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    );
  }

  registerStudentMenu = async (routeName) => {
    if (this.props.role === 'student') {
      const data = {
        menu: routeName,
      };
      await registerActivityMenu(data);
    }
  };

  render() {
    const {
      classes,
      color,
      logotypo,
      hasUniversityLogo,
      image,
      routes,
      bgColor,
      rtlActive,
      miniActive,
      isCompany,
      universityColor,
      isStudent,
      role,
    } = this.props;

    const colorPattern = '#009291';

    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive,
    })}`;
    const collapseItemText = `${classes.collapseItemText} ${cx({
      [classes.collapseItemTextMini]:
        this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    })}`;
    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`;
    const caret = `${classes.caret} ${cx({
      [classes.caretRTL]: rtlActive,
    })}`;
    const collapseItemMini = `${classes.collapseItemMini} ${cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    })}`;
    const photo = `${classes.photo} ${cx({
      [classes.photoRTL]: rtlActive,
    })}`;

    const isBackOffice = role === 'backoffice';

    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          <img src={avatar} className={classes.avatarImg} alt="..." />
        </div>
        <List className={classes.list}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to="#"
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={rtlActive ? 'تانيا أندرو' : 'Tania Andrew'}
                secondary={
                  <b
                    className={`${caret} ${classes.userCaret} ${
                      this.state.openAvatar ? classes.caretActive : ''
                    }`}
                  />
                }
                disableTypography
                className={`${itemText} ${classes.userItemText}`}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? 'مع' : 'MP'}
                    </span>
                    <ListItemText
                      primary={rtlActive ? 'ملفي' : 'My Profile'}
                      disableTypography
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? 'هوع' : 'EP'}
                    </span>
                    <ListItemText
                      primary={
                        rtlActive ? 'تعديل الملف الشخصي' : 'Edit Profile'
                      }
                      disableTypography
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
                <ListItem className={classes.collapseItem}>
                  <NavLink
                    to="#"
                    className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                  >
                    <span className={collapseItemMini}>
                      {rtlActive ? 'و' : 'S'}
                    </span>
                    <ListItemText
                      primary={rtlActive ? 'إعدادات' : 'Settings'}
                      disableTypography
                      className={collapseItemText}
                    />
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    const links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect || prop.hide || prop.subMenuEgressed) {
            return null;
          }

          const hasSubmenu = prop.subMenu && prop.subMenu.length > 0;
          const propPath = hasSubmenu ? prop.path : null;
          const navLinkClasses = `${classes.itemLink} ${cx({
            [`${
              isStudent === true
                ? classes[useDefineColor(universityColor)]
                : classes[useDefineColor(colorPattern)]
            } `]: this.activeRoute(prop.path, hasSubmenu), //
          })}`;
          const itemText = `${classes.itemText} ${cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          })}`;
          const itemIcon = `${classes.itemIcon} ${cx({
            [classes.itemIconRTL]: rtlActive,
          })}`;
          const caret = `${classes.caret} ${cx({
            [`${
              isStudent === true
                ? classes[useDefineColor(universityColor)]
                : classes[useDefineColor(colorPattern)]
            } `]: rtlActive,
          })}`;
          return (
            <ListItem key={key} className={classes.item}>
              {hasSubmenu ? (
                <>
                  <div
                    to="#"
                    className={navLinkClasses}
                    onClick={() =>
                      this.openSubMenu(
                        propPath,
                        !this.verifyOpenedSubmenu(propPath)
                      )
                    }
                  >
                    <ListItemIcon className={itemIcon}>
                      {typeof prop.icon === 'string' ? (
                        <Icon>{prop.icon}</Icon>
                      ) : (
                        <prop.icon />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={prop.name}
                      secondary={
                        <b
                          className={`${caret} ${
                            this.verifyOpenedSubmenu(propPath)
                              ? classes.caretActive
                              : ''
                          }`}
                        />
                      }
                      disableTypography
                      className={itemText}
                    />
                  </div>
                  {this.renderSubMenu(prop.subMenu, propPath)}
                </>
              ) : (
                <NavLink
                  to={prop.path}
                  onClick={() => this.registerStudentMenu(prop.name)}
                  className={navLinkClasses}
                >
                  <ListItemIcon className={itemIcon}>
                    {typeof prop.icon === 'string' ? (
                      <Icon>{prop.icon}</Icon>
                    ) : (
                      <prop.icon />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {prop.name}
                        {prop.premiumTag && <PremiumTag>PREMIUM</PremiumTag>}
                      </>
                    }
                    disableTypography
                    className={itemText}
                  />
                </NavLink>
              )}
            </ListItem>
          );
        })}
      </List>
    );

    const logoMini = `${classes.logoMini} ${cx({
      [classes.logoMiniRTL]: rtlActive,
    })}`;
    const brand = (
      <div className={classes.logo}>
        <div className={classes.logoContainer}>
          <span className={logoMini}>
            <a href="/#">
              {miniActive ? (
                <LogoMini color={universityColor} />
              ) : (
                <img
                  src={logotypo}
                  alt="logo"
                  className={classes.imgLogotypo}
                  height={miniActive && 50}
                  style={{
                    width: miniActive && 'auto',
                  }}
                />
              )}
            </a>
          </span>
        </div>
        <Close
          className={classes.closeIcon}
          onClick={this.props.handleDrawerToggle}
        />
      </div>
    );
    const drawerPaper = `${classes.drawerPaper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    })}`;
    const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    return (
      <div ref="mainPanel">
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${
                isStudent === true
                  ? classes[`${'white'}Background`]
                  : classes[`${'black'}Background`]
              }`,
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={
                <HeaderLinks
                  isStudent={isStudent}
                  universityColor={universityColor}
                />
              }
              links={links}
              suggestionLink={<SuggestionLink {...this.props} />}
            />
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
            classes={{
              paper: `${drawerPaper} ${classes[`${'white'}Background`]}`,
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
              suggestionLink={<SuggestionLink {...this.props} />}
            />
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

SidebarWrapper.propTypes = {
  className: string,
  headerLinks: object,
  links: object,
  suggestionLink: object,
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'dnaColor',
    'yellowIES',
    'redIES',
    'greenIES',
    'darkBlueIES',
    'blueIES',
    'lightBlueIES',
    'orangeIES',
  ]),
  logotypo: PropTypes.string,
  hasUniversityLogo: PropTypes.bool,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  handleDrawerToggle: func,
  miniActive: bool.isRequired,
  location: object,
  open: bool,
  isCompany: bool.isRequired,
  isStudent: bool.isRequired,
};

export default withStyles(sidebarStyle)(Sidebar);
