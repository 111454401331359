import React from 'react';
import { ContainerTitles, H5, P } from './style';

export default function Header({ header, subTitle }) {
  return (
    <ContainerTitles>
      <H5>{header}</H5>
      <P>{subTitle}</P>
    </ContainerTitles>
  );
}
