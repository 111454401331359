import { useContext } from 'react';
import { CsmContext } from '../../contexts/CsmContext';
import { CsmHome } from '../CsmHome';
import { SelectCategory } from '../SelectCategory';

export default function ControllerCarrearService() {
  const { activeCategory } = useContext(CsmContext);

  const returnComponent = activeCategory ? <CsmHome /> : <SelectCategory />;

  return <div>{returnComponent}</div>;
}
