const staffRoles = {
  BOARD: 'board',
  CAREER: 'career',
  COURSE_COORDINATOR: 'course_coordinator',
  UNITY_STAFF: 'unity_staff',
  EDUCATIONAL_GROUP_BOARD: 'educational_group_board',
  TEACHER: 'teacher',
  ACADEMIC_ASSOCIATION: 'academic_association',
};

export default staffRoles;
