import { Container } from './style';
import { useMemo } from 'react';
import { Stepper } from '../CourseCard/style';
import { CircularSkeleton, SkeletonBox } from '../../style';

export function CourseCardSkeleton() {
  const randomCourseNameWidth = useMemo(() => {
    const randomWidth = Math.floor(Math.random() * (150 - 65 + 1) + 65);
    return `${randomWidth}px`;
  }, []);

  return (
    <Container>
      <CircularSkeleton size={'40px'} />
      <h4>
        <SkeletonBox width={'130px'} height={'20px'} />
      </h4>
      <h3>
        <SkeletonBox width={randomCourseNameWidth} height={'20px'} />
      </h3>
      <SkeletonBox width={'215px'} height={'1px'} />
      <Stepper>
        <CircularSkeleton size={'25px'} />
        <SkeletonBox width={'37px'} height={'0.5px'} />
        <CircularSkeleton size={'25px'} />
        <SkeletonBox width={'37px'} height={'0.5px'} />
        <CircularSkeleton size={'25px'} />
      </Stepper>
      <footer>
        <SkeletonBox width={'143px'} height={'16px'} />
      </footer>
    </Container>
  );
}
