import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle';

const scoreRatingStyle = (theme) => ({
  ...modalStyle(theme),
  curriculumMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: 20,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '20px 0',
    },
  },
  choicesMenu: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'inherit',
    },
  },
});

export default scoreRatingStyle;
