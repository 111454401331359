import styled from 'styled-components';

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  > div {
    ${(props) => props.error && 'border: 2px solid red; border-radius: 5px;'}
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
`;
