import styled from 'styled-components';

export const Container = styled.div`
  display: ${(props) => (props.active ? 'flex' : 'none')};
  flex-direction: column;
  &:not(:last-child):after {
    border-bottom: 1px solid rgb(196 196 196 / 30%);
    content: '';
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr 1fr;
  margin-left: 38px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Item = styled.div`
  font-size: 16px;
  line-height: 28px;
`;

export const LastItem = styled(Item)`
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
`;

export const ButtonVisualization = styled.button`
  width: 100%;
  display: flex;
  height: 29px;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  color: #009291;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ffffff;

  justify-content: center;

  :hover {
    .alert-dialog {
      display: flex;
    }
  }

  .alert-dialog {
    padding: 0px 9px;
    bottom: 33px;
    display: none;
    z-index: 10;
    min-width: 60px;
    height: auto;
    background-color: #009291;
    position: absolute;
    color: #000;
    border: #009291 solid 1px;

    border-radius: 4px;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 8px 10px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      left: 27px;
      transform: scaleY(-1);
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 14px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -14px;
      left: 28px;
      transform: scaleY(-1);
    }

    span {
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }
  }
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  height: 29px;
  position: relative;
  align-items: center;
  background-color: #ffffff;
  color: #009291;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #ffffff;
  justify-content: center;

:hover {
    .alert-dialog {
      display: flex;
    }
  }

  .alert-dialog {
    padding: 0px 9px;
    bottom: 33px;
    display: none;
    z-index: 10;
    min-width: 60px;
    height: auto;
    background-color: #009291;
    position: absolute;
    color: #000;
    border: #009291 solid 1px;

    border-radius: 4px;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 8px 10px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      left: 20px;
      transform: scaleY(-1);
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 14px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -14px;
      left: 19px;
      transform: scaleY(-1);
    }

    span {
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }
`;

export const Icon = styled.img`
  width: 24px;
  cursor: pointer;
`;
