import React, { useContext, useState, useEffect, Fragment } from 'react';
import { ContainerCompetences, Container, CallToAction } from './style';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import TechnicalCompetences from 'views/MyProfile/components/TechnicalCompetence/TechnicalCompetences';
import { getTechnicalCompetences } from 'services/student/profile';
import Table from 'components/Table/Table.jsx';
import GenericLoading from 'components/GenericLoading';
import Card from 'components/Card/Card.jsx';

export function CompetencesContent({ cardDataKey, cardData }) {
  const { universityColor, openModalWithContent } = useContext(ProfileContext);
  const [loading, setLoading] = useState(false)

  async function fetchData() {
    const data = await getTechnicalCompetences();
    return openModalWithContent(
      <div style={{'padding': '10px'}}>
        <Card>
          <Table
            tableHeaderColor="primary"
            tableHead={['Competências', 'Rendimento']}
            tableData={data.values.map((obj) => [
              obj.name,
              obj.value,
            ])}
            coloredColls={[3]}
            colorsColls={['primary']}
          />
        </Card>
      </div>
    )
  }

  return (
    <Container>
      <ContainerCompetences>
        {cardData[cardDataKey].average > 0 ? (
          <Fragment>
            <TechnicalCompetences technicalCompetences={cardData[cardDataKey]} />
            <CallToAction
              showButton={true}
              universityColor={universityColor}
              onClick={
                 async () => {
                  setLoading(true);
                  await fetchData()
                  setLoading(false)
                 }
              }
            >
            {loading ? (
              <GenericLoading color={'white'}/>
            ) : (
              'Visualizar Competências'
            )}

            </CallToAction>
          </Fragment>
        ) : null}
      </ContainerCompetences>
    </Container>
  );
}
