import styled from 'styled-components';
import arrowRight from 'assets/Forms/arrowRight.svg';
import arrowLeft from 'assets/Forms/arrowLeft.svg';

export const Container = styled.div`
  padding: 20px 40px;

  > .divider {
    border-bottom: 1px solid #e5e5e5;
    margin-top: 15px;
  }
`;
export const ContainerListing = styled.div`
  background-color: #fff;
  margin-top: 20px;
  min-height: 400px;
  border-radius: 8px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  > div:nth-child(2) {
    position: absolute;
    right: 70px;
    width: auto;

    @media (max-width: 768px) {
      position: absolute;
      right: 70px;
      width: auto;
      position: absolute;
      bottom: ${({ bottom }) => bottom || '-10px'};
      left: 0px;
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    height: ${({ height }) => height || '180px'};
  }
`;

export const ContainerImageAndInformations = styled.div`
  display: flex;
  gap: 10px;

  > div > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  > div > input {
    accent-color: #009291;
    margin-right: 10px;
  }
  > div {
    position: relative;
    > .checkActive {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      right: 0;
      bottom: 33px;
      ${({ active }) => (active ? 'display: flex' : 'display: none')};

      align-items: center;
      justify-content: center;
      padding: 2px;
      background-color: #fff;

      > svg {
        background-color: #009192;
        border-radius: 50%;
      }
    }
  }

  > div {
    > h4 {
      margin: 0;
      color: #606062;
      font-weight: bold;
    }

    > p {
      margin: 0;

      > span {
        font-weight: bold;
        color: #009291;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const Header = styled.div`
  position: relative;

  > .headerButtons {
    display: flex;
    gap: 10px;
    position: absolute;
    right: 15px;
    top: 22px;
    width: 312px;
    justify-content: flex-end;

    @media (max-width: 768px) {
      position: unset;
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
      left: 15px;
      position: absolute;
      top: 50%;
    }

    @media (max-width: 768px) {
      position: relative;
      height: 60px;
      margin-bottom: 35px;
      left: 0px;
    }
  }

  > .studentsConfirmed {
    margin-top: 10px;
    font-size: 14px;

    > span {
      font-weight: 400;
    }
  }

  padding: 20px 40px;

  > h4 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    color: #606062;
  }

  .filtersAndBulk {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

    > div {
      gap: 10px;
      display: flex;
    }
  }
`;

export const ContainerSteps = styled.div`
  > div {
    padding: 30px 45px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 50px;
  padding-bottom: 50px;
`;

const BaseButton = styled.button`
  position: absolute;
  background-color: #009291;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  cursor: pointer;
`;

export const NextButton = styled(BaseButton)`
  right: 8px;
  background-image: url(${arrowRight});
`;

export const PrevsButton = styled(BaseButton)`
  left: 8px;
  background-image: url(${arrowLeft});
`;
export const SaveButton = styled.button`
  position: absolute;
  background-color: #009291;
  color: #fff;
  border: none;
  right: 8px;
  height: 29px;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  align-items: center;
  top: 7px;
  cursor: pointer;

  &:disabled {
    background-color: #949494;
    color: #fff;
  }
`;

export const LabelPresence = styled.span`
  color: ${({ presence }) => (presence ? '#068E2C' : '#DA0505')};
  display: block;
  font-weight: 400;
`;
