
import styled from 'styled-components'

export const Container = styled.div`
    padding: 11px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;
    background-color: #f5f5f5;
    
    margin: 0 24px ;
    gap: 7px;

    label {
      color: #606062;
      font-size: 13px;
      font-weight: 400;
    }


    input[type='checkbox'] {
      background-color: #f5f5f5;
      accent-color: #009291;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    input[type='checkbox']:disabled {
      cursor: default;
    }
`;