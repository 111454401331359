import { Fragment, useContext, useState, useEffect, useRef } from 'react';

import { ListArea, Sentinel } from '../../../Styles/BaseComponent';
import LearningCard from '../../../UIComponents/Card';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';
import { useIntersectionPagination } from 'views/Student/Learning/Hooks/useIntersectionPagination';
import { getLearningFilters } from 'views/Student/Learning/Services';

import { profileAdapter } from 'adapters/student/profileAdapter';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import SkeletonCard from '../../../UIComponents/SkeletonCard';
import BaseLayoutContext from 'contexts/base-layout';
import { CurriculumContext } from 'contexts/CurriculumContext';

export default function AvailableTab({
  activeInternalTab,
  setFilterOptions,
  learningCountControl,
  activeTab,
  scrollUp,
}) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  };
  const limit = 25;

  const { availableListRef } = useRef();
  const { getAvailableLearnings } = useAPICallsLearning();
  const {
    learnings,
    learningsCount,
    currentPageLearnings,
    loading,
    setLearnings,
    setLoadingFilters,
    queryFilter,
  } = useContext(LearningContext);

  const {
    profile,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateAvatarState,
    updateInterestState,
    isOpenCurriculumModal,
    closeCurriculumModal,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);

  useEffect(() => {
    if (activeInternalTab === 1 && (activeTab === 2 || activeTab === 1)) {
      setLearnings(null);
      getAvailableLearnings(true);
    }
  }, [activeInternalTab, activeTab]);

  useEffect(() => {
    if (activeInternalTab === 1 && (activeTab === 2 || activeTab === 1)) {
      setLoadingFilters(true);
      setLearnings(null);

      getLearningFilters('').then((data) => {
        setFilterOptions(data);
        setLoadingFilters(false);
      });
    }
  }, [activeInternalTab, activeTab]);

  const { sentinelRef } = useIntersectionPagination(
    options,
    learnings,
    learningsCount,
    getAvailableLearnings,
    currentPageLearnings,
    limit,
    queryFilter
  );

  return (
    <Fragment>
      <ListArea ref={availableListRef}>
        <LearningCard
          array={learnings}
          availableListRef={availableListRef}
          listingType="Available"
          learningCountControl={learningCountControl}
          scrollUp={scrollUp}
        />
        {loading ? <SkeletonCard quantity={3} /> : null}
      </ListArea>
      {isOpenCurriculumModal &&
        mergeContextsHelper(
          <CurriculumModal
            personalityTestIsDone={profile?.quizzes?.personality.is_done}
            behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
            lifeInterestList={profile?.life_interests}
            interestList={profile?.interests}
            studentProfileState={profileAdapter(profile?.academic_profile)}
            updateBehavioralState={updateBehavioralState}
            updatePersonalityState={updatePersonalityState}
            updateProfileState={updateProfileState}
            updateInterestState={updateInterestState}
            setClose={closeCurriculumModal}
            isOpen={isOpenCurriculumModal}
            updateAvatarState={updateAvatarState}
            handleShowSpecialNeeds={handleShowSpecialNeeds}
          />
        )}
      <Sentinel ref={sentinelRef} />
    </Fragment>
  );
}
