import axios from 'axios';

const getCities = (state) => {
  const response = axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getCities };
