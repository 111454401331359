import * as Yup from 'yup';

export const schemaValidation = Yup.object().shape({
  university: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
  responsible_name: Yup.string().required('Campo obrigatório'),
  responsible_email: Yup.string()
    .email('Insira um e-mail válido')
    .required('Campo obrigatório'),
});
