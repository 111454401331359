import { useEffect, useMemo, useRef, useState } from 'react';
import { useAcademicModeling } from '../../../contexts/AcademicModelingContext';
import {
  Container,
  ContainerListHeader,
  SearchInput,
  ContainerSearch,
} from './style';
import AcademicModelingApi from 'services/university/academicModeling';
import { useDebounce } from '../../../hooks/useDebounce';
import Pagination from 'components/CustomPagination';
import DisciplineItem from './DisciplineItem';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import SkeletonStepOne from './Skeleton';
import ModalInformation from 'components/informationModal';

export default function StepOne() {
  const {
    selectedCourse,
    setSelectedCourse,
    setCheckedDisciplines,
    checkCurrentStep,
  } = useAcademicModeling();

  const pageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [disciplines, setDisciplines] = useState(null);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedDisciplinesIds, setCheckedDisciplinesIds] = useState([]);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [updatesAlertShowed, setUpdatesAlertShowed] = useState(false);
  const [informationModalConfig, setInformationModalConfig] = useState({});
  const [searchTerm, setSearchTerm] = useState('');

  function showReportAlert(checked, discipline) {
    setInformationModalConfig({
      bubbleText: 'Atenção!',
      ParagraphText: `As edições realizadas na etapa de disciplinas poderão
       acarretar em alterações em todas as demais etapas.`,
      buttonConfirmText: 'CONTINUAR',
      buttonCancelText: 'CANCELAR',
      modalHeight: 350,
      showButtonCancel: true,
      buttonConfirmFunction: () => {
        if (discipline) {
          toggleDiscipline(checked, discipline);
        } else {
          checkAll(checked);
        }
        setModalInformationOpen(false);
        setUpdatesAlertShowed(true);
      },
      buttonCancelFunction: () => setModalInformationOpen(false),
      style: {
        textAlign: 'center',
      },
    });
    setModalInformationOpen(true);
  }

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const checkedDisciplines = disciplines?.filter((discipline) =>
      checkedDisciplinesIds.includes(discipline.id)
    );
    setCheckedDisciplines(checkedDisciplines);
  }, [checkedDisciplinesIds]);

  useEffect(() => {
    let searchQuery = `limit=${limit}&offset=0`;
    if (debouncedSearchTerm) {
      searchQuery = `search=${debouncedSearchTerm}&limit=${limit}&offset=0`;
    }
    fetchDisciplines(searchQuery);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (disciplines) {
      const checkedIds = disciplines.filter(
        (discipline) => discipline.is_actual === true
      );
      const mapIds = checkedIds.map((item) => item.id);
      setCheckedDisciplinesIds(mapIds);
    }
  }, [disciplines]);

  const verifyChecked = (id) => {
    return checkedDisciplinesIds.includes(id);
  };

  const fetchDisciplines = async (query) => {
    setLoading(true);
    const { results, count } = await AcademicModelingApi.getDisciplines(
      selectedCourse.id,
      query
    );
    setDisciplines(results);
    setCount(count);
    setLoading(false);
  };

  const toggleDiscipline = async (checked, discipline) => {
    if (checked) {
      setCheckedDisciplinesIds((prevs) => [...prevs, discipline.id]);
      await AcademicModelingApi.putDisciplineMatrix(
        selectedCourse.id,
        `add_disciplines=${discipline.id}`
      );
    } else {
      const filterDiscipline = checkedDisciplinesIds.filter(
        (id) => id !== discipline.id
      );

      setCheckedDisciplinesIds(filterDiscipline);
      await AcademicModelingApi.putDisciplineMatrix(
        selectedCourse.id,
        `remove_disciplines=${discipline.id}`
      );
    }
    await checkCurrentStep();
  };
  const handleCheckDiscipline = async (event, discipline) => {
    const checked = event.target.checked;
    await checkCurrentStep();
    if (
      !updatesAlertShowed &&
      (selectedCourse.steps.step_two || selectedCourse.steps.step_three)
    ) {
      showReportAlert(checked, discipline);
    } else {
      toggleDiscipline(checked, discipline);
    }
  };

  const checkAll = async (checked) => {
    const oldSelectedCourse = { ...selectedCourse };
    if (checked) {
      const mapIds = disciplines.map((item) => item.id);
      setCheckedDisciplinesIds(mapIds);
    } else {
      setCheckedDisciplinesIds([]);
    }
    setSelectedCourse((prev) => ({
      ...prev,
      disciplines_checked: checked,
    }));
    const query = queryParamsFromFilterObject({ add_all_disciplines: checked });
    const response = await AcademicModelingApi.putDisciplineMatrix(
      selectedCourse.id,
      query
    );
    if (response) {
      checkCurrentStep();
    } else {
      setSelectedCourse(oldSelectedCourse);
    }
  };

  const handleClickCheckAll = async (event) => {
    const checked = event.target.checked;
    if (
      !updatesAlertShowed &&
      (selectedCourse.steps.step_two || selectedCourse.steps.step_three)
    ) {
      showReportAlert(checked);
    } else {
      checkAll(checked);
    }
  };

  const mapDisciplines = disciplines?.map((discipline) => (
    <DisciplineItem
      discipline={discipline}
      handleCheckDiscipline={handleCheckDiscipline}
      verifyChecked={verifyChecked}
      fetchDisciplines={fetchDisciplines}
    />
  ));

  const handlePageChange = async (page) => {
    const newPage = `search=${debouncedSearchTerm}&limit=${limit}&offset=${
      (page - 1) * limit || 0
    }`;

    setCurrentPage(page);
    await fetchDisciplines(newPage);
  };

  const handleLimitChange = async (newLimit) => {
    let searchQuery = debouncedSearchTerm
      ? `search=${debouncedSearchTerm}`
      : '';
    const query =
      searchQuery + `${searchQuery && '&'}limit=${newLimit}&offset=0`;
    setCurrentPage(1);
    setLimit(newLimit);
    await fetchDisciplines(query);
  };

  const isAllDisciplinesChecked = useMemo(() => {
    return selectedCourse.disciplines_checked;
  }, [selectedCourse]);

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (20 - 7 + 1) + 7);
  }, []);

  return (
    <Container ref={pageRef}>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={informationModalConfig}
        buttonConfirmFunction={informationModalConfig.buttonConfirmFunction}
        buttonCancelFunction={informationModalConfig.buttonCancelFunction}
      />
      <header>
        <h3>Disciplinas da matriz curricular atual</h3>
        <p>
          {' '}
          Verifique as disciplinas que estão cadastradas no seu sistema
          acadêmico e selecione as que compõem a sua Matriz Curricular atual.
          Confira a carga horária de cada uma e as que não possuem será possível
          adicionar na próxima etapa. Você poderá revisar essa etapa a qualquer
          momento.{' '}
        </p>
      </header>
      <section>
        <ContainerSearch>
          <SearchInput placeholder="Buscar" onChange={handleSearch} />
        </ContainerSearch>
        <ContainerListHeader>
          <input
            type="checkbox"
            className="checkDisciplines"
            onChange={handleClickCheckAll}
            checked={isAllDisciplinesChecked}
          />
          <span className="code">CÓDIGO</span>
          <span className="discipline">DISCIPLINAS</span>
          <span className="workload">CARGA HORÁRIA</span>
          <span className="edit">EDITAR</span>
        </ContainerListHeader>
        {loading
          ? Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
              <SkeletonStepOne key={index} />
            ))
          : mapDisciplines}
      </section>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={count}
        onPageChange={handlePageChange}
        setLimit={handleLimitChange}
        showLimit={count > 20}
        setCurrentPage={setCurrentPage}
        pageRef={pageRef}
        limit={limit}
      ></Pagination>
    </Container>
  );
}
