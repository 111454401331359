import React, { useEffect } from 'react';
import LogosAnimated from '../UIComponents/LogosAnimated';
import logoWorkaloveColor from 'assets/img/icons/logoWorkaloveColor.svg';
import iconLoginCompany from 'assets/img/icons/iconLoginCompany.svg';
import iconLoginIES from 'assets/img/icons/iconLoginIES.svg';
import {
  Container,
  ContainerLogin,
  ContainerAllLogos,
  Titles,
  ContainerChooseUser,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  ButtonUser,
  ContentContainerUser,
  ParagraphUser,
  ImgUser,
  BoxLogos,
  Footer
} from '../styles';

export default function ChooseLogin ({ handleIsLoadingState }) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const dnaColor = "#009291"

  return (
    <Container color={dnaColor}>
      <BoxLogos>
        <ContainerAllLogos>
          <LogosAnimated color={dnaColor} />

          <Titles isLogo>
            <strong>
              Transformando vidas pela educação.
            </strong>
          </Titles>
        </ContainerAllLogos>
      </BoxLogos>
      <ContainerLogin>
        <FragmentContainer>
          <ContainerInCenter isCenter>
            <img src={logoWorkaloveColor} alt="" />
          </ContainerInCenter>
          <Titles isChooseUser>
            <strong>
              Seja bem-vindo! Escolha o
              seu <strong>perfil de usuário</strong> abaixo.
            </strong>
          </Titles>

          <ContainerChooseUser>
            <ButtonUser href="#/login/universidade">
              <ContentContainerUser>
                <ImgUser src={iconLoginIES} />
                <ParagraphUser color={dnaColor}>Instituição</ParagraphUser>
              </ContentContainerUser>
            </ButtonUser>

            <ButtonUser href="#/login/empresa">
              <ContentContainerUser>
                <ImgUser src={iconLoginCompany} />
                <ParagraphUser color={dnaColor}>Empresa</ParagraphUser>
              </ContentContainerUser>
            </ButtonUser>
          </ContainerChooseUser>
        </FragmentContainer>

        <ContainerWorkalove isChooseUser>
          <Footer>
            By <strong>Workalove</strong>
          </Footer>
        </ContainerWorkalove>
      </ContainerLogin>
    </Container>
  );
}
