import { useLearningIcons } from 'views/Student/Learning/Hooks/useLearningIcons'
import { 
  Content, Title, 
  Description, Grid, 
  WrapperItem, ContainerButtons, 
  AdvanceButton, WhiteButton, 
} from './style'
import {Container, Box, ButtonX} from '../../../Styles/BaseModal'
import {returnCouponDetails, returnModality} from '../../../../Utils/tools'
import ReactHtmlParser from 'react-html-parser';
import { modalViewDetailsStructure, returnTypes } from 'views/Student/Learning/Utils/tools';
import { AdditionalHoursFlag, CardDivider } from '../../../Styles/BaseComponent';
import {useState, Fragment, useContext} from 'react'
import { ReactButtons } from '../../ReactButtons';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';
import { ModalCompetencesSpecialities } from '../ModalCompetencesSpecialities';
import moment from 'moment';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalViewDetails({ 
  details, 
  setDetails, 
  controlOpenModal, 
  setControlOpenModal, 
  isFinished,
  handleAccess,
  handleMarkAsRead,
  handleInformConclusion,
  listingType,
  learningCountControl,
  scrollUp
}) {
  const [controlCompetencesSpecialities, setControlCompetencesSpecialities] = useState(false)
  const {universityColor} = useContext(BaseLayoutContext)

  const {
    BsStarFill, 
    BsPersonFill, 
    BrainIcon, 
    RiMoneyDollarCircleFill,
    CalendarIcon,
    MdLocationPin,
    IoExtensionPuzzleOutline,
    IoClose,
    HiWifi
  } = useLearningIcons();

  const handleCloseModal = () => {
    setDetails({})
    setControlOpenModal(false)
  }

  const {
    getAvailableLearnings, 
    getFinalizedLearnings, 
    getFavoritesLearnings
  } = useAPICallsLearning();

  const handleModalCompetencesSpecialities = () => {
    setControlCompetencesSpecialities(true)
  }

  const formatedHours = (details) => {
    if (details?.workload === '1.00') {
      return `${parseInt(details?.workload)} hora complementar`
    }
    if (details?.workload !== '1.00') {
      return `${parseInt(details?.workload)} horas complementares`
    }
  }

  const returnIconsModality = (item) => {
    if (item === 'online') {
      return <HiWifi color={universityColor || "#009291"} size={32} /> 
    }
    return <MdLocationPin color={universityColor || "#009291"} size={32} />
  }

  return (
    <Container isOpen={controlOpenModal}>
      <Box >
        <Content>
          {
            controlCompetencesSpecialities && (
              <ModalCompetencesSpecialities 
                details={details}
                setControlCompetencesSpecialities={setControlCompetencesSpecialities}
                controlCompetencesSpecialities={controlCompetencesSpecialities}
              />
            )
          }
          <ButtonX onClick={() => handleCloseModal()}>
            <IoClose size={25} color="#4B4B4D"/>
          </ButtonX>
          {
            details?.validity && details?.workload && (
              <AdditionalHoursFlag 
                universityColor={universityColor}
              >
                <div className="circle">
                  <BsStarFill size={11} color={universityColor || "#009291"}/>
                </div>
                <p>
                  {formatedHours(details)}
                  
                </p>
              </AdditionalHoursFlag>
            )
          }
          {
            details?.validity?.value === 'additional' && !details?.workload && (
              <AdditionalHoursFlag 
                universityColor={universityColor}
              >
                <div className="circle">
                  <BsStarFill size={11} color={universityColor || "#009291"}/>
                </div>
                <p>
                 Atividade complementar
                </p>
              </AdditionalHoursFlag>
            )
          }
          <Title marginTop={details?.workload}>
            {details?.name}
          </Title>
          <Description>
            {ReactHtmlParser(details?.description)}
          </Description>
          <Grid>
            <div className="left-side">
              {
                modalViewDetailsStructure[details?.type].publishedBy && (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    <BsPersonFill size={32} color={universityColor || "#009291"} />
                    <div className="item">
                      <p>Postado por</p>
                      <span>{details?.created_by.name}</span>
                    </div>
                  </WrapperItem>
                )
              }
              {
                modalViewDetailsStructure[details?.type].dateOfTheEvent && (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    <CalendarIcon size={32} color={universityColor || "#009291"} />
                    <div className="item">
                      <p>Data do evento</p>
                      <span> {moment(details?.created_at).format('DD/MM/YYYY')} </span>
                    </div>
                  </WrapperItem>
                )
              }

              {
                modalViewDetailsStructure[details?.type].coupon && (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    <RiMoneyDollarCircleFill size={32} color={universityColor || "#009291"} />
                    <div className="item">
                      <p>{returnCouponDetails(details)}</p>
                      {
                        details?.discount_ticket !== '' && (
                          <span> {details?.discount_ticket && `Cupom: ${details?.discount_ticket}`}  </span>
                        )
                      }
                    </div>
                  </WrapperItem>
                )
              }
              {
                modalViewDetailsStructure[details?.type].type && (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    <IoExtensionPuzzleOutline size={32} color={universityColor || "#009291"} />
                    <div className="item">
                      <p>Tipo de conteúdo</p>
                      <span>{returnTypes[details?.type]}</span>
                    </div>
                  </WrapperItem>
                )
              }
            </div>
            <div className="right-side">
              {
                modalViewDetailsStructure[details?.type].relatedKnowledge &&
                details?.competences_specialities.length > 0  &&
                (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    <BrainIcon color={universityColor || "#009291"} size={31} />
                    <div className="item">
                      <p>Conhecimentos relacionados</p>
                      <button 
                        onClick={() => handleModalCompetencesSpecialities(details)}
                      >
                        Clique para ver
                      </button>
                    </div>
                  </WrapperItem>
                )
              }
              {
                modalViewDetailsStructure[details?.type].modality && (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    {returnIconsModality(details?.modality)}
                    <div className="item">
                      <p>{returnModality(details)}</p>
                    </div>
                  </WrapperItem>
                )
              }
              {
                modalViewDetailsStructure[details?.type].postedIn && (
                  <WrapperItem 
                    universityColor={universityColor}
                  >
                    <CalendarIcon size={32} color={universityColor || "#009291"} />
                    <div className="item">
                      <p>Publicado em</p>
                      <span>27/03/2021</span>
                    </div>
                  </WrapperItem>
                )
              }
            </div>
          </Grid>
          <CardDivider marginOnTheSide="0" />
          <ContainerButtons>
            <div className="buttons-access">
              {
                details?.type === "announcements" && !isFinished && (
                  <AdvanceButton 
                    fullWidth
                    universityColor={universityColor}
                    onClick={() => handleMarkAsRead(details)}
                  > 
                    Marcar como lido 
                  </AdvanceButton>
                )}
                {
                  details?.type !== "announcements" &&
                  (
                    <Fragment>
                      {
                        details?.type_access !== "" && (
                          <AdvanceButton 
                            universityColor={universityColor}
                            onClick={() => handleAccess(details)}
                          > 
                            Acessar 
                          </AdvanceButton>
                        )
                      }
                      {
                        !isFinished  && (
                          <WhiteButton
                            universityColor={universityColor}
                            onClick={() => handleInformConclusion(details)}
                          > 
                            Informar conclusão 
                          </WhiteButton>
                        )
                      }
                    </Fragment>
                  )
                }
            </div>
            <ReactButtons 
              id={details.id}
              item={details}
              resetListAvailable={getAvailableLearnings}
              resetListFavorite={getFavoritesLearnings}
              resetListFinalized={getFinalizedLearnings}
              listingType={listingType}
              isFinished={isFinished}
              learningCountControl={learningCountControl}
              scrollUp={scrollUp}
              modalView
              setControlViewDetails={setControlOpenModal}
            />

          </ContainerButtons>


        </Content>
      </Box>
    </Container>
  )
}
