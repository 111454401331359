import { useState, useCallback } from 'react';
import GeneratedLists from './Tabs/GeneratedLists';
import InsideTabs from './components/InsideTabs';
import { tabsController } from './components/InsideTabs/TabsController';
import { Wrapper, Container, ContainerPagination } from './style';
import CreatedCampaigns from './Tabs/CreatedCampaigns';

import Pagination from 'components/CustomPagination';

import ListItems from './components/ListItems';
import { SendEmailProvider } from '../../contexts/SendEmailContext';
import FullScreenModal from './components/FullScreenModal';
import EngineSteps from './components/StepsForm/List/EngineSteps';
import sendEmailApi from '../../services/sendEmailApi';
import { useCsm } from '../../contexts/CsmContext';

export default function EngineSendEmail() {
  const { egressed } = useCsm();
  const [activeTab, setActiveTab] = useState(0);
  const [FullModalActive, setFullModalActive] = useState(false);
  const [FullScreenModalChildren, setFullScreenModalChildren] = useState(
    <EngineSteps />
  );

  const [currentPageListage, setCurrentPageListage] = useState(1);
  const [countListage, setCountListage] = useState(0);
  const [limitListage, setLimitListage] = useState(20);
  const [listageGeneratedList, setListageGeneratedList] = useState([]);
  const [listageCampaignList, setListageCampaignList] = useState([]);

  const [currentPageCampaign, setCurrentPageCampaign] = useState(1);
  const [countCampaign, setCountCampaign] = useState(0);
  const [limitCampaign, setLimitCampaign] = useState(20);

  const handlePageChangeListage = async (page) => {
    const newPage = `limit=${limitListage}&offset=${
      (page - 1) * limitListage || 0
    }`;
    setCurrentPageListage(page);
    const { count, results } = await sendEmailApi.getListage(newPage, egressed);
    setCountListage(count);
    setListageGeneratedList(results);
  };

  const handlePageChangeCampaign = async (page) => {
    const newPage = `limit=${limitCampaign}&offset=${
      (page - 1) * limitCampaign || 0
    }`;
    setCurrentPageCampaign(page);
    const { count, results } = await sendEmailApi.getCampaign(
      newPage,
      egressed
    );
    setCountCampaign(count);
    setListageCampaignList(results);
  };

  const setLimitOnList = async (value) => {
    const newPage = `limit=${value}&offset=${
      (currentPageListage - 1) * value || 0
    }`;
    const { count, results } = await sendEmailApi.getListage(newPage);
    setLimitListage(value);
    setCountListage(count);
    setListageGeneratedList(results);
  };

  const setLimitOnCampaign = async (value) => {
    const newPage = `limit=${value}&offset=${
      (currentPageCampaign - 1) * value || 0
    }`;
    const { count, results } = await sendEmailApi.getCampaign(
      newPage,
      egressed
    );
    setLimitCampaign(value);
    setCountCampaign(count);
    setListageCampaignList(results);
  };

  const currentPropsPagination = {
    0: {
      currentPage: currentPageListage,
      totalCount: countListage,
      limit: limitListage,
      setLimit: setLimitOnList,
      setCount: setCountListage,
      setCurrentPage: setCurrentPageListage,
      onPageChange: handlePageChangeListage,
    },
    1: {
      currentPage: currentPageCampaign,
      totalCount: countCampaign,
      limit: limitCampaign,
      setLimit: setLimitOnCampaign,
      setCount: setCountCampaign,
      setCurrentPage: setCurrentPageCampaign,
      onPageChange: handlePageChangeCampaign,
    },
  };

  const handleTabChange = useCallback((_, value) => {
    setActiveTab(value);
    setCurrentPageListage(1);
    setCurrentPageCampaign(1);
  }, []);

  const componentsMap = {
    0: <GeneratedLists />,
    1: <CreatedCampaigns />,
  };

  const activeComponent = componentsMap[activeTab];

  if (FullModalActive) {
    return (
      <SendEmailProvider
        setFullModalActive={setFullModalActive}
        currentPropsPagination={currentPropsPagination}
        FullScreenModalChildren={FullScreenModalChildren}
        setFullScreenModalChildren={setFullScreenModalChildren}
        listageCampaignList={listageCampaignList}
        setListageCampaignList={setListageCampaignList}
        egressed={egressed}
      >
        <FullScreenModal setFullModalActive={setFullModalActive}>
          {FullScreenModalChildren}
        </FullScreenModal>
      </SendEmailProvider>
    );
  }

  return (
    <SendEmailProvider
      FullModalActive={FullModalActive}
      setFullModalActive={setFullModalActive}
      listageGeneratedList={listageGeneratedList}
      setListageGeneratedList={setListageGeneratedList}
      currentPropsPagination={currentPropsPagination}
      FullScreenModalChildren={FullScreenModalChildren}
      setFullScreenModalChildren={setFullScreenModalChildren}
      listageCampaignList={listageCampaignList}
      setListageCampaignList={setListageCampaignList}
    >
      <Wrapper>
        <Container>
          <InsideTabs
            tabs={tabsController}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            egressed={egressed ?? false}
          />
          {activeComponent}
        </Container>
      </Wrapper>
      <ContainerPagination>
        <Pagination
          className="pagination-bar"
          {...currentPropsPagination[activeTab]}
        />
      </ContainerPagination>
    </SendEmailProvider>
  );
}
