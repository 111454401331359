import React, { useContext } from 'react';
import { Title, Paragraph, TitleStepThree, ScopeStepThree } from '../styled';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

export default function StepThree() {
  const { gamificationLevel } = useContext(ProfileContext);
  const levelThree = gamificationLevel === 3;
  const noLevelThree = gamificationLevel !== 3;

  const returnParagraph = () => {
    if (noLevelThree) {
      return `
      Ao concluir a Jornada do Protagonismo, você terá um perfil campeão, 
      com um currículo mais atraente e, consequentemente, você terá maior 
      probabilidade de alcançar as melhores vagas no sistema.
      `;
    }
    if (levelThree) {
      return `
      Parabéns! Agora você tem um perfil campeão e possui mais chances de se 
      destacar no mercado de trabalho. Lembre-se de sempre atualizar seu currículo 
      do futuro com os novos conhecimentos e experiências adquiridas. 
      Em breve tudo que você incluir no seu perfil valerá pontos qualificáveis para 
      que você possa se destacar ainda mais!
      `;
    }
  };

  return (
    <ScopeStepThree>
      <TitleStepThree>
        <Title isStepThree>Perfil Campeão</Title>
        {noLevelThree ? <IconBlocked color={'#606062'} /> : null}
      </TitleStepThree>

      <Paragraph>{returnParagraph()}</Paragraph>
    </ScopeStepThree>
  );
}
