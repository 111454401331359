import React, { useEffect, useState } from 'react';
import { Main, Header, Form } from './styled';
import SearchableSelect from 'react-select';
import { FormControl, InputLabel } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import classNames from 'classnames';
import {
  getCitiesFilters,
  getStatesFilters,
  getFilteredIesConnection,
} from 'services/companies';
import qs from 'querystring';

function IesFilter(props) {
  const { classes, requesting, setUniversities, fetchUniversities } = props;

  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const [statesQS, setStatesQS] = useState([]);
  const [citiesQS, setCitiesQS] = useState([]);

  const handleFilterChange = {
    states: async function (states) {
      if (states.length > 0) {
        // CitiesOptions and statesGet
        citiesAndStates.states.statesGet(states);
      } else {
        // Clear filter
        fetchUniversities();
        setSelectedCities([]);
        setCitiesOptions([]);
        setSelectedStates([]);
      }
    },
    cities: async function (cities) {
      if (cities.length > 0) {
        // CitiesGet
        citiesAndStates.cities.citiesGet(cities);
      } else {
        // ClearCity
        setSelectedCities([]);
        citiesAndStates.states.statesGet(selectedStates);
      }
    },
  };

  const citiesAndStates = {
    states: {
      statesOptions: async function () {
        // StatesOptions
        const response = await getStatesFilters();
        setStatesOptions(
          response.state.sort((a, b) => (a.label > b.label ? 1 : -1))
        );
      },
      statesGet: async function (states) {
        // GetStates and citiesOptions
        const statesArray = [];
        states.map((state) => {
          statesArray.push(state.value);
        });
        const response = await getFilteredIesConnection(
          qs.stringify({ states: statesArray })
        );
        setUniversities(response);
        setStatesQS(statesArray);
        citiesAndStates.cities.citiesOptions(statesArray);
      },
    },
    cities: {
      citiesOptions: async function (states) {
        // CitiesOptions
        const response = await getCitiesFilters(
          qs.stringify({ states: states })
        );
        setCitiesOptions(response.city);
      },
      citiesGet: async function (cities) {
        // GetCities
        const citiesArray = [];
        cities.map((city) => citiesArray.push(city.value));
        const response = await getFilteredIesConnection(
          qs.stringify({
            states: statesQS,
            cities: citiesArray,
          })
        );
        setUniversities(response);
      },
    },
  };
  useEffect(() => {
    citiesAndStates.states.statesOptions();
  }, []);

  return (
    <Main>
      <Header>Filtro avançado</Header>
      <Form>
        <h3>
          <strong>Localização:</strong>
        </h3>
        <FormControl
          variant="filled"
          fullWidth
          style={{ marginBottom: '10px' }}
        >
          <InputLabel htmlFor="state" shrink>
            Estado
          </InputLabel>
          <SearchableSelect
            name="state"
            id="state"
            isClearable
            isMulti
            // isDisabled
            InputLabel={{ shrink: true }}
            className={classNames({
              [classes.selectSection]: true,
              [classes.selectSectionAutoHeight]: !requesting,
              [classes.selectControlHeight]: requesting,
              [classes.selectSectionCard]: true,
            })}
            placeholder="Selecione um estado"
            isSearchable
            value={selectedStates}
            options={
              (statesOptions && statesOptions.length > 0 && statesOptions) || []
            }
            classNamePrefix="react-select"
            onChange={(event) => {
              if (event) {
                handleFilterChange.states(event);
                setSelectedStates(event);
              }
            }}
          />
        </FormControl>
        <FormControl variant="filled" fullWidth>
          <InputLabel htmlFor="city" shrink>
            Cidade
          </InputLabel>
          <SearchableSelect
            name="city"
            id="city"
            isClearable
            isMulti
            isDisabled={!citiesOptions.length > 0}
            InputLabel={{ shrink: true }}
            className={classNames({
              [classes.selectSection]: true,
              [classes.selectSectionAutoHeight]: citiesOptions.length > 0,
              [classes.selectControlHeight]: !citiesOptions.length > 0,
              [classes.selectSectionCard]: true,
            })}
            placeholder="Selecione um estado"
            isSearchable
            value={selectedCities}
            classNamePrefix="react-select"
            options={
              (citiesOptions && citiesOptions.length > 0 && citiesOptions) || []
            }
            onChange={(event) => {
              if (event) {
                handleFilterChange.cities(event);
                setSelectedCities(event);
              }
            }}
          />
        </FormControl>
      </Form>
    </Main>
  );
}

export default withStyles(ProfessionalExperienceFormStyles)(IesFilter);
