import React from 'react';
import walImage from 'assets/img/walModal.png';
import {
  ActionButton,
  CloseButton,
  Dialog,
  DialogContainer,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from './styles';

export function ModalDialog({
  isOpen,
  onClose,
  dialog,
  description,
  buttonLabel,
  actionButton,
}) {
  function handleAction() {
    !!actionButton && actionButton();
    onClose();
  }

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer>
        <ModalHeader>
          <CloseButton onClick={onClose} />
        </ModalHeader>
        <ModalContent>
          <DialogContainer>
            <img src={walImage} alt="" />
            <Dialog>{dialog}</Dialog>
          </DialogContainer>
          <p>{description}</p>
          <ActionButton onClick={handleAction}>{buttonLabel}</ActionButton>
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
}
