import React, { useState, useRef } from 'react';
import { Container, SubContainer } from './styled';
import GenericInternalTab from 'components/GenericInternalTab';
import { ExpiredSubTab } from './ExpiredSubTab';
import { RealizedSubTab } from './RealizedSubTab';
import { CanceledSubTab } from './CanceledSubTab';
import { useHistoricMentorshipContext } from '../context';
import { useMentorship } from 'views/Student/Mentorship/context/MentorshipContext';

export default function InternalTab({ currentTab }) {
  const { setMentorships, setPage } = useHistoricMentorshipContext();
  const {
    mentorshipCounts,
    updateMentorshipsCount,
    fetchMentorshipsCount,
    activeInternalTabHistoric,
    setActiveInternalTabHistoric,
  } = useMentorship();

  const handleTabChange = (_, value) => {
    if (activeInternalTabHistoric !== value) {
      setMentorships(null);
    }
    setPage(1);
    setActiveInternalTabHistoric(value);
  };

  const tabs = [
    `Realizadas (${mentorshipCounts.realized ?? 0})`,
    `Canceladas (${mentorshipCounts.canceled ?? 0})`,
    `Expiradas (${mentorshipCounts.expired ?? 0})`,
  ];

  const components = {
    0: (
      <RealizedSubTab
        updateMentorshipsCount={updateMentorshipsCount}
        currentTab={currentTab}
      />
    ),
    1: (
      <CanceledSubTab
        updateMentorshipsCount={updateMentorshipsCount}
        fetchMentorshipsCount={fetchMentorshipsCount}
        currentTab={currentTab}
      />
    ),
    2: (
      <ExpiredSubTab
        updateMentorshipsCount={updateMentorshipsCount}
        fetchMentorshipsCount={fetchMentorshipsCount}
        currentTab={currentTab}
        isActiveExpiredTab={activeInternalTabHistoric === 2}
      />
    ),
  };

  const returnCurrentComponentTab = () => {
    return components[activeInternalTabHistoric];
  };

  return (
    <Container>
      <SubContainer>
        <div style={{ width: '100%' }}>
          <GenericInternalTab
            tabs={tabs}
            activeTab={activeInternalTabHistoric}
            handleTabChange={handleTabChange}
            zIndex={'100'}
          />
        </div>
      </SubContainer>
      {returnCurrentComponentTab()}
    </Container>
  );
}
