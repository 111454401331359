import GenericInternalTab from 'components/GenericInternalTab';
import {
  Container,
  InsideInformations,
  Header,
  Content,
  Box,
  ContainerBottomInformations,
  ContainerButtons,
  Divider,
} from './styled';
import Competencies from './Tabs/Competencies';
import AboutEvent from './Tabs/AboutEvent';
import Programation from './Tabs/Programation';
import { useContext, useState } from 'react';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import BaseLayoutContext from 'contexts/base-layout';
import Button from '../Button';
import ReactionActions from '../ReactionActions';
import ModalInformation from 'components/informationModal';
import { VisualizationHandler } from '../../Handlers/VisualizationHandler';
import AboutEventDay from './Tabs/AboutEventDay';
import { CurriculumContext } from 'contexts/CurriculumContext';
import { checkCurriculumFilled } from 'services/student/profile';

export default function DetailsModal() {
  const [activeTab, setActiveTab] = useState(0);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const visualizationContextInstance = useVisualizationContext();

  const { profile, setIsOpenCurriculumModal } = useContext(CurriculumContext);

  const {
    detailsModalOpen,
    setDetailsModalOpen,
    detailsObject,
    currentTab,
  } = visualizationContextInstance;
  const { universityColor } = useContext(BaseLayoutContext);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const verifyRegistrationDate = () => {
    const today = new Date();
    const registrationStartDate = new Date(
      detailsObject.registration_start_date
    );

    return today >= registrationStartDate;
  };

  const currentBottomButtons = () => {
    if (currentTab === 0) {
      return (
        <Button
          width="130px"
          theme="flat"
          onClick={() => setModalInformationOpen(true)}
        >
          Cancelar inscrição
        </Button>
      );
    }

    if (currentTab === 1) {
      const handleClickSubscribe = async () => {
        const check = await checkCurriculumFilled();
        if (!check) {
          handleClickClose();

          return setIsOpenCurriculumModal(true);
        }

        if (
          !(await VisualizationHandler.verifyFairAndEvents(
            visualizationContextInstance,
            detailsObject.id
          ))
        ) {
          handleClickClose();
          return;
        }
        VisualizationHandler.subscribeStudent(
          visualizationContextInstance,
          detailsObject.id
        );
        handleClickClose();
      };

      return (
        verifyRegistrationDate() &&
        !(registred_students === limit_students && limit_students !== 0) && (
          <Button onClick={handleClickSubscribe} width="130px" theme="light">
            Inscrever-se
          </Button>
        )
      );
    }
  };

  const {
    event_schedule,
    associated_areas,
    about_event_day,
    registred_students,
    limit_students,
  } = detailsObject;

  const hasCompetencies = associated_areas?.length > 0;

  let tabs = [];
  let componentTabs = {};

  if (about_event_day) {
    tabs.push('Dia do evento');
    componentTabs[tabs.length - 1] = <AboutEventDay />;
  }

  tabs.push('Sobre o evento');
  componentTabs[tabs.length - 1] = <AboutEvent />;

  if (event_schedule) {
    tabs.push('Programação');
    componentTabs[tabs.length - 1] = <Programation />;
  }

  if (hasCompetencies) {
    tabs.push('Competências');
    componentTabs[tabs.length - 1] = <Competencies />;
  }

  if (!detailsModalOpen) {
    return null;
  }

  const handleClickClose = () => {
    setDetailsModalOpen(false);
  };

  const componentActiveTab = componentTabs[activeTab];

  const configModalInformation = {
    bubbleText: 'Atenção!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    buttonCancelWidth: 75,
    buttonCancelHeight: 38,
    ParagraphText: `
    Tem certeza que deseja cancelar a sua inscrição neste evento?
    `,
    TwoParagraphText: `Caso não tenham mais vagas disponíveis, você não poderá se inscrever novamente.`,
    buttonConfirmText: 'SIM, CONTINUAR',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
  };

  const handleConfirmModalInformation = () => {
    VisualizationHandler.onConfirmCancelSubscribe(visualizationContextInstance);
    setModalInformationOpen(false);
    handleClickClose();
  };

  return (
    <Container>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        buttonConfirmFunction={handleConfirmModalInformation}
        buttonCancelFunction={() => setModalInformationOpen(false)}
        config={configModalInformation}
        universityColor={universityColor}
      />
      <Box>
        <Content>
          <Header color={universityColor}>
            <CloseButton onClick={handleClickClose} />
          </Header>

          <GenericInternalTab
            activeTab={activeTab}
            tabs={tabs}
            handleTabChange={handleTabChange}
          />

          <InsideInformations>
            {componentActiveTab}

            <Divider />

            <ContainerBottomInformations>
              <ContainerButtons>{currentBottomButtons()}</ContainerButtons>

              <ReactionActions event={detailsObject} />
            </ContainerBottomInformations>
          </InsideInformations>
        </Content>
      </Box>
    </Container>
  );
}
