import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 2rem;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    padding: 1rem;
  }
`;

export const WrapperInformations = styled.div`
  padding: 2rem 2rem 0;
  width: 610px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;

  > span {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    color: #009291;
    margin-left: 5px;
  }

  > div {
    flex-direction: column;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const WrapperForm = styled.div`
  padding: 1rem 2rem 0;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;

  @media screen and (max-width: 768px) {
    padding: 1rem 2rem 1rem;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
`;

export const SubmitButton = styled.button`
  width: 90px;
  height: 44px;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 3px;
  opacity: 0px;
  background-color: ${(props) => props.color || '#009291'};
  margin: 20px 0;
  border: none;

  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.88px;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }

  @media screen and (max-width: 768px) {
    padding: 10px 20px;
  }
`;
