import React, { useContext, useRef, useEffect, useState } from 'react';
import {
  Box,
  Container,
  ContainerButton,
  Content,
  GreenButton,
  WrapperMessage,
  WrapperTitle,
  BubbleDialog,
  ButtonGray,
} from '../ModalStylingPattern/styles';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';
import { unpublishProject } from 'views/StaffPages/Opportunities/services';

export default function ModalUnpublishNoFeedback(props) {
  const {
    extensionProjectId,
    getAllOpenForSubscription,
    getAllFutureProjects,
    getAllInProgressProjects,
    setFutureExtension,
    setInProgressExtension,
    setOpenForSubscriptionsExtension,
  } = useContext(ExtensionProjectsContext);

  const { isOpen, setOpenModalUnpublish, closeTheParentModal } = props;
  const modalUnpublish = useRef();

  const closeModal = () => {
    setOpenModalUnpublish(false);
  };

  const closeModalClick = (e) => {
    if (modalUnpublish.current === e.target) {
      setOpenModalUnpublish(false);
    }
  };

  const handleUpdate = async () => {
    await unpublishProject(extensionProjectId);
    setOpenModalUnpublish(false);
    closeTheParentModal(false);

    setFutureExtension(null);
    setInProgressExtension(null);
    setOpenForSubscriptionsExtension(null);

    getAllOpenForSubscription(true);
    getAllFutureProjects(true);
    getAllInProgressProjects(true);
  };

  return (
    <Container isOpen={isOpen}>
      <Box ref={modalUnpublish} onClick={closeModalClick}>
        <Content>
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <BubbleDialog widthBubble="123px" heightBubble="48px">
              <span> Atenção! </span>
            </BubbleDialog>
          </WrapperTitle>

          <WrapperMessage
            fontMessage="20px"
            lineHeigthMessage="28px"
            positionMessage="start"
          >
            Tem certeza que deseja despublicar esse projeto? Essa ação não
            poderá ser desfeita.
          </WrapperMessage>

          <ContainerButton>
            <ButtonGray marginTopButton="10px" onClick={closeModal}>
              NÃO
            </ButtonGray>
            <GreenButton
              widthButton="211px"
              marginTopButton="10px"
              type="button"
              onClick={handleUpdate}
            >
              SIM, DESPUBLICAR
            </GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  );
}
