import { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import {
  CheckboxWrapper,
  CheckboxInput,
  CheckboxLabel,
} from 'views/ProfessionalStatus/styles/styles';

const Checkbox = ({ name, value, label, universityColor, ...rest }) => {
  const inputRef = useRef();
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const defaultChecked = defaultValue === value;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked;
      },
      clearValue: (ref) => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      },
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <CheckboxWrapper universityColor={universityColor}>
      <CheckboxInput
        defaultChecked={defaultChecked}
        ref={inputRef}
        value={value}
        type="checkbox"
        id={fieldName}
        universityColor={universityColor}
        {...rest}
      />

      <CheckboxLabel htmlFor={fieldName} key={fieldName}>
        {label}
      </CheckboxLabel>

      {error && <span>{error}</span>}
    </CheckboxWrapper>
  );
};
export default Checkbox;
