import React, { useState, useEffect } from 'react';

/**
 * this is a document and file viewer its focus is PDF files,
 * to work with PDF it has new features, but it also displays other
 * types of files/documents.
 * use file for files already loaded into memory and fileLink for loading from a link
 * only one should be used.
 *
 * @param {file} file use the file parameter to display file (binary type file)
 * @param {string} fileLink use this parameter to display a file from a url example: 'https://domain.subdomain.file.pdf'
 * @param {object} style - Custom style for your current usage (optional)
 * @returns {JSX.Element} Returns a document viewer component.
 */
const DisplayDocument = ({ file = null, style = null, fileLink = null }) => {
  const [fileState, setFileState] = useState(file);
  const [fileLinkState, setFileLinkState] = useState(fileLink);
  const [fileDisplay, setFileDisplay] = useState(null);
  const [documentValidate, setDocumentValidete] = useState(null);
  const defaultStyle = {
    margin: '0 auto',
    width: '100%',
    height: '100vh',
  };

  const isMimeTypeSupported = (mime) => {
    // this function will validate the types of files that
    // can be displayed or not by this component just by evaluating its type,
    // you can increase it if you need to
    const supportedMimeTypes = [
      'application/pdf',
      'image/png',
      'image/jpeg',
      'video/mp4',
      'text/html',
      // add if you have tested...
    ];

    return supportedMimeTypes.includes(mime);
  };

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await fetch(fileLink);
        const blob = await response.blob();
        const mimeType = blob.type;

        if (isMimeTypeSupported(mimeType)) {
          setFileDisplay(new File([blob], 'document', { type: mimeType }));
          setDocumentValidete(true);
        } else {
          setDocumentValidete(false);
          throw new Error('Unsupported file type.');
        }
      } catch (error) {
        console.error('Error fetching file:', error);
        setDocumentValidete(false);
      }
    };
    if (file) {
      if (isMimeTypeSupported(file.type)) {
        setDocumentValidete(true);
        setFileDisplay(file);
      } else {
        setDocumentValidete(false);
        throw new Error('unsupported file type.');
      }
    } else {
      fetchFile();
    }
  }, [fileState, fileLinkState]);

  return (
    <div style={style ? style : defaultStyle}>
      {documentValidate && fileDisplay ? (
        <iframe
          src={URL.createObjectURL(fileDisplay)}
          title="Document Viewer"
          width="100%"
          height="100%"
          allowFullScreen
        />
      ) : (
        <h3>Documento não suportado</h3>
      )}
    </div>
  );
};

export default DisplayDocument;
