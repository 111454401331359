export function TotalHitsIcon({ color = '#009291', size = 21 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_34256_5071)">
        <path
          d="M2.16667 13C2.16667 13.4583 2.54167 13.8333 3 13.8333H9.66667C10.125 13.8333 10.5 14.2083 10.5 14.6667C10.5 15.125 10.125 15.5 9.66667 15.5H3C1.625 15.5 0.5 14.375 0.5 13C0.5 11.9167 1.2 11 2.16667 10.65V4.66667C2.16667 2.36667 4.03333 0.5 6.33333 0.5H14.6667C16.9667 0.5 18.8333 2.36667 18.8333 4.66667V5.5C18.8333 5.95833 18.4583 6.33333 18 6.33333C17.5417 6.33333 17.1667 5.95833 17.1667 5.5V4.66667C17.1667 3.29167 16.0417 2.16667 14.6667 2.16667H6.33333C4.95833 2.16667 3.83333 3.29167 3.83333 4.66667V10.5H7.58333C7.80833 10.5 8.01667 10.5917 8.175 10.7417L8.76667 11.3333H9.675C10.1333 11.3333 10.5083 11.7083 10.5083 12.1667C10.5083 12.625 10.1333 13 9.675 13H8.425C8.2 13 7.99167 12.9083 7.83333 12.7583L7.24167 12.1667H3C2.54167 12.1667 2.16667 12.5417 2.16667 13ZM20.5 11.3333V17.1667C20.5 19.0083 19.0083 20.5 17.1667 20.5H15.5C13.6583 20.5 12.1667 19.0083 12.1667 17.1667V11.3333C12.1667 9.49167 13.6583 8 15.5 8H17.1667C19.0083 8 20.5 9.49167 20.5 11.3333ZM18.8333 11.3333C18.8333 10.4167 18.0833 9.66667 17.1667 9.66667H15.5C14.5833 9.66667 13.8333 10.4167 13.8333 11.3333V17.1667C13.8333 18.0833 14.5833 18.8333 15.5 18.8333H17.1667C18.0833 18.8333 18.8333 18.0833 18.8333 17.1667V11.3333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_34256_5071">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
