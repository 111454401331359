import styled from 'styled-components';
import { Grid, Button } from '@material-ui/core';

export const CoverLetterContainer = styled(Grid)`
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
`;

export const MenuContainer = styled(Grid)`
  padding-left: 20px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const Menu = styled.div`
  position: sticky !important;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  gap: 10px;
  @media (max-width: 768px) {
    margin: 20px 0;
  }
`;

export const DefaultButton = styled(Button)`
  background-color: #2f9190;
  border-radius: 30px;
  float: right;
  color: #fff;

  & svg {
    position: relative;
    top: 0;
    margin-right: 3px;
    margin-left: 20px;
    width: 30px;
    height: 30px;
  }

  &:hover {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }
`;

export const PrintButton = styled(DefaultButton)`
  margin-top: 20px;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'} !important;
  background-color: transparent;
  font-weight: 700;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
