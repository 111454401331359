import styled from 'styled-components';

export const UnInput = styled.input`
  border: 1px solid #d0d0d0;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  &:focus {
    border: 1px solid #2784ff;
  }
`;

export const UnInputLink = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  padding: 12px 30px;
  margin-bottom: 10px;
  &:focus {
    border-bottom: 1px solid #009291;
  }
`;
export const UnImageLink = styled.img`
  width: 20px;
  position: absolute;
  top: 30px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 7px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
`;

export const UnInputDesc = styled.textarea`
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 10em;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none;
  font-family: sans-serif;
  &:focus {
    border: 2px solid #2784ff;
  }
`;
