export const HourHelper = {
  formatDefault: (hour) => {
    const [hours, minutes] = hour.split(':');

    if (minutes !== '00') {
      return `${hours}h${minutes}`;
    }
    return `${hours}h`;
  },
};
