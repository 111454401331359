import styled from 'styled-components';

export const FooterText = styled.div`
  color: #c5c5c5;
  min-height: auto;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 10px;
  position: relative;

  @media screen and (max-width: 550px) {
    font-size: 12px
  }
`;
