import React from 'react';
import {
  InformationSkeleton,
  BackgroundModalsInterviewSkeleton,
  ContainerModalInterview,
  HeaderInterviewSkeleton,
  ContainerInterviewSkeleton,
} from '../../NewCards/styled';

const SkeletonModalsInterview = () => {
  return (
    <BackgroundModalsInterviewSkeleton>
      <ContainerModalInterview>
        <HeaderInterviewSkeleton />

        <ContainerInterviewSkeleton>
          <InformationSkeleton />
          <InformationSkeleton />
          <InformationSkeleton />
        </ContainerInterviewSkeleton>
      </ContainerModalInterview>
    </BackgroundModalsInterviewSkeleton>
  );
};

export default SkeletonModalsInterview;
