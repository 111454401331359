import styled from 'styled-components';
import searchImg from 'assets/Matriz/search.svg';

export const SearchInputWrapper = styled.input`
  padding-left: 40px;
  background-image: url(${searchImg});
  background-repeat: no-repeat;
  background-position: 12px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
`;
