import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 292px;
  height: 240px;
  box-shadow: 0 0 18px 0 #00000017;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;

  span {
    color: #606062;
    font-size: 12px;
  }

  h1 {
    color: #606062;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 0.5rem 0 0 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    padding: 0;
    gap: 0.5rem;

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      gap: 0.5rem;

      p {
        margin: 0;
        color: #606062;
        font-size: 13px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 48px;
  background-color: white;
  margin: auto 0 -16px -16px;
  border: none;
  cursor: pointer;
  color: #009291;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  border-radius: 0 0 10px 10px;
  box-sizing: border-box;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;
