import React, { useRef } from 'react';
import CardChallenges from './CardChallenges';

import { WrapperDiv, WrapperPublished } from './styles';

const BodyCards = (props) => {
  const {
    setActiveTab,
    isOpenModal,
    setIsOpenModal,
    formData,
    setFormData,
    currentStep,
    setCurrentStep,
    cacheOptions,
    setCacheOptions,
    setFormValues,
    challenges,
    count,
    loadChallenges,
    setChallenges,
    modalInfoOpen,
    setModalInfoOpen,
  } = props;

  const ref = useRef();

  const ScrollToTop = () => {
    if (ref) {
      ref.current.scrollIntoView();
    }
  };

  return (
    <WrapperPublished>
      <WrapperDiv ref={ref}>
        <CardChallenges
          referencia={ref}
          title={'Desafios'}
          subTitle={'Veja aqui os desafios publicados pela sua empresa'}
          setActiveTab={setActiveTab}
          formData={formData}
          setFormData={setFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          cacheOptions={cacheOptions}
          setCacheOptions={setCacheOptions}
          setFormValues={setFormValues}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          challenges={challenges}
          count={count}
          loadChallenges={loadChallenges}
          setChallenges={setChallenges}
          modalInfoOpen={modalInfoOpen}
          setModalInfoOpen={setModalInfoOpen}
          ScrollToTop={ScrollToTop}
        />
      </WrapperDiv>
    </WrapperPublished>
  );
};

export default BodyCards;
