import React, { useEffect, useRef, useState } from 'react';

import {
  Container,
  ContainerDate,
  ContainerAddress,
  ContainerSelectAndInput,
  ContainerInputsDate,
  ContainerSelect,
} from './styled';
import { StepTitle } from '../styled';
import { Form } from '@unform/web';
import ArrowLeft from 'assets/SettingsPage/Profile/arrowLeft';

import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import Input from 'components/UnformFields/inputField';
import InputWorkload from 'components/UnformFields/InputWorkload';
import StepController from '../../StepController';
import { FormHandlers } from 'views/StaffPages/FairAndEvents/Handlers/formHandlers';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import { staticOptions } from 'views/StaffPages/FairAndEvents/StaticOptions';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import FairAndEventsService from '../../../Services/index';
import MaskInput from 'components/UnformFields/MaskInput';
import HourPicker from 'components/UnformFields/Hourpicker';
import { schema as schemaPresential, schemaOnline } from './schema';
import formValidationHandler from 'views/StaffPages/FairAndEvents/Handlers/formValidationHandler';

export default function StepTwo() {
  const useRegisterFormInstance = useRegisterModalContext();

  const {
    selectOptions,
    stepsData,
    formRefStepTwo,
    setStepsData,
  } = useRegisterFormInstance;

  const FormHandlersFactory = FormHandlers(useRegisterFormInstance);

  const [
    isValidAscomplementaryActivity,
    setIsValidAsComplementaryactivity,
  ] = useState(false);
  const [activeDigitalCertificate, setActiveDigitalCertificate] = useState(
    false
  );
  const [tick, setTick] = useState(0);
  const [modality, setModality] = useState('');
  const [cep, setCep] = useState('');
  const [hasCepError, setHasCepError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const hasModalitySelected = modality.length > 0;
  const showCepField = modality === 'presential';
  const showAddressFields = cep.replace('_', '').length === 9;

  const handleSelectComplementaryActivity = (event) => {
    const value = event.value;
    setIsValidAsComplementaryactivity(value);

    if (!value) {
      formRefStepTwo.current.setFieldValue('complementary_activity_time', '');

      if (stepsData.complementary_activity_time) {
        const { complementary_activity_time, ...rest } = stepsData;

        setStepsData(rest);
      }
    }
  };

  useEffect(() => {
    if (stepsData.event_modality) {
      setModality(stepsData.event_modality);
    }
  }, [stepsData]);

  const handleSelectActivateDigitalCertificate = (event) => {
    const value = event.value;
    setActiveDigitalCertificate(value);

    if (!value) {
      formRefStepTwo.current.setFieldValue('certification_time', '');

      if (stepsData.certification_time) {
        const { certification_time, ...rest } = stepsData;

        setStepsData(rest);
      }
    }
  };

  const handleSelectModality = (event) => {
    const modality = event.value;
    setModality(modality);

    if (modality === 'online' && cep.length > 0) {
      setCep('');
    }
  };

  const getDataByZipCode = async () => {
    const response = await FairAndEventsService.getDataByZipCode(cep);
    setHasCepError(false);
    formRefStepTwo.current.setErrors({});
    if (response.erro) {
      formRefStepTwo.current.setFieldError(
        'zip_code',
        'Informe um CEP válido.'
      );
      setHasCepError(true);
      return;
    }

    if (response.logradouro.length > 0) {
      formRefStepTwo.current.setFieldValue('street', response.logradouro);
    }

    if (response.bairro.length > 0) {
      formRefStepTwo.current.setFieldValue('district', response.bairro);
    }

    if (response.localidade.length > 0) {
      formRefStepTwo.current.setFieldValue('city', response.localidade);
    }

    if (response.uf.length > 0) {
      formRefStepTwo.current.setFieldValue('state', response.uf);
    }
  };

  const handleChangeCep = (event) => {
    const cep = event?.target?.value || event;
    setCep(cep);
  };

  useEffect(() => {
    if (cep.replace('_', '').length === 9) {
      getDataByZipCode();
    }
  }, [cep]);

  const controllCepField = () => {
    if (hasModalitySelected) {
      if (showCepField) {
        return (
          <MaskInput
            name="zip_code"
            label="CEP*"
            width="100%"
            maxLength={8}
            value={cep}
            removeMargin
            onChange={handleChangeCep}
            onClick={getDataByZipCode}
          />
        );
      }
      return (
        <Input
          name="event_url"
          label="Link para participar do evento*"
          width="100%"
          removeMargin
          fontSize="13px"
        />
      );
    }
  };

  useEffect(() => {
    try {
      const formData = formRefStepTwo?.current?.getData();
      const currentSchema =
        modality === 'presential' ? schemaPresential : schemaOnline;

      const newSchema = formValidationHandler.convertSchema(currentSchema);
      newSchema.validateSync(formData, { abortEarly: false });

      return setIsButtonDisabled(false);
    } catch (error) {
      return setIsButtonDisabled(true);
    }
  }, [tick]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((prevs) => prevs + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (data) => {
    const currentSchema =
      modality === 'presential' ? schemaPresential : schemaOnline;

    await FormHandlersFactory.handleSubmitForm(
      data,
      currentSchema,
      formRefStepTwo
    );
  };

  useEffect(() => {
    if (Object.keys(stepsData).length > 0) {
      formRefStepTwo.current.setData(stepsData);
    }
  }, []);

  return (
    <Container>
      <StepTitle>Sobre o evento</StepTitle>
      <ContainerDate>
        <Form
          ref={formRefStepTwo}
          onSubmit={handleSubmit}
          initialData={stepsData}
        >
          <ContainerInputsDate>
            <DatePickerCustom label="Data de início*" name="event_start_at" />
            <HourPicker label="Horário de início*" name="event_start_time" />
          </ContainerInputsDate>
          <ContainerInputsDate>
            <DatePickerCustom label="Data de término*" name="event_end_at" />
            <HourPicker label="Horário de término" name="event_end_time" />
          </ContainerInputsDate>

          <ContainerSelect>
            <UnformSelect
              options={selectOptions.event_type}
              name="event_type"
              label="Tipo*"
              margin="15px 0px 0px 0px"
            />

            <ContainerSelectAndInput>
              <UnformSelect
                options={staticOptions.boolean}
                name="complementary_activity"
                label="Válido como atividade complementar*"
                width="100%"
                onChange={handleSelectComplementaryActivity}
              />
              {isValidAscomplementaryActivity && (
                <InputWorkload
                  name="complementary_activity_time"
                  label="Carga horária*"
                  width="180px"
                  removeMargin
                  height="38px"
                />
              )}
            </ContainerSelectAndInput>
            <ContainerSelectAndInput>
              <UnformSelect
                options={staticOptions.boolean}
                name="certification"
                label="Habilitar certificado*"
                width="100%"
                onChange={handleSelectActivateDigitalCertificate}
                useHint
                hintConfig={{
                  text:
                    'Gere certificados para os estudantes que participaram do evento',
                  direction: 'top',
                  isMultiline: true,
                }}
              />
              {activeDigitalCertificate && (
                <InputWorkload
                  name="certification_time"
                  label="Carga horária*"
                  width="180px"
                  removeMargin
                  height="38px"
                />
              )}
            </ContainerSelectAndInput>

            <ContainerSelectAndInput>
              <UnformSelect
                onChange={handleSelectModality}
                options={selectOptions.event_modality}
                name="event_modality"
                label="Modalidade*"
                width="100%"
              />
              {controllCepField()}
            </ContainerSelectAndInput>
          </ContainerSelect>

          {showAddressFields && !hasCepError && (
            <ContainerAddress>
              <div style={{ display: 'flex', gap: '20px', marginTop: '5px' }}>
                <Input
                  name="street"
                  label="Rua/Avenida*"
                  removeMargin
                  fontSize="13px"
                />
                <Input
                  name="number"
                  label="Número*"
                  width="180px"
                  removeMargin
                  fontSize="13px"
                />
                <Input
                  name="complement"
                  label="Complemento"
                  width="240px"
                  removeMargin
                  fontSize="13px"
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  gap: '20px',
                  marginBottom: '25px',
                  marginTop: '10px',
                }}
              >
                <Input
                  name="district"
                  label="Bairro*"
                  width="140px"
                  removeMargin
                  fontSize="13px"
                />
                <Input
                  name="city"
                  label="Cidade*"
                  width="350px"
                  removeMargin
                  fontSize="13px"
                />
                <Input
                  name="state"
                  label="UF*"
                  width="100px"
                  removeMargin
                  fontSize="13px"
                />
              </div>

              <Input
                name="location_name"
                label="Nome do local*"
                useCount
                maxLength={100}
                height="38px"
                fontSize="13px"
              />
            </ContainerAddress>
          )}
          <StepController isButtonDisabled={isButtonDisabled} />
        </Form>
      </ContainerDate>
    </Container>
  );
}
