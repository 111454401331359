import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
`;

export const WhiteBox = styled.div`
  width: ${(props) => (props.form ? '50em' : '992px')};
  height: auto;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 12px;
  position: relative;
`;

export const WrapperContent = styled.div`
  width: 100%;
  height: auto;
  min-height: 200px;
  border-radius: 8px;
  background-color: #ffffff;
`;
