import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: 100%;
  padding-top: 25px;
  margin-left: auto;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
  height: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  > h4 {
    color: #606062;
    font-weight: bold;
  }
`;

export const ContainerIframe = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  > iframe {
    width: 80%;
    height: 400px;
    border: none;
  }
`;
