import React, { useEffect } from 'react';
import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';
import { getCompanyProfile } from 'views/StaffPages/CompaniesManagement/Services';

import { Container, Wrapper } from './style';

export const AccordionContent = (props) => {
  const { MdOutlineMail, BsGrid, HiOutlineUser, CgFileDocument } = useIcons();

  const {
    setOpenModalConnectedCompanies,
    companies,
    buttonCompanyProfileDown,
    buttonCompanyProfileUp,
    responsibleForTheInvitation,
    responsibleForTheDisconnect,
    setDetails,
    setDocuments,
    responsibleForTheCanceled,
    responsibleForTheReconnect,
    setOpenModalViewStageData,
    setSelectedCompanyId,
  } = props;

  const handleChangeProfile = async (id) => {
    const data = await getCompanyProfile(id);
    setDetails(data);
    setSelectedCompanyId(id);
    setOpenModalConnectedCompanies(true);
  };

  return (
    <Container>
      <Wrapper>
        <div className="box-info">
          <MdOutlineMail color="#009291" size={26} />
          {companies?.staff?.name === '' ? (
            <div className="column">
              <span>Responsável legal da empresa</span>
              <p> Não informado </p>
            </div>
          ) : (
            <div className="column">
              <span>Responsável legal da empresa</span>
              <p> {companies?.staff?.name} </p>
              <p> {companies?.staff?.email} </p>
              <p> {companies?.staff?.phone} </p>
            </div>
          )}
        </div>
        {buttonCompanyProfileDown && (
          <div className="box-info">
            <BsGrid color="#009291" size={26} />
            <div className="column">
              <span>Perfil da empresa</span>
              <button onClick={() => handleChangeProfile(companies.id)}>
                Clique para ver
              </button>
            </div>
          </div>
        )}
      </Wrapper>
      {buttonCompanyProfileUp && (
        <Wrapper>
          <div className="box-info">
            <BsGrid color="#009291" size={26} />
            <div className="column">
              <span>Perfil da empresa</span>
              <button onClick={() => handleChangeProfile(companies.id)}>
                Clique para ver
              </button>
            </div>
          </div>
        </Wrapper>
      )}
      {responsibleForTheInvitation && !responsibleForTheReconnect && (
        <Wrapper>
          <div className="box-info">
            <HiOutlineUser color="#009291" size={26} />
            {companies?.invite_responsible !== null &&
            companies?.invite_responsible_email !== null ? (
              <div className="column">
                <span>Usuário responsável pelo convite </span>
                <p> {companies?.invite_responsible} </p>
                <p> {companies?.invite_responsible_email} </p>
                <p> {companies?.invite_responsible_phone} </p>
              </div>
            ) : (
              <div className="column">
                <span>Usuário responsável pelo convite </span>
                <p> Não informado </p>
              </div>
            )}
          </div>
        </Wrapper>
      )}
      {responsibleForTheReconnect && (
        <Wrapper>
          <div className="box-info">
            <HiOutlineUser color="#009291" size={26} />
            {companies?.reconnect_responsible !== null &&
            companies?.reconnect_responsible_email !== null ? (
              <div className="column">
                <span> Usuário responsável por reconectar </span>
                <p> {companies?.reconnect_responsible} </p>
                <p> {companies?.reconnect_responsible_email} </p>
                <p> {companies?.reconnect_responsible_phone} </p>
              </div>
            ) : (
              <div className="column">
                <span> Usuário responsável por reconectar </span>
                <p> Não informado </p>
              </div>
            )}
          </div>
        </Wrapper>
      )}
      {responsibleForTheCanceled && (
        <Wrapper>
          <div className="box-info">
            <HiOutlineUser color="#009291" size={26} />
            {companies?.cancel_responsible !== null &&
            companies?.cancel_responsible_email !== null ? (
              <div className="column">
                <span> Usuário responsável pelo cancelamento </span>
                <p> {companies?.cancel_responsible} </p>
                <p> {companies?.cancel_responsible_email} </p>
                <p> {companies?.cancel_responsible_phone} </p>
              </div>
            ) : (
              <div className="column">
                <span> Usuário responsável pelo cancelamento </span>
                <p> Não informado </p>
              </div>
            )}
          </div>
        </Wrapper>
      )}
      {responsibleForTheDisconnect && (
        <Wrapper>
          <div className="box-info">
            <HiOutlineUser color="#009291" size={26} />
            {companies?.disconnect_responsible !== null &&
            companies?.disconnect_responsible_email !== null ? (
              <div className="column">
                <span> Usuário responsável por desconectar-se </span>
                <p> {companies?.disconnect_responsible} </p>
                <p> {companies?.disconnect_responsible_email} </p>
                <p> {companies?.disconnect_responsible_phone} </p>
              </div>
            ) : (
              <div className="column">
                <span> Usuário responsável por desconectar-se </span>
                <p> Não informado </p>
              </div>
            )}
          </div>
        </Wrapper>
      )}
    </Container>
  );
};
