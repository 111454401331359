import {
  Box,
  Container,
  Content,
  HeaderGradient,
  ContainerImage,
  ContainerHeader,
  ContainerInformations,
} from './styled';

import location from 'assets/SettingsPage/Profile/location.svg';
import building from 'assets/SettingsPage/Profile/building.svg';
import university from 'assets/SettingsPage/Profile/university.svg';

import TabJobCompany from '../../Components/Tabs';
import { useCallback, useEffect, useRef, useState } from 'react';
import About from './About';
import Courses from './Courses';
import Gallery from './Gallery';
import ProfileAPI from '../services/ProfileAPI';
import CloseButton from '../../Components/CloseButton';

export default function PreviewModal({ open, setOpen, previewData }) {
  const [data, setData] = useState({});
  const [modalTop, setModalTop] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const divRef = useRef(null);

  useEffect(() => {
    setModalTop(50);
  }, [activeTab, divRef.current]);

  const calculateMargin = useCallback(() => {
    const currentMargin =
      Math.max(0, -divRef?.current?.getBoundingClientRect().top) + 50;
    setModalTop(currentMargin);
  }, [activeTab, open]);

  const getDataPreview = async () => {
    const { data } = await ProfileAPI.getDataPreview();
    setData(data);
  };

  useEffect(() => {
    calculateMargin();
  }, [open]);

  useEffect(() => {
    getDataPreview();
  }, []);

  const mountedData = { ...data, ...previewData };

  const {
    color,
    logo,
    educational_group,
    city,
    state,
    unities,
    courses,
  } = mountedData;

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const handleClickClose = useCallback(() => {
    setOpen(false);
    setActiveTab(0);
  }, []);

  const tabs = {
    0: <About data={mountedData} calculateMargin={calculateMargin} />,
    1: <Courses data={mountedData} calculateMargin={calculateMargin} />,
    2: <Gallery data={mountedData} calculateMargin={calculateMargin} />,
  };

  if (!open) {
    return null;
  }

  if (open && Object.keys(data).length > 0) {
    return (
      <Container>
        <Box margin={modalTop}>
          <Content>
            <HeaderGradient color={color} ref={divRef}>
              <CloseButton onClick={handleClickClose} />
            </HeaderGradient>
            <ContainerImage>
              <img src={logo} />
            </ContainerImage>
            <ContainerHeader>
              <h3>{data.initials}</h3>
              <ContainerInformations>
                <span>
                  <div>
                    <img src={building} />
                  </div>
                  <p>
                    {unities} {unities > 1 ? 'unidades' : 'unidade'}
                  </p>
                </span>
                <span>
                  <div>
                    <img src={location} />
                  </div>
                  <p>
                    {city} / {state}
                  </p>
                </span>
                {educational_group && (
                  <span>
                    <div>
                      <img src={university} />
                    </div>
                    <p>{educational_group}</p>
                  </span>
                )}
              </ContainerInformations>
            </ContainerHeader>

            <div
              style={{
                marginBottom: '30px',
                border: '1px solid #D9D9D9',
                margin: '35px 40px',
              }}
            >
              <div style={{ width: '100%' }}>
                <TabJobCompany
                  activeTab={activeTab}
                  handleTabChange={handleTabChange}
                  tabs={[
                    `Sobre a instituição`,
                    `Cursos ofertados (${courses})`,
                    `Galeria de fotos`,
                  ]}
                />
              </div>

              {tabs[activeTab]}
            </div>
          </Content>
        </Box>
      </Container>
    );
  }
}
