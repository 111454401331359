import React, { useContext, Fragment } from 'react';
import { OpportunitiesCompanyContext } from '../../../views/Company/OpportunitiesCompany/context/OpportunitiesCompanyContext';

import { Container, MultiStepDivActive, WrapperStep } from './styled';

export default function StepIndicator ({ Steps }) {

  const { step } = useContext(OpportunitiesCompanyContext);

  return (
    <WrapperStep>
      {Steps.map((_, index) => (
        <Fragment>
          <MultiStepDivActive 
            backgroundColor={ index === step ? '#009291' : index > step ? '#EFEEEC' : '#98D2D2'}
            color={index === step ? '#fff' : index > step ? '#606062' : '#fff'}
          >
            {index + 1}
          </MultiStepDivActive>
          <Container 
            backgroundColor={ index === step ? '#009291' : index > step ? '#EFEEEC' : '#98D2D2'}
            marginLeft={ index === step ? '-95px' : '-90px'}
          />
        </Fragment>
      ))}
    </WrapperStep>
  );
}
