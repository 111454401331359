import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';

import { Container } from './styled';

function Arrow({ titleArrow, onClick }) {
  return (
    <Container onClick={onClick}>
      <IoIosArrowBack size={24} color="#009291" />
      <p>{titleArrow}</p>
    </Container>
  );
}

export default Arrow;
