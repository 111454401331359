import { Container } from '../FinishedDisciplineCard/style';
import { SkeletonBox } from '../../../../style';
import { EditButton } from './style';

export const FinishedDisciplineCardSkeleton = () => {
  return (
    <Container>
      <span>
        <SkeletonBox width={'40px'} height={'14px'} />
      </span>
      <h1>
        <SkeletonBox width={'175px'} height={'22px'} />
      </h1>
      <ul>
        <li>
          <SkeletonBox width={'21px'} height={'21px'} />
          <SkeletonBox width={'160px'} height={'21px'} />
        </li>
        <li>
          <SkeletonBox width={'21px'} height={'21px'} />
          <SkeletonBox width={'185px'} height={'21px'} />
        </li>
        <li>
          <SkeletonBox width={'21px'} height={'21px'} />
          <SkeletonBox width={'135px'} height={'21px'} />
        </li>
      </ul>
      <SkeletonBox
        width={'100%'}
        height={'0.5px'}
        style={{
          marginTop: 'auto',
        }}
      />
      <EditButton>
        <SkeletonBox width={'135px'} height={'21px'} />
      </EditButton>
    </Container>
  );
};
