import styled from 'styled-components';
import { device } from './utils/devicesQuery';

export const BaseContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.usingPreviewPdf ? 0 : 9999999999)};
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 10.1rem;
  height: auto;
`;

export const Card = styled.div`
  width: 40.5rem;
  background-color: #ffff;
  border-radius: 8px;
  position: relative;
  min-height: 200px;
  text-align: initial;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 55px;
  
  > h3 {
    font-size: 20px;
    font-weight: 500;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }

  @media ${device.mobileL} {
    width: 22.4em;
    padding: 40px 7px;
  }
`;
