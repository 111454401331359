import React from 'react';

import {
  InfoModalBackground,
  InfoModalContainer,
  HeaderModal,
  BodyModal,
  ButtonX,
} from './styled';

import information from 'assets/Oportutinites/information.svg';
import sucess from 'assets/Oportutinites/sucess.svg';

import { RiCloseFill } from 'react-icons/ri';

export default function InformationModal({
  informationHeader,
  errorMsg,
  type,
  isOpen,
  setModalOpen,
  finish,
  children,
  setIsOpenModal,
  setCloseModal,
  getAllChallenges,
  closeOtherModal,
  setModalOpenAtraction,
}) {
  const setState = () => {
    if (finish) {
      setIsOpenModal(false);
      getAllChallenges(`limit=${24}`);
    }
    setModalOpen(false);
  };

  const closeModal = () => {
    setState();
    setCloseModal(false);
  };

  const returnCurrentImg = () => {
    return type === 'success' ? sucess : information;
  };

  return (
    <InfoModalBackground isOpen={isOpen} onClick={setState}>
      <InfoModalContainer>
        <HeaderModal>
          <ButtonX onClick={() => closeModal()}>
            <RiCloseFill size={25} />
          </ButtonX>
        </HeaderModal>
        <BodyModal>
          <img src={returnCurrentImg()} />

          <h4> {informationHeader} </h4>

          <p> {errorMsg} </p>

          {children && children}
        </BodyModal>
      </InfoModalContainer>
    </InfoModalBackground>
  );
}
