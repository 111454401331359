import { Form } from '@unform/web';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import { useRef, useEffect } from 'react';
import { PrevsButton, WrapperBottomActions, H3, P, H3Header } from '../styled';
import { updateCompany, uploadLogo } from 'services/companies';
import Button from 'views/StaffPages/FairAndEvents/UiComponents/Button';
import { schemaStepThree } from './schemas';
import * as Yup from 'yup';
import { staticOptions } from 'views/StaffPages/FairAndEvents/StaticOptions';

export default function MyProfileModalStepThree({
  data,
  options,
  stepsData,
  handlePrevs,
  formRef,
  closeModal,
}) {
  useEffect(() => {
    formRef.current.setFieldValue('interests', data?.interests);
    formRef.current.setFieldValue('macro_areas', data?.macro_areas);
    formRef.current.setFieldValue('values', data?.values);
  }, [data, options]);

  const handleSubmit = async (data) => {
    try {
      await schemaStepThree.validate(data, { abortEarly: false });

      await updateCompany({ ...data, ...stepsData });
      window.location.reload();

      if (stepsData.file) {
        await uploadLogo(stepsData.file);
      }
      closeModal();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit} initialData={data}>
      <H3Header>Sobre seu negócio</H3Header>
      <div>
        <H3>Areas de interesse* (preencha até 5)</H3>
        <P>
          Essas informações serão direcionadas ao setor acadêmico das
          instituições de ensino parceiras para que elas possam trabalhar os
          temas com seus estudantes e docentes (pesquisas).
        </P>
        <UnformSelect
          isMulti
          name="interests"
          options={options?.interests}
          maxLimit={5}
        />
      </div>

      <div>
        <H3>
          Quais são as competências que mais se destacam?* (preencha até 4)
        </H3>
        <P>
          Queremos entender melhor o DNA do perfil profissional da sua empresa,
          selecione abaixo até 4 macro áreas que mais se destacam nos
          profissionais da empresa.
        </P>
        <UnformSelect
          isMulti
          name="skills"
          options={options?.company_skills}
          maxLimit={4}
        />
      </div>
      <div>
        <H3>Quais são os valores da sua empresa?* (preencha até 3)</H3>
        <P>
          Me conte um pouco mais sobre a cultura da sua empresa. Selecione até 3
          temáticas que se aproximam mais da sua cultura.
        </P>
        <UnformSelect
          isMulti
          name="values"
          options={options?.company_values}
          maxLimit={3}
        />
      </div>

      <UnformSelect
        name="use_integration_agent"
        label="Você utiliza algum Agente Integrador para vagas de estágio?*"
        options={staticOptions.boolean}
      />

      <UnformSelect
        isMulti
        name="macro_areas"
        label="Em quais áreas você contrata estagiários?*"
        options={options?.company_macro_areas}
      />
      <WrapperBottomActions justify="space-between">
        <PrevsButton type="button" onClick={handlePrevs}></PrevsButton>
        <Button theme="dark" type="submit">
          Salvar
        </Button>
      </WrapperBottomActions>
    </Form>
  );
}
