import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 100%;
  max-width: fit-content;
  padding: 0;

  background-color: transparent;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  border: none;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

export const DialogActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 26px;
`;
