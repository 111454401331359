import React from 'react';
import PropTypes, { func, objectOf, object } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import LogoAceleradora from 'assets/img/logo-workalove.svg';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import Button from 'components/CustomButtons/Button.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      initialValues: {
        username: '',
        password: '',
        university: '',
      },
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.props.handleIsLoadingState(false);
    this.timeOutFunction = setTimeout(
      function () {
        this.setState({
          cardAnimaton: '',
          initialValues: {
            ...this.state.initialValues,
            university: this.props.match.params.universidade,
          },
        });
      }.bind(this),
      700
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    const { classes, match } = this.props;
    const { path: loginUrl, params: params } = match;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem
            xs={12}
            sm={6}
            md={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img src={LogoAceleradora} width="80%" height="100%" />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center" style={{ marginTop: 20 }}>
          <GridItem xs={12} sm={6} md={4}>
            <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader>
                <p>Escolha o seu perfil de usuário abaixo</p>
              </CardHeader>
              <CardBody>
                <Button
                  href="#/login/universidade"
                  className={classes.saveButton}
                  fullWidth
                  round
                >
                  Universidade
                </Button>
                <Button
                  href="#/login/empresa"
                  className={classes.saveButton}
                  fullWidth
                  round
                >
                  Empresa
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  handleIsLoadingState: func,
  match: objectOf(object),
  openSnackbar: func,
};

export default withStyles(loginPageStyle)(LoginPage);
