import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';

import {
  Container,
  ContainerPromenience,
  ContainerPromenienceImage,
  ContainerPromenienceInformations,
  ErrorText,
  UploadImageButton,
} from './styled';
import { StepTitle } from '../styled';
import Input from 'components/UnformFields/inputField';
import InputDesc from 'components/Opportunities/Inputs/InputDescription';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import StepController from '../../StepController';
import Gallery from 'assets/SettingsPage/Profile/Gallery';

import unSelectedImage from 'assets/FairAndEvents/unSelectedImage.svg';
import { schema } from './schema';
import { ConvertImageHelper } from 'views/StaffPages/FairAndEvents/Helpers/ConvertImageHelper';
import { FormHandlers } from 'views/StaffPages/FairAndEvents/Handlers/formHandlers';
import { IoTrashOutline } from 'react-icons/io5';

export default function StepOne() {
  const inputRef = useRef(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [tick, setTick] = useState(0);
  const [imageError, setImageError] = useState('');

  const useRegisterFormInstance = useRegisterModalContext();

  const FormHandlersFactory = FormHandlers(useRegisterFormInstance);
  const { stepsData, formRefStepOne } = useRegisterFormInstance;

  const handleClickUploadImage = () => {
    inputRef?.current.click();
  };

  useEffect(() => {
    try {
      const formData = formRefStepOne?.current?.getData();
      schema.validateSync(formData, { abortEarly: false });
      if (!selectedImage || !imageError) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    } catch (err) {
      setIsButtonDisabled(true);
    }
  }, [tick]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTick((prev) => prev + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleChangeImage = (event) => {
    setImageError('');
    const image = event.target.files?.[0];

    if (image) {
      if (image.name.length > 100) {
        setImageError('O nome do arquivo não pode superar 100 caracteres.');
      }
      const previewURL = URL.createObjectURL(image);
      setPreviewImage(previewURL);
      setSelectedImage(image);
      event.target.value = null;
    }
  };

  const handleSubmit = async (data) => {
    const mountedData = { ...data, event_cover: selectedImage };
    await FormHandlersFactory.handleSubmitForm(
      mountedData,
      schema,
      formRefStepOne
    );
  };

  const convertEditImage = async () => {
    const image = await ConvertImageHelper.convert(stepsData?.event_cover);
    const previewURL = URL.createObjectURL(image);
    setPreviewImage(previewURL);
    setSelectedImage(image);
  };

  const handleClickRemoveImage = () => {
    setPreviewImage('');
    setImageError('');
    setSelectedImage(null);
  };

  useEffect(() => {
    if (stepsData?.event_cover) {
      if (stepsData?.edit) {
        convertEditImage();
        return;
      }
      const previewURL = URL.createObjectURL(stepsData?.event_cover);
      setPreviewImage(previewURL);
      setSelectedImage(stepsData?.event_cover);
    }
  }, []);

  const uploadButtonDisabled = previewImage.length > 0;

  return (
    <Container>
      <input
        type="file"
        className="hiddenInput"
        ref={inputRef}
        onChange={handleChangeImage}
        accept="image/png, image/jpeg"
      />
      <StepTitle>Dados básicos do evento</StepTitle>

      <Form
        onSubmit={handleSubmit}
        ref={formRefStepOne}
        initialData={stepsData}
      >
        <Input name="name" label="Nome*" maxLength={100} useCount />
        <InputDesc
          name="description"
          label="Descrição"
          maxLength={2000}
          showCounter
        />
        <InputDesc name="event_schedule" label="Programação" maxLength={2000} />

        <ContainerPromenience>
          <ContainerPromenienceInformations>
            <h4>Imagem de destaque</h4>
            <p>
              Adicione uma imagem em formato JPEG ou PNG com a dimensão de 1600
              x 838
            </p>
            <UploadImageButton onClick={handleClickUploadImage}>
              <Gallery
                color={'#009291'}
                size={25}
                disabled={uploadButtonDisabled}
              />
              Adicionar imagem
            </UploadImageButton>
            {imageError && <ErrorText>{imageError}</ErrorText>}
          </ContainerPromenienceInformations>

          <ContainerPromenienceImage>
            {previewImage ? (
              <>
                <img className="selectedImage" src={previewImage} />
                <div className="removeImage" onClick={handleClickRemoveImage}>
                  <IoTrashOutline size={21} color={'#FFF'} />
                </div>
              </>
            ) : (
              <img className="unSelectedImage" src={unSelectedImage} alt="" />
            )}
          </ContainerPromenienceImage>
        </ContainerPromenience>
        <StepController isButtonDisabled={isButtonDisabled} />
      </Form>
    </Container>
  );
}
