import React, { useContext } from 'react';
import { FilterType } from './Filter';
import { Tab, TabsContainer } from '../styles';
import { useFilterContext } from '../contexts/FilterContext';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterTabs() {
  const {
    tabs,
    activeTab,
    setActiveTab,
    filterState,
    tabsContent,
  } = useFilterContext();
  const { universityColor } = useContext(BaseLayoutContext);

  function countFiltersFromTab(component) {
    let filterCount = 0;
    if (typeof component === 'object') {
      const children = component.props.children;
      const childList = Array.isArray(children) ? children : [children];
      childList.forEach((child) => {
        if (child && child.props) {
          if (
            child.props.type === FilterType.CHECKBOX ||
            child.props.type === FilterType.MULTI_SELECT ||
            child.props.type === FilterType.MULTI_TEXT
          ) {
            filterCount +=
              (filterState[child.props.name] &&
                filterState[child.props.name].length) ||
              0;
          } else {
            const names = Array.isArray(child.props.name)
              ? child.props.name
              : [child.props.name];
            names.forEach(
              (name) =>
                (filterCount += (filterState[name] !== undefined && 1) || 0)
            );
          }
          if (child.props.children) {
            filterCount += countFiltersFromTab(child);
          }
        }
      });
    }
    return filterCount;
  }

  return (
    <TabsContainer>
      {tabs?.map((tab, index) => {
        const filterCount = countFiltersFromTab(tabsContent[index]);
        return (
          <Tab
            key={tab}
            isActive={activeTab === index}
            onClick={() => setActiveTab(index)}
            universityColor={universityColor}
          >
            {`${tab}${
              filterCount > 0 ? ` (${filterCount})` : ''
            }`.toUpperCase()}
          </Tab>
        );
      })}
    </TabsContainer>
  );
}
