import React, { useState, useEffect } from 'react';
import { Document } from '@react-pdf/renderer';

import moment from 'moment';
import calculateTimeBetweenDates from 'utils/calculateTimeBetweenDates';

import { View } from '@react-pdf/renderer';

import {
  Body,
  Content,
  HeaderIcon,
  Row,
  HeaderText,
  NameText,
  Divider,
  DividerHalf,
  SubTitleText,
  InterestsTitle,
  InterestsTextContent,
  InterestsText,
  PersonalityItem,
  StrongText,
  Text,
  ElipseIcon,
  LanguageText,
  TextWithMarginLeft,
  ExpirienceTextBold,
  RowWithNoBorderBotton,
  ExpirienceTitle,
  ExpirienceDescriptText,
  TecnicalRow,
  ValueText,
  FooterText,
  RowNoWrap,
  Footer,
  Phrases,
  SubTitlePhrase,
} from './styles';

import graduationIcon from 'assets/curriculum/graduation.png';
import phoneIcon from 'assets/curriculum/phone.png';
import mailIcon from 'assets/curriculum/mail.png';
import specialNeedsIcon from 'assets/curriculum/specialNeeds.png';
import locationIcon from 'assets/curriculum/location.png';
import openfileIcon from 'assets/curriculum/openfile.png';
import ellipseIcon from 'assets/curriculum/ellipse.png';

function Curriculum({ curriculumData, showAverage, previewOrder = {} }) {
  const [academicProfile, setAcademicProfile] = useState(
    curriculumData?.academic_profile
  );

  const experienceValidateRender =
    curriculumData?.professional_experience?.length > 0 ||
    curriculumData?.life_experience?.length > 0;

  const professionalExperienceValidadeRender =
    curriculumData?.professional_experience?.length > 0;
  const lifeExperienceValidadeRender =
    curriculumData?.life_experience?.length > 0;

  const lastDateBehavioral = curriculumData?.quizzes?.behavioral?.last_date;
  const lastDatePersonality = curriculumData?.quizzes?.personality?.last_date;

  const returnLastDateBehavioral = () => {
    if (lastDateBehavioral === '' || !lastDateBehavioral) {
      return '.';
    }
    if (lastDateBehavioral) {
      return `, realizado no dia ${moment(lastDateBehavioral).format(
        'DD/MM/YYYY'
      )}.`;
    }
  };

  const returnLastDatePersonality = () => {
    if (lastDatePersonality === '' || !lastDatePersonality) {
      return '.';
    }
    if (lastDatePersonality) {
      return `, realizado no dia ${moment(lastDatePersonality).format(
        'DD/MM/YYYY'
      )}.`;
    }
  };

  const newBehavioralTexts = {
    analytical_mind:
      'Análise de diferentes argumentos e pontos de vista para se posicionar criticamente.',
    criativity:
      'Combinação de diferentes elementos para criar uma solução nova e gerar resultados mais efetivos.',
    leadership:
      'Influência das pessoas para se engajarem na realização de objetivos desejados.',
    communication:
      'Transmite informações com clareza e objetividade por meio da oratória e escrita.',
    team_work:
      'Trabalhar com pessoas de diferentes perfis e construir relações colaborativas.',
    adaptability:
      'Adaptação a novas realidades, flexibilidade no ponto de vista e controle das suas emoções para manter o foco.',
    decision_making:
      'Avaliação de opções, análise de riscos e definição das ações mais promissoras.',
    negotiation:
      'Integração de diferentes pontos de vista e geração de soluções conciliadoras',
    execution: 'Definição e execução de ações direcionadas para resultados.',
    organization: 'Gerenciamento de recursos, tempo, ações e resultados.',
  };

  const newPersonalTexts = {
    analytical:
      'Foco na qualidade e precisão. Características: curiosas, investigativas, observadoras, cuidadosas, organizadas e atentas aos detalhes.',
    executor:
      'Foco no resultado e na superação de obstáculos. Características: autoconfiança, competitividade, ousadia, determinação e proatividade.',
    communicator:
      'Interação e influência sob as outras pessoas. Características: comunicação, persuasão, colaboração e otimismo.',
    planner:
      'Atuação de forma estruturada e organizada. Características: estáveis, pacientes, calmas, ponderadas, disciplinadas e de ritmo consistente.',
  };

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function addOrdinalSymbol(str) {
    return `${str.split(' ').join('º ')}`;
  }

  function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const coursesDescription = academicProfile?.courses?.map((course) => {
    let courseText;
    const {
      degree_type,
      course_status,
      course_semester,
      name,
      degree_level,
      principal_course,
    } = course;
    if (capitalizeFirstLetter(course_status) === 'Matriculado') {
      const formattedDegree = capitalizeFirstLetter(degree_type);
      const formattedCourseSemester = addOrdinalSymbol(course_semester);
      return `${name} (${formattedDegree}) - ${formattedCourseSemester}`;
    }
    if (capitalizeFirstLetter(course_status) === 'Concluído') {
      const listDegreeLevel = {
        técnico: 'Formação em',
        profissionalizante: 'Formação em',
        'pós graduação': 'Especialização em',
        graduação: 'Graduação concluída em',
      };
      return `${
        listDegreeLevel[degree_level] ? listDegreeLevel[degree_level] : ''
      } ${principal_course ? principal_course : name}`;
    }
  });

  const interestesRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitleText>Interesses</SubTitleText>
        <InterestsTitle>Carreira</InterestsTitle>
        <Row>
          {curriculumData?.interests?.map((interest) => (
            <InterestsTextContent>
              <InterestsText>{interest.label}</InterestsText>
            </InterestsTextContent>
          ))}
        </Row>
        <InterestsTitle>Vida</InterestsTitle>
        <Row>
          {curriculumData?.life_interests?.map((interest) => (
            <InterestsTextContent>
              <InterestsText>{interest.label}</InterestsText>
            </InterestsTextContent>
          ))}
        </Row>
      </>
    );
  };

  const personalityRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitlePhrase>Personalidade</SubTitlePhrase>
        <Phrases>
          Mapeamento baseado na metodologia DISC{returnLastDatePersonality()}
        </Phrases>
        {curriculumData?.quizzes?.personality?.results?.map(
          ({ id, verbose_name }) => (
            <PersonalityItem key={id}>
              <Row>
                <ElipseIcon src={ellipseIcon} alt="ellipse" />
                <Text>
                  <StrongText>{verbose_name}: </StrongText>
                  {newPersonalTexts[id]}
                </Text>
              </Row>
            </PersonalityItem>
          )
        )}
      </>
    );
  };

  const behavioralRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitlePhrase>Comportamental</SubTitlePhrase>
        <Phrases>
          Mapeamento baseado no inventário de habilidades do Fórum Econômico
          Mundial{returnLastDateBehavioral()}
        </Phrases>
        {curriculumData?.quizzes?.behavioral?.results?.map(
          ({ id, verbose_name }) => (
            <PersonalityItem key={id}>
              <Row>
                <ElipseIcon src={ellipseIcon} alt="ellipse" />
                <Text>
                  <StrongText>{verbose_name}: </StrongText>
                  {newBehavioralTexts[id]}
                </Text>
              </Row>
            </PersonalityItem>
          )
        )}
      </>
    );
  };

  const valuesRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitlePhrase>Valores</SubTitlePhrase>
        <Phrases>
          Valores indicados como mais relevantes pelo estudante.
        </Phrases>
        {curriculumData?.quizzes?.values?.results?.map(
          ({ id, verbose_name, description }) => (
            <PersonalityItem key={id}>
              <Row>
                <ElipseIcon src={ellipseIcon} alt="ellipse" />
                <Text>
                  <StrongText>{verbose_name}: </StrongText>
                  {capitalizeFirstLetter(description)}
                </Text>
              </Row>
            </PersonalityItem>
          )
        )}
      </>
    );
  };

  const languagesRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitleText>Idiomas</SubTitleText>
        {curriculumData?.student_languages?.map((item) => (
          <PersonalityItem key={item.id}>
            <Row>
              <ElipseIcon src={ellipseIcon} alt="ellipse" />
              <Text>
                <StrongText>{item.language.label}: </StrongText>
                {item.fluence_level.description}
              </Text>
            </Row>
            <LanguageText>Nível {item.fluence_level.label}</LanguageText>
          </PersonalityItem>
        ))}
      </>
    );
  };

  const experiencesRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitleText>Experiências</SubTitleText>
        {professionalExperienceValidadeRender && (
          <ExpirienceTitle>Experiências de Carreiras</ExpirienceTitle>
        )}
        {professionalExperienceValidadeRender &&
          curriculumData?.professional_experience?.map(
            ({
              id,
              role,
              started_at,
              ended_at,
              company,
              area,
              description,
            }) => {
              const startDate = moment(started_at, 'DD/MM/YYYY').format(
                'MM/YYYY'
              );
              const now = moment().format('MM/YYYY');

              const endDate =
                ended_at !== 'Atual'
                  ? moment(ended_at, 'DD/MM/YYYY').format('MM/YYYY')
                  : ended_at;

              const calculatedTime = calculateTimeBetweenDates(
                startDate,
                endDate
              );

              const calculatedAtTheTime = calculateTimeBetweenDates(
                startDate,
                now
              );

              if (description) {
                return (
                  <View key={id}>
                    <RowWithNoBorderBotton>
                      <ElipseIcon src={ellipseIcon} alt="ellipse" />
                      <Text>
                        <ExpirienceTextBold>{role}</ExpirienceTextBold>
                      </Text>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <TextWithMarginLeft>
                        <ExpirienceTextBold>
                          {company} | {startDate} - {endDate}{' '}
                          {endDate !== 'Atual' &&
                            calculatedTime &&
                            `(${calculatedTime})`}
                          {endDate === 'Atual' &&
                            calculatedAtTheTime &&
                            `(${calculatedAtTheTime})`}
                        </ExpirienceTextBold>
                      </TextWithMarginLeft>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <ExpirienceDescriptText>
                        Principal conhecimento adquirido: {area.label}.
                      </ExpirienceDescriptText>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <ExpirienceDescriptText>
                        {description}.
                      </ExpirienceDescriptText>
                    </RowWithNoBorderBotton>
                  </View>
                );
              } else {
                return (
                  <View key={id}>
                    <RowWithNoBorderBotton>
                      <ElipseIcon src={ellipseIcon} alt="ellipse" />
                      <Text>
                        <ExpirienceTextBold>{role}</ExpirienceTextBold>
                      </Text>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <TextWithMarginLeft>
                        <ExpirienceTextBold>
                          {company} | {startDate} - {endDate}{' '}
                          {endDate !== 'Atual' &&
                            calculatedTime &&
                            `(${calculatedTime})`}
                          {endDate === 'Atual' &&
                            calculatedAtTheTime &&
                            `(${calculatedAtTheTime})`}
                        </ExpirienceTextBold>
                      </TextWithMarginLeft>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <ExpirienceDescriptText>
                        Principal conhecimento adquirido: {area.label}.
                      </ExpirienceDescriptText>
                    </RowWithNoBorderBotton>
                  </View>
                );
              }
            }
          )}
        {lifeExperienceValidadeRender && (
          <ExpirienceTitle>Experiências de Vida</ExpirienceTitle>
        )}
        {lifeExperienceValidadeRender &&
          curriculumData?.life_experience?.map(
            ({
              id,
              role,
              started_at,
              ended_at,
              knowledges,
              city,
              country,
              description,
            }) => {
              const startDate = moment(started_at, 'DD/MM/YYYY').format(
                'MM/YYYY'
              );
              const now = moment().format('MM/YYYY');

              const endDate =
                ended_at !== 'Atual'
                  ? moment(ended_at, 'DD/MM/YYYY').format('MM/YYYY')
                  : ended_at;

              const calculatedTime = calculateTimeBetweenDates(
                startDate,
                endDate
              );

              const calculatedAtTheTime = calculateTimeBetweenDates(
                startDate,
                now
              );

              if (description) {
                return (
                  <View key={id}>
                    <RowWithNoBorderBotton>
                      <ElipseIcon src={ellipseIcon} alt="ellipse" />
                      <Text>
                        <ExpirienceTextBold>{role}</ExpirienceTextBold> |{' '}
                        {startDate} - {endDate}{' '}
                        {endDate !== 'Atual' &&
                          calculatedTime &&
                          `(${calculatedTime})`}
                        {endDate === 'Atual' &&
                          calculatedAtTheTime &&
                          `(${calculatedAtTheTime})`}
                      </Text>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <TextWithMarginLeft>
                        Competências/especialidades adquiridas:{' '}
                        {knowledges.map((element) => element.label).join(' | ')}
                        .
                      </TextWithMarginLeft>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <ExpirienceDescriptText>
                        Local: {city} - {country.label}
                      </ExpirienceDescriptText>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <ExpirienceDescriptText>
                        {description}.
                      </ExpirienceDescriptText>
                    </RowWithNoBorderBotton>
                  </View>
                );
              } else {
                return (
                  <View key={id}>
                    <RowWithNoBorderBotton>
                      <ElipseIcon src={ellipseIcon} alt="ellipse" />
                      <Text>
                        <ExpirienceTextBold>{role}</ExpirienceTextBold> |{' '}
                        {startDate} - {endDate}{' '}
                        {endDate !== 'Atual' &&
                          calculatedTime &&
                          `(${calculatedTime})`}
                        {endDate === 'Atual' &&
                          calculatedAtTheTime &&
                          `(${calculatedAtTheTime})`}
                      </Text>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <TextWithMarginLeft>
                        Competências/especialidades adquiridas:{' '}
                        {knowledges.map((element) => element.label).join(' | ')}
                        .
                      </TextWithMarginLeft>
                    </RowWithNoBorderBotton>
                    <RowWithNoBorderBotton>
                      <ExpirienceDescriptText>
                        Local: {city} - {country.label}
                      </ExpirienceDescriptText>
                    </RowWithNoBorderBotton>
                  </View>
                );
              }
            }
          )}
      </>
    );
  };

  const extraCoursesRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitleText>Formação e Capacitação</SubTitleText>
        {curriculumData?.extra_courses?.map(
          ({ id, course, area, level, workload, institution }) => (
            <PersonalityItem key={id}>
              <RowWithNoBorderBotton>
                <ElipseIcon src={ellipseIcon} alt="ellipse" />
                <StrongText>{course}</StrongText>
              </RowWithNoBorderBotton>
              <TextWithMarginLeft>
                Principal conhecimento adquirido: {area.label}
              </TextWithMarginLeft>
              <TextWithMarginLeft>Nível: {level.label}</TextWithMarginLeft>
              <TextWithMarginLeft>Duração: {workload}</TextWithMarginLeft>
              <Row>
                <TextWithMarginLeft>
                  Instituição: {institution}
                </TextWithMarginLeft>
              </Row>
            </PersonalityItem>
          )
        )}
      </>
    );
  };

  const projectRender = () => {
    return (
      <>
        <DividerHalf />
        <SubTitleText>Portfólio</SubTitleText>
        {curriculumData?.projects?.map(
          ({ id, title, section, knowledge, link }) => (
            <PersonalityItem key={id}>
              <RowWithNoBorderBotton>
                <ElipseIcon src={ellipseIcon} alt="ellipse" />
                <Text>
                  <StrongText>{title}</StrongText>
                </Text>
              </RowWithNoBorderBotton>
              <TextWithMarginLeft>Área: {section.label}</TextWithMarginLeft>
              {knowledge.length > 0 && (
                <TextWithMarginLeft>
                  Principais conhecimentos adquiridos:{' '}
                  {knowledge.map((item) => item.label).join(' | ')}
                </TextWithMarginLeft>
              )}
              <Row>
                <TextWithMarginLeft>Link: {link}</TextWithMarginLeft>
              </Row>
            </PersonalityItem>
          )
        )}
      </>
    );
  };

  const technicalCompetences = () => {
    return (
      <>
        <DividerHalf />
        <SubTitleText>
          {' '}
          Competências{' '}
          {showAverage &&
            `- Média geral ${curriculumData?.technical_competences?.average}`}
        </SubTitleText>
        <TecnicalRow>
          {curriculumData?.technical_competences?.top_5_values > 0 && (
            <Text>
              <Text>
                <StrongText>Competências</StrongText>
              </Text>
              <StrongText>Rendimento</StrongText>
            </Text>
          )}
        </TecnicalRow>

        {curriculumData?.technical_competences?.top_5_values?.map(
          ({ id, name, value }) => (
            <TecnicalRow key={id}>
              <Text>{name}</Text>
              {showAverage && <ValueText>{value}</ValueText>}
            </TecnicalRow>
          )
        )}
      </>
    );
  };

  const currentDate = new Date();
  const formattedDate = `${currentDate
    .getDate()
    .toString()
    .padStart(2, '0')}/${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${currentDate.getFullYear()}`;
  const formattedTime = `${currentDate
    .getHours()
    .toString()
    .padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;

  const districtText = academicProfile?.district
    ? `${capitalizeFirstLetter(academicProfile.district)} - `
    : '';

  const locationText = `${districtText}${academicProfile?.city} / ${academicProfile?.state}`;

  const curriculumMap = {
    interests: interestesRender,
    personality: personalityRender,
    behavioral: behavioralRender,
    values:
      curriculumData?.quizzes?.values?.results.length > 0
        ? valuesRender
        : () => null,
    languages:
      curriculumData?.student_languages?.length > 0
        ? languagesRender
        : () => null,
    experiences: experienceValidateRender ? experiencesRender : () => null,
    extra_courses:
      curriculumData?.extra_courses?.length > 0
        ? extraCoursesRender
        : () => null,
    projects: curriculumData?.projects?.length > 0 ? projectRender : () => null,
    competences:
      curriculumData?.technical_competences?.top_5_values?.length > 0 ||
      showAverage
        ? technicalCompetences
        : () => null,
  };

  function renderCurriculum() {
    const orderMap =
      Object.keys(previewOrder).length > 0
        ? previewOrder
        : curriculumData?.information_order;
    if (!orderMap) return;
    const orderList = Object.keys(orderMap).sort(
      (a, b) => orderMap[a] - orderMap[b]
    );
    return orderList.map((item) => curriculumMap[item]());
  }

  return (
    <Document
      title={`Currículo ${academicProfile?.name}`}
      author="Workalove"
      creator="Workalove"
      producer="Workalove"
    >
      <Body wrap>
        <Content>
          <NameText>{academicProfile?.name}</NameText>
          {coursesDescription?.map((course) => (
            <Row>
              <HeaderIcon src={graduationIcon} alt="Graduação" />
              <HeaderText>{course}</HeaderText>
            </Row>
          ))}
          <Row>
            <HeaderIcon src={phoneIcon} alt="Telefone" />
            <HeaderText>{academicProfile?.mobile_phone}</HeaderText>
            <HeaderIcon src={mailIcon} alt="E-mail" />
            <HeaderText>{academicProfile?.email}</HeaderText>
          </Row>
          <Row>
            <HeaderIcon src={locationIcon} alt="Endereço" />
            <HeaderText>{locationText}</HeaderText>
            {academicProfile?.special_needs?.length > 0 && (
              <>
                <HeaderIcon
                  src={specialNeedsIcon}
                  alt="Necessidades especiais"
                />
                <HeaderText>
                  {academicProfile?.special_needs
                    ?.map((item) => ` ${item.label} `)
                    .join(', ')}
                </HeaderText>
              </>
            )}
          </Row>
          {academicProfile?.portfolio_url ? (
            <RowNoWrap>
              <HeaderIcon src={openfileIcon} alt="Telefone" />
              <HeaderText>
                Portfólio Geral: {academicProfile?.portfolio_url}
              </HeaderText>
            </RowNoWrap>
          ) : null}
          <Divider />
          {renderCurriculum()}
        </Content>
        <Footer fixed>
          <FooterText>
            Gerado em {formattedDate} às {formattedTime}
          </FooterText>
        </Footer>
      </Body>
    </Document>
  );
}

export default Curriculum;
