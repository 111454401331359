import React, { useContext, useRef, useState } from 'react';
import { MatrixContext } from '../../contexts/MatrixContext';
import SearchableSelectUn from '../Form/SearchableSelect';
import {
  HeaderInformationSelect,
  Informations,
  SelectCourse,
  Wrapper,
} from './style';

import { Form } from '@unform/web';
import { findDOMNode } from 'react-dom';

export default function HeaderMatrixController({ handleSelectCourse }) {
  const { courseOptions, selectedCourse } = useContext(MatrixContext);

  const formRef = useRef(null);

  const initialValueSearchable = {
    courses: courseOptions.find((item) => item.value === selectedCourse),
  };

  return (
    <HeaderInformationSelect>
      <Informations>
        <h5>Matriz Curricular</h5>
        <p>
          Aqui você conseguirá relacionar as suas disciplinas com as matrizes
          existentes do seu curso. Crie quantas matrizes forem necessárias para
          a organização das suas disciplinas/unidades curriculares.
        </p>
      </Informations>

      <SelectCourse>
        <Form ref={formRef} initialData={initialValueSearchable}>
          <SearchableSelectUn
            name="courses"
            options={courseOptions}
            onChange={handleSelectCourse}
          />
        </Form>
      </SelectCourse>
    </HeaderInformationSelect>
  );
}
