import {
  Content,
  BaseAnimation,
  Details,
  Button,
  Divider,
  MatchAnimation,
} from './styled';

export function SkeletonCard({ matchView }) {
  return (
    <Content>
      {matchView && (
        <div className="match-animation">
          <MatchAnimation>
            <div className="ghost" />
          </MatchAnimation>
        </div>
      )}

      <div className="left-side">
        <BaseAnimation />
        <div className="wrapper">
          <Details mWidth="25px" />
          <Details mWidth="300px" />
        </div>
        <div className="wrapper">
          <Details mWidth="25px" />
          <Details mWidth="350px" />
        </div>
        <div className="wrapper">
          <Details mWidth="25px" />
          <Details mWidth="450px" />
        </div>
      </div>
      <div className="right-side">
        <div className="align-divider">
          <Divider />
        </div>
        <div className="container-buttons">
          <div className="box">
            <Button />
            <Button />
          </div>
          <div className="box">
            <Details mWidth="210px" />
          </div>
        </div>
      </div>
    </Content>
  );
}
