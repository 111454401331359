import { MenuItem } from '@szhsin/react-menu';
import { Menu, Button } from './menuStyle';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import downArrow from './downArrow.svg';

export default function ButtonWithMenu({
  insideMenus,
  menuLabel,
  offsetX,
  buttonWidth,
  item,
  marginLeft,
  MinWidth,
  buttonHeight,
}) {
  const menus = insideMenus?.map((menu) => (
    <MenuItem
      disabled={menu.disabled}
      onClick={() => menu.onChange(item?.id, item)}
    >
      {menu.name}
    </MenuItem>
  ));

  return (
    <Menu
      menuButton={
        <Button buttonWidth={buttonWidth} buttonHeight={buttonHeight}>
          {menuLabel} <img src={downArrow} alt={''} />{' '}
        </Button>
      }
      transition
      offsetX={offsetX}
      marginLeft={marginLeft}
      MinWidth={MinWidth}
    >
      {menus}
    </Menu>
  );
}
