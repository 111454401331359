export function IconEditImage({ color }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6917 0.320312C23.4233 0.320312 24.017 0.897557 24.017 1.60881V3.87269L21.3664 6.44967V2.8973H2.81179V13.3341L8.1131 8.18012L13.8491 13.758L11.9738 15.5786L8.1131 11.8266L2.81179 16.9792V20.9362H16.7715L17.7098 20.9375L19.4712 19.2238L21.2338 20.9362H21.3664V17.3825L24.017 14.8055V22.2247C24.017 22.9359 23.4233 23.5132 22.6917 23.5132H1.48646C0.75753 23.5132 0.161133 22.9334 0.161133 22.2247V1.60881C0.161133 0.897557 0.754879 0.320312 1.48646 0.320312H22.6917ZM25.0481 6.51539L26.9221 8.33731L16.6137 18.3592L14.7371 18.3566L14.7397 16.5373L25.0481 6.51539ZM16.7277 5.47428C17.8251 5.47428 18.7157 6.34015 18.7157 7.40702C18.7157 8.4739 17.8251 9.33976 16.7277 9.33976C15.6304 9.33976 14.7397 8.4739 14.7397 7.40702C14.7397 6.34015 15.6304 5.47428 16.7277 5.47428Z"
        fill={color}
      />
    </svg>
  );
}
