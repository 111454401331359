import React from 'react';
import { shape, func, bool } from 'prop-types';

import { FastField } from 'formik';

import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

const StepOneForm = (props) => {
  const {
    classes,
    answered,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    metadata,
  } = props;
  return (
    <List className={classes.list}>
      {/* Question 1 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>1</strong> - Quem você considera ser bem sucedido? O que essas
          pessoas fazem e como elas fazem?
        </p>
        <FastField
          autoFocus
          className={classNames({
            [classes.inputError]:
              errors.pageOneQuestionOne && touched.pageOneQuestionOne,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageOneQuestionOne"
          variant="filled"
          id="pageOneQuestionOne"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageOneQuestionOne && touched.pageOneQuestionOne}
          value={values.pageOneQuestionOne}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageOneQuestionOne &&
            `Sua resposta tem ${values.pageOneQuestionOne.length} caracteres.`}
        </FormHelperText>
        {errors.pageOneQuestionOne && touched.pageOneQuestionOne ? (
          <FormHelperText>{errors.pageOneQuestionOne}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 2 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>2</strong> - Qual é sua maior conquista? O que precisou fazer
          para alcançá-la?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageOneQuestionTwo && touched.pageOneQuestionTwo,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageOneQuestionTwo"
          variant="filled"
          id="pageOneQuestionTwo"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageOneQuestionTwo && touched.pageOneQuestionTwo}
          value={values.pageOneQuestionTwo}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageOneQuestionTwo &&
            `Sua resposta tem ${values.pageOneQuestionTwo.length} caracteres.`}
        </FormHelperText>
        {errors.pageOneQuestionTwo && touched.pageOneQuestionTwo ? (
          <FormHelperText>{errors.pageOneQuestionTwo}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 3 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>3</strong> - O que precisaria acontecer para você alcançar seu
          sucesso ideal?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageOneQuestionThree && touched.pageOneQuestionThree,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageOneQuestionThree"
          variant="filled"
          id="pageOneQuestionThree"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageOneQuestionThree && touched.pageOneQuestionThree}
          value={values.pageOneQuestionThree}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageOneQuestionThree &&
            `Sua resposta tem ${values.pageOneQuestionThree.length} caracteres.`}
        </FormHelperText>
        {errors.pageOneQuestionThree && touched.pageOneQuestionThree ? (
          <FormHelperText>{errors.pageOneQuestionThree}</FormHelperText>
        ) : null}
      </ListItem>
    </List>
  );
};

StepOneForm.propTypes = {
  classes: shape.isRequired,
  answered: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  isSubmitting: bool.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(professionalExperienceFormStyles)(StepOneForm);
