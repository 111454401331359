import React, { Fragment, useState, useEffect } from 'react';
import { shape, func } from 'prop-types';

import {
  FormControl,
  InputLabel,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import SearchableSelect from 'react-select';
import classNames from 'classnames';

import states from 'variables/states';
import { getCities } from 'services/ibge';
import citiesAdapter from 'adapters/citiesAdapter';

const CityAndStateForm = ({
  classes,
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  values,
}) => {
  const [cities, setCities] = useState([]);

  const fetchCities = async (state) => {
    const response = await getCities(state);
    const citiesOptions = await citiesAdapter(response);
    setCities(citiesOptions);
  };

  useEffect(() => {
    if (values.selectedState) {
      if (states.some((state) => state.value === values.selectedState.value)) {
        fetchCities(values.selectedState.value);
      } else {
        setFieldValue('selectedState', values.selectedState);
        setFieldValue('selectedCity', values.selectedCity);
      }
    }
  }, []);

  return (
    <Fragment>
      <Grid container justify="center" spacing={24}>
        <Grid item xs={12} md={4} sm={6}>
          <FormControl variant="filled" style={{ width: '100%' }}>
            <InputLabel htmlFor="selectedState" shrink>
              Estado
            </InputLabel>
            <SearchableSelect
              name="selectedState"
              id="selectedState"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              placeholder={
                values.selectedState ? values.selectedState : 'Selecione'
              }
              error={
                !!errors && !!errors.selectedState && !!touched.selectedState
              }
              isSearchable
              classNamePrefix="react-select"
              className={classNames([classes.selectSection, classes.fullWidth])}
              style={{ width: 'auto' }}
              onBlur={(event) => setFieldTouched('selectedState', event.label)}
              onChange={(event) => {
                if (event) {
                  setFieldValue('selectedState', event);
                  setFieldValue('selectedCity', null);
                  fetchCities(event.value);
                } else {
                  setFieldValue('selectedState', null);
                  setFieldValue('selectedCity', null);
                  setCities([]);
                }
              }}
              options={states}
              value={values.selectedState}
            />
          </FormControl>
          {!!errors && !!errors.selectedState && !!touched.selectedState && (
            <Fragment>
              <FormHelperText
                className={classes.helperText}
                style={{ color: 'red' }}
              >
                {errors.selectedState}
              </FormHelperText>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} md={8} sm={6}>
          <FormControl variant="filled" style={{ width: '100%' }}>
            <InputLabel htmlFor="selectedCity" shrink>
              Cidade
            </InputLabel>
            <SearchableSelect
              name="selectedCity"
              id="selectedCity"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              placeholder={
                values.selectedCity ? values.selectedCity : 'Selecione'
              }
              isSearchable
              error={
                !!errors && !!errors.selectedCity && !!touched.selectedCity
              }
              required
              classNamePrefix="react-select"
              className={classNames([classes.selectSection, classes.fullWidth])}
              onBlur={(event) => setFieldTouched('selectedCity', event.label)}
              isDisabled={values.selectedCity ? false : cities.length <= 0}
              onChange={(event) => {
                if (event) {
                  setFieldValue('selectedCity', event);
                } else {
                  setFieldValue('selectedCity', null);
                }
              }}
              options={cities}
              value={values.selectedCity}
            />
          </FormControl>

          {!!errors && !!errors.selectedCity && !!touched.selectedCity && (
            <Fragment>
              <FormHelperText
                className={classes.helperText}
                style={{ color: 'red' }}
              >
                {errors.selectedCity}
              </FormHelperText>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

CityAndStateForm.propTypes = {
  classes: shape.isRequired,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  values: shape.isRequired,
};

export default CityAndStateForm;
