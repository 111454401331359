import React from 'react';

export function DownloadIcon({ size = 24, color = '#fff' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.97587 13.9989H2.75191C1.15298 13.9989 0.00155388 12.8455 0 11.2441C0 10.5899 0 9.93536 0 9.28046C0 8.84905 0.256392 8.55851 0.637092 8.55279C1.02608 8.54811 1.28506 8.83866 1.28557 9.28462C1.28557 9.93276 1.28557 10.5809 1.28557 11.229C1.28557 12.121 1.86465 12.7052 2.75191 12.7057C5.56823 12.7057 8.38437 12.7057 11.2003 12.7057C12.0871 12.7057 12.6641 12.1215 12.6656 11.2285C12.6656 10.5741 12.6656 9.91925 12.6656 9.26487C12.6656 8.83658 12.9319 8.54811 13.3136 8.55279C13.6953 8.55747 13.9497 8.84438 13.9507 9.25967C13.9507 9.95355 13.9548 10.6469 13.9507 11.3387C13.9489 12.0438 13.6684 12.7194 13.1707 13.2172C12.673 13.715 11.9988 13.9942 11.2962 13.9937C9.85676 14.0035 8.41631 13.9989 6.97587 13.9989Z"
        fill={color}
      />
      <path
        d="M6.3275 7.97265V7.76475C6.3275 5.42547 6.3275 3.08654 6.3275 0.74796C6.3275 0.361256 6.48288 0.124246 6.79366 0.0275699C6.87561 0.00254949 6.96176 -0.00556076 7.04692 0.00372946C7.13208 0.0130197 7.21448 0.0395195 7.28916 0.0816272C7.36384 0.123735 7.42925 0.180578 7.48144 0.248735C7.53364 0.316893 7.57155 0.39495 7.59287 0.478203C7.61546 0.585815 7.62537 0.695722 7.62239 0.805656C7.62447 3.12553 7.62447 5.44522 7.62239 7.76475V7.98045C7.71614 7.96486 7.74981 7.89625 7.79746 7.84895C8.55196 7.09426 9.30576 6.3387 10.0589 5.58227C10.3215 5.32239 10.6685 5.29172 10.9332 5.49963C11.0659 5.60042 11.1539 5.74944 11.1784 5.91466C11.2028 6.07989 11.1616 6.24813 11.0637 6.38322C11.0126 6.45012 10.9563 6.51286 10.8954 6.57086C9.77244 7.69805 8.6502 8.82524 7.52864 9.95243C7.14328 10.3386 6.80971 10.3391 6.42591 9.95243C5.28433 8.80895 4.14292 7.66357 3.00168 6.51628C2.60286 6.11503 2.73753 5.51366 3.25548 5.38268C3.51964 5.31563 3.73097 5.41594 3.91743 5.6041C4.65501 6.3484 5.39431 7.09097 6.13533 7.8318C6.18713 7.88378 6.22546 7.94147 6.27 7.99656L6.3275 7.97265Z"
        fill={color}
      />
    </svg>
  );
}
