export function MessageEmailIcon({ color }) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 15V28.5C35.9976 30.4884 35.2067 32.3947 33.8007 33.8007C32.3947 35.2067 30.4884 35.9976 28.5 36H7.5C5.51161 35.9976 3.60534 35.2067 2.19933 33.8007C0.793323 32.3947 0.00238179 30.4884 0 28.5L0 12C0.00238179 10.0116 0.793323 8.10534 2.19933 6.69933C3.60534 5.29332 5.51161 4.50238 7.5 4.5H19.5C19.8978 4.5 20.2794 4.65804 20.5607 4.93934C20.842 5.22064 21 5.60218 21 6C21 6.39782 20.842 6.77936 20.5607 7.06066C20.2794 7.34196 19.8978 7.5 19.5 7.5H7.5C6.68431 7.50226 5.88468 7.72692 5.18718 8.14981C4.48967 8.5727 3.9207 9.17781 3.5415 9.9L14.817 21.177C15.6625 22.0186 16.807 22.4911 18 22.4911C19.193 22.4911 20.3375 22.0186 21.183 21.177L26.9145 15.45C27.1974 15.1768 27.5763 15.0256 27.9696 15.029C28.3629 15.0324 28.7391 15.1902 29.0172 15.4683C29.2953 15.7464 29.4531 16.1226 29.4565 16.5159C29.4599 16.9092 29.3087 17.2881 29.0355 17.571L23.304 23.304C21.8959 24.708 19.9885 25.4964 18 25.4964C16.0115 25.4964 14.1041 24.708 12.696 23.304L3 13.6065V28.5C3 29.6935 3.47411 30.8381 4.31802 31.682C5.16193 32.5259 6.30653 33 7.5 33H28.5C29.6935 33 30.8381 32.5259 31.682 31.682C32.5259 30.8381 33 29.6935 33 28.5V15C33 14.6022 33.158 14.2206 33.4393 13.9393C33.7206 13.658 34.1022 13.5 34.5 13.5C34.8978 13.5 35.2794 13.658 35.5607 13.9393C35.842 14.2206 36 14.6022 36 15ZM25.5 7.5H28.5V10.5C28.5 10.8978 28.658 11.2794 28.9393 11.5607C29.2206 11.842 29.6022 12 30 12C30.3978 12 30.7794 11.842 31.0607 11.5607C31.342 11.2794 31.5 10.8978 31.5 10.5V7.5H34.5C34.8978 7.5 35.2794 7.34196 35.5607 7.06066C35.842 6.77936 36 6.39782 36 6C36 5.60218 35.842 5.22064 35.5607 4.93934C35.2794 4.65804 34.8978 4.5 34.5 4.5H31.5V1.5C31.5 1.10218 31.342 0.720644 31.0607 0.43934C30.7794 0.158035 30.3978 0 30 0C29.6022 0 29.2206 0.158035 28.9393 0.43934C28.658 0.720644 28.5 1.10218 28.5 1.5V4.5H25.5C25.1022 4.5 24.7206 4.65804 24.4393 4.93934C24.158 5.22064 24 5.60218 24 6C24 6.39782 24.158 6.77936 24.4393 7.06066C24.7206 7.34196 25.1022 7.5 25.5 7.5Z"
        fill={color}
      />
    </svg>
  );
}
