import React, { Fragment } from 'react';
import moment from 'moment';
import { array } from 'prop-types';
import { Document, View, Text } from '@react-pdf/renderer';
import * as styles from './ProfessionalStatusReportPDFStyle';

import gradientBackground from 'assets/img/gradient-background.png';

function convertNumber(data) {
  data = data.toLocaleString('pt-BR', {
    minimumFractionDigits: 0,
    MaximumFractionDigits: 2,
  });
  return data;
}

const mapActiveFilterDataWithComma = (item) => {
  const { label, value } = item;

  const isArray = Array.isArray(item.value);

  function verificateValue() {
    if (isArray) {
      return value.sort().join(', ');
    } else if (value === undefined) {
      return 'Indiferente';
    } else {
      return value;
    }
  }

  return (
    <styles.CategorySection>
      <Text>
        <styles.Bold>{label}: </styles.Bold>
        <styles.Grey>{verificateValue()}</styles.Grey>
      </Text>
    </styles.CategorySection>
  );
};

const ProfessionalStatusReportPDF = ({
  chartsData,
  activeFilters,
  summaryData,
}) => (
  <Document
    title="Perfil dos Estudantes"
    author="Workalove"
    creator="Workalove"
    producer="Workalove"
  >
    <styles.StyledPage>
      <styles.Header fixed>
        <styles.BackgroundImage
          src={gradientBackground}
          alt="gradient background"
        />
        <styles.PageTitle>Perfil dos Estudantes</styles.PageTitle>
        <styles.BottomRightText>Workalove</styles.BottomRightText>
      </styles.Header>

      <styles.AlignRightText fixed>
        Emitido em {moment().format('DD/MM/YYYY')}
      </styles.AlignRightText>

      {chartsData.map((item, index) => (
        <styles.DefaultSection>
          <styles.SectionTitle>{item.title}:</styles.SectionTitle>
          {index === 0 && (
            <styles.TotalStudents>
              {`Total de Estudantes que acessaram o sistema: ${convertNumber(
                summaryData.employed.total + summaryData.unemployed.total
              )}`}
            </styles.TotalStudents>
          )}
          <View>
            {item.emptyChart ? (
              <styles.EmptyText>Sem dados para o gráfico</styles.EmptyText>
            ) : (
              <styles.ChartImage src={item.uri} />
            )}
          </View>
          {index === 0 && (
            <Fragment>
              <styles.LegendMargin>Tipos de trabalho:</styles.LegendMargin>
              <styles.FormatLegend>
                {summaryData.employed.job_type_count.consultant
                  ? convertNumber(
                      summaryData.employed.job_type_count.consultant
                    )
                  : 0}{' '}
                - Trabalhando como consultor |{' '}
                {summaryData.employed.job_type_count.entrepreneur
                  ? convertNumber(
                      summaryData.employed.job_type_count.entrepreneur
                    )
                  : 0}{' '}
                - Trabalhando como empresário |{' '}
                {summaryData.employed.job_type_count.full_time_job
                  ? convertNumber(
                      summaryData.employed.job_type_count.full_time_job
                    )
                  : 0}{' '}
                - Trabalhando em tempo integral |{' '}
                {summaryData.employed.job_type_count.part_time_job
                  ? convertNumber(
                      summaryData.employed.job_type_count.part_time_job
                    )
                  : 0}{' '}
                - Trabalhando em meio período |{' '}
                {summaryData.employed.job_type_count.intern
                  ? convertNumber(summaryData.employed.job_type_count.intern)
                  : 0}{' '}
                - Estagiando |{' '}
                {summaryData.employed.job_type_count.trainee
                  ? convertNumber(summaryData.employed.job_type_count.trainee)
                  : 0}{' '}
                - Trainee
              </styles.FormatLegend>
            </Fragment>
          )}
        </styles.DefaultSection>
      ))}

      <View wrap={false}>
        <styles.SectionTitle>
          Filtros aplicados para geração do relatório:
        </styles.SectionTitle>

        <styles.Space />

        {activeFilters &&
          activeFilters.map((item) => mapActiveFilterDataWithComma(item))}
      </View>

      <styles.Footer fixed>
        <Text>Workalove - www.workalove.com</Text>
      </styles.Footer>
    </styles.StyledPage>
  </Document>
);

ProfessionalStatusReportPDF.propTypes = {
  chartsData: array.isRequired,
  activeFilters: array.isRequired,
};

export default ProfessionalStatusReportPDF;
