import React, { useContext } from 'react';
import BaseLayoutContext from '../../../../../../contexts/base-layout';
import { Button } from './components/Buttons/buttonJobDetail';
import { JobDetailsApplications } from 'views/JobOffers/components/JobDetails/jobDetailsApplications';

export const JobDetailButton = (props) => {
  const { children, jobID, isFromPartners, externalJobOffer, ...rest } = props;

  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);

  const handleClickList = (e) => {
    e.preventDefault();
    openModalWithContent(
      <JobDetailsApplications
        jobID={jobID}
        jobId={jobID}
        externalJobOffer={externalJobOffer}
        isFromPartners={isFromPartners}
        isUniversityScope={false}
        isCompanyScope={true}
        handleCloseModal={closeModal}
        fullWidth
      />
    );
  };

  return (
    <Button onClick={handleClickList} {...rest}>
      {children}
    </Button>
  );
};
