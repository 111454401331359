import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Card = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};

  border-radius: 8px;
  background-color: #fafafa;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px;
`;

export const CardGrayGradient = styled.div`
  position: absolute;
  top: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 215px;
  background-color: #f0f0f0;
  height: 60px;
  background-image: linear-gradient(
    120deg,
    #f0f0f0 30%,
    #f5f5f5 38%,
    #f5f5f5 40%,
    #f0f0f0 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none !important;
  width: 100%;
  top: 4em;
`;

export const StudentImage = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  position: absolute;
  background-color: #e5e5e5;
  border: 1px solid #f0f0f0;

  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.1s linear infinite;
`;

export const CardTitle = styled.div`
  position: absolute;
  width: 120px;
  height: 20px;
  top: 8em;
  margin-left: 20%;
  background-color: #e5e5e5;
  border: 1px solid #f0f0f0;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  border-radius: 4px;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardSubTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin-left: 13px;
  top: 11em;
`;

export const CardSubTitles = styled.div`
  width: 90%;
  height: 20px;
  border-radius: 4px;
  background-color: #e5e5e5;
  border: 1px solid #f0f0f0;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;
