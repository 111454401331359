import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

// creates a beautiful scrollbar
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import DnaDialog from 'components/Dialog/Dialog';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import BaseLayoutContext from 'contexts/base-layout';
import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle';
import { Helmet } from 'react-helmet';

import { Container } from './styled';
import { getUniversityLogos } from 'services/login/authenticate';
import useDefineColor from 'constants/colors';
import octadesk, { removeOctadesk } from 'utils/octadesk';

class BaseLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      isLoading: false,
      dnaDialogIsOpen: false,
      dialogContent: null,
      dialogButtonLabel: null,
      dialogCloseButtonLabel: null,
      snackbarOpen: false,
      snackbarMessage: null,
      universityColor: null,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.universityName = '';
  }

  getGtmIdForUniversity = (universityId) => {
    const gtmIds = {
      136: 'GTM-W43DVDX7',
      134: 'GTM-TMZ22ZG3',
    };
    return gtmIds[universityId.toString()] || null;
  };

  addGtmScript = (gtmId) => {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${gtmId}');`;
    document.head.appendChild(script);
  };

  componentDidMount() {
    const { match, university, location } = this.props;
    this.universityName = university ?? match?.params?.universidade ?? '';
    const handleColorLoading = async () => {
      if (this.universityName !== '') {
        const response = await getUniversityLogos(this.universityName);
        this.setState({ universityColor: response.data.university_color });
      }
    };
    handleColorLoading();
    octadesk();
    this.initializeUniversitySettings();
    window.addEventListener('resize', this.resizeFunction);

    setTimeout(() => {
      if (location.pathname === '/login/backoffice') {
        removeOctadesk();
      }
    }, '100');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  initializeUniversitySettings = async () => {
    const universityName = this.getUniversityName();
    if (universityName) {
      await this.loadUniversityColor(universityName);
      await this.verifyAddGtmScript(universityName);
    }
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  getUniversityName = () => {
    const { match, university } = this.props;
    return university ?? match?.params?.universidade ?? '';
  };

  loadUniversityColor = async (universityName) => {
    const response = await getUniversityLogos(universityName);
    this.setState({ universityColor: response.data.university_color });
    !response.data.use_tecnhical_support && removeOctadesk();
  };

  verifyAddGtmScript = async (universityName) => {
    const response = await getUniversityLogos(universityName);
    const universityId = response.data.id;
    const gtmId = this.getGtmIdForUniversity(universityId);
    if (gtmId && process.env.NODE_ENV === 'production') {
      this.addGtmScript(gtmId);
    }
  };

  openDnaDialog = (
    dialogTitle,
    dialogContent,
    buttonLabel,
    dialogCallback,
    closeButtonLabel,
    closeCallback
  ) => {
    this.setState({
      dnaDialogIsOpen: true,
      dialogCallback,
      closeCallback,
      dialogTitle,
      handleIsLoadingState,
      dialogContent,
      dialogButtonLabel: buttonLabel,
      dialogCloseButtonLabel: closeButtonLabel,
    });
  };

  handleCloseDnaDialog = () => {
    this.setState({
      dnaDialogIsOpen: false,
      dialoglCallback: null,
      dialogTitle: null,
    });
  };

  handleIsLoadingState = (isLoading) => {
    this.setState({
      isLoading,
    });
  };

  handleOpenSnackbarWithMessage = (message, isError) => {
    this.setState({
      snackbarOpen: true,
      snackbarMessage: message,
      snackError: isError,
    });
  };

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false,
      snackbarMessage: null,
    });
  };

  render() {
    const { universityColor } = this.state;
    const {
      classes,
      isStudentLogin,
      childComponent: ChildComponent,
    } = this.props;

    const propsCopy = { ...this.props };
    delete propsCopy.classes;

    return (
      <div>
        <Helmet>
          <title>Workalove</title>
        </Helmet>
        <Container>
          <BaseLayoutContext.Provider
            value={{
              ...this.state.data,
              ...propsCopy,
              openDnaDialog: this.openDnaDialog,
              handleCloseDnaDialog: this.handleCloseDnaDialog,
              handleIsLoadingState: this.handleIsLoadingState,
              openSnackbar: this.handleOpenSnackbarWithMessage,
              handleCloseSnackbar: this.handleCloseSnackba,
            }}
          >
            <div className={classes.container} style={{ width: '100%' }}>
              <BaseLayoutContext.Consumer>
                {(value) => <ChildComponent {...value} />}
              </BaseLayoutContext.Consumer>
            </div>
          </BaseLayoutContext.Provider>
        </Container>
        <DnaDialog
          isOpen={this.state.dnaDialogIsOpen}
          closeDialog={this.handleCloseDnaDialog}
          dialogCallback={this.state.dialogCallback}
          closeCallback={this.state.closeCallback}
          dialogContent={this.state.dialogContent}
          dialogTitle={this.state.dialogTitle}
          buttonLabel={this.state.dialogButtonLabel}
          closeButtonLabel={this.state.dialogCloseButtonLabel}
          universityColor={universityColor}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleCloseSnackbar}
        >
          <SnackbarContent
            className={cx({
              [classes.success]: !this.state.snackError,
              [classes.error]: this.state.snackError,
            })}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleCloseSnackbar}
              >
                <CloseIcon />
              </IconButton>,
            ]}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message}>
                <CheckCircleIcon className={classes.icon} />
                {this.state.snackbarMessage}
              </span>
            }
          />
        </Snackbar>
        {this.state.isLoading && (
          <div
            className={cx({
              [isStudentLogin
                ? classes[`${useDefineColor(universityColor)}loadingHolder`]
                : classes.dnaColorloadingHolder]: true,
              [classes.showLoading]: this.state.isLoading,
            })}
          >
            <div className={classes.spinner}>
              <div className={classes.rect1} />
              <div className={classes.rect2} />
              <div className={classes.rect3} />
              <div className={classes.rect4} />
              <div className={classes.rect5} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

BaseLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  childComponent: PropTypes.func.isRequired,
};

export default withStyles(appStyle)(BaseLogin);
