export const Themes = (universityColor) => {
  const theme = {
    light: {
      background: universityColor,
      color: '#FFF',
    },

    unfilled: {
      background: 'transparent',
      color: universityColor,
      border: true,
    },
    flat: {
      background: 'transparent',
      color: universityColor,
      border: false,
      underline: true,
    },
  };

  return theme;
};
