import React, { useState, useContext } from 'react';
import { ModalContent } from './style';
import StepOne from '../../StepOne';
import StepTwo from '../../StepTwo';
import { IndicationStudentContext } from '../../../context/indicationStudentContext';
export default function IndicateStudentModal() {
  const { stepNumber } = useContext(IndicationStudentContext);

  return (
    <>
      <ModalContent>{stepNumber == 1 ? <StepOne /> : <StepTwo />}</ModalContent>
    </>
  );
}
