import styled from 'styled-components';

export const ButtonNotification = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ImageIcon = styled.img`
  width: 20px;
  height: 36px;
`;
