import styled from 'styled-components';
import CheckboxCheck from './assets/CheckboxCheck.svg';

export const ModalOverlay = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  background-color: #00000040;
  top: 0;
  left: 0;
  z-index: 9000;
`;

export const FilterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  > div {
    display: flex;
    gap: 1rem;
  }
`;

export const ModalContainer = styled.div`
  display: ${(props) => (props.activeTab === 2 ? 'none' : 'inline-block')};
  flex-direction: column;
  z-index: 10000;
  text-align: left;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
  color: #606062;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0;
  color: #606062;
  margin: 0;
`;

export const ButtonClose = styled.button`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #c9c9c9;
  border: 1px solid transparent;
  padding: 2px 3px 3px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  position: absolute;
  margin-top: 1rem;
  z-index: 10000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}

  @media (max-width: 600px) {
    border-radius: 7px 7px 0 0;
    max-height: 100%;
    max-width: calc(100vw - 32px);
    position: fixed;
    top: 0;
    right: 16px;
    width: 100%;
    height: auto;
    overflow: auto;
  }

  @media screen and (min-width: 601px) {
    margin-left: -440px;
  }

  .teste {
    content: '';
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background: #fff;
    position: absolute;
    top: -10px;
    left: calc(100% - ${(props) => (props.hasFilters ? 115 : 95)}px);
    z-index: 10000;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  > strong {
    font-weight: bold;
    font-size: 18px;
    color: #4b4b4d;
    margin: 0;
    padding: 2rem 2rem 0 2rem;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin: 1rem 0 0 0;
      background-color: #dcdcdc;
    }
  }
`;

export const FixedContent = styled.div`
  display: flex;
  padding: 1rem 2rem;
`;

export const InputDiv = styled.div`
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  > label {
    color: #606062;
    font-size: 16px;
  }

  > input {
    border: 1px solid #efeeec;
    color: #606062;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 14px;
  }
`;

export const CheckboxUl = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5%;
  list-style: none;
  justify-content: space-between;
  padding: 0;
  transition: 0.3s;

  @media (max-width: 394px) {
    transition: 0.3s;
    flex-direction: column;
  }

  li {
    width: 45%;
    padding: 5px 0;
    transition: 0.3s;

    @media (max-width: 394px) {
      transition: 0.3s;
      width: 100%;
    }
  }
`;

export const FilterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const ButtonFilter = styled.button`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: 1px solid transparent;
  color: white;
  padding: 7px 10px;
  border-radius: 3px;
  width: fit-content;
  margin: 0 0 20px auto;

  cursor: pointer;
  transition: 0.2s linear;

  img {
    margin-right: 5px;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const FilterContent = styled.div`
  display: flex;
  gap: 1rem;
  overflow-y: auto;
  padding: 0 2rem;
  margin: 0 0.25rem 0.25rem 0;
  width: 620px;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;
  strong {
    font-size: 14px;
    color: #606062;
    font-weight: 700;
  }
  input[type='text'].filter-text {
    height: 38px;
    border-radius: 3px;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 0.5rem;
    box-sizing: border-box;
    &:active,
    &:focus {
      border: 2px solid #009291;
    }
  }
`;

export const FilterUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &.two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin: 0;
  }
`;

export const FilterCheckboxLi = styled.li`
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  input {
    height: 18px;
    width: 18px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;
    &:checked {
      border: none;
      background-color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
      background-image: url(${CheckboxCheck});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
  label {
    font-size: 14px;
    color: #949494;
    line-height: 24px;
    box-sizing: border-box;
    cursor: pointer;
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

export const FilterRadioLi = styled.li`
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;
  input {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;
    &:checked {
      border: 3.6px solid #009291;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  label {
    font-size: 14px;
    color: #949494;
    line-height: 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

export const FilterDatePeriodContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const ChipButton = styled.button`
  border: none;
  background: #009291;
  height: 22px;
  border-radius: 30px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  p {
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    margin: 0;
  }
`;

export const SelectFilterBox = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  min-width: 226px;
  box-sizing: border-box;
`;

export const FilterCounter = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  background-color: #fff;
  color: #009291;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  transition: 0.3s;

  @media (max-width: 381px) {
    transition: 0.3s;
    padding-left: 0px;
    padding-right: 0px;
  }

  div {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-left: auto;
  }

  button {
    border: none;
    font-size: 12px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    transition: filter 0.2s;
    padding: 0 1rem;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const CancelButton = styled.button`
  background-color: #fff;
  color: #606062;
`;

export const ApplyButton = styled.button`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: #fff;

  &:disabled {
    background-color: #6060629e;
  }
`;

export const CleanFiltersButton = styled.button`
  background-color: transparent;
  font-weight: 600;
  color: #606062;
`;

export const ContainerInput = styled.div`
  width: 400px;
  height: 48px;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2s;

  @media (max-width: 700px) {
    transition: 0.2s;
    width: 100%;
  }

  > input {
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border: none;
    margin-left: 10px;
  }
`;

export const InputName = styled.input`
  padding: 8px;
  padding-right: 32px;
`;

export const ContainerIcon = styled.div`
  right: 8px;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 2px;
  gap: 10px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  position: absolute;
  display: flex;
  align-items: center;
`;

export const IconImg = styled.img`
  width: 24px;
  height: 24px;
`;
