import styled from 'styled-components';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';

export const WrapperBar = styled.div`
  height: ${(props) => (props.openMore ? 'auto' : '100px')};
`;

export const WrapperList = styled.div`
  max-width: 550px;
  height: auto;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

export const ExpandedContent = styled.div`
  border-bottom: ${(props) => (props.checked ? '1px solid #009291' : '')};
  border-left: ${(props) => (props.checked ? '1px solid #009291' : '')};
  border-right: ${(props) => (props.checked ? '1px solid #009291' : '')};
  background-color: ${(props) => (props.checked ? '#00929117' : '')};
  padding-left: 2rem;
`;
export const ContainerName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: #f8f8f8;
  padding: 5px;
  color: #777777;
  border-radius: 2px;
  font-weight: 400;
  font-size: 12px;

  > span {
    border-radius: 2px;
    padding: 5px;
    background-color: #009291;
    color: #ffff;
    text-align: center;
    cursor: pointer;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const Title = styled.span`
  color: #b8b8b8;
  margin-top: 8px;
  margin-bottom: 10px;
  display: inline-flex;
  font-weight: 400;
`;

export const ActionArrow = styled.div`
  right: 15px;
  width: 25px;
  height: 25px;
  min-height: 25px;
  min-width: 25px;
  background-color: #e1e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
`;

export const ExpandedContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlagsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  margin: 1rem 0;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    > label {
      color: #777;
      font-size: 12px;
      margin-top: 2px;
      margin-left: 2px;
      white-space: nowrap;
    }
  }
  input {
    height: 14px;
    width: 14px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: #009291;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const Bubble = styled.div`
  display: none;
  position: absolute;
  background: #009291;
  color: #ffff;
  text-align: center;
  width: 106px;
  height: 23px;
  padding: 0;
  margin-top: 29px;
  font-weight: 400;
  border-radius: 4px;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #009291 transparent;
    border-width: 0 8px 11px;
    top: -11px;
    left: 22%;
    margin-left: -20px;
  }
`;
export const WrapperBubble = styled.div`
  position: relative;

  &:hover {
    > div {
      display: block;
    }
  }
`;
