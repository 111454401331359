import React, { useEffect } from 'react';
import TabsManager from './screens';
import { MentorshipContextProvider } from './context/MentorshipContext';

export default function MentorshipEngine({ handleIsLoadingState, props }) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <MentorshipContextProvider>
      <TabsManager {...props} />
    </MentorshipContextProvider>
  );
}
