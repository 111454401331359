export const configModalEmailNotFoundCompany = {
  modalHeight: 450,
  bubbleText: `Aviso!`,
  ParagraphText: `
    Infelizmente não identificamos no seu cadastro nenhum
    e-mail relacionado ao e-mail preenchido.
    <br />
    <br />
    Por favor, verifique se o e-mail preenchido é o mesmo do cadastro.
  `,
  style: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '25px',
  },
  buttonConfirmText: `ENTENDI`,
};

export const configModalBlackList = {
  modalHeight: 500,
  bubbleText: `Aviso!`,
  ParagraphText: `
    Não foi possível solicitar a sua recuperação de senha. É
    possível que haja problemas com o seu endereço de e-mail
    cadastrado em nossa plataforma.
    <br />
    <br />
    Entre em contato com a sua <strong>Instituição de Ensino</strong> 
    e solicite a atualização de seu cadastro na plataforma Workalove!
  `,
  style: {
    fontSize: '16px',
    lineHeight: '25px',
  },
  buttonConfirmText: `ENTENDI`,
};
