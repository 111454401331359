import React from 'react';
import LoadingUpdate from '../../../components/LoadingUpdate/LoadigUpdate';

import { InfoModalBackground, InfoModalContainer } from './styled';

export default function LoadingModal({ isOpen }) {
  return (
    <InfoModalBackground isOpen={isOpen}>
      <InfoModalContainer>
        <div className="content-wrapper">
          <LoadingUpdate />
          <h6> Estamos buscando as informações da oportunidade. Aguarde... </h6>
        </div>
      </InfoModalContainer>
    </InfoModalBackground>
  );
}
