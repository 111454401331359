import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;

export const Row = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const TextMentorName = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: #606062;
  font-family: 'Roboto', sans-serif;
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009291;
  color: #fff;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 0 1rem;
  cursor: pointer;
  transition: 0.2s;
  font-size: 16px;
  margin-left: auto;
  gap: 0.5rem;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TypeLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #eef2f4;
  height: 40px;
  font-size: 16px;
  color: #606062;
  padding: 0 1rem;
  width: fit-content;
  margin-top: 1rem;
`;

export const Description = styled.span`
  margin-top: 1rem;
  text-align: justify;
  font-size: 16px;
  color: #606062;
  line-height: 21px;
`;
export const TitleText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #606062;
  margin-top: 2rem;
`;

export const ScheduleBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  margin-top: 1rem;
  border-radius: 3px;
`;

export const DatesWrapper = styled.div`
  display: flex;
  gap: 2.5rem;
  row-gap: 1rem;
  flex-wrap: wrap;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  }
`;

export const TimeBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 45px;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  font-size: 16px;
  color: #606062;
  margin-left: ${(props) => props.marginLeft || '0'};
  margin-right: ${(props) => props.marginRight || '0'};
`;

export const CoursesContent = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

export const CourseBox = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 1rem;
  border: 1px solid ${(props) => props.color || '#009291'};
  border-radius: 3px;
  font-size: 16px;
  color: #606062;
`;

export const SpecialityBox = styled(CourseBox)`
  background-color: #0092911a;
  color: #009291;
  border: none;
  word-break: break-all;
  overflow-wrap: break-word;
`;

export const SpecificsDatesWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SpecificDateLabel = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  height: 26px;
  border-radius: 2px;
  background-color: #eef2f4;
  color: #606062;
  font-size: 14px;
  margin-bottom: 0.5rem;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const ScheduleContent = styled.div`
  display: flex;
  padding: 1rem;
`;
