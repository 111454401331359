import styled from 'styled-components';

export const InputRadio = styled.input`
  accent-color: ${(props) => props.universityColor || '#009291'};
  margin: 0;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LabelInput = styled.label`
  display: flex;
  align-items: center;
`;

export const TitleLabel = styled.p`
  font-size: 15px;
  color: #4b4b4d;
  margin: 0;
  padding: 0 10px;
`;

export const SubTitle = styled.p`
  margin-left: 30px;
  font-size: 14px;
  color: #606062;
  margin-top: -5px;
`;
