import React from 'react';
import CircularProgressBar from '../../../../../components/circularProgress';

import { CardContainer, ContainerProgress, H5, HelpText } from './style';

export default function CareerObjective({ graphData }) {
  const baseConfig = {
    size: 120,
    strokeWidth: 4.3,
  };

  const createPropsObject = (item) => {
    return {
      size: baseConfig.size,
      media: item.percentage,
      strokeWidth: 4.3,
      label: item.label,
      studentQuantityBubble: item.value,
      ...(item.help && { questionLabel: item.help }),
    };
  };

  const returnCircularProgressItens = () => {
    if (graphData) {
      return graphData.chart.map((item) => {
        const propsCircularProgress = createPropsObject(item);

        return <CircularProgressBar {...propsCircularProgress} />;
      });
    }
  };

  return (
    <CardContainer>
      <H5>Objetivo de carreira</H5>
      <ContainerProgress>{returnCircularProgressItens()}</ContainerProgress>
      <HelpText>
        A somatória das porcentagens representam 100% dos estudantes
        respondentes.
      </HelpText>
    </CardContainer>
  );
}
