import styled from 'styled-components';
import { device } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/ModalShowEvidence/utils/devicesQuery';

export const BaseContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.usingPreviewPdf ? 0 : 1300)};
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  height: auto;

  @media (max-height: 648px) {
    top: 2%;
  }
`;

export const Card = styled.div`
  width: 40.5rem;
  background-color: #ffff;
  border-radius: 8px;
  position: relative;
  min-height: 200px;
  text-align: initial;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 55px;

  > h3 {
    font-size: 20px;
    font-weight: 500;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }

  @media ${device.mobileL} {
    width: 26.4em;
    padding: 40px 20px;
  }
`;

export const ContaineForm = styled.div`
  display: flex;
  width: 100%;

  > form {
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  background-color: #949494;
  height: 1px;
  margin: 35px 0px;
`;

export const SaveButton = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 40px;

  > button {
    cursor: pointer;
    padding: 0 20px;
    &:active {
      transform: scale(0.9);
    }

    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    border: none;
    color: #ffff;

    height: 40px;
    font-weight: bold;
    border-radius: 4px;
  }
`;

export const ContainerList = styled.div`
  max-height: 162px;
  overflow-y: auto;
  width: 100%;
  padding-top: 16px;
`;

export const SubHeader = styled.h5`
  font-weight: 500;
  color: #606062;
  font-size: 15px;
`;
