import styled from 'styled-components';

import Youtube from 'assets/SettingsPage/Profile/Youtube.svg';

export const Container = styled.div`
  width: 100%;
  height: 500px;
  background-color: #ffffff;
  height: 100%;
  padding: 35px 0px;

  ${(props) => props.top && `margin-top: ${props.top}px;`}
`;

export const HeaderTitle = styled.h4`
  color: #4b4b4d;
  font-weight: bold;
  margin: 0;
  font-size: ${(props) => (props.size === 'main' ? '20px' : '18px')};
`;

export const HeaderSubTitle = styled.span`
  color: #606062;
  margin-top: ${(props) => (props.useNegative ? '-5px' : '0px')};
`;

export const Divider = styled.div`
  width: 100%;
  border: 0.5px solid #cdcdcd;
  margin-top: 30px;
`;

export const ColorInput = styled.input`
  -webkit-appearence: none;
  -moz-appearance: none;
  appearance: none;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin: auto 0;

  ::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
  }
`;

export const ContainerColorInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  width: 155px;
  border-radius: 4px;
  cursor: pointer;
`;

export const ContainerColorArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;

  > input {
    width: 110px;
    border: none;
    background-color: transparent;
    height: 35px;
  }
`;

export const ContainerComponentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  ${(props) =>
    props.useGap ? 'gap: 30px;' : 'justify-content: space-between;'}
`;

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerLogo = styled.div`
  border: 1px dashed #606062;
  padding: 20px 15px;
  border-radius: 4px;

  > img {
    width: 150px;
  }
`;

export const ContainerImages = styled.div`
  padding: 20px 15px;
  border-radius: 4px;
  display: flex;
  gap: 20px;

  > div > img {
    width: 150px;
  }

  > div {
    position: relative;
  }

  > div > span {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 25px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const UploadImageButton = styled.span`
  display: flex;
  align-items: center;
  color: #009291;
  font-weight: bold;
  margin-top: 8px;
  gap: 5px;
  cursor: pointer;
`;

export const YoutubeInput = styled.input`
  padding-left: 40px;
  background-image: url(${Youtube});
  background-repeat: no-repeat;
  background-position: 12px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 332px;
`;

export const SaveButton = styled.button`
  margin: 40px 0px;
  background-color: #009291;
  color: #fff;
  width: 124px;
  height: 38px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  padding: 0px 15px;

  &:disabled {
    color: #606062;
    background-color: #c4c4c4;
    cursor: not-allowed;
  }
`;

export const WrapperInputVideo = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: #f00;
    font-weight: 300;
    margin-top: -18px;
  }
`;

export const PreviewButton = styled.button`
  min-width: 155px;
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  border: none;
  height: 35px;
  border-radius: 4px;
  gap: 10px;
  cursor: pointer;
  padding: 0px 17px;

  > img {
    width: 16px;
  }
`;
