import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const TimeElapsedInfo = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #949494;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
  margin: 40px 0 20px;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const WrapperInformations = styled.div`
  width: 100%;
  max-width: 610px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;
  margin: 20px 0;

  > span {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.color || '#009291'};
    margin-left: 5px;
  }
`;

export const RejectButton = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  margin-bottom: 20px;
  color: #da0505;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const ConfirmButton = styled.button`
  width: 79px;
  height: 39px;
  gap: 10px;
  padding: 10px;
  border-radius: 3px;
  border: 1px;
  opacity: 0px;
  margin-bottom: 20px;
  background-color: ${(props) => props.color || '#009291'};

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: center;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const WrapperForm = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.31px;
  width: 100%;

  > p {
    margin: 5px 0;
  }
`;

export const WrapperFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
