import React, { useState, useEffect, useRef } from 'react';

import TargetIcon from 'assets/Oportutinites/target.svg';

import {
  getAdditionalInfosChallenge,
  getOptionsCoursesChallenges,
} from '../../services/challenges';

import ModalInformations from '../ModalMoreInformations/ModalInformations';

import {
  Card,
  CardImg,
  TitleH4,
  Informations,
  IconImage,
  MoreInfo,
  Information,
  WrapperChallenges,
  ContainerChallenges,
  CardTitle,
  CardSubTitle,
  ContainerTitleSubTitle,
  DivPagination,
  ButtonPagination,
  DivButton,
  ActivityType,
  StarActivityWrapper,
  StarActivityP,
  DivItemsStudents,
  ActivityTypeTwo,
  ImageActivity,
  DashedBorder,
  PlusButton,
  PlusImg,
} from './styles';

import imageBackgroundCardChallenge from 'assets/img/imageBackgroundCardChallenge.png';
import published from 'assets/Oportutinites/published.svg';
import expired from 'assets/Oportutinites/expired.svg';
import deleted from 'assets/Oportutinites/deleted.svg';
import checked from 'assets/Oportutinites/checked.svg';
import liked from 'assets/Oportutinites/liked.svg';

import SkeletonCardsChallenges from '../SkeletonCardsChallenges';
import LoadingCards from '../LoadingCards';
import GenericMessage from 'components/GenericMessage';

export default function CardChallenges({
  setCurrentStep,
  setFormValues,
  formData,
  modalInfoOpen,
  setModalInfoOpen,
  setActiveTab,
  isOpenModal,
  setIsOpenModal,
  setFormData,
  currentStep,
  cacheOptions,
  setCacheOptions,
  challenges,
  loadChallenges,
  setChallenges,
  count,
  ScrollToTop,
}) {
  const [item, setItem] = useState([]);
  const [limit, setLimit] = useState(24);
  const [loading, setLoading] = useState(false);
  const [loadingChallengeModal, setLoadingChallengeModal] = useState(false);

  const containerModal = useRef();

  const publicationFormat = {
    access_link: 'Link de acesso',
    video: 'Vídeos sequenciais',
    attraction_document: 'Arquivo PDF',
  };

  const handleCardsChange = async () => {
    const newCards = `limit=${limit}`;
    await loadChallenges(newCards);
    setLoading(false);
  };

  const loadMore = () => {
    setLoading(true);
    setLimit(limit + 24);
  };

  useEffect(() => {
    handleCardsChange(`limit=${limit}`);
  }, [limit]);

  const returnImageStatus = (item) => {
    if (item.is_published === false) {
      return deleted;
    }
    if (item.is_published === true && item.status === null) {
      return published;
    }
    if (item.status === 'Expirado(a)') {
      return expired;
    }

    return published;
  };

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const getAdditionalInfos = async (item) => {
    const response = await getAdditionalInfosChallenge(item);
    if (
      response.attraction_image &&
      response.attraction_image.value &&
      response.attraction_document &&
      response.attraction_document.value
    ) {
      const urlImg = `data:application/pdf;base64, ${response.attraction_image.value}`;
      const returnImgFromb64 = await fetch(urlImg)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${response.attraction_image.label}`, {
            type: 'image/png',
          });
          return file;
        });

      const urlpdf = `data:application/pdf;base64, ${response.attraction_document.value}`;
      const returnPdf = await fetch(urlpdf)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            `${response.attraction_document.label}`,
            {
              type: 'application/pdf',
            }
          );
          return file;
        });
      setFormValues({
        ...formData,
        ...response,
        attraction_image: returnImgFromb64,
        attraction_document: returnPdf,
        courses:
          response.target_audiences[0] && response.target_audiences[0].courses,
        competences_specialities: response.competences_specialities,
      });
      setIsOpenModal(true);

      return;
    }

    if (response.attraction_image && response.attraction_image.value) {
      const urlImg = `data:application/pdf;base64, ${response.attraction_image.value}`;
      const returnImgFromb64 = await fetch(urlImg)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `${response.attraction_image.label}`, {
            type: 'image/png',
          });
          return file;
        });
      setFormValues({
        ...formData,
        ...response,
        attraction_image: returnImgFromb64,
        attraction_document: null,
        courses:
          response.target_audiences[0] && response.target_audiences[0].courses,
        competences_specialities: response.competences_specialities,
      });
      setIsOpenModal(true);
      return;
    }

    if (response.attraction_document && response.attraction_document.value) {
      const urlpdf = `data:application/pdf;base64, ${response.attraction_document.value}`;
      const returnPdf = await fetch(urlpdf)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File(
            [blob],
            `${response.attraction_document.label}`,
            {
              type: 'application/pdf',
            }
          );
          return file;
        });
      setFormValues({
        ...formData,
        ...response,
        attraction_document: returnPdf,
        attraction_image: null,
        courses:
          response.target_audiences[0] && response.target_audiences[0].courses,
        competences_specialities: response.competences_specialities,
      });
      setIsOpenModal(true);
      return;
    }
    setFormValues({
      ...formData,
      ...response,
      attraction_document: null,
      attraction_image: null,
      courses:
        response.target_audiences[0] && response.target_audiences[0].courses,
      competences_specialities: response.competences_specialities,
    });
    setIsOpenModal(true);
  };

  const handleClickEdit = async (item) => {
    setLoadingChallengeModal(true);
    const response = await getOptionsCoursesChallenges();
    const options = response.principal_course;
    setCacheOptions(options);
    setFormValues({
      ...item,
      attraction_image: null,
      attraction_document: null,
    });
    await getAdditionalInfos(item.id);
    setLoadingChallengeModal(false);
  };

  const handleClickMoreInfo = (item) => {
    setModalInfoOpen(true);
    setItem(item);
  };

  const returnPaginationButton = () => {
    if (challenges && challenges.length >= 24) {
      return loading ? (
        <LoadingCards />
      ) : (
        <DivPagination>
          <DivButton>
            <ButtonPagination
              disabled={challenges && challenges.length === count}
              onClick={loadMore}
            >
              Ver mais
            </ButtonPagination>
          </DivButton>
        </DivPagination>
      );
    }
  };

  const mapChallenges = (array) => {
    if (array === null) {
      return <SkeletonCardsChallenges quantity={randomIntFromInterval(1, 3)} />;
    }

    if (!array.length) {
      return (
        <GenericMessage
          title="Não identificamos nenhum Desafio publicado!"
          buttonLabel="Adicionar Desafio"
          buttonFunc={() => setActiveTab(0)}
        />
      );
    }

    return array.map((item) => (
      <Card>
        <CardImg
          src={
            item.attraction_image
              ? item.attraction_image
              : imageBackgroundCardChallenge
          }
        />
        {item && (
          <DivItemsStudents>
            <ActivityType>
              <StarActivityWrapper>
                <ImageActivity src={checked} />
                <StarActivityP>
                  {item.total_reaction && item.total_reaction.total_finished}
                </StarActivityP>
              </StarActivityWrapper>
            </ActivityType>

            <ActivityTypeTwo>
              <StarActivityWrapper>
                <ImageActivity src={liked} />
                <StarActivityP>
                  {item.total_reaction && item.total_reaction.total_liked}
                </StarActivityP>
              </StarActivityWrapper>
            </ActivityTypeTwo>
          </DivItemsStudents>
        )}
        <TitleH4>{item.name}</TitleH4>
        <Informations>
          <div>
            <Information>
              <IconImage src={TargetIcon} />{' '}
              <p>
                {publicationFormat[
                  item.type_access && item.type_access.value
                ] || 'Não informado'}
              </p>
            </Information>
            <Information>
              <img
                style={{ margin: '0 10px', width: '6%' }}
                src={returnImageStatus(item)}
              />
              <p>{item.is_published ? 'Publicado' : 'Despublicado'}</p>
            </Information>
          </div>
          <div>
            <MoreInfo onClick={() => handleClickMoreInfo(item)}>
              Mais informações
            </MoreInfo>
          </div>
        </Informations>
      </Card>
    ));
  };

  return (
    <div>
      <ContainerChallenges ref={containerModal}>
        <ContainerTitleSubTitle>
          <CardTitle>Desafios</CardTitle>
          <CardSubTitle>
            Veja aqui os desafios publicados pela sua empresa
          </CardSubTitle>
        </ContainerTitleSubTitle>

        {Object.keys(item).length > 0 && (
          <ModalInformations
            item={item}
            setModalOpen={setModalInfoOpen}
            isOpen={modalInfoOpen}
            setItem={setItem}
            setActiveTab={setActiveTab}
            loadChallenges={loadChallenges}
            setModalInfoOpen={setModalInfoOpen}
            setChallenges={setChallenges}
            handleClickEdit={handleClickEdit}
            formData={formData}
            setFormData={setFormData}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            cacheOptions={cacheOptions}
            setCacheOptions={setCacheOptions}
            setFormValues={setFormValues}
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
            loadingChallengeModal={loadingChallengeModal}
            ScrollToTop={ScrollToTop}
          />
        )}
        <WrapperChallenges>{mapChallenges(challenges)}</WrapperChallenges>

        {returnPaginationButton()}
      </ContainerChallenges>
    </div>
  );
}
