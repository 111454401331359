import { Form } from '@unform/web';
import { useRef, useEffect, useState } from 'react';
import {
  ContainerForm,
  ContainerButton,
  Title,
  Subtitle,
  TagLength,
  CloseButton,
} from '../../../Styles/FormStyles';
import Input from 'components/UnformFields/inputField';
import { InputMultiText } from '../../../components/Form/InputMultiText';
import MultiSelect from '../../../components/Form/MultiSelect';
import academicInnovationApi from 'views/Backoffice/academicInnovation/services/academicInnovationApi';
import { ValidationSchema } from './schema';
import * as Yup from 'yup';
import { useFormContext } from '../../../contexts/index';
import AssociateTagUniversityForm from '../AssociateTagUniversityForm';
import FormLoading from 'views/Backoffice/academicInnovation/components/FormLoading';

const RegisterTagMainForm = ({
  selectedTag,
  isFromRegister,
  openRegisterAfterClose,
  itemCallBack,
}) => {
  const formRef = useRef(null);

  const [requirements, setRequirements] = useState([]);
  const [optionsRequirement, setOptionsRequirement] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [tagLength, setTagLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { setCurrentFormToShow } = useFormContext();

  const handleClickClose = () => {
    setCurrentFormToShow(null);
  };

  const handleTagChange = (e) => {
    const inputValue = e.target.value;
    setTagLength(inputValue.length);
    formRef.current.setErrors({});
  };

  const getOptions = async () => {
    const response = await academicInnovationApi.getOptionsRequirement();
    setOptionsRequirement(response);
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (selectedTag) {
      const getDetails = async () => {
        const {
          keywords,
          market_requirements,
          name,
        } = await academicInnovationApi.getDetailsPrincipalTag(selectedTag);

        const manipulatedKeywords = keywords.map((item) => item.value);

        formRef.current.setFieldValue(
          'market_requirements',
          market_requirements
        );
        formRef.current.setFieldValue('keywords', manipulatedKeywords);
        formRef.current.setFieldValue('name', name);
      };
      getDetails();
    }
  }, [selectedTag]);

  const controllerRequest = async (data) => {
    const selectedMarketRequirements = requirements.map(
      (requirement) => requirement.value
    );

    const dataToSend = {
      ...data,
      market_requirements: selectedMarketRequirements,
    };

    if (selectedTag) {
      await academicInnovationApi.putEditTag(selectedTag, dataToSend);
      if (openRegisterAfterClose) {
        setCurrentFormToShow(
          <AssociateTagUniversityForm
            selectedTag={selectedTag}
            item={itemCallBack}
          />
        );
        return;
      }
      handleClickClose();
      return;
    }

    const {
      data: responseData,
    } = await academicInnovationApi.postRegisterTagMain(dataToSend);
    const { id } = responseData;
    if (isFromRegister) {
      setCurrentFormToShow(
        <AssociateTagUniversityForm selectedTag={id} item={itemCallBack} />
      );
      return;
    }

    handleClickClose();
  };

  const handleSubmit = async (data) => {
    setIsLoading(true);
    try {
      await ValidationSchema.validate(data, {
        abortEarly: false,
      });
      controllerRequest(data);
      formRef.current.setErrors({});
    } catch (err) {
      setIsLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
      formRef.current.setErrors(validationErrors);
    }
  };

  return (
    <ContainerForm>
      <CloseButton onClick={() => handleClickClose()} />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title>{selectedTag ? 'Editar ' : 'Cadastrar '}TAG principal</Title>
        <Subtitle>
          {selectedTag ? 'Edite ' : 'Cadastre '} aqui uma TAG principal.
        </Subtitle>
        <Input
          type="text"
          name="name"
          label="Nome da TAG*"
          placeholder="Digite aqui..."
          onChange={(e) => {
            e.target.value = e.target.value.toUpperCase();
            handleTagChange(e);
          }}
          maxLength={50}
        />
        <TagLength>Caracteres restantes: {50 - tagLength}</TagLength>
        <InputMultiText
          type="text"
          name="keywords"
          label="Palavras-chave*"
          placeholder="Digite aqui..."
          list={keywords}
          setList={setKeywords}
          maxLength={50}
        />

        <MultiSelect
          selectedOptions={requirements}
          setSelectedOptions={setRequirements}
          name="market_requirements"
          options={optionsRequirement}
          label="Requisito/tendência de mercado*"
          placeholder={'Selecione'}
          isMulti
        />
        <ContainerButton>
          <button type="submit">
            {isLoading ? <FormLoading /> : 'Salvar'}
          </button>
        </ContainerButton>
      </Form>
    </ContainerForm>
  );
};

export default RegisterTagMainForm;
