import React, { useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';

import {
  Input,
  ContainerLabel,
  Label,
  ButtonEye,
  ErrorMessage,
} from '../styles/InputStyles';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export default function InputPasswordLogin({
  label,
  name,
  universityColor,
  type,
  value,
  buttonShowPassword,
  errorMessage,
  ...rest
}) {
  const inputRef = useRef(null);

  const { fieldName, registerField, defaultValue, clearError } = useField(name);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, newValue) => {
        inputRef.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ContainerLabel>
      {label && (
        <Label universityColor={universityColor} htmlFor={name}>
          {label || name}
        </Label>
      )}
      <Input
        ref={inputRef}
        type={showPassword ? 'text' : 'password'}
        defaultValue={defaultValue}
        onFocus={clearError}
        valid={defaultValue !== ''}
        name={name}
        data-testid="input-password"
        {...rest}
      />
      {buttonShowPassword && (
        <ButtonEye
          type="button"
          errorMessage={errorMessage}
          onClick={() => handleShowPassword()}
        >
          {showPassword ? (
            <AiOutlineEye size={20} />
          ) : (
            <AiOutlineEyeInvisible size={20} />
          )}
        </ButtonEye>
      )}

      {errorMessage && <ErrorMessage> {errorMessage}</ErrorMessage>}
    </ContainerLabel>
  );
}
