import {
  ButtonAddEvidencies,
  ButtonFinish,
  Circle,
  ContainerButtons,
  ContainerCard,
  Content,
  DataContent,
  Image,
  Like,
  MissingTime,
  Title,
  VacanciesFilled,
  ViewStudents,
} from './styled';
import { BiLike, BiTimeFive } from 'react-icons/bi';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { BsCalendar4Week } from 'react-icons/bs';
import moment from 'moment';
import { HiUser } from 'react-icons/hi';
import GraduationCap from 'assets/ExtensionProject/GraduationCap.svg';
import Loading from '../../../Loading';
import { useContext, useState } from 'react';

import {
  getDetailsExtensionProject,
  getEvidences,
} from '../../../../../../services';
import { ExtensionProjectsContext } from '../../../../../../Context/ExtensionProjectsContext';
import { DetailsButton, Divider } from '../styled';
import Tooltip from 'components/NewTooltip';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';
import ModalShowEvidence from 'components/ModalShowEvidence';

export function ToFinishCard({
  ref,
  item,
  waitingForStart,
  setDetails,
  setIsOpen,
  setStatus,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingEvidences, setLoadingEvidences] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const {
    setModalEnrolledStudents,
    setExtensionProjectId,
    setModalFinishProject,
    setUsingFormUpload,
    setIdPostEvidence,
    setEvidenceFiles,
    setEvidenceLinks,
    informationItem,
    setInformationItem,
    idProjectBeeingProcessed,
    setIdProjectBeeingProcessed,
  } = useContext(ExtensionProjectsContext);

  const [openShowModalEvidence, setOpenShowModalEvidence] = useState(false);
  const [configModalShowEvidence, setConfigModalShowEvidence] = useState({
    id: item.extension_project.id,
    useTab: false,
    seeAllEvidencesStudent: true,
  });

  const handleClickSeeAllEvidences = () => {
    setOpenShowModalEvidence(true);
    setConfigModalShowEvidence({
      id: item.extension_project.id,
      useTab: true,
      seeAllEvidencesStudent: false,
    });
  };

  const handleMoreInfo = async (item) => {
    setExtensionProjectId(item.extension_project.id);
    setLoadingDetails(true);
    const data = await getDetailsExtensionProject(item.id);
    setDetails(data);
    setIsOpen(true);
    setLoadingDetails(false);
  };

  const handleClickSeeEvidence = () => {
    setConfigModalShowEvidence({
      id: item.extension_project.id,
      useTab: false,
      seeAllEvidencesStudent: true,
    });
    setOpenShowModalEvidence(true);
  };

  const handleEnrolledStudents = (item) => {
    setModalEnrolledStudents(true);
    setExtensionProjectId(item.extension_project.id);
    setStatus(item.is_published);
  };

  function handleFinishProject() {
    setExtensionProjectId(item.extension_project.id);
    setModalFinishProject(true);
    setInformationItem(item);
  }

  const handleOpenEvidence = async () => {
    const { extension_project } = item;
    const { id } = extension_project;
    setLoadingEvidences(true);
    const { results } = await getEvidences(id);

    const files = results.filter(
      (item) => item.file !== null || item.image !== null
    );
    const links = results.filter(
      (item) => item.name !== '' && item.link !== ''
    );

    setEvidenceLinks(links);
    setEvidenceFiles(files);
    setUsingFormUpload(true);
    setLoadingEvidences(false);

    setIdProjectBeeingProcessed(id);

    setIdPostEvidence(id);
  };

  const strReduced = (str) => {
    if (str.length > 20) {
      return `${str.substr(0, 20)}...`;
    }
    return str;
  };

  const labelUploadEvidences =
    item.extension_project.evidences_quantity > 0
      ? `Adicionar evidências (${item.extension_project.evidences_quantity})`
      : 'Adicionar evidências';

  const interfaceMenuButton = [
    { name: `${labelUploadEvidences}`, onChange: handleOpenEvidence },
    ...(item.extension_project.enable_students_evidences
      ? [{ name: 'Analisar evidências', onChange: handleClickSeeEvidence }]
      : []),
    {
      name: 'Visualizar todas as evidências',
      onChange: handleClickSeeAllEvidences,
    },
  ];

  return (
    <ContainerCard ref={ref}>
      {ModalShowEvidence && (
        <ModalShowEvidence
          open={openShowModalEvidence}
          setOpen={setOpenShowModalEvidence}
          {...configModalShowEvidence}
        />
      )}
      {item.attraction_image.logo ? (
        <Image>
          <img src={item.attraction_image.image} />
        </Image>
      ) : (
        <Image>
          <img
            src={item.attraction_image.image}
            style={{
              width: '340px',
              height: '210px',
              borderTopLeftRadius: '11.5652px',
              borderTopRightRadius: '11.5652px',
              position: 'absolute',
              top: 0,
            }}
          />
        </Image>
      )}

      <Like>
        <BiLike size={17} />
        <p>{item.like_quantity}</p>
      </Like>
      <VacanciesFilled>
        <p>{item.extension_project.reminder}</p>
      </VacanciesFilled>
      {waitingForStart && (
        <MissingTime border={item.attraction_image.logo}>
          <BiTimeFive size={17} />
          <p>{item.extension_project.reminder}</p>
        </MissingTime>
      )}
      <DataContent>
        <Title title={item.name}>{item.name}</Title>
        <Content>
          <div className="wrapper-content">
            <IoExtensionPuzzleOutline size={20} color="#009291" />
            <p style={{ paddingLeft: '7px' }}>{item.extension_project.type}</p>
          </div>
          <div className="wrapper-content">
            <BsCalendar4Week size={17} color="#009291" />
            <p>
              De {''}
              {moment(item.start_at).format('DD/MM/YYYY')} {''}a {''}
              {moment(item.end_at).format('DD/MM/YYYY')} {''}
            </p>
          </div>
          <div className="wrapper-content">
            <Circle>
              <HiUser size={16} color="#ffffff" />
            </Circle>
            {item.extension_project.creator &&
            item.extension_project.creator.length <= 20 ? (
              <p>{item.extension_project.creator}</p>
            ) : (
              <Tooltip text={item.extension_project.creator}>
                <p>{strReduced(item.extension_project.creator)}</p>
              </Tooltip>
            )}
          </div>
          <div className="wrapper-content">
            <img src={GraduationCap} />
            <p>
              {(item.extension_project.students_registered > 1 ||
                item.extension_project.students_registered === 0) &&
                `${item.extension_project.students_registered} estudantes inscritos`}
              {item.extension_project.students_registered === 1 &&
                `${item.extension_project.students_registered} estudante inscrito`}
              {item.extension_project.students_registered > 0 && (
                <ViewStudents onClick={() => handleEnrolledStudents(item)}>
                  {' '}
                  ver{' '}
                </ViewStudents>
              )}
              {item.extension_project.students_registered === 0 &&
                item.extension_project.has_register === true && (
                  <ViewStudents onClick={() => handleEnrolledStudents(item)}>
                    {' '}
                    ver{' '}
                  </ViewStudents>
                )}
            </p>
          </div>
        </Content>
        <ContainerButtons>
          {item.is_published && (
            <ButtonFinish
              disabled={
                (item.extension_project.evidences_quantity === 0 &&
                  item.extension_project.students_evidences === 0) ||
                idProjectBeeingProcessed === item.extension_project.id
              }
              onClick={handleFinishProject}
            >
              Finalizar projeto
              <div className="alert-dialog">
                <span>
                  Para finalizar o projeto,
                  <br />
                  adicione as evidências.
                </span>
              </div>
            </ButtonFinish>
          )}
          <ButtonWithMenu
            insideMenus={interfaceMenuButton}
            menuLabel="Evidências"
            offsetX={-92}
          />
        </ContainerButtons>
        <Divider />
        <DetailsButton onClick={() => handleMoreInfo(item)}>
          {loadingDetails ? (
            <Loading color={'#009291'} />
          ) : (
            'VISUALIZAR DETALHES'
          )}
        </DetailsButton>
      </DataContent>
    </ContainerCard>
  );
}
