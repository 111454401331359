import React, { useRef } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  WrapperInformations,
  WrapperForm,
  SubmitButton,
  WrapperButton,
} from './styled';
import * as Yup from 'yup';
import { schema } from './schema';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';
import { useNextMentorshipContext } from '../../context';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import GenericModal from 'views/StaffPages/Mentorship/components/GenericModal';

export function CancelModalContent({
  item,
  title,
  onClose,
  isOpen,
  fetchMentorships,
}) {
  const formRef = useRef();
  const { closeDetailsModal, setPage } = useNextMentorshipContext();

  const handleClose = () => {
    formRef.current.reset();
    onClose();
  };

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });
      await mentorshipApi.cancelMentorship(item.id, data.cancelReason);
      handleClose();
      customSnackbar('Mentoria cancelada!', 'confirmation');
      setPage(1);
      fetchMentorships(true);
      closeDetailsModal();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errorMessages = {};
        err.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={handleClose} title={title}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <WrapperInformations>
            Explique abaixo o motivo do cancelamento da mentoria:
          </WrapperInformations>
          <WrapperForm>
            <DescriptionInput
              name="cancelReason"
              placeholder="Digite aqui..."
            />
          </WrapperForm>
          <WrapperButton>
            <SubmitButton type="submit">Enviar</SubmitButton>
          </WrapperButton>
        </Container>
      </Form>
    </GenericModal>
  );
}
