import React, { useRef } from 'react';
import { Container, WrapperForm, WrapperButton, SubmitButton } from './styled';
import { Form } from '@unform/web';
import { schema } from './schema';
import { useHistoricMentorshipContext } from '../../context';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import GenericModal from 'views/StaffPages/Mentorship/components/GenericModal';

const MentoringNotDoneModal = ({
  item,
  title,
  onClose,
  isOpen,
  fetchMentorships,
}) => {
  const formRef = useRef();
  const { submitMentoringFeedback, setPage } = useHistoricMentorshipContext();

  async function handleSubmit(data) {
    try {
      await schema.validate(data, { abortEarly: false });
      const feedbackData = {
        feedback: '',
        not_realize_reason: data.not_realize_reason,
      };
      const success = await submitMentoringFeedback(item.id, feedbackData);
      if (success) {
        setPage(1);
        fetchMentorships(true);
        onClose();
        customSnackbar('Feedback enviado com sucesso!', 'confirmation');
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <WrapperForm>
            <p>Explique abaixo o motivo da mentoria não ter sido realizada</p>
            <DescriptionInput
              name="not_realize_reason"
              placeholder="Digite aqui..."
            />
          </WrapperForm>
          <WrapperButton>
            <SubmitButton type="submit">Enviar</SubmitButton>
          </WrapperButton>
        </Container>
      </Form>
    </GenericModal>
  );
};

export default MentoringNotDoneModal;
