export function IconHouse({ color }) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33919 11.3349H2.67253V12.6682H0.00585938V5.89485C0.00585938 5.27485 0.285859 4.70152 0.772526 4.32152L4.77253 1.18818C5.49919 0.621517 6.51253 0.621517 7.23919 1.18818L9.33919 2.83485V1.33485H10.6725V3.88152L11.2392 4.32152C11.7259 4.70152 12.0059 5.27485 12.0059 5.89485V6.00152H10.6725V5.89485C10.6725 5.68818 10.5792 5.49485 10.4192 5.36818L6.41919 2.24152C6.17919 2.04818 5.83919 2.05485 5.59919 2.24152L1.59253 5.37485C1.43253 5.50152 1.33919 5.69485 1.33919 5.90152V11.3415V11.3349ZM16.0059 12.6682V14.6682C16.0059 15.7682 15.1059 16.6682 14.0059 16.6682H6.00586C4.90586 16.6682 4.00586 15.7682 4.00586 14.6682V12.6682H4.67253V9.33485C4.67253 8.23485 5.57253 7.33485 6.67253 7.33485H13.3392C14.4392 7.33485 15.3392 8.23485 15.3392 9.33485V12.6682H16.0059ZM6.00586 12.6682H8.27919L8.94586 13.3349H11.0592L11.7259 12.6682H13.9992V9.33485C13.9992 8.96818 13.6992 8.66818 13.3325 8.66818H6.67253C6.30586 8.66818 6.00586 8.96818 6.00586 9.33485V12.6682ZM14.6725 14.0015H12.2792L11.6125 14.6682H8.39253L7.72586 14.0015H5.33253V14.6682C5.33253 15.0349 5.63253 15.3349 5.99919 15.3349H13.9992C14.3659 15.3349 14.6659 15.0349 14.6659 14.6682V14.0015H14.6725Z"
        fill={color}
      />
    </svg>
  );
}
