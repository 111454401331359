import React, { createContext, useContext, useEffect, useState } from 'react';
import { mentorAdapter } from 'views/StaffPages/Mentorship/screens/MentorsTab/adapters/MentorAdapter';
import { adapterSpecificDates } from 'views/StaffPages/Mentorship/screens/MentorsTab/adapters/SpecificDatesAdapter';
import { mentorshipApi } from '../services/api';

export const MentorshipContext = createContext({});

export function MentorshipContextProvider({ children }) {
  const [loadingSelectedMentor, setLoadingSelectedMentor] = useState(null);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [mentorModalIsOpen, setMentorModalIsOpen] = useState(false);
  const [mentorshipCounts, setMentorshipCounts] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [activeInternalTabHistoric, setActiveInternalTabHistoric] = useState(0);

  const fetchMentorshipsCount = async () => {
    const response = await mentorshipApi.getMentorshipsCount();
    setMentorshipCounts(response);
  };

  useEffect(() => {
    fetchMentorshipsCount();
  }, []);

  const updateMentorshipsCount = (tab, value) => {
    setMentorshipCounts((prevCounts) => ({ ...prevCounts, [tab]: value }));
  };

  const handleOpenMentorModal = async (mentor) => {
    setLoadingSelectedMentor(mentor.mentor_id);
    const [
      mentorDetailsResponse,
      specificDatesResponse,
      absencesResponse,
    ] = await Promise.all([
      mentorshipApi.getMentorDetails(mentor.mentor_id),
      mentorshipApi.getMentorSpecificDates(mentor.mentor_id),
      mentorshipApi.getMentorAbsences(mentor.mentor_id),
    ]);
    const newSelectedMentor = mentorAdapter(mentorDetailsResponse);
    newSelectedMentor.specific_dates = adapterSpecificDates(
      specificDatesResponse
    );
    newSelectedMentor.absences = absencesResponse;
    setLoadingSelectedMentor(null);
    setSelectedMentor(newSelectedMentor);
    setMentorModalIsOpen(true);
  };

  const handleCloseMentorModal = () => {
    setMentorModalIsOpen(false);
    setSelectedMentor(null);
  };

  return (
    <MentorshipContext.Provider
      value={{
        loadingSelectedMentor,
        setLoadingSelectedMentor,
        selectedMentor,
        setSelectedMentor,
        mentorModalIsOpen,
        setMentorModalIsOpen,
        handleOpenMentorModal,
        handleCloseMentorModal,
        mentorshipCounts,
        setMentorshipCounts,
        updateMentorshipsCount,
        fetchMentorshipsCount,
        activeTab,
        setActiveTab,
        activeInternalTabHistoric,
        setActiveInternalTabHistoric,
      }}
    >
      {children}
    </MentorshipContext.Provider>
  );
}
export const useMentorship = () => useContext(MentorshipContext);
