import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: ${(props) => (props.usingPreviewPdf ? '0' : '2000')};

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.25);

  color: #606062;
`;
export const FileButton = styled.button`
  width: 100%;
  max-width: 264px;

  height: 33px;

  border: 1px solid #009291;

  border-radius: 6px;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #009291;
  font-weight: 400;
  flex: none;

  transition: 0.2s;

  cursor: pointer;

  margin-top: ${(props) => (props.expandPanel ? '0px' : '20px')};

  svg {
    margin-right: 7px;
    flex: none;
  }

  :hover {
    background-color: #009291;
    color: #fff;
  }

  :hover svg {
    fill: #fff;
  }
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-height: 724px) {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 605px;
  border-radius: 10px;

  background-color: #fff;

  display: flex;
  align-items: center;

  flex-direction: column;

  position: relative;

  padding-bottom: ${(props) => (props.paddingBottom ? '0' : '65px')};
`;

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin-top: 31px;

  gap: 6px;

  position: relative;

  strong {
    font-size: 20px;
    text-align: center;
  }

  .company-logo {
    width: 72px;
    height: 72px;
    border-radius: 200px;
    background-color: #f5f5f5;
    border: 1px solid #e5e5e5;
    margin-bottom: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .company-logo img {
    width: 72px;
    height: 72px;
    object-fit: cover;
    border-radius: 200px;
  }

  .wrapper-text {
    display: flex;
    gap: 7px;
    font-weight: 400;
  }

  .wrapper-text span {
    font-size: 15px;
    font-weight: 700;
  }
`;

export const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  margin-top: 30px;

  gap: 20px;

  .adress {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 7px;

    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  .adress svg {
    margin-bottom: 2px;
    flex: none;
  }

  .adress p {
    margin: 0;
    text-transform: capitalize;
  }

  span {
    max-width: 480px;

    font-size: 13px;
    font-weight: 400;

    text-align: center;
  }
`;

export const HighlightsArea = styled.div`
  width: 100%;
  max-width: 480px;

  padding: 16px;

  border-radius: 4px;
  background-color: #f8f8f8;

  margin-top: 15px;
`;

export const TitleArea = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 7px;

  .icon {
    width: 20px;
    height: 20px;
    border-radius: 3px;

    background-color: #009291;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 14px;
    color: #009291;
    font-weight: 400;
  }
`;

export const ContentArea = styled.div`
  width: 100%;
  margin-top: 12px;

  span {
    font-size: 12px;
    line-height: 15px;
  }

  .balloons-box {
    display: flex;
    align-items: center;
    gap: 8px;

    flex-wrap: wrap;

    text-transform: capitalize;
  }
`;

export const ListingBalloons = styled.div`
  width: fit-content;

  padding: 6px;
  height: 24px;

  background-color: #ffff;
  border: 0.5px solid #009291;

  border-radius: 15px;

  font-size: 13px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ViewInformation = styled.button`
  width: 100%;
  max-width: fit-content;
  height: 29px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  border: none;

  cursor: pointer;

  font-size: 12px;
  font-weight: 700;
  color: #ffffff;

  background-color: #009291;

  padding: 10px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin: 21px 0;
`;

export const ButtonX = styled.button`
  width: 28px;
  height: 28px;

  border-radius: 200px;
  border: none;

  background-color: #efeeec;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;
`;
