/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';

import { Star } from '@material-ui/icons';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { updateFavoritePayload } from 'adapters/student/professionsAdapter';
import backgroundMyCareer from 'assets/img/foto_moca_auto_a.png';

import { triggerHotjarEvent } from 'utils/hotjar';

import ViewsHeader from 'components/ViewsHeader/ViewsHeader.jsx';
import { Mixpanel } from 'utils/mixpanel';
import {
  getProfessions,
  updateFavoriteProfessions,
} from '../../services/student/professions';
import CardComposition from '../../components/CardComposition/CardComposition';
import ProfessionsList from './components/Professions/ProfessionsList';
import FavoritesDialog from './components/Professions/FavoritesDialog';
import { CardContainer } from './components/Professions/styles';
import { triggerPageView } from '../../utils/analytics';
import ModalInformation from 'components/informationModal';

class MyCareer extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    recommendProfessions: [],
    secondaryProfessions: [],
    favoriteProfessions: [],
    transversalProfessions: [],
    activeTabContent: 0,
    dialogIsOpen: false,
    modalInformationIsOpen: false,
    configModalInformation: {
      bubbleText: `Olá, ${this.props.academic_profile.name}`,
      buttonConfirmText: 'OK, ENTENDI',
      bubble: {
        style: {
          fontWeight: 400,
        },
      },
      ParagraphText:
        'Seu match é calculado com base no seu perfil! Confira abaixo os requisitos que podem aumentar seu potencial de empregabilidade:',
      informationList: [
        {
          text: 'Experiências de vida e carreira',
        },
        {
          text: 'Idiomas',
        },
        {
          text: 'Formações e capacitações',
        },
        {
          text: 'Portfólio de projetos',
        },
        {
          text: 'Competências',
        },
        {
          text: 'Ao acrescentar novas disciplinas',
        },
        {
          text: 'Ao remover disciplinas',
        },
      ],
    },
  };

  async UNSAFE_componentWillMount() {
    this.setState({ loading: true });
    const professions = await getProfessions();

    const favorites = this.fetchFavorites(professions.favorites, [
      ...professions.secondary,
      ...professions.recommended,
    ]);

    const transversal = professions.secondary.filter(
      (obj) => obj.profession.transversal_profession === true
    );

    const secondary = professions.secondary.filter(
      (obj) => obj.profession.transversal_profession !== true
    );

    this.setState({
      loading: false,
      recommendProfessions: professions.recommended,
      secondaryProfessions: secondary,
      favoriteProfessions: favorites,
      transversalProfessions: transversal,
    });
    if (this.props.metadata.university_slug !== 'uniexemplo') {
      triggerHotjarEvent('my_career');
    }
  }

  componentDidMount() {
    const { handleIsLoadingState } = this.props;
    handleIsLoadingState(false);
    Mixpanel.track('Acessou menu: Mercado');
  }

  fetchFavorites = (favoritesObjectList, secondaryProfessionsList) => {
    const newList = secondaryProfessionsList.filter((item) =>
      favoritesObjectList.find(
        (favoriteId) => item.profession && favoriteId === item.profession.id
      )
    );
    return newList;
  };

  handleDialog = (value) => {
    this.setState({ dialogIsOpen: value });
  };

  favoriteHandler = async (value) => {
    const { favoriteProfessions } = this.state;

    if (favoriteProfessions.find((item) => item === value)) {
      const filteredFavorites = favoriteProfessions.filter(
        (item) => item !== value
      );
      this.setState({ favoriteProfessions: filteredFavorites });
      await updateFavoriteProfessions(updateFavoritePayload(filteredFavorites));
      this.handleDialog(false);
    } else if (favoriteProfessions.length === 3) {
      this.handleDialog(true);
    } else {
      this.setState({ favoriteProfessions: [...favoriteProfessions, value] });
      await updateFavoriteProfessions(
        updateFavoritePayload([...favoriteProfessions, value])
      );
      triggerPageView('student/mercado/atuacao/favoritar');
    }
  };

  handleTabChange = (event, value) => {
    this.setState({ activeTabContent: value });
  };

  render() {
    const {
      classes,
      openModalWithContent,
      closeModal,
      openTalkModal,
      academic_profile,
      universityColor,
    } = this.props;
    const propsCopy = { ...this.props };
    const {
      recommendProfessions,
      loading,
      secondaryProfessions,
      favoriteProfessions,
      transversalProfessions,
      dialogIsOpen,
    } = this.state;
    delete propsCopy.classes;
    return (
      <div>
        <ViewsHeader
          title="Possibilidades de atuações profissionais"
          backgroundImage={backgroundMyCareer}
        />
        <GridContainer style={{ maxWidth: '1440px', margin: 'auto' }}>
          <div>
            <ModalInformation
              universityColor={universityColor}
              config={this.state.configModalInformation}
              modalOpenState={this.state.modalInformationIsOpen}
              buttonConfirmFunction={() =>
                this.setState({ modalInformationIsOpen: false })
              }
            />
            <Grid item xs={12} md={12}>
              <h4 className={classes.titleDefault}>
                Confira abaixo o match do seu perfil com as principais atuações
                profissionais relacionadas ao seu curso. Para aumentar o seu
                potencial de trabalhabilidade nessas atuações,{' '}
                <span
                  style={{
                    color: universityColor || '#009291',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    fontWeight: 400,
                  }}
                  onClick={() =>
                    this.setState({ modalInformationIsOpen: true })
                  }
                >
                  clique aqui
                </span>
                .
              </h4>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <CardContainer>
                <CardComposition
                  classes={classes}
                  cardTitleText="Minhas atuações favoritas"
                  emptyStateText="Você ainda não Selecionou atuações favoritas"
                  CardContent={() => (
                    <Fragment>
                      <p style={{ fontSize: '1.2rem' }}>
                        Favorite até 3 atuações (marcando{' '}
                        <Star className={classes.starIconLabel} />) que mais te
                        interessam para indicarmos as oportunidades de
                        aprendizagem que podem acelerar sua carreira.
                      </p>
                      <ProfessionsList
                        classes={classes}
                        areasOfExpertiseState={favoriteProfessions}
                        openModalWithContentFn={openModalWithContent}
                        closeModalFn={closeModal}
                        loading={loading}
                        favoriteHandler={this.favoriteHandler}
                        favoriteProfessions={favoriteProfessions}
                      />
                    </Fragment>
                  )}
                />
              </CardContainer>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <CardContainer>
                <CardComposition
                  classes={classes}
                  cardTitleText="Atuações recomendadas para meu perfil"
                  CardContent={() => (
                    <Fragment>
                      <p style={{ fontSize: '1.2rem' }}>
                        Apresentamos aqui as atuações mais alinhadas ao seu
                        perfil, considerando suas informações acadêmicas,
                        profissionais e comportamentais. Quanto mais atualizadas
                        estiverem as informações no seu Currículo do Futuro,
                        maior será o seu percentual de aderência.
                      </p>
                      <ProfessionsList
                        classes={classes}
                        areasOfExpertiseState={recommendProfessions}
                        openModalWithContentFn={openModalWithContent}
                        closeModalFn={closeModal}
                        loading={loading}
                        favoriteHandler={this.favoriteHandler}
                        favoriteProfessions={favoriteProfessions}
                      />
                    </Fragment>
                  )}
                  emptyStateText="Você ainda não realizou seu teste comportamental"
                />
              </CardContainer>
            </Grid>
            {secondaryProfessions.length > 0 && (
              <Grid item xs md={12} sm={12}>
                <CardContainer>
                  <CardComposition
                    classes={{
                      card: classes.cardMarginBottom,
                      ...classes,
                    }}
                    cardTitleText="Outras atuações alinhadas ao meu perfil"
                    CardContent={() => (
                      <Fragment>
                        <p style={{ fontSize: '1.2rem' }}>
                          Considere 70% um parâmetro suficiente para você se
                          candidatar às vagas relacionadas à essa atuação. Não é
                          necessário ter 100% de aderência às atuações para se
                          candidatar às vagas.
                        </p>
                        <ProfessionsList
                          classes={classes}
                          areasOfExpertiseState={secondaryProfessions}
                          openModalWithContentFn={openModalWithContent}
                          closeModalFn={closeModal}
                          loading={loading}
                          favoriteHandler={this.favoriteHandler}
                          favoriteProfessions={favoriteProfessions}
                        />
                      </Fragment>
                    )}
                    emptyStateText="Você ainda não realizou seu teste comportamental"
                  />
                </CardContainer>
              </Grid>
            )}
            {transversalProfessions.length > 0 && (
              <Grid item xs md={12} sm={12}>
                <CardContainer>
                  <CardComposition
                    classes={{
                      card: classes.cardMarginBottom,
                      ...classes,
                    }}
                    cardTitleText="Fonte de Renda Extra"
                    CardContent={() => (
                      <Fragment>
                        <p style={{ fontSize: '1.2rem' }}>
                          Aqui estão outras atuações profissionais que não
                          exigem pré-requisitos acadêmicos e podem ser boas
                          oportunidades para você ter suas primeiras
                          experiências profissionais e conhecer mais da dinâmica
                          do mercado de trabalho.
                        </p>

                        <ProfessionsList
                          classes={classes}
                          areasOfExpertiseState={transversalProfessions}
                          openModalWithContentFn={openModalWithContent}
                          closeModalFn={closeModal}
                          loading={loading}
                          favoriteHandler={this.favoriteHandler}
                          favoriteProfessions={favoriteProfessions}
                        />
                      </Fragment>
                    )}
                    emptyStateText="Você ainda não realizou seu teste comportamental"
                  />
                </CardContainer>
              </Grid>
            )}
          </div>
        </GridContainer>
        <FavoritesDialog
          dialogIsOpen={dialogIsOpen}
          handleDialog={this.handleDialog}
        />
      </div>
    );
  }
}

MyCareer.propTypes = {
  classes: PropTypes.shape.isRequired,
  openModalWithContent: PropTypes.func.isRequired,
  handleIsLoadingState: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  openSnackbar: PropTypes.func.isRequired,
  openTalkModal: PropTypes.func.isRequired,
  academic_profile: PropTypes.shape.isRequired,
};

export default withStyles(dashboardStyle)(MyCareer);
