import React, { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { selectPagination } from './styles';

function SelectLimit({ classes, setLimit, pageSize, onChange, ordering }) {
  const selectPagination = (e) => {
    if (e.target.value) {
      setLimit(e.target.value);
    }
    onChange && onChange({ limitPerPage: e.target.value, offset: 0, ordering });
  };

  return (
    <FormControl
      variant="outlined"
      classes={{
        root: classes.formControl,
      }}
    >
      <InputLabel
        htmlFor="outlined-age-native-simple"
        className={classes.selectLabel}
        classes={{
          root: classes.cssLabel,
        }}
      >
        Itens por página
      </InputLabel>

      <Select
        value={pageSize}
        fullWidth
        className={classes.selectInput}
        onChange={selectPagination}
        input={
          <OutlinedInput name="pageSelect" labelWidth={120} id="page-select" />
        }
      >
        {[20, 50, 100].map((value) => (
          <MenuItem value={value}>{value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withStyles(selectPagination)(SelectLimit);
