import iconLike from 'assets/img/icons/iconLike.svg';
import iconBadge from 'assets/img/icons/iconBadge.svg';
import iconLaptop from 'assets/img/icons/iconLaptop.svg';
import iconBullseyeArrow from 'assets/img/icons/iconBullseyeArrow.svg';
import IconGraduationCap from 'assets/img/icons/IconGraduationCap.svg';
import IconExpProfissional from 'assets/img/icons/iconExpProfissional.svg';
import iconCommentAltMinus from 'assets/img/icons/iconCommentAltMinus.svg';
import IconGender from 'assets/img/icons/IconGender.svg';
import IconDeficiency from 'assets/img/icons/IconDeficiency.svg';
import IconEthnicity from 'assets/img/icons/IconEthnicity.svg';
//add o icone do turno
export const icons = {
  iconExpProfissional: <img src={IconExpProfissional} />,
  iconLaptop: <img src={iconLaptop} />,
  IconGraduationCap: <img src={IconGraduationCap} />,
  iconLike: <img src={iconLike} />,
  iconBadge: <img src={iconBadge} />,
  iconBullseyeArrow: <img src={iconBullseyeArrow} />,
  iconCommentAltMinus: <img src={iconCommentAltMinus} />,
  iconGender: <img src={IconGender} />,
  iconDeficiency: <img src={IconDeficiency} />,
  iconEthnicity: <img src={IconEthnicity} />,
};
