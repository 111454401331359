import styled from 'styled-components';

export const Fragment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 90px;
`;
export const TitleParagraphChallenges = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 30px;
  margin-top: -30px;
`;
export const TitleChallenges = styled.h1`
  color: #4b4b4d;
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 30px;
`;
export const ParagraphChallenges = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #606062;
`;
export const CardContainer = styled.div`
  height: auto;
  margin-bottom: 40px;
`;
export const CardBody = styled.div`
  background: #ffffff40;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  border-top: 1px solid #4b4b4d;
  border-left: 1px solid #4b4b4d;
  border-right: 1px solid #4b4b4d;

  height: 215px;
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
`;
export const CardImage = styled.img`
  width: 62px;
  height: 62px;
  margin-top: 10px;
`;
export const CardTitle = styled.h3`
  color: #4b4b4d;

  font-size: 20px;
  font-weight: 500;
`;
export const CardParagraph = styled.p`
  color: #606062;

  font-weight: 400;
  font-size: 12px;

  text-align: center;
  padding: 0 20px;
`;
export const DivCardButton = styled.div`
  display: flex;
  width: 100%;
`;
export const CardButton = styled.button`
  border: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  color: #fff;
  background-color: #009291;
  width: 100%;
  height: 34px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const CardParagraphButton = styled.h4`
  padding: 0;
  font-weight: 550;
  font-size: 14px;
`;
export const SubtitleCard = styled.button`
  font-size: 18px;
  color: #0f7776;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
