import React, { useContext } from 'react';
import { DropArea, Container } from './style';

import BaseLayoutContext from 'contexts/base-layout';
import { getFileExtension } from '../../utils/getFileExtesion';
import { removeExtension } from '../../utils/removeFileExtesion';
import { generateUUID } from '../../utils/generateUUID';

import { BsUpload } from 'react-icons/bs';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function FileComponent({
  acceptFileTypes = ['PNG', 'JPG'],
  functionPostFile,
  idPost,
  setFilesList,
  setModalInformationOpen,
  setModalInformationConfig,
  uniqueUploadType,
  arrayFiles,
  limitUpload,
  companiesManagement,
  postDocumentTwoCreateFile,
  functionResetList,
  stateResetList,
  limit,
  currentPage,
  isFromSettingsLogo,
  itemWaitingDelete,
  functionDeleteEvidence,
  nameTypeDoc,
  limitSizeDocument,
  messageLimitSize,
}) {
  const { openSnackbar, universityColor } = useContext(BaseLayoutContext);

  const typesToForm = {
    PDF: 'application/pdf',
    PNG: 'image/png',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
    ZIP: 'application/zip',
    RAR: 'application/vnd.rar',
  };

  const validateDocumentType = {
    'application/pdf': 'PDF',
    'image/png': 'PNG',
    'image/jpg': 'JPG',
    'image/jpeg': 'JPEG',
    'application/zip': 'ZIP',
    'application/vnd.rar': 'RAR',
  };

  const handleConfirmError = () => {
    setModalInformationOpen(false);
  };

  const configTypeError = {
    bubbleText: 'Oops...',
    ParagraphText: 'Desculpe, somente arquivos do tipo PDF são permitidos.',
    buttonConfirmText: 'OK, ENTENDI!',
    modalHeight: 300,
    buttonConfirmFunction: handleConfirmError,
    style: {
      textAlign: 'center',
      fontWeight: '500',
      marginTop: '37px',
      fontSize: '17px',
    },
  };

  const configSizeError = {
    bubbleText: 'Oops...',
    ParagraphText: 'O tamanho máximo de arquivos é de 20mb.',
    buttonConfirmText: 'OK, ENTENDI!',
    modalHeight: 300,
    buttonConfirmFunction: handleConfirmError,
  };

  const configSizeCompaniesManagementError = {
    bubbleText: 'Oops...',
    ParagraphText: 'O tamanho máximo de arquivos é de 10mb.',
    buttonConfirmText: 'OK, ENTENDI!',
    modalHeight: 300,
    buttonConfirmFunction: handleConfirmError,
  };
  const configSizeLogoError = {
    bubbleText: 'Oops...',
    ParagraphText: 'O tamanho máximo de arquivos é de 5mb.',
    buttonConfirmText: 'OK, ENTENDI!',
    modalHeight: 300,
    buttonConfirmFunction: handleConfirmError,
  };

  const configOversizedNameError = {
    bubbleText: 'Ooops...',
    ParagraphText: 'O nome do arquivo não pode superar 100 caracteres.',
    buttonConfirmText: 'OK, ENTENDI!',
    modalHeight: 300,
    buttonConfirmFunction: handleConfirmError,
    style: {
      textAlign: 'center',
      fontWeight: '500',
      marginTop: '37px',
      fontSize: '17px',
    },
  };

  const createAccept = acceptFileTypes.reduce((acc, value) => {
    const format = typesToForm[value];
    if (format) {
      acc === '' ? (acc += `${format}`) : (acc += `,${format}`);
    }
    return acc;
  }, '');

  const handleChange = async (event) => {
    const file = event.target.files[0];
    event.target.value = null;

    if (!acceptFileTypes.includes(validateDocumentType[file.type])) {
      customSnackbar(
        'Desculpe, este tipo de arquivo não é permitido.',
        'error'
      );
      return;
    }

    if (limitSizeDocument && file?.size > limitSizeDocument) {
      customSnackbar(`${messageLimitSize}`, 'error');
      return;
    }

    if (isFromSettingsLogo && file?.size > 5242880) {
      setModalInformationOpen(true);
      setModalInformationConfig(configSizeLogoError);
      return;
    }

    if (companiesManagement && file?.type !== 'application/pdf') {
      setModalInformationOpen(true);
      setModalInformationConfig(configTypeError);
      return;
    }

    if (companiesManagement === true && file?.size > 9971520) {
      setModalInformationOpen(true);
      setModalInformationConfig(configSizeCompaniesManagementError);
      return;
    }

    if (companiesManagement === false && file?.size > 20971520) {
      setModalInformationOpen(true);
      setModalInformationConfig(configSizeError);
      return;
    }

    if (file?.name.length > 100) {
      setModalInformationOpen(true);
      setModalInformationConfig(configOversizedNameError);
      return;
    }

    if (arrayFiles.length >= limitUpload) {
      openSnackbar('Você atingiu o limite de upload de documentos', true);
      return;
    }

    if (companiesManagement) {
      const isValid = arrayFiles.map((item) => item.id);

      if (isValid[0] === 2) {
        const data = await functionPostFile(idPost, file);
        setFilesList((prevs) => [...prevs, data?.documents[0]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
        return;
      }

      if (isValid[0] === 1) {
        const data = await postDocumentTwoCreateFile(idPost, file);
        setFilesList((prevs) => [...prevs, data?.documents[1]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
        return;
      }

      if (arrayFiles.length === 0) {
        const data = await functionPostFile(idPost, file);
        setFilesList((prevs) => [...prevs, data?.documents[0]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
      }

      if (arrayFiles.length === 1) {
        const data = await postDocumentTwoCreateFile(idPost, file);
        setFilesList((prevs) => [...prevs, data?.documents[1]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
      }

      return;
    }
    const { name } = file;
    const nameWithoutSuffix = removeExtension(name);
    const type = getFileExtension(name);
    const fileCreated = await functionPostFile(idPost, file, type);
    setFilesList((prevs) => [...prevs, fileCreated]);

    if (itemWaitingDelete.length > 0 && isFromSettingsLogo) {
      const item = itemWaitingDelete[0];

      functionDeleteEvidence(idPost, item.id);
    }
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const fileData = event.dataTransfer.items[0].getAsFile();
    const { name } = fileData;
    const nameWithoutSuffix = removeExtension(name);

    const type = getFileExtension(name);
    const fileType = fileData.type;

    if (limitSizeDocument && fileData?.size > limitSizeDocument) {
      customSnackbar(`${messageLimitSize}`, 'error');
      return;
    }

    if (!acceptFileTypes.includes(validateDocumentType[fileType])) {
      customSnackbar(
        'Desculpe, este tipo de arquivo não é permitido.',
        'error'
      );
      return;
    }

    if (companiesManagement && fileData?.type !== 'application/pdf') {
      setModalInformationOpen(true);
      setModalInformationConfig(configTypeError);
      return;
    }

    if (companiesManagement === true && fileData?.size > 9971520) {
      setModalInformationOpen(true);
      setModalInformationConfig(configSizeCompaniesManagementError);
      return;
    }

    if (companiesManagement === false && fileData?.size > 20971520) {
      setModalInformationOpen(true);
      setModalInformationConfig(configSizeError);
      return;
    }

    if (fileData?.name.length > 100) {
      setModalInformationOpen(true);
      setModalInformationConfig(configOversizedNameError);
      return;
    }

    if (arrayFiles.length >= limitUpload) {
      openSnackbar('Você atingiu o limite de upload de documentos', true);
      return;
    }

    if (companiesManagement) {
      const isValid = arrayFiles.map((item) => item.id);

      const listObject = {
        id: generateUUID(),
        name: nameWithoutSuffix,
        type: type,
        createdNow: true,
        file: fileData,
      };

      if (isValid[0] === 2) {
        const data = await functionPostFile(idPost, fileData);
        setFilesList((prevs) => [...prevs, data?.documents[0]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
        return;
      }

      if (isValid[0] === 1) {
        const data = await postDocumentTwoCreateFile(idPost, fileData);
        setFilesList((prevs) => [...prevs, data?.documents[1]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
        return;
      }

      if (arrayFiles.length === 0) {
        const data = await functionPostFile(idPost, fileData);
        setFilesList((prevs) => [...prevs, data?.documents[0]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
      }

      if (arrayFiles.length === 1) {
        const data = await postDocumentTwoCreateFile(idPost, fileData);
        setFilesList((prevs) => [...prevs, data?.documents[1]]);
        stateResetList(null);
        await functionResetList(
          `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
        );
      }

      return;
    }

    if (createAccept.includes(fileType)) {
      const fileCreated = await functionPostFile(idPost, fileData, type);
      setFilesList((prevs) => [...prevs, fileCreated]);
    }
  };

  const copyArrayAccept = [...acceptFileTypes];

  const acceptLength = copyArrayAccept.length;
  const addString =
    copyArrayAccept.length > 1
      ? copyArrayAccept.splice(acceptLength - 1, 0, 'e')
      : copyArrayAccept;
  const convertToStringArray = copyArrayAccept
    .join(', ')
    .replace(', e,', ' e ');

  const returnNameTypeDoc = () => {
    if (nameTypeDoc === false || nameTypeDoc === null) {
      return '';
    }

    if (nameTypeDoc) {
      return nameTypeDoc;
    }

    return 'Documento';
  };

  return (
    <Container>
      {!uniqueUploadType && (
        <p>
          Caso você queira subir mais de um formato, basta fazer o upload dos
          seus arquivos e depois alterar o formato de publicação.
        </p>
      )}
      <h5>{returnNameTypeDoc()}</h5>
      <DropArea
        universityColor={universityColor}
        disabled={arrayFiles.length >= limitUpload}
      >
        <input
          type="file"
          onDrop={handleDrop}
          onChange={handleChange}
          accept={createAccept}
        />

        <div>
          <div className="circle-area">
            <BsUpload
              color={arrayFiles.length >= limitUpload ? '#949494' : '#fff'}
              size={28}
            />
          </div>
          <p>
            <strong>Clique aqui</strong> para selecionar um arquivo ou{' '}
            <strong>arraste-o</strong> para esta área. (Apenas arquivos{' '}
            <strong>{convertToStringArray}</strong> serão aceitos.)
          </p>
        </div>
      </DropArea>
    </Container>
  );
}
