import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonsContainer,
  CallToAction,
  Container,
  Section,
  Table,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
} from './style';
import { IoIosArrowForward } from 'react-icons/io';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ExtraCoursesForm from 'views/MyProfile/components/ExtraCourse/ExtraCoursesForm';
import { GenericButtons } from '../GenericButtons';
import useMediaQuery from 'hooks/useMediaQuery';
import { deleteExtraCourses, getExtraCourses } from 'services/student/extraCourses';
import { useDataManagement } from 'views/Profile/Hooks/useDataManagement';
import ModalInformation from 'components/informationModal';
import { profileTexts } from 'views/Profile/Utils/profileTexts';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { actionsProfileStore } from 'stores/profile.store';

export function ExtraCoursesContent({
  cardData,
  cardDataKey,
  openModalWithContent,
  setUserData,
  handleIsLoadingState,
  shouldHideItem,
}) {
  const { updateCard } = actionsProfileStore;
  const { universityColor, fetchGamificationData, visibleInCurriculum } =
    useContext(ProfileContext);
  const smMobile = useMediaQuery('(max-width: 399px)');
  const mdMobile = useMediaQuery('(max-width: 501px)');

  const [visibleExtraCourses, setVisibleExtraCourses] = useState([]);
  const [controlModal, setControlModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { handleVisibleItem } = useDataManagement(setUserData, setDisabled);

  const { configExcludeExtraCourses } = profileTexts();

  useEffect(() => {
    setVisibleExtraCourses(visibleInCurriculum.additional_course);
  }, [visibleInCurriculum]);

  const handleToggleVisibility = (item) => {
    let updatedVisibleExtraCourses = [...visibleExtraCourses];

    if (visibleExtraCourses.includes(item.id)) {
      updatedVisibleExtraCourses = updatedVisibleExtraCourses.filter(
        (id) => id !== item.id
      );
    }

    if (!visibleExtraCourses.includes(item.id)) {
      if (visibleExtraCourses.length === 3) {
        return customSnackbar(
          `Seus três cursos relevantes já estão selecionados!`,
          'alert'
        );
      }
      updatedVisibleExtraCourses = [...visibleExtraCourses, item.id];
    }

    const visibilityItemConfig = {
      items: updatedVisibleExtraCourses,
      item: item,
      reqKey: 'additional_course',
      isUpdate: true,
      cardData,
      dataKey: cardDataKey,
      updateKey: 'extra_courses',
      setVisibleItems: setVisibleExtraCourses,
    };
    handleVisibleItem(visibilityItemConfig);
  };

  const excludeExtraCourse = async (extraCourseId) => {
    setControlModal(false);
    handleIsLoadingState(true);
    await deleteExtraCourses(extraCourseId);
    const response = await getExtraCourses();
    updateCard(cardDataKey, response);
    await fetchGamificationData();
    if (visibleExtraCourses.includes(extraCourseId)) {
      const updatedVisibleCourses = visibleExtraCourses.filter(
        (id) => id !== extraCourseId
      );
      setVisibleExtraCourses(updatedVisibleCourses);
      setUserData({ extra_courses: updatedVisibleCourses });
    }

    handleIsLoadingState(false);
    customSnackbar('Curso extra excluído com sucesso!', 'confirmation');
  };

  const callToExtraCoursesForm = () => {
    openModalWithContent(
      mergeContextsHelper(
        <ExtraCoursesForm
          cardData={cardData}
          visibleExtraCourses={visibleExtraCourses}
          cardDataKey={cardDataKey}
          setVisibleCourses={setVisibleExtraCourses}
          fetchGamificationData={fetchGamificationData}
          handleVisibleItem={handleVisibleItem}
        />
      ),
      'Adicionar Formação e Capacitação',
      null,
      () => null,
      false,
      'extracourse'
    );
  };

  const callToEditExtraCoursesForm = (item) => {
    openModalWithContent(
      mergeContextsHelper(
        <ExtraCoursesForm
          isEdit
          cardData={cardData}
          extraCourse={item}
          cardDataKey={cardDataKey}
          fetchGamificationData={fetchGamificationData}
          handleVisibleItem={handleVisibleItem}
        />
      ),
      'Editar Formação e Capacitação',
      null,
      () => null,
      false,
      'extracourse'
    );
  };

  return (
    <Container>
      {controlModal && (
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={controlModal}
          config={configExcludeExtraCourses}
          buttonConfirmFunction={() => excludeExtraCourse(controlModal)}
          buttonCancelFunction={() => setControlModal(false)}
        />
      )}
      <Section>
        <TableWrapper>
          <Table>
            {cardData[cardDataKey].length > 0 && (
              <TableHead>
                {!mdMobile && <TableHeaderCell>Status</TableHeaderCell>}
                <TableHeaderCell>Curso</TableHeaderCell>
                {!smMobile && <TableHeaderCell>Instituição</TableHeaderCell>}
                <TableHeaderCell></TableHeaderCell>
              </TableHead>
            )}

            <tbody>
              {cardData.hasOwnProperty(cardDataKey) &&
                Array.isArray(cardData[cardDataKey]) &&
                cardData[cardDataKey]?.map((item) => (
                  <TableRow key={item.id}>
                    {!mdMobile && (
                      <TableCell>
                        <p> {item.status.label} </p>
                      </TableCell>
                    )}
                    <TableCell>
                      <p> {item.course} </p>
                    </TableCell>
                    {!smMobile && (
                      <TableCell>
                        <p> {item.institution} </p>
                      </TableCell>
                    )}

                    <TableCell>
                      <ButtonsContainer>
                        <GenericButtons
                          showEdit={true}
                          showDelet={true}
                          showEye={true}
                          disabled={disabled}
                          shouldHideItem={shouldHideItem}
                          handleFuncEdit={() =>
                            callToEditExtraCoursesForm(item)
                          }
                          handleFuncDelet={() => setControlModal(item.id)}
                          handleFuncVisibility={() =>
                            handleToggleVisibility(item)
                          }
                          visible={item.visible}
                        />
                      </ButtonsContainer>
                    </TableCell>
                  </TableRow>
                ))}
            </tbody>
          </Table>
        </TableWrapper>

        <CallToAction
          disabled={shouldHideItem}
          universityColor={universityColor}
          onClick={callToExtraCoursesForm}
          showButton
        >
          Adicionar <IoIosArrowForward />
        </CallToAction>
      </Section>
    </Container>
  );
}
