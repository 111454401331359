export const LivestreamHelper = () => {
  const getIdFromLive = (url) => {
    if (url) {
      const urlObj = new URL(url);
      const searchParams = new URLSearchParams(urlObj.search);

      if (searchParams.has('v')) {
        return searchParams.get('v');
      }

      if (urlObj.hostname === 'youtu.be') {
        return urlObj.pathname.substring(1);
      }
    }

    return null;
  };

  const isYouTubeLink = (link) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    return youtubeRegex.test(link);
  };

  return {
    getIdFromLive,
    isYouTubeLink,
  };
};
