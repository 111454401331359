import React, { useContext, useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from '@react-pdf/renderer';
import QRCode from 'qrcode';
import { saveAs } from 'file-saver';
import { TicketHandler } from '../../../Handlers/TicketHandler';
import { DateHelper } from '../../../Helpers/DateHelper';
import BaseLayoutContext from 'contexts/base-layout';
import { getUniversityLogo } from 'views/Student/Learning/Services';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F5F5F5',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentInformation: {
    width: '80%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#C4C4C4',
    minHeight: 120,
    padding: 20,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#606062',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  span: {
    fontWeight: '400',
    fontSize: 15,
  },
  information: {
    display: 'flex',
    flexDirection: 'row',
    color: '#767676',
    alignItems: 'center',
    fontSize: 12,
    marginBottom: 14,
  },
  ticketInformation: {
    color: '#606062',
    fontSize: 12,
    marginBottom: 12,
  },
  qrCode: {
    marginTop: 15,
    marginBottom: 15,
    width: 300,
    height: 300,
  },
  containerInformations: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  ticketInformations: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
  },
});

const TicketPDFDocument = ({
  modalTicketData,
  qrCodeImage,
  universityLogo,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View
          style={{
            width: '100%',
            height: 50,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#FFF',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        >
          <Image
            style={{ width: 'auto', height: '100%' }}
            src={universityLogo}
            alt="University Logo"
          />
        </View>
        <View style={styles.contentInformation}>
          <Text style={styles.information}>
            Código do evento:{' '}
            <Text style={styles.information}>{modalTicketData?.code}</Text>
          </Text>
          <Text style={styles.title}>
            Ingresso: <Text style={styles.span}>{modalTicketData?.name}</Text>
          </Text>
          <View style={styles.containerInformations}>
            <Text style={styles.information}>
              Data do evento:{' '}
              {DateHelper.formatRange(
                modalTicketData?.event_start_at,
                modalTicketData?.event_end_at
              )}
            </Text>
            <Text style={styles.information}>
              Inscrição realizada dia:{' '}
              {DateHelper.format(modalTicketData?.registrated_at)}
            </Text>
          </View>
        </View>

        <View
          style={{
            ...styles.contentInformation,
            marginTop: 40,
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Text>
            Apresente o QR Code abaixo para confirmar a sua presença no evento
          </Text>

          <View
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image style={styles.qrCode} src={qrCodeImage} />
          </View>
          <View style={styles.ticketInformations}>
            <Text style={styles.ticketInformation}>
              Código do ingresso: {modalTicketData?.uuid}
            </Text>
            <Text style={styles.ticketInformation}>
              {modalTicketData?.student?.name}
            </Text>
            <Text style={styles.ticketInformation}>
              ID: {modalTicketData?.student?.external_id}
            </Text>
            <Text style={styles.ticketInformation}>
              {modalTicketData?.student?.course} -{' '}
              {modalTicketData?.student?.period}º período
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const downloadPDF = async (modalTicketData) => {
  const qrCodeImage = await QRCode.toDataURL(
    TicketHandler.generateLink(modalTicketData?.id, modalTicketData?.uuid),
    { type: 'png' }
  ).then((img) => {
    return img;
  });

  let responseLogoUrl;
  const response = await getUniversityLogo();
  const urlImg = `data:application/pdf;base64, ${response.logo_base64}`;
  try {
    const response = await fetch(urlImg);
    const blob = await response.blob();
    const file = new File([blob], 'university_logo.png', {
      type: `image/png`,
    });

    responseLogoUrl = URL.createObjectURL(file);
  } catch (error) {
    throw new Error(error);
  }

  let imageFile;
  let imageSrc;
  try {
    const response = await fetch(qrCodeImage);
    const blob = await response.blob();
    imageFile = new File([blob], 'qr_code.png', { type: 'image/png' });
    imageSrc = URL.createObjectURL(imageFile);
  } catch (error) {
    throw new Error(error);
  }

  const doc = (
    <TicketPDFDocument
      modalTicketData={modalTicketData}
      qrCodeImage={imageSrc}
      universityLogo={responseLogoUrl}
    />
  );
  const asPdf = pdf([]);
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();
  saveAs(
    blob,
    `${modalTicketData?.name?.replace(/\s+/g, '_').toLowerCase()}_ticket.pdf`
  );
};

export { TicketPDFDocument, downloadPDF };
