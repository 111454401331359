import dataProductsAPI from '../../../../services/dataProductsApi';

const getGraphicData = async (query = '') => {
  const response = dataProductsAPI
    .get(`/university/dashboard/?${query}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getGraphicData };
