import { IconPersonality } from 'assets/img/iconsCurriculum/iconPersonality';
import { IconBehavioral } from 'assets/img/iconsCurriculum/iconBehavioral';
import { IconValues } from 'assets/img/iconsCurriculum/iconValues';
import { IconLanguage } from 'assets/img/iconsCurriculum/iconLanguage';
import { IconCapacitation } from 'assets/img/iconsCurriculum/iconCapacitation';
import { IconExperiences } from 'assets/img/iconsCurriculum/iconExperiences';
import { IconProjectsPortfolio } from 'assets/img/iconsCurriculum/iconProjectsPortfolio';
import { IconCompetence } from 'assets/img/iconsCurriculum/iconCompetence';
import { IconInterests } from 'assets/img/iconsCurriculum/iconInterests';
import { IoIosArrowForward } from 'react-icons/io';
import { IconCourse } from 'assets/img/iconsCurriculum/iconCourse';
import { IconTypeCourse } from 'assets/img/iconsCurriculum/iconTypeCourse';
import { IconStatusCourse } from 'assets/img/iconsCurriculum/iconStatusCourse';
import { IconInstitution } from 'assets/img/iconsCurriculum/iconInstitution';
import { IconInfoAcademicStatus } from 'assets/img/iconsCurriculum/iconInfoAcademicStatus';

import { IconEmail } from 'assets/img/iconsCurriculum/iconEmail';
import { IconCivilState } from 'assets/img/iconsCurriculum/iconCivilState';
import { IconContact } from 'assets/img/iconsCurriculum/iconContact';
import { IconIdentificationCode } from 'assets/img/iconsCurriculum/iconIdentificationCode';
import { IconLinkedin } from 'assets/img/iconsCurriculum/iconLinkedin';
import { IconLocalization } from 'assets/img/iconsCurriculum/iconLocalization';
import { IconPortfolio } from 'assets/img/iconsCurriculum/iconPortfolio';
import { IconSocialMeta } from 'assets/img/iconsCurriculum/iconSocialMeta';
import { IconAlert } from 'assets/img/iconsCurriculum/iconAlert';
import { IconArrowEdit } from 'assets/img/iconsCurriculum/iconArrowEdit';
import { FiEdit } from 'react-icons/fi';
import { IoTrashOutline } from 'react-icons/io5';
import { IoIosClose } from 'react-icons/io';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export const wrapperIconsProfile = () => {
  return {
    IconInterests,
    IconCompetence,
    IconProjectsPortfolio,
    IconExperiences,
    IconCapacitation,
    IconLanguage,
    IconValues,
    IconBehavioral,
    IconPersonality,
    IoIosArrowForward,
    IconPersonality,
    IconCourse,
    IconTypeCourse,
    IconStatusCourse,
    IconInstitution,
    IconInfoAcademicStatus,
    IconEmail,
    IconCivilState,
    IconContact,
    IconIdentificationCode,
    IconLinkedin,
    IconLocalization,
    IconPortfolio,
    IconSocialMeta,
    IconAlert,
    IconArrowEdit,
    FiEdit,
    IoTrashOutline,
    AiOutlineEye,
    AiOutlineEyeInvisible,
    IoIosClose,
  };
};
