import React, { useContext } from 'react';
import {
  Bullet,
  BulletItem,
  BulletList,
  CallToAction,
  Container,
  ContainerValues,
  Description,
  InvertDirection,
  Section,
} from './style';
import { IoIosArrowForward } from 'react-icons/io';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ValuesTest from 'views/MyProfile/components/ValuesTest/ValuesTest';

export function ValuesContent({
  cardData,
  cardDataKey,
  openModalWithContent,
  shouldHideItem,
}) {
  const { universityColor, fetchGamificationData } = useContext(ProfileContext);

  const callToValuesTest = () => {
    openModalWithContent(
      mergeContextsHelper(
        <ValuesTest
          indicator={cardData[cardDataKey]}
          cardDataKey={cardDataKey}
          fetchGamificationData={fetchGamificationData}
        />
      ),
      'Valores',
      null,
      () => null,
      false,
      'valuesTest'
    );
  };

  const showCTAValues = () => {
    if (cardData[cardDataKey]?.results?.length === 0) {
      return (
        <CallToAction
          disabled={shouldHideItem}
          showButton
          universityColor={universityColor}
          onClick={callToValuesTest}
        >
          Realizar questionário <IoIosArrowForward />
        </CallToAction>
      );
    }

    if (cardData[cardDataKey]?.results?.length > 0) {
      return (
        <CallToAction
          disabled={shouldHideItem}
          showButton
          universityColor={universityColor}
          onClick={callToValuesTest}
        >
          Editar <IoIosArrowForward />
        </CallToAction>
      );
    }
  };

  function captalize(str) {
    let newStr = str.charAt(0).toLocaleUpperCase() + str.slice(1);
    return newStr;
  }
  return (
    <Container>
      <Section>
        <InvertDirection>
          {cardData[cardDataKey]?.results?.map((value, index) => (
            <ContainerValues>
              <BulletList>
                <BulletItem key={value.id}>
                  <Bullet universityColor={universityColor}>
                    {index + 1}&ordm;
                  </Bullet>
                  <p>{captalize(value.verbose_name)}</p>
                </BulletItem>
              </BulletList>
              <Description>
                <p key={index}>{captalize(value.description)}</p>
              </Description>
            </ContainerValues>
          ))}
        </InvertDirection>
        {showCTAValues()}
      </Section>
    </Container>
  );
}
