import styled from 'styled-components';

export const ModalOverlay = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 49.9%;
  left: 50%;
  z-index: 99999;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-color: Rgb(0, 0, 0, 0.6);
  position: fixed;
  transform: translate(-50%, -50%);

  .box {
    width: calc(100% - 259px);
    margin-left: auto;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media(max-width: 960px) {
    .box {
      width: 100%;
    }
  }
`;

export const ModalContent = styled.div`
  width: 421px;
  height: 366px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 16px;
  padding: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    img {
      width: 75px;
      height: 75px;
    }
  }

  p {
    color: #606062;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
  }

  button {
    width: 210px;
    height: 50px;
    background-color: #009291;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: #007a77;
    }
  }
`;

export const Dialog = styled.div`
  background-color: #009291;
  border-radius: 4px;
  padding: 1rem;
  font-size: 20px;
  color: white;
  position: relative;

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background: #009291;
    position: absolute;
    left: -8px;
    top: 26px;
  }
`;
