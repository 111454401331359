import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { generateUUID } from 'components/UploadEvidences/utils/generateUUID';
import ModalWithContentComponent from '../ModalWithContent/ModalWithContent';

/**
 * @typedef IModalWithContentParams
 * @prop {React.component} modalContent,
 * @prop {String} modalTitle,
 * @prop {any} modalColorScheme,
 * @prop {function} exitingFunction,
 * @prop {Boolean} disableBackDrop,
 * @prop {String} modalName,
 * @prop {Boolean} dialogTitleDisable,
 * @prop {Boolean} isNewModalDialog = false
 */

function createModal(closeFunc, update) {
  const modal = new Modal(closeFunc, update);
  return modal;
}

/**
 *
 * @param {Function} closeFunc
 * @param {Function} update
 * @param {IModalWithContentParams} modalWithContentParams
 */
function createModalWithContent(closeFunc, update, modalWithContentParams) {
  const modal = new ModalWithContent(closeFunc, update, modalWithContentParams);
  return modal;
}

class Modal {
  id;
  content;
  closeFunc;
  update;
  constructor(closeFunc, update) {
    this.id = generateUUID();
    this.content = <React.Fragment></React.Fragment>;
    this.closeFunc = closeFunc;
    this.update = update;
  }

  setContent(component) {
    this.content = component;
  }

  closeModal() {
    this.closeFunc(this);
  }

  render() {
    const Component = this.content;
    return <>{Component}</>;
  }
}

class ModalWithContent extends Modal {
  /**
   * Constructor function
   * @param {*} closeFunc
   * @param {*} update
   * @param {IModalWithContentParams} params
   */
  constructor(closeFunc, update, params) {
    super(closeFunc, update);
    this.openModalWithContent(params);
  }

  /**
   * @param {IModalWithContentParams} params
   */
  openModalWithContent({
    modalContent,
    modalTitle,
    modalColorScheme,
    exitingFunction,
    disableBackDrop,
    modalName,
    dialogTitleDisable,
    isNewModalDialog = false,
  }) {
    this.setContent(
      <ModalWithContentComponent
        isOpen={true}
        closeModal={() => this.closeModal()}
        exitingFunction={exitingFunction}
        content={modalContent}
        dialogTitle={modalTitle}
        colorScheme={modalColorScheme}
        disableBackDrop={disableBackDrop}
        dialogTitleDisable={dialogTitleDisable}
        isNewModalDialog={isNewModalDialog}
        modalName={modalName}
      />
    );
  }
}

export const ModalsContext = createContext();

function modalReducer(modals, action) {
  const actions = {
    reset: initialState,
    add: [...modals, action.modal],
    remove: modals.filter((modal) => action.modal.id !== modal.id),
  };
  return actions[action.type];
}

export default function ModalsProvider({ children, ...props }) {
  const [updateModals, setUpdateModals] = useState(true);

  const [modals, dispatch] = useReducer(modalReducer, initialState);

  const addArrayItem = (item) => {
    dispatch({
      type: 'add',
      modal: item,
    });
  };

  const removeModal = (modal) =>
    dispatch({
      type: 'remove',
      modal,
    });

  const getModals = () => {
    return modals;
  };

  const update = () => {
    setUpdateModals(!updateModals);
  };

  const create = useCallback(() => {
    const modal = createModal(removeModal, update);
    addArrayItem(modal);
    return modal;
  }, [modals]);

  const openModalWithContent = useCallback(
    /**
     *
     * @param {IModalWithContentParams} params
     * @returns
     */
    (params) => {
      const modal = createModalWithContent(removeModal, update, params);
      addArrayItem(modal);
      return modal;
    },
    [modals]
  );

  return (
    <ModalsContext.Provider
      value={{
        createModal: create,
        openModalWithContent,
        getModals,
      }}
    >
      {children}
      {modals.map((modal, index) => {
        return <React.Fragment key={index}>{modal.render()}</React.Fragment>;
      })}
    </ModalsContext.Provider>
  );
}

const initialState = [];
