import React, { useRef, useContext } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  WrapperInformations,
  WrapperForm,
  SubmitButton,
  WrapperButton,
} from './styled';
import GenericModal from '../../../../components/GenericModal';
import BaseLayoutContext from 'contexts/base-layout';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { useHistoricMentorshipContext } from '../../context';
import { schema } from './schema';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';

export function MentoringNotDoneModal({
  item,
  title,
  onClose,
  isOpen,
  fetchMentorship,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const { submitMentoringFeedback, setPage } = useHistoricMentorshipContext();
  const formRef = useRef();

  async function handleSubmit(data) {
    try {
      await schema.validate(data, { abortEarly: false });
      const feedbackData = {
        mentor_rating: null,
        not_realize_reason: data.not_realize_reason,
      };
      const success = await submitMentoringFeedback(item.id, feedbackData);
      if (success) {
        onClose();
        setPage(1);
        fetchMentorship(true);
        customSnackbar('Feedback enviado com sucesso!', 'confirmation');
      }
    } catch (error) {
      if (error.name === 'ValidationError') {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      customSnackbar('Erro ao enviar feedback', 'error');
    }
  }

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <WrapperInformations>
            Explique abaixo o motivo da mentoria não ter sido realizada
          </WrapperInformations>
          <WrapperForm>
            <DescriptionInput
              name="not_realize_reason"
              placeholder="Digite aqui..."
            />
          </WrapperForm>
          <WrapperButton>
            <SubmitButton type="submit" color={universityColor}>
              Enviar
            </SubmitButton>
          </WrapperButton>
        </Container>
      </Form>
    </GenericModal>
  );
}
