import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

import { updateProfessionalStatus } from 'services/student/professionalStatus';

import LogoAceleradora from 'assets/img/wl.png';

const StyledCheckBox = withStyles({
  root: {
    color: dnaColor,
    '&$checked': {
      color: dnaColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 500px;
  min-height: 350px;
  padding: 32px;

  background-color: #eeeeee;

  header {
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    margin: 24px 0px 24px 0px;
  }

  p {
    font-size: 16px;
    margin-bottom: 24px;
  }

  button {
    border-style: none;
    border-radius: 16px;
    width: 130px;
    height: 30px;
    padding: 6px 12px 6px 12px;
    margin-top: 16px;

    background-color: ${dnaColor};

    color: white;
    font-weight: 400;

    font-size: 16px;

    transition: opacity 0.4s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  img {
    height: 100%;
    width: 80%;
  }

  label {
    ${({ status }) => (status ? 'color: #3C4858' : 'color: #AAA')}
  }
`;

export default function WorkInAreaDialog({ studentName, isOpen, setClose }) {
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(true);

  async function handleSubmit(workInArea) {
    const response = await updateProfessionalStatus(workInArea);
    if (response) {
      setClose({ workInAreaModal: false });
    }
    setOpen(false);
  }

  function handleStatus(value) {
    setStatus(value);
  }

  return (
    <Dialog open={isOpen && open} disableBackdropClick>
      <Container status={status}>
        <img src={LogoAceleradora} alt="logo" />
        <header>Olá, {studentName}</header>
        <p>
          Gostaríamos de saber se o seu emprego atual é dentro da área do seu
          curso.
        </p>
        <div>
          <label>Sim, estou trabalhando na minha área de formação.</label>
          <StyledCheckBox
            status={status}
            onClick={(event) => {
              handleStatus(event.target.checked);
            }}
          />
        </div>
        <button type="button" onClick={() => handleSubmit(status)}>
          Salvar
        </button>
      </Container>
    </Dialog>
  );
}
