import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const BaseContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: start;

  overflow: hidden;
`;

export const Container = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 7px;
  gap: 10px;
`;

export const ContainerContentCards = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
`;

export const HeaderPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 70px;
  height: 70px;
  opacity: 0.6;
  border-radius: 12px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const Level = styled.div`
  position: relative;
  width: 74px;
  height: 30px;
  border-radius: 10px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  opacity: 0.6;
`;

export const ButtonPagination = styled.div`
  width: 100px;
  height: 32px;
  border-radius: 30px;
  margin-left: ${(props) => (props.hasMarginRight ? '14px' : '')};

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  opacity: 0.6;
`;

export const Title = styled.div`
  width: 60px;
  height: 16px;
  border-radius: 5px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  opacity: 0.6;
`;

export const Paragraph = styled.div`
  width: 90px;
  height: 18px;
  border-radius: 5px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  opacity: 0.6;
`;
