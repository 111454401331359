export function IconCompany({ color }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.84212 8.22005H4.59212V9.38672H2.84212V8.22005ZM5.75879 9.38672H7.50879V8.22005H5.75879V9.38672ZM2.84212 11.7201H4.59212V10.5534H2.84212V11.7201ZM5.75879 11.7201H7.50879V10.5534H5.75879V11.7201ZM2.84212 4.72005H4.59212V3.55339H2.84212V4.72005ZM5.75879 4.72005H7.50879V3.55339H5.75879V4.72005ZM2.84212 7.05339H4.59212V5.88672H2.84212V7.05339ZM5.75879 7.05339H7.50879V5.88672H5.75879V7.05339ZM14.5088 5.30339V14.6367H0.508789V2.38672C0.508789 1.92259 0.693164 1.47747 1.02135 1.14928C1.34954 0.821093 1.79466 0.636719 2.25879 0.636719L8.09212 0.636719C8.55625 0.636719 9.00137 0.821093 9.32956 1.14928C9.65775 1.47747 9.84212 1.92259 9.84212 2.38672V3.55339H12.7588C13.2229 3.55339 13.668 3.73776 13.9962 4.06595C14.3244 4.39414 14.5088 4.83926 14.5088 5.30339ZM8.67546 2.38672C8.67546 2.23201 8.614 2.08364 8.5046 1.97424C8.3952 1.86484 8.24683 1.80339 8.09212 1.80339H2.25879C2.10408 1.80339 1.95571 1.86484 1.84631 1.97424C1.73691 2.08364 1.67546 2.23201 1.67546 2.38672V13.4701H8.67546V2.38672ZM13.3421 5.30339C13.3421 5.14868 13.2807 5.0003 13.1713 4.89091C13.0619 4.78151 12.9135 4.72005 12.7588 4.72005H9.84212V13.4701H13.3421V5.30339ZM11.0088 9.38672H12.1755V8.22005H11.0088V9.38672ZM11.0088 11.7201H12.1755V10.5534H11.0088V11.7201ZM11.0088 7.05339H12.1755V5.88672H11.0088V7.05339Z"
        fill={color}
      />
    </svg>
  );
}
