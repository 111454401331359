import React from 'react';

export const failedRequest = {
  description: (
    <span>
      <p>
        Vi que você solicitou a <u>Análise de Atividade Extracurricular</u>,
        após a confirmação vou encaminhar a soliciação para a sua Instituição de
        Ensino. Ah, lembre-se de acompanhar o andamento da sua solicitação por
        aqui!
      </p>
    </span>
  ),
  buttonText: 'OK, SOLICITAR ANÁLISE',
  buttonTextDenied: 'NÃO SOLICITAR ANÁLISE!',
};

export const approvedRequest = {
  description: (
    <span>
      <p>
        Caso você salve as alterações realizadas, será necessário solicitar uma
        nova análise da sua instituição de ensino, tem certeza que deseja salvar
        essas alterações?
      </p>
    </span>
  ),
  buttonText: 'SIM, SOLICITAR NOVA ANÁLISE',
  buttonTextDenied: 'NÃO DESEJO ALTERAR!',
};

export const awaitingRequest = {
  description: (
    <span>
      <p>
        Caso você salve as alterações realizadas, vou gerar uma nova solicitação
        para a sua instituição, isso poderá impactar no tempo de resposta, pois
        a data de solicitação também será atualizada. Tem certeza que deseja
        salvar essas alterações?
      </p>
    </span>
  ),
  buttonText: 'SIM, REENVIAR ANÁLISE',
  buttonTextDenied: 'NÃO DESEJO ALTERAR!',
};
