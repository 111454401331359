import React, { useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { UnInput, UnInputLabel, ContainerLabelInput, Error } from './style';
import { LetterCounter } from 'views/StaffPages/SettingsPage/PersonalizationTab/LoginConfig/Styles/BaseComponent';

/**
 * Input component with character counter.
 *
 * @param {string} label - The label for the input.
 * @param {string} name - The name of the input.
 * @param {number} maxLength - The maximum length allowed for the text in the input.
 * @param {Function} onChange - Function to be called when the value of the input changes.
 * @param {boolean} useCount - Indicates whether the character counter should be displayed.
 * @returns {JSX.Element} - The input component with character counter.
 */

export default function InputWithCounter({
  label,
  name,
  maxLength,
  onChange,
  useCount,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const [count, setCount] = useState(0);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',

      setValue: (ref, value) => {
        ref.value = value ? value : '';

        if (useCount) {
          setCount(value.length);
        }
      },
    });
  }, [fieldName, registerField, useCount]);

  const lengthRemaining = maxLength - count;

  const handleChange = (event, useDefault) => {
    const value = useDefault ? event : event.target.value;

    if (onChange) {
      onChange(event);
    }
    if (useCount) {
      setCount(value === '' ? 0 : value.length);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue, true);
    }
  }, [defaultValue]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel htmlFor={name}>{label || name}</UnInputLabel>}
      <UnInput
        error={error}
        ref={inputRef}
        maxLength={maxLength}
        defaultValue={defaultValue}
        onChange={handleChange}
        onInput={handleChange}
        {...rest}
      />
      {useCount && (
        <LetterCounter>{lengthRemaining} caracteres restantes</LetterCounter>
      )}
      {error && <Error>{error}</Error>}
    </ContainerLabelInput>
  );
}
