import { DataArea } from "../../Styles/BaseComponent";
import { SkeletonLegend } from "../../Styles/StyleSkeleton";


export function WidgetSkeleton(params) {
  return (
    <DataArea skeleton>
      <div className="data-side">
        <SkeletonLegend widthLegend={138}/>
        <SkeletonLegend widthLegend={88}/>
      </div>
      <div className="icon-side">
        <SkeletonLegend widthLegend={40} heightLegend={40}/>
      </div>
    </DataArea>
  )
}