import MentorshipModal from 'components/MentorshipModal';
import React, { useState, useContext } from 'react';
import { ActionButton, Container, StarButton } from './styles';
import BaseLayoutContext from 'contexts/base-layout';
import { useHistoricMentorshipContext } from '../../context';
import InformationModal from '../InformationModal';

export default function MentoringDoneModal({
  isOpen,
  onClose,
  item,
  fetchMentorship,
}) {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [value, setValue] = useState(0);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const { universityColor } = useContext(BaseLayoutContext);

  const { submitMentoringFeedback, setPage } = useHistoricMentorshipContext();

  async function handleEvaluate() {
    setRequestInProgress(true);
    const success = await submitMentoringFeedback(item.id, {
      mentor_rating: value,
      not_realize_reason: '',
    });
    if (success) {
      setPage(1);
      fetchMentorship(true);
      setShowSuccessMessage(true);
    }
    setRequestInProgress(false);
  }

  return (
    <MentorshipModal isOpen={isOpen} onClose={onClose}>
      {showSuccessMessage ? (
        <InformationModal
          title="Avaliação enviada"
          description="Agradecemos pela sua colaboração!"
          universityColor={universityColor}
        />
      ) : (
        <Container>
          <h1>Avalie a sua mentoria</h1>
          <strong>
            Sua avaliação é muito importante para que possamos sempre evoluir em
            nossas mentorias.
          </strong>
          <div>
            {[...Array(5)].map((star, index) => {
              const ratingValue = index + 1;
              return (
                <StarButton
                  key={ratingValue}
                  selected={ratingValue <= value}
                  onClick={() => setValue(ratingValue)}
                />
              );
            })}
          </div>
          {value > 0 && (
            <ActionButton
              universityColor={universityColor}
              disabled={requestInProgress}
              onClick={handleEvaluate}
            >
              {requestInProgress ? 'Enviando...' : 'Enviar'}
            </ActionButton>
          )}
        </Container>
      )}
    </MentorshipModal>
  );
}
