import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: ${(props) => (props.height ? props.height : '400px')};
  background-color: #ffff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #d8d7d7;
  position: relative;
  margin-bottom: 40px;
`;

export const ContainerTitle = styled.div`
  margin-left: 10px;

  h4 {
    font-weight: bold;
    color: #4b4b4d;
  }

  p {
    color: #777777;
  }
`;
