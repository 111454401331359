export default function BasicLanguageIcon({ color }) {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4623 23.8306C21.4456 25.8529 20.123 27.4834 18.1388 27.9458C17.7952 28.0202 17.4442 28.0547 17.0926 28.0487C15.2647 28.0487 13.436 28.0524 11.6081 28.0419C11.3815 28.0419 11.3218 28.093 11.3294 28.3182C11.3483 28.8369 11.318 29.3571 11.3407 29.8751C11.3566 30.2414 11.2357 30.5079 10.9132 30.6843H10.5514C10.3319 30.6145 10.1343 30.4893 9.97806 30.321C9.40702 29.746 8.80879 29.195 8.26343 28.5989C7.92957 28.2326 7.56852 28.1005 7.07831 28.1185C6.15679 28.1515 5.23301 28.1282 4.30998 28.1275C2.40425 28.1275 0.808975 27.0142 0.155605 25.2374C0.061187 24.9814 0.0740234 24.7119 0 24.4529V14.0274C0.126142 13.8638 0.113307 13.6581 0.166181 13.4727C0.647333 11.7724 2.23052 10.4819 4.00482 10.4482C6.08352 10.4091 8.16373 10.4324 10.2432 10.4354C10.6345 10.4354 10.9026 10.6869 10.9086 11.0239C10.9139 11.3805 10.6307 11.629 10.1986 11.6297C8.25965 11.6297 6.32069 11.6297 4.38249 11.6297C2.50849 11.6297 1.21006 12.9254 1.21006 14.7901C1.21006 17.7553 1.21006 20.7205 1.21006 23.6857C1.21006 25.5519 2.51075 26.8461 4.38249 26.8476C5.56762 26.8476 6.75351 26.8543 7.93864 26.8438C8.26495 26.8438 8.48777 26.9497 8.71135 27.2027C9.12604 27.6733 9.59434 28.0975 10.0853 28.5809L10.1314 28.6259V28.5644C10.1314 28.2191 10.1314 27.915 10.1314 27.611C10.1314 27.0668 10.3512 26.8506 10.9011 26.8498C12.9606 26.8498 15.02 26.8498 17.079 26.8498C18.9545 26.8498 20.256 25.5549 20.2583 23.6925C20.2583 22.4343 20.2583 21.1764 20.2583 19.9188C20.2547 19.7995 20.2661 19.6802 20.2922 19.5637C20.3238 19.4364 20.3983 19.3237 20.5033 19.2443C20.6083 19.165 20.7375 19.1237 20.8693 19.1276C21.1443 19.1448 21.3437 19.2822 21.4237 19.5532C21.4501 19.6493 21.4625 19.7486 21.4607 19.8482C21.4653 21.1754 21.4736 22.5026 21.4623 23.8306Z"
        fill={color}
      />
      <path
        d="M28.9125 3.24743C27.7502 1.80256 26.1487 0.770244 24.3472 0.304749C22.9174 -0.0773486 21.4618 -0.0112875 20.0071 0.0382576C19.3133 0.0673308 18.627 0.191879 17.9676 0.408344C14.3072 1.56965 11.8494 4.87716 11.8169 8.74693C11.8283 9.18372 11.8664 9.61942 11.931 10.0516C12.357 12.6137 13.6743 14.6038 15.8459 16.0286C17.1407 16.8819 18.6504 17.3573 20.2034 17.4008C21.4989 17.4466 22.795 17.4954 24.07 17.1959C24.1476 17.1691 24.2316 17.1665 24.3108 17.1885C24.3899 17.2105 24.4603 17.256 24.5127 17.319C25.1585 17.9758 25.8179 18.6192 26.4698 19.2708C26.6903 19.4907 26.9328 19.6311 27.2493 19.496C27.5514 19.3661 27.6156 19.1064 27.6133 18.8053C27.6073 17.8865 27.633 16.9677 27.6013 16.0503C27.5877 15.645 27.7191 15.386 28.0182 15.112C29.8068 13.474 30.7737 11.4336 30.8553 9.01643C30.9308 6.86197 30.2789 4.9237 28.9125 3.24743ZM29.5999 9.62298C29.3778 11.4862 28.5688 13.0761 27.1239 14.2937C26.5951 14.7359 26.3225 15.1758 26.3972 15.8702C26.4531 16.3896 26.4086 16.9211 26.4086 17.5217C25.9048 17.0187 25.4568 16.5751 25.0135 16.1262C24.8148 15.925 24.5814 15.8259 24.3057 15.9054C22.9642 16.3123 21.5865 16.2568 20.214 16.2057C18.5976 16.1442 17.1428 15.6119 15.901 14.5948C13.6864 12.7789 12.7097 10.43 13.135 7.59614C13.5338 4.93571 15.0452 3.0455 17.4736 1.88494C18.4019 1.44727 19.4162 1.2196 20.4436 1.21833C21.4059 1.20932 22.3705 1.15828 23.329 1.32042C25.2635 1.64772 26.8429 2.57407 28.0492 4.10471C29.3287 5.72393 29.8431 7.58037 29.5999 9.62298Z"
        fill={color}
      />
      <path
        d="M17.1803 19.1804C17.1776 19.3316 17.1184 19.4763 17.0143 19.5865C16.9101 19.6967 16.7684 19.7644 16.6168 19.7765C16.5266 19.7838 16.4361 19.7858 16.3456 19.7825H3.99808C3.54488 19.7772 3.25407 19.5363 3.26238 19.1722C3.27069 18.8081 3.55168 18.5859 3.99204 18.5852C6.07024 18.5852 8.14843 18.5852 10.2266 18.5852H16.3404C16.4305 18.5826 16.5208 18.5846 16.6108 18.5912C16.9408 18.6242 17.1788 18.8727 17.1803 19.1804Z"
        fill={color}
      />
      <path
        d="M14.4 23.0931C14.2645 23.1308 14.1234 23.1448 13.983 23.1344H4.00725C3.54801 23.1299 3.25947 22.8972 3.26173 22.5339C3.264 22.1706 3.55102 21.9386 4.01254 21.9386C5.70047 21.9386 7.3874 21.9386 9.07332 21.9386C10.7411 21.9386 12.4087 21.9386 14.0759 21.9386C14.4952 21.9386 14.7271 22.1067 14.7852 22.437C14.8124 22.5743 14.7883 22.7166 14.7173 22.8374C14.6464 22.9582 14.5336 23.0492 14.4 23.0931Z"
        fill={color}
      />
      <path
        d="M13.2571 15.8116C13.2617 16.0856 13.1189 16.2823 12.8545 16.3822C12.7293 16.4197 12.5982 16.4339 12.4678 16.4242H8.25225C6.85689 16.4242 5.46203 16.4242 4.06767 16.4242C3.9477 16.4279 3.82772 16.417 3.71039 16.3919C3.58269 16.3605 3.46921 16.2876 3.38793 16.1848C3.30665 16.082 3.26223 15.9551 3.26172 15.8244C3.26377 15.6936 3.30935 15.5672 3.39135 15.465C3.47335 15.3627 3.58714 15.2903 3.71492 15.2591C3.81249 15.2368 3.91243 15.2265 4.01252 15.2284C6.84253 15.2284 9.6728 15.2284 12.5033 15.2284C12.6233 15.2251 12.743 15.2403 12.8583 15.2734C12.9752 15.3068 13.0777 15.3777 13.1499 15.4751C13.222 15.5724 13.2597 15.6908 13.2571 15.8116Z"
        fill={color}
      />
      <path
        d="M17.1803 22.5437C17.172 22.699 17.1053 22.8456 16.9934 22.9543C16.8814 23.0631 16.7325 23.126 16.576 23.1307C16.4197 23.1225 16.2722 23.0562 16.1628 22.9449C16.0534 22.8337 15.9901 22.6856 15.9854 22.5301C15.9938 22.3749 16.0606 22.2284 16.1725 22.1197C16.2844 22.011 16.4332 21.948 16.5896 21.9431C16.7459 21.9513 16.8934 22.0176 17.0028 22.1289C17.1122 22.2401 17.1756 22.3882 17.1803 22.5437Z"
        fill={color}
      />
      <path
        d="M24.054 6.92984C24.0125 7.87945 23.3183 8.79829 22.3923 9.11058C22.1952 9.17664 22.1015 9.25471 22.1174 9.47916C22.1408 9.81697 22.1294 10.1548 22.1219 10.4971C22.1121 10.9242 21.8537 11.2095 21.5002 11.1982C21.1467 11.187 20.9247 10.9152 20.9179 10.4963C20.9179 10.2666 20.9179 10.0369 20.9179 9.80796C20.9179 9.57825 20.9111 9.34779 20.9179 9.11883C20.9212 8.893 20.9943 8.67364 21.1275 8.49058C21.2606 8.30752 21.4472 8.16963 21.6619 8.09565C21.7654 8.05736 21.8719 8.02884 21.9754 7.99131C22.2757 7.88342 22.5273 7.67231 22.6847 7.39612C22.8421 7.11994 22.8948 6.79691 22.8334 6.48543C22.769 6.17502 22.5969 5.89697 22.3471 5.69992C22.0973 5.50286 21.7858 5.39934 21.467 5.40745C21.1478 5.41657 20.8425 5.53939 20.6068 5.75353C20.3711 5.96767 20.2207 6.25886 20.1829 6.57401C20.1716 6.66259 20.1565 6.75193 20.1384 6.83976C20.0696 7.17306 19.8234 7.35623 19.491 7.32395C19.3435 7.31298 19.206 7.24561 19.1074 7.13601C19.0088 7.0264 18.9568 6.88308 18.9623 6.73616C18.9887 5.76027 19.4155 4.99082 20.2781 4.53441C22.106 3.57503 24.1447 4.88497 24.054 6.92984Z"
        fill={color}
      />
      <path
        d="M22.1156 12.983C22.1066 13.1429 22.0344 13.2928 21.9147 13.4001C21.795 13.5074 21.6376 13.5633 21.4766 13.5558C21.3992 13.5517 21.3234 13.5326 21.2535 13.4993C21.1836 13.4661 21.121 13.4195 21.0692 13.3622C21.0175 13.3048 20.9777 13.2379 20.9521 13.1652C20.9264 13.0926 20.9155 13.0156 20.9199 12.9387C20.9285 12.7837 20.9977 12.6382 21.1126 12.5332C21.2276 12.4281 21.3794 12.3719 21.5355 12.3764C21.6911 12.3868 21.8371 12.4549 21.9446 12.5672C22.0521 12.6796 22.1131 12.828 22.1156 12.983Z"
        fill={color}
      />
    </svg>
  );
}
