import {
  BubbleDialog,
  Button,
  ButtonWhite,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonX,
  WrapperStep,
} from '../style';
import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

import { Form } from '@unform/web';
import { updateProfessionalStatusModal } from 'services/student/professionalStatus';

import CustomSearchableSelect from '../CustomSelect';

export default function ModalStepOneEmployed(props) {
  const {
    config,
    profileData,
    universityColor,
    modalOpenState,
    professional_status,
    setOpenModalProfessionalStatus,
    setOpenModalSatisfaction,
    setOpenModalDissatisfaction,
    setOpenModalEmployedStatus,
    hasFirstStep,
  } = props;

  const formRef = useRef(null);

  const satisfactionOptions = [
    { label: 'Satisfeito', value: true },
    { label: 'Insatisfeito', value: false },
  ];

  function onSubmit(data) {
    updateProfessionalStatusModal(data);
    if (data.professional_satisfaction) {
      setOpenModalSatisfaction(true);
    }
    if (!data.professional_satisfaction) {
      setOpenModalDissatisfaction(true);
    }
    setOpenModalEmployedStatus(false);
  }

  return (
    <Wrapper visible={modalOpenState}>
      <Box>
        <Modal>
          {hasFirstStep && (
            <WrapperStep>
              <ButtonX color={universityColor} />
              <ButtonX color={universityColor} />
            </WrapperStep>
          )}

          <WrapperTitle alignIcon={config?.style?.alignIcon}>
            <IconPersonStyled
              universityColor={universityColor}
              src={IconPerson}
            />
            <BubbleDialog universityColor={universityColor}>
              <H4Styled>{profileData.name}, agora me conta:</H4Styled>
            </BubbleDialog>
          </WrapperTitle>
          <ParagraphStyled>
            <p>
              Como você avalia a sua satisfação na atuação "
              <strong>{professional_status?.occupation_area?.label}.</strong>"
            </p>
          </ParagraphStyled>
          <Form ref={formRef} style={{ width: '100%' }} onSubmit={onSubmit}>
            <CustomSearchableSelect
              fieldName="professional_satisfaction"
              options={satisfactionOptions}
              placeholder="Selecione"
              universityColor={universityColor}
              isMulti={false}
            />

            <Button type="submit" universityColor={universityColor}>
              ENVIAR INFORMAÇÕES
            </Button>
          </Form>

          <ButtonWhite
            type="button"
            universityColor={universityColor}
            onClick={setOpenModalProfessionalStatus}
          >
            NÃO ESTOU MAIS ATUANDO NESSA FUNÇÃO
          </ButtonWhite>
        </Modal>
      </Box>
    </Wrapper>
  );
}
