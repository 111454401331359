import React from 'react';
import LoadingUpdate from '../../../../../components/LoadingUpdate/LoadigUpdate';
import { InfoModalBackground, InfoModalContainer } from '../../Styles/Styled';

export default function LoadingModal({ isOpen }) {
  return (
    <InfoModalBackground isOpen={isOpen}>
      <InfoModalContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '5em',
          }}
        >
          <LoadingUpdate />
          <h6 style={{ marginTop: '3.3em' }}>
            Estamos buscando as informações da oportunidade. Aguarde...
          </h6>
        </div>
      </InfoModalContainer>
    </InfoModalBackground>
  );
}
