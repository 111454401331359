import {
  GraphicContainer,
  GridContainer,
  LegendArea,
} from '../../Styles/StyleGraphics';
import { GiPlainCircle } from 'react-icons/gi';
import { Description, SubTitle } from '../../Styles/StyleSections';
import {
  SkeletonDescription,
  SkeletonLegend,
  SkeletonTitle,
} from '../../Styles/StyleSkeleton';
import { CircularGraphSkeleton } from '../Skeletons/CircularGraphSkeleton';
import GenericMessage from 'components/GenericMessage';
import {
  returnAdapter,
  verifyValuesChartAreZero,
} from '../../Utils/formatDataGraphics';

export function LegendSection({ children, graphicalData, title, subtitle }) {
  const colorCode = {
    consultant: '#88EFEE',
    entrepreneur: '#6ABABA',
    full_time_job: '#1C6D6D',
    intern: '#3DA2A1',
    part_time_job: '#00F5F3',
    trainee: '#D5FFFA',
  };

  const dataAdapter = returnAdapter(graphicalData);

  const returnContent = () => {
    if (graphicalData === null) {
      return (
        <GraphicContainer>
          <GridContainer>
            <div className="left-side">
              <LegendArea>
                {title && <SkeletonTitle subtitle={subtitle} />}
                {subtitle && <SkeletonDescription />}
                <div className="wrapper">
                  {[1, 2, 3, 4, 5, 6].map(({}) => (
                    <div className="columns">
                      <SkeletonLegend widthLegend={130} />
                    </div>
                  ))}
                </div>
              </LegendArea>
            </div>
            <CircularGraphSkeleton />
          </GridContainer>
        </GraphicContainer>
      );
    }

    if (verifyValuesChartAreZero(dataAdapter)) {
      return (
        <GraphicContainer>
          <SubTitle pdLeft="31px"> {title} </SubTitle>
          <Description pdLeft="31px">{subtitle}</Description>
          <GenericMessage
            title="Dados Indisponíveis"
            subtitle="No momento, esses dados não estão cadastrados e por isso estão indisponíveis."
          />
        </GraphicContainer>
      );
    }

    if (dataAdapter?.result?.length > 0) {
      return (
        <GraphicContainer>
          <GridContainer>
            <div className="left-side">
              <LegendArea>
                <SubTitle> {title} </SubTitle>
                <Description mgBottom="40px">{subtitle}</Description>
                <div className="wrapper">
                  {dataAdapter?.result?.map(({ name, label }) => (
                    <div className="columns">
                      <GiPlainCircle size={9} color={colorCode[name]} />
                      <span> {label} </span>
                    </div>
                  ))}
                </div>
              </LegendArea>
            </div>
            <div className="right-side">{children}</div>
          </GridContainer>
        </GraphicContainer>
      );
    }
  };

  return <>{returnContent()}</>;
}
