import BaseModalAcademic from '../base';
import TagIcon from 'assets/academicModeling/tag.svg';
import {
  AddTagButton,
  ContainerAssociation,
  ContainerInformationsTag,
  ContainerNameTag,
  ContainerTags,
  DeleteIconWrapper,
  DividerTag,
} from './style';
import DeleteIcon from 'assets/academicModeling/delete';
import AcademicModelingApi from 'services/university/academicModeling';
import React, { useContext, useState, useEffect } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { useAcademicModeling } from '../../../../../contexts/AcademicModelingContext';
import ModalInformation from 'components/informationModal';

export default function AssociationsModal({
  associations,
  openModal,
  setOpenModal,
  setOpenCreateAssociationModal,
  marketItem,
  tags,
  refetchMarket,
  isTendencies,
  removeItemFromList,
}) {
  const [modalDeleteAlertOpen, setModalDeleteAlertOpen] = React.useState(false);
  const [deleteAlertConfig, setDeleteAlertConfig] = useState({});
  const [allTags, setAllTags] = useState([]);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const { openSnackbar } = useContext(BaseLayoutContext);
  const {
    selectedCourse,
    updateMarketRequirementsMetrics,
    updateMarketTendenciesMetrics,
    setSelectedStep,
    checkCurrentStep,
  } = useAcademicModeling();

  const configModalInformation = {
    bubbleText: 'Atenção!',
    ParagraphText: `Não indentificamos nenhuma tag cadastrada nas disciplinas
do seu curso. <br/><br/> Deseja realizar a descrição dos conteúdos abordados?`,
    buttonConfirmText: 'SIM, CRIAR TAGS',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    modalHeight: 380,
    buttonConfirmFunction: () => setSelectedStep(2),
    buttonCancelFunction: () => setModalInformationOpen(false),
  };

  useEffect(() => {
    const getAllTags = async () => {
      const response = await AcademicModelingApi.getTagsCreateTag(
        selectedCourse.id
      );
      setAllTags(response);
    };
    getAllTags();
  }, []);

  async function deleteTag(tagId) {
    const oldTags = tags.map((tag) => tag.value);
    const newTags = oldTags.filter((t) => t !== tagId);
    const response = await AcademicModelingApi.updateAssociation(
      marketItem.association_id,
      { tags: newTags }
    );
    if (response.status === 200) {
      refetchMarket(response.data);
      if (newTags.length === 0) {
        removeItemFromList(marketItem.id);
        if (isTendencies) {
          updateMarketTendenciesMetrics();
        } else {
          updateMarketRequirementsMetrics();
        }
      }
      checkCurrentStep();
    } else {
      openSnackbar('Ocorreu um erro, tente novamente.', true);
    }
  }

  function showDeleteAlertModal(id, isDiscipline = false) {
    const tagsAmount = tags.length;
    const disciplineAmount = associations.length;
    let paragraphText;
    let modalHeight;

    if (isDiscipline) {
      if (disciplineAmount === 1) {
        paragraphText = `Ao remover todas as DISCIPLINAS desse requisito, ele
será movido para os requisitos não contemplados.</br></br>Tem certeza que
deseja remover essa DISCIPLINA? `;
        modalHeight = '400';
      } else {
        paragraphText = `Tem certeza que deseja remover essa DISCIPLINA?
        A ação não poderá ser desfeita.`;
        modalHeight = '310';
      }
    } else {
      if (tagsAmount === 1) {
        paragraphText = `Ao remover todas as TAGs desse requisito, ele será
movido para os requisitos não contemplados.</br></br>Tem certeza que deseja
remover essa TAG? `;
        modalHeight = '374';
      } else {
        paragraphText = `Tem certeza que deseja remover essa TAG? A ação não
        poderá ser desfeita.`;
        modalHeight = '310';
      }
    }
    setDeleteAlertConfig({
      bubbleText: 'Atenção!',
      ParagraphText: paragraphText,
      buttonConfirmText: 'SIM, REMOVER',
      buttonConfirmFunction: () => {
        if (isDiscipline) {
          deleteDiscipline(id);
        } else {
          deleteTag(id);
        }
        setModalDeleteAlertOpen(false);
      },
      buttonCancelText: 'NÃO',
      showButtonCancel: true,
      modalWidth: '368',
      modalHeight: modalHeight,
    });
    setModalDeleteAlertOpen(true);
  }

  async function deleteDiscipline(disciplineId) {
    const oldDisciplines = associations.map((item) => item.id);
    const newDisciplines = oldDisciplines.filter((t) => t !== disciplineId);
    const response = await AcademicModelingApi.updateDisciplines(
      selectedCourse.id,
      marketItem.association_id,
      { disciplines: newDisciplines }
    );
    if (response.status === 200) {
      refetchMarket(response.data);
      if (newDisciplines.length === 0) {
        removeItemFromList(marketItem.id);
        if (isTendencies) {
          updateMarketTendenciesMetrics();
        } else {
          updateMarketRequirementsMetrics();
        }
      }
      checkCurrentStep();
    } else {
      openSnackbar('Ocorreu um erro, tente novamente.', true);
    }
  }

  const mapTags = (tags) => {
    return tags.map((item) => (
      <ContainerTags>
        <div>
          <ContainerNameTag>
            <img src={TagIcon} alt={''} />
            <p>{item.name}</p>
          </ContainerNameTag>
          <ContainerInformationsTag>
            <DividerTag></DividerTag>
            <DeleteIcon onClick={() => showDeleteAlertModal(item.id)} />
          </ContainerInformationsTag>
        </div>
      </ContainerTags>
    ));
  };

  const mapAssociations = associations.map((item) => (
    <ContainerAssociation>
      <div>
        <h6>{item.name}</h6>
        {marketItem.tags === 0 && (
          <DeleteIconWrapper>
            <DeleteIcon onClick={() => showDeleteAlertModal(item.id, true)} />
          </DeleteIconWrapper>
        )}
      </div>
      {mapTags(item.tags)}
    </ContainerAssociation>
  ));

  function handleOpenCreateAssociationModal() {
    if (allTags.length === 0) {
      setModalInformationOpen(true);
      return;
    }
    setOpenCreateAssociationModal(true);
  }

  return (
    <BaseModalAcademic
      openModal={openModal}
      setOpenModal={setOpenModal}
      headerTitle={marketItem.name}
    >
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={configModalInformation}
        buttonConfirmFunction={configModalInformation.buttonConfirmFunction}
        buttonCancelFunction={configModalInformation.buttonCancelFunction}
      />
      {mapAssociations}
      <AddTagButton onClick={handleOpenCreateAssociationModal}>
        Adicionar TAG
      </AddTagButton>
      <ModalInformation
        modalOpenState={modalDeleteAlertOpen}
        config={deleteAlertConfig}
        buttonConfirmFunction={deleteAlertConfig?.buttonConfirmFunction}
        buttonCancelFunction={() => {
          setModalDeleteAlertOpen(false);
        }}
      />
    </BaseModalAcademic>
  );
}
