import React, { useContext, useEffect, useRef } from 'react';
import {
  Box,
  BubbleDialog,
  ReturnButton,
  CloseButton,
  Container,
  ContainerButton,
  Content,
  GreenButton,
  WrapperMessage,
  WrapperTitle,
} from '../ModalStylingPattern/styles';
import Wall from 'assets/JobOffer/IconPerson.svg';

import { GrFormClose } from 'react-icons/gr';
import { ExtensionProjectsStudentContext } from 'views/Student/Learning/Context/ExtensionProjectStudentContext';
import { unsubscribeProject } from 'views/Student/Extracurricular/services';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalUnsubscribeProject(props) {
  const {
    extensionProjectId,
    modalFeedbackUnsubscribe,
    setModalFeedbackUnsubscribe,
    getExtesionProject,
    extensionProjectCount,
    getAllEnrolledExtensionProject,
    getAllNoSelectedExtensionProject,
    getAllFinishedExtensionProject,
  } = useContext(ExtensionProjectsStudentContext);

  const {universityColor} = useContext(BaseLayoutContext)

  const { isOpen, setModalUnsubscribe, currentList, name } = props;

  const listGetterCurrentUpdateList = {
    0: getAllEnrolledExtensionProject,
    1: getAllNoSelectedExtensionProject,
    2: getAllFinishedExtensionProject,
  };

  const unsubscribeModal = useRef();

  useEffect(() => {
    setModalFeedbackUnsubscribe(false);
  }, [isOpen]);

  const closeModal = () => {
    setModalUnsubscribe(false);
    const getterUpdate = listGetterCurrentUpdateList[currentList];
    getterUpdate && getterUpdate(true);
  };

  const closeModalClick = (e) => {
    if (unsubscribeModal.current === e.target) {
      setModalUnsubscribe(false);
      const getterUpdate = listGetterCurrentUpdateList[currentList];
      getterUpdate && getterUpdate(true);
    }
  };

  const closeModalUnsubscribe = async () => {
    await unsubscribeProject(extensionProjectId);
    extensionProjectCount();
    getExtesionProject();

    setModalFeedbackUnsubscribe(true);
  };

  const closeModalX = () => {
    setModalUnsubscribe(false);
  };

  const UnsubscribeModal = () => {
    return (
      <Container isOpen={isOpen}>
        <Box ref={unsubscribeModal} onClick={closeModalClick}>
          <Content>
            <WrapperTitle universityColor={universityColor}>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog
                universityColor={universityColor}
                widthBubble="123px"
                heightBubble="48px"
              >
                <span> Atenção! </span>
              </BubbleDialog>
            </WrapperTitle>

            <WrapperMessage
              fontMessage="20px"
              lineHeigthMessage="28px"
              positionMessage="start"
            >
              Tem certeza que deseja cancelar a sua inscrição no projeto de
              extensão?
            </WrapperMessage>
            <CloseButton onClick={closeModalX}>
              <GrFormClose size={18} />
            </CloseButton>
            <ContainerButton>
              <ReturnButton
                universityColor={universityColor}
                marginTopButton="10px"
                onClick={closeModalX}
              >
                NÃO
              </ReturnButton>
              <GreenButton
                widthButton="211px"
                marginTopButton="10px"
                type="button"
                onClick={closeModalUnsubscribe}
                universityColor={universityColor}
              >
                SIM, CANCELAR
              </GreenButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    );
  };

  const ConfirmUnsubscribeModal = () => {
    return (
      <Container>
        <Box ref={unsubscribeModal} onClick={closeModalClick}>
          <Content>
            <WrapperTitle universityColor={universityColor}>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog
                universityColor={universityColor}
                widthBubble="219px"
                heightBubble="48px"
              >
                <span> Inscrição cancelada. </span>
              </BubbleDialog>
            </WrapperTitle>

            <WrapperMessage
              fontMessage="20px"
              lineHeigthMessage="28px"
              positionMessage="center"
            >
              Você cancelou sua inscrição e não precisará participar do projeto
              de extensão <p> {name}. </p>
            </WrapperMessage>

            <ContainerButton>
              <GreenButton
                widthButton="211px"
                marginTopButton="10px"
                type="button"
                onClick={closeModal}
                universityColor={universityColor}
              >
                OK, ENTENDI
              </GreenButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    );
  };

  if (modalFeedbackUnsubscribe === false) {
    return <UnsubscribeModal />;
  } else {
    return <ConfirmUnsubscribeModal />;
  }
}
