import React, { Fragment, useEffect, useState } from 'react';
import { number, object, func } from 'prop-types';
import CardComposition from 'components/CardComposition/CardComposition';
import { getProfessionDetail } from 'services/backoffice/professions';
import { withStyles } from '@material-ui/core/styles';
import jobsPositionDetailsStyles from 'assets/jss/material-dashboard-pro-react/views/components/jobsPositionDetailsStyles';
import ReactHtmlParser from 'react-html-parser';

function ProfessionDetail(props) {
  const { classes, professionId, loadingState, openSnackbar, data } = props;
  const [professionDetail, setProfessionDetail] = useState({});

  useEffect(() => {
    if (data) {
      setProfessionDetail(data);
      loadingState(false);
    } else {
      loadingState(true);
      fetchProfessionDetail();
    }
  }, []);

  const fetchProfessionDetail = async () => {
    const response = await getProfessionDetail(professionId);

    if (!response.message) {
      setProfessionDetail(response);
      loadingState(false);
    } else {
      openSnackbar(
        'Falha ao carregar os detalhes da atuação! Tente novamente mais tarde.',
        true
      );
    }
  };

  return (
    <div style={{ margin: '0 auto', maxWidth: 860 }}>
      {professionDetail.name ? (
        <CardComposition
          CardContent={() => (
            <Fragment>
              <p className={classes.paragraph}>
                <strong>Nome da atuação:</strong>
                <br />
                {professionDetail.name}
              </p>
              {professionDetail.transversal_profession && (
                <p className={classes.paragraph}>
                  <strong>Atuação Transversal</strong>
                  <br />
                </p>
              )}
              {professionDetail.description && (
                <p className={classes.paragraph}>
                  <strong>Descrição:</strong>
                  <br />
                  {ReactHtmlParser(
                    professionDetail.description.replace(/style="[^"]*"/g, '')
                  )}
                </p>
              )}
              {!professionDetail.transversal_profession && (
                <p className={classes.paragraph}>
                  <strong>Cursos:</strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {professionDetail.associated_courses.map((course) => (
                        <li key={course.value}>{course.label}</li>
                      ))}
                    </ul>
                  </span>
                </p>
              )}
              {professionDetail.salary_range && (
                <p className={classes.paragraph}>
                  <strong>Faixa Salarial:</strong>
                  <br />
                  {professionDetail.salary_range}
                </p>
              )}
              {professionDetail.previous_requirements && (
                <p className={classes.paragraph}>
                  <strong>Requisitos Prévios:</strong>
                  <br />
                  {professionDetail.previous_requirements}
                </p>
              )}
              {professionDetail.notice_url && (
                <p className={classes.paragraph}>
                  <strong>Link para Editais:</strong>
                  <br />
                  <a href={professionDetail.notice_url} target="_blank">
                    {professionDetail.notice_url}
                  </a>
                </p>
              )}
              {/* {professionDetail.associated_areas.default.length > 0 && (
                <p className={classes.paragraph}>
                  <strong>Conhecimentos Específicos:</strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {professionDetail.associated_areas.default.map((area) => (
                        <li key={area.value}>{area.label}</li>
                      ))}
                    </ul>
                  </span>
                </p>
              )} */}
              {professionDetail.associated_areas.additional_courses.length >
                0 && (
                <p className={classes.paragraph}>
                  <strong>Requisitos de Mercado:</strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {professionDetail.associated_areas.additional_courses.map(
                        (area) => (
                          <li key={area.value}>{area.label}</li>
                        )
                      )}
                    </ul>
                  </span>
                </p>
              )}
              {professionDetail.associated_areas.market_tendencies.length >
                0 && (
                <p className={classes.paragraph}>
                  <strong>Tendências de Mercado:</strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {professionDetail.associated_areas.market_tendencies.map(
                        (area) => (
                          <li key={area.value}>{area.label}</li>
                        )
                      )}
                    </ul>
                  </span>
                </p>
              )}
              {professionDetail.associated_behavioral.length > 0 && (
                <p className={classes.paragraph}>
                  <strong>Atributos comportamentais:</strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {professionDetail.associated_behavioral.map(
                        (behavior) => (
                          <li key={behavior.value}>{behavior.label}</li>
                        )
                      )}
                    </ul>
                  </span>
                </p>
              )}
              <p className={classes.paragraph}>
                <strong>Andamento do curso:</strong>
                <br />
                {professionDetail.course_progress.label}
              </p>
            </Fragment>
          )}
        />
      ) : (
        <CardComposition
          CardContent={() => (
            <div>Não há detalhes cadastrados para esta atuação.</div>
          )}
        />
      )}
    </div>
  );
}
ProfessionDetail.propTypes = {
  classes: object.isRequired,
  professionId: number.isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  data: object,
};
export default withStyles(jobsPositionDetailsStyles)(ProfessionDetail);
