import { authenticatedAPI } from 'services/api';
import axios from 'axios';
import httpRequest from './HttpRequest';

const CancelToken = axios.CancelToken;

let cancelGetEvents;
let cancelGetCourses;
let cancelGetPrograms;
let cancelGetCommunicate;
let cancelGetTrails;
let cancelGetEbooks;
let cancelGetArticle;
let cancelGetChallenge;
let cancelGetSerie;

let cancelGetEducationalGroupEvents;
let cancelGetEducationalGroupCourses;
let cancelGetEducationalGroupPrograms;
let cancelGetEducationalGroupCommunicate;
let cancelGetEducationalGroupTrails;
let cancelGetEducationalGroupEbooks;
let cancelGetEducationalGroupArticle;
let cancelGetEducationalGroupChallenge;
let cancelGetEducationalGroupSerie;

const cancelRequests = () => {
  cancelGetEvents && cancelGetEvents();
  cancelGetCourses && cancelGetCourses();
  cancelGetPrograms && cancelGetPrograms();
  cancelGetCommunicate && cancelGetCommunicate();
  cancelGetTrails && cancelGetTrails();
  cancelGetEbooks && cancelGetEbooks();
  cancelGetArticle && cancelGetArticle();
  cancelGetChallenge && cancelGetChallenge();
  cancelGetSerie && cancelGetSerie();
};

const cancelRequestsEducationalGroup = () => {
  cancelGetEducationalGroupEvents && cancelGetEducationalGroupEvents();
  cancelGetEducationalGroupCourses && cancelGetEducationalGroupCourses();
  cancelGetEducationalGroupPrograms && cancelGetEducationalGroupPrograms();
  cancelGetEducationalGroupCommunicate &&
    cancelGetEducationalGroupCommunicate();
  cancelGetEducationalGroupTrails && cancelGetEducationalGroupTrails();
  cancelGetEducationalGroupEbooks && cancelGetEducationalGroupEbooks();
  cancelGetEducationalGroupArticle && cancelGetEducationalGroupArticle();
  cancelGetEducationalGroupChallenge && cancelGetEducationalGroupChallenge();
  cancelGetEducationalGroupSerie && cancelGetEducationalGroupSerie();
};

const createNewEvent = async (data) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(data));

  const response = authenticatedAPI
    .post('/api/internal/university/attractions/', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data)
    .catch((error) => error);
};

const createNewEventNew = async (data, educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/create`
    : `/api/internal/university/attractions/`;

  const formData = new FormData();
  const newData = Object.assign(data, {
    target_audiences: {
      universities: [...data.universities],
      macro_areas: [...data.macro_areas],
      courses: [...data.courses],
      semesters: [...data.semesters],
    },
  });

  if (data.attraction_document) {
    formData.append('attraction_document', data.attraction_document);
    delete data.attraction_document;
  }
  if (data.attraction_image) {
    formData.append('attraction_image', data.attraction_image);
    delete data.attraction_image;
  }
  formData.append('name', data.name);
  formData.append('access_link', data.access_link ? data.access_link : '');
  formData.append('type', data.type);
  formData.append('is_free', data.is_free ? data.is_free : true);
  formData.append('end_at', '');
  formData.append('start_at', '');
  formData.append(
    'deadline_registration',
    data.deadline_registration ? data.deadline_registration : ''
  );
  formData.append('value', data.value ? data.value : 0);
  formData.append('has_limited_vacancies', false);
  formData.append(
    'target_audiences',
    JSON.stringify([
      {
        universities: [...data.universities],
        macro_areas: [...data.macro_areas],
        courses: [...data.courses],
        semesters: [...data.semesters],
      },
    ])
  );
  formData.append('data', JSON.stringify(newData));

  const response = authenticatedAPI
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        //         'Content-Type': 'application/json',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const updateEvent = async (data, educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/update/${data.id}`
    : `/api/internal/university/attractions/${data.id}`;

  if (data.remove_attraction_image) {
    url += `?remove_attraction_image=true`;
  }

  if (data.remove_attraction_document) {
    url += `?remove_attraction_document=true`;
  }

  const formData = new FormData();
  const newData = Object.assign(data, {
    target_audiences: {
      universities: [...data.universities],
      macro_areas: [...data.macro_areas],
      courses: [...data.courses],
      semesters: [...data.semesters],
    },
  });

  formData.append(
    'attraction_document',
    data.attraction_document ? data.attraction_document : ''
  );
  formData.append(
    'attraction_image',
    data.attraction_image ? data.attraction_image : ''
  );

  formData.append('name', data.name);
  formData.append('access_link', data.access_link ? data.access_link : '');
  formData.append('type', data.type);
  formData.append('is_free', data.is_free ? data.is_free : true);
  formData.append('end_at', '');
  formData.append('start_at', '');
  formData.append(
    'deadline_registration',
    data.deadline_registration ? data.deadline_registration : ''
  );
  formData.append('value', data.value ? data.value : 0);
  formData.append('has_limited_vacancies', false);
  formData.append(
    'target_audiences',
    JSON.stringify([
      {
        universities: [...data.universities],
        macro_areas: [...data.macro_areas],
        courses: [...data.courses],
        semesters: [...data.semesters],
      },
    ])
  );
  formData.append('data', JSON.stringify(newData));

  const response = authenticatedAPI
    .put(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getAttractionsFilterOptions = () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/filter/options`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getAttractionsEducationGroupFilterOptions = () => {
  const response = authenticatedAPI
    .get(`/api/internal/educational-group/attractions/filter/options`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getAttractionsEducationGroupUniversityFilterOptions = () => {
  const response = authenticatedAPI
    .get(
      `/api/internal/educational-group-university/attractions/filter/options`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const unPublishAttraction = async (event, educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/university/attractions/${event.id}`
    : `/api/internal/educational-group/attractions/unpublish/${event.id}`;
  const response = authenticatedAPI
    .patch(url, { is_published: false })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const deleteEvent = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/university/attractions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getEvents = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=event`
    : `/api/internal/university/attractions/${page}&type=event`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEvents = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getArticles = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=article`
    : `/api/internal/university/attractions/${page}&type=article`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetArticle = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getSeries = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=serie`
    : `/api/internal/university/attractions/${page}&type=serie`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetSerie = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getEbooks = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=e_book`
    : `/api/internal/university/attractions/${page}&type=e_book`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEbooks = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getPrograms = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=program`
    : `/api/internal/university/attractions/${page}&type=program`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetPrograms = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getTrails = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=trail`
    : `/api/internal/university/attractions/${page}&type=trail`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetTrails = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getCourses = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=course`
    : `/api/internal/university/attractions/${page}&type=course`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetCourses = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getChallenges = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=challenge`
    : `/api/internal/university/attractions/${page}&type=challenge`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetChallenge = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getAnnouncements = async (educationalGroup, page) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/university-available/list${page}&type=announcements`
    : `/api/internal/university/attractions/${page}&type=announcements`;
  const response = authenticatedAPI
    .get(url, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetCommunicate = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const countAttractions = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/attractions/count`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const countAttractionsEducationalGroup = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/attractions-educational-group/count-group`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getEventsEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(`api/internal/educational-group/attractions/list${page}&type=event`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEducationalGroupEvents = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCoursesEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(`api/internal/educational-group/attractions/list${page}&type=course`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEducationalGroupCourses = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getProgramsEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(
      `api/internal/educational-group/attractions/list${page}&type=program`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetEducationalGroupPrograms = c;
        }),
      }
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getAnnoucementEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(
      `api/internal/educational-group/attractions/list${page}&type=announcements`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetEducationalGroupCommunicate = c;
        }),
      }
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getTrailsEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(`api/internal/educational-group/attractions/list${page}&type=trail`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEducationalGroupTrails = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getEbooksEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(`api/internal/educational-group/attractions/list${page}&type=e_book`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEducationalGroupEbooks = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getArticlesEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(
      `api/internal/educational-group/attractions/list${page}&type=article`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetEducationalGroupArticle = c;
        }),
      }
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getChallengesEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(
      `api/internal/educational-group/attractions/list${page}&type=challenge`,
      {
        cancelToken: new CancelToken(function executor(c) {
          cancelGetEducationalGroupChallenge = c;
        }),
      }
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getSeriesEducationalGroup = async (page) => {
  const response = authenticatedAPI
    .get(`api/internal/educational-group/attractions/list${page}&type=serie`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetEducationalGroupSerie = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getEventsPartners = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/companies-connections`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const unPublishEvent = async (event, publish) => {
  const response = authenticatedAPI
    .put(
      `/api/internal/university/attractions/${event.id}/companies-connections`,
      { unpublish: publish }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getEventById = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getUniversitiesOptions = async (type) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/university/attractions${type ? `?type=${type}` : '/'}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterOptions = async (type, queryParams) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/university/attractions?type=${type}&${queryParams && queryParams
      }`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCoursesByUniversityOptions = async (id) => {
  const response = authenticatedAPI
    .options(`/api/internal/university/attractions?university=${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAttractionsFilter = async (type, queryParams) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions?type=${type}&${queryParams}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const uploadImg = async (event) => {
  const dataFile = new FormData();
  dataFile.append('attraction_image', event.file);

  const response = authenticatedAPI
    .put(`/api/internal/university/attractions/${event.id}`, dataFile)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const uploadImgNew = async (event) => {
  const dataFile = new FormData();
  dataFile.append('attraction_image', event.attraction_image);

  const response = authenticatedAPI
    .put(`/api/internal/university/attractions/${event.id}`, dataFile)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const uploadPdf = async (event) => {
  const dataFile = new FormData();
  dataFile.append('attraction_document', event.attraction_document);

  const headers = {
    'content-type': 'multipart/form-data',
  };

  const response = authenticatedAPI
    .put(`/api/internal/university/attractions/${event.id}/file`, dataFile, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getDetails = async (opportunitiesId) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/detail/${opportunitiesId}`)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getVisibleAttraction = async (opportunitiesId, data) => {
  try {
    const response = await authenticatedAPI.patch(
      `/api/internal/university/attractions/analysis/${opportunitiesId}`, { ...data }
    );
    return response;
  } catch (error) {
    return error;
  }
};

const getAccessAttraction = async (opportunitiesId) => {
  return await httpRequest.get(
    `api/internal/university/attractions/access/${opportunitiesId}`
  );
};

export {
  createNewEvent,
  countAttractions,
  getEvents,
  getUniversitiesOptions,
  getCoursesByUniversityOptions,
  updateEvent,
  deleteEvent,
  getEventById,
  uploadImg,
  getEventsPartners,
  unPublishEvent,
  countAttractionsEducationalGroup,
  getPrograms,
  getChallenges,
  getCourses,
  getArticles,
  getEbooks,
  getTrails,
  getSeries,
  getAttractionsFilter,
  getFilterOptions,
  uploadPdf,
  createNewEventNew,
  uploadImgNew,
  getAnnouncements,
  unPublishAttraction,
  getEventsEducationalGroup,
  getCoursesEducationalGroup,
  getProgramsEducationalGroup,
  getAnnoucementEducationalGroup,
  getTrailsEducationalGroup,
  getEbooksEducationalGroup,
  getArticlesEducationalGroup,
  getChallengesEducationalGroup,
  getSeriesEducationalGroup,
  cancelRequests,
  cancelRequestsEducationalGroup,
  getDetails,
  getAttractionsFilterOptions,
  getAttractionsEducationGroupFilterOptions,
  getAttractionsEducationGroupUniversityFilterOptions,
  getVisibleAttraction,
  getAccessAttraction
};
