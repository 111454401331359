export function HouseLaptopIcon({ color = '#009291', size = '15px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66667 10.219H2.83333V11.3856H0.5V5.45896C0.5 4.91646 0.745 4.41479 1.17083 4.08229L4.67083 1.34063C5.30667 0.844792 6.19333 0.844792 6.82917 1.34063L8.66667 2.78146V1.46896H9.83333V3.69729L10.3292 4.08229C10.755 4.41479 11 4.91646 11 5.45896V5.55229H9.83333V5.45896C9.83333 5.27813 9.75167 5.10896 9.61167 4.99813L6.11167 2.26229C5.90167 2.09313 5.60417 2.09896 5.39417 2.26229L1.88833 5.00396C1.74833 5.11479 1.66667 5.28396 1.66667 5.46479V10.2248V10.219ZM14.5 11.3856V13.1356C14.5 14.0981 13.7125 14.8856 12.75 14.8856H5.75C4.7875 14.8856 4 14.0981 4 13.1356V11.3856H4.58333V8.46896C4.58333 7.50646 5.37083 6.71896 6.33333 6.71896H12.1667C13.1292 6.71896 13.9167 7.50646 13.9167 8.46896V11.3856H14.5ZM5.75 11.3856H7.73917L8.3225 11.969H10.1717L10.755 11.3856H12.7442V8.46896C12.7442 8.14813 12.4817 7.88563 12.1608 7.88563H6.33333C6.0125 7.88563 5.75 8.14813 5.75 8.46896V11.3856ZM13.3333 12.5523H11.2392L10.6558 13.1356H7.83833L7.255 12.5523H5.16083V13.1356C5.16083 13.4565 5.42333 13.719 5.74417 13.719H12.7442C13.065 13.719 13.3275 13.4565 13.3275 13.1356V12.5523H13.3333Z"
        fill={color}
      />
    </svg>
  );
}
