import React, { useEffect, useRef } from 'react';
import StepOneChallenges from './RegisterChallengesStepOne';
import StepTwoChallenges from './RegisterChallengesStepTwo';
import HeaderModal from 'components/Challenges/HeaderModal/HeaderModal';

import { ModalRegisterChallenges, BodyChallenges } from './stylesModal';

function BodyModalChallenges(props) {
  const {
    setIsOpenModal,
    formData,
    setFormData,
    currentStep,
    setCurrentStep,
    cacheOptions,
    setCacheOptions,
    setFormValues,
    editChallenge,
    loadChallenges,
    setChallenges,
    setModalInfoOpen,
    ScrollToTopEdit,
  } = props;

  const modalRef = useRef();

  const handleClose = (e) => {
    if (modalRef.current === e.target) {
      setIsOpenModal(false);
    }
  };

  const scrollToTop = () => {
    if (modalRef) {
      modalRef.current.scrollIntoView();
    }
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <BodyChallenges ref={modalRef} onClick={(e) => handleClose(e)}>
      <ModalRegisterChallenges editChallenge={editChallenge}>
        <HeaderModal
          setIsOpenModal={setIsOpenModal}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
        {currentStep === 1 ? (
          <StepOneChallenges
            setCurrentStep={setCurrentStep}
            formData={formData}
            setFormData={setFormData}
            setFormValues={setFormValues}
            currentStep={currentStep}
            modalRef={modalRef}
            ScrollToTop={scrollToTop}
          />
        ) : (
          <StepTwoChallenges
            setCurrentStep={setCurrentStep}
            formData={formData}
            setFormData={setFormData}
            setFormValues={setFormValues}
            cacheOptions={cacheOptions}
            setCacheOptions={setCacheOptions}
            setIsOpenModal={setIsOpenModal}
            loadChallenges={loadChallenges}
            setChallenges={setChallenges}
            setModalInfoOpen={setModalInfoOpen}
            ScrollToTop={scrollToTop}
            ScrollToTopEdit={ScrollToTopEdit}
          />
        )}
      </ModalRegisterChallenges>
    </BodyChallenges>
  );
}

export default BodyModalChallenges;
