import { useEffect, useState } from 'react';

export const useOutSideClick = (ref) => {
  const [clickedOutside, SetClickedOutside] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        SetClickedOutside(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return [clickedOutside, SetClickedOutside];
};
