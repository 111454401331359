import styled from 'styled-components';

export const ContainerInput = styled.div`
  width: 100%;
  max-width: 400px;
  height: 42px;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.2s;

  @media (max-width: 700px) {
    transition: 0.2s;
    width: 100%;
  }

  > input {
    width: 100%;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    border: none;
    margin-left: 10px;
  }
`;

export const ContainerIcon = styled.div`
  right: 8px;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 2px;
  gap: 10px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  position: absolute;
  display: flex;
  align-items: center;
`;
