import moment from 'moment';

export const scheduleInterviewAdapter = ({
  scheduling_date,
  address,
  description,
  scheduling_time,
  access_link,
  is_online,
  in_person,
}) => {
  let adapter = {
    processFormat: is_online ? true : false,
  };

  if (is_online) {
    adapter.accessLink = access_link;
    (adapter.descriptionOnline = description),
      (adapter.schedulingDateOnline = scheduling_date),
      (adapter.schedulingHourOnline = scheduling_time);
  }

  if (in_person) {
    (adapter.street = address?.street),
      (adapter.number = address?.number),
      (adapter.complement = address?.complement),
      (adapter.district = address?.district),
      (adapter.city = address?.city),
      (adapter.state = address?.state),
      (adapter.zipCode = address?.zip_code),
      (adapter.description = description),
      (adapter.schedulingDate = scheduling_date),
      (adapter.schedulingHour = scheduling_time);
  }
  return adapter;
};

export const scheduleInterviewPayload = ({
  schedulingDate,
  number,
  state,
  city,
  complement,
  district,
  description,
  street,
  zipCode,
  schedulingHour,
  accessLink,
  interviewerMessage,
  intervieweeMessage,
  descriptionOnline,
  schedulingHourOnline,
  schedulingDateOnline,
}) => ({
  address: zipCode
    ? {
        street: street,
        number: number,
        complement: complement,
        district: district,
        city: city,
        state: state,
        zip_code: zipCode,
      }
    : {},

  access_link: accessLink,
  scheduling_date: `${moment(schedulingDate || schedulingDateOnline).format(
    'DD/MM/YYYY'
  )} ${schedulingHour || schedulingHourOnline}`,
  scheluding_time: schedulingHourOnline || schedulingHour,
  interviewer_message: interviewerMessage ? interviewerMessage : '',
  interviewee_message: intervieweeMessage,
  description: description || descriptionOnline,
});
