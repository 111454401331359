import React, { useEffect, useState } from 'react';

import {
  Container,
  ContainerVisualization,
  ContainerCards,
  ContainerFilter,
  ContainerMobileButtons,
  CustomGenericTabs,
} from './styled';
import Header from './Header';
import GenericInternalTab from 'components/GenericInternalTab';
import RegisterModal from '../RegisterModal';

import { useRegisterModalContext } from '../../Contexts/RegisterModalContext';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import WaitingStart from './Tabs/WaitingStart';
import Button from '../Button';
import FilterModal from 'components/FilterModal';
import OnGoing from './Tabs/onGoing';
import ToFinish from './Tabs/ToFinish';
import DetailsModal from '../DetailsModal';
import FairAndEventsService from '../../Services/index';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { staticOptions } from '../../StaticOptions';
import { FilterHandlersFactory } from '../../Handlers/FilterHandlers';
import { filtersConfig } from '../../StaticOptions/FilterConfig';
import StudentVisualizationEngine from '../StudentVisualization/StudentVisualizationEngine';
import Finished from './Tabs/Finished';
import useMediaQuery from 'hooks/useMediaQuery';
import ModalInformation from 'components/informationModal';

export default function EngineVisualization({ id, uuid }) {
  const { setModalOpen } = useRegisterModalContext();
  const visualizationContextInstace = useVisualizationContext();

  const isMobile = useMediaQuery('(max-width: 768px)');

  const {
    currentTab,
    handleTabChange,
    studentViewModalOpen,
    setStudentViewModalOpen,
    modalInformationEmailSentOpen,
    setModalInformationEmailSentOpen,
    emailToExport,
    amounts,
    hasNextPagination,
    handleClickLoadMore,
    loading,
  } = visualizationContextInstace;

  const [filterOptions, setFilterOptions] = useState({});
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const FilterHandlersFactoryInstance = FilterHandlersFactory(
    visualizationContextInstace,
    setFilterOptions
  );

  const getFilterOptions = async () => {
    const endpoints = {
      0: FairAndEventsService.getAwaitingStartFilterOptions,
      1: FairAndEventsService.getOngoingFilterOptions,
      2: FairAndEventsService.getToFinishFilterOptions,
      3: FairAndEventsService.getFinishedFilterOptions,
    };

    const activeEndpoint = endpoints[currentTab];
    if (activeEndpoint) {
      const response = await activeEndpoint();
      setFilterOptions(response);
    }
  };

  useEffect(() => {
    getFilterOptions();
  }, [currentTab]);

  const tabsLabel = [
    `Aguardando início (${amounts?.amounts?.awaiting_start || 0})`,
    `Em andamento (${amounts?.amounts?.in_progress || 0})`,
    `A finalizar (${amounts?.amounts?.to_finish || 0})`,
    `Finalizados (${amounts?.amounts?.finished || 0})`,
  ];

  useEffect(() => {
    if (id && uuid) {
      setStudentViewModalOpen(true);
    }
  }, []);

  const accessFilterOption = (filter) => {
    if (filter.optionsKey === 'boolean') {
      return staticOptions.boolean;
    }
    return filterOptions[filter.optionsKey] || [];
  };

  const filterModal = (
    <FilterModal
      buttonWidth="100%"
      filterState={filterState}
      setFilterState={setFilterState}
      onClean={FilterHandlersFactoryInstance.onClearfilter}
      onApply={(event) => FilterHandlersFactoryInstance.onApplyFilters(event)}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={0}
    >
      <ContainerFilter>
        {filtersConfig.map((filterGroup, index) => {
          if (Array.isArray(filterGroup)) {
            return (
              <div key={`group-${index}`} className="groupFilters">
                {filterGroup.map((filter) => {
                  return (
                    <Filter
                      name={filter.name}
                      label={filter.label}
                      type={FilterType[filter.type]}
                      options={accessFilterOption(filter)}
                      {...filter.settings}
                      {...(filter.name === 'associated_areas' && {
                        settings: {
                          onType: (e) =>
                            FilterHandlersFactoryInstance.handleMacroAreaChange(
                              e
                            ),
                        },
                      })}
                    />
                  );
                })}
              </div>
            );
          }

          return (
            <div
              key={
                typeof filterGroup.name === 'string'
                  ? filterGroup.name
                  : filterGroup.name.join('_')
              }
            >
              <Filter
                name={filterGroup.name}
                label={filterGroup.label}
                type={FilterType[filterGroup.type]}
                options={accessFilterOption(filterGroup)}
                {...filterGroup.settings}
                {...(filterGroup.name === 'associated_areas' && {
                  settings: {
                    onType: (e) =>
                      FilterHandlersFactoryInstance.handleMacroAreaChange(e),
                  },
                })}
              />
            </div>
          );
        })}
      </ContainerFilter>
    </FilterModal>
  );

  const buttons = isMobile
    ? []
    : [
        filterModal,
        <Button theme="dark" onClick={() => setModalOpen(true)}>
          Cadastrar evento
        </Button>,
      ];

  const visualizationTabs = {
    0: <WaitingStart />,
    1: <OnGoing />,
    2: <ToFinish />,
    3: <Finished />,
  };

  if (studentViewModalOpen) {
    return (
      <Container>
        <StudentVisualizationEngine id={id} uuid={uuid} />
      </Container>
    );
  }

  return (
    <Container>
      <DetailsModal />
      <Header />
      <ModalInformation
        modalOpenState={modalInformationEmailSentOpen}
        config={{
          bubbleText: `Lista exportada com sucesso!`,
          ParagraphText: `A lista foi exportada com sucesso e enviada para o e-mail ${emailToExport.current}. `,
          buttonConfirmText: 'OK, ENTENDI',
        }}
        buttonConfirmFunction={() => setModalInformationEmailSentOpen(false)}
      />
      {isMobile && (
        <ContainerMobileButtons>
          <Button width="100%" theme="dark" onClick={() => setModalOpen(true)}>
            Cadastrar evento
          </Button>
          {filterModal}
        </ContainerMobileButtons>
      )}

      <ContainerVisualization>
        <CustomGenericTabs
          tabs={tabsLabel}
          handleTabChange={handleTabChange}
          activeTab={currentTab}
          buttons={buttons}
          zIndex={1}
          containerButtonsPosition={'unset'}
        />

        <ContainerCards>{visualizationTabs[currentTab]}</ContainerCards>

        {hasNextPagination && (
          <div className="loadMoreButton">
            <Button
              theme="dark"
              disabled={loading}
              onClick={handleClickLoadMore}
            >
              Ver mais
            </Button>
          </div>
        )}
      </ContainerVisualization>
    </Container>
  );
}
