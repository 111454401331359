export function IconProfileReward() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.8638 19.995C27.0775 19.9537 30 15.6038 30 11.8737C30 9.7775 28.515 8.02375 26.5438 7.6C26.7138 7.1175 26.8613 6.67375 26.96 6.3375C27.4013 4.84625 27.1162 3.27125 26.1775 2.015C25.22 0.735 23.7563 0 22.16 0H7.83875C6.2425 0 4.7775 0.735 3.82 2.015C2.88125 3.27125 2.59625 4.8475 3.0375 6.3375C3.1375 6.67375 3.28375 7.1175 3.455 7.60125C1.48375 8.025 0 9.77875 0 11.875C0 15.605 2.92375 19.955 11.1363 19.9963C11.2063 20.3738 11.25 20.7588 11.25 21.1513V25C11.25 27.2825 9.33 27.49 8.75 27.5H7.5C6.80875 27.5 6.25 28.0588 6.25 28.75C6.25 29.4412 6.80875 30 7.5 30H22.5C23.1912 30 23.75 29.4412 23.75 28.75C23.75 28.0588 23.1912 27.5 22.5 27.5H21.26C20.67 27.49 18.75 27.2825 18.75 25V21.15C18.75 20.7587 18.7938 20.3738 18.8638 19.9963V19.995ZM25.565 9.98625C25.5862 9.98625 25.6038 9.99875 25.625 9.99875C26.6588 9.99875 27.5 10.84 27.5 11.8737C27.5 14.4163 25.4888 17.12 19.955 17.4613C20.2313 17.0875 20.5475 16.7412 20.9075 16.435C23.0812 14.5875 24.5737 12.1187 25.565 9.98625ZM2.5 11.8737C2.5 10.84 3.34125 9.99875 4.375 9.99875C4.39625 9.99875 4.41375 9.9875 4.43375 9.98625C5.42625 12.1187 6.91875 14.5875 9.0925 16.435C9.4525 16.7412 9.76875 17.0863 10.045 17.4613C4.51125 17.12 2.5 14.4163 2.5 11.8737ZM12.24 12.2025C11.91 11.975 11.7712 11.555 11.9025 11.175L12.5513 9.1675L10.8438 7.9175C10.435 7.6175 10.3462 7.04375 10.6462 6.635C10.8187 6.4 11.0938 6.26 11.385 6.26H13.4862L14.125 4.26875C14.2863 3.785 14.8088 3.525 15.2925 3.68625C15.5675 3.7775 15.7837 3.99375 15.875 4.26875L16.5125 6.26H18.6138C19.12 6.26 19.5312 6.67 19.5312 7.1775C19.5312 7.47125 19.3913 7.74625 19.155 7.91875L17.4475 9.16875L18.0963 11.1763C18.2513 11.6588 17.9862 12.1762 17.5025 12.3325C17.2225 12.4225 16.9163 12.3725 16.6788 12.1987L15 10.9637L13.32 12.1975C13 12.4362 12.5625 12.4375 12.24 12.2025Z"
        fill="url(#paint0_linear_13782_55189)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_13782_55189"
          x1="2"
          y1="2.98023e-08"
          x2="24"
          y2="30"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DA" />
          <stop offset="0.53125" stop-color="#FBD12E" />
          <stop offset="1" stop-color="#F47929" />
        </linearGradient>
      </defs>
    </svg>
  );
}
