import React, { useEffect } from 'react';
import {
  Card,
  Header,
  UserInfo,
  Avatar,
  Name,
  Course,
  ID,
  BirthDate,
  Confirmation,
  Button,
  Container,
} from './styled';
import { DateHelper } from 'views/StaffPages/FairAndEvents/Helpers/DateHelper';
import altAvatar from 'assets/img/placeholder.jpg';
import { Card as CardComposition } from '../../Visualization/Card';
import { CiCalendar } from 'react-icons/ci';
import { IoClose } from 'react-icons/io5';
import { FaCheck } from 'react-icons/fa6';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';
import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { StudentHandlers } from 'views/StaffPages/FairAndEvents/Handlers/StudentHandlers';

export default function StudentCheckinCard({ student, refIds, getDetail }) {
  const visualizationStudentContextInstance = useVisualizationStudentContext();
  const visualizationContextInstance = useVisualizationContext();

  if (student && Object.keys(student).length === 0) return null;

  const studentHandlerFactory = StudentHandlers(
    visualizationContextInstance,
    visualizationStudentContextInstance
  );

  const refreshStudent = () => {
    getDetail(refIds.current.eventId, refIds.current.registerId);
  };

  const handleConfirm = () => {
    const response = studentHandlerFactory.onConfirmPresenceStudent(
      student.uuid,
      student.event.id
    );
    refreshStudent();
  };

  const handleRemove = () => {
    studentHandlerFactory.onRemovePresenceStudent(
      student.uuid,
      student.presence_id
    );
    refreshStudent();
  };

  const avatar =
    student.student.avatar.length > 0
      ? student.student.avatar.length
      : altAvatar;

  const courseStudent = student.student.courses[0].name;
  const semesterStudent = student.student.courses[0].course_semester;

  return (
    <Container>
      <Card>
        <Header>
          <span>Código do ingresso: {student?.uuid}</span>
          <h4>{student?.event.name}</h4>
          <span>
            <CardComposition.Information
              icon={<CiCalendar size={17} color="#009291" />}
              text={DateHelper.formatRange(
                student.event.event_start_at,
                student.event.event_end_at
              )}
            />
          </span>
        </Header>
      </Card>
      <Card>
        <UserInfo>
          <Avatar src={avatar} alt={student?.name} />
          <Name>{student?.student.name}</Name>
          <Course>
            {courseStudent} - {semesterStudent}
          </Course>
          <ID>ID: {student?.student.external_id}</ID>
          <BirthDate>
            Data de nascimento: {DateHelper.format(student?.student.birth_date)}
          </BirthDate>
        </UserInfo>
      </Card>
      <Card>
        <Confirmation>
          Confirmar a presença do estudante:
          <div>
            <Button cancel disabled={!student?.presence} onClick={handleRemove}>
              <IoClose />
            </Button>
            <Button
              confirm
              disabled={student?.presence}
              onClick={handleConfirm}
            >
              <FaCheck size={15} />
            </Button>
          </div>
        </Confirmation>
      </Card>
    </Container>
  );
}
