import React from 'react';
import { useField } from '@unform/core';
import { useEffect, useRef } from 'react';
import { FormInput } from './style';

export default function Input({
  name,
  label,
  disabled,
  style,
  autofocus = false,
  cepError,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormInput error={error !== undefined} style={style} cepError={cepError}>
      <label>{label}</label>
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={!disabled && 'Digite aqui...'}
        disabled={disabled}
        autoFocus={autofocus}
        {...rest}
      />
      {error && <span style={{ color: '#DA0505' }}>{error}</span>}
    </FormInput>
  );
}
