import { useState, useCallback, useEffect } from 'react';
import ImageDefault from 'assets/img/imageDefault';
import ModalUploadEvidences from 'components/UploadEvidences';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import { IoEyeOutline } from 'react-icons/io5';
import { Divider } from '../styled';
import {
  WrapperImage,
  InputImage,
  InputImageSkeleton,
  TextImage,
  Title,
  Info,
  ContainerImage,
  AddImage,
  Container,
  ContainerButtons,
} from './style';
import {
  deleteImageSeal,
  postImageSeal,
  getImageSeal,
} from 'views/StaffPages/SettingsPage/services';
import PreviewModal from './PreviewModal';

export default function StudentTab({ setNoScroll }) {
  const [seal, setSeal] = useState([]);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [backgroundImageSkeleton, setBackgroundImageSkeleton] = useState(false);
  const [controlPreviewModal, setControlPreviewModal] = useState(false);

  useEffect(() => {
    (async () => await getSeal())();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setBackgroundImageSkeleton(true);
    }, 400);

    return () => clearTimeout(timeoutId);
  }, []);

  const returnBackgroundImage = (image) => {
    if (!backgroundImageSkeleton && image?.file && image?.file !== '') {
      return <InputImageSkeleton />;
    }

    if (!image || image?.file === '') {
      return (
        <InputImage>
          <ImageDefault />
        </InputImage>
      );
    }

    return <img src={image.file} />;
  };

  const handleChangeUploadModal = async () => {
    setOpenModalUpload(true);
    await getSeal();
  };

  const handleCloseUpdateModal = (files) => {
    setSeal(files);
    setOpenModalUpload(false);
    getSeal();
    setNoScroll(false);
  };

  const getSeal = useCallback(async () => {
    const response = await getImageSeal();
    if (response && response.file) {
      setSeal([response]);
    } else if (response && !response.file) {
      setSeal([]);
    }
    return response;
  }, []);

  const openPreviewModal = () => {
    setNoScroll(true);
    setControlPreviewModal(true);
  };

  return (
    <Container>
      <ModalUploadEvidences
        open={openModalUpload}
        evidenceFiles={seal}
        evidenceLinks={[]}
        acceptFormats={['file']}
        acceptFileTypes={['PNG', 'JPEG']}
        functionCloseAndUpdate={handleCloseUpdateModal}
        headerTitle={'Adicionar Imagem'}
        buttonLabel={'SALVAR'}
        limitUpload={1}
        idPost={''}
        functionPostFile={postImageSeal}
        functionDeleteEvidence={deleteImageSeal}
        uniqueUploadType={'file'}
        nameTypeDoc={false}
        ctaDownloadPreview={true}
        limitSizeDocument={2097152}
        messageLimitSize={'O tamanho máximo da imagem é de 2MB.'}
      />
      <PreviewModal
        control={controlPreviewModal}
        close={() => {
          setControlPreviewModal(false);
          setNoScroll(false);
        }}
      />

      <WrapperImage>
        <TextImage>
          <Title>Brasão</Title>
          <Info marginTop="13px">
            Adicione o brasão ou logotipo da sua Instituição de Ensino para
            exibi-lo no perfil público dos seus estudantes.
          </Info>
          <AddImage
            type="button"
            onClick={() => {
              handleChangeUploadModal();
              setNoScroll(true);
            }}
          >
            <MdOutlineAddPhotoAlternate size={23} />
            {seal[0]?.file ? 'Substituir imagem' : 'Adicionar Imagem'}
          </AddImage>
        </TextImage>
        <ContainerImage>{returnBackgroundImage(seal[0])}</ContainerImage>
      </WrapperImage>
      <Divider />

      <WrapperImage>
        <TextImage>
          <Title>Simulação</Title>
          <Info marginTop="13px">
            Veja como ficará o perfil dos estudantes.
          </Info>
        </TextImage>
        <ContainerImage>
          <ContainerButtons>
            <button
              type="button"
              className="preview-button"
              onClick={openPreviewModal}
            >
              <IoEyeOutline size={23} />
              Pré-visualizar
            </button>
          </ContainerButtons>
        </ContainerImage>
      </WrapperImage>
    </Container>
  );
}
