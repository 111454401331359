import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonCancel,
  WrapperButtons,
} from './style';
import React, { Fragment, useMemo } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import CloseButton from '../activeMatrices/closeButtonModal';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

export default function ModalInformation({
  config,
  setModalOpen,
  modalOpenState,
  buttonConfirmFunction,
  buttonCancelFunction,
}) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            <WrapperTitle>
              <IconPersonStyled src={config.icon} />
              <BubbleDialog>
                <H4Styled>{config.bubbleText}</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled>
              {ReactHtmlParser(config.ParagraphText)}
            </ParagraphStyled>
            <WrapperButtons>
              {config.showButtonCancel && (
                <ButtonCancel
                  width={config.buttonCancelWidth}
                  height={config.buttonCancelHeight}
                  onClick={() => buttonCancelFunction && buttonCancelFunction()}
                  disabled={config.loading}
                >
                  {config.buttonCancelText}
                </ButtonCancel>
              )}

              <Button
                width={config.buttonConfirmWidth}
                height={config.buttonConfirmHeight}
                onClick={() => buttonConfirmFunction && buttonConfirmFunction()}
                disabled={config.loading}
              >
                {config.loading && <FilterLoading />}
                {config.buttonConfirmText}
              </Button>
            </WrapperButtons>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

ModalInformation.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
    loading: false,
    icon: IconPerson,
  },
};
