import styled from 'styled-components';

export const Box = styled.div`
  width: calc(100% - 259px);
  right: 0;
  position: fixed;
  top: 0;
  z-index: 1301;
  background-color: #e5e5e5;
  padding: 54px 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 1199px) {
    width: calc(100% - 80px);
  }

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const CourseName = styled.div`
  width: fit-content;
  padding: 0 15px;
  height: 43px;
  top: 82px;
  left: 384px;
  background: rgba(0, 146, 145, 0.15);
  text-align: center;
  border-radius: 2px;
  gap: 10px;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  color: rgba(0, 146, 145, 1);
  margin-top: 20px;
  margin-left: 42px;
`;

export const ContainerForm = styled.div`
  background-color: #ffff;
  max-width: 1080px;
  min-width: 900px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 2rem;

  > footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
  }
`;

export const NextButton = styled.button`
  width: 106px;
  height: 40px;
  background-color: ${(props) => (props.disabled ? '#c4c4c4' : '#009291')};
  border-radius: 4px;
  border: none;
  color: #ffff;
  font-size: 14px;
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease-in-out;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-left: auto;
  }

  &:hover {
    scale: ${(props) => (props.disabled ? '1' : '1.1')};
  }
`;

export const BackButton = styled.button`
  width: 106px;
  height: 40px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #009291;
  color: #009291;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-right: auto;
  }

  &:hover {
    scale: 1.1;
  }
`;
