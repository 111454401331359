import { studentSwitcher, studentOpenRoutes } from './students';
import universityRoutes from './university';
import loginRoutes from './login';
import { companyRoutes, companyOpenRoutes } from './company';
import backofficeRoutes from './backoffice';

const routesByRole = {
  student: studentSwitcher,
  university: universityRoutes,
  company: companyRoutes,
  backoffice: backofficeRoutes,
};
const openRoutesMerged = [
  ...companyOpenRoutes,
  ...studentOpenRoutes,
  ...loginRoutes,
];
const authenticatedRoutes = (userData) => {
  // When role is not recognized we return the loginRoute as default
  if (!routesByRole.hasOwnProperty(userData.metadata.role)) {
    return openRoutesMerged;
  }

  const routesFunc = routesByRole[userData.metadata.role];
  return routesFunc(userData);
};

export { authenticatedRoutes, openRoutesMerged, loginRoutes, companyRoutes };
