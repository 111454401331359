import React, { useContext, useEffect } from 'react';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import {
  getCacheOptions,
  getCompetencies,
  getExtensionProjectOptions,
  getInterests,
  OportunitiesEducationalGroupUniversities,
  OportunitiesMacroAreas,
} from '../../services';

import { Container, PublishedOportunities } from '../../Styles/Styled';
import Modal from '../modal';

import PrincipalCards from '../PrincipalCards';
import { getUnits } from '../../../jobOpeningsRebuild/services';

export default function TabOne({
  handleIsLoadingState,
  setActiveTab,
  activeTab,
  defaultCompetence,
  handleSubmitOportunity,
  isOnModal,
  educationalGroup,
}) {
  const {
    setMacroAreas,
    setInterests,
    setCompetencies,
    setCacheOptions,
    setFormData,
    setSelectOption,
    setOptionsId,
    setStep,
    setModalIsOpen,
    setModalContent,
    setExtensionProjectOptions,
    setUnities,
    setEducationalGroupUniversities,
  } = useContext(OportunitiesContext);

  useEffect(() => {
    setModalIsOpen(false);
    setModalContent();
    setDefaultCompetence();
    setSelectOption('');
    setOptionsId('');
    setStep(0);
  }, [activeTab]);

  useEffect(() => {
    async function getAll() {
      if (educationalGroup === true) {
        const responseEducationalGroupUniversities = await OportunitiesEducationalGroupUniversities();
        setEducationalGroupUniversities(responseEducationalGroupUniversities);
      }
      const result = await OportunitiesMacroAreas(educationalGroup, '');
      setMacroAreas(result);
      const responseInterests = await getInterests();
      setInterests(responseInterests);
      const responseCompetencies = await getCompetencies();
      setCompetencies(responseCompetencies);
      const responseExtensionProjectOption = await getExtensionProjectOptions();
      setExtensionProjectOptions(responseExtensionProjectOption);
      const responseUnities = await getUnits();
      setUnities(responseUnities);

      const cachedOptions = await getCacheOptions(educationalGroup);
      setCacheOptions([
        ...cachedOptions[0],
        ...cachedOptions[1],
        ...cachedOptions[2],
      ]);
      handleIsLoadingState(false);
    }

    getAll();
  }, []);

  const setDefaultCompetence = () => {
    if (defaultCompetence) {
      return setFormData({
        competences_specialities: [
          { label: defaultCompetence.label, value: defaultCompetence.value },
        ],
        handleSubmitOportunity,
        isOnModal,
      });
    }
  };

  return (
    <Container>
      <Modal />
      <PrincipalCards setActiveTab={setActiveTab} />
      <PublishedOportunities onClick={() => setActiveTab(1)}>
        Ver aprendizagens publicadas
      </PublishedOportunities>
    </Container>
  );
}
