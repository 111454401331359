import React, { Fragment } from 'react';
import { Card, CardImgSkeleton, CardSubTitles, CardTitle } from './styles';

export default function SkeletonCardCompany ({ quantity }) {
  const numberOfCards = [];

  for (let i = 1; i <= quantity; i++) {
    numberOfCards.push(i);
  }

  const mapCards = () => {
    return numberOfCards.map((item) => (
      <Card>
        <CardImgSkeleton />
        <CardTitle />
        <CardSubTitles top={18} />
        <CardSubTitles top={20} />
      </Card>
    ));
  };

  return <Fragment>{mapCards()}</Fragment>;
}

SkeletonCardCompany.defaultProps = {
  quantity: 3,
};
