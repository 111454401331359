import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { Box, Wrapper, Modal, CloseButton, Preview } from './style';
import { IoClose } from 'react-icons/io5';
import BaseLayoutContext from 'contexts/base-layout';
import defineUrlPublicProfile from 'utils/defineUrlPublicProfile';

export default function PreviewModal({ control, close }) {
  const { metadata } = useContext(BaseLayoutContext);
  const url = defineUrlPublicProfile();
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (control) {
      setShowIframe(true);
      return () => {
        setShowIframe(false);
      };
    }
  }, [control]);

  return (
    <Wrapper visible={control}>
      <Box>
        <Modal>
          <CloseButton onClick={close}>
            <IoClose size={16} />
          </CloseButton>
          <Preview>
            {showIframe && (
              <iframe
                src={`${url}/preview?university=${metadata.university_slug}`}
                className="iframe-content"
              ></iframe>
            )}
          </Preview>
        </Modal>
      </Box>
    </Wrapper>
  );
}
