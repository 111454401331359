import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';
import FairAndEventsService from '../Services';
import { DelaySearchHelper } from '../Helpers/DelaySearchHelper';

export const FilterHandlersFactory = (
  visualizationContextInstance,
  setFilterOptions
) => {
  const onApplyFilters = async (objectFilter) => {
    const {
      paginationObject,
      currentTab,
      finishedType,
    } = visualizationContextInstance;

    /**
     * @type {Object} currentPagination
     * @property {string} next
     * @property {string} type
     * @property {function} setter
     */
    const currentPagination = paginationObject[currentTab];

    const createFilterObjectInstance = new createFilterObject();

    const [query] = createFilterObjectInstance.convert(objectFilter);

    const response = await FairAndEventsService.getListings(
      currentPagination.type,
      query,
      finishedType
    );

    currentPagination.setter((prevs) => ({
      value: {
        ...response,
        results: response.results,
      },
    }));
  };

  const onClearfilter = async () => {
    const {
      paginationObject,
      currentTab,
      finishedType,
    } = visualizationContextInstance;

    /**
     * @type {Object} currentPagination
     * @property {string} next
     * @property {string} type
     * @property {function} setter
     */
    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const response = await FairAndEventsService.getListings(
      type,
      _,
      finishedType
    );

    setter((prevs) => ({
      value: {
        ...response,
        results: response.results,
      },
    }));
  };

  const handleChangeSearchParam = (searchParam) => {
    const {
      currentTab,
      paginationObject,
      finishedType,
    } = visualizationContextInstance;

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const getWithParam = async (signal) => {
      const searchQuery = `search=${searchParam}`;
      const response = await FairAndEventsService.getListings(
        type,
        searchQuery,
        finishedType,
        signal
      );
      setter({ value: response });
      return;
    };

    DelaySearchHelper.delay(getWithParam, 600);
  };

  const handleMacroAreaChange = async (value) => {
    const response = await FairAndEventsService.getOptionsFilter(
      `associated_areas=${value}`
    );
    setFilterOptions((prevs) => ({
      ...prevs,
      ...response,
    }));
  };

  return {
    handleChangeSearchParam,
    onApplyFilters,
    onClearfilter,
    handleMacroAreaChange,
  };
};
