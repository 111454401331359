import React, { useEffect, useContext, useState, useRef } from 'react';
import LogosAnimated from '../UIComponents/LogosAnimated';
import { Form } from '@unform/web';
import {
  getInfoStudent,
  getUniversityLogos,
} from 'services/login/authenticate';
import { login } from 'services/login/authenticate';
import { errors } from 'constants/errors';

import AuthContext from 'contexts/authentication';

import { IModalConfigNegativeLogin } from 'views/LoginPage/useCases/IModalConfig';

import ModalFail from 'views/LoginPage/components/informationModal';

import LoginForgotPassword from './ForgotPassword';

import {
  Container,
  ContainerLogin,
  ContainerAllLogos,
  Titles,
  Paragraph,
  ButtonPassword,
  ButtonLogin,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  BoxLogos,
  Footer,
  ContainerImage,
  ContainerSubTitles,
} from '../styles';
import InputUsernameLogin from '../UIComponents/InputUsername';
import InputPasswordLogin from '../UIComponents/InputPassword';
import EmailPassword from './BaseLogin/EmailPassword';
import verifyExistUniversity from 'services/student/verifyExistUniversity';
import urls from 'constants/urls';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import ImageSkeleton from '../styles/ImageSkeleton';
import BaseLayoutContext from 'contexts/base-layout';

import {
  verifyRecoverToken,
  confirmNewPassword,
} from 'services/login/recoverPassword';

export default function LoginStudent({
  match,
  handleIsLoadingState,
  type,
  university,
  history,
  isRecovery = false,
}) {
  const [modalFailOpenState, setModalFailOpenState] = useState(false);
  const { routerOnUserLogin } = useContext(AuthContext);
  const formLoginStudent = useRef();
  const formRecoveryStudent = useRef();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [contentLogin, setContentLogin] = useState(true);
  const [logo, setLogo] = useState(null);
  const [universityColor, setUniversityColor] = useState('');
  const [modalEmailSent, setModalEmailSent] = useState(false);
  const [email, setEmail] = useState(null);
  const [dataInfos, setDataInfos] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [skeleton, setSkeleton] = useState(false);

  const { openSnackbar } = useContext(BaseLayoutContext);

  const [token, setToken] = useState('');
  const [tokenExpired, setTokenExpired] = useState(false);
  const [recoveryErrors, setRecoveryErros] = useState({
    password: '',
    confirmPassword: '',
  });

  const universityName = match.params.universidade ?? university;
  const urlLogin = match.params.universidade
    ? match.path
    : '/login/:universidade/aluno';

  const getLogo = async (param) => {
    if (universityColor === '') {
      const response = await getUniversityLogos(param);
      setUniversityColor(response.data.university_color);
      setLogo(response.data.logo);
    }
  };

  const fetchInfos = async (typeLogin) => {
    if (typeLogin) {
      try {
        setSkeleton(true);
        const response = await getInfoStudent(universityName, typeLogin);
        setDataInfos(response);
        setSkeleton(false);
      } catch (err) {
        setSkeleton(false);
        setDataInfos(null);
      }
    }
  };

  const confirmRecovery = async (data) => {
    setRecoveryErros({});
    const validatePasswordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    if (!validatePasswordRegex.test(data.password)) {
      setRecoveryErros({
        password:
          'A senha deve conter no mínimo 8 caracteres, uma letra e um número.',
      });
      return;
    }
    if (data.password !== data.confirmPassword) {
      setRecoveryErros({ confirmPassword: 'As senhas devem ser iguais.' });
      return;
    }
    try {
      const response = await confirmNewPassword({ ...data, token });
      openSnackbar('Senha alterada com sucesso', false);
      if (response.status.toLocaleLowerCase() === 'ok') {
        const slug = new URLSearchParams(
          window.location.hash.split('?')[1]
        ).get('slug');
        history.push({
          pathname: `/login/${slug}/aluno`,
          state: {
            recoverSuccess: true,
          },
        });
      }
    } catch (e) {
      openSnackbar('Falha ao alterar senha, tente novamente.', true);
    }
  };

  useEffect(() => {
    if (isRecovery) {
      const token = new URLSearchParams(window.location.hash.split('?')[1]).get(
        'token'
      );
      const slug = new URLSearchParams(window.location.hash.split('?')[1]).get(
        'slug'
      );
      if (!token || token == null) {
        history.push(`/login/${slug}/aluno`);
      }
      setToken(token);
      (async () => {
        try {
          await verifyRecoverToken({ token });
        } catch (e) {
          setTokenExpired(true);
          handleForgotPasswordClick();
        }
      })();
    }
    if (history.location?.state?.recoverSuccess) {
      openSnackbar('Senha alterada com sucesso');
    }
  }, []);

  useEffect(() => {
    !isRecovery &&
      (async () => {
        const exists = await verifyExistUniversity(universityName);
        !exists && window.open(urls.URL_FRONT, '_self');
      })();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const id = searchParams.get('studentNumber');
    if (id) {
      formLoginStudent.current.setFieldValue('username', id);
    }
  }, [history]);

  
  useEffect(() => {
    if (universityName) {
      getLogo(universityName);
    } else {
      const slug = new URLSearchParams(window.location.hash.split('?')[1]).get(
        'slug'
      );
      getLogo(slug);
    }
  }, [logo]);

  useEffect(() => {
    fetchInfos(type);
  }, [universityName]);

  function handleForgotPasswordClick() {
    setShowForgotPassword(true);
    setContentLogin(false);
  }

  const handleLogin = async (data) => {
    const { username, password, email } = data;
    handleIsLoadingState(true);

    const dataLogin = {
      university: universityName,
      username,
      password,
      email,
      university: universityName,
    };

    let loginStudent = await login(urlLogin, dataLogin);
    if (loginStudent.isLogged) {
      if (match.params?.universidade && match.params?.id) {
        loginStudent.data['params'] = match.params;
      }
      if (match.params.eventId) {
        loginStudent.data['params'] = match.params;
      }
      routerOnUserLogin(
        loginStudent.data,
        undefined,
        window.location.href.split('?')[1]
      );
    } else {
      if (loginStudent.data.detail) {
        setModalFailOpenState(true);
        handleIsLoadingState(false);
        return;
      }
      const message = errors.hasOwnProperty(loginStudent.data.code)
        ? errors[loginStudent.data.code]
        : 'Não foi possível realizar o seu login. Tente novamente';

      handleIsLoadingState(false);
      setErrorMessage(dataInfos?.password_hint);
      customSnackbar(`${message}`, 'error');
    }
  };

  const closeModalFail = () => {
    setModalFailOpenState(false);
  };

  const returnImageLogin = (image) => {
    if (skeleton === true) {
      return <ImageSkeleton />;
    }

    if (image) {
      return <ContainerImage image={image} />;
    }

    if (!image) {
      return (
        <ContainerAllLogos>
          <LogosAnimated color={universityColor} />
          <div>
            <Titles isLogo lineHeightMobile="27px">
              <strong>Prepare-se para o novo mundo do trabalho.</strong>
            </Titles>
            <Paragraph>
              Conquiste o autoconhecimento, amplie seu potencial e independência
              para fazer escolhas em sua carreira.
            </Paragraph>
          </div>
        </ContainerAllLogos>
      );
    }
  };

  return (
    <Container color={universityColor}>
      <BoxLogos>{returnImageLogin(dataInfos?.image?.file)}</BoxLogos>
      <ContainerLogin contentImage={dataInfos?.image?.file}>
        {contentLogin === false && showForgotPassword && (
          <LoginForgotPassword
            showForgotPassword={showForgotPassword}
            setShowForgotPassword={setShowForgotPassword}
            isStudentLogin={true}
            setContentLogin={setContentLogin}
            universityColor={universityColor}
            setModalEmailSent={setModalEmailSent}
            setEmail={setEmail}
            logo={logo}
            universityName={universityName}
            expiredToken={tokenExpired}
          />
        )}

        {modalEmailSent && (
          <EmailPassword
            email={email}
            universityColor={universityColor}
            modalEmailSent={modalEmailSent}
            setModalEmailSent={setModalEmailSent}
            setContentLogin={setContentLogin}
            setEmail={setEmail}
          />
        )}
        {contentLogin && showForgotPassword === false && (
          <FragmentContainer>
            <ContainerInCenter isStudent>
              <img src={logo} alt="" />
            </ContainerInCenter>
            {!isRecovery && (
              <Titles color={'#606062'}>
                <p>Seja bem-vindo!</p>
                <p>Faça login em sua conta.</p>
              </Titles>
            )}
            {isRecovery && (
              <>
                <Titles isRecovery>
                  <p>Nova senha</p>
                </Titles>
                <ContainerSubTitles>
                  <p>Preencha os campos abaixo com a sua nova senha.</p>
                </ContainerSubTitles>
              </>
            )}
            {!isRecovery && (
              <div className="form">
                <Form ref={formLoginStudent} onSubmit={handleLogin}>
                  <InputUsernameLogin
                    name="username"
                    label="Usuário"
                    helpText={dataInfos?.login_hint}
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                  />

                  <InputPasswordLogin
                    name="password"
                    label="Senha"
                    errorMessage={errorMessage}
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                    buttonShowPassword
                    onClick={() => setErrorMessage(null)}
                  />
                  <span></span>
                  <ButtonPassword
                    color={universityColor}
                    onClick={handleForgotPasswordClick}
                    type="button"
                  >
                    Esqueceu sua senha?
                  </ButtonPassword>

                  <ContainerInCenter>
                    <ButtonLogin color={universityColor} type="submit">
                      Entrar
                    </ButtonLogin>
                  </ContainerInCenter>
                </Form>
              </div>
            )}
            {isRecovery && (
              <div className="form">
                <Form ref={formRecoveryStudent} onSubmit={confirmRecovery}>
                  <InputPasswordLogin
                    name="password"
                    label="Senha"
                    type="password"
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                    errorMessage={recoveryErrors.password}
                    buttonShowPassword
                  />

                  <InputPasswordLogin
                    name="confirmPassword"
                    label="Confirmar Senha"
                    type="password"
                    universityColor={universityColor}
                    placeholder="Digite aqui..."
                    errorMessage={recoveryErrors.confirmPassword}
                    buttonShowPassword
                  />
                  <span></span>
                  <ButtonLogin color={universityColor} type="submit">
                    Confirmar
                  </ButtonLogin>
                </Form>
              </div>
            )}
          </FragmentContainer>
        )}
        <ContainerWorkalove>
          <Footer>
            By <strong>Workalove</strong>
          </Footer>
        </ContainerWorkalove>

        <ModalFail
          universityColor={universityColor}
          modalFailOpenState={modalFailOpenState}
          config={IModalConfigNegativeLogin}
          buttonFunction={closeModalFail}
        />
      </ContainerLogin>
    </Container>
  );
}
