/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Formik, FastField } from 'formik';
import { func, bool, shape, array } from 'prop-types';

import { maskCnpj } from 'utils/inputMasks';
import { payloadIntegratorAdapter } from 'adapters/university/companyInviteAdapter';

import {
  FormHelperText,
  Grid,
  TextField,
  List,
  ListItem,
} from '@material-ui/core';

import { companyPreRegister } from 'services/companies';

import ZipCodeForm from 'components/ZipCodeForm/ZipCodeForm';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import Button from 'components/CustomButtons/Button';
import { companyPreRegisterSchema } from './PartnerRegistrationValidationSchema';

const PartnerRegistrationForm = ({
  handleIsLoadingStateFn,
  openSnackbarFn,
  closeModalFn,
  classes,
  companies,
  setCompanies,
  setCount,
}) => {
  const initialValues = {
    id: null,
    companyName: '',
    organizationNumber: '',
    zipCode: '',
    city: '',
    street: '',
    addressNumber: '',
    addressState: '',
    district: '',
    complement: '',
    send_invite_email: true,
    jobOwnerCompany: false,
  };

  const [fieldId, setFieldId] = useState('');

  const getFieldId = (id) => {
    setFieldId(id);
  };

  const handleFieldMask = (event, mask) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      return event.target.value;
    }
    const maskedValue = mask(event.target.value);
    return maskedValue;
  };

  const handleCompanyCreation = async (values) => {
    if (values) {
      const response = await companyPreRegister(values);
      return response;
    }
    return null;
  };

  return (
    <Grid
      container
      className={classes.fullScreenGridContainer}
      justify="center"
    >
      <Grid item xs md={12} className={classes.fullScreenGrid}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={companyPreRegisterSchema}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            handleIsLoadingStateFn(true);
            const newCompany = await handleCompanyCreation(
              payloadIntegratorAdapter(values)
            );
            if (newCompany.message) {
              openSnackbarFn(
                `Erro ao gravar seus dados. Se o erro persistir, entre em contato com o suporte avisando sobre o erro: ${newCompany.message}`,
                true
              );
              closeModalFn();
              actions.setSubmitting(false);
              handleIsLoadingStateFn(false);
            } else {
              if (companies) {
                const newCompanyFormatted = {
                  name: newCompany.name,
                  organization_number: newCompany.organization_number,
                  address: { city: newCompany.city, state: newCompany.state },
                };
                setCompanies([newCompanyFormatted, ...companies]);
                setCount(companies.length + 1);
              }
              actions.setSubmitting(false);
              setTimeout(() => {
                closeModalFn();
                openSnackbarFn('Empresa salva com sucesso!');
              }, 700);
              handleIsLoadingStateFn(false);
            }
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldError,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form
              onSubmit={handleSubmit}
              onChange={(event) => {
                getFieldId(event.target.id);
              }}
            >
              <fieldset
                style={{
                  border: 'none',
                }}
              >
                <List className={classes.list}>
                  <ListItem className={classes.listItem}>
                    <FastField
                      autoFocus
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      name="companyName"
                      variant="filled"
                      id="companyName"
                      label="Razão social"
                      placeholder="Ex.: Workalove"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!errors.companyName && !!touched.companyName}
                      value={values.companyName}
                      component={TextField}
                    />
                    {errors.companyName && touched.companyName && (
                      <FormHelperText className={classes.helperText}>
                        {errors.companyName}
                      </FormHelperText>
                    )}
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <FastField
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      name="organizationNumber"
                      variant="filled"
                      id="organizationNumber"
                      label="CNPJ"
                      placeholder="Ex.: 74.449.408/0001-81"
                      onKeyUp={(event) => {
                        handleFieldMask(event, maskCnpj);
                        handleChange(event);
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        !!errors.organizationNumber &&
                        !!touched.organizationNumber
                      }
                      value={values.organizationNumber}
                      component={TextField}
                    />
                    {!!errors.organizationNumber &&
                      !!touched.organizationNumber && (
                        <FormHelperText className={classes.helperText}>
                          {errors.organizationNumber}
                        </FormHelperText>
                      )}
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ZipCodeForm
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      isSubmitting={isSubmitting}
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <Grid container>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className={classes.saveButton}
                        round
                      >
                        Salvar
                      </Button>
                    </Grid>
                  </ListItem>
                </List>
              </fieldset>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

PartnerRegistrationForm.propTypes = {
  classes: shape.isRequired,
  closeModalFn: func.isRequired,
  companies: array.isRequired,
  setCompanies: func.isRequired,
  setCount: func.isRequired,
  openDnaDialogFn: func.isRequired,
  jobRegistration: bool.isRequired,
  openSnackbarFn: func.isRequired,
  handleIsLoadingStateFn: func.isRequired,
};
export default withStyles(ProfessionalExperienceFormStyles)(
  PartnerRegistrationForm
);
