import React, { useState, useEffect } from 'react';
import {
  ContainerInformations,
  CardHeader,
  HeaderTitle,
  CardBodyMobile,
  EmptyText,
  ContainerAcademic,
  Divider,
  ButtonSeeMore,
  ContainerEmptyText,
  CardBodySubTitle,
} from './styled';
import UtilsAcademic from './utils/UtilsAcademic';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';
import GenericModalInformations from './utils/ModalInformations';
import useMediaQuery from 'hooks/useMediaQuery';

export function AcademicInformations(props) {
  const { academic_profile, metadata } = props;
  const { IconArrowEdit } = wrapperIconsProfile();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const valueGender = academic_profile.gender.value;
  const [genderValue, setGenderValue] = useState(valueGender);
  const tabletView = useMediaQuery('(min-width: 850px)');

  const countCourses = academic_profile.courses.length;

  useEffect(() => {
    setGenderValue(valueGender);
  }, [valueGender]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const returnContentMobile = () => {
    return (
      <>
        <Divider />
        <ButtonSeeMore onClick={() => openModal()}>
          Visualizar <IconArrowEdit color={'#606062'} />
        </ButtonSeeMore>
      </>
    );
  };

  const returnContent = (countCourses) => {
    if (countCourses === 0) {
      return returnEmptyText();
    }

    return (
      <>
        <UtilsAcademic
          metadata={metadata}
          academicProfile={academic_profile.courses}
        />
      </>
    );
  };

  const returnEmptyText = () => {
    return (
      <ContainerEmptyText>
        <EmptyText align={tabletView}>
          Informações acadêmicas não disponíveis.
          {tabletView && 'Consulte sua instituição de ensino.'}
        </EmptyText>
      </ContainerEmptyText>
    );
  };

  return (
    <>
      {modalIsOpen && (
        <GenericModalInformations
          isOpen={modalIsOpen}
          closeModal={closeModal}
          title={'Informações Acadêmicas'}
          handleEditInfo={() => onClickButton()}
        >
          {returnContent()}
        </GenericModalInformations>
      )}

      <ContainerInformations>
        <CardHeader>
          <ContainerAcademic>
            <HeaderTitle hasMarginRight>Informações acadêmicas</HeaderTitle>
          </ContainerAcademic>
        </CardHeader>

        {tabletView && returnContent(countCourses)}

        {!tabletView && countCourses === 0 ? (
          returnEmptyText()
        ) : (
          <CardBodyMobile>{returnContentMobile()}</CardBodyMobile>
        )}
      </ContainerInformations>
    </>
  );
}
