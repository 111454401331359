import React, { useContext, useEffect, useRef } from 'react';

import {
  Container,
  Box,
  Content,
  WrapperTitle,
  ContainerButton,
  WrapperMessage,
  WhiteButton,
  GreenButton,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { JobOfferContext } from '../../../../Contexts/JobOfferContext';

import { unPublishJob } from '../../../../services';

export default function UnpublishModal(props) {
  const { isOpen, setModalUnpublish, jobID, limit } = props;

  const { dataFunction, offSet } = useContext(JobOfferContext);

  const modalRef = useRef();

  const closeModalClick = (e) => {
    if (modalRef.current === e.target) {
      setModalUnpublish(false);
    }
  };

  const closeModal = () => {
    setModalUnpublish(false);
  };

  const confirmUnpublish = async (id) => {
    await unPublishJob(id);
    dataFunction.handlePageChange(`limit=${limit}offset=${offSet}`);
    setModalUnpublish(false);
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <Box ref={modalRef} onClick={closeModalClick}>
        <Content size="unpublish">
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <div className="bubble-dialog">
              <span> Atenção! </span>
            </div>
          </WrapperTitle>
          <WrapperMessage>
            Esta ação não poderá ser desfeita, tem certeza que deseja
            despublicar esta vaga?
          </WrapperMessage>
          <ContainerButton>
            <WhiteButton
              onClick={() => confirmUnpublish(jobID)}
              size="unpublish"
            >
              SIM, QUERO DESPUBLICAR
            </WhiteButton>
            <GreenButton onClick={closeModal}>NÃO DESPUBLICAR</GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  ) : null;
}
