export const AddIcon = (props) => {
  const { size, color } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="21.0459"
        y="8.45456"
        width="3.09091"
        height="20.0909"
        rx="1.54545"
        transform="rotate(90 21.0459 8.45456)"
        fill={color}
      />
      <rect
        x="21.0459"
        y="8.45456"
        width="3.09091"
        height="20.0909"
        rx="1.54545"
        transform="rotate(90 21.0459 8.45456)"
        fill={color}
      />
      <rect
        x="9.45508"
        y="0.727295"
        width="3.09091"
        height="18.5455"
        rx="1.54545"
        fill={color}
      />
      <rect
        x="9.45508"
        y="0.727295"
        width="3.09091"
        height="18.5455"
        rx="1.54545"
        fill={color}
      />
    </svg>
  );
};
