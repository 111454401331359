import React, { useEffect } from 'react';
import CompanyJobsOpening from './Components/CompanyJobsOpenings';
import JobOfferProvider from './Contexts/JobOfferContext';

export default function CompanyPrincipalEngine({
  handleIsLoadingState,
  metadata,
}) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const { role, can_see_job_offers_match } = metadata;

  return (
    <JobOfferProvider>
      <CompanyJobsOpening role={role} seeMatch={can_see_job_offers_match} />
    </JobOfferProvider>
  );
}
