import {
  ListLearnings,
  WrapperDiv,
} from 'views/StaffPages/Opportunities/Styles/PublishedOpportunities';
import PublishedCard from '../PublishedCards';
import { useContext, useRef, useEffect } from 'react';
import { Container } from './TabStyleBase';
import {
  CardSubTitle,
  CardTitle,
} from 'components/Opportunities/styles/defaultStyled';
import { useEducationalGroupLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsEducationalGroup';
import { ButtonsPagination } from '../ButtonsPagination';
import {
  scrollLefEducationalGroupEbooks,
  scrollLefEducationalGrouptSeries,
  scrollLeftEducationalGroupAnnouncements,
  scrollLeftEducationalGroupArticle,
  scrollLeftEducationalGroupChallenges,
  scrollLeftEducationalGroupCourses,
  scrollLeftEducationalGroupEvents,
  scrollLeftEducationalGroupPrograms,
  scrollLeftEducationalGroupTrails,
  scrollRigthEducationalGroupAnnouncements,
  scrollRigthEducationalGroupArticle,
  scrollRigthEducationalGroupChallenges,
  scrollRigthEducationalGroupCourses,
  scrollRigthEducationalGroupEbooks,
  scrollRigthEducationalGroupEvents,
  scrollRigthEducationalGroupPrograms,
  scrollRigthEducationalGroupSeries,
  scrollRigthEducationalGroupTrails,
} from 'views/StaffPages/Opportunities/utils/LogicScroll/scrollPaginationByTheGroup';
import { ListPublishedByTheGroupContext } from 'views/StaffPages/Opportunities/Context/ListPublishedByTheGroupContext';
import { formatNumber } from 'utils/formatNumber';

export default function LearningPublishedByTheGroup(props) {
  const { setActiveTab, educationalGroup, activeInternalTab } = props;

  const {
    educationalGroupEventsCount,
    educationalGroupCoursesCount,
    educationalGroupProgramsCount,
    educationalGroupAnnouncementsCount,
    educationalGroupTrailsCount,
    educationalGroupEbooksCount,
    educationalGroupArticlesCount,
    educationalGroupChallengesCount,
    educationalGroupSeriesCount,
    currentPageEducationalGroupEvents,
    currentPageEducationalGroupCourses,
    currentPageEducationalGroupPrograms,
    currentPageEducationalGroupTrails,
    currentPageEducationalGroupEbooks,
    currentPageEducationalGroupAnnouncements,
    currentPageEducationalGroupArticles,
    currentPageEducationalGroupChallenges,
    currentPageEducationalGroupSeries,

    educationalGroupEvents,
    educationalGroupCourses,
    educationalGroupPrograms,
    educationalGroupCommunicate,
    educationalGroupTrails,
    educationalGroupEbooks,
    educationalGroupArticle,
    educationalGroupChallenge,
    setEducationalGroupEvents,
    setEducationalGroupCourses,
    setEducationalGroupPrograms,
    setEducationalGroupCommunicate,
    setEducationalGroupTrails,
    setEducationalGroupEbooks,
    setEducationalGroupArticle,
    setEducationalGroupChallenge,
    educationalGroupSerie,
    setEducationalGroupSerie,
  } = useContext(ListPublishedByTheGroupContext);

  const ref = useRef(null);

  const {
    getEducationalGroupAllEvents,
    getEducationalGroupAllCourses,
    getEducationalGroupAllProgram,
    getEducationalGroupAllAnnouncements,
    getEducationalGroupAllTrails,
    getEducationalGroupAllEbooks,
    getEducationalGroupAllArticles,
    getEducationalGroupAllChallenges,
    getEducationalGroupAllSeries,
  } = useEducationalGroupLearningAPICalls();

  useEffect(() => {
    setEducationalGroupEvents(null);
    setEducationalGroupCourses(null);
    setEducationalGroupPrograms(null);
    setEducationalGroupCommunicate(null);
    setEducationalGroupTrails(null);
    setEducationalGroupEbooks(null);
    setEducationalGroupArticle(null);
    setEducationalGroupChallenge(null);
    setEducationalGroupSerie(null);
  }, [activeInternalTab]);

  const educationalGroupEventSentinel = useRef();
  const educationalGroupCoursesSentinel = useRef();
  const educationalGroupProgramsSentinel = useRef();
  const educationalGroupAnnouncementsSentinel = useRef();
  const educationalGroupTrailsSentinel = useRef();
  const educationalGroupEbookSentinel = useRef();
  const educationalGroupArticleSentinel = useRef();
  const educationalGroupChallengeSentinel = useRef();
  const educationalGroupSeriesSentinel = useRef();

  // Event Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupEvents !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupEvents.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupEvents.length > 7) {
            intersectionObserver?.disconnect();
            if (educationalGroupEvents.length < educationalGroupEventsCount) {
              getEducationalGroupAllEvents(
                false,
                currentPageEducationalGroupEvents + 8
              );
            }
          }
        }
      });
      if (educationalGroupEvents?.length === educationalGroupEventsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupEvents.length > 0 &&
        educationalGroupEventSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupEventSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupEvents, educationalGroupEventsCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllEvents();
    } else {
      setEducationalGroupEvents(null);
    }
  }, [activeInternalTab]);

  // Course Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupCourses !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupCourses.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupCourses.length > 7) {
            intersectionObserver?.disconnect();
            if (educationalGroupCourses.length < educationalGroupCoursesCount) {
              getEducationalGroupAllCourses(
                false,
                currentPageEducationalGroupCourses + 8
              );
            }
          }
        }
      });
      if (educationalGroupCourses?.length === educationalGroupCoursesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupCourses.length > 0 &&
        educationalGroupCoursesSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupCoursesSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupCourses, educationalGroupCoursesCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllCourses();
    } else {
      setEducationalGroupCourses(null);
    }
  }, [activeInternalTab]);

  // Programs Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupPrograms !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupPrograms.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupPrograms.length > 7) {
            intersectionObserver?.disconnect();
            if (
              educationalGroupPrograms.length < educationalGroupProgramsCount
            ) {
              getEducationalGroupAllProgram(
                false,
                currentPageEducationalGroupPrograms + 8
              );
            }
          }
        }
      });
      if (educationalGroupPrograms?.length === educationalGroupProgramsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupPrograms.length > 0 &&
        educationalGroupProgramsSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupProgramsSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupPrograms, educationalGroupProgramsCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllProgram();
    } else {
      setEducationalGroupPrograms(null);
    }
  }, [activeInternalTab]);

  // Communicate Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupCommunicate !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupCommunicate.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupCommunicate.length > 7) {
            intersectionObserver?.disconnect();
            if (
              educationalGroupCommunicate.length <
              educationalGroupAnnouncementsCount
            ) {
              getEducationalGroupAllAnnouncements(
                false,
                currentPageEducationalGroupAnnouncements + 8
              );
            }
          }
        }
      });
      if (
        educationalGroupCommunicate?.length ===
        educationalGroupAnnouncementsCount
      ) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupCommunicate.length > 0 &&
        educationalGroupAnnouncementsSentinel.current
      ) {
        intersectionObserver?.observe(
          educationalGroupAnnouncementsSentinel.current
        );
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupCommunicate, educationalGroupAnnouncementsCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllAnnouncements();
    } else {
      setEducationalGroupCommunicate(null);
    }
  }, [activeInternalTab]);

  // Trails Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupTrails !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupTrails.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupTrails.length > 7) {
            intersectionObserver?.disconnect();
            if (educationalGroupTrails.length < educationalGroupTrailsCount) {
              getEducationalGroupAllTrails(
                false,
                currentPageEducationalGroupTrails + 8
              );
            }
          }
        }
      });
      if (educationalGroupTrails?.length === educationalGroupTrailsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupTrails.length > 0 &&
        educationalGroupTrailsSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupTrailsSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupTrails, educationalGroupTrailsCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllTrails();
    } else {
      setEducationalGroupTrails(null);
    }
  }, [activeInternalTab]);

  // Ebooks Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupEbooks !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupEbooks.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupEbooks.length > 7) {
            intersectionObserver?.disconnect();
            if (educationalGroupEbooks.length < educationalGroupEbooksCount) {
              getEducationalGroupAllEbooks(
                false,
                currentPageEducationalGroupEbooks + 8
              );
            }
          }
        }
      });
      if (educationalGroupEbooks?.length === educationalGroupEbooksCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupEbooks.length > 0 &&
        educationalGroupEbookSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupEbookSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupEbooks, educationalGroupEbooksCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllEbooks();
    } else {
      setEducationalGroupEbooks(null);
    }
  }, [activeInternalTab]);

  // Article Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupArticle !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupArticle.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupArticle.length > 7) {
            intersectionObserver?.disconnect();
            if (
              educationalGroupArticle.length < educationalGroupArticlesCount
            ) {
              getEducationalGroupAllArticles(
                false,
                currentPageEducationalGroupArticles + 8
              );
            }
          }
        }
      });
      if (educationalGroupArticle?.length === educationalGroupArticlesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupArticle.length > 0 &&
        educationalGroupArticleSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupArticleSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupArticle, educationalGroupArticlesCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllArticles();
    } else {
      setEducationalGroupArticle(null);
    }
  }, [activeInternalTab]);

  // Challenge Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupChallenge !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupChallenge.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupChallenge.length > 7) {
            intersectionObserver?.disconnect();
            if (
              educationalGroupChallenge.length < educationalGroupChallengesCount
            ) {
              getEducationalGroupAllChallenges(
                false,
                currentPageEducationalGroupChallenges + 8
              );
            }
          }
        }
      });
      if (
        educationalGroupChallenge?.length === educationalGroupChallengesCount
      ) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupChallenge.length > 0 &&
        educationalGroupChallengeSentinel.current
      ) {
        intersectionObserver?.observe(
          educationalGroupChallengeSentinel.current
        );
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupChallenge, educationalGroupChallengesCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllChallenges();
    } else {
      setEducationalGroupChallenge(null);
    }
  }, [activeInternalTab]);

  // Series Carousel
  useEffect(() => {
    let intersectionObserver;
    if (educationalGroupSerie !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (educationalGroupSerie.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (educationalGroupSerie.length > 7) {
            intersectionObserver?.disconnect();
            if (educationalGroupSerie.length < educationalGroupSeriesCount) {
              getEducationalGroupAllSeries(
                false,
                currentPageEducationalGroupSeries + 8
              );
            }
          }
        }
      });
      if (educationalGroupSerie?.length === educationalGroupSeriesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (
        educationalGroupSerie.length > 0 &&
        educationalGroupSeriesSentinel.current
      ) {
        intersectionObserver?.observe(educationalGroupSeriesSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [educationalGroupSerie, educationalGroupSeriesCount]);

  useEffect(() => {
    if (educationalGroup && activeInternalTab === 0) {
      getEducationalGroupAllSeries();
    } else {
      setEducationalGroupSerie(null);
    }
  }, [activeInternalTab]);

  return (
    <Container>
      <WrapperDiv ref={ref}>
        <CardTitle>
          Eventos ({formatNumber(educationalGroupEventsCount)}) <br />
          <CardSubTitle>Veja aqui os eventos publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-event-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupEvents}
            referencia={ref}
            title={`Eventos`}
            subTitle={'Veja aqui os eventos publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupEvents?.length >= 8 &&
            educationalGroupEvents?.length !== educationalGroupEventsCount && (
              <li ref={educationalGroupEventSentinel} />
            )}
        </ListLearnings>
        {educationalGroupEvents?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupEvents}
            funcButtonRight={scrollRigthEducationalGroupEvents}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Cursos ({formatNumber(educationalGroupCoursesCount)})<br />
          <CardSubTitle>Veja aqui os cursos publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-courses-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupCourses}
            referencia={ref}
            title={`Cursos`}
            subTitle={'Veja aqui os cursos publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupCourses?.length >= 8 &&
            educationalGroupCourses?.length !==
              educationalGroupCoursesCount && (
              <li ref={educationalGroupCoursesSentinel} />
            )}
        </ListLearnings>
        {educationalGroupCourses?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupCourses}
            funcButtonRight={scrollRigthEducationalGroupCourses}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Programas ({formatNumber(educationalGroupProgramsCount)})<br />
          <CardSubTitle>Veja aqui os programas publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-programs-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupPrograms}
            referencia={ref}
            title={`Programas`}
            subTitle={'Veja aqui os programas publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupPrograms?.length >= 8 &&
            educationalGroupPrograms?.length !==
              educationalGroupProgramsCount && (
              <li ref={educationalGroupProgramsSentinel} />
            )}
        </ListLearnings>
        {educationalGroupPrograms?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupPrograms}
            funcButtonRight={scrollRigthEducationalGroupPrograms}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Comunicados ({formatNumber(educationalGroupAnnouncementsCount)})<br />
          <CardSubTitle>Veja aqui os comunicados publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-announcements-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupCommunicate}
            referencia={ref}
            title={`Comunicados`}
            subTitle={'Veja aqui os comunicados publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupCommunicate?.length >= 8 &&
            educationalGroupCommunicate?.length !==
              educationalGroupAnnouncementsCount && (
              <li ref={educationalGroupAnnouncementsSentinel} />
            )}
        </ListLearnings>
        {educationalGroupCommunicate?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupAnnouncements}
            funcButtonRight={scrollRigthEducationalGroupAnnouncements}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Trilhas ({formatNumber(educationalGroupTrailsCount)})<br />
          <CardSubTitle>Veja aqui as trilhas publicadas</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-trails-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupTrails}
            referencia={ref}
            title={`Trilhas`}
            subTitle={'Veja aqui as trilhas publicadas'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupTrails?.length >= 8 &&
            educationalGroupTrails?.length !== educationalGroupTrailsCount && (
              <li ref={educationalGroupTrailsSentinel} />
            )}
        </ListLearnings>
        {educationalGroupTrails?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupTrails}
            funcButtonRight={scrollRigthEducationalGroupTrails}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          E-books ({formatNumber(educationalGroupEbooksCount)})<br />
          <CardSubTitle>Veja aqui os e-books publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-ebooks-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupEbooks}
            referencia={ref}
            title={`E-books`}
            subTitle={'Veja aqui os e-books publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupEbooks?.length >= 8 &&
            educationalGroupEbooks?.length !== educationalGroupEbooksCount && (
              <li ref={educationalGroupEbookSentinel} />
            )}
        </ListLearnings>
        {educationalGroupEbooks?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLefEducationalGroupEbooks}
            funcButtonRight={scrollRigthEducationalGroupEbooks}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Artigos ({formatNumber(educationalGroupArticlesCount)})<br />
          <CardSubTitle>Veja aqui os artigos publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-article-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupArticle}
            referencia={ref}
            title={`Artigos`}
            subTitle={'Veja aqui os artigos publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupArticle?.length >= 8 &&
            educationalGroupArticle?.length !==
              educationalGroupArticlesCount && (
              <li ref={educationalGroupArticleSentinel} />
            )}
        </ListLearnings>
        {educationalGroupArticle?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupArticle}
            funcButtonRight={scrollRigthEducationalGroupArticle}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Desafios ({formatNumber(educationalGroupChallengesCount)})<br />
          <CardSubTitle>Veja aqui os desafios publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-challenge-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupChallenge}
            referencia={ref}
            title={`Desafios`}
            subTitle={'Veja aqui os desafios publicados'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupChallenge?.length >= 8 &&
            educationalGroupChallenge?.length !==
              educationalGroupChallengesCount && (
              <li ref={educationalGroupChallengeSentinel} />
            )}
        </ListLearnings>
        {educationalGroupChallenge?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEducationalGroupChallenges}
            funcButtonRight={scrollRigthEducationalGroupChallenges}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Séries ({formatNumber(educationalGroupSeriesCount)})<br />
          <CardSubTitle>Veja aqui as séries publicadas</CardSubTitle>
        </CardTitle>
        <ListLearnings id="educational-group-series-container">
          <PublishedCard
            learningPublishedByTheGroup={true}
            visionGroup={educationalGroup}
            array={educationalGroupSerie}
            referencia={ref}
            title={'Séries'}
            subTitle={'Veja aqui as séries publicadas'}
            setActiveTab={setActiveTab}
          />
          {educationalGroupSerie?.length >= 8 &&
            educationalGroupSerie?.length !== educationalGroupSeriesCount && (
              <li ref={educationalGroupSeriesSentinel} />
            )}
        </ListLearnings>
        {educationalGroupSerie?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLefEducationalGrouptSeries}
            funcButtonRight={scrollRigthEducationalGroupSeries}
          />
        )}
        <hr />
      </WrapperDiv>
    </Container>
  );
}
