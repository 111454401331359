import react, { useContext, useState, useEffect, useRef } from 'react';
import { FormContainer, ContainerInput } from './style';
import { Form } from '@unform/web';
import Input from '../../../../../Opportunities/Components/Form/input';
import SearchableSelectUn from '../../../form/searchableSelect';
import Button from 'components/CustomButtons/Button';
import { IndicationStudentContext } from '../../context/indicationStudentContext';
import BaseLayoutContext from 'contexts/base-layout';
import { getFilters } from '../../services/IndicationStudent.service';
import { FilterMultiSelect } from './components/filterMultiSelect';

export default function StepOne() {
  const { job, getStudents } = useContext(IndicationStudentContext);
  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    courses: [],
    semesters: [],
    unities: [],
    academic_status: [
      {
        label: 'Egresso',
        value: 'egress',
      },
      {
        label: 'Matriculado',
        value: 'enrolled',
      },
    ],
  });
  useEffect(() => {
    (async () => {
      const dataFilters = await getFilters(job.id);
      setFilters({ academic_status: filters.academic_status, ...dataFilters });
      setLoading(false);
    })();
  }, []);

  const onSubmitForm = async () => {
    const {
      name,
      external_id,
      courses,
      semester,
      status,
      unities,
    } = formRef.current.getData();
    await getStudents({
      name,
      external_id,
      courses,
      semester,
      status,
      unities,
    });
  };

  return (
    <FormContainer>
      <div className="header">
        <h3>Filtrar estudante para vaga</h3>
        <p>Filtre o estudante de sua preferência para indicá-lo a esta vaga.</p>
      </div>
      <Form ref={formRef} onSubmit={onSubmitForm}>
        <ContainerInput>
          <label for="name">Nome</label>
          <Input
            name="name"
            type="text"
            placeholder="Digite aqui..."
            maxlength={50}
          />
        </ContainerInput>
        <ContainerInput>
          <label for="id">Código</label>
          <Input
            name="external_id"
            type="text"
            placeholder="Digite aqui..."
            maxlength={50}
          />
        </ContainerInput>
        <ContainerInput>
          <label>Curso</label>
          <FilterMultiSelect
            name="courses"
            options={filters.courses}
            loading={loading}
            disabled={loading}
          />
        </ContainerInput>
        <ContainerInput>
          <label>Semestre</label>
          <FilterMultiSelect
            name="semester"
            options={filters.semesters}
            loading={loading}
            disabled={loading}
          />
        </ContainerInput>
        <ContainerInput>
          <label>Status acadêmico</label>
          <FilterMultiSelect
            name="status"
            options={filters.academic_status}
            loading={loading}
            disabled={loading}
          />
        </ContainerInput>
        {filters.unities.length > 1 && (
          <ContainerInput>
            <label>Unidade</label>
            <FilterMultiSelect
              name="unities"
              options={filters.unities}
              loading={loading}
              disabled={loading}
            />
          </ContainerInput>
        )}
        <Button type="submit" className="buttonRight">
          Aplicar filtros
        </Button>
      </Form>
    </FormContainer>
  );
}
