export const selectOptionsCourses = [
  { id: 'short_term', value: 'short_term', label: 'Curta Duração' },
  { id: 'free_course', value: 'free_course', label: 'Curso Livre' },
  {
    id: 'professionalizing',
    value: 'professionalizing',
    label: 'Profissionalizante',
  },
  { id: 'idiom', value: 'idiom', label: 'Idioma' },
  { id: 'training', value: 'training', label: 'Formação' },
  { id: 'certification', value: 'certification', label: 'Certificação' },
  { id: 'technical_course', value: 'technical_course', label: 'Curso Técnico' },
  {
    id: 'university_graduate',
    value: 'university_graduate',
    label: 'Graduação',
  },
  { id: 'postgraduate', value: 'postgraduate', label: 'Pós Graduação' },
  { id: 'other', value: 'other', label: 'Outros' },
];

export const selectOptionsProgram = [
  { id: 'trainee', value: 'trainee', label: 'Trainee' },
  {
    id: 'exchange_program',
    value: 'exchange_program',
    label: 'Intercâmbio',
  },
  {
    id: 'pratical_experience',
    value: 'pratical_experience',
    label: 'Vivência Prática',
  },
  {
    id: 'scientific_initiation_scholarship',
    value: 'scientific_initiation_scholarship',
    label: 'Bolsa de iniciação científica',
  },
  { id: 'intern', value: 'intern', label: 'Estágio' },
  { id: 'free', value: 'free', label: 'Programa livre' },
];

export const modalityOptions = [
  { id: 'in_person', value: 'in_person', label: 'Presencial' },
  { id: 'online', value: 'online', label: 'Online' },
];

export const conditionOptions = [
  { id: 'payed', value: 'false', label: 'Pago' },
  { id: 'free', value: 'true', label: 'Gratuito' },
];

export const ExtesionOptions = [
  { id: 'additional', value: 'additional', label: 'Complementar' },
  { id: 'false', value: null, label: 'Nenhuma' },
];

export const PublicationFormat = [
  {
    id: 'access_link',
    value: 'access_link',
    label: 'Divulgação do Link de acesso',
  },
  {
    id: 'attraction_document',
    value: 'attraction_document',
    label: 'Upload de documento PDF',
  },
  {
    id: 'video',
    value: 'video',
    label: 'Vídeos sequencias',
  },
];

export const All = [
  {
    id: 'access_link',
    value: 'access_link',
    label: 'Divulgação do Link de acesso',
  },
  {
    id: 'attraction_document',
    value: 'attraction_document',
    label: 'Upload de documento PDF',
  },
  {
    id: 'video',
    value: 'video',
    label: 'Vídeos sequencias',
  },
  { id: 'additional', value: 'additional', label: 'Complementar' },
  { id: 'extension', value: 'extension', label: 'Extensão' },
  { id: 'false', value: null, label: 'Nenhum' },
  { id: 'payed', value: false, label: 'Pago' },
  { id: 'free', value: true, label: 'Gratuito' },
  { id: 'in_person', value: 'in_person', label: 'Presencial' },
  { id: 'online', value: 'online', label: 'Online' },
  { id: 'trainee', value: 'trainee', label: 'Programa de Intercâmbio' },
  {
    id: 'exchange_program',
    value: 'exchange_program',
    label: 'Bolsa de Iniciação Científica',
  },
  {
    id: 'pratical_experience',
    value: 'pratical_experience',
    label: 'Vivência Prática',
  },
  {
    id: 'scientific_initiation_scholarship',
    value: 'scientific_initiation_scholarship',
    label: 'Programa de Estágio',
  },
  { id: 'intern', value: 'intern', label: 'Programa Livre' },
  { id: 'short_term', value: 'short_term', label: 'Curta Duração' },
  { id: 'free_course', value: 'free_course', label: 'Curso Livre' },
  {
    id: 'professionalizing',
    value: 'professionalizing',
    label: 'Profissionalizante',
  },
  { id: 'idiom', value: 'idiom', label: 'Idioma' },
  { id: 'training', value: 'training', label: 'Formação' },
  { id: 'certification', value: 'certification', label: 'Certificação' },
  { id: 'technical_course', value: 'technical_course', label: 'Curso Técnico' },
  {
    id: 'university_graduate',
    value: 'university_graduate',
    label: 'Graduação',
  },
  { id: 'postgraduate', value: 'postgraduate', label: 'Pós Graduação' },
  { id: 'other', value: 'other', label: 'Outros' },
];
