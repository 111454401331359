import React from 'react';
import {
  ContainerButtons,
  NextButton,
  PrevsButton,
  ActionButtons,
  ConfirmButton,
  RejectButton,
  Wrapper,
} from './styled';
import { useNextMentorshipContext } from '../../context';

export default function StepController({ item, openCancelModal, onBack }) {
  const { isLastStep, isFirstStep, prevsFormStep } = useNextMentorshipContext();

  const handleRejectButtonClick = () => {
    openCancelModal(item);
  };

  const handleBackButtonClick = () => {
    onBack && onBack();
    prevsFormStep();
  };

  const lastFormButtons = (
    <ActionButtons>
      <Wrapper>
        <PrevsButton
          type={'button'}
          onClick={handleBackButtonClick}
        ></PrevsButton>
      </Wrapper>

      <Wrapper>
        <RejectButton onClick={handleRejectButtonClick}>
          Cancelar mentoria
        </RejectButton>
        <ConfirmButton type="submit">Salvar</ConfirmButton>
      </Wrapper>
    </ActionButtons>
  );

  const firstFormButtons = (
    <ActionButtons>
      <RejectButton onClick={handleRejectButtonClick}>
        Cancelar mentoria
      </RejectButton>
      <NextButton type="submit"></NextButton>
    </ActionButtons>
  );

  const middleFormButtons = (
    <>
      <PrevsButton onClick={handleBackButtonClick}></PrevsButton>
      <NextButton type="submit"></NextButton>
    </>
  );

  const controllButtons = () => {
    if (isLastStep) {
      return lastFormButtons;
    }

    if (isFirstStep) {
      return firstFormButtons;
    }
    return middleFormButtons;
  };

  return <ContainerButtons>{controllButtons()}</ContainerButtons>;
}
