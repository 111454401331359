import React, { createContext, useEffect, useRef, useState } from 'react';
import {
  getCountEnrolled,
  getImagesExtesionProject,
} from 'services/student/extensionProject';
import {
  getEnrolledExtensionProject,
  getNotSelectedExtensionProject,
  getFinishedExtensionProject,
  getImagesEnrolled,
  getImagesNotSelected,
  getImagesFinished,
  getWaitingEvidence,
  getImagesAwaitEvidence,
} from '../../Extracurricular/services';

export const ExtensionProjectsStudentContext = createContext();

export default function ExtensionProjectsStudentsProvider({
  children,
  currentTab,
  setCurrentTab,
  setCountSubscribe,
  extensionProjectCount,
  getExtesionProject,
}) {
  const [enrolledExtensionProject, setEnrolledExtensionProject] =
    useState(null);
  const [notSelectedExtensionProject, setNotSelectedExtensionProject] =
    useState(null);
  const [finishedExtensionProject, setFinishedExtensionProject] =
    useState(null);

  const [awaitEvidencesProjects, setAwaitEvidencesProject] = useState(null);

  const [pgFourCurrentPage, setPgFourCurrentPage] = useState(null);

  const [pgOneCurrentPage, setPgOneCurrentPage] = useState(null);
  const [pgTwoCurrentPage, setPgTwoCurrentPage] = useState(null);
  const [pgThreeCurrentPage, setPgThreeCurrentPage] = useState(null);

  const [pgOneCount, setPgOneCount] = useState(0);
  const [pgFourCount, setPgFourCount] = useState(0);
  const [pgTwoCount, setPgTwoCount] = useState(0);
  const [pgThreeCount, setPgThreeCount] = useState(0);
  const [imagesList, setImagesList] = useState([]);
  const [imagesEnrolled, setImagesEnrolled] = useState([]);

  const [usingPreview, setUsingPreview] = useState(false);

  const getImageEnrroledCall = async (query) => {
    const { results } = await getImagesEnrolled(query);
    setImagesEnrolled((prevs) => [...prevs, results]);
  };

  const getImagesNotSelectedCall = async (query) => {
    const { results } = await getImagesNotSelected(query);
    setImagesEnrolled((prevs) => [...prevs, results]);
  };

  const getImagesFinishedCall = async (query) => {
    const { results } = await getImagesFinished(query);
    setImagesEnrolled((prevs) => [...prevs, results]);
  };

  const getImagesAwaitEvidencesCall = async (query) => {
    const { results } = await getImagesAwaitEvidence(query);
    setImagesEnrolled((prevs) => [...prevs, results]);
  };

  const [modalFeedbackUnsubscribe, setModalFeedbackUnsubscribe] =
    useState(false);
  const [extensionProjectId, setExtensionProjectId] = useState(null);

  useEffect(() => {
    if (currentTab === 1) {
      getAllEnrolledExtensionProject();
    }
  }, [pgOneCurrentPage, currentTab]);

  useEffect(() => {
    if (currentTab === 1) {
      getAllNoSelectedExtensionProject();
    }
  }, [pgTwoCurrentPage, currentTab]);

  useEffect(() => {
    if (currentTab === 1) {
      getAllFinishedExtensionProject();
    }
  }, [pgThreeCurrentPage, currentTab]);

  useEffect(() => {
    if (currentTab === 1) {
      getWatingEvidencesEndpoint();
    }
  }, [currentTab]);

  useEffect(() => {
    if (currentTab !== 1) {
      setEnrolledExtensionProject(null);
      setNotSelectedExtensionProject(null);
      setFinishedExtensionProject(null);
      setAwaitEvidencesProject(null);
      setPgOneCurrentPage(0);
      setPgTwoCurrentPage(0);
      setPgThreeCurrentPage(0);
      setPgFourCurrentPage(0);
    }
  }, [currentTab]);

  const getImages = async (query) => {
    const { results } = await getImagesExtesionProject(query);
    setImagesList(results);
  };

  async function getAllEnrolledExtensionProject(reset = false) {
    const data = await getEnrolledExtensionProject(
      `?limit=8&offset=${reset ? 0 : pgOneCurrentPage}`
    );
    const { results, count } = data;
    if (reset) {
      setEnrolledExtensionProject(results);
    } else {
      setEnrolledExtensionProject((prevProjects) =>
        prevProjects ? [...prevProjects, ...results] : results
      );
    }
    getImageEnrroledCall(
      `?limit=8&offset=${pgOneCurrentPage ? pgOneCurrentPage : 0}`
    );
    setPgOneCount(count);
  }

  async function getAllNoSelectedExtensionProject(reset = false) {
    const data = await getNotSelectedExtensionProject(
      `?limit=8&offset=${reset ? 0 : pgOneCurrentPage}`
    );
    const { results, count } = data;

    if (reset) {
      setNotSelectedExtensionProject(results);
    } else {
      setNotSelectedExtensionProject((prevProjects) =>
        prevProjects ? [...prevProjects, ...results] : results
      );
    }
    getImagesNotSelectedCall(
      `?limit=8&offset=${pgOneCurrentPage ? pgOneCurrentPage : 0}`
    );
    setPgTwoCount(count);
  }

  async function getWatingEvidencesEndpoint(reset = false, page = 0) {
    if (reset) {
      setAwaitEvidencesProject([]);
      setPgFourCurrentPage(0);
      const data = await getWaitingEvidence(`?limit=8&offset=${0}`);
      const { results } = data;

      setAwaitEvidencesProject(results);
      return;
    }

    const data = await getWaitingEvidence(
      `?limit=8&offset=${reset ? 0 : page}`
    );
    const { results, count } = data;

    setAwaitEvidencesProject((prevProjects) =>
      prevProjects ? [...prevProjects, ...results] : results
    );
    getImagesAwaitEvidencesCall(`?limit=8&offset=${page ? page : 0}`);
    setPgFourCount(count);
  }

  async function getAllFinishedExtensionProject(reset = false, page) {
    const data = await getFinishedExtensionProject(
      `?limit=8&offset=${reset ? 0 : pgOneCurrentPage}`
    );
    const { results, count } = data;
    if (reset) {
      setFinishedExtensionProject(results);
    } else {
      setFinishedExtensionProject((prevProjects) =>
        prevProjects ? [...prevProjects, ...results] : results
      );
    }
    getImagesFinishedCall(
      `?limit=8&offset=${pgOneCurrentPage ? pgOneCurrentPage : 0}`
    );
    setPgThreeCount(count);
  }

  return (
    <ExtensionProjectsStudentContext.Provider
      value={{
        getAllEnrolledExtensionProject,
        enrolledExtensionProject,
        getAllNoSelectedExtensionProject,
        notSelectedExtensionProject,
        finishedExtensionProject,
        pgOneCurrentPage,
        setPgOneCurrentPage,
        pgTwoCurrentPage,
        setPgTwoCurrentPage,
        pgThreeCurrentPage,
        setPgThreeCurrentPage,
        pgOneCount,
        setPgOneCount,
        pgTwoCount,
        setPgTwoCount,
        pgThreeCount,
        setPgThreeCount,
        currentTab,
        extensionProjectCount,
        getImages,
        imagesList,
        imagesEnrolled,
        setImagesEnrolled,
        setCurrentTab,
        modalFeedbackUnsubscribe,
        setModalFeedbackUnsubscribe,
        extensionProjectId,
        setExtensionProjectId,
        getExtesionProject,
        setEnrolledExtensionProject,
        getWatingEvidencesEndpoint,
        awaitEvidencesProjects,
        setAwaitEvidencesProject,
        setPgFourCurrentPage,
        pgFourCount,
        setPgFourCount,
        usingPreview,
        setUsingPreview,
        pgFourCurrentPage,
      }}
    >
      {children}
    </ExtensionProjectsStudentContext.Provider>
  );
}
