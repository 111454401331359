import FilterModal from 'components/FilterModal';
import React, { useContext, useEffect, useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, SpanError, Grid, ContainerFilters } from './styles';
import { handleFieldMask } from 'utils/validation';
import { maskCnpj, onlyNumbers } from 'utils/inputMasks';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { JobOfferContext } from 'views/StaffPages/jobOpeningsRebuild/context/jobOfferContext';
import AuthContext from 'contexts/authentication';

import * as Yup from 'yup';
import useMediaQuery from 'hooks/useMediaQuery';
import { loadJobsData } from 'components/ListCandidates/Utils/mainFunctions';

export function FilterCandidates({
  activeTab,
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  setCandidates,
  candidates,
  environmentType,
  jobId,
  isPartner,
  selectedTab,
  seeMatch,
  company,
  university,
  isExternal,
}) {
  useEffect(() => {
    setFilterState({});
    setAppliedFilters({});
  }, []);
  const [loading, setLoading] = useState(false);
  const { metadata } = useContext(AuthContext);
  const canFilterUniversities = metadata?.is_educational_group || company;
  const canFilterMatch =
    (seeMatch && company) || (university && isExternal === false);

  function renderFilterContent() {
    return [
      <FilterGrid>
        <FilterGrid>
          {canFilterUniversities && (
            <Filter
              name={'universities'}
              label={'Instituições'}
              type={FilterType.MULTI_SELECT}
              settings={{
                placeholder: 'Selecione',
              }}
              options={filterOptions.universities}
            />
          )}
          <Filter
            name={'courses'}
            label={'Cursos'}
            type={FilterType.MULTI_SELECT}
            settings={{
              placeholder: 'Selecione',
            }}
            options={filterOptions.courses}
          />
          <ContainerFilters>
            <Filter
              name={'course_level'}
              label={'Nível de curso'}
              type={FilterType.MULTI_SELECT}
              settings={{
                placeholder: 'Selecione',
              }}
              options={filterOptions.course_level}
            />
            <Filter
              name={'periods'}
              label={'Período'}
              type={FilterType.MULTI_SELECT}
              settings={{
                placeholder: 'Selecione',
              }}
              options={filterOptions.periods}
            />
          </ContainerFilters>
        </FilterGrid>
        <Filter
          name={'is_champion'}
          label="Exibir somente estudantes com o perfil campeão"
          type={FilterType.SINGLE_CHECKBOX}
        />
      </FilterGrid>,

      <FilterGrid>
        <Filter
          name={'special_needs'}
          label={'PCD - Pessoa com deficiência'}
          type={FilterType.MULTI_SELECT}
          settings={{
            placeholder: 'Selecione',
          }}
          options={filterOptions.special_needs}
        />

        <ContainerFilters>
          <Filter
            name={'color_or_race'}
            label={'Cor/Raça'}
            type={FilterType.MULTI_SELECT}
            settings={{
              placeholder: 'Selecione',
            }}
            options={filterOptions.color_or_race}
          />

          <Filter
            name={'gender_identity'}
            label={'Identidade de Gênero'}
            type={FilterType.MULTI_SELECT}
            settings={{
              placeholder: 'Selecione',
            }}
            options={filterOptions.gender_identity}
          />
        </ContainerFilters>
      </FilterGrid>,

      <FilterGrid>
        {canFilterMatch && (
          <Filter
            name={['start_match', 'end_match']}
            label={'Match dos candidatos'}
            type={FilterType.PROGRESS_RANGE}
          />
        )}

        <Filter
          name={'behavioral_attributes'}
          label={'Informações comportamentais'}
          type={FilterType.MULTI_SELECT}
          settings={{
            placeholder: 'Selecione',
          }}
          options={filterOptions.behavioral_attributes}
        />

        <Filter
          name={'personality'}
          label={'Personalidade'}
          type={FilterType.MULTI_SELECT}
          settings={{
            placeholder: 'Selecione',
          }}
          options={filterOptions.personality}
        />

        <Filter
          name={'professional_status'}
          label={'Situação profissional'}
          type={FilterType.MULTI_SELECT}
          settings={{
            placeholder: 'Selecione',
          }}
          options={filterOptions.professional_status}
        />

        <Filter
          name={'market_availability'}
          label={'Disponibilidade de trabalho'}
          type={FilterType.MULTI_SELECT}
          settings={{
            placeholder: 'Selecione',
          }}
          options={filterOptions.market_availability}
        />
      </FilterGrid>,

      <FilterGrid>
        <Filter
          name={['start_date', 'end_date']}
          label={'Período de candidatura'}
          type={FilterType.DATE_RANGE}
          settings={{
            placeholder: ['De: DD/MM/AAAA', 'Até: DD/MM/AAAA'],
          }}
          startYear={2019}
        />
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const existFilters = filters && Object.keys(filters).length > 0;

    if (existFilters) {
      setLoading(true);
      const queryString = queryParamsFromFilterObject(filters);
      let response = await loadJobsData(
        environmentType,
        jobId,
        isPartner,
        selectedTab,
        _,
        queryString
      );

      if (filters.is_champion || filters.start_match || filters.end_match) {
        if (filters.is_champion) {
          response = response.filter(
            (candidate) => candidate.is_champion !== false
          );
        }

        if (filters.start_match || filters.end_match) {
          response = response.filter((candidate) => {
            if (filters.start_match && filters.end_match) {
              return (
                candidate.match >= filters.start_match &&
                candidate.match <= filters.end_match
              );
            } else if (filters.start_match) {
              return candidate.match >= filters.start_match;
            } else if (filters.end_match) {
              return candidate.match <= filters.end_match;
            }
          });
        }
        setCandidates(response);
      } else {
        setCandidates(response);
      }

      setLoading(false);
    }
  }

  async function onClearFilters() {
    setCandidates(null);
    setLoading(true);
    const response = await loadJobsData(
      environmentType,
      jobId,
      isPartner,
      selectedTab
    );
    setCandidates(response);
    setLoading(false);
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      tabs={[
        'Dados acadêmicos',
        'Dados de diversidade',
        'Requisitos',
        'Período',
      ]}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loading}
      zIndex={'1300'}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
