import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Card = styled.div`
  width: 100%;
  max-width: 300px;
  height: 514px;
  margin: 10px;
  margin-top: 0;
  border-radius: 8px;
  background-color: #f0f0f0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const CardImgSkeleton = styled.div`
  position: absolute;
  top: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  max-width: 300px;
  background-color: #e5e5e5;
  height: 181px;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardTitle = styled.div`
  position: absolute;
  max-width: ${(props) => props.widthTitle};
  width: 100%;

  height: 28px;
  top: 15em;
  margin: 0 10px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  border-radius: 4px;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardSubTitles = styled.div`
  position: absolute;
  max-width: ${(props) => props.widthSubTitle};
  width: 100%;
  height: 20px;
  top: ${(props) => `${props.top}em`};
  border-radius: 4px;
  margin: 0 10px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardButton = styled.div`
  position: absolute;
  max-width: ${(props) => props.widthSubTitle};
  width: 100%;

  height: 40px;
  top: ${(props) => `${props.top}em`};
  border-radius: 4px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  margin: 0 20%;

  @media (max-width: 375px) {
    margin-left: 35px;
  }

  @media (max-width: 320px) {
    margin: 0;
    margin-left: 12px;
  }
`;
