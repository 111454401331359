export function ClockIcon({ color = '#009291', size = '15px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 14C3.64008 14 0.5 10.8599 0.5 7C0.5 3.14008 3.64008 0 7.5 0C11.3599 0 14.5 3.14008 14.5 7C14.5 10.8599 11.3599 14 7.5 14ZM7.5 1.16667C4.2835 1.16667 1.66667 3.7835 1.66667 7C1.66667 10.2165 4.2835 12.8333 7.5 12.8333C10.7165 12.8333 13.3333 10.2165 13.3333 7C13.3333 3.7835 10.7165 1.16667 7.5 1.16667ZM10.4167 7C10.4167 6.67742 10.1559 6.41667 9.83333 6.41667H8.08333V3.5C8.08333 3.17742 7.822 2.91667 7.5 2.91667C7.178 2.91667 6.91667 3.17742 6.91667 3.5V7C6.91667 7.32258 7.178 7.58333 7.5 7.58333H9.83333C10.1559 7.58333 10.4167 7.32258 10.4167 7Z"
        fill={color}
      />
    </svg>
  );
}
