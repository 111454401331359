import React, { useContext, useEffect, useRef, useState } from 'react';
import { Wrapper, ContainerTypeName, ContainerActions, PopUp } from './style';
import BaseLayoutContext from 'contexts/base-layout';
import PdfViwer from '../PdfViwer';
import ImageViwer from '../ImageViwer';

import moment from 'moment';
import PDFIcon from 'assets/ComponentIcons/PDFIcon';
import {
  JPGIcon,
  MP4Icon,
  PNGIcon,
  RARIcon,
  ZIPIcon,
} from 'assets/ComponentIcons/evidencesIcon';
import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';
import { DefaultEyeIcon } from 'assets/ComponentIcons/DefaultEye';

export default function ListFiles({
  idPost,
  item,
  functionDeleteEvidence,
  filesList,
  setFilesList,
  setModalInformationConfig,
  setModalInformationOpen,
  setUsingPreviewPdf,
  canUseActions,
  companiesManagement,
  functionResetList,
  stateResetList,
  limit,
  currentPage,
  fromStudent,
  isFromSettingsLogo,
  setItemWaitingDelete,
  ctaDownloadPreview,
  limitSizeDocument,
}) {
  const { openModalWithContent, universityColor } = useContext(
    BaseLayoutContext
  );

  const [fileData, setFileData] = useState(null);
  const [fileIsImage, setFileIsImage] = useState(false);

  const currentLengthFilesList = useRef(0);

  useEffect(() => {
    if (fileData) {
      callModal(fileData);
    }
  }, [fileData]);

  useEffect(() => {
    if (fileIsImage) {
      callModalImage();
    }
  }, [fileIsImage]);

  const icons = {
    '.pdf': <PDFIcon color={universityColor || '#009291'} size={22} />,
    '.png': <PNGIcon color={universityColor || '#009291'} size={22} />,
    '.jpg': <JPGIcon color={universityColor || '#009291'} size={22} />,
    '.jpeg': <JPGIcon color={universityColor || '#009291'} size={22} />,
    '.mp4': <MP4Icon color={universityColor || '#009291'} size={22} />,
    '.zip': <ZIPIcon color={universityColor || '#009291'} size={22} />,
    '.rar': <RARIcon color={universityColor || '#009291'} size={22} />,
  };

  const returnName = () => {
    if (item?.file?.name && !item.file?.size) {
      return item.file.name;
    }
    if (item?.image?.name && !item.image?.size) {
      return item.image.name;
    }

    if (item?.label && item.value) {
      return item.label;
    }

    return item?.file?.name || item.label || item.name;
  };

  const returnIcon = () => {
    if (icons[item.type]) {
      return icons[item.type];
    }

    if (item.file?.suffix) {
      return icons[item.file.suffix];
    }

    if (item.value && item.label) {
      return icons['.pdf'];
    }

    if (item.image?.suffix) {
      return icons[item.image.suffix];
    }

    if (item.suffix) {
      return icons[item.suffix];
    }
  };

  const messageError = (item) => {
    const messages = {
      '.pdf': `Tem certeza que deseja excluir o documento "${returnName()}"?`,
      '.png': `Tem certeza que deseja excluir a imagem "${returnName()}"?`,
      '.jpg': `Tem certeza que deseja excluir a imagem "${returnName()}"?`,
      '.mp4': `Tem certeza que deseja excluir o video "${returnName()}"?`,
      '.zip': `Tem certeza que deseja excluir o arquivo "${returnName()}"?`,
      '.rar': `Tem certeza que deseja excluir o arquivo "${returnName()}"?`,
      image: `Tem certeza que deseja excluir a imagem "${returnName()}"?`,
      file: `Tem certeza que deseja excluir o documento "${returnName()}"?`,
    };

    if (item.file !== null) {
      return messages['file'];
    }
    if (item.image !== null) {
      return messages['image'];
    }

    if (item?.type) {
      return messages[item.type];
    }
  };

  const handleConfirmDelete = async () => {
    if (isFromSettingsLogo) {
      setItemWaitingDelete(item);
      const removeItemArray = filesList.filter((file) => file.id !== item.id);
      setFilesList(removeItemArray);
      setModalInformationOpen(false);
      return;
    }

    const { createdNow, id } = item;
    const removeItemArray = filesList.filter((file) => file.id !== item.id);

    setFilesList(removeItemArray);

    if (companiesManagement) {
      const formDataEdit = new FormData();

      if (id === 1) {
        formDataEdit.append('agreement_document_one_file', '');
      }

      if (id === 2) {
        formDataEdit.append('agreement_document_two_file', '');
      }

      await functionDeleteEvidence(idPost, formDataEdit);
      setModalInformationOpen(false);

      stateResetList(null);
      await functionResetList(
        `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
      );
      return;
    }

    if (!createdNow) {
      functionDeleteEvidence(idPost, item.id);
      setModalInformationOpen(false);
    }
  };

  const configDelete = {
    bubbleText: 'Atenção!',
    ParagraphText: messageError(item),
    buttonConfirmText: 'SIM, EXCLUIR ',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
    buttonConfirmFunction: handleConfirmDelete,
  };

  const handleClickDeleteIcon = () => {
    setModalInformationConfig(configDelete);
    setModalInformationOpen(true);
  };

  const handleClosePreview = () => {
    setUsingPreviewPdf(false);
    setFileData(null);
    setFileIsImage(false);
  };

  const callModalImage = () => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <ImageViwer
        file={item}
        name={returnName()}
        fromStudent={fromStudent}
        type={item.image?.suffix || item.suffix}
        universityColor={universityColor}
        ctaDownloadPreview={ctaDownloadPreview}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const callModal = (file) => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <PdfViwer
        universityColor={universityColor}
        file={file}
        name={returnName()}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const handleClickPreview = () => {
    if (
      item.image?.name ||
      item.image?.type === '.png' ||
      item.image?.type === '.jpeg' ||
      item.image?.type === '.jpg' ||
      item.image?.suffix === '.png' ||
      item.image?.suffix === '.jpeg' ||
      item.image?.suffix === '.jpg' ||
      item.type === '.png' ||
      item.type === '.jpeg' ||
      item.type === '.jpg' ||
      item.suffix === '.png' ||
      item.suffix === '.jpeg' ||
      item.suffix === '.jpg'
    ) {
      setFileIsImage(true);
      return;
    }
    const file = item.file?.file || item.file || item.value;
    setFileData(file);
  };

  return (
    <Wrapper>
      <ContainerTypeName>
        {returnIcon()}
        <div className="direction-column">
          <span>{returnName()}</span>
          {companiesManagement && <p>Termo de compromisso de estágio</p>}
          {item.created_at && (
            <p className="date">
              Enviado dia
              <span>
                {moment(item.created_at).format('DD/MM/YYYY')} às{' '}
                {moment(item.created_at).format('H:mm ')}
              </span>
            </p>
          )}
        </div>
      </ContainerTypeName>
      <ContainerActions>
        {canUseActions && (
          <button onClick={handleClickDeleteIcon}>
            <PopUp
              label="Excluir"
              universityColor={universityColor || '#009291'}
            >
              <DefaultTrash alt="trash" color={universityColor || '#009291'} />
            </PopUp>
          </button>
        )}
        <button onClick={handleClickPreview}>
          <PopUp
            label="Visualizar"
            universityColor={universityColor || '#009291'}
          >
            <DefaultEyeIcon
              size={20}
              color={universityColor || '#009291'}
              alt="eye"
            />
          </PopUp>
        </button>
      </ContainerActions>
    </Wrapper>
  );
}
