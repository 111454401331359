import React from 'react';

import { useDragLayer } from 'react-dnd';
import DragItemTemplate from '../dragItemTemplate';

export default function DragLayerItem(props) {
  const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  };

  const getFieldStyle = (isDragging) => {
    const style = {
      width: 300,
      maxWidth: 300,
    };
    style.opacity = isDragging ? 0.8 : 1;
    return style;
  };

  const getItemStyles = (currentOffset) => {
    if (!currentOffset) {
      return {
        display: 'none',
      };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
      transform,
      WebkitTransform: transform,
    };
  };

  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>
        <div style={getFieldStyle(isDragging)}>
          {itemType === 'Card' && <DragItemTemplate fields={item.ids} />}
        </div>
      </div>
    </div>
  );
}
