import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';

export const QrCodeScan = ({ onScan, onError, style, useCamera = 'front' }) => {
  const handleScan = (result, error) => {
    if (result) {
      onScan && onScan(result);
    }
    if (error) {
      onError && onError(error);
    }
  };

  const constraints =
    useCamera === 'front'
      ? { facingMode: 'environment' }
      : { facingMode: 'environment' };

  return (
    <>
      <QrReader
        scanDelay={500}
        constraints={constraints}
        videoStyle={style}
        onResult={handleScan}
      />
    </>
  );
};
