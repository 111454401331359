import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  min-height: 129px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 4em 5em;

  span {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    width: 100%;
    max-width: 1112px;
  }

`

export const WrapperContent = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  margin-top: 36px;
  border-radius: 8px;
  background-color: #ffffff;

`