import styled from 'styled-components';

export const ComebackHeader = styled.div`
  display: flex;
  width: 900px;
  margin-bottom: -50px;
  z-index: 2;
  margin-left: 35px;

  > button {
    position: absolute;
    top: 25px;
    border: none;
    transition: all ease 0.2s;
    padding: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #009291;
    cursor: pointer;
    background-color: #fff;
    width: 31px;
    overflow: hidden;
    height: 31px;
    border-radius: 4px;

    &:hover {
      width: 100px;
    }

    > svg {
      min-width: 30px;
    }
  }
`;
