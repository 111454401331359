import styled from 'styled-components';
import closeIcon from 'assets/Oportutinites/X.svg';
import { CourseStatus } from './context';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 18px 0 #0000001a;
  padding: 0 1rem 3rem 1rem;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 18px 0 #0000001a;
  padding: 0 1rem 3rem 1rem;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 825px;

  > strong {
    margin-top: 2.5rem;
    font-size: 20px;
    color: #606062;
  }

  > p {
    max-width: 630px;
    margin-top: 1rem;
    font-size: 16px;
    color: #606062;
  }
`;

export const Box = styled.div`
  display: flex;
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  margin-top: ${(props) => props.marginTop || '0'};
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  max-width: ${(props) => props.maxWidth || '100%'};
  gap: ${(props) => props.gap || '0'};
  height: ${(props) => props.height || 'fit-content'};
`;

export const Row = styled(Box)`
  flex-direction: row;
`;

export const CourseGrid = styled(Box)`
  display: grid;
  margin-top: 2rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  row-gap: 2rem;
`;

export const Column = styled(Box)`
  flex-direction: column;
`;

export const OutlinedBox = styled(Column)`
  border-radius: 12px;
  border: 1px solid #c4c4c4;
  position: relative;
  min-width: 254px;
  min-height: ${(props) => props.minHeight || '0'};
  padding: ${(props) => props.padding || '0'};
  animation-name: ${(props) => (props.animate ? 'breath-animation' : '0')};
  animation-duration: ${(props) => (props.animate ? `${props.animate}s` : '0')};
  ${(props) => (props.height ? `height: ${props.height};` : '')};
  @keyframes breath-animation {
    0% {
      transform: translateY(-170px);
      width: 100px;
      opacity: 0.3;
    }
    70% {
      transform: translateY(0px);
      width: 254px;
      opacity: 1;
    }
  }
`;
export const InfoIconWrapper = styled.div`
  position: absolute;
  top: 0.9rem;
  right: 1rem;

  > svg {
    cursor: pointer;
  }
`;

export const GreenBox = styled(Row)`
  height: 2rem;
  max-width: 630px;
  margin-top: 36px;
  border-radius: 5px;
  background: #e3f8f8;
  gap: 5rem;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    > p {
      color: #606062;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
    }
  }
`;
export const DataRow = styled(Row)`
  gap: 62px;
  margin-top: 32px;
`;

export const Text = styled.p`
  color: ${(props) =>
    props.green ? '#009291' : props.color ? props.color : '#606062'};
  font-size: ${(props) => props.fontSize || '16px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || '400'};
  line-height: normal;
  margin-top: ${(props) => props.marginTop || '0'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  margin-left: 0;
  margin-right: 0;
  white-space: nowrap;
  text-align: left;
  width: ${(props) => props.width || '100%'};
`;

export const GridCourseName = styled.div`
  display: flex;
  flex-direction: column;
  gap 5px;
`;

export const LabelCourse = styled.div`
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => props.fontWeight || '400'};
  color: ${(props) => props.color || '#949494'}; ;
`;

export const Labeltip = styled.div`
  display: flex;
  background-color: #eef2f4;
  border-radius: 4px;
  padding: 0.5rem;
  margin-top: 1rem;
  > p {
    color: #606062;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-style: normal;
    line-height: 14px;
    text-align: justify;
    margin: 0;
  }
`;

export const TextName = styled.p`
  color: ${(props) =>
    props.green ? '#009291' : props.color ? props.color : '#606062'};
  font-size: ${(props) => props.fontSize || '16px'};
  font-style: normal;
  font-weight: ${(props) => props.fontWeight || '400'};
  line-height: normal;
  margin-top: ${(props) => props.marginTop || '5px'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  width: ${(props) => props.width || '100%'};
  -webkit-line-clamp: 2;
  line-clamp: 2;
  min-height: 38px;
`;
export const Divider = styled.hr`
  height: 1px;
  background-color: #dadada;
  margin-top: ${(props) => props.marginTop || '0'};
  margin-bottom: ${(props) => props.marginBottom || '0'};
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth || '100%'};
`;

export const PedagogicalAdherenceButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009291;
  color: #fff;
  border-radius: 5px;
  border: none;
  width: fit-content;
  padding: 0 1rem;
  height: 40px;
  cursor: pointer;
  margin: 2rem 0;

  &:disabled {
    cursor: not-allowed;
    background: #f5f5f5;
    color: #949494;
  }
`;

export const GrayBox = styled(Column)`
  background-color: #f4f8f8;
  padding: ${(props) => props.padding || '2rem'};
  border-radius: 5px;
  gap: ${(props) => props.gap || '0.5rem'};
  align-items: flex-start;
`;

function getBackgroundColor(status) {
  const colors = {
    [CourseStatus.EQUAL_AVERAGE]: '#F0E051',
    [CourseStatus.ABOVE_AVERAGE]: '#6AC383',
    [CourseStatus.BELOW_AVERAGE]: '#F09D51',
    [CourseStatus.MAXIMUM_AVERAGE]: 'none',
  };
  return colors[status] || '#F09D51';
}

export const StatusIconWrapper = styled(Box)`
  height: ${(props) => props.size || '100%'};
  width: ${(props) => props.size || '100%'};
  background-color: ${(props) => getBackgroundColor(props.status)};
  border-radius: 5px;
`;

export const OverlayModal = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1062;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
`;

export const CloseButtonModal = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 32px;
  height: 32px;
  background-color: #efeeec;
  border-radius: 50%;
  top: 16px;
  right: 16px;
  background-image: url(${closeIcon});
  background-position: center;
  background-size: 35%;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid #dcdcdc;
  }
`;
