import React, { useState, useEffect, useRef } from 'react';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';
import {
  Container,
  StudentInfo,
  Article,
  Information,
  Filters,
  SearchFilter,
  OrderFilter,
  StyledSelect,
  ButtonClose,
  Header,
  Divider,
} from './styles';
import placeholderImage from 'assets/img/placeholder.jpg';
import closeIcon from 'assets/csm/closeIcon.svg';
import { AiOutlineSearch } from 'react-icons/ai';
import { FilterHistoric } from './components/Filter';
import {
  StudentProfileContent,
  AdditionalProfileContent,
} from './components/StudentProfile';
import { useDebounce } from 'hooks/useDebounce';
import { StudentDocuments } from './components/StudentDocuments';
import { TimelineList } from './components/Timeline';
import { StudentCategory } from 'views/StaffPages/Csm/resources/defaultStudentCategories';
import { studentData, listCampaigns } from './services';
import { SkeletonStudentData } from './components/Skeleton/skeletonStudentData';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export function StudentHistoric(props) {
  const { appliedFilters, setAppliedFilters } = useCsm();
  const { selectStudent, setSelectStudent,  activeCategory} = props
  const [dataStudent, setDataStudent] = useState('')
  const [emailDetails, setEmailDetails] = useState(false);
  const [emailItems, setEmailItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingEmails, setLoadingEmails] = useState(false);

  const id = selectStudent.id

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const data = await studentData(id);
        setDataStudent(data);
      } catch (error) {
        throw error;
      }
    };

    fetchStudentData();
  }, [id]);

  const studentProfile = StudentProfileContent(dataStudent);
  const additionalProfile = AdditionalProfileContent(dataStudent);

  const handleClose = () => {
    setSelectStudent(null);
  };

  const options = [
    { value: '-sent_amazon', label: 'Mais recentes para os mais antigos' },
    { value: 'sent_amazon', label: 'Mais antigos para os mais recentes' },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (selected) => {
    setSelectedOption(selected);
    let filters = queryParamsFromFilterObject(appliedFilters)
    let queryParam = searchQuery(
        selected ? selected.value : '', debouncedSearchTerm, filters, ''
    );
    fetchData(queryParam);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const topDivStudentHistoric = useRef();

  function searchQuery(ordering, debouncedSearchTerm, filters, limit) {
    let query = '';
    if (ordering) {
      query = `ordering=${ordering}`;
    }
    if (debouncedSearchTerm) {
      if (query) {
        query = `${query}&name=${debouncedSearchTerm}`;
      } else{
        query = `name=${debouncedSearchTerm}`;
      }
    }
    if (limit && !query) {
      query = `limit=${limit}`;
    } else if (limit && query) {
      query = `${query}&limit=${limit}`;
    }
    if (typeof filters === 'string') {
      if (query) {
        return `${query}&${filters}`;
      } else {
        query = `${filters}`;
      }
    }
    return query;
  }

  useEffect(() => {
    let filters = queryParamsFromFilterObject(appliedFilters)
    const search = searchQuery(
       selectedOption ? selectedOption.value : '', debouncedSearchTerm, filters, limit
    );
    fetchData(search);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (topDivStudentHistoric) {
      topDivStudentHistoric.current.scrollIntoView();
    }
  }, [topDivStudentHistoric]);

  const fetchData = async (queryParam) => {
    try {
      const response = await listCampaigns(id, queryParam);

      if(response.status === 200){
        setLoadingEmails(true);
      }
      if (typeof response.data === 'object' && response.data !== null) {
        const campaignArray = Object.entries(response.data);
        const flattenedCampaigns = campaignArray.flatMap(([month, campaigns]) => {
          const monthCampaign = {
            special: true,
            month,
          };
          return [monthCampaign, ...campaigns];
        });

        setEmailItems(flattenedCampaigns);
        setLoading(false);
      } else {
        setEmailItems([]);
        setLoading(false)
      }
    } catch (error) {
      setEmailItems([]);
      setLoadingEmails(false)
    }
  };

  const loadMore = () => {
    setLoading(true)
    setLimit((prevLimit) => prevLimit + 10);
  };

  useEffect(() => {
    let filters = queryParamsFromFilterObject(appliedFilters)
    let query = searchQuery(
      selectedOption ? selectedOption.value : '', searchTerm, filters, limit
    )
    fetchData(query);
  }, [limit]);

  useEffect(() => {
    let filters = queryParamsFromFilterObject(appliedFilters)
     let queryParam = searchQuery(
        selectedOption ? selectedOption.value : '', debouncedSearchTerm, filters, limit
     );
    fetchData(queryParam);
  }, [id]);

  return (
    <Container ref={topDivStudentHistoric}>
       {dataStudent === '' ?
        <SkeletonStudentData /> : (
          <StudentInfo>
            <Information className="studentAvatar">
              <img
                src={selectStudent.avatar}
                alt=""
                onError={(e) => {
                  e.target.src = placeholderImage;
                  e.target.onerror = null;
                }}
              />
            </Information>
            <Information>
              <span className="studentName">
                <p>{selectStudent.name}</p>
              </span>
            </Information>

            {activeCategory !== StudentCategory.INACTIVE &&
              studentProfile?.map((item, index) => (
                <Information key={index}>
                  <span title={item.text}>
                    <img src={item.icon} alt={item.text} />
                    <p>{item.text}</p>
                  </span>
                </Information>
              ))}

            {activeCategory !== StudentCategory.INACTIVE && <Divider />}
            {additionalProfile?.map((item, index) => (
              <Information key={index}>
                <span title={item.text}>
                  <span>{item.text}</span>
                </span>
              </Information>
            ))}
            <StudentDocuments />
          </StudentInfo>
        )
      }

      <Article>
        <Header>
          <Filters>
            <SearchFilter>
              <input
                type="text"
                placeholder="Pesquise por nome"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <AiOutlineSearch size={24} />
            </SearchFilter>

            <OrderFilter>
              <StyledSelect
                value={selectedOption}
                onChange={handleSelectChange}
                options={options}
                placeholder="Ordenar por"
                classNamePrefix="react-select"
              />
            </OrderFilter>

            <FilterHistoric
              id={id}
              fetchData={fetchData}
              searchQuery={searchQuery}
              ordering={selectedOption ? selectedOption.value : ''}
              searchTerm={searchTerm}
              limit={limit}
            />
          </Filters>

          <ButtonClose>
            <button onClick={handleClose}>
              <img src={closeIcon} />
            </button>
          </ButtonClose>
        </Header>
        <div
          style={{
            border: '0.1px solid #E6E6E6',
            marginTop: '15px',
            width: '100%',
          }}
        />
        <TimelineList
          emailDetails={emailDetails}
          setEmailDetails={setEmailDetails}
          emailItems={emailItems.slice(0, limit)}
          setEmailItems={setEmailItems}
          loadMore={loadMore}
          loading={loading}
          limit={limit}
          selectStudent={selectStudent}
          loadingEmails={loadingEmails}
        />
      </Article>
    </Container>
  );
}
