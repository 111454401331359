import styled from 'styled-components';

export const ColorInput = styled.input`
  -webkit-appearence: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  margin: auto 0;

  ::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  max-height: 50px;
  width: ${(props) => (props.isColorInput ? '40%' : '75%')};
  border: ${(props) =>
    props.error ? '2px solid #ff0000' : '1px solid #c4c4c4'};
  padding: 10px 10px;
  border-radius: 4px;
  margin: auto 0;

  ::placeholder {
    color: #c4c4c4;
  }
`;

export const NameLabel = styled.p`
  color: rgba(0, 0, 0, 0.54);

  font-size: 0.8rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  font-weight: 400;
  margin-top: 6px;
  margin-left: 2px;
`;

export const DivColorsInput = styled.div`
  display: flex;
`;

export const DivColors = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: baseline;
  padding-left: 9px;
  border-radius: 12px;
  background-color: white;
`;

export const ButtonChangePassword = styled.button`
  color: #009291;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0;
  bottom: 0;
  font-weight: 600;
`;
