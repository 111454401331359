import FilterModal from 'components/FilterModal';
import React, { useContext, useEffect, useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { JobOfferContext } from '../../context/jobOfferContext';
import { FilterGrid } from './styles';
import { handleFieldMask } from 'utils/validation';
import { maskCnpj, onlyNumbers } from 'utils/inputMasks';

export function FilterJobUniversity({
  activeTab,
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
}) {
  const { setCurrentJobFilter, isMaster } = useContext(JobOfferContext);
  const [coursesFilterOptions, setCoursesFilterOptions] = useState([]);
  const [showFilterOnMaster, setShowFilterOnMaster] = useState(false);

  const hasCandidatesOptions = [
    { label: 'Com candidaturas', value: true },
    { label: 'Sem candidaturas', value: false },
  ];

  const verifyFilter = () => {
    if (isMaster) {
      setShowFilterOnMaster(true);
    } else {
      setShowFilterOnMaster(activeTab === 0);
    }
  };

  useEffect(() => {
    setFilterState({});
    setAppliedFilters({});
    verifyFilter();
  }, [activeTab]);

  useEffect(() => {
    if (filterOptions.courses) {
      const newListFilterCourses = [...filterOptions.courses];
      filterOptions.jobs_without_courses &&
        newListFilterCourses.unshift({
          label: 'Vagas sem indicação de curso',
          value: 0,
        });
      setCoursesFilterOptions(newListFilterCourses);
    }
  }, [filterOptions.courses]);

  function renderFilterContent() {
    return [
      <FilterGrid>
        <FilterGrid>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
          >
            <Filter
              name={'job_code'}
              label={'Código da vaga'}
              type={FilterType.TEXT}
              applyMask={true}
              mask={onlyNumbers}
              settings={{
                placeholder: 'Digite aqui...',
              }}
              maxLength={10}
            />
            <div style={{ display: 'flex', gap: '10px' }}>
              <Filter
                name={'name'}
                label={'Nome da vaga'}
                type={FilterType.TEXT}
              />
              {!isMaster && (
                <Filter
                  name={'company_organization_number'}
                  label={'CNPJ'}
                  type={FilterType.TEXT}
                  onKeyUp={(event) => {
                    handleFieldMask(event, maskCnpj);
                  }}
                  maxLength={18}
                />
              )}
            </div>
          </div>
          {filterOptions.job_companies && (
            <Filter
              name={'company_name'}
              label={'Nome da empresa'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.job_companies}
            />
          )}
          <Filter
            name={['start_date', 'end_date']}
            label={'Período de publicação'}
            type={FilterType.DATE_RANGE}
            settings={{
              placeholder: ['De', 'Até'],
            }}
            startYear={2019}
          />
          <Filter
            name={'courses'}
            label={'Curso'}
            type={FilterType.MULTI_SELECT}
            options={coursesFilterOptions}
          />

          {showFilterOnMaster && (
            <FilterGrid>
              <Filter
                name={'responsible_user_name'}
                label={'Responsável pela publicação'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.responsible_user_name}
              />
            </FilterGrid>
          )}
          <Filter
            name={'has_candidates'}
            label={'Candidaturas'}
            type={FilterType.RADIO}
            options={hasCandidatesOptions}
            settings={{
              isTwoColumns: true,
            }}
          />
          {filterOptions.status && (
            <Filter
              name={'status'}
              label={'Status da vaga'}
              type={FilterType.RADIO}
              options={filterOptions.status}
              settings={{
                isTwoColumns: true,
              }}
            />
          )}
        </FilterGrid>
      </FilterGrid>,

      <FilterGrid>
        <div>
          <Filter
            name={'state'}
            label={'Filtrar por estado'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.states}
          />
          <Filter
            name={'city'}
            label={'Filtrar por cidade'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.cities}
          />
        </div>

        {isMaster ? (
          <Filter
            name={activeTab === 0 ? 'university' : 'created_by_university'}
            label={'Nome da Instituição'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.universities}
          />
        ) : (
          <Filter
            name={'unity'}
            label={'Unidade'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.unities}
          />
        )}

        <Filter
          name={'remote'}
          type={FilterType.SINGLE_CHECKBOX}
          label={'Vagas remotas'}
        />
      </FilterGrid>,
    ];
  }

  function onApplyFilters() {
    setCurrentJobFilter(filterState);
  }

  function onClearFilters() {
    setCurrentJobFilter({});
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      tabs={['Informações básicas', 'Localização']}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
