export function IconCloseModal({ color }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.75448 6.33594L13.4263 0.664062L15.0466 2.28427L9.37468 7.95614L15.0466 13.628L13.4263 15.2482L7.75448 9.57635L2.08261 15.2482L0.462402 13.628L6.13427 7.95614L0.462402 2.28427L2.08261 0.664062L7.75448 6.33594Z"
        fill={color}
      />
    </svg>
  );
}
