import * as Yup from 'yup';

export const schemaValidationLink = Yup.object().shape({
  evidenceName: Yup.string()
    .required('Campo obrigatório')
    .max(50, 'O Limite para o nome é de 50 caracteres'),
  link: Yup.string()
    .url('Insira uma url válida')
    .required('Campo obrigatório')
    .max(500, 'O Limite para o link é de 500 caracteres'),
});
