import { LuCalendarDays } from 'react-icons/lu';
import { Image, ContaineWithoutImage } from './styled';
import { string } from 'prop-types';

export default function CardImage({ src, onClick }) {
  if (!src || src?.length === 0) {
    return (
      <ContaineWithoutImage onClick={onClick}>
        <LuCalendarDays size={38} />
      </ContaineWithoutImage>
    );
  }

  return <Image src={src} onClick={onClick} />;
}

CardImage.prototype = {
  src: string,
};
