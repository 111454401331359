import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  flex-direction: column;
`;

export const SuportContainer = styled.div`
  width: 464px;
  height: 148px;

  background-color: #fff;

  border-radius: 10px;

  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;

export const ParagraphCentered = styled.div`
  font-size: 20px;
  font: Roboto;
  color: #606062;

  font-weight: 700;
  margin-top: 18px;
`;

export const ParagraphFooter = styled.div`
  margin-top: 30px;
  width: 400px;
  color: #606062;

  text-align: center;
`;

export const ButtonStyle = styled.div`
  margin-bottom: 10px;
`;

export const GreenButton = styled.button`
  width: 136px;
  height: 40px;
  border-radius: 4px;
  background-color: #009291;
  color: white;
  font-size: 13px;
  font-weight: 700;
  transition: background-color 0.3s ease;
  font-family: roboto;

  cursor: pointer;

  border: none;

  &:hover {
    background-color: #9e9e9e;
  }
`;
