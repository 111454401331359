import { useContext } from 'react';
import { ProfileContext } from '../Context/ProfileContext';

export const profileTexts = () => {
  const { stepOneData, stepTwoData, universityColor } =
    useContext(ProfileContext);

  const configModalLevelOne = {
    bubbleText: 'Parabéns!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    ParagraphText: `Você conseguiu! Agora você pode gerar o seu Currículo do Futuro,
      acessar as vagas mais alinhadas ao seu perfil e começar 
      a jornada do protagonismo!`,
    buttonConfirmText: 'OK, ENTENDI',
  };

  const configModalLevelTwo = {
    bubbleText: 'Parabéns!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    ParagraphText: `Você conseguiu! Agora você pode gerar o seu Currículo do Futuro e 
      acessar as vagas mais alinhadas ao seu perfil.`,
    buttonConfirmText: 'OK, ENTENDI',
  };

  const configModalLevelThree = {
    bubbleText: 'Parabéns!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    ParagraphText: `Parabéns! Agora você tem um Perfil Campeão. 
      Seu currículo está super atraente e suas chances de conseguir seu lugar 
      no mercado de trabalho aumentaram significativamente!`,
    buttonConfirmText: 'OK, ENTENDI',
  };

  const configExcludeExperience = {
    modalWidth: 382,
    bubbleText: `Atenção!`,
    ParagraphText: `Deseja realmente excluir esta experiência?`,
    buttonConfirmText: 'SIM, EXCLUIR!',
    showButtonCancel: true,
    buttonCancelText: 'VOLTAR',
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  };

  const configExcludeProjects = {
    modalWidth: 382,
    bubbleText: `Atenção!`,
    ParagraphText: `Deseja realmente excluir este Projeto?`,
    buttonConfirmText: 'SIM, EXCLUIR!',
    showButtonCancel: true,
    buttonCancelText: 'VOLTAR',
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  };

  const configExcludeExtraCourses = {
    modalWidth: 382,
    bubbleText: `Atenção!`,
    ParagraphText: `Deseja realmente excluir este Curso extra?`,
    buttonConfirmText: 'SIM, EXCLUIR!',
    showButtonCancel: true,
    buttonCancelText: 'VOLTAR',
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  };

  const getIconColorOne = (value) => {
    return stepOneData && stepOneData[value] > 0 ? universityColor : '#C4C4C4';
  };

  const getInfoColorOne = (value) => {
    return stepOneData && stepOneData[value] > 0 ? universityColor : '#606062';
  };

  const getIconColorTwo = (value) => {
    return stepTwoData && stepTwoData[value] > 0 ? universityColor : '#C4C4C4';
  };

  const getInfoColorTwo = (value) => {
    return stepTwoData && stepTwoData[value] > 0 ? universityColor : '#606062';
  };

  return {
    configModalLevelOne,
    configModalLevelTwo,
    configModalLevelThree,

    getIconColorOne,
    getInfoColorOne,

    getIconColorTwo,
    getInfoColorTwo,
    configExcludeExperience,
    configExcludeProjects,
    configExcludeExtraCourses,
  };
};
