import React, { useContext } from 'react';
import { shape, func, bool, string } from 'prop-types';

import { FastField } from 'formik';

import {
  FormHelperText,
  List,
  ListItem,
  TextField,
  Checkbox,
  Collapse,
  FormControlLabel,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';
import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

const StepThreeForm = (props) => {
  const {
    classes,
    answered,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    colorScheme,
    setFieldValue,
    metadata,
  } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  const fields = [
    { label: 'Remuneração e promoção', field: 'remunerationAndPromotion' },
    { label: 'Contribuição', field: 'contribution' },
    { label: 'Competência', field: 'competence' },
    { label: 'Criatividade', field: 'creativity' },
    { label: 'Identidade', field: 'identity' },
    { label: 'Conexão vida-carreira', field: 'connection' },
  ];

  return (
    <List className={`${classes.list} ${classes.cardList}`}>
      {fields.map(({ label, field }) => (
        <ListItem
          className={`${classes.listItem} ${classes[colorScheme]}`}
          key={field}
        >
          <FormControlLabel
            classes={{
              root: classes.selectForm,
              label: classes.radioLabel,
            }}
            label={label}
            labelPlacement="end"
            tabIndex={0}
            disabled={
              (!values[`${field}Check`] && values.checkedCount >= 3) ||
              metadata.role === 'university'
            }
            error={errors.checkedCount && touched.checkedCount}
            checked={values[`${field}Check`]}
            name={`${field}Check`}
            id={`${field}Check`}
            onChange={(event) => {
              if (event.target.checked) {
                setFieldValue('checkedCount', values.checkedCount + 1);
              } else {
                setFieldValue('checkedCount', values.checkedCount - 1);
              }
              handleChange(event);
            }}
            control={
              <Checkbox
                classes={{
                  root: classes.trailsCheckBox,
                  checked:
                    classes[`${useDefineColor(universityColor)}checkBox`],
                }}
              />
            }
          />
          <Collapse in={values[`${field}Check`]} className={classes.fullWidth}>
            <FastField
              error={errors[field] && touched[field]}
              style={{ marginTop: 10 }}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.cssLabelYellow,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInputYellow,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              name={field}
              id={field}
              variant="outlined"
              label="Justifique"
              multiline
              rowsMax="15"
              fullWidth
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[field]}
              component={TextField}
              disabled={
                isSubmitting || answered || metadata.role === 'university'
              }
            />
            <FormHelperText className={classes.helperText}>
              {values[field] &&
                `Sua resposta tem ${values[field].length} caracteres.`}
            </FormHelperText>
            {errors[field] && touched[field] && (
              <FormHelperText className={classes.textAlgC}>
                {errors[field]}
              </FormHelperText>
            )}
          </Collapse>
        </ListItem>
      ))}
      {errors.checkedCount && touched.checkedCount && (
        <FormHelperText
          className={classNames([classes.textAlgC, classes.error])}
        >
          {errors.checkedCount}
        </FormHelperText>
      )}
    </List>
  );
};

StepThreeForm.propTypes = {
  classes: shape.isRequired,
  answered: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  isSubmitting: bool.isRequired,
  colorScheme: string.isRequired,
  setFieldValue: func.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(professionalExperienceFormStyles)(StepThreeForm);
