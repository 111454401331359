export default function SalaryValuesIcon({ color }) {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1533 12.5054C20.1533 15.6784 20.1533 18.837 20.1533 22.01C20.1533 23.4458 19.2632 24.5944 17.8992 24.9103C17.6551 24.9677 17.3967 24.9964 17.1526 24.9964C12.4864 24.9964 7.83458 24.9964 3.1684 24.9964C1.41678 24.9964 0.15332 23.7329 0.15332 21.967C0.15332 15.664 0.15332 9.34673 0.15332 3.04379C0.15332 1.26346 1.41678 0 3.21147 0C7.83458 0 12.4577 0 17.0808 0C18.9042 0 20.139 1.2491 20.1533 3.0725C20.1533 6.2168 20.1533 9.36109 20.1533 12.5054ZM0.986056 12.491C0.986056 15.6353 0.986056 18.7796 0.986056 21.9095C0.986056 23.2879 1.86186 24.1493 3.22582 24.1493C7.83458 24.1493 12.4433 24.1493 17.0521 24.1493C18.4448 24.1493 19.3206 23.2879 19.3206 21.8952C19.3206 15.621 19.3206 9.34673 19.3206 3.0725C19.3206 1.67983 18.4591 0.818379 17.0665 0.818379C12.4721 0.818379 7.8633 0.818379 3.2689 0.818379C1.86186 0.818379 1.00041 1.67983 1.00041 3.08686C0.986054 6.23115 0.986056 9.36109 0.986056 12.491Z"
        fill={color}
      />
      <path
        d="M10.1162 7.49239C7.81902 7.49239 5.53618 7.49239 3.23897 7.49239C2.29138 7.49239 1.80322 7.00424 1.80322 6.071C1.80322 5.05161 1.80322 4.01787 1.80322 2.99849C1.80322 2.18011 2.32009 1.66324 3.13847 1.66324C7.80466 1.66324 12.4709 1.66324 17.137 1.66324C17.9411 1.66324 18.4579 2.18011 18.4723 2.98413C18.4723 4.04659 18.4723 5.1234 18.4723 6.18586C18.4723 6.97552 17.9411 7.50675 17.137 7.50675C14.8111 7.50675 12.4709 7.49239 10.1162 7.49239ZM10.1449 6.65965C12.4565 6.65965 14.7824 6.65965 17.094 6.65965C17.496 6.65965 17.6396 6.51608 17.6396 6.11407C17.6396 5.09468 17.6396 4.06094 17.6396 3.04156C17.6396 2.63955 17.496 2.49597 17.094 2.49597C12.4565 2.49597 7.83338 2.49597 3.1959 2.49597C2.79389 2.49597 2.65031 2.63955 2.65031 3.04156C2.65031 4.04659 2.65031 5.05161 2.65031 6.05664C2.65031 6.53044 2.76517 6.65965 3.25333 6.65965C5.55053 6.65965 7.84773 6.65965 10.1449 6.65965Z"
        fill={color}
      />
      <path
        d="M13.4788 18.7412C13.4788 17.6788 13.4788 16.602 13.4788 15.5395C13.4788 14.6781 13.9956 14.1756 14.8571 14.1756C15.618 14.1756 16.379 14.1756 17.1399 14.1756C17.9439 14.1756 18.4608 14.6924 18.4608 15.4965C18.4608 17.6788 18.4608 19.8468 18.4608 22.0291C18.4608 22.8188 17.9296 23.3356 17.1399 23.35C16.3646 23.35 15.575 23.35 14.7996 23.35C13.9956 23.35 13.4788 22.8188 13.4788 22.0148C13.4788 20.9092 13.4788 19.8181 13.4788 18.7412ZM17.6424 18.77C17.6424 17.6932 17.6424 16.6163 17.6424 15.5395C17.6424 15.1519 17.4989 15.0083 17.1112 15.0083C16.3503 15.0083 15.6037 15.0083 14.8427 15.0083C14.4551 15.0083 14.3115 15.1519 14.3115 15.5395C14.3115 17.6788 14.3115 19.8324 14.3115 21.9717C14.3115 22.3593 14.4551 22.5029 14.8427 22.5029C15.5893 22.5029 16.3359 22.5029 17.0825 22.5029C17.5132 22.5029 17.6424 22.3737 17.6424 21.943C17.6568 20.8949 17.6424 19.8324 17.6424 18.77Z"
        fill={color}
      />
      <path
        d="M4.31484 9.17858C4.73115 9.17858 5.14745 9.17858 5.56376 9.17858C6.28153 9.19294 6.81268 9.72409 6.81268 10.4275C6.81268 10.9874 6.82704 11.5329 6.81268 12.0927C6.79833 12.7961 6.26718 13.3273 5.56376 13.3273C4.71679 13.3417 3.88417 13.3417 3.0372 13.3273C2.34814 13.3129 1.81699 12.7818 1.80264 12.0927C1.78828 11.5185 1.78828 10.9443 1.80264 10.3701C1.81699 9.69538 2.34814 9.17858 3.02285 9.14987C3.46787 9.16423 3.88417 9.17858 4.31484 9.17858ZM4.31484 10.0112C3.91289 10.0112 3.51093 10.0112 3.12334 10.0112C2.82187 10.0112 2.66396 10.1691 2.66396 10.4849C2.66396 11.0017 2.66396 11.5329 2.66396 12.0497C2.66396 12.3511 2.82187 12.509 3.13769 12.509C3.9416 12.509 4.73115 12.509 5.53505 12.509C5.83651 12.509 5.99442 12.3511 5.99442 12.0353C5.99442 11.5185 5.99442 11.0161 5.99442 10.4993C5.99442 10.1547 5.83651 10.0112 5.49198 10.0112C5.09003 9.99684 4.70243 10.0112 4.31484 10.0112Z"
        fill={color}
      />
      <path
        d="M10.146 9.17786C10.5623 9.17786 10.9787 9.17786 11.395 9.17786C12.0984 9.19221 12.6295 9.72337 12.6439 10.4124C12.6582 10.9723 12.6582 11.5465 12.6439 12.1064C12.6295 12.7954 12.0984 13.3409 11.395 13.3409C10.5623 13.3553 9.72973 13.3553 8.89711 13.3409C8.17934 13.3409 7.66255 12.7954 7.64819 12.0777C7.64819 11.5322 7.64819 10.9866 7.64819 10.4411C7.66255 9.70901 8.1937 9.19221 8.92583 9.17786C9.34213 9.1635 9.74408 9.17786 10.146 9.17786ZM10.146 10.0105C9.75844 10.0105 9.37084 10.0105 8.96889 10.0105C8.62436 10.0105 8.48081 10.1684 8.46645 10.4986C8.46645 11.001 8.46645 11.5034 8.46645 12.0059C8.46645 12.3504 8.62436 12.494 8.95454 12.5083C9.72973 12.5083 10.5193 12.5083 11.2945 12.5083C11.639 12.5083 11.7826 12.3504 11.7969 12.0202C11.7969 11.5178 11.7969 11.0154 11.7969 10.5129C11.7969 10.1684 11.639 10.0248 11.3088 10.0105C10.9356 9.99612 10.548 10.0105 10.146 10.0105Z"
        fill={color}
      />
      <path
        d="M15.9915 9.17246C16.3934 9.17246 16.8097 9.17246 17.2117 9.17246C17.9438 9.18682 18.475 9.70362 18.475 10.4357C18.475 10.9813 18.475 11.5268 18.475 12.0723C18.4606 12.79 17.9295 13.3212 17.226 13.3212C16.3934 13.3355 15.5608 13.3355 14.7282 13.3212C14.0248 13.3068 13.4936 12.7757 13.4793 12.0866C13.4649 11.5268 13.4649 10.9525 13.4793 10.3927C13.4936 9.70361 14.0248 9.17247 14.6995 9.15811C15.1445 9.15811 15.5608 9.17246 15.9915 9.17246ZM15.9628 12.5029C16.3647 12.5029 16.7667 12.5029 17.1543 12.5029C17.4844 12.5029 17.6424 12.345 17.6424 12.0292C17.6424 11.5124 17.6424 11.01 17.6424 10.4932C17.6424 10.163 17.4844 10.0051 17.1686 10.0051C16.3791 10.0051 15.5895 10.0051 14.8 10.0051C14.4698 10.0051 14.3119 10.163 14.3119 10.4788C14.3119 10.9956 14.3119 11.498 14.3119 12.0148C14.3119 12.345 14.4698 12.5029 14.8143 12.5029C15.2019 12.5029 15.5895 12.5029 15.9628 12.5029Z"
        fill={color}
      />
      <path
        d="M4.31857 18.3386C3.91662 18.3386 3.50031 18.3386 3.09836 18.3386C2.36623 18.3243 1.83508 17.7931 1.83508 17.061C1.83508 16.5155 1.83508 15.97 1.83508 15.4245C1.84944 14.7067 2.38059 14.1756 3.09836 14.1756C3.91662 14.1756 4.74924 14.1756 5.5675 14.1756C6.29962 14.1756 6.83078 14.7211 6.83078 15.4532C6.83078 15.9987 6.83078 16.5442 6.83078 17.0897C6.81642 17.8075 6.28527 18.3386 5.5675 18.3386C5.15119 18.3386 4.73488 18.3386 4.31857 18.3386ZM4.30422 17.506C4.70617 17.506 5.10812 17.506 5.49572 17.506C5.82589 17.506 5.9838 17.3481 5.9838 17.0323C5.9838 16.5155 5.9838 16.0131 5.9838 15.4963C5.9838 15.1661 5.8259 15.0082 5.51008 15.0082C4.72053 15.0082 3.93098 15.0082 3.14143 15.0082C2.81125 15.0082 2.65334 15.1661 2.65334 15.4819C2.65334 15.9987 2.65334 16.5011 2.65334 17.0179C2.65334 17.3481 2.81125 17.506 3.15578 17.506C3.54338 17.506 3.91662 17.506 4.30422 17.506Z"
        fill={color}
      />
      <path
        d="M10.1173 18.333C9.71537 18.333 9.31342 18.333 8.92583 18.333C8.1937 18.3186 7.66255 17.8018 7.64819 17.0697C7.64819 16.5242 7.64819 15.9787 7.64819 15.4332C7.66255 14.7154 8.1937 14.1842 8.89711 14.1699C9.72973 14.1555 10.5623 14.1555 11.395 14.1699C12.0984 14.1699 12.6295 14.7154 12.6439 15.4045C12.6582 15.9643 12.6582 16.5385 12.6439 17.0984C12.6295 17.7874 12.0984 18.3186 11.395 18.333C10.9787 18.333 10.548 18.333 10.1173 18.333ZM10.1317 17.5003C10.5336 17.5003 10.9356 17.5003 11.3232 17.5003C11.6534 17.5003 11.8113 17.3424 11.8113 17.0266C11.8113 16.5098 11.8113 16.0074 11.8113 15.4906C11.8113 15.1604 11.6534 15.0025 11.3375 15.0025C10.548 15.0025 9.75844 15.0025 8.96889 15.0025C8.63872 15.0025 8.49516 15.1604 8.48081 15.4762C8.48081 15.993 8.48081 16.4955 8.48081 17.0123C8.48081 17.3424 8.63872 17.486 8.95454 17.5003C9.35649 17.5003 9.74409 17.5003 10.1317 17.5003Z"
        fill={color}
      />
      <path
        d="M4.30057 23.3273C3.89861 23.3273 3.48231 23.3273 3.08036 23.3273C2.34823 23.313 1.81708 22.7818 1.81708 22.0497C1.81708 21.5042 1.81708 20.9587 1.81708 20.4132C1.83143 19.6954 2.36258 19.1643 3.08036 19.1643C3.89861 19.1643 4.73123 19.1643 5.54949 19.1643C6.26726 19.1643 6.79841 19.6954 6.81277 20.4132C6.82712 20.9731 6.82712 21.5186 6.81277 22.0784C6.79841 22.7962 6.26726 23.3273 5.54949 23.3273C5.13318 23.3273 4.71687 23.3273 4.30057 23.3273ZM4.28621 22.4947C4.68816 22.4947 5.09011 22.4947 5.47771 22.4947C5.82224 22.4947 5.9658 22.3368 5.9658 22.0066C5.9658 21.5042 5.9658 21.0018 5.9658 20.4993C5.9658 20.1548 5.80789 20.0112 5.47771 20.0112C4.70252 20.0112 3.91297 20.0112 3.13778 20.0112C2.79325 20.0112 2.64969 20.1692 2.64969 20.4993C2.64969 21.0018 2.64969 21.5042 2.64969 22.0066C2.64969 22.3512 2.8076 22.4947 3.13778 22.4947C3.52537 22.4947 3.91297 22.4947 4.28621 22.4947Z"
        fill={color}
      />
      <path
        d="M10.1584 23.3273C9.74206 23.3273 9.32575 23.3273 8.90944 23.3273C8.19167 23.313 7.66052 22.7818 7.66052 22.0641C7.66052 21.5186 7.66052 20.9731 7.66052 20.4276C7.67488 19.6954 8.20603 19.1643 8.93815 19.1643C9.75641 19.1643 10.5747 19.1643 11.3786 19.1643C12.1251 19.1643 12.6562 19.6954 12.6562 20.4419C12.6562 20.9731 12.6562 21.5186 12.6562 22.0497C12.6419 22.7818 12.1107 23.313 11.3786 23.313C10.9623 23.3273 10.5603 23.3273 10.1584 23.3273ZM10.144 22.4947C10.5316 22.4947 10.9192 22.4947 11.3212 22.4947C11.6657 22.4947 11.8092 22.3368 11.8092 22.0066C11.8092 21.5042 11.8092 21.0018 11.8092 20.4993C11.8092 20.1548 11.6513 20.0112 11.3212 20.0112C10.546 20.0112 9.75641 20.0112 8.98122 20.0112C8.63669 20.0112 8.49314 20.1692 8.49314 20.4993C8.49314 21.0018 8.49314 21.5042 8.49314 22.0066C8.49314 22.3512 8.65105 22.4947 8.98122 22.4947C9.36882 22.4947 9.75641 22.4947 10.144 22.4947Z"
        fill={color}
      />
      <path
        d="M14.7128 4.59624C14.7415 4.3522 14.7272 4.09381 14.7846 3.84977C14.9282 3.24684 15.5167 2.85924 16.1197 2.93102C16.7226 3.00279 17.1963 3.49088 17.225 4.09381C17.2394 4.42398 17.2394 4.75415 17.225 5.08433C17.1963 5.74468 16.6365 6.26147 15.9761 6.26147C15.3158 6.26147 14.7702 5.73032 14.7415 5.06998C14.7415 4.91207 14.7415 4.75415 14.7415 4.59624C14.7272 4.59624 14.7128 4.59624 14.7128 4.59624ZM16.3924 4.56754C16.3924 4.43834 16.3924 4.30914 16.3924 4.17994C16.3781 3.9359 16.2058 3.76363 15.9761 3.76363C15.7464 3.76363 15.5742 3.92154 15.5598 4.17994C15.5454 4.45269 15.5454 4.7398 15.5598 5.01255C15.5742 5.25659 15.7464 5.42886 15.9618 5.42886C16.1914 5.42886 16.3637 5.27095 16.3781 5.01255C16.4068 4.85464 16.3924 4.71109 16.3924 4.56754Z"
        fill="#5b0e62"
      />
    </svg>
  );
}
