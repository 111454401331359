import { authenticatedAPI } from 'services/api';

const getCoverLetter = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/docs/cover-letter`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveCoverLetter = async (answers) => {
  const response = authenticatedAPI
    .put(`/api/internal/students/docs/cover-letter`, answers)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getCoverLetter, saveCoverLetter };
