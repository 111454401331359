import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 27px;
  font-weight: 700;
  font-size: 12px;
  color: #009291;
  background-color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 0;
  margin-left: ${(props) => (props.removeMarginLeft ? '0px' : '5px')};
  cursor: pointer;
`;
