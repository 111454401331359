import styled from 'styled-components';

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 4px;
  margin-top: -2px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  > div {
    ${(props) => props.error && 'border: 2px solid red; border-radius: 5px;'}
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
  margin: 5px 0;
  margin-top: -3px;
`;
