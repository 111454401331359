import React, { createContext, useEffect, useState } from 'react';
import {
  getStudentsList,
  getNextResults,
} from '../services/IndicationStudent.service';

export const IndicationStudentContext = createContext();

export default function IndicationStudentProvider({
  children,
  job,
  step = 2,
  students = [],
  next = null,
}) {
  const [stepNumber, setStepNumber] = useState(step);
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const [studentsData, setStudentsData] = useState(students);
  const [showSkeletonItens, setshowSkeletonItens] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);

  const handleOpenStudentModal = (value = true) => {
    if (value !== openStudentModal) {
      setOpenStudentModal(value);
    }
  };

  useEffect(() => {
    setNextUrl(next);
  }, [next]);

  function toStepTwo() {
    setStepNumber(2);
  }

  const getStudents = async (filters = {}) => {
    toStepTwo();
    setshowSkeletonItens(true);
    try {
      const data = await getStudentsList(job.id, filters);
      const { results, next } = data;
      setStudentsData(results);
      setNextUrl(next);
      setshowSkeletonItens(false);
    } catch (e) {}
  };

  const loadNext = async () => {
    const data = await getNextResults(nextUrl);
    const { results, next } = data;
    setNextUrl(next);
    setStudentsData([...studentsData, ...results]);
  };

  return (
    <IndicationStudentContext.Provider
      value={{
        stepNumber,
        toStepTwo,
        job: job,
        openStudentModal,
        handleOpenStudentModal: handleOpenStudentModal,
        students: studentsData,
        showSkeletonItens: showSkeletonItens,
        getStudents: getStudents,
        loadNext: loadNext,
        nextUrl: nextUrl,
      }}
    >
      {children}
    </IndicationStudentContext.Provider>
  );
}
