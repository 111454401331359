import React, { useContext, useEffect, useState } from 'react';
import { ContentTabs } from '../styled';
import ApplicationCardJobs from '../../NewCards/ApplicationCards/ApplicationCards';
import FinishedJobCards from '../../NewCards/ApplicationCards/FinishedCards';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import SkeletonCardsWithStatus from '../../GenericComponents/SkeletonCards/SkeletonCardsWithStatus';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import { GenericInternalSwitch } from 'components/GenericInternalSwitch';

export default function TabApplicationJobs(props) {
  const {
    listApplicationJobs,
    setListApplicationJobs,
    listFinishedJobs,
    setListFinishedJobs,
    isLoadingApplications,
    setIsLoadingApplications,
    firstName,
  } = useContext(JobOffersContext);
  const { universityColor } = props;
  const [activeSubTab, setActiveSubTab] = useState(0);

  const fetchListApplicationJobOffers = async (query = '', fromSeeMore) => {
    const data = await JobOffersApi.getApplicationJobs(query);
    if (setListApplicationJobs) {
      if (fromSeeMore) {
        setListApplicationJobs((prevs) => ({
          ...data,
          data: {
            ...data.data,
            results: {
              ...data.data.results,
              results: [
                ...prevs.data.results?.results,
                ...data.data.results?.results,
              ],
            },
          },
        }));
        return;
      }
      setListApplicationJobs(data);
    }
  };

  const fetchListFinishedJobOffers = async (query = '', fromSeeMore) => {
    const data = await JobOffersApi.getFinishedJobs(query);
    if (setListFinishedJobs) {
      if (fromSeeMore) {
        setListFinishedJobs((prevs) => ({
          ...data,
          data: {
            ...data.data,
            results: {
              ...data.data.results,
              results: [
                ...prevs.data.results?.results,
                ...data.data.results?.results,
              ],
            },
          },
        }));
        return;
      }
      setListFinishedJobs(data);
    }
  };

  useEffect(() => {
    fetchListApplicationJobOffers();
    fetchListFinishedJobOffers();
  }, []);

  const countApplicationJobs = listApplicationJobs?.data?.count || 0;
  const countFinishedJobs = listFinishedJobs?.data?.count || 0;

  const returnContentApplication =
    listApplicationJobs === 0 || listApplicationJobs === null ? (
      <SkeletonCardsWithStatus />
    ) : (
      <ApplicationCardJobs
        firstName={firstName}
        universityColor={universityColor}
        listApplicationJobs={listApplicationJobs}
        fetchListApplicationJobOffers={fetchListApplicationJobOffers}
        isLoadingApplications={isLoadingApplications}
        setIsLoadingApplications={setIsLoadingApplications}
      />
    );

  const returnContentFinished =
    listFinishedJobs === 0 || listFinishedJobs === null ? (
      <SkeletonCardsWithStatus />
    ) : (
      <FinishedJobCards
        listFinishedJobs={listFinishedJobs}
        universityColor={universityColor}
        fetchListFinishedJobOffers={fetchListFinishedJobOffers}
      />
    );

  const Tabs = [
    {
      activeSwitch: 0,
      component: returnContentApplication,
    },
    {
      activeSwitch: 1,
      component: returnContentFinished,
    },
  ];

  const handleChangeTab = (value) => {
    setActiveSubTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeSwitch === step);
    return returnTabs.component;
  };

  return (
    <ContentTabs>
      <GenericInternalSwitch
        activeTab={activeSubTab}
        handleChange={handleChangeTab}
        tabs={[
          `Em andamento (${countApplicationJobs})`,
          `Finalizadas (${countFinishedJobs})`,
        ]}
      />
      {renderPrincipalTabs(activeSubTab)}
    </ContentTabs>
  );
}
