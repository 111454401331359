import { authenticatedAPI } from 'services/api';

const getAreas = () => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/areas`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterAreas = (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/areas?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAreaDetails = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/areas/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveArea = (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/backoffice/areas`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const checkName = (name) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/areas/exists?name=${name}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const editArea = (data, id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/backoffice/areas/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteAreas = (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/backoffice/areas/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAreasOptions = () => {
  const response = authenticatedAPI
    .options(`/api/internal/backoffice/areas`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getFilterAreas,
  getAreas,
  getAreaDetails,
  deleteAreas,
  getAreasOptions,
  editArea,
  saveArea,
  checkName,
};
