import React, { createContext, useEffect, useRef, useState } from 'react';

export const OpportunitiesCompanyContext = createContext();

export default function OportunitiesCompanyProvider({ children }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const [step, setStep] = useState(0);

  const [formData, setFormData] = useState({});

  const [macroAreas, setMacroAreas] = useState(null);
  const [interests, setInterests] = useState(null);
  const [competencies, setCompetencies] = useState(null);

  const [cacheOptions, setCacheOptions] = useState([]);
  const [selectOption, setSelectOption] = useState('');
  const [optionsId, setOptionsId] = useState('');

  const [events, setEvents] = useState(null);
  const [courses, setCourses] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [communicate, setCommunicate] = useState(null);
  const [trails, setTrails] = useState(null);
  const [ebooks, setEbooks] = useState(null);
  const [article, setArticle] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [serie, setSerie] = useState(null);

  const [course, setCourse] = useState([]);
  const [periods, setPeriods] = useState([]);

  const stepOneRef = useRef(null);

  const setFormValues = (values) => {
    setFormData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  useEffect(() => {
    if (formData.course_category && formData.course_category.value) {
      formData.course_category = formData.course_category.value;
    }

    if (formData.category && formData.category.value) {
      formData.category = formData.category.value;
    }

    if (formData.type !== 'course' && formData.course_category) {
      formData.course_category = null;
    }
    if (formData.type !== 'program' && formData.category) {
      formData.category = null;
    }

    if (
      formData.type_access &&
      formData.type_access !== 'video' &&
      formData.content_pages
    ) {
      formData.content_pages = [];
    }

    if (
      formData.type_access &&
      formData.type_access !== 'attraction_document' &&
      formData.attraction_document
    ) {
      formData.attraction_document = null;
    }
    if (
      formData.type_access &&
      formData.type_access !== 'access_link' &&
      formData.access_link
    ) {
      formData.access_link = '';
    }

    if (
      formData.competences_specialities &&
      Array.isArray(formData.competences_specialities) &&
      !formData.competences_specialities.length
    ) {
      delete formData.competences_specialities;
    }

    if (formData.access_link) {
      var prefix = 'http://';
      if (formData.access_link.includes('http://') === false) {
        if (formData.access_link.includes('https://') === false) {
          formData.access_link = prefix + formData.access_link;
        }
      }
    }
  }, [formData]);

  return (
    <OpportunitiesCompanyContext.Provider
      value={{
        modalIsOpen,
        setModalIsOpen,
        modalContent,
        setModalContent,
        step,
        setStep,
        formData,
        setFormValues,
        macroAreas,
        setMacroAreas,
        interests,
        setInterests,
        competencies,
        setCompetencies,
        optionsId,
        setOptionsId,
        selectOption,
        setSelectOption,
        setFormData,
        cacheOptions,
        setCacheOptions,
        events,
        setEvents,
        courses,
        setCourses,
        programs,
        setPrograms,
        communicate,
        setCommunicate,
        trails,
        setTrails,
        ebooks,
        setEbooks,
        article,
        setArticle,
        challenge,
        setChallenge,
        serie,
        setSerie,
        stepOneRef,
        course,
        setCourse,
        periods,
        setPeriods,
      }}
    >
      {children}
    </OpportunitiesCompanyContext.Provider>
  );
}
