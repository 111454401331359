import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

export const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #d9d6d6;
  background-image: linear-gradient(
    120deg,
    #d9d6d6 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #d9d6d6 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  height: 47px;
  border-radius: 5px;
  width: 100%;

  margin: 30px 0;

  @media (max-width: 768px) {
    height: 40px;
  }

  @media (max-width: 375px) {
    height: 30px;
  }
`;

export const Subtitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #d9d6d6;
  background-image: linear-gradient(
    120deg,
    #d9d6d6 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #d9d6d6 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  height: 35px;
  width: 300px;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 40px;
    width: 250px;
  }

  @media (max-width: 375px) {
    height: 30px;
    width: 150px;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  height: 20px;
  width: 200px;
  border-radius: 5px;

  margin-bottom: 10px;
`;

export const ContentBox = styled(TitleBox)`
  height: 30px;
  width: ${(props) => props.widthContent};
`;

export const Divider = styled.div`
  margin: 20px 0;
`;

export const Box = styled.div`
  min-height: 450px;
  width: 640px;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  padding: 30px;
  border-radius: 15px;

  @media (max-width: 768px) {
    padding: 1rem;
    max-width: 640px;
  }

  @media (max-width: 375px) {
    max-width: 640px;
  }
`;
