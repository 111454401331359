import React, { Fragment, useEffect, useState } from 'react';
import { string, func, shape } from 'prop-types';
import { getLiveQuizAnswer, applyLiveQuiz } from 'services/student/quizzes';
import { liveQuizAdapter } from 'adapters/student/liveQuizAdapter';

import { Grid, CircularProgress } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';

const LivesModal = (props) => {
  const {
    classes,
    videoId,
    Form,
    openSnackbar,
    handleIsLoadingState,
    metadata: { university_slug },
    academic_profile,
    metadata,
  } = props;
  const [initialValues, setInitialValues] = useState({});

  const fetchQuizAnswer = async () => {
    const response = await getLiveQuizAnswer(
      videoId,
      university_slug,
      academic_profile.external_id
    );

    if (!response.message) {
      setInitialValues(response);
    } else {
      openSnackbar(
        'Falha ao carregar as respostas! Tente novamente mais tarde.',
        true
      );
      setInitialValues(false);
    }
  };

  useEffect(() => {
    if (Form && metadata.role === 'student') fetchQuizAnswer();
  }, []);

  const handleSubmit = async (answers) => {
    handleIsLoadingState(true);

    const payload = liveQuizAdapter(
      videoId,
      university_slug,
      academic_profile.external_id,
      answers
    );

    const response = await applyLiveQuiz(payload);

    if (!response.message) {
      setInitialValues(response);
      openSnackbar('Sua resposta foi enviada com sucesso!');
    } else {
      openSnackbar(
        'Falha ao enviar sua resposta! Tente novamente mais tarde.',
        true
      );
    }

    handleIsLoadingState(false);
  };

  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <iframe
            title={`série ${videoId}`}
            className={classes.videos}
            src={`https://www.youtube.com/embed/${videoId}?rel=0`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          {Form &&
            (initialValues ? (
              <Form
                answered={initialValues.answered}
                applyLiveQuiz={handleSubmit}
                initialValues={
                  initialValues.answered ? initialValues.answers : {}
                }
                metadata={metadata}
              />
            ) : (
              <CircularProgress className={classes.loading} />
            ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

LivesModal.propTypes = {
  classes: shape({}).isRequired,
  videoId: string.isRequired,
  openSnackbar: func.isRequired,
  handleIsLoadingState: func.isRequired,
  metadata: shape({}).isRequired,
  academic_profile: shape({}).isRequired,
  Form: func.isRequired,
};

export default withStyles(behavioralTestStyle)(LivesModal);
