import {
  dnaGreen,
  grayLightColor,
  dnaColor,
} from 'assets/jss/material-dashboard-pro-react';

const backOfficeStyles = (theme) => {
  return {
    dateFiltersTitle: {
      marginTop: '12px',
      display: 'block',
    },
    listItemPaddingZeroSides: {
      flexDirection: 'column',
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      '& > p': {
        width: '100%',
      },
    },
    selectSection: {
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      margin: '0 auto',
      fontSize: 17,
      '& .react-select__menu': {
        zIndex: 999,
      },
      '& > div:nth-child(1)': {
        height: 56,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 15,
        },
      },
      '& > div:nth-child(2)': {
        height: 56,
        border: 0,
        borderRadius: 10,
        '& > div': {
          marginLeft: 4,
          marginTop: 15,
        },
      },
    },
    selectSectionAutoHeight: {
      '& > div:nth-child(1)': {
        paddingTop: 10,
        height: 'auto',
      },
      '& > div:nth-child(2)': {
        paddingTop: 10,
        height: 'auto',
      },
      '& > div:last-child': {
        display: 'none',
      },
    },
    selectSectionCard: {
      width: '100%',
      borderWidth: 1,
      borderColor: grayLightColor,
      borderStyle: 'solid',
      borderRadius: 10,
    },
    input: {
      color: 'rgba(0, 0, 0, 0.54)',
      '& root': {
        backgroundColor: '#fff!important',
        borderRadius: 10,
      },
      borderRadius: 10,
      backgroundColor: '#fff',
      '&:before': {
        borderBottom: 0,
        borderRadius: 10,
      },
      '&:after': {
        borderBottom: 0,
        borderRadius: 10,
        backgroundColor: 'transparent',
        visibility: 'hidden',
      },
      '&:hover:before': {
        backgroundColor: '#fff',
        borderBottom: 0,
        borderRadius: 10,
        visibility: 'hidden',
      },
      '&:focus': {
        backgroundColor: '#fff',
        color: 'black',
      },
      '&:active': {
        backgroundColor: '#fff',
        color: 'black',
      },
      '& focused': {
        backgroundColor: '#fff',
        borderBottom: 0,
      },
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&:after:hover': {
        backgroundColor: 'transparent',
      },
    },
    helperText: {
      width: '100%',
      textAlign: 'right',
    },
    inputField: {
      color: `${dnaGreen} !important`,
      borderBottom: 0,
      margin: '0 auto',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& root': {
        backgroundColor: '#fff !important',
        borderRadius: 10,
      },
    },
    resetButton: {
      color: dnaColor,
      padding: 0,
    },
  };
};

export default backOfficeStyles;
