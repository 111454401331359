export const PayloadPreRegistrationComapany = (data, ies) => {
  return {
    organization_number: data.organizationNumber,
    legal_name: data.corporateName,
    city: data.city,
    state: data.state,
    staff_name: data.staffName,
    staff_email: data.staffEmail.toLowerCase(),
    staff_phone: data.staffPhone,
    send_invite_email: Boolean(data.sendInviteEmail),
  };
};

export const PayloadDocuments = (documents) => {
  return {
    documentOne: documents && documents[0] ? documents[0].value : null,
    documentTwo: documents && documents[1] ? documents[1].value : null,
  };
};
