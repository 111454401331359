import React, { useEffect, useState } from 'react';

import { Box, Container, ContainerIframe, Content } from './styled';
import { LivestreamHelper } from '../../Helpers/LivestreamHelper';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import { VisualizationHandler } from '../../Handlers/VisualizationHandler';

function LiveStreamBottomModal() {
  const livestreamHelperFactory = LivestreamHelper();
  const visualizationContextInstance = useVisualizationContext();

  const [videoId, setVideoId] = useState('');

  const { modalTicketData, livestreamModalOpen } = visualizationContextInstance;
  const { event_url, name } = modalTicketData || {};

  useEffect(() => {
    const videoId = livestreamHelperFactory.getIdFromLive(event_url);
    setVideoId(videoId);
  }, [event_url]);

  if (!livestreamModalOpen) {
    return null;
  }

  return (
    <Container>
      <Box>
        <Content>
          <CloseButton
            onClick={() =>
              VisualizationHandler.changeLivestreamModalOpen(
                visualizationContextInstance
              )
            }
          />
          <h4>{name}</h4>
          <ContainerIframe>
            <iframe
              src={`https://www.youtube.com/embed/${videoId}?rel=0`}
            ></iframe>
          </ContainerIframe>
        </Content>
      </Box>
    </Container>
  );
}

export default LiveStreamBottomModal;
