import FilterModal from 'components/FilterModal';
import React, { useState, useEffect, useContext } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import {
  FilterGrid,
  ContainerFlexDirection,
  SpanError,
  ContainerRow,
} from './styled';
import { shape } from 'prop-types';
import {
  getAttractionsFilterOptions,
  getAttractionsEducationGroupFilterOptions,
  getAttractionsEducationGroupUniversityFilterOptions,
} from 'services/university/events';
import { ListPublishedByTheGroupContext } from 'views/StaffPages/Opportunities/Context/ListPublishedByTheGroupContext';
import { useLearningAPICalls } from '../../../hooks/apiCallsOpportunities';
import { ListOfPublishedLearningContext } from 'views/StaffPages/Opportunities/Context/ListOfPublishedLearningContext';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterLearning({
  appliedFilters,
  setAppliedFilters,
  filterState,
  setFilterState,
  filterOptions,
  setFilterOptions,
  educationalGroup,
  activeInternalTab,
}) {
  const {
    setEvents,
    setCourses,
    setPrograms,
    setCommunicate,
    setTrails,
    setEbooks,
    setArticle,
    setChallenge,
    setSerie,
  } = useContext(ListOfPublishedLearningContext);

  const [loading, setLoading] = useState(false);

  const {
    getAllEvents,
    getAllPrograms,
    getAllTrails,
    getAllArticles,
    getAllSeries,
    getAllEbooks,
    getAllChallenges,
    getAllAnnouncements,
    getAllCourses,
  } = useLearningAPICalls();

  const setLearnings = () => {
    setEvents(null);
    setCourses(null);
    setPrograms(null);
    setCommunicate(null);
    setTrails(null);
    setEbooks(null);
    setArticle(null);
    setChallenge(null);
    setSerie(null);
  };

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setLoading(true);
        const response = educationalGroup
          ? await getAttractionsEducationGroupUniversityFilterOptions()
          : await getAttractionsFilterOptions();
        setFilterOptions(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  function renderFilterContent() {
    return [
      <ContainerFlexDirection>
        <FilterGrid>
          <div>
            <Filter
              name={'name'}
              label={'Filtrar por nome'}
              type={FilterType.TEXT}
            />
          </div>
        </FilterGrid>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'macro_areas'}
                label={'Área de atuação'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.macro_areas}
              />
            </div>
          </FilterGrid>
          <FilterGrid>
            <div>
              <Filter
                name={'courses'}
                label={'Curso'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.courses}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'periods'}
                label={'Período'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.periods}
              />
            </div>
          </FilterGrid>
          <FilterGrid>
            <div>
              <Filter
                name={'modality'}
                label={'Modalidade'}
                type={FilterType.SINGLE_SELECT}
                options={filterOptions.modality}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'competences'}
                label={'Competências'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.competences}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'status'}
                label={'Status'}
                type={FilterType.SINGLE_SELECT}
                options={filterOptions.status}
              />
            </div>
          </FilterGrid>
          <FilterGrid>
            <div>
              <Filter
                name={'responsible'}
                label={'Responsável pela publicação'}
                type={FilterType.SINGLE_SELECT}
                options={filterOptions.responsible}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
      </ContainerFlexDirection>,
    ];
  }

  function onApplyFilters() {
    setLearnings();

    if (educationalGroup) {
      getAllEvents(educationalGroup, true, null, filterState);
      getAllCourses(educationalGroup, true, null, filterState);
      getAllPrograms(educationalGroup, true, null, filterState);
      getAllAnnouncements(educationalGroup, true, null, filterState);
      getAllTrails(educationalGroup, true, null, filterState);
      getAllEbooks(educationalGroup, true, null, filterState);
      getAllArticles(educationalGroup, true, null, filterState);
      getAllChallenges(educationalGroup, true, null, filterState);
      getAllSeries(educationalGroup, true, null, filterState);
      setAppliedFilters(filterState);
    }
    if (!educationalGroup) {
      getAllEvents(false, true, null, filterState);
      getAllCourses(false, true, null, filterState);
      getAllPrograms(false, true, null, filterState);
      getAllAnnouncements(false, true, null, filterState);
      getAllTrails(false, true, null, filterState);
      getAllEbooks(false, true, null, filterState);
      getAllArticles(false, true, null, filterState);
      getAllChallenges(false, true, null, filterState);
      getAllSeries(false, true, null, filterState);
      setAppliedFilters(filterState);
    }
  }

  function onClearFilters() {
    setLearnings();
    if (educationalGroup) {
      getAllEvents(educationalGroup, true);
      getAllCourses(educationalGroup, true);
      getAllPrograms(educationalGroup, true);
      getAllAnnouncements(educationalGroup, true);
      getAllTrails(educationalGroup, true);
      getAllEbooks(educationalGroup, true);
      getAllArticles(educationalGroup, true);
      getAllChallenges(educationalGroup, true);
      getAllSeries(educationalGroup, true);
      setAppliedFilters(filterState);
    }
    if (!educationalGroup) {
      getAllEvents(false, true);
      getAllCourses(false, true);
      getAllPrograms(false, true);
      getAllAnnouncements(false, true);
      getAllTrails(false, true);
      getAllEbooks(false, true);
      getAllArticles(false, true);
      getAllChallenges(false, true);
      getAllSeries(false, true);
      setAppliedFilters(filterState);
    }
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loading}
      disabled={loading}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}

FilterLearning.propTypes = {
  filterOptions: shape.isRequired,
};
