import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Span, Wrapper } from './styled';

function Tooltip({
  children,
  text,
  direction = 'right',
  isMultiLine = false,
  maxWidth = '200px',
  minWidth = 'fit-content',
  spacing = '8px',
  divProps,
  spanProps,
  arrowOffset = '0px',
  colorSchema = 'dnaGreen',
  textColor,
}) {
  const [positionX, setPositionX] = useState(0);
  useEffect(() => {
    let refSpan = document.getElementById('spanRef');
    const { x } = refSpan.getBoundingClientRect();
    setPositionX(x);
  }, []);
  return (
    <Wrapper {...divProps} textColor={textColor}>
      {children}
      <Span
        direction={direction}
        arrowOffset={arrowOffset}
        spacing={spacing}
        maxWidth={maxWidth}
        minWidth={minWidth}
        isMultiLine={isMultiLine}
        colorSchema={colorSchema}
        {...spanProps}
        id="spanRef"
        positionX={positionX}
      >
        {ReactHtmlParser(text)}
      </Span>
    </Wrapper>
  );
}

export default Tooltip;
