import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 18px 0 #0000001a;
  padding: 2rem;
  gap: 1rem;

  > strong {
    color: #606062;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
  }

  > p,
  b {
    color: #606062;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
`;
