export function IconInformation({ color }) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_25150_3273)">
        <path
          d="M9 0.46875C7.21997 0.46875 5.47991 0.996591 3.99987 1.98552C2.51983 2.97446 1.36628 4.38006 0.685088 6.0246C0.00389957 7.66913 -0.17433 9.47873 0.172937 11.2246C0.520204 12.9704 1.37737 14.574 2.63604 15.8327C3.89472 17.0914 5.49836 17.9486 7.24419 18.2958C8.99002 18.6431 10.7996 18.4649 12.4442 17.7837C14.0887 17.1025 15.4943 15.9489 16.4832 14.4689C17.4722 12.9888 18 11.2488 18 9.46875C17.9974 7.08259 17.0484 4.79491 15.3611 3.10764C13.6738 1.42037 11.3862 0.471331 9 0.46875ZM9 16.9688C7.51664 16.9688 6.0666 16.5289 4.83323 15.7048C3.59986 14.8807 2.63856 13.7093 2.07091 12.3389C1.50325 10.9684 1.35473 9.46043 1.64411 8.00557C1.9335 6.55072 2.64781 5.21434 3.6967 4.16545C4.7456 3.11656 6.08197 2.40225 7.53683 2.11286C8.99168 1.82347 10.4997 1.972 11.8701 2.53965C13.2406 3.10731 14.4119 4.0686 15.236 5.30197C16.0601 6.53534 16.5 7.98539 16.5 9.46875C16.4978 11.4572 15.7069 13.3636 14.3009 14.7696C12.8948 16.1757 10.9885 16.9666 9 16.9688Z"
          fill={color}
        />
        <path
          d="M9 7.96875H8.25C8.05109 7.96875 7.86032 8.04777 7.71967 8.18842C7.57902 8.32907 7.5 8.51984 7.5 8.71875C7.5 8.91766 7.57902 9.10843 7.71967 9.24908C7.86032 9.38973 8.05109 9.46875 8.25 9.46875H9V13.9688C9 14.1677 9.07902 14.3584 9.21967 14.4991C9.36032 14.6397 9.55109 14.7188 9.75 14.7188C9.94891 14.7188 10.1397 14.6397 10.2803 14.4991C10.421 14.3584 10.5 14.1677 10.5 13.9688V9.46875C10.5 9.07093 10.342 8.68939 10.0607 8.40809C9.77936 8.12679 9.39782 7.96875 9 7.96875Z"
          fill={color}
        />
        <path
          d="M9 6.46875C9.62132 6.46875 10.125 5.96507 10.125 5.34375C10.125 4.72243 9.62132 4.21875 9 4.21875C8.37868 4.21875 7.875 4.72243 7.875 5.34375C7.875 5.96507 8.37868 6.46875 9 6.46875Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_25150_3273">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.46875)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
