import React, { Fragment, useEffect, useState, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import circleInterrogation from 'assets/DashBoard/circleInterrogation.svg';

import { useOutSideClick } from '../../views/JobOfferRebuild/hooks/useOutsideClick';

import { Wrapper, DisplayNumber, Container, Paragraph } from './style';
import { formatNumber } from '../../utils/formatNumber';

export default function CircularProgressBar({
  size,
  media,
  strokeWidth,
  label,
  studentQuantityBubble,
  questionLabel,
}) {
  const helpRef = useRef(null);

  const [progress, setProgress] = useState(0);

  const [activeHelp, setActiveHelp] = useState(false);

  const [clickedOutSideHelp, setClickedOutSideHelp] = useOutSideClick(helpRef);

  if (clickedOutSideHelp) {
    setActiveHelp(false);
    setClickedOutSideHelp(false);
  }

  async function mediaValue() {
    if (progress <= media) {
      for (let i = progress; i <= media; i += 0.1) {
        await new Promise((resolve) => {
          setTimeout(() => {
            const width = Math.ceil(i * 10) / 10;
            setProgress(() => width);
            resolve(i);
          }, 1000 / media);
        });
      }
    }
  }

  const handleClickHelp = () => {
    setActiveHelp(!activeHelp);
  };

  useEffect(() => {
    mediaValue();
  }, []);

  const studentsLabel = () => {
    return studentQuantityBubble > 1 ? 'estudantes' : 'estudante';
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Container>
        {studentQuantityBubble > 0 && (
          <div className="bubble">
            <strong>{formatNumber(studentQuantityBubble)}</strong>{' '}
            {studentsLabel()}
          </div>
        )}
        <Wrapper>
          <CircularProgress
            variant="determinate"
            style={{
              color: '#E1E8EB',
              strokeWidth: '20px',
            }}
            thickness={strokeWidth}
            value={100}
            size={size}
          />

          <CircularProgress
            style={{
              color: '#009291',
              strokeLinecap: 'round',
              left: 0,
              position: 'absolute',
              strokeWidth: '20px',
            }}
            thickness={strokeWidth}
            variant="static"
            value={progress}
            size={size}
          />

          <DisplayNumber size={size}>{progress}%</DisplayNumber>
        </Wrapper>
      </Container>

      <Paragraph activeHelp={activeHelp} ref={helpRef}>
        {label}
        {questionLabel && (
          <div onClick={handleClickHelp}>
            ?<div>{questionLabel}</div>
          </div>
        )}{' '}
      </Paragraph>
    </div>
  );
}
