import * as Yup from 'yup';
import moment from 'moment';
import { mobilePhoneNumberRegex, isValidDate } from 'utils/validation';

export const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .required('Este campo é obrigatório')
    .matches(
      /\S+\s+\w/,
      'Preencha Nome e Sobrenome válidos. Ex: Maria Eduarda'
    ),
  district: Yup.string()
    .nullable('Este campo é obrigatório')
    .required('Este campo é obrigatório'),
  birth_date: Yup.date()
    .required('Este campo é obrigatório')
    .test('isValid', 'Preencha a data no formato dia/mês/ano', (value) => {
      if (value) return isValidDate('birth_date');
      return false;
    })
    .typeError('Preencha a data no formato dia/mês/ano')
    .max(
      moment(Date.now()).subtract(1, 'days').format('YYYY-MM-DD'),
      'A data de nascimento deve ser menor que hoje'
    ),
  email: Yup.string()
    .email('Você deve informar um e-mail válido')
    .required('Este campo é obrigatório')
    .test(
      'no-special-characters',
      'O e-mail não pode conter caracteres especiais',
      (value) => {
        const specialCharacters = /[çáàãâéèêíïóôõöúçñ]/gi;

        return !specialCharacters.test(value);
      }
    ),
  mobilePhone: Yup.string()
    .matches(mobilePhoneNumberRegex, 'Informe um número de celular válido')
    .required('Este campo é obrigatório'),

  facebookInstagramUrl: Yup.string()
    .matches(
      /(facebook\.com|instagram\.com)/,
      'Esse não é um link válido. Copie e cole o link da página do seu Instagram ou Facebook'
    )
    .url('Esse não é um link válido')
    .max(255, 'Link passou o limite de caracteres'),
  linkedinUrl: Yup.string()
    .matches(
      /(linkedin.com)/,
      'Esse não é um link de LinkedIn válido. Copie e cole o link da página do seu LinkedIn'
    )
    .url('Esse não é um link de LinkedIn válido')
    .max(255, 'Link passou o limite de caracteres'),
  portfolioUrl: Yup.string()
    .url('Link inválido')
    .nullable()
    .max(200, 'Link passou o limite de caracteres'),
  zipCode: Yup.string()
    .required('Este campo é obrigatório')
    .min(9, 'Digite um CEP válido')
    .nullable(),
  address: Yup.string().required('Este campo é obrigatório'),
  addressNumber: Yup.number()
    .positive('Este campo não permite número negativo')
    .required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),

  colorOrRace: Yup.string().required('Este campo é obrigatório'),
  genderIdentity: Yup.string().required('Este campo é obrigatório'),
  special_needs: Yup.array().of(
    Yup.object()
      .shape({
        label: Yup.string().max(50, 'No máximo 50 dígitos'),
        value: Yup.string().max(50, 'No máximo 50 dígitos').nullable(),
      })
      .typeError('No máximo 50 dígitos')
  ),
});
