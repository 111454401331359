import React, { Fragment, useContext, useEffect, useState } from 'react';
import { MatrixContext } from '../../contexts/MatrixContext';
import Item from './item';
import Header from './header';
import Pagination from 'components/CustomPagination';
import { Grid } from '@material-ui/core';

import {
  ContainerList,
  Bar,
  CustomSpan,
  WrapperPagination,
  SearchInput,
  Spacer,
} from './style';
import GenericMessage from 'components/GenericMessage';

export default function DisabledMatrices({ openSnackbar }) {
  const {
    disabledMatrices,
    disabledMatricesCount,
    refreshDisabledMatrices,
    selectedMatrices,
    setSelectedMatrices,
    totalDisabledMatrices,
    resetDisabledMatrices,
    setResetDisabledMatrices,
  } = useContext(MatrixContext);
  const [selectedAll, setSelectedAll] = useState(false);
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchString, setSearchString] = useState('');

  const handleClickSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedAll(true);
      const allIds = disabledMatrices.map((item) => item.id);
      setSelectedMatrices(allIds);
      return;
    }
    setSelectedAll(false);
    return setSelectedMatrices([]);
  };

  const getQueryString = (page) => {
    return `&limit=${limit}&offset=${(page - 1) * limit || 0}${
      searchString && '&name=' + searchString.trim()
    }`;
  };

  const handlePageChange = async (page) => {
    const newPage = getQueryString(page);
    refreshDisabledMatrices(newPage);
  };

  useEffect(() => {
    handlePageChange(currentPage);
  }, [limit]);

  useEffect(() => {
    setSelectedAll(false);
    setSelectedMatrices([]);
  }, [disabledMatrices]);

  useEffect(() => {
    if (resetDisabledMatrices) {
      setSearchString('');
      onPageChange(1);
      setResetDisabledMatrices(false);
    }
  }, [resetDisabledMatrices]);

  const refreshList = async (page) => {
    refreshDisabledMatrices(getQueryString(page || currentPage));
  };

  useEffect(() => {
    const typingDelay = setTimeout(() => {
      refreshList(1);
    }, 1000);

    searchString && currentPage !== 1 && setCurrentPage(1);
    return () => clearTimeout(typingDelay);
  }, [searchString]);

  const onPageChange = (page) => {
    setCurrentPage(page);
    handlePageChange(page);
    setSelectedAll(false);
    setSelectedMatrices([]);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchString(value);
  };

  const mapMatrices = disabledMatrices.map((item) => (
    <Item
      code={item.code}
      id={item.id}
      name={item.name}
      disciplines={item.disciplines}
      selectedMatrices={selectedMatrices}
      setSelectedMatrices={setSelectedMatrices}
      openSnackbar={openSnackbar}
      refreshList={refreshList}
    />
  ));

  return totalDisabledMatrices > 0 ? (
    <Fragment>
      <ContainerList>
        <Header />

        <SearchInput
          placeholder="Buscar"
          value={searchString}
          onChange={handleSearch}
        />
        <Bar>
          <Grid container justify="center" alignItems="center">
            <Grid item md={1}>
              <input
                type="checkbox"
                onChange={handleClickSelectAll}
                checked={selectedAll}
              />
            </Grid>
            <Grid item md={5}>
              <CustomSpan>MATRIZ</CustomSpan>
            </Grid>
            <Grid item md={5}>
              <CustomSpan>
                QUANTIDADE DE <br />
                DISCIPLINAS RELACIONADAS
              </CustomSpan>
            </Grid>
            <Grid
              item
              md={1}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <CustomSpan>AÇÕES</CustomSpan>
            </Grid>
          </Grid>
        </Bar>
        <Grid container justify="center" alignItems="center">
          {mapMatrices}
        </Grid>
        <WrapperPagination>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={disabledMatricesCount}
            limit={limit}
            onPageChange={onPageChange}
            setLimit={setLimit}
            setCurrentPage={setCurrentPage}
          />
        </WrapperPagination>
      </ContainerList>
    </Fragment>
  ) : (
    <ContainerList>
      <Spacer />
      <Grid container justify="center" alignItems="center">
        <GenericMessage title="Oops! No momento, não existem matrizes desativadas." />
      </Grid>
    </ContainerList>
  );
}
