export function IconWithoutJobs({ color }) {
  return (
    <svg
      width="118"
      height="112"
      viewBox="0 0 118 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="58.999" cy="56" r="56" fill={color} fill-opacity="0.1" />
      <path
        d="M6.67784 86.6794C4.01635 86.1913 1.58202 87.5236 0.565919 90.0162C0.455407 90.2925 0.317267 90.5565 0.191406 90.8267V93.1229C0.320337 93.393 0.458477 93.6539 0.572059 93.9271C1.59429 96.4229 4.02863 97.749 6.69012 97.2578C9.12139 96.8097 10.9817 94.5472 11.0001 92.0177C11.0154 89.4207 9.16436 87.1337 6.67784 86.6794ZM5.60035 93.8995C4.51365 93.8995 3.6357 93.0123 3.65411 91.9379C3.66946 90.9065 4.51058 90.0592 5.5451 90.0316C6.6318 89.9978 7.52817 90.8574 7.54352 91.9379C7.55887 93.0277 6.69626 93.8995 5.60035 93.8995Z"
        fill={color}
      />
      <path
        d="M95.7247 36.8107V25.7902H94.2236C78.9054 25.7902 63.5872 25.781 48.2691 25.8148C47.342 25.8148 46.8324 25.5016 46.3474 24.7526C44.3459 21.6736 42.2676 18.6438 40.2631 15.5648C39.8548 14.9416 39.425 14.7206 38.6852 14.7237C29.9179 14.7482 21.1476 14.739 12.3772 14.7421C12.0119 14.7421 11.6497 14.7728 11.312 14.7881V84.38H36.8526V81.0186H15.2597C15.3426 80.5519 15.3825 80.2173 15.4654 79.892C18.658 67.0941 21.8659 54.2992 25.0308 41.4952C25.2641 40.5497 25.6233 40.2489 26.6179 40.2489C51.5875 40.2796 76.557 40.2734 101.524 40.2734H102.798C102.715 40.7155 102.675 41.0194 102.601 41.3141C99.387 54.1856 96.1576 67.0511 92.9681 79.9288C92.7501 80.8098 92.3817 81.0861 91.4946 81.0431C89.9044 80.9694 88.3082 81.0216 86.7027 81.0216V84.469C89.2997 84.469 91.82 84.4352 94.3403 84.4874C95.1568 84.5028 95.4362 84.2265 95.6296 83.4437C98.3371 72.4938 101.088 61.5531 103.826 50.6124C104.968 46.0538 106.104 41.4952 107.276 36.8107H95.7247ZM14.7778 18.1956H17.4669C23.892 18.1956 30.317 18.214 36.742 18.1741C37.574 18.171 38.0467 18.432 38.4887 19.1227C39.8763 21.3053 41.3375 23.4388 42.857 25.7196H14.7778V18.1956ZM92.2405 36.8261H90.7916C68.5081 36.8261 46.2246 36.8322 23.9411 36.8076C22.9772 36.8046 22.5873 37.0501 22.3448 38.0355C19.9841 47.6501 17.5621 57.2493 15.1554 66.8515C15.0633 67.2138 14.9558 67.5699 14.8545 67.929C14.8085 67.9137 14.7594 67.9014 14.7103 67.8891V29.3297H92.2405V36.8261Z"
        fill={color}
      />
      <path
        d="M79.374 80.4696C79.5582 80.1105 79.6748 79.8741 79.8037 79.6408C82.4929 74.7384 83.4046 69.532 82.4898 64.0064C80.3839 51.3006 68.2706 42.5333 55.4512 44.4519C42.6564 46.3644 33.6404 58.4071 35.3964 71.2419C37.6557 87.7573 55.8319 96.9144 70.4532 88.887C71.1992 88.4787 71.6044 88.5493 72.1907 89.1387C78.5328 95.5238 84.9057 101.878 91.2694 108.239C91.5579 108.527 91.8618 108.801 92.0736 109C94.7443 106.323 97.3322 103.729 99.9875 101.065C93.1603 94.2437 86.2625 87.3551 79.374 80.4696ZM58.9784 88.3774C47.7369 88.3559 38.5981 79.1957 38.6288 67.9818C38.6565 56.7709 47.8412 47.6261 59.0551 47.6414C70.2598 47.6568 79.4292 56.8569 79.4077 68.0585C79.3862 79.2847 70.2168 88.4019 58.9784 88.3774ZM74.579 86.3882C75.4815 85.5071 76.4792 84.534 77.4615 83.5793C78.2781 84.4235 79.2757 85.458 80.3348 86.557C79.4077 87.4749 78.4408 88.4357 77.5229 89.3505C76.516 88.3375 75.5061 87.3183 74.579 86.3882ZM80.2028 91.8831C81.0624 91.0082 82.0232 90.035 83.1344 88.9084C87.1282 92.9329 91.1527 96.9942 95.214 101.089C94.0966 102.105 93.0652 103.045 92.1749 103.855C88.2456 99.9259 84.1782 95.8584 80.2028 91.8831Z"
        fill={color}
      />
      <path
        d="M112.471 23.9488C109.469 23.9365 107.05 26.3186 107.034 29.3055C107.019 32.2955 109.416 34.7329 112.391 34.7513C115.363 34.7728 117.821 32.3477 117.84 29.3823C117.855 26.3985 115.452 23.9641 112.471 23.9488ZM112.36 31.2948C111.295 31.2548 110.439 30.3155 110.491 29.2472C110.54 28.2158 111.409 27.3961 112.443 27.4023C113.515 27.4084 114.405 28.3201 114.383 29.3854C114.359 30.4444 113.416 31.3347 112.36 31.2948Z"
        fill={color}
      />
      <path
        d="M99.4405 18.1587V21.8824H96.0116V18.217H92.248V14.7451H95.9594V11H99.4282V14.7328H103.133V18.1587H99.4436H99.4405Z"
        fill={color}
      />
      <path
        d="M27.5209 97.5884H31.309V101.005H27.6038V104.793H24.1288V101.057H20.4175V97.6375H24.0644V93.9077H27.5209V97.5884Z"
        fill={color}
      />
      <path
        d="M22.1368 20.3413H18.6157V23.6782H22.1368V20.3413Z"
        fill={color}
      />
      <path d="M29.4944 20.3511H25.915V23.6235H29.4944V20.3511Z" fill={color} />
      <path
        d="M36.8662 20.3384H33.3359V23.6568H36.8662V20.3384Z"
        fill={color}
      />
      <path
        d="M59.1778 51.5528C50.076 51.4791 42.6072 58.8435 42.5458 67.9485C42.4844 76.9797 49.8334 84.3963 58.9138 84.4669C67.9727 84.5345 75.3801 77.2437 75.4906 68.1511C75.6011 59.1597 68.1968 51.6265 59.1778 51.5528ZM58.9844 81.0073C51.8227 80.995 45.9625 75.1164 46.0054 67.9914C46.0515 60.8297 51.9209 54.9848 59.052 55.0094C66.1892 55.0308 72.0556 60.9279 72.0341 68.0559C72.0156 75.2085 66.1585 81.0226 58.9844 81.0073Z"
        fill={color}
      />
    </svg>
  );
}
