import styled from 'styled-components';

export const Content = styled.div`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  a,
  button,
  div,
  label,
  input,
  textarea,
  select {
    font-family: 'Roboto', sans-serif;
  }

  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: 0;
  background-color: #cdcdcd;
`;

function getStatusBackgroundColor(status) {
  switch (status) {
    case 'requested':
      return '#DEFDFD;';
    case 'realized':
      return '#D9FFE3';
    case 'accepted':
      return '#D9FFE3';
    case 'reschedule_request':
      return '#FFFDED';
    case 'canceled':
      return '#FFEDED';
    case 'scheduled':
      return '#FFFDED';
    case 'rescheduled':
      return '#FFFDED';
    default:
      // expired
      return '#F4F4F4';
  }
}

function getStatusColor(status) {
  switch (status) {
    case 'requested':
      return '#009291';
    case 'realized':
      return '#068E2C';
    case 'accepted':
      return '#068E2C';
    case 'reschedule_request':
      return '#E9B600';
    case 'scheduled':
      return '#E9B600';
    case 'rescheduled':
      return '#E9B600';
    case 'canceled':
      return '#DA0505';
    default:
      // expired
      return '#606062';
  }
}

export const StatusIndicator = styled.div`
  background-color: ${(props) => getStatusBackgroundColor(props.status)};
  color: ${(props) => getStatusColor(props.status)};
  border-radius: 3.39px;
  display: flex;
  gap: 0.5rem;
  padding: 0 1rem;
  font-size: 12px;
  font-weight: 400;
  height: 23px;
  align-items: center;
  justify-content: center;
  color: ${(props) => getStatusColor(props.status)};
  width: fit-content;
  margin: 0 0 20px 0;
`;
