import React from 'react';
import { Elipsis } from './styled';

export default function LoadingLogin ({ color }) {
  return (
    <Elipsis color={color}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Elipsis>
  );
}
