import styled from 'styled-components';

export const ContainerForm = styled.div`
  img {
    top: 13px;
  }

  > form > p {
    font-size: 12px;
    color: #4b4b4d;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 17px;
  }

  > form > div > span {
    font-size: inherit;
    font-weight: 200;
    line-height: normal;
    width: initial;
    text-align: inherit;
  }
`;

export const ContainerLink = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 35px;

  > div > span {
    font-size: inherit;
    font-weight: 200;
    line-height: normal;
    width: initial;
    text-align: inherit;
  }

  > button {
    border: 1px solid ${props => props.universityColor ? props.universityColor: "#009291"};
    background-color: #ffff;
    color: ${props => props.universityColor ? props.universityColor: "#009291"};
    border-radius: 4px;
    min-width: 132px;
    height: 31px;
    margin-left: 27px;
    margin-top: 8px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }
`;
