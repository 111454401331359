import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Footer from 'components/Footer/Footer';

import professionalStatusStyle from 'assets/jss/material-dashboard-pro-react/layouts/professionalStatusStyle';

class OpenBase extends React.Component {
  componentDidMount() {
    document.body.style.overflow = 'unset';
  }

  render() {
    const { classes, childComponent } = this.props;

    const ChildComponent = childComponent({
      academicProfile: this.props.academic_profile,
      metadata: this.props.metadata,
      globalState: this.props.globalState,
      ...this.props,
    });

    return (
      <div>
        <div className={classes.wrapper} ref="wrapper">
          {ChildComponent}
          <Footer black />
        </div>
      </div>
    );
  }
}

OpenBase.propTypes = {
  classes: PropTypes.shape.isRequired,
  childComponent: PropTypes.func.isRequired,
};

export default withStyles(professionalStatusStyle)(OpenBase);
