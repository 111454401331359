import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from './classe';

const KEYCODE_Z = 90;
const KEYCODE_Y = 89;

function isUndo(e) {
  return (
    (e.ctrlKey || e.metaKey) &&
    e.keyCode === (e.shiftKey ? KEYCODE_Y : KEYCODE_Z)
  );
}

function isRedo(e) {
  return (
    (e.ctrlKey || e.metaKey) &&
    e.keyCode === (e.shiftKey ? KEYCODE_Z : KEYCODE_Y)
  );
}

function getSelection(el) {
  let start, end;
  if (el.selectionStart !== undefined) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    try {
      let rangeEl = el.createTextRange();
      let clone = rangeEl.duplicate();

      rangeEl.moveToBookmark(document.selection.createRange().getBookmark());
      clone.setEndPoint('EndToStart', rangeEl);

      start = clone.text.length;
      end = start + rangeEl.text.length;
    } catch (e) {
      /* not focused or not visible */
    }
  }

  return { start, end };
}

function setSelection(el, selection) {
  try {
    if (el.selectionStart !== undefined) {
      el.setSelectionRange(selection.start, selection.end);
    } else {
      let rangeEl = el.createTextRange();
      rangeEl.collapse(true);
      rangeEl.moveStart('character', selection.start);
      rangeEl.moveEnd('character', selection.end - selection.start);
      rangeEl.select();
    }
  } catch (e) {
    /* not focused or not visible */
  }
}

const MaskedInput = ({
  mask,
  formatCharacters,
  placeholderChar,
  value: initialValue,
  onChange,
  children,
  fieldRef,
  ...restProps
}) => {
  const inputRef = useRef(null);

  const options = {
    pattern: mask,
    value: initialValue,
    formatCharacters,
  };

  const maskInstance = new InputMask(options);

  useEffect(() => {
    if (maskInstance) {
      maskInstance.setPattern(mask, { value: maskInstance.getRawValue() });
    }
  }, [mask, maskInstance]);

  useEffect(() => {
    if (maskInstance && initialValue !== maskInstance.getRawValue()) {
      maskInstance.setValue(initialValue);
    }
  }, [initialValue, maskInstance]);

  useEffect(() => {
    const updatePattern = () => {
      if (maskInstance && inputRef?.current) {
        maskInstance.setPattern(mask, {
          value: maskInstance.getRawValue(),
          selection: getSelection(inputRef?.current),
        });
      }
    };

    updatePattern();
  }, [mask, maskInstance, inputRef?.current]);

  useEffect(() => {
    if (inputRef?.current && maskInstance && inputRef?.current.selectionStart) {
      setSelection(inputRef?.current, maskInstance.selection);
    }
  }, [maskInstance, inputRef?.current]);

  const onChangeHandler = (e) => {
    let maskValue = maskInstance.getValue();
    let incomingValue = e.target.value;
    if (incomingValue !== maskValue && inputRef?.current) {
      maskInstance.selection = getSelection(inputRef?.current);
      maskInstance.setValue(incomingValue);
      e.target.value = maskInstance.getValue();
      setSelection(inputRef?.current, maskInstance.selection);
    }

    if (onChange) {
      onChange(e);
    }
  };

  const onKeyDownHandler = (e) => {
    if (isUndo(e)) {
      e.preventDefault();
      if (maskInstance.undo() && inputRef?.current) {
        e.target.value = maskInstance.getValue();
        setSelection(inputRef?.current, maskInstance.selection);
        if (onChange) {
          onChange(e);
        }
      }
      return;
    } else if (isRedo(e)) {
      e.preventDefault();
      if (maskInstance.redo() && inputRef?.current) {
        e.target.value = maskInstance.getValue();
        setSelection(inputRef?.current, maskInstance.selection);
        if (onChange) {
          onChange(e);
        }
      }
      return;
    }

    if (e.key === 'Backspace') {
      e.preventDefault();
      maskInstance.selection = getSelection(inputRef?.current);
      if (maskInstance.backspace()) {
        let value = maskInstance.getValue();
        e.target.value = value;
        if (value) {
          setSelection(inputRef?.current, maskInstance.selection);
        }
        if (onChange) {
          onChange(e);
        }
      }
    }
  };

  const onKeyPressHandler = (e) => {
    if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') {
      return;
    }

    e.preventDefault();
    maskInstance.selection = getSelection(inputRef?.current);
    if (maskInstance.input(e.key || e.data)) {
      e.target.value = maskInstance.getValue();
      setSelection(inputRef?.current, maskInstance.selection);
      if (onChange) {
        onChange(e);
      }
    }
  };

  const onPasteHandler = (e) => {
    e.preventDefault();
    maskInstance.selection = getSelection(inputRef?.current);
    if (maskInstance.paste(e.clipboardData.getData('Text'))) {
      e.target.value = maskInstance.getValue();
      setTimeout(
        () => setSelection(inputRef?.current, maskInstance.selection),
        0
      );
      if (onChange) {
        onChange(e);
      }
    }
  };

  const getDisplayValue = () => {
    let value = maskInstance.getValue();
    return value === maskInstance.emptyValue ? '' : value;
  };

  const keyPressPropName = () => {
    if (typeof navigator !== 'undefined') {
      return navigator.userAgent.match(/Android/i)
        ? 'onBeforeInput'
        : 'onKeyPress';
    }
    return 'onKeyPress';
  };

  const eventHandlers = {
    onChange: onChangeHandler,
    onKeyDown: onKeyDownHandler,
    onPaste: onPasteHandler,
    [keyPressPropName()]: onKeyPressHandler,
  };

  const maxLength = maskInstance ? maskInstance.pattern.length : undefined;
  const size = maxLength;
  const placeholder = maskInstance ? maskInstance.emptyValue : undefined;

  if (children) {
    const newChild = React.Children.map(children, (child) =>
      React.cloneElement(child, {
        ...restProps,
        ...eventHandlers,
        ref: (el) => {
          inputRef.current = el;
          fieldRef.current = el;
        },
        maxLength,
        value: getDisplayValue(),
        size,
        placeholder,
      })
    );
    return newChild;
  }

  return (
    <input
      {...restProps}
      {...eventHandlers}
      ref={(el) => {
        fieldRef.current = el;
        inputRef.current = el;
      }}
      maxLength={maxLength}
      value={getDisplayValue()}
      size={size}
      placeholder={placeholder}
    />
  );
};

MaskedInput.propTypes = {
  mask: PropTypes.string.isRequired,
  formatCharacters: PropTypes.object,
  placeholderChar: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

MaskedInput.defaultProps = {
  value: '',
};

export default MaskedInput;
