import React, { useEffect, useRef, useState } from 'react';
import { HomeDescriptionContainer, StudentListContainer } from './styles';
import { CategoriesNav } from './CategoriesNav';
import { StudentList } from './StudentList';
import { useCsm } from '../../contexts/CsmContext';
import LimitSelect from './LimitSelect';
import { StudentCategory } from '../../resources/defaultStudentCategories';
import { StudentHistoric } from './StudentHistoric';

export function CsmHome() {
  const refCsmHome = useRef(null);
  const {
    setLimitPerPage,
    limitPerPage,
    studentRequestInProgress,
    activeCategory,
    selectStudent,
    setSelectStudent,
    egressed,
  } = useCsm();

  useEffect(() => {
    if (studentRequestInProgress) {
      refCsmHome.current?.scrollIntoView({
        block: 'start',
        behaviour: 'smooth',
      });
    }
  }, [studentRequestInProgress]);

  function getCategoryDescription() {
    switch (activeCategory) {
      case StudentCategory.ACTIVE:
        return egressed ? (
          <span>
            Identifique os egressos pelo <strong>status profissional</strong> e
            acione-os para <strong>gerar seu currículo profissional.</strong>
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados pelo{' '}
            <strong>status profissional</strong> e acione-os para{' '}
            <strong>gerar seu currículo profissional.</strong>
          </span>
        );
      case StudentCategory.WITH_CURRICULUM:
        return egressed ? (
          <span>
            Identifique os egressos <strong>mais adequados</strong> para suas
            oportunidades e <strong>acione-os para as vagas publicadas.</strong>
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados{' '}
            <strong>mais adequados</strong> para suas oportunidades e{' '}
            <strong>acione-os para as vagas publicadas.</strong>
          </span>
        );
      case StudentCategory.APPLICATIONS:
        return egressed ? (
          <span>
            Identifique os egressos que se{' '}
            <strong>candidataram a vagas </strong>
            ativas e expiradas e <strong>mantenha-os atualizados!</strong>
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados que se{' '}
            <strong>candidataram a vagas </strong>
            ativas e expiradas e <strong>mantenha-os atualizados!</strong>
          </span>
        );
      case StudentCategory.WORKING:
        return egressed ? (
          <span>
            Identifique os egressos da sua Instituição que no momento{' '}
            <strong>estão trabalhando.</strong>
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados da sua Instituição que no
            momento <strong>estão trabalhando.</strong>
          </span>
        );
      case StudentCategory.NOT_WORKING:
        return egressed ? (
          <span>
            Identifique os egressos que <strong>não estão trabalhando </strong>e{' '}
            <strong> acione-os</strong> para as vagas publicadas.
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados que{' '}
            <strong>não estão trabalhando </strong>e <strong> acione-os</strong>{' '}
            para as vagas publicadas.
          </span>
        );
      case StudentCategory.INACTIVE:
        return egressed ? (
          <span>
            Identifique os egressos que ainda não acessaram o sistema e
            convide-os para participar.
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados que ainda não acessaram o
            sistema e convide-os para participar.
          </span>
        );
      case StudentCategory.DIRECT_HIRIGN:
        return egressed ? (
          <span>
            Identifique os egressos pelo <strong>status profissional</strong> e
            acione-os para <strong>gerar seu currículo profissional.</strong>
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados pelo{' '}
            <strong>status profissional</strong> e acione-os para{' '}
            <strong>gerar seu currículo profissional.</strong>
          </span>
        );
      case StudentCategory.WITHOUT_CURRICULUM:
        return egressed ? (
          <span>
            Identifique os egressos pelo <strong>status profissional</strong> e
            acione-os para <strong>gerar seu currículo profissional.</strong>
          </span>
        ) : (
          <span>
            Identifique os estudantes matriculados pelo{' '}
            <strong>status profissional</strong> e acione-os para{' '}
            <strong>gerar seu currículo profissional.</strong>
          </span>
        );
    }
  }

  if (selectStudent) {
    return (
      <StudentHistoric
        selectStudent={selectStudent}
        setSelectStudent={setSelectStudent}
        activeCategory={activeCategory}
      />
    );
  }

  return (
    <StudentListContainer ref={refCsmHome}>
      <CategoriesNav />
      <HomeDescriptionContainer>
        {getCategoryDescription()}
        <div className={'limit-select-container'}>
          <LimitSelect setLimit={setLimitPerPage} pageSize={limitPerPage} />
        </div>
      </HomeDescriptionContainer>
      <StudentList />
    </StudentListContainer>
  );
}
