import GenericInternalTab from 'components/GenericInternalTab';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Container,
  Header,
  FilterStyled,
  ContainerCards,
  ContainerButton,
} from './styles';
import BaseLayoutContext from 'contexts/base-layout';
import { FilterNetworkStudent } from '../../Components/Filters/Filters';
import CardNetwork from '../../Components/Card/CardNetwork';
import {
  getNetworkingCounts,
  getCompaniesConnected,
  getWithVacancies,
  getCompaniesFavorites,
  postFavorite,
  deleteFavorite,
  getCompaniesFilterOptions,
  getCompaniesActiveJobOffersFilterOptions,
  getCompaniesFavoritesFilterOptions,
} from '../../Services/Services';
import { genericAdapter } from 'adapters/genericAdapter';
import CardSkeletonNetwork from '../../Components/Skeleton/Skeleton';
import { CompanyDetail } from 'components/CompanyDetail/CompanyDetail';
import GenericMessage from 'components/GenericMessage';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { formatNumber } from 'utils/formatNumber';

export function DisplayCompanies({ data, dataCourses }) {
  const pagination = 9;
  const repeatSkeleton = new Array(pagination).fill(null);

  const [activeTab, setActiveTab] = useState(0);
  const { universityColor, metadata } = useContext(BaseLayoutContext);
  const [companiesData, setCompaniesData] = useState([]);
  const [withVacanciesData, setWithVacanciesData] = useState([]);
  const [favoritesData, setFavoritesData] = useState([]);
  const [reloadIntern, setReloadIntern] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [companyDetail, setCompanyDetail] = useState(null);

  const [companiesFilterOptions, setCompaniesFilterOptions] = useState(null);
  const [
    companiesActivesJobOffersFilterOptions,
    setCompaniesActivesJobOffersFilterOptions,
  ] = useState(null);
  const [companiesFavoriteFilterOptions, setCompaniesFavoriteFilterOptions] =
    useState(null);

  const [companiesFilterState, setCompaniesFilterState] = useState({});
  const [
    companiesActivesJobOffersFilterState,
    setCompaniesActivesJobOffersFilterState,
  ] = useState({});
  const [companiesFavoriteFilterState, setCompaniesFavoriteFilterState] =
    useState({});

  const [companiesAppliedFilters, setCompaniesAppliedFilterState] = useState(
    {}
  );
  const [
    companiesActivesJobOffersAppliedFilters,
    setCompaniesActivesJobOffersAppliedFilters,
  ] = useState({});
  const [companiesFavoriteAppliedFilters, setCompaniesFavoriteAppliedFilters] =
    useState({});

  const appliedFilters = {
    0: { get: companiesAppliedFilters, set: setCompaniesAppliedFilterState },
    1: {
      get: companiesActivesJobOffersAppliedFilters,
      set: setCompaniesActivesJobOffersAppliedFilters,
    },
    2: {
      get: companiesFavoriteAppliedFilters,
      set: setCompaniesFavoriteAppliedFilters,
    },
  };

  const filterState = {
    0: { get: companiesFilterState, set: setCompaniesFilterState },
    1: {
      get: companiesActivesJobOffersFilterState,
      set: setCompaniesActivesJobOffersFilterState,
    },
    2: {
      get: companiesFavoriteFilterState,
      set: setCompaniesFavoriteFilterState,
    },
  };

  const filterOptions = {
    0: { get: companiesFilterOptions, set: setCompaniesFilterOptions },
    1: {
      get: companiesActivesJobOffersFilterOptions,
      set: setCompaniesActivesJobOffersFilterOptions,
    },
    2: {
      get: companiesFavoriteFilterOptions,
      set: setCompaniesFavoriteFilterOptions,
    },
  };
  const isDisplayJobOffers = metadata.display_job_offer;

  const getCitiesOptions = {
    0: getCompaniesFilterOptions,
    1: getCompaniesActiveJobOffersFilterOptions,
    2: getCompaniesFavoritesFilterOptions,
  };

  const [loading, setLoading] = useState({
    0: true,
    1: true,
    2: true,
  });
  const [pages, setPages] = useState({
    0: pagination,
    1: pagination,
    2: pagination,
  });
  const [countTabs, setCountTabs] = useState({
    0: 0,
    1: 0,
    2: 0,
  });
  const [favoriteCompany, setFavoriteCompany] = useState(null);

  const descriptions = {
    0: 'No momento, não existem empresas disponíveis.',
    1: 'No momento, não existem empresas com vagas ativas.',
    2: 'Você ainda não favoritou nenhuma empresa.',
  };

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const forceReloadIntern = () => {
    setReloadIntern(!reloadIntern);
  };

  const applyQueryString = () => {
    const existFilters =
      filterState[activeTab].get &&
      Object.keys(filterState[activeTab].get).length > 0;
    if (existFilters) {
      return queryParamsFromFilterObject(filterState[activeTab].get);
    }
    return '';
  };

  const handleFavorite = async (data) => {
    const { id, isFavorite } = data;
    let companiesDataLocal = companiesData;
    let withVacanciesDataLocal = withVacanciesData;
    if (companiesData.filter((company) => company.id === id)) {
      companiesDataLocal.forEach((item) => {
        if (item.id === id) {
          item.isFavorite = !isFavorite;
        }
      });
      setCompaniesData(companiesDataLocal);
      forceReloadIntern();
    }
    if (withVacanciesData.filter((company) => company.id === id)) {
      withVacanciesDataLocal.forEach((item) => {
        if (item.id === id) {
          item.isFavorite = !isFavorite;
        }
      });
      setWithVacanciesData(withVacanciesDataLocal);
      forceReloadIntern();
    }
    if (!isFavorite) {
      const response = postFavorite(id);
    } else {
      const response = deleteFavorite(id);
    }
    const responseFavorites = await getCompaniesFavorites();
    countTabs[2] = responseFavorites.data.count;
    setFavoritesData(genericAdapter(responseFavorites.data.results));
    const favoriteFilters = await getCompaniesFavoritesFilterOptions();
    if (favoriteFilters.status === 200) {
      setCompaniesFavoriteFilterOptions(genericAdapter(favoriteFilters.data));
    }
  };

  const handleLoading = (value) => {
    let loadingLocal = { ...loading };
    loadingLocal[activeTab] = value;
    setLoading(loadingLocal);
  };

  const handleMoreInfo = async () => {
    handleLoading(true);
    let localPages = pages;
    localPages[activeTab] = localPages[activeTab] + pagination;
    if (activeTab === 0) {
      const responseCompanies = await getCompaniesConnected(
        localPages[activeTab],
        undefined,
        applyQueryString()
      );
      setCompaniesData(genericAdapter(responseCompanies.data.results));
      handleLoading(false);
      forceReloadIntern();
    }
    if (activeTab === 1 && isDisplayJobOffers) {
      const responseWithVacancies = await getWithVacancies(
        localPages[activeTab],
        undefined,
        applyQueryString()
      );
      setWithVacanciesData(genericAdapter(responseWithVacancies.data.results));
      handleLoading(false);
      forceReloadIntern();
    }
    if (activeTab === 2) {
      const responseFavorites = await getCompaniesFavorites(
        localPages[activeTab],
        undefined,
        applyQueryString()
      );
      setFavoritesData(genericAdapter(responseFavorites.data.results));
      handleLoading(false);
      forceReloadIntern();
    }
  };

  const callFirstData = async () => {
    let loadingLocal = { ...loading };
    const networkingCounts = await getNetworkingCounts();
    if (networkingCounts.status === 200) {
      setCountTabs({
        0: networkingCounts.data.companies,
        1: networkingCounts.data.active_offers,
        2: networkingCounts.data.favorites,
      });
    }

    const [responseCompanies, responseCompaniesFilters] = await Promise.all([
      getCompaniesConnected(),
      getCompaniesFilterOptions(),
    ]);
    if (responseCompanies.status === 200) {
      setCompaniesData(genericAdapter(responseCompanies.data.results));
      loadingLocal[0] = false;
      setLoading(loadingLocal);
    }
    if (responseCompaniesFilters.status === 200) {
      setCompaniesFilterOptions(genericAdapter(responseCompaniesFilters.data));
    }

    if (isDisplayJobOffers) {
      const [responseWithVacancies, responseCompaniesActiveJobOffersFilters] =
        await Promise.all([
          getWithVacancies(),
          getCompaniesActiveJobOffersFilterOptions(),
        ]);

      if (responseWithVacancies.status === 200) {
        setWithVacanciesData(
          genericAdapter(responseWithVacancies.data.results)
        );
        loadingLocal[1] = false;
        setLoading(loadingLocal);
      }
      if (responseCompaniesActiveJobOffersFilters.status === 200) {
        setCompaniesActivesJobOffersFilterOptions(
          genericAdapter(responseCompaniesActiveJobOffersFilters.data)
        );
      }
    }
    const [responseFavorites, responseCompaniesFavoritesFilters] =
      await Promise.all([
        getCompaniesFavorites(),
        getCompaniesFavoritesFilterOptions(),
      ]);
    if (responseFavorites.status === 200) {
      setFavoritesData(genericAdapter(responseFavorites.data.results));
      loadingLocal[2] = false;
      setLoading(loadingLocal);
    }

    if (responseCompaniesFavoritesFilters.status === 200) {
      setCompaniesFavoriteFilterOptions(
        genericAdapter(responseCompaniesFavoritesFilters.data)
      );
    }
  };

  const applyFilter = async (reset = false) => {
    const queryString = !reset ? applyQueryString() : '';
    const getDataApi = {
      0: getCompaniesConnected,
      1: getWithVacancies,
      2: getCompaniesFavorites,
    };
    const setData = {
      0: setCompaniesData,
      1: setWithVacanciesData,
      2: setFavoritesData,
    };
    loading[activeTab] = true;
    setData[activeTab]([]);

    const response = await getDataApi[activeTab](
      undefined,
      undefined,
      queryString
    );
    if (response.status === 200) {
      setData[activeTab](genericAdapter(response.data.results));
      countTabs[activeTab] = response.data.count;
      loading[activeTab] = false;
      forceReloadIntern();
    }
  };

  useEffect(() => {
    callFirstData();
  }, []);

  const generalData = useMemo(() => {
    if (activeTab === 0) {
      return companiesData;
    }
    if (activeTab === 1) {
      return withVacanciesData;
    }
    if (activeTab === 2) {
      return favoritesData;
    }
    return [];
  }, [
    activeTab,
    companiesData,
    withVacanciesData,
    favoritesData,
    reloadIntern,
  ]);

  const handleOpenModal = (id) => {
    setCompanyDetail(id);
    setOpenModal(true);
  };
  const closeModal = (e) => {
    e.preventDefault();
    setCompanyDetail(null);
    setOpenModal(false);
  };

  let Tabs;

  if (isDisplayJobOffers) {
    Tabs = [
      `Empresas (${formatNumber(countTabs[0])})`,
      `Com vagas ativas (${formatNumber(countTabs[1])})`,
      `Minhas favoritas (${formatNumber(countTabs[2])})`,
    ];
  } else {
    Tabs = [
      `Empresas (${formatNumber(countTabs[0])})`,
      `Minhas favoritas (${formatNumber(countTabs[1])})`,
    ];
  }

  return (
    <>
      <FilterStyled>
        <FilterNetworkStudent
          filterOptions={filterOptions[activeTab].get}
          filterState={filterState[activeTab].get}
          setFilterState={filterState[activeTab].set}
          appliedFilters={appliedFilters[activeTab].get}
          setAppliedFilters={appliedFilters[activeTab].set}
          getCitiesOptions={getCitiesOptions[activeTab]}
          setFilterOptions={filterOptions[activeTab].set}
          applyFilter={applyFilter}
        />
      </FilterStyled>
      <Container>
        <Header>
          <div style={{ width: '100%', height: '130px' }}>
            <GenericInternalTab
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabs={Tabs}
              zIndex={0}
            />
          </div>
        </Header>
        <ContainerCards>
          {generalData.length > 0
            ? generalData?.map((data, index) => (
                <CardNetwork
                  data={data}
                  universityColor={universityColor}
                  handleFavorite={handleFavorite}
                  favoriteCompany={favoriteCompany}
                  handleOpenModal={handleOpenModal}
                />
              ))
            : !loading[activeTab] && (
                <GenericMessage
                  title="Nenhuma empresa encontrada"
                  subtitle={descriptions[activeTab]}
                />
              )}
          {loading[activeTab] &&
            repeatSkeleton.map((_, index) => (
              <CardSkeletonNetwork
                universityColor={universityColor}
                key={index}
              />
            ))}
        </ContainerCards>
        {pages[activeTab] < countTabs[activeTab] && (
          <ContainerButton color={universityColor}>
            <button onClick={() => handleMoreInfo()}>Ver mais</button>
          </ContainerButton>
        )}
      </Container>
      {openModal === true && companyDetail !== null && (
        <CompanyDetail
          id={companyDetail}
          handleFavorite={handleFavorite}
          closeModal={closeModal}
          student
        />
      )}
    </>
  );
}
