import styled, { keyframes } from 'styled-components';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const ImageSkeleton = styled(Skeleton)`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-left: 2rem;
  padding-right: 5rem;
`;

export const SkeletonBox = styled(Skeleton)`
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
`;
