import styled from 'styled-components';

export const AbsenceItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  gap: 1rem;
  align-items: flex-start;

  > button {
    margin-bottom: 3px;
  }
`;

export const DateFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AbsenceItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
`;

export const AbsenceContent = styled.div`
  display: flex;
  width: 100%;
`;
