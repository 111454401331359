import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Container,
  MentorshipListContainer,
  SeeMoreButton,
  WhiteBox,
} from '../styled';
import { useHistoricMentorshipContext } from '../../context';
import DetailsModal from '../../../../components/DetailsModal';
import { MentorModal } from '../../../../../../StaffPages/Mentorship/screens/MentorsTab/components/MentorModal';
import { mentorshipApi } from 'views/Student/Mentorship/services/api';
import SkeletonCardMentorship from 'views/StaffPages/Mentorship/components/SkeletonCard';
import GenericMessage from 'components/GenericMessage';
import BaseLayoutContext from 'contexts/base-layout';
import { useMentorship } from 'views/Student/Mentorship/context/MentorshipContext';
import { MentorshipCard } from '../../../../components/MentorshipCard';

export function RealizedSubTab({ updateMentorshipsCount, currentTab }) {
  const {
    detailsModalOpen,
    modalData,
    openDetailsModal,
    closeDetailsModal,
    mentorships,
    setMentorships,
    loading,
    setLoading,
    page,
    setPage,
  } = useHistoricMentorshipContext();
  const { universityColor } = useContext(BaseLayoutContext);
  const [hasNextPage, setHasNextPage] = useState(false);
  const {
    selectedMentor,
    mentorModalIsOpen,
    handleCloseMentorModal,
  } = useMentorship();

  const fetchRealizedMentorships = async (reset = false) => {
    setLoading(true);
    const offset = (page - 1) * 20;
    const response = await mentorshipApi.getRealizedMentorshipsList({
      queryParams: `limit=20&offset=${offset}`,
    });
    const updatedMentorships = mentorships && !reset ? [...mentorships] : [];
    response.results.forEach((mentorship) => {
      const mentorshipIndex = updatedMentorships.findIndex(
        (element) => element.id === mentorship.id
      );
      if (mentorshipIndex > -1) {
        updatedMentorships[mentorshipIndex] = mentorship;
      } else {
        updatedMentorships.push(mentorship);
      }
    });
    setMentorships(updatedMentorships);
    setHasNextPage(response.next !== null);
    setLoading(false);
    updateMentorshipsCount('realized', response.count);
  };

  useEffect(() => {
    fetchRealizedMentorships();
  }, []);

  const handleNextPage = useCallback(async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const response = await mentorshipApi.getRealizedMentorshipsList({
      queryParams: `limit=20&offset=${offset}`,
    });
    if (response) {
      setMentorships([...mentorships, ...response.results]);
      setHasNextPage(!!response.next);
    }
    setLoading(false);
  }, [mentorships, page]);

  return (
    <Container>
      <WhiteBox>
        {mentorships?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhuma mentoria realizada foi encontrada"
            subtitle={`No momento não existe mentorias disponíveis`}
          />
        ) : (
          <MentorshipListContainer>
            {mentorships?.map((item) => (
              <MentorshipCard
                key={item.id}
                item={item}
                textButton={'Detalhes da mentoria'}
                openDetailsModal={openDetailsModal}
                currentTab={currentTab}
              />
            ))}
            {loading && (
              <SkeletonCardMentorship
                quantity={2}
                subTitleQuantity={4}
                cardHeight="350px"
                cardMargin="0"
              />
            )}
          </MentorshipListContainer>
        )}
      </WhiteBox>
      {hasNextPage && !loading && (
        <SeeMoreButton color={universityColor} onClick={handleNextPage}>
          Ver mais
        </SeeMoreButton>
      )}
      <DetailsModal
        isOpen={detailsModalOpen}
        onClose={closeDetailsModal}
        item={modalData}
        showFlagStatus={true}
        showStudentAssessment={true}
        title="Detalhes"
        mentoringStatus="Realizado"
        setPage={setPage}
        fetchMentorships={fetchRealizedMentorships}
      />
      <MentorModal
        mentor={selectedMentor}
        isOpen={mentorModalIsOpen}
        onClose={handleCloseMentorModal}
      />
    </Container>
  );
}
