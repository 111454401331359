import styled from 'styled-components';

export const CheckboxWrapper = styled.label`
  position: relative;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const CheckboxInput = styled.input`
  cursor: pointer;
  accent-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const CheckboxLabel = styled.span`
  color: #606062;

  > p {
    margin: 0;
  }

  a {
    color: ${({ universityColor }) =>
      universityColor ? universityColor : '#009291'};
  }
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
