import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);

  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    min-width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #efeeec;
  padding: 30px 60px;
  display: flex;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;

    canvas {
      width: 300px !important;
      height: auto !important;
    }
  }
`;

export const ContainerArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 40px;

  > div {
    background-color: ${({ color }) => color};
    padding: 4px;
    align-items: center;
    display: flex;
    border-radius: 4px;
  }

  > span {
    width: 100%;
    background-color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-weight: 500;
  }
`;
