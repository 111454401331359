import DateTime from 'react-datetime';
import styled from 'styled-components';

export const CustomDateTime = styled(DateTime)`
  &,
  .form-control {
    width: 100%;
  }
  .rdtPicker {
    top: ${(props) => (props.jobsCalendar ? '-303px' : '62px')};
  }
  .rdtDay.rdtToday.rdtActive,
  .rdtDay.rdtActive,
  .rdtDay.rdtActive:hover {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
    color: #ffffff;
    -webkit-box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
      0 7px 10px -5px rgb(47 145 144 / 15%);
    box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
      0 7px 10px -5px rgb(47 145 144 / 15%);
  }
  .rdtPicker,
  .rdtPicker th.rdtSwitch,
  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev,
  .rdtPicker .rdtTimeToggle {
    color: #484848;
  }
  .dow {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-weight: 500 !important;
  }
  .rdtMonths .rdtMonth.rdtActive,
  .rdtMonths .rdtYear.rdtActive,
  .rdtYears .rdtMonth.rdtActive,
  .rdtYears .rdtYear.rdtActive {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
    color: #ffffff;
  }
  .rdtCounters .rdtCounter:last-child .rdtCount {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    border-radius: 50%;
    border: 1px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  }
`;
