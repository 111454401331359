import React from 'react';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';

const UtilsLinkPersonal = (academic_profile) => {
  const {
    IconEmail,
    IconLinkedin,
    IconPortfolio,
    IconSocialMeta,
  } = wrapperIconsProfile();

  const linksData = [
    {
      icon: <IconEmail />,
      title: 'Email ',
      info: academic_profile?.email,
    },
    {
      icon: <IconSocialMeta />,
      title: 'Instagram ou Facebook',
      info: academic_profile?.facebook_url,
    },
    {
      icon: <IconLinkedin />,
      title: 'Linkedin',
      info: academic_profile?.linkedin_url,
    },
    {
      icon: <IconPortfolio />,
      title: 'Portfólio geral',
      info: academic_profile?.portfolio_url,
    },
  ];

  return linksData.filter((item) => item.info !== null && item.info !== '');
};

export default UtilsLinkPersonal;
