import React, { useContext } from 'react';

import { ModalX, InfoModalBackground, InfoModalContainer, HeaderModal, BodyModal } from './styled'

import information from '../../../../../assets/Oportutinites/information.svg';
import sucess from '../../../../../assets/Oportutinites/sucess.svg';

import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';

export default function InformationModal({
  informationHeader, errorMsg, type, isOpen,
  setModalOpen, finish, children, closeOtherModal,
  setModalOpenAtraction
}) {
  const { setModalIsOpen } = useContext(OpportunitiesCompanyContext);
  
  const setState = () => {
    if (closeOtherModal) {
      closeOtherModal();
    }
    if (finish) {
      setModalIsOpen(false);
        if (setModalOpenAtraction) {
          setModalOpenAtraction(false)
        }
    }
    setModalOpen(false);
  };

  const returnCurrentImg = () => { return type === 'success' ? sucess : information };

  return (
    <InfoModalBackground isOpen={isOpen} onClick={setState}>
      <InfoModalContainer>
        <HeaderModal>
          <ModalX onClick={setState} />
        </HeaderModal>
        <BodyModal>
          <img src={returnCurrentImg()} />

          <h4> {informationHeader} </h4>

          <p> {errorMsg} </p>

          {children && children}
        </BodyModal>
      </InfoModalContainer>
    </InfoModalBackground>
  );
}
