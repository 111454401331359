import {
  Box,
  BubbleDialog,
  Button,
  ButtonCancel,
  ContainerInputDescription,
  Content,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperButtons,
  WrapperTitle,
} from './style';
import { Input } from '../Steps/StepTwo/TodoTab/style';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import CloseButton from '../../../CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { formatWorkload } from '../../utils/workload';
import inputErrorIcon from 'assets/academicModeling/inputErrorIcon.svg';
import { ErrorMessage } from '../Steps/StepTwo/TodoTab/style';

export default function WorkLoadModal({
  config,
  setOpen,
  buttonConfirmFunction,
  buttonCancelFunction,
  initialValue = '',
  open,
}) {
  const [value, setValue] = useState('');
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!open) {
      setValue('');
      return;
    }
    setValue(initialValue);
  }, [initialValue, open]);

  const handleClose = () => {
    config?.onClose();
    setOpen(false);
  };

  useEffect(() => {
    if (!open) setTouched(false);
  }, [open]);

  const workloadError = useMemo(() => {
    if (value === '') {
      if (touched) return 'Campo obrigatório';
      return '';
    }
    return '';
  }, [value, touched]);

  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={open}>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            <CloseButton onClick={handleClose} />
            <WrapperTitle>
              <IconPersonStyled src={config.icon || IconPerson} />
              <BubbleDialog>
                <H4Styled>{config.bubbleText}</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled
              textAlign={config?.style?.textAlign}
              fontWeight={config?.style?.fontWeight}
              marginTop={config?.style?.marginTop}
              fontSize={config?.style?.fontSize}
            >
              <ContainerInputDescription>
                <Content>{ReactHtmlParser(config.ParagraphText)}</Content>
                <div className="labelInput">
                  <label htmlFor="workloadInput">Carga horária total*</label>
                  <Input
                    placeholder="HHH:MM"
                    id="workloadInput"
                    className="workloadInput"
                    onBlur={() => {
                      setTouched(true);
                    }}
                    onChange={(e) => {
                      setTouched(true);
                      formatWorkload(e, setValue);
                    }}
                    value={value}
                    error={workloadError}
                  />
                  {workloadError && (
                    <footer>
                      <img src={inputErrorIcon} alt="" />
                      <ErrorMessage>{workloadError}</ErrorMessage>
                    </footer>
                  )}
                </div>
              </ContainerInputDescription>
            </ParagraphStyled>
            <WrapperButtons>
              {config.showButtonCancel && (
                <ButtonCancel
                  width={config.buttonCancelWidth}
                  height={config.buttonCancelHeight}
                  onClick={() => buttonCancelFunction && buttonCancelFunction()}
                  disabled={config.loading}
                >
                  {config.buttonCancelText}
                </ButtonCancel>
              )}
              <Button
                type="button"
                width={config.buttonConfirmWidth}
                height={config.buttonConfirmHeight}
                onClick={() => {
                  buttonConfirmFunction && buttonConfirmFunction(value);
                }}
                active={value !== '' && !workloadError}
                disabled={config.loading || workloadError}
              >
                {config.loading && <FilterLoading />}
                {config.buttonConfirmText}
              </Button>
            </WrapperButtons>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [open, config, initialValue, value, touched]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

WorkLoadModal.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
    loading: false,
    icon: IconPerson,
    onClose: () => {},
  },
};
