import React, { useCallback, useContext, useState } from 'react';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';
import { ActionButton } from '../../styles';
import { DocIcon } from 'assets/csm/doc-icon';
import { getStudentCurriculumData } from 'services/university/curriculum';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import { getApplicationCoverLetter } from 'services/university/coverLetter';
import BaseLayoutContext from 'contexts/base-layout';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';

export function StudentDocuments() {
  const { selectStudent } = useCsm();
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);

  const {
    openModalWithContent,
    openSnackbar,
    handleIsLoadingState,
    closeModal,
    updateHasCoverLetter,
  } = useContext(BaseLayoutContext);

  const openCurriculum = useCallback(async ({ id }) => {
    handleIsLoadingState(true);

    const response = await getStudentCurriculumData(id);
    if (!response.message) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  const openLetter = useCallback(async ({ id }) => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(id);
    if (!response.message) {
      openModalWithContent(
        <CoverLetterContainer
          notStudent
          studentId={id}
          handleLoadingState={handleIsLoadingState}
          getApplicationCoverLetter={getApplicationCoverLetter}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
        />
      );
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  return (
    <>
      <ActionButton>
        <div>
          {selectStudent?.hasCurriculum ? (
            <button
              className="buttonCurriculum"
              onClick={() => openCurriculum(selectStudent)}
              disabled={!selectStudent.hasCurriculum}
              title={
                !selectStudent.hasCurriculum
                  ? 'Este estudante não possui currículo'
                  : ''
              }
            >
              <DocIcon color={'#ffffff'} />
              Visualizar currículo
            </button>
          ) : null}

          {selectStudent?.hasCoverLetter ? (
            <button
              className="buttonLetter"
              onClick={() => openLetter(selectStudent)}
              disabled={!selectStudent.hasCoverLetter}
              title={
                !selectStudent.hasCoverLetter
                  ? 'Este estudante não possui carta de apresentação'
                  : ''
              }
            >
              <DocIcon color={'#009291'} />
              Visualizar carta de apresentação
            </button>
          ) : null}
        </div>
      </ActionButton>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          showActions={false}
          showInterviewButton={false}
        />
      )}
    </>
  );
}
