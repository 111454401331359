import nameSplit from '../../utils/nameSplit';

const coursesAdapter = ({
  name,
  course_semester,
  course_status,
  principal_course,
  degree_level,
  degree_type,
  registration_date,
}) => {
  const course = {
    name,
    courseSemester: course_semester,
    courseStatus: course_status,
    degreeLevel: degree_level,
    degreeType: degree_type,
    principalCourse: principal_course,
  };
  return course;
};

const profilePayloadAdapter = ({
  name,
  email,
  mobilePhone,
  homePhone,
  facebookInstagramUrl,
  linkedinUrl,
  portfolioUrl,
  state,
  city,
  district,
  address,
  addressComplement,
  addressNumber,
  zipCode,
  birth_date,
  gender,
  marital_status,
  special_needs,
  travel_and_change,
  colorOrRace,
  genderIdentity,
  showGenderIdentity,
  specialNeeds,
}) => {
  const specialNeed = specialNeeds.filter(
    (item) => item.__isNew__ !== true && item.value !== null
  );
  const anotherSpecialNeeds = specialNeeds
    .filter((item) => item.__isNew__ === true || item.value === null)
    .map((e) => e.label);
  const profile = {
    name,
    email,
    birth_date,
    mobile_phone: mobilePhone,
    home_phone: homePhone,
    facebook_url: facebookInstagramUrl,
    portfolio_url: portfolioUrl,
    linkedin_url: linkedinUrl,
    state,
    city,
    district,
    address,
    address_complement: addressComplement,
    address_number: addressNumber,
    zip_code: zipCode,
    gender: gender.value,
    marital_status: marital_status.value,
    special_needs: specialNeed.map((e) => e.value),
    travel_and_change,
    another_special_needs: anotherSpecialNeeds,
    gender_identity: genderIdentity.value,
    color_or_race: colorOrRace.value,
    show_gender_identity: showGenderIdentity,
  };

  return profile;
};

const profileAdapter = ({
  gender,
  avatar,
  city,
  district,
  address,
  address_complement,
  address_number,
  zip_code,
  courses,
  email,
  mobile_phone,
  home_phone,
  facebook_url,
  portfolio_url,
  linkedin_url,
  marital_status,
  name,
  state,
  external_id,
  curriculum_filled,
  birth_date,
  specialNeeds,
  travel_and_change,
  another_special_needs,
  gender_identity,
  color_or_race,
  showGenderIdentity,
}) => {
  const profile = {
    birth_date,
    gender,
    avatar,
    city,
    district,
    address,
    addressComplement: address_complement,
    addressNumber: address_number,
    zipCode: zip_code,
    courses: courses.map((course) => coursesAdapter(course)),
    email,
    mobilePhone: mobile_phone,
    homePhone: home_phone,
    facebookInstagramUrl: facebook_url,
    portfolioUrl: portfolio_url,
    linkedinUrl: linkedin_url,
    maritalStatus: marital_status,
    fullName: name,
    name: nameSplit(name),
    state,
    externalId: external_id,
    curriculumFilled: curriculum_filled,
    special_needs: specialNeeds,
    travel_and_change,
    anotherDisability: another_special_needs,
    colorOrRace: color_or_race,
    genderIdentity: gender_identity,
    show_gender_identity: showGenderIdentity,
  };

  return profile;
};

export { coursesAdapter, profileAdapter, profilePayloadAdapter };
