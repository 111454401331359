import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const LinkItems = styled.div`
  font-size: 16px;
  display: flex;
  text-decoration: underline;
  font-weight: 400;
  cursor: pointer;
  color: #a6a6a6;
  text-align: center;
  flex-wrap: wrap;

  .linkItem {
    margin-top: 20px;
    margin-right: 20px;
    img {
      width: 25px;
      margin-right: 12px;
    }
  }
`;

export const AwaitingAnalysisText = styled.p`
  top: 50%;
  right: 8%;
  position: absolute;
  transform: translateY(-50%);
  margin: 0;
  font-size: 1rem;
  color: #e47639;
  font-weight: 400;
`;

export const AvatarItem = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 175px;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
`;

export const CurriculumLiked = styled.div`
  margin-bottom: 10px;
  color: ${dnaColor};
  svg {
    font-size: 17px;
    margin: -2px 5px;
  }
  span {
    font-size: 14px;
  }
`;

export const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem;
  position: relative;
  width: 100%;

  header {
    margin-bottom: 2.75rem;
    h2 {
      margin: 0;
      color: #009291;
      font-weight: 400;
      font-size: 1.5rem;
      text-align: center;
    }
    button {
      transform: rotate(45deg);
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      background-color: transparent;
      border: none;
      font-size: 3rem;
      color: #c4c4c4;
      cursor: pointer;
    }
  }
`;

export const FeedbackList = styled.div`
  width: 100%;
  border-radius: 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1366px) {
    white-space: nowrap;
    justify-content: flex-start;
  }
`;

export const ButtonDownload = styled.button`
  border: 2px solid #009291;
  margin: 1% 0 1% 2%;
  border-radius: 5px;
  cursor: pointer;
  width: 20%;
  background-color: #fff;
  color: #009291;
  padding: 3px;
  &:disabled {
    cursor: default;
    color: #bfbdbd;
    border-color: #bfbdbd;
    img {
      filter: saturate(0%);
      opacity: 0.5;
    }
  }
  @media (max-width: 1800px) {
    width: ${(props) => props.buttonWidth};
    padding: 2px;
  }
`;

export const ImgIcon = styled.img`
  width: 24px;
  height: 24px;
  padding: 1% 1%;
`;

export const Content = styled.div`
  margin: 20px 0;
  font-size: 16px;
  font-weight: 500;
`;

export const Footer = styled.div`
  width: 80%;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  button {
  }
`;
