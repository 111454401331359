import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1065px) {
    margin-left: auto;
  }
  margin: 10px 20px 0 0;
`;

export const FilterFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 705px) {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const FirstFilterGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SecondFilterGroup = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 705px) {
    flex-direction: column;
  }
`;
