import { authenticatedAPI } from 'services/api';

const getOptionsLanguages = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/students/me/languages`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveLanguage = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/languages`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getLanguages = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/languages`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateLanguage = async (data, id) => {
  const response = await authenticatedAPI
    .put(`/api/internal/students/me/languages/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteLanguage = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/students/me/languages/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};
export {
  getOptionsLanguages,
  getLanguages,
  saveLanguage,
  updateLanguage,
  deleteLanguage,
};
