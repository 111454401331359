import React, { Fragment, useContext, useState } from 'react';
import {
  Container,
  Timeline,
  TimelineContent,
  InfoDate,
  Containers,
  InfoContainer,
  IconContainer,
  InfoContent,
  SpanPopUp,
  Circle,
} from './styles';
import moment from 'moment';
import calendarIcon from 'assets/csm/calendarIcon.svg';
import envelopeIcon from 'assets/csm/envelopeIcon.svg';
import eye from 'assets/JobOffer/eye.svg';
import { EmailDetails } from '../EmailDetails';
import BaseLayoutContext from 'contexts/base-layout';
import { SkeletonTimeline } from '../Skeleton/skeletonTimeline';
import { PaginationEmails } from './pagination';
import { LoadEmails } from './pagination';

export function TimelineList({
  emailDetails,
  setEmailDetails,
  emailItems,
  setEmailItems,
  loadMore,
  loading,
  limit,
  selectStudent,
  loadingEmails,
}) {
  const [selectedCampaignIds, setSelectedCampaignIds] = useState({ emailId: null, campaignId: null });
  const { openSnackbar } = useContext(BaseLayoutContext);

  const isValidDate = (dateString) => {
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
  };

  const handleSpanPopUpClick = async (emailId, campaignId) => {
    try {
      setSelectedCampaignIds({ emailId, campaignId });
      setEmailDetails(true);
    } catch (error) {
      setEmailDetails(false);
      openSnackbar('Não foi possível abrir os detalhes da campanha.', true)
    }
  };

  const returnButtonPagination = () => {
    if(emailItems.length >= limit){
      return loading ? (
        <LoadEmails />
      ) : (
        <PaginationEmails onClick={() => loadMore()}/>
      );
    }
  }

  const returnParagraph = () => {
    if(loadingEmails && emailItems.length === 0){
      return (<p>Nenhuma campanha encontrada.</p>)
    }
  }

  return (
    <Container>
      {emailDetails && (
        <EmailDetails
          item={selectStudent}
          selectedCampaignIds={selectedCampaignIds}
          emailItem={emailItems}
          setEmailItem={setEmailItems}
          onClose={() => setEmailDetails(false)}
        />
      )}

      <Timeline>
        {!loadingEmails && emailItems.length === 0 ? ( <SkeletonTimeline /> ) : (
          <>
          {emailItems.length > 0 && (
            emailItems.map((campaign, index) => (
            <Fragment key={index}>
              <TimelineContent>
                  {campaign.special && (
                    <InfoDate>
                      <span>
                        <IconContainer>
                          <img src={calendarIcon} alt="Calendar" />
                        </IconContainer>
                        <p>{campaign.month}</p>
                      </span>
                    </InfoDate>
                  )}
                  {campaign.id && (
                    <Containers>
                      <InfoContainer>
                        <IconContainer>
                          <img src={envelopeIcon} alt="Envelope" />
                        </IconContainer>

                        <InfoContent>
                          <div className="infoDate">
                            Envio: {
                              isValidDate(campaign.sent_amazon)
                              ? moment(campaign.sent_amazon).format('DD/MM/YYYY - HH:mm') : ''
                            }
                          </div>
                          <div className="infoEmail">{campaign.name}</div>
                        </InfoContent>
                        <SpanPopUp onClick={() => handleSpanPopUpClick(campaign.id, campaign.campaign_id)}>
                          <div>Visualizar detalhes</div>
                          <img src={eye} alt="Eye" />
                        </SpanPopUp>
                      </InfoContainer>
                    </Containers>
                  )}
                </TimelineContent>
                  <Circle />
              </Fragment>
              ))
            )}
            </>
          )}
          {returnParagraph()}
          {returnButtonPagination()}
        </Timeline>
    </Container>
  );
}
