const getAreas = async (defaultA, aditionalA, trendA) => {
  const areaDefault = await defaultA.map((item) => item.value);
  const areaAditional = await aditionalA.map((item) => item.value);
  const areaTrend = await trendA.map((item) => item.value);

  return [...areaDefault, ...areaAditional, ...areaTrend];
};

const professionDataAdapter = async ({
  name,
  description,
  courses,
  courseProgress,
  defaultAreas,
  aditionalCourses,
  marketTendencies,
  behavioral,
  published,
  salaryRange,
  requirements,
  noticeUrl,
  transversal,
  content,
}) => {
  const data = {
    name,
    description: content,
    salary_range: salaryRange,
    previous_requirements: requirements,
    course_progress: courseProgress.value,
    associated_courses: courses.map((item) => item.value),
    associated_areas: await getAreas(
      defaultAreas,
      aditionalCourses,
      marketTendencies
    ),
    associated_behavioral: behavioral.map((item) => item.value),
    published,
    transversal_profession: transversal,
    notice_url: noticeUrl,
  };

  return data;
};

export { professionDataAdapter };
