import { Container } from './style';
import { ModalFinalization } from './FinalizationVacancy/modalFinalization';

export default function FinalizationModal(props){
  const {
    openConfirmationModal,
    setOpenConfirmationModal,
    jobID,
    isPartnerOffer,
    limit,
    status,
    setListJobsCompany,
    setListJobsCompanyHistory,
    setJobsCount,
    companyFilters,
    dataFunction,
  } = props;

  const closeModal = () => {
    setOpenConfirmationModal(false);
  };

  return (
    <Container isOpen={openConfirmationModal}>
      <ModalFinalization
        company={true}
        visible={openConfirmationModal}
        controlModal={setOpenConfirmationModal}
        setModalFinish={setOpenConfirmationModal}
        jobID={jobID}
        limit={limit}
        status={status}
        isPartnerOffer={isPartnerOffer}
        closeModal={closeModal}
        setListJobsCompany={setListJobsCompany}
        setListJobsCompanyHistory={setListJobsCompanyHistory}
        setJobsCount={setJobsCount}
        companyFilters={companyFilters}
        dataFunction={dataFunction}
      />
    </Container>
  );
}