import {
  Container,
  ContainerGradientImage,
  ContainerName,
  ContainerInformations,
  Divider,
  ContainerActions,
  ActionButton,
  Card,
} from './styled';
import location from 'assets/SettingsPage/Profile/location.svg';
import building from 'assets/SettingsPage/Profile/building.svg';
import { GreenButton } from '../../Styled';
import Tooltip from 'components/NewTooltip';
import coursesSvg from 'assets/SettingsPage/Profile/Courses.svg';
import gallerySvg from 'assets/SettingsPage/Profile/Gallery.svg';
import GenericMessage from 'components/GenericMessage';

export default function HorizontalCard({ universities, openModal, loading }) {
  const mapUniversities = () => {
    return universities.map((item) => (
      <Card>
        <ContainerGradientImage color={item.color}>
          <img src={item.logo?.file} />
        </ContainerGradientImage>

        <ContainerName>
          <h4>{item.company_presentation}</h4>
          <ContainerInformations>
            <span>
              <div>
                <img src={location} />
              </div>
              <p>
                {item.city} / {item.state}
              </p>
            </span>
            <span>
              <div>
                <img src={building} />
              </div>
              <p>
                {item.unities} {item.unities > 1 ? 'unidades' : 'unidade'}
              </p>
            </span>
          </ContainerInformations>
        </ContainerName>

        <Divider />

        <ContainerActions>
          <GreenButton onClick={() => openModal(item.id)}>
            Visualizar perfil
          </GreenButton>

          <Tooltip
            text={'Visualizar galeria de fotos'}
            colorSchema="black"
            direction="top"
          >
            <ActionButton onClick={() => openModal(item.id, 2)}>
              <img src={gallerySvg} />
            </ActionButton>
          </Tooltip>

          <Tooltip
            text={'Visualizar cursos'}
            colorSchema="black"
            direction="top"
          >
            <ActionButton onClick={() => openModal(item.id, 1)}>
              <img src={coursesSvg} />
            </ActionButton>
          </Tooltip>
        </ContainerActions>
      </Card>
    ));
  };

  if (universities.length === 0 && !loading) {
    return (
      <GenericMessage
        title="Sem resultados"
        subtitle="Nenhum resultado foi obtido para a busca!"
      />
    );
  }

  return <Container>{mapUniversities()}</Container>;
}
