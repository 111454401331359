import React, { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import FullScreenDialog from 'components/FullScreenDialog/FullScreenDialog';
import NewFullScreenDialog from 'components/FullScreenDialog/NewFullScreenDialog';

export default function ModalWithContent(props) {
  const {
    isOpen,
    closeModal,
    exitingFunction,
    content,
    dialogTitle,
    colorScheme,
    disableBackDrop,
    dialogTitleDisable,
    isNewModalDialog,
    modalName,
  } = props;
  const { miniActive, deactivateScrollModalWithContent, sidebarMinimize } =
    useContext(BaseLayoutContext);
  return isNewModalDialog ? (
    <NewFullScreenDialog
      sidebarMinimize={sidebarMinimize.bind(this)}
      miniActive={miniActive}
      isOpen={isOpen}
      closeModal={() => closeModal()}
      exitingFunction={exitingFunction}
      content={content}
      dialogTitle={dialogTitle}
      colorScheme={colorScheme}
      disableBackDrop={disableBackDrop}
      dialogTitleDisable={dialogTitleDisable}
      deactivateScroll={deactivateScrollModalWithContent}
    />
  ) : (
    <FullScreenDialog
      sidebarMinimize={sidebarMinimize.bind(this)}
      miniActive={miniActive}
      isOpen={true}
      closeModal={() => this.closeModal()}
      exitingFunction={exitingFunction}
      content={modalContent}
      dialogTitle={modalTitle}
      colorScheme={modalColorScheme}
      disableBackDrop={disableBackDrop}
      dialogTitleDisable={dialogTitleDisable}
      deactivateScroll={deactivateScrollModalWithContent}
    />
  );
}
