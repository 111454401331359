import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
} from '../style';
import React, { Fragment, useMemo } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

export default function ModalSatisfactionFinish(props) {
  const {
    config,
    closeAllModal,
    modalOpenState,
    universityColor,
    profileData,
  } = props;

  const returnGender = () => {
    if (profileData.gender.value === 'female') {
      return 'satisfeita';
    }

    if (profileData.gender.value === 'male') {
      return 'satisfeito';
    }
  };

  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal>
            <WrapperTitle alignIcon={config?.style?.alignIcon}>
              <IconPersonStyled
                universityColor={universityColor}
                src={IconPerson}
              />
              <BubbleDialog universityColor={universityColor}>
                <H4Styled>Wow, que bom que está {returnGender()}!</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled>
              <p>
                Mas será que você está usando o seu potencial máximo,{' '}
                {profileData.name}?
              </p>
              <p>
                Explore o menu <strong>"Mapa de Carreiras"</strong> e saiba mais sobre as
                possibilidades de atuações profissionais na sua área de
                formação. Tem sempre uma novidade. E lá você vai encontrar
                informações sobre remuneração (faixa salarial), principais
                atividades da carreira e as competências mais requeridas pelo
                mercado de trabalho.{' '}
              </p>
              <p>Bora para o próximo nível!</p>
            </ParagraphStyled>

            <Button onClick={closeAllModal} universityColor={universityColor}>
              OK, ENTENDI
            </Button>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}
