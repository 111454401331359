import styled from 'styled-components';

const dnaGreen = '#009291';

export const Container = styled.div`
  margin-top: 15px;
  background-color: white;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  ${(props) => props.half && 'width: 50%'};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  gap: ${(props) => (props.row ? '30px' : '2px')};
`;

export const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 15px;
`;

export const GridContainerTitle = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr 1fr;
  margin-left: 38px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 30px;
`;

export const Line = styled.div`
  border-bottom: 1px solid rgb(196 196 196 / 30%);
  width: 100%;
`;

export const ModelEmail = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #b8b8b8;
`;

export const Period = styled.div`
  grid-area: 1 / 2 / 2 / 3;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #b8b8b8;
`;

export const PreviewEmail = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-area: 1 / 3 / 2 / 4;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  margin-left: 14px;
  color: #b8b8b8;
`;

export const PreviewButton = styled.button`
  margin: 10px 0;
  color: ${(props) => (props.active === true ? `${dnaGreen}` : '#c4c4c4')};
  border: 1.5px solid #c4c4c4;
  background: ${(props) => (props.active === true ? 'white' : null)};
  padding: 15px 25px;
  font-weight: 700;
  width: fit-content;
  border-radius: 0;
  cursor: pointer;

  :first-child {
    border-radius: 4px 0 0 4px;
    margin-left: 38px;
  }

  :last-child {
    border-radius: 0 4px 4px 0;
  }

  cursor: pointer;
`;
