import styled from 'styled-components';

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  width: 20px;
  height: 20px;
  background: ${(props) => (props.checked ? '#009291' : 'white')};
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid grey;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const BackgroundCheckbox = styled.div`
  padding: 6px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: flex;
  gap: 5px;
  position: relative;
  align-items: center;

  svg:nth-child(2) {
    transform: ${(props) =>
      props.menuOpened ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 0.3s ease-in-out;
  }
`;

export const DropDown = styled.div`
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 2;
  width: 300px;
  background-color: #fff;
  top: 35px;
  left: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

export const DropDownOption = styled.span`
  padding: 10px;

  &:hover {
    background-color: #f5f5f5;
  }
`;
