import React, { Fragment, useContext } from 'react';
import {
  FragmentDiv,
  CardValues,
  TitleCardValues,
  ParagraphCardValues,
  TextDiv,
} from './stylesCardsValues';

import BaseLayoutContext from 'contexts/base-layout';

import ApprenticeShipIcon from 'assets/ComponentIcons/ApprenticeShipIcon';
import AutonomyIcon from 'assets/ComponentIcons/AutonomyIcon';
import EvolutionIcon from 'assets/ComponentIcons/EvolutionIcon';
import CollaborationIcon from 'assets/ComponentIcons/CollaborationIcon';
import ProportionIcon from 'assets/ComponentIcons/ProportionIcon';
import ResultValuesIcon from 'assets/ComponentIcons/ResultValuesIcon';
import SalaryValuesIcon from 'assets/ComponentIcons/SalaryValues';

const CardsContentValues = () => {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Fragment>
      <TextDiv>
        Conheça os significados de cada valor do ambiente de trabalho
      </TextDiv>
      <FragmentDiv>
        <CardValues>
          <div className="image-values">
            <ApprenticeShipIcon color={universityColor} />
          </div>
          <TitleCardValues>Aprendizado</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>incentive constantemente </strong>a diferenciação de perfil
            e o desenvolvimento de novas competências.
          </ParagraphCardValues>
        </CardValues>

        <CardValues>
          <div className="image-values">
            <AutonomyIcon color={universityColor} />
          </div>

          <TitleCardValues>Autonomia</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>dê às pessoas muita abertura </strong>para propor e realizar
            novas iniciativas, tarefas e ações.
          </ParagraphCardValues>
        </CardValues>

        <CardValues>
          <div className="image-values">
            <EvolutionIcon color={universityColor} />
          </div>

          <TitleCardValues>Crescimento</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>constantemente promova oportunidades </strong>de promoção,
            novos desafios e responsabilidades.
          </ParagraphCardValues>
        </CardValues>

        <CardValues>
          <div className="image-values">
            <CollaborationIcon color={universityColor} />
          </div>

          <TitleCardValues>Colaboração</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>
              promova um ambiente voltado para o trabalho em equipe.
            </strong>
          </ParagraphCardValues>
        </CardValues>
      </FragmentDiv>

      <FragmentDiv>
        <CardValues>
          <div className="image-values">
            <ProportionIcon color={universityColor} />
          </div>

          <TitleCardValues>Equilíbrio</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>promova flexibilidade</strong> para conciliar diferentes
            interesses e compromissos da vida pessoal e profissional.
          </ParagraphCardValues>
        </CardValues>

        <CardValues>
          <div className="image-values">
            <ResultValuesIcon color={universityColor} />
          </div>

          <TitleCardValues>Resultado</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>incentive constantemente</strong> a orientação das pessoas
            para o alcance de metas objetivas e foco nos resultados das
            entregas.
          </ParagraphCardValues>
        </CardValues>

        <CardValues>
          <div className="image-values">
            <SalaryValuesIcon color={universityColor} />
          </div>

          <TitleCardValues>Salário</TitleCardValues>
          <ParagraphCardValues>
            Quero atuar em um ambiente de trabalho que{' '}
            <strong>ofereça um programa de reconhecimento financeiro </strong>
            diferenciado e com benefícios.
          </ParagraphCardValues>
        </CardValues>
      </FragmentDiv>
    </Fragment>
  );
};

export default CardsContentValues;
