import React, { useEffect, useState } from 'react';
import { shape, func, bool } from 'prop-types';
import {
  FormHelperText,
  FormControl,
  InputLabel,
  Grid,
  TextField,
  ListItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { FastField } from 'formik';
import SearchableSelect from 'react-select';
import classNames from 'classnames';
import DateTimePicker from 'components/DateTimePicker';
import { Mixpanel } from 'utils/mixpanel';
import useDefineColor from 'constants/colors';

const ExperienceLifeForm = ({
  classes,
  errors,
  handleChange,
  handleBlur,
  touched,
  values,
  isEdit,
  setFieldValue,
  countriesOptions,
  setFieldTouched,
  getDedicatedHoursAndDays,
  lifeExperienceOptions,
  initialValues,
  setExperienceFormChosen,
  universityColor,
}) => {
  const [endAtControl, setEndAtControl] = useState(true);
  const [isLifeForm, setIsLifeForm] = useState(false);
  const [showDescription, setShowDescription] = useState(true);
  const [charCount, setCharCount] = useState(0);
  const maxChars = 200;

  useEffect(() => {
    if (isEdit) {
      if (initialValues && initialValues.inProgress) {
        setEndAtControl(false);
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (isLifeForm) {
      Mixpanel.track('Abriu formulário Experiência de Vida');
      if (!isEdit) {
        setExperienceFormChosen('lifeExperience');
      }
    }
  }, [isLifeForm]);

  useEffect(() => {
    if (isEdit) {
      if (values.description && values.description.length > 0) {
        setShowDescription(false);
        setCharCount(values.description.length);
      }
    }
  }, [isEdit, values.description]);

  return (
    <ListItem className={classes.listItem}>
      <Grid container justify="center" spacing={24}>
        <Grid item md={12} xs={12}>
          <FastField
            className={classes.inputField}
            InputProps={{
              className: classes.input,
            }}
            InputLabelProps={{
              FormLabelClasses: {
                focused: classes.inputField,
              },
            }}
            name="role"
            variant="filled"
            id="role"
            label="Título da Experiência"
            placeholder="Ex.: Intercâmbio"
            onChange={(event) => {
              handleChange(event);
              setIsLifeForm(true);
            }}
            onBlur={handleBlur}
            error={!!errors.role && !!touched.role}
            value={values.role}
            component={TextField}
            required
          />
          {errors.role && touched.role ? (
            <FormHelperText className={classes.helperText}>
              {errors.role}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="experienceType"
              shrink
              required
              error={errors.experienceType && touched.experienceType}
            >
              Tipo
            </InputLabel>

            <SearchableSelect
              name="experienceType"
              id="experienceType"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classNames([
                classes.selectSection,
                classes.selectSectionAutoHeight,
                classes.fullWidth,
              ])}
              onBlur={(event) => setFieldTouched('experienceType', event.label)}
              onChange={(event) => {
                if (event) {
                  setFieldValue('experienceType', event);
                } else {
                  setFieldValue('experienceType', '');
                }
              }}
              options={lifeExperienceOptions.experience_types || []}
              value={values.experienceType}
              placeholder="Selecione"
            />
          </FormControl>
          {!!errors.experienceType && !!touched.experienceType && (
            <FormHelperText className={classes.helperText}>
              {errors.experienceType}
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="knowledges"
              shrink
              required
              error={errors.knowledges && touched.knowledges}
            >
              Conhecimentos e Competências
            </InputLabel>

            <SearchableSelect
              name="knowledges"
              id="knowledges"
              isMulti
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classNames([
                classes.selectSection,
                classes.selectSectionAutoHeight,
                classes.fullWidth,
              ])}
              onBlur={(event) => setFieldTouched('knowledges', event.label)}
              onChange={(event) => {
                if (event.length <= 5) {
                  setFieldValue('knowledges', event);
                }
              }}
              options={lifeExperienceOptions.knowledges || []}
              value={values.knowledges}
              placeholder="Digite ou Escolha"
            />
          </FormControl>
          {!!errors.knowledges ? (
            !!touched.knowledges && (
              <FormHelperText className={classes.helperText}>
                {errors.knowledges}
              </FormHelperText>
            )
          ) : (
            <FormHelperText className={classes.helperText}>
              Até 5 conhecimentos
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="country"
              shrink
              required
              error={errors.country && touched.country}
            >
              País
            </InputLabel>

            <SearchableSelect
              name="country"
              id="country"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classNames([
                classes.selectSection,
                classes.selectSectionAutoHeight,
                classes.fullWidth,
              ])}
              onBlur={(event) => setFieldTouched('country', event.label)}
              onChange={(event) => {
                if (event) {
                  setFieldValue('country', event);
                } else {
                  setFieldValue('country', '');
                }
              }}
              options={countriesOptions || []}
              value={values.country}
              placeholder="Selecione"
            />
          </FormControl>
          {!!errors.country && !!touched.country && (
            <FormHelperText className={classes.helperText}>
              {errors.country}
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <FastField
            className={classes.inputField}
            InputProps={{
              className: classes.input,
            }}
            InputLabelProps={{
              FormLabelClasses: {
                focused: classes.inputField,
              },
            }}
            name="city"
            required
            variant="filled"
            id="city"
            label="Cidade"
            placeholder="Ex.: Nova Iorque"
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.city && !!touched.city}
            value={values.city}
            component={TextField}
          />
          {errors.city && touched.city ? (
            <FormHelperText className={classes.helperText}>
              {errors.city}
            </FormHelperText>
          ) : null}
        </Grid>
        <Grid item md={!values.inProgress ? 6 : 12} xs={12}>
          <DateTimePicker
            className={(classes.textFieldDatePicker, classes.inputField)}
            required
            InputProps={{
              className: [
                classes.input,
                classes.selectSection,

                classes.selectSectionCard,
              ],
            }}
            InputLabelProps={{
              shrink: true,
              FormLabelClasses: {
                focused: classes.inputField,
              },
            }}
            variant="filled"
            name="startedAt"
            id="startedAt"
            type="date"
            label="Data de Início"
            placeholder="Ex.: 17/11/2021"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.startedAt}
            error={errors.startedAt && touched.startedAt}
          />
          {errors.startedAt && touched.startedAt ? (
            <FormHelperText className={classes.helperText}>
              {errors.startedAt}
            </FormHelperText>
          ) : null}
        </Grid>
        {endAtControl && (
          <Grid item md={6} xs={12}>
            <DateTimePicker
              className={(classes.textFieldDatePicker, classes.inputField)}
              InputProps={{
                className: [
                  classes.input,
                  classes.selectSection,

                  classes.selectSectionCard,
                ],
              }}
              InputLabelProps={{
                shrink: true,
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              variant="filled"
              name="endedAt"
              required
              id="endedAt"
              type="date"
              label="Data de Término"
              placeholder="Ex.: 17/11/2022"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.endedAt}
              error={errors.endedAt && touched.endedAt}
            />
            {errors.endedAt && touched.endedAt && (
              <FormHelperText className={classes.helperText}>
                {errors.endedAt}
              </FormHelperText>
            )}
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="dedicatedDays"
              shrink
              required
              error={!!errors.dedicatedDays && !!touched.dedicatedDays}
            >
              Dias Dedicados
            </InputLabel>

            <SearchableSelect
              name="dedicatedDays"
              id="dedicatedDays"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classNames([classes.selectSection, classes.fullWidth])}
              onBlur={(event) => setFieldTouched('dedicatedDays', event.label)}
              onChange={(event) => {
                if (event) {
                  setFieldValue('dedicatedDays', event);
                }
              }}
              options={getDedicatedHoursAndDays(7, 'Dia') || []}
              value={values.dedicatedDays}
              placeholder="Dias dedicados semanalmente"
            />
          </FormControl>
          {!!errors.dedicatedDays && !!touched.dedicatedDays && (
            <FormHelperText className={classes.helperText}>
              {errors.dedicatedDays}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel
              htmlFor="dedicatedHours"
              shrink
              required
              error={!!errors.dedicatedHours && !!touched.dedicatedHours}
            >
              Horas Dedicadas
            </InputLabel>

            <SearchableSelect
              name="dedicatedHours"
              id="dedicatedHours"
              isClearable={false}
              InputLabel={{
                shrink: true,
              }}
              value={values.dedicatedHours}
              menuPlacement="bottom"
              isSearchable
              classNamePrefix="react-select"
              className={classNames([classes.selectSection, classes.fullWidth])}
              onBlur={(event) => setFieldTouched('dedicatedHours', event.label)}
              onChange={(event) => {
                if (event) {
                  setFieldValue('dedicatedHours', event);
                }
              }}
              options={getDedicatedHoursAndDays(24, 'Hora') || []}
              defaultValue={values.dedicatedHours}
              placeholder="Tempo médio de dedicação diária"
            />
          </FormControl>
          {!!errors.dedicatedHours && !!touched.dedicatedHours && (
            <FormHelperText className={classes.helperText}>
              {errors.dedicatedHours}
            </FormHelperText>
          )}
        </Grid>
        <Grid item md={12} xs={12}>
          {showDescription ? (
            <p style={{ textAlign: 'center' }}>
              Quer falar mais sobre essa experiência?{' '}
              <a
                href="#"
                onClick={() => setShowDescription(false)}
                style={{
                  color: universityColor,
                  textDecoration: 'underline',
                }}
              >
                Clique aqui e adicione uma descrição.
              </a>
            </p>
          ) : (
            <>
              <FastField
                className={classes.inputField}
                InputProps={{
                  className: classes.input,
                }}
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: classes.inputField,
                  },
                }}
                name="description"
                variant="filled"
                id="description"
                label="Descrição (Opcional)"
                placeholder="Descreva quais foram os seus aprendizados e as suas conquistas através dessa experiência."
                onChange={(event) => {
                  if (event.target.value.length <= maxChars) {
                    handleChange(event);
                    setCharCount(event.target.value.length);
                  }
                }}
                onBlur={handleBlur}
                error={!!errors.role && !!touched.role}
                value={values.description}
                component={TextField}
                rows={4}
                multiline
                inputProps={{ maxLength: maxChars }}
              />
              <div
                style={{
                  textAlign: 'right',
                  marginTop: 5,
                  fontSize: 12,
                  color: 'gray',
                }}
              >
                CARACTERES: {charCount}/{maxChars}
              </div>
            </>
          )}
        </Grid>

        <ListItem
          className={classNames([
            classes.listItem,
            classes.listItemPaddingTopZero,
          ])}
        >
          <FormControlLabel
            style={{ justifyContent: 'center !important' }}
            classes={{
              label: classes.checkboxLabel,
            }}
            labelPlacement="center"
            label="Estou vivendo essa experiência atualmente"
            control={
              <Checkbox
                id="inProgress"
                name="inProgress"
                checked={values.inProgress}
                classes={{
                  checked:
                    classes[`${useDefineColor(universityColor)}checkBox`],
                }}
                onChange={(event) => {
                  handleChange(event);
                  setFieldValue('endedAt', '');
                  setEndAtControl(!endAtControl);
                }}
                color="default"
                tabIndex={-1}
                disableRipple
              />
            }
          />
        </ListItem>
      </Grid>
    </ListItem>
  );
};

ExperienceLifeForm.defaultProps = {
  isEdit: false,
  setExperienceFormChosen: undefined,
};

ExperienceLifeForm.propTypes = {
  classes: shape.isRequired,
  errors: shape.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  touched: shape.isRequired,
  values: shape.isRequired,
  setFieldValue: func.isRequired,
  countriesOptions: shape.isRequired,
  setFieldTouched: func.isRequired,
  getDedicatedHoursAndDays: func.isRequired,
  lifeExperienceOptions: shape.isRequired,
  initialValues: shape.isRequired,
  isEdit: bool,
  setExperienceFormChosen: func,
};

export default ExperienceLifeForm;
