export function CircleAlert({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 2.75C5.27208 2.75 2.25 5.77208 2.25 9.5C2.25 13.2279 5.27208 16.25 9 16.25C12.7279 16.25 15.75 13.2279 15.75 9.5C15.75 5.77208 12.7279 2.75 9 2.75ZM0.75 9.5C0.75 4.94365 4.44365 1.25 9 1.25C13.5563 1.25 17.25 4.94365 17.25 9.5C17.25 14.0563 13.5563 17.75 9 17.75C4.44365 17.75 0.75 14.0563 0.75 9.5Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 5.75C9.41421 5.75 9.75 6.08579 9.75 6.5V9.5C9.75 9.91421 9.41421 10.25 9 10.25C8.58579 10.25 8.25 9.91421 8.25 9.5V6.5C8.25 6.08579 8.58579 5.75 9 5.75Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 12.5C8.25 12.0858 8.58579 11.75 9 11.75H9.0075C9.42171 11.75 9.7575 12.0858 9.7575 12.5C9.7575 12.9142 9.42171 13.25 9.0075 13.25H9C8.58579 13.25 8.25 12.9142 8.25 12.5Z"
        fill={color}
      />
    </svg>
  );
}
