import SegredosThumb from 'assets/img/thumbs/success-overcoming-8segredos.jpg';
import SucessoThumb from 'assets/img/thumbs/success-overcoming-sucesso-fracasso.jpg';
import KiwiThumb from 'assets/img/thumbs/success-overcoming-kiwi.png';

import TerceiraMedidaThumb from 'assets/img/thumbs/success-overcoming-livro-terceira-medida-sucesso.jpg';
import PoderAcaoThumb from 'assets/img/thumbs/success-overcoming-livro-poder-acao.jpg';
import TrabalheHorasThumb from 'assets/img/thumbs/success-overcoming-livro-trabalhe-4-horas.jpg';

import MovieSrThumb from 'assets/img/thumbs/selfknowledge-filme-um-senhor.jpg';
import MovieLostMarsThumb from 'assets/img/thumbs/selfknowledge-filme-perdido-marte.jpg';
import MovieLalalandThumb from 'assets/img/thumbs/selfknowledge-filme-lalaland.jpg';

import StepOneForm from '../components/forms/succesOvercoming/StepOneForm';
import StepTwoForm from '../components/forms/succesOvercoming/StepTwoForm';
import StepThreeForm from '../components/forms/succesOvercoming/StepThreeForm';
import StepFourForm from '../components/forms/succesOvercoming/StepFourForm';
import StepFiveForm from '../components/forms/succesOvercoming/StepFiveForm';

const data = {
  id: 'successOvercoming',
  trailTitle: 'Jornada Carreira e Futuro',
  steps: [
    {
      id: 1,
      video: 'https://www.youtube.com/embed/yH001715JuQ?rel=0',
      form: StepOneForm,
      intro:
        'Antes de começarmos, reserve um tempo para refletir e responder as perguntas abaixo. Isso é muito importante para você se orientar ao longo dessa Trilha:',
    },
    {
      id: 2,
      video: 'https://www.youtube.com/embed/8HdbYSa0L5g?rel=0',
      form: StepTwoForm,
      intro:
        'Seu legado é sua história. Tente responder abaixo como os diferentes fatores influenciam na sua concepção de Sucesso:',
    },
    {
      id: 3,
      video: 'https://www.youtube.com/embed/DayKep4AKgE?rel=0',
      form: StepThreeForm,
      intro:
        'Cada pessoa tem uma concepção muito específica do que seja sucesso para si. Marque os 3 principais fatores abaixo que pesam na sua concepção de sucesso. Em seguida, justifique.',
    },
    {
      id: 4,
      video: 'https://www.youtube.com/embed/1NLWkuHzfds?rel=0',
      form: StepFourForm,
      intro:
        'Aceita um Desafio? Com base em tudo o que já aprendeu até aqui, tente solucionar os desafios abaixo.',
    },
    {
      id: 5,
      video: 'https://www.youtube.com/embed/g4W9wkENARI?rel=0',
      form: StepFiveForm,
      intro:
        'Agora que você já explorou o que é  Sucesso e o que é importante para você se considerar como uma pessoa bem sucedida, tente concluir:',
    },
    {
      id: 6,
      content: {
        videos: {
          trail: [
            {
              img: SegredosThumb,
              title: 'Richard John – 8 segredos para o sucesso',
              description:
                'Questionado por uma estudante sobre os motivos que levam ao sucesso, Richard John entrevistou 500 participantes do TED Talks e descobriu os 8 segredos que levaram essas pessoas a serem bem sucedidas em seus projetos: trabalhar com nossas paixões, trabalhar duro, focar, persistir, desenvolver boas ideias, se tornar muito bom em algo, buscar ir sempre além e servir aos outros.',
            },
            {
              img: SucessoThumb,
              title:
                'Elizabeth Gilbert – Sucesso, fracasso e motivação para continuar criando',
              description:
                'A famosa autora do livro “Comer, Rezar e Amar” nos conta os momentos de grande angústia vividos logo após o seu grande sucesso com o best seller e seu medo de fracassar com um novo livro. Nesse processo, descobre algo fundamental sobre a relação entre sucesso e fracasso.',
            },
            {
              img: KiwiThumb,
              title: 'TED – O próximo passo da revolução colaborativa',
              description:
                'Todos temos limitações reais que nos desafiam na busca de nossos sonhos. Nessa animação criada por Dony Permedi, Kiwi, uma das poucas aves sem capacidade de voar, tem exatamente o sonho de voar. Para superar essa limitação, Kiwi cria uma alternativa para alcançar seu sonho ao inverter as condições de seu ambiente com criatividade e trabalho duro. Uma história sobre tornar o impossível realidade.',
            },
          ],
        },
      },
    },
    {
      id: 7,
      content: {
        books: {
          trail: [
            {
              img: TerceiraMedidaThumb,
              title: 'A Terceira Medida do Sucesso - Arianna Huffington',
              description:
                'Uma das mulheres de maior influência no mundo explica que nossa atual definição de sucesso está literalmente nos matando e nos ajuda a redefinir o que é uma vida realmente bem sucedida.',
            },
            {
              img: PoderAcaoThumb,
              title: 'O Poder da Ação - Paulo Vieira',
              description:
                'Esse livro é um convite para começar a percorrer um caminho de realizações baseado em desafios e atitudes (acreditar, ter foco e agir) capazes de promover a mudança rumo à vida que queremos viver.',
            },
            {
              img: TrabalheHorasThumb,
              title: 'Trabalhe 4 Horas por Semana – Tim Ferriss',
              description:
                'Um dos maiores empreendedores seriais do mundo nos ensina a esquecer conceitos antigos de trabalho e aposentadoria, traçar um mapa para realizar nossos sonhos, viver mais e trabalhar menos.',
            },
          ],
        },
      },
    },
    {
      id: 8,
      content: {
        movies: {
          trail: [
            {
              img: MovieSrThumb,
              title: 'Um senhor estagiário',
              description:
                'Ben é um viúvo aposentado que para preencher seu tempo livre aceita o desafio de se tornar estagiário de uma empresa virtual de loja de roupas femininas. Ben passa a trabalhar com a dona do negócio, Jules, uma jovem com fama de difícil e que vê sua vida familiar ser cada vez mais negligenciada com o crescimento do negócio. Mas, a proximidade de Ben e Julie transforma suas visões sobre ser bem sucedido.',
            },
            {
              img: MovieLostMarsThumb,
              title: 'Perdido em Marte',
              description:
                'Um astronauta é enviado a uma missão em Marte. Após uma severa tempestade, ele é dado como morto, abandonado pelos colegas e acorda sozinho no misterioso planeta com escassos suprimentos. Sem saber como reencontrar os companheiros ou retornar à Terra decide usar todas as combinações de recursos possíveis para alcançar seu único objetivo de sucesso: sobreviver.',
            },
            {
              img: MovieLalalandThumb,
              title: 'La La Land',
              description:
                'O musical traz a história do pianista Sebastian que ganha a vida tocando em festas e bares e que se apaixona perdidamente pela atriz iniciante Mia. Em busca de oportunidades numa cidade competitiva, as carreiras dos jovens finalmente começam a ascender e os pressionam a tomar decisões que podem ameaçar seu relacionamento. Um filme leve e inspirador sobre como escolher e viver o sucesso!',
            },
          ],
        },
      },
    },
  ],
};

export default data;
