// ##############################
// // // Footer styles
// #############################

import {
  defaultFont,
  container,
  containerFluid,
  primaryColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const footerStyle = (theme) => ({
  block: {},
  leftContent: {
    float: 'left',
    display: 'block',
    padding: '15px 15px 0',
    [theme.breakpoints.down('md')]: {
      float: 'right !important',
    },
  },
  rightContent: {
    float: 'right',
    display: 'inline-block',
    padding: '15px 15px 0',
    position: 'relative',
    top: '1rem',
    marginRight: '80px',
  },
  helpFooter: {
    display: 'inline-block',
    width: 'auto',
    textAlign: 'center',
    position: 'relative',
    marginLeft: '28%',
  },
  footer: {
    bottom: '0',
    borderTop: '1px solid #e7e7e7',
    padding: '15px 0',
    ...defaultFont,
    zIndex: 4,
  },
  container: {
    zIndex: 3,
    ...container,
    position: 'relative',
  },
  containerFluid: {
    zIndex: 3,
    ...containerFluid,
    position: 'relative',
  },
  a: {
    color: '#3C4858',
    fontWeight: '450',
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0',
    width: 'auto',
  },
  whiteColor: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },
  blackColor: {
    '&,&:hover,&:focus': {
      color: '#333 !important',
    },
  },
});
export default footerStyle;
