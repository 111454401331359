import {
  defaultsSubdomain,
  forbidenSubdomains,
  localDomains,
} from 'constants/subdomains';

class SubdomainService {
  hostname;
  subdomain;
  redirect;

  constructor() {
    this.hostname = this.defineDomain();
    this.subdomain = this.defineSubdomain();
    this.redirect = this.defineSubdomainRedirect();
  }

  defineSubdomainRedirect() {
    return (
      this.hostname !== 'localhost' &&
      this.subdomain !== '' &&
      !forbidenSubdomains.includes(this.subdomain)
    );
  }
  defineDomain() {
    const { hostname } = window.location;
    return hostname;
  }

  defineSubdomain() {
    return this.hostname.split('.')[0] !== 'worka' &&
      !forbidenSubdomains.includes(this.subdomain)
      ? this.hostname.split('.')[0]
      : '';
  }

  getContextLoginRoutes() {
    if (
      !this.redirect ||
      forbidenSubdomains.includes(this.subdomain) ||
      this.subdomain.includes(defaultsSubdomain.workability) ||
      this.subdomain === '' ||
      this.isLocalHost()
    ) {
      return 'default';
    } else {
      return this.getContext(this.subdomain);
    }
  }

  verifyStudentIes(data) {
    const { metadata } = data;
    const iesName = this.getIesName();
    if (iesName.includes(defaultsSubdomain.workability)) {
      return true;
    }
    return metadata.university_slug === iesName;
  }

  redirectStudentSubdomain(university_slug) {
    const url = (sub) => {
      if (this.hostname.includes('worka.local')) {
        return `http://${sub}.worka.local`;
      }

      return `https://${sub}.worka.love`;
    };
    if (this.getIesName() !== university_slug) {
      window.open(url(university_slug), '_self');
    }
  }

  redirectSubdomain(subdomain) {
    const url = (sub) => {
      if (this.hostname.includes('worka.local')) {
        return `http://${sub}.worka.local`;
      }

      return `https://${sub}.worka.love`;
    };
    window.open(url(subdomain), '_self');
  }

  isLocalHost = () => localDomains.includes(this.subdomain);

  getContext(context) {
    const contexts = {
      empresas: 'company',
      ies: 'university',
      student: 'student',
    };
    return contexts[context] ?? contexts['student'];
  }

  getIesName() {
    const subdomain = this.subdomain;
    if (this.subdomain.includes('-dev')) {
      return subdomain.replace('-dev', '');
    } else if (this.subdomain.includes('-hmg')) {
      return subdomain.replace('-hmg', '');
    } else if ('-stg') {
      return subdomain.replace('-stg');
    } else {
      return subdomain;
    }
  }

  getSubdomain() {
    return this.subdomain;
  }

  getRedirect() {
    return this.redirect;
  }
}

const subdomainService = new SubdomainService();
export default subdomainService;
