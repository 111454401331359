import React, { Fragment, useEffect, useState } from 'react';
import { func, shape } from 'prop-types';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import Chart from 'react-google-charts';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { getProfessionalStatusReport } from '../../../services/student/studentsList';
import ProfessionalStatusReportFormFilters from './ProfessionalStatusReportFormFilters';
import ProfessionalStatusReportPDFGenerationButton from './ProfessionalStatusReportPDFGenerationButton';
import { pink } from '@material-ui/core/colors';

const ProfessionalStatusReport = ({ classes, handleIsLoadingState }) => {
  const [professionalReportData, setProfessionalReportData] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);

  const loadChartData = async (filters = {}) => {
    setProfessionalReportData({});
    const data = await getProfessionalStatusReport(filters);
    setProfessionalReportData(data);
  };

  useEffect(() => {
    handleIsLoadingState(false);
    loadChartData();
  }, []);

  const TitleCard = styled.div`
    margin-top: 0.9rem;
    text-align: center;
    text-transform: none;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4em;
    color: #404040;
  `;

  const LegendTypeWork = styled.div`
    font-size: 1rem;
    font-weight: 400;
    color: #404040;
    text-align: justify;
  `;

  function convertNumber(data) {
    data = data.toLocaleString('pt-BR', {
      minimumFractionDigits: 0,
      MaximumFractionDigits: 2,
    });
    return data;
  }

  const summaryData = professionalReportData.summary;
  const workingInArea = professionalReportData.working_in_area;
  const careerGoals = professionalReportData.career_objectives;
  const generalSummaryReport = summaryData
    ? [
        ['Situação', 'Número de alunos'],
        [
          `${convertNumber(summaryData.employed.total)} - Trabalhando`,
          summaryData.employed.total,
        ],
        [
          `${convertNumber(
            summaryData.unemployed.total
          )} - Não estão trabalhando`,
          summaryData.unemployed.total,
        ],
      ]
    : [
        ['Situação', 'Número de alunos'],
        ['Trabalhando', 0],
        ['Não estão trabalhando', 0],
      ];

  const areaSummaryreport = workingInArea
    ? [
        ['Situação', 'Número de alunos'],
        [
          `${convertNumber(
            workingInArea.working_in_formation_area
          )} - Trabalhando na área de formação`,
          workingInArea.working_in_formation_area,
        ],
        [
          `${convertNumber(
            workingInArea.not_working_in_formation_area
          )} - Trabalhando em outras áreas`,
          workingInArea.not_working_in_formation_area,
        ],
      ]
    : [
        ['Situação', 'Número de alunos'],
        ['Trabalhando na área de formação', 0],
        ['Trabalhando em outras áreas', 0],
      ];

  const summaryReport = summaryData
    ? [
        [
          'Situação',
          `${convertNumber(
            summaryData.employed.available + summaryData.unemployed.available
          )} - Disponível`,
          `${convertNumber(
            summaryData.employed.closed + summaryData.unemployed.closed
          )} - Não disponível`,
        ],
        [
          'Trabalhando',
          summaryData.employed.available,
          summaryData.employed.closed,
        ],
        [
          'Não estão trabalhando',
          summaryData.unemployed.available,
          summaryData.unemployed.closed,
        ],
      ]
    : [
        ['Situação', 'Disponível', 'Não disponível'],
        ['Trabalhando', 0, 0],
        ['Não estão trabalhando', 0, 0],
      ];

  const studentSituations = summaryData
    ? [
        ['Situação', 'Número de alunos'],
        [
          `${convertNumber(careerGoals.internship_trainee)} - Busco um estágio`,
          careerGoals.internship_trainee,
        ],
        [
          `${convertNumber(
            careerGoals.opportunity_as_trainee
          )} - Busco uma oportunidade como trainee`,
          careerGoals.opportunity_as_trainee,
        ],
        [
          `${convertNumber(
            careerGoals.first_job
          )} - Busco meu primeiro trabalho`,
          careerGoals.first_job,
        ],
        [
          `${convertNumber(
            careerGoals.career_transition
          )} - Quero fazer uma mudança de carreira`,
          careerGoals.career_transition,
        ],
        [
          `${convertNumber(careerGoals.own_business)} - Quero empreender`,
          careerGoals.own_business,
        ],
        [
          `${convertNumber(
            careerGoals.unemployed_looking_for_a_job
          )} - Estou desempregado(a) e busco uma oportunidade no mercado`,
          careerGoals.unemployed_looking_for_a_job,
        ],
        [
          `${convertNumber(
            careerGoals.no_career_changes
          )} - Estou satisfeito com minha situação profissional`,
          careerGoals.no_career_changes,
        ],
      ]
    : [
        ['Situação', 'Número de alunos'],
        ['Busco um estágio ou trainee', 0],
        ['Busco meu primeiro trabalho', 0],
        ['Quero fazer uma mudança de carreira', 0],
        ['Quero empreender', 0],
        ['Estou desempregado(a) e busco uma oportunidade no mercado', 0],
        ['Estou satisfeito com minha situação profissional', 0],
      ];

  const atLeastOneAnsweredArea =
    !workingInArea ||
    workingInArea.working_in_formation_area ||
    workingInArea.not_working_in_formation_area;

  const allChartsData = [
    {
      title: 'Situação profissional dos estudantes',
      type: 'studentProfessionalStatus',
      loading: !summaryData,
      emptyChart:
        summaryData &&
        !summaryData.employed.total &&
        !summaryData.unemployed.total &&
        !summaryData.unknown.total,
      chartData: {
        width: '100%',
        height: '60%',
        chartType: 'PieChart',
        data: generalSummaryReport,
        options: {
          chartArea: {
            width: '90%',
            height: '90%',
          },
          title: '',
          colors: ['#54A17F', '#DF6039', '#54BBF9', '#E6973E'],
          legend: { textStyle: { color: '#696969' } },
          is3D: true,
        },
      },
    },
    {
      title: 'Área de atuação',
      subtitle: 'Estudantes que estão trabalhando',
      type: 'studentsWorking',
      invisible: !atLeastOneAnsweredArea,
      loading: !workingInArea,
      emptyChart:
        workingInArea &&
        !workingInArea.working_in_formation_area &&
        !workingInArea.not_working_in_formation_area,
      chartData: {
        width: '100%',
        height: '60%',
        chartType: 'PieChart',
        data: areaSummaryreport,
        options: {
          chartArea: {
            width: '90%',
            height: '90%',
          },
          title: '',
          colors: ['#54A17F', '#DF6039'],
          legend: { textStyle: { color: '#696969' } },
          is3D: true,
        },
      },
    },

    {
      title: 'Objetivos de carreira',
      type: 'careerGoals',
      loading: !careerGoals,
      emptyChart:
        careerGoals &&
        !careerGoals.career_transition &&
        !careerGoals.first_job &&
        !careerGoals.internship_trainee &&
        !careerGoals.no_career_changes &&
        !careerGoals.own_business &&
        !careerGoals.unemployed_looking_for_a_job &&
        !careerGoals.opportunity_as_trainee,
      chartData: {
        width: '100%',
        height: '60%',
        wordBreak: 'break-all',
        chartType: 'PieChart',
        data: studentSituations,
        options: {
          chartArea: {
            width: '100%',
            height: '90%',
          },
          title: '',
          colors: [
            '#32cd32',
            '#54A17F',
            '#54BBF9',
            '#E6973E',
            '#fbbc04',
            '#009291',
            '#176b8c',
          ],
          is3D: true,
          legend: {
            textStyle: { color: '#696969', wordBreak: 'break-all' },
          },
        },
      },
    },
    {
      title: 'Disponibilidade para o mercado de trabalho',
      type: 'availabilityToWork',
      loading: !summaryData,
      emptyChart:
        summaryData &&
        !summaryData.employed.available &&
        !summaryData.employed.closed &&
        !summaryData.unemployed.available &&
        !summaryData.unemployed.closed,
      chartData: {
        width: '100%',
        height: '300px',
        chartType: 'BarChart',
        data: summaryReport,
        options: {
          chart: {
            title: 'Número',
          },
          chartArea: { width: '50%' },
          isStacked: true,
          colors: ['#54A17F', '#DF6039'],
          legend: { textStyle: { color: '#696969' } },
          hAxis: {
            title: 'Número de alunos',
            minValue: 0,
            textStyle: { color: '#696969' },
          },
          vAxis: { textStyle: { color: '#696969' } },
        },
      },
    },
  ];

  const chartsData = allChartsData.filter((item) => !item.invisible);
  return (
    <Fragment>
      <GridContainer
        className={classes.fullScreenGridContainer}
        style={{ marginTop: 20, padding: 0 }}
      >
        <GridItem style={{ position: 'relative' }} xs md={12} lg={8} xl={9}>
          <ProfessionalStatusReportPDFGenerationButton
            chartsData={chartsData}
            activeFilters={activeFilters}
            summaryData={summaryData}
          >
            Gerar relatório
          </ProfessionalStatusReportPDFGenerationButton>
          {chartsData.map((item) => (
            <GridItem sm={12}>
              <Card>
                <CardHeader>
                  <h4>{item.title}</h4>
                </CardHeader>
                {item.type == 'studentProfessionalStatus' && summaryData && (
                  <TitleCard>
                    {`Total de Estudantes que acessaram o sistema: ${convertNumber(
                      summaryData.employed.total + summaryData.unemployed.total
                    )}`}
                  </TitleCard>
                )}
                <CardBody>
                  {item.loading || item.emptyChart ? (
                    <Typography
                      align="center"
                      style={{
                        marginBottom: 28,
                        color: '#bbb',
                        fontSize: 19,
                      }}
                    >
                      {item.loading
                        ? 'Carregando...'
                        : 'Sem dados para o gráfico'}
                    </Typography>
                  ) : (
                    <Chart {...item.chartData} />
                  )}

                  {item.type == 'studentProfessionalStatus' && summaryData && (
                    <LegendTypeWork>
                      <p>Tipos de trabalho:</p>

                      <p>
                        {summaryData.employed.job_type_count.consultant
                          ? convertNumber(
                              summaryData.employed.job_type_count.consultant
                            )
                          : 0}{' '}
                        - Trabalhando como consultor |{' '}
                        {summaryData.employed.job_type_count.entrepreneur
                          ? convertNumber(
                              summaryData.employed.job_type_count.entrepreneur
                            )
                          : 0}{' '}
                        - Trabalhando como empresário |{' '}
                        {summaryData.employed.job_type_count.full_time_job
                          ? convertNumber(
                              summaryData.employed.job_type_count.full_time_job
                            )
                          : 0}{' '}
                        - Trabalhando em tempo integral |{' '}
                        {summaryData.employed.job_type_count.part_time_job
                          ? convertNumber(
                              summaryData.employed.job_type_count.part_time_job
                            )
                          : 0}{' '}
                        - Trabalhando em meio período |{' '}
                        {summaryData.employed.job_type_count.intern
                          ? convertNumber(
                              summaryData.employed.job_type_count.intern
                            )
                          : 0}{' '}
                        - Estagiando |{' '}
                        {summaryData.employed.job_type_count.trainee
                          ? convertNumber(
                              summaryData.employed.job_type_count.trainee
                            )
                          : 0}{' '}
                        - Trainee
                      </p>
                    </LegendTypeWork>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          ))}
        </GridItem>

        <GridItem xs md={12} lg={4} xl={3}>
          <ProfessionalStatusReportFormFilters
            handleSubmitFilters={loadChartData}
            saveActiveFilters={setActiveFilters}
            isSocioeconomic={false}
          />
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

ProfessionalStatusReport.propTypes = {
  classes: shape.isRequired,
  handleIsLoadingState: func.isRequired,
};

export default withStyles(styles)(ProfessionalStatusReport);
