import React, { useContext, useRef } from 'react';
import {
  WrapperModal,
  DivContainer,
  StyledHeader,
  ContainerForm,
  WrapperButton,
} from './style';
import { Form } from '@unform/web';
import { JobOfferContext } from '../../context/jobOfferContext';
import { useOutSideClick } from '../../../../JobOfferRebuild/hooks/useOutsideClick';
import { radioOptions } from './useCases/radioOptions';
import Radio from '../form/radio';
import { NextButton } from '../../../Opportunities/Styles/formStyled';

export default function JobOfferPublicationTypeModal({
  openModal,
  setOpenModal,
  isInternalCompany,
  setOpenPublishJobModal,
  openModalPublishJob,
}) {
  const formRef = useRef(null);

  const DivContainerRef = useRef(null);
  const { setExternalJobOffer, setFormValues } = useContext(JobOfferContext);
  const [clickedOutside, SetClickedOutside] = useOutSideClick(DivContainerRef);

  if (clickedOutside) {
    setOpenModal(false);
    SetClickedOutside(false);
  }

  const handleSubmit = (data) => {
    if (data.typeSubmit !== '') {
      if (data.typeSubmit === 'external') {
        setExternalJobOffer(true);
      } else {
        setExternalJobOffer(false);
      }
      formRef.current.clearField('typeSubmit');
      formRef.current.reset();
      setOpenModal(false);
      setFormValues({
        setOpenPublishJobModal,
        openModalPublishJob,
      });
      return setOpenPublishJobModal(true);
    }
  };

  return (
    <WrapperModal isOpen={openModal}>
      <DivContainer ref={DivContainerRef}>
        <StyledHeader>Tipo de Divulgação</StyledHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ContainerForm>
            <Radio name="typeSubmit" options={radioOptions} />
            <WrapperButton>
              <NextButton type="submit" />
            </WrapperButton>
          </ContainerForm>
        </Form>
      </DivContainer>
    </WrapperModal>
  );
}
