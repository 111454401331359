import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > h3 {
    color: #606062;
    margin-bottom: 40px;
  }

  > span {
    margin: 40px 0px;
    font-size: 17px;
    color: #0f7776;
    cursor: pointer;
    font-weight: 600;
  }
`;

export const Card = styled.div`
  width: 16em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #606062;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  background-color: #fff;
  cursor: pointer;

  > h4 {
    font-weight: bold;
    color: #606062;
  }
`;

export const WrapperCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
`;
