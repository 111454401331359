import { api, authenticatedAPI } from 'services/api';
import qs from 'querystring';

const getProfessions = () => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/professions`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterProfessions = (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/professions?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionDetail = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/professions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveProfession = (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/backoffice/professions`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const editProfession = (data, id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/backoffice/professions/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteProfession = (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/backoffice/professions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionsOptions = (params, getAll) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/backoffice/professions?${
        !!getAll ? `all=${getAll}` : ''
      }${qs.stringify(params)}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const existProfession = async (professionName) => {
  const param = new URLSearchParams({ name: professionName }).toString();

  const response = api
    .get(`/api/internal/companies-universities/professions/exists?${param}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getProfessions,
  getProfessionDetail,
  deleteProfession,
  getProfessionsOptions,
  editProfession,
  saveProfession,
  getFilterProfessions,
  existProfession,
};
