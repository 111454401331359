import React, { Fragment, useState, useRef } from 'react';
import SkeletonCardProjectExtension from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/SkeletonExtensionProjects';

import SubscribeProjectCard from '../Card';
import { MoreInformationModalStudent } from '../../../Modal/MoreInformationModalStudent';

export default function PublishedCarousel({
  array,
  ref,
  enrolledProjects,
  notSelectedProjects,
  awaitEvidences,
  finishedProjects,
  images,
  extensionProjectId,
  setExtensionProjectId,
  currentList,
}) {
  const [details, setDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [modalMoreInformation, setModalMoreInformation] = useState(false);
  const [modalDetailsItem, setModalDetailsItem] = useState({});

  const refSubscribeProjects = useRef();

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const mapProject = (array) => {
    if (array === null) {
      return (
        <SkeletonCardProjectExtension quantity={randomIntFromInterval(3, 4)} />
      );
    }
    return (
      array &&
      array.map((item) => (
        <SubscribeProjectCard
          ref={ref}
          currentList={currentList}
          item={item}
          images={images}
          setDetails={setDetails}
          setIsOpen={setIsOpen}
          awaitEvidences={awaitEvidences}
          finishedProjects={finishedProjects}
          notSelectedProjects={notSelectedProjects}
          enrolledProjects={enrolledProjects}
          setModalMoreInformation={setModalMoreInformation}
          modalMoreInformation={modalMoreInformation}
          extensionProjectId={extensionProjectId}
          setExtensionProjectId={setExtensionProjectId}
          modalDetailsItem={modalDetailsItem}
          setModalDetailsItem={setModalDetailsItem}
        />
      ))
    );
  };

  return (
    <Fragment>
      {mapProject(array)}
      {modalMoreInformation && (
        <MoreInformationModalStudent
          isOpen={modalMoreInformation}
          setModalDetailsOpen={setModalMoreInformation}
          setModalDetailsItem={setModalDetailsItem}
          informations={modalDetailsItem}
          refCloseModal={refSubscribeProjects}
        />
      )}
    </Fragment>
  );
}
