import React, { useRef, useEffect, useState, useContext } from 'react';
import { IconCloseModal } from 'assets/img/iconsCurriculum/iconCloseModal';
import { CloseButton, Container, Header, Overlay } from './styled';
import BaseLayoutContext from 'contexts/base-layout';

const GenericModal = (props) => {
  const { isOpen, onClose, children, alpha } = props;
  const modalRef = useRef();
  const [centerModal, setCenterModal] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);

  const positionModal = () => {
    const modalHeight = modalRef.current.offsetHeight;
    const windowHeight = window.innerHeight;
    setCenterModal(modalHeight < windowHeight * 0.8);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, []);

  useEffect(() => {
    const positionModal = () => {
      const modalHeight = modalRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      setCenterModal(modalHeight < windowHeight);
    };
    window.addEventListener('resize', positionModal);
    return () => window.removeEventListener('resize', positionModal);
  }, [modalRef.current]);

  useEffect(() => {
    if (isOpen) {
      positionModal();
    }
  }, [isOpen]);

  return (
    <Overlay isOpen={isOpen} centerModal={centerModal} alpha={alpha}>
      <Container ref={modalRef}>
        <Header backgroundColor={universityColor}>
          <span>{props.title}</span>
          <CloseButton onClick={onClose}>
            <IconCloseModal color={'#4B4B4D'} />
          </CloseButton>
        </Header>
        {children}
      </Container>
    </Overlay>
  );
};

export default GenericModal;
