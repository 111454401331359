export function Grip({ color }) {
  return (
    <svg
      width="29"
      height="21"
      viewBox="0 0 29 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.19118 4.84891H24.8971C25.1467 4.84891 25.3861 4.74975 25.5626 4.57324C25.7391 4.39674 25.8382 4.15734 25.8382 3.90773C25.8382 3.65811 25.7391 3.41872 25.5626 3.24222C25.3861 3.06571 25.1467 2.96655 24.8971 2.96655H4.19118C3.94156 2.96655 3.70217 3.06571 3.52566 3.24222C3.34916 3.41872 3.25 3.65811 3.25 3.90773C3.25 4.15734 3.34916 4.39674 3.52566 4.57324C3.70217 4.74975 3.94156 4.84891 4.19118 4.84891Z"
        fill={color}
      />
      <path
        d="M24.8971 7.67236H4.19118C3.94156 7.67236 3.70217 7.77152 3.52566 7.94803C3.34916 8.12453 3.25 8.36392 3.25 8.61354C3.25 8.86316 3.34916 9.10255 3.52566 9.27905C3.70217 9.45556 3.94156 9.55472 4.19118 9.55472H24.8971C25.1467 9.55472 25.3861 9.45556 25.5626 9.27905C25.7391 9.10255 25.8382 8.86316 25.8382 8.61354C25.8382 8.36392 25.7391 8.12453 25.5626 7.94803C25.3861 7.77152 25.1467 7.67236 24.8971 7.67236Z"
        fill={color}
      />
      <path
        d="M24.8971 17.0842H4.19118C3.94156 17.0842 3.70217 17.1834 3.52566 17.3599C3.34916 17.5364 3.25 17.7758 3.25 18.0254C3.25 18.275 3.34916 18.5144 3.52566 18.6909C3.70217 18.8674 3.94156 18.9666 4.19118 18.9666H24.8971C25.1467 18.9666 25.3861 18.8674 25.5626 18.6909C25.7391 18.5144 25.8382 18.275 25.8382 18.0254C25.8382 17.7758 25.7391 17.5364 25.5626 17.3599C25.3861 17.1834 25.1467 17.0842 24.8971 17.0842Z"
        fill={color}
      />
      <path
        d="M24.8971 12.3784H4.19118C3.94156 12.3784 3.70217 12.4776 3.52566 12.6541C3.34916 12.8306 3.25 13.07 3.25 13.3196C3.25 13.5692 3.34916 13.8086 3.52566 13.9851C3.70217 14.1616 3.94156 14.2608 4.19118 14.2608H24.8971C25.1467 14.2608 25.3861 14.1616 25.5626 13.9851C25.7391 13.8086 25.8382 13.5692 25.8382 13.3196C25.8382 13.07 25.7391 12.8306 25.5626 12.6541C25.3861 12.4776 25.1467 12.3784 24.8971 12.3784Z"
        fill={color}
      />
    </svg>
  );
}
