import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  z-index: 5;
  width: 238px;
  height: 40px;

  position: absolute;

  bottom: 6.5em;
  right: 2em;
  border-radius: 4.07px;
  color: #009291;
  border: none;
  box-shadow: 0px 0px 4px #009291;
  background: #ffffff;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;

  @media screen and (max-width: 959px) {
    height: 33px;
    top: 56px;
    margin-top: 7em;
  }
`;
