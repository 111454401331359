import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubTabSelectContainer = styled.div`
  display: flex;
  height: 48px;
  border-radius: 10px;
  margin-top: 2rem;
`;

const SubTabItem = styled.div`
  display: flex;
  width: 118px;
  height: 100%;
  border: 1px solid ${(props) => (props.selected ? '#009291' : '#E5E5E5')};
  background-color: ${(props) => (props.selected ? '#00929133' : '#FFF')};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  padding: 0 0.5rem;
  color: ${(props) => (props.selected ? '#009291' : '#606062')};
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: ${(props) => (props.selected ? 'none' : 'brightness(0.9)')};
  }
`;

export const SchedulingSubTab = styled(SubTabItem)`
  border-radius: 10px 0 0 10px;
`;

export const AbsencesSubTab = styled(SubTabItem)`
  border-radius: 0 10px 10px 0;
`;
