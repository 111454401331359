import styled from 'styled-components';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate/index.jsx';
import { Container as CmContainer } from 'components/CurriculumModalGenerate/styled';
import Button from 'components/CustomButtons/Button.jsx';

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px 0 56px;
`;

export const ProfileImage = styled.img`
  width: 76px;
  height: 75px;
  background-image: url(${(props) => props.image});
  border-radius: 40px;
  background-position: center;
  background-size: cover;
`;

export const Infos = styled.div`
  margin-left: 24px;
  flex: 1;
  h3.name {
    font-size: 1rem;
    color: #009291;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 8px;
  }

  .withCollapse {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 8px;
    button {
      border-radius: 100%;
      width: 24px;
      height: 24px;
      margin-left: 8px;
      padding: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      cursor: pointer;
      color: #606062;
      background-color: #efeeec;
    }
    h3.name {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
  }

  p {
    font-size: 0.75rem;
    line-height: 14px;
    color: #4b4b4d;
    margin-bottom: 8px;
    font-weight: 400;
  }
  .row {
    display: flex;
  }
  .removeRl {
    margin-left: -3px;
  }
  .rowIndication {
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-break: anywhere;
      max-width: 400px;
    }
    button {
      padding: 6px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-transform: none;
      margin: 0px;
      margin-left: 1rem;
    }
  }
  .withIcon {
    display: flex;
    margin-right: 1rem;
    margin-top: 4px;
    svg {
      color: #009291;
      margin-right: 4px;
      font-size: 15px;
    }
  }
  a.hasItem {
    margin-top: 12px;
    display: flex;
    color: #009291;
    font-weight: 500;
    align-items: baseline;
    svg {
      font-size: 20px;
      padding: 0px;
      transform: translateY(2px);
    }
    span {
      margin-top: 8px;
    }
    cursor: pointer;
  }

  a.hasItem + a.hasItem {
    margin-left: 22px;
  }

  a.notHasItem {
    color: #da0505 !important;
    cursor: not-allowed;
  }

  .mb-16 {
    margin-bottom: 16px;
  }
`;

export const ButtonContainer = styled.div`
  float: right;
  .disabledButton {
    background-color: #e5e5e5 !important;
    cursor: not-allowed;
    color: #606062;
  }
`;

export const CurriculumModal = styled(CurriculumModalGenerate)`
  &${CmContainer} {
    ${(props) => !props.hasIndication && 'left: 0px'}
  }
`;

export const Tag = styled.div`
  background: #fff8ed;
  border-radius: 3x;
  font-size: 12px;
  line-height: 12px;
  color: #da5600;
  font-weight: 400;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 6px;
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 40px 0px;
  background: #e6e6e6;
  height: 1px;
`;
