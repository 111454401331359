import {  authenticatedAPI } from 'services/api';

const studentData = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/student-history/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const listCampaigns = async (id, queryParams) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/student-history/${id}/campaigns?${queryParams}`)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const filterCampaignsOptions = async (id) => {
  const response = authenticatedAPI
    .get(`api/internal/university/student-history/${id}/campaigns/filter`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
}

const campaignDetails = async (email, id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/student-history/email/${email}/campaigns/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
}

export {
  studentData,
  listCampaigns,
  filterCampaignsOptions,
  campaignDetails,
}
