import React, { Fragment } from 'react';
import { func, string, arrayOf, shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Paper, Hidden } from '@material-ui/core';

import GridItem from 'components/Grid/GridItem';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import mergeContextsHelper from 'utils/mergeContextsHelper';

const ContentItems = (props) => {
  const {
    classes,
    openModalWithContent,
    welcomeMessage,
    data,
    getCertificateData,
  } = props;

  const propsCopy = { ...props };
  delete propsCopy.classes;
  return (
    <Fragment>
      {welcomeMessage && (
        <Hidden smDown>
          <Grid item xs md={12}>
            <Paper className={classes.welcomeMessage} elevation={0}>
              <h4 className={classes.description}>{welcomeMessage}</h4>
            </Paper>
          </Grid>
        </Hidden>
      )}

      {/* Items */}
      {data.map((video) => {
        return (
          <GridItem
            key={video.id}
            xs={12}
            sm={4}
            className={classes.gridItemVideo}
          >
            <figure className={classes.videoThumbHolder}>
              <button
                type="button"
                className={classes.videoTrigger}
                onClick={() =>
                  openModalWithContent(
                    mergeContextsHelper(
                      <video.modalContent
                        {...props}
                        videoId={video.id}
                        Form={video.form}
                      />
                    ),
                    video.title,
                    null,
                    getCertificateData
                  )
                }
              >
                <img
                  src={`http://i3.ytimg.com/vi/${video.id}/hqdefault.jpg`}
                  alt={video.title}
                  className={classes.imageFluid}
                />
              </button>
              <figcaption>{video.description || video.title}</figcaption>
            </figure>
          </GridItem>
        );
      })}
    </Fragment>
  );
};

ContentItems.defaultProps = {
  getCertificateData: undefined,
  welcomeMessage: '',
};

ContentItems.propTypes = {
  getCertificateData: func,
  classes: shape({}).isRequired,
  openModalWithContent: func.isRequired,
  data: arrayOf({}).isRequired,
  welcomeMessage: string,
};

export default withStyles(dashboardStyle)(ContentItems);
