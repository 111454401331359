import React, { useEffect, useRef, Fragment, useReducer } from 'react';
import { useField } from '@unform/core';
import {
  Container,
  InputRadio,
  LabelInput,
  SubTitle,
  TitleLabel,
} from './styled';

const Checkbox = ({ name, options, margin, universityColor, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        if (refs.length === 1) {
          return refs[0].checked;
        }
        return (
          (refs.find((ref) => ref.checked) &&
            refs.find((ref) => ref.checked).value) ||
          ''
        );
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);
        if (item) {
          item.checked = true;
        }
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Fragment>
      {options.map((option, index) => (
        <Container>
          <LabelInput htmlFor={option.id} key={option.id}>
            <InputRadio
              id={option.id}
              ref={(ref) => ref && (inputRefs.current[index] = ref)}
              type={option.type}
              name={name}
              defaultChecked={option.value === defaultValue}
              value={option.value}
              resetMargin={margin}
              universityColor={universityColor}
              {...rest}
            />

            <TitleLabel>{option.label}</TitleLabel>
          </LabelInput>
          {option.description && <SubTitle>{option.description}</SubTitle>}
        </Container>
      ))}
    </Fragment>
  );
};

export default Checkbox;
