import React, { useEffect, useRef, useState } from 'react';
import {
  Dropdown,
  DropdownHeader,
  DropdownHeaderIcon,
  List,
  ListItem,
} from './styles';

export default function Select({
  selected,
  setValue,
  options,
  backgroundColor,
  isYear = false,
  ...rest
}) {
  const ref = useRef(null);
  const currentElementRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      } else {
        toggle();
      }
    };
    document.addEventListener('click', handleClickOutside);
    if (open) {
      currentElementRef.current.scrollIntoView();
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  const toggle = () => setOpen(!open);

  return (
    <Dropdown ref={ref} open={open} {...rest}>
      <DropdownHeader backgroundColor={backgroundColor}>
        <p>
          {options.find((option) => option.value === selected)
            ? options.find((option) => option.value === selected).label
            : options.find((option) => option.value === selected)}
        </p>
        <DropdownHeaderIcon open={open} />
      </DropdownHeader>
      {open && (
        <List>
          {options.map((option) => (
            <ListItem
              key={option.value}
              onClick={() => {
                if (isYear) {
                  setValue(option.value);
                } else {
                  setValue(options.indexOf(option));
                }
              }}
              selected={selected === option.value}
              type="button"
              ref={option.value === selected ? currentElementRef : null}
            >
              <span>{option.label}</span>
            </ListItem>
          ))}
        </List>
      )}
    </Dropdown>
  );
}
