import styled from 'styled-components';
import search from 'assets/Matriz/search.svg';

export const ContainerList = styled.div`
  width: 100%;
  border-bottom: 1px solid #eef2f4;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 10px 30px;
`;

export const ContainerListHeader = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0px 30px;

  > span {
    font-weight: bold;
    color: #b8b8b8;
    font-size: 12px;
  }
  height: 30px;
  display: flex;
  align-items: center;
`;

export const ContainerSearch = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0px 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const SpanName = styled.span`
  width: 100%;
`;

export const SpanPopUp = styled.span`
  position: relative;
  cursor: pointer;

  > img {
    width: 20px;
  }

  &:hover {
    > div {
      display: block;
    }
  }

  > div {
    background-color: #009291;
    color: #fff;
    padding: 5px 10px;
    position: absolute;
    top: -50px;
    left: ${(props) => props.left};
    border-radius: 4px;
    display: none;
    width: ${(props) => (props.isCampaign ? '135px' : 'auto')};
    margin-left: ${(props) => (props.isCampaign ? '-47px' : 'auto')};

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-color: #009291 transparent;
      border-width: 12px 8px 0;
      bottom: -10px;
      left: 85%;
      margin-left: -20px;
    }
  }
`;

export const Containers = styled.span`
  display: flex;
  gap: 10px;
  width: ${(props) => props.width || 'unset'};
`;

export const CampaignDate = styled.span`
  margin-left: -32px;
`;

export const SearchInput = styled.input`
  padding-left: 8px;
  background-image: url(${search});
  background-repeat: no-repeat;
  float: right;
  background-position: 231px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  width: 260px;
`;
