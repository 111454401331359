import { element, func, shape } from 'prop-types';
import React, { useEffect, useRef, useState, useContext } from 'react';
import pdf from 'assets/img/pdfFile.png';
import { ParagraphButton, UploadButton } from './styles/UploadPdfStyles';
import { getPDFname } from '../../utils/getPDFname';
import BaseLayoutContext from 'contexts/base-layout';

export function PDFUpload({
  children,
  setFieldValue,
  setFieldError,
  hasPDFChosen,
}) {
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isFileUpdated, setIsFileUpdated] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);
  const [pdfFile, setPdfFile] = useState('');
  const changeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/pdf') {
        setIsFilePicked(true);
        setFieldValue('pdfFile', file);
        setPdfFile(file.name);
        if (hasPDFChosen) {
          setIsFileUpdated(true);
        }
      } else {
        setFieldError(
          'pdfFile',
          'O formato do arquivo selecionado deve ser .pdf'
        );
      }
    }
  };
  const fileInput = useRef(null);

  function handleClick() {
    fileInput.current.click();
  }

  function handleOpenIsEdit() {
    if (hasPDFChosen && !isFileUpdated) {
      window.open(hasPDFChosen, '_blank');
    }
  }

  useEffect(() => {
    if (hasPDFChosen) {
      const name = getPDFname(hasPDFChosen);
      setIsFilePicked(true);
      setPdfFile(name);
    }
  }, [hasPDFChosen]);

  return (
    <div>
      <input
        id="pdfFile"
        type="file"
        name="pdfFile"
        onChange={changeHandler}
        ref={fileInput}
        style={{ display: 'none' }}
      />

      {isFilePicked ? (
        <div>
          <UploadButton 
            universityColor={universityColor}
            type="button" 
            onClick={() => handleClick()}>
            Alterar Certificado PDF
          </UploadButton>
          <ParagraphButton
            pointer={hasPDFChosen && !isFileUpdated}
            onClick={() => handleOpenIsEdit()}
            type="button"
          >
            <img src={pdf} alt="Pdf file" /> <span>{pdfFile}</span>
          </ParagraphButton>
        </div>
      ) : (
        <UploadButton
          universityColor={universityColor}
          type="button"
          onClick={() => handleClick()}
        >
          {children}
        </UploadButton>
      )}
    </div>
  );
}

PDFUpload.propTypes = {
  children: element.isRequired,
  setFieldValue: func.isRequired,
  setFieldError: func.isRequired,
  hasPDFChosen: shape({}).isRequired,
};
