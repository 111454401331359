import React, { useContext } from 'react';
import { shape, func, bool } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import BaseLayoutContext from 'contexts/base-layout';

const LiveSchema = Yup.object().shape({
  questionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
});

const LiveTenForm = (props) => {
  const { classes, answered, initialValues, applyLiveQuiz } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <GridContainer justify="center" className={classes.paddingReset}>
      <GridItem className={`${classes.paddingReset} ${classes.textAlgC}`}>
        <Formik
          enableReinitialize
          validationSchema={LiveSchema}
          validateOnChange={false}
          validade
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            await applyLiveQuiz(values);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <List className={classes.list}>
                {/* Question 1 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>1 -</strong> O quanto seu perfil é de Auxiliador?
                    <br />
                    <br />
                    Todos nós temos um pouquinho de cada um dos 6 grandes perfis
                    de carreira (convencedor, organizador, executor,
                    investigativo, social e artístico). Na verdade, somos uma
                    combinação de cada um deles.
                    <br />
                    Agora pense o quanto em termos de percentual você acredita
                    que seu perfil é predominantemente Auxiliador (5%, 30%, 70%,
                    90%, etc.)? Ou seja: se você fosse distribuir 100 pontos
                    entre os seis perfis, quantos pontos você destinaria para o
                    perfil Auxiliador?
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionOne && touched.questionOne,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionOne"
                    variant="filled"
                    id="questionOne"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionOne && touched.questionOne}
                    value={values.questionOne}
                    component={TextField}
                    disabled={isSubmitting || answered}
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionOne &&
                      `Sua resposta tem ${values.questionOne.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionOne && touched.questionOne ? (
                    <FormHelperText>{errors.questionOne}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 2 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>2 -</strong> Quem são as pessoas de Perfil
                    Auxiliador que você admira?
                    <br />
                    <br />
                    Estamos cercados de pessoas sensacionais, que são
                    verdadeiros exemplos e que fazem toda a diferença em nossa
                    vida, não é mesmo?
                    <br />
                    Tente identificar pelo menos 3 pessoas que você conheça ou
                    já tenha tido contato que possuem predominante o perfil
                    Auxiliador. O que elas tem em comum? Como elas agem?
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionTwo && touched.questionTwo,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionTwo"
                    variant="filled"
                    id="questionTwo"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionTwo && touched.questionTwo}
                    value={values.questionTwo}
                    component={TextField}
                    disabled={isSubmitting || answered}
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionTwo &&
                      `Sua resposta tem ${values.questionTwo.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionTwo && touched.questionTwo ? (
                    <FormHelperText>{errors.questionTwo}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 3 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>3 -</strong> Onde você quer chegar?
                    <br />
                    <br />
                    Já sabemos que a melhor forma de ter dinheiro e ser feliz é
                    dedicando nossa energia em nos tornarmos versões cada vez
                    melhores de nós mesmos. Ao trabalhar com o que se ama e faz
                    bem, conseguimos ser vistos e reconhecidos.
                    <br />
                    Também sabemos que o mundo está em plena transformação e é
                    fundamental mapear todas as tendências do futuro de nossa
                    área de atuação. Então, fica aqui o desafio de listar todos
                    os conhecimentos e requisitos de mercado que serão
                    determinantes para que você seja um líder do futuro (DICA:
                    reveja a live para descobrir!)
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionThree && touched.questionThree,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionThree"
                    variant="filled"
                    id="questionThree"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionThree && touched.questionThree}
                    value={values.questionThree}
                    component={TextField}
                    disabled={isSubmitting || answered}
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionThree &&
                      `Sua resposta tem ${values.questionThree.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionThree && touched.questionThree ? (
                    <FormHelperText>{errors.questionThree}</FormHelperText>
                  ) : null}
                </ListItem>
                {!answered && (
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={
                          isSubmitting || Object.keys(values).length !== 3
                        }
                        type="submit"
                        style={{
                          width: '250px',
                          borderRadius: '5px',
                          backgroundColor: universityColor,
                        }}
                        round
                      >
                        Enviar respostas
                      </Button>
                    </div>
                  </ListItem>
                )}
              </List>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

LiveTenForm.propTypes = {
  classes: shape.isRequired,
  answered: bool.isRequired,
  applyLiveQuiz: func.isRequired,
  initialValues: shape.isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(LiveTenForm);
