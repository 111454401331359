import Axios from 'axios';

let lastCancelSource = null;

export const DelaySearchHelper = {
  delay: (dispatch, ms) => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    if (lastCancelSource) {
      lastCancelSource.cancel('Operation canceled due to new request');
    }

    lastCancelSource = source;

    const handler = setTimeout(() => {
      dispatch({ cancelToken: source.token });
    }, ms);
  },
};
