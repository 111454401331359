import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';
import { mentorAdapter } from '../../MentorsTab/adapters/MentorAdapter';
import { adapterSpecificDates } from '../../MentorsTab/adapters/SpecificDatesAdapter';

const NextMentorshipContext = createContext();

export const ContextProvider = ({ children }) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [mentorships, setMentorships] = useState(null);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterState, setFilterState] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [rescheduleFormData, setRescheduleFormData] = useState(null);
  const formRefStepOne = useRef();
  const formRefStepTwo = useRef();

  const openDetailsModal = async (data) => {
    const mentorshipDetailsResponse = await mentorshipApi.getMentorshipDetails(
      data.id
    );
    setModalData(mentorshipDetailsResponse);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setModalData({});
  };

  const openRescheduleModal = async (data) => {
    const [
      mentorDetailsResponse,
      specificDatesResponse,
      absencesResponse,
      scheduledDays,
    ] = await Promise.all([
      mentorshipApi.getMentorDetails(data.mentor_id),
      mentorshipApi.getMentorSpecificDates(data.mentor_id),
      mentorshipApi.getMentorAbsences(data.mentor_id),
      mentorshipApi.getMentorMentorshipScheduled(data.mentor_id),
    ]);
    const newModalData = { ...mentorAdapter(mentorDetailsResponse), ...data };
    newModalData.specific_dates = adapterSpecificDates(specificDatesResponse);
    newModalData.absences = absencesResponse;
    newModalData.scheduled_days = scheduledDays;
    setModalData(newModalData);
    setRescheduleModalOpen(true);
    const newFormData = {
      mentor: data.mentor_id,
      mentorship_modality: data?.mentorship_modality,
    };
    setRescheduleFormData(newFormData);
  };

  const closeRescheduleModal = () => {
    setRescheduleModalOpen(false);
    setRescheduleFormData({});
  };

  const openCancelModal = (data) => {
    setModalData(data);
    setCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setCancelModalOpen(false);
  };

  useEffect(() => {
    if (!rescheduleModalOpen) {
      setCurrentStep(1);
      if (!detailsModalOpen) {
        setModalData({});
      }
    }
  }, [detailsModalOpen, rescheduleModalOpen]);

  const isLastStep = currentStep === 2;
  const isFirstStep = currentStep === 1;

  const nextFormStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const prevsFormStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  return (
    <NextMentorshipContext.Provider
      value={{
        detailsModalOpen,
        setDetailsModalOpen,
        rescheduleModalOpen,
        setRescheduleModalOpen,
        cancelModalOpen,
        setCancelModalOpen,
        modalData,
        setModalData,
        openDetailsModal,
        closeDetailsModal,
        openRescheduleModal,
        closeRescheduleModal,
        openCancelModal,
        closeCancelModal,
        mentorships,
        setMentorships,
        loading,
        setLoading,
        isLastStep,
        isFirstStep,
        currentStep,
        setCurrentStep,
        nextFormStep,
        prevsFormStep,
        formRefStepOne,
        formRefStepTwo,
        page,
        setPage,
        hasNextPage,
        setHasNextPage,
        appliedFilters,
        setAppliedFilters,
        filterState,
        setFilterState,
        rescheduleFormData,
        setRescheduleFormData,
      }}
    >
      {children}
    </NextMentorshipContext.Provider>
  );
};

export function useNextMentorshipContext() {
  return useContext(NextMentorshipContext);
}
