import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

let ps;

class SidebarWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
  }

  render() {
    const { className, headerLinks, links, suggestionLink } = this.props;
    return (
      <div className={className} ref={this.wrapperRef}>
        {links}
        {headerLinks}
        {suggestionLink}
      </div>
    );
  }
}

export default SidebarWrapper;
