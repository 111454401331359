import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const UnInput = styled.input`
  border: ${(props) =>
    props.error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: ${(props) => (props.error ? '#FFEDED' : '#FFFF')};
  &:focus {
    border: 2px solid #2784ff;
  }
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  position: relative;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-left: 2px;
`;

export const Count = styled.span`
  position: absolute;
  bottom: -25px;
  right: 0;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
