import styled from 'styled-components';
import GreenPlus from '../../../../assets/Oportutinites/GreenPlus.svg';
import white from '../../../../assets/img/icons/icoLocation.png';

export const WrapperPublished = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 4em 5em;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-weight: 500;
  color: #009291;
`;

export const WrapperDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: overlay;
`;

export const ListLearnings = styled.div`
  display: flex;
  gap: 21px;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  padding: 0 1.5em 0 1.5em;
  border-radius: 8px;
  display: flex;
  align-items: center;

  li {
    list-style-image: url(${white});
    width: 3px;
    height: 100%;
    background: #ffffff;
    margin-top: 200px;
  }
`;

export const CardTitle = styled.h4`
  font-weight: 400;
  color: #009291;
  font-size: 1.5rem;
`;
export const CardSubTitle = styled.p`
  color: #606062;
  font-size: 0.9rem;
`;

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
`;

export const Card = styled.div`
  width: 260px;
  height: ${(props) => (props.educationalGroup ? '350px' : '375px')};
  background-color: #ffff;
  border-radius: 16px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-right: 20px;
  padding-bottom: 4px;
`;

export const CardImg = styled.img`
  position: absolute;
  top: 0px;
  border-radius: ${(props) =>
    props.isModal ? '9px 9px 0 0' : '16px 16px 0 0;'};
  width: ${(props) => (props.isModal ? '100%' : '260px')};
  height: ${(props) => (props.isModal ? '207px' : '181px')};
  object-fit: cover;
`;

export const ActivityType = styled.div`
  width: auto;
  padding-right: 10px;
  height: 23px;
  background-color: #ffff;
  border-radius: 16px;
  position: absolute;
  z-index: 2;
  top: 14em;
  margin-left: 10px;
  font-size: 10px;
`;
export const StarActivityImgDiv = styled.div`
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 4px;
`;

export const StarActivityImg = styled.img`
  margin-top: 6px;
`;

export const StarActivityWrapper = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  text-align: center;
`;

export const StarActivityP = styled.p`
  color: #009291;
  font-weight: 400;
  margin-left: 8px;
  font-size: 12px;
  margin-top: 1px;
`;
export const TitleH4 = styled.h4`
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold;
  font-size: 14.5px;
  position: absolute;
  top: 13em;
  z-index: 2;
  left: 10px;
`;

export const Informations = styled.div`
  width: 100%;
  height: 20%;
  position: absolute;
  margin-top: 17em;
`;

export const TagIcons = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const IconImage = styled.div`
  width: 7%;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;
`;

export const ButtonLeft = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
  margin: 10px;
  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.8);
  }
`;
export const ButtonRight = styled.button`
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin: 10px;
  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    transform: scale(0.8);
  }
`;

export const MoreInfo = styled.div`
  margin-top: ${(props) => (props.educationalGroup ? '24px' : '11px')};
  margin-right: 20px;
  margin-left: 10px;
  gap: 10px;

  display: flex;
  align-items: center;

  button {
    color: #fff;
    background-color: #009291;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    border: none;
    border-radius: 3px;
    position: relative;
    width: 100%;
    height: 35px;
    padding: 10px;
  }
`;

export const DashedBorder = styled.div`
  width: 580px;
  height: 150px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PlusButton = styled.button`
  width: 214px;
  min-height: 42.48px;
  height: auto;
  border-radius: 8px;
  background-color: #009291;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #ffff;
  font-weight: bold;
  font-size: 15px;
`;

export const PlusImg = styled.img`
  background-image: url(${GreenPlus});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  width: 27.25px;
  height: 27.25px;
  background-color: #ffff;
  border-radius: 8px;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  width: 250px;
  margin-bottom: 5px;

  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
  }

  button {
    border: none;
    background-color: transparent;
    color: #009291;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    padding: 0;
    margin-left: 7px;
  }
`;

export const CardImgDiv = styled.div`
  border-radius: ${(props) =>
    props.isModal ? '9px 9px 0 0' : '16px 16px 0 0;'};
  position: absolute;
  top: 0px;
  width: ${(props) => (props.isModal ? '100%' : '260px')};
  height: ${(props) => (props.isModal ? '207px' : '181px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #009291;
`;

export const EventCardImg = styled.img`
  width: 75%;
`;

export const ContentTabs = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: overlay;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 31px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  height: ${(props) => (props.content ? '400px' : '230px')};
`;

export const SubContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const ButtonAddProject = styled.button`
  width: 180px;
  max-width: 285px;
  height: 34px;

  background-color: #009291;
  border: none;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 14px;
  text-align: center;

  margin-right: 10px;

  cursor: pointer;
`;

export const ContainerFilterAndAddProject = styled.div`
  display: flex;
  align-items: center;
`;
