import styled from 'styled-components';
import { device } from '../../../ExtensionProject/components/Modal/ModalShowEvidence/utils/devicesQuery';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    position: fixed;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 676px;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
  min-height: ${({ isOnline }) => (isOnline ? '370px' : '500px')};
  max-height: 700px;
  height: auto;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  padding: 70px 0px;

  @media (min-width: 600px) {
    max-width: 450px;
    min-height: ${({ isOnline }) => (isOnline ? '370px' : '650px')};
  }
`;

export const ContentInformation = styled.div`
  width: 80%;
  border: 0.5px solid #c4c4c4;
  min-height: 120px;
  height: auto;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  justify-content: center;
  flex-direction: column;

  > h5 {
    color: #606062;
    font-weight: bold;

    > span {
      font-weight: 400;
      font-size: 15px;
    }
  }
`;

export const Information = styled.span`
  display: flex;
  gap: 4px;
  color: #767676;
  align-items: center;
  font-weight: 400;

  > div {
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: ${({ color }) => `${color}33`};
    border-radius: 4px;
  }

  > p {
    text-decoration: underline;
    margin: 0;
    color: ${({ color }) => color};
    cursor: pointer;
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const TicketInformations = styled.div`
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const TicketInformation = styled.span`
  color: ${({ color }) => color || '#606062'};
  font-weight: 300;
`;
