import React, { useContext } from 'react';
import { Input, Container } from './styled';
import { IoMdSearch } from 'react-icons/io';

function Search({ width, ...rest }) {
  return (
    <Container width={width} color={'#009291'}>
      <div>
        <IoMdSearch color="#FFF" size={20} />
      </div>
      <Input placeholder="Buscar" width={width} {...rest} />
    </Container>
  );
}

export default Search;
