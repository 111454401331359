import React from 'react';

import HeaderReports from '../HeaderReports';
import GenericContainerReportsInfoJobs from './GenericContainerReportInfoJobs';

function ReportsInfosJobs(props) {
  const { InsightList, filterState } = props;
  const jobsFinished = InsightList?.jobsFinished;
  const applications = InsightList?.applications;

  return (
    <div>
      <HeaderReports />

      {InsightList?.jobsRegisteredProgress.map((item) => (
        <GenericContainerReportsInfoJobs
          title={item.name}
          total={item.total}
          noSpecificCourses={item.noSpecificCourses}
          specificCourses={item.specificCourses}
          byUniversity={item.byUniversity}
          byCompany={item.byCompany}
          relationshipJobs={item.name === 'registered'}
          inProgressOrRegistered={true}
          filterStateCourses={filterState.academic_courses}
        />
      ))}

      {jobsFinished && (
        <GenericContainerReportsInfoJobs
          title={jobsFinished.name}
          total={jobsFinished.total}
          finished={true}
          expired={jobsFinished.expired}
          hasContractingByPlatform={jobsFinished.hasContractingByPlatform}
          hasNotContractingByPlatform={jobsFinished.hasNotContractingByPlatform}
          noContracting={jobsFinished.nonContracting}
        />
      )}

      {applications && (
        <GenericContainerReportsInfoJobs
          applications={true}
          title={applications.name}
          total={applications.total}
          average={applications.average}
          byCompany={applications.byCompany}
          byUniversity={applications.byUniversity}
        />
      )}
    </div>
  );
}

export default ReportsInfosJobs;
