import React, { Fragment, useContext } from 'react';
import ReactDOM from 'react-dom';
import { object, func, string } from 'prop-types';
import { Grid, Button } from '@material-ui/core';

import StepWizard from 'react-step-wizard';

import withStyles from '@material-ui/core/styles/withStyles';
import learningTrailsStyle from 'assets/jss/material-dashboard-pro-react/views/learningTrailsStyle';
import WhatIs from './components/WhatIs';
import HowImportant from './components/HowImportant';
import HowAdvance from './components/HowAdvance';
import Videos from './components/Videos';
import Books from './components/Books';
import Movies from './components/Movies';
import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

const Nav = (props) => {
  const { classes, colorScheme, currentStep, totalSteps, goToStep } = props;
  const {universityColor} = useContext(BaseLayoutContext)
  const dots = [];
  for (let i = 1; i <= totalSteps; i += 1) {
    const isActive = currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        className={`${classes.dot} ${isActive ? classes[`${useDefineColor(universityColor)}stepActive`] : "" }`}
        onClick={() => {
          if (i > currentStep + 1 || i === currentStep) {
            return null;
          }
          return goToStep(i);
        }}
      >
        {i}
      </span>
    );
  }

  const dotsField = (
    <Grid
      container
      justify="center"
      className={(classes.fullScreenGridContainer, classes.dotsField)}
    >
      <Grid
        item
        xs
        md={8}
        sm={12}
        className={(classes.learningTrailsNav, classes[colorScheme])}
      >
        {dots}
      </Grid>
    </Grid>
  );

  return dotsField;
};

const NUMBER_OF_STEPS = 6;

class TrailContent extends React.Component {
  state = {
    step: 0,
  };

  wizard = React.createRef();

  wizardHolder = React.createRef();

  async UNSAFE_componentWillMount() {
    this.onUpdateStep(1);
  }

  onStepChange = (data) => {
    this.setState({
      step: data.activeStep,
    });

    /** TODO: Do this better */
    const container = ReactDOM.findDOMNode(this.wizardHolder.current);
    container.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });

    this.onUpdateStep(data.activeStep);
  };

  onSubmit = () => {
    this.props.onSubmit({ trailId: this.props.data.id });
  };

  onUpdateStep = (step) => {
    this.props.onUpdateStep({
      trailId: this.props.data.id,
      progress: step / NUMBER_OF_STEPS,
    });
  };

  render() {
    const { classes, data, colorScheme } = this.props;
    const { current } = this.wizard;
    return (
      <Grid
        container
        className={`${classes.fullScreenGridContainer} ${classes[colorScheme]}`}
        ref={this.wizardHolder}
      >
        <StepWizard
          ref={this.wizard}
          nav={<Nav classes={classes} colorScheme={colorScheme} />}
          onStepChange={this.onStepChange}
          isLazyMount
        >
          <Fragment>
            <WhatIs data={data.whatIs} colorScheme={colorScheme} />
            <Grid
              container
              justify="center"
              className={classes.fullScreenGridContainer}
            >
              <Button
                className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                onClick={() => current.nextStep()}
                style={{ color: '#fff', marginBottom: '10px' }}
              >
                <p style={{ color: '#fff', margin: '0px' }}>
                  Continuar
                </p>
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
            <HowImportant data={data.howImportant} colorScheme={colorScheme} />
            <Grid
              container
              justify="center"
              className={classes.fullScreenGridContainer}
            >
              <Button
                className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                onClick={() => current.nextStep()}
                style={{ color: '#fff', marginBottom: '10px' }}
              >
                <p style={{ color: '#fff', margin: '0px' }}>
                  Continuar
                </p>
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
            <HowAdvance data={data.howAdvance} colorScheme={colorScheme} />
            <Grid
              container
              justify="center"
              className={classes.fullScreenGridContainer}
            >
              <Button
                className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                onClick={() => current.nextStep()}
                style={{ color: '#fff', marginBottom: '10px' }}
              >
                <p style={{ color: '#fff', margin: '0px' }}>Continuar</p>
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
            <Videos data={data.videos} colorScheme={colorScheme} />
            <Grid
              container
              justify="center"
              className={classes.fullScreenGridContainer}
            >
              <Button
                className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                onClick={() => current.nextStep()}
                style={{ color: '#fff', marginBottom: '10px' }}
              >
                <p style={{ color: '#fff', margin: '0px' }}>Continuar</p>
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
            <Books data={data.books} colorScheme={colorScheme} />
            <Grid
              container
              justify="center"
              className={classes.fullScreenGridContainer}
            >
              <Button
                className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                onClick={() => current.nextStep()}
                style={{ color: '#fff', marginBottom: '10px' }}
              >
                <p style={{ color: '#fff', margin: '0px' }}>Continuar</p>
              </Button>
            </Grid>
          </Fragment>

          <Fragment>
            <Movies data={data.movies} colorScheme={colorScheme} />
            <Grid
              container
              justify="center"
              className={classes.fullScreenGridContainer}
            >
              <Button
                className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                onClick={() => this.onSubmit()}
                style={{ color: '#fff', marginBottom: '10px' }}
              >
                <p style={{ color: '#fff', margin: '0px' }}>Finalizar</p>
              </Button>
            </Grid>
          </Fragment>
        </StepWizard>
      </Grid>
    );
  }
}

TrailContent.propTypes = {
  classes: object,
  colorScheme: string,
  data: object,
  onSubmit: func,
  onUpdateStep: func,
};

export default withStyles(learningTrailsStyle)(TrailContent);
