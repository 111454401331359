import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Input, ContainerLabel, Label } from '../styles/InputStyles';
import { ErrorMessage } from 'components/Challenges/styleForm';

export default function InputUsernameLogin({
  label,
  name,
  universityColor,
  type,
  value,
  helpText,
  ...rest
}) {
  const inputRef = useRef(null);

  const {
    fieldName,
    registerField,
    error,
    defaultValue,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, newValue) => {
        inputRef.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabel>
      {label && (
        <Label universityColor={universityColor} htmlFor={name}>
          {label || name}
        </Label>
      )}
      {helpText && <div className="help-text">{helpText}</div>}
      <Input
        ref={inputRef}
        type={type}
        defaultValue={defaultValue}
        onFocus={clearError}
        valid={defaultValue !== ''}
        name={name}
        data-testid="input-username"
        {...rest}
      />

      {error && (
        <ErrorMessage marginTop={error && '0px'} order={error}>
          {' '}
          {error}
        </ErrorMessage>
      )}
    </ContainerLabel>
  );
}
