import styled from 'styled-components';

export const CardSpanStarProfessions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardProfessions = styled.div`
  border: 1px solid rgb(220 215 215);
  padding-top: 0.6rem;
  flex-wrap: nowrap;
  box-shadow: rgb(68 75 75 / 25%) 0px 1px 4px,
    rgb(101 107 107 / 13%) 0px 0px 4px 5px;
  border-radius: 15px;
`;
export const CardInfosProfessions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
  justify-content: space-between;
`;

export const SpanProfessions = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 20px;

  @media screen and (max-width: 430px) {
    font-size: 1.8rem;
    font-weight: 400;
    margin-left: 10px;
  }
`;

export const ButtonProfessions = styled.button`
  border: none;
  color: #fff;
  padding-left: 15px;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0px;
  width: 100%;
  height: 40px;
  text-align: initial;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const TitleProfessions = styled.p`
  font-size: 0.9em !important;
  padding: 0 !important;
  margin-left: 20px;
  margin-right: 20px;
  font-weight: 400;
  color: #7f7f80;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  height: 2.8em;
  max-height: 2.8em;

  @media screen and (max-width: 430px) {
    margin-right: 12px;
    margin-left: 12px;
    margin-bottom: 5px;
  }
`;

export const LineCardProfessions = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background-color: #e6e2e2;
`;

export const ContainerButton = styled.div``;
