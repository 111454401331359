import { useEffect, useState } from 'react';
import { generatePreview } from 'views/StaffPages/SettingsPage/CommunicationRulerTab/services/services';
import {
  Information,
  ContainerMail,
  Email,
  Metrics,
  CardCount,
  WrapperCardCount,
  CardInactiveData,
} from './style';
import metricsSvg from 'assets/csm/metrics.svg';
import { emailPart } from '../previewEmail';
import moment from 'moment';

export default function VisualizationEmail({ item }) {
  const isInactiveData = item.metrics.send === 0;

  return (
    <div>
      <Information>
        <span>
          Nome da campanha:
          <p>{item.name}</p>
        </span>
      </Information>
      <Information>
        <span>
          Criação da campanha:
          <p>
            {moment(item.created_at).format('DD/MM/YYYY')} (
            {moment(item.created_at).format('dddd')}) às{' '}
            {moment(item.created_at).format('HH:mm')}
          </p>{' '}
        </span>
      </Information>
      <Information>
        <span>
          Assunto do e-mail:
          <p>{item.subject}</p>
        </span>
      </Information>
      <Information>
        <span>
          Link do CTA inserido:
          <p>{item.button_link ? item.button_link : 'Não informado'}</p>
        </span>
      </Information>
      <Information>
        <span>
          Listas selecionadas:
          <p>{item.lists?.join(' , ')}</p>
        </span>
      </Information>

      <ContainerMail>
        <Email>{emailPart(item)}</Email>
        <Metrics>
          <div>
            <span>
              <img src={metricsSvg} />
              Métricas{' '}
            </span>
            <p>Confira aqui as métricas da sua campanha</p>
          </div>
          {isInactiveData ? (
            <CardInactiveData>
              A campanha não possui dados disponíveis.
            </CardInactiveData>
          ) : (
            <WrapperCardCount>
              <CardCount>
                <p>E-mails enviados</p>
                <h3>
                  {item.metrics.send}
                  <span>?</span>
                </h3>

                <div>Quantidade de e-mails enviados aos estudantes.</div>
              </CardCount>
              <CardCount>
                <p>E-mails entregues</p>
                <h3>
                  {item.metrics.delivery}
                  <sub>({item.metrics.delivery_percent}%)</sub>
                  <span>?</span>
                </h3>
                <div>Quantidade de e-mails entregues aos estudantes.</div>
              </CardCount>
              <CardCount>
                <p>Taxa de abertura</p>
                <h3>
                  {item.metrics.open}
                  <sub>({item.metrics.opened_percent}%)</sub>
                  <span>?</span>
                </h3>
                <div>
                  Quantidade de e-mails abertos em relação ao total de e-mails
                  enviados.
                </div>
              </CardCount>
              {item.button_link && (
                <CardCount>
                  <p>Taxa de cliques</p>
                  <h3>
                    {item.metrics.click}
                    <sub>({item.metrics.click_percent}%)</sub>
                    <span>?</span>
                  </h3>
                  <div>
                    Quantidade de estudantes que clicaram no CTA
                    (Call-to-Action) inserido nos e-mails.
                  </div>
                </CardCount>
              )}
            </WrapperCardCount>
          )}
        </Metrics>
      </ContainerMail>
    </div>
  );
}
