import { create } from 'zustand';

const initialState = {
  cardData: {
    careerInterests: [],
    lifeInterests: [],
    personality: [],
    behavioral: [],
    languages: [],
    values: [],
    extraCourses: [],
    lifeExperience: [],
    careerExperience: [],
    portfolio: [],
    technicalCompetences: [],
  },
};

export const ProfileStore = create((set) => ({
  cardData: initialState.cardData,
  changeCardData: (cardData) =>
    set((state) => {
      return { cardData: cardData };
    }),
  updateCard: (key, value) =>
    set((state) => {
      const data = { ...state.cardData, [key]: value };
      return (state.cardData = data);
    }),
  resetCardData: () => set((state) => (state.cardData = initialState.cardData)),
}));

export const actionsProfileStore = (() => {
  const changeCardData = ProfileStore.getState().changeCardData;
  const updateCard = ProfileStore.getState().updateCard;
  const resetCardData = ProfileStore.getState().resetCardData;

  return { changeCardData, updateCard, resetCardData };
})();

export const useProfileStore = () => {
  const cardData = ProfileStore((state) => state.cardData);
  return { cardData };
};
