import styled from 'styled-components';
import searchIcon from 'assets/mentorship/search.svg';
import error from 'assets/Matriz/error.svg';

export const FormInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 15px;
    line-height: 17px;
    color: #606062;
    font-weight: 700;
  }

  input {
    margin-top: 0.5rem;
    border-radius: 4px;
    padding: 10px;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    color: #606062;
    width: 100%;
    border: 1px solid ${(props) => (props.cepError ? '#FFCECE' : '#CDCDCD')};
    background-color: ${(props) => (props.cepError ? '#FFEDED' : '')};

    &.zip_code {
      background-image: url(${searchIcon});
      background-repeat: no-repeat;
      background-position: 96%;
    }

    &::placeholder {
      color: #cdcdcd;
    }
  }
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
  color: #3c4858;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
