export function IconLanguage({ color }) {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17284_54380)">
        <path
          d="M23.9263 5.55469H6.92099C3.5673 5.55469 0.847656 8.27311 0.847656 11.628V18.916C0.847656 22.2697 3.5673 24.9894 6.92099 24.9894H23.9263C27.2752 24.9894 29.9997 22.2649 29.9997 18.916V11.628C29.9997 8.27919 27.2752 5.55469 23.9263 5.55469ZM10.7108 20.1307C10.3148 20.1307 9.97103 19.8574 9.88235 19.4711L9.56047 18.0658H6.63433L6.30151 19.476C6.21041 19.8598 5.86909 20.1307 5.47433 20.1307C4.92651 20.1307 4.52203 19.6193 4.64714 19.0861L6.36346 11.8066C6.60761 10.7547 7.74454 10.0988 8.86082 10.5664C9.39406 10.7899 9.75239 11.3025 9.88357 11.8661L11.5392 19.0909C11.6606 19.623 11.2574 20.1307 10.7108 20.1307ZM27.5703 18.916C27.5703 20.9251 25.9354 22.56 23.9263 22.56H15.4237V7.98402H23.9263C25.9354 7.98402 27.5703 9.61897 27.5703 11.628V18.916ZM25.7483 12.3763V12.4018C25.7483 12.8148 25.4131 13.15 25.0001 13.15H24.6163C24.4693 14.6987 23.8668 16.4673 22.6643 17.8459C23.3227 18.2455 24.1122 18.531 25.0742 18.6221C25.4568 18.6585 25.7483 18.9816 25.7483 19.3667V19.3922C25.7483 19.8368 25.3645 20.1793 24.9224 20.1368C23.5413 20.0044 22.4214 19.5428 21.5116 18.8954C20.5969 19.5489 19.4637 20.0068 18.0716 20.1368C17.6295 20.1781 17.2469 19.8355 17.2469 19.391V19.3655C17.2469 18.9756 17.5481 18.6561 17.9368 18.6197C18.894 18.5286 19.6835 18.2468 20.3382 17.8483C19.9362 17.388 19.6009 16.8839 19.3264 16.3579C19.0677 15.8624 19.4345 15.2696 19.9932 15.2696H20.0054C20.2799 15.2696 20.5362 15.4178 20.6625 15.6607C20.8872 16.0883 21.1678 16.5001 21.5043 16.8778C22.4979 15.7603 22.9522 14.3173 23.087 13.1476H17.9963C17.5833 13.1476 17.2481 12.8123 17.2481 12.3993V12.3738C17.2481 11.9608 17.5833 11.6256 17.9963 11.6256H20.7378V11.1592C20.7378 10.7462 21.0731 10.4109 21.4861 10.4109H21.5116C21.9246 10.4109 22.2598 10.7462 22.2598 11.1592V11.6256H25.0013C25.4143 11.6256 25.7496 11.9608 25.7496 12.3738L25.7483 12.3763ZM8.21462 12.1904L9.17056 16.3652H7.03639L8.02148 12.1904C8.03242 12.1455 8.07129 12.1139 8.11744 12.1139C8.1636 12.1139 8.2049 12.1455 8.21462 12.1904Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_17284_54380">
          <rect
            width="29.152"
            height="29.152"
            fill="white"
            transform="translate(0.847656 0.696289)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
