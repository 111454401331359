import React, { useCallback, useContext, useState } from 'react';
import { Container, ActionButton, Button } from './styled';
import { DocIcon } from 'assets/csm/doc-icon';
import { getStudentCurriculumData } from 'services/university/curriculum';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import { getApplicationCoverLetter } from 'services/university/coverLetter';
import BaseLayoutContext from 'contexts/base-layout';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import { LetterIcon } from 'assets/mentorship/LetterIcon';
import { registerAccessedCurriculum } from 'services/student/registerActivityStudent';

export function StudentDocuments({ studentProfile }) {
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);

  const {
    openModalWithContent,
    openSnackbar,
    handleIsLoadingState,
    closeModal,
    updateHasCoverLetter,
  } = useContext(BaseLayoutContext);

  const openCurriculum = useCallback(async ({ id }) => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(id);
    await registerAccessedCurriculum([response.curriculum_id]);
    if (!response.message) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  const openLetter = useCallback(async ({ id }) => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(id);
    if (!response.message) {
      openModalWithContent(
        <CoverLetterContainer
          notStudent
          studentId={id}
          handleLoadingState={handleIsLoadingState}
          getApplicationCoverLetter={getApplicationCoverLetter}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
        />
      );
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  return (
    <Container>
      <ActionButton>
        <Button
          type={'button'}
          className="buttonCurriculum"
          onClick={() => openCurriculum(studentProfile)}
          disabled={!studentProfile?.has_curriculum}
        >
          <DocIcon
            color={!studentProfile?.has_curriculum ? '#949494' : '#009291'}
          />
          Visualizar currículo
        </Button>

        <Button
          type={'button'}
          className="buttonLetter"
          onClick={() => openLetter(studentProfile)}
          disabled={!studentProfile?.has_cover_letter}
        >
          <LetterIcon
            color={!studentProfile?.has_cover_letter ? '#949494' : '#009291'}
          />
          Visualizar carta de apresentação
        </Button>
      </ActionButton>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          showActions={false}
          showInterviewButton={false}
        />
      )}
    </Container>
  );
}
