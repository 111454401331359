import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1103;
  @media (max-width: 475px) {
    width: 100%;
    display: block;
  }
`;

export const ModalContent = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 614px;
  max-height: 534px;
  position: relative;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  @media (max-width: 475px) {
    width: 100%;
    position: fixed;
    bottom: 0;
    max-height: 100%;
  }
`;

export const FragmentInfo = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const CloseButton = styled.button`
  background-color: white;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  box-shadow: 0px 1px 6px 0px #0000002e;
  cursor: pointer;
`;

export const ContainerBanner = styled.div`
  width: 100%;
  height: 87px;
  border-radius: 15px 15px 0px 0px;
  background-color: blue;
  border: none;
  ${(props) =>
    props.img
      ? `background-image: url(${props.img});`
      : `background-color: ${props.color || 'none'};`}
  background-size: cover;
  background-position: center;
  ${(props) => (props.img && `height: 248px;`) || ''}
`;

export const FragmentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  > h4 {
    font-size: 23px;
    font-weight: 400;
    font-family: Roboto;
  }
  > p {
    font-weight: 200;
    font-family: Roboto;
    line-height: 30px;
    word-break: break-word;
    font-size: 13px;
    color: #606062;
    height: auto;
    max-height: 207px;
    width: 100%;
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: #eeeeee;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #eeeeee;
    }
  }
  @media (max-width: 475px) {
    padding: 0.7rem;
    > div {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: baseline;
    }
  }
`;

export const Line = styled.hr`
  margin-top: -9px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
  }
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 475px) {
    flex-direction: column;
    gap: 1rem;
    > div {
      justify-content: center;
      flex-direction: column;
    }
    > span {
      margin-bottom: 1.5rem;
      gap: 1rem;
    }
  }
`;

export const AccessButton = styled.button`
  background-color: ${(props) => props.color || ''};
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  @media (max-width: 475px) {
    min-width: 194px;
    justify-content: center;
  }
`;

export const InformationButton = styled.button`
  background-color: transparent;
  border: 1px solid ${(props) => props.color || ''};
  color: ${(props) => props.color || ''};
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 15px;
  white-space: nowrap;
  @media (max-width: 475px) {
    min-width: 194px;
  }
`;
const returnLikedorNot = (props) => {
  let backgroundColor = '#F6F6F6';
  let fillColor = props.like ? '#13B266' : '#D26464';
  if (props?.liked) {
    backgroundColor = '#13B266';
    fillColor = 'white';
  }
  if (props?.disliked) {
    backgroundColor = '#D26464';
    fillColor = 'white';
  }
  return `
    background-color: ${backgroundColor};
    >svg{
      fill: ${fillColor};
    }
    `;
};

export const ContainerButton = styled.div`
  ${(props) => returnLikedorNot(props)}
  padding: 4px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  height: 32px;
  width: 33px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 25px;
  white-space: nowrap;
`;
