import backgroundNetworkStudent from 'assets/img/background-network-student.png';
import React, { useEffect, useState } from 'react';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import { FutureConstruction } from 'components/FutureConstruction/FutureConstruction';
import { DisplayCompanies } from './Screens/Companies/DisplayCompanies';

export default function NetWorkStudentEngine({ handleIsLoadingState }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const components = {
    0: <DisplayCompanies />,
    1: (
      <FutureConstruction
        children={
          <p>
            Em breve, você poderá <b>conectar com seus colegas</b> da sua
            instituição de ensino, cursos e disciplinas e desfrutar do poder do{' '}
            <b>Networking!</b>
          </p>
        }
      />
    ),
  };
  return (
    <>
      <CompanyViewsHeader
        title="Networking"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundNetworkStudent}
        tabs={[`Empresas`, `Estudantes`]}
      />
      {components[activeTab]}
    </>
  );
}
