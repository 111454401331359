import styled from 'styled-components';
import CardComposition from 'components/CardComposition/CardComposition';

export const ConfigurationCardComposistion = styled(CardComposition)`
  margin-bottom: 32px;
  &:nth-child(3) {
    margin-bottom: 0px;
    padding-bottom: 24px;
  }
  & > div:first-child {
    margin: 1.75rem 2rem 0.75rem 2rem;
    padding: 0px;
    h3 {
      padding: 0px;
      margin: 0px !important;
    }
  }
  & > div:nth-child(2) {
    margin: 0px 2rem 0.75rem 2rem !important;
    padding: 0px;
    & > div {
      padding: 0px !important;
    }
  }
`;
