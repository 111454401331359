import styled from 'styled-components';

import arrowRight from 'assets/Forms/arrowRight.svg';
import arrowLeft from 'assets/Forms/arrowLeft.svg';

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 50px;
  padding-bottom: 50px;
`;

const BaseButton = styled.button`
  position: absolute;
  background-color: #009291;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  cursor: pointer;
`;

export const NextButton = styled(BaseButton)`
  right: 8px;
  background-image: url(${arrowRight});

  &:disabled {
    background-color: #949494;
    cursor: not-allowed;
  }
`;

export const PrevsButton = styled(BaseButton)`
  left: 8px;
  background-image: url(${arrowLeft});
`;
export const SaveButton = styled.button`
  position: absolute;
  background-color: #009291;
  color: #fff;
  border: none;
  right: 8px;
  height: 29px;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  align-items: center;
  top: 7px;
  cursor: pointer;

  &:disabled {
    background-color: #949494;
    color: #fff;
  }
`;
