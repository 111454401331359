export default function Ticket({ size = 18, color }) {
  return (
    <div>
      <svg
        width={size}
        height={size - 4}
        viewBox="0 0 18 14"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66797 10.332L9.0013 8.58203L11.293 10.332L10.418 7.4987L12.7513 5.66536H9.91797L9.0013 2.83203L8.08463 5.66536H5.2513L7.54297 7.4987L6.66797 10.332ZM2.33464 13.6654C1.8763 13.6654 1.48394 13.5022 1.15755 13.1758C0.831163 12.8494 0.667969 12.457 0.667969 11.9987V9.1862C0.667969 9.03342 0.71658 8.90147 0.813802 8.79036C0.911024 8.67925 1.03602 8.60981 1.1888 8.58203C1.52214 8.47092 1.79644 8.26953 2.01172 7.97786C2.227 7.6862 2.33464 7.35981 2.33464 6.9987C2.33464 6.63759 2.227 6.3112 2.01172 6.01953C1.79644 5.72786 1.52214 5.52648 1.1888 5.41536C1.03602 5.38759 0.911024 5.31814 0.813802 5.20703C0.71658 5.09592 0.667969 4.96398 0.667969 4.8112V1.9987C0.667969 1.54036 0.831163 1.148 1.15755 0.821615C1.48394 0.495226 1.8763 0.332031 2.33464 0.332031H15.668C16.1263 0.332031 16.5187 0.495226 16.845 0.821615C17.1714 1.148 17.3346 1.54036 17.3346 1.9987V4.8112C17.3346 4.96398 17.286 5.09592 17.1888 5.20703C17.0916 5.31814 16.9666 5.38759 16.8138 5.41536C16.4805 5.52648 16.2062 5.72786 15.9909 6.01953C15.7756 6.3112 15.668 6.63759 15.668 6.9987C15.668 7.35981 15.7756 7.6862 15.9909 7.97786C16.2062 8.26953 16.4805 8.47092 16.8138 8.58203C16.9666 8.60981 17.0916 8.67925 17.1888 8.79036C17.286 8.90147 17.3346 9.03342 17.3346 9.1862V11.9987C17.3346 12.457 17.1714 12.8494 16.845 13.1758C16.5187 13.5022 16.1263 13.6654 15.668 13.6654H2.33464ZM2.33464 11.9987H15.668V9.8737C15.1541 9.56814 14.7478 9.16189 14.4492 8.65495C14.1506 8.148 14.0013 7.59592 14.0013 6.9987C14.0013 6.40148 14.1506 5.84939 14.4492 5.34245C14.7478 4.8355 15.1541 4.42925 15.668 4.1237V1.9987H2.33464V4.1237C2.84852 4.42925 3.25477 4.8355 3.55339 5.34245C3.852 5.84939 4.0013 6.40148 4.0013 6.9987C4.0013 7.59592 3.852 8.148 3.55339 8.65495C3.25477 9.16189 2.84852 9.56814 2.33464 9.8737V11.9987Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
