import React from 'react';

import { Wrapper, Image, ContainerInformation } from './style';

import projection from 'assets/DashBoard/projection.svg';

export default function Soon({ useError }) {
  const configs = {
    default: {
      header: 'Em construção!',
      p: `Acompanhe aqui as novidades que
       estamos desenvolvendo para você.`,
    },
    error: {
      header: 'Oops!',
      p: `Aconteceu um erro em buscar os dados
       para você! Tente novamente mais tarde.`,
    },
  };

  return (
    <Wrapper>
      <Image src={projection} />

      <ContainerInformation>
        <h3>{useError ? configs.error.header : configs.default.header}</h3>
        <p>{useError ? configs.error.p : configs.default.p}</p>
      </ContainerInformation>
    </Wrapper>
  );
}
