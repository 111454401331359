import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  z-index: 1029;
  width: 100%;
  position: relative;
  display: flex;
  padding: 60px 40px;
  gap: 20px;
`;

export const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  height: 100%;
  max-height: 700px;
  width: 100%;
  max-width: 340px;
  align-items: center;
  border-radius: 8px;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 15.08px;
  font-weight: 400;
  > img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-top: 10px;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 14px;
      height: 14px;
      margin-top: 4px;
    }
    p {
      margin: 5px 2px 0px;
      box-sizing: content-box;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .studentName {
    font-size: 20px;
    font-weight: 700;
    color: #009291;
    line-height: 25px;
    margin: 10px;
  }
`;
export const ActionButton = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      width: 278.63px;
      border-radius: 5px;
      border: 1px solid;
      border-color: #009291;
      svg {
        margin-right: 10px;
      }
    }

    .buttonCurriculum {
      background-color: #009291;
      color: #ffffff;
      cursor: pointer;
    }

    .buttonLetter {
      background-color: #ffffff;
      color: #009291;
      cursor: pointer;
    }
  }
  margin-bottom: 20px;
`;

export const Article = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const Divider = styled.div`
  border: 0.2px solid #e6e6e6;
  margin: 12px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  width: 100%;
`;

export const SearchFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: #c4c4c4;
  height: 38px;

  input {
    border: none;
    cursor: pointer;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
  }
  svg {
    margin-left: 15px;
    color: #606062;
  }
`;

export const OrderFilter = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 130px;

  > span {
    display: none;
    align-items: center;
  }
  .react-select__control {
    box-shadow: rgb(255, 255, 255) 0px 3px 8px;
    border-color: #c4c4c4;
    height: 34px;
    z-index: 1;
    cursor: pointer;
  }
  .react-select__control:hover {
    box-shadow: rgb(255, 255, 255) 0px 3px 8px;
    border-color: #c4c4c4;
  }
  .react-select__menu {
    width: 260px;
    margin-left: -130px;
  }

  .react-select__placeholder {
    color: #606062;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }
`;

export const ButtonClose = styled.div`
  display: flex;
  align-items: center;
  button {
    width: 40px;
    height: 40px;
    border: none;
    font-weight: 400;
    background-color: #ffffff;
    cursor: pointer;
  }
`;
