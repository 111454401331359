import { getCities, getUfsByNumber } from '../../../services';
import { validationCurrency } from '../../../../../utils/validation';

const jobOfferAdapter = async (data, setCacheContext) => {
  delete data.required_formation;

  const getCurrentCities = async (arrayUfs) => {
    const response = await getCities(arrayUfs);
    setCacheContext(response);
  };

  const mapUfs = (ufs) => {
    const currentUfs = ufs.map((uf) => uf).join('|');
    getCurrentCities(currentUfs);
  };

  const getUfs = async () => {
    if (data.locations.length) {
      const dataLocations = data.locations;
      const numberOfUfs = dataLocations
        .map((item) => {
          return item.value.substring(0, 2);
        })
        .join('|');
      const response = await getUfsByNumber(numberOfUfs);

      if (Array.isArray(response)) {
        const mappedUfs = response.map((item) => item.sigla);
        mapUfs(mappedUfs);
        return mappedUfs;
      }
      mapUfs(response.sigla.split());
      return response.sigla.split();
    }
  };

  return {
    ...data,
    isInternalCompany: true,
    isEditJob: true,
    profession:
      data.courses_matching_rule && data.courses_matching_rule.profession
        ? data.courses_matching_rule.profession.label
        : '',
    id:
      data.courses_matching_rule && data.courses_matching_rule.profession
        ? data.courses_matching_rule.profession.value
        : 'noValueId',
    courses: data.courses_matching_rule
      ? data.courses_matching_rule.courses
      : [],
    uf: await getUfs(),
    locations: data.locations.length
      ? data.locations.map((item) => {
          return item.value;
        })
      : [],
    jobId: data.id,
    application_type:
      data.application_type && data.application_type.length
        ? data.application_type
        : [],
    salary: validationCurrency(data.salary),
  };
};

export { jobOfferAdapter };
