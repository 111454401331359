import styled from 'styled-components';
import search from 'assets/Matriz/search.svg';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const ContainerList = styled.div`
  padding: 30px 66px 66px 66px;
  width: 100%;
`;

export const ContainerMatrix = styled.div`
  width: 30%;
  padding: 10px;
`;

export const Bar = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eef2f4;
  margin-top: 20px;
  align-items: baseline;
  padding: 10px;

  > div > div > input {
    position: relative;
    &:hover {
      &:before {
        display: block;
      }
      &:after {
        display: block;
      }
    }
    &:before {
      content: 'selecionar todos';
      height: 17px;
      background-color: #009291;
      position: absolute;
      z-index: 2;
      top: -35px;
      padding: 5px;
      left: -13px;
      color: #fff;
      font-weight: 400;
      border-radius: 4px;
      width: 100px;
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-color: #009291 transparent;
      border-width: 10px 9px 0;
      bottom: 11px;
      left: 53%;
      margin-left: -9px;
      display: none;
    }
  }
`;

export const BarPrincipal = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #eef2f4;
  margin-top: 20px;
  align-items: baseline;
  padding: 10px;

  > div > input {
    position: relative;
    &:hover {
      &:before {
        display: block;
      }
      &:after {
        display: block;
      }
    }
    &:before {
      content: 'selecionar';
      height: 17px;
      background-color: #009291;
      position: absolute;
      z-index: 2;
      top: -35px;
      padding: 5px;
      left: -13px;
      color: #fff;
      font-weight: 400;
      border-radius: 4px;
      width: 60px;
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-color: #009291 transparent;
      border-width: 10px 9px 0;
      bottom: 11px;
      left: 53%;
      margin-left: -9px;
      display: none;
    }
  }
`;

export const CustomSpan = styled.span`
  width: ${(props) => props.width};
  font-weight: bold;
  color: #b8b8b8;
`;

export const SpanItems = styled.span`
  width: ${(props) => props.width};
`;

export const WrapperPagination = styled.div`
  margin-top: 30px;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const WrapperButton = styled.div`
  position: relative;

  &:hover {
    > div {
      display: block;
    }
  }
`;

export const PopUp = styled.div`
  height: 30px;
  background-color: #009291;
  position: absolute;
  z-index: 0;
  top: -42px;
  padding: 5px;
  left: -13px;
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
  display: none;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #009291 transparent;
    border-width: 10px 9px 0;
    bottom: -10px;
    left: 53%;
    margin-left: -11px;
  }
`;

export const SearchInput = styled.input`
  padding-left: 40px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 12px 9px;
  background-size: 16px;
  border: 1px solid #777777;
  border-radius: 4px;
  line-height: 30px;
  width: 332px;
`;

export const Spacer = styled.input`
  height: 40px;
  width: 100%;
  border: none;
`;
