import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import {
  AboutTitle,
  CompetenciesWrapper,
  DescImg,
  Img,
  MultiStepContainer,
  CompetenciesContainer,
  SelectedCompetencies,
  Title,
  Wrapper,
  WrapperCompetencies,
  DivCompetencies,
  InfoImg,
  ContainerLabelTooltip,
  LabelInput,
} from '../../../Styles/formStyled';
import extensionProjectIcon from 'assets/img/extension-project-medium.svg';
import FooterForm from '../../FooterForm';
import SearchableSelectUn from '../../Form/searchableSelect';
import StepIndicator from '../../StepIndicator';
import { getCourses, getPeriod, getDisciplines } from '../../../services';
import { ButtonX } from '../../../Styles/Styled';
import Input from '../../Form/input';
import Tooltip from 'components/NewTooltip';
import info from 'assets/Oportutinites/info.svg';

export function StepOne() {
  const formRef = useRef(null);
  const {
    formData,
    macroAreas,
    setCourses,
    courses,
    periods,
    setPeriods,
    unities,
    competencies,
    disciplines,
    setDisciplines,
    setFormValues,
    setStep,
    step,
    modalIsOpen,
  } = useContext(OportunitiesContext);

  const isEdit = !!formData.uuid;
  const [arrayMacroAreas, setArrayMacroAreas] = useState([]);
  const [arrayCourseSearch, setArrayCourseSearch] = useState([]);
  const [arrayDisciplineSearch, setArrayDisciplineSearch] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);

  useEffect(() => {
    if ((isEdit || formData.isOnModal) && formRef.current) {
      formRef.current.setFieldValue('unities', formData.unities);
      setSelectedCompetencies(formData.competences_specialities || []);
    }
  }, [isEdit, formRef, formData.isOnModal]);

  useEffect(() => {
    if (selectedCompetencies.length >= 5) {
      setFilterSearch([]);
      setInputSearch('');
    } else {
    }
  }, [selectedCompetencies]);

  useEffect(() => {
    if (!modalIsOpen) {
      if (!formData.name) {
        formRef.current.reset();
        formRef.current.setErrors({});
      }
    }
    if (modalIsOpen) {
      formRef.current.setFieldValue('name', formRef.name);
    }
  }, [modalIsOpen]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const handleMacroChange = useCallback((e) => {
    setArrayMacroAreas([]);
    e.forEach((value) =>
      setArrayMacroAreas((prevsState) => [
        ...prevsState,
        `macroareas=${value.value}&`,
      ])
    );
  });

  const handleCourseChange = (e) => {
    setArrayCourseSearch([]);
    e.forEach((value) =>
      setArrayCourseSearch((prevsState) => [
        ...prevsState,
        `courses=${value.value.replace('c', '')}&`,
      ])
    );
  };

  const handleDisciplineChange = (e) => {
    setArrayDisciplineSearch([]);
    e.forEach((value) =>
      setArrayDisciplineSearch((prevsState) => [
        ...prevsState,
        `disciplines=${value.value}&`,
      ])
    );
  };

  useEffect(() => {
    const sendCourse = arrayMacroAreas.join('');
    if (arrayMacroAreas.length) {
      const setCourseRequest = async () => {
        const response = await getCourses(_, sendCourse, false);
        setCourses(response);
      };

      setCourseRequest();
    } else {
      setCourses([]);
      setPeriods([]);
      setDisciplines([]);
    }
  }, [arrayMacroAreas]);

  useEffect(() => {
    const sendPeriod = arrayCourseSearch.join('');

    if (arrayCourseSearch.length) {
      const sendPeriodRequest = async () => {
        const response = await getPeriod(sendPeriod);
        setPeriods([]);
        response.forEach((item) =>
          setPeriods((prevsState) =>
            getUniqueListBy([...prevsState, item], 'label')
          )
        );
      };
      sendPeriodRequest();
    }
  }, [arrayCourseSearch]);

  useEffect(() => {
    const sendDisciplines = arrayCourseSearch.join('');

    if (arrayCourseSearch.length) {
      const setDisciplineRequest = async () => {
        const response = await getDisciplines(sendDisciplines);
        setDisciplines(response);
      };
      setDisciplineRequest();
    } else {
      setDisciplines([]);
    }
  }, [arrayCourseSearch]);

  async function handleSubmit(data) {
    (data.competences_specialities = selectedCompetencies.map(
      (competence) => competence.value
    )),
      setFormValues(data);
    formRef.current.reset();
    setStep(1);
  }

  useEffect(() => {
    if (formData.macro_areas) {
      formRef.current.setFieldValue('macro_areas', formData.macro_areas);
    }
    if (formData.courses) {
      formRef.current.setFieldValue('courses', formData.courses);
    }
    if (formData.disciplines) {
      formRef.current.setFieldValue('disciplines', formData.disciplines);
    }
    if (formData.semesters) {
      formRef.current.setFieldValue('semesters', formData.semesters);
    }
    if (formData.unities) {
      formRef.current.setFieldValue('unities', formData.unities);
    }
    if (formData.competences_specialities) {
      formRef.current.setFieldValue('test', formData.competences_specialities);
    }
  }, [isEdit, formData, step]);

  const HandleClickDelete = (e, id) => {
    e.preventDefault();
    const filterArray = selectedCompetencies.filter(
      (compet) => compet.value !== id
    );
    setSelectedCompetencies(filterArray);
  };

  const handleFilter = (e) => {
    const targetValue = e.target.value;
    setInputSearch(targetValue);

    const OptionsFilter = competencies.filter((value) => {
      return value.label.toLowerCase().includes(inputSearch.toLowerCase());
    });
    setFilterSearch(getUniqueListBy(OptionsFilter, 'label'));
  };

  useEffect(() => {
    if (inputSearch === '') {
      setFilterSearch([]);
    }
  }, [inputSearch]);

  const handleCompetencie = (value) => {
    setSelectedCompetencies((prevsState) =>
      getUniqueListBy([...prevsState, value], 'value')
    );
  };

  const populateNewSelection = () => {
    const newCurrentArrayPopulated = selectedCompetencies.reduce(
      (accumulator, item) => {
        accumulator[item.label] = item.value;
        return accumulator;
      },
      {}
    );

    const filterArray = filterSearch.filter((item) => {
      return !newCurrentArrayPopulated[item.label];
    });

    return filterArray.map((item) => (
      <DivCompetencies key={item.value} onClick={() => handleCompetencie(item)}>
        {item.label}
      </DivCompetencies>
    ));
  };

  return (
    <Form
      ref={formRef}
      initialData={formData}
      onSubmit={handleSubmit}
      style={{ marginTop: '16px' }}
    >
      <Wrapper>
        <MultiStepContainer>
          <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
        </MultiStepContainer>
        <DescImg>
          <Img src={extensionProjectIcon} />
          <Title>Projeto de Extensão</Title>
        </DescImg>
        <AboutTitle>Público Alvo</AboutTitle>
        <SearchableSelectUn
          name="macro_areas"
          options={macroAreas ? macroAreas : []}
          isMulti
          placeholder={'Todos'}
          onChange={handleMacroChange}
          label="Áreas de atuação relacionadas"
        />
        <SearchableSelectUn
          name="courses"
          options={courses ? courses : []}
          isMulti
          placeholder={'Todos'}
          onChange={handleCourseChange}
          label="Selecione os cursos relacionados"
        />
        <div>
          <ContainerLabelTooltip>
            <LabelInput>Disciplinas relacionadas</LabelInput>
            <Tooltip
              text={`Mostramos as disciplinas que foram
                associadas ao projeto de extensão no menu
                “Acadêmico”, em “Matriz curricular”.`}
              direction={'top'}
              minWidth="250px"
              isMultiLine
            >
              <InfoImg src={info} />
            </Tooltip>
          </ContainerLabelTooltip>
          <SearchableSelectUn
            name="disciplines"
            options={disciplines ? disciplines : []}
            isMulti
            placeholder={'Selecione'}
            onChange={handleDisciplineChange}
          />
        </div>
        <SearchableSelectUn
          name="semesters"
          options={periods ? periods : []}
          isMulti
          placeholder={'Todos'}
          label="Períodos"
          style={{ padding: '0px 16px', height: '2.3em' }}
        />
        <SearchableSelectUn
          name="unities"
          options={unities ? unities : []}
          isMulti
          placeholder={'Todos'}
          label="Unidades/Polos"
          style={{ padding: '0px 16px', height: '2.3em' }}
        />
        <AboutTitle>Competências</AboutTitle>
        <span className="competencies-description">
          Selecione até <b>5 competências/especialidades</b> que serão
          aprendidas pelos estudantes ao realizar esse projeto de extensão.
        </span>
        <CompetenciesContainer>
          <CompetenciesWrapper>
            {selectedCompetencies.map((value, index) => (
              <div>
                {value && value.label && (
                  <SelectedCompetencies key={index}>
                    {value.label}
                    <ButtonX
                      onClick={(e) => HandleClickDelete(e, value.value)}
                    />
                  </SelectedCompetencies>
                )}
              </div>
            ))}
          </CompetenciesWrapper>

          <Input
            name="test"
            disabled={selectedCompetencies.length >= 5}
            value={inputSearch}
            placeholder="Digite aqui..."
            onChange={handleFilter}
          />

          {filterSearch.length !== 0 && (
            <WrapperCompetencies>{populateNewSelection()}</WrapperCompetencies>
          )}
        </CompetenciesContainer>
      </Wrapper>
      <FooterForm haveNext={true} haveBack={false} />
    </Form>
  );
}
