import moment from 'moment';

const calculateTimeBetweenDates = (startDate, endDate) => {
  if (endDate !== 'Atual') {
    const calculatedTime = moment.duration(
      moment(endDate, 'MM/YYYY').diff(moment(startDate, 'MM/YYYY'))
    );

    const { years, months } = calculatedTime._data;

    if (years && months) {
      return `${years > 1 ? `${years} anos` : `${years} ano`} e ${
        months > 1 ? `${months} meses` : `${months} mês`
      }`;
    }
    if (months) {
      return `${months > 1 ? `${months} meses` : `${months} mês`}`;
    }
    if (years) {
      return `${years > 1 ? `${years} anos` : `${years} ano`}`;
    }
  }
  return null;
};

export default calculateTimeBetweenDates;
