import styled from 'styled-components';
import search from 'assets/Matriz/search.svg';

export const ContainerList = styled.div`
  .containerHeader {
    padding: 0px 40px;
    display: flex;
    margin: 30px 0px;
    color: #b8b8b8;
    font-weight: bold;

    .span1 {
      width: 31em;
    }
    .span2 {
      width: 20em;
    }
    .span3 {
      width: 11em;
    }
    .span4 {
    }
  }
`;

export const Header = styled.header`
  padding: 12px 40px;
  border-bottom: 2px solid #eef2f4;
  display: flex;
  justify-content: space-between;

  > button {
    background-color: #009291;
    border: none;
    border-radius: 4px;
    color: #fff;
    padding: 12px;
    cursor: pointer;
    font-weight: bold;
  }
`;

export const SearchInput = styled.input`
  padding-left: 8px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 231px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  width: 260px;
`;
