import React from 'react';
import { ActionContainer } from './styled';

export default function Action({ icon, active, color, onClick }) {
  return (
    <ActionContainer active={active} color={color} onClick={onClick}>
      {icon}
    </ActionContainer>
  );
}
