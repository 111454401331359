import React, { Fragment, useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { shape, func, object } from 'prop-types';
import ReportsStyle from 'assets/jss/material-dashboard-pro-react/views/ReportsStyle';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TabContainer from 'components/TabContainer';

import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import backgroundHeader from 'assets/DashBoard/background.png';

// TABS
import CustomReport from 'views/StaffPages/CustomReport';
import { DashboardEngine } from '../Dashboard/DashboardEngine';
import ReportsEngine from '../ReportsTab/ReportsEngine';
import CustomTabInsights from '../CustomTabInsights';

import {
  getInsightData,
  getInsightOptions,
} from 'services/university/indicators';
import {
  insightAdapter,
  insightOptionsAdapter,
} from 'adapters/myPanel/insightsAdapter';
import { graphDataAdapter } from '../ReportsTab/useCases/graphDataAdapter';
import { getGraphicData } from '../ReportsTab/services/graphicData';

function Reports({
  handleIsLoadingState,
  classes,
  openSnackbar,
  openTalkModal,
  globalState,
}) {
  const [activeTabContent, setActiveTabContent] = useState(0);
  const [insightList, setInsightList] = useState(null);
  const [insightOptions, setInsightOptions] = useState(null);
  const [isRequestingOptions, setIsRequestingOptions] = useState(false);
  const [showSurveys, setShowSurveys] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [lastRequest, setLastRequest] = useState(null);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const handleQueryParams = async (filters) => {
    await handleInsightData(filters);
  };

  const handleInsightData = async (filters) => {
    const response = await getInsightData(filters);
    if (response.message) {
      handleIsLoadingState(false);
      if (response.response && response.response.status == 403) {
        openTalkModal({
          description: (
            <span>
              <p style={{ marginBottom: '20px' }}>Ops!</p>
              <p>
                Há um problema com o seu cadastro na Workalove. Não foram
                encontradas instituições de ensino associadas ao seu usuário.
                Por favor, entre em contato com o suporte e informe sobre essa
                situação.
              </p>
            </span>
          ),
          buttonText: 'Entendi',
        });
      } else {
        openSnackbar(
          `Não foi possível carregar os indicadores! Tente novamente mais tarde.`,
          true
        );
      }
      return;
    }
    setInsightList(insightAdapter(response));
    handleIsLoadingState(false);
  };

  const handleOptions = async () => {
    setIsRequestingOptions(true);
    const response = await getInsightOptions();
    if (response && response.message) {
      openSnackbar(
        `Houve um problema ao carregar os filtros! Tente novamente mais tarde.`,
        true
      );
      return;
    }
    setIsRequestingOptions(false);
    setInsightOptions(insightOptionsAdapter(response));
  };

  const getDataDashboard = async (filter) => {
    setIsLoadingDashboard(true);
    const { code, result } = await getGraphicData(filter);
    setDashboardData(graphDataAdapter({ ...result, code }));
    setIsLoadingDashboard(false);
    handleIsLoadingState(false);
  };

  const callDatas = async () => {
    let now = new Date();
    if (lastRequest === null) {
      await getDataDashboard();
      await handleInsightData();
      await handleOptions();
      setLastRequest(now);
    } else {
      let dif = now - lastRequest;
      if (dif > 10000) {
        if (activeTabContent === 0 || activeTabContent === 1) {
          await getDataDashboard();
        }
        if (activeTabContent === 2) {
          await handleInsightData();
          await handleOptions();
        }
        setLastRequest(now);
      }
    }
  };

  useEffect(() => {
    callDatas();
    if (
      globalState.staffRole === 'unity_staff' ||
      globalState.staffRole === 'educational_group_board'
    ) {
      setShowSurveys(false);
    } else {
      setShowSurveys(true);
    }
  }, [activeTabContent]);

  const handleTabChange = (event, value) => {
    setActiveTabContent(value);
  };

  const insightsComponent = () => {
    return (
      <GridContainer direction="column" className={classes.padding0}>
        <GridItem xs lg={12} xl={10} className={classes.width100}>
          <CustomTabInsights
            InsightList={insightList}
            handleQueryParams={handleQueryParams}
            insightOptions={insightOptions}
            isRequestingOptions={isRequestingOptions}
            handleIsLoadingState={handleIsLoadingState}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const customReportComponent = () => {
    return (
      <GridContainer
        direction="column"
        alignItems="center"
        className={classes.padding0}
      >
        <GridItem item xs md={9} sm={12} className={classes.width100}>
          <TabContainer>
            <CustomReport
              handleIsLoadingState={handleIsLoadingState}
              openSnackbar={openSnackbar}
            />
          </TabContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const componentsObject = {
    0: (
      <DashboardEngine
        handleIsLoadingState={handleIsLoadingState}
        data={dashboardData}
        getDataDashboard={getDataDashboard}
        isLoading={isLoadingDashboard}
      />
    ),
    1: (
      <ReportsEngine
        handleIsLoadingState={handleIsLoadingState}
        data={dashboardData}
        isLoading={isLoadingDashboard}
      />
    ),
    2: insightsComponent(),
    3: customReportComponent(),
  };

  const returnComponentCurrentTab = () => {
    return componentsObject[activeTabContent];
  };

  return (
    <Fragment>
      <CompanyHeader
        title="Dashboard"
        activeTab={activeTabContent}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundHeader}
        tabs={
          showSurveys
            ? [
                'Dashboard',
                `Insights`,
                'Relatórios',
                `Relatórios Personalizados`,
              ]
            : ['Dashboard', `Insights`, 'Relatórios']
        }
      />
      <Fragment>{returnComponentCurrentTab()}</Fragment>
    </Fragment>
  );
}
Reports.propTypes = {
  classes: shape.isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  openTalkModal: func.isRequired,
  globalState: object,
};
export default withStyles(ReportsStyle)(Reports);
