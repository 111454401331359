import React, { useEffect, useRef, useState } from 'react';

import { Container, ContainerDate } from './styled';
import { StepTitle } from '../styled';
import Input from 'components/UnformFields/inputField';
import { Form } from '@unform/web';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import StepController from '../../StepController';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import { FormHandlers } from 'views/StaffPages/FairAndEvents/Handlers/formHandlers';
import { getValidationSchema } from './schema';
import formValidationHandler from 'views/StaffPages/FairAndEvents/Handlers/formValidationHandler';

export default function StepThree() {
  const useRegisterFormInstance = useRegisterModalContext();

  const FormHandlersFactory = FormHandlers(useRegisterFormInstance);

  const { stepsData, formRefStepThree } = useRegisterFormInstance;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { event_start_at, event_end_at } = stepsData;

  const schema = getValidationSchema(event_start_at, event_end_at);

  const handleSubmit = (data) => {
    FormHandlersFactory.handleSubmitForm(data, schema, formRefStepThree);
  };

  setInterval(async () => {
    const formData = formRefStepThree?.current?.getData();

    const newSchema = formValidationHandler.convertSchema(schema);

    try {
      await newSchema.validate(formData, { abortEarly: false });
      setIsButtonDisabled(false);
    } catch (err) {
      setIsButtonDisabled(true);
    }
  }, 1000);

  return (
    <Container>
      <StepTitle>Detalhes da inscrição</StepTitle>

      <Form
        ref={formRefStepThree}
        onSubmit={handleSubmit}
        initialData={stepsData}
      >
        <ContainerDate>
          <DatePickerCustom
            name="registration_start_date"
            label="Data de início*"
          />
          <DatePickerCustom
            name="registration_end_date"
            label="Data de término*"
          />
        </ContainerDate>

        <Input
          name="limit_students"
          label="Limite de estudantes"
          formatDecimal={true}
          useHint
          hintConfig={{
            text: `Caso não seja informado um limite
              de estudantes ou ele seja 0, entenderemos que o
              evento está aberto a um número
              ilimitado de participantes.`,
            direction: 'top',
            isMultiLine: true,
            minWidth: '200px',
          }}
        />
        <StepController isButtonDisabled={isButtonDisabled} />
      </Form>
    </Container>
  );
}
