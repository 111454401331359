import React, { useContext } from 'react';
import BaseLayoutContext from '../../../../../../contexts/base-layout';
import { JobOfferContext } from '../../../../Contexts/JobOfferContext';
import PreviewJob from '../PreviewJobs';
import { PrevButton } from './styles';

export const PreviewButton = ({ children, stepThreeData, handleSubmit }) => {
  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const { data, stepThreeRef, externalJobOffer, setZIndex } = useContext(
    JobOfferContext
  );

  const handleClickPreview = (e) => {
    e.preventDefault();
    setZIndex(0);
    openModalWithContent(
      <PreviewJob
        data={data}
        stepThreeData={stepThreeRef}
        externalJobOffer={externalJobOffer}
        handleSubmit={handleSubmit}
        closeModal={closeModal}
      />,
      null,
      null,
      () => setZIndex(2000000000)
    );
  };

  return <PrevButton onClick={handleClickPreview}>{children}</PrevButton>;
};
