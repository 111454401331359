import MarketTips from '../../components/UIComponents/WorkaCard/Images/marketTips.png';
import MentoringMarketTips from '../../components/UIComponents/WorkaCard/Images/mentoring-marketTips.png';
import NewWorldOfWorks from '../../components/UIComponents/WorkaCard/Images/newWorldOfWork.png';
import LifeProject from '../../components/UIComponents/WorkaCard/Images/lifeProject.png';

export default [
  {
    route: '/trilhas-aprendizagem',
    name: 'Projeto de Vida',
    description:
      'Desenvolvemos um programa de autosugestão de vida e carreira para você dar um passo de como construir seu projeto de vida. Percorra as trilhas de Autoconhecimento, Soft Skills, Protagonismo e Sucesso e Superação. Cada trilha de aprendizagem vai te ajudar a descobrir melhor seus talentos, paixões e interesses e como tudo isso pode te ajudar a fazer uma real diferença no mundo... E se quiser se aprofundar, preparamos uma curadoria de vídeos, livros e filmes para você em cada trilha!',
    modality: 'Online',
    created_by: {
      name: 'Workalove',
    },
    certificate: true,
    image: LifeProject,
  },
  {
    route: '/seu-sucesso?tab=0',
    name: 'Dicas do Mercado de Trabalho',
    description:
      'Se para você o mercado de trabalho ainda tem vários mistérios que precisam ser desvendados, essa temporada é para você! Vamos no passo a passo e com a ajuda de profissionais incríveis descobrir os mitos e verdades sobre como alcançar o bom sucesso que te traga reconhecimento financeiro e realização profissional.',
    modality: 'Online',
    created_by: {
      name: 'Workalove',
    },
    certificate: true,
    image: MarketTips,
  },
  {
    route: '/seu-sucesso?tab=1',
    name: 'Mentorias - Dicas do Mercado do Trabalho',
    description:
      'Se você já deu um mergulho profundo na 1ª Temporada das Dicas do Mercado do Trabalho e já explorou todas as funcionalidades do sistema, então chegou a hora de colocar a mão na massa! Essa temporada foi preparada para você avançar na sua jornada para o novo mundo do trabalho.',
    modality: 'Online',
    created_by: {
      name: 'Workalove',
    },
    certificate: false,
    image: MentoringMarketTips,
  },
  {
    route: '/seu-sucesso?tab=2',
    name: 'O novo mundo do trabalho',
    description:
      'O mundo sempre esteve mudando, mas a intensidade com que as mudanças chegaram mais recentemente tem levado a uma reviravolta na dinâmica do mundo do trabalho. Aprenda mais sobre essas mudanças e o impacto na sua vida e carreira.',
    modality: 'Online',
    created_by: {
      name: 'Workalove',
    },
    certificate: false,
    image: NewWorldOfWorks,
  },
];
