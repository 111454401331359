const payloadAdapter = ({
  id,
  companyName,
  organizationNumber,
  staffName,
  staffPhone,
  staffEmail,
  selectedCity,
  selectedState,
  mandatory_internship,
  non_mandatory_internship,
  send_invite_email,
  address,
}) => {
  const dataMutation = {
    legal_name: companyName,
    name: companyName,
    organization_number: organizationNumber,

    address: address && address.address,
    address_number: address && address.number,
    address_complement: address && address.complement,
    district: address && address.district,
    zip_code: address && address.zipCode,

    state: selectedState && selectedState.label,
    city: selectedCity && selectedCity.label ? selectedCity.label : '',

    staff_name: staffName,
    staff_email: staffEmail,
    staff_phone: staffPhone,

    mandatory_internship,
    non_mandatory_internship,
    send_invite_email,
  };
  if (id) {
    dataMutation.id = id;
  }
  return dataMutation;
};
const payloadIntegratorAdapter = ({
  id,
  addressNumber,
  city,
  companyName,
  complement,
  organizationNumber,
  street,
  addressState,
  district,
  zipCode,
  send_invite_email,
}) => {
  const dataMutation = {
    id: id !== null && id !== undefined ? id : null,
    legal_name: companyName,
    name: companyName,
    organization_number: organizationNumber,
    address: street,
    address_number: addressNumber,
    address_complement: complement,
    district,
    zip_code: zipCode,
    city,
    state: addressState,
    send_invite_email,
  };

  return dataMutation;
};

const companyInviteAdapter = ({
  id,
  address,
  created_at,
  updated_at,
  legal_name,
  organization_number,
  staff,
  company_activities_sectors,
  company_challenges,
  company_interests,
  company_vacancies,
  is_accepted,
  is_answerable,
  is_canceled,
  is_rejected,
  is_editable,
  send_invite_email,
  invite_sent_at,
  invite_answered_at,
  mandatory_internship,
  non_mandatory_internship,
  responsible_user,
  agreement_document_one_file,
  agreement_document_two_file,
  status,
}) => ({
  id,
  address: {
    city: address && address.city,
    zipCode: address && address.zip_code,
    address: address && address.address,
    addressNumber: address && address.number,
    addressComplement: address && address.complement,
    district: address && address.district,
    state: address && address.state,
  },
  name: legal_name,
  organizationNumber: organization_number,
  staff: {
    name: staff && staff.name,
    phone: staff && staff.phone,
    email: staff && staff.email,
  },
  activitiesSectors: company_activities_sectors,
  challenges: company_challenges,
  interests: company_interests,
  isAccepted: is_accepted,
  isAnswerable: is_answerable,
  isCanceled: is_canceled,
  isRejected: is_rejected,
  isEditable: is_editable,
  createdAt: created_at,
  updatedAt: updated_at,
  sendInviteEmail: send_invite_email,
  inviteSentAt: invite_sent_at,
  inviteAnsweredAt: invite_answered_at,
  mandatory_internship,
  non_mandatory_internship,
  responsible_user,
  companyVacancies: company_vacancies,
  agreementDocumentOneFile: {
    label: agreement_document_one_file && agreement_document_one_file.label,
    value: agreement_document_one_file && agreement_document_one_file.value,
  },
  agreementDocumentTwoFile: {
    label: agreement_document_two_file && agreement_document_two_file.label,
    value: agreement_document_two_file && agreement_document_two_file.value,
  },
  status,
});

export { companyInviteAdapter, payloadAdapter, payloadIntegratorAdapter };
