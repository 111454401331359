const radioOptions = [
  {
    id: 'inteligence',
    value: 'inteligence',
    label: 'Publicar vaga com Inteligência',
    description:
      'Você receberá uma sugestão de texto para preenchimento de sua vaga, ' +
      'conforme dados do mercado. Edite de acordo com a sua necessidade.',
    type: 'radio',
  },
  {
    id: 'external',
    value: 'external',
    label: 'Somente divulgar meu Processo Seletivo',
    description: `Somente utilize essa opção se você já cadastrou seu processo seletivo em outra plataforma.
     Vamos pedir o link ou e-mail do seu processo seletivo.`,
    type: 'radio',
  },
];

export { radioOptions };
