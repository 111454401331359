import React from 'react';
import { getImagesIes } from 'views/StaffPages/Opportunities/services';
import { GenerateBlob } from '../PdfViwer/generateBlob';
import { Container } from './style';
import { DownloadButton } from '../PdfViwer/style';

export default function ImageViwer({
  file,
  name,
  type,
  fromStudent,
  universityColor,
  ctaDownloadPreview = true,
}) {
  const handleClickDownload = async () => {
    const response = await getImagesIes(file.id, fromStudent);
    const blob = window.URL.createObjectURL(new Blob([response.data]));
    const downloadLink = document.createElement('a');
    downloadLink.href = blob;
    downloadLink.download = `${name}${type}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blob);
  };

  if (file.createdNow) {
    const previewURL = URL.createObjectURL(file.file);
    return (
      <Container>
        <img src={previewURL} />
      </Container>
    );
  }

  return (
    <Container>
      {ctaDownloadPreview && (
        <DownloadButton
          universityColor={universityColor}
          onClick={handleClickDownload}
        >
          FAZER DOWNLOAD
        </DownloadButton>
      )}

      <img src={file.image?.file || file.file} />
    </Container>
  );
}
