import valNotFound from '../../assets/img/valNotFound.svg';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { MainDiv, Title, Text, ButtonBack, ContainerImg } from './styled';

export default function WallNotFound(props) {
  const { title, text, titleButton, actionButton } = props;

  return (
    <Fragment>
      <MainDiv>
        <ContainerImg src={valNotFound}></ContainerImg>
        <div className="divText">
          <Title>{title}</Title>
          <Text>{text}</Text>
          <ButtonBack onClick={() => actionButton()}>{titleButton}</ButtonBack>
        </div>
      </MainDiv>
    </Fragment>
  );
}
