import styled from 'styled-components';
import Warning from '../../../../../../assets/JobOffer/warning.svg';
import Contract from '../../../../../../assets/JobOffer/withContracting.svg';
import noContracting from '../../../../../../assets/JobOffer/noContract.svg';

export const Container = styled.div`
  width: 100%;
  max-width: 255px;
  height: 100%;
  max-height: 300px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 16px;
`;

export const WrapperInfos = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  strong {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 8px;
    text-transform: capitalize !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    height: 33px;
    word-break: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    padding-bottom: 1rem;
    color: #949494;
    line-height: 1rem;
  }
`;

export const VisualIndicator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  gap: 0.5rem;

  .icon {
    width: 28px;
    height: 28px;
    padding: 4px;
    border-radius: 3px;
    background: #e0ffff;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon img {
    width: 16px;
  }

  p {
    font-family: 'Roboto', sans-serif;

    font-size: 11.6px;
    font-weight: 400;
    color: #606062;
    line-height: 0.89rem;
    margin: 0 !important;
  }

  button {
    background-color: transparent;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #009291;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
    padding: 0;
  }
`;

export const SimpleBar = styled.div`
  height: 1px;
  width: 100%;
  border: 0.8px solid #e5e5e5;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  height: 27px;

  font-weight: 700;
  font-size: 12px;
  max-width: ${(props) => (props.size === 'job expired' ? '100%' : 'unset')};
  color: ${(props) => (props.color === 'job expired' ? '#009291' : '#ffffff')};
  background-color: ${(props) =>
    props.backgroundColor === 'job expired' ? '#ffffff' : '#009291'};

  border: 1px solid #009291;
  border-radius: 4px;

  padding: 0;
  margin-top: 12px;
  margin-bottom: 15px;
  margin-left: ${(props) => props.marginLeft};

  cursor: pointer;
`;

export const ButtonDelete = styled.button`
  width: 100%;
  max-width: 140px;
  border: 1px solid;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #606062;
  font-size: 12px;

  background-color: transparent;
  border: none;

  padding-top: 20px;
  padding-left: 0;

  cursor: pointer;
`;

export const ButtonEdit = styled.button`
  width: 100%;
  max-width: 43px;

  display: flex;
  align-items: center;

  color: #009291;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;

  background-color: transparent;
  border: none;

  padding-top: 20px;
  padding-left: 0;

  cursor: pointer;
`;
export const JobsStatus = styled.div`
  width: 28px;
  height: 28px;
  padding: 4px;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.expired {
    background: url(${Warning}) no-repeat;
    background-position: center;
    background-size: 16px;
    background-color: #e0ffff;
  }

  &.noContract {
    background: url(${noContracting}) no-repeat;
    background-position: center;
    background-size: 13px;
    background-color: #e0ffff;
  }

  &.withContractingExternal {
    background: url(${Contract}) no-repeat;
    background-position: center;
    background-size: 16px;
    background-color: #e0ffff;
  }

  &.withContractingWorkalove {
    background: url(${Contract}) no-repeat;
    background-position: center;
    background-size: 16px;
    background-color: #e0ffff;
  }

  &.unpublishJob {
    background: url(${noContracting}) no-repeat;
    background-position: center;
    background-size: 13px;
    background-color: #e0ffff;
  }
`;

export const ListButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #009291;
  cursor: pointer;
  text-decoration: underline;
`;

export const FeedbackButton = styled.button`
  background-color: #009291;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  height: 27px;
  font-weight: 700;
  font-size: 12px;
  border: none;
  margin-top: 12px;
  margin-bottom: 15px;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
`;
