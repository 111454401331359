import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import {
  CardContainer,
  CardHeader,
  CardIcon,
  CardsWrapper,
  CardText,
  ChoiceInput,
} from './Styled';
import BasicLanguageIcon from 'assets/ComponentIcons/LanguageCardIcons/BasicIcon';
import ElementaryLanguageIcon from 'assets/ComponentIcons/LanguageCardIcons/ElementaryIcon';
import IntermediaryLanguageIcon from 'assets/ComponentIcons/LanguageCardIcons/IntermediaryIcon';
import AdvancedLanguageIcon from 'assets/ComponentIcons/LanguageCardIcons/AdvancedIcon';
import FluentLanguageIcon from 'assets/ComponentIcons/LanguageCardIcons/FluentIcon';
import AcademicLanguageIcon from 'assets/ComponentIcons/LanguageCardIcons/AcademicIcon';

function LanguageCardItem({
  options,
  setProps,
  name,
  dataLanguage,
  isEdit,
  universityColor,
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = '', error } = useField(name);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return (
          (refs.find((ref) => ref.checked) &&
            refs.find((ref) => ref.checked).value) ||
          ''
        );
      },
      setValue: (refs, id) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField, selectedCard]);

  function checkedCard(option) {
    if (isEdit && selectedCard === null) {
      return option.id === dataLanguage.fluence_level.value;
    } else {
      return option.id === selectedCard;
    }
  }

  const returnIcon = {
    basic: <BasicLanguageIcon color={universityColor} />,
    elementary: <ElementaryLanguageIcon color={universityColor} />,
    intermediary: <IntermediaryLanguageIcon color={universityColor} />,
    advanced: <AdvancedLanguageIcon color={universityColor} />,
    fluent: <FluentLanguageIcon color={universityColor} />,
    academic: <AcademicLanguageIcon color={universityColor} />,
  };

  return (
    <CardsWrapper>
      {options.map((options, index) => (
        <CardContainer
          universityColor={universityColor}
          key={options.id}
          checked={checkedCard(options)}
          onClick={() => setSelectedCard(options.id)}
        >
          <div className="bg-opacity"></div>
          <CardIcon>{returnIcon[options.id]}</CardIcon>
          <CardHeader>{options.title}</CardHeader>
          <CardText>
            <p>{options.description}</p>
          </CardText>
          <ChoiceInput
            type="radio"
            ref={(ref) => {
              inputRefs.current[index] = ref;
            }}
            id={options.id}
            name={name}
            defaultChecked={dataLanguage && dataLanguage.fluence_level.value}
            value={options.id}
            {...rest}
          />
        </CardContainer>
      ))}
      {error && (
        <p style={{ color: '#f00', margin: '0px auto', fontSize: '16px' }}>
          {error}
        </p>
      )}
    </CardsWrapper>
  );
}

export default LanguageCardItem;
