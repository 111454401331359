import React, { memo, useEffect, useState } from 'react';
import {
  Wrapper,
  Container,
  ContainerPieChart,
  ContainerBarChart,
  LoadingContainer,
} from './style';

import HeaderReports from 'components/HeaderReports';
import Soon from 'components/soon';

import CareerObjective from './components/careerObjective';
import Card from './components/card';
import PieChartCustom from '../../../components/pieChart';
import { returnObjectValue } from './utils/returnObjectValue';
import BarChartCustom from './components/barChart';
import { formatNumber } from '../../../utils/formatNumber';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';

function ReportsEngine({ handleIsLoadingState, data, isLoading }) {
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    if (data.code !== 200) {
      setDisplayError(true);
      return;
    }
    setDisplayError(false);
  }, [data]);

  useEffect(() => {
    handleIsLoadingState(true);
  }, []);

  const activeStudents = data.carrerObjective ? data.carrerObjective.total : 0;

  const configHeader = {
    title: 'Insights de Trabalhabilidade',
    subTitle: `Os seus <strong>${activeStudents} estudantes </strong> que responderam sobre o seu objetivo de carreira desejam:`,
  };

  const configEmployGraphic = {
    colors: ['#8BD3D3', '#009291'],
    graphicData: returnObjectValue(data, 'employedAndUnemployed', 'chart'),
    bottomPhrase: `Total de estudantes: ${formatNumber(
      returnObjectValue(data, 'employedAndUnemployed', 'total')
    )}`,
    widthContainer: 500,
    heightContainer: 300,

    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
    keyAccessGraphic: 'percentage',
    useChartLegend: true,
  };

  const configStudentsWorkingInAreaGraphic = {
    colors: ['#8BD3D3', '#009291'],
    graphicData: returnObjectValue(data, 'studentsWorkingInArea', 'chart'),
    bottomPhrase: `Total de estudantes: ${formatNumber(
      returnObjectValue(data, 'studentsWorkingInArea', 'total')
    )}`,
    widthContainer: 500,
    heightContainer: 300,
    keyAccessGraphic: 'percentage',
    layout: 'vertical',
    verticalAlign: 'middle',
    align: 'right',
    useChartLegend: true,
  };

  const configBarChartSimple = {
    data: data.marketRelationship && data.marketRelationship.chart,
    total: formatNumber(
      data.marketRelationship && data.marketRelationship.total
    ),
    useLegendChart: true,
    xKey: 'none',
    margin: { top: 30, right: 0, left: -20, bottom: 50 },
  };

  return (
    <Wrapper>
      {isLoading ? (
        <LoadingContainer>
          <LoadingUpdate />
          <p
            style={{
              fontSize: '16px',
              fontWeight: '400',
            }}
          >
            Carregando...
          </p>
        </LoadingContainer>
      ) : displayError ? (
        <>
          <Soon useError={true} />
        </>
      ) : (
        <Container>
          <HeaderReports
            title={configHeader.title}
            subTitle={configHeader.subTitle}
          />
          <CareerObjective graphData={data.carrerObjective} />

          <ContainerPieChart>
            <Card
              title={'Situação profissional'}
              subTitle={'Seus estudantes <strong>ativos</strong> estão:'}
            >
              <PieChartCustom config={configEmployGraphic} />
            </Card>
            <Card
              title={'Atuando na área de formação'}
              subTitle={
                'Dos seus estudantes que estão <strong>trabalhando:</strong>'
              }
            >
              <PieChartCustom config={configStudentsWorkingInAreaGraphic} />
            </Card>
          </ContainerPieChart>

          <ContainerBarChart>
            {data.marketRelationship && (
              <Card
                title={'Relacionamento com o mercado'}
                subTitle={'Dos seus estudantes que estão trabalhando:'}
              >
                <BarChartCustom config={configBarChartSimple} />
              </Card>
            )}
          </ContainerBarChart>
        </Container>
      )}
    </Wrapper>
  );
}

export default memo(ReportsEngine);
