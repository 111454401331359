import FilterModal from 'components/FilterModal';
import React, { useState, useEffect } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterContent } from './styled';
import { mentorshipApi } from 'views/Student/Mentorship/services/api';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import * as Yup from 'yup';

export const MentorsTabFilter = ({ fetchMentors }) => {
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [errorDate, setErrorDate] = useState(false);

  useEffect(() => {
    async function fetchFilterOptions() {
      const response = await mentorshipApi.getMentorsFilterOptions();
      setFilterOptions(response);
    }
    fetchFilterOptions();
  }, []);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        end_date: Yup.date().min(
          Yup.ref('start_date'),
          'A data final precisa ser maior do que a data inicial.'
        ),
      });
      await schema.validate(data, { abortEarly: false });
      setErrorDate(false);
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  const handleApplyFilters = async (filters) => {
    const isValid = await validateData(filters);
    if (!isValid) {
      return false;
    } else {
      const queryString = queryParamsFromFilterObject(filters);
      await fetchMentors(queryString);
    }
  };

  const handleClearFilters = () => {
    setErrorDate(false);
    fetchMentors();
  };

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={handleApplyFilters}
      onClean={handleClearFilters}
      setAppliedFilters={setAppliedFilters}
      appliedFilters={appliedFilters}
      validateDate={true}
    >
      <FilterContent>
        <Filter
          name={['start_date', 'end_date']}
          label={'Data da mentoria'}
          type={FilterType.DATE_RANGE}
          settings={{
            placeholder: ['Data inicial', 'Data final'],
            minDate: new Date(),
            onChange: () => setErrorDate(false),
          }}
        />
        <Filter
          name={'periods'}
          label={'Turno'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.period}
        />
        <Filter
          name={'mentorship_modality'}
          label={'Modalidade'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.mentorship_modality}
        />
        <Filter
          name={'mentor'}
          label={'Mentor'}
          type={FilterType.SINGLE_SELECT}
          options={filterOptions.mentors}
        />
      </FilterContent>
    </FilterModal>
  );
};
