import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 9px;
  padding: 1rem;
  gap: 0.75rem;

  span {
    font-size: 14px;
    color: #777;
  }

  > div {
    display: flex;
    gap: 0.5rem;
    margin-left: auto;
    align-items: center;

    svg {
      cursor: pointer;
    }

    span {
      margin: 0 2rem;
    }
  }
`;
export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
`;
