import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;
  padding: 35px 45px;
`;

export const Card = styled.div`
  border: 1px solid #cdcdcd;
  width: 100%;
  height: 120px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const ContainerGradientImage = styled.div`
  position: relative;
  background: linear-gradient(to right, ${(props) => props.color}, #ffffff);
  width: 90px;
  height: 100%;

  > img {
    position: absolute;
    left: 40px;
    width: 100px;
    border-radius: 50%;
    object-fit: contain;
    height: 100px;
    border: 1px solid #c4c4c4;
    background-color: #fff;
    top: 9px;
  }
`;

export const ContainerName = styled.div`
  margin-left: 75px;
  width: 530px;

  > h4 {
    font-weight: 400;
    color: #000;
  }
`;

export const ContainerInformations = styled.div`
  border-radius: 4px;
  display: flex;
  gap: 20px;
  padding: 5px 0px;

  > span {
    display: flex;
    align-items: center;
    gap: 10px;

    > p {
      margin: 0;
      font-weight: 400;
      color: #606062;
    }

    > div {
      background-color: #00929126;
      padding: 4px;
      border-radius: 4px;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;

      > img {
        width: 18px;
      }
    }
  }
`;

export const Divider = styled.div`
  border-left: 1px solid #cdcdcd;
  height: 90%;
`;

export const ContainerActions = styled.div`
  display: flex;
  gap: 10px;
  margin: 0px 10px;
`;

export const ActionButton = styled.div`
  background-color: #00929126;
  width: 35px;
  height: 35px;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const SkeletonBase = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const SkeletonCard = styled(SkeletonBase)`
  height: 120px;
  display: flex;
  align-items: center;
`;

export const SkeletonImage = styled(SkeletonBase)`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  margin-left: 43px;
  animation-delay: 3s;
`;

export const SkeletonTitle = styled(SkeletonBase)`
  width: 120px;
  margin-left: 43px;
`;
