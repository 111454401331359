import React, { useContext, useRef, useEffect, useState, useMemo } from 'react';
import {
  Box,
  CloseButton,
  Container,
  Content,
  WrapperMessage,
  WrapperTitle,
  BubbleDialog,
  ContainerButton,
  GreenButton,
  ErrorMessage,
  GreenButtonFeedback,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { Form } from '@unform/web';
import NoteInput from 'components/UnformFields/noteInput';
import { GrFormClose } from 'react-icons/gr';
import * as Yup from 'yup';

export default function ModalFeedBack({
  isOpen,
  setOpen,
  buttonInsideFunction,
  item,
}) {
  const formRef = useRef();

  const handleSubmit = async (data, { reset }) => {
    try {
      const schema = Yup.object().shape({
        feedback: Yup.string()
          .required('Esse campo é obrigatório!')
          .min(20, 'O mínimo de caracteres é 20'),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      buttonInsideFunction(data, item.id);
      reset();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const error = err.errors[0];
        formRef.current.setFieldError('feedback', error);
      }
    }
  };

  const memoDescInput = useMemo(() => {
    return (
      <Form ref={formRef} onSubmit={handleSubmit}>
        <NoteInput name="feedback" maxLength={200} useCount />
        <ContainerButton>
          <GreenButtonFeedback type="submit">
            RECUSAR EVIDÊNCIAS
          </GreenButtonFeedback>
        </ContainerButton>
      </Form>
    );
  }, []);

  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <Container isOpen={isOpen}>
      <Box>
        <Content size="unpublish">
          <CloseButton onClick={handleClickClose}>
            <GrFormClose size={18} />
          </CloseButton>

          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <BubbleDialog widthBubble="220px" heightBubble="54px">
              <span> Envie seu feedback </span>
            </BubbleDialog>
          </WrapperTitle>
          <WrapperMessage
            fontMessage="18px"
            lineHeigthMessage="1.6rem"
            positionMessage="start"
          >
            Mande um feedback explicando o motivo de ter recusado as evidências
            do estudante.
          </WrapperMessage>
          {memoDescInput}
        </Content>
      </Box>
    </Container>
  );
}
