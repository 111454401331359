import styled from 'styled-components';

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: 705px) {
    grid-template-columns: 1fr;
  }
`;

export const FilterContainer = styled.div`
  display: flex;

  @media (max-width: 1065px) {
    margin-left: auto;
  }
`;

export const FilterFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  > strong {
    font-size: 17px;
    font-weight: 700;
    color: #4b4b4d;
  }
`;

export const SizedBox = styled.div`
  height: ${({ height }) => height || 'auto'};
`;
