import styled from 'styled-components';

export const ContainerSelectCreateTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 15px;

  > .inputContainer {
    width: 100%;
  }

  img {
    width: 20px;
  }
`;
