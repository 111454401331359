import { useContext, useState } from 'react';
import {
  Container,
  GrayGradient,
  StudentImage,
  TextName,
  Row,
  Tooltip,
  GenericButton,
  DetailsButton,
  IconWrapper,
  WrapperInformations,
} from './styled';
import userIcon from 'assets/img/userIcon.png';
import { formatDateTime } from 'utils/formatDateTime';
import AuthContext from 'contexts/authentication';
import Icons from 'views/StaffPages/Mentorship/utils/mentorshipIcons';

export function StudentCard({
  item,
  detailsButtonText,
  additionalDetailsButtonText,
  showDetailsButton,
  showAdditionalDetailsButton,
  showCanceledInfo,
  buttonDetailsFunction,
  buttonAdditionalDetailsFunction,
  showCourse,
  showCareerGoals,
}) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);

  const {
    simpleFormattedDate,
    formattedStartHour,
    formattedEndHour,
  } = formatDateTime(item?.date, item?.start_hour, item?.end_hour);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = !['teacher', 'course_coordinator'].includes(
    staffRole
  );
  const careerGoalLabel =
    item.career_goals?.[0]?.[0]?.label || 'Objetivo não especificado';
  const shouldShowTooltip = careerGoalLabel !== 'Objetivo não especificado';

  return (
    <Container>
      <GrayGradient />
      <StudentImage
        src={item?.student_avatar || userIcon}
        onError={(e) => {
          e.target.src = userIcon;
          e.target.onerror = null;
        }}
        margin={'13px auto 0 auto'}
      />
      <TextName
        onClick={() => buttonDetailsFunction && buttonDetailsFunction(item)}
      >
        {item.student}
      </TextName>
      {shouldRenderForStaff && (
        <Row>
          <IconWrapper>
            <Icons.UserIcon />
          </IconWrapper>
          <span>{item?.mentor || 'Mentor não identificado'}</span>
        </Row>
      )}
      <Row>
        <IconWrapper>
          <Icons.CalendarIcon />
        </IconWrapper>
        <span>{simpleFormattedDate}</span>
      </Row>
      <Row>
        <IconWrapper>
          <Icons.ClockIcon />
        </IconWrapper>
        <span>
          Das {formattedStartHour} às {formattedEndHour}
        </span>
      </Row>
      {!shouldRenderForStaff && (
        <Row>
          <IconWrapper>
            <Icons.HouseLaptopIcon />
          </IconWrapper>
          <span>{item?.mentorship_modality?.label}</span>
        </Row>
      )}
      {showCareerGoals && (
        <Row onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <IconWrapper>
            <Icons.TargetIcon />
          </IconWrapper>
          <span>{careerGoalLabel}</span>
          {shouldShowTooltip && (
            <Tooltip visible={showTooltip}>{careerGoalLabel}</Tooltip>
          )}
        </Row>
      )}
      {showCourse && !shouldRenderForStaff && (
        <Row>
          <IconWrapper>
            <Icons.LaptopIcon />
          </IconWrapper>
          <span>{item?.student_course[0]}</span>
        </Row>
      )}

      {showDetailsButton && (
        <DetailsButton
          onClick={() => buttonDetailsFunction && buttonDetailsFunction(item)}
        >
          {detailsButtonText}
        </DetailsButton>
      )}
      {showAdditionalDetailsButton && (
        <GenericButton
          onClick={() =>
            buttonAdditionalDetailsFunction &&
            buttonAdditionalDetailsFunction(item)
          }
        >
          {additionalDetailsButtonText}
        </GenericButton>
      )}
      {showCanceledInfo && (
        <WrapperInformations>
          {item?.status === 'not_realized' && 'Mentoria não realizada'}
          {item?.status === 'canceled' &&
            (item?.canceled_by === 'student'
              ? 'Mentoria cancelada pelo estudante'
              : `Mentoria cancelada por ${item?.canceled_by}`)}
        </WrapperInformations>
      )}
    </Container>
  );
}
