export function UserIcon({ color = '#009291', size = '15px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.23257 7.97591C7.01059 7.97591 7.77114 7.75344 8.41804 7.33663C9.06494 6.91982 9.56914 6.3274 9.86687 5.63427C10.1646 4.94114 10.2425 4.17845 10.0907 3.44263C9.93894 2.70681 9.56429 2.03092 9.01414 1.50042C8.464 0.969928 7.76307 0.608656 7 0.462292C6.23693 0.315929 5.44599 0.391048 4.72719 0.67815C4.00839 0.965252 3.39403 1.45144 2.96178 2.07524C2.52954 2.69904 2.29883 3.43242 2.29883 4.18266C2.29987 5.18838 2.71465 6.15263 3.45214 6.86378C4.18964 7.57494 5.1896 7.9749 6.23257 7.97591ZM6.23257 1.65382C6.75125 1.65382 7.25828 1.80214 7.68955 2.08001C8.12082 2.35788 8.45695 2.75283 8.65544 3.21491C8.85393 3.677 8.90586 4.18546 8.80467 4.67601C8.70348 5.16655 8.45371 5.61715 8.08695 5.97081C7.72019 6.32448 7.25291 6.56532 6.74419 6.6629C6.23548 6.76048 5.70818 6.7104 5.22898 6.51899C4.74979 6.32759 4.34021 6.00347 4.05205 5.5876C3.76388 5.17174 3.61008 4.68281 3.61008 4.18266C3.61008 3.51197 3.88637 2.86875 4.37819 2.3945C4.87 1.92025 5.53704 1.65382 6.23257 1.65382Z"
        fill={color}
      />
      <path
        d="M6.23238 8.65063C4.59347 8.65224 3.02222 9.22825 1.86334 10.2523C0.704456 11.2763 0.0525992 12.6647 0.0507812 14.1129C0.0507813 14.2739 0.123145 14.4283 0.251953 14.5421C0.380761 14.6559 0.555463 14.7198 0.737625 14.7198C0.919787 14.7198 1.09449 14.6559 1.2233 14.5421C1.35211 14.4283 1.42447 14.2739 1.42447 14.1129C1.42447 12.9862 1.93101 11.9056 2.83267 11.1088C3.73433 10.3121 4.95724 9.86448 6.23238 9.86448C7.50751 9.86448 8.73042 10.3121 9.63208 11.1088C10.5337 11.9056 11.0403 12.9862 11.0403 14.1129C11.0403 14.2739 11.1126 14.4283 11.2415 14.5421C11.3703 14.6559 11.545 14.7198 11.7271 14.7198C11.9093 14.7198 12.084 14.6559 12.2128 14.5421C12.3416 14.4283 12.414 14.2739 12.414 14.1129C12.4122 12.6647 11.7603 11.2763 10.6014 10.2523C9.44254 9.22825 7.87128 8.65224 6.23238 8.65063Z"
        fill={color}
      />
    </svg>
  );
}
