import React, { useContext, useEffect, useState, Fragment } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import bag from '../../../../assets/JobOffer/bag.svg';
import {
  Header,
  HeaderTitle,
  Wrapper,
  WrapperPeriod,
  Container,
  WrapperLink,
  SelectiveProcessWrapper,
  WrapperJobInfo,
  ContainerSelectiveProcess,
  InputDescNewJobText,
} from './style';
import Input from '../../../../views/StaffPages/Opportunities/Components/Form/input';
import DatePickerCustom from '../../../StaffPages/Opportunities/Components/Form/datePicker';
import Radio from '../Form/radioInput';
import InputDesc from '../../../StaffPages/Opportunities/Components/Form/inputDesc';
import SearchableSelectUn from '../Form/searchableSelect';
import { JobOfferContext } from '../../Contexts/JobOfferContext';
import {
  colorOrRace,
  disability,
  genderIndentity,
  jobType,
  publicationType,
} from './useCases/SelectOptions';
import {
  createJobOffer,
  editJobOffer,
  getCities,
  getCourses,
  getUniversities,
} from '../../services';

import * as Yup from 'yup';
import { payloadCreateExternalJob } from './helpers/payloadCreateExternalJob';
import { login } from '../../../../services/login/authenticate';
import { schemaValidation } from './useCases/schemaValidationJob';

import {
  IModalConfigCreateCompany,
  IModalConfigCreateJob,
  IModalConfigCreateJobError,
  IModalConfigCreateCompanyError,
  IModalConfigCreateJobInternal,
  IModalConfigCreateJobErrorInternal,
  IModalConfigEditJobInternal,
  IModalConfigEditJobErrorInternal,
} from '../JobOfferForm/Steps/useCases/IModalConfig';
import {
  CheckboxWrapper,
  CompletedButton,
  ContainerButtons,
  ContainerCity,
  ContainerSpecificsUniversities,
  FooterForm,
} from '../JobOfferForm/Steps/style';
import { PreviewButtonExternal } from '../JobOfferForm/Steps/Buttons/PreviewButtonExternal';
import { modalityType } from '../JobOfferForm/Steps/useCases/SelectOptions';
import SearchableSelectCitiesUn from '../Form/searchableSelectCities';
import states from 'variables/states';
import LoadingModal from '../LoadingModal';

export default function ExternalJobOffer() {
  const {
    companyData,
    data: contextData,
    setData,
    ExternalJobRef,
    dataFunction,
    cacheContext,
    handleTabChange,
    activeTab,
    metadata,
    setShowInformationModalMyProfile,
  } = useContext(JobOfferContext);
  const [selectiveProcess, setSelectiveProcess] = useState(false);
  const [justForSomeUniversities, setJustForSomeUniversities] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [isFetchingUniversities, setIsFetchingUniversities] = useState(false);
  const [courses, setCourses] = useState([]);
  const [firstLoadUniversity, setFirstLoadUniversity] = useState(true);

  const [currentModalConfig, setCurrentModalConfig] = useState(
    IModalConfigCreateCompany
  );

  const [modalOpen, setModalOpen] = useState(false);

  const [onUserAuthenticate, setOnUserAuthenticate] = useState({});

  const [showUf, setShowUf] = useState(false);
  const [showLink, setShowLink] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [cities, setCities] = useState([]);
  const [isSearchingCities, setIsSearchingCities] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState([]);

  useEffect(() => {
    if (contextData?.application_type && contextData.application_type.length > 0) {
      setSelectedApplication(contextData.application_type);
      ExternalJobRef.current.setFieldValue(
        'application_type',
        contextData.application_type
      );
    }
  }, [contextData]);

  const handleSelectModality = (e) => {
    const value = e.value;
    if (value === 'hybrid' || value === 'presential') {
      return setShowUf(true);
    }
    setShowUf(false);
    setCities([]);
  };

  const handleSelectPublicationType = (e) => {
    const value = e.value;
    if (value === 'link') {
      setData((prev) => ({
        ...prev,
        job_email: '',
      }));
      setShowLink(true);
      setShowEmail(false);
      ExternalJobRef.current.setFieldValue('job_email', '');
    }
    if (value === 'email') {
      setData((prev) => ({
        ...prev,
        divulgation_link: '',
      }));
      setShowLink(false);
      setShowEmail(true);
      ExternalJobRef.current.setFieldValue('divulgation_link', '');
    }
  };

  useEffect(() => {
    if (contextData && contextData.job_email) {
      ExternalJobRef.current.setFieldValue('publication_type', {
        value: 'email',
        label: 'E-mail de direcionamento',
      });
      setShowLink(false);
      setShowEmail(true);
    }

    if (contextData && contextData.divulgation_link) {
      ExternalJobRef.current.setFieldValue('publication_type', 'link');
      ExternalJobRef.current.setFieldValue('publication_type', {
        value: 'link',
        label: 'Link de direcionamento',
      });
      setShowLink(true);
      setShowEmail(false);
    }
  }, [contextData, cacheContext]);

  const getCurrentCities = async (arrayUfs) => {
    setIsSearchingCities(true);
    const response = await getCities(arrayUfs);
    setCities(response);
    setIsSearchingCities(false);
  };

  const handleSelectUf = (e) => {
    const currentUfs = e.map((uf) => uf.value).join('|');
    getCurrentCities(currentUfs);
  };

  const mapIfDataUf = (ufs) => {
    const currentUfs = ufs.map((uf) => uf).join('|');
    getCurrentCities(currentUfs);
  };

  async function fetchUniversities(courses) {
    setIsFetchingUniversities(true);
    let query = '';
    if (courses && courses.length > 0) {
      query = `courses=${courses.join('&courses=')}`;
    }
    const response = await getUniversities(query);
    if (response?.status === 200) {
      setUniversities(response.data);
    }
    setIsFetchingUniversities(false);
    return response.data;
  }

  useEffect(() => {
    if (contextData.uf) {
      setShowUf(true);
      mapIfDataUf(contextData.uf);
    }
    if (
      contextData.enabled_just_for_universities &&
      contextData.enabled_just_for_universities?.length > 0
    ) {
      setJustForSomeUniversities(true);
    }
  }, [contextData, cacheContext]);

  const radioOptions = [
    {
      id: '1',
      value: true,
      label: `Quero promover a diversidade e inclusão de pessoas
      levando em consideração raça, identidade
      de gênero e pessoa com deficiência.`,
      type: 'checkbox',
    },
  ];

  const handleUserLogin = (loginUser) => {
    if (onUserAuthenticate.isLogged || loginUser.isLogged) {
      companyData.functionToLogin(
        onUserAuthenticate.data ? onUserAuthenticate.data : loginUser.data
      );
    }
  };

  const handleSetModalState = () => {
    setModalOpen(false);
  };

  const returnCurrentFunction = () => {
    if (currentModalConfig === IModalConfigCreateJobErrorInternal) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigCreateJobError) {
      return handleUserLogin();
    }
    if (currentModalConfig === IModalConfigCreateCompanyError) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigEditJobErrorInternal) {
      return handleSetModalState();
    }
  };

  const handleSelectSelectionProcess = (e) => {
    setData((prev) => ({
      ...prev,
      administrative_selection_process: e.target ? e.target.checked : false,
      disability: [],
      color_or_race: [],
      gender_identity: [],
    }));
    ExternalJobRef.current.setFieldValue('disability', []);
    ExternalJobRef.current.setFieldValue('color_or_race', []);
    ExternalJobRef.current.setFieldValue('gender_identity', []);
    if (e.target.checked) {
      setSelectiveProcess(() => true);
      return;
    }
    setSelectiveProcess(() => false);
  };

  useEffect(() => {
    const getCoursesAPI = async () => {
      const response = await getCourses();
      setCourses(response);
    };

    getCoursesAPI();
  }, []);

  const editJob = async (data) => {
    setModalOpen(true);
    setCurrentModalConfig(IModalConfigEditJobInternal);

    const responseEdit = await editJobOffer(
      contextData.jobId,
      payloadCreateExternalJob(data)
    );

    if (responseEdit.message) {
      return setCurrentModalConfig(IModalConfigEditJobErrorInternal);
    }

    if (contextData.isInternalCompany) {
      setModalOpen(false);
      dataFunction.handlePageChange('limit=0');
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }
  };

  const createJob = async (data) => {
    setModalOpen(true);
    setCurrentModalConfig(IModalConfigCreateJobInternal);

    const responseJOB = await createJobOffer(payloadCreateExternalJob(data));

    if (responseJOB.message) {
      return setCurrentModalConfig(IModalConfigCreateJobErrorInternal);
    }

    if (contextData.isInternalCompany) {
      await dataFunction.handlePageChange('limit=0');
      setModalOpen(false);
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }

    if (!contextData.isInternalCompany) {
      handleUserLogin();
    }
  };

  const createCompanyAndPublicateJob = async (data) => {
    setModalOpen(true);

    const loginUrl = '/login/empresa';
    const userData = {
      username: companyData.staff_email,
      password: companyData.staff_password,
    };

    const loginUser = await login(loginUrl, userData);

    setOnUserAuthenticate(loginUser);
    setCurrentModalConfig(IModalConfigCreateJob);

    const responseJOB = await createJobOffer(payloadCreateExternalJob(data));

    if (responseJOB.message) {
      return setCurrentModalConfig(IModalConfigCreateJobError);
    }

    window.location.reload();
  };

  const handleSubmit = async (data) => {
    const formattedData = {
      ...data,
      start_offer_at: moment(data.start_offer_at).format('YYYY-MM-DD'),
      end_offer_at: moment(data.end_offer_at).format('YYYY-MM-DD'),
    };
    ExternalJobRef.current.setErrors({});

    try {
      await schemaValidation.validate(formattedData, {
        abortEarly: false,
      });

      if (!contextData.isInternalCompany) {
        return await createCompanyAndPublicateJob(formattedData);
      }
      if (!contextData.isEditJob) {
        await createJob(formattedData);
        handleTabChange(_, 0);
        if (metadata.is_profile_incomplete) {
          setShowInformationModalMyProfile(true);
        }
        return;
      }

      await editJob(formattedData);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        ExternalJobRef.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (contextData.isEditJob) {
      ExternalJobRef.current.setFieldValue(
        'application_type',
        contextData.application_type
      );
      ExternalJobRef.current.setFieldValue('courses', contextData.courses);
      ExternalJobRef.current.setFieldValue(
        'modality_type',
        contextData.modality_type
      );

      if (contextData.administrative_selection_process) {
        setSelectiveProcess(true);
      }
      fetchUniversities(contextData.courses.map((course) => course.value));
    }
  }, [contextData, cacheContext]);

  function toggleForSpecificsUniversities() {
    setJustForSomeUniversities(!justForSomeUniversities);
    if (justForSomeUniversities) {
      contextData.enabled_just_for_universities = null;
    }
  }

  const onChangeCourses = async (courses) => {
    if (justForSomeUniversities) {
      const coursesIds = courses.map((course) => course.value);
      const universitiesResponse = await fetchUniversities(coursesIds);
      if (!universitiesResponse) return;
      const formUniversities = ExternalJobRef.current.getFieldRef(
        'enabled_just_for_universities'
      );
      if (!formUniversities || !formUniversities.state.value) return;
      const filteredUniversities = formUniversities.state.value.filter(
        (university) => {
          return universitiesResponse.some(
            (universityResponse) =>
              universityResponse.value === university.value
          );
        }
      );
      ExternalJobRef.current.setFieldValue(
        'enabled_just_for_universities',
        filteredUniversities
      );
    }
  };

  useEffect(() => {
    if (justForSomeUniversities) {
      const courses = ExternalJobRef.current.getFieldValue('courses');
      fetchUniversities(courses);
    }
  }, [justForSomeUniversities]);

  useEffect(() => {
    if (
      contextData &&
      contextData.enabled_just_for_universities &&
      firstLoadUniversity
    ) {
      if (contextData.enabled_just_for_universities.length > 0) {
        if (justForSomeUniversities) {
          ExternalJobRef.current.setFieldValue(
            'enabled_just_for_universities',
            contextData.enabled_just_for_universities
          );
          setFirstLoadUniversity(false);
        }
      } else {
        setFirstLoadUniversity(false);
      }
    }
  }, [contextData, firstLoadUniversity, justForSomeUniversities]);

  useEffect(() => {
    if (selectedApplication.length > 1) {
      const lastOption = selectedApplication[selectedApplication.length - 1];
      setSelectedApplication([lastOption]);
      ExternalJobRef.current.setFieldValue('application_type', [lastOption]);
    }
  }, [selectedApplication]);

  const handleApplicationTypeSelect = (options) => {
    setSelectedApplication(options);
  };

  return (
    <Fragment>
      <LoadingModal
        modalOpenState={modalOpen}
        config={currentModalConfig}
        buttonFunction={returnCurrentFunction}
      />

      <Wrapper>
        <Form
          ref={ExternalJobRef}
          onSubmit={handleSubmit}
          initialData={contextData}
        >
          <Header>
            <img src={bag} />
            <HeaderTitle>Divulgar processo seletivo</HeaderTitle>
          </Header>
          <p>
            Agora só falta completar as <strong>informações específicas</strong>{' '}
            de sua vaga.
          </p>

          <WrapperPeriod>
            <h5>Período de Divulgação*</h5>
            <Container>
              <DatePickerCustom
                minDate={new Date()}
                name="start_offer_at"
                placeholder="De"
              />
              <DatePickerCustom
                minDate={new Date()}
                name="end_offer_at"
                placeholder="Até"
              />
            </Container>
          </WrapperPeriod>

          <WrapperLink>
            <h5>Como você deseja divulgar sua vaga:</h5>
            <SearchableSelectUn
              name="publication_type"
              label="Tipo de publicação*"
              options={publicationType}
              onChange={handleSelectPublicationType}
            />

            {showLink && (
              <Input
                name="divulgation_link"
                placeholder="Insira o link que o estudante irá acessar ao se candidatar"
              />
            )}
            {showEmail && (
              <Input
                name="job_email"
                placeholder="Insira o e-mail que o estudante irá acessar ao se candidatar"
              />
            )}
          </WrapperLink>

          <hr />

          <WrapperJobInfo>
            <InputDescNewJobText>
              <Input
                name="name"
                placeholder="Digite aqui..."
                label="Nome da vaga*"
              />
            </InputDescNewJobText>
            <InputDescNewJobText>
              <InputDesc
                name="description"
                placeholder="Digite aqui"
                label="Descrição da vaga"
              />
            </InputDescNewJobText>

            <SearchableSelectUn
              name="application_type"
              label="Tipo de vaga*"
              isClearable
              isMulti
              value={selectedApplication}
              onChange={handleApplicationTypeSelect}
              options={jobType}
            />

            <SearchableSelectUn
              name="modality_type"
              label="Modalidade de trabalho*"
              options={modalityType}
              onChange={handleSelectModality}
            />

            {showUf && (
              <ContainerCity>
                <SearchableSelectUn
                  name="uf"
                  options={states}
                  label="UF*"
                  isClearable
                  isMulti
                  onChange={handleSelectUf}
                />
                <SearchableSelectCitiesUn
                  name="locations"
                  options={cities}
                  label="Cidade*"
                  isCleareble
                  getOptionLabel={(options) => options['nome']}
                  getOptionValue={(options) => options['id']}
                  isMulti
                  isDisabled={isSearchingCities}
                  isLoading={isSearchingCities}
                />
              </ContainerCity>
            )}

            <SearchableSelectUn
              name="courses"
              label="Cursos"
              isClearable
              isMulti
              options={courses}
              onChange={onChangeCourses}
            />
          </WrapperJobInfo>
          <CheckboxWrapper>
            <Radio
              name="just_for_some_universities"
              options={[
                {
                  id: '2',
                  value: true,
                  label:
                    'Desejo publicar a vaga para alunos de instituições' +
                    ' de ensino específicas',
                  type: 'checkbox',
                },
              ]}
              onChange={toggleForSpecificsUniversities}
              checked={justForSomeUniversities}
            />
          </CheckboxWrapper>
          {justForSomeUniversities && (
            <ContainerSpecificsUniversities>
              <p>Selecione a(s) instituição(ões) de ensino desejadas.</p>
              <SearchableSelectUn
                name="enabled_just_for_universities"
                options={universities}
                isDisabled={isFetchingUniversities}
                isLoading={isFetchingUniversities}
                isCleareble
                isMulti
              />
            </ContainerSpecificsUniversities>
          )}
          <SelectiveProcessWrapper hasMargin={!justForSomeUniversities}>
            <h5>Processo Seletivo Afirmativo </h5>
            <Radio
              name="administrative_selection_process"
              options={radioOptions}
              onChange={handleSelectSelectionProcess}
              checked={selectiveProcess}
            />

            {selectiveProcess && (
              <ContainerSelectiveProcess>
                <SearchableSelectUn
                  name="disability"
                  isClearable
                  isMulti
                  label="PCD - Selecione possíveis deficiências"
                  options={disability}
                />
                <SearchableSelectUn
                  name="color_or_race"
                  isClearable
                  label="Raça/Cor"
                  isMulti
                  options={colorOrRace}
                />
                <SearchableSelectUn
                  name="gender_identity"
                  isClearable
                  isMulti
                  label="Identidade de Gênero"
                  options={genderIndentity}
                />
              </ContainerSelectiveProcess>
            )}
          </SelectiveProcessWrapper>
          <FooterForm>
            <ContainerButtons>
              <PreviewButtonExternal
                courses={courses}
                handleSubmit={handleSubmit}
                universities={universities}
              >
                Pré-Visualizar Vaga
              </PreviewButtonExternal>
              <CompletedButton> Publicar </CompletedButton>
            </ContainerButtons>
          </FooterForm>
        </Form>
      </Wrapper>
    </Fragment>
  );
}
