export function IconArrowEdit({ color }) {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.73488 4.56829L1.40383 0.2449C1.33269 0.173179 1.24806 0.116253 1.15481 0.0774042C1.06157 0.0385559 0.961551 0.0185547 0.860536 0.0185547C0.75952 0.0185547 0.659503 0.0385559 0.566256 0.0774042C0.473009 0.116253 0.388377 0.173179 0.317242 0.2449C0.174722 0.38827 0.0947266 0.582212 0.0947266 0.784368C0.0947266 0.986524 0.174722 1.18047 0.317242 1.32384L4.10499 5.14985L0.317242 8.9376C0.174722 9.08097 0.0947266 9.27491 0.0947266 9.47707C0.0947266 9.67922 0.174722 9.87317 0.317242 10.0165C0.388111 10.0888 0.472623 10.1464 0.565883 10.1858C0.659142 10.2252 0.759294 10.2457 0.860536 10.2461C0.961777 10.2457 1.06193 10.2252 1.15519 10.1858C1.24845 10.1464 1.33296 10.0888 1.40383 10.0165L5.73488 5.69314C5.81255 5.62149 5.87454 5.53452 5.91693 5.43772C5.95933 5.34092 5.98122 5.23639 5.98122 5.13072C5.98122 5.02504 5.95933 4.92051 5.91693 4.82371C5.87454 4.72692 5.81255 4.63995 5.73488 4.56829Z"
        fill={color}
      />
    </svg>
  );
}
