import React, { useEffect, useMemo, useState, useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import {
  ChipButton,
  InputLabel,
  LabelWrapper,
  SelectedContainer,
  SelectWrapper,
  Error,
  Button,
} from './style';
import { CloseIcon } from 'assets/JobOffer/Close';
import { useField } from '@unform/core';

export default function MultiSelect({
  label,
  name,
  options,
  placeholder,
  isOptional,
  onChange,
  selectedOptions,
  setSelectedOptions,
  isDisabled,
  ...rest
}) {
  const [value, setValue] = useState(null);
  const [optionsToShow, setOptionsToShow] = useState(10);

  const inputRef = useRef(null);

  const unselectedOptions = useMemo(() => {
    if (!Array.isArray(options)) {
      return [];
    }
    return options?.filter(
      (option) => !selectedOptions?.find((o) => o.value === option.value)
    );
  }, [options, selectedOptions]);

  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state.value',
      getValue: () => {
        return selectedOptions.map((option) => option.value);
      },
      setValue: (ref, values) => {
        return setSelectedOptions(values);
      },
    });
  }, [fieldName, registerField, selectedOptions]);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: '192px',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Não há mais opções para selecionar</span>
      </components.NoOptionsMessage>
    );
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#9FD6D6',
        primary50: '#80C9C8',
        primary: '#009291',
      },
    };
  }

  return (
    <SelectWrapper>
      {label && (
        <LabelWrapper>
          <InputLabel>{label}</InputLabel>
          {isOptional && <span> (OPCIONAL)</span>}
        </LabelWrapper>
      )}
      <ReactSelect
        error={error}
        placeholder={'Selecione'}
        isDisabled={isDisabled}
        options={unselectedOptions}
        value={value}
        components={{ NoOptionsMessage }}
        classNamePrefix="react-select"
        theme={selectTheme}
        onChange={(selected) => {
          if (selected && options) {
            setValue(null);
            const newSelectedOptions = [...selectedOptions, ...selected];
            if (onChange) onChange(newSelectedOptions);
            setSelectedOptions(newSelectedOptions);
          }
        }}
        styles={{
          control: (provided, state) => ({
            ...provided,
            backgroundColor: isDisabled
              ? '#eeeeee'
              : state.selectProps.error
              ? '#FFEDED'
              : '#FFFF',
            border: isDisabled
              ? '1px solid #D8D8D8'
              : state.selectProps.error
              ? '1px solid #FFCECE'
              : '1px solid #d0d0d0',
          }),
          ...selectStyles,
        }}
        {...rest}
      />
      {error && <Error>{error}</Error>}{' '}
      {selectedOptions?.length > 0 && (
        <SelectedContainer>
          {selectedOptions.slice(0, optionsToShow).map((item) => (
            <ChipButton
              key={item.value}
              onClick={() => {
                const newSelectedOptions = selectedOptions.filter(
                  (option) => option !== item
                );
                if (onChange) onChange(newSelectedOptions);
                setSelectedOptions(newSelectedOptions);
              }}
            >
              <p>{item.label}</p>
              <CloseIcon color={'#009291'} />
            </ChipButton>
          ))}
        </SelectedContainer>
      )}
      {selectedOptions.length > optionsToShow && (
        <Button onClick={() => setOptionsToShow(optionsToShow + 10)}>
          Ver mais
        </Button>
      )}
    </SelectWrapper>
  );
}
