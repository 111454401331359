export function Translate({ color }) {
  return (
    <svg
      width="28"
      height="19"
      viewBox="0 0 28 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.2196 0.479492H6.0989C2.91966 0.479492 0.341492 3.05651 0.341492 6.2369V13.1458C0.341492 16.325 2.91966 18.9032 6.0989 18.9032H22.2196C25.3943 18.9032 27.977 16.3204 27.977 13.1458V6.2369C27.977 3.06226 25.3943 0.479492 22.2196 0.479492ZM9.69152 14.2973C9.31613 14.2973 8.99026 14.0382 8.90621 13.672L8.60106 12.3397H5.82715L5.51164 13.6766C5.42528 14.0405 5.10171 14.2973 4.72748 14.2973C4.20816 14.2973 3.82472 13.8125 3.94332 13.307L5.57037 6.40616C5.80181 5.40898 6.8796 4.78718 7.93781 5.2305C8.44331 5.44238 8.783 5.9283 8.90736 6.46259L10.4768 13.3116C10.592 13.8159 10.2097 14.2973 9.69152 14.2973ZM25.6741 13.1458C25.6741 15.0503 24.1242 16.6002 22.2196 16.6002H14.1593V2.78245H22.2196C24.1242 2.78245 25.6741 4.33235 25.6741 6.2369V13.1458ZM23.9468 6.94621V6.97039C23.9468 7.36189 23.629 7.6797 23.2375 7.6797H22.8737C22.7343 9.14784 22.1632 10.8244 21.0232 12.1313C21.6473 12.5102 22.3958 12.7808 23.3078 12.8671C23.6705 12.9017 23.9468 13.208 23.9468 13.573V13.5972C23.9468 14.0186 23.583 14.3433 23.1638 14.303C21.8546 14.1775 20.7929 13.7399 19.9305 13.1262C19.0634 13.7457 17.9891 14.1798 16.6695 14.303C16.2504 14.3422 15.8876 14.0175 15.8876 13.596V13.5718C15.8876 13.2022 16.1732 12.8994 16.5417 12.8648C17.449 12.7785 18.1975 12.5113 18.8182 12.1336C18.437 11.6972 18.1192 11.2194 17.859 10.7208C17.6137 10.251 17.9615 9.68904 18.4911 9.68904H18.5026C18.7629 9.68904 19.0058 9.82952 19.1256 10.0598C19.3386 10.4651 19.6046 10.8555 19.9236 11.2136C20.8655 10.1542 21.2961 8.78628 21.424 7.6774H16.5981C16.2066 7.6774 15.8888 7.35959 15.8888 6.96809V6.94391C15.8888 6.5524 16.2066 6.23459 16.5981 6.23459H19.197V5.79243C19.197 5.40092 19.5148 5.08311 19.9063 5.08311H19.9305C20.322 5.08311 20.6398 5.40092 20.6398 5.79243V6.23459H23.2387C23.6302 6.23459 23.948 6.5524 23.948 6.94391L23.9468 6.94621ZM7.32522 6.77003L8.23144 10.7277H6.20829L7.14214 6.77003C7.1525 6.72743 7.18935 6.69749 7.2331 6.69749C7.27686 6.69749 7.31601 6.72743 7.32522 6.77003Z"
        fill={color}
      />
    </svg>
  );
}
