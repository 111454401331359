import React, { Fragment } from 'react';

import styled, { keyframes } from 'styled-components';

export default function ImageSkeleton() {
  return <ContentSkeleton />;
}
const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

const ContentSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #d9d6d6;
  background-image: linear-gradient(
    120deg,
    #d9d6d6 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #d9d6d6 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  height: 100%;
  border-radius: 5px;
  width: 100%;

  margin: 30px 0;
`;
