import styled from 'styled-components';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 500px;
  min-height: 200px;
  padding: 32px;

  background-color: #eeeeee;

  header {
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 24px;
    position: relative;

    button {
      position: absolute;

      transform: rotate(45deg);
      font-size: 36px;
      font-weight: 600;

      max-height: 1rem;
      max-width: 1rem;

      margin: 0;
      padding: 0;

      top: -36px;
      right: -24px;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 16px;
  }

  button {
    border-style: none;
    border-radius: 8px;
    width: 200px;
    height: 30px;
    padding: 6px 12px 6px 12px;
    margin-top: 16px;

    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-weight: 400;

    font-size: 16px;

    transition: opacity 0.4s;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  button + button {
    font-weight: bold;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 500px) {
      flex-direction: column;
    }
  }
`;
