import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';

const updatePortfolioProjects = async (data) => {
  const response = await axios
    .patch(
      `${URLS.API_URL}/api/internal/students/me/portfolio/${data.id}`,
      data,
      {
        withCredentials: true,
        headers: {
          'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deletePortfolioProject = async (projectId) => {
  const response = await axios
    .delete(`${URLS.API_URL}/api/internal/students/me/portfolio/${projectId}`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const savePortfolioProjects = async (data) => {
  const response = await axios
    .post(`${URLS.API_URL}/api/internal/students/me/portfolio`, data, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptions = async () => {
  const response = await axios
    .options(`${URLS.API_URL}/api/internal/students/me/portfolio`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProjects = async () => {
  const response = await axios
    .get(`${URLS.API_URL}/api/internal/students/me/projects`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data.projects)
    .catch((error) => error);

  return response;
};

export {
  deletePortfolioProject,
  getOptions,
  updatePortfolioProjects,
  savePortfolioProjects,
  getProjects,
};
