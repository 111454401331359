import GenericMessage from 'components/GenericMessage';
import {
  ContainerDescriptions,
  ContainerStars,
  Descriptions,
  Star,
} from '../styled';
import ReactHtmlParser from 'react-html-parser';

export default function About({ data }) {
  const {
    about_us,
    vision,
    values,
    mission,
    video_url,
    general_course_index,
  } = data;

  const createStars = Array.from({ length: 5 }).map((_, index) => (
    <Star active={index + 1 <= general_course_index}>&#9733;</Star>
  ));

  const youTubeIdFromLink = (url) =>
    url.match(
      /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/
    )[1];

  if (!about_us && !vision && !values && !mission && !video_url) {
    return (
      <GenericMessage
        title="Nenhuma informação encontrada"
        subtitle="A instituição de ensino ainda não realizou o preenchimento desta informação."
      />
    );
  }

  return (
    <ContainerDescriptions>
      {general_course_index > 0 && (
        <ContainerStars>
          <span>Nota MEC</span>
          {createStars}
        </ContainerStars>
      )}

      {video_url && (
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${youTubeIdFromLink(video_url)}`}
        ></iframe>
      )}

      {about_us && (
        <Descriptions>
          <h2>Sobre nós</h2>
          {ReactHtmlParser(about_us)}
        </Descriptions>
      )}

      {mission && (
        <Descriptions>
          <h2>Missão</h2>
          {ReactHtmlParser(mission)}
        </Descriptions>
      )}
      {vision && (
        <Descriptions>
          <h2>Visão</h2>
          {ReactHtmlParser(vision)}
        </Descriptions>
      )}
      {values && (
        <Descriptions>
          <h2>Valores</h2>
          {ReactHtmlParser(values)}
        </Descriptions>
      )}
    </ContainerDescriptions>
  );
}
