import styled from 'styled-components';

export const Information = styled.div`
  > span {
    display: flex;
    gap: 8px;
    color: #009291;
    font-weight: 500;

    > p {
      color: #606062;
      font-weight: 300;
    }
  }

  border-bottom: 1px solid #eef2f4;
  margin-bottom: 10px;
`;

export const ContainerMail = styled.div`
  padding: 10px 10px;
  display: flex;
  gap: 20px;
`;

export const Email = styled.div`
  width: 700px;
  height: 517px;
  margin-top: -27px;
  height: auto;
  overflow: hidden;

  > #email {
    width: 200px;
  }
`;

export const Metrics = styled.div`
  width: 500px;
  height: 517px;
  margin-top: 5px;

  > div {
    > p {
      margin-top: 8px;
    }

    > span {
      font-size: 16px;
      display: flex;
      align-items: center;
      color: #009291;
      text-align: center;
      font-weight: 400;
      gap: 10px;
      margin-bottom: 10px;

      > img {
        width: 20px;
      }
    }
  }
`;

export const WrapperCardCount = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

export const CardCount = styled.div`
  min-width: 165px;
  height: 90px;
  border-radius: 4px;
  background-color: #fbfbfb;
  border: 1px solid #eef2f4;
  padding: 0px 14px;

  > p {
    margin-top: 10px;
    color: #009291;
    font-weight: 400;
  }

  h3 {
    font-weight: 700;
    color: #4b4b4d;
    display: flex;
    align-items: baseline;
    gap: 5px;
    font-size: 32px;
    margin: 0px;

    > sub {
      font-weight: 400;
      font-size: 15px;
    }
    > span {
      margin-left: auto;
      border: 1px solid #009291;
      border-radius: 50%;
      color: #009291;
      width: 15px;
      height: 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      position: relative;
      cursor: pointer;
    }
  }
  &:hover > div {
    display: block;
  }

  > div {
    display: none;
    z-index: 10;
    max-width: 170px;
    min-height: 50px;
    height: auto;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    color: #ffffff;
    border: solid 1px #eef2f4;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    margin-left: -12px;
    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 6px 7px;
      border-color: rgba(0, 0, 0, 0.75) transparent;
      display: block;
      width: 0;
      z-index: 1;
      top: -5px;
      left: 9.5em;
    }
  }
`;
export const CardInactiveData = styled.div`
  max-width: 300px;
  max-height: 90px;
  border-radius: 4px;
  background-color: #fbfbfb;
  border: 1px solid #eef2f4;
  padding: 15px;
  margin-top: 15px;
`;
