export function IconFireMatch({ color }) {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43131 1.90896C6.91706 1.47217 6.38539 1.02117 5.85189 0.488125L5.36239 0L4.87702 0.488583C4.14996 1.28269 3.62805 2.24249 3.35673 3.28442C3.34985 3.26058 3.34344 3.23858 3.33702 3.21246L3.04644 2.08633L2.19989 2.88108C1.34969 3.67812 0.564104 4.63191 0.563646 6.20216C0.554706 7.26236 0.900304 8.29515 1.54554 9.13644C2.19077 9.97773 3.09863 10.5793 4.12489 10.8455C4.52791 10.9477 4.94204 10.9996 5.35781 11C5.98832 11.001 6.61284 10.8776 7.19562 10.6369C7.77841 10.3963 8.30802 10.0431 8.75416 9.59755C9.2003 9.15201 9.5542 8.62287 9.79563 8.04041C10.0371 7.45794 10.1613 6.83359 10.1611 6.20308C10.1611 4.22675 8.89568 3.15241 7.43131 1.90896ZM7.46293 8.90358C7.41297 8.94208 7.35981 8.97416 7.30848 9.00991C7.41416 8.75521 7.46866 8.4822 7.46889 8.20645C7.46889 7.04275 6.41473 6.57845 5.36056 5.52521C4.21152 6.67425 3.25223 7.04275 3.25223 8.20645C3.25432 8.49845 3.31739 8.78679 3.43739 9.05299C2.97338 8.73692 2.59422 8.31157 2.33334 7.81442C2.07246 7.31727 1.93786 6.76359 1.94139 6.20216C1.92976 5.63122 2.11363 5.07346 2.46252 4.62137C2.51248 4.70021 2.56519 4.77629 2.6211 4.85054C2.7409 5.01233 2.90537 5.13558 3.09429 5.20512C3.28321 5.27466 3.48833 5.28746 3.68444 5.24196C3.88336 5.19756 4.06527 5.09678 4.20842 4.95169C4.35156 4.8066 4.44986 4.62334 4.49156 4.42383C4.65362 3.56168 4.97089 2.73612 5.42793 1.98733C5.81477 2.343 6.19335 2.66429 6.53985 2.959C8.01477 4.21071 8.78568 4.92387 8.78568 6.20308C8.78687 6.72447 8.66821 7.23916 8.43885 7.7074C8.2095 8.17564 7.8756 8.5849 7.46293 8.90358Z"
        fill={color}
      />
    </svg>
  );
}
