import React, { useContext } from "react";

import Button from "components/CustomButtons/Button.jsx";
import Notifications from "@material-ui/icons/Notifications";

import withStyles from "@material-ui/core/styles/withStyles";
import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

import AuthContext from "contexts/authentication";
import { object, func } from "prop-types";

const NotificationButton = props => {
  const auth = useContext(AuthContext);
  const { classes, toggleNotificationDrawer } = props;

  return (
    <div className={classes.headerButton}>
      {auth.isLogged ? (
        <Button
          color="transparent"
          round
          justIcon
          aria-label="open drawer"
          onClick={toggleNotificationDrawer}
          className={classes.notificationButton}
        >
          <Notifications className={classes.icons} />
        </Button>
      ) : null}
    </div>
  );
};

NotificationButton.propTypes = {
  classes: object.isRequired,
  toggleNotificationDrawer: func
};

export default withStyles(headerLinksStyle)(NotificationButton);
