import { api, authenticatedAPI } from 'services/api';

function getEnvironmentUrl(metadata) {
  let url;
  switch (metadata.role) {
    case 'company':
      url = '/api/internal/companies/me/use-terms';
      break;
    default:
      url = '/api/internal/me/use-terms';
      break;
  }
  return url;
}

const getTerm = async (metadata) => {
  const response = authenticatedAPI
    .get(getEnvironmentUrl(metadata))
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTermCompanyNotAuthenticate = async () => {
  const response = api
    .get('/api/internal/companies/use-terms')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const termAccept = async (metadata) => {
  const response = authenticatedAPI
    .post(getEnvironmentUrl(metadata))
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getTerm, termAccept, getTermCompanyNotAuthenticate };
