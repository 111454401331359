import React, { Fragment } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import MyProfileContext from 'contexts/my-profile';
import CompanyContext from 'contexts/company';
import ProfileProvider from 'views/Profile/Context/ProfileContext';
import CurriculumContextProvider from 'contexts/CurriculumContext';

const mergeContextsHelper = (props) => {
  const Component = typeof props.type === 'function' ? props.type : props;
  return (
    <Fragment>
      <CompanyContext.Consumer>
        {(contextCompany) => (
          <MyProfileContext.Consumer>
            {(contextMyProfile) => {
              return (
                <ProfileProvider>
                  <CurriculumContextProvider>
                    <BaseLayoutContext.Consumer>
                      {({
                        closeModal,
                        handleIsLoadingState,
                        openModalWithContent,
                        openDnaDialog,
                        openSnackbar,
                        metadata,
                        academic_profile,
                        generateLinkJob,
                      }) => (
                        <Component
                          {...contextCompany}
                          {...contextMyProfile}
                          {...props.props}
                          handleIsLoadingState={handleIsLoadingState}
                          openModalWithContent={openModalWithContent}
                          closeModal={closeModal}
                          openDnaDialog={openDnaDialog}
                          openSnackbar={openSnackbar}
                          metadata={metadata}
                          academicProfile={academic_profile}
                          generateLinkJob={generateLinkJob}
                        />
                      )}
                    </BaseLayoutContext.Consumer>
                  </CurriculumContextProvider>
                </ProfileProvider>
              );
            }}
          </MyProfileContext.Consumer>
        )}
      </CompanyContext.Consumer>
    </Fragment>
  );
};

export default mergeContextsHelper;
