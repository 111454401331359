import Pagination from 'components/CustomPagination';
import styled from 'styled-components';

const isIphone = !!navigator.userAgent.match(/iPhone/i);

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 40px 40px 40px;

  .paginationContainer {
    display: flex;
    height: ${isIphone ? '46px' : '42px'};
    margin-bottom: 2.5em;
  }

  .quickFilters {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 100%;
    margin-bottom: 16px;
    .quickFilter {
      padding: 10px;
      border: #dadada 1px solid;
      border-radius: 45px;
      background: #fff;
      outline: none;
      color: #606060;
      font-size: 16px;
      margin-right: 8px;
      cursor: pointer;

      &.active {
        background-color: #009291;
        color: #fff;
      }
    }
  }
`;

export const Paginate = styled(Pagination)`
  padding-top: 30px;
  margin: 0px;
  background: #eeeeee !important;
  .wrapper {
    background: #eeeeee !important;
  }
`;
