import React, { useState, useRef, useEffect } from 'react';
import ModalStudentCancelInterview from './ModalStudentCancelInterview';
import { Form } from '@unform/web';
import {
  ContainerModalInterview,
  BackgroundModals,
  HeaderModalInterview,
  ButtonClose,
  ParagraphInterviewUnsheduled,
  ParagraphInterview,
  BodyModalInterview,
  ContainerInviteInterview,
  StatusImage,
  ContainerInput,
  Button,
  ContainerButtons,
} from '../../styled';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';
import SimpleInput from 'components/UnformFields/SimpleInput';
import SimpleTextareaInput from 'components/UnformFields/SimpleTextareaInput';
import SkeletonModalsInterview from '../../GenericComponents/SkeletonCards/SkeletonModalsInterview';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function ModalUnscheduledInterview(props) {
  const {
    interview,
    interviewId,
    universityColor,
    handleCloseModal,
    typeInterview,
    updateInterview,
    applicationId,
    setApplication,
    setInterview,
    setTypeInterview,
    fetchListApplicationJobOffers,
  } = props;
  const [cancelInterview, setCancelInterview] = useState(false);
  const [showUnscheduledInterview, setShowUnscheduledInterview] = useState(
    true
  );
  const { iconUnscheduled } = wrapperIconsJobOffers();
  const [loading, setLoading] = useState(false);
  const interviewRef = useRef('');

  const initialValues = {
    ...interview,
    is_online: 'Online',
    in_person: 'Presencial',
  };

  function changeInterviewUpdate(response) {
    setApplication({ ...applicationId, interviews: [response] });
  }

  async function handleScheduled() {
    setLoading(true);
    const response = await updateInterview(applicationId, {
      id: interviewId,
      status: 'scheduled',
    });
    if (!response.message) {
      response.applicationId = applicationId;
      changeInterviewUpdate(response);
      customSnackbar('Confirmação realizada com sucesso!', 'confirmation');
    } else {
      customSnackbar(
        'Não foi possível realizar a confirmação. Tente novamente mais tarde!',
        'error'
      );
    }
    fetchListApplicationJobOffers && (await fetchListApplicationJobOffers());
    setLoading(false);
    setShowUnscheduledInterview(false);
  }

  const openModalCancelInterview = () => {
    setCancelInterview(true);
    setShowUnscheduledInterview(false);
  };

  const closeCancelInterview = () => {
    setCancelInterview(false);
  };

  const returnInterviewStatus = () => {
    if (interview.status === 'scheduled') {
      return 'Detalhes da entrevista';
    }
    return 'Convite para entrevista';
  };

  const isPersonModal = interview.in_person === true;

  return (
    <>
      {loading || (interviewId === null && <SkeletonModalsInterview />)}
      {cancelInterview && (
        <ModalStudentCancelInterview
          universityColor={universityColor}
          handleCloseModal={closeCancelInterview}
          interview={interview}
          interviewId={interviewId}
          typeInterview={typeInterview}
          setInterview={setInterview}
          applicationId={applicationId}
          setApplication={setApplication}
          setTypeInterview={setTypeInterview}
          fetchListApplicationJobOffers={fetchListApplicationJobOffers}
        />
      )}
      {showUnscheduledInterview && (
        <BackgroundModals>
          <ContainerModalInterview isPerson={isPersonModal} isInterview>
            <HeaderModalInterview universityColor={universityColor}>
              <ParagraphInterview>Detalhes da entrevista</ParagraphInterview>

              <ButtonClose onClick={handleCloseModal}>X</ButtonClose>
            </HeaderModalInterview>

            <BodyModalInterview>
              <ContainerInviteInterview status={interview.status}>
                <StatusImage src={iconUnscheduled} />
                <ParagraphInterviewUnsheduled
                  isInterview
                  status={interview.status}
                >
                  {returnInterviewStatus()}
                </ParagraphInterviewUnsheduled>
              </ContainerInviteInterview>

              <Form
                ref={interviewRef}
                initialData={initialValues}
                onSubmit={handleScheduled}
              >
                {interview.is_online === true && (
                  <>
                    <ContainerInput>
                      <SimpleInput
                        name="is_online"
                        label="Formato da entrevista"
                        disabled
                      />
                    </ContainerInput>

                    <ContainerInput>
                      <SimpleInput name="access_link" label="Link*" disabled />
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_date"
                          label="Data*"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_hour"
                          label="Horário*"
                          disabled
                        />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput>
                      <SimpleTextareaInput
                        name="description"
                        label="Recomendações*"
                        disabled
                      />
                    </ContainerInput>
                  </>
                )}

                {interview.in_person === true && (
                  <>
                    <ContainerInput>
                      <SimpleInput
                        name="in_person"
                        label="Formato da entrevista"
                        disabled
                      />
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput name="zipCode" label="CEP*" disabled />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput name="district" label="Bairro*" disabled />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput
                          name="street"
                          label="Rua/Avenida*"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="addressNumber"
                          label="Número*"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="complement"
                          label="Complemento"
                          disabled
                        />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput name="city" label="Cidade*" disabled />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput name="addressState" label="UF*" disabled />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_date"
                          label="Data*"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_hour"
                          label="Horário*"
                          disabled
                        />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput>
                      <SimpleTextareaInput
                        name="description"
                        label="Recomendações*"
                        disabled
                      />
                    </ContainerInput>
                  </>
                )}

                {interview.status === 'unscheduled' ||
                interview.status === 'rescheduled' ? (
                  <ContainerButtons>
                    <Button
                      universityColor={universityColor}
                      onClick={() => openModalCancelInterview()}
                      type="button"
                      isCancel
                    >
                      Cancelar entrevista
                    </Button>

                    <Button universityColor={universityColor} type="submit">
                      Confirmar entrevista
                    </Button>
                  </ContainerButtons>
                ) : (
                  <ContainerButtons>
                    <Button
                      universityColor={universityColor}
                      onClick={() => openModalCancelInterview()}
                      type="button"
                      isCancel
                    >
                      Cancelar
                    </Button>
                  </ContainerButtons>
                )}
              </Form>
            </BodyModalInterview>
          </ContainerModalInterview>
        </BackgroundModals>
      )}
    </>
  );
}
