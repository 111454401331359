import React, { useRef, useEffect, useState, useContext } from 'react';
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select';
import { useField } from '@unform/core';
import { ContainerLabelInput } from '../styles/styleForm';
import { UnInputLabel } from '../styles/unformCompany';

import { OpportunitiesCompanyContext } from '../../../views/Company/OpportunitiesCompany/context/OpportunitiesCompanyContext';

export default function SearchableSelectCompany({
  label,
  name,
  options,
  placeholder,
  ...rest
}) {
  const selectRef = useRef(null);
  const { cacheOptions } = useContext(OpportunitiesCompanyContext);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) { 
            return [];
          }
            return ref.state.value.map((option) => option.value);
        } else {
          if (!ref.state.value) { 
            return '';
          }
            return ref.state.value.value;
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  
  return (
    <ContainerLabelInput>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <ReactSelect
        defaultValue={
          defaultValue && Array.isArray(defaultValue) ? 
            defaultValue.map((item) => 
              cacheOptions.find((option) => 
                option.value === item || 
                option.value === item.value 
              ))
            : 
          options.find((option) => 
            String(option.value) === String(defaultValue) || 
            (defaultValue && String(option.value) === String(defaultValue.value))
          )
        }
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </ContainerLabelInput>
  );
}
