import styled from 'styled-components';

export const ContainerPDF = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;

  .Document {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;
