import React, { Fragment, useState } from 'react';
import { shape, number, bool, func } from 'prop-types';

import { IconButton, CircularProgress } from '@material-ui/core';
import { ThumbUpAltOutlined, ThumbUpAlt } from '@material-ui/icons';

import withStyles from '@material-ui/core/styles/withStyles';
import applicationReturnStyle from 'assets/jss/material-dashboard-pro-react/components/applicationReturnStyle';

import { handleLike } from 'services/company/students';

const Like = (props) => {
  const { classes, studentId, hasLiked, updateLiked } = props;
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(hasLiked);

  return (
    <div className={`${classes.curriculumMenu} ${classes.choicesMenu}`}>
      <p>Esse currículo é interessante para sua empresa?</p>
      {loading && <CircularProgress className={classes.loading} />}
      {!loading && (
        <Fragment>
          <div className={classes.choicesContainer}>
            <div className={classes.applicationButtonContainer}>
              <IconButton
                disableRipple
                onClick={async () => {
                  if (!liked) {
                    setLoading(true);
                    const { curriculums, id, email, mobile_phone } =
                      await handleLike(studentId);
                    setLiked(curriculums[0].liked_by.length > 0);
                    setLoading(false);
                    updateLiked({ curriculums, id });
                  }
                }}
              >
                {liked ? (
                  <ThumbUpAlt className={classes.iconGreen} />
                ) : (
                  <ThumbUpAltOutlined className={classes.iconGreen} />
                )}
              </IconButton>
              <small>{liked ? 'Curtido' : 'Curtir'}</small>
            </div>
          </div>
          {!liked && (
            <small className={classes.choiceInfo}>
              Os dados de contatos só serão disponibilizados nos currículos que
              você curtiu.
            </small>
          )}
        </Fragment>
      )}
    </div>
  );
};

Like.propTypes = {
  classes: shape.isRequired,
  studentId: number.isRequired,
  hasLiked: bool.isRequired,
  updateLiked: func.isRequired,
};

export default withStyles(applicationReturnStyle)(Like);
