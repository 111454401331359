export function IconUnliked() {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2447 8.33355L15.4791 8.88774L16.0785 8.94019L22.3656 9.49032L17.611 13.6081L17.1554 14.0027L17.2915 14.5899L18.7132 20.7215L13.3224 17.4679L12.8052 17.1557L12.2883 17.4682L6.90684 20.7215L8.32013 14.5886L8.45527 14.0021L8.00033 13.6081L3.24571 9.49032L9.53283 8.94019L10.1323 8.88774L10.3667 8.33355L12.8057 2.56707L15.2447 8.33355Z"
        stroke="#606062"
        stroke-width="2"
      />
    </svg>
  );
}
