import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const schema = Yup.object().shape({
  event_start_at: Yup.date()
    .typeError('Por favor, informe uma data válida')
    .required('Campo obrigatório')
    .min(today, 'A data deve ser no mínimo hoje'),
  event_end_at: Yup.date()
    .required('Campo obrigatório')
    .typeError('Por favor, informe uma data válida')
    .min(
      Yup.ref('event_start_at'),
      'A data deve ser maior que a data de início.'
    ),
  zip_code: Yup.string().required('O endereço é obrigatório'),
  street: Yup.string().required('A rua é obrigatória.'),
  state: Yup.string().required('O estado é obrigatório.'),
  city: Yup.string().required('A cidade é obrigatória.'),
  number: Yup.string().required('O número é obrigatório.'),
  location_name: Yup.string().required('O nome do local é obrigatório.'),
  event_type: Yup.string().required('O tipo de evento é obrigatório.'),
  event_start_time: Yup.string()
    .required('O horario de inicio é obrigatório.')
    .test('valid-time', 'O limite de horario é 23:59', (value) => {
      if (!value) {
        return false;
      }
      const [hour, minute] = value.split(':');
      if (parseInt(minute, 10) > 59) {
        return false;
      }
      return true;
    }),
  event_end_time: Yup.string().test(
    'valid-time',
    'O limite de horario é 23:59',
    (value) => {
      if (!value) {
        return true;
      }
      const [hour, minute] = value.split(':');

      if (parseInt(hour, 10) > 23 || parseInt(minute, 10) > 59) {
        return false;
      }
      return true;
    }
  ),
  complementary_activity: Yup.string().required('O campo é obrigatório'),
  complementary_activity_time: Yup.string().when('complementary_activity', {
    is: 'true',
    then: Yup.string()
      .required('A carga horária é obrigatória.')
      .test(
        'valid-complementary-activity-time',
        'O limite de minutos é 59',
        (value) => {
          if (!value) {
            return false;
          }
          const parts = value.split(':');
          if (parts.length === 2) {
            const minute = parseInt(parts[1], 10);
            if (minute <= 59) {
              return true;
            } else {
              return false;
            }
          }

          return false;
        }
      ),
  }),
  certification: Yup.string().required('O campo é obrigatório'),
  certification_time: Yup.string().when('certification', {
    is: 'true',
    then: Yup.string()
      .required('A carga horária é obrigatória.')
      .test(
        'valid-complementary-activity-time',
        'O limite de minutos é 59',
        (value) => {
          if (!value) {
            return false;
          }
          const parts = value.split(':');
          if (parts.length === 2) {
            const minute = parseInt(parts[1], 10);
            if (minute <= 59) {
              return true;
            } else {
              return false;
            }
          }

          return false;
        }
      ),
  }),
  event_modality: Yup.string().required('A modalidade é obrigatória.'),
});

export const schemaOnline = Yup.object().shape({
  event_start_at: Yup.date()
    .typeError('Por favor, informe uma data válida')
    .required('Campo obrigatório')
    .min(today, 'A data deve ser no mínimo hoje'),
  event_end_at: Yup.date()
    .required('Campo obrigatório')
    .typeError('Por favor, informe uma data válida')
    .min(
      Yup.ref('event_start_at'),
      'A data deve ser maior que a data de início.'
    ),
  event_url: Yup.string()
    .url('Insira um link valido.')
    .required('O link é obrigatorio.'),
  event_type: Yup.string().required('O tipo de evento é obrigatório.'),
  event_start_time: Yup.string()
    .required('O horario de inicio é obrigatório.')
    .test('valid-time', 'O limite de horario é 23:59', (value) => {
      if (!value) {
        return false;
      }
      const [hour, minute] = value.split(':');

      if (parseInt(hour, 10) > 23 || parseInt(minute, 10) > 59) {
        return false;
      }
      return true;
    }),
  event_end_time: Yup.string().test(
    'valid-time',
    'O limite de horario é 23:59',
    (value) => {
      if (!value) {
        return true;
      }
      const [hour, minute] = value.split(':');

      if (parseInt(hour, 10) > 23 || parseInt(minute, 10) > 59) {
        return false;
      }
      return true;
    }
  ),
  complementary_activity: Yup.string().required('O campo é obrigatório'),

  certification: Yup.string().required('O campo é obrigatório'),

  complementary_activity_time: Yup.string().when('complementary_activity', {
    is: 'true',
    then: Yup.string()
      .required('A carga horária é obrigatória.')
      .test(
        'has-value',
        'A carga horária é obrigatória.',
        (value) => value != null && value.trim() !== ''
      )
      .test(
        'valid-complementary-activity-time',
        'O limite de minutos é 59',
        (value) => {
          if (!value) {
            return false;
          }
          const parts = value.split(':');
          if (parts.length === 2) {
            const minute = parseInt(parts[1], 10);
            return minute >= 0 && minute <= 59;
          }

          return false;
        }
      ),
  }),
  certification_time: Yup.string().when('certification', {
    is: 'true',
    then: Yup.string()
      .required('A carga horária é obrigatória.')
      .test(
        'has-value',
        'A carga horária é obrigatória.',
        (value) => value != null && value.trim() !== ''
      )
      .test(
        'valid-complementary-activity-time',
        'O limite de minutos é 59',
        (value) => {
          if (!value) {
            return false;
          }
          const parts = value.split(':');
          if (parts.length === 2) {
            const minute = parseInt(parts[1], 10);
            return minute >= 0 && minute <= 59;
          }

          return false;
        }
      ),
  }),
  event_modality: Yup.string().required('A modalidade é obrigatória.'),
});
