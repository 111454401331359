import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;

  > header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      margin-top: -17px;
    }

    > form {
      width: 100%;
      max-width: 350px;
    }
  }

  > h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #009291;
  }

  > p {
    font-size: 16px;
    line-height: 1.5;
    color: #606062;
    margin-top: 0.5rem;
  }

  > strong {
    margin-top: 2rem;
    font-weight: 700;
    font-size: 14px;
  }

  > span {
    font-size: 14px;
    color: #606062;
    margin-bottom: 0.5rem;
  }
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: -14px;
`;
