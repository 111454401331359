import axios from 'axios';
import urls from 'constants/urls';

const verifyExistUniversity = async (university_slug) => {
  try {
    const { data } = await axios.get(
      `${urls.API_URL}/api/internal/students/university-exists/${university_slug}`
    );
    return data.exists ?? false;
  } catch (error) {
    return false;
  }
};

export default verifyExistUniversity;
