import {
  AreaAmount,
  Container,
  GrayGradient,
  IconWrapper,
  Row,
  ShowProfileButton,
  TextName,
} from './styled';
import { BookIcon } from 'assets/mentorship/BookIcon';
import { HouseLaptopIcon } from 'assets/mentorship/HouseLaptopIcon';
import { MentorImage, MentorStatusLabel } from '../../styled';
import { useMemo } from 'react';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import userIcon from 'assets/img/userIcon.png';
import { BrainIcon } from 'assets/mentorship/BrainIcon';

export function MentorCard(props) {
  const { mentor, onOpenMentorModal, isLoading } = props;

  const mentorStatusLabel = {
    active: 'ATIVO',
    inactive: 'INATIVO',
  };

  const modalityDescription = useMemo(() => {
    if (mentor.mentorship_modality.length === 1) {
      const modality = mentor?.mentorship_modality[0].label;
      return modality.charAt(0).toUpperCase() + modality.slice(1);
    } else {
      return 'Presencial e Online';
    }
  }, [mentor]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const buttonText =
    mentor?.is_logged_staff && mentor?.is_teacher_or_coordinator
      ? 'Visualizar meu perfil'
      : 'Visualizar perfil';

  return (
    <Container>
      <GrayGradient />
      <MentorImage
        src={mentor?.avatar || userIcon}
        margin={'13px auto 0 auto'}
        onError={(e) => {
          e.target.src = userIcon;
          e.target.onerror = null;
        }}
      />
      <MentorStatusLabel status={mentor.status} marginTop={'8px'}>
        MENTOR {mentorStatusLabel[mentor.status]}
      </MentorStatusLabel>
      <TextName>{mentor.name}</TextName>
      <Row>
        <IconWrapper>
          <BookIcon />
        </IconWrapper>
        <span>
          Mentora{' '}
          {mentor.courses_count === null
            ? 'todos os cursos'
            : `${mentor.courses_count} curso${
                mentor.courses_count > 1 ? 's' : ''
              } `}
          {mentor.courses_count > 0 && (
            <b onClick={() => onOpenMentorModal(mentor)}>ver</b>
          )}
        </span>
      </Row>
      <Row>
        <IconWrapper>
          <BrainIcon />
        </IconWrapper>
        <span className="specialities">
          {capitalizeFirstLetter(mentor?.specialities[0])}
        </span>
        {mentor?.specialities_count > 1 && (
          <AreaAmount onClick={() => onOpenMentorModal(mentor)}>
            {`+${mentor.specialities_count - 1}`}
          </AreaAmount>
        )}
      </Row>

      <Row>
        <IconWrapper>
          <HouseLaptopIcon />
        </IconWrapper>
        <span>{modalityDescription}</span>
      </Row>
      <ShowProfileButton
        onClick={() => onOpenMentorModal(mentor)}
        disabled={isLoading}
      >
        {isLoading ? <FilterLoading /> : buttonText}
      </ShowProfileButton>
    </Container>
  );
}
