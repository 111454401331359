import React, { useContext, useState, useRef } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { Button } from './components/Buttons/buttonIndicateStudent';
import IndicateStudentModal from './components/Modals/IndicationStudentModal';
import { FilterModal, Loading } from './style';
import Input from '../../../Opportunities/Components/Form/input';
import mergeContextsHelper from '../../../../../utils/mergeContextsHelper';
import IndicationStudentProvider, {
  IndicationStudentContext,
} from './context/indicationStudentContext';
import { Form } from '@unform/web';
import CustomButton from 'components/CustomButtons/Button';
import { getStudentsList } from './services/IndicationStudent.service';
import { ModalsContext } from 'components/modals/contexts/modalContext';

const FilterStudentModal = (props) => {
  const { openModalWithContent } = useContext(ModalsContext);
  const { children, jobs, closeModalFunc, ...rest } = props;
  const [showModal, setShowModal] = useState(true);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [dialogText, setDialogText] = useState('Indicar estudante');
  const [loadingStudent, setLoadingStudent] = useState(false);
  const formRef = useRef(null);
  const [showRequiredField, setShowRequiredField] = useState(false);

  const getStudents = async () => {
    setLoadingStudent(true);
    const { nameOrCode } = formRef.current.getData();
    if (!nameOrCode || nameOrCode == '') {
      setLoadingStudent(false);
      setShowRequiredField(true);
      return;
    }
    try {
      const response = await getStudentsList(jobs.id, {
        name_or_external_id: nameOrCode,
      });
      const { count, results, next } = response;
      if (!count) {
        throw new Error('Cannot find student');
      }
      setLoadingStudent(false);
      handleClickList({}, 2, results, next);
    } catch (e) {
      setShowFailureModal(true);
      setLoadingStudent(false);
    }
  };

  const handleClickList = async (e, step = 1, students = [], next) => {
    e.hasOwnProperty('preventDefault') && e.preventDefault();
    await exitModal();
    const baseModal = openModalWithContent({
      modalContent: mergeContextsHelper(
        <IndicationStudentProvider
          job={jobs}
          step={step}
          students={students}
          next={next}
        >
          <IndicateStudentModal />
        </IndicationStudentProvider>
      ),
      isNewModalDialog: true,
    });
  };

  const exitModal = async () => {
    await closeModalFunc();
  };

  return (
    <>
      <FilterModal
        isOpen={showModal}
        bubbleText={showFailureModal ? 'Oops!' : 'Indicar estudante'}
        widthBubble={showFailureModal ? '82px' : '200px'}
        bubbleClass="bubble"
        alignWrapper={showFailureModal && 'center'}
        closeModalFunc={exitModal}
      >
        {showFailureModal ? (
          <>
            <p className="message">Nenhum estudante foi encontrado.</p>
            <div className="buttonContainer">
              <CustomButton
                className="actionButton"
                onClick={(e) => handleClickList(e)}
              >
                OK, VAMOS TENTAR OUTRA VEZ!
              </CustomButton>
            </div>
          </>
        ) : (
          <>
            <p className="message">
              Informe os dados do estudante para indicá-lo à vaga.
            </p>
            <Form ref={formRef} onSubmit={getStudents}>
              <div className="inputContainer">
                <label for="nameOrCode">Nome/Código</label>
                <Input
                  name="nameOrCode"
                  type="text"
                  placeholder="Digite aqui..."
                  error={showRequiredField}
                  onChange={() => {
                    showRequiredField && setShowRequiredField(false);
                  }}
                />
                {showRequiredField && (
                  <span className="error">Campo obrigatório</span>
                )}
              </div>
              <div className="buttonContainer">
                <CustomButton
                  disabled={loadingStudent}
                  className="actionButton"
                  type="submit"
                >
                  {loadingStudent ? <Loading /> : <>Buscar</>}
                </CustomButton>
              </div>
            </Form>
          </>
        )}
      </FilterModal>
    </>
  );
};

export const IndicateStudentButton = (props) => {
  const [openStudentModal, setOpenStudentModal] = useState(false);
  const { children, ...rest } = props;
  const { createModal } = useContext(ModalsContext);

  const handleOpenModal = () => {
    setOpenStudentModal(!openStudentModal);
  };

  const openModal = () => {
    const modal = createModal();
    modal.setContent(
      <FilterStudentModal
        {...props}
        closeModalFunc={() => modal.closeModal()}
      />
    );
  };

  return (
    <>
      <Button {...rest} onClick={openModal}>
        {children}
      </Button>
    </>
  );
};
