import React from 'react';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import headerBackgroundImage from 'assets/img/companies-jobs.jpg';
import {
  CategoryButton,
  CategoryGrid,
  SelectCategoryContainer,
} from './styles';
import { useCsm } from '../../contexts/CsmContext';
import { CategoryImage } from '../CategoryImage';

export function SelectCategory() {
  const { changeCategory, categoriesList, setSelectStudent } = useCsm();

  function handleChangeCategory(category) {
    changeCategory(category);
    setSelectStudent(null);
  }

  return (
    <div>
      <SelectCategoryContainer>
        <span>Identifique os estudantes pela sua situação no sistema.</span>
        <CategoryGrid>
          {categoriesList.map((category) => {
            return (
              <CategoryButton
                key={category.value}
                onClick={() => handleChangeCategory(category.value)}
              >
                <CategoryImage category={category.value} color={'#009291'} />
                <span>
                  <strong>{category.name}</strong>
                  <span> ({category.studentsCount})</span>
                </span>
                <p>{category.description}</p>
              </CategoryButton>
            );
          })}
        </CategoryGrid>
      </SelectCategoryContainer>
    </div>
  );
}
