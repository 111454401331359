import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
`;

export const ContainerHeader = styled.div`
  background: #f8fafc;
  padding: 1rem;
  margin-bottom: 2.5rem;
  span {
    font-size: 1rem;
    color: #949494;
    font-weight: 400;
    margin-bottom: 4px;
  }
  h3 {
    font-size: 22px;
    font-weight: 700;
    color: #606062;
    margin: 0px;
  }
`;
export const ContainerBody = styled.div`
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding: 1rem;

  .seeMoreRow {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 40px 0px;
  background: #e6e6e6;
  height: 1px;
`;
