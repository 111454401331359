import {
  ContainerBars,
  ContainerLegendBars,
  ContentBars,
  SkeletonBars,
  SkeletonLegend,
} from '../../Styles/StyleSkeleton';

export function VerticalBarGraphSkeleton(params) {
  return (
    <ContentBars vertical>
      <ContainerBars vertical>
        <SkeletonBars vertical heightBars={50} />
        <SkeletonBars vertical heightBars={90} />
        <SkeletonBars vertical heightBars={120} />
        <SkeletonBars vertical heightBars={150} />
        <SkeletonBars vertical heightBars={230} />
      </ContainerBars>
      <ContainerLegendBars vertical>
        <SkeletonLegend widthLegend={80} />
        <SkeletonLegend widthLegend={80} />
        <SkeletonLegend widthLegend={80} />
        <SkeletonLegend widthLegend={80} />
        <SkeletonLegend widthLegend={80} />
      </ContainerLegendBars>
    </ContentBars>
  );
}
