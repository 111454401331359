import React, { useContext, useEffect, useRef, useState } from 'react';
import TabJobCompany from '../../components/Tabs';
import RegisteredByInstitution from './components/Tabs/RegisteredByInstitution';
import RegisteredByPartners from './components/Tabs/RegisteredByPartners';

import { Container, Content } from './styled';
import { JobOfferContext } from '../../context/jobOfferContext';
import {
  getJobsHistoryFilters,
  getJobsPartnersHistoryFilters,
  getHistoryInstitution,
  getHistoryPartners,
} from '../../services';
import { formatNumber } from '../../../../../utils/formatNumber';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export default function HistoryJobsTabs(props) {
  const {
    countHistoryPartners,
    countHistoryInstitution,
    countJobs,
    role,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});

  const [jobsCountInstitution, setJobsCountInstitution] = useState(0);
  const [currentPageInstitution, setCurrentPageInstitution] = useState(1);
  const [limitInstitution, setLimitInstitution] = useState(20);
  const [jobsInstitution, setJobsInstitution] = useState(null);

  const [jobsCountPartners, setJobsCountPartners] = useState(0);
  const [currentPagePartners, setCurrentPagePartners] = useState(1);
  const [limitPartners, setLimitPartners] = useState(20);
  const [jobsPartners, setJobsPartners] = useState(null);

  const {
    setCurrentJobFilter,
    currentJobFilter,
    isMaster,
    setDataFunctions,
  } = useContext(JobOfferContext);

  useEffect(() => {
    setCurrentJobFilter(null);
    switch (activeTab) {
      case 0:
        getJobsHistoryFilters().then((data) => {
          setFilterOptions(data);
        });
        break;
      case 1:
        getJobsPartnersHistoryFilters().then((data) => {
          setFilterOptions(data);
        });
        break;
    }
  }, [activeTab]);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
    setJobsCountInstitution(countHistoryInstitution);
    setJobsCountPartners(countHistoryPartners);
  };

  const loadJobsInstitution = async (page) => {
    let data;
    setJobsInstitution(null);

    const existFilters =
      currentJobFilter && Object.keys(currentJobFilter).length > 0;

    if (existFilters) {
      const queryParams = queryParamsFromFilterObject(currentJobFilter, {
        commaSeparatedFields: ['created_by_university'],
      });
      data = await getHistoryInstitution(`${page}&${queryParams}`);
    } else {
      data = await getHistoryInstitution(`${page}`);
    }

    const { results, count } = data;
    setJobsInstitution(results);
    setJobsCountInstitution(count);
  };

  const handlePageChangeInstitution = async (page, queryParams = '') => {
    const newPage = `limit=${limitInstitution}&offset=${
      (page - 1) * limitInstitution || 0
    }&${queryParams}`;
    await loadJobsInstitution(newPage);
    await countJobs();
  };

  useEffect(() => {
    setDataFunctions({ handlePageChangeInstitution });
  }, []);

  const onPageChangeInstitution = (page) => {
    setCurrentPageInstitution(page);
  };

  const loadJobsPartners = async (page) => {
    let data;
    setJobsPartners(null);

    const existFilters =
      currentJobFilter && Object.keys(currentJobFilter).length > 0;

    if (existFilters) {
      const queryParams = queryParamsFromFilterObject(currentJobFilter, {
        commaSeparatedFields: ['created_by_university'],
      });
      data = await getHistoryPartners(`${page}&${queryParams}`);
    } else {
      data = await getHistoryPartners(`${page}`);
    }

    const { results, count } = data;
    setJobsPartners(results);
    setJobsCountPartners(count);
  };

  const handlePageChangePartners = async (page) => {
    const newPage = `limit=${limitPartners}&offset=${
      (page - 1) * limitPartners || 0
    }`;
    await loadJobsPartners(newPage);
    await countJobs();
  };

  useEffect(() => {
    setDataFunctions({ handlePageChangePartners });
  }, []);

  const onPageChangePartners = (page) => {
    setCurrentPagePartners(page);
  };

  const refContainer = useRef(null);

  const ScrollToDiv = () => {
    if (refContainer) {
      refContainer.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    ScrollToDiv();
  }, [currentPageInstitution, currentPagePartners]);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <RegisteredByInstitution
          countJobs={countJobs}
          activeTab={activeTab}
          currentJobFilter={currentJobFilter}
          ScrollToDiv={ScrollToDiv}
          jobsCountInstitution={jobsCountInstitution}
          setJobsCountInstitution={setJobsCountInstitution}
          currentPageInstitution={currentPageInstitution}
          setCurrentPageInstitution={setCurrentPageInstitution}
          limitInstitution={limitInstitution}
          setLimitInstitution={setLimitInstitution}
          onPageChangeInstitution={onPageChangeInstitution}
          jobsInstitution={jobsInstitution}
          setJobsInstitution={setJobsInstitution}
          loadJobsInstitution={loadJobsInstitution}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <RegisteredByPartners
          role={role}
          countJobs={countJobs}
          activeTab={activeTab}
          currentJobFilter={currentJobFilter}
          ScrollToDiv={ScrollToDiv}
          jobsCountPartners={jobsCountPartners}
          setJobsCountPartners={setJobsCountPartners}
          currentPagePartners={currentPagePartners}
          setCurrentPagePartners={setCurrentPagePartners}
          limitPartners={limitPartners}
          setLimitPartners={setLimitPartners}
          onPageChangePartners={onPageChangePartners}
          jobsPartners={jobsPartners}
          setJobsPartners={setJobsPartners}
          loadJobsPartners={loadJobsPartners}
        />
      ),
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  return (
    <Container ref={refContainer}>
      <Content>
        <TabJobCompany
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={[
            `${
              isMaster
                ? 'Cadastradas pelo Grupo'
                : 'Cadastradas pela Instituição'
            } (${
              activeTab === 0
                ? formatNumber(jobsCountInstitution)
                : formatNumber(countHistoryInstitution)
            })`,
            `${
              isMaster
                ? 'Cadastradas pelas Instituições'
                : 'Cadastradas por Parceiros'
            } (${
              activeTab === 1
                ? formatNumber(jobsCountPartners)
                : formatNumber(countHistoryPartners)
            })`,
          ]}
          filterOptions={filterOptions}
        />
        {renderPrincipalTabs(activeTab)}
      </Content>
    </Container>
  );
}
