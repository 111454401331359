import React from 'react';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';

const UtilsPersonal = (academic_profile) => {
  const {
    IconCivilState,
    IconContact,
    IconIdentificationCode,
    IconLocalization,
  } = wrapperIconsProfile();

  return [
    {
      icon: <IconContact />,
      title: 'Contato',
      info: academic_profile?.mobile_phone || '-',
    },
    {
      icon: <IconIdentificationCode />,
      title: 'Código de identificação',
      info: academic_profile?.external_id || '-',
    },
    {
      icon: <IconCivilState />,
      title: 'Estado civil',
      info: academic_profile?.marital_status.label,
    },
    {
      icon: <IconLocalization />,
      title: 'Cidade atual',
      info: `${academic_profile?.city} | ${academic_profile?.state}` || '-',
    },
  ];
};

export default UtilsPersonal;
