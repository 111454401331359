import {Fragment} from 'react';
import styled from 'styled-components'

export const Title = styled.p`
  font-size: 22px;
  color: ${props => props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;
  padding-bottom: 13px;
`;

export const SMTitle = styled(Title)`
  display: none;

  @media (max-width: 768px) {
    font-size: 20px;
    display: flex;
    text-align: center;
  }
  
  @media (max-width: 527px) {
    display: none;
  }
`

export const XSTitle = styled(Title)`
  display: none;

  @media (max-width: 527px) {
    font-size: 20px;
    display: flex;
    text-align: center;
  }
`

export const LGTitle = styled(Title)`
  font-size: 20px;
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 527px) {
    display: none;
  }
`

export default function ResponsiveTitle ({ xsText, smText, lgText, universityColor }) {
  return (
    <Fragment>
      <LGTitle universityColor={universityColor}> 
        {lgText} 
      </LGTitle>
      <SMTitle universityColor={universityColor}> 
        {smText} 
      </SMTitle>
      <XSTitle universityColor={universityColor}> 
        {xsText} 
      </XSTitle>
    </Fragment>
  )
}