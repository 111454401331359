import React from 'react';
import { HeaderH2, SubHeader } from './styled';

export default function Header({ color, academic_profile, count }) {
  return (
    <>
      <HeaderH2 color={color}>
        Feiras e eventos recomendados para você!
      </HeaderH2>
      <SubHeader>
        {academic_profile.name}, encontramos{' '}
        {count === 1 ? '1 feira e evento' : `${count} feiras e eventos`} para
        acelerar sua carreira.
      </SubHeader>
    </>
  );
}
