import React, { useState, useContext, useEffect, useCallback } from 'react';
import {
  WhiteBox,
  Container,
  MentorshipListContainer,
  SeeMoreButton,
} from '../styled';
import { useHistoricMentorshipContext } from '../../context';
import DetailsModal from '../../../../components/DetailsModal';
import BaseLayoutContext from 'contexts/base-layout';
import WallGenericDialogModal from 'components/WallGenericDialogModal';
import { MentorModal } from '../../../../../../StaffPages/Mentorship/screens/MentorsTab/components/MentorModal';
import { mentorshipApi } from 'views/Student/Mentorship/services/api';
import GenericMessage from 'components/GenericMessage';
import SkeletonCardMentorship from 'views/StaffPages/Mentorship/components/SkeletonCard';
import { useMentorship } from 'views/Student/Mentorship/context/MentorshipContext';
import MentoringDoneModal from '../../components/MentoringDoneModal';
import { MentoringNotDoneModal } from '../../components/MentoringNotDoneModal';
import { MentorshipCard } from '../../../../components/MentorshipCard';

export function ExpiredSubTab({
  updateMentorshipsCount,
  fetchMentorshipsCount,
  currentTab,
  isActiveExpiredTab,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const {
    modalData,
    setModalData,
    detailsModalOpen,
    openDetailsModal,
    closeDetailsModal,
    evaluateMentorshipModalOpen,
    setEvaluateMentorshipModalOpen,
    mentorships,
    setMentorships,
    loading,
    setLoading,
    page,
    setPage,
  } = useHistoricMentorshipContext();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [openModalAdjustStatus, setOpenModalAdjustStatus] = useState(false);
  const [mentoringNotDoneModalOpen, setMentoringNotDoneModalOpen] = useState(
    false
  );
  const {
    selectedMentor,
    mentorModalIsOpen,
    handleCloseMentorModal,
  } = useMentorship();

  const fetchExpiredMentorships = async (reset = false) => {
    setLoading(true);
    const offset = reset ? 0 : (page - 1) * 20;
    const response = await mentorshipApi.getExpiredMentorshipsList({
      queryParams: `limit=20&offset=${offset}`,
    });
    const updatedMentorships = mentorships && !reset ? [...mentorships] : [];
    response.results.forEach((mentorship) => {
      const mentorshipIndex = updatedMentorships.findIndex(
        (element) => element.id === mentorship.id
      );
      if (mentorshipIndex > -1) {
        updatedMentorships[mentorshipIndex] = mentorship;
      } else {
        updatedMentorships.push(mentorship);
      }
    });
    setMentorships(updatedMentorships);
    setHasNextPage(response.next !== null);
    setLoading(false);
    updateMentorshipsCount('expired', response.count);
    fetchMentorshipsCount();
  };

  useEffect(() => {
    fetchExpiredMentorships();
  }, []);

  const handleNextPage = useCallback(async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const response = await mentorshipApi.getExpiredMentorshipsList({
      queryParams: `limit=20&offset=${offset}`,
    });
    if (response) {
      setMentorships([...mentorships, ...response.results]);
      setHasNextPage(!!response.next);
    }
    setLoading(false);
  }, [mentorships, page]);

  const handleOpenModalAdjustStatus = (data) => {
    setModalData(data);
    setOpenModalAdjustStatus(true);
  };

  const openMentoringNotDoneModal = (data) => {
    setModalData(data);
    setMentoringNotDoneModalOpen(true);
    setOpenModalAdjustStatus(false);
  };
  const closeMentoringNotDoneModal = () => {
    setMentoringNotDoneModalOpen(false);
  };
  const openEvaluateMentorshipModal = () => {
    setEvaluateMentorshipModalOpen(true);
    setOpenModalAdjustStatus(false);
  };

  const closeEvaluateMentorshipModal = () => {
    setEvaluateMentorshipModalOpen(false);
  };

  const configModalAdjustStatus = {
    bubbleText: 'Atenção!',
    ParagraphText: `Para ajustar o status, precisamos confirmar se a mentoria foi realizada.`,
    buttonConfirmText: 'SIM, REALIZEI A MENTORIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      sizeIconPerson: '78px',
      bubbleMargin: '10px 0',
      bubbleFontWeight: '400',
    },
  };

  return (
    <Container>
      <WhiteBox>
        {mentorships?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhuma mentoria expirada foi encontrada"
            subtitle={`No momento não existe mentorias disponíveis`}
          />
        ) : (
          <MentorshipListContainer>
            {mentorships?.map((item) => (
              <MentorshipCard
                key={item.id}
                item={item}
                textButton={'Ajustar status'}
                showDetailsExpiredButton={true}
                openDetailsModal={handleOpenModalAdjustStatus}
                openDetailsExpiredModal={openDetailsModal}
                currentTab={currentTab}
              />
            ))}
            {loading && (
              <SkeletonCardMentorship
                quantity={2}
                subTitleQuantity={4}
                cardHeight="350px"
                cardMargin="0"
              />
            )}
          </MentorshipListContainer>
        )}
      </WhiteBox>
      {hasNextPage && !loading && (
        <SeeMoreButton color={universityColor} onClick={handleNextPage}>
          Ver mais
        </SeeMoreButton>
      )}
      <WallGenericDialogModal
        modalOpenState={openModalAdjustStatus}
        config={configModalAdjustStatus}
        universityColor={universityColor}
        buttonConfirmFunction={openEvaluateMentorshipModal}
        buttonCancelFunction={() => openMentoringNotDoneModal(modalData)}
      />
      <MentoringNotDoneModal
        isOpen={mentoringNotDoneModalOpen}
        onClose={closeMentoringNotDoneModal}
        item={modalData}
        title="Mentoria não realizada"
        fetchMentorship={fetchExpiredMentorships}
      />
      <MentoringDoneModal
        isOpen={evaluateMentorshipModalOpen}
        onClose={closeEvaluateMentorshipModal}
        item={modalData}
        fetchMentorship={fetchExpiredMentorships}
      />
      <DetailsModal
        isOpen={detailsModalOpen}
        onClose={closeDetailsModal}
        item={modalData}
        showFlagStatus={true}
        isActiveExpiredTab={isActiveExpiredTab}
        title="Detalhes"
        mentoringStatus="Expirado"
        setPage={setPage}
        fetchMentorship={fetchExpiredMentorships}
      />
      <MentorModal
        mentor={selectedMentor}
        isOpen={mentorModalIsOpen}
        onClose={handleCloseMentorModal}
      />
    </Container>
  );
}
