import { Fragment, useState } from 'react';
import downArrow from 'assets/Matriz/downArrow.svg';
import {
  ContainerList,
  DropDown,
  ActionArrow,
  Information,
  StatusSpan,
} from './style';
import person from 'assets/backoffice/person.svg';
import mail from 'assets/backoffice/mail.svg';
import moment from 'moment';
import Tooltip from 'components/NewTooltip';
import trash from 'components/UploadEvidences/assets/Trash.svg';
import ModalInformation from 'components/informationModal';
import backOfficeApi from 'views/Backoffice/emailManagement/services/backOfficeApi';
import { status } from './status';
import { InformationIcon } from 'assets/backoffice/information';

export default function EmailDropDown({ item, getListage }) {
  const [open, setOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const config = {
    bubbleText: 'Atenção',
    ParagraphText: `Tem certeza de que deseja excluir o e-mail?
    <br/><br/>
    Ao realizar essa ação, a Instituição de ensino não conseguirá mais disparar e-mails no menu CSM.`,
    buttonConfirmText: 'SIM, EXCLUIR',
    buttonCancelText: 'NÃO',
    modalHeight: 370,
    showButtonCancel: true,
    style: {
      textAlign: 'justify',
    },
  };

  const handleClickArrow = () => {
    setOpen(!open);
  };

  const handleClickDelete = () => {
    setModalInformationOpen(true);
  };

  const handleConfirmModal = async () => {
    await backOfficeApi.deleteEmail(item.id);
    setModalInformationOpen(false);
    getListage();
  };

  const handleCancelModal = () => {
    setModalInformationOpen(false);
  };

  const { label, color, backgroundColor, helpText } = status[item.status];

  return (
    <Fragment>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={config}
        buttonConfirmFunction={handleConfirmModal}
        buttonCancelFunction={handleCancelModal}
      />
      <ContainerList>
        <DropDown open={open}>
          <span className="span1">
            {item.university.label}
            <ActionArrow onClick={handleClickArrow} open={open}>
              <img src={downArrow} />
            </ActionArrow>
          </span>
          <Information>
            <img src={person} />
            Responsável por informar o e-mail: {item.created_by}
          </Information>
          <Information>
            <img src={mail} />
            E-mail cadastrado em {moment(item.created_at).format('DD/MM/YYYY')}
          </Information>
        </DropDown>
        <span className="span2">{item.email}</span>
        <StatusSpan color={color} backgroundColor={backgroundColor}>
          <span>
            {label}
            {helpText && (
              <Tooltip text={helpText} direction="top" colorSchema="black">
                <InformationIcon color={color} size="12" />
              </Tooltip>
            )}
          </span>
        </StatusSpan>
        <span className="span4">
          <Tooltip text="Excluir e-mail" direction="top">
            <img src={trash} onClick={handleClickDelete} />
          </Tooltip>
        </span>
      </ContainerList>
    </Fragment>
  );
}
