import { useRef, useState, useCallback, useEffect } from 'react';
import ImageDefault from 'assets/img/imageDefault';
import {
  BoxLink,
  Container,
  Info,
  ContainerImage,
  LetterCounter,
  TextImage,
  Title,
  WrapperImage,
  InputImage,
  AddImage,
  ContainerButtons,
  BoxLinkSkeleton,
  InputImageSkeleton,
} from '../Styles/BaseComponent';
import { Form } from '@unform/web';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import ModalUploadEvidences from 'components/UploadEvidences';
import { DividerResponsive } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/MoreInformationModalStudent/style';
import { IoEyeOutline } from 'react-icons/io5';
import {
  createLoginConfig,
  editLoginConfig,
  getLoginConfig,
  postImageSettingsCompany,
  getImageLoginCompany,
  deleteImageLoginConfigCompany,
} from 'views/StaffPages/SettingsPage/services';
import { PreviewModal } from '../PreviewModal';
import CopyLinkButton from 'components/CopyLinkButton';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function CompaniesTab({ setNoScroll }) {
  const formRef = useRef(null);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [image, setImage] = useState([]);
  const [controlPreviewModal, setControlPreviewModal] = useState(false);
  const [dataConfig, setDataConfig] = useState({});
  const [skeleton, setSkeleton] = useState(false);
  const [backgroundImageSkeleton, setBackgroundImageSkeleton] = useState(false);

  const url = `https://empresas.worka.love/#/login/${
    dataConfig?.university?.slug ?? ''
  }`;

  const handleCloseUpdateModal = () => {
    setOpenModalUpload(false);
    getSettings();
    setNoScroll(false);
  };

  const getSettings = useCallback(async () => {
    const response = await getLoginConfig('company');
    setDataConfig(response);
    return response;
  }, []);

  const handleChangeUploadModal = async () => {
    setOpenModalUpload(true);
    const response = await getImageLoginCompany();
    if (response && response.image) {
      setImage([response]);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSkeleton(true);
      setBackgroundImageSkeleton(true);
    }, 400);

    return () => clearTimeout(timeoutId);
  }, []);

  const returnBoxLink = () => {
    if (!skeleton) {
      return <BoxLinkSkeleton />;
    }

    return (
      <BoxLink>
        <strong> Link de acesso: </strong>
        <span>{url}</span>
        <CopyLinkButton textToCopy={url} tooltipMessage="Copiar Link" />
      </BoxLink>
    );
  };

  const returnBackgroundImage = (image) => {
    if (!backgroundImageSkeleton && image?.file) {
      return <InputImageSkeleton />;
    }

    if (!image) {
      return (
        <InputImage>
          <ImageDefault />
        </InputImage>
      );
    }

    return <img src={image.file} />;
  };

  const functionDeleteImage = async () => {
    try {
      const response = await deleteImageLoginConfigCompany();
      if (response.status === 204) {
        setImage([]);
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Container>
      <ModalUploadEvidences
        open={openModalUpload}
        evidenceFiles={image ?? []}
        evidenceLinks={[]}
        acceptFormats={['file']}
        acceptFileTypes={['PNG', 'JPEG']}
        functionCloseAndUpdate={handleCloseUpdateModal}
        headerTitle={'Adicionar Imagem'}
        buttonLabel={'SALVAR'}
        limitUpload={1}
        idPost={dataConfig?.id ?? 0}
        functionPostFile={postImageSettingsCompany}
        functionDeleteEvidence={functionDeleteImage}
        uniqueUploadType={'file'}
        nameTypeDoc={false}
        ctaDownloadPreview={false}
        limitSizeDocument={2097152}
        messageLimitSize={'O tamanho máximo da imagem é de 2MB.'}
      />
      <PreviewModal
        control={controlPreviewModal}
        data={dataConfig}
        isNewCompanyLogin
        updatedData={formRef.current?.getData()}
        close={() => {
          setControlPreviewModal(false);
          setNoScroll(false);
        }}
        isCompanyPreview
      />
      {returnBoxLink()}
      <Form ref={formRef} initialData={dataConfig}>
        <WrapperImage>
          <TextImage>
            <Title> Imagem da tela de acesso</Title>
            <Info marginTop="13px">
              Escolha uma imagem que represente a sua Instituição para
              apresenta-lá na tela de acesso das empresas parceiras.
            </Info>
          </TextImage>
          <ContainerImage>
            {returnBackgroundImage(dataConfig?.image)}
            <AddImage
              type="button"
              onClick={() => {
                handleChangeUploadModal();
                setNoScroll(true);
              }}
            >
              <MdOutlineAddPhotoAlternate size={23} />
              {dataConfig?.image?.file
                ? 'Substituir imagem'
                : 'Adicionar Imagem'}
            </AddImage>
          </ContainerImage>
        </WrapperImage>
        <DividerResponsive />
        <ContainerButtons>
          <button
            type="button"
            className="preview-button"
            onClick={() => {
              setNoScroll(true);
              setControlPreviewModal(true);
            }}
          >
            <IoEyeOutline size={23} />
            Pré-visualizar
          </button>
        </ContainerButtons>
      </Form>
    </Container>
  );
}
