import React, {useContext} from "react";
import { object, func, number, string } from "prop-types";
import BaseLayoutContext from "contexts/base-layout";
import useDefineColor from "constants/colors";

const Nav = props => {
  const { classes, goToStep, currentStep } = props;
  const dots = [];

  const {universityColor} = useContext(BaseLayoutContext)
  for (let i = 1; i <= props.totalSteps; i++) {
    const isActive = currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        id={i}
        className={`${classes.dot} ${isActive ? classes[`${useDefineColor(universityColor)}stepActive`] : "" }`}
        onClick={event => {
          event.persist();
          goToStep(event.target.id);
        }}
      >
        {i}
      </span>
    );
  }

  return <div className={classes.paginationBulletsHolder}>{dots}</div>;
};

Nav.propTypes = {
  classes: object,
  goToStep: func,
  totalSteps: number,
  currentStep: number,
};

export default Nav;
