import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';

import {
  Wrapper,
  DescImg,
  MultiStepContainer,
  Img,
  Title,
  SubTitle,
  AboutTitle,
  Abount,
  WrapperModality,
  Documents,
  Button,
  ErrorMessage,
} from '../../../../../../../components/Opportunities/styles/styleForm';

import Group448 from '../../../../../../../assets/Oportutinites/Group448.svg';

import {
  ExtesionOptions,
  PublicationFormat,
} from '../../../../resources/SelectedOptionsCompany';

import InformationModal from '../../../InformationModal';
import Input from '../../../../../../../components/Opportunities/Inputs/Input';
import FooterForm from '../../../../../../../components/Opportunities/Footer';
import Videos from '../../../../../../../components/Opportunities/ComponentVideos';
import ModalGeneric from '../../../../../../../components/Opportunities/ModalGeneric';
import FileInput from '../../../../../../../components/Opportunities/Inputs/FileInput';
import LinkInput from '../../../../../../../components/Opportunities/Inputs/LinkInput';
import StepIndicator from '../../../../../../../components/Opportunities/StepIndicator';
import FileInputPdf from '../../../../../../../components/Opportunities/Inputs/PDFInput';
import InputDesc from '../../../../../../../components/Opportunities/Inputs/InputDescription';
import InputWorkload from '../../../../../../../components/Opportunities/Inputs/InputWorkload';
import SearchableSelectCompany from '../../../../../../../components/Opportunities/Select/SearchableSelect';

import { OpportunitiesCompanyContext } from '../../../../context/OpportunitiesCompanyContext';

export default function StepTwoCompany({ nextFormStep, prevsFormStep }) {
  const formRef = useRef();

  const Titles = [
    { id: 'article', title: 'Sobre o Artigo' },
    { id: 'e_book', title: 'Sobre o E-Book' },
    { id: 'trail', title: 'Sobre a Trilha' },
    { id: 'serie', title: 'Sobre a Série' },
    { id: 'challenge', title: 'Sobre o Desafio' },
    { id: 'announcements', title: 'Sobre o Comunicado' },
  ];

  const { formData, setFormValues } = useContext(OpportunitiesCompanyContext);
  const [condition, setCondition] = useState(true);
  const [validate, setValidate] = useState(false);
  const [publicationFormat, setPublicationFormat] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [noHasName, setNoHasName] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [title, setTitle] = useState('Adicionar vídeos');

  const handleSubmit = (data, { reset }) => {
    validateTypeAccess(data);
    if (data.name === '') {
      setModalInformationOpen(true);
      setNoHasName(true);
      return;
    } else {
      setNoHasName(false);
    }

    setFormValues({
      ...data,
      workload: data.workload ? data.workload.replace(':', '.') : null,
    });
    nextFormStep();
    reset();
  };

  const topDiv = useRef(null);

  const handleValidation = (e) => {
    const event = e.value;
    setValidate(event);
  };

  const validateTypeAccess = (data) => {
    if (data.type_access === 'access_link' && data.access_link === '') {
      data.access_link = '';
      data.type_access = null;
    }

    if (
      data.type_access === 'attraction_document' &&
      data.attraction_document === null
    ) {
      data.attraction_document = null;
      data.type_access = null;
    }

    if (data.type_access === 'video' && formData.content_pages.length < 1) {
      data.content_pages = [];
      data.type_access = null;
    }
  };

  const handlePublicationFormat = (e) => {
    const event = e.value;
    setPublicationFormat(event);
    if (formData.type_access) {
      delete formData.type_access;
    }
  };

  const returnTitle = () => {
    const Title = Titles.filter((Title) => Title.id === formData.type);
    return Title[0].title || '';
  };

  const handleModal = (e) => {
    e.preventDefault();
    setModalOpen(!modalOpen);
  };

  const scrollToTopDiv = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (modalOpen === true) {
      scrollToTopDiv();
      return;
    }
  }, [modalOpen]);

  useEffect(() => {
    if (formData.type_access) {
      setPublicationFormat(formData.type_access);
    }
    if (formData) {
      setCondition(formData.is_free);
    }

    if (formData.validity) {
      setValidate(formData.validity);
    }

    if (formData.content_pages) {
      setTitle('Editar vídeos');
    }
  }, [formData]);

  return (
    <Fragment>
      <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
        <InformationModal
          isOpen={modalInformationOpen}
          type={'error'}
          setModalOpen={setModalInformationOpen}
          informationHeader={
            'Ooops! Existe uma informação pendente no formulário.'
          }
          errorMsg={
            'Preencha o nome da oportunidade de aprendizagem para seguir em frente'
          }
        />
        <MultiStepContainer>
          <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
        </MultiStepContainer>
        <Wrapper ref={topDiv}>
          <DescImg>
            <Img src={Group448} />
            <Title>Conteúdos</Title>
          </DescImg>

          <Abount>
            <AboutTitle>{returnTitle()}</AboutTitle>
            <Input name="name" placeholder="Digite aqui..." label="Nome *" />
            {noHasName && (
              <ErrorMessage marginTop="-12px">
                Responda para seguir em frente
              </ErrorMessage>
            )}
            <InputDesc
              name="description"
              placeholder="Digite aqui..."
              label="Descrição"
            />

            <Title
              style={{ color: '#6d6d6d', fontWeight: 'bold', fontSize: '18px' }}
            >
              Imagem de destaque <br />{' '}
              <SubTitle>
                Adicione uma imagem em formato JPEG ou PNG com dimensão de 1600
                X 838
              </SubTitle>
            </Title>
            <FileInput name="attraction_image" accept="image/png, image/jpeg" />
          </Abount>
        </Wrapper>
        <hr />
        {formData.type !== 'announcements' && (
          <Fragment>
            <WrapperModality>
              <SearchableSelectCompany
                name="validity"
                options={ExtesionOptions}
                label="Validade"
                onChange={handleValidation}
                style={{ padding: '0px 16px', height: '2.3em' }}
              />

              {validate && (
                <InputWorkload
                  name="workload"
                  label="Carga horaria"
                  placeholder={'Ex: 40:00'}
                  style={{ padding: '10px 16px' }}
                />
              )}
            </WrapperModality>

            <Documents>
              <SearchableSelectCompany
                name="type_access"
                onChange={handlePublicationFormat}
                options={PublicationFormat}
                label="Formato de publicação"
              />
              {publicationFormat === 'access_link' ? (
                <LinkInput
                  style={{ marginTop: '20px' }}
                  name="access_link"
                  placeholder="Digite seu link aqui"
                />
              ) : publicationFormat === 'attraction_document' ? (
                <div>
                  <FileInputPdf name="attraction_document" accept=".pdf" />
                </div>
              ) : (
                publicationFormat === 'video' && (
                  <div>
                    <Button onClick={(e) => handleModal(e)}>{title}</Button>
                    <ModalGeneric
                      isOpen={modalOpen}
                      setModalOpen={setModalOpen}
                    >
                      <Videos setModalOpen={setModalOpen} />
                    </ModalGeneric>
                  </div>
                )
              )}
            </Documents>
          </Fragment>
        )}

        <FooterForm
          haveNext={true}
          haveBack={true}
          prevsState={prevsFormStep}
        />
      </Form>
    </Fragment>
  );
}
