export const DelaySearchHelper = {
  delay: (dispatch, ms, signal) => {
    const handler = setTimeout(() => {
      dispatch();
    }, ms);
    return () => {
      clearTimeout(handler);
      signal.abort();
    };
  },
};
