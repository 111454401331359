import React, { Fragment, useContext, useMemo } from 'react';

import {
  CardImg,
  Cards,
  CardSubTitle,
  CardsWrapper,
  CardTitle,
  Wrapper,
} from '../Styles/Styled';

import { PrincipalCardsObjects } from '../Resources/PrincipalCardsObjects';
import { OportunitiesContext } from '../Context/OportunitiesContext';
import AuthContext from '../../../../contexts/authentication';
import OpportunitiesGroupIcon from 'assets/ComponentIcons/OpportunitiesGroupIcon';

export default function PrincipalCards({ setActiveTab }) {
  const {
    setModalIsOpen,
    setModalContent,
    macroAreas,
    periods,
    unities,
    disciplines,
  } = useContext(OportunitiesContext);
  const { metadata } = useContext(AuthContext);

  let cards = PrincipalCardsObjects;

  if (metadata && !metadata.has_extension_project) {
    cards = cards.filter((card) => card.title !== 'Projetos de Extensão');
  }

  const isExtensionProjectEnabled = useMemo(() => {
    return !!macroAreas && !!periods && Array.isArray(unities) && !!disciplines;
  }, [macroAreas, periods, unities, disciplines]);

  const totalCards = cards.map((item, index) => (
    <CardsWrapper key={item.id}>
      <Cards
        onClick={() => {
          if (index !== 2 || (index === 2 && isExtensionProjectEnabled)) {
            item.handleCall(setModalIsOpen, setModalContent, setActiveTab);
          }
        }}
        disabled={(index === 2 && !isExtensionProjectEnabled) || false}
        title={index === 2 && !isExtensionProjectEnabled ? 'Aguarde...' : ''}
      >
        {index === 2 ? (
          <OpportunitiesGroupIcon
            size={63}
            color={isExtensionProjectEnabled ? '#009291' : '#C4C4C4'}
          />
        ) : (
          <CardImg src={item.image} alt="alt" />
        )}
        <CardTitle>{item.title}</CardTitle>
        <CardSubTitle>{item.subtitle}</CardSubTitle>
      </Cards>
    </CardsWrapper>
  ));

  return (
    <Fragment>
      <h2 style={{ marginTop: '1.5em' }}>
        Qual <strong>oportunidade de aprendizagem</strong> você deseja{' '}
        <strong>publicar?</strong>
      </h2>
      <span className={'create-opportunities-subtitle'}>
        Aqui você pode publicar oportunidades de aprendizagem para ajudar os
        seus estudantes a inserir no mercado de trabalho.
      </span>
      <Wrapper>{totalCards}</Wrapper>
    </Fragment>
  );
}
