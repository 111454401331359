export default function ProfileIcon({ color, size=50}) {
  return (
    <svg 
     width={size} 
     height={size} 
     viewBox="0 0 31 31" 
     fill="none" 
     xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="31" height="31" fill="#E5E5E5"/>
      <g clip-path="url(#clip0_11279_27086)">
         <rect x="-25.6679" y="-197.668" width="283.641" height="460.336" rx="10.0383" fill="white" stroke="#DCDCDC" stroke-width="0.664265"/>
         <rect x="0.570557" y="0.615234" width="29.8791" height="29.8791" rx="2.48992" fill={color}/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M15.3354 16.0565H15.2273C14.1771 16.0411 13.3276 15.6704 12.6943 14.9599C11.3197 13.4 11.536 10.728 11.5669 10.4809C11.6595 8.55024 12.5708 7.63898 13.3276 7.20652C14.0844 6.77406 14.8566 6.71228 15.2582 6.71228H15.32C16.0459 6.72773 16.6946 6.89762 17.2506 7.22197C18.0074 7.65443 18.9032 8.56568 18.9959 10.4809C19.0268 10.728 19.243 13.4 17.8684 14.9599C17.2352 15.6704 16.3857 16.0411 15.3354 16.0565ZM12.5399 10.5426V10.5735C12.5245 10.589 12.2928 13.0447 13.4512 14.3267C13.8991 14.8364 14.5014 15.0989 15.2891 15.0989H15.32C16.1232 15.0835 16.7255 14.8364 17.1734 14.3267C18.2665 13.1006 18.074 10.8151 18.0551 10.591C18.0543 10.5808 18.0538 10.5749 18.0538 10.5735V10.5426C17.9302 8.10232 16.293 7.70075 15.32 7.68531H15.2737C14.486 7.68531 12.6635 7.96332 12.5399 10.5426Z" fill="white"/>
         <path fill-rule="evenodd" clip-rule="evenodd" d="M22.6564 20.4893V20.4893C22.6564 20.4429 22.6564 20.4275 22.6564 20.3966C22.641 19.6861 22.5947 18.0181 21.0347 17.4929C21.0193 17.4929 21.0038 17.4929 21.0038 17.4775C19.3821 17.0605 18.0384 16.1338 18.0229 16.1183C17.8067 15.9639 17.4978 16.0257 17.3433 16.2419C17.1889 16.4581 17.2507 16.767 17.4669 16.9215C17.5287 16.9678 18.9496 17.9563 20.7412 18.4196C21.5753 18.7131 21.6679 19.6089 21.6988 20.4275C21.6988 20.4584 21.6988 20.4893 21.6988 20.5202C21.6988 20.8445 21.6834 21.3388 21.6216 21.6322C21.0347 21.9566 18.7643 23.0995 15.2892 23.0995C11.8295 23.0995 9.5436 21.9566 8.95669 21.6168C8.89491 21.3233 8.87947 20.8291 8.87947 20.5047C8.87947 20.4738 8.87947 20.4429 8.87947 20.412C8.91036 19.5935 9.00303 18.6977 9.83706 18.4042C11.6287 17.9408 13.0496 16.9524 13.1114 16.906C13.3276 16.7516 13.3894 16.4581 13.235 16.2264C13.0805 16.0102 12.7716 15.9484 12.5554 16.1029C12.5399 16.1183 11.1962 17.045 9.57449 17.462C9.55905 17.462 9.5436 17.4775 9.5436 17.4775C7.98366 18.0181 7.93732 19.6707 7.92188 20.3812C7.92188 20.412 7.92188 20.4429 7.92188 20.4738V20.4893C7.92188 20.6746 7.92188 21.6322 8.10722 22.111C8.13811 22.2037 8.21533 22.2809 8.29256 22.3427C8.40067 22.4199 10.98 24.0571 15.3046 24.0571C19.6292 24.0571 22.2085 22.4045 22.3166 22.3427C22.3939 22.2963 22.4711 22.2037 22.502 22.111C22.6719 21.6322 22.6719 20.6746 22.6564 20.4893Z" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_11279_27086">
          <rect width="284" height="461" fill="white" transform="translate(-26 -198)"/>
        </clipPath>
      </defs>
    </svg>
  );
}