import GenericInternalTab from 'components/GenericInternalTab';
import { Content, Container } from './styled';
import { useContext, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import { TalentsTabs } from './components/TalentsTabs';
import { TalentsContext } from './contexts/TalentListContext';
import { TalentsFilter } from './components/TalentsFilter';
export function NewTalentList() {
  const { activeTab, setActiveTab, tabs, getIndications } =
    useContext(TalentsContext);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const renderTab = (tab) => {
    return tabs[activeTab].component;
  };

  const getIndicationsData = async () => {
    await getIndications();
  };

  useEffect(() => {
    getIndicationsData();
  }, []);

  return (
    <>
      <Container justify="center">
        <Grid item xs md={11} sm={11}>
          <Content>
            <TalentsTabs
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabs={tabs.map((tab) => tab.label)}
              showFilter={true}
            />
            {renderTab()}
          </Content>
        </Grid>
      </Container>
    </>
  );
}
