import FilterModal from 'components/FilterModal';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, SpanError } from './styles';
import { handleFieldMask } from 'utils/validation';
import { maskCnpj } from 'utils/inputMasks';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import {
  getListConnectedCompanies,
  getListDisconnectCompanies,
} from 'views/StaffPages/CompaniesManagement/Services';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { useAPICalls } from '../../../Hooks/apiCalls';
import * as Yup from 'yup';

export function FilterCompaniesManagement({
  activeTab,
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  zIndex,
  disconnect,
  connect,
  setQueryFilter,
  handleChangeStates,
  isFetchingCities,
  loading,
  getCountTabs,
  setCountDisconnectCompanies,
  setCountConnectedCompanies,
  limit,
  setCurrentPage,
  setSearch,
}) {
  const {
    setListConnectedCompanies,
    setListDisconnectCompanies,
    setCompaniesDisconnectCount,
    setCompaniesConnectCount,
  } = useContext(CompaniesManagementContext);
  const [errorDate, setErrorDate] = useState(false);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        expiration_range_after: Yup.date(),
        expiration_range_before: Yup.date().min(
          Yup.ref('expiration_range_after'),
          `A data final precisa ser maior do que a data inicial.`
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  const { getConnectedCompanies, getDisconnectCompanies } = useAPICalls();

  useEffect(() => {
    setFilterState({});
  }, [activeTab]);

  const orderedActivitiesType = useMemo(() => {
    if (!filterOptions.activities_type) {
      return [];
    }
    return filterOptions.activities_type.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
  }, [filterOptions.activities_type]);

  const agreementOptions = [
    { label: 'Sem termo de convênio', value: 'no_term' },
    { label: 'Termo de convênio vigente', value: 'has_term' },
    {
      label: 'Termo de convênio próximo ao vencimento',
      value: 'close_expiration',
    },
  ];

  function renderFilterContent() {
    return [
      <FilterGrid>
        <div>
          <Filter
            label={'Estado'}
            name={'state'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.states}
            settings={{
              onChange: handleChangeStates,
            }}
          />
          <Filter
            name={'city'}
            label={'Cidade'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.cities}
            settings={{
              disabled: !filterState['state'] || isFetchingCities,
              loading: isFetchingCities,
            }}
          />
        </div>
        <div>
          <Filter
            label={'Tipo de empresa'}
            name={'type'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.type}
          />
          <Filter
            name={'size'}
            label={'Porte'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.size}
          />
        </div>
        <Filter
          label={'Tipo de atividade'}
          name={'activities_type'}
          type={FilterType.MULTI_SELECT}
          options={orderedActivitiesType}
        />
        <div>
          <Filter
            name={'legal_name'}
            label={'Nome da empresa'}
            type={FilterType.TEXT}
          />
          <Filter
            label={'CNPJ'}
            name={'organization_number'}
            type={FilterType.TEXT}
            onKeyUp={(event) => {
              handleFieldMask(event, maskCnpj);
            }}
            onPaste={(event) => {
              handleFieldMask(
                event,
                maskCnpj,
                event.clipboardData.getData('Text')
              );
            }}
            maxLength={18}
          />
        </div>
        <div>
          <Filter
            label="Status do convênio"
            name={'agreement_status'}
            type={FilterType.SINGLE_SELECT}
            options={agreementOptions}
          />
        </div>
        <div>
          <Filter
            type={FilterType.DATE_RANGE}
            name={['expiration_range_after', 'expiration_range_before']}
            label={'Período de vigência'}
            settings={{
              placeholder: ['Data inicial', 'Data final'],
              onChange: () => {
                setErrorDate(false);
              },
            }}
            startYear={2019}
          />
        </div>
        {errorDate && (
          <SpanError>
            A data final precisa ser maior do que a data inicial.
          </SpanError>
        )}
      </FilterGrid>,
      <FilterGrid>
        <div>
          <Filter
            label={'Competências comportamentais'}
            name={'skills'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.skills}
          />
        </div>
        <div>
          <Filter
            label={'Interesses'}
            name={'interests'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.interests}
          />
        </div>
        <div>
          <Filter
            label={'Valores'}
            name={'values'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.values}
          />
        </div>
      </FilterGrid>,
      <FilterGrid>
        {connect && (
          <div>
            <Filter
              label={'Usuário que realizou o convite'}
              name={'connection_responsible'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.connections_responsible}
            />
          </div>
        )}

        {disconnect && (
          <div>
            <Filter
              label={'Usuário que realizou o convite'}
              name={'invite_responsible'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.invite_responsible}
            />
          </div>
        )}

        {disconnect && (
          <div>
            <Filter
              label={'Usuário que desativou a empresa'}
              name={'disconnect_responsible'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.disconnect_responsible}
            />
          </div>
        )}
        <div>
          <Filter
            label={'Unidade que convidou a empresa'}
            name={'responsible_unities'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.responsible_unities}
          />
        </div>
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    setSearch('');
    const existFilters = filters && Object.keys(filters).length > 0;
    if (existFilters) {
      const isValid = await validateData(filters);
      if (isValid === false) {
        return false;
      }
    }

    if (!existFilters) {
      onClearFilters();
      return;
    }

    if (existFilters && disconnect) {
      const queryString = queryParamsFromFilterObject(filters, {
        commaSeparatedFields: [
          'invite_responsible',
          'disconnect_responsible',
          'responsible_unities',
        ],
      });
      setQueryFilter(queryString);
      setListDisconnectCompanies(null);

      const data = await getListDisconnectCompanies(queryString);
      const { count, results } = data;
      setListDisconnectCompanies(results);
      setCurrentPage(1);
      setCountDisconnectCompanies(count);
      setCompaniesDisconnectCount(count);
    }

    if (existFilters && connect) {
      const queryString = queryParamsFromFilterObject(filters, {
        commaSeparatedFields: ['connection_responsible', 'responsible_unities'],
      });
      setQueryFilter(queryString);
      setListConnectedCompanies(null);

      const data = await getListConnectedCompanies(queryString);
      const { count, results } = data;
      setListConnectedCompanies(results);
      setCurrentPage(1);
      setCountConnectedCompanies(count);
      setCompaniesConnectCount(count);
    }
  }

  async function onClearFilters() {
    setErrorDate(false);
    if (disconnect) {
      setListDisconnectCompanies(null);
      setQueryFilter(null);
      setCurrentPage(1);
      await getCountTabs();
      await getDisconnectCompanies(`limit=${limit}&offset=0`);
    }

    if (connect) {
      setListConnectedCompanies(null);
      setQueryFilter(null);
      setCurrentPage(1);
      await getCountTabs();
      await getConnectedCompanies(`limit=${limit}&offset=0`);
    }
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      tabs={['Dados da Empresa', 'Perfil da Empresa', 'Relacionamento']}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={zIndex}
      loading={loading}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
