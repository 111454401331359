import { Fragment, useEffect, useRef, useState } from 'react';

import {
  Container,
  HeaderTitle,
  HeaderSubTitle,
  Divider,
  ColorInput,
  ContainerComponentHeader,
  ContainerHeader,
  ContainerColorArrow,
  ContainerColorInput,
  ContainerLogo,
  UploadImageButton,
  YoutubeInput,
  SaveButton,
  ContainerImages,
  WrapperInputVideo,
  PreviewButton,
} from '../styled';
import ArrowDown from 'assets/SettingsPage/Profile/ArrowDown';

import { Form } from '@unform/web';
import ModalUploadEvidences from 'components/UploadEvidences';
import Gallery from 'assets/SettingsPage/Profile/Gallery';
import PreviewModal from '../PreviewModal';
import ProfileAPI from '../services/ProfileAPI';
import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';
import Trash from 'assets/SettingsPage/Profile/Trash';
import eye from 'assets/mentorship/eye.svg';
import ModalInformation from 'components/informationModal';
import InputDesc from 'views/StaffPages/Opportunities/Components/Form/inputDesc';
import DescriptionInput from '../Form/DescriptionInput';
import { ValueText } from 'components/CurriculumModalGenerate/Curriculum/styles';

export default function InstitutionTab({ setNoScroll }) {
  const [color, setColor] = useState('');
  const [logo, setLogo] = useState(null);
  const [usingModalUpload, setUsingModalUpload] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const [data, setData] = useState({});

  const [images, setImages] = useState([]);
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [isValidLinkYoutube, setIsValidLinkYoutube] = useState(false);

  const [currentConfig, setCurrentConfig] = useState({});

  const [usingPreviewModal, setUsingPreviewModal] = useState(false);
  const [openWalModal, setOpenWalModal] = useState(false);

  const [enableSubmitButton, setEnableSubmitButton] = useState(false);

  const colorRef = useRef(null);
  const formRef = useRef(null);

  const oldValuesForm = useRef({});

  const compareValues = (oldObject, newObject) => {
    const oldKeys = Object.keys(oldObject);

    if (enableSubmitButton) {
      return;
    }

    for (let key of oldKeys) {
      if (
        newObject &&
        oldObject[key] !== newObject[key] &&
        newObject[key] !== '<p><br></p>'
      ) {
        setEnableSubmitButton(true);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentData = formRef?.current?.getData();

      compareValues(oldValuesForm.current, currentData);
    }, 5000);
  }, []);

  const getUniversityData = async (fromUpdate) => {
    const { data } = await ProfileAPI.getUniversityData();
    const { logo, profile_color, video_url, photos } = data;

    oldValuesForm.current = {
      mission: data.mission || '',
      vision: data.vision || '',
      values: data.values || '',
      about_us: data.about_us || '',
    };

    setLogo(logo?.file);
    setYoutubeUrl(video_url || '');
    setData({ ...data, video_url: video_url || '' });
    if (!fromUpdate) {
      formRef.current.setData({ ...data });
      setColor(profile_color);
    }
  };

  useEffect(() => {
    getUniversityData();
  }, []);

  useEffect(() => {
    const youtubeLinkRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    const isValidLink = youtubeLinkRegex.test(youtubeUrl);

    setIsValidLinkYoutube(isValidLink);
  }, [youtubeUrl]);

  const handleClickUploadLogo = () => {
    const logo = { image: { ...data.logo } };
    setImages([logo]);
    setCurrentConfig(configLogo);
    setUsingModalUpload(true);
    setNoScroll(true);
  };

  const handleClickUploadImage = () => {
    const { photos } = data;

    if (photos.length > 0) {
      const mountedPhotos = photos.map((item) => ({
        ...item,
        image: { ...item },
      }));
      setImages(mountedPhotos);
    }

    setCurrentConfig(configGallery);
    setUsingModalUpload(true);
    setNoScroll(true);
  };

  const functionClose = () => {
    setUsingModalUpload(false);
    setNoScroll(false);
  };

  const functionPostImages = async (_, file) => {
    const { data } = await ProfileAPI.postImages(file);

    const mountedResponse = { image: { ...data } };
    return mountedResponse;
  };

  const functionPostLogo = async (_, file) => {
    const { data } = await ProfileAPI.postUniversityLogo(file);

    const mountedResponse = { image: { ...data } };

    setLogo(data.file);
    return mountedResponse;
  };

  const handleSubmit = async () => {
    const formData = formRef?.current?.getData();

    const data = { ...formData, profile_color: color, video_url: youtubeUrl };

    if (youtubeUrl.length > 0 && !isValidLinkYoutube) {
      return;
    }

    const response = await ProfileAPI.putUniversityData(data);

    oldValuesForm.current = {
      mission: response.data.mission || '',
      vision: response.data.vision || '',
      values: response.data.values || '',
      about_us: response.data.about_us || '',
    };

    if (response.status === 200) {
      setEnableSubmitButton(false);
      setOpenWalModal(true);
      setNoScroll(true);
    }
  };

  const handleChangeInputVideo = (event) => {
    const value = event.target.value;
    setYoutubeUrl(value);
    if (value !== data.video_url) {
      setEnableSubmitButton(true);
      return;
    }
    setEnableSubmitButton(false);
  };

  const functionDeleteEvidence = async (_, id) => {
    await ProfileAPI.deleteImages(id);
    getUniversityData(true);
  };

  const handleCloseUpload = () => {
    setUsingModalUpload(false);
    getUniversityData(true);
    setNoScroll(false);
  };

  const configLogo = {
    limitUpload: 1,
    functionPostFile: functionPostLogo,
    functionDeleteEvidence: ProfileAPI.deleteUniversityLogo,
    functionCloseAndUpdate: handleCloseUpload,
    isFromSettingsLogo: true,
  };

  const configGallery = {
    limitUpload: 3,
    functionPostFile: functionPostImages,
    functionDeleteEvidence: functionDeleteEvidence,
    functionCloseAndUpdate: handleCloseUpload,
  };

  const handleClickPreview = () => {
    const formData = formRef?.current?.getData();
    const dataMounted = {
      ...formData,
      color: color,
      video_url: youtubeUrl,
      logo: logo,
      photos: data.photos,
    };

    setPreviewData(dataMounted);
    setUsingPreviewModal(true);
    setNoScroll(true);
  };

  const handleClosePreview = () => {
    setUsingPreviewModal(false);
    setNoScroll(false);
  };

  const configWalModal = {
    bubbleText: 'Tudo certo!',
    ParagraphText: `Seu perfil foi atualizado com sucesso!`,
    buttonConfirmText: 'OK',
    showButtonCancel: false,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
    },
  };

  const handleChangeColor = (event) => {
    const color = event.target.value;
    if (color.toLowerCase() !== data?.profile_color?.toLowerCase()) {
      setEnableSubmitButton(true);
    } else {
      setEnableSubmitButton(false);
    }

    if (!color.includes('#')) {
      const includeColor = '#' + color;
      setColor(includeColor.toLowerCase());
      return;
    }
    setColor(color.toLowerCase());
  };

  return (
    <Fragment>
      <ModalInformation
        modalOpenState={openWalModal}
        config={configWalModal}
        buttonConfirmFunction={() => {
          setOpenWalModal(false);
          setNoScroll(false);
        }}
      />
      <PreviewModal
        open={usingPreviewModal}
        setOpen={handleClosePreview}
        previewData={previewData}
      />
      <ModalUploadEvidences
        open={usingModalUpload}
        evidenceFiles={images}
        evidenceLinks={[]}
        acceptFileTypes={['JPEG', 'PNG']}
        functionCloseAndUpdate={functionClose}
        idPost={0}
        headerTitle={'Adicionar imagem'}
        uniqueUploadType={'file'}
        limitUpload={1}
        buttonLabel={'SALVAR'}
        {...currentConfig}
      />
      <Container>
        <>
          <HeaderTitle size="main">Identidade visual</HeaderTitle>
          <HeaderSubTitle useNegative>
            Insira os dados de identidade visual da sua Instituição de ensino.
          </HeaderSubTitle>
        </>

        <ContainerComponentHeader>
          <ContainerHeader>
            <HeaderTitle>Cor principal</HeaderTitle>
            <HeaderSubTitle>Aplicada no cabeçalho do perfil.</HeaderSubTitle>
          </ContainerHeader>

          <ContainerColorInput>
            <ColorInput
              type="color"
              value={color}
              onChange={handleChangeColor}
              ref={colorRef}
            />

            <ContainerColorArrow>
              <input value={color} onChange={handleChangeColor} />
            </ContainerColorArrow>
          </ContainerColorInput>
        </ContainerComponentHeader>

        <Divider />

        <ContainerComponentHeader>
          <ContainerHeader>
            <HeaderTitle>Logo</HeaderTitle>
            <HeaderSubTitle>
              Faça o upload do logotipo. Recomendamos que não seja utilizada a
              marca na cor branca.
              <UploadImageButton onClick={handleClickUploadLogo}>
                <Gallery color={'#009291'} size={25} />
                Definir logotipo
              </UploadImageButton>
            </HeaderSubTitle>
          </ContainerHeader>

          <ContainerLogo>
            <img src={logo} />
          </ContainerLogo>
        </ContainerComponentHeader>
      </Container>

      <Container top={50}>
        <>
          <HeaderTitle size="main">Institucional</HeaderTitle>
          <HeaderSubTitle useNegative>
            Insira as informações da sua Instituição de ensino.
          </HeaderSubTitle>
        </>
        <Form ref={formRef}>
          <ContainerComponentHeader>
            <ContainerHeader>
              <HeaderTitle>Sobre nós</HeaderTitle>
              <HeaderSubTitle>Escreva uma breve descrição.</HeaderSubTitle>
            </ContainerHeader>

            <div style={{ width: '70%' }}>
              <DescriptionInput name="about_us" height="2em" />
            </div>
          </ContainerComponentHeader>

          <Divider />

          <ContainerComponentHeader>
            <ContainerHeader>
              <HeaderTitle>Missão</HeaderTitle>
              <HeaderSubTitle>Descreva a sua missão.</HeaderSubTitle>
            </ContainerHeader>

            <div style={{ width: '70%' }}>
              <DescriptionInput name="mission" height="2em" />
            </div>
          </ContainerComponentHeader>

          <Divider />

          <ContainerComponentHeader>
            <ContainerHeader>
              <HeaderTitle>Visão</HeaderTitle>
              <HeaderSubTitle>Descreva a sua visão.</HeaderSubTitle>
            </ContainerHeader>

            <div style={{ width: '70%' }}>
              <DescriptionInput name="vision" height="2em" />
            </div>
          </ContainerComponentHeader>

          <Divider />

          <ContainerComponentHeader>
            <ContainerHeader>
              <HeaderTitle>Valores</HeaderTitle>
              <HeaderSubTitle>Descreva os seus valores.</HeaderSubTitle>
            </ContainerHeader>

            <div style={{ width: '70%' }}>
              <DescriptionInput name="values" height="2em" />
            </div>
          </ContainerComponentHeader>
        </Form>
      </Container>

      <Container top={50}>
        <>
          <HeaderTitle size="main">Multimídia</HeaderTitle>
          <HeaderSubTitle useNegative>
            Insira aqui as mídias da sua Instituição de ensino.
          </HeaderSubTitle>
        </>

        <ContainerComponentHeader>
          <ContainerHeader>
            <HeaderTitle>Vídeo institucional</HeaderTitle>
            <HeaderSubTitle>
              Insira o link do youtube do seu vídeo institucional.
            </HeaderSubTitle>
          </ContainerHeader>

          <WrapperInputVideo>
            <YoutubeInput
              onChange={handleChangeInputVideo}
              value={youtubeUrl}
              placeholder="Insira o link"
            />
            {!isValidLinkYoutube && youtubeUrl.length > 0 && (
              <span>Insira um link de vídeo válido.</span>
            )}
          </WrapperInputVideo>
        </ContainerComponentHeader>

        <Divider />

        <ContainerComponentHeader>
          <ContainerHeader>
            <HeaderTitle>Galeria de fotos</HeaderTitle>
            <HeaderSubTitle>
              Insira até 3 fotos da sua Instituição de ensino para fazerem parte
              do seu perfil.
              <UploadImageButton onClick={handleClickUploadImage}>
                <Gallery color={'#009291'} size={25} />
                Adicionar imagem
              </UploadImageButton>
            </HeaderSubTitle>
          </ContainerHeader>

          <ContainerImages>
            {data?.photos?.length > 0 &&
              data?.photos?.map((item) => (
                <div>
                  <img src={item.file} />
                  <span onClick={() => functionDeleteEvidence(null, item.id)}>
                    <Trash color={'#FFF'} size={15} />
                  </span>
                </div>
              ))}
          </ContainerImages>
        </ContainerComponentHeader>

        <Divider />

        <ContainerComponentHeader>
          <ContainerHeader>
            <HeaderTitle>Simulação</HeaderTitle>
            <HeaderSubTitle>
              Veja como as empresas irão visualizar o seu perfil.
            </HeaderSubTitle>
          </ContainerHeader>

          <PreviewButton onClick={handleClickPreview}>
            <img src={eye} />
            Pré-visualizar perfil
          </PreviewButton>
        </ContainerComponentHeader>
      </Container>

      <SaveButton disabled={!enableSubmitButton} onClick={handleSubmit}>
        Salvar
      </SaveButton>
    </Fragment>
  );
}
