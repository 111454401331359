import * as Yup from 'yup';
import { isValidDate } from 'utils/validation';

export async function experienceFormSchemas(type) {
  const ProfessionalExperienceSchema = Yup.object().shape({
    role: Yup.string().required('Por gentileza, informe o cargo.'),
    company: Yup.string().required('Você deve informar o nome da empresa.'),
    location: Yup.lazy((value) => {
      switch (typeof value) {
        case 'object':
          return Yup.object()
            .shape({
              value: Yup.string().nullable(),
            })
            .typeError('Escolha uma das opções de cidade.')
            .required('Escolha uma das opções de cidade.');
        case 'string':
          return Yup.string().required('Campo obrigatório');
        default:
          return Yup.mixed();
      }
    }),
    country: Yup.object().required('Campo obrigatório'),
    area: Yup.object()
      .shape({
        value: Yup.number().nullable(),
      })
      .typeError('Escolha uma das opções de conhecimento.')
      .required('Escolha uma das opções de conhecimento.'),
    section: Yup.object()
      .shape({
        value: Yup.string().nullable(),
      })
      .typeError('Escolha uma das opções de setores.')
      .required('Escolha uma das opções de setores.'),
    startedAt: Yup.date()
      .required('Escolha uma data de início.')
      .test(
        'isValidStartedAt',
        'Preencha a data no formato dia/mês/ano',
        (value) => {
          if (value) return isValidDate('startedAt');
          return false;
        }
      )

      .typeError('Preencha a data no formato dia/mês/ano'),
    endedAt: Yup.date().when('isCurrentJob', {
      is: false,
      then: Yup.date()
        .min(
          Yup.ref('startedAt'),
          'A data de término deve ser maior que Data de início.'
        )
        .required('Escolha uma data de término.')
        .test(
          'isValidEndAt',
          'Preencha a data no formato dia/mês/ano',
          (value) => {
            if (value) return isValidDate('endedAt');
            return false;
          }
        )
        .typeError('Preencha a data no formato dia/mês/ano'),
    }),

    experienceType: Yup.object().required('Campo obrigatório').nullable(),
    knowledges: Yup.array()
      .of(Yup.string())
      .required('Campo obrigatório')
      .max(5, 'Você deve escolher no máximo 5'),
    dedicatedHours: Yup.object().nullable().required('Campo obrigatório.'),
  });

  const LifeExperienceSchema = Yup.object().shape({
    role: Yup.string().required('Por gentileza, informe o título.'),
    experienceType: Yup.object().required('Campo obrigatório'),
    knowledges: Yup.array()
      .required('Campo obrigatório')
      .of(Yup.string())
      .max(5, 'Você deve escolher no máximo 5'),
    country: Yup.object().required('Campo obrigatório'),

    city: Yup.string().required('Campo obrigatório'),
    startedAt: Yup.date()
      .required('Escolha uma data de início.')
      .test(
        'isValidStartedAt',
        'Preencha a data no formato dia/mês/ano',
        (value) => {
          if (value) return isValidDate('startedAt');
          return false;
        }
      )

      .typeError('Preencha a data no formato dia/mês/ano'),
    endedAt: Yup.date().when('inProgress', {
      is: false,
      then: Yup.date()
        .min(
          Yup.ref('startedAt'),
          'A data de término deve ser maior que Data de início.'
        )
        .required('Escolha uma data de término.')
        .test(
          'isValidEndAt',
          'Preencha a data no formato dia/mês/ano',
          (value) => {
            if (value) return isValidDate('endedAt');
            return false;
          }
        )
        .typeError('Preencha a data no formato dia/mês/ano'),
    }),
    dedicatedHours: Yup.object()
      .shape({
        value: Yup.number().nullable(),
      })
      .typeError('Campo obrigatório.')
      .required('Campo obrigatório.'),
    dedicatedDays: Yup.object()
      .shape({
        value: Yup.number().nullable(),
      })
      .typeError('Campo obrigatório.')
      .required('Campo obrigatório.'),
  });

  if (type === 'career') return ProfessionalExperienceSchema;
  if (type === 'life') return LifeExperienceSchema;
  return null;
}
