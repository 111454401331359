import DataNotFoundIcon from 'assets/ComponentIcons/DataNotFoundIcon';
import useMediaQuery from 'hooks/useMediaQuery';
import ReactHtmlParser from 'react-html-parser';

import React, { useContext } from 'react';

import { Container, GenericButton, LogoArea, Subtitle, Title } from './style';
import BaseLayoutContext from 'contexts/base-layout';

// This component is standard for when we have an empty listing.

export default function GenericMessage({
  title,
  subtitle,
  subtitleResponsible,
  buttonLabel,
  buttonLabelResponsible,
  buttonFunc,
  style = null,
  icon = null,
  resetIndex = false,
}) {
  const { universityColor } = useContext(BaseLayoutContext);

  const mobile = useMediaQuery('(max-width: 425px)');

  return (
    <Container style={style ? style : null}>
      {icon ? (
        <>{icon}</>
      ) : (
        <LogoArea resetIndex={resetIndex} universityColor={universityColor}>
          <DataNotFoundIcon color={universityColor || '#009291'} />
        </LogoArea>
      )}
      <Title>{ReactHtmlParser(title)}</Title>
      <Subtitle>{mobile ? subtitleResponsible : subtitle || subtitle}</Subtitle>
      {buttonFunc && (
        <GenericButton onClick={buttonFunc} universityColor={universityColor}>
          {mobile ? buttonLabelResponsible : buttonLabel || buttonLabel}
        </GenericButton>
      )}
    </Container>
  );
}
