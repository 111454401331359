import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { FastField, Formik } from 'formik';
import { format, isValid, parse } from 'date-fns';
import { Grid, List, ListItem } from '@material-ui/core';

import { careerExperienceAdapter, lifeExperienceAdapter } from 'adapters/student/experiencesAdapter';
import {
  getProfessionalExperience,
  getProfessionalExperienceAreas,
  saveProfessionalExperience,
  updateProfessionalExperience,
} from 'services/student/professionalExperience';
import { getCountries, getOptions, saveLifeExperience, updateLifeExperience } from 'services/student/lifeExperience';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles
  from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { bool, func, shape } from 'prop-types';
import { triggerHotjarEvent } from 'utils/hotjar';
import { Mixpanel } from 'utils/mixpanel';
import ExperienceLife from './components/ExperiencieLife';
import ExperienceCareer from './components/ExperienceCareer';
import { experienceFormSchemas } from './schemas/experienceFormSchemas';
import { triggerPageView } from '../../../../../../utils/analytics';
import SaveButtonsTests from 'components/SaveButtonsTests';
import BaseLayoutContext from 'contexts/base-layout';
import { useDataManagement } from 'views/Profile/Hooks/useDataManagement';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { actionsProfileStore } from 'stores/profile.store';

function ExperienceForm({
  classes,
  isEdit,
  closeModal,
  handleIsLoadingState,
  experience,
  metadata,
  setExperienceFormChosen,
  visibleLifeExperiences,
  visibleCareerExperiences,
  setVisibleCareerExperiences,
  setVisibleLifeExperiences,
  cardDataKey,
  cardDataKeyTwo,
  isCareer,
  cardData,
  setUserData,
  fetchGamificationData,
}) {
  const [validationSchema, setValidationSchema] = useState(null);
  const [areasOptions, setAreasOptions] = useState([]);
  const [knowledgesOptions, setKnowledgesOptions] = useState([]);
  const [experienceTypesOptions, setExperienceTypesOptions] = useState([]);
  const [lifeExperienceOptions, setLifeExperienceOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [chosenForm, setChosenForm] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { handleVisibleItem } = useDataManagement(setUserData);
  const [initialValues, setInitialValues] = useState({
    experienceType: '',
    city: '',
    knowledges: [],
    id: '',
    isCurrentJob: false,
    inProgress: false,
    role: '',
    description: '',
    company: '',
    location: null,
    area: null,
    section: null,
    startedAt: '',
    endedAt: '',
    country: '',
    dedicatedHours: null,
    dedicatedDays: null,
    type: isCareer ? 'career' : 'life',
  });

  const { universityColor } = useContext(BaseLayoutContext);
  const { updateCard } = actionsProfileStore;

  async function getLifeExperienceOptions() {
    const lifeOptions = await getOptions();
    setLifeExperienceOptions(lifeOptions);
  }

  function successRequest(response) {
    if (response.message) {
      customSnackbar(
        `Erro ao gravar seus dados. Se o erro persistir,
          entre em contato com o suporte avisando
          sobre o erro: ${response.message}
        `,
        'error'
      );

      return false;
    }

    customSnackbar('Experiência salva com sucesso!', 'confirmation');

    return true;
  }

  async function getCountriesOptions() {
    const options = await getCountries();

    setCountriesOptions(options);
  }

  function getDedicatedHoursAndDays(max, label) {
    const hours = [];
    for (let i = 1; i <= max; i++) {
      hours.push({
        label: i > 1 ? `${i} ${label}s` : `${i} ${label}`,
        value: i,
      });
    }
    return hours;
  }

  async function getProfessionalExperienceAreasHandle() {
    const options = await getProfessionalExperienceAreas();
    setLocationOptions(options.cities);
    setAreasOptions(options.area);
    setKnowledgesOptions(options.knowledges);
    setExperienceTypesOptions(options.experience_types);
    setSectionOptions(options.section);

    if (isEdit) {
      const {
        area,
        id,
        isCurrentJob,
        role,
        company,
        section,
        location,
        startedAt,
        endedAt,
        dedicatedHours,
        knowledges,
        description,
        dedicatedDays,
        type,
        experienceType,
        inProgress,
        city,
        country = {},
      } = experience;
      const brazil = { label: 'Brasil', value: '076' };
      setInitialValues({
        ...initialValues,
        id,
        isCurrentJob,
        role,
        company,
        location,
        description,
        city,
        area,
        section,
        type,
        inProgress,
        country: country && !country.value ? brazil : country,
        dedicatedHours: {
          label:
            dedicatedHours > 1
              ? `${dedicatedHours} Horas`
              : `${dedicatedHours} Hora`,
          value: dedicatedHours,
        },
        dedicatedDays: {
          label:
            dedicatedDays > 1
              ? `${dedicatedDays} Dias`
              : `${dedicatedDays} Dia`,
          value: dedicatedDays,
        },
        knowledges,
        experienceType,
        startedAt: format(
          parse(startedAt, 'dd/MM/yyyy', new Date()),
          'yyyy-MM-dd'
        ),
        endedAt: isValid(parse(endedAt, 'dd/MM/yyyy', new Date()))
          ? format(parse(endedAt, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
          : '',
      });
    }
  }

  useLayoutEffect(() => {
    async function getSchema() {
      if (experience) {
        const schema = await experienceFormSchemas(experience.type);
        setValidationSchema(schema);
        setChosenForm(experience.type);
      } else {
        const schema = await experienceFormSchemas(initialValues.type);
        setValidationSchema(schema);
      }
    }

    getSchema();
  }, []);

  useEffect(() => {
    getCountriesOptions();
    getLifeExperienceOptions();
    getProfessionalExperienceAreasHandle();

    handleIsLoadingState(false);

    if (metadata.university_slug !== 'uniexemplo') {
      triggerHotjarEvent('career_experience');
    }
  }, []);

  return (
    <Grid
      container
      className={classes.fullScreenGridContainer}
      justify="center"
    >
      <Grid item xs md={12} className={classes.fullScreenGrid}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            if (isSubmitted) {
              return;
            }
            setIsSubmitted(true);
            handleIsLoadingState(true);
            if (isCareer) {
              const newProfessionalExperience = isEdit
                ? await updateProfessionalExperience(
                    careerExperienceAdapter(values)
                  )
                : await saveProfessionalExperience(
                    careerExperienceAdapter(values)
                  );
              const response = await getProfessionalExperience();

              if (!isEdit && visibleCareerExperiences.length < 3) {
                const updatedVisibleExperiences = [
                  ...visibleCareerExperiences,
                  newProfessionalExperience.id,
                ];

                const visibilityItemConfig = {
                  items: updatedVisibleExperiences,
                  item: newProfessionalExperience,
                  reqKey: 'experience',
                  isUpdate: true,
                  cardData,
                  dataKey: cardDataKey,
                  updateKey: 'professional_experience',
                  setVisibleItems: setVisibleCareerExperiences,
                };

                handleVisibleItem(visibilityItemConfig);
              }
              if (successRequest(newProfessionalExperience)) {
                if (values.id) {
                  newProfessionalExperience.id = values.id;
                }
                cardDataKey &&
                  updateCard(cardDataKey, response.professional_experience);

                await fetchGamificationData();
              }
              Mixpanel.track('Salvou uma Experiência de Carreira');
              triggerPageView('student/experiencias/carreira/cadastro');
            } else {
              const lifeExperience = isEdit
                ? await updateLifeExperience(lifeExperienceAdapter(values))
                : await saveLifeExperience(lifeExperienceAdapter(values));
              const response = await getProfessionalExperience();

              if (!isEdit && visibleLifeExperiences.length < 3) {
                const updatedVisibleExperiences = [
                  ...visibleLifeExperiences,
                  lifeExperience.id,
                ];

                const visibilityItemConfig = {
                  items: updatedVisibleExperiences,
                  item: lifeExperience,
                  reqKey: 'life_experience',
                  isUpdate: true,
                  cardData,
                  dataKey: cardDataKeyTwo,
                  updateKey: 'life_experience',
                  setVisibleItems: setVisibleLifeExperiences,
                };

                handleVisibleItem(visibilityItemConfig);
              }

              if (successRequest(lifeExperience)) {
                lifeExperience.type = 'life';
                if (values.id) {
                  lifeExperience.id = values.id;
                }

                cardDataKeyTwo &&
                    updateCard(cardDataKeyTwo, response.life_experience);
                await fetchGamificationData();
                triggerPageView('student/experiencias/vida/cadastro');
                Mixpanel.track('Salvou uma Experiência de Vida');
              }
            }

            handleIsLoadingState(false);
            closeModal();
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              {isEdit && (
                <FastField
                  type="hidden"
                  id="id"
                  name="id"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              )}
              <List className={classes.list}>
                <ListItem className={classes.listItem}></ListItem>
                {isCareer ? (
                  <ExperienceCareer
                    classes={classes}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    values={values}
                    isEdit={isEdit}
                    setFieldValue={setFieldValue}
                    countriesOptions={countriesOptions}
                    setFieldTouched={setFieldTouched}
                    locationOptions={locationOptions}
                    areasOptions={areasOptions}
                    knowledgesOptions={knowledgesOptions}
                    getDedicatedHours={getDedicatedHoursAndDays}
                    sectionOptions={sectionOptions}
                    experienceTypesOptions={experienceTypesOptions}
                    setExperienceFormChosen={setExperienceFormChosen}
                    universityColor={universityColor}
                  />
                ) : (
                  <ExperienceLife
                    classes={classes}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    values={values}
                    isEdit={isEdit}
                    setFieldValue={setFieldValue}
                    countriesOptions={countriesOptions}
                    setFieldTouched={setFieldTouched}
                    getDedicatedHoursAndDays={getDedicatedHoursAndDays}
                    lifeExperienceOptions={lifeExperienceOptions}
                    initialValues={initialValues}
                    setExperienceFormChosen={setExperienceFormChosen}
                    universityColor={universityColor}
                  />
                )}
                <ListItem className={classes.listItem}>
                  <Grid container>
                    <SaveButtonsTests type="submit" round />
                  </Grid>
                </ListItem>
              </List>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
}

ExperienceForm.propTypes = {
  classes: shape.isRequired,
  isEdit: bool,
  experience: shape,
  closeModal: func.isRequired,
  updateProfessionalState: func.isRequired,
  handleIsLoadingState: func.isRequired,
  setVisibleExperiences: func.isRequired,
  visibleExperiences: shape.isRequired,
};

ExperienceForm.defaultProps = {
  isEdit: false,
  experience: null,
};

export default withStyles(ProfessionalExperienceFormStyles)(ExperienceForm);
