import CardContainerButtons from './CardContainerButtons';
import CardContainerInformation from './CardContainerInformation';
import CardImage from './CardImage';
import CardInformation from './CardInformation';
import CardStatus from './CardStatus';
import CardTitle from './CardTitle';
import CardContainerRoot from './CardContainerRoot';

export const Card = {
  Root: CardContainerRoot,
  Title: CardTitle,
  Status: CardStatus,
  Information: CardInformation,
  Image: CardImage,
  ContainerButton: CardContainerButtons,
  ContainerInformation: CardContainerInformation,
};
