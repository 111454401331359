import React, {
  Fragment,
  useRef,
  useEffect,
  useContext,
  useState,
} from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import {
  WrapperContainer,
  TitleForm,
  MultiStepContainer,
  InfoWrapper,
} from 'views/ProfessionalStatus/styles/styles';
import Select from '../../../components/Select/select';
import StepHeader from '../../../components/StepsHeader';
import { useFormContext } from 'views/ProfessionalStatus/context';
import { icons } from 'views/ProfessionalStatus/utils/OptionsIcon';
import FooterForm from '../../../components/StepsFooter/FooterForm';
import professionalStatusApi from 'views/ProfessionalStatus/services/professionalStatusApi';
import BaseLayoutContext from 'contexts/base-layout';
import { validationSchemaStepThree } from './schema';

export function StepThree({
  isProfessionalStatusDefined,
  isSurveysDefined,
  universityColor,
  prevsFormStep,
  nextFormStep,
  options,
  profileData,
}) {
  const formRef = useRef();
  const { formData, setFormValues, setStep } = useFormContext();
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { closeModal } = useContext(BaseLayoutContext) || {};
  const stepsToShow = isProfessionalStatusDefined
    ? ['StepOne', 'StepTwo']
    : ['StepOne', 'StepTwo', 'StepThree', 'StepFour'];

  const reverseTransformArrayQuestion = (transformedQuestion) => {
    const { R, Q } = transformedQuestion;
    return R.map((option) => ({
      label: option.label,
      value: option.label,
    }));
  };

  const topDiv = useRef(null);

  const scrollToTopDiv = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTopDiv();
  }, []);

  const reverseTransformSingleQuestion = (transformedQuestion) => {
    const { R, Q } = transformedQuestion;
    return {
      label: R.label,
      value: R.label,
    };
  };

  const reverseTransformObject = (transformedObject) => {
    const originalData = transformedObject.map((survey) => {
      const originalSurvey = { ...survey };
      originalSurvey.answers = {};

      for (const key in survey.answers) {
        const transformedQuestion = survey.answers[key];

        if (Array.isArray(transformedQuestion.R)) {
          originalSurvey.answers[
            `question${Number(key)}`
          ] = reverseTransformArrayQuestion(transformedQuestion);
        } else {
          originalSurvey.answers[
            `question${Number(key)}`
          ] = reverseTransformSingleQuestion(transformedQuestion);
        }
      }
      return originalSurvey;
    });

    return originalData;
  };

  useEffect(() => {
    if (profileData && profileData?.answered_survey?.length > 0) {
      const originalData = reverseTransformObject(profileData?.answered_survey);
      formRef.current.setData({ ...originalData[0].answers });
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [profileData]);

  useEffect(() => {
    if (
      formData?.socioeconomic_status &&
      Object.keys(formData.socioeconomic_status).length > 0
    ) {
      const originalData = reverseTransformObject([
        formData.socioeconomic_status,
      ]);
      formRef.current.setData({ ...originalData[0].answers });
    }
  }, [formData]);

  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  const findOptionIndex = (questionDataOption, questionNumber) => {
    return options.surveys[0].data[
      Number(questionNumber - 1)
    ].options.findIndex((option) => option.label === questionDataOption.label);
  };

  const transformArrayQuestion = (
    questionData,
    questionNumber,
    questionTitle
  ) => {
    const questionIndices = questionData.map((questionDataOption) =>
      findOptionIndex(questionDataOption, questionNumber)
    );

    const letters = questionIndices.map((index) =>
      index >= 0 && index < alphabet.length ? alphabet[index] : null
    );

    let arrayLabelValue = [];
    questionData.map((option, index) => {
      arrayLabelValue.push({ label: option.value, letter: letters[index] });
    });

    return {
      R: arrayLabelValue,
      Q: questionTitle,
    };
  };

  const transformSingleQuestion = (
    questionData,
    questionNumber,
    questionTitle
  ) => {
    const questionIndex = findOptionIndex(questionData, questionNumber);

    const letter =
      questionIndex >= 0 && questionIndex < alphabet.length
        ? alphabet[questionIndex]
        : null;

    return {
      R: { label: questionData.label, letter: letter },
      Q: questionTitle,
    };
  };

  const handleSubmit = async (data) => {
    try {
      setLoading(true);
      const validationSchema = validationSchemaStepThree(options.surveys);
      await validationSchema.validate(data, { abortEarly: false });
      const transformedObject = {};
      const transformObject = (object) => {
        for (const key in object) {
          const questionNumber = key.replace('question', '');
          const questionData = object[key];
          const questionTitle =
            options.surveys[0].data[questionNumber - 1].title;

          transformedObject[questionNumber] = Array.isArray(questionData)
            ? transformArrayQuestion(
                questionData,
                questionNumber,
                questionTitle
              )
            : transformSingleQuestion(
                questionData,
                questionNumber,
                questionTitle
              );
        }
      };

      transformObject(data);

      if (isProfessionalStatusDefined && isEdit) {
        const response = await professionalStatusApi.putFirstAccess({
          ...data,
          ...formData,
          socioeconomic_status: {
            ses_survey: options.surveys[0].id,
            answers: transformedObject,
          },
        });
        nextFormStep();
        closeModal();
        return;
      }
      if (!isEdit && isProfessionalStatusDefined) {
        const response = await professionalStatusApi.postFirstAccess({
          ...data,
          ...formData,
          socioeconomic_status: {
            ses_survey: options.surveys[0].id,
            answers: transformedObject,
          },
        });
        nextFormStep();
        closeModal();
      }
      setFormValues({
        ...data,
        socioeconomic_status: {
          ses_survey: options.surveys[0].id,
          answers: transformedObject,
        },
      });
      nextFormStep();
    } catch (error) {
      setLoading(false);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handlePrevsFormStep = () => {
    const data = formRef?.current?.getData();
    setFormValues({
      ...data,
    });
    setStep((prevsStep) => prevsStep - 1);
  };

  return (
    <Fragment>
      <div ref={topDiv}>
        <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
          <WrapperContainer>
            <MultiStepContainer>
              <StepHeader
                Steps={stepsToShow}
                universityColor={universityColor}
                isProfessionalStatusDefined={isProfessionalStatusDefined}
              />
            </MultiStepContainer>
            <InfoWrapper>
              <TitleForm universityColor={universityColor}>
                Agora, responda algumas perguntas solicitadas pela sua
                Instituição de ensino:
              </TitleForm>

              {options &&
                options.surveys.map((survey) => (
                  <div key={survey.id}>
                    {survey.data &&
                      survey.data.map((question, index) => (
                        <div key={question.title}>
                          <Select
                            fullValue={true}
                            icon={icons.iconCommentAltMinus}
                            universityColor={universityColor}
                            label={question.title}
                            name={`question${index + 1}`}
                            options={question.options.map((option) => ({
                              label: option.label,
                              value: option.value,
                            }))}
                            isMulti={question.type === 'checkbox'}
                          />
                        </div>
                      ))}
                  </div>
                ))}
            </InfoWrapper>

            {isProfessionalStatusDefined ? (
              <FooterForm
                haveFinish={true}
                haveBack={true}
                haveNext={false}
                prevsState={handlePrevsFormStep}
                universityColor={universityColor}
                loading={loading}
              />
            ) : (
              <FooterForm
                haveNext={true}
                haveBack={true}
                prevsState={prevsFormStep}
                universityColor={universityColor}
              />
            )}
          </WrapperContainer>
        </Form>
      </div>
    </Fragment>
  );
}
