import styled from 'styled-components';

export const SelectCategoryContainer = styled.div`
  display: flex;
  min-height: 60vh;
  align-items: center;
  justify-content: center;
  gap: 20px;

  > span {
    font-size: 20px;
    text-align: center;
    color: #777;
    width: 100%;
    margin: 32px 0;
  }
`;

export const CategoryGrid = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 32px;
  justify-content: center;
  align-items: center;
  margin: 16px auto;

  @media (max-width: 1060px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const CategoryButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
  height: 224px;
  width: 224px;
  background-color: #fff;
  border: 1px solid #4b4b4d;
  border-radius: 12px;
  cursor: pointer;
  gap: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }

  > span {
    font-size: 18px;
    font-weight: 700;
    color: #4b4b4d;

    span {
      font-size: 13px;
      color: #777;
    }
  }

  p {
    font-size: 12px;
    color: #606062;
  }
`;
