import React, { useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';

import {
  UnInput,
  UnInputLabel,
  ContainerLabelInput,
  ErrorMessage,
} from './styled';

export default function InputWorkload({
  label,
  name,
  margin,
  width,
  height,
  ...rest
}) {
  const inputRef = useRef(null);
  const [workload, setWorkload] = useState('');
  const { fieldName, registerField, defaultValue, error } = useField(name);

  const maskHours = (value) => {
    const newValue = value.length === 1 ? `0${value}` : value;

    let varTemp = newValue || '';
    if (varTemp >= 0 && typeof varTemp === 'number') {
      varTemp = String(varTemp);
    }

    return varTemp
      .replace(/\D/g, '')
      .replace(/^(\d+)?(\d{2})$/, (match, p1, p2) => {
        const hours = p1 || '0';
        return `${parseInt(hours, 10)}:${p2}`;
      });
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = maskHours(workload);
    }
  }, [workload]);

  useEffect(() => {
    if (defaultValue) {
      setWorkload(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        ref.setInputValue && ref.setInputValue(value);
        setWorkload(value);
      },
      getValue: () => {
        return inputRef.current.value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput margin={margin} width={width} height={height}>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput
        width={width}
        height={height}
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={(value) => setWorkload(value.target.value)}
        {...rest}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </ContainerLabelInput>
  );
}
