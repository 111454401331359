import {
  BackgroundModals,
  ContainerModalFeedback,
  BodyModalFeedback,
  HeaderModalFeedback,
  ParagraphFeedback,
  ButtonCloseFeedback,
  ContainerContent,
  ContainerColumn,
  ListParagraph,
  CustomTextParagraph,
} from './styled';

export default function ModalFeedback({
  feedback,
  handleCloseModal,
  universityColor,
}) {
  return (
    <BackgroundModals>
      <ContainerModalFeedback>
        <HeaderModalFeedback universityColor={universityColor}>
          <ParagraphFeedback>Feedback</ParagraphFeedback>

          <ButtonCloseFeedback onClick={handleCloseModal}>
            X
          </ButtonCloseFeedback>
        </HeaderModalFeedback>

        <BodyModalFeedback>
          <CustomTextParagraph
            color="#6d6d6d"
            fontWeight="500"
            fontSize="14px"
            style={{ marginBottom: '5px' }}
          >
            O currículo não foi curtido por:
          </CustomTextParagraph>

          <ContainerContent
            style={{
              marginTop: '0px',
              backgroundColor: '#fff',
              border: '1px solid #d0d0d0',
              borderRadius: '4px',
            }}
          >
            <ContainerColumn>
              <ul>
                {feedback.reasons && feedback.reasons.length
                  ? feedback.reasons.map((reason, index) => (
                      <ListParagraph key={index}>{reason}</ListParagraph>
                    ))
                  : null}

                {feedback.reason_description &&
                feedback.reason_description.length ? (
                  <ListParagraph
                    key={feedback.reasons ? feedback.reasons.length + 1 : 1}
                  >
                    {feedback.reason_description}
                  </ListParagraph>
                ) : null}
              </ul>
            </ContainerColumn>
          </ContainerContent>
        </BodyModalFeedback>
      </ContainerModalFeedback>
    </BackgroundModals>
  );
}
