import React, { useContext, useEffect, useState } from 'react';
import { ContainerCards, ContainerGenericMessage } from '../styled';
import GenericCardsJobOffers from '../../NewCards/Cards';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import GenericMessage from 'components/GenericMessage';
import { ContainerButtonsPagination } from '../../styled';
import PaginationSeeMore from '../../PaginationLoadMore';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import GamificationLevelOneIncompleteModal from 'views/JobOffers/components/Tabs/TabsJobOffers/GamificationLevelOneIncompleteModal';
import useStepOneSorting from 'views/Profile/UIComponents/Gamification/Components/Cards/stepOne/useStepOneSorting';
import BlankModalInformation from 'components/BlankInformationModal';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import { openCoverLetterForm } from 'components/JobDetailsModal/useCases/utils';
import BaseLayoutContext from 'contexts/base-layout';
import { checkHasCoverLetter } from 'services/student/profile';
import { DetailsJobOffer } from '../../JobDetails';
import CurriculumContextProvider from 'contexts/CurriculumContext';
import { coverLetterConfig } from '../../Card/useCases/IModalConfig';
import ModalInformation from 'components/informationModal';
import { configLocationModal } from '../../utils/wrapperTextsConfig';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';

const FavoriteJobOffers = (props) => {
  const {
    universityColor,
    listFavoriteJobOffers,
    search,
    fetchListFavoriteJobOffers,
  } = props;
  const {
    openSnackbar,
    setGlobalState,
  } = useContext(BaseLayoutContext);
  const {
    visibleItems,
    setVisibleItems,
    filterData,
    closeModal,
    handleIsLoadingState,
    openModalWithContent,
    updateAvatarState,
    fetchGamificationData,
    profileData,
    setProfileData,
    updateProfileState,
    handleShowSpecialNeeds,
    zipCodeStudent,
    stateAndCityStudent,
  } = useContext(JobOffersContext);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedJobMatch, setSelectedJobMatch] = useState(null);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const dataFavoriteJobOffers = listFavoriteJobOffers?.data?.results;
  const [isApplying, setIsApplying] = useState(false);
  const [
    jobIdBeingProcessedAsFavorite,
    setJobIdBeingProcessedAsFavorite,
  ] = useState(null);
  const [
    jobIdCandidatureBeingProcessed,
    setJobIdCandidatureBeingProcessed,
  ] = useState(null);
  const [
    openGamificationLevelOneIncompleteModal,
    setOpenGamificationLevelOneIncompleteModal,
  ] = useState(false);
  const [openCoverLetterModal, setOpenCoverLetterModal] = useState(false);
  const [appliedJobId, setAppliedJobId] = useState(null);
  const [hasCoverLetter, setHasCoverLetter] = useState(false);
  const { gamificationLevel, stepOneData } = useContext(ProfileContext);

  const [openModalLocalization, setOpenModalLocalization] = useState(false);
  const [selectJobId, setSelectJobId] = useState(null);
  const [location, setLocation] = useState(null);

  const gamificationItems = useStepOneSorting(stepOneData);
  const gamificationLevelOneIncomplete = gamificationLevel <= 1;

  const handleCloseModalLocalization = () => {
    setOpenModalLocalization(false)
  };

  const openLocalizationModal = async (jobId, location) => {
    setSelectJobId(jobId);
    setLocation(location)

    if (!gamificationLevelOneIncomplete &&
      location && location?.length !== 0 &&
      Array.isArray(location) && !location?.includes(stateAndCityStudent)) {
      setOpenModalLocalization(true);
    } else {
      await candidateJobOffer(jobId);
    }
  };

  const onClickEdit = (jobId) => {
    openModalWithContent(
      <ProfileForm
        handleShowSpecialNeeds={handleShowSpecialNeeds}
        handleIsLoadingState={handleIsLoadingState}
        universityColor={universityColor}
        updateAvatarState={updateAvatarState}
        updateProfileState={updateProfileState}
        closeModal={closeModal}
        profileData={profileData}
        setProfileData={setProfileData}
        fetchGamificationData={fetchGamificationData}
        isCandidate={true}
        jobId={jobId}
        applyToJobOffer={applyToJobOffer}
        zipCodeStudent={zipCodeStudent}
        {...props}
      />,
      'Edição Dados Pessoais'
    );

    setOpenModalLocalization && setOpenModalLocalization(false)
  };

  useEffect(() => {
    const checkCoverLetter = async () => {
      const result = await checkHasCoverLetter();
      setHasCoverLetter(result);
    };
    checkCoverLetter();
  }, []);

  useEffect(() => {
    setGlobalState(hasCoverLetter);
  }, [hasCoverLetter]);

  const handleSeeMore = () => {
    setVisibleItems((prev) => prev + 20);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };

  const handleUnfavoriteToggle = async (jobId, isFavorited) => {
    try {
      setJobIdBeingProcessedAsFavorite(jobId);
      if (isFavorited === true) {
        await JobOffersApi.fetchActionToUnfavoriteJobs(jobId);
        await fetchListFavoriteJobOffers(false);
      }
    } finally {
      setJobIdBeingProcessedAsFavorite(null);
    }
  };

  const isGamificationLevelOneIncomplete = () => {
    setOpenGamificationLevelOneIncompleteModal(gamificationLevelOneIncomplete);
    return gamificationLevelOneIncomplete;
  };

  const isCoverLetterMissing = (jobId) => {
    setAppliedJobId(jobId);
    setOpenCoverLetterModal(!hasCoverLetter);
    return !hasCoverLetter;
  };

  const handleCloseCoverLetterModal = () => {
    setAppliedJobId(null);
    setOpenCoverLetterModal(false);
  };

  async function applyToJobOffer(jobId) {
    try {
      if (isApplying) {
        return;
      }
      const data = { job_offer: jobId };
      setIsApplying(true);
      const response = await JobOffersApi.getCandidateJobOffer(data);
      await fetchListFavoriteJobOffers(false);
      if (response.message) {
        customSnackbar(
          'Erro ao candidatar a vaga, tente novamente mais tarde.',
          'error'
        );
      } else {
        customSnackbar(
          'Candidatura realizada com sucesso! Acompanhe o status da vaga em "Candidaturas".',
          'confirmation'
        );
      }
    } catch (error) {
      customSnackbar(
        'Erro inesperado ao candidatar a vaga, tente novamente mais tarde.',
        'error'
      );
    } finally {
      setIsApplying(false);
      handleCloseCoverLetterModal();
    }
  }

  async function candidateJobOffer(jobId) {
    setOpenModalLocalization && setOpenModalLocalization(false)
    try {
      if (isGamificationLevelOneIncomplete()) {
        return;
      }

      if (isCoverLetterMissing(jobId)) {
        return;
      }

      setJobIdCandidatureBeingProcessed(jobId);

      await applyToJobOffer(jobId);
    } finally {
      setJobIdCandidatureBeingProcessed(null);
    }
  }

  const returnCardJobs = (dataFavoriteJobOffers, visibleItems) => {
    if (dataFavoriteJobOffers?.length >= 1) {
      const filteredData = filterData(dataFavoriteJobOffers, search);

      if (filteredData.length === 0) {
        return (
          <ContainerGenericMessage>
            <GenericMessage
              title="Nenhuma vaga encontrada."
              subtitle="Não encontramos nenhuma vaga com este nome."
            />
          </ContainerGenericMessage>
        );
      }

      return filterData(dataFavoriteJobOffers, search)
        .slice(0, visibleItems)
        .map((job) => (
          <div key={job}>
            <GenericCardsJobOffers
              key={job}
              match={job.match}
              code={job.id}
              name={job.name}
              companyId={job.company_id}
              companyName={job.company_name}
              finallyDate={job.end_offer_at}
              jobModality={job.modality}
              shift={job.shift}
              location={job.location ? job.location : 'Não informado'}
              processingAsFavorite={job.id === jobIdBeingProcessedAsFavorite}
              like={true}
              isFavorite={job.is_favorite}
              onClickLike={() =>
                handleUnfavoriteToggle(job.id, job.is_favorite)
              }
              onClickDetailsButton={() => onClickDetails(job.id, job.match)}
              labelTwo={'Detalhes'}
              onClickButton={() => openLocalizationModal(job.id, job.location)}
              label={'Candidatar'}
              processingCandidature={job.id === jobIdCandidatureBeingProcessed}
              universityColor={universityColor}
            />
          </div>
        ));
    }
  };

  const onClickDetails = (jobId, match) => {
    setSelectedJobId(jobId);
    setSelectedJobMatch(match);
    setOpenDetailsModal(true);
  };

  return dataFavoriteJobOffers?.length === 0 ? (
    <GenericMessage
      title={'Nenhuma vaga favoritada.'}
      subtitle={'No momento você não possui nenhuma vaga favoritada.'}
    />
  ) : (
    <CurriculumContextProvider>

      {openLocalizationModal &&
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={openModalLocalization}
          config={configLocationModal}
          buttonCancelFunction={handleCloseModalLocalization}
          buttonConfirmFunction={() => candidateJobOffer(selectJobId)}
          closeModalFunction={handleCloseModalLocalization}
          buttonThreeFunction={() => onClickEdit(selectJobId)}
        />
      }

      <ContainerCards>
        <div>{returnCardJobs(dataFavoriteJobOffers, visibleItems)}</div>
      </ContainerCards>

      <ContainerButtonsPagination>
        <PaginationSeeMore
          filled
          data={dataFavoriteJobOffers}
          onClick={() => handleSeeMore()}
          universityColor={universityColor}
          renderedItems={returnCardJobs(dataFavoriteJobOffers, visibleItems)}
        />
      </ContainerButtonsPagination>

      {openDetailsModal && (
        <DetailsJobOffer
          jobId={selectedJobId}
          handleCloseModal={handleCloseModal}
          universityColor={universityColor}
          match={selectedJobMatch}
          onClickApplyToJobOffer={() => openLocalizationModal(selectedJobId, location)}
          candidatureBeingProcessed={jobIdCandidatureBeingProcessed !== null}
          isExclusive={true}
        />
      )}

      <>
        {openGamificationLevelOneIncompleteModal && (
          <GamificationLevelOneIncompleteModal
            universityColor={universityColor}
            gamificationItems={gamificationItems}
            handleIsLoadingState={handleIsLoadingState}
            onCloseModal={() => {
              setOpenGamificationLevelOneIncompleteModal(false);
            }}
          />
        )}

        {openCoverLetterModal && (
          <BlankModalInformation
            universityColor={universityColor}
            modalOpenState={openCoverLetterModal}
            config={coverLetterConfig}
            buttonCancelFunction={() => applyToJobOffer(appliedJobId)}
            buttonConfirmFunction={() => {
              handleCloseCoverLetterModal();
              openCoverLetterForm(
                openSnackbar,
                closeModal,
                handleIsLoadingState,
                setHasCoverLetter,
                openModalWithContent,
                universityColor
              );
            }}
            closeModalFunction={handleCloseCoverLetterModal}
          />
        )}
      </>
    </CurriculumContextProvider>
  );
};

export default FavoriteJobOffers;
