import React, { useEffect, useRef, Fragment, useState } from 'react';

import { useField } from '@unform/core';
import { UnInput, UnInputLabel } from '../../Styles/unformComponentsStyled';
import { ContainerLabelInput, ErrorWrapper } from '../../Styles/formStyled';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function InputWorkload({ label, name, ...rest }) {
  const inputRef = useRef(null);
  const [workload, setWorkload] = useState('');
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(name);

  const maskHours = (value) => {
    let varTemp = value;
    if (varTemp >= 0 && typeof varTemp === 'number') {
      varTemp = String(varTemp);
    }

    return varTemp
      .replace(/\D/g, '')
      .replace(/(\d{1,6})([0-9][0-9]$)/, '$1:$2');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = maskHours(workload);
    }
  }, [workload]);

  useEffect(() => {
    if (defaultValue) {
      setWorkload(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={(value) => setWorkload(value.target.value)}
        error={error}
        onFocus={clearError}
        {...rest}
      />
      <ErrorWrapper>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
