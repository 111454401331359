import React, { useContext, useEffect } from 'react';
import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';
import {
  Container,
  PublishedOportunities,
} from '../../../../../components/Opportunities/styles/defaultStyled';
import Modal from '../Modal';
import RegistrationCard from '../RegistrationCard';

import {
  getCacheOptions,
  getCompetencies,
  getInterests,
  OportunitiesMacroAreas,
} from '../../services';

export default function RegisterOpportunity({
  handleIsLoadingState,
  setActiveTab,
  activeTab,
}) {
  const {
    setStep,
    setModalIsOpen,
    setModalContent,
    setMacroAreas,
    setCacheOptions,
    setCompetencies,
    setInterests,
    setFormData,
    setSelectOption,
    setOptionsId,
  } = useContext(OpportunitiesCompanyContext);

  useEffect(() => {
    setModalIsOpen(false);
    setModalContent();
    setFormData({});
    setSelectOption('');
    setOptionsId('');
    setStep(0);
  }, [activeTab]);

  useEffect(() => {
    async function getAll() {
      const result = await OportunitiesMacroAreas();
      setMacroAreas(result);
      const responseInterests = await getInterests();
      setInterests(responseInterests);
      const responseCompetencies = await getCompetencies();
      setCompetencies(responseCompetencies);

      const cachedOptions = await getCacheOptions();
      setCacheOptions([
        ...cachedOptions[0],
        ...cachedOptions[1],
        ...cachedOptions[2],
      ]);
      handleIsLoadingState(false);
    }
    getAll();
  }, []);

  return (
    <Container>
      <Modal />
      <RegistrationCard setActiveTab={setActiveTab} />
      <PublishedOportunities onClick={() => setActiveTab(1)}>
        Ver aprendizagens publicadas
      </PublishedOportunities>
    </Container>
  );
}
