import styled, { keyframes } from 'styled-components';
import { Container as ContainerFromCard } from '../CourseCard/style';
export const Container = styled(ContainerFromCard)`
  cursor: unset;
  transition: none;
  border: 1px solid #e5e5e5;

  &:hover {
    transform: none;
  }

  h4 {
    background-color: transparent;
  }
  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
