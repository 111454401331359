const IModalConfigLogin = {
  ParagraphText:
    'Identificamos que a sua empresa <strong>já tem um cadastro na Workalove.</strong> Faça o <strong>seu login</strong>, e caso tenha esquecido a senha clique em “Esqueci minha senha” ou contate a nossa equipe de suporte!',
  buttonText: 'IR PARA LOGIN',
  bubbleText: 'AVISO!',
  showButton: true,
};

const IModalConfigNegativeLogin = {
  ParagraphText:
    'Você não tem mais acesso a plataforma. Entre em contato com a sua Instituição de Ensino.',
  buttonText: 'OK, ENTENDI!',
  bubbleText: 'Oops!',
  showButton: true,
};

const IModalCompanyIesPreRegister = {
  ParagraphText:
    'Identificamos que sua empresa já foi pré-cadastrada por alguma instituição de ensino. Estamos te direcionando para dar continuidade no cadastro. ',
  buttonText: 'CONTINUAR CADASTRO',
  bubbleText: 'Atenção!',
  showButton: true,
  modalWidth: 427,
  buttonWidth: 312,
  textAlign: 'unset',
};

const IModalCompanyPreRegister = {
  ParagraphText:
    'Já foi iniciado um cadastro com este CNPJ. Estamos te direcionando para continuar de onde você parou.',
  buttonText: 'CONTINUAR CADASTRO',
  bubbleText: 'Atenção!',
  showButton: true,
  modalWidth: 427,
  buttonWidth: 312,
  textAlign: 'unset',
};

const IModalCompanyHasRegister = {
  ParagraphText: `O CNPJ informado já está cadastrado em nosso sistema. Faça o seu login para seguir em frente.<br/><br/>
  Caso você não tenha feito este cadastro, entre em contato com nossa equipe através do e-mail suporte@workalove.com`,
  buttonText: 'ACESSAR',
  bubbleText: 'Atenção!',
  showButton: true,
  modalWidth: 427,
  buttonWidth: 312,
  textAlign: 'unset',
  buttonConfirmText: 'OK, ENTENDI',
};

export {
  IModalConfigLogin,
  IModalConfigNegativeLogin,
  IModalCompanyPreRegister,
  IModalCompanyHasRegister,
  IModalCompanyIesPreRegister,
};
