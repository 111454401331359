import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const CtaButton = styled(Button)`
  width: auto !important;
`;

export const ContainedButton = styled(Button)`
  font-size: 0.875rem !important;
  margin-bottom: 12px;
  svg {
    font-size: 24px !important;
    margin: 0px !important;
  }
`;
