export function IconDate({ color }) {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9943 4.00574C14.9867 2.49794 13.7606 1.14244 12.0396 1.15005C11.8797 1.15005 11.7198 1.15005 11.537 1.15005C11.537 0.967288 11.537 0.799755 11.537 0.632221C11.537 0.266692 11.2857 0.000159121 10.9506 0.000159121C10.6156 0.000159121 10.3719 0.26669 10.3719 0.639834C10.3719 0.807368 10.3719 0.967287 10.3719 1.14244H4.63003C4.63003 0.967287 4.63003 0.799754 4.63003 0.624605C4.63003 0.259076 4.37111 -0.00745606 4.03605 0.000159121C3.70098 0.0077743 3.46491 0.266691 3.45729 0.624605C3.45729 0.792139 3.45729 0.959673 3.45729 1.15767C3.19076 1.15767 2.93184 1.14244 2.68054 1.15767C1.31743 1.24905 0.205609 2.29994 0.0228446 3.65545C0.0228446 3.68591 0.00761518 3.70875 0 3.73921V12.3748C0.0228455 12.4891 0.0456906 12.6033 0.0685362 12.7175C0.342683 13.9207 1.32504 14.8041 2.55109 14.9564C2.58155 14.9564 2.60439 14.9716 2.63485 14.9792H12.3594C12.4965 14.9488 12.6336 14.9259 12.7707 14.8955C14.05 14.5985 14.9867 13.441 14.9943 12.1311C15.0019 9.43537 15.0019 6.71675 14.9943 4.00574ZM13.8216 5.96285C13.8216 7.98087 13.8216 9.99889 13.8216 12.0245C13.8216 13.0754 13.0829 13.8217 12.0244 13.8217C9.00114 13.8217 5.98553 13.8217 2.96231 13.8217C1.91141 13.8217 1.16512 13.0754 1.16512 12.0245C1.16512 10.0065 1.16512 7.98849 1.16512 5.96285V5.7877H13.8139V5.96285H13.8216ZM13.8139 4.59973H1.18797C0.982356 3.05385 1.97233 2.14003 3.45729 2.34564C3.45729 2.71878 3.45729 3.09192 3.45729 3.46507C3.46491 3.83821 3.77713 4.09713 4.14266 4.04382C4.4168 3.99813 4.62241 3.76206 4.62241 3.46507C4.63003 3.14523 4.62241 2.81778 4.62241 2.49794V2.3304H10.3566C10.3566 2.3761 10.3643 2.4294 10.3643 2.47509C10.3643 2.79493 10.3643 3.12238 10.3643 3.44222C10.3643 3.80014 10.6156 4.05144 10.9582 4.05144C11.2933 4.05144 11.537 3.79252 11.537 3.44984C11.537 3.08431 11.537 2.71878 11.537 2.35325C12.8163 2.14764 14.0119 2.86347 13.8139 4.59973Z" fill={color} />
      <path d="M5.20105 11.5343C5.20105 11.8465 4.95736 12.113 4.62991 12.1207C4.23392 12.1283 3.83793 12.1283 3.44956 12.1207C3.12972 12.113 2.87842 11.8465 2.87842 11.5343C2.87842 11.2221 3.12972 10.9631 3.45717 10.9555C3.65517 10.9479 3.85316 10.9555 4.05877 10.9555C4.24915 10.9555 4.43953 10.9479 4.62991 10.9555C4.94975 10.9631 5.20105 11.2144 5.20105 11.5343Z" fill={color} />
      <path d="M12.1153 11.5419C12.1153 11.8541 11.864 12.113 11.5365 12.1207C11.1482 12.1283 10.7598 12.1283 10.3638 12.1207C10.0363 12.113 9.79265 11.8541 9.78503 11.5419C9.78503 11.2221 10.0363 10.9631 10.3714 10.9555C10.5694 10.9479 10.7598 10.9555 10.9578 10.9555C11.1481 10.9555 11.3385 10.9555 11.5289 10.9555C11.864 10.9631 12.1229 11.2221 12.1153 11.5419Z" fill={color} />
      <path d="M12.1162 8.07045C12.1238 8.39029 11.8649 8.65682 11.5375 8.66444C11.1491 8.67205 10.7683 8.67205 10.3799 8.66444C10.0449 8.65682 9.79358 8.39791 9.79358 8.07807C9.79358 7.75823 10.0525 7.49931 10.3799 7.4917C10.5779 7.4917 10.7683 7.4917 10.9663 7.4917C11.1567 7.4917 11.3471 7.4917 11.5375 7.4917C11.8573 7.50693 12.1162 7.75823 12.1162 8.07045Z" fill={color} />
      <path d="M5.20121 8.08145C5.20121 8.40129 4.94991 8.66021 4.61484 8.66782C4.42446 8.67544 4.23408 8.66782 4.0437 8.66782C3.85332 8.66782 3.66294 8.66782 3.47256 8.66782C3.12988 8.66021 2.87097 8.40129 2.87858 8.07384C2.8862 7.754 3.14511 7.5027 3.47256 7.49508C3.85332 7.49508 4.23408 7.48747 4.61484 7.49508C4.94991 7.5027 5.20121 7.76162 5.20121 8.08145Z" fill={color} />
      <path d="M8.65857 8.09122C8.65857 8.41106 8.40727 8.66997 8.08743 8.66997C7.69906 8.67759 7.31068 8.67759 6.91469 8.66997C6.59485 8.66236 6.33594 8.40344 6.33594 8.09122C6.33594 7.77138 6.58723 7.51246 6.9223 7.50485C7.1203 7.49723 7.31068 7.50485 7.50867 7.50485C7.69905 7.50485 7.88943 7.50485 8.07981 7.50485C8.40726 7.50485 8.65857 7.76377 8.65857 8.09122Z" fill={color} />
      <path d="M8.65844 11.5296C8.65844 11.8494 8.40714 12.1083 8.07207 12.1159C8.00353 12.1159 7.93499 12.1159 7.86646 12.1159C7.79031 12.1159 7.70654 12.1159 7.63039 12.1159C7.5847 12.1159 7.539 12.1159 7.49331 12.1159C7.44001 12.1159 7.37909 12.1159 7.32578 12.1159C7.19632 12.1159 7.06686 12.1159 6.9374 12.1159C6.60234 12.1083 6.33581 11.8494 6.34342 11.522C6.35104 11.2021 6.60234 10.9508 6.9374 10.9432C7.31816 10.9356 7.69892 10.9356 8.07968 10.9432C8.39952 10.9584 8.65082 11.2097 8.65844 11.5296Z" fill={color} />
    </svg>
  );
}
