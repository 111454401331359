import styled from 'styled-components';
import StarActivity from '../../../../../assets/Oportutinites/StarActivity.svg';
import leftArrow from '../../../../../assets/Oportutinites/leftArrow.svg';
import rightArrow from '../../../../../assets/Oportutinites/rightArrow.svg';
import GreenPlus from '../../../../../assets/Oportutinites/GreenPlus.svg';

export const WrapperPublished = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 2em 3em;
  flex-direction: column;

  @media(max-width: 1370px){
    padding: 2em 2em;
  }
`;

export const Title = styled.h3`
  font-weight: 500;
  color: #009291;
`;

export const WrapperDiv = styled.div`
  width: 100%;
  min-height: 300px;
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 0;
  display: flex;
  align-items: start;
  padding: 40px;
  flex-direction: column;

  @media(max-width: 1370px){
    padding: 40px 2px 20px 40px;
  }
`;

export const CardTitle = styled.h4`
  font-weight: 500;
  font-size: 24px;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: #009291;

  padding: 30px 0px 6px 47px;


`;
export const CardSubTitle = styled.p`
  color: #606062;
  font-size: 0.9rem;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0px 0px 10px 47px;
`;

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
`;

export const Card = styled.div`
  width: 260px;
  height: 413px;
  background-color: #ffff;
  border-radius: 16px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-right: 20px;
  margin-top: 20px;

  @media screen and (max-width: 1453px) {
    margin-right: 14px;
    margin-top: 14px;
  }
`;

export const CardImg = styled.img`
  position: absolute;
  top: 0px;
  left: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 260px;
  height: 209px;
`;

export const ActivityType = styled.div`
  width: auto;
  padding-right: 10px;
  height: 23px;
  background-color: #ffff;
  border-radius: 16px;
  position: absolute;
  z-index: 2;
  top: 17em;
  margin-left: 10px;
  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StarActivityImgDiv = styled.div`
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 4px;
`;

export const StarActivityImg = styled.img`
  margin-top: 6px;
`;

export const StarActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
  }
`;

export const StarActivityP = styled.p`
  color: #009291;
  font-size: 12px;
  font-weight: 500;

  margin: 0px;
  margin-top: 2px;
`;

export const TitleH4 = styled.h4`
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold;
  font-size: 14.5px;
  position: absolute;
  top: 15em;
  z-index: 2;
  left: 10px;
  text-align: start;
`;

export const Informations = styled.div`
  width: 100%;
  height: 20%;
  position: absolute;
  margin-top: 19em;
`;

export const TagIcons = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const IconImage = styled.img`
  width: 7%;
  margin-left: 10px;
  margin-right: 10px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;
`;

export const ButtonLeft = styled.button`
  background-image: url(${leftArrow});
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:active {
    transform: scale(0.8);
  }
`;
export const ButtonRight = styled.button`
  background-image: url(${rightArrow});
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:active {
    transform: scale(0.8);
  }
`;

export const MoreInfo = styled.p`
  margin-top: 11px;
  color: #009291;
  text-decoration: underline;
  text-decoration-color: #009291;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
`;

export const DashedBorder = styled.div`
  width: 580px;
  height: 150px;
  border-width: 2px;
  border-color: #c4c4c4;
  border-style: dashed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PlusButton = styled.button`
  width: 214px;
  min-height: 42.48px;
  height: auto;
  border-radius: 8px;
  background-color: #009291;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #ffff;
  font-weight: bold;
  font-size: 15px;
`;

export const PlusImg = styled.img`
  background-image: url(${GreenPlus});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  width: 27.25px;
  height: 27.25px;
  background-color: #ffff;
  border-radius: 8px;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  width: 250px;

  margin-bottom: 8px;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px 0 10px;
  }

  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const ButtonValidation = styled.button`
  width: 100%;
  max-width: 119px;
  height: 30px;
  
  background: #009291;

  border: none;
  border-radius: 5px;

  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;

  cursor: pointer;
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`
export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0px 46px;
  width: 100%;
`

export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  margin-bottom: 20px;
`;

export const DivButton = styled.div`
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
`

export const ButtonPagination = styled.button`
    border: none;
    border-radius: 4px;
    background-color: #009291;

    width: 200px;
    height: 40px;

    padding: 12px;

    font-size: 14px;
    color: #fff;
    font-weight: bold;

    cursor: pointer;

    &:hover{
    background-color: #25a3a2;
    transition: 0.8s;
  }

  &:disabled{
    display: none;
    cursor: default;
    background-color: #ababab;
  }
`

export const WrapperBoxReaction = styled.div`
  width: 100%;
  max-width: 100px;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  position: absolute;
  top: 8px;
  right: 8px;


`

export const BoxReaction = styled.div`
  width: 100%;
  max-width: 45px;
  height: 23px;
 
  color: #4B4B4D;
  border-radius: 3px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3px;
  margin-bottom: 10px;

  box-shadow: 0px 0px 10.0697px rgba(0, 0, 0, 0.4);


  span {
    font-size: 13px !important;
    padding: 2px;
  }
`
