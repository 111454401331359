const defineCookieDomain = () => {
  const { hostname } = window.location;

  if (hostname.includes('.worka.local')) {
    return '.worka.local';
  } else if (hostname.includes('.worka.love')) {
    return '.worka.love';
  }
  return '';
};

const cleanCookies = () => {
  document.cookie = `sessionid= ; path=/ ; domain = ${defineCookieDomain()} ; expires = Thu, 01 Jan 1970 00:00:00 GMT ; `;
  document.cookie = `viewModal= ;  path=/ ; domain = ${defineCookieDomain()} ; expires = Thu, 01 Jan 1970 00:00:00 GMT; `;
  document.cookie = `csrftoken= ; path=/ ; domain = ${defineCookieDomain()} ; expires = Thu, 01 Jan 1970 00:00:00 GMT; `;
  document.cookie = `dataproductstoken= ; path=/ ; domain = ${defineCookieDomain()} ; expires = Thu, 01 Jan 1970 00:00:00 GMT; `;
};

export default cleanCookies;
