const jobDetailsAdapter = ({
  name,
  company_name,
  company_organization_number,
  job_creator_name,
  third_party_offer,
  quantity,
  description,
  application_type,
  salary_range_offered,
  another_perks,
  salary,
  disability,
  locations,
  start_time,
  end_time,
  perks_offered,
  start_offer_at,
  end_offer_at,
  contact_email,
  required_behavioral_attributes,
  courses_matching_rules,
  required_formation,
  required_academic_progress,
  required_professional_areas,
  required_professional_experience_time,
  required_professional_sectors,
  required_additional_courses_areas,
  enabled_just_for_universities,
  external,
  id,
  salary_coin,
  is_candidate,
  job_application_id,
  is_answered,
  is_interesting,
  has_application,
  is_job_done,
  is_contracted_by_platform,
}) => {
  return {
    isContractedByPlatform: is_contracted_by_platform,
    isJobDone: is_job_done,
    hasApplication: has_application,
    isAnswered: is_answered,
    isInteresting: is_interesting,
    company: {
      label: company_name,
      value: company_organization_number,
    },
    profession: name,
    jobsOpenings: quantity,
    salaryCoin: salary_coin,
    jobCreatorName: job_creator_name,
    thirdPartyOffer: third_party_offer,
    selectedJobsType: application_type,
    description,
    anotherBenefit: another_perks,
    selectedDisability: disability,
    salaryRange: salary_range_offered,
    salary,
    selectedBenefits: perks_offered
      ? perks_offered.concat({ value: '' })
      : perks_offered,
    selectedCities: locations,
    startTime: start_time,
    endTime: end_time,
    startedAt: start_offer_at,
    endAt: end_offer_at,
    jobContact: contact_email,
    selectedBehaviors: required_behavioral_attributes,
    selectedAcademicRequirements: courses_matching_rules,
    academicPerformance: required_academic_progress,
    academicLevelRequired: required_formation,
    selectedProfessionalRequirements: required_professional_sectors,
    experienceTimeRequired: required_professional_experience_time,
    selectedSectors: required_professional_areas,
    selectedExtraCourses: required_additional_courses_areas,
    relatedProfession: required_additional_courses_areas,
    enableJustForUniversities: enabled_just_for_universities,
    macroAreas: external.macro_areas,
    courses: external.courses,
    divulgationLink: external.divulgation_link,
    jobEmail: external.job_email,
    name: name,
    id: id,
    jobExternal: external,
    isCandidate: is_candidate,
    jobApplicationId: job_application_id,
  };
};

const jobDetailsFormAdapter = ({
  profession,
  jobsOpenings,
  selectedJobsType,
  company,
  description,
  disability,
  salaryRange,
  salary,
  anotherBenefit,
  selectedBenefits,
  selectedDisability,
  selectedCities,
  startedAt,
  endAt,
  startTime,
  endTime,
  jobContact,
  selectedBehaviors,
  selectedAcademicRequirements,
  academicPerformance,
  academicLevelRequired,
  selectedProfessionalRequirements,
  experienceTimeRequired,
  selectedSectors,
  selectedExtraCourses,
  jobAvailableCompany,
  relatedProfession,
  selectedUniversities,
}) => {
  return {
    profession,
    jobsOpenings,
    company,
    selectedJobsType,
    description,
    disability,
    jobContact,
    anotherBenefit,
    salaryRange,
    salary,
    selectedBenefits,
    selectedCities,
    startedAt,
    endAt,
    startTime,
    endTime,
    academicLevelRequired,
    selectedBehaviors,
    selectedAcademicRequirements,
    academicPerformance,
    selectedDisability,
    selectedProfessionalRequirements,
    experienceTimeRequired,
    selectedSectors,
    selectedExtraCourses,
    jobAvailableCompany,
    relatedProfession,
    enableJustForUniversities: selectedUniversities,
  };
};

export { jobDetailsAdapter, jobDetailsFormAdapter };
