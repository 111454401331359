import FilterModal from 'components/FilterModal';
import React, { useState, useEffect, useContext } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, ContainerFlexDirection, ContainerRow } from './styled';
import { shape } from 'prop-types';
import {
  getAttractionsFilterOptions,
  getAttractionsEducationGroupFilterOptions,
  getAttractionsEducationGroupUniversityFilterOptions,
} from 'services/university/events';
import { ListPublishedByTheGroupContext } from 'views/StaffPages/Opportunities/Context/ListPublishedByTheGroupContext';
import { useEducationalGroupLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsEducationalGroup';

export function FilterEducationalGroup({
  appliedFilters,
  setAppliedFilters,
  filterState,
  setFilterState,
  filterOptions,
  setFilterOptions,
  educationalGroup,
  activeInternalTab,
}) {
  const {
    setEducationalGroupEvents,
    setEducationalGroupCourses,
    setEducationalGroupPrograms,
    setEducationalGroupCommunicate,
    setEducationalGroupTrails,
    setEducationalGroupEbooks,
    setEducationalGroupArticle,
    setEducationalGroupChallenge,
    setEducationalGroupSerie,
  } = useContext(ListPublishedByTheGroupContext);

  useEffect(() => {
    setFilterState({});
    setAppliedFilters({});
  }, [activeInternalTab]);

  const [loading, setLoading] = useState(false);

  const {
    getEducationalGroupAllEvents,
    getEducationalGroupAllCourses,
    getEducationalGroupAllProgram,
    getEducationalGroupAllAnnouncements,
    getEducationalGroupAllTrails,
    getEducationalGroupAllEbooks,
    getEducationalGroupAllArticles,
    getEducationalGroupAllChallenges,
    getEducationalGroupAllSeries,
  } = useEducationalGroupLearningAPICalls();

  const setLearnings = () => {
    setEducationalGroupEvents(null);
    setEducationalGroupCourses(null);
    setEducationalGroupPrograms(null);
    setEducationalGroupCommunicate(null);
    setEducationalGroupTrails(null);
    setEducationalGroupEbooks(null);
    setEducationalGroupArticle(null);
    setEducationalGroupChallenge(null);
    setEducationalGroupSerie(null);
  };

  async function typeFilter() {
    if (activeInternalTab === 0) {
      return await getAttractionsEducationGroupFilterOptions();
    }
    if (activeInternalTab === 1) {
      return await getAttractionsEducationGroupUniversityFilterOptions();
    }
  }

  useEffect(() => {
    typeFilter();
  }, [activeInternalTab]);

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setLoading(true);
        const response = await typeFilter();
        setFilterOptions(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  function renderFilterContent() {
    return [
      <ContainerFlexDirection>
        <FilterGrid>
          <div>
            <Filter
              name={'name'}
              label={'Filtrar por nome'}
              type={FilterType.TEXT}
            />
          </div>
        </FilterGrid>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'macro_areas'}
                label={'Área de atuação'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.macro_areas}
              />
            </div>
          </FilterGrid>
          <FilterGrid>
            <div>
              <Filter
                name={'courses'}
                label={'Curso'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.courses}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'periods'}
                label={'Período'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.periods}
              />
            </div>
          </FilterGrid>
          <FilterGrid>
            <div>
              <Filter
                name={'modality'}
                label={'Modalidade'}
                type={FilterType.SINGLE_SELECT}
                options={filterOptions.modality}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'competences'}
                label={'Competências'}
                type={FilterType.MULTI_SELECT}
                options={filterOptions.competences}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
        <ContainerRow>
          <FilterGrid>
            <div>
              <Filter
                name={'status'}
                label={'Status'}
                type={FilterType.SINGLE_SELECT}
                options={filterOptions.status}
              />
            </div>
          </FilterGrid>
          <FilterGrid>
            <div>
              <Filter
                name={'responsible'}
                label={'Responsável pela publicação'}
                type={FilterType.SINGLE_SELECT}
                options={filterOptions.responsible}
              />
            </div>
          </FilterGrid>
        </ContainerRow>
      </ContainerFlexDirection>,
    ];
  }

  function onApplyFilters() {
    setLearnings();
    getEducationalGroupAllEvents(true, null, filterState);
    getEducationalGroupAllCourses(true, null, filterState);
    getEducationalGroupAllProgram(true, null, filterState);
    getEducationalGroupAllAnnouncements(true, null, filterState);
    getEducationalGroupAllTrails(true, null, filterState);
    getEducationalGroupAllEbooks(true, null, filterState);
    getEducationalGroupAllArticles(true, null, filterState);
    getEducationalGroupAllChallenges(true, null, filterState);
    getEducationalGroupAllSeries(true, null, filterState);
    setAppliedFilters(filterState);
  }

  function onClearFilters() {
    setLearnings();
    getEducationalGroupAllEvents(_, true);
    getEducationalGroupAllCourses(_, true);
    getEducationalGroupAllProgram(_, true);
    _, true;
    getEducationalGroupAllAnnouncements(_, true);
    getEducationalGroupAllTrails(_, true);
    getEducationalGroupAllEbooks(_, true);
    getEducationalGroupAllArticles(_, true);
    getEducationalGroupAllChallenges(_, true);
    getEducationalGroupAllSeries(_, true);
    setAppliedFilters(filterState);
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loading}
      disabled={loading}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}

FilterEducationalGroup.propTypes = {
  filterOptions: shape.isRequired,
};
