import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  overflow-y: scroll;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 750px;
  background-color: #fff;
  min-height: 410px;
  border-radius: 8px;
  padding: 20px 42px;
  position: absolute;
  top: 10px;

  > h3 {
    color: #009291;
    font-weight: 500;
    font-size: 20px;
  }
`;

export const InputLabel = styled.label`
  color: #6d6d6d;
  font-weight: bold;
  font-size: 13px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin: 5px 0;

  > span {
    color: #949494;
    font-size: 10px;
    margin-left: 5px;
  }
`;

export const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const ChipButton = styled.button`
  border: none;
  background: #caeaea;
  height: 22px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 4px;

  p {
    line-height: 22px;
    color: #777777;
    font-size: 12px;
    margin: 0;
  }
`;

export const SaveButtonWrapper = styled.div`
  width: 100%;
  height: 40px;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
  margin-top: 1.5rem;

  > button {
    position: absolute;
    right: 0;
    width: 74px;
    height: 40px;
    background-color: #009291;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover:not(:disabled) {
      filter: brightness(0.9);
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
`;
