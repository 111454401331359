import { dnaGreen, dnaColor } from 'assets/jss/material-dashboard-pro-react';
import {
  yellowIESCardHeader,
  redIESCardHeader,
  greenIESCardHeader,
  darkBlueIESCardHeader,
  blueIESCardHeader,
  lightBlueIESCardHeader,
  orangeIESCardHeader,
  dnaColorCardHeader
} from 'assets/jss/material-dashboard-pro-react.jsx';
import styled from 'styled-components';

const JobHeaderTab = (theme) => ({
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 10,
    alignSelf: 'flex-end',
    display: 'flex',
  },

  tabIndicator: {
    '&$yellowIESCardHeader, &$redIESCardHeader, &$greenIESCardHeader, &$darkBlueIESCardHeader, &$blueIESCardHeader, &$lightBlueIESCardHeader, &$orangeIESCardHeader, &$dnaColorCardHeader': {
      height: 3,
      zIndex: 2,
    },
  },
  filterFlexContainer: {
    color: '#777 !important',
  },
  scrollButtons: {
    borderBottom: '1px solid #EDEDED',
    marginBottom: '-1px',
    [theme.breakpoints.down('lg')]: {
      color: '#777777',
    },
  },
  borderContainer: {
    border: '1px solid',
  },
  tabButton: {
    fontSize: 18,
    color: '#777777',
    fontWeight: '500',
    maxWidth: 'fit-content',
    textAlign: 'center',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 180,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectLabel: {
    whiteSpace: 'nowrap',
  },
  cssLabel: {
    '&$cssFocused': {
      color: dnaColor,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  cssFocused: {},
  yellowIESCardHeader,
  redIESCardHeader,
  greenIESCardHeader,
  darkBlueIESCardHeader,
  blueIESCardHeader,
  lightBlueIESCardHeader,
  orangeIESCardHeader,
  dnaColorCardHeader

});

export default JobHeaderTab;

export const FilterContainer = styled.div`
  display: flex;
  right: 2rem;
  top: 1rem;
  position: absolute;
  gap: 20px;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  z-index: 0;
`;

export const ButtonCreate = styled.button`
  background-color: #009291;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  border: none;
  padding: 9px;
  cursor: pointer;
  disabled: ${(props) => props.disabled};
  &:disabled {
    cursor: not-allowed;
    background-color: gray;
  }
`;
