import React from 'react';

function LogoJobNetwork({ color = '#009291', width = 16, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 2.66667H10V2C10 0.897333 9.10267 0 8 0C6.89733 0 6 0.897333 6 2V2.66667H3.33333C1.49533 2.66667 0 4.162 0 6V12.6667C0 14.5047 1.49533 16 3.33333 16H12.6667C14.5047 16 16 14.5047 16 12.6667V6C16 4.162 14.5047 2.66667 12.6667 2.66667ZM7.33333 2C7.33333 1.632 7.63267 1.33333 8 1.33333C8.36733 1.33333 8.66667 1.632 8.66667 2V3.33C8.66667 3.33 8.66667 3.332 8.66667 3.33333C8.66667 3.33467 8.66667 3.33533 8.66667 3.33667V4H7.33267V2H7.33333ZM14.6667 12.6667C14.6667 13.7693 13.7693 14.6667 12.6667 14.6667H3.33333C2.23067 14.6667 1.33333 13.7693 1.33333 12.6667V6C1.33333 4.89733 2.23067 4 3.33333 4H6C6 4.73533 6.598 5.33333 7.33333 5.33333H8.66667C9.402 5.33333 10 4.73533 10 4H12.6667C13.7693 4 14.6667 4.89733 14.6667 6V12.6667ZM6.33333 8.33333C6.33333 7.41267 7.07933 6.66667 8 6.66667C8.92067 6.66667 9.66667 7.41267 9.66667 8.33333C9.66667 9.254 8.92067 10 8 10C7.07933 10 6.33333 9.254 6.33333 8.33333ZM11.312 13.1673C11.404 13.5233 11.1893 13.8873 10.8327 13.9793C10.474 14.072 10.1127 13.856 10.0207 13.4993C9.8 12.6447 8.93133 12 7.99933 12C7.06733 12 6.198 12.6447 5.978 13.4993C5.90067 13.8 5.63 14 5.33267 14C5.278 14 5.222 13.9933 5.166 13.9793C4.80933 13.8873 4.59467 13.524 4.68667 13.1673C5.05933 11.718 6.452 10.6667 7.99867 10.6667C9.54533 10.6667 10.938 11.718 11.3107 13.1673H11.312Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoJobNetwork;
