
import React, {useEffect, Fragment,useState} from 'react'
import CompaniesManagementProvider from './Context/CompaniesManagementContext';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader'

import headerBackGroundImage from 'assets/img/ies-companies-management.svg';
import ConnectedCompanies from './Components/Screens/ConnectedCompanies.jsx';
import DisconnectedCompanies from './Components/Screens/DisconnectedCompanies.jsx';
import InvitationsSent from './Components/Screens/InvitationSent';
import { countTabs } from './Services';
import { formatNumber } from 'utils/formatNumber';



export default function ({handleIsLoadingState}) {
  const [activeTab, setActiveTab] = useState(0);

  const [countTabInvitesCreated, setCountTabInviteCreated] = useState(0)
  const [countTabInvitesSent, setCountTabInviteSent] = useState(0)
  const [countTabInvitesAnswered, setCountTabInviteAnswered] = useState(0)
  const [countTabInvitesCanceled, setCountTabInviteCanceled] = useState(0)

  const [countConnectedCompanies, setCountConnectedCompanies] = useState(0)
  const [countDisconnectCompanies, setCountDisconnectCompanies] = useState(0)
  const [countTotalInvitesSentCompanies, setCountTotalInvitesSentCompanies] = useState(0)

  const getCountTabs = async () => {
    const data = await countTabs();
    const {
      invites_created, 
      invites_sent, 
      invites_answered, 
      invites_canceled, 
      connected_companies, 
      disconnected_companies, 
      invites_by_university_sum
    } = data;
    setCountTabInviteSent(invites_sent)
    setCountTabInviteCreated(invites_created)
    setCountTabInviteAnswered(invites_answered)
    setCountTabInviteCanceled(invites_canceled)

    setCountConnectedCompanies(connected_companies)
    setCountDisconnectCompanies(disconnected_companies)
    setCountTotalInvitesSentCompanies(invites_by_university_sum)
  }

  useEffect(() => {
    getCountTabs()
  }, [activeTab])

  const Tabs = [
    { 
      activeTab: 0, 
      component: 
        <ConnectedCompanies 
          activeTab={activeTab}  
          getCountTabs={getCountTabs}
          setCountConnectedCompanies={setCountConnectedCompanies}
        /> 
    },
    { 
      activeTab: 1, 
      component: 
        <DisconnectedCompanies 
          activeTab={activeTab}
          getCountTabs={getCountTabs}
          setCountDisconnectCompanies={setCountDisconnectCompanies}
        /> 
    },
    { 
      activeTab: 2, 
      component: 
        <InvitationsSent 
          activeTab={activeTab} 
          countTabInvitesCreated={countTabInvitesCreated}
          countTabInvitesSent={countTabInvitesSent}
          countTabInvitesAnswered={countTabInvitesAnswered}
          countTabInvitesCanceled={countTabInvitesCanceled}
          setCountTabInviteCreated={setCountTabInviteCreated}
          setCountTabInviteSent={setCountTabInviteSent}
          setCountTabInviteAnswered={setCountTabInviteAnswered}
          setCountTabInviteCanceled={setCountTabInviteCanceled}
          getCountTabs={getCountTabs}
        /> 
    },
  ];

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);

    return returnTabs.component;
  };

  useEffect(() => {
    handleIsLoadingState(false);
  }, [])



  return (
    <CompaniesManagementProvider> 
      <Fragment>
        <CompanyHeader
          title="Gestão de empresas"
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          backgroundImage={headerBackGroundImage}
          tabs={[
            `Empresas conectadas (${formatNumber(countConnectedCompanies)})`,
            `Empresas desconectadas (${formatNumber(countDisconnectCompanies)})`,
            `Convites enviados (${formatNumber(countTotalInvitesSentCompanies)})`,
          ]}
        />
        {renderPrincipalTabs(activeTab)}
      </Fragment>
    </CompaniesManagementProvider>
  )
}