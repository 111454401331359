import React, { createContext, useEffect, useMemo, useState } from 'react';

export const ListOfPublishedLearningContext = createContext();

export default function ListOfPublishedLearningProvider({ children }) {
  const [events, setEvents] = useState(null);
  const [courses, setCourses] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [communicate, setCommunicate] = useState(null);
  const [trails, setTrails] = useState(null);
  const [ebooks, setEbooks] = useState(null);
  const [article, setArticle] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [serie, setSerie] = useState(null);

  const [eventsCount, setEventCount] = useState(0);
  const [coursesCount, setCoursesCount] = useState(0);
  const [programsCount, setProgramsCount] = useState(0);
  const [announcementsCount, setAnnouncementsCount] = useState(0);
  const [trailsCount, setTrailsCount] = useState(0);
  const [ebooksCount, setEbooksCount] = useState(0);
  const [articlesCount, setArticlesCount] = useState(0);
  const [challengesCount, setChallengesCount] = useState(0);
  const [seriesCount, setSeriesCount] = useState(0);
  const [countUniversitiesAttractionsSum, setCountUniversitiesAttractionsSum] =
    useState(0);
  const [refreshCountUniversity, setRefreshCountUniversity] = useState(false);

  const [currentPageEvents, setCurrentPageEvents] = useState(0);
  const [currentPageCourses, setCurrentPageCourses] = useState(0);
  const [currentPagePrograms, setCurrentPagePrograms] = useState(0);
  const [currentPageAnnouncements, setCurrentPageAnnouncements] = useState(0);
  const [currentPageTrails, setCurrentPageTrails] = useState(0);
  const [currentPageEbooks, setCurrentPageEbooks] = useState(0);
  const [currentPageArticles, setCurrentPageArticles] = useState(0);
  const [currentPageChallenges, setCurrentPageChallenges] = useState(0);
  const [currentPageSeries, setCurrentPageSeries] = useState(0);

  const result =
    seriesCount +
    challengesCount +
    articlesCount +
    ebooksCount +
    trailsCount +
    announcementsCount +
    programsCount +
    coursesCount +
    eventsCount;

  useEffect(() => {
    if (refreshCountUniversity) {
      setCountUniversitiesAttractionsSum(result);
      setRefreshCountUniversity(false);
    }
  }, [refreshCountUniversity]);

  return (
    <ListOfPublishedLearningContext.Provider
      value={{
        refreshCountUniversity,
        setRefreshCountUniversity,
        events,
        setEvents,
        courses,
        setCourses,
        programs,
        setPrograms,
        communicate,
        setCommunicate,
        trails,
        setTrails,
        ebooks,
        setEbooks,
        article,
        setArticle,
        challenge,
        setChallenge,
        serie,
        setSerie,
        eventsCount,
        setEventCount,
        coursesCount,
        setCoursesCount,
        programsCount,
        setProgramsCount,
        announcementsCount,
        setAnnouncementsCount,
        trailsCount,
        setTrailsCount,
        ebooksCount,
        setEbooksCount,
        articlesCount,
        setArticlesCount,
        challengesCount,
        setChallengesCount,
        seriesCount,
        setSeriesCount,
        setCountUniversitiesAttractionsSum,
        countUniversitiesAttractionsSum,
        currentPageEvents,
        setCurrentPageEvents,
        currentPageCourses,
        setCurrentPageCourses,
        currentPagePrograms,
        setCurrentPagePrograms,
        currentPageAnnouncements,
        setCurrentPageAnnouncements,
        currentPageTrails,
        setCurrentPageTrails,
        currentPageEbooks,
        setCurrentPageEbooks,
        currentPageArticles,
        setCurrentPageArticles,
        currentPageChallenges,
        setCurrentPageChallenges,
        currentPageSeries,
        setCurrentPageSeries,
      }}
    >
      {children}
    </ListOfPublishedLearningContext.Provider>
  );
}
