import styled from 'styled-components';

export const MultiStepDivActive = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-weight: bold;
  z-index: 2;
  margin-left: 55px;
`;

export const WrapperStep = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
  margin-top: -50px;
`;
