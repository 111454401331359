import React, { useEffect } from 'react';
import { func, shape } from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import 'moment/locale/pt-br';

import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import backgroundImage from 'assets/img/contents.jpg';

import {
  MainContainer,
  SuportContainer,
  ParagraphFooter,
  ParagraphCentered,
  ButtonStyle,
  GreenButton,
} from './styled';

const HelpMenu = (props) => {
  const { handleIsLoadingState } = props;

  function handleClick() {
    window.open(
      'https://docs.google.com/presentation/d/17SMMI_kLNUwY1pxYPl2u72nSZQqwTSNGVMwuBoFPyk0/edit#slide=id.g1dcd1f57cc5_0_26',
      '_blank'
    );
  }

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);
  return (
    <>
      <CompanyHeader
        title="Central de Ajuda"
        backgroundImage={backgroundImage}
      />
      <MainContainer>
        <SuportContainer>
          <ParagraphCentered>
            Acesse o nosso FAQ e tire suas dúvidas
          </ParagraphCentered>
          <ButtonStyle>
            <GreenButton onClick={handleClick}>Acessar FAQ</GreenButton>
          </ButtonStyle>
        </SuportContainer>
        <ParagraphFooter>
          Para solicitar suporte técnico ou instruções de uso, entre em contato
          pelo e-mail <b>suporte@workalove.com</b>
        </ParagraphFooter>
      </MainContainer>
    </>
  );
};

HelpMenu.propTypes = {
  handleIsLoadingState: func.isRequired,
  classes: shape.isRequired,
  location: shape.isRequired,
  openModalWithContent: func.isRequired,
};

export default withStyles(dashboardStyle)(HelpMenu);
