import styled from 'styled-components';

export const HeaderH2 = styled.h2`
  color: ${({ color }) => color};
  font-weight: bold;
  font-size: 18px;
  margin: 0;
`;

export const SubHeader = styled.span`
  color: #606062;
`;
