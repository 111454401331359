import React, { Fragment } from 'react';
import { object } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import useDefineColor from 'constants/colors';

const ValuesTestHelpContent = (props) => {
  const { classes, universityColor } = props;

  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Valores
          </h2>
          <p className={classes.cardCategory}>
            Seus valores representam o que é importante para você tomar decisões
            e fazer escolher de carreira. É por meio dos valores que as empresas
            conseguem identificar o alinhamento entre o seu perfil e a cultura
            da organização.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/FQyc7aksd-k"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

ValuesTestHelpContent.propTypes = {
  classes: object,
};

export default withStyles(behavioralTestStyle)(ValuesTestHelpContent);
