import styled from 'styled-components';

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonLogout = styled.button`
  width: 89px;
  height: 40px;
  border-radius: 24px;
  background-color: #fff;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 16px;
  font-weight: 400;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  margin-left: 20px;

  @media (max-width: 960px) {
    font-size: 0;
    gap: 0;
    width: 70px;
    height: 35px;
  }
`;
