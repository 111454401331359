import styled from 'styled-components';
import arrowRight from 'assets/Oportutinites/Setadireita.svg';
export const ButtonArrowRight = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${arrowRight});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  position: absolute;
  right: 5.4em;
`;
