import React from 'react';

function LogoOnlineNetwork({ color = '#009291', width = 16, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7013 8V0H1.368V8H0.034668V10C0.034668 10.5304 0.245382 11.0391 0.620454 11.4142C0.995527 11.7893 1.50424 12 2.03467 12H14.0347C14.5651 12 15.0738 11.7893 15.4489 11.4142C15.824 11.0391 16.0347 10.5304 16.0347 10V8H14.7013ZM2.70133 1.33333H13.368V8H9.982L9.41734 8.66667H6.65133L6.08734 8H2.70133V1.33333ZM14.7013 10C14.7013 10.1768 14.6311 10.3464 14.5061 10.4714C14.381 10.5964 14.2115 10.6667 14.0347 10.6667H2.03467C1.85786 10.6667 1.68829 10.5964 1.56326 10.4714C1.43824 10.3464 1.368 10.1768 1.368 10V9.33333H5.46867L6.03467 10H10.0347L10.5993 9.33333H14.7013V10Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoOnlineNetwork;
