export function IconPortfolio() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47768)">
        <path
          d="M17.6198 8.5286C17.2545 8.0381 16.6943 7.7561 16.0823 7.7561H15.75V6.26585C15.75 4.1981 14.0677 2.51585 12 2.51585H8.208C8.0925 2.51585 7.9755 2.4881 7.87275 2.4371L5.5065 1.2536C5.196 1.09835 4.848 1.0166 4.5 1.0166H3C1.3455 1.01585 0 2.36135 0 4.01585V13.7659C0 15.8336 1.68225 17.5159 3.75 17.5159H13.1685C14.838 17.5159 16.281 16.4419 16.77 14.8106L17.919 10.2236C18.0945 9.6371 17.985 9.01985 17.6198 8.5286ZM1.5 13.7659V4.01585C1.5 3.1886 2.17275 2.51585 3 2.51585H4.5C4.6155 2.51585 4.7325 2.5436 4.83525 2.5946L7.2015 3.7781C7.512 3.93335 7.86 4.0151 8.208 4.0151H12C13.2405 4.0151 14.25 5.0246 14.25 6.2651V7.75535H6.46125C5.18625 7.75535 4.04775 8.5646 3.6195 9.79535L1.977 15.1489C1.6785 14.7671 1.5 14.2871 1.5 13.7659ZM16.473 9.8261L15.324 14.4124C15.036 15.3716 14.1698 16.0159 13.1685 16.0159H3.75C3.594 16.0159 3.44175 16.0001 3.29475 15.9694L5.04525 10.2626C5.2545 9.66035 5.8245 9.2561 6.462 9.2561H16.083C16.2645 9.2561 16.3703 9.3611 16.4167 9.4241C16.4632 9.4871 16.5338 9.61835 16.473 9.8261Z"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47768">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.265625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
