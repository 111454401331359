import React, { useEffect, useContext, Fragment } from 'react';
import { shape, func, object } from 'prop-types';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import backgroundHeader from 'assets/img/charts-commerce-data.jpg';

import AuthContext from 'contexts/authentication';

function NoAccess({
  handleIsLoadingState,
  classes,
  openSnackbar,
  openTalkModal,
}) {
  const auth = useContext(AuthContext);

  useEffect(() => {
    handleIsLoadingState(false);
    openTalkModal(
      {
        description: (
          <span>
            <p style={{ marginBottom: '20px' }}>Ops!</p>
            <p>
              Há um problema com o seu cadastro na Workalove. Não foram
              encontradas funcionalidades disponíveis com acesso liberado para o
              seu usuário. Por favor, entre em contato com o suporte e informe
              sobre essa situação.
            </p>
          </span>
        ),
        buttonText: 'Entendi',
      },
      () => {
        auth.routerOnUserLogout();
      }
    );
  }, []);

  return (
    <Fragment>
      <CompanyHeader title="Sem acesso" backgroundImage={backgroundHeader} />
      <p></p>
    </Fragment>
  );
}

NoAccess.propTypes = {
  classes: shape.isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  openTalkModal: func.isRequired,
  globalState: object,
};

export default withStyles(ProfessionalExperienceFormStyles)(NoAccess);
