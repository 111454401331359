import React, { useState, useEffect } from 'react';
import backgroundImage from 'assets/img/jobOffers.jpg';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import TabJobStudent from './components/Tabs';
import { Container, Content } from './components/styled';
import JobOffersProvider from './context/JobOffersContext';
import JobOffersApi from './services/JobOffersApi';
import TabExternalJobs from './components/Tabs/TabExternalJobs';
import TabApplicationJobs from './components/Tabs/TabApplicationJobs';
import TabExclusiveVacancies from './components/Tabs/TabsJobOffers/ExclusiveVacancies';
import FavoriteJobsTab from './components/Tabs/TabFavoriteJobs';
import ProfileProvider from '../Profile/Context/ProfileContext';

export default function NewJobOffers(props) {
  const {
    universityColor,
    handleIsLoadingState,
    academic_profile,
    interests,
    life_interests,
    setUserData,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [gamificationData, setGamificationData] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterOptionsOtherExternal, setFilterOptionsOtherExternal] = useState(
    []
  );
  const [filterState, setFilterState] = useState({});
  const [queryFilter, setQueryFilter] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [loadingFilters, setLoadingFilters] = useState(false);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  useEffect(() => {
    switch (activeTab) {
      case 0:
        JobOffersApi.getFilterJobOffers().then((data) => {
          setFilterOptions(data);
        });
        break;

      case 1:
        JobOffersApi.getFilterExternalJobs().then((data) => {
          setFilterOptions(data);
        });
        JobOffersApi.getFilterExternalOtherJobs().then((data) => {
          setFilterOptionsOtherExternal(data);
        });
        break;

      case 2:
        JobOffersApi.getFilterOnGoingJobs().then((data) => {
          setFilterOptions(data);
        });
        break;

      case 3:
        JobOffersApi.getFilterFavoriteJobs().then((data) => {
          setFilterOptions(data);
        });
        break;
    }
  }, [activeTab]);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <TabExclusiveVacancies
          universityColor={universityColor}
          setFilterOptions={setFilterOptions}
          filterState={filterState}
          filterOptions={filterOptions}
          activeTab={activeTab}
          setLoadingFilters={setLoadingFilters}
          handleIsLoadingState={handleIsLoadingState}
          academic_profile={academic_profile}
          interests={interests}
          life_interests={life_interests}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <TabExternalJobs
          universityColor={universityColor}
          setFilterOptions={setFilterOptions}
          filterState={filterState}
          filterOptions={filterOptions}
          activeTab={activeTab}
          setLoadingFilters={setLoadingFilters}
          academic_profile={academic_profile}
          interests={interests}
          life_interests={life_interests}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <TabApplicationJobs
          universityColor={universityColor}
          setFilterOptions={setFilterOptions}
          filterState={filterState}
          filterOptions={filterOptions}
          activeTab={activeTab}
          setLoadingFilters={setLoadingFilters}
        />
      ),
    },
    {
      activeTab: 3,
      component: (
        <FavoriteJobsTab
          universityColor={universityColor}
          queryFilter={queryFilter}
          setFilterOptions={setFilterOptions}
          setLoadingFilters={setLoadingFilters}
          academic_profile={academic_profile}
          interests={interests}
          life_interests={life_interests}
        />
      ),
    },
  ];

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);

    return returnTabs.component;
  };

  return (
    <JobOffersProvider
     setGamificationData={setGamificationData}
     setUserData={setUserData}
    >
      <ProfileProvider
        gamificationData={gamificationData}
        setGamificationData={setGamificationData}
      >
        <CompanyHeader title="Vagas" backgroundImage={backgroundImage} />
        <Container>
          <Content>
            <TabJobStudent
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              filterState={filterState}
              setFilterState={setFilterState}
              appliedFilters={appliedFilters}
              universityColor={universityColor}
              setAppliedFilters={setAppliedFilters}
              setQueryFilter={setQueryFilter}
              loadingFilters={loadingFilters}
              setLoadingFilters={setLoadingFilters}
              filterOptions={filterOptions}
              filterOptionsOtherExternal={filterOptionsOtherExternal}
              showFilter
            />
            {renderPrincipalTabs(activeTab)}
          </Content>
        </Container>
      </ProfileProvider>
    </JobOffersProvider>
  );
}
