import styled from 'styled-components';

export const TalentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px #cdcdcd solid;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 40px 10px 40px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  & + & {
    margin-top: 20px;
  }

  .row {
    display: flex;
    padding: 0px;
    flex-direction: row;
  }

  .firstColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .secondColumn {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-bottom: 10px;
    padding-right: 0px;
    width: -webkit-fill-available;
    margin-left: auto;

    button.openCurriculum {
      color: #009291;
      background: none;
      outline: none;
      border: none;
      font-size: 14px;
      font-weight: 400;
      height: 40px;
      margin-top: auto;
      margin-right: 4px;
      cursor: pointer;
    }
  }

  h3.name {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    margin: 0px 0px 14px;
  }

  infos {
    width: 388px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;

    b {
      font-weight: 800;
    }
  }
  p.highlight {
    font-weight: 700;
    color: #009291;
  }

  a.phone {
    color: #009291;
    text-decoration: underline;
    cursor: pointer;
  }

  button.curriculumIndication {
    width: 151px;
    height: fit-content;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    background-color: #009291;
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    margin-top: auto;
  }
`;
