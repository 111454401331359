import ArrowLeft from 'assets/SettingsPage/Profile/arrowLeft';
import { ComebackHeader } from './style';

export default function CloseButton({ onClick }) {
  return (
    <ComebackHeader>
      <button onClick={onClick}>
        <ArrowLeft color={'#009291'} size={30} />
        Voltar
      </button>
    </ComebackHeader>
  );
}
