export function IconInterests({ color }) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16960_53819)">
        <rect width="28.2813" height="28.2813" fill="white" />
        <path
          d="M26.8354 9.09882C26.2829 8.46203 25.5999 7.9514 24.8329 7.60151C24.0658 7.25162 23.2325 7.07063 22.3894 7.07081H17.6888L18.0847 4.66573C18.2248 3.81877 18.0558 2.94968 17.6085 2.21696C17.1612 1.48425 16.4654 0.936732 15.648 0.674281C14.8307 0.411829 13.9462 0.45193 13.156 0.787269C12.3657 1.12261 11.7223 1.73084 11.3432 2.50103L9.08773 7.07081H5.89194C4.32987 7.07269 2.83232 7.69404 1.72778 8.79859C0.623228 9.90314 0.00187111 11.4007 0 12.9628L0 18.8547C0.00187111 20.4168 0.623228 21.9143 1.72778 23.0189C2.83232 24.1234 4.32987 24.7448 5.89194 24.7466H21.5645C22.9826 24.7408 24.3516 24.2261 25.4223 23.2962C26.493 22.3663 27.1943 21.0829 27.3987 19.6796L28.2295 13.7876C28.3465 12.9518 28.2825 12.1007 28.042 11.2917C27.8015 10.4828 27.39 9.73493 26.8354 9.09882ZM2.35678 18.8547V12.9628C2.35678 12.0252 2.72923 11.126 3.3922 10.463C4.05517 9.80004 4.95436 9.42759 5.89194 9.42759H8.24871V22.3899H5.89194C4.95436 22.3899 4.05517 22.0174 3.3922 21.3544C2.72923 20.6915 2.35678 19.7923 2.35678 18.8547ZM25.8904 13.4565L25.0584 19.3484C24.9368 20.1898 24.5172 20.9595 23.876 21.5177C23.2348 22.0759 22.4146 22.3853 21.5645 22.3899H10.6055V9.11414C10.7165 9.01739 10.8085 8.90068 10.8765 8.77005L13.456 3.5439C13.5527 3.36942 13.6895 3.22038 13.855 3.10903C14.0206 2.99768 14.2102 2.92721 14.4083 2.9034C14.6063 2.87959 14.8073 2.90312 14.9945 2.97206C15.1817 3.041 15.3499 3.15339 15.4852 3.29997C15.601 3.4346 15.6856 3.59312 15.7331 3.76422C15.7805 3.93532 15.7896 4.11479 15.7598 4.28982L15.1376 8.06066C15.1103 8.22915 15.1199 8.40156 15.1658 8.56596C15.2116 8.73036 15.2927 8.88284 15.4033 9.01283C15.5139 9.14283 15.6514 9.24725 15.8064 9.31886C15.9613 9.39047 16.13 9.42757 16.3006 9.42759H22.3894C22.8954 9.42752 23.3955 9.53608 23.8559 9.74593C24.3164 9.95577 24.7264 10.262 25.0583 10.644C25.3902 11.0259 25.6362 11.4747 25.7798 11.9599C25.9233 12.4451 25.961 12.9555 25.8904 13.4565Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_16960_53819">
          <rect width="28.2813" height="28.2813" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
