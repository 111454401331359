import React, { useEffect, useState } from 'react';
import { Form } from '@unform/web';
import {
  ContainerModalInterview,
  BackgroundModals,
  HeaderModalInterview,
  ButtonClose,
  ParagraphInterview,
  BodyModalInterview,
  ContainerButtons,
  Button,
  ParagraphText,
  CustomCancellationForm,
} from '../../styled';
import SimpleInput from 'components/UnformFields/SimpleInput';
import { IconArrowLeftFlag } from 'assets/JobOffer/IconArrowLeftFlag';

export default function ModalStudentCancelConfirmation(props) {
  const {
    universityColor,
    handleCloseModal,
    interviewId,
    getInterviewId,
    applicationId,
  } = props;
  const [canceledInterview, setCanceledInterview] = useState(null);

  const fetchInterview = async () => {
    const response = await getInterviewId(interviewId, applicationId);
    setCanceledInterview && setCanceledInterview(response);
  };

  useEffect(() => {
    fetchInterview();
  }, []);

  const formatMessage = (message) => {
    return message.replace(/<[^>]+>/g, '');
  };

  const formattedMessage = canceledInterview
    ? formatMessage(
        canceledInterview.interviewee_message ||
          canceledInterview.interviewer_message
      )
    : '';

  return (
    <BackgroundModals>
      <ContainerModalInterview isInterview>
        <HeaderModalInterview universityColor={universityColor}>
          <ParagraphInterview>Detalhes do cancelamento</ParagraphInterview>
          <ButtonClose onClick={handleCloseModal}>X</ButtonClose>
        </HeaderModalInterview>
        <BodyModalInterview>
          <Form initialData={{ interviewee_message: formattedMessage }}>
            <ParagraphText>Motivo do cancelamento:</ParagraphText>
            {canceledInterview?.interviewee_message !== '' ? (
              <SimpleInput
                name="interviewee_message"
                placeholder="Motivo do cancelamento"
                value={formattedMessage}
                disabled
              />
            ) : (
              <SimpleInput
                name="intervieweer_message"
                placeholder="Motivo do cancelamento"
                value={formattedMessage}
                disabled
              />
            )}
            <CustomCancellationForm>
              <div className="author-flag">
                <IconArrowLeftFlag color="#DA0505" />
                {canceledInterview?.canceled_by}
              </div>
            </CustomCancellationForm>
          </Form>
        </BodyModalInterview>
      </ContainerModalInterview>
    </BackgroundModals>
  );
}
