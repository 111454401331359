import React, { useState, useRef, useEffect } from 'react';
import {
  Page,
  Document,
  View,
  Text,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from '@react-pdf/renderer';
import WorkaloveLogo from 'assets/academicModeling/LogoWorkaloveWhite.png';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingBottom: 20,
  },
  header: {
    backgroundColor: '#2f9190',
    width: '100%',
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  logo: {
    width: 200,
    marginBottom: 10,
  },
  content: {
    backgroundColor: 'white',
    padding: 20,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  sectionHeader: {
    marginBottom: 10,
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    color: '#009291',
    fontSize: 14,
  },
  boldText: {
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,
  },
  slightlyBoldText: {
    color: '#606062',
    fontWeight: 'bold',
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,
  },
  subText: {
    marginBottom: '5px',
    color: '#606062',
  },
  regularText: {
    fontWeight: 'normal',
    fontSize: 11,
  },
  block: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#C4C4C4',
    borderBottomStyle: 'solid',
    marginBottom: 20,
    marginTop: 50,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 11,
    marginBottom: 10,
    color: '#606062',
    lineHeight: 1.5,
  },
  requirementHeader: {
    fontWeight: 'bold',
    color: '#606062',
    lineHeight: 1.5,
  },
  requirementHeaderOportunities: {
    fontWeight: 'bold',
    color: '#606062',
  },
  requirementItem: {
    padding: '16px 0',
    color: '#777777',
  },
  requirementItemDivider: {
    width: '100%',
    backgroundColor: '#C4C4C4',
    height: '1px',
  },
  lateralInformations: {
    display: 'flex',
  },
  card: {
    borderWidth: 1,
    borderColor: '#009291',
    width: '250px',
    height: '150px',
    borderRadius: 4,
    borderStyle: 'solid',
    marginRight: '40px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 30px',
  },
  porcentageHeader: {
    color: '#009291',
    fontSize: 30,
    marginBottom: 10,
    fontFamily: 'Helvetica-Bold',
  },
  CardTitle: {
    color: '#606062',
    fontSize: 13,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 10,
  },
  CardSubTitle: {
    color: '#606062',
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center',
  },
  containerCards: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  requerimentsListageHeader: {
    color: '#B8B8B8',
    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
    marginTop: 30,
    marginBottom: 15,
    display: 'inline-block',
  },
  requerimentsHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  requerimentsListageWrapper: {
    display: 'flex',
    flexDirection: 'row',
    padding: '16px 0',
    color: '#777777',
  },
  headers: {
    requeriments: {
      color: '#B8B8B8',
      border: '1px solid red',
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      marginTop: 30,
    },
    oportunities: {
      whiteSpace: 'nowrap',
      color: '#B8B8B8',
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      marginTop: 30,
      marginBottom: 15,
      width: '90%',
      textAlign: 'right',
    },
  },
  listage: {
    requeriments: {
      width: '90%',
      color: '#777777',
    },
    oportunities: {
      maxWidth: '150px',
      textAlign: 'center',
      color: '#B8B8B8',
      fontFamily: 'Helvetica-Bold',
      fontSize: 9,
      marginTop: 30,
      marginBottom: 15,
    },
  },
});
const mapContempled = (array, oportunities) => {
  if (!oportunities) {
    return array.map((item, index) => (
      <View>
        <Text key={item} style={styles.requirementItem}>
          {item}
        </Text>
        {index !== array.length - 1 && (
          <View style={styles.requirementItemDivider} />
        )}
      </View>
    ));
  }
  return array.map((item, index) => (
    <View>
      <View style={styles.requerimentsListageWrapper}>
        <Text style={styles.listage.requeriments}>{item.name}</Text>
        <Text style={styles.listage.requeriments}>{item.opportunities}</Text>
      </View>
      {index !== array.length - 1 && (
        <View style={styles.requirementItemDivider} />
      )}
    </View>
  ));
};

export const PDF = ({ data, isTendencies }) => (
  <Document>
    <Page style={styles.page}>
      <View fixed style={styles.header}>
        <Image style={{ width: '130px' }} src={WorkaloveLogo} />
      </View>

      <View style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.sectionHeader}>
            {isTendencies
              ? 'Listagem das tendências contempladas'
              : 'Listagem dos requisitos contemplados'}
          </Text>
          <View style={styles.block}>
            <Text style={styles.subText}>
              <Text style={styles.slightlyBoldText}>Data: </Text>
              {data?.date}
            </Text>
            <Text style={styles.subText}>
              <Text style={styles.slightlyBoldText}>Curso:</Text> {data?.name}
            </Text>
            <Text style={styles.subText}>
              <Text style={styles.slightlyBoldText}>
                {data?.course_coordinator?.length > 1
                  ? 'Coordenadores'
                  : 'Coordenador'}{' '}
                do Curso (a):
              </Text>{' '}
              {data?.course_coordinator?.join(', ')}{' '}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <Text style={styles.sectionHeader}>
            {isTendencies ? 'Tendências' : 'Requisitos'} de Mercado
          </Text>
          <View style={styles.block}>
            <Text style={styles.subtitle}>
              Esse é o percentual que o seu plano pedagógico atual atende de
              {isTendencies
                ? ' têndencias de mercado'
                : ' requisitos' + ' exigidos pelo mercado de trabalho'}{' '}
              nas principais atuações profissionais de seu curso.
            </Text>

            <View style={styles.containerCards}>
              <View style={styles.card}>
                <Text style={styles.porcentageHeader}>
                  {data?.metrics.percent_contemplated}%
                </Text>

                <Text style={styles.CardTitle}>Contemplados</Text>
                <Text style={styles.CardSubTitle}>
                  {data?.metrics.percent_contemplated}%{' '}
                  {isTendencies
                    ? 'das tendências de mercado '
                    : 'dos requisitos de mercado '}
                  estão contemplad{isTendencies ? 'a' : 'o'}s no seu plano
                  pedagógico
                </Text>
              </View>

              <View style={styles.card}>
                <Text style={styles.porcentageHeader}>
                  {data?.metrics.percent_not_contemplated}%
                </Text>

                <Text style={styles.CardTitle}>Não contemplados</Text>
                <Text style={styles.CardSubTitle}>
                  {data?.metrics.percent_not_contemplated > 0
                    ? `${data?.metrics.percent_not_contemplated}` +
                      `% ${
                        isTendencies
                          ? 'das tendências de mercado'
                          : 'dos' + ' requisitos de mercado'
                      }` +
                      ' não estão contemplados no seu plano pedagógico'
                    : `Muito bem! No momento, você não possui ${
                        isTendencies ? 'tendências' : 'requisitos'
                      } a` +
                      ' serem contemplados de acordo com o seu perfil de' +
                      ' egresso.'}
                </Text>
              </View>
            </View>

            <View style={styles.divider} />
          </View>
        </View>
        {data?.metrics.percent_contemplated ? (
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>
              {isTendencies
                ? 'Tendências de mercado contempladas'
                : 'Requisitos de mercado contemplados'}
            </Text>
            <View style={styles.block}>
              <Text style={styles.requirementHeader}>
                Identificamos que{' '}
                {isTendencies ? 'as tendências' : 'os' + ' requisitos'} de
                mercado apresentados abaixo são contemplados pela matriz
                curricular do seu curso, de acordo com o mapeamento dos
                conteúdos descritos pelas tags.
              </Text>
              <Text style={styles.requerimentsListageHeader}>
                {isTendencies ? 'TENDÊNCIA' : 'REQUISITO'}
              </Text>
              {mapContempled(data?.contemplated)}
            </View>
          </View>
        ) : null}

        {data?.metrics.percent_not_contemplated ? (
          <View style={styles.section}>
            <Text style={styles.sectionHeader}>
              Sugestões para oferta de oportunidades de aprendizagem
            </Text>
            <View style={styles.block}>
              <Text style={styles.requirementHeader}>
                {isTendencies
                  ? 'Essas são as tendências de mercado não' + ' contempladas'
                  : 'Esses são os requisitos de mercado' +
                    ' não contemplados'}{' '}
                em sua Matriz Curricular atual. Para aumentar sua pontuação no
                indicador de trabalhabilidade, publique oportunidades de
                aprendizagem para
                {isTendencies ? ' essas tendências' : ' esses requisitos'}.
              </Text>

              <View style={styles.requerimentsHeaderWrapper}>
                <Text style={styles.headers.requeriments}>
                  {isTendencies ? 'TENDÊNCIA' : 'REQUISITO'}
                </Text>
                <Text style={styles.headers.oportunities}>
                  OPORTUNIDADES DE APRENDIZAGEM
                </Text>
              </View>

              {mapContempled(data?.not_contemplated, true)}
            </View>
          </View>
        ) : null}
      </View>
    </Page>
  </Document>
);

const ReportsPdf = ({ data, closeModal, isTendencies = false }) => {
  const [startDownload, setStartDownload] = useState(false);

  const buttonRef = useRef(null);

  const handleClickTrigger = () => {
    setStartDownload(true);
  };

  useEffect(() => {
    buttonRef.current?.click();
    setTimeout(() => {
      closeModal();
    }, 500);
  }, []);

  return (
    <div>
      <button ref={buttonRef} onClick={handleClickTrigger}>
        trigger
      </button>
      <PDF data={data} isTendencies={isTendencies} />
      {startDownload && (
        <PDFDownloadLink
          document={<PDF data={data} isTendencies={isTendencies} />}
          fileName="Relatorio.pdf"
        >
          {({ url, loading }) => {
            if (!loading) {
              setStartDownload(false);
              const downloadLink = document.createElement('a');
              const fileName = `Relatório de ${
                isTendencies ? 'tendências' : 'requisitos'
              } de ${data.name} - ${data.date}.pdf`;
              downloadLink.href = url;
              downloadLink.download = fileName;
              downloadLink.click();
            }
          }}
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default ReportsPdf;
