import { ActiveCategoryIcon } from 'assets/csm/ActiveCategoryIcon';
import { WithCurriculumCategoryIcon } from 'assets/csm/WithCurriculumCategoryIcon';
import { CandidatesCategoryIcon } from 'assets/csm/CandidatesCategoryIcon';
import { WorkingCategoryIcon } from 'assets/csm/WorkingCategoryIcon';
import { NotWorkingCategoryIcon } from 'assets/csm/NotWorkingCategoryIcon';
import { InactiveCategoryIcon } from 'assets/csm/InactiveCategoryIcon';
import React from 'react';
import { StudentCategory } from '../resources/defaultStudentCategories';
import { DirectHiringIcon } from 'assets/csm/DirectHiringIcon';
import { WithoutCurriculumIcon } from 'assets/csm/WithoutCurriculumIcon';

export function CategoryImage({ category, size = 56, color = 'fff' }) {
  switch (category) {
    case StudentCategory.ACTIVE:
      return <ActiveCategoryIcon size={size} color={color} />;
    case StudentCategory.DIRECT_HIRIGN:
      return <DirectHiringIcon size={size} color={color} />;
    case StudentCategory.WITHOUT_CURRICULUM:
      return <WithoutCurriculumIcon size={size} color={color} />;
    case StudentCategory.WITH_CURRICULUM:
      return <WithCurriculumCategoryIcon size={size} color={color} />;
    case StudentCategory.APPLICATIONS:
      return <CandidatesCategoryIcon size={size} color={color} />;
    case StudentCategory.WORKING:
      return <WorkingCategoryIcon size={size} color={color} />;
    case StudentCategory.NOT_WORKING:
      return <NotWorkingCategoryIcon size={size} color={color} />;
    case StudentCategory.INACTIVE:
      return <InactiveCategoryIcon size={size} color={color} />;
    default:
      return null;
  }
}
