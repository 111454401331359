import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  margin: 2px;
  border: none;
  transition: 1s;
  display: flex;
  flex-direction: column;
  flex: none;

  @media (max-width: 425px) {
    min-width: 280px;
    transition: 1s;
  }

  @media (max-width: 375px) {
    min-width: 220px;
    transition: 1s;
  }
`;

export const Image = styled.div`
  height: 181px;
  object-fit: cover;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  img {
    width: ${(props) => (props.isLogo ? '75%' : '100%')};
    height: ${(props) => (props.isLogo ? 'unset' : '100%')};
    object-fit: cover;

    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
`;

export const Title = styled.p`
  padding: 21px 21px 10px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 5px;

  span {
    font-size: 13px;
    color: #4b4b4d;
    font-weight: 700;
    margin: 0;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ListItemCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 7px;
  flex-direction: column;
  padding: 0 21px;
  height: 164px;

  .wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .wrapper p {
    font-size: 13px;
    margin: 0;
    margin-top: 2px;
  }

  .wrapper-description {
    height: 70px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .wrapper-description span {
    font-size: 11px;
    color: #606062;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wrapper:first-child {
    margin-top: 13px;
  }

  @media (max-width: 330px) {
    .wrapper p {
      font-size: 12px;
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 21px 17px;
  gap: 2px;
  height: 26px;
  margin-bottom: 20px;

  @media (max-width: 370px) {
    gap: 10px;
    flex-direction: column;
    height: 100%;
  }
`;

export const ContainerReactButtons = styled(ContainerButtons)`
  height: 100%;

  .container-react {
    display: flex;
    gap: 2px;
  }
  @media (max-width: 370px) {
    flex-direction: column;

    .container-react {
      gap: 14px;
    }
  }
`;

export const AdvanceButton = styled.button`
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? '100%' : '96px')};
  height: 26px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  @media (max-width: 370px) {
    max-width: 100%;
    height: 30px;
  }
`;

export const WhiteButton = styled.button`
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? '100%' : '146px')};
  height: 26px;
  border-radius: 4px;
  background-color: transparent;
  color: #009291;
  font-size: 12px;
  border: 1px solid #009291;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 370px) {
    max-width: 100%;
    height: 30px;
  }
`;

export const SimpleButton = styled.button`
  background-color: transparent;
  color: #009291;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  border: none;

  @media (max-width: 370px) {
    order: 2;
  }
`;
