import React from 'react';
import {
  ContentContainer,
  Icon,
  HeaderPagination,
  ButtonPagination,
  BaseContainer,
  Title,
  Paragraph,
  ContainerContentCards,
  Container,
} from './styled';

export default function SkeletonGamification() {
  return (
    <BaseContainer>
      <HeaderPagination>
        <ContainerContentCards>
          <Icon />
          <Container>
            <Title />
            <Paragraph />
          </Container>
        </ContainerContentCards>

        <ButtonPagination />
      </HeaderPagination>
      <ContentContainer />
      <ContentContainer size={'70%'} />
    </BaseContainer>
  );
}
