import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ finished }) => finished};
  border-radius: 12px;
  width: 215px;
  height: 295px;
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.04);
  }

  h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dcdcdc;
    font-size: 12px;
    color: #606062;
    border-radius: 4px;
    height: 20px;
    padding: 0 4px;
    margin: 1rem 0 0 0;
  }

  h3 {
    width: 100%;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    line-height: 1.2;
    height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin: 1.5rem 0;
  }

  > footer,
  strong {
    font-size: 10px;
    margin-top: 1rem;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ finished }) => finished};
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const Stepper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`;
export const StepStatus = {
  PENDING: 'pending',
  CURRENT: 'current',
  DONE: 'done',
};

const stepBackgroundColors = {
  [StepStatus.PENDING]: '#E5E5E5',
  [StepStatus.CURRENT]: '#F09D51',
  [StepStatus.DONE]: '#009291',
};

const stepFontColors = {
  [StepStatus.DONE]: '#FFF',
  [StepStatus.PENDING]: '#AAB4BD',
  [StepStatus.CURRENT]: '#AAB4BD',
};

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${({ status }) => stepBackgroundColors[status]};
  font-size: 15px;
  color: ${({ status }) => stepFontColors[status]};
`;
