import React from 'react';

function LinkIconAlt({ color, size = 20 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_36331_40540)">
        <path
          fill={color}
          d="M6.53 13.475a5.399 5.399 0 01-.535-.623.833.833 0 011.347-.982c.108.148.229.29.365.426a3.518 3.518 0 002.504 1.037c.946 0 1.836-.368 2.504-1.037l4.583-4.584a3.546 3.546 0 000-5.009 3.546 3.546 0 00-5.009 0l-.882.882a.832.832 0 11-1.178-1.178l.882-.882a5.213 5.213 0 017.366 0 5.214 5.214 0 010 7.366l-4.584 4.583A5.172 5.172 0 0110.211 15c-1.391 0-2.7-.542-3.682-1.525zM5.21 20a5.17 5.17 0 003.683-1.526l.882-.881a.832.832 0 10-1.178-1.179l-.883.882a3.518 3.518 0 01-2.504 1.037 3.518 3.518 0 01-2.504-1.037 3.518 3.518 0 01-1.038-2.504c0-.946.369-1.836 1.038-2.505l4.583-4.583a3.518 3.518 0 012.504-1.037c.946 0 1.836.368 2.504 1.037.134.134.255.277.364.425a.833.833 0 101.348-.98 5.203 5.203 0 00-.532-.622A5.173 5.173 0 009.793 5c-1.39 0-2.699.542-3.682 1.526l-4.583 4.583a5.172 5.172 0 00-1.526 3.683c0 1.39.542 2.699 1.526 3.682A5.17 5.17 0 005.211 20z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_36331_40540">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkIconAlt;
