import styled from 'styled-components';

export const LinkText = styled.a`
  display: flex;
  margin: 15px 0px;
  line-height: 17.25px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 13px;
`;
