import React, { useEffect, useState } from 'react';
import { zipCodeRequest } from 'services/zipCode';
import { maskZipCode } from 'utils/inputMasks';
import { handleFieldMask } from 'utils/validation';
import { ErrorWrapper, WrapperContainer, WrapperForm } from './styled';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import Input from 'views/StaffPages/Opportunities/Components/Form/input';

const CepAddressInput = ({ formRef, defaultValues }) => {
  const [cep, setCep] = useState(defaultValues?.zip_code || '');
  const [addressData, setAddressData] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchAddress = async () => {
      if (cep.length === 9) {
        try {
          setIsLoading(true);
          const response = await zipCodeRequest(cep);
          setIsLoading(false);
          if (!response.erro) {
            setAddressData(response);
            setError(false);
          } else {
            setError(true);
          }
        } catch (error) {
          setError(true);
        }
      } else {
        formRef.current.setFieldError('address', null);
        formRef.current.setFieldError('address_number', null);
        formRef.current.setFieldError('district', null);
        formRef.current.setFieldError('city', null);
        formRef.current.setFieldError('state', null);
      }
    };

    fetchAddress();
  }, [cep]);

  useEffect(() => {
    if (defaultValues?.zip_code) {
      setCep(defaultValues?.zip_code);
    }
  }, []);

  const handleCepChange = (value) => {
    setCep(value);
    setAddressData({});
    setError(false);
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('Text');
    if (pastedText.length === 9) {
      handleCepChange(pastedText);
    }
  };

  return (
    <>
      <WrapperContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WrapperForm>
              <p>CEP do local da mentoria*</p>
              <Input
                name="zip_code"
                placeholder="Digite aqui..."
                onKeyUp={(e) => handleFieldMask(e, maskZipCode)}
                onPaste={handlePaste}
                maxLength={9}
                onChange={(e) => handleCepChange(e.target.value)}
                error={error}
                style={{ width: '15rem' }}
                onSearchIcon={true}
                marginTop="25px"
                defaultValue={defaultValues?.zip_code}
              />
            </WrapperForm>
          </div>
          {error && (
            <ErrorWrapper>
              <AiOutlineCloseCircle size={18} color={'#f10909'} />
              CEP não encontrado
            </ErrorWrapper>
          )}
        </div>
        {cep.length === 9 && !isLoading && !error && (
          <WrapperForm>
            <p>Rua/Avenida*</p>
            <Input
              name="address"
              placeholder="Digite aqui..."
              defaultValue={addressData.logradouro || ''}
              style={{ width: '23rem' }}
            />
          </WrapperForm>
        )}
      </WrapperContainer>

      {cep.length === 9 && !isLoading && !error && (
        <>
          <WrapperContainer>
            <WrapperForm>
              <p>Número*</p>
              <Input
                name="address_number"
                placeholder="Digite aqui..."
                defaultValue={addressData.address_number || ''}
              />
            </WrapperForm>
            <WrapperForm>
              <p>Complemento</p>
              <Input
                name="address_complement"
                placeholder="Digite aqui..."
                defaultValue={addressData.address_complement || ''}
              />
            </WrapperForm>
            <WrapperForm>
              <p>Bairro*</p>
              <Input
                name="district"
                placeholder="Digite aqui..."
                defaultValue={addressData.bairro || ''}
              />
            </WrapperForm>
          </WrapperContainer>
          <WrapperContainer>
            <WrapperForm>
              <p>Cidade*</p>
              <Input
                name="city"
                placeholder="Digite aqui..."
                value={addressData.localidade || ''}
                disabled
                style={{
                  backgroundColor: '#F5F5F5',
                  cursor: 'not-allowed',
                  width: '25rem',
                }}
              />
            </WrapperForm>
            <WrapperForm>
              <p>UF*</p>
              <Input
                name="state"
                placeholder="Digite aqui..."
                value={addressData.uf || ''}
                disabled
                style={{
                  backgroundColor: '#F5F5F5',
                  cursor: 'not-allowed',
                }}
              />
            </WrapperForm>
          </WrapperContainer>
        </>
      )}
    </>
  );
};

export default CepAddressInput;
