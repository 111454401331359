import React, { useCallback, useMemo, useState } from 'react';
import Pagination from 'components/CustomPagination';
import JobHistoryCards from '../../../components/HistoryCards';
import SkeletonJobsCard from '../../../components/JobsSkeleton';

import { Container, Content, Title } from './styled';
import FeedBackModal from '../../../components/HistoryCards/FeedBackModal';
import GenericMessage from 'components/GenericMessage';

function RegisteredByInstitution({
  jobs,
  setCurrentPage,
  currentPage,
  onPageChange,
  jobsCount,
  setLimit,
  limit,
  loadJobsData,
  setListJobsCompanyHistory,
  setJobsCount,
  companyFilters,
  dataFunction,
}) {
  const [feedback, setFeedback] = useState({ feedback: '' });
  const [modalFeedbackOpen, setModalFeedbackOpen] = useState(false);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const skeletonCardsQuantity = useMemo(() => {
    return randomIntFromInterval(1, 4);
  }, []);

  const changeModalFeedbackState = useCallback(() => {
    setModalFeedbackOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalFeedbackOpen(false);
  }, []);

  return jobs === null ? (
    <Container>
      <Content>
        <SkeletonJobsCard quantity={skeletonCardsQuantity} />
      </Content>
    </Container>
  ) : jobs?.length > 0 ? (
    <Container>
      <Content>
        {jobs.map((jobs) => (
          <JobHistoryCards
            changeModalFeedbackState={changeModalFeedbackState}
            setFeedback={setFeedback}
            jobName={jobs.name}
            openedSince={jobs.opened_since}
            responsibleName={jobs.responsible_name}
            numberOfCandidates={jobs.number_of_candidates}
            status={jobs.status}
            jobID={jobs.id}
            isStatus={jobs.status}
            jobs={jobs}
            limit={limit}
            setListJobsCompanyHistory={setListJobsCompanyHistory}
            setJobsCount={setJobsCount}
            companyFilters={companyFilters}
            dataFunction={dataFunction}
          />
        ))}

        <FeedBackModal
          limit={limit}
          currentPage={currentPage}
          feedback={feedback}
          isOpen={modalFeedbackOpen}
          closeModal={handleCloseModal}
          loadJobsData={loadJobsData}
        />
      </Content>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={jobsCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  ) : (
    <Container>
      <GenericMessage title="No momento, não há nenhuma vaga disponível." />
    </Container>
  );
}

export default RegisteredByInstitution;
