import { GenerateBlob } from 'components/UploadEvidences/components/PdfViwer/generateBlob';
import { DownloadButton } from 'components/UploadEvidences/components/PdfViwer/style';
import React, {useContext} from 'react';
import { getImagesStudent } from 'views/Student/Extracurricular/services';
import { Container } from './style';
import BaseLayoutContext from 'contexts/base-layout';

export default function ImageViwer({ file, name, type, universityColor }) {

  const handleClickDownload = async () => {
    const response = await getImagesStudent(file.id);
    const blob = window.URL.createObjectURL(new Blob([response.data]));
    const downloadLink = document.createElement('a');
    downloadLink.href = blob;
    downloadLink.download = `${name}${type}`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blob);
  };



  if (file.createdNow) {
    const previewURL = URL.createObjectURL(file.file);
    return (
      <Container>
        <img src={previewURL} />
      </Container>
    );
  }

  return (
    <Container>
      <DownloadButton universityColor={universityColor} onClick={handleClickDownload}>
        FAZER DOWNLOAD
      </DownloadButton>

      <img src={file.image.file} />
    </Container>
  );
}
