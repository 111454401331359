import * as Yup from 'yup';

export const schema = Yup.object().shape({
  cancelReason: Yup.string()
    .trim()
    .required('Este campo é obrigatório')
    .test(
      'limite-de-caracteres',
      'Limite de 200 caracteres excedido',
      (value) => {
        if (!value) return true;
        if (stripHtml(value).replace(/\s/g, '').length <= 200) {
          return true;
        }
        return false;
      }
    ),
});

const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};
