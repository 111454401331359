import React from 'react';
import { IconWrapper, ItemWrapper, TruncatedLink } from './styled';

const DetailsItem = ({ icon, label, value, valueNew }) => {
  const renderValue = (value) =>
    value == null ? '' : typeof value === 'object' ? value.label || '' : value;

  return (
    <ItemWrapper>
      <div className="container">
        <IconWrapper>{icon}</IconWrapper>
        <div className="text-wrapper">
          <strong>{label}</strong>
          {valueNew ? (
            <div className="value-wrapper">
              <p>
                <strong>Antigo:</strong>
                <span>{renderValue(value)}</span>
              </p>
              <p>
                <strong>Novo:</strong> {renderValue(valueNew)}
              </p>
            </div>
          ) : (
            <p>
              {label === 'Online' && value ? (
                <TruncatedLink
                  href={value}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {renderValue(value)}
                </TruncatedLink>
              ) : (
                renderValue(value)
              )}
            </p>
          )}
        </div>
      </div>
    </ItemWrapper>
  );
};

export default DetailsItem;
