import {
  SkeletonDescription,
  SkeletonTitle,
  Wrapper,
} from '../../Styles/StyleSkeleton';

export function SectionSkeleton({ title, subtitle }) {
  return (
    <Wrapper>
      {title && <SkeletonTitle subtitle={subtitle} />}
      {subtitle && <SkeletonDescription />}
    </Wrapper>
  );
}
