import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 60vh;
  display: flex;

  flex-direction: column;
  margin-top: 50px;

  > span {
    text-align: center;
    color: #4b4b4d;
    width: 100%;
    font-weight: 400;
    font-size: 22px;
    margin: 40px 0px;
  }
`;

export const SelectCategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;
