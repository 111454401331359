import {
  AddButton,
  CharactersCounter,
  Container,
  FieldWrapper,
  Input,
  ListTags,
  Progress,
  ProgressBar,
  ProgressWrapper,
  WorkloadWrapper,
  ContainerSelect,
  ContainerCharacterCount,
  Error,
} from './style';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import AcademicModelingApi from 'services/university/academicModeling';
import { useAcademicModeling } from '../../../../contexts/AcademicModelingContext';
import Select, { components } from 'react-select';
import { ClockIcon } from 'assets/ComponentIcons/ClockIcon';
import { FaPlus } from 'react-icons/fa';
import { TagTile } from '../TagTile';
import WorkLoadModal from '../../../WorkloadModal';
import BaseLayoutContext from 'contexts/base-layout';
import ModalInformation from 'components/informationModal';
import EditIcon from 'assets/academicModeling/edit';
import { Form } from '@unform/web';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import { SelectAll } from '@material-ui/icons';

export const TodoTab = ({
  metrics,
  setMetrics,
  selectedDiscipline,
  setSelectedDiscipline,
}) => {
  const [progressPercent, setProgressPercent] = useState({ start: 0, end: 0 });
  const [disciplines, setDisciplines] = useState(null);
  const [disciplineDetails, setDisciplineDetails] = useState({});
  const [modalWorkloadOpen, setModalWorkloadOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [informationModalConfig, setInformationModalConfig] = useState({});
  const [tagName, setTagName] = useState({});
  const [tags, setTags] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const { selectedCourse, checkCurrentStep } = useAcademicModeling();
  const { openSnackbar } = useContext(BaseLayoutContext);
  const [optionsTag, setOptionsTag] = useState([]);
  const [hasBracketsError, setHasBracketsError] = useState(false);
  const [fromSelectOption, setFromSelectOption] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const formRef = useRef(null);

  const characterCountMessage = (charactersRemaining) => {
    if (charactersRemaining === 0) {
      return 'Limite de caracteres atingido';
    } else if (charactersRemaining === 1) {
      return `${charactersRemaining} caractere restante`;
    } else {
      return `${charactersRemaining} caracteres restantes`;
    }
  };

  useEffect(() => {
    function checkFormValid() {
      if (!inputValue?.length > 0 && tagName?.label?.length > 0) {
        setTagName({});
      }

      const hasBrackets = /\[|\]/.test(tagName?.label);
      setHasBracketsError(hasBrackets);

      setFormValid(tagName?.label?.length > 0 && !hasBrackets);
    }

    checkFormValid();
  }, [tagName, inputValue]);

  useEffect(() => {
    updateMetrics();
  }, []);

  async function updateMetrics() {
    const response = await AcademicModelingApi.getDisciplinesMetrics(
      selectedCourse.id
    );
    if (response.status === 200) {
      setProgressPercent((prevState) => ({
        start: prevState.end,
        end: response.data.percent,
      }));
      setMetrics(response.data);
    }
    return response.data;
  }

  useEffect(() => {
    async function fetchDisciplines() {
      const response = await AcademicModelingApi.getDisciplinesOptions(
        selectedCourse.id
      );
      if (response.status === 200) {
        setDisciplines(response.data);
      }
    }

    fetchDisciplines();
  }, [selectedCourse]);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#9FD6D6',
        primary50: '#80C9C8',
        primary: '#009291',
      },
    };
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Não há mais opções para selecionar</span>
      </components.NoOptionsMessage>
    );
  };

  async function handleChangeDiscipline(discipline) {
    setSelectedDiscipline(discipline);
  }

  useEffect(() => {
    async function onChangeDiscipline() {
      if (!selectedDiscipline) return;
      const responseMetrics = await AcademicModelingApi.getDisciplineDetails(
        selectedDiscipline.value
      );
      if (responseMetrics.status === 200) {
        setDisciplineDetails(responseMetrics.data);
        if (responseMetrics.data.workload === null) {
          setModalWorkloadOpen(true);
        }
      }
      const responseTags = await AcademicModelingApi.getTags(
        selectedDiscipline.value
      );
      if (responseTags.status === 200) {
        setTags(responseTags.data.results);
      }
    }

    onChangeDiscipline();
  }, [selectedDiscipline]);

  async function updateDisciplineDetails(disciplineId) {
    const response = await AcademicModelingApi.getDisciplineDetails(
      disciplineId
    );
    if (response.status === 200) {
      setDisciplineDetails(response.data);
    }
    return response.data;
  }

  async function handleSubmitTag() {
    formRef.current.reset();
    setInputValue('');
    setRequestInProgress(true);
    const replaceRegex = /\[[^\]]*\]/g;
    const tagNameWithReplace = tagName?.label.replace(replaceRegex, '');
    const tag = {
      name: tagNameWithReplace,
    };

    const response = await (fromSelectOption
      ? AcademicModelingApi.createTagBaseSelect(
          selectedDiscipline.value,
          tagName.value,
          tag
        )
      : AcademicModelingApi.createTag(selectedDiscipline.value, tag));

    if (response.status === 201) {
      setTagName('');
      setTags([
        ...tags,
        {
          ...response.data,
          ...(fromSelectOption ? { verifyId: tagName?.value } : {}),
        },
      ]);
      await updateDisciplineDetails(selectedDiscipline.value);

      if (tags.length === 0) {
        checkCurrentStep();
        const metricsResponse = await updateMetrics();
        if (metricsResponse.to_do === 0) {
          setInformationModalConfig({
            bubbleText: 'Parabéns!',
            ParagraphText: `O processo de classificação dos conteúdos das
  disciplinas da sua matriz curricular atual foi finalizado com sucesso!
  <br/></br> Você poderá editar as informações inseridas a qualquer momento.`,
            buttonConfirmText: 'OK, ENTENDI',
            buttonConfirmFunction: () => {
              setModalInformationOpen(false);
            },
            modalWidth: '368',
            modalHeight: '414',
          });
          setModalInformationOpen(true);
        }
      }
    } else {
      openSnackbar('Não foi possível criar a tag', true);
    }
    setRequestInProgress(false);
    if (fromSelectOption) {
      setFromSelectOption(false);
    }
  }

  const disciplineName = useMemo(() => {
    if (!selectedDiscipline) return '';
    return selectedDiscipline.label.split('- ')[1];
  }, [selectedDiscipline]);

  const modalWorkloadConfig = useMemo(() => {
    if (disciplineDetails?.workload) {
      return {
        bubbleText: 'Edite a carga horária',
        ParagraphText: `<p>Edite a carga horária total da disciplina
          <strong> ${disciplineName} </strong></p>`,
        modalHeight: '520',
        buttonConfirmText: 'SALVAR',
        onClose: () => {},
      };
    }
    return {
      bubbleText: 'Edite a carga horária',
      ParagraphText: `<p>Não identificamos a carga horária total da disciplina
          <strong>${disciplineName}</strong>.</p>
          <p>Para avançar forneça essa informação no campo abaixo:</p>`,
      modalHeight: '548',
      buttonConfirmText: 'SALVAR',
      onClose: () => setSelectedDiscipline(null),
    };
  }, [disciplineName, disciplineDetails]);

  async function handleSubmitWorkload(workload) {
    if (!workload) return;
    try {
      const hours = workload.split(':')[0];
      const responseData = await AcademicModelingApi.updateWorkload(
        selectedDiscipline.value,
        hours
      );
      if (responseData) {
        setDisciplineDetails({
          remain_workload: responseData.remain_workload,
          workload: workload,
        });
        setModalWorkloadOpen(false);
      }
    } catch (e) {
      openSnackbar(
        'Não foi possível salvar a carga horária da disciplina',
        true
      );
    }
  }

  function handleChangeTagName(e, fromCallback) {
    if (fromCallback) {
      if (e) {
        const mappedLabel = tags.map((item) => item.name.toUpperCase());
        if (!mappedLabel.includes(e.toUpperCase())) {
          setTagName({ label: e, value: e });
        }
      }
      return;
    }
    if (e?.value) {
      setTagName({ label: e.label.replace(/\[[^\]]*\]/g, ''), value: e.value });
      setFromSelectOption(true);
      return;
    }
    setTagName('');
  }

  async function deleteTag(tagId) {
    const responseDelete = await AcademicModelingApi.deleteTag(
      selectedDiscipline.value,
      tagId
    );
    if (responseDelete.status === 204) {
      const responseTags = await AcademicModelingApi.getTags(
        selectedDiscipline.value
      );
      if (responseTags.status === 200) {
        setTags(responseTags.data.results);
      }
      updateDisciplineDetails(selectedDiscipline.value);
      checkCurrentStep();
      updateMetrics();
    }
  }

  async function editTag(newTag) {
    if (newTag.name.length < 1) {
      openSnackbar(`Preencha o nome da tag`, 'error');
      return;
    }

    const response = await AcademicModelingApi.updateTag(
      selectedDiscipline.value,
      newTag
    );
    if (response.status === 200) {
      await updateDisciplineDetails(selectedDiscipline.value);
      const newTags = tags.map((element) => {
        if (element.id === newTag.id) {
          return {
            ...element,
            name: newTag.name,
          };
        }
        return element;
      });
      setTags(newTags);

      return response.data;
    }
  }

  const handleInputChange = (characterEntered, action) => {
    if (action === 'input-change') {
      setInputValue(characterEntered);
    }

    if (characterEntered.length === 0) {
      setOptionsTag([]);
      return;
    }

    if (characterEntered.length > 1) {
      handleChangeTagName(characterEntered, true);

      const manipulateResp = (resp) => {
        const { suggested_tags, suggested_tags_by_keywords } = resp;

        const mountedArray = [];

        const mappedIds = tags.map((item) => item.id);
        const mappedIdsWithVerify = tags.map((item) => item.verifyId);

        const filterSuggestedTags = suggested_tags?.filter(
          (item) =>
            !mappedIds.includes(item.value) &&
            !mappedIdsWithVerify.includes(item.value)
        );
        const filterSuggestedTagsByKeyword = suggested_tags_by_keywords?.filter(
          (item) =>
            !mappedIds.includes(item.value) &&
            !mappedIdsWithVerify.includes(item.value)
        );

        if (suggested_tags?.length > 0) {
          mountedArray.push({
            label: 'Tags sugeridas',
            options: filterSuggestedTags,
          });
        }

        if (suggested_tags_by_keywords?.length > 0) {
          mountedArray.push({
            label: 'Tags sugeridas por palavra chave',
            options: filterSuggestedTagsByKeyword,
          });
        }

        if (
          suggested_tags?.length === 0 &&
          suggested_tags_by_keywords?.length === 0
        ) {
          setOptionsTag([]);
          return;
        }

        setOptionsTag(mountedArray);
      };

      AcademicModelingApi.getTagsWithFilter(characterEntered).then((resp) =>
        manipulateResp(resp)
      );
    } else {
      setOptionsTag([]);
      setHasBracketsError(false);
    }
  };

  const formatLabel = (label, userInput) => {
    const result = label.split(/\[(.*?)\]/).map((part, index) => {
      if (index % 2 === 0) {
        return part.split('').map((char) => {
          if (char === ' ') {
            return ' ';
          }

          const isCharMatched = userInput
            .toLowerCase()
            .split('')
            .includes(char.toLowerCase());

          return isCharMatched ? (
            <b style={{ color: '#009291' }}>{char}</b>
          ) : (
            char
          );
        });
      } else {
        const result = part
          .toLowerCase()
          .split('')
          .map((char) => {
            const isTextMatched = userInput
              .toLowerCase()
              .split('')
              .includes(char.toLowerCase());
            return (
              <i
                style={
                  isTextMatched ? { color: '#009291', fontWeight: 600 } : null
                }
              >
                {char.toUpperCase()}
              </i>
            );
          });

        const formattedResult = (
          <>
            [<span>{result}</span>]
          </>
        );

        return formattedResult;
      }
    });
    return result;
  };

  const Option = (props) => {
    const userInput = props?.selectProps?.inputValue || '';
    const label = props?.data?.label || '';
    return (
      <components.Option {...props}>
        <div>
          {userInput?.length
            ? label?.split(' ').length
              ? formatLabel(label, userInput)
              : label
            : label}
        </div>
      </components.Option>
    );
  };

  return (
    <Container>
      <ProgressWrapper>
        <header>
          <strong>Disciplinas mapeadas</strong>
          {metrics && (
            <span>
              {metrics.finished}/{metrics.total} ({progressPercent.end}%)
            </span>
          )}
        </header>
        <ProgressBar>
          <Progress
            initialProgress={progressPercent.start}
            finalProgress={progressPercent.end}
          />
        </ProgressBar>
      </ProgressWrapper>
      <h5>Selecione a disciplina</h5>
      <h6>
        Escolha a disciplina para descrever as tags relacionadas aos
        <strong> principais conteúdos</strong>.
      </h6>
      <Select
        placeholder="Selecione"
        options={disciplines || []}
        theme={selectTheme}
        components={{ NoOptionsMessage }}
        value={selectedDiscipline}
        styles={selectStyles}
        onChange={handleChangeDiscipline}
        isClearable
      />
      {selectedDiscipline && (
        <Fragment>
          <WorkloadWrapper>
            <ClockIcon size={17} color={'#009291'} />
            <span>
              Carga horária total da disciplina -{' '}
              {disciplineDetails.workload?.replace('.', ':')}
            </span>
            <EditIcon
              color={'#009291'}
              onClick={() => {
                setModalWorkloadOpen(true);
              }}
            />
          </WorkloadWrapper>
          <h5>Nome da tag de conteúdo*</h5>
          <h6>
            Informe o nome da tag de conteúdo ou selecione uma das tags
            sugeridas.
          </h6>
          <div>
            <Form ref={formRef} onSubmit={() => {}}>
              <ContainerSelect>
                <div style={{ flex: 14 }}>
                  <UnformSelect
                    onInputChange={(inputValue, { action }) => {
                      const limitedInput = inputValue
                        .slice(0, 50)
                        .toUpperCase();
                      handleInputChange(limitedInput, action);

                      return limitedInput;
                    }}
                    onChange={(e) => {
                      handleChangeTagName(e);
                      setInputValue(e?.label?.replace(/\[[^\]]*\]/g, ''));
                    }}
                    name="tag"
                    options={optionsTag}
                    isClearable
                    components={{ Option }}
                    placeholder="Digite aqui..."
                    noOptionsMessage={() => null}
                    inputValue={inputValue}
                    value={inputValue}
                  />
                  <ContainerCharacterCount>
                    <p className="character-count">
                      {characterCountMessage(50 - (inputValue || '').length)}
                    </p>
                  </ContainerCharacterCount>
                  {hasBracketsError && (
                    <Error>Não é permitido o uso de colchetes</Error>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <p className="button-title">Adicionar</p>

                  <AddButton
                    type={'button'}
                    title={
                      !formValid
                        ? 'Preencha os campos para adicionar'
                        : 'Adicionar'
                    }
                    formValid={formValid}
                    disabled={
                      !formValid || requestInProgress || hasBracketsError
                    }
                    onClick={handleSubmitTag}
                  >
                    <FaPlus color={formValid ? '#FFF' : '#949494'} size={18} />
                  </AddButton>
                </div>
              </ContainerSelect>
            </Form>
          </div>
          <ListTags>
            {tags.map((tag) => (
              <TagTile
                key={tag.id}
                tag={tag}
                editTag={editTag}
                deleteTag={deleteTag}
              />
            ))}
          </ListTags>
        </Fragment>
      )}
      <WorkLoadModal
        open={modalWorkloadOpen}
        setOpen={setModalWorkloadOpen}
        config={modalWorkloadConfig}
        buttonConfirmFunction={handleSubmitWorkload}
        initialValue={disciplineDetails?.workload?.replace('.', ':') || ''}
      />
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={informationModalConfig}
        buttonConfirmFunction={informationModalConfig?.buttonConfirmFunction}
      />
    </Container>
  );
};
