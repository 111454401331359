import React, { useEffect, useMemo, useState } from 'react';
import ReactSelect, { components } from 'react-select';
import {
  ChipButton,
  InputLabel,
  LabelWrapper,
  SelectedContainer,
  SelectWrapper,
} from '../base/style';
import { CloseIcon } from 'assets/JobOffer/Close';

export default function MultiSelect({
  label,
  name,
  options,
  placeholder,
  isOptional,
  onChange,
  selectedOptions,
  setSelectedOptions,
  ...rest
}) {
  const [value, setValue] = useState(null);

  const unselectedOptions = useMemo(() => {
    return options?.filter(
      (option) => !selectedOptions.find((o) => o.value === option.value)
    );
  }, [options, selectedOptions]);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      maxHeight: '192px',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Não há mais opções para selecionar</span>
      </components.NoOptionsMessage>
    );
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: '#9FD6D6',
        primary50: '#80C9C8',
        primary: '#009291',
      },
    };
  }

  return (
    <SelectWrapper>
      {label && (
        <LabelWrapper>
          <InputLabel>{label}</InputLabel>
          {isOptional && <span> (OPCIONAL)</span>}
        </LabelWrapper>
      )}
      <ReactSelect
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={unselectedOptions}
        value={value}
        components={{ NoOptionsMessage }}
        classNamePrefix="react-select"
        styles={selectStyles}
        theme={selectTheme}
        onChange={(selected) => {
          if (selected && options) {
            setValue(null);
            const newSelectedOptions = [...selectedOptions, ...selected];
            if (onChange) onChange(newSelectedOptions);
            setSelectedOptions(newSelectedOptions);
          }
        }}
        {...rest}
      />
      {selectedOptions.length > 0 && (
        <SelectedContainer>
          {selectedOptions.map((item) => (
            <ChipButton
              key={item.value}
              onClick={() => {
                const newSelectedOptions = selectedOptions.filter(
                  (option) => option !== item
                );
                if (onChange) onChange(newSelectedOptions);
                setSelectedOptions(newSelectedOptions);
              }}
            >
              <p>{item.label}</p>
              <CloseIcon color={'#777777'} />
            </ChipButton>
          ))}
        </SelectedContainer>
      )}
    </SelectWrapper>
  );
}
