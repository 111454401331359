import {
  Container,
  FlagContainer,
  Item,
  FileButton,
  ExpandButton,
  Grid,
  ButtonGreen,
  ButtonGray,
  ConnectionStatus,
  CNPJ,
  ConnectionMade,
  CanvasButton,
  ButtonEdit,
} from './style';
import { useContext, useState, useEffect } from 'react';
import { useToggle } from '../../../Hooks/useToggle';
import { useIcons } from '../../../Hooks/useIcons';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import GenericModalForm from '../Modals/GenericModalForm';
import CompanyPreRegistrationForm from '../CompanyPreRegistrationForm';
import { getUpdateForm } from 'views/StaffPages/CompaniesManagement/Services';
import { AdapterPreRegistrationCompany } from 'views/StaffPages/CompaniesManagement/Utils/FormPreRegistrationAdapter';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';

export default function GenericAccordion(props) {
  const {
    children,
    data,
    funcSetSelectedCheckbox,
    stateSelectedCheckbox,
    textStatus,
    iconStatus,
    textTitle,
    flagStatus,
    contentSubtitle,
    iconSession,
    textSubtitle,
    secondCTA,
    labelSecondCTA,
    grayFlag,
    labelGrayFlag,
    checkbox,
    greenRightButton,
    greenRightButtonLabel,
    greenRightButtonFunc,
    grayRightButton,
    grayRightButtonLabel,
    grayRightButtonFunc,
    connectionStatus,
    buttonEdit,
    companyId,
    colorStatus,
    loadingButton,
    buttonId,
    setDocuments,
    getCountTabs,
    disableButton,
    setId,
    connectedCompanies,
  } = props;

  const {
    setUsingModalUpload,
    setFormData,
    setIdPostDocument,
    setDocumentFiles,
  } = useContext(CompaniesManagementContext);

  const [openModalFormEdit, setOpenModalFormEdit] = useState(false);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);

  const { expandPanel, handleExpandChange } = useToggle();
  const { IoIosArrowDown, BsFolder2Open, FaRegEdit, IoIosArrowUp } = useIcons();

  function toggleCompanies(data) {
    if (stateSelectedCheckbox.some((s) => s === data.id)) {
      funcSetSelectedCheckbox(
        stateSelectedCheckbox.filter((s) => s !== data.id)
      );
    } else {
      if (connectedCompanies) {
        setId(data.id);
      }
      funcSetSelectedCheckbox([...stateSelectedCheckbox, data.id]);
    }
  }

  function isCompaniesSelected(companyId) {
    return stateSelectedCheckbox.some((item) => item === companyId);
  }

  const handleEditModal = async (id) => {
    const data = await getUpdateForm(id);
    const dataAdapter = AdapterPreRegistrationCompany(data);
    setFormData(dataAdapter);
    setDocumentFiles(data?.documents);

    setOpenModalFormEdit(true);
  };

  const handleOpenModalUpload = (data) => {
    setIdPostDocument(companyId);
    setUsingModalUpload(true);
    setDocuments(data?.documents);
  };

  return (
    <Container>
      <Grid right={false}>
        {flagStatus && (
          <FlagContainer color={colorStatus}>
            <div className="status">
              {iconStatus(data)}
              {textStatus(data)}
            </div>
          </FlagContainer>
        )}
        <div className="align-title-check">
          {checkbox && (
            <input
              type={'checkbox'}
              id={`data${data.id}`}
              checked={isCompaniesSelected(data.id)}
              onChange={() => toggleCompanies(data)}
            />
          )}
          {textTitle && <Item htmlFor={`data${companyId}`}>{textTitle}</Item>}

          <ExpandButton onClick={handleExpandChange}>
            {expandPanel ? (
              <IoIosArrowUp size={20} color={'#606062'} />
            ) : (
              <IoIosArrowDown size={20} color={'#606062'} />
            )}
          </ExpandButton>
        </div>
        {contentSubtitle && expandPanel === true ? (
          <CNPJ checkbox={checkbox}>
            <strong> {textSubtitle} </strong> {contentSubtitle}
          </CNPJ>
        ) : null}

        {iconSession && iconSession(data)}

        {expandPanel === true && children}

        <CanvasButton>
          {secondCTA && (
            <FileButton
              expandPanel={expandPanel}
              onClick={() => handleOpenModalUpload(data)}
            >
              <BsFolder2Open color="#009291" size={16} />
              {labelSecondCTA}
            </FileButton>
          )}
          {grayFlag && (
            <ConnectionMade
              expandPanel={expandPanel}
              iconSession={iconSession}
              flagStatus={flagStatus}
            >
              {labelGrayFlag}
            </ConnectionMade>
          )}
        </CanvasButton>
      </Grid>
      <Grid right={true} expandPanel={expandPanel}>
        <div className="container-button">
          {grayRightButton && (
            <ButtonGray onClick={grayRightButtonFunc}>
              {grayRightButtonLabel}
            </ButtonGray>
          )}
          {greenRightButton && (
            <ButtonGreen
              onClick={greenRightButtonFunc}
              disabled={disableButton}
            >
              {loadingButton && buttonId === data.id ? (
                <Loading color={'#fff'} />
              ) : (
                greenRightButtonLabel
              )}
            </ButtonGreen>
          )}
          {connectionStatus && (
            <div className="connection-status-box">
              <ConnectionStatus>Conectada</ConnectionStatus>
              <p>
                você poderá se desconectar <br />
                da empresa na aba <br />
                “empresas conectadas”
              </p>
            </div>
          )}
        </div>
        {buttonEdit && (
          <ButtonEdit
            expandPanel={expandPanel}
            onClick={() => handleEditModal(companyId)}
          >
            <FaRegEdit color="#009291" size={20} />
          </ButtonEdit>
        )}
      </Grid>
      {openModalFormEdit && expandPanel && (
        <GenericModalForm
          openModal={openModalFormEdit}
          buttonCloseModal={false}
          zIndex={9999999}
          setOpenModalForm={setOpenModalFormEdit}
          usingPreviewPdf={usingPreviewPdf}
        >
          <CompanyPreRegistrationForm
            isEdit={true}
            getCountTabs={getCountTabs}
            companyId={companyId}
            setOpenModalFormEdit={setOpenModalFormEdit}
            setUsingPreviewPdf={setUsingPreviewPdf}
          />
        </GenericModalForm>
      )}
    </Container>
  );
}
