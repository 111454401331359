import React, { useState, useEffect } from 'react';
import Rating from 'react-rating';
import withStyles from '@material-ui/core/styles/withStyles';
import { object, func, string } from 'prop-types';

import scoreRatingStyle from 'assets/jss/material-dashboard-pro-react/components/scoreRatingStyle';

import { setCurriculumScore } from 'services/university/jobs';
import { setCompanyCurriculumScore } from 'services/company/jobs';
import { jobScoreAdapter } from 'adapters/job/jobScoreAdapter';

const ScoreRating = (props) => {
  const [student, setStudent] = useState([]);
  const [score, setScore] = useState(0);

  const { classes, studentData, role, updateCandidates } = props;

  useEffect(() => {
    setStudent(studentData);
    setScore(studentData.score);
  }, []);

  return (
    <div className={`${classes.curriculumMenu} ${classes.choicesMenu}`}>
      {!updateCandidates ? (
        <h5>Nível de alinhamento desse candidato à vaga:</h5>
      ) : (
        <h5>Indique o nível de alinhamento desse candidato à vaga:</h5>
      )}
      <div>
        <Rating
          placeholderRating={score}
          style={{
            marginRight: 22,
          }}
          onChange={async (score) => {
            if (updateCandidates) {
              if (role === 'university') {
                const response = await setCurriculumScore(jobScoreAdapter(student.id, score));
                setScore(response.score)
                updateCandidates(student, score);
              } else {
                const response = await setCompanyCurriculumScore(
                  jobScoreAdapter(student.id, score)
                );
                setScore(response.score)
                updateCandidates(student, score);
              }
            }
          }}
          readonly={!updateCandidates}
          emptySymbol={
            <span
              className="material-icons"
              style={{
                color: '#C5C5C5',
                marginTop: 2,
              }}
            >
              star_border
            </span>
          }
          placeholderSymbol={
            <span
              className="material-icons"
              style={{
                color: '#ffcc00',
                marginTop: 2,
              }}
            >
              star
            </span>
          }
          fullSymbol={
            <span
              className="material-icons"
              style={{
                color: '#ffcc00',
                marginTop: 2,
              }}
            >
              star
            </span>
          }
        />
      </div>
    </div>
  );
};

ScoreRating.propTypes = {
  role: string,
  classes: object,
  studentData: object,
  updateCandidates: func,
};

export default withStyles(scoreRatingStyle)(ScoreRating);
