import styled, { keyframes } from 'styled-components';

export const Logos = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 0px 32px 20px 0 !important;

  @media (max-width: 850px) {
    width: 100%;
    justify-content: center;
    margin: 34px 0 30px 0 !important;
    order: 2;
  }

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    margin: 10px 0 30px 0 !important;
    order: 2;
  }
`;

export const waveAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ContainerLogos = styled.div`
  width: ${({ boxSize }) => (boxSize ? boxSize : '56px')};
  height: ${({ boxSize }) => (boxSize ? boxSize : '56px')};
  background-color: #fff;
  border-radius: 10px;
  margin: 0 8px;

  background-color: ${(props) => props.color};
  animation: ${waveAnimation} 1.5s ease-in-out infinite;
  animation-delay: ${(props) => props.delay}s;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 850px) {
    width: 44px;
    height: 44px;
  }
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {transform: translateY(0)};
	40% {transform: translateY(-30px)};
	60% {transform: translateY(-15px)};
`;
