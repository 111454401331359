import moment from 'moment';
import {
  FaHourglass,
  FaRegCalendarCheck,
  FaRegCalendarXmark,
} from 'react-icons/fa6';
import { LuUserCheck } from 'react-icons/lu';

const verifyRegistrationDateLimit = (
  startDate,
  endDate,
  hasStudentLimit,
  studentLimit,
  registredStudents,
  tab
) => {
  const newStartDate = new Date(startDate + 'T00:00:00').getTime();

  const newEndDate = new Date(endDate + 'T23:59:59').getTime();
  const currentDate = new Date().getTime();

  if (currentDate < newStartDate) {
    return 'awatingStartRegistrationPeriod';
  }

  if (currentDate >= newStartDate && currentDate <= newEndDate) {
    if (hasStudentLimit && registredStudents >= studentLimit) {
      return 'AwatingEventStart';
    }
    return 'withinRegistrationPeriod';
  }

  return 'AwatingEventStart';
};

export const CardFormatHelper = {
  formatStatus: (item, tab) => {
    const staticStatus = {
      awatingStartRegistrationPeriod: {
        label: 'Aguardando início das inscrições',
        background: '#8D8D8D',
        icon: <FaHourglass />,
      },
      AwatingEventStart: {
        0: {
          label: 'Aguardando início do evento',
          background: '#B8B8B8',
          icon: <FaHourglass />,
        },
        1: {
          label: 'Inscrições encerradas',
          background: '#B8B8B8',
          icon: <FaRegCalendarXmark />,
        },
      },
      withinRegistrationPeriod: {
        0: {
          label: 'Dentro do período de inscrição',
          background: '#323232',
          icon: <LuUserCheck />,
        },
        1: {
          label: 'Incrições abertas',
          background: '#323232',
          icon: <FaRegCalendarCheck />,
        },
      },
    };

    const {
      registration_end_date,
      registration_start_date,
      limit_students,
      registred_students,
    } = item;
    const status = verifyRegistrationDateLimit(
      registration_start_date,
      registration_end_date,
      true,
      limit_students,
      registred_students
    );

    return staticStatus[status][tab] || staticStatus[status];
  },

  formatLabelDate: (item) => {
    const {
      registration_end_date,
      event_start_at,
      registration_start_date,
      limit_students,
      registred_students,
    } = item;
    const status = verifyRegistrationDateLimit(
      registration_start_date,
      registration_end_date,
      true,
      limit_students,
      registred_students
    );

    if (status === 'withinRegistrationPeriod') {
      return `Inscrições abertas até: ${moment(registration_end_date).format(
        'DD/MM/YYYY'
      )}`;
    }
    if (status === 'AwatingEventStart') {
      return `Início do evento: ${moment(event_start_at).format('DD/MM/YYYY')}`;
    }
    if (status === 'awatingStartRegistrationPeriod') {
      return `Início das inscrições: ${moment(registration_start_date).format(
        'DD/MM/YYYY'
      )}`;
    }
  },

  formatDate: (item) => {
    const { event_start_at, event_end_at } = item;
    return `De ${moment(event_start_at).format('DD/MM/YYYY')} a ${moment(
      event_end_at
    ).format('DD/MM/YYYY')}`;
  },

  formatQuantityStudents: (item) => {
    const { limit_students, registred_students } = item;

    if (limit_students > 0) {
      return `${registred_students.toLocaleString(
        'pt-BR'
      )}/${limit_students.toLocaleString('pt-BR')} ${
        limit_students === 1 ? 'estudante registrado' : 'estudantes registrados'
      }`;
    }

    return `${registred_students.toLocaleString('pt-BR')} ${
      registred_students === 1 ? 'estudante inscrito' : 'estudantes inscritos'
    }`;
  },
};
