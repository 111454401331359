import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
  100%{transform: rotate(1turn)}
`;

export const Spinner = styled.div`
  width: ${(props) => (props.size ? props.size : '25px')};
  height: ${(props) => (props.size ? props.size : '25px')};
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(
        farthest-side,
        ${(props) => (props.color ? props.color : '#a9a9a9')} 94%,
        #0000
      )
      top/ ${(props) => (props.thickness ? props.thickness : '5px')}
      ${(props) => (props.thickness ? props.thickness : '5px')} no-repeat,
    conic-gradient(
      #0000 30%,
      ${(props) => (props.color ? props.color : '#a9a9a9')}
    );
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - ${(props) => (props.thickness ? props.thickness : '5px')}),
    #000 0
  );
  animation: ${rotateAnimation} 1s infinite linear;
`;
