export function getAddressDetails(item) {
  const addressDetails = [
    item?.address,
    item?.address_number,
    item?.district,
    item?.city,
    item?.state,
    item?.zip_code,
  ];

  return addressDetails.filter((detail) => detail).join(', ');
}
