const cardAvatarStyle = (theme) => ({
  cardAvatar: {
    '&$cardAvatarProfile .avatar,&$cardAvatarTestimonial img': {
      width: 190,
      height: 190,
    },
  },
  cardAvatarProfile: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      top: 0,
      left: 0,
      paddingTop: 65,
      width: '100%',
      justifyContent: 'center',
      position: 'relative',
      margin: '0 auto',
    },
    display: 'flex',
    flexDirection: 'row',
    padding: '0',
    '&$cardAvatarPlain': {
      marginTop: '0',
    },
    top: 115,
    marginLeft: 66,
    width: 'auto',
    position: 'absolute',
    zIndex: 2,
  },
  cardAvatarPlain: {},
  cardAvatarTestimonial: {
    margin: '-50px auto 0',
    maxWidth: '100px',
    maxHeight: '100px',
    borderRadius: '50%',
    overflow: 'hidden',
    padding: '0',
    boxShadow:
      '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    '&$cardAvatarPlain': {
      marginTop: '0',
    },
  },
  cardAvatarTestimonialFooter: {
    marginBottom: '-50px',
    marginTop: '10px',
  },
});

export default cardAvatarStyle;
