import styled from 'styled-components';

export const PremiumTag = styled.span`
  border-radius: 40px;
  padding: 6px;
  background: linear-gradient(
    94.85deg,
    #fff5da 3.91%,
    #fbd12e 53.37%,
    #f47929 101.85%
  );
  font-size: 10px;
  font-weight: 500;
  color: #ab5501;
`;
