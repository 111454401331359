import React, { cloneElement, useContext } from 'react';

import { ButtonStyled } from './styled';
import { Themes } from './Themes';
import BaseLayoutContext from 'contexts/base-layout';

export default function Button({ children, icon, theme = 'light', ...rest }) {
  const { universityColor } = useContext(BaseLayoutContext);

  const getThemes = Themes(universityColor);

  const injectPropertiesInIcon = () => {
    if (icon) {
      return cloneElement(icon, { size: 18, color: getThemes[theme].color });
    }
  };

  return (
    <ButtonStyled
      {...rest}
      color={getThemes[theme].color}
      background={getThemes[theme].background}
      border={getThemes[theme].border}
      underline={getThemes[theme].underline}
    >
      {injectPropertiesInIcon()}
      {children}
    </ButtonStyled>
  );
}
