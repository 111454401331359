import { ContextProvider } from './context';
import InternalTab from './screens';
import { Container, WhiteBox } from './styled';

export function ScheduledTab({ currentTab }) {
  return (
    <ContextProvider>
      <Container>
        <WhiteBox>
          <InternalTab currentTab={currentTab} />
        </WhiteBox>
      </Container>
    </ContextProvider>
  );
}
