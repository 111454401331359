import styled from 'styled-components'

export const Content = styled.div`
  width: 100%;
  max-width: 605px;

  background-color: #fff;

  border-radius: 10px;

  padding: 45px 50px;
  margin-right: 18px;

  position: relative;
`

export const Title = styled.p`
  font-size: 20px;
  color: #009291;

  font-weight: 500;

  margin-bottom: 12px;
`

export const Subtitle = styled.p`
  font-size: 16px;
  font-weight: 400;

  color: #606062;
`

export const BgDocument = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #f8f8f8;

  border-radius: 4px;

  margin-top: 35px;

  :last-child {
    margin-top: 11px;
  }
  
  .button-icons {
    width: 100px;
    
    display: flex;
    justify-content: flex-end;

    margin-right: 20px;

    gap: 20px;
  }

  .button-icons button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .button-icons svg {
    flex: none;
  }
`

export const Document = styled.div`
  display: flex;
  align-items: center;

  padding: 13px;

  gap: 12px;

  .name-term {
    display: flex;
    flex-direction: column;
  }
`