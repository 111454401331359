import styled, { keyframes } from 'styled-components';
const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Information = styled.div`
  display: flex;
  height: 25px;
  width: 150px;
  border-radius: 5px;
  opacity: 0.8;
  margin-bottom: 8px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const InformationAvatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`

export const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 10px;
  height: 430px;
  width: 336px;
  align-items: center;
  border-radius: 8px;
`;

export const Container = styled.div`
  z-index: 1029;
  width: 100%;
  position: relative;
  display: flex;
  padding: 60px;
  gap: 20px;
`;

export const Divider = styled.div`
  border: 0.2px solid #e6e6e6;
  margin: 12px;
  width: 100%;
`;

export const ContainerEmails = styled.div`
  width: 100%;
`

export const Emails = styled.div`
  width: 489px;
  height: 65px;
  border-radius: 0px 10px 10px 10px;
  margin-top: 20px;
  position: relative;
  opacity: 0.8;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`

export const Mounth = styled.div`
  width: 200px;
  height: 50px;
  border-radius: 18px;
  opacity: 0.8;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`