import React, { Fragment } from 'react';
import { Container } from '../style';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';
import HeaderAcademicInnovation from '../../Header';
import WrapperRenderForm from '../RenderForm';

export default function WrapperFormToShow() {
  const { currentFormToShow } = useFormContext();

  return (
    <Fragment>
      <Container>
        {!currentFormToShow && <HeaderAcademicInnovation />}
        <WrapperRenderForm />
      </Container>
    </Fragment>
  );
}
