import React, { useContext } from 'react';

import { CertificateStatus } from './styled';
import BaseLayoutContext from 'contexts/base-layout';

export default function CardStatusCertificate({ status }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <CertificateStatus color={universityColor}>{status}</CertificateStatus>
  );
}
