import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';

import {
  WrapperMoreInfo,
  WrapperHeader,
  ImgHeader,
  WrapperContent,
  WrapperStatus,
  StarActivityImg,
  DivStar,
  Wrapper,
  WrapperStatusPublished,
  WrapperTitles,
  Title,
  Description,
  WrapperInformartions,
  UnpublishButton,
  ContainerButtons,
  Container,
  GridSide,
  ItemWrapper,
  BottomCards,
  DivButtonsFooter,
  DivDescription,
  ButtonX,
  WrapperBoxReaction,
  BoxReaction,
} from './styles';

import imageBackgroundCardChallenge from 'assets/img/imageBackgroundCardChallenge.png';
import Alvo from 'assets/Oportutinites/Alvo.svg';
import BigPhone from 'assets/Oportutinites/Group10.svg';
import GreenStar from 'assets/Oportutinites/GreenStar.svg';
import blackTrash from 'assets/Oportutinites/blackTrash.svg';
import GraduationCap from 'assets/Oportutinites/chapeu.svg';

import { RiCloseFill } from 'react-icons/ri';
import { HiOutlineCheck } from 'react-icons/hi';
import { BsCalendar4Week } from 'react-icons/bs';
import { AiOutlineLink, AiOutlineStar } from 'react-icons/ai';
import { FaCheckCircle, FaMinusCircle, FaUser } from 'react-icons/fa';

import InformationModal from '../InformationModal';
import ModalTargetAudience from '../ModalTargetAudiences';
import ConfirmDespublishChallenges from '../ConfirmDespublishChallenges';

import GenericModal from '../GenericModal';

import { unPublishChallenge } from '../../../services';

import { BiLike, BiDislike } from 'react-icons/bi';

export default function ModalMoreInformations(props) {
  const {
    isOpen,
    children,
    setModalOpen,
    setChallenge,
    item,
    setItem,
    getAllChallenges,
    ScrollToTopDiv,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [modalOpen, setOpen] = useState(false);
  const [modalSucess, setModalSucess] = useState(false);

  const [objectModalSucess, setObjectModalSucess] = useState({
    header: 'Tudo certo!',
    msg: 'Desafio despublicado',
    icon: 'success',
  });

  const modalRefOpen = useRef();
  const modalCenter = useRef();

  const returnImageStatus = (item) => {
    if (item.is_published === false) {
      return <FaMinusCircle size={14} color="#009291" />;
    }
    if (item.is_published === true && item.status === null) {
      return <FaCheckCircle size={14} color="#009291" />;
    }

    return <FaCheckCircle size={14} color="#009291" />;
  };

  const publicationFormat = {
    access_link: 'Link de acesso',
    video: 'Vídeos sequenciais',
    attraction_document: 'Arquivo PDF',
  };

  const closeModal = (e) => {
    if (modalCenter.current === e.target) {
      setModalOpen(false);
      setIsOpenModal(false);
      setOpen(false);
      setModalSucess(false);
    }
  };

  const ScrollPosition = () => {
    if (modalRefOpen) {
      modalRefOpen.current.scrollIntoView({ alignToTop: true });
    }
  };

  const setState = () => {
    if (setItem) {
      setItem([]);
    }
    setModalOpen(false);
  };

  useEffect(() => {
    if (isOpen === true) {
      ScrollPosition();
    }
  }, [isOpen]);

  const handleConfirm = async (item) => {
    setOpen(false);
    setModalSucess(true);
    setChallenge(null);
    await unPublishChallenge(item);
    getAllChallenges(`limit=${24}`);
    ScrollToTopDiv();
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <div className="box" ref={modalCenter} onClick={closeModal}>
        <WrapperMoreInfo ref={modalRefOpen} isOpen={isOpen}>
          <WrapperHeader>
            <ImgHeader
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = imageBackgroundCardChallenge;
              }}
              src={
                item.attraction_image
                  ? item.attraction_image
                  : imageBackgroundCardChallenge
              }
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'center',
                top: '20px',
                position: 'absolute',
                right: '10px',
                width: '100%',
              }}
            >
              <ButtonX onClick={setState}>
                <RiCloseFill size={25} />
              </ButtonX>
            </div>
            <GenericModal />
            {item && (
              <WrapperBoxReaction>
                <BoxReaction>
                  <BiLike size={48} color="#4B4B4D" />
                  <span>
                    {item.total_reaction && item.total_reaction.total_liked}
                  </span>
                </BoxReaction>
                <BoxReaction>
                  <BiDislike size={48} color="#4B4B4D" />
                  <span>
                    {item.total_reaction && item.total_reaction.total_disliked}
                  </span>
                </BoxReaction>
              </WrapperBoxReaction>
            )}
          </WrapperHeader>
          {!children && (
            <WrapperContent>
              {isOpenModal && (
                <ModalTargetAudience
                  setIsOpenModal={setIsOpenModal}
                  isOpenModal={isOpenModal}
                  item={item}
                  setItem={setItem}
                />
              )}

              <WrapperStatus>
                {item.validity && (
                  <Wrapper>
                    <DivStar>
                      <StarActivityImg src={GreenStar} />
                    </DivStar>
                    <p style={{ marginTop: '10px', marginLeft: '5px' }}>
                      {item.validity.label}
                    </p>
                  </Wrapper>
                )}

                <WrapperStatusPublished>
                  <i style={{ marginRight: '6px' }}>
                    {returnImageStatus(item)}
                  </i>
                  <p>{item.is_published ? 'Publicado' : 'Despublicado'}</p>
                </WrapperStatusPublished>
              </WrapperStatus>

              <InformationModal
                informationHeader={
                  'Tem certeza que deseja despublicar esse desafio?'
                }
                errorMsg={'Você não poderá recuperar a publicação'}
                type={'Information'}
                isOpen={modalOpen}
                setCloseModal={setModalOpen}
                getAllChallenges={getAllChallenges}
                setModalOpen={setOpen}
                children={
                  <ConfirmDespublishChallenges
                    functionConfirm={() => handleConfirm(item)}
                    functionCancel={() => setOpen(false)}
                  />
                }
              />

              <InformationModal
                informationHeader={objectModalSucess.header}
                errorMsg={objectModalSucess.msg}
                type={objectModalSucess.icon}
                isOpen={modalSucess}
                setModalOpen={setModalSucess}
                closeOtherModal={setState}
                setCloseModal={setModalOpen}
                getAllChallenges={getAllChallenges}
              />

              <WrapperTitles>
                <Title>{item.name}</Title>
                <DivDescription>
                  {item.description === '' || !item.description ? null : (
                    <Description>
                      {ReactHtmlParser(item.description)}
                    </Description>
                  )}
                </DivDescription>
              </WrapperTitles>

              <WrapperInformartions>
                <div style={{ display: 'flex' }}>
                  <GridSide>
                    <ItemWrapper>
                      <div className="container">
                        <img src={Alvo} alt="icon-alvo" />
                        <div className="text-wrapper">
                          <strong> Público-alvo </strong>
                          <button onClick={() => setIsOpenModal(true)}>
                            Clique para ver
                          </button>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <i>
                          <FaUser size={28} color="#009291" />
                        </i>
                        <div className="text-wrapper">
                          <strong> Responsável: </strong>
                          <p>
                            {' '}
                            {item.created_by
                              ? item.created_by.user
                              : 'Não identificado'}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    {item.type_access ? (
                      <ItemWrapper>
                        <div className="container">
                          <img src={BigPhone} alt="icon-altoFaltante" />
                          <div className="text-wrapper">
                            <strong> Tipo de publicação: </strong>
                            <p>
                              {publicationFormat[
                                item.type_access && item.type_access.value
                              ] || 'Não informado'}
                            </p>
                          </div>
                        </div>
                      </ItemWrapper>
                    ) : (
                      <ItemWrapper>
                        <div className="container">
                          <img src={BigPhone} alt="icon-altoFaltante" />
                          <div className="text-wrapper">
                            <strong> Tipo de publicação: </strong>
                            <p> Não informado</p>
                          </div>
                        </div>
                      </ItemWrapper>
                    )}
                  </GridSide>

                  <GridSide style={{ marginLeft: '40px' }}>
                    <ItemWrapper>
                      <div className="container">
                        <img src={GraduationCap} />
                        <div className="text-wrapper">
                          <strong> Postado por: </strong>
                          <p>
                            {' '}
                            {item.created_by
                              ? item.created_by.name
                              : 'Não identificado'}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div
                        className="container"
                        style={{ alignItems: 'center' }}
                      >
                        <i style={{ marginTop: '10px' }}>
                          <BsCalendar4Week size={30} color="#009291" />
                        </i>
                        <div className="text-wrapper">
                          <strong> Publicado em: </strong>
                          <p>
                            {' '}
                            {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>
                  </GridSide>
                </div>
              </WrapperInformartions>

              <DivButtonsFooter>
                <BottomCards>
                  <div className="cards">
                    <HiOutlineCheck size={22} color="#009291" />
                    <p>
                      {`Concluído por 
														${item.total_reaction && item.total_reaction.total_finished} 
														${
                              item.total_reaction &&
                              item.total_reaction.total_finished === 1
                                ? 'estudante'
                                : 'estudantes'
                            }`}
                    </p>
                  </div>

                  <div className="cards">
                    <AiOutlineStar size={22} color="#606062" />
                    <p>
                      {`Favoritado 
														${item.total_reaction && item.total_reaction.total_recommended} 
														${
                              item.total_reaction &&
                              item.total_reaction.total_recommended === 1
                                ? 'vez'
                                : 'vezes'
                            }`}
                    </p>
                  </div>

                  <div className="cards">
                    <AiOutlineLink size={22} color="#606062" />
                    <p>
                      {`
														${item.total_reaction && item.total_reaction.total_clicked} 
														${
                              item.total_reaction &&
                              item.total_reaction.total_clicked === 1
                                ? 'estudante direcionado ao link cadastrado'
                                : 'estudantes direcionados ao link cadastrado'
                            } 
													`}
                    </p>
                  </div>
                </BottomCards>

                {item.is_published && (
                  <ContainerButtons>
                    <UnpublishButton onClick={() => setOpen(true)}>
                      <img src={blackTrash} alt="icon-lixeira" />
                      DESPUBLICAR
                    </UnpublishButton>
                  </ContainerButtons>
                )}
              </DivButtonsFooter>
            </WrapperContent>
          )}
          {children && children}
        </WrapperMoreInfo>
      </div>
    </Container>
  ) : null;
}
