import React from 'react';

import { Information } from './styled';
import { node, string } from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

export default function CardInformation({
  icon,
  text,
  seeMore,
  onClickSeeMore,
}) {
  return (
    <Information>
      <div>{icon}</div> {text} <span onClick={onClickSeeMore}>{seeMore}</span>
    </Information>
  );
}

CardInformation.prototype = {
  icon: node,
  text: string,
};
