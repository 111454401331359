import EnrolledStudentsSkeleton from '../EnrolledStudentsSkeleton';
import EnrolledStudents from '../EnrolledStudents';
import { Fragment, useContext, useEffect } from 'react';
import { ExtensionProjectsContext } from '../../../../../../../Context/ExtensionProjectsContext';
import { ContainerDashed } from '../../../ModalStylingPattern/styles';

export function EnrolledStudentsList(props) {
  const {
    ordering,
    status,
    limitPerPage,
    page,
    getStudents,
    students,
    requestInProgress,
    showStatus = true,
    setScore,
    finalization = false,
  } = props;
  const offset = (page - 1) * limitPerPage;
  const { modalEnrolledStudents } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    getStudents({ limitPerPage, offset, ordering });
  }, [modalEnrolledStudents]);

  const renderItems = () => {
    if (students.length === 0) {
      return (
        <ContainerDashed>
          <p>
            Não é possível finalizar o projeto 
            sem estudantes inscritos.
          </p>
        </ContainerDashed>
      );
    }

    if (requestInProgress === true) {
      return Array(8)
        .fill(1)
        .map((_, index) => <EnrolledStudentsSkeleton />);
    }

    if (students.length > 0) {
      return students.map((registeredStudent) => (
        <EnrolledStudents
          registeredStudent={registeredStudent}
          getStudents={getStudents}
          limitPerPage={limitPerPage}
          offset={offset}
          status={status}
          showStatus={showStatus}
          setScore={setScore}
          finalization={finalization}
        />
      ));
    }
  };

  return <Fragment>{renderItems()}</Fragment>;
}
