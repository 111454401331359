import styled from "styled-components";

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;

  span {
    text-align: start;
    font-size: 14px !important;
  }
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
  text-align: start;
`;

export const UnInput = styled.input`
  border: 1px solid #d0d0d0;
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  &:focus {
    border: 2px solid #2784ff;
  }
`;