import styled from 'styled-components';
import GridContainer from 'components/Grid/GridContainer';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate/index.jsx';
import { Container as CmContainer } from 'components/CurriculumModalGenerate/styled';

export const Content = styled.div`
  width: 100%;
  min-height: 400px;

  border-radius: 8px;
  background-color: #ffffff;
`;

export const Container = styled(GridContainer)`
  margin-top: 0px !important;
`;
