export const names = {
  students_on_the_platform: 'Na base de dados do sistema',
  active_students_on_the_platform: 'Ativos no sistema',
  with_curriculum: 'Com currículo',
  no_with_curriculum: 'Sem currículo',
  working: 'Trabalhando',
  not_working: 'Não estão trabalhando',
  registered: 'Cadastradas',
  in_progress: 'Em andamento',
  finished: 'Finalizadas',
  applications: 'Total de candidaturas',
  companies_connected: 'Empresas Conectadas',
  companies_disconnected: 'Empresas Desconectadas',
};
