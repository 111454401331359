import React, { createContext, useContext, useEffect, useState } from 'react';
import { TalentsTab } from '../components/Tabs/TalentsTab';
import * as talentApiSerive from '../services/talents.service';
import BaseLayoutContext from 'contexts/base-layout';
import { talentsFilterService } from '../services/filters.service';
import { getStudentCurriculumData } from 'services/company/curriculum';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate/index.jsx';
import { IndicationsTab } from '../components/Tabs/IndicationsTab';
import { formatNumber } from 'utils/formatNumber';

export const TalentsContext = createContext();

export default function TalentsListProvider({
  children,
  handleIsLoadingState,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [talents, setTalents] = useState([]);
  const [talentsData, setTalentsData] = useState({});
  const [indications, setIndications] = useState([]);
  const [indicationsData, setIndicationsData] = useState({});
  const { openSnackbar } = useContext(BaseLayoutContext);
  const [loadingTalents, setLoadingTalents] = useState(false);
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const [talentsTabLimit, setTalentsTabLimit] = useState(20);
  const [indicationsTabLimit, setIndicationsTabLimit] = useState(20);
  const [tabRef, setTabRef] = useState(null);
  const [talentFilterState, setTalentFilterState] = useState({});
  const [indicationFilterState, setIndicationFilterState] = useState({});
  const [quickFilters, setQuickFilters] = useState({
    active: false,
    inactive: false,
  });

  const tabs = [
    {
      label: `Talentos (${
        talentsData?.count ? formatNumber(talentsData.count) : 0
      })`,
      component: <TalentsTab />,
    },
    {
      label: `Indicados (${
        indicationsData?.count ? formatNumber(indicationsData.count) : 0
      })`,
      component: <IndicationsTab />,
    },
  ];

  useEffect(() => {
    (async () => {
      await getHeadFilters();
      handleIsLoadingState(false);
    })();
  }, []);

  async function getTalents(filters = '', offset = 0, limit) {
    toTop();
    try {
      if (limit) {
        setTalentsTabLimit(limit);
      }
      setLoadingTalents(true);
      const dataTalents = await talentApiSerive.getTalents(
        filters,
        offset,
        limit ?? talentsTabLimit
      );
      setTalents(dataTalents.data.results);
      setTalentsData(dataTalents.data);
    } catch (e) {
      openSnackbar('Não foi possivel carregar os estudantes', true);
    } finally {
      setLoadingTalents(false);
      toTop();
    }
  }

  async function getIndications(filters = '', offset = 0, limit) {
    toTop();
    try {
      if (limit) {
        setTalentsTabLimit(limit);
      }
      setLoadingTalents(true);
      const dataIndication = await talentApiSerive.getIndications(
        filters,
        offset,
        limit ?? indicationsTabLimit
      );
      setIndications(dataIndication.data.results);
      setIndicationsData(dataIndication.data);
    } catch (e) {
      openSnackbar('Não foi possivel carregar os estudantes', true);
    } finally {
      setLoadingTalents(false);
      toTop();
    }
  }

  async function updateIndicationsCount(filters = '', offset = 0, limit) {
    try {
      setLoadingTalents(true);
      const dataIndication = await talentApiSerive.getIndications(
        filters,
        offset,
        limit ?? indicationsTabLimit
      );
      setIndicationsData({...indicationsData, count: dataIndication.data.count});
    } catch (e) {
      openSnackbar('Não foi possivel atualizar a contagem de indicações', true);
    } finally {
      setLoadingTalents(false);
    }
  }

  const toTop = () =>
    setTimeout(() => {
      tabRef?.current?.scrollIntoView();
    }, 500);

  async function getHeadFilters() {
    try {
      const response = await talentApiSerive.getHeadFilters();
      talentsFilterService.setOptionsFilters(response.data);
    } catch (e) {
      openSnackbar('Não foi possivel carregar os estudantes', true);
    }
  }

  function clearTalentsList() {
    setTalents([]);
    setTalentsData({});
  }

  async function openCurriculum(talent) {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(talent?.id);
    if (!response.message) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }

  /**
   *
   * @param {'active'| 'inactive'} filter
   */
  async function useQuickFilters(filter) {
    const currentActive = quickFilters.active;
    const currentInactive = quickFilters.inactive;

    let newActive = false;
    let newInactive = false;

    if (filter === 'active') {
      if (currentActive) {
        newActive = false;
        newInactive = false;
      } else {
        newActive = true;
      }
    } else if (filter === 'inactive') {
      if (currentInactive) {
        newInactive = false;
        newActive = false;
      } else {
        newInactive = true;
      }
    }

    setQuickFilters({ active: newActive, inactive: newInactive });

    let isActive;
    if (newActive) {
      isActive = true;
    } else if (newInactive) {
      isActive = false;
    } else {
      isActive = undefined;
    }

    const params = talentsFilterService.getUrlParamsFilters({
      ...talentFilterState,
      is_active: isActive,
    });

    getTalents(params);
  }

  return (
    <TalentsContext.Provider
      value={{
        activeTab,
        tabs,
        talents,
        talentsData,
        getTalents,
        getIndications,
        indications,
        indicationsData,
        setActiveTab,
        getHeadFilters,
        clearTalentsList,
        loadingTalents,
        openCurriculum,
        setTalentsTabLimit,
        talentsTabLimit,
        setIndicationsTabLimit,
        indicationsTabLimit,
        setTabRef,
        tabRef,
        talentFilterState,
        setTalentFilterState,
        indicationFilterState,
        setIndicationFilterState,
        useQuickFilters,
        quickFilters,
        setQuickFilters,
        updateIndicationsCount
      }}
    >
      {children}
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          hasIndication={false}
          showActions={false}
          showInterviewButton={false}
        />
      )}
    </TalentsContext.Provider>
  );
}
