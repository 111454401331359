import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 676px;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
  min-height: 250px;
  max-height: 700px;
  height: auto;
`;

export const Header = styled.div`
  background-color: #009291;
  height: 90px;
  width: 100%;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ContainerImageAndInformations = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 30px 50px;

  > img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  > div {
    > h4 {
      margin: 0;
      color: #606062;
      font-weight: bold;
    }

    > p {
      margin: 0;

      > span {
        font-weight: bold;
        color: #009291;
        margin-left: 5px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;
