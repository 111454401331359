import React, { useEffect, useRef, useState } from 'react';

import { ModalBackground, ModalContainer, ModalX, Header } from './styled';

export default function GenericModal() {

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [optionsId, setOptionsId] = useState('');
  const [modalContent, setModalContent] = useState();

  const [formData, setFormData] = useState({});
  const [selectOption, setSelectOption] = useState('');

  const topModal = useRef(null);

  useEffect(() => {
    if (modalIsOpen === false) {
      setFormData({});
      setSelectOption('');
      setOptionsId('');
      setStep(0);
    }
  }, [modalIsOpen]);

  const ScrollToModalTop = () => {
    if (topModal.current) {
      topModal.current.scrollIntoView({ block: 'start', inline: 'nearest' });
    } else {
      return;
    }
  };

  useEffect(() => {
    ScrollToModalTop();
  }, [step]);

  return (
    <ModalBackground isOpen={modalIsOpen}>
      <ModalContainer>
        <Header>
          <ModalX ref={topModal} onClick={() => setModalIsOpen(false)} />
        </Header>
        {modalContent}
      </ModalContainer>
    </ModalBackground>
  );
}
