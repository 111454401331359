import React, { useEffect, useRef, useState } from 'react';
import MyProfileModalStepTwo from './Steps/stepTwo';
import MyProfileModalStepThree from './Steps/StepThree';
import { getFormOptions, getCompanyLogged } from 'services/companies/';

import StepIndicator from 'components/StepIndicator';
import MyProfileModalStepOne from './Steps/StepOne';
import { ModalContent, ModalOverlay, CloseButton } from './styled';
import { IoIosClose } from 'react-icons/io';
import ModalInformation from 'components/informationModal';
import { updateCompany, uploadLogo } from 'services/companies';

export const MyProfileModal = ({ closeModal }) => {
  const [companyOptions, setCompanyOptions] = useState(null);
  const [companyData, setCompanyData] = useState(null);

  const [
    showInformationModalMyProfile,
    setShowInformationModalMyProfile,
  ] = useState(false);

  const [step, setStep] = useState(1);
  const [stepsData, setStepsData] = useState({});

  const refCompanyColor = useRef({});
  const refCompanyLogo = useRef({});

  const formRefStepOne = useRef(null);
  const formRefStepTwo = useRef(null);
  const formRefStepThree = useRef(null);

  const handleNext = (data) => {
    setStepsData((prevs) => ({ ...prevs, ...data }));
    setStep((prevs) => prevs + 1);
  };

  const handlePrevs = () => {
    setStep((prevs) => prevs - 1);
  };

  const steps = {
    1: (
      <MyProfileModalStepOne
        data={companyData}
        options={companyOptions}
        handleNext={handleNext}
        stepsData={stepsData}
        formRef={formRefStepOne}
        refCompanyColor={refCompanyColor}
        refCompanyLogo={refCompanyLogo}
      />
    ),
    2: (
      <MyProfileModalStepTwo
        data={companyData}
        options={companyOptions}
        handleNext={handleNext}
        handlePrevs={handlePrevs}
        stepsData={stepsData}
        formRef={formRefStepTwo}
      />
    ),
    3: (
      <MyProfileModalStepThree
        data={companyData}
        options={companyOptions}
        stepsData={stepsData}
        handlePrevs={handlePrevs}
        formRef={formRefStepThree}
        closeModal={closeModal}
      />
    ),
  };

  useEffect(() => {
    async function fetchData() {
      const options = await getFormOptions();
      const companyInfo = await getCompanyLogged();
      setCompanyOptions(options);
      setCompanyData(companyInfo);
    }
    fetchData();
  }, []);

  const handleClickClose = () => {
    setShowInformationModalMyProfile(true);
  };

  const isEmpty = (value) => {
    if (value == null) return true;
    if (typeof value === 'string' && value.trim() === '') return true;
    if (Array.isArray(value) && value.length === 0) return true;
    if (
      typeof value === 'object' &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0
    )
      return true;
    return false;
  };

  const cleanObject = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => !isEmpty(value))
    );
  };

  const handleClickSave = async () => {
    const dataStepTwo = formRefStepTwo?.current?.getData();
    const dataStepThree = formRefStepThree?.current?.getData();
    let fullData = {
      company_color: refCompanyColor?.current?.value,
      logo: refCompanyLogo?.current?.value,
      ...dataStepTwo,
      ...dataStepThree,
      ...stepsData,
    };
    fullData = cleanObject(fullData);

    await updateCompany(fullData);

    if (refCompanyLogo?.current?.value) {
      await uploadLogo(refCompanyLogo?.current?.value);
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalInformation
          modalOpenState={showInformationModalMyProfile}
          closeModalFunction={() => setShowInformationModalMyProfile(false)}
          config={{
            ParagraphText:
              'Tem certeza de que deseja sair sem finalizar o seu perfil que será destacado para os candidatos?',
            buttonConfirmText: 'CONTINUAR EDITANDO',
            buttonCancelText: 'SALVAR INFORMAÇÕES E SAIR',
            bubbleText: 'Atenção',
            showButtonCancel: true,
            buttonCloseX: false,
            buttonCancelWidth: 290,
            zIndex: 999999,
            buttons: {
              flexDirection: 'column-reverse',
            },
          }}
          buttonConfirmFunction={() => {
            setShowInformationModalMyProfile(false);
          }}
          buttonCancelFunction={() => {
            setShowInformationModalMyProfile(false);
            handleClickSave();

            closeModal();
          }}
        />
        <CloseButton onClick={handleClickClose}>
          <IoIosClose size={30} />
        </CloseButton>

        <div>
          <StepIndicator currentStep={step} count={3} />
        </div>

        {steps[step]}
      </ModalContent>
    </ModalOverlay>
  );
};
