import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

export const StyledButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  margin-right: ${({ isContent }) =>
    isContent ? '3rem !important' : '1rem !important'};
  svg {
    fill: #999999;
  }
`;
