import WifiIcon from 'assets/Oportutinites/Icons/WifiIcon';
import UserCircleIcon from 'assets/Oportutinites/Icons/UserCircleIcon';
import UserIconDefault from 'assets/Oportutinites/Icons/UserIconDefault';
import GraduationCap from 'assets/Oportutinites/Icons/GraduationCap';
import ProgramIcon from 'assets/Oportutinites/Icons/ProgramIcon';
import DeletedIcon from 'assets/Oportutinites/Icons/DeletedIcon';
import PublishedIcon from 'assets/Oportutinites/Icons/PublishedIcon';

import { IoCloseCircleSharp } from 'react-icons/io5';
import { AiOutlineLink, AiOutlineStar } from 'react-icons/ai';
import { BiLike, BiDislike } from 'react-icons/bi';

export const useIcons = () => {
  return {
    WifiIcon,
    UserCircleIcon,
    UserIconDefault,
    GraduationCap,
    ProgramIcon,
    DeletedIcon,
    PublishedIcon,
    IoCloseCircleSharp,
    AiOutlineLink,
    AiOutlineStar,
    BiLike,
    BiDislike,
  };
};
