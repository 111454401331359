import styled, { keyframes } from 'styled-components';
import white from 'assets/img/icons/icoLocation.png';

export const Container = styled.div`
  padding: 60px;
  position: relative;

  @media (max-width: 959px) {
    padding: 20px;
  }

  @media (max-width: 320px) {
    padding: ${(props) => (props.selfDevelopment ? '6px' : '20px')};
  }
`;

const arrowUp = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(2px); }
  50% { transform: translateY(-3px); }
  100% { transform: translateY(0px); }
`;

export const ScrollUp = styled.button`
  width: 50px;
  height: 50px;

  border-radius: 200px;

  background-color: #ccc;

  position: fixed;
  bottom: 110px;
  right: 25px;

  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1300;

  svg {
    animation: ${arrowUp} 1.6s linear infinite;
    transition: 1s;
  }

  @media (max-width: 425px) {
    width: 45px;
    height: 45px;
    right: 8px;
  }
`;

export const Title = styled.p`
  font-size: 22px;
  color: #009291;
  font-weight: 700;
  padding-bottom: 13px;

  @media (max-width: 375px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
    text-align: center;
    ${(props) => props.setTitle && setTitle(true)}
  }
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 8.90097px;

  border-top-left-radius: ${(props) => props.internalWhiteBox && 'unset'};
  border-top-right-radius: ${(props) => props.internalWhiteBox && 'unset'};

  margin-top: ${(props) => (props.internalWhiteBox ? 'unset' : '33px')};

  position: relative;

  .container-pagination {
    display: none;
  }

  @media (max-width: 1724px) {
    .container-pagination {
      display: flex;
      padding: 20px;
      padding-top: 0;
    }

    .container-pagination div {
      margin: 0;
    }
  }

  @media (max-width: 320px) {
    padding: 2px;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  right: 0.8em;
  height: 100%;

  position: absolute;

  z-index: 1290;

  @media (max-width: 486px) {
    display: none;
  }
`;

export const FilterReponsiveContainer = styled.div`
  display: none;

  @media (max-width: 486px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding-right: ${(props) => (props.padRight ? '128px' : '100px')};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;
  padding-top: 8px;
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #efefef;
`;

export const ListArea = styled.div`
  width: 100%;
  height: 100%;
  padding: 29px;
  background-color: #fff;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-wrap: wrap;
  gap: 21px;

  @media (max-width: 1420px) {
    justify-content: center;
    gap: 7px;
  }

  @media (max-width: 1363px) {
    justify-content: center;
    gap: 63px;
  }

  @media (max-width: 768px) {
    gap: 21px;
  }

  @media (max-width: 727px) {
    gap: 18px;
  }
`;

export const CardDivider = styled.div`
  height: 1px;
  margin: 12px ${(props) => props.marginOnTheSide};
  background-color: #e5e5e5;
`;

export const AdditionalHoursFlag = styled.div`
  width: fit-content;

  padding: 3px 7px;
  background-color: ${(props) => (props.card ? '#fff' : props.universityColor)};
  border-radius: 12px;
  display: flex;
  align-items: center;

  gap: 7px;
  margin-bottom: 24px;

  bottom: -17px;
  left: 10px;
  position: ${(props) => props.card && 'absolute'};
  z-index: 2;

  p {
    color: ${(props) => (props.card ? props.universityColor : '#fff')};
    font-size: 11px;
    font-weight: 500;
    margin: 0;
  }

  .circle {
    width: 15px;
    height: 15px;
    background-color: ${(props) =>
      props.card ? props.universityColor : '#fff'};
    border-radius: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Sentinel = styled.li`
  list-style-image: url(${white});
  width: 3px;
  height: 100%;
  margin-top: -20px;
`;
