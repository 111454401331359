import { JobDetailButton } from 'views/StaffPages/jobOpeningsRebuild/components/JobDetails';
import { JobActions, JobItem, JobDetails } from '../style';
import { translateLocation } from '../helpers';
import {
  ContainerIconAndText,
  ContentIcon,
  TextParagraph,
} from 'components/JobDetailsModal/styles';
import { IconLocation } from 'assets/img/iconsJobOffers/iconLocation';
import { SlBriefcase } from 'react-icons/sl';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import Building from 'assets/FairAndEvents/Building';
import { useRef } from 'react';

export default function Card({ vacancy, onOpenJobDetails, closeFunction }) {
  const buttonRef = useRef(null);

  const handleClickCard = () => {
    buttonRef?.current?.click();
  };

  return (
    <JobItem key={vacancy.id} onClick={handleClickCard}>
      <JobDetails>
        <h3>
          <span className="vacancy-id"> [{vacancy.id}]</span> {vacancy.name}
        </h3>
        <ContainerIconAndText>
          <ContentIcon universityColor="#009291">
            <SlBriefcase color="#009291" size={20} />
          </ContentIcon>
          <TextParagraph>
            {vacancy?.application_type?.[0]?.label}
            {vacancy?.application_type?.length > 1 && (
              <span className="application-type-length">
                +{vacancy?.application_type?.length - 1}
              </span>
            )}
          </TextParagraph>
        </ContainerIconAndText>
        <ContainerIconAndText>
          <ContentIcon universityColor="#009291">
            <Building color="#009291" size={20} />
          </ContentIcon>
          <TextParagraph>{vacancy?.modality_type?.label}</TextParagraph>
        </ContainerIconAndText>
        {vacancy?.locations?.length > 0 && (
          <ContainerIconAndText>
            <ContentIcon universityColor="#009291">
              <HiOutlineLocationMarker color="#009291" size={20} />
            </ContentIcon>
            <TextParagraph>
              {vacancy?.locations?.[0]?.label}
              {vacancy?.locations?.length > 1 && (
                <span className="application-type-length">
                  +{vacancy?.locations?.length - 1}
                </span>
              )}
            </TextParagraph>
          </ContainerIconAndText>
        )}
      </JobDetails>
      <JobActions>
        <JobDetailButton
          isFromPartners={true}
          canIndication={true}
          jobID={vacancy.id}
          fullWidth={true}
          buttonFunction={onOpenJobDetails}
          closeFunction={closeFunction}
          buttonRef={buttonRef}
        >
          <span className="span-button">Visualizar detalhes</span>
        </JobDetailButton>
      </JobActions>
    </JobItem>
  );
}
