export const liveQuizAdapter = (liveId, universitySlug, studentId, answers) => {
  const payload = {
    answered: true,
    live_id: liveId,
    student_id: studentId,
    university_slug: universitySlug,
    answers
  };

  return payload;
};
