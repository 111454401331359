import React from "react";
import { func, object, number } from "prop-types";

const Nav = props => {
  const { classes, checkForm, openSnackbar } = props;
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        className={`${classes.dot} ${isActive ? classes.active : ""}`}
        onClick={async () =>
          (await checkForm())
            ? openSnackbar("Preencha todos os campos obrigatórios.", true)
            : props.goToStep(i)
        }
      >
        {i}
      </span>
    );
  }

  return <div className={classes.paginationBulletsHolder}>{dots}</div>;
};

Nav.propTypes = {
  classes: object,
  checkForm: func,
  totalSteps: number,
  currentStep: number,
  goToStep: func,
  openSnackbar: func
};

export default Nav;
