import styled from 'styled-components';

export const FilterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const ContainerFilters = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

export const SpanError = styled.p`
  font-size: 12px;
  text-align: start;
  right: 0;
  color: #f00;
  margin: 0;
`;

export const Grid = styled.p`
  display: flex;
  gap: 1rem;
  @media (max-width: 475px) {
    flex-direction: column;
  }
`;
