import styled from 'styled-components';
import X from 'assets/Oportutinites/X.svg';

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.25);
`;
export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  background-color: #ffff;
  width: 577px;
  height: 420px;
  border-radius: 8px;
  position: absolute;
  padding: 16px 29px;
  top: 60px;
  bottom: 20px;
  z-index: 2000;
  display: flex;
  flex-direction: column;
`;
export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
`;
export const Title = styled.h4`
  color: #009291;
  font-size: 18px;
  font-weight: bold;
`;
export const SubTitle = styled.h4`
  color: #009291;
  font-size: 12px;
  font-weight: bold;
  margin-top: -10px;
`;

export const DividerTitle = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  line-height: 0;
`;
export const DivAssociation = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  width: 100%;
  height: auto;
  padding: 10px 10px 0 10px;
`;
export const TitleAssociation = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin: 20px 0 20px;
  text-align: left;
  color: #c4c4c4;
`;
export const ListAssociations = styled.p`
  width: 100%;
  display: flex;
  border-bottom: 2px solid #eef2f4;

  .list-name {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #777777;
  }
`;

export const ContainerPagination = styled.div`
  z-index: 999999;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
`;

export const CloseButton = styled.span`
  background-color: #f0f0f0;
  width: 30px !important;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
