import React from "react";
import { object, string } from "prop-types";
import { Grid } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import learningTrailsStyle from "assets/jss/material-dashboard-pro-react/views/learningTrailsStyle";

const WhatIs = props => {
  const { classes, data } = props;
  return (
    <Grid
      container
      justify="center"
      className={classes.fullScreenGridContainer}
    >
      <Grid item xs md={8} sm={12} className={classes.learningTrails}>
        <h2 className={classes.cardSubtitle}>{data.stepTitle || "O que é?"}</h2>
        {data.description && (
          <p className={classes.cardSubtitleDescription}>{data.description}</p>
        )}

        <Grid
          container
          justify="center"
          className={(classes.cardVideo, classes.blockMargin)}
        >
          <iframe
            className={classes.videos}
            src={data.videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
        {data.cite && (
          <div className={classes.citeGroup}>
            {data.cite.map((cite, key) => (
              <p key={key}>
                <cite className={classes.cardCite}>{`"${
                  cite.description
                }"`}</cite>
                <span className={classes.cardAuthor}>{cite.author}</span>
              </p>
            ))}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

WhatIs.propTypes = {
  classes: object,
  data: object,
  colorScheme: string
};

export default withStyles(learningTrailsStyle)(WhatIs);
