import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Filters,
  WhiteBox,
  Container,
  OrderFilter,
  StyledSelect,
  SearchFilter,
} from '../styled';
import GenericMessage from 'components/GenericMessage';
import ModalInformation from 'components/informationModal';
import { StudentCard } from '../../../components/StudentCard';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';
import { useHistoricMentorshipContext } from '../../../context';
import MentoringDoneModal from '../../../components/MentoringDoneModal';
import MentoringNotDoneModal from '../../../components/MentoringNotDoneModal';
import SkeletonCardMentorship from 'views/StaffPages/Mentorship/components/SkeletonCard';
import { MentorshipListContainer } from '../../../../NextMentorshipsTab/screens/Tabs/styled';
import { SeeMoreButton } from '../../../../MentorsTab/styled';
import AuthContext from 'contexts/authentication';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { SearchInputWithDebounce } from 'components/SearchInputWithDebounce';
import DetailsModal from 'views/StaffPages/Mentorship/components/DetailsModal';

export function ExpiredTab({
  isActiveExpiredTab,
  fetchMentorshipsCount,
  updateMentorshipsCount,
  setMentorshipCounts,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrderOption, setSelectedOrderOption] = useState({
    value: '-date',
    label: 'Mais recentes para os mais antigos',
  });
  const [mentoringNotDoneModalOpen, setMentoringNotDoneModalOpen] = useState(
    false
  );
  const [openModalAdjustStatus, setOpenModalAdjustStatus] = useState(false);
  const [mentoringDoneModalOpen, setMentoringDoneModalOpen] = useState(false);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = !['teacher', 'course_coordinator'].includes(
    staffRole
  );
  const {
    page,
    setPage,
    hasNextPage,
    setHasNextPage,
    modalData,
    setModalData,
    detailsModalOpen,
    openDetailsModal,
    closeDetailsModal,
    mentorships,
    setMentorships,
    loading,
    setLoading,
    appliedFilters,
    setAppliedFilters,
    filterState,
    setFilterState,
  } = useHistoricMentorshipContext();

  async function fetchExpiredMentorships(
    reset = false,
    order = selectedOrderOption.value
  ) {
    setLoading(true);
    const offset = reset ? 0 : (page - 1) * 20;
    const queryString = queryParamsFromFilterObject(appliedFilters);
    let queryParams = `limit=20&offset=${offset}&ordering=${order}`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    const response = await mentorshipApi.getExpiredMentorshipsList({
      queryParams,
    });
    const updatedMentorships = mentorships && !reset ? [...mentorships] : [];
    response.results.forEach((mentorship) => {
      const mentorshipIndex = updatedMentorships.findIndex(
        (element) => element.id === mentorship.id
      );
      if (mentorshipIndex > -1) {
        updatedMentorships[mentorshipIndex] = mentorship;
      } else {
        updatedMentorships.push(mentorship);
      }
    });
    setMentorships(updatedMentorships);
    setHasNextPage(response.next !== null);
    updateMentorshipsCount('expired', response.count);
    const countsResponse = await fetchMentorshipsCount();
    setMentorshipCounts({ ...countsResponse, expired: response.count });
    setLoading(false);
  }

  useEffect(() => {
    fetchExpiredMentorships();
  }, []);

  const orderOptions = [
    { value: '-date', label: 'Mais recentes para os mais antigos' },
    { value: 'date', label: 'Mais antigos para os mais recentes' },
  ];

  const handleOrderChange = (selected) => {
    setSelectedOrderOption(selected);
    setPage(1);
    fetchExpiredMentorships(true, selected.value);
  };

  const handleOpenModalAdjustStatus = (data) => {
    setModalData(data);
    setOpenModalAdjustStatus(true);
  };

  const openMentoringNotDoneModal = (data) => {
    setModalData(data);
    setMentoringNotDoneModalOpen(true);
    setOpenModalAdjustStatus(false);
  };
  const closeMentoringNotDoneModal = () => {
    setMentoringNotDoneModalOpen(false);
    setModalData({});
  };

  const openMentoringDoneModal = (data) => {
    setModalData(data);
    setMentoringDoneModalOpen(true);
    setOpenModalAdjustStatus(false);
  };
  const closeMentoringDoneModal = () => {
    setMentoringDoneModalOpen(false);
    setModalData({});
  };

  const configModalAdjustStatus = {
    bubbleText: 'Ajustar status da mentoria',
    ParagraphText: `Para ajustar o status, precisamos saber: A mentoria foi realizada?`,
    buttonConfirmText: 'SIM, REALIZEI A MENTORIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      bubbleWidth: '161px',
      sizeIconPerson: '78px',
      bubbleMargin: '4px 0',
      bubbleFontWeight: '400',
    },
  };

  const studentName =
    mentorships && modalData?.id
      ? mentorships.find((m) => m.id === modalData.id)?.student
      : '';

  const handleNextPage = useCallback(async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const queryString = queryParamsFromFilterObject(appliedFilters);
    let queryParams = `limit=20&offset=${offset}&ordering=${selectedOrderOption.value}`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    const response = await mentorshipApi.getExpiredMentorshipsList({
      queryParams,
    });
    if (response) {
      setMentorships([...mentorships, ...response.results]);
      setHasNextPage(!!response.next);
    }
    setLoading(false);
  }, [mentorships, page, appliedFilters, selectedOrderOption]);

  const handleSearchNameChange = (event) => {
    const newName = event.target.value;
    setSearchTerm(newName);
    setPage(1);
    setAppliedFilters({ ...appliedFilters, name: newName });
    setFilterState({ ...filterState, name: newName });
  };

  const onSearchName = () => {
    fetchExpiredMentorships(true);
  };

  return (
    <Container>
      <Filters>
        <SearchFilter>
          <SearchInputWithDebounce
            placeholder={'Buscar'}
            setSearchTerm={(e) => handleSearchNameChange(e)}
            searchTerm={searchTerm}
            onSearch={onSearchName}
          />
        </SearchFilter>
        <OrderFilter>
          <StyledSelect
            value={selectedOrderOption}
            options={orderOptions}
            placeholder="Ordenar por"
            onChange={handleOrderChange}
            classNamePrefix="react-select"
          />
        </OrderFilter>
      </Filters>
      <WhiteBox>
        {mentorships?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhuma mentoria expirada foi encontrada"
            subtitle={`No momento não existe mentorias disponíveis`}
          />
        ) : (
          <MentorshipListContainer>
            {mentorships?.map((item) => (
              <StudentCard
                key={item.id}
                item={item}
                detailsButtonText="Ajustar status"
                additionalDetailsButtonText="Detalhes"
                showDetailsButton={true}
                showCourse={true}
                showAdditionalDetailsButton={true}
                showCareerGoals={shouldRenderForStaff}
                buttonDetailsFunction={() => handleOpenModalAdjustStatus(item)}
                buttonAdditionalDetailsFunction={() => openDetailsModal(item)}
              />
            ))}
            {loading && (
              <SkeletonCardMentorship
                quantity={3}
                subTitleQuantity={4}
                cardHeight="386px"
                cardMargin="0"
              />
            )}
          </MentorshipListContainer>
        )}
        {hasNextPage && !loading && (
          <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
        )}
      </WhiteBox>
      <ModalInformation
        modalOpenState={openModalAdjustStatus}
        config={configModalAdjustStatus}
        buttonConfirmFunction={() => openMentoringDoneModal(modalData)}
        buttonCancelFunction={() => openMentoringNotDoneModal(modalData)}
      />
      <DetailsModal
        isOpen={detailsModalOpen}
        onClose={closeDetailsModal}
        studentName={studentName}
        item={modalData}
        title="Detalhes"
        showMentorName={true}
        showmodalityInfo={true}
        showFlagStatus={true}
        showSimpleDateFormat={true}
        isActiveExpiredTab={isActiveExpiredTab}
      />
      <MentoringNotDoneModal
        isOpen={mentoringNotDoneModalOpen}
        onClose={closeMentoringNotDoneModal}
        item={modalData}
        title="Mentoria não realizada"
        fetchMentorships={fetchExpiredMentorships}
      />
      <MentoringDoneModal
        isOpen={mentoringDoneModalOpen}
        onClose={closeMentoringDoneModal}
        item={modalData}
        title="Mentoria realizada"
        buttonTitle="Enviar"
        fetchMentorships={fetchExpiredMentorships}
      />
    </Container>
  );
}
