import {
  Column,
  Content,
  CourseBox,
  CoursesContent,
  DatesWrapper,
  Description,
  EditButton,
  Row,
  ScheduleBox,
  ScheduleContent,
  SpecialityBox,
  SpecificDateLabel,
  SpecificsDatesWrapper,
  TextMentorName,
  TimeBox,
  TitleText,
  TypeLabel,
} from './styled';
import { useContext, useMemo, useState } from 'react';
import { MentorImage, MentorStatusLabel } from '../../styled';
import EditIcon from 'assets/academicModeling/edit';
import { Tabs } from './components/Tabs';
import GenericModal from '../../../../components/GenericModal';
import BaseLayoutContext from 'contexts/base-layout';
import AuthContext from 'contexts/authentication';
import userIcon from 'assets/img/userIcon.png';

export function MentorModal(props) {
  const { isOpen, onClose, mentor, handleEditMentor } = props;
  const [selectedScheduleTab, setSelectedScheduleTab] = useState(null);
  const { universityColor } = useContext(BaseLayoutContext);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;

  const isEditButtonVisible =
    handleEditMentor &&
    (['career', 'board'].includes(staffRole) ||
      (['teacher', 'course_coordinator'].includes(staffRole) &&
        mentor?.is_logged_staff));

  const handleClose = () => {
    setSelectedScheduleTab(null);
    onClose();
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('pt-BR', options);
  }

  const daysOfWeekMap = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sáb',
  };

  const scheduleTabsOptions = useMemo(() => {
    if (!mentor) return null;
    if (!isOpen) return null;
    const options = Object.keys(mentor.available_days).map((day) => ({
      label: `${daysOfWeekMap[day]} (${mentor.available_days[day].length})`,
      value: day,
    }));
    if (mentor?.specific_dates) {
      const specificDatesAmount = mentor?.specific_dates.reduce(
        (acc, sd) => acc + sd.schedules.length,
        0
      );
      if (specificDatesAmount > 0) {
        options.push({
          label: `Datas específicas (${specificDatesAmount})`,
          value: 'specificsDates',
        });
      }
    }
    return options;
  }, [mentor, isOpen]);

  const modalityDescription = useMemo(() => {
    const modality = mentor?.mentorship_modality?.[0];
    if (!modality) return '';

    return modality.length === 1
      ? modality[0].value === 'in_person'
        ? 'presenciais'
        : 'online'
      : 'presenciais e online';
  }, [mentor]);

  return (
    <GenericModal
      isOpen={isOpen}
      onClose={handleClose}
      title={'Visualizar perfil'}
    >
      <Content>
        <Row>
          <MentorImage
            src={mentor?.avatar || userIcon}
            onError={(e) => {
              e.target.src = userIcon;
              e.target.onerror = null;
            }}
          />
          <Column>
            <MentorStatusLabel
              status={mentor?.status}
              height={'26px'}
              fontSize={'12px'}
            >
              MENTOR {mentor?.status?.toUpperCase()}
            </MentorStatusLabel>
            <TextMentorName>{mentor?.name?.name}</TextMentorName>
          </Column>
          {isEditButtonVisible && (
            <EditButton onClick={handleEditMentor} type={'button'}>
              Editar <EditIcon color={'#FFF'} size={'19px'} />
            </EditButton>
          )}
        </Row>
        <TypeLabel>Mentorias {modalityDescription}</TypeLabel>
        <Description>{mentor?.biography}</Description>
        {mentor?.macro_areas?.length > 0 && (
          <>
            <TitleText>Áreas de atuação</TitleText>
            <CoursesContent>
              {mentor?.macro_areas
                ? mentor?.macro_areas.map((macroArea) => (
                    <CourseBox key={macroArea.value} color={universityColor}>
                      {macroArea.label}
                    </CourseBox>
                  ))
                : null}
            </CoursesContent>
          </>
        )}
        {mentor?.specialities?.length > 0 && (
          <>
            <TitleText>Especialidades</TitleText>
            <CoursesContent>
              {mentor?.specialities
                ? mentor?.specialities.map((speciality) => (
                    <SpecialityBox key={speciality} color={universityColor}>
                      {speciality}
                    </SpecialityBox>
                  ))
                : null}
            </CoursesContent>
          </>
        )}
        <TitleText>Disponibilidade de horário</TitleText>
        <ScheduleBox>
          <Tabs
            options={scheduleTabsOptions}
            selectedOption={selectedScheduleTab}
            setSelectedOption={setSelectedScheduleTab}
          />
          <ScheduleContent>
            {selectedScheduleTab === 'specificsDates' ? (
              <SpecificsDatesWrapper>
                {mentor?.specific_dates?.map((sd) => (
                  <>
                    <SpecificDateLabel>{formatDate(sd.date)}</SpecificDateLabel>
                    <DatesWrapper>
                      {sd.schedules.map((time) => (
                        <div>
                          <TimeBox marginRight={'0.5rem'}>
                            {time.start_hour}
                          </TimeBox>
                          -
                          <TimeBox marginLeft={'0.5rem'}>
                            {time.end_hour}
                          </TimeBox>
                        </div>
                      ))}
                    </DatesWrapper>
                  </>
                ))}
              </SpecificsDatesWrapper>
            ) : (
              <DatesWrapper>
                {mentor?.available_days
                  ? mentor?.available_days[selectedScheduleTab]?.map((time) => (
                      <div>
                        <TimeBox marginRight={'0.5rem'}>
                          {time.start_hour}
                        </TimeBox>
                        -
                        <TimeBox marginLeft={'0.5rem'}>{time.end_hour}</TimeBox>
                      </div>
                    ))
                  : null}
              </DatesWrapper>
            )}
          </ScheduleContent>
        </ScheduleBox>
        {mentor?.courses?.length > 0 && (
          <>
            <TitleText>Mentorias disponíveis para os cursos de</TitleText>
            <CoursesContent>
              {mentor.courses.map((course, index) => (
                <CourseBox key={index} color={universityColor}>
                  {course.label}
                </CourseBox>
              ))}
            </CoursesContent>
          </>
        )}
      </Content>
    </GenericModal>
  );
}
