import React, { useState, useRef, useCallback } from 'react';
import Input from 'components/Opportunities/Inputs/Input';
import IconChallengeCard from 'assets/img/icons/IconChallengeCard.svg';
import FooterFormChallenges from '../FooterFormChallenges';

import {
  Title,
  SubTitle,
  SearchInputDiv,
  WrapperCompetencies,
  DivCompetencies,
  SelectedCompetencies,
} from 'components/Opportunities/styles/styleForm';

import { getCompetencies } from '../../../../OpportunitiesCompany/services';

import InformationModal from '../../InformationModal';
import { ButtonX } from 'components/Opportunities/styles/defaultStyled';
import { Form } from '@unform/web';

import {
  ModalRegisterChallengesSTwo,
  BodyChallenges,
  ModalTitle,
  CardImageModal,
  CardTitleModal,
  InputsModalChallenge,
  Divider,
  DivScroll,
} from './stylesStepTwo';
import SearchableCourses from './SearchableCourses';

import {
  getOptionsCoursesChallenges,
  createNewChallenge,
  updateChallenge,
} from '../../../services/challenges';
import { useEffect } from 'react';
import LoadingModal from '../../loadingModal';

import {
  IModalConfigCreateJobInternal,
  IModalConfigEditJobInternal,
} from '../../loadingModal/iModalConfig';

export default function StepTwoChallenges({
  setCurrentStep,
  formData,
  setFormValues,
  setIsOpenModal,
  cacheOptions,
  setCacheOptions,
  loadChallenges,
  setChallenges,
  setModalInfoOpen,
  ScrollToTop,
  ScrollToTopEdit,
}) {
  const [optionsCourses, setOptionsCourses] = useState([]);
  const [competencies, setCompetencies] = useState([]);

  const [arrayCourseSearch, setArrayCourseSearch] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const [disabledInput, setDisabledInput] = useState(false);
  const [informationModalOpen, setInformationModalOpen] = useState(false);
  const [modalInformation, setModalInformation] = useState({
    type: 'success',
    informationHeader: 'Tudo certo!',
    errorMsg: 'Suas informações foram  enviadas com sucesso',
    finish: true,
  });

  const [modalLoadingOpen, setModalLoadingOpen] = useState(false);
  const [currentModalConfig, setCurrentModalConfig] = useState(
    IModalConfigCreateJobInternal
  );

  const [inputSearch, setInputSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [selectedCompetenciesValues, setSelectedCompetenciesValues] = useState(
    []
  );

  const formRef = useRef();

  const topModal = useRef();

  const sendCourses = [];

  useEffect(() => {
    async function getCompetenciesList() {
      const response = await getCompetencies();
      setCompetencies(response);
    }
    getCompetenciesList();
  }, []);

  useEffect(() => {
    async function getCourses() {
      const response = await getOptionsCoursesChallenges();
      const options = response.principal_course;

      setOptionsCourses(options);
      setCacheOptions(options);
    }
    getCourses();
  }, []);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  useEffect(() => {
    if (inputSearch === '') {
      setFilterSearch([]);
    }
  }, [inputSearch]);

  const handlePrevsFormStep = () => {
    const data = formRef?.current?.getData();
    setFormValues({
      ...data,
      competences_specialities: selectedCompetencies,
    });
    setCurrentStep(1);
    ScrollToTop();
  };

  const handleFilter = (e) => {
    const targetValue = e.target.value;
    setInputSearch(targetValue);

    const OptionsFilter = competencies.filter((value) => {
      return value.label.toLowerCase().includes(inputSearch.toLowerCase());
    });
    setFilterSearch(getUniqueListBy(OptionsFilter, 'label'));
  };

  const handleCompetencie = (value) => {
    setSelectedCompetencies((prevsState) =>
      getUniqueListBy([...prevsState, value], 'value')
    );
  };

  const populateNewSelection = () => {
    const newCurrentArrayPopulated = selectedCompetencies.reduce(
      (accumulator, item) => {
        accumulator[item.label] = item.value;
        return accumulator;
      },
      {}
    );

    const filterArray = filterSearch.filter((item) => {
      return !newCurrentArrayPopulated[item.label];
    });

    return filterArray.map((item) => (
      <DivCompetencies key={item.value} onClick={() => handleCompetencie(item)}>
        {item.label}
      </DivCompetencies>
    ));
  };

  const handleClickDelet = (e, id) => {
    e.preventDefault();
    setSelectedCompetenciesValues([]);
    const filterArray = selectedCompetencies.filter(
      (compet) => compet.value !== id
    );
    setSelectedCompetencies(filterArray);
  };

  const handleCourseChange = (e) => {
    setArrayCourseSearch([]);
    e.forEach((value) =>
      setArrayCourseSearch((prevsState) => [...prevsState, value])
    );
  };

  useEffect(() => {
    if (selectedCompetencies.length >= 5) {
      setFilterSearch([]);
      setDisabledInput(true);
      setInputSearch('');
    } else {
      setDisabledInput(false);
    }
  }, [selectedCompetencies]);

  useEffect(() => {
    selectedCompetencies &&
      selectedCompetencies.forEach((value) =>
        setSelectedCompetenciesValues((prevsState) => [
          ...prevsState,
          value.value,
        ])
      );
  }, [selectedCompetencies]);

  useEffect(() => {
    if (formData.competences_specialities) {
      setSelectedCompetencies(formData.competences_specialities);
    }
  }, [formData]);

  const handleSubmit = async (data) => {
    setDisableButton(true);
    if (formData.is_editable) {
      setModalLoadingOpen(true);
      setCurrentModalConfig(IModalConfigEditJobInternal);
    } else {
      setModalLoadingOpen(true);
      setCurrentModalConfig(IModalConfigCreateJobInternal);
    }

    if (data && data.courses) {
      data.courses.forEach((item) => {
        sendCourses.push(item.value);
      });
    }

    const fullData = {
      ...formData,
      ...data,
      competences_specialities: [...new Set(selectedCompetenciesValues)],
      courses: sendCourses,
      area_categories: formData.area_categories
        ? formData.area_categories.value
        : '',
    };

    const ScrollToModalTop = () => {
      if (topModal.current) {
        topModal.current.scrollIntoView({ block: 'start', inline: 'nearest' });
      } else {
        return;
      }
    };

    if (formData.is_editable) {
      const response = await updateChallenge(fullData);
      setDisableButton(false);
      if (response.status === 200) {
        ScrollToModalTop();
        setModalLoadingOpen(false);
        setModalInformation({
          type: 'success',
          informationHeader: 'Tudo certo!',
          errorMsg: 'Suas informações foram  enviadas com sucesso',
          finish: true,
          setActiveTab: formData.setActiveTab,
          setModalOpen: formData.setModalOpen,
        });
        setChallenges(null);
        setInformationModalOpen(true);
        loadChallenges(`limit=24`);
        ScrollToTop();
        ScrollToTopEdit();
      } else {
        ScrollToModalTop();
        setModalLoadingOpen(false);
        setInformationModalOpen(true);
        setModalInformation({
          type: 'error',
          informationHeader: 'Ooops! Algo deu errado.',
          errorMsg: 'Verifique se existem informações pendentes ou incorretas.',
        });
      }
      return;
    }

    const response = await createNewChallenge(fullData);

    setDisableButton(false);
    if (response.status === 201) {
      ScrollToModalTop();
      setModalLoadingOpen(false);
      setInformationModalOpen(true);
    } else {
      ScrollToModalTop();
      setModalLoadingOpen(false);
      setInformationModalOpen(true);
      setModalInformation({
        type: 'error',
        informationHeader: 'Ooops! Algo deu errado.',
        errorMsg: 'Verifique se existem informações pendentes ou incorretas.',
      });
    }
  };

  useEffect(() => {
    if (
      formData.type_access &&
      formData.type_access !== 'video' &&
      formData.content_pages
    ) {
      formData.content_pages = [];
    }

    if (
      formData.type_access &&
      formData.type_access !== 'attraction_document' &&
      formData.attraction_document
    ) {
      formData.attraction_document = null;
    }
    if (
      formData.type_access &&
      formData.type_access !== 'access_link' &&
      formData.access_link
    ) {
      formData.access_link = '';
    }

    if (
      formData.competences_specialities &&
      Array.isArray(formData.competences_specialities) &&
      !formData.competences_specialities.length
    ) {
      delete formData.competences_specialities;
    }

    if (formData.access_link) {
      var prefix = 'http://';
      if (formData.access_link.includes('http://') === false) {
        if (formData.access_link.includes('https://') === false) {
          formData.access_link = prefix + formData.access_link;
        }
      }
    }
  }, [formData]);

  return (
    <BodyChallenges>
      <ModalRegisterChallengesSTwo>
        <ModalTitle>
          <CardImageModal src={IconChallengeCard} />
          <CardTitleModal>Sobre o Desafio</CardTitleModal>
        </ModalTitle>
        <InformationModal
          isOpen={informationModalOpen}
          setModalOpen={setInformationModalOpen}
          type={modalInformation.type}
          informationHeader={modalInformation.informationHeader}
          errorMsg={modalInformation.errorMsg}
          finish={modalInformation.finish}
          setActiveTab={modalInformation.setActiveTab}
          setModalOpenAtraction={modalInformation.setModalOpen}
          setIsOpenModal={setIsOpenModal}
          setCloseModal={setModalInfoOpen}
        />
        <LoadingModal
          modalOpenState={modalLoadingOpen}
          config={currentModalConfig}
          edit={formData.is_editable}
        />
        <DivScroll>
          <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
            <CardTitleModal
              style={{
                fontSize: '1.3rem',
                margin: '50px 0 0 40px',
              }}
            >
              Público-Alvo
            </CardTitleModal>
            <InputsModalChallenge>
              <Title
                style={{
                  color: '#6d6d6d',
                  fontWeight: 'bold',
                  fontSize: '18px',
                }}
              >
                Selecione os cursos <br />{' '}
                <SubTitle style={{ marginTop: '0px' }}>
                  Se você não selecionar os cursos desejados, o seu desafio será
                  publicado para os estudantes de todos os cursos.
                </SubTitle>
              </Title>

              <SearchableCourses
                name="courses"
                options={optionsCourses}
                placeholder="Todos"
                isMulti
                cacheOptions={cacheOptions}
                onChange={handleCourseChange}
              />
              <Divider />
              <div style={{ marginTop: '40px' }}>
                <CardTitleModal
                  style={{
                    fontSize: '1.3rem',
                    marginLeft: '0',
                    marginBottom: '20px',
                  }}
                >
                  Conhecimentos
                </CardTitleModal>
                <SubTitle style={{ marginTop: '0' }}>
                  Selecione até
                  <strong style={{ color: '##606062' }}>
                    {' '}
                    5 conhecimentos{' '}
                  </strong>
                  que os estudantes poderão alcançar ao tentar resolver o seu
                  desafio.
                </SubTitle>
                <SearchInputDiv>
                  <div
                    style={{
                      display: 'flex',
                      maxHeight: '350px',
                      maxWidth: '500px',
                      flexWrap: 'wrap',
                    }}
                  >
                    {selectedCompetencies.map((value, index) => (
                      <div>
                        {value && value.label && (
                          <SelectedCompetencies key={index}>
                            {value.label}
                            <ButtonX
                              onClick={(e) => handleClickDelet(e, value.value)}
                            />
                          </SelectedCompetencies>
                        )}
                      </div>
                    ))}
                  </div>

                  <Input
                    name="competencies"
                    disabled={disabledInput}
                    value={inputSearch}
                    placeholder="Digite aqui..."
                    onChange={handleFilter}
                  />

                  {filterSearch.length !== 0 && (
                    <WrapperCompetencies>
                      {populateNewSelection()}
                    </WrapperCompetencies>
                  )}
                </SearchInputDiv>
              </div>
            </InputsModalChallenge>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '30px',
                paddingRight: '40px',
              }}
            >
              <FooterFormChallenges
                haveFinish={true}
                haveNext={false}
                haveBack={true}
                onClick={() => handleSubmit()}
                disabledButton={disableButton}
                setCurrentStep={setCurrentStep}
                setFormValues={setFormValues}
                formDataStep2={formRef}
                handlePrevsFormStep={handlePrevsFormStep}
              />
            </div>
          </Form>
        </DivScroll>
      </ModalRegisterChallengesSTwo>
    </BodyChallenges>
  );
}

StepTwoChallenges.prototype = {};
