import styled, { keyframes } from 'styled-components';

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.2);
`;

export const Modal = styled.div`
  width: 510px;
  padding-bottom: 25px;

  border-radius: 16px;
  max-width: 100%;

  background-color: #fff;

  @media screen and (max-width: 430px) {
    height: 100%;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 13px;
  color: #f00;
  text-align: start;
  margin-top: 4px;
  font-weight: 400;
`;

export const ContainerWithoutJobs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: transparent;
  width: 100%;
  height: auto;
  margin-top: 40px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
`;

export const IconWithoutJobsContainer = styled.div`
  width: 112px;
  height: 112px;
`;

export const Title = styled.h1`
  font-size: 1.3em;
  font-weight: 700;
  color: #606062;
  margin: 15px 0;
`;
export const Paragraph = styled.p`
  font-size: 0.9em;
  font-weight: 400;
  color: #606062;
  margin: 0;
  font-weight: 400;
  text-align: ${(props) => (props.isName ? 'center' : 'start')};
`;

export const Text = styled.p`
  color: ${(props) => (props.isTitleModal ? props.universityColor : '#646060')};
  font-size: ${(props) => (props.isTitleModal ? '24px' : '16px')};
  font-weight: ${(props) => (props.isTitleModal ? '500' : '100')};
  margin-top: ${(props) => (props.isTitleModal ? '0' : '8px')};

  @media screen and (max-width: 430px) {
    font-size: ${(props) => (props.isTitleModal ? '16px' : '14px')};
    padding: 0 8px;
    text-align: center;
  }

  @media screen and (max-width: 491px) {
    text-align: center;
  }
`;

export const ContainerTexts = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ButtonPaginationSeeMore = styled.button`
  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 45px;
  font-size: 1em;
  font-weight: 700;

  padding: 8px 12px;
  gap: 5px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
`;

export const Button = styled.button`
  color: ${(props) => (props.isCancel ? '#C11717' : '#fff')};
  background-color: ${(props) =>
    props.isCancel ? 'transparent' : props.universityColor};
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ContainerButtons = styled.div`
  width: 100%;

  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

export const ContainerButtonsPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center !important;
`;

export const ButtonCloseModal = styled.button`
  border: none;
  border-radius: 50%;

  background-color: #efeeec;
  color: #4b4b4d;
  font-weight: 600;
  font-size: 20px;

  cursor: pointer;

  width: 35px;
  height: 35px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 15px 20px;
`;

export const ContainerCloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin-top: 40px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 85%;
  height: 500px;

  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 80px;
  height: 100%;
`;

const slideIn = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
`;

export const ContainerModals = styled.div`
  background-color: #fff;
  display: flex;
  align-items: start;
  flex-direction: column;
  padding: 60px;
  z-index: 2;
  border-radius: 10px;
  max-width: 870px;
  position: relative;
  top: 70px;

  @media (min-width: 1024px) and (max-width: 1440px) {
    top: 40px;
  }

  @media (max-width: 690px) {
    animation: ${slideIn} 0.3s ease-in-out;
  }

  @media (max-width: 570px) {
    width: 100%;
    top: 300px;
    height: 790px;
  }

  @media (max-width: 521px) {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: 410px) {
    font-size: 0.8em;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.6em;
  font-weight: 700;
  color: #606062;
`;

export const ButtonClose = styled.button`
  background-color: #efeeec;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  color: #606062;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;

export const BackgroundModals = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  @media (max-width: 690px) {
    align-items: flex-end !important;
  }
`;

export const IconImg = styled.img`
  width: 20px;
  height: 20px;
`;

export const ContainerTextsModals = styled.div`
  margin-top: 8px;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const TextModals = styled.p`
  font-size: 1.3em;
  font-weight: 400;
  line-height: 25px;
  color: #606062;
`;

export const ParagraphMatch = styled.p`
  font-size: 1.2em;
  font-weight: 400;
  color: #8b8b8b;
  margin: 0;
`;

export const TitleMatchs = styled.h1`
  font-size: 1.8em;
  font-weight: 700;
  margin: 0;
  color: ${(props) => {
    switch (props.match) {
      case 'matchOne':
        return '#CF0000';
      case 'matchTwo':
        return '#E4C000';
      case 'matchThree':
        return '#3392DB';
      case 'matchFour':
        return '#009660';
      default:
        return '#000000';
    }
  }};
`;

export const ContainerMatchs = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
`;

export const LevelMatch = styled.div`
  width: 64px;
  height: 8px;
  border-radius: 10px;
  background-color: #d9d9d9;
`;

export const LevelMatchOne = styled.div`
  width: 64px;
  height: 8px;
  border-radius: 10px;
  background-color: #cf0000;
`;

export const LevelMatchTwo = styled.div`
  width: 64px;
  height: 8px;
  border-radius: 10px;
  background-color: #e4c000;
`;

export const LevelMatchThree = styled.div`
  width: 64px;
  height: 8px;
  border-radius: 10px;
  background-color: #3392db;
`;

export const LevelMatchFour = styled.div`
  width: 64px;
  height: 8px;
  border-radius: 10px;
  background-color: #009660;
`;

export const ContentMatch = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonSeeMore = styled.button`
  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 45px;
  font-size: 1em;
  font-weight: 700;

  padding: 8px 12px;
  gap: 5px;

  background-color: ${({ universityColor, filled }) =>
    filled ? (universityColor ? universityColor : '#009291') : 'transparent'};
  border: none;
  color: ${({ universityColor, filled }) =>
    filled ? '#fff' : universityColor};
  border-radius: 4px;
  cursor: pointer;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
`;

export const ButtonFavorite = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ContainerStar = styled.div`
  position: absolute;
  margin-left: 160px;
`;

export const HeaderModalInterview = styled.div`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#fff'};

  width: 100%;
  height: 90px;
  padding: 0 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ParagraphInterview = styled.p`
  margin: 0;
  font-size: ${(props) => (props.isInterview ? '12px' : '22px')};
  font-weight: 700;

  color: ${(props) => (props.isInterview ? 'rgba(240, 157, 81, 1)' : '#fff')};
  height: ${(props) => (props.isInterview ? '18px' : 'unset')};
`;

export const BodyModalInterview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 40px;
`;

export const ParagraphInterviewUnsheduled = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 700;

  color: ${(props) => {
    switch (props.status) {
      case 'unscheduled':
        return '#f09d51';
      case 'scheduled':
        return '#1cab66';
      default:
        return '#f09d51';
    }
  }};
  height: 18px;
`;

export const ContainerModalInterview = styled.div`
  overflow: hidden;
  background-color: #fff;
  margin-top: 3%;
  display: flex;
  align-items: start;
  flex-direction: column;
  z-index: 2;
  border-radius: 10px;
  max-width: 870px;
  width: 100%;
  max-width: 670px;

  height: ${(props) => (props.isInterview ? 'auto' : '350px')};
  position: relative;
  top: ${(props) => (props.isPerson ? '150px' : '20px')};

  @media (max-width: 690px) {
    top: ${(props) => (props.isPerson ? '150px' : '170px')};
  }

  @media (min-width: 691px) and (max-width: 1440px) {
    top: ${(props) =>
      props.isUnscheduled ? '100px' : props.isPerson ? '150px' : '50px'};
  }
`;

export const ContainerInviteInterview = styled.div`
  background-color: ${(props) => {
    switch (props.status) {
      case 'unscheduled':
        return '#f8f0e7';
      case 'scheduled':
        return '#e7f8e8';
      default:
        return '#f8f0e7';
    }
  }};
  width: 185px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
`;

export const ParagraphText = styled.p`
  margin: 0 0 5px 2px;
  color: #6d6d6d;
  font-weight: 500;
  font-size: 14px;
`;

export const StatusImage = styled.img`
  width: 14px;
  height: 14px;
  margin: 0;
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;

  flex-direction: ${(props) => (props.isData ? 'row' : 'column')};
  gap: ${(props) => (props.isData ? '30px' : 'unset')};
`;

export const CustomForm = styled.div`
  width: 100%;
  display: flex;
  color: #606062;
  align-items: center;
  justify-content: flex-start;
`;

export const CustomCancellationForm = styled(CustomForm)`
  .author-flag {
    background-color: #ffeded;
    color: #da0505;
    gap: 6px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: fit-content;

    padding: 6px;
    border-radius: 3px;

    margin-top: 22px;
  }
`;
