import { Container, Tab, TabIndicator } from './style';
import React, { useMemo, useState } from 'react';
import { TodoTab } from './TodoTab';
import { FinishedTab } from './FinishedTab';

export default function StepTwo() {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedDiscipline, setSelectedDiscipline] = useState(null);
  const [metrics, setMetrics] = useState({
    to_do: 0,
    finished: 0,
    percent: 0,
  });

  const tabs = useMemo(() => {
    return {
      1: (
        <TodoTab
          metrics={metrics}
          setMetrics={setMetrics}
          selectedDiscipline={selectedDiscipline}
          setSelectedDiscipline={setSelectedDiscipline}
        />
      ),
      2: (
        <FinishedTab
          setSelectedTab={setSelectedTab}
          setSelectedDiscipline={setSelectedDiscipline}
        />
      ),
    };
  }, [metrics, selectedDiscipline]);

  return (
    <Container>
      <header>
        <h3>Tag de conteúdos</h3>
        <p>
          Agora que você já selecionou quais são as disciplinas que fazem parte
          da sua matriz atual, elenque os <strong>principais conteúdos</strong>
          <br /> em formato de tag.
        </p>
      </header>
      <TabIndicator>
        <Tab active={selectedTab === 1} onClick={() => setSelectedTab(1)} left>
          A fazer {metrics && `(${metrics.to_do})`}
        </Tab>
        <Tab active={selectedTab === 2} onClick={() => setSelectedTab(2)}>
          Concluído {metrics && `(${metrics.finished})`}
        </Tab>
      </TabIndicator>
      {tabs[selectedTab]}
      <hr />
    </Container>
  );
}
