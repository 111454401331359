import React, { useEffect, useState } from 'react';
import SearchableSelect from 'react-select';
import { func, shape } from 'prop-types';
import classNames from 'classnames';

import { Formik } from 'formik';

import withStyles from '@material-ui/core/styles/withStyles';

import ProfessionalStatusReportStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalStatusReportStyles';

import {
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';

const CustomReportFormFilters = ({
  classes,
  handleSubmitFilters,
  reportOptions,
}) => {
  const [state, setState] = useState({});
  const [valueState, setValueState] = useState({});

  const handleLoadFormOptions = async () => {
    setState({
      unitiesFilters: reportOptions.unity_socioeconomic,
      coursesFilters: reportOptions.courses,
      periodsFilters: reportOptions.semesters,
    });
  };


  const fieldsData = [
    {
      label: 'Unidades',
      name: 'unities',
      options: state.unitiesFilters,
      isMulti: true,
    },
    {
      label: 'Cursos',
      name: 'courses',
      options: state.coursesFilters,
      isMulti: true,
    },
    {
      label: 'Períodos',
      name: 'periods',
      options: state.periodsFilters,
      isMulti: true,
    },
  ];

  useEffect(() => {
    handleLoadFormOptions();
  }, []);

  const handleReset = () => {
    Object.keys(valueState).forEach((key) => {
      setValueState((prevValues) => ({ ...prevValues, [key]: [] }));
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        unities: { label: null, value: undefined },
        courses: { label: null, value: undefined },
        periods: { label: null, value: undefined },
      }}
      onSubmit={async (values, actions) => {
        const { unities, courses, periods } = values;
        await handleSubmitFilters({
          unities,
          courses,
          periods,
        });

        actions.setSubmitting(false);
      }}
      render={({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        submitForm,
        isLoading = !Object.values(state).length,
      }) => (
        <form>
          <List className={classes.list}>
            {isLoading ? (
              <Typography
                align="center"
                style={{
                  marginTop: 40,
                  marginBottom: 18,
                  color: 'rgb(117 117 117)',
                  fontSize: 19,
                }}
              >
                Carregando filtros...
              </Typography>
            ) : (
              fieldsData.map((item) => (
                <ListItem
                  className={classes.listItem}
                  style={{ paddingLeft: 15, paddingRight: 15 }}
                >
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor={item.name}
                      shrink
                      error={errors[item.name] && touched[item.name]}
                    >
                      {item.label}
                    </InputLabel>
                    <SearchableSelect
                      name={item.name}
                      id={item.name}
                      isClearable
                      InputLabel={{
                        shrink: true,
                      }}
                      isMulti={item.isMulti}
                      value={valueState[item.name]}
                      placeholder="Selecione"
                      menuPlacement="bottom"
                      isSearchable
                      classNamePrefix="react-select"
                      className={
                        item.isMulti
                          ? classNames([
                              classes.selectSection,
                              classes.selectSectionAutoHeight,
                            ])
                          : classes.selectSection
                      }
                      onBlur={(event) =>
                        setFieldTouched(item.name, event.label)
                      }
                      onChange={async (event) => {
                        if (event === null) {
                          await setFieldValue(item.name, {
                            label: event,
                            value: undefined,
                          });
                        } else {
                          await setFieldValue(item.name, event);
                        }
                        setValueState((prevValues) => ({
                          ...prevValues,
                          [item.name]: event,
                        }));
                        submitForm();
                      }}
                      options={item.options || []}
                    />
                  </FormControl>
                </ListItem>
              ))
            )}
          </List>
          <ListItem className={classes.itemListFlexEnd}>
            <Button
              type="button"
              className={classes.resetButton}
              onClick={() => {
                fieldsData.forEach((field) => {
                  setFieldValue(field.name, { label: null, value: undefined });
                  handleReset();
                });
                handleSubmitFilters({});
              }}
            >
              Limpar
            </Button>
          </ListItem>
        </form>
      )}
    />
  );
};

CustomReportFormFilters.propTypes = {
  classes: shape.isRequired,
  handleSubmitFilters: func.isRequired,
  reportOptions: shape.isRequired,
};

export default withStyles(ProfessionalStatusReportStyles)(
  CustomReportFormFilters
);
