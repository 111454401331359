import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';

function Footer({ ...props }) {
  const { classes, fluid, white, black } = props;
  const container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
    [classes.blackColor]: black,
  });

  const date = new Date();
  const currentYear = date.getFullYear();

  const anchor =
    classes.a +
    cx({
      [` ${classes.whiteColor}`]: white,
      [` ${classes.blackColor}`]: black,
    });

  return (
    <footer
      className={cx({ [classes.footer]: true, [classes.blackColor]: black })}
    >
      <div className={container} style={{zIndex:0}}>
        <p className={classes.rightContent}>
          <a href="https://workalove.com/" target="_blank" className={anchor}>
            Powered by Workalove &copy;{currentYear}.
          </a>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.shape.isRequired,
  fluid: PropTypes.bool.isRequired,
  white: PropTypes.bool.isRequired,
  rtlActive: PropTypes.bool.isRequired,
  black: PropTypes.bool.isRequired,
};

export default withStyles(footerStyle)(Footer);
