import React, { useState, useEffect, useCallback, useRef } from 'react';
import { object, func, bool, number } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import {
  getProfessionsOptions,
  getProfessionDetail,
  editProfession,
  saveProfession,
} from 'services/backoffice/professions';

import { professionDataAdapter } from 'adapters/backoffice/professions';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SearchableSelect from 'react-select';
import Button from 'components/CustomButtons/Button';
import {
  FormHelperText,
  List,
  ListItem,
  TextField,
  InputLabel,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import ProfessionDetail from './ProfessionDetail';
import JoditEditor from 'jodit-react';

const regexUrlLink = /^https:\/\/*/i;

const config = {
  theme: 'summer',
  style: {
    colorBorder: 'black',
    background: 'white',
    minWidth: '350px',
    maxWidth: '750px',
  },
  placeholder: 'Digite aqui...',
  language: 'pt_br',
  readonly: false,
  height: '10em',
  buttons: ['bold', 'italic', 'underline'],
  buttonsMD: ['bold', 'italic', 'underline'],
  buttonsSM: ['bold', 'italic', 'underline'],
  buttonsXS: ['bold', 'italic', 'underline'],
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: true,
  allowResizeX: false,
  allowResizeY: false,
  toolbarSticky: false,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  tabIndex: 0,
  defaultActionOnPaste: 'insert_only_text',
};

const ProfessionRegistrationSchema = Yup.object().shape({
  name: Yup.string().required('Você deve informar o nome da atuação.'),
  courses: Yup.string().when('transversal_profession', {
    is: (value) => value === false,
    then: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.number(),
        })
      )
      .min(1, 'É obrigatório selecionar pelo menos um curso.'),
  }),
  courseProgress: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number(),
    })
    .required('É obrigatório selecionar o andamento do curso.'),
  behavioral: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .max(4, 'Só podem ser selecionados 4 atributos comportamentais.'),
  noticeUrl: Yup.string()
    .max(499, 'O campo Link aceita no máximo 500 caracteres')
    .matches(regexUrlLink, 'Você deve informar um link válido'),
});

function ProfessionForm(props) {
  const {
    classes,
    loadingState,
    openSnackbar,
    isEdit,
    professionId,
    closeModal,
    openModal,
    updateData,
    canPublish,
    addArea,
    addCourse,
    setProfessionValues,
    professionValues,
  } = props;
  const [initialValues, setInitialValues] = useState({});
  const [options, setOptions] = useState({});
  const [haveChanges, setHaveChanges] = useState(false);
  const [content, setContent] = useState('');
  const descriptionRef = useRef();

  useEffect(
    useCallback(() => {
      loadingState(true);
      fetchOptionsData();
    }),
    []
  );

  const handleResponseError = (message) => {
    openSnackbar(message, true);
    loadingState(false);
    closeModal();
  };

  const fetchProfessionData = async () => {
    const response = await getProfessionDetail(professionId);
    if (response.message) {
      handleResponseError(
        'Falha ao carregar as informações! Tente novamente mais tarde.'
      );
    } else {
      setInitialValues({
        name: response.name,
        description: response.description,
        courses: response.associated_courses,
        defaultAreas: response.associated_areas.default,
        aditionalCourses: response.associated_areas.additional_courses,
        marketTendencies: response.associated_areas.market_tendencies,
        behavioral: response.associated_behavioral,
        courseProgress: response.course_progress,
        salaryRange: response.salary_range,
        requirements: response.previous_requirements,
        noticeUrl: response.notice_url,
        transversal: response.transversal_profession,
      });
      setContent(response.description);
    }
  };

  const fetchOptionsData = async () => {
    const response = await getProfessionsOptions(undefined, true);

    if (response.message) {
      handleResponseError(
        'Falha ao carregar as informações! Tente novamente mais tarde.'
      );
    } else {
      setOptions(response);
      if (Object.keys(professionValues).length > 0) {
        setHaveChanges(true);
        setInitialValues({
          name: professionValues.name,
          description: professionValues.description,
          courses: professionValues.courses,
          defaultAreas: professionValues.defaultAreas,
          aditionalCourses: professionValues.aditionalCourses,
          marketTendencies: professionValues.marketTendencies,
          behavioral: professionValues.behavioral,
          courseProgress: professionValues.courseProgress,
          requirements: professionValues.requirements,
          noticeUrl: professionValues.notice_url,
          salaryRange: professionValues.salaryRange,
          transversal: professionValues.transversal,
        });
        setContent(professionValues.description);
      } else if (isEdit) {
        await fetchProfessionData();
      } else {
        setInitialValues({
          courses: [],
          defaultAreas: [],
          aditionalCourses: [],
          marketTendencies: [],
          behavioral: [],
          transversal: true,
          courseProgress: response.course_progress[0],
        });
      }
      loadingState(false);
    }
  };

  const handleEdit = async (values) => {
    loadingState(true);
    const response = await editProfession(
      await professionDataAdapter({ ...values, content }),
      professionId
    );

    if (response.message) {
      loadingState(false);
      handleResponseError(
        'Falha ao tentar editar essa atuação! Tente novamente mais tarde.'
      );
    } else {
      await updateData(true);
      openSnackbar('Alterações salvas!');
      closeModal();
      openModal(
        <ProfessionDetail
          loadingState={loadingState}
          openSnackbar={openSnackbar}
          data={response}
        />,
        response.name
      );
    }
  };

  const handleProfessionSave = async (values) => {
    loadingState(true);
    const response = await saveProfession(
      await professionDataAdapter({ ...values, content })
    );

    if (response.message) {
      loadingState(false);
      handleResponseError(
        'Falha ao tentar salvar essa atuação! Tente novamente mais tarde.'
      );
    } else {
      openSnackbar('Atuação cadastrada!');

      await updateData(true);
      closeModal();
      openModal(
        <ProfessionDetail
          loadingState={loadingState}
          openSnackbar={openSnackbar}
          data={response}
        />,
        response.name
      );
    }
  };

  return (
    <GridContainer justify="center">
      <GridItem>
        {Object.keys(options).length > 0 &&
          Object.keys(initialValues).length > 0 && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={ProfessionRegistrationSchema}
              validateOnChange={false}
              onSubmit={async (values, actions) => {
                setProfessionValues({});
                if (
                  initialValues === values &&
                  haveChanges === false &&
                  content === initialValues.description
                ) {
                  actions.setSubmitting(false);
                  closeModal();
                } else if (isEdit) {
                  handleEdit(values);
                } else {
                  handleProfessionSave(values);
                }
              }}
              render={({
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <List className={classes.list}>
                    {/* Name */}
                    <ListItem
                      className={classNames([classes.professionListItem])}
                    >
                      <FastField
                        autoFocus
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                          shrink: values.name,
                        }}
                        variant="filled"
                        name="name"
                        id="name"
                        label="Nome"
                        placeholder="Ex.: Analista de sistemas"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.name && touched.name}
                        value={values.name}
                        component={TextField}
                        disabled={isSubmitting}
                      />
                      {errors.name && touched.name ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.name}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Description */}
                    <ListItem
                      className={classNames([classes.professionListItem])}
                    >
                      <JoditEditor
                        name="description"
                        id="description"
                        disabled={isSubmitting}
                        value={values.description}
                        ref={descriptionRef}
                        onBlur={(newContent) => setContent(newContent)}
                        onChange={(newContent) =>
                          setFieldValue('description', newContent)
                        }
                        config={config}
                      />
                      {errors.description && touched.description ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.description}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Transversal Profession */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.transversal}
                          value={values.transversal}
                          classes={{
                            checked: classes.checkBox,
                          }}
                          color="default"
                          onClick={(event) => {
                            if (event.target.checked) {
                              setFieldValue(
                                'transversal',
                                event.target.checked
                              );
                              setFieldValue('courses', []);
                            } else {
                              setFieldValue(
                                'transversal',
                                event.target.checked
                              );
                            }
                          }}
                          disableRipple
                        />
                      }
                      label="Atuação Transversal"
                      labelPlacement="transversal profession"
                    />
                    {/* Course */}
                    {!values.transversal && (
                      <ListItem className={classes.professionListItem}>
                        <FormControl
                          variant="filled"
                          fullWidth
                          className={classNames([
                            classes.coursesDropdownHolder,
                            classes.inlineGroup,
                            classes.alignVerticalCenter,
                          ])}
                        >
                          <InputLabel
                            htmlFor="courses"
                            shrink
                            error={errors.courses && touched.courses}
                          >
                            Cursos
                          </InputLabel>
                          <SearchableSelect
                            name="courses"
                            id="courses"
                            InputLabel={{
                              shrink: true,
                            }}
                            placeholder="Selecione"
                            isSearchable
                            isMulti
                            classNamePrefix="react-select"
                            className={classNames([
                              classes.selectSection,
                              classes.selectSectionAutoHeight,
                              classes.selectSectionCard,
                            ])}
                            onBlur={() => {
                              setFieldTouched('courses');
                            }}
                            onChange={(values) =>
                              setFieldValue('courses', values)
                            }
                            options={options.associated_courses || []}
                            value={values.courses}
                          />
                          <Tooltip
                            title="Adicionar Curso"
                            placement="right"
                            enterDelay={300}
                          >
                            <IconButton
                              arial-label="Adicionar Curso"
                              onClick={() => {
                                loadingState(true);
                                setProfessionValues(values);
                                addCourse(professionId, true);
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </FormControl>
                        {errors.courses && touched.courses ? (
                          <FormHelperText className={classes.helperText}>
                            {errors.courses}
                          </FormHelperText>
                        ) : null}
                      </ListItem>
                    )}
                    {/* Salary Range */}
                    <ListItem
                      className={classNames([classes.professionListItem])}
                    >
                      <FastField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                          shrink: values.salaryRange,
                        }}
                        name="salaryRange"
                        variant="filled"
                        id="salaryRange"
                        label="Faixa Salarial"
                        multiline
                        rowsMax="15"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.salaryRange && touched.salaryRange}
                        value={values.salaryRange}
                        component={TextField}
                        disabled={isSubmitting}
                      />
                      {errors.salaryRange && touched.salaryRange ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.salaryRange}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Requirements */}
                    <ListItem
                      className={classNames([classes.professionListItem])}
                    >
                      <FastField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                          shrink: values.requirements,
                        }}
                        name="requirements"
                        variant="filled"
                        id="requirements"
                        label="Requisitos Prévios"
                        multiline
                        rowsMax="15"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.requirements && touched.requirements}
                        value={values.requirements}
                        component={TextField}
                        disabled={isSubmitting}
                      />
                      {errors.requirements && touched.requirements ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.requirements}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Link */}
                    <ListItem
                      className={classNames([classes.professionListItem])}
                    >
                      <FastField
                        autoFocus
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        InputLabelProps={{
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                          shrink: values.noticeUrl,
                        }}
                        name="noticeUrl"
                        variant="filled"
                        id="noticeUrl"
                        label="Link para Editais"
                        component={TextField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.noticeUrl && touched.noticeUrl}
                        value={values.noticeUrl}
                        disabled={isSubmitting}
                      />
                      {errors.noticeUrl && touched.noticeUrl ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.noticeUrl}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Course progress */}
                    <ListItem className={classes.professionListItem}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.coursesDropdownHolder}
                      >
                        <InputLabel
                          htmlFor="courseProgress"
                          shrink
                          error={
                            errors.courseProgress && touched.courseProgress
                          }
                        >
                          Andamento do curso
                        </InputLabel>
                        <SearchableSelect
                          name="courseProgress"
                          id="courseProgress"
                          InputLabel={{
                            shrink: true,
                          }}
                          placeholder="Selecione"
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                            classes.selectSectionCard,
                          ])}
                          onBlur={() => {
                            setFieldTouched('courseProgress');
                          }}
                          onChange={(values) =>
                            setFieldValue('courseProgress', values)
                          }
                          options={options.course_progress || []}
                          value={values.courseProgress}
                        />
                      </FormControl>
                      {errors.courseProgress && touched.courseProgress ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.courseProgress}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Specific knowledge */}
                    {/* <ListItem className={classes.professionListItem}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classNames([
                          classes.coursesDropdownHolder,
                          classes.inlineGroup,
                          classes.alignVerticalCenter,
                        ])}
                      >
                        <InputLabel
                          htmlFor="defaultAreas"
                          shrink
                          error={errors.defaultAreas && touched.defaultAreas}
                        >
                          Conhecimentos Específicos
                        </InputLabel>
                        <SearchableSelect
                          name="defaultAreas"
                          id="defaultAreas"
                          InputLabel={{
                            shrink: true,
                          }}
                          placeholder="Selecione"
                          isSearchable
                          isMulti
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                            classes.selectSectionCard,
                          ])}
                          onBlur={() => {
                            setFieldTouched('defaultAreas');
                          }}
                          onChange={(values) =>
                            setFieldValue('defaultAreas', values)
                          }
                          options={options.associated_areas.default || []}
                          value={values.defaultAreas}
                        />
                        <Tooltip
                          title="Adicionar Conhecimentos Específicos"
                          placement="right"
                          enterDelay={300}
                        >
                          <IconButton
                            arial-label="Adicionar Conhecimentos Específicos"
                            onClick={() => {
                              loadingState(true);
                              setProfessionValues(values);
                              addArea('default', professionId, true);
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </FormControl>
                      {errors.defaultAreas && touched.defaultAreas ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.defaultAreas}
                        </FormHelperText>
                      ) : null}
                    </ListItem> */}
                    {/* Market Requirements */}
                    <ListItem className={classes.professionListItem}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classNames([
                          classes.coursesDropdownHolder,
                          classes.inlineGroup,
                          classes.alignVerticalCenter,
                        ])}
                      >
                        <InputLabel
                          htmlFor="aditionalCourses"
                          shrink
                          error={
                            errors.aditionalCourses && touched.aditionalCourses
                          }
                        >
                          Requisitos de Mercado
                        </InputLabel>
                        <SearchableSelect
                          name="aditionalCourses"
                          id="aditionalCourses"
                          InputLabel={{
                            shrink: true,
                          }}
                          placeholder="Selecione"
                          isSearchable
                          isMulti
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                            classes.selectSectionCard,
                          ])}
                          onBlur={() => {
                            setFieldTouched('aditionalCourses');
                          }}
                          onChange={(values) =>
                            setFieldValue('aditionalCourses', values)
                          }
                          options={
                            options.associated_areas.additional_courses || []
                          }
                          value={values.aditionalCourses}
                        />
                        <Tooltip
                          title="Adicionar Requisitos de Mercado"
                          placement="right"
                          enterDelay={300}
                        >
                          <IconButton
                            arial-label="Adicionar Requisitos de Mercado"
                            onClick={() => {
                              loadingState(true);
                              setProfessionValues(values);
                              addArea('additional_courses', professionId, true);
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </FormControl>
                      {errors.aditionalCourses && touched.aditionalCourses ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.aditionalCourses}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Market Tendencies */}
                    <ListItem className={classes.professionListItem}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classNames([
                          classes.coursesDropdownHolder,
                          classes.inlineGroup,
                          classes.alignVerticalCenter,
                        ])}
                      >
                        <InputLabel
                          htmlFor="marketTendencies"
                          shrink
                          error={
                            errors.marketTendencies && touched.marketTendencies
                          }
                        >
                          Tendências de Mercado
                        </InputLabel>
                        <SearchableSelect
                          name="marketTendencies"
                          id="marketTendencies"
                          InputLabel={{
                            shrink: true,
                          }}
                          placeholder="Selecione"
                          isSearchable
                          isMulti
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                            classes.selectSectionCard,
                          ])}
                          onBlur={() => {
                            setFieldTouched('marketTendencies');
                          }}
                          onChange={(values) =>
                            setFieldValue('marketTendencies', values)
                          }
                          options={
                            options.associated_areas.market_tendencies || []
                          }
                          value={values.marketTendencies}
                        />
                        <Tooltip
                          title="Adicionar Tendências de Mercado"
                          placement="right"
                          enterDelay={300}
                        >
                          <IconButton
                            arial-label="Adicionar Tendências de Mercado"
                            onClick={() => {
                              loadingState(true);
                              setProfessionValues(values);
                              addArea('market_tendencies', professionId, true);
                            }}
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </FormControl>
                      {errors.marketTendencies && touched.marketTendencies ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.marketTendencies}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {/* Behavioral */}
                    <ListItem className={classes.professionListItem}>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.coursesDropdownHolder}
                      >
                        <InputLabel
                          htmlFor="behavioral"
                          shrink
                          error={errors.behavioral && touched.behavioral}
                        >
                          Atributos comportamentais
                        </InputLabel>
                        <SearchableSelect
                          name="behavioral"
                          id="behavioral"
                          InputLabel={{
                            shrink: true,
                          }}
                          placeholder="Selecione"
                          isSearchable
                          isMulti
                          classNamePrefix="react-select"
                          className={classNames([
                            classes.selectSection,
                            classes.selectSectionAutoHeight,
                            classes.selectSectionCard,
                          ])}
                          onBlur={() => {
                            setFieldTouched('behavioral');
                          }}
                          onChange={(values) =>
                            setFieldValue('behavioral', values)
                          }
                          options={options.associated_behavioral || []}
                          value={values.behavioral}
                        />
                      </FormControl>
                      {errors.behavioral && touched.behavioral ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.behavioral}
                        </FormHelperText>
                      ) : null}
                    </ListItem>
                    {canPublish && isEdit && (
                      <ListItem className={classes.professionListItem}>
                        <FormControlLabel
                          checked={values.published}
                          onChange={handleChange}
                          className={classes.editButton}
                          value={values.published}
                          control={
                            <Switch
                              classes={{
                                checked: classes.switchBase,
                                bar: classes.bar,
                              }}
                              name="published"
                              id="published"
                            />
                          }
                          label="Publicar"
                          labelPlacement="start"
                        />
                      </ListItem>
                    )}
                    <ListItem
                      className={classNames([classes.professionListItem])}
                    >
                      <div className={classes.buttonContainer}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          className={classes.saveButton}
                          round
                        >
                          Salvar
                        </Button>
                      </div>
                    </ListItem>
                  </List>
                </form>
              )}
            />
          )}
      </GridItem>
    </GridContainer>
  );
}

ProfessionForm.propTypes = {
  classes: object.isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  closeModal: func.isRequired,
  openModal: func.isRequired,
  addArea: func.isRequired,
  addCourse: func.isRequired,
  setProfessionValues: func.isRequired,
  professionValues: object.isRequired,
  canPublish: bool,
  isEdit: bool,
  professionId: number,
  updateData: func,
};
export default withStyles(professionalExperienceFormStyles)(ProfessionForm);
