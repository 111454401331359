export const CursorIcon = ({ color = '#009291', size = 13 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15313_49133)">
        <path
          d="M7.504 12.4998C7.22688 12.5003 6.95503 12.4241 6.71847 12.2797C6.48191 12.1354 6.28985 11.9285 6.1635 11.6818L4.6 8.32982L2.829 9.87632C2.75669 9.9395 2.66771 9.98053 2.5727 9.99449C2.4777 10.0085 2.38068 9.99477 2.29324 9.95506C2.20581 9.91535 2.13166 9.8513 2.07966 9.77057C2.02766 9.68984 2 9.59585 2 9.49982V1.98982C1.99999 1.70087 2.08343 1.41808 2.24029 1.17542C2.39715 0.932763 2.62076 0.740576 2.88424 0.621962C3.14771 0.503348 3.43984 0.463356 3.7255 0.506793C4.01116 0.550231 4.27819 0.675248 4.4945 0.866818L9.8445 5.98582C9.91392 6.05213 9.96278 6.13702 9.98525 6.23035C10.0077 6.32368 10.0028 6.42151 9.97121 6.51215C9.93957 6.60279 9.88251 6.6824 9.80685 6.74148C9.73118 6.80056 9.6401 6.83661 9.5445 6.84532L7.3105 7.04882L8.843 10.3333C8.95625 10.5616 9.00945 10.8151 8.99759 11.0697C8.98572 11.3243 8.90918 11.5716 8.77518 11.7884C8.64118 12.0052 8.45415 12.1843 8.23174 12.3088C8.00933 12.4333 7.75887 12.499 7.504 12.4998ZM4.7715 7.01582C4.80626 7.01585 4.84094 7.01936 4.875 7.02632C4.95091 7.04233 5.02205 7.07578 5.08279 7.12403C5.14354 7.17228 5.19223 7.234 5.225 7.30432L7.0625 11.2423C7.09336 11.3003 7.13534 11.3517 7.18606 11.3935C7.23677 11.4353 7.29521 11.4667 7.35806 11.4859C7.4209 11.505 7.48691 11.5117 7.55231 11.5054C7.61772 11.4991 7.68124 11.4799 7.73925 11.4491C7.79726 11.4182 7.84863 11.3762 7.89041 11.3255C7.9322 11.2748 7.96359 11.2164 7.98278 11.1535C8.00198 11.0907 8.00862 11.0247 8.0023 10.9593C7.99599 10.8939 7.97686 10.8303 7.946 10.7723L6.105 6.82682C6.07105 6.75412 6.05509 6.67431 6.05847 6.59414C6.06186 6.51397 6.08449 6.4358 6.12445 6.36622C6.16442 6.29664 6.22054 6.2377 6.28808 6.19438C6.35562 6.15105 6.43259 6.12462 6.5125 6.11732L8.3625 5.94882L3.817 1.60282C3.74577 1.54229 3.65859 1.50358 3.56592 1.49134C3.47324 1.4791 3.379 1.49385 3.2945 1.53382C3.20617 1.57266 3.13119 1.63658 3.07884 1.71764C3.02649 1.79869 2.99908 1.89333 3 1.98982V8.39982L4.4425 7.13982C4.53354 7.06002 4.65044 7.01596 4.7715 7.01582Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_15313_49133">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
