import React, { useEffect } from 'react';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import headerAcademicInnovation from 'assets/backoffice/headerAcademicInnovation.svg';
import WrapperFormToShow from './Screens/Wrapper/FormToShow';
import { FormProvider } from './contexts';

export default function AcademicInnovationEngine({ handleIsLoadingState }) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <FormProvider>
      <CompanyHeader
        title="Inovação acadêmica"
        backgroundImage={headerAcademicInnovation}
      />
      <WrapperFormToShow />
    </FormProvider>
  );
}
