import React, { Fragment, useState, useContext } from 'react';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';
import ModalMoreInformations from '../../../Modal/ModalMoreInformation';
import SkeletonCardProjectExtension from '../../../SkeletonExtensionProjects';
import { PublishedCard } from '../Card';

export default function PublishedCarousel({
  array,
  waitingForStart,
  ref,
  setStatus,
}) {
  const [details, setDetails] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const { setModalEnrolledStudents, setExtensionProjectId } = useContext(
    ExtensionProjectsContext
  );

  const handleEnrolledStudents = (item) => {
    setModalEnrolledStudents(true);
    setExtensionProjectId(item.extension_project.id);
    setStatus(item.is_published);
  };

  const mapProject = (array) => {
    if (array === null) {
      return (
        <SkeletonCardProjectExtension quantity={randomIntFromInterval(3, 4)} />
      );
    }
    return (
      array &&
      array.map((item) => (
        <PublishedCard
          ref={ref}
          item={item}
          waitingForStart={waitingForStart}
          setDetails={setDetails}
          setIsOpen={setIsOpen}
          handleEnrolledStudents={handleEnrolledStudents}
        />
      ))
    );
  };

  return (
    <Fragment>
      {mapProject(array)}
      {details && Object.keys(details).length > 0 ? (
        <ModalMoreInformations
          details={details}
          isOpen={isOpen}
          setModalOpen={setIsOpen}
          handleEnrolledStudents={handleEnrolledStudents}
        />
      ) : null}
    </Fragment>
  );
}
