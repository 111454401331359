import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { func, string } from 'prop-types';
import BaseLayoutContext from 'contexts/base-layout';
import {
  Modal,
  Text,
  Button,
  ContainerButtons,
  ButtonCloseModal,
  ContainerCloseModal,
  ContainerTexts,
} from './styled';

const ConfirmaApplicationDialog = ({
  candidateJobOffer,
  candidateJobOfferDetails,
  id,
  isOpen,
  setClose,
}) => {
  const { closeModal, universityColor } = useContext(BaseLayoutContext);

  const handleClickToApplyJob = (event) => {
    event.currentTarget.disabled = true;

    candidateJobOffer && candidateJobOffer(id);
    candidateJobOfferDetails && candidateJobOfferDetails();
    setClose();
    closeModal();
  };

  return (
    <Dialog
      style={{}}
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
      }}
      open={isOpen}
      onBackdropClick={() => setClose()}
    >
      <Modal>
        <ContainerCloseModal isCloseModal>
          <ButtonCloseModal
            onClick={() => {
              setClose();
            }}
          >
            x
          </ButtonCloseModal>
        </ContainerCloseModal>

        <ContainerTexts>
          <Text universityColor={universityColor} isTitleModal>
            Confirmar candidatura à vaga?
          </Text>
          <Text>Tem certeza que deseja se candidatar a essa vaga?</Text>
        </ContainerTexts>

        <ContainerButtons>
          <Button
            universityColor={universityColor}
            isCancel
            onClick={() => {
              setClose();
            }}
          >
            Não, cancelar candidatura.
          </Button>

          <Button
            universityColor={universityColor}
            onClick={(event) => {
              handleClickToApplyJob(event);
            }}
          >
            Sim, quero candidatar.
          </Button>
        </ContainerButtons>
      </Modal>
    </Dialog>
  );
};

ConfirmaApplicationDialog.propTypes = {
  candidateJobOffer: func.isRequired,
  id: string.isRequired,
};

export default ConfirmaApplicationDialog;
