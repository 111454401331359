import styled from 'styled-components';

export const Container = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;

  > p {
    font-size: 18px;
    color: #606062;
    margin: 0 0 0 4px !important;
  }
`;
export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const SwitchSlider = styled.div`
  display: flex;
  width: 24px;
  height: 15px;
  background-color: ${({ checked }) => (checked ? `#008281` : '#ccc')};
  border: none;
  cursor: pointer;
  border-radius: 20px;
  margin: 0 !important;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    height: 11px;
    width: 11px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ checked }) =>
      checked ? 'translateX(9px)' : 'translateX(0)'};
  }
`;
