import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  width: 80%;
  margin: auto;
  border-radius: 10px;
  padding: 20px 40px;
  overflow-x: hidden;

  p {
    margin: 0;
  }

  h2 {
    color: #606062;
    font-size: 21px;
    font-weight: 400;
  }

  @media (min-width: 2400px) {
    width: 55%;
  }
`;

export const Header = styled.div`
  h1 {
    font-size: 28px;
    font-weight: bold;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }

  p {
    color: #606062;
    font-size: 15px;
  }
`;

export const Wrapper = styled.div``;

export const HeaderSelectLanguages = styled.div`
  p {
    color: #606062;
    padding: 5px 2px;
    font-size: 17px;
    font-weight: 400;
  }
`;
export const ProficiencyTestButton = styled.button`
  color: #ffffff;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  padding: 12px 30px;
  min-width: 35%;
  font-size: 15px;
  min-height: auto;
  text-align: center;
  display: flex;
  align-items: start;
  justify-content: start;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.42857143;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 6px;

  @media (max-width: 768px) {
    padding: 12px 10px;
    min-width: 35%;
    font-size: 12px;
  }
`;

export const CardsWrapper = styled.div`
  h2 {
    font-size: 18px;
    font-weight: 600;
    width: 100%;
  }
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 3rem;
  width: 95%;
  height: auto;
  margin: 0 auto;

  @media (min-width: 1660px) {
    width: 70%;
  }
`;

export const CardContainer = styled.label`
  font-size: 0px;
  display: block;
  margin-top: 5px;
  border: 2px solid;
  border-color: ${(props) =>
    props.checked ? props.universityColor : 'rgb(96,96,98,0.3)'};
  border-radius: 24px;
  width: 13rem;
  height: 15rem;
  padding: 1.5rem 1rem;
  cursor: pointer;
  text-align: center;

  position: relative;
  z-index: 1;

  .bg-opacity {
    background-color: ${(props) =>
      props.checked ? props.universityColor : '#ffffff'};

    position: absolute;
    inset: 0;
    border-radius: 24px;
    opacity: 0.1;
    z-index: 0;
  }

  h1 {
    color: ${(props) => (props.checked ? props.universityColor : '#606062')};
  }

  p {
    color: ${(props) => (props.checked ? '#101010' : '#949494')};
  }
`;

export const ChoiceInput = styled.input`
  width: '0px';
  opacity: 0;
  margin-left: -2px;
`;
export const CardIcon = styled.div`
  svg {
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 20;
  }
`;

export const CardHeader = styled.h1`
  font-size: 18px;
  font-weight: bold;

  position: relative;
  z-index: 20;
`;

export const CardText = styled.div`
  font-size: 15px;
  max-width: 100%;
  word-break: break-word;
  text-align: -webkit-center;

  position: relative;
  z-index: 20;
`;

export const WrapperButton = styled.div`
  text-align: center;

  @media (max-width: 768px) {
    min-width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LanguageButton = styled.button`
  color: #ffffff;
  border: none;
  margin: 2rem auto;
  cursor: pointer;
  padding: 12px 30px;
  min-width: 35%;
  font-size: 15px;
  min-height: auto;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.42857143;
  border-radius: 30px;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 6px;

  @media (max-width: 768px) {
    padding: 12px 10px;
    min-width: 35%;
    font-size: 12px;
  }
`;

// styles for language result

export const TableContainer = styled.div`
  max-width: 100%;

  ul {
    padding: 0;
  }

  li {
    display: flex;
    justify-content: space-between;
  }
`;

export const ListItem = styled.li`
  ${(props) =>
    props.hasBorder && 'border-bottom: 1px solid rgba(0, 0, 0, 0.1);'}
`;

export const LanguageList = styled.div`
  h5 {
    font-weight: bold;
    text-transform: capitalize;
  }

  p {
    font-size: 1em;
    font-weight: 300;
  }
`;

export const LanguageIcons = styled.div`
  display: flex;
  margin: 13px 0px;
`;
