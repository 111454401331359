import React, { useRef, useEffect, useState, Fragment } from 'react';

import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';


import OpportunityList from './components/TabPages/OpportunityList'
import RegisterOpportunity from './components/TabPages/RegisterOpportunity'

import opportunityImg from '../../../assets/img/oportunidades.png';

import OportunitiesCompanyProvider from './context/OpportunitiesCompanyContext'

const OpportunitiesCompany = ({handleIsLoadingState}) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    handleIsLoadingState(false)
  }, [])

  const renderizeTab = (step) => {
    if (step === 0) {
      return (
        <RegisterOpportunity
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
      );
    }
    if (step === 1) {
      return (
        <OpportunityList
          handleIsLoadingState={handleIsLoadingState}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
      />

      );
    }

  };

  const topTab = useRef(null);

  const ScrollTopTab = () => {
    if (topTab) {
      topTab.current.scrollIntoView({ alignToTop: true });
    }
  };

  useEffect(() => {
    ScrollTopTab();
  }, [activeTab]);

  return (
    <div ref={topTab}>
    <OportunitiesCompanyProvider>
      <Fragment>
        <CompanyHeader
          title="Oportunidades de Aprendizagem"
          backgroundImage={opportunityImg}
          handleTabChange={handleTabChange}
          tabs={[`Cadastrar Aprendizagem`, `Aprendizagem Publicadas`]}
          activeTab={activeTab}

        />
        {renderizeTab(activeTab)}
      </Fragment>
    </OportunitiesCompanyProvider>
    </div>
  );
};

export default OpportunitiesCompany;
