export const removeOctadesk = () => {
  const scripts = document.getElementById('octadesk-octachat-appchat');
  scripts && scripts.parentNode.removeChild(scripts);

  const scriptExisting = document.querySelector(
    "script[src='https://cdn.octadesk.com/embed.js']"
  );
  if (scriptExisting) {
    scriptExisting.remove();
  }
};

const octadesk = () => {
  removeOctadesk();

  const script = document.createElement('script');
  script.innerHTML = (function (o, c, t, a, d, e, s, k) {
    o.octadesk = o.octadesk || {};
    o.octadesk.chatOptions = {
      subDomain: a,
      showButton: d,
      openOnMessage: e,
      hide: s,
    };
    var bd = c.getElementsByTagName('body')[0];
    var sc = c.createElement('script');
    sc.async = 1;
    sc.src = t;
    bd.appendChild(sc);
  })(
    window,
    document,
    'https://cdn.octadesk.com/embed.js',
    'workalove1',
    'true',
    'true',
    'false'
  );
  document.head.appendChild(script);
};

export default octadesk;
