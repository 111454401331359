import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import {
  ContainerBetween,
  H2,
  H5,
  NextButton,
  WrapperBottomActions,
} from '../styled';
import { ColorInput } from 'views/Company/components/CompanyRegistrationForm/styles';
import {
  ContainerColorArrow,
  ContainerColorInput,
  UploadImageButton,
} from 'views/StaffPages/SettingsPage/PersonalizationTab/Profile/styled';
import Gallery from 'assets/SettingsPage/Profile/Gallery';
import UploadedAvatarCustom from 'components/UploadedAvatarCustom/UploadedAvatarCustom';

export default function MyProfileModalStepOne({
  data,
  handleNext,
  stepsData,
  formRef,
  refCompanyColor,
  refCompanyLogo,
}) {
  const [color, setColor] = useState(data?.company_color);
  const [file, setFile] = useState('');

  const buttonUploadRef = useRef(null);

  const handleSubmit = (data) => {
    handleNext({ ...data, file: file, company_color: color });
  };

  useEffect(() => {
    if (stepsData.company_color) {
      setColor(stepsData.company_color);
    }
    if (!stepsData.company_color) {
      setColor(data?.company_color);
    }
  }, [stepsData, data]);

  const handleClickUpload = (event) => {
    buttonUploadRef?.current?.onClick(event);
  };

  const currentImage = () => {
    if (stepsData.file) {
      return null;
    }
    return data?.logo;
  };

  const handleChangeFile = (file) => {
    setFile(file);
    refCompanyLogo.current.value = file;
  };

  const handleChangeColor = (e) => {
    setColor(e.target.value);
  };

  useEffect(() => {
    refCompanyColor.current.value = color;
  }, [color]);

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <H2>Personalização</H2>
      <ContainerBetween>
        <div>
          <p>
            <H5>Logotipo</H5>
            Adicione o logotipo da sua empresa em PNG. Essa imagem ficará
            visível para os estudantes e egressos.
          </p>
          <UploadImageButton onClick={handleClickUpload}>
            <Gallery color={'#009291'} size={25} />
            Adicionar imagem
          </UploadImageButton>
        </div>

        <div>
          <UploadedAvatarCustom
            heightImageDisplay="100px"
            widthImageDisplay="100px"
            imageUrl={currentImage()}
            setFile={handleChangeFile}
            buttonUploadRef={buttonUploadRef}
            dataFile={stepsData.file}
          />
        </div>
      </ContainerBetween>
      <ContainerBetween>
        <p>
          <H5>Cor principal da marca</H5>
          Aplicada no cabeçalho do perfil
        </p>
        <ContainerColorInput>
          <ColorInput
            style={{
              width: '40px',
              height: '40px',
            }}
            type="color"
            value={color}
            onChange={handleChangeColor}
          />

          <ContainerColorArrow>
            <input value={color} onChange={handleChangeColor} />
          </ContainerColorArrow>
        </ContainerColorInput>
      </ContainerBetween>
      <WrapperBottomActions justify="flex-end">
        <NextButton type="submit"></NextButton>
      </WrapperBottomActions>
    </Form>
  );
}
