import styled from 'styled-components';

import { Grid, Button } from '@material-ui/core';
import CardComposition from 'components/CardComposition/CardComposition';

export const FromWrapper = styled(Grid)`
  margin: auto;
  padding: 20px;
`;

export const Form = styled.form`
  width: 100%;
`;

export const FormItem = styled(Grid)``;

export const FormCard = styled(CardComposition)``;

export const FormButton = styled(Button)`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'} !important;
  border-radius: 30px;
  color: #fff !important;
  transition: 0.5s;

  &:disabled {
    background-color: #c1bcbc;
    color: #777777;
  }

  &:hover {
    transition: 0.5s;
    background-color: transparent !important;
    border: 2px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')} !important;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
  }
`;
