const defaultIndexCategories = [
  {
    icon: '',
    title: `Gestão de
   Serviços de Carreira`,
    subTitle: `Acompanhe seu funil de
   empregabilidade dos estudantes.`,
    category: 'carrearService',
    index: 0,
  },
  {
    icon: '',
    title: `Gestão de Atividades`,
    subTitle: `Crie listas segmentadas e personalize sua comunicação.`,
    category: 'activityManagement',
    index: 1,
  },
];

export { defaultIndexCategories };
