import styled from 'styled-components';
import activeDropdownIcon from 'assets/mentorship/dropdown-active.svg';
import dropdownIcon from 'assets/mentorship/dropdown.svg';

export const Dropdown = styled.div`
  background-color: #fff;
  width: 104px;
  height: 34px;
  border-radius: 6.65px;
`;

export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  padding: 0 0.625rem;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#F8F7FA'};
  border-radius: 6.65px;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  p {
    font-weight: 600;
    color: #4b4b4d;
    font-size: 12px;
    margin: 0;
    line-height: 20px;
  }

  position: relative;
`;

export const DropdownHeaderIcon = styled.div`
  position: absolute;
  right: 0.5rem;
  width: 9px;
  height: 6px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${(props) =>
    props.open ? activeDropdownIcon : dropdownIcon});
`;

export const List = styled.div`
  margin: 12px 0 0 0;
  border-radius: 4px;
  box-shadow: 0 0 7px 0 #00000026;
  max-height: 200px;
  overflow-y: auto;
  width: fit-content;
  background-color: #fff;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaaaaa;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`;

export const ListItem = styled.button`
  width: 100%;
  height: 2.5rem;
  border: none;
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: auto auto;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  position: relative;
  z-index: 3;

  &:hover {
    background-color: #009291;

    span {
      color: #fff;
    }
  }

  span {
    text-align: center;
    font-size: 0.9375rem;
    font-weight: ${(props) => (props.selected ? '500' : '400')};
    line-height: 1.125rem;
    font-family: 'Roboto', sans-serif;
    color: ${(props) => (props.selected ? '#009291' : '#4B4B4D')};
  }
`;
