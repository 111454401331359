import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 10px;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;
`;

export const ContainerTypeName = styled.div`
  display: flex;
  gap: 10px;

  > img {
    width: 20px;
  }

  > div {
    .nameEvidence {
      display: flex;
      align-items: center;
      margin: 0;
      font-weight: 400;
    }
    .date {
      font-size: 12px;
      display: unset;
      margin: 0;
    }
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  gap: 10px;

  > img {
    width: 20px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }
`;

export const PopUp = styled.div`
> img {
  width: 20px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
}

  position: relative;

  &:hover {
    &:before {
      display: block;
    }
    &:after {
      display: block;
    }
  }

  &:before {
    content: '${(props) => props.label}';
    width: 56px;
    background-color: #009291;
    position: absolute;
    top: -38px;
    right: -20px;
    padding: 5px 7px;
    text-align: center;
    display: block;
    color: #ffff;
    border-radius: 4px;
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #009291 transparent;
    border-width: 10px 10px 0;
    bottom: 19px;
    left: 50%;
    margin-left: -10px;
    display: none;
  }
}

> button {
  border: 1px solid #009291;
  color: #009291;
  font-weight: 500;
  padding: 5px 12px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
}
`;
