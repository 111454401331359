import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);

  position: fixed;
  left: 0;
  top: 0;
  z-index: ${(props) => props.zIndex};
  overflow-y: auto;
`;

export const Box = styled.div`
  width: calc(100% - 259px);

  margin: 40px auto;
  position: absolute;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  border-radius: 8px;

  background-color: #ffffff;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #009291;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  > .border-image {
    border: 1px solid #ccc;
    border-radius: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
  background-color: #009291;
  padding: 20px 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  > h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }

  > .close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    background-color: #fff;
    color: #606062;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const JobList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  align-items: center;
  justify-content: center;
`;

export const JobItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 295px;
  min-height: 266px;
  height: auto;

  button {
    width: 100%;
    height: 39px;
    color: #fff;
    background-color: #009291;
    border-radius: 4px;
    font-size: 14px;
  }

  &:hover {
    background-color: #0092910d;
    border: 1px solid #009291;
    cursor: pointer;

    button {
      background-color: #3dadac;
      color: #fff;
      border: none;
      padding: 10px 20px;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .vacancy-id {
    color: #606062;
    font-size: 14px;
    font-weight: 400;
  }

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: #606062;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 50px;
  }

  > p {
    margin: 0;
    font-size: 14px;
  }
`;

export const JobActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  > button {
    background: #009291;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
`;

export const ContainerList = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > .company-info {
    width: 90%;
    > p {
      margin-bottom: 5px;
      color: #606062;
    }

    .company-name {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

export const SwitchContainer = styled.div`
  margin: 20px 40px;
  width: 90%;
  align-items: flex-start;
`;
