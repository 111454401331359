import React, { useState, useEffect, Fragment } from 'react';
import { object, func, bool, number } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import {
  getCoursesOptions,
  getCourseDetail,
  editCourse,
  saveCourse,
} from 'services/backoffice/courses';

import { courseDataAdapter } from 'adapters/backoffice/courses';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SearchableSelect from 'react-select';
import Button from 'components/CustomButtons/Button';
import {
  FormHelperText,
  List,
  ListItem,
  TextField,
  InputLabel,
  FormControl,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import CourseDetail from './CourseDetail';
import { Add } from '@material-ui/icons';

const maskTime = (event, inputValue) => {
  if (!inputValue) {
    return '';
  }

  let onlyDigits = inputValue.replace(/\D/g, '');
  const digits = onlyDigits.length;

  if (event.key === 'Backspace') {
    if (digits >= 3) {
      return onlyDigits.substring(0, 2);
    }
    return onlyDigits;
  }

  let hours = '';
  let minutes = '';
  if (digits <= 2) {
    return onlyDigits;
  } else {
    hours = onlyDigits.substring(0, 2);
    minutes = onlyDigits.substring(2, digits);
    return `${hours}:00`;
  }
};
const fillTime = (value, setFieldValue, fieldName) => {
  if (value.length === 0) {
    return '';
  }

  if (value.includes(':')) {
    return value;
  }
  const digits = value.length;

  if (digits <= 2) {
    setFieldValue(fieldName, `${value}:00`);
    return `${value}:00`;
  }
};

const CourseRegistrationSchema = Yup.object().shape({
  name: Yup.string().required('Você deve informar o nome do curso.'),
  macroArea: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .typeError('Você deve selecionar uma macro área.'),
  courseLevel: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .typeError('Você deve selecionar o nível do curso.'),
  max_daily_internship_workload: Yup.string()
    .required('Você deve informar a carga horária diária máxima.')
    .test(
      'max_daily_internship_workload',
      'A carga horária diária deve ser entre 1 e 8.',
      function (value) {
        let num = 0;

        if (!value) {
          return this.createError({
            path: 'max_daily_internship_workload',
            message:
              'Valor inválido. A carga horária diária não pode estar vazia.',
          });
        }

        num = parseInt(value, 10);

        return num >= 1 && num <= 8;
      }
    ),
  max_weekly_internship_workload: Yup.string()
    .required('Você deve informar a carga horária semanal máxima.')
    .test(
      'max_weekly_internship_workload',
      'A carga horária semanal deve ser entre 1 e 40.',
      function (value) {
        let num = 0;

        if (!value) {
          return this.createError({
            path: 'max_weekly_internship_workload',
            message:
              'Valor inválido. A carga horária semanal não pode estar vazia.',
          });
        }

        num = parseInt(value, 10);

        return num >= 1 && num <= 40;
      }
    ),
});

function CourseForm(props) {
  const {
    classes,
    loadingState,
    openSnackbar,
    isEdit,
    courseId,
    closeModal,
    openModal,
    updateData,
    professionId,
    editProfession,
    addProfession,
    addArea,
    setCoursesValues,
    shouldReturnProfessionForm,
    setSaveFormAndOpenProfessions,
    coursesValues,
  } = props;

  const [initialValues, setInitialValues] = useState({
    name: '',
    macroArea: null,
    courseLevel: null,
    aditionalCourses: [],
    marketTendencies: [],
    max_daily_internship_workload: '',
    max_weekly_internship_workload: '',
  });
  const [options, setOptions] = useState({});
  const [haveChanges, setHaveChanges] = useState(false);

  useEffect(() => {
    loadingState(true);
    fetchOptionsData();
  }, []);

  const fetchOptionsData = async () => {
    const response = await getCoursesOptions();

    if (response.message) {
      handleResponseError(
        'Falha ao carregar as informações! Tente novamente mais tarde.'
      );
    } else {
      setOptions(response);
      if (coursesValues && Object.keys(coursesValues).length > 0) {
        setHaveChanges(true);
        setInitialValues({
          name: coursesValues.name,
          macroArea: coursesValues.macroArea.value
            ? coursesValues.macroArea
            : null,
          courseLevel: coursesValues.courseLevel.value
            ? coursesValues.courseLevel
            : null,
          aditionalCourses: coursesValues.aditionalCourses,
          marketTendencies: coursesValues.marketTendencies,
        });
      } else if (isEdit) {
        await fetchCourseData();
      } else {
        setInitialValues({ macroArea: null, courseLevel: null });
      }

      loadingState(false);
    }
  };

  const handleEdit = async (values) => {
    loadingState(true);
    const response = await editCourse(
      await courseDataAdapter(values),
      courseId
    );

    if (response.message) {
      loadingState(false);
      handleResponseError(
        'Falha ao tentar editar esse curso! Tente novamente mais tarde.'
      );
    } else {
      await updateData(response);
      openSnackbar('Alterações salvas!');
      closeModal();
      openModal(
        <CourseDetail
          loadingState={loadingState}
          openSnackbar={openSnackbar}
          data={response}
        />,
        response.name
      );
    }
  };

  const handleResponseError = (message) => {
    openSnackbar(message, true);
    loadingState(false);
    closeModal();
  };

  const fetchCourseData = async () => {
    const response = await getCourseDetail(courseId);

    if (response.message) {
      handleResponseError(
        'Falha ao carregar as informações! Tente novamente mais tarde.'
      );
    } else {
      setInitialValues({
        name: response.name,
        macroArea: response.macro_area.value ? response.macro_area : null,
        courseLevel: response.course_level.value ? response.course_level : null,
        aditionalCourses: response.areas.additional_courses,
        marketTendencies: response.areas.market_tendencies,
        max_daily_internship_workload:
          response.max_daily_internship_workload || '',
        max_weekly_internship_workload:
          response.max_weekly_internship_workload || '',
      });
    }
  };

  const handleCourseSave = async (values) => {
    loadingState(true);
    const response = await saveCourse(await courseDataAdapter(values));

    if (response.message) {
      loadingState(false);
      handleResponseError(
        'Falha ao tentar salvar esse curso! Tente novamente mais tarde.'
      );
    } else {
      await updateData(response);
      openSnackbar('Curso cadastrado!');
      if (shouldReturnProfessionForm) {
        professionId !== undefined
          ? setSaveFormAndOpenProfessions({ id: professionId, response })
          : setSaveFormAndOpenProfessions({ id: null, response });
      } else closeModal();
      loadingState(false);
    }
  };

  return (
    <GridContainer justify="center">
      <GridItem>
        {Object.keys(options).length > 0 && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={CourseRegistrationSchema}
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              setCoursesValues({});
              if (initialValues === values && haveChanges === false) {
                actions.setSubmitting(false);
                closeModal();
              } else if (isEdit) {
                handleEdit(values);
              } else {
                handleCourseSave(values);
              }
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <h3 style={{ color: '#009291', fontWeight: 'bold' }}>
                  Dados do curso
                </h3>

                <List className={classes.list}>
                  {/* Name */}
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <FastField
                      autoFocus
                      className={classes.inputField}
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                        shrink: values.name,
                      }}
                      variant="filled"
                      name="name"
                      id="name"
                      label="Nome"
                      placeholder="Ex.: Engenharia de Software"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.name && touched.name}
                      value={values.name}
                      component={TextField}
                      disabled={isSubmitting}
                    />
                    {errors.name && touched.name ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.name}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                  {/* Course Level */}
                  <ListItem className={classes.professionListItem}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      className={classes.coursesDropdownHolder}
                    >
                      <InputLabel
                        htmlFor="courseLevel"
                        shrink
                        error={errors.courseLevel && touched.courseLevel}
                      >
                        Nível do Curso
                      </InputLabel>
                      <SearchableSelect
                        name="courseLevel"
                        id="courseLevel"
                        InputLabel={{
                          shrink: true,
                        }}
                        placeholder="Selecione"
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                          classes.selectSectionCard,
                        ])}
                        onBlur={handleBlur}
                        onChange={(choice) =>
                          setFieldValue('courseLevel', choice)
                        }
                        options={options.course_level || []}
                        value={values.courseLevel}
                      />
                    </FormControl>
                    {errors.courseLevel && touched.courseLevel ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.courseLevel}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                  {/* Macro areas */}
                  <ListItem className={classes.professionListItem}>
                    <FormControl
                      variant="filled"
                      fullWidth
                      className={classes.coursesDropdownHolder}
                    >
                      <InputLabel
                        htmlFor="macroArea"
                        shrink
                        error={errors.macroArea && touched.macroArea}
                      >
                        Macro Área
                      </InputLabel>
                      <SearchableSelect
                        name="macroArea"
                        id="macroArea"
                        InputLabel={{
                          shrink: true,
                        }}
                        placeholder="Selecione"
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                          classes.selectSectionCard,
                        ])}
                        onBlur={handleBlur}
                        onChange={(choice) =>
                          setFieldValue('macroArea', choice)
                        }
                        options={options.macro_areas || []}
                        value={values.macroArea}
                      />
                    </FormControl>
                    {errors.macroArea && touched.macroArea ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.macroArea}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                  {isEdit && (
                    <Fragment>
                      {/* Market Requirements */}
                      <ListItem className={classes.professionListItem}>
                        <FormControl
                          variant="filled"
                          fullWidth
                          className={classNames([
                            classes.coursesDropdownHolder,
                            classes.inlineGroup,
                            classes.alignVerticalCenter,
                          ])}
                        >
                          <InputLabel
                            htmlFor="aditionalCourses"
                            shrink
                            error={
                              errors.aditionalCourses &&
                              touched.aditionalCourses
                            }
                          >
                            Requisitos de Mercado
                          </InputLabel>
                          <SearchableSelect
                            name="aditionalCourses"
                            id="aditionalCourses"
                            InputLabel={{
                              shrink: true,
                            }}
                            placeholder="Selecione"
                            isSearchable
                            isMulti
                            classNamePrefix="react-select"
                            className={classNames([
                              classes.selectSection,
                              classes.selectSectionAutoHeight,
                              classes.selectSectionCard,
                            ])}
                            onBlur={() => {
                              setFieldTouched('aditionalCourses');
                            }}
                            onChange={(values) =>
                              setFieldValue('aditionalCourses', values)
                            }
                            options={options.areas.additional_courses || []}
                            value={values.aditionalCourses}
                          />
                        </FormControl>
                        {errors.aditionalCourses && touched.aditionalCourses ? (
                          <FormHelperText className={classes.helperText}>
                            {errors.aditionalCourses}
                          </FormHelperText>
                        ) : null}
                      </ListItem>
                      {/* Market Tendencies */}
                      <ListItem className={classes.professionListItem}>
                        <FormControl
                          variant="filled"
                          fullWidth
                          className={classNames([
                            classes.coursesDropdownHolder,
                            classes.inlineGroup,
                            classes.alignVerticalCenter,
                          ])}
                        >
                          <InputLabel
                            htmlFor="marketTendencies"
                            shrink
                            error={
                              errors.marketTendencies &&
                              touched.marketTendencies
                            }
                          >
                            Tendências de Mercado
                          </InputLabel>
                          <SearchableSelect
                            name="marketTendencies"
                            id="marketTendencies"
                            InputLabel={{
                              shrink: true,
                            }}
                            placeholder="Selecione"
                            isSearchable
                            isMulti
                            classNamePrefix="react-select"
                            className={classNames([
                              classes.selectSection,
                              classes.selectSectionAutoHeight,
                              classes.selectSectionCard,
                            ])}
                            onBlur={() => {
                              setFieldTouched('marketTendencies');
                            }}
                            onChange={(values) => {
                              setFieldValue('marketTendencies', values);
                            }}
                            options={options.areas.market_tendencies || []}
                            value={values.marketTendencies}
                          />

                          <Tooltip
                            title="Adicionar Tendências de Mercado"
                            placement="right"
                            enterDelay={300}
                          >
                            <IconButton
                              arial-label="Adicionar Tendências de Mercado"
                              onClick={() => {
                                loadingState(true);
                                setCoursesValues(values);
                                addArea(
                                  'market_tendencies',
                                  courseId,
                                  true,
                                  true
                                );
                              }}
                            >
                              <Add />
                            </IconButton>
                          </Tooltip>
                        </FormControl>
                        {errors.marketTendencies && touched.marketTendencies ? (
                          <FormHelperText className={classes.helperText}>
                            {errors.marketTendencies}
                          </FormHelperText>
                        ) : null}
                      </ListItem>
                    </Fragment>
                  )}

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'start',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h3
                      style={{
                        alignSelf: 'flex-start',
                        color: '#009291',
                        fontWeight: 'bold',
                      }}
                    >
                      Dados do estágio
                    </h3>

                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <ListItem className={classes.professionListItem}>
                        <FastField
                          className={classes.inputField}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            FormLabelClasses: {
                              focused: classes.inputField,
                            },
                            shrink: values.max_daily_internship_workload,
                          }}
                          variant="filled"
                          name="max_daily_internship_workload"
                          id="max_daily_internship_workload"
                          label="Carga horária diária máxima"
                          placeholder="Digite aqui..."
                          onChange={handleChange}
                          onBlur={(event) => {
                            event.target.value = fillTime(
                              event.target.value,
                              setFieldValue,
                              'max_daily_internship_workload'
                            );
                            handleBlur(event);
                          }}
                          error={Boolean(
                            errors.max_daily_internship_workload &&
                              touched.max_daily_internship_workload
                          )}
                          onKeyUp={(event) => {
                            event.target.value = maskTime(
                              event,
                              event.target.value
                            );
                            event.target.value = maskTime(
                              event,
                              event.target.value
                            );
                          }}
                          value={values.max_daily_internship_workload}
                          component={TextField}
                          disabled={isSubmitting}
                        />
                        {errors.max_daily_internship_workload &&
                          touched.max_daily_internship_workload && (
                            <FormHelperText className={classes.helperText}>
                              {errors.max_daily_internship_workload}
                            </FormHelperText>
                          )}
                      </ListItem>

                      <ListItem className={classes.professionListItem}>
                        <FastField
                          className={classes.inputField}
                          InputProps={{
                            className: classes.input,
                          }}
                          InputLabelProps={{
                            FormLabelClasses: {
                              focused: classes.inputField,
                            },
                            shrink: values.max_weekly_internship_workload,
                          }}
                          variant="filled"
                          name="max_weekly_internship_workload"
                          id="max_weekly_internship_workload"
                          label="Carga horária semanal máxima"
                          placeholder="Digite aqui..."
                          onChange={handleChange}
                          onBlur={(event) => {
                            event.target.value = fillTime(
                              event.target.value,
                              setFieldValue,
                              'max_weekly_internship_workload'
                            );
                            handleBlur(event);
                          }}
                          error={Boolean(
                            errors.max_weekly_internship_workload &&
                              touched.max_weekly_internship_workload
                          )}
                          onKeyUp={(event) => {
                            event.target.value = maskTime(
                              event,
                              event.target.value
                            );
                          }}
                          value={values.max_weekly_internship_workload}
                          component={TextField}
                          disabled={isSubmitting}
                        />
                        {errors.max_weekly_internship_workload &&
                          touched.max_weekly_internship_workload && (
                            <FormHelperText className={classes.helperText}>
                              {errors.max_weekly_internship_workload}
                            </FormHelperText>
                          )}
                      </ListItem>
                    </div>
                  </div>

                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        className={classes.saveButton}
                        round
                      >
                        Salvar
                      </Button>
                    </div>
                  </ListItem>
                </List>
              </form>
            )}
          />
        )}
      </GridItem>
    </GridContainer>
  );
}

CourseForm.propTypes = {
  classes: object.isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  closeModal: func.isRequired,
  openModal: func.isRequired,
  addArea: func.isRequired,
  shouldReturnProfessionForm: bool,
  professionId: number,
  editProfession: func,
  addProfession: func,
  isEdit: bool,
  courseId: number,
  updateData: func,
};
export default withStyles(ProfessionalExperienceFormStyles)(CourseForm);
