import { useVisualizationContext } from '../../../Contexts/VisualizationContext';
import { H4Header } from '../styled';
import ReactHtmlParser from 'react-html-parser';

export default function AboutEventDay() {
  const { detailsObject } = useVisualizationContext();

  const { about_event_day } = detailsObject;

  return (
    <>
      <H4Header>Programação do evento</H4Header>

      <p>{ReactHtmlParser(about_event_day)}</p>
    </>
  );
}
