import { Container, Subtitle, WhiteBox } from '../../Styles/BaseComponent';
import ResponsiveTitle from '../../UIComponents/TitleResponsive';
import { WorkaCard } from '../../UIComponents/WorkaCard';

import dataCards from '../../../Utils/workaData/data';

import { ContainerSelfdevelopment, ListCaroussel } from './style';
import { ButtonsPagination } from 'views/StaffPages/Opportunities/Components/PublishedOpportunities/ButtonsPagination';
import {
  scrollRigthSelfDevelopment,
  scrollLeftSelfDevelopment,
} from '../../../Utils/scrollSelfDevelopment';
import { useContext } from 'react';

import { profileAdapter } from 'adapters/student/profileAdapter';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import BaseLayoutContext from 'contexts/base-layout';
import { CurriculumContext } from 'contexts/CurriculumContext';

export function SelfDevelopment({ history, academic_profile, count }) {
  const {
    profile,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateAvatarState,
    updateInterestState,
    isOpenCurriculumModal,
    closeCurriculumModal,
    handleShowSpecialNeeds,
    loadingCurriculum,
  } = useContext(CurriculumContext);

  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <ContainerSelfdevelopment>
      <Container selfDevelopment>
        <WhiteBox selfDevelopment>
          <ListCaroussel id="list-caroussel">
            {dataCards.map((item) => (
              <WorkaCard
                item={item}
                history={history}
                loadingCurriculum={loadingCurriculum}
              />
            ))}
          </ListCaroussel>
          <div className="container-pagination">
            <ButtonsPagination
              positionStart
              funcButtonLeft={scrollLeftSelfDevelopment}
              funcButtonRight={scrollRigthSelfDevelopment}
              universityColor={universityColor}
            />
          </div>
          {isOpenCurriculumModal &&
            mergeContextsHelper(
              <CurriculumModal
                personalityTestIsDone={profile?.quizzes?.personality.is_done}
                behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
                lifeInterestList={profile?.life_interests}
                interestList={profile?.interests}
                studentProfileState={profileAdapter(profile?.academic_profile)}
                updateBehavioralState={updateBehavioralState}
                updatePersonalityState={updatePersonalityState}
                updateProfileState={updateProfileState}
                updateInterestState={updateInterestState}
                setClose={closeCurriculumModal}
                isOpen={isOpenCurriculumModal}
                updateAvatarState={updateAvatarState}
                handleShowSpecialNeeds={handleShowSpecialNeeds}
              />
            )}
        </WhiteBox>
      </Container>
    </ContainerSelfdevelopment>
  );
}
