import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import FairAndEventsService from '../Services';

export const CoreHandlers = (
  visualizationContextInstance,
  registerModalContextInstance,
  visualizationStudentContextInstance
) => {
  const getDetails = async (eventId) => {
    const { setDetailsObject, detailsObject } = visualizationContextInstance;

    const detailEvent = await FairAndEventsService.getEventDetail(eventId);
    setDetailsObject(detailEvent);
    return detailEvent;
  };

  const handleClickEdit = async (eventId) => {
    const {
      setModalOpen,
      updateObjectStepsData,
    } = registerModalContextInstance;
    const {
      detailsModalOpen,
      setDetailsModalOpen,
    } = visualizationContextInstance;

    const detailEvent = await getDetails(eventId);

    updateObjectStepsData({ ...detailEvent, edit: true, id: eventId });
    setModalOpen(true);

    if (detailsModalOpen) {
      setDetailsModalOpen(false);
    }
  };

  const handleClickCheckIn = async (eventId) => {
    const { setStudentViewModalOpen } = visualizationContextInstance;
    await getDetails(eventId);
    setStudentViewModalOpen(true);
  };

  const handleClickUnpublish = async (eventId, feedback) => {
    const { getListings, setDetailsModalOpen } = visualizationContextInstance;

    const response = await FairAndEventsService.patchUnpublishEvent(
      eventId,
      feedback
    );
    setDetailsModalOpen(false);
    getListings();
  };

  const studentEventCertificate = async (studentUuid, eventId) => {
    const response = await FairAndEventsService.getStudentEventCertificate(
      studentUuid
    );
    return response;
  };

  const handleClickFinishEvent = async (eventId) => {
    const {
      setStudentViewModalOpen,
      setDetailsModalOpen,
    } = visualizationContextInstance;
    await getDetails(eventId);
    setDetailsModalOpen(false);
    setStudentViewModalOpen(true);
  };

  const handleFinishEvent = async (eventId, feedback) => {
    const {
      setStudentViewModalOpen,
      getListings,
      handleTabChange,
    } = visualizationContextInstance;
    const response = await FairAndEventsService.putFinishEvent(
      eventId,
      feedback
    );
    getListings();
    handleTabChange(_, 3);
    setStudentViewModalOpen(false);
  };

  const exportList = async (eventId) => {
    const { setModalInformationEmailSentOpen } = visualizationContextInstance;
    const response = await FairAndEventsService.getExportList(eventId);
    setModalInformationEmailSentOpen(true);
  };

  const scanQrCode = () => {
    const { setShowScanQrCodeModal } = visualizationStudentContextInstance;
    setShowScanQrCodeModal(true);
  };

  return {
    handleClickEdit,
    handleClickCheckIn,
    handleClickUnpublish,
    studentEventCertificate,
    handleFinishEvent,
    exportList,
    scanQrCode,
    handleClickFinishEvent,
    getDetails,
  };
};
