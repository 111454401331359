import { useContext, useState } from 'react';
import { Button, ButtonsContainer } from './CardContent/style';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';
import useMediaQuery from 'hooks/useMediaQuery';

export function GenericButtons({
  showEdit,
  showDelet,
  showEye,
  handleFuncEdit,
  handleFuncDelet,
  handleFuncVisibility,
  visible,
  disabled,
  shouldHideItem,
}) {
  const {
    FiEdit,
    IoTrashOutline,
    AiOutlineEye,
    AiOutlineEyeInvisible,
  } = wrapperIconsProfile();
  const { universityColor } = useContext(ProfileContext);
  const smMobile = useMediaQuery('(max-width: 386px)');

  return (
    <>
      {showEdit && (
        <Button disabled={shouldHideItem} onClick={() => handleFuncEdit()}>
          <FiEdit color={universityColor} size={smMobile ? 20 : 22} />
        </Button>
      )}

      {showDelet && (
        <Button disabled={shouldHideItem} onClick={() => handleFuncDelet()}>
          <IoTrashOutline color={universityColor} size={smMobile ? 20 : 22} />
        </Button>
      )}

      {showEye && (
        <Button
          disabled={disabled || shouldHideItem}
          onClick={() => handleFuncVisibility()}
        >
          {visible ? (
            <AiOutlineEye color={universityColor} size={smMobile ? 21 : 24} />
          ) : (
            <AiOutlineEyeInvisible
              color={universityColor}
              size={smMobile ? 21 : 24}
            />
          )}
        </Button>
      )}
    </>
  );
}
