import React, { Fragment, useContext, useState } from "react";
import {Form} from '@unform/web'

import {
  Wrapper, DescImg, MultiStepContainer, Img, Title, SubTitle, Choices,
  ChoicesTitle, SelectOptions, Text, ErrorMessage
} from '../../../../../../../components/Opportunities/styles/styleForm';

import pp from '../../../../../../../assets/Oportutinites/pp.svg';
import Group4 from '../../../../../../../assets/Oportutinites/Group4.svg';
import Group3 from '../../../../../../../assets/Oportutinites/Group3.svg';
import Group6 from '../../../../../../../assets/Oportutinites/Group6.svg';
import Group5 from '../../../../../../../assets/Oportutinites/Group5.svg';
import Group2 from '../../../../../../../assets/Oportutinites/Group2.svg';
import OportunidadesGroup from '../../../../../../../assets/img/OportunidadesGroup.svg';

import FooterForm from "../../../../../../../components/Opportunities/Footer";
import InputRadio from '../../../../../../../components/Opportunities/Inputs/InputRadio';
import StepIndicator from "../../../../../../../components/Opportunities/StepIndicator";
import SearchableSelectCompany from "../../../../../../../components/Opportunities/Select/SearchableSelect";

import { OpportunitiesCompanyContext } from "../../../../context/OpportunitiesCompanyContext";

import {
  selectOptionsCourses,
  selectOptionsProgram
} from '../../../../resources/SelectedOptionsCompany';

export default function StepOneCompany ({nextFormStep, prevsFormStep}) {

  const [courseCategory, setCourseCategory] = useState('')
  const [programCategory, setProgramCategory] = useState('')

  const [errorCategory, setErrorCategory] = useState(false)
  const [noSelect, setNoSelect] = useState(false);

  const { formData, setFormValues, selectOption, setSelectOption, stepOneRef } = useContext(OpportunitiesCompanyContext);

  const radioOptions = [
    {
      id: 'event',
      value: 'event',
      label: 'Eventos',
      imageOne: Group5,
      imageTwo: Group6,
    },
    {
      id: 'program',
      value: 'program',
      label: 'Programas',
      imageOne: Group2,
      imageTwo: Group3,
    },
    {
      id: 'course',
      value: 'course',
      label: 'Cursos',
      imageOne: Group4,
      imageTwo: pp,
    },
  ];

  const handleSubmitCompany = async(data) => {
    if (selectOption === '') {
      setNoSelect(true);
      return;
    }

    if (selectOption === 'course') {
      if (courseCategory === '') {
        setErrorCategory(true);
        return;
      }
    }

    if (selectOption === 'program') {
      if (programCategory === '') {
        setErrorCategory(true);
        return;
      }
    }

    if (data) {
      if (data.type !== '') {
        setFormValues(data);
        nextFormStep();
      }
    }
  }

  const handleFormSelect = (data) => {
    setSelectOption(data.target.value)
  }

  const handleSelectType = (data) => {
    setCourseCategory(data.value)
  }

  const handleSelectTypeProgram = (data) => {
    setProgramCategory(data.value);
  }

  return (
    <Form ref={stepOneRef} initialData={formData} onSubmit={handleSubmitCompany} >
      <MultiStepContainer>
        <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
      </MultiStepContainer>

    <Wrapper>
      <DescImg>
        <Img src={OportunidadesGroup} />
        <Title>
          Eventos e cursos <br />{' '}
          <SubTitle>Eventos, Cursos e Programas</SubTitle>
        </Title>
      </DescImg>

      <Choices>
        <ChoicesTitle>
          Selecione a <strong>oportunidade de aprendizagem</strong> que deseja cadastrar:
        </ChoicesTitle>
      </Choices>

      <InputRadio
        name="type"
        options={radioOptions}
        onChange={handleFormSelect}
        defaultValueBeta={selectOption}
      />
      {noSelect && selectOption === '' && (
        <ErrorMessage marginTop="10px">
          Para seguir em frente selecione uma oportunidade de aprendizagem
        </ErrorMessage>
      )}

      {selectOption === 'course' && (
        <Fragment>
          <Text>
            Agora selecione o <strong>tipo de curso</strong> *
          </Text>
          <SelectOptions>
            <SearchableSelectCompany
              name="course_category"
              options={selectOptionsCourses}
              onChange={handleSelectType}
            />

            {errorCategory && courseCategory === '' && (
              <ErrorMessage>
                Responda para seguir em frente
              </ErrorMessage>
            )}
          </SelectOptions>
        </Fragment>
      )}

      {selectOption === 'program' && (
        <Fragment>
          <Text>
            Agora selecione o <strong>tipo de programa </strong> *
          </Text>
          <SelectOptions>
            <SearchableSelectCompany
              name="category"
              options={selectOptionsProgram}
              onChange={handleSelectTypeProgram}
            />

            {errorCategory && programCategory === '' && (
              <ErrorMessage>
                Responda para seguir em frente
              </ErrorMessage>
            )}
          </SelectOptions>
        </Fragment>
      )}
      <FooterForm
        haveNext={true}
        haveBack={false}
        prevsState={prevsFormStep}
      />
    </Wrapper>
  </Form>
  );
}
