import styled from 'styled-components';
import ReactSelect from 'react-select';

export const ContainerSelect = styled.div`
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin: 8px 0 10px 0;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  div {
    display: flex;
    flex-direction: column;
  }
`;

export const InputIcon = styled.div`
  width: 45px;
  height: 45px;
  padding: 12.25px;
  border-radius: 2.3px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const InputLabel = styled.div`
  margin-top: 15px;
  color: #606062;
  font-weight: 400;
  font-size: 15px;
`;

export const InputSubLabel = styled.div`
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 10px;
`;

export const StyledReactSelect = styled(ReactSelect)`
  .css-bg1rzq-control.react-select__control {
    border: 1px solid ${(props) => (props.error ? '#FFCECE' : '#CDCDCD')} !important;
    background-color: ${(props) => (props.error ? '#FFEDED' : '')} !important;
  }
`;
