import { shape } from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import Chart from 'react-google-charts';
import {
  primaryColor,
  warningColor,
  dangerColor,
  dnaColor,
} from 'assets/jss/material-dashboard-pro-react';
import { Typography } from '@material-ui/core';
import { ChartLegends } from './styles';

export function ChartDetails({ question }) {
  const colors = [
    warningColor,
    dangerColor,
    '#1cb45e',
    '#54BBF9',
    '#FFFF00',
    dnaColor,
    primaryColor,
  ];
  const resultsSum = useMemo(() => {
    return question.data.reduce((total, current, index) => {
      if (index === 0) return null;
      return total + current[1];
    }, 0);
  }, [question]);
  return (
    <div>
      <h1
        style={{
          margin: '2rem 1.5rem 0',
          height: 'auto',
          lineHeight: 1.4,
          width: '100%',
        }}
      >
        {question.title}
      </h1>
      <Fragment>
        {resultsSum === 0 ? (
          <Typography
            align="center"
            style={{
              margin: '28px 0',
              color: '#bbb',
              fontSize: 19,
            }}
          >
            Sem dados para o gráfico
          </Typography>
        ) : (
          <Chart
            width="100%"
            height="450px"
            chartType="PieChart"
            data={question.data}
            options={{
              chartArea: { width: '50%' },
              title: '',
              colors,
              is3D: true,
              legend: { position: 'none' },
              tooltip: { trigger: 'none' },
            }}
          />
        )}
        <ChartLegends>
          {question.data.map((item, itemIndex) => {
            if (itemIndex === 0) {
              return null;
            }
            const itemPercentage =
              resultsSum === 0
                ? 0
                : Math.round((item[1] * 1000) / resultsSum) / 10;
            return (
              <div>
                <p
                  style={{
                    backgroundColor: colors[itemIndex - 1],
                  }}
                />
                <p>{`${item[0]} - ${item[1]} (${itemPercentage}%)`}</p>
              </div>
            );
          })}
        </ChartLegends>
      </Fragment>
    </div>
  );
}

ChartDetails.propTypes = {
  question: shape([]).isRequired,
};
