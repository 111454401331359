import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import JobHeaderTab, { Container, FilterContainer } from './style';
import { FilterJobUniversity } from '../Filter';

const TabJobCompany = (props) => {
  const {
    classes,
    activeTab,
    handleTabChange,
    tabs,
    filterOptions = {},
  } = props;
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const hasFilters = Object.keys(filterState).length > 0;

  return (
    <Container>
      <AppBar position="static" className={classes.appBarHolder}>
        {tabs && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            scrollButtons="on"
            scrollable
            classes={{
              indicator: classes.tabIndicator,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.borderContainer,
            }}
          >
            {tabs.map((tabName) => (
              <Tab
                style={{ zIndex: 2 }}
                key={tabName}
                label={tabName}
                className={classes.tabButton}
              />
            ))}
          </Tabs>
        )}
        <FilterContainer hasFilters={hasFilters}>
          <FilterJobUniversity
            activeTab={activeTab}
            filterOptions={filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
          />
        </FilterContainer>
      </AppBar>
    </Container>
  );
};

TabJobCompany.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};

export default withStyles(JobHeaderTab)(TabJobCompany);
