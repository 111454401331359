import React, { Fragment, useEffect, useRef, useState } from 'react';
import { getChallenges } from '../../../Challenges/services';
import ChallengesEngine from '../../../Challenges/ChallengesEngine';

export default function TabThree({
  setActiveTab,
}) {
  const [challenge, setChallenge] = useState(null);
  const [limit, setLimit] = useState(24);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const topDiv = useRef();

  const ScrollToTop = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView();
    }
  };

  const getAllChallenges = async (pages) => {
    const challenges = await getChallenges(pages);
    const { results, count } = challenges;
    setChallenge(results);
    setCount(count);
    setLoading(false);
  };

  const handleCardsChange = async (pages) => {
    const mathOffSet = (pages - 1) * limit || 0;
    const newCards = `limit=${limit}&offset=${mathOffSet}`;
    await getAllChallenges(newCards);
  };

  const loadMore = () => {
    setLimit(limit + 24);
    setLoading(true);
  };

  useEffect(() => {
    handleCardsChange(`limit=${limit}`);
  }, [limit]);

  return (
    <Fragment>
      <ChallengesEngine
        challenges={challenge}
        setActiveTab={setActiveTab}
        setChallenge={setChallenge}
        loadMore={loadMore}
        getAllChallenges={getAllChallenges}
        loading={loading}
        ScrollToTop={ScrollToTop}
        topDiv={topDiv}
        count={count}
      />
    </Fragment>
  );
}
