import React, { createContext, useContext, useEffect, useState } from 'react';
import { mentorshipApi } from '../services/api';

export const MentorshipContext = createContext({});

export function MentorshipContextProvider({ children }) {
  const [mentorshipCounts, setMentorshipCounts] = useState({});

  const fetchMentorshipsCount = async () => {
    const response = await mentorshipApi.getMentorshipsCount();
    setMentorshipCounts(response);
    return response;
  };

  useEffect(() => {
    fetchMentorshipsCount();
  }, []);

  const updateMentorshipsCount = (tab, value) => {
    setMentorshipCounts({ ...mentorshipCounts, [tab]: value });
  };

  return (
    <MentorshipContext.Provider
      value={{
        fetchMentorshipsCount,
        updateMentorshipsCount,
        mentorshipCounts,
        setMentorshipCounts,
      }}
    >
      {children}
    </MentorshipContext.Provider>
  );
}

export const useMentorship = () => useContext(MentorshipContext);
