import styled from 'styled-components';
import arrowLeft from 'assets/Oportutinites/Setaesquerda.svg';
import arrowRight from 'assets/Oportutinites/Setadireita.svg';

export const Container = styled.div`
  width: 100%;
  margin-top: -13px;
  display: flex;
  align-items: center;
  flex-direction: column;

  footer {
    display: flex;
    margin-top: 2rem;
    width: 90%;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: #ffffff;
  margin-top: 45px;
  border-radius: 10px;
`;

export const CloseButton = styled.button`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  border: none;
  border-radius: 50%;
  background-color: #efeeec;
  margin: 1rem 1rem 1rem auto;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const StepIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem 3rem;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

export const FinishProjectButton = styled.button`
  width: 146px;
  height: 40px;
  background-color: #009291;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  :disabled {
    display: none;

  }
`;
export const BackButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${arrowLeft});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  margin-right: auto;

  &:disabled {
    background-color: #c8c8c8;
  }
`;

export const NextButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${arrowRight});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  margin-left: auto;

  &:disabled {
    background-color: #c8c8c8;
  }
`;
