import styled, { keyframes } from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  overflow-y: auto;

  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: ${(props) => (props.width ? props.width + 'px' : '415px')};
  height: ${(props) => (props.height ? props.height + 'px' : '100%')};
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  margin-top: 130px;

  padding: 40px;

  @media (min-width: 1600px) {
    margin-top: 0px;
  }
`;

export const Box = styled.div`
  width: calc(100% - 40px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const WrapperStep = styled.div`
  display: flex;

  align-items: center;
  height: 20%;
  justify-content: 'space-between';
  gap: 10px;
  margin-bottom: 20px;
  width: ${(props) => (props.width ? props.width + '%' : '100%')};
`;

export const WrapperTitle = styled.div`
  display: flex;

  align-items: center;
  height: 20%;
  justify-content: ${(props) =>
    props.alignIcon ? props.alignIcon : 'flex-start'};
  width: ${(props) => (props.width ? props.width + '%' : '100%')};
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 1.5em;
  border-radius: 4px;
  position: relative;
  padding: 0px 20px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-top: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 21px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #ffff;
  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  width: ${(props) => (props.sizeIconPerson ? props.sizeIconPerson : '75px')};
  height: ${(props) => (props.sizeIconPerson ? props.sizeIconPerson : '75px')};
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 13px;
  font-weight: 400;
  font-size: 16px;
  color: #ffff;
`;

export const ParagraphStyled = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '20px')};
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gapParagraph ? props.gapParagraph + 'px' : '0px')};
  font-size: 17px;
  text-align: start;
  font-weight: 400;
`;

export const Button = styled.button`
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: ${(props) =>
    props.disabled
      ? '#f5f5f5'
      : props.universityColor
      ? props.universityColor
      : '#009291'};
  border: none;
  border-radius: 4px;
  color: ${(props) => (props.disabled ? '#606062' : '#ffff')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: 14px;
  margin-top: 20px;

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 375px) {
    width: 100%;
    order: 1;
  }
`;

export const ButtonWhite = styled.button`
  text-transform: uppercase;
  width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  border-radius: 4px;
  background-color: transparent;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 500;
  font-size: 14px;
  margin-top: 25px;

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }

  &:active {
    transform: scale(0.9);
  }

  @media (max-width: 375px) {
    width: 100%;
    order: 1;
  }
`;

export const ButtonX = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 200px;
  background-color: ${(props) => (props.color ? props.color : '#d9d9d9')};
  border: none;

  left: 30px;
  top: 23px;
`;

export const ContainerInformationList = styled.div`
  width: 310px;
`;

export const SpanInformationList = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  font-weight: 400;

  > div > svg {
    margin-bottom: -3px;
  }
`;

export const WrapperTips = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  text-align: start;
  flex-direction: column;
`;

export const BubbleTips = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #cdcdcd;
  top: 0;
`;

export const WrapperPhraseTips = styled.div`
  display: flex;
  margin-bottom: 8px;

  p {
    font-size: 17px;
    text-align: start;
    font-weight: 400;
    width: 100%;
    margin-left: 6px;
  }
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
  color: #3c4858;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
