import React, { useContext } from 'react';
import { object, func } from 'prop-types';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';
import { ButtonConfig } from '../Buttons';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { changePassword } from 'services/student/changePassword';
import AuthContext from 'contexts/authentication';

import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

const ChangePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required('Informe a senha atual.'),
  new_password: Yup.string()
    .required('Informe a nova senha.')
    .min(8, 'A nova senha deve ter no mínimo 8 caracteres.')
    .notOneOf(
      [Yup.ref('old_password'), null],
      'A nova senha não pode ser igual a senha atual.'
    ),
  confirm_password: Yup.string()
    .required('Confirme a nova senha.')
    .oneOf(
      [Yup.ref('new_password'), null],
      'A confirmação de senha não confere com a nova senha informada.'
    ),
});

function ChangePasswordForm(props) {
  const { classes, loadingState, openSnackbar, closeModal } = props;
  const { universityColor } = useContext(BaseLayoutContext);
  const auth = useContext(AuthContext);

  const handleSave = async (values, actions) => {
    loadingState(true);
    const defaultErrorMessage = 'Não foi possível alterar a senha.';
    const response = await changePassword(values);
    loadingState(false);
    if (response) {
      if (response.status == 400 && response.data[0] === 'Incorrect password') {
        actions.setFieldError('old_password', 'Senha atual incorreta.');
        return;
      } else if (response.status == 201) {
        openSnackbar('Senha alterada com sucesso. Efetue login novamente.');
        closeModal();
        setTimeout(() => {
          auth.routerOnUserLogout();
        }, 1500);
        return;
      }
    }
    openSnackbar(defaultErrorMessage, true);
  };

  return (
    <GridContainer justify="center">
      <GridItem>
        <Formik
          initialValues={{
            old_password: '',
            new_password: '',
            confirm_password: '',
          }}
          validationSchema={ChangePasswordSchema}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            actions.setSubmitting(true);
            await handleSave(values, actions);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <List className={classes.list}>
                {/* Old Password */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <FastField
                    autoFocus
                    className={
                      classes.inputField
                    }
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused:
                          classes.inputField,
                      },
                    }}
                    variant="filled"
                    name="old_password"
                    id="old_password"
                    label="Senha Atual"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.old_password && touched.old_password}
                    value={values.old_password}
                    component={TextField}
                    disabled={isSubmitting}
                    required
                  />
                  {errors.old_password && touched.old_password ? (
                    <FormHelperText className={classes.helperText}>
                      {errors.old_password}
                    </FormHelperText>
                  ) : null}
                </ListItem>
                {/* New Password */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <FastField
                    className={
                      classes.inputField
                    }
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused:
                          classes.inputField
                      },
                    }}
                    variant="filled"
                    name="new_password"
                    id="new_password"
                    label="Nova Senha"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.new_password && touched.new_password}
                    value={values.new_password}
                    component={TextField}
                    disabled={isSubmitting}
                    required
                  />
                  {errors.new_password && touched.new_password ? (
                    <FormHelperText className={classes.helperText}>
                      {errors.new_password}
                    </FormHelperText>
                  ) : null}
                </ListItem>
                {/* Confirm Password */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <FastField
                    className={
                      classes.inputField
                    }
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused:
                          classes.inputField
                      },
                    }}
                    variant="filled"
                    name="confirm_password"
                    id="confirm_password"
                    label="Confirmação de Senha"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.confirm_password && touched.confirm_password}
                    value={values.confirm_password}
                    component={TextField}
                    disabled={isSubmitting}
                    required
                  />
                  {errors.confirm_password && touched.confirm_password ? (
                    <FormHelperText className={classes.helperText}>
                      {errors.confirm_password}
                    </FormHelperText>
                  ) : null}
                </ListItem>
                <ListItem className={classNames([classes.professionListItem])}>
                  <div className={classes.buttonContainer}>
                    <ButtonConfig
                      disabled={isSubmitting}
                      universityColor={universityColor}
                      type="submit"
                      className={classes.saveButton}
                      round
                    >
                      Salvar
                    </ButtonConfig>
                  </div>
                </ListItem>
              </List>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
}

ChangePasswordForm.propTypes = {
  classes: object.isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  closeModal: func.isRequired,
};
export default withStyles(ProfessionalExperienceFormStyles)(ChangePasswordForm);
