import FilterModal from 'components/FilterModal';
import React, { useContext, useEffect, useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterContainer, FilterGrid } from './styles';
import { JobOfferContext } from '../../../../Contexts/JobOfferContext';
import { handleFieldMask } from 'utils/validation';
import { onlyNumbers } from 'utils/inputMasks';

export function FilterJobCompany({
  activeTab,
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
}) {
  const { setCurrentJobFilter } = useContext(JobOfferContext);
  const [coursesFilterOptions, setCoursesFilterOptions] = useState([]);

  useEffect(() => {
    setFilterState({});
    setAppliedFilters({});
  }, [activeTab]);

  useEffect(() => {
    if (filterOptions.courses) {
      const newListFilterCourses = [...filterOptions.courses];
      filterOptions.jobs_without_courses &&
        newListFilterCourses.unshift({
          label: 'Vagas sem indicação de curso',
          value: 0,
        });
      setCoursesFilterOptions(newListFilterCourses);
    }
  }, [filterOptions.courses]);

  const hasCandidatesOptions = [
    { label: 'Com candidaturas', value: true },
    { label: 'Sem candidaturas', value: false },
  ];

  function onApplyFilters() {
    setCurrentJobFilter(filterState);
  }

  function onClearFilters() {
    setCurrentJobFilter({});
  }

  function renderFilterContent() {
    return [
      <FilterGrid>
        <Filter
          name={'job_code'}
          label={'Código da vaga'}
          type={FilterType.TEXT}
          applyMask={true}
          mask={onlyNumbers}
          settings={{
            placeholder: 'Digite aqui...',
          }}
          maxLength={10}
        />
        <Filter name={'name'} label={'Nome da vaga'} type={FilterType.TEXT} />
        <Filter
          name={['start_date', 'end_date']}
          label={'Período de publicação'}
          type={FilterType.DATE_RANGE}
          settings={{
            placeholder: ['De', 'Até'],
          }}
          startYear={2019}
        />
        <Filter
          name={'courses'}
          label={'Curso'}
          type={FilterType.MULTI_SELECT}
          options={coursesFilterOptions}
        />
        <Filter
          name={'has_candidates'}
          label={'Candidaturas'}
          type={FilterType.RADIO}
          options={hasCandidatesOptions}
          settings={{
            isTwoColumns: true,
          }}
        />
        {filterOptions.status && (
          <Filter
            name={'status'}
            label={'Status da vaga'}
            type={FilterType.RADIO}
            options={filterOptions.status}
            settings={{
              isTwoColumns: true,
            }}
          />
        )}
      </FilterGrid>,
    ];
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
