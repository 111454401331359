import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { Container, UnInputLabel, Error, HintInformation } from './styled';
import ReactSelect from 'react-select';
import Tooltip from 'components/NewTooltip';
import lightenColor from 'utils/lightenColor';

export default function UnformSelect({
  label,
  name,
  options,
  placeholder,
  zIndex,
  onChange,
  width,
  margin,
  useHint,
  hintConfig,
  maxLimit,
  universityColor,
  labelWhite = false,
  ...rest
}) {
  const selectRef = useRef(null);
  const {
    fieldName,
    defaultValue,
    registerField,
    clearError,
    error,
  } = useField(name);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [disableOptions, setDisableOptions] = useState(false);

  const handleChange = (event) => {
    if (rest.isMulti && maxLimit) {
      setSelectedOptions(event);
    }
    onChange && onChange(event);
  };

  const verifyDisableOptions = () => {
    if (maxLimit) {
      setDisableOptions(selectedOptions.length >= maxLimit);
      return;
    }
    return false;
  };

  useEffect(() => {
    verifyDisableOptions();
  }, [selectedOptions]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value.value;
        }
      },
      setValue: (ref, value) => {
        if (value !== null && value !== undefined) {
          const currentOptions = ref.props.options;

          if (Array.isArray(value) && currentOptions?.length > 0) {
            const newArray = value.map((value) =>
              currentOptions.find(
                (option) =>
                  option.value === value || option.value === value.value
              )
            );
            return ref.onChange(newArray);
          }
          if (
            !Array.isArray(value) &&
            currentOptions &&
            currentOptions?.length > 0
          ) {
            const valueSingle = currentOptions.find(
              (option) =>
                String(option.value) === String(value.value) ||
                String(option.value) === String(value)
            );
            return ref.onChange(valueSingle);
          }
          ref.onChange(value);
        }
      },
    });
  }, [fieldName, registerField]);

  const controlLimit =
    maxLimit && disableOptions
      ? {
          pointerEvents: 'none',
          opacity: '0.6',
        }
      : {};

  const controlLimitMultiValue =
    maxLimit && disableOptions
      ? {
          pointerEvents: 'all',
          opacity: '1',
        }
      : {};

  const borderStyle = error
    ? { border: '1px solid #FFCECE', backgroundColor: '#FFEDED' }
    : {};
  const selectStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
    control: (base, _) => ({
      ...base,
      ...borderStyle,
      ...controlLimit,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: universityColor || '#009291',
    }),
    option: (base, state) => ({
      ...base,
      ...controlLimit,
      color:
        state.isSelected || (state.isFocused && universityColor && labelWhite)
          ? '#fff'
          : '#000',
    }),
    multiValue: (base) => ({
      ...base,
      ...controlLimitMultiValue,
    }),
  };

  function selectTheme(theme) {
    const primary25 = universityColor
      ? lightenColor(universityColor, 25)
      : '#9FD6D6';
    const primary50 = universityColor
      ? lightenColor(universityColor, 50)
      : '#80C9C8';
    const primary = universityColor ? universityColor : '#009291';

    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: primary25,
        primary50: primary50,
        primary: primary,
      },
    };
  }

  return (
    <Container
      error={error}
      zIndex={zIndex}
      className="inputContainer"
      width={width}
      margin={margin}
    >
      {label && (
        <UnInputLabel>
          {label}

          {useHint && (
            <Tooltip {...hintConfig}>
              <HintInformation>i</HintInformation>
            </Tooltip>
          )}
        </UnInputLabel>
      )}
      <ReactSelect
        defaultValue={
          defaultValue && Array.isArray(defaultValue)
            ? defaultValue.map(
                (item) =>
                  options &&
                  options.find(
                    (option) =>
                      option.value === item || option.value === item.value
                  )
              )
            : options &&
              options.find(
                (option) =>
                  String(option.value) === String(defaultValue) ||
                  (defaultValue &&
                    String(option.value) === String(defaultValue.value))
              )
        }
        theme={selectTheme}
        styles={selectStyles}
        onChange={handleChange}
        onBlur={clearError}
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        {...(rest.showNoOptionsMessage && {
          noOptionsMessage: ({ inputValue }) =>
            !inputValue
              ? 'Digite no mínimo 3 caracteres para buscar'
              : !rest.requestInProgress
              ? 'Sem resultados para a busca.'
              : 'Buscando',
        })}
        {...rest}
      />
      {error && <Error>{error}</Error>}
    </Container>
  );
}
