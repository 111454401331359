import { BaseContainer } from '../LoginConfig/style';
import { GenericInternalSwitch } from 'components/GenericInternalSwitch';
import { useState, useCallback } from 'react';
import InstitutionTab from './InstitutionTab';
import StudentTab from './StudentTab';

export function EngineProfileConfig({ setNoScroll }) {
  const [activeTabs, setActiveTabs] = useState(0);

  const Tabs = [
    {
      activeSwitch: 0,
      component: <InstitutionTab setNoScroll={setNoScroll} />,
    },
    {
      activeSwitch: 1,
      component: <StudentTab setNoScroll={setNoScroll} />,
    },
  ];

  const handleChangeTab = (value) => {
    setActiveTabs(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeSwitch === step);
    return returnTabs.component;
  };

  return (
    <BaseContainer>
      <GenericInternalSwitch
        activeTab={activeTabs}
        handleChange={handleChangeTab}
        tabs={[`Instituição`, `Estudante`]}
      />
      {renderPrincipalTabs(activeTabs)}
    </BaseContainer>
  );
}
