import React, { useEffect, useState } from "react";
import { array, object, number, func, bool, string } from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import dragDropTestStyle from "assets/jss/material-dashboard-pro-react/views/dragDropTestStyle";

import { Chip } from "@material-ui/core";
import Sortable from "react-sortablejs";

const DragDropTest = props => {
  const [positions, setPositions] = useState([]);
  const [chips, setChips] = useState([]);

  const {
    classes,
    testOptions,
    selectedOptions,
    index,
    updateSource,
    optionSelect,
    positionsCount,
    changeSourceEnable,
    destinationLabel
  } = props;

  useEffect(() => {
    const fillStates = async () => {
      setPositions(await definePositions());
      setChips(await defineChips());
    };

    fillStates();
  }, []);

  const definePositions = async () => {
    let newPositions = [...positions];

    for (let i = 1; i <= positionsCount; i++) {
      newPositions.push(<strong>{`${i}º`}</strong>);
    }

    return newPositions;
  };

  const defineChips = async () => {
    let newChips = [...chips];

    for (let i = 1; i <= positionsCount; i++) {
      newChips.push(
        <Chip
          label={`${i}º ${destinationLabel}`}
          className={classes.tempChip}
        />
      );
    }

    return newChips;
  };

  return (
    <div className="test">
      {testOptions[index].length > 0 && (
        <div className={classes.testHeader}>
          <h4>
            <strong>Características</strong>
          </h4>
          <Sortable
            options={{
              animation: 150,
              dragClass: classes.dragItem,
              fallbackOnBody: true,
              swapThreshold: 0.65,
              preventOnFilter: false,
              forceFallback: true,
              sort: false,
              group: {
                name: "options",
                pull: true,
                put: changeSourceEnable
              }
            }}
            onChange={(order, sortable, evt) => {
              const newTestOptions = [...testOptions];
              const newOptions = [...selectedOptions[index]];

              if (evt.type === "add" && evt.to.id === "source") {
                newTestOptions[index].splice(
                  evt.newIndex,
                  0,
                  selectedOptions[index][evt.oldIndex]
                );
                newOptions.splice(evt.oldIndex, 1);

                optionSelect(newOptions, index);
                updateSource(newTestOptions);
              }
            }}
            id="source"
            tag="div"
          >
            {testOptions[index].map(option => (
              <Chip
                key={option.id}
                label={option.option}
                data-id={option.id}
                className={classes.chipTestAnswer}
              />
            ))}
          </Sortable>
        </div>
      )}
      <div className={classes.testBody}>
        <h4>
          <strong>Suas características</strong>
        </h4>
        <div className={classes.bodyContainer}>
          <div className={classes.positions}>{positions}</div>
          <div className={classes.positionsList}>{chips}</div>
          <Sortable
            options={{
              animation: 150,
              dragClass: classes.dragItem,
              fallbackOnBody: true,
              swapThreshold: 0.65,
              preventOnFilter: false,
              forceFallback: true,
              group: {
                name: "options",
                pull: changeSourceEnable,
                put: to => {
                  return to.el.childElementCount < positionsCount;
                }
              }
            }}
            id="destination"
            onChange={(order, sortable, evt) => {
              const newTestOptions = [...testOptions];
              const newOptions = [...selectedOptions[index]];

              if (evt.type === "add" && evt.to.id === "destination") {
                newOptions.splice(
                  evt.newIndex,
                  0,
                  testOptions[index][evt.oldIndex]
                );
                newTestOptions[index].splice(evt.oldIndex, 1);

                optionSelect(newOptions, index);
                updateSource(newTestOptions);
              } else if (evt.type === "update") {
                const item = newOptions.splice(evt.oldIndex, 1);

                newOptions.splice(evt.newIndex, 0, item[0]);

                optionSelect(newOptions, index);
              }
            }}
            className={classes.selectedList}
            tag="div"
          >
            {selectedOptions[index] &&
              selectedOptions[index].map(option => (
                <Chip
                  key={option.id}
                  label={option.option}
                  data-id={option.id}
                  className={classes.chipTestAnswer}
                />
              ))}
          </Sortable>
        </div>
      </div>
    </div>
  );
};

DragDropTest.propTypes = {
  classes: object.isRequired,
  testOptions: array.isRequired,
  selectedOptions: array.isRequired,
  index: number,
  positionsCount: number,
  updateSource: func,
  optionSelect: func,
  changeSourceEnable: bool,
  destinationLabel: string
};

export default withStyles(dragDropTestStyle)(DragDropTest);
