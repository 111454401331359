import styled from 'styled-components';
import unselectedStar from 'assets/mentorship/small-unselected-star.svg';
import selectedStar from 'assets/mentorship/small-selected-star.svg';
import { device } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/ModalShowEvidence/utils/devicesQuery';

const backgroundColorMap = {
  canceled: '#FFEDED',
  not_realized: '#FFEDED',
  expired: '#EEF2F4',
  realized: '#D9FFE3',
  requested: '#FFFDED',
  rescheduled: '#0050961A',
  reschedule_request: '#0050961A',
  accepted: '#0050961A',
};

const textColorMap = {
  canceled: '#DA0505',
  not_realized: '#DA0505',
  expired: '#606062',
  realized: '#068E2C',
  requested: '#E9B600',
  rescheduled: '#005096',
  reschedule_request: '#005096',
  accepted: '#005096',
};

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const WrapperInformations = styled.div`
  max-width: 560px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #606062;

  > span {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #009291;
    margin-left: 5px;
  }

  > div {
    flex-direction: column;
  }
`;

export const TypeLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #eef2f4;
  height: 20px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  padding: 0 10px;

  color: #606062;
  width: fit-content;
  margin: 8px 0;
`;
export const StudentNotes = styled.div`
  font-family: Roboto, sans-serif;
  width: 100%;
  color: #606062;
  display: flex;
  flex-direction: column;

  > strong {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
    padding: 10px 0;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  > div > strong {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 20px;
  }
`;

export const GridSide = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 60px;

  @media screen and (max-width: 768px) {
    gap: 10px;
  }
`;

export const GridModality = styled.div`
  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #606062;

  .container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .text-wrapper strong {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }

  .text-wrapper p {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    margin-top: 4px;
  }

  .value-wrapper {
    margin-top: 5px;
  }

  .value-wrapper p {
    font-size: 16px;
    line-height: 0;
  }

  .value-wrapper strong {
    font-size: 16px;
    font-weight: 700;
  }

  .value-wrapper span {
    text-decoration: line-through;
  }
`;

export const TruncatedLink = styled.a`
  display: flex;
  color: #5578ac;
  text-decoration: none;
  flex-direction: row;
  word-break: break-word;
  white-space: normal;

  &:hover {
    text-decoration: underline;
  }
`;
export const IconWrapper = styled.div`
  margin-top: -10px;
`;

export const WrapperLink = styled.div`
  width: 100%;
  font-family: Roboto, sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.31px;
  margin-top: 10px;
  color: #606062;
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin: 2rem 0 2rem;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const RescheduleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  border-radius: 3px;
  color: #ffff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  background-color: ${(props) => props.color || '#009291'};

  &:hover {
    filter: brightness(0.9);
  }

  @media screen and (max-width: 767px) {
    order: 1;
  }
`;
export const ConfirmedButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 40px;
  border-radius: 3px;
  color: #ffff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  background-color: ${(props) => props.color || '#009291'};

  &:hover {
    filter: brightness(0.9);
  }

  @media screen and (max-width: 767px) {
    order: 1;
  }
`;
export const CanceledButton = styled.button`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: left;
  color: #da0505;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }

  @media screen and (max-width: 767px) {
    order: 2;
  }
`;

export const Flag = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px;
  gap: 9px;
  border-radius: 5px;
  margin: 20px 0;
  height: 40px;
  font-family: Roboto, sans-serif;
  font-size: 13px;
  font-weight: 700;
  text-align: left;

  background-color: ${({ mentoringStatus }) =>
    backgroundColorMap[mentoringStatus] || '#D9FFE3'};
  color: ${({ mentoringStatus }) => textColorMap[mentoringStatus] || '#068E2C'};

  white-space: nowrap;

  > p {
    margin: 0;
  }

  max-width: none;
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #606062;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
`;

export const StarIcon = styled.div`
  height: 19px;
  width: 19px;
  background-size: cover;
  background-image: url(${(props) =>
    props.selected ? selectedStar : unselectedStar});
  background-repeat: no-repeat;
  background-position: center;
`;

export const FileTile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;
  margin-bottom: 16px;

  p {
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  button {
    border: 1px solid transparent;
    background-color: transparent;
    > svg {
      cursor: pointer;
    }
  }

  @media ${device.mobileL} {
    height: 56px;
  }
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;

  > p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const ActionButton = styled.button`
  cursor: pointer;
  width: 22px;
  height: 22px;
  padding: 0;

  > img {
    width: 100%;
    height: 100%;
  }
`;
