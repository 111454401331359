const defineApiUrl = () => {
  const { hostname } = window.location;
  const domain = hostname.match(/netlify\.com/g);
  const location = window.location;

  if (hostname === 'localhost') {
    return 'http://localhost:8000';
  }
  if (hostname.includes('worka.local')) {
    return 'http://worka.local';
  }
  if (hostname === 'bs-local.com') {
    return 'http://bs-local.com:8000';
  }
  if (domain) {
    return 'https://retencao-staging.dnadaeducacao.com.br';
  }
  if (
    location.protocol !== 'https:' &&
    !location.origin.includes('workability') &&
    location.port === '3000'
  ) {
    const ip = location.origin.split(':')[1];
    return `http:${ip}:8000`;
  }
  if (
    (location.protocol == 'https:' && hostname.includes('-dev')) ||
    hostname.includes('.dev')
  ) {
    return `https://workability.dev.worka.love`;
  }
  if (
    (location.protocol == 'https:' && hostname.includes('-stg')) ||
    hostname.includes('.stg')
  ) {
    return `https://workability-stg.worka.love`;
  }
  if (
    (location.protocol == 'https:' && hostname.includes('-hmg')) ||
    hostname.includes('.hmg')
  ) {
    return `https://workability.hmg.worka.love`;
  }
  if (location.protocol == 'https:' && !hostname.includes('workability')) {
    return `https://workability.worka.love`;
  }

  return window.location.origin;
};

export default defineApiUrl;
