import {
  IModalConfigExternalJobEmail,
  IModalConfigExternalJobLink,
} from 'views/JobOffers/components/Card/useCases/IModalConfig';
import { currency } from './selectOptionsDetails';
import CoverLetterForm from 'views/Student/Docs/CoverLetter/CoverLetterForm';

export const renderLabelCurrency = (salaryCoin) => {
  if (salaryCoin) {
    const foundCurrency = currency.find(
      (item) => item.value === salaryCoin.value
    );
    if (foundCurrency) {
      return foundCurrency.previewText;
    }
  }
};

export const formatQuantity = (job) => {
  if (!job) return '';
  return Number(job?.jobsOpenings) <= 1
    ? `${job.jobsOpenings} vaga`
    : `${job.jobsOpenings} vagas`;
};

export const dialogAlertJobOffers = (
  job,
  setModalConfig,
  setExternalModalOpen
) => {
  job.divulgationLink
    ? setModalConfig(IModalConfigExternalJobLink)
    : setModalConfig(IModalConfigExternalJobEmail);
  setExternalModalOpen(true);
};

export const dialogConfirmApplication = (
  hasCoverLetter,
  setConfirmApplication,
  setConfigCoverLetter
) => {
  hasCoverLetter ? setConfirmApplication(true) : setConfigCoverLetter(true);
};

export const openCoverLetterForm = (
  openSnackbar,
  closeModal,
  handleIsLoadingState,
  updateHasCoverLetter,
  openModalWithContent,
  universityColor
) => {
  openModalWithContent(
    <CoverLetterForm
      openSnackbar={openSnackbar}
      closeModal={closeModal}
      handleLoadingState={handleIsLoadingState}
      updateHasCoverLetter={updateHasCoverLetter}
      openModalWithContent={openModalWithContent}
      universityColor={universityColor}
    />
  );
};

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const renderList = (items) => {
  if (items && items.length) {
    return (
      <ul>
        {items.map((item) => (
          <li> {capitalizeFirstLetter(item.label)} </li>
        ))}
      </ul>
    );
  }

  return (
    <ul>
      <li> Não informado </li>
    </ul>
  );
};
