import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { bool, func, number } from 'prop-types';
import BaseLayoutContext from 'contexts/base-layout';
import {
  Modal,
  Text,
  Button,
  ContainerButtons,
  ButtonCloseModal,
  ContainerCloseModal,
  ContainerTexts,
} from './styled';

const CancelApplicationDialog = ({
  isOpen,
  setClose,
  id,
  deleteAplication,
}) => {
  const { closeModal, universityColor } = useContext(BaseLayoutContext);
  return (
    <Dialog open={isOpen} onBackdropClick={() => setClose()}>
      <Modal>
        <ContainerCloseModal isCloseModal>
          <ButtonCloseModal
            onClick={() => {
              setClose();
            }}
          >
            x
          </ButtonCloseModal>
        </ContainerCloseModal>

        <ContainerTexts>
          <Text universityColor={universityColor} isTitleModal>
            Cancelar candidatura
          </Text>
          <Text>Tem certeza que deseja cancelar candidatura à vaga?</Text>
        </ContainerTexts>

        <ContainerButtons>
          <Button
            universityColor={universityColor}
            isCancel
            onClick={() => {
              deleteAplication(id);
              setClose();
              closeModal();
            }}
          >
            Sim, cancelar candidatura.
          </Button>

          <Button
            universityColor={universityColor}
            onClick={() => {
              setClose();
            }}
          >
            Não, voltar para vagas.
          </Button>
        </ContainerButtons>
      </Modal>
    </Dialog>
  );
};

CancelApplicationDialog.propTypes = {
  isOpen: bool,
  setClose: func,
  id: number,
  deleteAplication: func,
};

export default CancelApplicationDialog;
