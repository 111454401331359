import { useContext, useState, useEffect, useRef, useCallback } from 'react';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';

import DisconnectModal from '../UIComponents/Modals/DisconnectModal';
import GenericAccordion from '../UIComponents/GenericAccordion';
import SelectAllCheckBox from '../UIComponents/SelectAllCheckBox';
import { AccordionContent } from '../UIComponents/AccordionContent';
import AccordionSkelleton from '../UIComponents/AccordionSkelleton';
import CompanyProfileModal from '../UIComponents/Modals/CompanyProfileModal';
import { FilterCompaniesManagement } from '../UIComponents/FilterCompaniesManagement';
import DocumentsUploadModal from '../UIComponents/Modals/DocumentsUploadModal';

import {
  Container,
  Title,
  Subtitle,
  WhiteBox,
  FilterContainer,
  HeaderTab,
  DisconnectButton,
  Header,
  SearchInput,
  ContainerSearch,
  WrapperSearch,
} from '../Styles/BaseComponent';

import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';

import Pagination from 'components/CustomPagination';
import GenericMessage from 'components/GenericMessage';
import ModalUploadEvidences from 'components/UploadEvidences';

import { renderItemsAccordion } from 'views/StaffPages/CompaniesManagement/Utils/renderItemsAccordion';
import {
  deleteDocument,
  getCitiesOptions,
  getConnectedCompaniesFilters,
  postDocumentOneCreateFile,
  postDocumentTwoCreateFile,
} from '../../Services';
import VacanciesModal from '../UIComponents/Modals/VacanciesModal';
import { IoMdSearch } from 'react-icons/io';
import { useDebounce } from 'hooks/useDebounce';
import { genericMessage } from '../../constants/genericMessage';
import { maskCnpj } from 'utils/inputMasks';
import { handleFieldMask } from 'utils/validation';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';

export default function ConnectedCompanies({
  activeTab,
  getCountTabs,
  setCountConnectedCompanies,
}) {
  const { getConnectedCompanies } = useAPICalls();
  const {
    listConnectedCompanies,
    setListConnectedCompanies,
    usingModalUpload,
    setUsingModalUpload,
    idPostDocument,
    setLoadingFilter,
    companiesConnectCount,
  } = useContext(CompaniesManagementContext);

  const {
    returnStatus,
    returnIcons,
    returnIconSessions,
  } = renderItemsAccordion();

  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const [openModalDisconnect, setOpenModalDisconnect] = useState(false);
  const [openModalViewStageData, setOpenModalViewStageData] = useState(false);
  const [
    openModalConnectedCompanies,
    setOpenModalConnectedCompanies,
  ] = useState(false);

  const [filterState, setFilterState] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [isFetchingCities, setIsFetchingCities] = useState(false);

  const [queryFilter, setQueryFilter] = useState(null);

  const [disconnectOne, setDisconnectOne] = useState(false);

  const [id, setId] = useState(null);
  const [details, setDetails] = useState(null);

  const [documents, setDocuments] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  const [openVacanciesModal, setOpenVacanciesModal] = useState(false);
  const [selectedCompanyVacancies, setSelectedCompanyVacancies] = useState(
    null
  );

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearch) {
      getConnectedCompanies(
        `search=${debouncedSearch} ${queryFilter ? `&${queryFilter}` : ''}`
      );
    }
    if (debouncedSearch === '') {
      getConnectedCompanies(queryFilter);
    }
  }, [debouncedSearch]);

  const toggleVacanciesModal = useCallback(
    (data) => {
      setOpenVacanciesModal((prevState) => !prevState);
      setSelectedCompanyVacancies(data);
    },
    [openVacanciesModal, setSelectedCompanyVacancies]
  );

  const connectedCompaniesDiv = useRef();

  useEffect(() => {
    setSelectedCompanies([]);
  }, [currentPage]);

  useEffect(() => {
    if (activeTab === 0) {
      setLoadingFilter(true);
      setListConnectedCompanies(null);
      setCurrentPage(1);

      getConnectedCompaniesFilters().then((data) => {
        setFilterOptions(data);
        setLoadingFilter(false);
      });
    }
  }, [activeTab]);

  async function handleChangeStates(states) {
    if (states && states.length > 0) {
      setIsFetchingCities(true);
      const data = await getCitiesOptions(states, 'connected');

      if (filterState['cities']) {
        const newFilterState = { ...filterState };
        const responseCitiesValues = data.cities.map((city) => city.value);
        const newCities = newFilterState.cities.filter((city) =>
          responseCitiesValues.includes(city)
        );
        if (newCities.length > 0) {
          newFilterState.cities = newCities;
        } else {
          delete newFilterState.cities;
        }
        newFilterState.states = states;
        setFilterState(newFilterState);
      }
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          cities: data.cities,
        };
      });
      setIsFetchingCities(false);
    } else {
      setFilterState((prevState) => {
        const newFilterState = { ...prevState };
        delete newFilterState.states;
        delete newFilterState.cities;
        return newFilterState;
      });
    }
  }

  const handlePageChange = async (page) => {
    if (queryFilter === null) {
      const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
      setListConnectedCompanies(null);
      await getConnectedCompanies(newPage);
    }

    if (queryFilter !== null) {
      const query = `${queryFilter}&limit=${limit}&offset=${
        (page - 1) * limit || 0
      }`;
      setListConnectedCompanies(null);
      await getConnectedCompanies(query);
    }
  };

  const onPageChange = (page) => {
    scrollToTopDiv();
    setCurrentPage(page);
    handlePageChange(page);
  };

  const greenRightButtonFunc = (id) => {
    setOpenModalDisconnect(true);
    setId(id);
    setDisconnectOne(true);
  };

  const refreshLimit = (page) => {
    handlePageChange(page);
  };

  useEffect(() => {
    refreshLimit(currentPage);
  }, [limit]);

  const scrollToTopDiv = () => {
    if (connectedCompaniesDiv) {
      connectedCompaniesDiv.current.scrollIntoView();
    }
  };

  const isAllCompaniesSelected =
    selectedCompanies?.map((s) => s.id).length ===
    listConnectedCompanies?.map((s) => s.id).length;

  function toggleSelectAll() {
    if (selectedCompanies?.length === listConnectedCompanies?.length) {
      setSelectedCompanies([]);
    } else {
      setSelectedCompanies(listConnectedCompanies.map((s) => s.id));
    }
  }

  const DisconnectAll = () => {
    setOpenModalDisconnect(true);
    setId(id);
  };

  const closeModalUpload = () => {
    setUsingModalUpload(false);
  };

  const renderItems = () => {
    if (listConnectedCompanies?.length === 0) {
      return (
        <>
          <GenericMessage
            title={
              search.length > 0
                ? genericMessage.connectedCompaniesSearch.title
                : genericMessage.connectedCompanies.title
            }
            subtitle={
              search.length > 0
                ? genericMessage.connectedCompaniesSearch.subtitle
                : genericMessage.connectedCompanies.subtitle
            }
          />
          {search.length > 0 && (
            <WrapperSearch>
              <ContainerSearch>
                <SearchInput
                  value={search}
                  placeholder="Buscar por empresa/CNPJ"
                  onChange={(event) => {
                    setSearch(event.target.value);
                  }}
                  onKeyUp={(event) => {
                    const hasLetters = /[a-zA-Z]/.test(event.target.value);
                    if (hasLetters) {
                      return;
                    } else {
                      handleFieldMask(event, maskCnpj);
                    }
                  }}
                />
                <div className="search-icon">
                  <IoMdSearch size={20} color="#fff" />
                </div>
              </ContainerSearch>
            </WrapperSearch>
          )}
        </>
      );
    }

    if (listConnectedCompanies === null) {
      return Array(24)
        .fill(1)
        .map((_, index) => <AccordionSkelleton />);
    }

    if (listConnectedCompanies?.length > 0) {
      return listConnectedCompanies?.map((companies) => (
        <GenericAccordion
          companyId={companies.id}
          data={companies}
          checkbox={true}
          stateSelectedCheckbox={selectedCompanies}
          funcSetSelectedCheckbox={setSelectedCompanies}
          greenRightButton={true}
          grayRightButton={false}
          greenRightButtonLabel={'Desconectar'}
          flagStatus={true}
          textStatus={returnStatus}
          iconStatus={returnIcons}
          colorStatus={companies?.agreement_status}
          textTitle={companies?.legal_name || 'Não informado'}
          textSubtitle={`${companies?.organization_number_type}:`}
          contentSubtitle={companies?.organization_number}
          iconSession={() =>
            returnIconSessions(companies, true, toggleVacanciesModal)
          }
          secondCTA={true}
          labelSecondCTA={
            companies?.documents_quantity === 0
              ? 'Adicionar documentos de estágio'
              : `Visualizar documentos de estágio (${companies.documents_quantity})`
          }
          greenRightButtonFunc={() => greenRightButtonFunc(companies?.id)}
          setDocuments={setDocuments}
          getCountTabs={getCountTabs}
          disableButton={selectedCompanies?.length > 1}
          connectedCompanies={true}
          setId={setId}
        >
          <AccordionContent
            companies={companies}
            setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
            buttonCompanyProfileDown={true}
            setDetails={setDetails}
            setDocuments={setDocuments}
            responsibleForTheInvitation={true}
            responsibleForTheReconnect={companies?.is_reconnected}
            setSelectedCompanyId={setSelectedCompanyId}
          />
        </GenericAccordion>
      ));
    }
  };

  return (
    <Container>
      <Title>Empresas conectadas</Title>
      <Header padRight={Object.keys(filterState).length > 0}>
        <Subtitle>
          Confira aqui as empresas nas quais você possui conexão e sua origem.
          Ao estar conectado com uma empresa, seus estudantes poderão ter acesso
          as vagas cadastradas por elas.
        </Subtitle>
        <FilterContainer>
          <FilterCompaniesManagement
            activeTab={activeTab}
            filterOptions={filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            setQueryFilter={setQueryFilter}
            connect={true}
            setLimit={setLimit}
            handleChangeStates={handleChangeStates}
            isFetchingCities={isFetchingCities}
            loading={listConnectedCompanies === null}
            getCountTabs={getCountTabs}
            setCountConnectedCompanies={setCountConnectedCompanies}
            limit={limit}
            setCurrentPage={setCurrentPage}
            setSearch={setSearch}
          />
        </FilterContainer>
      </Header>
      <WhiteBox ref={connectedCompaniesDiv}>
        {listConnectedCompanies?.length !== 0 && (
          <HeaderTab>
            <div className="header-tab-left">
              <SelectAllCheckBox
                type="checkbox"
                id="checkAll"
                checked={isAllCompaniesSelected}
                onChange={toggleSelectAll}
                disabled={listConnectedCompanies === null}
                buttonLabel={'Selecionar tudo'}
              />
              {selectedCompanies?.length > 0 && (
                <DisconnectButton onClick={() => DisconnectAll()}>
                  Desconectar de {selectedCompanies.length}{' '}
                  {selectedCompanies.length > 1 ? 'empresas' : 'empresa'}
                </DisconnectButton>
              )}
            </div>
            <SearchInput
              value={search}
              placeholder="Buscar por empresa/CNPJ"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              onKeyUp={(event) => {
                const hasLetters = /[a-zA-Z]/.test(event.target.value);
                if (hasLetters) {
                  return;
                } else {
                  handleFieldMask(event, maskCnpj);
                }
              }}
            />
            <div className="search-icon">
              <IoMdSearch size={20} color="#fff" />
            </div>
          </HeaderTab>
        )}
        {renderItems()}
      </WhiteBox>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={companiesConnectCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
        paginationItensValue={[20, 50, 100, 500]}
      />
      {usingModalUpload && (
        <DocumentsUploadModal
          companyId={idPostDocument}
          closeModal={closeModalUpload}
          handlePageChange={handlePageChange}
          selectedCompanyId={selectedCompanyId}
          setSelectedCompanyId={setSelectedCompanyId}
        />
      )}
      {openModalConnectedCompanies && (
        <CompanyProfileModal
          isOpen={openModalConnectedCompanies}
          details={details}
          documents={documents}
          setDocuments={setDocuments}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          setOpenModalViewStageData={setOpenModalViewStageData}
          openModalViewStageData={openModalViewStageData}
          openModalUpload={setUsingModalUpload}
          setSelectedCompanyId={setSelectedCompanyId}
        />
      )}
      {openModalDisconnect && (
        <DisconnectModal
          isOpen={openModalDisconnect}
          disconnectAll={selectedCompanies.length > 0}
          setSelectedCompanies={setSelectedCompanies}
          selectedCompanies={selectedCompanies}
          setOpenModalDisconnect={setOpenModalDisconnect}
          setFilterState={setFilterState}
          id={id}
          disconnectOne={disconnectOne}
          getCountTabs={getCountTabs}
          setDisconnectOne={setDisconnectOne}
          limit={limit}
          currentPage={currentPage}
          setFilterOptions={setFilterOptions}
        />
      )}
      {openVacanciesModal && (
        <VacanciesModal
          isOpen={openVacanciesModal}
          closeModal={() => setOpenVacanciesModal(false)}
          selectedCompanyVacancies={selectedCompanyVacancies}
        />
      )}
    </Container>
  );
}
