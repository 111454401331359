import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useField } from '@unform/core';
import {
  Container,
  DropArea,
  ActionButton,
  SelectedWrapper,
  LabelContent,
  ActionsWrapper,
} from './styled';
import BaseLayoutContext from 'contexts/base-layout';
import { BsUpload } from 'react-icons/bs';

import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';
import PDFIcon from 'assets/ComponentIcons/PDFIcon';
import eye from 'assets/JobOffer/eye.svg';
import PdfViwer from 'components/UploadEvidences/components/PdfViwer';
import DisplayPdf from 'components/DisplayDocument/DisplayDocument';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

function InputFile({ name, setHasFile, ...rest }) {
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const { universityColor, openModalWithContent } =
    useContext(BaseLayoutContext);
  const { fieldName, registerField, defaultValue, clearError } = useField(name);
  const [selectedFile, setSelectedFile] = useState(defaultValue);

  const maxFileSize = 10485760; // 10MB in bytes

  const handleSelect = useCallback((e) => {
    const file = e.target.files?.[0];
    if (!file) {
      setSelectedFile(null);
      setHasFile(false);
      return;
    }
    if (file.type !== 'application/pdf') {
      handleDelete();
      setHasFile(false);
      return;
    }

    if (file.size > maxFileSize) {
      customSnackbar('O arquivo não pode ser maior do que 10MB.', 'error');
      return;
    }

    setSelectedFile(file);
    setHasFile(true);
  }, []);

  const handleDelete = useCallback(() => {
    clearError();
    inputRef.current.value = '';
    setSelectedFile(null);
    setHasFile(false);
  }, [clearError]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue(ref) {
        ref.value = '';
        setSelectedFile(null);
      },
      setValue(_, value) {
        setSelectedFile(value);
      },
    });
  }, [fieldName, registerField]);

  const labelMaxWidth = useMemo(() => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    return `${containerWidth - 120}px`;
  }, [selectedFile]);

  const handleSeeDocument = () => {
    openModalWithContent(
      <DisplayPdf
        file={selectedFile}
        style={{
          margin: '0 auto',
          width: '80%',
          height: '90vh',
        }}
      />,
      null,
      null,
      () => {},
      false,
      null,
      true
    );
  };

  return (
    <Container ref={containerRef}>
      <h5>Documento</h5>
      {selectedFile && (
        <SelectedWrapper>
          <LabelContent labelMaxWidth={labelMaxWidth}>
            <PDFIcon color={universityColor || '#009291'} size={22} />
            <p title={selectedFile.name}>{selectedFile.name}</p>
          </LabelContent>
          <ActionsWrapper>
            <ActionButton onClick={handleSeeDocument} type={'button'}>
              <img src={eye} alt="Ver documento" />
            </ActionButton>
            <ActionButton onClick={handleDelete} type={'button'}>
              <DefaultTrash color={universityColor || '#009291'} size={18} />
            </ActionButton>
          </ActionsWrapper>
        </SelectedWrapper>
      )}

      <DropArea universityColor={universityColor} selectedFile={selectedFile}>
        <input
          type="file"
          ref={inputRef}
          accept="application/pdf"
          onChange={handleSelect}
          {...rest}
        />
        <div>
          <div className="circle-area">
            <BsUpload color="#fff" size={28} />
          </div>
          <p>
            <strong>Clique aqui</strong> para selecionar um arquivo ou{' '}
            <strong>arraste-o</strong> para esta área. (Apenas arquivos{' '}
            <strong>PDF</strong> serão aceitos.)
          </p>
        </div>
      </DropArea>
    </Container>
  );
}

export default InputFile;
