import React, { useContext, useEffect, useState, useRef } from 'react';

import {
  WrapperMoreInfo,
  WrapperHeader,
  ImgHeader,
  WrapperContent,
  WrapperStatus,
  StarActivityImg,
  DivStar,
  Wrapper,
  WrapperStatusPublished,
  ReactionWrapper,
  Reaction,
  WrapperTitles,
  Title,
  SubTitle,
  WrapperInformartions,
  UnpublishButton,
  ContainerButtons,
  EditButton,
  Container,
  GridSide,
  ItemWrapper,
  BottomCards,
} from './styled';

import Alvo from '../../../../../../../assets/Oportutinites/Alvo.svg';
import liked from '../../../../../../../assets/Oportutinites/liked.svg';
import event from '../../../../../../../assets/Oportutinites/event.svg';
import chapeu from '../../../../../../../assets/Oportutinites/chapeu.svg';
import person from '../../../../../../../assets/Oportutinites/person.svg';
import checked from '../../../../../../../assets/Oportutinites/checked.svg';
import Group10 from '../../../../../../../assets/Oportutinites/Group10.svg';
import expired from '../../../../../../../assets/Oportutinites/expired.svg';
import deleted from '../../../../../../../assets/Oportutinites/deleted.svg';
import deslike from '../../../../../../../assets/Oportutinites/deslike.svg';
import Calendar from '../../../../../../../assets/Oportutinites/Calendar.svg';
import linkBlack from '../../../../../../../assets/Oportutinites/linkBlack.svg';
import blackStar from '../../../../../../../assets/Oportutinites/blackStar.svg';
import GreenStar from '../../../../../../../assets/Oportutinites/GreenStar.svg';
import published from '../../../../../../../assets/Oportutinites/published.svg';
import blackTrash from '../../../../../../../assets/Oportutinites/blackTrash.svg';
import InformationModal from '../../../InformationModal';

import ReactHtmlParser from 'react-html-parser';

import { ModalX } from '../../../../../../../components/Opportunities/styles/defaultStyled';
import moment from 'moment';
import {
  getAnnouncements,
  getArticles,
  getChallenges,
  getCourses,
  getEbooks,
  getEvents,
  getPrograms,
  getSeries,
  getTrails,
  unPublishAttraction,
} from '../../../../../../../services/company/events';

import ConfirmDespublish from './unPublish';

import Modal from '../../../Modal';
import LoadingModal from '../../../../../../../components/Opportunities/LoadingModal';
import { OpportunitiesCompanyContext } from '../../../../context/OpportunitiesCompanyContext';

export default function MoreInformationModal({
  isOpen,
  children,
  setModalOpen,
  item,
  setItem,
  handleClickEdit,
  SetmacroModalOpen,
}) {
  const setState = () => {
    if (setItem) {
      setItem([]);
    }
    setModalOpen(false);
  };

  const {
    setEvents,
    setCourses,
    setPrograms,
    setCommunicate,
    setTrails,
    setEbooks,
    setArticle,
    setChallenge,
    setSerie,
    setModalIsOpen,
    setStep,
    setModalContent,
    setOptionsId,
    setSelectOption,
    setFormData,
    stepOneRef,
  } = useContext(OpportunitiesCompanyContext);

  useEffect(() => {
    if (isOpen === false) {
      stepOneRef.current && stepOneRef.current.reset();
      setFormData({});
      setSelectOption('');
      setOptionsId('');
      setStep(0);
      setModalIsOpen(false);
      setModalContent(null);
    }
  }, [isOpen]);

  const [modalOpen, setOpen] = useState(false);
  const [modalSucess, setModalSucess] = useState(false);
  const [objectModalSucess, setObjectModalSucess] = useState({
    header: 'Tudo certo!',
    msg: 'Oportunidade despublicada',
    icon: 'success',
  });
  const [loadingModal, setLoadingModal] = useState(false);

  const returnImageStatus = (item) => {
    if (item.is_published === false) {
      return deleted;
    }
    if (item.is_published === true && item.status === null) {
      return published;
    }
    if (item.status === 'Expirada') {
      return expired;
    }

    return published;
  };

  const titleEvent = {
    event: 'Tipo de evento:',
    program: 'Tipo de programa:',
    course: 'Tipo de curso:',
  };

  const returnSubTitleEvent = () => {
    if (item.type === 'event') {
      return item.modality === 'online' ? 'Online' : 'Presencial';
    }
    if (item.type === 'program') {
      if (item.category && item.category.label) {
        return item.category && item.category.label;
      }
      return 'Não informado';
    }
    if (item.type === 'course') {
      if (item.course_category && item.course_category.label) {
        return item.course_category && item.course_category.label;
      }
      return 'Não informado';
    }
    return 'Não informado';
  };

  const publicationFormat = {
    access_link: 'Link de acesso',
    video: 'Vídeos sequenciais',
    attraction_document: 'Arquivo PDF',
  };

  const returnTitle = {
    event: true,
    program: true,
    course: true,
  };

  const modalRefOpen = useRef();
  const modalCenter = useRef();

  const ScrollPosition = () => {
    if (modalRefOpen) {
      modalRefOpen.current.scrollIntoView({ alignToTop: true });
    }
  };

  const closeModal = (e) => {
    if (modalCenter.current === e.target) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen === true) {
      ScrollPosition();
    }
  }, [isOpen]);

  const findCurrentReload = [
    {
      type: 'event',
      getOpportunitie: getEvents,
      setOpportunitie: setEvents,
    },
    {
      type: 'program',
      getOpportunitie: getPrograms,
      setOpportunitie: setPrograms,
    },
    {
      type: 'course',
      getOpportunitie: getCourses,
      setOpportunitie: setCourses,
    },
    {
      type: 'article',
      getOpportunitie: getArticles,
      setOpportunitie: setArticle,
    },
    { type: 'e_book', getOpportunitie: getEbooks, setOpportunitie: setEbooks },
    { type: 'trail', getOpportunitie: getTrails, setOpportunitie: setTrails },
    { type: 'serie', getOpportunitie: getSeries, setOpportunitie: setSerie },
    {
      type: 'announcements',
      getOpportunitie: getAnnouncements,
      setOpportunitie: setCommunicate,
    },
    {
      type: 'challenge',
      getOpportunitie: getChallenges,
      setOpportunitie: setChallenge,
    },
  ];

  const handleConfirm = async (item) => {
    const returned = findCurrentReload.find(
      (option) => option.type === item.type
    );
    returned.setOpportunitie(null);
    setOpen(false);
    setModalSucess(true);

    await unPublishAttraction(item);
    const getCurrentOportunitie = await returned.getOpportunitie(
      returned.setOpportunitie
    );
    returned.setOpportunitie(getCurrentOportunitie);
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <div className="box" ref={modalCenter} onClick={closeModal}>
        <WrapperMoreInfo ref={modalRefOpen} isOpen={isOpen}>
          <WrapperHeader>
            <ImgHeader
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  'https://www.colorbook.io/imagecreator.php?hex=009291&width=1920&height=1080';
              }}
              src={item.attraction_image}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                textAlign: 'center',
                top: '20px',
                position: 'absolute',
                right: '10px',
              }}
            >
              <ModalX onClick={setState} />
            </div>
            <Modal />

            {item.total_reaction && (
              <ReactionWrapper>
                <Reaction>
                  <img style={{ margin: '0px 5px' }} src={liked} />
                  {item.total_reaction.total_liked}
                </Reaction>

                <Reaction>
                  <img style={{ margin: '0px 5px' }} src={deslike} />
                  {item.total_reaction.total_disliked}
                </Reaction>
              </ReactionWrapper>
            )}
          </WrapperHeader>
          {!children && (
            <WrapperContent>
              <WrapperStatus>
                {item.validity && (
                  <Wrapper>
                    <DivStar>
                      <StarActivityImg src={GreenStar} />
                    </DivStar>
                    <p style={{ marginTop: '10px', marginLeft: '5px' }}>
                      {item.validity.label}
                    </p>
                  </Wrapper>
                )}
                <WrapperStatusPublished>
                  <img
                    style={{ marginRight: '5px' }}
                    src={returnImageStatus(item)}
                  />
                  {item.status}
                </WrapperStatusPublished>
              </WrapperStatus>

              <InformationModal
                informationHeader={
                  'Tem certeza que deseja despublicar essa oportunidade de aprendizagem?'
                }
                errorMsg={'Você não poderá recuperar a publicação'}
                type={'Information'}
                isOpen={modalOpen}
                setModalOpen={setOpen}
                children={
                  <ConfirmDespublish
                    functionConfirm={() => handleConfirm(item)}
                    functionCancel={() => setOpen(false)}
                  />
                }
              />

              <InformationModal
                informationHeader={objectModalSucess.header}
                errorMsg={objectModalSucess.msg}
                type={objectModalSucess.icon}
                isOpen={modalSucess}
                setModalOpen={setModalSucess}
                closeOtherModal={setState}
              />

              <LoadingModal isOpen={loadingModal} />

              <WrapperTitles>
                <Title>{item.name}</Title>
                <SubTitle>
                  {ReactHtmlParser(
                    item.description.replace(/style="[^"]*"/g, '')
                  )}
                </SubTitle>
              </WrapperTitles>

              <WrapperInformartions>
                <div style={{ display: 'flex' }}>
                  <GridSide>
                    <ItemWrapper>
                      {returnTitle[item.type] && (
                        <div className="container">
                          <img src={event} alt="icon-evento" />
                          <div className="text-wrapper">
                            <strong> {titleEvent[item.type]} </strong>
                            <p> {returnSubTitleEvent()} </p>
                          </div>
                        </div>
                      )}
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={Calendar} alt="icon-calendario" />
                        <div className="text-wrapper">
                          <strong> Publicado em: </strong>
                          <p>
                            {' '}
                            {moment(item.created_at).format('DD/MM/YYYY')}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={Calendar} alt="icon-calendario" />
                        <div className="text-wrapper">
                          <strong> Data de realização: </strong>
                          <p>
                            {item.deadline_registration
                              ? moment(item.deadline_registration).format(
                                  'DD/MM/YYYY'
                                )
                              : 'Não definido'}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    {item.type_access && (
                      <ItemWrapper>
                        <div className="container">
                          <img src={Group10} alt="icon-altoFaltante" />
                          <div className="text-wrapper">
                            <strong> Tipo de publicação: </strong>
                            <p> {publicationFormat[item.type_access]} </p>
                          </div>
                        </div>
                      </ItemWrapper>
                    )}
                  </GridSide>
                  <GridSide>
                    <ItemWrapper>
                      <div className="container">
                        <img src={Alvo} alt="icon-alvo" />
                        <div className="text-wrapper">
                          <strong> Público-alvo </strong>
                          <button onClick={() => SetmacroModalOpen(true)}>
                            Clique para ver
                          </button>
                        </div>
                      </div>
                    </ItemWrapper>
                    <ItemWrapper>
                      <div className="container">
                        <img src={chapeu} alt="icon-chapeu" />
                        <div className="text-wrapper">
                          <strong> Postado por: </strong>
                          <p>
                            {' '}
                            {item.created_by
                              ? item.created_by.name
                              : 'Não identificado'}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>

                    <ItemWrapper>
                      <div className="container">
                        <img src={person} alt="icon-person" />
                        <div className="text-wrapper">
                          <strong> Responsável: </strong>
                          <p>
                            {' '}
                            {item.created_by
                              ? item.created_by.user
                              : 'Não identificado'}{' '}
                          </p>
                        </div>
                      </div>
                    </ItemWrapper>
                  </GridSide>
                </div>
              </WrapperInformartions>

              {/* bottom cards */}
              <BottomCards>
                <div className="cards">
                  <img widthImg="24px" src={checked} alt="icon-checked" />
                  <p>
                    {`Concluído por
                      ${
                        item.total_reaction &&
                        item.total_reaction.total_finished
                      }
                      ${
                        item.total_reaction &&
                        item.total_reaction.total_finished === 1
                          ? 'estudante'
                          : 'estudantes'
                      }`}
                  </p>
                </div>

                <div className="cards">
                  <img widthImg="22px" src={blackStar} alt="icon-estrela" />
                  <p>
                    {`Favoritado
                    ${
                      item.total_reaction &&
                      item.total_reaction.total_recommended
                    }
                    ${
                      item.total_reaction &&
                      item.total_reaction.total_recommended === 1
                        ? 'vez'
                        : 'vezes'
                    }`}
                  </p>
                </div>

                <div className="cards">
                  <img widthImg="22px" src={linkBlack} alt="icon-link" />
                  <p>
                    {`
                    ${item.total_reaction && item.total_reaction.total_clicked}
                    ${
                      item.total_reaction &&
                      item.total_reaction.total_clicked === 1
                        ? 'estudante direcionado ao link cadastrado'
                        : 'estudantes direcionados ao link cadastrado'
                    }
                    `}
                  </p>
                </div>
              </BottomCards>
              {item.is_published && (
                <ContainerButtons>
                  <UnpublishButton onClick={() => setOpen(true)}>
                    <img src={blackTrash} alt="icon-lixeira" />
                    DESPUBLICAR
                  </UnpublishButton>

                  <EditButton
                    onClick={() =>
                      handleClickEdit(item, loadingModal, setLoadingModal)
                    }
                  >
                    EDITAR
                  </EditButton>
                </ContainerButtons>
              )}
            </WrapperContent>
          )}

          {children && children}
        </WrapperMoreInfo>
      </div>
    </Container>
  ) : null;
}
