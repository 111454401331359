import React from 'react';
import { MentorsTab } from './MentorsTab';
import { HistoricTab } from './HistoricTab';
import { ScheduledTab } from './ScheduledTab';
import backgroundMentoring from 'assets/img/mentorship-background.jpg';
import CompanyViewsHeader from 'components/ViewsHeader/CompanyViewsHeader';
import { useMentorship } from '../context/MentorshipContext';

export default function TabsManager() {
  const {
    mentorshipCounts,
    fetchMentorshipsCount,
    activeTab,
    setActiveTab,
  } = useMentorship();

  const handleTabChange = (event, value) => {
    fetchMentorshipsCount();
    setActiveTab(value);
  };

  const goToScheduledTab = () => {
    setActiveTab(1);
  };

  return (
    <>
      <CompanyViewsHeader
        title="Mentoria de Carreira"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundMentoring}
        tabs={[
          `Mentores (${mentorshipCounts.mentors ?? 0})`,
          `Agendadas (${mentorshipCounts.mentorships ?? 0})`,
          `Histórico  (${mentorshipCounts.historic ?? 0})`,
        ]}
      />
      {activeTab === 0 && <MentorsTab goToScheduledTab={goToScheduledTab} />}
      {activeTab === 1 && <ScheduledTab currentTab={activeTab} />}
      {activeTab === 2 && <HistoricTab currentTab={activeTab} />}
    </>
  );
}
