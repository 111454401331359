import React, { Fragment } from 'react';
import { func, shape } from 'prop-types';
import { ListItem, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { getInsightSpreadsheet } from 'services/university/indicators';
import { ExportButton } from './styles';

const InsightsExport = ({ handleIsLoadingState }) => {
  async function handleClick() {
    handleIsLoadingState(true);
    const response = await getInsightSpreadsheet();
    handleIsLoadingState(false);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'insights.xls');
    document.body.appendChild(link);
    link.click();
  }

  return (
    <ExportButton onClick={() => handleClick()}>
      {'Exportar relatório'}
    </ExportButton>
  );
};

InsightsExport.propTypes = {
  handleIsLoadingState: func.isRequired,
};

export default withStyles(styles)(InsightsExport);
