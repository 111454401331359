import ReactionActions from '../ReactionActions';
import CardContainerButtons from './CardContainerButtons';
import CardContainerInformation from './CardContainerInformation';
import CardImage from './CardImage';
import CardImageStatus from './CardImageStatus';
import CardInformation from './CardInformation';
import CardStatus from './CardStatus';
import CardStatusCertificate from './CardStatusCertificate';
import CardTitle from './CardTitle';
import { Divider } from './styled';
import ContainerCardRoot from './ContainerCard';

export const Card = {
  Root: ContainerCardRoot,
  Title: CardTitle,
  Status: CardStatus,
  Information: CardInformation,
  Image: CardImage,
  ContainerButton: CardContainerButtons,
  ContainerInformation: CardContainerInformation,
  Reaction: ReactionActions,
  Divider: Divider,
  ImageStatus: CardImageStatus,
  CertificateStatus: CardStatusCertificate,
};
