import axios from 'axios';

const zipCodeRequest = (zipCode) => {
  let zipCodeOnlyDigits = zipCode.replace(/\D/g, '');
  if (zipCodeOnlyDigits.length !== 8) {
    return;
  }
  let response = axios
    .get(`https://viacep.com.br/ws/${zipCodeOnlyDigits}/json/`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { zipCodeRequest };
