import styled from 'styled-components';

export const SeeAdherenceButton = styled.button`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009291;
  font-weight: 700;
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 100%;
  height: 35px;
  cursor: pointer;
  font-size: 12px;
  padding: 0.6rem;
  white-space: nowrap;
  &:not(&:disabled):hover {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
    background: #f5f5f5;
    color: #949494;
  }
`;
