export function Suitcase({ color }) {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3849 3.56149H15.4743C15.2822 2.6273 14.7739 1.7879 14.0351 1.18478C13.2963 0.581662 12.3721 0.251692 11.4184 0.250488L9.76286 0.250488C8.80913 0.251692 7.88497 0.581662 7.14614 1.18478C6.40732 1.7879 5.89901 2.6273 5.70689 3.56149H4.79637C3.69911 3.5628 2.64717 3.99927 1.87129 4.77515C1.0954 5.55103 0.658938 6.60297 0.657623 7.70023L0.657623 15.9777C0.658938 17.075 1.0954 18.1269 1.87129 18.9028C2.64717 19.6787 3.69911 20.1152 4.79637 20.1165H16.3849C17.4821 20.1152 18.5341 19.6787 19.3099 18.9028C20.0858 18.1269 20.5223 17.075 20.5236 15.9777V7.70023C20.5223 6.60297 20.0858 5.55103 19.3099 4.77515C18.5341 3.99927 17.4821 3.5628 16.3849 3.56149ZM9.76286 1.90599H11.4184C11.9301 1.90811 12.4287 2.06829 12.846 2.36463C13.2632 2.66097 13.5787 3.07898 13.7493 3.56149H7.43192C7.60251 3.07898 7.918 2.66097 8.33525 2.36463C8.75249 2.06829 9.2511 1.90811 9.76286 1.90599ZM4.79637 5.21698H16.3849C17.0435 5.21698 17.6751 5.47861 18.1408 5.94431C18.6065 6.41001 18.8681 7.04163 18.8681 7.70023V10.1835H2.31312V7.70023C2.31312 7.04163 2.57475 6.41001 3.04045 5.94431C3.50615 5.47861 4.13777 5.21698 4.79637 5.21698ZM16.3849 18.461H4.79637C4.13777 18.461 3.50615 18.1993 3.04045 17.7336C2.57475 17.2679 2.31312 16.6363 2.31312 15.9777V11.839H9.76286V12.6667C9.76286 12.8863 9.85007 13.0968 10.0053 13.252C10.1605 13.4073 10.3711 13.4945 10.5906 13.4945C10.8101 13.4945 11.0207 13.4073 11.1759 13.252C11.3312 13.0968 11.4184 12.8863 11.4184 12.6667V11.839H18.8681V15.9777C18.8681 16.6363 18.6065 17.2679 18.1408 17.7336C17.6751 18.1993 17.0435 18.461 16.3849 18.461Z"
        fill={color}
      />
    </svg>
  );
}
