import { Work, Email } from '@material-ui/icons';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import BaseLayout from 'layouts/BaseLayout';
import CompanyJobsOpenings from 'views/Backoffice/Professions';
import AcademicInnovationEngine from 'views/Backoffice/academicInnovation/academicInnovationEngine';
import emailManagementEngine from 'views/Backoffice/emailManagement/emailManagementEngine';

const backofficeRoutes = (userData) => {
  const { can_use_academic_mapping } = userData.metadata;

  const defaultRoutes = [
    {
      path: '/',
      name: 'Atuações',
      icon: Work,
      baseComponent: BaseLayout,
      component: CompanyJobsOpenings,
    },
    {
      path: '/emails',
      name: 'Gestão de e-mails',
      icon: Email,
      baseComponent: BaseLayout,
      component: emailManagementEngine,
    },
  ];

  if (can_use_academic_mapping) {
    defaultRoutes.push({
      path: '/inovacao-academica',
      name: 'Inovação acadêmica',
      icon: LocalOfferOutlinedIcon,
      baseComponent: BaseLayout,
      component: AcademicInnovationEngine,
    });
  }

  defaultRoutes.push({
    redirect: true,
    path: '*',
    pathTo: '/',
  });

  return defaultRoutes;
};

export default backofficeRoutes;
