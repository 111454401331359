import { authenticatedAPI } from 'services/api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancelReqProfile;

const cancelRequestsProfile = () => {
  cancelReqProfile && cancelReqProfile();
};

const checkCurriculumFilled = () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/check-curriculum-filled`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const checkHasCoverLetter = () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/check-cover-letter`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const checkBasicInformations = () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/check-basic-information`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getProfile = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelReqProfile = c;
      }),
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getStudentGamification = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/gamification/status/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCurriculumEvolution = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/curriculum-evolution`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateProfile = async (data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/students/me`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const visibleItems = async (data) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/docs/curriculum/visible-items`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getImageExists = async (url) => {
  const response = authenticatedAPI.get(url);

  return response;
};

const getOptionsProfile = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/students/me`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptionsProfessionalStatus = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/me`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateAvatar = async (file) => {
  const dataFile = new FormData();
  dataFile.append('file', file);

  const response = authenticatedAPI
    .post(`/api/internal/students/me/avatar`, dataFile)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const removeAvatar = async () => {
  const response = authenticatedAPI
    .delete('/api/internal/students/me/avatar/remove')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateOccupationAreaAndOpenOffer = async (id, data) => {
  const response = authenticatedAPI
    .put(`/api/internal/students/me/occupation-area/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateOccupationArea = async (id, data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/students/me/occupation-area/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTechnicalCompetences = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/technical-competences`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const generatePublicProfileLog = async () => {
  try {
    const response = await authenticatedAPI.post(
      '/api/internal/history/action',
      {
        action: 'click',
        identifier: 'public_profile',
      }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export {
  getProfile,
  updateAvatar,
  updateProfile,
  getOptionsProfile,
  removeAvatar,
  getImageExists,
  getCurriculumEvolution,
  updateOccupationAreaAndOpenOffer,
  updateOccupationArea,
  getStudentGamification,
  visibleItems,
  cancelRequestsProfile,
  checkCurriculumFilled,
  getOptionsProfessionalStatus,
  checkHasCoverLetter,
  checkBasicInformations,
  getTechnicalCompetences,
  generatePublicProfileLog,
};
