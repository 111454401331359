import styled from 'styled-components';

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 9999;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 10px auto;
  max-width: 800px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;
