export class createFilterObject {
  constructor(remove, options = { commaSeparatedFields: [] }) {
    this.options = options;
    this.remove = remove;
  }

  isArray(value) {
    return Array.isArray(value);
  }

  isStringOrBoolean(value) {
    return typeof value === 'string' || typeof value === 'boolean';
  }

  isDate(value) {
    return value?.toISOString && typeof value === 'object';
  }

  joinArrayValues(key, value) {
    return this.options.commaSeparatedFields.includes(key)
      ? value.join(',')
      : value.join(`&${key}=`);
  }

  convert(filterObject) {
    const copyFilterObject = Object.assign({}, filterObject);

    const filtred = Object.keys(copyFilterObject).map((item) => {
      if (
        Array.isArray(copyFilterObject[item]) &&
        !copyFilterObject[item].length
      ) {
        return delete copyFilterObject[item];
      }

      const removeItens = Object.keys(copyFilterObject).map((item) => {
        if (this.remove?.includes(item)) {
          delete copyFilterObject[item];
        }
      });

      if (
        copyFilterObject[item] === null ||
        copyFilterObject[item] === undefined ||
        copyFilterObject[item] === ''
      ) {
        delete copyFilterObject[item];
      }
    });

    return this._create(copyFilterObject);
  }

  _create(filterObject) {
    const queryParam = Object.entries(filterObject).reduce(
      (acc, [key, value]) => {
        let newAcc = acc === '' ? '' : `${acc}&`;
        if (this.isArray(value)) {
          return `${newAcc}${key}=${this.joinArrayValues(key, value)}`;
        }
        if (this.isStringOrBoolean(value)) {
          return `${newAcc}${key}=${value}`;
        }
        if (this.isDate(value)) {
          return `${newAcc}${key}=${value.toISOString().split('T')[0]}`;
        }

        return acc;
      },
      ''
    );

    return [queryParam, filterObject];
  }
}
