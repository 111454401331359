import React, { Fragment, useEffect, useState } from 'react';

import ModalStepOneEmployed from 'views/MyProfile/modalCepAndProfessionalStatus/ModalEmployed';
import ModalCep from 'views/MyProfile/modalCepAndProfessionalStatus/ModalCep';
import ProfessionalStatusModal from 'views/MyProfile/modalCepAndProfessionalStatus/ProfessionalStatusModal';
import ModalSatisfactionFinish from 'views/MyProfile/modalCepAndProfessionalStatus/ModalSatisfactionFinish';
import ModalDissatisfied from 'views/MyProfile/modalCepAndProfessionalStatus/ModalDissatisfied';

export default function OrganizationModalComponent(props) {
  const { universityColor, curriculumData, profileData } = props;
  const [openModalCep, setOpenModalCep] = useState(false);
  const [openModalSatisfaction, setOpenModalSatisfaction] = useState(false);
  const [openModalDissatisfaction, setOpenModalDissatisfaction] = useState(
    false
  );
  const [
    openModalProfessionalStatus,
    setOpenModalProfessionalStatus,
  ] = useState(false);
  const [openModalEmployedStatus, setOpenModalEmployedStatus] = useState(false);
  const [hasFirstStep, setHasFirstStep] = useState(false);
  useState(false);

  useEffect(() => {
    if (!props.metadata.has_zip_code) {
      setOpenModalCep(true);
      return;
    }
    if (
      props.metadata.need_update_professional_satisfaction &&
      props.professional_status.is_employed
    ) {
      setOpenModalEmployedStatus(true);
      return;
    }
    if (
      props.metadata.need_update_professional_satisfaction &&
      !props.professional_status.is_employed
    ) {
      setOpenModalProfessionalStatus(true);
    }
  }, []);

  const closeAllModal = () => {
    setOpenModalCep(false);
    setOpenModalSatisfaction(false);
    setOpenModalDissatisfaction(false);
    setOpenModalProfessionalStatus(false);
    setOpenModalEmployedStatus(false);
    setHasFirstStep(false);
    window.location.reload(true);
  };

  return (
    <Fragment>
      {openModalCep && (
        <ModalCep
          universityColor={universityColor}
          modalOpenState={openModalCep}
          studentData={curriculumData}
          profileData={profileData}
          setOpenModalCep={setOpenModalCep}
          setOpenModalEmployedStatus={setOpenModalEmployedStatus}
          setOpenModalProfessionalStatus={setOpenModalProfessionalStatus}
          setHasFirstStep={setHasFirstStep}
          {...props}
        />
      )}
      {openModalEmployedStatus && (
        <ModalStepOneEmployed
          universityColor={universityColor}
          modalOpenState={openModalEmployedStatus}
          curriculumData={curriculumData}
          profileData={profileData}
          setOpenModalSatisfaction={setOpenModalSatisfaction}
          setOpenModalDissatisfaction={setOpenModalDissatisfaction}
          setOpenModalProfessionalStatus={setOpenModalProfessionalStatus}
          setOpenModalEmployedStatus={setOpenModalEmployedStatus}
          hasFirstStep={hasFirstStep}
          {...props}
        />
      )}
      {openModalProfessionalStatus && (
        <ProfessionalStatusModal
          universityColor={universityColor}
          modalOpenState={openModalProfessionalStatus}
          curriculumData={curriculumData}
          profileData={profileData}
          professionalStatus={props.professional_status}
          setOpenModalCep={setOpenModalCep}
          setOpenModalProfessionalStatus={setOpenModalProfessionalStatus}
          setOpenModalSatisfaction={setOpenModalSatisfaction}
          setOpenModalDissatisfaction={setOpenModalDissatisfaction}
          hasFirstStep={hasFirstStep}
          {...props}
        />
      )}

      {openModalSatisfaction && (
        <ModalSatisfactionFinish
          universityColor={universityColor}
          modalOpenState={openModalSatisfaction}
          studentData={curriculumData}
          profileData={profileData}
          closeAllModal={closeAllModal}
          {...props}
        />
      )}

      {openModalDissatisfaction && (
        <ModalDissatisfied
          universityColor={universityColor}
          modalOpenState={openModalDissatisfaction}
          studentData={curriculumData}
          profileData={profileData}
          closeAllModal={closeAllModal}
          {...props}
        />
      )}
    </Fragment>
  );
}
