import React, { useState } from 'react';

import {
  ModalX,
  InfoModalBackground,
  InfoModalContainer,
  HeaderModal,
  BodyModal,
} from './styled';

import information from 'assets/Oportutinites/information.svg';
import sucess from 'assets/Oportutinites/sucess.svg';

export default function InformationModal({
  informationHeader,
  errorMsg,
  type,
  isOpen,
  setModalOpen,
  finish,
  children,
  closeOtherModal,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const setState = () => {
    if (closeOtherModal) {
      closeOtherModal();
    }
    if (finish) {
      setModalIsOpen(false);
    }
    setModalOpen(false);
  };

  const returnCurrentImg = () => {
    return type === 'success' ? sucess : information;
  };

  return (
    <InfoModalBackground isOpen={isOpen} onClick={setState}>
      <InfoModalContainer>
        <HeaderModal>
          <ModalX onClick={setState} />
        </HeaderModal>
        <BodyModal>
          <img src={returnCurrentImg()} />

          <h4> {informationHeader} </h4>

          <p> {errorMsg} </p>

          {children && children}
        </BodyModal>
      </InfoModalContainer>
    </InfoModalBackground>
  );
}
