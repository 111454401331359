import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';
import formValidationHandler from './formValidationHandler';
import FairAndEventsService from '../Services/index';
import { payload } from '../Adapters/payload';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export const FormHandlers = (
  useRegisterFormInstance,
  visualizationContextInstance
) => {
  const handleSubmitForm = async (data, schemaValidation, formRef) => {
    const { getListings } = visualizationContextInstance || {};

    const {
      nextFormStep,
      updateObjectStepsData,
      isLastStep,
      stepsData,
      setModalOpen,
    } = useRegisterFormInstance;
    const objectValidate = {
      schemaValidation,
      data,
      formRef,
    };

    if (schemaValidation) {
      try {
        await formValidationHandler.validateForm(objectValidate);
      } catch {
        return;
      }
    }

    updateObjectStepsData(data);
    if (stepsData.event_end_time === '') {
      delete stepsData.event_end_time;
    }
    const requestType = isLastStep && stepsData.id ? 'patchEdit' : 'postCreate';
    const fullData = payload({ ...stepsData, ...data }, requestType);

    if (isLastStep) {
      try {
        const response = await FairAndEventsService[requestType](
          fullData,
          stepsData.id
        );
        getListings();
        setModalOpen(false);
        customSnackbar(
          `${
            requestType === 'patchEdit'
              ? 'Cadastro atualizado'
              : 'Cadastro realizado'
          } com sucesso!`,
          'confirmation'
        );
      } catch (error) {
        customSnackbar(
          `Ocorreu um erro durante a ${
            requestType === 'patchEdit' ? 'edição' : 'criação'
          } do evento!`,
          'error'
        );
        return;
      }
      return;
    }

    nextFormStep();
  };

  const handleCourseChange = async (value, useRegisterFormInstance) => {
    const { setSelectOptions } = useRegisterFormInstance;
    const objectValue = {
      courses: Array.isArray(value)
        ? value.map((item) => item?.value || item)
        : value,
    };
    const createFilter = new createFilterObject();
    const [param, _] = createFilter.convert(objectValue);
    const response = await FairAndEventsService.getSelectOptions(param);
    setSelectOptions(response);
  };

  const handleMacroAreaChange = async (value, useRegisterFormInstance) => {
    const { setSelectOptions } = useRegisterFormInstance;
    const objectValue = {
      macro_areas: Array.isArray(value)
        ? value.map((item) => item?.value || item)
        : value,
    };
    const createFilter = new createFilterObject();
    const [param, _] = createFilter.convert(objectValue);
    const response = await FairAndEventsService.getSelectOptions(param);
    setSelectOptions((prevs) => ({
      ...prevs,
      ...response,
    }));
  };

  return { handleSubmitForm, handleCourseChange, handleMacroAreaChange };
};
