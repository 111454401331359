import React, { Fragment, useState, useEffect } from 'react';
import { func, shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import IcoAdd from '@material-ui/icons/Add';

// core components
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import CardComposition from 'components/CardComposition/CardComposition';
import GridContainer from 'components/Grid/GridContainer';
import { Grid, Divider } from '@material-ui/core';

import Button from 'components/CustomButtons/Button';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import headerBackGroundImage from 'assets/img/ies-company-invites.jpg';

import styled from 'styled-components';
import { listPartners } from 'services/company/partners';
import PartnerRegistrationForm from './PartnerRegistrationForm';

const PartnerCompanies = styled.div`
  display: flex;
  flex: 1;
  padding: 12px;
  table {
    width: 100%;
  }
  tr {
    justify-content: space-between;
  }
  th {
    text-align: start;
  }
  td {
    padding: 12px;
  }
  header {
    color: #999;
    font-size: 17px;
    font-weight: 400;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
  hr {
    margin-bottom: 22px;
  }
`;
const CompaniesList = styled.div`
  .status {
    color: ${(props) => (props.isConnected ? '#3C3' : '#a5300d')};
  }
`;

function IntegratorPreRegister(props) {
  const {
    classes,
    openModalWithContent,
    handleIsLoadingState,
    closeModal,
    openSnackbar,
  } = props;

  const [companies, setCompanies] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    listPartners().then((response) => {
      setCount(response.pop().count);
      setCompanies(response);
    });
    handleIsLoadingState(false);
  }, []);

  return (
    <Fragment>
      <CompanyHeader
        title="Conexões"
        backgroundImage={headerBackGroundImage}
        tabs={[`Empresas Parceiras (${count})`]}
      />
      <GridContainer
        justify="center"
        className={classes.cardMarginTop}
        id="container"
      >
        <Grid item xs={12}>
          <Button
            round
            className={classes.addButton}
            onClick={() =>
              openModalWithContent(
                <PartnerRegistrationForm
                  handleIsLoadingStateFn={handleIsLoadingState}
                  openSnackbarFn={openSnackbar}
                  closeModalFn={closeModal}
                  companies={companies}
                  setCompanies={setCompanies}
                  setCount={setCount}
                  jobRegistration={false}
                />,
                'Formulário de Pré-cadastro de empresa'
              )
            }
          >
            Cadastrar empresa
            <IcoAdd />
          </Button>
        </Grid>
        <Grid item xs md={9} sm={12}>
          <CardComposition
            customStyle={{ margin: '0 !important' }}
            CardContent={() => (
              <PartnerCompanies>
                <table>
                  <thead>
                    <tr>
                      <td>
                        <header>Nome</header>
                      </td>
                      <td>
                        <header>CNPJ</header>
                      </td>
                      <td>
                        <header>Cidade/UF</header>
                      </td>
                      <td>
                        <header>Status</header>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {companies &&
                      companies.length > 0 &&
                      companies.map((company) => (
                        <tr>
                          <td>
                            <Divider />
                            <span>{company.name}</span>
                          </td>
                          <td>
                            <Divider />
                            <span>{company.organization_number}</span>
                          </td>
                          <td>
                            <Divider />
                            <span>
                              {company.address.city}/{company.address.state}
                            </span>
                          </td>
                          <td>
                            <Divider />
                            <CompaniesList isConnected={company.connected}>
                              {company.connected ? (
                                <span className="status">Conectada</span>
                              ) : (
                                <span className="status">Não Conectada</span>
                              )}
                            </CompaniesList>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </PartnerCompanies>
            )}
          />
          <Grid item xs md={12} sm={12} className={classes.flexCenter} />
        </Grid>
      </GridContainer>
    </Fragment>
  );
}

IntegratorPreRegister.propTypes = {
  classes: shape.isRequired,
  handleIsLoadingState: func.isRequired,
  openModalWithContent: func.isRequired,
  closeModal: func.isRequired,
  openSnackbar: func.isRequired,
};

export default withStyles(styles)(IntegratorPreRegister);
