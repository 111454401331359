import React, { useState, useContext, useEffect } from 'react';
import HeaderMatrixController from '../headerMatrixController';
import InsideTabs from '../insideTabs';
import ActiveMatrices from '../activeMatrices';
import { Wrapper, Content } from './style';
import DisabledMatrices from '../disabledMatrices';
import { MatrixContext } from '../../contexts/MatrixContext';
import ModalInformation from 'components/informationModal';
export default function MatrixController({
  openSnackbar,
  handleSelectCourse,
  totalSubjects,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { totalDisabledMatrices, totalMatrices } = useContext(MatrixContext);

  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const configModalInformation = {
    bubbleText: 'Atenção!',
    ParagraphText: `Ao editar a <strong>sua matriz curricular atual</strong>,
 haverá alterações na aba “mapeamento acadêmico". Veja abaixo quando essas
 alterações podem ocorrer:`,
    buttonConfirmText: 'ESTOU CIENTE',
    showButtonCancel: false,
    modalHeight: 400,
    buttonConfirmFunction: () => setModalInformationOpen(false),

    informationList: [
      {
        text: 'Ao acrescentar novas disciplinas',
        information:
          'As novas disciplinas deverão passar pelo processo de descrição ' +
          'dos conteúdos.',
        toolTipWidth: '300px',
      },
      {
        text: 'Ao remover disciplinas',
        information:
          'Caso a disciplina esteja contemplando alguma tendência ou ' +
          'requisito de mercado, a sua porcentagem de aderência irá diminuir.',
        toolTipWidth: '300px',
      },
    ],
  };

  useEffect(() => {
    setModalInformationOpen(true);
  }, []);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const renderCurrentTabMatrix = () => {
    const pages = {
      0: <ActiveMatrices totalSubjects={totalSubjects} />,
      1: <DisabledMatrices openSnackbar={openSnackbar} />,
    };
    return pages[activeTab];
  };

  return (
    <Wrapper>
      <HeaderMatrixController handleSelectCourse={handleSelectCourse} />
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={configModalInformation}
        buttonConfirmFunction={configModalInformation.buttonConfirmFunction}
      />
      <Content>
        <InsideTabs
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={[
            `Matrizes ativadas (${totalMatrices})`,
            `Matrizes desativadas (${totalDisabledMatrices})`,
          ]}
          openSnackbar={openSnackbar}
        />
        {renderCurrentTabMatrix()}
      </Content>
    </Wrapper>
  );
}
