import styled from 'styled-components';

export const InputRadio = styled.input`
  accent-color: #009291;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    margin: 0;
  }
`;
export const LabelInput = styled.label`
  display: flex;
  align-items: flex-start;
`;

export const TitleLabel = styled.p`
  font-size: 15px;
  color: #4b4b4d;
  margin-left: 10px !important;
`;

export const SubTitle = styled.p`
  margin-left: 30px !important;
  font-size: 14px;
  color: #606062;
  margin-bottom: 10px !important;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px !important;
  position: relative;
  margin-top: 8px !important;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px !important;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px !important;
`;
export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
`;
