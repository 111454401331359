import styled from 'styled-components';

export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 40px;

  > form {
    width: 100%;
    position: relative;
  }
`;

export const ErrorInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #efeeec;
  width: 321px;
  border-radius: 4px;
  min-height: 44px;
  padding: 8px 13px;
  margin-bottom: 20px;

  > p {
    margin: 0;
  }

  > img {
    background-color: #009291;
    width: 40px;
    border-radius: 4px;
    padding: 5px;
  }
`;

export const ContainerButton = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: end;

  > button {
    background-color: #009291;
    border: none;
    color: #fff;
    border-radius: 4px;
    right: 0;
    cursor: pointer;
    height: 40px;
    padding: 13px;
    font-weight: bold;
  }
`;
