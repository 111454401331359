// ModalMatchInformation.js
import React from 'react';
import iconInfoBlack from 'assets/img/iconsJobOffers/iconInfoBlack.png';
import iconTotalMatch from 'assets/img/iconsJobOffers/iconTotalMatch.png';
import Tooltip from 'components/NewTooltip';
import { matchTexts, FormattedText, getHighlight } from './matchTexts';
import {
  ContainerModals,
  HeaderModal,
  IconImg,
  TitleModal,
  ButtonClose,
  BackgroundModals,
  ContainerTextsModals,
  TextModals,
  TitleMatchs,
  ParagraphMatch,
  LevelMatch,
  LevelMatchOne,
  LevelMatchTwo,
  LevelMatchThree,
  LevelMatchFour,
  ContainerMatchs,
  ContentMatch,
} from '../styled';

const MatchContent = ({ matchLevel, levels }) => (
  <ContentMatch>
    <TitleMatchs match={matchLevel}>{matchTexts[matchLevel].range}</TitleMatchs>
    <ContainerMatchs>
      {levels.map((Level, index) => (
        <Level key={index} />
      ))}
    </ContainerMatchs>
    <ParagraphMatch>
      <FormattedText text={matchTexts[matchLevel].description} />
    </ParagraphMatch>
  </ContentMatch>
);

export default function ModalMatchInformation({ setOpenModalMatch }) {
  const onCloseModal = () => {
    setOpenModalMatch(false);
  };

  return (
    <BackgroundModals>
      <ContainerModals>
        <HeaderModal>
          <TitleModal>Entenda o match</TitleModal>
          <ButtonClose onClick={onCloseModal}>X</ButtonClose>
        </HeaderModal>

        <ContainerTextsModals>
          <TextModals>
            A porcentagem do seu match com a vaga é calculada com base na sua{' '}
            <strong>
              localização, seu curso de formação, disponibilidade de horário e
              competências comportamentais.
            </strong>
            Confira abaixo os níveis dos matchs:
          </TextModals>

          <MatchContent
            matchLevel="matchOne"
            levels={[LevelMatchOne, LevelMatch, LevelMatch, LevelMatch]}
          />
          <MatchContent
            matchLevel="matchTwo"
            levels={[LevelMatchTwo, LevelMatchTwo, LevelMatch, LevelMatch]}
          />
          <MatchContent
            matchLevel="matchThree"
            levels={[
              LevelMatchThree,
              LevelMatchThree,
              LevelMatchThree,
              LevelMatch,
            ]}
          />

          <ContentMatch>
            <ContainerMatchs>
              <TitleMatchs match="matchFour">
                {matchTexts.matchFour.range}
              </TitleMatchs>
              <IconImg src={iconTotalMatch} alt="match" isMatch />
            </ContainerMatchs>
            <ContainerMatchs>
              <LevelMatchFour />
              <LevelMatchFour />
              <LevelMatchFour />
              <LevelMatchFour />
            </ContainerMatchs>
            <ParagraphMatch>
              <FormattedText text={matchTexts.matchFour.description} />
            </ParagraphMatch>
          </ContentMatch>
        </ContainerTextsModals>
      </ContainerModals>
    </BackgroundModals>
  );
}
