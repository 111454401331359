import React from 'react';
import { FeedbackList } from 'components/TalentsList/styled';
import { Divider } from '@material-ui/core';
import { shape } from 'prop-types';
import { format } from 'date-fns';

export function FeedbackCard({ analysisRequest = [] }) {
  return analysisRequest ? (
    <div style={{ width: '100%', padding: '0 1.5rem' }}>
      <FeedbackList>
        {analysisRequest.map((item, index) =>
          item.feedback ? (
            <div style={{ width: '100%' }}>
              <p style={{ margin: 0 }}>Status: {item.status.label}</p>
              {item.feedback && item.feedback.feedback && (
                <p style={{ margin: 0 }}>Feedback: {item.feedback.feedback}</p>
              )}
              <p style={{ margin: 0 }}>
                Responsável pelo feedback: {item.feedback.created_by} -{' '}
                {format(
                  new Date(item.feedback.created_at),
                  "dd/MM/yyyy 'às' H"
                )}
                :00
              </p>

              {index !== analysisRequest.length - 1 ? (
                <Divider style={{ margin: '1rem 0' }} />
              ) : null}
            </div>
          ) : null
        )}
      </FeedbackList>
    </div>
  ) : (
    <FeedbackList>
      <h4 style={{ textAlign: 'center' }}>Ainda não há feedbacks</h4>
    </FeedbackList>
  );
}

FeedbackCard.propTypes = {
  analysisRequest: shape.isRequired,
};
