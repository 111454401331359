import React, { useContext, Fragment } from 'react';
import { OpportunitiesCompanyContext } from '../context/OpportunitiesCompanyContext';
import { RegistrationCardObject } from '../resources/RegistrationCardObject';

import {
  CardImg,
  Cards,
  CardsWrapper,
  Wrapper,
  Title,
} from '../../../../components/Opportunities/styles/defaultStyled';

import {
  CardTitle,
  CardSubTitle,
} from 'views/StaffPages/Opportunities/Styles/Styled';

export default function RegistrationCard({ setActiveTab }) {
  const { setModalIsOpen, setModalContent } = useContext(
    OpportunitiesCompanyContext
  );

  const PrincipalCards = RegistrationCardObject.map((item) => (
    <CardsWrapper key={item.id}>
      <Cards
        onClick={() =>
          item.handleCall(setModalIsOpen, setModalContent, setActiveTab)
        }
      >
        <CardImg src={item.image} />
        <CardTitle> {item.title} </CardTitle>
        <CardSubTitle> {item.subtitle} </CardSubTitle>
      </Cards>
    </CardsWrapper>
  ));

  return (
    <Fragment>
      <Title>
        Qual <strong>oportunidade de aprendizagem</strong> você deseja{' '}
        <strong>publicar?</strong>
      </Title>
      <Wrapper> {PrincipalCards} </Wrapper>
    </Fragment>
  );
}
