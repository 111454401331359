export function IconCompetence({ color }) {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1667 1.69727H5.83333C2.61683 1.69727 0 4.3141 0 7.5306V21.5306C0 24.7471 2.61683 27.3639 5.83333 27.3639H22.1667C25.3832 27.3639 28 24.7471 28 21.5306V7.5306C28 4.3141 25.3832 1.69727 22.1667 1.69727ZM25.6667 21.5306C25.6667 23.4603 24.0963 25.0306 22.1667 25.0306H5.83333C3.90367 25.0306 2.33333 23.4603 2.33333 21.5306V7.5306C2.33333 5.60093 3.90367 4.0306 5.83333 4.0306H22.1667C24.0963 4.0306 25.6667 5.60093 25.6667 7.5306V21.5306ZM22.1667 8.69726C22.1667 9.34126 21.644 9.86393 21 9.86393H12.8333C12.1893 9.86393 11.6667 9.34126 11.6667 8.69726C11.6667 8.05326 12.1893 7.5306 12.8333 7.5306H21C21.644 7.5306 22.1667 8.05326 22.1667 8.69726ZM9.33333 8.69726C9.33333 9.66326 8.54933 10.4473 7.58333 10.4473C6.61733 10.4473 5.83333 9.66326 5.83333 8.69726C5.83333 7.73126 6.61733 6.94726 7.58333 6.94726C8.54933 6.94726 9.33333 7.73126 9.33333 8.69726ZM22.1667 14.5306C22.1667 15.1746 21.644 15.6973 21 15.6973H12.8333C12.1893 15.6973 11.6667 15.1746 11.6667 14.5306C11.6667 13.8866 12.1893 13.3639 12.8333 13.3639H21C21.644 13.3639 22.1667 13.8866 22.1667 14.5306ZM9.33333 14.5306C9.33333 15.4966 8.54933 16.2806 7.58333 16.2806C6.61733 16.2806 5.83333 15.4966 5.83333 14.5306C5.83333 13.5646 6.61733 12.7806 7.58333 12.7806C8.54933 12.7806 9.33333 13.5646 9.33333 14.5306ZM22.1667 20.3639C22.1667 21.0079 21.644 21.5306 21 21.5306H12.8333C12.1893 21.5306 11.6667 21.0079 11.6667 20.3639C11.6667 19.7199 12.1893 19.1973 12.8333 19.1973H21C21.644 19.1973 22.1667 19.7199 22.1667 20.3639ZM9.33333 20.3639C9.33333 21.3299 8.54933 22.1139 7.58333 22.1139C6.61733 22.1139 5.83333 21.3299 5.83333 20.3639C5.83333 19.3979 6.61733 18.6139 7.58333 18.6139C8.54933 18.6139 9.33333 19.3979 9.33333 20.3639Z"
        fill={color}
      />
    </svg>
  );
}
