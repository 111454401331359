import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const UnInput = styled.input`
  border: ${(props) =>
    props.error ? '1px solid transparent' : '1px solid #d0d0d0'};
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 4px;
  box-shadow: ${(props) => (props.error ? '0 0 0 2px #F00' : 'none')};

  &:focus {
    box-shadow: ${(props) =>
      props.error ? '0 0 0 2px #F00' : '0 0 0 2px #009291'};
    border: 1px solid transparent;
  }
`;

export const UnInputLink = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  padding: 12px 30px;
  margin-bottom: 10px;

  &:focus {
    border-bottom: 1px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  }
`;
export const UnImageLink = styled.img`
  width: 20px;
  position: absolute;
  top: 30px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
`;

export const UnInputDesc = styled.textarea`
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 10em;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none;
  font-family: sans-serif;

  &:focus {
    border: 2px solid #2784ff;
  }
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;

  svg {
    position: absolute;
    top: 8px;
  }
`;

export const Error = styled.span`
  position: absolute;
  bottom: -25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
