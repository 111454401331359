import Axios from 'axios';

export const GenerateBlob = (url) => {
  const blobResponse = Axios.get(url, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  })
    .then((response) => {
      return window.URL.createObjectURL(new Blob([response.data]));
    })
    .catch((error) => error);

  return blobResponse;
};
