import React, { createContext, useEffect, useRef, useState } from 'react';
import {
  getMatricesCourse,
  getDisabledMatricesCourse,
  setMatrixActive,
  reactivateMatrices,
  getSubjectsCourse,
} from '../services/matrixServices';

export const MatrixContext = createContext();

export default function MatrixProvider({ children }) {
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [subjectsCourse, setSubjectsCourse] = useState([]);
  const [matricesCourse, setMatricesCourse] = useState([]);
  const [modalIsOpenName, setModalIsOpenName] = useState(null);
  const [disabledMatrices, setDisabledMatrices] = useState([]);
  const [disabledMatricesCount, setDisabledMatricesCount] = useState(0);
  const [totalDisabledMatrices, setTotalDisabledMatrices] = useState(0);
  const [selectedMatrices, setSelectedMatrices] = useState([]);
  const [resetDisabledMatrices, setResetDisabledMatrices] = useState(false);
  const [showMoreId, setShowMoreId] = useState('');
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [totalSubjects, setTotalSubjects] = useState(0);
  const [allDisciplinesCourse, setAllDisciplinesCourse] = useState([]);
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [totalMatrices, setTotalMatrices] = useState(0);
  const [actualPageMatrix, setActualPageMatrix] = useState(1);
  const [successSaveEdit, setSuccessSaveEdit] = useState(false);
  const [currentPageDisciplines, setCurrentPageDisciplines] = useState(1);
  const [limitDisciplines, setLimitDisciplines] = useState(20);
  const [filter, setFilter] = useState([...subjectsCourse]);
  const [selectedAll, setSelectedAll] = useState(false);

  const [loading, setLoading] = useState(false);

  const inputRef = useRef('');

  const isRemoving = useRef(false);

  const checkedAllDisciplines = useRef(false);

  const filterCurrentPage = useRef(1);

  const clearData = () => {
    isRemoving.current = false;
    checkedAllDisciplines.current = false;
    inputRef.current = '';
    setSelectedCourse(null);
  };

  const filterOptions = [
    {
      label: 'Todas as disciplinas',
      value: 'all_disciplines',
    },
    {
      label: 'Sem relacionamento com alguma matriz',
      value: 'without_relationship',
    },
    {
      label: 'Com relacionamento com alguma matriz',
      value: 'with_relationship',
    },
  ];

  const getMatrices = async (id) => {
    const { results, count } = await getMatricesCourse(id);
    setMatricesCourse(results);
    setTotalMatrices(count);
  };

  const getCourseSubjects = async (id) => {
    let params = '';
    if (appliedFilters && appliedFilters.disciplines) {
      switch (appliedFilters.disciplines) {
        case 'with_relationship':
          params = 'with_relationship=true';
          break;
        case 'without_relationship':
          params = 'with_relationship=false';
          break;
      }
    }
    const query = `limit=20${params && '&' + params}`;
    return await getSubjectsCourse(id, query);
  };

  const getTotalDisabledMatrices = async (id) => {
    const { count } = await getDisabledMatricesCourse(id, '&limit=1');
    setTotalDisabledMatrices(count);
  };

  const getDisabledMatrices = async (id, query = '') => {
    const { results, count } = await getDisabledMatricesCourse(id, query);
    setDisabledMatrices(results);
    setDisabledMatricesCount(count);
    getTotalDisabledMatrices(id);
  };

  const refreshDisabledMatrices = async (query = '') => {
    await getDisabledMatrices(selectedCourse, query);
    getMatrices(selectedCourse);
  };

  const refreshCourseSubjects = async () => {
    if (selectedCourse) {
      const { count, results } = await getCourseSubjects(selectedCourse);
      setSubjectsCourse(results);
      setTotalSubjects(count);
      setCurrentPageDisciplines(1);
      setLimitDisciplines(20);
      setSelectedSubjects([]);
    }
  };

  const reactivateMatrix = async (id) => {
    return await setMatrixActive(id);
  };

  const reactivateSelectedMatrices = async () => {
    return await reactivateMatrices(selectedCourse, selectedMatrices);
  };

  const resetFilters = async () => {
    setFilterState({});
    setAppliedFilters({});
    setSelectedSubjects([]);
  };

  return (
    <MatrixContext.Provider
      value={{
        courseOptions,
        setCourseOptions,
        selectedCourse,
        setSelectedCourse,
        subjectsCourse,
        setSubjectsCourse,
        matricesCourse,
        setMatricesCourse,
        getMatrices,
        modalIsOpenName,
        setModalIsOpenName,
        refreshDisabledMatrices,
        getDisabledMatrices,
        disabledMatrices,
        setDisabledMatrices,
        disabledMatricesCount,
        reactivateMatrix,
        selectedMatrices,
        setSelectedMatrices,
        reactivateSelectedMatrices,
        totalDisabledMatrices,
        resetDisabledMatrices,
        setResetDisabledMatrices,
        selectedSubjects,
        setSelectedSubjects,
        allDisciplinesCourse,
        setAllDisciplinesCourse,
        appliedFilters,
        filterState,
        setFilterState,
        checkedAllDisciplines,
        setAppliedFilters,
        filterOptions,
        getCourseSubjects,
        refreshCourseSubjects,
        totalSubjects,
        setTotalSubjects,
        resetFilters,
        totalMatrices,
        setTotalMatrices,
        actualPageMatrix,
        setActualPageMatrix,
        showMoreId,
        setShowMoreId,
        successSaveEdit,
        setSuccessSaveEdit,
        currentPageDisciplines,
        setCurrentPageDisciplines,
        limitDisciplines,
        setLimitDisciplines,
        filter,
        setFilter,
        selectedAll,
        setSelectedAll,
        inputRef,
        loading,
        setLoading,
        isRemoving,
        filterCurrentPage,
        clearData,
      }}
    >
      {children}
    </MatrixContext.Provider>
  );
}
