import React, { useContext, useEffect } from 'react';
import BaseLayoutContext from 'contexts/base-layout';

import { JobOfferContext } from '../../../context/jobOfferContext';
import PreviewJob from '../PreviewJobs';
import { PrevButton } from './styles';

export const PreviewButton = ({ children, stepThreeData, handleSubmit }) => {
  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const {
    data,
    stepThreeRef,
    externalJobOffer,
    setZIndex,
    zIndex,
    selectedUniversities,
  } = useContext(JobOfferContext);

  const handleClickPreview = (e) => {
    e.preventDefault();
    setZIndex(0);
    openModalWithContent(
      <PreviewJob
        data={data}
        stepThreeData={stepThreeRef}
        externalJobOffer={externalJobOffer}
        handleSubmit={handleSubmit}
        closeModal={closeModal}
        selectedUniversities={selectedUniversities}
      />,
      null,
      null,
      () => setZIndex(2000000000)
    );
  };

  return <PrevButton onClick={handleClickPreview}>{children}</PrevButton>;
};
