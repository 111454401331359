import React, { useRef, useEffect, useContext } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { ContainerLabelInput } from '../../../../../Opportunities/Styles/formStyled';
import { UnInputLabel } from '../../../../../Opportunities/Styles/unformComponentsStyled';

export default function SearchableSelectUn({
  label,
  name,
  options,
  placeholder,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value.value;
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <div>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <ReactSelect
        defaultValue={defaultValue}
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
    </div>
  );
}
