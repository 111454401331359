import React from 'react';
import { Fragment } from 'react';
import {
  ButtonsContainer,
  FinishButton,
  NextButton,
  PrevsButton,
} from './stylesFooterFormChallenges';

function FooterFormChallenges({
  haveNext,
  haveBack,
  haveFinish,
  disabledButton,
  handlePrevsFormStep,
  setCurrentStep,
  setFormValues,
  formDataStep2,
}) {
  function handleClickPrevsButton() {
    const data = formDataStep2?.current?.getData();
    setFormValues(data);
    setCurrentStep(1);
  }

  const returnButtons = () => {
    if (haveNext && !haveBack && !haveFinish) {
      return (
        <Fragment>
          <div />
          <NextButton />
        </Fragment>
      );
    }
    if (haveFinish && haveBack) {
      return (
        <Fragment>
          <PrevsButton onClick={() => handlePrevsFormStep()} />
          <FinishButton disabled={disabledButton}>Publicar</FinishButton>
        </Fragment>
      );
    }
  };

  return <ButtonsContainer>{returnButtons()}</ButtonsContainer>;
}

export default FooterFormChallenges;
