import React, { Fragment } from 'react';
import { Card, CardImgSkeleton, CardSubTitle, CardTitle } from './styles';

export default function SkeletonCardsChallenges({ quantity }) {
  const numberOfCards = [];

  for (let i = 1; i <= quantity; i++) {
    numberOfCards.push(i);
  }

  const mapCards = () => {
    return numberOfCards.map((item) => (
      <Card>
        <CardImgSkeleton />
        <CardTitle />
        <CardSubTitle top={18} />
        <CardSubTitle top={20} />
      </Card>
    ));
  };

  return <Fragment>{mapCards()}</Fragment>;
}

SkeletonCardsChallenges.defaultProps = {
  quantity: 3,
};
