import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  > p {
    color: #009291;
    font-weight: 400;
    font-size: 17px;
  }
`;
