import React, { Component, Fragment } from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import { maskCnpj, maskInputPhone } from 'utils/inputMasks';
import { validateCNPJ, mobilePhoneNumberRegex } from 'utils/validation';
import {
  companyInviteAdapter,
  payloadAdapter,
} from 'adapters/university/companyInviteAdapter';

import {
  FormHelperText,
  Grid,
  Checkbox,
  TextField,
  List,
  ListItem,
} from '@material-ui/core';

import { isCompanyRegistered } from 'services/companies';
import { organizationNumberExists } from 'services/company/partners';
import {
  inviteExistingCompany,
  isInviteRegistered,
  saveCompanyInvite,
  updateCompanyInvite,
} from 'services/university/companyInvite';

import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { object, func, bool } from 'prop-types';
import CityAndStateForm from '../../MyProfile/components/Profile/components/CityAndStateForm';
import { ThemeConsumer } from 'styled-components';

import DropFileInput from '../../../components/CustomUpload/DropFileInput';
import { MessageError } from '../../../components/CustomUpload/styles/DropFileInputStyles';

const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

class CompanyPreRegisterForm extends Component {
  integratorJobPartnerSchema = Yup.object().shape({
    companyName: Yup.string().required(
      'Por gentileza, informe o nome de registrado da empresa.'
    ),
    organizationNumber: Yup.string()
      .matches(cnpjRegex, { message: 'Informe um CNPJ válido.' })
      .test(
        'organizationNumberValidation',
        'Informe um CNPJ válido.',
        (value) => {
          if (value) {
            return validateCNPJ(value);
          }

          return false;
        }
      )
      .test(
        'organizationExists',
        'CNPJ já existe em sua lista.',
        async (value) => {
          if (value) {
            if (!this.props.isEdit) {
              const isInvited = await isInviteRegistered(value);
              if (isInvited.message && false) {
                this.props.openSnackbarFn(
                  `Erro ${isInvited.message} ao consultar CNPJ. Entre em contato com o suporte.`,
                  true
                );
                return false;
              }
              return !isInvited.exists || false;
            }
            return validateCNPJ(value);
          }

          return false;
        }
      )
      .test(
        'organizationRegistered',
        'Esta empresa já é cadastrada.',
        async (value) => {
          if (value) {
            if (!this.props.isEdit) {
              const isRegistered = await isCompanyRegistered(value);
              const isInvited = await isInviteRegistered(value);
              if (isRegistered.message || (isInvited.message && false)) {
                this.props.openSnackbarFn(
                  `Erro ${isRegistered.message} ao consultar CNPJ. Entre em contato com o suporte.`,
                  true
                );

                return false;
              }

              if (isRegistered.exists && !isInvited.exists) {
                this.props.openDnaDialogFn(
                  'Convidar empresa cadastrada',
                  'Deseja enviar um convite para esta empresa?',
                  'Sim, convidar!',
                  async () => {
                    const inviteExisting = await inviteExistingCompany(value);
                    if (inviteExisting.message) {
                      this.props.openSnackbarFn(
                        `Erro ao enviar convite. Se o erro persistir, entre em contato com o suporte avisando sobre o erro: ${inviteExisting.message}`,
                        true
                      );

                      return false;
                    }
                    this.props.updateCompaniesStateFn(
                      companyInviteAdapter(inviteExisting),
                      true
                    );
                    this.props.closeModalFn();
                    this.props.openSnackbarFn('Empresa convidada com sucesso!');
                  }
                );

                return false;
              }
            }

            return true;
          }
        }
      )
      .required('Este campo é obrigatório.'),
  });

  companyPreRegisterSchema = Yup.object().shape({
    companyName: Yup.string().notRequired().nullable(),
    organizationNumber: Yup.string()
      .matches(cnpjRegex, { message: 'Informe um CNPJ válido.' })
      .test(
        'organizationNumberValidation',
        'Informe um CNPJ válido.',
        (value) => {
          if (value) {
            return validateCNPJ(value);
          }

          return false;
        }
      )
      .test(
        'organizationExists',
        'CNPJ já existe em sua lista.',
        async (value) => {
          if (value) {
            if (!this.props.isEdit) {
              const isInvited = await isInviteRegistered(value);
              if (isInvited.message && false) {
                this.props.openSnackbarFn(
                  `Erro ${isInvited.message} ao consultar CNPJ. Entre em contato com o suporte.`,
                  true
                );
                return false;
              }
              return !isInvited.exists || false;
            }
            return validateCNPJ(value);
          }

          return false;
        }
      )
      .test(
        'organizationRegistered',
        'Esta empresa já é cadastrada.',
        async (value) => {
          if (value) {
            if (!this.props.isEdit) {
              const isRegistered = await isCompanyRegistered(value);
              const isInvited = await isInviteRegistered(value);
              if (isRegistered.message || (isInvited.message && false)) {
                this.props.openSnackbarFn(
                  `Erro ${isRegistered.message} ao consultar CNPJ. Entre em contato com o suporte.`,
                  true
                );

                return false;
              }

              if (isRegistered.exists && !isInvited.exists) {
                this.props.openDnaDialogFn(
                  'Convidar empresa cadastrada',
                  'Deseja enviar um convite para esta empresa?',
                  'Sim, convidar!',
                  async () => {
                    const inviteExisting = await inviteExistingCompany(value);
                    if (inviteExisting.message) {
                      this.props.openSnackbarFn(
                        `Erro ao enviar convite. Se o erro persistir, entre em contato com o suporte avisando sobre o erro: ${inviteExisting.message}`,
                        true
                      );

                      return false;
                    }
                    this.props.updateCompaniesStateFn(
                      companyInviteAdapter(inviteExisting),
                      true
                    );
                    this.props.closeModalFn();
                    this.props.openSnackbarFn('Empresa convidada com sucesso!');
                  }
                );

                return false;
              }
            }

            return true;
          }
        }
      )
      .required('Este campo é obrigatório.'),
    selectedState: Yup.object()
      .shape({
        label: Yup.string().nullable().notRequired(),
        value: Yup.string().nullable().notRequired(),
      })
      .notRequired()
      .nullable(),
    selectedCity: Yup.object()
      .shape({
        label: Yup.string().nullable().notRequired(),
        value: Yup.mixed().nullable().notRequired(),
      })
      .notRequired()
      .nullable(),
    staffName: Yup.string()
      .test(
        'staffMemberValidation',
        'Por gentileza, informe o nome do responsável pela empresa',
        (value) => {
          if (this.props.minimalForm) {
            if (value) return true;
            return false;
          }
          return true;
        }
      )
      .notRequired()
      .nullable(),
    staffEmail: Yup.string()
      .test(
        'staffMemberEmailValidation',
        'Por gentileza, informe o email do responsável pela empresa',
        (value) => {
          if (this.props.minimalForm) {
            if (value) return true;
            return false;
          }
          return true;
        }
      )
      .notRequired()
      .nullable(),
    staffPhone: Yup.string()
      .notRequired()
      .nullable()
      .matches(mobilePhoneNumberRegex, 'Informe um número de telefone válido.'),
  });

  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        id: null,
        companyName: '',
        organizationNumber: '',
        staffName: '',
        staffPhone: '',
        staffEmail: '',

        address: '',
        addressNumber: '',
        addressComplement: '',
        district: '',
        zipCode: '',

        selectedState: '',
        selectedCity: '',

        mandatory_internship: false,
        non_mandatory_internship: false,
        send_invite_email: true,
        jobOwnerCompany: false,
      },
      fieldId: '',
      files: {
        agreementDocumentOneFile: null,
        agreementDocumentTwoFile: null,
      },
      isAgreementDocumentOneFile: false,
      isAgreementDocumentTwoFile: false,
      requiredDocument: false /*Documento obrigatório  */,
      saveDocument: false /* Documento sem checkbox */,
    };

    this.newCompany = null;
    this.staffPhoneRef = React.createRef();
  }
  setFile = (files) => {
    this.setState({
      files: files,
    });
  };
  setIsAgreementDocumentOneFile = (value) => {
    this.setState({
      isAgreementDocumentOneFile: value,
    });
  };
  setIsAgreementDocumentTwoFile = (value) => {
    this.setState({
      isAgreementDocumentTwoFile: value,
    });
  };

  setRequiredDocument = (value) => {
    this.setState({
      requiredDocument: value,
    });
  };

  setSaveDocument = (value) => {
    this.setState({
      saveDocument: value,
    });
  };

  UNSAFE_componentWillMount() {
    if (this.props.isEdit) {
      const { company } = this.props;
      this.setState({
        initialValues: {
          id: company.id,
          role: company.role,
          companyName: company.name,
          organizationNumber: company.organizationNumber,

          staffName: company.staff.name,
          staffPhone: company.staff.phone,
          staffEmail: company.staff.email,

          selectedState: company.address.state,
          selectedCity: {
            label:
              company.address && company.address.city
                ? company.address.city
                : '',
          },
          selectedState: {
            label: company.address && company.address.state,
            value: company.address && company.address.state,
          },

          mandatory_internship: company.mandatory_internship,
          non_mandatory_internship: company.non_mandatory_internship,
          jobOwnerCompany: company.jobOwnerCompany,
        },
      });
    }
    const { companyPreData } = this.props;
    if (companyPreData) {
      this.setState({
        initialValues: {
          companyName: companyPreData.name,
          organizationNumber: companyPreData.organization_number,
          send_invite_email: true,
          mandatory_internship: true,
          non_mandatory_internship: false,
          jobOwnerCompany: false,
          staffName: null,
          staffPhone: null,
          staffEmail: null,
        },
      });
    }
  }

  getFieldId = (fieldId) => {
    this.setState({
      fieldId,
    });
  };

  handleFieldMask = (event, mask) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      return event.target.value;
    }
    event.target.value = mask(event.target.value);
  };
  render() {
    const {
      classes,
      company,
      isEdit,
      minimalForm,
      closeModalFn,
      updateCompaniesStateFn,
      handleModalSubmit,
      openSnackbarFn,
      jobRegistration,
      handleIsLoadingStateFn,
      companyPreData,
      setSaveDocument,
      saveDocument,
    } = this.props;

    return (
      <Grid
        container
        className={classes.fullScreenGridContainer}
        justify="center"
      >
        <Grid item xs md={12} className={classes.fullScreenGrid}>
          <Formik
            enableReinitialize
            initialValues={this.state.initialValues}
            validationSchema={
              this.props.minimalForm
                ? this.companyPreRegisterSchema
                : this.integratorJobPartnerSchema
            }
            validateOnChange={false}
            onSubmit={async (values, actions) => {
              // case it is a company invitation creation
              // init validate document

              if (
                values.selectedCity &&
                values.selectedCity.label &&
                values.selectedCity.label === 'Selecione'
              ) {
                values.selectedCity.label = '';
              }

              if (
                (values.mandatory_internship ||
                  values.non_mandatory_internship) &&
                !this.state.isAgreementDocumentOneFile &&
                !this.state.isAgreementDocumentTwoFile
              ) {
                this.setRequiredDocument(true);
                return;
              }

              if (
                !values.mandatory_internship &&
                !values.non_mandatory_internship &&
                (this.state.isAgreementDocumentOneFile ||
                  this.state.isAgreementDocumentTwoFile)
              ) {
                setSaveDocument(true);
                return;
              }

              handleIsLoadingStateFn(true);
              this.newCompany = isEdit
                ? (this.newCompany = await updateCompanyInvite({
                    data: payloadAdapter(values),
                    files: this.state.files,
                  }))
                : await saveCompanyInvite({
                    data: payloadAdapter(values),
                    files: this.state.files,
                  });

              if (values.id) {
                this.newCompany.id = values.id;
              }

              if (!companyPreData)
                updateCompaniesStateFn(
                  companyInviteAdapter(this.newCompany),
                  true
                );
              actions.setSubmitting(false);
              setTimeout(() => {
                closeModalFn();
                openSnackbarFn('Empresa salva com sucesso!');
              }, 700);
              handleIsLoadingStateFn(false);

              if (this.newCompany.message) {
                this.props.openSnackbarFn(
                  `Erro ao gravar seus dados. Se o erro persistir, entre em contato com o suporte avisando sobre o erro: ${this.newCompany.message}`,
                  true
                );
                actions.setSubmitting(false);
                handleIsLoadingStateFn(false);
              }
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
            }) => (
              <form
                onSubmit={handleSubmit}
                onChange={(event) => {
                  this.getFieldId(event.target.id);
                }}
              >
                <fieldset
                  disabled={company ? !company.isEditable : false}
                  style={{
                    border: 'none',
                  }}
                >
                  {isEdit && (
                    <input type="hidden" id="id" name="id" value={values.id} />
                  )}
                  <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                      <FastField
                        autoFocus
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        name="organizationNumber"
                        variant="filled"
                        id="organizationNumber"
                        label="CNPJ"
                        placeholder="Ex.: 74.449.408/0001-81"
                        onKeyUp={(event) => {
                          this.handleFieldMask(event, maskCnpj);
                          handleChange(event);
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          !!errors.organizationNumber &&
                          !!touched.organizationNumber
                        }
                        value={values.organizationNumber}
                        component={TextField}
                      />
                      {!!errors.organizationNumber &&
                        !!touched.organizationNumber && (
                          <FormHelperText className={classes.helperText}>
                            {errors.organizationNumber}
                          </FormHelperText>
                        )}
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <FastField
                        className={classes.inputField}
                        InputProps={{
                          className: classes.input,
                        }}
                        name="companyName"
                        variant="filled"
                        id="companyName"
                        label="Razão social"
                        placeholder="Ex.: Workalove"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.companyName && !!touched.companyName}
                        value={values.companyName}
                        component={TextField}
                      />
                      {!!errors.companyName && !!touched.companyName && (
                        <FormHelperText className={classes.helperText}>
                          {errors.companyName}
                        </FormHelperText>
                      )}
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <CityAndStateForm
                        classes={classes}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </ListItem>
                    {minimalForm && (
                      <ListItem className={classes.listItem}>
                        <FastField
                          className={classes.inputField}
                          InputProps={{
                            className: classes.input,
                          }}
                          name="staffName"
                          variant="filled"
                          id="staffName"
                          label="Responsável"
                          placeholder="Ex.: Seu nome e Sobrenome"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={!!errors.staffName && !!touched.staffName}
                          value={values.staffName}
                          component={TextField}
                        />
                        {errors.staffName && touched.staffName && (
                          <FormHelperText className={classes.helperText}>
                            {errors.staffName}
                          </FormHelperText>
                        )}
                      </ListItem>
                    )}
                    {minimalForm && (
                      <Fragment>
                        <ListItem className={classes.listItem}>
                          <FastField
                            className={classes.inputField}
                            InputProps={{
                              className: classes.input,
                            }}
                            name="staffEmail"
                            variant="filled"
                            id="staffEmail"
                            label="E-mail do responsável"
                            placeholder="Ex.: seu.nome@dominio.com.br"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors.staffEmail && !!touched.staffEmail}
                            value={values.staffEmail}
                            component={TextField}
                          />
                          {errors.staffEmail && touched.staffEmail && (
                            <FormHelperText className={classes.helperText}>
                              {errors.staffEmail}
                            </FormHelperText>
                          )}
                        </ListItem>
                        {/* mobilePhone */}
                        <ListItem className={classes.listItem}>
                          <FastField
                            className={classes.inputField}
                            InputProps={{
                              className: classes.input,
                              ref: this.staffPhoneRef,
                            }}
                            name="staffPhone"
                            variant="filled"
                            id="staffPhone"
                            label="Celular ou Telefone Fixo"
                            placeholder="(11) 99163-8247"
                            onChange={(event) => {
                              event.target.value = maskInputPhone(
                                event.target.value
                              );
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            error={!!errors.staffPhone && !!touched.staffPhone}
                            value={values.staffPhone}
                            component={TextField}
                          />
                          {!!errors.staffPhone && !!touched.staffPhone && (
                            <FormHelperText className={classes.helperText}>
                              {errors.staffPhone}
                            </FormHelperText>
                          )}
                        </ListItem>
                      </Fragment>
                    )}

                    {/* send_invite_email */}
                    {minimalForm && (
                      <Fragment>
                        <ListItem className={classes.listItem}>
                          <Grid container>
                            <label>
                              <Checkbox
                                classes={{
                                  checked: classes.checkBox,
                                }}
                                checked={values.send_invite_email}
                                onClick={() => {
                                  setFieldValue(
                                    'send_invite_email',
                                    !values.send_invite_email
                                  );
                                }}
                                value={values.send_invite_email}
                              />
                              <span>
                                Deseja disparar o convite para a empresa ao
                                finalizar o pré cadastro?
                              </span>
                            </label>
                          </Grid>
                        </ListItem>

                        {/* mandatory_internship */}
                        <ListItem className={classes.listItem}>
                          <Grid container>
                            <label>
                              <Checkbox
                                classes={{
                                  checked: classes.checkBox,
                                }}
                                checked={values.mandatory_internship}
                                onClick={() => {
                                  setFieldValue(
                                    'mandatory_internship',
                                    !values.mandatory_internship
                                  );
                                }}
                                value={values.mandatory_internship}
                              />
                              <span>
                                Essa empresa possui convênio de estágio
                                obrigatório?
                              </span>
                            </label>
                          </Grid>
                        </ListItem>
                        {/* non_mandatory_internship */}
                        <ListItem className={classes.listItem}>
                          <Grid container>
                            <label>
                              <Checkbox
                                classes={{
                                  checked: classes.checkBox,
                                }}
                                checked={values.non_mandatory_internship}
                                onClick={() => {
                                  setFieldValue(
                                    'non_mandatory_internship',
                                    !values.non_mandatory_internship
                                  );
                                }}
                                value={values.non_mandatory_internship}
                              />
                              <span>
                                Essa empresa possui convênio de estágio não
                                obrigatório?
                              </span>
                            </label>
                          </Grid>
                        </ListItem>
                        <DropFileInput
                          name="files"
                          backgroundColor="#f9f9f9"
                          setIsAgreementDocumentOneFileExternal={
                            this.setIsAgreementDocumentOneFile
                          }
                          setIsAgreementDocumentTwoFileExternal={
                            this.setIsAgreementDocumentTwoFile
                          }
                          valueNonMandatoryInternship={
                            values.non_mandatory_internship
                          }
                          valueMandatoryInternship={values.mandatory_internship}
                          setRequiredDocument={this.setRequiredDocument}
                          requiredDocument={this.state.requiredDocument}
                          setSaveDocument={this.setSaveDocument}
                          saveDocument={saveDocument}
                          company={this.state.initialValues}
                          setFile={(e) => this.setFile(e)}
                          isEdit={isEdit}
                        />
                      </Fragment>
                    )}
                    {this.state.saveDocument == true ? (
                      <MessageError>
                        <p>
                          Ops, para salvar é necessário excluir o(s)
                          documento(s) anexado(s).
                        </p>
                      </MessageError>
                    ) : null}
                    <ListItem className={classes.listItem}>
                      <Grid container>
                        {jobRegistration ? (
                          <Button
                            className={classes.saveButton}
                            onClick={async () => {
                              if (Object.keys(errors).length === 0) {
                                await handleModalSubmit(values);
                              } else {
                                openSnackbarFn(
                                  `Preencha os campos obrigatórios.`,
                                  true
                                );
                              }
                            }}
                            round
                          >
                            Salvar
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            className={classes.saveButton}
                            round
                          >
                            Salvar
                          </Button>
                        )}
                      </Grid>
                    </ListItem>
                  </List>
                </fieldset>
              </form>
            )}
          />
        </Grid>
      </Grid>
    );
  }
}

CompanyPreRegisterForm.propTypes = {
  classes: object,
  company: object,
  isEdit: bool,
  experience: object,
  companyPreData: object,
  closeModalFn: func.isRequired,
  handleModalSubmit: func.isRequired,
  updateCompaniesStateFn: func.isRequired,
  openSnackbarFn: func.isRequired,
  handleIsLoadingStateFn: func.isRequired,
  openDnaDialogFn: func.isRequired,
};
export default withStyles(ProfessionalExperienceFormStyles)(
  CompanyPreRegisterForm
);
