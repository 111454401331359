import { useLearningIcons } from "views/Student/Learning/Hooks/useLearningIcons";
import { Dots, ContainerDots, ArrowButton } from "./style";
import {useContext} from 'react'
import BaseLayoutContext from "contexts/base-layout";

export const NavigationDots = ({ totalSlides, activeSlide, onDotClick, setActiveSlide }) => {
  const {universityColor} = useContext(BaseLayoutContext)

  const {MdKeyboardArrowLeft, MdKeyboardArrowRight} = useLearningIcons()

  const renderDots = () => {
    
    const dots = Array.from({ length: totalSlides }, (_, index) => (
      <Dots
        key={index}
        isActive={activeSlide === index}
        onClick={() => onDotClick(index)}
        universityColor={universityColor}
      >
        {index + 1}
      </Dots>
    ));
    return dots;
  };
  
  const handleNext = () => {
    if (activeSlide !== totalSlides - 1) {
      setActiveSlide(prevs => prevs + 1)
    }
  }

  const handleReturn = () => {
    if (activeSlide !== 0) {
      setActiveSlide(prevs => prevs - 1)
    }
  }

  return (
    <ContainerDots>
      <ArrowButton
        universityColor={universityColor}
        onClick={() => handleReturn()}
      >
        <MdKeyboardArrowLeft size={15} />
      </ArrowButton>
        {renderDots()}
      <ArrowButton
        universityColor={universityColor}
        onClick={() => handleNext()}
      >
        <MdKeyboardArrowRight size={15} />
      </ArrowButton>
    </ContainerDots>
  )
};