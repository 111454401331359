import React, { useEffect, useRef } from 'react';
import { ContentCircularProgress } from '../../Styles/StyleGraphics';

const CircularProgress = ({ progress, label, totalWidth = 100 }) => {
  const radius = 60;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * progress) / 100;
  const circleWidth = 140;

  const circleRef = useRef(null);

  useEffect(() => {
    const circle = circleRef.current;
    if (circle) {
      circle.style.transition = 'stroke-dashoffset 0.5s ease-in-out';
      setTimeout(() => {
        circle.style.strokeDashoffset = dashOffset;
      }, 50);
    }
  }, [dashOffset]);

  return (
    <ContentCircularProgress
      progress={progress}
      totalWidth={totalWidth}
    >
      <svg
        width={circleWidth}
        height={circleWidth}
        viewBox={`0 0 ${circleWidth} ${circleWidth}`}
        style={{
          objectFit: 'fill'
        }}
      >
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="16px"
          r={radius}
          className="circle-background"
        />

        <circle
          ref={circleRef}
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="16px"
          r={radius}
          className="circle-progress"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashArray,
            transform: 'rotate(-90deg)',
            transformOrigin: 'center',
          }}
        />
        <g className="text-group">
          <text
            x="53%"
            y="53%"
            dy="0.3em"
            textAnchor="middle"
            className="circle-text-match"
          >
            {progress}%
          </text>
        </g>
      </svg>
      <p>{label}</p>
    </ContentCircularProgress>
  );
};

export default CircularProgress;
