import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: block;

  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => (props.margin ? `margin-top: ${props.margin}px` : '')};

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  background-color: #fff;
  width: 900px;
  border-radius: 4px;
`;

export const HeaderGradient = styled.div`
  background: linear-gradient(${(props) => props.color}, #fff);
  height: 120px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  position: relative;
`;

export const Logo = styled.img``;

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 10px 0px;

  > h3 {
    color: #606062;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export const ContainerInformations = styled.div`
  background-color: #f8fafc;
  border-radius: 4px;
  display: flex;
  gap: 20px;
  padding: 5px 10px;

  > span {
    display: flex;
    align-items: center;
    gap: 10px;

    > p {
      margin: 0;
      font-weight: 400;
      color: #606062;
    }

    > div {
      background-color: #00929126;
      padding: 4px;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;

      > img {
        width: 13px;
      }
    }
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -70px;

  > img {
    width: 130px;
    height: 130px;
    object-fit: contain;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1;
    padding: 0px 5px;
  }
`;

export const Descriptions = styled.div`
  background-color: #f8fafc;
  padding: 10px 40px;
  border-radius: 4px;

  > h2 {
    font-size: 1.5em;
    color: #606062;
    font-weight: bold;
    margin: 0;
  }
`;

export const ContainerDescriptions = styled.div`
  min-height: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 41px 30px;

  > iframe {
    border: none;
  }
`;
