import React from 'react';
import { string, shape } from 'prop-types';

import { Document, Font } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

import certificado from 'assets/img/certificado.png';

Font.register({
  family: 'Forum',
  src: 'https://raw.githubusercontent.com/google/fonts/master/ofl/forum/Forum-Regular.ttf',
});

// Create Styled Components
const Body = styled.Page`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: normal;
  font-family: 'Forum';
  font-size: 20pt;
  color: #4e4e50;
  user-select: none !important;
`;

const Background = styled.Image`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  display: block;
  height: 100%;
  width: 100%;
`;

const HeaderText = styled.Text`
  z-index: 1;
  top: 200px;
`;

const Name = styled.Text`
  font-size: 60pt;
  position: absolute;
  z-index: 1;
  top: 260px;
  text-transform: uppercase;
  color: #333;
`;

const HoursText = styled.Text`
  position: absolute;
  z-index: 1;
  top: 340px;
`;

const DateText = styled.Text`
  position: absolute;
  z-index: 1;
  top: 360px;
`;

// Create Document Component
const Certificate = (props) => {
  const { trailTitle, certificateData } = props;

  return (
    <Document
      title={`Certificado trilha ${trailTitle}`}
      author="Workalove"
      creator="Workalove"
      producer="Workalove"
    >
      <Body size="A4" wrap={false} orientation="landscape">
        <Background src={certificado} />
        <HeaderText>{`Certifica a conclusão do curso de`}</HeaderText>
        <HeaderText>{`${trailTitle} à`}</HeaderText>
        <Name>{certificateData.student.split(/(\s).+\s/).join('')}</Name>
        <HoursText>{`com carga horária de ${certificateData.totalHours} horas,`}</HoursText>
        <DateText>
          {`Finalizado em ${certificateData.completionDate}. Válido por 2 anos.`}
        </DateText>
      </Body>
    </Document>
  );
};

Certificate.propTypes = {
  trailTitle: string.isRequired,
  certificateData: shape.isRequired,
};

export default Certificate;
