import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 0;
  padding: 1rem;

  @media (max-width: 600px) {
    width: 100%;
  }

  h2 {
    font-family: Roboto, sans-serif;
    margin: 0;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-size: 22px;
    line-height: 18px;
    font-weight: 600;
    max-width: 16rem;
    text-align: center;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    color: #4b4b4d;
    line-height: 23px;
    margin-top: 18px;
    max-width: 222px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 2.5rem;
  }
`;
