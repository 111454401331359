import styled from 'styled-components';
import closeIcon from 'assets/Oportutinites/X.svg';

export const ModalHeader = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;
export const CloseButton = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  transition: filter 0.2s;
  background-image: url(${closeIcon});

  &:hover {
    filter: brightness(0.9);
  }
`;
export const Container = styled.div`
  width: 15rem;
  height: 3rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const StepItem = styled.button`
  display: flex;
  width: 7.5rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: none;
  cursor: default;
  background-color: #fff;
  transition: filter 0.2s;
  padding: 0.25rem 0 0;
  border-radius: 0.25rem;

  span {
    margin-top: 4px;
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 700;
    background: ${(props) => (props.active ? '#009291' : '#F5F5F5')};
    color: ${(props) => (props.active ? '#FFF' : '#CDCDCD')};
  }

  &:after {
    content: '';
    height: 0.25rem;
    width: 100%;
    background: ${(props) => (props.active ? '#009291' : '#F5F5F5')};
  }
`;
