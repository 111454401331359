import React, { useContext, useEffect, useState } from 'react';
import {
  Wrapper,
  ContainerTypeName,
  ContainerActions,
  PopUp,
  Status,
} from './style';
import Trash from 'components/UploadEvidences/assets/Trash.svg';
import PDFIcon from 'assets/ComponentIcons/PDFIcon';
import { 
  JPGIcon, 
  MP4Icon, 
  PNGIcon, 
  RARIcon, 
  ZIPIcon 
} from 'assets/ComponentIcons/evidencesIcon';
import BaseLayoutContext from 'contexts/base-layout';
import PdfViwer from '../PdfViwer';
import ImageViwer from '../ImageViwer';
import eye from 'assets/JobOffer/eye.svg';
import feedback from 'assets/ExtensionProject/feedback.svg';
import ModalFeedbackExtensionProject from '../../ModalViewFeedback';
import moment from 'moment';
import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';
import { DefaultEyeIcon } from 'assets/ComponentIcons/DefaultEye';

export default function ListFiles({
  idPost,
  item,
  functionDeleteEvidence,
  filesList,
  setFilesList, 
  setModalInformationConfig,
  setModalInformationOpen,
  setUsingPreviewPdf,
}) {
  const { openModalWithContent, closeModal, universityColor } = useContext(BaseLayoutContext);

  const [fileData, setFileData] = useState(null);
  const [fileIsImage, setFileIsImage] = useState(false);

  const [openFeedBack, setOpenFeedBack] = useState(false);

  useEffect(() => {
    if (fileData) {
      callModal(fileData);
    }
  }, [fileData]);

  useEffect(() => {
    if (fileIsImage) {
      callModalImage();
    }
  }, [fileIsImage]);

  const icons = {
    '.pdf': <PDFIcon color={universityColor || "#009291"} size={22}/>,
    '.png': <PNGIcon color={universityColor || "#009291"} size={22}/>,
    '.jpg': <JPGIcon color={universityColor || "#009291"} size={22}/>,
    '.jpeg': <JPGIcon color={universityColor || "#009291"} size={22}/>,
    '.mp4': <MP4Icon color={universityColor || "#009291"} size={22} />,
    '.zip': <ZIPIcon color={universityColor || "#009291"} size={22} />,
    '.rar': <RARIcon color={universityColor || "#009291"} size={22} />,
  };

  const returnName = () => {
    if (item.file?.name && !item.file?.size) {
      return item.file.name;
    }
    if (item.image?.name && !item.image?.size) {
      return item.image.name;
    }
    return item.name;
  };

  const returnIcon = () => {
    if (icons[item.type]) {
      return icons[item.type];
    }

    if (item.file?.suffix) {
      return icons[item.file.suffix];
    }
    if (item.image?.suffix) {
      return icons[item.image.suffix];
    }
  };

  const handleConfirmDelete = () => {
    const { createdNow } = item;
    const removeItemArray = filesList.filter((file) => file.id !== item.id);
    setFilesList(removeItemArray);

    if (!createdNow) {
      functionDeleteEvidence(idPost, item.id); 
    }
  };

  const configDelete = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza que deseja excluir a evidência "${returnName()}"?`,
    buttonConfirmText: 'SIM, EXCLUIR EVIDÊNCIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    modalHeight: 300,
    buttonConfirmFunction: handleConfirmDelete,
  };

  const handleClickDeleteIcon = () => {
    handleConfirmDelete();
  };

  const handleClosePreview = () => {
    setUsingPreviewPdf(false);
    setFileData(null);
    setFileIsImage(false);
  };

  const callModalImage = () => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <ImageViwer 
        universityColor={universityColor} 
        file={item} 
        name={returnName()} 
        type={item.image.suffix}
      />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const callModal = (file) => {
    setUsingPreviewPdf(true);
    openModalWithContent(
      <PdfViwer universityColor={universityColor} file={file} name={returnName()} />,
      null,
      null,
      () => handleClosePreview()
    );
  };

  const handleClickPreview = () => {
    if (item.image?.name || item.type === '.png' || item.type === '.jpeg') {
      setFileIsImage(true);
      return;
    }
    const file = item.file.file || item.file;
    setFileData(file);
  };

  const canUseActions = item.analyze ? false : true;
  const hasFeedBack =
    item.analyze &&
    Object.keys(item.analyze).length > 0 &&
    item.analyze.feedback.feedback !== '';

  const colors = {
    refused: {
      label: 'RECUSADO',
      color: '#DA0505',
      background: '#FFEDED',
    },
    accepted: {
      label: 'ACEITO',
      color: '#068E2C',
      background: '#D9FFE3',
    },
    isNull: {
      label: 'AGUARDANDO ANÁLISE',
      color: '#E9B600',
      background: '#FFFDED',
    },
  };

  const deleteIcon = () => {
    if (canUseActions) {
      return (
        <button onClick={handleClickDeleteIcon}>
          <PopUp label="Excluir" universityColor={universityColor || "#009291"}>
            <DefaultTrash 
              alt="trash" 
              color={universityColor || "#009291"}
            />        
          </PopUp>
        </button>
       
      );
    }
  };

  const seeFeedback = () => {
    if (hasFeedBack) {
      return (
        <PopUp label="visualizar feedback">
          <img
            src={feedback}
            alt="feedback"
            onClick={() => setOpenFeedBack(true)}
          />
        </PopUp>
      );
    }
  };

  return (
    <Wrapper>
      {openFeedBack && (
        <ModalFeedbackExtensionProject
          isOpen={openFeedBack}
          setOpenModalFeedback={setOpenFeedBack}
          feedback={item.analyze?.feedback}
        />
      )}
      <ContainerTypeName>
        {returnIcon()}
        <div>
          <div className="nameEvidence">
            <p>{returnName()}</p>
            {item.analyze ? (
              <Status
                color={colors[item.analyze.status].color}
                background={colors[item.analyze.status].background}
              >
                {colors[item.analyze.status].label}
              </Status>
            ) : (
              <Status
                color={colors['isNull'].color}
                background={colors['isNull'].background}
              >
                {colors['isNull'].label}
              </Status>
            )}
          </div>
          <p className="date">
            Enviado dia
            <span>
              {moment(item.created_at).format('DD/MM/YYYY')} às{' '}
              {moment(item.created_at).format('H:mm ')}
            </span>
          </p>
        </div>
      </ContainerTypeName>
      <ContainerActions>
        {deleteIcon()}
      {seeFeedback()}
        <button onClick={handleClickPreview}>
          <PopUp label="Visualizar" universityColor={universityColor || "#009291"}>
            <DefaultEyeIcon
              size={20}
              color={universityColor || "#009291"} 
              alt="eye" 
            />
          </PopUp>
        </button>
      </ContainerActions>
    </Wrapper>
  );
}
