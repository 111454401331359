import { SearchInputWrapper } from './styles';

import { useEffect } from 'react';
import { useDebounce } from './useDebounce';

export function SearchInputWithDebounce({
  searchTerm,
  setSearchTerm,
  onSearch,
  onCancelSearch,
  placeholder = 'Buscar',
}) {
  const debouncedSearchTerm = useDebounce(searchTerm, 500, onCancelSearch);

  useEffect(() => {
    onSearch && onSearch(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <SearchInputWrapper
      placeholder={placeholder}
      onChange={setSearchTerm}
      value={searchTerm}
    />
  );
}
