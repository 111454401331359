import React, { useState, useContext } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { arrayOf, func, shape, bool } from 'prop-types';
import StarRate from 'views/Company/components/Rating/StarRate';
import ProfessionRequirementsDetails from './ProfessionRequirementsDetails';
import { HiOutlineEye } from 'react-icons/hi';
import useDefineColor from 'constants/colors';
import {
  CardProfessions,
  ButtonProfessions,
  TitleProfessions,
  CardSpanStarProfessions,
  SpanProfessions,
  LineCardProfessions,
  CardInfosProfessions,
  ContainerButton,
} from './stylesProfessionsList';

import BaseLayoutContext from 'contexts/base-layout';

const ProfessionsList = (props) => {
  const {
    classes,
    areasOfExpertiseState,
    openModalWithContentFn,
    loading,
    favoriteHandler,
    favoriteProfessions,
  } = props;

  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <div className={classes.areaGap}>
      {loading && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} md={12}>
            <CircularProgress
              className={classes[`${useDefineColor(universityColor)}Loading`]}
            />
          </Grid>
        </Grid>
      )}
      {areasOfExpertiseState.length > 0 &&
        areasOfExpertiseState.map(
          (area) =>
            area.profession && (
              <CardProfessions
                item
                container
                xs={12}
                md={12}
                sm={12}
                key={area.profession.id}
              >
                <CardInfosProfessions style={{ padding: '0px !important' }}>
                  <CardSpanStarProfessions>
                    <SpanProfessions universityColor={universityColor}>
                      {(area.score.average * 10).toFixed(0)}%
                    </SpanProfessions>
                    <div className={classes.iconAlign}>
                      <StarRate
                        style={{ fontSize: 40 }}
                        marked={favoriteProfessions.find(
                          (item) => item === area
                        )}
                        favoriteHandler={favoriteHandler}
                        profession={area}
                      />
                    </div>
                  </CardSpanStarProfessions>
                  <div className={classes.profession}>
                    <TitleProfessions>{area.profession.name}</TitleProfessions>
                  </div>
                </CardInfosProfessions>
                <LineCardProfessions />
                <ContainerButton>
                  <ButtonProfessions
                    disableRipple
                    universityColor={universityColor}
                    onClick={() => {
                      openModalWithContentFn(
                        <ProfessionRequirementsDetails
                          profession={area}
                          favoriteProfessions={favoriteProfessions}
                          favoriteHandler={favoriteHandler}
                        />,
                        <strong>{area.profession.name}</strong>
                      );
                    }}
                  >
                    Ver detalhes <HiOutlineEye size={20} />
                  </ButtonProfessions>
                </ContainerButton>
              </CardProfessions>
            )
        )}
    </div>
  );
};

ProfessionsList.propTypes = {
  classes: shape.isRequired,
  areasOfExpertiseState: arrayOf(shape).isRequired,
  favoriteProfessions: arrayOf(shape).isRequired,
  openModalWithContentFn: func.isRequired,
  favoriteHandler: func.isRequired,
  loading: bool.isRequired,
};
export default ProfessionsList;
