import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;
`;

export const ContainerDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  > form > div {
    display: flex;
  }

  > form {
    width: 100%;
  }
`;

export const ContainerAddress = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerSelectAndInput = styled.div`
  display: flex;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }
`;

export const ContainerInputsDate = styled.div`
  display: flex;

  gap: 10px;
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
