import React from 'react';

export default function MacroAreas({ item, visionGroup }) {
  const returnUniversities = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].universities &&
        item.target_audiences[0].universities.length
      ) {
        const result = item.target_audiences[0].universities.map(
          (item) => item.label
        );
        return result.join(', ');
      }
    }
    return 'Todas as Instituições';
  };

  const returnMacroAreas = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].macro_areas &&
        item.target_audiences[0].macro_areas.length
      ) {
        const result = item.target_audiences[0].macro_areas.map(
          (item) => item.label
        );
        return result.join(', ');
      }
    }
    return 'Todas as áreas de atuação';
  };

  const returnCourses = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].courses &&
        item.target_audiences[0].courses.length
      ) {
        const result = item.target_audiences[0].courses.map(
          (item) => item.label
        );
        return result.join(', ');
      }
    }
    return 'Todos os cursos';
  };

  const returnPeriods = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].semesters &&
        item.target_audiences[0].semesters.length
      ) {
        let result = item.target_audiences[0].semesters.map((item) => item);
        if (result.length <= 1) {
          return (result = result + 'º Semestre');
        }
        const arrayLength = result.length;
        result[arrayLength - 1] = `${result[arrayLength - 1] + 'º Semestre'}`;

        return result.join('º Semestre, ');
      }
    }
    return 'Todos os períodos';
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginTop: '40px' }}>
        <h3 style={{ fontWeight: 'bold', color: '#009291', fontSize: '20px' }}>
          Público-Alvo
        </h3>
        <div style={{ marginTop: '20px' }}>
          {visionGroup && (
            <div>
              <h6 style={{ fontSize: '14px' }}>
                Instituições de ensino relacionadas:{' '}
              </h6>
              <p
                style={{
                  maxWidth: '400px',
                  marginTop: '-5px',
                }}
              >
                {returnUniversities()}
              </p>
            </div>
          )}

          <div>
            <h6 style={{ fontSize: '14px' }}>
              Áreas de atuação relacionadas:{' '}
            </h6>
            <p
              style={{
                maxWidth: '400px',
                marginTop: '-5px',
              }}
            >
              {returnMacroAreas()}
            </p>
          </div>
          <div>
            <h6 style={{ fontSize: '14px' }}>Cursos relacionados:</h6>
            <p
              style={{
                maxWidth: '400px',
                marginTop: '-5px',
              }}
            >
              {returnCourses()}
            </p>
          </div>
          <div>
            <h6 style={{ fontSize: '14px' }}>Período:</h6>
            <p
              style={{
                maxWidth: '400px',
                marginTop: '-5px',
              }}
            >
              {returnPeriods()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
