import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 255px;
  height: 100%;
  max-height: 300px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 16px;
`;

export const WrapperInfos = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  strong {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 8px;
    text-transform: capitalize !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    height: 33px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 221px;
    word-break: break-word;
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    padding-bottom: 1rem;
    color: #949494;
    line-height: 1rem;
  }
`;

export const VisualIndicator = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  gap: 0.5rem;

  .icon {
    width: 28px;
    height: 28px;
    padding: 4px;
    border-radius: 3px;
    background: #e0ffff;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon img {
    width: 20px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 11.5px;
    color: #606062;
    margin: 0 !important;
    line-height: 0.89rem;
  }

  button {
    background-color: transparent;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #009291;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
    padding: 0;
  }
`;

export const SimpleBar = styled.div`
  height: 1px;
  width: 100%;
  border: 0.8px solid #e5e5e5;
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  height: 27px;

  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  max-width: 100px;
  background-color: #009291;

  border: 1px solid #009291;
  border-radius: 4px;

  padding: 0;
  margin-top: 12px;
  margin-bottom: 15px;

  cursor: pointer;
`;

export const ButtonDelete = styled.button`
  width: 100%;
  max-width: 140px;
  border: 1px solid;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #606062;
  font-size: 12px;

  background-color: transparent;
  border: none;

  padding-top: 20px;
  padding-left: 0;

  cursor: pointer;
`;

export const ButtonEdit = styled.button`
  width: 100%;
  max-width: 43px;

  display: flex;
  align-items: center;

  color: #009291;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;

  background-color: transparent;
  border: none;

  padding-top: 20px;
  padding-left: 0;

  cursor: pointer;
`;

export const ListButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #009291;
  cursor: pointer;
  text-decoration: underline;
`;
