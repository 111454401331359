import {
  ButtonDetails,
  ButtonEdit,
  Circle,
  ContainerButtons,
  ContainerCard,
  Content,
  DataContent,
  Image,
  Information,
  Like,
  MissingTime,
  ProgressBar,
  ProgressBarPercentual,
  Title,
  VacanciesFilled,
  ViewStudents,
} from './styled';
import { BiLike, BiTimeFive } from 'react-icons/bi';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { BsCalendar4Week } from 'react-icons/bs';
import moment from 'moment';
import { HiUser } from 'react-icons/hi';
import GraduationCap from 'assets/ExtensionProject/GraduationCap.svg';
import Loading from '../../../Loading';
import { useCallback, useContext, useState } from 'react';
import Tooltip from 'components/NewTooltip';

import {
  getCourses,
  getDetailsExtensionProject,
  getEditExtensionProjectValues,
  getPeriod,
} from '../../../../../../services';
import { ExtensionProjectsContext } from '../../../../../../Context/ExtensionProjectsContext';
import { handleCreateExtensionProject } from '../../../../../../utils/handleFunctions';
import { OportunitiesContext } from '../../../../../../Context/OportunitiesContext';

export function PublishedCard({
  ref,
  item,
  waitingForStart,
  setDetails,
  setIsOpen,
  handleEnrolledStudents,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [showFullName, setShowFullName] = useState(false);

  const {
    setFormValues,
    setCourses,
    setPeriods,
    setModalIsOpen,
    setModalContent,
    setActiveTab,
  } = useContext(OportunitiesContext);

  const getDataCoursesAndPeriods = useCallback((extensionProject) => {
    function getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    }

    const macroAreas = extensionProject.macro_areas;
    const courses = extensionProject.courses;

    const searchedCourses = [];
    const searchedPeriods = [];

    if (macroAreas) {
      macroAreas.forEach((e) => {
        searchedCourses.push(`macroareas=${e}&`);
      });

      const setCourseRequest = async () => {
        const send = searchedCourses.join('');
        const response = await getCourses(_, send, false);
        setCourses(response);
      };

      setCourseRequest();
    }

    if (courses) {
      courses.forEach((e) => {
        searchedPeriods.push(`courses=${e.value.toString().replace('c', '')}&`);
      });
      const sendPeriodoRequest = async () => {
        const send = searchedPeriods.join('');
        const response = await getPeriod(send);
        setPeriods(getUniqueListBy(response, 'label'));
        setFormValues({
          semesters: extensionProject.semesters.map((e) => `${e}p`),
        });
      };

      sendPeriodoRequest();
    }
  }, []);
  const handleClickEdit = async (item) => {
    setLoadingEdit(true);
    const response = await getEditExtensionProjectValues(item.id);
    const address = response.address;
    delete response.address;
    const extensionProject = response.extension_project;
    delete response.extension_project;
    const targetAudiences = response.target_audiences[0];
    delete response.target_audiences;
    const data = {
      ...response,
      ...address,
      ...extensionProject,
      ...targetAudiences,
      courses: targetAudiences.courses.map((e) => ({
        ...e,
        value: `${e.value}c`,
      })),
      attraction_image: null,
      uuid: item.id,
      registered_students: item.extension_project.students_registered,
    };
    if (response.attraction_image) {
      const urlImg = `data:application/pdf;base64, ${response.attraction_image.value}`;
      data.attraction_image = await fetch(urlImg)
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], `${response.attraction_image.label}`, {
            type: 'image/png',
          });
        });
    }
    getDataCoursesAndPeriods(data);
    setFormValues(data);
    setFormValues(data);
    handleCreateExtensionProject(setModalIsOpen, setModalContent, setActiveTab);
    setLoadingEdit(false);
  };

  const { setExtensionProjectId } = useContext(ExtensionProjectsContext);

  const handleMoreInfo = async (item) => {
    setExtensionProjectId(item.extension_project.id);
    setLoadingDetails(true);
    const data = await getDetailsExtensionProject(item.id);
    setDetails(data);
    setIsOpen(true);
    setLoadingDetails(false);
  };

  const strReduced = (str) => {
    if (str.length > 20) {
      return `${str.substr(0, 20)}...`;
    }
    return str;
  };

  return (
    <ContainerCard ref={ref}>
      {item.attraction_image.logo ? (
        <Image>
          <img src={item.attraction_image.image} />
        </Image>
      ) : (
        <Image>
          <img
            src={item.attraction_image.image}
            style={{
              width: '340px',
              height: '210px',
              borderTopLeftRadius: '11.5652px',
              borderTopRightRadius: '11.5652px',
              position: 'absolute',
              top: 0,
            }}
          />
        </Image>
      )}

      <Like>
        <BiLike size={17} />
        <p>{item.like_quantity}</p>
      </Like>
      <VacanciesFilled>
        <p>
          {item.extension_project.enrolled_students_quantity} vagas preenchidas
        </p>
      </VacanciesFilled>
      {waitingForStart && (
        <MissingTime border={item.attraction_image.logo}>
          <BiTimeFive size={17} />
          <p>{item.extension_project.reminder}</p>
        </MissingTime>
      )}
      <DataContent>
        <ProgressBar>
          <ProgressBarPercentual
            percentual={String(
              item.extension_project.enrolled_students_percentual
            )}
          />
        </ProgressBar>
        <Information>
          {item.extension_project.enrolled_students_percentual}
          {''} vagas preenchidas
        </Information>

        <Title title={item.name}>{item.name}</Title>

        <Content>
          <div className="wrapper-content">
            <IoExtensionPuzzleOutline size={20} color="#009291" />
            <p style={{ paddingLeft: '7px' }}>{item.extension_project.type}</p>
          </div>
          <div className="wrapper-content">
            <BsCalendar4Week size={17} color="#009291" />
            <p>
              De {''}
              {moment(item.start_at).format('DD/MM/YYYY')} {''}a {''}
              {moment(item.end_at).format('DD/MM/YYYY')} {''}
            </p>
          </div>
          <div className="wrapper-content">
            <Circle>
              <HiUser size={16} color="#ffffff" />
            </Circle>
            {item.extension_project.creator &&
            item.extension_project.creator.length <= 20 ? (
              <p>{item.extension_project.creator}</p>
            ) : (
              <Tooltip text={item.extension_project.creator}>
                <p>{strReduced(item.extension_project.creator)}</p>
              </Tooltip>
            )}
          </div>
          <div className="wrapper-content">
            <img src={GraduationCap} />
            <p>
              {(item.extension_project.students_registered > 1 ||
                item.extension_project.students_registered === 0) &&
                `${item.extension_project.students_registered} estudantes inscritos`}
              {item.extension_project.students_registered === 1 &&
                `${item.extension_project.students_registered} estudante inscrito`}
              {item.extension_project.students_registered > 0 && (
                <ViewStudents onClick={() => handleEnrolledStudents(item)}>
                  {' '}
                  ver{' '}
                </ViewStudents>
              )}
              {item.extension_project.students_registered === 0 &&
                item.extension_project.has_register === true && (
                  <ViewStudents onClick={() => handleEnrolledStudents(item)}>
                    {' '}
                    ver{' '}
                  </ViewStudents>
                )}
            </p>
          </div>
        </Content>
        <ContainerButtons>
          <ButtonDetails onClick={() => handleMoreInfo(item)}>
            {loadingDetails ? (
              <Loading color={'#fff'} />
            ) : (
              'Visualizar detalhes'
            )}
          </ButtonDetails>
          {item.is_published && (
            <ButtonEdit onClick={() => handleClickEdit(item)}>
              {loadingEdit ? <Loading color={'#009291'} /> : 'Editar'}
            </ButtonEdit>
          )}
        </ContainerButtons>
      </DataContent>
    </ContainerCard>
  );
}
