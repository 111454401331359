import React from 'react';

function LogoNetworkNotFound({ color }) {
  return (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99951 20.0039H10.4995V23.0039H5.99951V20.0039ZM13.4995 23.0039H17.9995V20.0039H13.4995V23.0039ZM5.99951 29.0039H10.4995V26.0039H5.99951V29.0039ZM13.4995 29.0039H17.9995V26.0039H13.4995V29.0039ZM5.99951 11.0039H10.4995V8.00391H5.99951V11.0039ZM13.4995 11.0039H17.9995V8.00391H13.4995V11.0039ZM5.99951 17.0039H10.4995V14.0039H5.99951V17.0039ZM13.4995 17.0039H17.9995V14.0039H13.4995V17.0039ZM35.9995 12.5039V36.5039H-0.000488281V5.00391C-0.000488281 3.81043 0.473617 2.66584 1.31753 1.82193C2.16144 0.978012 3.30604 0.503906 4.49951 0.503906L19.4995 0.503906C20.693 0.503906 21.8376 0.978012 22.6815 1.82193C23.5254 2.66584 23.9995 3.81043 23.9995 5.00391V8.00391H31.4995C32.693 8.00391 33.8376 8.47801 34.6815 9.32193C35.5254 10.1658 35.9995 11.3104 35.9995 12.5039ZM20.9995 5.00391C20.9995 4.60608 20.8415 4.22455 20.5602 3.94325C20.2789 3.66194 19.8973 3.50391 19.4995 3.50391H4.49951C4.10169 3.50391 3.72016 3.66194 3.43885 3.94325C3.15755 4.22455 2.99951 4.60608 2.99951 5.00391V33.5039H20.9995V5.00391ZM32.9995 12.5039C32.9995 12.1061 32.8415 11.7246 32.5602 11.4432C32.2789 11.1619 31.8973 11.0039 31.4995 11.0039H23.9995V33.5039H32.9995V12.5039ZM26.9995 23.0039H29.9995V20.0039H26.9995V23.0039ZM26.9995 29.0039H29.9995V26.0039H26.9995V29.0039ZM26.9995 17.0039H29.9995V14.0039H26.9995V17.0039Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoNetworkNotFound;
