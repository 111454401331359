import React, { useState, useEffect, useContext } from 'react';
import CompanyHeader from '../../../components/ViewsHeader/CompanyViewsHeader';
import headerMatriz from 'assets/Matriz/headerMatriz.png';
import CurriculumMatrix from './components/CurriculumMatrix/engine';
import MatrixProvider from './components/CurriculumMatrix/contexts/MatrixContext';
import { AcademicModeling } from './components/AcademicModeling';
import { AcademicModelingContextProvider } from './components/AcademicModeling/contexts/AcademicModelingContext';
import AuthContext from 'contexts/authentication';

export default function AcademicEngine({
  handleIsLoadingState,
  classes,
  openSnackbar,
  openModalWithContent,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const { metadata } = useContext(AuthContext);

  const isOldCurriculumMatrix = metadata?.old_curriculum_matrix;

  const tabs = [`Inovação Acadêmica`];
  if (isOldCurriculumMatrix) {
    tabs.push('Matriz Curricular');
  }

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const propsAssociation = {
    classes,
    openSnackbar,
    openModalWithContent,
    handleIsLoadingState,
  };

  const propsMatrix = {
    openSnackbar,
    openModalWithContent,
    handleIsLoadingState,
    activeTab,
  };

  const renderContentTab = () => {
    const components = {
      0: (
        <AcademicModelingContextProvider>
          <AcademicModeling />
        </AcademicModelingContextProvider>
      ),
      1: <CurriculumMatrix {...propsMatrix} />,
    };
    return components[activeTab];
  };

  return (
    <div>
      <CompanyHeader
        title="Acadêmicos"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={headerMatriz}
        tabs={tabs}
      />

      <MatrixProvider>{renderContentTab()}</MatrixProvider>
    </div>
  );
}
