import { useRef } from 'react';
import { throttle } from 'lodash';

const useDragScrolling = () => {
  const isScrolling = useRef(false);

  const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];

  const goDown = () => {
    baseScroll.scrollTop += 8;

    const { offsetHeight, scrollTop, scrollHeight } = baseScroll;
    const isScrollEnd = offsetHeight + scrollTop >= scrollHeight;

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goDown);
    }
  };

  const goUp = () => {
    baseScroll.scrollTop -= 8;

    if (isScrolling.current && baseScroll.scrollTop > 0) {
      window.requestAnimationFrame(goUp);
    }
  };

  const onDragOver = (event) => {
    const isMouseOnTop = event.clientY < 100;
    const isMouseOnDown = window.innerHeight - event.clientY < 50;

    if (!isScrolling.current && (isMouseOnTop || isMouseOnDown)) {
      isScrolling.current = true;

      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp);
      }

      if (isMouseOnDown) {
        window.requestAnimationFrame(goDown);
      }
    } else if (!isMouseOnTop && !isMouseOnDown) {
      isScrolling.current = false;
    }
  };

  const throttleOnDragOver = throttle(onDragOver, 300);

  const addEventListenerForWindow = () => {
    baseScroll.addEventListener('dragover', throttleOnDragOver, false);
  };

  const removeEventListenerForWindow = () => {
    baseScroll.removeEventListener('dragover', throttleOnDragOver, false);
    isScrolling.current = false;
  };

  return {
    addEventListenerForWindow,
    removeEventListenerForWindow,
  };
};

export default useDragScrolling;
