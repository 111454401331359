export const Themes = (universityColor) => {
  const theme = {
    light: {
      background: universityColor || '#009291',
      color: '#FFF',
    },

    unfilled: {
      background: 'transparent',
      color: universityColor || '#009291',
      border: true,
    },
  };

  return theme;
};
