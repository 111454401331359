const graphDataAdapter = ({
  carrer_objective,
  employed_and_unemployed,
  market_relationship,
  students_working_in_area,
  active_students,
  student_curriculum,
  actives_vs_curriculum,
  companies_invites,
  jobs_disc,
  jobs_finished_bar,
  jobs_bar,
  code,
}) => {
  return {
    carrerObjective: carrer_objective,
    employedAndUnemployed: employed_and_unemployed,
    marketRelationship: market_relationship,
    studentsWorkingInArea: students_working_in_area,
    activeStudents: active_students,
    studentsWithCurriculum: student_curriculum,
    activesVsCurriculum: actives_vs_curriculum,
    companiesInvites: companies_invites,
    jobs: jobs_disc,
    jobsFinished: jobs_finished_bar,
    jobStatus: jobs_bar,
    code,
  };
};

export { graphDataAdapter };
