import GenericMessage from 'components/GenericMessage';
import { Container, Paginate } from './styled';
import { TalentItem } from '../../TalentItem';
import { useContext, useEffect, useRef, useState } from 'react';
import { TalentsContext } from 'components/NewTalentList/contexts/TalentListContext';
import { generateUUID } from 'components/UploadEvidences/utils/generateUUID';
import { TalentItemSkeleton } from '../../TalentsItemSkeleton';
import Pagination from 'components/CustomPagination';
import { talentsFilterService } from 'components/NewTalentList/services/filters.service';

export function IndicationsTab() {
  const {
    indications,
    loadingTalents,
    indicationsData,
    getIndications,
    setIndicationsTabLimit,
    indicationsTabLimit,
  } = useContext(TalentsContext);
  const [currentPage, setCurrentPage] = useState(1);

  const onPageChange = async (page) => {
    setCurrentPage(page);
    const offset = (page - 1) * indicationsTabLimit;
    await getIndications(
      talentsFilterService.filterUrl,
      offset,
      indicationsTabLimit
    );
  };

  const alterLimit = async (limit) => {
    setIndicationsTabLimit(limit);
    await getIndications(talentsFilterService.filterUrl, 0, limit);
  };

  return (
    <>
      <Container>
        {loadingTalents ? (
          Array.from({ length: 4 }).map((i) => <TalentItemSkeleton />)
        ) : indications.length > 0 ? (
          <>
            {indications.map((indication) => (
              <TalentItem
                key={generateUUID()}
                talent={indication}
                isIndication
              />
            ))}
          </>
        ) : (
          <GenericMessage
            title="Nenhuma indicação encontrada"
          />
        )}
      </Container>
      {indications.length > 0 && (
        <Paginate
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={indicationsData.count ?? 0}
          limit={indicationsTabLimit}
          onPageChange={onPageChange}
          setLimit={alterLimit}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}
