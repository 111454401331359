import { ContentWrapper } from '../../Styles/FeedbackComponent';
import {
  Box,
  Wrapper,
  Content,
  TopBar,
  CloseButton,
} from 'components/ListCandidates/Styles/ModalComponents';
import { IoClose } from 'react-icons/io5';
import { Form } from '@unform/web';
import { useState, useRef } from 'react';

import { CheckboxGroup } from 'components/UnformFields/CheckboxGroup';
import * as Yup from 'yup';
import NoteInput from 'components/UnformFields/noteInput';

export function FeedbackModal({
  controlModal,
  setControlModal,
  options,
  handleApplicationReturn,
}) {
  const optionsForm = [...(options || []), { label: 'Outros', value: '' }];
  const formRef = useRef();
  const [showTextArea, setShowTextArea] = useState(false);
  const [reason, setReason] = useState([]);

  const handleOptionChange = (event) => {
    let selectedValues = event;

    let otherOptionSelected = selectedValues.some(
      (selected) => selected.value === ''
    );

    setShowTextArea(otherOptionSelected);

    selectedValues = selectedValues
      .filter((selected) => selected.value !== '')
      .map((selected) => selected.value);

    setReason(selectedValues);
  };

  const handleSubmit = async (data) => {
    let dataForm = {
      mark_as: 'not_interesting',
      reasons: reason,
      reason_description: data.reason_description || '',
    };

    try {
      let schema = Yup.object().shape({
        reasons: Yup.array().min(1, 'Selecione pelo menos uma opção.'),
      });

      if (showTextArea) {
        schema = Yup.object().shape({
          reasons: Yup.array().min(1, 'Selecione pelo menos uma opção.'),
          reason_description: Yup.string().required('Este campo é obrigatório'),
        });
      }
      await schema.validate(data, {
        abortEarly: false,
      });
      await handleApplicationReturn(dataForm);

      resetModal();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          formRef.current.setFieldError(error.path, error.message);
        });
      }
    }
  };

  const resetModal = () => {
    setControlModal(false);
    setShowTextArea(false);
    setReason([]);
    formRef.current.reset();
  };

  return (
    <Wrapper visible={controlModal}>
      <Box>
        <Content>
          <TopBar>
            <p className="title">Feedback</p>
            <CloseButton onClick={resetModal}>
              <IoClose size={18} />
            </CloseButton>
          </TopBar>
          <ContentWrapper>
            <p className="title">
              Qual o motivo de não ter considerado esse candidato interessante?
            </p>
            <span>
              Por favor, indique em qual dos tópicos abaixo o aluno precisará se
              desenvolver mais para atender aos requisitos da vaga. Se preferir,
              insira também um feedback descrito justificando sua avaliação.
            </span>
            <div className="checkbox-group">
              <Form ref={formRef} onSubmit={handleSubmit}>
                <CheckboxGroup
                  spacing={10}
                  name="reasons"
                  options={optionsForm}
                  onCheckboxChange={handleOptionChange}
                />
                {showTextArea && (
                  <NoteInput
                    name="reason_description"
                    label="Feedback:"
                    placeholder="Digite aqui..."
                    maxLength={150}
                    useCount
                    textAreaHeight="65px"
                  />
                )}
                <div className="container-button">
                  <button
                    className="cancel-button"
                    type="button"
                    onClick={resetModal}
                  >
                    Cancelar
                  </button>

                  <button className="send-button">Enviar feedback</button>
                </div>
              </Form>
            </div>
          </ContentWrapper>
        </Content>
      </Box>
    </Wrapper>
  );
}
