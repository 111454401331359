import * as Yup from 'yup';

export const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .strict()
    .uppercase('Nome deve ser em letras maiúsculas')
    .test('name', 'Não é permitido o uso de colchetes', (value) => {
      return !value.match(/\[|\]/);
    })
    .required('Informação obrigatória'),
  keywords: Yup.array()
    .test('keywords', 'Não é permitido o uso de colchetes', (values) => {
      let isValid = true;
      for (const value of values) {
        if (value.match(/\[|\]/) !== null) {
          isValid = false;
          break;
        }
      }
      return isValid;
    })
    .required('Informação obrigatória'),
  market_requirements: Yup.array().required('Informação obrigatória'),
});
