export default function EditIcon({ color = "#009291", size = 17 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1559_3571)">
        <path
          d="M16.2571 2.26778C16.2028 2.29386 16.2193 2.35145 16.2091 2.39281C16.1366 2.69186 15.9834 2.96522 15.7665 3.1831C15.4807 3.47293 15.191 3.75577 14.909 4.04847C14.8455 4.1121 14.8122 4.1121 14.7503 4.04847C14.0067 3.2989 13.261 2.55167 12.513 1.80678C12.4527 1.74696 12.4536 1.71547 12.513 1.65756C12.8147 1.362 13.1037 1.05308 13.412 0.766742C13.629 0.560655 13.8968 0.41602 14.1879 0.347633C14.479 0.279246 14.783 0.289561 15.0689 0.377526C15.3547 0.46549 15.6121 0.627945 15.8146 0.84827C16.0172 1.06859 16.1578 1.33888 16.2218 1.63147C16.2272 1.66306 16.2307 1.69493 16.2323 1.72692C16.2641 1.77751 16.2222 1.8405 16.2603 1.89013L16.2571 2.26778Z"
          fill={color}
        />
        <path
          d="M6.79024 16.519C5.19034 16.519 3.59045 16.5142 1.99056 16.5212C1.27413 16.5244 0.755229 16.1862 0.363989 15.6189C0.136612 15.29 0.0353088 14.9136 0.0353088 14.5121C0.0353088 11.3592 0.0353088 8.20623 0.0353088 5.05314C0.0353088 3.92339 0.917187 3.01698 2.04168 3.00807C3.84196 2.99662 5.64256 3.00362 7.44315 3.00616C7.84963 3.00616 8.14655 3.30363 8.14465 3.68859C8.14274 4.07356 7.84296 4.36085 7.43426 4.36117C5.67092 4.36265 3.90738 4.36265 2.14362 4.36117C1.65648 4.36117 1.38909 4.6265 1.38909 5.11359C1.38909 8.22447 1.38909 11.3351 1.38909 14.4456C1.38909 14.9247 1.65426 15.1923 2.13251 15.1923C5.24253 15.1923 8.35255 15.1923 11.4626 15.1923C11.9284 15.1923 12.2 14.9177 12.2 14.4532C12.2 12.6807 12.2 10.9085 12.2 9.13661C12.2 8.71474 12.501 8.41186 12.9011 8.42427C13.2695 8.43572 13.5455 8.7281 13.5528 9.1242C13.5582 9.42008 13.5528 9.71659 13.5528 10.0128C13.5528 11.4992 13.5601 12.9859 13.5502 14.4723C13.5442 15.3526 13.1311 15.9908 12.3384 16.3745C12.1336 16.4729 11.9088 16.5226 11.6817 16.5199C10.0535 16.5167 8.42189 16.519 6.79024 16.519Z"
          fill={color}
        />
        <path
          d="M6.15265 10.7772C5.90019 10.7744 5.76046 10.5803 5.81159 10.3235C5.87097 10.0232 5.93258 9.72319 5.99291 9.42317C6.08818 8.9574 6.18345 8.49227 6.27237 8.02586C6.29102 7.92701 6.34037 7.83659 6.41337 7.76752C8.15299 6.02533 9.89208 4.28228 11.6306 2.5384C11.7027 2.46618 11.7393 2.46268 11.8129 2.5384C12.549 3.28075 13.2881 4.02119 14.0302 4.75972C14.0997 4.82876 14.0991 4.86217 14.0302 4.93152C12.2846 6.67478 10.5414 8.42047 8.80051 10.1686C8.7081 10.2612 8.5979 10.2959 8.47818 10.3204C7.73444 10.4688 6.99049 10.6173 6.24633 10.7658C6.21533 10.7712 6.18406 10.775 6.15265 10.7772Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1559_3571">
          <rect
            width="16.2219"
            height="16.2219"
            fill="white"
            transform="translate(0.0355835 0.299561)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
