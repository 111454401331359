import EditIcon from 'assets/academicModeling/edit';
import { useMemo, useState } from 'react';
import AcademicModelingApi from 'services/university/academicModeling';
import WorkLoadModal from '../../../WorkloadModal';
import Tooltip from 'components/NewTooltip';
import { ContainerList } from '../style';

export default function DisciplineItem({
  discipline,
  handleCheckDiscipline,
  verifyChecked,
  fetchDisciplines,
}) {
  const [modalWorkloadOpen, setModalWorkloadOpen] = useState(false);

  const controllEditIcon = () => {
    const handleClickEdit = () => {
      setModalWorkloadOpen(true);
    };

    if (discipline.workload) {
      return <EditIcon color={'#009291'} onClick={handleClickEdit} />;
    }

    return (
      <Tooltip
        text="Será possível adicionar a carga horária na próxima etapa!"
        direction="top"
        isMultiLine={true}
        maxWidth="220px"
        minWidth="220px"
      >
        <EditIcon color="#C4C4C4" />
      </Tooltip>
    );
  };

  const modalConfig = {
    bubbleText: 'Edite a carga horária',
    ParagraphText: `<p>Edite a carga horária total da
        disciplina <strong> ${discipline.name} </strong>
      </p>`,
    modalHeight: '520',
    buttonConfirmText: 'SALVAR',
    onClose: () => {},
  };

  const buttonConfirmFunctionEditWorkload = async (workload) => {
    const formatWorkload = +workload.replace(':', '.');
    await AcademicModelingApi.updateWorkload(discipline.id, formatWorkload);
    fetchDisciplines();
    setModalWorkloadOpen(false);
  };

  const minWorkload = useMemo(() => {
    if (!discipline.remain_workload) return null;
    const currentWorkload = Number(discipline.workload.split(':')[0]);
    const remainWorkload = Number(discipline.remain_workload.split(':')[0]);
    return currentWorkload - remainWorkload;
  }, [discipline]);

  return (
    <ContainerList key={discipline.id}>
      <WorkLoadModal
        open={modalWorkloadOpen}
        setOpen={setModalWorkloadOpen}
        config={modalConfig}
        buttonConfirmFunction={buttonConfirmFunctionEditWorkload}
        initialValue={discipline.workload?.replace('.', ':')}
        minWorkload={minWorkload}
      />
      <input
        type="checkbox"
        className="checkDisciplines"
        checked={verifyChecked(discipline.id)}
        onChange={(e) => handleCheckDiscipline(e, discipline)}
      />
      <span className="code">{discipline.code}</span>
      <span className="discipline">{discipline.name}</span>
      <span className="workloadList">
        {discipline.workload
          ? discipline.workload.replace('.', ':').padStart(5, '0')
          : '00:00'}
      </span>
      <span className="edit">
        {controllEditIcon(discipline.workload, discipline.id)}
      </span>
    </ContainerList>
  );
}
