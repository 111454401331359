import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  > .filters {
    display: flex;
    justify-content: space-around;

    @media (max-width: 991px) {
      flex-direction: column;
      gap: 20px;
      justify-content: unset;
    }
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerButtonLoadMore = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivButton = styled.div`
display: flex;
flex-direction: row-reverse;
width: 100%;
gap: 8px;

.feedBackButton {
  font-size: 12px;
}
`
