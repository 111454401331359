import { Container } from './style';

export default function SelectAllCheckBox ({
  id, 
  type, 
  checked,
  onChange, 
  disabled, 
  buttonLabel
  }) {
  return (
    <Container>
      <input 
        type={type}
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label htmlFor={id}>
        {buttonLabel}
      </label>
    </Container>
  )
}
