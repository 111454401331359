export const filtersConfig = [
  {
    name: 'event_type',
    label: 'Tipo',
    type: 'MULTI_SELECT',
    optionsKey: 'event_type',
  },
  {
    name: 'event_modality',
    label: 'Modalidade',
    type: 'SINGLE_SELECT',
    optionsKey: 'event_modality',
  },
  {
    name: 'certification',
    label: 'Com certificação',
    type: 'SINGLE_SELECT',
    optionsKey: 'boolean',
  },
  {
    name: 'complementar_activity',
    label: 'Válido como atividade complementar',
    type: 'SINGLE_SELECT',
    optionsKey: 'boolean',
  },
  {
    name: 'published_by',
    label: 'Responsável pela publicação',
    type: 'MULTI_SELECT',
    optionsKey: 'published_by',
  },
  {
    name: 'event_status',
    label: 'Status do evento',
    type: 'MULTI_SELECT',
    optionsKey: 'event_status',
  },
];
