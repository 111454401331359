import { Container, IconWrapper, Step, Stepper, StepStatus } from './style';
import { ProgressBubbles } from 'assets/ComponentIcons/ProgressBubbles';
import { Hourglass } from 'assets/ComponentIcons/Hourglass';
import { useCallback, useMemo, Fragment } from 'react';
import { useAcademicModeling } from '../../contexts/AcademicModelingContext';
import { Divider } from '../../style';
import { AcademicCheckIcon } from 'assets/ComponentIcons/AcademicCheckIcon';

export function CourseCard({ course }) {
  const { handleSelectCourse } = useAcademicModeling();

  const modalityCapitalized = useMemo(() => {
    return course.modality.charAt(0).toUpperCase() + course.modality.slice(1);
  }, [course]);

  const courseCurrentStep = useMemo(() => {
    if (!course) return 1;
    const { steps } = course;
    const keys = Object.keys(steps);
    return keys.findIndex((key) => !steps[key]) + 1;
  }, [course]);

  const finished = useMemo(() => {
    const { steps } = course;
    const keys = Object.keys(steps);
    return keys.every((key) => steps[key]);
  }, [course]);

  const getStepStatus = useCallback(
    (step) => {
      const { steps } = course;
      const stepIsDone = Object.values(steps)[step - 1];
      if (stepIsDone) {
        return StepStatus.DONE;
      }
      if (courseCurrentStep === step) {
        return StepStatus.CURRENT;
      }
      return StepStatus.PENDING;
    },
    [course]
  );

  const stepBuilder = useCallback(
    (step) => {
      const stepStatus = getStepStatus(step);
      return (
        <Step status={stepStatus}>
          {courseCurrentStep === step ? <Hourglass /> : step}
        </Step>
      );
    },
    [courseCurrentStep]
  );

  const controllFooter = () => {
    if (course.analyzed && !finished && course.exists_association) {
      return 'CONCLUÍDO (COM PENDÊNCIAS)';
    }
    if (course.analyzed && finished) {
      return 'CONCLUÍDO';
    }
    if (!course.analyzed && finished) {
      return 'AGUARDANDO ANÁLISE';
    }
    return (
      <Fragment>
        <strong>ETAPA {courseCurrentStep}</strong> - AGUARDANDO INÍCIO
      </Fragment>
    );
  };

  const controllColors = () => {
    if (course.analyzed && (finished || course.exists_association)) {
      return '#009291';
    }
    if (!course.analyzed && finished) {
      return '#F09D51';
    }
    return '#F09D51';
  };

  const controllIcon = () => {
    if (course.analyzed && (finished || course.exists_association)) {
      return <AcademicCheckIcon />;
    }
    if (!course.analyzed && finished) {
      return <ProgressBubbles />;
    }
    return <ProgressBubbles />;
  };

  return (
    <Container
      onClick={() => handleSelectCourse(course)}
      finished={controllColors()}
    >
      <IconWrapper finished={controllColors()}>{controllIcon()}</IconWrapper>
      <h4>Modalidade: {modalityCapitalized}</h4>
      <h3 title={course.name}>{course.name}</h3>
      <Divider color={controllColors()} width={'215px'} />
      <Stepper>
        {stepBuilder(1)}
        <Divider width={'37px'} height={'0.5px'} />
        {stepBuilder(2)}
        <Divider width={'37px'} height={'0.5px'} />
        {stepBuilder(3)}
      </Stepper>
      <footer>{controllFooter()}</footer>
    </Container>
  );
}
