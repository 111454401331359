import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  Box,
  Content,
  Header,
  ContainerList,
  SwitchContainer,
} from './style';
import { GenericInternalSwitch } from 'components/GenericInternalSwitch';
import { getCompanyVacancies } from 'views/StaffPages/CompaniesManagement/Services';
import OpenedJobs from './pages/OpenedJobs';
import FinishedJobs from './pages/FinishedJobs';
import { concatResults } from './helpers';

export default function VacanciesModal({
  isOpen,
  closeModal,
  selectedCompanyVacancies,
}) {
  if (!isOpen) return null;

  const [activeTab, setActiveTab] = useState(0);
  const [zIndex, setZIndex] = useState(2000);
  const [vacanciesData, setVacanciesData] = useState([]);

  const getVacancies = async (params) => {
    const response = await getCompanyVacancies(
      selectedCompanyVacancies.id,
      params
    );
    setVacanciesData((prev) => concatResults(prev, response));
  };

  useEffect(() => {
    getVacancies();
  }, [selectedCompanyVacancies]);

  const tabs = [
    `Em aberto (${vacanciesData?.jobs?.count})`,
    `Finalizadas (${vacanciesData?.finished_jobs?.count})`,
  ];
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const onOpenJobDetails = () => {
    setZIndex(500);
  };

  const closeFunction = () => {
    setZIndex(2000);
  };

  const seeMorePaginationCallback = useCallback((params) => {
    getVacancies(params);
  }, []);

  const componentsProps = {
    vacanciesData,
    seeMorePaginationCallback,
    onOpenJobDetails,
    closeFunction,
  };

  const componentes = {
    0: <OpenedJobs {...componentsProps} />,
    1: <FinishedJobs {...componentsProps} />,
  };

  const activeComponent = componentes[activeTab];

  return (
    <Container zIndex={zIndex}>
      <Box>
        <Content>
          <Header>
            <h2>Vagas publicadas</h2>
            <button className="close-button" onClick={closeModal}>
              ×
            </button>
          </Header>
          <ContainerList>
            <div className="company-info">
              <p className="company-name">
                {selectedCompanyVacancies?.legal_name}
              </p>
              <p className="company-cnpj">
                CNPJ: {selectedCompanyVacancies?.organization_number} |{' '}
                {selectedCompanyVacancies?.address.city} -{' '}
                {selectedCompanyVacancies?.address.state}
              </p>
            </div>

            <SwitchContainer>
              <GenericInternalSwitch
                tabs={tabs}
                activeTab={activeTab}
                handleChange={handleTabChange}
              />
            </SwitchContainer>
            {activeComponent}
          </ContainerList>
        </Content>
      </Box>
    </Container>
  );
}
