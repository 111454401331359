function triggerEvent(action, category, label) {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
  });
}

function triggerPageView(path, role = null) {
  window.gtag('event', 'page_view', {
    page_location: role,
    page_path: role ? role + path : path,
  });
}

export { triggerEvent, triggerPageView };
