export const ChevronIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width={'10'}
      height={'17'}
      viewBox="0 0 10 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_34528_1356)">
        <path
          d="M9.44455 8.51447L2.23735 15.7217L0.555664 14.04L6.08119 8.51447L0.555664 2.98895L2.23735 1.30727L9.44455 8.51447Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_34528_1356">
          <rect
            width="16.6667"
            height="10"
            fill="white"
            transform="translate(0 16.833) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
