import styled from "styled-components";

import X from '../../../../../assets/Oportutinites/X.svg';

export const ModalContainer = styled.div`
  width: 512px;
  min-height: 428px;
  margin-bottom: 5em;

  max-height: auto;
  height: auto;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const ModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 4.5em;
  z-index: 1105;
  justify-content: center;
  align-items: center;
`;

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
`