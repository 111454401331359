import moment from 'moment';

const newInterviewAdapter = ({
  scheduling_date,
  addressNumber,
  addressState,
  city,
  complement,
  district,
  description,
  street,
  zipCode,
  interviewer_message,
  scheduling_hour,
  access_link,
}) => ({
  scheduling_date: `${moment(scheduling_date).format(
    'DD/MM/YYYY'
  )} ${scheduling_hour}`,
  access_link,
  address: {
    street,
    number: addressNumber,
    complement,
    district,
    city,
    state: addressState,
    zip_code: zipCode,
  },
  description,
  interviewer_message,
});

const loadInterviewAdapter = ({
  access_link,
  description,
  address,
  id,
  in_person,
  interviewee_message,
  interviewer_message,
  is_online,
  scheduling_date,
  status,
  reasonRescheduled,
}) => ({
  id,
  status,
  description,
  interviewee_message,
  is_online,
  in_person,
  scheduling_date: moment(scheduling_date).format('DD/MM/YYYY'),
  scheduling_hour: moment(scheduling_date).format('HH:mm'),
  addressNumber: address.number,
  addressState: address.state,
  city: address.city,
  complement: address.complement,
  district: address.district,
  street: address.street,
  zipCode: address.zip_code,
  interviewer_message,
  access_link,
  reasonRescheduled,
});

const loadInterviewOnlineAdapter = ({
  access_link,
  description,
  id,
  address,
  in_person,
  interviewee_message,
  interviewer_message,
  is_online,
  scheduling_date,
  status,
  reasonRescheduled,
}) => ({
  id,
  status,
  interviewee_message,
  description,
  is_online,
  in_person,
  scheduling_date: moment(scheduling_date).format('DD/MM/YYYY'),
  scheduling_hour: moment(scheduling_date).format('HH:mm'),
  interviewer_message,
  addressNumber: address ? address.number : null,
  addressState: address ? address.state : '',
  city: address ? address.city : '',
  complement: address ? address.complement : '',
  district: address ? address.district : '',
  street: address ? address.street : '',
  zipCode: address ? address.zip_code : '',
  access_link,
  reasonRescheduled,
});
export {
  newInterviewAdapter,
  loadInterviewAdapter,
  loadInterviewOnlineAdapter,
};
