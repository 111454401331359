import React, { useEffect } from 'react';

import { Wrapper } from './style';

import EngineRegister from './Components/RegisterCompany/Engine';

import BaseLayoutContext from '../../contexts/base-layout';
import JobOfferProvider from '../JobOfferRebuild/Contexts/JobOfferContext';

export default function companyRebuildEngine({
  handleIsLoadingState,
  ...rest
}) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <JobOfferProvider>
      <Wrapper>
        <BaseLayoutContext.Consumer>
          {(values) => {
            return <EngineRegister {...values} {...rest} />;
          }}
        </BaseLayoutContext.Consumer>
      </Wrapper>
    </JobOfferProvider>
  );
}
