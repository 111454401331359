import { ButtonTab, ContainerButtonTab } from './style';

export default function TabHeader({ tabs, onChange, activeTab }) {
  const MappedTabs = tabs.map((tab, index) => (
    <ButtonTab
      onClick={() => onChange(index)}
      active={activeTab === index}
      key={tab}
    >
      {tab}
    </ButtonTab>
  ));

  return <ContainerButtonTab>{MappedTabs}</ContainerButtonTab>;
}
