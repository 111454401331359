import {
  ButtonInsideTabs,
  ContainerInsideTabs,
  GenerateReportButton,
  InvisibleLine,
} from '../style';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Contemplated } from '../Contemplated';
import { NotContemplated } from '../NotContemplated';

import { useAcademicModeling } from '../../../../contexts/AcademicModelingContext';
import AcademicModelingApi from 'services/university/academicModeling';
import BaseLayoutContext from 'contexts/base-layout';
import ReportsPdf from '../ReportsPdf';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';

export function MarketRequirements() {
  const [activeTab, setActiveTab] = useState(1);
  const [loadingReport, setLoadingReport] = useState(false);
  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const [reportData, setReportData] = useState({});

  const {
    marketRequirementsMetrics: metrics,
    updateMarketRequirementsMetrics,
    selectedCourse,
  } = useAcademicModeling();

  useEffect(() => {
    updateMarketRequirementsMetrics();
  }, [activeTab]);

  const getRequerimentsReport = async () => {
    setLoadingReport(true);
    const response = await AcademicModelingApi.getRequerimentsReport(
      selectedCourse.id
    );
    setReportData(response);
    setLoadingReport(false);
    openModalWithContent(
      <ReportsPdf data={response} closeModal={closeModal} />
    );
  };

  const tabs = useMemo(
    () => ({
      1: <Contemplated />,
      2: <NotContemplated />,
    }),
    [metrics]
  );

  const showContemplated = metrics.contemplated > 0;

  const controlActiveTab = () => {
    if (!showContemplated) {
      return setActiveTab(2);
    }
    return setActiveTab(1);
  };

  useEffect(() => {
    controlActiveTab();
  }, [showContemplated]);

  return (
    <div>
      <ContainerInsideTabs>
        <div>
          <InvisibleLine></InvisibleLine>
          {showContemplated && (
            <ButtonInsideTabs
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
              disabled={activeTab === 1}
            >
              Contemplados ({metrics.contemplated})
            </ButtonInsideTabs>
          )}
          <ButtonInsideTabs
            active={activeTab === 2 || !showContemplated}
            onClick={() => setActiveTab(2)}
            disabled={activeTab === 2}
          >
            Não contemplados ({metrics.not_contemplated})
          </ButtonInsideTabs>
        </div>
        <GenerateReportButton onClick={getRequerimentsReport}>
          {loadingReport && <Loading color="#009291" />}
          Gerar relatório
        </GenerateReportButton>
      </ContainerInsideTabs>
      {tabs[activeTab]}
    </div>
  );
}
