import React from 'react';
import { IconArrowEdit } from 'assets/img/iconsCurriculum/iconArrowEdit';
import { ButtonSeeMore, ContainerButton } from '../styled';

export default function PaginationSeeMore({
  universityColor,
  onClick,
  disabled,
  renderedItems,
  data,
  filled,
}) {
  return (
    <div>
      {renderedItems &&
        renderedItems?.length < data?.length &&
        renderedItems.length >= 10 && (
          <ContainerButton>
            <ButtonSeeMore
              filled={filled}
              universityColor={universityColor}
              onClick={onClick}
              disabled={disabled}
            >
              Ver mais
              <IconArrowEdit color={universityColor} />
            </ButtonSeeMore>
          </ContainerButton>
        )}
    </div>
  );
}
