import React, { useContext } from 'react';

import { ImageStatus } from './styled';
import { object } from 'prop-types';
import BaseLayoutContext from 'contexts/base-layout';

export default function CardImageStatus({ label }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <ImageStatus color={universityColor} backgroundColor={'#FFF'}>
      {label}
    </ImageStatus>
  );
}

CardImageStatus.prototype = {
  status: object,
};
