export function UniversityIcon({ size = 13, color = '#009291', ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill="none"
      {...props}
    >
      <path
        d="M11.4775 3.97754H8.18454L7.60504 3.39804L9.47754 2.22754L7.47754 0.977539H6.47754V3.27054L5.77054 3.97754H2.47754C2.07971 3.97754 1.69818 4.13557 1.41688 4.41688C1.13557 4.69818 0.977539 5.07971 0.977539 5.47754L0.977539 12.9775H12.9775V5.47754C12.9775 5.07971 12.8195 4.69818 12.5382 4.41688C12.2569 4.13557 11.8754 3.97754 11.4775 3.97754ZM11.4775 4.97754C11.6101 4.97754 11.7373 5.03022 11.8311 5.12399C11.9249 5.21775 11.9775 5.34493 11.9775 5.47754V5.97754H10.1845L9.18454 4.97754H11.4775ZM2.47754 4.97754H4.77054L3.77054 5.97754H1.97754V5.47754C1.97754 5.34493 2.03022 5.21775 2.12399 5.12399C2.21775 5.03022 2.34493 4.97754 2.47754 4.97754ZM7.47754 11.9775H6.47754V10.4775C6.47754 10.3449 6.53022 10.2178 6.62399 10.124C6.71775 10.0302 6.84493 9.97754 6.97754 9.97754C7.11015 9.97754 7.23732 10.0302 7.33109 10.124C7.42486 10.2178 7.47754 10.3449 7.47754 10.4775V11.9775ZM8.47754 11.9775V10.4775C8.47754 10.0797 8.3195 9.69818 8.0382 9.41688C7.7569 9.13557 7.37536 8.97754 6.97754 8.97754C6.57971 8.97754 6.19818 9.13557 5.91688 9.41688C5.63557 9.69818 5.47754 10.0797 5.47754 10.4775V11.9775H1.97754V6.97754H4.18454L6.97754 4.18454L9.77054 6.97754H11.9775V11.9775H8.47754ZM2.97754 7.97754H4.47754V8.97754H2.97754V7.97754ZM2.97754 9.97754H4.47754V10.9775H2.97754V9.97754ZM9.47754 7.97754H10.9775V8.97754H9.47754V7.97754ZM9.47754 9.97754H10.9775V10.9775H9.47754V9.97754ZM7.97754 6.97754C7.97754 7.17532 7.91889 7.36866 7.80901 7.53311C7.69913 7.69756 7.54295 7.82573 7.36022 7.90142C7.1775 7.97711 6.97643 7.99691 6.78245 7.95832C6.58847 7.91974 6.41028 7.8245 6.27043 7.68465C6.13058 7.54479 6.03534 7.36661 5.99675 7.17263C5.95817 6.97865 5.97797 6.77758 6.05366 6.59486C6.12935 6.41213 6.25752 6.25595 6.42197 6.14607C6.58642 6.03619 6.77976 5.97754 6.97754 5.97754C7.24276 5.97754 7.49711 6.0829 7.68465 6.27043C7.87218 6.45797 7.97754 6.71232 7.97754 6.97754Z"
        fill={color}
      />
    </svg>
  );
}
