import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import {
  getAccessData,
  getCareerObjective,
  getEmployedUnemployed,
  getJourneyStatus,
  getMarketRelationship,
  getProfessionalStatusChange,
  getStudentLogins,
  getStudentsCurriculum,
  getStudentsWorkingInArea,
  getSystemUsage,
} from 'views/StaffPages/Dashboard/Services';

export const fetchAllData = async (
  setDataFunctions,
  academicStatus,
  filter,
  setLoading
) => {
  const [
    setStudentsCurriculum,
    setAccessData,
    setStudentLogins,
    setMarketRelationship,
    setSystemUsage,
    setJourneyStatus,
    setEmployedUnemployed,
    setStudentWorkingInArea,
    setCareerObjective,
    setProfessionalStatusChange,
  ] = setDataFunctions;

  try {
    const fetchFunctions = [
      { fetch: getStudentsCurriculum, set: setStudentsCurriculum },
      { fetch: getAccessData, set: setAccessData },
      { fetch: getStudentLogins, set: setStudentLogins },
      {
        fetch: getMarketRelationship,
        set: setMarketRelationship,
      },
      { fetch: getSystemUsage, set: setSystemUsage },
      { fetch: getJourneyStatus, set: setJourneyStatus },
      {
        fetch: getEmployedUnemployed,
        set: setEmployedUnemployed,
      },
      {
        fetch: getStudentsWorkingInArea,
        set: setStudentWorkingInArea,
      },
      {
        fetch: getCareerObjective,
        set: setCareerObjective,
      },
      {
        fetch: getProfessionalStatusChange,
        set: setProfessionalStatusChange,
      },
    ];
    setLoading(true);

    let completedRequests = 0;
    const totalRequests = fetchFunctions.length;

    fetchFunctions.forEach(async ({ fetch, set }) => {
      try {
        set(null);
        const response = await fetch(academicStatus, filter);
        set(response);
      } catch (error) {
        customSnackbar(
          `Não foi possível carregar os dados! Tente novamente mais tarde.`,
          'alert'
        );
      } finally {
        completedRequests += 1;
        if (completedRequests === totalRequests) {
          setLoading(false);
        }
      }
    });
  } catch (error) {
    return error;
  }
};
