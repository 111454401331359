import React, { useContext } from 'react';
import { IconButton, Fragment } from './styled';
import { IconHelp } from 'assets/img/iconsCurriculum/iconHelp';
import BaseLayoutContext from 'contexts/base-layout';

const ButtonHelpStudent = ({ onClick, disabled }) => {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Fragment>
      <IconButton
        disabled={disabled}
        universityColor={universityColor}
        onClick={onClick}
      >
        <IconHelp color={universityColor} alt="Ajuda" />O que é?
      </IconButton>
    </Fragment>
  );
};

export default ButtonHelpStudent;
