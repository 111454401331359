import GenericInternalTab from 'components/GenericInternalTab';
import React, { useEffect, useState, useContext, useMemo } from 'react';

import BaseLayoutContext from 'contexts/base-layout';

import { IoIosArrowBack } from 'react-icons/io';
import { genericAdapter } from 'adapters/genericAdapter';
import {
  ContainerInfo,
  Header,
  FilterStyled,
  ContainerCards,
  ContainerButton,
  Container,
  BackButton,
  FragmentColor,
  ContainerLogo,
  TextName,
  Row,
  ContainerMiniLogo,
  TextRow,
  GridHeader,
  ContainerTabs,
  Box,
  ButtonFavorite,
  BoxTabs,
  BoxModal,
} from './styles';
import LogoNetworkNotFound from 'assets/Network/logoCompanyNotFound';
import {
  getCompanyDetail,
  getCompanyJobsOpen,
  getCompanyJobsFinished,
  getCompanyChallengesAvailable,
  getCompanyChallengesWithParticipation,
} from './Services/services';
import LogoJobNetwork from 'assets/Network/logoActivitiesSectors';
import LogoCompanyNetwork from 'assets/Network/logoCompanyNetwork';
import LogoLocationNetwork from 'assets/Network/logoLocationNetwork';
import useMediaQuery from 'hooks/useMediaQuery';
import { DisplayJobsNetwork } from './Components/DisplayJobs/DisplayJobs';
import { DisplayChallengesNetwork } from './Components/DisplayChallenges/DisplayChallenges';
import { set } from 'date-fns';
import LearningProvider from 'views/Student/Learning/Context/LearningContext';
import CurriculumContextProvider from 'contexts/CurriculumContext';
import { AiOutlineStar } from 'react-icons/ai';
import { AiFillStar } from 'react-icons/ai';
import { DisplayAboltCompany } from './Components/DisplayAboltCompany/DisplayAboltCompany';
import { getCompanyDetails } from 'views/StaffPages/jobOpeningsRebuild/components/IndicationStudent/services/IndicationStudent.service';

export function CompanyDetail({
  id,
  closeModal,
  student,
  handleFavorite,
  setIsLoadingData,
  isUniversityScope = false,
  fullWidth = false,
}) {
  const { universityColor, metadata } = useContext(BaseLayoutContext);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState(null);
  const mobileL = useMediaQuery('(max-width: 776px)');
  const [jobsOpen, setJobsOpen] = useState([]);
  const [jobsFinished, setJobsFinished] = useState([]);
  const [challengesWithParticipation, setChallengesWithParticipation] =
    useState([]);
  const [challengesAvailable, setChallengesAvailable] = useState([]);
  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const isStudent = metadata.role === 'student' || student === true;
  const isDisplayJobOffers = metadata.display_job_offer;

  const callDataChalenges = async () => {
    if (!isStudent) {
      return;
    }
    const [companyChallengesAvailable, companyChallengesWithParticipation] =
      await Promise.all([
        getCompanyChallengesAvailable(id),
        getCompanyChallengesWithParticipation(id),
      ]);
    if (companyChallengesAvailable.data) {
      setChallengesAvailable(genericAdapter(companyChallengesAvailable.data));
    }
    if (companyChallengesWithParticipation.data) {
      setChallengesWithParticipation(
        genericAdapter(companyChallengesWithParticipation.data)
      );
    }
  };

  const callDataJobs = async () => {
    if (!isStudent) {
      return;
    }
    if (!isDisplayJobOffers) {
      return;
    }
    const [companyJobsOpen, companyJobsFinished] = await Promise.all([
      getCompanyJobsOpen(id),
      getCompanyJobsFinished(id),
    ]);
    if (companyJobsOpen.data) {
      setJobsOpen(genericAdapter(companyJobsOpen.data));
    }
    if (companyJobsFinished.data) {
      setJobsFinished(genericAdapter(companyJobsFinished.data));
    }
  };

  const callFirstData = async () => {
    try {
      setIsLoadingData && setIsLoadingData(true);
      const response = isUniversityScope
        ? await getCompanyDetails(id)
        : await getCompanyDetail(id);

      if (response.data) {
        setData(genericAdapter(response.data));
      }
      if (!isUniversityScope) {
        callDataChalenges();
        callDataJobs();
      }
    } finally {
      setIsLoadingData && setIsLoadingData(false);
    }
  };

  useEffect(() => {
    callFirstData();
  }, []);

  const hasLogo = Boolean(data && data?.logo);

  const ocupation = () => {
    const { activitiesSectors } = data;
    const sectorName =
      activitiesSectors[0] && activitiesSectors[0]?.length > 47
        ? `${activitiesSectors[0].substring(0, 47)}...`
        : activitiesSectors[0];
    return (
      <TextRow color={universityColor}>
        {` ${activitiesSectors[0] ? sectorName : 'Não informado'} `}{' '}
        {activitiesSectors.length > 1 && <b>+{activitiesSectors.length - 1}</b>}
      </TextRow>
    );
  };

  const sizeCompany = () => {
    if (data.size && typeof data.size === 'string') {
      const sizeSplit = data.size.split('(');
      if (typeof sizeSplit === 'object') {
        return sizeSplit[0];
      } else {
        return data.size;
      }
    } else {
      return 'Não informado';
    }
  };

  const updateValues = () => {
    const arrayTabs = [`Sobre a empresa`];
    const renderTabs = {
      0: <DisplayAboltCompany data={data} />,
    };
    if (isStudent && isDisplayJobOffers && !isUniversityScope) {
      arrayTabs.push(
        `Vagas (${jobsOpen.length + jobsFinished.length})`,
        `Desafios (${
          challengesWithParticipation.length + challengesAvailable.length
        })`
      );

      renderTabs[1] = (
        <DisplayJobsNetwork jobsFinished={jobsFinished} jobsOpen={jobsOpen} />
      );
      renderTabs[2] = (
        <CurriculumContextProvider networking={true}>
          <LearningProvider>
            <DisplayChallengesNetwork
              callDataChalenges={callDataChalenges}
              challengesWithParticipation={challengesWithParticipation}
              challengesAvailable={challengesAvailable}
            />
          </LearningProvider>
        </CurriculumContextProvider>
      );
    }
    if (isStudent && !isDisplayJobOffers) {
      arrayTabs.push(
        `Desafios (${
          challengesWithParticipation.length + challengesAvailable.length
        })`
      );

      renderTabs[1] = (
        <CurriculumContextProvider networking={true}>
          <LearningProvider>
            <DisplayChallengesNetwork
              callDataChalenges={callDataChalenges}
              challengesWithParticipation={challengesWithParticipation}
              challengesAvailable={challengesAvailable}
            />
          </LearningProvider>
        </CurriculumContextProvider>
      );
    }
    return [arrayTabs, renderTabs];
  };

  const actionFavorite = (data) => {
    handleFavorite && handleFavorite(data);
    data.isFavorite = !data.isFavorite;
  };

  const closeModalForKey = (e) => {
    if (e.key === 'Escape') {
      closeModal(e);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', closeModalForKey);
    return () => {
      document.removeEventListener('keydown', closeModalForKey);
    };
  }, []);

  return (
    data && (
      <BoxModal fullWidth={fullWidth}>
        <Container>
          <ContainerInfo>
            <FragmentColor color={data.companyColor}>
              <BackButton
                onClick={(e) => closeModal(e)}
                color={universityColor}
                onKeyDown={closeModalForKey}
              >
                <IoIosArrowBack />
                <p>Voltar</p>
              </BackButton>
            </FragmentColor>
            <Header>
              <ContainerLogo img={hasLogo && data?.logo}>
                {!hasLogo && <LogoNetworkNotFound color={universityColor} />}
              </ContainerLogo>
              <Row>
                <TextName>{data?.legalName}</TextName>
              </Row>
              <GridHeader>
                <Row>
                  <ContainerMiniLogo color={universityColor}>
                    <LogoJobNetwork color={universityColor} />
                  </ContainerMiniLogo>
                  {ocupation()}
                </Row>
                <Row>
                  <ContainerMiniLogo color={universityColor}>
                    <LogoCompanyNetwork color={universityColor} />
                  </ContainerMiniLogo>
                  <TextRow>{sizeCompany()}</TextRow>
                </Row>
                <Row>
                  <ContainerMiniLogo color={universityColor}>
                    <LogoLocationNetwork color={universityColor} />
                  </ContainerMiniLogo>
                  <TextRow>
                    {data.city || data.state
                      ? `${data.city ? data.city : 'Não informado'}/ ${
                          data.state ? data.state : 'Não informado'
                        }`
                      : 'Não informado'}
                  </TextRow>
                </Row>
              </GridHeader>
            </Header>
            {isStudent && mobileL && (
              <ButtonFavorite
                color={universityColor}
                onClick={() => actionFavorite(data)}
              >
                {data.isFavorite ? <AiFillStar /> : <AiOutlineStar />}
                {`${
                  (data.isFavorite && 'Desfavoritar') || 'Favoritar'
                } empresa`}
              </ButtonFavorite>
            )}
            <Box>
              <ContainerTabs>
                <BoxTabs>
                  <GenericInternalTab
                    activeTab={activeTab}
                    handleTabChange={handleTabChange}
                    tabs={updateValues()[0].filter((item) => item !== false)}
                    zIndex={0}
                  />
                  {isStudent && !mobileL && (
                    <ButtonFavorite
                      color={universityColor}
                      onClick={() => actionFavorite(data)}
                    >
                      {data.isFavorite ? <AiFillStar /> : <AiOutlineStar />}
                      {`${
                        (data.isFavorite && 'Desfavoritar') || 'Favoritar'
                      } empresa`}
                    </ButtonFavorite>
                  )}
                </BoxTabs>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '20px',
                  }}
                >
                  <hr />
                </div>
                {updateValues()[1][activeTab]}
              </ContainerTabs>
            </Box>
          </ContainerInfo>
        </Container>
      </BoxModal>
    )
  );
}
