import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1101;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #ffff;
  max-width: 800px;
  max-height: 583px;
  border-radius: 8px;
  background-color: #fff;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

export const CloseButton = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  display: flex;
`;

export const Preview = styled.div`
  width: 100%;
  height: 100%;

  .iframe-content {
    width: 160%;
    height: 160%;
    scale: 0.625;
    transform-origin: 0 0;
    overflow-x: hidden;
    border-radius: 8px;
  }
`;
