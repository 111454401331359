import React, { useRef, useContext } from 'react';

import { Card, HeaderH5, Paragraph, InformationH6, WrapperForm } from './style';

import { Form } from '@unform/web';
import { MatrixContext } from '../../contexts/MatrixContext';
import SearchableSelectUn from '../Form/SearchableSelect';

export default function CardSelectCourse({ handleSelectCourse }) {
  const formRef = useRef(null);

  const { courseOptions } = useContext(MatrixContext);

  return (
    <Card>
      <HeaderH5>Matriz Curricular</HeaderH5>

      <Paragraph>
        Aqui você conseguirá relacionar as suas disciplinas com as matrizes
        existentes do seu curso. Para isso, basta selecionar o curso de sua
        preferência.
      </Paragraph>

      <InformationH6>Selecione ou digite o nome do curso</InformationH6>

      <WrapperForm>
        <Form ref={formRef}>
          <SearchableSelectUn
            name="course"
            options={courseOptions}
            onChange={handleSelectCourse}
          />
        </Form>
      </WrapperForm>
    </Card>
  );
}
