import { authenticatedAPI } from 'services/api';

const getCourses = () => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterCourses = (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/courses?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCourseDetail = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/backoffice/courses/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveCourse = (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/backoffice/courses`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const editCourse = (data, id) => {
  const response = authenticatedAPI
    .patch(`/api/internal/backoffice/courses/${id}`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteCourses = (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/backoffice/courses/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCoursesOptions = () => {
  const response = authenticatedAPI
    .options(`/api/internal/backoffice/courses`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getFilterCourses,
  getCourses,
  getCourseDetail,
  deleteCourses,
  getCoursesOptions,
  editCourse,
  saveCourse,
};
