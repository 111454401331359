import httpRequest from 'services/university/HttpRequest';

class MentorshipApi {
  baseUrl = `api/internal/students/me/mentorships`;
  baseGlobalUrl = `api/internal/university-student/mentorships`;

  async getMentorsList(queryString = '') {
    return await httpRequest.get(`${this.baseUrl}/mentors/list?${queryString}`);
  }

  async getMentorDetails(mentorId) {
    return await httpRequest.get(`${this.baseUrl}/mentor/detail/${mentorId}`);
  }

  async getMentorSpecificDates(mentorId) {
    return await httpRequest.get(
      `${this.baseUrl}/mentor/specific-dates/${mentorId}`
    );
  }

  async getMentorshipOptions(mentorId) {
    return await httpRequest.get(
      `${this.baseUrl}/scheduling/create/options/${mentorId}`
    );
  }

  async getMentorAbsences(mentorId) {
    return await httpRequest.get(`${this.baseUrl}/mentor/absences/${mentorId}`);
  }

  async getMentorNextDays(mentorId, dateReference = null) {
    const dateQueryParam =
      dateReference === null ? '' : `?reference_date=${dateReference}`;
    return await httpRequest.get(
      `${this.baseGlobalUrl}/mentor/next-days/${mentorId}${dateQueryParam}`
    );
  }

  async getMentorMentorshipScheduled(mentorId) {
    return await httpRequest.get(
      `${this.baseGlobalUrl}/mentor/list-scheduling/${mentorId}`
    );
  }

  async getMentorsFilterOptions() {
    return await httpRequest.get(`${this.baseUrl}/mentor/filter-options`);
  }

  async getPendingMentorshipsList({ queryParams }) {
    return await httpRequest.get(`${this.baseUrl}/pending?${queryParams}`);
  }

  async getConfirmedMentorshipsList({ queryParams }) {
    return await httpRequest.get(`${this.baseUrl}/confirmed?${queryParams}`);
  }

  async getRealizedMentorshipsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/historic/realized?${queryParams}`
    );
  }

  async getCanceledMentorshipsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/historic/canceled?${queryParams}`
    );
  }

  async getExpiredMentorshipsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/historic/expired?${queryParams}`
    );
  }

  async getMentorshipsCount() {
    return await httpRequest.get(
      `/api/internal/students/me/university/mentorships/count`
    );
  }

  async getMentorshipDetails(id) {
    return await httpRequest.get(`${this.baseUrl}/detail/${id}`);
  }

  async createMentorship(data) {
    return await httpRequest.post(`${this.baseUrl}/scheduling/create`, data);
  }

  async cancelMentorship(mentorshipId, cancelReason) {
    return await httpRequest.put(
      `${this.baseGlobalUrl}/cancel/${mentorshipId}`,
      {
        cancel_reason: cancelReason,
      }
    );
  }

  async confirmMentorship(id) {
    return await httpRequest.put(`${this.baseGlobalUrl}/confirm/${id}`);
  }

  async mentoringFeedback(mentorshipId, data) {
    return await httpRequest.put(
      `api/internal/students/me/university/mentorships/finish/${mentorshipId}`,
      data
    );
  }

  async rescheduleMentorship(mentorshipId, data) {
    return await httpRequest.patch(
      `${this.baseGlobalUrl}/reschedule/${mentorshipId}`,
      data
    );
  }
}

export const mentorshipApi = new MentorshipApi();
