import { dnaGreen } from 'assets/jss/material-dashboard-pro-react.jsx';

const jobsPositionDetailsStyles = (theme) => ({
  divider: {
    marginTop: 15,
    marginBottom: 15,
  },
  title: {
    fontSize: 25,
  },
  paragraph: {
    fontSize: 18,
    lineHeight: '1.7em',
    marginBottom: 15,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  behaviorList: {
    marginTop: 0,
    listStyle: 'none',
    padding: 0,
  },
  descriptionHtml: {
    fontSize: '18px',
    lineHeight: '22px',
    marginTop: '10px',
  },
  listItemStyle: {
    listStyle: 'disc',
  },
  titleIdNameJob: {
    color: dnaGreen,
    fontSize: 35,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
      marginTop: '60px',
      marginLeft: '16px',
      marginRight: '16px',
      textAlign: 'center',
      width: 'auto',
    },
  },
});

export default jobsPositionDetailsStyles;
