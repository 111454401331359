export const evidenceTypes = [
  { label: 'Link', value: 'link' },
  { label: 'PDF', value: 'file' },
  { label: 'Imagem', value: 'image' },
];

export const evidenceStatus = [
  { label: 'Aceito', value: 'accepted' },
  { label: 'Recusado', value: 'refused' },
  { label: 'Aguardando análise', value: 'waiting_analysis' },
];
