import LivesModal from './LivesModal';

import LiveOneForm from './LivesForms/Season01/LiveOneForm';
import LiveTwoForm from './LivesForms/Season01/LiveTwoForm';
import LiveThreeForm from './LivesForms/Season01/LiveThreeForm';
import LiveFourForm from './LivesForms/Season01/LiveFourForm';
import LiveFiveForm from './LivesForms/Season01/LiveFiveForm';
import LiveSevenForm from './LivesForms/Season01/LiveSevenForm';
import LiveEightForm from './LivesForms/Season01/LiveEightForm';
import LiveSixForm from './LivesForms/Season02/LiveSixForm';
import LiveTenForm from './LivesForms/Season01/LiveTenForm';
import LiveNineForm from './LivesForms/Season01/LiveNineForm';

const data = [
  {
    id: 'tYdpGnZ7Zfs',
    title: 'O trabalho dos seus sonhos',
    description:
      'Descubra como suas capacidades são construídas ao longo do tempo para formar o seu potencial profissional e levar você onde quer chegar.',
    modalContent: LivesModal,
    form: LiveOneForm,
  },
  {
    id: 'S4a56nIfus0',
    title: 'Futuro profissional',
    description:
      'Identifique seus talentos e paixões e aplique-os na profissão que escolheu como forma de diferenciar você da multidão e encontrar realização.',
    modalContent: LivesModal,
    form: LiveTwoForm,
  },

  {
    id: 'GbAT2IC-eY0',
    title: '10 vezes mais você',
    description:
      'Desvende qual é o real impacto de seus estudos, dedicação e inteligência na sua vida e no seu plano de carreira.',
    modalContent: LivesModal,
    form: LiveThreeForm,
  },

  {
    id: 'MlNsK35S5vU',
    title: 'Trabalhabilidade',
    description:
      'Descubra o que de fato faz a diferença na construção de uma carreira à prova de crises e mudanças no mercado de trabalho.',
    modalContent: LivesModal,
    form: LiveFourForm,
  },

  {
    id: 'QaaXklsn3eg',
    title: 'Líder do futuro',
    description:
      'Identifique as competências que já hoje permitem identificar novos talentos e os líderes do futuro.',
    modalContent: LivesModal,
    form: LiveFiveForm,
  },
  {
    id: 'i1Bv0993LnM',
    title: 'O trabalho dos sonhos',
    description:
      'Descubra quais são os fatores que permitem a construção de seu futuro e sonhos sem mitos, tabus e medos.',
    modalContent: LivesModal,
    form: LiveSixForm,
  },
  {
    id: 'VbTUHZp9RCA',
    title: 'Propósito',
    description:
      'Aprenda o significado do termo lifelong learning e sua importância para o seu futuro no seu presente.',
    modalContent: LivesModal,
    form: LiveSevenForm,
  },

  {
    id: 't6-_bWcUmNM',
    title: 'Networking e Colaboração',
    description:
      'Descubra a revolução da colaboração e o fator decisivo na construção de uma nova perspectiva de carreira e sociedade.',
    modalContent: LivesModal,
    form: LiveEightForm,
  },

  {
    id: 'o16Z7wImt-4',
    title: 'Mercado de trabalho',
    description:
      'Desvende como o mercado de trabalho realmente funciona e quais são os fatores de peso na contratação de talentos universitários.',
    modalContent: LivesModal,
    form: LiveNineForm,
  },

  {
    id: 'FFGuSCO-weM',
    title: 'Construindo meu Pitch',
    description:
      'Aprenda a elaborar uma apresentação de quem você é e onde quer chegar de forma simples, efetiva e impactante.',
    modalContent: LivesModal,
    form: LiveTenForm,
  },

  {
    id: 'i0nHZSQ5SRk',
    title: 'Como construir seu Currículo',
    description:
      'Aprenda a construir seu currículo com base em seus pontos fortes.',
    modalContent: LivesModal,
  },
];

export default data;
