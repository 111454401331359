import React, { Fragment } from 'react';
import RoundedChart from 'components/RoundedChart/RoundedChart.jsx';
import { object } from 'prop-types';

const TechnicalCompetences = (props) => {
  const { technicalCompetences } = props;

  return (
    <Fragment>
      {technicalCompetences.average > 0 && (
        <RoundedChart
          chartValue={technicalCompetences.average}
          chartText="Média geral"
        />
      )}
    </Fragment>
  );
};
TechnicalCompetences.propTypes = {
  technicalCompetences: object,
};
export default TechnicalCompetences;
