import styled from 'styled-components';

export const Container = styled.div`
  > p {
    font-size: 12px;
    color: #4b4b4d;
    font-weight: 400;
    margin-top: 6px;
    margin-bottom: 17px;
  }

  > h5 {
    font-weight: 500;
    color: #606062;
    font-size: 15px;
  }
`;

export const DropArea = styled.div`
  width: 100%;
  border: 1px dashed #606062;
  height: 185px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


  > input {
    width: 100%;
    height: 185px;
    opacity: 0;
    z-index: 10;
  }

  > div {
    position: absolute;
    max-width: 450px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 14px;

    > img {
      margin-bottom: 1.2em;
    }

    .circle-area {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 200px;

      background-color: ${props => props.disabled ? "#E5E5E5" : props.universityColor || "#009291"};
    }
  }
`;
