import React from 'react';
import { Fragment } from 'react';
import {
  NextButton,
  PrevsButton,
  FinishButton,
  ButtonsContainer,
} from 'views/ProfessionalStatus/styles/styles';
import FormLoading from 'views/ProfessionalStatus/utils/Loading';

function FooterForm({
  labelFinishButton,
  universityColor,
  disabledButton,
  prevsState,
  haveFinish,
  haveNext,
  haveBack,
  loading,
}) {
  const returnButtons = () => {
    if (haveNext && !haveBack && !haveFinish) {
      return (
        <Fragment>
          <div />
          <NextButton universityColor={universityColor} />
        </Fragment>
      );
    }
    if (haveNext && haveBack && !haveFinish) {
      return (
        <Fragment>
          <PrevsButton
            universityColor={universityColor}
            type={'button'}
            onClick={prevsState}
          />
          <NextButton universityColor={universityColor} type="submit" />
        </Fragment>
      );
    }
    if (haveBack && !haveNext && !haveFinish) {
      return <PrevsButton type={'button'} onClick={prevsState} />;
    }

    if (haveFinish && haveBack) {
      return (
        <Fragment>
          <PrevsButton
            universityColor={universityColor}
            onClick={prevsState}
            type={'button'}
          />
          <FinishButton
            universityColor={universityColor}
            disabled={disabledButton}
            type={'submit'}
          >
            {labelFinishButton ? (
              labelFinishButton
            ) : loading ? (
              <FormLoading />
            ) : (
              'Salvar'
            )}
          </FinishButton>
        </Fragment>
      );
    }

    if (haveFinish && !haveNext && !haveBack) {
      return (
        <Fragment>
          <div></div>
          <FinishButton
            universityColor={universityColor}
            disabled={disabledButton}
            type={'submit'}
          >
            {labelFinishButton ? (
              labelFinishButton
            ) : loading ? (
              <FormLoading />
            ) : (
              'Salvar'
            )}
          </FinishButton>
        </Fragment>
      );
    }
  };

  return <ButtonsContainer>{returnButtons()}</ButtonsContainer>;
}

export default FooterForm;
