export const genericMessage = {
  connectedCompanies: {
    title: 'Nenhuma empresa encontrada',
    subtitle:
      'Caso queira, acesse a aba "convites enviados" para enviar um convite de conexão para uma empresa.',
  },
  connectedCompaniesSearch: {
    title: 'Nenhuma empresa encontrada',
    subtitle: 'Não foi encontrado nenhum resultado para a busca.',
  },

  disconnectedCompanies: {
    title: 'Nenhuma empresa encontrada',
    subtitle:
      'Caso queira, acesse a aba ”empresas conectadas” para se desconectar de alguma empresa.',
  },
  disconnectedCompaniesSearch: {
    title: 'Nenhuma empresa encontrada',
    subtitle: 'Não foi encontrado nenhum resultado para a busca.',
  },
};
