import React, { useState, useContext, useEffect } from 'react';

import { BaseContainer, Box, Wrapper, Card, LoadingWrapper } from './styled';
import ModalInformation from 'components/informationModal';

import BaseLayoutContext from 'contexts/base-layout';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';

import { getDocumentsById } from '../../../../Services';

import DocumentForm from './Components/Form';
import FilesList from './Components/FilesList';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';

function DocumentsUploadModal({
  companyId,
  closeModal,
  handlePageChange,
  selectedCompanyId,
  setSelectedCompanyId,
}) {
  const { universityColor } = useContext(BaseLayoutContext);

  const [filesList, setFilesList] = useState([]);
  const [modalType, setModalType] = useState('loading');
  const [formInitialData, setFormInitialData] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);

  useEffect(() => {
    getDocuments();
  }, []);

  const getDocuments = async () => {
    if (selectedCompanyId) {
      const documents = await getDocumentsById(selectedCompanyId);

      if (documents.length === 0) {
        setModalType('form');
        return;
      }

      setFilesList(documents);
      setModalType('view');
      return;
    }
    if (!companyId) {
      return;
    }
    const documents = await getDocumentsById(companyId);

    if (documents.length === 0) {
      setModalType('form');
      return;
    }

    setFilesList(documents);
    setModalType('view');
  };

  const handleClickClose = () => {
    setSelectedCompanyId(null);
    closeModal();
  };

  const components = {
    loading: (
      <LoadingWrapper>
        <Loading color="#009291" />
      </LoadingWrapper>
    ),
    view: (
      <FilesList
        files={filesList}
        universityColor={universityColor}
        setModalType={setModalType}
        setFormInitialData={setFormInitialData}
        formInitialData={formInitialData}
        setSelectedDocument={setSelectedDocument}
        handlePageChange={handlePageChange}
        setFilesList={setFilesList}
        companyId={companyId}
      />
    ),
    form: (
      <DocumentForm
        companyId={companyId}
        universityColor={universityColor}
        formInitialData={formInitialData}
        selectedDocument={selectedDocument}
        closeModal={closeModal}
        handlePageChange={handlePageChange}
        getDocuments={getDocuments}
        setModalType={setModalType}
        setFormInitialData={setFormInitialData}
      />
    ),
  };

  const renderComponent = () => {
    return components[modalType];
  };

  return (
    <BaseContainer>
      <ModalInformation
        universityColor={universityColor}
        modalOpenState={false}
        config={{}}
      />
      <Box>
        <Wrapper>
          <Card universityColor={universityColor}>
            <CloseButton onClick={handleClickClose} />
            {renderComponent()}
          </Card>
        </Wrapper>
      </Box>
    </BaseContainer>
  );
}

export default DocumentsUploadModal;
