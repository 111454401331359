import { useState } from 'react';
import { CancelTab } from './CancelTab';
import { ExpiredTab } from './ExpiredTab';
import { Container, SubContainer } from './styled';
import { AccomplishedTab } from './AccomplishedTab';
import { GenericInternalTabs } from 'components/GenericNavegation';
import { useHistoricMentorshipContext } from '../../context';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { mentorshipApi } from '../../../../services/api';
import { FilterMentorship } from 'views/StaffPages/Mentorship/components/Filter';
import { useMentorship } from 'views/StaffPages/Mentorship/contexts/MentorshipContext';

export default function InternalTab({
  openAccomplishedDetailsModal,
  openCancelDetailsModal,
  openExpiredDetailsModal,
}) {
  const [activeSubTab, setActiveSubTab] = useState(0);
  const {
    mentorshipCounts,
    updateMentorshipsCount,
    fetchMentorshipsCount,
    setMentorshipCounts,
  } = useMentorship();
  const {
    setMentorships,
    setPage,
    setHasNextPage,
    setLoading,
    appliedFilters,
    setAppliedFilters,
    filterState,
    setFilterState,
  } = useHistoricMentorshipContext();

  const handleChangeTab = (value) => {
    if (activeSubTab !== value) {
      setMentorships(null);
    }
    setFilterState({});
    setPage(1);
    setAppliedFilters({});
    setActiveSubTab(value);
  };

  const tabs = [
    {
      id: 0,
      label: `Realizadas (${mentorshipCounts.realized ?? 0})`,
      component: (
        <AccomplishedTab
          openAccomplishedDetailsModal={openAccomplishedDetailsModal}
          updateMentorshipsCount={updateMentorshipsCount}
          fetchMentorshipsCount={fetchMentorshipsCount}
          setMentorshipCounts={setMentorshipCounts}
        />
      ),
    },
    {
      id: 1,
      label: `Canceladas (${mentorshipCounts.canceled ?? 0})`,
      component: (
        <CancelTab
          openCancelDetailsModal={openCancelDetailsModal}
          updateMentorshipsCount={updateMentorshipsCount}
          fetchMentorshipsCount={fetchMentorshipsCount}
          setMentorshipCounts={setMentorshipCounts}
        />
      ),
    },
    {
      id: 2,
      label: `Expiradas  (${mentorshipCounts.expired ?? 0})`,
      component: (
        <ExpiredTab
          openExpiredDetailsModal={openExpiredDetailsModal}
          isActiveExpiredTab={activeSubTab === 2}
          fetchMentorshipsCount={fetchMentorshipsCount}
          updateMentorshipsCount={updateMentorshipsCount}
          setMentorshipCounts={setMentorshipCounts}
        />
      ),
    },
  ];

  const renderTabContent = () => {
    const selectedTab = tabs.find((tab) => tab.id === activeSubTab);
    return selectedTab ? selectedTab.component : null;
  };

  async function onApplyFilters(filters) {
    setMentorships([]);
    setLoading(true);
    setPage(1);
    const queryString = queryParamsFromFilterObject(filters, {
      commaSeparatedFields: ['mentor'],
    });
    let queryParams = `limit=20&offset=0`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    let response;
    if (activeSubTab === 0) {
      response = await mentorshipApi.getRealizedMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, realized: response.count });
    } else if (activeSubTab === 1) {
      response = await mentorshipApi.getCanceledMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, canceled: response.count });
    } else {
      response = await mentorshipApi.getExpiredMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, expired: response.count });
    }
    setMentorships(response.results);
    setHasNextPage(response.next !== null);
    setLoading(false);
  }

  async function onCleanFilters() {
    setMentorships([]);
    setLoading(true);
    setPage(1);
    let queryParams = `limit=20&offset=0`;
    let response;
    if (activeSubTab === 0) {
      response = await mentorshipApi.getRealizedMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, realized: response.count });
    } else if (activeSubTab === 1) {
      response = await mentorshipApi.getCanceledMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, canceled: response.count });
    } else {
      response = await mentorshipApi.getExpiredMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, expired: response.count });
    }
    setMentorships(response.results);
    setHasNextPage(response.next !== null);
    setLoading(false);
  }

  return (
    <Container>
      <SubContainer>
        <GenericInternalTabs
          activeTab={activeSubTab}
          handleChange={handleChangeTab}
          tabs={tabs.map((tab) => tab.label)}
          height="45px"
        />
        <FilterMentorship
          onApplyFilters={onApplyFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          filterState={filterState}
          setFilterState={setFilterState}
          onCleanFilters={onCleanFilters}
        />
      </SubContainer>
      {renderTabContent()}
    </Container>
  );
}
