import {
  BubbleDialog,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonX,
  ContainerFeedback,
} from './styled';
import React, { Fragment, useMemo, useContext } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import { InformationIcon } from 'assets/backoffice/information';
import Tooltip from 'components/NewTooltip';

import { IoClose, IoPersonCircle } from 'react-icons/io5';
import { LuCalendarDays } from 'react-icons/lu';
import BaseLayoutContext from 'contexts/base-layout';

export default function FeedbackModal({
  config,
  onClose,
  open,
  buttonConfirmFunction,
  buttonCancelFunction,
}) {
  const { universityColor: UniversityColorBase } = useContext(
    BaseLayoutContext
  );

  if (!open) {
    return null;
  }

  const universityColor = UniversityColorBase || '#009291';

  const memorizedModal = useMemo(
    () => (
      <Wrapper>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            <ButtonX colorBg={config?.style?.colorBgButtonX} onClick={onClose}>
              <IoClose size={25} color="#4B4B4D" />
            </ButtonX>
            <WrapperTitle alignIcon={config?.style?.alignIcon}>
              <IconPersonStyled
                universityColor={universityColor}
                sizeIconPerson={config?.style?.sizeIconPerson}
                src={config.icon || IconPerson}
              />
              <BubbleDialog universityColor={universityColor}>
                <H4Styled>{config.bubbleText}</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled
              textAlign={config?.style?.textAlign}
              fontWeight={config?.style?.fontWeight}
              marginTop={config?.style?.marginTop}
              fontSize={config?.style?.fontSize}
              lineHeight={config?.style?.lineHeight}
              gapParagraph={config?.style?.gapParagraph}
              maxWidth={config?.style?.maxWidth}
            >
              <p>{ReactHtmlParser(config.ParagraphText)}</p>
            </ParagraphStyled>

            <ContainerFeedback>
              <div className="feedback">{config.feedback.feedback}</div>
              <div className="senderInformation">
                <span>
                  <IoPersonCircle size={20} color={universityColor} />
                  Enviado por {config.feedback.created_by}
                </span>
                <span>
                  <LuCalendarDays size={20} color={universityColor} />
                  {new Date(config.feedback.created_at).toLocaleDateString()}
                </span>
              </div>
            </ContainerFeedback>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [open, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

FeedbackModal.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 330,
    iconWidth: 95,
    iconHeight: 95,
    titleWidth: 100,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    feedback: 'Adicione seu Feedback',
  },
};
