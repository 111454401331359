import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  display: flex;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: ${(props) => (props.width ? props.width + 'px' : '415px')};
  height: ${(props) => (props.height ? props.height + 'px' : '320px')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  padding: 35px;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
    height: 100%;
    align-items: flex-end;
    padding-bottom: 0px;
    align-items: center;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;

  align-items: center;
  height: 20%;
  justify-content: ${(props) =>
    props.alignIcon ? props.alignIcon : 'flex-start'};
  width: ${(props) => (props.width ? props.width + '%' : '100%')};
  margin-top: 27px;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 1.5em;
  border-radius: 4px;
  width: fit-content;
  min-height: 49px;
  position: relative;
  padding: 0px 20px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-top: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 21px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #ffff;
  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  width: ${(props) => (props.sizeIconPerson ? props.sizeIconPerson : '80px')};
  height: ${(props) => (props.sizeIconPerson ? props.sizeIconPerson : '80px')};
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 13px;
  font-weight: bold;
  color: #ffff;
`;

export const ParagraphStyled = styled.div`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '30px')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '312px')};
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gapParagraph ? props.gapParagraph + 'px' : '0px')};
  color: ${(props) =>
    props.colorParagraph ? props.colorParagraph : '#606062'};

  > p {
    font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'start')};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
    line-height: ${(props) =>
      props.lineHeight ? props.lineHeight : props.fontSize};
  }
  word-break: break-word;
`;
export const ButtonX = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 200px;

  background-color: ${(props) => props.colorBg || 'transparent'};
  border: none;

  position: absolute;
  right: 10px;
  top: 10px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerFeedback = styled.div`
  width: 100%;

  > h2 {
    color: #606062;
    font-weight: 500;
    font-size: 15px;
  }

  > .feedback {
    border: 1px solid #c6c6c6;
    height: fit-content;
    border-radius: 4px;
    padding: 10px;
  }

  > .senderInformation {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    > span {
      display: flex;
      gap: 10px;
      font-size: 12px;
    }
  }
`;
