import React, { Fragment, useEffect, useState } from 'react';
import {
  Overlay,
  Box,
  Content,
  HeaderModal,
  Title,
  SubTitle,
  DividerTitle,
  DivAssociation,
  TitleAssociation,
  ListAssociations,
  ContainerPagination,
  CloseButton,
} from './style';
import Pagination from 'components/CustomPagination';
import academicInnovationApi from '../../services/academicInnovationApi';
import GenericMessage from 'components/GenericMessage';
import { CircularProgress } from '@material-ui/core';
import { useFormContext } from '../../contexts';

export default function ModalViewAssociation({
  setViewAssociationModal,
  currentItemView,
}) {
  const { isLoading, setIsLoading } = useFormContext();

  const [limit, setLimit] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);

  const handleClickClose = () => {
    setViewAssociationModal(false);
  };

  const [tags, setTags] = useState([]);

  const listViewAssociation = async (query) => {
    setIsLoading(true);

    const {
      results,
      count,
    } = await academicInnovationApi.getAssociatedTagsModal(
      currentItemView.id,
      query
    );
    setTags(results);
    setCount(count);
    setIsLoading(false);
  };

  useEffect(() => {
    const newPage = `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`;
    listViewAssociation(newPage);
  }, []);

  const handlePageChange = async (page) => {
    const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
    await listViewAssociation(newPage);
    setCurrentPage(page);
  };

  const handleLimitChange = async (newLimit) => {
    const newPage = `limit=${newLimit}&offset=${
      (currentPage - 1) * newLimit || 0
    }`;
    setCurrentPage(1);
    await listViewAssociation(newPage);
    setLimit(newLimit);
  };

  return (
    <Fragment>
      <Overlay>
        <Box>
          <Content>
            <HeaderModal>
              <DividerTitle>
                {isLoading ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '10rem 0 0 15rem',
                    }}
                  >
                    <CircularProgress
                      style={{ color: '#009291', thickness: 4 }}
                    />
                  </div>
                ) : tags.length === 0 ? (
                  <GenericMessage
                    style={{
                      margin: '14px',
                    }}
                    title="Nenhuma Associação foi encontrada"
                  />
                ) : (
                  <div>
                    <Title>{currentItemView.name}</Title>
                    <SubTitle> TAG PRINCIPAL</SubTitle>
                  </div>
                )}
              </DividerTitle>
              <CloseButton onClick={handleClickClose} />
            </HeaderModal>

            <DivAssociation>
              {tags.length > 0 && (
                <>
                  <TitleAssociation>ASSOCIAÇÕES</TitleAssociation>
                  {tags.map((item, index) => (
                    <ListAssociations key={index}>
                      <span className="list-name">{item.name}</span>
                    </ListAssociations>
                  ))}
                </>
              )}
            </DivAssociation>
            <ContainerPagination>
              <Pagination
                className="pagination-bar"
                currentPage={currentPage}
                onPageChange={handlePageChange}
                setLimit={handleLimitChange}
                showLimit={tags.length > 10}
                setCurrentPage={setCurrentPage}
                pageRef={null}
                limit={limit}
                totalCount={count}
              ></Pagination>
            </ContainerPagination>
          </Content>
        </Box>
      </Overlay>
    </Fragment>
  );
}
