import { useState, Fragment, useEffect, useContext } from 'react';
import { useLearningIcons } from 'views/Student/Learning/Hooks/useLearningIcons';
import {
  Container,
  Image,
  Title,
  ListItemCard,
  ContainerButtons,
  AdvanceButton,
  WhiteButton,
  SimpleButton,
  ContainerReactButtons,
} from './style';
import SkeletonCard from '../../UIComponents/SkeletonCard';
import {
  cardStructure,
  returnTypes,
  returnModality,
  returnCoupon,
  returnPublicationType,
} from 'views/Student/Learning/Utils/tools';
import {
  GenerateBlob,
  finishedLearning,
  getAccess,
  getDetails,
  newReaction,
} from 'views/Student/Learning/Services';
import ModalViewDetails from '../Modals/ViewDetails';
import { CardDivider, AdditionalHoursFlag } from '../../Styles/BaseComponent';
import InformationConclusionModal from '../Modals/InformConclusion';
import SequentialVideoModal from '../Modals/AccessModal/SequentialVideo';
import ModalInformation from 'components/informationModal';
import { configMarkAsRead } from 'views/Student/Learning/Utils/configModals';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import ReactHtmlParser from 'react-html-parser';
import { ReactButtons } from '../ReactButtons';
import GenericMessage from 'components/GenericMessage';
import moment from 'moment';
import BaseLayoutContext from 'contexts/base-layout';
import { CurriculumContext } from 'contexts/CurriculumContext';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function LearningCard({
  array,
  availableListRef,
  isFinished,
  listingType,
  learningCountControl,
  scrollUp,
}) {
  const {
    MdLocationPin,
    RiMoneyDollarCircleFill,
    BsPersonFill,
    IoExtensionPuzzleOutline,
    IoCalendarOutline,
    DigitalBookIcon,
    TbSpeakerphone,
    IoIosCalendar,
    HiFlag,
    DialogIcon,
    BsStarFill,
    HiWifi,
  } = useLearningIcons();

  const { setIsOpenCurriculumModal, profile } = useContext(CurriculumContext);

  const { universityColor } = useContext(BaseLayoutContext);

  const {
    getAvailableLearnings,
    getFinalizedLearnings,
    getFavoritesLearnings,
  } = useAPICallsLearning();

  const {
    setLearnings,
    setFavoritesLearning,
    setFinalizedLearning,
  } = useContext(LearningContext);

  const [details, setDetails] = useState({});
  const [modalViewDetails, setModalViewDetails] = useState(false);
  const [informConclusionData, setInformConclusionData] = useState(null);
  const [modalInformConclusion, setModalInformConclusion] = useState(false);

  const [types, setTypes] = useState(null);

  const [contentAccess, setContentAccess] = useState(null);

  const [modalSequentialVideo, setModalSequentialVideo] = useState(false);
  const [modalMarkAsRead, setModalMarkAsRead] = useState(false);

  const [urlDocument, setUrlDocument] = useState(null);
  const [modalDownloadPDF, setModalDownloadPDF] = useState(false);

  const returnIconsType = {
    event: <IoIosCalendar color={universityColor || '#009291'} size={18} />,
    program: <DigitalBookIcon size={17} color={universityColor || '#009291'} />,
    course: <DigitalBookIcon size={17} color={universityColor || '#009291'} />,

    trail: (
      <IoExtensionPuzzleOutline
        color={universityColor || '#009291'}
        size={18}
      />
    ),
    serie: (
      <IoExtensionPuzzleOutline
        color={universityColor || '#009291'}
        size={18}
      />
    ),
    e_book: (
      <IoExtensionPuzzleOutline
        color={universityColor || '#009291'}
        size={18}
      />
    ),
    article: (
      <IoExtensionPuzzleOutline
        color={universityColor || '#009291'}
        size={18}
      />
    ),

    challenge: <HiFlag color={universityColor || '#009291'} size={18} />,
    announcements: (
      <DialogIcon color={universityColor || '#009291'} size={18} />
    ),
  };

  const returnIconsModality = (item) => {
    if (item === 'online') {
      return <HiWifi color={universityColor || '#009291'} size={18} />;
    }
    return <MdLocationPin color={universityColor || '#009291'} size={18} />;
  };

  const handleModalViewDetails = async (id) => {
    try {
      const response = await getDetails(id);
      setModalViewDetails(true);
      setDetails(response);
    } catch (error) {
      return;
    }
  };

  const handleInformConclusion = async (item) => {
    if (!profile.academic_profile?.curriculum_filled) {
      setModalViewDetails(false);
      return setIsOpenCurriculumModal(true);
    }

    setModalInformConclusion(true);
    setInformConclusionData(item);
  };

  const handleMarkAsRead = async (item) => {
    if (!profile.academic_profile?.curriculum_filled) {
      setModalViewDetails(false);
      return setIsOpenCurriculumModal(true);
    }

    try {
      const response = await finishedLearning(item.id);

      setModalMarkAsRead(true);
      learningCountControl();
    } catch (error) {
      return customSnackbar('Ocorreu um erro', 'error');
    }
  };

  const handleCloseModalMarkAsRead = () => {
    setModalMarkAsRead(false);

    if (listingType === 'Available') {
      setLearnings(null);
      learningCountControl();
      getAvailableLearnings(true);
      scrollUp();
      return;
    }

    if (listingType === 'Favorite') {
      setFavoritesLearning(null);
      learningCountControl();
      getFavoritesLearnings(true);
      scrollUp();
      return;
    }

    if (listingType === 'Finalized') {
      setFinalizedLearning(null);
      learningCountControl();
      getFinalizedLearnings(true);
      scrollUp();
      return;
    }
  };

  const handleAccess = async (item) => {
    if (!profile.academic_profile?.curriculum_filled) {
      setModalViewDetails(false);
      return setIsOpenCurriculumModal(true);
    }

    const response = await getAccess(item.id);
    const { content } = response;

    if (item.type_access === 'video') {
      if (content) {
        setContentAccess(content);
        setModalSequentialVideo(true);
      }
    }

    if (item.type_access === 'access_link') {
      if (content) {
        window.open(content, '_blank');
        newReaction(item.id, {
          type: 'url_clicked',
          recommended: item.is_recommended,
        });
      }
    }

    if (item.type_access === 'attraction_document') {
      setTypes(item.type);
      setUrlDocument(content);
      setModalDownloadPDF(true);
    }
  };

  const handleCloseModalDownloadPDF = () => {
    setModalDownloadPDF(false);
    setTypes(null);
  };

  const isLogo = (imageURL) => {
    return imageURL && imageURL.includes('logos/') ? true : false;
  };

  const handleDownloadModal = async (content) => {
    if (!profile.academic_profile?.curriculum_filled) {
      return setIsOpenCurriculumModal(true);
    }

    const link = await GenerateBlob(content.document_url);
    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.rel = 'noopener noreferrer';
    downloadLink.href = link;
    downloadLink.download = `${content.document_name}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setModalDownloadPDF(false);
  };

  const formatedHours = (item) => {
    if (item?.workload === '1.00') {
      return `${parseInt(item?.workload)} hora complementar`;
    }
    if (item?.workload !== '1.00') {
      return `${parseInt(item?.workload)} horas complementares`;
    }
  };

  const mapLearning = (content) => {
    if (content === null) {
      return <SkeletonCard quantity={25} />;
    }

    if (content?.length === 0) {
      return (
        <GenericMessage
          title="Nenhum resultado encontrado"
          subtitle="Oops! Não conseguimos encontrar nenhum resultado."
        />
      );
    }

    return content?.map((item) => (
      <Container>
        <Image
          universityColor={universityColor}
          isLogo={isLogo(item.attraction_image)}
          hoverOn={returnPublicationType[item?.type_access]}
          onClick={() => handleAccess(item)}
        >
          {item?.workload && (
            <AdditionalHoursFlag
              universityColor={universityColor || '#009291'}
              card
            >
              <div className="circle">
                <BsStarFill size={11} color="#fff" />
              </div>
              <p>{formatedHours(item)}</p>
            </AdditionalHoursFlag>
          )}
          {item?.validity?.value === 'additional' && !item?.workload && (
            <AdditionalHoursFlag
              universityColor={universityColor || '#009291'}
              card
            >
              <div className="circle">
                <BsStarFill size={11} color="#fff" />
              </div>
              <p>Atividade complementar</p>
            </AdditionalHoursFlag>
          )}
          <img src={item.attraction_image} alt="Imagem/Logo" />
        </Image>
        <Title>
          <span>{item.name}</span>
        </Title>
        <ListItemCard>
          {cardStructure[item.type].description && item.description && (
            <div className="wrapper-description">
              <span>{ReactHtmlParser(item?.description)}</span>
            </div>
          )}

          {cardStructure[item.type].modality && (
            <div className="wrapper">
              {returnIconsModality(item?.modality)}
              <p> {returnModality(item)} </p>
            </div>
          )}

          {cardStructure[item.type].type && (
            <div className="wrapper">
              {returnIconsType[item.type]}
              <p>
                {returnTypes[item.type]}
                {item?.category && ` - ${item.category?.label}`}
                {item?.course_category && ` - ${item.course_category?.label}`}
              </p>
            </div>
          )}

          {cardStructure[item.type].publicationType &&
            returnPublicationType[item?.type_access] && (
              <div className="wrapper">
                <TbSpeakerphone
                  color={universityColor || '#009291'}
                  size={18}
                />
                <p> {returnPublicationType[item?.type_access]} </p>
              </div>
            )}

          {cardStructure[item.type].publishedBy && (
            <div className="wrapper">
              <BsPersonFill color={universityColor || '#009291'} size={18} />
              <p> Publicado por {item.created_by.name} </p>
            </div>
          )}

          {cardStructure[item.type].coupon && (
            <div className="wrapper">
              <RiMoneyDollarCircleFill
                color={universityColor || '#009291'}
                size={18}
              />
              <p> {returnCoupon(item)} </p>
            </div>
          )}

          {cardStructure[item.type].postedIn && (
            <div className="wrapper">
              <IoCalendarOutline
                color={universityColor || '#009291'}
                size={18}
              />
              <p>
                {' '}
                Publicado em {moment(item?.created_at).format(
                  'DD/MM/YYYY'
                )}{' '}
              </p>
            </div>
          )}
        </ListItemCard>
        {item.type === 'announcements' && (
          <ContainerButtons>
            {!isFinished && details?.type_access !== '' && (
              <AdvanceButton
                universityColor={universityColor}
                onClick={() => handleMarkAsRead(item)}
                fullWidth
              >
                Marcar como lido
              </AdvanceButton>
            )}
          </ContainerButtons>
        )}
        {item.type !== 'announcements' && (
          <ContainerButtons>
            {item?.type_access !== '' && (
              <AdvanceButton
                universityColor={universityColor}
                fullWidth={isFinished === true}
                onClick={() => handleAccess(item)}
              >
                Acessar
              </AdvanceButton>
            )}
            {!isFinished && (
              <WhiteButton
                universityColor={universityColor}
                fullWidth={item?.type_access === ''}
                onClick={() => handleInformConclusion(item)}
              >
                Informar conclusão
              </WhiteButton>
            )}
          </ContainerButtons>
        )}
        <CardDivider marginOnTheSide="21px" />

        <ContainerReactButtons>
          <SimpleButton
            universityColor={universityColor}
            onClick={() => handleModalViewDetails(item.id)}
          >
            Visualizar detalhes &gt;
          </SimpleButton>
          <ReactButtons
            id={item.id}
            item={item}
            resetListAvailable={getAvailableLearnings}
            scrollUp={scrollUp}
            resetListFavorite={getFavoritesLearnings}
            resetListFinalized={getFinalizedLearnings}
            listingType={listingType}
            isFinished={isFinished}
            learningCountControl={learningCountControl}
          />
        </ContainerReactButtons>
      </Container>
    ));
  };

  const configDownloadPDF = {
    modalWidth: 428,
    bubbleText: 'Atenção!',
    ParagraphText: `Este ${returnTypes[types]} é um documento PDF. `,
    TwoParagraphText: `Tem certeza que deseja baixar este arquivo ?`,
    buttonCloseX: true,
    showButtonCancel: true,
    buttonCancelText: 'NÃO',
    buttonConfirmText: 'SIM, BAIXAR',
    style: {
      gapParagraph: 5,
      textAlign: 'start',
      fontWeight: '400',
      fontSize: '20px',
      sizeIconPerson: '78px',
      colorBgButtonX: '#ececec',
      lineHeight: '25px',
      maxWidth: '100%',
    },
  };

  return (
    <Fragment>
      {Object.keys(details).length > 0 && modalViewDetails && (
        <ModalViewDetails
          details={details}
          setDetails={setDetails}
          controlOpenModal={modalViewDetails}
          setControlOpenModal={setModalViewDetails}
          isFinished={isFinished}
          handleAccess={handleAccess}
          handleMarkAsRead={handleMarkAsRead}
          handleInformConclusion={handleInformConclusion}
          listingType={listingType}
          learningCountControl={learningCountControl}
          scrollUp={scrollUp}
        />
      )}
      {modalInformConclusion && (
        <InformationConclusionModal
          data={informConclusionData}
          resetData={setInformConclusionData}
          controlOpenModal={modalInformConclusion}
          setControlOpenModal={setModalInformConclusion}
          availableListRef={availableListRef}
          setModalViewDetails={setModalViewDetails}
          listingType={listingType}
          resetListAvailable={getAvailableLearnings}
          resetListFavorite={getFavoritesLearnings}
          resetListFinalized={getFinalizedLearnings}
          learningCountControl={learningCountControl}
        />
      )}
      {modalSequentialVideo && (
        <SequentialVideoModal
          contentAccess={contentAccess}
          setContentAccess={setContentAccess}
          controlOpenModal={modalSequentialVideo}
          setControlOpenModal={setModalSequentialVideo}
        />
      )}
      {modalDownloadPDF && (
        <ModalInformation
          config={configDownloadPDF}
          closeModalFunction={handleCloseModalDownloadPDF}
          modalOpenState={modalDownloadPDF}
          buttonCancelFunction={handleCloseModalDownloadPDF}
          buttonConfirmFunction={() => handleDownloadModal(urlDocument)}
          universityColor={universityColor}
        />
      )}
      {modalMarkAsRead && (
        <ModalInformation
          config={configMarkAsRead}
          modalOpenState={modalMarkAsRead}
          buttonConfirmFunction={handleCloseModalMarkAsRead}
          listingType={listingType}
          universityColor={universityColor}
        />
      )}

      {mapLearning(array)}
    </Fragment>
  );
}
