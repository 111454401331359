import { authenticatedAPI } from 'services/api';

export const changePasswordUniversity = async (newPassword) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/change-password`, newPassword)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};
