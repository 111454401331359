import React, { useContext } from 'react';

import { Information } from './styled';
import { node, string } from 'prop-types';
import BaseLayoutContext from 'contexts/base-layout';

export default function CardInformation({ icon, text }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Information color={universityColor}>
      <div>{icon}</div> {text}
    </Information>
  );
}

CardInformation.prototype = {
  icon: node,
  text: string,
};
