import React, { Fragment } from 'react';
import { shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import BehavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import useDefineColor from 'constants/colors';

const ExtraCoursesHelpContent = (props) => {
  const { classes, universityColor } = props;
  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Formação e Capacitação
          </h2>
          <p className={classes.cardCategory}>
            Aqui é importante que você cadastre todos os cursos de capacitação
            e/ou formação que você já realizou. Ah! Vale também os cursos em
            andamento. Vale incluir: cursos livres, cursos de extensão,
            formações, capacitações, treinamentos, vivências, oficinas,
            workshops, webinários, cursos técnicos, cursos profissionalizantes e
            até outro curso superior ou de pós, mestrado e/ou doutorado que
            tenha iniciado ou finalizado. Recomendamos que você realize 2 cursos
            de capacitação a cada semestre para se manter atualizado com a nova
            dinâmica do mundo do trabalho.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/6aEVYBsdoVM"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="Video de explicação do formulário de Formação e Capacitação"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

ExtraCoursesHelpContent.propTypes = {
  classes: shape({}).isRequired,
};

export default withStyles(BehavioralTestStyle)(ExtraCoursesHelpContent);
