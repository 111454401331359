import LogosAnimated from 'views/LoginPage/Login/UIComponents/LogosAnimated';
import {
  Box,
  Modal,
  Wrapper,
  Container,
  LoginArea,
  Logo,
  Title,
  InputArea,
  Text,
  SendButton,
  CloseButton,
  ByText,
  CreateAccountDiv,
  ButtonOutlined,
} from '../Styles/PreviewModal';
import { IoClose } from 'react-icons/io5';

export function PreviewModal({
  control,
  close,
  data,
  updatedData,
  isCompanyPreview = false,
  isNewCompanyLogin,
}) {
  return (
    <Wrapper visible={control}>
      <Box>
        <Modal
          universityColor={
            isCompanyPreview ? data?.university?.color : data?.color
          }
        >
          <Container image={data?.image?.file}>
            {data?.image?.file ? (
              <div className="left-side-image" />
            ) : (
              <div className="left-side-default">
                <LogosAnimated
                  boxSize="37px"
                  size={20}
                  color={
                    isCompanyPreview ? data?.university?.color : data?.color
                  }
                />
                <div className="box-text">
                  <h3>
                    <strong>Prepare-se para o novo mundo do trabalho.</strong>
                  </h3>
                  <span>
                    Conquiste o autoconhecimento, amplie seu potencial e
                    independência para fazer escolhas em sua carreira.
                  </span>
                </div>
              </div>
            )}

            <div className="right-side">
              <CloseButton onClick={close}>
                <IoClose size={16} />
              </CloseButton>
              <LoginArea>
                <Logo src={data?.logo} isCompany={isCompanyPreview} />
                <Title isCompany={isCompanyPreview}>
                  Seja bem vindo! <br />
                  {isCompanyPreview
                    ? 'Acesse sua conta.'
                    : 'Faça login em sua conta.'}
                </Title>
                <InputArea
                  universityColor={
                    isCompanyPreview ? data?.university?.color : data?.color
                  }
                >
                  <span>
                    {isNewCompanyLogin ? 'E-mail de acesso' : 'Usuário'}{' '}
                  </span>
                  <Text>{updatedData?.login_hint}</Text>
                  <input type="text" placeholder="Digite aqui..." disabled />
                </InputArea>
                <InputArea
                  isCompany={isCompanyPreview}
                  universityColor={
                    isCompanyPreview ? data?.university?.color : data?.color
                  }
                >
                  <span> Senha </span>
                  <input type="text" placeholder="Digite aqui..." disabled />
                  <Text error>{updatedData?.password_hint}</Text>

                  <Text
                    universityColor={
                      isCompanyPreview ? data?.university?.color : data?.color
                    }
                    link
                  >
                    Esqueceu a sua senha ?
                  </Text>
                  <SendButton
                    universityColor={
                      isCompanyPreview ? data?.university?.color : data?.color
                    }
                  >
                    {' '}
                    Entrar{' '}
                  </SendButton>
                </InputArea>

                {isCompanyPreview && (
                  <CreateAccountDiv>
                    <div className="divider">
                      <hr />
                      <span>OU</span>
                      <hr />
                    </div>
                    <ButtonOutlined
                      color={
                        isCompanyPreview ? data?.university?.color : data?.color
                      }
                    >
                      Criar conta
                    </ButtonOutlined>
                  </CreateAccountDiv>
                )}
              </LoginArea>
              <ByText>
                <span>By</span>
                <strong>Workalove</strong>
              </ByText>
            </div>
          </Container>
        </Modal>
      </Box>
    </Wrapper>
  );
}
