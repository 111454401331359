export const filtersConfig = [
  {
    name: 'associated_areas',
    label: 'Competências',
    type: 'MULTI_SELECT',
    optionsKey: 'associated_areas',
  },
  [
    {
      name: 'event_type',
      label: 'Tipo',
      type: 'MULTI_SELECT',
      optionsKey: 'event_type',
    },
    {
      name: 'event_modality',
      label: 'Modalidade',
      type: 'SINGLE_SELECT',
      optionsKey: 'event_modality',
    },
  ],
  [
    {
      name: 'certification',
      label: 'Com certificação',
      type: 'SINGLE_SELECT',
      optionsKey: 'boolean',
    },
    {
      name: 'complementar_activity',
      label: 'Válido como atividade complementar',
      type: 'SINGLE_SELECT',
      optionsKey: 'boolean',
    },
  ],
  {
    name: 'macro_areas',
    label: 'Área de atuação',
    type: 'MULTI_SELECT',
    optionsKey: 'macro_areas',
  },
  [
    {
      name: 'course',
      label: 'Curso',
      type: 'MULTI_SELECT',
      optionsKey: 'course',
    },
    {
      name: 'semesters',
      label: 'Período',
      type: 'MULTI_SELECT',
      optionsKey: 'semesters',
    },
  ],
  {
    name: 'published_by',
    label: 'Responsável pela publicação',
    type: 'MULTI_SELECT',
    optionsKey: 'published_by',
  },

  {
    name: ['start_date', 'end_date'],
    label: 'Publicado em',
    type: 'DATE_RANGE',
    settings: {
      placeholder: ['De: DD/MM/AAAA', 'Até: DD/MM/AAAA'],
    },
  },
];
