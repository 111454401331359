import { hoursRegex } from 'utils/validation';
import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);

export const presentialModeValidation = Yup.object().shape({
  city: Yup.string().required('Campo obrigatório'),

  state: Yup.string().required('Campo obrigatório'),

  number: Yup.string().required('Campo obrigatório'),

  street: Yup.string().required('Campo obrigatório'),

  zipCode: Yup.string()
    .required('Campo obrigatório')
    .min(9, 'O CEP deve conter 8 dígitos.'),

  district: Yup.string().required('Campo obrigatório'),

  description: Yup.string().required('Campo obrigatório'),

  schedulingHour: Yup.string()
    .required('Campo obrigatório')
    .matches(hoursRegex, 'Formato de hora inválido'),

  schedulingDate: Yup.date()
    .typeError('Data inválida')
    .required('Campo obrigatório'),
});

export const onlineModeValidation = Yup.object().shape({
  accessLink: Yup.string()
    .url('Insira uma URL valida')
    .required('Campo obrigatório'),

  descriptionOnline: Yup.string().required('Campo obrigatório'),

  schedulingHourOnline: Yup.string()
    .required('Campo obrigatório')
    .matches(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Formato de hora inválido'),

  schedulingDateOnline: Yup.date()
    .typeError('Data inválida')
    .required('Campo obrigatório'),
});

export const cancellationInterviewValidation = Yup.object().shape({
  interviewerMessage: Yup.string().required('Campo obrigatório'),
});
