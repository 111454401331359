import styled from 'styled-components';

export const TextInput = styled.p`
  margin: 0;
  align-self: center;
  text-align: center;
  width: 100%;
`;

export const ImgUrl = styled.img`
  margin: 0;
  position: relative;
  align-self: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
`;

export const EditImgButton = styled.p`
  align-self: center;
  width: 100%;
  height: 35px;
  border-radius: 25px;
  border: none;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: white;
  font-family: roboto;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
`;

export const RemoveImageButton = styled.div`
  bottom: 40%;
  align-self: center;
  position: absolute;
  width: 32px;
  height: 32px;
  border-radius: ${(props) => props.radius};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    padding: 8px;
    width: 32px;
    height: 32px;
    color: #fff;
    clip-path: circle(50% at center);
    background-color: rgba(0, 0, 0, 0.6);;
  }
`;

export const ImageError = styled.p`
  color: red;
  display: flex;
  position: absolute;
  margin-top: 5px;
  left: 32%;
`;

export const DivInsertImg = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1103;
`;

export const FragmentModal = styled.div`
  width: 900px;
  height: 700px;
  background: #ffffff;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 0;
    padding-bottom: 30px;
  }

  @media (max-width: 380px) {
    padding-bottom: 0px;
  }
`;

export const Title = styled.p`
  font-family: roboto;
  font-size: 25px;
  font-weight: 700;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  padding: 1rem;
  margin: 0;
`;

export const FrangmentAvatar = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: #dcdcdc;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Buttons = styled.div`
  display: flex;
`;

export const FragmentZoom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LabelZomm = styled.p`
  color: #606062;
  font-family: Roboto;
  font-size: 19px;
  font-weight: 700;
  margin: 0;
`;

export const InputZomm = styled.input`
  -webkit-appearance: none;
  width: 215px;
  height: 7px;
  background: #d9d9d9;
  border-radius: 5px;
  background-image: linear-gradient(
    ${(props) => (props.universityColor ? props.universityColor : '#009291')},
    ${(props) => (props.universityColor ? props.universityColor : '#009291')}
  );
  background-size: ${(props) => `${(props.value - 1) * 100}%`} 100%;
  background-repeat: no-repeat;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 ##009291;
    transition: background 0.3s ease-in-out;
  }
  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;

export const Button = styled.p`
  border: none;
  width: 100%;
  height: 35px;
  min-width: 150px;
  background-color: ${(props) => props.background};
  color: ${(props) => props.color};
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  border-radius: 7px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.p`
  color: #606062;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const DivAlterImg = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;
