import React, { useEffect, Fragment } from 'react';
import JobOfferProvider from './context/jobOfferContext';
import JobController from './JobController/JobController';
export default function JobEngine({ handleIsLoadingState, metadata }) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const { role } = metadata;

  return (
    <JobOfferProvider metadata={metadata}>
      <JobController role={role} />
    </JobOfferProvider>
  );
}
