import styled from 'styled-components';

export const Fragment = styled.div`
  display: flex;
`;
//buttons "what is"
export const IconButton = styled.button`
  z-index: 10;
  margin: 2% 3% 0 0;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 600;
  border-radius: 5px;
  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  background-color: transparent;
  width: 116px;

  display: flex;
  align-items: center;

  top: 0;
  position: absolute;
  right: 0;
  font-size: 0.9rem;

  cursor: pointer;

  @media screen and (max-width: 430px) {
    bottom: ${(props) => (props.footerText ? '122px' : '19px')};
    top: unset;
    right: 10px;
    margin: 0% 6% 0 0;
  }
  @media screen and (max-width: 409px) {
    bottom: ${(props) => (props.footerText ? '144px' : '19px')};
  }
  @media screen and (max-width: 349px) {
    bottom: ${(props) => (props.footerText ? '162px' : '19px')};
  }
`;
