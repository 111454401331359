import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999999;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.25);

  color: #606062;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 55px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }

  @media (max-height: 810px) {
    align-items: ${(props) => (props.centerModal ? 'center' : 'flex-start')};
  }
`;

export const ButtonX = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 200px;

  background-color: ${(props) => props.colorBg || 'transparent'};
  border: none;

  position: absolute;
  right: 10px;
  top: 10px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;
