import React, { useContext, useState } from 'react';
import {
  BarPrincipal,
  SpanItems,
  IconButton,
  PopUp,
  WrapperButton,
} from '../style';
import { Grid } from '@material-ui/core';
import reactivateIcon from 'assets/Matriz/reactivate.png';
import { MatrixContext } from '../../../contexts/MatrixContext';
import ModalInformation from '../../informationModal';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

export default function Item({
  id,
  name,
  disciplines,
  selectedMatrices,
  setSelectedMatrices,
  openSnackbar,
  refreshList,
}) {
  const { reactivateMatrix, getMatrices, selectedCourse } = useContext(
    MatrixContext
  );
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const checkboxChecked = selectedMatrices.includes(id);

  const configModalConfirmReactivate = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja reativar esta matriz?`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
    modalHeight: 315,
  };

  const handleClickCheckBox = (e) => {
    if (e.target.checked) {
      setSelectedMatrices((prevs) => [...prevs, id]);
      return;
    }
    const removed = selectedMatrices.filter((ids) => ids !== id);
    return setSelectedMatrices(removed);
  };

  async function reactivateCurrentMatrix() {
    const response = await reactivateMatrix(id);
    if (response.status == 200) {
      openSnackbar('Matrix reativada com sucesso.');
      refreshList();
      getMatrices(selectedCourse);
    } else {
      openSnackbar('Erro ao reativar matriz.', true);
    }
    setConfirmModalOpen(false);
  }

  const handleClickReactivate = () => {
    setConfirmModalOpen(true);
  };

  return (
    <BarPrincipal>
      <ModalInformation
        modalOpenState={confirmModalOpen}
        config={configModalConfirmReactivate}
        buttonConfirmFunction={reactivateCurrentMatrix}
        buttonCancelFunction={() => setConfirmModalOpen(false)}
      />
      <Grid item md={1}>
        <input
          type="checkbox"
          checked={checkboxChecked}
          onClick={handleClickCheckBox}
        />
      </Grid>
      <Grid item md={5}>
        <SpanItems>{name || 'Sem nome'}</SpanItems>
      </Grid>
      <Grid item md={5}>
        <SpanItems>
          {disciplines ? disciplines.length : '0'} disciplinas relacionadas
        </SpanItems>
      </Grid>
      <Grid item md={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <WrapperButton>
          <PopUp>reativar</PopUp>
          <IconButton onClick={handleClickReactivate}>
            <img src={reactivateIcon} />
          </IconButton>
        </WrapperButton>
      </Grid>
    </BarPrincipal>
  );
}
