import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 428px;

  background-color: #ffffff;
  border-radius: 10px;
  padding: 29px;

  position: relative;
`;

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .border-image {
    width: 76px;
    height: 76px;
    background-color: #ebebeb;
    border: 2px solid ${props => props.universityColor ? props.universityColor : "#009291"};
    border-radius: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-image img {
    width: 100%;
    height: 100%;
    margin-bottom: -3px;
  }

  @media (max-width: 442px) {
    .border-image img {
      width: 74px;
      height: 74px;
    }
  }
`;

export const BubbleDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: 
    ${props => props.universityColor ? props.universityColor : "#009291"};
  margin-left: 1.5em;
  border-radius: 4px;
  width: ${(props) => props.widthBubble};
  height: ${(props) => props.heightBubble};
  position: relative;

  ::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;

    border-left: 4.5px solid transparent;
    border-right: 4.5px solid 
      ${props => props.universityColor ? props.universityColor : "#009291"};
    border-top: 4.5px solid 
      ${props => props.universityColor ? props.universityColor : "#009291"};
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 13px;
    transform: rotate(-135deg);
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
  }
`;

export const WrapperMessage = styled.div`
  width: 100%;

  font-size: ${(props) => props.fontMessage};
  font-weight: 400;
  color: #606062;
  line-height: ${(props) => props.lineHeigthMessage};
  text-align: ${(props) => props.positionMessage};

  padding: 22px 0;
  gap: 14px;
`;

export const ContainerButton = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AdvanceButton = styled.button`
  width: 100%;
  padding: 11px;
  max-width: ${(props) => props.widthButton};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: 
    ${props => props.universityColor ? props.universityColor : "#009291"};
  margin-top: ${(props) => props.marginTopButton || '36px'};
  cursor: pointer;

  border: none;
`;

export const ButtonReturn = styled.button`
  width: 100%;
  max-width: 98px;
  padding: 11px;
  border: 1px solid 
    ${props => props.universityColor ? props.universityColor : "#009291"};
  cursor: pointer;
  background-color: transparent;
  margin-top: ${(props) => props.marginTopButton || '36px'};
  margin-right: 38px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: ${props => props.universityColor ? props.universityColor : "#009291"};
`;
