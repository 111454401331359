export function getCheckInConfigErrors(studentName, EventName) {
  return {
    notFound: {
      modalWidth: 428,
      bubbleText: 'Oops!',
      ParagraphText: `O QR Code escaneado é invalido. Tente novamente com outro código.`,
      showButtonCancel: false,
      buttonConfirmText: 'OK, ENTENDI',
    },
    hasPresenceConfirmed: {
      modalWidth: 428,
      bubbleText: 'Oops!',
      ParagraphText: `O estudante ${studentName} já realizou o check-in desse evento anteriormente.`,
      buttonConfirmText: 'OK, ENTENDI',
    },
    DiferenceQrEvent: {
      modalWidth: 428,
      bubbleText: 'Oops!',
      ParagraphText: `Esse QR Code pertence ao evento ${EventName}. Portanto, não foi possível realizar o check-in.`,
      buttonConfirmText: 'OK, ENTENDI',
    },
  };
}
