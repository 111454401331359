import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons

// core components
import cardIconStyle from 'assets/jss/material-dashboard-pro-react/components/cardIconStyle';
function CardIcon({ ...props }) {
  const { classes, className, children, color, style, ...rest } = props;
  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[`${color}CardHeader`]]: color,
    [className]: className !== undefined,
  });
  return (
    <div className={cardIconClasses} {...rest}>
      {children}
    </div>
  );
}

CardIcon.propTypes = {
  classes: PropTypes.shape.isRequired,
  className: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'yellowIES',
    'redIES',
    'greenIES',
    'darkBlueIES',
    'blueIES',
    'lightBlueIES',
    'orangeIES',
    'dnaColor',
  ]),
};

export default withStyles(cardIconStyle)(CardIcon);
