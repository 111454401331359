import React, { useContext } from 'react';
import { func, bool, shape } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import BaseLayoutContext from 'contexts/base-layout';

const LiveSchema = Yup.object().shape({
  questionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
});

const LiveOneForm = (props) => {
  const { classes, answered, initialValues, applyLiveQuiz, metadata } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <GridContainer justify="center" className={classes.paddingReset}>
      <GridItem className={classes.paddingReset}>
        <h4>
          Com base no que foi falado no webinário, responda as perguntas abaixo:
        </h4>
      </GridItem>
      <GridItem className={classes.paddingReset}>
        <Formik
          enableReinitialize
          validationSchema={LiveSchema}
          validateOnChange={false}
          validade
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            await applyLiveQuiz(values);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <List className={classes.list}>
                {/* Question 1 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>1</strong> - Dentre as 10 Competências
                    Comportamentais apresentadas no Webinário, quais são as 4
                    mais evidentes no seu perfil? (Relembrando quais são elas:
                    Adaptabilidade, Comunicação, Criatividade, Execução,
                    Liderança, Negociação, Organização, Tomada de Decisão,
                    Trabalho em Equipe, Visão Analítica). Lembre-se de ter
                    clareza sobre elas porque representam seus pontos fortes!
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionOne && touched.questionOne,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionOne"
                    variant="filled"
                    id="questionOne"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionOne && touched.questionOne}
                    value={values.questionOne}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionOne &&
                      `Sua resposta tem ${values.questionOne.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionOne && touched.questionOne ? (
                    <FormHelperText>{errors.questionOne}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 2 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>2</strong> - E dentre as 10 Competências
                    Comportamentais apresentadas, qual delas é sua “fraqueza” e
                    precisará da sua atenção para não limitar seu
                    desenvolvimento pessoal e profissional? Pense também em como
                    as 4 competências que são seus pontos fortes podem amenizar
                    suas limitações.
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionTwo && touched.questionTwo,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionTwo"
                    variant="filled"
                    id="questionTwo"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionTwo && touched.questionTwo}
                    value={values.questionTwo}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionTwo &&
                      `Sua resposta tem ${values.questionTwo.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionTwo && touched.questionTwo ? (
                    <FormHelperText>{errors.questionTwo}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 3 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>3</strong> - Durante o Webinário, foram apresentadas
                    várias dicas de como desenvolver suas competências
                    comportamentais no seu dia a dia. Selecione 3 delas que você
                    pode adotar agora mesmo e fale sobre ela com pelo menos um
                    amigo.
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionThree && touched.questionThree,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionThree"
                    variant="filled"
                    id="questionThree"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionThree && touched.questionThree}
                    value={values.questionThree}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionThree &&
                      `Sua resposta tem ${values.questionThree.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionThree && touched.questionThree ? (
                    <FormHelperText>{errors.questionThree}</FormHelperText>
                  ) : null}
                </ListItem>
                {!answered && (
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={
                          isSubmitting || Object.keys(values).length !== 3
                        }
                        type="submit"
                        style={{
                          width: '250px',
                          borderRadius: '5px',
                          backgroundColor: universityColor,
                        }}
                        round
                      >
                        Enviar respostas
                      </Button>
                    </div>
                  </ListItem>
                )}
              </List>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

LiveOneForm.propTypes = {
  classes: shape({}).isRequired,
  answered: bool.isRequired,
  applyLiveQuiz: func.isRequired,
  initialValues: shape({}).isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(LiveOneForm);
