import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import FairAndEventsService from '../Services/index';

const RegisterModalContext = createContext();

export const RegisterModalProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [stepsData, setStepsData] = useState({});

  const formRefStepOne = useRef(null);
  const formRefStepTwo = useRef(null);
  const formRefStepThree = useRef(null);
  const formRefStepFour = useRef(null);

  const [selectOptions, setSelectOptions] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const getSelectOptions = async () => {
    const response = await FairAndEventsService.getSelectOptions();
    setSelectOptions(response);
  };

  const formRefs = {
    1: formRefStepOne,
    2: formRefStepTwo,
    3: formRefStepThree,
    4: formRefStepFour,
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      setCurrentStep(1);
      setStepsData({});
    }
  }, [modalOpen]);

  const isLastStep = currentStep === 4;
  const isFirstStep = currentStep === 1;

  const nextFormStep = () => {
    setCurrentStep((prevs) => prevs + 1);
  };

  const prevsFormStep = () => {
    setCurrentStep((prevs) => prevs - 1);
  };

  const updateObjectStepsData = useCallback(
    (data) => {
      setStepsData((prevs) => ({ ...prevs, ...data }));
    },
    [stepsData]
  );

  return (
    <RegisterModalContext.Provider
      value={{
        selectOptions,
        updateObjectStepsData,
        isLastStep,
        isFirstStep,
        currentStep,
        nextFormStep,
        prevsFormStep,
        stepsData,
        setSelectOptions,
        modalOpen,
        setModalOpen,
        setCurrentStep,
        formRefStepOne,
        formRefStepTwo,
        formRefStepThree,
        formRefStepFour,
        formRefs,
        setStepsData,
      }}
    >
      {children}
    </RegisterModalContext.Provider>
  );
};

export const useRegisterModalContext = () => {
  const context = useContext(RegisterModalContext);
  if (!context) {
    throw new Error(
      'useRegisterModalContext must be used within a RegisterModalProvider'
    );
  }
  return context;
};
