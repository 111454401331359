import React, { useEffect, useRef, useState } from 'react';
import {
  AddImageButton,
  Container,
  DeleteImageButton,
  DropContent,
  DropOverlayContent,
  DropRow,
  ErrorMessage,
  ImageWrapper,
} from './styled';
import { useField } from '@unform/core';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import ImageDefault from 'assets/img/imageDefault';
import DeleteIcon from 'assets/academicModeling/delete';
import { InfoIconWrapper, Row, Tooltip } from '../styles';
import { InfoCycleIcon } from 'assets/ComponentIcons/InfoCycleIcon';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';

import {
  Actions,
  Button,
  Buttons,
  DivAlterImg,
  FragmentModal,
  FragmentZoom,
  FrangmentAvatar,
  InputZoom,
  LabelZoom,
  ModalWrapper,
  Row as AvatarEditorRow,
  Text,
  TextInput,
  Title,
} from 'components/UploadedAvatarCustom/styles';

export function ImageInput({
  name,
  formRef,
  fileSizeMBLimit,
  onChange,
  defaultValue,
  ...rest
}) {
  const componentRef = useRef(null);
  const inputRef = useRef(null);
  let [dragOverlay, setDragOverlay] = useState(false);
  const [selectedFile, setSelectedFile] = useState(
    defaultValue ? defaultValue : null
  );
  const [multipleFileError, setMultipleFileError] = useState(false);
  const [openCutModal, setOpenCutModal] = useState(false);
  const [zoom, setZoom] = useState(1.0);
  const [editor, setEditor] = useState(null);
  const { fieldName, registerField, error } = useField(name);

  const allowedFileFormats = ['image/jpeg', 'image/png'];

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: componentRef.current,
      getValue: () => {
        return selectedFile;
      },
      setValue: (ref, value) => setSelectedFile(value),
      clearValue: () => setSelectedFile(null),
    });
  }, [fieldName, registerField, selectedFile]);

  useEffect(() => {
    onChange && onChange(selectedFile);
  }, [selectedFile]);

  const handleDrag = (e) => {
    preventBrowserDefaults(e);
  };

  const preventBrowserDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    preventBrowserDefaults(e);
    const mouseIsOver = componentRef.current.contains(e.relatedTarget);
    const fileAmount = e.dataTransfer.items.length;
    if (fileAmount > 0 && mouseIsOver) {
      setDragOverlay(true);
      if (fileAmount > 1) {
        setMultipleFileError(true);
      } else {
        setMultipleFileError(false);
      }
    }
  };

  const handleDragOut = (e) => {
    if (!componentRef.current.contains(e.relatedTarget)) {
      setMultipleFileError(false);
      setDragOverlay(false);
    }
  };
  const validateFiles = async ({
    files,
    allowedFileFormats,
    fileSizeMBLimit,
  }) => {
    const { length } = files;
    let result = {
      isValid: true,
      err: null,
    };

    async function validateFile(file) {
      const { size, type, name } = file;
      if (!allowedFileFormats.includes(type)) {
        return 'Formato de arquivo não permitido.';
      } else if (size / 1024 / 1024 > fileSizeMBLimit) {
        return `São permitidos arquivos de no máximo ${fileSizeMBLimit}MB.`;
      } else if (name.length > 100) {
        return 'O nome do arquivo não pode superar 100 caracteres.';
      }
    }

    if (length === 0) {
      return result;
    } else if (length > 1) {
      result.err = 'É permitido o carregamento de apenas 1 arquivo.';
      result.isValid = false;
    } else {
      const err = await validateFile(files[0]);
      if (err) {
        result.err = err;
        result.isValid = false;
        return result;
      }
    }
    return result;
  };

  const handleDrop = async (e, dropped = false) => {
    preventBrowserDefaults(e);
    setMultipleFileError(false);
    let newFiles;
    if (dropped) {
      newFiles = e.dataTransfer.files;
    } else {
      newFiles = e.target.files;
    }
    setDragOverlay(false);
    const newErrors = formRef.current.getErrors();
    delete newErrors[name];
    formRef.current.setErrors(newErrors);

    const { err } = await validateFiles({
      files: [...newFiles],
      allowedFileFormats,
      fileSizeMBLimit,
    });
    if (err) {
      const newErrors = formRef.current.getErrors();
      newErrors[name] = err;
      formRef.current.setErrors(newErrors);
      return false;
    } else {
      if (newFiles.length === 1) {
        setSelectedFile(newFiles[0]);
        setOpenCutModal(true);
      }
    }
  };

  const handleDeleteImage = () => {
    setSelectedFile(null);
    inputRef.current.value = null;
    formRef.current.setFieldError(name, null);
  };

  const handleDropCutModal = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      setOpenCutModal(true);
    }
  };

  const handleCancelEdit = () => {
    setSelectedFile(null);
    setOpenCutModal(false);
    setZoom(1.0);
  };

  const handleZoomChange = (value) => {
    setZoom(value);
  };

  const handleSave = () => {
    if (editor) {
      const { props } = editor;
      const canvas = editor.getImage();
      canvas.toBlob((blob) => {
        const file = new File([blob], props?.image?.name, {
          type: props?.image?.type,
        });
        setSelectedFile(file);
      }, props?.image?.type);
      setOpenCutModal(false);
      setZoom(1.0);
    }
  };

  return (
    <Container
      ref={componentRef}
      className={`${dragOverlay && 'drop-overlay'} ${
        error || (multipleFileError && 'invalid-dropped-file')
      }`}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDrag}
      onDrop={(e) => {
        handleDrop(e, true);
      }}
      {...rest}
    >
      <input
        ref={inputRef}
        type="file"
        name="pdfFile"
        id="upload"
        accept={'.jpg,.jpeg,.png'}
        onChange={handleDrop}
      />
      <DropContent>
        {dragOverlay && (
          <DropOverlayContent hasError={!!multipleFileError}>
            <p>
              {multipleFileError
                ? 'Apenas um arquivo é permitido'
                : 'Solte a imagem para adicionar'}
            </p>
          </DropOverlayContent>
        )}
        {!dragOverlay && (
          <DropRow>
            <div>
              <Row alignItems={'flex-end'} gap={'0.5rem'}>
                <b>Imagem de perfil</b>
                <Tooltip
                  label="Adicione uma imagem para que os estudantes identifiquem o mentor"
                  width={'220px'}
                  isTwoLines
                >
                  <InfoIconWrapper>
                    <InfoCycleIcon color={'#606062'} size={16} />
                  </InfoIconWrapper>
                </Tooltip>
              </Row>
              <p>
                <strong>Clique</strong> ou <strong>arraste</strong> para
                {selectedFile ? ' substituir a ' : ' adicionar a'} imagem em
                formato JPEG ou PNG de no máximo 5MB.
              </p>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <AddImageButton
                type={'button'}
                onClick={() => {
                  inputRef.current.click();
                }}
              >
                <MdOutlineAddPhotoAlternate color={'#009291'} size={18} />
                <span>
                  {!!selectedFile ? 'Substituir' : 'Adicionar'} imagem
                </span>
              </AddImageButton>
            </div>
            <ImageWrapper hasImage={!!selectedFile}>
              {!!selectedFile && (
                <DeleteImageButton type={'button'} onClick={handleDeleteImage}>
                  <DeleteIcon color={'#FFF'} size={18} />
                </DeleteImageButton>
              )}
              {selectedFile ? (
                <img
                  src={
                    typeof selectedFile === 'string'
                      ? selectedFile
                      : URL.createObjectURL(selectedFile)
                  }
                  alt={'Imagem de perfil'}
                  draggable={false}
                />
              ) : (
                <ImageDefault />
              )}
            </ImageWrapper>
          </DropRow>
        )}
      </DropContent>
      {selectedFile && openCutModal && (
        <ModalWrapper>
          <FragmentModal>
            <Title>Editar foto</Title>
            <FrangmentAvatar>
              <AvatarEditor
                ref={(editorRef) => setEditor(editorRef)}
                image={selectedFile}
                width={500}
                height={500}
                border={0}
                borderRadius={250}
                color={[0, 0, 0, 0.5]}
                scale={zoom}
                rotate={0}
              />
            </FrangmentAvatar>
            <Actions>
              <FragmentZoom>
                <AvatarEditorRow>
                  <LabelZoom>Ajuste de zoom:</LabelZoom>
                  <InputZoom
                    type="range"
                    min="1"
                    max="2"
                    step="0.01"
                    value={zoom}
                    onChange={(e) =>
                      handleZoomChange(parseFloat(e.target.value))
                    }
                  />
                </AvatarEditorRow>
                <Text>
                  Caso queira, ajuste o zoom para deixar sua foto mais próxima.
                </Text>
              </FragmentZoom>
              <Buttons>
                <Button
                  type={'button'}
                  background={'#fff'}
                  color={'#009291'}
                  onClick={handleCancelEdit}
                >
                  Cancelar
                </Button>

                <Button
                  type={'button'}
                  background={'#009291'}
                  color={'#fff'}
                  onClick={handleSave}
                >
                  Concluir
                </Button>
              </Buttons>
            </Actions>
            <DivAlterImg>
              <Dropzone
                onDrop={handleDropCutModal}
                accept="image/*"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      width: '100%',
                      height: '30px',
                      border: '2px dashed #ccc',
                      borderRadius: '5px',
                      padding: '1rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      display: 'flex',
                    }}
                  >
                    <input {...getInputProps()} />
                    <TextInput>
                      Clique aqui ou arraste uma nova imagem para substituir
                    </TextInput>
                  </div>
                )}
              </Dropzone>
            </DivAlterImg>
          </FragmentModal>
        </ModalWrapper>
      )}
    </Container>
  );
}
