function snakeToCamel(snakeStr) {
  return snakeStr.replace(/_([a-z])/g, function (match, letter) {
    return letter.toUpperCase();
  });
}

function camelToSnake(camelStr) {
  return camelStr.replace(/[A-Z]/g, function (match) {
    return '_' + match.toLowerCase();
  });
}

/**
 * this function converts the object's keys from camel to snake or snake to camel
 * just pass the object and the parameter, by default it converts to camel.
 *
 * @param {Object|Array} data - The object or value to convert.
 * @param {String|any} type - 'snaketocamel' or 'cameltosnake', deafult is tocamel
 * @returns {Object|Array|any} The object or value with the converted keys.
 */
function genericAdapter(data, type = 'snaketocamel') {
  const convert =
    type.toLowerCase() === 'cameltosnake' ? camelToSnake : snakeToCamel;

  if (typeof data === 'object') {
    if (Array.isArray(data)) {
      return data.map((item) => genericAdapter(item, type));
    } else {
      const newObject = {};
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const newKey = convert(key);
          const newValue = genericAdapter(data[key], type);
          newObject[newKey] = data[key] !== null ? newValue : null;
        }
      }
      return newObject;
    }
  } else {
    return data;
  }
}

export { genericAdapter };
