import {
  ActionButton,
  Actions,
  ContainerActions,
  DenyButton,
} from '../../styled';
import { BiLike, BiDislike } from 'react-icons/bi';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from 'contexts/authentication';
import { FeedbackModal } from 'components/ListCandidates/UIComponents/Modal/FeedbackModal';
import { BaseAnimation } from '../SkeletonButtons/styled';

export function InterstBoxComponent({
  studentData,
  returnJobApplication,
  options,
  fetchStudentData,
  university,
  company,
  partnerJob,
  selectedTab,
  jobId,
  setCandidates,
}) {
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  let environmentType = company ? 'company' : 'university';

  useEffect(() => {
    setStudent(studentData);
  }, []);

  const handleApplicationReturn = async (data, partnerJob) => {
    setLoading(true);
    const newStudent = await returnJobApplication(
      data || false,
      partnerJob,
      university,
      company
    );
    setStudent(newStudent);
    setLoading(false);
    return newStudent;
  };

  const controlLikeButton = async () => {
    const newStudentData = await handleApplicationReturn(false);
    await fetchStudentData(newStudentData);
  };

  const returnResumeEvaluationModal = (student) => {
    const returnReactionIcon = {
      true: <BiLike size={18} />,
      false: <BiDislike size={18} />,
    };

    const returnReactionLabel = {
      true: 'Currículo considerado interessante',
      false: 'Currículo considerado não interessante',
    };

    if (loading) {
      return <BaseAnimation />;
    }

    if (student?.is_answered && !loading) {
      return (
        <ContainerActions>
          <div className="buttons">
            <ActionButton
              disabled={student?.is_answered}
              liked={student?.is_interesting}
            >
              {returnReactionIcon[student?.is_interesting]}
              {returnReactionLabel[student?.is_interesting]}
            </ActionButton>
          </div>
          {!student?.is_interesting && returnFeedbackMessage(student)}
        </ContainerActions>
      );
    }

    if (!student?.is_answered) {
      return (
        <ContainerActions>
          <div className="buttons">
            <ActionButton liked={true} onClick={controlLikeButton}>
              <BiLike size={18} />
              Sim
            </ActionButton>
            <ActionButton
              liked={false}
              onClick={() => setOpenModalFeedback(true)}
            >
              <BiDislike size={18} />
              Não
            </ActionButton>
          </div>
        </ContainerActions>
      );
    }
  };

  const returnFeedbackMessage = ({ reasons, reason_description }) => {
    const reasonMessage = `
      Currículo marcado como não interessante 
      por: ${reasons?.join(', ')}.
    `;

    if (!reasons?.length && reason_description !== '') {
      return (
        <div className="feedback">
          Currículo marcado como não interessante por: {reason_description}.
        </div>
      );
    } else {
      return (
        <div className="feedback">
          {reasonMessage}
          {reason_description && (
            <>
              <br />
              Outras razões: {reason_description}.
            </>
          )}
        </div>
      );
    }
  };

  return (
    <Actions>
      <FeedbackModal
        controlModal={openModalFeedback}
        setControlModal={setOpenModalFeedback}
        options={options}
        handleApplicationReturn={handleApplicationReturn}
      />
      <div className="phrase">
        Você achou o currículo deste candidato interessante?
      </div>
      {returnResumeEvaluationModal(student)}
    </Actions>
  );
}
