import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';
import { authenticatedAPI } from 'services/api';

const getProfessions = async () => {
  const response = await axios
    .get(`${URLS.API_URL}/api/internal/students/me/professions`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateFavoriteProfessions = async (favorites) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/professions/favorite`, favorites)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getProfessions, updateFavoriteProfessions };
