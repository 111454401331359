import React, { useEffect, useRef, Fragment, useState } from 'react';
import { useField } from '@unform/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR'; // the locale you want

import '../styles/reactDatePicker.css';

import { UnInputLabel } from '../styles/unformCompany';
import { ContainerLabelInput, ErrorMessage } from '../styles/styleForm';

registerLocale('ptBR', ptBR);

export default function DatePickerCustom({
  placeholder,
  label,
  name,
  ...rest
}) {
  const datepickerRef = useRef(null);
  const [startDate, setstartDate] = useState(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setstartDate(new Date(defaultValue));
    }
  }, [defaultValue]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <DatePicker
        ref={datepickerRef}
        locale="ptBR"
        isClearable
        dateFormat="dd/MM/yyyy"
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1).toUpperCase()}
        selected={startDate}
        placeholderText={placeholder}
        minDate={new Date()}
        onChange={(date) => setstartDate(date)}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ContainerLabelInput>
  );
}
