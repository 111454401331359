import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  0% {
    background-position: 0% 0%
  }
  100% {
    background-position: -135% 0%
  }
`;

export const SkeletonAnimation = styled.div`
  background-image: linear-gradient(
    -90deg,
    #e5e5e5 0%,
    #f0f0f0 50%,
    #e5e5e5 100%
  );
  background-size: 400% 400%;
  border-radius: 3px;
  animation: ${shimmer} 1.9s ease-in-out infinite;
`;

export const ContainerSkeleton = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border-bottom: 3px solid #e7edf1;
  border-left: 3px solid #e7edf1;

  .legend-area {
    height: 31px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 31px;
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 31px;
`;

export const ContainerLegend = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: 15px;
`;

export const SkeletonTitle = styled(SkeletonAnimation)`
  width: 100%;
  max-width: 200px;
  height: 28px;
  margin-bottom: ${({ subtitle }) => (subtitle ? '10px' : '20px')};
  margin-left: ${({ mgLeft }) => mgLeft};
`;

export const SkeletonLegend = styled(SkeletonAnimation)`
  width: 100%;
  max-width: ${({ widthLegend }) => widthLegend + 'px'};
  height: ${({ heightLegend }) =>
    heightLegend ? heightLegend + 'px' : '19px'};

  margin-bottom: ${({ marginBottom }) => marginBottom + 'px'};
  margin-left: ${({ mgLeft }) => mgLeft};
`;

export const SkeletonDescription = styled(SkeletonAnimation)`
  width: 100%;
  max-width: ${({ widthLegend }) => widthLegend ? widthLegend + 'px' : '400px'};
  height: 25px;
  margin-bottom: 20px;
  margin-left: ${({ mgLeft }) => mgLeft};
`;

export const ContentBars = styled(Content)`
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
`;

export const ContainerBars = styled(ContainerSkeleton)`
  flex-direction: ${({ vertical }) => (vertical ? 'row' : 'column')};

  justify-content: space-around;
  align-items: ${({ vertical }) => (vertical ? 'flex-end' : 'flex-start')};

  height: ${({ vertical }) => (vertical ? '250px' : '158px')};
`;

export const SkeletonBars = styled(SkeletonAnimation)`
  width: 100%;
  max-width: ${({ widthBars, vertical }) =>
    vertical ? '77px' : widthBars + 'px'};
  height: ${({ vertical, heightBars }) =>
    vertical ? heightBars + 'px' : '30px'};

  justify-content: flex-start;
  border-radius: 0px;
`;

export const ContainerLegendBars = styled(ContainerLegend)`
  max-width: ${({ vertical }) => !vertical && '180px'};
  width: ${({ vertical }) => vertical && '100%'};
  height: 100%;
  gap: 33px;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'row' : 'column')};
  justify-content: ${({ vertical }) => vertical && 'space-around'};
  padding: ${({ vertical }) => vertical && '10px 21px'};
  margin-left: ${({ vertical }) => vertical && '4px'};
  margin-bottom: 0;
`;

export const ContainerCircularGraph = styled.div`
  flex-direction: row;
  width: 60%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerCircularProgress = styled(ContainerCircularGraph)`
  justify-content: space-around;
`;

export const SkeletonPie = styled(SkeletonAnimation)`
  width: 100%;
  max-width: 240px;
  height: 100%;
  max-height: 240px;

  border-radius: 200px;
`;

export const SkeletonCircularProgress = styled(SkeletonAnimation)`
  width: 100%;
  max-width: 90px;
  height: 100%;
  max-height: 90px;

  border-radius: 200px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  .white-circle {
    position: absolute;
    width: 100%;
    max-width: 60px;
    height: 100%;
    max-height: 60px;
    background-color: #fff;
    border-radius: 200px;
  }
`;

export const ItemSkeleton = styled(SkeletonAnimation)`
  border-radius: 8px;
  width: 100%;
  height: 140px;
  padding: 20px;

  margin: 0 auto;
`;

export const ContainerCompany = styled.div`
  display: flex;
  width: 100%;
  padding: 50px;
  border-bottom: 1px solid #e5e5e5;

  .left-side {
    width: 50%;   
  }

  .right-side {
    width: 50%;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

export const ControlSideTitle = styled.div`
  display: flex;
  justify-content: ${({ side }) =>
    side === 'end' ? 'flex-end' : 'flex-start'};
  margin-bottom: 50px;
  width: 100%;
`;
