export default function VectorArrowBackSvg({ color }) {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.000127792 8.25841C0.20434 8.56259 0.370396 8.90602 0.618563 9.16462C2.66586 11.2983 4.72782 13.4171 6.78732 15.5385C7.33525 16.1028 8.05197 16.1525 8.56814 15.6714C9.11881 15.1583 9.13041 14.3275 8.58066 13.7568C7.2397 12.365 5.89295 10.9796 4.54894 9.59098C4.4711 9.51058 4.39754 9.42575 4.27391 9.29028C4.45431 9.29028 4.57397 9.29028 4.69332 9.29028C13.2775 9.29028 13.4805 9.29028 22.0646 9.28996C22.7637 9.28996 23.2337 8.97059 23.4105 8.379C23.6471 7.58674 23.111 6.77264 22.3119 6.71376C22.1983 6.70553 22.0839 6.70996 21.97 6.70996C13.4273 6.70996 13.2656 6.70996 4.72293 6.70996C4.5987 6.70996 4.47476 6.70996 4.27269 6.70996C4.39174 6.57987 4.46836 6.49188 4.54955 6.408C5.89356 5.01972 7.23696 3.63049 8.58371 2.24443C8.94909 1.8684 9.07272 1.42051 8.93078 0.916921C8.79525 0.436119 8.46589 0.132889 7.99153 0.029385C7.52694 -0.071903 7.13989 0.0945897 6.80839 0.437386C5.30321 1.99311 3.79467 3.54566 2.28766 5.09948C1.72905 5.67556 1.15702 6.23898 0.619173 6.83531C0.377417 7.10309 0.204647 7.43734 0.000434875 7.74152C0.0001297 7.91434 0.000127792 8.08622 0.000127792 8.25841Z"
        fill={color}
      />
    </svg>
  );
}
