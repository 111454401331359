import moment from 'moment';

const formatDate = (date) =>
  moment(date).locale('pt-br').format('dddd, DD [de] MMMM');

export const formatDateTime = (
  date,
  startHour,
  endHour,
  previousDate,
  previousStartHour,
  previousEndHour
) => {
  const fullFormattedDate = formatDate(date);
  const shortFormattedDate = moment(date)
    .locale('pt-br')
    .format('dddd, DD [de] MMMM')
    .replace(/-feira/g, '');
  const simpleFormattedDate = moment(date).format('DD/MM/YYYY');
  const formattedStartHour = moment(startHour, 'HH:mm:ss').format('HH[h]');
  const formattedEndHour = moment(endHour, 'HH:mm:ss').format('HH[h]');
  const formattedPreviousDate = moment(previousDate).format('DD/MM/YYYY');
  const formattedPreviousStartHour = moment(
    previousStartHour,
    'HH:mm:ss'
  ).format('HH[h]');
  const formattedPreviousEndHour = moment(previousEndHour, 'HH:mm:ss').format(
    'HH[h]'
  );

  return {
    fullFormattedDate,
    shortFormattedDate,
    simpleFormattedDate,
    formattedStartHour,
    formattedEndHour,
    formattedPreviousDate,
    formattedPreviousStartHour,
    formattedPreviousEndHour,
  };
};

export const getFullAddress = (addressDetails) => {
  return addressDetails.filter((detail) => detail).join(', ');
};
