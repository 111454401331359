export default function SalaryIcon({ color }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="4" fill={color} />
      <path
        d="M33.9948 19.5353C33.959 19.4906 33.9802 19.4356 33.9743 19.3886V19.2882C33.9747 19.2831 33.9747 19.2779 33.9743 19.2728C33.9711 19.2484 33.9711 19.2238 33.9743 19.1995V19.1709C33.5212 11.9387 27.6299 6.19211 20.3617 6.0044C20.242 6.00147 20.1204 6 19.9968 6C12.4673 6 6.32647 11.9761 6.01195 19.4649C6.01195 19.4942 6.01195 19.5243 6.0083 19.5536C6.00392 19.6651 6.001 19.7736 6.00027 19.888C5.99991 19.8904 5.99991 19.8929 6.00027 19.8953C6.00027 19.9386 6.00027 19.9811 6.00027 20.0244V20.0852C6.0052 23.6478 7.35501 27.0759 9.77724 29.6774C12.1995 32.279 15.5137 33.8603 19.051 34.1023L19.1145 34.1067H19.4407C19.4692 34.1028 19.4982 34.1099 19.5217 34.1265H19.5261L20.0004 34.1345C20.1748 34.1345 20.3471 34.1345 20.52 34.125L20.5135 34.1345C20.5168 34.1308 20.5207 34.1275 20.5251 34.125C20.5689 34.092 20.6229 34.1125 20.6711 34.1067H20.8046C20.8189 34.1056 20.8333 34.1056 20.8477 34.1067H20.8834C27.8948 33.6667 33.508 28.0448 33.9714 21.0069V20.9725C33.9641 20.9373 33.9714 20.8992 33.9714 20.8632V20.7628C33.9718 20.7584 33.9718 20.754 33.9714 20.7496C33.9655 20.7147 33.972 20.6789 33.9897 20.6484V20.6425C33.994 20.5259 33.9977 20.4093 33.9991 20.292V19.8359C34.0013 19.7369 33.9991 19.6357 33.9948 19.5353ZM20.0318 32.3791C13.2977 32.3864 7.79982 26.8694 7.79325 20.097C7.78669 13.3246 13.2736 7.80749 20.0158 7.79942C26.7579 7.79136 32.2477 13.3055 32.255 20.0808C32.2623 26.8562 26.7739 32.3747 20.0318 32.3791Z"
        fill="white"
      />
      <path
        d="M21.141 26.9413C20.9564 26.9955 20.8754 27.0637 20.8929 27.2676C20.9075 27.5142 20.9075 27.7615 20.8929 28.0082C20.8874 28.239 20.7918 28.4584 20.6267 28.619C20.4617 28.7796 20.2404 28.8686 20.0107 28.8668C19.7855 28.863 19.5706 28.7721 19.4104 28.6132C19.2501 28.4543 19.157 28.2394 19.1503 28.0133C19.1415 27.7574 19.1371 27.5 19.1503 27.2456C19.1605 27.0718 19.1131 26.9955 18.9357 26.9464C18.1347 26.7171 17.4271 26.2376 16.9155 25.5772C16.4038 24.9169 16.1146 24.11 16.0897 23.2735C16.0788 22.7096 16.4196 22.3049 16.9231 22.2851C17.4405 22.2638 17.8141 22.6517 17.8367 23.2258C17.8461 23.5162 17.9127 23.8019 18.0326 24.0663C18.1524 24.3307 18.3232 24.5687 18.5351 24.7664C18.747 24.9641 18.9958 25.1177 19.2671 25.2182C19.5384 25.3188 19.8268 25.3644 20.1157 25.3523C21.2972 25.3098 22.232 24.2927 22.2006 23.0843C22.1707 21.9272 21.225 21.0004 20.0479 20.9652C18.3264 20.9131 17.0793 20.1051 16.394 18.5212C15.4636 16.3706 16.6932 13.8525 18.9423 13.2271C19.0977 13.1838 19.1612 13.1193 19.1496 12.9506C19.1335 12.6955 19.1401 12.4373 19.1496 12.1822C19.1501 11.9509 19.2418 11.7293 19.4045 11.5658C19.5673 11.4023 19.7878 11.3102 20.018 11.3096C20.2478 11.3096 20.4685 11.4004 20.6323 11.5624C20.7961 11.7244 20.89 11.9446 20.8936 12.1756C20.898 12.312 20.8936 12.4498 20.8936 12.5869H20.9024C20.9024 12.7695 20.8433 12.9829 20.9185 13.1259C20.9936 13.2689 21.2366 13.2542 21.403 13.318C22.8983 13.8899 23.9206 15.3293 23.9491 16.9154C23.9593 17.4712 23.6061 17.8789 23.1026 17.8921C22.5991 17.9053 22.2269 17.5255 22.2028 16.9645C22.1954 16.6716 22.1298 16.3832 22.0099 16.1161C21.89 15.8491 21.7182 15.6087 21.5046 15.4093C21.291 15.2099 21.04 15.0553 20.7661 14.9547C20.4923 14.8541 20.2012 14.8094 19.91 14.8234C18.7212 14.871 17.7893 15.9093 17.8397 17.131C17.8856 18.2602 18.8285 19.1841 19.9778 19.2068C21.3884 19.2347 22.5108 19.8118 23.296 20.9894C24.7635 23.1855 23.6652 26.1999 21.141 26.9413Z"
        fill="white"
      />
    </svg>
  );
}
