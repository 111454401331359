export const studentAdapter = (student) => {
  return {
    id: student.id,
    externalId: student.external_id,
    avatar: student.avatar,
    name: student.name,
    professionalStatus: student.professional_status,
    workingInFormationArea: student.working_in_formation_area,
    courses: student.courses
      ? student.courses.map((course) => {
          return {
            name: course.course__name,
            semester: course.semester,
          };
        })
      : [],
    mobilePhone: student.mobile_phone,
    email: student.email,
    hasCoverLetter: student.has_cover_letter,
    hasCurriculum: student.has_curriculum,
  };
};
