import styled from "styled-components";


export const ModalBackground = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(4px);
    border-radius: 12px !important;
    background-color: rgba(0, 0, 0, 0.25);
`
export const DivModal = styled.div`
    width: 410px;
    height: auto;
    background-color: #fff;
    border-radius: 12px;
`
export const HeaderModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 24px 0 24px;
`
export const Title = styled.h4`
    color: #009291;
    font-size: 18px;
    font-weight: bold;
`
export const Divider = styled.div`
    width: 118px;
    height: 1px;
    margin-top: -5px;
    background-color: #D4D0D0;
`
export const DividerTitle = styled.div`
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: start;
`
export const DivCourses = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;

    width: 100%;
    height: auto;

    padding: 0 44px 40px 24px;
`
export const TitleCourses = styled.h3`
    font-weight: bold;
    font-size: 14px;
    color: #606062;
`
export const Courses = styled.p`
    font-size: 13px;
    font-weight: 400;
    
    color: #606062;
`