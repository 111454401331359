import Arrow from 'views/StaffPages/FairAndEvents/UiComponents/Arrow';
import { Box, Container, ContainerArrow, Content } from './styled';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { IoIosArrowBack } from 'react-icons/io';
import { BackButton } from 'components/BackButton/BackButton';

export default function CertificateModal({ open, certificate, onClose }) {
  const [url, setUrl] = useState(null);

  const getCertificate = async () => {
    const blob = new Blob([certificate], { type: 'application/pdf' });
    const urlBlob = window.URL.createObjectURL(blob);
    setUrl(urlBlob);
  };

  useEffect(() => {
    if (open) {
      getCertificate();
    }
  }, [open]);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  if (!open) {
    return null;
  }
  return (
    <Container>
      <Box>
        <Content>
          <div>
            <BackButton color={'#009291'} onClick={onClose} marginBotton={16}>
              <IoIosArrowBack />
              <p>Voltar</p>
            </BackButton>
            {url && (
              <Document file={url}>
                <Page pageNumber={1} />
              </Document>
            )}
          </div>
        </Content>
      </Box>
    </Container>
  );
}
