import React, { Fragment, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Content,
  Container,
  Header,
  BubbleWal,
  Title,
  Wrapper,
  ValidatedButton,
  ContainerButtons,
  EditButtons,
  ButtonX,
} from './styled';

import { Form } from '@unform/web';

import IconWal from 'assets/img/iconWal.svg';
import SearchableSelectChallenge from '../../Inputs/Select/SearchableSelect';
import InputWorkloadChallenge from '../../Inputs/InputWorkload';

import {
  getOptionsComplementaryActivity,
  validationComplementaryActivity,
  deleteValidation,
  editValidation,
} from '../../../services';

import { ComplementaryActivityAdapter } from './utils/ComplementaryActivityAdapter';

import { RiCloseFill } from 'react-icons/ri';
import { ComplementaryActivityValidation } from './utils/ComplementaryActivityValidation';

export default function ModalComplementaryActivity(props) {
  const {
    openModal,
    setOpenModal,
    item,
    getAllChallenges,
    setChallenge,
    ScrollToTopDiv,
  } = props;

  const [error, setError] = useState(false);
  const [value, setValue] = useState(false);

  const modalRef = useRef(null);
  const complementaryRef = useRef();

  function handleCloseModal(e) {
    if (modalRef.current === e.target) {
      setOpenModal(false);
      if (error) {
        complementaryRef.current.reset();
        setValue(false);
        setError(false);
      }
    }
  }

  const funcButtonX = () => {
    setOpenModal(false);

    if (error) {
      complementaryRef.current.reset();
      setValue(false);
      setError(false);
    }
  };

  const getValueSelect = () => {
    setValue(true);
  };

  const handleValidationActivity = async () => {
    const data = complementaryRef?.current?.getData();
    const dataAdp = ComplementaryActivityAdapter(data);

    try {
      complementaryRef.current.setErrors({});
      await ComplementaryActivityValidation.validate(dataAdp, {
        abortEarly: false,
      });

      await validationComplementaryActivity(item, dataAdp);
      setOpenModal(false);
      setValue(false);
      setChallenge(null);
      getAllChallenges(`limit=${24}`);
      ScrollToTopDiv();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setError(true);
      }
    }
  };

  const handleDeleteActivity = async () => {
    if (item.challenge_validation.activity_type) {
      await deleteValidation(item.id, item.challenge_validation.id);
      setOpenModal(false);
      setValue(false);
      setChallenge(null);
      getAllChallenges(`limit=${24}`);
      ScrollToTopDiv();
    }
  };

  const handleEditActivity = async () => {
    const data = complementaryRef?.current?.getData();

    const dataAdp = ComplementaryActivityAdapter(data);

    try {
      complementaryRef.current.setErrors({});
      await ComplementaryActivityValidation.validate(dataAdp, {
        abortEarly: false,
      });

      if (item && item.challenge_validation.activity_type) {
        await editValidation(item.id, item.challenge_validation.id, dataAdp);
        setOpenModal(false);
        setChallenge(null);
        getAllChallenges(`limit=${24}`);
        ScrollToTopDiv();
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (item && item.challenge_validation.activity_type) {
      complementaryRef.current.setData({
        activityType: item.challenge_validation.activity_type,
        workload: item.challenge_validation.workload,
      });
    } else {
      complementaryRef.current.reset();
      setValue(false);
      setError(false);
    }
  }, [item]);

  return (
    <Container isOpen={openModal}>
      <Box ref={modalRef} onClick={handleCloseModal}>
        <Content>
          <Header>
            <BubbleWal>
              <img src={IconWal} />
            </BubbleWal>
            <Title>Validar atividade</Title>
            <div className="buttonClose">
              <ButtonX onClick={funcButtonX}>
                <RiCloseFill size={25} />
              </ButtonX>
            </div>
          </Header>
          <Wrapper>
            Escolha se esse desafio será ofertado como uma atividade
            complementar.
          </Wrapper>

          <Form ref={complementaryRef} onSubmit={handleValidationActivity}>
            <div className="styled-form">
              <SearchableSelectChallenge
                label="Tipo de Atividade"
                name="activityType"
                options={[
                  {
                    value: 'complementary_activity',
                    display_name: 'Atividade Complementar',
                  },
                ]}
                onChange={getValueSelect}
              />
            </div>
            {value ? (
              <Fragment>
                <Wrapper>
                  Agora indique a carga horária válida para essa atividade:
                </Wrapper>
                <div className="styled-form">
                  <InputWorkloadChallenge
                    placeholder="HH:MM"
                    label="Carga horária"
                    name="workload"
                    error={error}
                  />
                </div>

                {item && item.challenge_validation.activity_type ? (
                  <ContainerButtons>
                    <EditButtons
                      backgroundColor="#fff"
                      color="#009291"
                      type="button"
                      onClick={handleDeleteActivity}
                    >
                      EXCLUIR VALIDAÇÃO
                    </EditButtons>
                    <EditButtons
                      backgroundColor="#009291"
                      color="#fff"
                      type="button"
                      onClick={handleEditActivity}
                    >
                      SALVAR
                    </EditButtons>
                  </ContainerButtons>
                ) : (
                  <ValidatedButton type="submit">VALIDAR</ValidatedButton>
                )}
              </Fragment>
            ) : null}
          </Form>
        </Content>
      </Box>
    </Container>
  );
}
