import React, { useRef, useEffect, Fragment } from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';

function SearchableCourses({
  label,
  name,
  options,
  placeholder,
  cacheOptions,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (!ref.state.value) {
          return '';
        } else if (ref.state.value.value) {
          return ref.state.value.value;
        }
        return ref.state.value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Fragment>
      <Select
        getOptionLabel={options.label}
        placeholder={placeholder ? placeholder : 'Selecione'}
        defaultValue={
          defaultValue && Array.isArray(defaultValue)
            ? defaultValue.map(
              (item) =>
                cacheOptions &&
                cacheOptions.find(
                  (option) =>
                    option.value === item || option.value === item.value
                )
            )
            : cacheOptions &&
            cacheOptions.find(
              (option) =>
                String(option.value) === String(defaultValue) ||
                (defaultValue &&
                  String(option.value) === String(defaultValue.value))
            )
        }
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        value={options.value}
        {...rest}
      />
      <p style={{ color: '#f00', marginTop: '10px' }}>{error}</p>
    </Fragment>
  );
}

export default SearchableCourses;
