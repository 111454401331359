import React, { useContext, useState } from 'react';
import {
  ContainerPagination,
  ButtonPagination,
  LevelOneAndTwo,
  LevelThree,
  HeaderPagination,
  IconImg,
  ContainerReward,
} from './styled';

import iconNext from 'assets/img/iconsCurriculum/iconNext.svg';
import iconPrevs from 'assets/img/iconsCurriculum/iconPrevs.svg';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

import ButtonViewReward from '../InformationsRewards/ButtonViewReward';
import ModalRewards from '../InformationsRewards/ModalRewards';

const Pagination = ({
  activeStep,
  handleStepChange,
  gamificationLevel,
  props,
}) => {
  const { universityColor, modalReward, setModalRewards } = useContext(
    ProfileContext
  );
  const levelThree = gamificationLevel === 3;

  const handlePrevPage = () => {
    if (activeStep > 1) {
      handleStepChange(activeStep - 1);
    }
  };

  const handleNextPage = () => {
    if (activeStep < 3) {
      handleStepChange(activeStep + 1);
    }
  };

  const returnLevelobj = {
    1: '1',
    2: '2',
    3: '3',
  };

  const handleOpenModal = () => {
    setModalRewards(true);
  };

  const handleCloseModal = () => {
    setModalRewards(false);
  };

  return (
    <>
      {modalReward && (
        <ModalRewards
          closeModalReward={handleCloseModal}
          gamificationLevel={gamificationLevel}
          props={props}
        />
      )}

      <HeaderPagination>
        <ContainerReward>
          {activeStep === 3 ? (
            <LevelThree levelThree={levelThree}>NÍVEL 3</LevelThree>
          ) : (
            <LevelOneAndTwo universityColor={universityColor}>
              NÍVEL {returnLevelobj[activeStep]}
            </LevelOneAndTwo>
          )}
          <ButtonViewReward onClick={handleOpenModal} />
        </ContainerReward>

        <ContainerPagination>
          <ButtonPagination
            universityColor={universityColor}
            onClick={handlePrevPage}
            disabled={activeStep === 1}
          >
            <IconImg src={iconPrevs} />
          </ButtonPagination>

          <ButtonPagination
            hasMarginRight
            universityColor={universityColor}
            onClick={handleNextPage}
            disabled={activeStep === 3}
          >
            <IconImg src={iconNext} />
          </ButtonPagination>
        </ContainerPagination>
      </HeaderPagination>
    </>
  );
};

export default Pagination;
