import styled from 'styled-components';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const Salarycontainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 4px 16px;

  svg {
    color: ${props => props.universityColor ? props.universityColor : "#009291"};
  }

  p {
    margin: 0 5px;
  }
`;

export const MediumSalarycontainer = styled.div`
  display: flex;
  align-items: center;

  p {
    color: #777777;
  }

  @media screen and (max-width: 430px) {
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  align-items: center;
  width: 700px;
  margin: 0 0 0 38px;
  justify-content: space-between;

  div {
    margin-left: 10px;
  }

  img {
    margin-top: 24px;
    height: 50px;
    width: 50px;
  }

  h3 {
    font-size: 14px;
  }

  p {
    font-size: 12px;
    margin-top: -10px;
  }
  @media screen and (max-width: 1093px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const LinkUrlContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    color: ${props => props.universityColor ? props.universityColor : "#009291"};
    font-weight: 500;
    cursor: pointer;
  }

  @media screen and (max-width: 599px) {
    width: 250px;
    justify-content: center;
    align-items: center;
    margin: 4px 0 0 36px;
    div {
      margin-left: 6px;
    }
    img {
      width: 20px;
      height: 20px;
    }

    h3 {
      font-size: 14px;
      margin: 0;
    }
    a {
      font-size: 12px;
    }
    p {
      margin: -6px 0 0 0;
    }
  }
`;

export const RequirementsContainer = styled(Salarycontainer)`
  svg {
    color: #d8c51f;
  }
`;

export const Buttom = styled.div`
  cursor: pointer;
  width: 75%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  gap: 8px;
  background-color: ${(props) => (props.marked ? '#FFF8E5' : '#f6f6f6')};
  margin-left: 12%;
  margin-top: 20px;
  strong {
    font-weight: 400;
    font-size: 18px;
    color: ${(props) => (props.marked ? '#FFc328' : '#3C4858')};
  }

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2f918f;
    color: #fff;
    border-radius: 20px;
    font-weight: 500;
    font-size: 16px;
    padding: 8px 16px;
    margin: 0px;
    transition: opacity 0.2s;

    span {
      color: '#ffcc00';
    }
    &:hover {
      opacity: 0.9;
    }
  }
`;

export const ButtonMobile = styled(Buttom)``;

export const InstructionText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  margin-top: 25px;
`;

export const CustomButton = styled.button`
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
  margin-top: 1.5rem;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: none;
  font-size: 17px;
`;

export const CustomDiv = styled.div`
  display: ${({ active }) => (active ? 'none' : 'block')};
`;

export const CardContainer = styled.div`
  & > div > div:last-child {
    padding-bottom: 35px;
  }

  & > div > div > div > div {
    & > div:first-child > span {
      margin-right: 1rem;
      font-size: 45px;
    }
    & > div:last-child > span {
      margin-right: 1rem;
      font-size: 36px;
    }
  }

  h3 {
    margin: 0;
    padding: 35px 30px 0 35px;

    font-size: 1.5rem;
  }

  p {
    padding-left: 30px;

    font-size: 1rem;
  }
`;
