import React from 'react';
import { names } from '../constantNamesReports';
import { formatNumber } from 'utils/formatNumber';

import {
  Title,
  SubTitle,
  ContainerInfoStudents,
  Divider,
  ContainerTitleInfos,
  ContainerJob,
  ContainerSubTitles,
  SubTitleInfos,
} from '../styled';

function GenericContainerReportsInfoJobs(props) {
  const {
    title,
    total,
    noSpecificCourses,
    specificCourses,
    byCompany,
    byUniversity,
    inProgressOrRegistered,
    finished,
    hasContractingByPlatform,
    expired,
    hasNotContractingByPlatform,
    noContracting,
    applications,
    average,
    filterStateCourses,
  } = props;

  return (
    <div>
      {inProgressOrRegistered && (
        <ContainerTitleInfos>
          <ContainerInfoStudents>
            <Title>{names[title]}</Title>

            <Title>{formatNumber(total)}</Title>
          </ContainerInfoStudents>

          <ContainerSubTitles ContainerJob>
            <ContainerInfoStudents>
              <SubTitle subTitle isWidth>
                Da sua instituição: {formatNumber(byUniversity)}
              </SubTitle>
              <SubTitle subTitle isProgres>
                Diretamente pelas empresas: {formatNumber(byCompany)}
              </SubTitle>
            </ContainerInfoStudents>
          </ContainerSubTitles>

          <ContainerInfoStudents>
            <SubTitleInfos>Relação das vagas com os cursos</SubTitleInfos>
          </ContainerInfoStudents>

          <ContainerSubTitles>
            {filterStateCourses > 0 ? (
              <SubTitle subTitle isWidth>
                Específicas para os cursos selecionados:{' '}
                {formatNumber(specificCourses)}
              </SubTitle>
            ) : (
              <SubTitle subTitle isWidth>
                Específicas para os cursos: {formatNumber(specificCourses)}
              </SubTitle>
            )}

            <SubTitle subTitle isProgres>
              Sem especificação de curso: {formatNumber(noSpecificCourses)}
            </SubTitle>
          </ContainerSubTitles>
        </ContainerTitleInfos>
      )}

      {finished && (
        <ContainerTitleInfos>
          <ContainerInfoStudents>
            <Title>{names[title]}</Title>

            <Title>{formatNumber(total)}</Title>
          </ContainerInfoStudents>

          <ContainerJob ContainerJob>
            <ContainerInfoStudents subTitle>
              <SubTitle subTitle isWidth>
                Com contratação pelo sistema:{' '}
                {formatNumber(hasContractingByPlatform)}
              </SubTitle>
              <SubTitle subTitle isWidth>
                Com contratação externa:{' '}
                {formatNumber(hasNotContractingByPlatform)}
              </SubTitle>
            </ContainerInfoStudents>

            <ContainerInfoStudents
              subTitle
              style={{
                marginLeft: '56px',
              }}
            >
              <SubTitle subTitle>
                Sem contratação: {formatNumber(noContracting)}
              </SubTitle>
              <SubTitle subTitle>Expiradas: {formatNumber(expired)}</SubTitle>
            </ContainerInfoStudents>
          </ContainerJob>
        </ContainerTitleInfos>
      )}

      {applications && (
        <ContainerTitleInfos>
          <ContainerInfoStudents>
            <Title>{names[title]}</Title>

            <Title>{formatNumber(total)}</Title>
          </ContainerInfoStudents>

          <ContainerJob style={{ width: '87%' }}>
            <ContainerInfoStudents>
              <SubTitle subTitle isWidth>
                Vagas cadastradas pela instituição: {formatNumber(byUniversity)}
              </SubTitle>
              <SubTitle subTitle isProgres>
                Vagas cadastradas pelas empresas: {formatNumber(byCompany)}
              </SubTitle>
            </ContainerInfoStudents>
          </ContainerJob>

          <ContainerInfoStudents>
            <SubTitleInfos>Média de candidatura por vaga</SubTitleInfos>

            <Title>{formatNumber(average)}</Title>
          </ContainerInfoStudents>
        </ContainerTitleInfos>
      )}
      <Divider />
    </div>
  );
}

export default GenericContainerReportsInfoJobs;
