import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 512px;

  padding: 40px;

  background-color: #fff;

  border-radius: 12px;

  position: relative;

  @media (max-width: 375px) {
    padding: 23px;
  }
`;

export const Title = styled.p`
  font-size: 16px;
  color: #4b4b4d;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  color: #606062;
  line-height: 22px;
  margin-bottom: 30px;
`;

export const Item = styled.div`
  width: fit-content;
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 68px;
  border: 1px solid #c4c4c4;
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  gap: 13px;
`;
