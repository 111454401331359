import React, { useContext } from 'react';
import { Dialog, ModalContent, ModalOverlay } from './styled';
import { ExtensionProjectsContext } from '../../../../../Context/ExtensionProjectsContext';
import Wall from 'assets/img/walModal.png';

export const ModalSuccessCreateProject = () => {
  const { isSuccessModalOpen, setIsSuccessModalOpen } = useContext(
    ExtensionProjectsContext
  );

  return (
    <ModalOverlay isOpen={isSuccessModalOpen}>
      <div className="box">
        <ModalContent>
          <header>
            <img src={Wall} alt="Icone Wall" />
            <Dialog>Projeto publicado com sucesso!</Dialog>
          </header>
          <p>
            Acompanhe todos os seus projetos cadastrados na aba “Projetos de
            Extensão”.
          </p>
          <button onClick={() => setIsSuccessModalOpen(false)}>
            OK, ENTENDI
          </button>
        </ModalContent>
      </div>
    </ModalOverlay>
  );
};
