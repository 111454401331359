export const renderVacancies = (vacanciesData) => {
  return vacanciesData.map((vacancy) => {
    return <Card key={vacancy.id} vacancy={vacancy} />;
  });
};

export const concatResults = (prev, results) => {
  const prevsResults = prev?.jobs?.results || [];
  const resultsResults = results?.jobs?.results || [];

  const prevsFinishedJobs = prev?.finished_jobs?.results || [];
  const resultsFinishedJobs = results?.finished_jobs?.results || [];

  const seenIds = new Set(prevsResults.map((job) => job.id));

  const newResults = [
    ...prevsResults,
    ...resultsResults.filter((job) => !seenIds.has(job.id)),
  ];

  const seenFinishedIds = new Set(prevsFinishedJobs.map((job) => job.id));

  const newFinishedJobs = [
    ...prevsFinishedJobs,
    ...resultsFinishedJobs.filter((job) => !seenFinishedIds.has(job.id)),
  ];

  return {
    ...prev,
    jobs: {
      ...prev.jobs,
      ...results.jobs,
      results: newResults,
    },
    finished_jobs: {
      ...prev.finished_jobs,
      ...results.finished_jobs,
      results: newFinishedJobs,
    },
  };
};
