import walWhat from 'assets/img/walWhat.svg';

const IModalConfigLoading = {
  ParagraphText: 'Estamos buscando os detalhes da sua vaga.',
  bubbleText: 'Aguarde...',
};

const configModal = {
  modalWidth: 'auto',
  modalHeight: 'auto',
  iconWidth: 70,
  iconHeight: 70,
  titleWidth: 100,
  bubbleText: 'Oops!',
  bubbleWidth: '80px',
  ParagraphText: 'Existem vagas expiradas aguardando a finalização.',
  TwoParagraphText: 'Finalize-as antes de publicar uma nova vaga.',
  buttonWidth: '240px',
  buttonHeight: 50,
  buttonFunction: () => {
    finalizationJobModal();
  },
  buttonConfirmText: 'FINALIZAR AGORA',
  loading: false,
  showCloseButton: false,
  fontSizeButton: '18px',
  paragraphStyled: {
    fontSize: '20px',
    maxWidth: '330px',
    textAlign: 'center',
    marginTop: '20px',
    color: '#fff',
  },
  iconStyled: {
    width: '95px',
    height: '95px',
  },
  icon: walWhat,
};

export { IModalConfigLoading, configModal };
