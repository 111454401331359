export function leftPad(value, length) {
  return value.toString().length < length
    ? leftPad(`0${value}`, length)
    : value;
}

export function capitalizeFirstLetterWithExceptions(str, exceptions) {
  const listExceptions = exceptions || [
    'da',
    'de',
    'do',
    'das',
    'dos',
    'e',
    'em',
    'no',
    'na',
    'nos',
    'nas',
    'um',
    'uma',
    'uns',
    'umas',
  ];

  const text = str.toLowerCase();
  const words = text.split(' ');

  const capitalizedWords = words.map((word) => {
    word = word.trim();
    if (listExceptions.includes(word)) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return capitalizedWords.join(' ');
}

/**
 * This function changes the first letter to larger and the rest remains lowercase
 *
 * @param {String} str - String that is capitalized.
 * @returns {String} Returns the String with only the first letter.
 **/
export const capitalizeFirstLetter = (str) => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};
