import React, { useEffect } from 'react';
import { Form } from '@unform/web';
import {
  Container,
  TimeElapsedInfo,
  WrapperForm,
  WrapperInformations,
  WrapperLink,
} from '../styled';
import * as Yup from 'yup';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import { useNextMentorshipContext } from '../../../context';
import StepController from '../../StepController';
import CepAddressInput from 'components/CepAddressInput';
import Input from 'components/UnformFields/inputField';
import { rescheduleStepTwoValidationSchema } from '../schema';
import { mentorshipApi } from '../../../../../services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

const StepTwo = ({
  item,
  studentName,
  openCancelModal,
  handleClose,
  handleBack,
  countDays,
  fetchMentorships,
}) => {
  const {
    formRefStepTwo,
    rescheduleFormData,
    closeDetailsModal,
    setPage,
  } = useNextMentorshipContext();

  const handleSubmit = async (data) => {
    try {
      data.mentorship_modality = rescheduleFormData.mentorship_modality;
      const validatedData = await rescheduleStepTwoValidationSchema.validate(
        data,
        {
          abortEarly: false,
        }
      );
      const formData = {
        ...rescheduleFormData,
        ...validatedData,
      };
      if (formData.mentor === item.mentor_id) {
        delete formData.mentor;
      }
      const response = await mentorshipApi.rescheduleMentorship(
        item.id,
        formData
      );
      formRefStepTwo.current.setErrors({});
      if (response) {
        handleClose();
        closeDetailsModal();
        setPage(1);
        fetchMentorships(true);
        customSnackbar('Mentoria reagendada com sucesso!', 'confirmation');
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRefStepTwo.current.setErrors(validationErrors);
      }
    }
  };

  useEffect(() => {
    if (formRefStepTwo.current && rescheduleFormData.description) {
      formRefStepTwo.current?.setFieldValue(
        'description',
        rescheduleFormData.description
      );
    }
  }, [rescheduleFormData, formRefStepTwo.current]);

  return (
    <Form ref={formRefStepTwo} onSubmit={handleSubmit}>
      <Container>
        <WrapperInformations>
          <TimeElapsedInfo>
            Solicitada{' '}
            {countDays === 0
              ? 'hoje'
              : countDays === 1
              ? 'há 1 dia'
              : `há ${countDays} dias`}
          </TimeElapsedInfo>
          Sessão de mentoria com <span>{studentName}</span>
        </WrapperInformations>

        <WrapperForm>
          {rescheduleFormData.mentorship_modality === 'online' ? (
            <WrapperLink>
              <p>Link para realizar a mentoria:</p>
              <Input
                name="access_link"
                placeholder="Digite aqui..."
                defaultValue={rescheduleFormData.access_link}
              />
            </WrapperLink>
          ) : (
            <WrapperLink>
              <CepAddressInput
                formRef={formRefStepTwo}
                defaultValues={rescheduleFormData}
              />
            </WrapperLink>
          )}
        </WrapperForm>
        <WrapperForm>
          <p>Explique o motivo do reagendamento da mentoria</p>
          <DescriptionInput
            name="rescheduling_reason"
            placeholder="Digite aqui..."
            value={rescheduleFormData.rescheduling_reason}
            handleChange={(value) => {
              if (formRefStepTwo) {
                if (['', '<p><br></p>'].includes(value)) {
                  formRefStepTwo.current.setFieldError(
                    'rescheduling_reason',
                    'Este campo é obrigatório'
                  );
                } else {
                  formRefStepTwo.current.setFieldError(
                    'rescheduling_reason',
                    null
                  );
                }
              }
            }}
          />
        </WrapperForm>

        <StepController
          item={item}
          openCancelModal={openCancelModal}
          onBack={handleBack}
        />
      </Container>
    </Form>
  );
};

export default StepTwo;
