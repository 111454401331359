import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

const getMatchColor = (match) => {
  if (match >= 0 && match <= 33.9) {
    return '#CF0000';
  } else if (match >= 34 && match <= 69.9) {
    return '#E4C000';
  } else if (match >= 70 && match <= 99.9) {
    return '#3392DB';
  } else if (match === 100) {
    return '#009660';
  } else {
    return '#009291';
  }
};

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;

  background-color: ${(props) => (props.isOtherJobs ? '#fff' : '#F5F5F5')};
  width: 100%;
  height: auto;
  overflow: unset;
  margin-top: 40px;
  padding: 30px 0;
  border-radius: 10px;
`;

export const ContainerFiltersPartners = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: 17px;
`;

export const ButtonFiltersPartners = styled.button`
  background-color: transparent;
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#606062')};
  border-radius: 45px;

  font-size: 1em;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#606062'};

  padding: 6px 15px;
`;

export const ContainerExternalCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top: 15px;

  > div {
    width: 100%;
    display: grid;
    gap: 1.1rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerFavoriteCompanies = styled.div`
  display: flex;
  padding: 10px 20px 0;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  transition: 0.2s;
  position: relative;
  overflow: hidden;

  @media (max-width: 388px) {
    padding: 10px 0px 0;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
  }
`;

export const ContainerWithoutJobs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: transparent;
  width: 100%;
  height: auto;
  margin-top: 40px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
`;

export const IconWithoutJobsContainer = styled.div`
  width: 112px;
  height: 112px;
`;

export const Card = styled.div`
  padding: 8px 15px 20px;
  width: 230px;
  max-width: 100%;
  height: 420px;
  max-height: 460px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid ${(props) => (props.match === 100 ? '#009660' : '#CDCDCD')};
  position: relative;
`;

export const CardWithStatus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0;

  width: ${(props) => (props.isFinishedJobs ? '230px' : '250px')};
  max-width: 100%;
  height: ${(props) => (props.isFinishedJobs ? '295px' : '370px')};
  background-color: #fff;
  border-radius: 12px;

  border: 1px solid #cdcdcd;
`;

export const ContentWithStatus = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5px 20px 15px;

  height: 100%;
  width: 100%;

  .indicationStatus {
    margin-top: 11px;
    border-radius: 3px;
    padding: 6px;
    background: #0050961c;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 12px;
      color: #005096;
      line-height: 13px;
      font-weight: 400;
      margin: 0px 4px;
    }
    svg {
      color: #005096;
      font-size: 14px;
    }
  }
`;

const statusColors = {
  curriculum_sent: 'rgba(229, 229, 229, 1)',
  unscheduled: 'rgba(248, 240, 231, 1)',
  scheduled: 'rgba(208, 243, 226, 1)',
  rescheduled: 'rgba(248, 242, 231, 1)',
  canceled: 'rgba(193, 23, 23, 0.1)',
  liked: 'rgba(227, 235, 247, 1)',
  not_liked: 'rgba(248, 238, 243, 1)',
  default: 'rgba(229, 229, 229, 1)',
};

const getStatusColorBackground = (status) => {
  return statusColors[status] || statusColors.default;
};

const paragraphColors = {
  curriculum_sent: 'rgba(96, 96, 98, 1)',
  unscheduled: 'rgba(240, 157, 81, 1)',
  scheduled: 'rgba(28, 171, 102, 1)',
  rescheduled: 'rgba(231, 184, 85, 1)',
  canceled: 'rgba(193, 23, 23, 1)',
  liked: 'rgba(85, 143, 231, 1)',
  not_liked: 'rgba(193, 23, 105, 1)',
  default: '#606062',
};

const getStatusColorParagraph = (status) => {
  return paragraphColors[status] || paragraphColors.default;
};

const finishedBackgroundColors = {
  job_finished: (universityColor) => universityColor,
  student_contracted: (universityColor) => universityColor,
  job_expired: 'rgba(171, 171, 171, 1)',
  unpublished: 'rgba(171, 171, 171, 1)',
  default: '#606062',
};

const getStatusFinishedBackground = (statusFinished, universityColor) => {
  if (
    statusFinished === 'job_finished' ||
    statusFinished === 'student_contracted'
  ) {
    return finishedBackgroundColors[statusFinished](universityColor);
  }

  return (
    finishedBackgroundColors[statusFinished] || finishedBackgroundColors.default
  );
};

export const JobStatusFinished = styled.div`
  height: 46px;
  width: 100%;

  background-color: ${(props) =>
    getStatusFinishedBackground(props.statusFinished, props.universityColor)};

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  gap: 5px;
`;

export const ButtonDetailsJobOffersWithStatus = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 30px;

  background-color: transparent;
  border: none;
  color: ${(props) => (props.universityColor ? props.universityColor : '#000')};
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
`;

export const JobStatusParagraphFinished = styled.p`
  color: #fff;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;

export const Component = styled.div`
  position: absolute;
  z-index: 200;
  width: 100%;
  height: 248px;
  top: 205px;
`;

export const FeedbackParagraph = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  position: ${(props) => (props.isFinished ? 'absolute' : 'unset')};
  margin-top: ${(props) => (props.isFinished ? '75px' : 'unset')};

  .paragraph {
    font-size: 0.9em;
    font-weight: 400;
    color: #606062;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
  }

  .paragraphSpan {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#606062'};
    font-weight: bold;
  }

  .feedback {
    margin: 0 !important;
    font-size: 13px;
    color: #606062;
    font-weight: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => (props.isFeedback ? '2' : '1')};
    -webkit-box-orient: vertical;
    height: auto;
  }

  .paragraphFinished {
    color: #949494;
    font-size: 12px;
    text-align: center;
  }
`;

export const ButtonFeedback = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContractedJob = styled.button`
  font-size: 12px;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#606062'};
  font-weight: bold;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  height: auto;
  border: none;
`;

export const JobStatus = styled.div`
  height: 46px;
  width: 100%;

  background-color: ${(props) => getStatusColorBackground(props.status)};

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  gap: 5px;
`;

export const StatusImage = styled.img`
  width: 15px;
  height: 16px;
  margin: 0;
`;

export const ContainerParagraphStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
`;

export const JobStatusParagraph = styled.p`
  color: ${(props) => getStatusColorParagraph(props.status)};
  margin: 0;
  font-weight: ${(props) => (props.isStatus ? 'bold' : '400')};
  font-size: ${(props) => (props.isStatus ? '14px' : '12px')};
  text-align: center;
  display: flex;
  flex-wrap: nowrap;
`;

export const CardExternal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 15px;
  padding: ${(props) => (props.isOnGoing ? '0 20px 15px' : '20px 15px')};

  width: 250px;
  height: ${(props) => (props.isOnGoing ? 'auto' : '270px')};
  background-color: #fff;
  border-radius: 12px;

  border: 1px solid #cdcdcd;
`;

export const ContainerCards = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;

  > div {
    width: 100%;
    display: grid;
    gap: 1.1rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerCardsFavoriteCompanies = styled(ContainerCards)`
  > div {
    width: 100%;
    display: grid;
    gap: 1.1rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    height: ${(props) => (props.isExternal ? '341px' : '491px')};
    overflow-y: hidden;
    padding-top: 71px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerInviteInterview = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #005096;
  gap: 10px;
  margin-top: 40px;

  p {
    margin: 0;
    font-size: 1.125em;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContainerCardsExternalJobs = styled(ContainerCards)`
  > div {
    width: 100%;
    display: grid;
    gap: 1rem;

    padding-top: 72px;

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerGenericMessage = styled.div`
  position: relative;
  width: 60vw;
`;

export const Title = styled.h1`
  font-size: 1.3em;
  font-weight: 700;
  color: #606062;
  margin: 0px 6px;

  @media (max-width: 388px) {
    max-width: 230px;
  }
`;

export const Paragraph = styled.p`
  font-size: 1em;
  font-weight: 500;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#606062'};
  margin: 0;
  font-weight: ${(props) => (props.bold ? '700' : '400')};
  text-align: start;
  white-space: ${(props) => (props.isDate ? 'nowrap' : 'unset')};
`;

export const ParagraphTitle = styled.p`
  font-size: ${(props) => (props.isName ? '1em' : '1.1em')};
  font-weight: 500;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#606062'};
  margin: 0;
  font-weight: 700;
  text-align: ${(props) => (props.isName ? 'start' : 'center')};

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => (props.isName ? '1' : '2')};
  -webkit-box-orient: vertical;

  height: ${(props) => (props.isName ? 'auto' : '39px')};
  max-height: ${(props) => (props.isName ? '24px' : '39px')};
`;

export const ParagraphCode = styled.p`
  font-size: 0.9em;
  color: #949494;
  margin: 0;
  white-space: nowrap;
`;

export const IconImg = styled.img`
  width: ${(props) => (props.isInterview ? '24px' : '14px')};
  height: ${(props) => (props.isInterview ? '24px' : '14px')};
`;

export const ButtonSeeMoreJobs = styled.button`
  border: none;
  background-color: transparent;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
`;

export const CircleMatch = styled.div`
  width: 72px;
  height: 72px;
  margin-bottom: 10px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  border: 8px solid ${(props) => getMatchColor(props.match)};
  align-items: center;
  justify-content: center;
`;

export const HeaderMatchComponent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
`;

export const ParagraphMatch = styled.p`
  color: #606062;
  font-weight: 400;
  font-size: 8px;

  margin: 0;
`;

export const ContentStatus = styled.div`
  position: relative;
  height: 24px;
  width: 160px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    opacity: 0.2;
    border-radius: 64px;
  }
`;

export const Header = styled.div`
  margin-top: ${(props) =>
    !props.isCandidature || props.isFinishedJobs
      ? '15px'
      : props.isIndication
      ? '8px'
      : '48px'};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Status = styled.p`
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const InformationsJob = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  gap: 10px;
`;

export const ContentIcon = styled.div`
  position: relative;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    border-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    opacity: 0.1;
  }
`;

function hexToRgb(hex) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
}

export const ContainerStatusCard = styled.div`
  margin-top: 8px;
  height: 26px;
  width: auto;
  padding: 5px 10px;
  border-radius: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.universityColor
      ? `rgba(${hexToRgb(props.universityColor)}, 0.1)`
      : 'rgba(0, 146, 145, 0.1)'};
`;

export const ParagraphStatusCard = styled.p`
  margin: 0;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 12px;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
`;

export const ButtonJobOffers = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (props.isOnGoingJobs ? '100%' : '80px')};
  height: 30px;

  padding: 8px 12px;
  gap: 5px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : 'transparent'};
  border: none;
  border-radius: 4px;
  color: ${(props) => (props.universityColor ? '#fff' : props.universityColor)};

  cursor: pointer;
  ${(props) => (props.disabled ? 'pointer-events: none;' : '')}
`;

export const ButtonDetailsJobOffers = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${(props) => (props.isOnGoingJobs ? '100%' : '80px')};
  height: 30px;

  padding: 8px 12px;
  gap: 5px;

  background-color: transparent;
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : 'none')};
  color: ${(props) => (props.universityColor ? props.universityColor : '#000')};
  border-radius: 4px;
  cursor: pointer;
  ${(props) => (props.disabled ? 'pointer-events: none;' : '')}
`;

export const ContentButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  padding: 0px 15px 18px;
  position: absolute;
  bottom: 0;
`;

export const ContentButtonsApply = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  margin-top: ${(props) => (props.isOnGoing ? '228px' : '190px')};
  position: absolute;
`;

export const CardSkeleton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #cdcdcd;

  padding: 8px 15px 20px;
  width: 230px;
  max-width: 100%;
  height: 270px;
  max-height: 460px;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
`;

export const MatchSkeleton = styled.div`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 50%;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ParagraphSkeleton = styled.div`
  width: 102px;
  height: 21px;
  border-radius: 30px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerSkeletonUnderstand = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: self-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
`;

export const ButtonSeeMore = styled.button`
  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  width: 160px;
  height: 45px;
  font-size: 1em;
  font-weight: 700;

  padding: 8px 12px;
  gap: 5px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
`;

export const InformationSkeletonUnderstand = styled.div`
  width: 90%;
  height: 80px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const TextSkeletonUnderstend = styled.div`
  width: ${(props) => (props.isTitleUnderstand ? '45%' : '30%')};
  height: 30px;
  border-radius: 8px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerModal = styled.div`
  display: flex;
  align-items: self-start;
  flex-direction: column;

  background-color: #fff;
  padding: 20px;
  border-radius: 10px;

  height: 90vh;
  width: 50vw;
  gap: 8px;
`;

export const InformationSkeleton = styled.div`
  width: 100%;
  height: 22px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ButtonSkeleton = styled.div`
  width: 80px;
  height: 30px;
  border-radius: 6px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerCardsSkeleton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;

  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;

  > div {
    width: 100%;
    ${(props) => (props.divHeight ? `height: ${props.divHeight};` : '')}
    display: grid;
    gap: 1.1rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &::after {
      content: '';
      position: absolute;
      margin-left: 50px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    }
  }
`;

export const ContainerStatus = styled.div`
  height: 44px;
  width: 100%;

  background-color: cyan;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  font-weight: bold;
`;

export const BackgroundModalsInterviewSkeleton = styled.div`
  width: 100%;
  height: 100%;

  background-color: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center !important;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;

  @media (max-width: 690px) {
    align-items: flex-end;
  }
`;

export const ContainerModalInterview = styled.div`
  overflow: hidden;
  background-color: #fff;
  margin-top: 5%;
  z-index: 2;
  border-radius: 10px;
  max-width: 870px;
  height: ${(props) => (props.isInterview ? 'auto' : '350px')};
  width: 100%;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const HeaderInterviewSkeleton = styled.div`
  width: 100%;
  height: 90px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const ContainerInterviewSkeleton = styled.div``;

export const ContentCircularProgress = styled.div`
  z-index: 1;

  .circle-background {
    fill: none;
    stroke: #ddd;
  }

  .circle-progress {
    fill: none;
    stroke: ${(props) => getMatchColor(props.match)};
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  svg {
    width: ${({ totalWidth }) => `${totalWidth}px`};
    height: 100%;
  }

  .circle-text {
    font-size: 20px;
    font-weight: bold;
    fill: ${(props) => getMatchColor(props.match)};
  }

  .circle-text-match {
    font-size: 14px;
    font-weight: 400;
  }

  .fire-icon {
    fill: ${(props) => getMatchColor(props.match)};
  }
`;
