import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  AddButton,
  ButtonDiv,
  DocumentHeader,
  Footer,
  StatusMessage,
  SubTitle,
  TextDiv,
  Title,
  Wrapper,
} from './styled';
import { ContainerForm, SaveButton } from '../../styled';

import { Form } from '@unform/web';

import PDFIcon from 'assets/ComponentIcons/PDFIcon';

import UnformSelect from 'components/UnformFields/Select/SearchableSelect';

import Valid from '../../assets/valid.svg';
import Expiring from '../../assets/expiring.svg';
import Expired from '../../assets/expired.svg';
import Document from '../../assets/document.svg';

import {
  deleteDocuments,
  getMacroAreas,
  updateDocument,
} from '../../../../../../Services';

import moment from 'moment';

import * as Yup from 'yup';

import DownloadIcon from '../../assets/download.svg';
import BaseLayoutContext from 'contexts/base-layout';
import EditIcon from 'assets/academicModeling/edit';
import DeleteIcon from 'assets/academicModeling/delete';
import ModalInformation from 'components/informationModal';

function FilesList({
  files,
  universityColor,
  setModalType,
  formInitialData,
  setFormInitialData,
  setSelectedDocument,
  handlePageChange,
  companyId,
  getDocuments,
  setFilesList,
}) {
  const formRef = useRef(null);
  const { openSnackbar } = useContext(BaseLayoutContext);

  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [configModalDelete, setConfigModalDelete] = useState({});
  const [fileDelete, setFileDelete] = useState({});

  const statusMessage = {
    current: {
      iconPath: Valid,
      background: '#D9FFE3',
      textColor: '#068E2C',
      message: 'Vigente',
    },
    no_validity: {
      iconPath: Expired,
      background: '#FFEDED',
      textColor: '#DA0505',
      message: 'Inválido',
    },
    close_expiration: {
      iconPath: Expiring,
      background: '#FFF2E3',
      textColor: '#F09D51',
      message: 'Próximo do vencimento',
    },
    expired: {
      iconPath: Expired,
      background: '#FFEDED',
      textColor: '#DA0505',
      message: 'Vencido',
    },
  };

  const returnStatus = (status) => {
    const { iconPath, background, textColor, message } = statusMessage[status];
    return (
      <StatusMessage color={textColor} background={background}>
        <img src={iconPath} alt="status" />
        <p>{message}</p>
      </StatusMessage>
    );
  };

  const returnDocumentName = (name) => {
    if (name.length <= 16) {
      return name;
    }
    return `${name.slice(0, 16)}...`;
  };

  const returnSurveillance = (start, end, status = null) => {
    if (status && status === 'no_validity') {
      return 'Não informado';
    }
    if (!start) {
      return 'Não possui tempo preestabelecido';
    }
    return `${moment(start).format('DD/MM/YYYY')} a ${moment(end).format(
      'DD/MM/YYYY'
    )}`;
  };

  const handleSubmit = async (data) => {
    try {
      const { id } = files[0];
      const newDocumentData = await updateDocument(companyId, id, data);
      await getDocuments();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleClickAdd = () => {
    setModalType('form');
  };

  const handleDelete = async (file) => {
    const { company_invite, id } = file;
    try {
      const response = await deleteDocuments(company_invite, id);
      if (response?.status === 204) {
        const newDocument = files.filter((file) => file.id !== id);
        setFilesList(newDocument);
      }
      handlePageChange(0);
      setOpenModalDelete(false);
      setConfigModalDelete(null);
      setFileDelete(null);
      return true;
    } catch (err) {}
  };

  const actionDelete = (e, file) => {
    e.preventDefault();
    setConfigModalDelete({
      bubbleText: 'Atenção!',
      ParagraphText: `Tem certeza que deseja excluir o documento "${file?.file?.file_name}"?`,
      buttonConfirmText: 'SIM, EXCLUIR.',
      buttonCancelText: 'NÃO',
      showButtonCancel: true,
      style: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    });
    setFileDelete(file);
    setOpenModalDelete(true);
  };

  const handleDownload = async (file) => {
    try {
      const url = file.file.file_url;
      const filename = file.file.file_name;
      const response = await fetch(url);
      const blob = await response.blob();
      const isPDF = response.headers.get('content-type') === 'application/pdf';
      if (isPDF) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } else {
        openSnackbar('Ocorreu um erro durante o download', true);
      }
    } catch (error) {
      openSnackbar('Ocorreu um erro durante o download', true);
    }
  };

  const cannotAddDocument = files.length >= 2;

  return (
    <ContainerForm>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title universityColor={universityColor}>
          Visualizar documentos de estágio
        </Title>
        <SubTitle>Confira aqui os documentos de estágio.</SubTitle>

        {files.map((file) => (
          <Wrapper>
            <PDFIcon color={universityColor || '#009291'} size={22} />
            <TextDiv>
              <DocumentHeader>
                <h6>{returnDocumentName(file.file.file_name)}</h6>
                {returnStatus(file.status)}
              </DocumentHeader>
              <p>
                Vigência:{' '}
                {returnSurveillance(
                  file?.start_validity_date,
                  file?.end_validity_date,
                  file?.status
                )}
              </p>
            </TextDiv>
            <ButtonDiv>
              <button onClick={() => handleDownload(file)}>
                <img src={DownloadIcon} alt="download" />
              </button>
              <button
                onClick={() => {
                  setSelectedDocument(file.id);

                  setFormInitialData({
                    ...file,
                  });
                  setModalType('form');
                }}
              >
                <EditIcon size={'15'} />
              </button>
              <button onClick={(e) => actionDelete(e, file)}>
                <DeleteIcon />
              </button>
            </ButtonDiv>
          </Wrapper>
        ))}
        <Footer>
          {!cannotAddDocument && (
            <AddButton>
              <button onClick={handleClickAdd} disabled={cannotAddDocument}>
                <img src={Document} alt="Adicionar documento" />
                Adicionar outro documento
              </button>
            </AddButton>
          )}
        </Footer>
      </Form>
      {openModalDelete && configModalDelete && (
        <ModalInformation
          modalOpenState={openModalDelete}
          config={configModalDelete}
          buttonConfirmFunction={(e) => handleDelete(fileDelete)}
          buttonCancelFunction={() => setOpenModalDelete(false)}
        />
      )}
    </ContainerForm>
  );
}

export default FilesList;
