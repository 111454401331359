export const filterOptionsAdapter = (filterOptions) => {
  return {
    states: filterOptions.states,
    cities: filterOptions.cities,
    unities: filterOptions.unities,
    specialNeeds: filterOptions.special_needs,
    colorOrRace: filterOptions.color_or_race,
    genderIdentity: filterOptions.gender_identity,
    gender: filterOptions.gender,
    professionalStatus: filterOptions.professional_status,
    jobTypes: filterOptions.job_types,
    professionalOffers: filterOptions.professional_offers,
    workingInFormationArea: filterOptions.working_in_formation_area,
    courseLevel: filterOptions.course_level,
    academicProgress: filterOptions.academic_progress,
    studentStatus: filterOptions.student_status,
    behavioralAttributes: filterOptions.behavioral_attributes,
    personality: filterOptions.personality,
    periods: filterOptions.periods,
    courses: filterOptions.courses,
    names: filterOptions.name,
    externalIds: filterOptions.external_id,
    areas: filterOptions.areas,
    professionalAreas: filterOptions.professional_experience_areas,
    attendanceMode: filterOptions.attendance_mode,
  };
};
