import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';
import {
  ApplyButton,
  ButtonFilter,
  CancelButton,
  CleanFiltersButton,
  FilterContent,
  FilterCounter,
  FilterFooter,
  ModalContainer,
  ModalContent,
  ModalOverlay,
} from './styles';
import { FilterTabs } from './components/FilterTabs';
import { BsFilterLeft } from 'react-icons/bs';
import FilterLoading from './components/FilterLoading';
import { FilterContextProvider } from './contexts/FilterContext';
import BaseLayoutContext from 'contexts/base-layout';
import { Themes } from './themes';
import useMediaQuery from 'hooks/useMediaQuery';

export default function FilterModal({
  children,
  filterState,
  setFilterState,
  onApply,
  onClean,
  onClose,
  onOpen,
  tabs,
  loading = false,
  appliedFilters,
  setAppliedFilters,
  width,
  marginLeft,
  zIndex,
  theme = 'light',
  zIndexOverlay = 1080,
  validateDate = false,
  ...rest
}) {
  const contentRef = useRef(null);
  const buttonRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [errorDate, setErrorDate] = useState(false);

  const { universityColor } = useContext(BaseLayoutContext);

  const themes = Themes(universityColor, theme);

  const currentTheme = themes[theme];

  const isMobile = useMediaQuery('(max-width: 640px)');

  useEffect(() => {
    if (validateDate && filterState.start_date && filterState.end_date) {
      const startDate = new Date(filterState.start_date);
      const endDate = new Date(filterState.end_date);
      if (startDate > endDate) {
        setErrorDate(true);
      } else {
        setErrorDate(false);
      }
    } else {
      setErrorDate(false);
    }
  }, [filterState]);

  useEffect(() => {
    setActiveTab(() => 0);
  }, [isOpen]);

  async function handleToggleOpen() {
    if (isOpen) {
      await handleCancel();
    } else {
      handleOpen();
    }
  }

  async function handleClose() {
    setIsOpen(false);
    onClose && (await onClose());
  }

  async function handleOpen() {
    setIsOpen(true);
    onOpen && (await onOpen());
  }

  async function handleCancel() {
    await handleClose();
    setFilterState(() => appliedFilters || {});
  }

  const filterCount = Object.keys(filterState).reduce((acc, key) => {
    const typeOfFilter = typeof filterState[key];
    if (Array.isArray(filterState[key])) {
      return acc + filterState[key].length;
    } else if (typeOfFilter === 'string') {
      const value = filterState[key].trim();
      return value.length > 0 ? acc + 1 : acc;
    } else if (['boolean', 'object', 'number'].includes(typeOfFilter)) {
      return filterState[key] !== null ? acc + 1 : acc;
    } else if (typeOfFilter === 'number') {
      return filterState[key] !== 0 ? acc + 1 : acc;
    }
    return acc;
  }, 0);

  async function handleApplyFilters(filterState) {
    if (validateDate && errorDate) {
      return;
    }
    handleClose();
    const aply = onApply && (await onApply(filterState));
    if (aply === false) {
      return;
    }
    setAppliedFilters(filterState);
  }

  function handleCleanFilters() {
    setFilterState({});
    setAppliedFilters({});
    onClean && onClean();
    handleClose();
  }

  const buttonFilter = (
    <ButtonFilter
      onClick={handleToggleOpen}
      ref={buttonRef}
      isOpen={isOpen}
      hasFilters={filterCount > 0}
      disabled={loading}
      currentTheme={currentTheme}
    >
      {loading ? (
        <FilterLoading />
      ) : (
        <BsFilterLeft size={23} color={currentTheme.color} />
      )}
      <span style={{ width: 'auto', fontSize: '15px' }}>Filtros</span>
      {filterCount > 0 && (
        <FilterCounter universityColor={universityColor}>
          {filterCount}
        </FilterCounter>
      )}
    </ButtonFilter>
  );

  return (
    <Fragment>
      <ModalOverlay
        isOpen={isOpen}
        zIndexOverlay={zIndexOverlay}
        onClick={handleClose}
      />
      <ModalContainer zIndex={zIndex} {...rest}>
        {isMobile ? !isOpen && buttonFilter : buttonFilter}
        {isOpen && (
          <ModalContent
            isOpen={isOpen && !loading}
            ref={contentRef}
            hasFilters={filterCount > 0}
            width={width}
            marginLeft={marginLeft}
          >
            <strong>Filtros</strong>
            <FilterContextProvider
              filterState={filterState}
              setFilterState={setFilterState}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={tabs}
              tabsContent={children}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              setErrorDate={setErrorDate}
              errorDate={errorDate}
              validateDate={validateDate}
              isOpen={isOpen}
            >
              {tabs && <FilterTabs />}
              <FilterContent>
                {tabs ? children[activeTab] : children}
              </FilterContent>
              <FilterFooter>
                {filterCount > 0 && (
                  <CleanFiltersButton onClick={handleCleanFilters}>
                    Limpar todos os filtros
                  </CleanFiltersButton>
                )}
                <div>
                  <CancelButton onClick={handleCancel}>Cancelar</CancelButton>
                  <ApplyButton
                    universityColor={universityColor}
                    onClick={() => handleApplyFilters(filterState)}
                    disabled={errorDate}
                  >
                    Aplicar filtros
                  </ApplyButton>
                </div>
              </FilterFooter>
            </FilterContextProvider>
          </ModalContent>
        )}
      </ModalContainer>
    </Fragment>
  );
}
