import React, { useCallback, useContext, useState } from 'react';
import {
  ActionButton,
  ActionsContainer,
  StudentData,
  StudentInfo,
  StudentTileContainer,
  Flag,
} from './styles';
import phoneIcon from 'assets/mentorship/phone-icon.svg';
import emailIcon from 'assets/mentorship/email-icon.svg';
import { useCsm } from '../../contexts/CsmContext';
import { DocIcon } from 'assets/csm/doc-icon';
import BaseLayoutContext from 'contexts/base-layout';
import { getStudentCurriculumData } from 'services/university/curriculum';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import { getApplicationCoverLetter } from 'services/university/coverLetter';
import placeholderImage from 'assets/img/placeholder.jpg';
import { StudentCategory } from '../../resources/defaultStudentCategories';
import { capitalizeFirstLetterWithExceptions } from 'utils/stringUtils';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import statsIcon from 'assets/csm/statsIcon.svg';
import { registerAccessedCurriculum } from 'services/student/registerActivityStudent';

export function StudentTile({ student }) {
  const {
    selectedStudents,
    setSelectedStudents,
    activeCategory,
    setSelectStudent,
  } = useCsm();
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);

  const {
    openModalWithContent,
    openSnackbar,
    handleIsLoadingState,
    closeModal,
    updateHasCoverLetter,
  } = useContext(BaseLayoutContext);

  function toggleStudent(student) {
    if (selectedStudents.some((s) => s.id === student.id)) {
      setSelectedStudents(selectedStudents.filter((s) => s.id !== student.id));
    } else {
      setSelectedStudents([...selectedStudents, student]);
    }
  }

  function isStudentSelected(studentId) {
    return selectedStudents.some((s) => s.id === studentId);
  }

  const openCurriculum = useCallback(async ({ id }) => {
    handleIsLoadingState(true);

    const response = await getStudentCurriculumData(id);
    await registerAccessedCurriculum([response.curriculum_id]);
    if (!response.message) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  const openLetter = useCallback(async ({ id }) => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(id);
    if (!response.message) {
      openModalWithContent(
        <CoverLetterContainer
          notStudent
          studentId={id}
          handleLoadingState={handleIsLoadingState}
          getApplicationCoverLetter={getApplicationCoverLetter}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
        />
      );
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  const handleHistoricClick = () => {
    setSelectStudent(student);
  };

  return (
    <StudentTileContainer>
      {activeCategory !== StudentCategory.INACTIVE && (
        <input
          type="checkbox"
          id={`studentTile${student.id}`}
          checked={isStudentSelected(student.id)}
          onChange={() => toggleStudent(student)}
        />
      )}
      <StudentData>
        <img
          src={student.avatar}
          alt=""
          onError={(e) => {
            e.target.src = placeholderImage;
            e.target.onerror = null;
          }}
        />
        <StudentInfo>
          <strong>{student.name}</strong>
          <p id={'code'}>Código: {student.externalId}</p>
          {activeCategory !== StudentCategory.INACTIVE && (
            <span>
              {student.professionalStatus}
              {student.workingInFormationArea ? ' - Na área de formação' : ''}
            </span>
          )}
          {student.courses.map((course) => (
            <div>
              <span>{capitalizeFirstLetterWithExceptions(course.name)}</span> -{' '}
              <span>{course.semester}º semestre</span>
            </div>
          ))}
          {(student.mobilePhone || student.email) && (
            <div className="phone-email">
              {student.mobilePhone && (
                <div>
                  <img src={phoneIcon} alt="" />
                  <p>{student.mobilePhone}</p>
                </div>
              )}
              {student.email && (
                <div>
                  <img src={emailIcon} alt="" />
                  <p>{student.email}</p>
                </div>
              )}
            </div>
          )}
          <ActionsContainer>
            {activeCategory !== StudentCategory.INACTIVE && (
              <ActionButton
                onClick={() => openCurriculum(student)}
                disabled={!student.hasCurriculum}
                title={
                  !student.hasCurriculum
                    ? 'Este estudante não possui currículo'
                    : ''
                }
              >
                <DocIcon
                  color={student.hasCurriculum ? '#009291' : '#949494'}
                />
                Visualizar currículo
              </ActionButton>
            )}

            {activeCategory !== StudentCategory.INACTIVE && (
              <ActionButton
                onClick={() => openLetter(student)}
                disabled={!student.hasCoverLetter}
                title={
                  !student.hasCoverLetter
                    ? 'Este estudante não possui carta de apresentação'
                    : ''
                }
              >
                <DocIcon
                  color={student.hasCoverLetter ? '#009291' : '#949494'}
                />
                Visualizar carta de apresentação
              </ActionButton>
            )}
            <ActionButton onClick={handleHistoricClick}>
              <img src={statsIcon} />
              Visualizar histórico <Flag>NOVO</Flag>
            </ActionButton>
          </ActionsContainer>
        </StudentInfo>
      </StudentData>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          showActions={false}
          showInterviewButton={false}
        />
      )}
    </StudentTileContainer>
  );
}
