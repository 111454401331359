import React from 'react';

import {
  Container,
  Box,
  Content,
  Header,
  Title,
  ContainerInformations,
  HeaderInformation,
} from './styled';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';

export default function TargetAudienceModal({ closeModal, open }) {
  const { detailsObject } = useVisualizationContext();

  const { macro_areas, courses, semesters } = detailsObject;

  const formatAreasAndCourses = (items, key) => {
    if (items && items.length > 0) {
      return items.map((item) => item.label || item).join(', ');
    }
    return key;
  };

  const formattedMacroAreas = formatAreasAndCourses(
    macro_areas,
    'Todas as áreas de atuação'
  );
  const formattedCourses = formatAreasAndCourses(courses, 'Todos os cursos');
  const formattedPeriod = formatAreasAndCourses(semesters, 'Todos os períodos');

  if (!open) {
    return null;
  }

  return (
    <Container>
      <Box>
        <Content>
          <Header>
            <CloseButton onClick={closeModal} />
          </Header>
          <ContainerInformations>
            <Title>Público-alvo</Title>

            <div>
              <HeaderInformation>
                Áreas de atuação relacionadas: <br />
                <p>{formattedMacroAreas}</p>
              </HeaderInformation>
              <HeaderInformation>
                Cursos relacionados: <br />
                <p>{formattedCourses}</p>
              </HeaderInformation>
              <HeaderInformation>
                Período: <br />
                <p>{formattedPeriod}</p>
              </HeaderInformation>
            </div>
          </ContainerInformations>
        </Content>
      </Box>
    </Container>
  );
}
