import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.2s ease-in-out;

  > .tooltip {
    display: none;
  }

  &:hover {
    > .tooltip {
      border-radius: 2px;
      background: var(--tooltip-75, rgba(0, 0, 0, 0.75));
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 8px;
      z-index: 100;
      position: absolute;
      bottom: calc(100% + 12px);
      left: 50%;
      transform: translateX(-50%);
      color: var(--character-primary-inverse, #fff);
      text-align: center;
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: default;
      width: ${(props) => (props.width ? props.width : '100px')};
      margin-bottom: ${(props) =>
        props.marginBottom ? props.marginBottom : '0'};

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 5px 0 5px;
        border-color: var(--tooltip-75, rgba(0, 0, 0, 0.75)) transparent
          transparent transparent; /* Alterado o valor aqui */
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;
