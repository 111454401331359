export const AdapterPreRegistrationCompany = (
  {
    organization_number,
    legal_name,
    address,
    staff,
    send_invite_email
  }
) => {
  return {
    organizationNumber: organization_number,
    corporateName: legal_name,
    city: address?.city,
    state: address?.state,
    staffName: staff?.name,
    staffEmail: staff?.email,
    staffPhone: staff?.phone,
    sendInviteEmail: Boolean(send_invite_email)
  };
}
