import { dnaGreen } from 'assets/jss/material-dashboard-pro-react';
import React from 'react';
import styled from 'styled-components';

const SwitchWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ checked }) => (checked ? `${dnaGreen}` : '#ccc')};
  transition: 0.4s;
  border-radius: 20px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
    transform: ${({ checked }) =>
      checked ? 'translateX(20px)' : 'translateX(0)'};
  }
`;

const Switch = ({ checked, onChange }) => {
  const handleChange = () => {
    onChange && onChange(!checked);
  };

  return (
    <SwitchWrapper>
      <SwitchInput type="checkbox" checked={checked} onChange={handleChange} />
      <SwitchSlider checked={checked} />
    </SwitchWrapper>
  );
};

export default Switch;
