import React, { Fragment } from 'react';
import {
  AvatarImage,
  Box,
  BubbleDialog,
  CardContainer,
  CardItem,
  Container,
  ContainerIcon,
  Infos,
  ParagraphStyled,
  WrapperIcon,
} from './style';

export default function SkeletonModal() {
  const numberItems = 5;

  return (
    <Box>
      <Container>
        <ContainerIcon>
          <AvatarImage />
          <BubbleDialog />
        </ContainerIcon>
        <ParagraphStyled />
        <ParagraphStyled skeletonWidth="80%" />
        <CardContainer>
          {[...Array(numberItems)].map((_, index) => (
            <WrapperIcon>
              <CardItem />
              <Infos widthInfos="150px" />
            </WrapperIcon>
          ))}
        </CardContainer>
      </Container>
    </Box>
  );
}
