import React, { useContext, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { MatrixContext } from '../../../contexts/MatrixContext';
import { getSubjectsCourse } from '../../../services/matrixServices';
import Pagination from 'components/CustomPagination';
import DragItem from '../dragItem';
import { Bar, SPAN, SearchInput, WrapperPagination } from '../style';
import { paginate } from '../../../utils/paginate';
import { CountSelected } from '../modalEditAndDetails/style';
import Tooltip from 'components/NewTooltip';
import GenericMessage from 'components/GenericMessage';

export default function ListDisciplines({}) {
  const {
    allDisciplinesCourse,
    subjectsCourse,
    selectedSubjects,
    selectedCourse,
    setSelectedSubjects,
    setSubjectsCourse,
    showMoreId,
    setShowMoreId,
    currentPageDisciplines,
    setCurrentPageDisciplines,
    limitDisciplines,
    setLimitDisciplines,
    setAllDisciplinesCourse,
    inputRef,
    totalSubjects,
    getMatrices,
    checkedAllDisciplines,
    filterCurrentPage,
  } = useContext(MatrixContext);

  const [filter, setFilter] = useState([...subjectsCourse]);
  const [selectedAll, setSelectedAll] = useState(false);

  const [inputValue, setInputvalue] = useState('');

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const handleChange = (event, usingRef) => {
    const value = usingRef ? event : event.target.value;

    setInputvalue(value);

    inputRef.current = value;

    if (!value) {
      return setFilter([...subjectsCourse]);
    }

    const filtred = [...allDisciplinesCourse].filter(
      (item) =>
        item.name.toLowerCase().includes(value.toLowerCase()) ||
        item.code.toLowerCase().includes(value.toLowerCase())
    );

    setCount(filtred.length);
    setFilter(paginate([...filtred], limit));
  };

  const handleLimitFilterChange = (valueLimit) => {
    setLimit(valueLimit);

    const filtred = [...allDisciplinesCourse].filter(
      (item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.code.toLowerCase().includes(inputValue.toLowerCase())
    );

    setCount(filtred.length);

    setFilter(paginate([...filtred], valueLimit));
  };

  const handleLimitChange = async (valueLimit) => {
    setLimitDisciplines(valueLimit);

    const newPage = `&limit=${valueLimit}&offset=${
      (currentPageDisciplines - 1) * valueLimit || 0
    }`;
    const { results } = await getSubjectsCourse(selectedCourse, newPage);
    setSubjectsCourse(results);
  };

  useEffect(() => {
    if (inputRef.current !== '') {
      handleChange(inputRef.current, true);
    }
    if (checkedAllDisciplines.current) {
      setSelectedAll(true);
    }
    if (inputRef.current !== '') {
      setCurrentPage(filterCurrentPage.current);
    }
  }, []);

  const handleClickSelectAll = (e) => {
    if (!selectedAll) {
      checkedAllDisciplines.current = true;
      if (inputValue !== '' && Array.isArray(filter[currentPage - 1])) {
        const allIds = filter[currentPage - 1].map((item) => item.id);
        setSelectedSubjects(allIds);
        return;
      }

      const allIds = subjectsCourse.map((item) => item.id);
      setSelectedSubjects(allIds);
      return;
    }
    checkedAllDisciplines.current = false;
    return setSelectedSubjects([]);
  };

  const getCourseSubjects = async (id) => {
    const query = 'limit=20';
    return await getSubjectsCourse(id, query);
  };

  const allDisciplinesCount = allDisciplinesCourse.length;

  const handlePageChange = async (page) => {
    const newPage = `&limit=${limitDisciplines}&offset=${
      (page - 1) * limitDisciplines || 0
    }`;
    const { results } = await getSubjectsCourse(selectedCourse, newPage);
    setSubjectsCourse(results);
  };

  const onPageChange = (page) => {
    checkedAllDisciplines.current = false;
    setCurrentPageDisciplines(page);
    handlePageChange(page);
    setSelectedAll(false);
    setSelectedSubjects([]);
  };

  const onPageChangeFilter = (page) => {
    setCurrentPage(page);
    setSelectedAll(false);
    setSelectedSubjects([]);
    checkedAllDisciplines.current = false;
    filterCurrentPage.current = page;
  };

  function updateList(id, newData) {
    const newList = [...subjectsCourse];
    const index = newList.findIndex((item) => item.id === id);
    newList[index] = { ...newList[index], ...newData };
    setSubjectsCourse(newList);
  }

  const mapSubjects = () => {
    if (
      inputValue !== '' &&
      filter.length > 0 &&
      Array.isArray(filter[currentPage - 1])
    ) {
      return filter[currentPage - 1].map((item) => (
        <DragItem
          key={item.id}
          code={item.code}
          id={item.id}
          setAllDisciplinesCourse={setAllDisciplinesCourse}
          selectedCourse={selectedCourse}
          matrix={item.related_matrixes}
          name={item.name}
          internshipDiscipline={item.internship_discipline}
          extensionProjectDiscipline={item.extension_project_discipline}
          selectedSubjects={selectedSubjects}
          setSelectedSubjects={setSelectedSubjects}
          getCourseSubjects={getCourseSubjects}
          setSubjectsCourse={setSubjectsCourse}
          showMoreId={showMoreId}
          setShowMoreId={setShowMoreId}
          getMatrices={getMatrices}
          updateList={updateList}
        />
      ));
    } else if (
      (inputValue !== '' && filter.length === 0) ||
      filter.length === 0
    ) {
      return (
        <GenericMessage title="No momento não foram encontradas disciplinas/unidades curriculares." />
      );
    }

    return filter.map((item) => (
      <DragItem
        key={item.id}
        getMatrices={getMatrices}
        code={item.code}
        id={item.id}
        selectedCourse={selectedCourse}
        matrix={item.related_matrixes}
        name={item.name}
        internshipDiscipline={item.internship_discipline}
        extensionProjectDiscipline={item.extension_project_discipline}
        setAllDisciplinesCourse={setAllDisciplinesCourse}
        selectedSubjects={selectedSubjects}
        setSelectedSubjects={setSelectedSubjects}
        getCourseSubjects={getCourseSubjects}
        setSubjectsCourse={setSubjectsCourse}
        showMoreId={showMoreId}
        setShowMoreId={setShowMoreId}
        updateList={updateList}
      />
    ));
  };

  const returnFilterPagination = () => {
    if (inputValue === '') {
      return (
        <WrapperPagination>
          <Pagination
            className="pagination-bar"
            currentPage={currentPageDisciplines}
            totalCount={totalSubjects}
            limit={limitDisciplines}
            onPageChange={onPageChange}
            setLimit={handleLimitChange}
            setCurrentPage={setCurrentPageDisciplines}
          />
        </WrapperPagination>
      );
    }

    return (
      <WrapperPagination>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={count}
          limit={limit}
          onPageChange={onPageChangeFilter}
          setLimit={handleLimitFilterChange}
          showLimit={filter > 0}
          setCurrentPage={setCurrentPage}
        />
      </WrapperPagination>
    );
  };

  const handleClickSpanSelectAll = () => {
    const disciplinesId = allDisciplinesCourse.map((item) => item.id);
    setSelectedSubjects([...disciplinesId]);
  };

  return (
    <Fragment>
      <SearchInput
        onChange={handleChange}
        value={inputValue}
        placeholder="Buscar"
      />

      {selectedAll && (
        <CountSelected>
          Todas as {selectedSubjects.length} disciplinas nesta página estão
          selecionadas.{' '}
          {selectedSubjects.length < allDisciplinesCourse.length && (
            <span onClick={handleClickSpanSelectAll}>
              Selecionar todas as {allDisciplinesCourse.length} disciplinas
              cadastradas
            </span>
          )}
        </CountSelected>
      )}

      <Bar>
        <Tooltip text={'selecionar todos'} direction={'top'}>
          <input
            type="checkbox"
            onChange={handleClickSelectAll}
            checked={selectedAll}
          />
        </Tooltip>
        <SPAN width="120PX">CÓDIGO</SPAN>
        <SPAN width="230px">DISCIPLINA</SPAN>
        <SPAN>MATRIZ</SPAN>
      </Bar>
      {mapSubjects()}

      {returnFilterPagination()}
    </Fragment>
  );
}
