import styled from 'styled-components';
import X from 'assets/Oportutinites/X.svg';
import plus from 'assets/Oportutinites/plus.svg';
import Lixeira from 'assets/Oportutinites/Lixeira.svg';

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  width: 96%;
  margin-top: 10px;
  margin-top: 30px;
`;
export const InputFileChallenges = styled.div`
  background-color: #009291;
  color: #fff;
  font-size: 14px;
  border: none;
`;
export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
`;
export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
`;

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
`;
export const RModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  bottom: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  height: 100%;
  left: 0;
  right: 0;
  backdrop-filter: blur(2px);
  border-radius: 10px;
`;
export const RModalContainer = styled.div`
  width: 94%;
  height: 86%;
  overflow-y: auto;

  top: 27px;
  position: absolute;

  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  
  padding: 15px;

  .content-wrapper {
    display: flex;
    justify-content: flex-end;
    text-align: center;
  }
  ::-webkit-scrollbar {
        width: 6px;
    };

    ::-webkit-scrollbar-track {
        box-shadow: none; 
        background-color: transparent;
    };

    ::-webkit-scrollbar-thumb {
        background-color: #c2bdbd;
        border-radius: 6px;
    };

    ::-webkit-scrollbar-thumb:hover {
        background-color: #aaa; 
    };

    ::-webkit-transition{ 
        background-color: .3s linear ease-in-out}
    ;
`;
export const AppendButton = styled.button`
  border: none;
  border-radius: 10px;
  background-color: #0f7776;
  color: #ffff;
  font-weight: bold;
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &:before {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    content: '';
    background: url(${plus}) no-repeat 0 0;
    background-position: center;
    background-size: 12px;
    background-color: #fff;
    border-radius: 50%;
  }
`;
export const ButtonSave = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #0f7776;
  font-size: 14px;
  margin-top: 5em;
  cursor: pointer;
`;
export const ButtonDelet = styled.button`
  width: 23px;
  height: 23px;
  background-image: url(${Lixeira});
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-left: 5px;
`;
export const SubTitle = styled.p`
  color: #000000;
  margin-top: -5px;
  font-weight: 100;
  font-size: 12px;
`;
export const Title = styled.h4`
  color: #009291;
  font-weight: 400;
`;

export const ContainerVideos = styled.div`
  width: 100%;
`