import { dnaGreen } from '../../material-dashboard-pro-react';

const StaffHeaderStyle = (theme) => ({
  backgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  viewHeaderBackground: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '160%',
    },
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 20%',
  },
  staffHeaderHolder: {
    height: 'auto',
    left: 'auto',
    margin: '0 0 15px',
    position: 'relative',
    top: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: 155,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      minHeight: 135,
    },
  },
  profileHeaderMask: {
    width: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    overflow: 'hidden',
  },
  studentInfo: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    color: dnaGreen,
  },
  studentCityState: {
    [theme.breakpoints.down('sm')]: {
      color: 'gray',
      justifyContent: 'center',
      '& img': {
        filter: 'invert(100%) opacity(.4)',
      },
    },
    color: '#fff',
    display: 'flex',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: 3,
    },
    padding: 0,
  },
  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    color: dnaGreen,
    fontSize: 35,
    fontWeight: 400,
    display: 'flex',
    marginBottom: 0,
    marginTop: 0,
    zIndex: 2,
  },
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 50,
    alignSelf: 'flex-end',
    zIndex:1
  },
  companyHeaderHolder: {
    height: 'auto',
    left: 'auto',
    margin: '0 0 15px',
    position: 'relative',
    top: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: 155,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      minHeight: 135,
      display: 'flex',
    },
  },
  cardHeaderCompany: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& $pageTitle': {
      color: '#fff',
      marginTop: 115,
      marginLeft: '2.5%',
    },
  },
  tabButton: {
    fontSize: 20,
    fontWeight: 'normal',
    maxWidth: 'fit-content',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  tabIndicator: {
    backgroundColor: '#fff',
    height: 4,
    zIndex: 2,
  },
  backgroundViewsHeaderOverlay: {
    height: 255,
    position: 'absolute',
    left: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      height: 135,
    },
    width: '100%',
    opacity: 0.5,
    background:
      'linear-gradient(to top, rgba(0,0,0,1) 10%, rgba(0,0,0,0) 100%, rgba(0,0,0,0.08) 40%, rgba(0,0,0,0) 20%)',
    zIndex: 1,
  },
  scrollButtons: {
    zIndex: 9,
    '@media(max-width: 1315px)': {
      display: 'flex',
    },
  },
  tabContainer: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
  },
  tabContainerStart: {
    justifyContent: 'flex-start',
  },
});

export default StaffHeaderStyle;
