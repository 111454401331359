import styled from 'styled-components';

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
`;

export const WrapperForm = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  width: 100%;

  p {
    margin-top: 5px;
  }
`;
