import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 85%;
  height: 500px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 80px;
  height: 100%;
`;

export const TabIndicationJob = styled.div`
  padding: 40px 16px;
  .inputFilterContainer {
    border: solid 1px #c4c4c4;
    border-radius: 2px;
    padding: 6px 8px 6px 16px;
    position: relative;
    width: fit-content;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      border: none;
      outline: none;
      font-size: 1rem;
      width: 132px;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      background-color: #009291;
      outline: none;
      border: none;
      color: #fff;
      border-radius: 2px;
      cursor: pointer;
    }
  }
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 40px 0px;
  background: #e6e6e6;
  height: 1px;
`;
