import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { bool, func, string } from 'prop-types';
import { Container } from './CoverLetterDialogStyles';
import BaseLayoutContext from 'contexts/base-layout';

const CoverLetterDialog = ({
  openCoverLetterForm,
  candidateJobOffer,
  candidateJobOfferDetails,
  id,
  isOpen,
  setClose,
}) => {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Dialog
      BackdropProps={{
        style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
      }}
      open={isOpen}
      onBackdropClick={() => setClose()}
    >
      <Container universityColor={universityColor}>
        <header>
          Carta de Apresentação
          <button
            type="button"
            onClick={() => {
              setClose();
            }}
          >
            +
          </button>
        </header>
        <p>
          Você ainda não criou a sua Carta de Apresentação, com ela você poderá
          se destacar dentre outros candidatos.
        </p>
        <div>
          <button
            type="button"
            onClick={() => {
              candidateJobOffer && candidateJobOffer(id);
              candidateJobOfferDetails && candidateJobOfferDetails();
              setClose();
            }}
          >
            CANDIDATAR ASSIM MESMO
          </button>
          <button
            type="button"
            onClick={() => {
              openCoverLetterForm();
              setClose();
            }}
          >
            QUERO CRIAR MINHA CARTA
          </button>
        </div>
      </Container>
    </Dialog>
  );
};

CoverLetterDialog.propTypes = {
  setClose: func.isRequired,
  isOpen: bool.isRequired,
  openCoverLetterForm: func.isRequired,
  candidateJobOffer: func.isRequired,
  id: string.isRequired,
};

export default CoverLetterDialog;
