import { func, number, shape } from 'prop-types';
import React from 'react';
import { FieldArray } from 'formik';

import {
  CustomFormHelperText as FormHelperText,
  OptionTextField,
  PlusButton,
} from './styles';

const OptionGenerator = ({
  questionNumber,
  setQuestions,
  questions,
  errors,
  touched,
}) => {
  const toggleOption = (value, questionNumber, optionIndex) => {
    const myQuestions = [...questions];

    myQuestions[questionNumber].options[optionIndex] = value;
    setQuestions(myQuestions);
  };

  const removeOption = (questionNumber, optionIndex, renderedOptions) => {
    const myQuestions = [...questions];
    myQuestions[questionNumber].options = myQuestions[
      questionNumber
    ].options.filter((item, index) => index !== optionIndex);

    const newRenderedOptions = renderedOptions.filter(
      (item, index) => index !== optionIndex
    );
    setQuestions(myQuestions);
    return newRenderedOptions;
  };

  let options = [];
  const optionsCounter = questions.map((question) => question.options.length);

  return (
    <FieldArray
      name={`questions.${questionNumber}`}
      render={({ form }) =>
        questions[questionNumber].options &&
        questions[questionNumber].options.length > 0 ? (
          questions[questionNumber].options.map((option, index) => (
            <div key={index}>
              <div
                key={index}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <OptionTextField
                  variant="filled"
                  name={`questions.${questionNumber}.option.${index}`}
                  id={`option${index}`}
                  placeholder={`Alternativa ${index + 1}`}
                  onChange={(event) => {
                    toggleOption(event.target.value, questionNumber, index);
                  }}
                  value={questions[questionNumber].options[index]}
                  autoFocus
                  required
                />
                <div style={{ display: 'flex', margin: '8px 28px 8px 0px' }}>
                  {optionsCounter[questionNumber] - 1 === index &&
                    optionsCounter[questionNumber] < 6 && (
                      <PlusButton
                        onClick={() => {
                          if (optionsCounter[questionNumber] < 6) {
                            const myQuestions = [...questions];
                            myQuestions[questionNumber].options.push('');
                            setQuestions(myQuestions);
                          }
                        }}
                      >
                        +
                      </PlusButton>
                    )}
                  {optionsCounter[questionNumber] > 2 && (
                    <PlusButton
                      onClick={() => {
                        if (optionsCounter[questionNumber] > 1) {
                          options = removeOption(
                            questionNumber,
                            index,
                            options
                          );
                        }
                      }}
                    >
                      -
                    </PlusButton>
                  )}
                </div>
              </div>
              {errors &&
              errors.questions &&
              errors.questions[questionNumber] &&
              errors.questions[questionNumber].options &&
              errors.questions[questionNumber].options[index] &&
              touched &&
              touched.questions &&
              touched.questions[questionNumber] &&
              touched.questions[questionNumber].options &&
              touched.questions[questionNumber].options[index] ? (
                <FormHelperText
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    marginLeft: 130,
                  }}
                >
                  {typeof errors.questions[questionNumber].options === 'string'
                    ? questions[questionNumber].options[index].trim() === ''
                      ? 'Você deve digitar a alternativa'
                      : errors.questions[questionNumber].options
                    : errors.questions[questionNumber].options[index]}
                </FormHelperText>
              ) : null}
            </div>
          ))
        ) : (
          <p>Carregando</p>
        )
      }
    />
  );
};

OptionGenerator.propTypes = {
  errors: shape().isRequired,
  setQuestions: func.isRequired,
  touched: shape().isRequired,
  questions: shape().isRequired,
  questionNumber: number.isRequired,
};

export default OptionGenerator;
