export const configMarkAsRead = {
  modalWidth: 421,
  bubbleText: 'Tudo certo!!',
  ParagraphText: `O comunicado foi marcado <br/> como lido. `,
  buttonConfirmText: 'OK, ENTENDI',
  style: {
    textAlign: 'center',
    fontWeight: '400',
    fontSize: '20px',
    sizeIconPerson: '78px',
    lineHeight: '26px'
  },
};
