import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import MaskedInput from 'react-maskedinput';
import 'views/StaffPages/Opportunities/Styles/Reactpicker.css';
import { ActionButton } from './styles';
import { addYears, getMonth, getYear } from 'date-fns';
import Select from './Select';
import { FilterType } from '../Filter';
import { useFilterContext } from '../../contexts/FilterContext';

registerLocale('ptBR', ptBR);

export function CustomDatePicker({
  name,
  placeholder,
  onChange,
  startYear = 2020,
  maxDate,
  ...rest
}) {
  const { filterState, updateFilterState } = useFilterContext();

  function range(start, end) {
    return Array.apply(start, Array(end - start + 1)).map(
      (element, index) => index + start
    );
  }

  const years = range(startYear, getYear(new Date()) + 50, 1).map((year) => {
    return { label: year, value: year };
  });

  const months = [
    { value: 'january', label: 'Janeiro' },
    { value: 'february', label: 'Fevereiro' },
    { value: 'march', label: 'Março' },
    { value: 'april', label: 'Abril' },
    { value: 'may', label: 'Maio' },
    { value: 'june', label: 'Junho' },
    { value: 'july', label: 'Julho' },
    { value: 'august', label: 'Agosto' },
    { value: 'september', label: 'Setembro' },
    { value: 'october', label: 'Outubro' },
    { value: 'november', label: 'Novembro' },
    { value: 'december', label: 'Dezembro' },
  ];

  return (
    <DatePicker
      className={'react-datepicker__input-container'}
      locale="ptBR"
      isClearable
      dateFormat="dd/MM/yyyy"
      maxDate={maxDate || addYears(new Date(), 50)}
      showDisabledMonthNavigation
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1).toUpperCase()}
      selected={filterState[name]}
      customInput={
        <MaskedInput
          placeholder="dd/MM/yyyy"
          mask="11/11/1111"
          maskstring="dd/mm/yyyy"
        />
      }
      onChange={(date) => {
        updateFilterState(date, name, FilterType.DATE_RANGE);
        onChange && onChange();
      }}
      placeholderText={placeholder}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: '10px 0',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <ActionButton
            type="button"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          />
          <Select
            options={months}
            selected={months[getMonth(date)].value}
            setValue={changeMonth}
            style={{ width: '100px' }}
          />
          <Select
            isYear
            options={years}
            selected={getYear(date)}
            setValue={changeYear}
            style={{ width: '60px' }}
            backgroundColor={'#FFF'}
          />
          <ActionButton
            type="button"
            isNext
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
        </div>
      )}
      {...rest}
    />
  );
}
