export const scrollRigthUnpublishProjects = () => {
  document.getElementById('unpublish-project-container').scrollLeft += 318;
};

export const scrollLeftUnpublishProjects = () => {
  document.getElementById('unpublish-project-container').scrollLeft += -318;
};

export const scrollRigthFinishedProjects = () => {
  document.getElementById('finished-project-container').scrollLeft += 318;
};

export const scrollLeftFinishedProjects = () => {
  document.getElementById('finished-project-container').scrollLeft += -318;
};
