import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: lightgray;
  top: 255px;
  left: 260px;
  z-index: 9999;
  padding: 50px 80px;
`;
