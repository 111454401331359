import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons'
import {Container, CloseButtonExternal, Wrapper} from '../../../Styles/BaseModalForm'

export default function GenericModalForm({children, openModal, closeModal, buttonCloseModal, zIndex, usingPreviewPdf}) {

  const {IoClose} = useIcons()

  return (
    <Container 
      usingPreviewPdf={usingPreviewPdf} 
      zIndex={zIndex} 
      openModal={openModal}
    >
      {
        buttonCloseModal && (
          <CloseButtonExternal onClick={closeModal}>
            <IoClose />
          </CloseButtonExternal>
        )
      }
      <Wrapper>
        {children}
      </Wrapper>
    </Container>
  )
}