import React, { useContext } from 'react';

import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';

import StepOneCompany from './Steps/ContentTrailCompany/StepOne'
import StepTwoCompany from './Steps/ContentTrailCompany/StepTwo';
import StepThree from './Steps/CoursesAndEventsCompany/StepThree';

export default function ContentCompany ({ setActiveTab }) {
  const { step, setStep, setFormValues } = useContext(OpportunitiesCompanyContext);

  const nextFormStep = () => {
    setStep((prevsStep) => prevsStep + 1);
  };

  const prevsFormStep = () => {
    setStep((prevsStep) => prevsStep - 1);
  };

  const handleSubmit = (data) => {
    setFormValues(data);
    nextFormStep();
  };

  return (
    <div>
      {step === 0 && (
        <StepOneCompany
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
        />
      )}
      {step === 1 && (
        <StepTwoCompany
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
        />
      )}
      {step === 2 && (
        <StepThree
          type={"Content"}
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
}
