import React from 'react';
import { ModalX, RModalBackground, RModalContainer } from './styled';

export default function ModalGeneric({ isOpen, children, setModalOpen }) {
  const setState = () => {
    setModalOpen(false);
  };

  return (
    <RModalBackground isOpen={isOpen}>
      <RModalContainer>
        <div className="content-wrapper">
         <ModalX onClick={setState} />
        </div>
        {children}
      </RModalContainer>
    </RModalBackground>
  );
}
