import React, { useState, createContext, useContext } from 'react';
import { mentorshipApi } from 'views/Student/Mentorship/services/api';

const HistoricMentorshipContext = createContext();

export const ContextProvider = ({ children }) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [
    evaluateMentorshipModalOpen,
    setEvaluateMentorshipModalOpen,
  ] = useState(false);
  const [mentorships, setMentorships] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const openDetailsModal = async (data) => {
    const mentorshipDetailsResponse = await mentorshipApi.getMentorshipDetails(
      data.id
    );
    setModalData(mentorshipDetailsResponse);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setModalData({});
  };
  const openRescheduleModal = (data) => {
    setModalData(data);
    setRescheduleModalOpen(true);
  };

  const closeRescheduleModal = () => {
    setRescheduleModalOpen(false);
  };
  const openCancelModal = (data) => {
    setModalData(data);
    setCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setCancelModalOpen(false);
  };

  const submitMentoringFeedback = async (mentorshipId, feedbackData) => {
    try {
      await mentorshipApi.mentoringFeedback(mentorshipId, feedbackData);
      return true;
    } catch (error) {
      console.error('Erro ao enviar feedback de mentoria:', error);
      throw error;
    }
  };

  return (
    <HistoricMentorshipContext.Provider
      value={{
        detailsModalOpen,
        setDetailsModalOpen,
        rescheduleModalOpen,
        setRescheduleModalOpen,
        cancelModalOpen,
        setCancelModalOpen,
        modalData,
        setModalData,
        openDetailsModal,
        closeDetailsModal,
        openRescheduleModal,
        closeRescheduleModal,
        openCancelModal,
        closeCancelModal,
        evaluateMentorshipModalOpen,
        setEvaluateMentorshipModalOpen,
        submitMentoringFeedback,
        mentorships,
        setMentorships,
        loading,
        setLoading,
        page,
        setPage,
      }}
    >
      {children}
    </HistoricMentorshipContext.Provider>
  );
};

export function useHistoricMentorshipContext() {
  return useContext(HistoricMentorshipContext);
}
