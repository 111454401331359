import React, { useState } from 'react';
import { ContainerExternalCards } from '../styled';

import GenericCardsJobOffersWithStatus from '../CardsWithStatus';
import GenericMessage from 'components/GenericMessage';
import {
  ContainerButtonsPagination,
  ButtonPaginationSeeMore,
} from '../../styled';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import { DetailsJobOffer } from '../../JobDetails';

const FinishedJobCards = ({
  universityColor,
  listFinishedJobs,
  fetchListFinishedJobOffers,
}) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dataFinishedJobOffers = listFinishedJobs?.data?.results.results;
  const [selectedJobId, setSelectedJobId] = useState(null);

  const handleMoreItems = async () => {
    try {
      setIsLoading(true);
      const query = listFinishedJobs.data.next.split('?');
      await fetchListFinishedJobOffers(query[1], true);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickDetails = (jobId) => {
    setSelectedJobId(jobId);
    setOpenDetailsModal(true);
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };

  const returnCardJobs = (dataFinishedJobOffers) => {
    return dataFinishedJobOffers?.map((job) => (
      <div isOnGoing key={job}>
        <GenericCardsJobOffersWithStatus
          name={job.job_offer.name}
          job={job.job_offer}
          companyName={job.job_offer.company_name}
          universityColor={universityColor}
          location={
            job.job_offer.location ? job.job_offer.location : 'Não informado'
          }
          returnLabelFinished={'Detalhes da vaga'}
          statusFinished={job.status}
          onClickButton={() => onClickDetails(job.id)}
          isFinishedJobs={true}
          feedback={job.feedback}
          feedbackName={job.feedback_user_name}
          feedbackFinished={job.finished_at}
          code={job.job_offer.id}
        />
      </div>
    ));
  };

  return (
    <>
      {dataFinishedJobOffers?.length === 0 ? (
        <GenericMessage
          title="Nenhuma vaga finalizada."
          subtitle="No momento você não possui nenhuma vaga finalizada"
        />
      ) : (
        <>
          <ContainerExternalCards>
            <div>{returnCardJobs(dataFinishedJobOffers)}</div>
          </ContainerExternalCards>

          <ContainerButtonsPagination style={{ marginTop: '20px' }}>
            {listFinishedJobs?.data?.next && (
              <ButtonPaginationSeeMore
                universityColor={universityColor}
                onClick={handleMoreItems}
              >
                {isLoading ? (
                  <FilterLoading width={'88px'} height={'16px'} />
                ) : (
                  'Ver mais'
                )}
              </ButtonPaginationSeeMore>
            )}
          </ContainerButtonsPagination>

          {openDetailsModal && (
            <DetailsJobOffer
              handleCloseModal={handleCloseModal}
              universityColor={universityColor}
              jobId={selectedJobId}
              isApplication={true}
            />
          )}
        </>
      )}
    </>
  );
};

export default FinishedJobCards;
