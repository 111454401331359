import { defaultButton } from 'assets/jss/material-dashboard-pro-react';

import {
  yellowIE,
  redIE,
  greenIE,
  darkBlueIE,
  blueIE,
  lightBlueIE,
  dnaGreen,
  orangeIE,
} from 'assets/jss/material-dashboard-pro-react';

const ProfileHeaderStyle = (theme) => ({
  dnaColorbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: dnaGreen,
    position: 'absolute',
    zIndex: 1,
  },

  yellowIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: yellowIE,
    position: 'absolute',
    zIndex: 1,
  },

  redIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: redIE,
    position: 'absolute',
    zIndex: 1,
  },

  greenIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: greenIE,
    position: 'absolute',
    zIndex: 1,
  },

  darkBlueIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: darkBlueIE,
    position: 'absolute',
    zIndex: 1,
  },

  blueIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: blueIE,
    position: 'absolute',
    zIndex: 1,
  },

  lightBlueIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: lightBlueIE,
    position: 'absolute',
    zIndex: 1,
  },

  orangeIESbackgroundOverlay: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '105%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    width: '100%',
    backgroundColor: orangeIE,
    position: 'absolute',
    zIndex: 1,
  },

  backgroundViewsHeaderOverlay: {
    height: 255,
    position: 'absolute',
    left: 0,
    top: 0,
    [theme.breakpoints.down('sm')]: {
      height: 135,
    },
    width: '100%',
    background:
      'linear-gradient(to top, rgba(0,0,0,1) 7%, rgba(0,0,0,0) 30%, rgba(0,0,0,0.08) 40%, rgba(0,0,0,0) 75%)',
    zIndex: 1,
  },
  profileHeaderBackground: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
    },
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center 50%',
    backgroundColor: '#009291',
    filter: 'opacity(.9) blur(15px)',
  },
  viewHeaderBackground: {
    height: 255,
    [theme.breakpoints.down('sm')]: {
      height: 135,
      backgroundSize: '160%',
    },
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 20%',
    backgroundColor: '#009291',
  },
  profileHeaderHolder: {
    height: 'auto',
    margin: '0 0 40px',
    position: 'relative',
    top: 'auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: 255,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
      minHeight: 135,
    },
  },
  profileHeaderMask: {
    width: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
    overflow: 'hidden',
  },
  studentInfo: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    color: '#fff',
    margin: '0 35px',
    width: '100%',
  },
  studentInfoContainerNameAndButton: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: 14,
    },
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontSize: 17,
    fontWeight: 400,
  },
  nameAndButtonMobile: {
    color: 'black',
    display: 'flex',
    fontWeight: 400,
    fontSize: 14,
    marginTop: '15px',
    ['@media (max-width:400px)']: {
      marginLeft: '25px',
    },
    '& li': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  studentInfoContainerProfessionalStatus: {
    [theme.breakpoints.down('sm')]: {
      color: 'black',
      margin: '5px 25px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      fontSize: '14px',
    },
    display: 'flex',
    alignItems: 'baseline',
    flexDirection: 'row',
    justifyContent: 'start',
    fontSize: '17px',
    fontWeight: '400',
    margin: 5,
  },

  studentCityState: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: '1px solid #009291',
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: '-1px 4px 5px 0px rgb(0 146 145 / 50%)',
      fontWeight: '750',
    },
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: '#009291',
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  dnaColorEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${dnaGreen}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: dnaGreen,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  darkBlueIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${darkBlueIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: darkBlueIE,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  blueIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${blueIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: blueIE,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  lightBlueIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${lightBlueIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: lightBlueIE,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  orangeIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${orangeIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: orangeIE,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  yellowIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${yellowIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: yellowIE,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  redIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${redIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    display: 'flex',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: redIE,
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  greenIESEditInformation: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      border: `1px solid ${greenIE}`,
      padding: '10px 15px',
      fontSize: '14px',
      boxShadow: `-1px 4px 5px 0px #9eadc3`,
      fontWeight: '750',
    },
    alignItems: 'center',
    gap: '7px',
    textTransform: 'uppercase',
    border: '2px solid #fff',
    backgroundColor: '#fff',
    borderRadius: '5px',
    color: greenIE,
    display: 'flex',
    padding: '5px 10px',
    fontWeight: '500',
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    '& img': {
      width: 16,
      height: 16,
      marginRight: 5,
      marginTop: -2,
    },
  },

  studentTravelAndChange: {
    marginLeft: 22,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  studentName: {
    [theme.breakpoints.down('sm')]: {
      color: dnaGreen,
      fontSize: '35px',
      justifyContent: 'center',
    },
    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  dnaColorStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: dnaGreen,
      fontSize: '35px',
      justifyContent: 'center',
    },
    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  yellowIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: yellowIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  redIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: redIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  greenIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: greenIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  darkBlueIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: darkBlueIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  blueIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: blueIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  lightBlueIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: lightBlueIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  orangeIESStudentName: {
    [theme.breakpoints.down('sm')]: {
      color: orangeIE,
      fontSize: '35px',
      justifyContent: 'center',
    },

    display: 'flex',
    marginBottom: 0,
    fontWeight: '700',
    fontSize: '2.5em',
  },

  pageTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
      justifyContent: 'center',
      marginTop: 20,
      marginLeft: 20,
      display: 'inline-block',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    color: '#fff',
    fontSize: 35,
    display: 'flex',
    marginBottom: 0,
    marginTop: 65,
    fontWeight: 400,
  },
  icoEditAvatar: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    position: 'absolute',
    right: 15,
    bottom: 0,
    zIndex: 1,
  },
  editAvatar: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      position: 'absolute',
      top: '11%',
      right: '29%',
      width: '190px',
    },
    width: 226,
    height: 190,
    right: 26,
    borderRadius: '50%',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginRight: -202,
    },
  },
  avatar: {
    width: 235,
    height: 190,
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      width: 190,
      height: 190,
    },
    overflow: 'hidden',
    borderRadius: '50%',
    boxShadow:
      '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  professionalStatus: {
    display: 'flex',
    flexDirection: 'column',
    left: '215px',
    position: 'absolute',
    top: '155px',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      justifyContent: 'center',
      left: 'auto',
      position: 'relative',
      textAlign: 'center',
      top: 'auto',
    },
  },
  statusItem: {
    color: '#b1b1b1',
    textTransform: 'capitalize',
    marginRight: '1.2em',
    padding: '.6em',
    whiteSpace: 'nowrap',
  },
  active: {
    color: '#1cb45e',
  },
  icon: {
    marginRight: '.8em',
  },
  iconEmployed: {
    marginRight: '.8em',
    maxWidth: '32px',
  },
  professionalStatusContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  subDescription: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      bottom: '20%',
    },
  },
  iconFlight: {
    marginRight: 7,
  },
  btnConfirmVacany: {
    width: '250px',
    height: '36px',
    color: '#009291',
    border: '2px solid #009291',
    borderRadius: '200px',
    fontSize: '0.875rem',
  },
});

export default ProfileHeaderStyle;
