import React from 'react';
import { ModalX, RModalBackground, RModalContainer } from '../../Styles/Styled';

export default function RModal({ isOpen, children, setModalOpen }) {
  const setState = () => {
    setModalOpen(false);
  };

  return (
    <RModalBackground isOpen={isOpen}>
      <RModalContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'center',
            positon: 'static',
            top: '20px',
          }}
        >
          <ModalX onClick={setState} />
        </div>

        {children}
      </RModalContainer>
    </RModalBackground>
  );
}
