import React from 'react';
import { IconWrapper, ItemWrapper, TruncatedLink } from './styled';

const DetailsItem = ({ icon, label, value, valueNew }) => (
  <ItemWrapper>
    <div className="container">
      <IconWrapper>{icon}</IconWrapper>
      <div className="text-wrapper">
        <strong>{label}</strong>
        {valueNew ? (
          <div className="value-wrapper">
            <p>
              <strong>Antigo:</strong>
              <span>{value}</span>
            </p>
            <p>
              <strong>Novo:</strong> {valueNew}
            </p>
          </div>
        ) : (
          <p>
            {label === 'Online' && value ? (
              <TruncatedLink
                href={value}
                target="_blank"
                rel="noopener noreferrer"
              >
                {value}
              </TruncatedLink>
            ) : (
              value
            )}
          </p>
        )}
      </div>
    </div>
  </ItemWrapper>
);

export default DetailsItem;
