import React, { useState, useContext } from 'react';
import {
  Wrapper,
  ContainerTypeName,
  ContainerActions,
  PopUp,
  Status,
} from './style';

import ModalFeedbackExtensionProject from '../../ModalViewFeedback';
import moment from 'moment';
import feedback from 'assets/ExtensionProject/feedback.svg';

import { LinkIcon } from 'assets/ComponentIcons/evidencesIcon';
import BaseLayoutContext from 'contexts/base-layout';
import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';

export default function ListLinks({
  handleDeleteLink,
  item,
  handleClickEdit,
  setModalInformationConfig,
  setModalInformationOpen,
}) {
  const { name, link } = item;

  const { universityColor } = useContext(BaseLayoutContext);

  const [openFeedBack, setOpenFeedBack] = useState(false);

  const handleConfirmDelete = () => {
    handleDeleteLink(item);
    setModalInformationOpen(false);
  };

  const configDelete = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza que deseja excluir a evidência "${name}"?`,
    buttonConfirmText: 'SIM, EXCLUIR EVIDÊNCIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px'
    },
    buttonConfirmFunction: handleConfirmDelete,
  };

  const handleClickDelete = () => {
    setModalInformationConfig(configDelete);
    setModalInformationOpen(true);
  };

  const canUseActions = item.analyze ? false : true;
  const hasFeedBack =
    item.analyze &&
    Object.keys(item.analyze).length > 0 &&
    item.analyze.feedback !== '';

  const colors = {
    refused: {
      label: 'RECUSADO',
      color: '#DA0505',
      background: '#FFEDED',
    },
    accepted: {
      label: 'ACEITO',
      color: '#068E2C',
      background: '#D9FFE3',
    },
    isNull: {
      label: 'AGUARDANDO ANÁLISE',
      color: '#E9B600',
      background: '#FFFDED',
    },
  };

  const deleteIcon = () => {
    if (canUseActions) {
      return (
        <button onClick={handleDeleteLink}>
          <PopUp label="Excluir" universityColor={universityColor}>
            <DefaultTrash size={19} color={universityColor} />
          </PopUp>
        </button>
      );
    }
  };

  const seeFeedback = () => {
    if (hasFeedBack) {
      return (
        <PopUp label="visualizar feedback">
          <img
            src={feedback}
            alt="feedback"
            onClick={() => setOpenFeedBack(true)}
          />
        </PopUp>
      );
    }
  };

  return (
    <Wrapper>
      {openFeedBack && (
        <ModalFeedbackExtensionProject
          isOpen={openFeedBack}
          setOpenModalFeedback={setOpenFeedBack}
          feedback={item.analyze?.feedback}
        />
      )}
      <ContainerTypeName>
        <LinkIcon color={universityColor} size={22} />
        <div>
          <div className="nameEvidence">
            <a href={link} target="_blank">
              {name}
            </a>
            {item.analyze ? (
              <Status
                color={colors[item.analyze.status].color}
                background={colors[item.analyze.status].background}
              >
                {colors[item.analyze.status].label}
              </Status>
            ) : (
              <Status
                color={colors['isNull'].color}
                background={colors['isNull'].background}
              >
                {colors['isNull'].label}
              </Status>
            )}
          </div>
          <p className="date">
            Enviado dia
            <span>
              {moment(item.created_at).format('DD/MM/YYYY')} às{' '}
              {moment(item.created_at).format('H:mm ')}
            </span>
          </p>
        </div>
      </ContainerTypeName>
      <ContainerActions>
        {deleteIcon()}
        {seeFeedback()}
      </ContainerActions>
    </Wrapper>
  );
}
