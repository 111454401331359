import { getInternshipAuthData } from 'services/internship';
import { publicInternshipAPI } from 'services/api';

const authInternship = async () => await getInternshipAuthData();

async function authValidate(token) {
  const response = await publicInternshipAPI.post(
    `api/accounts/token-login/`,
    token
  );
  return response.data;
}

export { authInternship, authValidate };
