export const configHasStudents = (
  count,
  listName,
  actions,
  filter,
  dataMounted,
  isEdit,
  id
) => {
  const { cancel, confirm, edit } = actions;

  const singularWords = count > 1 ? 'e-mails segmentados' : 'e-mail segmentado';

  const currentFunction = isEdit ? edit : confirm;

  return {
    modalHeight: 330,
    bubbleText: 'Atenção!',
    ParagraphText: `Os filtros aplicados resultaram em
    <strong>${count}</strong> ${singularWords}. Tem certeza que deseja gerar a lista <strong>${listName}</strong>?`,
    buttonConfirmText: 'SIM, GERAR LISTA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    buttonConfirmFunction: () => currentFunction(filter, dataMounted, id),
    buttonCancelFunction: cancel,
  };
};

export const configNoHasStudents = (count, listName, actions) => {
  const { confirmError } = actions;

  return {
    modalHeight: 330,
    bubbleText: 'Oops!',
    ParagraphText: `Os filtros aplicados resultaram em
    <strong>${count}</strong> estudantes segmentados. Então não é possível gerar a lista <strong>${listName}</strong>.`,
    buttonConfirmText: 'OK, ENTENDI',
    buttonConfirmFunction: confirmError,
  };
};
