import styled from 'styled-components';

export const HeaderInformationSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 14px;
`;

export const Informations = styled.div`
  max-width: 590px;

  h5 {
    color: #009291;
    font-weight: 500;
    font-size: 22px;
    }

    p {
      font-size: 15px;
      color: #606062;
    }
 }
`;

export const SelectCourse = styled.div`
  width: 250px;

  > form > div {
    .css-1pcexqc-container {
      .css-bg1rzq-control {
        max-height: 35px;
        min-height: 35px;
        border: none;
      }
    }
  }
`;
