import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const TalentItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px #cdcdcd solid;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 40px 10px 40px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  & + & {
    margin-top: 20px;
  }

  .row {
    display: flex;
    padding: 0px;
    flex-direction: row;
  }

  .firstColumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .secondColumn {
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding-bottom: 10px;
    width: 200px;
  }

  h3.name {
    font-size: 18px;
    line-height: 25px;
    font-weight: 400;
    margin: 0px 0px 14px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;

    b {
      font-weight: 800;
    }
  }
  p.highlight {
    font-weight: 700;
    color: #009291;
  }
`;

export const SkeletonDiv = styled.div`
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;
