import React, { Fragment, useContext, useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import JobHeaderTab, {
  Container,
  FilterContainer,
  ButtonCreate,
} from './style';
import FilterModal from '../../../../../../../components/FilterModal';
import {
  Filter,
  FilterType,
} from '../../../../../../../components/FilterModal/components/Filter';
import { MatrixContext } from '../../contexts/MatrixContext';
import { getSubjectsCourse } from '../../services/matrixServices';
import ModalInformation from '../informationModal';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

const InsideTabs = (props) => {
  const { classes, activeTab, handleTabChange, tabs, openSnackbar } = props;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const {
    setModalIsOpenName,
    selectedMatrices,
    reactivateSelectedMatrices,
    setResetDisabledMatrices,
    filterOptions,
    appliedFilters,
    setAppliedFilters,
    refreshCourseSubjects,
    filterState,
    setFilterState,
    selectedCourse,
    getCourseSubjects,
    setTotalSubjects,
    setCurrentPageDisciplines,
    setLimitDisciplines,
    setSubjectsCourse,
  } = useContext(MatrixContext);

  const configModalConfirmReactivate = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja reativar as matrizes selecionadas?`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
    modalHeight: 315,
  };

  const handleClickCreateMatrix = () => {
    setModalIsOpenName('registerMatrix');
  };

  const reactivateMatrices = async () => {
    const response = await reactivateSelectedMatrices();
    if (response.status === 201) {
      openSnackbar('Matrizes reativadas com sucesso.');
      setResetDisabledMatrices(true);
    } else {
      openSnackbar('Erro ao reativar matrizes selecionadas.', true);
    }
    setConfirmModalOpen(false);
  };

  const handleClickReactivate = async () => {
    setConfirmModalOpen(true);
  };

  useEffect(() => {
    refreshCourseSubjects();
  }, [appliedFilters]);

  const handleApplyFilters = (newState) => {
    setFilterState(newState);
    setAppliedFilters(newState);
  };

  const handleCleanFilters = async () => {
    setAppliedFilters(filterState);
    const { count, results } = await getSubjectsCourse(
      selectedCourse,
      'limit=20'
    );
    setSubjectsCourse(results);
    setTotalSubjects(count);
    setCurrentPageDisciplines(1);
    setLimitDisciplines(20);
  };

  return (
    <Fragment>
      <ModalInformation
        modalOpenState={confirmModalOpen}
        config={configModalConfirmReactivate}
        buttonConfirmFunction={reactivateMatrices}
        buttonCancelFunction={() => setConfirmModalOpen(false)}
      />
      <Container>
        <AppBar position="static" className={classes.appBarHolder}>
          {tabs && (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              scrollButtons="on"
              scrollable
              classes={{
                indicator: classes.tabIndicator,
                scrollButtons: classes.scrollButtons,
                flexContainer: classes.borderContainer,
              }}
            >
              {tabs.map((tabName) => (
                <Tab
                  style={{ zIndex: 2 }}
                  key={tabName}
                  label={tabName}
                  className={classes.tabButton}
                />
              ))}
            </Tabs>
          )}
          {activeTab === 0 ? (
            <FilterContainer>
              <FilterModal
                filterState={filterState}
                setFilterState={setFilterState}
                width="600px"
                marginLeft="-435"
                onClean={handleCleanFilters}
                appliedFilters={appliedFilters}
                setAppliedFilters={handleApplyFilters}
              >
                <Filter
                  name={'disciplines'}
                  label={'Disciplinas'}
                  type={FilterType.RADIO}
                  options={filterOptions}
                />
              </FilterModal>
              <ButtonCreate onClick={handleClickCreateMatrix}>
                + CRIAR NOVA MATRIZ
              </ButtonCreate>
            </FilterContainer>
          ) : (
            <FilterContainer>
              <ButtonCreate
                disabled={!selectedMatrices || selectedMatrices.length === 0}
                onClick={handleClickReactivate}
              >
                Reativar matrizes
              </ButtonCreate>
            </FilterContainer>
          )}
        </AppBar>
      </Container>
    </Fragment>
  );
};

InsideTabs.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};

export default withStyles(JobHeaderTab)(InsideTabs);
