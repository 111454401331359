import React, { useEffect, useRef } from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';
import { ContainerLabelInput, UnInputLabel } from './style';
import { useState } from 'react';

export default function SearchableSelectUnAllValues({
  label,
  name,
  options,
  placeholder,
  default: haveDefault,

  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return Array.isArray(ref.state.value)
            ? ref.state.value.map((option) => option)
            : [ref.state.value].map((option) => option);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value;
        }
      },
      setValue(ref, value) {
        const valor = defaultValue.find(
          (option) => String(option.value) === String(value)
        );
        ref.onChange(valor);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <Select
        defaultValue={
          defaultValue && Array.isArray(defaultValue)
            ? defaultValue.map(
                (item) =>
                  options &&
                  options.find(
                    (option) =>
                      option.value === item || option.value === item.value
                  )
              )
            : options &&
              options.find(
                (option) =>
                  String(option.value) === String(defaultValue) ||
                  (defaultValue &&
                    String(option.value) === String(defaultValue.value))
              )
        }
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      <p style={{ color: '#f00', marginTop: '10px' }}>{error}</p>
    </ContainerLabelInput>
  );
}
