import styled from 'styled-components';

export const MainContainer = styled.div`
  margin: 37px 70px;
  border-radius: 4px;
  box-shadow: 0px 0px 17.8px 0px #0000001a;
  background-color: #fff;
`;

export const Header = styled.div`
  border-bottom: 1px solid #d9d9d9;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
`;

export const Content = styled.div`
  padding: ${({ removePd }) => (removePd ? '0px' : '37px 21px')};
`;

export const BoxMain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
`;

export const BoxContent = styled.div`
  background-color: #f6f6f6;
  padding: 39px 45px;

  margin: 30px 19px;
  width: 100%;
  border-radius: 2px;
`;

export const Title = styled.strong`
  color: #009291;
  font-size: 20px;
`;

export const SubtitleFilter = styled.p`
  color: #606062;
  font-size: 16px;
  font-weight: 400;
  margin-top: 5px;
`;

export const DataContent = styled.div`
  margin-top: 30px;
`;

export const GridArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 20px;
`;

export const GridAreaJobs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 20px;
`;

export const DataArea = styled.div`
  display: flex;
  justify-content: space-between;

  max-width: 100%;
  padding: ${({ padding }) => padding || '14px'};
  background-color: #fff;

  .data-side {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    width: ${({ skeleton }) => skeleton && '80%'};
    gap: ${({ skeleton }) => skeleton && '7px'};
  }

  .data-side p {
    color: #949494;
    font-size: 16px;
    margin: 0;
  }

  .data-side strong {
    color: #606062;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    margin-top: 4px;
  }

  .icon-side {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ skeleton }) => skeleton && '20%'};
    margin-left: 5px;
  }

  .value-information {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .value-information svg {
    margin-bottom: -4px;
  }

  .background-icon {
    padding: 10px;
    border-radius: 4px;
    background-color: #00929117;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const DataAreaBig = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 15px;

  max-width: 100%;
  padding: ${({ padding }) => padding || '26px'};
  background-color: #fff;

  .data-side {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    width: ${({ skeleton }) => skeleton && '80%'};
    gap: ${({ skeleton }) => skeleton && '7px'};
  }

  .data-side p {
    color: #949494;
    font-size: 16px;
    font-weight: 500;

    span {
      margin-left: 5px;
      margin-top: 2px;
    }

    display: flex;
    @media (min-width: 1800px) {
      font-size: 22px;

      span {
        margin-top: 0px;
      }
    }
  }

  .data-side strong {
    color: #009291;
    font-size: 28px;
    font-weight: 700;
  }

  .icon-side {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ skeleton }) => skeleton && '20%'};
  }

  .value-information {
    display: flex;
    align-items: center;
    gap: 7px;
  }

  .value-information svg {
    margin-bottom: -4px;
  }

  .background-icon {
    padding: 10px;
    border-radius: 4px;
    background-color: #00929117;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const LoadingStyle = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ExportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-left: 20px;

  color: #fff;
  font-size: 15px;
  font-weight: 400;

  border: none;
  border-radius: 3px;
  background-color: #009291;

  width: 190px;
  height: 34px;

  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #037474;
    transition: 0.2s;
  }

  &:disabled {
    cursor: default;
  }
`;

export const Alert = styled.p`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  font-size: 12px;
  align-items: center;
  gap: 5px;
  margin-top: -11px;
  margin-bottom: -5px;
  margin-left: 5px;
  height: 20px;
`;

export const SpanError = styled.p`
  display: ${({ display }) => (display ? 'flex' : 'none')};
  font-size: 12px;
  text-align: start;
  color: #f00;
  margin-left: 22px;
`;

export const AlertDiv = styled.div`
  display: flex !important;
  gap: 0px !important; 
  flex-direction: column !important;
  height: 18px;
`;
