import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 730px;
  border-radius: 12px;
  background: #ffffff;
  position: relative;
  min-height: 350px;
  max-height: auto;
  height: auto;
`;

export const Header = styled.div`
  background-color: #009291;
  height: 90px;
  width: 100%;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ContainerInformations = styled.div`
  padding: 10px 30px;
`;

export const Title = styled.h4`
  color: #009291;
  font-weight: 500;
`;

export const HeaderInformation = styled.h4`
  color: #009291;
  font-weight: 500;
  color: #606062;

  > p {
    font-weight: 300;
    font-size: 15px;
  }
`;
