import React, { useEffect, useRef, useContext } from 'react';
import { useField } from '@unform/core';
import {
  ChoiceInput,
  ChoicesCard,
  ChoicesContainer,
  TextChoice,
} from '../../Styles/formStyled';
import { OportunitiesContext } from '../../Context/OportunitiesContext';

const Radio = ({ name, options, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error, defaultValue = '' } = useField(name);
  const { optionsId, setOptionsId, formData } = useContext(OportunitiesContext);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,

      getValue: (refs) => {
        return (
          (refs.find((ref) => ref.checked) &&
            refs.find((ref) => ref.checked).value) ||
          ''
        );
      },
      setValue: (refs, id) => {
        const inputRef = refs.find((ref) => ref.id === id);
        if (inputRef) inputRef.checked = true;
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const handleInputChange = (e) => {
    setOptionsId(e);
  };

  useEffect(() => {
    if (defaultValue) {
      setOptionsId(defaultValue);
    }
  }, [defaultValue]);

  return (
    <ChoicesContainer>
      {options.map((option, index) => (
        <ChoicesCard
          key={option.id}
          imageOne={option.imageOne}
          imageTwo={option.imageTwo}
          checked={optionsId === option.id}
        >
          <ChoiceInput
            onClick={() => handleInputChange(option.id)}
            ref={(ref) => ref && (inputRefs.current[index] = ref)}
            id={option.id}
            type="radio"
            name={fieldName}
            defaultChecked={formData && formData.type === option.id}
            value={option.value}
            {...rest}
          />
          <TextChoice checked={optionsId === option.id}>
            {option.label}
          </TextChoice>
        </ChoicesCard>
      ))}
    </ChoicesContainer>
  );
};

export default Radio;
