import styled from 'styled-components';

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  z-index: 9999;
  overflow: auto;
`;

export const ContentWrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  margin: 10px auto;
  max-width: 800px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Helper = styled.div`
  position: relative;
  bottom: 300px;
  left: 680px;
  max-width: 205px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  color: #009291;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;

  .alert-dialog {
    padding: 0px 5px;
    bottom: 33px;
    display: block;
    z-index: 10;
    min-width: 80px;
    text-align: center;
    height: auto;
    background-color: #009291;
    position: absolute;
    color: #000;
    border: #009291 solid 1px;
    border-radius: 4px;
    font-weight: 500;

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 14px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: 39px;
      left: -14px;
      transform: scaleX(-1) rotate(90deg);
    }

    span {
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  @media (min-width: 1200px) {
    bottom: 300px;
    left: 680px;
  }

  @media (min-width: 1300px) {
    bottom: 300px;
    left: 725px;
  }

  @media (min-width: 1400px) {
    bottom: 300px;
    left: 760px;
  }

  @media (min-width: 1900px) {
    bottom: 300px;
    left: 1000px;
  }

  @media (min-width: 2500px) {
    bottom: 300px;
    left: 1320px;
  }
`;

export const HelperTwo = styled(Helper)`
  bottom: 170px;
  left: 620px;
  max-width: 240px;

  @media (min-width: 1200px) {
    bottom: 170px;
    left: 620px;
  }

  @media (min-width: 1300px) {
    bottom: 170px;
    left: 665px;
  }

  @media (min-width: 1400px) {
    bottom: 168px;
    left: 703px;
  }
  @media (min-width: 1900px) {
    bottom: 167px;
    left: 941px;
  }

  @media (min-width: 2500px) {
    bottom: 167px;
    left: 1262px;
  }
`;
