import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  padding-top: 25px;
  margin-top: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(39, 70, 132, 0.2);
  background: #fff;
  overflow: hidden;
`;

export const CardTitle = styled.h2`
  margin-bottom: 40px;
`;

export const CurrentValueContainer = styled.div`
  width: 100%;
`;

export const SliderContainer = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 90%;
  height: 5px;
  background: #eef2f4;
  border-radius: 5px;
  cursor: pointer;
`;

export const SliderHandle = styled.div`
  position: absolute;
  top: 0;
  height: 5px;
  border-radius: 5px;
  background: ${({ handleColor }) => handleColor};
  cursor: pointer;

  &:hover::before {
    opacity: 1;
  }

  &:hover::after {
    opacity: 1;
  }

  div {
    position: absolute;
    right: -1.5px;
    top: -10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 1px 3px 7px 0px #00000040;
  }

  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`;

export const MinSliderHandle = styled(SliderHandle)`
  z-index: 2;
`;

export const MaxSliderHandle = styled(SliderHandle)`
  z-index: 1;
`;

export const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 240px;
`;

export const TextRange = styled.input`
  width: 100%;
  max-width: 240px;
  height: 34px;
  padding: 13px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 14px;
  color: #606062 !important;
  margin-bottom: 17px;

  :disabled {
    background-color: #fff;
  }
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
  gap: 8px;

  label {
    font-size: 14px;
    color: #606062;
    font-weight: 400;
    display: flex;
    margin-top: -17px;
  }

  svg {
    margin-top: -17px;
  }
`;

export const ContainerMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;

export const WrapperInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  margin: 13px 0 16px 0;
`;

export const ErrorMessage = styled.div`
  margin: 0 0 12px;

  p {
    margin: 0;
    color: #da0505;
    font-size: 12px;
  }
`;
