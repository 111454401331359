import styled from 'styled-components';

export const ModalContainer = styled.div`
  width: 100%;
  height: 200%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10000;
`;

export const Header = styled.div`
  padding: 30px;
  text-align: right;

  button {
    border: 1px solid transparent;
    border-radius: 500px;
    padding: 12px 15px;
    background-color: transparent;
    transition: 0.2s linear;
    cursor: pointer;
    font-size: 24px;
    font-weight: bold;

    :hover {
      background-color: rgba(50, 50, 50, 0.2);
    }
  }
`;

export const Body = styled.div``;
