import React, { useState, useEffect, Fragment } from 'react';

import PageOneChallenges from './PageOneChallenges';
import ChallengesPageTwo from './PageTwoChallenges';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';

import challengeBackground from 'assets/img/challengeBackground.png';

import { func } from 'prop-types';
import { getChallenges } from './services/challenges';

const Challenges = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [cacheOptions, setCacheOptions] = useState([]);
  const [challenges, setChallenges] = useState(null);
  const [modalInfoOpen, setModalInfoOpen] = useState(false);
  const [count, setCount] = useState(0);

  const setFormValues = (values) => {
    setFormData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  const nullChallenges = () => {
    setChallenges(null);
    loadChallenges(`limit=24`);
  };

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const loadChallenges = async (pages) => {
    const response = await getChallenges(pages);
    const { results, count } = response;
    setChallenges(results);
    setCount(count);
  };

  useEffect(() => {
    if (isOpenModal === false) {
      setFormData({});
      setCurrentStep(1);
      setIsOpenModal(false);
      setCacheOptions([]);
    }
  }, [isOpenModal]);

  useEffect(() => {
    nullChallenges();
  }, [activeTab]);

  const renderizeTab = (step) => {
    if (step === 0) {
      return (
        <PageOneChallenges
          setActiveTab={setActiveTab}
          isOpenModal={isOpenModal}
          activeTab={activeTab}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          formData={formData}
          setFormData={setFormData}
          setFormValues={setFormValues}
          cacheOptions={cacheOptions}
          setCacheOptions={setCacheOptions}
          setIsOpenModal={setIsOpenModal}
          loadChallenges={loadChallenges}
          setModalInfoOpen={setModalInfoOpen}
          setChallenges={setChallenges}
        />
      );
    }
    if (step === 1) {
      return (
        <ChallengesPageTwo
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
          formData={formData}
          setFormData={setFormData}
          setFormValues={setFormValues}
          cacheOptions={cacheOptions}
          setCacheOptions={setCacheOptions}
          setIsOpenModal={setIsOpenModal}
          isOpenModal={isOpenModal}
          challenges={challenges}
          count={count}
          loadChallenges={loadChallenges}
          setChallenges={setChallenges}
          modalInfoOpen={modalInfoOpen}
          setModalInfoOpen={setModalInfoOpen}
        />
      );
    }
  };

  return (
    <Fragment>
      <CompanyHeader
        title="Desafios"
        handleTabChange={handleTabChange}
        activeTab={activeTab}
        backgroundImage={challengeBackground}
        tabs={['Cadastro', 'Histórico']}
      />
      {renderizeTab(activeTab)}
    </Fragment>
  );
};

Challenges.propTypes = {
  openModalWithContent: func,
};

export default Challenges;
