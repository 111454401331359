import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h1`
  color: #009292;
  font-size: 24px;
  text-align: center;
  font-weight: 450;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2%;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin: 0 0 0 10px;

  strong {
    color: #009291;
  }
  p {
    font-size: 12px;
  }
`;
export const Divider = styled.div`
  background-color: #efeeec;
  height: 1px;
  width: 100%;
`;
const shimmer = keyframes`
  0% {
    background-position: 0% 0%
  }
  100% {
    background-position: -135% 0%
  }
`;

export const SkeletonAnimation = styled.div`
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  animation: ${shimmer} 1.9s ease-in-out infinite;
`;

export const SendButton = styled.button`
  width: 100%;
  margin: 0 auto;
  text-transform: uppercase;
  min-width: 60px;
  height: 40px;
  background-color: #009291;
  border-radius: 200px;
  font-size: 14px;
  color: white;
  border: 1px solid transparent;
  cursor: pointer;
  :disabled {
    background-color: gray;
    cursor: default;
  }
`;
