export const PublicationFormat = [
  {
    id: 'access_link',
    value: 'access_link',
    label: 'Divulgação do Link de acesso',
  },
  {
    id: 'attraction_document',
    value: 'attraction_document',
    label: 'Upload de documento PDF',
  },
  {
    id: 'video',
    value: 'video',
    label: 'Vídeos sequencias',
  },
];
