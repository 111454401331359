import React from 'react';
import {
  ContainerPagination,
  ButtonPagination,
  ContainerLoading,
} from './styles';

export function PaginationEmails({onClick}) {
  return (
  <ContainerPagination>
    <ButtonPagination onClick={onClick}>
      Ver mais
    </ButtonPagination>
  </ContainerPagination>
  );
}

export function LoadEmails() {
  return (
    <ContainerLoading>
      <p>
        Carregando...
      </p>
    </ContainerLoading>
  );
}
