import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 100%;
  min-height: 250px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d8d7d7;
  padding: 10px;
`;

export const ContainerProgress = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 20px;
  height: 100%;
  flex-wrap: wrap;
`;

export const H5 = styled.h5`
  font-weight: bold;
  margin-left: 10px;
`;

export const HelpText = styled.p`
  margin-top: 30px;
  margin-bottom: 15px;
  color: #4b4b4d;
  size: 10px;
  margin-left: 10px;
`;
