import styled, { keyframes } from "styled-components";

const girateAnimation = keyframes`
  0%{transform: rotate(0)}
  100%{transform: rotate(360deg)}
`;
export const ParagraphLoading = styled.p`
  color: #5a4c4c;
  font-size: 16px;
  position: relative;
  font-weight: 500;

  margin: 0;
`;
export const DivLoading = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 30px 0 9px;
`;

export const DivAnimatedLoading = styled.div`
    width: 15px;
    height: 15px;

    border-radius: 50%;
    border: 3px solid #CCCCCC;
    border-left: 3px solid #009291;

  animation: ${girateAnimation} 1s infinite
`;