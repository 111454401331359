import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';

const getInterests = async () => {
  const response = await axios
    .options(`${URLS.API_URL}/api/internal/students/me/interests`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getSelectedInterests = async () => {
  const response = await axios
    .get(`${URLS.API_URL}/api/internal/students/me/interests`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const saveInterests = async (data) => {
  const response = await axios
    .post(`${URLS.API_URL}/api/internal/students/me/interests`, data, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export { getInterests, saveInterests, getSelectedInterests };
