export default function WifiIcon({ color, size }) {
  return (
    <svg
      width={size}
      height={size - 5}
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1232_1727)">
        <path
          d="M7.4962 0C10.6461 0.0942428 13.1158 1.42244 14.8581 4.07041C15.1086 4.45068 15.0171 4.92483 14.6647 5.16282C14.2985 5.40961 13.8403 5.33957 13.5799 4.9604C13.1641 4.35423 12.7169 3.77778 12.1536 3.3018C10.5882 1.9791 8.78033 1.40484 6.74573 1.62266C4.50822 1.86322 2.77395 2.9593 1.52426 4.82545C1.32538 5.12285 1.08987 5.33883 0.712617 5.30216C0.117804 5.24202 -0.188393 4.61753 0.12403 4.10304C0.427697 3.60654 0.783799 3.14416 1.1862 2.72387C2.4669 1.37929 4.01742 0.526708 5.83776 0.166117C6.38432 0.0599747 6.93947 0.0043685 7.4962 0Z"
          fill={color}
        />
        <path
          d="M11.9699 6.39829C11.9644 6.75582 11.7553 7.04258 11.4388 7.15296C11.2932 7.2048 11.1352 7.21061 10.9862 7.16962C10.8371 7.12863 10.7043 7.04281 10.6056 6.92377C10.3605 6.61904 10.1466 6.28974 9.8441 6.03305C8.42226 4.82513 6.47117 4.84933 5.08266 6.09722C4.82847 6.32568 4.63655 6.60694 4.43327 6.87903C4.24574 7.13059 3.98496 7.23914 3.67657 7.18266C3.35389 7.12326 3.14219 6.9234 3.05392 6.60401C2.99129 6.37628 3.04293 6.16396 3.1638 5.96777C4.99512 2.98098 9.37931 2.75325 11.5253 5.5347C11.6329 5.67405 11.7329 5.81926 11.83 5.96631C11.9226 6.10456 11.9783 6.25674 11.9699 6.39829Z"
          fill={color}
        />
        <path
          d="M7.49963 9.99991C6.78761 9.99991 6.20049 9.40952 6.21001 8.70362C6.21482 8.364 6.35283 8.0399 6.59425 7.80128C6.83566 7.56267 7.16112 7.42867 7.50037 7.42822C8.21238 7.42822 8.79951 8.01825 8.78998 8.72415C8.78499 9.06374 8.64693 9.38777 8.40557 9.6264C8.1642 9.86504 7.83885 9.99918 7.49963 9.99991Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1232_1727">
          <rect width="15" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
