import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import HeaderForm from '../HeaderForm';
import { Wrapper, ContainerForm } from './style';
export default function FullScreenModal({ children }) {
  const { onCloseModal, configFullScreenModal } = useSendEmail();

  const handleClickClose = () => {
    onCloseModal();
  };

  return (
    <Wrapper>
      <ContainerForm style={configFullScreenModal}>
        <CloseButton onClick={handleClickClose} />
        {children}
      </ContainerForm>
    </Wrapper>
  );
}
