function checkIndiferentChoice(valueArr = []) {
  const hasIndiferent = valueArr.find((item) => item.label === 'Indiferente');
  if (hasIndiferent) {
    return [];
  }
  return valueArr.map((item) => item.value);
}

const SocioeconomicReportAdapter = ({
  name,
  level,
  macroArea,
  courses,
  status,
  term,
  questions,
}) => {
  return {
    title: name,
    target_audience: {
      course_levels: checkIndiferentChoice(level),
      macroareas: checkIndiferentChoice(macroArea),
      courses: checkIndiferentChoice(courses),
      student_status: checkIndiferentChoice(status),
      semesters: checkIndiferentChoice(term),
    },
    data: questions.map((question) => ({
      type: question.type,
      title: question.title,
      options: question.options.map((option) => ({
        label: option,
        value: option,
      })),
    })),
  };
};

export { SocioeconomicReportAdapter };
