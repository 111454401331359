import React from 'react';
import Edifice from 'assets/JobOffer/edifice.svg';
import User from 'assets/JobOffer/user.svg';
import { CalendarIcon } from 'assets/ComponentIcons/CalendarIcon';
import { PageContact } from 'assets/ComponentIcons/PageContact';
import {
  Container,
  Card,
  CardHeader,
  VisualIndicator,
  SubmitButton,
  DateLabel,
} from './style';

import { ThumbUpAlt, ThumbDownAlt, Block } from '@material-ui/icons';
import { JobDetailButton } from '../../../JobDetails';
import { formatDateTime } from 'utils/formatDateTime';
import { CheckIcon } from 'assets/ComponentIcons/LanguageCardIcons/CheckIcon';

export default function IndicationJobsCards(props) {
  const numberOfCandidates = 1;

  const { job, closeModalFunc, isTalentListScope } = props;

  const returnStatus = (status) => {
    const statusMessages = {
      curriculum_sent: 'Indicação feita',
      unscheduled: 'Convite para entrevista enviado',
      scheduled: 'Entrevista agendada',
      rescheduled: 'Entrevista reagendada',
      canceled: 'Entrevista cancelada',
      liked: 'Seu currículo foi curtido',
      not_liked: 'Currículo não curtido',
      job_expired: 'Vaga expirada',
      student_contracted: 'Estudante contratado',
      job_finished: 'Vaga finalizada',
    };
    return statusMessages[status] || 'Indicação feita';
  };

  const returnStatusIcon = (status) => {
    const statusIcons = {
      curriculum_sent: <PageContact color="#606062" />,
      unscheduled: <CalendarIcon color="#F09D51" />,
      scheduled: <CalendarIcon color="#1CAB66" />,
      rescheduled: <CalendarIcon color="#E7B855" />,
      canceled: <CalendarIcon color="#C11717" />,
      liked: <ThumbUpAlt color="#558FE7" />,
      not_liked: <ThumbDownAlt color="#C11769" />,
      job_expired: <Block color="#ffffff" />,
      student_contracted: <CalendarIcon color="#ffffff" />,
      job_finished: <CheckIcon color="#ffffff" />,
    };

    return statusIcons[status] || <CalendarIcon color="#606062" />;
  };
  const formattedJobDate = formatDateTime(job.created_at).simpleFormattedDate;

  const returnStatusClass = (status) => {
    const statusClass = {
      curriculum_sent: 'curriculumSent',
      unscheduled: 'unscheduled',
      scheduled: 'scheduled',
      rescheduled: 'rescheduled',
      canceled: 'canceled',
      liked: 'liked',
      not_liked: 'notLiked',
      job_expired: 'jobExpired',
      student_contracted: 'studentContracted',
      job_finished: 'jobFinished',
    };

    return statusClass[status] || 'curriculumSent';
  };

  return (
    <Card>
      <CardHeader className={returnStatusClass(job.status)}>
        {returnStatusIcon(job.status)}
        <p>{returnStatus(job.status)}</p>
      </CardHeader>
      <div className="cardBody">
        <h3 className="title">{job.job_offer_name}</h3>
        <div className="flex">
          <span className="info">{job.opened_since}</span>
          <span className="info">CÓDIGO: {job.job_offer_id}</span>
        </div>
        <VisualIndicator>
          <div className="icon">
            <img src={Edifice} alt="Icone Edifício" />
          </div>
          <p>{job.company}</p>
        </VisualIndicator>
        <VisualIndicator>
          <div className="icon">
            <img src={User} alt="Icone Usuário" />
          </div>
          <p>
            {job.number_of_candidates === 0
              ? 'Nenhum estudante candidatado'
              : null}
            {job.number_of_candidates === 1
              ? `${job.number_of_candidates} estudante candidatado`
              : null}
            {job.number_of_candidates > 1
              ? `${job.number_of_candidates} estudantes candidatados`
              : null}
          </p>
        </VisualIndicator>
        {isTalentListScope ? (
          <JobDetailButton
            jobID={job.job_offer_id}
            style={{
              backgroundColor: '#009291',
              color: '#FFFF',
              width: '215px',
              height: '33px',
              marginTop: 'auto',
            }}
            isFromPartners={false}
            canIndication={false}
            buttonFunction={closeModalFunc}
            fullWidth
            isCompanyScope
          >
            Detalhes da vaga
          </JobDetailButton>
        ) : (
          <JobDetailButton
            jobID={job.job_offer_id}
            style={{
              backgroundColor: '#009291',
              color: '#FFFF',
              width: '215px',
              height: '33px',
              marginTop: 'auto',
            }}
            isFromPartners={true}
            canIndication={false}
            buttonFunction={closeModalFunc}
            fullWidth
          >
            Detalhes da vaga
          </JobDetailButton>
        )}
        {formattedJobDate && (
          <DateLabel>Indicado em {formattedJobDate}</DateLabel>
        )}
      </div>
    </Card>
  );
}
