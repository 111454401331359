import React, { useContext, useEffect, useRef, useState } from 'react';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';

import {
  BoxReaction,
  ButtonFeedback,
  ButtonX,
  Container,
  ContainerButtons,
  Description,
  DivButtonsFooter,
  DivDescription,
  DivX,
  Favorite,
  GridSide,
  Image,
  ItemWrapper,
  Title,
  UnpublishButton,
  WrapperBoxReaction,
  WrapperContent,
  WrapperHeader,
  WrapperInformartions,
  WrapperMoreInfo,
  WrapperStatus,
  WrapperStatusPublished,
  WrapperTitles,
} from './styled';

import { RiCloseFill } from 'react-icons/ri';
import { AiOutlineClockCircle } from 'react-icons/ai';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaMinusCircle,
  FaUser,
} from 'react-icons/fa';
import { BiDislike, BiLike } from 'react-icons/bi';
import { IoCalendarOutline, IoLocationSharp } from 'react-icons/io5';

import Alvo from 'assets/Oportutinites/Alvo.svg';
import people from 'assets/Oportutinites/people.svg';
import blackTrash from 'assets/Oportutinites/blackTrash.svg';
import GraduationCap from 'assets/Oportutinites/chapeu.svg';
import GraduationCapNoFilled from 'assets/ExtensionProject/GraduationCap.svg';

import { getFeedback } from '../../../../../services';
import { BsStar } from 'react-icons/bs';
import ModalUnpublishProject from '../ModalUnpublishProject';
import { ExtensionProjectsContext } from '../../../../../Context/ExtensionProjectsContext';
import ModalViewFeedback from '../ModalViewFeedback';
import ModalTargetAudienceExtensionProjects from '../ModalTargetAudienceExtensionProjects';
import ImpactedPeopleModal from '../ImpactedPeopleModal';
import ModalUnpublishNoFeedback from '../ModalUnpublishProject/ModalUnpublishNoFeedback';
import { Divider } from '../ModalStylingPattern/styles';

export default function ModalMoreInformations(props) {
  const { setExtensionProjectId } = useContext(ExtensionProjectsContext);
  const modalContainer = useRef();

  const {
    isOpen,
    setModalOpen,
    details,
    children,
    finishedProject,
    handleEnrolledStudents,
    toFinishProject = false,
  } = props;

  const [openModalUnpublish, setOpenModalUnpublish] = useState(false);
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const [feedback, setFeedback] = useState({});
  const [openModalTargetAudience, setOpenModalTargetAudience] = useState(false);
  const [openImpactedPeopleModal, setOpenImpactedPeopleModal] = useState(false);

  const impactedPeople =
    details.extension_project.finished_information?.external_public ||
    details.extension_project.finished_information?.impacted_public;

  const returnImageStatus = (item) => {
    if (toFinishProject) {
      return <FaExclamationCircle size={14} color="#009291" />;
    }
    if (item.is_published === false) {
      return <FaMinusCircle size={14} color="#009291" />;
    }
    if (item.is_published === true && item.status === null) {
      return <FaCheckCircle size={14} color="#009291" />;
    }

    return <FaCheckCircle size={14} color="#009291" />;
  };

  const formatWorkload = (workload) => {
    if (workload) {
      const split = workload?.split('.');
      if (split[1] === '00') {
        return `${split[0].padStart(2, '0')}h`;
      }
      return `${split[0].padStart(2, '0')}h${split[1]}`;
    }
  };

  const formatDate = () => {
    const startOffer = moment(details.start_at).format('DD/MM/YYYY');
    const endOffer = moment(details.end_at).format('DD/MM/YYYY');

    return `${startOffer} a ${endOffer}`;
  };

  const dateNow = new Date();

  const compareDate = (dateNow, startAt) => {
    const dateNowFormat = moment(dateNow);
    const startAtFormat = moment(startAt);

    if (startAtFormat >= dateNowFormat) {
      return true;
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setExtensionProjectId(null);
  };

  const closeModalClickOutside = (e) => {
    if (modalContainer.current === e.target) {
      setModalOpen(false);
      setExtensionProjectId(null);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setOpenModalTargetAudience(false);
      setOpenImpactedPeopleModal(false);
    }
  }, [isOpen]);

  const handleModalFeedback = async (details) => {
    let feedbackId = details.extension_project.feedback_id;
    let projectId = details.id;
    setOpenModalFeedback(true);

    const data = await getFeedback(projectId, feedbackId);
    setFeedback(data);
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <div
        className="box"
        ref={modalContainer}
        onClick={closeModalClickOutside}
      >
        <WrapperMoreInfo isOpen={isOpen}>
          <WrapperHeader>
            {details.attraction_image && details.attraction_image.logo ? (
              <Image>
                <img src={details.attraction_image.image} />
              </Image>
            ) : (
              <Image>
                {details.attraction_image && (
                  <img
                    src={details.attraction_image.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: '11.5652px',
                      borderTopRightRadius: '11.5652px',
                      position: 'absolute',
                      objectFit: 'cover',
                      top: 0,
                    }}
                  />
                )}
              </Image>
            )}

            <DivX>
              <ButtonX onClick={closeModal}>
                <RiCloseFill size={25} />
              </ButtonX>
            </DivX>

            {/* <GenericModal /> */}
            <WrapperBoxReaction>
              <BoxReaction>
                <BiLike size={17} color="#4B4B4D" />
                <span>
                  {details.total_reaction && details.total_reaction.total_liked}
                </span>
              </BoxReaction>
              <BoxReaction>
                <BiDislike size={17} color="#4B4B4D" />
                <span>
                  {details.total_reaction &&
                    details.total_reaction.total_disliked}
                </span>
              </BoxReaction>
            </WrapperBoxReaction>
          </WrapperHeader>
          <WrapperContent>
            <WrapperStatus>
              <WrapperStatusPublished>
                <i style={{ marginRight: '6px' }}>
                  {returnImageStatus(details)}
                </i>
                <p>
                  {toFinishProject
                    ? 'Necessita finalização'
                    : details.is_published
                    ? 'Publicado'
                    : 'Despublicado'}
                </p>
              </WrapperStatusPublished>
            </WrapperStatus>

            {openModalFeedback && (
              <ModalViewFeedback
                isOpen={openModalFeedback}
                setOpenModalFeedback={setOpenModalFeedback}
                feedback={feedback}
              />
            )}

            {openModalTargetAudience && (
              <ModalTargetAudienceExtensionProjects
                item={details}
                setIsOpenModal={setOpenModalTargetAudience}
              />
            )}

            {openImpactedPeopleModal && (
              <ImpactedPeopleModal
                item={details}
                setIsOpenModal={setOpenImpactedPeopleModal}
              />
            )}

            {openModalUnpublish &&
              details.extension_project.students_registered > 0 && (
                <ModalUnpublishProject
                  isOpen={openModalUnpublish}
                  closeTheParentModal={setModalOpen}
                  setOpenModalUnpublish={setOpenModalUnpublish}
                />
              )}

            {openModalUnpublish &&
              details.extension_project.students_registered < 1 && (
                <ModalUnpublishNoFeedback
                  isOpen={openModalUnpublish}
                  closeTheParentModal={setModalOpen}
                  setOpenModalUnpublish={setOpenModalUnpublish}
                />
              )}
            <WrapperTitles>
              <Title>{details.name}</Title>
              <DivDescription>
                {details.description === '' || !details.description ? null : (
                  <Description>
                    {ReactHtmlParser(details.description)}
                  </Description>
                )}
              </DivDescription>
            </WrapperTitles>

            <WrapperInformartions>
              <div style={{ display: 'flex' }}>
                <GridSide>
                  <ItemWrapper>
                    <div className="container">
                      <i>
                        <IoCalendarOutline size={30} color="#009291" />
                      </i>
                      <div className="text-wrapper">
                        <strong style={{ marginLeft: '-2px' }}>
                          {' '}
                          Data de realização:{' '}
                        </strong>
                        <p style={{ marginLeft: '-2px' }}>
                          {details.start_at && details.end_at
                            ? formatDate()
                            : 'Não informado'}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>

                  <ItemWrapper>
                    <div className="container">
                      <img src={Alvo} alt="icon-alvo" />
                      <div className="text-wrapper">
                        <strong> Público-alvo: </strong>
                        <button
                          onClick={() => setOpenModalTargetAudience(true)}
                        >
                          Clique para ver
                        </button>
                      </div>
                    </div>
                  </ItemWrapper>

                  <ItemWrapper>
                    <div className="container" style={{ alignItems: 'center' }}>
                      <i>
                        <IoCalendarOutline size={30} color="#009291" />
                      </i>
                      <div className="text-wrapper">
                        <strong style={{ marginLeft: '-3px' }}>
                          Publicado em:
                        </strong>
                        <p style={{ marginLeft: '-3px' }}>
                          {moment(details.created_at).format('DD/MM/YYYY')}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>

                  <ItemWrapper>
                    <div className="container" style={{ alignItems: 'center' }}>
                      <i>
                        <AiOutlineClockCircle size={30} color="#009291" />
                      </i>
                      <div className="text-wrapper">
                        <strong style={{ marginLeft: '-3px' }}>
                          Carga horária:
                        </strong>
                        <p style={{ marginLeft: '-3px' }}>
                          {formatWorkload(details.workload)}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>
                </GridSide>

                <GridSide
                  finishedProject={finishedProject}
                  style={{ marginLeft: '20px' }}
                >
                  {details.extension_project.students_registered > 0 && (
                    <ItemWrapper>
                      <div
                        className="container"
                        style={{ alignItems: 'center' }}
                      >
                        <i>
                          <img src={GraduationCapNoFilled} />
                        </i>
                        <div className="text-wrapper">
                          <strong style={{ marginLeft: '-7px' }}>
                            Estudantes inscritos:
                          </strong>
                          <button
                            style={{ marginLeft: '-7px' }}
                            onClick={() => handleEnrolledStudents(details)}
                          >
                            Clique para ver
                          </button>
                        </div>
                      </div>
                    </ItemWrapper>
                  )}
                  <ItemWrapper>
                    <div className="container">
                      <i>
                        <FaUser size={28} color="#009291" />
                      </i>
                      <div className="text-wrapper">
                        <strong> Responsável: </strong>
                        <p>
                          {details.creator_name
                            ? details.creator_name
                            : 'Não identificado'}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>
                  <ItemWrapper>
                    <div className="container">
                      <i>
                        <img src={GraduationCap} />
                      </i>
                      <div className="text-wrapper">
                        <strong style={{ marginLeft: '-5px' }}>
                          {' '}
                          Postado por:{' '}
                        </strong>
                        <p style={{ marginLeft: '-5px' }}>
                          {details.university
                            ? details.university
                            : 'Não identificado'}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>

                  <ItemWrapper>
                    <div className="container">
                      <i>
                        <IoLocationSharp size={28} color="#009291" />
                      </i>
                      <div className="text-wrapper">
                        <strong> Local do Projeto: </strong>
                        <p>
                          {details.address &&
                            details.address.street &&
                            `${details.address.street},`}
                          {details.address &&
                            details.address.number &&
                            ` ${details.address.number},`}
                          {details.address &&
                            details.address.complement &&
                            ` ${details.address.complement},`}
                          {details.address &&
                            details.address.city &&
                            ` ${details.address.city},`}
                          {details.address &&
                            details.address.state &&
                            ` ${details.address.state},`}
                          {details.address &&
                            details.address.zip_code &&
                            ` ${details.address.zip_code}`}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>

                  {finishedProject > 0 && impactedPeople && (
                    <ItemWrapper>
                      <div className="container">
                        <img src={people} alt="icon-people" />
                        <div className="text-wrapper">
                          <strong> Público Impactado: </strong>
                          <button
                            onClick={() => setOpenImpactedPeopleModal(true)}
                          >
                            Clique para ver
                          </button>
                        </div>
                      </div>
                    </ItemWrapper>
                  )}
                </GridSide>
              </div>
            </WrapperInformartions>
            <Divider></Divider>
            <DivButtonsFooter>
              {details.is_published &&
                !toFinishProject &&
                compareDate(dateNow, details.start_at) === true && (
                  <ContainerButtons>
                    <UnpublishButton
                      onClick={() => setOpenModalUnpublish(true)}
                    >
                      <img src={blackTrash} alt="icon-lixeira" />
                      DESPUBLICAR
                    </UnpublishButton>
                  </ContainerButtons>
                )}
              {details.is_published === false &&
                details.extension_project.feedback_id !== null &&
                finishedProject === true && (
                  <ContainerButtons>
                    <ButtonFeedback
                      onClick={() => handleModalFeedback(details)}
                    >
                      VISUALIZAR FEEDBACK
                    </ButtonFeedback>
                  </ContainerButtons>
                )}
              <Favorite>
                <div className="yellow-box">
                  <BsStar size={20} color="#fff" />
                </div>
                {`Favoritado ${
                  details.total_reaction &&
                  details.total_reaction.total_recommended
                } ${
                  details.total_reaction &&
                  details.total_reaction.total_recommended === 1
                    ? 'vez'
                    : 'vezes'
                }`}
              </Favorite>
            </DivButtonsFooter>
          </WrapperContent>
          {children && children}
        </WrapperMoreInfo>
      </div>
    </Container>
  ) : null;
}
