import { useEffect, Fragment } from 'react';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import ListItems from '../../components/ListItems';
import { Header } from '../../components/ListItems';
import { ContainerMessage } from './style';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';

export default function GeneratedLists() {
  const {
    listageGeneratedList,
    setListageGeneratedList,
    currentPropsPagination,
  } = useSendEmail();
  const { egressed } = useCsm();
  const listPaginationProps = currentPropsPagination[0];

  const getList = async () => {
    const page = `limit=${listPaginationProps.limit}&offset=${
      (listPaginationProps.currentPage - 1) * listPaginationProps.limit || 0
    }`;
    const { results, count } = await sendEmailApi.getListage(page, egressed);
    listPaginationProps.setCount(count);
    setListageGeneratedList(results);
  };

  useEffect(() => {
    getList();
  }, []);

  const mappedList = listageGeneratedList.map((item) => (
    <ListItems informations={item} />
  ));

  const ControllComponents = () => {
    if (listageGeneratedList.length === 0) {
      return (
        <ContainerMessage>
          <p>Oops! No momento, não existem listas ativadas</p>
        </ContainerMessage>
      );
    }

    return (
      <Fragment>
        <Header />
        {mappedList}
      </Fragment>
    );
  };

  return <div>{ControllComponents()}</div>;
}
