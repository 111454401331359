import React, { Fragment, useContext, useEffect } from 'react';
import { ExtensionProjectsContext } from '../../Context/ExtensionProjectsContext';
import ExtensionProjectsEngine from '../ExtensionProjects/ExtensionProjectsEngine';
import Modal from '../modal';

export default function TabExtensionProjects({ setActiveTab, activeTab }) {
  const {
    setFutureExtension,
    setInProgressExtension,
    setOpenForSubscriptionsExtension,
    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
    refModalExtensionProject,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    setFutureExtension(null);
    setInProgressExtension(null);
    setOpenForSubscriptionsExtension(null);
    setPaginationOneCurrentPage(0);
    setPaginationTwoCurrentPage(0);
    setPaginationThreeCurrentPage(0);
  }, [activeTab]);

  return (
    <Fragment>
      <div ref={refModalExtensionProject}>
        <ExtensionProjectsEngine setActiveTab={setActiveTab} />
      </div>
      <Modal />
    </Fragment>
  );
}
