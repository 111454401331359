import React, { createContext, useState, useEffect, useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { getStudentGamification, visibleItems } from 'services/student/profile';

export const ProfileContext = createContext();

export default function ProfileProvider({
  children,
  gamificationData,
  setGamificationData,
  handleCurriculum,
  setModalRewards,
  modalReward,
}) {
  const {
    profile_id,
    universityColor,
    openModalWithContent,
    handleIsLoadingState,
  } = useContext(BaseLayoutContext);

  const [currentPage, setCurrentPage] = useState(0);
  const [avatar, setAvatar] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [profileId, setProfileId] = useState(0);

  const [nextDateBehavioral, setNextDateBehavioral] = useState('');
  const [nextDatePersonality, setNextDatePersonality] = useState('');

  const [isOpenModalNextDate, setIsOpenModalNextDate] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [visibleInCurriculum, setVisibleInCurriculum] = useState([]);
  const [statusIcon, setStatusIcon] = useState(null);

  const handleClick = () => {
    setOpenModal(true);
  };

  const fetchGamificationData = async () => {
    const id = profile_id;
    const data = await getStudentGamification(id);
    setGamificationData && setGamificationData(data);
  };

  const fetchVisibleItems = async () => {
    const response = await visibleItems();
    setVisibleInCurriculum(response);
  };

  useEffect(() => {
    if(profile_id) {
      fetchGamificationData();
      fetchVisibleItems();
    }
  }, []);

  const stepOneData = gamificationData?.gamification_status?.step_one;
  const stepOneDataSum =
    gamificationData?.gamification_status?.step_one?.step_one_sum;

  const stepTwoData = gamificationData?.gamification_status?.step_two;
  const stepTwoDataSum =
    gamificationData?.gamification_status?.step_two?.step_two_sum;

  const gamificationLevel = gamificationData?.profile_level;

  return (
    <ProfileContext.Provider
      value={{
        profile_id,
        currentPage,
        setCurrentPage,
        gamificationData,
        setGamificationData,
        universityColor,
        openModalWithContent,
        handleIsLoadingState,
        stepOneData,
        stepOneDataSum,
        stepTwoData,
        stepTwoDataSum,
        gamificationLevel,

        avatar,
        setAvatar,
        isOpen,
        setIsOpen,
        profileId,
        setProfileId,
        nextDateBehavioral,
        setNextDateBehavioral,
        nextDatePersonality,
        setNextDatePersonality,
        isOpenModalNextDate,
        setIsOpenModalNextDate,

        openModal,
        setOpenModal,
        handleClick,

        fetchGamificationData,
        visibleInCurriculum,

        statusIcon,
        setStatusIcon,

        handleCurriculum,
        modalReward,
        setModalRewards,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}
