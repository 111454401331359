export function WithResumeIcon({color = '#009291', size}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_34256_5043)">
        <path
          d="M8.83333 19.6667C8.83333 20.1275 8.46083 20.5 8 20.5H4.66667C2.36917 20.5 0.5 18.6308 0.5 16.3333V4.66667C0.5 2.36917 2.36917 0.5 4.66667 0.5H11.3333C13.6308 0.5 15.5 2.36917 15.5 4.66667V6.33333C15.5 6.79417 15.1275 7.16667 14.6667 7.16667C14.2058 7.16667 13.8333 6.79417 13.8333 6.33333V4.66667C13.8333 3.28833 12.7117 2.16667 11.3333 2.16667H4.66667C3.28833 2.16667 2.16667 3.28833 2.16667 4.66667V16.3333C2.16667 17.7117 3.28833 18.8333 4.66667 18.8333H8C8.46083 18.8333 8.83333 19.2058 8.83333 19.6667ZM12.1667 5.5C12.1667 5.03917 11.7942 4.66667 11.3333 4.66667H4.66667C4.20583 4.66667 3.83333 5.03917 3.83333 5.5C3.83333 5.96083 4.20583 6.33333 4.66667 6.33333H11.3333C11.7942 6.33333 12.1667 5.96083 12.1667 5.5ZM8.83333 9.66667C8.83333 9.20583 8.46083 8.83333 8 8.83333H4.66667C4.20583 8.83333 3.83333 9.20583 3.83333 9.66667C3.83333 10.1275 4.20583 10.5 4.66667 10.5H8C8.46083 10.5 8.83333 10.1275 8.83333 9.66667ZM4.66667 13C4.20583 13 3.83333 13.3725 3.83333 13.8333C3.83333 14.2942 4.20583 14.6667 4.66667 14.6667H6.33333C6.79417 14.6667 7.16667 14.2942 7.16667 13.8333C7.16667 13.3725 6.79417 13 6.33333 13H4.66667ZM20.5 14.6667C20.5 17.8825 17.8825 20.5 14.6667 20.5C11.4508 20.5 8.83333 17.8825 8.83333 14.6667C8.83333 11.4508 11.4508 8.83333 14.6667 8.83333C17.8825 8.83333 20.5 11.4508 20.5 14.6667ZM18.8333 14.6667C18.8333 12.3692 16.9642 10.5 14.6667 10.5C12.3692 10.5 10.5 12.3692 10.5 14.6667C10.5 16.9642 12.3692 18.8333 14.6667 18.8333C16.9642 18.8333 18.8333 16.9642 18.8333 14.6667ZM16.1733 13.6325L14.3208 15.4108C14.2008 15.5283 14.005 15.5308 13.8858 15.4125L12.9433 14.4892C12.6133 14.1658 12.0867 14.1708 11.765 14.5008C11.4425 14.83 11.4475 15.3567 11.7767 15.6792L12.72 16.6033C13.1033 16.9775 13.605 17.165 14.1058 17.165C14.6067 17.165 15.1067 16.9775 15.4833 16.6058L17.3275 14.8358C17.6592 14.5167 17.67 13.9892 17.3517 13.6575C17.0325 13.3242 16.5042 13.315 16.1733 13.6333V13.6325Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_34256_5043">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
