import { authenticatedAPI } from 'services/api';

const getBehavioralTestQuestions = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/quizzes/behavioral`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getBehavioralNextDate = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/quizzes/behavioral-next-date`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getPersonalityTestQuestions = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/quizzes/personality`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getPersonalityNextDate = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/quizzes/personality-next-date`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const applyPersonalityTest = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/quizzes/personality`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getValuesTestQuestions = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/quizzes/values`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const applyValuesTest = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/quizzes/values`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getLiveQuizAnswer = async (liveId, universitySlug, studentId) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/quizzes/live-quiz?live_id=${liveId}&university_slug=${universitySlug}&student_id=${studentId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const applyLiveQuiz = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/quizzes/live-quiz`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getQuizzCertificateData = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/quizzes/live-quiz/certificate`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getBehavioralTestQuestions,
  getPersonalityTestQuestions,
  applyPersonalityTest,
  getValuesTestQuestions,
  applyValuesTest,
  getLiveQuizAnswer,
  applyLiveQuiz,
  getQuizzCertificateData,
  getBehavioralNextDate,
  getPersonalityNextDate,
};
