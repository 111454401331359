import React, { useEffect, useState } from 'react';

import {
  Container,
  ContainerFilter,
  ContainerNavigation,
  WhiteBox,
  CustomGenericTabs,
} from './styled';
import Header from './UiComponents/Header';
import GenericInternalTab from 'components/GenericInternalTab';
import BaseLayoutContext from 'contexts/base-layout';
import MyRegistrations from './Tabs/MyRegistrations';
import { useVisualizationContext } from './Contexts/VisualizationContext';
import Availables from './Tabs/Availables';
import Finished from './Tabs/Finished';
import DetailsModal from './UiComponents/DetailsModal';
import LiveStreamBottomModal from './UiComponents/LiveStreamBottomModal';
import FilterHandlers from './Handlers/FilterHandlers';
import FilterModal from 'components/FilterModal';
import {
  getFairAndEventsAssociatedAreas,
  getFairAndEventsFilterOptions,
  getFairAndEventsFilterOptionsFinished,
} from 'views/Student/Learning/Services';
import { filtersConfig } from './StaticOptions/FilterConfig';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { staticOptions } from './StaticOptions';
import useMediaQuery from 'hooks/useMediaQuery';
import { VisualizationHandler } from './Handlers/VisualizationHandler';
import CurriculumContextProvider from 'contexts/CurriculumContext';

export default function FairAndEventsEngine({
  academic_profile,
  setFairAndEventsCount,
  eventIdRoute,
  eventRegisterId,
}) {
  const visualizationContextInstance = useVisualizationContext();
  const {
    handleTabChange,
    currentTab,
    counts,
    setCurrentTab,
  } = visualizationContextInstance;
  const FilterHandlersFactory = FilterHandlers(visualizationContextInstance);

  const isMobile = useMediaQuery('(max-width: 640px)');

  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterState, setFilterState] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [openFilters, setOpenFilters] = useState(false);
  const { availables, finished, my_registrations } = counts;

  const fullCount = availables + finished + my_registrations || 0;

  const tabsLabel = [
    `Minhas inscrições (${my_registrations || 0})`,
    `Disponíveis (${availables || 0})`,
    `Eventos finalizados (${finished || 0})`,
  ];

  const tabsComponents = {
    0: <MyRegistrations />,
    1: <Availables />,
    2: <Finished />,
  };

  const activeTab = tabsComponents[currentTab];

  const getOptionsFilter = async () => {
    const ApiRoutes = {
      0: getFairAndEventsFilterOptions,
      1: getFairAndEventsFilterOptions,
      2: getFairAndEventsFilterOptionsFinished,
    };

    const response = await ApiRoutes[currentTab]();
    setFilterOptions(response);
  };

  const getAssociatedAreas = async (param) => {
    const response = await getFairAndEventsAssociatedAreas(param);
    setFilterOptions((prevs) => ({ ...prevs, ...response }));
  };

  useEffect(() => {
    getOptionsFilter();
  }, []);

  useEffect(() => {
    if (eventIdRoute && eventRegisterId) {
      VisualizationHandler.openDetailsModal(
        visualizationContextInstance,
        eventIdRoute,
        eventRegisterId
      );
    }

    if (eventIdRoute && !eventRegisterId) {
      VisualizationHandler.openDetailsModal(
        visualizationContextInstance,
        eventIdRoute
      );
      setCurrentTab(2);
    }
  }, [eventIdRoute, eventRegisterId]);

  const accessFilterOption = (filter) => {
    if (filter.optionsKey === 'boolean') {
      return staticOptions.boolean;
    }
    return filterOptions[filter.optionsKey] || [];
  };

  const filtersConfigWithParameter = filtersConfig.concat({
    name: 'competence',
    label: 'Competências',
    type: 'MULTI_SELECT',
    optionsKey: 'associated_areas',
    settings: {
      onType: getAssociatedAreas,
    },
  });

  const filterModal = (
    <FilterModal
      buttonWidth="100%"
      filterState={filterState}
      setFilterState={setFilterState}
      onClean={FilterHandlersFactory.onClearfilter}
      onApply={(event) => FilterHandlersFactory.onApplyFilters(event)}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={isMobile && -1}
      zIndexOverlay={isMobile && -1}
      className="filterContainer"
      onClose={()=>setOpenFilters(false)}
      onOpen={()=>setOpenFilters(true)}
    >
      <ContainerFilter>
        {filtersConfigWithParameter.map((filter) => (
          <div
            key={
              typeof filter.name === 'string'
                ? filter.name
                : filter.name.join('_')
            }
          >
            <Filter
              name={filter.name}
              label={filter.label}
              type={FilterType[filter.type]}
              options={accessFilterOption(filter)}
              settings={filter.settings}
            />
          </div>
        ))}
      </ContainerFilter>
    </FilterModal>
  );

  return (
    <CurriculumContextProvider>
      <Container>
        <Header
          color={''}
          academic_profile={academic_profile}
          count={fullCount}
        />
        <LiveStreamBottomModal />
        <DetailsModal />
        <ContainerNavigation>
          <CustomGenericTabs
            tabs={tabsLabel}
            handleTabChange={handleTabChange}
            activeTab={currentTab}
            buttons={!isMobile ? [filterModal] : []}
            zIndex={1}
            containerButtonsPosition={'unset'}
          />
        </ContainerNavigation>
        <LiveStreamBottomModal />

        <WhiteBox openFilters={openFilters}>
          {isMobile && <div className="filter-modal">{filterModal}</div>}

          {activeTab}
        </WhiteBox>
      </Container>
    </CurriculumContextProvider>
  );
}
