import React from 'react';
import { CalendarIcon } from 'assets/mentorship/CalendarIcon';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineTimer } from 'react-icons/md';
import { AiOutlineCheck } from 'react-icons/ai';
import { Flag } from '../components/DetailsModal/styled';

const statusMapping = {
  reschedule_request: {
    icon: <CalendarIcon size={20} color="#F09D51" />,
    text: 'Solicitação de reagendamento',
  },

  canceled: {
    icon: <AiOutlineCloseCircle size={20} color="#DA0505" />,
    text: 'Mentoria cancelada',
  },
  not_realized: {
    icon: <AiOutlineCloseCircle size={20} color="#DA0505" />,
    text: 'Mentoria não realizada',
  },
  expired: {
    icon: <MdOutlineTimer size={20} color="#606062" />,
    text: 'Mentoria expirada',
  },
  realized: {
    icon: <AiOutlineCheck size={20} color="#068E2C" />,
    text: 'Mentoria realizada',
  },
};

const StatusFlag = ({ mentoringStatus, isActiveExpiredTab }) => {
  const status = isActiveExpiredTab ? 'expired' : mentoringStatus;
  const { icon, text } = statusMapping[status] || {};

  return (
    <Flag mentoringStatus={status}>
      <span>{icon}</span>
      <p>{text}</p>
    </Flag>
  );
};

export default StatusFlag;
