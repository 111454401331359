import axios from 'axios';

export const GenerateBlobPdf = (url) => {
  const blobResponse = axios
    .get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    })
    .then((response) => {
      return window.URL.createObjectURL(new Blob([response.data]));
    })
    .catch((error) => error);

  return blobResponse;
};
