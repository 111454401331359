export function IconUser({ color }) {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_40715_1363)">
        <path d="M15.9998 7.92524C15.9709 7.77264 15.9998 7.61587 15.9627 7.46327C15.8325 6.50267 15.5131 5.57743 15.0231 4.74121C14.4807 3.79454 13.7364 2.97919 12.8432 2.35324C11.9461 1.71307 10.9165 1.28323 9.83082 1.09541C9.22389 1.00615 8.60951 0.97843 7.99703 1.01284C7.18057 1.04444 6.37709 1.22767 5.62753 1.55315C4.65739 1.95647 3.78273 2.55927 3.06022 3.32248C2.39493 4.02613 1.8679 4.84876 1.50665 5.74751C1.0675 6.87487 0.903614 8.09091 1.02863 9.29443C1.1221 10.3483 1.45169 11.3675 1.99291 12.2763C2.54885 13.2463 3.31658 14.0781 4.23878 14.7096C5.01417 15.2467 5.88373 15.633 6.80197 15.848C7.16414 15.9206 7.5301 15.9729 7.89812 16.0046H9.06021C9.21681 15.9758 9.36928 16.0047 9.52588 15.9676C10.499 15.8417 11.4367 15.5204 12.2827 15.0231C13.2466 14.4676 14.0733 13.7021 14.7017 12.7836C15.3178 11.8955 15.7327 10.8835 15.9174 9.81822C15.9586 9.58726 15.9586 9.3521 15.9956 9.12527V8.97685V8.77487V8.21805V8.04063L15.9998 7.92524Z" fill={color} />
        <path d="M8.64925 9.41765C9.11903 9.41765 9.70008 9.38056 10.277 9.44242C10.8789 9.50908 11.4354 9.79448 11.841 10.2445C12.2467 10.6946 12.4731 11.2779 12.4775 11.884C12.4816 12.0409 12.45 12.1968 12.385 12.3397C12.3201 12.4826 12.2235 12.6088 12.1025 12.7088C11.3022 13.3921 10.3223 13.8302 9.27974 13.9709C7.90484 14.1663 6.50731 13.8284 5.37314 13.0264C5.14814 12.8904 4.94755 12.7176 4.77974 12.5151C4.67855 12.3564 4.61258 12.1777 4.58628 11.9913C4.55998 11.8049 4.57395 11.615 4.62727 11.4345C4.73767 10.8563 5.04811 10.3356 5.50406 9.9638C5.96001 9.59195 6.53231 9.39262 7.1204 9.40114C7.5737 9.41351 8.04348 9.41765 8.64925 9.41765Z" fill="white" />
        <path d="M10.9885 5.73531C10.9885 6.39161 10.728 7.02104 10.2643 7.48511C9.80064 7.94918 9.17174 8.20988 8.51598 8.20988C7.86023 8.20988 7.23133 7.94918 6.76764 7.48511C6.30395 7.02104 6.04346 6.39161 6.04346 5.73531C6.04346 5.07901 6.30395 4.44958 6.76764 3.98551C7.23133 3.52144 7.86023 3.26074 8.51598 3.26074C9.17174 3.26074 9.80064 3.52144 10.2643 3.98551C10.728 4.44958 10.9885 5.07901 10.9885 5.73531Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_40715_1363">
          <rect width="15" height="15" fill="white" transform="translate(1 1)" />
        </clipPath>
      </defs>
    </svg>
  );
}
