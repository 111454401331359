import styled from 'styled-components';

const getBackgroundColor = (props) => {
  return props.universityColor ? props.universityColor : '#009291';
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  margin-top: 20px;
  flex-direction: column;
`;

export const ContainerCompetences = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  max-width: 356px;
  width: 100%;
  margin-top: 20px;

  p {
    color: #606062;
    font-weight: 700;
    font-size: 15px;
  }

  @media (max-width: 720px) {
    max-width: 370px;
    transition: 0.5s;
    margin-right: 26px;
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ContainerRole = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-right: 57px;

  p {
    font-size: 15px;
  }

  h3 {
    color: #606062;
    font-weight: 700;
    font-size: 15px;
  }
`;

export const ContainerPeriod = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;

  width: 124px;

  p {
    font-size: 15px;
  }
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 0;

  &:disabled {
    cursor: default;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 14px;
`;

export const IconDiv = styled.img``;

export const Divider = styled.div`
  background-color: #e5e5e5;
  height: 1px;
  width: 100%;
  margin: 20px 0;
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const ContainerPersonality = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;

  .divider-control {
    background-color: #e5e5e5;
    height: 1px;
    width: 100%;
    margin: 20px 0;
  }
`;

export const Title = styled.p`
  color: #606062;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: 0;
`;

export const AlertTest = styled.div`
  display: ${(props) => (props.showAlert ? 'flex' : 'none')};
  color: #8b8b8b;
  font-size: 12px;
  font-weight: 400;

  margin-top: 26px;
`;

export const Paragraph = styled.p`
  color: #949494;
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;

  :first-letter {
    text-transform: uppercase;
  }
`;

export const CallToAction = styled.button`
  width: auto;

  display: ${(props) => (props.showButton ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  padding: 11px;
  gap: 3px;

  border: none;
  border-radius: 4.591px;
  background-color: ${getBackgroundColor};

  color: #fff;
  font-size: 13px;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 7px;

  cursor: pointer;

  svg {
    margin-top: 2.4px;
  }

  &:disabled {
    cursor: default;
  }
`;

export const EditButton = styled(CallToAction)`
  max-width: 72px;
  font-size: 12px;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 10px 0;
`;

export const ExperiencesContainer = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  padding: 10px;
`;

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  margin: ${(props) => (props.margin ? '20px 0' : '10px 0')};
  flex-wrap: wrap;
  gap: 14px;
`;

export const Chip = styled.div`
  width: fit-content;
  padding: 8px 16px;
  color: #606062;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  background: #f5f5f5;
  border-radius: 30px;
`;
export const InvertDirection = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${(props) => (props.gap ? props.gap : 'unset')};
  flex-wrap: wrap;
  align-items: center;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  gap: 20px;

  @media (max-width: 457px) {
    transition: 0.5s;
    flex-direction: column;
    width: 100%;
    gap: 26px;
  }
`;

export const ContainerValues = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 457px) {
    align-items: flex-start;
  }
`;

export const BulletList = styled.ol`
  width: 100%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 457px) {
    align-items: flex-start;
  }
`;
export const BulletItem = styled.li`
  align-items: center;
  display: flex;
  font-weight: 400;
  flex-flow: column;
  font-size: 1.1em;
  margin: 0 1em;
  text-align: center;
  color: #606062;
  transition: 0.5s;

  @media (max-width: 457px) {
    transition: 0.5s;
    align-items: center;
  }

  > p {
    font-weight: 700;
  }
`;

export const Bullet = styled.span`
  align-items: center;
  background: ${getBackgroundColor};
  color: #fff;
  border-radius: 50%;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 51px;
  transition: 0.5s;

  p {
    font-size: 15px;
    max-width: 190px;
    text-align: center;
  }

  @media (max-width: 457px) {
    transition: 0.5s;
    p {
      font-size: 13px;
    }
  }
`;

export const ContainerLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: ${(props) => (props.projects ? '405px' : '143px')};
  margin-right: 12px;

  strong {
    color: #606062;
    font-size: 17px;
  }

  span {
    color: #606062;
    font-size: 14px;
  }
  transition: 0.5s;

  @media (max-width: 685px) {
    max-width: ${(props) => (props.adaptable ? '100px' : 'unset')};
    transition: 0.5s;
    margin-right: 26px;
  }

  @media (max-width: 597px) {
    max-width: ${(props) => (props.adaptable ? '70px' : 'unset')};
    transition: 0.5s;
  }

  @media (max-width: 375px) {
    max-width: ${(props) => (props.adaptable ? '110px' : 'unset')};
  }
`;

export const ContainerRight = styled.div`
  display: flex;
  align-items: center;
  gap: 25px;

  @media (max-width: 600px) {
    gap: 14px;
    transition: 0.5s;
  }
`;

export const WrapperContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 26px;
  padding: 10px;

  width: 100%;

  @media (max-width: 600px) {
    align-items: flex-start;
  }
`;

export const ContainerInfos = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-right: 26px;
  max-width: 650px;
`;
export const TableWrapper = styled.div`
  overflow-x: auto;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead``;

export const TableHeaderCell = styled.th`
  padding: 8px;
  text-align: left;
`;

export const TableRow = styled.tr`
  width: 100%;
`;

export const TableCell = styled.td`
  padding: 8px;
  p {
    font-size: 15px;
  }

  h3 {
    color: #606062;
    font-weight: 700;
    font-size: 15px;
    margin: 4px 0 10px;
  }

  @media (max-width: 425px) {
    p {
      font-size: 14px;
    }

    h3 {
      font-size: 13px;
    }
  }
`;

export const TableItems = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
