import React, { useEffect, useRef } from 'react';

export const useScrollToDiv = (condition) => {
  const divRef = useRef(null);

  const ScrollToFinalDiv = () => {
    if (divRef && divRef.current) {
      divRef.current.scrollIntoView({ block: 'start', inline: 'nearest' });
    }
  };

  useEffect(() => {
    ScrollToFinalDiv();
  }, [condition]);

  return [divRef];
};
