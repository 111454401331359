import React, { useState, useEffect } from 'react';
import {
  Box,
  ContainerForm,
  NextButton,
  BackButton,
  CourseName,
} from './style';
import CloseButton from '../../../CurriculumMatrix/components/activeMatrices/closeButtonModal';
import StepIndicator from '../StepIndicator';
import StepOne from '../Steps/StepOne';
import { useAcademicModeling } from '../../contexts/AcademicModelingContext';
import StepFour from '../Steps/StepFour';
import StepThree from '../Steps/StepThree';
import StepTwo from '../Steps/StepTwo';

import './pageSize.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

export default function FullScreenModal() {
  const [courseNameAndType, setCourseNameAndType] = useState('');
  const {
    setFullScreenModalOpen,
    selectedStep,
    setSelectedStep,
    currentStep,
    selectedCourse,
  } = useAcademicModeling();

  const handleClickClose = () => {
    setFullScreenModalOpen(false);
  };
  const canAdvance = selectedStep + 1 <= currentStep;

  const handleBack = () => {
    setSelectedStep(selectedStep - 1);
  };

  const handleNext = () => {
    if (!canAdvance) return;
    setSelectedStep(selectedStep + 1);
  };

  useEffect(() => {
    setCourseNameAndType(`${selectedCourse.name} | ${selectedCourse.modality}`);
  }, [selectedCourse]);

  const steps = {
    1: <StepOne />,
    2: <StepTwo />,
    3: <StepThree />,
    4: <StepFour />,
  };

  return (
    <Box>
      <ContainerForm>
        <CloseButton onClick={handleClickClose} />
        <CourseName>{courseNameAndType}</CourseName>
        <StepIndicator />
        {steps[selectedStep]}
        <footer>
          {selectedStep !== 1 && (
            <BackButton onClick={handleBack}>
              <FaChevronLeft />
              Voltar
            </BackButton>
          )}
          {selectedStep !== 4 && (
            <NextButton
              disabled={!canAdvance}
              title={!canAdvance ? 'Finalize o passo atual' : ''}
              onClick={handleNext}
            >
              Avançar
              <FaChevronRight />
            </NextButton>
          )}
        </footer>
      </ContainerForm>
    </Box>
  );
}
