import styled from 'styled-components';

export const Container = styled.div`
  padding: 60px;
`;

export const Title = styled.p`
  font-size: 22px;
  color: #009291;
  font-weight: 700;
  padding-bottom: 13px;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
`;

export const Header = styled.div`
  width: 100%;
  padding-right: ${(props) => (props.padRight ? '128px' : '100px')};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  position: relative;
  padding-top: 8px;
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  box-shadow: 0px 0px 17.8019px rgba(0, 0, 0, 0.1);
  border-radius: 8.90097px;

  border-top-left-radius: ${(props) => props.internalWhiteBox && 'unset'};
  border-top-right-radius: ${(props) => props.internalWhiteBox && 'unset'};

  margin-top: ${(props) => (props.internalWhiteBox ? 'unset' : '33px')};
`;

export const FilterContainer = styled.div`
  display: flex;
  right: 0;
  top: 0.5rem;

  position: absolute;
`;

export const HeaderTab = styled.div`
  height: 81px;
  display: flex;
  position: relative;

  border-bottom: 1px solid #e6e6e6;

  align-items: center;
  justify-content: space-between;

  > .header-tab-left {
    display: flex;
    align-items: center;
    width: 50%;
  }

  .search-icon {
    position: absolute;
    right: 30px;
    top: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009291;
    border-radius: 4px;
    padding: 4px;
  }
`;
export const DisconnectButton = styled.button`
  width: 100%;
  max-width: 194px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #009291;
  border: unset;

  border-radius: 4px;

  font-size: 13px;
  font-weight: 400;
  color: #fff;

  cursor: pointer;
`;

export const InviteCompanyButton = styled.button`
  padding: 7px 12px;

  background-color: #009291;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-around;

  gap: 7px;

  border: none;
  cursor: pointer;

  border-radius: 3px;

  margin-right: ${(props) => (props.marginRight ? '27px' : '13px')};

  flex: none;

  .base-icon {
    width: 22px;
    height: 22px;

    background-color: #fff;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SearchInput = styled.input`
  padding-left: 10px;
  padding-right: 40px;

  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-right: 24px;
  height: 35px;
  width: 220px;
`;

export const WrapperSearch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  padding-bottom: 20px;
  width: fit-content;

  .search-icon {
    position: absolute;
    right: 1.8rem;
    top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009291;
    border-radius: 4px;
    padding: 4px;
  }
`;
