import React, { Fragment, useState, useEffect } from 'react';
import { useFormContext } from 'views/ProfessionalStatus/context';
import { Container, MultiStepDivActive, WrapperStep } from './styled';

export default function StepHeader({ Steps, universityColor }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const { step } = useFormContext();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <WrapperStep>
      {Steps.map((_, index) => (
        <Fragment key={index}>
          <MultiStepDivActive
            universityColor={universityColor}
            backgroundColor={
              index === step
                ? universityColor || '#009291'
                : index > step
                ? '#EFEEEC'
                : universityColor || '#98D2D2'
            }
            color={index === step ? '#fff' : index > step ? '#606062' : '#fff'}
            opacity={index === step ? 'none' : index > step ? 'none' : '0.3'}
          >
            {isMobile ? null : index + 1}
          </MultiStepDivActive>
          <Container
            universityColor={universityColor}
            backgroundColor={
              index === step
                ? universityColor || '#009291'
                : index > step
                ? '#EFEEEC'
                : universityColor || '#98D2D2'
            }
            opacity={index === step ? 'none' : index > step ? 'none' : '0.3'}
            marginLeft={index === step ? '-52px' : '-50px'}
          />
        </Fragment>
      ))}
    </WrapperStep>
  );
}
