import FilterModal from 'components/FilterModal';
import React, { useContext, useEffect, useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, SpanError, Grid } from './styles';
import { handleFieldMask } from 'utils/validation';
import { maskCnpj } from 'utils/inputMasks';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

import * as Yup from 'yup';
import useMediaQuery from 'hooks/useMediaQuery';

export function FilterNetworkStudent({
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  getCitiesOptions,
  setFilterOptions,
  applyFilter,
}) {
  const [loadingStates, setLoadingStates] = useState(false);
  const mobileL = useMediaQuery('(max-width: 768px)');

  async function handleChangeStates(states) {
    if (states && states.length > 0) {
      const { data } = await getCitiesOptions(states);

      if (filterState['cities']) {
        const newFilterState = { ...filterState };
        const responseCitiesValues = data.cities.map((city) => city.value);
        const newCities = newFilterState.cities.filter((city) =>
          responseCitiesValues.includes(city)
        );
        if (newCities.length > 0) {
          newFilterState.cities = newCities;
        } else {
          delete newFilterState.cities;
        }
        newFilterState.states = states;
        setFilterState(newFilterState);
      }
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          cities: data.cities,
        };
      });
      setLoadingStates(false);
    } else {
      setFilterState((prevState) => {
        const newFilterState = { ...prevState };
        delete newFilterState.states;
        delete newFilterState.cities;
        return newFilterState;
      });
    }
  }

  function renderFilterContent() {
    return [
      <FilterGrid>
        <Grid style={{ marginTop: '26px' }}>
          <Filter
            name={'name_or_cnpj'}
            label={'Nome ou CNPJ da empresa'}
            type={FilterType.TEXT}
            onKeyUp={(event) => {
              if (Number(event.target.value.replace(/\D/g, ''))) {
                handleFieldMask(event, maskCnpj);
              }
            }}
            onPaste={(event) => {
              if (Number(event.target.value.replace(/\D/g, ''))) {
                handleFieldMask(
                  event,
                  maskCnpj,
                  event.clipboardData.getData('Text')
                );
              }
            }}
          />
        </Grid>
        <Grid>
          <Filter
            label={'Filtrar por estado'}
            name={'state'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions?.states}
            settings={{
              onChange: handleChangeStates,
            }}
          />
          <Filter
            name={'city'}
            label={'Filtrar por cidade'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions?.cities}
            settings={{
              disabled: !filterState['state'] || loadingStates,
              loading: loadingStates,
            }}
          />
        </Grid>
        <Grid>
          <Filter
            label={'Tipo de atividade'}
            name={'activities_type'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions?.activitiesType}
          />
          <Filter
            name={'jobs_macro_areas'}
            label={'Tipo de atividade das vagas'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions?.jobsMacroAreas}
          />
        </Grid>
        <Grid>
          <Filter
            label={'Porte da empresa'}
            name={'size'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions?.size}
          />
          <Filter
            name={'values'}
            label={'Valores'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions?.values}
          />
        </Grid>
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const existFilters = filters && Object.keys(filters).length > 0;
    if (existFilters) {
      applyFilter && applyFilter();
    }
    if (!existFilters) {
      onClearFilters();
      return;
    }
  }

  async function onClearFilters() {
    applyFilter && (await applyFilter(true));
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={mobileL ? 1100 : 0}
      loading={!Boolean(filterOptions)}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
