export function Calendar({ color = '#009291', size = 21 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_36583_130)">
        <path
          d="M17.5 2.16667H15V0.5H13.3333V2.16667H6.66667V0.5H5V2.16667H2.5C1.12167 2.16667 0 3.28833 0 4.66667V20.5H20V4.66667C20 3.28833 18.8783 2.16667 17.5 2.16667ZM18.3333 18.8333H1.66667V8.83333H18.3333V18.8333ZM18.3333 7.16667H1.66667V4.66667C1.66667 4.2075 2.04 3.83333 2.5 3.83333H17.5C17.96 3.83333 18.3333 4.2075 18.3333 4.66667V7.16667ZM7.57167 16.6783L5.25333 14.4317L6.41417 13.2342L8.74167 15.49L13.5825 10.7392L14.7525 11.9267L9.925 16.6825C9.6025 17.005 9.17833 17.1658 8.7525 17.1658C8.32667 17.1658 7.8975 17.0033 7.57167 16.6783Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_36583_130">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
