import axios from 'axios';
import getCookieValue from '../../../../utils/getCookieValue';
import URLS from '../../../../constants/urls';

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const CancelToken = axios.CancelToken;

let cancelGetAllToFinishProjects;
let cancelGetAllFinishedProjects;
let cancelGetAllUnpublishedProjects;
let cancelGetOpenedExtensionProject;
let cancelGetFutureExtensionProject;
let cancelGetInProgressExtensionProject;

const OportunitiesEducationalGroupUniversities = () => {
  const response = authenticatedAPI
    .get('/api/internal/educational-group/attractions/universities')
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getExtensionProjectFiltersToFinish = () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/extension-project/filter/to-finish`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getExtensionProjectFiltersFinished = () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/extension-project/filter/finished`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getExtensionProjectFilterOptions = () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/extension-project/filter/options`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const countAllAttractions = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/attractions/count`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const OportunitiesMacroAreas = (params, educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/macro-areas?${params}`
    : `/api/internal/university/attractions/macroareas`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCacheOptions = (educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/macro-areas/courses/periods`
    : `/api/internal/university/attractions/macroareas-courses`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCourses = (universities, macroareas, educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/courses?${universities}${macroareas}`
    : `/api/internal/university/attractions/macroareas/course?${macroareas}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getDisciplines = (params) => {
  const response = authenticatedAPI
    .get(`api/internal/university/extension-project/disciplines?${params}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getPeriod = (params, educationalGroup) => {
  let url = educationalGroup
    ? `/api/internal/educational-group/attractions/courses/periods?${params}`
    : `/api/internal/university/attractions/macroareas/course/period?${params}`;
  const response = authenticatedAPI
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCompetencies = () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/competences`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getAdditionalInfosAttraction = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};
const getInterests = () => {
  const response = authenticatedAPI
    .get(`/api/internal/university/attractions/interests`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

/* Extension project */

const getFutureExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/future-extension-project/${page}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetFutureExtensionProject = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getInProgressExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/in-progress-extension-project/${page}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetFutureExtensionProject = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getOpenedExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/opened-extension-project/${page}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetOpenedExtensionProject = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const cancelRequests = () => {
  cancelGetAllToFinishProjects && cancelGetAllToFinishProjects();
  cancelGetAllFinishedProjects && cancelGetAllFinishedProjects();
  cancelGetAllUnpublishedProjects && cancelGetAllUnpublishedProjects();
  cancelGetOpenedExtensionProject && cancelGetOpenedExtensionProject();
  cancelGetFutureExtensionProject && cancelGetFutureExtensionProject();
  cancelGetInProgressExtensionProject && cancelGetInProgressExtensionProject();
};

const getListToFinishExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/to-finish-extension-project/${page}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetAllToFinishProjects = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getDetailsExtensionProject = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/extension-project/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const countExtensionProjects = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/extension-project/count`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const countAttractionsEducationalGroup = () => {
  const response = authenticatedAPI
    .get(`api/internal/university/attractions-educational-group/count-group`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getExtensionProjectOptions = () => {
  const response = authenticatedAPI
    .options(`/api/internal/university/extension-project/create`)
    .then((res) => {
      let data = {};
      data.projectTypes = res.data.actions.POST.extension_project.children.type.choices.map(
        (option) => {
          return {
            value: option.value,
            label: option.display_name,
          };
        }
      );
      data.developmentObjectives = res.data.actions.POST.extension_project.children.development_objective.choices.map(
        (option) => {
          return {
            value: option.value,
            label: option.display_name,
          };
        }
      );
      return data;
    })
    .catch((error) => error);
  return response;
};

const createExtensionProject = async (data) => {
  return authenticatedAPI
    .post('/api/internal/university/extension-project/create', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res)
    .catch((error) => error);
};

const updateExtensionProject = async (data, uuid) => {
  return authenticatedAPI
    .put(`/api/internal/university/extension-project/${uuid}/update`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res)
    .catch((error) => error);
};

const extensionProjectFile = async (data, id) => {
  return authenticatedAPI
    .patch(`/api/internal/university/extension-project/${id}/file`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res)
    .catch((error) => error);
};

const getEditExtensionProjectValues = async (uuid) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/extension-project/${uuid}/update`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getListExtensionProjectStudents = async (
  extensionProjectId,
  limit,
  offset
) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/extension-project/${extensionProjectId}/students?limit=${limit}&offset=${offset}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getListExtensionProjectStudentsFinish = async (
  extensionProjectId,
  limit,
  offset,
  ordering
) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/extension-project/${extensionProjectId}/enrolled-students?limit=${limit}&offset=${offset}&ordering=${ordering}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const unpublishProject = async (extensionProjectId, data) => {
  const response = authenticatedAPI
    .put(
      `/api/internal/university/extension-project/${extensionProjectId}/unpublished`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFeedback = async (projectId, feedbackId) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/extension-project/${projectId}/feedback/${feedbackId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const removeStudent = async (extensionProjectId, studentId, data) => {
  const response = authenticatedAPI
    .put(
      `/api/internal/university/extension-project/${extensionProjectId}/students/${studentId}`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFeedbackStudent = async (studentId) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/extension-project/student-feedback/${studentId}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getListUnpublishExtensionProject = async (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/unpublished-extension-project/${page}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetAllUnpublishedProjects = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getListFinishedExtensionProject = async (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/finished-extension-project/${page}`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelGetAllFinishedProjects = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const postCreateFileEvidence = async (id, body, type) => {
  const currentType =
    type.includes('.png') || type.includes('.jpg') || type.includes('.jpeg')
      ? 'image'
      : 'file';

  const dataFile = new FormData();
  dataFile.append(currentType, body);
  dataFile.append('type', currentType);
  const response = authenticatedAPI
    .post(
      `/api/internal/university/extension-project/${id}/evidence/file`,
      dataFile,
      {
        headers: {
          'Content-Type': 'application/x-zip-compressed',
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const postCreateLinkEvidence = async (id, body, uniqueLink) => {
  const type = uniqueLink ? 'link_unique' : 'link';
  const response = authenticatedAPI
    .post(`/api/internal/university/extension-project/${id}/evidence/link`, {
      ...body,
      type: type,
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deleteEvidence = (id, evidenceId) => {
  const response = authenticatedAPI
    .delete(
      `/api/internal/university/extension-project/${id}/evidence/${evidenceId}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getEvidences = (id, query = 'limit=100000') => {
  const response = authenticatedAPI
    .get(`/api/internal/university/extension-project/${id}/evidence?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const putEditLinkEvidence = (id, idEvidence, body) => {
  const response = authenticatedAPI
    .put(
      `/api/internal/university/extension-project/${id}/evidence/${idEvidence}`,
      body
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const finalizeProjectForm = (extensionId, data) => {
  const response = authenticatedAPI
    .post(
      `/api/internal/university/extension-project/${extensionId}/finish`,
      data
    )
    .then((res) => res.data)
    .catch((error) => {
      throw error;
    });
  return response;
};

const getStudentEvidenceApi = (id, studentId, query) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/extension-project/${id}/students-evidence/${studentId}?${query}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getAllStudentEvidenceApi = (id, query) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/university/extension-project/${id}/students-evidence?${query}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const createAnalyzeEvidence = (evidenceId, data) => {
  const response = authenticatedAPI
    .post(
      `/api/internal/university/extension-project/evidence/${evidenceId}/analyze`,
      data
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getImagesIes = (id, fromStudent) => {
  const baseUrl = fromStudent
    ? `api/internal/students/extension-project/evidence/${id}/download`
    : `api/internal/university/extension-project/evidence/${id}/download`;

  const response = authenticatedAPI
    .get(baseUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'image/*',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export {
  OportunitiesMacroAreas,
  getCourses,
  getDisciplines,
  getPeriod,
  getCompetencies,
  getInterests,
  getCacheOptions,
  countAllAttractions,
  getAdditionalInfosAttraction,
  getFutureExtensionProject,
  getInProgressExtensionProject,
  getExtensionProjectOptions,
  createExtensionProject,
  extensionProjectFile,
  getDetailsExtensionProject,
  countExtensionProjects,
  getOpenedExtensionProject,
  getEditExtensionProjectValues,
  updateExtensionProject,
  getListExtensionProjectStudents,
  countAttractionsEducationalGroup,
  unpublishProject,
  getFeedback,
  removeStudent,
  getFeedbackStudent,
  getListUnpublishExtensionProject,
  getListFinishedExtensionProject,
  getListToFinishExtensionProject,
  postCreateFileEvidence,
  getListExtensionProjectStudentsFinish,
  deleteEvidence,
  getEvidences,
  cancelRequests,
  postCreateLinkEvidence,
  putEditLinkEvidence,
  finalizeProjectForm,
  getStudentEvidenceApi,
  getAllStudentEvidenceApi,
  createAnalyzeEvidence,
  getImagesIes,
  OportunitiesEducationalGroupUniversities,
  getExtensionProjectFiltersToFinish,
  getExtensionProjectFilterOptions,
  getExtensionProjectFiltersFinished,
};
