import useMediaQuery from 'hooks/useMediaQuery';
import { useEffect, useState } from 'react';

export default function TabIcon({ className }) {
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [currentFill, setCurrentFill] = useState('#242B35');

  useEffect(() => {
    if (isMobile) {
      setCurrentFill('#fff');
      return;
    }

    if (window.location.href.includes('feiras-e-eventos')) {
      setCurrentFill('#fff');
      return;
    }

    setCurrentFill('#242B35');
  }, [isMobile]);

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      display="inline"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        color: 'inherit',
        width: '30px',
        height: '24px',
        float: 'left',
        position: 'inherit',
        top: '3px',
        marginRight: '15px',
        textAlign: 'center',
        verticalAlign: 'middle',
        opacity: '0.8',
      }}
    >
      <path
        d="M18.7917 1.83333H16.5V0H13.75V1.83333H8.25V0H5.5V1.83333H3.20833C1.43917 1.83333 0 3.2725 0 5.04167V22H22V5.04167C22 3.2725 20.5608 1.83333 18.7917 1.83333ZM2.75 19.25V8.25H19.25V19.25H2.75ZM12.2989 12.3814H14.9435V13.5373L13.0744 14.619L13.849 16.973L12.9287 17.6156L11.0147 16.1352L9.08325 17.6284L8.19408 16.9592L8.95125 14.5924L7.06842 13.5456V12.3823H9.72767L10.4225 9.71758H11.6041L12.2989 12.3823V12.3814Z"
        fill={currentFill}
      />
    </svg>
  );
}
