import styled from 'styled-components';

export const HeaderH4 = styled.h4`
  font-weight: 500;
`;

export const InformationParagraph = styled.p`
  color: #606062;
  display: inline;
  flex-direction: column;
  margin-top: -5px;
`;

export const ComplementaryInformation = styled.span`
  margin-top: 15px;
  margin-bottom: 20px;
  display: block;
`;
