import styled from 'styled-components';

export const Title = styled.strong`
  color: ${(props) => props.color || '#009291'};
  font-size: 20px;
`;

export const SubTitle = styled.span`
  color: ${(props) => props.color || '#606062'};
  font-size: 18px;
  font-weight: 700;

  display: inline-flex;
  align-items: center;
  padding-bottom: 10px;

  :first-child {
    padding-left: ${(props) => props.pdLeft};
  }
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.color || '#606062'};
  margin-bottom: ${(props) => props.mgBottom || '24px'};
  padding-left: ${(props) => props.pdLeft};
`;

export const FooterMessage = styled(Description)`
  color: #777777;
`;

export const LegendBox = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  padding-left: 31px;
  width: 190px;;

  flex: none;

  .item {
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 56px;
  }

  .item svg {
    margin-bottom: -2px;
    margin-left: 3px;
  }

  strong {
    padding-right: 3px;
  }

  span {
    font-weight: 400;
  }
`;

export const GreenContainer = styled.div`
  width: 100%;
  background-color: ${({ loading}) => loading ? '#fff' : '#009291'};
  margin-top: 30px;
  height: 100%;

  padding: 20px;
  padding-left: 0px;

  position: relative;
  box-sizing: border-box;

  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
    padding: 0 31px;
  }

  .item {
    background-color: #fff;
    border-radius: 8px;
    width: 100%;
    padding: 20px;

    display: flex;
    flex-direction: column;
    margin: 0 auto;

    justify-content: space-between;
  }

  .item span {
    font-size: 14px;
    font-weight: 400;
    padding-bottom: 20px;
    color: #606062;
  }

  .item strong {
    font-size: 25px;
    color: #009291;
  }
`;
