import styled from 'styled-components';
import arrowLeft from 'assets/Oportutinites/Setaesquerda.svg';

export const ButtonArrowLeft = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${arrowLeft});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;

  &:disabled {
    background-color: #c8c8c8;
  }
`;
