import { useEffect, useState } from 'react';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';

export default function InactiveStudentsForm({ engineRef, egressed }) {
  const { step } = useSendEmail();
  const [fetchingOptions, setFeatchingOptions] = useState(false);
  const [options, setOptions] = useState({});

  const getOptions = async () => {
    setFeatchingOptions(true);
    const response = await sendEmailApi.getOptionsInactiveStudents(egressed);
    setOptions(response);
    setFeatchingOptions(false);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const props = {
    fetchingOptions,
    options,
    engineRef,
  };

  const steps = {
    1: <StepOne {...props} />,
    2: <StepTwo {...props} />,
  };

  const currentStep = steps[step];

  return <div>{currentStep}</div>;
}
