import React, { useContext } from 'react';
import { DateHelper } from '../../../Helpers/DateHelper';
import { BiCalendarAlt } from 'react-icons/bi';
import useMediaQuery from 'hooks/useMediaQuery';
import BaseLayoutContext from 'contexts/base-layout';
import {
  ContainerInformations,
  ContentInformation,
  Information,
  TicketInformation,
  TicketInformations,
} from '../styled';
import QRCode from 'qrcode.react';
import { TicketHandler } from '../../../Handlers/TicketHandler';
import { IoTicketOutline } from 'react-icons/io5';
import Ticket from 'assets/FairAndEvents/Ticket';
import Button from '../../Button';
import { downloadPDF } from '../TicketDownload';

function Presential({ modalTicketData }) {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const { universityColor, metadata } = useContext(BaseLayoutContext);

  const {
    name,
    event_start_at,
    event_end_at,
    registration,
    id,
    registrated_at,
    student,
    uuid,
  } = modalTicketData || {};

  const informations = [
    {
      icon: (
        <div>
          <BiCalendarAlt color={universityColor} size={18} />
        </div>
      ),
      information: DateHelper.formatRange(event_start_at, event_end_at),
    },
  ];

  if (!isMobile) {
    informations.push({
      icon: (
        <div>
          <Ticket color={universityColor} size={18} />
        </div>
      ),
      information: `Inscrição realizada dia ${DateHelper.format(
        registrated_at
      )}`,
    });
  }

  const createInformations = informations.map((item) => (
    <Information color={universityColor}>
      {item.icon}
      {item.information}
    </Information>
  ));

  const title = isMobile ? (
    <h5>{name}</h5>
  ) : (
    <h5>
      Ingresso: <span>{name}</span>
    </h5>
  );

  return (
    <>
      <ContentInformation>
        {title}
        <ContainerInformations>{createInformations}</ContainerInformations>
      </ContentInformation>

      <ContentInformation qrCode>
        {!isMobile && (
          <p>
            Apresente o QR Code abaixo para confirmar a sua presença no evento
          </p>
        )}

        <QRCode value={TicketHandler.generateLink(id, uuid)} />

        {!isMobile && (
          <TicketInformations>
            <TicketInformation color={'#767676'}>
              Código do ingresso: {uuid}
            </TicketInformation>
            <TicketInformation>{student?.name}</TicketInformation>
            <TicketInformation>ID: {student?.external_id}</TicketInformation>
            <TicketInformation>
              {student?.course} - {student?.period}º período
            </TicketInformation>
          </TicketInformations>
        )}
      </ContentInformation>

      <Button
        width="140px"
        onClick={() => downloadPDF(modalTicketData, metadata.university_logo)}
      >
        Baixar Ingresso
      </Button>
    </>
  );
}

export default Presential;
