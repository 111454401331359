import styled from 'styled-components';

export const ButtonDetails = styled.button`
  width: 100%;
  max-width: 272px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009291;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  position: ${(props) => (props.unpublishCard ? 'absolute' : 'unset')};
  bottom: ${(props) => (props.unpublishCard ? '20px' : 'unset')};
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;

  background-color: ${(props) => props.bgColor || '#009291'};
  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const ContainerCard = styled.div`
  width: 340px;
  height: ${(props) => (props.unpublishCard ? '432px' : '490px')};

  background: #ffffff;
  box-shadow: 0px 0px 13px rgb(0 0 0 / 6%);
  border-radius: 11.5652px;

  flex: none;

  position: relative;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 12px;

  .wrapper-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
  }

  .wrapper-content img {
    width: 18px;
    flex: none;
  }

  .wrapper-content svg {
    flex: none;
  }

  .wrapper-content p {
    padding-left: ${(props) => props.paddingLeft || '10px'};
    margin: 0;
    text-align: start;
    word-break: break-word;
  }
`;

export const DataContent = styled.div`
  width: 100%;
  padding: 19px 29px;
`;

export const Image = styled.div`
  width: 100%;
  height: 209px;

  background-color: #009291;

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75%;
  }
`;

export const Like = styled.div`
  width: 100%;
  max-width: 45px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0px 0px 11.6458px rgba(0, 0, 0, 0.4);

  position: absolute;
  top: 40px;
  top: ${(props) => (props.unpublishCard ? '20px' : '40px')};
  right: 10px;

  background: #ffffff;
  border-radius: 4px;

  p {
    margin: 0;
    padding-right: 3px;
  }
`;

export const Approved = styled(Like)`
  top: 10px;

  svg {
    flex: none;
  }

  p {
    margin: 0;
    padding-right: 6px;
  }
`;

export const Title = styled.p`
  font-size: 17px;
  font-weight: 700;
  color: #4b4b4d;
  margin-bottom: 21px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const ButtonEvidence = styled.button`
  width: 100%;
  max-width: 272px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffff;
  color: #009291;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #009291;
  border-radius: 4px;
  cursor: pointer;

  position: ${(props) => (props.unpublishCard ? 'absolute' : 'unset')};
  bottom: ${(props) => (props.unpublishCard ? '20px' : 'unset')};
`;
