import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import {
  ContainerLabelInput,
  UnInputLabel,
  UnInput,
  Error,
} from './Style/BaseInputs';

export default function SimpleInput({
  label,
  name,
  marginTop,
  type,
  value,
  maxWidth,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, newValue) => {
        inputRef.current.value = newValue;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput marginTop={marginTop}>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput
        ref={inputRef}
        error={error}
        type={type}
        defaultValue={defaultValue}
        onFocus={clearError}
        maxWidth={maxWidth}
        {...rest}
      />
      <Error error={error}>{error}</Error>
    </ContainerLabelInput>
  );
}
