import React, { useContext } from 'react';
import { shape, func, bool } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import BaseLayoutContext from 'contexts/base-layout';

const LiveSchema = Yup.object().shape({
  questionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
});

const LiveThreeForm = (props) => {
  const { classes, answered, initialValues, applyLiveQuiz, metadata } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <GridContainer justify="center" className={classes.paddingReset}>
      <GridItem className={`${classes.paddingReset} ${classes.textAlgC}`}>
        <Formik
          enableReinitialize
          validationSchema={LiveSchema}
          validateOnChange={false}
          validade
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            await applyLiveQuiz(values);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <List className={classes.list}>
                {/* Question 1 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <GridItem
                    className={`${classes.paddingReset} ${classes.textAlgC}`}
                  >
                    <h4>
                      <strong>
                        Recordar é viver! Tente responder com suas próprias
                        palavras as perguntas feitas a nossos convidados sobre
                        seus CONHECIMENTOS:
                      </strong>
                    </h4>
                  </GridItem>
                  <ul
                    className={`${classes.questionsList} ${classes.marginRAuto}`}
                  >
                    <li>
                      <strong>1 - Resolução de Problemas: </strong>
                      Como nossos conhecimentos nos auxiliam a resolver
                      problemas - requisito obrigatório do profissional do
                      futuro?
                    </li>
                    <li>
                      <strong>2 - Pensamento Crítico: </strong>
                      Como nossos conhecimentos moldam nossa visão crítica sobre
                      o mundo a nosso redor?
                    </li>
                    <li>
                      <strong>3 - Lifelong Learnig: </strong>
                      Como nossos conhecimentos serão transformados ao longo de
                      toda a vida?
                    </li>
                  </ul>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionOne && touched.questionOne,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionOne"
                    variant="filled"
                    id="questionOne"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionOne && touched.questionOne}
                    value={values.questionOne}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionOne &&
                      `Sua resposta tem ${values.questionOne.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionOne && touched.questionOne ? (
                    <FormHelperText>{errors.questionOne}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 2 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <GridItem
                    className={`${classes.paddingReset} ${classes.textAlgC}`}
                  >
                    <h4>
                      <strong>
                        Recordar é viver! Tente responder com suas próprias
                        palavras as perguntas feitas a nossos convidados sobre
                        suas HABILIDADES:
                      </strong>
                    </h4>
                  </GridItem>
                  <ul
                    className={`${classes.questionsList} ${classes.marginRAuto}`}
                  >
                    <li>
                      <strong>1 - Comportamentos: </strong>Como nossos
                      comportamentos refletem nossa competência?
                    </li>
                    <li>
                      <strong>2 - Diferenciação: </strong>Como nossas
                      habilidades podem nos tornar únicos e desenvolver uma
                      “marca profissional”?
                    </li>
                    <li>
                      <strong>3 - Criatividade e Inovação: </strong>
                      Como nossa capacidade criativa pode gerar soluções
                      inovadoras?
                    </li>
                  </ul>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionTwo && touched.questionTwo,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionTwo"
                    variant="filled"
                    id="questionTwo"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionTwo && touched.questionTwo}
                    value={values.questionTwo}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionTwo &&
                      `Sua resposta tem ${values.questionTwo.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionTwo && touched.questionTwo ? (
                    <FormHelperText>{errors.questionTwo}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 3 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <GridItem
                    className={`${classes.paddingReset} ${classes.textAlgC}`}
                  >
                    <h4>
                      <strong>
                        Recordar é viver! Tente responder com suas próprias
                        palavras as perguntas feitas a nossos convidados sobre
                        suas ATITUDES:
                      </strong>
                    </h4>
                  </GridItem>
                  <ul
                    className={`${classes.questionsList} ${classes.marginRAuto}`}
                  >
                    <li>
                      <strong>1 - Visão Intrapessoal: </strong>
                      Como nosso autoconhecimento reflete nosso potencial
                      profissional e determina nossas escolhas?
                    </li>
                    <li>
                      <strong>2 - Visão Interpessoal: </strong>Como nossas
                      capacidade de se relacionar, negociar e servir impactam
                      nossa carreira?
                    </li>
                    <li>
                      <strong>3 - Visão de Mundo: </strong>
                      Como a interdisciplinaridade e a visão de outras
                      realidades pode nos auxiliar a superar limitações?
                    </li>
                  </ul>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionThree && touched.questionThree,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionThree"
                    variant="filled"
                    id="questionThree"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionThree && touched.questionThree}
                    value={values.questionThree}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionThree &&
                      `Sua resposta tem ${values.questionThree.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionThree && touched.questionThree ? (
                    <FormHelperText>{errors.questionThree}</FormHelperText>
                  ) : null}
                </ListItem>
                {!answered && (
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={
                          isSubmitting || Object.keys(values).length !== 3
                        }
                        type="submit"
                        style={{
                          width: '250px',
                          borderRadius: '5px',
                          backgroundColor: universityColor,
                        }}
                        round
                      >
                        Enviar respostas
                      </Button>
                    </div>
                  </ListItem>
                )}
              </List>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

LiveThreeForm.propTypes = {
  classes: shape({}).isRequired,
  answered: bool.isRequired,
  applyLiveQuiz: func.isRequired,
  initialValues: shape({}).isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(LiveThreeForm);
