import React, { forwardRef } from 'react';
import { object, bool, func, array } from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@material-ui/core';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
// core components
import Button from 'components/CustomButtons/Button';
import ReasonsForm from './ReasonsForm';

import applicationReturnStyle from 'assets/jss/material-dashboard-pro-react/components/applicationReturnStyle';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ReasonsFormSchema = Yup.object().shape({
  selectedReasons: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(
      1,
      'Você deve selecionar ao menos um motivo para não aceitar a candidatura.'
    ),
  otherReason: Yup.string(),
});

const ApplicationReturnForm = (props) => {
  const {
    classes,
    openModal,
    setModal,
    options,
    handleApplicationReturn,
  } = props;

  const setRequestData = async (values) => {
    let data = {
      mark_as: 'not_interesting',
      reasons: [],
      reason_description: '',
    };

    await values.selectedReasons.map((reason) => {
      data.reasons.push(reason.value);
    });

    if (values.otherReason) {
      data.reason_description = values.otherReason;
    }

    return data;
  };

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modalReasons,
      }}
      open={openModal}
      transition={Transition}
      keepMounted
      onClose={() => setModal(!openModal)}
      aria-labelledby="modal-slide-title"
      aria-describedby="modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader + ' ' + classes.applicationModalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton + ' ' + classes.reasonCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={() => setModal(!openModal)}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          <strong>Qual o motivo para não aceitar a candidatura?</strong>
        </h4>
        <small>
          Por favor, indique em qual dos tópicos abaixo o aluno precisará se
          desenvolver mais para atender aos requisitos da vaga. Se preferir,
          insira também um feedback descrito justificando sua avaliação.
        </small>
      </DialogTitle>
      <DialogContent id="modal-slide-description" className={classes.modalBody}>
        <h5>Descreva abaixo:</h5>
        <Formik
          enableReinitialize={true}
          initialValues={{ selectedReasons: [] }}
          validationSchema={ReasonsFormSchema}
          validateOnChange={false}
          onSubmit={async (values, actions) => {
            const data = await setRequestData(values);
            await handleApplicationReturn(data);

            actions.setSubmitting(false);
            setModal(!openModal);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <ReasonsForm
                options={options}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                isSubmitting={isSubmitting}
                ReasonsFormSchema={ReasonsFormSchema}
              />
              <div className={classes.buttonContainer}>
                <Button link onClick={() => setModal(!openModal)}>
                  Cancelar
                </Button>
                <div className={classes.wrapper}>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className={classes.saveButton}
                    round
                  >
                    Enviar Recusa
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

ApplicationReturnForm.propTypes = {
  classes: object,
  openModal: bool,
  setModal: func,
  options: array,
  handleApplicationReturn: func,
};

export default withStyles(applicationReturnStyle)(ApplicationReturnForm);
