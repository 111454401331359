import React, { useState } from 'react';
import { DropDown, ActionArrow, Information, ItemTitle } from '../style';
import downArrow from 'assets/Matriz/downArrow.svg';
import course from 'assets/backoffice/course.svg';
import discipline from 'assets/backoffice/discipline.svg';
import { useToggleOpen } from '../utils/useToggleOpen';

export default function DropDownPending({ item }) {
  const { open, handleExpandChange } = useToggleOpen();

  return (
    <DropDown open={open}>
      <span className="list-name-tag">
        <ItemTitle title={open ? null : item.name} open={open}>
          {item.name}
        </ItemTitle>
        <ActionArrow onClick={handleExpandChange} open={open}>
          <img src={downArrow} />
        </ActionArrow>
      </span>
      {open ? (
        <Information>
          <img src={course} />
          Curso relacionado: {item.course}
        </Information>
      ) : null}
      {open ? (
        <Information>
          <img src={discipline} />
          Disciplina relacionada: {item.discipline}
        </Information>
      ) : null}
    </DropDown>
  );
}
