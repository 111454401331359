import FilterModal from 'components/FilterModal';
import React, { useContext, useEffect, useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, SpanError } from '../styles';
import { handleFieldMask } from 'utils/validation';
import { maskCnpj } from 'utils/inputMasks';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { useAPICalls } from '../../../../Hooks/apiCalls';
import {
  getListCanceledInvite,
  getListInviteAnswered,
  getListInviteCreated,
  getListInviteSent,
} from 'views/StaffPages/CompaniesManagement/Services';
import * as Yup from 'yup';

export function FilterInvitationSent({
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  zIndex,
  activeInternalTab,
  setQueryFilter,
  handleChangeStates,
  isFetchingCities,
  loading,
  getCountTabs,
  setCountTabInviteCreated,
  setCountTabInviteSent,
  setCountTabInviteAnswered,
  setCountTabInviteCanceled,
}) {
  const {
    setInviteCreated,
    setInviteSent,
    setInviteAnswered,
    setCanceledInvite,
    setInviteCreatedCount,
    setInviteSentCount,
    setInviteAnsweredCount,
    setCanceledInviteCount,
    setCurrentPage,
    limit,
  } = useContext(CompaniesManagementContext);

  const [errorDate, setErrorDate] = useState(false);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        expiration_range_after: Yup.date(),
        expiration_range_before: Yup.date().min(
          Yup.ref('expiration_range_after'),
          `A data final precisa ser maior do que a data inicial.`
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  const {
    getInviteCreated,
    getInviteSent,
    getInviteAnswered,
    getCanceledInvite,
  } = useAPICalls();

  useEffect(() => {
    setFilterState({});
  }, [activeInternalTab]);

  const agreementOptions = [
    { label: 'Sem termo de convênio', value: 'no_term' },
    { label: 'Termo de convênio vigente', value: 'has_term' },
    {
      label: 'Termo de convênio próximo ao vencimento',
      value: 'close_expiration',
    },
  ];

  function renderFilterContent() {
    return [
      <FilterGrid>
        <div>
          <Filter
            label={'Estado'}
            name={'state'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.states}
            settings={{
              onChange: handleChangeStates,
            }}
          />
          <Filter
            name={'city'}
            label={'Cidade'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.cities}
            settings={{
              disabled: !filterState['state'] || isFetchingCities,
              loading: isFetchingCities,
            }}
          />
        </div>
        <div>
          <Filter
            name={'legal_name'}
            label={'Nome da empresa'}
            type={FilterType.TEXT}
          />
          <Filter
            label={'CNPJ'}
            name={'organization_number'}
            type={FilterType.TEXT}
            onKeyUp={(event) => {
              handleFieldMask(event, maskCnpj);
            }}
            maxLength={18}
          />
        </div>
        {activeInternalTab === 2 && (
          <Filter
            label={'Tipo de atividade'}
            name={'activities_type'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.activities_type}
          />
        )}
        <div>
          <Filter
            label="Status do convênio"
            name={'agreement_status'}
            type={FilterType.SINGLE_SELECT}
            options={agreementOptions}
          />
        </div>
        <div>
          <Filter
            type={FilterType.DATE_RANGE}
            name={['expiration_range_after', 'expiration_range_before']}
            label={'Período de vigência'}
            settings={{
              placeholder: ['Data inicial', 'Data final'],
              onChange: () => {
                setErrorDate(false);
              },
            }}
            startYear={2019}
          />
        </div>
        {errorDate && (
          <SpanError>
            A data final precisa ser maior do que a data inicial.
          </SpanError>
        )}
      </FilterGrid>,
      <FilterGrid>
        <div>
          <Filter
            label={'Unidade'}
            name={'responsible_unities'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.responsible_unities}
          />
        </div>
        <div>
          <Filter
            label={'Usuário que realizou o cadastro'}
            name={'invite_responsible'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.invite_responsible}
          />
        </div>
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const existFilters = filters && Object.keys(filters).length > 0;

    if (existFilters) {
      const isValid = await validateData(filters);
      if (isValid === false) {
        return false;
      }
    }

    if (!existFilters) {
      onClearFilters();
      return;
    }

    if (existFilters && activeInternalTab === 0) {
      const queryString = queryParamsFromFilterObject(filters);
      setInviteCreated(null);
      setQueryFilter(queryString);
      setCurrentPage(1);
      const data = await getListInviteCreated(queryString);
      const { count, results } = data;
      setInviteCreated(results);

      setCountTabInviteCreated(count);
      setInviteCreatedCount(count);
    }

    if (existFilters && activeInternalTab === 1) {
      const queryString = queryParamsFromFilterObject(filters);
      setInviteSent(null);
      setQueryFilter(queryString);
      setCurrentPage(1);
      const data = await getListInviteSent(queryString);
      const { count, results } = data;
      setInviteSent(results);

      setCountTabInviteSent(count);
      setInviteSentCount(count);
    }

    if (existFilters && activeInternalTab === 2) {
      const queryString = queryParamsFromFilterObject(filters);
      setInviteAnswered(null);
      setQueryFilter(queryString);
      setCurrentPage(1);
      const data = await getListInviteAnswered(queryString);
      const { count, results } = data;
      setInviteAnswered(results);

      setCountTabInviteAnswered(count);
      setInviteAnsweredCount(count);
    }

    if (existFilters && activeInternalTab === 3) {
      const queryString = queryParamsFromFilterObject(filters);
      setCanceledInvite(null);
      setQueryFilter(queryString);
      setCurrentPage(1);
      const data = await getListCanceledInvite(queryString);
      const { count, results } = data;
      setCanceledInvite(results);

      setCountTabInviteCanceled(count);
      setCanceledInviteCount(count);
    }
  }

  async function onClearFilters() {
    setErrorDate(false);
    if (activeInternalTab === 0) {
      setInviteCreated(null);
      setQueryFilter(null);
      setCurrentPage(1);
      await getCountTabs();
      await getInviteCreated(`limit=${limit}&offset=0`);
    }

    if (activeInternalTab === 1) {
      setInviteSent(null);
      setQueryFilter(null);
      setCurrentPage(1);
      await getCountTabs();
      await getInviteSent(`limit=${limit}&offset=0`);
    }

    if (activeInternalTab === 2) {
      setInviteAnswered(null);
      setQueryFilter(null);
      setCurrentPage(1);
      await getCountTabs();
      await getInviteAnswered(`limit=${limit}&offset=0`);
    }

    if (activeInternalTab === 3) {
      setCanceledInvite(null);
      setQueryFilter(null);
      setCurrentPage(1);
      await getCountTabs();
      await getCanceledInvite(`limit=${limit}&offset=0`);
    }
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      tabs={['Dados da Empresa', 'Dados da Instituição']}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={zIndex}
      loading={loading}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
