import { authenticatedAPI } from 'services/api';

const getCompaniesInvites = async (params) => {
  const response = await authenticatedAPI
    .get(`/api/internal/university/companies-invites?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const sendCompanyInviteEmail = async (data) => {
  const response = await authenticatedAPI
    .post(`/api/internal/university/companies-invites/${data.id}/send-email`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompaniesFilters = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/university/companies-invites`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilteredCompanies = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies-invites?${params}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCompanyInvitesFilesUtils = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/companies-invites/file?${params}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const saveCompanyInvite = async (data) => {
  const dataSend = new FormData();
  dataSend.append(
    'agreement_document_one_file',
    data.files.agreementDocumentOneFile
  );
  dataSend.append(
    'agreement_document_two_file',
    data.files.agreementDocumentTwoFile
  );
  dataSend.append('data', JSON.stringify(data.data));
  const response = authenticatedAPI
    .post(`/api/internal/university/companies-invites`, dataSend, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const inviteExistingCompany = async (organizationNumber) => {
  const data = { organization_number: organizationNumber };
  const response = await authenticatedAPI
    .post('/api/internal/university/companies-invites/existing', data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateCompanyInvite = async (data) => {
  const dataSend = new FormData();
  dataSend.append(
    'agreement_document_one_file',
    data.files.agreementDocumentOneFile
  );
  dataSend.append(
    'agreement_document_two_file',
    data.files.agreementDocumentTwoFile
  );
  dataSend.append('fileUpdate', JSON.stringify(data.files.fileUpdate));
  dataSend.append('data', JSON.stringify(data.data));

  const response = authenticatedAPI
    .patch(
      `/api/internal/university/companies-invites/${data.data.id}`,
      dataSend,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deleteCompanyInvite = async (data) => {
  const response = await authenticatedAPI
    .delete(`/api/internal/university/companies-invites/${data.id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const isInviteRegistered = async (organizationNumber) => {
  const data = { organization_number: organizationNumber };

  const response = await authenticatedAPI
    .post(`/api/internal/university/companies-invites/exists`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const answerCompanyInviteById = async (id, checked) => {
  const data = { connect: !!checked };
  const response = authenticatedAPI
    .post(`/api/internal/university/companies-invites/${id}/answer`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const changeConnectInvites = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/companies-invites/connections/`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const cancelInviteById = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/university/companies-invites/${id}/cancel`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  cancelInviteById,
  inviteExistingCompany,
  isInviteRegistered,
  deleteCompanyInvite,
  getCompaniesInvites,
  sendCompanyInviteEmail,
  saveCompanyInvite,
  updateCompanyInvite,
  answerCompanyInviteById,
  getCompaniesFilters,
  getFilteredCompanies,
  getCompanyInvitesFilesUtils,
  changeConnectInvites,
};
