import React, { useEffect, useState } from 'react';
import { getJobsPartners } from '../../../../../services';

import Pagination from '../../../../../../../components/CustomPagination';

import JobCards from '../../../components/Cards';
import SkeletonJobsCard from '../../../components/JobsSkeleton';

import { Container, Content, Title } from './styled';
import { useScrollToDiv } from '../../../../../hooks/scrollToCurrentDiv';

import { isMasterLet } from 'views/StaffPages/jobOpeningsRebuild/JobController/PublishedJobsTabs';
import GenericMessage from 'components/GenericMessage';

export default function PublishedByPartners({ countJobs, seeMatch }) {
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [listJobsPartners, setListJobsPartners] = useState(null);

  const loadJobsData = async (page) => {
    const data = await getJobsPartners(page);
    const { results, count } = data;
    setCount(count);
    setListJobsPartners(results);
  };

  const handlePageChange = async (page) => {
    const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
    await loadJobsData(newPage);
    await countJobs();
  };

  const onPageChange = (page) => {
    setListJobsPartners(null);
    setCurrentPage(page);
    handlePageChange(page);
  };

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    setListJobsPartners(null);
    handlePageChange(`limit=${limit}`);
  }, [limit]);

  const [refContainer] = useScrollToDiv(listJobsPartners);

  return listJobsPartners === null ? (
    <Container ref={refContainer}>
      <Content>
        <SkeletonJobsCard quantity={randomIntFromInterval(1, 4)} />
      </Content>
    </Container>
  ) : listJobsPartners.length > 0 ? (
    <Container ref={refContainer}>
      <Content>
        {listJobsPartners.map((jobs) => (
          <JobCards
            jobName={jobs.name}
            isPartnerOffer={
              jobs.is_partner_offer ||
              (isMasterLet && !jobs.is_educational_group)
            }
            openedSince={jobs.opened_since}
            responsibleName={jobs.responsible_name}
            numberOfCandidates={jobs.number_of_candidates}
            status={jobs.status}
            jobs={jobs}
            jobID={jobs.id}
            seeMatch={seeMatch}
            setCount={setCount}
          />
        ))}
      </Content>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={count}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  ) : (
    <Container>
      <GenericMessage title="No momento, não há nenhuma vaga disponível." />
    </Container>
  );
}
