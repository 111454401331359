import styled from 'styled-components';

export const Card = styled.div`
  width: 95%;
  height: auto;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  background-color: ${(props) =>
    props.isDropping ? 'rgba(0, 146, 145, 0.09)' : '#FFF'};
  padding: 15px;

  &:hover {
    background-color: rgba(0, 146, 145, 0.09);
    border: 1px solid #009291;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  display: flex;
  justify-content: space-between;

  > img {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: #e5e5e5;
  width: 100%;
  margin: 10px 0px;
`;

export const AddSubject = styled.span`
  color: #009291;
  font-weight: 500;

  > span {
    cursor: pointer;
  }
`;

export const ButtonDetails = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #009291;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 10px;

  > img {
    margin-top: 2px;
  }
`;
