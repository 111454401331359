import styled from 'styled-components';
import search from 'assets/academicModeling/search.svg';

export const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
  margin: 30px 0px;
`;
export const Container = styled.div`
  padding: 46px 40px;
`;

export const ContainerCourse = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

export const Course = styled.div`
  border: 1px solid #c4c4c4;
  background-color: #f6f6f6;
  padding: 10px 14px;
  border-radius: 4px;
  min-width: 200px;
`;

export const ContainerHeaderAndCourses = styled.div`
  color: #606062;
  font-weight: bold;

  > span {
    color: #009291;
    font-weight: bold;
    cursor: pointer;
    margin-top: 15px;
    display: block;
  }

  > h4 {
    font-weight: bold;
  }
`;

export const SearchInput = styled.input`
  padding-left: 10px;
  padding-right: 35px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 300px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  margin-bottom: 20px;
  width: 332px;
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > span {
    color: #606062;
    font-weight: bold;
  }
`;
