import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 250px;
  height: fit-content;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
`;

export const GrayGradient = styled.div`
  height: 63px;
  width: 250px;
  border-radius: 10px 10px 0 0;
  left: -1px;
  top: -1px;
  background: linear-gradient(
    180deg,
    rgba(151, 167, 185, 0.5) 0%,
    rgba(151, 167, 185, 0.1) 100%
  );
  position: absolute;
`;

export const TextName = styled.span`
  font-size: 18px;
  color: ${({ color }) => color || '#606062'};
  margin: 0.5rem 0;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  color: #606062;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .modality-description {
    white-space: normal;
    overflow: visible;
    text-overflow: clip;
    max-width: none;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    content: '';
    background-color: ${({ color }) => color || '#009291'};
    opacity: 0.1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
`;

export const AreaAmount = styled.span`
  color: #ffffff;
  font-size: 12px;
  width: 24px;
  height: 17px;
  font-family: 'Roboto', sans-serif;
  border-radius: 2px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#009291'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MentorImage = styled.img`
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin: ${({ margin }) => margin || '0'};
  border: 1px solid #606062;
  z-index: 1;
`;

export const ShowDetailsButton = styled.button`
  background-color: #fff;
  color: ${({ color }) => color || '#009291'};
  border: 1px solid ${({ color }) => color || '#009291'};
  border-radius: 3px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
  flex: 1;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ScheduleButton = styled.button`
  background-color: ${({ color }) => color || '#009291'};
  color: #fff;
  border: none;
  border-radius: 3px;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
  flex: 1;

  &:hover {
    filter: brightness(0.9);
  }
`;
