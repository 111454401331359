import React, { useEffect, useState } from 'react';
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchableSelect from 'react-select';
import DateTimePicker from 'components/DateTimePicker';
import { func, number, shape } from 'prop-types';
import * as Yup from 'yup';
import { dangerColor } from 'assets/jss/material-dashboard-pro-react';
import styles from './styles';

const ProfessionFilter = (props) => {
  const { classes, filterOptions, filterProfessions, activeTabContent } = props;
  const [selectOptions, setSelectOptions] = useState({});
  const [selectFilters, setSelectFilters] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [dateIsValid, setDateIsValid] = useState(true);
  const [dateValueState, setDateValueState] = useState({
    startDate: '',
    endDate: '',
  });
  const dataFields = [
    { label: 'De', name: 'startDate' },
    { label: 'Até', name: 'endDate' },
  ];
  const filterDateSchema = Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date().min(
      Yup.ref('startDate'),
      'A data final precisa ser maior do que a data inicial.'
    ),
  });

  async function getFilters() {
    if (filterOptions) {
      const options = await filterOptions(selectFilters);
      setSelectOptions(options);
    }
  }

  const handleDateParams = async (dateName, value) => {
    setDateValueState((prevValues) => ({ ...prevValues, [dateName]: value }));
  };

  useEffect(() => {
    getFilters();
  }, []);

  useEffect(() => {
    filterProfessions(selectFilters);
    getFilters();
  }, [selectFilters]);

  useEffect(() => {
    if (
      dateValueState.startDate.length === 10 &&
      dateValueState.endDate.length === 0
    ) {
      setSelectFilters({
        ...selectFilters,
        start_date: dateValueState.startDate,
      });
    } else if (
      dateValueState.endDate.length > 0 &&
      dateValueState.startDate.length === 0
    ) {
      setDateIsValid(false);
    } else if (
      dateValueState.startDate.length === 10 &&
      dateValueState.endDate.length === 10
    ) {
      filterDateSchema.isValid(dateValueState).then((value) => {
        setDateIsValid(value);
        if (value) {
          setSelectFilters({
            ...selectFilters,
            start_date: dateValueState.startDate,
            end_date: dateValueState.endDate,
          });
        }
      });
    }
  }, [dateValueState]);

  const handleReset = () => {
    setSelectFilters([]);
    setDateIsValid(true);
    Object.keys(dateValueState).forEach((key) => {
      setDateValueState((prevValues) => ({ ...prevValues, [key]: '' }));
    });
    Object.keys(selectedValues).forEach((key) => {
      setSelectedValues((prevValues) => ({ ...prevValues, [key]: [] }));
    });
  };

  return (
    <List style={{ position: 'sticky', top: 0, zIndex: 0, paddingTop: 0 }}>
      <h4 style={{ marginTop: 0 }}>Filtro avançado</h4>
      {activeTabContent === 0 && (
        <div>
          {/* Cursos */}
          <ListItem className={classes.listItemPaddingZeroSides}>
            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="selectedCourse" shrink>
                Cursos
              </InputLabel>
              <SearchableSelect
                name="selectedCourse"
                id="selectedCourse"
                isMulti
                InputLabel={{
                  shrink: true,
                }}
                placeholder="Selecione"
                isSearchable
                rows={3}
                classNamePrefix="react-select"
                className={classNames([
                  classes.selectSection,
                  classes.selectSectionAutoHeight,
                  classes.selectSectionCard,
                ])}
                value={selectedValues.associated_courses}
                onChange={async (event) => {
                  if (event) {
                    await setSelectFilters({
                      ...selectFilters,
                      associated_courses: event.map((item) => item.value),
                    });
                    setSelectedValues({
                      ...selectedValues,
                      associated_courses: event,
                    });
                  }
                }}
                options={selectOptions.associated_courses || []}
                defaultValue={[]}
              />
            </FormControl>
          </ListItem>

          {/* Requisitos de Mercado */}
          {selectOptions.associated_areas && (
            <div>
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="selectedCompanyType" shrink>
                    Requisitos de Mercado
                  </InputLabel>
                  <SearchableSelect
                    name="selectedCompanyType"
                    id="selectedCompanyType"
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder="Selecione"
                    isSearchable
                    isMulti
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames([
                      classes.selectSection,
                      classes.selectSectionAutoHeight,
                      classes.selectSectionCard,
                    ])}
                    value={selectedValues.additional_courses}
                    onChange={async (event) => {
                      if (event) {
                        await setSelectFilters({
                          ...selectFilters,
                          additional_courses: event.map((item) => item.value),
                        });
                        setSelectedValues({
                          ...selectedValues,
                          additional_courses: event,
                        });
                      }
                    }}
                    options={
                      selectOptions.associated_areas.additional_courses || []
                    }
                    defaultValue={[]}
                  />
                </FormControl>
              </ListItem>
              {/* Tendências */}
              <ListItem className={classes.listItemPaddingZeroSides}>
                <FormControl variant="filled" fullWidth>
                  <InputLabel htmlFor="selectedTendences" shrink>
                    Tendências
                  </InputLabel>
                  <SearchableSelect
                    name="selectedTendences"
                    id="selectedTendences"
                    InputLabel={{
                      shrink: true,
                    }}
                    placeholder="Selecione"
                    isSearchable
                    isMulti
                    rows={3}
                    classNamePrefix="react-select"
                    className={classNames([
                      classes.selectSection,
                      classes.selectSectionAutoHeight,
                      classes.selectSectionCard,
                    ])}
                    value={selectedValues.market_tendencies}
                    onChange={async (event) => {
                      if (event) {
                        await setSelectFilters({
                          ...selectFilters,
                          market_tendencies: event.map((item) => item.value),
                        });
                        setSelectedValues({
                          ...selectedValues,
                          market_tendencies: event,
                        });
                      }
                    }}
                    options={
                      (selectOptions &&
                        selectOptions.associated_areas &&
                        selectOptions.associated_areas.market_tendencies) ||
                      []
                    }
                    defaultValue={[]}
                  />
                </FormControl>
              </ListItem>
            </div>
          )}
        </div>
      )}
      {/* Tendências */}
      {activeTabContent === 1 && (
        <div>
          <ListItem className={classes.listItemPaddingZeroSides}>
            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="selectedTendencesCourses" shrink>
                Tendências
              </InputLabel>
              <SearchableSelect
                name="selectedTendencesCourses"
                id="selectedTendencesCourses"
                InputLabel={{
                  shrink: true,
                }}
                placeholder="Selecione"
                isSearchable
                isMulti
                rows={3}
                classNamePrefix="react-select"
                className={classNames([
                  classes.selectSection,
                  classes.selectSectionAutoHeight,
                  classes.selectSectionCard,
                ])}
                value={selectedValues.market_tendencies}
                onChange={async (event) => {
                  if (event) {
                    await setSelectFilters({
                      ...selectFilters,
                      market_tendencies: event.map((item) => item.value),
                    });
                    setSelectedValues({
                      ...selectedValues,
                      market_tendencies: event,
                    });
                  }
                }}
                options={
                  (selectOptions &&
                    selectOptions.areas &&
                    selectOptions.areas.market_tendencies) ||
                  []
                }
                defaultValue={[]}
              />
            </FormControl>
          </ListItem>
        </div>
      )}

      {activeTabContent === 2 && (
        <div>
          <ListItem className={classes.listItemPaddingZeroSides}>
            <FormControl variant="filled" fullWidth>
              <InputLabel htmlFor="areaType" shrink>
                Tipo de Área
              </InputLabel>
              <SearchableSelect
                name="areaType"
                id="areaType"
                InputLabel={{
                  shrink: true,
                }}
                isClearable
                placeholder="Selecione"
                isSearchable
                rows={3}
                classNamePrefix="react-select"
                className={classNames([
                  classes.selectSection,
                  classes.selectSectionAutoHeight,
                  classes.selectSectionCard,
                ])}
                value={selectedValues.subgroup}
                onChange={async (event) => {
                  if (event) {
                    await setSelectFilters({
                      ...selectFilters,
                      subgroup: [event.value],
                    });
                    setSelectedValues({
                      ...selectedValues,
                      subgroup: event,
                    });
                  } else {
                    await setSelectFilters({
                      ...selectFilters,
                      subgroup: [],
                    });
                    setSelectedValues({
                      ...selectedValues,
                      subgroup: null,
                    });
                  }
                }}
                options={selectOptions.subgroup || []}
                defaultValue={[]}
              />
            </FormControl>
          </ListItem>
        </div>
      )}
      {activeTabContent !== 1}

      <strong className={classes.dateFiltersTitle}>Período de Criação</strong>
      {dataFields.map((dateType) => (
        <ListItem className={classes.listItemPaddingZeroSides}>
          <FormControl variant="filled" fullWidth style={{ marginTop: '10px' }}>
            <InputLabel htmlFor={dateType.name} shrink>
              {dateType.label}
            </InputLabel>

            <DateTimePicker
              InputProps={{
                className: classes.input,
                style: {
                  border: !dateIsValid ? `1px solid ${dangerColor}` : null,
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: { border: '1px solid rgba(0, 0, 0, 0.54)' },
                FormLabelClasses: {
                  focused: classes.inputField,
                },
              }}
              className={classNames([
                classes.selectSection,
                classes.selectSectionAutoHeight,
                classes.selectSectionCard,
              ])}
              type="date"
              name={dateType.name}
              variant="filled"
              id={dateType.name}
              placeholder="Ex: 01/10/2012"
              value={dateValueState[dateType.name]}
              onChange={(event) => {
                handleDateParams(dateType.name, event.target.value);
              }}
            />
          </FormControl>
        </ListItem>
      ))}
      {!dateIsValid ? (
        <FormHelperText className={classes.helperText}>
          {dateValueState.endDate && !dateValueState.startDate
            ? 'É necessário definir uma data inicial.'
            : 'A data final precisa ser maior do que a data inicial.'}
        </FormHelperText>
      ) : null}
      <Button className={classes.resetButton} onClick={handleReset}>
        Limpar
      </Button>
    </List>
  );
};

ProfessionFilter.propTypes = {
  activeTabContent: number.isRequired,
  classes: shape({}).isRequired,
  filterOptions: func.isRequired,
  filterProfessions: func.isRequired,
};

export default withStyles(styles)(ProfessionFilter);
