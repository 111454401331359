import { getCurriculumsCompanyForDownload } from 'services/company/curriculum';
import { registerDownloadedCurriculum } from 'services/student/registerActivityStudent';
import { getCurriculums } from 'services/university/curriculum';
import {
  getCandidatesHistory,
  getCandidates,
  getOptionsFilterHistoryCompany,
  getOptionsFilterCompany,
} from 'views/JobOfferRebuild/services';
import {
  getOptionsFilterHistoryUniversity,
  getOptionsFilterUniversity,
  getUniversityCandidates,
  getUniversityCandidatesHistory,
} from 'views/StaffPages/jobOpeningsRebuild/services';
import jszip from 'jszip';
import Curriculum from 'components/CurriculumModalGenerate/Curriculum';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { getCoverLetterForDownloadCompanies } from 'services/company/coverLetter';
import { getCoverLetterForDownload } from 'services/university/coverLetter';
import CoverLetter from 'views/Student/Docs/CoverLetter/CoverLetterContainer/components/CoverLetter';
import qs from 'querystring';

// Toggle Select Engine Component

export const isAllCandidatesSelected = (selectedCandidates, candidates) =>
  selectedCandidates?.length === candidates?.length;

export function toggleSelectAll(
  selectedCandidates,
  setSelectedCandidates,
  candidates
) {
  if (selectedCandidates?.length === candidates?.length) {
    setSelectedCandidates([]);
  } else {
    setSelectedCandidates(candidates);
  }
}

// Toggle Select Child Component

export function toggleSelectCandidate(
  candidates,
  selectedCandidates,
  setSelectedCandidates
) {
  if (selectedCandidates.some((candidate) => candidate.id === candidates.id)) {
    setSelectedCandidates(
      selectedCandidates.filter((s) => s.id !== candidates.id)
    );
  } else {
    setSelectedCandidates([...selectedCandidates, candidates]);
  }
}

export function isCandidatesSelected(candidateId, selectedCandidates) {
  return selectedCandidates.some((candidate) => candidate.id === candidateId);
}

export const sortCandidatesByCriterion = (e, array, setCandidates) => {
  const sortedArray = [...array];

  switch (e?.value) {
    case 'mostRated':
      sortedArray.sort((a, b) => (a.score > b.score ? -1 : 0));
      break;
    case 'notEvaluated':
      sortedArray.sort((a, b) => (a.score < b.score ? -1 : 0));
      break;
    case 'matchPercentage':
      sortedArray.sort((a, b) => (a.match > b.match ? -1 : 0));
      break;
    case 'markedAsInteresting':
      sortedArray.sort((a, b) =>
        a.is_interesting && !b.is_interesting ? -1 : 0
      );
      break;
    default:
      break;
  }

  setCandidates(sortedArray);
};

//List of candidates

export const loadJobsData = async (
  environmentType,
  jobId,
  isPartner,
  selectedTab,
  setCandidates,
  query
) => {
  const controlTabPublishedJobs = {
    company: getCandidates,
    university: getUniversityCandidates,
  };

  const controlTabHistory = {
    company: getCandidatesHistory,
    university: getUniversityCandidatesHistory,
  };

  if (selectedTab === 'publishedJobs') {
    const data = await controlTabPublishedJobs[environmentType](
      jobId,
      isPartner,
      query
    );
    const { results } = data;
    setCandidates && setCandidates(results);
    return results;
  }

  if (selectedTab === 'history') {
    const data = await controlTabHistory[environmentType](
      jobId,
      isPartner,
      query
    );
    const { results } = data;
    setCandidates && setCandidates(results);
    return results;
  }
};

export const returnOptionsFilter = async (
  environmentType,
  jobId,
  isPartner,
  selectedTab
) => {
  let optionsFilter;

  const controlTabPublishedJobs = {
    company: getOptionsFilterCompany,
    university: getOptionsFilterUniversity,
  };

  const controlTabHistory = {
    company: getOptionsFilterHistoryCompany,
    university: getOptionsFilterHistoryUniversity,
  };
  if (selectedTab === 'publishedJobs') {
    optionsFilter = await controlTabPublishedJobs[environmentType](
      jobId,
      isPartner
    );
  }

  if (selectedTab === 'history') {
    optionsFilter = await controlTabHistory[environmentType](jobId, isPartner);
  }
  return optionsFilter;
};

//Bulk Download

export const returnConfigModal = (
  selectedCandidates,
  typeDownload,
  downloadLoading
) => {
  const totalCandidates = selectedCandidates.length;

  let objectResponse;

  const commonConfig = {
    bubbleText: 'Aviso!',
    modalWidth: 370,
    buttonConfirmWidth: 180,
    buttonConfirmHeight: 40,
    buttonCancelWidth: 80,
    buttonCancelHeight: 38,
    style: {
      fontSize: '16px',
      lineHeight: '21px',
      marginTop: '25px',
    },
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    loading: downloadLoading,
  };

  if (typeDownload === 'curriculum') {
    const curriculumCounter = selectedCandidates?.filter(
      (candidates) => candidates.has_curriculum === true
    ).length;

    const curriculumText =
      curriculumCounter === totalCandidates
        ? `Você realizou a seleção de ${totalCandidates} estudantes.`
        : `Entre os ${totalCandidates} estudantes selecionados, ${curriculumCounter} possuem currículo.`;

    objectResponse = {
      ...commonConfig,
      ParagraphText: curriculumText,
      TwoParagraphText:
        curriculumCounter === totalCandidates
          ? 'Tem certeza que deseja baixar o currículo de todos?'
          : 'Você tem certeza de que deseja baixar esses currículos?',
      buttonConfirmText:
        curriculumCounter > 1 ? 'BAIXAR CURRÍCULOS' : 'BAIXAR CURRÍCULO',
    };
  }

  if (typeDownload === 'coverLetter') {
    const coverLetterCounter = selectedCandidates?.filter(
      (candidates) => candidates.has_cover_letter === true
    ).length;

    const coverLetterText =
      coverLetterCounter === totalCandidates
        ? `Você realizou a seleção de ${totalCandidates} estudantes.`
        : `Entre os ${totalCandidates} estudantes selecionados, ${coverLetterCounter} possuem carta de apresentação.`;

    objectResponse = {
      ...commonConfig,
      ParagraphText: coverLetterText,
      TwoParagraphText:
        coverLetterCounter === totalCandidates
          ? 'Tem certeza que deseja baixar a carta de apresentação de todos?'
          : 'Você tem certeza de que deseja baixar essas cartas de apresentação?',
      buttonConfirmText:
        coverLetterCounter > 1 ? 'BAIXAR CARTAS' : 'BAIXAR CARTA',
    };
  }
  return objectResponse;
};

export const returnDownloadZippedCurriculum = async (
  selectedCandidates,
  environmentType,
  closeModal,
  setControlLoading
) => {
  const curriculumDownloadHandlers = {
    company: getCurriculumsCompanyForDownload,
    university: getCurriculums,
  };
  const idsCandidatesWithCurriculum = selectedCandidates
    .filter((candidate) => candidate.has_curriculum)
    .map((candidate) => candidate.student.id);

  const queryString = qs.stringify({
    students: idsCandidatesWithCurriculum,
  });

  setControlLoading(true);

  const candidatesCurriculums = await curriculumDownloadHandlers[
    environmentType
  ](queryString);
  setControlLoading(false);

  const downloadedResumes = candidatesCurriculums.map(
    (candidate) => candidate.curriculum_id
  );

  await registerDownloadedCurriculum(downloadedResumes);

  let JSZip = new jszip();
  candidatesCurriculums.map((student) => {
    const MyDoc = <Curriculum curriculumData={student} />;

    const blob = pdf(MyDoc).toBlob();
    JSZip.file(
      `${student.academic_profile.name}-${student.academic_profile.external_id}.pdf`,
      blob,
      { binary: true }
    );
  });

  const zippedFiles = await JSZip.generateAsync({ type: 'blob' });
  saveAs(zippedFiles, `Currículos ${new Date().toLocaleDateString()}.zip`);
  closeModal(false);
};

export const returnDownloadZippedCoverLetter = async (
  selectedCandidates,
  environmentType,
  closeModal,
  setControlLoading
) => {
  const coverLetterDownloadHandlers = {
    company: getCoverLetterForDownloadCompanies,
    university: getCoverLetterForDownload,
  };

  const idsCandidatesWithCoverLetter = selectedCandidates
    .filter((candidate) => candidate.has_cover_letter)
    .map((candidate) => candidate.student.id);

  const queryString = qs.stringify({
    students: idsCandidatesWithCoverLetter,
  });
  setControlLoading(true);
  const candidateCoverLetters = await coverLetterDownloadHandlers[
    environmentType
  ](queryString);
  setControlLoading(false);

  let JSZip = new jszip();
  candidateCoverLetters.map((coverLetter) => {
    const MyDoc = <CoverLetter coverLetter={coverLetter} />;
    const blob = pdf(MyDoc).toBlob();
    JSZip.file(
      `${coverLetter.student.name}-${coverLetter.student.external_id}.pdf`,
      blob,
      { binary: true }
    );
  });
  const zippedFiles = await JSZip.generateAsync({ type: 'blob' });
  saveAs(
    zippedFiles,
    `Cartas de Apresentação ${new Date().toLocaleDateString()}.zip`
  );
  closeModal(false);
};
