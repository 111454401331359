import React, { useContext, useRef, useEffect } from 'react';
import {
  ModalX,
  InfoModalBackground,
  InfoModalContainer,
} from '../../Styles/Styled';
import information from '../../../../../assets/Oportutinites/information.svg';
import sucess from '../../../../../assets/Oportutinites/sucess.svg';
import { OportunitiesContext } from '../../Context/OportunitiesContext';

export default function InformationModal({
  informationHeader,
  errorMsg,
  type,
  isOpen,
  setModalOpen,
  finish,
  children,
  closeOtherModal,
  setModalOpenAtraction,
}) {
  const { setModalIsOpen } = useContext(OportunitiesContext);
  const topDiv = useRef(null);
  const scrollToTopDiv = () => {
    if (topDiv.current) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const setState = () => {
    if (closeOtherModal) {
      closeOtherModal();
    }
    if (finish) {
      setModalIsOpen(false);
      if (setModalOpenAtraction) {
        setModalOpenAtraction(false);
      }
    }
    setModalOpen(false);
  };
  useEffect(() => {
    scrollToTopDiv();
  }, [isOpen]);

  const returnCurrentImg = () => {
    return type === 'success' ? sucess : information;
  };
  return (
    <InfoModalBackground isOpen={isOpen} onClick={setState}>
      <div className="center-modal" ref={topDiv}>
        <InfoModalContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              textAlign: 'center',
              positon: 'static',
              top: '20px',
            }}
          >
            <ModalX onClick={setState} />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={returnCurrentImg()} style={{ width: '15%' }} />
            <h4
              style={{
                color: '#009291',
                maxWidth: '20em',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {informationHeader}
            </h4>
            <p>{errorMsg}</p>

            {children && children}
          </div>
        </InfoModalContainer>
      </div>
    </InfoModalBackground>
  );
}
