import React, { Fragment } from 'react';
import { object, func } from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import PortfolioProjectsForm from './PortfolioProjectsForm';
import withStyles from '@material-ui/core/styles/withStyles';
import BehavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import useDefineColor from 'constants/colors';

const PortfolioProjectsHelpContent = (props) => {
  const { classes, openModalWithContent, universityColor } = props;
  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Portfólio de Projetos
          </h2>
          <p className={classes.cardCategory}>
            Destaque trabalhos que tenham relação com seus interesses. Isso
            poderá deixar seu perfil ainda mais interessante para o mercado de
            trabalho.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/6yqOLkYeqzM"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
        {/* <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <br />
          <Button
            onClick={() =>
              openModalWithContent(
                mergeContextsHelper(<PortfolioProjectsForm />),
                "Adicionar Projeto"
              )
            }
          >
            Adicionar projeto
            <KeyboardBackspace className={classes.rightArrow} />
          </Button>
        </Grid> */}
      </Grid>
    </Fragment>
  );
};

PortfolioProjectsHelpContent.propTypes = {
  classes: object,
  openModalWithContent: func,
};

export default withStyles(BehavioralTestStyle)(PortfolioProjectsHelpContent);
