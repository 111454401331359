import React from 'react';
import { Elipsis } from './style';

export default function FormLoading({
  background,
  position,
  justifyContent,
  marginTop,
  marginLeft,
}) {
  return (
    <Elipsis
      background={background}
      position={position}
      justifyContent={justifyContent}
      marginTop={marginTop}
      marginLeft={marginLeft}
    >
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Elipsis>
  );
}
