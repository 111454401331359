import React from 'react';
import { Fragment } from 'react';
import { ButtonsContainer, FinishButton, NextButton, PrevsButton } from './styled';

function FooterForm({ haveNext, haveBack, prevsState,haveFinish, disabledButton }) {

  const returnButtons = () => {
    if (haveNext && !haveBack && !haveFinish) {
      return (
        <Fragment>
          <br />
          <NextButton />
        </Fragment>
      );
    }
    if (haveNext && haveBack && !haveFinish) {
      return (
        <Fragment>
          <PrevsButton onClick={prevsState} />
          <NextButton type="submit" />
        </Fragment>
      );
    }
    if (haveBack && !haveNext && !haveFinish) {
      return <PrevsButton onClick={prevsState} />;
    }

    if (haveFinish && haveBack) {
      return (
        <Fragment>
          <PrevsButton onClick={prevsState} />
          <FinishButton disabled={disabledButton}>Enviar</FinishButton>
        </Fragment>
      );
    }
  };

  return <ButtonsContainer>{returnButtons()}</ButtonsContainer>;
}

export default FooterForm;
