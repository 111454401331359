import React, { useContext } from 'react';
import { FilterCheckboxLi, FilterUl } from '../styles';
import { useFilterContext } from '../contexts/FilterContext';
import { FilterType } from './Filter';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterCheckbox({
  name,
  options,
  isTwoColumns = false,
  wrap = false,
}) {
  const { filterState, updateFilterState } = useFilterContext();
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <FilterUl
      className={`${isTwoColumns ? 'two-columns' : ''}${wrap ? ' wrap' : ''}`}
    >
      {options.map((option) => (
        <FilterCheckboxLi universityColor={universityColor}>
          <input
            type="checkbox"
            id={`${name}-${option.value}`}
            value={option.value}
            checked={
              filterState[name] && filterState[name].includes(option.value)
            }
            onChange={() =>
              updateFilterState(option.value, name, FilterType.CHECKBOX)
            }
          />
          <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
        </FilterCheckboxLi>
      ))}
    </FilterUl>
  );
}
