import { IndicateStudentForJob } from '../IndicateForJob';
import { TalentItemContainer, SkeletonDiv } from './style';
export function TalentItemSkeleton() {
  return (
    <TalentItemContainer>
      <div className="row">
        <div className="firstColumn">
          <h3 className="name">
            <SkeletonDiv
              width={'240px'}
              height={'24px'}
              margin={'0px 0px 10px 0px'}
            />
          </h3>
          <div className="infos">
            <SkeletonDiv
              width={'150px'}
              height={'16px'}
              margin={'0px 0px 10px 0px'}
            />
            <SkeletonDiv
              width={'270px'}
              height={'16px'}
              margin={'0px 0px 10px 0px'}
            />
            <SkeletonDiv
              width={'374px'}
              height={'16px'}
              margin={'0px 0px 10px 0px'}
            />
            <span>
              <SkeletonDiv
                width={'40%'}
                height={'16px'}
                margin={'0px 10px 10px 0px'}
              />
              <SkeletonDiv
                width={'40%'}
                height={'16px'}
                margin={'0px 0px 10px 0px'}
              />
            </span>
            <SkeletonDiv width={'172px'} height={'16px'} />
          </div>
        </div>
        <div className="secondColumn">
          <SkeletonDiv
            width={'142px'}
            height={'40px'}
            margin={'0px 0px 0px auto'}
          />
        </div>
      </div>
    </TalentItemContainer>
  );
}
