import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const DisplayNumber = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${(props) => `${props.size}px`};
  font-weight: bold;
  font-size: 18px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;

  &:hover {
    .bubble {
      display: block;
    }
  }

  .bubble {
    position: absolute;
    top: -50px;
    background-color: #222e56;
    min-width: 130px;
    width: auto;
    color: #fff;
    display: none;
  }

  .bubble:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #222e56 transparent;
    border-width: 11px 7px 0;
    bottom: -11px;
    left: 50%;
    margin-left: -7px;
  }
`;

export const Paragraph = styled.p`
  margin-top: 10px;
  max-width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    border-radius: 50%;
    margin-top: 5px;
    max-width: 15px;
    max-height: 15px;
    min-width: 15px;
    min-height: 15px;
    border: 1px solid #009291;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #009291;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    > div {
      top: 30px;
      display: ${(props) => (props.activeHelp ? `block` : 'none')};
      min-width: 200px;
      background-color: #f5f5f5;
      position: absolute;
      color: #000;
      border: gray solid 1px;

      border-radius: 4px;
      font-weight: 500;
      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 8px 10px;
        border-color: #f5f5f5 transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -10px;
        left: 92px;
      }

      &:before {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 10px 14px;
        border-color: gray transparent;
        display: block;
        width: 0;
        z-index: 0;
        top: -14px;
        left: 90px;
      }
    }
  }
`;
