import { Information } from './style';
import eye from 'assets/JobOffer/eye.svg';
import AcademicModelingApi from 'services/university/academicModeling';
import { useAcademicModeling } from '../../../../contexts/AcademicModelingContext';
import { useContext, useMemo, useState } from 'react';
import AssociationsModal from '../Modals/associations';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';
import CreateTagModal from '../Modals/createTag';
import OpportunitiesModal from '../Modals/OpportunitiesModal';
import BaseLayoutContext from 'contexts/base-layout';

export default function MarketItem({
  marketItem,
  contemplated = false,
  removeItemFromList,
  isTendencies,
  getNotContemplated,
  updateContemplated,
}) {
  const { selectedCourse } = useAcademicModeling();
  const [associations, setAssociations] = useState([]);
  const [openCreateAssociationModal, setOpenCreateAssociationModal] = useState(
    false
  );
  const [associationModalOpen, setAssociationModalOpen] = useState(false);
  const [openOpportunitiesModal, setOpenOpportunitiesModal] = useState(false);

  const { handleIsLoadingState } = useContext(BaseLayoutContext);

  const getAssociations = async () => {
    const response = await AcademicModelingApi.getAssociationsMarket(
      selectedCourse.id,
      marketItem.association_id
    );
    setAssociations(response.disciplines);
    setAssociationModalOpen(true);
  };

  const menusButtons = [
    {
      name: 'Adicionar oportunidade',
      onChange: () => setOpenOpportunitiesModal(true),
    },
    {
      name: 'Adicionar tag de conteúdo',
      onChange: () => setOpenCreateAssociationModal(true),
    },
  ];

  const handleCloseOpportunitiesModal = () => {
    setOpenOpportunitiesModal(false);
  };

  const handleSubmitOpportunity = () => {
    getNotContemplated();
    setOpenOpportunitiesModal(false);
  };

  const mountedCompetence = {
    label: marketItem.name,
    value: marketItem.id,
  };

  const tags = useMemo(() => {
    return (
      associations?.reduce((acc, discipline) => {
        return [
          ...acc,
          ...discipline.tags.map((tag) => {
            return {
              label: tag.name,
              value: tag.id,
            };
          }),
        ];
      }, []) || []
    );
  }, [associations]);

  const refetchMarket = (item) => {
    getAssociations();
    updateContemplated(item);
  };

  const createTagModal = useMemo(() => {
    const defaultDisciplines = associations.map((discipline) => {
      return {
        label: discipline.name,
        value: discipline.id,
      };
    });
    return (
      <CreateTagModal
        openModal={openCreateAssociationModal}
        setOpenModal={setOpenCreateAssociationModal}
        item={marketItem}
        courseId={selectedCourse.id}
        removeItemFromList={removeItemFromList}
        isTendencies={isTendencies}
        contemplated={contemplated}
        refetchMarket={refetchMarket}
        defaultDisciplines={defaultDisciplines}
        defaultTags={tags}
      />
    );
  }, [
    tags,
    openCreateAssociationModal,
    marketItem,
    selectedCourse.id,
    removeItemFromList,
    isTendencies,
    associations,
  ]);

  if (!contemplated) {
    return (
      <Information not_completed={true}>
        {openOpportunitiesModal && (
          <OpportunitiesModal
            handleIsLoadingState={handleIsLoadingState}
            handleCloseModal={handleCloseOpportunitiesModal}
            defaultCompetence={mountedCompetence}
            handleSubmitOportunity={handleSubmitOpportunity}
          />
        )}
        {createTagModal}
        <span className="HeaderOne">{marketItem.name}</span>
        <span className="HeaderTwo">{marketItem.opportunities}</span>
        <span className="HeaderThree">
          <ButtonWithMenu
            insideMenus={menusButtons}
            menuLabel="Ações"
            marginLeft={'-126px !important'}
          />
        </span>
      </Information>
    );
  }
  return (
    <Information>
      {associations.length > 0 && (
        <AssociationsModal
          marketItem={marketItem}
          associations={associations}
          openModal={associationModalOpen}
          setOpenModal={setAssociationModalOpen}
          setOpenCreateAssociationModal={setOpenCreateAssociationModal}
          tags={tags}
          refetchMarket={refetchMarket}
          isTendencies={isTendencies}
          removeItemFromList={removeItemFromList}
        />
      )}
      {createTagModal}
      <span className="HeaderOne" title={marketItem.name}>
        {marketItem.name}
      </span>
      <span className="HeaderTwo">{marketItem.disciplines}</span>
      <span className="HeaderThree">{marketItem.tags}</span>
      <span className="HeaderFour">
        {' '}
        <img onClick={getAssociations} src={eye} alt={'Ver detalhes'} />{' '}
      </span>
    </Information>
  );
}
