import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  Fragment,
} from 'react';
import * as Yup from 'yup';
import CloseButton from '../closeButtonModal';
import {
  Container,
  Header,
  WrapperFields,
  SpaceNoteInput,
  WrapperSendButton,
  WrapperActionsDisciplines,
  WrapperDisciplines,
  DesactiveButton,
  SubmitButton,
  RemoveDisciplines,
  SearchInput,
  WrapperSeeMore,
  SeeMoreButton,
  TrashIcon,
  PopUp,
  WrapperTrash,
  WrapperChangeMatrix,
  CountSelected,
} from './style';

import IconPerson from 'assets/JobOffer/IconPerson.svg';
import { Form } from '@unform/web';
import Input from 'components/UnformFields/inputField';
import NoteInput from 'components/UnformFields/noteInput';
import { Bar, SPAN, SpanItems } from '../style';

import logOff from 'assets/Matriz/logOff.svg';
import trash from 'assets/Matriz/trash.svg';
import {
  desactiveMatrix,
  getMatrixData,
  getSubjectsCourse,
  patchEditMatrix,
  removeDisciplinesInMatrix,
  setMatrixActual,
} from '../../../services/matrixServices';
import { MatrixContext } from '../../../contexts/MatrixContext';
import { matrixSchema } from './schemaValidation';
import SearchableSelectUn from '../../Form/SearchableSelect';
import ModalInformation from '../../informationModal';
import { WrapperBubble, Bubble } from '../dragItem/style';

export default function ModalEditAndDetails({
  handleClickClose,
  modalDetailAndEditProps,
  setModalDetailAndEditProps,
}) {
  const formRef = useRef(null);
  const {
    form,
    course,
    id,
    disciplines,
    isEdit,
    isActualMatrix,
    hasRemoved,
  } = modalDetailAndEditProps;

  const [totalPerPage, setTotalPerPage] = useState(15);
  const [filterDisciplines, setFilterDisciplines] = useState([...disciplines]);
  const [selectedDisciplines, setSelectedDisciplines] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [currentConfigModal, setCurrentConfigModal] = useState({});
  const [checkedAll, setCheckedAll] = useState(false);

  const configModalConfirm = {
    bubbleText: 'Atenção!',
    ParagraphText: `Ao substituir a matriz atual, o indicador de trabalhabilidade poderá sofrer alterações.
    <br/> <br/>
    Tem certeza que deseja realizar a substituição?`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
  };

  const configModalConfirmDesactive = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja desativar esta matriz? Caso precise,
     você poderá reativa-la na aba "matrizes desativadas".`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
    modalHeight: 340,
  };

  const configModalSucess = {
    bubbleText: 'Tudo certo!',
    ParagraphText: `As alterações foram salvas com sucesso.`,
    buttonConfirmText: 'OK, ENTENDI',
    icon: IconPerson,
    modalHeight: 290,
  };

  const configModalConfirmRemoveDisciplines = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja remover esta disciplina? Ao realizar
     essa alteração o indicador de trabalhabilidade poderá sofrer alterações.`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
  };

  const configModalNameAlreadyExists = {
    bubbleText: 'Atenção!',
    ParagraphText: `O nome escolhido para esta matriz já existe na sua base de
     matrizes. Por favor, escolha outro nome.`,
    buttonConfirmText: 'OK, ENTENDI',
    showButtonCancel: false,
    icon: IconPerson,
    modalHeight: 350,
  };

  const configModalExitWithoutSave = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja sair sem salvar as alterações realizadas?.`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
    modalHeight: 325,
  };

  const modalRef = useRef(null);

  useEffect(() => {
    if (!isRemoving.current) {
      if (modalRef && modalRef.current) {
        modalRef.current.scrollIntoView({ block: 'start', inline: 'nearest' });
      }
    }
  }, []);

  const {
    getMatrices,
    matricesCourse,
    setActualPageMatrix,
    successSaveEdit,
    setSuccessSaveEdit,
    getDisabledMatrices,
    isRemoving,
    limitDisciplines,
    currentPageDisciplines,
    setSubjectsCourse,
  } = useContext(MatrixContext);

  const searchableMatrices = matricesCourse
    .filter((item) => !item.is_actual)
    .map((item) => ({ label: item.name, value: item.id }));

  const handleConfirmRemoveDisciplines = async (arrayId) => {
    const query = `&limit=${limitDisciplines}&offset=${
      (currentPageDisciplines - 1) * limitDisciplines || 0
    }`;

    await removeDisciplinesInMatrix(id, arrayId);
    setSuccessSaveEdit(true);
    const { disciplines: matrixDisciplines } = await getMatrixData(id);
    await getMatrices(course);
    const { results } = await getSubjectsCourse(course, query);
    setSubjectsCourse(results);
    setModalDetailAndEditProps((prevs) => ({
      ...prevs,
      disciplines: matrixDisciplines,
      hasRemoved: true,
    }));
  };

  const handleRemoveDiscipline = async (
    arrayId,
    showConfirmationMessage = false
  ) => {
    isRemoving.current = true;
    if (!showConfirmationMessage) {
      await handleConfirmRemoveDisciplines(arrayId);
    } else {
      setConfirmModalOpen(true);
      setCurrentConfigModal({
        configModal: configModalConfirmRemoveDisciplines,
        functionConfirm: () => handleConfirmRemoveDisciplines(arrayId),
        functionCancel: () => {
          setConfirmModalOpen(false);
        },
      });
    }
  };

  const initialData = {
    name: form.name,
    description: form.description,
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setCheckedAll(true);
      const disciplinesId = disciplines
        .slice(0, totalPerPage)
        .map((item) => item.id);
      setSelectedDisciplines(disciplinesId);
      return;
    }
    setCheckedAll(false);
    setSelectedDisciplines([]);
  };

  const handleSelectDiscipline = (id, event) => {
    if (event.target.checked) {
      setSelectedDisciplines([...selectedDisciplines, id]);
      return;
    }
    const filterId = selectedDisciplines.filter((item) => item !== id);
    setSelectedDisciplines(filterId);
  };

  const mapSubjects = filterDisciplines.slice(0, totalPerPage).map((item) => (
    <Bar checked={selectedDisciplines.includes(item.id)} useBorder>
      {isEdit && (
        <WrapperBubble>
          <Bubble>selecionar</Bubble>
          <input
            type="checkbox"
            checked={selectedDisciplines.includes(item.id)}
            onChange={(event) => handleSelectDiscipline(item.id, event)}
          />
        </WrapperBubble>
      )}
      <SpanItems width="120px">{item.code}</SpanItems>
      <SpanItems width="230px">{item.name}</SpanItems>

      {isEdit && (
        <WrapperTrash onClick={() => handleRemoveDiscipline([item.id], true)}>
          <PopUp>remover</PopUp>
          <TrashIcon src={trash} />
        </WrapperTrash>
      )}
    </Bar>
  ));

  const handleConfirmSucessEdit = async () => {
    handleClickClose();
    await getMatrices(course);
    isRemoving.current = false;
  };

  const handleSubmit = async () => {
    const data = formRef?.current?.getData();

    formRef.current.setErrors({});

    try {
      const schema = matrixSchema;
      await schema.validate(data, {
        abortEarly: false,
      });

      if (data.actualMatrix) {
        handleChangeActualMatrix();
        return;
      }

      setConfirmModalOpen(true);
      const response = await patchEditMatrix(id, { ...data });
      if (response.status !== 200) {
        setCurrentConfigModal({
          configModal: configModalNameAlreadyExists,
          functionConfirm: handleCancelChangeMatrix,
        });
        setConfirmModalOpen(true);
        return;
      }
      setCurrentConfigModal({
        configModal: configModalSucess,
        functionConfirm: handleConfirmSucessEdit,
        functionCancel: () => {},
      });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handleChangeSearch = (event) => {
    const value = event.target.value;

    if (!value) {
      setTotalPerPage(15);
    }
    return setFilterDisciplines(
      [...disciplines].filter(
        (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()) ||
          item.code.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const handleCancelChangeMatrix = () => {
    setConfirmModalOpen(false);
  };

  const handleCancelDesactiveMatrix = () => {
    setConfirmModalOpen(false);
  };

  const handleConfirmSucess = async () => {
    setConfirmModalOpen(false);
    await getMatrices(course);
    handleClickClose();
    isRemoving.current = false;
  };

  const handleConfirmChangeMatrix = async () => {
    const data = formRef?.current?.getData();
    const matrixSetActualId = data.actualMatrix;
    await setMatrixActual(matrixSetActualId);
    const response = await patchEditMatrix(id, { ...data });
    if (response.status !== 200) {
      setCurrentConfigModal({
        configModal: configModalNameAlreadyExists,
        functionConfirm: handleCancelChangeMatrix,
      });
      setConfirmModalOpen(true);
      return;
    }
    setCurrentConfigModal({
      configModal: configModalSucess,
      functionConfirm: handleConfirmSucess,
      functionCancel: () => {},
    });
  };

  const handleConfirmDesativeMatrix = async () => {
    await desactiveMatrix(id);
    handleClickClose();
    isRemoving.current = false;
    await getMatrices(course);
    await getDisabledMatrices(course);
    setActualPageMatrix(1);
  };

  const handleDesactiveMatrix = async () => {
    setConfirmModalOpen(true);
    setCurrentConfigModal({
      configModal: configModalConfirmDesactive,
      functionConfirm: handleConfirmDesativeMatrix,
      functionCancel: handleCancelDesactiveMatrix,
    });
  };

  const handleChangeActualMatrix = () => {
    setCurrentConfigModal({
      configModal: configModalConfirm,
      functionConfirm: handleConfirmChangeMatrix,
      functionCancel: handleCancelChangeMatrix,
    });
    setConfirmModalOpen(true);
  };

  const handleClose = () => {
    isRemoving.current = false;
    if (successSaveEdit || !isEdit) {
      setSuccessSaveEdit(false);
      handleClickClose();
    } else {
      setCurrentConfigModal({
        configModal: configModalExitWithoutSave,
        functionConfirm: () => {
          setSuccessSaveEdit(false);
          handleClickClose();
        },
        functionCancel: () => {
          setConfirmModalOpen(false);
        },
      });
      setConfirmModalOpen(true);
    }
  };

  const handleClickSeeMore = () => {
    setTotalPerPage((prevs) => prevs + 15);

    if (checkedAll) {
      const disciplinesId = disciplines
        .slice(0, totalPerPage + 15)
        .map((item) => item.id);
      setSelectedDisciplines([...disciplinesId]);
    }
  };

  const handleClickSpanSelectAll = () => {
    const disciplinesId = disciplines.map((item) => item.id);
    setSelectedDisciplines([...disciplinesId]);
  };

  return (
    <Container ref={modalRef}>
      <CloseButton onClick={handleClose} />
      <ModalInformation
        modalOpenState={confirmModalOpen}
        config={currentConfigModal.configModal}
        buttonCancelFunction={currentConfigModal.functionCancel}
        buttonConfirmFunction={currentConfigModal.functionConfirm}
      />
      <Header>
        <h4>{form.name || 'Matriz Atual (renomeie)'}</h4>
        <span>{disciplines.length || 0} disciplinas relacionadas</span>
      </Header>

      <Form ref={formRef} onSubmit={handleSubmit} initialData={initialData}>
        <WrapperFields>
          <Input
            name="name"
            label="Nome da matriz*"
            placeholder={!isEdit ? '' : 'Digite aqui...'}
            maxLength={30}
            useCount={isEdit}
            disabled={!isEdit}
          />
          <SpaceNoteInput>
            <NoteInput
              name="description"
              label="Observação*"
              placeholder={!isEdit ? '' : 'Digite aqui...'}
              maxLength={500}
              useCount={isEdit}
              disabled={!isEdit}
            />
          </SpaceNoteInput>
        </WrapperFields>

        {isActualMatrix && isEdit && (
          <WrapperChangeMatrix>
            <h4>Substituir matriz atual</h4>
            <p>
              Selecione a nova matriz atual previamente cadastrada. Recomendamos
              que essa matriz atual já tenha disciplinas cadastradas
            </p>

            <SearchableSelectUn
              name="actualMatrix"
              options={searchableMatrices}
              isClearable
            />

            <span>
              <strong>Importante:</strong> Ao realizar essa alteração o
              indicador de trabalhabilidade poderá sofrer alterações
            </span>
          </WrapperChangeMatrix>
        )}
      </Form>

      <WrapperSendButton isEdit={isEdit}>
        {!isActualMatrix && isEdit && (
          <DesactiveButton onClick={handleDesactiveMatrix}>
            <img src={logOff} />
            Desativar matriz
          </DesactiveButton>
        )}
        {isEdit && (
          <SubmitButton onClick={handleSubmit}>Salvar alterações</SubmitButton>
        )}
      </WrapperSendButton>

      <WrapperDisciplines isEdit={isEdit}>
        <h5>Disciplinas associadas a esta matriz</h5>
        {disciplines.length > 0 ? (
          <Fragment>
            <WrapperActionsDisciplines>
              <SearchInput onChange={handleChangeSearch} placeholder="Buscar" />
              {isEdit && (
                <RemoveDisciplines
                  onClick={() =>
                    handleRemoveDiscipline(selectedDisciplines, true)
                  }
                  disabled={
                    !selectedDisciplines || selectedDisciplines.length === 0
                  }
                >
                  Remover disciplinas
                </RemoveDisciplines>
              )}
            </WrapperActionsDisciplines>

            {checkedAll && (
              <CountSelected>
                Todas as {selectedDisciplines.length} disciplinas nesta página
                estão selecionadas.{' '}
                {selectedDisciplines.length < disciplines.length && (
                  <span onClick={handleClickSpanSelectAll}>
                    Selecionar todas as {disciplines.length} disciplinas
                    cadastradas
                  </span>
                )}
              </CountSelected>
            )}

            <Bar>
              {isEdit && (
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={checkedAll}
                />
              )}
              <SPAN width="120PX">CÓDIGO</SPAN>
              <SPAN width="230px">DISCIPLINA</SPAN>
              {isEdit && <SPAN>AÇÕES</SPAN>}
            </Bar>
            {mapSubjects}
          </Fragment>
        ) : (
          <p>
            No momento, não existem disciplinas associadas a esta matriz. Para
            adicionar disciplinas nesta matriz, vá até a tela inicial e
            selecione as disciplinas de sua preferência.
          </p>
        )}
      </WrapperDisciplines>

      {filterDisciplines.length > 15 &&
        totalPerPage < filterDisciplines.length && (
          <WrapperSeeMore>
            <SeeMoreButton onClick={handleClickSeeMore}>Ver mais</SeeMoreButton>
          </WrapperSeeMore>
        )}
    </Container>
  );
}
