import { authenticatedAPI } from 'services/api';

export const registerActivityMenu = async (data) => {
  try {
    await authenticatedAPI.post(`/api/internal/students/history/menu`, data);
  } catch (err) {
    return err;
  }
};

export const registerAccessedCurriculum = async (ids) => {
  try {
    let data = {
      curriculums_ids: ids,
      accessed: true,
      downloaded: false,
    };
    await authenticatedAPI.post(
      `/api/internal/students/history/curriculum`,
      data
    );
  } catch (err) {
    return err;
  }
};

export const registerDownloadedCurriculum = async (ids) => {
  try {
    let data = {
      curriculums_ids: ids,
      accessed: false,
      downloaded: true,
    };
    await authenticatedAPI.post(
      `/api/internal/students/history/curriculum`,
      data
    );
  } catch (err) {
    return err;
  }
};
