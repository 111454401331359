import styled from "styled-components";

import X from 'assets/Oportutinites/X.svg';

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const InfoModalContainer = styled.div`
  width: 512px;
  min-height: 321px;
  margin-bottom: 5em;
  overflow: hidden;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const InfoModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(4px);
  border-radius: 12px !important;
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
  position: static;
  top: 20px;

  width: 100%;
  height: 40px;
`

export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 15%;
  }

  h4 {
    color: #009291;
    max-width: 20em;
    text-align: center;
    font-weight: bold;
  }
`

export const ButtonX = styled.button`
    width: 100%;
    max-width: 25px;
    height: 25px;

    background-color: #EDEDED;
    border: none;
    border-radius: 200px;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
`