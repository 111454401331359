import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 65px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 80px;
  gap: 20px;
`;

export const Title = styled.span`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  font-size: 20px;
`;
