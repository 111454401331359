import React, { Fragment, useRef } from 'react';

import {
  ModalBackground,
  DivModal,
  Title,
  GridWrapper,
  Information,
  Subtitle,
  BackToHome,
} from './styles';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import {
  BoxReaction,
  ButtonX,
  DivX,
  Image,
  WrapperBoxReaction,
} from '../ModalMoreInformation/styled';
import { BiDislike, BiLike } from 'react-icons/bi';
import { RiCloseFill } from 'react-icons/ri';

export default function ModalTargetAudienceExtensionProjects({
  item,
  setIsOpenModal,
}) {
  const setState = () => {
    setIsOpenModal(false);
  };

  const returnCourses = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].courses &&
        item.target_audiences[0].courses.length
      ) {
        const result = item.target_audiences[0].courses.map((item) => item);
        return result.join(' | ');
      }
    }
    return 'Todos os cursos';
  };

  const returnMacroAreas = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].macro_areas &&
        item.target_audiences[0].macro_areas.length
      ) {
        const result = item.target_audiences[0].macro_areas.map((item) => item);
        return result.join(', ');
      }
    }
    return 'Todas as áreas de atuação';
  };

  const returnDisciplines = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].disciplines &&
        item.target_audiences[0].disciplines.length
      ) {
        const result = item.target_audiences[0].disciplines.map((item) => item);
        return result.join(', ');
      }
    }
    return 'Todas as disciplinas';
  };

  const returnPeriods = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].semesters &&
        item.target_audiences[0].semesters.length
      ) {
        let result = item.target_audiences[0].semesters.map((item) => item);
        if (result.length <= 1) {
          return (result = result + 'º Semestre');
        }
        const arrayLength = result.length;
        result[arrayLength - 1] = `${result[arrayLength - 1] + 'º Semestre'}`;

        return result.join('º Semestre, ');
      }
    }
    return 'Todos os períodos';
  };

  const returnUnities = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].unities &&
        item.target_audiences[0].unities.length
      ) {
        const result = item.target_audiences[0].unities.map((item) => item);
        return result.join(', ');
      }
    }
    return 'Todas as Unidades';
  };

  const modalTargetAudience = useRef();

  const closeModalClickOutside = (e) => {
    if (modalTargetAudience.current === e.target) {
      setIsOpenModal(false);
    }
  };

  const closeModalX = () => {
    setIsOpenModal(false);
  };

  return (
    <Fragment>
      <ModalBackground
        ref={modalTargetAudience}
        onClick={closeModalClickOutside}
      >
        <DivModal>
          <div className="container-wrapper ">
            {item.attraction_image && item.attraction_image.logo ? (
              <Image>
                <img src={item.attraction_image.image} />
              </Image>
            ) : (
              <Image>
                {item.attraction_image && (
                  <img
                    src={item.attraction_image.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: '11.5652px',
                      borderTopRightRadius: '11.5652px',
                      position: 'absolute',
                      objectFit: 'cover',
                      top: 0,
                    }}
                  />
                )}
              </Image>
            )}
            <DivX>
              <ButtonX onClick={closeModalX}>
                <RiCloseFill size={25} />
              </ButtonX>
            </DivX>
            <WrapperBoxReaction>
              <BoxReaction>
                <BiLike size={48} color="#4B4B4D" />
                <span>
                  {item.total_reaction && item.total_reaction.total_liked}
                </span>
              </BoxReaction>
              <BoxReaction>
                <BiDislike size={48} color="#4B4B4D" />
                <span>
                  {item.total_reaction && item.total_reaction.total_disliked}
                </span>
              </BoxReaction>
            </WrapperBoxReaction>
            <div className="align-arrow">
              <BackToHome onClick={setState}>
                <HiOutlineArrowNarrowLeft size={32} color="#009291" />
              </BackToHome>
            </div>
            <Title> Público-Alvo </Title>
            <div className="container-box">
              <GridWrapper>
                <Subtitle>Áreas de atuação relacionadas:</Subtitle>
                <Information> {returnMacroAreas(item)} </Information>

                <Subtitle> Período: </Subtitle>
                <Information> {returnPeriods(item)} </Information>
              </GridWrapper>
              <GridWrapper>
                <Subtitle> Cursos relacionados: </Subtitle>
                <Information> {returnCourses(item)} </Information>

                <Subtitle> Unidades/Polos: </Subtitle>
                <Information> {returnUnities(item)} </Information>
              </GridWrapper>
            </div>
            <div className="container-box">
              <GridWrapper>
                <Subtitle> Disciplinas relacionadas: </Subtitle>
                <Information> {returnDisciplines(item)} </Information>
              </GridWrapper>
            </div>
          </div>
        </DivModal>
      </ModalBackground>
    </Fragment>
  );
}
