import Card from '../components/Card';
import { Footer, JobList } from '../style';

export default function OpenedJobs({
  vacanciesData,
  onOpenJobDetails,
  seeMorePaginationCallback,
  closeFunction,
}) {
  const currentPage = vacanciesData?.jobs?.current_page;
  const numberOfPages = vacanciesData?.jobs?.num_pages;

  const createCards = () => {
    return vacanciesData?.jobs?.results?.map((job) => {
      return (
        <Card
          key={job.id}
          vacancy={job}
          onOpenJobDetails={onOpenJobDetails}
          closeFunction={closeFunction}
        />
      );
    });
  };

  const params = `jobs_page=${currentPage + 1}`;

  return (
    <JobList>
      {createCards()}
      {currentPage < numberOfPages && (
        <Footer>
          <button onClick={() => seeMorePaginationCallback(params)}>
            Ver mais
          </button>
        </Footer>
      )}
    </JobList>
  );
}
