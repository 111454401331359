import React from 'react';
import { InfoModalBackground, InfoModalContainer } from './styles';
import LoadingUpdate from '../../LoadingUpdate/LoadigUpdate';

export default function LoadingModalChallenges({
  isOpenLoadingModalChallenge,
}) {
  return (
    <InfoModalBackground isOpen={isOpenLoadingModalChallenge}>
      <InfoModalContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '5em',
          }}
        >
          <LoadingUpdate />
          <h6 style={{ marginTop: '3.3em' }}>
            Estamos buscando as informações do desafio. Aguarde...
          </h6>
        </div>
      </InfoModalContainer>
    </InfoModalBackground>
  );
}
