import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  width: 350px;
  padding: 16px;

  border-radius: 6px;
  border: none;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  font-weight: bold;
  color: #fff;

  cursor: pointer;
`;

export const ButtonFinnalyAndNext = styled.button`
  width: 150px;
  padding: 10px;
  margin-bottom: 20px;

  color: #fff;
  font-size: 16px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  border-radius: 6px;
  border: none;

  cursor: pointer;

  transition: 0.4s;
`;

export const ContainerFinal = styled.div``;
export const ButtonFinal = styled.button`
  width: 120px;
  border-radius: 6px;
  justify-content: center;
  border: none;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: bold;
  align-items: center;
  padding: 8px;
  display: flex;
  color: #fff;
  cursor: pointer;
`;
