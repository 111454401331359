export default function DownBuilding({ size, color }) {
  return (
    <svg
      width={size}
      height={size - 1}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.332031 17.25V0.75H9.4987V4.41667H18.6654V17.25H0.332031ZM2.16536 15.4167H7.66536V13.5833H2.16536V15.4167ZM2.16536 11.75H7.66536V9.91667H2.16536V11.75ZM2.16536 8.08333H7.66536V6.25H2.16536V8.08333ZM2.16536 4.41667H7.66536V2.58333H2.16536V4.41667ZM9.4987 15.4167H16.832V6.25H9.4987V15.4167ZM11.332 9.91667V8.08333H14.9987V9.91667H11.332ZM11.332 13.5833V11.75H14.9987V13.5833H11.332Z"
        fill={color}
      />
    </svg>
  );
}
