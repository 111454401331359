import React from 'react';
import closeIcon from 'assets/Oportutinites/X.svg';

import { CloseButtonStyle } from './style';

export default function CloseButton({ onClick }) {
  return (
    <CloseButtonStyle onClick={onClick}>
      <img src={closeIcon} />
    </CloseButtonStyle>
  );
}
