import React, { useContext, useEffect, useRef } from 'react';
import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';

import { ModalBackground, ModalContainer, ModalX, Header } from './styled';

export default function Modal () {
  const {
    modalIsOpen,
    setModalIsOpen,
    modalContent,
    step,
    setStep,
    setOptionsId,
    setSelectOption,
    setFormData,
  } = useContext(OpportunitiesCompanyContext);

  const topModal = useRef(null);

  useEffect(() => {
    if (modalIsOpen === false) {
      setFormData({});
      setSelectOption('');
      setOptionsId('');
      setStep(0);
    }
  }, [modalIsOpen]);

  const ScrollToModalTop = () => {
    if (topModal.current) {
      topModal.current.scrollIntoView({ block: 'start', inline: 'nearest' });
    } else {
      return;
    }
  };

  useEffect(() => {
    ScrollToModalTop();
  }, [step]);

  return (
    <ModalBackground isOpen={modalIsOpen}>
      <ModalContainer>
        <Header>
          <ModalX ref={topModal} onClick={() => setModalIsOpen(false)} />
        </Header>
        {modalContent}
      </ModalContainer>
    </ModalBackground>
  );
}
