import styled from 'styled-components';
import Flag from '../../../../../../assets/JobOffer/flag.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  font-family: 'Roboto', sans-serif;

  margin-top: -76px;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 874px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .header {
    width: 100%;
    height: 68px;

    justify-content: space-between;
  }

  .header h1 {
    font-size: 30px;
    font-weight: 700;
    color: #009291;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 70px;
  padding: 38px;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;

  font-weight: 400;

  .description-job {
    font-size: 1.1rem;
    line-height: 1.4rem;
    color: #4b4b4d;
    margin-bottom: 33px;
  }
`;

export const ListCourses = styled.div`
  ul {
    list-style: none;
    font-size: 1rem;

    padding: 0;
    margin-bottom: 33px;
    margin-top: 0;
  }

  ul li {
    font-size: 1.1rem;
    margin-bottom: 12px;
    color: #4b4b4d;
  }

  ul li::before {
    content: url(${Flag});
    margin-right: 15px;
  }
`;

export const SimpleText = styled.div`
  font-size: 1.1rem;
  color: #4b4b4d;
  margin-bottom: 33px;
`;

export const SimpleLink = styled.a`
  font-size: 1.1rem;
  color: #4b4b4d;
  margin-bottom: 33px;
  word-break: break-all;
  text-decoration: underline;
`;

export const ButtonEdit = styled.button`
  width: 100%;
  max-width: 30px;
  height: 24px;

  cursor: pointer;

  padding: 0;
  background-color: transparent;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Subtitle = styled.strong`
  font-size: 1.4rem;
  padding-bottom: ${(props) => props.spaceBottom || '14px'};
  color: #009291;
`;

export const Title = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 5px;

  strong {
    font-size: 25px;
    font-weight: 500;
    color: #4b4b4d;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  height: 45px;
  margin: 30px 0;

  button {
    width: 100%;
    max-width: 222px;
    height: 45px;

    cursor: pointer;

    border: none;
    border-radius: 4px;

    background: #009291;

    font: 700 21px Roboto;
    color: #ffffff;
  }
`;
