import React, { useRef } from 'react';

import { ContainerFinishForm } from './styled';
import { Form } from '@unform/web';
import InputDesc from 'components/Opportunities/Inputs/InputDescription';

export default function FinishForm({ formRef }) {
  return (
    <ContainerFinishForm>
      <h4>Detalhes do evento</h4>
      <p>
        Caso queira, insira informações sobre o evento para a visualização dos
        estudantes e demais usuários da sua instituição.
      </p>

      <Form ref={formRef}>
        <InputDesc name="feedback" />
      </Form>
    </ContainerFinishForm>
  );
}
