import styled from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  background-color: #eef2f4;
  color: ${({ color }) => color};
  display: flex;
  gap: 5px;
  padding: 2px 10px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;
