import styled from 'styled-components';

export const Card = styled.div`
  width: 720px;
  height: 300px;
  border-radius: 8px;
  background-color: #fff;
  margin-top: 55px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const HeaderH5 = styled.h5`
  color: #009291;
  font-size: 26px;
  font-weight: 400;
`;

export const Paragraph = styled.p`
  max-width: 450px;
`;

export const InformationH6 = styled.h6`
  font-size: 13px;
`;

export const WrapperForm = styled.div`
  width: 250px;

  > form > div {
    .css-1pcexqc-container {
      .css-kj6f9i-menu {
        z-index: 99999999999;
      }
    }
`;
