import * as Yup from 'yup';

export const rescheduleStepOneValidationSchema = Yup.object().shape({
  mentor: Yup.string().nullable().required('Este campo é obrigatório'),
  date: Yup.string().nullable().required('Este campo é obrigatório'),
  hours: Yup.string().nullable().required('Este campo é obrigatório'),
});

const mentorshipModalityValidator = (condition, schema) =>
  Yup.string().when('mentorship_modality', {
    is: condition,
    then: schema,
    otherwise: Yup.string().nullable(),
  });

const stripHtml = (html) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const rescheduleStepTwoValidationSchema = Yup.object().shape({
  rescheduling_reason: Yup.string()
    .trim()
    .required('Este campo é obrigatório')
    .test(
      'is-filled',
      'Este campo é obrigatório',
      (value) => !['', '<p><br></p>'].includes(value)
    )
    .test(
      'limite-de-caracteres',
      'Limite de 200 caracteres excedido',
      (value) => {
        if (!value) return true;
        return stripHtml(value).replace(/\s/g, '').length <= 200;
      }
    ),
  zip_code: mentorshipModalityValidator(
    'in_person',
    Yup.string()
      .required('Este campo é obrigatório')
      .test('is-valid-zip', 'Formato de CEP inválido', (value) => {
        if (!value) return true;
        return /^\d{5}-\d{3}$/.test(value);
      })
  ),
  address: mentorshipModalityValidator(
    'in_person',
    Yup.string().required('Este campo é obrigatório')
  ),
  address_number: mentorshipModalityValidator(
    'in_person',
    Yup.string().required('Este campo é obrigatório')
  ),
  district: mentorshipModalityValidator(
    'in_person',
    Yup.string().required('Este campo é obrigatório')
  ),
  access_link: mentorshipModalityValidator(
    'online',
    Yup.string().when('mentorship_modality', {
      is: 'online',
      then: Yup.string()
        .url('Formato de link inválido')
        .required('Este campo é obrigatório'),
      otherwise: Yup.string().nullable(),
    })
  ),
});
