import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: ${({ noScroll }) => noScroll && 'fixed'};
  z-index: 1300;

  ${({ noScroll }) =>
    noScroll &&
    css`
      position: fixed;
      width: 90%;
      display: inline-block;
      overflow-y: hidden;
    `}
`;
