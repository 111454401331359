import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  z-index: 1;

  width: 100%;
  height: 177px;
  border-radius: 4px;
  margin-top: 2.5rem;
  transition: all 0.2s ease-in-out;

  > input {
    display: none;
  }

  &.drop-overlay {
    border: 1px dashed #009291;
    padding: 1rem;
  }

  &.invalid-dropped-file {
    border: 1px dashed #da0505;
  }
`;

export const DropContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  b {
    font-weight: 700;
    font-size: 18px;
    color: #606062;
    text-align: ${(props) => (props.center ? 'center' : 'left')};
  }

  strong {
    font-weight: 700;
  }

  p,
  strong {
    font-size: 16px;
    color: #606062;
    max-width: 300px;
    margin-top: 1rem;
    margin-bottom: 0;
  }
`;

export const DropRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

const ActionButton = styled.button`
  display: flex;
  border: none;
  background-color: #fff;
  padding: 0 0.5rem;
  height: 32px;
  width: fit-content;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  gap: 4px;

  &:hover {
    filter: brightness(0.95);
  }
`;
export const AddImageButton = styled(ActionButton)`
  span {
    font-size: 16px;
    color: #009291;
    font-weight: 700;
  }

  svg {
    margin-top: -2px;
  }
`;

export const DeleteImageButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 4px;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: #00000055;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  border: 1px solid transparent;

  &:hover {
    background-color: #00000088;
    border: 1px solid #fff;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 148px;
  height: 148px;
  border-radius: 50%;
  border: ${(props) => (props.hasImage ? 'none' : '1px dashed #C4C4C4')};
  align-items: center;
  justify-content: center;
  position: relative;

  > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;
export const DropOverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > p {
    font-size: 16px;
    color: ${(props) => (props.hasError ? '#DA0505' : '#606062')};
    margin: 0;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 16px;
  color: #da0505 !important;
  margin-top: 0.5rem !important;
  white-space: nowrap !important;
`;
