export function IconSuitcase({ color }) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0892 2.68978H10.4475C10.3121 2.03144 9.95392 1.4399 9.43326 1.01486C8.91259 0.589831 8.26131 0.357294 7.58919 0.356445L6.42253 0.356445C5.75041 0.357294 5.09913 0.589831 4.57846 1.01486C4.0578 1.4399 3.69958 2.03144 3.56419 2.68978H2.92253C2.14926 2.6907 1.40793 2.99829 0.861154 3.54507C0.314374 4.09185 0.00678563 4.83318 0.00585938 5.60645L0.00585938 11.4398C0.00678563 12.213 0.314374 12.9544 0.861154 13.5012C1.40793 14.0479 2.14926 14.3555 2.92253 14.3564H11.0892C11.8625 14.3555 12.6038 14.0479 13.1506 13.5012C13.6973 12.9544 14.0049 12.213 14.0059 11.4398V5.60645C14.0049 4.83318 13.6973 4.09185 13.1506 3.54507C12.6038 2.99829 11.8625 2.6907 11.0892 2.68978ZM6.42253 1.52311H7.58919C7.94984 1.52461 8.30123 1.63749 8.59527 1.84633C8.88931 2.05517 9.11164 2.34975 9.23186 2.68978H4.77986C4.90007 2.34975 5.12241 2.05517 5.41645 1.84633C5.71049 1.63749 6.06187 1.52461 6.42253 1.52311ZM2.92253 3.85645H11.0892C11.5533 3.85645 11.9984 4.04082 12.3266 4.36901C12.6548 4.6972 12.8392 5.14232 12.8392 5.60645V7.35645H1.17253V5.60645C1.17253 5.14232 1.3569 4.6972 1.68509 4.36901C2.01328 4.04082 2.4584 3.85645 2.92253 3.85645ZM11.0892 13.1898H2.92253C2.4584 13.1898 2.01328 13.0054 1.68509 12.6772C1.3569 12.349 1.17253 11.9039 1.17253 11.4398V8.52311H6.42253V9.10645C6.42253 9.26115 6.48398 9.40953 6.59338 9.51892C6.70278 9.62832 6.85115 9.68978 7.00586 9.68978C7.16057 9.68978 7.30894 9.62832 7.41834 9.51892C7.52773 9.40953 7.58919 9.26115 7.58919 9.10645V8.52311H12.8392V11.4398C12.8392 11.9039 12.6548 12.349 12.3266 12.6772C11.9984 13.0054 11.5533 13.1898 11.0892 13.1898Z"
        fill={color}
      />
    </svg>
  );
}
