import styled from 'styled-components';

export const WrapperModal = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background-color: rgba(0, 0, 0, 0.67);
  position: fixed;
  left: 0;
  top: 0;
  align-items: center;
  justify-content: center;
`;

export const DivContainer = styled.div`
  width: 630px;
  min-height: 300px;
  height: auto;
  background-color: #ffff;
  margin-left: 200px;
  border-radius: 8px;
  @media screen and (max-width: 959px) {
    margin-left: 0;
  }
`;

export const StyledHeader = styled.h5`
  font-weight: 600;
  color: #009291;
  border-bottom: 1px solid #efeeec;
  padding: 15px 15px;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: start;
  justify-content: flex-end;
  margin-top: 10px;
`;
