import React from 'react';
import { Content, TitleBox, ContentBox, Divider, Box } from './styled';

export default function SkeletonProfessionalStatus() {
  return (
    <Content>
      <Box>
        <ContentBox widthContent="40%" />
        <Divider>
          <TitleBox />
          <ContentBox widthContent="100%" />
        </Divider>

        <Divider>
          <TitleBox />
          <ContentBox widthContent="100%" />
        </Divider>

        <Divider>
          <TitleBox />
          <ContentBox widthContent="100%" />
        </Divider>

        <Divider>
          <TitleBox />
          <ContentBox widthContent="100%" />
        </Divider>
        <Divider>
          <TitleBox />
          <ContentBox widthContent="100%" />
        </Divider>
      </Box>
    </Content>
  );
}
