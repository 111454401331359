import { useState, createContext, useContext } from 'react';

const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [selectOption, setSelectOption] = useState('');

  const setFormValues = (values) => {
    setFormData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  return (
    <FormContext.Provider
      value={{
        step,
        setStep,
        formData,
        setFormData,
        setFormValues,
        selectOption,
        setSelectOption,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export function useFormContext() {
  return useContext(FormContext);
}
