import styled from 'styled-components';
import { Input } from '../TodoTab/style';
import searchImg from 'assets/academicModeling/search.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      max-width: 547px;
      margin-top: 2rem;
      color: #606062;
      font-size: 16px;
    }
  }
`;

export const SearchInput = styled(Input)`
  max-width: 286px;
  padding-left: 36px;
  background-image: ${`url(${searchImg})`};
  background-repeat: no-repeat;
  background-position: 8px center;
`;

export const DisciplinesList = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2rem;
`;
