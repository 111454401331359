import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import HeaderReports from 'views/StaffPages/CustomTabInsights/CustomContainerReports/HeaderReports';
import GenericContainerReportsMarket from 'views/StaffPages/CustomTabInsights/CustomContainerReports/reportsMarket/GenericContainerReportsMarket';
import { useState, useEffect, useContext } from 'react';
import { DashboardContext } from '../../Context';

import GenericMessage from 'components/GenericMessage';
import { getCompaniesData } from '../../Services';
import { CompanySkeleton } from '../../UiComponents/Skeletons/CompanySkeleton';

export function CompanyTab(params) {
  const [insightList, setInsightList] = useState(null);

  const { fetchMe, queryFilter } = useContext(DashboardContext);

  const handleInsightData = async (filter) => {
    const response = await getCompaniesData(filter);
    if (response.status === 200) {
      setInsightList(response);
    }
    if (response.status === 500) {
      customSnackbar(
        `Não foi possível carregar os indicadores! Tente novamente mais tarde.`,
        'error'
      );
    }
  };

  useEffect(() => {
    if (fetchMe) {
      handleInsightData(queryFilter);
    }
  }, [fetchMe, queryFilter]);

  const returnContent = (array) => {
    if (array === null) {
      return [1, 2].map(() => <CompanySkeleton />);
    }

    if (array.status === 403) {
      return (
        <GenericMessage
          title="Problema no Cadastro"
          subtitle={`Não foram encontradas instituições de ensino associadas
             ao seu usuário. Por favor, entre em contato com o suporte.
          `}
        />
      );
    }

    if (array?.data?.result?.length > 0) {
      return array?.data?.result?.map((item) => (
        <>
          <HeaderReports />
          <GenericContainerReportsMarket
            title={item.name}
            directJobsCourses={item.direct_jobs_courses}
            indirectJobsCourses={item.indirect_jobs_courses}
            inviteBySystemCompany={item.invite_by_system_company}
            inviteByUniversity={item.invite_by_university}
            totalCompanies={item.total_companies}
          />
        </>
      ));
    }
  };

  return <div>{returnContent(insightList)}</div>;
}
