import { Form } from '@unform/web';
import { useRef, Fragment, useState, useMemo, useEffect } from 'react';
import SearchableSelectUn from 'views/StaffPages/Academic/components/CurriculumMatrix/components/Form/SearchableSelect';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import { valuesFalseOrTrue } from 'views/StaffPages/Csm/resources/selectOptions';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import BlockComponentForm from '../../../blockComponentForm';
import { ButtonArrowRight } from '../../../buttonArrowRight';

export default function StepOne({ fetchingOptions, options, engineRef }) {
  const formRef = useRef(null);

  const {
    handleClickArrowNext,
    setFormValues,
    formData,
    formMode,
  } = useSendEmail();

  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);

  const isViwerOnly = formMode === 'viwerOnly';

  const propertiesIsViwerOnly = {
    isDisabled: true,
    isMulti: true,
    disabled: true,
  };

  const createProperties = {
    isDisabled: fetchingOptions,
    isLoading: fetchingOptions,
    isMulti: true,
  };

  const commonProperties = isViwerOnly
    ? propertiesIsViwerOnly
    : createProperties;

  const {
    unities,
    professional_status,
    states,
    job_types,
    working_in_formation_area,
    professional_offers,
    special_needs,
    gender_identity,
    color_or_race,
  } = options;

  useEffect(() => {
    if (Object.keys(options).length > 0 && Object.keys(formData).length > 0) {
      formRef.current.setData({ ...formData });
    }
  }, [options, formData]);

  useEffect(() => {
    if (cities.length > 0 && formData.cities?.length > 0) {
      formRef?.current?.setFieldValue('cities', formData.cities);
    }
  }, [cities]);

  const citiesProps = {
    isDisabled: cities.length === 0,
    options: cities,
    isLoading: loadingCities,
  };

  useEffect(() => {
    if (cities.length === 0) {
      formRef.current.setFieldValue('cities', []);
    }
  }, [cities]);

  const handleSelectState = async (event) => {
    setLoadingCities(true);
    const values = event.map((item) => item.value);
    const { cities } = await sendEmailApi.getCitiesOptions(
      'active-options',
      values
    );
    setCities(cities);
    setLoadingCities(false);
  };

  const BlocksStepOne = [
    {
      blockTitle: '',
      components: [
        {
          type: 'select',
          name: 'unities',
          label: 'Unidade/Polo',
          props: { ...commonProperties, options: unities },
        },
        {
          type: 'betweenInput',
          globalProps: { ...commonProperties },
          inputs: [
            {
              name: 'name',
              label: 'Nome do estudante',
              props: { placeholder: 'Digite aqui...' },
            },
            {
              name: 'external_id',
              label: 'Código do estudante',
              props: { placeholder: 'Digite aqui...' },
            },
          ],
        },
        {
          type: 'betweenSelect',
          globalProps: { ...commonProperties },
          inputs: [
            {
              name: 'states',
              label: 'Estado',
              props: { options: states, onChange: handleSelectState },
            },
            { name: 'cities', label: 'Cidade', props: { ...citiesProps } },
          ],
        },
      ],
    },
    {
      blockTitle: 'Diversidade',
      components: [
        {
          type: 'betweenSelect',
          globalProps: { ...commonProperties },
          inputs: [
            {
              name: 'special_needs',
              label: 'PCD - Pessoa com deficiência',
              props: { options: special_needs },
            },
            {
              name: 'color_or_race',
              label: 'Cor/raça',
              props: { options: color_or_race },
            },
          ],
        },
        {
          type: 'select',
          name: 'gender_identity',
          label: 'Identidade de gênero',
          props: {
            ...commonProperties,
            options: gender_identity,
            isMulti: false,
            isClearable: true,
          },
        },
      ],
    },
    {
      blockTitle: 'Status profissional',
      components: [
        {
          type: 'select',
          name: 'professional_status',
          label: 'Status profissional',
          props: {
            ...commonProperties,
            options: professional_status,
            isMulti: false,
            isClearable: true,
          },
        },
        {
          type: 'select',
          name: 'job_types',
          label: 'Tipo de trabalho',
          props: {
            ...commonProperties,
            options: job_types,
          },
        },
        {
          type: 'select',
          name: 'working_in_formation_area',
          label: 'Na área de formação',
          props: {
            ...commonProperties,
            options: working_in_formation_area,
            isMulti: false,
            isClearable: true,
          },
        },
        {
          type: 'select',
          name: 'professional_offers',
          label: 'Interessado em',
          props: { ...commonProperties, options: professional_offers },
        },
        {
          type: 'betweenSelect',
          globalProps: {
            ...commonProperties,
            isMulti: false,
            isClearable: true,
          },
          inputs: [
            {
              name: 'has_curriculum',
              label: 'Com currículo gerado',
              props: { options: valuesFalseOrTrue },
            },
            {
              name: 'has_cover_letter',
              label: 'Com carta de apresentação',
              props: { options: valuesFalseOrTrue },
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];
    baseScroll.scrollTop = 0;
  }, []);

  const generateBlocks = () => {
    return BlocksStepOne.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        components={block.components}
      />
    ));
  };

  const verifyValuesInFirstForm = () => {
    engineRef.current.setErrors({});
    const listName = engineRef.current.getFieldValue('listName');
    const error = listName ? false : true;
    if (error) {
      engineRef.current.setFieldError(
        'listName',
        'O nome da lista é obrigatório'
      );
    }
    return { listName, error };
  };

  const handleSubmit = (data) => {
    const { listName, error } = verifyValuesInFirstForm();
    const isActiveForm = engineRef.current.getFieldValue('studentSitu');
    if (error) {
      return;
    }
    setFormValues({ active: isActiveForm, listName, ...data });
    handleClickArrowNext();
  };

  return (
    <div>
      <Form ref={formRef} onSubmit={handleSubmit}>
        {generateBlocks()}
        <ButtonArrowRight type="submit" />
      </Form>
    </div>
  );
}
