import { Container } from './styles';
import underConstructionImage from 'assets/indicators/under-construction-icon.png';

export function Benchmarking() {
  return (
    <Container>
      <img src={underConstructionImage} alt="Em construção" />
      <strong>Em construção</strong>
      <p>
        Em breve você terá acesso ao comparativo dos seus{' '}
        <b>estudantes em formação</b> e <b>formados,</b> trabalhando{' '}
        <b>na área</b> e <b>fora da área</b>.
      </p>
    </Container>
  );
}
