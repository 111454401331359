import styled from 'styled-components';
import ModalIconPerson from '../../../Modals/ModalIconPerson';
import CustomButton from 'components/CustomButtons/Button.jsx';

export const ModalContainer = styled(ModalIconPerson)`
  p.message {
    font-weight: 400;
    font-size: 20px;
    color: #606062;
    margin-bottom: 24px;
  }

  .acceptTerms {
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      font-size: 1rem;
      font-weight: 400;
      color: #949494;
    }
    margin: 12px 0px 20px 0px;

    .customCheck {
      padding: 0px 2px 0px 0px;
      svg {
        width: 24px;
        height: 24px;
        fill: #949494;
      }
    }
    .customChecked {
      svg{
        fill: #009291;
      }
    }
  }

  .customDisabled{
    background-color: #949494 !important;
  }

  .flex {
    display: flex;
  }
`

export const OutlineButton = styled(CustomButton)`
  color: #009291 !important;
  border: solid 1px #009291 !important;
  box-sizing: content-box;
  -webkit-box-sizing: border-box;
  background: #FFF !important;
  max-height: 44px;
  margin-right: 16px !important;
`