import React, { Fragment } from "react";
import { object } from "prop-types";
import { Grid, Divider } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import learningTrailsStyle from "assets/jss/material-dashboard-pro-react/views/learningTrailsStyle";

const Videos = props => {
  const { classes, data } = props;
  return (
    <Grid
      container
      justify="center"
      className={classes.fullScreenGridContainer}
    >
      <Grid item xs md={8} sm={12} className={classes.fullScreenGrid}>
        <h3>Vídeos</h3>

        {data.trail &&
          data.trail.map((video, key) => (
            <Fragment key={key}>
              <Grid
                container
                className={(classes.cardVideo, classes.blockMargin)}
              >
                <Grid item sm={12} md={12}>
                  <h4 className={classes.itemTitleMobile}>{video.title}</h4>
                  <img src={video.img} alt={video.itemTitle} width="100%" />
                  <p>{video.description}</p>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
            </Fragment>
          ))}
      </Grid>
    </Grid>
  );
};

Videos.propTypes = {
  classes: object,
  data: object
};

export default withStyles(learningTrailsStyle)(Videos);
