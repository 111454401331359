import GenericMessage from 'components/GenericMessage';
import { Grid } from '@material-ui/core';
import { Container, Paginate } from './styled';
import { TalentItem } from '../../TalentItem';
import { useContext, useEffect, useRef, useState } from 'react';
import { TalentsContext } from 'components/NewTalentList/contexts/TalentListContext';
import { generateUUID } from 'components/UploadEvidences/utils/generateUUID';
import { TalentItemSkeleton } from '../../TalentsItemSkeleton';
import { talentsFilterService } from 'components/NewTalentList/services/filters.service';

export function TalentsTab() {
  const {
    talents,
    loadingTalents,
    talentsData,
    getTalents,
    talentsTabLimit,
    setTalentsTabLimit,
    quickFilters,
    setQuickFilters,
    useQuickFilters,
  } = useContext(TalentsContext);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (talents.length === 0) {
      setQuickFilters({ active: false, inactive: false });
    }
  }, [talents.length]);

  const onPageChange = async (page) => {
    setCurrentPage(page);
    const offset = (page - 1) * talentsTabLimit;
    await getTalents(talentsFilterService.filterUrl, offset, talentsTabLimit);
  };

  const alterLimit = async (limit) => {
    setTalentsTabLimit(limit);
    await getTalents(talentsFilterService.filterUrl, 0, limit);
  };

  return (
    <>
      <Container>
        {loadingTalents ? (
          Array.from({ length: 4 }).map((i) => <TalentItemSkeleton />)
        ) : talents.length > 0 ? (
          <>
            <div className="quickFilters">
              <button
                className={`quickFilter ${quickFilters.active && 'active'}`}
                onClick={() => useQuickFilters('active')}
              >
                Estudantes ativos
              </button>
              <button
                className={`quickFilter ${quickFilters.inactive && 'active'}`}
                onClick={() => useQuickFilters('inactive')}
              >
                Estudantes inativos
              </button>
            </div>
            {talents.map((talent) => (
              <TalentItem key={generateUUID()} talent={talent} />
            ))}
          </>
        ) : (
          <GenericMessage
            title="Nenhum talento encontrado"
            subtitle="Para iniciar sua busca por talentos clique no botão “filtros”."
          />
        )}
      </Container>
      {talents.length > 0 && (
        <Paginate
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={talentsData.count ?? 0}
          limit={talentsTabLimit}
          onPageChange={onPageChange}
          setLimit={alterLimit}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
}
