import React, { Fragment } from 'react';

import {
  HeaderH4,
  InformationParagraph,
  ComplementaryInformation,
} from './style';

export default function Header({ count = 3 }) {
  return (
    <Fragment>
      <HeaderH4>Disciplinas / Unidades curriculares ({count})</HeaderH4>
      <InformationParagraph>
        Para adicionar uma disciplina/unidade* curricular em uma matriz,
        <strong> selecione e depois arraste</strong> a disciplina para a matriz
        de sua preferência.
        <br />
      </InformationParagraph>
      <ComplementaryInformation>
        *Considere como disciplina/unidade o componente curricular da sua
        instituição.
      </ComplementaryInformation>
    </Fragment>
  );
}
