import axios from "axios";
import getCookieValue from "utils/getCookieValue";
import URLS from "constants/urls";

const saveBehavioralTest = async data => {
  let answers = {
    "1": [],
    "2": [],
    "3": [],
    "4": [],
    "5": []
  };
  Object.keys(data).filter(item => {
    if (data[item] === "1") {
      answers[1].push(Number(item));
    }
    if (data[item] === "2") {
      answers[2].push(Number(item));
    }
    if (data[item] === "3") {
      answers[3].push(Number(item));
    }
    if (data[item] === "4") {
      answers[4].push(Number(item));
    }
    if (data[item] === "5") {
      answers[5].push(Number(item));
    }
  });

  const response = await axios
    .post(`${URLS.API_URL}/api/internal/quizzes/behavioral`, answers, {
      withCredentials: true,
      headers: {
        "X-CSRFTOKEN": getCookieValue("csrftoken", document.cookie)
      }
    })
    .then(res => res.data)
    .catch(error => error);

  return response;
};

export { saveBehavioralTest };
