import {
  AdherenceOutlinedBox,
  IconWrapper,
  Modal,
  Progress,
  ProgressBar,
  ProgressWrapper,
} from './styles';
import React, { useMemo } from 'react';
import {
  CloseButtonModal,
  Divider,
  GrayBox,
  OverlayModal,
  Row,
  StatusIconWrapper,
  Text,
  GridCourseName,
  LabelCourse,
  Labeltip,
} from '../../styles';
import { ExclamationIcon } from 'assets/ComponentIcons/ExclamationIcon';
import { LearningIcon } from 'assets/ComponentIcons/LearningIcon';
import JobBagIcon from 'assets/ComponentIcons/JobBagIcon';
import { CourseStatus, useWorkabilityIndicator } from '../../context';
import { GrowthIcon } from 'assets/ComponentIcons/GrowthIcon';
import { FaCheck } from 'react-icons/fa';
import { InfoCycleIcon } from 'assets/ComponentIcons/InfoCycleIcon';
import { Tooltip } from '../Tooltip';

export function PedagogicalPlanAdherenceModal({ isOpen, onClose }) {
  const { selectedAdherence, setSelectedAdherence } = useWorkabilityIndicator();
  const adherenceStatus = useMemo(() => {
    if (selectedAdherence) {
      const adherenceRatio =
        selectedAdherence?.percentAdherence /
        selectedAdherence?.percentAdherenceMedia;
      if (
        adherenceRatio === 1 ||
        selectedAdherence?.percentAdherence +
          selectedAdherence?.percentAdherenceMedia ===
          0
      ) {
        return CourseStatus.EQUAL_AVERAGE;
      } else if (adherenceRatio < 1) {
        return CourseStatus.BELOW_AVERAGE;
      } else {
        return CourseStatus.ABOVE_AVERAGE;
      }
    }
  }, [selectedAdherence]);

  const statusIcon = useMemo(() => {
    const icons = {
      [CourseStatus.EQUAL_AVERAGE]: <GrowthIcon />,
      [CourseStatus.ABOVE_AVERAGE]: <FaCheck size={12} color={'#fff'} />,
      [CourseStatus.BELOW_AVERAGE]: <ExclamationIcon size={15} />,
    };
    return icons[adherenceStatus] || <ExclamationIcon size={15} />;
  }, [adherenceStatus]);

  const descriptionComparativeRatio = useMemo(() => {
    if (selectedAdherence) {
      const descriptionComparativeRatio = {
        [CourseStatus.EQUAL_AVERAGE]: 'mesma média de outras instituições',
        [CourseStatus.ABOVE_AVERAGE]: `superando em ${
          selectedAdherence.percentAdherence -
          selectedAdherence.percentAdherenceMedia
        }% a média de outras instituições`,
        [CourseStatus.BELOW_AVERAGE]: `${
          selectedAdherence.percentAdherenceMedia -
          selectedAdherence.percentAdherence
        }% inferior a média de outras instituições`,
      };
      return descriptionComparativeRatio[adherenceStatus] || '';
    }
  }, [adherenceStatus]);

  const percentAdherence = useMemo(() => {
    return selectedAdherence?.percentAdherence;
  }, [selectedAdherence]);

  const percentAdherenceMedia = useMemo(() => {
    return selectedAdherence?.percentAdherenceMedia;
  }, [selectedAdherence]);

  function handleClose() {
    setSelectedAdherence(null);
    onClose();
  }

  const valuesZero =
    selectedAdherence &&
    selectedAdherence.percentAdherence +
      selectedAdherence.percentAdherenceMedia +
      selectedAdherence.percentMarketRequirementsAndTendences +
      selectedAdherence.percentOportunities ===
      0;
  const moreHeight = () => {
    let moreHeight = 530;
    if (selectedAdherence?.name) {
      moreHeight += 30;
    }
    if (valuesZero) {
      moreHeight += 50;
    }
    return moreHeight + 'px';
  };
  return (
    <OverlayModal isOpen={isOpen}>
      <Modal height={moreHeight()}>
        <CloseButtonModal onClick={handleClose} />
        {selectedAdherence?.name && (
          <>
            <GridCourseName>
              <LabelCourse color="#606062" fontSize="20px" fontWeight="700">
                {selectedAdherence?.name}
              </LabelCourse>
            </GridCourseName>
            <br />
          </>
        )}
        <h4>Média de aderência do plano pedagógico*</h4>
        {!selectedAdherence?.name && (
          <>
            <GridCourseName>
              <LabelCourse color="#777777" fontSize="13px" fontWeight="400">
                *O cálculo da média geral de aderência do plano pedagógico é
                realizado uma vez ao dia.
              </LabelCourse>
            </GridCourseName>
            <br />
          </>
        )}
        <GrayBox width={'171px'} marginTop={'0rem'} padding={'1rem'} gap={'0'}>
          <Row justifyContent={'flex-start'} gap={'0.5rem'}>
            <Text fontSize={'48px'} fontWeight={'700'} width={'fit-content'}>
              {percentAdherence}%
            </Text>
            <StatusIconWrapper size={'24px'} status={adherenceStatus}>
              {statusIcon}
            </StatusIconWrapper>
          </Row>
          <Text fontSize={'14px'} width={'fit-content'} color={'#777'}>
            Média: {percentAdherenceMedia}%
          </Text>
        </GrayBox>
        <Divider marginTop={'2rem'} marginBottom={'1.5rem'} />
        <p>
          Levando em consideração as
          <b> aprendizagens, tendências e requisitos de mercado</b> ofertadas
          aos {selectedAdherence?.name ? 'seus ' : ''} estudantes
          {selectedAdherence?.name
            ? ` do curso de ${selectedAdherence?.name}`
            : ' da sua' + ' instituição'}
          , o plano pedagógico está {percentAdherence}% aderente ao mercado,{' '}
          {descriptionComparativeRatio}, de acordo com o perfil de egresso
          {selectedAdherence?.name ? 'do curso' : ''}.
        </p>
        <Row marginTop={'1.5rem'} gap={'2rem'}>
          <AdherenceOutlinedBox>
            <Row gap={'0.5rem'} justifyContent={'flex-start'}>
              <IconWrapper>
                <LearningIcon />
              </IconWrapper>
              <strong>Aprendizagens</strong>
              <Tooltip
                text={`Para aumentar sua aderência, divulgue conteúdos no menu “Aprendizagem” que
                  abordem requisitos de mercado não contemplados no plano pedagógico desse curso.`}
                width={'247px'}
                showTooltip={true}
              >
                <InfoCycleIcon size={12} />
              </Tooltip>
            </Row>

            <ProgressWrapper>
              <ProgressBar>
                <Progress
                  finalProgress={selectedAdherence?.percentOportunities}
                />
              </ProgressBar>
              <p>{`${selectedAdherence?.percentOportunities}% ADERENTE`}</p>
            </ProgressWrapper>
          </AdherenceOutlinedBox>
          <AdherenceOutlinedBox>
            <Row gap={'0.5rem'} justifyContent={'flex-start'}>
              <IconWrapper>
                <JobBagIcon size={18} color={'#009291'} />
              </IconWrapper>
              <strong>Mercado</strong>
              <Tooltip
                text={`Para aumentar sua aderência, tenha atualizações constantes das tags de
                conteúdos ofertados pelo curso no menu Acadêmico.`}
                width={'247px'}
                showTooltip={true}
              >
                <InfoCycleIcon size={12} />
              </Tooltip>
            </Row>
            <ProgressWrapper>
              <ProgressBar>
                <Progress
                  finalProgress={
                    selectedAdherence?.percentMarketRequirementsAndTendences
                  }
                />
              </ProgressBar>
              <p>{`${selectedAdherence?.percentMarketRequirementsAndTendences}% ADERENTE`}</p>
            </ProgressWrapper>
          </AdherenceOutlinedBox>
        </Row>
        {valuesZero && (
          <Labeltip>
            <p>
              <b>Dica:</b> Para aumentar a aderência dos planos pedagógicos,
              basta acessar o menu ”Acadêmico”, selecionar o curso de sua
              preferência para realizar o mapeamento e seguir o passo a passo de
              4 etapas.
            </p>
          </Labeltip>
        )}
      </Modal>
    </OverlayModal>
  );
}
