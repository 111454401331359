import { Container, HeaderPrincipal } from '../../styles/BaseLayouSection';
import ButtonHelpStudent from './ButtonHelpStudent';
import GenericHeader from './Header/Header';
import { useContext, useEffect } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

export default function (props) {
  const { CardContent, helpContent, shouldHideItem } = props;

  const { universityColor } = useContext(BaseLayoutContext);
  const { setGamificationData, openModalWithContent } = useContext(
    ProfileContext
  );

  const openHelpModal = () => {
    openModalWithContent(helpContent);
  };

  return (
    <Container disabled={shouldHideItem}>
      <HeaderPrincipal>
        <GenericHeader
          disabled={shouldHideItem}
          universityColor={universityColor}
          {...props}
        />
        <ButtonHelpStudent
          disabled={shouldHideItem}
          onClick={() => openHelpModal()}
        />
      </HeaderPrincipal>
      {CardContent && (
        <CardContent
          shouldHideItem={shouldHideItem}
          setGamificationData={setGamificationData}
          {...props}
        />
      )}
    </Container>
  );
}
