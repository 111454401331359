import styled from 'styled-components';

export const Title = styled.p`
  width: 992px;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  margin: 18px 0;
  color: #009291;
  text-align: left;
`;

export const SubContainer = styled.div`
  width: 992px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    width: 504px;
    height: 56px;

    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const ButtonAddTag = styled.div`
  width: 167px;
  height: 45px;
  padding: 15px, 14px, 15px, 14px;
  background-color: #009291;

  border: none;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 13px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

  margin: -24px 0 0;

  cursor: pointer;
`;
