import StepIndicator from 'components/StepIndicator';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import { ContainerTitle } from './style';

export default function HeaderForm({ title, subTitle }) {
  const { step } = useSendEmail();

  return (
    <div>
      <StepIndicator count={2} currentStep={step} />
      <ContainerTitle>
        <h3>{title}</h3>
        <p>{subTitle}</p>
      </ContainerTitle>
    </div>
  );
}
