import LivesModal from './LivesModal';

const data = [
  {
    id: 'x2QnvWhxLW0',
    title: 'O Futuro do Mundo do Trabalho',
    description:
      'Entenda mais sobre o perfil do profissional do novo mundo do trabalho e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
  },
  {
    id: '7kOIzfsyALk',
    title: 'Hackeando o Novo Mundo do Trabalho',
    description:
      'Descubra como desenvolver melhor suas competências comportamentais, autoconhecimento e personalidade.',
    modalContent: LivesModal,
  },
  {
    id: 'LYmnHV5XRl8',
    title: 'Você na sua melhor versão (Programa de Sucesso do Estudante - PSE)',
    description:
      'Conheça os fatores decisivos para você se transformar em um profissional do futuro - à prova de crises existenciais e transformações digitais.',
    modalContent: LivesModal,
  },
  {
    id: '2R7tiEq_9Gg',
    title: 'Painel de Profissões do Futuro',
    description:
      'O principal investimento que um profissional pode fazer na sua carreira é se especializar em si mesmo. O autoconhecimento é a bússola interna necessária para se reposicionar e se readaptar ao mundo novo.  Escolha ser você!',
    modalContent: LivesModal,
  },
];

export default data;
