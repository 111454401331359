const notificationsAdapter = ({ count, next, previous, results }) => {
  return {
    count: count,
    next: next,
    previous: previous,
    results: resultsAdapter(results)
  };
};

const resultsAdapter = notifications =>
  notifications.map(({ id, notification_type, message, created_at }) => {
    return {
      id: id,
      link: setLink(notification_type),
      message: message,
      createdAt: created_at
    };
  });

const setLink = notificationType => {
  if (notificationType === "company_invited") {
    return "/networking";
  } else if (notificationType === "job_offer_related") {
    return "/vagas";
  }

  return "/#";
};

export { notificationsAdapter };
