import {
  AddMentorButton,
  Container,
  MentorListContainer,
  SeeMoreButton,
  WhiteBox,
} from './styled';
import { MentorCard } from './components/MentorCard';
import { MentorModal } from './components/MentorModal';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CreateMentorModal } from './components/CreateMentorModal';
import { CreateMentorContextProvider } from './components/CreateMentorModal/context';
import AuthContext from '../../../../../contexts/authentication';
import { mentorshipApi } from '../../services/api';
import { mentorAdapter } from './adapters/MentorAdapter';
import SkeletonCardMentorship from '../../components/SkeletonCard';
import GenericMessage from 'components/GenericMessage';
import { adapterSpecificDates } from './adapters/SpecificDatesAdapter';

export function MentorsTab({ fetchMentorshipsCount }) {
  const [mentorModalIsOpen, setMentorModalIsOpen] = useState(false);
  const [createMentorModalIsOpen, setCreateMentorModalIsOpen] = useState(false);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [loadingMentorList, setLoadingMentorList] = useState(true);
  const [loadingSelectedMentor, setLoadingSelectedMentor] = useState(null);
  const [mentors, setMentors] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMentorsNextPage, setHasMentorsNextPage] = useState(false);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = ['teacher', 'course_coordinator'].includes(
    staffRole
  );

  async function fetchMentors() {
    setLoadingMentorList(true);
    const offset = (page - 1) * 20;
    const mentorsResponse = await mentorshipApi.getMentorsList({
      queryParams: `limit=20&offset=${offset}`,
    });
    const updatedMentors = mentors ? [...mentors] : [];
    mentorsResponse.results.forEach((mentor) => {
      const mentorIndex = updatedMentors.findIndex(
        (element) => element.id === mentor.id
      );
      if (mentorIndex > -1) {
        updatedMentors[mentorIndex] = mentor;
      } else {
        updatedMentors.push(mentor);
      }
    });
    setMentors(updatedMentors);
    setHasMentorsNextPage(mentorsResponse.next !== null);
    setLoadingMentorList(false);
    fetchMentorshipsCount();
  }

  useEffect(() => {
    fetchMentors();
  }, []);

  const handleOpenMentorModal = async (mentor) => {
    setLoadingSelectedMentor(mentor.id);
    const [
      mentorDetailsResponse,
      specificDatesResponse,
      absencesResponse,
    ] = await Promise.all([
      mentorshipApi.getMentorDetails(mentor.id),
      mentorshipApi.getMentorSpecificDates(mentor.id),
      mentorshipApi.getMentorAbsences(mentor.id),
    ]);
    const newSelectedMentor = mentorAdapter(mentorDetailsResponse);
    newSelectedMentor.specific_dates = adapterSpecificDates(
      specificDatesResponse
    );
    newSelectedMentor.absences = absencesResponse;
    setLoadingSelectedMentor(null);
    setSelectedMentor(newSelectedMentor);
    setMentorModalIsOpen(true);
  };

  const handleCloseMentorModal = () => {
    setMentorModalIsOpen(false);
    setSelectedMentor(null);
  };

  const handleOpenCreateMentorModal = () => {
    setCreateMentorModalIsOpen(true);
  };

  const handleCloseCreateMentorModal = () => {
    setCreateMentorModalIsOpen(false);
    fetchMentors();
  };

  const handleNextPage = useCallback(async () => {
    setLoadingMentorList(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const response = await mentorshipApi.getMentorsList({
      queryParams: `limit=20&offset=${offset}`,
    });
    if (response) {
      setMentors([...mentors, ...response.results]);
      setHasMentorsNextPage(!!response.next);
    }
    setLoadingMentorList(false);
  }, [mentors, page]);

  const shouldHideCreateButton = mentors?.some(
    (mentor) => mentor.is_logged_staff && mentor.is_teacher_or_coordinator
  );

  return (
    <Container>
      {!loadingMentorList && !shouldHideCreateButton && (
        <AddMentorButton onClick={handleOpenCreateMentorModal}>
          {shouldRenderForStaff ? 'Quero ser um mentor' : 'Cadastrar um mentor'}
        </AddMentorButton>
      )}

      <WhiteBox>
        {mentors?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhum mentor encontrado"
            subtitle="Para cadastrar um mentor, clique no botão acima"
          />
        ) : (
          <MentorListContainer>
            {mentors?.map((mentor) => (
              <MentorCard
                key={mentor.id}
                mentor={mentor}
                onOpenMentorModal={handleOpenMentorModal}
                isLoading={loadingSelectedMentor === mentor.id}
              />
            ))}
            {loadingMentorList && (
              <SkeletonCardMentorship
                quantity={3}
                subTitleQuantity={3}
                cardWidth="215px"
                cardHeight="300px"
                cardMargin="0"
              />
            )}
          </MentorListContainer>
        )}
        {hasMentorsNextPage && !loadingMentorList && (
          <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
        )}
      </WhiteBox>
      <MentorModal
        mentor={selectedMentor}
        isOpen={mentorModalIsOpen}
        onClose={handleCloseMentorModal}
        handleEditMentor={handleOpenCreateMentorModal}
      />
      <CreateMentorContextProvider
        isOpen={createMentorModalIsOpen}
        onClose={handleCloseCreateMentorModal}
        mentor={selectedMentor}
        setMentor={setSelectedMentor}
      >
        <CreateMentorModal />
      </CreateMentorContextProvider>
    </Container>
  );
}
