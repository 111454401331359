import React, { useEffect, useState, Fragment } from 'react';
import { func, shape } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import GridContainer from 'components/Grid/GridContainer';
import { Grid } from '@material-ui/core';

import headerBackGroundImage from 'assets/img/charts-commerce-data.jpg';
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';

import ProfessionalStatusReport from 'views/StaffPages/ProfessionalStatusReport/ProfessionalStatusReport';
import TabContainer from 'components/TabContainer';

const StudentProfile = (props) => {
  const { handleIsLoadingState, classes, openSnackbar } = props;
  const [activeTabContent, setActiveTabContent] = useState(0);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const handleTabChange = (event, value) => {
    handleIsLoadingState(true);
    setActiveTabContent(value);
  };

  return (
    <Fragment>
      <CompanyHeader
        title="Acompanhamento de Empregabilidade"
        activeTab={activeTabContent}
        handleTabChange={handleTabChange}
        backgroundImage={headerBackGroundImage}
        tabs={[`Relatórios Padrão`]}
      />
      <GridContainer
        justify="center"
        className={classes.cardMarginTop}
        id="container"
      >
        <Grid item xs sm={12}>
          {activeTabContent === 0 && (
            <TabContainer>
              <ProfessionalStatusReport {...props} />
            </TabContainer>
          )}
        </Grid>
      </GridContainer>
    </Fragment>
  );
};

StudentProfile.propTypes = {
  handleIsLoadingState: func.isRequired,
  classes: shape.isRequired,
  openSnackbar: func.isRequired,
};

export default withStyles(styles)(StudentProfile);
