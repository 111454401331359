export default function CalendarArrow({ size = 21, color }) {
  return (
    <div>
      <svg
        width={size - 0}
        height={size}
        viewBox="0 0 21 21"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 2.36198H15.5V0.695312H13.8333V2.36198H7.16667V0.695312H5.5V2.36198H3C1.62167 2.36198 0.5 3.48365 0.5 4.86198V20.6953H20.5V4.86198C20.5 3.48365 19.3783 2.36198 18 2.36198ZM3 4.02865H18C18.46 4.02865 18.8333 4.40281 18.8333 4.86198V7.36198H2.16667V4.86198C2.16667 4.40281 2.54 4.02865 3 4.02865ZM2.16667 19.0286V9.02865H18.8333V19.0286H2.16667ZM11.6708 11.1786L14.2492 13.757L13.0708 14.9353L11.3333 13.1978V17.362H9.66667V13.1886L7.9375 14.927L6.75917 13.7486L9.32833 11.1795C9.97417 10.5336 11.025 10.5361 11.6717 11.1795L11.6708 11.1786Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
