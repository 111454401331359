import { Information } from './style';
import { SkeletonBox } from '../../../../style';

export default function MarketItemSkeleton({ notContemplated = false }) {
  function randomNameWithLength() {
    return Math.floor(Math.random() * (300 - 170 + 1) + 170);
  }

  if (notContemplated) {
    return (
      <Information not_completed={true}>
        <span className="HeaderOne">
          <SkeletonBox width={`${randomNameWithLength()}px`} height={'14px'} />
        </span>
        <span className="HeaderTwo">
          <SkeletonBox width={`14px`} height={'14px'} />
        </span>
        <span className="HeaderFour">
          <SkeletonBox width={`64px`} height={'29px'} />
        </span>
      </Information>
    );
  }
  return (
    <Information>
      <span className="HeaderOne">
        <SkeletonBox width={`${randomNameWithLength()}px`} height={'14px'} />
      </span>
      <span className="HeaderTwo">
        <SkeletonBox width={`14px`} height={'14px'} />
      </span>
      <span className="HeaderThree">
        <SkeletonBox width={`14px`} height={'14px'} />
      </span>
      <span className="HeaderFour">
        <SkeletonBox width={`14px`} height={'14px'} />
      </span>
    </Information>
  );
}
