import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import {
  UnInputLink,
  UnInputLabel,
  UnImageLink,
} from '../../Styles/unformComponentsStyled';
import { ContainerLabelInput } from '../../Styles/formStyled';
import link from '../../../../../assets/Oportutinites/link.svg';

export default function LinkInput({ label, name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnImageLink src={link} />
      <UnInputLink ref={inputRef} defaultValue={defaultValue} {...rest} />
    </ContainerLabelInput>
  );
}
