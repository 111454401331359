import React from 'react';
import { ButtonConfirm, WrapperDespublish, ButtonCancel } from '../styled';

export default function ConfirmDespublish({ functionConfirm, functionCancel }) {
  return (
    <WrapperDespublish>
      <ButtonConfirm onClick={functionConfirm}>
        Sim, quero despublicar
      </ButtonConfirm>
      <ButtonCancel onClick={functionCancel}>
        Não quero despublicar
      </ButtonCancel>
    </WrapperDespublish>
  );
}
