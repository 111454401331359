const forbidenSubdomains = ['workability', 'internship', 'admission'];

const defaultsSubdomain = {
  university: 'ies',
  company: 'empresas',
  workability: 'workability',
};

const localDomains = ['localhost'];
export { forbidenSubdomains, localDomains, defaultsSubdomain };
