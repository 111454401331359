import React from 'react';
import { Elipsis } from './style';

export default function FormLoading({}) {
  return (
    <Elipsis>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Elipsis>
  );
}
