import { GenericInternalSwitch } from 'components/GenericInternalSwitch';
import { useContext } from 'react';
import { TrainingTab } from './SubTabs/Student/Training';
import { GraduatesTab } from './SubTabs/Student/Graduates';
import {
  cancelStudentInTrainingRequests,
  getOptionsStudent,
} from '../../Services';
import { DashboardContext } from '../../Context';

export function StudentsTab() {
  const {activeSubTab, setActiveSubTab} = useContext(DashboardContext);

  const Tabs = [
    {
      activeSwitch: 0,
      component: <TrainingTab />,
    },
    {
      activeSwitch: 1,
      component: <GraduatesTab />,
    },
  ];

  const handleChangeTab = async (value) => {
    setActiveSubTab(value);
    cancelStudentInTrainingRequests();
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeSwitch === step);
    return returnTabs.component;
  };

  return (
    <div>
      <GenericInternalSwitch
        activeTab={activeSubTab}
        handleChange={handleChangeTab}
        tabs={[`Em formação`, `Formados`]}
      />
      {renderPrincipalTabs(activeSubTab)}
    </div>
  );
}
