import styled from 'styled-components';

export const ButtonAddEvidencies = styled.button`
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009291;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  max-width: ${(props) => (props.evidence !== 0 ? '132px' : 'unset')};
`;

export const ButtonFinish = styled.button`
  width: 100%;
  display: flex;
  height: 29px;
  align-items: center;
  background-color: #ffffff;
  color: #009291;
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #009291;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;

  :disabled {
    cursor: auto;
    color: #949494;
    border: 1px solid #949494;

    &:hover {
      .alert-dialog {
        display: block;
      }
    }
  }

  .alert-dialog {
    padding: 0px 5px;
    margin-top: 18px;
    display: none;
    z-index: 10;
    min-width: 136px;
    min-height: 50px;
    height: auto;
    background-color: #009291;
    position: absolute;
    color: #000;
    border: #009291 solid 1px;

    border-radius: 4px;
    font-weight: 500;

    right: 168px;
    bottom: 126px;

    &:after {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 8px 10px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 1;
      bottom: -10px;
      left: 30px;
      transform: scaleY(-1);
    }

    &:before {
      content: '';
      position: absolute;
      border-style: solid;
      border-width: 0 10px 14px;
      border-color: #009291 transparent;
      display: block;
      width: 0;
      z-index: 0;
      bottom: -14px;
      left: 28px;
      transform: scaleY(-1);
    }

    span {
      color: #ffffff;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }
  }
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;

  background-color: ${(props) => props.bgColor || '#009291'};
  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 16px;
`;

export const ContainerCard = styled.div`
  width: 340px;
  height: 505px;

  background: #ffffff;
  box-shadow: 0px 0px 13px rgb(0 0 0 / 6%);
  border-radius: 11.5652px;

  flex: none;

  position: relative;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 12px;

  .wrapper-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  .wrapper-content img {
    width: 18px;
    flex: none;
  }

  .wrapper-content svg {
    flex: none;
  }

  .wrapper-content p {
    padding-left: ${(props) => props.paddingLeft || '10px'};
    margin: 0;
    text-align: start;
    word-break: break-all;
  }
`;

export const DataContent = styled.div`
  width: 100%;
  padding: 19px 29px;
`;

export const Image = styled.div`
  width: 100%;
  height: 209px;

  background-color: #009291;

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75%;
  }
`;

export const Information = styled.p`
  font-size: 11px;
  font-weight: 400;
  margin-top: 2px;
  color: #606062;
  text-align: start;
`;

export const Like = styled.div`
  width: 100%;
  max-width: 45px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0px 0px 11.6458px rgba(0, 0, 0, 0.4);

  position: absolute;
  top: 10px;
  right: 10px;

  background: #ffffff;
  border-radius: 4px;

  p {
    margin: 0;
    padding-right: 3px;
  }
`;

export const MissingTime = styled.div`
  height: 5%;
  padding: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  background: #009291;

  position: absolute;
  top: 10px;
  left: 15px;

  border-radius: 4px;
  border: ${(props) => (props.border ? '1px solid #ffffff' : 'unset')};

  svg {
    margin-right: 3px;
  }

  p {
    margin: unset;
  }
`;

export const ProgressBar = styled.div`
  width: 138px;
  height: 8px;
  background: #eaeaea;
  border-radius: 20px;
  position: relative;
`;

export const ProgressBarPercentual = styled.div`
  width: ${(props) => props.percentual};
  height: 8px;
  background: #009291;
  border-radius: 20px;
  position: absolute;
  left: 0;
`;

export const Title = styled.p`
  font-size: 17px;
  font-weight: 700;
  color: #4b4b4d;
  margin-bottom: 10px;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`;

export const VacanciesFilled = styled.div`
  width: 100%;
  max-width: 155px;
  height: 20px;

  border-radius: 4px;

  color: #009291;
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  background: #ffffff;

  position: absolute;
  top: 178px;
  left: 15px;
`;

export const ViewStudents = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  color: #009291;
  font-weight: 600;
  cursor: pointer;
`;
