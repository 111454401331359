import React, { Fragment } from 'react';
import { WhiteBox, WrapperContent } from '../style';
import InternalTab from '../../Tabs';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';

export default function WrapperRenderForm() {
  const { currentFormToShow } = useFormContext();

  const controlRenderForm = () => {
    if (currentFormToShow) {
      return currentFormToShow;
    }
    return <InternalTab />;
  };

  return (
    <>
      <Fragment>
        <WhiteBox form={currentFormToShow}>
          <WrapperContent>{controlRenderForm()}</WrapperContent>
        </WhiteBox>
      </Fragment>
      <br />
      <br />
    </>
  );
}
