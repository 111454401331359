import { getFairAndEventCertificate } from 'views/Student/Learning/Services';

export function certificateHandlers(event) {
  const { uuid } = event?.registration || {};
  
  const getCertificate = async () => {
    const response = await getFairAndEventCertificate(uuid);
    const blob = new Blob([response], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    return url;
  };

  const downloadCertificate = async () => {
    const url = await getCertificate();
    const a = document.createElement('a');
    a.href = url;
    a.download = 'certificate.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const printCertificate = async () => {
    const url = await getCertificate();
    const win = window.open(url, '_blank');
    win.addEventListener('afterprint', (event)=> {
      win.close();
    })
    win.print();
  };

  return {
    getCertificate,
    downloadCertificate,
    printCertificate,
  };
}
