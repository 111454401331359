import styled from 'styled-components';
import search from 'assets/Matriz/search.svg';

export const Container = styled.div`
  width: 570px;
  min-height: 520px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  position: relative;
`;

export const Header = styled.div`
  > h4 {
    margin: 0px;
    font-weight: 500;
    color: #4b4b4d;
  }

  p {
    color: #606062;
  }
`;

export const WrapperFields = styled.div`
  margin-top: 25px;

    > div {
      > label {
        margin-bottom: 8px;
      }
    }
  }
`;

export const SpaceNoteInput = styled.div`
  margin-top: 30px;
`;

export const WrapperSendButton = styled.div`
  margin-top: ${(props) => (props.isEdit ? '60px' : '0px')};
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const WrapperDisciplines = styled.div`
  margin-top: 50px;

  h5 {
    font-weight: 500;
    margin-bottom: ${(props) => (props.isEdit ? '25px' : '10px')};
  }
`;

export const WrapperActionsDisciplines = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const SubmitButton = styled.button`
  background-color: #009291;
  width: 135px;
  height: 35px;
  border-radius: 4px;
  color: #fff;
  border: none;
  right: 10px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  position: absolute;

  &:active {
    transform: scale(0.9);
  }
`;

export const DesactiveButton = styled.button`
  background-color: #ffff;
  border: 1px solid #009291;
  color: #009291;
  width: 143px;
  height: 35px;
  border-radius: 4px;
  right: 10px;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  &:active {
    transform: scale(0.9);
  }
`;

export const RemoveDisciplines = styled.button`
  background-color: #009291;
  color: #ffff;
  width: 135px;
  height: 35px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
  &:disabled {
    background-color: #f5f5f5;
    color: #949494;
    cursor: not-allowed;
  }
`;

export const SearchInput = styled.input`
  padding-left: 40px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 12px 9px;
  background-size: 16px;
  border: 1px solid #777777;
  border-radius: 4px;
  height: 35px;
`;

export const WrapperSeeMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const SeeMoreButton = styled.button`
  background-color: #009291;
  color: #ffff;
  border-radius: 4px;
  width: 130px;
  border: none;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
`;

export const TrashIcon = styled.img`
  width: 16px;
  margin-left: 10px;
  cursor: pointer;
`;

export const PopUp = styled.div`
  height: 30px;
  background-color: #009291;
  position: absolute;
  z-index: 0;
  top: -42px;
  padding: 5px;
  left: -13px;
  color: #fff;
  font-weight: 400;
  border-radius: 4px;
  display: none;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #009291 transparent;
    border-width: 10px 9px 0;
    bottom: -10px;
    left: 53%;
    margin-left: -11px;
  }
`;

export const WrapperTrash = styled.div`
  position: relative;

  &:hover {
    > div {
      display: block;
    }
  }
`;

export const WrapperChangeMatrix = styled.div`
  border: 1px dashed;
  margin: 44px 0px;
  padding: 20px;
  border-radius: 4px;

  > h4 {
    font-weight: 500;
    color: #009291;
    font-size: 19px;
  }

  > p {
    margin: 20px 0px;
  }

  > span {
    display: inline-block;
    margin-top: 16px;
  }
`;

export const CountSelected = styled.div`
  background-color: #f9f9f9;
  padding: 5px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
  margin: 10px 0px;

  > span {
    color: #009291;
    cursor: pointer;
  }
`;
