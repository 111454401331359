import React, {useContext} from "react";
import { object } from "prop-types";
import { Grid } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import learningTrailsStyle from "assets/jss/material-dashboard-pro-react/views/learningTrailsStyle";
import useDefineColor from "constants/colors";
import BaseLayoutContext from "contexts/base-layout";

const HowAdvance = props => {
  const { classes, data } = props;
  const {universityColor} = useContext(BaseLayoutContext)
  return (
    <Grid
      container
      justify="center"
      className={classes.fullScreenGridContainer}
    >
      <Grid
        item
        md={8}
        sm={12}
        className={(classes.fullScreenGrid, classes.learningTrails)}
      >
        <h2 className={classes.cardSubtitle}>Como desenvolver?</h2>

        <br />

        <Grid
          container
          justify="center"
          className={(classes.cardVideo, classes.blockMargin)}
        >
          <iframe
            className={classes.videos}
            src={data.videoSrc}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>

        <div className={classes.blockMargin}>
          <ol className={classes[`${useDefineColor(universityColor)}trailsList`]}>
            {data.steps &&
              data.steps.map((step, key) => (
                <li key={key}>
                  <span className="index">{key + 1}</span>
                  <p className="text">{step}</p>
                </li>
              ))}
          </ol>
        </div>
      </Grid>
    </Grid>
  );
};

HowAdvance.propTypes = {
  classes: object,
  data: object
};

export default withStyles(learningTrailsStyle)(HowAdvance);
