import React from 'react';

import { Elipsis } from './LoadingUptStyles';

export default function LoadingUpdate({className}) {
  return (
    <Elipsis className={className}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Elipsis>
  );
}
