/* eslint-disable no-loop-func */
import React, { Fragment, useEffect, useState } from 'react';
import { Field, FieldArray } from 'formik';
import withStyles from '@material-ui/core/styles/withStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';
import { func, shape } from 'prop-types';
import { Divider, FormControl, FormControlLabel } from '@material-ui/core';

import {
  CustomFormHelperText as FormHelperText,
  CustomTextField as TextField,
  InputField,
  PlusButton,
} from './styles';
import OptionGenerator from './OptionGenerator';

const GreenRadio = withStyles({
  root: {
    color: `${dnaColor}`,
    '&$checked': {
      color: `${dnaColor}`,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const QuestionGenerator = ({ errors, touched, setFieldValue }) => {
  const emptyQuestion = {
    type: 'checkbox',
    title: '',
    options: ['', ''],
  };

  const [questions, setQuestions] = useState([emptyQuestion]);

  useEffect(() => {
    setFieldValue('questions', questions);
  }, [questions]);

  const handleTitle = (title, index) => {
    const myQuestions = [...questions];
    myQuestions[index].title = title;
    setQuestions(myQuestions);
  };

  const handleType = (type, index) => {
    const myQuestions = [...questions];
    myQuestions[index].type = type;
    setQuestions(myQuestions);
  };

  return (
    <FieldArray
      name="questions"
      render={(arrayHelpers) =>
        questions && questions.length > 0 ? (
          questions.map((question, index) => (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Field
                  style={{
                    flex: 1,
                    width: 'auto',
                  }}
                  variant="filled"
                  name={`questions.${index}.title`}
                  placeholder={`Questão ${index + 1}`}
                  onChange={(event) => {
                    handleTitle(event.target.value, index);
                  }}
                  value={questions[index].title}
                  component={TextField}
                  autoFocus
                  required
                />
                <div style={{ display: 'flex', margin: '8px 28px' }}>
                  {index === questions.length - 1 && index < 6 && (
                    <PlusButton
                      onClick={() => {
                        setQuestions([...questions, emptyQuestion]);
                        arrayHelpers.push(emptyQuestion);
                      }}
                    >
                      +
                    </PlusButton>
                  )}
                  {questions.length > 1 && (
                    <PlusButton
                      onClick={() => {
                        const updatedQuestions = questions;
                        updatedQuestions.splice(index, 1);
                        setQuestions(updatedQuestions);
                        arrayHelpers.remove(index);
                      }}
                    >
                      -
                    </PlusButton>
                  )}
                </div>
              </div>
              {errors &&
              errors.questions &&
              errors.questions[index] &&
              errors.questions[index].title &&
              touched &&
              touched.questions &&
              touched.questions[index] &&
              touched.questions[index].title ? (
                <FormHelperText
                  style={{
                    color: 'red',
                    textAlign: 'left',
                    marginLeft: 35,
                  }}
                >
                  {errors.questions[index].title}
                </FormHelperText>
              ) : null}
              <InputField>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={questions[index].type}
                    style={{ flexFlow: 'nowrap' }}
                    onChange={(event) => {
                      handleType(event.target.value, index);
                    }}
                  >
                    <FormControlLabel
                      checked={questions[index].type === 'checkbox'}
                      value="checkbox"
                      control={<GreenRadio />}
                      label="Múltipla Escolha"
                    />
                    <FormControlLabel
                      checked={questions[index].type === 'radio'}
                      value="radio"
                      control={<GreenRadio />}
                      label="RU (Resposta Única)"
                    />
                  </RadioGroup>
                </FormControl>
              </InputField>
              <OptionGenerator
                questionNumber={index}
                setQuestions={setQuestions}
                questions={questions}
                errors={errors}
                touched={touched}
              />
              <Divider style={{ margin: '16px' }} />
            </div>
          ))
        ) : (
          <p>Carregando</p>
        )
      }
    />
  );
};

QuestionGenerator.propTypes = {
  errors: shape().isRequired,
  setFieldValue: func.isRequired,
  touched: shape().isRequired,
};

export default QuestionGenerator;
