import styled from 'styled-components';
import unselectedStar from 'assets/mentorship/small-unselected-star.svg';
import selectedStar from 'assets/mentorship/small-selected-star.svg';

const backgroundColorMap = {
  canceled: '#FFEDED',
  not_realized: '#FFEDED',
  expired: '#EEF2F4',
  realized: '#D9FFE3',
  reschedule_request: '#FFF2E3',
};

const textColorMap = {
  canceled: '#DA0505',
  not_realized: '#DA0505',
  expired: '#606062',
  realized: '#068E2C',
  reschedule_request: '#F09D51',
};

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
  justify-content: space-between;
`;

export const WrapperInformations = styled.div`
  width: 610px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;
  color: #606062;
  margin-bottom: ${(props) => props.marginBottom || '10px'};

  > span {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    color: #009291;
    margin-left: 5px;
  }

  > div {
    flex-direction: column;
  }

  > p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    color: #949494;
    margin-bottom: 0;
  }
`;

export const WrapperLink = styled.div`
  width: 100%;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.31px;
  margin-top: 10px;
`;
export const GridSide = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: -15px;
`;

export const GridModality = styled.div`
  width: 100%;
`;

export const Divider = styled.div`
  width: 610px;
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IconWrapper = styled.div`
  margin-top: -8px;
`;

export const StudentNotes = styled.div`
  font-family: Roboto;
  width: 100%;
  color: #606062;
  display: flex;
  flex-direction: column;

  > strong {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
    padding: 10px 0;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  > div > strong {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
  }

  > p {
    font-size: 16px;
    font-weight: 400;
    text-align: left;
  }
`;

export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  background: transparent;
`;

export const RejectButton = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  margin-bottom: 20px;
  color: #da0505;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const ChangeButton = styled.div`
  width: 191px;
  height: 39px;
  padding: 10px 29px 10px 29px;
  gap: 10px;
  border-radius: 3px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #009291;
  margin-bottom: 20px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: center;
  color: #009291;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 10px;
  color: #606062;
  margin: 20px 0;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
`;

export const StarIcon = styled.div`
  height: 19px;
  width: 19px;
  background-size: cover;
  background-image: url(${(props) =>
    props.selected ? selectedStar : unselectedStar});
  background-repeat: no-repeat;
  background-position: center;
`;

export const TypeLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #eef2f4;
  height: 20px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;
  padding: 0 10px;

  color: #606062;
  width: fit-content;
`;

export const WrapperForm = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.31px;
  width: 100%;

  > p {
    margin: 5px 0;
  }
`;

export const Flag = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 10px;
  gap: 9px;
  border-radius: 5px;
  margin-bottom: 15px;

  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.23px;
  text-align: left;

  background-color: ${({ mentoringStatus }) =>
    backgroundColorMap[mentoringStatus] || '#D9FFE3'};
  color: ${({ mentoringStatus }) => textColorMap[mentoringStatus] || '#068E2C'};

  white-space: nowrap;

  > p {
    margin: 0;
  }

  max-width: none;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #606062;

  .container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .text-wrapper strong {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }

  .text-wrapper p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    margin-top: 4px;
  }
  .value-wrapper {
    margin-bottom: 10px;
  }

  .value-wrapper p {
    font-size: 16px;
    margin-bottom: -5px;
  }

  .value-wrapper strong {
    font-size: 16px;
    font-weight: 700;
  }

  .value-wrapper span {
    text-decoration: line-through;
  }
`;
export const TruncatedLink = styled.a`
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #5578ac;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const RescheduleButton = styled.div`
  width: 100%;
  height: 39px;
  padding: 10px 29px 10px 29px;
  gap: 10px;
  border-radius: 3px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #009291;
  margin-bottom: 20px;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: center;
  color: #009291;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const ConfirmButton = styled.button`
  width: 100%;
  height: 39px;
  padding: 10px 29px 10px 29px;
  gap: 10px;
  border-radius: 3px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #009291;
  margin-bottom: 20px;
  background-color: #009291;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: center;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const FileTile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: -10px;
  p {
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  button {
    border: 1px solid transparent;
    background-color: transparent;
    > svg {
      cursor: pointer;
    }
  }
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;

  > p {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const ActionButton = styled.button`
  cursor: pointer;
  width: 22px;
  height: 22px;
  padding: 0;

  > img {
    width: 100%;
    height: 100%;
  }
`;
