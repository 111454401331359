import React, { useContext } from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import { Grid, List, ListItem } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { object, func } from 'prop-types';
import { ButtonConfig } from '../Buttons';
import ChangePasswordForm from './ChangePasswordForm';
import BaseLayoutContext from 'contexts/base-layout';

const ChangePassword = ({
  classes,
  handleIsLoadingState,
  openSnackbar,
  openModalWithContent,
  closeModal,
  globalState,
}) => {
  function openChangePasswordForm() {
    openModalWithContent(
      <ChangePasswordForm
        loadingState={handleIsLoadingState}
        openSnackbar={openSnackbar}
        openModal={openModalWithContent}
        closeModal={closeModal}
      />,
      'Alterar senha'
    );
  }

  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Grid item xs md={12} className={classes.fullScreenGrid}>
      <List className={classes.list}>
        <ListItem className={`${classes.listItem} ${classes.listFlex}`}>
          <div>
            <h4 className={classes.fieldLabel}>Login:</h4>
          </div>
          <div>
            <h4 className={classes.fieldLabel}>{globalState.loginUserName}</h4>
          </div>
        </ListItem>
        <ListItem className={`${classes.listItem} ${classes.listFlex}`}>
          <div>
            <h4 className={classes.fieldLabel}>Senha Atual:</h4>
          </div>
          <div>
            <h4 className={classes.fieldLabel}>**************</h4>
          </div>
        </ListItem>
        <ListItem className={classes.listItem}>
          <div className={classes.buttonContainer}>
            <ButtonConfig
              universityColor={universityColor}
              type="submit"
              className={classes.saveButton}
              round
              onClick={openChangePasswordForm}
            >
              Alterar Senha
            </ButtonConfig>
          </div>
        </ListItem>
      </List>
    </Grid>
  );
};

ChangePassword.propTypes = {
  classes: object,
  handleIsLoadingState: func,
  openSnackbar: func,
  openModalWithContent: func,
  closeModal: func,
  globalState: object,
};

export default withStyles(ProfessionalExperienceFormStyles)(ChangePassword);
