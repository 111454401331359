import { authenticatedAPI } from 'services/api';

const getCompanyDetail = async (companyId) => {
  const response = await authenticatedAPI
    .get(`api/internal/students/networking/detail/${companyId}`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompanyJobsOpen = async (companyId) => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/detail/company-open-offers/${companyId}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompanyJobsFinished = async (companyId) => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/detail/company-finished-offers/${companyId}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompanyChallengesAvailable = async (companyId) => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/detail/company-open-challenges/${companyId}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompanyChallengesWithParticipation = async (companyId) => {
  const response = await authenticatedAPI
    .get(
      `api/internal/students/networking/detail/company-completed-challenges/${companyId}`
    )
    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getCompanyChallengeDetail = async (challengeId) => {
  const response = await authenticatedAPI
    .get(`api/internal/students/me/attractions/detail/${challengeId}`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};
const editReactions = async (challenge, data) => {
  const response = await authenticatedAPI
    .patch(
      `api/internal/students/me/attractions/${challenge?.id}/reaction/${challenge?.reactions?.id}/edit`,
      { ...data }
    )

    .then((res) => res)
    .catch((error) => error);
  return response;
};

const deleteReactions = async (challenge, data) => {
  const response = await authenticatedAPI
    .delete(
      `api/internal/students/me/attractions/${challenge?.id}/reaction/${challenge?.reactions?.id}/edit`,
      { ...data }
    )

    .then((res) => res)
    .catch((error) => error);
  return response;
};

const createReactions = async (challenge, data) => {
  const response = await authenticatedAPI
    .post(`api/internal/students/me/attractions/${challenge?.id}/reaction`, {
      ...data,
    })

    .then((res) => res)
    .catch((error) => error);
  return response;
};

const getAccess = (uuid) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/attractions/access/${uuid}`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};
export {
  getCompanyDetail,
  getCompanyJobsOpen,
  getCompanyJobsFinished,
  getCompanyChallengesAvailable,
  getCompanyChallengesWithParticipation,
  getCompanyChallengeDetail,
  editReactions,
  deleteReactions,
  createReactions,
  getAccess,
};
