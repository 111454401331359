import React, { useContext, useRef, useState, useEffect } from 'react';
import PublishedCardCompany from './components/Cards';
import { WrapperPublished, Title, WrapperDiv, ContentTabs } from './styles';

import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';

export default function PublishedOpportunitiesCompany({ setActiveTab }) {
  const {
    events,
    courses,
    programs,
    communicate,
    trails,
    ebooks,
    article,
    challenge,
    serie,
  } = useContext(OpportunitiesCompanyContext);
  const ref = useRef(null);

  return (
    <WrapperPublished>
      <Title>Aprendizagens Publicadas</Title>

      <WrapperDiv ref={ref} style={{ overflow: 'hidden' }}>
        <PublishedCardCompany
          array={events}
          referencia={ref}
          title={'Eventos'}
          subTitle={'Veja aqui os eventos publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={courses}
          referencia={ref}
          title={'Cursos'}
          subTitle={'Veja aqui os cursos publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={programs}
          referencia={ref}
          title={'Programas'}
          subTitle={'Veja aqui os programas publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={communicate}
          referencia={ref}
          title={'Comunicados'}
          subTitle={'Veja aqui os comunicados publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={trails}
          referencia={ref}
          title={'Trilhas'}
          subTitle={'Veja aqui as trilhas publicadas'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={ebooks}
          referencia={ref}
          title={'E-books'}
          subTitle={'Veja aqui os e-books publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={article}
          referencia={ref}
          title={'Artigos'}
          subTitle={'Veja aqui os artigos publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={challenge}
          referencia={ref}
          title={'Desafios'}
          subTitle={'Veja aqui os desafios publicados'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
      <WrapperDiv ref={ref} style={{ overflow: 'hidden', marginTop: '-70px' }}>
        <PublishedCardCompany
          array={serie}
          referencia={ref}
          title={'Séries'}
          subTitle={'Veja aqui as séries publicadas'}
          setActiveTab={setActiveTab}
        />
      </WrapperDiv>
    </WrapperPublished>
  );
}
