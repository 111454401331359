import {
  ListLearnings,
  WrapperDiv,
} from 'views/StaffPages/Opportunities/Styles/PublishedOpportunities';
import PublishedCard from '../PublishedCards';
import { useContext, useRef, useEffect, useState } from 'react';
import { Container } from './TabStyleBase';
import { useLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsOpportunities';

import {
  scrollRigthEvents,
  scrollLeftEvents,
  scrollRigthCourses,
  scrollLeftCourses,
  scrollLeftPrograms,
  scrollRigthPrograms,
  scrollLeftAnnouncements,
  scrollRigthAnnouncements,
  scrollRigthTrails,
  scrollLeftTrails,
  scrollRigthEbooks,
  scrollLefEbooks,
  scrollRigthArticle,
  scrollLeftArticle,
  scrollRigthSeries,
  scrollLeftSeries,
  scrollLeftChallenges,
  scrollRigthChallenges,
} from '../../../utils/LogicScroll/scrollPaginationForTheAll';
import { ButtonsPagination } from '../ButtonsPagination';
import {
  CardSubTitle,
  CardTitle,
} from 'components/Opportunities/styles/defaultStyled';
import { ListOfPublishedLearningContext } from 'views/StaffPages/Opportunities/Context/ListOfPublishedLearningContext';
import { formatNumber } from 'utils/formatNumber';
import { countAllAttractions } from 'views/StaffPages/Opportunities/services';

export default function ListOfPublishedLearnings(props) {
  const { setActiveTab, educationalGroup, activeInternalTab } = props;

  const {
    events,
    courses,
    programs,
    communicate,
    trails,
    ebooks,
    article,
    challenge,
    serie,
    setEvents,
    setCourses,
    setPrograms,
    setCommunicate,
    setTrails,
    setEbooks,
    setArticle,
    setChallenge,
    setSerie,

    eventsCount,
    coursesCount,
    programsCount,
    announcementsCount,
    trailsCount,
    ebooksCount,
    articlesCount,
    challengesCount,
    seriesCount,

    currentPageEvents,
    currentPageCourses,
    currentPagePrograms,
    currentPageAnnouncements,
    currentPageTrails,
    currentPageEbooks,
    currentPageArticles,
    currentPageChallenges,
    currentPageSeries,
  } = useContext(ListOfPublishedLearningContext);

  const {
    getAllEvents,
    getAllPrograms,
    getAllTrails,
    getAllArticles,
    getAllSeries,
    getAllEbooks,
    getAllChallenges,
    getAllAnnouncements,
    getAllCourses,
  } = useLearningAPICalls();

  const ref = useRef(null);

  useEffect(() => {
    setEvents(null);
    setCourses(null);
    setPrograms(null);
    setCommunicate(null);
    setTrails(null);
    setEbooks(null);
    setArticle(null);
    setChallenge(null);
    setSerie(null);
  }, [activeInternalTab]);

  const eventSentinel = useRef();
  const coursesSentinel = useRef();
  const programsSentinel = useRef();
  const announcementsSentinel = useRef();
  const trailsSentinel = useRef();
  const ebookSentinel = useRef();
  const articleSentinel = useRef();
  const challengeSentinel = useRef();
  const seriesSentinel = useRef();

  // Event Carousel
  useEffect(() => {
    let intersectionObserver;
    if (events !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (events.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (events.length > 7) {
            intersectionObserver?.disconnect();
            if (events.length < eventsCount) {
              getAllEvents(educationalGroup, false, currentPageEvents + 8);
            }
          }
        }
      });
      if (events?.length === eventsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (events.length > 0 && eventSentinel.current) {
        intersectionObserver?.observe(eventSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [events, eventsCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllEvents(educationalGroup);
    } else {
      setEvents(null);
    }
  }, [activeInternalTab]);

  // Course Carousel
  useEffect(() => {
    let intersectionObserver;
    if (courses !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (courses.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (courses.length > 7) {
            intersectionObserver?.disconnect();
            if (courses.length < coursesCount) {
              getAllCourses(educationalGroup, false, currentPageCourses + 8);
            }
          }
        }
      });
      if (courses.length === coursesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (courses.length > 0 && coursesSentinel.current) {
        intersectionObserver?.observe(coursesSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [courses, coursesCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllCourses(educationalGroup);
    } else {
      setCourses(null);
    }
  }, [activeInternalTab]);

  // Programs Carousel
  useEffect(() => {
    let intersectionObserver;
    if (programs !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (programs.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (programs.length > 7) {
            intersectionObserver?.disconnect();
            if (programs.length < programsCount) {
              getAllPrograms(educationalGroup, false, currentPagePrograms + 8);
            }
          }
        }
      });
      if (programs?.length === programsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (programs.length > 0 && programsSentinel.current) {
        intersectionObserver?.observe(programsSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [programs, programsCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllPrograms(educationalGroup);
    } else {
      setPrograms(null);
    }
  }, [activeInternalTab]);

  // Communicate Carousel
  useEffect(() => {
    let intersectionObserver;
    if (communicate !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (communicate.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (communicate.length > 7) {
            intersectionObserver?.disconnect();
            if (communicate.length < announcementsCount) {
              getAllAnnouncements(
                educationalGroup,
                false,
                currentPageAnnouncements + 8
              );
            }
          }
        }
      });
      if (communicate?.length === announcementsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (communicate.length > 0 && announcementsSentinel.current) {
        intersectionObserver?.observe(announcementsSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [communicate, announcementsCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllAnnouncements(educationalGroup);
    } else {
      setCommunicate(null);
    }
  }, [activeInternalTab]);

  // Trails Carousel
  useEffect(() => {
    let intersectionObserver;
    if (trails !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (trails.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (trails.length > 7) {
            intersectionObserver?.disconnect();
            if (trails.length < trailsCount) {
              getAllTrails(educationalGroup, false, currentPageTrails + 8);
            }
          }
        }
      });
      if (trails?.length === trailsCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (trails.length > 0 && trailsSentinel.current) {
        intersectionObserver?.observe(trailsSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [trails, trailsCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllTrails(educationalGroup);
    } else {
      setTrails(null);
    }
  }, [activeInternalTab]);

  // Ebooks Carousel
  useEffect(() => {
    let intersectionObserver;
    if (ebooks !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (ebooks.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (ebooks.length > 7) {
            intersectionObserver?.disconnect();
            if (ebooks.length < ebooksCount) {
              getAllEbooks(educationalGroup, false, currentPageEbooks + 8);
            }
          }
        }
      });
      if (ebooks?.length === ebooksCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (ebooks.length > 0 && ebookSentinel.current) {
        intersectionObserver?.observe(ebookSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [ebooks, ebooksCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllEbooks(educationalGroup);
    } else {
      setEbooks(null);
    }
  }, [activeInternalTab]);

  // Article Carousel
  useEffect(() => {
    let intersectionObserver;
    if (article !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (article.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (article.length > 7) {
            intersectionObserver?.disconnect();
            if (article.length < articlesCount) {
              getAllArticles(educationalGroup, false, currentPageArticles + 8);
            }
          }
        }
      });
      if (article?.length === articlesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (article.length > 0 && articleSentinel.current) {
        intersectionObserver?.observe(articleSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [article, articlesCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllArticles(educationalGroup);
    } else {
      setArticle(null);
    }
  }, [activeInternalTab]);

  // Challenge Carousel
  useEffect(() => {
    let intersectionObserver;
    if (challenge !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (challenge.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (challenge.length > 7) {
            intersectionObserver?.disconnect();
            if (challenge.length < challengesCount) {
              getAllChallenges(
                educationalGroup,
                false,
                currentPageChallenges + 8
              );
            }
          }
        }
      });
      if (challenge?.length === challengesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (challenge.length > 0 && challengeSentinel.current) {
        intersectionObserver?.observe(challengeSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [challenge, challengesCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllChallenges(educationalGroup);
    } else {
      setChallenge(null);
    }
  }, [activeInternalTab]);

  // Series Carousel
  useEffect(() => {
    let intersectionObserver;
    if (serie !== null) {
      intersectionObserver = new IntersectionObserver((entries) => {
        if (serie.length === 0) {
          intersectionObserver?.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (serie.length > 7) {
            intersectionObserver?.disconnect();
            if (serie.length < seriesCount) {
              getAllSeries(educationalGroup, false, currentPageSeries + 8);
            }
          }
        }
      });
      if (serie?.length === seriesCount) {
        return () => intersectionObserver?.disconnect();
      }

      if (serie.length > 0 && seriesSentinel.current) {
        intersectionObserver?.observe(seriesSentinel.current);
      }
    }
    return () => intersectionObserver?.disconnect();
  }, [serie, seriesCount]);

  useEffect(() => {
    if (activeInternalTab === 1) {
      getAllSeries(educationalGroup);
    } else {
      setSerie(null);
    }
  }, [activeInternalTab]);

  return (
    <Container>
      <WrapperDiv ref={ref}>
        <CardTitle>
          Eventos ({formatNumber(eventsCount)}
          ) <br />
          <CardSubTitle>Veja aqui os eventos publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="event-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={events}
            title={'Eventos'}
            subTitle={'Veja aqui os eventos publicados'}
            referencia={ref}
            setActiveTab={setActiveTab}
          />
          {events?.length >= 8 && events?.length !== eventsCount && (
            <li ref={eventSentinel} />
          )}
        </ListLearnings>
        {events?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftEvents}
            funcButtonRight={scrollRigthEvents}
          />
        )}
      </WrapperDiv>
      <hr />
      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Cursos ({formatNumber(coursesCount)}
          ) <br />
          <CardSubTitle>Veja aqui os cursos publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="courses-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={courses}
            referencia={ref}
            title={'Cursos'}
            subTitle={'Veja aqui os cursos publicados'}
            setActiveTab={setActiveTab}
          />
          {courses?.length >= 8 && courses?.length !== coursesCount ? (
            <li ref={coursesSentinel} />
          ) : (
            <li />
          )}
        </ListLearnings>
        {courses?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftCourses}
            funcButtonRight={scrollRigthCourses}
          />
        )}
      </WrapperDiv>
      <hr />
      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Programas ({formatNumber(programsCount)}
          ) <br />
          <CardSubTitle>Veja aqui os programas publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="programs-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={programs}
            referencia={ref}
            title={'Programas'}
            subTitle={'Veja aqui os programas publicados'}
            setActiveTab={setActiveTab}
          />
          {programs?.length >= 8 && programs?.length !== programsCount ? (
            <li ref={programsSentinel} />
          ) : (
            <li />
          )}
        </ListLearnings>
        {programs?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftPrograms}
            funcButtonRight={scrollRigthPrograms}
          />
        )}
      </WrapperDiv>
      <hr />
      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Comunicados ({formatNumber(announcementsCount)}
          ) <br />
          <CardSubTitle>Veja aqui os comunicados publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="announcements-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={communicate}
            referencia={ref}
            title={'Comunicados'}
            subTitle={'Veja aqui os comunicados publicados'}
            setActiveTab={setActiveTab}
          />
          {communicate?.length >= 8 &&
          communicate?.length !== announcementsCount ? (
            <li ref={announcementsSentinel} />
          ) : (
            <li cla />
          )}
        </ListLearnings>
        {communicate?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftAnnouncements}
            funcButtonRight={scrollRigthAnnouncements}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Trilhas ({formatNumber(trailsCount)}
          ) <br />
          <CardSubTitle>Veja aqui as trilhas publicadas</CardSubTitle>
        </CardTitle>
        <ListLearnings id="trails-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={trails}
            referencia={ref}
            title={'Trilhas'}
            subTitle={'Veja aqui as trilhas publicadas'}
            setActiveTab={setActiveTab}
          />
          {trails?.length >= 8 && trails?.length !== trailsCount && (
            <li ref={trailsSentinel} />
          )}
        </ListLearnings>
        {trails?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftTrails}
            funcButtonRight={scrollRigthTrails}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          E-books ({formatNumber(ebooksCount)}
          ) <br />
          <CardSubTitle>Veja aqui os e-books publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="ebooks-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={ebooks}
            referencia={ref}
            title={'E-books'}
            subTitle={'Veja aqui os e-books publicados'}
            setActiveTab={setActiveTab}
          />
          {ebooks?.length >= 8 && ebooks?.length !== ebooksCount && (
            <li ref={ebookSentinel} />
          )}
        </ListLearnings>
        {ebooks?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLefEbooks}
            funcButtonRight={scrollRigthEbooks}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Artigos ({formatNumber(articlesCount)}
          ) <br />
          <CardSubTitle>Veja aqui os artigos publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="article-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={article}
            referencia={ref}
            title={'Artigos'}
            subTitle={'Veja aqui os artigos publicados'}
            setActiveTab={setActiveTab}
          />
          {article?.length >= 8 && article?.length !== articlesCount && (
            <li ref={articleSentinel} />
          )}
        </ListLearnings>
        {article?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftArticle}
            funcButtonRight={scrollRigthArticle}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Desafios ({formatNumber(challengesCount)}
          ) <br />
          <CardSubTitle>Veja aqui os desafios publicados</CardSubTitle>
        </CardTitle>
        <ListLearnings id="challenge-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={challenge}
            referencia={ref}
            title={'Desafios'}
            subTitle={'Veja aqui os desafios publicados'}
            setActiveTab={setActiveTab}
          />
          {challenge?.length >= 8 && challenge?.length !== challengesCount && (
            <li ref={challengeSentinel} />
          )}
        </ListLearnings>
        {challenge?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftChallenges}
            funcButtonRight={scrollRigthChallenges}
          />
        )}
      </WrapperDiv>
      <hr />

      <WrapperDiv ref={ref} marginTop>
        <CardTitle>
          Séries ({formatNumber(seriesCount)}
          ) <br />
          <CardSubTitle>Veja aqui as séries publicadas</CardSubTitle>
        </CardTitle>
        <ListLearnings id="serie-container">
          <PublishedCard
            learningPublishedByTheIEs={true}
            visionGroup={educationalGroup}
            array={serie}
            referencia={ref}
            title={'Séries'}
            subTitle={'Veja aqui as séries publicadas'}
            setActiveTab={setActiveTab}
          />
          {serie?.length >= 8 && serie?.length !== seriesCount && (
            <li ref={seriesSentinel} />
          )}
        </ListLearnings>
        {serie?.length !== 0 && (
          <ButtonsPagination
            funcButtonLeft={scrollLeftSeries}
            funcButtonRight={scrollRigthSeries}
          />
        )}
      </WrapperDiv>
    </Container>
  );
}
