import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { patchFeedBack } from '../../services';
import RadioFeedBack from './radio';
import closedEye from 'assets/JobOffer/closedEye.svg';
import closedEyeWhite from 'assets/JobOffer/closedEyeWhite.svg';
import check from 'assets/JobOffer/check.svg';
import checkWhite from 'assets/JobOffer/checkWhite.svg';

export default function FeedBackButtons({ jobID, jobs }) {
  const [currentChecked, setCurrentChecked] = useState('');
  const [optionValue, setOptionValue] = useState('');

  useEffect(() => {
    if (jobs.analysis) {
      formRef.current.setFieldValue('analysis', jobs.analysis);
    }
  }, [jobs]);

  const submitForm = async (data) => {
    await patchFeedBack(jobID, data);
  };

  const handleChange = async (e) => {
    setOptionValue(e.target.value);

    if (currentChecked === e.target.value) {
      return;
    }
    setCurrentChecked(e.target.value);
    formRef.current.submitForm();
  };

  const Options = [
    {
      id: Math.floor(Math.random() * 1000),
      value: 'enabled',
      label: 'sim',
      type: 'radio',
      color: '#009291',
      imgPrimary: check,
      imgSecundary: checkWhite,
      bubble: 'Aprovar vaga',
    },
    {
      id: Math.floor(Math.random() * 1000),
      value: 'disabled',
      label: 'não',
      type: 'radio',
      color: '#D26464',
      imgPrimary: closedEye,
      imgSecundary: closedEyeWhite,
      bubble: 'Ocultar para estudante',
    },
  ];

  const formRef = useRef(null);

  return (
    <Form ref={formRef} onSubmit={submitForm}>
      <div style={{ display: 'flex' }}>
        <RadioFeedBack
          name="analysis"
          options={Options}
          onChange={handleChange}
          optionValue={optionValue}
          setOptionValue={setOptionValue}
        />
      </div>
    </Form>
  );
}
