import styled from 'styled-components';
import search from 'assets/Matriz/search.svg';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem 3rem;

  > section {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  > header {
    > h3 {
      font-size: 18px;
      font-weight: bold;
      color: #606062;
    }

    p {
      max-width: 700px;
      font-size: 14px;
      margin: 0;
    }
  }
`;

export const ContainerListHeader = styled.div`
  color: #b8b8b8;
  font-weight: 500;
  display: flex;
  gap: 3rem;

  input {
    height: 14px;
    width: 14px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: #009291;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .checkDisciplines {
    cursor: pointer;
  }

  .code {
    display: inline-flex;
    flex: 1;
  }

  .discipline {
    display: inline-flex;
    flex: 4;
  }

  .workload {
    display: inline-flex;
    flex: 1;
    white-space: nowrap;
  }
`;

export const ContainerList = styled.div`
  padding: 20px 0;

  gap: 3rem;
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid #eef2f4;
  }

  input {
    height: 14px;
    width: 14px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: #009291;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .checkDisciplines {
    cursor: pointer;
  }

  .code {
    flex: 1;
    display: inline-flex;
  }

  .discipline {
    display: inline-flex;
    flex: 4;
  }

  .workloadList {
    display: inline-flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-width: 158px;
  }

  .edit {
    cursor: pointer;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-end')};
`;

export const SearchInput = styled.input`
  padding-left: 40px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 12px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: ${(props) => (props.width ? props.width : '332px')};
`;

export const ContainerSkeleton = styled.div`
  display: flex;
  gap: 3rem;
  align-items: center;
  margin-top: 13px;
  padding: 20px 0;
  border-bottom: 1px solid #eef2f4;

  > .checkbox {
    margin-left: 3px;
  }

  > .code {
    flex: 1;
  }

  > .discipline {
    flex: 4;
  }

  > .workloadList {
    flex: 1;
  }
`;
