import { useVisualizationContext } from '../../../Contexts/VisualizationContext';
import { H4Header } from '../styled';

export default function Competencies() {
  const { detailsObject } = useVisualizationContext();

  const { associated_areas } = detailsObject;

  const createCompetencies = () => {
    return associated_areas?.map((area) => {
      return <li>{area.label}</li>;
    });
  };

  return (
    <>
      <H4Header>Competências trabalhadas no evento</H4Header>

      <ul>{createCompetencies()}</ul>
    </>
  );
}
