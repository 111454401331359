import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: auto;
  min-height: 180px;
  margin-bottom: 30px;
  padding: 24px 20px;
  border-radius: 10px;
  background-color: #fff;
  filter: ${(props) => (props.disabled ? 'blur(1.5px)' : blur(0))};
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e5e5e5;
  margin: 20px 0;
`;

export const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 10px;
  border: 2px solid;
  border-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 15px 15px 0;
`;
export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #606062;
  margin: 0;
`;

export const InformationsIcons = styled.p`
  left: 14px;
  color: #8b8b8b;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
`;
export const InfoWrapper = styled.p`
  display: flex;
  align-items: center;
`;

export const Informations = styled.p`
  left: 14px;
  color: #8b8b8b;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin: 0 8px 0 0;
`;

export const ContainerTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: center;
  margin: 0 5px 0 0;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  margin: 20px 0 0 0;
`;

export const HeaderPrincipal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  gap: 15px;
  transition: 0.5s;

  @media (max-width: 525px) {
    transition: 0.5s;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin: 30px 0 10px 0;
`;

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const ContentBody = styled.div`
  margin-top: 6%;
  padding: 15px;
`;

export const ContainerAcademicInformations = styled.div``;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  transition: 0.5s;

  @media (max-width: 850px) {
    transition: 0.5s;
    flex-direction: column-reverse;
  }
`;

export const FlexItem = styled.div`
  box-sizing: border-box;
  transition: 0.5s;

  @media (max-width: 1200px) {
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
`;

export const AlertArea = styled.div`
  display: ${(props) => (props.display ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  width: 100%;
  border-radius: 8px;
  background: #caeaea;

  font-size: 20px;
  font-weight: 400;
  color: #009291;
  text-align: center;

  padding: 20px;
  margin-bottom: 24px;
  gap: 3px;

  p {
    margin: 0;
  }
  transition: 0.5s;

  @media (max-width: 425px) {
    font-size: 18px;
    transition: 0.5s;
  }

  @media (max-width: 320px) {
    font-size: 16px;
    transition: 0.5s;
  }
`;

export const ContainerCurriculumItems = styled.div`
  width: 70%;

  @media (max-width: 1211px) {
    width: 66%;
  }

  @media (max-width: 850px) {
    width: 100%;
  }
`;
export const ContainerAreas = styled.div`
  width: 30%;

  @media (max-width: 850px) {
    width: 100%;
    transition: 0.5s;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }
`;
