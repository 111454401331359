import React, { useRef, useEffect, Fragment, useState } from 'react';
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import {
  ContainerSelect,
  ContainerHeader,
  InputSubLabel,
  ErrorWrapper,
  InfoTooltip,
  InputLabel,
  InputIcon,
} from 'views/ProfessionalStatus/styles/styles';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';

export default function Select({
  universityColor,
  selectOptions,
  placeholder,
  infoTooltip,
  subLabel,
  disabled,
  options,
  label,
  icon,
  name,
  ...rest
}) {
  const selectReference = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectReference.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.fullValue) {
          if (rest.isMulti) {
            return Array.isArray(ref.state.value)
              ? ref.state.value.map((option) => option)
              : [];
          } else {
            return ref.state.value ? ref.state.value : '';
          }
          return;
        }
        if (rest.isMulti) {
          return Array.isArray(ref.state.value)
            ? ref.state.value.map((option) => option.value)
            : [];
        } else {
          return ref.state.value ? ref.state.value.value : '';
        }
      },
      setValue: (ref, value) => {
        if (value !== null && value !== undefined) {
          const currentOptions = ref.props.options;
          if (Array.isArray(value) && currentOptions.length > 0) {
            const newArray = value.map((value) =>
              currentOptions.find(
                (option) =>
                  option.value === value || option.value === value.value
              )
            );
            return ref.onChange(newArray);
          }
          if (
            !Array.isArray(value) &&
            currentOptions &&
            currentOptions.length > 0
          ) {
            const valueSingle = currentOptions.find(
              (option) =>
                String(option.value) === String(value) ||
                String(option) === String(value)
            );
            return ref.onChange(valueSingle);
          }
          ref.onChange(value);
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleInfoClick = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div>
      <ContainerSelect>
        <ContainerHeader>
          {icon && (
            <InputIcon universityColor={universityColor}>{icon}</InputIcon>
          )}
          <div>
            {label && (
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <InputLabel>{label}</InputLabel>
                {infoTooltip && (
                  <AiOutlineInfoCircle
                    size={16}
                    color={universityColor || '#009291'}
                    onClick={handleInfoClick}
                    style={{
                      marginLeft: '5px',
                      cursor: 'pointer',
                      minWidth: '16px',
                    }}
                  />
                )}
              </span>
            )}
            {subLabel && <InputSubLabel>{subLabel}</InputSubLabel>}
          </div>
        </ContainerHeader>
        {showTooltip && infoTooltip && <InfoTooltip>{infoTooltip}</InfoTooltip>}
        <ReactSelect
          defaultValue={
            Array.isArray(options) && options.length > 0
              ? options.find(
                  (option) => String(option.value) === String(defaultValue)
                )
              : null
          }
          placeholder={placeholder || 'Selecione'}
          options={options}
          ref={selectReference}
          onFocus={clearError}
          isDisabled={disabled}
          className={`react-select ${error ? 'error' : ''}`}
          {...rest}
        />
        <ErrorWrapper>
          {error && (
            <Fragment>
              <AiOutlineCloseCircle size={18} color={'#f10909'} />
              {error}
            </Fragment>
          )}
        </ErrorWrapper>
      </ContainerSelect>
    </div>
  );
}
