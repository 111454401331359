import React, { useState, useContext } from 'react';
import {
  DownloadInProgressIndicator,
  EmptyList,
  Footer,
  ListContainer,
  ListContent,
  ListHeader,
  ListHeaderActions,
  ListHeaderCheckbox,
  OutlinedButton,
  PaginationContainer,
} from './styles';
import { DownloadIcon } from 'assets/csm/DownloadIcon';
import { StudentTile } from './StudentTile';
import { useCsm } from '../../contexts/CsmContext';
import { StudentFilter } from './StudentFilter';
import { StudentCategory } from '../../resources/defaultStudentCategories';
import { StudentTileSkeleton } from './StudentTileSkeleton';
import { ModalDialog } from '../ModalDialog/ModalDiaolog';
import jszip from 'jszip';
import Curriculum from 'components/CurriculumModalGenerate/Curriculum';
import { pdf } from '@react-pdf/renderer';
import CoverLetter from 'views/Student/Docs/CoverLetter/CoverLetterContainer/components/CoverLetter';
import { getCurriculums } from 'services/university/curriculum';
import { getCoverLetterForDownload } from 'services/university/coverLetter';
import qs from 'querystring';
import { saveAs } from 'file-saver';
import progressImg from 'assets/csm/progress.svg';
import Pagination from 'components/CustomPagination';
import LimitSelect from './LimitSelect';
import Api from '../../services/api';
import AuthContext from 'contexts/authentication';
import GenericMessage from 'components/GenericMessage';
import { registerDownloadedCurriculum } from 'services/student/registerActivityStudent';

export function StudentList() {
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [downloadingAmountFiles, setDownloadingAmountFiles] = useState(0);
  const { metadata } = useContext(AuthContext);

  const {
    studentsList,
    selectedStudents,
    setSelectedStudents,
    activeCategory,
    studentRequestInProgress,
    requestedStudentCount,
    limitPerPage,
    setLimitPerPage,
    currentPage,
    setCurrentPage,
    changePage,
    appliedFilters,
    filterRequestInProgress,
    egressed,
  } = useCsm();

  function toggleSelectAll() {
    if (selectedStudents.length === studentsList.length) {
      setSelectedStudents([]);
    } else {
      setSelectedStudents(studentsList);
    }
  }

  const isAllStudentsSelected =
    studentsList && selectedStudents.length === studentsList.length;

  const loadingDataMessage = studentRequestInProgress
    ? 'Aguarde o carregamento dos dados'
    : '';

  const curriculumAmount = selectedStudents.filter(
    (student) => student.hasCurriculum
  ).length;

  const coverLetterAmount = selectedStudents.filter(
    (student) => student.hasCoverLetter
  ).length;

  const handleCloseModalDialog = () => {
    setIsModalDialogOpen(false);
  };

  async function downloadZippedCurriculum() {
    setDownloadingAmountFiles(curriculumAmount);

    const idsStudentsWithCurriculum = selectedStudents
      .filter((student) => student.hasCurriculum)
      .map((student) => student.id);

    const queryString = qs.stringify({
      students: idsStudentsWithCurriculum,
    });
    const studentsCurriculums = await getCurriculums(queryString);
    const downloadedResumes = studentsCurriculums.map(
      (student) => student.curriculum_id
    );
    await registerDownloadedCurriculum(downloadedResumes);

    let JSZip = new jszip();
    studentsCurriculums.map((student) => {
      const MyDoc = <Curriculum curriculumData={student} />;

      const blob = pdf(MyDoc).toBlob();
      JSZip.file(
        `${student.academic_profile.name}-${student.academic_profile.external_id}.pdf`,
        blob,
        { binary: true }
      );
    });
    const zippedFiles = await JSZip.generateAsync({ type: 'blob' });
    saveAs(zippedFiles, `Currículos ${new Date().toLocaleDateString()}.zip`);
    setDownloadingAmountFiles(0);
  }

  async function downloadZippedCoverLetter() {
    setDownloadingAmountFiles(coverLetterAmount);
    const idsStudentsWithCoverLetter = selectedStudents.reduce(
      (acc, student) => {
        if (student.hasCoverLetter) {
          acc.push(student.id);
        }
        return acc;
      },
      []
    );
    const studentCoverLetters = await getCoverLetterForDownload(
      qs.stringify({ students: idsStudentsWithCoverLetter })
    );

    let JSZip = new jszip();
    studentCoverLetters.map((coverLetter) => {
      const MyDoc = <CoverLetter coverLetter={coverLetter} />;
      const blob = pdf(MyDoc).toBlob();
      JSZip.file(
        `${coverLetter.student.name}-${coverLetter.student.external_id}.pdf`,
        blob,
        { binary: true }
      );
    });

    const zippedFiles = await JSZip.generateAsync({ type: 'blob' });
    saveAs(
      zippedFiles,
      `Cartas de Apresentação ${new Date().toLocaleDateString()}.zip`
    );
    setDownloadingAmountFiles(0);
  }

  const handleOpenModalSpreadSheet = () => {
    Api.generateSpreadsheetAndSendToEmail(activeCategory, egressed);
    const description = (
      <p>
        A lista está sendo exportada e você a receberá em seu e-mail{' '}
        <b> {metadata.staff_email}</b> no período de até 10 minutos.
      </p>
    );
    setDialogData({
      dialog: 'Tudo certo!',
      description,
      buttonLabel: `OK, ENTENDI`,
    });
    setIsModalDialogOpen(true);
  };

  const handleOpenModalCurriculum = () => {
    const description =
      curriculumAmount === selectedStudents.length
        ? `Você está prestes a baixar ${curriculumAmount} currículo${
            curriculumAmount > 1 ? 's' : ''
          }. Deseja continuar?`
        : `Dos ${selectedStudents.length} estudantes selecionados, apenas ${curriculumAmount} possuem currículo.`;
    setDialogData({
      dialog: 'Aviso!',
      description,
      buttonLabel: `BAIXAR CURRÍCULO${curriculumAmount > 1 ? 'S' : ''}`,
      actionButton: downloadZippedCurriculum,
    });
    setIsModalDialogOpen(true);
  };

  const handleOpenModalLetter = () => {
    const description =
      coverLetterAmount === selectedStudents.length
        ? `Você está prestes a baixar ${coverLetterAmount} carta${
            coverLetterAmount > 1 ? 's' : ''
          } de apresentação. Deseja continuar?`
        : `Dos ${selectedStudents.length} estudantes selecionados, apenas ${coverLetterAmount} possuem carta de apresentação.`;
    setDialogData({
      dialog: 'Aviso!',
      description,
      buttonLabel: `BAIXAR CARTA${
        coverLetterAmount > 1 ? 'S' : ''
      } DE APRESENTAÇÃO`,
      actionButton: downloadZippedCoverLetter,
    });
    setIsModalDialogOpen(true);
  };

  function handleChangePage(page) {
    changePage(page);
  }

  function addDotToNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return (
    <ListContainer>
      <ListContent>
        <ListHeader>
          <ListHeaderActions>
            {activeCategory !== StudentCategory.INACTIVE && (
              <ListHeaderCheckbox htmlFor="checkAll" title={loadingDataMessage}>
                <input
                  type="checkbox"
                  id="checkAll"
                  checked={isAllStudentsSelected}
                  onChange={toggleSelectAll}
                  disabled={studentRequestInProgress}
                  title={loadingDataMessage}
                />
                Selecionar todos{' '}
                {selectedStudents.length > 0 && `(${selectedStudents.length})`}
              </ListHeaderCheckbox>
            )}
            <div>
              {activeCategory === StudentCategory.INACTIVE && (
                <OutlinedButton
                  title={loadingDataMessage}
                  onClick={handleOpenModalSpreadSheet}
                >
                  <DownloadIcon size={14} color={'#009291'} />
                  Exportar Excel
                </OutlinedButton>
              )}
              {curriculumAmount > 0 && (
                <OutlinedButton
                  disabled={studentRequestInProgress}
                  title={loadingDataMessage}
                  onClick={handleOpenModalCurriculum}
                >
                  <DownloadIcon size={14} color={'#009291'} />
                  Baixar currículo{curriculumAmount > 1 ? 's' : ''}
                  {curriculumAmount > 0 ? ` (${curriculumAmount})` : ''}
                </OutlinedButton>
              )}
              {coverLetterAmount > 0 && (
                <OutlinedButton
                  disabled={studentRequestInProgress}
                  title={loadingDataMessage}
                  onClick={handleOpenModalLetter}
                >
                  <DownloadIcon size={14} color={'#009291'} />
                  Baixar carta{coverLetterAmount > 1 ? 's' : ''} de apresentação{' '}
                  {coverLetterAmount > 0 ? ` (${coverLetterAmount})` : ''}
                </OutlinedButton>
              )}
              <StudentFilter
                loading={filterRequestInProgress}
                title={loadingDataMessage}
              />
            </div>
          </ListHeaderActions>
          {!!requestedStudentCount &&
            !studentRequestInProgress &&
            Object.keys(appliedFilters).length > 0 && (
              <span>
                <strong>
                  {addDotToNumber(requestedStudentCount)} estudantes encontrados
                </strong>{' '}
                após filtrar dados
              </span>
            )}
        </ListHeader>
        {studentRequestInProgress ? (
          Array(4)
            .fill(1)
            .map((_, index) => <StudentTileSkeleton key={index} />)
        ) : studentsList.length > 0 ? (
          studentsList.map((student) => (
            <StudentTile key={student.id} student={student} />
          ))
        ) : (
          <GenericMessage
            title={
              egressed
                ? 'Nenhum egresso encontrado'
                : 'Nenhum estudante matriculado encontrado'
            }
            subtitle={
              egressed
                ? 'Nenhum egresso foi encontrado no momento'
                : 'Nenhum estudante matriculado encontrado no momento'
            }
          />
        )}
        <ModalDialog
          isOpen={isModalDialogOpen}
          onClose={handleCloseModalDialog}
          dialog={dialogData.dialog}
          description={dialogData.description}
          buttonLabel={dialogData.buttonLabel}
          actionButton={dialogData.actionButton}
        />
        {downloadingAmountFiles > 0 && (
          <DownloadInProgressIndicator>
            Fazendo o download de {downloadingAmountFiles} arquivos...
            <img src={progressImg} alt="" />
          </DownloadInProgressIndicator>
        )}
      </ListContent>
      {!studentRequestInProgress && (
        <Footer>
          <PaginationContainer>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={requestedStudentCount}
              limit={limitPerPage}
              onPageChange={handleChangePage}
              setLimit={setLimitPerPage}
              setCurrentPage={setCurrentPage}
              showLimit={false}
            />
          </PaginationContainer>
          <div className={'limit-container'}>
            <LimitSelect setLimit={setLimitPerPage} pageSize={limitPerPage} />
          </div>
        </Footer>
      )}
    </ListContainer>
  );
}
