import React, { useRef, useEffect, useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepIndicator from 'components/StepIndicator';
import { useNextMentorshipContext } from '../../context';
import { mentorshipApi } from '../../../../services/api';
import * as Yup from 'yup';
import { rescheduleStepOneValidationSchema } from './schema';
import GenericModal from 'views/StaffPages/Mentorship/components/GenericModal';

const RescheduleModalContent = (props) => {
  const {
    item,
    studentName,
    openCancelModal,
    title,
    onClose,
    isOpen,
    countDays,
    fetchMentorships,
  } = props;

  const {
    currentStep,
    setCurrentStep,
    rescheduleFormData,
    setRescheduleFormData,
    rescheduleModalOpen,
    formRefStepTwo,
    nextFormStep,
    formRefStepOne,
  } = useNextMentorshipContext();

  const [options, setOptions] = useState(null);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await mentorshipApi.getRescheduleOptions(
        item.id,
        item.mentor_id
      );
      setOptions(response);
    };
    if (item.id && isOpen) {
      fetchOptions();
    }
  }, [item, isOpen]);

  const formRef = useRef();

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.setErrors({});
    }
    onClose();
  };

  const handleBackToStepOne = () => {
    const formData = formRefStepTwo.current.getData();
    const newRescheduleFormData = {
      ...rescheduleFormData,
      ...formData,
    };
    setRescheduleFormData(newRescheduleFormData);
  };

  const handleSubmitStepOne = async (
    data = formRefStepOne.current.getData()
  ) => {
    try {
      await rescheduleStepOneValidationSchema.validate(data, {
        abortEarly: false,
      });
      const newRescheduleFormData = {
        ...rescheduleFormData,
        mentor: data.mentor.value,
        mentorship_modality: data.mentorship_modality.value,
        start_hour: hours[data.hours.value]?.start_hour,
        end_hour: hours[data.hours.value]?.end_hour,
        date: data.date,
      };
      setRescheduleFormData(newRescheduleFormData);
      nextFormStep();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};

        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        if (formRefStepOne) {
          formRefStepOne?.current?.setErrors(validationErrors);
        }
      }
    }
  };

  const steps = {
    1: (
      <StepOne
        item={item}
        studentName={studentName}
        openCancelModal={openCancelModal}
        options={options}
        handleSubmit={handleSubmitStepOne}
        setHours={setHours}
        countDays={countDays}
      />
    ),
    2: (
      <StepTwo
        item={item}
        studentName={studentName}
        openCancelModal={openCancelModal}
        handleClose={handleClose}
        handleBack={handleBackToStepOne}
        countDays={countDays}
        fetchMentorships={fetchMentorships}
      />
    ),
  };

  useEffect(() => {
    formRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentStep]);

  if (!rescheduleModalOpen) {
    return null;
  }

  const handleClickStepChange = (value) => {
    if (value === 1) {
      handleBackToStepOne();
      setCurrentStep(1);
    } else {
      handleSubmitStepOne();
    }
  };

  return (
    <GenericModal isOpen={isOpen} onClose={handleClose} title={title}>
      <div style={{ margin: '2rem' }}>
        <StepIndicator
          count={2}
          currentStep={currentStep}
          onStepChange={handleClickStepChange}
        />
        {steps[currentStep]}
      </div>
    </GenericModal>
  );
};

export default RescheduleModalContent;
