import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  ChipButton,
  InputContainer,
  InputBox,
  UnInputLabel,
  Error,
  TagLength,
} from './style';
import { CloseIcon } from 'assets/JobOffer/Close';
import BaseLayoutContext from 'contexts/base-layout';
import { useField } from '@unform/core';

export function InputMultiText({
  name,
  label,
  placeholder,
  list,
  setList,
  ...props
}) {
  const [value, setValue] = useState('');
  const inputRef = useRef(null);
  const { universityColor } = useContext(BaseLayoutContext);
  const [isValid, setIsValid] = useState(true);

  const [remainingWords, setRemainingWords] = useState(3);
  const [hasError, setHasError] = useState(false);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.includes('[') || inputValue.includes(']')) {
      setHasError(true);
    } else {
      setHasError(false);
      const words = inputValue.split(/\s+/).filter(Boolean);
      const remaining = 3 - words.length;
      if (remaining === 0) {
        setIsValid(true);
        setRemainingWords(remaining);
        setValue(e.target.value.toUpperCase().trim());
      } else if (remaining > 0) {
        setIsValid(true);
        setRemainingWords(remaining);
        setValue(e.target.value.toUpperCase());
      } else {
        setIsValid(false);
      }
    }
  };

  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'state.value',
      getValue: () => {
        return list;
      },
      setValue: (ref, values) => {
        setList(values);
      },
    });
  }, [fieldName, registerField, list]);

  useEffect(() => {
    document.addEventListener('mousedown', handleSubmit, false);
    return () => {
      document.removeEventListener('mousedown', handleSubmit, false);
    };
  }, [list]);

  function handleSubmit() {
    const valueIsSelected = list.includes(value);
    if (!valueIsSelected && value.trim() !== '') {
      setList([...list, value]);
      setValue('');
      setRemainingWords(3);
    }
  }

  return (
    <InputBox error={error}>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <input
        className={`input-multi-text`}
        name={name}
        type="text"
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
          }
        }}
        placeholder={placeholder}
        {...props}
      />
      {error && <Error>{error}</Error>}
      {hasError && <Error>Não é permitido o uso de colchetes</Error>}

      <TagLength>Palavras restantes: {remainingWords} </TagLength>

      <InputContainer>
        {list.map((option) => (
          <ChipButton
            universityColor={universityColor}
            key={option}
            onClick={() => setList(list.filter((v) => v !== option))}
          >
            <p>{option}</p>
            <CloseIcon color={'#009291'} />
          </ChipButton>
        ))}
      </InputContainer>
    </InputBox>
  );
}
