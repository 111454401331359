import styled from 'styled-components';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.isOpen ? `flex` : `none`)};
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1300;
  background-color: rgba(0, 0, 0, 0.5);
  color: #606062;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100vw - 260px);
  height: 100%;
  background-color: #eee;
  padding: 0 2rem 2rem 2rem;
  align-items: center;
  overflow-y: scroll;

  @media screen and (max-width: 963px) {
    left: 0;
    width: 100vw;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1048px;
  padding: 2rem;
  margin: 6rem 0 2rem 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;

  > form {
    display: flex;
    flex-direction: column;
    position: relative;
  }
`;

export const CloseButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  border-radius: 100%;
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Row = styled.div`
  display: flex;
  gap: ${(props) => props.gap || '1rem'};
  margin-top: ${(props) => props.marginTop || '0'};
  max-width: ${(props) => props.maxWidth || '100%'};

  @media screen and (max-width: 600px) {
    padding-bottom: 8px;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.customDateSelector > * {
    flex: 1 1 calc(50% - 0.5rem);
    box-sizing: border-box;
  }

  ${(props) =>
    props.wrap &&
    `
    @media screen and (max-width: 670px) {
      flex-wrap: wrap; 
    }
  `}
`;

export const MentorImage = styled.img`
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin: ${({ margin }) => margin || '0'};
  border: 1px solid #606062;
`;

export const DaySelector = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.color};
  width: 118px;
  min-width: 118px;
  height: 118px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  gap: 0.5rem;

  > span {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: ${(props) => props.textColor};
  }

  > p {
    font-size: 18px;
    line-height: 18px;
    color: #606062;
    margin: 0;
  }
`;
export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  > span {
    font-weight: 700;
    font-size: 24px;
    color: #606062;
  }

  > div {
    display: flex;
    gap: 0.5rem;

    > a {
      font-size: 18px;
      color: ${(props) => props.color || '#009291'};
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const ChipButton = styled.button`
  display: flex;
  border: ${(props) => (props.selected ? 'none' : `1px solid ${props.color}`)};
  background-color: ${(props) => (props.selected ? props.color : '#fff')};
  color: ${(props) => (props.selected ? '#fff' : '#606062')};
  height: 40px;
  font-size: 17px;
  padding: 0 1rem;
  border-radius: 46px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  @media screen and (max-width: 475px) {
    width: 100%;
  }
`;
export const WrapperTimes = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  row-gap: 1rem;
  flex-wrap: wrap;
`;

export const WrapperItem = styled.div`
  height: 80px;
  display: flex;
  width: 233px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => (props.selected ? props.color : '#EEF2F4')};
  cursor: pointer;
  gap: 0.5rem;

  > span {
    font-size: 16px;
    color: #606062;
  }
`;

export const CircleRadio = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 100%;
  border: 2px solid ${(props) => (props.selected ? props.color : '#C4C4C4')};
  background-color: #fff;
  position: relative;

  ${({ selected, color }) =>
    selected &&
    `
  &::after {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    left: 2px;
    top: 2px;
    background-color: ${color};
    border-radius: 100%;
  }
  `}
`;

export const TimeField = styled.div`
  display: flex;
  border: 1px solid #c4c4c4;
  height: 40px;
  width: 60px;
  border-radius: 3px;
  color: #606062;
  font-size: 16px;
  justify-content: center;
  margin: 0;
  align-items: center;
`;

export const FieldLabel = styled.p`
  font-size: 20px;
  color: #606062;
  margin-top: 2rem;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;

export const SubmitButton = styled.button`
  width: 220px;
  height: 44px;
  border: none;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  margin-top: 4rem;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: #efeeec;
    color: #949494;
  }
`;

export const DateInvisibleWrapper = styled.div`
  display: flex;

  .react-datepicker-wrapper {
    display: none;
  }
  .react-datepicker {
    margin-left: ${(props) => props.marginLeft};
    margin-top: 12rem;

    .react-datepicker__triangle {
      display: none;
    }

    @media (max-width: 600px) {
      margin-left: 4rem;
      margin-top: 22rem;
    }
  }
`;
