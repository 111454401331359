import { FilterMultiSelect } from './FilterMultiSelect';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterText } from './FilterText';
import React, { Fragment, useContext } from 'react';
import { FilterBox } from '../styles';
import { FilterDateRange } from './FilterDateRange';
import { FilterSingleCheckbox } from './FilterSingleCheckbox';
import { FilterRadio } from './FilterRadio';
import { FilterSingleSelect } from './FilterSingleSelect';
import { FilterMultiText } from './FilterMultiText';
import BaseLayoutContext from 'contexts/base-layout';
import { FilterProgressRange } from './FilterProgressRange';
import { FilterAsyncSingleSelect } from './FilterAsyncSingleSelect';

export const FilterType = {
  SINGLE_CHECKBOX: 'SINGLE_CHECKBOX',
  CHECKBOX: 'CHECKBOX',
  RADIO: 'RADIO',
  SINGLE_SELECT: 'SINGLE_SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  TEXT: 'TEXT',
  MULTI_TEXT: 'MULTI_TEXT',
  DATE_RANGE: 'DATE_RANGE',
  PROGRESS_RANGE: 'PROGRESS_RANGE',
  ASYNC_SINGLE_SELECT: 'ASYNC_SINGLE_SELECT',
};

const defaultSettings = {
  isTwoColumns: false,
  wrap: false,
  placeholder: 'Digite aqui...',
  disabled: false,
  onChange: () => {},
  loading: false,
  listHeight: 'auto',
  onType: () => {},
  loadOptions: () => {},
  minCharToSearch: 3,
  latency: 500,
};

export function Filter({
  type,
  name,
  label,
  startYear,
  maxDate,
  options = [],
  settings,
  ...props
}) {
  settings = {
    ...defaultSettings,
    ...settings,
  };
  const { universityColor } = useContext(BaseLayoutContext);

  function renderFilter({ type, name, options }) {
    switch (type) {
      case FilterType.MULTI_SELECT:
        return (
          <FilterMultiSelect
            name={name}
            options={options}
            disabled={settings.disabled}
            onChange={settings.onChange}
            loading={settings.loading}
            onType={settings.onType}
            listHeight={settings.listHeight}
          />
        );
      case FilterType.CHECKBOX:
        return (
          <FilterCheckbox
            name={name}
            options={options}
            isTwoColumns={settings.isTwoColumns}
            wrap={settings.wrap}
          />
        );
      case FilterType.TEXT:
        return (
          <FilterText
            placeholder={settings.placeholder}
            name={name}
            disabled={settings.disabled}
            {...props}
          />
        );
      case FilterType.DATE_RANGE:
        const placeholder =
          typeof settings.placeholder === 'string'
            ? null
            : settings.placeholder;
        return (
          <FilterDateRange
            name={name}
            placeholder={placeholder}
            onChange={settings.onChange}
            startYear={startYear}
            maxDate={maxDate}
          />
        );
      case FilterType.SINGLE_CHECKBOX:
        return <FilterSingleCheckbox name={name} label={label} />;
      case FilterType.RADIO:
        return (
          <FilterRadio
            name={name}
            options={options}
            isTwoColumns={settings.isTwoColumns}
            wrap={settings.wrap}
          />
        );
      case FilterType.PROGRESS_RANGE:
        return (
          <FilterProgressRange
            name={name}
            placeholder={settings.placeholder}
            {...props}
          />
        );
      case FilterType.SINGLE_SELECT:
        return (
          <FilterSingleSelect
            name={name}
            options={options}
            onType={settings.onType}
          />
        );
      case FilterType.ASYNC_SINGLE_SELECT:
        return (
          <FilterAsyncSingleSelect
            name={name}
            loadOptions={settings.loadOptions}
            minCharToSearch={settings.minCharToSearch}
            latency={settings.latency}
          />
        );
      case FilterType.MULTI_TEXT:
        return (
          <FilterMultiText
            name={name}
            placeholder={settings.placeholder}
            {...props}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Fragment>
      {type !== FilterType.SINGLE_CHECKBOX ? (
        <FilterBox key={name} universityColor={universityColor}>
          <strong
            style={{
              marginBottom: type !== FilterType.CHECKBOX ? '0.5rem' : '0',
            }}
          >
            {label}
          </strong>
          {renderFilter({ name, type, options })}
        </FilterBox>
      ) : (
        renderFilter({ name, type, options })
      )}
    </Fragment>
  );
}
