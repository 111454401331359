import { UserIcon } from 'assets/mentorship/UserIcon';
import { CalendarIcon } from 'assets/mentorship/CalendarIcon';
import { ClockIcon } from 'assets/mentorship/ClockIcon';
import { LaptopIcon } from 'assets/mentorship/LaptopIcon';
import { HouseLaptopIcon } from 'assets/mentorship/HouseLaptopIcon';
import { TargetIcon } from 'assets/mentorship/TargetIcon';
import { AiOutlineCheck, AiOutlineCloseCircle } from 'react-icons/ai';
import { MdOutlineTimer } from 'react-icons/md';
import EditIcon from 'assets/ComponentIcons/EditIcon';

const Icons = {
  UserIcon,
  CalendarIcon,
  ClockIcon,
  LaptopIcon,
  HouseLaptopIcon,
  TargetIcon,
  EditIcon,
  AiOutlineCheck,
  AiOutlineCloseCircle,
  MdOutlineTimer,
};

export default Icons;
