import { shape } from 'prop-types';
import React, { useState, Fragment, useRef } from 'react';
import ListTargetAudienceItem from './ListTargetAudienceItem';

export function TargetAudienceDetails({ reportOptions, targetAudience }) {
  const [showMore, setShowMore] = useState(false);
  const ref = useRef();
  const pRef = useRef();
  function toggleShowMore() {
    setShowMore(!showMore);
    if (showMore) {
      ref.current.scrollIntoView();
    }
  }
  let matchCourseLevel;
  let matchMacroarea;
  let matchStudentStatus;
  const allOptions = [{ label: 'Indiferente', value: null }];
  if (Object.keys(reportOptions)) {
    const {
      course_levels: courseLevel,
      macroareas,
      student_statuses: studentStatus,
    } = reportOptions;

    matchCourseLevel = courseLevel.filter((item) =>
      targetAudience.course_levels.some((level) => level === item.value)
    );

    matchMacroarea = macroareas.filter((item) =>
      targetAudience.macroareas.some((macro) => macro === item.value)
    );

    matchStudentStatus = studentStatus.filter((item) =>
      targetAudience.student_status.some((status) => status === item.value)
    );
  }
  return (
    <div className="targetAudience">
      <h1 ref={ref}>Público Alvo:</h1>
      <ListTargetAudienceItem
        target={
          matchCourseLevel.length === 0
            ? allOptions
            : matchCourseLevel
        }
        title="Nível do curso:"
        isItemlabel
      />
      <ListTargetAudienceItem
        target={
          matchMacroarea.length === 0
            ? allOptions
            : matchMacroarea
        }
        title={matchMacroarea.length >= 1 ? 'Macroáreas:' : 'Macroárea:'}
        isItemlabel
      />
      <Fragment>
        <ListTargetAudienceItem
          target={
            targetAudience.courses.length === 0
              ? allOptions
              : targetAudience.courses
          }
          title={targetAudience.courses.length > 1 ? 'Cursos:' : 'Curso:'}
          isItemlabel
          showMore={showMore}
          ref={pRef}
        />
        {pRef.current && pRef.current.clientHeight >= 150 && (
          <button type="button" onClick={() => toggleShowMore(!showMore)}>
            {!showMore ? 'mostrar mais' : 'mostrar menos'}
          </button>
        )}
      </Fragment>
      <ListTargetAudienceItem
        target={
          matchStudentStatus.length === 0
            ? allOptions
            : matchStudentStatus
        }
        title="Status do Estudante:"
        isItemlabel
      />
      <ListTargetAudienceItem
        target={
          targetAudience.semesters.length === 0
            ? allOptions
            : targetAudience.semesters
        }
        title={targetAudience.semesters.length > 1 ? 'Períodos:' : 'Período:'}
        isItemlabel={
          targetAudience.semesters.length === 0
        }
      />
    </div>
  );
}

TargetAudienceDetails.propTypes = {
  reportOptions: shape({}).isRequired,
  targetAudience: shape({}).isRequired,
};
