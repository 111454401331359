import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ActionButton = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const Button = styled.button`
  width: auto;
  height: auto;
  padding: 10px;
  border-radius: 2px 0 0 0;
  border: none;
  background: #0092911c;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
  color: #009291;

  display: flex;
  align-items: center;
  gap: 6px;

  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.8);
  }

  &:disabled {
    cursor: not-allowed;
    color: #949494;
    background-color: #f5f5f5;
  }

  &:disabled:hover {
    filter: none;
  }
`;
