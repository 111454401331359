import React, { Fragment, useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { formatNumber } from '../../utils/formatNumber';
import NoGraphicValue from '../NoGraphicValue';

import { Ul, Li, CustomToolTip, ContainerLegend, TotalStudents } from './style';

export default function PieChartCustom({ config }) {
  const [focusItem, setFocusItem] = useState(null);
  const [valueIsZero, setValueIsZero] = useState(false);

  const {
    graphicData,
    bottomPhrase,
    colors,
    heightContainer,
    layout,
    verticalAlign,
    align,
    keyAccessGraphic,
    innerRadius,
    showInsideLabel,
    useChartLegend,
    useToolTipPercentage,
    labelInsideText,
    valueInsideText,
    fontSizeInsideText,
    verifyValues,
  } = config;

  useEffect(() => {
    if (verifyValues) {
      if (graphicData) {
        const items = graphicData.filter((item) => item.value === 0);
        if (items.length > 0) {
          return setValueIsZero(true);
        }
        setValueIsZero(false);
      }
    }
  }, [graphicData]);

  const CustomLabel = ({ viewBox, labelText, value }) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text
          x={cx}
          y={cy}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fontSize={fontSizeInsideText || 25}
          fontWeight="600"
          fill="#009291"
        >
          {value}
        </text>
        <text
          x={cx}
          y={cy + 20}
          className="recharts-text recharts-label"
          textAnchor="middle"
          dominantBaseline="central"
          alignmentBaseline="middle"
          fill="#000"
          fontSize="12"
        >
          {labelText}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      setFocusItem(payload[0].payload.value);
      return (
        <CustomToolTip>
          <label>
            {`${formatNumber(payload[0].payload.value)}`}
            {useToolTipPercentage && payload[0].payload.percentage && (
              <Fragment>
                <br />
                <span>{payload[0].payload.percentage}%</span>
              </Fragment>
            )}
          </label>
        </CustomToolTip>
      );
    }
    setFocusItem(null);
    return null;
  };

  const CustomizedLegend = (props) => {
    const { payload } = props;

    return (
      <ContainerLegend>
        <Ul>
          {payload.map((item) => (
            <Li color={item.payload.fill}>{item.payload.label}</Li>
          ))}
          <TotalStudents>{bottomPhrase}</TotalStudents>
        </Ul>
      </ContainerLegend>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={heightContainer}>
      {valueIsZero ? (
        <NoGraphicValue />
      ) : (
        <PieChart>
          <label />
          <Pie
            data={graphicData ? graphicData : []}
            color="#000000"
            dataKey={keyAccessGraphic}
            nameKey="label"
            innerRadius={innerRadius}
            outerRadius="100%"
            stroke="none"
            margin={{
              top: 10,
              right: 10,
              left: 20,
              bottom: 5,
            }}
          >
            {graphicData &&
              graphicData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index]}
                  style={
                    focusItem === entry.value
                      ? {
                          filter: `drop-shadow(0px 0px 5px ${colors[index]}`,
                        }
                      : {}
                  }
                />
              ))}
            {showInsideLabel && (
              <Label
                content={
                  <CustomLabel
                    labelText={labelInsideText}
                    value={valueInsideText}
                  />
                }
                position="center"
              />
            )}
          </Pie>
          <Tooltip content={<CustomTooltip />} />

          {useChartLegend && (
            <Legend
              layout={layout}
              verticalAlign={verticalAlign}
              align={align}
              content={<CustomizedLegend />}
            />
          )}
        </PieChart>
      )}
    </ResponsiveContainer>
  );
}
