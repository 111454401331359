import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: ${(props) => (props.size === 'unpublish' ? '519px' : '402px')};
  height: 301px;

  background-color: #ffffff;
  border-radius: 10px;
  padding: 39px;
`;

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .border-image {
    width: 76px;
    height: 76px;
    background-color: #009291;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-image img {
    width: 100%;
    height: 100%;
  }

  .bubble-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #009291;
    margin-left: 1.5em;
    border-radius: 4px;
    width: 107px;
    height: 35px;
    position: relative;

    ::before {
      content: '';
      width: 0px;
      height: 0px;
      position: absolute;
      border-left: 4.5px solid transparent;
      border-right: 4.5px solid #009291;
      border-top: 4.5px solid #009291;
      border-bottom: 4.5px solid transparent;
      border-radius: 1px;
      left: -4px;
      top: 18px;
      transform: rotate(-135deg);
    }
  }

  .bubble-dialog span {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
  }
`;

export const WrapperMessage = styled.div`
  width: 100%;
  font-size: 1.4rem;
  font-weight: 400;
  color: #606062;
  line-height: 1.6rem;
  padding: 22px 0;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const WhiteButton = styled.div`
  padding: 11px;
  border-radius: 9px;
  width: 100%;
  max-width: ${(props) => (props.size === 'unpublish' ? '280px' : '129px')};
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  color: #009291;
  background-color: #ffffff;
  border: 1px solid #009291;
  margin-right: 5px;
  cursor: pointer;
`;

export const GreenButton = styled.div`
  width: 100%;
  max-width: 183px;
  padding: 11px;
  border-radius: 9px;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #009291;
  margin: 5px;
  cursor: pointer;
`;
