export const GrowthIcon = ({ size = 13, color = 'white' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16396_51298)">
        <path
          d="M10.3839 3H7.88392C7.46992 3 7.13392 3.336 7.13392 3.75C7.13392 4.164 7.46992 4.5 7.88392 4.5H9.57342L6.63392 7.4395L4.57442 5.38C4.05592 4.8615 3.21192 4.8615 2.69342 5.38L0.353417 7.7195C0.060417 8.0125 0.060417 8.487 0.353417 8.78C0.646417 9.073 1.12092 9.073 1.41392 8.78L3.63342 6.5605L5.69292 8.62C5.95242 8.8795 6.29242 9.009 6.63342 9.009C6.97442 9.009 7.31442 8.879 7.57392 8.62L10.6334 5.5605V7.25C10.6334 7.664 10.9694 8 11.3834 8C11.7974 8 12.1334 7.664 12.1334 7.25V4.75C12.1334 3.785 11.3489 3 10.3839 3Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_16396_51298">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.133911)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
