import { BtArrow, ButtonNav, Container } from './styled';
export function GenericInternalTabs(props) {
  const { tabs, handleChange, activeTab, disabled, height } = props;

  const scrollRigthInternalTab = () => {
    document.getElementById('generic-internal-tab').scrollLeft += 150;
  };

  const scrollLeftInternalTab = () => {
    document.getElementById('generic-internal-tab').scrollLeft += -150;
  };

  return (
    <>
      <Container height={height} id="generic-internal-tab">
        <BtArrow left="left" onClick={() => scrollLeftInternalTab()}></BtArrow>
        {tabs &&
          tabs.length > 1 &&
          tabs.map((item, index) => (
            <ButtonNav
              type="button"
              active={activeTab === index}
              onClick={() => handleChange(index)}
              disabled={disabled}
            >
              {item}
            </ButtonNav>
          ))}
        <BtArrow
          right="right"
          onClick={() => scrollRigthInternalTab()}
        ></BtArrow>
      </Container>
    </>
  );
}

GenericInternalTabs.defaultProps = {
  tabs: ['Aba 1', 'Aba 2'],
  handleChange: () => {},
  activeTab: 0,
};
