import React, { useContext, useEffect, useRef, useState } from 'react';
import TabJobCompany from './Components/Tabs';
import StudentsPage from './Students';

import { Container, Content } from './styled';

export default function CommunicationRulerTab({ noScroll, setNoScroll }) {
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const refContainer = useRef(null);

  const ScrollToDiv = () => {
    if (refContainer) {
      refContainer.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    ScrollToDiv();
  }, [currentPage]);

  const Tabs = [
    {
      activeTab: 0,
      component: <StudentsPage setNoScroll={setNoScroll} />,
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  return (
    <Container noScroll={noScroll} ref={refContainer}>
      <Content>
        <TabJobCompany
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={[`Estudantes`]}
        />
        {renderPrincipalTabs(activeTab)}
      </Content>
    </Container>
  );
}
