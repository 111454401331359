export function adapterSpecificDates(data) {
  const result = [];

  if (!data || data.length === 0) {
    return result;
  }

  data.reduce((acc, curr) => {
    const existingEntry = acc.find((entry) => entry.date === curr.date);

    if (existingEntry) {
      existingEntry.schedules.push({
        start_hour: curr.start_hour,
        end_hour: curr.end_hour,
        id: curr.id,
      });
    } else {
      acc.push({
        date: curr.date,
        schedules: [
          {
            start_hour: curr.start_hour,
            end_hour: curr.end_hour,
            id: curr.id,
          },
        ],
      });
    }

    return acc;
  }, result);

  return result;
}
