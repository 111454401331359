import React, { Fragment } from 'react';
import { bool, shape, func } from 'prop-types';
import { FastField } from 'formik';
import { FormHelperText } from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import { InfoIcon, AlignCenterView } from 'components/Styled';

function QuestionTwo({
  isSubmitting,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) {
  return (
    <Fragment>
      <p>
        A sua formação representa a oportunidade de acessar novas possibilidades
        no mercado de trabalho, além de permitir que você se torne cada vez mais
        um especialista em você. Para que isso fique claro e sirva como um
        direcionador em sua jornada estudantil, tente detalhar quais
        conhecimentos e habilidades espera desenvolver ao longo do curso, quais
        interesses quer cultivar e quais experiências quer viver.
      </p>
      <AlignCenterView>
        <InfoIcon color="#eb9b00" height="25px" /> Dica: Lembre-se que o
        recrutador quer conhecer quem você é por meio das suas escolhas e
        expectativas.
      </AlignCenterView>
      <FastField
        name="questionTwo"
        label="Conte suas expectativas"
        variant="outlined"
        id="questionTwo"
        placeholder="Resposta"
        multiline
        rowsMax="15"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.questionTwo && touched.questionTwo}
        value={values.questionTwo}
        component={CustomTextField}
        fullWidth
        disabled={isSubmitting}
      />
      <FormHelperText>
        {values.questionTwo &&
          `Sua resposta tem ${values.questionTwo.length} caracteres.`}
      </FormHelperText>
      {errors.questionTwo && touched.questionTwo ? (
        <FormHelperText>{errors.questionTwo}</FormHelperText>
      ) : null}
    </Fragment>
  );
}

QuestionTwo.propTypes = {
  isSubmitting: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
};

export default QuestionTwo;
