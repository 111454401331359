import {
  ContentWrapper,
  FakeCheckbox,
} from 'components/ListCandidates/Styles/FeedbackComponent';
import {
  Box,
  Wrapper,
  Content,
  TopBar,
  CloseButton,
} from 'components/ListCandidates/Styles/ModalComponents';
import { IoClose } from 'react-icons/io5';
import { IoMdCheckmark } from 'react-icons/io';

export function ViewFeedback({
  reasonsList,
  reasonsDescription,
  controlModal,
  setControlModal,
}) {
  return (
    <Wrapper visible={controlModal}>
      <Box>
        <Content>
          <TopBar>
            <p className="title">Visualizar feedback</p>
            <CloseButton onClick={() => setControlModal(false)}>
              <IoClose size={18} />
            </CloseButton>
          </TopBar>
          <ContentWrapper>
            <p>Currículo marcado como não interessante por:</p>
            <span>
              Confira abaixo os motivos pelos quais você não considerou o
              currículo desse estudante interessante.
            </span>
            <div className="checkbox-group">
              {reasonsList?.map((feedback) => (
                <div className="group-span-icon">
                  <FakeCheckbox>
                    <IoMdCheckmark />
                  </FakeCheckbox>
                  <span>{feedback}</span>
                </div>
              ))}
              {reasonsDescription?.length > 0 && (
                <div className="group-span-icon">
                  <FakeCheckbox>
                    <IoMdCheckmark />
                  </FakeCheckbox>
                  <span>{reasonsDescription}</span>
                </div>
              )}
            </div>
          </ContentWrapper>
        </Content>
      </Box>
    </Wrapper>
  );
}
