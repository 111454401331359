import AppBar from '@material-ui/core/AppBar';

import styled from 'styled-components';

const JobHeaderTab = (theme) => ({
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 10,
    alignSelf: 'flex-end',
    display: 'flex',
  },

  tabIndicator: {
    height: 3,
    zIndex: 2,
  },
  scrollButtons: {
    borderBottom: '1px solid #EDEDED',
    marginBottom: '-1px',
    [theme.breakpoints.down('lg')]: {
      color: '#777777',
    },
  },
  borderContainer: {
    border: '1px solid',
  },
  tabButton: {
    fontSize: 18,
    color: '#777777',
    fontWeight: '500',
    maxWidth: 'fit-content',
    textAlign: 'center',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 180,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectLabel: {
    whiteSpace: 'nowrap',
  },
  cssFocused: {},
});

export default JobHeaderTab;

export const FilterContainer = styled.div`
  display: flex;
  right: 2rem;
  top: 1rem;
  position: absolute;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const TalentsAppBar = styled(AppBar)`
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 10;
  align-self: flex-end;
  display: flex;
  box-shadow: none;

  .tabIndicator {
    background-color: #009291;
    height: 3;
    z-index: 2;
  }

  .scrollButtons {
    border-bottom: 1px solid #ededed;
    margin-bottom: -1px;
  }
  .borderContainer {
    border: 1px solid;
  }
  .tabButton {
    font-size: 18px;
    color: #777777;
    font-weight: 500;
    max-width: fit-content;
    text-align: center;
    text-transform: none;
    z-index: 2;

    @media screen and (max-width: 600px) {
      font-size: 14;
    }
  }
`;
