import {
  Container, Box, Content, 
  WrapperTitle, BubbleDialog, 
  ContainerButton, ButtonReturn, 
  GreenButton, WrapperMessage
} from './style'
import Wall from 'assets/JobOffer/IconPerson.svg';

export default function SimpleModalWal (props) {

  const {
    isOpen, funcGreenButton, 
    funcGrayButton, configs
  } = props;

  return (
    <Container isOpen={isOpen}>
      <Box>
        <Content>
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <BubbleDialog 
              widthBubble={configs?.styles?.widthBubble} 
              heightBubble={configs?.styles?.heightBubble}
            >
              <span> {configs?.title} </span>
            </BubbleDialog>
          </WrapperTitle>
          <WrapperMessage
            fontMessage={configs?.styles?.fontMessage}
            lineHeigthMessage={configs?.styles?.lineHeigthMessage}
            positionMessage={configs?.styles?.positionMessage}
          >
            <span>
              {configs?.message}
            </span>
            <span>
              {configs?.secondMessage}
            </span>
          </WrapperMessage>
          <ContainerButton>
            <ButtonReturn
              onClick={funcGrayButton}
              marginTopButton={configs?.styles?.marginTopGrayButton}
            >
              {configs?.labelGrayButton}
            </ButtonReturn>
            <GreenButton
              onClick={funcGreenButton}
              marginTopButton={configs?.styles?.marginTopGreenButton}
            >
              {configs?.labelGreenButton}
            </GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  )  
}