import axios from 'axios';
import URLS from 'constants/urls';
import { studentFilterAdapter } from 'adapters/student/studentListAdapter';

const getProfessionalStatusReport = async (filters) => {
  const queryString = studentFilterAdapter(filters, false);

  const response = await axios
    .get(
      `${URLS.API_URL}/api/internal/university/reports/professional-status?${queryString}`,
      {
        withCredentials: true,
      }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getStudentProfessionalReportOptions = async () => {
  const response = await axios
    .options(
      `${URLS.API_URL}/api/internal/university/reports/professional-status`,
      {
        withCredentials: true,
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};
export { getProfessionalStatusReport, getStudentProfessionalReportOptions };
