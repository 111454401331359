import { createContext, useContext, useState } from 'react';

export const DashboardContext = createContext({});

export function DashboardProvider({ children }) {
  const [fetchMe, setFetchMe] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState(0);
  const [jobsFilter, setJobsFilter] = useState(null);
  const [queryFilter, setQueryFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [informationPeriod, setInformationPeriod] = useState({})

  return (
    <DashboardContext.Provider
      value={{
        fetchMe,
        setFetchMe,
        queryFilter,
        setQueryFilter,
        activeSubTab,
        setActiveSubTab,
        jobsFilter,
        setJobsFilter,
        loading, 
        setLoading,
        informationPeriod, 
        setInformationPeriod
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
