import styled from 'styled-components';
import {
  ButtonLeft,
  ButtonRight,
} from 'views/StaffPages/Opportunities/Styles/PublishedOpportunities';

import {IoIosArrowForward, IoIosArrowBack} from 'react-icons/io'

export const ButtonsPagination = ({funcButtonRight, funcButtonLeft, positionStart, universityColor}) => {

  const ContainerButtons = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${positionStart ? 'flex-start' : 'flex-end'};
    margin-top: 20px;
  `;



  return (
    <ContainerButtons>
      <ButtonLeft 
        universityColor={universityColor} 
        onClick={funcButtonLeft}
      >
        <IoIosArrowBack size={18} color="#fff" />
      </ButtonLeft>
      <ButtonRight 
        universityColor={universityColor}
        onClick={funcButtonRight}
      >
        <IoIosArrowForward size={18} color="#fff"/>  
      </ButtonRight>
    </ContainerButtons>
  );
};
