import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import { Form } from '@unform/web';
import {
  Wrapper,
  DescImg,
  MultiStepContainer,
  Img,
  Title,
  SubTitle,
  AboutTitle,
  Abount,
  CompetenciesContainer,
  WrapperCompetencies,
  DivCompetencies,
  SelectedCompetencies,
} from '../../../Styles/formStyled';
import Group448 from '../../../../../../assets/Oportutinites/Group448.svg';
import OportunidadesGroup from '../../../../../../assets/img/OportunidadesGroup.svg';
import SearchableSelectUn from '../../Form/searchableSelect';
import Input from '../../Form/input';
import {
  OportunitiesMacroAreas,
  getCourses,
  getPeriod,
} from '../../../services';
import FooterForm from '../../FooterForm';
import { ButtonX } from '../../../Styles/Styled';
import StepIndicator from '../../StepIndicator';
import {
  createNewEventNew,
  updateEvent,
} from '../../../../../../services/university/events';
import moment from 'moment';
import InformationModal from '../../InformationModal';
import { useEducationalGroupLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsEducationalGroup';
import { useLearningAPICalls } from 'views/StaffPages/Opportunities/hooks/apiCallsOpportunities';

function StepTree({ setActiveTab, type }) {
  const formRef = useRef(null);
  const {
    setStep,
    macroAreas,
    educationalGroupUniversities,
    formData,
    interests,
    competencies,
    setFormValues,
    periods,
    setPeriods,
    educationalGroup,
    setMacroAreas,
    course,
    setCourse,
    step,
  } = useContext(OportunitiesContext);

  const {
    getAllEvents,
    getAllPrograms,
    getAllTrails,
    getAllArticles,
    getAllSeries,
    getAllEbooks,
    getAllChallenges,
    getAllAnnouncements,
    getAllCourses,
  } = useLearningAPICalls();

  const [arrayMacroAreas, setArrayMacroAreas] = useState([]);
  const [
    arrayEducationalGroupUniversities,
    setArrayEducationalGroupUniversities,
  ] = useState([]);
  const [arrayCourseSearch, setArrayCourseSearch] = useState([]);
  const [disableButton, setDisableButton] = useState(false);

  const [inputSearch, setInputSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState([]);
  const [selectedCompetencies, setSelectedCompetencies] = useState([]);
  const [selectedCompetenciesValues, setSelectedCompetenciesValues] = useState(
    []
  );

  const [disabledInput, setDisabledInput] = useState(false);
  const [informationModalOpen, setInformationModalOpen] = useState(false);
  const [modalInformation, setModalInformation] = useState({
    type: 'success',
    informationHeader: 'Tudo certo!',
    errorMsg: 'Suas informações foram  enviadas com sucesso',
    finish: true,
  });

  const {
    getEducationalGroupAllEvents,
    getEducationalGroupAllProgram,
    getEducationalGroupAllCourses,
    getEducationalGroupAllArticles,
    getEducationalGroupAllEbooks,
    getEducationalGroupAllTrails,
    getEducationalGroupAllSeries,
    getEducationalGroupAllAnnouncements,
    getEducationalGroupAllChallenges,
  } = useEducationalGroupLearningAPICalls();

  const topModal = useRef();

  const sendCourses = [];
  const sendPeriods = [];
  const sendMacroAreas = [];
  const sendUniversities = [];

  const arrayTypes = [
    {
      type: 'event',
      getOpportunities: getAllEvents,
    },
    {
      type: 'course',
      getOpportunities: getAllCourses,
    },
    {
      type: 'program',
      getOpportunities: getAllPrograms,
    },
    {
      type: 'announcements',
      getOpportunities: getAllAnnouncements,
    },
    {
      type: 'trail',
      getOpportunities: getAllTrails,
    },
    {
      type: 'e_book',
      getOpportunities: getAllEbooks,
    },
    {
      type: 'article',
      getOpportunities: getAllArticles,
    },
    {
      type: 'challenge',
      getOpportunities: getAllChallenges,
    },
    {
      type: 'serie',
      getOpportunities: getAllSeries,
    },
  ];

  const arrayTypesEducationalGroup = [
    {
      type: 'event',
      getOpportunitie: getEducationalGroupAllEvents,
    },
    {
      type: 'program',
      getOpportunitie: getEducationalGroupAllProgram,
    },
    {
      type: 'course',
      getOpportunitie: getEducationalGroupAllCourses,
    },
    {
      type: 'article',
      getOpportunitie: getEducationalGroupAllArticles,
    },
    {
      type: 'e_book',
      getOpportunitie: getEducationalGroupAllEbooks,
    },
    {
      type: 'trail',
      getOpportunitie: getEducationalGroupAllTrails,
    },
    {
      type: 'serie',
      getOpportunitie: getEducationalGroupAllSeries,
    },
    {
      type: 'announcements',
      getOpportunitie: getEducationalGroupAllAnnouncements,
    },
    {
      type: 'challenge',
      getOpportunitie: getEducationalGroupAllChallenges,
    },
  ];

  const opportunitiesFind = async (educationalGroup) => {
    if (!educationalGroup) {
      const returned = arrayTypes.find((item) => item.type === formData.type);
      await returned.getOpportunities(educationalGroup, true);
    }

    if (educationalGroup) {
      const returned = arrayTypesEducationalGroup.find(
        (item) => item.type === formData.type
      );
      await returned.getOpportunitie(true);
    }
  };

  const handleSubmit = async (data) => {
    setDisableButton(true);

    if (educationalGroup) {
      data.universities.forEach((item) => {
        sendUniversities.push(item);
      });
    }
    data.courses.forEach((item) => {
      sendCourses.push(item.replace('c', ''));
    });
    data.semesters.forEach((item) => {
      sendPeriods.push(item.replace('p', ''));
    });
    if (formData.isOnModal) {
      formData.handleSubmitOportunity();
    }

    const fullData = {
      ...formData,
      ...data,
      deadline_registration: formData.deadline_registration
        ? moment(formData.deadline_registration).format('DD/MM/YYYY 00:00')
        : '',
      competences_specialities: [...new Set(selectedCompetenciesValues)],
      universities: sendUniversities,
      courses: sendCourses,
      semesters: sendPeriods,
      area_categories: formData.area_categories
        ? formData.area_categories.value
        : '',
    };
    const ScrollToModalTop = () => {
      if (topModal.current) {
        topModal.current.scrollIntoView({ block: 'start', inline: 'nearest' });
      } else {
        return;
      }
    };

    if (formData.is_editable) {
      const response = await updateEvent(fullData, educationalGroup);
      setDisableButton(false);
      if (response.status === 200) {
        ScrollToModalTop();
        setModalInformation({
          type: 'success',
          informationHeader: 'Tudo certo!',
          errorMsg: 'Suas informações foram  enviadas com sucesso',
          finish: true,
          setActiveTab: formData.setActiveTab,
          setModalOpen: formData.setModalOpen,
        });
        setInformationModalOpen(true);
        opportunitiesFind(educationalGroup);
      } else {
        ScrollToModalTop();
        setInformationModalOpen(true);
        setModalInformation({
          type: 'error',
          informationHeader: 'Ooops! Algo deu errado.',
          errorMsg: 'Verifique se existem informações pendentes ou incorretas.',
        });
      }
      return;
    }

    const response = await createNewEventNew(fullData, educationalGroup);
    setDisableButton(false);
    if (response.status === 201) {
      ScrollToModalTop();
      setInformationModalOpen(true);
    } else {
      ScrollToModalTop();
      setInformationModalOpen(true);
      setModalInformation({
        type: 'error',
        informationHeader: 'Ooops! Algo deu errado.',
        errorMsg: 'Verifique se existem informações pendentes ou incorretas.',
      });
    }
  };

  const handlePrevsFormStep = () => {
    const data = formRef?.current?.getData();
    setFormValues({
      ...data,
      competences_specialities: selectedCompetenciesValues,
    });
    setStep((prevsStep) => prevsStep - 1);
  };

  const handleEducationalGroupUniversitiesChange = useCallback((e) => {
    setArrayEducationalGroupUniversities([]);
    e.forEach((value) =>
      setArrayEducationalGroupUniversities((prevsState) => [
        ...prevsState,
        `selected_universities=${value.value || value}&`,
      ])
    );
  });

  const handleMacroChange = useCallback((e) => {
    setArrayMacroAreas([]);
    e.forEach((value) =>
      setArrayMacroAreas((prevsState) => [
        ...prevsState,
        `macroareas=${value.value}&`,
      ])
    );
  });

  const handleCourseChange = (e) => {
    setArrayCourseSearch([]);
    e.forEach((value) =>
      setArrayCourseSearch((prevsState) => [
        ...prevsState,
        `courses=${value.value.replace('c', '')}&`,
      ])
    );
  };

  const setTitle = (trailType) => {
    if (trailType && trailType === 'Content') {
      return (
        <>
          <Img src={Group448} />
          <Title>Conteúdos</Title>
        </>
      );
    }
    return (
      <>
        <Img src={OportunidadesGroup} />
        <Title>Eventos e cursos</Title>
      </>
    );
  };

  useEffect(() => {
    const sendMacroAreas = arrayMacroAreas.join('');
    const sendUniversities = arrayEducationalGroupUniversities.join('');

    if (educationalGroup) {
      if (arrayEducationalGroupUniversities.length) {
        const setCourseRequest = async () => {
          const response = await getCourses(
            sendUniversities,
            sendMacroAreas,
            educationalGroup
          );
          setCourse(response);
        };
        setCourseRequest();
      } else {
        const setCourseRequest = async () => {
          const response = await getCourses(
            _,
            sendMacroAreas,
            educationalGroup
          );
          setCourse(response);
        };
        setCourseRequest();
      }
    }

    if (!educationalGroup) {
      if (arrayMacroAreas.length) {
        const setCourseRequest = async () => {
          const response = await getCourses(_, sendMacroAreas, false);
          setCourse(response);
        };
        setCourseRequest();
      }
    }
  }, [arrayMacroAreas, arrayEducationalGroupUniversities]);

  useEffect(() => {
    if (educationalGroup) {
      const sendMacroAreas = arrayEducationalGroupUniversities.join('');
      const sendUniversities = arrayEducationalGroupUniversities.join('');
      if (arrayEducationalGroupUniversities.length) {
        const setCourseRequest = async () => {
          const response = await OportunitiesMacroAreas(
            sendUniversities,
            educationalGroup,
            sendMacroAreas
          );
          setMacroAreas(response);
        };
        setCourseRequest();
      } else {
        const setCourseRequest = async () => {
          const response = await OportunitiesMacroAreas(
            _,
            educationalGroup,
            sendMacroAreas
          );
          setMacroAreas(response);
        };
        setCourseRequest();
      }
    }
  }, [arrayEducationalGroupUniversities]);

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  useEffect(() => {
    const sendPeriod = arrayCourseSearch.join('');
    if (arrayCourseSearch.length) {
      const sendPeriodsRequest = async () => {
        const response = await getPeriod(sendPeriod, educationalGroup);
        setPeriods([]);
        response.forEach((item) =>
          setPeriods((prevsState) =>
            getUniqueListBy([...prevsState, item], 'label')
          )
        );
      };
      sendPeriodsRequest();
    }
  }, [arrayCourseSearch]);

  const handleFilter = (e) => {
    const targetValue = e.target.value;
    setInputSearch(targetValue);

    const OptionsFilter = competencies.filter((value) => {
      return value.label.toLowerCase().includes(inputSearch.toLowerCase());
    });
    setFilterSearch(getUniqueListBy(OptionsFilter, 'label'));
  };

  useEffect(() => {
    if (inputSearch === '') {
      setFilterSearch([]);
    }
  }, [inputSearch]);

  const handleCompetencie = (value) => {
    setSelectedCompetencies((prevsState) =>
      getUniqueListBy([...prevsState, value], 'value')
    );
  };

  const HandleClickDelet = (e, id) => {
    e.preventDefault();
    setSelectedCompetenciesValues([]);
    const filterArray = selectedCompetencies.filter(
      (compet) => compet.value !== id
    );
    setSelectedCompetencies(filterArray);
  };

  useEffect(() => {
    if (selectedCompetencies.length >= 5) {
      setFilterSearch([]);
      setDisabledInput(true);
      setInputSearch('');
    } else {
      setDisabledInput(false);
    }
  }, [selectedCompetencies]);

  useEffect(() => {
    selectedCompetencies.forEach((value) =>
      setSelectedCompetenciesValues((prevsState) => [
        ...prevsState,
        value.value,
      ])
    );
  }, [selectedCompetencies]);

  const populateNewSelection = () => {
    const newCurrentArrayPopulated = selectedCompetencies.reduce(
      (accumulator, item) => {
        accumulator[item.label] = item.value;
        return accumulator;
      },
      {}
    );

    const filterArray = filterSearch.filter((item) => {
      return !newCurrentArrayPopulated[item.label];
    });

    return filterArray.map((item) => (
      <DivCompetencies key={item.value} onClick={() => handleCompetencie(item)}>
        {item.label}
      </DivCompetencies>
    ));
  };

  useEffect(() => {
    if (formData.competences_specialities) {
      setSelectedCompetencies(null);
      const arrayUnique = [...new Set(formData.competences_specialities)];

      let selectedCompetenciesData = arrayUnique.map((item) => {
        return competencies.find(
          (option) => option.value === item || option.value === item.value
        );
      });

      setSelectedCompetencies(selectedCompetenciesData);
    }
  }, [formData]);

  useEffect(() => {
    if (formData && educationalGroup) {
      if (formData.universities) {
        formRef.current.setFieldValue('universities', formData.universities);
      }

      if (formData.target_audiences) {
        formRef.current.setFieldValue(
          'macro_areas',
          formData.target_audiences[0].macro_areas
        );
        formRef.current.setFieldValue(
          'courses',
          formData.target_audiences[0].courses
        );
        formRef.current.setFieldValue(
          'periods',
          formData.target_audiences[0].periods
        );
      }
    }
  }, [formData, educationalGroup]);

  return (
    <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
      <MultiStepContainer>
        <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
      </MultiStepContainer>
      <div ref={topModal} />
      <Wrapper>
        <InformationModal
          isOpen={informationModalOpen}
          setModalOpen={setInformationModalOpen}
          type={modalInformation.type}
          informationHeader={modalInformation.informationHeader}
          errorMsg={modalInformation.errorMsg}
          finish={modalInformation.finish}
          setActiveTab={modalInformation.setActiveTab}
          setModalOpenAtraction={modalInformation.setModalOpen}
        />
        <DescImg>{setTitle(type)}</DescImg>
        <Abount>
          <AboutTitle>Público-alvo</AboutTitle>
        </Abount>
      </Wrapper>
      <div style={{ marginBottom: '1.5em' }}>
        {educationalGroup && (
          <SearchableSelectUn
            name="universities"
            options={educationalGroupUniversities}
            isMulti
            placeholder={'Todos'}
            onChange={handleEducationalGroupUniversitiesChange}
            label="Instituições de ensino participantes:"
          />
        )}
      </div>
      <div style={{ marginBottom: '1.5em' }}>
        <SearchableSelectUn
          name="macro_areas"
          options={macroAreas}
          isMulti
          placeholder={'Todos'}
          onChange={handleMacroChange}
          label="Áreas de atuação relacionadas:"
        />
      </div>
      <div style={{ marginBottom: '1.5em' }}>
        <SearchableSelectUn
          name="courses"
          options={course}
          placeholder={'Todos'}
          isMulti
          onChange={handleCourseChange}
          label="Selecione os cursos relacionados:"
        />
      </div>
      <div style={{ marginBottom: '1.5em' }}>
        <SearchableSelectUn
          name="semesters"
          options={periods}
          isMulti
          placeholder={'Todos'}
          label="Período:"
        />
      </div>

      <div style={{ marginBottom: '1.5em' }}>
        <SearchableSelectUn
          name="professional_offers"
          options={interests}
          isClearable
          placeholder={'Todos'}
          label="Objetivo de Carreira:"
        />
      </div>
      <hr />
      <Wrapper>
        <DescImg>
          <AboutTitle>
            Competências <br />{' '}
            <SubTitle style={{ marginTop: '2px' }}>
              Selecione até
              <strong> 5 competências/especialidades </strong>
              adquiridas ao consumir esse conteúdo
            </SubTitle>
          </AboutTitle>
        </DescImg>

        <CompetenciesContainer>
          <div
            style={{
              display: 'flex',
              maxHeight: '350px',
              maxWidth: '500px',
              flexWrap: 'wrap',
            }}
          >
            {selectedCompetencies.map((value, index) => (
              <div>
                {value && value.label && (
                  <SelectedCompetencies key={index}>
                    {value.label}
                    <ButtonX
                      onClick={(e) => HandleClickDelet(e, value.value)}
                    />
                  </SelectedCompetencies>
                )}
              </div>
            ))}
          </div>

          <Input
            name="test"
            disabled={disabledInput}
            value={inputSearch}
            placeholder="Digite aqui..."
            onChange={handleFilter}
          />

          {filterSearch.length !== 0 && (
            <WrapperCompetencies>{populateNewSelection()}</WrapperCompetencies>
          )}
        </CompetenciesContainer>
      </Wrapper>
      <FooterForm
        haveFinish={true}
        haveBack={true}
        haveNext={false}
        prevsState={handlePrevsFormStep}
        disabledButton={disableButton}
      />
    </Form>
  );
}

export default memo(StepTree);
