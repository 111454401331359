import React, { Fragment, useState } from 'react';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';
import {
  CardBodySubTitle,
  CardBodyTitle,
  ContainerIcon,
  IconImg,
} from '../styled';
import { DotsPagination } from '../../PaginationDots';

const UtilsAcademic = ({ metadata, academicProfile }) => {
  const { IconCourse, IconTypeCourse, IconStatusCourse, IconInstitution } =
    wrapperIconsProfile();
  const itemsPerPage = 1;

  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastCourse = currentPage * itemsPerPage;
  const indexOfFirstCourse = indexOfLastCourse - itemsPerPage;
  const currentCourses = academicProfile?.slice(
    indexOfFirstCourse,
    indexOfLastCourse
  );

  const changePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <Fragment>
      {currentCourses.map((course, index) => (
        <div>
          <ContainerIcon>
            <IconImg>
              <IconCourse />
            </IconImg>
            <CardBodyTitle>Curso</CardBodyTitle>
          </ContainerIcon>
          <CardBodySubTitle>
            <CardBodySubTitle capitalize>{course.name}</CardBodySubTitle>
          </CardBodySubTitle>

          <ContainerIcon>
            <IconImg>
              <IconTypeCourse />
            </IconImg>
            <CardBodyTitle>Tipo</CardBodyTitle>
          </ContainerIcon>
          <CardBodySubTitle>
            <CardBodySubTitle capitalize>
              {course.degree_level} | {course.degree_type}
            </CardBodySubTitle>
          </CardBodySubTitle>

          <ContainerIcon>
            <IconImg>
              <IconStatusCourse />
            </IconImg>
            <CardBodyTitle>Status</CardBodyTitle>
          </ContainerIcon>
          <CardBodySubTitle>
            <CardBodySubTitle capitalize>
              {course.course_status} | {course.course_semester}
            </CardBodySubTitle>
          </CardBodySubTitle>

          <ContainerIcon>
            <IconImg>
              <IconInstitution />
            </IconImg>
            <CardBodyTitle>Instituição</CardBodyTitle>
          </ContainerIcon>
          <CardBodySubTitle>
            <CardBodySubTitle capitalize>
              {metadata.university}
            </CardBodySubTitle>
          </CardBodySubTitle>
        </div>
      ))}
      {academicProfile?.length > 1 && (
        <DotsPagination
          totalCourses={academicProfile?.length}
          currentPage={currentPage}
          onChangePage={changePage}
        />
      )}
    </Fragment>
  );
};

export default UtilsAcademic;
