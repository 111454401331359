import React, { useCallback, useMemo, useState, useEffect } from 'react';
import Pagination from 'components/CustomPagination';
import JobHistoryCards from '../../../../components/HistoryCards';
import SkeletonJobsCard from '../../../../components/JobsSkeleton';
import FeedBackModal from 'views/StaffPages/jobOpeningsRebuild/components/HistoryCards/FeedBackModal';

import { Container, Content, Title } from './styled';
import GenericMessage from 'components/GenericMessage';

export default function RegisteredByPartners({
  role,
  currentJobFilter,
  activeTab,
  ScrollToDiv,
  jobsCountPartners,
  currentPagePartners,
  setCurrentPagePartners,
  limitPartners,
  setLimitPartners,
  onPageChangePartners,
  jobsPartners,
  setJobsPartners,
  loadJobsPartners,
  setJobsCountPartners,
}) {
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const [feedback, setFeedback] = useState();
  const [modalFeedbackOpen, setModalFeedbackOpen] = useState(false);

  const skeletonCardsQuantity = useMemo(() => {
    return randomIntFromInterval(1, 4);
  }, []);

  const changeModalFeedbackState = useCallback(() => {
    setModalFeedbackOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setModalFeedbackOpen(false);
  }, []);

  useEffect(() => {
    setJobsPartners(null);
    loadJobsPartners(
      `limit=${limitPartners}&offset=${
        (currentPagePartners - 1) * limitPartners || 0
      }`
    );
  }, [activeTab, currentJobFilter, currentPagePartners, limitPartners]);

  useEffect(() => {
    setCurrentPagePartners(1);
  }, [limitPartners, currentJobFilter, activeTab]);

  useEffect(() => {
    ScrollToDiv();
  }, [currentPagePartners]);

  const renderItems = () => {
    if (jobsPartners === null) {
      return (
        <Container>
          <Content>
            <SkeletonJobsCard quantity={skeletonCardsQuantity} />
          </Content>
        </Container>
      );
    }

    if (jobsPartners?.length === 0) {
      return (
        <Container>
          <GenericMessage title="No momento, não há nenhuma vaga disponível." />
        </Container>
      );
    }

    if (jobsPartners?.length > 0) {
      return (
        <Container>
          <Content>
            {jobsPartners.map((jobs) => (
              <JobHistoryCards
                jobName={jobs.name}
                openedSince={jobs.opened_since}
                responsibleName={jobs.responsible_name}
                numberOfCandidates={jobs.number_of_candidates}
                status={jobs.status}
                jobID={jobs.id}
                jobs={jobs}
                setFeedback={setFeedback}
                changeModalFeedbackState={changeModalFeedbackState}
                role={role}
                universityFilters={currentJobFilter}
                setJobsCountPartners={setJobsCountPartners}
              />
            ))}
            <FeedBackModal
              limit={limitPartners}
              currentPage={currentPagePartners}
              feedback={feedback}
              isOpen={modalFeedbackOpen}
              closeModal={handleCloseModal}
              loadJobsData={loadJobsPartners}
            />
          </Content>
          <Pagination
            className="pagination-bar"
            currentPage={currentPagePartners}
            totalCount={jobsCountPartners}
            limit={limitPartners}
            onPageChange={onPageChangePartners}
            setLimit={setLimitPartners}
            setCurrentPage={setCurrentPagePartners}
          />
        </Container>
      );
    }
  };

  return <div>{renderItems()}</div>;
}
