import styled from 'styled-components';

export const PermissionsContainer = styled.div`
  padding: 0;
  .flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    span {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 22px;
      margin-left: 0.5rem;
    }
    .checkBoxContainer {
      width: 18px;
      padding-top: 3px;
      input[type='checkbox'] {
        width: 18px;
        height: 18px;
        margin: 0px;
      }
      input[type='checkbox']:checked {
        background-color: ${(props) => props.universityColor} !important;
      }
    }
  }
`;
