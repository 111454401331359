import MarketItem from '../MarketItem';
import {
  ChildrenContainer,
  CircularProgressWrapper,
  HeaderInformation,
} from '../style';
import AcademicModelingApi from 'services/university/academicModeling';
import { useAcademicModeling } from '../../../../contexts/AcademicModelingContext';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CircularProgress } from '../CircularProgress';
import { Divider, SeeMoreButton } from '../../../../style';
import MarketItemSkeleton from '../MarketItem/MarketItemSkeleton';
import { ContainerSearch, SearchInput } from '../../StepOne/style';
import { useDebounce } from '../../../../hooks/useDebounce';

export function Contemplated({ isTendencies }) {
  const {
    selectedCourse,
    marketRequirementsMetrics,
    marketTendenciesMetrics,
  } = useAcademicModeling();

  const [hasNextPage, setHasNextPage] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);

  const metrics = useMemo(() => {
    if (isTendencies) {
      return marketTendenciesMetrics;
    }
    return marketRequirementsMetrics;
  }, [isTendencies, marketRequirementsMetrics, marketTendenciesMetrics]);

  const [contemplated, setContemplated] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setContemplated([]);
    const searchQuery = debouncedSearchTerm
      ? `name=${debouncedSearchTerm}&`
      : '';
    const queryParams = `?${searchQuery}limit=20&offset=0`;
    getContemplated(queryParams);
  }, [debouncedSearchTerm]);

  const getContemplated = async (queryParams = '?limit=20&offset=0') => {
    setRequestInProgress(true);
    setPage(1);
    let list;
    let responseHasNextPage;
    if (isTendencies) {
      const {
        results,
        next,
      } = await AcademicModelingApi.getContemplatedMarketTendencies(
        selectedCourse.id,
        queryParams
      );
      responseHasNextPage = !!next;
      list = results;
    } else {
      const {
        results,
        next,
      } = await AcademicModelingApi.getContemplatedMarketRequirements(
        selectedCourse.id,
        queryParams
      );
      responseHasNextPage = !!next;
      list = results;
    }
    setHasNextPage(responseHasNextPage);
    setContemplated(list);
    setRequestInProgress(false);
  };

  const updateContemplated = async (item) => {
    const index = contemplated.findIndex((i) => i.id === item.id);
    const newContemplated = [...contemplated];
    newContemplated[index] = item;
    setContemplated(newContemplated);
  };

  function removeItemFromList(id) {
    const newList = contemplated.filter((item) => item.id !== id);
    setContemplated(newList);
  }

  const mapContemplated = useMemo(
    () =>
      contemplated?.map((item, index) => (
        <Fragment>
          <MarketItem
            marketItem={item}
            updateContemplated={updateContemplated}
            removeItemFromList={removeItemFromList}
            contemplated
          />
          {index !== contemplated.length - 1 && <Divider color={'#EEF2F4'} />}
        </Fragment>
      )),
    [contemplated]
  );

  const handleNextPage = useCallback(async () => {
    setRequestInProgress(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    let responseData;
    const searchQuery = debouncedSearchTerm
      ? `name=${debouncedSearchTerm}&`
      : '';
    if (isTendencies) {
      responseData = await AcademicModelingApi.getContemplatedMarketTendencies(
        selectedCourse.id,
        `?${searchQuery}limit=20&offset=${offset}`
      );
    } else {
      responseData = await AcademicModelingApi.getContemplatedMarketRequirements(
        selectedCourse.id,
        `?${searchQuery}limit=20&offset=${offset}`
      );
    }
    if (responseData) {
      setContemplated([...contemplated, ...responseData.results]);
      setHasNextPage(!!responseData.next);
    }
    setRequestInProgress(false);
  }, [contemplated, selectedCourse, page]);

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (20 - 7 + 1) + 7);
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  return (
    <ChildrenContainer>
      <header>
        <div>
          <span>
            {isTendencies
              ? 'Tendências de mercado contempladas'
              : 'Requisitos de mercado contemplados'}
          </span>
          <p>
            Identificamos que{' '}
            {isTendencies
              ? 'as tendências de mercado apresentados abaixo são contempladas '
              : 'os requisitos de mercado apresentados abaixo são contemplados '}
            pela matriz curricular do seu curso, de acordo com o mapeamento dos
            conteúdos descritos pelas tags.
          </p>
        </div>
        <CircularProgressWrapper>
          <CircularProgress progress={metrics.percent_contemplated} />
        </CircularProgressWrapper>
      </header>
      <ContainerSearch justify={'flex-start'}>
        <SearchInput
          placeholder={`Buscar por ${isTendencies ? 'tendência' : 'requisito'}`}
          onChange={handleSearch}
        />
      </ContainerSearch>
      <HeaderInformation>
        <span className="HeaderOne">
          {isTendencies ? 'TENDÊNCIA' : 'REQUISITO'}
        </span>
        <span className="HeaderTwo">DISCIPLINAS</span>
        <span className="HeaderThree">TAGS</span>
        <span className="HeaderFour">DETALHES</span>
      </HeaderInformation>
      {mapContemplated}
      {requestInProgress &&
        Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
          <Fragment>
            {(contemplated.length > 0 || index > 0) && (
              <Divider color={'#EEF2F4'} />
            )}
            <MarketItemSkeleton key={index} />
          </Fragment>
        ))}
      {hasNextPage && !requestInProgress && (
        <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
      )}
    </ChildrenContainer>
  );
}
