import { authenticatedAPI } from 'services/api';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancelReqCurriculum;

const cancelRequests = () => {
  cancelReqCurriculum && cancelReqCurriculum();
};

const getCurriculumData = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/curriculum-data`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCurriculum = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/docs/curriculum`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelReqCurriculum = c;
      }),
    })
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const updateCurriculum = async (data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/students/docs/curriculum`, data)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const updateVisibleCurriculum = async (data) => {
  const response = authenticatedAPI
    .patch(`/api/internal/students/docs/curriculum/visibility`, data)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

export {
  getCurriculumData,
  getCurriculum,
  updateCurriculum,
  updateVisibleCurriculum,
  cancelRequests,
};
