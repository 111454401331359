import styled from 'styled-components';

export const ListButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #009291;
  cursor: pointer;
  text-decoration: underline;
`;
