export const scrollRigthUpcomingActivities = () => {
  document.getElementById('upcoming-activities').scrollLeft += 290;
};

export const scrollLeftUpcomingActivities = () => {
  document.getElementById('upcoming-activities').scrollLeft += -290;
};

export const scrollRigthAwaitEvidence = () => {
  document.getElementById('await-evidences').scrollLeft += 290;
};

export const scrollLeftAwaitEvidence = () => {
  document.getElementById('await-evidences').scrollLeft += -290;
};

export const scrollRigthNotBeenSelected = () => {
  document.getElementById('not-been-selected').scrollLeft += 290;
};

export const scrollLeftNotBeenSelected = () => {
  document.getElementById('not-been-selected').scrollLeft += -290;
};

export const scrollRigthClosedProjects = () => {
  document.getElementById('closed-projects').scrollLeft += 290;
};

export const scrollLeftClosedProjects = () => {
  document.getElementById('closed-projects').scrollLeft += -290;
};
