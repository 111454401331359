import React, { useCallback, useContext, useEffect, useState } from 'react';
import CardSelectCourse from './components/cardSelectCourse/cardSelectCourse';
import { MatrixContext } from './contexts/MatrixContext';
import { WrapperMatrix } from './style';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withScrolling from 'react-dnd-scrolling';

import {
  getCoursesOptions,
  getMatricesCourse,
  getSubjectsCourse,
  postCreateMatrix,
} from './services/matrixServices';
import MatrixController from './components/matrixController';
import DragLayerItem from './components/activeMatrices/dragLayer';

export default function CurriculumMatrix(props) {
  const {
    setCourseOptions,
    selectedCourse,
    setSelectedCourse,
    setSubjectsCourse,
    setMatricesCourse,
    getDisabledMatrices,
    setAllDisciplinesCourse,
    setTotalMatrices,
    getCourseSubjects,
    resetFilters,
    setLoading,
    clearData,
  } = useContext(MatrixContext);

  const [totalSubjects, setTotalSubjects] = useState(0);

  useEffect(() => {
    const getOptionsCourse = async () => {
      const response = await getCoursesOptions();
      const { courses } = response;
      setCourseOptions(courses);
    };
    getOptionsCourse();
  }, []);

  useEffect(() => {
    clearData();
  }, [props.activeTab]);

  const getMatrices = async (id, query) => {
    const { results, count } = await getMatricesCourse(id, query);
    setMatricesCourse(results);
    setTotalMatrices(count);
  };

  const handleSelectCourse = useCallback(async (valueSearch) => {
    setLoading(true);
    const { value } = valueSearch;
    const { results, count } = await getCourseSubjects(value);
    await getMatrices(value);
    await getDisabledMatrices(value);
    setTotalSubjects(count);
    setSubjectsCourse(results);
    setSelectedCourse(value);
    resetFilters();

    const allDisciplnes = await getSubjectsCourse(value);

    setAllDisciplinesCourse(allDisciplnes);
    setLoading(false);
  }, []);

  const duplicatedProps = {
    handleSelectCourse,
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <DragLayerItem />
      <WrapperMatrix>
        {!selectedCourse ? (
          <CardSelectCourse {...duplicatedProps} />
        ) : (
          <MatrixController
            {...props}
            {...duplicatedProps}
            totalSubjects={totalSubjects}
          />
        )}
      </WrapperMatrix>
    </DndProvider>
  );
}
