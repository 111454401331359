import { SelectCategoryContainer, Wrapper } from './style';
import { defaultIndexCategories } from 'views/StaffPages/Csm/resources/defaultIndexCategories';
import CardSelect from '../CardSelect';

export default function HomeCategories({ setSelectedHomeCategory }) {
  const mapOptionsCard = defaultIndexCategories.map((category) => (
    <CardSelect
      category={category}
      setSelectedHomeCategory={setSelectedHomeCategory}
    />
  ));

  return (
    <Wrapper>
      <span>O que você deseja fazer?</span>
      <SelectCategoryContainer>{mapOptionsCard}</SelectCategoryContainer>
    </Wrapper>
  );
}
