import React, { useEffect, useRef } from 'react';
import { ModalX, RModalBackground, RModalContainer } from './styles';

export default function ModalGenericChallenges({ isOpen, children, setModalOpen }) {
  const setState = () => {
    setModalOpen(false);
  };

  const topModal = useRef()

  useEffect(() => {
    if (isOpen) {
      topModal.current.scrollIntoView();
    }
  }, [isOpen])

  return (
    <RModalBackground ref={topModal} isOpen={isOpen}>
      <RModalContainer>
        <div className="content-wrapper">
          <ModalX onClick={setState} />
        </div>
        {children}
      </RModalContainer>
    </RModalBackground>
  );
}
