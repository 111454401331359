import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Card = styled.div`
  width: 100%;
  max-width: 255px;
  height: 270px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 16px;

  .container-subtitle {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 13px;
  }

  .container-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
  }
`;

export const CardTitle = styled.div`
  width: 136px;
  height: 34px;
  background-color: #e5e5e5;
  margin-bottom: 20px;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  border-radius: 4px;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardImage = styled.div`
  width: 21px;
  height: 22px;
  margin-right: 7px;
  border-radius: 3px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardSubTitles = styled.div`
  width: ${(props) => props.size};

  height: 22px;
  border-radius: 4px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardButton = styled.div`
  width: ${(props) => props.size};
  height: 27px;
  border-radius: 3px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;
export const SimpleBar = styled.div`
  height: 1.8px;
  width: 100%;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;
