export function PersonalInfo({ color = '#009291', size = 18 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1667 1.16602H5.83333C2.61683 1.16602 0 3.78285 0 6.99935V20.9994C0 24.2159 2.61683 26.8327 5.83333 26.8327H22.1667C25.3832 26.8327 28 24.2159 28 20.9994V6.99935C28 3.78285 25.3832 1.16602 22.1667 1.16602ZM25.6667 20.9994C25.6667 22.929 24.0963 24.4994 22.1667 24.4994H5.83333C3.90367 24.4994 2.33333 22.929 2.33333 20.9994V6.99935C2.33333 5.06968 3.90367 3.49935 5.83333 3.49935H22.1667C24.0963 3.49935 25.6667 5.06968 25.6667 6.99935V20.9994ZM22.1667 8.16602C22.1667 8.81002 21.644 9.33268 21 9.33268H12.8333C12.1893 9.33268 11.6667 8.81002 11.6667 8.16602C11.6667 7.52202 12.1893 6.99935 12.8333 6.99935H21C21.644 6.99935 22.1667 7.52202 22.1667 8.16602ZM9.33333 8.16602C9.33333 9.13202 8.54933 9.91602 7.58333 9.91602C6.61733 9.91602 5.83333 9.13202 5.83333 8.16602C5.83333 7.20002 6.61733 6.41602 7.58333 6.41602C8.54933 6.41602 9.33333 7.20002 9.33333 8.16602ZM22.1667 13.9993C22.1667 14.6433 21.644 15.166 21 15.166H12.8333C12.1893 15.166 11.6667 14.6433 11.6667 13.9993C11.6667 13.3553 12.1893 12.8327 12.8333 12.8327H21C21.644 12.8327 22.1667 13.3553 22.1667 13.9993ZM9.33333 13.9993C9.33333 14.9653 8.54933 15.7493 7.58333 15.7493C6.61733 15.7493 5.83333 14.9653 5.83333 13.9993C5.83333 13.0333 6.61733 12.2493 7.58333 12.2493C8.54933 12.2493 9.33333 13.0333 9.33333 13.9993ZM22.1667 19.8327C22.1667 20.4767 21.644 20.9994 21 20.9994H12.8333C12.1893 20.9994 11.6667 20.4767 11.6667 19.8327C11.6667 19.1887 12.1893 18.666 12.8333 18.666H21C21.644 18.666 22.1667 19.1887 22.1667 19.8327ZM9.33333 19.8327C9.33333 20.7987 8.54933 21.5827 7.58333 21.5827C6.61733 21.5827 5.83333 20.7987 5.83333 19.8327C5.83333 18.8667 6.61733 18.0827 7.58333 18.0827C8.54933 18.0827 9.33333 18.8667 9.33333 19.8327Z"
        fill={color}
      />
    </svg>
  );
}
