import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Footer from 'components/Footer/Footer';

import professionalStatusStyle from 'assets/jss/material-dashboard-pro-react/layouts/professionalStatusStyle';

export function ProfessionalStatus({
  classes,
  childComponent,
  academic_profile,
  metadata,
  globalState,
}) {
  const [pageTitle, setPageTile] = useState('');
  const wrapper = useRef(null);

  useEffect(() => {
    document.body.style.overflow = 'unset';

    let newPageTitle = 'Workalove';

    if (metadata.role === 'student') {
      newPageTitle = `${metadata.university} | Workalove | Estudante`;
    } else if (metadata.role === 'university') {
      newPageTitle = `${metadata.university} | Workalove | IE`;
    } else if (metadata.role === 'company') {
      newPageTitle = `${metadata.company_name} | Workalove | Empresa`;
    }

    setPageTile(newPageTitle);
  }, []);

  const ChildComponent = childComponent({
    academicProfile: academic_profile,
    metadata,
    globalState,
  });

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={classes.wrapper} ref={wrapper}>
        {ChildComponent}
      </div>
      <Footer white />
    </div>
  );
}

ProfessionalStatus.propTypes = {
  classes: PropTypes.shape.isRequired,
  childComponent: PropTypes.func.isRequired,
  academic_profile: PropTypes.shape.isRequired,
  metadata: PropTypes.shape.isRequired,
  globalState: PropTypes.shape.isRequired,
};

export default withStyles(professionalStatusStyle)(ProfessionalStatus);
