import React, { useContext, useEffect } from 'react';

import { Filter, ContainerFilters } from './styled';
import { VisualizationHandler } from '../../Handlers/VisualizationHandler';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import BaseLayoutContext from 'contexts/base-layout';

function InsideFilter({ optionsConfig = [] }) {
  const visualizationContextInstance = useVisualizationContext();

  const {
    getFavoritedAvaibles,
    typeFinish,
    counts,
  } = visualizationContextInstance;

  const { filters } = counts || {};

  const { universityColor } = useContext(BaseLayoutContext);

  const options = optionsConfig?.map((config) => {
    return {
      ...config,
      label: `${config.label}`,
    };
  });

  const createFilters = options.map((option) => (
    <Filter
      key={option.value}
      borderLeftRadius={option.borderLeftRadius}
      color={universityColor}
      active={
        String(option.value) === String(getFavoritedAvaibles) ||
        String(option.value) === String(typeFinish)
      }
      onClick={() => option.onClick(option.value)}
      style={option.style}
    >
      {option.label}
    </Filter>
  ));

  return <ContainerFilters>{createFilters}</ContainerFilters>;
}

export default InsideFilter;
