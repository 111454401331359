export default function EditIcon({ color = '#009291', size = '17', ...rest }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.3035 0.155336C15.1413 0.103557 14.925 0.0517785 14.7627 0H14.2761C14.2761 0 14.2761 0 14.222 0C13.6812 0.0517785 13.2486 0.310671 12.8701 0.724898C10.4367 3.26204 8.00327 5.85097 5.56986 8.38811C5.46171 8.49167 5.40763 8.59523 5.35356 8.75056C5.08318 9.57902 4.8128 10.3557 4.48834 11.1841C4.38019 11.5466 4.38019 11.8573 4.65057 12.1679C4.97503 12.5304 5.40763 12.5822 5.84024 12.4268C6.65138 12.1162 7.40844 11.7537 8.21957 11.443C8.3818 11.3913 8.54403 11.2359 8.70626 11.1324C10.2745 9.52724 11.8427 7.87033 13.3568 6.2652C14.222 5.33318 15.1413 4.40117 16.0065 3.46916C17.0339 2.33003 16.6554 0.67312 15.3035 0.155336ZM6.16469 10.7699C6.32692 10.2521 6.54322 9.73435 6.70545 9.21657C7.02991 9.52724 7.35436 9.78613 7.73289 10.0968C7.19213 10.3557 6.70545 10.5628 6.16469 10.7699ZM15.0331 2.43359C14.8709 2.6407 14.6546 2.84782 14.4383 3.05493C12.5456 5.02251 10.653 6.99009 8.81441 8.95767C8.76033 9.00945 8.70626 9.06123 8.70626 9.11301C8.3818 8.80234 8.05735 8.54345 7.73289 8.23278C8.32773 7.61143 8.92256 6.99009 9.51739 6.36875C10.9774 4.8154 12.4375 3.31382 13.8975 1.76047C13.9516 1.70869 14.0057 1.60513 14.1138 1.55335C14.3842 1.29446 14.7627 1.29446 15.0331 1.55335C15.3035 1.81225 15.2494 2.1747 15.0331 2.43359Z"
        fill={color}
      />
      <path
        d="M15.1953 12.8415C15.1953 14.0324 14.6545 14.9644 13.573 15.5857C13.0323 15.8964 12.4915 16 11.8967 16H7.57061C6.16464 16 4.70459 16 3.29862 16C1.89265 16 0.865213 15.3786 0.270379 14.1359C0.0540758 13.7217 0 13.2557 0 12.8415C0 10.0972 0 7.35297 0 4.60871C0 3.10714 1.02744 1.86445 2.59564 1.55378C2.86602 1.502 3.1364 1.45023 3.40677 1.45023C5.46165 1.45023 7.46246 1.45023 9.51734 1.45023C10.0581 1.45023 10.3825 1.7609 10.3285 2.17512C10.3285 2.48579 10.0581 2.79647 9.67957 2.79647C9.57141 2.79647 9.51734 2.79647 9.40919 2.79647H3.29862C2.48749 2.79647 1.89265 3.10714 1.5682 3.83203C1.46005 4.09093 1.40597 4.34982 1.40597 4.60871C1.40597 7.35297 1.40597 10.0972 1.40597 12.8415C1.40597 13.8771 2.21711 14.602 3.24455 14.602C6.11056 14.602 9.03066 14.602 11.8967 14.602C12.9782 14.602 13.7352 13.8253 13.7352 12.8415C13.7352 10.9257 13.7352 9.00988 13.7352 7.09408C13.7352 6.73163 13.8975 6.47274 14.2219 6.36918C14.6545 6.21384 15.0871 6.52452 15.1953 6.93874C15.1953 6.99052 15.1953 7.0423 15.1953 7.09408C15.1953 9.06166 15.1953 10.9257 15.1953 12.8415Z"
        fill={color}
      />
    </svg>
  );
}
