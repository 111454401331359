import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;

    background-color: #fff;
    border-radius: 9px;
`

export const ContainerTitleInfos = styled.div`
    padding: 15px 60px;
    border: 2px solid transparent;

    &:hover{
        border: 2px solid #009291;
        border-radius: 6px;
        background-color: rgba(0, 146, 145, 0.09);
    }
`

export const ContainerTexts = styled.div`
    padding: 5px 60px;
`

export const ContainerInfoStudents = styled.div`
    display: flex;
    align-items: ${(props) => (props.subTitle ? 'start' : 'center')};
    flex-direction: ${(props) => (props.subTitle ? 'column' : 'row')};

    justify-content: space-between;

    margin-top: ${(props) => (props.subTitle ? '5px' : '0')};
`

export const ContainerInfos = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    width: 300px;
    justify-content: space-between;
`

export const Title = styled.h1`
    color: #606062;
    font-size: 16px;
    font-weight: bold;
`

export const SubTitleInfos = styled.h1`
    color: #6f6f72;
    font-size: 14px;
    font-weight: 450;
    display: flex;
    justify-content: flex-start;
`

export const SubTitle = styled.h3`
    color: #606062;
    font-size: 13px;
    text-align: start;
    margin-top: ${(props) => (props.subTitle ? '5px' : '16px')};

    width: ${(props) => props.isWidth ? '420px' : 'auto'};

    margin-left: ${(props) => props.isProgres ? '56px' :  '0'};
    white-space: nowrap;
`

export const TitleInfos = styled.h3`
    color: #B8B8B8;
    font-size: 14px;
    font-weight: bold;
`

export const Divider = styled.div`
    width: 100%;
    height: 2px;

    background-color: #EDEDED;
`

export const ContainerJob = styled.div`
    display: flex;
    margin-bottom: 15px;
    justify-content: ${(props) => props.isStudent ? 'space-between' : ''};
`


export const ContainerSubTitles = styled.div`
    display: flex;
    flex-direction: row;`