import { Form } from '@unform/web';
import React, { useContext, useEffect, useState } from 'react';
import SearchableSelectUn from '../../Form/searchableSelect';
import Input from '../../../../StaffPages/Opportunities/Components/Form/input';
import {
  jobBenefits,
  jobType,
  modalityType,
  shiftOptions,
} from './useCases/SelectOptions';
import InputDesc from '../../../../StaffPages/Opportunities/Components/Form/inputDesc';
import states from 'variables/states';
import Header from '../../Header';
import {
  CheckboxWrapper,
  ContainerCity,
  ContainerSpecificsUniversities,
  FooterForm,
  InputDescNewJobText,
} from './style';
import { getCities, getUniversities } from '../../../services';
import SearchableSelectCitiesUn from '../../Form/searchableSelectCities';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';

import * as Yup from 'yup';
import { getSchemaValidationStepTwo } from './useCases/SchemaValidation';
import {
  NextButton,
  PrevsButton,
} from '../../../../StaffPages/Opportunities/Styles/formStyled';

export default function StepTwoJobOffer() {
  const {
    nextJobOfferStep,
    setFormValues,
    data,
    prevsJobOfferStep,
    setCacheContext,
    stepTwoRef,
  } = useContext(JobOfferContext);

  const [selectedShift, setSelectedShift] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState([]);

  const [cities, setCities] = useState([]);
  const [isSearchingCities, setIsSearchingCities] = useState(false);
  const [showUf, setShowUf] = useState(false);
  const [justForSomeUniversities, setJustForSomeUniversities] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [firstLoadUniversity, setFirstLoadUniversity] = useState(true);
  const [isFetchingUniversities, setIsFetchingUniversities] = useState(false);

  useEffect(() => {
    if (data?.application_type && data.application_type.length > 0) {
      setSelectedApplication(data.application_type);
      stepTwoRef.current.setFieldValue(
        'application_type',
        data.application_type
      );
    }
  }, [data]);

  const isShiftSelected = (selectedOptions, shift) => {
    return selectedOptions.some((option) => option?.value === shift);
  };

  const handleShiftSelect = (selectedOption) => {
    setSelectedShift(selectedOption);
    const indifferentSelected = isShiftSelected(selectedOption, 'indifferent');
    const morningSelected = isShiftSelected(selectedOption, 'morning');
    const afternoonSelected = isShiftSelected(selectedOption, 'afternoon');
    const nightSelected = isShiftSelected(selectedOption, 'night');

    let shifts;

    if (
      indifferentSelected &&
      (morningSelected || afternoonSelected || nightSelected)
    ) {
      shifts = [{ label: 'Indiferente', value: 'indifferent' }];
    } else {
      if (morningSelected && afternoonSelected && nightSelected) {
        shifts = [{ label: 'Indiferente', value: 'indifferent' }];
      } else {
        shifts = selectedOption;
      }
    }

    const indifferentShift = shifts.find(
      (shift) => shift.value === 'indifferent'
    );
    if (indifferentShift) {
      shifts = [indifferentShift];
    }

    setSelectedShift(shifts);
    stepTwoRef.current.setFieldValue('shift', shifts);
  };

  useEffect(() => {
    if (data?.shift) {
      let shifts = data.shift
        .map((shiftValue) => {
          const shiftOption = shiftOptions.find(
            (shift) =>
              shift.value === shiftValue.value || shift.value === shiftValue
          );
          return shiftOption
            ? { label: shiftOption.label, value: shiftOption.value }
            : null;
        })
        .filter(Boolean);

      const indifferentSelected = isShiftSelected(shifts, 'indifferent');
      const morningSelected = isShiftSelected(shifts, 'morning');
      const afternoonSelected = isShiftSelected(shifts, 'afternoon');
      const nightSelected = isShiftSelected(shifts, 'night');

      if (
        indifferentSelected &&
        (morningSelected || afternoonSelected || nightSelected)
      ) {
        shifts = [{ label: 'Indiferente', value: 'indifferent' }];
      } else {
        if (morningSelected && afternoonSelected && nightSelected) {
          shifts = [{ label: 'Indiferente', value: 'indifferent' }];
        }
      }

      const indifferentShift = shifts.find(
        (shift) => shift.value === 'indifferent'
      );
      if (indifferentShift) {
        shifts = [indifferentShift];
      }

      setSelectedShift(shifts);
      stepTwoRef.current.setFieldValue('shift', shifts);
    }
  }, [data]);

  useEffect(() => {
    if (selectedApplication.length > 1) {
      const lastOption = selectedApplication[selectedApplication.length - 1];
      setSelectedApplication([lastOption]);
      stepTwoRef.current.setFieldValue('application_type', [lastOption]);
    }
  }, [selectedApplication]);

  const handleApplicationTypeSelect = (options) => {
    setSelectedApplication(options);
  };

  const getCurrentCities = async (arrayUfs) => {
    setIsSearchingCities(true);
    const response = await getCities(arrayUfs);
    setCities(response);
    setCacheContext(response);
    setIsSearchingCities(false);
  };

  const handleSelectUf = (e) => {
    const currentUfs = e.map((uf) => uf.value).join('|');
    getCurrentCities(currentUfs);
    setFormValues({ locations: null });
    stepTwoRef.current.setFieldValue('locations', null);
  };

  const handleSelectModality = (e) => {
    const value = e.value;
    if (value === 'hybrid' || value === 'presential') {
      setShowUf(true);
    }
    if (value === 'remote') {
      setFormValues({ uf: null, locations: null });
      stepTwoRef.current.setFieldValue('uf', null);
      stepTwoRef.current.setFieldValue('locations', null);
      setShowUf(false);
    }
  };

  const mapIfDataUf = (ufs) => {
    const currentUfs = ufs.map((uf) => uf).join('|');
    getCurrentCities(currentUfs);
  };

  async function fetchUniversities(courses) {
    setIsFetchingUniversities(true);
    let query = '';
    if (courses && courses.length > 0) {
      query = `courses=${courses.join('&courses=')}`;
    }
    const response = await getUniversities(query);
    if (response?.status === 200) {
      setUniversities(response.data);
    }
    setIsFetchingUniversities(false);
  }

  useEffect(() => {
    if (justForSomeUniversities) {
      const courses = data.courses.map((course) => course.value);
      fetchUniversities(courses);
    }
  }, [justForSomeUniversities]);

  useEffect(() => {
    if (data.uf) {
      setShowUf(true);
      if (
        JSON.stringify(data.uf) !==
        JSON.stringify(cities.map((city) => city.uf))
      ) {
        mapIfDataUf(data.uf);
      }
    } else {
      setCities([]);
    }
    if (
      data.enabled_just_for_universities &&
      data.enabled_just_for_universities?.length > 0 &&
      firstLoadUniversity
    ) {
      setJustForSomeUniversities(true);
    }
  }, [data]);

  function handleShift(data) {
    const getShiftOptions = (shifts) =>
      shifts
        .map((item) => shiftOptions.find((option) => option?.value === item))
        .filter(Boolean);

    const verifyData = getShiftOptions(data?.shift || []);

    const isAllShiftsSelected = (shifts) => {
      const values = shifts?.map((option) => option?.value);
      return (
        values?.includes('morning') &&
        values?.includes('afternoon') &&
        values?.includes('night')
      );
    };

    if (isAllShiftsSelected(verifyData)) {
      stepTwoRef.current.setFieldValue('shift', [
        { value: 'indifferent', label: 'Indiferente' },
      ]);
    } else {
      stepTwoRef.current.setFieldValue('shift', verifyData);
    }

    if (verifyData?.length === 0) {
      const originalShiftOptions = getShiftOptions(data?.shift || []);

      if (isAllShiftsSelected(originalShiftOptions)) {
        stepTwoRef.current.setFieldValue('shift', [
          { value: 'indifferent', label: 'Indiferente' },
        ]);
      } else {
        stepTwoRef.current.setFieldValue('shift', data?.shift);
      }
    }
  }

  useEffect(() => {
    handleShift(data);
  }, []);

  const handleSubmit = async (data) => {
    stepTwoRef.current.setErrors({});

    let newData = data;

    if (data.shift.includes('indifferent')) {
      delete data.shift;
      newData = {
        ...data,
        shift: ['indifferent'],
      };
    }

    try {
      const schema = getSchemaValidationStepTwo({
        hasUf: showUf,
        hasUniversities: justForSomeUniversities,
      });
      await schema.validate(newData, {
        abortEarly: false,
      });
      setFormValues(newData);
      nextJobOfferStep();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepTwoRef.current.setErrors(validationErrors);
      }
    }
  };

  function toggleForSpecificsUniversities() {
    setJustForSomeUniversities(!justForSomeUniversities);
    if (justForSomeUniversities) {
      data.enabled_just_for_universities = null;
    }
  }

  useEffect(() => {
    if (
      data &&
      data.enabled_just_for_universities &&
      universities.length > 0 &&
      firstLoadUniversity
    ) {
      if (data.enabled_just_for_universities.length > 0) {
        if (justForSomeUniversities) {
          let newSelectedUniversities;
          if (typeof data.enabled_just_for_universities[0] === 'number') {
            newSelectedUniversities = data.enabled_just_for_universities.map(
              (university) => {
                return {
                  value: university,
                  label: universities.find((uni) => uni.value === university)
                    .label,
                };
              }
            );
          } else {
            newSelectedUniversities = data.enabled_just_for_universities;
          }
          stepTwoRef.current.setFieldValue(
            'enabled_just_for_universities',
            newSelectedUniversities
          );
          setFirstLoadUniversity(false);
        }
      } else {
        setFirstLoadUniversity(false);
      }
    }
  }, [data, firstLoadUniversity, justForSomeUniversities, universities]);

  return (
    <div>
      <Form ref={stepTwoRef} onSubmit={handleSubmit} initialData={data}>
        <Header
          header="Tipo de Vaga*"
          subTitle="Selecione qual tipo de sua vaga."
        />

        <SearchableSelectUn
          name="application_type"
          options={jobType}
          isMulti
          value={selectedApplication}
          onChange={handleApplicationTypeSelect}
          isCleareble
        />

        <Header header="Turno*" subTitle="Selecione o turno de sua vaga." />
        <SearchableSelectUn
          name="shift"
          options={shiftOptions}
          placeholder="Selecione"
          value={selectedShift}
          isMulti={true}
          onChange={handleShiftSelect}
          isCleareble
        />

        <Header
          header="Quantidade de Vagas Disponíveis"
          subTitle="Indique aqui a quantidade de vagas."
        />
        <Input name="quantity" type="number" min={1} />

        <Header
          header="Benefícios"
          subTitle="Selecione os principais benefícios."
        />
        <SearchableSelectUn
          name="perks_offered"
          options={jobBenefits}
          isMulti
          isCleareble
        />
        <InputDescNewJobText>
          <InputDesc
            name="another_perks"
            label="Detalhe mais sobre seu programa de benefícios"
          />
        </InputDescNewJobText>

        <Header
          header="Modalidade de Trabalho*"
          subTitle="Selecione a modalidade específica para sua vaga."
        />
        <SearchableSelectUn
          name="modality_type"
          options={modalityType}
          onChange={handleSelectModality}
        />

        {showUf && (
          <ContainerCity>
            <SearchableSelectUn
              name="uf"
              options={states}
              label="UF*"
              isClearable
              isMulti
              onChange={handleSelectUf}
            />
            <SearchableSelectCitiesUn
              name="locations"
              options={cities}
              label="Cidade*"
              isCleareble
              getOptionLabel={(options) => options['nome']}
              getOptionValue={(options) => options['id']}
              isMulti
              isDisabled={isSearchingCities}
              isLoading={isSearchingCities}
            />
          </ContainerCity>
        )}
        <CheckboxWrapper>
          <input
            id={'justForSomeUniversities'}
            type={'checkbox'}
            name={'justForSomeUniversities'}
            onChange={toggleForSpecificsUniversities}
            checked={justForSomeUniversities}
          />
          <label htmlFor={'justForSomeUniversities'}>
            Desejo publicar a vaga para alunos de instituições de ensino
            específicas
          </label>
        </CheckboxWrapper>
        {justForSomeUniversities && (
          <ContainerSpecificsUniversities>
            <p>Selecione a(s) instituição(ões) de ensino desejadas.</p>
            <SearchableSelectUn
              name="enabled_just_for_universities"
              options={universities}
              isDisabled={isFetchingUniversities}
              isLoading={isFetchingUniversities}
              isCleareble
              isMulti
            />
          </ContainerSpecificsUniversities>
        )}
        <FooterForm>
          <PrevsButton onClick={prevsJobOfferStep} />
          <NextButton />
        </FooterForm>
      </Form>
    </div>
  );
}
