import React from 'react';
import { ContainerInput, ContainerIcon } from './style';
import { AiOutlineSearch } from 'react-icons/ai';

/**
 * This component displays a text input field with a search icon beside it.
 *
 * @param {string} value - The current value of the input field.
 * @param {function} onChange - Function called when the value of the input field changes.
 * @param {string} universityColor - Color associated with the university for styling.
 * @param {string} placeholder - Placeholder text to display in the input field when it's empty.
 * @returns {JSX.Element} - Returns the JSX representation of the component.
 */

export const GenericInputSearch = ({
  value,
  onChange,
  universityColor,
  placeholder,
}) => {
  return (
    <ContainerInput>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <ContainerIcon universityColor={universityColor}>
        <AiOutlineSearch color={'#ffff'} size={24} />
      </ContainerIcon>
    </ContainerInput>
  );
};
