import React from 'react';
import { shape, func, bool } from 'prop-types';

import { FastField } from 'formik';

import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

const StepFiveForm = (props) => {
  const {
    classes,
    answered,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    metadata,
  } = props;

  return (
    <List className={classes.list}>
      {/* Question 1 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>1</strong> - Em uma frase Sucesso para você é:
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageFiveQuestionOne && touched.pageFiveQuestionOne,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageFiveQuestionOne"
          variant="filled"
          id="pageFiveQuestionOne"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageFiveQuestionOne && touched.pageFiveQuestionOne}
          value={values.pageFiveQuestionOne}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageFiveQuestionOne &&
            `Sua resposta tem ${values.pageFiveQuestionOne.length} caracteres.`}
        </FormHelperText>
        {errors.pageFiveQuestionOne && touched.pageFiveQuestionOne ? (
          <FormHelperText>{errors.pageFiveQuestionOne}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 2 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>2</strong> - O quanto está vivendo sua concepção de sucesso
          hoje?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageFiveQuestionTwo && touched.pageFiveQuestionTwo,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageFiveQuestionTwo"
          variant="filled"
          id="pageFiveQuestionTwo"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageFiveQuestionTwo && touched.pageFiveQuestionTwo}
          value={values.pageFiveQuestionTwo}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageFiveQuestionTwo &&
            `Sua resposta tem ${values.pageFiveQuestionTwo.length} caracteres.`}
        </FormHelperText>
        {errors.pageFiveQuestionTwo && touched.pageFiveQuestionTwo ? (
          <FormHelperText>{errors.pageFiveQuestionTwo}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 3 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>3</strong> - Quais próximos passos precisa dar para ficar mais
          próximo de viver seu ideal de sucesso?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageFiveQuestionThree && touched.pageFiveQuestionThree,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageFiveQuestionThree"
          variant="filled"
          id="pageFiveQuestionThree"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageFiveQuestionThree && touched.pageFiveQuestionThree}
          value={values.pageFiveQuestionThree}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageFiveQuestionThree &&
            `Sua resposta tem ${values.pageFiveQuestionThree.length} caracteres.`}
        </FormHelperText>
        {errors.pageFiveQuestionThree && touched.pageFiveQuestionThree ? (
          <FormHelperText>{errors.pageFiveQuestionThree}</FormHelperText>
        ) : null}
      </ListItem>
    </List>
  );
};

StepFiveForm.propTypes = {
  classes: shape.isRequired,
  answered: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  isSubmitting: bool.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(professionalExperienceFormStyles)(StepFiveForm);
