import styled from "styled-components";

export const InfoModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000000000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(10px);
`;


export const InfoModalContainer = styled.div`
  width: 512px;
  min-height: 321px;
  margin-bottom: 5em;
  overflow: hidden;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5em;
  }

  .content-wrapper h6 {
    margin-top: 3.3em;
  }
`;