import {
  BusinessCenter,
  AccountBalance,
  Domain,
  DonutSmall,
  Settings,
  Event,
  HelpOutline,
  Description,
  CastForEducation,
} from '@material-ui/icons';
import CollegeTalents from 'assets/img/icons/icoCollegeTalents';
import IconChallenge from 'assets/img/icons/icoChallenge';
import BaseLayout from 'layouts/BaseLayout';
import Company from 'views/Company/Company';
import CompanyConfig from 'views/Company/CompanyConfig';
import IntegratorPreRegister from 'views/StaffPages/CompanyPreRegister/IntegratorPreRegister';
import IesInvites from 'views/Company/IesInvites';
import CompanyPrincipalEngine from 'views/JobOfferRebuild/CompanyPrincipalEngine';
import StudentCurriculum from 'views/Company/components/StudentCurriculum/StudentCurriculum';
import Talents from 'views/Company/Talents';
import OpportunitiesCompany from '../views/Company/OpportunitiesCompany';
import HelpMenu from 'views/Company/components/HelpMenu';
import { InternshipRedirect } from '../components/InternshipRedirect';
import { InternshipLogout } from '../components/InternshipLogout';
import ChallengesViews from '../views/Company/ChallengeCompany/index';
import companyRebuildEngine from '../views/CompanyRebuild/companyRebuildEngine';
import EngineIesCompany from 'views/Company/IESCompany/Engine';
import { RiUserSettingsFill } from 'react-icons/ri';

const companyRoutes = (props) => {
  const isIntegrator =
    props.metadata.role === 'company' && props.metadata.create_external_job;
  const commonCompany = props.metadata.role === 'company';
  const isAdmin = props.metadata.company_is_admin;

  let menuRemoveIntegrator = [
    { name: 'Aprendizagem' },
    { name: 'Filtro de Talentos' },
    { name: 'Currículo do Futuro' },
  ];

  let menuRemoveCommon = [
    { name: 'Aprendizagem' },
    { name: 'Filtro de Talentos' },
    { name: 'Currículo do Futuro' },
  ];

  let menuRemoveAdmin = [
    { name: 'Instituições de ensino' },
    { name: 'Currículo do Futuro' },
  ];

  let routes = [
    {
      path: '/',
      name: 'Vagas',
      icon: BusinessCenter,
      baseComponent: BaseLayout,
      component: CompanyPrincipalEngine,
    },
    {
      path: '/desafios',
      name: 'Desafios',
      icon: IconChallenge,
      baseComponent: BaseLayout,
      component: ChallengesViews,
    },
    {
      path: '/instituicoes/conexoes',
      name: 'Instituições de ensino',
      icon: AccountBalance,
      baseComponent: BaseLayout,
      component: EngineIesCompany,
    },
    {
      path: '/aprendizagem',
      name: 'Aprendizagem',
      icon: CastForEducation,
      baseComponent: BaseLayout,
      component: OpportunitiesCompany,
    },
    {
      path: '/instituicoes/talentos',
      name: 'Filtro de Talentos',
      icon: CollegeTalents,
      baseComponent: BaseLayout,
      component: Talents,
    },
    {
      path: '/compartilhar/curriculo/:id',
      name: 'Currículo do Futuro',
      baseComponent: BaseLayout,
      component: StudentCurriculum,
    },
    {
      path: '/estagio',
      name: 'Gestão de Estágio',
      icon: Description,
      baseComponent: BaseLayout,
      component: InternshipRedirect,
      premiumTag: true,
    },
    {
      path: '/estagio/logout',
      baseComponent: BaseLayout,
      hide: true,
      component: InternshipLogout,
    },
    {
      path: '/empresas/meu-perfil',
      name: 'Meu perfil',
      icon: RiUserSettingsFill,
      baseComponent: BaseLayout,
      component: CompanyConfig,
    },
    {
      path: '/empresas/ajuda',
      name: 'Central de Ajuda',
      icon: HelpOutline,
      baseComponent: BaseLayout,
      component: HelpMenu,
    },
    {
      redirect: true,
      path: '*',
      pathTo: '/',
    },
  ];

  /*
    Return treatment of menus using the filter function to remove the object,
    the removal condition was made using the some function.
  */

  if (isAdmin) {
    return routes.filter((obj) => {
      return !menuRemoveAdmin.some((route) => route.name === obj.name);
    });
  }

  if (isIntegrator) {
    return routes.filter((obj) => {
      return !menuRemoveIntegrator.some((route) => route.name === obj.name);
    });
  }

  if (commonCompany) {
    return routes.filter((obj) => {
      return !menuRemoveCommon.some((route) => route.name === obj.name);
    });
  }
};

const companyOpenRoutes = [
  {
    path: '/empresas/cadastro',
    name: 'Cadastro de empresa',
    icon: DonutSmall,
    baseComponent: BaseLayout,
    component: companyRebuildEngine,
    hideName: true,
  },
  // This route is used by the backend mailing, if we change it we have to update the e-mail
  {
    path: '/empresas/cadastro/:id',
    name: 'Cadastro de empresa',
    hide: true,
    icon: DonutSmall,
    baseComponent: BaseLayout,
    component: companyRebuildEngine,
    state: {
      status: 'new',
    },
  },
];

export { companyRoutes, companyOpenRoutes };
