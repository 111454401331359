export function Suitcase({ color = '#009291', size = 21 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_36583_836)">
        <path
          d="M15.8333 3.83333H14.9167C14.7233 2.89284 14.2115 2.04779 13.4677 1.4406C12.7239 0.833408 11.7935 0.501212 10.8333 0.5L9.16667 0.5C8.2065 0.501212 7.2761 0.833408 6.53229 1.4406C5.78848 2.04779 5.27675 2.89284 5.08333 3.83333H4.16667C3.062 3.83466 2.00296 4.27407 1.22185 5.05518C0.440735 5.8363 0.00132321 6.89534 0 8L0 16.3333C0.00132321 17.438 0.440735 18.497 1.22185 19.2782C2.00296 20.0593 3.062 20.4987 4.16667 20.5H15.8333C16.938 20.4987 17.997 20.0593 18.7782 19.2782C19.5593 18.497 19.9987 17.438 20 16.3333V8C19.9987 6.89534 19.5593 5.8363 18.7782 5.05518C17.997 4.27407 16.938 3.83466 15.8333 3.83333ZM9.16667 2.16667H10.8333C11.3485 2.1688 11.8505 2.33007 12.2706 2.62841C12.6906 2.92674 13.0083 3.34758 13.18 3.83333H6.82C6.99174 3.34758 7.30936 2.92674 7.72942 2.62841C8.14948 2.33007 8.65145 2.1688 9.16667 2.16667ZM4.16667 5.5H15.8333C16.4964 5.5 17.1323 5.76339 17.6011 6.23223C18.0699 6.70107 18.3333 7.33696 18.3333 8V10.5H1.66667V8C1.66667 7.33696 1.93006 6.70107 2.3989 6.23223C2.86774 5.76339 3.50363 5.5 4.16667 5.5ZM15.8333 18.8333H4.16667C3.50363 18.8333 2.86774 18.5699 2.3989 18.1011C1.93006 17.6323 1.66667 16.9964 1.66667 16.3333V12.1667H9.16667V13C9.16667 13.221 9.25446 13.433 9.41074 13.5893C9.56702 13.7455 9.77899 13.8333 10 13.8333C10.221 13.8333 10.433 13.7455 10.5893 13.5893C10.7455 13.433 10.8333 13.221 10.8333 13V12.1667H18.3333V16.3333C18.3333 16.9964 18.0699 17.6323 17.6011 18.1011C17.1323 18.5699 16.4964 18.8333 15.8333 18.8333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_36583_836">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
