import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-top: 30em;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const WhiteBox = styled.div`
  background-color: #ffff;
  width: 589px;
  min-height: 698px;
  border-radius: 8px;
  position: absolute;
  padding: 16px 29px;
  top: 40px;
`;

export const H4Header = styled.h4`
  font-weight: 500;
  color: #606062;
`;
export const SectionMail = styled.section`
  border: 1px solid gray;
  border-radius: 8px;
  text-align: center;
  margin-top: 30px;
  background-color: #fbfbfb;

  > footer {
    > div {
      margin-top: 1px;
      display: flex;
      flex-direction: column;
      align-items: end;
      font-size: 9.5px;
      margin-right: 21px;
      padding: 10px 0px;
      line-height: 15px;

      > span {
        a {
          text-decoration: underline;
          color: ${(props) => props.color};
          font-weight: 400;
        }
      }
    }
  }
`;

export const SectionHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.color};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin-bottom: 30px;

  > img {
    width: 190px;
    padding: 20px 0px;
  }
`;

export const MainContent = styled.main`
  h3 {
    font-weight: bold;
    color: #4b4b4d;
    margin: 20px 0px;
  }

  p {
    margin: 10px 0px;
    padding: 0px 16px;
    font-size: 12px;
    font-family: sans-serif;
  }

  .actionButton {
    margin: 20px 0px;
    > button {
      width: 196px;
      height: 34px;
      border-radius: 6px;
      border: none;
      background-color: ${(props) => props.color};
      color: #ffff;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .sendBy {
    display: flex;
    font-size: 9.5px;
    flex-direction: column;
    line-height: 15px;
  }

  .spamInfo {
    margin-top: 20px;
    font-size: 9.5px;
    padding: 0px 45px;
    display: flex;
    flex-direction: column;
    color: #606062;
    line-height: 15px;
  }

  .spamClick {
    text-decoration: underline;
    color: ${(props) => props.color};
    font-weight: 400;
  }
`;
