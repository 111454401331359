import React, { Fragment, useContext, useRef, useState } from 'react';
import PaginationTotal from '../../../../../../../../components/PaginationTotal';
import { MatrixContext } from '../../../contexts/MatrixContext';
import {
  getMatricesCourse,
  postCreateMatrix,
} from '../../../services/matrixServices';
import DropCardMatrix from '../dropCardMatrix';
import {
  WrapperActualMatrix,
  H4Matrix,
  TitleSpan,
  WrapperCreatedMatrix,
} from '../style';

export default function ListMatrices({
  modalDetailAndEditProps,
  setModalDetailAndEditProps,
}) {
  const {
    matricesCourse,
    setModalIsOpenName,
    totalMatrices,
    selectedCourse,
    setMatricesCourse,
    actualPageMatrix,
    setActualPageMatrix,
  } = useContext(MatrixContext);

  const refList = useRef(null);

  const actualMatrix = matricesCourse.find((item) => item.is_actual === true);
  const createdMatrices = matricesCourse.filter(
    (item) => item.is_actual === false
  );

  const handleClickDetails = () => {
    setModalIsOpenName('editAndInformation');
  };

  const renderCardCreatedMatrices = createdMatrices.map((item) => (
    <DropCardMatrix
      id={item.id}
      course={item.course}
      disciplines={item.disciplines}
      name={item.name}
      description={item.description}
      handleClickDetails={handleClickDetails}
      setModalDetailAndEditProps={setModalDetailAndEditProps}
    />
  ));

  const handlePageChange = async (page) => {
    const newPage = `&limit=${8}&offset=${(page - 1) * 8 || 0}`;
    const { results } = await getMatricesCourse(selectedCourse, newPage);

    const persistActualMatrix = matricesCourse.filter(
      (item) => item.is_actual === true
    );

    if (page !== 1) {
      return setMatricesCourse([...persistActualMatrix, ...results]);
    }
    setMatricesCourse(results);
  };

  const onPageChange = (page) => {
    setActualPageMatrix(page);
    handlePageChange(page);
  };

  return (
    <Fragment>
      <H4Matrix>Matrizes</H4Matrix>

      <TitleSpan>Matriz atual</TitleSpan>

      <WrapperActualMatrix ref={refList}>
        <DropCardMatrix
          id={actualMatrix.id}
          course={actualMatrix.course}
          disciplines={actualMatrix.disciplines}
          name={actualMatrix.name}
          description={actualMatrix.description}
          handleClickDetails={handleClickDetails}
          setModalDetailAndEditProps={setModalDetailAndEditProps}
          isActualMatrix={true}
        />
      </WrapperActualMatrix>

      {createdMatrices.length > 0 && (
        <Fragment>
          <TitleSpan>Matrizes criadas</TitleSpan>
          <WrapperCreatedMatrix>
            {renderCardCreatedMatrices}

            <PaginationTotal
              pageRef={refList}
              totalCount={totalMatrices - 1}
              currentPage={actualPageMatrix}
              onPageChange={onPageChange}
              limit={8}
            />
          </WrapperCreatedMatrix>
        </Fragment>
      )}
    </Fragment>
  );
}
