import React, { Fragment } from "react";
import backgroundMyCareer from "assets/img/foto_moca_auto_a.jpg";
import CardAvatar from "components/Card/CardAvatar.jsx";
import { object, string, func } from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import staffHeaderStyle from "assets/jss/material-dashboard-pro-react/components/staffHeaderStyle.jsx";

const StaffHeader = props => {
  const { classes, title } = props;

  return (
    <div className={classes.staffHeaderHolder}>
      <Fragment>
        <CardAvatar profile>
          <div className={classes.studentInfo}>
            <h1 className={classes.pageTitle}>{title}</h1>
          </div>
        </CardAvatar>
      </Fragment>
    </div>
  );
};

StaffHeader.propTypes = {
  classes: object.isRequired,
  title: string,
  openModalWithContent: func,
  closeModal: func,
  openSnackbar: func,
  updateAvatarState: func,
  updateProfileState: func,
  handleIsLoadingState: func
};

export default withStyles(staffHeaderStyle)(StaffHeader);
