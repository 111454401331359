export const insightAdapter = ({
  students_platform_actives_curriculum,
  student_actuation_work_career,
  jobs_registered_progress,
  jobs_finished,
  applications,
  companies_connected_disconnected_jobs,
}) => {
  const data = {
    studentsPlatformActivesCurriculum: students_platform_actives_curriculum,
    studentActuationWorkCareer: student_actuation_work_career.map(
      (key, index) => {
        return {
          name: key.name,
          enrolled: key.enrolled,
          done: key.done,
          total: key.total,
          career_objectives: {
            opportunityAsTrainee: key.career_objectives.opportunity_as_trainee,
            careerTransition: key.career_objectives.career_transition,
            firstJob: key.career_objectives.first_job,
            internshipTrainee: key.career_objectives.internship_trainee,
            noCareerChanges: key.career_objectives.no_career_changes,
            ownBusiness: key.career_objectives.own_business,
            unemploydLookingForAJob:
              key.career_objectives.unemployd_looking_for_a_job,
          },
          notWorkingInFormationArea:
            key.not_working_in_formation_area &&
            key.not_working_in_formation_area,
          workingInFormationArea:
            key.working_in_formation_area && key.working_in_formation_area,
        };
      }
    ),
    jobsRegisteredProgress: jobs_registered_progress.map((key, index) => {
      return {
        name: key.name,
        byUniversity: key.by_university,
        byCompany: key.by_company,
        total: key.total,
        specificCourses: key.specific_courses,
        noSpecificCourses: key.no_specific_courses,
      };
    }),
    jobsFinished: {
      name: jobs_finished.name,
      hasContractingByPlatform: jobs_finished.has_contracting_by_platform,
      hasNotContractingByPlatform:
        jobs_finished.has_not_contracting_by_platform,
      nonContracting: jobs_finished.non_contracting,
      expired: jobs_finished.expired,
      total: jobs_finished.total,
    },
    applications: {
      name: applications.name,
      byUniversity: applications.by_university,
      byCompany: applications.by_company,
      average: applications.average,
      total: applications.total,
    },
    companiesConectedDisconectedJobs: companies_connected_disconnected_jobs.map(
      (key, index) => {
        return {
          name: key.name,
          inviteBySystemCompany: key.invite_by_system_company,
          totalCompanies: key.total_companies,
          inviteByUniversity: key.invite_by_university,
          indirectJobsCourses: key.indirect_jobs_courses,
          directJobsCourses: key.direct_jobs_courses,
        };
      }
    ),
  };
  return data;
};

export const insightOptionsAdapter = ({ result }) => {
  const data = {
    unities: result.unities,
    academicCourse: result.academic_course,
  };
  return data;
};
