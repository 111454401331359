import { dnaGreen, dnaColor } from 'assets/jss/material-dashboard-pro-react';

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 25rem;
  min-width: 16rem;
  max-height: 25rem;
  max-width: 16rem;
  background-color: transparent;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -57px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: transparent;
  padding: 1rem;
`;
export const FragmentColor = styled.div`
  width: 100%;
  border-radius: 8px;
  min-height: 80px;
  background: linear-gradient(to bottom, ${(props) => props.color}, white);
`;

export const ContainerLogo = styled.div`
  width: 87px;
  height: 87px;
  border-radius: 50px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #cdcdcd;
  ${(props) => (props.img ? `background-image: url(${props.img});` : '')}
  background-size: cover;
  background-position: center;
`;

export const TextName = styled.div`
  font-size: 21px;
  font-weight: 400;
  font-family: Roboto;
  color: #000000;
  line-height: 30px;
  text-align: center;
  padding: 0.5rem;
  height: 76px;
  text-transform: capitalize;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
`;

export const ContainerMiniLogo = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background: ${(props) => props.color}19;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 3px;
`;

export const TextRow = styled.p`
  font-size: 15px;
  font-family: Roboto;
  font-weight: 400;
  color: #606062;
  margin: 0;
  align-items: center;
  align-content: center;
  > b {
    color: ${(props) => props.color || 'transparent'};
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1rem;

  > span {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    align-items: center;
    align-content: center;

    > div {
      justify-content: center;
    }
  }
`;

export const ProfileDetail = styled.button`
  background-color: ${(props) => props.color};
  height: 37px;
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

export const ButtonFavorite = styled.button`
  background-color: transparent;
  width: 27px;
  height: 22px;
  align-items: center;
  align-content: center;

  padding: 1px;
  border: none;
  cursor: pointer;
  animation-duration: 2s;

  > svg {
    fill: ${(props) => (props.isFavorite ? props.color : '#606062;')};
    width: 25px;
    height: 25px;
    margin-top: -3px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:hover {
    > svg {
      fill: ${(props) => props.color};
    }
  }
  &:active {
    > svg {
      animation-name: breath-animation;
      animation-duration: 2s;
      @keyframes breath-animation {
        0% {
          transform: scale(1.5, 1.5);
        }
        50% {
          transform: scale(2, 2);
        }
        100% {
          transform: scale(1.5, 1.5);
        }
      }
    }
  }
`;
