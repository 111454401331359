import React, { useContext } from 'react';
import { Container, StepItem } from './styles';
import BaseLayoutContext from 'contexts/base-layout';
import useMediaQuery from 'hooks/useMediaQuery';

export default function StepIndicator({
  count,
  currentStep,
  onStepChange,
  toStepFunction = null,
  ...rest
}) {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { universityColor } = useContext(BaseLayoutContext);

  const handleClick = (index) => {
    if (toStepFunction !== null) {
      toStepFunction(index + 1);
    } else {
      currentStep !== index + 1 && onStepChange && onStepChange(index + 1);
    }
  };
  return (
    <Container isMobile={isMobile}>
      {Array.from({ length: count }).map((_, index) => {
        return (
          <StepItem
            universityColor={
              universityColor || rest?.universityColor || '#009291'
            }
            key={index}
            onClick={() => handleClick(index)}
            active={index + 1 === currentStep}
          >
            <span>{index + 1}</span>
          </StepItem>
        );
      })}
    </Container>
  );
}
