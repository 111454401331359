import BaseLayoutContext from 'contexts/base-layout';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineDislike, AiOutlineLike } from 'react-icons/ai';
import { IoStarOutline } from 'react-icons/io5';
import Action from './Action';
import { Container } from './styled';
import {
  getFairAndEventsListings,
  postCreateReaction,
} from 'views/Student/Learning/Services';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';

export default function ReactionActions({ event, fromCard }) {
  const { universityColor } = useContext(BaseLayoutContext);
  const {
    updateCount,
    getListings,
    currentTab,
    paginationObject,
    setGetFavoritedAvaiables,
  } = useVisualizationContext();

  const { interactions, id } = event || {};

  const [typeAction, setTypeAction] = useState('');
  const [favorited, setFavorited] = useState(false);

  const isFavorited = favorited === true;
  const isLiked = typeAction === 'like';
  const isDesliked = typeAction === 'dislike';

  const handleChangeFavoritedInsideFilter = async (favorited) => {
    updateCount();
    setGetFavoritedAvaiables(favorited);

    const currentListing = paginationObject[currentTab];

    const { type, setter } = currentListing;

    const searchParam = '';
    const query = '';

    const response = await getFairAndEventsListings(
      type,
      searchParam,
      favorited,
      _,
      query
    );

    setter({ value: response });
  };

  const typesApi = {
    like: () => postCreateReaction(id, 'like'),
    dislike: () => postCreateReaction(id, 'dislike'),
    favorited: () => {
      postCreateReaction(id, 'favorite');
    },
  };

  useEffect(() => {
    if (interactions) {
      if (interactions.liked) {
        setTypeAction('like');
      }
      if (interactions.disliked) {
        setTypeAction('dislike');
      }
      if (interactions.favorited) {
        setFavorited(true);
      }
    }
  }, [interactions]);

  const handleClickLikeActions = async (type) => {
    setTypeAction(type);
    const callAPI = typesApi[type];
    const response = await callAPI();
  };

  const handleClickFavorite = async () => {
    const callAPI = typesApi['favorited'];
    const response = await callAPI();
    setFavorited((prevs) => !prevs);
    handleChangeFavoritedInsideFilter(!favorited);
  };

  const actions = [
    {
      icon: <IoStarOutline size={18} color={universityColor} />,
      active: isFavorited,
      color: universityColor,
      onClick: handleClickFavorite,
    },
    {
      icon: <AiOutlineLike size={18} color="#009291" />,
      active: isLiked,
      color: '#009291',
      onClick: () => handleClickLikeActions('like'),
    },
    {
      icon: <AiOutlineDislike size={18} color="#DA0505" />,
      active: isDesliked,
      color: '#DA0505',
      onClick: () => handleClickLikeActions('dislike'),
    },
  ];

  const mapActions = actions.map((item) => (
    <Action
      icon={item.icon}
      active={item.active}
      color={item.color}
      onClick={item.onClick}
    />
  ));

  const returnCurrentBottom = () => {
    if (event.limit_students === 0) {
      return mapActions;
    }

    if (event.registred_students < event.limit_students) {
      return mapActions;
    }
  };

  return <Container fromCard={fromCard}>{returnCurrentBottom()}</Container>;
}
