import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import HeaderBackground from 'assets/SettingsPage/Profile/HeaderBackground.png';
import { useCallback, useEffect, useState } from 'react';
import {
  Wrapper,
  WhiteCard,
  SearchInput,
  ContainerHeaderFilter,
  GreenButton,
  WrapperLoadmore,
  ContainerBetween,
  ContainerFilters,
} from './Styled';

import IESCompanyAPI from './Service/API';
import HorizontalCard from './UIComponents/HorizontalCard';
import FullScreenModal from './UIComponents/FullScreenModal';
import FilterModal from 'components/FilterModal';
import { useDebounce } from 'hooks/useDebounce';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';
import CardSkeleton from './UIComponents/HorizontalCard/skeleton';

export default function EngineIesCompany({ handleIsLoadingState }) {
  const [universities, setUniversities] = useState([]);
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [selectedUnivesityId, setSelectedUniversityId] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [paginationNext, setPaginationNext] = useState(null);

  const [optionsFilter, setOptionsFilter] = useState({});

  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [queryCities, setQueryCities] = useState('');

  const [loading, setLoading] = useState(false);

  const value = useDebounce(searchValue, 500);

  useEffect(() => {
    getUniversities(`?name=${value}`);
  }, [value]);

  useEffect(() => {
    handleIsLoadingState(false);
    getFilters();
  }, []);

  const getFilters = async (query = '') => {
    const response = await IESCompanyAPI.getFilters(query);
    setOptionsFilter(response);
  };

  const getUniversities = async (query = '', concat) => {
    setLoading(true);
    const { next, results } = await IESCompanyAPI.getUniversities(query);
    setPaginationNext(next?.split(`?`)[1] || null);
    setLoading(false);

    if (concat) {
      setUniversities((prevs) => [...prevs, ...results]);
      return;
    }
    setUniversities(results);
  };

  const openModal = useCallback((id, tab) => {
    if (tab) {
      setActiveTab(tab);
    }

    setSelectedUniversityId(id);
    setFullScreenOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setFullScreenOpen(false);
  }, []);

  const handleClickLoadMore = () => {
    getUniversities(`?${paginationNext}`, true);
  };

  if (fullScreenOpen) {
    return (
      <>
        <CompanyHeader title="Conexões" backgroundImage={HeaderBackground} />
        <FullScreenModal
          id={selectedUnivesityId}
          closeModal={closeModal}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </>
    );
  }

  const handleApplyFilters = (filters) => {
    setFilterState(filters);
    setAppliedFilters(filters);
    const createFilter = new createFilterObject();

    const [param, _] = createFilter.convert(filters);
    getUniversities(`?${param}`);
  };

  const handleClearFilters = () => {
    setFilterState({});
    setAppliedFilters({});
    getUniversities(``);
  };

  const handleSelectState = (value) => {
    if (value.length > 0) {
      const queryString = `?state=${value[0]}${value
        .slice(1)
        .map((state) => `&state=${state}`)
        .join('')}`;
      setQueryCities(queryString.replace('?', ''));
      getFilters(queryString);
      return;
    }
    getFilters(``);
  };

  const handleSelectMacroArea = (value) => {
    if (value.length > 0) {
      const queryString = `?macro_area=${value[0]}${value
        .slice(1)
        .map((state) => `&macro_area=${state}`)
        .join('')}`;
      getFilters(`${queryString}&${queryCities}`);
      return;
    }
    getFilters(`?${queryCities}`);
  };

  const statusOptions = [
    { label: 'conectada', value: true },
    { label: 'desconectada', value: false },
  ];

  return (
    <>
      <CompanyHeader title="Conexões" backgroundImage={HeaderBackground} />
      <Wrapper>
        <WhiteCard>
          <ContainerHeaderFilter>
            <SearchInput
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Pesquisar por nome"
              value={searchValue}
            />

            <FilterModal
              filterState={filterState}
              setFilterState={setFilterState}
              onClean={handleClearFilters}
              appliedFilters={appliedFilters}
              setAppliedFilters={handleApplyFilters}
            >
              <ContainerFilters>
                <ContainerBetween margin={true}>
                  <Filter
                    options={optionsFilter.states}
                    label="Estado"
                    name={'state'}
                    type={FilterType.MULTI_SELECT}
                    settings={{
                      onChange: handleSelectState,
                    }}
                  />
                  <Filter
                    options={optionsFilter.cities}
                    label="Cidade"
                    name={'city'}
                    type={FilterType.MULTI_SELECT}
                    settings={{
                      disabled: optionsFilter.cities?.length === 0,
                    }}
                  />
                </ContainerBetween>

                <ContainerBetween>
                  <Filter
                    options={optionsFilter.macro_areas}
                    label="Macroáreas"
                    name={'macro_area'}
                    type={FilterType.MULTI_SELECT}
                    settings={{
                      onChange: handleSelectMacroArea,
                    }}
                  />
                </ContainerBetween>

                <ContainerBetween>
                  <Filter
                    options={statusOptions}
                    label="Status"
                    name={'status'}
                    type={FilterType.SINGLE_SELECT}
                  />
                  <Filter
                    label="Cursos"
                    name={'courses'}
                    options={optionsFilter.courses}
                    type={FilterType.MULTI_SELECT}
                  />
                </ContainerBetween>
              </ContainerFilters>
            </FilterModal>
          </ContainerHeaderFilter>
          <HorizontalCard
            universities={universities}
            openModal={openModal}
            setActiveTab={setActiveTab}
            loading={loading}
          />
          {loading && <CardSkeleton />}
          {paginationNext && (
            <WrapperLoadmore>
              <GreenButton onClick={handleClickLoadMore}>Ver mais</GreenButton>
            </WrapperLoadmore>
          )}
        </WhiteCard>
      </Wrapper>
    </>
  );
}
