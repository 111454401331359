const maskInputPhone = (inputValue) => {
  var maskedPhoneValue = inputValue.replace(/[^\d]/g, '');
  maskedPhoneValue = maskedPhoneValue && '(' + maskedPhoneValue;
  if (maskedPhoneValue.length > 3) {
    maskedPhoneValue = [
      maskedPhoneValue.slice(0, 3),
      ') ',
      maskedPhoneValue.slice(3),
    ].join('');
  }
  if (maskedPhoneValue.length > 12) {
    if (maskedPhoneValue.length > 13)
      maskedPhoneValue = [
        maskedPhoneValue.slice(0, 10),
        '-',
        maskedPhoneValue.slice(10),
      ].join('');
    else
      maskedPhoneValue = [
        maskedPhoneValue.slice(0, 9),
        '-',
        maskedPhoneValue.slice(9),
      ].join('');
  }
  if (maskedPhoneValue.length > 15) {
    maskedPhoneValue = maskedPhoneValue.substr(0, 15);
  }
  if (maskedPhoneValue.inputValue === '0000000') {
    maskedPhoneValue.inputValue.replace('0', '');
  }
  return maskedPhoneValue;
};

const maskCnae = (inputValue) => {
  if (!inputValue) {
    return '';
  }
  let onlyDigits = inputValue.replace(/\D/g, '');
  return `${onlyDigits.slice(0, 4)}-${onlyDigits.slice(
    4,
    5
  )}/${onlyDigits.slice(5, 7)}`;
};

const maskZipCode = (inputValue) => {
  if (!inputValue) {
    return '';
  }
  let onlyDigits = inputValue.replace(/\D/g, '');
  if (onlyDigits.length < 6) {
    return `${onlyDigits.slice(0, 5)}`;
  }
  return `${onlyDigits.slice(0, 5)}-${onlyDigits.slice(5, 8)}`;
};

const maskTime = (inputValue) => {
  if (!inputValue) {
    return '';
  }
  let onlyDigits = inputValue.replace(/\D/g, '');
  const digitsAmount = onlyDigits ? onlyDigits.length : 0;
  let hours = '';
  let minutes = '';
  if (digitsAmount <= 2) {
    return onlyDigits;
  } else {
    hours = onlyDigits.substring(0, digitsAmount - 2);
    minutes = onlyDigits.substring(digitsAmount - 2, digitsAmount);
    return `${hours}:${minutes}`;
  }
};

const maskTimeBlur = (inputValue) => {
  if (inputValue) {
    let [hours, minutes] = inputValue.split(':');
    minutes = minutes || '00';
    if (hours && hours.length === 1) {
      hours = `0${hours}`;
    }
    if (minutes && minutes.length === 1) {
      minutes = `0${minutes}`;
    }
    return `${hours}:${minutes}`;
  }
  return '';
};

function validTime(input) {
  const regexTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

  if (!regexTime.test(input)) {
    return false;
  }

  const hour = parseInt(input.split(':')[0], 10);
  if (hour > 23) {
    return false;
  }

  return true;
}

const maskCnpj = (inputValue) => {
  if (!inputValue) {
    return '';
  }
  let onlyDigits = inputValue.replace(/\D/g, '');
  return `${onlyDigits.slice(0, 2)}.${onlyDigits.slice(
    2,
    5
  )}.${onlyDigits.slice(5, 8)}/${onlyDigits.slice(8, 12)}-${onlyDigits.slice(
    12,
    14
  )}`;
};

const onlyNumbers = (value) => {
  if (value) {
    value = value.replace(/\D/gim, '');
    return value;
  }
  return '';
};

const fillTime = (value) => {
  if (!value) {
    return '';
  }
  value = value.replace(':', '');
  const digits = value.length;
  if (digits <= 2) {
    return `${value}:00`;
  } else {
    const hours = value.substring(0, digits - 2);
    const minutes = value.substring(digits - 2, digits);
    return `${hours}:${minutes}`;
  }
};

export {
  maskCnae,
  maskCnpj,
  maskInputPhone,
  maskZipCode,
  maskTime,
  maskTimeBlur,
  onlyNumbers,
  validTime,
  fillTime,
};
