import styled from 'styled-components';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const PaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
`;

export const PaginationItem = styled.li`
  height: 40px;
  min-width: 41px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 20px;

  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 15px;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  text-align: center;
  font-weight: 700;

  padding: 0;
  margin: auto 4px;

  &.dots:hover {
    background-color: transparent;

    cursor: default;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  .arrow {
    &::before {
      position: relative;
      content: '';
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid rgba(0, 0, 0, 0.87);
      border-top: 0.12em solid rgba(0, 0, 0, 0.87);
    }

    &.left {
      transform: rotate(-135deg) translate(-50%);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;
    .arrow::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }

  @media screen and (max-width: 600px) {
    height: 30px;
    min-width: 31px;

    font-size: 12px;
  }
`;

export const PaginationDots = styled(PaginationItem)`
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};

  &.selected {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    color: #ffffff;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .wrapper {
    position: relative;

    width: 100%;

    display: flex;
    justify-content: center;

    margin-bottom: 15px;
  }

  .formControl {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const selectPagination = {
  selectLabel: {
    whiteSpace: 'nowrap',
  },

  formControl: {
    width: 120,
  },

  selectInput: {
    minWidth: 120,
    textAlign: 'center',
  },

  cssLabel: {
    '&$cssFocused': {
      color: dnaColor,
    },
  },

  cssFocused: {},
};

export const Visualization = styled.div`
  ${(props) => (props.visible === true ? `display: none;` : `display: flex;`)}
`;
