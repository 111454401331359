import styled from 'styled-components';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';

export const ModalOverlay = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  background-color: #00000040;
  top: 0;
  left: 0;
  z-index: 1080;
`;

export const ContainerFlexDirection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  flex-direction: column;
  display: inline-block;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 1090)};
`;

export const ModalContent = styled.div`
  ${(props) => (props.isOpen ? `visibility: visible;` : `visibility: hidden;`)}
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 1rem;
  margin-left: ${(props) =>
    props.marginLeft ? props.marginLeft : props.hasFilters ? -504 : -530}px;
  height: auto;
  width: ${(props) => (props.width ? props.width : '675px')};

  @media (min-width: 820px) {
    &:before {
      content: '';
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background: #fff;
      position: absolute;
      top: -10px;
      left: calc(100% - ${(props) => (props.hasFilters ? 120 : 110)}px);
    }
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 820px) {
    max-height: calc(100vh - 32px);
    max-width: calc(100vw - 32px);
    position: fixed;
    top: 0;
    right: 16px;
    width: 100%;
    height: auto;
    overflow: auto;
  }

  > strong {
    font-weight: bold;
    font-size: 18px;
    color: #4b4b4d;
    margin: 0;
    padding: 2rem 2rem 0 2rem;

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin: 1rem 0 0 0;
      background-color: #dcdcdc;
    }
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  padding: 1rem 2rem;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

export const Tab = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 12px;
  font-weight: ${(props) => (props.isActive ? '700' : '500')};
  cursor: pointer;
  padding: ${(props) =>
    props.isActive ? '3px 0.75rem 0 0.75rem' : '0 0.75rem'};
  box-sizing: border-box;
  border-bottom: ${(props) => (props.isActive ? '3px solid #009291' : 'none')};
  background-position: center;
  transition: background 0.8s;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  white-space: nowrap;

  &:hover {
    background: #eee radial-gradient(circle, transparent 1%, #eee 1%)
      center/15000%;
  }

  &:active {
    background-color: #ccc;
    background-size: 100%;
    transition: background 0s;
  }
`;

export const ButtonFilter = styled.button`
  border: none;
  font-size: 13px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  padding: 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  border-radius: 3px;
  cursor: pointer;
  gap: 0.5rem;
  transition: filter 0.2s;
  color: #fff;
  z-index: ${(props) => (props.isOpen ? 10000 : 'inherit')};
  width: fit-content;

  &:not(&:disabled):hover {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const FilterContent = styled.div`
  display: flex;
  padding: 0 2rem;
  margin: 0;
  height: 100%;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }

  @media (max-width: 820px) {
    margin-bottom: 14px;
  }
`;

export const FilterBox = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  height: fit-content;
  box-sizing: border-box;
  width: 100%;

  margin: 0 5px;

  strong {
    font-size: 14px;
    color: #606062;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
  }

  input[type='text'].filter-text {
    height: 38px;
    border-radius: 3px;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 0.5rem;
    box-sizing: border-box;

    &:active,
    &:focus {
      border: 2px solid
        ${(props) =>
          props.universityColor ? props.universityColor : '#009291'};
    }
  }
`;

export const FilterUl = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;

  &.two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }

  &.wrap {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 8px;
    gap: 2rem;
  }
`;

export const FilterCheckboxLi = styled.li`
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;

  input {
    height: 18px;
    width: 18px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-size: 14px;
    color: #949494;
    line-height: 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

export const FilterRadioLi = styled.li`
  display: flex;
  gap: 0.5rem;
  box-sizing: border-box;

  input {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: 3.6px solid #009291;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  label {
    font-size: 14px;
    color: #949494;
    line-height: 24px;
    box-sizing: border-box;
    cursor: pointer;
  }
`;

export const FilterDatePeriodContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const ChipButton = styled.button`
  border: none;
  background: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  height: 22px;
  border-radius: 30px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};

  p {
    line-height: 22px;
    color: #fff;
    font-size: 12px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 820px) {
    height: 34px;

    p {
      line-height: 16px;
    }
  }
`;

export const SelectFilterBox = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  box-sizing: border-box;
  text-align: left;
`;

export const FilterCounter = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #fff;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
`;

export const FilterFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding: 2rem;

  @media (max-width: 820px) {
    margin-top: auto;
  }

  div {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-left: auto;
  }

  button {
    border: none;
    font-size: 12px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    transition: filter 0.2s;
    padding: 0 1rem;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const CancelButton = styled.button`
  background-color: #fff;
  color: #606062;
`;

export const ApplyButton = styled.button`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: #fff;
`;

export const CleanFiltersButton = styled.button`
  background-color: transparent;
  font-weight: 600;
  color: #606062;
`;

export const Elipsis = styled.div`
  .lds-ellipsis {
    position: relative;
    display: flex;
    width: 23px;
    height: 23px;
    align-items: center;
    justify-content: center;
  }

  .lds-ellipsis div {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    margin-left: -2px;
  }

  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 12px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 21px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(6px, 0);
    }
  }
`;
