import React from 'react';
import {
  ContainerLegend,
  ContainerLegendBars,
  ContainerBars,
  ContentBars,
  SkeletonLegend,
  SkeletonBars,
} from '../../Styles/StyleSkeleton';

export function HorizontalGraphSkeleton() {
  return (
    <ContentBars>
      <ContainerLegendBars>
        <SkeletonLegend widthLegend={150}/>
        <SkeletonLegend widthLegend={123} />
        <SkeletonLegend widthLegend={97} />
      </ContainerLegendBars>
      <ContainerBars>
        <SkeletonBars widthBars={650} />
        <SkeletonBars widthBars={480} />

        <SkeletonBars widthBars={230} />
      </ContainerBars>
    </ContentBars>
  );
}
