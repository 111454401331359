export function IconTypeCourse() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47818)">
        <path
          d="M16.5431 4.18933L11.0126 1.55458C9.79525 0.827407 8.28001 0.814504 7.05039 1.52083L1.45766 4.18933C1.43667 4.19984 1.41491 4.21109 1.39466 4.22308C0.0674441 4.98196 -0.393314 6.67308 0.365569 8.0003C0.622807 8.45023 1.00119 8.81888 1.45766 9.06434L3.00042 9.79935V13.4743C3.00134 15.1176 4.07062 16.5695 5.63968 17.0579C6.73159 17.3737 7.8638 17.5284 9.00044 17.5169C10.1369 17.5296 11.2691 17.3762 12.3612 17.0616C13.9303 16.5733 14.9995 15.1214 15.0004 13.4781V9.79784L16.5005 9.08083V15.2668C16.5005 15.681 16.8362 16.0168 17.2504 16.0168C17.6647 16.0168 18.0004 15.681 18.0004 15.2668V6.26682C18.0054 5.38612 17.31 4.57253 16.5431 4.18933ZM13.5004 13.4781C13.5008 14.461 12.8631 15.3305 11.9254 15.6253C10.9746 15.897 9.98924 16.0289 9.0004 16.0168C8.01156 16.0289 7.02624 15.897 6.0754 15.6253C5.13775 15.3304 4.50001 14.461 4.5004 13.4781V10.5141L6.98816 11.6991C7.60199 12.0636 8.30304 12.2552 9.01692 12.2534C9.69642 12.2582 10.3645 12.0783 10.9497 11.7329L13.5004 10.5141V13.4781ZM15.9004 7.71058L10.2439 10.4106C9.45522 10.8698 8.47763 10.8568 7.70141 10.3768L2.16715 7.74433C1.55026 7.41168 1.31985 6.64193 1.6525 6.02508C1.765 5.81646 1.93368 5.64353 2.13941 5.52583L7.76065 2.84084C8.54959 2.38261 9.52666 2.39559 10.3032 2.87459L15.8337 5.50934C16.2404 5.73518 16.4948 6.16166 16.5004 6.62685C16.5011 7.0675 16.2742 7.47728 15.9004 7.71058Z"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47818">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.266602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
