import React, { useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { UnInput, UnInputLabel, ContainerLabelInput } from './styled';

export default function InputWorkloadChallenge({ label, name, error, ...rest }) {
  const inputRef = useRef(null);
  const [workload, setWorkload] = useState('');
  const { fieldName, registerField, defaultValue } = useField(name);

  const maskHours = (value) => {
    let varTemp = value;
    if (varTemp >= 0 && typeof varTemp === 'number') {
      varTemp = String(varTemp);
    }

    return varTemp
      .replace(/\D/g, '')
      .replace(/(\d{2,6})([0-5]{1}[0-9]{1}$)/, '$1:$2');
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = maskHours(workload);
    }
  }, [workload]);

  useEffect(() => {
    if (defaultValue) {
      setWorkload(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        setWorkload(value)
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput
        ref={inputRef}
        defaultValue={defaultValue}
        onChange={(value) => setWorkload(value.target.value)}
        {...rest}
      />
      {
        error ? (
          <span style={{ color: '#F00' }}>
            A carga horária é obrigatória !
          </span>
        ) : null
      }
    </ContainerLabelInput>
  );
}
