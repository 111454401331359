import React, { useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';
import ClassNames from 'classnames';
import { element, object, bool, func, string } from 'prop-types';
import PropTypes from 'prop-types';

import MoreVert from '@material-ui/icons/MoreVert';
import ViewList from '@material-ui/icons/ViewList';
import { IconButton, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CancelIcon from '@material-ui/icons/Cancel';
import { newFullScreenDialogStyle } from '../../assets/jss/material-dashboard-pro-react/components/fullScreeDialogStyle';
import { TitleDialog } from './styled';
import BaseLayoutContext from 'contexts/base-layout';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const NewFullScreenDialog = (props) => {
  const {
    classes,
    dialogTitle,
    closeModal,
    isOpen,
    colorScheme,
    disableBackDrop,
    exitingFunction,
    dialogTitleDisable,
    deactivateScroll,
  } = props;

  const { sidebarMinimize } = classes;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onExiting={exitingFunction && exitingFunction}
      disableBackdropClick={exitingFunction && disableBackDrop}
      TransitionComponent={Transition}
      classes={{
        paperFullScreen: classes.paperFullScreen,
      }}
      className={`${classes.dialogWindow} ${
        props.miniActive ? classes.withSidebarMinimized : null
      }`}
    >
      <Grid
        container
        className={classes.container}
        style={{ overflow: deactivateScroll ? 'hidden' : '' }}
      >
        <Grid
          item
          xs
          md={12}
          sm={12}
          className={[
            classes.dialogHeader,
            colorScheme && classes[colorScheme],
          ]}
        >
          <Hidden smDown implementation="css">
            <div
              className={ClassNames([
                sidebarMinimize,
                classes.sidebarMinimizeButton,
              ])}
            >
              {props.miniActive ? (
                <ViewList
                  className={classes.sidebarMiniIcon}
                  onClick={props.sidebarMinimize}
                />
              ) : (
                <MoreVert
                  className={classes.sidebarMiniIcon}
                  onClick={props.sidebarMinimize}
                />
              )}
            </div>
          </Hidden>
          {!dialogTitleDisable && dialogTitle !== undefined && (
            <TitleDialog universityColor={universityColor}>
              {dialogTitle}
            </TitleDialog>
          )}
          <IconButton
            className={classes.closeFullScreenDialog}
            onClick={closeModal}
            disabled={disableBackDrop}
          >
            <CancelIcon />
          </IconButton>
        </Grid>
        <Grid item xs md={12} sm={12} className={classes.content}>
          {props.content}
        </Grid>
      </Grid>
    </Dialog>
  );
};

NewFullScreenDialog.propTypes = {
  content: element,
  classes: object,
  isOpen: bool,
  miniActive: bool,
  dialogTitle: string,
  exitingFunction: func,
  sidebarMinimize: func.isRequired,
  closeModal: func.isRequired,
  disableBackDrop: bool,
};
export default withStyles(newFullScreenDialogStyle)(NewFullScreenDialog);
