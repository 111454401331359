import {
  ContainerForm,
  Title,
  ContainerButton,
  CloseButton,
} from 'views/Backoffice/academicInnovation/Styles/FormStyles';
import { Form } from '@unform/web';
import { useRef, useState, useEffect } from 'react';
import MultiSelect from '../../../components/Form/MultiSelect';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';
import academicInnovationApi from 'views/Backoffice/academicInnovation/services/academicInnovationApi';
import * as Yup from 'yup';
import FormLoading from 'views/Backoffice/academicInnovation/components/FormLoading';

export default function AssociateTagForm({ item }) {
  const formRef = useRef(null);

  const getAssociatedTags = async () => {
    const response = await academicInnovationApi.getAssociatedTags(item.id);
    formRef.current.setFieldValue('content_tags', response);
    setValidation(response);
  };

  const [selectedCourses, setSelectedCourses] = useState([]);
  const [disciplineName, setDisiciplinesName] = useState([]);
  const [tagNames, setTagNames] = useState([]);
  const [validation, setValidation] = useState([]);
  const [courses, setCourses] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setCurrentFormToShow } = useFormContext();

  const handleClickClose = () => {
    setCurrentFormToShow(null);
  };

  useEffect(() => {
    const getOptions = async () => {
      const response = await academicInnovationApi.getCoursesOptionsAssociateForm();
      setCourses(response);
    };

    getOptions();
  }, []);

  const handleSelectCourse = async (event) => {
    if (event.length === 0 && disciplineName.length > 0) {
      formRef.current.setFieldValue('disciplines', []);
    }

    const values = event.map((item) => item.value).join('&courses=');
    const disciplines = await academicInnovationApi.getDisciplinesOptionsAssociateForm(
      values
    );
    setDisciplines(disciplines);

    formRef.current.setErrors({});
  };

  const getTags = async () => {
    const valuesDisciplines = disciplineName
      ?.map((item) => item.value)
      .join('&disciplines=');
    const valuesCourses = selectedCourses
      .map((item) => item.value)
      .join('&courses=');

    const coursesParam = valuesCourses ? `courses=${valuesCourses}` : '';
    const disciplinesParam = valuesDisciplines
      ? `disciplines=${valuesDisciplines}`
      : '';

    const query = `${
      coursesParam && disciplinesParam ? '' : ''
    }${coursesParam}${
      coursesParam && disciplinesParam ? '&' : ''
    }${disciplinesParam}`;

    const tags = await academicInnovationApi.getTagsOptionsAssociateForm(query);
    setTags(tags);
  };

  const ValidationSchema = Yup.object().shape({
    courses: Yup.array().test(
      'is-courses',
      'Informação obrigatória',
      function () {
        return validation.length !== 0 || tagNames.length !== 0;
      }
    ),
    content_tags: Yup.array().test(
      'is-tag',
      'Informação obrigatória',
      function () {
        return validation.length !== 0 || tagNames.length !== 0;
      }
    ),
  });

  const handleSelectDiscipline = async () => {
    formRef.current.setErrors({});
  };

  useEffect(() => {
    getTags();
  }, [selectedCourses, disciplineName]);

  useEffect(() => {
    getAssociatedTags();
  }, []);

  const handleSubmit = async (data) => {
    setIsLoading(true);
    try {
      await ValidationSchema.validate(data, {
        abortEarly: false,
      });
      const { content_tags } = data;
      await academicInnovationApi.putAssociteTagForm(item.id, { content_tags });
      handleClickClose();
    } catch (err) {
      setIsLoading(false);
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
      formRef.current.setErrors(validationErrors);
    }
  };

  return (
    <ContainerForm>
      <CloseButton onClick={() => handleClickClose()} />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title weight={600} margin={'25px 0px'} size={'22px'}>
          {item.name}
        </Title>
        <Title size={'18px'} margin={'10px 0px 15px;'}>
          Associação de TAGS
        </Title>
        <MultiSelect
          selectedOptions={selectedCourses}
          setSelectedOptions={setSelectedCourses}
          name="courses"
          options={courses}
          label="Nome do curso*"
          placeholder={'Selecione'}
          isMulti
          onChange={handleSelectCourse}
        />
        <MultiSelect
          selectedOptions={disciplineName}
          setSelectedOptions={setDisiciplinesName}
          name="disciplines"
          options={disciplines}
          label="Nome da disciplina"
          placeholder={'Selecione'}
          isMulti
          onChange={handleSelectDiscipline}
          isDisabled={selectedCourses.length === 0}
        />
        <MultiSelect
          selectedOptions={tagNames}
          setSelectedOptions={setTagNames}
          name="content_tags"
          options={tags}
          label="Nome da TAG da Instituição de Ensino*"
          placeholder={'Selecione'}
          isMulti
        />

        <ContainerButton>
          <button type="submit">
            {isLoading ? <FormLoading /> : 'Salvar'}
          </button>
        </ContainerButton>
      </Form>
    </ContainerForm>
  );
}
