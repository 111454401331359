import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import Videos from './Videos';
import Books from './Books';
import Movies from './Movies';

const Contents = ({ data, colorScheme }) => {
  return (
    <Grid item>
      {data.videos && <Videos data={data.videos} colorScheme={colorScheme} />}
      {data.books && <Books data={data.books} colorScheme={colorScheme} />}
      {data.movies && <Movies data={data.movies} colorScheme={colorScheme} />}
    </Grid>
  );
};

Contents.propTypes = {
  data: PropTypes.shape({
    videos: PropTypes.shape,
    books: PropTypes.shape,
    movies: PropTypes.shape,
  }).isRequired,
  colorScheme: PropTypes.string.isRequired,
};

export default Contents;
