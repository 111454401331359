import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';

import CurriculoModalDragAndDropEditing from './CurriculoModal/CurriculoModalDragAndDropEditing';

import BaseLayoutContext from 'contexts/base-layout';
import AuthContext from 'contexts/authentication';

import { Document, Page, pdfjs } from 'react-pdf';
import { StyleSheet } from '@react-pdf/renderer';

import notifications from 'assets/curriculum/notification.svg';

import Hidden from '@material-ui/core/Hidden';
import { ExitToApp } from 'assets/curriculum/exit.jsx';
import Curriculum from './Curriculum';

import {
  Actions,
  AsideColumn,
  Checkbox,
  CleanButton,
  CloseButton,
  Container,
  Content,
  CurriculumSection,
  Header,
  ButtonNotification,
  LocalActions,
  ButtonLogout,
  ImageIcon,
  Preferences,
  HeaderPreference,
  SpanNew,
  PrimaryButton,
  PreviewHeader,
  SimpleButton,
} from './styled';

import { BlobProvider } from '@react-pdf/renderer';
import { CircularProgress } from '@material-ui/core';
import Like from '../../views/Company/components/Like/Like';
import ApplicationReturn from '../../views/Company/components/ApplicationReturn/ApplicationReturn';
import ScoreRating from '../../views/Company/components/ScoreRating/ScoreRating';
import { registerDownloadedCurriculum } from 'services/student/registerActivityStudent';
import { ScheduleInterviewModal } from 'components/ListCandidates/UIComponents/Modal/ScheduleInterviewModal';
import { getStudentCurriculumData } from 'services/company/curriculum';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import { getApplicationCoverLetter as universityGetApplicationCoverLetter } from 'services/university/coverLetter';
import { getApplicationCoverLetter as companyGetApplicationCoverLetter } from 'services/company/coverLetter';

import { InterstBoxComponent } from './components/InterestBox';
import StarRating from 'components/StarRating';
import { CancelInterviewModal } from 'components/ListCandidates/UIComponents/Modal/CancelInterviewModal';
import { CancellationDetails } from 'components/ListCandidates/UIComponents/Modal/CancellationDetails';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function CurriculumModalGenerate(props) {
  const {
    isOpen,
    onClose,
    curriculumData,
    setCurriculumData,
    studentId,
    liked,
    updateLiked,
    showLike,
    updateCandidates,
    showApplicationReturn,
    studentData,
    returnJobApplication,
    fetchStudentData,
    options,
    partnerJob,
    hasCoverLetter,
    university,
    company,
    candidateId,
    jobId,
    interview,
    setCandidates,
    history,
    selectedTab,
    className,
    showInterviewButton,
    showActions,
  } = props;

  const documentDiv = useRef(null);
  const { IoClose } = useIcons();

  const {
    miniActive,
    universityColor,
    toggleNotificationDrawer,
    handleIsLoadingState,
    openModalWithContent,
    openSnackbar,
    closeModal,
  } = useContext(BaseLayoutContext);

  const { metadata } = useContext(AuthContext);

  const auth = useContext(AuthContext);
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfPages, setPdfPages] = useState(0);
  const [pdfBlob, setPdfBlob] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [previewOrder, setPreviewOrder] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalReward, setModalRewards] = useState(false);
  const [scheduleInterviewModal, setScheduleInterviewModal] = useState(false);
  const [cancelInterviewModal, setCancelInterviewModal] = useState(false);
  const [cancellationModal, setCancellationModal] = useState(false);

  const [coverLetter, setHasCoverLetter] = useState(hasCoverLetter);
  const [showAverage, setShowAverage] = useState(false);

  const handleDownload = async () => {
    const url = window.URL.createObjectURL(
      new Blob([pdfBlob], { type: 'application/pdf' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      curriculumData?.academic_profile.name || 'documento.pdf'
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    await registerDownloadedCurriculum([curriculumData.curriculum_id]);
  };

  const handlePrint = () => {
    const win = window.open(pdfLink, '_blank');
    win.print();
  };

  useEffect(() => {
    setModalRewards && setModalRewards(false);
    return () => {
      setPdfLink(null);
      setPdfPages(null);
    };
  }, []);

  function updateInformationOrder(newOrder) {
    const newCurriculumData = { ...curriculumData };
    newCurriculumData.information_order = newOrder;
    setCurriculumData(newCurriculumData);
  }

  const styles = StyleSheet.create({
    document: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '20px',
    },
  });

  const loadPages = () => {
    const pages = [];
    for (let i = 1; i <= pdfPages; i++) {
      pages &&
        pages.push(
          <Page
            width={documentDiv.current.offsetWidth}
            height={documentDiv.current.offsetHeight}
            className="pageCurriculum"
            pageNumber={i}
          />
        );
    }

    return pages;
  };

  function handleClosePreview() {
    setShowPreview(false);
    setOpenEditModal(true);
  }

  const updateHasCoverLetter = (state) => {
    setHasCoverLetter(state);
  };

  const returnPhrase = (metadata) => {
    const roleMessages = {
      student: 'Imprima ou baixe o seu currículo.',
      university: '',
      company: '',
    };

    return roleMessages[metadata.role] || '';
  };

  const openLetter = async (id) => {
    if (hasCoverLetter) {
      openModalWithContent(
        <CoverLetterContainer
          notStudent
          studentId={id}
          handleLoadingState={handleIsLoadingState}
          getApplicationCoverLetter={
            metadata.role === 'company'
              ? companyGetApplicationCoverLetter
              : universityGetApplicationCoverLetter
          }
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
          metadata={metadata}
        />
      );
    } else {
      openSnackbar(`Error ao obter os dados! Tente novamente mais tarde`, true);
    }
  };

  const returnLabelButton = () => {
    if (interview?.hasOwnProperty('status')) {
      const statusLabel = {
        unscheduled: 'Detalhes da entrevista',
        scheduled: 'Detalhes da entrevista',
        rescheduled: 'Detalhes da entrevista',
        canceled: 'Detalhes do cancelamento',
      };

      return statusLabel[interview.status];
    }
    return 'Agendar entrevista';
  };

  const controlOpenModal = () => {
    if (interview?.hasOwnProperty('status')) {
      if (interview.status !== 'canceled') {
        setScheduleInterviewModal(true);
      } else {
        setCancellationModal(true);
      }
    } else {
      setScheduleInterviewModal(true);
    }
  };

  const returnButtons = () => {
    if (metadata.role === 'student') {
      return (
        <>
          <PrimaryButton
            universityColor={universityColor}
            onClick={handleDownload}
          >
            Baixar currículo
          </PrimaryButton>
          <CleanButton universityColor={universityColor} onClick={handlePrint}>
            Imprimir
          </CleanButton>
        </>
      );
    }
    if (metadata.role === 'university' || metadata.role === 'company') {
      return (
        <>
          {showInterviewButton && (
            <PrimaryButton
              universityColor={universityColor}
              onClick={() => controlOpenModal()}
            >
              {returnLabelButton()}
            </PrimaryButton>
          )}

          <CleanButton
            universityColor={universityColor}
            onClick={handleDownload}
          >
            Baixar currículo
          </CleanButton>
          <SimpleButton
            disabled={!hasCoverLetter}
            onClick={() => openLetter(studentId)}
          >
            Visualizar carta de apresentação
          </SimpleButton>
        </>
      );
    }
  };

  return (
    <Container
      isOpen={isOpen}
      miniActive={miniActive}
      overflow={openEditModal}
      className={className}
    >
      <Header universityColor={universityColor}>
        <LocalActions>
          <ButtonNotification
            round
            justIcon
            aria-label="open drawer"
            onClick={toggleNotificationDrawer}
          >
            <ImageIcon src={notifications} />
          </ButtonNotification>
          <Hidden smDown implementation="css">
            <ButtonLogout
              universityColor={universityColor}
              onClick={auth.routerOnUserLogout}
            >
              <ExitToApp
                color={universityColor ? universityColor : '#009291'}
              />
              Sair
            </ButtonLogout>
          </Hidden>
        </LocalActions>
        <p>Currículo do Futuro</p>
      </Header>
      <Content showPreview={showPreview}>
        {showPreview && (
          <PreviewHeader universityColor={universityColor}>
            <strong>Pré-visualizar currículo</strong>
            <button onClick={handleClosePreview}>
              <IoClose color="#fff" />
            </button>
          </PreviewHeader>
        )}
        <CurriculumSection ref={documentDiv}>
          <CloseButton onClick={onClose} showPreview={showPreview}>
            <IoClose />
          </CloseButton>
          {curriculumData && curriculumData && (
            <BlobProvider
              document={
                <Curriculum
                  curriculumData={curriculumData}
                  showAverage={showAverage}
                  previewOrder={previewOrder}
                />
              }
            >
              {({ blob, url, loading, error }) =>
                !loading ? (
                  <Document
                    style={styles.document}
                    file={url}
                    loading={<CircularProgress color={universityColor} />}
                    noData={null}
                    onLoadSuccess={(pdf) => {
                      handleIsLoadingState(false);
                      setPdfPages(pdf.numPages);
                      setPdfLink(url);
                      setPdfBlob(blob);
                    }}
                  >
                    {loadPages && loadPages().map((page) => page)}
                  </Document>
                ) : null
              }
            </BlobProvider>
          )}
        </CurriculumSection>
        {!showPreview && (
          <AsideColumn>
            <Actions>
              <h1>Ações</h1>
              <p>{returnPhrase(metadata)}</p>
              <div className="container-buttons">{returnButtons()}</div>
            </Actions>
            {(metadata.role === 'university' || metadata.role === 'company') &&
              showActions && (
                <>
                  <InterstBoxComponent
                    studentData={studentData}
                    updateCandidates={updateCandidates}
                    returnJobApplication={returnJobApplication}
                    options={options}
                    fetchStudentData={fetchStudentData}
                    partnerJob={partnerJob}
                    university={university}
                    company={company}
                    jobId={jobId}
                    selectedTab={selectedTab}
                    setCandidates={setCandidates}
                  />
                  <Actions>
                    {!updateCandidates ? (
                      <div className="phrase">
                        Nível de alinhamento desse candidato à vaga:
                      </div>
                    ) : (
                      <div className="phrase">
                        Indique o nível de alinhamento desse candidato à vaga:
                      </div>
                    )}

                    <StarRating
                      studentData={studentData}
                      updateCandidates={updateCandidates}
                      role={metadata.role}
                      score={studentData?.score}
                      jobId={jobId}
                      isPartner={partnerJob}
                      selectedTab={selectedTab}
                      setCandidates={setCandidates}
                      company={company}
                    />
                  </Actions>
                </>
              )}

            {metadata.role === 'student' && (
              <Preferences>
                <HeaderPreference>
                  <h1>Preferências</h1> <SpanNew>NOVO</SpanNew>
                </HeaderPreference>
                <p>
                  Defina aqui a ordenação das informações apresentadas no seu
                  currículo.
                </p>
                <CleanButton
                  universityColor={universityColor}
                  onClick={() => setOpenEditModal(true)}
                >
                  Reorganizar meu currículo
                </CleanButton>
                <Checkbox>
                  <input
                    type="checkbox"
                    checked={showAverage}
                    onClick={() => setShowAverage(!showAverage)}
                  />
                  Exibir média do meu rendimento acadêmico.
                </Checkbox>
              </Preferences>
            )}
            {showLike && (
              <Like
                studentId={studentId}
                hasLiked={liked[studentId]}
                updateLiked={updateLiked}
              />
            )}

            {scheduleInterviewModal && (
              <ScheduleInterviewModal
                visible={scheduleInterviewModal}
                controlModal={setScheduleInterviewModal}
                candidateId={candidateId}
                jobId={jobId}
                company={company}
                university={university}
                interview={interview}
                setCancelInterviewModal={setCancelInterviewModal}
                selectedTab={selectedTab}
                setCandidates={setCandidates}
                isPartner={partnerJob}
              />
            )}
            {cancellationModal && (
              <CancellationDetails
                openModal={cancellationModal}
                controlModal={setCancellationModal}
                jobId={jobId}
                interviewId={interview.id}
                candidateId={candidateId}
                company={company}
              />
            )}
            {cancelInterviewModal && (
              <CancelInterviewModal
                cancelInterviewModal={cancelInterviewModal}
                setCancelInterviewModal={setCancelInterviewModal}
                jobId={jobId}
                candidateId={candidateId}
                interview={interview}
                company={company}
                toView={interview.status === 'canceled'}
                history={history}
                setCandidates={setCandidates}
                isPartner={partnerJob}
              />
            )}

            {scheduleInterviewModal && (
              <ScheduleInterviewModal
                visible={scheduleInterviewModal}
                controlModal={setScheduleInterviewModal}
                candidateId={candidateId}
                jobId={jobId}
                company={company}
                university={university}
                interview={interview}
                setCancelInterviewModal={setCancelInterviewModal}
                selectedTab={selectedTab}
                setCandidates={setCandidates}
                isPartner={partnerJob}
              />
            )}
          </AsideColumn>
        )}
      </Content>
      {openEditModal && (
        <CurriculoModalDragAndDropEditing
          curriculumData={curriculumData}
          isOpen={openEditModal}
          setOpenEditModal={setOpenEditModal}
          pdfLink={pdfLink}
          updateInformationOrder={updateInformationOrder}
          previewOrder={previewOrder}
          setPreviewOrder={setPreviewOrder}
          setShowPreview={setShowPreview}
        />
      )}
    </Container>
  );
}

CurriculumModalGenerate.defaultProps = {
  showInterviewButton: true,
  showActions: true,
};

export default CurriculumModalGenerate;
