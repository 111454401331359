import styled from 'styled-components';

export const ContainerMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 65px 0px;

  > p {
    font-size: 15px;
  }
`;
