import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import {
  ChevronWrapper,
  Container,
  Tab,
  TabsContainer,
  TabsWrapper,
} from './styled';
import { useContext, useEffect, useRef, useState } from 'react';
import BaseLayoutContext from 'contexts/base-layout';

export function Tabs({ options, selectedOption, setSelectedOption }) {
  const [marginLeft, setMarginLeft] = useState({ before: 0, current: 0 });
  const [showRightChevron, setShowRightChevron] = useState(false);
  const containerRef = useRef(null);
  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    if (!selectedOption && options?.length > 0) {
      setSelectedOption(options[0].value);
    }
  }, [options]);

  function checkShowChevron() {
    if (!options) return;
    const tabContainer = document.getElementById('tabContainer');
    const tabContainerWidth = tabContainer.offsetWidth;
    const tabsWidth = tabContainer.scrollWidth;

    if (tabsWidth > tabContainerWidth) {
      setShowRightChevron(true);
    } else {
      setShowRightChevron(false);
    }
  }

  useEffect(() => {
    checkShowChevron();
    setMarginLeft({ before: 0, current: 0 });
  }, [options]);

  const handleChevronClick = (direction) => {
    const step = ((containerRef.current?.offsetWidth || 250) * 2) / 3;
    const tabContainer = document.getElementById('tabContainer');
    const tabContainerWidth = tabContainer.offsetWidth;
    const tabsWidth = tabContainer.scrollWidth;
    const scrollValue = direction === 'left' ? step : -step;
    const marginLeftRemaining = -(tabsWidth - tabContainerWidth);
    const newMarginLeft = marginLeft.current + scrollValue;

    if (newMarginLeft > 0) {
      setMarginLeft({ before: marginLeft.current, current: 0 });
      setShowRightChevron(true);
    } else if (-step < marginLeftRemaining && direction === 'right') {
      setMarginLeft({
        before: marginLeft.current,
        current: marginLeft.current + marginLeftRemaining,
      });
      setShowRightChevron(false);
    } else if (marginLeftRemaining && marginLeftRemaining < step) {
      setMarginLeft({ before: marginLeft.current, current: newMarginLeft });
      setShowRightChevron(true);
    } else if (newMarginLeft === marginLeftRemaining) {
      setMarginLeft({ before: marginLeft.current, current: newMarginLeft });
    } else {
      if (direction === 'left') {
        setMarginLeft({
          before: marginLeft.current,
          current: marginLeft.current + step,
        });
        setShowRightChevron(true);
      } else {
        setMarginLeft({
          before: marginLeft.current,
          current: marginLeft.current + marginLeftRemaining,
        });
        setShowRightChevron(false);
      }
    }
  };

  return (
    <Container ref={containerRef}>
      <ChevronWrapper
        show={marginLeft.current !== 0}
        onClick={() => handleChevronClick('left')}
      >
        <BsChevronLeft size={20} />
      </ChevronWrapper>
      <TabsContainer>
        <TabsWrapper
          id={'tabContainer'}
          marginLeft={`${marginLeft.current}px`}
          initialMarginLeft={`${marginLeft.before}px`}
        >
          {options?.map((option) => (
            <Tab
              color={universityColor}
              key={option.value}
              selected={option.value === selectedOption}
              onClick={() => setSelectedOption(option.value)}
            >
              {option.label}
            </Tab>
          ))}
        </TabsWrapper>
      </TabsContainer>
      <ChevronWrapper
        marginLeft={'auto'}
        show={showRightChevron}
        onClick={() => handleChevronClick('right')}
      >
        <BsChevronRight size={20} />
      </ChevronWrapper>
    </Container>
  );
}
