import React, { useContext } from 'react';
import {
  ModalMobile,
  HeaderModal,
  HeaderTitle,
  BackgroundModal,
  ContentModal,
  ButtonPersonalInfoMobile,
  ContainerButtonMobile,
  ButtonCloseModal,
  CardBodySubTitle,
  HeaderGenericModal,
} from '../styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';

const GenericModalInformations = ({
  isOpen,
  closeModal,
  title,
  children,
  isPersonalInfo,
  handleEditInfo,
  show,
  subTitleCourses,
}) => {
  const { universityColor } = useContext(ProfileContext);
  const { IoIosClose } = wrapperIconsProfile();
  return (
    <>
      {isOpen ? (
        <BackgroundModal>
          <ModalMobile show={isOpen}>
            <HeaderModal>
              <HeaderGenericModal>
                <HeaderTitle>{title}</HeaderTitle>
                <CardBodySubTitle>{subTitleCourses}</CardBodySubTitle>
              </HeaderGenericModal>
              <ButtonCloseModal onClick={closeModal}>
                <IoIosClose size={24} />
              </ButtonCloseModal>
            </HeaderModal>

            <ContentModal>{children}</ContentModal>

            {isPersonalInfo && (
              <ContainerButtonMobile>
                <ButtonPersonalInfoMobile
                  onClick={() => handleEditInfo()}
                  universityColor={universityColor}
                >
                  Editar dados pessoais
                </ButtonPersonalInfoMobile>
              </ContainerButtonMobile>
            )}
          </ModalMobile>
        </BackgroundModal>
      ) : null}
    </>
  );
};

export default GenericModalInformations;
