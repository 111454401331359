import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 50vh;
  height: auto;
  padding: 2em 4em;
`;
export const Container = styled.div`
  width: 100%;
  background-color: #ffff;
  border-radius: 4px;
`;

export const ContainerPagination = styled.div`
  padding-bottom: 47px;
`;
