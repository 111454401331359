import React, { useRef, useState, useEffect } from 'react';
import { postCreateReaction } from 'services/student/extensionProject';
import RadioFeedBack from './radioInput';
import primaryLike from 'assets/student/primaryLike.svg';
import secundaryLike from 'assets/student/secundaryLike.svg';
import secundaryDeslike from 'assets/student/secundaryDeslike.svg';
import primaryDeslike from 'assets/student/primaryDeslike.svg';
import primaryStar from 'assets/student/primaryStar.svg';
import secundaryStar from 'assets/student/secundaryStar.svg';
import { Form } from '@unform/web';
import { updateReaction } from 'services/student/programs';
import { newRecommend } from 'services/student/events';
import { cancelRecommendation } from 'services/student/events';
import { deleteReaction } from 'services/student/programs';
export default function Reactions({ id, item, getExtesionProject, limit }) {
  const formRef = useRef(null);

  const [optionValue, setOptionValue] = useState('');
  const [optionValeuRecommended, setOptionValeuRecommended] = useState('');

  useEffect(() => {
    setOptionValue('');
    setOptionValeuRecommended('');
    if (formRef.current) {
      if (item.is_liked) {
        setOptionValue('liked');
        formRef.current.setFieldValue('type', 'liked');
      }
      if (item.is_disliked) {
        setOptionValue('disliked');
        formRef.current.setFieldValue('type', 'disliked');
      }
      if (item.is_recommended) {
        setOptionValeuRecommended('recommended');
        formRef.current.setFieldValue('recommended', 'recommended');
      }
    }
  }, [item, id]);

  const handleChange = async (e) => {
    const value = e.target.value;
    if (e.target.value === optionValue) {
      await deleteReaction(id, item.reaction_id);
      setOptionValue('');
      getExtesionProject(`limit=${limit}`);
      return;
    }
    setOptionValue(value);
    formRef.current.submitForm();
  };

  const handleChangeRecommended = async () => {
    if (optionValeuRecommended === '') {
      setOptionValeuRecommended('recommended');
      await newRecommend(id);
      getExtesionProject(`limit=${limit}`);
      return;
    }
    await cancelRecommendation(id);
    setOptionValeuRecommended('');
    getExtesionProject(`limit=${limit}`);
  };

  const Options = [
    {
      id: Math.floor(Math.random() * 1000),
      value: 'liked',
      label: 'yes',
      type: 'radio',
      color: '#009291',
      imgPrimary: primaryLike,
      imgSecundary: secundaryLike,
    },
    {
      id: Math.floor(Math.random() * 1000),
      value: 'disliked',
      label: 'no',
      type: 'radio',
      color: '#D26464',
      imgPrimary: primaryDeslike,
      imgSecundary: secundaryDeslike,
    },
  ];

  const OptionRecommended = [
    {
      id: Math.floor(Math.random() * 1000),
      value: 'recommended',
      label: 'no',
      type: 'checkbox',
      color: '#FFC328',
      imgPrimary: primaryStar,
      imgSecundary: secundaryStar,
    },
  ];

  const handleSubmit = async (data) => {
    if (item.reaction_id) {
      await updateReaction(id, item.reaction_id, { ...data });
      getExtesionProject(`limit=${limit}`);
      return;
    }
    getExtesionProject(`limit=${limit}`);
    return await postCreateReaction(id, { ...data });
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <RadioFeedBack
          name="recommended"
          options={OptionRecommended}
          onChange={handleChangeRecommended}
          optionValue={optionValeuRecommended}
          setOptionValue={setOptionValeuRecommended}
        />

        <RadioFeedBack
          name="type"
          options={Options}
          onClick={handleChange}
          optionValue={optionValue}
          setOptionValue={setOptionValue}
        />
      </div>
    </Form>
  );
}
