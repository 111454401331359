import React, { useState, useEffect } from 'react';
import ContentProfessionalStatus from './Screens/Steps/ContentProfessionalStatus';
import ContentProfessionalStatusEdit from './Screens/Steps/ContentProfessionalStatusEdit';
import { FormProvider } from './context';
import { Container } from 'views/ProfessionalStatus/styles/styles';
import professionalStatusApi from './services/professionalStatusApi';
import SkeletonProfessionalStatus from './components/Skeleton';

export default function ProfessionalStatusEngine({
  classes,
  metadata,
  academicProfile,
  professional_status,
  globalState,
}) {
  const [professionalStatusData, setProfessionalStatusData] = useState({});
  const universityColor = metadata.university_color;
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDataFirstAccess = async () => {
      const response = await professionalStatusApi.getFirstAccess();
      const data = await professionalStatusApi.getProfile();
      setProfessionalStatusData(response);
      setProfileData(data);
      setIsLoading(false);
    };
    getDataFirstAccess();
  }, []);

  const isProfessionalStatusDefined =
    professionalStatusData.professional_status_defined;

  return (
    <FormProvider>
      <Container>
        {isLoading ? (
          <SkeletonProfessionalStatus />
        ) : !isProfessionalStatusDefined ? (
          <ContentProfessionalStatus
            classes={classes}
            metadata={metadata}
            academicProfile={academicProfile}
            professional_status={professional_status}
            globalState={globalState}
            universityColor={universityColor}
            profileData={profileData}
            setIsLoading={setIsLoading}
            professionalStatusData={professionalStatusData}
          />
        ) : (
          <ContentProfessionalStatusEdit
            classes={classes}
            metadata={metadata}
            academicProfile={academicProfile}
            professional_status={professional_status}
            globalState={globalState}
            universityColor={universityColor}
            isProfessionalStatusDefined={isProfessionalStatusDefined}
            profileData={profileData}
            setIsLoading={setIsLoading}
          />
        )}
      </Container>
    </FormProvider>
  );
}
