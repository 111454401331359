export function IconCurriculumReward({ color }) {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9999 11.6667C9.74825 11.6667 7.91659 13.4983 7.91659 15.75C7.91659 18.0017 9.74825 19.8333 11.9999 19.8333C14.2516 19.8333 16.0833 18.0017 16.0833 15.75C16.0833 13.4983 14.2516 11.6667 11.9999 11.6667ZM11.9999 17.5C11.0351 17.5 10.2499 16.7148 10.2499 15.75C10.2499 14.7852 11.0351 14 11.9999 14C12.9648 14 13.7499 14.7852 13.7499 15.75C13.7499 16.7148 12.9648 17.5 11.9999 17.5ZM21.2738 6.45867L17.2091 2.39167C15.6656 0.849333 13.6146 0 11.4341 0H6.16659C2.95009 0 0.333252 2.61683 0.333252 5.83333V22.1667C0.333252 25.3832 2.95009 28 6.16659 28H17.8333C21.0498 28 23.6666 25.3832 23.6666 22.1667V12.2325C23.6666 10.0497 22.8161 8.001 21.2738 6.45867ZM19.6241 8.1095C19.9951 8.47933 20.3089 8.89117 20.5633 9.3345H15.4988C14.8548 9.3345 14.3321 8.81067 14.3321 8.16783V3.10217C14.7754 3.3565 15.1873 3.67033 15.5583 4.04133L19.6229 8.10833L19.6241 8.1095ZM8.70059 25.6678C9.18242 24.3098 10.4786 23.3345 11.9999 23.3345C13.5213 23.3345 14.8174 24.3098 15.2993 25.6678H8.70059ZM21.3333 22.1678C21.3333 24.0975 19.7629 25.6678 17.8333 25.6678H17.7154C17.1729 23.009 14.8163 21.0012 11.9999 21.0012C9.18359 21.0012 6.82575 23.009 6.28442 25.6678H6.16659C4.23692 25.6678 2.66659 24.0975 2.66659 22.1678V5.83333C2.66659 3.90367 4.23692 2.33333 6.16659 2.33333H11.4341C11.6243 2.33333 11.8133 2.34267 11.9999 2.36017V8.16667C11.9999 10.0963 13.5703 11.6667 15.4999 11.6667H21.3064C21.3239 11.8533 21.3333 12.0423 21.3333 12.2325V22.1678Z"
        fill={color}
      />
    </svg>
  );
}
