import styled from "styled-components";
import X from '../../../assets/Oportutinites/X.svg';

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const RModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000000000000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(10px);
`;

export const RModalContainer = styled.div`
  width: 512px;
  max-height: 1020px;
  margin-bottom: 5em;
  overflow: scroll;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;

  .content-wrapper {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    position: static;
    top: 20px;
  }
`;