import styled from 'styled-components'

export const Container = styled.div`
  padding: 20px;

  .container-wrapper h3 {
    font-weight: bold; 
    color: #009291;
    font-size: 20px;
  }

	.container-box {
		margin-top: 20px;
	}

`

export const Wrapper = styled.div`

	h6 {
		font-size: 14px;
	}

	p {
		max-width: 400px;
		margin-top: -5px;
		word-break: break-all;
	}
`
