import React, { Fragment } from 'react';
import { names } from '../constantNamesReports';
import { formatNumber } from 'utils/formatNumber';

import {
  Title,
  SubTitle,
  ContainerInfoStudents,
  ContainerInfos,
  Divider,
  ContainerTitleInfos,
  ContainerJob,
  SubTitleInfos,
} from '../styled';

function GenericContainerReportsStudents(props) {
  const {
    careerObjective,
    title,
    enrolled,
    done,
    total,
    notWorkingInFormationArea,
    workingInFormationArea,
  } = props;
  const titles = {
    working: 'Objetivos de carreira dos alunos que trabalham',
    not_working: 'Objetivos de carreira dos alunos que não trabalham',
  };
  return (
    <div>
      <ContainerTitleInfos>
        <ContainerInfoStudents>
          <Title>{names[title]}</Title>
          <Title>{formatNumber(total)}</Title>
        </ContainerInfoStudents>

        <ContainerInfos>
          <SubTitle>{`Matriculados: ${formatNumber(enrolled)}`}</SubTitle>
          <SubTitle>{`Egressos: ${formatNumber(done)}`}</SubTitle>
        </ContainerInfos>

        {(notWorkingInFormationArea || workingInFormationArea) && (
          <ContainerInfoStudents subTitle>
            <SubTitleInfos>Área de Atuação</SubTitleInfos>

            <SubTitle subTitle>
              Trabalhando na área de formação:{' '}
              {formatNumber(workingInFormationArea)}
            </SubTitle>
            <SubTitle subTitle>
              Trabalhando em outras áreas:{' '}
              {formatNumber(notWorkingInFormationArea)}
            </SubTitle>
          </ContainerInfoStudents>
        )}

        {careerObjective && (
          <Fragment>
            <SubTitleInfos>{titles[title]}</SubTitleInfos>
            <ContainerJob isStudent>
              <ContainerInfoStudents subTitle>
                <SubTitle subTitle>
                  Busca uma oportunidade no mercado:{' '}
                  {formatNumber(careerObjective.unemploydLookingForAJob)}
                </SubTitle>
                <SubTitle subTitle>
                  Busca um estágio:{' '}
                  {formatNumber(careerObjective.internshipTrainee)}
                </SubTitle>
                <SubTitle subTitle>
                  Busca o primeiro emprego:{' '}
                  {formatNumber(careerObjective.firstJob)}
                </SubTitle>
              </ContainerInfoStudents>

              <ContainerInfoStudents subTitle>
                <SubTitle subTitle>
                  Deseja empreender: {formatNumber(careerObjective.ownBusiness)}
                </SubTitle>
                <SubTitle subTitle>
                  Deseja uma mudança de carreira:{' '}
                  {formatNumber(careerObjective.careerTransition)}
                </SubTitle>
                <SubTitle subTitle>
                  Permanecer como está (satisfeito):{' '}
                  {formatNumber(careerObjective.noCareerChanges)}
                </SubTitle>
              </ContainerInfoStudents>

              <ContainerInfoStudents subTitle>
                <SubTitle subTitle>
                  Busca oportunidade como trainee:{' '}
                  {formatNumber(careerObjective.opportunityAsTrainee)}
                </SubTitle>
              </ContainerInfoStudents>
            </ContainerJob>
          </Fragment>
        )}
      </ContainerTitleInfos>

      <Divider />
    </div>
  );
}

export default GenericContainerReportsStudents;
