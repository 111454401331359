import React, { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { JobOfferContext } from '../../../context/jobOfferContext';
import PreviewJobExternal from '../PreviewJobs/previewJobExternal';
import { PrevButton } from './styles';

export const PreviewButtonExternal = ({ children, courses, handleSubmit }) => {
  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const {
    externalJobOffer,
    ExternalJobRef,
    zIndex,
    setZIndex,
    selectedUniversities,
  } = useContext(JobOfferContext);

  const handleClickPreview = (e) => {
    e.preventDefault();
    setZIndex(0);
    openModalWithContent(
      <PreviewJobExternal
        externalJobOffer={externalJobOffer}
        formRef={ExternalJobRef}
        courses={courses}
        handleSubmit={handleSubmit}
        closeModal={closeModal}
        selectedUniversities={selectedUniversities}
      />,
      null,
      null,
      () => setZIndex(2000000000)
    );
  };

  return <PrevButton onClick={handleClickPreview}>{children}</PrevButton>;
};
