import styled from 'styled-components';

export const MainDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  .divText {
    display: flex;
    flex-direction: column;
    gap: 11px;
    align-items: center;
    @media screen and (max-width: 600px) {
      text-align: center;
      align-items: center;
    }
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`;
export const ContainerImg = styled.img`
  @media screen and (max-width: 600px) {
    max-width: 180px;
  }
`;
export const Title = styled.p`
  font-weight: 700;
  color: #009291;
  font-size: 34px; ;
`;
export const Text = styled.p`
  font-weight: 300;
  font-size: 23px;
  line-height: 1.2;
  text-align: center;
`;

export const ButtonBack = styled.button`
  max-width: 185px;
  height: 36px;
  cursor: pointer;
  color: white;
  padding: 6px;
  background-color: #009291;
  border: none;
  border-radius: 6px;
  display: flex;
  font-size: 17px;
  align-items: center;
  justify-content: center;
`;
