import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 215px;
  height: auto;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
`;

export const GrayGradient = styled.div`
  height: 58px;
  width: 215px;
  border-radius: 10px 10px 0 0;
  left: -1px;
  top: -1px;
  background: linear-gradient(
    180deg,
    rgba(151, 167, 185, 0.5) 0%,
    rgba(151, 167, 185, 0.1) 100%
  );
  position: absolute;
`;

export const TextName = styled.span`
  display: block;
  width: 100%;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;
  text-align: left;
  color: #009291;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  width: 150px;
  text-overflow: ellipsis;

  &:hover {
    color: #007272;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > span {
    color: #606062;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    > b {
      color: #009291;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const RescheduleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #009291;
  border-radius: 3px;
  color: #009291;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 1rem;
  background-color: transparent;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ConfirmedButton = styled.button`
  background-color: #009291;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 1rem;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  background-color: #000000;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? '0.8' : '0')};
  transition: opacity 0.3s;
  z-index: 999;
  left: 50%;
  margin-bottom: 30px;
  transform: translate(-50%, -50%);
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: calc(100% - 4px);
    left: 50%;
    transform: translateX(-50%);
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: #000000 transparent transparent transparent;
  }
`;

export const DetailsButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #009291;
  height: 40px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 1rem;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CanceledButton = styled.button`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: left;
  color: #da0505;
  border: none;
  margin: 10px 0;
  background-color: transparent;
  cursor: pointer;
  margin-top: 10px;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: #f09d51;
  gap: 5px;
  margin: 10px 0;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  background-color: #caeaea;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 9px;
  padding: 2rem;
  margin-top: 1rem;
  width: 100%;
  box-shadow: 0 0 18px 0 #0000001a;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;
`;

export const StudentImage = styled.img`
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin: ${({ margin }) => margin || '0'};
  border: 1px solid #606062;
  z-index: 1;
`;
