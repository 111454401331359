import styled from 'styled-components';

export const Information = styled.div`
  display: flex;
  gap: 3rem;
  height: ${(props) => (props.not_completed ? '61px' : '53px')};
  > span {
    display: inline-block;
    color: #777777;
    font-weight: 500;
    padding: 16px 0;
  }

  > .HeaderOne,
  .HeaderTwo,
  .HeaderThree,
  .HeaderFour {
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      display: inline-block;
    }
  }

  > .HeaderOne {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: ${(props) => (props.not_completed ? 10 : 9)};
  }

  > .HeaderTwo {
    flex: 1;
    justify-content: flex-end;
    min-width: 84px;
  }

  > .HeaderThree {
    flex: 1;
    justify-content: flex-end;
    padding-right: ${(props) => (props.not_completed ? '0' : '16px')};
  }

  > .HeaderFour {
    flex: 1;
    min-width: 69px;
    margin-left: 0;
    justify-content: flex-end;
  }

  img {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid #eef2f4;
`;
