import styled from 'styled-components';

export const SelectBox = styled.div`
  padding: 46px;
  padding-top: 0px;
  padding-bottom: ${({ formActived }) => formActived && '0px'};
`;

export const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
  color: #606062;

  padding: 0px 46px;
  padding-bottom: 41px;
`;

export const CustomCancellationForm = styled(CustomForm)`
  padding: 21px 46px;

  .paragraph-description {
    color: #606062;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    color: #606062;
  }

  .view-message {
    border: 1px solid #c4c4c4;
    padding: 14px;
    color: #8b8b8b;
    margin-top: 10px;
    font-size: 15px;

    border-radius: 4px;
  }

  .author-flag {
    background-color: #ffeded;
    color: #da0505;
    gap: 6px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    width: fit-content;

    padding: 6px;
    border-radius: 3px;

    margin-top: 22px;
  }
`;

export const ContainerSendButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  margin-top: 33px;
`;

export const CallToActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 146px;
  height: 38px;
  border-radius: 3px;
  border: ${({ outlined }) => (outlined ? '1px solid #009291' : 'none')};

  background-color: ${({ outlined }) => (outlined ? 'transparent' : '#009291')};

  font-size: 14px;
  font-weight: 700;
  color: ${({ outlined }) => (outlined ? '#009291' : '#fff')};

  cursor: pointer;
`;

export const CanceledButton = styled(CallToActionButton)`
  background-color: transparent;
  border: none;
  color: #da0505;
  padding: 0px;
  margin-right: 15px;
`;

export const ContainerInput = styled.div`
  width: 100%;
  display: flex;

  flex-direction: ${(props) => (props.isData ? 'row' : 'column')};
  gap: ${(props) => (props.isData ? '30px' : 'unset')};
`;

export const ContainerForms = styled.div`
  .status-interview {
    border: 1px solid;
    width: fit-content;
    margin: 18px 47px;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 13px;
  color: #f00;
  text-align: start;
  margin-top: 4px;
  font-weight: 400;
`;
