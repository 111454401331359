export function ExitToApp({ color }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1525 8.33674H4.24306C3.74791 8.33674 3.4025 8.05299 3.33936 7.60181C3.2778 7.16418 3.62999 6.72239 4.06984 6.68483C4.15384 6.67753 4.23889 6.67857 4.32341 6.67857H12.1129C12.1102 6.59042 12.0429 6.56173 12.0007 6.51948C11.4184 5.93372 10.8319 5.35161 10.2496 4.76533C9.97257 4.48628 9.91622 4.10186 10.0968 3.78264C10.1852 3.62163 10.3236 3.49379 10.4912 3.4185C10.6588 3.34321 10.8464 3.32455 11.0255 3.36535C11.1661 3.39511 11.2945 3.46655 11.3939 3.57035C12.5115 4.68831 13.6289 5.8061 14.7462 6.92372C15.0801 7.25859 15.089 7.73951 14.7561 8.07491C13.6333 9.20678 12.507 10.334 11.3772 11.4565C11.0641 11.7694 10.559 11.7392 10.2397 11.4106C9.9204 11.0819 9.90996 10.5927 10.2267 10.2724C10.8126 9.68144 11.4038 9.09463 11.9923 8.50627C12.0362 8.46193 12.0784 8.41446 12.1525 8.33674Z"
        fill={color}
      />
      <path
        d="M0.002866 7.50872C0.002866 6.13639 0.000257176 4.76405 0.002866 3.39223C0.0059966 1.75023 1.11162 0.396146 2.71605 0.0732743C3.23781 -0.0310463 3.76741 -0.00235735 4.29178 0.0284172C4.68467 0.0513677 4.99408 0.440482 4.99773 0.835857C4.99889 1.03235 4.93065 1.22294 4.80505 1.37408C4.67944 1.52522 4.50452 1.6272 4.31109 1.66207C4.0142 1.71058 3.71366 1.67616 3.41521 1.68137C3.20713 1.67699 2.99935 1.69925 2.79692 1.74762C2.13532 1.93279 1.72 2.44552 1.67826 3.13247C1.67199 3.2368 1.66991 3.34112 1.66991 3.44544C1.66991 6.15777 1.66991 8.8701 1.66991 11.5824C1.66276 11.7904 1.68382 11.9983 1.73252 12.2005C1.81244 12.5116 1.9904 12.7887 2.24007 12.9909C2.48973 13.193 2.79785 13.3095 3.11885 13.323C3.44965 13.3413 3.78202 13.3303 4.11386 13.3345C4.62258 13.3408 5.00086 13.6996 4.99825 14.1748C4.99564 14.65 4.61319 15.0036 4.10238 15.0021C3.51226 15.0021 2.9211 15.036 2.34299 14.8518C0.980655 14.4179 0.0122575 13.1342 0.00338748 11.705C-0.00391724 10.305 0.002866 8.90662 0.002866 7.50872Z"
        fill={color}
      />
    </svg>
  );
}
