import { Fragment, useEffect, useRef, useState } from 'react';
import BlockComponentForm from '../../blockComponentForm';
import { Form } from '@unform/web';
import { ButtonArrowRight } from '../../buttonArrowRight';
import { Span, ContainerFooter } from './style';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import * as Yup from 'yup';
import { schemaValidationStepOne } from './schemaValidation';
import PreviewEmail from '../../previewEmail';
import InputDesc from 'components/Opportunities/Inputs/InputDescription';

export default function StepOne() {
  const formRef = useRef(null);
  const { setFormValues, handleClickArrowNext, formData } = useSendEmail();

  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);

  const handleClickPreviewEmail = () => {
    setOpenPreviewEmail(true);
  };

  const handleClickClosePreviewEmail = () => {
    setOpenPreviewEmail(false);
  };

  useEffect(() => {
    const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];
    baseScroll.scrollTop = 0;
  }, []);

  const BlocksStepOne = [
    {
      blockTitle: 'Dados da campanha',
      components: [
        {
          type: 'input',
          name: 'name',
          label: 'Nome da campanha*',
        },
        {
          type: 'input',
          name: 'subject',
          label: 'Assunto do e-mail*',
        },
        {
          type: 'description',
          name: 'email_body',
          label: 'Corpo do e-mail*',
        },
      ],
    },
  ];

  const BlocksStepOne2 = [
    {
      blockTitle: '',
      components: [
        {
          type: 'betweenInput',
          inputs: [
            {
              name: 'button_name',
              label: '',
              props: {
                useCount: true,
                maxLength: 35,
                placeholder: 'Nome do botão',
              },
            },
            {
              name: 'button_link',
              label: '',
              props: { placeholder: 'Link do botão' },
            },
          ],
        },
      ],
    },
  ];

  const generateBlocks = () => {
    return BlocksStepOne.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        components={block.components}
      />
    ));
  };

  const generateBlocks2 = () => {
    return BlocksStepOne2.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        components={block.components}
      />
    ));
  };

  useEffect(() => {}, [formData]);

  const handleSubmit = async (data) => {
    formRef.current.setErrors({});

    try {
      const schema = schemaValidationStepOne;
      await schema.validate(data, {
        abortEarly: false,
      });
      setFormValues(data);
      handleClickArrowNext();
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <Fragment>
      <PreviewEmail
        open={openPreviewEmail}
        close={handleClickClosePreviewEmail}
        formRef={formRef}
      />
      <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
        {generateBlocks()}
        <Span>Adicionar botão de ação</Span>
        <p>
          Caso deseje, adicione um botão de ação no e-mail para direcionar seus
          estudantes para algum site em específico. Você poderá ver uma prévia
          clicando em "pré-visualizar e-mail".
        </p>
        {generateBlocks2()}
        <ContainerFooter>
          <p onClick={handleClickPreviewEmail}>Pré-visualizar e-mail</p>
          <ButtonArrowRight type="submit" />
        </ContainerFooter>
      </Form>
    </Fragment>
  );
}
