export default function DataNotFoundIcon ({color}) {
  return (
    <svg width="118" height="98" viewBox="0 0 118 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.67882 75.6789C4.01732 75.1908 1.58299 76.5231 0.566896 79.0158C0.456384 79.292 0.318244 79.556 0.192383 79.8262V82.1224C0.321313 82.3925 0.459453 82.6534 0.573035 82.9267C1.59527 85.4224 4.0296 86.7485 6.6911 86.2574C9.12236 85.8092 10.9826 83.5467 11.0011 81.0173C11.0164 78.4202 9.16534 76.1332 6.67882 75.6789ZM5.60133 82.899C4.51463 82.899 3.63667 82.0119 3.65509 80.9374C3.67044 79.906 4.51156 79.0587 5.54607 79.0311C6.63277 78.9973 7.52915 79.8569 7.5445 80.9374C7.55985 82.0272 6.69724 82.899 5.60133 82.899Z" fill={color}/>
      <path d="M95.7262 25.8107V14.7902H94.2251C78.9069 14.7902 63.5887 14.781 48.2705 14.8148C47.3434 14.8148 46.8339 14.5016 46.3488 13.7526C44.3473 10.6736 42.2691 7.64377 40.2645 4.56478C39.8563 3.94162 39.4265 3.72059 38.6867 3.72366C29.9194 3.74822 21.149 3.73901 12.3787 3.74208C12.0134 3.74208 11.6512 3.77278 11.3135 3.78813V73.38H36.854V70.0186H15.2612C15.3441 69.5519 15.384 69.2173 15.4669 68.892C18.6595 56.0941 21.8674 43.2992 25.0323 30.4952C25.2656 29.5497 25.6248 29.2489 26.6194 29.2489C51.5889 29.2796 76.5585 29.2734 101.525 29.2734H102.799C102.716 29.7155 102.676 30.0194 102.602 30.3141C99.3884 43.1856 96.159 56.0511 92.9695 68.9288C92.7516 69.8098 92.3832 70.0861 91.496 70.0431C89.9059 69.9694 88.3096 70.0216 86.7041 70.0216V73.469C89.3012 73.469 91.8214 73.4352 94.3417 73.4874C95.1583 73.5028 95.4376 73.2265 95.631 72.4437C98.3386 61.4938 101.089 50.5531 103.827 39.6124C104.969 35.0538 106.105 30.4952 107.278 25.8107H95.7262ZM14.7793 7.19558H17.4684C23.8934 7.19558 30.3185 7.214 36.7435 7.17409C37.5754 7.17102 38.0482 7.43195 38.4902 8.12265C39.8778 10.3053 41.339 12.4388 42.8585 14.7196H14.7793V7.19558ZM92.242 25.8261H90.7931C68.5096 25.8261 46.226 25.8322 23.9425 25.8076C22.9786 25.8046 22.5888 26.0501 22.3463 27.0355C19.9856 36.6501 17.5635 46.2493 15.1568 55.8515C15.0647 56.2138 14.9573 56.5699 14.856 56.929C14.81 56.9137 14.7608 56.9014 14.7117 56.8891V18.3297H92.242V25.8261Z" fill={color}/>
      <path d="M79.3749 69.4696C79.5591 69.1105 79.6758 68.8741 79.8047 68.6408C82.4938 63.7384 83.4056 58.532 82.4908 53.0064C80.3849 40.3006 68.2716 31.5333 55.4522 33.4519C42.6573 35.3644 33.6414 47.4071 35.3973 60.2419C37.6567 76.7573 55.8328 85.9144 70.4542 77.887C71.2001 77.4787 71.6053 77.5493 72.1917 78.1387C78.5338 84.5238 84.9067 90.8782 91.2703 97.2388C91.5589 97.5274 91.8628 97.8006 92.0746 98.0001C94.7453 95.3233 97.3331 92.7293 99.9885 90.0648C93.1613 83.2437 86.2635 76.3551 79.3749 69.4696ZM58.9793 77.3774C47.7378 77.3559 38.5991 68.1957 38.6298 56.9818C38.6574 45.7709 47.8422 36.6261 59.0561 36.6414C70.2608 36.6568 79.4302 45.8569 79.4087 57.0585C79.3872 68.2847 70.2178 77.4019 58.9793 77.3774ZM74.58 75.3882C75.4825 74.5071 76.4801 73.534 77.4625 72.5793C78.279 73.4235 79.2767 74.458 80.3358 75.557C79.4087 76.4749 78.4417 77.4357 77.5239 78.3505C76.517 77.3375 75.507 76.3183 74.58 75.3882ZM80.2038 80.8831C81.0633 80.0082 82.0242 79.035 83.1354 77.9084C87.1292 81.9329 91.1537 85.9942 95.215 90.0893C94.0976 91.1054 93.0662 92.0448 92.1759 92.8552C88.2466 88.9259 84.1791 84.8584 80.2038 80.8831Z" fill={color}/>
      <path d="M112.472 12.9483C109.47 12.936 107.051 15.3182 107.035 18.305C107.02 21.295 109.417 23.7324 112.392 23.7508C115.364 23.7723 117.822 21.3472 117.841 18.3818C117.856 15.398 115.453 12.9636 112.472 12.9483ZM112.361 20.2943C111.296 20.2544 110.44 19.315 110.492 18.2467C110.541 17.2153 111.41 16.3956 112.444 16.4018C113.516 16.4079 114.406 17.3197 114.384 18.3849C114.36 19.4439 113.417 20.3342 112.361 20.2943Z" fill={color}/>
      <path d="M99.4415 7.15872V10.8824H96.0126V7.21704H92.249V3.74513H95.9604V0H99.4292V3.73285H103.134V7.15872H99.4446H99.4415Z" fill={color}/>
      <path d="M27.5224 86.5879H31.3105V90.0046H27.6053V93.7927H24.1303V90.0567H20.4189V86.637H24.0658V82.9072H27.5224V86.5879Z" fill={color}/>
      <path d="M22.1372 9.3418H18.6162V12.6786H22.1372V9.3418Z" fill={color}/>
      <path d="M29.4954 9.35059H25.916V12.623H29.4954V9.35059Z" fill={color}/>
      <path d="M36.8672 9.33887H33.3369V12.6573H36.8672V9.33887Z" fill={color}/>
      <path d="M59.1793 40.5523C50.0774 40.4786 42.6087 47.843 42.5473 56.948C42.4859 65.9793 49.8349 73.3958 58.9153 73.4664C67.9742 73.534 75.3816 66.2433 75.4921 57.1506C75.6026 48.1592 68.1983 40.626 59.1793 40.5523ZM58.9859 70.0068C51.8241 69.9945 45.9639 64.1159 46.0069 56.991C46.0529 49.8292 51.9224 43.9843 59.0534 44.0089C66.1907 44.0304 72.057 49.9274 72.0355 57.0554C72.0171 64.208 66.16 70.0222 58.9859 70.0068Z" fill={color}/>
    </svg>

  )
}