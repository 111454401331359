import OportunidadesGroup from 'assets/img/OportunidadesGroup.svg';
import extensionProjectIcon from 'assets/Oportutinites/extension-projects.svg';
import Group448 from 'assets/Oportutinites/Group448.svg';
import {
  Content,
  coursesAndEvents,
  handleCreateExtensionProject,
} from '../utils/handleFunctions';

const randomId = Math.floor(Math.random() * 1000);

export const PrincipalCardsObjects = [
  {
    id: randomId,
    image: OportunidadesGroup,
    title: 'Eventos e Cursos',
    subtitle: 'Eventos, Cursos e Programas',
    handleCall: coursesAndEvents,
  },
  {
    id: randomId,
    image: Group448,
    title: 'Conteúdos',
    subtitle: 'Artigos, E-books, Trilhas, Séries, Comunicados e Desafios',
    handleCall: Content,
  },
  {
    id: randomId,
    image: extensionProjectIcon,
    title: 'Projetos de Extensão',
    subtitle: 'Aqui você pode cadastrar seus projetos de extensão',
    handleCall: handleCreateExtensionProject,
  },
];
