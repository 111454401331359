import styled from 'styled-components';

export const ModalBackground = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 12px !important;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  width: 100%;
  height: 88px;

  background-color: #009291;

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;

  > p {
    color: #fff;
    font-size: 22px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    margin-left: 33px;
    margin-top: 10px;
  }
`;

export const ButtonX = styled.button`
  width: 100%;
  max-width: 42px;
  height: 42px;

  background-color: #ededed;
  border: none;
  border-radius: 200px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivX = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  text-align: center;

  position: absolute;
  top: 25px;
  right: 25px;
`;

export const DivModal = styled.div`
  width: 673px;
  height: auto;
  background-color: #fff;
  border-radius: 12px;

  position: absolute;
  left: -20%;

  .container-box {
    display: flex;
    justify-content: space-between;

    padding: 0 33px;
    gap: 1.5rem;
  }

  .align-arrow {
    display: flex;
    align-items: flex-start;
    margin: 38px 33px 38px 33px;
  }
`;
export const Title = styled.h4`
  color: #009291;
  font-size: 17px;
  font-weight: bold;

  margin: 13px 33px 13px 33px;

  text-align: start;
`;
export const Contect = styled.div`
  padding: 20px;

  max-height: 700px;
  overflow-y: auto;

  text-align: left;
  h4 {
    font-size: 17px;
    font-weight: bold;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 3px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`;
