const ReportsStyle = (theme) => ({
  cardIcon: {
    marginTop: '-30px',
    width: 'auto',
    height: 'auto',
    '& h1': {
      margin: 0,
    },
  },
  cardTitle: {
    fontWeight: 500,
    minHeight: 48,
    margin: '15px 0 35px 0',
    '& span': {
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
      [theme.breakpoints.up('md')]: {
        fontSize: 21,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 24,
      },
    },
  },
  sectionTitle: {
    marginTop: 0,
    marginBottom: 36,
  },
  betaContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: 2,
    borderRadius: 16,
    borderColor: '#2f9190',
    right: 0,
    marginRight: 16,
    position: 'absolute',
    top: -32,
  },
  betaText: {
    fontSize: 16,
    fontWeight: 450,
    color: '#2f9190',
    margin: '0px 16px',
  },
  helpButton: {
    display: 'flex',
    alignCenter: 'center',
    justifyContent: 'center',
    right: 0,
    marginRight: 16,
    position: 'absolute',
    top: -32,
  },
  centerTitle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  list: {
    padding: 0,
    '& span': {
      fontSize: 20,
    },
  },
  listSmallCard: {
    '& span': {
      fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
      '& span': {
        fontSize: 14,
      },
    },
  },
  button: {
    marginBottom: 35,
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  width100: {
    width: '100%',
  },

  fullMaxWidth: {
    maxWidth: '100%',
  },
  margin0: {
    margin: 0,
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  alignCenter: {
    alignItems: 'center',
  },
  marginB35: {
    marginBottom: 35,
  },
  marginB15: {
    marginBottom: 15,
  },

  padding0: {
    padding: 0,
  },
  displayNone: {
    display: 'none',
  },
  column: {
    flexDirection: 'column',
  },
  indicatorCardIcon: {
    height: 56,
    width: 64,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '1rem',
  },
  generalIndicatorCardIcon: {
    height: 72,
    width: 80,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      width: 64,
      height: 56,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '24px',
      height: 72,
      width: 80,
    },
  },
  indicatorAverageCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  indicatorTypeName: {
    fontWeight: 500,
    [theme.breakpoints.down('xl')]: {
      minWidth: '118px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '130px',
    },
    '& span': {
      [theme.breakpoints.down('xl')]: {
        fontSize: 16,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 18,
      },
    },
    marginLeft: '-7px',
  },
  generalIndicatorTypeName: {
    fontWeight: 500,
    [theme.breakpoints.down('xl')]: {
      minWidth: '118px',
    },
    [theme.breakpoints.up('xl')]: {
      minWidth: '130px',
    },
    '& span': {
      [theme.breakpoints.up('xl')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('xl')]: {
        fontSize: 24,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('lg')]: {
        fontSize: 18,
      },
    },
    marginLeft: 0,
  },
  row: {
    flexDirection: 'row',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  rowDirection: {
    display: 'flex',
    justifyContent: 'center',
  },
  listGap: {
    marginTop: 12,
  },
  averageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
  },
});

export default ReportsStyle;
