import React, { useContext, useRef, useState } from 'react';
import {
  Container,
  Header,
  WrapperFields,
  SpaceNoteInput,
  WrapperSendButton,
} from './style';
import { Form } from '@unform/web';

import Input from 'components/UnformFields/inputField';
import NoteInput from 'components/UnformFields/noteInput';
import * as Yup from 'yup';

import CloseButton from '../closeButtonModal';
import { postCreateMatrix } from '../../../services/matrixServices';
import { MatrixContext } from '../../../contexts/MatrixContext';
import { useScrollToDiv } from '../../../../../../../JobOfferRebuild/hooks/scrollToCurrentDiv';
import ModalInformation from '../../informationModal';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import { matrixSchema } from '../modalEditAndDetails/schemaValidation';

export default function RegisterModal({ courseId, handleClickClose }) {
  const formRef = useRef(null);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);

  const { getMatrices } = useContext(MatrixContext);

  const configModalNameAlreadyExists = {
    bubbleText: 'Atenção!',
    ParagraphText: `O nome escolhido para esta matriz já existe na sua base de
     matrizes. Por favor, escolha outro nome.`,
    buttonConfirmText: 'OK, ENTENDI',
    showButtonCancel: false,
    icon: IconPerson,
  };

  const handleSubmit = async (data) => {
    formRef.current.setErrors({});

    try {
      const schema = matrixSchema;
      await schema.validate(data, {
        abortEarly: false,
      });

      const response = await postCreateMatrix({ ...data, course: courseId });
      if (response.status !== 201) {
        setModalConfirmOpen(true);
        return;
      }
      await getMatrices(courseId);
      handleClickClose();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const [modalRef] = useScrollToDiv(courseId);

  return (
    <Container ref={modalRef}>
      <ModalInformation
        modalOpenState={modalConfirmOpen}
        buttonConfirmFunction={() => setModalConfirmOpen(false)}
        config={configModalNameAlreadyExists}
      />
      <CloseButton onClick={handleClickClose} />
      <Header>Cadastro de matriz</Header>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <WrapperFields>
          <Input
            name="name"
            label="Nome da matriz*"
            placeholder="Digite aqui..."
            maxLength={30}
            useCount
          />
          <SpaceNoteInput>
            <NoteInput
              name="description"
              label="Observação*"
              placeholder="Digite aqui..."
              maxLength={500}
              useCount
            />
          </SpaceNoteInput>

          <WrapperSendButton>
            <button>Cadastrar matriz</button>
          </WrapperSendButton>
        </WrapperFields>
      </Form>
    </Container>
  );
}
