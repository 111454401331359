export function CircleUserIcon({ size = 12, color = '#009291', ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_15313_49129)">
        <path
          d="M6 0.5C2.6915 0.5 0 3.1915 0 6.5C0 9.8085 2.6915 12.5 6 12.5C9.3085 12.5 12 9.8085 12 6.5C12 3.1915 9.3085 0.5 6 0.5ZM4 11.082V11C4 9.897 4.897 9 6 9C7.103 9 8 9.897 8 11V11.082C7.387 11.3505 6.711 11.5 6 11.5C5.289 11.5 4.613 11.3505 4 11.082ZM8.9625 10.5255C8.7345 9.096 7.493 8 6 8C4.507 8 3.266 9.096 3.0375 10.5255C1.8025 9.614 1 8.149 1 6.5C1 3.743 3.243 1.5 6 1.5C8.757 1.5 11 3.743 11 6.5C11 8.149 10.1975 9.614 8.9625 10.5255ZM6 3C4.897 3 4 3.897 4 5C4 6.103 4.897 7 6 7C7.103 7 8 6.103 8 5C8 3.897 7.103 3 6 3ZM6 6C5.4485 6 5 5.5515 5 5C5 4.4485 5.4485 4 6 4C6.5515 4 7 4.4485 7 5C7 5.5515 6.5515 6 6 6Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_15313_49129">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
