import styled from 'styled-components';

export const TitleGreen = styled.h3`
  color: #009291;
  font-size: 17px;
  font-weight: 400;

  > p {
    font-size: 12px;
    color: #868687;
  }
`;
