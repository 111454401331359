import React, { useEffect, useRef, useState } from 'react';
import search from 'assets/FairAndEvents/search.svg';

import { useField } from '@unform/core';
import MaskedInput from '../MaskedInput';
import {
  ContainerLabelInput,
  SelectTime,
  UnInputLabel,
  NumberDiv,
  Error,
  UnInput,
} from './styled';

export default function HourPicker({
  label,
  name,
  maxLength,
  onChange,
  useCount,
  width,
  padding,
  onClick,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);
  const [selectedValue, setSelectedValue] = useState('');
  const [openSelectDiv, setOpenSelectDiv] = useState(false);

  const divRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        ref.value = value ? value : '';

        if (value?.includes(':')) {
          const newValue = value.replace(':', '');
          setSelectedValue(newValue);
        }
      },
    });
  }, [fieldName, registerField]);

  const generateTimeList = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  };

  const formatTimeInput = (input) => {
    let cleanInput = input.replace(/\D/g, '');

    cleanInput = cleanInput.substring(0, 4);

    const paddedInput = cleanInput.padEnd(4, '0');

    const formattedTime = paddedInput.replace(/(\d{2})(\d{2})/, '$1:$2');

    return formattedTime;
  };

  const handleChange = (event) => {
    const value = event.target.value;

    setSelectedValue(value);
  };

  const handleSelectTime = (time) => {
    setSelectedValue(time);
    setOpenSelectDiv(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef?.current && !divRef.current.contains(event.target)) {
        if (inputRef.current.value.includes('_')) {
          setSelectedValue('');
        }
        setOpenSelectDiv(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ContainerLabelInput ref={divRef} style={{ width: width }}>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <MaskedInput
        fieldRef={inputRef}
        mask="11:11"
        placeholderChar="HH:MM"
        value={selectedValue}
        onChange={handleChange}
        onClick={() => setOpenSelectDiv(true)}
        onBlur={clearError}
      >
        <UnInput error={error} />
      </MaskedInput>

      {openSelectDiv && (
        <SelectTime>
          {generateTimeList().map((time) => (
            <NumberDiv
              selected={time === selectedValue}
              onClick={() => handleSelectTime(time)}
              key={time}
            >
              {time}
            </NumberDiv>
          ))}
        </SelectTime>
      )}

      {error && <Error>{error}</Error>}
    </ContainerLabelInput>
  );
}
