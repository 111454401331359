import { wrapperIconsJobOffers } from './wrapperIconsJobOffers';

export const jobOffersTexts = () => {
  const {
    iconCanceled,
    iconCurriculumLiked,
    iconCurriculumNotLiked,
    iconCurriculumSent,
    iconExternal,
    iconRescheduled,
    iconScheduled,
    iconUnscheduled,
    iconExpired,
    iconFinished,
  } = wrapperIconsJobOffers();

  const getFinishedStatusIcon = (statusFinished) => {
    const icons = {
      job_finished: iconFinished,
      job_expired: iconExpired,
      unpublished: iconExpired,
      student_contracted: iconFinished,
    };

    return icons[statusFinished] || iconFinished;
  };

  const returnParagraphFinished = (statusFinished) => {
    const paragraphs = {
      job_finished: 'Vaga finalizada',
      job_expired: 'Vaga expirada',
      unpublished: 'Vaga despublicada',
      student_contracted: 'Vaga finalizada',
    };

    return paragraphs[statusFinished] || 'Vaga finalizada';
  };

  const getStatusIcon = (status) => {
    const icons = {
      curriculum_sent: iconCurriculumSent,
      unscheduled: iconUnscheduled,
      scheduled: iconScheduled,
      rescheduled: iconRescheduled,
      canceled: iconCanceled,
      liked: iconCurriculumLiked,
      not_liked: iconCurriculumNotLiked,
    };

    return icons[status] || iconCurriculumSent;
  };

  const returnParagraph = (status, data) => {
    const paragraphs = {
      curriculum_sent: 'A empresa irá avaliar seu currículo',
      unscheduled: 'Confirme sua presença em “detalhes”',
      scheduled: `Entrevista agendada para ${data}`,
      rescheduled: 'Confirme sua presença em “detalhes”',
      canceled: 'A entrevista foi cancelada',
      liked: 'Currículo marcado como interessante',
      not_liked: 'Veja o feedback em “detalhes”',
    };

    return paragraphs[status] || 'A empresa irá avaliar seu currículo';
  };

  const returnStatus = (status) => {
    const statusMessages = {
      curriculum_sent: 'Currículo enviado',
      unscheduled: 'Convite para entrevista',
      scheduled: 'Entrevista agendada',
      rescheduled: 'Entrevista reagendada',
      canceled: 'Entrevista cancelada',
      liked: 'Seu currículo foi curtido',
      not_liked: 'Currículo não curtido',
    };

    return statusMessages[status] || 'Currículo enviado';
  };

  const getButtonLabelOne = (status) => {
    const labelOne = {
      curriculum_sent: 'Detalhes da vaga',
      unscheduled: 'Detalhes da entrevista',
      scheduled: 'Detalhes da entrevista',
      rescheduled: 'Detalhes da entrevista',
      canceled: 'Detalhes do cancelamento',
      liked: 'Detalhes da vaga',
      not_liked: 'Detalhes da vaga',
      default: 'Detalhes da vaga',
    };

    return labelOne[status] || 'Detalhes da vaga';
  };

  const getButtonLabelTwo = (status) => {
    const labelTwo = {
      curriculum_sent: 'Cancelar candidatura',
      unscheduled: 'Detalhes da vaga',
      scheduled: 'Detalhes da vaga',
      rescheduled: 'Detalhes da vaga',
      canceled: 'Detalhes da vaga',
      liked: 'Cancelar candidatura',
      not_liked: 'Cancelar candidatura',
      default: 'Cancelar candidatura',
    };

    return labelTwo[status] || 'Cancelar candidatura';
  };

  return {
    returnParagraph,
    returnStatus,
    getStatusIcon,
    getButtonLabelOne,
    getButtonLabelTwo,
    getFinishedStatusIcon,
    returnParagraphFinished,
  };
};
