export function List({ color }) {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0833 0.466309H4.41667C2.11917 0.466309 0.25 2.33547 0.25 4.63297V14.633C0.25 16.9305 2.11917 18.7996 4.41667 18.7996H16.0833C18.3808 18.7996 20.25 16.9305 20.25 14.633V4.63297C20.25 2.33547 18.3808 0.466309 16.0833 0.466309ZM18.5833 14.633C18.5833 16.0113 17.4617 17.133 16.0833 17.133H4.41667C3.03833 17.133 1.91667 16.0113 1.91667 14.633V4.63297C1.91667 3.25464 3.03833 2.13297 4.41667 2.13297H16.0833C17.4617 2.13297 18.5833 3.25464 18.5833 4.63297V14.633ZM16.0833 5.46631C16.0833 5.92631 15.71 6.29964 15.25 6.29964H9.41667C8.95667 6.29964 8.58333 5.92631 8.58333 5.46631C8.58333 5.00631 8.95667 4.63297 9.41667 4.63297H15.25C15.71 4.63297 16.0833 5.00631 16.0833 5.46631ZM6.91667 5.46631C6.91667 6.15631 6.35667 6.71631 5.66667 6.71631C4.97667 6.71631 4.41667 6.15631 4.41667 5.46631C4.41667 4.77631 4.97667 4.21631 5.66667 4.21631C6.35667 4.21631 6.91667 4.77631 6.91667 5.46631ZM16.0833 9.63297C16.0833 10.093 15.71 10.4663 15.25 10.4663H9.41667C8.95667 10.4663 8.58333 10.093 8.58333 9.63297C8.58333 9.17297 8.95667 8.79964 9.41667 8.79964H15.25C15.71 8.79964 16.0833 9.17297 16.0833 9.63297ZM6.91667 9.63297C6.91667 10.323 6.35667 10.883 5.66667 10.883C4.97667 10.883 4.41667 10.323 4.41667 9.63297C4.41667 8.94297 4.97667 8.38297 5.66667 8.38297C6.35667 8.38297 6.91667 8.94297 6.91667 9.63297ZM16.0833 13.7996C16.0833 14.2596 15.71 14.633 15.25 14.633H9.41667C8.95667 14.633 8.58333 14.2596 8.58333 13.7996C8.58333 13.3396 8.95667 12.9663 9.41667 12.9663H15.25C15.71 12.9663 16.0833 13.3396 16.0833 13.7996ZM6.91667 13.7996C6.91667 14.4896 6.35667 15.0496 5.66667 15.0496C4.97667 15.0496 4.41667 14.4896 4.41667 13.7996C4.41667 13.1096 4.97667 12.5496 5.66667 12.5496C6.35667 12.5496 6.91667 13.1096 6.91667 13.7996Z"
        fill={color}
      />
    </svg>
  );
}
