import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form } from '@unform/web';
import {
  Content,
  SelectBox,
  SimpleButton,
  ContainerForms,
  ContainerButton,
  Phrase,
} from './styled';
import NoteInput from 'components/UnformFields/noteInput';
import { finalizationVacancyAdapter } from '../utils/finalizationVacancyAdapter';
import {
  finishHistoryJob,
  finishJob,
} from 'views/StaffPages/jobOpeningsRebuild/services'; //ies
import {
  finishJobHistory as finishJobHistoryCompany,
  finishJob as finishJobCompany,
} from 'views/JobOfferRebuild/services'; //company
import SimpleReactSelect from 'components/UnformFields/SimpleReactSelect';
import {
  customCommonStyles,
  selectTheme,
} from 'components/ListCandidates/Styles/BaseComponent';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import * as Yup from 'yup';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import {
  loadJobsProgressCompany,
  reloadProgressJobs,
} from '../utils/handleReloadJobs';
import SearchableSelectUn from 'views/JobOfferRebuild/Components/Form/searchableSelect';

export const ModalStepsFinalizeJob = (props) => {
  const {
    jobID,
    status,
    closeModal,
    university,
    company,
    setModalFinish,
    optionsCandidate,
    setListJobs,
    setJobsInstitution,
    setListJobsCompany,
    setListJobsCompanyHistory,
    companyFilters,
    universityFilters,
    dataFunction,
  } = props;
  const [inputCandidateVisible, setInputCandidateVisible] = useState(false);
  const [inputFeedbackVisible, setInputFeedbackVisible] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [formData, setFormData] = useState(null);

  const [contractJob, setContractJob] = useState(false);
  const [systemJob, setSystemJob] = useState(false);
  const [externalJob, setExternalJob] = useState(false);

  const formRef = useRef()

  const getSchema = () => {
    return Yup.object().shape({
      feedback: inputFeedbackVisible
        ? Yup.string().required('Campo obrigatório')
        : Yup.string()
    });
  };

  const schema = useMemo(getSchema, [inputFeedbackVisible]);

  const options = [
    {
      id: 1,
      value: 'withoutCandidates',
      label: 'A vaga não foi preenchida por um candidato do sistema',
    },
    {
      id: 2,
      value: 'noContract',
      label: 'A vaga foi finalizada sem contratação',
    },
    {
      id: 3,
      value: 'candidates',
      label: 'A vaga foi preenchida por um candidato do sistema',
    },
  ];

  const loadJobsData = async (params = '') => {
    setListJobs(null);
    dataFunction.handlePageChange('limit=0', params);
  };

  const loadHistoryJobsData = async (params) => {
    setJobsInstitution(null);
    dataFunction.handlePageChangeInstitution('limit=0', params);
  };

  const handleCandidateSelect = (option) => {
    const selectedValues = option.map((opt) => opt.value);
    setSelectedCandidate(selectedValues);

    setFormData((prevData) => ({ ...prevData, candidates: selectedValues }));

    if (selectedValues && selectedValues.length > 0) {
      setInputFeedbackVisible(true);
    } else {
      setInputFeedbackVisible(false);
    }

    if (selectedValues.length > 0) {
      setSystemJob((prevCandidates) => [
        ...(Array.isArray(prevCandidates) ? prevCandidates : []),
        ...selectedValues,
      ]);
    }
  };

  const handleProcessFinalized = (option) => {
    setSelectedOption(option);
    if (!option) return;

    const selectedId = option.id;

    if (selectedId === 1 || selectedId === 2) {
      setExternalJob(true);
      setInputCandidateVisible(false);
      setSelectedOption(selectedOption);
      setInputFeedbackVisible(false)

      setContractJob(false);
      setSystemJob(false);
    }

    if (selectedId === 2) {
      setSystemJob(true);
      setInputCandidateVisible(false);
      setSelectedOption(selectedOption);
      setInputFeedbackVisible(false)

      setExternalJob(false);
      setContractJob(false);
    }

    if (selectedId === 3) {
      if (selectedCandidate) {
        setContractJob((prevCandidates) => [
          ...prevCandidates,
          selectedCandidate,
        ]);
      }
      setInputCandidateVisible(true);
      setSelectedOption(selectedOption);

      setExternalJob(false);
      setSystemJob(false);
    }
    setFormData({ ...formData, selectedId });
  };

  const handleSubmit = async (data) => {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const has_contracting = selectedOption?.id === 3;
      const candidates = data.candidates?.filter((candidate) => candidate !== null) || [];

      const dataAdapter = finalizationVacancyAdapter({
        feedback: data.feedback,
        candidates,
        statusSelect: data.statusSelect || [],
        has_contracting,
        selected_option_id: selectedOption?.id,
      });

      if (university) {
        const queryParams = queryParamsFromFilterObject(universityFilters);

        if (status) {
          await finishHistoryJob(jobID, dataAdapter);
          loadHistoryJobsData(queryParams);
        } else {
          await finishJob(jobID, dataAdapter);
          loadJobsData(queryParams);
        }
      }

      if (company) {
        const queryParams = queryParamsFromFilterObject(companyFilters);

        if (status) {
          await finishJobHistoryCompany(jobID, dataAdapter);
          loadJobsProgressCompany(
            setListJobsCompanyHistory,
            dataFunction,
            queryParams
          );
        } else {
          await finishJobCompany(jobID, dataAdapter);
          reloadProgressJobs(setListJobsCompany, dataFunction, queryParams);
        }
      }

      customSnackbar('Vaga finalizada com sucesso!', 'confirmation');
      setModalFinish(true);
      closeModal(true);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      customSnackbar(
        'Não foi possível finalizar a vaga. Tente novamente mais tarde!',
        'error'
      );
    }
  };

  useEffect(() => {
    if (systemJob || externalJob || selectedCandidate !== null) {
      setIsButtonEnabled(false);
    } else {
      setIsButtonEnabled(true);
    }
  }, [systemJob, externalJob]);

  return (
    <Content>
      <ContainerForms>
        <Form onSubmit={handleSubmit} ref={formRef}>
          <SelectBox>
            <Phrase>Esperamos que tenha tido êxito em seu processo seletivo!</Phrase>
            <SimpleReactSelect
              name="statusSelect"
              placeholder="Selecione"
              label="Para concluir o processo de finalização da vaga, poderia nos informar sobre o status atual dela?"
              options={optionsCandidate.length >= 1 ? options : options.filter((option) => option.value !== 'candidates')}
              onChange={handleProcessFinalized}
              theme={selectTheme}
              styles={customCommonStyles}
            />
          </SelectBox>

          {inputCandidateVisible && (
            <SearchableSelectUn
              name="candidates"
              label="Informe o nome do candidato*"
              placeholder="Selecione"
              options={optionsCandidate}
              onChange={handleCandidateSelect}
              isClearable
              isMulti
            />
          )}

          {inputFeedbackVisible && (
            <SelectBox>
              <NoteInput
                name="feedback"
                label="Envie um feedback para o candidato selecionado no processo."
                placeholder="Digite aqui" />
            </SelectBox>
          )}

          <ContainerButton isStepTwo>
            <SimpleButton disabled={isButtonEnabled} type="submit">Finalizar vaga</SimpleButton>
          </ContainerButton>
        </Form>
      </ContainerForms>
    </Content>
  );
};
