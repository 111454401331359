import styled, { keyframes } from 'styled-components';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const StudentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1029;
  width: 100%;
  background-color: #eee;
  position: absolute;
`;

export const HomeDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  row-gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 3rem 0 3rem;

  > span {
    font-size: 18px;
    font-weight: 400;
    color: #606062;

    strong {
      font-weight: 700;
    }
  }

  div.limit-select-container {
    position: relative;
    margin-top: -3rem;

    @media (max-width: 1290px) {
      display: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  margin: 16px 32px;
  position: relative;

  > div:first-child {
    margin-right: auto;
  }
`;

export const HeaderButton = styled.button`
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #949494;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const CategoriesNavContainer = styled.nav`
  margin: 1rem 3rem;
  background-color: #cfcfcf;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;

  > div {
    display: flex;
    gap: 10px;
    overflow-x: hidden;
    scroll-behavior: smooth;
    margin: 0px 20px;

    @media (min-width: 1870px) {
      width: 100%;
      margin: 0px;
    }
  }
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${(props) => (props.active ? '#009291' : '#fff')};
  border-radius: 8px;
  height: 56px;
  padding: 0 16px;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  flex: 1;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2px;

    strong {
      font-weight: 700;
      color: ${(props) => (props.active ? '#fff' : '#4B4B4D')};
      font-size: 14px;
      white-space: nowrap;
    }

    span {
      color: ${(props) => (props.active ? '#fff' : '#4B4B4D')};
      font-size: 12px;
      white-space: nowrap;
    }
  }

  &:hover {
    filter: brightness(0.95);
  }
`;

export const ListContainer = styled.div`
  margin: 2rem 3rem 6rem 3rem;
  flex-direction: column;
  display: flex;
  gap: 2rem;
`;

export const ListContent = styled.div`
  background-color: #fff;
  border-radius: 10px;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;

  div.limit-container {
    position: relative;

    @media (max-width: 1048px) {
      margin-top: 6rem;
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > div {
    justify-content: center;

    @media (max-width: 1080px) {
      justify-content: flex-start;
    }
  }
`;

export const selectPagination = {
  selectLabel: {
    whiteSpace: 'nowrap',
  },

  formControl: {
    width: 120,
    position: 'absolute',
    right: 0,
    top: 0,
  },

  selectInput: {
    minWidth: 120,
    textAlign: 'center',
  },

  cssLabel: {
    '&$cssFocused': {
      color: dnaColor,
    },
  },

  cssFocused: {},
};

export const EmptyList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  color: #777;
  font-size: 14px;
`;

export const ListHeader = styled.header`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #e6e6e6;
  padding: 2rem;
  gap: 1rem;

  > span {
    font-size: 12px;
    color: #777777;
    width: 100%;
    text-align: right;
    margin-bottom: -1rem;

    > strong {
      font-weight: 700;
    }
  }
`;

export const ListHeaderActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  gap: 1.5rem;

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    margin-left: auto;

    @media (max-width: 1065px) {
      width: 100%;
    }
  }
`;

export const ListHeaderCheckbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: #f5f5f5;
  accent-color: #009291;
  color: #4b4b4d;
  font-size: 13px;
  gap: 8px;
  row-gap: 8px;
  cursor: pointer;
  white-space: nowrap;

  input[type='checkbox'] {
    cursor: pointer;
    width: 1rem;
    height: 1rem;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const OutlinedButton = styled.button`
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
  font-size: 13px;
  color: #009291;
  border-radius: 6px;
  border: 1px solid #009291;
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;

  svg {
    flex: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const StudentTileContainer = styled.div`
  display: flex;
  margin: 0 0.5rem;
  padding: 2rem 1.5rem;
  gap: 2rem;
  flex-wrap: wrap;

  &:not(:last-child) {
    border-bottom: 1px solid #e6e6e6;
  }

  > input[type='checkbox'] {
    background-color: #f5f5f5;
    accent-color: #009291;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
`;

export const StudentData = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  row-gap: 1rem;

  > img {
    height: 75px;
    width: 75px;
    border-radius: 50%;
  }
`;

export const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > strong {
    font-size: 17px;
    font-weight: 700;
    color: #009291;
    text-decoration: underline;
    margin-bottom: 0.5rem;
  }

  > p#code {
    font-size: 12px;
    margin: -12px 0 0 0;
  }

  > div {
    line-height: 1.5;
  }

  > span,
  p {
    font-size: 14px;
    color: #4b4b4d;
  }

  > img {
    height: 16px;
    width: 16px;
  }

  > div.phone-email {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    gap: 0.5rem;

    > div {
      display: flex;
      flex-wrap: nowrap;
      gap: 4px;

      > p {
        margin: 0 8px 0 0;
        white-space: nowrap;
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  gap: 0.5rem;
  row-gap: 0.5rem;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #fff;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #009291;
  font-size: 14px;
  font-weight: 500;
  gap: 4px;
  white-space: nowrap;

  &:not(:disabled):hover {
    background-color: #f5f5f5;
  }

  &:disabled {
    cursor: not-allowed;
    color: #949494;
  }
`;

export const Flag = styled.div`
  width: 46.1px;
  height: 22.65px;
  left: 128px;
  border-radius: 3.32px;
  background-color: #3492e9;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`;
const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const ImageSkeleton = styled(Skeleton)`
  height: 75px;
  width: 75px;
  border-radius: 50%;
`;

export const SkeletonBox = styled(Skeleton)`
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;

export const DownloadInProgressIndicator = styled.div`
  display: flex;
  position: fixed;
  left: calc(260px + 2rem);
  bottom: 2rem;
  height: 48px;
  background-color: #777777;
  color: #fff;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 4px;
  gap: 1rem;

  @media (max-width: 960px) {
    left: 2rem;
  }
`;

export const Button = styled.button`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  width: 50px;
  background-position: center;
  cursor: pointer;
  height: 40px;
  background-repeat: no-repeat;
  border: none;
  background-color: #009291;
  border-radius: 50%;
  &:active {
    transform: scale(0.9);
  }

  @media (min-width: 1870px) {
    display: none;
  }
`;
