import styled from 'styled-components';

export const Modal = styled.div`
  width: 500px;
  height: 422px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  background: var(--white, #fff);
  position: relative;
  padding: 2rem;

  > main {
    margin-top: 28px;

    > p {
      color: #606062;
      font-size: 15px;
      line-height: 21px;
      text-align: justify;
      font-family: 'Roboto', sans-serif;
      margin-bottom: 1rem;
    }
  }

  > span {
    display: flex;
    background-color: #eef2f4;
    border-radius: 4px;
    padding: 1rem;

    > p {
      color: #606062;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;
      font-style: normal;
      line-height: 18px;
      text-align: justify;
      margin: 0;
    }
  }
`;

export const ModalHeader = styled.header`
  display: flex;
`;

export const WalIcon = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #009291;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: #009291;
  margin-left: 1rem;
  border-radius: 4px;
  width: 200px;
  align-items: center;
  padding: 0 1rem;
  position: relative;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: #009291;
    position: absolute;
    border-radius: 1px;
    left: -5px;
    top: 25px;
    transform: rotate(-45deg);
  }

  > p {
    color: #fff;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    margin: 0;
  }
`;
