/* eslint-disable camelcase */
const specialInformationAdapter = ({
  showDisability,
  showGenderIdentity,
  showColorOrRace,
}) => {
  const specialInformation = {
    show_special_needs: showDisability,
    show_gender_identity: showGenderIdentity,
    show_color_or_race: showColorOrRace,
  };
  return specialInformation;
};
const specialNeedsOptionsAdapter = (special_needs) => {
  const options = special_needs.map((item) => {
    item.value = null;
    return item;
  });

  return options;
};

export { specialInformationAdapter, specialNeedsOptionsAdapter };
