import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import {
  Container,
  Box,
  WhiteBox,
  H4Header,
  SectionMail,
  SectionHeader,
  MainContent,
} from './style';
import ReactHtmlParser from 'react-html-parser';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import { useState, useEffect } from 'react';

export const emailPart = (data) => {
  const [dataUniversity, setDataUniversity] = useState({});

  const callDataUniversity = async () => {
    const data = await sendEmailApi.getUniversityData();
    setDataUniversity(data);
  };

  const canSeeButton = data?.button_name && data?.button_link;

  useEffect(() => {
    callDataUniversity();
  }, []);

  return (
    <SectionMail color={dataUniversity?.color}>
      <SectionHeader color={dataUniversity?.color}>
        <img alt="logoIes" src={dataUniversity?.logo} />
      </SectionHeader>

      <MainContent color={dataUniversity?.color}>
        <p>{ReactHtmlParser(data?.email_body)}</p>

        {canSeeButton && (
          <div className="actionButton">
            <button>{data?.button_name}</button>
          </div>
        )}

        <div className="sendBy">
          <span>
            Enviado pelo <strong>Escritório de Carreira</strong>
            <br />
            Todos os direitos reservados{' '}
          </span>
        </div>

        <div className="spamInfo">
          <span>
            Só te pedimos apenas uma coisa, <strong>não</strong> jogue a gente
            no spam!
          </span>
          <span>
            Se não quiser receber mais os nossos e-mails,{' '}
            <span className="spamClick">clique aqui</span>. Vamos ficar tristes,
            mas respeitaremos sua decisão, ok?
          </span>
        </div>
      </MainContent>

      <footer>
        <div>
          <span>
            Tecnologia by <a>Workalove</a>
          </span>
          <span>O maior banco de talentos universitários do Brasil</span>
        </div>
      </footer>
    </SectionMail>
  );
};

export default function PreviewEmail({ open, close, formRef }) {
  if (!open) {
    return null;
  }

  const data = formRef?.current?.getData();

  return (
    <Container>
      <Box>
        <WhiteBox>
          <header>
            <H4Header> Pré-visualizar e-mail</H4Header>
            <CloseButton onClick={close} />
          </header>

          {emailPart(data)}
        </WhiteBox>
      </Box>
    </Container>
  );
}
