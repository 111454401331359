import { authenticatedAPI } from 'services/api';

const getAttractions = async (pageQS, filters) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/students/me/attractions/?${pageQS}&is_recommended=false&${
        filters || ''
      }`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const concludeAttraction = async (id) => {
  const isFinished = { is_finished: true };
  const response = authenticatedAPI
    .post(`/api/internal/students/me/attractions/${id}/finished`, isFinished)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFilterOptions = async (queryParams) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/students/me/attractions/?${queryParams && queryParams}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAttractionsRecommended = async (pageQS, filters) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/students/me/attractions/?${pageQS}&is_recommended=true&${
        filters || ''
      }`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAttractionsOptions = async (typeString) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/students/me/attractions/?is_recommended=false${typeString}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getAttractionsRecommendedOptions = async (typeString) => {
  const response = authenticatedAPI
    .options(
      `/api/internal/students/me/attractions/?is_recommended=true${typeString}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const newReaction = async (id, reaction) => {
  const response = authenticatedAPI
    .patch(`/api/internal/students/me/attractions/${id}/reaction`, reaction)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const newRecommend = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/attractions/${id}/recommendation`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const cancelRecommendation = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/students/me/attractions/${id}/recommendation`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const downloadPdf = (id) => {
  const { baseURL } = authenticatedAPI.defaults;
  const Url = `${baseURL}/api/internal/students/me/attractions/${id}/download`;
  return Url;
};

const getImagesAttraction = (query) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/attractions-image/?${query}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  getAttractions,
  newReaction,
  newRecommend,
  cancelRecommendation,
  getAttractionsRecommended,
  getAttractionsOptions,
  getAttractionsRecommendedOptions,
  concludeAttraction,
  getFilterOptions,
  downloadPdf,
  getImagesAttraction,
};
