import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1101;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: 100%;
  height: 100%;
  max-width: 800px;
  max-height: 583px;
  border-radius: 8px;
  background-color: ${({ universityColor }) =>
    universityColor ? universityColor : '#009291'};
  position: relative;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
    height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  height: 100%;

  .left-side-image {
    width: 55%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-size: cover;
    background-image: url(${(props) => props.image});

    position: absolute;
  }

  .left-side-default {
    width: 55%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  .box-text {
    width: 273px;
  }

  .box-text h3 {
    color: #fff;
    font-size: 25px;
    margin-top: 10px;
  }

  .box-text span {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
  }

  .right-side {
    width: 50%;
    height: 100%;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    position: absolute;
    right: 0;
  }

  img {
    max-width: 800px;
    height: 100%;
  }
`;

export const ByText = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;

  strong {
    font-size: 12px;
    line-height: 26px;
  }
`;

export const LoginArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 91px 90px;
  transition: 0.5s;

  @media (max-height: 528px) {
    transition: 0.5s;
    margin: 54px 90px;
  }
`;

export const Logo = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -6px;

  margin-bottom: 14px;

  width: 130px;

  @media (max-height: 600px) {
    margin-bottom: ${(props) => (props.isCompany ? '0' : '23px')};
  }
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 23px;

  @media (max-height: 600px) {
    margin-bottom: ${(props) => (props.isCompany ? '0' : '23px')};
  }
`;

export const InputArea = styled.div`
  margin-bottom: 14px;

  span {
    font-size: 13px;
    color: ${({ universityColor }) =>
      universityColor ? universityColor : '#009291'};
    font-weight: 500;
  }

  input {
    border: 1px solid #979797;
    border-radius: 3px;
    width: 100%;
    max-width: 254px;
    height: 32px;
    font-size: 11px;
    padding: 8px;
  }

  input::placeholder {
    color: #c4c4c4;
  }

  @media (max-height: 600px) {
    margin-bottom: ${(props) => (props.isCompany ? '0' : '23px')};
  }
`;

export const Text = styled.p`
  font-size: 11px;
  color: ${({ error, link, universityColor }) =>
    error ? '#DA0505' : link ? universityColor : '#606062'};
  line-height: 14px;
  margin-top: ${({ error }) => error && '6px'};
  font-weight: 400;
  margin-bottom: 5px;
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};
`;

export const SendButton = styled.div`
  width: 100%;
  max-width: 254px;
  height: 34px;
  background-color: ${({ universityColor }) =>
    universityColor ? universityColor : '#009291'};

  font-size: 15px;
  font-weight: 400;
  color: #fff;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 29px;
`;

export const CloseButton = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  display: flex;
`;

export const CreateAccountDiv = styled.div`
  margin-top: 10px;
  width: 100%;
  justify-content: center;
  .divider {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      margin: 0px 12px;
    }
    hr {
      background-color: #e0e0e0;
      height: 1px;
      flex: 1;
    }
  }

  @media (max-height: 650px) {
    margin-top: 0.2em;
  }
`;

export const ButtonOutlined = styled.button`
  width: 210px;
  max-width: 100vw;
  height: 34px;
  border: solid 1px;
  border-color: ${(props) => props.color};
  color: ${(props) => props.color};
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
  background-color: #fff;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 0px auto;
  transition: all 0.2s;
  cursor: pointer;
  @media (max-width: 850px) {
    width: 70vw;
    margin-top: 20px;
  }
  &:hover {
    filter: brightness(120%);
  }
  @media (max-height: 650px) {
    margin-top: 0.2em;
  }
`;
