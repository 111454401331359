import React, { useContext, useState } from 'react';
import {
  ContainerInfo,
  Container,
  BackButton,
  FragmentColor,
  Box,
  BoxModal,
} from '../../JobDetails/styled';
import { ContainerCards, ContainerGenericMessage } from '../styled';
import { IoIosArrowBack } from 'react-icons/io';
import GenericCardsJobOffers from '../Cards';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import { ContainerButtonsPagination } from '../../styled';
import PaginationSeeMore from '../../PaginationLoadMore';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import { HeaderTabs, ButtonMatch } from '../../Tabs/styled';
import FilterInputName from '../../FilterModal/FilterName';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';
import ModalMatchInformation from '../../Modals/ModalMatchInfo';
import { DetailsJobOffer } from '../../JobDetails';
import GenericMessage from 'components/GenericMessage';
import ModalInformation from 'components/informationModal';
import { configLocationModal } from '../../utils/wrapperTextsConfig';

export default function MoreFavoriteCompanyJobs(props) {
  const {
    universityColor,
    handleCloseModal,
    dataFavoriteJobOffers,
    candidateJobOffer,
    jobIdCandidatureBeingProcessed,
    fetchListFavoriteJobOffers,
    filterData,
    onClickEdit,
    openLocalizationModal,
    handleCloseModalLocalization,
    selectJobId,
    openModalLocalization,
    location,
  } = props;
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedJobMatch, setSelectedJobMatch] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [
    jobIdBeingProcessedAsFavorite,
    setJobIdBeingProcessedAsFavorite,
  ] = useState(null);
  const { visibleItems, setVisibleItems } = useContext(JobOffersContext);
  const [openModalMatch, setOpenModalMatch] = useState(false);
  const [search, setSearch] = useState('');
  const { IconInformation } = wrapperIconsJobOffers();

  const onClickModal = () => {
    setOpenModalMatch(true);
  };

  const handleSeeMore = () => {
    setVisibleItems((prev) => prev + 20);
  };

  const onClickDetails = (jobId, match) => {
    setOpenDetailsModal(true);
    setSelectedJobMatch(match);
    setSelectedJobId(jobId);
  };

  const handleCloseDetailsModal = () => {
    setOpenDetailsModal(false);
  };

  const handleFavoriteToggle = async (jobId, isFavorited) => {
    try {
      setJobIdBeingProcessedAsFavorite(jobId);
      if (isFavorited === false) {
        const data = { job_offer: jobId };
        await JobOffersApi.fetchActionToFavoriteJobs(data);
        await fetchListFavoriteJobOffers(false);
      }
      if (isFavorited === true) {
        await JobOffersApi.fetchActionToUnfavoriteJobs(jobId);
        await fetchListFavoriteJobOffers(false);
      }
    } finally {
      setJobIdBeingProcessedAsFavorite(null);
    }
  };

  const returnCardJobs = (dataFavoriteJobOffers, visibleItems) => {
    const filteredData = filterData(dataFavoriteJobOffers, search);

    if (filteredData.length === 0) {
      return (
        <ContainerGenericMessage>
          <GenericMessage
            title="Nenhuma vaga encontrada."
            subtitle="Não encontramos nenhuma vaga de empresa favoritada com este nome."
          />
        </ContainerGenericMessage>
      );
    }

    if (dataFavoriteJobOffers?.length >= 1) {
      return filterData(dataFavoriteJobOffers, search)
        .slice(0, visibleItems)
        .map((job) => (
          <div key={job}>
            <GenericCardsJobOffers
              match={job.match}
              code={job.id}
              name={job.name}
              companyName={job.company_name}
              finallyDate={job.end_offer_at}
              jobModality={job.modality}
              shift={job.shift}
              location={job.location ? job.location : 'Não informado'}
              processingAsFavorite={job.id === jobIdBeingProcessedAsFavorite}
              like={true}
              status={job.status}
              isFavorite={job.is_favorite}
              onClickLike={() => handleFavoriteToggle(job.id, job.is_favorite)}
              onClickDetailsButton={() => onClickDetails(job.id, job.match)}
              labelTwo={'Detalhes'}
              onClickButton={() => openLocalizationModal(job.id, job.location)}
              label={'Candidatar'}
              processingCandidature={job.id === jobIdCandidatureBeingProcessed}
              universityColor={universityColor}
            />
          </div>
        ));
    }
  };

  return (
    <>
      {openLocalizationModal &&
        <ModalInformation
          universityColor={universityColor}
          modalOpenState={openModalLocalization}
          config={configLocationModal}
          buttonCancelFunction={handleCloseModalLocalization}
          buttonConfirmFunction={() => candidateJobOffer(selectJobId)}
          closeModalFunction={handleCloseModalLocalization}
          buttonThreeFunction={() => onClickEdit(selectJobId)}
        />
      }
      <BoxModal>
        {openModalMatch && (
          <ModalMatchInformation setOpenModalMatch={setOpenModalMatch} />
        )}
        <Container>
          <ContainerInfo>
            <FragmentColor style={{ minHeight: 'auto' }}>
              <BackButton
                onClick={(e) => handleCloseModal(e)}
                color={universityColor}
              >
                <IoIosArrowBack />
                <p>Voltar</p>
              </BackButton>
            </FragmentColor>

            <Box>
              <HeaderTabs style={{ marginBottom: '1.8rem' }}>
                <FilterInputName
                  placeholder="Buscar por vaga"
                  universityColor={universityColor}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <ButtonMatch
                  universityColor={universityColor}
                  onClick={onClickModal}
                >
                  <IconInformation color={universityColor} />
                  Entenda o match
                </ButtonMatch>
              </HeaderTabs>

              <ContainerCards>
                <div>{returnCardJobs(dataFavoriteJobOffers, visibleItems)}</div>
              </ContainerCards>

              {openDetailsModal && (
                <DetailsJobOffer
                  handleCloseModal={handleCloseDetailsModal}
                  universityColor={universityColor}
                  match={selectedJobMatch}
                  jobId={selectedJobId}
                  onClickApplyToJobOffer={() =>
                    openLocalizationModal(selectedJobId, location)
                  }
                  candidatureBeingProcessed={
                    jobIdCandidatureBeingProcessed !== null
                  }
                  isExclusive={true}
                />
              )}

              <ContainerButtonsPagination>
                <PaginationSeeMore
                  filled
                  data={dataFavoriteJobOffers}
                  onClick={() => handleSeeMore()}
                  universityColor={universityColor}
                  renderedItems={returnCardJobs(
                    dataFavoriteJobOffers,
                    visibleItems
                  )}
                />
              </ContainerButtonsPagination>
            </Box>
          </ContainerInfo>
        </Container>
      </BoxModal>
    </>
  );
}
