export const configLocationModal = {
  bubbleText: `Atenção!`,
  ParagraphText: `Notamos que você está se candidatando a uma vaga que não corresponde a sua localidade atual.`,
  TwoParagraphText: `Tem certeza de que deseja continuar com essa candidatura?`,
  buttonConfirmText: 'CANDIDATAR MESMO ASSIM',
  buttonCancelText: 'NÃO QUERO ME CANDIDATAR',
  showButtonCancel: true,
  buttonCancelWidth: 290,
  buttonThreeWidth: 302,
  showButtonThree: true,
  buttonThreeText: 'ATUALIZAR ENDEREÇO E CANDIDATAR',
  buttonCloseX: true,
  style: {
    fontSize:"16px"
  },
  buttons: {
    flexDirection: 'column',
  },
};