import styled from 'styled-components';

const ExportButton = styled.button`
  border: none;
  font-size: 15px;
  background-color: #009291;
  padding: 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 20px;
  color: #fff;
  width: 190px;
  transition: 0.2s;
  &:hover {
    background-color: #037474;
    transition: 0.2s;
  }
`;

export { ExportButton };
