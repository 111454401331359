import { 
  dnaGreen, 
  redIE,
  yellowIE,
  greenIE,
  darkBlueIE,
  blueIE,
  lightBlueIE,
  orangeIE,
} from 'assets/jss/material-dashboard-pro-react';

const notificationsStyle = (theme) => ({
  dnaColorShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: dnaGreen,
    },
  },

  redIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: redIE,
    },
  },

  yellowIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: yellowIE,
    },
  },

  greenIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: greenIE,
    },
  },

  darkBlueIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: darkBlueIE,
    },
  },

  blueIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: blueIE,
    },
  },

  lightBlueIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: lightBlueIE,
    },
  },

  orangeIESShowMoreContainer: {
    justifyContent: 'center',
    paddingBottom: 30,
    '& button': {
      color: orangeIE,
    },
  },


  notificationSidebar: {
    width: 380,
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '& ul': {
      paddingTop: 0,
    },
    '& hr': {
      margin: '0 20px',
    },
  },
  dnaColorSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: dnaGreen,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  redIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: redIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  yellowIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: yellowIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  greenIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: greenIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  darkBlueIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: darkBlueIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  blueIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: blueIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  lightBlueIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: lightBlueIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },

  orangeIESSidebarHeader: {
    justifyContent: 'space-between',
    backgroundColor: orangeIE,
    '& h3': {
      margin: 0,
      color: '#FFF',
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      color: '#FFF',
    },
    '& svg': {
      fontSize: 32,
    },
  },





  notificationLink: {
    '&:hover': {
      '& li': {
        backgroundColor: '#eee',
      },
    },
  },
});

export default notificationsStyle;
