import React, { Fragment } from 'react';
import {
  ContentContainer,
  Level,
  HeaderPagination,
  ButtonPagination,
  BaseContainer,
  ContainerCards,
  Title,
  Paragraph,
  ContainerContentCards,
  Container,
} from './styles';

export default function SkeletonGamification() {
  const numContainers = 5;

  return (
    <BaseContainer>
      <HeaderPagination>
        <Level />
        <ButtonPagination />
      </HeaderPagination>

      <ContainerCards>
        {[...Array(numContainers)].map((_, index) => (
          <ContainerContentCards key={index}>
            <ContentContainer />
            <Container>
              <Title />
              <Paragraph />
            </Container>
          </ContainerContentCards>
        ))}
      </ContainerCards>
    </BaseContainer>
  );
}
