import React from 'react';
import { ContainerInput, ContainerIcon } from '../styled';
import { AiOutlineSearch } from 'react-icons/ai';

const FilterInputName = ({ value, onChange, universityColor, placeholder }) => {
  return (
    <ContainerInput>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <ContainerIcon universityColor={universityColor}>
        <AiOutlineSearch color={'#ffff'} size={24} />
      </ContainerIcon>
    </ContainerInput>
  );
};

export default FilterInputName;
