import React, { useContext, useEffect, useState } from 'react';

import { FilterGrid } from './styled';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import FilterModal from 'components/FilterModal';
import { handleFieldMask } from 'utils/validation';
import { onlyNumbers } from 'utils/inputMasks';

export function JobOffersFilters({
  selectedJobTypes,
  filterOptions,
  filterOptionsOtherExternal,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  setSelectedJobTypes,
  activeTab,
  setQueryFilter,
  setCountJobs,
}) {
  const {
    setListJobOffers,
    setListFavoriteJobOffers,
    setListExternalJobOffers,
    setListExternalOtherJobs,
    setListApplicationJobs,
    setListFinishedJobs,
    setListFavoriteJobs,
    setIsLoadingFavoriteJobs,
    setIsLoadingOtherJobs,
    setIsLoadingApplications,
    setIsLoadingFavorites,
    setIsLoadingExternalJobs,
    setIsLoadingExternalOtherJobs,
    listJobOffers,
    listFavoriteJobOffers,
    listApplicationJobs,
    listFinishedJobs,
    listFavoriteJobs,
    listExternalJobOffers,
    listExternalOtherJobs,
    activeSubTab,
  } = useContext(JobOffersContext);
  const [loading, setLoading] = useState(false);
  const [city, setCity] = useState([]);

  const getOptions = (optionsOthers, options) => {
    return activeTab === 1 && activeSubTab === 0 ? optionsOthers : options;
  };

  const jobStateOptions = getOptions(
    filterOptionsOtherExternal?.job_state,
    filterOptions?.job_state
  );
  const jobTypeOptions = getOptions(
    filterOptionsOtherExternal?.job_type,
    filterOptions?.job_type
  );

  const handleExclusiveEndpoints = async (params = '') => {
    setIsLoadingOtherJobs(true);
    setIsLoadingFavoriteJobs(true);

    const [otherJobs, favCompanies] = await Promise.all([
      JobOffersApi.getListJobOffers(params),
      JobOffersApi.getFavoriteJobsCompanies(params),
    ]);
    setListJobOffers(otherJobs);
    setListFavoriteJobOffers(favCompanies);

    setIsLoadingOtherJobs(false);
    setIsLoadingFavoriteJobs(false);
  };

  const handleApplicationsEndpoints = async (params = '') => {
    setIsLoadingApplications(true);

    const [applicationJobs, finishedJobs] = await Promise.all([
      JobOffersApi.getApplicationJobs(params),
      JobOffersApi.getFinishedJobs(params),
    ]);
    setListApplicationJobs(applicationJobs);
    setListFinishedJobs(finishedJobs);
    setIsLoadingApplications(false);
  };

  const handleExternalEndpoints = async (params = '') => {
    if (activeSubTab === 0) {
      setIsLoadingExternalOtherJobs(true);

      const external = await JobOffersApi.getExternalOtherJobs(params);
      setListExternalOtherJobs(external);
      setIsLoadingExternalOtherJobs(false);
    } else {
      setIsLoadingExternalJobs(true);

      const externalJobs = await JobOffersApi.getListExternalJobOffers(params);
      setListExternalJobOffers(externalJobs);
      setIsLoadingExternalJobs(false);
    }
  };

  const handleFavoritesEndpoint = async (params = '') => {
    setIsLoadingFavorites(true);

    const favJobs = await JobOffersApi.getListFavoriteJobOffers(params);
    setListFavoriteJobs(favJobs);
    setIsLoadingFavorites(false);
  };

  useEffect(() => {
    const newCount =
      listJobOffers?.data?.count + listFavoriteJobOffers?.data?.count;
    setCountJobs((prev) => ({ ...prev, exclusive_offers: newCount }));
  }, [listJobOffers, listFavoriteJobOffers]);

  useEffect(() => {
    const newCount =
      listExternalJobOffers?.data?.count + listExternalOtherJobs?.data?.count;
    setCountJobs((prev) => ({ ...prev, external_offers: newCount }));
  }, [listExternalJobOffers, listExternalOtherJobs]);

  useEffect(() => {
    const newCount =
      listApplicationJobs?.data?.count + listFinishedJobs?.data?.count;
    setCountJobs((prev) => ({ ...prev, applications: newCount }));
  }, [listApplicationJobs, listFinishedJobs]);

  useEffect(() => {
    const newCount = listFavoriteJobs?.data?.count;
    setCountJobs((prev) => ({ ...prev, favorite_offers: newCount }));
  }, [listFavoriteJobs]);

  const handleActions = (params = '') => {
    const tabActions = {
      0: handleExclusiveEndpoints,
      1: handleExternalEndpoints,
      2: handleApplicationsEndpoints,
      3: handleFavoritesEndpoint,
    };
    const action = tabActions[activeTab];
    if (action) {
      setLoading(true);
      action(params ? params : '');
      setLoading(false);
    }
  };

  const handleCheckboxChange = (value) => {
    if (selectedJobTypes.includes(value)) {
      return setSelectedJobTypes(
        selectedJobTypes.filter((item) => item != value)
      );
    }
    return setSelectedJobTypes([...selectedJobTypes, value]);
  };

  const handleCityOptions = async (value) => {
    const stateParam = `?state=${value.join('&state=')}`;
    switch (activeTab) {
      case 0:
        setCity([]);
        JobOffersApi.getFilterJobOffers(stateParam).then((data) => {
          setCity(data?.job_city);
        });
        break;

      case 1:
        setCity([]);
        if (activeTab === 1 && activeSubTab === 0) {
          JobOffersApi.getFilterExternalOtherJobs(stateParam).then((data) => {
            setCity(data?.job_city);
          });
        } else {
          JobOffersApi.getFilterExternalJobs(stateParam).then((data) => {
            setCity(data?.job_city);
          });
        }
        break;

      case 2:
        setCity([]);
        JobOffersApi.getFilterOnGoingJobs(stateParam).then((data) => {
          setCity(data?.job_city);
        });
        break;

      case 3:
        setCity([]);
        JobOffersApi.getFilterFavoriteJobs(stateParam).then((data) => {
          setCity(data?.job_city);
        });
        break;
    }
  };

  useEffect(() => {
    setFilterState({});
    setCity([]);
  }, [activeTab]);

  useEffect(() => {
    if (city.length === 0 && filterState?.job_city?.length > 0) {
      setFilterState((prevs) => ({ ...prevs, job_city: [] }));
    }
  }, [city, filterState]);

  function renderFilterContent() {
    return [
      <FilterGrid>
        {(activeTab === 1 || activeTab === 2) && (
          <>
            <div>
              <Filter
                label={'Código da vaga (apenas números)'}
                placeholder={'Digite aqui...'}
                name={'job_id'}
                maxLength={10}
                type={FilterType.TEXT}
                onKeyUp={(event) => {
                  handleFieldMask(event, onlyNumbers);
                }}
              />
            </div>
            <div>
              <Filter
                label={'Nome da vaga'}
                name={'name'}
                placeholder={'Digite aqui...'}
                type={FilterType.TEXT}
              />
            </div>
          </>
        )}
        <div>
          <Filter
            label={'Filtrar por estado'}
            name={'job_state'}
            type={FilterType.MULTI_SELECT}
            options={jobStateOptions}
            settings={{
              onChange: (value) => {
                handleCityOptions(value);
              },
            }}
          />
        </div>
        <div>
          <Filter
            label={'Filtrar por cidade'}
            name={'job_city'}
            type={FilterType.MULTI_SELECT}
            settings={{ disabled: city.length === 0 }}
            options={city}
          />
        </div>
        {jobTypeOptions && jobTypeOptions.length > 0 && (
          <div>
            <Filter
              label="Tipo de vaga"
              name="job_type"
              checked={
                selectedJobTypes && selectedJobTypes.includes(item.value)
              }
              onChange={() => handleCheckboxChange(item.value)}
              options={jobTypeOptions}
              type={FilterType.RADIO}
              settings={{
                isTwoColumns: true,
              }}
            />
          </div>
        )}
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const existFilters = filters && Object.keys(filters).length > 0;
    const queryString = queryParamsFromFilterObject(filters);
    setQueryFilter(queryString);

    if (!existFilters) {
      await onClearFilters();
      return;
    }
    handleActions(queryString);
  }

  async function onClearFilters() {
    setFilterState({});
    setAppliedFilters({});
    handleActions();
  }

  useEffect(() => {
    setFilterState({});
  }, [activeTab]);

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loading}
      disabled={loading}
      zIndex={9999999999}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
