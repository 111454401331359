import BaseLayoutContext from 'contexts/base-layout';
import { ContainerDots, Dots } from './styled';
import { useContext } from 'react';

export function DotsPagination({
  totalCourses,
  coursesPerPage = 1,
  currentPage,
  onChangePage,
}) {
  const { universityColor } = useContext(BaseLayoutContext);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalCourses / coursesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ContainerDots>
      {pageNumbers.map((number) => (
        <Dots
          key={number}
          onClick={() => onChangePage(number)}
          isActive={number === currentPage}
          universityColor={universityColor}
        />
      ))}
    </ContainerDots>
  );
}
