import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { Grid } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';

import BehavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';

const HeaderHelpContent = (props) => {
  const { classes } = props;
  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <p className={classes.cardCategory}>
            Você é resultado da soma dos seus talentos, habilidades, atitudes e
            competências. E o currículo do Futuro é o meio de apresentar todo o
            seu potencial para o mundo! Seja sincero ao responder seus testes e
            invista em seu desenvolvimento. Seu futuro está sendo escrito nas
            escolhas que você faz agora!
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/jBr4z7swsGo"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

HeaderHelpContent.propTypes = {
  classes: object,
};

export default withStyles(BehavioralTestStyle)(HeaderHelpContent);
