import React, { useContext, useEffect, useRef, useState } from 'react';
import TabJobCompany from './Components/Tabs';
import SettingsPage from './Email';

import { Container, Content } from './styled';
import { EngineLoginConfig } from './LoginConfig/EngineLoginConfig';
import { EngineProfileConfig } from './Profile/EngineProfileConfig';

export default function PersonalizationTabs({
  metadata,
  noScroll,
  setNoScroll,
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const refContainer = useRef(null);

  const ScrollToDiv = () => {
    if (refContainer) {
      refContainer.current.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  useEffect(() => {
    ScrollToDiv();
  }, [currentPage]);

  const Tabs = [
    {
      activeTab: 0,
      component: <SettingsPage setNoScroll={setNoScroll} />,
    },
    {
      activeTab: 1,
      component: <EngineLoginConfig setNoScroll={setNoScroll} />,
    },
    {
      activeTab: 2,
      component: <EngineProfileConfig setNoScroll={setNoScroll} />,
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  const tabs = [`E-mail`, `Tela de acesso`];

  if (metadata.can_edit_ie_profile) {
    tabs.push(`Perfil`);
  }

  return (
    <Container ref={refContainer} noScroll={noScroll}>
      <Content>
        <TabJobCompany
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={tabs}
        />
      </Content>
      {renderPrincipalTabs(activeTab)}
    </Container>
  );
}
