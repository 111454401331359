import React from 'react';
import { Fragment } from 'react';
import {
  ButtonsContainer,
  FinishButton,
  NextButton,
  PrevsButton,
} from '../Styles/formStyled';

function FooterForm({
  haveNext,
  haveBack,
  prevsState,
  haveFinish,
  backState,
  handleSubmit,
  children,
  disabledButton,
  labelFinishButton,
}) {
  const returnButtons = () => {
    if (haveNext && !haveBack && !haveFinish) {
      return (
        <Fragment>
          <div />
          <NextButton />
        </Fragment>
      );
    }
    if (haveNext && haveBack && !haveFinish) {
      return (
        <Fragment>
          <PrevsButton type={'button'} onClick={prevsState} />
          <NextButton type="submit" />
        </Fragment>
      );
    }
    if (haveBack && !haveNext && !haveFinish) {
      return <PrevsButton type={'button'} onClick={prevsState} />;
    }

    if (haveFinish && haveBack) {
      return (
        <Fragment>
          <PrevsButton onClick={prevsState} type={'button'} />
          <FinishButton disabled={disabledButton} type={'submit'}>
            {labelFinishButton ? labelFinishButton : 'Enviar'}
          </FinishButton>
        </Fragment>
      );
    }
  };

  return <ButtonsContainer>{returnButtons()}</ButtonsContainer>;
}

export default FooterForm;
