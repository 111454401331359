import styled from 'styled-components';

export const Container = styled.div`
  ${(props) => (props.openModal ? `display: flex;` : `display: none;`)}
  width: calc(100% - 260px);
  min-height: 100%;
  height: auto;
  background-color: #eeeeee;
  position: fixed;
  z-index: ${(props) => (props.usingPreviewPdf ? 0 : props.zIndex)};
  align-items: center;
  top: 0;
  left: 0;
  justify-content: center;
  transition: all 1s ease-in-out;

  overflow-y: auto;
  left: 260px;

  @media (max-width: 960px) {
    width: 100%;
    left: 0px;
  }

  .container-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  position: absolute;
  top: 5em;
`;

export const CloseButtonExternal = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  top: 40px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;


export const SaveButton = styled.button`
  width: 100%;
  max-width: 100px;
  
  padding: 16px;
  
  font-size: 16px;
  color: #fff;
  font-weight: 700;

  background-color: #009291;
  border: none;
  border-radius: 4px;

  margin-top: 40px;

  cursor: pointer;
`

export const CloseButtonInternal = styled.button`
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 10px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #4b4b4d;
  font-weight: 500;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;