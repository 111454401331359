export function transformKeysToObject(obj) {
  const result = {};
  for (const key in obj) {
    let currentPoint = result;
    const splitKey = key.split('.');
    splitKey.forEach((part, index) => {
      if (index === splitKey.length - 1) {
        currentPoint[part] = obj[key];
      } else {
        const match = part.match(/(.+)\[(\d+)]/);
        if (match) {
          const arrayKey = match[1];
          const arrayIndex = match[2];
          if (!currentPoint[arrayKey]) {
            currentPoint[arrayKey] = [];
          }
          if (!currentPoint[arrayKey][arrayIndex]) {
            currentPoint[arrayKey][arrayIndex] = {};
          }
          currentPoint = currentPoint[arrayKey][arrayIndex];
        } else {
          if (!currentPoint[part]) {
            currentPoint[part] = {};
          }
          currentPoint = currentPoint[part];
        }
      }
    });
  }
  return result;
}

export function transformObjectToKeys(obj, parentKey = '') {
  let result = {};

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        result = {
          ...result,
          ...transformObjectToKeys(item, `${parentKey}${key}[${index}].`),
        };
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = {
        ...result,
        ...transformObjectToKeys(obj[key], `${parentKey}${key}.`),
      };
    } else {
      result[`${parentKey}${key}`] = obj[key];
    }
  }
  return result;
}
