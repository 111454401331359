export default function PublishedIcon({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.23685 15.0386C4.17764 15.0386 0.887207 11.7482 0.887207 7.689C0.887207 3.62979 4.17764 0.339355 8.23685 0.339355C12.2961 0.339355 15.5865 3.62979 15.5865 7.689C15.5865 11.7482 12.2961 15.0386 8.23685 15.0386ZM7.50409 10.6289L12.7003 5.43193L11.6611 4.39269L7.50409 8.55038L5.42488 6.47117L4.38564 7.51041L7.50409 10.6289Z"
        fill={color}
      />
    </svg>
  );
}
