import React, { Fragment, useEffect, useState } from 'react';
import imgBackground from 'assets/JobOffer/bg-vagas.svg';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import { Container } from './style';
import PersonalizationTabs from './PersonalizationTab/index';
import CommunicationRulerTab from './CommunicationRulerTab';

export default function SettingsEngine({
  handleIsLoadingState,
  openSnackBar,
  metadata,
}) {
  const [activeTab, setActiveTab] = useState(0);

  const [noScroll, setNoScroll] = useState(false);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <PersonalizationTabs
          metadata={metadata}
          noScroll={noScroll}
          setNoScroll={setNoScroll}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <CommunicationRulerTab setNoScroll={setNoScroll} noScroll={noScroll} />
      ),
    },
  ];

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  return (
    <Fragment>
      <Container noScroll={noScroll}>
        <CompanyHeader
          title="Configurações"
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          backgroundImage={imgBackground}
          tabs={[`Personalização`, `Régua de comunicação padrão`]}
        />
        {renderPrincipalTabs(activeTab)}
      </Container>
    </Fragment>
  );
}
