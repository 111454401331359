import { components } from 'react-select';
import Select from 'react-select';
import React, { useContext, useState, useEffect } from 'react';
import { SelectFilterBox } from '../styles';
import { FilterType } from './Filter';
import { useFilterContext } from '../contexts/FilterContext';
import BaseLayoutContext from 'contexts/base-layout';
import { useDebounce } from 'hooks/useDebounce';

export function FilterAsyncSingleSelect({
  name,
  loadOptions,
  minCharToSearch,
  latency,
}) {
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const {
    filterState,
    updateFilterState,
    isOpen: filterModalIsOpen,
  } = useFilterContext();
  const { universityColor } = useContext(BaseLayoutContext);

  const debounceValue = useDebounce(inputValue, latency);

  useEffect(() => {
    if (!debounceValue && !filterState[name]) {
      setOptions([]);
    } else if (debounceValue?.length >= minCharToSearch) {
      loadOptions(debounceValue).then((values) => {
        setOptions(values);
      });
    }
  }, [debounceValue, filterState]);

  function handleType(value = '') {
    setInputValue(value);
  }

  useEffect(() => {
    if (filterModalIsOpen && filterState[name]) {
      loadOptions(filterState[name]).then((values) => {
        setOptions(values);
      });
    }
  }, [filterModalIsOpen]);

  function getShadeColor(color, percent) {
    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? 0 : 255;
    const p = percent < 0 ? percent * -1 : percent;
    const R = f >> 16;
    const G = (f >> 8) & 0x00ff;
    const B = f & 0x0000ff;
    return `#${(
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)}`;
  }

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary25: getShadeColor(
          universityColor ? universityColor : '#009291',
          0.85
        ),
        primary50: getShadeColor(
          universityColor ? universityColor : '#009291',
          0.6
        ),
        primary: universityColor || '#009291',
      },
    };
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>
          {inputValue.length >= minCharToSearch || minCharToSearch === 1
            ? 'Nenhuma opção encontrada'
            : `Digite pelo menos ${minCharToSearch} letras`}
        </span>
      </components.NoOptionsMessage>
    );
  };

  const LoadingMessage = (props) => {
    return (
      <components.LoadingMessage {...props}>
        <span>Carregando...</span>
      </components.LoadingMessage>
    );
  };

  return (
    <SelectFilterBox>
      <Select
        name={name}
        placeholder={
          minCharToSearch === 1
            ? 'Busque aqui'
            : `Digite pelo menos ${minCharToSearch} letras`
        }
        theme={selectTheme}
        components={{
          NoOptionsMessage,
          LoadingMessage,
        }}
        options={options}
        value={options.find((option) => option.value === filterState[name])}
        styles={selectStyles}
        onChange={(selected) => {
          return updateFilterState(
            selected ? selected.value : null,
            name,
            FilterType.ASYNC_SINGLE_SELECT
          );
        }}
        onInputChange={handleType}
        isClearable
      />
    </SelectFilterBox>
  );
}
