import React, { useMemo } from 'react';
import Pagination from 'components/CustomPagination';
import JobCards from '../../../../components/Cards';
import SkeletonJobsCard from '../../../../components/JobsSkeleton';
import { Container, Content, Title } from './styled';
import GenericMessage from 'components/GenericMessage';

function JobsInProgress({
  jobs,
  jobsCount,
  limit,
  setLimit,
  onPageChange,
  currentPage,
  setCurrentPage,
  activeTab,
  setListJobs,
  universityFilters,
  countJobs,
}) {
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const skeletonCardsQuantity = useMemo(() => {
    return randomIntFromInterval(1, 4);
  }, []);

  return jobs === null ? (
    <Container>
      <Content>
        <SkeletonJobsCard quantity={skeletonCardsQuantity} />
      </Content>
    </Container>
  ) : jobs?.length > 0 ? (
    <Container>
      <Content>
        {jobs?.map((jobs) => (
          <JobCards
            jobName={jobs.name}
            openedSince={jobs.opened_since}
            responsibleName={jobs.responsible_name}
            numberOfCandidates={jobs.number_of_candidates}
            jobs={jobs}
            jobID={jobs.id}
            isEducationalGroup={jobs.is_educational_group}
            activeTab={activeTab}
            setListJobs={setListJobs}
            universityFilters={universityFilters}
            countJobs={countJobs}
          />
        ))}
      </Content>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={jobsCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  ) : (
    <Container>
      <GenericMessage title="No momento, não há nenhuma vaga disponível." />
    </Container>
  );
}

export default JobsInProgress;
