import React from 'react';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';

import { ButtonsStudentInfos, ButtonImgBlocked } from './styled';

const ButtonsStudentsInfos = ({
  universityColor,
  title,
  blocked,
  onClick,
  disabled,
}) => (
  <ButtonsStudentInfos
    universityColor={universityColor}
    onClick={onClick}
    disabled={disabled}
  >
    {title}
    {blocked ? (
      <ButtonImgBlocked disabled={disabled}>
        <IconBlocked color="#949494" />
      </ButtonImgBlocked>
    ) : null}
  </ButtonsStudentInfos>
);

export default ButtonsStudentsInfos;
