import styled from 'styled-components';

export const HtmlEditorStyled = styled.div`
  width: 100%;
  .editorStyled {
    height: 100%;
    padding: 0px 11px 11px !important;
    border-radius: 2px !important;
    background: #fff;
    border-radius: 10px !important;
    overflow-y: auto;
    background: ${(props) => (props.readOnly ? 'rgb(245, 245, 245)' : 'white')};
  }
  div.DraftEditor-root {
    height: 200px;
  }
  .editorError {
    border: 1px solid #f44336;
  }
  .toolBarStyled {
    border-radius: 10px !important;
    a {
      color: black;
    }
  }
  .charCountStyled {
    font-size: 12px;
    color: #676565;
    margin: 4px 6px;
    float: right;
  }

  .error {
    font-size: 12px;
    color: #676565;
    margin: 4px 6px;
    float: left;
  }
`;
