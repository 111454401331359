import * as Yup from 'yup';

const conditionalField = (value, schema) =>
  Yup.mixed().when('mentorship_modality', {
    is: (modality) => modality?.value === value,
    then: schema,
    otherwise: Yup.mixed().nullable(),
  });

export const schema = Yup.object().shape({
  zip_code: conditionalField(
    'in_person',
    Yup.string()
      .required('Este campo é obrigatório')
      .test('is-valid-zip', 'Formato de CEP inválido', (value) => {
        if (!value) return true;
        return /^\d{5}-\d{3}$/.test(value);
      })
  ),
  address: conditionalField(
    'in_person',
    Yup.string().required('Este campo é obrigatório')
  ),
  address_number: conditionalField(
    'in_person',
    Yup.string().required('Este campo é obrigatório')
  ),
  district: conditionalField(
    'in_person',
    Yup.string().required('Este campo é obrigatório')
  ),
  access_link: conditionalField(
    'online',
    Yup.string()
      .url('Formato de link inválido')
      .required('Este campo é obrigatório')
  ),
});
