import {
  dnaGreen,
  yellowIE,
  redIE,
  greenIE,
  darkBlueIE,
  lightBlueIE,
  blueIE,
  orangeIE,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const roundedChartStyle = {
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    margin: '20px',
    color: '#009291',
    strokeLinecap: 'round',
  },
  chartInfo: {
    margin: 0,
    textAlign: 'center',
    position: 'absolute',
  },
  average: {
    fontSize: '30px',
  },
  dnaColorProgress: {
    color: dnaGreen,
    margin: '20px',
    strokeLinecap: 'round',
  },
  yellowIESProgress: {
    color: yellowIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
  redIESProgress: {
    color: redIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
  greenIESProgress: {
    color: greenIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
  darkBlueIESProgress: {
    color: darkBlueIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
  lightBlueIESProgress: {
    color: lightBlueIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
  orangeIESProgress: {
    color: orangeIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
  blueIESProgress: {
    color: blueIE,
    margin: '20px',
    strokeLinecap: 'round',
  },
};

export default roundedChartStyle;
