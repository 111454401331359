import React, { useRef } from 'react';

import {
  Container,
  Box,
  Content,
  WrapperTitle,
  ContainerButton,
  WrapperMessage,
  WhiteButton,
  GreenButton,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';

export default function FinalizationConfirmationModal(props) {
  const {
    isOpen,
    setModalFinish,
    setOpenConfirmationModal,
  } = props;
  const modalRef = useRef();

  const closeModalClick = (e) => {
    if (modalRef.current === e.target) {
      setModalFinish(false);
    }
  };

  const closeModal = () => {
    setOpenConfirmationModal(false);
    setModalFinish(false);
  };

  const handleModal = () => {
    setOpenConfirmationModal(true);
    setModalFinish(false);
  };

  return (
    <Container isOpen={isOpen}>
      <Box ref={modalRef} onClick={closeModalClick}>
        <Content size="variant">
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <div className="bubble-dialog">
              <span> Atenção! </span>
            </div>
          </WrapperTitle>
          <WrapperMessage>
            Tem certeza que deseja finalizar a vaga?
          </WrapperMessage>
          <ContainerButton>
            <WhiteButton onClick={closeModal} size="finish">
              NÃO
            </WhiteButton>
            <GreenButton closeModal={closeModal} onClick={handleModal}>
              SIM
            </GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  );
}
