import React, { Fragment } from 'react';
import { object } from 'prop-types';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import BehavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import useDefineColor from 'constants/colors';

const TechnicalCompetenceHelpContent = (props) => {
  const { classes, universityColor } = props;
  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Competências
          </h2>
          <p className={classes.cardCategory}>
            Seu desempenho acadêmico reflete seu preparo técnico para sua
            atuação profissional. Por isso, dedique-se aos estudos e se mantenha
            em constante desenvolvimento.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/p9Fid3SgkdE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

TechnicalCompetenceHelpContent.propTypes = {
  classes: object,
};

export default withStyles(BehavioralTestStyle)(TechnicalCompetenceHelpContent);
