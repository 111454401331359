import styled from 'styled-components';

export const PrevButton = styled.button`
  width: 100%;
  max-width: 200px;
  height: 45px;
  background-color: #ffffff;
  border: 1px solid #009291;
  border-radius: 4px;
  font: 700 18px Roboto;
  color: #009291;
  text-align: center;
  margin-right: 5px;
  cursor: pointer;
`;
