import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Container, WrapperButton } from './style';

import Card from './components/card/Card';
import { ExtensionProjectsStudentContext } from 'views/Student/Learning/Context/ExtensionProjectStudentContext';
import ModalInformation from 'components/informationModal';
import { ConfigModalSuccessSubscribe } from './useCases/configModal';
import { MoreInformationModalStudent } from '../Modal/MoreInformationModalStudent';
import CTANotActiveProjects from '../CTANotActiveProjects';
import { getDetails } from '../../../../../../Extracurricular/services';
import mergeContextsHelper from 'utils/mergeContextsHelper';

import { profileAdapter } from 'adapters/student/profileAdapter';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import GenericMessage from 'components/GenericMessage';
import BaseLayoutContext from 'contexts/base-layout';
import { CurriculumContext } from 'contexts/CurriculumContext';

export default function ListExtesionProject({
  extesionProjectData,
  getExtesionProject,
  countExtesionProject,
  currentTab,
  metadata,
  updateActiveTab,
}) {
  const [limit, setLimit] = useState(10);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalDetaisOpen, setModalDetailsOpen] = useState(false);
  const [modalDetailsItem, setModalDetailsItem] = useState({});
  const { extensionProjectCount, imagesList, getImages } = useContext(
    ExtensionProjectsStudentContext
  );

  const {
    profile,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateAvatarState,
    updateInterestState,
    isOpenCurriculumModal,
    closeCurriculumModal,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);

  const { universityColor } = useContext(BaseLayoutContext);

  const refListExtensionProject = useRef();

  const handleClickDetails = useCallback(async (item) => {
    const response = await getDetails(item.id);
    setModalDetailsItem({ ...item, ...response });
    setModalDetailsOpen(true);
  }, []);

  const propsCard = {
    getExtesionProject,
    imagesList,
    setModalInformationOpen,
    limit,
    handleClickDetails,
    setModalDetailsItem,
  };

  useEffect(() => {
    if (currentTab === 0) {
      getExtesionProject();
    }
  }, [currentTab]);

  useEffect(() => {
    const query = `limit=${limit}`;
    getImages(query);
  }, [limit]);

  useEffect(() => {
    extensionProjectCount();
  }, []);

  const mapExtesionsProject = extesionProjectData?.map((item) => (
    <Card item={item} {...propsCard} />
  ));

  const updateListAndCount = () => {
    getExtesionProject('limit=10');
    extensionProjectCount();
  };

  const handleClickSeeMore = () => {
    const query = `limit=${limit + 10}`;
    setLimit((prevs) => prevs + 10);
    getExtesionProject(query);
  };

  const conditionalSeeMore = () => {
    if (limit < countExtesionProject) {
      return (
        <WrapperButton universityColor={universityColor}>
          <button onClick={handleClickSeeMore}>Ver mais</button>
        </WrapperButton>
      );
    }
  };

  const handleConfirmButtonModal = () => {
    setModalInformationOpen(false);
    updateListAndCount();
  };

  if (
    (extesionProjectData && extesionProjectData.length === 0) ||
    !metadata.has_extension_project
  ) {
    return (
      <GenericMessage
        title="Nenhum Projeto de Extensão encontrado"
        subtitle=" Oops! No momento, não existem projetos de extensão publicados."
        subtitleResponsible="Oops! No momento não existem projetos de extensão publicados, mas você
        pode acessar outras oportunidades de aprendizagem."
        buttonLabel="Acessar outras oportunidades de aprendizagem"
        buttonLabelResponsible="Visualizar"
        buttonFunc={() => updateActiveTab(2)}
      />
    );
  }

  return (
    <Fragment>
      <MoreInformationModalStudent
        isOpen={modalDetaisOpen}
        setModalDetailsOpen={setModalDetailsOpen}
        setModalDetailsItem={setModalDetailsItem}
        informations={modalDetailsItem}
        refCloseModal={refListExtensionProject}
      />
      <ModalInformation
        universityColor={universityColor}
        modalOpenState={modalInformationOpen}
        config={ConfigModalSuccessSubscribe}
        buttonConfirmFunction={handleConfirmButtonModal}
      />
      {isOpenCurriculumModal &&
        mergeContextsHelper(
          <CurriculumModal
            personalityTestIsDone={profile?.quizzes?.personality.is_done}
            behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
            lifeInterestList={profile?.life_interests}
            interestList={profile?.interests}
            studentProfileState={profileAdapter(profile?.academic_profile)}
            updateBehavioralState={updateBehavioralState}
            updatePersonalityState={updatePersonalityState}
            updateProfileState={updateProfileState}
            updateInterestState={updateInterestState}
            setClose={closeCurriculumModal}
            isOpen={isOpenCurriculumModal}
            updateAvatarState={updateAvatarState}
            handleShowSpecialNeeds={handleShowSpecialNeeds}
          />
        )}
      <Container>{mapExtesionsProject}</Container>
      {conditionalSeeMore()}
    </Fragment>
  );
}
