import React, { Fragment, useMemo } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import { IoClose } from 'react-icons/io5';
import { ButtonX, Wrapper, Box, Modal, WrapperTitle, BubbleDialog, H4Styled, ParagraphStyled, IconPersonStyled } from 'components/informationModal/style';
import { ContainerColumn, ContainerContent, ContainerInformations, ContanetInformations, IconInformations, ParagraphFeedback, TextInformations } from './styled';
import { wrapperIconsJobOffers } from '../utils/wrapperIconsJobOffers';
import moment from 'moment';

export default function ModalFeedbackJobOffer({
  handleCloseModal,
  modalOpenState,
  universityColor,
  feedback,
  data,
  feedbackName,
}) {
  const { IconUser, IconDate } = wrapperIconsJobOffers();

  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal>
            <ButtonX
              onClick={handleCloseModal}
            >
              <IoClose size={25} color="#4B4B4D" />
            </ButtonX>

            <WrapperTitle>
              <IconPersonStyled
                universityColor={universityColor}
                src={IconPerson}
              />
              <BubbleDialog universityColor={universityColor}>
                <H4Styled>Visualizar feedback</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphFeedback>
              O Recrutador deixou uma mensagem sobre a sua candidatura.
            </ParagraphFeedback>

            <ParagraphFeedback isFeedbackTitle>
              Feedback
            </ParagraphFeedback>

            <ContainerContent>
              <ContainerColumn>
                {ReactHtmlParser(feedback)}
              </ContainerColumn>
            </ContainerContent>

            <ContainerInformations>
              <ContanetInformations>
                <IconInformations>
                  <IconUser color={universityColor} />
                </IconInformations>
                <TextInformations>Enviado por {feedbackName}</TextInformations>
              </ContanetInformations>

              <ContanetInformations>
                <IconInformations>
                  <IconDate color={universityColor} />
                </IconInformations>
                <TextInformations>{data}</TextInformations>
              </ContanetInformations>
            </ContainerInformations>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}