import React, { Fragment } from 'react';
import { shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import BehavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import useDefineColor from 'constants/colors';

const ProfessionalExperienceHelpContent = (props) => {
  const { classes, universityColor } = props;
  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Experiências
          </h2>
          <p className={classes.cardCategory}>
            Aqui é importante que você cadastre todas as suas experiências,
            incluindo: estágios obrigatórios e não obrigatórios, trabalhos
            voluntários e humanitários, experiências temporárias/freelancer,
            liderança em movimentos estudantis, sociais, políticos e religiosos,
            experiência em negócios familiares, participação em projetos ou
            iniciativas civis, participação em olimpíadas de conhecimento e
            festivais, e organização de eventos.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/vciUuPQcGOI"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            title="Estágios e Emprego"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

ProfessionalExperienceHelpContent.propTypes = {
  classes: shape.isRequired,
};

export default withStyles(BehavioralTestStyle)(
  ProfessionalExperienceHelpContent
);
