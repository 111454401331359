import React from 'react';
import { Wrapper } from './style';

export default function NoGraphicValue() {
  return (
    <Wrapper>
      <p>Nenhum resultado encontrado para o período selecionado.</p>
    </Wrapper>
  );
}
