import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import {
  AboutTitle,
  AddressContainer,
  CepButton,
  NoSearchZipCodeError,
  DescImg,
  FormRow,
  Img,
  MultiStepContainer,
  Title,
  Wrapper,
  ContainerCheckBox,
} from '../../../Styles/formStyled';
import extensionProjectIcon from 'assets/img/extension-project-medium.svg';
import Input from '../../Form/input';
import FooterForm from '../../FooterForm';
import SearchableSelectUn from '../../Form/searchableSelect';
import DatePickerCustom from '../../Form/datePicker';
import InputWorkload from '../../Form/inputWorkload';
import StepIndicator from '../../StepIndicator';
import * as Yup from 'yup';
import { handleFieldMask } from 'utils/validation';
import { maskZipCode } from 'utils/inputMasks';
import { zipCodeRequest } from 'services/zipCode';
import Checkbox from 'components/UnformFields/CheckBox';
import NoteInput from 'components/UnformFields/noteInput';
import { FiXCircle } from 'react-icons/fi';
import {
  createExtensionProject,
  extensionProjectFile,
  updateExtensionProject,
} from '../../../services';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';

import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading/index.jsx';

export function StepThree({ prevFormStep }) {
  const formRef = useRef(null);
  const {
    formData,
    extensionProjectOptions,
    setModalIsOpen,
    setFormValues,
  } = useContext(OportunitiesContext);
  const { developmentObjectives, projectTypes } = extensionProjectOptions;
  const [zipCode, setZipCode] = useState(false);
  const [showErrorNoSearchZipCode, setShowErrorNoSearchZipCode] =
    useState(false);
  const [disabledFinishButton, setDisabledFinishButton] = useState(false);
  const [enableStudentsEvidence, setEnableStudentsEvidence] = useState(false);
  const [LoadingZipCode, setLoadingZipCode] = useState(false);
  const {
    imageChanged,
    setIsSuccessModalOpen,
    setActiveTab,
    getAllFutureProjects,
    getAllOpenForSubscription,
    getAllInProgressProjects,
    setFutureExtension,
    setInProgressExtension,
    setOpenForSubscriptionsExtension,
    setPaginationOneCurrentPage,
    setPaginationTwoCurrentPage,
    setPaginationThreeCurrentPage,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    if (formData.zip_code) {
      setZipCode(formData.zip_code);
    }
    if (formData.enable_students_evidences) {
      setEnableStudentsEvidence(true);
    }
    if (formData.development_objective) {
      formRef.current.setFieldValue(
        'development_objective',
        formData.development_objective
      );
    }
  }, []);

  const isEdit = !!formData.uuid;

  const handleEnableStudentEvidence = (e) => {
    const checked = e.target.checked;
    setEnableStudentsEvidence(checked);

    clearStudentEvidencesOnDisable(checked);
  };

  const clearStudentEvidencesOnDisable = (checked) => {
    if (!checked) {
      delete formData.about_students_evidences;
      delete formData.evidences_start_at;
      delete formData.evidences_end_at;
    }
  };

  const enableStudentOption = [
    {
      id: 'enable_students_evidences',
      type: 'checkbox',
      value: true,
      label: 'Desejo habilitar o envio de evidências pelos meus estudantes',
    },
  ];
  async function validateData() {
    if (!zipCode) {
      setShowErrorNoSearchZipCode(true);
    }

    const data = formRef?.current?.getData();
    try {
      const schema = Yup.object().shape({
        type: Yup.string().required('Informe o tipo de projeto'),
        ods: Yup.string(),
        workload: Yup.string()
          .required('Informe a carga horária')
          .test('valid-workload', 'Carga horária inválida', (value) => {
            if (!value) {
              return false;
            }
            const digits = value.length;
            const hours = parseInt(value.substring(0, 2));
            const minutes = parseInt(value.substring(digits - 2, digits));
            return (
              !(hours < 1 || hours > 9999) && !(minutes < 0 || minutes > 59)
            );
          }),
        students_limit: Yup.number()
          .typeError('Informe o limite de alunos')
          .test('is-greater-than-zero', 'Carga horária inválida', (value) => {
            return value > 0;
          })
          .max(32767, 'Limite de alunos inválido')
          .min(1, 'Limite de alunos inválido')
          .required('Informe o limite de alunos')
          .test(
            'is-greater-than-current-registrations',
            'Limite não pode ser menor do que o número de alunos já inscritos',
            (value) => {
              if (isEdit) {
                return value >= formData.registered_students;
              }
              return true;
            }
          ),
        start_at: Yup.date()
          .typeError('Informe a data de início')
          .required('Informe a data de início'),
        end_at: Yup.date()
          .typeError('Informe a data de término')
          .required('Informe a data de término')
          .min(
            Yup.ref('start_at'),
            'A data final precisa ser maior do que a data inicial.'
          ),
        street: Yup.string().required('Informe a avenida/rua'),
        number: Yup.string().required('Informe o número'),
        zip_code: Yup.string().required('Informe o CEP'),
        city: Yup.string().required('Informe a cidade'),
        district: Yup.string().required('Informe o bairro'),
        state: Yup.string().required('Informe o estado'),
        enable_students_evidences: Yup.boolean(),
        about_students_evidences: Yup.string().when(
          'enable_students_evidences',
          {
            is: true,
            then: Yup.string().required('Informe como deve ser as evidências'),
          }
        ),
        evidences_start_at: Yup.date().when('enable_students_evidences', {
          is: true,
          then: Yup.date()
            .typeError('Informe a data de início')
            .required('Informe a data de início'),
        }),
        evidences_end_at: Yup.date().when('enable_students_evidences', {
          is: true,
          then: Yup.date()
            .typeError('Informe a data de término')
            .required('Informe a data de término')
            .min(
              Yup.ref('start_at'),
              'A data final precisa ser maior do que a data inicial.'
            ),
        }),
      });

      const validatedData = await schema.validate(data, {
        abortEarly: false,
      });
      if (!zipCode) {
        return (validatedData = false);
      }
      formRef.current.setErrors({});
      return validatedData;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function handleSubmit() {
    setDisabledFinishButton(true);
    if (formRef.current) {
      const data = await validateData();
      if (data) {
        const addressData = {
          street: data.street,
          number: data.number,
          complement: data.complement,
          district: data.district,
          city: data.city,
          state: data.state,
          zip_code: data.zip_code,
        };
        const extensionProjectData = {
          type: data.type,
          development_objective: data.development_objective,
          students_limit: data.students_limit,
          enable_students_evidences: data.enable_students_evidences,
          about_students_evidences: data.about_students_evidences,
          evidences_start_at: data.evidences_start_at,
          evidences_end_at: data.evidences_end_at,
          orientation: formData.orientation,
        };
        const targetAudiencesData = {
          courses: formData.courses.map((course) =>
            parseInt(course.replace('c', ''))
          ),
          semesters: formData.semesters.map((course) =>
            parseInt(course.replace('p', ''))
          ),
          disciplines: formData.disciplines,
          macro_areas: formData.macro_areas,
          unities: formData.unities,
        };
        const dataRequest = {
          name: formData.name,
          description: formData.description,
          start_at: data.start_at ? data.start_at.toISOString() : undefined,
          end_at: data.end_at ? data.end_at.toISOString() : undefined,
          workload: data.workload ? data.workload.replace(':', '.') : null,
          competences_specialities: formData.competences_specialities || [],
          address: addressData,
          extension_project: extensionProjectData,
          target_audiences: targetAudiencesData,
        };

        let response;
        if (isEdit) {
          response = await updateExtensionProject(dataRequest, formData.uuid);
        } else {
          response = await createExtensionProject(dataRequest);
        }

        if (response.status === 201 || response.status === 200) {
          if (imageChanged) {
            const dataFile = new FormData();
            dataFile.append(
              'attraction_image',
              formData.attraction_image ? formData.attraction_image : ''
            );
            await extensionProjectFile(dataFile, response.data.id);
          }
          if (formData.isOnModal) {
            formData.handleSubmitOportunity();
          }
          setModalIsOpen(false);
          setIsSuccessModalOpen(true);
          setActiveTab(3);

          setFutureExtension(null);
          setInProgressExtension(null);
          setOpenForSubscriptionsExtension(null);

          setPaginationOneCurrentPage(0);
          setPaginationTwoCurrentPage(0);
          setPaginationThreeCurrentPage(0);

          getAllFutureProjects(true, true);
          getAllOpenForSubscription(true, true);
          getAllInProgressProjects(true, true);
        }

        clearStudentEvidencesOnDisable();
        setFormValues({
          ...data,
          workload: data.workload ? data.workload.replace(':', '.') : null,
        });
        formRef.current?.reset();
      }
      setDisabledFinishButton(false);
    }
  }

  useEffect(() => {
    const data = formRef?.current?.getData();
    if (data.workload) {
      formRef.current.setFieldValue('workload', data.workload);
    }
  }, [isEdit]);

  function invalidateCep() {
    formRef.current.setFieldError('zip_code', 'CEP inválido');
    setZipCode(false);
  }

  const removeError = () => {
    formRef.current.setFieldError('street', '');
    formRef.current.setFieldError('city', '');
    formRef.current.setFieldError('district', '');
    formRef.current.setFieldError('state', '');
  };

  const handleZipCode = async () => {
    const value = formRef.current.getFieldValue('zip_code');
    setLoadingZipCode(true);
    setShowErrorNoSearchZipCode(false);
    removeError();

    if (value.length === 9) {
      try {
        const data = await zipCodeRequest(value);
        setZipCode(true);
        setLoadingZipCode(false);
        formRef.current.setFieldValue('street', data.logradouro);
        formRef.current.setFieldValue('city', data.localidade);
        formRef.current.setFieldValue('district', data.bairro);
        formRef.current.setFieldValue('state', data.uf);
        formRef.current.setFieldValue('number', '');

        if (data.erro) {
          invalidateCep();
          setLoadingZipCode(false);
        } else {
          formRef.current.setFieldError('zip_code', '');
          setLoadingZipCode(false);
        }
      } catch (e) {
        invalidateCep();
        setLoadingZipCode(false);
      }
    } else {
      invalidateCep();
      setLoadingZipCode(false);
    }
  };

  const minStartDate = useMemo(() => {
    const today = new Date();
    const todayMoreOneDay = new Date(today.setDate(today.getDate() + 1));
    if (isEdit) {
      const currentStartDate = new Date(formData.start_at);
      if (currentStartDate < todayMoreOneDay) {
        return currentStartDate;
      } else {
        return todayMoreOneDay;
      }
    }
    return todayMoreOneDay;
  });

  const minEndDate = useMemo(() => {
    const today = new Date();
    const todayMoreOneDay = new Date(today.setDate(today.getDate() + 1));
    if (isEdit) {
      const currentEndDate = new Date(formData.end_at);
      if (currentEndDate < todayMoreOneDay) {
        return currentEndDate;
      } else {
        return todayMoreOneDay;
      }
    }
    return todayMoreOneDay;
  });

  return (
    <Form
      ref={formRef}
      initialData={formData}
      onSubmit={handleSubmit}
      style={{ marginTop: '16px' }}
    >
      <MultiStepContainer>
        <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
      </MultiStepContainer>
      <Wrapper>
        <DescImg>
          <Img src={extensionProjectIcon} />
          <Title>Projeto de Extensão</Title>
        </DescImg>
        <AboutTitle>Sobre o projeto</AboutTitle>
        <SearchableSelectUn
          name="type"
          options={projectTypes}
          label="Tipo de projeto*"
          style={{ padding: '0px 16px', height: '2.3em' }}
          onChange={(option) => {
            if (option.value) {
              formRef.current.setFieldError('type', '');
            }
          }}
        />
        <SearchableSelectUn
          name="development_objective"
          options={developmentObjectives}
          label="Selecione as ODS - Objetivo de Desenvolvimento Sustentável relacionadas"
          style={{ padding: '0px 16px', height: '2.3em' }}
          isMulti
        />
        <FormRow>
          <DatePickerCustom
            minDate={minStartDate}
            name="start_at"
            label="Data inicial do projeto*"
            placeholder="Ex: 15/02/2022"
          />
          <DatePickerCustom
            minDate={minEndDate}
            name="end_at"
            label="Data final do projeto*"
            placeholder="Ex: 15/02/2022"
          />
        </FormRow>
        <FormRow>
          <InputWorkload
            name="workload"
            label="Carga horária*"
            placeholder={'Ex.: 20:00'}
            style={{ padding: '10px 16px' }}
            maxLength={7}
          />
          <Input
            style={{ padding: '10px 16px' }}
            name="students_limit"
            placeholder="Ex.: 200"
            label="Limite de estudantes*"
            type={'number'}
            maxLength={5}
            min="0"
          />
        </FormRow>
        <ContainerCheckBox>
          <Checkbox
            name="enable_students_evidences"
            options={enableStudentOption}
            onChange={handleEnableStudentEvidence}
          />

          {enableStudentsEvidence && (
            <div>
              <h3>Sobre o envio de evidências*</h3>
              <p>
                Explique o que são as evidências e por que os estudantes devem
                realizar o upload delas. Esse texto ficará disponível para os
                estudantes que participarão do projeto.
              </p>
              <NoteInput
                name="about_students_evidences"
                placeholder="Digite aqui..."
              />
              <FormRow style={{ marginTop: '20px' }}>
                <DatePickerCustom
                  minDate={minStartDate}
                  name="evidences_start_at"
                  label="Data inicial para envio*"
                  placeholder="Ex: 15/02/2022"
                />
                <DatePickerCustom
                  minDate={minEndDate}
                  name="evidences_end_at"
                  label="Data final para envio*"
                  placeholder="Ex: 15/02/2022"
                />
              </FormRow>
              <span style={{ marginTop: '5px' }}>
                Defina a data de início e a data final para o upload de
                evidências por parte dos estudantes.
              </span>
            </div>
          )}
        </ContainerCheckBox>

        <AboutTitle>Local de realização do projeto de extensão</AboutTitle>
        <FormRow>
          <Input
            name="zip_code"
            placeholder="Ex.: 00000-000"
            label="CEP*"
            onChange={() => setZipCode(false)}
            onKeyUp={(e) => {
              handleFieldMask(e, maskZipCode);
            }}
            maxlength={9}
            onFocus={() => setShowErrorNoSearchZipCode(false)}
          />
          <CepButton
            type="button"
            onClick={handleZipCode}
            disabled={LoadingZipCode}
            LoadingZipCode={LoadingZipCode}
          >
            {LoadingZipCode === true ? <Loading color={'#fff'} /> : 'Buscar'}
          </CepButton>
        </FormRow>
        {showErrorNoSearchZipCode && (
          <NoSearchZipCodeError>
            <FiXCircle color="red" size={17} />
            Busque o CEP para preencher os campos automaticamente
          </NoSearchZipCodeError>
        )}
        <AddressContainer zipCode={zipCode}>
          <div className="left-side">
            <Input name="street" padding="0 16px" placeholder="Avenida/Rua" />
            <Input
              name="complement"
              padding="0 16px"
              placeholder="Complemento"
            />
            <Input name="city" padding="0 16px" placeholder="Cidade" />
          </div>

          <div className="right-side">
            <Input name="number" padding="0 16px" placeholder="Número*" />
            <Input name="district" padding="0 16px" placeholder="Bairro*" />
            <Input name="state" padding="0 16px" placeholder="Estado*" />
          </div>
        </AddressContainer>
      </Wrapper>
      <FooterForm
        haveNext={false}
        haveBack={true}
        haveFinish={true}
        prevsState={prevFormStep}
        disabledButton={disabledFinishButton}
        labelFinishButton={'Publicar'}
      />
    </Form>
  );
}
