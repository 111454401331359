import styled from 'styled-components';

export const InputRadio = styled.input`
  accent-color: #009291;
  margin: 0 0.5rem 0;
  visibility: hidden;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
`;
export const LabelInput = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  justify-content: center;

  margin-left: 10px;
  background-color: ${(props) => (props.checked ? props.color : '#F6F6F6')};
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-image: url(${(props) =>
    props.checked ? props.SecundaryImg : props.primaryImg});
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 4px;

  &:hover:after {
    display: block;
  }

  &:after {
    content: '${(props) => (props.bubble ? props.bubble : 'asd')}';
    color: #4b4b4b;
    min-width: 100px;
    width: auto;
    background-color: #f6f6f6;
    padding: 3px;
    border-radius: 10px;
    text-align: center;
    margin-top: 80px;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

export const TitleLabel = styled.p``;

export const SubTitle = styled.p`
  margin-left: 40px;
  font-size: 14px;
  color: #606062;
  margin-top: 10px;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
`;
