import React from 'react';
import { Container } from './styles';

export default function InformationModal({
  title,
  description,
  universityColor,
}) {
  return (
    <Container universityColor={universityColor}>
      <h2>{title}</h2>
      {description && <p>{description}</p>}
    </Container>
  );
}
