import {
  Column,
  Divider,
  GrayBox,
  InfoIconWrapper,
  OutlinedBox,
  Row,
  StatusIconWrapper,
  Text,
  TextName,
} from '../../styles';
import { InfoCycleIcon } from 'assets/ComponentIcons/InfoCycleIcon';
import { SeeAdherenceButton } from './styles';
import { CursorIcon } from 'assets/ComponentIcons/CursorIcon';
import { CircleUserIcon } from 'assets/ComponentIcons/CircleUserIcon';
import { UniversityIcon } from 'assets/ComponentIcons/UniversityIcon';
import { ExclamationIcon } from 'assets/ComponentIcons/ExclamationIcon';
import { CourseStatus } from '../../context';
import { GrowthIcon } from 'assets/ComponentIcons/GrowthIcon';
import { useCallback, useMemo } from 'react';
import { FaCheck } from 'react-icons/fa';
import { Tooltip } from '../Tooltip';
import GenericMessage from 'components/GenericMessage';
import { LoadingContainer } from 'components/Styled';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';
import PadLockIcon from 'assets/ComponentIcons/padLock';
import RocketIcon from 'assets/ComponentIcons/RocketIcon';

export function CourseCard({
  course,
  handleOpenInfoIndicatorModal,
  handleAdherence,
  loadingAdherence,
  index,
  codeCourse,
  hasPedagogicalAdherenceAccess,
}) {
  function putDotsInNumber(number) {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  const status = useMemo(() => {
    const statusRatio =
      course.percentWorkingStudents / course.percentWorkingStudentsMedia;
    if (course.percentWorkingStudents === 100) {
      return CourseStatus.MAXIMUM_AVERAGE;
    }
    if (statusRatio === 1) {
      return CourseStatus.EQUAL_AVERAGE;
    } else if (statusRatio < 1) {
      return CourseStatus.BELOW_AVERAGE;
    } else {
      return CourseStatus.ABOVE_AVERAGE;
    }
  }, [course]);

  const inAreaStatus = useMemo(() => {
    const statusRatio =
      course.percentWorkingStudentsInArea /
      course.percentWorkingStudentsInAreaMedia;
    if (statusRatio === 1) {
      return CourseStatus.EQUAL_AVERAGE;
    } else if (statusRatio < 1) {
      return CourseStatus.BELOW_AVERAGE;
    } else {
      return CourseStatus.ABOVE_AVERAGE;
    }
  }, [course]);

  const statusIcon = useMemo(() => {
    const icons = {
      [CourseStatus.EQUAL_AVERAGE]: <GrowthIcon />,
      [CourseStatus.ABOVE_AVERAGE]: <FaCheck size={12} color={'#fff'} />,
      [CourseStatus.BELOW_AVERAGE]: <ExclamationIcon size={15} />,
      [CourseStatus.MAXIMUM_AVERAGE]: <RocketIcon size={40} />,
    };
    return icons[status] || <ExclamationIcon size={15} />;
  }, [status]);

  const inAreaStatusIcon = useMemo(() => {
    const icons = {
      [CourseStatus.EQUAL_AVERAGE]: <GrowthIcon size={12} />,
      [CourseStatus.ABOVE_AVERAGE]: <FaCheck size={10} color={'#fff'} />,
      [CourseStatus.BELOW_AVERAGE]: <ExclamationIcon size={12} />,
    };
    return icons[inAreaStatus] || <ExclamationIcon size={12} />;
  }, [inAreaStatus]);

  const tooltipText = useCallback((inArea = false) => {
    return {
      [CourseStatus.EQUAL_AVERAGE]: `Sua média de estudantes trabalhando ${
        (inArea && 'na área') || ''
      } é
        igual a de outras instituições`,
      [CourseStatus.ABOVE_AVERAGE]: `Parabéns! Sua média de estudantes trabalhando ${
        (inArea && 'na área') || ''
      } é maior do que a de  outras instituições.`,
      [CourseStatus.BELOW_AVERAGE]: `Você possui menos estudantes  trabalhando ${
        (inArea && 'na área') || ''
      } do que a média das outras instituições`,
      [CourseStatus.MAXIMUM_AVERAGE]: `Parabéns! 100% dos estudantes de ${course.name} ativos no sistema estão trabalhando`,
    };
  }, []);

  const toolTipStatusText = useMemo(() => tooltipText()[status], [status]);

  const coursesForTen = (value, total) => {
    const forTenPercent = total * 0.1;
    return Math.ceil(forTenPercent) - value;
  };

  const tooltipStatusInAreaText = useMemo(
    () => tooltipText(true)[inAreaStatus],
    [inAreaStatus]
  );

  const insufficientDataCourses =
    !course?.percentActiveStudents || course?.percentActiveStudents < 10;

  return (
    <OutlinedBox
      justifyContent={'flex-start'}
      padding={'36px 18px'}
      minHeight={'700px'}
      animate={index !== 0 ? 0.5 * index : 0.5}
      height="740px"
    >
      <InfoIconWrapper onClick={handleOpenInfoIndicatorModal}>
        <InfoCycleIcon />
      </InfoIconWrapper>
      <TextName green fontWeight={'700'} fontSize={'16px'}>
        {course.name.length < 50
          ? course.name
          : `${course.name.substring(0, 50)}...`}
      </TextName>
      <Row justifyContent={'flex-start'} marginTop={'10px'} gap={'0.5rem'}>
        <CursorIcon />
        <Text fontSize={'12px'}>
          {putDotsInNumber(course.activeStudents)} (
          {course.percentActiveStudents}%) estudantes ativos
        </Text>
      </Row>
      <Row justifyContent={'flex-start'} marginTop={'10px'} gap={'0.5rem'}>
        <CircleUserIcon />
        <Text fontSize={'12px'}>
          {putDotsInNumber(course.integratedStudents)} estudantes integrados
        </Text>
      </Row>
      <Row justifyContent={'flex-start'} marginTop={'10px'} gap={'0.5rem'}>
        <UniversityIcon />
        <Text fontSize={'12px'}>
          {course.courseType} | {course.modality}
        </Text>
      </Row>
      {insufficientDataCourses ? (
        <div
          style={{
            width: '100%',
            padding: 'initial',
            margin: '0',
            minHeight: '550px',
            display: 'flex',
          }}
        >
          <GenericMessage
            style={{
              width: '100%',
              padding: 'initial',
              margin: '0',
              fontSize: '10px',
            }}
            title="Conteúdo bloqueado"
            subtitle={`Para ter acesso a essa informação ative mais ${coursesForTen(
              course.activeStudents,
              course.integratedStudents
            )} estudantes desse curso.
            Entre em contato com o seu consultor e monte estratégias de engajamento desses estudantes.`}
            icon={
              <PadLockIcon
                width={'114'}
                height={'114'}
                colorCircle={'#FFF2E3'}
                colorPadLock={'#F09D51'}
              />
            }
          />
        </div>
      ) : (
        <>
          <Text green marginTop={'2rem'} fontSize={'15px'} fontWeight={'500'}>
            Estudantes trabalhando
          </Text>
          <GrayBox marginTop={'10px'} padding={'1rem'}>
            <Row justifyContent={'flex-start'} gap={'0.5rem'}>
              <Text
                fontSize={'30px'}
                fontWeight={'700'}
                width={'fit-content'}
                marginTop={'0.5rem'}
              >
                {course.percentWorkingStudents}%
              </Text>
              <Tooltip text={toolTipStatusText} width={'200px'}>
                <StatusIconWrapper size={'24px'} status={status}>
                  {statusIcon}
                </StatusIconWrapper>
              </Tooltip>
            </Row>
            <Text fontSize={'16px'} width={'fit-content'}>
              {putDotsInNumber(course.workingStudents)} estudantes
            </Text>
            <Text fontSize={'14px'} width={'fit-content'}>
              <b>Média:</b> {course.percentWorkingStudentsMedia}%
            </Text>
          </GrayBox>
          <Row marginTop={'2rem'} gap={'1rem'}>
            <Column gap={'0.5rem'}>
              <Text fontSize={'14px'} fontWeight={'500'}>
                Na área
              </Text>
              <GrayBox padding={'10px'} gap={'0.25rem'}>
                <Row justifyContent={'flex-start'} gap={'0.25rem'}>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    width={'fit-content'}
                    marginTop={'0.5rem'}
                  >
                    {course.percentWorkingStudentsInArea}%
                  </Text>
                  <Tooltip text={tooltipStatusInAreaText} width={'208px'}>
                    <StatusIconWrapper size={'18px'} status={inAreaStatus}>
                      {inAreaStatusIcon}
                    </StatusIconWrapper>
                  </Tooltip>
                </Row>
                <Text fontSize={'14px'} width={'fit-content'}>
                  {putDotsInNumber(course.studentsWorkingInArea)}
                </Text>
                <Text fontSize={'12px'} width={'fit-content'}>
                  <b>Média:</b> {course.percentWorkingStudentsInAreaMedia}%
                </Text>
              </GrayBox>
            </Column>
            <Column gap={'0.5rem'}>
              <Text fontSize={'14px'} fontWeight={'500'}>
                Fora da área
              </Text>
              <GrayBox padding={'10px'} gap={'0.25rem'}>
                <Row justifyContent={'flex-start'} gap={'0.25rem'}>
                  <Text
                    fontSize={'18px'}
                    fontWeight={'700'}
                    width={'fit-content'}
                    marginTop={'0.5rem'}
                  >
                    {course.percentWorkingStudentsOutArea}%
                  </Text>
                </Row>
                <Text fontSize={'14px'} width={'fit-content'}>
                  {putDotsInNumber(course.studentsWorkingOutArea)}
                </Text>
                <Text fontSize={'12px'} width={'fit-content'}>
                  <b>Média:</b> {course.percentWorkingStudentsOutAreaMedia}%
                </Text>
              </GrayBox>
            </Column>
          </Row>
          <Divider marginTop={'2rem'} marginBottom={'2rem'}></Divider>
          <Row gap={'1rem'}>
            <Text green fontSize={'14px'} fontWeight={'500'}>
              Estudantes não <br />
              trabalhando
            </Text>
            <GrayBox padding={'10px'} gap={'0.25rem'}>
              <Row justifyContent={'flex-start'} gap={'0.25rem'}>
                <Text
                  fontSize={'18px'}
                  fontWeight={'700'}
                  width={'fit-content'}
                  marginTop={'0.5rem'}
                >
                  {course.percentNotWorkingStudents}%
                </Text>
              </Row>
              <Text fontSize={'14px'} width={'fit-content'}>
                {putDotsInNumber(course.notWorkingStudents)}
              </Text>
              <Text fontSize={'12px'} width={'fit-content'}>
                <b>Média:</b> {course.percentNotWorkingStudentsMedia}%
              </Text>
            </GrayBox>
          </Row>
          <Row>
            {loadingAdherence && course.code === codeCourse ? (
              <LoadingContainer>
                <LoadingUpdate />
              </LoadingContainer>
            ) : (
              <Tooltip
                text={`Disponível para o plano Premium. Entre em contato
                  com o seu consultor através do e-mail
                  sucesso@workalove.com para saber mais.`}
                showTooltip={!hasPedagogicalAdherenceAccess}
              >
                <SeeAdherenceButton
                  disabled={
                    (course.code !== codeCourse && codeCourse !== null) ||
                    !hasPedagogicalAdherenceAccess
                  }
                  onClick={() => handleAdherence(course.code)}
                >
                  Ver aderência do plano pedagógico
                </SeeAdherenceButton>
              </Tooltip>
            )}
          </Row>
        </>
      )}
    </OutlinedBox>
  );
}
