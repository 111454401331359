import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  Container,
  ContainerInfo,
  FragmentColor,
  Header,
  DivSkeleton,
} from './styles';

export default function CardSkeletonNetwork({ universityColor }) {
  return (
    <Container color={universityColor} className="skeleton">
      <FragmentColor color={'#00000066'} />
      <Header>
        <DivSkeleton width="87px" height="87px" borderRadius="50px" />
        <br />
        <DivSkeleton width="200px" height="30px" />
      </Header>
      <ContainerInfo>
        <DivSkeleton width="200px" height="20px" />
        <DivSkeleton width="200px" height="20px" />
        <DivSkeleton width="200px" height="20px" />
        <br />
        <br />
        <DivSkeleton width="200px" height="31px" />
      </ContainerInfo>
    </Container>
  );
}
