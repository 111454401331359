import {
  ContainerInput,
  CustomCancellationForm,
  CallToActionButton,
  ContainerSendButton,
  ErrorMessage,
} from 'components/ListCandidates/Styles/ScheduleInterviewComponent';
import {
  Box,
  Wrapper,
  Content,
  TopBar,
  CloseButton,
} from 'components/ListCandidates/Styles/ModalComponents';
import { IoClose } from 'react-icons/io5';
import { Form } from '@unform/web';
import { useRef, useState } from 'react';
import { JoditTextArea } from 'components/UnformFields/JoditTextArea';
import { cancellationInterviewValidation } from 'components/ListCandidates/Utils/scheduleInterviewValidation';
import { updateScheduleInterview } from 'views/JobOfferRebuild/services';
import { updateScheduleInterviewUniversity } from 'views/StaffPages/jobOpeningsRebuild/services';
import * as Yup from 'yup';
import { loadJobsData } from 'components/ListCandidates/Utils/mainFunctions';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function CancelInterviewModal({
  cancelInterviewModal,
  setCancelInterviewModal,
  jobId,
  candidateId,
  interview,
  company,
  toView,
  history,
  setCandidates,
  isPartner,
}) {
  const cancelInterviewRef = useRef();
  let environmentType = company ? 'company' : 'university';
  let selectedTab = history ? 'history' : 'publishedJobs';
  const [error, setError] = useState('');

  const handleSubmit = async (data) => {
    const getUpdateInterview = {
      company: updateScheduleInterview,
      university: updateScheduleInterviewUniversity,
    };

    try {
      const schema = cancellationInterviewValidation;
      await schema.validate(data, {
        abortEarly: false,
      });

      await getUpdateInterview[environmentType](
        jobId,
        candidateId,
        interview.id,
        {
          status: 'canceled',
          interviewee_message: '',
          interviewer_message: data.interviewerMessage,
        }
      );
      await loadJobsData(
        environmentType,
        jobId,
        isPartner,
        selectedTab,
        setCandidates
      );
      setCancelInterviewModal(false);
      customSnackbar('Entrevista cancelada com sucesso!', 'confirmation');
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        cancelInterviewRef.current.setErrors(validationErrors);
      }
      setError(validationErrors.interviewerMessage);
    }
  };

  return (
    <Wrapper visible={cancelInterviewModal}>
      <Box>
        <Content>
          <TopBar>
            <p className="title">Cancelar Entrevista</p>
            <CloseButton onClick={() => setCancelInterviewModal(false)}>
              <IoClose size={18} />
            </CloseButton>
          </TopBar>
          <CustomCancellationForm>
            <div className="paragraph-description">
              Explique abaixo o motivo do cancelamento da entrevista*
            </div>
            <Form ref={cancelInterviewRef} onSubmit={handleSubmit}>
              <ContainerInput>
                <JoditTextArea
                  name="interviewerMessage"
                  placeholder="Digite aqui..."
                />
                {error ? <ErrorMessage> {error} </ErrorMessage> : null}
              </ContainerInput>
              <ContainerSendButton>
                <CallToActionButton>Confirmar</CallToActionButton>
              </ContainerSendButton>
            </Form>
          </CustomCancellationForm>
        </Content>
      </Box>
    </Wrapper>
  );
}
