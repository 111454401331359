import React from 'react';
import { func } from 'prop-types';

import ButtonLogoutStudent from 'components/LogoutButton/ButtonLogout';
import ButtonNotifications from 'components/Notifications/ButtonNotification';
import { Container } from './styled';

const HeaderLinks = (props) => {
  const { metadata } = props;
  const roleStudent = metadata?.role === 'student';

  return (
    <Container>
      {!roleStudent ? (
        <ButtonNotifications
          toggleNotificationDrawer={props.toggleNotificationDrawer}
        />
      ) : null
    }
      <ButtonLogoutStudent
        universityColor={props.universityColor}
        isStudent={props.isStudent}
        metadata={props.metadata}
      />
    </Container>
  );
};

HeaderLinks.propTypes = {
  toggleNotificationDrawer: func,
};

export default HeaderLinks;
