import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
  z-index: 300;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: ${(props) => (props.width ? props.width + 'px' : '415px')};
  height: ${(props) => (props.height ? props.height + 'px' : '418px')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;

  align-items: center;
  margin-top: 3.5em;
  height: 20%;
  margin-left: 100px;
  width: 100%;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: #009291;
  margin-left: 1.5em;
  border-radius: 4px;
  max-width: 180px;
  min-height: 49px;
  position: relative;
  padding: 0px 20px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid #009291;
    border-top: 4.5px solid #009291;
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 30px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #ffff;
  border: 2px solid #009291;
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 13px;
  font-weight: bold;
  color: #ffff;
`;

export const ParagraphStyled = styled.p`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '30px')};
  max-width: 312px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'start')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '400')};
`;

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width + 'px' : '290px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: ${({ active }) => (active ? '#009291' : '#F5F5F5')};
  color: ${({ active }) => (active ? '#ffffff' : '#949494')};
  border: none;
  border-radius: 4px;
  cursor: ${({ active }) => (active ? 'pointer' : 'not-allowed')};
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }

  &:active {
    transform: ${({ active }) => (active ? 'scale(0.9)' : 'scale(1)')};
  }
`;

export const WrapperButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 80%;
  margin-top: 30px;
`;

export const ButtonCancel = styled.button`
  border: none;
  width: ${(props) => (props.width ? props.width + 'px' : '100px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: #e1e1e1;
  color: #777777;
  cursor: pointer;
  border-radius: 4px;

  &:active {
    transform: scale(0.9);
  }

  &:disabled {
    filter: brightness(0.9);
    cursor: not-allowed;
  }
`;

export const ContainerInputDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 290px;
  gap: 48px;

  .labelInput {
    display: flex;
    flex-direction: column;

    > label {
      color: #606062;
      font-weight: bold;
      margin-bottom: 5px;
    }

    > input {
      width: 290px;
      height: 40px;
      padding: 0px 8px;
      margin-left: -2px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      box-sizing: border-box;

      &:focus {
        border: 2px solid #009291;
      }
    }
    footer {
      display: flex;
      margin-top: 6px;
      font-size: 12px;
      width: 100%;
      gap: 0.5rem;
      align-items: center;
      line-height: 1.5;
      padding: 0;
      justify-content: flex-start;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p {
    margin: 0;
  }
`;
