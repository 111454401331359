import React from 'react';
import { Container, StepItem } from './styles';

export default function StepIndicatorChallenges({ count, currentStep, onStepChange }) {
  return (
    <Container>
      {Array.from({ length: count }).map((_, index) => {
        return (
          <StepItem
            key={index}
            active={index + 1 === currentStep}
          >
            <span>{index + 1}</span>
          </StepItem>
        );
      })}
    </Container>
  );
}
