import React from 'react';
import { Background, ContentWrapper } from './styled';

function PreviewModal({ html, closeModal }) {
  return (
    <Background onClick={closeModal}>
      <ContentWrapper dangerouslySetInnerHTML={{ __html: html }} />
    </Background>
  );
}

export default PreviewModal;
