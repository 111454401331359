import { dnaGreen, dnaColor } from 'assets/jss/material-dashboard-pro-react';

import styled from 'styled-components';

export const FilterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    margin-top: 26px;
    display: flex;
  }
`;

export const SpanError = styled.p`
  font-size: 12px;
  text-align: start;
  right: 0;
  color: #f00;
  margin: 0;
`;

export const ContainerFlexDirection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  > span {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    padding: 0 10px 0 0;

    width: 100%;
    text-align: start;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  right: ${(props) => (props.hasFilters ? '8rem' : '6rem')};
  top: 1rem;
`;

export const Container = styled.div`
  height: 100%;
  min-height: 129px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1em 5em;

  position: relative;
`;

export const WrapperContent = styled.div`
  width: 100%;
  height: auto;
  min-height: 300px;
  margin-top: 36px;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const LoadingStyle = styled.div`
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.p`
  color: #009291;
  font-weight: 700;
  font-size: 24px;

  width: 100%;
  text-align: start;
  margin-bottom: 18px;
`;

export const ButtonExcel = styled.button`
  border: none;
  font-size: 13px;
  background-color: #009291;
  padding: 8px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 20px;
  color: #fff;
  width: 150px;
`;

const JobHeaderTab = (theme) => ({
  appBarHolder: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    marginTop: 10,
    alignSelf: 'flex-end',
    display: 'flex',
  },

  tabIndicator: {
    backgroundColor: dnaGreen,
    height: 3,
    zIndex: 2,
  },
  scrollButtons: {
    borderBottom: '1px solid #EDEDED',
    marginBottom: '-1px',
    [theme.breakpoints.down('lg')]: {
      color: '#777777',
    },
  },
  borderContainer: {
    border: '1px solid',
  },
  tabButton: {
    fontSize: 18,
    color: '#777777',
    fontWeight: '500',
    maxWidth: 'fit-content',
    textAlign: 'center',
    textTransform: 'none',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      minWidth: 180,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  selectLabel: {
    whiteSpace: 'nowrap',
  },
  cssLabel: {
    '&$cssFocused': {
      color: dnaColor,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  cssFocused: {},
});

export default JobHeaderTab;

export const ContainerIns = styled.div`
  display: flex;
  position: relative;
  z-index: 900;
`;
