import styled from 'styled-components';

export const ContainerAssociation = styled.div`
  background-color: #f6f6f6;
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 16px;

  &:nth-last-child(3) {
    margin-bottom: 60px;
  }

  div {
    display: flex;
    justify-content: space-between;

    > h6 {
      font-weight: 500;
      font-size: 13px;
    }
    > svg {
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  p {
    margin: 0;
  }
`;

export const DeleteIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerTags = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  flex-direction: column;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:not(:last-child):after {
    content: '';
    display: flex;
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    background-color: #e5e5e5;
  }
`;

export const AddTagButton = styled.button`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem 42px;
  background-color: #009291;
  color: #fff;
  height: 30px;
  width: 103px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  font-size: 12px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ContainerNameTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: 2px;

  > img {
    width: 17px;
  }
`;

export const ContainerInformationsTag = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;

  > svg {
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const DividerTag = styled.div`
  width: 2px;
  height: 19px;
  background-color: #009291;
`;
