const defineUrlFront = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'http://localhost:3000';
  }

  if (hostname.includes('worka.local')) {
    return 'http://worka.local';
  }

  if (hostname.includes('.dev')) {
    return 'https://workability.dev.worka.love';
  }

  if (hostname.includes('-stg')) {
    return 'https://workability-stg.worka.love';
  }

  if (hostname.includes('.hmg')) {
    return 'https://workability.hmg.worka.love';
  }

  return 'https://workability.worka.love';
};

export default defineUrlFront;
