import React, { useState, useContext, useEffect } from 'react';
import { HeaderTabs, ContentTabs, ButtonMatch } from '../styled';
import ModalMatchInformation from '../../Modals/ModalMatchInfo';
import FilterInputName from '../../FilterModal/FilterName';
import FavoriteJobOffers from './FavoriteCards';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import SkeletonCards from '../../GenericComponents/SkeletonCards';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';

export default function FavoriteJobsTab(props) {
  const {
    listFavoriteJobs,
    setListFavoriteJobs,
    search,
    setSearch,
    isLoadingFavorites,
    setIsLoadingFavorites,
  } = useContext(JobOffersContext);
  const { universityColor } = props;
  const [openModalMatch, setOpenModalMatch] = useState(false);
  const { IconInformation } = wrapperIconsJobOffers();

  const fetchListFavoriteJobOffers = async (
    query = '',
    showLoadingEffect = true
  ) => {
    if (showLoadingEffect) {
      setIsLoadingFavorites(true);
    }
    const data = await JobOffersApi.getListFavoriteJobOffers(query);
    setListFavoriteJobs && setListFavoriteJobs(data);
    if (showLoadingEffect) {
      setIsLoadingFavorites(false);
    }
  };

  useEffect(() => {
    fetchListFavoriteJobOffers();
  }, []);

  const onClickModal = () => {
    setOpenModalMatch(true);
  };

  return (
    <ContentTabs>
      {openModalMatch && (
        <ModalMatchInformation setOpenModalMatch={setOpenModalMatch} />
      )}
      <HeaderTabs>
        <FilterInputName
          placeholder="Buscar por vaga"
          universityColor={universityColor}
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonMatch universityColor={universityColor} onClick={onClickModal}>
          <IconInformation color={universityColor} />
          Entenda o match
        </ButtonMatch>
      </HeaderTabs>

      {isLoadingFavorites ? (
        <SkeletonCards />
      ) : (
        <FavoriteJobOffers
          search={search}
          universityColor={universityColor}
          listFavoriteJobOffers={listFavoriteJobs}
          fetchListFavoriteJobOffers={fetchListFavoriteJobOffers}
        />
      )}
    </ContentTabs>
  );
}
