export default function AcademicLanguageIcon({ color }) {
  return (
    <svg
      width="30"
      height="33"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0923 15.4894C25.2149 10.0974 21.3501 6.96782 15.6252 6.3402C11.7252 5.91267 8.26909 7.13114 5.38685 9.77329C0.696975 14.0726 -0.299332 20.8361 2.96749 26.282C4.99882 29.6689 8.01958 31.7373 11.9153 32.5308C12.3266 32.6163 12.7585 32.5907 13.1508 32.7668H13.4089C13.4434 32.7284 13.495 32.7352 13.5354 32.7335H13.5604C13.5794 32.731 13.5987 32.7327 13.617 32.7384C13.6353 32.7442 13.6521 32.7539 13.6662 32.7668H13.9243C13.9852 32.741 14.0508 32.7277 14.117 32.7277C14.1833 32.7277 14.2488 32.741 14.3097 32.7668H14.5033C14.553 32.7411 14.6082 32.7277 14.6642 32.7277C14.7202 32.7277 14.7754 32.7411 14.8251 32.7668H15.2114C15.2195 32.7577 15.2294 32.7505 15.2405 32.7455C15.2517 32.7405 15.2637 32.7379 15.2759 32.7379C15.2881 32.7379 15.3002 32.7405 15.3113 32.7455C15.3225 32.7505 15.3324 32.7577 15.3405 32.7668H15.5332C15.5427 32.7155 15.5805 32.707 15.6261 32.7036C18.2451 32.4684 20.5784 31.5227 22.651 29.9203C26.9924 26.5591 28.887 20.6446 27.0923 15.4894ZM14.4018 31.4791C7.7718 31.4791 2.33856 26.0648 2.36868 19.4962C2.39879 12.8815 7.76233 7.55183 14.3941 7.54499C21.0258 7.53815 26.4392 12.8994 26.4461 19.5039C26.4521 26.0931 21.0327 31.4808 14.4018 31.4774V31.4791Z"
        fill={color}
      />
      <path
        d="M29.8724 6.90211C29.8655 7.04833 29.758 7.11673 29.6332 7.17231C29.2624 7.33478 28.8968 7.51434 28.5225 7.66654C28.4483 7.69505 28.3818 7.74022 28.328 7.79851C28.2743 7.8568 28.2349 7.92664 28.2128 8.00258C28.0803 8.39506 27.94 8.78497 27.7964 9.17316C27.743 9.31682 27.7284 9.51519 27.5228 9.52203C27.3171 9.52887 27.2956 9.33221 27.2423 9.18514C27.0977 8.78582 26.9463 8.38822 26.8121 7.98804C26.7924 7.91667 26.7561 7.85087 26.7062 7.79592C26.6563 7.74098 26.5942 7.69843 26.5247 7.67167C26.1711 7.52203 25.8235 7.35786 25.4733 7.20139C25.3262 7.13555 25.1636 7.08595 25.167 6.88074C25.167 6.69177 25.3228 6.6473 25.4596 6.58659C25.8106 6.43012 26.1582 6.2668 26.5118 6.11716C26.5857 6.08815 26.6519 6.04258 26.7052 5.984C26.7584 5.92542 26.7974 5.8554 26.819 5.77941C26.9583 5.35188 27.1141 4.93803 27.2672 4.51905C27.3102 4.40105 27.3455 4.26253 27.5606 4.26253C27.6725 4.26766 27.7198 4.38651 27.7637 4.5105C27.9134 4.93033 28.0708 5.34846 28.2111 5.77172C28.2345 5.85263 28.2768 5.92692 28.3345 5.98856C28.3922 6.05021 28.4637 6.09749 28.5432 6.12657C28.9071 6.27791 29.2659 6.4438 29.6272 6.60369C29.7572 6.65927 29.8802 6.7311 29.8724 6.90211Z"
        fill={color}
      />
      <path
        d="M9.23107 1.95382C9.23107 2.08721 9.13041 2.12996 9.03147 2.17357C9.02181 2.17844 9.01172 2.18244 9.00135 2.18554C8.22702 2.36596 7.8433 2.89439 7.69446 3.63915C7.67037 3.758 7.64714 3.91448 7.47249 3.90165C7.32536 3.89139 7.31847 3.74603 7.27976 3.63915C7.26911 3.62091 7.26018 3.60173 7.25309 3.58186C7.13436 2.83881 6.71106 2.37365 5.97631 2.18469C5.86188 2.15561 5.74745 2.10687 5.74659 1.95809C5.74573 1.80931 5.86532 1.75544 5.97631 1.72894C6.75666 1.54766 7.13177 1.01496 7.28234 0.27533C7.30729 0.153056 7.3374 0.0188114 7.52152 0C7.55967 0.0286771 7.59431 0.0616775 7.62476 0.0983323C7.65472 0.14223 7.6755 0.191662 7.68586 0.243693C7.84158 1.00299 8.22273 1.55707 9.03061 1.7392C9.08465 1.74441 9.1349 1.76913 9.17184 1.80868C9.20878 1.84823 9.22985 1.89987 9.23107 1.95382Z"
        fill={color}
      />
      <path
        d="M2.35867 6.8289C2.35744 6.98097 2.32605 7.13131 2.26629 7.27132C2.20654 7.41132 2.11959 7.53824 2.01043 7.64482C1.90127 7.7514 1.77204 7.83554 1.63013 7.89244C1.48822 7.94933 1.33642 7.97787 1.1834 7.9764C1.02748 7.97828 0.872759 7.94914 0.728336 7.8907C0.583913 7.83226 0.45271 7.7457 0.34245 7.63612C0.232189 7.52653 0.145098 7.39614 0.086302 7.2526C0.0275057 7.10907 -0.00180756 6.9553 8.62423e-05 6.80034C0.00198004 6.64538 0.0350429 6.49235 0.0973298 6.35028C0.159617 6.20821 0.249866 6.07995 0.362772 5.97306C0.475678 5.86617 0.608963 5.78281 0.754771 5.72787C0.90058 5.67294 1.05597 5.64754 1.2118 5.65318C1.36482 5.65541 1.5159 5.6876 1.65639 5.74792C1.79688 5.80824 1.92402 5.89549 2.03054 6.00469C2.13707 6.1139 2.22088 6.2429 2.27719 6.38433C2.33349 6.52575 2.36118 6.67683 2.35867 6.8289Z"
        fill={color}
      />
      <path
        d="M23.909 2.44371C23.9085 2.64962 23.8256 2.84687 23.6787 2.99207C23.5318 3.13727 23.3329 3.21852 23.1257 3.21795C22.9185 3.21739 22.7201 3.13505 22.574 2.98905C22.4279 2.84305 22.3461 2.64535 22.3467 2.43944C22.347 2.33748 22.3675 2.23658 22.407 2.14249C22.4465 2.0484 22.5043 1.96297 22.577 1.89108C22.6498 1.81918 22.736 1.76223 22.8309 1.72348C22.9258 1.68472 23.0274 1.66491 23.13 1.66519C23.2326 1.66548 23.3341 1.68584 23.4288 1.72511C23.5235 1.76439 23.6094 1.82181 23.6818 1.8941C23.7541 1.9664 23.8114 2.05214 23.8504 2.14644C23.8894 2.24074 23.9093 2.34176 23.909 2.44371Z"
        fill={color}
      />
      <path
        d="M22.7386 19.1041H9.31685C8.17944 19.1041 7.04289 19.1041 5.90548 19.1041C5.3686 19.1041 5.14577 19.3418 5.14749 19.8685C5.14626 20.2951 5.17877 20.7211 5.24472 21.1426C5.62232 23.5552 6.94658 25.7208 8.92749 27.1651C10.9084 28.6094 13.3845 29.2147 15.8135 28.8484C20.1894 28.1985 23.5336 24.4345 23.6515 20.0258C23.6756 19.293 23.488 19.1041 22.7386 19.1041ZM19.0803 26.1643C17.6719 27.1494 16.1164 27.6607 14.4154 27.6624C12.6671 27.6564 11.1142 27.1357 9.70919 26.1464C9.50442 26.0027 9.51129 25.9104 9.65068 25.7317C10.4422 24.7176 11.5013 24.1344 12.7342 23.8505C14.541 23.4375 16.2618 23.6299 17.8758 24.5799C18.362 24.8744 18.7904 25.2539 19.1406 25.7C19.2972 25.8967 19.3118 26.0036 19.0812 26.1643H19.0803ZM22.2973 20.7928C22.1018 22.3122 21.4585 23.7402 20.4483 24.8971C20.3847 24.9707 20.3322 25.1537 20.1756 24.9536C19.0726 23.5273 17.5644 22.7757 15.8213 22.4867C13.7607 22.1447 11.8136 22.4824 10.0439 23.6334C9.45329 24.0177 8.94077 24.5091 8.53305 25.0818C7.858 24.3624 7.32869 23.5205 6.97406 22.6021C6.72899 21.9658 6.56398 21.3018 6.48279 20.6252C6.45698 20.4286 6.5129 20.3764 6.69272 20.3961C6.7569 20.3999 6.82126 20.3999 6.88544 20.3961H21.9419C22.3463 20.3961 22.3498 20.3961 22.2981 20.7928H22.2973Z"
        fill={color}
      />
      <path
        d="M12.0514 15.8424C12.0574 16.2366 11.8071 16.5119 11.4492 16.5307C11.0689 16.5504 10.7961 16.2956 10.7695 15.8834C10.7178 15.084 10.3393 14.5059 9.58214 14.2178C8.44989 13.786 7.24021 14.5923 7.16708 15.8056C7.1361 16.3247 6.8126 16.6103 6.3652 16.5145C6.0288 16.4418 5.86447 16.1767 5.87824 15.7287C5.9247 14.1895 7.19546 12.936 8.82845 12.8214C10.2997 12.718 11.74 13.8475 11.9963 15.3011C12.0294 15.4798 12.0479 15.6608 12.0514 15.8424Z"
        fill={color}
      />
      <path
        d="M22.9407 15.7632C22.9519 16.2317 22.7196 16.5216 22.323 16.5327C21.9264 16.5438 21.6794 16.2762 21.6502 15.8042C21.6279 15.3895 21.4611 14.9954 21.1782 14.6897C20.8954 14.384 20.5142 14.1857 20.1003 14.129C19.6863 14.0722 19.2654 14.1605 18.9098 14.3787C18.5542 14.5968 18.2862 14.9313 18.1518 15.3245C18.0928 15.5068 18.0578 15.6959 18.0477 15.8871C18.0193 16.2984 17.7457 16.5524 17.3663 16.531C17.0032 16.5105 16.764 16.2369 16.7589 15.841C16.746 14.2061 18.231 12.7738 19.8958 12.8157C21.5607 12.8576 22.902 14.1642 22.9407 15.7632Z"
        fill={color}
      />
    </svg>
  );
}
