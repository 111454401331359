import { Container } from './styles';
import { useRef } from 'react';

export function Tooltip({
  children,
  text,
  width,
  showTooltip = true,
  marginBottom,
  ...props
}) {
  const ref = useRef(null);
  return (
    <Container width={width} {...props} marginBottom={marginBottom}>
      {children}
      {showTooltip && (
        <span className={'tooltip'} ref={ref}>
          {text}
        </span>
      )}
    </Container>
  );
}
