import React from 'react';
import { Elipsis } from '../styles';

export default function FilterLoading({ color, width, height }) {
  return (
    <Elipsis color={color} width={width} height={height}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Elipsis>
  );
}
