import moment from 'moment';

export const DateHelper = {
  formatRange: (initialDate, endDate) => {
    const formatedInitialDate = moment(initialDate).format('DD/MM/YYYY');
    const formatedEndDate = moment(endDate).format('DD/MM/YYYY');

    return `De ${formatedInitialDate} a ${formatedEndDate}`;
  },

  format: (date) => {
    const formartedDate = moment(date).format('DD/MM/YYYY');

    return formartedDate;
  },

  formatLabelPeriodDay: (start, end) => {
    try {
      const parseTime = (time) => new Date(`2000-01-01T${time}`);

      const limits = {
        morning: {
          start: parseTime('06:00:00'),
          end: parseTime('12:00:00'),
        },
        afternoon: {
          start: parseTime('12:00:00'),
          end: parseTime('18:00:00'),
        },
        night: {
          start: parseTime('18:00:00'),
          end: parseTime('23:59:59'),
        },
      };

      const startObj = parseTime(start);
      let endObj = parseTime(end);

      // consider the end time as being from the next day
      if (endObj < startObj) {
        endObj = new Date(endObj.getTime() + 24 * 60 * 60 * 1000); // add one day in milliseconds
      }

      const morningIncluded =
        startObj <= limits.morning.end && endObj >= limits.morning.start;
      const afternoonIncluded =
        startObj <= limits.afternoon.end && endObj >= limits.afternoon.start;
      const nightIncluded =
        startObj <= limits.night.end && endObj >= limits.night.start;

      let resultado = '';

      if (morningIncluded) {
        resultado += 'Manhã';
      }

      if (afternoonIncluded) {
        resultado += resultado ? ' e Tarde' : 'Tarde';
      }

      if (nightIncluded) {
        resultado += resultado ? ' e Noite' : 'Noite';
      }

      return resultado || 'Fora dos limites de manhã, tarde e noite.';
    } catch (error) {
      return 'Formato de horário inválido. Use o formato HH:MM:SS.';
    }
  },

  formatDateWithHour: (date) => {
    return moment(date).format('DD/MM/YYYY [às] HH[h]');
  },
};
