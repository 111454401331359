import styled from 'styled-components';

export const GraphicContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 30px;
  height: 100%;

  padding: 20px;
  padding-left: 0px;

  position: relative;
  box-sizing: border-box;

  > svg {
    margin-right: 4px;
    margin-bottom: -3px;
  }

  .box-title {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .box-title svg {
    margin-bottom: 4px;

  }

  .content {
    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-wrap: wrap;

    margin-bottom: 30px;
    padding-left: 31px;
    height: ${({loading}) => loading && '195px'}

    
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;

  .left-side {
    width: 60%;
  }

  .right-side {
    width: 46%;
  }

  @media (max-width: 1387px) {
    .right-side {
      width: 60%;
    }
  }
`;

export const LegendArea = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 30px;

  .legend {
    display: flex;
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
    height: 120px;
  }

  .columns {
    width: 50%;
    display: flex;
    align-items: flex-start;
  }

  .columns span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 400;
    color: #606062;
  }

  .columns svg {
    margin-top: 5px;
    flex: none;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const DisplayNumber = styled.div`
  top: 4px;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${(props) => `${props.size}px`};
  font-weight: bold;
  font-size: 15px;
`;

export const DisplayLabel = styled.span`
  color: #606062;
  max-width: 90px;
  margin-top: 10px;
  font-weight: 400;
`;

export const ContainerLegend = styled.div`
  margin-bottom: 20px;
  display: flex;
  gap: 15px;
  margin-left: 8px;
`;

export const GraphicCapsule = styled.div`
  position: relative;
  height: ${({ heightGraphic }) => heightGraphic + 'px'};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: ${({ marginRight }) => marginRight + 'px'};
`;

export const Paragraph = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '•';
    font-size: 55px;
    border-radius: 50%;
    color: ${(props) => props.color};
    padding-right: 5px;
  }
`;

export const ContainerToolTip = styled.div`
  min-width: 217px;
  height: auto;
  padding: 2px;
  background: #000000bf;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const TooltipLegend = styled.div`
  position: absolute;
  left: ${(props) => props.coordinate - 95 + 'px'};
  top: 200px;
  overflow: visible;
  width: 150;
  background: white;
  border: 1px solid grey;

  background: #000000bf;

  width: 180px;
  border-radius: 3px;
  text-align: center;
  padding: 5px;

  span {
    font-size: 13px;
    color: #fff;
    font-weight: 400;
  }

  :after {
    content: '';

    width: 0;
    height: 0;

    position: absolute;

    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;

    bottom: -5px;
    left: 50%;
  }
`;

export const HeaderToolTip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: 15px;

  p {
    margin: 0;
  }
`;

export const ToolTipP = styled.p`
  color: #fff;
  margin: 0;
  font-size: 14px;
`;


export const ContentCircularProgress = styled.div`
  z-index: 1;


  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    padding-top: 10px;
    text-align: center;
    width: 84px;
    font-weight: 400;
    color: #606062;
  }
  
  
  .circle-background {
    fill: none;
    stroke: #D8EDED;
  }

  .circle-progress {
    fill: none;
    stroke: #009291;
    stroke-linecap: initial;
    stroke-linejoin: initial;
  }

  svg {
    width: ${({ totalWidth }) => `${totalWidth}px`};
    height: 100%;
    object-fit: fill;
  }

  .circle-text-match {
    font-size: 22px;
    font-weight: 500;
    color: #606062;
  }


`