import { useCallback, useEffect, useState } from 'react';
import {
  WrapperPhotos,
  Container,
  Box,
  Content,
  Header,
  ButtonLeft,
  ButtonRight,
} from './styled';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';

export default function Gallery({ data, calculateMargin }) {
  const { photos } = data;

  useEffect(() => {
    calculateMargin();
  }, []);

  const [isActiveSlideShow, setIsActiveSlideShow] = useState(false);
  const [indexPhoto, setIndexPhoto] = useState(0);

  const handleClickImage = (index) => {
    setIsActiveSlideShow(true);
    setIndexPhoto(index);
  };

  const mappedPhotos = () => {
    return photos.map((item, index) => (
      <img onClick={() => handleClickImage(index)} src={item.file} />
    ));
  };

  const handleClickPrevs = () => {
    if (photos[indexPhoto - 1]?.file) {
      setIndexPhoto((prevs) => prevs - 1);
    }
  };

  const handleClickNext = () => {
    if (photos[indexPhoto + 1]?.file) {
      setIndexPhoto((prevs) => prevs + 1);
    }
  };

  const handleClickClose = useCallback(() => {
    setIsActiveSlideShow(false);
  }, []);

  const SlideShow = () => {
    if (isActiveSlideShow) {
      return (
        <Container>
          <Box>
            <ButtonLeft onClick={handleClickPrevs} />

            <Content>
              <Header>
                <h4>Galeria de fotos</h4>

                <CloseButton onClick={handleClickClose} />
              </Header>
              <img src={photos[indexPhoto].file} />
            </Content>

            <ButtonRight onClick={handleClickNext} />
          </Box>
        </Container>
      );
    }
  };

  return (
    <WrapperPhotos>
      {SlideShow()}
      {mappedPhotos()}
    </WrapperPhotos>
  );
}
