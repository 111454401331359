import styled from 'styled-components';

const getBackgroundColor = (props) => {
  return props.universityColor ? props.universityColor : '#009291';
};

export const ButtonReward = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 30px;
  background-color: transparent;
  border: 1px solid ${getBackgroundColor};
  color: ${getBackgroundColor};
  border-radius: 4px;
  cursor: pointer;
`;

export const ButtonSeeReward = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  width: auto;
  height: 40px;
  background-color: ${getBackgroundColor};
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
`;

export const ButtonCopyLink = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 15px;
  width: auto;
  height: 40px;
  background-color: #fff;
  border: ${getBackgroundColor} 1px solid;
  color: ${getBackgroundColor};
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 16px;
`;

export const BackgroundModalReward = styled.div`
  background-color: #00000040;

  top: 0;
  left: 0;
  right: 0;

  width: 100%;
  height: 100vh;

  position: fixed;
  z-index: 10000;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalReward = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 700px;
  height: auto;

  background-color: #fff;
  border-radius: 10px;
  padding: 40px;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const ButtonCloseModal = styled.button`
  width: 40px;
  height: 40px;

  border-radius: 50%;
  background-color: #efeeec;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TitleModal = styled.h1`
  text-align: start;
  font-size: 24px;
  color: #606062;
  font-weight: 700;
  margin: 0;
`;

export const SectionRewardLevel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

export const SectionButtonLevel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  transition: 0.5s;
  margin-bottom: 10px;

  @media (max-width: 520px) {
    transition: 0.5s;
    flex-direction: column;
    align-items: start;
    margin-bottom: 20px;
    gap: 15px;
  }

  div.buttonsContainer {
    display: flex;
    flex-direction: column;
  }
`;

export const ContainerIconReward = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  transition: 0.5s;

  @media (max-width: 450px) {
    transition: 0.5s;
    width: 180px;
  }
`;

export const IconReward = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  border-radius: 8px;
  box-shadow: -4px 4px 6px #0000004a;
`;

export const TitleReward = styled.p`
  text-align: start;
  font-size: 16px;
  color: #606062;
  margin: 0;

  font-weight: ${(props) => (props.isParagraph ? '400' : '700')};
`;

export const StatusReward = styled.p`
  text-align: start;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  font-style: italic;
  color: ${(props) => (props.returnColor ? props.returnColor : '#606062')};
`;

export const ParagraphReward = styled.p`
  text-align: start;
  font-size: 14px;
  color: #606062;
  margin: 10px 0 0 0;
  width: 100%;
  max-width: 312px;
  font-weight: 400;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
  margin: 30px 0;
`;
