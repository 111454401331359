export default function ImageDefault({ color, size }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 35.0002C7.41667 35.0002 6.63194 34.6739 5.97917 34.0211C5.32639 33.3683 5 32.5836 5 31.6669V8.33358C5 7.41691 5.32639 6.63219 5.97917 5.97941C6.63194 5.32663 7.41667 5.00024 8.33333 5.00024H31.6667C32.5833 5.00024 33.3681 5.32663 34.0208 5.97941C34.6736 6.63219 35 7.41691 35 8.33358V31.6669C35 32.5836 34.6736 33.3683 34.0208 34.0211C33.3681 34.6739 32.5833 35.0002 31.6667 35.0002H8.33333ZM8.33333 31.6669H31.6667V8.33358H8.33333V31.6669ZM10 28.3336H30L23.75 20.0002L18.75 26.6669L15 21.6669L10 28.3336Z"
        fill="#C4C4C4"
      />
    </svg>
  );
}
