import axios from 'axios';
import getCookieValue from '../../../../utils/getCookieValue';
import URLS from '../../../../constants/urls';

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const getEnrolledExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/enrolled-extension-project${page}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getNotSelectedExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/not-selected-extension-project${page}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getFinishedExtensionProject = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/finished-extension-project${page}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getWaitingEvidence = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/waiting-evidences-extension-project${page}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const postCreateFileEvidenceStudent = async (id, body, type) => {
  const currentType =
    type.includes('.png') || type.includes('.jpg') || type.includes('.jpeg')
      ? 'image'
      : 'file';

  const dataFile = new FormData();
  dataFile.append(currentType, body);
  dataFile.append('type', currentType);

  const response = authenticatedAPI
    .post(
      `/api/internal/students/extension-project/${id}/evidence/file`,
      dataFile,
      {
        headers: {
          'Content-Type': 'application/x-zip-compressed',
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const postCreateLinkEvidenceStudent = async (id, body, uniqueLink) => {
  const type = uniqueLink ? 'link_unique' : 'link';

  const response = authenticatedAPI
    .post(`/api/internal/students/extension-project/${id}/evidence/link`, {
      ...body,
      type: type,
    })
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deleteEvidenceStudent = (id, evidenceId) => {
  const response = authenticatedAPI
    .delete(
      `/api/internal/students/extension-project/${id}/evidence/${evidenceId}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const putEditLinkEvidenceStudent = (id, idEvidence, body, uniqueLink) => {
  const type = uniqueLink ? 'link_unique' : 'link';

  const response = authenticatedAPI
    .put(
      `/api/internal/students/extension-project/${id}/evidence/${idEvidence}`,
      { ...body, type: type }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getProjectEvidence = (id, query = '') => {
  const response = authenticatedAPI
    .get(`/api/internal/students/extension-project/${id}/evidence?${query}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const unsubscribeProject = (extensionId) => {
  const response = authenticatedAPI
    .put(`/api/internal/students/me/extension-project/${extensionId}/unenroll`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};
const getImagesEnrolled = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/enrolled-extension-project-images${page}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getImagesNotSelected = (page) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/students/me/not-selected-extension-project-images${page}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getImagesFinished = (page) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/finished-extension-project-images${page}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getDetails = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/extension-project/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getExtensionProjectFeedback = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/extension-project/${id}/feedback`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const deleteEvidence = (id, evidenceId) => {
  const response = authenticatedAPI
    .delete(
      `/api/internal/university/extension-project/${id}/evidence/<int:evidence_id></${evidenceId}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getImagesAwaitEvidence = (page) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/students/me/waiting-evidences-extension-project-images${page}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getImagesStudent = (id) => {
  const response = authenticatedAPI
    .get(`api/internal/students/extension-project/evidence/${id}/download`, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'image/*',
      },
    })
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export {
  getEnrolledExtensionProject,
  getNotSelectedExtensionProject,
  getFinishedExtensionProject,
  unsubscribeProject,
  getImagesAwaitEvidence,
  getImagesEnrolled,
  getImagesNotSelected,
  getImagesFinished,
  getDetails,
  getExtensionProjectFeedback,
  deleteEvidence,
  getWaitingEvidence,
  postCreateFileEvidenceStudent,
  postCreateLinkEvidenceStudent,
  deleteEvidenceStudent,
  putEditLinkEvidenceStudent,
  getProjectEvidence,
  getImagesStudent,
};
