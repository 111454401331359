const {
  getJobsOpeningsCompany,
  getHistoryInstitution,
} = require('views/JobOfferRebuild/services');

// vagas normais da empresa
export const reloadProgressJobs = async (
  setProgressJobs,
  dataFunction,
  params = ''
) => {
  setProgressJobs(null);
  dataFunction.handlePageChange('limit=0', params);
};

// histórico das vagas da empresa
export const loadJobsProgressCompany = async (
  setHistoryJobs,
  dataFunction,
  params = ''
) => {
  setHistoryJobs(null);
  dataFunction.handlePageChange('limit=0', params);
};
