import React, { useContext } from 'react';
import {
  Title,
  Paragraph,
  ContainerCards,
  Card,
  IconImg,
  Text,
  Infos,
  TextComposition,
} from '../styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import useStepTwoSorting from './useStepTwoSorting';

export default function StepTwo() {
  const { stepTwoData } = useContext(ProfileContext);
  const gamificationItems = useStepTwoSorting(stepTwoData);

  return (
    <>
      <Title>Jornada do protagonismo</Title>
      <Paragraph>
        Chegou a hora de se destacar! Durante a sua Jornada do protagonismo,
        dentre as 5 categorias abaixo indique pelo menos 10 conhecimentos que
        você já conquistou e ganhe o selo de perfil campeão.
      </Paragraph>

      <ContainerCards>
        {gamificationItems.map((item, index) => (
          <Card key={index}>
            <IconImg>{item.icon}</IconImg>
            <TextComposition>
              <Text>{item.title}</Text>
              <Infos>{item.info}</Infos>
            </TextComposition>
          </Card>
        ))}
      </ContainerCards>
    </>
  );
}
