import React, { Fragment } from 'react';
import { bool, shape, func } from 'prop-types';
import { FastField } from 'formik';
import { FormHelperText } from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import { InfoIcon, AlignCenterView } from 'components/Styled';

function QuestionThree({
  isSubmitting,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) {
  return (
    <Fragment>
      <p>
        O futuro é o resultado do que escolhemos viver no momento presente. Para
        isso, o primeiro passo é ter clareza de qual futuro você quer viver,
        detalhando o que você estará fazendo, quem estará com você, onde você
        estará, etc. Em seguida, é fundamental definir qual será a sua
        estratégia ou as ações que você irá realizar para tornar esse futuro
        possível.
      </p>
      <AlignCenterView>
        <InfoIcon color="#eb9b00" height="25px" /> Dica: Lembre-se de que o
        recrutador quer descobrir como a oportunidade (de estágio, emprego,
        trainee, etc.) irá contribuir para que você alcance seus objetivos
        enquanto gera valor para a organização.
      </AlignCenterView>
      <FastField
        name="questionThree"
        label="Conte onde você quer chegar"
        variant="outlined"
        id="questionThree"
        placeholder="Resposta"
        multiline
        rowsMax="15"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.questionThree && touched.questionThree}
        value={values.questionThree}
        component={CustomTextField}
        fullWidth
        disabled={isSubmitting}
      />
      <FormHelperText>
        {values.questionThree &&
          `Sua resposta tem ${values.questionThree.length} caracteres.`}
      </FormHelperText>
      {errors.questionThree && touched.questionThree ? (
        <FormHelperText>{errors.questionThree}</FormHelperText>
      ) : null}
    </Fragment>
  );
}

QuestionThree.propTypes = {
  isSubmitting: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
};

export default QuestionThree;
