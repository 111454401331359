import styled from '@react-pdf/styled-components';

export const Body = styled.Page`
  display: flex;
  flex-direction: column;
  padding: 40px 16px;
  font-weight: normal;
  font-size: 11px;
`;

export const Content = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 60px;
`;

export const NameText = styled.Text`
  font-size: 27px;
  color: #606062;
  margin: 0 0 16px 0;
  padding: 0;
  font-family: 'Helvetica-Bold';
`;

export const Row = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;

export const RowNoWrap = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  white-space: nowrap;
`;

export const HeaderIcon = styled.Image`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

export const HeaderText = styled.Text`
  font-size: 12px;
  color: #949494;
  margin-top: 2px;
  margin-right: 32px;
  max-width: 90%;
`;

export const SubTitleText = styled.Text`
  font-size: 16px;
  color: #606062;
  margin-bottom: 16px;
  font-family: 'Helvetica-Bold';
`;

export const InterestsTitle = styled.Text`
  font-size: 13px;
  color: #606062;
  margin-bottom: 4px;
  font-family: 'Helvetica-Bold';
`;

export const InterestsText = styled.Text`
  font-size: 10px;
  color: #777777;
`;

export const InterestsTextContent = styled.View`
  border: 1.2px;
  border-radius: 5px;
  color: #606062;
  border-color: #606062;
  padding: 4px;
  margin-right: 8px;
  margin-bottom: 4px;
`;

export const Divider = styled.View`
  background-color: #c4c4c4;
  height: 1px;
  width: 100%;
  margin-bottom: -9px;
  margin-top: 8px;
`;

export const DividerHalf = styled.View`
  margin-bottom: 16px;
  margin-top: 8px;
  background-color: #c4c4c4;
  height: 1px;
  width: 20%;
`;

export const PersonalityItem = styled.View`
  list-style-type: disc;
`;

export const StrongText = styled.Text`
  font-size: 12px;
  color: #606062;
  font-family: 'Helvetica-Bold';
`;

export const Text = styled.Text`
  font-size: 12px;
  color: #606062;
  max-width: 95%;
`;

export const LanguageText = styled.Text`
  font-size: 8px;
  color: #606062;
  max-width: 95%;
  font-style: italic;
  margin-top: -7px;
  margin-left: 11px;
  margin-bottom: 8px;
`;

export const ElipseIcon = styled.Image`
  width: 5px;
  height: 5px;
  margin-right: 4px;
  margin-top: 5px;
  margin-left: 2px;
`;

export const TextWithMarginLeft = styled.Text`
  font-size: 11px;
  color: #606062;
  max-width: 95%;
  margin-left: 11px;
`;

export const ExpirienceDescriptText = styled.Text`
  font-size: 11px;
  color: #606062;
  max-width: 95%;
  margin-left: 11px;
  margin-bottom: 16px;
`;

export const ExpirienceTextBold = styled.Text`
  font-size: 11px;
  color: #606062;
  max-width: 95%;
  font-family: 'Helvetica-Bold';
`;

export const RowWithNoBorderBotton = styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ExpirienceTitle = styled.Text`
  font-size: 13px;
  color: #606062;
  max-width: 95%;
  margin-bottom: 16px;
`;

export const TecnicalRow = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ValueText = styled.Text`
  font-size: 12px;
  color: #606062;
  max-width: 95%;
  margin-right: 20px;
`;

export const Footer = styled.View`
  position: absolute;
  bottom: 20px;
  width: 100vw;
  text-align: right;
  margin-top: 28px;
`;

export const FooterText = styled.Text`
  font-size: 10px;
  margin-right: 24px;
  color: #bbb;
`;

export const Phrases = styled.Text`
  color: #949494;
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
`;

export const SubTitlePhrase = styled.Text`
  font-size: 16px;
  color: #606062;
  margin-bottom: 3px;
  font-family: 'Helvetica-Bold';
`;
