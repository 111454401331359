import React, { useEffect, useState, useContext } from 'react';
import FilterModal from 'components/FilterModal';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import {
  FilterContainer,
  FilterFlexBox,
  FirstFilterGroup,
  SecondFilterGroup,
} from './style';
import academicInnovationApi from '../../services/academicInnovationApi';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';

export function FilterAcademicInnovation({
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  activeSubTab,
  setCountPendingTag,
  setCountAssociatedTag,
  setCountMainTag,
}) {
  const {
    searchTeam,
    setQueryFilter,
    setPendingTags,
    setAssociatedTags,
    setMainTags,
    setCurrentPage,
  } = useFormContext();

  const filterMethods = {
    0: 'getOptionsFilterPending',
    1: 'getOptionsFilterAssociated',
    2: 'getOptionsFilterMainTags',
  };

  const filterMethod = filterMethods[activeSubTab];

  const [filterOptions, setFilterOptions] = useState({
    courses: [],
    educationalGroups: [],
    universities: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (filterMethod) {
        const response = await academicInnovationApi[
          filterMethods[activeSubTab]
        ]();
        if (response) {
          let newOptions = {
            courses: [],
            educationalGroups: [],
            universities: [],
          };

          if (activeSubTab === 0 || activeSubTab === 1 || activeSubTab === 2) {
            newOptions.courses = [
              ...newOptions.courses,
              ...response.courses.map((course) => ({
                label: course.label,
                value: course.value,
              })),
            ];

            newOptions.educationalGroups = [
              ...newOptions.educationalGroups,
              ...response.educational_groups.map((group) => ({
                label: group.label,
                value: group.value,
              })),
            ];

            newOptions.universities = [
              ...newOptions.universities,
              ...response.universities.map((university) => ({
                label: university.label,
                value: university.value,
              })),
            ];
          }

          setFilterOptions(newOptions);
        }
      }
    };

    fetchData();
  }, [filterMethod, activeSubTab]);

  async function onApply(filters) {
    setCurrentPage(1);
    const existFilters = filters && Object.keys(filters).length > 0;
    let queryString = queryParamsFromFilterObject(filters);
    const searchParam = new URLSearchParams(queryString);
    if (searchTeam && searchParam.get('name') == null) {
      queryString = `name=${searchTeam}&${queryString}`;
    }

    if (!existFilters) {
      onClearFilters();
      return;
    }

    if (existFilters && activeSubTab === 0) {
      setQueryFilter(queryString);
      const data = await academicInnovationApi.getPendingTagsList(queryString);
      const { count, results } = data;
      setPendingTags(results);
      setCountPendingTag(count);
    }
    if (existFilters && activeSubTab === 1) {
      setQueryFilter(queryString);
      const data = await academicInnovationApi.getAssociatedTagsList(
        queryString
      );
      const { count, results } = data;
      setAssociatedTags(results);
      setCountAssociatedTag(count);
    }
    if (existFilters && activeSubTab === 2) {
      setQueryFilter(queryString);
      const data = await academicInnovationApi.getMainTagsList(queryString);
      const { count, results } = data;
      setMainTags(results);
      setCountMainTag(count);
    }
  }

  async function onClearFilters() {
    setCurrentPage(1);
    let searchTerm = searchTeam ? `name=${searchTeam}` : '';
    if (activeSubTab === 0) {
      setQueryFilter(null);
      const { count, results } = await academicInnovationApi.getPendingTagsList(
        searchTerm
      );
      setPendingTags(results);
      setCountPendingTag(count);
    }

    if (activeSubTab === 1) {
      setQueryFilter(null);
      const {
        count,
        results,
      } = await academicInnovationApi.getAssociatedTagsList(searchTerm);
      setAssociatedTags(results);
      setCountAssociatedTag(count);
    }

    if (activeSubTab === 2) {
      setQueryFilter(null);
      const { count, results } = await academicInnovationApi.getMainTagsList(
        searchTerm
      );
      setMainTags(results);
      setCountMainTag(count);
    }
  }

  return (
    <FilterContainer>
      <FilterModal
        filterState={filterState}
        setFilterState={setFilterState}
        onApply={onApply}
        onClean={onClearFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
      >
        <FilterFlexBox>
          <FirstFilterGroup>
            {activeSubTab !== 2 && (
              <Filter
                name={'discipline'}
                label={'Disciplina'}
                type={FilterType.TEXT}
              />
            )}
            <Filter
              name={'educational_groups'}
              label={'Grupo educacional'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.educationalGroups}
            />
          </FirstFilterGroup>
          <SecondFilterGroup>
            <Filter
              name={'universities'}
              label={'Instituição'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.universities}
            />
            <Filter
              name={'courses'}
              label={'Curso'}
              type={FilterType.MULTI_SELECT}
              options={filterOptions.courses}
            />
          </SecondFilterGroup>
        </FilterFlexBox>
      </FilterModal>
    </FilterContainer>
  );
}
