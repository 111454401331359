import React, { useState, createContext, useContext } from 'react';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';

const HistoricMentorshipContext = createContext();

export const ContextProvider = ({ children }) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [mentorships, setMentorships] = useState(null);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterState, setFilterState] = useState({});

  const openDetailsModal = async (data) => {
    const mentorshipDetailsResponse = await mentorshipApi.getMentorshipDetails(
      data.id
    );
    setModalData(mentorshipDetailsResponse);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setModalData({});
  };

  const submitMentoringFeedback = async (mentorshipId, feedbackData) => {
    try {
      await mentorshipApi.finishMentorship(mentorshipId, feedbackData);
      return true;
    } catch (error) {
      console.error('Erro ao enviar feedback de mentoria:', error);
      throw error;
    }
  };

  return (
    <HistoricMentorshipContext.Provider
      value={{
        detailsModalOpen,
        setDetailsModalOpen,
        modalData,
        setModalData,
        openDetailsModal,
        closeDetailsModal,
        mentorships,
        setMentorships,
        loading,
        setLoading,
        error,
        setError,
        page,
        setPage,
        hasNextPage,
        setHasNextPage,
        appliedFilters,
        setAppliedFilters,
        filterState,
        setFilterState,
        submitMentoringFeedback,
      }}
    >
      {children}
    </HistoricMentorshipContext.Provider>
  );
};

export function useHistoricMentorshipContext() {
  return useContext(HistoricMentorshipContext);
}
