import { formatNumber } from 'utils/formatNumber';
import { DataArea } from '../../Styles/BaseComponent';
import {
  returnIcons,
  returnInformations,
  returnInformationsGraduates,
} from '../../Utils/formatDataGraphics';
import Tooltip from 'components/NewTooltip';
import { RiInformationLine } from 'react-icons/ri';
import { WidgetSkeleton } from '../Skeletons/WidgetSkeleton';
import { DashboardContext } from '../../Context';
import { useContext } from 'react';

export function StatWidget({ data, countSkeleton, padding, graduated }) {
  const { informationPeriod } = useContext(DashboardContext);
  const returnContent = () => {
    if (data === null) {
      return countSkeleton.map(() => <WidgetSkeleton />);
    }

    if (graduated) {
      if (data?.length > 0) {
        return data?.map((item) => (
          <DataArea padding={padding}>
            <div className="data-side">
              <p>{item.label}</p>
              <div className="value-information">
                <strong>{formatNumber(item.value)}</strong>

                <Tooltip
                  direction={'top'}
                  isMultiLine={true}
                  minWidth={'211px'}
                  text={returnInformationsGraduates(
                    item.name,
                    informationPeriod
                  )}
                  colorSchema="black"
                >
                  <RiInformationLine />
                </Tooltip>
              </div>
            </div>
            <div className="icon-side">
              <div className="background-icon">{returnIcons[item.name]}</div>
            </div>
          </DataArea>
        ));
      }
    } else {
      if (data?.length > 0) {
        return data?.map((item) => (
          <DataArea padding={padding}>
            <div className="data-side">
              <p>{item.label}</p>
              <div className="value-information">
                <strong>{formatNumber(item.value)}</strong>
                <Tooltip
                  direction={'top'}
                  isMultiLine={true}
                  minWidth={'211px'}
                  text={returnInformations(item.name, informationPeriod)}
                  colorSchema="black"
                >
                  <RiInformationLine />
                </Tooltip>
              </div>
            </div>
            <div className="icon-side">
              <div className="background-icon">{returnIcons[item.name]}</div>
            </div>
          </DataArea>
        ));
      }
    }
  };

  return <>{returnContent()}</>;
}
