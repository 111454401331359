import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import { object, string, func, number, array } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import staffHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/staffHeaderStyle.jsx';
import { dnaGreen } from 'assets/jss/material-dashboard-pro-react';

const CompanyHeader = (props) => {
  const {
    classes,
    title,
    activeTab,
    handleTabChange,
    tabs,
    backgroundImage,
    headerElements,
  } = props;

  return (
    <div className={classes.companyHeaderHolder}>
      <CardAvatar className={classes.cardHeaderCompany}>
        <h1 className={classes.pageTitle}>{title}</h1>
        {headerElements && headerElements()}
      </CardAvatar>
      <div className={classes.backgroundViewsHeaderOverlay} />
      <div className={classes.profileHeaderMask}>
        <div
          className={classes.viewHeaderBackground}
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            filter: 'blur(3px)',
            backgroundColor: `${backgroundImage ? null : dnaGreen}`,
          }}
        />
      </div>
      <AppBar position="static" className={classes.appBarHolder}>
        {tabs && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            scrollButtons="on"
            scrollable
            classes={{
              indicator: classes.tabIndicator,
              scrollButtons: classes.scrollButtons,
              flexContainer:
                tabs.length <= 2
                  ? classes.tabContainer
                  : classes.tabContainerStart,
            }}
          >
            {tabs.map((tabName) => (
              <Tab
                style={{ zIndex: 2 }}
                key={tabName}
                className={classes.tabButton}
                label={tabName}
              />
            ))}
          </Tabs>
        )}
      </AppBar>
    </div>
  );
};

CompanyHeader.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};

export default withStyles(staffHeaderStyle)(CompanyHeader);
