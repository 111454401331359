import React, { useContext } from 'react';
import { ContainerFinal, ButtonFinal } from './styled';
import { Check } from '@material-ui/icons';
import BaseLayoutContext from 'contexts/base-layout';

function FinallyTests({ handleTestData }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <ContainerFinal>
      <ButtonFinal onClick={handleTestData} universityColor={universityColor}>
        Finalizar <Check />
      </ButtonFinal>
    </ContainerFinal>
  );
}

export default FinallyTests;
