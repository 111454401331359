import React, { Fragment } from 'react';
import { shape, func, bool } from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

const StatusInformation = ({
  classes,
  invite,
  loading,
  sendInvite,
  received,
  role,
  currentTab,
  ids,
  changeConnections,
}) => {
  const handleClick = (invite) => {
    if (invite.status === 'accepted' || invite.status === 'rejected') {
      buttonConfig[invite.status].function(
        buttonConfig[invite.status].values,
        invite
      );
    } else {
      buttonConfig[invite.status].function(invite);
    }
  };

  const buttonConfig = {
    invited: {
      text: 'Reenviar convite',
      function: sendInvite,
    },
    created: {
      text: 'Enviar convite',
      function: sendInvite,
    },
    accepted: {
      text: 'Desconectar',
      function: changeConnections,
      values: {
        origin: currentTab === 0 ? 'sent' : 'received',
        currentStatus: 'accepted',
        newStatus: 'rejected',
        lote: false,
      },
    },
    rejected: {
      text: 'Conectar',
      function: changeConnections,
      values: {
        origin: currentTab === 0 ? 'sent' : 'received',
        currentStatus: 'rejected',
        newStatus: 'accepted',
        lote: false,
      },
    },
  };
  return (
    // InviteStatus || Accepted || Rejected || Canceled

    <Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1px',
          textAlign: 'center',
          minWidth: '164px',
        }}
      >
        <div>
          <p
            style={{
              color: invite.isAccepted ? '#1CB45F' : '#ff0000',
              margin: '0 0 0px',
              fontWeight: 400,
            }}
          >
            {invite.isAccepted && 'Conectado'}
            {invite.isRejected && 'Desconectado'}
            {invite.isCanceled && 'Cancelado'}
          </p>
        </div>
        {invite.isAccepted || invite.isRejected || invite.isCanceled ? (
          <p
            className={classes.fontSizeParagraphDate}
            style={{
              color: invite.isAccepted ? '#1CB45F' : '#ff0000',
            }}
          >
            {invite.updatedAt}
          </p>
        ) : null}
      </div>

      {invite.createdAt &&
        !invite.isAccepted &&
        !invite.isRejected &&
        !invite.isCanceled &&
        currentTab === 2 &&
        role === 'company' && (
          <div>
            <p>Enviado</p>
            <i>{invite.createdAt}</i>
          </div>
        )}
      {/* Send/Resend invite button */}
      {role === 'university' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {!invite.isAccepted &&
            !invite.isCanceled &&
            !invite.isRejected &&
            !received && (
              <div
                style={{
                  gap: '1px',
                  textAlign: 'center',
                  minWidth: invite.inviteSentAt ? '130px' : '',
                }}
              >
                <p
                  style={{
                    fontSize: '14.71px',
                    color: 'gray',
                    margin: '0 0 0px',
                    fontWeight: 400,
                  }}
                >
                  {invite.inviteSentAt
                    ? 'Convite Enviado'
                    : 'Convite não enviado'}
                </p>
                <p className={classes.fontSizeParagraphDate}>
                  {invite.inviteSentAt ? invite.inviteSentAt : invite.createdAt}
                </p>
              </div>
            )}
          <div>
            {buttonConfig[invite.status] && (
              <Button
                className={classes.buttonStatusInformation}
                onClick={() => handleClick(invite)}
                disabled={ids && ids.length > 0}
              >
                {loading && (
                  <Fragment>
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </Fragment>
                )}
                {!loading && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      textTransform: 'none',
                    }}
                  >
                    <p style={{ margin: '0 0 0px' }}>
                      {buttonConfig[invite.status] &&
                      buttonConfig[invite.status].text
                        ? buttonConfig[invite.status].text
                        : 'Cancelado'}
                    </p>
                  </div>
                )}
              </Button>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

StatusInformation.propTypes = {
  classes: shape.isRequired,
  invite: shape.isRequired,
  sendInvite: func.isRequired,
  loading: bool.isRequired,
  received: bool.isRequired,
};

export default StatusInformation;
