import styled, { keyframes } from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    font-size: 16px;
    font-weight: bold;
    color: #606062;
    margin: 2rem 0 0 0;
  }

  h6 {
    font-size: 15px;
    color: #606062;
    font-weight: 400;
    text-transform: unset;
  }

  form {
    display: flex;
    margin-top: 2rem;
    width: 100%;
    gap: 1rem;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 1.5rem;
  background-color: #f7f7f7;
  border-radius: 9px;
  gap: 10px;
  width: 100%;

  > header {
    display: flex;
    justify-content: space-between;
  }

  > strong {
    font-size: 14px;
    font-weight: 700;
  }

  > span {
    color: #606062;
    font-size: 14px;
  }
`;

const fill = (start, end) => keyframes`
  0% {
    width: ${start}%
  }
  100% {
    width: ${end}%
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 9px;
  background-color: #e4e3e3;
  border-radius: 15px;
`;

export const Progress = styled.div`
  width: ${(props) => props.finalProgress}%;
  height: 9px;
  background-color: #009291;
  border-radius: 15px;
  animation: ${({ initialProgress, finalProgress }) =>
      fill(initialProgress, finalProgress)}
    2s ease-in-out;
`;

export const AddButton = styled.button`
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: ${({ formValid }) => (formValid ? '#009291' : '#f5f5f5')};
  border-radius: 8px;
  cursor: ${({ formValid }) => (formValid ? 'pointer' : 'not-allowed')};
  transition: all 0.3s ease-in-out;
`;

export const CharactersCounter = styled.p`
  margin-left: auto;
  color: #777777;
`;

export const ErrorMessage = styled.p`
  color: #606062;
  margin: 0;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex};
  gap: 1rem;
  width: 100%;

  label {
    color: #b8b8b8;
    font-size: 14px;
    font-weight: 700;
  }

  footer {
    display: flex;
    margin-top: calc(-1rem + 6px);
    font-size: 12px;
    width: 100%;
    gap: 0.5rem;
    align-items: flex-start;
    line-height: 1.5;
    padding: 0;

    img {
      margin-top: 4px;
    }
  }
`;

export const ListTags = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 1rem 0 0 0;
  padding: 0;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 0 0.75rem;
  border: 1px solid ${({ error }) => (error ? '#FFCECE' : '#C4C4C4')};
  border-radius: 4px;
  background-color: ${({ error }) => (error ? '#FFEDED' : 'transparent')};
  box-sizing: border-box;
  cursor: text !important;

  &:focus {
    border: 2px solid
      ${({ error }) => (error ? '#FFCECE !important' : '#009291')};
  }

  &::placeholder {
    color: #777777;
    font-size: 14px;
  }
`;

export const VerticalDivider = styled.div`
  width: 2px;
  background-color: ${(props) => props.color || '#c4c4c4'};
  height: ${(props) => props.height};
`;

export const WorkloadWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0 1rem;
  justify-content: center;
  align-items: center;
  height: 45px;
  background-color: #fcfcfc;
  border-radius: 8px;
  margin: 1.5rem auto 0 auto;
  gap: 1rem;

  span {
    color: #4b4b4d;
    font-size: 14px;
  }

  img,
  svg {
    cursor: pointer;
  }
`;

export const ContainerSelect = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: -30px;

  > div {
    width: 100%;
  }
  p {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    color: #b8b8b8;
  }
  .character-count {
    font-family: Roboto;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: right;
    color: #777777;
  }

  .button-title {
    margin: -30px 0 10px -8px;
  }

  input {
    opacity: 1 !important;
  }
`;

export const ContainerCharacterCount = styled.div`
  width: 100%;
  align-items: flex-end;
  display: flex;
  justify-content: end;
  margin-top: -20px;
`;
export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #3c4858;
  font-size: 13px;
  margin-top: -10px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
