import GenericMessage from 'components/GenericMessage';
import { Row, FragmentText, AboltCompany } from './styles';

import React, { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import ReactHtmlParser from 'react-html-parser';

export function DisplayAboltCompany({ data }) {
  const { universityColor } = useContext(BaseLayoutContext);

  const noInfoCompany = () => {
    return Boolean(
      data?.description === '' &&
        data?.values.length === 0 &&
        data?.skills.length === 0 &&
        data?.challenges === ''
    );
  };

  return (
    <>
      {noInfoCompany() ? (
        <GenericMessage
          title="Oops... Nenhuma informação encontrada"
          subtitle="No momento, esta empresa ainda não preencheu alguns dados."
          subtitleResponsible="No momento, esta empresa ainda não preencheu alguns dados."
        />
      ) : (
        <AboltCompany>
          {data?.description !== '' && (
            <FragmentText>
              <h4>Descrição</h4>
              <p>{ReactHtmlParser(data?.description)}</p>
            </FragmentText>
          )}
          {data?.activitiesSectors?.length > 0 && (
            <FragmentText color={universityColor}>
              <h4>Setores de atividade</h4>
              <div>
                {data?.activitiesSectors.map((value, index) => (
                  <Row>
                    <span />
                    <p>{capitalizeFirstLetter(value)}</p>
                  </Row>
                ))}
              </div>
            </FragmentText>
          )}
          {data?.values.length > 0 && (
            <FragmentText color={universityColor}>
              <h4>Principais valores</h4>
              <p>
                {`Confira quais são os valores da ${data?.legalName} relacionados com o seu perfil.`}
              </p>
              <div>
                {data?.values.map((value, index) => (
                  <Row>
                    <span />
                    <p>{value}</p>
                  </Row>
                ))}
              </div>
            </FragmentText>
          )}
          {data?.skills.length > 0 && (
            <FragmentText color={universityColor}>
              <h4>Principais competências comportamentais dos profissionais</h4>
              <p>
                {`Confira quais são as principais competências comportamentais dos profissionais da ${data?.legalName} e a relação com o seu perfil.`}
              </p>
              <div>
                {data?.skills.map((value, index) => (
                  <Row>
                    <span />
                    <p>{value}</p>
                  </Row>
                ))}
              </div>
            </FragmentText>
          )}

          <br />
        </AboltCompany>
      )}
    </>
  );
}
