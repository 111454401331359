import styled from 'styled-components';
import Setadireita from 'assets/Oportutinites/Setadireita.svg';
import Setaesquerda from 'assets/Oportutinites/Setaesquerda.svg';

export const ButtonsContainer = styled.div`
  margin: 30px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-left: 40px;
  align-items: center;
`;
export const NextButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${Setadireita});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;
export const PrevsButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${Setaesquerda});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;
export const FinishButton = styled.button`
  color: #ffffff;
  width: 25%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:disabled {
    background-color: #69b6b8;
    cursor: wait;
  }
`;
