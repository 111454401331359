export function IconStar({ color }) {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 8.7L15.36 7.944L12 0L8.64 7.944L0 8.7L6.54 14.364L4.596 22.8L12 18.324L19.416 22.8L17.46 14.364L24 8.7Z"
        fill={color}
      />
    </svg>
  );
}
