export function IconHelp({ color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none">
      <rect
        id="backgroundrect"
        width="100%"
        height="100%"
        x="0"
        y="0"
        fill="none"
        stroke="none"
      />
      <g class="currentLayer">
        <title>Layer 1</title>
        <path
          d="M6.86797 23.132C2.37749 18.6404 2.37717 11.3589 6.86804 6.86804C11.3596 2.37706 18.6411 2.37756 23.1321 6.86808C27.622 11.3597 27.6223 18.6407 23.132 23.1322C18.6405 27.6225 11.359 27.622 6.86797 23.132Z"
          stroke="none"
          stroke-width="2"
          id="svg_1"
          class="selected"
          fill={color}
          stroke-opacity="1"
        />
        <path
          d="M13.7344 16.6562C13.7344 15.9635 13.8203 15.4115 13.9922 15C14.1641 14.5885 14.5026 14.138 15.0078 13.6484C15.5182 13.1536 15.8411 12.8021 15.9766 12.5938C16.1849 12.276 16.2891 11.9323 16.2891 11.5625C16.2891 11.0729 16.1667 10.7005 15.9219 10.4453C15.6823 10.1849 15.3281 10.0547 14.8594 10.0547C14.4115 10.0547 14.0495 10.1823 13.7734 10.4375C13.5026 10.6875 13.3672 11.0286 13.3672 11.4609H11.4688C11.4792 10.5391 11.7917 9.8099 12.4062 9.27344C13.026 8.73698 13.8438 8.46875 14.8594 8.46875C15.9062 8.46875 16.7214 8.73438 17.3047 9.26562C17.8932 9.79688 18.1875 10.5391 18.1875 11.4922C18.1875 12.3411 17.7917 13.1771 17 14L16.0391 14.9453C15.6953 15.3359 15.5182 15.9062 15.5078 16.6562H13.7344ZM13.6016 19.0859C13.6016 18.7786 13.6979 18.5312 13.8906 18.3438C14.0833 18.151 14.3438 18.0547 14.6719 18.0547C15.0052 18.0547 15.2682 18.1536 15.4609 18.3516C15.6536 18.5443 15.75 18.7891 15.75 19.0859C15.75 19.3724 15.6562 19.612 15.4688 19.8047C15.2812 19.9974 15.0156 20.0938 14.6719 20.0938C14.3281 20.0938 14.0625 19.9974 13.875 19.8047C13.6927 19.612 13.6016 19.3724 13.6016 19.0859Z"
          fill="#fff"
          id="svg_2"
          class=""
          fill-opacity="1"
        />
      </g>
    </svg>
  );
}
