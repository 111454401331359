import React, { useContext } from 'react';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

import { ContainerBackground, BackgroundColor } from './styled';

const Background = () => {
  const { universityColor } = useContext(ProfileContext);

  return (
    <ContainerBackground>
      <BackgroundColor universityColor={universityColor} />
    </ContainerBackground>
  );
};

export default Background;
