import React, { useState, useEffect } from 'react';
import { StepTwo } from './StepsProfessionalStatus/stepTwo';
import { StepThree } from './StepsProfessionalStatus/stepThree';
import { useFormContext } from 'views/ProfessionalStatus/context';
import professionalStatusApi from 'views/ProfessionalStatus/services/professionalStatusApi';

export default function ContentProfessionalStatusEdit({
  isProfessionalStatusDefined,
  professional_status,
  academicProfile,
  universityColor,
  globalState,
  profileData,
  setIsLoading,
  metadata,
  classes,
}) {
  const { step, setStep, setFormValues } = useFormContext();
  const [surveysEditData, setSurveysEditData] = useState([]);
  const [options, setOptions] = useState([]);
  const [answeredSurvey, setAnsweredSurvey] = useState([]);

  useEffect(() => {
    const getSelectOptions = async () => {
      const response = await professionalStatusApi.getFirstAccessOptions();
      const data = await professionalStatusApi.getProfile();
      setOptions(response);
      setSurveysEditData(response);
      setAnsweredSurvey(data);
      setIsLoading(false);
    };
    getSelectOptions();
  }, []);

  const nextFormStep = () => {
    setStep((prevsStep) => prevsStep + 1);
  };

  const prevsFormStep = () => {
    setStep((prevsStep) => prevsStep - 1);
  };

  const handleSubmit = (data) => {
    setFormValues(data);
    nextFormStep();
  };

  const isSurveysDefined = surveysEditData.surveys?.length > 0;
  const isAnsweredSurveyDefined = answeredSurvey.answered_survey?.length > 0;

  return (
    <div>
      {step === 0 && (
        <StepTwo
          classes={classes}
          metadata={metadata}
          academicProfile={academicProfile}
          professional_status={professional_status}
          globalState={globalState}
          universityColor={universityColor}
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
          options={options}
          isProfessionalStatusDefined={isProfessionalStatusDefined}
          isSurveysDefined={isSurveysDefined}
          isAnsweredSurveyDefined={isAnsweredSurveyDefined}
          profileData={profileData}
        />
      )}
      {step === 1 && isSurveysDefined && !isAnsweredSurveyDefined && (
        <StepThree
          classes={classes}
          metadata={metadata}
          academicProfile={academicProfile}
          professional_status={professional_status}
          globalState={globalState}
          universityColor={universityColor}
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
          options={options}
          isProfessionalStatusDefined={isProfessionalStatusDefined}
          isSurveysDefined={isSurveysDefined}
          profileData={profileData}
        />
      )}
    </div>
  );
}
