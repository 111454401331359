import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 30px 60px;
  min-height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 30px;
`;
