import {
  ContainerInput,
  CustomForm,
  SelectBox,
  ContainerForms,
} from '../../Styles/ScheduleInterviewComponent';
import {
  Box,
  Wrapper,
  Content,
  TopBar,
  CloseButton,
} from 'components/ListCandidates/Styles/ModalComponents';
import { IoClose } from 'react-icons/io5';
import { Form } from '@unform/web';
import SimpleReactSelect from 'components/UnformFields/SimpleReactSelect';
import {
  customCommonStyles,
  selectTheme,
} from 'components/ListCandidates/Styles/BaseComponent';
import { useState, useRef, useEffect } from 'react';
import SimpleInput from 'components/UnformFields/SimpleInput';
import { handleFieldMask } from 'utils/validation';
import { zipCodeRequest } from 'services/zipCode';
import { maskTime, maskZipCode, fillTime } from 'utils/inputMasks';
import SimpleDatePicker from 'components/UnformFields/SimpleDatePicker';
import {
  controlGetInitialData,
  controlProcessFormat,
  controlSubmitForm,
  controlUpdateInterview,
  returnSetOfButtons,
  returnTitleModalScheduleInterview,
} from 'components/ListCandidates/Utils/modalInterviewFunctions';
import NoteInput from 'components/UnformFields/noteInput';
import { scheduleInterviewAdapter } from 'components/ListCandidates/Utils/scheduleInterviewPayloadAndAdapter';
import { returnStatusInterview } from 'components/ListCandidates/Utils/cardFunctions';
import { loadJobsData } from 'components/ListCandidates/Utils/mainFunctions';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function ScheduleInterviewModal({
  visible,
  controlModal,
  candidateId,
  jobId,
  company,
  interview,
  setCancelInterviewModal,
  setCandidates,
  selectedTab,
  isPartner,
}) {
  const [onlineForm, setOnlineForm] = useState(false);
  const [inPersonForm, setInPersonForm] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const formRef = useRef();
  const options = [
    { value: false, label: 'Presencial' },
    { value: true, label: 'Online' },
  ];
  const [interviewDetails, setInterviewDetails] = useState({});

  let disabledField =
    Object.keys(interview).length !== 0 &&
    interview.status !== 'canceled' &&
    !reschedule;

  let environmentType = company ? 'company' : 'university';

  const handleZipCode = async (e) => {
    const value = e.target.value;

    if (value.length === 9) {
      const response = await zipCodeRequest(value);
      if (response.erro) {
        formRef.current.setFieldError('zipCode', 'CEP inválido');
      } else {
        formRef.current.setErrors({});
        formRef.current.setFieldValue('zipCode', response.cep);
        formRef.current.setFieldValue('street', response.logradouro);
        formRef.current.setFieldValue('district', response.bairro);
        formRef.current.setFieldValue('city', response.localidade);
        formRef.current.setFieldValue('state', response.uf);
      }
    }
  };

  const initialSubmit = async () => {
    const response = await controlGetInitialData(
      environmentType,
      jobId,
      candidateId,
      interview.id
    );
    const adaptedResponse = scheduleInterviewAdapter(response);
    setInterviewDetails(adaptedResponse);
    formRef.current.setFieldValue(
      'processFormat',
      adaptedResponse.processFormat
    );
  };

  const handleSubmit = async (data) => {
    const response = await controlSubmitForm(
      data,
      formRef,
      jobId,
      candidateId,
      controlModal,
      environmentType
    );

    if (!response.errors) {
      await loadJobsData(
        environmentType,
        jobId,
        isPartner,
        selectedTab,
        setCandidates
      );
      customSnackbar('Entrevista agendada com sucesso!', 'confirmation');
    }
  };

  const handleUpdateInterview = async () => {
    const data = formRef.current.getData();
    const response = await controlUpdateInterview(
      formRef,
      environmentType,
      jobId,
      candidateId,
      interview.id,
      data,
      resetModal
    );

    if (!response.errors) {
      await loadJobsData(
        environmentType,
        jobId,
        isPartner,
        selectedTab,
        setCandidates
      );
    }
  };

  useEffect(() => {
    if (interview.id) {
      initialSubmit();
    }
  }, [interview]);

  const returnOnlineForm = () => {
    return (
      <CustomForm>
        <ContainerInput>
          <SimpleInput
            name="accessLink"
            label="Link*"
            placeholder="Digite aqui..."
            disabled={disabledField}
          />
        </ContainerInput>

        <ContainerInput isData>
          <ContainerInput>
            <SimpleDatePicker
              minDate={new Date()}
              name="schedulingDateOnline"
              label="Data*"
              placeholder="Ex: 15/02/2022"
              disabled={disabledField}
            />
          </ContainerInput>

          <ContainerInput>
            <SimpleInput
              name="schedulingHourOnline"
              label="Horário*"
              placeholder="HH:MM"
              onKeyUp={(event) => {
                handleFieldMask(event, maskTime);
              }}
              disabled={disabledField}
              onBlur={(event) => {
                event.target.value = fillTime(event.target.value);
              }}
            />
          </ContainerInput>
        </ContainerInput>

        <ContainerInput>
          <NoteInput
            name="descriptionOnline"
            label="Recomendações*"
            placeholder="Digite aqui..."
            disabled={disabledField}
          />
        </ContainerInput>
        {returnSetOfButtons(
          interview,
          reschedule,
          setReschedule,
          handleUpdateInterview,
          setCancelInterviewModal,
          resetModal
        )}
      </CustomForm>
    );
  };

  const returnPersonForm = () => {
    return (
      <CustomForm>
        <ContainerInput isData>
          <ContainerInput>
            <SimpleInput
              name="zipCode"
              label="CEP"
              placeholder="Digite aqui..."
              onKeyUp={async (event) => {
                handleFieldMask(event, maskZipCode);
                await handleZipCode(event);
              }}
              disabled={disabledField}
            />
          </ContainerInput>

          <ContainerInput>
            <SimpleInput
              name="district"
              label="Bairro"
              placeholder="Digite aqui..."
              disabled={disabledField}
            />
          </ContainerInput>
        </ContainerInput>

        <ContainerInput isData>
          <ContainerInput>
            <SimpleInput
              name="street"
              label="Rua/Avenida"
              placeholder="Digite aqui..."
              disabled={disabledField}
            />
          </ContainerInput>

          <ContainerInput>
            <SimpleInput
              name="number"
              label="Número"
              placeholder="Digite aqui..."
              disabled={disabledField}
            />
          </ContainerInput>

          <ContainerInput>
            <SimpleInput
              name="complement"
              label="Complemento"
              placeholder="Digite aqui..."
              disabled={disabledField}
            />
          </ContainerInput>
        </ContainerInput>

        <ContainerInput isData>
          <ContainerInput>
            <SimpleInput
              name="city"
              label="Cidade"
              placeholder="Digite aqui..."
              disabled={disabledField}
            />
          </ContainerInput>

          <ContainerInput>
            <SimpleInput
              name="state"
              label="UF"
              placeholder="Digite aqui..."
              disabled={disabledField}
            />
          </ContainerInput>
        </ContainerInput>

        <ContainerInput isData>
          <ContainerInput>
            <SimpleDatePicker
              minDate={new Date()}
              name="schedulingDate"
              label="Data"
              placeholder="Ex: 15/02/2022"
              disabled={disabledField}
              isClearable={!disabledField}
            />
          </ContainerInput>

          <ContainerInput>
            <SimpleInput
              name="schedulingHour"
              label="Horário*"
              placeholder="HH:MM"
              onKeyUp={(event) => {
                handleFieldMask(event, maskTime);
              }}
              onBlur={(event) => {
                event.target.value = fillTime(event.target.value);
              }}
              disabled={disabledField}
            />
          </ContainerInput>
        </ContainerInput>

        <ContainerInput>
          <NoteInput
            name="description"
            label="Recomendações"
            placeholder="Digite aqui..."
            disabled={disabledField}
          />
        </ContainerInput>
        {returnSetOfButtons(
          interview,
          reschedule,
          setReschedule,
          handleUpdateInterview,
          setCancelInterviewModal,
          resetModal
        )}
      </CustomForm>
    );
  };

  const resetModal = () => {
    controlModal(false);
    setReschedule(false);
  };

  return (
    <Wrapper visible={visible} formActived={onlineForm || inPersonForm}>
      <Box>
        <Content>
          <TopBar>
            <p className="title">
              {returnTitleModalScheduleInterview(interview, reschedule)}
            </p>
            <CloseButton onClick={resetModal}>
              <IoClose size={18} />
            </CloseButton>
          </TopBar>
          <ContainerForms>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={interviewDetails}
            >
              <div className="status-interview">
                {returnStatusInterview(interview)}
              </div>
              <SelectBox formActived={onlineForm || inPersonForm}>
                <SimpleReactSelect
                  name="processFormat"
                  label="Formato da entrevista"
                  placeholder="Selecione"
                  options={options}
                  onChange={(e) =>
                    controlProcessFormat(
                      e,
                      formRef,
                      setOnlineForm,
                      setInPersonForm
                    )
                  }
                  theme={selectTheme}
                  styles={customCommonStyles}
                  disabled={disabledField}
                />
              </SelectBox>
              {onlineForm && returnOnlineForm()}
              {inPersonForm && returnPersonForm()}
            </Form>
          </ContainerForms>
        </Content>
      </Box>
    </Wrapper>
  );
}
