import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 255px;
  height: 350px;
  max-height: 350px;
  border: 1px solid #eaeaea;
  border-radius: 12px;
  padding: 16px;
`;

export const WrapperInfos = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  height: 100%;
  justify-content: space-between;

  strong {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 12px;
    text-transform: capitalize !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 1rem;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 221px;
    word-break: break-word;
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 0.8rem;
    padding-bottom: 0.5rem;
    color: #949494;
    line-height: 1rem;
  }
`;

export const VisualIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 0.5rem;

  .icon {
    width: 28px;
    height: 28px;
    padding: 4px;
    border-radius: 3px;
    background: #e0ffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon img {
    width: 20px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 11.5px;
    color: #606062;
    margin: 0 !important;
    line-height: 0.89rem;
  }

  button {
    background-color: transparent;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 12px;
    color: #009291;
    cursor: pointer;
    text-decoration: underline;
    white-space: nowrap;
    padding: 0;
  }
`;

export const SimpleBar = styled.div`
  height: 1px;
  width: 100%;
  border: 0.8px solid #e5e5e5;

  ${(props) =>
    props.hasMarginTop ? 'margin-top: 26px;' : 'margin: 5px 0 12px 0'}
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Button = styled.button`
  width: 100%;
  height: 27px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  max-width: 100px;
  background-color: #009291;
  border: 1px solid #009291;
  border-radius: 4px;
  padding: 0;
  cursor: pointer;
  margin: 0;
`;

export const ButtonDelete = styled.button`
  width: 100%;
  max-width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #606062;
  font-size: 12px;
  background-color: transparent;
  border: none;
  padding-bottom: 5px;
  padding-left: 0;
  cursor: pointer;
`;

export const ButtonEdit = styled.button`
  width: 100%;
  max-width: 43px;
  display: flex;
  align-items: center;
  color: #009291;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 700;
  background-color: transparent;
  border: none;
  padding-bottom: 5px;
  padding-left: 0;
  cursor: pointer;
`;

export const ListButton = styled.button`
  background-color: transparent;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #009291;
  cursor: pointer;
  text-decoration: underline;
`;

export const TitleText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.isIcons ? 'center' : 'left')};

  flex-direction: column;

  gap: ${(props) => {
    if (props.isTitle) {
      return '2px;';
    }
    if (props.isMaster) {
      return '0px;';
    }
    return '8px;';
  }};

  height: ${(props) => {
    if (props.isIcons) {
      return '40%;';
    }
    return '%';
  }};
`;
