import React, { useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import JobHeaderTab, { Container, ContainerButtons } from './style';
import PropTypes from 'prop-types';
import useDefineColor from 'constants/colors';
import BaseLayoutContext from 'contexts/base-layout';

const GenericInternalTab = (props) => {
  const {
    classes,
    activeTab,
    handleTabChange,
    tabs,
    zIndex,
    buttons,
    containerButtonsPosition,
    className,
    hideFirstTab,
  } = props;

  const { universityColor, isStudent } = useContext(BaseLayoutContext);

  return (
    <Container zIndex={zIndex} className={className}>
      <AppBar position="static" className={classes.appBarHolder}>
        {tabs && (
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            scrollButtons="on"
            scrollable
            classes={{
              indicator: isStudent
                ? classes[`${useDefineColor(universityColor)}CardHeader`]
                : classes.dnaColorCardHeader,
              scrollButtons: classes.scrollButtons,
              flexContainer: classes.borderContainer,
            }}
            className="genericTabs"
          >
            {tabs.map((tabName) => (
              <Tab
                style={{
                  zIndex: zIndex ?? 2,
                  display:
                    hideFirstTab && tabName === 'Autodesenvolvimento'
                      ? 'none'
                      : 'block',
                }}
                key={tabName}
                label={tabName}
                className={classes.tabButton}
              />
            ))}
          </Tabs>
        )}
        {buttons && (
          <ContainerButtons containerButtonsPosition={containerButtonsPosition}>
            {buttons.map((button) => button)}
          </ContainerButtons>
        )}
      </AppBar>
    </Container>
  );
};

GenericInternalTab.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  buttons: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
  color: PropTypes.oneOf([
    'yellowIES',
    'redIES',
    'greenIES',
    'darkBlueIES',
    'blueIES',
    'lightBlueIES',
    'orangeIES',
    'dnaColor',
  ]),
};

export default withStyles(JobHeaderTab)(GenericInternalTab);
