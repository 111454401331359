import React from 'react';

export const GraduationIcon = ({ color = '#FFF', size = 16 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4402 2.9767L10.2784 0.517606C9.14222 -0.161087 7.728 -0.173129 6.58035 0.486106L1.36048 2.9767C1.34089 2.98651 1.32058 2.99701 1.30168 3.0082C0.0629478 3.71649 -0.367092 5.29487 0.341197 6.53361C0.581286 6.95354 0.934447 7.29761 1.36048 7.52671L2.80039 8.21272V11.6427C2.80125 13.1765 3.79924 14.5315 5.26369 14.9873C6.28281 15.2821 7.33954 15.4265 8.4004 15.4157C9.46109 15.4276 10.5178 15.2845 11.5371 14.9908C13.0016 14.535 13.9995 13.18 14.0004 11.6462V8.21131L15.4004 7.5421V13.3157C15.4004 13.7023 15.7138 14.0157 16.1004 14.0157C16.487 14.0157 16.8004 13.7023 16.8004 13.3157V4.91569C16.805 4.0937 16.156 3.33436 15.4402 2.9767ZM12.6004 11.6462C12.6007 12.5636 12.0055 13.3751 11.1304 13.6503C10.2429 13.9039 9.32328 14.027 8.40036 14.0157C7.47745 14.027 6.55781 13.9039 5.67037 13.6503C4.79523 13.3751 4.20001 12.5636 4.20037 11.6462V8.87983L6.52228 9.98584C7.09518 10.326 7.7495 10.5048 8.41578 10.5031C9.04998 10.5076 9.67352 10.3397 10.2197 10.0173L12.6004 8.87979V11.6462ZM14.8404 6.2632L9.56097 8.78319C8.82486 9.21182 7.91244 9.19968 7.18797 8.75169L2.02267 6.2947C1.44691 5.98423 1.23186 5.2658 1.54233 4.69007C1.64733 4.49536 1.80476 4.33396 1.99678 4.2241L7.24326 1.71812C7.97961 1.29044 8.89153 1.30255 9.61626 1.74961L14.7781 4.20871C15.1577 4.4195 15.3952 4.81755 15.4003 5.25172C15.401 5.66299 15.1893 6.04545 14.8404 6.2632Z"
      fill={color}
    />
  </svg>
);
