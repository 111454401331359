import React, { useContext } from 'react';
import BaseLayoutContext from '../../../../../../contexts/base-layout';
import { JobOfferContext } from '../../../../Contexts/JobOfferContext';
import PreviewJobExternal from '../PreviewJobs/previewJobExternal';
import { PrevButton } from './styles';

export const PreviewButtonExternal = ({
  children,
  courses,
  handleSubmit,
  universities,
}) => {
  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const { externalJobOffer, ExternalJobRef, setZIndex } = useContext(
    JobOfferContext
  );

  const handleClickPreview = (e) => {
    e.preventDefault();
    setZIndex(0);
    openModalWithContent(
      <PreviewJobExternal
        externalJobOffer={externalJobOffer}
        formRef={ExternalJobRef}
        courses={courses}
        handleSubmit={handleSubmit}
        closeModal={closeModal}
        universities={universities}
      />,
      null,
      null,
      () => setZIndex(2000000000)
    );
  };

  return <PrevButton onClick={handleClickPreview}>{children}</PrevButton>;
};
