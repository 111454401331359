import React, { useRef, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import BoardContext from './CurriculoModalContext';
import BaseLayoutContext from 'contexts/base-layout';

import { AiOutlineMenu } from 'react-icons/ai';

import {
  ContainerInfoItem,
  IconItem,
  ItemName,
  Left,
  RightIcon,
} from './style';

import { Grip } from 'assets/curriculum/grip';

function ItemInfoList({ data, index }) {
  const ref = useRef();
  const { move } = useContext(BoardContext);
  const { universityColor } = useContext(BaseLayoutContext);

  const [{ isDragging }, dragRef] = useDrag({
    type: 'CARD',
    item: { id: data.value, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item, monitor) {
      const draggedIndex = item.index;
      const targetIndex = index;

      if (draggedIndex === targetIndex) {
        return;
      }

      const targetSize = ref.current.getBoundingClientRect();
      const targetCenter = (targetSize.bottom - targetSize.top) / 2;

      const draggedOffset = monitor.getClientOffset();
      const draggedTop = draggedOffset.y - targetSize.top;

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return;
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return;
      }

      move(draggedIndex, targetIndex);

      item.index = targetIndex;
    },
  });

  dragRef(dropRef(ref));

  return (
    <div>
      <ContainerInfoItem
        key={data.id}
        value={data.value}
        ref={ref}
        isDragging={isDragging}
      >
        <Left>
          <IconItem universityColor={universityColor}>{data.icon}</IconItem>
          <ItemName>{data.name}</ItemName>
        </Left>
        <RightIcon universityColor={universityColor}>
          {<Grip color={universityColor} size={24} />}
        </RightIcon>
      </ContainerInfoItem>
    </div>
  );
}

export default ItemInfoList;
