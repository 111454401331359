import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: #efeeec;
  margin-top: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ComebackHeader = styled.div`
  display: flex;
  width: 900px;
  margin-bottom: -50px;
  z-index: 2;
  margin-left: 45px;

  > button {
    border: none;
    transition: all ease 0.2s;
    padding: 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: bold;
    color: #009291;
    cursor: pointer;
    background-color: #fff;
    width: 31px;
    overflow: hidden;
    height: 31px;
    border-radius: 4px;

    &:hover {
      width: 100px;
    }

    > svg {
      min-width: 30px;
    }
  }
`;
