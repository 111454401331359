import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 3rem 3rem;

  > hr {
    margin: 5rem 0 -3rem 0;
    height: 2px;
    background-color: #eef2f4;
    border: none;
    border-radius: 2px;
  }

  > header {
    > h3 {
      font-size: 18px;
      font-weight: bold;
      color: #606062;
      margin: 0;
    }

    > p {
      max-width: 886px;
      margin: 1rem 0 0 0 !important;
      font-size: 14px;
    }
  }
`;

export const TabIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 48px;
  border-radius: 8px;
  margin-top: 2rem;
`;

export const Tab = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? '#FFF' : '#F7F7F7')};
  height: 100%;
  border-radius: ${(props) => (props.left ? '8px 0 0 8px' : '0 8px 8px 0')};
  cursor: pointer;
  border: 1px solid #eef2f4;
  font-size: 18px;
  color: ${(props) => (props.active ? '#009291' : '#949494')};
  font-weight: 700;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: rgba(89, 89, 89, 0.1);
  }
`;
