import React, { useContext, useEffect, useState } from 'react';
import {
  ActionButton,
  ActionButtons,
  ActionsWrapper,
  CanceledButton,
  ConfirmedButton,
  Container,
  FileTile,
  GridModality,
  GridSide,
  LabelContent,
  RatingContainer,
  RescheduleButton,
  StarIcon,
  StudentNotes,
  TypeLabel,
  WrapperInformations,
} from './styled';
import { CalendarIcon } from 'assets/mentorship/CalendarIcon';
import { ClockIcon } from 'assets/mentorship/ClockIcon';
import GenericModal from '../../components/GenericModal';
import { UserIcon } from 'assets/mentorship/UserIcon';
import BaseLayoutContext from 'contexts/base-layout';
import { HouseLaptopIcon } from 'assets/mentorship/HouseLaptopIcon';
import DetailsItem from './detailsItem';
import { formatDateTime } from 'utils/formatDateTime';
import StatusFlag from 'views/Student/Mentorship/utils/StatusFlag';
import { getAddressDetails } from 'views/StaffPages/Mentorship/utils/addressAdapter';
import { mentorshipApi } from 'views/Student/Mentorship/services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import PDFIcon from 'assets/ComponentIcons/PDFIcon';
import DisplayPdf from 'components/DisplayDocument/DisplayDocument';
import { DefaultEyeIcon } from 'assets/ComponentIcons/DefaultEye';

export function DetailsModal({
  item,
  title,
  onClose,
  isOpen,
  openRescheduleModal,
  openCancelModal,
  showRescheduleButton,
  buttonText,
  showTypeLabel,
  showReasonCancellation,
  showModalityInfo,
  showModality,
  showActionButtons,
  isActiveExpiredTab,
  showStudentAssessment,
  showConfirmedButton,
  fetchMentorships,
  setPage,
}) {
  const [documentFile, setDocumentFile] = useState(null);
  const { universityColor, openModalWithContent } = useContext(
    BaseLayoutContext
  );
  const {
    formattedStartHour,
    formattedEndHour,
    formattedPreviousDate,
    formattedPreviousStartHour,
    formattedPreviousEndHour,
    simpleFormattedDate,
  } = formatDateTime(
    item?.date,
    item?.start_hour,
    item?.end_hour,
    item?.previous_date,
    item?.previous_start_hour,
    item?.previous_end_hour
  );

  const handleConfirmMentorship = async (mentorshipId) => {
    try {
      await mentorshipApi.confirmMentorship(mentorshipId);
      onClose();
      customSnackbar('Mentoria confirmada!', 'confirmation');
      setPage(1);
      fetchMentorships(true);
    } catch (error) {
      customSnackbar('Erro ao confirmar mentoria', 'error');
    }
  };

  const renderMentorDetails = () => {
    const isRescheduleRequest = item?.status === 'reschedule_request';
    const mentorLabel = item?.mentor?.label || 'Mentor não identificado';
    const previousMentor = item?.previous_mentor;

    return (
      <DetailsItem
        icon={<UserIcon size="30px" color={universityColor} />}
        label="Mentor"
        value={
          isRescheduleRequest && previousMentor ? previousMentor : mentorLabel
        }
        valueNew={
          isRescheduleRequest && previousMentor ? mentorLabel : undefined
        }
      />
    );
  };

  const renderDateDetails = () => (
    <DetailsItem
      icon={<CalendarIcon size="30px" color={universityColor} />}
      label="Data"
      value={
        item?.status === 'reschedule_request'
          ? formattedPreviousDate
          : simpleFormattedDate
      }
      valueNew={
        item?.status === 'reschedule_request' ? simpleFormattedDate : undefined
      }
    />
  );

  const renderTimeDetails = () => (
    <DetailsItem
      icon={<ClockIcon size="30px" color={universityColor} />}
      label="Horário"
      value={
        item?.status === 'reschedule_request'
          ? `Das ${formattedPreviousStartHour} às ${formattedPreviousEndHour}`
          : `Das ${formattedStartHour} às ${formattedEndHour}`
      }
      valueNew={
        item?.status === 'reschedule_request'
          ? `Das ${formattedStartHour} às ${formattedEndHour}`
          : undefined
      }
    />
  );

  const renderModalityDetails = () => (
    <>
      {showModality && (
        <DetailsItem
          icon={<HouseLaptopIcon size="30px" color={universityColor} />}
          label="Modalidade"
          value={item?.mentorship_modality}
        />
      )}
      {showModalityInfo && (
        <GridModality>
          {item?.mentorship_modality === 'Presencial' &&
            getAddressDetails(item) && (
              <DetailsItem
                icon={<HouseLaptopIcon size="30px" color={universityColor} />}
                label="Presencial"
                value={getAddressDetails(item)}
              />
            )}
          {item?.mentorship_modality === 'Online' && item?.access_link && (
            <DetailsItem
              icon={<HouseLaptopIcon size="30px" color={universityColor} />}
              label="Online"
              value={item?.access_link}
            />
          )}
        </GridModality>
      )}
    </>
  );

  const renderTypeLabel = () => {
    return (
      showTypeLabel &&
      (item?.status === 'reschedule_request' ? (
        <TypeLabel>
          {item?.rescheduled_by === 'student'
            ? 'Reagendada por você'
            : 'Reagendada pela Instituição'}
        </TypeLabel>
      ) : item?.status === 'canceled' ? (
        <TypeLabel>
          {item?.canceled_by === 'student'
            ? 'Cancelada por você'
            : 'Cancelada pela Instituição'}
        </TypeLabel>
      ) : item?.status === 'not_realized' ? (
        <TypeLabel>
          {item?.finished_by === 'student'
            ? 'Marcada como não realizada por você'
            : 'Marcada como não realizada pela Instituição'}
        </TypeLabel>
      ) : null)
    );
  };

  const renderStudentAssessment = () =>
    showStudentAssessment &&
    item.mentor_rating > 0 && (
      <RatingContainer>
        <span>Sua avaliação:</span>
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index} selected={index < item.mentor_rating} />
        ))}
      </RatingContainer>
    );

  const renderRescheduleReason = () => {
    return (
      item?.status === 'reschedule_request' && (
        <StudentNotes>
          <strong>Motivo do reagendamento:</strong>
          <p
            dangerouslySetInnerHTML={{
              __html: item?.rescheduling_reason,
            }}
          />
        </StudentNotes>
      )
    );
  };

  const renderCancellationReason = () =>
    showReasonCancellation &&
    (item?.cancel_reason || item?.not_realize_reason) ? (
      <StudentNotes>
        <strong>
          {item?.cancel_reason
            ? 'Motivo do cancelamento:'
            : 'Motivo pelo qual a mentoria não foi realizada:'}
        </strong>
        <p
          dangerouslySetInnerHTML={{
            __html: item?.cancel_reason || item?.not_realize_reason,
          }}
        />
      </StudentNotes>
    ) : null;

  useEffect(() => {
    if (isOpen && item?.document) {
      async function urlToFile(url, filename) {
        const mimeType = 'application/pdf';

        const res = await fetch(url);
        const buffer = await res.arrayBuffer();
        return new File([buffer], filename, { type: mimeType });
      }

      urlToFile(item?.document.file, item?.document.name).then((file) =>
        setDocumentFile(file)
      );
    }
  }, [item, isOpen]);

  const handleSeeDocument = () => {
    openModalWithContent(
      <DisplayPdf
        file={documentFile}
        style={{
          margin: '0 auto',
          width: '80%',
          height: '90vh',
        }}
      />,
      null,
      null,
      () => {},
      false,
      null,
      true
    );
  };

  const renderFileInput = () =>
    documentFile && (
      <FileTile>
        <LabelContent>
          <PDFIcon color={universityColor || '#009291'} size={22} />
          <p title={documentFile?.name}>{documentFile?.name}</p>
        </LabelContent>
        <ActionsWrapper>
          <ActionButton
            color={universityColor}
            onClick={handleSeeDocument}
            type={'button'}
          >
            <DefaultEyeIcon
              size={20}
              color={universityColor || '#009291'}
              alt="Ver documento"
            />
          </ActionButton>
        </ActionsWrapper>
      </FileTile>
    );

  const renderActionButtons = () =>
    showActionButtons && (
      <ActionButtons>
        <CanceledButton onClick={() => openCancelModal(item)}>
          {buttonText}
        </CanceledButton>
        {showRescheduleButton && (
          <RescheduleButton
            color={universityColor}
            onClick={() => openRescheduleModal(item)}
          >
            Reagendar mentoria
          </RescheduleButton>
        )}
        {showConfirmedButton &&
          item?.status === 'reschedule_request' &&
          item?.rescheduled_by !== 'student' && (
            <ConfirmedButton
              color={universityColor}
              onClick={() => handleConfirmMentorship(item.id)}
            >
              Confirmar mentoria
            </ConfirmedButton>
          )}
      </ActionButtons>
    );

  return (
    <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
      <Container>
        <StatusFlag
          mentoringStatus={item?.status}
          isActiveExpiredTab={isActiveExpiredTab}
        />
        <WrapperInformations>
          <GridSide>
            {renderMentorDetails()}
            {renderDateDetails()}
            {renderTimeDetails()}
            {renderModalityDetails()}
          </GridSide>
        </WrapperInformations>
        {renderStudentAssessment()}
        {renderTypeLabel()}
        {renderRescheduleReason()}
        {renderCancellationReason()}
        {renderFileInput()}
        {renderActionButtons()}
      </Container>
    </GenericModal>
  );
}

export default DetailsModal;
