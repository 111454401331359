import React, { useContext, Fragment } from 'react';
import { MultiStepDivActive, WrapperStep } from './style';

export default function StepIndicator({ StepsQuantity, currentStep }) {
  const Steps = Array.from({ length: StepsQuantity });

  return (
    <WrapperStep>
      {Steps.map((_, index) => (
        <Fragment>
          <MultiStepDivActive
            style={
              index === currentStep
                ? { backgroundColor: '#009291', color: '#FFF' }
                : index > currentStep
                ? { color: '#606062', backgroundColor: '#EFEEEC' }
                : { backgroundColor: '#98D2D2', color: '#fff' }
            }
          >
            {index + 1}
          </MultiStepDivActive>

          <div
            style={
              index === currentStep
                ? {
                    width: '34%',
                    backgroundColor: '#009291',
                    height: '5px',
                    marginTop: '50px',
                    marginLeft: '-95px',
                  }
                : index > currentStep
                ? {
                    width: '34%',
                    backgroundColor: '#EFEEEC',
                    height: '5px',
                    marginTop: '50px',
                    marginLeft: '-90px',
                  }
                : {
                    width: '34%',
                    backgroundColor: '#98D2D2',
                    height: '5px',
                    marginTop: '50px',
                    marginLeft: '-90px',
                  }
            }
          ></div>
        </Fragment>
      ))}
    </WrapperStep>
  );
}
