import React, { Fragment } from 'react';
import { Card, CardButton, CardImgSkeleton, CardSubTitles, CardTitle } from './style';
export default function SkeletonCard({ quantity }) {
  const numberOfCards = [];

  for (let i = 1; i <= quantity; i++) {
    numberOfCards.push(i);
  }

  const mapCards = () => {
    return numberOfCards.map((item) => (
      <Card>
        <CardImgSkeleton />
        <CardTitle widthTitle="190px"/>
        <CardSubTitles widthSubTitle="150px" top={20} />
        <CardSubTitles widthSubTitle='180px' top={22} />
        <CardSubTitles widthSubTitle='190px' top={24} />
        <CardSubTitles widthSubTitle='180px' top={26} />
        <CardSubTitles widthSubTitle='190px' top={28} />
        <CardButton widthSubTitle='180px' top={32}/>
      </Card>
    ));
  };

  return <Fragment>{mapCards()}</Fragment>;
}

SkeletonCard.defaultProps = {
  quantity: 3,
};
