export default function DeletedIcon({ color, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.35" cy="7.35" r="7.35" fill={color} />
      <path
        d="M7.5 6.72234L10.2223 4L11 4.77766L8.27766 7.5L11 10.2223L10.2223 11L7.5 8.27766L4.77766 11L4 10.2223L6.72234 7.5L4 4.77766L4.77766 4L7.5 6.72234Z"
        fill="white"
      />
    </svg>
  );
}
