import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;

  text-align: left;

  color: #606062;
  margin: ${(props) => (props.isStepThree ? '0 10px 0 0' : '0')};
`;

export const Paragraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  text-align: left;

  color: #606062;
  margin: 0;
`;

export const ContainerInformation = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
`;

export const ContainerIconTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;

  @media (max-width: 1200px) {
    gap: 20px;
    padding: 2rem 0.4rem 3rem;
    overflow-x: scroll !important;
    overflow-y: hidden;
    flex-basis: 0;
    flex-grow: 1;
    height: 100%;
    z-index: 0;
  }
`;

export const GamificationColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-top: 25px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 18px;
`;

export const IconImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;
  margin-right: 10px;

  border-radius: 8px;
  box-shadow: -4px 4px 6px #00000017;
`;

export const Text = styled.p`
  color: #606062;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  transition: 0.5s;

  @media (max-width: 1380px) {
    font-size: 13px;
    transition: 0.5s;
  }
`;

export const Infos = styled.p`
  color: #606062;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  transition: 0.5s;

  @media (max-width: 1380px) {
    font-size: 12px;
    transition: 0.5s;
  }
`;

export const InfosOne = styled.p`
  color: ${(props) => (props.returnColor ? props.returnColor : '#606062')};
  font-size: 14px;
  font-weight: 400;
  margin: 0;

  transition: 0.5s;

  @media (max-width: 1380px) {
    font-size: 13px;
    transition: 0.5s;
  }
`;

export const InfosTwo = styled.p`
  color: ${(props) => (props.returnColor ? props.returnColor : '#606062')};
  font-size: 14px;
  font-weight: 400;
  margin: 0;
`;

export const TextComposition = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const Icon = styled.div`
  width: 15px;
  height: 20px;
`;

export const TitleStepThree = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 4px;
`;

export const ScopeStepThree = styled.div`
  padding: 20px 34px 0 0;
`;

export const ColorInfo = styled.div`
  color: ${(props) => props.color};
  font-weight: 400;
`;
export const Button = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;

  color: ${(props) => (props.universityColor ? props.universityColor : '#000')};
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;

  padding: 0;

  background-color: transparent;
  border: 0;

  cursor: pointer;
`;
