import React, { createContext, useState, useEffect, useContext } from 'react';
import { getProfile } from 'services/student/profile';
import BaseLayoutContext from 'contexts/base-layout';
import AuthContext from 'contexts/authentication';
import { getCurriculum } from 'services/student/curriculumData';

export const CurriculumContext = createContext();

export default function CurriculumContextProvider(props) {
  const [isOpenCurriculumModal, setIsOpenCurriculumModal] = useState(false);
  const [profile, setProfile] = useState([]);
  const [curriculum, setCurriculum] = useState([]);
  const [loadingCurriculum, setLoadingCurriculum] = useState(false);
  const { setUserData, metadata } = useContext(AuthContext);
  const { handleIsLoadingState } = useContext(BaseLayoutContext);

  async function fetchData() {
    setLoadingCurriculum(true);
    const [curriculum, profile] = await Promise.all([
      getCurriculum(),
      getProfile(),
    ]);
    setProfile(profile);
    setCurriculum(curriculum);
    setLoadingCurriculum(false);
  }

  useEffect(() => {
    metadata?.role === 'student' && fetchData();
  }, []);

  const updateBehavioralState = (newBehavioralTest) => {
    const newProfile = profile;
    newProfile.quizzes.behavioral.is_done = true;
    newProfile.quizzes.behavioral.results = newBehavioralTest;
    setProfile(newProfile);
    updateCurriculumState();
    setUserData({
      quizzes: {
        ...profile.quizzes,
        behavioral: {
          is_done: true,
          is_enabled: false,
          results: newBehavioralTest,
        },
      },
    });
  };

  const updatePersonalityState = (newPersonalityTest) => {
    const newProfile = profile;
    newProfile.quizzes.personality.is_done = true;
    newProfile.quizzes.personality.results = newPersonalityTest;
    setProfile(newProfile);
    updateCurriculumState();
    setUserData({
      quizzes: {
        ...profile.quizzes,
        personality: {
          is_done: true,
          is_enabled: false,
          results: newPersonalityTest,
        },
      },
    });
  };

  const updateProfileState = (academicProfile, profileUpdated) => {
    const newProfile = profile;
    newProfile.academic_profile = academicProfile;
    setProfile(newProfile);
    setUserData(profileUpdated);
    updateCurriculumState();
  };

  const updateInterestState = (newInterestState) => {
    const newProfile = profile;
    newProfile.interests = newInterestState.interests;
    newProfile.life_interests = newInterestState.life_interests;
    setProfile(newProfile);
    setUserData(newInterestState);
    updateCurriculumState();
  };

  const updateCurriculumState = async () => {
    const newProfile = await getProfile();
    setProfile(newProfile);
    handleIsLoadingState(false);
  };

  const updateAvatarState = (newAvatar) => {
    const newProfile = profile;
    newProfile.academic_profile.avatar = newAvatar.avatar;
    setProfile(newProfile);
    setUserData(newAvatar);
    updateCurriculumState();
  };

  const handleShowSpecialNeeds = (value) => {
    const newCurriculum = curriculum;
    newCurriculum.show_color_or_race = value || false;
    newCurriculum.show_gender_identity = value || false;
    newCurriculum.show_gender_identity = value || false;
    setCurriculum(newCurriculum);
    updateCurriculumState();
  };

  const closeCurriculumModal = () => {
    setIsOpenCurriculumModal(false);
  };
  const contextValues = {
    isOpenCurriculumModal,
    setIsOpenCurriculumModal,
    closeCurriculumModal,
    handleShowSpecialNeeds,
    profile,
    setProfile,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateInterestState,
    updateAvatarState,
    loadingCurriculum,
  };

  return (
    <CurriculumContext.Provider value={contextValues}>
      {props.children}
    </CurriculumContext.Provider>
  );
}
