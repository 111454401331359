import React, { useState } from 'react';

import { Container, Title } from '../styled';
import AnotherJobs from './AnotherJobs';
import GenericMessage from 'components/GenericMessage';

export default function OtherCardsJobOffers(props) {
  const {
    universityColor,
    listJobOffers,
    setListJobOffers,
    countListJobOffers,
    search,
    fetchListJobOffers,
    isGamificationLevelOneIncomplete,
    gamificationLevelOneIncomplete,
    isCoverLetterMissing,
    applyToJobOffer,
    totalLoadedOffers,
    setTotalLoadedOffers,
    listFavoriteJobOffers,
  } = props;
  const countJobOffers = listJobOffers?.data?.count;
  const dataJobOffers = listJobOffers?.data?.results;
  const dataFavoriteJobOffers = listFavoriteJobOffers?.data?.results;
  const returnTitle = () =>
    dataFavoriteJobOffers?.length === 0 ? null : (
      <Title>Outras vagas ({countJobOffers})</Title>
    );

  const returnContainerCards = () => {
    if (dataJobOffers?.length === 0 && dataFavoriteJobOffers?.length > 0) {
      return null;
    } else if (dataJobOffers?.length > 0) {
      return (
        <Container isOtherJobs>
          <>
            {returnTitle()}
            <AnotherJobs
              search={search}
              listJobOffers={listJobOffers}
              universityColor={universityColor}
              setListJobOffers={setListJobOffers}
              countListJobOffers={countListJobOffers}
              fetchListJobOffers={fetchListJobOffers}
              isGamificationLevelOneIncomplete={
                isGamificationLevelOneIncomplete
              }
              gamificationLevelOneIncomplete={gamificationLevelOneIncomplete}
              isCoverLetterMissing={isCoverLetterMissing}
              applyToJobOffer={applyToJobOffer}
              totalLoadedOffers={totalLoadedOffers}
              setTotalLoadedOffers={setTotalLoadedOffers}
              dataJobOffers={dataJobOffers}
            />
          </>
        </Container>
      );
    } else {
      return (
        <GenericMessage
          title="Nenhuma vaga encontrada."
          subtitle="No momento você não possui nenhuma vaga."
        />
      );
    }
  };

  return <>{returnContainerCards()}</>;
}
