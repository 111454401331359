import styled from 'styled-components';
import X from '../../../assets/Oportutinites/X.svg';
import plus from '../../../assets/Oportutinites/plus.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Wrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-top: 5em;
  align-items: center;
  justify-content: center;
`;

export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5em;
`;
export const Cards = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 8px;
  margin: 2em;
  width: 223px;
  height: 206px;
  position: relative;
`;

export const CardImg = styled.img`
  margin-bottom: 1em;
  width: 35%;
  color: white;
  position: absolute;
  top: 2.3em;
`;

export const CardTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #009291;
  margin-top: 40px;
`;

export const CardSubTitle = styled.p`
  max-width: 80%;
  word-break: break-word;
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  margin-top: 10px;
  color: #606062;
`;

export const PublishedOportunities = styled.span`
  cursor: pointer;
  margin: 1em 0;
  font-size: 1.5em;
  font-weight: 400;
  color: #0f7776;
`;

export const RModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000000000000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(10px);
`;

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const RModalContainer = styled.div`
  width: 512px;
  max-height: 1020px;
  margin-bottom: 5em;
  overflow: scroll;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const InfoModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000000000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(10px);
`;

export const InfoModalContainer = styled.div`
  width: 512px;
  min-height: 321px;
  margin-bottom: 5em;
  overflow: hidden;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const ButtonSave = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #0f7776;
  font-size: 14px;
  margin-top: 5em;
  cursor: pointer;
`;
export const AppendButton = styled.button`
  border: none;
  background-color: #0f7776;
  border-radius: 10px;
  color: #ffff;
  padding: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  &:before {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    content: '';
    background: url(${plus}) no-repeat 0 0;
    background-position: center;
    background-size: 15px;
    background-color: #fff;
    border-radius: 50%;
  }
`;

export const ButtonX = styled.button`
  background-color: transparent;
  border: none;
  width: 8px;
  height: 8px;
  background-image: url(${X});
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  margin: 0 0 0 5px;
  cursor: pointer;
`;

export const Title = styled.h2`
  margin-top: 1.5em;
`;

export const ErrorMessage = styled.span`
  color: #f00;
  margin-top: ${(props) => props.marginTop || '10px'};
  font-size: 12px;
`;
