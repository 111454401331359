import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  ContainerSearch,
  ProfessionsHeader,
  WrapperProfessions,
} from './style';

import AcademicModelingApi from 'services/university/academicModeling';
import { useAcademicModeling } from '../../../contexts/AcademicModelingContext';
import { SearchInput } from '../StepOne/style';
import Pagination from 'components/CustomPagination';
import { useDebounce } from '../../../hooks/useDebounce';
import SkeletonStepThree from './Skeleton';
import ModalInformation from 'components/informationModal';
import informationIcon from 'assets/academicModeling/information.svg';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export default function StepThree() {
  const pageRef = useRef(null);
  const [professions, setProfessions] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [informationModalConfig, setInformationModalConfig] = useState({});
  const [reportAlertShowed, setReportAlertShowed] = useState(false);
  const [charactersCountPpc, setCharactersCountPpc] = useState(0);
  const ppcDescriptionRef = useRef(null);

  const {
    selectedCourse,
    checkCurrentStep,
    setSelectedCourse,
  } = useAcademicModeling();

  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const togglePair = async (checked, id) => {
    let response;
    const profession = professions.find((profession) => profession.id === id);
    if (!profession) return;
    profession.in_profile = checked;
    const oldProfessions = professions;
    const newProfessions = professions.map((profession) => {
      if (profession.id === id) {
        profession.in_profile = checked;
      }
      return profession;
    });

    setProfessions(newProfessions);

    if (checked) {
      response = await AcademicModelingApi.putCreateEgressProfileProfession(
        selectedCourse.id,
        `add_professions=${id}`
      );
    } else {
      response = await AcademicModelingApi.putCreateEgressProfileProfession(
        selectedCourse.id,
        `remove_professions=${id}`
      );
    }
    if (!response) {
      setProfessions(oldProfessions);
    }
    checkCurrentStep();
  };

  const checkAll = async (checked) => {
    const oldProfessions = [...professions];
    const oldSelectedCourse = { ...selectedCourse };
    const newProfessions = professions.map((profession) => {
      profession.in_profile = checked;
      return profession;
    });
    setSelectedCourse((prev) => ({
      ...prev,
      professions_checked: checked,
    }));
    setProfessions(newProfessions);
    const query = queryParamsFromFilterObject({ add_all_professions: checked });
    const response = await AcademicModelingApi.putCreateEgressProfileProfession(
      selectedCourse.id,
      query
    );
    if (response) {
      checkCurrentStep();
    } else {
      setSelectedCourse(oldSelectedCourse);
      setProfessions(oldProfessions);
    }
  };

  const handleClickCheckAll = async (event) => {
    const checked = event.target.checked;
    const { contemplated_areas } = selectedCourse;
    if (reportAlertShowed || !contemplated_areas) {
      checkAll(checked);
    } else {
      showReportAlert(checked);
    }
  };

  const handleCheckPair = async (event, id) => {
    const checked = event.target.checked;
    const { contemplated_areas } = selectedCourse;

    if (reportAlertShowed || !contemplated_areas) {
      await togglePair(checked, id);
    } else {
      showReportAlert(checked, id);
    }
  };

  const professionsInputs = useMemo(() => {
    const pairs = professions.reduce((acc, curr, index) => {
      if (index % 2 === 0) {
        acc.push([curr]);
      } else {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, []);

    if (loading) {
      return null;
    }

    return pairs.map((pair, index) => (
      <WrapperProfessions key={index}>
        <span className="firstPair" title={pair[0]?.name}>
          <input
            type="checkbox"
            onChange={(event) => handleCheckPair(event, pair[0].id)}
            checked={pair[0].in_profile}
            id={`checkbox-${pair[0].id}`}
          />
          <label htmlFor={`checkbox-${pair[0].id}`}>{pair[0].name}</label>
        </span>
        {pair[1]?.name && (
          <span className="secondPair" title={pair[1]?.name}>
            <input
              type="checkbox"
              onChange={(event) => handleCheckPair(event, pair[1].id)}
              checked={pair[1].in_profile}
              id={`checkbox-${pair[1].id}`}
            />
            <label htmlFor={`checkbox-${pair[1].id}`}>{pair[1].name}</label>
          </span>
        )}
      </WrapperProfessions>
    ));
  }, [professions, loading]);

  const getProfessions = async (query = '') => {
    setLoading(true);
    const { results, count } = await AcademicModelingApi.getProfessions(
      selectedCourse.id,
      query
    );
    setProfessions(results);
    setLoading(false);
    setCount(count);
  };

  useEffect(() => {
    let searchQuery = `limit=${limit}&offset=0`;
    if (debouncedSearchTerm) {
      searchQuery = `name=${debouncedSearchTerm}&limit=${limit}&offset=0`;
    }
    getProfessions(searchQuery);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    async function fetchEgressProfile() {
      const data = await AcademicModelingApi.getEgressProfile(
        selectedCourse.id
      );
      if (data && ppcDescriptionRef.current) {
        ppcDescriptionRef.current.value = data.description;
        setCharactersCountPpc(data.description.length);
        setSelectedCourse({
          ...selectedCourse,
          professions_checked: data.professions_checked,
        });
      }
    }

    fetchEgressProfile();
  }, [ppcDescriptionRef]);

  const handleBlurDescription = async (event) => {
    const value = event.target.value;
    const data = { description: value };
    await AcademicModelingApi.putCreateEgressProfileData(
      selectedCourse.id,
      data
    );
    checkCurrentStep();
  };

  const handlePageChange = async (page) => {
    const newPage = `name=${debouncedSearchTerm}&limit=${limit}&offset=${
      (page - 1) * limit || 0
    }`;

    await getProfessions(newPage);
    setCurrentPage(page);
  };

  const handleLimitChange = async (newLimit) => {
    let searchQuery = debouncedSearchTerm ? `name=${debouncedSearchTerm}` : '';
    const query =
      searchQuery + `${searchQuery && '&'}limit=${newLimit}&offset=0`;
    setCurrentPage(1);
    await getProfessions(query);
    setLimit(newLimit);
  };

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (20 - 7 + 1) + 7);
  }, []);

  function handleClickInformationEgressProfile() {
    setInformationModalConfig({
      bubbleText: 'Dica!',
      ParagraphText: `Ao selecionar as possíveis carreiras de seu egresso,
       lembre-se de escolher atuações que estão relacionadas a sua matriz de
        curso, refletindo em uma quantidade ideal para o seu direcionamento
        de carreira.`,
      buttonConfirmText: 'OK, ENTENDI',
      buttonConfirmFunction: () => {
        setModalInformationOpen(false);
      },
      modalWidth: '368',
      modalHeight: '380',
    });
    setModalInformationOpen(true);
  }

  function showReportAlert(checked, id) {
    setInformationModalConfig({
      bubbleText: 'Atenção!',
      ParagraphText: `Ao alterar o perfil do egresso, o relatório de
      compatibilidade também poderá sofrer alterações.`,
      buttonConfirmText: 'CONTINUAR',
      buttonCancelText: 'CANCELAR',
      modalHeight: 350,
      showButtonCancel: true,
      buttonCancelWidth: 120,
      buttonConfirmFunction: () => {
        if (id) {
          togglePair(checked, id);
        } else {
          checkAll(checked);
        }
        setModalInformationOpen(false);
        setReportAlertShowed(true);
      },
      buttonCancelFunction: () => setModalInformationOpen(false),
      style: {
        textAlign: 'center',
      },
    });
    setModalInformationOpen(true);
  }

  const isAllProfessionsChecked = useMemo(() => {
    return selectedCourse.professions_checked;
  }, [selectedCourse]);

  return (
    <Container ref={pageRef}>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={informationModalConfig}
        buttonConfirmFunction={informationModalConfig.buttonConfirmFunction}
        buttonCancelFunction={informationModalConfig.buttonCancelFunction}
      />
      <header>
        <span>
          <h3>Perfil de egresso</h3>
          <img
            src={informationIcon}
            alt=""
            onClick={handleClickInformationEgressProfile}
          />
        </span>
        <br />
        <p>
          Mapeamos as principais carreiras das iniciativas públicas, privadas,
          empreendedoras e acadêmicas que podem ser desenvolvidas pelo egresso
          do curso.
          <br />
          Para auxiliar na definição de seu perfil do egresso, insira aqui uma
          breve descrição do PPC contendo as informações necessárias.
        </p>
      </header>

      <section className="descriptionStepThree">
        <textarea
          ref={ppcDescriptionRef}
          placeholder="Digite aqui"
          onBlur={handleBlurDescription}
          maxLength={2000}
          onChange={(event) => {
            setCharactersCountPpc(event.target.value.length);
          }}
        />
        <span>{2000 - charactersCountPpc} Caracteres restantes</span>
      </section>

      <section>
        <ContainerSearch>
          <span>
            <p>
              Agora selecione as carreiras que são mais adequadas ao perfil dos
              egressos de seu curso.{' '}
            </p>
            <SearchInput
              placeholder="Buscar"
              width="250px"
              onChange={handleSearch}
            />
          </span>
        </ContainerSearch>
        <ProfessionsHeader>
          <input
            type="checkbox"
            onChange={handleClickCheckAll}
            checked={isAllProfessionsChecked}
          />
          <p>NOME DA ATUAÇÃO PROFISSIONAL</p>
        </ProfessionsHeader>

        {loading
          ? Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
              <SkeletonStepThree key={index} />
            ))
          : professionsInputs}
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={count}
          onPageChange={handlePageChange}
          setLimit={handleLimitChange}
          showLimit={count > 20}
          setCurrentPage={setCurrentPage}
          pageRef={pageRef}
          limit={limit}
        ></Pagination>
      </section>
    </Container>
  );
}
