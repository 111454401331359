import { IoStarOutline } from 'react-icons/io5';
import Button from '../../Button';
import FeedbackContent from '../../FeedbackContent';
import {
  ContainerBottomInformations,
  ContainerButtons,
  ContainerFeedBacks,
} from '../styled';
import { BiDislike, BiLike } from 'react-icons/bi';
import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';
import { BuildTwoTone } from '@material-ui/icons';
import { useVisualizationStudentContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationStudent';
import FeedbackModal from 'components/FeedbackModal';
import { useState } from 'react';

export default function BottomActions({}) {
  const visualizationContextInstance = useVisualizationContext();
  const registerModalInstance = useRegisterModalContext();
  const visualizationStudentInstance = useVisualizationStudentContext();

  const { detailsObject, currentTab } = visualizationContextInstance;

  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  const CoreHandlersFactory = CoreHandlers(
    visualizationContextInstance,
    registerModalInstance,
    visualizationStudentInstance
  );

  const handleConfirmUnpublish = (feedback) => {
    CoreHandlersFactory.handleClickUnpublish(detailsObject.id, feedback);
  };

  const handleCLickUnpublish = () => {
      handleOpenFeedbackModal();
      return;
  };

  const buttons = [
    {
      label: 'Editar',
      onClick: () => CoreHandlersFactory.handleClickEdit(detailsObject.id),
      theme: 'dark',
    },
    currentTab === 1
      ? {
          label: 'Realizar check-in',
          onClick: () =>
            CoreHandlersFactory.handleClickCheckIn(detailsObject.id),
          theme: 'unfilled',
        }
      : null,

    currentTab === 2
      ? {
          label: 'Finalizar evento',
          onClick: () =>
            CoreHandlersFactory.handleClickFinishEvent(detailsObject.id),
          theme: 'unfilled',
        }
      : null,
    {
      label: 'Despublicar evento',
      onClick: () => handleCLickUnpublish(),
      theme: 'flat',
      width: '100%',
    },
  ].filter(Boolean);

  const createButtons = () => {
    if (currentTab !== 3) {
      return buttons.map((button) => {
        return (
          <Button
            theme={button.theme}
            onClick={button.onClick}
            width={button.width}
          >
            {button.label}
          </Button>
        );
      });
    }
  };

  const handleOpenFeedbackModal = () => {
    setFeedbackModalOpen(true);
  };

  const onCloseFeedbackModal = () => {
    setFeedbackModalOpen(false);
  };

  return (
    <ContainerBottomInformations>
      <ContainerButtons>{createButtons()}</ContainerButtons>
      <FeedbackModal
        isOpen={feedbackModalOpen}
        showFeedback={false}
        required={true}
        config={{
          bubbleText: 'Atenção!',
          paragraphText:
            'Para despublicar o evento, envie um feedback aos seus estudantes:',
          buttonConfirmText: 'DESPUBLICAR',
        }}
        onConfirm={handleConfirmUnpublish}
        onClose={onCloseFeedbackModal}
      />
      <ContainerFeedBacks>
        <FeedbackContent
          icon={<IoStarOutline />}
          text={detailsObject.interactions?.favorited || 0}
          color="#606062"
        />
        <FeedbackContent
          icon={<BiLike />}
          text={detailsObject.interactions?.like || 0}
          color="#009291"
        />
        <FeedbackContent
          icon={<BiDislike />}
          text={detailsObject.interactions?.dislike || 0}
          color="#DA0505"
        />
      </ContainerFeedBacks>
    </ContainerBottomInformations>
  );
}
