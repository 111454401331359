import styled from 'styled-components';
import white from 'assets/img/icons/icoLocation.png';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  width: 88%;
  height: 100%;

  border-radius: 8px;
  background-color: #ffffff;
  height: 100%;
  margin-top: 32px;
`;

export const Title = styled.p`
  color: #009291;
  font-weight: 500;
  font-size: 22px;

  width: 100%;
  text-align: start;
  margin-bottom: 18px;
  margin-top: ${(props) => props.marginTop || '54px'};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  margin-bottom: 50px;

  .scroll-buttons {
    width: 90px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    margin-top: 30px;
    margin-bottom: -10px;
  }
`;

export const SubTitle = styled.p`
  font-size: 16px;
  text-align: start;
  margin-bottom: 26px;
`;

export const ListPublishedProjects = styled.div`
  display: flex;
  height: 84%;
  gap: 36px;
  overflow: hidden;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;

  padding: 10px;
  margin-left: -10px;

  > li {
    list-style-image: url(${white});
    width: 3px;
    height: 100%;
    background: #ffffff;
    margin-top: 420px;
  }
`;

export const ArrowButton = styled.button`
  width: 35px;
  height: 35px;

  background: #009291;

  border: none;
  border-radius: 200px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  :active {
    transform: scale(0.8);
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e9e9e9;
  border-radius: 8px;
`;

export const ContainerDashed = styled.div`
  width: 100%;
  max-width: 620px;
  height: ${(props) => props.height || '168px'};

  border: 1px dashed #bdbdbd;
  border-radius: 4px;
  padding: 2rem 0.5rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;

  p {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
`;

export const ButtonAddProject = styled.button`
  width: 100%;
  max-width: 285px;
  height: 42px;

  background-color: #009291;
  border: none;
  border-radius: 8px;

  display: flex;
  align-items: center;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  margin-top: 24px;

  cursor: pointer;

  .white-box {
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;
  }
`;
