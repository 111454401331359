import { useContext } from 'react';

import {
  getFavoriteLearnings,
  getFinishedLearnings,
  getLearnings,
} from '../Services';
import { LearningContext } from '../Context/LearningContext';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export const useAPICallsLearning = () => {
  const {
    setLearnings,
    setLearningsCount,
    setCurrentPageLearnings,
    setFinalizedLearning,
    setFinalizedCount,
    setCurrentPageFinalized,
    setFavoritesLearning,
    setFavoritesCount,
    setCurrentPageFavorites,
    setFavoritesTabCount,
    setFinishedTabCount,
    setAvailableTabCount,
    setFilterState,
    setQueryFilter,
    setAppliedFilters,
  } = useContext(LearningContext);

  const getAvailableLearnings = async (
    reset = false,
    currentPage = 0,
    queryFilter
  ) => {
    const param = queryFilter
      ? `?${queryFilter}&limit=25&offset=${reset ? 0 : currentPage}`
      : `?limit=25&offset=${reset ? 0 : currentPage}`;

    try {
      const response = await getLearnings(param);
      const { results, count } = response;

      if (reset) {
        setLearnings(null);
        setCurrentPageLearnings(0);
        setLearningsCount(0);
        setLearnings(results);
        setLearningsCount(count);
        setAvailableTabCount(count);
        setQueryFilter(null);
        setFilterState({});
        setAppliedFilters({});
        return;
      }

      if (results.length !== 0) {
        setCurrentPageLearnings(currentPage);
        setLearnings((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setLearningsCount(count);
        setAvailableTabCount(count);
      } else {
        setLearnings([]);
        setAvailableTabCount(count);
      }
    } catch (error) {
      customSnackbar(
        'Ocorreu um erro ao buscar as aprendizagens disponíveis.',
        'error'
      );
    }
  };

  const getFinalizedLearnings = async (
    reset = false,
    currentPage = 0,
    queryFilter
  ) => {
    const param = queryFilter
      ? `${queryFilter}&limit=25&offset=${reset ? 0 : currentPage}`
      : `?limit=25&offset=${reset ? 0 : currentPage}`;

    try {
      const response = await getFinishedLearnings(param);
      const { results, count } = response;

      if (reset) {
        setFinalizedLearning(null);
        setCurrentPageFinalized(0);
        setFinalizedCount(0);
        setFinalizedLearning(results);
        setFinalizedCount(count);
        setFinishedTabCount(count);
        setQueryFilter(null);
        setFilterState({});
        setAppliedFilters({});
        return;
      }

      if (results.length !== 0) {
        setCurrentPageFinalized(currentPage);
        setFinalizedLearning((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setFinalizedCount(count);
        setFinishedTabCount(count);
      } else {
        setFinalizedLearning([]);
        setFinishedTabCount(count);
      }
    } catch (error) {
      customSnackbar(
        'Ocorreu um erro ao buscar as aprendizagens finalizadas.',
        'error'
      );
    }
  };

  const getFavoritesLearnings = async (
    reset = false,
    currentPage = 0,
    queryFilter
  ) => {
    const param = queryFilter
      ? `${queryFilter}&limit=25&offset=${reset ? 0 : currentPage}`
      : `?limit=25&offset=${reset ? 0 : currentPage}`;

    try {
      const response = await getFavoriteLearnings(param);
      const { results, count } = response;

      if (reset) {
        setFavoritesLearning(null);
        setCurrentPageFavorites(0);
        setFavoritesCount(0);
        setFavoritesLearning(results);
        setFavoritesCount(count);
        setFavoritesTabCount(count);
        setQueryFilter(null);
        setFilterState({});
        setAppliedFilters({});
        return;
      }

      if (results.length !== 0) {
        setCurrentPageFavorites(currentPage);
        setFavoritesLearning((prevLearnings) =>
          prevLearnings ? [...prevLearnings, ...results] : results
        );
        setFavoritesCount(count);
        setFavoritesTabCount(count);
      } else {
        setFavoritesLearning([]);
        setFavoritesTabCount(count);
      }
    } catch (error) {
      customSnackbar(
        'Ocorreu um erro ao buscar as aprendizagens favoritadas.',
        'error'
      );
    }
  };

  return {
    getAvailableLearnings,
    getFinalizedLearnings,
    getFavoritesLearnings,
  };
};
