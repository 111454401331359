import React, { Fragment } from "react";
import { object } from "prop-types";
import { Grid } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import learningTrailsStyle from "assets/jss/material-dashboard-pro-react/views/learningTrailsStyle";

const HowImportant = props => {
  const { classes, data } = props;
  return (
    <Fragment>
      <Grid
        container
        justify="center"
        className={classes.fullScreenGridContainer}
      >
        <Grid item xs md={8} sm={12} className={classes.learningTrails}>
          <h2 className={classes.cardSubtitle}>Qual a importância?</h2>
          <p className={classes.cardSubtitleDescription}>{data.description}</p>

          <br />

          <Grid
            container
            justify="center"
            className={(classes.cardVideo, classes.blockMargin)}
          >
            <iframe
              className={classes.videos}
              src={data.videoSrc}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

HowImportant.propTypes = {
  classes: object,
  data: object
};

export default withStyles(learningTrailsStyle)(HowImportant);
