export function BrainIcon ({ color = "#009291", size = 12}) {
  return (
    <svg width={size} height={size} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.0002 42.0491V37.5956C36.0002 37.0433 36.4463 36.5956 36.9986 36.5956L45.6464 36.5954C46.8648 36.5954 47.1694 35.5893 47.1694 35.0863V31.6892C47.1694 31.6067 47.1795 31.523 47.2015 31.4435C47.7193 29.5734 48.724 30.0559 50.2156 30.0559C51.7386 30.0559 51.7386 32.5711 51.7386 34.5832C51.7386 36.1929 52.4155 36.5954 52.754 36.5954H68C68.5523 36.5954 69.0049 37.0461 68.9601 37.5966C68.6664 41.2063 66.795 43.3534 64.8947 44.5199C64.0571 45.0341 63.4572 47.2511 63.9035 48.1268L63.923 48.1652C64.9384 50.1774 64.9384 57.2199 59.8615 59.7351C56.0162 61.6401 52.4743 60.0882 50.9592 58.9215C50.8013 58.7998 50.6956 58.6264 50.6436 58.434C49.9767 55.9642 49.1611 53.0422 50.7229 59.2322C52.7537 67.2808 46.6614 69.796 41.5846 68.7899C37.5231 67.9851 36.1692 64.0949 36 62.2505L36.0002 56.7109C36.0002 56.1587 36.4482 55.7144 36.9957 55.6425C39.3295 55.3361 43.0376 53.8693 42.6002 48.6685C42.2574 44.5933 39.2393 43.3361 37.0849 43.1436C36.5025 43.0916 36.0002 42.6338 36.0002 42.0491Z" fill={color} stroke={color} stroke-width="0.5"/>
      <path d="M43.0004 32.7457H36.5004L36.5 24.2461C36.5 23.0461 35.8333 22.7461 35.5 22.7461H32C30.4 22.7461 30 21.4128 30 20.7461V19.7461C30 18.1461 31.6667 17.7461 32.5 17.7461H35.5C36.3 17.7461 36.5 17.0794 36.5 16.7461L36.5004 7.74609C36.1004 4.14609 39.0004 2.24602 40.5004 1.74598C50.1004 -1.45402 51.8337 6.41256 51.5004 10.7459C59.5004 7.24586 64.0004 12.7459 64.5004 17.2459C64.9004 20.8459 63.3337 23.4125 62.5004 24.2459C67.7004 25.8459 69.0004 30.5792 69.0004 32.7459L56.0004 32.7457C55.6004 29.5455 53.5004 27.7456 52.5004 27.2457C51.667 26.9123 49.4004 26.4457 47.0004 27.2457C44.6004 28.0457 43.3337 31.2457 43.0004 32.7457Z" fill={color} stroke={color} stroke-width="0.5"/>
      <path d="M26.3331 36.0002H32.6664L32.6667 44.8356C32.6667 46.083 33.3163 46.3948 33.6411 46.3948H37.0513C38.6103 46.3948 39 47.7808 39 48.4738V49.5133C39 51.1765 37.3761 51.5923 36.5641 51.5923H33.6411C32.8616 51.5923 32.6667 52.2853 32.6667 52.6318L32.6664 61.9874C33.0561 65.7296 30.2305 67.7048 28.769 68.2246C19.4152 71.551 17.7263 63.3736 18.0511 58.8691C10.2563 62.5074 5.87175 56.7901 5.38457 52.1123C4.99483 48.3701 6.52132 45.7021 7.33327 44.8358C2.26665 43.1726 1 38.2523 1 36L13.6665 36.0002C14.0563 39.3267 16.1024 41.1978 17.0768 41.7175C17.8887 42.064 20.0973 42.5491 22.4357 41.7175C24.7741 40.8859 26.0083 37.5594 26.3331 36.0002Z" fill={color} stroke={color} stroke-width="0.5"/>
      <path d="M32.9998 26.2017V32.5735L23.6459 32.5737C22.4644 32.5737 22.169 33.554 22.169 34.0442V37.4751C21.6767 39.4357 20.6921 38.9455 19.2152 38.9455C17.7383 38.9455 17.7383 36.4948 17.7383 34.5343C17.7383 32.9658 17.0819 32.5737 16.7537 32.5737H1C1 26.3 5.59483 24.078 7.89224 23.7512C7.56407 23.5879 6.7108 22.869 5.92311 21.3005C4.93851 19.34 4.93851 12.4781 9.86154 10.0274C13.8 8.0668 17.4102 9.86398 18.723 11.0076C19.3795 13.4583 20.2986 16.7911 18.7232 10.5173C16.754 2.67508 22.6616 0.22439 27.5847 1.20467C31.5231 1.98889 32.8359 5.77929 33 7.57647L32.9998 13.9483C31.0306 13.9483 26.1075 14.9286 26.5998 20.8102C26.9937 25.5155 31.0306 26.3651 32.9998 26.2017Z" fill={color} stroke={color} stroke-width="0.5"/>
    </svg>

  )
}