import React, { useState, useEffect } from 'react';
import {
  Overlay,
  Box,
  Content,
  ButtonClose,
  SpanContainer,
  Image,
  Item,
  MailContainer,
  MailHeader,
  MainContent,
} from './styles';
import moment from 'moment';
import userIcon from 'assets/csm/userIcon.svg';
import calendarClockIcon from 'assets/csm/calendarClockIcon.svg';
import bullhornIcon from 'assets/csm/bullhornIcon.svg';
import closeIcon from 'assets/csm/closeIcon.svg';
import ReactHtmlParser from 'react-html-parser';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import { campaignDetails } from '../../services';

export function EmailDetails({ onClose, selectedCampaignIds }) {
  const [itemUniversity, setItemUniversity] = useState({});
  const [campaignDetailsData, setCampaignDetailsData] = useState(null);
  const emailId = selectedCampaignIds.emailId;
  const campaignId = selectedCampaignIds.campaignId

  useEffect(() => {
    const fetchCampaignDetails = async () => {
      try {
        const details = await campaignDetails(emailId, campaignId);
        setCampaignDetailsData(details);
      } catch (error) {
        throw error;
      }
    };
    fetchCampaignDetails();
  }, [emailId, campaignId]);

  useEffect(() => {
    callDataUniversity();
  }, []);

  const callDataUniversity = async () => {
    try {
      const item = await sendEmailApi.getUniversityData();
      setItemUniversity(item);
    } catch (error) {
        throw error;
    }
  };

  const returnDate = (date) => {
    if(!date){
      return '-'
    }
    
    const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}).*Z$/;
    const matches = date.match(regex);
    if(!matches.length > 0){
      return '-'
    }

    let newDateEmail = matches[1] + 'Z';
    return moment(newDateEmail).format('DD/MM/YYYY - HH:mm');
  }

  return (
    <Overlay>
      <Box>
        <Content>
          <header>
            <SpanContainer>
              <div className="rowContainer">
                <span className="sendDate">
                  Envio: {moment(campaignDetailsData?.send_at).format('DD/MM/YYYY - HH:mm')}
                </span>
                <ButtonClose>
                  <button onClick={onClose}>
                    <img src={closeIcon} />
                  </button>
                </ButtonClose>
              </div>
            </SpanContainer>

            <SpanContainer>
              <div className="campaignName">
                <p>{campaignDetailsData?.name}</p>
              </div>
            </SpanContainer>

              <SpanContainer>
                <div className="firstRow">
                  <span>
                    <Image>
                      <img src={bullhornIcon} />
                    </Image>
                    <Item>
                      Nome da campanha:
                      <p>{campaignDetailsData?.name}</p>
                    </Item>
                  </span>
                  <span>
                    <Image>
                      <img src={userIcon} />
                    </Image>
                    <Item>
                      Responsável:
                      <p>{campaignDetailsData?.responsible}</p>
                    </Item>
                  </span>
                </div>
                <div className="secondRow">
                  <span>
                    <Image>
                      <img src={calendarClockIcon} />
                    </Image>
                    <Item>
                      Data e hora de abertura:
                      <p>{returnDate(campaignDetailsData?.opened_at)}</p>
                    </Item>
                  </span>
                  {campaignDetailsData?.button_link ? (
                    <span>
                      <Image>
                        <img src={calendarClockIcon} />
                      </Image>
                      <Item>
                        Data e hora de clique:
                        <p>{returnDate(campaignDetailsData?.clicked_at)}</p>
                      </Item>
                    </span>
                    ) : null
                  }
                </div>
              </SpanContainer>
            </header>
          <div
            style={{
              border: '0.2px solid #E6E6E6',
              width: '520px',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          />
          <div className="campaignName">
            <span>Visualizar e-mail:</span>
          </div>
          <MailContainer color={itemUniversity?.color}>
            <MailHeader color={itemUniversity?.color}>
              <img alt="logoIes" src={itemUniversity?.logo} />
            </MailHeader>

              <MainContent color={itemUniversity?.color}>
                <p>
                  {ReactHtmlParser(campaignDetailsData?.email_body)}
                </p>
                {campaignDetailsData?.button_name &&
                  <div className="actionButton">
                    <button>
                      {campaignDetailsData?.button_name}
                    </button>
                  </div>
                }
                <div className="sendBy">
                  <span>
                    Enviado pelo <strong>Escritório de Carreira</strong>
                    <br />
                    Todos os direitos reservados{' '}
                  </span>
                </div>

                <div className="spamInfo">
                  <span>
                    Só te pedimos apenas uma coisa, não jogue a gente no spam!
                  </span>
                  <span>
                    Se não quiser receber mais os nossos e-mails, clique aqui.
                    Vamos ficar tristes, mas respeitaremos sua decisão, ok?
                  </span>
                </div>
              </MainContent>

              <footer>
                <div>
                  <span>
                    Tecnologia by <a>Workalove</a>
                  </span>
                  <span>O maior banco de talentos universitários do Brasil</span>
                </div>
              </footer>
            </MailContainer>
        </Content>
      </Box>
    </Overlay>
  );
}

