import React, { useEffect, useRef, useState } from 'react';
import arrowLeft from 'assets/companiesManagement/arrowLeft.png';

import { Form } from '@unform/web';

import {
  BackButton,
  CheckBoxWrapper,
  ContainerForm,
  Divider,
  SaveButton,
} from '../../styled';

import InputFile from '../InputFile';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';

import DatePickerCustom from 'components/Opportunities/DatePicker';

import {
  createDocument,
  getMacroAreas,
  updateDocument,
} from '../../../../../../Services';

import moment from 'moment';
import Checkbox from 'components/UnformFields/CheckBox';

import * as Yup from 'yup';

function DocumentForm({
  companyId,
  universityColor,
  formInitialData,
  selectedDocument,
  closeModal,
  handlePageChange,
  setModalType,
  setFormInitialData,
}) {
  const formRef = useRef(null);
  const [hasFile, setHasFile] = useState(false);
  const [hasUndeterminedTime, setHasUndeterminedTime] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [touchedSave, setTouchedSave] = useState(false);

  const schema = Yup.object().shape({
    has_no_validity_date: Yup.boolean().required(),
    start_validity_date: Yup.date().when('has_no_validity_date', {
      is: false,
      then: Yup.date('Data inválida').required(
        'A data de início é obrigatória.'
      ),
      otherwise: Yup.date().notRequired(),
    }),
    end_validity_date: Yup.date('Error').when('has_no_validity_date', {
      is: false,
      then: Yup.date('Data inválida')
        .min(
          Yup.ref('start_validity_date'),
          'A data de término deve ser após a data de início.'
        )
        .required('A data de término é obrigatória.'),
    }),
  });

  const handleUpdateData = async (data) => {
    const updatedData = {
      start_validity_date: moment(data.start_validity_date).format(
        'YYYY/MM/DD'
      ),
      end_validity_date: moment(data.end_validity_date).format('YYYY/MM/DD'),
      has_no_validity_date: hasUndeterminedTime,
    };
    try {
      await schema.validate(updatedData, {
        abortEarly: false,
      });
      if (hasUndeterminedTime) {
        updatedData.start_validity_date = null;
        updatedData.end_validity_date = null;
      }

      await updateDocument(companyId, selectedDocument, updatedData);
      closeAndUpdate();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  function convertStringToDate(fieldName, dateString) {
    if (hasUndeterminedTime) {
      return dateString;
    }

    const convertedDate = moment(dateString, 'YYYY-MM-DD', true);
    if (convertedDate.isValid()) {
      return convertedDate.toDate();
    }
    formRef.current.setFieldError(fieldName, 'Insira uma data válida');
  }

  const handleCreateDocument = async (data) => {
    const dataToValidate = {
      start_validity_date: convertStringToDate(
        'start_validity_date',
        data.start_validity_date
      ),
      end_validity_date: convertStringToDate(
        'end_validity_date',
        data.end_validity_date
      ),
      has_no_validity_date: hasUndeterminedTime,
    };
    try {
      await schema.validate(dataToValidate, {
        abortEarly: false,
      });
      const formData = createFormData(data);
      await createDocument(formData, companyId);
      closeAndUpdate();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const closeAndUpdate = () => {
    handlePageChange(0);
    closeModal();
  };

  const handleSubmit = async (data) => {
    setTouchedSave(true);
    if (isEdit) {
      handleUpdateData(data);
      return;
    }
    handleCreateDocument(data);
  };

  const createFormData = (data) => {
    const formData = new FormData();

    formData.append('file', data.file);

    if (data.has_no_validity_date) {
      formData.append('has_no_validity_date', true);
      return formData;
    }
    formData.append('has_no_validity_date', false);
    formData.append(
      'start_validity_date',
      moment(data.start_validity_date).format('YYYY/MM/DD')
    );
    formData.append(
      'end_validity_date',
      moment(data.end_validity_date).format('YYYY/MM/DD')
    );
    return formData;
  };

  useEffect(() => {
    handleIsEdit();
  }, []);

  const handleIsEdit = () => {
    if (formInitialData) {
      const keys = Object.keys(formInitialData);
      if (keys.length === 1) {
        setIsEdit(false);
        return;
      }

      setHasFile(true);
      setIsEdit(true);
      if (formInitialData.has_no_validity_date) {
        setHasUndeterminedTime(true);
      }
    }
  };

  const canSubmit = (hasFile || !isEdit) && !touchedSave;

  const returnHeader = () => {
    return isEdit
      ? 'Editar documentos de estágio'
      : 'Adicionar documentos de estágio';
  };

  const checkboxTest = [
    {
      id: 1,
      value: true,
      type: 'checkbox',
    },
  ];

  function handleBackEdit() {
    setModalType('view');
    setIsEdit(false);
  }
  return (
    <>
      <BackButton onClick={handleBackEdit}>
        <img
          style={{ width: '30px', height: '30px' }}
          src={arrowLeft}
          alt="Voltar"
        />
      </BackButton>
      <h3>{returnHeader()}</h3>
      <ContainerForm>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={formInitialData}
        >
          {!isEdit && (
            <>
              <Divider />
              <InputFile name="file" setHasFile={setHasFile} />
            </>
          )}

          {(hasFile || isEdit) && (
            <>
              <h5>Vigência do documento*</h5>
              <p
                style={{
                  fontSize: '13px',
                  fontWeight: '400',
                  margin: '0 0 10px 0',
                }}
              >
                Indique o tempo de vigência do documento ou sinalize que este
                documento não possui um tempo de vigência preestabelecido.
              </p>
              <CheckBoxWrapper>
                <Checkbox
                  name="has_no_validity_date"
                  options={checkboxTest}
                  onChange={(e) => setHasUndeterminedTime(e.target.checked)}
                />
                <span>
                  Este documento não possui tempo de vigência preestabelecido
                </span>
              </CheckBoxWrapper>

              {!hasUndeterminedTime && (
                <div className="date-wrapper">
                  <DatePickerCustom
                    name="start_validity_date"
                    label="Válido de:"
                    placeholder={'Ex.: 15/02/2022'}
                    minDate={null}
                    maxDate={new Date()}
                  />
                  <DatePickerCustom
                    name="end_validity_date"
                    label="Válido até:"
                    placeholder={'Ex.: 15/02/2022'}
                  />
                </div>
              )}
            </>
          )}
          <SaveButton universityColor={universityColor}>
            <button type="submit" disabled={!canSubmit}>
              Salvar
            </button>
          </SaveButton>
        </Form>
      </ContainerForm>
    </>
  );
}

export default DocumentForm;
