import React from 'react';

function LogoActivitiesSectors({ color = '#009291', width = 16, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 3.6569H11.9333C11.7786 2.90451 11.3692 2.22847 10.7742 1.74271C10.1791 1.25696 9.4348 0.991204 8.66667 0.990234L7.33333 0.990234C6.5652 0.991204 5.82088 1.25696 5.22583 1.74271C4.63079 2.22847 4.2214 2.90451 4.06667 3.6569H3.33333C2.4496 3.65796 1.60237 4.00949 0.97748 4.63438C0.352588 5.25927 0.00105857 6.1065 0 6.99023L0 13.6569C0.00105857 14.5406 0.352588 15.3879 0.97748 16.0128C1.60237 16.6376 2.4496 16.9892 3.33333 16.9902H12.6667C13.5504 16.9892 14.3976 16.6376 15.0225 16.0128C15.6474 15.3879 15.9989 14.5406 16 13.6569V6.99023C15.9989 6.1065 15.6474 5.25927 15.0225 4.63438C14.3976 4.00949 13.5504 3.65796 12.6667 3.6569ZM7.33333 2.32357H8.66667C9.07884 2.32528 9.48042 2.45429 9.81647 2.69296C10.1525 2.93163 10.4066 3.2683 10.544 3.6569H5.456C5.59339 3.2683 5.84749 2.93163 6.18353 2.69296C6.51958 2.45429 6.92116 2.32528 7.33333 2.32357ZM3.33333 4.99023H12.6667C13.1971 4.99023 13.7058 5.20095 14.0809 5.57602C14.456 5.95109 14.6667 6.4598 14.6667 6.99023V8.99023H1.33333V6.99023C1.33333 6.4598 1.54405 5.95109 1.91912 5.57602C2.29419 5.20095 2.8029 4.99023 3.33333 4.99023ZM12.6667 15.6569H3.33333C2.8029 15.6569 2.29419 15.4462 1.91912 15.0711C1.54405 14.696 1.33333 14.1873 1.33333 13.6569V10.3236H7.33333V10.9902C7.33333 11.167 7.40357 11.3366 7.5286 11.4616C7.65362 11.5867 7.82319 11.6569 8 11.6569C8.17681 11.6569 8.34638 11.5867 8.47141 11.4616C8.59643 11.3366 8.66667 11.167 8.66667 10.9902V10.3236H14.6667V13.6569C14.6667 14.1873 14.456 14.696 14.0809 15.0711C13.7058 15.4462 13.1971 15.6569 12.6667 15.6569Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoActivitiesSectors;
