import React from 'react';
import { ContainerInputs } from './styled';
import { useFilterContext } from 'components/FilterModal/contexts/FilterContext';
import { MultiRangeSlider } from 'components/MultiRangeSlide';

export function FilterProgressRange({
  name,
  placeholder,
  onPaste,
  onChange,
  ...props
}) {
  const { filterState, updateFilterState } = useFilterContext();

  return (
    <ContainerInputs>
      <MultiRangeSlider
        placeholder={placeholder}
        name={name}
        updateFilterState={updateFilterState}
        filterState={filterState}
        startRange={parseInt(filterState.start_match)}
        endRange={parseInt(filterState.end_match)}
      />
    </ContainerInputs>
  );
}
