import { useVisualizationContext } from '../../../Contexts/VisualizationContext';
import { H4Header } from '../styled';
import ReactHtmlParser from 'react-html-parser';

export default function Programation() {
  const { detailsObject } = useVisualizationContext();

  const { event_schedule } = detailsObject;

  return (
    <>
      <H4Header>Programação do evento</H4Header>

      <p>{ReactHtmlParser(event_schedule)}</p>
    </>
  );
}
