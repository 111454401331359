import styled from 'styled-components';
import SearchableSelect from 'react-select';

export const Main = styled.div`
  width: 100%;
  h3 {
    font-size: 14px;
  }
`;
export const Header = styled.div`
  width: 100%;
  text-align: left;
  color: #3c4858;
  font-weight: 300;
  font-size: 18px;
`;
export const Form = styled.form``;
