import styled from "styled-components";

export const ButtonConfirm = styled.button`
  width: 14em;
  height: 30px;
  color: #009291;
  background-color: #ffff;
  border: 2px solid #009291;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
`;
export const WrapperDespublish = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
export const ButtonCancel = styled.button`
  width: 14em;
  height: 30px;
  color: #ffff;
  background-color: #009291;
  border: 2px solid #009291;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
`;