import React from 'react';

import { Container } from './styled';
import Arrow from './Arrow';

function FullscreenModal({ children, titleArrow }) {
  return (
    <Container>
      <Arrow titleArrow={titleArrow} />
      {children}
    </Container>
  );
}

export default FullscreenModal;
