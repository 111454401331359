// StudentCard.styles.js
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin: 10px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 400px;
`;

export const Header = styled.div`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > h4 {
    font-weight: bold;
    color: #606062;
    margin: 0;
    padding: 0;
  }

  > span {
    font-size: 13px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const Avatar = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #606062;
`;

export const Course = styled.div`
  font-size: 14px;
  color: #666;
`;

export const ID = styled.div`
  font-size: 14px;
  color: #666;
`;

export const BirthDate = styled.div`
  font-size: 14px;
  color: #666;
`;

export const Confirmation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    gap: 10px;
  }
`;

export const Button = styled.button`
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 24px;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: #f5f5f5;
    color: #606062;
    cursor: not-allowed;
  }

  ${({ cancel }) =>
    cancel &&
    `
    background-color: #ffdddd;
    color: #ff5c5c;
  `}

  ${({ confirm }) =>
    confirm &&
    `
    background-color: #ddffdd;
    color: #00c851;
  `}
`;
