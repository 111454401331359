import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { GenerateBlob } from './generateBlob';
import { ContainerPdf, DownloadButton } from './style';

export default function PdfViwer({ file, name, universityColor }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [numOfPages, setNumOfPages] = useState(0);


  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumOfPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const generatePages = () => {
    const array = Array.from({ length: numOfPages }, (_, index) => (
      <Page pageNumber={index + 1} />
    ));
    return array;
  };

  const handleClickDownload = async () => {
    const link = await GenerateBlob(file);
    const downloadLink = document.createElement('a');
    downloadLink.href = link;
    downloadLink.download = `${name}.pdf`;
    downloadLink.click();
  };


  return (
    <ContainerPdf>
      <DownloadButton universityColor={universityColor} onClick={handleClickDownload}>
        FAZER DOWNLOAD
      </DownloadButton>

      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        {generatePages()}
      </Document>
    </ContainerPdf>
  );
}
