import React from 'react';
import styled, { keyframes } from 'styled-components';

const pulse = keyframes`
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
`;

const SkeletonCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SkeletonHeader = styled.div`
  height: 200px;
  background-color: #f0f0f0;
  animation: ${pulse} 1.5s ease-in-out infinite;
`;

const SkeletonBody = styled.div`
  padding: 16px;
`;

const SkeletonLine = styled.div`
  height: 20px;
  border-radius: 4px;
  background-color: #f0f0f0;
  animation: ${pulse} 1.5s ease-in-out infinite;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &:nth-child(2) {
    width: 80%;
  }

  &:nth-child(3) {
    width: 60%;
  }
`;

const CardSkeleton = () => {
  return (
    <SkeletonCard>
      <SkeletonHeader />
      <SkeletonBody>
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
      </SkeletonBody>
    </SkeletonCard>
  );
};

export default CardSkeleton;
