import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';

import { authInternship, authValidate } from 'services/authenticatedInternship';

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const api = axios.create({
  baseURL: URLS.API_URL,
});

api.interceptors.request.use(async (config) => {
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const publicInternshipAPI = axios.create({
  baseURL: URLS.API_INTERNSHIP_URL,
});

publicInternshipAPI.interceptors.request.use(async (config) => {
  config.withCredentials = false;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const authenticatedInternshipAPI = axios.create({
  baseURL: URLS.API_INTERNSHIP_URL,
});

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function authenticateAndValidate(config, retries = 3, delayTime = 1000) {
  try {
    const data = await authInternship();
    const auth = await authValidate({ token: data.token });
    config.withCredentials = false;
    config.headers = {
      authorization: `Bearer ${auth.access}`,
      'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      ...config.headers,
    };
    return config;
  } catch (err) {
    if (retries > 0) {
      await delay(delayTime);
      return authenticateAndValidate(config, retries - 1, delayTime);
    } else {
      throw new Error(`Authentication failed `, { err });
    }
  }
}

authenticatedInternshipAPI.interceptors.request.use((config) =>
  authenticateAndValidate(config)
);

export {
  authenticatedAPI,
  publicInternshipAPI,
  api,
  authenticatedInternshipAPI,
};
