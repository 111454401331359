import styled from 'styled-components';

import arrowLeft from 'assets/SettingsPage/Profile/ArrowLeft.svg';
import arrowRight from 'assets/SettingsPage/Profile/ArrowRight.svg';

export const WrapperPhotos = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: 30px;

  > img {
    width: 150px;
    object-fit: cover;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: block;

  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 500px;
  height: 600px;

  > img {
    object-fit: fill;
    width: 500px;
    height: 600px;
  }
`;

export const Header = styled.div`
background-color: #FFF;
display: flex;
position: relative;
height: 68px;
border-top-left-radius: 4px;
border-top-right-radius: 4px;
display: flex;
align-items: center;
padding: 19px;
}

`;

export const ButtonLeft = styled.div`
  margin-right: 8em;
  cursor: pointer;

  background-image: url(${arrowLeft});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
`;

export const ButtonRight = styled.div`
  margin-left: 8em;

  cursor: pointer;
  background-image: url(${arrowRight});
  background-size: 19px;
  background-repeat: no-repeat;
  background-position: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
`;
