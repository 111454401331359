import React, { cloneElement } from 'react';

import { ButtonStyled } from './styled';
import { Themes } from './Themes';

export default function Button({
  children,
  icon,
  customColor,
  theme = 'light',
  ...rest
}) {
  const injectPropertiesInIcon = () => {
    if (icon) {
      return cloneElement(icon, { size: 18, color: Themes[theme].color });
    }
  };

  const color = customColor || Themes[theme].color;

  return (
    <ButtonStyled
      {...rest}
      theme={theme}
      color={color}
      background={Themes[theme].background}
      border={Themes[theme].border}
      underline={Themes[theme].underline}
    >
      {injectPropertiesInIcon()}
      {children}
    </ButtonStyled>
  );
}
