import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  transition: all 0.5s ease;
  width: 25px;
  max-width: 25px;
  height: 30px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  overflow: hidden;

  &:hover {
    width: 100px;
    max-width: 100px;
  }

  > svg {
    position: absolute;
  }

  > p {
    display: block;
    padding: 0px 30px;
    margin: 0;
    white-space: nowrap;
  }
`;
