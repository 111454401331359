import React, { useState, useEffect, useRef } from 'react';
import {
  ContainerLabelInput,
  ContainerMain,
  MaxSliderHandle,
  MinSliderHandle,
  SliderContainer,
  TextRange,
  WrapperInputs,
  ErrorMessage,
} from './style.js';
import { AiOutlinePercentage } from 'react-icons/ai';
import { FilterType } from 'components/FilterModal/components/Filter.jsx';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  start_match: Yup.number(),
  end_match: Yup.number().min(
    Yup.ref('start_match'),
    'O valor máximo não pode ser menor que o valor mínimo'
  ),
});

export const MultiRangeSlider = ({
  min = 0,
  max = 100,
  minValueBetween = 5,
  updateFilterState,
  name,
  startRange,
  endRange,
}) => {
  const [currentMin, setCurrentMin] = useState(startRange || 0);
  const [inputMin, setInputMin] = useState(startRange || 0);
  const [currentMax, setCurrentMax] = useState(endRange || 100);
  const [inputMax, setInputMax] = useState(endRange || 100);

  const [errorMessages, setErrorMessages] = useState({});
  const [inputValues, setInputValues] = useState({
    start_match: startRange || 0,
    end_match: endRange || 100,
  });

  useEffect(() => {
    const validateInput = async () => {
      try {
        await schema.validate(inputValues, { abortEarly: false });
        setErrorMessages({});
      } catch (error) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrorMessages(validationErrors);
      }
    };

    validateInput();
  }, [inputValues]);

  const sliderRef = useRef(null);
  const minValueRef = useRef(null);
  const maxValueRef = useRef(null);

  useEffect(() => {
    minValueRef.current.style.width = `${(currentMin * 100) / max}%`;
    maxValueRef.current.style.width = `${(currentMax * 100) / max}%`;
  }, [currentMin, currentMax, max]);

  const handleSetMin = (e) => {
    let inputValue = parseInt(e.target.value);

    if (inputValue > currentMax - minValueBetween) {
      inputValue = currentMax - minValueBetween;
    }

    setInputMin(inputValue);

    if (inputValue >= min && inputValue <= currentMax - minValueBetween) {
      setCurrentMin(inputValue);
      minValueRef.current.style.width = `${(inputValue * 100) / max}%`;
    }
    updateFilterState &&
      updateFilterState(inputValue, name[0], FilterType.PROGRESS_RANGE);

    setInputValues({ ...inputValues, start_match: inputValue });
  };

  const handleSetMax = (e) => {
    let inputValue = parseInt(e.target.value);
    setInputMax(inputValue);
    if (inputValue > 100) {
      setInputMax(100);
      inputValue = 100;
    }
    if (inputValue >= currentMin + minValueBetween && inputValue <= max) {
      setCurrentMax(inputValue);
      let widthPercentage = (inputValue * 100) / max;
      if (widthPercentage > 100) {
        widthPercentage = 100;
      }
      maxValueRef.current.style.width = `${(inputValue * 100) / max}%`;
    }
    updateFilterState &&
      updateFilterState(inputValue, name[1], FilterType.PROGRESS_RANGE);

    setInputValues({ ...inputValues, end_match: inputValue });
  };

  const handleMouseMoveMin = (e) => {
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newWidth = e.clientX - sliderRect.left;
    const newWidthInPercent = (newWidth * 100) / sliderRect.width;
    const newCurrentMin = parseInt((max * newWidthInPercent) / 100);

    if (newCurrentMin >= min && newCurrentMin <= currentMax - minValueBetween) {
      minValueRef.current.style.width = `${newWidthInPercent}%`;
      setCurrentMin(newCurrentMin);
      setInputMin(newCurrentMin);
      updateFilterState(newCurrentMin, name[0], FilterType.PROGRESS_RANGE);
    }
  };

  const handleMouseMoveMax = (e) => {
    const sliderRect = sliderRef.current.getBoundingClientRect();
    const newWidth = e.clientX - sliderRect.left;
    const newWidthInPercent = (newWidth * 100) / sliderRect.width;
    const newCurrentMax = Math.abs(parseInt((max * newWidthInPercent) / 100));

    if (newCurrentMax >= currentMin + minValueBetween && newCurrentMax <= max) {
      maxValueRef.current.style.width = `${newWidthInPercent}%`;
      setCurrentMax(newCurrentMax);
      setInputMax(newCurrentMax);
      updateFilterState(newCurrentMax, name[1], FilterType.PROGRESS_RANGE);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', handleMouseMoveMin);
    document.removeEventListener('mousemove', handleMouseMoveMax);
  };

  const handleMouseDownMin = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMoveMin);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDownMax = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMoveMax);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const maxForMin = () => currentMax - minValueBetween;
  const minForMax = () => currentMin + minValueBetween;

  return (
    <ContainerMain>
      <WrapperInputs>
        <ContainerLabelInput>
          <label> Mínimo: </label>
          <TextRange
            type="number"
            onChange={handleSetMin}
            value={inputMin}
            min={min}
            max={maxForMin()}
            name="start_match"
          />
          <AiOutlinePercentage color="#606062" size={26} />
        </ContainerLabelInput>
        <ContainerLabelInput>
          <label> Máximo: </label>
          <TextRange
            type="number"
            onChange={handleSetMax}
            value={inputMax}
            min={minForMax()}
            max={max}
            name="end_match"
          />
          <AiOutlinePercentage color="#606062" size={26} />
        </ContainerLabelInput>
      </WrapperInputs>

      {inputValues && inputValues.end_match < inputValues.start_match && (
        <ErrorMessage>
          {errorMessages && errorMessages.end_match && (
            <p>{errorMessages.end_match}</p>
          )}
        </ErrorMessage>
      )}

      <SliderContainer ref={sliderRef}>
        <MinSliderHandle
          ref={minValueRef}
          data-content={currentMin}
          handleColor="#ececec"
          onMouseDown={handleMouseDownMin}
          onTouchStart={handleMouseDownMin}
        >
          <div></div>
        </MinSliderHandle>

        <MaxSliderHandle
          ref={maxValueRef}
          data-content={currentMax}
          handleColor="#009291"
          onMouseDown={handleMouseDownMax}
          onTouchStart={handleMouseDownMax}
        >
          <div></div>
        </MaxSliderHandle>
      </SliderContainer>
    </ContainerMain>
  );
};
