import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 40px;

  > form {
    width: 100%;
  }
`;

export const ContainerDate = styled.div`
  display: flex;
  margin-bottom: 25px;
  width: 100%;
  gap: 10px;
`;
