import styled, { keyframes } from 'styled-components';
import { OutlinedBox } from '../../styles';

export const Modal = styled.div`
  width: 700px;
  height: ${(props) => props.height || '670px'};
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  background: var(--white, #fff);
  position: relative;
  padding: 2.5rem 4rem;

  > strong {
    color: #606062;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  > p {
    color: #606062;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: justify;
    margin: 0;
  }

  > h4 {
    color: #606062;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  background: #caeaea;
`;

export const AdherenceOutlinedBox = styled(OutlinedBox)`
  align-items: flex-start;
  padding: 1.5rem;
  height: 130px;

  strong {
    font-weight: 700;
    width: fit-content;
    color: #606062;
    font-size: 16px;
    white-space: nowrap;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  gap: 5px;
  width: 100%;

  p {
    color: #606062;
    text-align: right;
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 11px;
    margin: 0;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  background-color: #e5e5e5;
  border-radius: 88px;
`;

const fill = (end) => keyframes`
  0% {
    width: 0
  }
  100% {
    width: ${end}%
  }
`;

export const Progress = styled.div`
  width: ${(props) => props.finalProgress}%;
  height: 12px;
  background-color: #009291;
  border-radius: 88px;
  animation: ${({ finalProgress }) => fill(finalProgress)} 2s ease-in-out;
`;
