import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styled';
import HomeCards from '../UiComponents/HomeCards';
import { VisualizationProvider } from '../Contexts/VisualizationContext';
import EngineVisualization from '../UiComponents/Visualization/EngineVisualization';
import { VisualizationStudentProvider } from '../Contexts/VisualizationStudent';

export default function ControllerVisualization({ history, id, uuid }) {
  const [seeFairAndEvents, setSeeFairAndEvents] = useState(false);

  const handleClickSeeFairAndEvents = useCallback(() => {
    setSeeFairAndEvents(true);
  }, []);

  useEffect(() => {
    if (id && uuid) {
      setSeeFairAndEvents(true);
    }
  }, []);

  const viewFairAndEvents = seeFairAndEvents ? (
    <EngineVisualization id={id} uuid={uuid} />
  ) : (
    <HomeCards
      handleClickSeeFairAndEvents={handleClickSeeFairAndEvents}
      history={history}
    />
  );

  return <Container>{viewFairAndEvents}</Container>;
}
