import React from 'react';


import CoursesAndEventsCompany from '../components/Forms/CoursesAndEventsCompany'
import ContentCompany from '../components/Forms/ContentCompany';

export function coursesAndEventsCompany(modal, setModalContent, setActiveTab) {
  modal(true);
  setModalContent(<CoursesAndEventsCompany setActiveTab={setActiveTab} />);
}

export function contentCompany(modal, setModalContent, setActiveTab) {
  modal(true);
  setModalContent(<ContentCompany setActiveTab={setActiveTab} />);
}
