import React, {
  ChangeEvent,
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useField } from '@unform/core';
import { Fragment } from 'react';
import {
  ButtonDelet,
  ButtonPickFile,
  FileContainer,
  FileWrapper,
  ErrorMessage,
} from '../../Styles/formStyled';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import InformationModal from '../InformationModal';

export default function FileInput({
  name,
  formRef,
  onChange,
  onChangeFile,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [existFile, setExistFile] = useState(defaultValue);
  const { formData } = useContext(OportunitiesContext);
  const [key, setKey] = useState(Date.now());
  const [alertType, setAlertType] = useState(false);
  const [informationModalOpen, setInformationModalOpen] = useState(false);
  const [currentError, setCurrentError] = useState('oversizedFile');

  const errorMessages = {
    oversizedFile: {
      header: 'Ooops! Tamanho da imagem excedido.',
      message: 'O tamanho da imagem não pode superar 2mb.',
    },
    oversizedName: {
      header: 'Ooops! O nome do arquivo excede o limite.',
      message: 'O nome do arquivo não pode superar 100 caracteres.',
    },
  };

  const handlePreview = (e) => {
    const file = e.target.files && e.target.files[0];

    if (onChangeFile) {
      onChangeFile();
    }

    if (!file) {
      setPreview(null);
      setExistFile(null);
      e.target.value = null;
    }

    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      setAlertType(false);
    } else {
      setAlertType(true);
      setPreview(null);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    if (file.size > 2097152) {
      setCurrentError('oversizedFile');
      setInformationModalOpen(true);
      setPreview(null);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    if (file.name.length > 100) {
      setCurrentError('oversizedName');
      setInformationModalOpen(true);
      setPreview(null);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    setExistFile(file);
  };

  useEffect(() => {
    if (defaultValue) {
      setExistFile(defaultValue);
    }
  }, [defaultValue]);

  const handleDelete = useCallback((e) => {
    onChange && onChange(e);
    onChangeFile && onChangeFile();
    delete formData.attraction_image;
    delete formData.image_b64;
    e.preventDefault();
    inputRef.current.value = '';
    setExistFile(null);
    setPreview(null);
    setExistFile(null);
    if (formRef && formRef.current) {
      formRef.current.setFieldError(name, '');
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (formData.attraction_image) {
          return formData.attraction_image;
        }
        if (ref.files[0]) {
          const file = ref.files[0];

          if (file.size > 20971520) {
            return null;
          } else {
            return ref.files[0];
          }
        }
        return null;
      },

      clearValue(ref) {
        ref.value = '';

        setPreview(null);
        setExistFile(null);
      },
      setValue(_, value) {
        setPreview(value);
        setExistFile(null);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Fragment>
      <InformationModal
        isOpen={informationModalOpen}
        type={'error'}
        setModalOpen={setInformationModalOpen}
        informationHeader={errorMessages[currentError].header}
        errorMsg={errorMessages[currentError].message}
      />
      {existFile && (
        <FileWrapper error={!!error}>
          <FileContainer>
            {existFile && (
              <span style={{ wordBreak: 'break-all' }}>{existFile.name}</span>
            )}
          </FileContainer>
          <ButtonDelet onClick={handleDelete} />
        </FileWrapper>
      )}
      {!existFile && (
        <ButtonPickFile onClick={handleClick} type={'button'}>
          {' '}
          Selecionar arquivo
        </ButtonPickFile>
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <input
          style={{ display: 'none' }}
          type="file"
          key={key}
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />
        {alertType && (
          <ErrorMessage paddingBottom="5px">
            Desculpe, somente arquivos do tipo Imagem são permitidos.
          </ErrorMessage>
        )}
      </div>
      <span
        style={{
          color: error ? '#F00' : 'transparent',
          fontSize: '12px',
          marginTop: '-8px',
        }}
      >
        {error && error}
      </span>
    </Fragment>
  );
}
