import React, { useState, Fragment, useEffect, useContext } from 'react';
import {
  WrapperPublished,
  Title,
  ContentTabs,
  SubContainer,
  ButtonAddProject,
  ContainerFilterAndAddProject,
} from '../../Styles/PublishedOpportunities';
import ListOfPublishedLearnings from './InternalTabs/ListOfPublishedLearnings';
import GenericInternalTab from 'components/GenericInternalTab';
import LearningPublishedByTheGroup from './InternalTabs/LearningPublishedByTheGroup';
import {
  cancelRequests,
  cancelRequestsEducationalGroup,
  countAttractionsEducationalGroup,
  getAttractionsFilterOptions,
} from 'services/university/events';

import { formatNumber } from 'utils/formatNumber';
import { FilterContainer, ContainerHeader } from './Filter/styled';
import { FilterEducationalGroup } from './Filter/FilterEducationalGroup';
import { FilterLearning } from './Filter/FilterLearning';
import { ListOfPublishedLearningContext } from '../../Context/ListOfPublishedLearningContext';
import { ListPublishedByTheGroupContext } from '../../Context/ListPublishedByTheGroupContext';

export default function PublishedOpportunitiesEngine({
  setActiveTab,
  educationalGroup,
}) {
  const [activeInternalTab, setActiveInternalTab] = useState(0);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterState, setFilterState] = useState({});
  const [filters, setFilters] = useState({});
  const hasFilters = Object.keys(filterState).length > 0;
  const [filterOptions, setFilterOptions] = useState({});

  const {
    countUniversitiesAttractionsSum,
    setCountUniversitiesAttractionsSum,
  } = useContext(ListOfPublishedLearningContext);

  const { countGroupAttractionsSum, setCountGroupAttractionsSum } = useContext(
    ListPublishedByTheGroupContext
  );

  const getCountAllAttractions = async () => {
    const data = await countAttractionsEducationalGroup();
    const { group_attractions_sum, universities_attractions_sum } = data;
    setCountUniversitiesAttractionsSum(universities_attractions_sum);
    setCountGroupAttractionsSum(group_attractions_sum);
  };

  useEffect(() => {
    getCountAllAttractions();
    setFilterState({});
  }, [activeInternalTab]);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <LearningPublishedByTheGroup
          educationalGroup={educationalGroup}
          setActiveTab={setActiveTab}
          activeInternalTab={activeInternalTab}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <ListOfPublishedLearnings
          educationalGroup={educationalGroup}
          setActiveTab={setActiveTab}
          activeInternalTab={activeInternalTab}
        />
      ),
    },
  ];

  const renderPrincipalTabs = (step) => {
    if (educationalGroup) {
      const returnTabs = Tabs.find((tab) => tab.activeTab === step);
      return returnTabs.component;
    }
  };

  const handleTabChange = (_, value) => {
    if (value !== 0) {
      cancelRequestsEducationalGroup();
    }

    if (value !== 1) {
      cancelRequests();
    }
    setActiveInternalTab(value);
  };

  const renderFilter = () => {
    if (educationalGroup && activeInternalTab === 0) {
      return (
        <FilterEducationalGroup
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          filters={filters}
          setFilters={setFilters}
          setAppliedFilters={setAppliedFilters}
          educationalGroup={educationalGroup}
          activeInternalTab={activeInternalTab}
        />
      );
    }

    return (
      <FilterLearning
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        filterState={filterState}
        setFilterState={setFilterState}
        appliedFilters={appliedFilters}
        filters={filters}
        setFilters={setFilters}
        setAppliedFilters={setAppliedFilters}
        educationalGroup={educationalGroup}
        activeInternalTab={activeInternalTab}
      />
    );
  };

  return (
    <WrapperPublished>
      <SubContainer>
        <Title>Aprendizagens Publicadas</Title>
        <ContainerFilterAndAddProject>
          <ButtonAddProject onClick={() => setActiveTab(0)}>
            Cadastrar aprendizagem
          </ButtonAddProject>

          <FilterContainer hasFilters={hasFilters}>
            {renderFilter()}
          </FilterContainer>
        </ContainerFilterAndAddProject>
      </SubContainer>
      {
        <ContentTabs>
          {educationalGroup ? (
            <Fragment>
              <GenericInternalTab
                zIndex={900}
                activeTab={activeInternalTab}
                handleTabChange={handleTabChange}
                tabs={[
                  `Aprendizagens publicadas pelo grupo  (${formatNumber(
                    countGroupAttractionsSum
                  )})`,
                  `Demais aprendizagens publicadas  (${formatNumber(
                    countUniversitiesAttractionsSum
                  )})`,
                ]}
              />
              {renderPrincipalTabs(activeInternalTab)}
            </Fragment>
          ) : (
            <ListOfPublishedLearnings
              educationalGroup={educationalGroup}
              setActiveTab={setActiveTab}
              activeInternalTab={1}
            />
          )}
        </ContentTabs>
      }
    </WrapperPublished>
  );
}
