import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #e5e5e5;
  position: absolute;
  width: 100%;
  margin-top: -20px;
  z-index: 99;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerForm = styled.div`
  width: 700px;
  min-height: 500px;
  height: auto;
  background-color: #ffff;
  margin: 100px 0px;
  border-radius: 12px;
  padding: 66px 70px;
  position: relative;
  margin: 100px 0px;
`;
