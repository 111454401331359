import { Form } from '@unform/web';
import { useEffect, useState, useRef, useContext } from 'react';
import BlockComponentForm from 'views/StaffPages/Csm/components/EngineSendEmail/components/blockComponentForm';
import backOfficeApi from '../../services/backOfficeApi';
import { schemaValidation } from './schema';
import { ContainerForm, ErrorInformation, ContainerButton } from './style';
import * as Yup from 'yup';
import exclamation from 'assets/backoffice/exclamation.svg';

import ModalInformation from 'components/informationModal';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';

export default function RegisterEmailForm({ setUsingCreateForm }) {
  const formRef = useRef(null);

  const [options, setOptions] = useState([]);
  const [hasError, setHasError] = useState({});
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const handleClickClose = () => {
    setUsingCreateForm(false);
  };

  const getOptions = async () => {
    const response = await backOfficeApi.getOptionsUniversitys();
    setOptions(response);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const BlocksStepOne = [
    {
      blockTitle: 'Cadastrar e-mail',
      subTitle:
        'Preencha os campos abaixo para cadastrar um e-mail vinculado a Instituição de ensino',
      components: [
        {
          type: 'select',
          name: 'university',
          label: 'Instituição de ensino relacionada*',
          props: { options: options },
        },
        {
          type: 'input',
          name: 'email',
          label: 'E-mail*',
        },
        {
          type: 'input',
          name: 'responsible_name',
          label: 'Responsável por informar o e-mail*',
        },
        {
          type: 'input',
          name: 'responsible_email',
          label: 'E-mail do responsável*',
        },
      ],
    },
  ];

  const generateBlocks = () => {
    return BlocksStepOne.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        subTitle={block.subTitle}
        components={block.components}
      />
    ));
  };

  const createEmail = async (data) => {
    try {
      await backOfficeApi.postCreateEmail(data);
      setModalInformationOpen(true);
    } catch (error) {
      setHasError(error.response.data);
    }
  };

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = schemaValidation;
      await schema.validate(data, {
        abortEarly: false,
      });

      createEmail(data);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const replaceKey = (key) => {
    if (key === 'email') {
      return 'e-mail';
    }
    return key;
  };

  const displayError = () => {
    const verifyError = Object.keys(hasError).length > 0;

    if (verifyError) {
      return Object.entries(hasError).map(([key, value]) => (
        <ErrorInformation>
          <img src={exclamation} />
          <p>
            <strong>Observação: </strong>
            {replaceKey(key)}: {value}
          </p>
        </ErrorInformation>
      ));
    }
  };

  const config = {
    bubbleText: 'E-mail cadastrado com sucesso!',
    ParagraphText: `Uma mensagem de confirmação será enviada para o e-mail
  informado. Não esqueça de solicitar ao cliente para entrar no e-mail fornecido e realizar a confirmação.`,
    buttonConfirmText: 'OK, ENTENDI',
    modalHeight: 370,
    style: {
      textAlign: 'justify',
    },
  };

  const handleConfirmModal = () => {
    setUsingCreateForm(false);
  };

  return (
    <ContainerForm>
      <CloseButton onClick={handleClickClose} />
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={config}
        buttonConfirmFunction={handleConfirmModal}
      />
      <Form ref={formRef} onSubmit={handleSubmit}>
        {generateBlocks()}

        <ContainerButton>
          <button>Cadastrar e-mail</button>
        </ContainerButton>
        {displayError()}
      </Form>
    </ContainerForm>
  );
}
