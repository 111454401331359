import * as Yup from 'yup';
import { validateCNPJ } from 'utils/validation';
import { organizationNumberExists } from 'services/company/partners';

const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;

export const companyPreRegisterSchema = Yup.object().shape({
  companyName: Yup.string().required(
    'Por gentileza, informe o nome de registrado da empresa.'
  ),
  organizationNumber: Yup.string()
    .matches(cnpjRegex, { message: 'Informe um CNPJ válido.' })
    .test(
      'organizationNumberValidation',
      'Informe um CNPJ válido.',
      (value) => {
        if (value) {
          return validateCNPJ(value);
        }

        return false;
      }
    )
    .test(
      'organizationExists',
      'CNPJ já existe em sua lista.',
      async (value) => {
        if (value) {
          const isInvited = await organizationNumberExists(value);
          if (isInvited.message) {
            return false;
          }
          return !isInvited.exists || false;
        }
        return false;
      }
    )
    .required('Este campo é obrigatório.'),
  zipCode: Yup.string()
    .max(9, 'O CEP deve conter 8 dígitos.')
    .required('Este campo é obrigatório.'),
  street: Yup.string().required(
    'Por gentileza, informe o nome de registrado da empresa.'
  ),
  city: Yup.string().required(
    'Por gentileza, informe o nome da cidade em que reside.'
  ),
  addressState: Yup.string().required(
    'Por gentileza, informe o nome do estado em que reside.'
  ),
  district: Yup.string().required('Por gentileza, informe o nome do bairro.'),
  addressNumber: Yup.number()
    .typeError('Precisa ser um número inteiro. Ex: 40')
    .required('Por gentileza, informe o número.'),
});
