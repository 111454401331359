import styled from 'styled-components';
import { device } from '../utils/devicesQuery';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 10px;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;

  @media ${device.mobileL} {
    height: 95px;
  }
`;

export const ContainerTypeName = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;

  > img {
    width: 20px;
    @media ${device.mobileL} {
      margin-top: -5px;
    }
  }

  > span {
    font-size: 13px;
    display: inline-block;
    width: 335px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    .nameEvidence {
      display: flex;
      align-items: center;
      margin: 0;
      font-weight: 400;

      @media ${device.mobileL} {
        flex-direction: column;
        align-items: unset;

        > a {
          order: 2;
          max-width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        > span {
          margin-left: 0px;
          background-color: transparent;
          padding: 0;
        }
      }
    }
    .date {
      font-size: 12px;
      display: flex;
      margin: 0;
      gap: 6px;
    }
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  gap: 10px;

  > img {
    width: 20px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }

  > button {
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`;

export const PopUp = styled.div`
> img {
  width: 20px;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
}

  position: relative;

  &:hover {
    &:before {
      display: block;
    }
    &:after {
      display: block;
    }
  }

  &:before {
    content: '${(props) => props.label}';
    min-width: 56px;
    width: auto;
    white-space: nowrap;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    position: absolute;
    top: -38px;
    right: -20px;
    padding: 5px 7px;
    text-align: center;
    display: block;
    color: #ffff;
    border-radius: 4px;
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} transparent;
    border-width: 10px 10px 0;
    bottom: 26px;
    left: 50%;
    margin-left: -10px;
    display: none;
  }
}

> button {
  border: 1px solid ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 500;
  padding: 5px 12px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
}
`;

export const Status = styled.span`
  background-color: ${(props) => props.background};
  font-weight: bold;
  color: ${(props) => props.color};
  padding: 5px;
  margin-left: 10px;
  font-size: 10px;
  height: 15px;
  display: flex;
  align-items: center;
`;
