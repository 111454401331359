import {
  Approved,
  ButtonDetails,
  Circle,
  ContainerButtons,
  ContainerCard,
  Content,
  DataContent,
  Image,
  Like,
  Title,
  ButtonEvidence,
} from './styled';

import { BiLike, BiCheck } from 'react-icons/bi';
import { IoExtensionPuzzleOutline } from 'react-icons/io5';
import { BsCalendar4Week } from 'react-icons/bs';
import moment from 'moment';
import { HiUser } from 'react-icons/hi';
import GraduationCap from 'assets/ExtensionProject/GraduationCap.svg';
import Loading from '../../../Loading';
import { useContext, useState, useEffect } from 'react';

import { getDetailsExtensionProject } from '../../../../../../services';

import { ExtensionProjectsContext } from '../../../../../../Context/ExtensionProjectsContext';
import { ViewStudents } from '../../PublishedProjects/Card/styled';
import Tooltip from 'components/NewTooltip';

export function FinishedProjectsCard({
  ref,
  item,
  setDetails,
  setIsOpen,
  setStatus,
  unpublishCard,
  handleEnrolledStudents,
  finishedProject,
  setItemId,
  loadEvidences,
  setShowEvidences,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadingEvidence, setLoadingEvidence] = useState(false);

  const { setExtensionProjectId, setStudentScore } = useContext(
    ExtensionProjectsContext
  );

  useEffect(() => {
    if (finishedProject === true) {
      setStudentScore(true);
    }
  }, []);

  const handleMoreInfo = async (item) => {
    setExtensionProjectId(item.extension_project.id);
    setLoadingDetails(true);
    const data = await getDetailsExtensionProject(item.id);
    setDetails(data);
    setIsOpen(true);
    setLoadingDetails(false);
  };

  const handleClickEvidence = () => {
    setLoadingEvidence(true);
    loadEvidences(item.extension_project.id, 'limit=5&offset=0');
    setItemId(item.extension_project.id);
    setTimeout(() => {
      setShowEvidences(true);
    }, 300);
    setLoadingEvidence(false);
  };

  const strReduced = (str) => {
    if (str.length > 20) {
      return `${str.substr(0, 20)}...`;
    }
    return str;
  };

  return (
    <ContainerCard unpublishCard={unpublishCard} ref={ref}>
      {item && item.attraction_image.logo ? (
        <Image>
          <img src={item.attraction_image.image} />
        </Image>
      ) : (
        <Image>
          <img
            src={item.attraction_image.image}
            style={{
              width: '340px',
              height: '210px',
              borderTopLeftRadius: '11.5652px',
              borderTopRightRadius: '11.5652px',
              position: 'absolute',
              top: 0,
            }}
          />
        </Image>
      )}

      {unpublishCard === false && (
        <Approved>
          <BiCheck size={24} color="#13B266" />
          <p>{item.extension_project.students_registered}</p>
        </Approved>
      )}

      <Like unpublishCard={unpublishCard}>
        <BiLike size={17} />
        <p>{item.like_quantity}</p>
      </Like>

      <DataContent>
        <Title title={'teste'}>{item.name}</Title>

        <Content>
          <div className="wrapper-content">
            <IoExtensionPuzzleOutline size={20} color="#009291" />

            <p style={{ paddingLeft: '7px' }}>{item.extension_project.type}</p>
          </div>
          {unpublishCard === false && (
            <div className="wrapper-content">
              <BsCalendar4Week size={17} color="#009291" />
              <p>
                De {''}
                {moment(item.start_at).format('DD/MM/YYYY')} {''}a {''}
                {moment(item.end_at).format('DD/MM/YYYY')} {''}
              </p>
            </div>
          )}

          <div className="wrapper-content">
            <Circle>
              <HiUser size={16} color="#ffffff" />
            </Circle>
            {item.extension_project.creator &&
            item.extension_project.creator.length <= 20 ? (
              <p>{item.extension_project.creator}</p>
            ) : (
              <Tooltip text={item.extension_project.creator}>
                <p>{strReduced(item.extension_project.creator)}</p>
              </Tooltip>
            )}
          </div>

          {unpublishCard === false && (
            <div className="wrapper-content">
              <img src={GraduationCap} />
              <p>
                {(item.extension_project.students_registered > 1 ||
                  item.extension_project.students_registered === 0) &&
                  `${item.extension_project.students_registered} estudantes inscritos`}
                {item.extension_project.students_registered === 1 &&
                  `${item.extension_project.students_registered} estudante inscrito`}
                {item.extension_project.students_registered > 0 && (
                  <ViewStudents onClick={() => handleEnrolledStudents(item)}>
                    {' '}
                    ver{' '}
                  </ViewStudents>
                )}
                {item.extension_project.students_registered === 0 &&
                  item.extension_project.has_register === true && (
                    <ViewStudents onClick={() => handleEnrolledStudents(item)}>
                      {' '}
                      ver{' '}
                    </ViewStudents>
                  )}
              </p>
            </div>
          )}
        </Content>
        <ContainerButtons>
          <ButtonDetails
            unpublishCard={unpublishCard}
            onClick={() => handleMoreInfo(item)}
          >
            {loadingDetails ? (
              <Loading color={'#fff'} />
            ) : (
              'Visualizar detalhes'
            )}
          </ButtonDetails>
          <ButtonEvidence onClick={handleClickEvidence}>
            {loadingEvidence ? (
              <Loading color={'#009291'} />
            ) : (
              'Visualizar evidências'
            )}
          </ButtonEvidence>
        </ContainerButtons>
      </DataContent>
    </ContainerCard>
  );
}
