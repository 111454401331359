import GenericMessage from 'components/GenericMessage';
import {
  Description,
  GreenContainer,
  SubTitle,
} from '../../Styles/StyleSections';
import {
  ItemSkeleton,
  SkeletonDescription,
  SkeletonTitle,
} from '../../Styles/StyleSkeleton';
import {
  returnAdapter,
  verifyValuesChartAreZero,
} from '../../Utils/formatDataGraphics';

export function DataSection({ data, loading }) {
  const dataAdapter = returnAdapter(data);

  return (
    <>
      {loading === null && (
        <GreenContainer loading={loading === null}>
          <SkeletonTitle mgLeft="31px" subtitle />
          <SkeletonDescription mgLeft="31px" />
          <div className="wrapper">
            {[1, 2, 3, 4].map(() => (
              <ItemSkeleton />
            ))}
          </div>
        </GreenContainer>
      )}
      {verifyValuesChartAreZero(dataAdapter) && (
        <GreenContainer loading={true}>
          <SubTitle pdLeft="31px" color="#606062">
            Mudança de status profissional*
          </SubTitle>
          <Description pdLeft="31px" color="#606062">
            Aqui está a nossa principal métrica. Destacamos abaixo o número de
            estudantes que mudaram o seu status profissional depois do primeiro
            acesso no sistema.
          </Description>
          <GenericMessage
            title="Dados Indisponíveis"
            subtitle="No momento, esses dados não estão cadastrados e por isso estão indisponíveis."
          />
        </GreenContainer>
      )}
      {!verifyValuesChartAreZero(dataAdapter) && data?.length > 0 && (
        <GreenContainer>
          <SubTitle pdLeft="31px" color="#fff">
            Mudança de status profissional*
          </SubTitle>
          <Description pdLeft="31px" color="#fff">
            Aqui está a nossa principal métrica. Destacamos abaixo o número de
            estudantes que mudaram o seu status profissional depois do primeiro
            acesso no sistema.
          </Description>
          <div className="wrapper">
            {data?.map(({ label, value }) => (
              <div className="item" key={value}>
                <span>{label}</span>
                <strong>{value}</strong>
              </div>
            ))}
          </div>
        </GreenContainer>
      )}
    </>
  );
}
