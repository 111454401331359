// ##############################
// // // Sidebar styles
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  dnaGreen,
  dnaColor,
} from 'assets/jss/material-dashboard-pro-react';

const sidebarStyle = (theme) => ({
  drawerPaperRTL: {
    [theme.breakpoints.up('md')]: {
      left: 'auto !important',
      right: '0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      left: '0  !important',
      right: 'auto !important',
    },
  },
  drawerPaper: {
    border: 'none',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: '1300',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    backgroundColor: '#fff',
    ...boxShadow,
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed',
      height: '100vh',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      ...boxShadow,
      position: 'fixed',
      display: 'block',
      top: '0',
      height: 'none',
      right: '0',
      left: 'auto',
      zIndex: '1032',
      visibility: 'visible',
      overflowY: 'visible',
      borderTop: 'none',
      textAlign: 'left',
      paddingRight: '0px',
      paddingLeft: '0',
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
    '&:before,&:after': {
      position: 'absolute',
      zIndex: '3',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      top: '0',
    },
  },
  blackBackground: {
    color: '#FFFFFF',
    overflow: 'hidden',
    '&:after': {
      background: '#000',
      opacity: '.8',
    },
  },
  blueBackground: {
    color: '#FFFFFF',
    '&:after': {
      background: 'black',
      opacity: '.93',
    },
  },
  whiteBackground: {
    color: '#3C4858',
    '&:after': {
      background: '#F9F9F9',
      opacity: '.93',
    },
  },
  whiteAfter: {
    '&:after': {
      backgroundColor: 'hsla(0,0%,71%,.3) !important',
    },
  },
  drawerPaperMini: {
    width: `${drawerMiniWidth}px!important`,
  },
  logo: {
    padding: '15px 0 10px',
    margin: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },

  logoDefault: {
    padding: '10px 0 38px',
    margin: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    zIndex: '4',
    background: '#009291',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },

  logoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    // float: "left",
    textAlign: 'center',
    display: 'inline-block',
    margin: 'auto',
    color: 'inherit',
    width: 'auto',
    '& img': {
      display: 'block',
      margin: 'auto',
      maxWidth: '200px',
      width: '100%',
    },
  },
  logoMiniRTL: {
    float: 'right',
    marginRight: '30px',
    marginLeft: '26px',
  },
  logoNormal: {
    ...defaultFont,
    transition: 'all 300ms linear',
    display: 'block',
    opacity: '1',
    transform: 'translate3d(0px, 0, 0)',
    textTransform: 'uppercase',
    padding: '5px 0px',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    fontWeight: '400',
    lineHeight: '30px',
    marginTop: '5px',
    overflow: 'hidden',
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  logoNormalRTL: {
    textAlign: 'right',
  },
  logoNormalSidebarMini: {
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)',
  },
  logoNormalSidebarMiniRTL: {
    transform: 'translate3d(25px, 0, 0)',
  },
  img: {
    width: '55px',
    verticalAlign: 'middle',
    border: '0',
  },
  imgLogotypo: {
    width: '160px',
    verticalAlign: 'middle',
    border: '0',
  },
  background: {
    position: 'absolute',
    zIndex: '1',
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
  },
  list: {
    marginTop: '15px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "',
    },
    '&:after': {
      clear: 'both',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '7px',
      overflowY: 'auto',
      overflowX: 'hidden',
      height: 'calc(100% - 75px)',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#80808069',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'white',
        borderRadius: '4px',
      },
    },
  },
  item: {
    color: 'inherit',
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 7px)',
    },
  },
  userItem: {
    '&:last-child': {
      paddingBottom: '0px',
    },
  },
  itemLink: {
    paddingLeft: '10px',
    paddingRight: '10px',
    transition: 'all 300ms linear',
    margin: '10px 15px 0',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px 15px',
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  itemIcon: {
    color: 'inherit',
    width: '28px',
    height: '28px',
    float: 'left',
    position: 'inherit',
    top: '3px',
    marginRight: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  itemIconRTL: {
    float: 'right',
    marginLeft: '15px',
    marginRight: '1px',
  },
  itemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    lineHeight: '30px',
    fontSize: '14px',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap',
  },
  userItemText: {
    lineHeight: '22px',
  },
  itemTextRTL: {
    marginRight: '45px',
    textAlign: 'right',
  },
  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  itemTextMiniRTL: {
    transform: 'translate3d(25px, 0, 0) !important',
  },
  collapseList: {
    marginTop: '0',
    height: 'auto',
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: 'rgba(200, 200, 200, 0.2)',
    boxShadow: 'none',
  },
  collapseItemLink: {
    transition: 'all 300ms linear',
    margin: '0 15px',
    borderRadius: '3px',
    position: 'relative',
    display: 'block',
    padding: '10px',
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(200, 200, 200, 0.2)',
      boxShadow: 'none',
    },
    '&,&:hover,&:focus': {
      color: 'inherit',
    },
  },
  collapseItemMini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center',
    letterSpacing: '1px',
    position: 'relative',
    float: 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemMiniRTL: {
    float: 'right',
    marginLeft: '30px',
    marginRight: '1px',
  },
  collapseItemText: {
    color: 'inherit',
    ...defaultFont,
    margin: '0',
    position: 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
  },
  collapseItemTextRTL: {
    textAlign: 'right',
  },
  collapseItemTextMiniRTL: {
    transform: 'translate3d(25px, 0, 0) !important',
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '18px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
  },
  userCaret: {
    marginTop: '10px',
  },
  caretRTL: {
    left: '11px',
    right: 'auto',
  },
  caretActive: {
    transform: 'rotate(180deg)',
  },

  purple: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: primaryColor,
      ...primaryBoxShadow,
    },
  },
  blue: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: infoColor,
      boxShadow:
        '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    },
  },
  green: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: successColor,
      backgroundColor: '#000',
      boxShadow:
        '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)',
    },
  },
  orange: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: warningColor,
      boxShadow:
        '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)',
    },
  },
  red: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: dangerColor,
      boxShadow:
        '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)',
    },
  },
  white: {
    '&,&:hover,&:focus': {
      color: '#3C4858',
      backgroundColor: '#EEEEEE',
      boxShadow: '0 4px 20px 0 #6d6c6c, 0 7px 10px -5px #6d6c6c',
    },
  },
  rose: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: roseColor,
      boxShadow:
        '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(233,30,99,.4)',
    },
  },

  dnaColor: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: dnaGreen,
      boxShadow:
        '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)',
    },
  },

  yellowIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#F9A400',
      boxShadow: '-1px 4px 5px 0px #979797',
    },
  },

  redIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#E23C2E',
    },
  },

  greenIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#259444',
    },
  },

  orangeIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#fb7048',
    },
  },

  darkBlueIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#0B4295',
    },
  },

  blueIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#0641D8',
    },
  },

  lightBlueIES: {
    '&,&:hover,&:focus': {
      color: '#ffff',
      backgroundColor: '#2D7BED',
    },
  },

  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 75px)',
    width: '260px',
    zIndex: '4',
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    paddingBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      height: 'calc(100vh - 84px)',
      paddingBottom: '50px',
      margin: '10px 15px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '0.4em',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#80808069',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'white',
        borderRadius: '4px',
      },
    },
  },
  sidebarWrapperWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  user: {
    paddingBottom: '20px',
    margin: '20px auto 0',
    position: 'relative',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      right: '15px',
      height: '1px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)',
    },
  },
  photo: {
    transition: 'all 300ms linear',
    width: '34px',
    height: '34px',
    overflow: 'hidden',
    float: 'left',
    zIndex: '5',
    marginRight: '11px',
    borderRadius: '50%',
    marginLeft: '23px',
    ...boxShadow,
  },
  photoRTL: {
    float: 'right',
    marginLeft: '12px',
    marginRight: '24px',
  },
  avatarImg: {
    width: '100%',
    verticalAlign: 'middle',
    border: '0',
  },
  userCollapseButton: {
    margin: '0',
    padding: '6px 15px',
    '&:hover': {
      background: 'none',
    },
  },
  userCollapseLinks: {
    marginTop: '-4px',
    '&:hover,&:focus': {
      color: '#FFFFFF',
    },
  },
  suggestionLink: {
    marginTop: '28px',
    textAlign: 'center',
    fontSize: '12px',
  },
  closeIcon: {
    position: 'absolute',
    right: '10px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    userSelect: 'none',
    margin: '25px 0 0',
  },
  tagContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    letterSpacing: 2,
  },
  freemium: {
    backgroundColor: dnaColor,
    padding: '0 4px',
    borderRadius: 10,
    fontSize: 10,
    letterSpacing: 1,
    alignSelf: 'flex-end',
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      marginRight: 0,
    },
  },

  subMenuItem: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      width: '22px !important',
      height: '6px !important',
      marginLeft: '6px',
      marginRight: '0px',
      top: 0,
    },
  },

  subMenudnaColor: {
    '&,&:hover,&:focus': {
      color: dnaGreen,
      backgroundColor: '#caeaea',
      display: 'flex',
      alignItems: 'center',
    },
    '& > svg': {
      width: '22px !important',
      height: '6px !important',
      marginLeft: '6px',
      marginRight: '0px',
      top: 0,
    },
  },
});

export default sidebarStyle;
