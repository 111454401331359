import React, { useEffect, useState } from 'react';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';
import * as Yup from 'yup';
import FilterModal from 'components/FilterModal';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, FilterContainer, SpanError } from './styles';
import { filterCampaignsOptions, listCampaigns } from '../../services';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export function FilterHistoric({
  id,
  fetchData,
  searchQuery,
  ordering,
  searchTerm,
  limit,
}) {
  const [errorDate, setErrorDate] = useState(false);
  const [filterState, setFilterState] = useState({});
  const { appliedFilters, setAppliedFilters } = useCsm();
  const [filterOptions, setFilterOptions] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentCampaignFilter, setCurrentCampaignFilter] = useState(null);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        start_date: Yup.date(),
        end_date: Yup.date().min(
          Yup.ref('start_date'),
          `A data final precisa ser maior do que a data inicial.`
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setLoading(true);
        const response = await filterCampaignsOptions(id);
        setFilterOptions(response.campaigns);
        setLoading(false);
      } catch (error) {
        setFilterOptions({});
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, [id]);

  async function onApplyFilters(filters) {
    const existFilters = filters && Object.keys(filters).length > 0;
    setCurrentCampaignFilter(filterState);
    let queryString = queryParamsFromFilterObject(filters);

    if (existFilters) {
      const isValid = await validateData(filters);
      if (isValid === false) {
        return false;
      }
      setAppliedFilters(queryString);
      let query = searchQuery(ordering, searchTerm, queryString, limit);
      await fetchData(query);
    } else {
      onClearFilters();
      return;
    }
  }

  async function onClearFilters() {
    setFilterOptions({});
    setErrorDate(false);
    await fetchData('');
  }

  return (
    <FilterContainer>
      <FilterModal
        filterState={filterState}
        setFilterState={setFilterState}
        onApply={onApplyFilters}
        onClean={onClearFilters}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        loading={loading}
        disabled={loading}
      >
        <FilterGrid>
          <Filter
            name={['start_date', 'end_date']}
            label={'Período de envio'}
            type={FilterType.DATE_RANGE}
            settings={{
              placeholder: ['Data inicial', 'Data final'],
            }}
          />
          {errorDate && (
            <SpanError>
              A data final precisa ser maior do que a data inicial.
            </SpanError>
          )}
        </FilterGrid>
      </FilterModal>
    </FilterContainer>
  );
}
