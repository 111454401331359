import React, {useContext} from 'react';
import { Wrapper, ContainerTypeName, ContainerActions, PopUp } from './style';
import LINK from 'assets/ModalUploadEvidences/iconsFormatType/Link.svg';
import Trash from '../../assets/Trash.svg';
import edit from 'assets/ExtensionProject/edit.svg';
import moment from 'moment';
import EditIcon from 'assets/ComponentIcons/EditIcon';
import BaseLayoutContext from 'contexts/base-layout';
import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';
import { LinkIcon } from 'assets/ComponentIcons/evidencesIcon';

export default function ListLinks({
  handleDeleteLink,
  item,
  handleClickEdit,
  canUseActions,
  setModalInformationConfig,
  setModalInformationOpen,
}) {
  const { name, link } = item;

  const {universityColor} = useContext(BaseLayoutContext)

  const handleConfirmDelete = () => {
    handleDeleteLink(item);
    setModalInformationOpen(false);
  };

  const configDelete = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza que deseja excluir a evidência "${name}"?`,
    buttonConfirmText: 'SIM, EXCLUIR EVIDÊNCIA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px'
    },
    buttonConfirmFunction: handleConfirmDelete,
  };

  const handleClickDelete = () => {
    setModalInformationConfig(configDelete);
    setModalInformationOpen(true);
  };

  return (
    <Wrapper>
      <ContainerTypeName>
        <LinkIcon color={universityColor} size={20} />
        <div>
          <a href={link} target="_blank">
            {name}
          </a>
          <p className="date">
            Enviado dia
            <span>
              {moment(item.created_at).format('DD/MM/YYYY')} às{' '}
              {moment(item.created_at).format('H:mm ')}
            </span>
          </p>
        </div>
      </ContainerTypeName>
      {canUseActions && (
        <ContainerActions>
          <button onClick={handleClickDelete}>
            <PopUp label="Excluir" universityColor={universityColor || "#009291"}>
              <DefaultTrash color={universityColor || "#009291"} size={20} />
            </PopUp>
          </button>
          <button onClick={() => handleClickEdit(item)}>
            <PopUp label="Editar" universityColor={universityColor || "#009291"}>
              <EditIcon color={universityColor || "#009291"} size={19}/>
            </PopUp>
          </button>
        </ContainerActions>
      )}
    </Wrapper>
  );
}
