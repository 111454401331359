import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonX,
  WrapperStep,
} from '../style';
import { LinkText } from './style';
import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

import { Form } from '@unform/web';
import { handleFieldMask } from 'utils/validation';
import { maskZipCode } from 'utils/inputMasks';
import { zipCodeRequest } from 'services/zipCode';

import Input from './Input';
import { Error } from './Input/style';
import { updateProfile } from 'services/student/profile';

export default function ModalCep({
  config,
  closeModalFunction,
  modalOpenState,
  buttonConfirmFunction,
  buttonCancelFunction,
  studentData,
  profileData,
  universityColor,
  setOpenModalCep,
  setOpenModalEmployedStatus,
  setOpenModalUnemployedStatus,
  setOpenModalProfessionalStatus,
  setHasFirstStep,
  ...props
}) {
  const formRef = useRef(null);
  const [cepError, setCepError] = useState(false);

  async function handleZipCodeChange(e) {
    const value = e.target.value;
    if (value.length === 9) {
      const response = await zipCodeRequest(value);
      if (response.erro === true) {
        setCepError(true);
        return;
      } else {
        setCepError(false);
      }
    }
  }

  async function onSubmit(data) {
    const cepValue = data.zip_code;
    if (cepValue.length !== 9) {
      setCepError(true);
      return;
    }
    const response = await zipCodeRequest(cepValue);
    if (response.erro === true) {
      setCepError(true);
      return;
    } else {
      setCepError(false);
    }

    const newData = {
      ...data,
      ...(response.logradouro ? { address: response.logradouro } : {}),
      ...(response.bairro ? { district: response.bairro } : {}),
      ...(response.uf ? { state: response.uf } : {}),
      ...(response.localidade ? { city: response.localidade } : {}),
    };
    updateProfile(newData);
    if (!cepError) {
      setOpenModalCep(false);
    }
    if (props.metadata.need_update_professional_satisfaction) {
      setOpenModalProfessionalStatus(true);
      setHasFirstStep(true);
      return;
    }
    if (
      !props.metadata.need_update_professional_satisfaction &&
      props.professional_status.is_employed
    ) {
      setOpenModalEmployedStatus(true);
      setHasFirstStep(true);
      return;
    }
    if (
      !props.metadata.need_update_professional_satisfaction &&
      !props.professional_status.is_employed
    ) {
      setOpenModalProfessionalStatus(true);
      setHasFirstStep(true);
      return;
    }
  }

  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal>
            <WrapperStep>
              <ButtonX color={universityColor} />
              <ButtonX />
            </WrapperStep>

            <WrapperTitle alignIcon={config?.style?.alignIcon}>
              <IconPersonStyled
                universityColor={universityColor}
                src={IconPerson}
              />
              <BubbleDialog universityColor={universityColor}>
                <H4Styled>Olá, {profileData.name}!</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled>
              <p>
                Preciso enviar para você as oportunidades de trabalho mais
                próximas do local da sua residência.
              </p>
            </ParagraphStyled>
            <Form ref={formRef} style={{ width: '100%' }} onSubmit={onSubmit}>
              <div style={{}}>
                <Input
                  name={'zip_code'}
                  label={'Para isso, insira aqui o seu CEP:'}
                  maxlength={9}
                  onKeyUp={async (event) => {
                    handleFieldMask(event, maskZipCode);
                    await handleZipCodeChange(event);
                  }}
                  cepError={cepError}
                />
              </div>
              {cepError && (
                <Error>
                  O CEP informado está incorreto. Atualize-o para seguir em
                  frente.
                </Error>
              )}
              <LinkText
                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                target="_blank"
              >
                Não sei meu CEP
              </LinkText>
              <Button
                type="submit"
                universityColor={universityColor}
                disabled={cepError}
              >
                AVANÇAR
              </Button>
            </Form>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config, cepError]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}
