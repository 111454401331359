import React, { useContext } from 'react';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';
import StepThreeJobOffer from './StepThree';
import StepOneJobOffer from './StepOne';
import StepTwoJobOffer from './StepTwo';

import StepIndicator from '../../StepIndicator';
import {
  WrapperSteps,
  WrapperStepIndicator,
  HeaderStep,
  TitleRegister,
} from './style';
import ExternalJobOffer from '../../ExternalJobOffer';

import bag from '../../../../../assets/JobOffer/bag.svg';
import { formSubTitle } from './useCases/FormSubTitles';
import { useScrollToDiv } from '../../../hooks/scrollToCurrentDiv';

export default function EngineSteps() {
  const { jobOfferStep, externalJobOffer } = useContext(JobOfferContext);

  const steps = [
    <StepOneJobOffer />,
    <StepTwoJobOffer />,
    <StepThreeJobOffer />,
  ];

  const [formDiv] = useScrollToDiv(jobOfferStep);

  return (
    <WrapperSteps>
      {!externalJobOffer ? (
        <div style={{ padding: '0px 30px' }}>
          <WrapperStepIndicator>
            <StepIndicator currentStep={jobOfferStep} StepsQuantity={3} />
          </WrapperStepIndicator>

          <HeaderStep ref={formDiv}>
            <img src={bag} />
            <TitleRegister>Cadastrar Vaga</TitleRegister>
          </HeaderStep>
          <p>{formSubTitle[jobOfferStep]}</p>

          {steps[jobOfferStep]}
        </div>
      ) : (
        <ExternalJobOffer />
      )}
    </WrapperSteps>
  );
}
