import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  ContainerModalInterview,
  BackgroundModals,
  HeaderModalInterview,
  ButtonClose,
  ParagraphInterview,
  BodyModalInterview,
  ContainerButtons,
  Button,
  ParagraphText,
  ErrorMessage,
} from '../../styled';
import { newInterviewAdapter } from 'adapters/job/jobInterviewAdapter';
import { updateInterview } from 'services/student/jobOffers';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { JoditTextArea } from 'components/UnformFields/JoditTextArea';
import * as Yup from 'yup';

export default function ModalStudentCancelInterview(props) {
  const {
    interview,
    setApplication,
    interviewId,
    universityColor,
    handleCloseModal,
    applicationId,
    fetchListApplicationJobOffers,
  } = props;
  const [error, setError] = useState('');
  const interviewCanceledRef = useRef('');

  const schema = Yup.object().shape({
    interviewee_message: Yup.string().required('Campo obrigatório'),
  });

  function changeInterviewUpdate(response) {
    setApplication({ ...applicationId, interviews: [response] });
  }

  async function cancelAndHandleInterview(data) {
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      const cancelObj = () => ({
        status: 'canceled',
        interviewee_message: data.interviewee_message,
        id: interviewId,
        interviewer_message: '',
      });

      const interViewAdapter = newInterviewAdapter(data);
      interViewAdapter.status = 'canceled';
      delete interViewAdapter.description;
      interViewAdapter.id = interview.id;

      const response = await updateInterview(
        applicationId,
        cancelObj(interview)
      );

      if (!response.message) {
        response.applicationId = applicationId;
        changeInterviewUpdate(response);
        customSnackbar('Entrevista cancelada com sucesso!', 'confirmation');
      } else {
        customSnackbar(
          'Não foi possível cancelar a entrevista. Tente novamente mais tarde!',
          'error'
        );
      }
      fetchListApplicationJobOffers && (await fetchListApplicationJobOffers());
      handleCloseModal();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        interviewCanceledRef.current.setErrors(validationErrors);
      }
      customSnackbar(
        'Não foi possível cancelar a entrevista. Tente novamente mais tarde!',
        'error'
      );
      setError(validationErrors.interviewee_message);
    }
  }

  return (
    <BackgroundModals>
      <ContainerModalInterview isInterview>
        <HeaderModalInterview universityColor={universityColor}>
          <ParagraphInterview>Cancelar entrevista</ParagraphInterview>
          <ButtonClose onClick={handleCloseModal}>X</ButtonClose>
        </HeaderModalInterview>
        <BodyModalInterview>
          <Form ref={interviewCanceledRef} onSubmit={cancelAndHandleInterview}>
            <ParagraphText>
              Explique abaixo o motivo do cancelamento da entrevista*
            </ParagraphText>
            <JoditTextArea
              name="interviewee_message"
              placeholder="Motivo do cancelamento"
            />
            {error ? <ErrorMessage> {error} </ErrorMessage> : null}
            <ContainerButtons>
              <Button universityColor={universityColor} type="submit">
                Confirmar
              </Button>
            </ContainerButtons>
          </Form>
        </BodyModalInterview>
      </ContainerModalInterview>
    </BackgroundModals>
  );
}
