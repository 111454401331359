import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { GraphicCapsule } from '../../Styles/StyleGraphics';
import useMediaQuery from 'hooks/useMediaQuery';
import { returnAdapter } from '../../Utils/formatDataGraphics';
import { formatNumber } from 'utils/formatNumber';

export function CustomPieChart({ graphicalData }) {
  const adapterData = returnAdapter(graphicalData);
  const sortedData =
    adapterData?.result?.sort((a, b) => b.percentage - a.percentage) || [];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, payload } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius - 10) * cos;
    const sy = cy + (outerRadius - 3) * sin;
    const mx = cx + (outerRadius - 10) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={'#147C7C'}
          fill="none"
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 10}
          y={ey + 4}
          textAnchor={textAnchor}
          fill="#333"
          style={{ fontWeight: 500, color: '#606062' }}
        >{`${formatNumber(payload.value)}`}</text>
      </g>
    );
  };

  const colorCode = [
    '#88EFEE',
    '#1C6D6D',
    '#00F5F3',
    '#6ABABA',
    '#3DA2A1',
    '#D5FFFA',
  ];

  return (
    <GraphicCapsule heightGraphic={340} marginRight={20}>
      <ResponsiveContainer width="120%" height="120%">
        <PieChart>
          <Pie
            data={sortedData}
            labelLine={false}
            label={renderActiveShape}
            outerRadius={120}
            dataKey="percentage"
            stroke="none"
            margin={{ top: 5, right: 40, left: 0, bottom: 5 }}
          >
            {sortedData?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colorCode[index % colorCode.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </GraphicCapsule>
  );
}
