export function IconTime() {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 11.7654C2.46721 11.7654 0 9.29817 0 6.26538C0 3.23259 2.46721 0.765381 5.5 0.765381C8.53279 0.765381 11 3.23259 11 6.26538C11 9.29817 8.53279 11.7654 5.5 11.7654ZM5.5 1.68205C2.97275 1.68205 0.916667 3.73813 0.916667 6.26538C0.916667 8.79263 2.97275 10.8487 5.5 10.8487C8.02725 10.8487 10.0833 8.79263 10.0833 6.26538C10.0833 3.73813 8.02725 1.68205 5.5 1.68205ZM7.79167 6.26538C7.79167 6.01192 7.58679 5.80705 7.33333 5.80705H5.95833V3.51538C5.95833 3.26192 5.753 3.05705 5.5 3.05705C5.247 3.05705 5.04167 3.26192 5.04167 3.51538V6.26538C5.04167 6.51884 5.247 6.72371 5.5 6.72371H7.33333C7.58679 6.72371 7.79167 6.51884 7.79167 6.26538Z"
        fill="#8B8B8B"
      />
    </svg>
  );
}
