import axios from 'axios';
import getCookieValue from '../../../../utils/getCookieValue';
import URLS from '../../../../constants/urls';
const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const OportunitiesMacroAreas = () => {
  const response = authenticatedAPI
    .get('/api/internal/companies/attractions/macroareas')
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCacheOptions = () => {
  const response = authenticatedAPI
    .get('/api/internal/companies/attractions/macroareas-courses')
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCourses = (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/macroareas/course?${params}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getPeriod = (params) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/companies/attractions/macroareas/course/period?${params}`
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getCompetencies = () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/competences`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getAdditionalInfosAttraction = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/${id}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getInterests = () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/interests`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getDetails = (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/attractions/detail/${id}`)
    .then((res) => res)
    .catch((error) => error);
  return response;
};

export {
  OportunitiesMacroAreas,
  getCourses,
  getPeriod,
  getCompetencies,
  getInterests,
  getCacheOptions,
  getAdditionalInfosAttraction,
  getDetails,
};
