import styled from 'styled-components';

export const BackgroundModals = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: #00000030;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  @media (max-width: 690px) {
    align-items: flex-end !important;
  }
`;

export const ContainerModalFeedback = styled.div`
  overflow: hidden;
  background-color: #fff;
  margin-top: 3%;
  display: flex;
  align-items: start;
  flex-direction: column;
  z-index: 2;
  border-radius: 10px;
  max-width: 870px;

  height: auto;
  width: 100%;
  max-width: 670px;
`;

export const BodyModalFeedback = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 40px;
`;

export const HeaderModalFeedback = styled.div`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#fff'};

  width: 100%;
  height: 90px;
  padding: 0 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonCloseFeedback = styled.button`
  background-color: #efeeec;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  color: #606062;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 35px;
  height: 35px;
`;

export const ContainerContent = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-top: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  width: 100%;
  height: auto;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: ${(props) => (props.isContentCenter ? 'center' : 'flex-start')};
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const CustomTextParagraph = styled.p`
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize}` : props.isCode ? '0.9em' : '1.125em'};
  font-weight: ${(props) =>
    props.fontWeight ? `${props.fontWeight}` : props.isBold ? 'bold' : '400'};
  color: ${(props) => (props.color ? `${props.color}` : '#606062')};
  margin: 0;
  font-style: ${(props) => (props.isItalicStyle ? 'italic' : 'normal')};
  text-align: ${(props) => (props.textAlign ? `${props.textAlign}` : 'start')};
`;

export const ParagraphFeedback = styled.p`
  font-size: ${(props) => (props.isFeedbackTitle ? '1em' : '1.25em')};
  font-weight: ${(props) => (props.isFeedbackTitle ? '700' : '400')};
  margin: ${(props) => (props.isFeedbackTitle ? '0' : '25px 0')};
  text-align: start;
  width: 100%;
`;

export const ContainerInformations = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  gap: 5px;
`;

export const ContanetInformations = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 5px;
`;

export const IconInformations = styled.div`
  width: 16px;
  height: 16px;
`;

export const TextInformations = styled.p`
  margin: 0;
  color: #606062;
  font-size: 1em;
`;
