import React, { useContext, useEffect } from 'react';

import { Filter, ContainerFilters } from './styled';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import { VisualizationHandlers } from '../../Handlers/visualizationHandlers';

export default function InsideFilter() {
  const visualizationContextInstance = useVisualizationContext();

  const { finished, finishedType } = visualizationContextInstance;

  const options = [
    {
      label: `Finalizados`,
      value: 'finished',
      borderLeftRadius: true,
    },
    {
      label: `Despublicados `,
      value: 'canceled',
      borderLeftRadius: false,
    },
  ];

  const createFilters = options.map((option) => (
    <Filter
      borderLeftRadius={option.borderLeftRadius}
      color="#009291"
      active={option.value === finishedType}
      onClick={() =>
        VisualizationHandlers.handleChangeFinishedType(
          visualizationContextInstance,
          option.value
        )
      }
    >
      {option.label}
    </Filter>
  ));

  return <ContainerFilters>{createFilters}</ContainerFilters>;
}
