import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  
  width: 100%;
  
`

export const DropFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  width: 90%;

  background-color: transparent;
  
  padding: 20px;
  margin: 1%;
  
  border-radius: 5px;


  strong {
    font-size: 17px;
    font-weight: 500;
    color: #009291;
    padding: 0 0 12px 0;
  }

`

export const ContainerWrapper = styled.div`
    width: 100%;
`

export const DropFileInputStyled = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  border: 1px dashed #009291;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9F9F9;

  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  :hover,
  .drop-file-input.dragover {
    opacity: 0.7;
  }

  
  .drop-file-input_label span {
    font-size: 15px;
    padding: 0;
    color: #BCBCBC;
    font-weight: 400;
    background: #F9F9F9;

    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
  }
`

export const Main = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DropFilePreview = styled.div`
  width: 100%;
  display: flex;
  align-items: center ;
  justify-content: center ;

  p { font-weight: 500; }

  .drop-file-preview_title {
    width: 83%;
    display: flex;
    align-items: center;

    border-radius: 5px;
    padding: 5px;
    background: #EEEEEE;
    margin-bottom: 4px;
  }
`

export const PreviewItem = styled.div`
    width: 100%;
    max-width: 380px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;

    img {
      width: 50px;
      margin-right: 20px;
    }

    p {
      margin: 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
`

export const MessageError = styled.div`
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;

  p {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 8px;

    color: #f44336;
  }

`
