import React from 'react';

export function InformationIcon({ size = 56, color = '#fff' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_11968_36024)">
        <path
          d="M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.39991 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216643 4.00021 -0.0968503 5.00555 0.0960759 5.97545C0.289002 6.94536 0.765206 7.83627 1.46447 8.53554C2.16373 9.2348 3.05465 9.711 4.02455 9.90393C4.99446 10.0969 5.99979 9.99784 6.91342 9.6194C7.82705 9.24096 8.60794 8.6001 9.15735 7.77785C9.70676 6.95561 10 5.98891 10 5C9.99857 3.67436 9.47133 2.40342 8.53395 1.46605C7.59658 0.528678 6.32565 0.00143378 5 0ZM5 9.16667C4.17591 9.16667 3.37033 8.9223 2.68513 8.46446C1.99992 8.00662 1.46587 7.35587 1.1505 6.59452C0.835139 5.83316 0.752625 4.99538 0.913397 4.18712C1.07417 3.37887 1.47101 2.63644 2.05372 2.05372C2.63644 1.471 3.37887 1.07417 4.18713 0.913395C4.99538 0.752623 5.83316 0.835137 6.59452 1.1505C7.35588 1.46587 8.00662 1.99992 8.46446 2.68512C8.9223 3.37033 9.16667 4.17591 9.16667 5C9.16546 6.1047 8.72608 7.1638 7.94494 7.94494C7.1638 8.72608 6.1047 9.16545 5 9.16667Z"
          fill={color}
        />
        <path
          d="M5.00033 4.16602H4.58366C4.47315 4.16602 4.36717 4.20991 4.28903 4.28805C4.21089 4.36619 4.16699 4.47218 4.16699 4.58268C4.16699 4.69319 4.21089 4.79917 4.28903 4.87731C4.36717 4.95545 4.47315 4.99935 4.58366 4.99935H5.00033V7.49935C5.00033 7.60986 5.04422 7.71584 5.12236 7.79398C5.2005 7.87212 5.30649 7.91602 5.41699 7.91602C5.5275 7.91602 5.63348 7.87212 5.71162 7.79398C5.78976 7.71584 5.83366 7.60986 5.83366 7.49935V4.99935C5.83366 4.77834 5.74586 4.56637 5.58958 4.41009C5.4333 4.25381 5.22134 4.16602 5.00033 4.16602Z"
          fill={color}
        />
        <path
          d="M5 3.33398C5.34518 3.33398 5.625 3.05416 5.625 2.70898C5.625 2.36381 5.34518 2.08398 5 2.08398C4.65482 2.08398 4.375 2.36381 4.375 2.70898C4.375 3.05416 4.65482 3.33398 5 3.33398Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_11968_36024">
          <rect width="10" height="10" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
