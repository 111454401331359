import styled from 'styled-components';
import closeIcon from 'assets/Oportutinites/X.svg';

export const Container = styled.div`
  width: 500px;
  height: 520px;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  position: relative;
`;

export const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #009291;
  margin-top: 30px;
`;

export const WrapperFields = styled.div`
  margin-top: 25px;

    > div {
      > label {
        margin-bottom: 8px;
      }
    }
  }
`;

export const SpaceNoteInput = styled.div`
  margin-top: 30px;
`;

export const WrapperSendButton = styled.div`
  margin-top: 60px;
  position: relative;

  > button {
    background-color: #009291;
    width: 135px;
    height: 40px;
    border-radius: 4px;
    color: #fff;
    border: none;
    position: absolute;
    right: 10px;
    font-weight: bold;
    font-size: 13px;
    cursor: pointer;

    &:active {
      transform: scale(0.9);
    }
  }
`;
