import React, { Fragment, useEffect, useState } from 'react';
import { number, object, func } from 'prop-types';
import CardComposition from 'components/CardComposition/CardComposition';
import { getAreaDetails } from 'services/backoffice/areas';
import { withStyles } from '@material-ui/core/styles';
import jobsPositionDetailsStyles from 'assets/jss/material-dashboard-pro-react/views/components/jobsPositionDetailsStyles';

function AreaDetail(props) {
  const { classes, areaId, loadingState, openSnackbar, data } = props;
  const [areaDetail, setAreaDetail] = useState({});

  useEffect(() => {
    if (data) {
      setAreaDetail(data);
      loadingState(false);
    } else {
      loadingState(true);
      fetchAreaDetail();
    }
  }, []);

  const fetchAreaDetail = async () => {
    const response = await getAreaDetails(areaId);

    if (!response.message) {
      setAreaDetail(response);
      loadingState(false);
    } else {
      openSnackbar(
        'Falha ao carregar os detalhes da área! Tente novamente mais tarde.',
        true
      );
    }
  };

  return (
    <div style={{ margin: '0 auto', maxWidth: 860 }}>
      {areaDetail.name ? (
        <CardComposition
          CardContent={() => (
            <Fragment>
              <p className={classes.paragraph}>
                <strong>Nome da área:</strong>
                <br />
                {areaDetail.name}
              </p>
              <p className={classes.paragraph}>
                <strong>Subgrupo:</strong>
                <br />
                {areaDetail.subgroup.label}
              </p>
              {areaDetail.subgroup.value === 'market_tendencies' && (
                <p className={classes.paragraph}>
                  <strong>Descrição:</strong>
                  <br />
                  {areaDetail.description}
                </p>
              )}
            </Fragment>
          )}
        />
      ) : (
        <CardComposition
          CardContent={() => <div>Não há detalhes para esta área.</div>}
        />
      )}
    </div>
  );
}
AreaDetail.propTypes = {
  classes: object.isRequired,
  areaId: number.isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  data: object,
};
export default withStyles(jobsPositionDetailsStyles)(AreaDetail);
