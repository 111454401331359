import {
  AddButtonWrapper,
  AddSpecificSchedulingButton,
  CheckBoxWrapper,
  Dash,
  DeleteScheduleButton,
  HourInput,
  HourInputWrapper,
  HoursContainer,
  ScheduleError,
  ScheduleFieldsWrapper,
  ScheduleListContainer,
  SchedulesContainer,
  WeeklySchedulesContainer,
  WithoutScheduleMessage,
  WrapperLabel,
} from './styled';

import { FieldLabel, Form, TextTitle } from '../../../styled';
import React, { useCallback, useEffect, useState } from 'react';
import { SelectField } from '../../../../../../../components/SelectField';
import { Scope } from '@unform/core';
import { maskTime, maskTimeBlur } from 'utils/inputMasks';
import DeleteIcon from 'assets/academicModeling/delete';
import { HiPlus } from 'react-icons/hi';
import {
  transformKeysToObject,
  transformObjectToKeys,
} from 'utils/unformUtils';
import { useCreateMentor } from '../../../context';
import { SpecificDayModal } from './SpecificDateModal';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { mentorshipApi } from '../../../../../../../services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export function SchedulingTab() {
  const [specificDayModalOpen, setSpecificDayModalOpen] = useState(false);
  const {
    formData,
    schedulingRef,
    validateScheduling,
    scheduleError,
    scheduleSelectedDays,
    setScheduleSelectedDays,
    daysOfWeekMap,
    validateSchedule,
    validateHourRange,
    validateWeekSchedule,
    options,
    scheduleSpecificListError,
    validateScheduleSpecificList,
    validateHourRangeSpecificList,
    updateSpecificDates,
    isEdit,
    mentor,
    setMentor,
  } = useCreateMentor();

  const [specificDates, setSpecificDates] = useState(
    formData.specific_dates || []
  );

  useEffect(() => {
    if (formData.specific_dates) {
      setSpecificDates(formData.specific_dates);
    }
  }, [formData]);

  const isDaySelected = useCallback(
    (day) => {
      return scheduleSelectedDays[day].selected;
    },
    [scheduleSelectedDays]
  );

  useEffect(() => {
    const validateRangeOfAllDays = () => {
      Object.keys(scheduleSelectedDays).forEach((day) => {
        validateHourRange(day);
      });
    };

    validateRangeOfAllDays();
  }, [scheduleSelectedDays]);

  useEffect(() => {
    const validateRangeOfAllDaysSpecificList = () => {
      specificDates.forEach((specificDate, index) =>
        validateHourRangeSpecificList(index)
      );
    };
    validateRangeOfAllDaysSpecificList();
  }, [specificDates]);

  const toggleSelectedDay = (day) => {
    const newSelectedDays = { ...scheduleSelectedDays };
    const newSelectedValue = !newSelectedDays[day].selected;
    newSelectedDays[day].selected = newSelectedValue;
    if (newSelectedValue) {
      newSelectedDays[day].schedulesAmount = 1;
    } else {
      newSelectedDays[day].schedulesAmount = 0;
      const errors = schedulingRef.current.getErrors();
      Object.keys(errors).forEach((error) => {
        if (error.includes(day)) {
          delete errors[error];
        }
      });
      validateWeekSchedule();
    }
    setScheduleSelectedDays(newSelectedDays);
  };

  const calculateEndHour = (weekDay, index, time) => {
    const value = maskTimeBlur(time);
    if (!value) {
      schedulingRef.current.setFieldValue(
        `available_days.${weekDay}[${index}].end_hour`,
        ``
      );
      return;
    }
    let [hours, minutes] = value.split(':');
    let newHours = (parseInt(hours) + 1) % 24;
    newHours = newHours < 10 ? `0${newHours}` : newHours;
    schedulingRef.current.setFieldValue(
      `available_days.${weekDay}[${index}].end_hour`,
      `${newHours}:${minutes}`
    );
  };

  const calculateEndHourSpecificDay = (dayIndex, scheduleIndex, time) => {
    const value = maskTimeBlur(time);
    if (!value) {
      schedulingRef.current.setFieldValue(
        `specific_dates[${dayIndex}].schedules[${scheduleIndex}].end_hour`,
        ''
      );
      schedulingRef.current.setFieldValue(
        `specific_dates[${dayIndex}].schedules[${scheduleIndex}].end_hour`,
        ''
      );
      return;
    }
    let [hours, minutes] = value.split(':');
    let newHours = (parseInt(hours) + 1) % 24;
    newHours = newHours < 10 ? `0${newHours}` : newHours;
    schedulingRef.current.setFieldValue(
      `specific_dates[${dayIndex}].schedules[${scheduleIndex}].end_hour`,
      `${newHours}:${minutes}`
    );
  };

  const handleDeleteOneSchedule = (weekDay, index) => {
    const newSelectedDays = { ...scheduleSelectedDays };
    const schedules = schedulingRef.current.getData()['available_days'][
      weekDay
    ];
    schedules.splice(index, 1);
    schedules.forEach((schedule, index) => {
      schedulingRef.current.setFieldValue(
        `available_days.${weekDay}[${index}].start_hour`,
        schedule.start_hour
      );
      schedulingRef.current.setFieldValue(
        `available_days.${weekDay}[${index}].end_hour`,
        schedule.end_hour
      );
    });
    const errors = schedulingRef.current.getErrors();
    const objectErrors = transformKeysToObject(errors);
    if (objectErrors['available_days'][weekDay]) {
      objectErrors['available_days'][weekDay].splice(index, 1);
      schedulingRef.current.setErrors(transformObjectToKeys(objectErrors));
    }
    newSelectedDays[weekDay].schedulesAmount -= 1;
    setScheduleSelectedDays(newSelectedDays);
  };

  const handleDeleteOneScheduleSpecificList = (
    indexDay,
    indexSchedule,
    scheduleId
  ) => {
    const schedules = schedulingRef.current.getData()['specific_dates'][
      indexDay
    ].schedules;
    if (isEdit && scheduleId) {
      mentorshipApi.deleteSpecificDate(mentor.id, scheduleId);
    }
    if (schedules.length === 1) {
      let newSpecificDates = [...specificDates];
      newSpecificDates = newSpecificDates.filter(
        (specificDate, index) => index !== indexDay
      );
      setSpecificDates(newSpecificDates);
      return;
    }
    schedules.splice(indexSchedule, 1);
    schedules.forEach((schedule, index) => {
      schedulingRef.current.setFieldValue(
        `specific_dates[${indexDay}].schedules[${index}].start_hour`,
        schedule.start_hour
      );
      schedulingRef.current.setFieldValue(
        `specific_dates[${indexDay}].schedules[${index}].end_hour`,
        schedule.end_hour
      );
    });
    let newErrors = schedulingRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    if (
      objectErrors['specific_dates'] &&
      objectErrors['specific_dates'][indexDay]
    ) {
      objectErrors['specific_dates'][indexDay].schedules.splice(
        indexSchedule,
        1
      );
      newErrors = transformObjectToKeys(objectErrors);
      schedulingRef.current.setErrors(newErrors);
    }
    const newSpecificDates = [...specificDates];
    newSpecificDates[indexDay].schedules.splice(indexSchedule, 1);
    setSpecificDates(newSpecificDates);
    if (isEdit) {
      setMentor({ ...mentor, specific_dates: newSpecificDates });
    }
    validateHourRangeSpecificList(indexDay, newErrors);
  };

  const addOneScheduleSpecificList = (dayIndex) => {
    const newSpecificDates = [...specificDates];
    newSpecificDates[dayIndex].schedules.push({ start_hour: '', end_hour: '' });
    setSpecificDates(newSpecificDates);
  };

  const addOneSchedule = (weekDay) => {
    if (scheduleSelectedDays[weekDay].schedulesAmount < 24) {
      const newSelectedDays = { ...scheduleSelectedDays };
      const newScheduleAmount = newSelectedDays[weekDay].schedulesAmount + 1;
      newSelectedDays[weekDay].schedulesAmount = newScheduleAmount;
      setScheduleSelectedDays(newSelectedDays);
      schedulingRef.current.setFieldError(
        `available_days.${weekDay}[${newScheduleAmount - 1}].start_hour`,
        null
      );
      schedulingRef.current.setFieldError(
        `available_days.${weekDay}[${newScheduleAmount - 1}].end_hour`,
        null
      );
    }
  };

  const buildDaySchedule = (day) => {
    const daySelected = isDaySelected(day.value);
    const scheduleAmount = scheduleSelectedDays[day.value].schedulesAmount;
    return (
      <SchedulesContainer key={day.value}>
        <CheckBoxWrapper>
          <input
            type="checkbox"
            name={day.value}
            id={day.value}
            checked={scheduleSelectedDays[day.value].selected}
            onChange={() => toggleSelectedDay(day.value)}
          />
          <label htmlFor={day.value}>{day.label}</label>
        </CheckBoxWrapper>
        {daySelected ? (
          <ScheduleFieldsWrapper>
            {Object.keys([...Array(scheduleAmount)]).map((_, index) => (
              <HoursContainer key={index}>
                <Scope path={`${day.value}[${index}]`}>
                  <HourInputWrapper>
                    <HourInput
                      name={'start_hour'}
                      placeholder={'00:00'}
                      maxLength={5}
                      hasMask
                      showErrorLabel={false}
                      onChange={(event) => {
                        const maskedValue = maskTime(event.target.value);
                        schedulingRef.current.setFieldValue(
                          `available_days.${day.value}[${index}].start_hour`,
                          maskedValue
                        );
                        event.target.value = maskedValue;
                        calculateEndHour(day.value, index, maskedValue);
                      }}
                      onBlur={(event) => {
                        event.target.value = maskTimeBlur(event.target.value);
                        validateSchedule(day.value, index, event.target.value);
                      }}
                      defaultValue={
                        formData.available_days?.[day.value]?.[index]
                          ?.start_hour
                      }
                    />
                  </HourInputWrapper>
                  <Dash />
                  <HourInputWrapper>
                    <HourInput
                      showErrorLabel={false}
                      name={'end_hour'}
                      placeholder={'00:00'}
                      maxLength={5}
                      defaultValue={
                        formData.available_days?.[day.value]?.[index]?.end_hour
                      }
                      disabled
                    />
                  </HourInputWrapper>
                  {scheduleAmount > 1 && (
                    <DeleteScheduleButton
                      type={'button'}
                      onClick={() => handleDeleteOneSchedule(day.value, index)}
                    >
                      <DeleteIcon color={'#606062'} size={18} />
                    </DeleteScheduleButton>
                  )}
                </Scope>
              </HoursContainer>
            ))}
          </ScheduleFieldsWrapper>
        ) : (
          <WithoutScheduleMessage>
            Sem horário disponível
          </WithoutScheduleMessage>
        )}
        {scheduleAmount < 24 && (
          <AddButtonWrapper
            paddingBottom={scheduleAmount > 1 ? '2rem' : '1rem'}
          >
            {isDaySelected(day.value) && (
              <HiPlus
                color={'#009291'}
                size={18}
                onClick={() => addOneSchedule(day.value)}
              />
            )}
          </AddButtonWrapper>
        )}
      </SchedulesContainer>
    );
  };

  const handleCloseSpecificDayModal = () => {
    setSpecificDayModalOpen(false);
  };

  const handleOpenSpecificDayModal = () => {
    updateSpecificDates(specificDates);
    setSpecificDayModalOpen(true);
  };

  const specificError = (index) => {
    return scheduleSpecificListError.find((error) => error.index === index);
  };

  const handleBlurSpecificDateStartHour = async (
    event,
    indexSpecificDate,
    indexSchedule,
    scheduleId
  ) => {
    const value = maskTimeBlur(event.target.value);
    event.target.value = value;
    const isValid = validateScheduleSpecificList(
      indexSpecificDate,
      indexSchedule,
      value
    );
    if (isValid && isEdit) {
      try {
        let [hours, minutes] = value.split(':');
        let newHours = (parseInt(hours) + 1) % 24;
        newHours = newHours < 10 ? `0${newHours}` : newHours;
        const endHour = `${newHours}:${minutes}`;
        const newSpecificDate = {
          mentor: mentor.id,
          specific_dates: [
            {
              date: specificDates[indexSpecificDate].date,
              start_hour: value,
              end_hour: endHour,
            },
          ],
        };
        if (scheduleId) {
          mentorshipApi.deleteSpecificDate(mentor.id, scheduleId);
        }
        const response = await mentorshipApi.createSpecificDates(
          newSpecificDate
        );
        const id = response?.dates[0].id;
        const newSpecificDates = [...specificDates];
        newSpecificDates[indexSpecificDate].schedules[indexSchedule] = {
          start_hour: value,
          end_hour: endHour,
          id,
        };
        setSpecificDates(newSpecificDates);
        setMentor({ ...mentor, specific_dates: newSpecificDates });
      } catch (e) {
        customSnackbar(
          'Ocorreu um error ao atualizar as datas específicas',
          'error'
        );
      }
    }
  };

  return (
    <>
      <Form ref={schedulingRef}>
        <TextTitle marginTop={'2rem'}>
          Agora, nos informe a disponibilidade de horários para realizar as
          mentorias
        </TextTitle>
        <FieldLabel marginTop={'2rem'}>Modalidade*</FieldLabel>
        <SelectField
          name={'mentorship_modality'}
          defaultValue={formData.mentorship_modality}
          options={options?.mentorship_modality}
          onChange={(option) =>
            validateScheduling('mentorship_modality', option)
          }
          isLoading={!options}
          isMulti
        />
        <WrapperLabel>
          <FieldLabel marginTop={'2rem'} width={'fit-content'}>
            Horários semanais*
          </FieldLabel>
          {scheduleError && (
            <ScheduleError className={'error-label'}>
              {scheduleError}
            </ScheduleError>
          )}
        </WrapperLabel>
        <WeeklySchedulesContainer error={!!scheduleError}>
          <Scope path={'available_days'}>
            {Object.keys(daysOfWeekMap).map((day) =>
              buildDaySchedule({ label: daysOfWeekMap[day], value: day })
            )}
          </Scope>
        </WeeklySchedulesContainer>
        <AddSpecificSchedulingButton
          type={'button'}
          onClick={handleOpenSpecificDayModal}
        >
          Incluir um dia e horário específico
        </AddSpecificSchedulingButton>
        {specificDates.map((item, indexSpecificDate) => (
          <>
            <WrapperLabel key={item.id}>
              <FieldLabel
                marginTop={'2rem'}
                marginBottom={'0.5rem'}
                width={'fit-content'}
              >
                {capitalizeFirstLetter(
                  new Date(item.date).toLocaleDateString('pt-BR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })
                )}
              </FieldLabel>
              {specificError(indexSpecificDate) && (
                <ScheduleError className={'error-label'} margin={'0 0 12px 0'}>
                  {specificError(indexSpecificDate).value}
                </ScheduleError>
              )}
            </WrapperLabel>
            <ScheduleListContainer>
              <ScheduleFieldsWrapper>
                {specificDates[indexSpecificDate]?.schedules.map(
                  (schedule, indexSchedule) => (
                    <Scope
                      key={indexSchedule}
                      path={`specific_dates[${indexSpecificDate}].schedules[${indexSchedule}]`}
                    >
                      <HoursContainer key={indexSchedule}>
                        <HourInputWrapper>
                          <HourInput
                            hasMask
                            name={'start_hour'}
                            placeholder={'00:00'}
                            maxLength={5}
                            showErrorLabel={false}
                            onChange={(event) => {
                              const maskedValue = maskTime(event.target.value);
                              schedulingRef.current.setFieldValue(
                                `specific_dates[${indexSpecificDate}].schedules[${indexSchedule}].start_hour`,
                                maskedValue
                              );
                              calculateEndHourSpecificDay(
                                indexSpecificDate,
                                indexSchedule,
                                maskedValue
                              );
                            }}
                            onBlur={(event) =>
                              handleBlurSpecificDateStartHour(
                                event,
                                indexSpecificDate,
                                indexSchedule,
                                schedule.id
                              )
                            }
                            defaultValue={schedule.start_hour}
                          />
                        </HourInputWrapper>
                        <Dash />
                        <HourInputWrapper>
                          <HourInput
                            showErrorLabel={false}
                            name={'end_hour'}
                            placeholder={'00:00'}
                            maxLength={5}
                            defaultValue={schedule.end_hour}
                            disabled
                          />
                        </HourInputWrapper>
                        <DeleteScheduleButton
                          type={'button'}
                          onClick={() =>
                            handleDeleteOneScheduleSpecificList(
                              indexSpecificDate,
                              indexSchedule,
                              schedule.id
                            )
                          }
                        >
                          <DeleteIcon color={'#606062'} size={18} />
                        </DeleteScheduleButton>
                      </HoursContainer>
                    </Scope>
                  )
                )}
              </ScheduleFieldsWrapper>
              {specificDates[indexSpecificDate]?.schedules.length < 24 && (
                <AddButtonWrapper>
                  <HiPlus
                    color={'#009291'}
                    size={18}
                    onClick={() => {
                      addOneScheduleSpecificList(indexSpecificDate);
                    }}
                  />
                </AddButtonWrapper>
              )}
            </ScheduleListContainer>
          </>
        ))}
      </Form>
      <SpecificDayModal
        isOpen={specificDayModalOpen}
        onClose={handleCloseSpecificDayModal}
      />
    </>
  );
}
