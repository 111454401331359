import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Form } from '@unform/web';
import {
  WrapperTitleEdit,
  WrapperContainer,
  TitleForm,
  MultiStepContainer,
  InfoWrapper,
} from 'views/ProfessionalStatus/styles/styles';
import * as Yup from 'yup';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { validationSchemaStepTwo } from './schema';
import Select from '../../../components/Select/select';
import StepHeader from '../../../components/StepsHeader';
import { useFormContext } from 'views/ProfessionalStatus/context';
import { icons } from 'views/ProfessionalStatus/utils/OptionsIcon';
import FooterForm from '../../../components/StepsFooter/FooterForm';
import professionalStatusApi from 'views/ProfessionalStatus/services/professionalStatusApi';
import BaseLayoutContext from 'contexts/base-layout';

export function StepTwo({
  isProfessionalStatusDefined,
  isSurveysDefinedData,
  isSurveysDefined,
  universityColor,
  academicProfile,
  prevsFormStep,
  nextFormStep,
  profileData,
  options,
  isAnsweredSurveyDefined,
}) {
  const [currentSituation, setCurrentSituation] = useState('');
  const [loading, setLoading] = useState(false);
  const { formData, setFormValues } = useFormContext();
  const { closeModal } = useContext(BaseLayoutContext) || {};
  const formRef = useRef();
  const topDiv = useRef(null);

  const scrollToTopDiv = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTopDiv();
  }, []);

  useEffect(() => {
    formRef.current.setData(profileData);
  }, [profileData]);

  useEffect(() => {
    if (currentSituation === 'unemployed') {
      if (profileData?.occupation_area) {
        delete formData.occupation_area;
        delete profileData.occupation_area;
      }
      if (profileData?.is_working_in_formation_area) {
        delete formData.is_working_in_formation_area;
        delete profileData.is_working_in_formation_area;
      }
      if (profileData?.professional_satisfaction) {
        delete formData.professional_satisfaction;
        delete profileData.professional_satisfaction;
      }
    }
  }, [currentSituation]);

  const HeaderControll = () => {
    if (!isProfessionalStatusDefined && isSurveysDefinedData) {
      return (
        <MultiStepContainer>
          <StepHeader
            Steps={['StepOne', 'StepTwo', 'StepThree', 'StepFour']}
            universityColor={universityColor}
          />
        </MultiStepContainer>
      );
    }
    if (
      isProfessionalStatusDefined &&
      isSurveysDefined &&
      !isAnsweredSurveyDefined
    ) {
      return (
        <MultiStepContainer>
          <StepHeader
            Steps={['StepOne', 'StepTwo']}
            universityColor={universityColor}
          />
        </MultiStepContainer>
      );
    }
    if (isProfessionalStatusDefined) {
      return null;
    }
    return (
      <MultiStepContainer>
        <StepHeader
          Steps={['StepOne', 'StepTwo', 'StepThree']}
          universityColor={universityColor}
        />
      </MultiStepContainer>
    );
  };

  const handleValidationErrors = async (data) => {
    try {
      await validationSchemaStepTwo.validate(data, {
        abortEarly: false,
      });
      return null;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
      }
      return validationErrors;
    }
  };

  const handleSubmit = async (data) => {
    try {
      const validationErrors = await handleValidationErrors(data);

      if (validationErrors) {
        formRef.current.setErrors(validationErrors);
        return;
      }
      setLoading(true);
      if (
        isProfessionalStatusDefined &&
        isSurveysDefined &&
        !isAnsweredSurveyDefined
      ) {
        setFormValues(data);
        nextFormStep();
        return;
      }
      if (isProfessionalStatusDefined) {
        const response = await professionalStatusApi.putFirstAccess({
          ...data,
          ...formData,
        });
        closeModal();
        return;
      }
      setFormValues(data);
      nextFormStep();
    } catch (error) {
      setLoading(false);
    }
  };

  const bolleanOptions = [
    { label: 'Sim', value: 'true' },
    { label: 'Não', value: 'false' },
  ];

  const shiftOptions = [
    { label: 'Manhã', value: 'morning' },
    { label: 'Tarde', value: 'afternoon' },
    { label: 'Noite', value: 'night' },
    { label: 'Indiferente', value: 'indifferent' },
  ];
  const [selectedShift, setSelectedShift] = useState([]);

  const handleShiftSelect = (selectedOption) => {
    setSelectedShift(selectedOption);
    const indifferentSelected = selectedOption.some(
      (option) => option?.value === 'indifferent'
    );
    const morningSelected = selectedOption.some(
      (option) => option?.value === 'morning'
    );
    const afternoonSelected = selectedOption.some(
      (option) => option?.value === 'afternoon'
    );
    const nightSelected = selectedOption.some(
      (option) => option?.value === 'night'
    );

    if (
      indifferentSelected &&
      (morningSelected || afternoonSelected || nightSelected)
    ) {
      setSelectedShift([{ label: 'Indiferente', value: 'indifferent' }]);
    } else {
      if (morningSelected && afternoonSelected && nightSelected) {
        setSelectedShift([{ label: 'Indiferente', value: 'indifferent' }]);
      } else {
        setSelectedShift(selectedOption);
      }
    }
  };

  useEffect(() => {
    setCurrentSituation(formData?.status);
    if (formData?.work_shift) {
      let shifts = [];
      formData?.work_shift.forEach((o) =>
        shifts.push({
          label: shiftOptions.find((shift) => shift.value === o).label,
          value: o,
        })
      );
      setSelectedShift(shifts);
      formRef.current.setFieldValue('work_shift', shifts);
    }
  }, [formData]);

  const FooterControll = () => {
    if (
      isProfessionalStatusDefined &&
      isSurveysDefined &&
      !isAnsweredSurveyDefined
    ) {
      return (
        <FooterForm
          haveFinish={false}
          haveBack={false}
          haveNext={true}
          universityColor={universityColor}
        />
      );
    }
    if (isProfessionalStatusDefined) {
      return (
        <FooterForm
          haveFinish={true}
          haveNext={false}
          haveBack={false}
          universityColor={universityColor}
          loading={loading}
        />
      );
    }
    return (
      <FooterForm
        haveNext={true}
        haveBack={true}
        prevsState={prevsFormStep}
        universityColor={universityColor}
      />
    );
  };

  return (
    <Fragment>
      <div ref={topDiv}>
        <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
          <WrapperContainer>
            {HeaderControll()}
            {isProfessionalStatusDefined && (
              <WrapperTitleEdit universityColor={universityColor}>
                <div className="border-image">
                  <img src={Wall} alt="Icone Wall" />
                </div>
                <div>
                  <p universityColor={universityColor}>
                    Olá, {academicProfile.name}!
                  </p>
                  <p>
                    Eu sou sua mentora de Carreiras. Me conta aí, quais foram as
                    últimas mudanças na sua carreira.
                  </p>
                </div>
              </WrapperTitleEdit>
            )}

            <InfoWrapper>
              {!isProfessionalStatusDefined && (
                <TitleForm universityColor={universityColor}>
                  Para entendermos melhor sobre você. Me conta mais sobre suas
                  expectativas:
                </TitleForm>
              )}
              <Select
                icon={icons.iconExpProfissional}
                universityColor={universityColor}
                label="Qual opção melhor descreve sua situação profissional atual?"
                name="status"
                options={options.status}
                onChange={(selectedOption) =>
                  setCurrentSituation(selectedOption.value)
                }
                placeholder="Selecione"
                isMulti={false}
              />
              {currentSituation !== 'unemployed' && (
                <>
                  <Select
                    icon={icons.iconLaptop}
                    universityColor={universityColor}
                    label="Qual atuação melhor descreve sua principal função atual:"
                    subLabel="Ex: Auxiliar Administrativo"
                    placeholder="Digite aqui..."
                    name="occupation_area"
                    options={options.occupation_area}
                    isMulti={false}
                    disabled={currentSituation === 'unemployed'}
                  />
                  <Select
                    icon={icons.IconGraduationCap}
                    universityColor={universityColor}
                    label="Você está trabalhando na sua área de formação?"
                    name="is_working_in_formation_area"
                    options={bolleanOptions}
                    placeholder="Selecione"
                    isMulti={false}
                    disabled={currentSituation === 'unemployed'}
                  />
                  <Select
                    icon={icons.iconLike}
                    universityColor={universityColor}
                    label="Você está satisfeito com a sua situação profissional?"
                    name="professional_satisfaction"
                    options={bolleanOptions}
                    placeholder="Selecione"
                    isMulti={false}
                    disabled={currentSituation === 'unemployed'}
                  />
                </>
              )}
              <Select
                icon={icons.iconBadge}
                universityColor={universityColor}
                label="Você aceita receber propostas de trabalho disponibilizando seu Currículo de forma pública?"
                name="is_open_to_offers"
                options={bolleanOptions}
                placeholder="Selecione"
                isMulti={false}
              />
              <Select
                icon={icons.iconBadge}
                universityColor={universityColor}
                label="Qual é a sua disponibilidade de horário para oportunidades de trabalho ou estágio?"
                name="work_shift"
                options={shiftOptions}
                value={selectedShift}
                onChange={handleShiftSelect}
                placeholder="Selecione"
                isMulti={true}
              />
              <Select
                icon={icons.iconBullseyeArrow}
                universityColor={universityColor}
                label="Agora, me fala, qual o seu principal objetivo de carreira no curto prazo (6 à 12 meses):"
                name="is_carrer_objective"
                options={options.is_carrer_objective}
                placeholder="Selecione"
                isMulti={false}
              />
            </InfoWrapper>
            {FooterControll()}
          </WrapperContainer>
        </Form>
      </div>
    </Fragment>
  );
}
