import React, { useState, Fragment, useEffect } from 'react';
import Button from 'components/CustomButtons/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { reportOptions } from 'services/university/socioeconomicReport';
import { func } from 'prop-types';
import ReportRegisterForm from './ReportRegisterForm';
import ReportList from './ReportList';

const CustomReport = ({ handleIsLoadingState, openSnackbar }) => {
  const [view, setView] = useState(false);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    let isMounted = true;
    reportOptions().then((res) => {
      if (isMounted) setOptions(res);
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          padding: '16px 0',
          marginLeft: '-2.2em',
        }}
      >
        <Button style={{ borderRadius: 100 }} onClick={() => setView(!view)}>
          {!view && 'CADASTRAR RELATÓRIO'}
          {view && (
            <Fragment>
              <ArrowBackIosIcon />
              VOLTAR
            </Fragment>
          )}
        </Button>
      </div>
      {view && (
        <ReportRegisterForm
          targetOptions={options}
          handleIsLoadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          setView={setView}
        />
      )}
      {!view && options && (
        <ReportList
          reportOptions={options}
          handleIsLoadingState={handleIsLoadingState}
        />
      )}
    </div>
  );
};

CustomReport.propTypes = {
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
};

export default CustomReport;
