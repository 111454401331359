import React, { useContext } from 'react';
import { FilterRadioLi, FilterUl } from '../styles';
import { FilterType } from './Filter';
import { useFilterContext } from '../contexts/FilterContext';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterRadio({
  name,
  options,
  isTwoColumns = false,
  wrap = false,
}) {
  const { filterState, updateFilterState } = useFilterContext();
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <FilterUl
      className={`${isTwoColumns ? 'two-columns' : ''}${wrap ? ' wrap' : ''}`}
    >
      {options.map((option) => (
        <FilterRadioLi universityColor={universityColor}>
          <input
            universityColor={universityColor}
            type="radio"
            id={`${name}-${option.value}`}
            value={option.value}
            checked={
              filterState[name] !== null && filterState[name] === option.value
            }
            onChange={() =>
              updateFilterState(option.value, name, FilterType.RADIO)
            }
            onClick={() => {
              if (
                filterState[name] !== null &&
                filterState[name] === option.value
              ) {
                updateFilterState(null, name, FilterType.RADIO);
              }
            }}
          />
          <label htmlFor={`${name}-${option.value}`}>{option.label}</label>
        </FilterRadioLi>
      ))}
    </FilterUl>
  );
}
