import { ContainerButtons, EditButton, UnpublishButton } from './style';
import blackTrash from 'assets/Oportutinites/blackTrash.svg';

export default function ContainerButtonsModal({
  funcUnpublish,
  funcEdit,
  showUnpublishButton,
  showEditButton,
}) {
  return (
    <ContainerButtons showContainer={showUnpublishButton || showEditButton}>
      {showUnpublishButton && (
        <UnpublishButton onClick={funcUnpublish}>
          <img src={blackTrash} />
          DESPUBLICAR
        </UnpublishButton>
      )}
      {showEditButton && <EditButton onClick={funcEdit}>EDITAR</EditButton>}
    </ContainerButtons>
  );
}
