import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as Yup from 'yup';
import {
  transformKeysToObject,
  transformObjectToKeys,
} from 'utils/unformUtils';
import { maskTimeBlur } from 'utils/inputMasks';
import { mentorshipApi } from '../../../../services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { mentorAdapter } from '../../adapters/MentorAdapter';
import AuthContext from 'contexts/authentication';

export const CreateMentorContext = createContext({});

export function CreateMentorContextProvider(props) {
  const { children, isOpen, onClose, mentor, setMentor } = props;
  const isEdit = useMemo(() => {
    return !!mentor;
  }, [mentor]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [stepTwoSelectedTab, setStepTwoSelectedTab] = useState('scheduling');
  const [scheduleError, setScheduleError] = useState('');
  const [scheduleSpecificModalError, setScheduleSpecificModalError] = useState(
    ''
  );
  const [scheduleSpecificListError, setScheduleSpecificListError] = useState(
    []
  );
  const [newAbsencesAmount, setNewAbsencesAmount] = useState(1);
  const [absencesForDelete, setAbsencesForDelete] = useState([]);
  const [scheduleAmountSpecificDate, setScheduleAmountSpecificDate] = useState(
    1
  );
  const stepOneRef = useRef(null);
  const schedulingRef = useRef(null);
  const absencesRef = useRef(null);
  const specificDateRef = useRef(null);
  const [options, setOptions] = useState(null);
  const [specialities, setSpecialities] = useState([]);
  const [noSpecialitiesError, setNoSpecialitiesError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = ['teacher', 'course_coordinator'].includes(
    staffRole
  );
  const filteredAbsences = useMemo(
    () =>
      isEdit
        ? mentor.absences.filter(
            (absence) => !absencesForDelete.includes(absence.id)
          )
        : null,
    [isEdit, mentor, absencesForDelete]
  );

  useEffect(() => {
    if (isOpen) {
      const fetchOptions = async () => {
        const options = await mentorshipApi.getMentorsOptions();
        if (isEdit) {
          options.staff.push({
            label: mentor.name.name,
            value: mentor.name.id,
          });
        }
        setOptions(options);
      };
      fetchOptions();
    }
  }, [isOpen]);

  const daysOfWeekMap = {
    monday: 'SEGUNDA-FEIRA',
    tuesday: 'TERÇA-FEIRA',
    wednesday: 'QUARTA-FEIRA',
    thursday: 'QUINTA-FEIRA',
    friday: 'SEXTA-FEIRA',
    saturday: 'SÁBADO',
    sunday: 'DOMINGO',
  };

  const getInitialWeekSchedule = (day, options = { reset: false }) => {
    if (
      options.reset ||
      !formData.available_days ||
      !formData.available_days[day]
    ) {
      return {
        selected: false,
        schedulesAmount: 0,
      };
    }
    return {
      selected: formData.available_days[day]?.length > 0,
      schedulesAmount: formData.available_days[day].length,
    };
  };

  const [scheduleSelectedDays, setScheduleSelectedDays] = useState(
    Object.keys(daysOfWeekMap).reduce((acc, day) => {
      acc[day] = getInitialWeekSchedule(day);
      return acc;
    }, {})
  );

  const resetScheduleSelectedDays = () => {
    setScheduleSelectedDays(
      Object.keys(daysOfWeekMap).reduce((acc, day) => {
        acc[day] = getInitialWeekSchedule(day, { reset: true });
        return acc;
      }, {})
    );
  };

  useEffect(() => {
    if (isOpen && options) {
      if (shouldRenderForStaff) {
        const stepOne = {
          staff: options.staff[0],
        };
        setFormData(stepOne);
        stepOneRef.current?.setData(stepOne);
      }
      if (isEdit) {
        const stepOneData = {
          staff: options.staff.find(
            (mentorOption) => mentorOption.value === mentor.name.id
          ),
          macro_areas:
            mentor.macro_areas.length !== 9
              ? options.macro_areas.filter((area) =>
                  mentor.macro_areas?.find(
                    (mentorMacroArea) => mentorMacroArea.value === area.value
                  )
                )
              : [],
          courses: options.courses.filter((course) =>
            mentor.courses?.find((mentorCourse) => {
              return course.value === mentorCourse.value;
            })
          ),
          biography: mentor.biography,
          avatar: mentor.avatar,
        };
        stepOneRef.current?.setData(stepOneData);
        const newScheduleSelectedDays = { ...scheduleSelectedDays };
        Object.keys(mentor.available_days).forEach((day) => {
          newScheduleSelectedDays[day] = {
            selected: mentor.available_days[day].length > 0,
            schedulesAmount: mentor.available_days[day].length,
          };
        });
        setScheduleSelectedDays(newScheduleSelectedDays);
        setFormData({
          ...stepOneData,
          mentorship_modality: options.mentorship_modality.filter((modality) =>
            mentor.mentorship_modality[0].find((mentorModality) => {
              return modality.value === mentorModality.value;
            })
          ),
          available_days: mentor.available_days,
          absences: mentor.absences,
          specific_dates: mentor.specific_dates,
          id: mentor.id,
        });
        setSpecialities(mentor?.specialities || []);
      }
    }
  }, [isOpen, mentor, isEdit, options, shouldRenderForStaff, filteredAbsences]);

  useEffect(() => {
    setNewAbsencesAmount((filteredAbsences?.length || 0) >= 1 ? 0 : 1);
  }, [isOpen]);

  const incrementAbsenceAmount = () => {
    setNewAbsencesAmount(newAbsencesAmount + 1);
  };

  const decrementAbsenceAmount = () => {
    const savedAbsencesAmount = filteredAbsences?.length || 0;
    const minAbsencesAmount = isEdit && savedAbsencesAmount >= 1 ? 0 : 1;
    setNewAbsencesAmount(Math.max(minAbsencesAmount, newAbsencesAmount - 1));
  };

  const handleClose = () => {
    setFormData({});
    onClose();
    stepOneRef.current?.reset();
    schedulingRef.current?.reset();
    schedulingRef.current?.reset();
    absencesRef.current?.reset();
    specificDateRef.current?.reset();
    stepOneRef.current?.setErrors({});
    schedulingRef.current?.setErrors({});
    absencesRef.current?.setErrors({});
    specificDateRef.current?.setErrors({});
    setScheduleError('');
    setScheduleSpecificListError([]);
    setCurrentStep(1);
    setSpecialities([]);
    setNoSpecialitiesError(false);
    resetScheduleSelectedDays();
    setStepTwoSelectedTab('scheduling');
    setAbsencesForDelete([]);
  };

  const hasOneHourOfDifference = (startHour, endHour) => {
    const [startHours, startMinutes] = startHour.split(':');
    const [endHours, endMinutes] = endHour.split(':');
    const startTotalMinutes =
      parseInt(startHours) * 60 + parseInt(startMinutes);
    const endTotalMinutes = parseInt(endHours) * 60 + parseInt(endMinutes);
    return Math.abs(endTotalMinutes - startTotalMinutes) >= 60;
  };

  const verifyIfHasScheduleError = (newErrors, ref, setError) => {
    const errors = newErrors ? newErrors : ref.current.getErrors();
    const hasInvalidHours = Object.values(errors).some(
      (error) => error === 'Existem horários inválidos'
    );
    const hasConflictHours = Object.values(errors).some(
      (error) => error === 'Existem horários conflitantes'
    );
    if (hasInvalidHours) {
      setError('Existem horários inválidos');
      return;
    }
    if (hasConflictHours) {
      setError('Existem horários conflitantes');
      return;
    }
    setError('');
  };

  const verifyIfHasScheduleSpecificListError = (newErrors, dayIndex) => {
    const errors = newErrors ? newErrors : schedulingRef.current.getErrors();
    const hasInvalidHours = Object.values(errors).some(
      (error) => error === 'Existem horários inválidos'
    );
    const hasConflictHours = Object.values(errors).some(
      (error) => error === 'Dia com horários conflitantes'
    );
    const newScheduleSpecificListError = scheduleSpecificListError.filter(
      (error) => error.index !== dayIndex
    );
    if (hasConflictHours) {
      newScheduleSpecificListError.push({
        index: dayIndex,
        value: 'Dia com horários conflitantes',
      });
    }
    if (hasInvalidHours) {
      newScheduleSpecificListError.push({
        index: dayIndex,
        value: 'Existem horários inválidos',
      });
    }
    setScheduleSpecificListError(newScheduleSpecificListError);
  };

  const validateSchedule = (weekDay, index, time) => {
    let isValid = true;
    let newErrors = schedulingRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    if (
      objectErrors['available_days'] &&
      objectErrors['available_days'][weekDay] &&
      objectErrors['available_days'][weekDay][index]
    ) {
      objectErrors['available_days'][weekDay][index] = {
        start_hour: null,
        end_hour: null,
      };
      schedulingRef.current.setFieldError(
        `available_days.${weekDay}[${index}].start_hour`,
        null
      );
      schedulingRef.current.setFieldError(
        `available_days.${weekDay}[${index}].end_hour`,
        null
      );
    }
    const maskedValue = maskTimeBlur(time);
    const [hours, minutes] = maskedValue.split(':');
    if (!maskedValue || parseInt(hours) > 23 || parseInt(minutes) > 59) {
      if (!objectErrors['available_days']) {
        objectErrors['available_days'] = {};
      }
      if (!objectErrors['available_days'][weekDay]) {
        objectErrors['available_days'][weekDay] = [];
      }
      objectErrors['available_days'][weekDay][index] = {
        start_hour: 'Existem horários inválidos',
        end_hour: 'Existem horários inválidos',
      };
      schedulingRef.current.setFieldError(
        `available_days.${weekDay}[${index}].start_hour`,
        'Existem horários inválidos'
      );
      schedulingRef.current.setFieldError(
        `available_days.${weekDay}[${index}].end_hour`,
        'Existem horários inválidos'
      );
      isValid = false;
    }
    const isValidHourRange = validateHourRange(
      weekDay,
      transformObjectToKeys(objectErrors)
    );
    return isValid && isValidHourRange;
  };

  const validateScheduleSpecificList = (dayIndex, scheduleIndex, time) => {
    let isValid = true;
    let newErrors = schedulingRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    if (
      objectErrors['specific_dates'] &&
      objectErrors['specific_dates'][dayIndex] &&
      objectErrors['specific_dates'][dayIndex].schedules[scheduleIndex]
    ) {
      objectErrors['specific_dates'][dayIndex].schedules[scheduleIndex] = {
        start_hour: null,
        end_hour: null,
      };
      schedulingRef.current.setFieldError(
        `specific_dates[${dayIndex}].schedules[${scheduleIndex}].start_hour`,
        null
      );
      schedulingRef.current.setFieldError(
        `specific_dates[${dayIndex}].schedules[${scheduleIndex}].end_hour`,
        null
      );
    }
    const maskedValue = maskTimeBlur(time);
    const [hours, minutes] = maskedValue.split(':');
    if (!maskedValue || parseInt(hours) > 23 || parseInt(minutes) > 59) {
      if (!objectErrors['specific_dates']) {
        objectErrors['specific_dates'] = [];
      }
      if (!objectErrors['specific_dates'][dayIndex]) {
        objectErrors['specific_dates'][dayIndex] = {};
      }
      if (!objectErrors['specific_dates'][dayIndex].schedules) {
        objectErrors['specific_dates'][dayIndex].schedules = [];
      }
      if (!objectErrors['specific_dates'][dayIndex].schedules[scheduleIndex]) {
        objectErrors['specific_dates'][dayIndex].schedules[scheduleIndex] = {};
      }
      objectErrors['specific_dates'][dayIndex].schedules[scheduleIndex] = {
        start_hour: 'Existem horários inválidos',
        end_hour: 'Existem horários inválidos',
      };
      schedulingRef.current.setFieldError(
        `specific_dates[${dayIndex}].schedules[${scheduleIndex}].start_hour`,
        'Existem horários inválidos'
      );
      schedulingRef.current.setFieldError(
        `specific_dates[${dayIndex}].schedules[${scheduleIndex}].end_hour`,
        'Existem horários inválidos'
      );
      isValid = false;
    }
    const isValidHourRange = validateHourRangeSpecificList(
      dayIndex,
      transformObjectToKeys(objectErrors)
    );
    return isValid && isValidHourRange;
  };

  const validateHourRange = (weekDay, e) => {
    let isValid = true;
    if (!schedulingRef.current.getData()['available_days']) return;
    const schedules = schedulingRef.current.getData()['available_days'][
      weekDay
    ];
    if (!schedules) return;
    let newErrors = e ? e : schedulingRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    Object.keys(newErrors).forEach((errorKey) => {
      if (
        errorKey.includes(weekDay) &&
        newErrors[errorKey] === 'Existem horários conflitantes'
      ) {
        newErrors[errorKey] = null;
        schedulingRef.current.setFieldError(errorKey, null);
      }
    });
    schedules.forEach((schedule, i) => {
      if (
        newErrors[[`${weekDay}[${i}].start_hour`]] ===
        'Existem horários' + ' conflitantes'
      ) {
        if (
          objectErrors['available_days'][weekDay] &&
          objectErrors['available_days'][weekDay][i]
        ) {
          delete objectErrors['available_days'][weekDay][i];
          newErrors = transformObjectToKeys(objectErrors);
          schedulingRef.current.setErrors(transformObjectToKeys(objectErrors));
        }
      }
      schedules.find((s, j) => {
        if (
          i !== j &&
          schedule.start_hour &&
          s.start_hour &&
          !hasOneHourOfDifference(schedule.start_hour, s.start_hour)
        ) {
          {
            if (!objectErrors['available_days'])
              objectErrors['available_days'] = {};
            if (!objectErrors['available_days'][weekDay]) {
              objectErrors['available_days'][weekDay] = [];
            }
            objectErrors['available_days'][weekDay][i] = {
              start_hour: 'Existem horários conflitantes',
              end_hour: 'Existem horários conflitantes',
            };
            objectErrors['available_days'][weekDay][j] = {
              start_hour: 'Existem horários conflitantes',
              end_hour: 'Existem horários conflitantes',
            };
            newErrors = transformObjectToKeys(objectErrors);
            schedulingRef.current.setErrors(newErrors);
            isValid = false;
          }
        }
      });
    });
    verifyIfHasScheduleError(newErrors, schedulingRef, setScheduleError);
    return isValid;
  };

  const validateHourRangeSpecificList = (dayIndex, e) => {
    let isValid = true;
    if (!schedulingRef.current.getData()['specific_dates']) return;
    const schedules = schedulingRef.current.getData()['specific_dates'][
      dayIndex
    ]['schedules'];
    if (!schedules) return;
    let newErrors = e ? e : schedulingRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    Object.keys(newErrors).forEach((errorKey) => {
      if (newErrors[errorKey] === 'Dia com horários conflitantes') {
        newErrors[errorKey] = null;
        schedulingRef.current.setFieldError(errorKey, null);
      }
    });
    schedules.forEach((schedule, i) => {
      if (
        newErrors[[`[${dayIndex}].schedules[${i}].start_hour`]] ===
        'Dia horários conflitantes'
      ) {
        if (objectErrors[dayIndex]['schedules'][i]) {
          delete objectErrors[dayIndex]['schedules'][i];
          newErrors = transformObjectToKeys(objectErrors);
          schedulingRef.current.setErrors(transformObjectToKeys(objectErrors));
        }
      }
      schedules.find((s, j) => {
        if (
          i !== j &&
          schedule.start_hour &&
          s.start_hour &&
          !hasOneHourOfDifference(schedule.start_hour, s.start_hour)
        ) {
          {
            if (!objectErrors['specific_dates']) {
              objectErrors['specific_dates'] = [];
            }
            if (!objectErrors['specific_dates'][dayIndex]) {
              objectErrors['specific_dates'][dayIndex] = {};
            }
            if (!objectErrors['specific_dates'][dayIndex].schedules) {
              objectErrors['specific_dates'][dayIndex].schedules = [];
            }
            objectErrors['specific_dates'][dayIndex].schedules[i] = {
              start_hour: 'Dia com horários conflitantes',
              end_hour: 'Dia com horários conflitantes',
            };
            objectErrors['specific_dates'][dayIndex].schedules[j] = {
              start_hour: 'Dia com horários conflitantes',
              end_hour: 'Dia com horários conflitantes',
            };
            newErrors = transformObjectToKeys(objectErrors);
            schedulingRef.current.setErrors(newErrors);
            isValid = false;
          }
        }
      });
    });
    verifyIfHasScheduleSpecificListError(newErrors, dayIndex);
    return isValid;
  };

  const validateHourRangeSpecificModal = (e) => {
    let isValid = true;
    if (!specificDateRef.current.getData()['schedules']) return;
    const schedules = specificDateRef.current.getData()['schedules'];
    if (!schedules) return;
    let newErrors = e ? e : specificDateRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    Object.keys(newErrors).forEach((errorKey) => {
      if (newErrors[errorKey] === 'Existem horários conflitantes') {
        newErrors[errorKey] = null;
        specificDateRef.current.setFieldError(errorKey, null);
      }
    });
    schedules.forEach((schedule, i) => {
      if (
        newErrors[[`[${i}].start_hour`]] ===
        'Existem horários' + ' conflitantes'
      ) {
        if (objectErrors['schedules'][i]) {
          delete objectErrors['schedules'][i];
          newErrors = transformObjectToKeys(objectErrors);
          specificDateRef.current.setErrors(
            transformObjectToKeys(objectErrors)
          );
        }
      }
      schedules.find((s, j) => {
        if (
          i !== j &&
          schedule.start_hour &&
          s.start_hour &&
          !hasOneHourOfDifference(schedule.start_hour, s.start_hour)
        ) {
          {
            if (!objectErrors['schedules']) objectErrors['schedules'] = [];
            objectErrors['schedules'][i] = {
              start_hour: 'Existem horários conflitantes',
              end_hour: 'Existem horários conflitantes',
            };
            objectErrors['schedules'][j] = {
              start_hour: 'Existem horários conflitantes',
              end_hour: 'Existem horários conflitantes',
            };
            newErrors = transformObjectToKeys(objectErrors);
            specificDateRef.current.setErrors(newErrors);
            isValid = false;
          }
        }
      });
    });
    verifyIfHasScheduleError(
      newErrors,
      specificDateRef,
      setScheduleSpecificModalError
    );
    return isValid;
  };

  const validateWeekSchedule = (data) => {
    const hasAtLeastOneScheduleSelected =
      !!data?.available_days && !!Object.keys(data?.available_days)?.length > 0;
    if (!hasAtLeastOneScheduleSelected) {
      setScheduleError('Selecione ao menos um horário');
    }
    let hasInvalidTime = false;
    Object.keys(scheduleSelectedDays).forEach((day) =>
      Array.from({ length: scheduleSelectedDays[day].schedulesAmount }).forEach(
        (_, index) => {
          if (
            !validateSchedule(
              day,
              index,
              data?.available_days[day][index]?.start_hour
            )
          ) {
            hasInvalidTime = true;
          }
        }
      )
    );
    return hasAtLeastOneScheduleSelected && !hasInvalidTime;
  };

  const validateSpecificList = (data) => {
    if (!data) return true;
    let hasInvalidTime = false;
    data.forEach((day, indexSpecificDate) =>
      data[indexSpecificDate].schedules.forEach((schedule, scheduleIndex) => {
        if (
          !validateScheduleSpecificList(
            indexSpecificDate,
            scheduleIndex,
            schedule.start_hour
          )
        ) {
          hasInvalidTime = true;
        }
      })
    );
    return !hasInvalidTime;
  };

  const validateWeekScheduleSpecificDate = (data) => {
    let hasInvalidTime = false;
    Array.from({ length: scheduleAmountSpecificDate }).forEach((_, index) => {
      if (
        !validateScheduleSpecificDate(
          index,
          data?.schedules[index]?.start_hour,
          true
        )
      ) {
        hasInvalidTime = true;
      }
    });
    return !hasInvalidTime;
  };

  const payloadStepOne = (data) => {
    if (!data) return;
    return {
      staff: data.staff?.value,
      macro_areas: data.macro_areas?.map((area) => area.value),
      courses: data.courses?.map((course) => course.value),
      biography: data.biography,
      avatar: data.avatar,
      specialities: specialities,
    };
  };

  const payloadScheduling = (data) => {
    if (!data) return;
    const availableDays = data?.available_days
      ? Object.keys(data?.available_days)?.map((key) => {
          return { day: key, times: data?.available_days[key] };
        })
      : {};
    return {
      mentorship_modality: data.mentorship_modality?.map(
        (modality) => modality.value
      ),
      available_days: availableDays,
    };
  };

  const validateStepOne = async (field, value) => {
    try {
      const data = stepOneRef.current.getData();
      const schema = Yup.object().shape({
        staff: Yup.number('não é um número').required(
          'Este campo é obrigatório'
        ),
        biography: Yup.string().required('Este campo é obrigatório'),
        avatar: Yup.mixed()
          .test(
            'fileSize',
            'O arquivo é muito grande',
            (value) =>
              typeof value === 'string' ||
              !value ||
              (value && value.size <= 20 * 1024 * 1024) // 20MB
          )
          .test(
            'fileFormat',
            'Formato não suportado',
            (value) =>
              typeof value === 'string' ||
              !value ||
              (value &&
                ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type))
          ),
      });
      let validatedData;
      const payloadData = payloadStepOne(data);
      if (field) {
        payloadData[field] = value;
        validatedData = await schema.validateAt(field, payloadData, {
          abortEarly: false,
        });
        const errors = stepOneRef.current.getErrors();
        delete errors[field];
        stepOneRef.current.setErrors(errors);
      } else {
        stepOneRef.current.setErrors({});
        validatedData = await schema.validate(payloadData, {
          abortEarly: false,
        });
        if (!specialities.length) {
          setNoSpecialitiesError(true);
          return;
        }
      }
      return validatedData;
    } catch (err) {
      if (!field && !specialities.length) {
        setNoSpecialitiesError(true);
      }
      const validationErrors = stepOneRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepOneRef.current.setErrors(validationErrors);
      }
    }
  };

  const validateScheduling = async (field, value) => {
    try {
      const data = schedulingRef.current.getData();
      const schema = Yup.object().shape({
        mentorship_modality: Yup.array()
          .min(1, 'Este campo é obrigatório')
          .nullable()
          .required('Este campo é obrigatório'),
      });
      let validatedData;
      const payloadData = payloadScheduling(data);
      if (field) {
        payloadData[field] = value;
        validatedData = await schema.validateAt(field, payloadData, {
          abortEarly: false,
        });
        const errors = schedulingRef.current.getErrors();
        delete errors[field];
        schedulingRef.current.setErrors(errors);
      } else {
        const scheduleIsValid = validateWeekSchedule(data);
        const specificListIsValid = validateSpecificList(data.specific_dates);
        validatedData = await schema.validate(payloadData, {
          abortEarly: false,
        });
        if (!scheduleIsValid || !specificListIsValid) {
          return;
        }
        schedulingRef.current.setErrors({});
      }
      return validatedData;
    } catch (err) {
      const validationErrors = schedulingRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        schedulingRef.current.setErrors(validationErrors);
      }
    }
  };

  const validateAbsences = async (data = absencesRef.current.getData()) => {
    data.newAbsences = data.newAbsences?.filter(
      (absence) => absence.start_date !== null && absence.end_date !== null
    );
    try {
      const schema = Yup.object().shape({
        newAbsences: Yup.array().of(
          Yup.object().shape({
            start_date: Yup.date()
              .typeError('Este campo é obrigatório')
              .test(
                'is-null',
                'Este campo é obrigatório',
                function (start_date) {
                  if (this.parent.end_date) {
                    return !!start_date;
                  }
                  return true;
                }
              )
              .required('Este campo é obrigatório'),
            end_date: Yup.date('Este campo é obrigatório')
              .typeError('Este campo é obrigatório')
              .test(
                'is-greater',
                'A data final deve ser maior que a data inicial',
                function (end_date) {
                  if (this.parent.start_date) {
                    return end_date >= this.parent.start_date;
                  }
                  return true;
                }
              )
              .test('is-null', 'Este campo é obrigatório', function (end_date) {
                if (this.parent.start_date) {
                  return !!end_date;
                }
                return true;
              })
              .required('Este campo é obrigatório'),
          })
        ),
      });
      let validatedData = await schema.validate(data, {
        abortEarly: false,
      });
      absencesRef.current.setErrors({});
      return validatedData;
    } catch (err) {
      const validationErrors = absencesRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        absencesRef.current.setErrors(validationErrors);
      }
    }
  };

  const validateSpecificDate = async (field, value) => {
    try {
      const data = specificDateRef.current.getData();
      const schema = Yup.object().shape({
        date: Yup.date()
          .test('not-nul', 'Este campo é obrigatório', (value) => !!value)
          .nullable(),
      });
      let validatedData;
      if (field) {
        data[field] = value;
        validatedData = await schema.validateAt(field, data, {
          abortEarly: false,
        });
        const errors = specificDateRef.current.getErrors();
        delete errors[field];
        specificDateRef.current.setErrors(errors);
      } else {
        const scheduleIsValid = validateWeekScheduleSpecificDate(data);
        validatedData = await schema.validate(data, {
          abortEarly: false,
        });
        if (!scheduleIsValid) {
          return;
        }
        specificDateRef.current.setErrors({});
      }
      specificDateRef.current.setErrors({});
      return validatedData;
    } catch (err) {
      const validationErrors = specificDateRef?.current?.getErrors();
      if (validationErrors && err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        specificDateRef.current.setErrors(validationErrors);
      }
    }
  };

  const saveTabStepTwo = (tab) => {
    let data;
    if (tab === 'scheduling') {
      data = schedulingRef.current.getData();
      if (data.specific_dates) {
        data.specific_dates = data.specific_dates.map((specificDate, index) => {
          return {
            ...specificDate,
            date: formData.specific_dates[index].date,
          };
        });
      }
      setFormData({ ...formData, ...data });
    } else {
      data = absencesRef.current.getData();
      const firstAbsence = data?.absences ? data?.absences[0] : null;
      if (firstAbsence && firstAbsence.start_date && firstAbsence.end_date) {
        setFormData({ ...formData, ...data });
      } else {
        const newFormData = { ...formData };
        delete newFormData.absences;
        setFormData(newFormData);
      }
    }
  };

  const handleStepTwoChangeTab = async (tab) => {
    let isValid = false;
    if (tab === 'scheduling') {
      if (await validateAbsences()) {
        saveTabStepTwo('absences');
        isValid = true;
      }
    } else {
      if (await validateScheduling()) {
        saveTabStepTwo('scheduling');
        isValid = true;
      }
    }
    if (isValid) {
      setStepTwoSelectedTab(tab);
    }
  };

  const handleChangeStep = async (step) => {
    let data;
    switch (step) {
      case 1:
        saveTabStepTwo(stepTwoSelectedTab);
        setStepTwoSelectedTab('scheduling');
        setCurrentStep(1);
        break;
      case 2:
        if (await validateStepOne()) {
          data = stepOneRef.current.getData();
          setFormData({ ...formData, ...data, specialities });
          setCurrentStep(2);
        }
        break;
      default:
        break;
    }
  };

  function payloadSpecificDates(data) {
    if (!data) return [];
    return data.reduce((acc, specificDate) => {
      const newSpecificDate = specificDate.schedules.map((schedule) => {
        return {
          date: specificDate.date,
          start_hour: schedule.start_hour,
          end_hour: schedule.end_hour,
        };
      });
      return [...acc, ...newSpecificDate];
    }, []);
  }

  function finalPayload(data) {
    return {
      ...payloadStepOne(data),
      ...payloadScheduling(data),
      absences: data.newAbsences?.filter(
        (absence) => absence.start_date !== null && absence.end_date !== null
      ),
    };
  }

  async function updateMentorImage(mentorId, avatar) {
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);
      return await mentorshipApi.updateMentorImage(mentorId, formData);
    } catch (e) {
      customSnackbar('error', 'Ocorreu um erro ao salvar a imagem do mentor.');
    }
  }

  async function updateSpecificDates(mentorId, specificDates) {
    try {
      await mentorshipApi.createSpecificDates({
        mentor: mentorId,
        specific_dates: payloadSpecificDates(specificDates),
      });
    } catch (e) {
      customSnackbar(
        'Ocorreu um error ao atualizar as datas específicas',
        'error'
      );
    }
  }

  async function createAbsences(mentorId, absences) {
    try {
      return await mentorshipApi.createAbsences({
        mentor: mentorId,
        absences: absences,
      });
    } catch (e) {
      customSnackbar('Ocorreu um error ao atualizar as ausências', 'error');
    }
  }

  async function deleteAbsences(absencesIds) {
    try {
      return await mentorshipApi.deleteAbsences(absencesIds);
    } catch (e) {
      customSnackbar('Ocorreu um error ao atualizar as ausências', 'error');
    }
  }

  const handleSubmit = async () => {
    let finalData;
    setIsSubmitting(true);
    if (stepTwoSelectedTab === 'scheduling') {
      if (await validateScheduling()) {
        const schedulingData = schedulingRef.current.getData();
        if (schedulingData.specific_dates) {
          schedulingData.specific_dates = schedulingData.specific_dates.map(
            (specificDate, index) => {
              return {
                ...specificDate,
                date: formData.specific_dates[index].date,
              };
            }
          );
        }
        finalData = { ...formData, ...schedulingData };
      } else {
        setIsSubmitting(false);
        return;
      }
    } else {
      if (await validateAbsences()) {
        const absencesData = absencesRef.current.getData();
        finalData = { ...formData, ...absencesData };
      } else {
        setIsSubmitting(false);
        return;
      }
    }
    let responseMentor;
    const finalPayloadData = finalPayload(finalData);
    try {
      if (isEdit) {
        finalPayloadData.id = mentor.id;
        responseMentor = await mentorshipApi.updateMentor(finalPayloadData);
      } else {
        responseMentor = await mentorshipApi.createMentor(finalPayloadData);
      }
    } catch (e) {
      customSnackbar('Ocorreu um erro ao salvar o mentor', 'error');
      setIsSubmitting(false);
      return;
    }
    if (responseMentor) {
      customSnackbar('success', 'O mentor foi criado com sucesso');
      const requests = [];
      if (finalData.avatar instanceof File) {
        requests.push(() =>
          updateMentorImage(responseMentor.id, finalData.avatar)
        );
      }
      const specificDates = finalData?.specific_dates;
      if (!isEdit && specificDates && specificDates?.length > 0) {
        requests.push(() =>
          updateSpecificDates(responseMentor.id, finalData.specific_dates)
        );
      }
      const absences = finalPayloadData.absences;
      if (absencesForDelete && absencesForDelete.length > 0) {
        requests.push(() => deleteAbsences(absencesForDelete));
      }
      if (absences && absences?.length > 0) {
        requests.push(() => createAbsences(responseMentor.id, absences));
      }
      const responses = await Promise.all(requests.map((request) => request()));
      if (isEdit) {
        const newAbsences = responses[responses.length - 1]?.absences || [];
        const newMentor = mentorAdapter(
          await mentorshipApi.getMentorDetails(mentor.id)
        );
        newMentor.specific_dates = specificDates;
        newMentor.absences = [...mentor.absences, ...newAbsences].filter(
          (absence) => !absencesForDelete.includes(absence.id)
        );
        setMentor(newMentor);
      }
      handleClose();
    } else {
      customSnackbar('Ocorreu um erro ao criar o mentor.', 'error');
    }
    setIsSubmitting(false);
  };

  const validateScheduleSpecificDate = (index, time) => {
    let isValid = true;
    let newErrors = specificDateRef.current.getErrors();
    const objectErrors = transformKeysToObject(newErrors);
    if (objectErrors['schedules'] && objectErrors['schedules'][index]) {
      objectErrors['schedules'][index] = {
        start_hour: null,
        end_hour: null,
      };
      specificDateRef.current.setFieldError(
        `schedules[${index}].start_hour`,
        null
      );
      specificDateRef.current.setFieldError(
        `schedules[${index}].end_hour`,
        null
      );
    }
    const maskedValue = maskTimeBlur(time);
    const [hours, minutes] = maskedValue.split(':');
    if (!maskedValue || parseInt(hours) > 23 || parseInt(minutes) > 59) {
      if (!objectErrors['schedules']) {
        objectErrors['schedules'] = [];
      }
      objectErrors['schedules'][index] = {
        start_hour: 'Existem horários inválidos',
        end_hour: 'Existem horários inválidos',
      };
      specificDateRef.current.setFieldError(
        `schedules[${index}].start_hour`,
        'Existem horários inválidos'
      );
      specificDateRef.current.setFieldError(
        `schedules[${index}].end_hour`,
        'Existem horários inválidos'
      );
      isValid = false;
    }
    const isValidHourRange = validateHourRangeSpecificModal(
      transformObjectToKeys(objectErrors)
    );
    return isValid && isValidHourRange;
  };

  const handleSubmitSpecificDate = async (dayIndex) => {
    const validatedData = await validateSpecificDate();
    if (validatedData) {
      const newFormData = { ...formData };
      if (!newFormData.specific_dates) {
        newFormData.specific_dates = [];
      }
      if (dayIndex >= 0) {
        newFormData.specific_dates[dayIndex] = validatedData;
      } else {
        newFormData.specific_dates.push(validatedData);
      }
      setFormData(newFormData);
      if (isEdit) {
        await mentorshipApi.createSpecificDates({
          mentor: mentor.id,
          specific_dates: payloadSpecificDates([validatedData]),
        });
      }
      return true;
    }
    return false;
  };

  const updateSpecificDatesFormData = (specificsDates) => {
    setFormData({ ...formData, specific_dates: specificsDates });
  };

  const addToAbsencesForDeleteList = (absenceId) => {
    if (!absencesForDelete.includes(absenceId)) {
      setAbsencesForDelete([...absencesForDelete, absenceId]);
    }
  };

  return (
    <CreateMentorContext.Provider
      value={{
        isOpen,
        currentStep,
        stepOneRef,
        schedulingRef,
        absencesRef,
        specificDateRef,
        handleChangeStep,
        handleClose,
        formData,
        stepTwoSelectedTab,
        handleStepTwoChangeTab,
        validateStepOne,
        validateScheduling,
        validateScheduleSpecificDate,
        scheduleError,
        scheduleSpecificModalError,
        scheduleSpecificListError,
        setScheduleSpecificModalError,
        scheduleSelectedDays,
        setScheduleSelectedDays,
        daysOfWeekMap,
        validateSchedule,
        validateHourRange,
        validateWeekSchedule,
        newAbsencesAmount,
        handleSubmit,
        validateAbsences,
        hasOneHourOfDifference,
        handleSubmitSpecificDate,
        validateHourRangeSpecificModal,
        validateHourRangeSpecificList,
        scheduleAmountSpecificDate,
        setScheduleAmountSpecificDate,
        validateScheduleSpecificList,
        updateSpecificDates: updateSpecificDatesFormData,
        options,
        setOptions,
        specialities,
        setSpecialities,
        noSpecialitiesError,
        setNoSpecialitiesError,
        isSubmitting,
        isEdit,
        mentor,
        setMentor,
        setFormData,
        finalPayload,
        absencesForDelete,
        addToAbsencesForDeleteList,
        filteredAbsences,
        incrementAbsenceAmount,
        decrementAbsenceAmount,
      }}
    >
      {children}
    </CreateMentorContext.Provider>
  );
}

export function useCreateMentor() {
  return useContext(CreateMentorContext);
}
