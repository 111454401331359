import React, { useContext, useState, Fragment, useRef } from 'react';
import { MatrixContext } from '../../contexts/MatrixContext';
import BaseModal from '../baseModal';
import Header from './header';
import RegisterModal from './registerMatrixModal';
import withScrolling from 'react-dnd-scrolling';
import ListDisciplines from './listDisciplinesAndFilter';
import ListMatrices from './listMatrices';

import {
  Wrapper,
  ContainerList,
  ContainerMatrix,
  MatricesSkeleton,
} from './style';
import ModalEditAndDetails from './modalEditAndDetails';
import { SkeletonAnimation } from 'components/JobsFinishDialog/Step2/styled';

export default function ActiveMatrices() {
  const {
    selectedCourse,
    modalIsOpenName,
    setModalIsOpenName,
    allDisciplinesCourse,
    loading,
  } = useContext(MatrixContext);

  const divRef = useRef(null);

  const [modalDetailAndEditProps, setModalDetailAndEditProps] = useState({
    data: {},
    isEdit: false,
  });

  const handleClickCloseModal = () => {
    return setModalIsOpenName(null);
  };

  const currentModalToOpen = {
    registerMatrix: (
      <RegisterModal
        courseId={selectedCourse}
        handleClickClose={handleClickCloseModal}
      />
    ),
    editAndInformation: (
      <ModalEditAndDetails
        handleClickClose={handleClickCloseModal}
        modalDetailAndEditProps={modalDetailAndEditProps}
        setModalDetailAndEditProps={setModalDetailAndEditProps}
      />
    ),
  };

  const returnCurrentModalOpen = () => {
    if (modalIsOpenName) {
      return <BaseModal>{currentModalToOpen[modalIsOpenName]}</BaseModal>;
    }
    return null;
  };

  const ScrollingComponent = withScrolling('div');

  const returnListDisciplines = () => {
    if (loading) {
      return <SkeletonAnimation></SkeletonAnimation>;
    }
    if (allDisciplinesCourse.length <= 0) {
      return (
        <p>
          Oops! No momento, não existem disciplinas/unidades curriculares
          cadastradas.
        </p>
      );
    }

    return (
      <Fragment>
        <Header count={allDisciplinesCourse.length} />
        <ListDisciplines />
      </Fragment>
    );
  };

  return (
    <ScrollingComponent>
      <Wrapper ref={divRef}>
        {returnCurrentModalOpen()}
        <ContainerList>{returnListDisciplines()}</ContainerList>

        <ContainerMatrix>
          {loading ? (
            <MatricesSkeleton></MatricesSkeleton>
          ) : (
            <ListMatrices
              modalDetailAndEditProps={modalDetailAndEditProps}
              setModalDetailAndEditProps={setModalDetailAndEditProps}
            />
          )}
        </ContainerMatrix>
      </Wrapper>
    </ScrollingComponent>
  );
}
