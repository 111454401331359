export default function JobBagIcon({ color = '#FFF', size = 12 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49963 1.99986H8.94957C8.71051 0.836693 7.68726 0.00140621 6.49972 0H5.49967C4.31213 0.00140621 3.28888 0.836693 3.04983 1.99986H2.49977C1.12004 2.0015 0.00164057 3.1199 0 4.49986V9.49963C0.00164057 10.8796 1.12004 11.998 2.5 11.9996H9.49986C10.8798 11.998 11.9982 10.8796 11.9999 9.49963V4.49986C11.9982 3.1199 10.8798 2.0015 9.49986 1.99986H9.49963ZM5.49967 0.999813H6.49972C7.13298 1.00239 7.69664 1.40269 7.90757 1.99986H4.09159C4.30276 1.40269 4.86618 1.00263 5.49944 0.999813H5.49967ZM2.49977 2.99967H9.49963C10.3281 2.99967 10.9996 3.67114 10.9996 4.49963V5.99958H1.00005V4.49963C1.00005 3.67114 1.67151 2.99967 2.5 2.99967H2.49977ZM9.49963 10.9993H2.5C1.67151 10.9993 1.00005 10.3279 1.00005 9.49939V6.99939H5.49991V7.4993C5.49991 7.77538 5.72373 7.99921 5.99981 7.99921C6.2759 7.99921 6.49972 7.77538 6.49972 7.4993V6.99939H10.9996V9.49939C10.9996 10.3279 10.3281 10.9993 9.49963 10.9993Z"
        fill={color}
      />
    </svg>
  );
}
