import React, { useContext, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import JobHeaderTab, { Container, FilterContainer } from './styled';
import { JobOffersFilters } from '../FilterModal';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import { formatNumber } from 'utils/formatNumber';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';

const TabJobStudent = (props) => {
  const {
    classes,
    activeTab,
    handleTabChange,
    filterOptions,
    showFilter = false,
    filterState,
    setFilterState,
    appliedFilters,
    filterQS,
    universityColor,
    setQueryFilter,
    setAppliedFilters,
    filterOptionsOtherExternal,
  } = props;

  const { countJobs, setCountJobs } = useContext(JobOffersContext);

  const fetchCountJobOffers = async () => {
    const response = await JobOffersApi.geCountJobOffers();
    setCountJobs(response?.data);
  };

  useEffect(() => {
    fetchCountJobOffers();
  }, []);

  return (
    <Container universityColor={universityColor}>
      <AppBar position="static" className={classes.appBarHolder}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          scrollButtons="on"
          scrollable
          universityColor={universityColor}
          classes={{
            indicator: 'custom-indicator',
            scrollButtons: classes.scrollButtons,
            flexContainer: classes.borderContainer,
          }}
        >
          <Tab
            universityColor={universityColor}
            style={{ zIndex: 2 }}
            label={`Vagas Exclusivas (${formatNumber(
              countJobs?.exclusive_offers || '0'
            )})`}
            className={classes.tabButton}
          />

          <Tab
            universityColor={universityColor}
            style={{ zIndex: 2 }}
            label={`Vagas Externas (${formatNumber(
              countJobs?.external_offers || '0'
            )})`}
            className={classes.tabButton}
          />

          <Tab
            universityColor={universityColor}
            style={{ zIndex: 2 }}
            label={`Candidaturas (${formatNumber(
              countJobs?.applications || '0'
            )})`}
            className={classes.tabButton}
          />

          <Tab
            universityColor={universityColor}
            style={{ zIndex: 2 }}
            label={`Favoritadas (${formatNumber(
              countJobs?.favorite_offers || '0'
            )})`}
            className={classes.tabButton}
          />
        </Tabs>
        {showFilter && (
          <FilterContainer>
            <JobOffersFilters
              activeTab={activeTab}
              resetFilterQS={filterQS}
              filterState={filterState}
              setAppliedFilters={setAppliedFilters}
              setFilterState={setFilterState}
              appliedFilters={appliedFilters}
              setQueryFilter={setQueryFilter}
              filterOptions={filterOptions}
              filterOptionsOtherExternal={filterOptionsOtherExternal}
              setCountJobs={setCountJobs}
            />
          </FilterContainer>
        )}
      </AppBar>
    </Container>
  );
};

TabJobStudent.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};

export default withStyles(JobHeaderTab)(TabJobStudent);
