import React from 'react';
import {
  ActionsContainer,
  ImageSkeleton,
  SkeletonBox,
  StudentData,
  StudentInfo,
  StudentTileContainer,
} from './styles';
import { useCsm } from '../../contexts/CsmContext';
import { StudentCategory } from '../../resources/defaultStudentCategories';

export function StudentTileSkeleton() {
  const { activeCategory } = useCsm();
  return (
    <StudentTileContainer>
      {activeCategory !== StudentCategory.INACTIVE && (
        <SkeletonBox width="16px" height="16px" />
      )}
      <StudentData>
        <ImageSkeleton />
        <StudentInfo>
          <SkeletonBox width="300px" height="21px" marginBottom="0.5rem" />
          <SkeletonBox width="75px" height="12px" marginTop={'-12px'} />
          {activeCategory !== StudentCategory.INACTIVE && (
            <SkeletonBox width="120px" height="14px" />
          )}
          <SkeletonBox width="420px" height="14px" />
          <div className="phone-email">
            <SkeletonBox width="120px" height="14px" />
            <SkeletonBox width="220px" height="14px" />
          </div>
          {activeCategory !== StudentCategory.INACTIVE && (
            <ActionsContainer>
              <SkeletonBox width="150px" height="32px" />
              <SkeletonBox width="237px" height="32px" />
            </ActionsContainer>
          )}
        </StudentInfo>
      </StudentData>
    </StudentTileContainer>
  );
}
