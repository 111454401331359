// ##############################
// // // Dashboard View styles
// #############################

import { dnaLightGreen } from 'assets/jss/material-dashboard-pro-react';

const cardChart = (theme) => ({
  progressArea: {
    height: '100%',
    paddingBottom: '1em',
    paddingTop: '1em',
    textAlign: 'center',
    width: '100%',
  },
  progress: {
    position: 'relative',
    zIndex: '2',
  },
  cardIcon: {
    '& green': {
      backgroundColor: 'red',
    },
  },
  progressFixed: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    color: '#efeeec',
  },
  progressNumber: {
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    top: '-78px',
    bottom: 0,
    height: '40px',
    fontSize: '1.7em',
  },
  cardSubtitle: {},
  buttonFluid: {
    display: 'flex',
    maxWidth: '300px',
    justifyContent: 'space-between',
    borderRadius: '2em',
    color: '#FFF',
    '&:hover': {
      backgroundColor: dnaLightGreen,
    },
  },
  green: {
    '& $cardSubtitle': {
      color: '#1cb45e',
    },
    '& $cardIcon': {
      backgroundColor: '#1cb45e',
      borderRadius: '5px',
      marginRight: '1em',
      '& img': {
        width: 'auto',
      },
    },
    '& $buttonFluid': {
      backgroundColor: '#1cb45e',
    },
    '& $progressArea': {
      color: '#1cb45e',
    },
    '& $progress, $certButton': {
      color: '#1cb45e',
    },
  },
  purple: {
    '& $cardIcon': {
      backgroundColor: '#692a71',
      borderRadius: '5px',
      marginRight: '1em',
    },
    '& $buttonFluid': {
      '&$disabled': {
        backgroundColor: '#F5F5F5',
      },
      backgroundColor: '#692a71',
    },
    '& $progress, $certButton': {
      color: '#692a71',
    },
    '& $progressArea': {
      color: '#692a71',
    },
  },
  blue: {
    '& $cardIcon': {
      backgroundColor: '#0183ad',
      borderRadius: '5px',
      marginRight: '1em',
    },
    '& $buttonFluid': {
      '&$disabled': {
        backgroundColor: '#F5F5F5',
      },
      backgroundColor: '#0183ad',
    },
    '& $progress, $certButton': {
      color: '#0183ad',
    },
    '& $progressArea': {
      color: '#0183ad',
    },
  },
  yellow: {
    '& $cardIcon': {
      backgroundColor: '#eb9b00',
      borderRadius: '5px',
      marginRight: '1em',
    },
    '& $buttonFluid': {
      '&$disabled': {
        backgroundColor: '#F5F5F5',
      },
      backgroundColor: '#eb9b00',
    },
    '& $progress, $certButton': {
      color: '#eb9b00',
    },
    '& $progressArea': {
      color: '#eb9b00',
    },
  },
  certButton: {
    marginTop: 10,
  },
  hidden: {
    visibility: 'hidden',
  },
  gridReset: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  disabled: {
    backgroundColor: '#cecece',
    color: 'rgba(0, 0, 0, 0.26) !important',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
});

export default cardChart;
