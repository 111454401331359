import styled from 'styled-components';

export const ContainerTitles = styled.div``;

export const H5 = styled.h5`
  font-weight: 600;
  font-size: 15px;
  color: #6d6d6d;
`;

export const P = styled.p`
  margin-top: -5px;
`;
