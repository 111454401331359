import React, { useContext } from 'react';
import { shape, func, bool } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import BaseLayoutContext from 'contexts/base-layout';

const LiveSchema = Yup.object().shape({
  questionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
});

const LiveEightForm = (props) => {
  const { classes, answered, initialValues, applyLiveQuiz, metadata } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <GridContainer justify="center" className={classes.paddingReset}>
      <GridItem className={`${classes.paddingReset} ${classes.textAlgC}`}>
        <Formik
          enableReinitialize
          validationSchema={LiveSchema}
          validateOnChange={false}
          validade
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            await applyLiveQuiz(values);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <List className={classes.list}>
                {/* Question 1 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>1 -</strong> A primeira coisa a se fazer para
                    aproveitar nossa rede de conexões é reconhecê-la. Para isso,
                    tente pensar no máximo de pessoas que você conhece e que
                    poderiam te apoiar ao longo de sua carreira.
                    <br />
                    <br />
                    Tente pensar nessas pessoas dividindo-as em 3 categorias:
                    <br />
                    <br />
                    a) pessoas que você conhece por toda a vida
                    <br />
                    b) pessoas que estudaram/trabalharam com você
                    <br />
                    c) pessoas que você admira.
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionOne && touched.questionOne,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionOne"
                    variant="filled"
                    id="questionOne"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionOne && touched.questionOne}
                    value={values.questionOne}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionOne &&
                      `Sua resposta tem ${values.questionOne.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionOne && touched.questionOne ? (
                    <FormHelperText>{errors.questionOne}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 2 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>2 -</strong> A segunda coisa a se fazer para
                    aproveitar nossa rede de conexões é apoiá-la. Para isso,
                    tente pensar no que você tem a oferecer para cada pessoa
                    identificada no exercício 1.
                    <br />
                    <br />
                    Como você pode auxiliá-las a resolverem seus problemas?{' '}
                    <br />
                    O que você pode trocar com elas? <br />E o que você pode
                    ensinar a elas?
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionTwo && touched.questionTwo,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionTwo"
                    variant="filled"
                    id="questionTwo"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionTwo && touched.questionTwo}
                    value={values.questionTwo}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionTwo &&
                      `Sua resposta tem ${values.questionTwo.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionTwo && touched.questionTwo ? (
                    <FormHelperText>{errors.questionTwo}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 3 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>3 -</strong> A terceira coisa a se fazer para
                    aproveitar nossa rede de conexões é ampliá-la. Então, tente
                    identificar quem seriam as 5 pessoas que você mais desejaria
                    conviver e que você acredita que te levariam a se tornar
                    ainda mais sua melhor versão?
                    <br />
                    <br />
                    O que essas pessoas têm em comum? <br />O que elas te
                    oferecem?
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionThree && touched.questionThree,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionThree"
                    variant="filled"
                    id="questionThree"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionThree && touched.questionThree}
                    value={values.questionThree}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionThree &&
                      `Sua resposta tem ${values.questionThree.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionThree && touched.questionThree ? (
                    <FormHelperText>{errors.questionThree}</FormHelperText>
                  ) : null}
                </ListItem>
                {!answered && (
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={
                          isSubmitting || Object.keys(values).length !== 3
                        }
                        type="submit"
                        style={{
                          width: '250px',
                          borderRadius: '5px',
                          backgroundColor: universityColor,
                        }}
                        round
                      >
                        Enviar respostas
                      </Button>
                    </div>
                  </ListItem>
                )}
              </List>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

LiveEightForm.propTypes = {
  classes: shape({}).isRequired,
  answered: bool.isRequired,
  applyLiveQuiz: func.isRequired,
  initialValues: shape({}).isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(LiveEightForm);
