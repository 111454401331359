import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Content = styled.div`
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;
  padding: 25px;

  .left-side {
    width: 60%;
    gap: 8px;
    padding: 0px 25px;
    display: flex;
    flex-direction: column;
  }

  .right-side {
    width: 40%;
    padding: 0px 45px;
    gap: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
  }

  .align-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 1px;
  }

  .container-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 16px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .match-animation {
    width: 90px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BaseAnimation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #e7e7e7;
  background-image: linear-gradient(
    120deg,
    #e7e7e7 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e7e7e7 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  height: 33px;
  border-radius: 5px;
  width: 100%;
  max-width: 185px;
`;

export const Details = styled(BaseAnimation)`
  width: 100%;
  height: 23px;
  max-width: ${({ mWidth }) => mWidth || '305px'};
`;

export const Button = styled(BaseAnimation)`
  height: 45px;
  width: 160px;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: #cdcdcd;
  height: 110px;
`;

export const MatchAnimation = styled(BaseAnimation)`
  width: 90px;
  height: 90px;
  border-radius: 200px;

  position: relative;

  .ghost {
    width: 65px;
    height: 65px;
    border-radius: 200px;
    background-color: #fff;

    position: absolute;
    top: 13px;
    left: 12px;
  }
`;
