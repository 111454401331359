const colorOrRace = [
  { id: 'black', value: 'black', label: 'Cor Preta' },
  { id: 'indigenous', value: 'indigenous', label: 'Raça/Etnia Indígena' },
];

const genderIndentity = [
  {
    id: 'transgender_man',
    value: 'transgender_man',
    label: 'Homem transgênero',
  },
  {
    id: 'transgender_woman',
    value: 'transgender_woman',
    label: 'Mulher transgênero',
  },
];

const disability = [
  {
    id: 'visual_disability',
    value: 'visual_disability',
    label: 'Deficiência Visual',
  },
  {
    id: 'hearing_disability',
    value: 'hearing_disability',
    label: 'Deficiência Auditiva',
  },
  {
    id: 'physical_disability',
    value: 'physical_disability',
    label: 'Deficiência Física',
  },
  {
    id: 'mental_disability',
    value: 'mental_disability',
    label: 'Deficiência Mental',
  },
  {
    id: 'intellectual_disability',
    value: 'intellectual_disability',
    label: 'Deficiência Intelectual',
  },
];

const jobType = [
  { value: 'full_time_job', label: 'Emprego em tempo integral' },
  { value: 'part_time_job', label: 'Emprego de meio período' },
  { value: 'mandatory_internship', label: 'Estágio obrigatório' },
  { value: 'non_mandatory_internship', label: 'estágio não obrigatório' },
  { value: 'apprentice', label: 'jovem aprendiz' },
  { value: 'trainee', label: 'Trainee' },
  { value: 'contract', label: 'Contratado' },
  { value: 'temporary', label: 'Temporário' },
  { value: 'voluntary', label: 'Voluntário' },
  { value: 'other', label: 'Outro' },
];
const publicationType = [
  { value: 'email', label: 'E-mail de direcionamento' },
  { value: 'link', label: 'Link de direcionamento' },
];

export { colorOrRace, genderIndentity, disability, jobType, publicationType };
