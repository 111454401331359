import React, { Fragment, useContext, useEffect } from 'react';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import PublishedOpportunitiesEngine from '../PublishedOpportunities/PublishedOpportunitiesEngine';

import { useOpportunitiesAPICalls } from '../../hooks/apiCallsOpportunities';

export default function TabTwo({
  handleIsLoadingState,
  setActiveTab,
  activeTab,
  defaultCompetence,
  educationalGroup,
}) {
  const {
    setFormData,
    setSelectOption,
    setOptionsId,
    setStep,
    setModalIsOpen,
    setModalContent,
  } = useContext(OportunitiesContext);

  useEffect(() => {
    setModalIsOpen(false);
    setModalContent();
    setDefaultCompetence();
    setSelectOption('');
    setOptionsId('');
    setStep(0);
  }, [activeTab]);

  const setDefaultCompetence = () => {
    if (defaultCompetence) {
      return setFormData({
        competences_specialities: [
          { label: defaultCompetence.label, value: defaultCompetence.value },
        ],
      });
    }
    return setFormData({});
  };

  return (
    <Fragment>
      <PublishedOpportunitiesEngine
        setActiveTab={setActiveTab}
        handleIsLoadingState={handleIsLoadingState}
        educationalGroup={educationalGroup}
      />
    </Fragment>
  );
}
