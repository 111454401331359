import React, { useState, useEffect, Fragment } from 'react';
import { object, func, array, string, number, bool } from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';
import dragDropTestStyle from 'assets/jss/material-dashboard-pro-react/views/dragDropTestStyle';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import DragDrop from './DragDrop';
import Nav from './PaginationBullets';
import { Button } from '@material-ui/core';
import { NavigateBefore, NavigateNext, Check } from '@material-ui/icons';
import FinallyTests from 'components/SaveButtonsTests/FinallyTests';

import { FooterText } from './styles';

const PersonalityTest = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([[]]);
  const [currentStep, setCurrentStep] = useState(1);
  const [testData, setTestData] = useState(1);

  const {
    classes,
    testOptions,
    fixedOptions,
    headerText,
    subHeaderText,
    footerText,
    totalSteps,
    snackbarErrorMessage,
    optionsPerPage,
    openSnackbar,
    handleTestOptions,
    sendTestData,
    changeSourceEnable,
    destinationLabel,
  } = props;

  let arrayIndex = currentStep;
  arrayIndex--;

  const handleStepChange = (page) => {
    let newCurrentStep = parseInt(page);
    if (
      selectedOptions[arrayIndex].length < optionsPerPage &&
      newCurrentStep > currentStep
    ) {
      openSnackbar(snackbarErrorMessage, 'error');
      return;
    }
    setCurrentStep(newCurrentStep);
  };

  const handleOptionsSource = (newTestOptions) => {
    handleTestOptions(newTestOptions);
  };

  const handleSelectedOptions = (options, index) => {
    let newSelectedOptions = [...selectedOptions];

    if (newSelectedOptions.length === 1 && testOptions.length !== 1) {
      for (let i = 1; i < testOptions.length; i++) {
        newSelectedOptions.push([]);
      }
    }
    newSelectedOptions[index] = options;
    setSelectedOptions(newSelectedOptions);
  };

  const defineTestData = (optionsCount) => {
    let newTestData = {};
    for (let i = 1; i <= optionsCount; i++) {
      newTestData[i] = [];
    }

    setTestData(newTestData);
  };

  const handleTestData = () => {
    if (selectedOptions[arrayIndex].length !== optionsPerPage) {
      openSnackbar(
        'Você deve selecionar suas características antes de finalizar!',
        'error'
      );
      return;
    }

    let newTestData = { ...testData };

    selectedOptions.map((page) => {
      page.map((item, index) => {
        let position = index;
        position++;

        newTestData[position].push(item.id);
      });
    });

    sendTestData(newTestData);
  };

  useEffect(() => {
    defineTestData(optionsPerPage);
  }, []);

  return (
    <GridContainer
      direction="column"
      alignItems="center"
      className={classes.grid}
    >
      <GridItem xs md={10} sm={10} lg={8}>
        <h4>{headerText}</h4>
        <p>{subHeaderText}</p>
      </GridItem>
      {testOptions.length > 1 && totalSteps > 1 && (
        <GridItem xs md={10} sm={10} lg={8}>
          <Nav
            classes={classes}
            totalSteps={totalSteps}
            goToStep={(id) => handleStepChange(id)}
            currentStep={currentStep}
          />
        </GridItem>
      )}
      <GridItem xs md={10} sm={10} lg={8} className={classes.grid}>
        <Card className={classes.card}>
          <CardBody>
            {testOptions.length > 0 && (
              <Fragment>
                {fixedOptions && fixedOptions.length > 0 && (
                  <ul className={classes.descriptionList}>
                    {fixedOptions[arrayIndex].map((option) => (
                      <li key={option.id}>
                        <strong>{option.option}: </strong>
                        {option.description}
                      </li>
                    ))}
                  </ul>
                )}
                <DragDrop
                  testOptions={testOptions}
                  selectedOptions={selectedOptions}
                  index={arrayIndex}
                  updateSource={handleOptionsSource}
                  optionSelect={handleSelectedOptions}
                  positionsCount={optionsPerPage}
                  changeSourceEnable={changeSourceEnable || false}
                  destinationLabel={destinationLabel || 'Adjetivo'}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem
        xs
        md={10}
        sm={10}
        lg={8}
        className={`${classes.grid} ${classes.navigationButtons}`}
      >
        <Button
          onClick={() => {
            let newCurrentStep = currentStep;
            newCurrentStep--;

            handleStepChange(newCurrentStep);
          }}
          className={classes.defaultButton}
          disabled={currentStep === 1}
        >
          <NavigateBefore /> Anterior
        </Button>
        {currentStep === totalSteps ? (
          <FinallyTests handleTestData={handleTestData} />
        ) : (
          //finalizar testes, material-ui
          <Button
            onClick={() => {
              let newCurrentStep = currentStep;
              newCurrentStep++;

              handleStepChange(newCurrentStep);
            }}
          >
            Próximo <NavigateNext />
          </Button>
        )}
      </GridItem>
      <div>{footerText && <FooterText>{footerText}</FooterText>}</div>
    </GridContainer>
  );
};

PersonalityTest.propTypes = {
  classes: object,
  openSnackbar: func.isRequired,
  testOptions: array.isRequired,
  fixedOptions: array,
  headerText: string,
  subHeaderText: string,
  footerText: string,
  totalSteps: number,
  snackbarErrorMessage: string.isRequired,
  optionsPerPage: number,
  handleTestOptions: func.isRequired,
  sendTestData: func.isRequired,
  changeSourceEnable: bool,
  destinationLabel: string,
};

export default withStyles(dragDropTestStyle)(PersonalityTest);
