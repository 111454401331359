import React from 'react';
import { shape, func, bool } from 'prop-types';

import { FastField } from 'formik';

import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

const StepFourForm = (props) => {
  const {
    classes,
    answered,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    metadata,
  } = props;

  return (
    <List className={classes.list}>
      {/* Question 1 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>1</strong> - Como você pode adaptar a sua rotina para focar
          ainda mais em seus interesses e paixões? Isso pode te deixar mais
          próximo de viver seu ideal de sucesso?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageFourQuestionOne && touched.pageFourQuestionOne,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageFourQuestionOne"
          variant="filled"
          id="pageFourQuestionOne"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageFourQuestionOne && touched.pageFourQuestionOne}
          value={values.pageFourQuestionOne}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageFourQuestionOne &&
            `Sua resposta tem ${values.pageFourQuestionOne.length} caracteres.`}
        </FormHelperText>
        {errors.pageFourQuestionOne && touched.pageFourQuestionOne ? (
          <FormHelperText>{errors.pageFourQuestionOne}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 2 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>2</strong> - O que você aprendeu com seus 3 últimos erros,
          falhas ou fracassos? E o que eles dizem sobre você?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageFourQuestionTwo && touched.pageFourQuestionTwo,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageFourQuestionTwo"
          variant="filled"
          id="pageFourQuestionTwo"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageFourQuestionTwo && touched.pageFourQuestionTwo}
          value={values.pageFourQuestionTwo}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageFourQuestionTwo &&
            `Sua resposta tem ${values.pageFourQuestionTwo.length} caracteres.`}
        </FormHelperText>
        {errors.pageFourQuestionTwo && touched.pageFourQuestionTwo ? (
          <FormHelperText>{errors.pageFourQuestionTwo}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 3 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>3</strong> - Pense em 3 pessoas que você admira por sua
          história de sucesso. O que a história delas ensina sobre determinação
          e que você pode aplicar na sua vida agora ?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageFourQuestionThree && touched.pageFourQuestionThree,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageFourQuestionThree"
          variant="filled"
          id="pageFourQuestionThree"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageFourQuestionThree && touched.pageFourQuestionThree}
          value={values.pageFourQuestionThree}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageFourQuestionThree &&
            `Sua resposta tem ${values.pageFourQuestionThree.length} caracteres.`}
        </FormHelperText>
        {errors.pageFourQuestionThree && touched.pageFourQuestionThree ? (
          <FormHelperText>{errors.pageFourQuestionThree}</FormHelperText>
        ) : null}
      </ListItem>
    </List>
  );
};

StepFourForm.propTypes = {
  classes: shape.isRequired,
  answered: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  isSubmitting: bool.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(professionalExperienceFormStyles)(StepFourForm);
