import React, { useContext } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { object, number, string } from 'prop-types';
import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

import RoundedChartStyle from 'assets/jss/material-dashboard-pro-react/components/roundedChartStyle.jsx';

const RoundedChart = (props) => {
  const { classes, chartValue, chartText } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <div className={classes.chartContainer}>
      <CircularProgress
        className={`${classes[`${useDefineColor(universityColor)}Progress`]}`}
        variant="static"
        value={chartValue}
        size={170}
      />
      <p className={classes.chartInfo}>
        <span className={classes.average}>{chartValue}%</span>
        <br />
        {chartText}
      </p>
    </div>
  );
};

RoundedChart.propTypes = {
  classes: object,
  chartValue: number,
  chartText: string,
};

export default withStyles(RoundedChartStyle)(RoundedChart);
