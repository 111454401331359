import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  margin-left: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  @media (max-width: 959px) {
    min-width: 100%;
  }
`;

export const Container = styled.div`
  background-color: #ffff;
  width: 100%;
  max-width: 564px;
  overflow-y: auto;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 768px) {
    min-width: calc(100vw - 28px);
  }
`;

export const ContainerIcon = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const AvatarImage = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 100%;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;

  margin-left: 1.5em;
  border-radius: 4px;
  min-height: 49px;
  position: relative;
  padding: 0px 20px;
  width: 150px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid #e5e5e5;
    border-top: 4.5px solid #e5e5e5;
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 30px;
    transform: rotate(-135deg);
  }
`;

export const ParagraphStyled = styled.p`
  height: 20px;
  width: ${(props) => props.skeletonWidth || '100%'};
  border-radius: 3px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

export const CardContainer = styled.div`
  margin-top: 8px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 8px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-right: auto;
  }
`;

export const CardItem = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 3px;
  display: flex;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const Infos = styled.div`
  width: ${(props) => props.widthInfos || '50px'};
  height: 15px;
  display: flex;
  border-radius: 3px;

  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;
