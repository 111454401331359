import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #f8f8f8;
  color: #606062;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 3px;
  margin-top: 20px;
`;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-left: 20px;
  gap: 5px;

  h6 {
    font-weight: 600;
    font-size: 14px;
    margin: 0;
  }
  p {
    font-weight: 400;
    font-size: 12px;
    margin: 0;
  }
`;
export const ButtonDiv = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;

  button {
    &:first-child {
      margin-bottom: 6px;
    }
    border: 1px solid transparent;
    background-color: transparent;
    cursor: pointer;
  }
`;
export const Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const SubTitle = styled.h4`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  color: #606062;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const AddButton = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: 40px;

  > button {
    cursor: pointer;
    padding: 0 20px;
    line-height: 15px;

    &:active {
      transform: scale(0.9);
    }
    &:disabled {
      border-color: gray;
      color: gray;
      cursor: not-allowed;

      > img {
        filter: invert(51%) sepia(8%) saturate(12%) hue-rotate(318deg)
          brightness(96%) contrast(84%);
      }
    }

    background-color: white;

    border: 1px solid;
    border-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};

    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};

    height: 40px;
    font-weight: bold;
    border-radius: 4px;

    > img {
      margin-right: 5px;
      margin-top: -3px;
    }
  }
`;
export const DocumentHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StatusMessage = styled.div`
  background-color: ${({ background }) => background};
  color: ${({ color }) => color};
  padding: 2px 5px;
  border-radius: 3px;
  display: flex;
  gap: 3px;

  img {
  }
`;
