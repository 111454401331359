import styled from 'styled-components';
import Setadireita from 'assets/Oportutinites/Setadireita.svg';
import Setaesquerda from 'assets/Oportutinites/Setaesquerda.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

export const ContainerSelect = styled.div`
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin: 8px 0 10px 0;

  .react-select.error {
    border: 1px solid #f10909;
    transition: border-color 0.3s ease;
    border-radius: 5px;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  div {
    display: flex;
    flex-direction: column;
  }
`;

export const InputIcon = styled.div`
  width: 45px;
  height: 45px;
  padding: 12.25px;
  border-radius: 2.3px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const InputLabel = styled.div`
  color: #606062;
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  display: flex;
`;

export const InputSubLabel = styled.div`
  color: #8b8b8b;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  margin-left: 10px;
`;

export const InfoTooltip = styled.div`
  background-color: #e1e4e8;
  margin-bottom: 10px;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  height: 52px;
  width: 100%;
`;

export const StyledLabel = styled.label`
  position: absolute;
  top: 6px;
  left: 5px;
  padding: 0 5px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0px;
  text-align: left;
  color: #8b8b8b;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 1;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  margin-top: ${(props) => props.marginTop || '5px'};
  color: #606062;
  font-size: 12px;
`;

export const InputField = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  transition: border-color 0.3s;
  z-index: 0;
  padding-top: 20px;

  &:focus {
    outline: none;
    border-color: #888;
  }

  &:focus + label,
  &:not(:placeholder-shown) + label {
    font-size: 13px;
    top: -12px;
    left: 0;
    color: #bdbdbd;
  }
  &.error {
    border: 1px solid #f10909;
    transition: border-color 0.3s ease;
    border-radius: 5px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const CustomRadioButtonWrapper = styled.label`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const CustomRadioButton = styled.input`
  display: none;
`;

export const CustomRadioVisual = styled.span`
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border: 2px solid
    ${(props) =>
      props.selected ? props.universityColor || '#009291' : '#606062'};
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  background-color: ${(props) =>
    props.selected ? props.universityColor || '#009291' : 'transparent'};
  margin-right: 8px;
`;

export const CheckIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  display: flex;
`;

export const CustomRadioLabel = styled.label`
  user-select: none;
  margin: 5px 0 5px 0;
  color: #606062;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const MultiStepContainer = styled.div`
  height: 5em;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: -20px;

  @media (max-width: 769px) {
    height: 3em;
    margin-left: 0;
  }
`;

export const WrapperContainer = styled.div`
  min-height: 450px;
  max-width: 640px;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  padding: 30px;
  border-radius: 15px;
`;
export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
export const WrapperInput = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const InfoGenderIdentity = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  margin: 10px 0px;
  color: #949494;
`;

export const Link = styled.a`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: #8b8b8b;
  text-decoration: underline;
  margin: -20px 0 10px 0;
`;

export const CheckboxWrapper = styled.label`
  position: relative;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  margin-bottom: 10px;
`;

export const CheckboxInput = styled.input`
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #606062;
  color: #606062;
  cursor: pointer;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  transition: none;
`;

export const CheckboxLabel = styled.span`
  color: #606062;
  margin-left: 20px;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  text-align: start;
  margin-top: 10px;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const TitleForm = styled.div`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-bottom: 20px;
`;

export const SubTitle = styled.p`
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  margin-top: 10px;
  text-align: left;
`;

export const Content = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: justify;

  p {
    margin-top: 10px;
  }
`;

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .border-image {
    width: 89px;
    height: 89px;
    background-color: #ebebeb;
    border: 2px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-image img {
    width: 100%;
    height: 100%;
    margin-bottom: -3px;
  }
`;
export const WrapperTitleEdit = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 0;

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 0;
  }

  .border-image {
    max-width: 70px;
    height: 70px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }

  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 200px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  p {
    margin: 0;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    text-align: left;
  }

  p:first-child {
    margin-bottom: 8px;
  }
`;

export const ButtonsContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
`;

export const NextButton = styled.button`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${Setadireita});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;
export const FinishButton = styled.button`
  color: #ffffff;
  width: 25%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: gray;
    cursor: wait;
  }
`;

export const PrevsButton = styled.button`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${Setaesquerda});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;

  &:disabled {
    background-color: #c8c8c8;
  }
`;
