export const ClockIcon = ({ color = '#009291', size = 36 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5378 35.017C17.8528 35.017 17.1678 35.017 16.4828 35.017C16.3971 34.9998 16.3115 34.9656 16.2173 34.957C13.4773 34.7601 10.9172 33.9809 8.5796 32.551C4.04149 29.7682 1.25012 25.7695 0.274 20.5207C0.154126 19.8614 0.0941872 19.1935 0 18.5342C0 17.8492 0 17.1642 0 16.4792C0.0171249 16.3593 0.0428134 16.2395 0.0599384 16.1196C0.171251 15.3661 0.231187 14.604 0.393874 13.8591C1.31006 9.65492 3.4678 6.21281 6.89279 3.61838C11.1483 0.390323 15.9519 -0.679986 21.1836 0.416011C25.3706 1.28938 28.7785 3.48138 31.3815 6.86355C34.19 10.5197 35.363 14.6811 34.9263 19.262C34.5667 22.9696 33.1282 26.249 30.6537 29.0489C27.9394 32.1143 24.5657 34.0237 20.5243 34.743C19.8735 34.8628 19.2056 34.9313 18.5378 35.017ZM17.5188 2.72788C9.36734 2.71932 2.74855 9.32955 2.72287 17.4981C2.70574 25.6325 9.31597 32.2684 17.4846 32.2941C25.6275 32.3284 32.2977 25.6667 32.2977 17.5067C32.2977 9.35523 25.6789 2.73644 17.5188 2.72788Z"
        fill={color}
      />
      <path
        d="M16.1494 13.5508C16.1494 12.2151 16.1408 10.8879 16.1494 9.55217C16.158 8.52467 17.0913 7.87392 18.0075 8.23355C18.564 8.44761 18.8637 8.9528 18.8723 9.65492C18.8723 11.9068 18.8894 14.1673 18.8637 16.4193C18.8637 16.8217 18.9921 17.0529 19.3089 17.2841C20.7731 18.3544 22.2116 19.4504 23.6587 20.5378C24.1639 20.9146 24.4036 21.4026 24.2409 22.0277C24.0097 22.961 22.948 23.3463 22.1517 22.7898C21.6465 22.4387 21.167 22.0534 20.6704 21.6852C19.4288 20.7604 18.2044 19.81 16.9457 18.9024C16.3891 18.5 16.1323 18.0033 16.1408 17.3098C16.1665 16.0596 16.1494 14.801 16.1494 13.5508Z"
        fill={color}
      />
    </svg>
  );
};
