import styled from 'styled-components';

export const FragmentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  margin-bottom: 46px;
  margin-top: 46px;

  @media screen and (max-width: 680px) {
    flex-wrap: wrap;
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const CardValues = styled.div`
  background-color: rgb(255, 255, 255);
  width: 185px;
  height: 254px;
  border-radius: 13.6px;
  overflow: hidden;
  margin: 0 0 0 44px;

  .image-values {
    width: 35px;
    height: 35px;
    position: absolute;
    margin: 10px 0 0 15px;
  }


  @media screen and (max-width: 680px) {
    margin: 0 4% 6% 6%;
  }

  @media screen and (max-width: 399px) {
    margin: 2%;
    width: 142px;
    height: 220px;
  }
`;
export const TitleCardValues = styled.h1`
  font-size: 0.9rem;
  color: #606062;
  margin-top: 52px;
  font-weight: bold;
  position: absolute;
  margin-left: 13px;
`;
export const ParagraphCardValues = styled.p`
  font-size: 0.85rem;
  color: #949494;
  padding: 8px 3px 0 0;
  margin: 68px 0 0 10px;
`;

export const TextDiv = styled.h1`
  display: flex;
  margin: 0 0 3% 0;
  font-size: 1.3rem;
  color: #606062;
  justify-content: center;
  align-items: center;
  font-weight: 400;
`;

