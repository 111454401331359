import React from 'react';
import { Container, Box } from './style';

export default function BaseModal({ children, ...rest }) {
  return (
    <Container {...rest}>
      <Box>{children}</Box>
    </Container>
  );
}
