import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  TextField
} from "@material-ui/core";
import { Delete, FilterList } from "@material-ui/icons";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { dnaLightGreen } from "assets/jss/material-dashboard-pro-react.jsx";

function TableToolbar(props) {
  const { numSelected, handleDelete, classes, handleSearch, title } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selecionados
          </Typography>
        ) : (
          <h4>{title}</h4>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Deletar">
            <IconButton aria-label="Deletar" onClick={handleDelete}>
              <Delete />
            </IconButton>
          </Tooltip>
        ) : (
          <div className={classes.actionContainer}>
            <TextField
              id="outlined-search"
              label="Pesquisar"
              type="search"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              onChange={event => handleSearch(event.target.value)}
              InputLabelProps={{
                FormLabelClasses: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline
                }
              }}
            />
            <Tooltip title="Filtros">
              <IconButton aria-label="Filtros">
                <FilterList />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  actionContainer: {
    display: "flex",
    alignItems: "center"
  },
  textField: {
    margin: 0,
    marginRight: 10
  },
  cssLabel: {
    transform: "translate(14px, 12px) scale(1)",
    "&$cssFocused": {
      color: dnaLightGreen
    }
  },
  cssFocused: {},
  cssOutlinedInput: {
    height: 40,
    width: 300,
    "&$cssFocused $notchedOutline": {
      borderColor: dnaLightGreen
    }
  },
  notchedOutline: {}
});

export default withStyles(toolbarStyles)(TableToolbar);
