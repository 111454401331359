import styled from 'styled-components';
import { FormHelperText } from '@material-ui/core';
import SearchableSelect from 'react-select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 32px;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  h1 {
    width: 783px;
    height: 22px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
  }
`;

export const ExpandedTitle = styled.h1`
  width: 783px;
  height: 22px;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
`;

export const InfoHeader = styled.header`
  text-align: center;
  p {
    margin-bottom: 8px;
  }
`;

export const CustomFormHelperText = styled(FormHelperText)`
  width: 100%;
  text-align: right;
`;
export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  width: 100%;
  padding: 8px 32px;
`;

export const InputWithError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomTextField = styled.input`
  margin: 8px 32px;
  padding: 12px 20px;
  //box
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 10px;

  //text
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
`;

export const OptionTextField = styled(CustomTextField)`
  margin: 8px 131px;
  flex: 1;
`;

export const Searchable = styled(SearchableSelect)`
  margin: 8px 32px;
  padding: 12px 20px;

  width: -webkit-fill-available;
  margin: 0px 8px;

  background: #ffffff;
  border: 1px solid #efeeec;
  box-sizing: border-box;
  border-radius: 10px;

  -webkit-box-shadow: 5px 5px 5px 2px rgba(188, 188, 188, 0.88);
  box-shadow: 5px 5px 5px 2px rgba(188, 188, 188, 0.88);

  //text
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  div {
    border: none;
  }

  p {
    top: 4px;
    left: 6px;
    z-index: 69;
  }
`;

export const PlusButton = styled.div`
  /* margin: 8px 131px 8px -64px; */
  background-color: #2f9190;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.9;
  }
`;
export const CustomExpansionPanel = styled(ExpansionPanel)`
  padding: 16px;
  border-radius: 20px;
  margin-bottom: 8px;

  &:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  &:before {
    display: none;
  }
`;

export const CustomExpansionPanelSummary = styled(ExpansionPanelSummary)`
  width: 100%;
  position: relative;

  .MuiExpansionPanelSummary-content-1612.MuiExpansionPanelSummary-expanded-1609 {
    margin-bottom: 0;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    span {
      justify-content: flex-end;
    }
    h1 {
      width: 100%;
      height: 22px;
      display: contents;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 20px;
    }
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    div .name {
      display: flex;
      flex-direction: column;
      margin-left: 8px;

      p {
        margin-left: 8px;
      }
    }

    div .status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 150px;
      position: absolute;
      right: 0;
      top: -3%;
      p {
        margin: 0;
        color: ${({ activeStatus }) => (activeStatus ? '#1CB45F' : '#F1312E')};
      }
    }
  }
`;

export const ListDetail = styled.div`
  width: 100%;
  h1 {
    width: 783px;
    height: 22px;
    margin: 2rem 3rem 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 20px;

    & + hr {
      height: 0;
    }

    & + p {
      font-size: 0.8rem;
    }

    & + div {
      margin-top: 1rem;
    }
  }

  div.targetAudience div:last-child {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 6px;
    margin-left: 3.5rem;
    font-size: 1rem;

    & + p {
      margin-left: 3px;
    }
  }

  span {
    padding: 16px;
    float: right;
    color: ${({ publish }) => (publish ? '#f00' : '#3C4858')};
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }

  ul {
    list-style-type: none;
    padding-left: 8px;
    margin-bottom: 2rem;
    margin-left: 3rem;

    li {
      margin-bottom: 0.5rem;
      font-size: 1rem;
    }
  }

  svg {
    color: #2f9190;
    font-size: small;
    margin-right: 4px;
  }

  hr {
    height: 2px;
  }

  .targetAudience ul {
    display: flex;
    flex-direction: column;
  }

  .targetAudience {
    display: flex;
    flex-direction: column;
    & > div {
      & > p + p + p {
        overflow: hidden;
      }
    }

    button {
      display: block;
      border: none;
      background-color: transparent;
      width: 8rem;
      margin: 0 auto;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      color: ${dnaColor};
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  div div {
    display: flex;
    flex-direction: row;

    & > p {
      margin-right: 0.3rem;

      & > p:not(:last-child)::after {
        content: ',';
        margin-right: 10px;
      }
    }
  }
`;

export const ChartLegends = styled.footer`
  display: block;
  p {
    display: inline-block;
  }
  div {
    p:first-child {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    p:last-child {
      transform: translateY(-25%);
    }
  }
`;
