import styled, { keyframes } from 'styled-components';

export const CircularProgressWrapper = styled.div`
  position: relative;
  width: 100px;
  height: 100px;

  > p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-size: 21px;
    font-weight: 700;
    color: #4b4b4d;
  }
`;

export const progressAnimation = (circumference, offset) => keyframes`
  0% {
    stroke-dashoffset: ${circumference};
  }
  100% {
    stroke-dashoffset: ${offset};
  }
`;

export const Background = styled.path`
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 9px;
  stroke-linecap: round;
`;

export const Progress = styled.path`
  fill: none;
  stroke: #009291;
  stroke-width: 9px;
  stroke-linecap: round;
  stroke-dasharray: ${(props) => props.circumference};
  stroke-dashoffset: ${(props) => props.offset};
  transform-origin: 50% 50%;
  animation: ${({ circumference, offset }) =>
      progressAnimation(circumference, offset)}
    1s ease-out forwards;
`;
