import {
  grayColor,
  dnaColor,
  yellowIE,
  dnaGreen,
  redIE,
  greenIE,
  darkBlueIE,
  blueIE,
  lightBlueIE,
  orangeIE
} from 'assets/jss/material-dashboard-pro-react.jsx';

const dragDropTestStyle = (theme) => ({
  grayColor,
  dnaColor,
  chipTestAnswer: {
    borderRadius: 25,
    fontSize: 14,
    paddingTop: 10,
    paddingRight: 5,
    paddingLeft: 5,
    paddingBottom: 10,
    marginRight: 15,
    marginBottom: 20,
    maxWidth: '190px',
    height: 'auto',
    '& span': {
      whiteSpace: 'nowrap',
    },
    '&:hover': {
      cursor: 'grab',
    },
    '&:active': {
      cursor: 'grabbing',
    },
  },
  tempChip: {
    backgroundColor: 'transparent',
    border: '1px solid #C8C8C8',
    borderRadius: 25,
    fontSize: 14,
    paddingTop: 9,
    paddingRight: 4,
    paddingLeft: 4,
    paddingBottom: 9,
    marginRight: 15,
    marginBottom: 20,
    width: 150,
    height: 'auto',
  },
  container: {
    margin: 0,
    '& h4': {
      textAlign: 'center',
    },
  },
  card: {
    margin: '20px 0',
  },
  bodyContainer: {
    display: 'flex',
    marginTop: 10,
  },
  positions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    '& strong': {
      marginBottom: 40,
      marginRight: 20,
    },
  },
  testHeader: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
  },
  selectedList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 10,
    position: 'absolute',
    marginLeft: 34,
  },
  positionsList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 10,
  },
  selectedItem: {
    marginBottom: 10,
    '& strong': {
      marginRight: 10,
    },
  },

  dot: {
    borderRadius: 50,
    border: `2px solid ${grayColor}`,
    color: grayColor,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: 17,
    fontWeight: 400,
    marginRight: 15,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: 30,
      height: 30,
    },
  },

  dnaColorstepActive: {
    color: '#fff',
    border: `2px solid ${dnaGreen}`,
    backgroundColor: dnaGreen,
  },

  yellowIESstepActive: {
    color: '#fff',
    border: `2px solid ${yellowIE}`,
    backgroundColor: yellowIE,
  },

  redIESstepActive: {
    color: '#fff',
    border: `2px solid ${redIE}`,
    backgroundColor: redIE,
  },

  greenIESstepActive: {
    color: '#fff',
    border: `2px solid ${greenIE}`,
    backgroundColor: greenIE,
  },

  darkBlueIESstepActive: {
    color: '#fff',
    border: `2px solid ${darkBlueIE}`,
    backgroundColor: darkBlueIE,
  },

  blueIESstepActive: {
    color: '#fff',
    border: `2px solid ${blueIE}`,
    backgroundColor: blueIE,
  },

  lightBlueIESstepActive: {
    color: '#fff',
    border: `2px solid ${lightBlueIE}`,
    backgroundColor: lightBlueIE,
  },

  orangeIESstepActive: {
    color: '#fff',
    border: `2px solid ${orangeIE}`,
    backgroundColor: orangeIE,
  },

  grid: {
    marginTop: 20,
    width: '100%',
  },
  navigationButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 50,
  },
  finishButton: {
    backgroundColor: dnaColor,
    borderRadius: 20,
    color: '#FFF',
  },
  dragItem: {
    position: 'absolute !important',
  },
  descriptionList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      marginBottom: 5,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export default dragDropTestStyle;
