import { Container, Error } from './style';
import tagIcon from 'assets/academicModeling/tag.svg';
import DeleteIcon from 'assets/academicModeling/delete';
import EditIcon from 'assets/academicModeling/edit';
import { FieldWrapper, Input, VerticalDivider } from '../TodoTab/style';
import React, { Fragment, useMemo, useState, useContext } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import ModalInformation from 'components/informationModal';
import BaseLayoutContext from 'contexts/base-layout';

export function TagTile({ tag, deleteTag, editTag }) {
  const [editMode, setEditMode] = useState(false);
  const [tagName, setTagName] = useState(tag.name);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [hasBrackets, setHasBrackets] = useState(false);
  const [tagChanged, setTagChanged] = useState(false);
  const { openSnackbar } = useContext(BaseLayoutContext);

  function handleChangeTagName(e) {
    const inputValue = e.target.value.toUpperCase();

    setTagName(inputValue);
    setTagChanged(true);
    setHasBrackets(/[\[\]]/.test(inputValue));
  }

  function handleCancel() {
    setTagName(tag.name);
    setEditMode(false);
  }

  const modalDeleteConfig = useMemo(
    () => ({
      bubbleText: 'Atenção!',
      ParagraphText: `Tem certeza que deseja excluir a tag? Essa ação não poderá ser desfeita.`,
      buttonConfirmText: 'SIM, EXCLUIR',
      buttonConfirmFunction: () => {
        deleteTag(tag.id);
        setModalDeleteOpen(false);
      },
      showButtonCancel: true,
      buttonCancelText: 'NÃO',
      modalWidth: '428',
      modalHeight: '342',
    }),
    []
  );

  async function handleSubmitEdit() {
    if (hasBrackets) {
      openSnackbar(`Não é permitido o uso de colchetes`, true);
      return;
    }

    const success = await editTag({
      id: tag.id,
      name: tagName,
    });
    if (success) {
      setEditMode(false);
    }
  }

  return (
    <Container>
      <img src={tagIcon} />
      {editMode ? (
        <FieldWrapper flex={8}>
          <Input
            type="text"
            id="tag-name"
            placeholder="Digite aqui..."
            value={tagName}
            onChange={(e) => handleChangeTagName(e)}
            maxLength={50}
            autoFocus
          />
        </FieldWrapper>
      ) : (
        <span>
          {tag.name.toUpperCase()}
          {editMode && 'edit mode'}
        </span>
      )}
      <div>
        <VerticalDivider height={'21px'} color={'#009291'} />

        {editMode ? (
          <Fragment>
            <FaTimes size={19} color={'#009291'} onClick={handleCancel} />
            <FaCheck
              size={19}
              color="#009291"
              onClick={handleSubmitEdit}
              style={{
                cursor: hasBrackets || !tagChanged ? 'not-allowed' : 'pointer',
              }}
              disabled={hasBrackets || !tagChanged}
            />
          </Fragment>
        ) : (
          <Fragment>
            <EditIcon
              size={'19'}
              color={'#009291'}
              onClick={() => {
                setEditMode(true);
              }}
            />
            <DeleteIcon
              color={'#009291'}
              size={19}
              onClick={() => {
                setModalDeleteOpen(true);
              }}
            />
          </Fragment>
        )}
      </div>

      {modalDeleteOpen && (
        <ModalInformation
          config={modalDeleteConfig}
          buttonConfirmFunction={modalDeleteConfig?.buttonConfirmFunction}
          buttonCancelFunction={() => setModalDeleteOpen(false)}
        />
      )}
    </Container>
  );
}
