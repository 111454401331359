// ##############################
// // // behavioralTestsStyle component styles
// #############################

import {
  dnaColor,
  defaultButton,
  rightArrow,
  tooltip,
  cardTitle,
  fullScreenGridContainer,
  fullScreenGrid,
  grayColor,
  yellowIE,
  dnaGreen,
  redIE,
  greenIE,
  darkBlueIE,
  blueIE,
  lightBlueIE,
  orangeIE,
} from 'assets/jss/material-dashboard-pro-react';

import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle';

const descriptionDefault = {
  lineHeight: '1.3em',
  fontSize: 20,
  color: '#3C4858',
};

const isIphone = !!navigator.userAgent.match(/iPhone/i);

const behavioralTestStyle = (theme) => ({
  fullScreenGridContainer,
  defaultButton,
  rightArrow,
  ...hoverCardStyle,
  tooltip,
  gridListItem: {
    height: 'auto!important',
  },
  headerSpace: {
    marginTop: isIphone ? 1200 : 0,
    padding: 0,
  },
  gridListItemOptions: {
    width: '100%',
    height: 'auto!important',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    '& div': {
      width: '100%',
      justifyContent: 'space-around',
    },
  },
  cardTitle: {
    ...cardTitle,
    color: dnaColor,
    fontSize: 35,
    marginTop: 0,
  },
  cardTitleProfile: {
    ...cardTitle,
    color: dnaColor,
  },
  cardIcon: {
    marginTop: '0!important',
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  cardProductTitle: {
    ...cardTitle,
    textAlign: 'center',
  },
  cardCategory: {
    color: '#000',
    fontSize: '18px',
    paddingTop: '10px',
    margin: '0',
    lineHeight: '1.7em',
  },
  dnaColorcardTitleHelpContent: {
    color: dnaGreen,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  yellowIEScardTitleHelpContent: {
    color: yellowIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  redIEScardTitleHelpContent: {
    color: redIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  greenIEScardTitleHelpContent: {
    color: greenIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  darkBlueIEScardTitleHelpContent: {
    color: darkBlueIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  blueIEScardTitleHelpContent: {
    color: blueIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  lightBlueIEScardTitleHelpContent: {
    color: lightBlueIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  orangeIEScardTitleHelpContent: {
    color: orangeIE,
    fontSize: '2.6em',
    fontWeight: '300',
    lineHeight: '1.5em',
  },
  cardProductDescription: {
    textAlign: 'center',
    color: '#999999',
  },
  cardTestHead: {
    marginTop: 0,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  checkedRadio: {
    width: 60,
    height: 60,
  },
  uncheckedRadio: {
    width: 60,
    height: 60,
  },
  chip: {
    borderRadius: 5,
    borderColor: '#dedede',
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#dedede',
  },

  dnaColorChipChecked: {
    background: dnaGreen,
    borderRadius: 5,
    borderColor: dnaGreen,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  redIESchipChecked: {
    background: redIE,
    borderRadius: 5,
    borderColor: redIE,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  yellowIESchipChecked: {
    background: yellowIE,
    borderRadius: 5,
    borderColor: yellowIE,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  darkBlueIESchipChecked: {
    background: darkBlueIE,
    borderRadius: 5,
    borderColor: darkBlueIE,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  blueIESchipChecked: {
    background: blueIE,
    borderRadius: 5,
    borderColor: blueIE,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  lightBlueIESchipChecked: {
    background: lightBlueIE,
    borderRadius: 5,
    borderColor: lightBlueIE,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  orangeIESchipChecked: {
    background: orangeIE,
    borderRadius: 5,
    borderColor: orangeIE,
    borderWidth: 1,
    borderStyle: 'solid',
    color: '#fff',
  },

  radioQuestionsHolder: {
    position: 'relative',
    marginRight: 0,
    '&: span': {
      padding: 0,
    },
  },

  dot: {
    borderRadius: 50,
    border: `2px solid ${grayColor}`,
    color: grayColor,
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: 17,
    fontWeight: 400,
    marginRight: 15,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
      width: 35,
      height: 35,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },

  dnaColorstepActive: {
    color: '#fff',
    border: `2px solid ${dnaGreen}`,
    backgroundColor: dnaGreen,
  },

  yellowIESstepActive: {
    color: '#fff',
    border: `2px solid ${yellowIE}`,
    backgroundColor: yellowIE,
  },

  redIESstepActive: {
    color: '#fff',
    border: `2px solid ${redIE}`,
    backgroundColor: redIE,
  },

  greenIESstepActive: {
    color: '#fff',
    border: `2px solid ${greenIE}`,
    backgroundColor: greenIE,
  },

  darkBlueIESstepActive: {
    color: '#fff',
    border: `2px solid ${darkBlueIE}`,
    backgroundColor: darkBlueIE,
  },

  blueIESstepActive: {
    color: '#fff',
    border: `2px solid ${blueIE}`,
    backgroundColor: blueIE,
  },

  lightBlueIESstepActive: {
    color: '#fff',
    border: `2px solid ${lightBlueIE}`,
    backgroundColor: lightBlueIE,
  },

  orangeIESstepActive: {
    color: '#fff',
    border: `2px solid ${orangeIE}`,
    backgroundColor: orangeIE,
  },

  description: {
    ...descriptionDefault,
    marginTop: 10,
  },
  dividerNav: {
    marginTop: 15,
    marginBottom: 15,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  quizDescription: {
    ...descriptionDefault,
  },
  quizQuestionDescription: {
    ...descriptionDefault,
  },
  quizQuestionTitle: {
    color: '#009790',
    fontWeight: 'bold',
    marginBottom: 0,
  },
  stats: {
    color: '#999999',
    fontSize: '12px',
    lineHeight: '22px',
    display: 'inline-flex',
    '& svg': {
      position: 'relative',
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      top: '4px',
      fontSize: '16px',
      marginRight: '3px',
    },
  },
  paginationBulletsHolder: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 15,
  },
  buttonLeft: {
    '@media only screen and (max-width: 1315px)': {
      float: 'left',
      bottom: 20,
    },
  },
  centerItems: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonWidth: {
    width: 110,
    bottom: 20,
  },
  videos: {
    width: '90%',
    height: '600px',
    '@media only screen and (max-width: 1920px) and (min-width: 1280px)': {
      height: '450px',
    },
    '@media only screen and (max-width: 1279px) and (min-width: 768px)': {
      height: '350px',
    },
    '@media only screen and (max-width: 760px) and (min-width: 420px)': {
      width: '100%',
      height: '280px',
    },
    '@media only screen and (max-width: 419px) and (min-width: 370px)': {
      width: '100%',
      height: '250px',
    },
    '@media only screen and (max-width: 369px) and (min-width: 300px)': {
      width: '100%',
      height: '200px',
    },
  },
  fullScreenGrid: {
    ...fullScreenGrid,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

export default behavioralTestStyle;
