import React, { useContext, useEffect, useState } from 'react';

import { Container, ContainerButtonLoadMore, ContainerCards } from './styled';
import { Card } from '../../UiComponents/Card';
import { LuBuilding2, LuCalendarClock, LuClock3 } from 'react-icons/lu';
import Button from '../../UiComponents/Button';
import { IoIosLaptop } from 'react-icons/io';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import { FaRegCalendar } from 'react-icons/fa';
import BaseLayoutContext from 'contexts/base-layout';
import TicketModal from '../../UiComponents/TicketModal';
import { VisualizationHandler } from '../../Handlers/VisualizationHandler';
import { DateHelper } from '../../Helpers/DateHelper';
import Search from '../../UiComponents/Search';
import GenericMessage from 'components/GenericMessage';
import FilterHandlers from '../../Handlers/FilterHandlers';
import useMediaQuery from 'hooks/useMediaQuery';

function MyRegistrations() {
  const isMobile = useMediaQuery('(max-width: 640px)');
  const useVisualizationContextInstance = useVisualizationContext();

  const FilterHandlersFactory = FilterHandlers(useVisualizationContextInstance);

  const {
    myRegistrations,
    hasNextPagination,
    handleClickLoadMore,
  } = useVisualizationContextInstance;

  const { universityColor } = useContext(BaseLayoutContext);

  const [searchText, setSearchText] = useState('');

  const [blockButton, setBlockButton] = useState(false);

  const loadMore = async () => {
    setBlockButton(true);
    await handleClickLoadMore()
    setBlockButton(false);
  }

  const createCardListing = myRegistrations?.value?.results.map((event) => {
    const modality =
      event.event_modality === 'presential' ? 'Presencial' : 'Online';
    const iconModality =
      event.event_modality === 'presential' ? (
        <LuBuilding2 color={universityColor} />
      ) : (
        <IoIosLaptop color={universityColor} />
      );

    return (
      <Card.Root>
        <Card.Image
          src={event.event_cover.file}
          onClick={() =>
            VisualizationHandler.openDetailsModal(
              useVisualizationContextInstance,
              event?.id,
              event?.registration?.uuid
            )
          }
        />
        {event.certification && (
          <Card.CertificateStatus status="Possui certificado" />
        )}
        <Card.Title id={event.id} title={event.name} />
        <Card.ContainerInformation>
          <Card.Information icon={iconModality} text={modality} />
          <Card.Information
            icon={<FaRegCalendar color={universityColor} />}
            text={DateHelper.formatRange(
              event.event_start_at,
              event.event_end_at
            )}
          />
          {event.complementary_activity && (
            <Card.Information
              icon={<LuClock3 color={universityColor} />}
              text={'Atividade complementar'}
            />
          )}
        </Card.ContainerInformation>
        <Card.ContainerButton>
          <Button
            width="100%"
            onClick={() =>
              VisualizationHandler.openTicketModal(
                useVisualizationContextInstance,
                event,
                event?.id,
                event?.registration?.uuid
              )
            }
          >
            Acessar ingresso
          </Button>
          <Button
            onClick={() =>
              VisualizationHandler.openDetailsModal(
                useVisualizationContextInstance,
                event?.id,
                event?.registration?.uuid
              )
            }
            width="100%"
            theme="flat"
          >
            Detalhes
          </Button>
        </Card.ContainerButton>
      </Card.Root>
    );
  });

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    FilterHandlersFactory.handleChangeSearchParam(event.target.value);
  };

  if (myRegistrations?.value?.results.length === 0 && searchText.length === 0) {
    return (
      <Container>
        <GenericMessage
          resetIndex={true}
          title="Nenhum evento encontrado"
          subtitle="No momento não existem eventos em que você se inscreveu."
          subtitleResponsible="No momento não existem eventos em que você se inscreveu."
        />
      </Container>
    );
  }

  if (myRegistrations?.value?.results.length === 0 && searchText.length > 0) {
    return (
      <Container>
        {!isMobile && <Search onChange={handleSearchChange} />}
        <GenericMessage
          resetIndex={true}
          title="Nenhum evento encontrado"
          subtitleResponsible="Não foram encontrados eventos para sua busca."
        />
      </Container>
    );
  }

  return (
    <Container>
      {!isMobile && <Search onChange={handleSearchChange} />}
      <ContainerCards>
        <TicketModal />
        {createCardListing}
      </ContainerCards>
      {hasNextPagination && (
        <ContainerButtonLoadMore>
          <Button onClick={loadMore} disabled={blockButton}>Ver mais</Button>
        </ContainerButtonLoadMore>
      )}
    </Container>
  );
}

export default MyRegistrations;
