import styled from 'styled-components';

export const InputRadio = styled.input`
  accent-color: #009291;
  margin: 0 0.5rem 0;
  visibility: hidden;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
`;
export const LabelInput = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  justify-content: center;

  margin-left: 10px;
  background-color: ${(props) => (props.checked ? props.color : '#F6F6F6')};
  width: 28px;
  height: 28px;
  cursor: pointer;
  background-image: url(${(props) =>
    props.checked ? props.SecundaryImg : props.primaryImg});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 4px;
`;

export const TitleLabel = styled.p``;

export const SubTitle = styled.p`
  margin-left: 40px;
  font-size: 14px;
  color: #606062;
  margin-top: 10px;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
`;
