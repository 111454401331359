import { Fragment, useContext, useEffect } from 'react';
import { ListArea, Sentinel } from '../../../Styles/BaseComponent';
import { useIntersectionPagination } from 'views/Student/Learning/Hooks/useIntersectionPagination';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';
import LearningCard from '../../../UIComponents/Card';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import { getLearningFilters } from 'views/Student/Learning/Services';
import SkeletonCard from '../../../UIComponents/SkeletonCard';

export default function FinishedTab({
  activeInternalTab,
  setFilterOptions,
  queryFilter,
  learningCountControl,
  scrollUp,
}) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  };
  const limit = 25;

  const { getFinalizedLearnings } = useAPICallsLearning();

  const {
    finalizedLearning,
    setFinalizedLearning,
    finalizedCount,
    currentPageFinalized,
    setLoadingFilters,
    loading,
  } = useContext(LearningContext);

  useEffect(() => {
    if (activeInternalTab === 3) {
      setFinalizedLearning(null);
      getFinalizedLearnings(true);
    }
  }, [activeInternalTab]);

  useEffect(() => {
    if (activeInternalTab === 3) {
      setLoadingFilters(true);
      setFinalizedLearning(null);

      getLearningFilters('is_finished=true').then((data) => {
        setFilterOptions(data);
        setLoadingFilters(false);
      });
    }
  }, [activeInternalTab]);

  const { sentinelRef } = useIntersectionPagination(
    options,
    finalizedLearning,
    finalizedCount,
    getFinalizedLearnings,
    currentPageFinalized,
    limit,
    queryFilter
  );

  return (
    <Fragment>
      <ListArea>
        <LearningCard
          array={finalizedLearning}
          isFinished
          listingType="Finalized"
          learningCountControl={learningCountControl}
          scrollUp={scrollUp}
        />
        {loading ? <SkeletonCard quantity={3} /> : null}
      </ListArea>
      <Sentinel ref={sentinelRef} />
    </Fragment>
  );
}
