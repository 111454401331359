import React, { useEffect, useState } from 'react';
import { Card } from '../../Card';
import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { BsBuilding, BsCalendar } from 'react-icons/bs';
import { CardFormatHelper } from 'views/StaffPages/FairAndEvents/Helpers/CardFormatterHelper';
import { IoIosLaptop } from 'react-icons/io';
import ButtonWithMenu from 'components/ModalShowEvidence/ButtonWithMenu';
import Button from '../../../Button';
import { VisualizationHandlers } from 'views/StaffPages/FairAndEvents/Handlers/visualizationHandlers';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import DetailsModal from '../../../DetailsModal';
import Search from '../../../SearchBar';
import { ContainerCards, SearchAndCardsContainer } from '../styled';
import FullscreenModal from '../../../FullscreenModal';
import { FilterHandlersFactory } from 'views/StaffPages/FairAndEvents/Handlers/FilterHandlers';
import { RiGraduationCapLine } from 'react-icons/ri';
import StudentCheckinCard from '../../../CheckIn/StudentCheckinCard';
import CardSkeleton from '../../Card/Skeleton';
import GenericMessage from 'components/GenericMessage';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';

export default function WaitingStart() {
  const registerModalContextInstance = useRegisterModalContext();
  const visualizationContextInstance = useVisualizationContext();
  const filterHandlerInstanceFactory = FilterHandlersFactory(
    visualizationContextInstance
  );

  const CoreHandlersFactory = CoreHandlers(visualizationContextInstance);

  const [searchText, setSearchText] = useState('');

  const handleChangeSearch = (event) => {
    const value = event.target.value;

    setSearchText(value);
    filterHandlerInstanceFactory.handleChangeSearchParam(value);
  };

  const {
    awaiting,
    setStudentViewModalOpen,
    setSelectedEvent,
  } = visualizationContextInstance;

  const handleClickSeeMoreStudents = (event) => {
    setSelectedEvent(event);
    setStudentViewModalOpen(true);
  };

  const results = awaiting?.value?.results;

  const widthButtons = `calc(50% - 7.5px)`;

  const card = results?.map((item) => (
    <Card.Root key={item.code}>
      <Card.Status status={CardFormatHelper.formatStatus(item, 0)} />
      <Card.Image src={item.event_cover.file} />
      <Card.Title id={item.code} title={item.name} />
      <Card.ContainerInformation>
        <Card.Information
          icon={<BsCalendar color="#009291" />}
          text={CardFormatHelper.formatLabelDate(item)}
        />
        <Card.Information
          icon={
            item.event_modality === 'presential' ? (
              <BsBuilding color="#009291" />
            ) : (
              <IoIosLaptop color="#009291" />
            )
          }
          text={item.event_modality === 'presential' ? 'Presencial' : 'Online'}
        />
        <Card.Information
          icon={<RiGraduationCapLine color="#009291" />}
          text={CardFormatHelper.formatQuantityStudents(item)}
          seeMore={item.registred_students > 0 && 'ver'}
          onClickSeeMore={() => handleClickSeeMoreStudents(item)}
        />
      </Card.ContainerInformation>

      <Card.ContainerButton>
        <Button
          theme="unfilled"
          width={widthButtons}
          onClick={() =>
            VisualizationHandlers.handleClickDetails(
              visualizationContextInstance,
              item.id
            )
          }
        >
          Detalhes
        </Button>
        <ButtonWithMenu
          menuLabel="Ações"
          buttonWidth={widthButtons}
          buttonHeight="34px"
          insideMenus={[
            {
              name: 'Editar',
              onChange: () =>
                VisualizationHandlers.handleClickOpenEditEvent(
                  registerModalContextInstance,
                  item.id
                ),
            },
            {
              name: 'Baixar lista de inscritos',
              onChange: () => CoreHandlersFactory.exportList(item.id),
              disabled: item.registred_students <= 0,
            },
          ]}
        />
      </Card.ContainerButton>
    </Card.Root>
  ));

  if (results?.length === 0 && searchText.length === 0) {
    return (
      <GenericMessage
        resetIndex={true}
        title="Nenhum evento encontrado"
        subtitle="No momento não existem eventos aguardando início."
      />
    );
  }

  if (!card) {
    return <CardSkeleton />;
  }

  return (
    <SearchAndCardsContainer>
      <Search onChange={handleChangeSearch} value={searchText} />
      <ContainerCards>{card}</ContainerCards>
    </SearchAndCardsContainer>
  );
}
