import LivesModal from './LivesModal';
// import LiveTwoForm from './LivesForms/Season02/LiveTwoForm';
// import LiveThreeForm from './LivesForms/Season02/LiveThreeForm';
// import LiveFourForm from './LivesForms/Season02/LiveFourForm';
// import LiveFiveForm from './LivesForms/Season02/LiveFiveForm';
// import LiveSevenForm from './LivesForms/Season02/LiveSevenForm';

const data = [
  {
    id: 'EOYGZs8Ri-s',
    title: 'Mentoria: Personalidade e Competências Comportamentais',
    modalContent: LivesModal,
  },
  {
    id: '2t4SNhApNwk',
    title: 'Mentoria: Talentos, Paixões e seu futuro profissional',
    modalContent: LivesModal,
  },
  {
    id: 'P2ZQ5R3keZA',
    title: 'Mentoria: Desempenho Acadêmico e o impacto na sua trabalhabilidade',
    modalContent: LivesModal,
  },

  {
    id: 'wfMeaWbuqJM',
    title: 'Mentoria: Hard Skills e Soft Skills',
    modalContent: LivesModal,
  },
  {
    id: '4kfD-9kJjNM',
    title: 'Mentoria: O Trabalho dos Sonhos',
    modalContent: LivesModal,
  },

  {
    id: 'dp1f45S9OlY',
    title: 'Mentoria: Trilhas de Aprendizagem',
    modalContent: LivesModal,
  },
  {
    id: '_IK4CYqyjyM',
    title: 'Mentoria: Networking',
    modalContent: LivesModal,
  },
  {
    id: 'yb2QR7nzBk0',
    title: 'Mentoria: Mercado de Trabalho',
    modalContent: LivesModal,
  },

  {
    id: 'zDhgm_eigHE',
    title: 'Mentoria: Entrevistas de Emprego',
    modalContent: LivesModal,
  },

  // {
  //   id: 'w3h00NdiGTo',
  //   title: 'Mentoria: O poder da Trabalhabilidade',
  //   modalContent: LivesModal,
  // },

  /* {
    id: '-5OkeUYU_zI',
    title: 'As profissões do Futuro: Perfil Convencedor',
    description:
      'Entenda mais sobre as profissões do futuro de pessoas com o perfil convencedor e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
    form: LiveTwoForm,
  },
  {
    id: 'MCKkpYQoj-Y',
    title: 'As profissões do Futuro: Perfil Organizador',
    description:
      'Entenda mais sobre as profissões do futuro de pessoas com o perfil organizador e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
    form: LiveThreeForm,
  },
  {
    id: 'dXe3-j6bfZI',
    title: 'As profissões do Futuro: Perfil Executor',
    description:
      'Entenda mais sobre as profissões do futuro de pessoas com o perfil executor e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
    form: LiveFourForm,
  },
  {
    id: 'S6K6G-Q8skc',
    title: 'As profissões do Futuro: Perfil Investigativo',
    description:
      'Entenda mais sobre as profissões do futuro de pessoas com o perfil investigativo e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
    form: LiveFiveForm,
  },
  {
    id: 'l6RteBbwDDI',
    title: 'As profissões do Futuro: Perfil Auxiliador',
    description:
      'Entenda mais sobre as profissões do futuro de pessoas com o perfil auxiliador e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
    form: LiveSixForm,
  },
  {
    id: 'OM0TGAnbwcI',
    title: 'As profissões do Futuro: Perfil Criador',
    description:
      'Entenda mais sobre as profissões do futuro de pessoas com o perfil criador e como se preparar para atuar nesta nova dinâmica.',
    modalContent: LivesModal,
    form: LiveSevenForm,
  },
  {
    id: '2R7tiEq_9Gg',
    title: 'Painel de Profissões do Futuro',
    description:
      'Na plataforma Workalove, disponibilizamos exercícios que geram micro certificações a respeito deste tema. ',
    modalContent: LivesModal,
  }, */
];

export default data;
