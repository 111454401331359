import React, { useState } from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

export default function AreasTableHead(props) {
  const [rows] = useState([
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "NOME",
      sort: true
    },
    {
      id: "subgroup",
      numeric: false,
      disablePadding: false,
      label: "SUBGRUPO",
      sort: false
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "CRIADO EM",
      sort: true
    },
    {
      id: "options",
      numeric: true,
      disablePadding: false,
      label: "OPÇÕES",
      sort: false
    }
  ]);

  const {
    onSelectAllClick,
    onRequestSort,
    order,
    orderBy,
    numSelected,
    rowCount
  } = props;

  const createSortHandler = property => {
    onRequestSort(property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {rows.map(
          row => (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              {row.sort ? (
                <Tooltip
                  title="Ordenar"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={() => createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              ) : (
                row.label
              )}
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
}

AreasTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
