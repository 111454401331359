export function IconCheck({ color }) {
  return (
    <svg
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.34985 13.0689C6.09909 13.069 5.85077 13.0197 5.61912 12.9237C5.38746 12.8277 5.17702 12.6869 4.99985 12.5094L0.829102 8.34095L2.4206 6.7502L6.34985 10.6794L16.5791 0.450195L18.1706 2.04095L7.69985 12.5094C7.52268 12.6869 7.31224 12.8277 7.08059 12.9237C6.84893 13.0197 6.60061 13.069 6.34985 13.0689Z"
        fill={color}
      />
    </svg>
  );
}
