import React, { useRef } from 'react';
import { CategoriesNavContainer, NavButton, Button } from './styles';
import { useCsm } from '../../contexts/CsmContext';
import { CategoryImage } from '../CategoryImage';
import arrowLeft from 'assets/csm/arrowLeft.svg';
import arrowRight from 'assets/csm/arrowRight.svg';

export function CategoriesNav() {
  const { categoriesList, activeCategory, changeCategory } = useCsm();

  function handleChangeCategory(category) {
    changeCategory(category);
  }
  const divRef = useRef(null);

  const handleClickRight = () => {
    if (divRef.current) {
      const divWidth = divRef.current.offsetWidth;
      const contentWidth = divRef.current.scrollWidth;
      const currentScroll = divRef.current.scrollLeft;
      const scrollAmount = Math.min(divWidth, 75);
      divRef.current.scrollLeft = Math.min(
        currentScroll + scrollAmount,
        contentWidth - divWidth
      );
    }
  };

  const handleClickLeft = () => {
    if (divRef.current) {
      const divWidth = divRef.current.offsetWidth;
      const contentWidth = divRef.current.scrollWidth;
      const currentScroll = divRef.current.scrollLeft;
      const scrollAmount = Math.min(divWidth, 75);
      divRef.current.scrollLeft = Math.max(currentScroll - scrollAmount, 0);
    }
  };

  return (
    <CategoriesNavContainer>
      <Button image={arrowLeft} onClick={handleClickLeft}></Button>
      <div ref={divRef}>
        {categoriesList.map((category) => (
          <NavButton
            key={category.name}
            active={activeCategory === category.value}
            onClick={() => handleChangeCategory(category.value)}
            disabled={activeCategory === category.value}
          >
            <CategoryImage
              category={category.value}
              size={28}
              color={activeCategory === category.value ? '#fff' : '#009291'}
            />
            <div>
              <strong>{category.name}</strong>
              <span> ({category.studentsCount})</span>
            </div>
          </NavButton>
        ))}
      </div>

      <Button image={arrowRight} onClick={handleClickRight}></Button>
    </CategoriesNavContainer>
  );
}
