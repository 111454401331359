import React, { Fragment } from "react";
import { object } from "prop-types";
import { Grid, Divider } from "@material-ui/core";

import withStyles from "@material-ui/core/styles/withStyles";
import learningTrailsStyle from "assets/jss/material-dashboard-pro-react/views/learningTrailsStyle";

const Books = props => {
  const { classes, data } = props;
  return (
    <Fragment>
      <Grid
        container
        justify="center"
        className={classes.fullScreenGridContainer}
      >
        <Grid item xs md={8} sm={12} className={classes.fullScreenGrid}>
          <h3>Livros</h3>

          {data.trail &&
            data.trail.map((book, key) => (
              <Fragment key={key}>
                <Grid
                  container
                  className={(classes.cardVideo, classes.blockMargin)}
                >
                  <Grid item sm={3} md={4} className={classes.thumbnail}>
                    <h4 className={classes.itemTitleMobile}>{book.title}</h4>
                    <img src={book.img} alt={book.itemTitle} width="100%" />
                  </Grid>
                  <Grid item sm={9} md={8}>
                    <h4 className={classes.itemTitle}>{book.title}</h4>
                    <p>{book.description}</p>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
              </Fragment>
            ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};

Books.propTypes = {
  classes: object,
  data: object
};

export default withStyles(learningTrailsStyle)(Books);
