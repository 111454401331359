import React from 'react';

import { ContaineWithoutImage, Image } from './styled';
import { string } from 'prop-types';
import { LuCalendarDays } from 'react-icons/lu';

export default function CardImage({ src }) {
  if (!src || src?.length === 0) {
    return (
      <ContaineWithoutImage>
        <LuCalendarDays size={38} />
      </ContaineWithoutImage>
    );
  }

  return <Image src={src} />;
}

CardImage.prototype = {
  src: string,
};
