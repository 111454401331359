import * as Yup from 'yup';

export const schemaValidationStepOne = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório')
    .max(50, 'O limite para o nome é de 50 caracteres'),
  subject: Yup.string()
    .required('Campo obrigatório')
    .max(50, 'O limite para o assunto é de 50 caracteres'),
  email_body: Yup.string().required('O corpo do e-mail é obrigatório'),
  button_name: Yup.string()
    .max(35, 'O limite para o nome é de 35 caracteres')
    .test(
      'conditional-button-name',
      'O nome do botão é obrigatório quando o link está preenchido',
      function (value) {
        const buttonLink = this.resolve(Yup.ref('button_link'));
        if (buttonLink && !value) {
          return this.createError({
            path: 'button_name',
            message:
              'O nome do botão é obrigatório quando o link está preenchido',
          });
        }
        if (value !== '' && value.length < 3) {
          return this.createError({
            path: 'button_name',
            message: 'O campo precisa ter no mínimo 3 caracteres.',
          });
        }
        return true;
      }
    ),
  button_link: Yup.string()
    .url('Insira uma URL válida')
    .max(500, 'O limite para o link é de 500 caracteres')
    .test(
      'conditional-button-link',
      'O link do botão é obrigatório quando o nome está preenchido',
      function (value) {
        const buttonName = this.resolve(Yup.ref('button_name'));
        if (buttonName && !value) {
          return this.createError({
            path: 'button_link',
            message:
              'O link do botão é obrigatório quando o nome está preenchido',
          });
        }
        return true;
      }
    ),
});
