import styled from 'styled-components';

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-block: 5px;

  img {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    margin-top: 4px;
  }

  span {
    color: #4f4f4f !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
`;
