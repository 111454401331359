import Tooltip from 'components/NewTooltip';
import { RiInformationLine } from 'react-icons/ri';
import { LegendBox } from '../../Styles/StyleSections';

export function LegendFinishedJobs({ data }) {

  const tooltipMessages = {
    total_contracted:
      'Aqui estão as vagas que foram finalizadas com contratação de estudantes da sua instituição',
    not_contracting:
      'Aqui estão as vagas que foram finalizadas sem contratação de estudantes da sua instituição',
    expired: 'Aqui estão as vagas que foram expiradas ou despublicadas',
  };

  return (
    <LegendBox>
      {data.result.map((item) => (
        <div className="item">
          <span>
            {item.label}
            <Tooltip
              text={tooltipMessages[item.name]}
              colorSchema="black"
              direction="top"
              minWidth="211px"
              isMultiLine
            >
              <RiInformationLine />
            </Tooltip>
          </span>
        </div>
      ))}
    </LegendBox>
  );
}
