import { Fragment } from 'react';
import Input from 'components/UnformFields/inputField';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import {
  ContainerInputsLateralSide,
  ContainerSelect,
  ContainerSelectLateralSide,
} from '../StepsForm/List/ActiveStudents/style';
import { TitleGreen } from '../TitleGreen';
import { WrapperBlock } from './style';
import InputDesc from 'views/StaffPages/Opportunities/Components/Form/inputDesc';

export default function BlockComponentForm({
  blockTitle,
  subTitle,
  components,
}) {
  const componentsField = {
    select: ({ name, label, key, ...rest }) => (
      <ContainerSelect key={key}>
        <UnformSelect name={name} label={label} {...rest} />
      </ContainerSelect>
    ),
    input: ({ name, label, disabled, ...rest }) => {
      const inputStyles = {
        backgroundColor: disabled ? '#eeeeee' : 'initial',
      };

      return (
        <div style={{ margin: '20px 0px' }}>
          <Input
            name={name}
            label={label}
            disabled={disabled}
            style={inputStyles}
            {...rest}
          />
        </div>
      );
    },
    description: ({ name, label, ...rest }) => (
      <InputDesc name={name} label={label} {...rest} />
    ),
  };

  const generateFields = () => {
    return components.map((component) => {
      if (component.inputs && component.type === 'betweenInput') {
        return (
          <ContainerInputsLateralSide key={component.name}>
            {component.inputs.map((mapped) => (
              <Input
                key={component.name}
                name={mapped.name}
                label={mapped.label}
                {...component.globalProps}
                {...mapped.props}
              />
            ))}
          </ContainerInputsLateralSide>
        );
      }
      if (component.inputs && component.type === 'betweenSelect') {
        return (
          <ContainerSelectLateralSide key={component.name}>
            {component.inputs.map((mapped) => (
              <UnformSelect
                key={component.name}
                name={mapped.name}
                label={mapped.label}
                {...component.globalProps}
                {...mapped.props}
              />
            ))}
          </ContainerSelectLateralSide>
        );
      }

      return componentsField[component.type]({
        ...component,
        ...component.props,
        ...component.globalProps,
      });
    });
  };

  return (
    <WrapperBlock blockTitle={blockTitle}>
      {blockTitle && (
        <TitleGreen>
          {blockTitle}
          {subTitle && <p>{subTitle}</p>}
        </TitleGreen>
      )}

      {generateFields()}
    </WrapperBlock>
  );
}
