import BaseLayoutContext from 'contexts/base-layout';
import React, {
  createContext,
  useEffect,
  useRef,
  useState,
  useContext,
} from 'react';

export const OportunitiesContext = createContext();

export default function OportunitiesProvider({ children }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [courses, setCourses] = useState();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [macroAreas, setMacroAreas] = useState(null);
  const [
    educationalGroupUniversities,
    setEducationalGroupUniversities,
  ] = useState(null);
  const [interests, setInterests] = useState(null);
  const [competencies, setCompetencies] = useState(null);
  const [optionsId, setOptionsId] = useState('');
  const [selectOption, setSelectOption] = useState('');
  const [cacheOptions, setCacheOptions] = useState([]);

  const [university, setUniversity] = useState(null);
  const [course, setCourse] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [extensionProjectOptions, setExtensionProjectOptions] = useState({});
  const [unities, setUnities] = useState({});

  const { metadata } = useContext(BaseLayoutContext);

  const educationalGroup =
    metadata && metadata.staff_role === 'educational_group_board';

  const stepOneRef = useRef(null);

  const setFormValues = (values) => {
    setFormData((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  useEffect(() => {
    if (formData.course_category && formData.course_category.value) {
      formData.course_category = formData.course_category.value;
    }
    if (formData.category && formData.category.value) {
      formData.category = formData.category.value;
    }

    if (formData.type !== 'course' && formData.course_category) {
      formData.course_category = null;
    }
    if (formData.type !== 'program' && formData.category) {
      formData.category = null;
    }

    if (
      formData.type_access &&
      formData.type_access !== 'video' &&
      formData.content_pages
    ) {
      formData.content_pages = [];
    }
    if (
      formData.type_access &&
      formData.type_access !== 'attraction_document' &&
      formData.attraction_document
    ) {
      formData.attraction_document = null;
    }
    if (
      formData.type_access &&
      formData.type_access !== 'access_link' &&
      formData.access_link
    ) {
      formData.access_link = '';
    }

    if (
      formData.competences_specialities &&
      Array.isArray(formData.competences_specialities) &&
      !formData.competences_specialities.length
    ) {
      delete formData.competences_specialities;
    }

    if (formData.access_link) {
      var prefix = 'http://';
      if (formData.access_link.includes('http://') === false) {
        if (formData.access_link.includes('https://') === false) {
          formData.access_link = prefix + formData.access_link;
        }
      }
    }
  }, [formData]);

  return (
    <OportunitiesContext.Provider
      value={{
        modalIsOpen,
        setModalIsOpen,
        modalContent,
        setModalContent,
        step,
        setStep,
        formData,
        setFormValues,
        macroAreas,
        setMacroAreas,
        university,
        setUniversity,
        interests,
        setInterests,
        competencies,
        setCompetencies,
        optionsId,
        setOptionsId,
        selectOption,
        setSelectOption,
        setFormData,
        cacheOptions,
        setCacheOptions,
        stepOneRef,
        course,
        setCourse,
        periods,
        setPeriods,
        setExtensionProjectOptions,
        extensionProjectOptions,
        unities,
        setUnities,
        educationalGroupUniversities,
        setEducationalGroupUniversities,
        educationalGroup,
        courses,
        setCourses,
        disciplines,
        setDisciplines,
      }}
    >
      {children}
    </OportunitiesContext.Provider>
  );
}
