import React, { createContext, useEffect, useRef, useState } from 'react';
import {
  countExtensionProjects,
  getFutureExtensionProject,
  getInProgressExtensionProject,
  getListFinishedExtensionProject,
  getListToFinishExtensionProject,
  getListUnpublishExtensionProject,
  getOpenedExtensionProject,
} from '../services';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export const ExtensionProjectsContext = createContext();

export default function ExtensionProjectsProvider({
  children,
  activeTab,
  setActiveTab,
}) {
  const [futureExtension, setFutureExtension] = useState(null);
  const [inProgressExtension, setInProgressExtension] = useState(null);
  const [
    openForSubscriptionsExtension,
    setOpenForSubscriptionsExtension,
  ] = useState(null);

  const [paginationOneCount, setPaginationOneCount] = useState(0);
  const [paginationTwoCount, setPaginationTwoCount] = useState(0);
  const [paginationThreeCount, setPaginationThreeCount] = useState(0);

  const [paginationOneCurrentPage, setPaginationOneCurrentPage] = useState(
    null
  );
  const [paginationTwoCurrentPage, setPaginationTwoCurrentPage] = useState(
    null
  );
  const [paginationThreeCurrentPage, setPaginationThreeCurrentPage] = useState(
    0
  );

  const [unpublishProjects, setUnpublishProjects] = useState(null);
  const [finishedProjects, setFinishedProjects] = useState(null);

  const [currentPageFinishedProject, setCurrentPageFinishedProject] = useState(
    null
  );
  const [
    currentPageUnpublishProject,
    setCurrentPageUnpublishProject,
  ] = useState(null);
  const [currentPageToFinishProject, setCurrentPageToFinishProject] = useState(
    null
  );
  const [toFinishLoading, setToFinishLoading] = useState(false);
  const [finishedProjectCount, setFinishedProjectCount] = useState(0);
  const [unpublishProjectCount, setUnpublishProjectCount] = useState(0);
  const [toFinishProjectCount, setToFinishProjectCount] = useState(0);
  const [imageChanged, setImageChanged] = useState(false);
  const [usingFormUpload, setUsingFormUpload] = useState(false);
  const [extensionProjectId, setExtensionProjectId] = useState(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [modalFeedbackStudent, setModalFeedbackStudent] = useState(false);
  const [modalEnrolledStudents, setModalEnrolledStudents] = useState(false);
  const [modalFinishProject, setModalFinishProject] = useState(false);
  const [modalFeedbackUnpublish, setModalFeedbackUnpublish] = useState(false);
  const [activeTabExtensionProject, setActiveTabExtensionProject] = useState(0);
  const [toFinishProjects, setToFinishProjects] = useState(null);
  const [evidenceFiles, setEvidenceFiles] = useState([]);
  const [evidenceLinks, setEvidenceLinks] = useState([]);
  const [informationItem, setInformationItem] = useState(null);
  const [queryFilter, setQueryFilter] = useState(null);

  const [idPostEvidence, setIdPostEvidence] = useState(0);

  const refModalExtensionProject = useRef(null);

  const [formDataFinishProject, setFormDataFinishProject] = useState({});

  const [studentScore, setStudentScore] = useState(null);

  const [countInProgressProjects, setCountInProgressProjects] = useState(0);
  const [countFutureProjects, setCountFutureProjects] = useState(0);
  const [countOpenedProjects, setCountOpenedProjects] = useState(0);
  const [countToFinishedProjects, setCountToFinishedProjects] = useState(0);
  const [countUnpublishedProjects, setCountUnpublishedProjects] = useState(0);
  const [countFinishedProjects, setCountFinishedProjects] = useState(0);
  const [countPublishedSum, setCountPublishedSum] = useState(0);
  const [countFinishedhedSum, setCountFinishedhedSum] = useState(0);

  const [idProjectBeeingProcessed, setIdProjectBeeingProcessed] = useState(
    null
  );

  const getCountProjects = async () => {
    const data = await countExtensionProjects();
    const {
      in_progress_projects,
      future_extension_projects,
      opened_projects,
      to_finish_projects,
      unpublished_projects,
      finished_projects,
      projects_published_sum,
      projects_finished_sum,
    } = data;
    setCountInProgressProjects(in_progress_projects);
    setCountFutureProjects(future_extension_projects);
    setCountOpenedProjects(opened_projects);
    setCountToFinishedProjects(to_finish_projects);
    setCountUnpublishedProjects(unpublished_projects);
    setCountFinishedProjects(finished_projects);
    setCountPublishedSum(projects_published_sum);
    setCountFinishedhedSum(projects_finished_sum);
  };

  const setFormValues = (values) => {
    setFormDataFinishProject((prevsValues) => ({
      ...prevsValues,
      ...values,
    }));
  };

  useEffect(() => {
    if (activeTabExtensionProject === 0 && activeTab === 3) {
      getAllOpenForSubscription();
    } else {
      setOpenForSubscriptionsExtension(null);
    }
  }, [paginationOneCurrentPage, activeTab, activeTabExtensionProject]);

  useEffect(() => {
    if (activeTabExtensionProject === 0 && activeTab === 3) {
      getAllFutureProjects();
    } else {
      setFutureExtension(null);
    }
  }, [paginationTwoCurrentPage, activeTab, activeTabExtensionProject]);

  useEffect(() => {
    if (activeTabExtensionProject === 0 && activeTab === 3) {
      getAllInProgressProjects();
    } else {
      setInProgressExtension(null);
    }
  }, [paginationThreeCurrentPage, activeTab, activeTabExtensionProject]);

  useEffect(() => {
    if (activeTab !== 3) {
      setFutureExtension(null);
      setInProgressExtension(null);
      setOpenForSubscriptionsExtension(null);
      setPaginationOneCurrentPage(0);
      setPaginationTwoCurrentPage(0);
      setPaginationThreeCurrentPage(0);
      setModalEnrolledStudents(false);
      setExtensionProjectId(null);
    }
    setActiveTabExtensionProject(0);
  }, [activeTab]);

  useEffect(() => {
    if (activeTabExtensionProject !== 3) {
      setUnpublishProjects(null);
      setFinishedProjects(null);
      setCurrentPageUnpublishProject(0);
      setCurrentPageFinishedProject(0);
      setCurrentPageToFinishProject(0);
    }
  }, [activeTabExtensionProject]);

  useEffect(() => {
    const sumProjetc =
      countFutureProjects + countOpenedProjects + countInProgressProjects;

    setCountPublishedSum(sumProjetc);
  }, [countFutureProjects, countOpenedProjects, countInProgressProjects]);

  /* Published Projects */

  async function getAllFutureProjects(appliedFilters, reset = false) {
    let queryParams = `?limit=8&offset=${reset ? 0 : paginationTwoCurrentPage}`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    const response = await getFutureExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCountFutureProjects(count);
      if (reset) {
        setFutureExtension(results);
      } else {
        setFutureExtension((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setPaginationTwoCount(count);
    } else {
      setFutureExtension(null);
    }
  }

  async function getAllOpenForSubscription(appliedFilters, reset = false) {
    let queryParams = `?limit=8&offset=${reset ? 0 : paginationOneCurrentPage}`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }

    const response = await getOpenedExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCountOpenedProjects(count);
      if (reset) {
        setOpenForSubscriptionsExtension(results);
      } else {
        setOpenForSubscriptionsExtension((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setPaginationOneCount(count);
    } else {
      setOpenForSubscriptionsExtension(null);
    }
  }

  async function getAllInProgressProjects(appliedFilters, reset = false) {
    let queryParams = `?limit=8&offset=${
      reset ? 0 : paginationThreeCurrentPage
    }`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    const response = await getInProgressExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCountInProgressProjects(count);

      if (reset) {
        setInProgressExtension(results);
      } else {
        setInProgressExtension((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setPaginationThreeCount(count);
    } else {
      setInProgressExtension(null);
    }
  }

  /* Finished Projects */

  useEffect(() => {
    if (activeTabExtensionProject === 2) {
      getAllUnpublishedProjects();
    } else {
      setUnpublishProjects(null);
    }
  }, [currentPageUnpublishProject, activeTabExtensionProject]);

  useEffect(() => {
    const sumFinishedProjetc = countFinishedProjects + countUnpublishedProjects;

    setCountFinishedhedSum(sumFinishedProjetc);
  }, [countUnpublishedProjects, countFinishedProjects]);

  useEffect(() => {
    if (activeTabExtensionProject === 2) {
      getAllFinishedProjects();
    } else {
      setFinishedProjects(null);
    }
  }, [currentPageFinishedProject, activeTabExtensionProject]);

  async function getAllUnpublishedProjects(appliedFilters, reset = false) {
    let queryParams = `?limit=8&offset=${
      reset ? 0 : currentPageUnpublishProject
    }`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    const response = await getListUnpublishExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCountUnpublishedProjects(count);
      if (reset) {
        setUnpublishProjects(results);
      } else {
        setUnpublishProjects((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setUnpublishProjectCount(count);
    } else {
      setUnpublishProjects(null);
    }
  }

  async function getAllFinishedProjects(appliedFilters, reset = false) {
    let queryParams = `?limit=8&offset=${
      reset ? 0 : currentPageFinishedProject
    }`;
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    const response = await getListFinishedExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCountFinishedProjects(count);
      if (reset) {
        setFinishedProjects(results);
      } else {
        setFinishedProjects((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setFinishedProjectCount(count);
    } else {
      setFinishedProjects(null);
    }
  }

  /* To Finish Projects */

  useEffect(() => {
    if (activeTabExtensionProject === 1) {
      getAllToFinishProjects();
    } else {
      setToFinishProjects(null);
    }
  }, [currentPageToFinishProject, activeTabExtensionProject]);

  useEffect(() => {
    setCountToFinishedProjects(countToFinishedProjects);
  }, [countToFinishedProjects]);

  async function getAllToFinishProjects(appliedFilters, reset = false) {
    let queryParams;
    if (currentPageToFinishProject > 0 && reset) {
      queryParams = `?limit=${currentPageToFinishProject + 10}&offset=${
        reset ? 0 : currentPageToFinishProject
      }`;
    } else {
      queryParams = `?limit=10&offset=${
        reset ? 0 : currentPageToFinishProject
      }`;
    }
    if (appliedFilters) {
      queryParams = `${queryParams}&${queryParamsFromFilterObject(
        appliedFilters
      )}`;
    }
    setToFinishLoading(true);
    const response = await getListToFinishExtensionProject(queryParams);
    if (response.status === 200) {
      const { results, count } = response.data;
      setCountToFinishedProjects(count);
      if (reset) {
        setToFinishProjects(results);
      } else {
        setToFinishProjects((prevProjects) =>
          prevProjects ? [...prevProjects, ...results] : results
        );
      }
      setToFinishProjectCount(count);
    } else {
      setToFinishProjects(null);
    }
    setToFinishLoading(false);
    setIdProjectBeeingProcessed(null);
  }

  return (
    <ExtensionProjectsContext.Provider
      value={{
        activeTab,
        refModalExtensionProject,

        futureExtension,
        setFutureExtension,

        inProgressExtension,
        setInProgressExtension,

        unpublishProjects,
        setUnpublishProjects,

        finishedProjects,
        setFinishedProjects,

        openForSubscriptionsExtension,
        setOpenForSubscriptionsExtension,

        paginationOneCurrentPage,
        setPaginationOneCurrentPage,

        paginationTwoCurrentPage,
        setPaginationTwoCurrentPage,

        paginationThreeCurrentPage,
        setPaginationThreeCurrentPage,

        paginationOneCount,
        setPaginationOneCount,

        paginationTwoCount,
        setPaginationTwoCount,

        paginationThreeCount,
        setPaginationThreeCount,

        modalEnrolledStudents,
        setModalEnrolledStudents,

        modalFeedbackUnpublish,
        setModalFeedbackUnpublish,

        modalFeedbackStudent,
        setModalFeedbackStudent,

        extensionProjectId,
        setExtensionProjectId,

        isSuccessModalOpen,
        setIsSuccessModalOpen,

        setActiveTab,
        getAllFutureProjects,
        getAllOpenForSubscription,
        getAllInProgressProjects,

        imageChanged,
        setImageChanged,

        usingFormUpload,
        setUsingFormUpload,

        activeTabExtensionProject,
        setActiveTabExtensionProject,

        getAllUnpublishedProjects,
        getAllFinishedProjects,

        currentPageUnpublishProject,
        setCurrentPageUnpublishProject,

        currentPageFinishedProject,
        setCurrentPageFinishedProject,

        unpublishProjectCount,
        setUnpublishProjectCount,

        finishedProjectCount,
        setFinishedProjectCount,

        toFinishProjectCount,
        setToFinishProjectCount,
        getAllToFinishProjects,
        toFinishProjects,
        setToFinishProjects,
        setCurrentPageToFinishProject,
        toFinishLoading,

        modalFinishProject,
        setModalFinishProject,
        idPostEvidence,
        setIdPostEvidence,
        evidenceFiles,
        setEvidenceFiles,
        evidenceLinks,
        setEvidenceLinks,

        formDataFinishProject,
        setFormDataFinishProject,
        setFormValues,

        studentScore,
        setStudentScore,

        informationItem,
        setInformationItem,

        queryFilter,
        setQueryFilter,

        countInProgressProjects,
        setCountInProgressProjects,

        countFutureProjects,
        setCountFutureProjects,

        countOpenedProjects,
        setCountOpenedProjects,

        countToFinishedProjects,
        setCountToFinishedProjects,

        countUnpublishedProjects,
        setCountUnpublishedProjects,

        countFinishedProjects,
        setCountFinishedProjects,

        countPublishedSum,
        setCountPublishedSum,

        countFinishedhedSum,
        setCountFinishedhedSum,

        getCountProjects,

        idProjectBeeingProcessed,
        setIdProjectBeeingProcessed,
      }}
    >
      {children}
    </ExtensionProjectsContext.Provider>
  );
}
