import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 215px;
  height: 325px;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
`;

export const GrayGradient = styled.div`
  height: 58px;
  width: 215px;
  border-radius: 10px 10px 0 0;
  left: -1px;
  top: -1px;
  background: linear-gradient(
    180deg,
    rgba(151, 167, 185, 0.5) 0%,
    rgba(151, 167, 185, 0.1) 100%
  );
  position: absolute;
`;

export const TextName = styled.span`
  font-size: 18px;
  color: #606062;
  margin: 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  > span {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;

    > b {
      color: #009291;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
  }
  .specialities {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px;
  }
`;

export const AreaAmount = styled.span`
  color: #ffffff;
  font-size: 12px;
  width: 24px;
  height: 17px;
  font-family: 'Roboto', sans-serif;
  border-radius: 2px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#009291'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  background-color: #caeaea;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
`;

export const ShowProfileButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #009291;
  height: 40px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 1rem;

  &:hover {
    filter: brightness(0.9);
  }
`;
