import React from 'react';

import { Container } from './styled';

export default function Header() {
  return (
    <Container>
      <h4>Feiras e Eventos</h4>
      <span>
        Aqui você pode acompanhar as feiras e eventos que já foram publicadas
        para os seus estudantes em algum momento.
      </span>
    </Container>
  );
}
