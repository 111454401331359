import styled from 'styled-components';
import closeIcon from 'assets/Oportutinites/X.svg';

export const ModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${(props) => props.zIndex || '9999'};
  align-items: center;
  height: 100vh;
  width: 120vw;
  justify-content: center;
  background-color: Rgb(0, 0, 0, 0.6);
  position: fixed;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 768px) {
    width: 100%;
    top: none;
    left: none;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  max-height: ${(props) => (!!props.maxHeight ? props.maxHeight : '80vh')};

  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 2.5rem;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #aaaaaa;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`;

export const ModalContainer = styled.div`
  width: 370px;
  height: auto;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 768px) {
    width: 100%;
    position: fixed;
    padding: 0;
    margin: 0;
    bottom: 0;
    transform: translate(-50%, 20%);
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 0 2.5rem;

  @media (max-width: 600px) {
    div:first-of-type {
      width: 100%;
    }
  }
`;

export const CloseButton = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  transition: filter 0.2s;
  background-image: url(${closeIcon});
  margin: 0;
  position: absolute;
  right: 10px;
  top: 10px;

  &:hover {
    filter: brightness(0.9);
  }
`;
