export default function UserCircleIcon({ color, size }) {
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1232_1731)">
        <path
          d="M14.4938 15.0627C14.1613 15.0627 13.8288 15.054 13.4988 15.0661C13.348 15.0719 13.3043 15.0394 13.3048 14.8769C13.3103 10.1155 13.3127 5.35519 13.3118 0.596011C13.3118 0.306985 13.2171 0.103999 12.9419 0H2.05767C1.78337 0.103999 1.68778 0.306985 1.6882 0.595593C1.69235 5.34586 1.69332 10.0958 1.69111 14.8456C1.6884 14.8818 1.6884 14.9183 1.69111 14.9546C1.70482 15.0469 1.66285 15.0653 1.57848 15.0644C1.22106 15.0602 0.863223 15.0615 0.505803 15.0644C0.203659 15.0644 -0.00206515 15.2578 -0.000818338 15.5347C0.000428475 15.8116 0.206983 16.0012 0.509958 16.0012H14.4892C14.793 15.9996 15.0008 15.8108 15.0008 15.5335C15.0008 15.2561 14.7964 15.064 14.4938 15.0627ZM8.97291 15.0627C8.48125 15.0598 7.98917 15.0627 7.49752 15.0627C7.00586 15.0627 6.52417 15.0602 6.0375 15.0653C5.93858 15.0653 5.90866 15.0435 5.90908 14.94C5.91323 13.9568 5.91323 12.9736 5.90908 11.9904C5.90908 11.8972 5.92695 11.8676 6.02669 11.868C7.01001 11.8722 7.99361 11.8722 8.97748 11.868C9.06393 11.868 9.09011 11.8893 9.08969 11.9787C9.08692 12.9722 9.08692 13.9658 9.08969 14.9596C9.09094 15.0577 9.05146 15.0644 8.97457 15.064L8.97291 15.0627ZM12.2092 15.0627C11.5357 15.0558 10.862 15.0558 10.1881 15.0627C10.0581 15.0627 10.0161 15.041 10.0173 14.8957C10.0244 13.755 10.0215 12.6148 10.0211 11.4742C10.0211 11.1041 9.84776 10.9274 9.48078 10.9274H5.51633C5.1506 10.9274 4.97604 11.1037 4.97604 11.4737C4.97604 12.6194 4.97604 13.7651 4.97812 14.9107C4.97812 15.0172 4.96732 15.0627 4.84056 15.0615C4.14608 15.054 3.45161 15.0556 2.75713 15.0615C2.65157 15.0615 2.61874 15.0389 2.61874 14.9266C2.62206 10.3072 2.62206 5.68793 2.61874 1.06881C2.61874 0.959381 2.64617 0.931815 2.75505 0.931815C5.91614 0.9346 9.07736 0.9346 12.2387 0.931815C12.3684 0.931815 12.375 0.981518 12.375 1.0851C12.3726 3.39201 12.3726 5.69893 12.375 8.00584C12.375 10.3027 12.3763 12.5999 12.3788 14.8974C12.38 15.0394 12.341 15.0673 12.2092 15.0657V15.0627Z"
          fill={color}
        />
        <path
          d="M11.2579 2.61001C11.2579 2.25875 11.0696 2.0708 10.7176 2.0708C10.0028 2.0708 9.28808 2.0708 8.57352 2.0708C8.22192 2.0708 8.03323 2.25792 8.03323 2.60667C8.03323 3.33062 8.03323 4.05458 8.03323 4.77854C8.03323 5.1181 8.22524 5.30981 8.56313 5.31023C9.28351 5.31023 10.0039 5.31023 10.7243 5.31023C11.0701 5.31023 11.2567 5.11894 11.2579 4.76726C11.2579 4.40807 11.2579 4.04887 11.2579 3.68968C11.2579 3.33049 11.2604 2.9692 11.2579 2.61001ZM10.3274 3.15548C10.3186 3.33717 10.3249 3.51969 10.3249 3.70179C10.3249 3.88389 10.3207 4.066 10.327 4.2481C10.3299 4.33957 10.3103 4.3734 10.2089 4.3734C9.83626 4.36728 9.4636 4.36728 9.09095 4.3734C8.9833 4.3734 8.96336 4.33706 8.96627 4.23975C8.97125 3.87554 8.97125 3.51134 8.96627 3.14713C8.96627 3.04313 8.9858 3.0022 9.10051 3.00471C9.4625 3.01222 9.82532 3.01306 10.1873 3.00471C10.3091 3.00178 10.3328 3.04397 10.3274 3.15548Z"
          fill={color}
        />
        <path
          d="M6.43939 2.0708C5.7143 2.0708 4.98921 2.0708 4.26412 2.0708C3.93537 2.0708 3.74378 2.2646 3.74253 2.59497C3.74045 2.95918 3.74253 3.32339 3.74253 3.68801C3.74253 4.05263 3.74253 4.40598 3.74253 4.76517C3.74253 5.11769 3.92997 5.30814 4.27658 5.30814C4.99697 5.30814 5.71735 5.30814 6.43773 5.30814C6.77562 5.30814 6.96638 5.11643 6.96721 4.77562C6.96721 4.05166 6.96721 3.3277 6.96721 2.60374C6.96513 2.26335 6.77645 2.07122 6.43939 2.0708ZM6.03459 4.26564C6.03459 4.34584 6.01423 4.37549 5.93069 4.37298C5.7391 4.36714 5.5475 4.37298 5.35591 4.37298C5.16432 4.37298 4.97272 4.36964 4.78113 4.37298C4.70299 4.37507 4.67224 4.35544 4.67307 4.26982C4.67723 3.88501 4.67723 3.50006 4.67307 3.11497C4.67307 3.03561 4.69302 3.00554 4.77697 3.00679C5.15961 3.01069 5.54265 3.01069 5.92612 3.00679C6.00467 3.00679 6.03542 3.02601 6.03459 3.11121C6.03071 3.49574 6.03071 3.88055 6.03459 4.26564Z"
          fill={color}
        />
        <path
          d="M6.96512 6.94133C6.96512 6.62975 6.7677 6.44013 6.45725 6.43721C6.09484 6.43512 5.73243 6.43721 5.36961 6.43721C5.00679 6.43721 4.63441 6.43721 4.2666 6.43721C3.93411 6.43721 3.74335 6.62641 3.74252 6.95637C3.74086 7.69007 3.74086 8.42377 3.74252 9.15747C3.74252 9.47281 3.93037 9.66745 4.24124 9.66912C4.98185 9.67274 5.72246 9.67274 6.46307 9.66912C6.77602 9.66912 6.96179 9.47407 6.96387 9.15831C6.9672 8.41932 6.96761 7.68032 6.96512 6.94133ZM6.03458 8.62913C6.03458 8.71266 6.00632 8.73438 5.92735 8.73396C5.54472 8.73062 5.16209 8.73062 4.77945 8.73396C4.69924 8.73396 4.67223 8.71057 4.67306 8.62787C4.67721 8.24362 4.67721 7.85909 4.67306 7.47428C4.67306 7.38782 4.7034 7.37069 4.78028 7.37236C4.97146 7.37696 5.16306 7.37236 5.35423 7.37236C5.54541 7.37236 5.73701 7.37571 5.9286 7.37236C6.00673 7.37236 6.03583 7.39032 6.03499 7.47511C6.03056 7.85992 6.03042 8.24459 6.03458 8.62913Z"
          fill={color}
        />
        <path
          d="M11.2571 6.94133C11.2571 6.62975 11.0601 6.44013 10.7492 6.43721C10.3818 6.43512 10.014 6.43721 9.6462 6.43721C9.27839 6.43721 8.92138 6.43721 8.55897 6.43721C8.22649 6.43721 8.03531 6.62599 8.03448 6.95637C8.03282 7.69007 8.03282 8.42377 8.03448 9.15747C8.03448 9.47281 8.22275 9.66745 8.53321 9.66912C9.27354 9.67274 10.014 9.67274 10.7546 9.66912C11.0676 9.66912 11.2533 9.47365 11.2533 9.15831C11.2581 8.41932 11.2593 7.68032 11.2571 6.94133ZM10.327 8.61994C10.327 8.70598 10.3062 8.73772 10.2152 8.73647C9.83779 8.73173 9.46028 8.73173 9.08264 8.73647C8.98206 8.73647 8.96419 8.70013 8.96502 8.61117C8.96945 8.23694 8.96945 7.86271 8.96502 7.48848C8.96502 7.40077 8.98829 7.37195 9.07682 7.37487C9.268 7.38155 9.45959 7.37487 9.65077 7.37487C9.84195 7.37487 10.0248 7.37821 10.2093 7.37487C10.2925 7.37487 10.329 7.38531 10.3274 7.48221C10.3216 7.85895 10.3228 8.23861 10.327 8.61994Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1232_1731">
          <rect width="15" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
