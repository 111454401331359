import { useState } from 'react';
import { Container, SubContainer } from './styled';
import { PendingStudents } from './PendingStudents';
import { ConfirmedStudents } from './ConfirmedStudents';
import { GenericInternalTabs } from 'components/GenericNavegation';
import { useNextMentorshipContext } from '../../context';
import { mentorshipApi } from '../../../../services/api';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { useMentorship } from 'views/StaffPages/Mentorship/contexts/MentorshipContext';
import { FilterMentorship } from 'views/StaffPages/Mentorship/components/Filter';

export default function InternalTab() {
  const [activeSubTab, setActiveSubTab] = useState(0);
  const {
    updateMentorshipsCount,
    mentorshipCounts,
    setMentorshipCounts,
  } = useMentorship();
  const {
    setMentorships,
    setPage,
    setHasNextPage,
    setLoading,
    appliedFilters,
    setAppliedFilters,
    filterState,
    setFilterState,
  } = useNextMentorshipContext();

  const handleChangeTab = (value) => {
    if (activeSubTab !== value) {
      setMentorships(null);
    }
    setFilterState({});
    setPage(1);
    setAppliedFilters({});
    setActiveSubTab(value);
  };

  const tabs = [
    {
      id: 0,
      label: `Pendentes (${mentorshipCounts.pending ?? 0})`,
      component: (
        <PendingStudents updateMentorshipsCount={updateMentorshipsCount} />
      ),
    },
    {
      id: 1,
      label: `Confirmadas  (${mentorshipCounts.confirmed ?? 0})`,
      component: (
        <ConfirmedStudents updateMentorshipsCount={updateMentorshipsCount} />
      ),
    },
  ];

  const renderTabContent = () => {
    const selectedTab = tabs.find((tab) => tab.id === activeSubTab);
    return selectedTab ? selectedTab.component : null;
  };

  async function onApplyFilters(filters) {
    setMentorships([]);
    setLoading(true);
    setPage(1);
    const queryString = queryParamsFromFilterObject(filters, {
      commaSeparatedFields: ['mentor'],
    });
    let queryParams = `limit=20&offset=0`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    let response;
    if (activeSubTab === 0) {
      response = await mentorshipApi.getPendingMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, pending: response.count });
    } else {
      response = await mentorshipApi.getConfirmedMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, confirmed: response.count });
    }
    setMentorships(response.results);
    setHasNextPage(response.next !== null);
    setLoading(false);
  }

  async function onCleanFilters() {
    setMentorships([]);
    setLoading(true);
    setPage(1);
    let queryParams = `limit=20&offset=0`;
    let response;
    if (activeSubTab === 0) {
      response = await mentorshipApi.getPendingMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, pending: response.count });
    } else {
      response = await mentorshipApi.getConfirmedMentorshipsList({
        queryParams,
      });
      setMentorshipCounts({ ...mentorshipCounts, confirmed: response.count });
    }
    setMentorships(response.results);
    setHasNextPage(response.next !== null);
    setLoading(false);
  }

  return (
    <Container>
      <SubContainer>
        <GenericInternalTabs
          activeTab={activeSubTab}
          handleChange={handleChangeTab}
          tabs={tabs.map((tab) => tab.label)}
          height="45px"
        />
        <FilterMentorship
          onApplyFilters={onApplyFilters}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          filterState={filterState}
          setFilterState={setFilterState}
          onCleanFilters={onCleanFilters}
        />
      </SubContainer>
      {renderTabContent()}
    </Container>
  );
}
