import React, { useEffect, useState } from 'react';
import { func, string, shape } from 'prop-types';
import {
  Dialog,
  Button,
  FormHelperText,
  Grid,
  TextField,
  CircularProgress,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { FastField, Formik } from 'formik';
import Icon from '@material-ui/core/Icon';

import walImg from 'assets/img/faces/wal.png';
import * as Yup from 'yup';
import { login } from '../../services/login/authenticate';
import { ContentDialog, Form } from './style';
import { changePasswordUniversity } from '../../services/firstPassword';

const PasswordFirstAccess = ({
  classes,
  globalState,
  changePassword,
  userName,
  openSnackbar,
  metadata,
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      globalState &&
      !globalState.has_changed_password &&
      metadata &&
      metadata.role === 'university'
    ) {
      setShowDialog(true);
    }
  }, []);

  async function handleChangePassword(newPassword) {
    setLoading(true);
    const response = await changePasswordUniversity(newPassword);

    if (!response.message) {
      setShowDialog(false);
      await login('/login/universidade', {
        username: userName,
        password: newPassword.new_password,
      });
      changePassword({ has_changed_password: true });
      openSnackbar('Alteração realizada com sucesso!');
    } else {
      openSnackbar('Ops! Algo deu errado. Tente novamente mais tarde.', true);
    }
    setLoading(false);
  }

  const FirstPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Campo Obrigatório')
      .min(8, 'Sua senha precisa ter no mínimo 8 caracteres.')
      .matches(
        new RegExp('([A-Za-z][0-9]|[0-9][A-Za-z])'),
        'Sua senha deve conter letras e números'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'As senhas digitadas são diferentes')
      .required('Campo Obrigatório'),
  });
  return (
    <Dialog
      open={showDialog}
      onClose={() => setShowDialog(false)}
      PaperProps={{
        style: {
          backgroundColor: '#f1f1f1',
          borderRadius: 20,
          width: 550,
          maxHeight: 620,
        },
      }}
      disableBackdropClick
    >
      <ContentDialog>
        <div className="headerContent">
          <img src={walImg} alt="Wal" />
          <div className="textContent">
            <h4>
              <strong>Olá, seja bem vindo(a) à Workalove!</strong>
            </h4>
            <p>
              Para a sua segurança, é necessário que você altere a sua senha de
              acesso!
            </p>
          </div>
        </div>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={FirstPasswordSchema}
          onSubmit={(values) => {
            handleChangePassword({ new_password: values.confirmPassword });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={24}>
                <Grid item xs={12} md={12}>
                  <FastField
                    className={classes.inputField}
                    autoFocus
                    InputProps={{
                      className: classes.input,
                    }}
                    maxLength={9}
                    name="password"
                    variant="filled"
                    id="password"
                    type="password"
                    label="Nova Senha"
                    placeholder="Preencha sua nova senha"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors.password && !!touched.password}
                    value={values.password}
                    component={TextField}
                  />
                  {errors.password && touched.password && (
                    <FormHelperText className={classes.helperText}>
                      {errors.password}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <FastField
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                    }}
                    maxLength={9}
                    name="confirmPassword"
                    variant="filled"
                    id="confirmPassword"
                    label="Confirmação da Senha"
                    type="password"
                    placeholder="Preencha a senha informada no campo acima"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={
                      !!errors.confirmPassword && !!touched.confirmPassword
                    }
                    value={values.confirmPassword}
                    component={TextField}
                  />
                  {errors.confirmPassword && touched.confirmPassword && (
                    <FormHelperText className={classes.helperText}>
                      {errors.confirmPassword}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
              {!loading ? (
                <div className="actions">
                  <Button type="submit" variant="contained" color="primary">
                    CONFIRMAR <Icon className="saveIcon">check_circle</Icon>
                  </Button>
                </div>
              ) : (
                <div className="load">
                  <CircularProgress className={classes.loading} />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </ContentDialog>
    </Dialog>
  );
};

PasswordFirstAccess.propTypes = {
  classes: shape.isRequired,
  globalState: shape.isRequired,
  changePassword: func.isRequired,
  userName: string.isRequired,
  openSnackbar: func.isRequired,
  metadata: shape.isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(
  PasswordFirstAccess
);
