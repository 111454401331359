import styled from 'styled-components';

export const Modal = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: fixed;
  z-index: 10000;
  padding-top: 50vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  /* Modal Content */
  .modalContent {
    transform: translateY(-50%) !important;
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
  }

  /* The Close Button */
  .close {
    color: #b7b6b6;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin-top: 9px;
  }

  .close:hover,
  .close:focus {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    text-decoration: none;
    cursor: pointer;
  }
  // old border of Val image
  .modalHeader {
    padding: 2px 16px;
    color: white;
    img {
      margin-right: 1.5em;
      max-height: 97px;
      border-radius: 50%;
      border: 3px solid
        ${(props) =>
          props.universityColor ? props.universityColor : '#009291'};
      position: absolute;
      top: -50px;
      left: -54px;
      width: 80px;
      background-color: #fff;
    }
  }

  .modalBody {
    padding: ${(props) => (props.noActions ? '6px 29px 36px' : '6px 29px')};
    margin-top: 30px;
    text-align: justify;
    font-size: 16px;
    line-height: 1.5em;
  }

  .modalActions {
    color: white;
    display: flex;
    justify-content: center;
    margin-top: -12px;
    padding: 14px 16px 20px;
    flex-wrap: wrap;
    button {
      margin: 10px;
      font-size: 14px;
      color: #fff;
      border-radius: 5px;
      background: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
      padding-left: 13px;
    }
  }
  .deniedButton {
    background: none !important;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
  }
  .iconTalk {
    font-size: 20px;
    margin-left: 4px;
    margin-bottom: 2px;
  }
  @media (max-width: 1024px) {
    .modalContent {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    .modalHeader img {
      left: -33px;
    }
  }
`;
