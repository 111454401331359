const IModalConfigCreateJobErrorInternal = {
  ParagraphText:
    'Um erro ocorreu durante a criação do seu desafio! Revise os dados e tente novamente.',
  bubbleText: 'Oops!',
  buttonText: 'Ok, entendi!',
  showButton: true,
};

const IModalConfigEditJobErrorInternal = {
  ParagraphText:
    'Um erro ocorreu durante a edição do seu desafio! Revise os dados e tente novamente.',
  bubbleText: 'Oops!',
  buttonText: 'Ok, entendi!',
  showButton: true,
};

const IModalConfigCreateJobInternal = {
  ParagraphText: 'Estamos publicando seu desafio',
  bubbleText: 'Falta pouco...',
};

const IModalConfigEditJobInternal = {
  ParagraphText: 'Estamos salvando as edições do seu desafio',
  bubbleText: 'Falta pouco...',
};

export {
  IModalConfigCreateJobInternal,
  IModalConfigCreateJobErrorInternal,
  IModalConfigEditJobErrorInternal,
  IModalConfigEditJobInternal,
};
