import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
  ComposedChart,
  Text,
} from 'recharts';
import { GraphicCapsule } from '../../Styles/StyleGraphics';
import { returnAdapter } from '../../Utils/formatDataGraphics';
import { formatNumber } from 'utils/formatNumber';

export function CustomHorizontalBarChart({
  LegendBoxComponent,
  graphicalData,
  dataKey,
  heightGraphicValue,
}) {
  const adapterData = returnAdapter(graphicalData);

  const CustomizedXAxisTick = (props) => {
    const { x, y, offset, payload } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          x={-150}
          y={4}
          dy={0}
          style={{ fontSize: 13, fontWeight: 400 }}
          fill="#606062"
          textAnchor="start"
          width="170"
          verticalAnchor="middle"
        >
          {payload.value}
        </Text>
      </g>
    );
  };

  const CustomTickFormatter = (tick) => {
    const number = formatNumber(tick);
    return Number.isInteger(tick) ? number : '';
  };

  return (
    <GraphicCapsule
      heightGraphic={heightGraphicValue ? heightGraphicValue : 190}
    >
      {LegendBoxComponent && <LegendBoxComponent data={adapterData} />}
      <ResponsiveContainer
        width={LegendBoxComponent ? '80%' : '94%'}
        height="100%"
      >
        <ComposedChart
          layout="vertical"
          data={adapterData?.result}
          margin={{
            top: 5,
            right: 100,
            left: LegendBoxComponent ? -54 : 110,
            bottom: 5,
          }}
        >
          <CartesianGrid
            strokeDasharray="0.3"
            vertical={true}
            strokeOpacity={1}
          />
          <XAxis
            type="number"
            dataKey={dataKey}
            interval={0}
            tickLine={false}
            style={{ fontWeight: 500 }}
            axisLine={{ stroke: '#ececec' }}
            tickFormatter={CustomTickFormatter}
          />
          <YAxis
            dataKey={!LegendBoxComponent && 'label'}
            type="category"
            tick={CustomizedXAxisTick}
            tickLine={false}
            axisLine={{ stroke: '#ececec' }}
            tickFormatter={CustomTickFormatter}
          />
          <Bar dataKey={dataKey} barSize={32} fill="#009291">
            <LabelList
              dataKey="value"
              fill="#606062"
              position="right"
              style={{ fontWeight: 700, fontSize: 12 }}
              formatter={(value) => formatNumber(value)}
            />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </GraphicCapsule>
  );
}
