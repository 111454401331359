import * as Yup from 'yup';
import moment from 'moment';
const mobilePhoneNumberRegex = /^(?:\(\d{2}\)\s?9\d{4}-\d{4})|(?:\(\d{2}\)\s?\d{4,5}-\d{4})$/;

export const validationSchemaStepThree = (surveys) => {
  let schemaFields = {};

  surveys.forEach((survey, surveyIndex) => {
    survey.data.forEach((question, questionIndex) => {
      const fieldName = `question${questionIndex + 1}`;
      schemaFields[fieldName] = Yup.string().required('Informação obrigatória');
    });
  });

  return Yup.object().shape(schemaFields);
};

export const validationSchemaStepTwo = Yup.object().shape({
  status: Yup.string().required('Informação obrigatória'),
  occupation_area: Yup.string().when('status', {
    is: (status) => status !== 'unemployed',
    then: Yup.string().required('Informação obrigatória'),
  }),

  is_working_in_formation_area: Yup.string().when('status', {
    is: (status) => status !== 'unemployed',
    then: Yup.string().required('Informação obrigatória'),
  }),

  work_shift: Yup.string().required('Informação obrigatória'),

  professional_satisfaction: Yup.string().when('status', {
    is: (status) => status !== 'unemployed',
    then: Yup.string().required('Informação obrigatória'),
  }),
  is_open_to_offers: Yup.string().required('Informação obrigatória'),
  is_carrer_objective: Yup.string().required('Informação obrigatória'),
});

export const validationSchemaStepFour = Yup.object().shape({
  name: Yup.string()
    .required('Informação obrigatória')
    .matches(/\S+\s+\w/, 'Preencha o nome completo'),
  birth_date: Yup.string()
    .required('Informação obrigatória')
    .test(
      'isBeforeToday',
      'A data de nascimento deve ser menor que hoje.',
      function (value) {
        if (!value) {
          return true;
        }
        return moment(value, 'DD/MM/YYYY').isBefore(
          moment().subtract(1, 'days')
        );
      }
    ),
  email: Yup.string()
    .email('Você deve informar um e-mail válido')
    .required('Informação obrigatória')
    .test(
      'no-special-characters',
      'O e-mail não pode conter caracteres especiais',
      (value) => {
        const specialCharacters = /[çáàãâéèêíïóôõöúçñ]/gi;
        return !specialCharacters.test(value);
      }
    ),
  mobile_phone: Yup.string()
    .test('is-valid-mobile', 'Informe um número de celular válido', (value) => {
      return mobilePhoneNumberRegex.test(value);
    })
    .required('Informação obrigatória'),
  zip_code: Yup.string()
    .min(9, 'Digite um CEP válido')
    .required('Informação obrigatória'),
  address: Yup.string().required('Informação obrigatória'),
  address_number: Yup.string()
    .required('Informação obrigatória')
    .test('is-positive-integer', 'Digite um número válido', (value) => {
      if (!value) return true;
      return /^\d+$/.test(value);
    }),
  district: Yup.string().required('Informação obrigatória'),
  city: Yup.string().required('Informação obrigatória'),
  state: Yup.string().required('Informação obrigatória'),
  gender_identity: Yup.string().required('Informação obrigatória'),
  color_or_race: Yup.string().required('Informação obrigatória'),
});
