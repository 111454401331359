import styled from 'styled-components';
export const AboltCompany = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 9px;
  width: 100%;
  padding: 0 3rem;
  gap: 1.5rem;
  @media (max-width: 475px) {
    padding: 0.7rem;
  }
`;

export const FragmentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #f8fafc;
  border-radius: 9px;
  width: 100%;
  padding: 1rem;
  @media (max-width: 475px) {
    padding: 0.5rem;
  }
  > h4 {
    font-size: 23px;
    font-weight: 400;
    font-family: Roboto;
  }
  > p {
    font-size: 18px;
    font-weight: 200;
    font-family: Roboto;
    line-height: 30px;
  }
  > div {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    > div {
      > span {
        height: 0.7rem;
        width: 0.7rem;
        border-radius: 50%;
        background-color: ${(props) => props.color || '#009291'};
      }
      > p {
        font-size: 18px;
        font-weight: 200;
        font-family: Roboto;
        color: #606062;
        padding: 0;
        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    > div {
      gap: 16px;
      align-items: center;
    }

    > h4 {
      font-size: 23px;
      font-weight: 400;
      font-family: Roboto;
    }
  }

  @media (max-width: 592px) {
    > div {
      > div {
        > p {
          font-size: 16px;
        }
      }
    }
    > h4 {
      font-size: 20px;
    }

    > p {
      font-size: 16px;
    }
  }

  @media (max-width: 475px) {
    padding: 0.7rem;
    > div {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-top: 10px;
      align-items: baseline;

      > div {
        > p {
          font-size: 15px;
        }
      }
    }

    > h4 {
      font-size: 18px;
    }

    > p {
      font-size: 15px;
    }
  }

  @media (max-width: 375px) {
    > div {
      > div {
        > p {
          font-size: 14px;
        }
      }
    }

    > h4 {
      font-size: 16px;
    }

    > p {
      font-size: 14px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  align-content: center;
`;
