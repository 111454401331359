import { authenticatedAPI } from 'services/api';

const newReaction = async (id, reaction) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/attractions/${id}/reaction`, reaction)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateReaction = async (id, reactionId, reaction) => {
  const response = authenticatedAPI
    .patch(
      `/api/internal/students/me/attractions/${id}/reaction/${reactionId}/edit`,
      reaction
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteReaction = async (id, reactionId) => {
  const response = authenticatedAPI
    .delete(
      `/api/internal/students/me/attractions/${id}/reaction/${reactionId}/edit`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const concludeAttraction = async (id) => {
  const isFinished = { is_finished: true };
  const response = authenticatedAPI
    .post(`/api/internal/students/me/attractions/${id}/finished`, isFinished)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const newRecommend = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/attractions/${id}/recommendation`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const cancelRecommendation = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/students/me/attractions/${id}/recommendation`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  newReaction,
  newRecommend,
  cancelRecommendation,
  concludeAttraction,
  updateReaction,
  deleteReaction,
};
