import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext.js';
import { renderItemsAccordion } from 'views/StaffPages/CompaniesManagement/Utils/renderItemsAccordion.js';
import { AccordionContent } from '../../../UIComponents/AccordionContent';
import AccordionSkelleton from '../../../UIComponents/AccordionSkelleton';
import GenericAccordion from '../../../UIComponents/GenericAccordion';
import { Fragment, useContext, useEffect, useState } from 'react';
import Pagination from 'components/CustomPagination';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';
import { WhiteBox } from '../../../Styles/BaseComponent';
import GenericMessage from 'components/GenericMessage';
import { getCanceledInviteFilter } from 'views/StaffPages/CompaniesManagement/Services';
import ViewStageDataModal from '../../../UIComponents/Modals/ViewStageDataModal';

export default function CanceledInvitationTab({
  setOpenModalConnectedCompanies,
  activeInternalTab,
  setFilterOptions,
  queryFilter,
  setLoading,
  documents,
  setDocuments,
  setTab,
  scrollToTopDiv,
  getCountTabs,
}) {
  const {
    returnStatus,
    returnIcons,
    returnIconSessions,
  } = renderItemsAccordion(false);

  const [openModalViewStageData, setOpenModalViewStageData] = useState(false);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);

  const {
    canceledInvite,
    setCanceledInvite,
    canceledInviteCount,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
  } = useContext(CompaniesManagementContext);
  const { getCanceledInvite } = useAPICalls();

  useEffect(() => {
    if (activeInternalTab === 3) {
      setTab('canceled');
      setLoading(true);
      getCountTabs();
      setCanceledInvite(null);

      getCanceledInviteFilter().then((data) => {
        setFilterOptions(data);
        setLoading(false);
      });
    }
  }, [activeInternalTab]);

  const handlePageChange = async (page) => {
    if (queryFilter === null) {
      const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
      setCanceledInvite(null);
      await getCanceledInvite(newPage);
    }

    if (queryFilter !== null) {
      const query = `${queryFilter}&limit=${limit}&offset=${
        (page - 1) * limit || 0
      }`;
      setCanceledInvite(null);
      await getCanceledInvite(query);
    }
  };

  const onPageChange = (page) => {
    scrollToTopDiv();
    setCurrentPage(page);
    handlePageChange(page);
  };

  const refreshLimit = (page) => {
    handlePageChange(page);
  };

  useEffect(() => {
    refreshLimit(currentPage);
  }, [limit]);

  const renderItems = () => {
    if (canceledInvite?.length === 0) {
      return (
        <GenericMessage
          title="Nenhuma empresa encontrada"
          subtitle="
            No momento, não existem convites de
            pré-cadastro cancelados por você
          "
        />
      );
    }

    if (canceledInvite === null) {
      return Array(8)
        .fill(1)
        .map((_, index) => <AccordionSkelleton />);
    }

    if (canceledInvite?.length > 0) {
      return canceledInvite?.map((companies) => (
        <GenericAccordion
          companyId={companies.id}
          data={companies}
          greenRightButton={false}
          grayRightButton={false}
          checkbox={false}
          flagStatus={true}
          textStatus={returnStatus}
          iconStatus={returnIcons}
          colorStatus={companies?.agreement_status}
          textTitle={companies?.legal_name || 'Não informado'}
          textSubtitle={`${companies?.organization_number_type}:`}
          contentSubtitle={companies?.organization_number}
          iconSession={() => returnIconSessions(companies, false)}
          secondCTA={false}
          setDocuments={setDocuments}
        >
          <AccordionContent
            companies={companies}
            setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
            responsibleForTheCanceled={true}
            setDocuments={setDocuments}
            setOpenModalViewStageData={setOpenModalViewStageData}
          />
        </GenericAccordion>
      ));
    }
  };

  return (
    <Fragment>
      <WhiteBox internalWhiteBox={true}>{renderItems()}</WhiteBox>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={canceledInviteCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
      {openModalViewStageData && (
        <ViewStageDataModal
          isOpen={openModalViewStageData}
          setOpenModalViewStageData={setOpenModalViewStageData}
          documents={documents}
          usingPreviewPdf={usingPreviewPdf}
          setUsingPreviewPdf={setUsingPreviewPdf}
        />
      )}
    </Fragment>
  );
}
