import React, {useContext} from "react";
import { RadioGroup, FormControlLabel, Radio, Chip } from "@material-ui/core";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import behavioralTestStyle from "assets/jss/material-dashboard-pro-react/views/behavioralTestStyle";
import useDefineColor from "constants/colors";
import BaseLayoutContext from "contexts/base-layout";

const RadioGroupWithButtons = props => {
  const { classes, groupName, onChange, optionValue } = props;
  const {universityColor} = useContext(BaseLayoutContext)
  return (
    <RadioGroup
      aria-label="position"
      name={groupName || "default"}
      value={optionValue}
      onChange={onChange}
      row
    >
      <FormControlLabel
        className={classes.radioQuestionsHolder}
        control={
          <Radio
            color="primary"
            icon={
              <Chip label="1" variant="outlined" className={classes.chip} />
            }
            checkedIcon={
              <Chip 
                label="1" 
                className={classes[`${useDefineColor(universityColor)}chipChecked`]} 
              />
            }
          />
        }
        value="1"
      />
      <FormControlLabel
        className={classes.radioQuestionsHolder}
        control={
          <Radio
            color="primary"
            icon={
              <Chip label="2" variant="outlined" className={classes.chip} />
            }
            checkedIcon={
              <Chip 
                label="2" 
                className={classes[`${useDefineColor(universityColor)}chipChecked`]} 
              />
            }
          />
        }
        value="2"
      />
      <FormControlLabel
        className={classes.radioQuestionsHolder}
        control={
          <Radio
            color="primary"
            icon={
              <Chip label="3" variant="outlined" className={classes.chip} />
            }
            checkedIcon={
              <Chip 
                label="3" 
                className={classes[`${useDefineColor(universityColor)}chipChecked`]} 
              />
            }
          />
        }
        value="3"
      />
      <FormControlLabel
        className={classes.radioQuestionsHolder}
        control={
          <Radio
            color="primary"
            icon={
              <Chip label="4" variant="outlined" className={classes.chip} />
            }
            checkedIcon={
              <Chip 
                label="4" 
                className={classes[`${useDefineColor(universityColor)}chipChecked`]} 
              />
            }
          />
        }
        value="4"
      />
      <FormControlLabel
        className={classes.radioQuestionsHolder}
        control={
          <Radio
            color="primary"
            icon={
              <Chip label="5" variant="outlined" className={classes.chip} />
            }
            checkedIcon={
              <Chip 
                label="5" 
                className={classes[`${useDefineColor(universityColor)}chipChecked`]} 
              />
            }
          />
        }
        value="5"
      />
    </RadioGroup>
  );
};

export default withStyles(behavioralTestStyle)(RadioGroupWithButtons);
