import ReportsStyle from 'assets/jss/material-dashboard-pro-react/views/ReportsStyle';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TabContainer from 'components/TabContainer';
import CustomReport from 'views/StaffPages/CustomReport';
import { useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { shape, func } from 'prop-types';

function CustomReportsView({ classes, handleIsLoadingState, openSnackbar }) {
  
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <GridContainer
      direction="column"
      alignItems="center"
      className={classes.padding0}
    >
      <GridItem item xs md={9} sm={12} className={classes.width100}>
        <TabContainer>
          <CustomReport
            handleIsLoadingState={handleIsLoadingState}
            openSnackbar={openSnackbar}
          />
        </TabContainer>
      </GridItem>
    </GridContainer>
  );
}

CustomReportsView.propTypes = {
  classes: shape.isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
};

export default withStyles(ReportsStyle)(CustomReportsView);
