import { useLearningIcons } from 'views/Student/Learning/Hooks/useLearningIcons';
import {
  Container,
  Image,
  Title,
  ListItemCard,
  ContainerButtons,
  AdvanceButton,
} from './style';
import ReactHtmlParser from 'react-html-parser';
import { useContext } from 'react';
import { AdditionalHoursFlag } from '../../Styles/BaseComponent';
import BaseLayoutContext from 'contexts/base-layout';
import { CurriculumContext } from 'contexts/CurriculumContext';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';

export function WorkaCard({ item, history, loadingCurriculum }) {
  const { BsPersonFill, BiWifi, CertificateIcon, BsStarFill } =
    useLearningIcons();

  const { universityColor } = useContext(BaseLayoutContext);

  const { setIsOpenCurriculumModal, profile } = useContext(CurriculumContext);

  const checkHistory = () => {
    if (!profile.academic_profile?.curriculum_filled) {
      return setIsOpenCurriculumModal(true);
    }

    if (item.route) {
      history.push(item.route);
    }
  };

  return (
    <Container>
      <Image universityColor={universityColor}>
        <img src={item?.image} alt="" />
        {item?.workload && (
          <AdditionalHoursFlag universityColor={universityColor} card>
            <div className="circle">
              <BsStarFill size={11} color="#fff" />
            </div>
            <p>{item?.workload} horas complementares</p>
          </AdditionalHoursFlag>
        )}
      </Image>
      <Title>
        <span>{item.name}</span>
      </Title>

      <ListItemCard>
        <div className="wrapper-description">
          <span>{ReactHtmlParser(item?.description)}</span>
        </div>
        <div className="wrapper">
          <BsPersonFill color={universityColor || '#009291'} size={18} />
          <p> Publicado por {item.created_by.name} </p>
        </div>
        {item.certificate && (
          <div className="wrapper">
            <CertificateIcon size={17} color={universityColor || '#009291'} />
            <p> Possui certificado </p>
          </div>
        )}
        <div className="wrapper">
          <BiWifi color={universityColor || '#009291'} size={18} />
          <p> {item.modality} </p>
        </div>
      </ListItemCard>
      <ContainerButtons>
        <AdvanceButton
          fullWidth
          universityColor={universityColor}
          onClick={() => checkHistory(item)}
          disabled={loadingCurriculum}
        >
          {loadingCurriculum ? <Loading color="#fff" /> : 'Acessar'}
        </AdvanceButton>
      </ContainerButtons>
    </Container>
  );
}
