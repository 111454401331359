import React, { Fragment } from 'react';
import {
  CardSubTitlesContainer,
  CardGrayGradient,
  ImageContainer,
  CardSubTitles,
  StudentImage,
  CardTitle,
  Card,
} from './styled';

export default function SkeletonCardMentorship({
  quantity,
  subTitleQuantity,
  cardWidth,
  cardHeight,
  cardMargin,
}) {
  const numberOfCards = [];

  for (let i = 1; i <= quantity; i++) {
    numberOfCards.push(i);
  }

  const mapCards = () => {
    return numberOfCards.map((item) => (
      <Card
        key={item}
        width={cardWidth}
        height={cardHeight}
        margin={cardMargin}
      >
        <CardGrayGradient />
        <ImageContainer>
          <StudentImage />
        </ImageContainer>

        <CardTitle />
        <CardSubTitlesContainer>
          {Array.from({ length: subTitleQuantity }).map((_, index) => (
            <CardSubTitles key={index} />
          ))}
        </CardSubTitlesContainer>
      </Card>
    ));
  };

  return <Fragment>{mapCards()}</Fragment>;
}

SkeletonCardMentorship.defaultProps = {
  quantity: 3,
  subTitleQuantity: 4,
  cardWidth: '215px',
  cardHeight: '316px',
  cardMargin: '10px',
};
