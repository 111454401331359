export function getCheckInConfirmationConfig(count) {
  return {
    presence: {
      modalWidth: 428,
      bubbleText: 'Atenção!',
      ParagraphText: `Tem certeza que deseja remover a presença dos ${count} estudantes selecionados?`,
      buttonCloseX: true,
      showButtonCancel: true,
      buttonCancelText: 'NÃO',
      buttonConfirmText: 'SIM, CONTINUAR',
    },
    noPresence: {
      modalWidth: 428,
      bubbleText: 'Atenção!',
      ParagraphText: `Tem certeza que deseja confirmar a presença dos ${count} estudantes selecionados?`,
      buttonCloseX: true,
      showButtonCancel: true,
      buttonCancelText: 'NÃO',
      buttonConfirmText: 'SIM, CONTINUAR',
    },
  };
}
