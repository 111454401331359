import React, { useState, useContext } from 'react';

import {
  Container,
  Button,
  WrapperInfos,
  VisualIndicator,
  ContainerButton,
  JobsStatus,
  FeedbackButton,
} from './styled';

import Edifice from 'assets/JobOffer/edifice.svg';
import User from 'assets/JobOffer/user.svg';

import classnames from 'classnames';
import FinishModal from '../Modal/FinishModal';
import { JobDetailButton } from '../JobDetails';
import { ListButton } from '../ListCandidates/components/ListButton/buttonView';

import { isMasterLet } from '../../JobController/PublishedJobsTabs';
import { EngineListCandidates } from 'components/ListCandidates/EngineListCandidates';
import { JobOfferContext } from '../../context/jobOfferContext';

export default function JobHistoryCards(props) {
  const {
    jobName,
    openedSince,
    responsibleName,
    numberOfCandidates,
    jobID,
    status,
    jobs,
    setFeedback,
    changeModalFeedbackState,
    role,
    setJobsInstitution,
    universityFilters,
    setJobsCountInstitution,
    setJobsCountPartners,
    setCountMyJobs,
  } = props;

  const { dataFunction } = useContext(JobOfferContext);

  const [expandedResponsible, setExpandedResponsible] = useState(false);
  const [modalFinish, setModalFinish] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isPartner =
    jobs.is_partner_offer || (isMasterLet && !jobs.is_educational_group);

  const strReduced = (str) => {
    if (str.length > 17) {
      return `${str.substr(0, 17)}...`;
    } else {
      return str;
    }
  };

  const returnResponsibleName = () => {
    if (jobs.is_educational_group) {
      return `Grupo educacional ${responsibleName}`;
    }

    if (role === 'university' && isPartner && !isMasterLet) {
      return `Empresa ${responsibleName}`;
    }

    return `Instituição ${responsibleName}`;
  };

  const handleModalFinish = () => {
    setModalFinish(true);
  };

  const handleModalListContent = () => {
    setOpenModal(true);
  };

  const handleClickFeedBack = () => {
    setFeedback({ feedback: jobs.feedback, id: jobID });

    changeModalFeedbackState();
  };

  const isReadOnly = () => {
    if (isMasterLet) {
      return jobs.is_educational_group ? false : true;
    }
    return jobs.is_educational_group ? true : false;
  };

  return (
    <Container>
      {openModal && (
        <EngineListCandidates
          controlModal={openModal}
          setControlModal={setOpenModal}
          jobs={jobs}
          isPartner={isPartner}
          history={true}
          university={true}
        />
      )}

      <FinishModal
        setModalFinish={setModalFinish}
        isOpen={modalFinish}
        jobID={jobID}
        isPartnerOffer={jobs.is_partner_offer}
        status={status}
        setJobsInstitution={setJobsInstitution}
        universityFilters={universityFilters}
        setJobsCountInstitution={setJobsCountInstitution}
        setJobsCountPartners={setJobsCountPartners}
        setCountMyJobs={setCountMyJobs}
        dataFunction={dataFunction}
      />
      <WrapperInfos>
        <strong> {jobName} </strong>
        <span> {openedSince} </span>
        <span> {`CÓDIGO: ${jobID}`} </span>

        <VisualIndicator>
          <div className="icon">
            <img src={Edifice} alt="Icone Edifício" />
          </div>
          <p title={responsibleName}>
            {expandedResponsible || responsibleName.length <= 12
              ? returnResponsibleName()
              : strReduced(responsibleName)}
          </p>
          {!expandedResponsible && responsibleName.length > 12 && (
            <button
              onClick={() => {
                setExpandedResponsible(true);
              }}
            >
              ver mais
            </button>
          )}
        </VisualIndicator>
        <VisualIndicator>
          <div className="icon">
            <img src={User} alt="Icone Usuário" />
          </div>

          <p>
            {numberOfCandidates === 0 ? 'Nenhum estudante candidatado' : null}
            {numberOfCandidates === 1
              ? `${numberOfCandidates} estudante candidatado`
              : null}
            {numberOfCandidates > 1
              ? `${numberOfCandidates} estudantes candidatados`
              : null}
          </p>
          {numberOfCandidates ? (
            <ListButton onClick={handleModalListContent}>ver</ListButton>
          ) : null}
        </VisualIndicator>
        <VisualIndicator>
          <JobsStatus
            className={classnames({
              expired: status === 'Vaga expirada',
              withContractingWorkalove:
                status === 'Finalizadas com contratação pelo sistema',
              withContractingExternal:
                status === 'Finalizada com contratação externa',
              unpublishJob: status === 'Vaga despublicada',
              noContract: status === 'Vaga finalizada sem contratação',
            })}
          />
          <p> {status} </p>
        </VisualIndicator>
        <ContainerButton>
          {!isReadOnly() &&
          !jobs.is_partner_offer &&
          status === 'Vaga expirada' ? (
            <Button
              background="#009291"
              color="#ffffff"
              onClick={handleModalFinish}
            >
              Finalizar Vaga
            </Button>
          ) : null}

          {jobs.feedback && (
            <FeedbackButton onClick={handleClickFeedBack}>
              Olhar Feedback
            </FeedbackButton>
          )}

          {isReadOnly() &&
          !jobs.is_partner_offer &&
          status === 'Vaga expirada' ? (
            <JobDetailButton
              jobID={jobID}
              isFromPartners={jobs.is_partner_offer}
              externalJobOffer={jobs.is_external}
              style={
                jobs.is_partner_offer || status !== 'Vaga expirada'
                  ? {
                      background: '#009291',
                      color: '#ffffff',
                    }
                  : { marginLeft: 0 }
              }
              fullWidth
            >
              Detalhes da Vaga
            </JobDetailButton>
          ) : (
            <JobDetailButton
              jobID={jobID}
              isFromPartners={jobs.is_partner_offer}
              externalJobOffer={jobs.is_external}
              style={
                jobs.is_partner_offer || status !== 'Vaga expirada'
                  ? {
                      background: '#009291',
                      color: '#ffffff',
                      marginLeft: 0,
                      width: '100%',
                    }
                  : undefined
              }
              fullWidth
            >
              Detalhes da Vaga
            </JobDetailButton>
          )}
        </ContainerButton>
      </WrapperInfos>
    </Container>
  );
}
