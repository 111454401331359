import { Form } from '@unform/web';
import ModalInformation from 'components/informationModal';
import { useRef, useState, useEffect } from 'react';
import SearchableSelectUn from 'views/StaffPages/Academic/components/CurriculumMatrix/components/Form/SearchableSelect';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';
import { createListPayload } from 'views/StaffPages/Csm/helpers/createListPayload';
import {
  configHasStudents,
  configNoHasStudents,
} from 'views/StaffPages/Csm/resources/informationModalConfig';
import { valuesFalseOrTrue } from 'views/StaffPages/Csm/resources/selectOptions';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';
import BlockComponentForm from '../../../blockComponentForm';
import { ButtonArrowLeft } from '../../../buttonArrowLeft';
import { SendButton } from '../../../buttonSend';
import { ContainerFooter } from './style';

export default function StepTwo({ fetchingOptions, options }) {
  const formRef = useRef(null);
  const divRef = useRef(null);

  const {
    setFormValues,
    handleClickArrowPrevs,
    formData,
    onCloseModal,
    formMode,
    egressed,
  } = useSendEmail();
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({});
  const [loading, setLoading] = useState(false);

  const {
    student_status,
    courses,
    periods,
    course_level,
    areas,
    professional_experience_areas,
    gamification_level,
    behavioral_attributes,
    personality,
  } = options;

  const isViwerOnly = formMode === 'viwerOnly';
  const isEdit = formMode === 'edit';

  const propertiesIsViwerOnly = {
    isDisabled: true,
    isMulti: true,
    disabled: true,
  };
  const studentStatusOptions = [
    { label: 'Matriculado', value: 'enrolled' },
    { label: 'Egresso', value: 'done' },
  ];

  useEffect(() => {
    const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];
    baseScroll.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (Object.keys(options).length > 0 && Object.keys(formData).length > 0) {
      const updatedFormData = { ...formData };
      if (egressed) {
        updatedFormData.student_status = 'done';
      } else {
        updatedFormData.student_status = 'enrolled';
      }
      formRef.current.setData(updatedFormData);
    }
  }, [options, formData, egressed]);

  const createProperties = {
    isDisabled: fetchingOptions,
    isLoading: fetchingOptions,
    isMulti: true,
  };

  const commonProperties = isViwerOnly
    ? propertiesIsViwerOnly
    : createProperties;

  const BlocksStepTwo = [
    {
      blockTitle: 'Requisitos Acadêmicos',
      components: [
        {
          type: 'select',
          name: 'student_status',
          label: 'Tipo de Estudante',

          props: {
            ...commonProperties,
            options: studentStatusOptions,
            isMulti: false,
            isClearable: true,
            isDisabled: true,
          },
        },
        {
          type: 'select',
          name: 'courses',
          label: 'Curso',
          props: { ...commonProperties, options: courses },
        },
        {
          type: 'select',
          name: 'periods',
          label: 'Período',
          props: { ...commonProperties, options: periods },
        },
        {
          type: 'select',
          name: 'course_level',
          label: 'Grau de Escolaridade',
          props: { ...commonProperties, options: course_level },
        },
      ],
    },
    {
      blockTitle: 'Status profissional',
      globalProps: { ...commonProperties },
      components: [
        {
          type: 'select',
          name: 'areas',
          label: 'Competências Técnicas',
          props: { options: areas, ...commonProperties },
        },
        {
          type: 'select',
          name: 'professional_experience_areas',
          label: 'Setor de Experiência',
          props: {
            options: professional_experience_areas,
            ...commonProperties,
          },
        },
      ],
    },
    {
      blockTitle: 'Soft skills',
      globalProps: { ...commonProperties },
      components: [
        {
          type: 'select',
          name: 'behavioral_attributes',
          label: 'Comportamental',
          props: { options: behavioral_attributes, ...commonProperties },
        },
        {
          type: 'select',
          name: 'personality',
          label: 'Personalidade',
          props: { options: personality, ...commonProperties },
        },
      ],
    },
    {
      blockTitle: 'Gamificação',
      globalProps: { ...commonProperties },
      components: [
        {
          type: 'select',
          name: 'gamification_level',
          label: 'Nível',
          props: { options: gamification_level, ...commonProperties },
        },
      ],
    },
  ];

  const genereteBlocks = () => {
    return BlocksStepTwo.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        components={block.components}
      />
    ));
  };

  const actionsModal = {
    cancel: () => {
      setModalInformationOpen(false);
    },
    confirm: async (filter, data) => {
      await sendEmailApi.postCreateList(
        createListPayload(filter, data),
        egressed
      );
      setModalInformationOpen(false);
      onCloseModal();
    },
    edit: async (filter, data, id) => {
      await sendEmailApi.putEdit(createListPayload(filter, data), id);
      setModalInformationOpen(false);
      onCloseModal();
    },
    confirmError: () => {
      setModalInformationOpen(false);
    },
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    const mounted = { ...formData, ...data };
    const options = ['periods', 'name', 'external_id', 'areas'];
    const instaceFilter = new createFilterObject(['listName', 'id'], {
      commaSeparatedFields: options,
    });
    const [queryParam, mountedFilter] = instaceFilter.convert(mounted);
    const count = await sendEmailApi.getStudentCount(queryParam, egressed);
    setLoading(false);

    if (count > 0) {
      const objectConfig = configHasStudents(
        count,
        formData.listName,
        actionsModal,
        mountedFilter,
        mounted,
        isEdit,
        formData.id
      );
      setCurrentConfig(objectConfig);
      setModalInformationOpen(true);
      return;
    }
    const objectConfig = configNoHasStudents(
      count,
      formData.listName,
      actionsModal
    );
    setCurrentConfig(objectConfig);
    setModalInformationOpen(true);
  };

  const ClickArrowPrevs = (e) => {
    const data = formRef?.current?.getData();
    setFormValues({ ...formData, ...data });
    handleClickArrowPrevs(e);
  };

  return (
    <div ref={divRef}>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={currentConfig}
        buttonConfirmFunction={currentConfig.buttonConfirmFunction}
        buttonCancelFunction={currentConfig.buttonCancelFunction}
      />
      <Form ref={formRef} onSubmit={handleSubmit}>
        {genereteBlocks()}

        <ContainerFooter>
          <ButtonArrowLeft onClick={ClickArrowPrevs} />
          <SendButton disabled={loading || isViwerOnly}>
            {loading && <Loading color={'#fff'} />}
            Salvar
          </SendButton>
        </ContainerFooter>
      </Form>
    </div>
  );
}
