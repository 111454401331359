import React, { createContext, useState, useEffect } from 'react';

export const ListPublishedByTheGroupContext = createContext();

export default function ListPublishedByTheGroupProvider({ children }) {
  const [educationalGroupEventsCount, setEducationalGroupEventCount] =
    useState(0);
  const [educationalGroupCoursesCount, setEducationalGroupCoursesCount] =
    useState(0);
  const [educationalGroupProgramsCount, setEducationalGroupProgramsCount] =
    useState(0);
  const [
    educationalGroupAnnouncementsCount,
    setEducationalGroupAnnouncementsCount,
  ] = useState(0);
  const [educationalGroupTrailsCount, setEducationalGroupTrailsCount] =
    useState(0);
  const [educationalGroupEbooksCount, setEducationalGroupEbooksCount] =
    useState(0);
  const [educationalGroupArticlesCount, setEducationalGroupArticlesCount] =
    useState(0);
  const [educationalGroupChallengesCount, setEducationalGroupChallengesCount] =
    useState(0);
  const [educationalGroupSeriesCount, setEducationalGroupSeriesCount] =
    useState(0);
  const [countGroupAttractionsSum, setCountGroupAttractionsSum] = useState(0);

  const [
    currentPageEducationalGroupEvents,
    setCurrentPageEducationalGroupEvents,
  ] = useState(0);
  const [
    currentPageEducationalGroupCourses,
    setCurrentPageEducationalGroupCourses,
  ] = useState(0);
  const [
    currentPageEducationalGroupPrograms,
    setCurrentPageEducationalGroupPrograms,
  ] = useState(0);
  const [
    currentPageEducationalGroupAnnouncements,
    setCurrentPageEducationalGroupAnnouncements,
  ] = useState(0);
  const [
    currentPageEducationalGroupTrails,
    setCurrentPageEducationalGroupTrails,
  ] = useState(0);
  const [
    currentPageEducationalGroupEbooks,
    setCurrentPageEducationalGroupEbooks,
  ] = useState(0);
  const [
    currentPageEducationalGroupArticles,
    setCurrentPageEducationalGroupArticles,
  ] = useState(0);
  const [
    currentPageEducationalGroupChallenges,
    setCurrentPageEducationalGroupChallenges,
  ] = useState(0);
  const [
    currentPageEducationalGroupSeries,
    setCurrentPageEducationalGroupSeries,
  ] = useState(0);

  const [educationalGroupEvents, setEducationalGroupEvents] = useState(null);
  const [educationalGroupCourses, setEducationalGroupCourses] = useState(null);
  const [educationalGroupPrograms, setEducationalGroupPrograms] =
    useState(null);
  const [educationalGroupCommunicate, setEducationalGroupCommunicate] =
    useState(null);
  const [educationalGroupTrails, setEducationalGroupTrails] = useState(null);
  const [educationalGroupEbooks, setEducationalGroupEbooks] = useState(null);
  const [educationalGroupArticle, setEducationalGroupArticle] = useState(null);
  const [educationalGroupChallenge, setEducationalGroupChallenge] =
    useState(null);
  const [educationalGroupSerie, setEducationalGroupSerie] = useState(null);

  const [refreshCountGroup, setRefreshCountGroup] = useState(false);

  const result =
    educationalGroupEventsCount +
    educationalGroupCoursesCount +
    educationalGroupProgramsCount +
    educationalGroupAnnouncementsCount +
    educationalGroupTrailsCount +
    educationalGroupEbooksCount +
    educationalGroupArticlesCount +
    educationalGroupChallengesCount +
    educationalGroupSeriesCount;

  useEffect(() => {
    if (refreshCountGroup) {
      setCountGroupAttractionsSum(result);
      setRefreshCountGroup(false);
    }
  }, [refreshCountGroup]);

  return (
    <ListPublishedByTheGroupContext.Provider
      value={{
        refreshCountGroup,
        setRefreshCountGroup,
        currentPageEducationalGroupEvents,
        setCurrentPageEducationalGroupEvents,
        currentPageEducationalGroupCourses,
        setCurrentPageEducationalGroupCourses,
        currentPageEducationalGroupPrograms,
        setCurrentPageEducationalGroupPrograms,
        currentPageEducationalGroupAnnouncements,
        setCurrentPageEducationalGroupAnnouncements,
        currentPageEducationalGroupTrails,
        setCurrentPageEducationalGroupTrails,
        currentPageEducationalGroupEbooks,
        setCurrentPageEducationalGroupEbooks,
        currentPageEducationalGroupArticles,
        setCurrentPageEducationalGroupArticles,
        currentPageEducationalGroupChallenges,
        setCurrentPageEducationalGroupChallenges,
        currentPageEducationalGroupSeries,
        setCurrentPageEducationalGroupSeries,
        educationalGroupEventsCount,
        educationalGroupCoursesCount,
        educationalGroupProgramsCount,
        educationalGroupAnnouncementsCount,
        educationalGroupTrailsCount,
        educationalGroupEbooksCount,
        educationalGroupArticlesCount,
        educationalGroupChallengesCount,
        educationalGroupSeriesCount,
        countGroupAttractionsSum,
        setCountGroupAttractionsSum,
        setEducationalGroupAnnouncementsCount,
        setEducationalGroupEventCount,
        setEducationalGroupCoursesCount,
        setEducationalGroupProgramsCount,
        setEducationalGroupTrailsCount,
        setEducationalGroupEbooksCount,
        setEducationalGroupArticlesCount,
        setEducationalGroupChallengesCount,
        setEducationalGroupSeriesCount,

        educationalGroupEvents,
        setEducationalGroupEvents,
        educationalGroupCourses,
        setEducationalGroupCourses,
        educationalGroupPrograms,
        setEducationalGroupPrograms,
        educationalGroupCommunicate,
        setEducationalGroupCommunicate,
        educationalGroupTrails,
        setEducationalGroupTrails,
        educationalGroupEbooks,
        setEducationalGroupEbooks,
        educationalGroupArticle,
        setEducationalGroupArticle,
        educationalGroupChallenge,
        setEducationalGroupChallenge,
        educationalGroupSerie,
        setEducationalGroupSerie,
      }}
    >
      {children}
    </ListPublishedByTheGroupContext.Provider>
  );
}
