import React, { useState } from 'react';
import styled from 'styled-components';
import { IoCopyOutline } from 'react-icons/io5';
import Tooltip from 'components/NewTooltip';

/**
 * A button for copying text to the clipboard.
 *
 * @param {string} textToCopy - The text to be copied to the clipboard.
 * @param {string} [universityColor='#009291'] - The color to be used for the copy icon. Default is '#009291'.
 * @param {string} tooltipMessage - The message displayed when the user hovers over the button.
 * @param {string} [tooltipColor] - The color of the tooltip. If not specified, the default color defined by the Tooltip component will be used.
 * @param {string} [tooltipDirection='top'] - The direction in which the tooltip should appear. Options: 'top', 'bottom', 'left', 'right'. Default is 'top'.
 * @returns {JSX.Element} - The copy button component.
 */

const CopyLinkButton = ({
  textToCopy,
  universityColor = '#009291',
  tooltipMessage,
  tooltipColor,
  tooltipDirection = 'top',
}) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(textToCopy);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div>
      <Tooltip
        text={tooltipMessage}
        colorSchema={tooltipColor}
        direction={tooltipDirection}
        minWidth="50px"
      >
        <BaseCopy onClick={copyToClipboard}>
          <IoCopyOutline color={universityColor} size={20} />
        </BaseCopy>
      </Tooltip>
    </div>
  );
};

export default CopyLinkButton;

const BaseCopy = styled.button`
  width: fit-content;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-top: 6px;
  margin-left: 8px;
  cursor: pointer;
`;
