import React, { useContext, useRef, useEffect, useState, useMemo } from 'react';
import {
  Box,
  CloseButton,
  Container,
  ContainerButton,
  Content,
  GreenButton,
  WrapperMessage,
  WrapperTitle,
  BubbleDialog,
  ErrorMessage,
} from '../ModalStylingPattern/styles';
import Wall from 'assets/JobOffer/IconPerson.svg';
import InputDesc from '../../../../../../../../components/Opportunities/Inputs/InputDescription';
import { Form } from '@unform/web';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';

import { GrFormClose } from 'react-icons/gr';
import { unpublishProject } from 'views/StaffPages/Opportunities/services';
import * as Yup from 'yup'
export default function ModalUnpublishProject(props) {
  const {
    extensionProjectId, 
    modalFeedbackUnpublish, setModalFeedbackUnpublish,
    getAllFutureProjects,
    getAllOpenForSubscription,
    getAllInProgressProjects,
  } = useContext(ExtensionProjectsContext);

  const { isOpen, setOpenModalUnpublish, closeTheParentModal } = props;
  const [error, setError] = useState(false)
  const modalUnpublish = useRef();
  const formRef = useRef();

  useEffect(() => {
    setModalFeedbackUnpublish(false)
  }, [])

  const closeModal = () => {
    setOpenModalUnpublish(false);
  };

  const closeModalClick = (e) => {
    if (modalUnpublish.current === e.target) {
      setOpenModalUnpublish(false);
    }
  };

  const closeModalFeedback = (e) => {
    setOpenModalUnpublish(false);
    closeTheParentModal(false);

    getAllFutureProjects(true)
    getAllOpenForSubscription(true)
    getAllInProgressProjects(true)
  };

  const handleUpdateFeedback = async (data) => {
    try {
      const schema = Yup.object().shape({
        general_feedback: Yup.string().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      await unpublishProject(extensionProjectId, data);
      setModalFeedbackUnpublish(true)
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setError(true)
      }
    }
  }

  const memoDescInput = useMemo(() => {
    return (
      <Form ref={formRef} onSubmit={handleUpdateFeedback}>
        <InputDesc 
          name="general_feedback"
          error={error}
        />
        { error ? <ErrorMessage> Esse campo é obrigatório! </ErrorMessage> : null }
        <ContainerButton>
          <GreenButton 
            marginTopButton="15px" 
            type="submit"
          >
            DESPUBLICAR PROJETO
          </GreenButton>
        </ContainerButton>
      </Form>
    );
  }, [error]);

  const FormFeedback = () => {
    return (
      <Container isOpen={isOpen}>
        <Box ref={modalUnpublish} onClick={closeModalClick}>
          <Content size="unpublish">
            <CloseButton onClick={closeModal}>
              <GrFormClose size={18} />
            </CloseButton>

            <WrapperTitle>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog widthBubble="201px" heightBubble="54px">
                <span> Despublicar projeto </span>
              </BubbleDialog>
            </WrapperTitle>

            <WrapperMessage
              fontMessage="18px"
              lineHeigthMessage="1.6rem"
              positionMessage="start"
            >
              Para despublicar um projeto, você deve adicionar um feedback para
              os estudantes que já fizeram a inscrição.
            </WrapperMessage>
            {memoDescInput}
          </Content>
        </Box>
      </Container>
    );
  };

  const FeedbackSent = () => {
    return (
      <Container>
        <Box>
          <Content>
            <WrapperTitle>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog widthBubble="240px" heightBubble="71px">
                <span> Feedback enviado com sucesso! </span>
              </BubbleDialog>
            </WrapperTitle>

            <WrapperMessage
              fontMessage="20px"
              lineHeigthMessage="28px"
              positionMessage="center"
            >
              O estudante conseguirá visualizar o feedback enviado na
              plataforma.
            </WrapperMessage>

            <ContainerButton>
              <GreenButton
                widthButton="211px"
                marginTopButton="10px"
                type="button"
                onClick={closeModalFeedback}
              >
                OK, ENTENDI
              </GreenButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    );
  };

  return modalFeedbackUnpublish ? <FeedbackSent /> : <FormFeedback />

}
