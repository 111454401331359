import { ChildrenContainer, CircularProgressWrapper } from '../style';
import { HeaderInformation } from './style';
import MarketItem from '../MarketItem';
import AcademicModelingApi from 'services/university/academicModeling';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAcademicModeling } from '../../../../contexts/AcademicModelingContext';
import { CircularProgress } from '../CircularProgress';
import { Divider, SeeMoreButton } from '../../../../style';
import MarketItemSkeleton from '../MarketItem/MarketItemSkeleton';
import GenericMessage from 'components/GenericMessage';
import { useDebounce } from '../../../../hooks/useDebounce';
import { ContainerSearch, SearchInput } from '../../StepOne/style';

export function NotContemplated({ isTendencies }) {
  const {
    selectedCourse,
    marketRequirementsMetrics,
    marketTendenciesMetrics,
  } = useAcademicModeling();

  const [hasNextPage, setHasNextPage] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);

  const metrics = useMemo(() => {
    if (isTendencies) {
      return marketTendenciesMetrics;
    }
    return marketRequirementsMetrics;
  }, [isTendencies, marketRequirementsMetrics, marketTendenciesMetrics]);
  const [notContemplated, setNotContemplated] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    setNotContemplated([]);
    const searchQuery = debouncedSearchTerm
      ? `name=${debouncedSearchTerm}&`
      : '';
    const queryParams = `?${searchQuery}limit=20&offset=0`;
    getNotContemplated(queryParams);
  }, [debouncedSearchTerm]);

  const getNotContemplated = async (queryParams = '?limit=20&offset=0') => {
    setRequestInProgress(true);
    let list;
    let responseHasNextPage;
    if (isTendencies) {
      const {
        results,
        next,
      } = await AcademicModelingApi.getNotContemplatedMarketTendencies(
        selectedCourse.id,
        queryParams
      );
      responseHasNextPage = !!next;
      list = results;
    } else {
      const {
        results,
        next,
      } = await AcademicModelingApi.getNotContemplatedMarketRequirements(
        selectedCourse.id,
        queryParams
      );
      responseHasNextPage = !!next;
      list = results;
    }
    setHasNextPage(responseHasNextPage);
    setNotContemplated(list);
    setRequestInProgress(false);
  };

  function removeItemFromList(id) {
    const newList = notContemplated.filter((item) => item.id !== id);
    setNotContemplated(newList);
  }

  const mapNotContemplated = useMemo(
    () =>
      notContemplated.map((item, index) => (
        <Fragment key={item.id}>
          <MarketItem
            marketItem={item}
            removeItemFromList={removeItemFromList}
            isTendencies={isTendencies}
            getNotContemplated={getNotContemplated}
          />
          {index !== notContemplated.length - 1 && (
            <Divider color={'#EEF2F4'} />
          )}
        </Fragment>
      )),
    [notContemplated]
  );

  const handleNextPage = useCallback(async () => {
    setRequestInProgress(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    let responseData;
    const searchQuery = debouncedSearchTerm
      ? `name=${debouncedSearchTerm}&`
      : '';
    if (isTendencies) {
      responseData = await AcademicModelingApi.getNotContemplatedMarketTendencies(
        selectedCourse.id,
        `?${searchQuery}limit=20&offset=${offset}`
      );
    } else {
      responseData = await AcademicModelingApi.getNotContemplatedMarketRequirements(
        selectedCourse.id,
        `?${searchQuery}limit=20&offset=${offset}`
      );
    }
    if (responseData) {
      setNotContemplated([...notContemplated, ...responseData.results]);
      setHasNextPage(!!responseData.next);
    }
    setRequestInProgress(false);
  }, [notContemplated, selectedCourse, page]);

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (20 - 7 + 1) + 7);
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  return (
    <ChildrenContainer>
      <header>
        <div>
          <span>Sugestões de ofertas de oportunidades de aprendizagem</span>
          <p>
            {isTendencies
              ? 'Essas são as tendências de mercado não contempladas'
              : 'Esses são os requisitos de mercado não contemplados'}{' '}
            em sua Matriz Curricular atual. Para aumentar sua pontuação no
            indicador de trabalhabilidade, publique oportunidades de
            aprendizagem para{' '}
            {isTendencies ? 'essas tendências.' : 'esses requisitos.'}{' '}
          </p>
        </div>
        <CircularProgressWrapper>
          <CircularProgress progress={metrics.percent_not_contemplated} />
        </CircularProgressWrapper>
      </header>
      <ContainerSearch justify={'flex-start'}>
        <SearchInput
          placeholder={`Buscar por ${isTendencies ? 'tendência' : 'requisito'}`}
          onChange={handleSearch}
        />
      </ContainerSearch>
      {metrics.not_contemplated > 0 && (
        <HeaderInformation>
          <span className="HeaderOne">
            {isTendencies ? 'TENDÊNCIA' : 'REQUISITO'}
          </span>
          <span className="HeaderTwo">OPORTUNIDADES RELACIONADAS</span>
          <span className="HeaderThree">AÇÕES</span>
        </HeaderInformation>
      )}
      {metrics.not_contemplated === 0 ? (
        <GenericMessage
          title="Muito bem!"
          subtitle={`${
            isTendencies
              ? 'No momento, você não possui tendências a serem contempladas de acordo com o seu perfil de egresso. Na aba "contempladas” você poderá ver todas as disciplinas e tags que contemplam cada tendência de mercado.'
              : 'No momento, você não possui requisitos a serem contemplados de acordo com o seu perfil de egresso. Na aba "contemplados” você poderá ver todas as disciplinas e tags que contemplam cada requisito de mercado.'
          }  `}
        />
      ) : (
        mapNotContemplated
      )}
      {requestInProgress &&
        metrics.not_contemplated !== 0 &&
        Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
          <Fragment key={index}>
            {(notContemplated.length > 0 || index > 0) && (
              <Divider color={'#EEF2F4'} />
            )}
            <MarketItemSkeleton notContemplated />
          </Fragment>
        ))}
      {hasNextPage && !requestInProgress && (
        <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
      )}
    </ChildrenContainer>
  );
}
