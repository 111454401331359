import { Container, SwitchInput, SwitchSlider } from './styled';

export function Switch(props) {
  const {
    activeLabel = 'Ativo',
    inactiveLabel = 'Inativo',
    showLabel = true,
    value,
    setValue,
    onChange,
  } = props;

  const handleChange = () => {
    setValue(!value);
    onChange && onChange(!value);
  };

  return (
    <Container>
      <SwitchInput type="checkbox" checked={value} onChange={handleChange} />
      <SwitchSlider checked={value} />
      {showLabel && <p>{value ? activeLabel : inactiveLabel}</p>}
    </Container>
  );
}
