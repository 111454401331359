import { Fragment, useEffect, useRef, useState } from 'react';
import BlockComponentForm from '../../blockComponentForm';
import { Form } from '@unform/web';
import { CountLists } from './style';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import { ContainerFooter } from '../List/ActiveStudents/style';
import { ButtonArrowLeft } from '../../buttonArrowLeft';
import { SendButton } from '../../buttonSend';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import ModalInformation from 'components/informationModal';
import { isNumberStr } from 'utils/isNumber';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';

export default function StepTwo({ options }) {
  const formRef = useRef(null);

  const { lists } = options;

  const { handleClickArrowPrevs, formData, onCloseModal } = useSendEmail();
  const { egressed } = useCsm();
  const [count, setCount] = useState(0);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalFailed, setModalFailed] = useState(false);
  const [error, setError] = useState(false);

  const handleSelectList = async (event) => {
    const value = event.map((item) => item.value);
    if (value.length < 1) {
      return;
    }
    const createQuery = new createFilterObject();
    const [query] = createQuery.convert({ lists: value });
    const { total } = await sendEmailApi.getCountLists(query, egressed);
    if (total && isNumberStr(total)) {
      setCount(total);
      setError(false);
    } else {
      setModalFailed(true);
      setError(true);
    }
  };

  const BlocksStepOne = [
    {
      blockTitle: 'Escolher destinatários',
      components: [
        {
          type: 'select',
          name: 'lists',
          label: 'Nome da lista',
          props: { options: lists, onChange: handleSelectList, isMulti: true },
        },
      ],
    },
  ];

  useEffect(() => {
    const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];
    baseScroll.scrollTop = 0;
  }, []);

  const generateBlocks = () => {
    return BlocksStepOne.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        components={block.components}
      />
    ));
  };

  const handleSubmit = async () => {
    if (error) {
      setModalFailed(true);
      return;
    }
    setModalInformationOpen(true);
  };

  const handleConfirmSubmit = async (data) => {
    let itemsQuantity = count.replace('.', '');
    await sendEmailApi.postCreateCampaign(
      {
        ...data,
        ...formData,
        items_quantity: itemsQuantity,
      },
      egressed
    );
    onCloseModal();
    setModalInformationOpen(false);
  };

  const handleCancel = () => {
    setModalInformationOpen(false);
  };
  const handleFailed = () => {
    setModalFailed(false);
  };

  const configModalInformation = {
    modalHeight: 380,
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja enviar este e-mail para ${count} ${
      count > 1 ? 'estudantes' : 'estudante'
    }? <br/><br/> O envio de todos os e-mails pode demorar de acordo com o volume de estudantes.`,
    buttonConfirmText: 'SIM, ENVIAR E-MAIL',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    buttonConfirmFunction: () =>
      handleConfirmSubmit(formRef?.current?.getData()),
    buttonCancelFunction: () => handleCancel(),
  };

  const failedModalInformation = {
    modalHeight: 380,
    bubbleText: 'Oops!',
    ParagraphText: `Houve um erro no processamento das listas, entre em contato com o suporte.`,
    buttonConfirmText: 'OK!',
    buttonConfirmFunction: () => handleFailed(),
  };

  const singularWords =
    count > 1 ? 'destinatários selecionados' : 'destinatário selecionado';

  return (
    <Fragment>
      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={configModalInformation}
        buttonConfirmFunction={configModalInformation.buttonConfirmFunction}
        buttonCancelFunction={configModalInformation.buttonCancelFunction}
      />
      <ModalInformation
        modalOpenState={modalFailed}
        config={failedModalInformation}
        buttonConfirmFunction={failedModalInformation.buttonConfirmFunction}
      />
      <Form ref={formRef} onSubmit={handleSubmit}>
        {generateBlocks()}

        {count > 0 && (
          <CountLists>
            <strong>{count} </strong>
            {singularWords}
          </CountLists>
        )}
        <ContainerFooter>
          <ButtonArrowLeft onClick={handleClickArrowPrevs} />
          <SendButton>Enviar campanha</SendButton>
        </ContainerFooter>
      </Form>
    </Fragment>
  );
}
