import React, { useEffect, useState, Fragment } from 'react';

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getReports,
  getReportsChartData,
} from 'services/university/socioeconomicReport';
import { func, shape } from 'prop-types';

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Container,
  CustomExpansionPanel as ExpansionPanel,
  CustomExpansionPanelSummary as ExpansionPanelSummary,
} from './styles';
import ReportDetail from './ReportDetail';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CustomReportFormFilters from './CustomReportFormFilters';

const ReportList = ({ classes, reportOptions, handleIsLoadingState }) => {
  const [reports, setReports] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const handleFilters = async (filters = {}) => {
    setLoading(true);
    setReports([]);
    const data = await getReports(filters);
    const chartData = await getReportsChartData(filters);
    if (Array.isArray(chartData)) {
      const reportData = data.map((item) => {
        const newItem = { ...item };
        const surveyResults = chartData.find((survey) => survey.id === item.id);
        newItem.chartData = surveyResults && surveyResults.questions;
        return newItem;
      });
      setReports(reportData);
      setLoading(false);
    } else {
      setReports(data);
      setLoading(false);
    }
    handleIsLoadingState(false);
  };
  useEffect(() => {
    handleIsLoadingState(true);
    handleFilters();
  }, []);

  const updateReport = (report, index) => {
    const updatedReport = [...reports];
    updatedReport[index] = report;
    setReports(updatedReport);
  };
  return (
    <Fragment>
      <GridContainer
        className={classes.fullScreenGridContainer}
        style={{ marginTop: 20, padding: 0 }}
      >
        <GridItem style={{ position: 'relative' }} xs md={12} lg={8} xl={9}>
          {reports.length > 0 ? (
            reports.map((report, index) => (
              <ExpansionPanel key={report.id} style={{ borderRadius: '20px' }}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  activeStatus={!report.unpublished_at}
                >
                  <h1>Relatório</h1>
                  <div style={{ marginTop: 10 }}>
                    <div className="name">
                      <h1>{report.title}</h1>
                      {report.unpublished_at ? (
                        <p>
                          Período de aplicação:{' '}
                          {report.created_at.split(' ')[0]} até{' '}
                          {report.unpublished_at.split(' ')[0]}
                        </p>
                      ) : (
                        <p>Ativo desde {report.created_at.split(' ')[0]}</p>
                      )}
                    </div>
                    {!report.unpublished_at ? (
                      <div className="status">
                        <p>Ativo desde</p>
                        <p>{report.created_at.split(' ')[0]}</p>
                      </div>
                    ) : (
                      <div className="status">
                        <p>Inativo desde</p>
                        <p>{report.unpublished_at.split(' ')[0]}</p>
                      </div>
                    )}
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  style={{ padding: '0', display: 'block' }}
                >
                  <div style={{ maxWidth: '100%' }}>
                    <p style={{ marginLeft: '40px' }}>
                      Responsável pela publicação: {report.published_by.name}
                    </p>
                    <ReportDetail
                      reportDetail={report}
                      updateReport={updateReport}
                      index={index}
                      reportOptions={reportOptions}
                    />
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          ) : isLoading ? (
            <Container>
              <h4 style={{ fontWeight: 300 }}> Carregando...</h4>
            </Container>
          ) : (
            reports.length <= 0 && (
              <Container>
                <h4 style={{ fontWeight: 300 }}>
                  {' '}
                  Não há Relatórios Personalizados criados
                </h4>
              </Container>
            )
          )}
        </GridItem>
        <GridItem xs md={12} lg={4} xl={3}>
          <CustomReportFormFilters
            handleSubmitFilters={handleFilters}
            reportOptions={reportOptions}
          />
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

ReportList.propTypes = {
  classes: shape().isRequired,
  reportOptions: shape.isRequired,
  handleIsLoadingState: func.isRequired,
};

export default withStyles(styles)(ReportList);
