import React from 'react';
import { FilterType } from './Filter';
import { useFilterContext } from '../contexts/FilterContext';
import { handleFieldMask } from 'utils/validation';

export function FilterText({
  name,
  placeholder,
  onPaste,
  disabled,
  applyMask = false,
  mask = null,
  ...props
}) {
  const { filterState, updateFilterState } = useFilterContext();

  const handleKeyUp = (event) => {
    if (applyMask && mask) {
      updateFilterState(handleFieldMask(event, mask), name, FilterType.TEXT);
    }
  };

  return (
    <input
      className={`filter-text`}
      name={name}
      type="text"
      disabled={disabled}
      value={filterState[name] || ''}
      onChange={(e) => {
        updateFilterState(e.target.value, name, FilterType.TEXT);
      }}
      onPaste={(e) => {
        onPaste && onPaste(e);
        updateFilterState(e.target.value, name, FilterType.TEXT);
      }}
      placeholder={placeholder}
      onKeyUp={handleKeyUp}
      {...props}
    />
  );
}
