import React, { useContext } from 'react';
import {
  Title,
  Paragraph,
  ContainerCards,
  Card,
  IconImg,
  Text,
  Infos,
  InfosOne,
  TextComposition,
  ContainerInformation,
  ContainerIconTooltip,
} from '../styled';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';
import { IconCurriculumReward } from 'assets/img/iconsCurriculum/iconCurriculumReward';

import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import useStepOneSorting from './useStepOneSorting';
import { IconInformation } from 'assets/img/iconsCurriculum/iconInformation';
import Tooltip from 'components/NewTooltip';

export default function StepOne() {
  const { stepOneData, gamificationLevel, universityColor } =
    useContext(ProfileContext);
  const gamificationItems = useStepOneSorting(stepOneData);
  const levelOne = gamificationLevel === 1;
  const moreLevelOne = gamificationLevel > 1;

  const returnColorOne = () => {
    return moreLevelOne ? universityColor : '#606062';
  };

  const returnIconLevelOne = () => {
    if (gamificationLevel <= 1) {
      return <IconBlocked color="#C4C4C4" />;
    }
    if (gamificationLevel > 1) {
      return <IconCurriculumReward color={universityColor} />;
    }
  };

  const returnTitleLevelOne = () => {
    if (levelOne) {
      return 'Recompensa';
    }
    if (gamificationLevel > 1) {
      return `Recompensa Resgatada`;
    }
  };

  return (
    <>
      <Title>Jornada do autoconhecimento</Title>
      <Paragraph>
        Complete os itens abaixo para ter acesso ao seu currículo do futuro e
        avançar para a próxima etapa.
      </Paragraph>

      <ContainerCards>
        {gamificationItems.map((item, index) => (
          <Card key={index}>
            <IconImg>{item.icon}</IconImg>
            <TextComposition>
              <Text>{item.title}</Text>
              <Infos>{item.info}</Infos>
            </TextComposition>
          </Card>
        ))}

        <Card>
          <IconImg>{returnIconLevelOne()}</IconImg>
          <TextComposition>
            <ContainerInformation>
              <Text>{returnTitleLevelOne()}</Text>
              {moreLevelOne ? (
                <Tooltip
                  text={`Agora você pode gerar o seu Currículo do Futuro, 
                    acessar as vagas mais alinhadas ao seu perfil e 
                    começar a jornada do protagonismo!`}
                  colorSchema={'black'}
                  direction={'left'}
                  minWidth="470px"
                  isMultiLine={true}
                >
                  <ContainerIconTooltip>
                    <IconInformation color={'#606062'} />
                  </ContainerIconTooltip>
                </Tooltip>
              ) : null}
            </ContainerInformation>
            <InfosOne returnColor={returnColorOne}>
              Currículo Profissional
            </InfosOne>
          </TextComposition>
        </Card>
      </ContainerCards>
    </>
  );
}
