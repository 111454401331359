import { Fragment, useEffect, useRef } from 'react';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import HeaderForm from '../../HeaderForm';
import ActiveStudentsForm from './ActiveStudents';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import InactiveStudentsForm from './InactiveStudents';
import Input from 'views/StaffPages/Opportunities/Components/Form/input';
import { Form } from '@unform/web';
import { TitleGreen } from '../../TitleGreen';
import { typesStudent } from 'views/StaffPages/Csm/resources/selectTypeStudent';
import { ButtonArrowLeft } from '../../buttonArrowLeft';
import { ButtonArrowRight } from '../../buttonArrowRight';

export default function EngineSteps() {
  const formRef = useRef(null);
  const {
    setActiveStudentsForm,
    activeStudentsForm,
    step,
    formData,
    formMode,
    egressed,
  } = useSendEmail();

  const HeaderMessage = {
    create: 'Gerar lista',
    edit: 'Editar lista',
    viwerOnly: 'Visualizar lista',
  };

  const currentForm = activeStudentsForm ? (
    <ActiveStudentsForm engineRef={formRef} egressed={egressed} />
  ) : (
    <InactiveStudentsForm engineRef={formRef} egressed={egressed} />
  );

  const handleChangeStudentType = (event) => {
    const value = event.value;
    setActiveStudentsForm(value);
  };

  useEffect(() => {
    if (formRef.current && step === 1) {
      const currentObject = typesStudent.find(
        (type) => type.value === activeStudentsForm
      );
      formRef.current.setFieldValue('studentSitu', currentObject);
    }
  }, [step]);

  const handleSubmit = (data) => {
    const { listName } = data;
    if (!listName) {
      formRef.current.setFieldError('listName', 'O nome é obrigatorio');
      return { error: true };
    }
    return data;
  };

  const returnHeaderNameAndSelect = () => {
    if (step === 1) {
      return (
        <Form ref={formRef} onSubmit={handleSubmit} initialData={formData}>
          <TitleGreen>Dados básicos</TitleGreen>
          <Input name="listName" label="Nome da lista*" />
          <UnformSelect
            options={typesStudent}
            name="studentSitu"
            label="Situação do estudante no sistema*"
            onChange={handleChangeStudentType}
          />
        </Form>
      );
    }
  };

  return (
    <Fragment>
      <HeaderForm
        title={HeaderMessage[formMode]}
        subTitle={
          egressed ? (
            <>
              Selecione as opções de sua preferência para gerar a lista de{' '}
              <strong>Egresso</strong>.
            </>
          ) : (
            <>
              Selecione as opções de sua preferência para gerar a lista de
              estudantes <strong>Matriculados</strong>.
            </>
          )
        }
      />
      {returnHeaderNameAndSelect()}
      {currentForm}
    </Fragment>
  );
}
