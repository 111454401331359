import { Container, CourseList, SeeMoreButton } from './style';
import { CourseCard } from './components/CourseCard';
import { useAcademicModeling } from './contexts/AcademicModelingContext';
import FullScreenModal from './components/FullScreenModal';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CourseCardSkeleton } from './components/CourseCardSkeleton';
import AcademicModelingApi from 'services/university/academicModeling';
import BaseLayoutContext from 'contexts/base-layout';

export function AcademicModeling() {
  const {
    courses,
    setCourses,
    fullScreenModalOpen,
    isLoadingCourses,
    setIsLoadingCourses,
    hasCoursesNextPage,
    setHasCoursesNextPage,
  } = useAcademicModeling();

  const { setMiniActive, miniActive } = useContext(BaseLayoutContext);

  useEffect(() => {
    const resizeFunction = () => {
      if (window.innerWidth >= 1200) {
        setMiniActive(false);
      } else {
        setMiniActive(true);
      }
    };
    window.addEventListener('resize', resizeFunction);

    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [miniActive]);

  const [page, setPage] = useState(1);

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (20 - 7 + 1) + 7);
  }, []);

  const handleNextPage = useCallback(async () => {
    setIsLoadingCourses(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const response = await AcademicModelingApi.getCourses(
      `?limit=20&offset=${offset}`
    );
    if (response.status === 200) {
      setCourses([...courses, ...response.data.results]);
      setHasCoursesNextPage(!!response.data.next);
    }
    setIsLoadingCourses(false);
  }, [courses, page]);

  if (fullScreenModalOpen) {
    return <FullScreenModal />;
  }

  return (
    <Container>
      <span>
        Aqui você pode definir sua Matriz Curricular atual, descrever os
        principais conteúdos das disciplinas em formato de tags, selecionar as
        atuações profissionais relacionadas ao seu perfil de egresso e ter
        acesso aos relatórios de compatibilidade da sua matriz curricular com os
        requisitos e tendências de mercado.
      </span>
      <strong>Para iniciar, selecione o curso em que deseja trabalhar:</strong>
      <CourseList>
        {courses.map((course) => (
          <CourseCard key={course.id} course={course} />
        ))}
        {isLoadingCourses &&
          Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
            <CourseCardSkeleton key={index} />
          ))}
      </CourseList>
      {hasCoursesNextPage && !isLoadingCourses && (
        <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
      )}
    </Container>
  );
}
