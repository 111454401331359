import {
  Wrapper,
  Modal,
  WrapperTitle,
  IconPersonStyled,
  BubbleDialog,
  H4Styled,
  ParagraphStyled,
  Button,
  RocketWrapper,
  Container,
  Img,
  Rocket,
  Box,
} from './style';
import React, { useMemo, Fragment } from 'react';
import IconPerson from '../../../../assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';

export default function LoadingModal({
  config,
  setModalOpen,
  modalOpenState,
  buttonFunction,
}) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            <RocketWrapper>
              <span>
                <i></i>
              </span>
            </RocketWrapper>

            <h4>{config.bubbleText}</h4>

            <ParagraphStyled>
              {ReactHtmlParser(config.ParagraphText)}
            </ParagraphStyled>

            {config.showButton && (
              <Button
                width={config.buttonWidth}
                height={config.buttonHeight}
                onClick={() => buttonFunction && buttonFunction()}
              >
                {config.buttonText}
              </Button>
            )}
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config]
  );

  return <Fragment>{memorizedModal}</Fragment>;
}

LoadingModal.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    bubbleText: 'titulo modalx',
    ParagraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
  },
};
