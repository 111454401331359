import styled from 'styled-components';
import closeIcon from 'assets/Oportutinites/X.svg';

export const ModalOverlay = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 99999;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  background-color: Rgb(0, 0, 0, 0.6);
  position: fixed;
  transform: translate(-50%, -50%);
`;

export const ModalContainer = styled.div`
  width: auto;
  height: auto;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 0 31.2239px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: absolute;
  left: calc(50% + 130px);
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 960px) {
    left: 50%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;
  height: auto;
  overflow-y: auto;
  max-height: ${(props) => (!!props.maxHeight ? props.maxHeight : '80vh')};
  gap: 1rem;
  align-items: center;

  img {
    width: 80px;
    height: 80px;
  }

  p {
    font-size: 20px;
    color: #606062;
    max-width: 370px;
    text-align: center;
    margin: 0;
  }
`;

export const DialogContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
`;

export const Dialog = styled.div`
  background-color: #009291;
  border-radius: 4px;
  padding: 1rem;
  font-size: 20px;
  color: white;
  position: relative;

  &:before {
    content: '';
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    background: #009291;
    position: absolute;
    left: -8px;
  }
`;

export const CloseButton = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  transition: filter 0.2s;
  background-image: url(${closeIcon});
  margin: 0;
  position: absolute;
  right: 10px;
  top: 10px;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2.5rem 0 2.5rem;
  @media (max-width: 600px) {
    div:first-of-type {
      width: 100%;
    }
  }
`;

export const ActionButton = styled.button`
  background-color: #009291;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  transition: filter 0.2s;
  height: 50px;
  padding: 0 1rem;
  width: fit-content;

  &:hover {
    filter: brightness(0.9);
    cursor: pointer;
  }
`;
