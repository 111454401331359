import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`;
export const AddMentorButton = styled.button`
  width: 160px;
  height: 40px;
  margin-left: auto;
  background-color: #009291;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 9px;
  padding: 2rem;
  margin-top: 1rem;
  width: 100%;
  box-shadow: 0 0 18px 0 #0000001a;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

export const MentorListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;
`;

export const MentorImage = styled.img`
  width: 74px;
  height: 74px;
  border-radius: 50%;
  margin: ${({ margin }) => margin || '0'};
  border: 1px solid #606062;
  z-index: 1;
`;

export const MentorStatusLabel = styled.div`
  height: ${({ height }) => height || '24px'};
  width: fit-content;
  background-color: ${({ status }) =>
    status === 'Ativo' || status === 'active' ? '#D9FFE3' : '#E5E5E5'};
  color: ${({ status }) =>
    status === 'Ativo' || status === 'active' ? '#068E2C' : '#606062'};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: ${({ fontSize }) => fontSize || '10px'};
  padding: 0.5rem;
  font-weight: 700;
`;

export const SeeMoreButton = styled.button`
  display: flex;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #009291;
  margin: 2rem auto 0;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    filter: brightness(0.9);
  }
`;
