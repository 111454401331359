import styled from 'styled-components';

export const ContinerCard = styled.div`
  border: 1px solid #cdcdcd;
  width: 280px;
  min-height: ${props => props.isFinished ? 'auto' : '600px' };
  position: relative;
  border-radius: 12px;
  height: ${props => props.isFinished ? '530px' :'570px'};

  @media (max-width: 671px) {
    width: 230px;
  }
`;

export const Status = styled.div`
  display: flex;
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 10px;
  font-weight: 600;
  padding: 10px;
  line-height: 1rem;
`;

export const ImageStatus = styled.div`
  display: flex;
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: fit-content;
  height: 30px;
  align-items: center;
  padding: 0px 10px;
  font-weight: 400;
  color: white;
  gap: 10px;
  bottom: 10px;
  top: 150px;
  left: 10px;
  border-radius: 8px;
  color: ${({ color }) => color};
`;

export const Image = styled.img`
  width: 278px;
  height: 200px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media (max-width: 671px) {
    width: 229px;
  }
`;

export const ContaineWithoutImage = styled.div`
  background-color: #b8b8b833;
  width: 278px;
  height: 200px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 671px) {
    width: 228px;
  }
`;

export const ContainerTitle = styled.div`
  padding: 10px;

  > h5 {
    margin: 0;
    color: #4b4b4d;
    font-size: 15px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
  }

  > span {
    display: inline-block;
    margin-bottom: 5px;
    color: #777777;
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  height: 140px;
  position: absolute;
  bottom: ${props => props.isFinished ? '17%' :'25%'};
`;

export const ContainerButtons = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  flex-wrap: wrap;
  position: absolute;
  bottom: ${({ hasReaction }) => (hasReaction ? '75px' : '10px')};
  width: 100%;
`;

export const Information = styled.span`
  display: flex;
  gap: 4px;
  color: #767676;
  align-items: center;
  font-weight: 400;

  > div {
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: ${({ color }) => `${color}33`};
    border-radius: 4px;
  }
`;

export const Divider = styled.div`
  width: 90%;
  border-bottom: 1px solid #c4c4c4;
  margin: 20px 14px;

  position: absolute;
  bottom: 44px;
`;

export const CertificateStatus = styled.div`
  background-color: ${({ color }) => `${color}33`};
  width: 140px;
  color: ${({ color }) => `${color}`};
  border-radius: 3px;
  margin: 10px;
  font-weight: 400;
  padding: 1px 10px;
  display: flex;
  justify-content: center;
`;
