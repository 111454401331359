import React, { Component, Fragment, useContext } from 'react';
import { shape, func, string } from 'prop-types';
import classNames from 'classnames';
import * as Yup from 'yup';
import checkSteps from 'utils/checkErrorsStep';

import { Formik } from 'formik';
import StepWizard from 'react-step-wizard';
import { Grid, Button } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import learningTrailsStyle from 'assets/jss/material-dashboard-pro-react/views/learningTrailsStyle';

import {
  getTrailAnswer,
  saveTrailAnswer,
} from 'services/student/learningTrails';

import Contents from './Contents';
import BaseLayoutContext from 'contexts/base-layout';
import useDefineColor from 'constants/colors';

const Nav = (props) => {
  const {
    classes,
    colorScheme,
    stepErrors,
    currentStep,
    errors,
    setFieldTouched,
    openSnackbar,
  } = props;
  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    const {universityColor} = useContext(BaseLayoutContext)

    dots.push(
      <span
        key={`step-${i}`}
        className={`${classes.dot} ${isActive ? classes[`${useDefineColor(universityColor)}stepActive`] : "" }`}
        onClick={() => {
          if (currentStep === 3) {
            setFieldTouched('checkedCount');
          }

          if (i > currentStep + 1 || i === currentStep) {
            return null;
          }

          if (i < currentStep) {
            props.goToStep(i);
          } else if (!stepErrors[currentStep] && i === currentStep + 1) {
            props.goToStep(i);
          } else if (
            checkSteps(stepErrors[currentStep], errors, setFieldTouched) &&
            currentStep + 1 === i
          ) {
            props.goToStep(i);
          } else {
            openSnackbar('Preencha todos os campos obrigatórios.', true);
          }
          return null;
        }}
      >
        {i}
      </span>
    );
  }

  const dotsField = (
    <Grid
      container
      justify="center"
      className={(classes.fullScreenGridContainer, classes.dotsField)}
    >
      <Grid
        item
        xs
        md={8}
        sm={12}
        className={(classes.learningTrailsNav, classes[colorScheme])}
      >
        {dots}
      </Grid>
    </Grid>
  );

  return dotsField;
};

const LiveSchema = Yup.object().shape({
  pageOneQuestionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageOneQuestionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageOneQuestionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageTwoQuestionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageTwoQuestionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageTwoQuestionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageFourQuestionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageFourQuestionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageFourQuestionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageFiveQuestionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageFiveQuestionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  pageFiveQuestionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  checkedCount: Yup.number().min(3, 'Você deve escolher três opções.'),
  remunerationAndPromotionCheck: Yup.bool(),
  remunerationAndPromotion: Yup.string().when('remunerationAndPromotionCheck', {
    is: (value) => value === true,
    then: Yup.string()
      .required('Você deve responder essa pergunta antes de enviar.')
      .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  }),
  contributionCheck: Yup.bool(),
  contribution: Yup.string().when('contributionCheck', {
    is: (value) => value === true,
    then: Yup.string()
      .required('Você deve responder essa pergunta antes de enviar.')
      .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  }),
  competenceCheck: Yup.bool(),
  competence: Yup.string().when('competenceCheck', {
    is: (value) => value === true,
    then: Yup.string()
      .required('Você deve responder essa pergunta antes de enviar.')
      .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  }),
  creativityCheck: Yup.bool(),
  creativity: Yup.string().when('creativityCheck', {
    is: (value) => value === true,
    then: Yup.string()
      .required('Você deve responder essa pergunta antes de enviar.')
      .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  }),
  identityCheck: Yup.bool(),
  identity: Yup.string().when('identityCheck', {
    is: (value) => value === true,
    then: Yup.string()
      .required('Você deve responder essa pergunta antes de enviar.')
      .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  }),
  connectionCheck: Yup.bool(),
  connection: Yup.string().when('connectionCheck', {
    is: (value) => value === true,
    then: Yup.string()
      .required('Você deve responder essa pergunta antes de enviar.')
      .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  }),
});

class TrailWithForm extends Component {
  state = {
    step: 1,
    initialValues: {},
  };

  wizard = React.createRef();

  wizardHolder = React.createRef();

  async componentDidMount() {
    const { data, openSnackbar, metadata } = this.props;
    if (metadata && metadata.role === 'student') {
      const response = await getTrailAnswer(data.id);

      const answer = { ...response.answer };
      answer.checkedCount = answer.checkedCount || 0;

      if (!response.message) {
        this.setState({ initialValues: answer });
      } else {
        openSnackbar(
          'Falha ao carregar as respostas! Tente novamente mais tarde.',
          true
        );
      }
    }
  }

  handleUpdateStep = (newStep) => {
    const { onUpdateStep, data } = this.props;

    onUpdateStep({
      trailId: data.id,
      progress: newStep / data.steps.length,
    });
  };

  handleSaveTrail = async (trail, answer) => {
    const { openSnackbar, metadata } = this.props;
    if (metadata.role === 'student') {
      const payload = { answer };
      const response = await saveTrailAnswer(trail, payload);

      if (response.message) {
        openSnackbar(
          'Falha ao salvar suas respostas! Tente novamente mais tarde.',
          true
        );
      }
    }
  };

  onStepChange = (dataStepChange, answer, trail) => {
    const { initialValues } = this.state;
    const { activeStep, previousStep } = dataStepChange;

    this.setState({ step: activeStep });

    /** TODO: Do this better */
    const container = this.wizardHolder.current;
    container.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });

    this.handleUpdateStep(dataStepChange.activeStep);

    if (activeStep > previousStep && answer !== initialValues) {
      this.handleSaveTrail(trail, answer);
    }
  };

  render() {
    const formValidations = {
      1: ['pageOneQuestionOne', 'pageOneQuestionTwo', 'pageOneQuestionThree'],
      2: ['pageTwoQuestionOne', 'pageTwoQuestionTwo', 'pageTwoQuestionThree'],
      3: [
        'checkedCount',
        'remunerationAndPromotion',
        'contribution',
        'competence',
        'creativity',
        'identity',
        'connection',
      ],
      4: [
        'pageFourQuestionOne',
        'pageFourQuestionTwo',
        'pageFourQuestionThree',
      ],
      5: [
        'pageFiveQuestionOne',
        'pageFiveQuestionTwo',
        'pageFiveQuestionThree',
      ],
    };

    const {
      onSubmit,
      data,
      classes,
      colorScheme,
      openSnackbar,
      metadata,
    } = this.props;
    const { step, initialValues } = this.state;

    return (
      <div
        className={`${classes.fullScreenGridContainer} ${classes[colorScheme]} ${classes.marginB30}`}
        ref={this.wizardHolder}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={metadata.role === 'student' ? LiveSchema : null}
          validateOnChange={false}
          onSubmit={(values) => {
            if (values !== initialValues) {
              this.handleSaveTrail(data.id, values);
            }
            onSubmit({ trailId: data.id });
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldTouched,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <StepWizard
                ref={this.wizard}
                nav={
                  <Nav
                    classes={classes}
                    colorScheme={colorScheme}
                    stepErrors={formValidations}
                    currentStep={step}
                    errors={errors}
                    setFieldTouched={setFieldTouched}
                    openSnackbar={openSnackbar}
                  />
                }
                onStepChange={(stepData) =>
                  this.onStepChange(stepData, values, data.id)
                }
                isLazyMount
              >
                {data.steps.map(({ id, video, form: Form, intro, content }) => (
                  <Grid
                    key={id}
                    container
                    justify="center"
                    className={classes.fullScreenGridContainer}
                    xs={12}
                    lg={10}
                  >
                    <Grid item className={classes.trailDataWrapper}>
                      <h2 className={classes.cardSubtitle}>
                        {data.trailTitle}
                      </h2>
                      {video && (
                        <iframe
                          className={classes.videos}
                          src={video}
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Trilha Sucesso e Superação"
                        />
                      )}
                      {intro && (
                        <Grid
                          item
                          className={`${classes.paddingReset} ${classes.intro}`}
                        >
                          <h4>{intro}</h4>
                        </Grid>
                      )}
                      {content ? (
                        <Fragment>
                          <Contents data={content} colorScheme={colorScheme} />
                        </Fragment>
                      ) : (
                        <Form
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          isSubmitting={isSubmitting}
                          wizard={this.wizard}
                          colorScheme={colorScheme}
                          setFieldValue={setFieldValue}
                          metadata={metadata}
                        />
                      )}
                      <Grid
                        item
                        className={classNames([
                          classes.professionListItem,
                          classes[colorScheme],
                        ])}
                      >
                        {data.steps.length !== step ? (
                          <Button
                            className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                            style={{ color: '#fff' }}
                            onClick={() => {
                              if (step === 3) {
                                setFieldTouched('checkedCount');
                              }

                              if (!formValidations[step]) {
                                this.wizard.current.nextStep();
                              } else if (
                                checkSteps(
                                  formValidations[step],
                                  errors,
                                  setFieldTouched
                                )
                              ) {
                                this.wizard.current.nextStep();
                              } else {
                                openSnackbar(
                                  'Preencha todos os campos obrigatórios.',
                                  true
                                );
                              }
                            }}
                          >
                            <p style={{ color: '#fff', margin: '0px' }}>
                              Continuar
                            </p>
                          </Button>
                        ) : (
                          <Button
                            disabled={isSubmitting}
                            style={{ color: '#fff' }}
                            type="submit"
                            className={`${classes.defaultButton} ${classes.updateProfileButton}`}
                            round
                          >
                            <p style={{ color: '#fff', margin: '0px' }}>
                              Enviar respostas
                            </p>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </StepWizard>{' '}
            </form>
          )}
        />
      </div>
    );
  }
}

TrailWithForm.propTypes = {
  classes: shape.isRequired,
  colorScheme: string.isRequired,
  data: shape.isRequired,
  onSubmit: func.isRequired,
  onUpdateStep: func.isRequired,
  openSnackbar: func.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(learningTrailsStyle)(TrailWithForm);
