import React, { useEffect, useState, useContext, useMemo } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { GrClose } from 'react-icons/gr';
import { genericAdapter } from 'adapters/genericAdapter';
import ReactHtmlParser from 'react-html-parser';
import liked from 'assets/Oportutinites/liked.svg';
import { profileAdapter } from 'adapters/student/profileAdapter';
import { BiLike, BiDislike } from 'react-icons/bi';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';

import {
  ModalWrapper,
  ModalContent,
  CloseButton,
  ContainerBanner,
  FragmentText,
  FragmentInfo,
  Line,
  Footer,
  AccessButton,
  InformationButton,
  ContainerButton,
} from './styles';
import {
  createReactions,
  deleteReactions,
  editReactions,
  getAccess,
  getCompanyChallengeDetail,
} from 'components/CompanyDetail/Services/services';
import SequentialVideoModal from 'views/Student/Learning/components/UIComponents/Modals/AccessModal/SequentialVideo';
import { GenerateBlobPdf } from 'utils/generateBlobPdf';
import ModalInformation from 'components/informationModal';
import InformationConclusionModal from 'views/Student/Learning/components/UIComponents/Modals/InformConclusion';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import { CurriculumContext } from 'contexts/CurriculumContext';
export function ModalDetailChallenge({
  closeModalDetail,
  showModalDetail,
  challengeId,
  finished,
  callDataChalenges,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const [typeAccess, setTypeAccess] = useState(null);
  const [challengeData, setChallengeData] = useState(null);
  const [contentAccess, setContentAccess] = useState(null);
  const [informConclusionData, setInformConclusionData] = useState(null);
  const [modalInformConclusion, setModalInformConclusion] = useState(false);
  const [displayButtonConclusion, setDisplayButtonConclusion] = useState(
    finished
  );
  const {
    setIsOpenCurriculumModal,
    isOpenCurriculumModal,
    profile,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateAvatarState,
    updateInterestState,
    closeCurriculumModal,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);

  const teste = useContext(LearningContext);

  const callFirstData = async () => {
    const response = await getCompanyChallengeDetail(challengeId);
    if (response.data) {
      setChallengeData(genericAdapter(response.data));
    }
  };

  const closeModalProfile = () => {
    closeCurriculumModal();
  };

  useEffect(() => {
    callFirstData();
  }, []);

  const likeOrDislike = async (action) => {
    if (!challengeData) {
      return;
    }
    const { reactions } = challengeData;
    let newChallenge = { ...challengeData };
    let newReaction = {};
    const data = {
      type: action,
    };
    if (reactions?.id && reactions?.type === action) {
      deleteReactions(challengeData);
      callFirstData();
      return;
    } else if (reactions?.id) {
      newReaction = await editReactions(challengeData, data);
    } else {
      newReaction = await createReactions(challengeData, data);
    }
    newChallenge.reactions = { ...newReaction?.data };
    setChallengeData({ ...newChallenge });
  };

  const handleAccessAttraction = async () => {
    if (!challengeData) {
      return;
    }

    if (!profile?.academic_profile?.curriculum_filled) {
      setIsOpenCurriculumModal(true);
      return;
    }
    const response = await getAccess(challengeData?.id);
    const { content } = response.data;
    if (!content) {
      return;
    }
    if (challengeData?.typeAccess === 'video') {
      setContentAccess(content);
      setTypeAccess('video');
    }
    if (challengeData?.typeAccess === 'access_link') {
      window.open(content, '_blank');
      createReactions(challengeData, {
        type: 'url_clicked',
      });
    }
    if (challengeData.typeAccess === 'attraction_document') {
      setTypeAccess('attraction_document');
      setContentAccess(content);
    }
  };
  const closeModal = () => {
    setTypeAccess(null);
  };

  const configDownloadPDF = {
    modalWidth: 428,
    bubbleText: 'Atenção!',
    ParagraphText: `Este Desafio é um documento PDF. `,
    TwoParagraphText: `Tem certeza que deseja baixar este arquivo ?`,
    buttonCloseX: true,
    showButtonCancel: true,
    buttonCancelText: 'NÃO',
    buttonConfirmText: 'SIM, BAIXAR',
    style: {
      gapParagraph: 5,
      textAlign: 'start',
      fontWeight: '400',
      fontSize: '20px',
      sizeIconPerson: '78px',
      colorBgButtonX: '#ececec',
      lineHeight: '25px',
      maxWidth: '100%',
    },
  };

  const handleInformConclusion = async (item) => {
    if (!profile.academic_profile?.curriculum_filled) {
      setIsOpenCurriculumModal(true);
      return;
    }
    setModalInformConclusion(true);
    setInformConclusionData(genericAdapter(item, 'cameltosnake'));
  };

  const updatedChallenges = () => {
    setDisplayButtonConclusion(true);
    callDataChalenges && callDataChalenges();
  };

  const handleDownloadModal = async (content) => {
    const link = await GenerateBlobPdf(content.document_url);
    const downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.rel = 'noopener noreferrer';
    downloadLink.href = link;
    downloadLink.download = `${content.document_name}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setTypeAccess(null);
    setContentAccess(null);
  };

  return (
    showModalDetail && (
      <ModalWrapper>
        <ModalContent>
          <CloseButton onClick={() => closeModalDetail()}>
            <GrClose style={{ marginTop: '3px' }} />
          </CloseButton>
          {challengeData && (
            <ContainerBanner
              color={universityColor}
              img={
                Boolean(challengeData && challengeData?.image) &&
                challengeData?.image
              }
            ></ContainerBanner>
          )}
          <FragmentInfo>
            <FragmentText>
              <h4>Desafio Workalove</h4>
              <p>
                {challengeData?.description !== ''
                  ? ReactHtmlParser(challengeData?.description)
                  : 'Não Informado'}
              </p>
            </FragmentText>
            <Line />
            <Footer>
              <div>
                {challengeData?.typeAccess !== '' && (
                  <AccessButton
                    color={universityColor}
                    onClick={() => handleAccessAttraction()}
                  >
                    Acessar
                  </AccessButton>
                )}
                {!displayButtonConclusion && (
                  <InformationButton
                    color={universityColor}
                    onClick={() => handleInformConclusion(challengeData)}
                  >
                    Informar conclusão
                  </InformationButton>
                )}
              </div>

              <span>
                <ContainerButton
                  like
                  liked={challengeData?.reactions?.liked}
                  onClick={() => likeOrDislike('liked')}
                >
                  <BiLike />
                </ContainerButton>
                <ContainerButton
                  disliked={challengeData?.reactions?.disliked}
                  onClick={() => likeOrDislike('disliked')}
                >
                  <BiDislike />
                </ContainerButton>
              </span>
            </Footer>
          </FragmentInfo>
          {isOpenCurriculumModal &&
            mergeContextsHelper(
              <CurriculumModal
                personalityTestIsDone={profile?.quizzes?.personality.is_done}
                behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
                lifeInterestList={profile?.life_interests}
                interestList={profile?.interests}
                studentProfileState={profileAdapter(profile?.academic_profile)}
                updateBehavioralState={updateBehavioralState}
                updatePersonalityState={updatePersonalityState}
                updateProfileState={updateProfileState}
                updateInterestState={updateInterestState}
                setClose={closeModalProfile}
                isOpen={isOpenCurriculumModal}
                updateAvatarState={updateAvatarState}
                handleShowSpecialNeeds={handleShowSpecialNeeds}
              />
            )}

          {typeAccess === 'video' && contentAccess !== null && (
            <SequentialVideoModal
              contentAccess={contentAccess}
              setContentAccess={setContentAccess}
              controlOpenModal={typeAccess === 'video'}
              setControlOpenModal={closeModal}
            />
          )}
          {typeAccess === 'attraction_document' && contentAccess !== null && (
            <ModalInformation
              config={configDownloadPDF}
              closeModalFunction={closeModal}
              modalOpenState={
                typeAccess === 'attraction_document' && contentAccess !== null
              }
              buttonCancelFunction={closeModal}
              buttonConfirmFunction={() => handleDownloadModal(contentAccess)}
              universityColor={universityColor}
            />
          )}
          {modalInformConclusion && (
            <InformationConclusionModal
              data={informConclusionData}
              resetData={setInformConclusionData}
              controlOpenModal={modalInformConclusion}
              setControlOpenModal={setModalInformConclusion}
              updatedData={updatedChallenges}
            />
          )}
        </ModalContent>
      </ModalWrapper>
    )
  );
}
