export function IconAlert() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 30.1328C6.71573 30.1328 0 23.4171 0 15.1328C0 6.84854 6.71573 0.132812 15 0.132812C23.2843 0.132812 30 6.84854 30 15.1328C30 23.4171 23.2843 30.1328 15 30.1328Z"
        fill="#F8D1B4"
      />
      <path
        d="M8.60694 17.2841L13.3212 8.597C13.4944 8.30499 13.7407 8.06308 14.0357 7.89505C14.3307 7.72703 14.6644 7.63867 15.0039 7.63867C15.3434 7.63867 15.6771 7.72703 15.9721 7.89505C16.2672 8.06308 16.5134 8.30499 16.6866 8.597L16.6941 8.61093L21.3998 17.2702C21.5761 17.5672 21.6706 17.9056 21.6737 18.251C21.6769 18.5964 21.5885 18.9365 21.4176 19.2367C21.2468 19.5368 20.9995 19.7864 20.7009 19.96C20.4023 20.1337 20.0631 20.2252 19.7177 20.2252H10.3212C9.9716 20.2278 9.62754 20.1377 9.32412 19.964C9.02071 19.7902 8.7688 19.5391 8.59408 19.2363C8.42235 18.9392 8.33301 18.6016 8.33527 18.2585C8.33753 17.9153 8.43131 17.579 8.60694 17.2841ZM15.7855 11.6163C15.7855 11.4087 15.7031 11.2096 15.5563 11.0628C15.4095 10.9161 15.2104 10.8336 15.0028 10.8336C14.7953 10.8336 14.5962 10.9161 14.4494 11.0628C14.3026 11.2096 14.2202 11.4087 14.2202 11.6163V14.747C14.2202 14.9546 14.3026 15.1537 14.4494 15.3004C14.5962 15.4472 14.7953 15.5297 15.0028 15.5297C15.2104 15.5297 15.4095 15.4472 15.5563 15.3004C15.7031 15.1537 15.7855 14.9546 15.7855 14.747V11.6163ZM15.0034 18.6599C15.2365 18.6599 15.4643 18.5907 15.6582 18.4612C15.852 18.3317 16.003 18.1477 16.0922 17.9323C16.1814 17.7169 16.2048 17.48 16.1593 17.2514C16.1138 17.0227 16.0016 16.8127 15.8367 16.6479C15.6719 16.4831 15.4619 16.3708 15.2333 16.3254C15.0047 16.2799 14.7677 16.3032 14.5524 16.3924C14.337 16.4816 14.1529 16.6327 14.0234 16.8265C13.8939 17.0203 13.8248 17.2482 13.8248 17.4813C13.8248 17.6363 13.8554 17.7898 13.9148 17.933C13.9742 18.0762 14.0613 18.2063 14.1711 18.3158C14.2809 18.4253 14.4112 18.512 14.5546 18.5711C14.6979 18.6301 14.8515 18.6603 15.0066 18.6599H15.0034Z"
        fill="#F27141"
      />
    </svg>
  );
}
