import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 620px;
  height: ${(props) => props.height || '168px'};

  @media (max-width: 425px) {
    height: 267px;
  }

  border: 1px dashed #bdbdbd;
  border-radius: 4px;
  padding-top: 36px;

  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 10px auto;

  p {
    font-size: 20px;
    font-weight: 400;
    text-align: center;

    margin-bottom: 17px;
  }

  > button {
    width: 346px;
    height: 42px;
    padding: 0px 20px;
    color: #ffff;
    border: none;
    border-radius: 8px;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-weight: 600;
    margin-top: 15px;
    cursor: pointer;

    @media (max-width: 425px) {
      width: 200px;
    }
  }
`;
