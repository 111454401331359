import React, { useContext, useRef } from 'react';

import {
  Box,
  Container,
  ContainerButton,
  Content,
  GreenButton,
  WhiteButton,
  WrapperMessage,
  WrapperTitle,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { JobOfferContext } from '../../context/jobOfferContext';

import { unPublishJob } from '../../services';

export default function UnpublishModal(props) {
  const { isOpen, setModalUnpublish, jobID } = props;
  const { dataFunction } = useContext(JobOfferContext);
  const modalRef = useRef();

  const closeModalClick = (e) => {
    if (modalRef.current === e.target) {
      setModalUnpublish(false);
    }
  };

  const closeModal = () => {
    setModalUnpublish(false);
  };

  const confirmUnpublish = async (id) => {
    await unPublishJob(id);
    dataFunction.handlePageChange('limit=0');
    setModalUnpublish(false);
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      <Box ref={modalRef} onClick={closeModalClick}>
        <Content size="unpublish">
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <div className="bubble-dialog">
              <span> Atenção! </span>
            </div>
          </WrapperTitle>
          <WrapperMessage>
            Esta ação não poderá ser desfeita, tem certeza que deseja
            despublicar esta vaga?
          </WrapperMessage>
          <ContainerButton>
            <WhiteButton
              onClick={() => confirmUnpublish(jobID)}
              size="unpublish"
            >
              SIM, QUERO DESPUBLICAR
            </WhiteButton>
            <GreenButton onClick={closeModal}>NÃO DESPUBLICAR</GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  ) : null;
}
