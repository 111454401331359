import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';

const getProfessionalExperience = async () => {
  const response = await axios
    .get(`${URLS.API_URL}/api/internal/students/me/experiences`, {
      withCredentials: true,
      headers: {
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateProfessionalExperience = async (data) => {
  const response = await axios
    .patch(
      `${URLS.API_URL}/api/internal/students/me/professional-experience/${data.id}`,
      data,
      {
        withCredentials: true,
        headers: {
          'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteProfessionalExperience = async (experienceId) => {
  const response = await axios
    .delete(
      `${URLS.API_URL}/api/internal/students/me/professional-experience/${experienceId}`,
      {
        withCredentials: true,
        headers: {
          'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getProfessionalExperienceAreas = async () => {
  const response = await axios
    .options(
      `${URLS.API_URL}/api/internal/students/me/professional-experience`,
      {
        withCredentials: true,
        headers: {
          'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const saveProfessionalExperience = async (data) => {
  const response = await axios
    .post(
      `${URLS.API_URL}/api/internal/students/me/professional-experience`,
      data,
      {
        withCredentials: true,
        headers: {
          'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  deleteProfessionalExperience,
  getProfessionalExperienceAreas,
  updateProfessionalExperience,
  saveProfessionalExperience,
  getProfessionalExperience,
};
