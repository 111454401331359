import styled from 'styled-components';
import { device } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/ModalShowEvidence/utils/devicesQuery';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  margin-top: 10px;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;

  @media ${device.mobileL} {
    height: 56px;
  }
`;

export const ContainerTypeName = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  > img {
    width: 24px;
  }

  .direction-column {
    display: flex;
    flex-direction: column;

    > .date {
      display: flex;
      gap: 5px;
      > span {
        font-weight: 300;
        font-size: unset;
        width: 135px;
      }
    }
  }

  .direction-column span {
    font-size: 13px;
    display: inline-block;
    width: 335px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;

    @media ${device.mobileL} {
      max-width: 150px;
    }
  }

  .direction-column p {
    font-size: 11px;
    margin: 0;
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  gap: 10px;

  > img {
    width: 20px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }

  > button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;

    padding: 0;
  }
`;

export const PopUp = styled.div`
  > svg {
    width: 20px;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }

  position: relative;

  &:hover {
    &:before {
      display: block;
    }
    &:after {
      display: block;
    }
  }

  &:before {
    content: '${(props) => props.label}';
    width: 56px;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    position: absolute;
    top: -37px;
    right: -20px;
    padding: 5px 7px;
    text-align: center;
    display: block;
    color: #ffff;
    border-radius: 4px;
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'}
      transparent;
    border-width: 10px 10px 0;
    bottom: 25px;
    left: 50%;
    margin-left: -10px;
    display: none;
  }

  > button {
    border: 1px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    font-weight: 500;
    padding: 5px 12px;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }
`;
