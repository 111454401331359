import styled, { keyframes } from 'styled-components';
export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.color};

  .form {
    width: 100%;
  }

  .form span {
    display: flex;
    height: 13px;
  }

  .form div:first-child {
    margin-top: 28px;
    margin-bottom: 22px;
  }

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;

  margin-top: 30px;
`;

export const ContainerAllLogos = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 370px;

  > div {
    margin-left: 10px;
  }

  @media (max-width: 850px) {
    margin-top: 25px;
    align-items: center;
  }
`;

export const BoxLogos = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgArrow = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 20px;

  cursor: pointer;
`;

export const ContainerButtonBack = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const ContainerButtonArrow = styled.div`
  margin-left: 120px;

  @media (max-width: 850px) {
    margin-left: 0;
  }
`;

export const ContainerInCenter = styled.div`
  display: flex;
  align-items: ${(props) => (props.isCenter ? 'center' : 'flex-start')};
  justify-content: center;
  flex-direction: column;
  width: 100%;

  img {
    width: 100%;
    max-width: 130px;
    height: 100%;
    margin-bottom: 60px;
  }

  @media (max-width: 850px) {
    display: ${(props) => (props.isStudent ? 'none' : '')};
    padding-top: 0;

    img {
      width: 45%;
      display: none;
    }
    align-items: center !important;
  }
`;

export const FragmentContainer = styled.div`
  margin: ${(props) => props.marginFragment || '0'};
`;

export const Titles = styled.div`
  color: ${(props) => (props.isLogo ? '#fff' : '#4B4B4D')};
  font-size: ${(props) => (props.isLogo ? '36px' : '22px')};
  margin-top: 0px;
  text-align: ${(props) => (props.isChooseUser ? 'center' : 'start')};
  font-weight: ${(props) => (props.isPassword ? '0' : '700')};
  width: ${(props) => (props.isChooseUser ? '289px' : '344px')};

  @media (max-width: 850px) {
    font-size: 1.6em;
    margin-top: 0px;
    max-width: ${(props) => (props.isLogo ? '300px' : '100%')};
    margin-bottom: ${(props) => (props.isContainerLogin ? '60px' : '0')};
    width: 100%;
    text-align: center;
    order: 1;
  }

  span {
    line-height: 26px;
  }

  strong {
    margin-bottom: 15px;
    line-height: ${(props) => props.lineHeight || '43px'};
  }

  display: flex;
  flex-direction: column;
`;

export const ContainerTitles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  p {
    font-size: 25px;
    font-weight: 700;
  }

  @media (max-width: 850px) {
    text-align: center;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  color: ${(props) => (props.isFooter ? '#4B4B4D' : '#fff')};
  font-size: ${(props) => (props.isFooter ? '12px' : '18px')};
  line-height: 26px;

  @media (max-width: 850px) {
    display: none;
    order: 2;
  }
`;

export const Footer = styled.span`
  color: #4b4b4d;
  font-size: 12px;
  line-height: 26px;
  margin: 0 !important;

  @media (max-width: 1480px) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
`;

export const ButtonRegister = styled.button`
  background-color: transparent;
  font-weight: 700;
  border: none;
  color: ${(props) => props.color};

  cursor: pointer;

  font-size: 12px;
`;

export const ContainerLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-color: #fff;
  border-bottom-left-radius: 70px;
  border-top-left-radius: 70px;
  width: 50%;
  height: 100%;

  svg {
    cursor: pointer;
  }

  @media (max-width: 850px) {
    width: 100%;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    justify-content: space-around;
  }
`;

export const ButtonPassword = styled.button`
  border: none;
  background-color: transparent;
  color: ${(props) => props.color};
  cursor: pointer;
`;

export const ButtonLogin = styled.button`
  width: 100%;
  max-width: 370px;
  height: 50px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  background-color: ${(props) => props.color};

  padding: 12px 0;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 16px;

  cursor: pointer;

  @media (max-width: 850px) {
    width: 70vw;
    margin-top: 40px;
  }
`;

export const RecoverPassword = styled.a`
  color: ${(props) => props.color} !important;
  font-size: 13px;
  margin-top: 13px;
`;

export const ContainerButtonFooter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;

  border-radius: 50%;

  margin-right: 6px;

  background-color: ${(props) => props.color};
`;

export const ContainerWorkalove = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 602px;
  position: absolute;
`;

export const ContainerChooseUser = styled.div`
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 31px;
  width: 100%;
`;

export const ButtonUser = styled.a`
  width: 120px;
  height: 120px;

  background-color: transparent;

  border: 1px solid #4b4b4d;
  border-radius: 10px;

  cursor: pointer;
`;

export const ContentContainerUser = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
`;

export const ImgUser = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

export const ParagraphUser = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.color};
  margin: 0;
`;

export const ParagraphEmail = styled.p`
  font-size: 22px;
  color: #4b4b4d;
  line-height: 29px;
`;

export const ContainerTitlesEmail = styled.div`
  width: 70%;
  margin: 40px 0 120px 120px;

  @media (max-width: 850px) {
    margin-left: 0;
    margin-top: 70px;
    margin: 70px 0 0 0;
  }
`;

export const ContainerParagraphs = styled.div`
  margin-top: 16px;

  @media (max-width: 850px) {
    margin-left: 0;
    width: 140%;
  }
`;

export const CircleEmail = styled.div`
  position: relative;

  width: 83px;
  height: 83px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#b5e7e6'};
  position: relative;

  svg {
    z-index: 2;
    cursor: default;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 104%;
    height: 104%;
    background-color: #fff;
    opacity: 0.9;
    z-index: 1;
    border-radius: 50%;
  }

  @media (min-width: 850px) {
    margin-top: 40px;
  }
`;

export const ImgEmail = styled.img`
  width: 36px;
  height: 36px;
`;

export const ContainerParagraphLogin = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 30px;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const ContainerWal = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
`;

export const ContainerButton = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 428px;

  background-color: #ffffff;
  border-radius: 10px;
  padding: 39px;

  position: relative;
`;

export const GreenButton = styled.button`
  width: 100%;
  padding: 11px;
  max-width: ${(props) => props.widthButton};

  border-radius: 4px;

  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;
  background-color: #009291;
  margin-top: ${(props) => props.marginTopButton || '36px'};
  cursor: pointer;

  border: none;
`;

export const WrapperMessage = styled.div`
  width: 100%;

  font-size: ${(props) => props.fontMessage};
  font-weight: 400;
  color: #606062;
  line-height: ${(props) => props.lineHeigthMessage};
  text-align: ${(props) => props.positionMessage};

  padding: 22px 0;
`;

export const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .border-image {
    width: 76px;
    height: 76px;
    background-color: #ebebeb;
    border: 2px solid #009291;
    border-radius: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-image img {
    width: 100%;
    height: 100%;
    margin-bottom: -3px;
  }
`;

export const BubbleDialog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #009291;
  margin-left: 1.5em;
  border-radius: 4px;
  width: ${(props) => props.widthBubble};
  height: ${(props) => props.heightBubble};
  position: relative;

  ::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;

    border-left: 4.5px solid transparent;
    border-right: 4.5px solid #009291;
    border-top: 4.5px solid #009291;
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 13px;
    transform: rotate(-135deg);
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
  }
`;
export const ContainerLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 17.58px;
`;
