import React, { useEffect, useRef, Fragment, useState, useMemo } from 'react';
import { useField } from '@unform/core';
import JoditEditor from 'jodit-react';
import styled from 'styled-components';
import { UnInputLabel } from '../styles/unformCompany';
import { ContainerLabelInput } from '../styles/styleForm';

import '../styles/joditCompany.css';
import { Error } from 'components/UnformFields/inputField/style';

function InputDesc({ label, name, setError, maxLength = null, ...rest }) {
  const inputRef = useRef(null);
  const [description, setDescription] = useState('');
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    setDescription(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',

      setValue: (ref, value) => {
        setDescription(value);
      },
    });
  }, [fieldName, registerField]);

  const config = useMemo(
    () => ({
      theme: 'summer',
      style: {
        'color-text': 'red',
        colorBorder: 'black',
        'color-panel': 'blue',
      },
      placeholder: 'Digite aqui...',
      language: 'pt_br',
      readonly: false,
      height: '10em',
      buttons: ['bold', 'italic', 'underline'],
      buttonsMD: ['bold', 'italic', 'underline'],
      buttonsSM: ['bold', 'italic', 'underline'],
      buttonsXS: ['bold', 'italic', 'underline'],
      removeButtons: [
        'source',
        '|',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'image',
        'table',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
        'eraser',
        'fullsize',
      ],
      showXPathInStatusbar: false,
      showCharsCounter: false,
      showWordsCounter: false,
      toolbarAdaptive: true,
      allowResizeX: false,
      allowResizeY: false,
      toolbarSticky: false,
      askBeforePasteFromWord: false,
      askBeforePasteHTML: false,
      tabIndex: 0,
      defaultActionOnPaste: 'insert_only_text',
      limitChars: maxLength,
    }),
    [maxLength]
  );

  const handleUpdate = (event) => {
    setDescription(event);
  };

  useEffect(() => {
    if (maxLength) {
      config.limitChars = maxLength;
    }
  }, [maxLength]);

  const joditEditorMemo = useMemo(
    () => (
      <ContainerLabelInput>
        {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
        <div className="EditJodit">
          <JoditEditor
            ref={inputRef}
            value={description}
            config={config}
            onBlur={handleUpdate}
            defaultValue={defaultValue}
            onChange={(newContent) => {}}
          />
        </div>
      </ContainerLabelInput>
    ),
    [description, error, config.limitChars]
  );

  return (
    <Fragment>
      {joditEditorMemo}
      {error && <Error>{error}</Error>}
    </Fragment>
  );
}

export default InputDesc;
