import styled, { keyframes } from 'styled-components';

import rocket from '../../../../assets/JobOffer/rocket.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items:center;
  justify-content: center;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: ${(props) => (props.width ? props.width + 'px' : '415px')};
  height: ${(props) => (props.height ? props.height + 'px' : '418px')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-weight: 600;
  }
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5em;
  width: 70%;
  height: 20%;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;

  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #009291;
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 13px;
  font-weight: bold;
  color: #ffff;
`;

export const ParagraphStyled = styled.p`
  margin-top: 10px;
  max-width: 250px;
  font-size: 15px;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const RocketWrapper = styled.div`
  position: relative;
  display: flex;

  margin-bottom: 10px;
  margin-top: -5px;
  span {
    position: relative;
    width: 170px;
    height: 170px;
    background: #eaeef0;

    border-radius: 50%;

    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      border-radius: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      inset: 2px;
      background: #ffff;

      border-radius: 50%;

      background-image: url(${rocket});
      background-size: 54px;
      background-position: center;
      background-repeat: no-repeat;
    }

    i {
      position: absolute;
      inset: 0;
      border-radius: 50%;
      filter: blur(5px);
      background: linear-gradient(#dedede, #009b9a);
      animation: ${rotate} 0.7s linear infinite;
    }
  }
`;

export const Container = styled.div`
  position: relative;
`;

export const Img = styled.div``;

export const Rocket = styled.div`
  position: absolute;
  inset: 50px;
  z-index: 10;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width + 'px' : '290px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: #009291;
  border: none;
  border-radius: 4px;
  color: #ffff;
  cursor: pointer;
  &:active {
    transform: scale(0.9);
  }
`;
