import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;

  margin: 0 49px;

  padding: 27px 0;

  gap: 20px;

  .align-title-check {
    display: flex;
    align-items: center;

    gap: 7px;
  }

  .align-title-check input[type='checkbox'] {
    background-color: #f5f5f5;
    accent-color: #009291;
    width: 1rem;
    height: 1rem;
    cursor: pointer;
  }
`;

export const IconSession = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.gap};

  margin: 10px 0;
`;

export const IconGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  font-weight: 400;
`;

export const OpenVacancies = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  cursor: ${({ hasVacancies }) => (hasVacancies ? 'pointer' : 'auto')};
  text-decoration: ${({ hasToggleVacanciesModal, hasVacancies }) =>
    hasToggleVacanciesModal && hasVacancies ? 'underline' : 'none'};
  font-weight: 600;
  color: ${({ hasToggleVacanciesModal, hasVacancies }) =>
    hasToggleVacanciesModal && hasVacancies ? '#009291' : '#949494'};
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
`;

export const Item = styled.label`
  font-size: 20px;
  font-weight: 500;
  color: #606062;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CNPJ = styled(Item)`
  font-size: 16px;
  margin-top: 3px;
  font-weight: 400;

  justify-content: flex-start;
  margin-left: ${(props) => (props.checkbox ? '31px' : '0px')};

  gap: 7px;
`;

const statusColor = {
  current: { background: '#D9FFE3', text: '#068E2C' },
  no_term: { background: '#FFEDED', text: '#DA0505' },
  close_expiration: { background: '#FFF2E3', text: '#F09D51' },
  expired: { background: '#FFEDED', text: '#DA0505' },
};

export const FlagContainer = styled.div`
  width: max-content;
  height: 28px;
  background-color: ${(props) => statusColor[props.color].background || ''};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 3.29px;

  margin-bottom: 15px;
  padding: 11px;

  span {
    font-size: 12px;
    color: ${(props) => statusColor[props.color].text || ''};
  }

  svg {
    flex: none;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 7px;
  }
`;

export const BoxIcon = styled.div`
  width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #caeaea;

  border-radius: 3px;

  svg {
    flex: none;
  }
`;

export const FileButton = styled.button`
  width: 100%;
  max-width: 264px;

  height: 33px;

  border: 1px solid #009291;

  border-radius: 6px;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #009291;
  font-weight: 400;
  flex: none;

  transition: 0.2s;

  cursor: pointer;

  margin-top: ${(props) => (props.expandPanel ? '0px' : '20px')};

  svg {
    margin-right: 7px;
    flex: none;
  }

  :hover {
    background-color: #009291;
    color: #fff;
  }

  :hover svg {
    fill: #fff;
  }
`;

export const ButtonEdit = styled.button`
  position: absolute;
  bottom: 0px;
  right: 0px;

  width: 54px;
  height: 54px;

  border-radius: 200px;

  border: none;

  background-color: #caeaea;

  display: ${(props) => (props.expandPanel ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const CanvasButton = styled.div`
  display: flex;
  align-items: center;

  gap: 15px;
`;

export const ConnectionMade = styled.div`
  width: fit-content;

  height: 33px;

  border-radius: 4px;

  background-color: #f5f5f5;

  color: #949494;
  font-size: 12.35px;
  font-weight: 500;

  display: ${(props) => (props.expandPanel ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  padding: 0 11px;

  flex: none;
`;

export const ExpandButton = styled.button`
  width: 28px;
  height: 28px;

  background-color: #f5f5f5;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  border-radius: 200px;

  cursor: pointer;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: ${(props) => (props.right ? '100%' : '60%')};
  max-width: ${(props) => (props.right ? '355px' : 'unset')};

  position: relative;

  .container-button {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    margin-top: 40px;

    position: relative;
  }

  @media (max-width: 1150px) {
    .container-button {
      align-items: flex-end;
      flex-direction: column;
      gap: 7px;
    }
  }

  .connection-status-box {
    display: flex;
    flex-direction: column;
    text-align: center;

    align-items: center;
    justify-content: center;

    max-width: 242px;

    margin-right: 0px;
  }
`;

export const ButtonGreen = styled.button`
  padding: 0 15px;
  height: 40px;

  border: none;
  border-radius: 4px;

  color: #fff;
  background-color: #009291;

  margin-right: 0px;
  width: 100%;
  max-width: 175px;

  cursor: pointer;

  :disabled {
    background-color: #f5f5f5;
    color: #949494;
    cursor: auto;
  }
`;

export const ButtonGray = styled.button`
  height: 40px;
  max-width: 175px;
  width: 100%;
  border: none;
  border-radius: 4px;

  color: #009291;
  background-color: #f5f5f5;

  margin-right: 12px;

  cursor: pointer;

  @media (max-width: 1150px) {
    margin-right: 0px;
  }
`;

export const ConnectionStatus = styled.div`
  background-color: #d9ffe3;
  color: #068e2c;

  padding: 11px;

  border-radius: 4px;

  max-width: 122px;
  width: 100%;

  margin-bottom: 10px;
`;
