import React from 'react';

import {
  Container,
  Box,
  Content,
  Header,
  ContainerImageAndInformations,
} from './styled';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import altAvatar from 'assets/img/placeholder.jpg';

function StudentCoursesModal({ studentData, open, onClose }) {
  if (!open) {
    return null;
  }

  const avatarSource = studentData.student.avatar
    ? studentData.student.avatar
    : altAvatar;

  const createCourses = () => {
    const courses = studentData.student.courses;
    const coursesAndPeriods = courses.map((course) => {
      return (
        <span>
          {course?.course__name} - {course?.semester}° período <br />
          RA: {studentData.student.external_id} <br />
        </span>
      );
    });

    return coursesAndPeriods;
  };

  return (
    <Container>
      <Box>
        <Content>
          <Header>
            <CloseButton onClick={onClose} />
          </Header>

          <ContainerImageAndInformations>
            <img src={avatarSource} />
            <div>
              <h4>
                {studentData.student.external_id} - {studentData.student.name}
              </h4>

              {createCourses()}
            </div>
          </ContainerImageAndInformations>
        </Content>
      </Box>
    </Container>
  );
}

export default StudentCoursesModal;
