import styled from 'styled-components';
import ViewsHeader from 'components/ViewsHeader/ViewsHeader';
import { Info } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import Pagination from 'rc-pagination';

export const Loading = styled(CircularProgress)`
  margin: 20px;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 50%;
`;

export const LoadingAttraction = styled(CircularProgress)`
  color: #009291;
`;

export const AbsolutWrapper = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
`;

export const InfoIcon = styled(Info)`
  width: auto;
  height: ${(props) => props.height};
  color: ${(props) => props.color};
  margin-right: 20px;
`;

export const AlignCenterView = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const DefaultPagination = styled(Pagination)`
  display: flex;
  list-style: none;
  flex: 1;
  justify-content: center;
  padding: 0;

  &::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    height: 36px;
    margin-right: 8px;
    line-height: 36px - 2px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    user-select: none;

    a {
      display: block;
      padding: 0 6px;
      transition: none;
      color: rgba(0, 0, 0, 0.87);

      &:hover {
        text-decoration: none;
      }
    }

    svg {
      fill: rgba(0, 0, 0, 0.87);

      &:hover {
        fill: ${(props) =>
          props.universityColor ? props.universityColor : '#009291'};
      }
    }

    &:focus,
    &:hover {
      border-color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
      transition: all 0.3s;
      a {
        color: ${(props) =>
          props.universityColor ? props.universityColor : '#009291'};
      }
    }
  }

  .rc-pagination-item-active {
    font-weight: bold;
    background: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    border-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};

    a {
      color: #fff;
    }

    &:focus a,
    &:hover a {
      color: #fff;
    }
  }

  .rc-pagination-disabled {
    &,
    &:hover,
    &:focus {
      cursor: not-allowed;
      border-color: #d9d9d9;
      a,
      svg {
        &,
        &:hover,
        &:focus {
          fill: rgba(0, 0, 0, 0.25);
          color: rgba(0, 0, 0, 0.25);
          border-color: #d9d9d9;
          cursor: not-allowed;
        }
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  background: ${({ loading }) => (loading ? '#eee' : 'transparent')};
  opacity: ${({ loading }) => (loading ? '0.6' : '1')};
  filter: ${({ loading }) => loading && 'blur(3px)'};
`;

export const LoadingAbsolute = styled(Loading)`
  position: relative;
  z-index: 100;
  left: -28px;
  top: 9px;
`;

export const Header = styled(ViewsHeader)``;
