import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const UnInput = styled.input`
  border: ${(props) =>
    props.error ? '0.6px solid #FFCECE' : '1px solid #d0d0d0'};
  width: 100%;
  padding: 12px;
  background-color: ${(props) => (props.error ? '#FFEDED' : '#fff')};
  border-radius: 4px;
  margin-bottom: 4px;
  position: relative;
  height: ${(props) => props.height || 'auto'};
`;

export const UnInputLink = styled.input`
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d0d0d0;
  width: 100%;
  padding: 12px 30px;
  margin-bottom: 10px;

  &:focus {
    border-bottom: 1px solid #009291;
  }
`;
export const UnImageLink = styled.img`
  width: 20px;
  position: absolute;
  top: 30px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
`;

export const UnInputDesc = styled.textarea`
  border: 1px solid #d0d0d0;
  width: 100%;
  height: 10em;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  resize: none;
  font-family: sans-serif;

  &:focus {
    border: 2px solid #2784ff;
  }
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 5px;

  .competencies-description {
    font-size: 12px;
    color: #606062;
  }
`;

export const ContainerCheckBox = styled.div`
  margin: 10px 0px 10px 0px;

  > div {
    > h3 {
      font-size: 17px;
      font-weight: 500;
      color: #606062;
    }

    > p {
      font-size: 12.5px;
      font-weight: 350;
      margin-top: -9px;
      margin-bottom: 15px;
    }

    > span {
      font-size: 12px;
      margin-top: -5px;
      display: inherit;
    }
  }
`;

export const MultiStepContainer = styled.div`
  height: 5em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MultiStepDiv = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efeeec;
  font-weight: bold;
  z-index: 2;
`;

export const MultiStepDivActive = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  font-weight: bold;
  z-index: 2;
  margin-left: 55px;
`;

export const DivIndicatorActive = styled.div`
  width: 123px;
  height: 5px;
  background-color: #009291;
  margin-top: 12px;
  margin-left: -2px;
`;

export const DivIndicator = styled.div`
  width: 123px;
  height: 5px;
  background-color: #efeeec;
  margin-top: 12px;
  margin-left: -2px;
  position: fixed;
  top: 30px;
`;

export const WrapperStep = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
  margin-top: -50px;
`;

export const DescImg = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 10vh;
`;

export const Img = styled.img`
  width: 12%;
  margin-top: -10px;
  margin-right: 1.5em;
`;

export const Title = styled.h4`
  color: #009291;
  font-weight: 400;
`;

export const SubTitle = styled.p`
  color: #000000;
  margin-top: -5px;
  font-weight: 100;
  font-size: 12px;
  margin-top: 4px;
`;
export const Choices = styled.div`
  margin-top: 25px;
  display: flex;
  margin-left: 3px;
`;
export const ChoicesTitle = styled.span`
  font-size: 17px;
`;

export const ChoicesContainer = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
export const ChoicesCard = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4b4b4d;
  justify-content: center;
  background-color: ${(props) => (props.checked ? '#009291' : '#f0f0f0')};
  width: 120px;
  height: 91px;
  border-radius: 8px;
  margin-right: 25px;
  margin-top: 10px;
  background-image: url(${(props) =>
    props.checked ? props.imageTwo : props.imageOne});
  background-position: center;
  background-position-y: 20px;
  background-repeat: no-repeat;
  background-size: 35%;
`;
export const ChoiceInput = styled.input`
  width: '0px';
  opacity: 0;
  margin-left: -2px;
`;

export const ChoiceImg = styled.img`
  margin-bottom: 8px;
  width: 35%;
`;

export const Text = styled.span`
  margin-top: 40px;
`;

export const TextChoice = styled.span`
  margin-top: 55px;
  color: ${(props) => (props.checked ? '#FFFF' : '#000')};
`;

export const SelectOptions = styled.div`
  margin: 20px 0;
`;

export const SelectStyled = styled.select`
  width: 100%;
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  font-size: 15px;
  color: #000000;
`;

export const ButtonsContainer = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.span`
  color: #f00;
  margin-top: ${(props) => props.marginTop || '10px'};
  margin-bottom: ${(props) => props.marginBottom};

  font-size: 12px;
`;

export const Abount = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const AboutTitle = styled.h3`
  color: #009291;
  font-weight: bold;
  font-size: 18px;
`;

export const ButtonPickFile = styled.button`
  color: #ffffff;
  width: 40%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const FileWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  align-items: center;
  border: ${(props) => (props.error ? '2px solid red' : 'unset')};
  border-radius: 4px;
  padding-right: 4px;
`;

export const FileContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  display: flex;
  min-height: 30px;
  height: auto;
  align-items: center;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const AddressContainer = styled.div`
  display: flex;

  .left-side {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 416px;
  }

  .right-side {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 160px;
    margin-left: 26px;
  }
`;

export const CepButton = styled.button`
  background-color: #009291;
  width: 94px;
  height: 38px;
  border-radius: 4px;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 0;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : '100%')};
  position: relative;
  > div {
    ${(props) => props.error && 'border: 2px solid red; border-radius: 5px;'}
  }
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 4px;
  width: 100%;
  text-align: right;
  gap: 4px;
  color: #606062;
  font-size: 12px;
`;

export const ContainerLabelInputDesc = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  > div {
    ${(props) =>
      props.error && 'border: 1px solid #FFCECE; border-radius: 5px;'}
  }

  .EditJodit {
    position: relative;
  }

  .EditJodit .jodit-container:not(.jodit_inline) {
    border: 1px solid #f00;
    border-top-color: rgb(218, 218, 218);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(218, 218, 218);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(218, 218, 218);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(218, 218, 218);
    border-left-style: solid;
    border-left-width: 1px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    border-radius: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    ${(props) =>
      props.error
        ? 'background-color: #FFEDED;'
        : 'background-color: transparent;'}
  }

  .EditJodit .jodit-toolbar__box {
    position: absolute;
    bottom: 0;
    background-color: transparent;
    display: flex !important;
  }

  .jodit-ui-group .jodit-ui-group_size_middle {
    flex-wrap: unset !important;
  }

  .jodit-container:not(.jodit_inline) .jodit-wysiwyg {
    text-align: start;
  }

  .jodit-toolbar-button button {
    padding: 8px !important;
  }

  .EditJodit .jodit-placeholder {
    z-index: 0;
  }
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
`;

export const Button = styled.button`
  margin-top: 20px;
  color: #ffffff;
  width: 40%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const FinishButton = styled.button`
  color: #ffffff;
  width: 25%;
  height: 35px;
  font-size: 15px;
  font-weight: bold;
  background-color: #009291;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:disabled {
    background-color: #69b6b8;
    cursor: wait;
  }
`;

export const CompetenciesContainer = styled.div`
  margin-top: 1.3em;
`;

export const WrapperCompetencies = styled.div`
  max-height: 300px;
  overflow: scroll;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 8px;
  padding: 10px;
`;

export const DivCompetencies = styled.div`
  padding: 5px;

  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

export const SelectedCompetencies = styled.div`
  background-color: #ffffff;
  color: #606062;

  border: 1px solid #606062;

  margin: 4px 7px;
  padding: 8px;

  display: flex;
  align-items: center;

  border-radius: 12px;
`;

export const CompetenciesWrapper = styled.div`
  display: flex;
  max-height: 350px;
  max-width: 500px;
  flex-wrap: wrap;
`;

export const InfoImg = styled.img`
  width: 12px;
  height: 12px;
`;

export const ContainerLabelTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 12px 0 -4px 0;
`;

export const LabelInput = styled.p`
  color: #6d6d6d;
  margin: 0 4px 0 0;
  font-weight: bold;
  font-size: 13px;
`;
