import styled from 'styled-components';

export const Container = styled.div`  
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
`;

export const Box = styled.div`  
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div` 
  width: 100%;
  max-width: 428px;

  background-color: #ffffff;
  border-radius: 10px;
  padding: 39px;

  position: relative;
`;


export const WrapperTitle = styled.div` 
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .border-image {
    width: 76px;
    height: 76px;
    background-color: #ebebeb;
    border: 2px solid #009291;
    border-radius: 200px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-image img {
    width: 100%;
    height: 100%;
    margin-bottom: -3px;
  }
`;

export const BubbleDialog = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #009291;
  margin-left: 1.5em;
  border-radius: 4px;
  width: ${(props) => props.widthBubble};
  height: ${(props) => props.heightBubble};
  position: relative;

  ::before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;

    border-left: 4.5px solid transparent;
    border-right: 4.5px solid #009291;
    border-top: 4.5px solid #009291;
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 13px;
    transform: rotate(-135deg);
  }

  span {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
  }
`;

export const WrapperMessage = styled.div` 
  width: 100%;

  font-size: ${(props) => props.fontMessage};
  font-weight: 400;
  color: #606062;
  line-height: ${(props) => props.lineHeigthMessage};
  text-align: ${(props) => props.positionMessage};

  display: flex;
  flex-direction: column;

  padding: 22px 0;
  gap: 7px;
`;

export const ContainerButton = styled.div` 
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GreenButton = styled.button` 
  width: 100%;
  padding: 11px;
  max-width: ${(props) => props.widthButton};

  border-radius: 4px;

  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
  background-color: #009291;
  margin-top: ${(props) => props.marginTopButton || '36px'};
  cursor: pointer;

  border: none;
`;

export const CloseButton = styled.button`
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 10px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #4b4b4d;
  font-weight: 500;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonReturn = styled.button`
  width: 100%;
  max-width: 98px;
  padding: 11px;
  border: 1px solid #009291;
  cursor: pointer;
  background-color: transparent;
  margin-top: ${(props) => props.marginTopButton || '36px'};
  margin-right: 38px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #009291;
`;
