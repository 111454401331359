import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { MatrixContext } from '../../../contexts/MatrixContext';
import {
  addDisciplinesInMatrix,
  getSubjectsCourse,
} from '../../../services/matrixServices';
import { Card, Title, Divider, AddSubject, ButtonDetails } from './style';
import edit from 'assets/Matriz/edit.svg';
import ModalInformation from '../../informationModal';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

import miniArrow from 'assets/Matriz/miniArrow.svg';

export default function DropCardMatrix({
  name,
  id,
  course,
  description,
  disciplines,
  handleClickDetails,
  setModalDetailAndEditProps,
  isActualMatrix,
}) {
  const {
    getMatrices,
    selectedSubjects,
    selectedCourse,
    limitDisciplines,
    currentPageDisciplines,
    setSubjectsCourse,
    allDisciplinesCourse,
  } = useContext(MatrixContext);

  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [disciplinesToAdd, setDisciplinesToAdd] = useState([]);

  const [configModal, setConfigModal] = useState({
    config: {},
    buttonConfirm: handleConfirm,
    buttonCancel: handleCancel,
  });

  const configModalAfterAdd = {
    bubbleText: 'Oops!!',
    ParagraphText: `Essa disciplina já foi adicionada nesta matriz.
     Por isso, nenhuma ação foi realizada.`,
    buttonConfirmText: 'OK, ENTENDI',
    showButtonCancel: false,
    icon: IconPerson,
    modalHeight: 320,
  };

  const handleClickButton = () => {
    setModalDetailAndEditProps({
      form: { name, description },
      course,
      id,
      disciplines,
      isActualMatrix,
    });
    handleClickDetails();
  };

  const refTitle = useRef(null);

  useEffect(() => {
    if (refTitle.current) {
      refTitle.current.click();
    }
  }, [allDisciplinesCourse]);

  const handleClickEdit = () => {
    setModalDetailAndEditProps({
      form: { name, description },
      course,
      id,
      disciplines,
      isEdit: true,
      isActualMatrix,
    });
    handleClickDetails();
  };

  const [collectedProps, dropRef] = useDrop(() => ({
    accept: 'Card',
    drop: (item, monitor) => {
      if (item.ids && item.ids.length > 0) {
        patchDisciplinesMatrix([...item.ids]);
      }
    },
  }));

  const handleCancel = () => {
    setModalConfirmOpen(false);
  };

  const confirmSucess = (results) => {
    getMatrices(course);
    setSubjectsCourse(results);
    setModalConfirmOpen(false);
  };

  const confirmExists = async (receivedDisciplines, useSucess) => {
    let resultDisciplines = [];

    const query = `&limit=${limitDisciplines}&offset=${
      (currentPageDisciplines - 1) * limitDisciplines || 0
    }`;
    const disciplinesId = disciplines.map((item) => item.id);
    if (receivedDisciplines.length > 0) {
      await addDisciplinesInMatrix(id, [
        ...disciplinesId,
        ...receivedDisciplines,
      ]);
      const { results, count } = await getSubjectsCourse(selectedCourse, query);
      resultDisciplines = results;
    }

    if (useSucess) {
      setModalConfirmOpen(true);
      setConfigModal({
        config: {
          bubbleText: 'Tudo certo!',
          ParagraphText: `${
            receivedDisciplines.length === 1
              ? `A disciplina foi adicionada a matriz <strong>${
                  name || 'Matriz Atual (renomeie)'
                }</strong> com sucesso.`
              : `As disciplinas foram adicionadas a matriz <strong>${
                  name || 'Matriz atual (renomeie)'
                }</strong> com sucesso.`
          }`,
          buttonConfirmText: 'OK, ENTENDI',
          showButtonCancel: false,
          icon: IconPerson,
          modalHeight: 310,
        },
        buttonConfirm: () => confirmSucess(resultDisciplines),
        buttonCancel: () => {},
      });

      return;
    }

    if (resultDisciplines.length > 0) {
      confirmSucess(resultDisciplines);
    } else {
      setModalConfirmOpen(false);
    }
  };

  const handleConfirm = async (receivedDisciplines) => {
    const transformObject = disciplines.reduce((accumulator, item) => {
      accumulator[item.id] = item.id;
      return accumulator;
    }, {});

    const findExists = receivedDisciplines.filter(
      (item) => transformObject[item]
    );
    const findNotExist = receivedDisciplines.filter(
      (item) => !transformObject[item]
    );

    if (findExists.length > 0) {
      setConfigModal({
        config: {
          bubbleText: 'Atenção!',
          ParagraphText: `Foram adicionadas ${findNotExist.length} disciplinas pois já existiam ${findExists.length} presentes na matriz.`,
          buttonConfirmText: 'OK, ENTENDI',
          showButtonCancel: false,
          icon: IconPerson,
          modalHeight: 310,
        },
        buttonConfirm: () => confirmExists(findNotExist, false),
        buttonCancel: () => {},
      });

      setModalConfirmOpen(true);
      return;
    }

    confirmExists(receivedDisciplines, true);
  };

  const onExistDisciplineConfirm = () => {
    setModalConfirmOpen(false);
  };

  const patchDisciplinesMatrix = async (receivedDisciplines) => {
    setDisciplinesToAdd(receivedDisciplines);

    const unique = [...new Set(receivedDisciplines)];
    if (unique.length === 1) {
      const find = disciplines.find((item) => item.id === unique[0]);
      if (find && find.id) {
        setConfigModal({
          config: configModalAfterAdd,
          buttonConfirm: onExistDisciplineConfirm,
          buttonCancel: () => {},
        });
        setModalConfirmOpen(true);
        return;
      }
    }

    setConfigModal({
      config: {
        bubbleText: 'Atenção!',
        ParagraphText: `Tem certeza de que deseja adicionar ${
          unique.length
        } disciplina${unique.length > 1 ? 's' : ''} a matriz <strong>${
          name || 'Matriz atual (renomeie)'
        }</strong>?`,
        buttonConfirmText: 'SIM, TENHO CERTEZA',
        buttonCancelText: 'NÃO',
        showButtonCancel: true,
        icon: IconPerson,
        modalHeight: 310,
      },
      buttonConfirm: () => handleConfirm(unique),
      buttonCancel: handleCancel,
    });
    setModalConfirmOpen(true);
  };

  const buttonLabel =
    selectedSubjects.length > 0 ? (
      <span onClick={() => patchDisciplinesMatrix([...selectedSubjects])}>
        Adicionar disciplinas selecionadas
      </span>
    ) : (
      <ButtonDetails onClick={handleClickButton}>
        Ver detalhes <img src={miniArrow} />
      </ButtonDetails>
    );

  const quantityLabel = () => {
    if (disciplines.length === 0) {
      return 'Nenhuma disciplina relacionada';
    }
    if (disciplines.length === 1) {
      return `${disciplines.length} disciplina relacionada`;
    }

    return `${disciplines.length} disciplinas relacionadas`;
  };

  return (
    <Card ref={dropRef}>
      <ModalInformation
        modalOpenState={modalConfirmOpen}
        buttonConfirmFunction={configModal.buttonConfirm}
        buttonCancelFunction={configModal.buttonCancel}
        config={configModal.config}
      />
      <Title>
        {name || 'Matriz atual (renomeie)'}{' '}
        <img src={edit} onClick={handleClickEdit} />{' '}
      </Title>
      <span>{quantityLabel()}</span>
      <Divider></Divider>
      <AddSubject>{buttonLabel}</AddSubject>
    </Card>
  );
}
