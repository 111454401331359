import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 60px 30px;
  margin-top: -10px;
`;

export const Container = styled.div`
  background-color: #e3e3e3;
  width: 100%;
  min-height: 100vh;
  height: auto;
  padding: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
`;

export const H5 = styled.h5`
  font-weight: bold;
`;

export const ContainerPieChart = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 30px;

  max-width: 100%;
`;

export const ContainerBarChart = styled.div`
  margin-top: 15px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 90px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
`;
