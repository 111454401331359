export function LaptopIcon({ color = '#009291', size = '15px' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 36 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 18V0H3V18H0V22.5C0 23.6935 0.474106 24.8381 1.31802 25.682C2.16193 26.5259 3.30653 27 4.5 27H31.5C32.6935 27 33.8381 26.5259 34.682 25.682C35.5259 24.8381 36 23.6935 36 22.5V18H33ZM6 3H30V18H22.3815L21.111 19.5H14.8875L13.6185 18H6V3ZM33 22.5C33 22.8978 32.842 23.2794 32.5607 23.5607C32.2794 23.842 31.8978 24 31.5 24H4.5C4.10218 24 3.72064 23.842 3.43934 23.5607C3.15804 23.2794 3 22.8978 3 22.5V21H12.2265L13.5 22.5H22.5L23.7705 21H33V22.5Z"
        fill={color}
      />
    </svg>
  );
}
