import moment from 'moment';
import React, { useContext, useState } from 'react';
import {
  ContainerExternalCards,
  ContainerGenericMessage,
  ContainerInviteInterview,
  IconImg,
} from '../styled';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import {
  loadInterviewAdapter,
  loadInterviewOnlineAdapter,
} from 'adapters/job/jobInterviewAdapter';

import GenericCardsJobOffersWithStatus from '../CardsWithStatus';
import ModalUnscheduledInterview from '../../Modals/ModalsInterview/ModalUnscheduledInterview';
import ModalInformation from 'components/informationModal';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { getInterviewId, updateInterview } from 'services/student/jobOffers';
import ModalInterviewConfirmed from '../../Modals/ModalsInterview/ModalInterviewConfirmed';
import GenericMessage from 'components/GenericMessage';
import {
  ContainerButtonsPagination,
  ButtonPaginationSeeMore,
} from '../../styled';
import ModalStudentCancelConfirmation from '../../Modals/ModalsInterview/ModalStudentCancelConfirmation';
import { DetailsJobOffer } from '../../JobDetails';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';

const ApplicationCardJobs = ({
  universityColor,
  listApplicationJobs,
  fetchListApplicationJobOffers,
  firstName,
  isLoadingApplications,
  setIsLoadingApplications,
}) => {
  const dataApplicationJobOffers = listApplicationJobs?.data?.results.results;
  const countInterviews = listApplicationJobs?.data?.results.interviews_count;
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [interviewId, setInterviewId] = useState(null);
  const [typeInterview, setTypeInterview] = useState('Online');
  const [applicationJob, setApplication] = useState(dataApplicationJobOffers);
  const [cancelCandidature, setCancelCandidature] = useState(null);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openUnscheduledModal, setOpenUnscheduledModal] = useState(false);
  const [openCanceledModal, setOpenCanceledModal] = useState(false);
  const [openConfirmedModal, setOpenConfirmedModal] = useState(false);
  const [
    openConfirmCanceledCandidature,
    setOpenConfirmCanceledCandidature,
  ] = useState(false);
  const [interview, setInterview] = useState({});
  const { iconInviteInterview } = wrapperIconsJobOffers();

  const handleMoreItems = async () => {
    try {
      setIsLoadingApplications(true);
      const query = listApplicationJobs.data.next.split('?');
      await fetchListApplicationJobOffers(query[1], true);
    } finally {
      setIsLoadingApplications(false);
    }
  };

  const handleFetchInterviewData = async (interviewId, applicationId) => {
    try {
      const interviewData = await getInterviewId(interviewId, applicationId);
      const isOnline = interviewData.is_online;
      setTypeInterview(isOnline ? 'online' : 'presential');
      setInterview(
        isOnline
          ? loadInterviewOnlineAdapter(interviewData)
          : loadInterviewAdapter(interviewData)
      );
    } catch (error) {
      throw error;
    }
  };

  const handleOpenModalByStatusButtonOne = async (
    status,
    jobId,
    applicationId,
    interviewId
  ) => {
    setSelectedJobId(jobId);
    setInterviewId(interviewId);
    setSelectedApplicationId(applicationId);

    switch (status) {
      case 'curriculum_sent':
      case 'liked':
      case 'not_liked':
      case 'default':
        setOpenDetailsModal(true);
        break;
      case 'unscheduled':
      case 'rescheduled':
        setOpenUnscheduledModal(true);
        if (status === 'unscheduled' || status === 'rescheduled')
          await handleFetchInterviewData(interviewId, applicationId);
        break;
      case 'scheduled':
        setOpenConfirmedModal(true);
        await handleFetchInterviewData(interviewId, applicationId);
        break;
      case 'canceled':
        setOpenConfirmCanceledCandidature(true);
        break;
      default:
        setOpenDetailsModal(true);
        break;
    }
  };

  const handleOpenModalByStatusButtonTwo = (status, jobId, applicationId) => {
    setSelectedJobId(jobId);
    setSelectedApplicationId(applicationId);

    const shouldOpenCanceledModal = [
      'curriculum_sent',
      'liked',
      'not_liked',
    ].includes(status);
    if (shouldOpenCanceledModal) {
      setOpenCanceledModal(true);
    } else {
      setOpenDetailsModal(true);
    }
  };

  const fetchCancelJobOffer = async (jobId) => {
    try {
      const data = await JobOffersApi.fetchCancelJobOffer(jobId);
      if (data.status === 204) {
        setCancelCandidature(data);
        fetchListApplicationJobOffers &&
          (await fetchListApplicationJobOffers());
        customSnackbar('Candidatura cancelada com sucesso!', 'confirmation');
        setOpenCanceledModal(false);
      } else {
        customSnackbar(
          'Erro ao cancelar candidatura, tente novamente mais tarde.',
          'error'
        );
      }
    } catch (error) {
      customSnackbar(
        'Erro ao cancelar candidatura, tente novamente mais tarde.',
        'error'
      );
      throw error;
    }
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    setOpenCanceledModal(false);
    setOpenConfirmedModal(false);
    setOpenUnscheduledModal(false);
    setOpenConfirmCanceledCandidature(false);
    setInterview({});
  };

  const configModalCanceled = {
    modalWidth: 500,
    bubbleText: `Atenção!`,
    ParagraphText: `Você tem certeza que deseja cancelar essa candidatura?`,
    buttonConfirmText: 'SIM, CANCELAR CANDIDATURA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    buttonCloseX: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '92%',
    },
  };

  const returnCardJobs = (dataApplicationJobOffers) => {
    return dataApplicationJobOffers?.map((job) => (
      <div isOnGoing key={job}>
        <GenericCardsJobOffersWithStatus
          code={job.job_offer.id}
          name={job.job_offer.name}
          companyName={job.job_offer.company_name}
          location={
            job.job_offer.location ? job.job_offer.location : 'Não informado'
          }
          status={job.status}
          onClickButton={() =>
            handleOpenModalByStatusButtonOne(
              job.status,
              job.job_offer.id,
              job.id,
              job?.interview?.id
            )
          }
          onClickButtonTwo={() =>
            handleOpenModalByStatusButtonTwo(
              job.status,
              job.job_offer.id,
              job.id
            )
          }
          isOnGoingJobs={true}
          universityColor={universityColor}
          interview={interview}
          data={moment(job?.interview?.scheduling_date).format('DD/MM/YYYY')}
          is_indication={job.is_indication}
        />
      </div>
    ));
  };

  const returnPlural = countInterviews > 1 ? 'convites' : 'convite';

  const returnInviteInterview = () => {
    if (countInterviews > 0) {
      return (
        <ContainerInviteInterview>
          <IconImg isInterview src={iconInviteInterview} />
          <p>
            <strong>Convite para entrevista</strong> {firstName}, você tem{' '}
            <strong>{countInterviews}</strong> {returnPlural} para entrevista!
            Confirme sua presença para participar. Te desejamos boa sorte!
          </p>
        </ContainerInviteInterview>
      );
    }
  };

  return (
    <>
      {dataApplicationJobOffers?.length === 0 ? (
        <GenericMessage
          title="Nenhuma vaga em andamento."
          subtitle="No momento você não possui nenhuma vaga em andamento"
        />
      ) : (
        <>
          {returnInviteInterview()}
          <ContainerExternalCards>
            <div>{returnCardJobs(dataApplicationJobOffers)}</div>
          </ContainerExternalCards>

          {openDetailsModal && (
            <DetailsJobOffer
              isApplication={true}
              haveCancelCandidature={true}
              config={configModalCanceled}
              jobId={selectedApplicationId}
              universityColor={universityColor}
              handleCloseModal={handleCloseModal}
              applicationId={selectedApplicationId}
              setCancelCandidature={setCancelCandidature}
              fetchListApplicationJobOffers={fetchListApplicationJobOffers}
            />
          )}

          {openUnscheduledModal && (
            <ModalUnscheduledInterview
              universityColor={universityColor}
              handleCloseModal={handleCloseModal}
              applicationId={selectedApplicationId}
              setApplication={setApplication}
              interviewId={interviewId}
              interview={interview}
              setInterview={setInterview}
              typeInterview={typeInterview}
              updateInterview={updateInterview}
              setTypeInterview={setTypeInterview}
              fetchListApplicationJobOffers={fetchListApplicationJobOffers}
            />
          )}

          {openConfirmedModal && (
            <ModalInterviewConfirmed
              universityColor={universityColor}
              handleCloseModal={handleCloseModal}
              interviewId={interviewId}
              interview={interview}
              setInterview={setInterview}
              typeInterview={typeInterview}
              applicationId={selectedApplicationId}
              setTypeInterview={setTypeInterview}
              setApplication={setApplication}
              fetchListApplicationJobOffers={fetchListApplicationJobOffers}
            />
          )}

          {openConfirmCanceledCandidature && (
            <ModalStudentCancelConfirmation
              applicationId={selectedApplicationId}
              handleCloseModal={handleCloseModal}
              universityColor={universityColor}
              getInterviewId={getInterviewId}
              interviewId={interviewId}
              interview={interview}
            />
          )}

          {openCanceledModal && (
            <ModalInformation
              universityColor={universityColor}
              modalOpenState={openCanceledModal}
              config={configModalCanceled}
              buttonCancelFunction={handleCloseModal}
              buttonConfirmFunction={() => fetchCancelJobOffer(selectedJobId)}
              closeModalFunction={handleCloseModal}
            />
          )}

          <ContainerButtonsPagination style={{ marginTop: '20px' }}>
            {listApplicationJobs?.data?.next && (
              <ButtonPaginationSeeMore
                universityColor={universityColor}
                onClick={handleMoreItems}
              >
                {isLoadingApplications ? (
                  <FilterLoading width={'88px'} height={'16px'} />
                ) : (
                  'Ver mais'
                )}
              </ButtonPaginationSeeMore>
            )}
          </ContainerButtonsPagination>
        </>
      )}
    </>
  );
};

export default ApplicationCardJobs;
