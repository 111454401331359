import styled from 'styled-components';

export const Bubble = styled.div`
  background-color: #fff;
  border: 1px solid #009291;
  min-width: 100px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  min-height: 40px;
  height: auto;
  border-radius: 4px;
  label {
    color: #009291;
  }
`;

export const Ul = styled.ul`
  list-style: none;
`;

export const Li = styled.li`
  display: flex;
  margin-bottom: 8px;

  &:before {
    content: '•';
    font-size: 55px;
    border-radius: 50%;
    color: ${(props) => props.color};
    padding-right: 5px;
  }
`;

export const CustomToolTip = styled.div`
  background-color: #ffff;
  border-radius: 4px;
  width: 60px;
  text-align: center;
  height: 30px;
  padding: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  label {
    color: #009291;
  }
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TotalStudents = styled.p`
  margin-top: 22px;
  font-weight: bold;
`;
