import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Content, TabIndicationJob, Divider } from './styles';
import StudentItem from '../../components/IndicationStudent/components/StudentItem';
import { Search } from '@material-ui/icons';
import { getIndications } from '../../../jobOpeningsRebuild/components/IndicationStudent/services/IndicationStudent.service';
import GenericMessage from 'components/GenericMessage';
import BaseLayoutContext from 'contexts/base-layout';

export default function IndicationJobsTabs(props) {
  const [students, setStudents] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const { openSnackBar } = useContext(BaseLayoutContext);
  const { countJobs } = props;

  const refFilter = useRef(null);

  useEffect(() => {
    countJobs();
    (async () => {
      const response = await getIndications();
      setStudents(response.results);
    })();
  }, []);

  let timeout = null;
  const filterStudents = async (value) => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(async () => {
      setLoadingFilters(true);
      try {
        const response = await getIndications(value);
        setStudents(response.results);
      } catch (e) {
        openSnackBar('Não foi possivel fitrar as indicações', true);
      } finally {
        setLoadingFilters(false);
        refFilter.current.focus();
      }
    }, 500);
  };

  return (
    <Container>
      <Content>
        <TabIndicationJob>
          <div className="inputFilterContainer">
            <input
              type="text"
              name="filter"
              placeholder="Buscar"
              onChange={(e) => filterStudents(e.target.value)}
              disabled={loadingFilters}
              ref={refFilter}
            />
            <button disabled={loadingFilters}>
              <Search />
            </button>
          </div>
          <div>
            {students.length ? (
              students.map((student, index) =>
                index === students.length - 1 ? (
                  <StudentItem student={student} hasIndication={true} />
                ) : (
                  <>
                    <StudentItem student={student} hasIndication={true} />
                    <Divider />
                  </>
                )
              )
            ) : (
              <GenericMessage
                title={'Nenhuma indicação encontrada'}
                subtitle={'Nenhuma indicação foi encontrado com esses filtros.'}
              />
            )}
          </div>
        </TabIndicationJob>
      </Content>
    </Container>
  );
}
