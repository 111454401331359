import React, { useContext, useState, useEffect } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid } from '../../Styles/StyleFilter';
import FilterModal from 'components/FilterModal';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { DashboardContext } from '../../Context';
import { SpanError } from 'views/StaffPages/Dashboard/Styles/BaseComponent';
import * as Yup from 'yup';

export function FilterJobsDashboard({
  filterOptions,
  filterState,
  setFilterState,
  appliedFilters,
  setAppliedFilters,
  zIndex = 1300,
  loading = false,
  staffRole,
}) {
  const { setQueryFilter } = useContext(DashboardContext);
  const [educationalGroupSelect, setEducacionalGroupSelect] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        start_date: Yup.date(),
        end_date: Yup.date().min(
          Yup.ref('start_date'),
          `A data final precisa ser maior do que a data inicial.`
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  const responsibleOptions = [
    { label: 'Instituição de ensino', value: 'university' },
    { label: 'Empresas', value: 'company' },
  ];

  const educationalGroupResponsibleOptions = [
    { label: 'Grupo Educacional', value: 'educational_group' },
    { label: 'Instituição de ensino', value: 'university' },
    { label: 'Empresas', value: 'company' },
  ];

  const handleViewSelect = (arrayValues) => {
    if (arrayValues.includes('university')) {
      setEducacionalGroupSelect(true);
    } else {
      setEducacionalGroupSelect(false);
      setFilterState((prevState) => {
        const newFilterState = { ...prevState };
        delete newFilterState.university;
        return newFilterState;
      });
    }
  };

  function renderFilterContent() {
    return [
      <FilterGrid>
        <Filter
          name={['start_date', 'end_date']}
          label={'Período das informações'}
          type={FilterType.DATE_RANGE}
          maxDate={new Date()}

          settings={{
            placeholder: ['Data inicial', 'Data final'],
            onChange: () => {
              setErrorDate(false);
            },
          }}
        />
        <Filter
          label={'Responsável pela publicação'}
          name={'publisher'}
          type={FilterType.MULTI_SELECT}
          options={
            staffRole === 'educationalGroup'
              ? educationalGroupResponsibleOptions
              : responsibleOptions
          }
          settings={{
            onChange: handleViewSelect,
          }}
        />
        {educationalGroupSelect && staffRole === 'educationalGroup' && (
          <Filter
            label={'Universidade'}
            name={'university'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.university}
          />
        )}
        <Filter
          label={'Área de atuação'}
          name={'macro_area'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.macro_area}
        />
        <Filter
          label={'Curso'}
          name={'academic_course'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.academic_course}
        />
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const isValid = await validateData(filters);
    if (!isValid) return false;
    const existFilters = filters && Object.keys(filters).length > 0;

    if (!existFilters) {
      onClearFilters();
      return;
    }

    if (existFilters) {
      const queryString = queryParamsFromFilterObject(filters);
      setQueryFilter(queryString);
    }
  }

  async function onClearFilters() {
    setQueryFilter('');
    setErrorDate(false);
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      zIndex={zIndex}
      loading={loading}
      validateDate={true}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
