export function queryParamsFromFilterObject(
  filterObject,
  options = {
    commaSeparatedFields: [],
  }
) {
  if (!filterObject) {
    return '';
  }
  return Object.keys(filterObject).reduce((acc, key) => {
    let newAcc = acc === '' ? '' : `${acc}&`;
    const typeOfFilter = typeof filterObject[key];
    if (Array.isArray(filterObject[key])) {
      if (options.commaSeparatedFields.includes(key)) {
        return `${newAcc}${key}=${filterObject[key].join(',')}`;
      }
      return `${newAcc}${key}=${filterObject[key].join(`&${key}=`)}`;
    } else if (['string', 'boolean', 'number'].includes(typeOfFilter)) {
      return `${newAcc}${key}=${filterObject[key]}`;
    } else if (typeOfFilter === 'object') {
      return `${newAcc}${key}=${filterObject[key].toISOString().split('T')[0]}`;
    }
    return acc;
  }, '');
}
