import React from 'react';
import {
  ContainerLegend,
  ContainerSkeleton,
  Content,
  SkeletonLegend,
} from '../../Styles/StyleSkeleton';

export function LinearGraphSkeleton({ strokeWidth = 8, color = '#f1f1f1' }) {
  return (
    <Content>
      <ContainerLegend>
        <SkeletonLegend widthLegend={197} />
        <SkeletonLegend widthLegend={197} />
      </ContainerLegend>
      <ContainerSkeleton>
        <div className="legend-area"></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="5 0 1433 321">
          <defs>
            <linearGradient id="chart-shine" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor={'#e1e0e0'} stopOpacity="1">
                <animate
                  attributeName="stop-opacity"
                  values="1;0;1"
                  dur="1.9s"
                  repeatCount="indefinite"
                  from="0"
                  to="100%"
                />
              </stop>
              <stop offset="50%" stopColor={'#f0f0f0'} stopOpacity="0.9">
                <animate
                  attributeName="stop-opacity"
                  values="0.4;1;0.4"
                  dur="1s"
                  repeatCount="indefinite"
                  from="0"
                  to="100%"
                />
              </stop>
              <stop offset="100%" stopColor={'#e1e0e0'} stopOpacity="1">
                <animate
                  attributeName="stop-opacity"
                  values="1;0;1"
                  dur="1.9s"
                  repeatCount="indefinite"
                  from="0"
                  to="100%"
                />
              </stop>
            </linearGradient>
          </defs>
          <path
            fill="#fff"
            fillOpacity="1"
            stroke="url(#chart-shine)"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            d="M0,32L16,32C32,32,64,32,96,32C128,32,160,32,192,42.7C224,53,256,75,288,112C320,149,352,203,384,208C416,213,448,171,480,138.7C512,107,544,85,576,90.7C608,96,640,128,672,144C704,160,736,160,768,186.7C800,213,832,267,864,288C896,309,928,299,960,293.3C992,288,1024,288,1056,245.3C1088,203,1120,117,1152,90.7C1184,64,1216,96,1248,112C1280,128,1312,128,1344,160C1376,192,1408,256,1424,288L1440,320L1440,320L1424,320C1408,320,1376,320,1344,320C1312,320,1280,320,1248,320C1216,320,1184,320,1152,320C1120,320,1088,320,1056,320C1024,320,992,320,960,320C928,320,896,320,864,320C832,320,800,320,768,320C736,320,704,320,672,320C640,320,608,320,576,320C544,320,512,320,480,320C448,320,416,320,384,320C352,320,320,320,288,320C256,320,224,320,192,320C160,320,128,320,96,320C64,320,32,320,16,320L0,320Z"
          ></path>
        </svg>
      </ContainerSkeleton>
    </Content>
  );
}
