import React, { useState, useEffect } from 'react';
import { Benchmarking } from './components/Benchmarking';
import { WorkabilityIndicatorContextProvider } from './components/WorkabilityIndicator/context';
import { WorkabilityIndicator } from './components/WorkabilityIndicator';
import backgroundHeaderImage from 'assets/img/my-dashboard-background-header.png';
import CompanyHeader from '../../../components/ViewsHeader/CompanyViewsHeader';
import { getAnalyticsDataGeneral, getAnalyticsDataCourses } from './services';
import { genericAdapter } from 'adapters/genericAdapter';

export default function IndicatorsEngine({ handleIsLoadingState }) {
  const [activeTab, setActiveTab] = useState(0);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [analitycsDataCourse, setAnalitycsDataCourse] = useState(null);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };
  const renderContentTab = () => {
    const components = {
      0: (
        <WorkabilityIndicatorContextProvider>
          <WorkabilityIndicator
            data={analyticsData}
            dataCourses={analitycsDataCourse}
          />
        </WorkabilityIndicatorContextProvider>
      ),
      1: <Benchmarking />,
    };
    return components[activeTab];
  };

  const getData = async () => {
    const analyticsDataGeneral = await getAnalyticsDataGeneral();
    const { result, code } = analyticsDataGeneral;
    setAnalyticsData(genericAdapter({ ...result, code }));

    const analyticsDataCourses = await getAnalyticsDataCourses();
    const courses = genericAdapter(analyticsDataCourses?.result);
    setAnalitycsDataCourse({ ...courses });
  };
  useEffect(() => {
    handleIsLoadingState(false);
    getData();
  }, []);

  return (
    <div>
      <CompanyHeader
        title="Indicador de trabalhabilidade"
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundHeaderImage}
        tabs={['Indicador de trabalhabilidade', 'Comparativo']}
      />
      {renderContentTab()}
    </div>
  );
}
