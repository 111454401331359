import React, { useState, useEffect } from 'react';
import StepOne from './StepsProfessionalStatus/stepOne';
import { StepTwo } from './StepsProfessionalStatus/stepTwo';
import { StepFour } from './StepsProfessionalStatus/stepFour';
import { StepThree } from './StepsProfessionalStatus/stepThree';
import { useFormContext } from 'views/ProfessionalStatus/context';
import professionalStatusApi from 'views/ProfessionalStatus/services/professionalStatusApi';

export default function ContentProfessionalStatus({
  professional_status,
  professionalStatusData,
  academicProfile,
  universityColor,
  globalState,
  setIsLoading,
  metadata,
  classes,
}) {
  const { step, setStep, setFormValues } = useFormContext();
  const [surveysData, setSurveysData] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getSelectOptions = async () => {
      const response = await professionalStatusApi.getFirstAccessOptions();
      setOptions(response);
      setSurveysData(response);
      setIsLoading(false);
    };

    getSelectOptions();
  }, []);

  const nextFormStep = () => {
    setStep((prevsStep) => prevsStep + 1);
  };

  const prevsFormStep = () => {
    setStep((prevsStep) => prevsStep - 1);
  };

  const handleSubmit = (data) => {
    setFormValues(data);
    nextFormStep();
  };

  const isSurveysDefinedData = surveysData.surveys?.length > 0;

  const stepsWithProfessional = {
    0: (
      <StepOne
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
        isSurveysDefinedData={isSurveysDefinedData}
      />
    ),
    1: (
      <StepTwo
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
        isSurveysDefinedData={isSurveysDefinedData}
      />
    ),
    2: (
      <StepThree
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
        isSurveysDefinedData={isSurveysDefinedData}
      />
    ),
    3: (
      <StepFour
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
        isSurveysDefinedData={isSurveysDefinedData}
        professionalStatusData={professionalStatusData}
      />
    ),
  };
  const stepsWithoutSurveys = {
    0: (
      <StepOne
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
      />
    ),
    1: (
      <StepTwo
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
      />
    ),

    2: (
      <StepFour
        classes={classes}
        metadata={metadata}
        academicProfile={academicProfile}
        professional_status={professional_status}
        globalState={globalState}
        universityColor={universityColor}
        prevsFormStep={prevsFormStep}
        nextFormStep={nextFormStep}
        handleSubmit={handleSubmit}
        options={options}
        professionalStatusData={professionalStatusData}
      />
    ),
  };
  const currentObject = !isSurveysDefinedData
    ? stepsWithoutSurveys
    : stepsWithProfessional;

  return <div>{currentObject[step]}</div>;
}
