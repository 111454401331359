import React from 'react';

export function WithCurriculumCategoryIcon({ size = 56, color = '#fff' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9565 9.00699C14.6356 8.99646 13.3647 9.51088 12.4229 10.4372C11.4812 11.3634 10.9459 12.6257 10.9346 13.9466C10.9274 14.6046 11.0502 15.2575 11.296 15.8679C11.5417 16.4783 11.9055 17.0342 12.3666 17.5037C12.8277 17.9732 13.3769 18.347 13.9828 18.6037C14.5886 18.8605 15.2392 18.9951 15.8972 18.9998C17.2168 19.0011 18.4832 18.4794 19.4189 17.5489C20.3546 16.6185 20.8834 15.3551 20.8895 14.0355C20.9018 12.7146 20.3891 11.4429 19.464 10.5C18.539 9.55705 17.2774 9.02003 15.9565 9.00699V9.00699ZM15.9022 16.5234C15.5689 16.5211 15.2394 16.4527 14.9327 16.3221C14.626 16.1916 14.3483 16.0016 14.1155 15.763C13.8828 15.5244 13.6996 15.2421 13.5767 14.9323C13.4538 14.6225 13.3936 14.2914 13.3995 13.9581C13.409 13.2951 13.6788 12.6624 14.1507 12.1966C14.6227 11.7309 15.2589 11.4694 15.9219 11.4686C16.2552 11.4718 16.5845 11.5408 16.891 11.6717C17.1975 11.8026 17.475 11.9928 17.7078 12.2314C17.9405 12.4699 18.1238 12.7521 18.2471 13.0617C18.3704 13.3713 18.4313 13.7023 18.4263 14.0355C18.4185 14.6992 18.1489 15.3329 17.6762 15.7989C17.2035 16.2648 16.5659 16.5252 15.9022 16.5234V16.5234Z"
        fill={color}
      />
      <path
        d="M6.87749 35.8351C7.12447 35.2769 7.58878 35.0892 8.18318 35.0908C10.6283 35.1024 13.0734 35.0908 15.5185 35.0908C17.9636 35.0908 20.4087 35.0908 22.8538 35.0908C23.6326 35.0908 24.171 35.5848 24.1875 36.278C24.2039 36.9712 23.6523 37.5162 22.8702 37.5458C22.7402 37.5458 22.6101 37.5458 22.4817 37.5458H13.3681C11.6261 37.5458 9.88569 37.5557 8.14366 37.5458C7.1294 37.5442 6.50537 36.683 6.87749 35.8351Z"
        fill={color}
      />
      <path
        d="M7.48182 27.4172C7.07018 22.8513 10.4785 19.7657 13.9543 19.5566C14.613 19.5171 15.2864 19.5566 15.8133 19.5566C17.4367 19.499 18.9055 19.5154 20.2951 20.1823C21.6256 20.8126 22.7296 21.8375 23.4567 23.1177C24.1838 24.3978 24.4988 25.8709 24.3588 27.3365C24.2896 28.0395 23.7891 28.5352 23.1436 28.5434C22.4982 28.5516 21.9483 28.0313 21.9005 27.3035C21.8593 26.696 21.9153 26.0851 21.7655 25.4825C21.5375 24.5114 20.9933 23.6437 20.2184 23.0157C19.4434 22.3877 18.4817 22.035 17.4845 22.0132C16.192 21.9968 14.8846 21.8486 13.6053 22.0972C11.4862 22.5006 10.0405 24.2377 9.94502 26.3782C9.9302 26.7305 9.94502 27.0846 9.90551 27.432C9.87454 27.739 9.72913 28.0232 9.49818 28.2279C9.26722 28.4326 8.96766 28.5429 8.65909 28.5368C8.35882 28.5298 8.0719 28.4113 7.85426 28.2043C7.63661 27.9973 7.50383 27.7167 7.48182 27.4172V27.4172Z"
        fill={color}
      />
      <path
        d="M32.6359 42.9306C32.6052 43.2086 32.4804 43.4678 32.2821 43.6652C32.0839 43.8625 31.8241 43.9862 31.5459 44.0156C31.3632 44.0338 31.1771 44.0288 30.9911 44.0288H8.44343C7.34849 44.0288 6.75575 43.5859 6.77222 42.7824C6.78868 41.9789 7.36497 41.5705 8.41875 41.5705H31.0767C31.3353 41.5475 31.5959 41.5778 31.8423 41.6595C32.0962 41.7543 32.3115 41.9303 32.455 42.1602C32.5986 42.3901 32.6622 42.6609 32.6359 42.9306V42.9306Z"
        fill={color}
      />
      <path
        d="M41.132 13.644C36.7753 9.30486 32.4268 4.95747 28.0865 0.601844C27.9054 0.406374 27.6847 0.251691 27.4391 0.148073C27.1935 0.0444552 26.9287 -0.00572466 26.6623 0.000861011C19.513 0.0118379 12.3638 0.0118379 5.21456 0.000861011C4.71559 -0.00770168 4.21759 0.0476323 3.73268 0.165514C1.42753 0.764852 0.00164653 2.68635 0 5.23683C0 12.1446 0 19.0529 0 25.9617C0 32.9068 0 39.8519 0 46.797C0 49.8859 2.1092 51.9984 5.1948 52C15.6404 52 26.0871 52 36.5349 52C39.6205 52 41.7198 49.8875 41.7215 46.7904C41.7281 36.2152 41.7281 25.6396 41.7215 15.0633C41.7282 14.7987 41.6792 14.5355 41.5777 14.291C41.4761 14.0465 41.3243 13.8261 41.132 13.644V13.644ZM28.2084 4.39381L37.3499 13.5222H30.5119C28.8654 13.5222 28.2067 12.8636 28.2067 11.1923L28.2084 4.39381ZM39.2517 46.7788C39.2517 48.5719 38.2638 49.545 36.4526 49.545H5.28207C3.44619 49.545 2.48296 48.5769 2.48296 46.7344C2.48296 39.8255 2.48296 32.9172 2.48296 26.0095C2.48296 19.1385 2.51754 12.2675 2.46156 5.39655C2.44674 3.42071 3.74091 2.44267 5.39732 2.45419C11.9834 2.49865 18.5844 2.47889 25.1788 2.45419C25.6892 2.45419 25.7748 2.59744 25.7715 3.0667C25.7485 5.82629 25.7583 8.58588 25.76 11.3455C25.76 14.1742 27.5712 15.9821 30.4213 15.9854C33.199 15.9854 35.9784 15.9969 38.7561 15.9755C39.1743 15.9755 39.2698 16.0793 39.2682 16.4925C39.2528 26.5891 39.2473 36.6845 39.2517 46.7788V46.7788Z"
        fill={color}
      />
      <path
        d="M15.9565 9.00699C14.6356 8.99646 13.3647 9.51088 12.4229 10.4372C11.4812 11.3634 10.9459 12.6257 10.9346 13.9466C10.9274 14.6046 11.0502 15.2575 11.296 15.8679C11.5417 16.4783 11.9055 17.0342 12.3666 17.5037C12.8277 17.9732 13.3769 18.347 13.9828 18.6037C14.5886 18.8605 15.2392 18.9951 15.8972 18.9998C17.2168 19.0011 18.4832 18.4794 19.4189 17.5489C20.3546 16.6185 20.8834 15.3551 20.8895 14.0355C20.9018 12.7146 20.3891 11.4429 19.464 10.5C18.539 9.55705 17.2774 9.02003 15.9565 9.00699V9.00699ZM15.9022 16.5234C15.5689 16.5211 15.2394 16.4527 14.9327 16.3221C14.626 16.1916 14.3483 16.0016 14.1155 15.763C13.8828 15.5244 13.6996 15.2421 13.5767 14.9323C13.4538 14.6225 13.3936 14.2914 13.3995 13.9581C13.409 13.2951 13.6788 12.6624 14.1507 12.1966C14.6227 11.7309 15.2589 11.4694 15.9219 11.4686C16.2552 11.4718 16.5845 11.5408 16.891 11.6717C17.1975 11.8026 17.475 11.9928 17.7078 12.2314C17.9405 12.4699 18.1238 12.7521 18.2471 13.0617C18.3704 13.3713 18.4313 13.7023 18.4263 14.0355C18.4185 14.6992 18.1489 15.3329 17.6762 15.7989C17.2035 16.2648 16.5659 16.5252 15.9022 16.5234V16.5234Z"
        fill={color}
      />
      <path
        d="M6.87749 35.8351C7.12447 35.2769 7.58878 35.0892 8.18318 35.0908C10.6283 35.1024 13.0734 35.0908 15.5185 35.0908C17.9636 35.0908 20.4087 35.0908 22.8538 35.0908C23.6326 35.0908 24.171 35.5848 24.1875 36.278C24.2039 36.9712 23.6523 37.5162 22.8702 37.5458C22.7402 37.5458 22.6101 37.5458 22.4817 37.5458H13.3681C11.6261 37.5458 9.88569 37.5557 8.14366 37.5458C7.1294 37.5442 6.50537 36.683 6.87749 35.8351Z"
        fill={color}
      />
      <path
        d="M7.48182 27.4172C7.07018 22.8513 10.4785 19.7657 13.9543 19.5566C14.613 19.5171 15.2864 19.5566 15.8133 19.5566C17.4367 19.499 18.9055 19.5154 20.2951 20.1823C21.6256 20.8126 22.7296 21.8375 23.4567 23.1177C24.1838 24.3978 24.4988 25.8709 24.3588 27.3365C24.2896 28.0395 23.7891 28.5352 23.1436 28.5434C22.4982 28.5516 21.9483 28.0313 21.9005 27.3035C21.8593 26.696 21.9153 26.0851 21.7655 25.4825C21.5375 24.5114 20.9933 23.6437 20.2184 23.0157C19.4434 22.3877 18.4817 22.035 17.4845 22.0132C16.192 21.9968 14.8846 21.8486 13.6053 22.0972C11.4862 22.5006 10.0405 24.2377 9.94502 26.3782C9.9302 26.7305 9.94502 27.0846 9.90551 27.432C9.87454 27.739 9.72913 28.0232 9.49818 28.2279C9.26722 28.4326 8.96766 28.5429 8.65909 28.5368C8.35882 28.5298 8.0719 28.4113 7.85426 28.2043C7.63661 27.9973 7.50383 27.7167 7.48182 27.4172V27.4172Z"
        fill={color}
      />
      <path
        d="M32.6359 42.9306C32.6052 43.2086 32.4804 43.4678 32.2821 43.6652C32.0839 43.8625 31.8241 43.9862 31.5459 44.0156C31.3632 44.0338 31.1771 44.0288 30.9911 44.0288H8.44343C7.34849 44.0288 6.75575 43.5859 6.77222 42.7824C6.78868 41.9789 7.36497 41.5705 8.41875 41.5705H31.0767C31.3353 41.5475 31.5959 41.5778 31.8423 41.6595C32.0962 41.7543 32.3115 41.9303 32.455 42.1602C32.5986 42.3901 32.6622 42.6609 32.6359 42.9306V42.9306Z"
        fill={color}
      />
      <path
        d="M32.6359 42.9306C32.6052 43.2086 32.4804 43.4678 32.2821 43.6652C32.0839 43.8625 31.8241 43.9862 31.5459 44.0156C31.3632 44.0338 31.1771 44.0288 30.9911 44.0288H8.44343C7.34849 44.0288 6.75575 43.5859 6.77222 42.7824C6.78868 41.9789 7.36497 41.5705 8.41875 41.5705H31.0767C31.3353 41.5475 31.5959 41.5778 31.8423 41.6595C32.0962 41.7543 32.3115 41.9303 32.455 42.1602C32.5986 42.3901 32.6622 42.6609 32.6359 42.9306V42.9306Z"
        fill={color}
      />
      <path
        d="M24.3588 27.3314C24.2897 28.0345 23.7891 28.5301 23.1437 28.5383C22.4982 28.5465 21.9483 28.0262 21.9006 27.2985C21.8594 26.6909 21.9154 26.08 21.7655 25.4774C21.5376 24.5063 20.9934 23.6386 20.2184 23.0106C19.4435 22.3826 18.4818 22.0299 17.4845 22.0081C16.192 21.9917 14.8847 21.8435 13.6053 22.0921C11.4862 22.4955 10.0406 24.2326 9.94507 26.3731C9.93025 26.7255 9.94507 27.0795 9.90555 27.4269C9.87458 27.734 9.72918 28.0181 9.49822 28.2228C9.26727 28.4275 8.9677 28.5378 8.65914 28.5317C8.35915 28.5243 8.07262 28.4056 7.85533 28.1987C7.63803 27.9917 7.5055 27.7113 7.48351 27.4121C7.07188 22.8462 10.4802 19.7606 13.956 19.5515C14.6147 19.512 15.2881 19.5515 15.815 19.5515C17.4384 19.4939 18.9072 19.5104 20.2968 20.1772C21.627 20.8077 22.7306 21.8328 23.4574 23.1129C24.1843 24.393 24.499 25.866 24.3588 27.3314Z"
        fill={color}
      />
      <path
        d="M15.9565 9.00699C14.6356 8.99646 13.3647 9.51088 12.4229 10.4372C11.4812 11.3634 10.9459 12.6257 10.9346 13.9466C10.9274 14.6046 11.0502 15.2575 11.296 15.8679C11.5417 16.4783 11.9055 17.0342 12.3666 17.5037C12.8277 17.9732 13.3769 18.347 13.9828 18.6037C14.5886 18.8605 15.2392 18.9951 15.8972 18.9998C17.2168 19.0011 18.4832 18.4794 19.4189 17.5489C20.3546 16.6185 20.8834 15.3551 20.8895 14.0355C20.9018 12.7146 20.3891 11.4429 19.464 10.5C18.539 9.55705 17.2774 9.02003 15.9565 9.00699V9.00699ZM15.9022 16.5234C15.5689 16.5211 15.2394 16.4527 14.9327 16.3221C14.626 16.1916 14.3483 16.0016 14.1155 15.763C13.8828 15.5244 13.6996 15.2421 13.5767 14.9323C13.4538 14.6225 13.3936 14.2914 13.3995 13.9581C13.409 13.2951 13.6788 12.6624 14.1507 12.1966C14.6227 11.7309 15.2589 11.4694 15.9219 11.4686C16.2552 11.4718 16.5845 11.5408 16.891 11.6717C17.1975 11.8026 17.475 11.9928 17.7078 12.2314C17.9405 12.4699 18.1238 12.7521 18.2471 13.0617C18.3704 13.3713 18.4313 13.7023 18.4263 14.0355C18.4185 14.6992 18.1489 15.3329 17.6762 15.7989C17.2035 16.2648 16.5659 16.5252 15.9022 16.5234V16.5234Z"
        fill={color}
      />
      <path
        d="M24.1876 36.2863C24.2073 36.9943 23.6525 37.5245 22.8704 37.5541C22.7403 37.5541 22.6102 37.5541 22.4818 37.5541H13.3682C11.6262 37.5541 9.88581 37.564 8.14378 37.5541C7.13775 37.5541 6.51371 36.6847 6.88582 35.8368C7.1328 35.2786 7.59713 35.0909 8.19153 35.0925C10.6366 35.1041 13.0817 35.0925 15.5268 35.0925C17.9719 35.0925 20.417 35.0925 22.8621 35.0925C23.6327 35.1024 24.1695 35.5947 24.1876 36.2863Z"
        fill={color}
      />
    </svg>
  );
}
