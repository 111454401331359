import styled from 'styled-components';

export const SaveButtonProfileForm = styled.button`
  color: #fff;
  cursor: pointer;
  padding: 12px 30px;
  border: none;
  position: relative;
  letter-spacing: inherit;
  font-weight: 550;
  font-size: 14px;
  border-radius: 5px !important;
  text-transform: uppercase;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const CancelButtonProfileForm = styled.button`
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
  padding: 12px 30px;
  border: none;
  position: relative;
  letter-spacing: inherit;
  font-weight: 550;
  font-size: 14px;
  border-radius: 5px !important;
  text-transform: uppercase;
  background-color: transparent;
  transition: 0.5s;

  &:hover {
    transition: 0.5s;
    background-color: #979797;
    color: #fff;
  }
`;
