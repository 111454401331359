import React, { useEffect, useState, useContext, useMemo } from 'react';
import {
  TextName,
  ContainerLogo,
  Container,
  ContainerInfo,
  FragmentColor,
  Header,
  Row,
  ContainerMiniLogo,
  TextRow,
  ContainerActions,
  ProfileDetail,
  ButtonFavorite,
} from './styles';
import LogoNetworkNotFound from 'assets/Network/logoCompanyNotFound';
import LogoActivitiesSectors from 'assets/Network/logoActivitiesSectors';
import LogoCompanyNetwork from 'assets/Network/logoCompanyNetwork';
import LogoLocationNetwork from 'assets/Network/logoLocationNetwork';

import { AiOutlineStar } from 'react-icons/ai';
import { AiFillStar } from 'react-icons/ai';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import LogoJobNetwork from 'assets/Network/logoJobNetwork';

export default function CardNetwork({
  data,
  universityColor,
  favoriteCompany,
  handleFavorite,
  handleOpenModal,
}) {
  const hasLogo = Boolean(data?.logo);

  const jobOrOcupation = () => {
    const { offersQuantity, activitiesSectors } = data;

    if (offersQuantity > 0) {
      const s = offersQuantity > 1 ? 's' : '';
      return (
        <TextRow color={universityColor}>
          {offersQuantity} {` vaga${s} aberta${s}`}
        </TextRow>
      );
    } else {
      const sectorName =
        activitiesSectors[0] && activitiesSectors[0].length > 19
          ? `${activitiesSectors[0].substring(0, 19)}...`
          : activitiesSectors[0];
      return (
        <TextRow color={universityColor}>
          {` ${
            activitiesSectors[0]
              ? capitalizeFirstLetter(sectorName)
              : 'Não informado'
          } `}{' '}
          {activitiesSectors.length > 1 && (
            <b>+{activitiesSectors.length - 1}</b>
          )}
        </TextRow>
      );
    }
  };

  const returnAdress = () => {
    const adress =
      data.city || data.state
        ? `${data.city ? data.city : 'Não informado'}/ ${
            data.state ? data.state : 'Não informado'
          }`
        : 'Não informado';

    return adress && adress.length > 19
      ? `${adress.substring(0, 19)}...`
      : adress;
  };

  const sizeCompany = () => {
    if (data.size && typeof data.size === 'string') {
      const sizeSplit = data.size.split('(');
      if (typeof sizeSplit === 'object') {
        return sizeSplit[0];
      } else {
        return data.size;
      }
    } else {
      return 'Não informado';
    }
  };

  const favoriteString = data.isFavorite ? 'Desfavoritar' : 'Favoritar';

  return (
    <Container>
      <FragmentColor color={data.companyColor} />
      <Header>
        <ContainerLogo img={hasLogo && data.logo}>
          {!hasLogo && <LogoNetworkNotFound color={universityColor} />}
        </ContainerLogo>
        <TextName>
          {data.legalName.length < 20
            ? data.legalName
            : `${data.legalName.substring(0, 20)}...`}
        </TextName>
      </Header>
      <ContainerInfo>
        <Row>
          <ContainerMiniLogo color={universityColor}>
            {data?.offersQuantity === 0 ? (
              <LogoActivitiesSectors color={universityColor} />
            ) : (
              <LogoJobNetwork color={universityColor} />
            )}
          </ContainerMiniLogo>
          {jobOrOcupation()}
        </Row>
        <Row>
          <ContainerMiniLogo color={universityColor}>
            <LogoCompanyNetwork color={universityColor} />
          </ContainerMiniLogo>
          <TextRow>{sizeCompany()}</TextRow>
        </Row>
        <Row>
          <ContainerMiniLogo color={universityColor}>
            <LogoLocationNetwork color={universityColor} />
          </ContainerMiniLogo>
          <TextRow>{returnAdress()}</TextRow>
        </Row>
      </ContainerInfo>
      <ContainerActions>
        <ProfileDetail
          color={universityColor}
          onClick={() => handleOpenModal(data.id)}
        >
          Visualizar perfil
        </ProfileDetail>
        <span>
          <Row>
            <ButtonFavorite
              color={universityColor}
              isFavorite={data.isFavorite}
              onClick={() => {
                handleFavorite(data);
              }}
              disabled={favoriteCompany !== null}
            >
              {data.isFavorite ? <AiFillStar /> : <AiOutlineStar />}
            </ButtonFavorite>
            <TextRow>{`${favoriteString} empresa`}</TextRow>
          </Row>
        </span>
      </ContainerActions>
    </Container>
  );
}
