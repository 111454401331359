import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 0 2rem;
`;

export const WrapperLink = styled.div`
  width: 100%;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.31px;
  margin-top: 30px;
  color: #606062;
`;

export const WrapperForm = styled.div`
  font-family: Roboto;
  font-size: 16px;
  width: 100%;
  font-weight: 400;
  line-height: 25.31px;
  margin: 30px 0 0;
  color: #606062;

  > strong {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin-right: 10px;
    padding: 10px 0;
  }
  > p {
    margin: 5px 0;
  }
`;
export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
`;

export const SubmitButton = styled.button`
  height: 44px;
  padding: 0 20px;
  border-radius: 3px;
  opacity: 0px;
  background-color: #009291;
  border: none;
  display: inline-flex;

  align-items: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.88px;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;
