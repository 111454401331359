import { authenticatedAPI } from 'services/api';

const getJobOffers = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/jobs?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getStudentJobDetails = async (id, finishedJobs = false) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/job/${id}?finished_jobs=${finishedJobs}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getStudentApplicationDetails = async (id) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/job-applications/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const studentContracted = async (jobId) => {
  const response = authenticatedAPI
    .patch(`/api/internal/students/me/job/${jobId}/candidate`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const applyJobOffer = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/job/${id}/apply`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const jobApplications = async () => {
  const response = authenticatedAPI
    .get('/api/internal/students/me/job-applications')
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInterviewId = async (interviewId, applicationId) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/students/me/job-applications/${applicationId}/interview/${interviewId}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateInterview = async (applicationId, interview) => {
  const response = await authenticatedAPI
    .put(
      `/api/internal/students/me/job-applications/${applicationId}/interview/${interview.id}`,
      interview
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const deleteJobApplication = async (id) => {
  const response = authenticatedAPI
    .delete(`/api/internal/students/me/job-applications/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getInfoJobOffers = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/public-job-offers?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  deleteJobApplication,
  getJobOffers,
  getStudentJobDetails,
  applyJobOffer,
  jobApplications,
  studentContracted,
  getStudentApplicationDetails,
  getInterviewId,
  updateInterview,
  getInfoJobOffers,
};
