import {
  ContainerCircularGraph,
  SkeletonCircularProgress,
  SkeletonPie,
} from '../../Styles/StyleSkeleton';

export function CircularGraphSkeleton(params) {
  return (
    <ContainerCircularGraph>
      <SkeletonPie />
    </ContainerCircularGraph>
  );
}

export function CircularProgressGraphSkeleton(params) {
  return (
    <SkeletonCircularProgress>
      <div className="white-circle"></div>
    </SkeletonCircularProgress>
  );
}
