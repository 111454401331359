import React, { useMemo, useRef, useEffect } from 'react';

import {
  Container,
  Box,
  Content,
  WrapperButton,
  ButtonSubmit,
  WrapperTitle,
  IconPersonStyled,
  BubbleDialog,
  H4Styled,
  StyledP,
  CloseButton,
} from './style';

import { Form } from '@unform/web';

import { useOutSideClick } from '../../../../../hooks/useOutsideClick';
import { updateFeedBack } from '../../../../../services';
import InputDesc from '../../../../../../../components/Opportunities/Inputs/InputDescription';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

export default function FeedBackModal({
  feedback,
  isOpen,
  closeModal,
  limit,
  currentPage,
  loadJobsData,
}) {
  const formRef = useRef(null);
  const divRef = useRef(null);

  const [clickedOutside, SetClickedOutside] = useOutSideClick(divRef);

  if (clickedOutside) {
    closeModal();
    SetClickedOutside(false);
  }

  const handleUpdateFeedback = async (data) => {
    const page = `limit=${limit}&offset=0`;
    await updateFeedBack(feedback.id, data);
    loadJobsData(page);
    closeModal();
  };

  const memoDescInput = useMemo(() => {
    return (
      <Form
        ref={formRef}
        initialData={feedback}
        onSubmit={handleUpdateFeedback}
      >
        <InputDesc name="feedback" />

        <WrapperButton>
          <ButtonSubmit type="submit">Atualizar</ButtonSubmit>
        </WrapperButton>
      </Form>
    );
  }, [feedback]);

  return (
    <Container isOpen={isOpen}>
      <Box>
        <Content ref={divRef}>
          <CloseButton onClick={closeModal}>X</CloseButton>
          <WrapperTitle>
            <IconPersonStyled src={IconPerson} />
            <BubbleDialog>
              <H4Styled>Editar feedback</H4Styled>
            </BubbleDialog>
          </WrapperTitle>
          <StyledP>
            Insira abaixo o novo feedback que ficará disponível para os
            candidatos.
          </StyledP>
          {memoDescInput}
        </Content>
      </Box>
    </Container>
  );
}
