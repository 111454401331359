export default function ArrowLeft({ color = '#606062', size = 18 }) {
  return (
    <svg
      width={size}
      height={size - 9}
      viewBox="0 0 13 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 21.3326L0 10.6659L10.6667 -0.000732422L12.56 1.8926L3.78667 10.6659L12.56 19.4393L10.6667 21.3326Z"
        fill={color}
      />
    </svg>
  );
}
