import React from 'react';

import { Status } from './styled';
import { object, string } from 'prop-types';

export default function CardStatus({ status }) {
  const { background, label, icon } = status;

  return (
    <Status backgroundColor={background}>
      {icon} {label}
    </Status>
  );
}

CardStatus.prototype = {
  status: object,
};
