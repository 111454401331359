// ##############################
// // // App styles
// #############################

import {
  drawerWidth,
  drawerMiniWidth,
  transition,
  successColor,
  dangerColor,
  dnaGreen,
  yellowIE,
  redIE,
  greenIE,
  darkBlueIE,
  lightBlueIE,
  orangeIE,
  blueIE,
} from 'assets/jss/material-dashboard-pro-react.jsx';

import BackgroundLogin from 'assets/img/login.jpg';

const appStyle = (theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  container: {
    minHeight: 'calc(100vh - 77px)',
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    position: 'relative',
    margin: 0,
    width: '100%',
    height: 'auto',
  },
  map: {
    marginTop: '70px',
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
  icon: {
    marginRight: 15,
  },
  success: {
    backgroundColor: successColor,
  },
  error: {
    backgroundColor: dangerColor,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
    fontSize: '10px',
    '& > div': {
      left: 10,
      backgroundColor: '#fff',
      height: '9px',
      width: '2px',
      display: 'inline-block',
    },
  },
  '@keyframes App-logo-spin': {
    '0%': { transform: 'scale(0, 0)', opacity: 0 },
    '50%': { transform: 'scale(5, 15)', opacity: 1 },
    '100%': { transform: 'scale(0, 0)', opacity: 0 },
  },
  '@keyframes loading-holder': {
    '0%': { transform: 'opacity(0)' },
    '100%': { transform: 'opacity(1)' },
  },
  rect3: {
    marginLeft: 20,
    animation: 'App-logo-spin 1.0s infinite ease-in-out',
    'animation-delay': '-1.0s',
  },
  rect4: {
    marginLeft: 20,
    animation: 'App-logo-spin 1.0s infinite ease-in-out',
    'animation-delay': '-0.9s',
  },
  rect5: {
    marginLeft: 20,
    animation: 'App-logo-spin 1.0s infinite ease-in-out',
    'animation-delay': '-0.8s',
  },
  rect2: {
    marginLeft: 20,
    animation: 'App-logo-spin 1.0s infinite ease-in-out',
    'animation-delay': '-1.1s',
  },
  rect1: {
    marginLeft: 20,
    animation: 'App-logo-spin 1.0s infinite ease-in-out',
    'animation-delay': '-1.2s',
  },
  dnaColorloadingHolder: {
    opacity: 0,
    backgroundColor: '#009291',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  yellowIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${yellowIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  redIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${redIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  greenIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${greenIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  darkBlueIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${darkBlueIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  blueIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${blueIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  lightBlueIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${lightBlueIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  orangeIESloadingHolder: {
    opacity: 0,
    backgroundColor: `${orangeIE}`,
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '0',
    transition: 'height .8s ease-in-out, opacity .5s ease-in-out',
    zIndex: 100000,
  },

  showLoading: {
    height: '100%',
    visibility: 'visible',
    transition: 'height .2s ease-in-out, opacity .3s ease-in-out',
    opacity: 1,
    zIndex: 100000,
  },
  contentLoginOverlay: {
    backgroundColor: 'rgba(0, 0, 0, .6)',
  },
  backgroundLogin: {
    backgroundImage: `url(${BackgroundLogin})`,
    backgroundSize: 'cover',
  },
});

export default appStyle;
