import React, { Fragment, useState, useEffect, useContext } from 'react';
import { object, func, array, string } from 'prop-types';

import { IconButton, CircularProgress } from '@material-ui/core';
import { ThumbUpAltOutlined, ThumbDownOutlined } from '@material-ui/icons';

import withStyles from '@material-ui/core/styles/withStyles';
import applicationReturnStyle from 'assets/jss/material-dashboard-pro-react/components/applicationReturnStyle';
import ApplicationReturnForm from './ApplicationReturnForm';
import AuthContext from '../../../../contexts/authentication';

const ApplicationReturn = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { metadata } = useContext(AuthContext);

  const {
    classes,
    studentData,
    returnJobApplication,
    options,
    fetchStudentData,
    updateCandidates,
  } = props;

  useEffect(() => {
    setStudent(studentData);
  }, []);

  const handleApplicationReturn = async (data, isPartnerJob) => {
    setLoading(true);

    const newStudent = await returnJobApplication(data || false);
    setStudent(newStudent);
    setLoading(false);
    return newStudent;
  };

  return (
    student && (
      <Fragment>
        <div className={`${classes.curriculumMenu} ${classes.choicesMenu}`}>
          <p>Deseja levar esse candidato adiante no processo de entrevista?</p>
          {loading ? (
            <CircularProgress className={classes.loading} />
          ) : !student.is_answered ? (
            <Fragment>
              <div className={classes.choicesContainer}>
                <div className={classes.applicationButtonContainer}>
                  <IconButton
                    disableRipple
                    onClick={async () => {
                      const newStudentData = await handleApplicationReturn();
                      await fetchStudentData(newStudentData);
                      if (updateCandidates) {
                        updateCandidates();
                      }
                    }}
                  >
                    <ThumbUpAltOutlined className={classes.iconGreen} />
                  </IconButton>
                  <small>Sim</small>
                </div>
                <div className={classes.applicationButtonContainer}>
                  <IconButton
                    disableRipple
                    onClick={() => {
                      setOpenModal(!openModal);
                      if (updateCandidates) {
                        updateCandidates();
                      }
                    }}
                  >
                    <ThumbDownOutlined className={classes.iconRed} />
                  </IconButton>
                  <small>Não</small>
                </div>
              </div>
              {metadata.role !== 'university' && (
                <small className={classes.choiceInfo}>
                  Será disponibilizado os dados para contato, somente dos
                  candidatos que forem interessantes para sua vaga.
                </small>
              )}
            </Fragment>
          ) : (
            <div className={classes.applicationButtonContainer}>
              {!student.is_interesting ? (
                <Fragment>
                  <ThumbDownOutlined className={classes.iconRed} />
                  <small>
                    Esse currículo foi marcado como não interessante
                  </small>
                </Fragment>
              ) : (
                <Fragment>
                  <ThumbUpAltOutlined className={classes.iconGreen} />
                  <small>Esse currículo foi marcado como interessante</small>
                </Fragment>
              )}
            </div>
          )}
        </div>

        {options.length > 0 && (
          <ApplicationReturnForm
            openModal={openModal}
            setModal={setOpenModal}
            options={options}
            handleApplicationReturn={handleApplicationReturn}
          />
        )}
      </Fragment>
    )
  );
};

ApplicationReturn.propTypes = {
  classes: object,
  studentData: object,
  returnJobApplication: func,
  options: array,
  updateCandidates: func,
  fetchStudentData: func.isRequired,
};

export default withStyles(applicationReturnStyle)(ApplicationReturn);
