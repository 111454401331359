import styled from 'styled-components';

export const SendButton = styled.button`
  width: 100px;
  height: 36px;
  border: none;
  background-color: #009291;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
  min-width: 30px;
  position: absolute;
  right: 5.6em;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    transform: scale(0.9);
  }
  &:disabled {
    background-color: #e1e1e1;
  }
`;
