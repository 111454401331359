import { authenticatedAPI } from 'services/api';

class JobOffersApi {
  getListJobOffers = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/another-offers?${params}`)
      .then((res) => res)
      .catch((error) => error);
  };

  geCountJobOffers = () => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/count`)
      .then((res) => res)
      .catch((error) => error);
  };

  getListFavoriteJobOffers = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/favorites?${params}`)
      .then((res) => res)
      .catch((error) => error);
  };

  fetchActionToFavoriteJobs = (data) => {
    return authenticatedAPI
      .post(`/api/internal/students/me/jobs/preferred`, data)
      .then((res) => res)
      .catch((error) => error);
  };

  fetchActionToUnfavoriteJobs = (id) => {
    return authenticatedAPI
      .delete(`/api/internal/students/me/jobs/preferred/${id}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getDetailsExclusiveJobs = (id) => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/${id}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getDetailsApplicationsJobs = (id) => {
    return authenticatedAPI
      .get(`api/internal/students/me/job-applications/${id}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getApplicationJobs = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/ongoing-applications?${params}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getFinishedJobs = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/finished-applications?${params}`)
      .then((res) => res)
      .catch((error) => error);
  };

  /* external jobs */

  getListExternalJobOffers = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/public-job-offers?${params}`)
      .then((res) => res)
      .catch((error) => error);
  };

  async getExternalOtherJobs(params = '') {
    try {
      const response = await authenticatedAPI.get(
        `api/internal/students/me/jobs/external?${params}`
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  getDetailsExternalJobOffers = (id) => {
    return authenticatedAPI
      .get(`/api/internal/students/public-job-offers/${id}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getFavoriteJobsCompanies = (id) => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/favorite-companies?${id}`)
      .then((res) => res)
      .catch((error) => error);
  };

  getCompareMatch = (id) => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/${id}/match`)
      .then((res) => res)
      .catch((error) => error);
  };

  /* filters */

  getFilterJobOffers = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/filter${params}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getFilterFavoriteJobs = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/favorite-filter${params}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  async getFilterOnGoingJobs(params = '') {
    try {
      const response = await authenticatedAPI.get(
        `/api/internal/students/me/ongoing-applications/filter${params}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }

  getFilterExternalOtherJobs = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/jobs/external-filter${params}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getFilterFinishedJobs = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/me/finished-applications/filter${params}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getFilterExternalJobs = (params = '') => {
    return authenticatedAPI
      .get(`/api/internal/students/public-job-offers/filter${params}`)
      .then((res) => res.data)
      .catch((error) => error);
  };

  getCandidateJobOffer = (data) => {
    return authenticatedAPI
      .post(`/api/internal/students/me/jobs/apply`, data)
      .then((res) => res)
      .catch((error) => error);
  };

  fetchCancelJobOffer = (id) => {
    return authenticatedAPI
      .delete(`/api/internal/students/me/jobs/cancel-apply/${id}`)
      .then((res) => res)
      .catch((error) => error);
  };
}

export default new JobOffersApi();
