const IModalConfigProfession = {
  ParagraphText:
    'As informações preenchidas na próxima tela são sugestões de textos que aparecem nas principais vagas para essa atuação profissional. Você poderá editar as informações conforme a sua necessidade.',
  buttonText: 'OK, ENTENDI!',
  bubbleText: 'Atenção!',
  showButton: true,
};

const IModalConfigCreateCompany = {
  ParagraphText: 'Estamos criando sua empresa.',
  bubbleText: 'Falta pouco!',
};

const IModalConfigCreateCompanyError = {
  ParagraphText:
    'Um erro ocorreu durante a criação de sua empresa! Revise os dados e tente novamente.',
  bubbleText: 'Oops!',
  buttonText: 'Ok',
  showButton: true,
};

const IModalConfigCreateJobError = {
  ParagraphText:
    'Um erro ocorreu durante a criação de sua vaga! Faça o login e tente novamente.',
  bubbleText: 'Oops!',
  buttonText: 'Fazer login',
  showButton: true,
};

const IModalConfigCreateJobErrorInternal = {
  ParagraphText:
    'Um erro ocorreu durante a criação de sua vaga! Revise os dados e tente novamente.',
  bubbleText: 'Oops!',
  buttonText: 'Ok, entendi!',
  showButton: true,
};

const IModalConfigEditJobErrorInternal = {
  ParagraphText:
    'Um erro ocorreu durante a edição da sua vaga! Revise os dados e tente novamente.',
  bubbleText: 'Oops!',
  buttonText: 'Ok, entendi!',
  showButton: true,
};

const IModalConfigCreateJob = {
  ParagraphText: 'Estamos publicando sua vaga',
  bubbleText: 'Última etapa...',
};

const IModalConfigCreateJobInternal = {
  ParagraphText: 'Estamos publicando sua vaga',
  bubbleText: 'Falta pouco...',
};

const IModalConfigEditJobInternal = {
  ParagraphText: 'Estamos salvando as edições da sua vaga',
  bubbleText: 'Falta pouco...',
};

export {
  IModalConfigProfession,
  IModalConfigCreateCompany,
  IModalConfigCreateJob,
  IModalConfigCreateCompanyError,
  IModalConfigCreateJobError,
  IModalConfigCreateJobInternal,
  IModalConfigCreateJobErrorInternal,
  IModalConfigEditJobErrorInternal,
  IModalConfigEditJobInternal,
};
