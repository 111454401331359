export function IconLinkedin() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47762)">
        <path
          d="M15.3378 15.6035H12.6705V11.4265C12.6705 10.4303 12.6527 9.14855 11.2831 9.14855C9.89402 9.14855 9.68113 10.2334 9.68113 11.3546V15.6035H7.0147V7.01345H9.57558V8.187H9.61106C10.1335 7.29464 11.1039 6.76153 12.1373 6.79967C14.841 6.79967 15.3387 8.57818 15.3387 10.8907L15.3378 15.6035ZM4.00498 5.83901C3.14987 5.83901 2.4571 5.14623 2.4571 4.29113C2.4571 3.43602 3.14987 2.74324 4.00498 2.74324C4.86008 2.74324 5.55286 3.43602 5.55286 4.29113C5.55286 5.14623 4.86008 5.83901 4.00498 5.83901ZM5.3382 15.6035H2.66821V7.01345H5.3382V15.6035ZM16.6674 0.266633H1.3279C0.603186 0.25865 0.00887038 0.83966 0 1.56437V16.9669C0.00887038 17.6925 0.603186 18.2735 1.3279 18.2655H16.6674C17.3939 18.2744 17.9909 17.6934 18.0007 16.9669V1.56348C17.99 0.836999 17.393 0.255989 16.6674 0.265746"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47762">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.265625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
