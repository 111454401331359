import { BubbleDialog, Modal, ModalHeader, WalIcon } from './styles';
import walIcon from 'assets/JobOffer/IconPerson.svg';
import React from 'react';
import { CloseButtonModal, OverlayModal } from '../../styles';

export function InfoWorkabilityIndicatorModal({ isOpen, onClose }) {
  return (
    <OverlayModal isOpen={isOpen}>
      <Modal>
        <CloseButtonModal onClick={onClose} />
        <ModalHeader>
          <WalIcon src={walIcon} />
          <BubbleDialog>
            <p>Entenda o seu Indicador de Trabalhabilidade.</p>
          </BubbleDialog>
        </ModalHeader>
        <main>
          <p>
            O Indicador de Trabalhabilidade tem o objetivo de identificar{' '}
            <b>o desempenho da sua instituição de ensino</b>, através dos
            estudantes que estão trabalhando (dentro e fora da área) e
            estudantes que não estão trabalhando.
          </p>
          <p>
            <b>Tese: </b>Quanto maior o indicador de trabalhabilidade, menor a
            taxa de evasão e inadimplência da instituição de ensino.
          </p>
        </main>
        <span>
          <p>
            <b>Observação:</b> A análise geral do indicador é medida usando os
            parâmetros de 0% à 100%.
          </p>
        </span>
      </Modal>
    </OverlayModal>
  );
}
