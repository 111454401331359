import styled from 'styled-components';

export const ContainerInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .parallel-inputs {
    margin-top: 15px;
    width: 100%;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
  }

  .input-content {
    display: flex;
    align-items: center;

    gap: 8px;
  }

  .input-content span {
    color: #606062;
    margin-bottom: 16px;
    font-weight: 400;
  }

  .input-content svg {
    margin-bottom: 18px;
  }
`;

export const Slider = styled.div`
  height: 3px;
  border-radius: 3px;
  width: 100%;
  background-color: #ddd;

  .progress {
    height: 3px;
    border-radius: 3px;
    background-color: #009291;

    position: absolute;
    left: 10%;
    right: 10%;
  }
`;

export const RangeInput = styled.div`
  position: relative;
  width: 100%;

  input {
    position: absolute;
    top: -5px;
    height: 3px;
    width: 100%;

    background: none;
    -webkit-appearance: none;
    pointer-events: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;

    background-color: #fff;
    pointer-events: auto;

    border-radius: 200px;
    box-shadow: 1px 3px 7px 0px #00000040;
  }

  .range-min input {
    background: ${(props) => `linear-gradient(to right, 
    #009291 0%,
    #009291 ${props.value < 25 ? 0 : ((props.value - 25) / (100 - 25)) * 100}%,
    #EEF2F4 ${props.value < 25 ? 0 : ((props.value - 25) / (100 - 25)) * 100}%,
    #EEF2F4 100%);
  `};
  }
`;

export const TextRange = styled.input`
  width: 100%;
  max-width: 240px;
  height: 34px;
  padding: 13px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  font-size: 14px;
  color: #606062 !important;
  margin-bottom: 17px;

  :disabled {
    background-color: #fff;
  }
`;
