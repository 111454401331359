import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  .wrapper {
    width: 100%;
    display: flex;
    padding: 30px 0;
  }
`;
export const BoxImage = styled.div`
  width: 100%;
  max-width: 140px;
  height: 100%;

  display: flex;
  align-items: start;
  justify-content: center;

  img {
    width: 80px;
    border-radius: 200px;
  }
`;

export const ContainerInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BoxInformation = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const RemoveCandidateButton = styled.button`
  width: 100%;
  max-width: 148px;
  height: 34px;

  background-color: #009291;
  border: none;
  border-radius: 4px;

  color: #ffffff;
  font-size: 13px;

  cursor: pointer;
  margin-right: 34px;
`;

export const FlagStatus = styled.div`
  padding: 8px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => (props.status ? '#D9FFE3' : '#FFEDED')};
  border-radius: 4px;

  color: ${(props) => (props.status ? '#068E2C' : '#DA0505')};
  font-size: 11px;
  font-weight: 500;
`;

export const Name = styled.p`
  font-size: 17px;
  font-weight: 700;
  color: #009291;
  margin-top: 9px;
`;

export const AcademicInformation = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #4b4b4d;
  margin-top: -5px;
  margin-bottom: 5px;

  .wrapperFlex {
    display: flex;
  }

  .wrapperFlex p {
    margin: 0;
  }

  .wrapperFlex span {
    margin: 0 4px;
  }
`;

export const ContactInformation = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #4b4b4d;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 16px;

  svg {
    margin-right: 2px;
    margin-bottom: 2px;
  }
`;

export const ContainerContact = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  width: 96%;
  height: 1px;
  margin-left: 19px;
  background-color: #e6e6e6;
`;

export const ButtonFeedback = styled.button`
  width: 100%;
  max-width: 142px;
  height: 26px;

  background-color: #009291;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-top: 8px;

  cursor: pointer;
`;

export const StatusStudent = styled.div`
  width: 100%;
  max-width: 148px;
  height: 34px;

  background-color: ${(props) =>
    props.statusStudent !== 'Estudante removido' && 'Estudante cancelou'
      ? '#D9FFE3'
      : '#FFEDED'};

  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 43px;

  font-size: 13px;
  font-weight: 400;
  color: ${(props) =>
    props.statusStudent !== 'Estudante removido' && 'Estudante cancelou'
      ? '#068E2C'
      : '#DA0505'}; ;
`;

export const ButtonSeeEvidences = styled.button`
  margin-top: 19px;
  width: 203px;
  color: #ffff;
  border: none;
  background-color: #009291;
  border-radius: 4px;
  font-size: 13px;
  height: 30px;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  cursor: pointer;
`;
