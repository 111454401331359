import styled from 'styled-components';

export const ContainerFilters = styled.div`
  display: flex;
  width: 100%;
`;

export const Filter = styled.div`
  background-color: ${({ color, active }) => (active ? '#FFF' : '#e5e5e5')};
  border: 1px solid #e5e5e5;
  width: fit-content;
  font-weight: bold;
  color: ${({ color, active }) => (active ? `${color}` : '#606062')};
  height: 40px;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 5px;
  cursor: pointer;

  ${({ borderLeftRadius }) =>
    borderLeftRadius
      ? 'border-top-left-radius: 8px; border-bottom-left-radius: 8px'
      : 'border-top-right-radius: 8px; border-bottom-right-radius: 8px'};
`;
