import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  WrapperButtons,
  ButtonX,
  ContainerTextArea,
} from './styled';
import React, { Fragment, useMemo, useRef } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

import { IoClose } from 'react-icons/io5';
import NoteInput from 'components/UnformFields/noteInput';
import { Form } from '@unform/web';

export default function FeedbackModal({
  config,
  onConfirm,
  universityColor,
  isOpen,
  onClose,
  required,
  showFeedback = true,
}) {
  const formRef = useRef(null);

  const handleSubmit = (data) => {
    if (required) {
      if (data.feedback.length === 0) {
        return formRef.current.setFieldError('feedback', 'Campo obrigatório');
      }
    }

    onConfirm && onConfirm(data.feedback);
  };

  const memorizedModal = useMemo(
    () => (
      <Wrapper>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            <ButtonX colorBg={config?.style?.colorBgButtonX} onClick={onClose}>
              <IoClose size={25} color="#4B4B4D" />
            </ButtonX>
            <WrapperTitle alignIcon={config?.style?.alignIcon}>
              <IconPersonStyled
                universityColor={universityColor}
                sizeIconPerson={config?.style?.sizeIconPerson}
                src={config.icon || IconPerson}
              />
              <BubbleDialog universityColor={universityColor}>
                <H4Styled>{config.bubbleText}</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled
              textAlign={config?.style?.textAlign}
              fontWeight={config?.style?.fontWeight}
              marginTop={config?.style?.marginTop}
              fontSize={config?.style?.fontSize}
              lineHeight={config?.style?.lineHeight}
              gapParagraph={config?.style?.gapParagraph}
              maxWidth={config?.style?.maxWidth}
            >
              <p>{ReactHtmlParser(config.paragraphText)}</p>
            </ParagraphStyled>

            <Form ref={formRef} onSubmit={handleSubmit}>
              <ContainerTextArea>
                <NoteInput
                  style={{ height: '100px' }}
                  resize="none"
                  placeholder="Digite aqui"
                  name="feedback"
                />
              </ContainerTextArea>

              <WrapperButtons marginButtons={config?.style?.marginButtons}>
                <Button
                  type="submit"
                  universityColor={universityColor}
                  width={config.buttonConfirmWidth}
                  height={config.buttonConfirmHeight}
                  disabled={config.loading}
                >
                  {config.loading && <FilterLoading />}
                  {config.buttonConfirmText}
                </Button>
              </WrapperButtons>
            </Form>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [isOpen, config, required, formRef.current]
  );
  return <Fragment>{isOpen && memorizedModal}</Fragment>;
}

FeedbackModal.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    iconWidth: 95,
    iconHeight: 95,
    titleWidth: 100,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonText: 'Botão',
    loading: false,
    icon: IconPerson,
  },
};
