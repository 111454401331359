import styled from 'styled-components';
import ArrowRight from 'assets/Oportutinites/Setadireita.svg';
import ArrowLeft from 'assets/Oportutinites/Setaesquerda.svg';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  overflow: auto;
  padding: 80px 20px;
`;

export const ModalContent = styled.div`
  background: #fff;
  padding: 80px;
  border-radius: 8px;
  width: 50%;
  max-width: 90%;
  height: auto;
  margin: auto;
`;

export const NextButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${ArrowRight});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;

export const PrevsButton = styled.button`
  background-color: #009291;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-image: url(${ArrowLeft});
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
`;

export const H5 = styled.h5`
  font-weight: 600;
  color: #606062;
  margin: 0;
`;

export const ContainerBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 40px 0px;

  > div > p {
    max-width: 400px;
  }
`;

export const H2 = styled.div`
  font-weight: 600;
  color: #009291;
  font-size: 22px;
  margin-top: 30px;
`;

export const WrapperBottomActions = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  margin-top: 40px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  background: none;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const WrapperSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const H3 = styled.h3`
  margin-bottom: 2px;
  font-size: 16px;
  color: #6d6d6d;
  font-weight: 600;
`;

export const P = styled.p`
  font-size: 14px;
`;

export const H3Header = styled.h3`
  font-size: 21px;
  font-weight: 600;
  color: #009291;
`;
