import {
  Content,
  TopBar,
  Box,
  Wrapper,
  CloseButton,
} from 'components/ListCandidates/Styles/ModalComponents';
import { CustomCancellationForm } from 'components/ListCandidates/Styles/ScheduleInterviewComponent';
import { IconArrowLeftFlag } from 'assets/JobOffer/IconArrowLeftFlag';
import { getDataCancellationModal } from 'components/ListCandidates/Utils/modalInterviewFunctions';
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import ReactHtmlParser from 'react-html-parser';

export function CancellationDetails({
  openModal,
  controlModal,
  jobId,
  candidateId,
  interviewId,
  company,
}) {
  const [interviewerMessage, setInterviewerMessage] = useState('');
  const [intervieweeMessage, setIntervieweeMessage] = useState('');
  const [canceledBy, setCanceledBy] = useState(null);
  let environmentType = company ? 'company' : 'university';

  const handleToViewData = async () => {
    const response = await getDataCancellationModal(
      environmentType,
      jobId,
      candidateId,
      interviewId
    );

    if (response.interviewer_message !== '') {
      setInterviewerMessage(response.interviewer_message);
    }

    if (response.interviewee_message !== '') {
      setIntervieweeMessage(response.interviewee_message);
    }

    if (response.canceled_by) {
      setCanceledBy(response.canceled_by);
    }
  };

  useEffect(() => {
    handleToViewData();
  }, []);

  return (
    <Wrapper visible={openModal}>
      <Box>
        <Content>
          <TopBar>
            <p className="title">Detalhes do cancelamento</p>
            <CloseButton onClick={() => controlModal(false)}>
              <IoClose size={18} />
            </CloseButton>
          </TopBar>
          <CustomCancellationForm>
            <span> Motivo do cancelamento:</span>
            <div className="view-message">
              {ReactHtmlParser(interviewerMessage || intervieweeMessage)}
            </div>
            {canceledBy && (
              <div className="author-flag">
                <IconArrowLeftFlag color="#DA0505" />
                {canceledBy}
              </div>
            )}
          </CustomCancellationForm>
        </Content>
      </Box>
    </Wrapper>
  );
}
