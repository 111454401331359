import styled from 'styled-components';

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

export const ContainerSelectLateralSide = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 30px;

  > div {
    width: 100%;
  }
`;

export const ContainerInputsLateralSide = styled.div`
  display: flex;
  gap: 30px;
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
`;
