import React, { useContext } from 'react';
import { FilterCheckboxLi } from '../styles';
import { FilterType } from './Filter';
import { useFilterContext } from '../contexts/FilterContext';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterSingleCheckbox({ label, name }) {
  const { filterState, updateFilterState } = useFilterContext();
  const { universityColor } = useContext(BaseLayoutContext);

  const isChecked = filterState[name] === true;
  return (
    <FilterCheckboxLi universityColor={universityColor}>
      <input
        type="checkbox"
        id={name}
        value={filterState[name]}
        checked={isChecked}
        onChange={() =>
          updateFilterState(
            isChecked ? null : true,
            name,
            FilterType.SINGLE_CHECKBOX
          )
        }
      />
      <label htmlFor={name}>{label}</label>
    </FilterCheckboxLi>
  );
}
