export const scrollRigthEvents = () => {
  document.getElementById('event-container').scrollLeft += 318;
};

export const scrollLeftEvents = () => {
  document.getElementById('event-container').scrollLeft += -318;
};

export const scrollRigthCourses = () => {
  document.getElementById('courses-container').scrollLeft += 318;
};

export const scrollLeftCourses = () => {
  document.getElementById('courses-container').scrollLeft += -318;
};

export const scrollRigthPrograms = () => {
  document.getElementById('programs-container').scrollLeft += 318;
};

export const scrollLeftPrograms = () => {
  document.getElementById('programs-container').scrollLeft += -318;
};

export const scrollRigthAnnouncements = () => {
  document.getElementById('announcements-container').scrollLeft += 318;
};

export const scrollLeftAnnouncements = () => {
  document.getElementById('announcements-container').scrollLeft += -318;
};

export const scrollRigthTrails = () => {
  document.getElementById('trails-container').scrollLeft += 318;
};

export const scrollLeftTrails = () => {
  document.getElementById('trails-container').scrollLeft += -318;
};

export const scrollRigthEbooks = () => {
  document.getElementById('ebooks-container').scrollLeft += 318;
};

export const scrollLefEbooks = () => {
  document.getElementById('ebooks-container').scrollLeft += -318;
};

export const scrollRigthArticle = () => {
  document.getElementById('article-container').scrollLeft += 318;
};

export const scrollLeftArticle = () => {
  document.getElementById('article-container').scrollLeft += -318;
};

export const scrollRigthChallenges = () => {
  document.getElementById('challenge-container').scrollLeft += 318;
};

export const scrollLeftChallenges = () => {
  document.getElementById('challenge-container').scrollLeft += -318;
};

export const scrollRigthSeries = () => {
  document.getElementById('serie-container').scrollLeft += 318;
};

export const scrollLeftSeries = () => {
  document.getElementById('serie-container').scrollLeft += -318;
};
