export const finalizationVacancyAdapter = ({
  candidates,
  feedback,
  withoutCandidates,
  noContract,
  statusSelect,
}) => ({
  candidates,
  feedback,
  has_contracting: noContract
    ? false
    : withoutCandidates || (candidates ?? []).length > 0
    ? true
    : false,
  statusSelect: statusSelect || [],
});
