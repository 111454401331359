import { authenticatedAPI } from 'services/api';

/**
 *
 * @param {String} jobId
 * @param {Object} params
 * @returns {Promise<Object|Array|any>}
 */
const getStudentsList = async (jobId, params, offset=0) => {
  const queryParams = new URLSearchParams();
  Object.entries(params).map(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        value.map((v) => queryParams.append(key, v));
      } else {
        queryParams.append(
          key == 'name' || key == 'external_id' ? 'name_or_external_id' : key,
          value
        );
      }
    }
  });
  return (
    await authenticatedAPI.get(
      `api/internal/university/indications/students/list/${jobId}?limit=20&offset=${offset}&${queryParams.toString()}`
    )
  ).data;
};

/**
 *
 * @param {String} jobId
 * @returns {Promise<Object|Array|any>}
 */
const getFilters = async (jobId) => {
  try {
    const response = await authenticatedAPI.get(
      `api/internal/university/indications/students/list/filter-options/${jobId}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Create indication function
 * @typedef IIndication
 * @prop {String} job_offer //job offer id
 * @prop {String} student //student id
 * @prop {String} university //university id
 *
 * @param {IIndication} indication
 * @returns {Promise<void>}
 */
const createIndication = async (indication) => {
  return await authenticatedAPI.post(
    `api/internal/university/indications/list-create`,
    indication
  );
};

/**
 * Get my indications function
 * @returns {Promise<Array<unknown>>}
 */
const getIndications = async (filter="") => {
  return (
    await authenticatedAPI.get(
      `api/internal/university/indications/list-create${filter == "" ? "" :`?name=${filter}`}`
    )
  ).data;
};

/**
 * Count my indications function
 * @returns {Promise<unknown>}
 */
const countIndications = async () => {
  return (
    await authenticatedAPI.get(`api/internal/university/indications/list/count`)
  ).data;
};

const getStudentIndications = async (studentId, params) => {
  return (
    await authenticatedAPI.get(
      `api/internal/university/indications/student/indicated-jobs/list/${studentId}?${params}`
    )
  ).data;
};

const getNextResults = async(url) => {
  return (
    await authenticatedAPI.get(url)
  ).data;
}

/**
 * @param {string} id
 * @returns {Promise<unknown>}
 */
const getCompanyDetails = async (id) => {
  return await authenticatedAPI.get(
    `api/internal/university/indications/company/profile/${id}`
  );
};


export {
  getStudentsList,
  getFilters,
  createIndication,
  getIndications,
  getStudentIndications,
  getNextResults,
  getCompanyDetails
};
