import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
`;

export const BoxLink = styled.div`
  width: 100%;
  height: 33px;

  color: #4b4b4d;

  display: flex;
  align-items: center;
  background-color: #eef2f4;

  border-radius: 3px;
  padding: 10px;

  strong {
    margin-right: 5px;
    font-size: 15px;
  }

  span {
    font-weight: 400;
    font-size: 15px;
  }

  margin-bottom: 20px;
`;

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const BoxLinkSkeleton = styled(BoxLink)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #dde9ef;
  background-image: linear-gradient(
    120deg,
    #dde9ef 30%,
    #fff 38%,
    #fff 40%,
    #dde9ef 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  border-radius: 5px;
  width: 100%;
  height: 33px;
`;

export const Info = styled.p`
  color: #606062;
  font-size: 14px;
  margin-bottom: 20px;
`;

export const Title = styled.p`
  font-size: 19px;
  color: #606062;
  margin-bottom: 9px;
  font-weight: 700;
`;

export const LetterCounter = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  display: flex;
  justify-content: flex-end;
  color: #606062;
  margin-top: 5px;
`;

export const WrapperImage = styled.div`
  display: flex;
  margin-top: 38px;
  width: 100%;
  justify-content: space-between;
`;

export const TextImage = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  max-width: 375px;
`;

export const ContainerImage = styled.div`
  width: 50%;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  img {
    width: 211px;
    height: 139px;
    border-radius: 3px;
  }
`;

export const InputImage = styled.div`
  width: 211px;
  height: 139px;
  background-color: #f9f9f9;
  border: 1px dashed #c4c4c4;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputImageSkeleton = styled(InputImage)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #dde9ef;
  background-image: linear-gradient(
    120deg,
    #dde9ef 30%,
    #fff 38%,
    #fff 40%,
    #dde9ef 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  border-radius: 5px;
  width: 211px;
  height: 139px;
`;

export const AddImage = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #009291;
  font-size: 15px;

  padding: 3px;
  gap: 3px;

  width: fit-content;

  background-color: transparent;
  border: none;
  font-weight: 700;

  margin-top: 14px;

  cursor: pointer;
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 26px;

  .save-button {
    padding: 10px 15px;
    background-color: #009291;
    border: none;
    border-radius: 3px;

    font-weight: 700;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }

  .preview-button {
    background-color: transparent;
    border: none;
    font-weight: 700;
    color: #009291;
    font-size: 16px;

    gap: 5px;
    text-decoration: underline;

    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
  }
`;
