import styled, { keyframes } from 'styled-components';
import { IconButton } from '@material-ui/core';

export const ContainerInformations = styled.div`
  width: 100%;
  max-width: 420px;
  height: auto;
  margin-bottom: 30px;
  padding: 2px 15px 30px;
  border-radius: 10px;
  background-color: #fff;
  transition: 0.5s;

  @media (max-width: 850px) {
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: 0.5s;
    max-width: 42vw;
  }

  @media (min-width: 1800px) {
    max-width: 550px;
    transition: 0.5s;
  }
`;

export const IconImg = styled.div`
  width: 18px;
  height: 18px;
`;

export const Content = styled.div`
  margin-bottom: 2px;
`;

export const HeaderTitle = styled.h3`
  color: #3c4858;
  font-size: 20px;
  font-weight: 500;
  margin: ${(props) => (props.hasMarginRight ? '0 8px 0 0' : '')};

  @media (max-width: 850px) {
    white-space: unset;
  }

  @media (max-width: 350px) {
    font-size: 16px;
  }
`;

export const ContainerTitleAndIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px 0;

  @media (max-width: 850px) {
    margin: 14px 4px 14px 12px;
  }
`;

export const CardBody = styled.div`
  @media (max-width: 850px) {
    display: none;
  }
`;

export const CardBodyMobile = styled.div`
  display: none;
  @media (max-width: 850px) {
    display: flex;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
  }
`;

export const HeaderGenericModal = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonSeeMore = styled.button`
  border: none;
  background-color: transparent;

  width: 100%;
  display: flex;
  align-items: center;

  color: #606062;
  font-size: 14px;
  font-weight: 600;

  cursor: pointer;

  margin: 14px 4px 10px 10px;
  gap: 5px;
`;

export const CardBodyTitle = styled.h4`
  color: #606062;
  font-weight: 700;
  white-space: nowrap;
  font-size: 1em;
  line-height: 15px;
  margin: 0 0 0 6px;
  width: 100%;
`;

export const CardBodySubTitle = styled.h5`
  color: #606062;
  display: flex;
  font-weight: 400;
  font-size: 1em;
  flex-direction: row;
  gap: 2%;
  margin-top: 2px;
  text-transform: ${(props) => (props.capitalize ? 'capitalize' : 'none')};
  a {
    color: #5578ac;
    &:visited &:hover &:active {
      color: #5578ac;
    }
  }
  ${(props) => (props.capitalize ? 'text-transform: capitalize' : '')}
  ${(props) => (props.hasMarginBottom ? 'margin-bottom: 30px' : '')}

  @media (max-width: 350px) {
    font-size: 12px;
  }
`;

export const CardBodySubTitleLink = styled.a`
  display: flex;
  font-weight: 400;
  font-size: 1em;
  flex-direction: row;
  gap: 2%;
  margin-top: 2px;
  color: #5578ac;
  overflow: hidden;
  &:visited &:hover &:active {
    color: #5578ac;
  }
`;

export const ExpandButton = styled(IconButton)`
  width: 100%;
  @media (min-width: 600px) {
    display: none !important;
  }
  span {
    transition: 0.1s linear;
    ${(props) =>
      props.active
        ? `
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);`
        : ``};
  }
`;

export const EmptyText = styled.p`
  text-align: ${(props) => (props.align ? 'center' : 'start')};
  color: #999999;
  margin-top: 0;
  font-size: 16px;

  @media (max-width: 718px) {
    font-size: 14px;
    margin-left: 12px;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerAlert = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  @media (max-width: 1350px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const ContainerAcademic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const slideIn = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ModalMobile = styled.div`
  height: auto;
  width: 100%;

  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 30px 40px;

  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;

  animation: ${(props) => (props.isOpen ? slideIn : slideOut)} 0.3s ease-in-out;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonCloseModal = styled.button`
  border-radius: 50%;
  border: none;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4c4c4;

  color: #fff;
  font-size: 1.3em;
  font-weight: 700;

  cursor: pointer;
`;

export const BackgroundModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000052;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  @media (min-width: 850px) {
    display: none;
  }
`;

export const ContainerButtonMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
`;

export const ButtonPersonalInfoMobile = styled.button`
  color: #fff;
  width: 310px;
  height: 38px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  @media (min-width: 850px) {
    display: none;
  }
`;

export const ContainerPaginationCourses = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 850px) {
    display: none;
  }
`;

export const PaginationButton = styled.button`
  width: 14px;
  height: 14px;

  border: none;
  border-radius: 50%;

  cursor: pointer;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const ButtonPersonalInfo = styled.button`
  margin-left: 15px;

  color: #fff;
  font-weight: 700;
  font-size: 14px;

  width: auto;
  height: auto;

  padding: 10px;
  gap: 5px;

  border: none;
  border-radius: 5px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  @media (max-width: 1350px) {
    display: none;
    margin-left: 0;
  }
`;

export const MobileButtonEdit = styled(ButtonPersonalInfo)`
  display: none;

  @media (max-width: 1350px) {
    display: flex;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-top: 4px;
`;

export const ContainerEmptyText = styled.div``;

export const PublicProfileContentCard = styled.div`
  span {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }

  button {
    margin-top: 24px;
    width: 100%;
    background: ${(props) => props.universityColor ?? '#005096'};
    color: #fff;
    padding: 12px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    border-radius: 2px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    align-items: center;
    display: flex;
    justify-content: center;

    & > svg {
      margin-left: 12px;
    }
    &:hover {
      filter: brightness(1.2);
    }

    &:disabled {
      background: #949494;
    }
  }
`;
