import React, { Fragment } from 'react';
import {
  Container,
  Wrapper,
  ButtonEdit,
  Title,
  Content,
  ContainerButton,
  ListCourses,
  Subtitle,
  SimpleText,
} from './style';

import iconEdit from 'assets/JobOffer/edit.svg';
import {
  courseProgess,
  currency,
  jobBenefits,
  jobType,
  modalityType,
} from '../useCases/SelectOptions';

import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { disability } from '../../../../../JobOfferRebuild/Components/ExternalJobOffer/useCases/SelectOptions';

export default function PreviewJob({
  data,
  stepThreeData,
  externalJobOffer,
  handleSubmit,
  closeModal,
  selectedUniversities,
}) {
  const dataStepThree = stepThreeData?.current?.getData();

  const handleSubmitInsidePreview = () => {
    closeModal();

    setTimeout(() => {
      stepThreeData.current.submitForm();
    }, 1000);
  };

  const mapItems = (arrayOfItems) => {
    if (arrayOfItems.length) {
      return arrayOfItems.map((item) => (
        <ul>
          <li style={{ textTransform: 'capitalize' }}> {item.label} </li>
        </ul>
      ));
    }

    return (
      <ul>
        <li> Não informado </li>
      </ul>
    );
  };

  const returnLabelCourseProgress = () => {
    const foundCourse = courseProgess.find(
      (item) => item.value === data.course_progress
    );
    return foundCourse ? foundCourse.previewText : 'Não informado';
  };

  const returnLabelCurrency = () => {
    const foundCurrency = currency.find(
      (item) => item.value === data.salary_coin
    );

    return foundCurrency ? foundCurrency.previewText : 'Não informado';
  };

  const returnLabelModalityType = () => {
    const foundModality = modalityType.find(
      (item) => item.value === data.modality_type
    );

    return foundModality ? foundModality.label : 'Não informado';
  };

  const returnLabels = (arrayToFind, itemToFind) => {
    if (itemToFind.length) {
      if (Array.isArray(itemToFind) && itemToFind.length > 1) {
        const found = arrayToFind.filter((item) => {
          return itemToFind.find((itemTwo) => item.value === itemTwo);
        });
        return mapItems(found);
      }

      const foundLabel = arrayToFind.find(
        (item) => item.value === itemToFind[0]
      );

      return mapItems([foundLabel]);
    }

    return mapItems(itemToFind);
  };

  const formatDate = () => {
    const startOffer = moment(dataStepThree.start_offer_at).format(
      'DD/MM/YYYY'
    );
    const endOffer = moment(dataStepThree.end_offer_at).format('DD/MM/YYYY');

    return `${startOffer} a ${endOffer}`;
  };

  return (
    <Container>
      <Wrapper>
        <div className="header">
          <h1> Pré-Visualização da Vaga</h1>
        </div>

        <Title style={{ marginTop: '30px' }}>
          <strong> Dados Básicos </strong>
        </Title>

        <Content>
          <Subtitle> Vaga </Subtitle>
          <SimpleText>{data.name}</SimpleText>
          <Subtitle spaceBottom="18px"> Descrição da Vaga </Subtitle>
          <div className="description-job">
            {data.description
              ? ReactHtmlParser(data.description.replace(/style="[^"]*"/g, ''))
              : 'Não informado'}
          </div>
          <Subtitle spaceBottom="25px"> Cursos relacionados </Subtitle>
          <ListCourses>{mapItems(data.courses)}</ListCourses>
          <Subtitle> Andamento mínimo do curso </Subtitle>
          <SimpleText>{returnLabelCourseProgress()}</SimpleText>
          <Subtitle> Média salarial </Subtitle>
          <SimpleText>
            {`${data.salary_coin && data.salary ? returnLabelCurrency() : ''}` +
              ` ${data.salary ? data.salary : ' Não informado'}`}
          </SimpleText>

          {data.required_attributes && (
            <Fragment>
              <Subtitle> Pré-requisitos obrigatórios </Subtitle>
              <SimpleText>{data.required_attributes}</SimpleText>
            </Fragment>
          )}
        </Content>

        <Title>
          <strong> Competências Desejadas </strong>
        </Title>
        <Content>
          <Subtitle spaceBottom="25px"> Hard Skills </Subtitle>
          <ListCourses>
            {mapItems(data.required_additional_courses_areas)}
          </ListCourses>
          <Subtitle spaceBottom="25px"> Soft Skills </Subtitle>
          <ListCourses>
            {mapItems(data.required_behavioral_attributes)}
          </ListCourses>
        </Content>

        <Title>
          <strong> Dados Específicos da Vaga </strong>
        </Title>
        <Content>
          <Subtitle spaceBottom="25px"> Tipo de Vaga </Subtitle>
          <ListCourses>
            {returnLabels(jobType, data.application_type)}
          </ListCourses>

          <Subtitle spaceBottom="25px"> Divulgando para </Subtitle>
          <ListCourses>
            {selectedUniversities?.length > 0 ? (
              mapItems(selectedUniversities)
            ) : (
              <ul>
                <li>Todas as instituições do grupo</li>
              </ul>
            )}
          </ListCourses>

          <Subtitle> Quantidade de vagas </Subtitle>
          <SimpleText>{`${data.quantity} ${
            Number(data.quantity) <= 1 ? 'vaga' : 'vagas'
          }`}</SimpleText>

          <Subtitle spaceBottom="25px"> Deficiências </Subtitle>
          <ListCourses>
            {returnLabels(
              disability,
              dataStepThree.disability ? dataStepThree.disability : []
            )}
          </ListCourses>

          <Subtitle> Benefícios </Subtitle>
          <ListCourses>
            {returnLabels(jobBenefits, data.perks_offered)}
          </ListCourses>
          <Subtitle> Modalidade de Trabalho </Subtitle>
          <SimpleText>{returnLabelModalityType()}</SimpleText>
        </Content>

        <Title>
          <strong> Dados Específicos da Vaga </strong>
        </Title>
        <Content>
          <Subtitle> Período de Divulgação: </Subtitle>
          <SimpleText>
            {dataStepThree.start_offer_at && dataStepThree.end_offer_at
              ? formatDate()
              : 'Não informado'}
          </SimpleText>
          <Subtitle> E-mail para Notificação de Novas Candidaturas </Subtitle>
          <SimpleText>
            {dataStepThree.contact_email
              ? dataStepThree.contact_email
              : 'Não informado'}
          </SimpleText>
          {externalJobOffer && (
            <Fragment>
              <Subtitle>
                {' '}
                Link ou e-mail para direcionamento dos candidatos{' '}
              </Subtitle>
              <SimpleText>wwww.workalove.com</SimpleText>
            </Fragment>
          )}
        </Content>

        {/* Container Button */}
        <ContainerButton onClick={handleSubmitInsidePreview}>
          <button> Publicar </button>
        </ContainerButton>
      </Wrapper>
    </Container>
  );
}
