import { Footer, JobList } from '../style';
import Card from '../components/Card';

export default function FinishedJobs({
  vacanciesData,
  seeMorePaginationCallback,
  onOpenJobDetails,
  closeFunction,
}) {
  const numberOfPages = vacanciesData?.finished_jobs?.num_pages;
  const currentPage = vacanciesData?.finished_jobs?.current_page;

  const createCards = () => {
    return vacanciesData?.finished_jobs?.results?.map((job) => {
      return (
        <Card
          key={job.id}
          vacancy={job}
          onOpenJobDetails={onOpenJobDetails}
          closeFunction={closeFunction}
        />
      );
    });
  };

  const params = `finished_jobs_page=${currentPage + 1}`;

  return (
    <JobList>
      {createCards()}
      {currentPage < numberOfPages && (
        <Footer>
          <button onClick={() => seeMorePaginationCallback(params)}>
            Ver mais
          </button>
        </Footer>
      )}
    </JobList>
  );
}
