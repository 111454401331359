import React from 'react';
import { Ellipsis } from './styled';

export default function GenericLoading({ color }) {
  return (
    <Ellipsis color={color}>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Ellipsis>
  );
}
