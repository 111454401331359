import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

import 'assets/scss/material-dashboard-pro-react.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Authentication from './views/Authentication/Authentication';

Sentry.init({
  dsn:
    'https://c2fb68013010481aa0e0a42b89eae36c@o562998.ingest.sentry.io/5731869',
  integrations: [new Integrations.BrowserTracing()],
  allowUrls: [
    /https?:\/\/((((?!workability-hmg|workability-stg|workability-dev\b)[\w\-]))+\.)?worka\.love/,
  ],
});

ReactDOM.render(
  <HashRouter>
    <Authentication />
  </HashRouter>,
  document.getElementById('root')
);
