import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
  ProfileImage,
  Content,
  Infos,
  ButtonContainer,
  CurriculumModal,
  Tag,
} from './style.js';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import placeholder from 'assets/img/placeholder.jpg';
import Button from 'components/CustomButtons/Button.jsx';
import Collapse from '@material-ui/core/Collapse';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import IndicationJobsModal from '../Modals/IndicationJobsModal/index.jsx';
import ConfirmIndicationModal from './components/ConfirmIndicationModal/index.jsx';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import { getStudentCurriculumData } from 'services/university/curriculum';
import BaseLayoutContext from 'contexts/base-layout.jsx';
import { getApplicationCoverLetter } from 'services/university/coverLetter';
import { IndicationStudentContext } from '../../context/indicationStudentContext.js';

/**
 * @typedef IProps
 * @prop {Boolean} hasIndication
 * @prop {IStudent | null} student
 *
 * @typedef IStudent
 * @prop {String} id
 * @prop {String} external_id
 * @prop {String} name
 * @prop {String} email
 * @prop {String} mobile_phone
 * @prop {String} home_phone
 * @prop {String} professional_status
 * @prop {Boolean} has_curriculum
 * @prop {Boolean} has_cover_letter
 * @prop {Boolean} is_active
 * @prop {Boolean} has_indicated
 * @prop {Boolean} accept_be_indicated
 * @prop {String} avatar
 * @prop {Array<ICourse>} course
 *
 * @typedef ICourse
 * @prop {string} course__name
 * @prop {string} semester
 */

/**
 *
 * Student item component
 * @param {IProps} props
 * @returns
 */
export default function StudentItem({ hasIndication = false, student = null }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openIndicationModal, setOpenIndicationModal] = useState(false);
  const [openConfirmIndicationMotal, setOpenConfirmIndicationModal] =
    useState(false);
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);
  const [curriculumData, setCurriculumData] = useState(null);
  const {
    openModalWithContent,
    handleIsLoadingState,
    openSnackbar,
    updateHasCoverLetter,
  } = useContext(BaseLayoutContext);
  const [sucessfullLoadAvatar, setSucessfullLoadAvatar] = useState(false);
  const [studentStatus, setStudentStatus] = useState('');

  const { job } = useContext(IndicationStudentContext) ?? { job: null };

  const closeModal = () => {
    setOpenIndicationModal(false);
  };

  const closeConfirmIndicationModal = () => {
    setOpenConfirmIndicationModal(false);
  };

  const openCurriculum = useCallback(async () => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(student?.id);
    if (!response.message) {
      setCurriculumData(response);
      setGenerateCurriculumModal(true);
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  useEffect(() => {
    if (student && student.accept_be_indicated === false) {
      setStudentStatus('cannotBeIndicated');
    } else if (student && student.is_active === false) {
      setStudentStatus('doenstAccessPlatform');
    } else if (student && student.has_curriculum === false) {
      setStudentStatus('dontHaveCurriculum');
    } else {
      setStudentStatus('default');
    }
  }, [student]);

  const openLetter = useCallback(async () => {
    handleIsLoadingState(true);
    const response = await getStudentCurriculumData(student?.id);
    if (!response.message) {
      openModalWithContent(
        <CoverLetterContainer
          notStudent
          studentId={student?.id}
          handleLoadingState={handleIsLoadingState}
          getApplicationCoverLetter={getApplicationCoverLetter}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
        />
      );
    } else {
      openSnackbar('Error ao obter os dados! Tente novamente mais tarde', true);
    }
  }, []);

  return (
    <>
      <Content>
        {!sucessfullLoadAvatar && <ProfileImage src={placeholder} />}
        <ProfileImage
          src={student?.avatar}
          onLoad={() => setSucessfullLoadAvatar(true)}
          style={!sucessfullLoadAvatar ? { display: 'none' } : {}}
        ></ProfileImage>
        <Infos>
          {student?.is_active == false && (
            <Tag>Estudante nunca acessou a plataforma</Tag>
          )}
          <div className="withCollapse">
            <h3 className="name">{student?.name}</h3>
            {hasIndication && (
              <button onClick={() => setOpenCollapse(!openCollapse)}>
                {openCollapse ? <ExpandLess /> : <ExpandMore />}
              </button>
            )}
          </div>

          {hasIndication && (
            <div className={`${openCollapse && 'mb-16'} rowIndication`}>
              <p>
                <strong>Indicação feita para a vaga:&nbsp;</strong>
                {student.last_indication.name}
              </p>
              <Button
                onClick={() => {
                  setOpenIndicationModal(true);
                }}
              >
                {student.indications_count > 1
                  ? `+${
                      student.indications_count - 1 > 1
                        ? student.indications_count - 1 + ' vagas'
                        : '1 vaga'
                    }`
                  : 'ver detalhes da vaga'}
              </Button>
            </div>
          )}
          {!hasIndication && (
            <>
              <p>Código: {student?.external_id}</p>
              <p>{student?.professional_status}</p>
            </>
          )}
          {hasIndication ? (
            <Collapse in={openCollapse}>
              {student?.course?.map(({ course__name, semester }) => (
                <p>
                  {course__name} - {semester}º semestre
                </p>
              ))}

              <div className="row">
                <div className="withIcon removeRl">
                  <PhoneAndroidIcon />
                  <p>
                    {student?.mobile_phone && student.mobile_phone !== ''
                      ? student.mobile_phone
                      : 'Não informado'}
                  </p>
                </div>
                <div className="withIcon">
                  <AlternateEmailIcon />
                  <p>{student?.email ?? 'Não informado'}</p>
                </div>
              </div>
            </Collapse>
          ) : (
            <>
              {student?.course?.map(({ course__name, semester }) => (
                <p>
                  {course__name} - {semester}º semestre
                </p>
              ))}
              <div className="row">
                <div className="withIcon removeRl">
                  <PhoneAndroidIcon />
                  <p>
                    {student?.mobile_phone && student.mobile_phone !== ''
                      ? student.mobile_phone
                      : 'Não informado'}
                  </p>
                </div>
                <div className="withIcon">
                  <AlternateEmailIcon />
                  <p>{student?.email ?? 'Não informado'}</p>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <a
              className={`hasItem removeRl ${
                !student?.has_curriculum && 'notHasItem'
              }`}
              onClick={student?.has_curriculum && openCurriculum}
            >
              <DescriptionIcon />
              <span>
                {student?.has_curriculum
                  ? 'Visualizar currículo'
                  : 'Não possui currículo'}
              </span>
            </a>
            <a
              className={`hasItem ${
                !student?.has_cover_letter && 'notHasItem'
              }`}
              onClick={student?.has_cover_letter && openLetter}
            >
              <DescriptionIcon />
              <span>
                {student?.has_cover_letter
                  ? 'Visualizar carta de apresentação'
                  : 'Não possui carta de apresentação'}
              </span>
            </a>
          </div>
        </Infos>
        {!hasIndication && (
          <ButtonContainer>
            {student?.has_indicated ? (
              <Button disabled className="disabledButton">
                Estudante já indicado
              </Button>
            ) : (
              <Button onClick={() => setOpenConfirmIndicationModal(true)}>
                Indicar
              </Button>
            )}
          </ButtonContainer>
        )}
      </Content>
      {openIndicationModal && (
        <IndicationJobsModal
          isOpen={openIndicationModal}
          closeModalFunc={closeModal}
          student={student}
        />
      )}
      {openConfirmIndicationMotal && (
        <ConfirmIndicationModal
          student={student}
          job={job}
          isOpen={openConfirmIndicationMotal}
          closeModalFunc={closeConfirmIndicationModal}
          studentStatus={studentStatus}
        />
      )}
      {generateCurriculumModal && (
        <CurriculumModal
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={curriculumData}
          setCurriculumData={setCurriculumData}
          hasIndication={hasIndication}
          showActions={false}
          showInterviewButton={false}
        />
      )}
    </>
  );
}
