import { useRef, useEffect, useState } from 'react';

import { useField } from '@unform/core';
import { TextArea, Box } from './style';

export default function UnformTextarea({
  name,
  label,
  rows = 5,
  cols,
  maxLength,
  useCount,
  onChange,
  ...rest
}) {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue = '', registerField, error } = useField(name);

  const [count, setCount] = useState(0);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const lengthRemaing = maxLength - count;

  const renderCount = useCount && (
    <Box countText={lengthRemaing}>
      {lengthRemaing.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')}{' '}
      caracteres restantes
    </Box>
  );

  const handleChange = (event, useDefault) => {
    const value = useDefault ? event : event.target.value;

    if (onChange) {
      onChange(event);
    }
    if (useCount) {
      setCount(value.length);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue, true);
    }
  }, []);

  return (
    <div>
      <TextArea
        id={fieldName}
        ref={textareaRef}
        defaultValue={defaultValue}
        onChange={handleChange}
        maxLength={maxLength}
        {...rest}
      />
      {renderCount}
      {error && <span>{error}</span>}
    </div>
  );
}
