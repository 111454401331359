import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Card = styled.div`
  width: 260px;
  height: 365px;
  margin: 10px;
  margin: 0 10px;
  border-radius: 8px;
  background-color: #f0f0f0;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
`;

export const CardImgSkeleton = styled.div`
  position: absolute;
  top: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 260px;
  background-color: #e5e5e5;
  height: 181px;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardTitle = styled.div`
  position: absolute;
  width: 100px;
  height: 20px;
  top: 15em;
  margin-left: 10px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  border-radius: 4px;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const CardSubTitles = styled.div`
  position: absolute;
  width: 150px;
  height: 20px;
  top: ${(props) => `${props.top}em`};
  border-radius: 4px;
  margin-left: 10px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;
