import React, { useMemo } from 'react';

import Pagination from 'components/CustomPagination';
import JobCards from '../../../../components/Cards';
import SkeletonJobsCard from '../../../../components/JobsSkeleton';

import { Container, Content, Title } from './styled';

import { isMasterLet } from '../../index';
import GenericMessage from 'components/GenericMessage';

export default function PublishedByPartners({
  currentPage,
  jobs,
  setCurrentPage,
  onPageChange,
  setLimit,
  limit,
  jobsCount,
  role,
  universityFilters,
}) {
  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const skeletonCardsQuantity = useMemo(() => {
    return randomIntFromInterval(1, 4);
  }, []);

  return jobs === null ? (
    <Container>
      <Content>
        <SkeletonJobsCard quantity={skeletonCardsQuantity} />
      </Content>
    </Container>
  ) : jobs?.length > 0 ? (
    <Container>
      <Content>
        {jobs?.map((jobs) => (
          <JobCards
            jobName={jobs.name}
            openedSince={jobs.opened_since}
            responsibleName={jobs.responsible_name}
            numberOfCandidates={jobs.number_of_candidates}
            status={jobs.status}
            jobs={jobs}
            jobID={jobs.id}
            JobPartners={isMasterLet ? false : true}
            role={role}
            isPartner={true}
            universityFilters={universityFilters}
          />
        ))}
      </Content>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={jobsCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  ) : (
    <Container>
      <GenericMessage title="No momento, não há nenhuma vaga disponível." />
    </Container>
  );
}
