import React from 'react';

export function InactiveCategoryIcon({ size = 56, color = '#fff' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 52 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50.2458 36.5447C45.6065 28.5349 40.9766 20.5212 36.356 12.5034C34.5249 9.33248 32.7179 6.14545 30.8506 2.99992C29.5348 0.781989 27.5324 -0.212535 24.9611 0.0377692C22.8851 0.242563 21.3712 1.34819 20.3378 3.13646C16.7925 9.2776 13.2477 15.4192 9.70327 21.5612C6.78172 26.6209 3.85883 31.68 0.934605 36.7388C0.106059 38.167 -0.228572 39.6715 0.163616 41.2964C0.84894 44.1381 3.2034 45.9813 6.15886 45.9933C11.1489 46.0022 16.1385 46.0022 21.1276 45.9933C29.1043 45.9933 37.0806 45.9933 45.0564 45.9933C47.8164 45.9933 50.0558 44.4446 50.9392 41.9349C51.073 41.5668 51.1681 41.1867 51.2805 40.8119V39.2057C51.0679 38.2727 50.7193 37.3762 50.2458 36.5447ZM25.6451 42.987C19.1872 42.987 12.7288 42.987 6.26996 42.987C4.55665 42.987 3.35867 42.0299 3.0575 40.4331C2.90759 39.6367 3.14451 38.9232 3.53937 38.2406C5.36601 35.0861 7.18997 31.929 9.01126 28.7692L22.8677 4.76275C23.7484 3.23817 25.1525 2.64387 26.6476 3.15652C27.4508 3.42423 27.9862 3.99712 28.3944 4.7159C30.2648 7.96138 32.1369 11.2046 34.0109 14.4456C38.5806 22.3599 43.1499 30.2755 47.7187 38.1924C48.6075 39.7317 48.3786 41.3031 47.1217 42.3244C46.4779 42.8464 45.7256 42.9937 44.9212 42.9937C38.4963 42.9874 32.0709 42.9852 25.6451 42.987Z"
        fill={color}
      />
      <path
        d="M27.1549 29.541C27.1549 29.9131 27.0732 30.0215 26.6944 30.0028C25.9957 29.9679 25.2916 29.9693 24.5929 30.0028C24.2155 30.0215 24.1298 29.9157 24.1311 29.541C24.1472 26.0876 24.1405 22.6328 24.1405 19.1781C24.1405 17.9092 24.1526 16.6349 24.1325 15.3727C24.1325 15.0474 24.2115 14.9497 24.534 14.9618C25.2676 14.9885 26.0064 14.9872 26.7373 14.9618C27.0652 14.951 27.1482 15.0447 27.1468 15.3713C27.1321 17.7405 27.1388 20.1111 27.1388 22.4816C27.1388 24.8521 27.1361 27.1878 27.1549 29.541Z"
        fill={color}
      />
      <path
        d="M27.6448 34.9815C27.648 35.5124 27.4408 36.023 27.0686 36.4015C26.6963 36.78 26.1893 36.9957 25.6584 37.0014C25.128 37.0039 24.6181 36.7964 24.2402 36.4242C23.8623 36.052 23.6469 35.5454 23.6413 35.015C23.6388 34.4848 23.8461 33.9751 24.218 33.5972C24.5899 33.2193 25.0961 33.0039 25.6263 32.9978C26.1565 32.995 26.6663 33.202 27.0445 33.5736C27.4227 33.9452 27.6385 34.4514 27.6448 34.9815Z"
        fill={color}
      />
    </svg>
  );
}
