import React, { useState, Fragment, useEffect, useContext } from 'react';
import CompanyHeader from '../../../../components/ViewsHeader/CompanyViewsHeader';
import { Container, Button } from './style';

import { getJobsCount } from '../../services';

import imgBackground from '../../../../assets/JobOffer/bg-vagas.svg';

import HistoryJobsTavs from './HistoryJobsTabs';
import PublishedJobsTabs from './PublishedJobsTabs';
import JobOfferPublicationTypeModal from '../JobOfferForm/JobOfferPublicationTypeModal';
import PrincipalModal from '../JobOfferForm/helpers/PrincipalModal';
import EngineSteps from '../JobOfferForm/Steps/EngineSteps';
import { JobOfferContext } from '../../Contexts/JobOfferContext';
import LoadingModal from '../LoadingModal';
import { IModalConfigLoading, configModal } from './useCases/IModalConfig';
import { formatNumber } from '../../../../utils/formatNumber';
import { hasExpiredJobOffers } from 'services/company/jobs';
import BaseLayoutContext from 'contexts/base-layout';
import ModalInformation from 'components/informationModal';

export default function CompanyJobsOpening({ role, seeMatch }) {
  const [activeTab, setActiveTab] = useState(0);
  const [openModalPublicationType, setOpenModalPublicationType] = useState(
    false
  );
  const [openModalPublishJob, setOpenModalPublishJob] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const { handleIsLoadingState } = useContext(BaseLayoutContext);
  const {
    setFormValues,
    setDataFunctions,
    zIndex,
    setZIndex,
    filterState,
    setFilterState,
    appliedFilters,
    setAppliedFilters,
    setCurrentJobFilter,
    listJobs,
  } = useContext(JobOfferContext);

  const [countHistoryInstitution, setCountHistoryInstitution] = useState(0);
  const [countHistoryPartners, setCountHistoryPartners] = useState(0);

  const [countMyJobs, setCountMyJobs] = useState(0);
  const [countJobsPartners, setCountJobsPartners] = useState(0);

  const [sumJobs, setSumJobs] = useState(0);
  const [sumHistory, setSumHistory] = useState(0);

  const [existExpiredJob, setExistExpiredJob] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const countJobs = async () => {
    const data = await getJobsCount();
    handleIsLoadingState(false);
    const {
      jobs,
      jobs_partner,
      jobs_sum,

      jobs_history,
      jobs_history_partner,
      jobs_history_sum,
    } = data;

    setSumHistory(jobs_history);
    setCountHistoryInstitution(jobs_history);
    setCountHistoryPartners(jobs_history_partner);

    setSumJobs(jobs);
    setCountMyJobs(jobs);
    setCountJobsPartners(jobs_partner);
  };
  const Tabs = [
    {
      activeTab: 0,
      component: (
        <PublishedJobsTabs
          countJobs={countJobs}
          countMyJobs={countMyJobs}
          countJobsPartners={countJobsPartners}
          role={role}
          seeMatch={seeMatch}
          setOpenModalPublishJob={setOpenModalPublicationType}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <HistoryJobsTavs
          countJobs={countJobs}
          countHistoryPartners={countHistoryPartners}
          countHistoryInstitution={countHistoryInstitution}
          role={role}
          seeMatch={seeMatch}
        />
      ),
    },
  ];

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  const createNewJobOffer = async () => {
    const hasExpiredJob = await hasExpiredJobOffers();
    if (hasExpiredJob.exists) {
      setExistExpiredJob(true);
      setActiveTab(1);
      return;
    }
    setExistExpiredJob(false);
    setOpenModalPublicationType(true);
  };

  const newJobOfferButton = () => {
    return (
      <Button onClick={() => createNewJobOffer()}>CADASTRAR NOVA VAGA</Button>
    );
  };

  async function finalizationJobModal() {
    setExistExpiredJob(false);
    await setAppliedFilters({ status: 'expired' });
    await setFilterState({ status: 'expired' });
    await setCurrentJobFilter({ status: 'expired' });
  }

  const handleOpenPublishModal = () => {
    return setOpenModalPublishJob(true);
  };

  useEffect(() => {
    setDataFunctions({
      handleOpenPublishModal,
      setLoadingModal,
      setOpenModalPublishJob,
    });
  }, []);

  useEffect(() => {
    if (!listJobs) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [listJobs, activeTab]);

  return (
    <Fragment>
      <ModalInformation
        modalOpenState={existExpiredJob}
        config={configModal}
        buttonConfirmFunction={finalizationJobModal}
        disableButton={disableButton}
      />

      <div>
        <Container>
          <CompanyHeader
            title="Vagas"
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            backgroundImage={imgBackground}
            tabs={[
              `Vagas Publicadas (${formatNumber(sumJobs)})`,
              `Histórico de Vagas (${formatNumber(sumHistory)})`,
            ]}
            headerElements={newJobOfferButton}
          />

          {renderPrincipalTabs(activeTab)}
          <JobOfferPublicationTypeModal
            openModal={openModalPublicationType}
            setOpenModal={setOpenModalPublicationType}
            isInternalCompany={true}
            setOpenPublishJobModal={setOpenModalPublishJob}
            openModalPublishJob={openModalPublishJob}
          />
        </Container>

        <LoadingModal
          modalOpenState={loadingModal}
          config={IModalConfigLoading}
        />

        <PrincipalModal
          zIndex={zIndex}
          openModal={openModalPublishJob}
          closeModal={() => setOpenModalPublishJob(false)}
        >
          <EngineSteps />
        </PrincipalModal>
      </div>
    </Fragment>
  );
}
