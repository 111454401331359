import { AiOutlineUser } from 'react-icons/ai';
import { ActiveUsersIcon } from 'assets/DashBoard/Rebuild/ActiveUsersIcon';
import { WithResumeIcon } from 'assets/DashBoard/Rebuild/WithResumeIcon';
import { NoResumeIcon } from 'assets/DashBoard/Rebuild/NoResumeIcon';
import { TotalHitsIcon } from 'assets/DashBoard/Rebuild/TotalHitsIcon';
import { GraduationCapIcon } from 'assets/ImgsLogin/IconsLogin';
import { Suitcase } from 'assets/DashBoard/Rebuild/Suitcase';
import { Calendar } from 'assets/DashBoard/Rebuild/Calendar';

export const returnIcons = {
  total_students: <GraduationCapIcon size={20} color="#009291" />,
  active_students: <ActiveUsersIcon size={20} />,
  students_with_curriculum: <WithResumeIcon size={20} />,
  students_without_curriculum: <NoResumeIcon size={20} />,
  total_logins: <TotalHitsIcon size={20} />,
  total_unique_logins: <AiOutlineUser size={20} color="#009291" />,
  total_jobs: <Suitcase size={20} color="#009291" />,
  total_active_jobs: <Suitcase size={20} color="#009291" />,
  total_finished_jobs: <Calendar size={20} color="#009291" />,
  total_applications: <ActiveUsersIcon size={36} />,
  average_applications_per_job_offer: <ActiveUsersIcon size={36} />,
};

export const returnText = {
  total_applications: 'Total de candidaturas nas vagas',
  average_applications_per_job_offer: 'Média de candidaturas por vaga',
};

export const returnInformations = (type, informationPeriod) => {
  const returnInformations = {
    total_students: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que se matricularam no período informado`
        : `Aqui estão os estudantes que estão matriculados`
    }`,
    active_students: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que acessaram o sistema pela primeira vez no período informado`
        : `Aqui estão os estudantes que já acessaram o sistema`
    }`,
    students_with_curriculum: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que criaram o currículo no período informado`
        : `Aqui estão os estudantes que possuem currículo`
    }`,
    students_without_curriculum: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que não criaram o currículo até o final do período informado`
        : `Aqui estão os estudantes que não possuem currículo`
    }`,
    total_logins: `${
      informationPeriod.star_date !== undefined
        ? `Aqui está a quantidade de acessos feitos por estudantes no período informado`
        : `Aqui está a quantidade de acessos feitos por estudantes`
    }`,
    total_unique_logins: `${
      informationPeriod.star_date !== undefined
        ? `Aqui está a quantidade de acessos únicos feitos por estudantes no período informado`
        : `Aqui está a quantidade de acessos feitos por estudantes`
    }`,
    total_jobs: 'Aqui está a quantidade de vagas cadastradas',
    total_active_jobs: 'Aqui está a quantidade de vagas ativas',
    total_finished_jobs:
      'Aqui está a quantidade de vagas finalizadas e expiradas',
    total_applications: 'Aqui está a quantidade de candidaturas nas vagas',
    average_applications_per_job_offer:
      'Aqui está a média de candidaturas por vaga',
  };

  return returnInformations[type];
};

export const returnInformationsGraduates = (type, informationPeriod) => {
  const returnInformations = {
    total_students: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que se matricularam no período informado`
        : `Aqui estão os estudantes que já estão formados`
    }`,
    active_students: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que acessaram o sistema pela primeira vez no período informado`
        : `Aqui estão os estudantes formados que já acessaram o sistema`
    }`,
    students_with_curriculum: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que criaram o currículo no período informado`
        : `Aqui estão os estudantes formados que possuem currículo`
    }`,
    students_without_curriculum: `${
      informationPeriod.star_date !== undefined
        ? `Aqui estão os estudantes que não criaram o currículo até o final do período informado`
        : `Aqui estão os estudantes formados que não possuem currículo`
    }`,
    total_logins: `${
      informationPeriod.star_date !== undefined
        ? `Aqui está a quantidade de acessos feitos por estudantes no período informado`
        : `Aqui está a quantidade de vagas cadastradas`
    }`,
    total_unique_logins: `${
      informationPeriod.star_date !== undefined
        ? `Aqui está a quantidade de acessos únicos feitos por estudantes no período informado`
        : `Aqui está a quantidade de acessos únicos feitos por estudantes formados`
    }`,
    total_jobs: 'Aqui está a quantidade de vagas cadastradas',
  total_active_jobs: 'Aqui está a quantidade de vagas ativas',
  total_finished_jobs:
    'Aqui está a quantidade de vagas finalizadas e expiradas',
  total_applications: 'Aqui está a quantidade de candidaturas nas vagas',
  average_applications_per_job_offer:
    'Aqui está a média de candidaturas por vaga',
  };

  return returnInformations[type];
};


const baseConfig = {
  size: 90,
};

export const createPropsObject = (item) => {
  return {
    size: baseConfig.size,
    media: item.percentage,
    strokeWidth: 5.3,
    label: item.label,
    studentQuantityBubble: item.value,
  };
};

/**
 * Adapts the provided data into a specific structure with a 'result' key.
 *
 * @param {any} data - The data to be adapted.
 * @returns {Object} - An object containing the provided data under the 'result' key.
 */

export const returnAdapter = (data) => {
  const dataChart = {
    result: data || null,
  };
  return dataChart;
};

/**
 * Checks if all values in the 'result' array of the provided data object are zero.
 *
 * @param {Object} data - The data object containing a 'result' array.
 * @returns {boolean} - Returns true if 'result' is an array and all its elements have a value of zero, otherwise false.
 */

export const verifyValuesChartAreZero = (data) => {
  return (
    Array.isArray(data?.result) &&
    data?.result.every(({ value }) => value === 0)
  );
};
