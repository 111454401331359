import styled from 'styled-components';

export const Container = styled.div``;

export const StudentCardContainer = styled.div`
  position: fixed;
  z-index: 9999999;
  background-color: #eeee;
  width: calc(100% - 252px);
  height: 100%;
  top: 0;
  left: 252px;
`;

export const ContainerInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  align-items: center;
  > span {
    border-radius: 4px;
    padding: 10px;
    background-color: #fff;
    word-break: break-word;
    margin: 10px;
    width: 100%;
    display: flex;
    align-items: center;

    > img {
      width: 20px;
      margin-right: 10px;
    }

    > p {
      margin: 0;
    }
  }
`;
