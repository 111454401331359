import React, { useEffect } from 'react';

import background from 'assets/FairAndEvents/background.png';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import ControllerVisualization from './ControllerHomeVisualization';
import RegisterModal from './UiComponents/RegisterModal';
import { RegisterModalProvider } from './Contexts/RegisterModalContext';

import { VisualizationProvider } from './Contexts/VisualizationContext';
import { VisualizationStudentProvider } from './Contexts/VisualizationStudent';

export default function FairAndEventsEngine({
  handleIsLoadingState,
  history,
  match,
  ...rest
}) {
  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const { params } = match;
  const { id, uuid } = params;

  return (
    <>
      <RegisterModalProvider>
        <VisualizationProvider history={history}>
          <VisualizationStudentProvider>
            <RegisterModal />
            <CompanyHeader
              title="Feiras e Eventos"
              backgroundImage={background}
            />
            <ControllerVisualization history={history} id={id} uuid={uuid} />
          </VisualizationStudentProvider>
        </VisualizationProvider>
      </RegisterModalProvider>
    </>
  );
}
