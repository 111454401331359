import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import VectorArrowBack from 'assets/img/VectorArrowBack.svg';
import BaseLayoutContext from 'contexts/base-layout';
import { Redirect } from 'react-router-dom';
import { maskCnpj } from 'utils/inputMasks';
import { handleFieldMask, validateCNPJ, cnpjRegex } from 'utils/validation';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import {
  companyPreRegisterStatus,
  isCompanyRegistered,
  verifyRecaptchaV3,
} from 'services/companies';

import {
  ContainerLogin,
  Titles,
  Paragraph,
  ButtonLogin,
  ImgArrow,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  Divider,
  ContainerParagraphLogin,
  ButtonRegister,
  Footer,
  CreateAccountDiv,
  ButtonOutlined,
  CaptchaContainer,
  CaptchaSkeleton,
} from '../../styles';
import InputUsernameLogin from '../../UIComponents/InputUsername';
import { Helmet } from 'react-helmet';
import ModalFail from 'views/LoginPage/components/informationModal';
import {
  IModalCompanyPreRegister,
  IModalCompanyHasRegister,
  IModalCompanyIesPreRegister,
} from 'views/LoginPage/useCases/IModalConfig';
import { captcha } from 'constants/configs';
import ModalInformation from 'components/informationModal';
import VectorArrowBackSvg from '../../UIComponents/VectorArrowBack';

export default function CreateAccountCompany(props) {
  const {
    setShowCreateAccount,
    setContentLogin,
    logo,
    universityColor,
  } = props;
  const [organizationNumber, setOrganizationNumber] = useState();
  const { handleIsLoadingState, openSnackbar } = useContext(BaseLayoutContext);
  const [grecaptcha, setGrecaptcha] = useState(null);
  const [confirmCaptcha, setConfirmCaptcha] = useState(false);
  const [loadedCaptcha, setLoadedCaptcha] = useState(false);
  const [modalFailOpenState, setModalFailOpenState] = useState(false);
  const [modalFailConfig, setModalFailConfig] = useState(
    IModalCompanyHasRegister
  );
  const [status, setStatus] = useState('new');

  const formCreateAccount = useRef();

  useEffect(
    () => () => {
      window.removeEventListener('loadGrecaptcha');
    },
    []
  );

  useEffect(() => renderCaptcha(), [grecaptcha]);

  useEffect(() => {
    if (grecaptcha == null) {
      window.addEventListener('loadGrecaptcha', (event) => {
        setGrecaptcha(event.detail);
      });
    }
  }, []);

  function handleBackLogin() {
    setShowCreateAccount(false);
    setContentLogin(true);
  }

  const renderCaptcha = useCallback(() => {
    if (grecaptcha == null) return;
    window.removeEventListener('loadGrecaptcha');
    try {
      grecaptcha.render('captchaDiv', {
        sitekey: captcha.siteKey,
        callback: (token) => verifyCaptcha(token),
      });
      setTimeout(() => setLoadedCaptcha(true), 500);
    } catch (e) {
      return;
    }
  }, [grecaptcha]);

  const verifyCaptcha = (token) => {
    verifyRecaptchaV3(token);
    setConfirmCaptcha(true);
  };

  const closeModalFail = () => {
    setModalFailOpenState(false);
  };

  const handleCreateAccount = async (data) => {
    const organizationNumber = data.organizationNumberCompany;

    if (!confirmCaptcha) {
      openSnackbar('Verifique o captcha', true);
      return;
    }

    let isRegistered = await companyPreRegisterStatus(
      data.organizationNumberCompany
    );

    try {
      formCreateAccount.current.setErrors({});

      const companyBeginRegistrationSchema = Yup.object().shape({
        organizationNumberCompany: Yup.string()
          .matches(cnpjRegex, { message: 'Informe um CNPJ válido.' })
          .test(
            'organizationNumberValidation',
            'Informe um CNPJ válido.',
            (value) => {
              if (value) {
                if (!validateCNPJ(value)) {
                  openSnackbar('Informe um CNPJ válido.', true);
                  return;
                }
                return validateCNPJ(value);
              }
              return false;
            }
          )
          .test(
            'organizationPartialRegistered',
            'Esta empresa já iniciou o cadastro.',
            async (value) => {
              if (value) {
                handleIsLoadingState(false);
                if (isRegistered.status === 'partial') {
                  setModalFailOpenState(true);
                  setModalFailConfig({
                    ...IModalCompanyPreRegister,
                    buttonConfirmText: 'CONTINUAR CADASTRO',
                    buttonConfirmFunction: () => {
                      setModalFailOpenState(false);
                      setStatus('partial');
                      setOrganizationNumber(organizationNumber);
                    },
                  });
                  return false;
                }
                return true;
              }
              return false;
            }
          )
          .test(
            'organizationPartialRegistered',
            'Esta empresa já iniciou o cadastro.',
            async (value) => {
              if (value) {
                handleIsLoadingState(false);
                if (isRegistered.status === 'partial_by_university') {
                  setModalFailOpenState(true);
                  setModalFailConfig({
                    ...IModalCompanyIesPreRegister,
                    buttonConfirmText: 'CONTINUAR CADASTRO',
                    buttonConfirmFunction: () => {
                      setModalFailOpenState(false);
                      setStatus('partial');
                      setOrganizationNumber(organizationNumber);
                    },
                  });
                  return false;
                }
                return true;
              }
              return false;
            }
          )
          .test(
            'organizationRegistered',
            'Esta empresa já é cadastrada.',
            async (value) => {
              if (value) {
                handleIsLoadingState(false);

                if (isRegistered.status === 'complete') {
                  openSnackbar(`CNPJ já é cadastrado.`, true);
                  setModalFailOpenState(true);
                  setModalFailConfig(IModalCompanyHasRegister);
                  return false;
                }

                if (isRegistered.message) {
                  openSnackbar(
                    `Erro ${isRegistered.message} ao consultar CNPJ. Entre em contato com o suporte.`,
                    true
                  );
                  return false;
                }

                return isRegistered.status !== 'complete';
              }
            }
          )
          .test('required', 'Campo obrigatório', (value) => {
            return !!value;
          }),
      });

      const validit = await companyBeginRegistrationSchema.validate(data, {
        abortEarly: false,
      });

      if (validit) {
        setOrganizationNumber(organizationNumber);
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formCreateAccount.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?onload=loadCallback&render=explicit`}
          async
        ></script>
        <script async>
          {`
          function loadCallback() {
            window.dispatchEvent(new CustomEvent('loadGrecaptcha', {detail:grecaptcha}))
            }`}
        </script>
      </Helmet>
      <ContainerLogin>
        {organizationNumber && (
          <Redirect
            to={{
              pathname: '/empresas/cadastro',
              state: {
                organizationNumber: organizationNumber,
                universityColor: universityColor,
                status: status,
              },
            }}
          />
        )}
        <FragmentContainer marginFragment="0 0 0 0">
          <div onClick={handleBackLogin}>
            <VectorArrowBackSvg color={universityColor} />
          </div>
          <ContainerInCenter logoWidth="260px" isCompany>
            <img src={logo} alt="" />
          </ContainerInCenter>
          <Titles lineHeight="35px" style={{ marginTop: '20px' }}>
            <strong>Insira seu CNPJ para iniciar o cadastro</strong>
          </Titles>

          <Form ref={formCreateAccount} onSubmit={handleCreateAccount}>
            <InputUsernameLogin
              id="organizationNumber"
              name="organizationNumberCompany"
              label="CNPJ"
              universityColor={universityColor}
              placeholder="Digite aqui..."
              onKeyUp={(event) => {
                handleFieldMask(event, maskCnpj);
              }}
            />
            <ContainerInCenter>
              <ButtonLogin type="submit" color={universityColor}>
                Cadastrar
              </ButtonLogin>
            </ContainerInCenter>
          </Form>

          <CaptchaContainer>
            <div
              id="captchaDiv"
              style={{ display: loadedCaptcha ? 'block' : 'none' }}
            ></div>
            <CaptchaSkeleton show={!loadedCaptcha} />
          </CaptchaContainer>

          <CreateAccountDiv>
            <div className="divider">
              <hr />
              <span>OU</span>
              <hr />
            </div>
            <div className="button-login-container">
              <ButtonOutlined color={universityColor} onClick={handleBackLogin}>
                Entrar
              </ButtonOutlined>
            </div>
          </CreateAccountDiv>
        </FragmentContainer>
        <ContainerWorkalove>
          <Footer>
            By <strong>Workalove</strong>
          </Footer>
        </ContainerWorkalove>
      </ContainerLogin>
      <ModalInformation
        universityColor={universityColor}
        modalOpenState={modalFailOpenState}
        config={modalFailConfig}
        buttonConfirmFunction={
          modalFailConfig.buttonConfirmFunction ?? closeModalFail
        }
      />
    </>
  );
}
