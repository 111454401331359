import { authenticatedAPI } from 'services/api';

const getStudentNotifications = async (urlParams) => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/notifications${urlParams || ''}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getSettings = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/students/me/notifications/settings`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveSettings = async (data) => {
  const response = authenticatedAPI
    .post(`/api/internal/students/me/notifications/settings`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const disableNotifications = async (id) => {
  const response = authenticatedAPI
    .put(`/email/unsubscribe/student/${id}`)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const enableNotifications = async (id) => {
  const response = authenticatedAPI
    .put(`/email/subscribe/student/${id}`)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

export {
  getStudentNotifications,
  getSettings,
  saveSettings,
  disableNotifications,
  enableNotifications,
};
