import React, { Fragment } from 'react';
import { ListItem, Grid } from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Button from 'components/CustomButtons/Button';
import { bool, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import IcoDone from '@material-ui/icons/Done';

const ProfessionRequirementsReturn = ({
  classes,
  titles,
  value,
  requirement,
  index,
  keyName,
  competences,
  isMoreRequirements,
  isMatching,
  isExtracourse,
  name,
}) => {
  const existValue = competences
    ? competences.values.find((obj) => obj.name === value.name)
    : undefined;

  return (
    <Fragment>
      {index === 0 &&
        (!isExtracourse ? (
          <h5 className={classes.requirementSubTitle}>
            <strong>{titles[requirement.name]}</strong>
          </h5>
        ) : isMatching ? (
          <div>
            <h5 className={classes.requirementSubTitle}>
              <strong>Competências Adquiridas</strong>
            </h5>
            <p
              className={classNames(
                classes.requirementSubTitle,
                classes.paragraphClass
              )}
            >
              Mantenha seu currículo atualizado para identificarmos todas as
              competências adquiridas, {name}
            </p>
          </div>
        ) : !isMoreRequirements ? (
          <div>
            <h5
              className={classNames(
                classes.requirementSubTitle,
                classes.marginTopClass
              )}
            >
              <strong>Competências a serem desenvolvidas</strong>
            </h5>
            <p
              className={classNames(
                classes.requirementSubTitle,
                classes.paragraphClass
              )}
            >
              Ainda não identificamos essas competências no seu currículo,{' '}
              {name}
            </p>
          </div>
        ) : null)}
      <ListItem style={{ marginBottom: '-14px' }}>
        <span className={classes.iconChecked}>
          <IcoDone
            className={classNames({
              [classes.matching]: value.matching || existValue,
              [classes.notMatching]: !value.matching && !existValue,
              [classes.courseText]:
                !value.matching && keyName === 'academic_requirements',
            })}
          />
        </span>{' '}
        <span
          className={classNames(classes.requirementTitle, {
            [classes.matching]: value.matching || existValue,
            [classes.notMatching]: !value.matching && !existValue,
            [classes.courseText]:
              !value.matching && keyName === 'academic_requirements',
          })}
        >
          {value.name}{' '}
        </span>
      </ListItem>

      {value.courses &&
        value.courses.map((course) => (
          <div
            style={{
              flexGrow: 1,
              maxWidth: 919,
              padding: '5px 62px',
              marginBottom: -17,
            }}
            key={course.id}
          >
            <Grid container spacing={16}>
              <Grid item sm={12} xs={12} md={12}>
                <ListItem dense>
                  <ListItemText
                    primary={
                      <b>
                        <Icon
                          style={{
                            fontSize: '16px',
                            marginBottom: -3,
                          }}
                        >
                          chevron_right
                        </Icon>
                        {course.name}
                      </b>
                    }
                    secondary={
                      <p
                        style={{
                          marginLeft: 16,
                          textAlign: 'justify',
                        }}
                      >
                        {course.description ? course.description : ''}
                      </p>
                    }
                  />
                  <ListItemSecondaryAction style={{ right: -59 }}>
                    <Button
                      target="_blank"
                      href={course.link}
                      style={{
                        fontSize: '10px',
                        padding: '5px 14px 3px',
                      }}
                      round
                      className={classes.addButton}
                    >
                      Inscreva-se
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </Grid>
            </Grid>
          </div>
        ))}
    </Fragment>
  );
};

ProfessionRequirementsReturn.defaultProps = {
  isMoreRequirements: false,
  isExtracourse: false,
  isMatching: false,
};

ProfessionRequirementsReturn.propTypes = {
  isMatching: bool,
  isMoreRequirements: bool,
  isExtracourse: bool,
  value: shape.isRequired,
  titles: shape.isRequired,
  classes: shape.isRequired,
  requirement: shape.isRequired,
  index: number.isRequired,
  keyName: string.isRequired,
  competences: shape.isRequired,
  name: string.isRequired,
};

export default ProfessionRequirementsReturn;
