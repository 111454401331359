export const status = {
  pending: {
    label: 'PENDENTE',
    color: '#FFAD00',
    backgroundColor: '#FFFCE0',
    helpText: 'Confirmação do e-mail pendente',
  },
  success: {
    label: 'SUCESSO',
    color: '#068E2C',
    backgroundColor: '#D9FFE3',
    helpText: 'O e-mail está ativo',
  },
  failed: {
    label: 'FALHA',
    color: '#DA0505',
    backgroundColor: '#FFEDED',
    helpText: 'Tente cadastrar o e-mail novamente mais tarde',
  },
  'temporary Failure': {
    label: 'FALHA TEMPORÁRIA',
    color: '#FF5C00',
    backgroundColor: '#FFE9D9',
    helpText: 'Entre em contato com a equipe de engenharia',
  },
  'not Started': {
    label: 'NÃO INICIADO',
    color: '#606062',
    backgroundColor: '#E5E5E5',
    helpText: 'A análise do e-mail ainda não foi iniciada',
  },
  '': {
    label: '',
    color: '',
    backgroundColor: '',
    helpText: '',
  },
};
