import styled from '@react-pdf/styled-components';

export const Body = styled.Page`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  font-weight: normal;
  font-size: 10px;
  font-family: 'Helvetica';
`;

export const Header = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-bottom: 20px;
`;

export const Name = styled.Text`
  font-size: 30px;
`;

export const ContactContainer = styled.View`
  color: #999;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContactIcon = styled.Image`
  margin-right: 2.5px;
  color: #999;
  width: 14px;
  height: auto;
`;

export const Contact = styled.Text`
  margin-right: 12px;
`;

export const DefaultSection = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 60px;
`;

export const SectionTitle = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
`;

export const SectionIcon = styled.Image`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-right: 25px;
  border-radius: 5px;
  padding: 5px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

export const SectionContainer = styled.View`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
`;

export const SectionContent = styled.Text`
  margin-bottom: 10px;
`;

export const SectionContentItemColumn = styled.Text`
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  display: flex;
  max-width: 50%;
`;
export const SectionContentItemRow = styled.Text`
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 60%;
  width: 60%;
`;

export const SectionContentLanguage = styled.View`
  max-width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
`;

export const ContentColumn = styled.View`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
`;

export const SectionColumn = styled.View`
  display: flex;
  flex-direction: column;
  margin: -10px -10px 0 -10px;
`;

export const SectionContentColumn = styled.View`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const ContentList = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TextStrong = styled.Text`
  font-family: 'Helvetica-Bold';
`;

export const Divider = styled.View`
  margin-top: 10px;
  background-color: #efeeec;
  height: 1px;
  width: 100%;
`;

export const Footer = styled.View`
  color: #999;
  position: absolute;
  bottom: 20px;
  width: 100vw;
  text-align: right;
`;

export const TextMargin = styled.View`
  margin-right: 32px;
`;

export const SubTitle = styled.Text`
  font-family: 'Helvetica-Bold';
`;

export const SubTitleExp = styled.Text`
  font-family: 'Helvetica-Bold';
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
`;
