import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import { Container, Box, Content } from './style';

export default function BaseModalAcademic({
  children,
  openModal,
  setOpenModal,
  headerTitle,
}) {
  const closeButtonFunction = () => {
    setOpenModal && setOpenModal(false);
  };

  if (!openModal) {
    return null;
  }

  return (
    <Container>
      <Box>
        <Content>
          <CloseButton onClick={closeButtonFunction} />

          <h3>{headerTitle ? headerTitle : 'Análise de Contratos'}</h3>
          {children}
        </Content>
      </Box>
    </Container>
  );
}
