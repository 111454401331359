import React, { useState, useEffect, useContext } from 'react';
import { HeaderTabs, ContentTabs, ButtonMatch, Icon } from '../styled';
import ModalMatchInformation from '../../Modals/ModalMatchInfo';
import FilterInputName from '../../FilterModal/FilterName';
import OtherCardsJobOffers from '../../NewCards/OtherCardsJobOffers';
import JobOffersApi from 'views/JobOffers/services/JobOffersApi';
import SkeletonCards from '../../GenericComponents/SkeletonCards';
import FavoriteCardsCompanyJobs from '../../NewCards/FavoriteCompanyCards';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import GamificationLevelOneIncompleteModal from 'views/JobOffers/components/Tabs/TabsJobOffers/GamificationLevelOneIncompleteModal';
import BlankModalInformation from 'components/BlankInformationModal';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { openCoverLetterForm } from 'components/JobDetailsModal/useCases/utils';
import { checkHasCoverLetter } from 'services/student/profile';
import CurriculumContextProvider from 'contexts/CurriculumContext';
import { coverLetterConfig } from '../../Card/useCases/IModalConfig';

export default function TabExclusiveVacancies(props) {
  const {
    listJobOffers,
    setListJobOffers,
    listFavoriteJobOffers,
    setListFavoriteJobOffers,
    search,
    setSearch,
    isLoadingFavoriteJobs,
    setIsLoadingFavoriteJobs,
    isLoadingOtherJobs,
    setIsLoadingOtherJobs,
    openGamificationLevelOneIncompleteModal,
    setOpenGamificationLevelOneIncompleteModal,
    closeModal,
    openSnackbar,
    handleIsLoadingState,
    openModalWithContent,
    setGlobalState,
    openCoverLetterModal,
    setOpenCoverLetterModal,
    hasCoverLetter,
    setHasCoverLetter,
  } = useContext(JobOffersContext);
  const { IconInformation } = wrapperIconsJobOffers();

  const {
    universityColor,
    academic_profile,
    interests,
    life_interests,
  } = props;
  const [openModalMatch, setOpenModalMatch] = useState(false);

  const { gamificationLevel } = useContext(ProfileContext);
  const gamificationLevelOneIncomplete = gamificationLevel <= 1;
  const [totalLoadedOffers, setTotalLoadedOffers] = useState(24);

  const [isApplying, setIsApplying] = useState(false);
  const [
    appliedJobIdFavoriteCompanies,
    setAppliedJobIdFavoriteCompanies,
  ] = useState(null);
  const [
    appliedJobIdAnotherJobOffers,
    setAppliedJobIdAnotherJobOffers,
  ] = useState(null);

  useEffect(() => {
    const checkCoverLetter = async () => {
      const result = await checkHasCoverLetter();
      setHasCoverLetter(result);
    };
    checkCoverLetter();
  }, []);

  useEffect(() => {
    setGlobalState(hasCoverLetter);
  }, [hasCoverLetter]);

  const handleCloseCoverLetterModal = () => {
    setAppliedJobIdFavoriteCompanies(null);
    setAppliedJobIdAnotherJobOffers(null);
    setOpenCoverLetterModal(false);
  };

  async function applyToJobOfferFavoriteCompanies(jobId) {
    try {
      if (isApplying) {
        return;
      }

      const data = { job_offer: jobId };
      setIsApplying(true);
      const response = await JobOffersApi.getCandidateJobOffer(data);
      await fetchListFavoriteJobOffers(false);
      if (response.message) {
        customSnackbar(
          'Erro ao candidatar a vaga, tente novamente mais tarde.',
          'error'
        );
      } else {
        customSnackbar(
          'Agora você está concorrendo a essa vaga!',
          'confirmation'
        );
      }
    } catch (error) {
      customSnackbar(
        'Erro inesperado ao candidatar a vaga, tente novamente mais tarde.',
        'error'
      );
    } finally {
      setIsApplying(false);
      handleCloseCoverLetterModal();
    }
  }

  async function applyToJobOfferAnotherJobOffers(jobId) {
    try {
      if (isApplying) {
        return;
      }

      const data = { job_offer: jobId };
      setIsApplying(true);
      const response = await JobOffersApi.getCandidateJobOffer(data);
      await fetchListJobOffers(false);
      if (response.message) {
        customSnackbar(
          'Erro ao candidatar a vaga, tente novamente mais tarde.',
          'error'
        );
      } else {
        customSnackbar(
          'Agora você está concorrendo a essa vaga!',
          'confirmation'
        );
      }
    } catch (error) {
      customSnackbar(
        'Erro inesperado ao candidatar a vaga, tente novamente mais tarde.',
        'error'
      );
    } finally {
      setIsApplying(false);
      handleCloseCoverLetterModal();
    }
  }

  const isCoverLetterMissingFavoriteCompanies = (jobId) => {
    setAppliedJobIdFavoriteCompanies(jobId);
    setOpenCoverLetterModal(!hasCoverLetter);
    return !hasCoverLetter;
  };

  const isCoverLetterMissingAnotherJobOffers = (jobId) => {
    setAppliedJobIdAnotherJobOffers(jobId);
    setOpenCoverLetterModal(!hasCoverLetter);
    return !hasCoverLetter;
  };

  const isGamificationLevelOneIncomplete = () => {
    setOpenGamificationLevelOneIncompleteModal(gamificationLevelOneIncomplete);
    return gamificationLevelOneIncomplete;
  };

  const fetchListJobOffers = async (showLoadingEffect = true) => {
    if (showLoadingEffect) {
      setIsLoadingOtherJobs(true);
    }
    const data = await JobOffersApi.getListJobOffers();
    setListJobOffers && setListJobOffers(data);
    if (showLoadingEffect) {
      setIsLoadingOtherJobs(false);
    }
  };

  const fetchListFavoriteJobOffers = async (showLoadingEffect = true) => {
    if (showLoadingEffect) {
      setIsLoadingFavoriteJobs(true);
    }
    const data = await JobOffersApi.getFavoriteJobsCompanies(_);
    setListFavoriteJobOffers && setListFavoriteJobOffers(data);
    if (showLoadingEffect) {
      setIsLoadingFavoriteJobs(false);
    }
  };

  const countListJobOffers = listJobOffers?.data?.count;

  useEffect(() => {
    fetchListJobOffers();
    fetchListFavoriteJobOffers();
  }, []);

  const onClickModal = () => {
    setOpenModalMatch(true);
  };

  return (
    <CurriculumContextProvider>
      <ContentTabs>
        {openModalMatch && (
          <ModalMatchInformation setOpenModalMatch={setOpenModalMatch} />
        )}
        <HeaderTabs>
          <FilterInputName
            placeholder="Buscar por vaga"
            universityColor={universityColor}
            onChange={(e) => setSearch(e.target.value)}
          />
          <ButtonMatch universityColor={universityColor} onClick={onClickModal}>
            <IconInformation color={universityColor} />
            Entenda o match
          </ButtonMatch>
        </HeaderTabs>

        {isLoadingFavoriteJobs ? (
          <SkeletonCards />
        ) : (
          <FavoriteCardsCompanyJobs
            search={search}
            universityColor={universityColor}
            listFavoriteJobOffers={listFavoriteJobOffers}
            setListFavoriteJobOffers={setListFavoriteJobOffers}
            fetchListFavoriteJobOffers={fetchListFavoriteJobOffers}
            isGamificationLevelOneIncomplete={isGamificationLevelOneIncomplete}
            gamificationLevelOneIncomplete={gamificationLevelOneIncomplete}
            isCoverLetterMissing={isCoverLetterMissingFavoriteCompanies}
            applyToJobOffer={applyToJobOfferFavoriteCompanies}
          />
        )}

        {isLoadingOtherJobs ? (
          <SkeletonCards />
        ) : (
          <OtherCardsJobOffers
            search={search}
            listJobOffers={listJobOffers}
            universityColor={universityColor}
            setListJobOffers={setListJobOffers}
            countListJobOffers={countListJobOffers}
            fetchListJobOffers={fetchListJobOffers}
            isGamificationLevelOneIncomplete={isGamificationLevelOneIncomplete}
            gamificationLevelOneIncomplete={gamificationLevelOneIncomplete}
            isCoverLetterMissing={isCoverLetterMissingAnotherJobOffers}
            applyToJobOffer={applyToJobOfferAnotherJobOffers}
            totalLoadedOffers={totalLoadedOffers}
            setTotalLoadedOffers={setTotalLoadedOffers}
            listFavoriteJobOffers={listFavoriteJobOffers}
          />
        )}

        <>
          {openGamificationLevelOneIncompleteModal && (
            <GamificationLevelOneIncompleteModal
              universityColor={universityColor}
              onCloseModal={() => {
                setOpenGamificationLevelOneIncompleteModal(false);
              }}
              handleIsLoadingState={handleIsLoadingState}
              academic_profile={academic_profile}
              interests={interests}
              lifeInterests={life_interests}
            />
          )}

          {openCoverLetterModal && (
            <BlankModalInformation
              universityColor={universityColor}
              modalOpenState={openCoverLetterModal}
              config={coverLetterConfig}
              buttonCancelFunction={() => {
                if (appliedJobIdFavoriteCompanies)
                  applyToJobOfferFavoriteCompanies(
                    appliedJobIdFavoriteCompanies
                  );
                if (appliedJobIdAnotherJobOffers)
                  applyToJobOfferAnotherJobOffers(appliedJobIdAnotherJobOffers);
              }}
              buttonConfirmFunction={() => {
                handleCloseCoverLetterModal();
                openCoverLetterForm(
                  openSnackbar,
                  closeModal,
                  handleIsLoadingState,
                  setHasCoverLetter,
                  openModalWithContent,
                  universityColor
                );
              }}
              closeModalFunction={handleCloseCoverLetterModal}
            />
          )}
        </>
      </ContentTabs>
    </CurriculumContextProvider>
  );
}
