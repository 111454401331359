import {
  getCancellationCompanyInterview,
  getScheduleInterview,
  scheduleInterviewCompany,
  updateScheduleInterview,
} from 'views/JobOfferRebuild/services';
import {
  getCancellationUniversityInterview,
  getScheduleInterviewUniversity,
  scheduleInterviewUniversity,
  updateScheduleInterviewUniversity,
} from 'views/StaffPages/jobOpeningsRebuild/services';
import * as Yup from 'yup';
import {
  onlineModeValidation,
  presentialModeValidation,
} from './scheduleInterviewValidation';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import {
  CallToActionButton,
  CanceledButton,
  ContainerSendButton,
} from '../Styles/ScheduleInterviewComponent';
import { scheduleInterviewPayload } from './scheduleInterviewPayloadAndAdapter';

export const controlSubmitForm = async (
  data,
  formRef,
  jobId,
  applicationId,
  controlModal,
  environmentType
) => {
  const dataAdpt = scheduleInterviewPayload(data);

  const validationForm = {
    Online: onlineModeValidation,
    Presencial: presentialModeValidation,
  };

  const scheduleInterviewFunctions = {
    company: scheduleInterviewCompany,
    university: scheduleInterviewUniversity,
  };
  try {
    const schema = validationForm[data?.processFormat];
    await schema.validate(data, {
      abortEarly: false,
    });
    const response = await scheduleInterviewFunctions[environmentType](
      jobId,
      applicationId,
      {
        ...dataAdpt,
        status: 'unscheduled',
      }
    );
    controlModal(false);
    formRef?.current?.reset();
    customSnackbar('Agendamento solicitado com sucesso!', 'confirmation');
    return response;
  } catch (err) {
    const validationErrors = {};

    if (err instanceof Yup.ValidationError) {
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      formRef.current.setErrors(validationErrors);
    }
    return err;
  }
};

export const controlGetInitialData = async (
  environmentType,
  jobId,
  candidateId,
  interviewId
) => {
  const getDataInterviews = {
    company: getScheduleInterview,
    university: getScheduleInterviewUniversity,
  };

  const response = await getDataInterviews[environmentType](
    jobId,
    candidateId,
    interviewId
  );

  if (!response) {
    customSnackbar(
      'Não foi possível retornar os dados da entrevista! Tente novamente mais tarde.',
      'error'
    );
    return;
  }

  return response;
};

export const getDataCancellationModal = async (
  environmentType,
  jobId,
  candidateId,
  interviewId
) => {
  const getDataInterviews = {
    company: getCancellationCompanyInterview,
    university: getCancellationUniversityInterview,
  };

  const response = await getDataInterviews[environmentType](
    jobId,
    candidateId,
    interviewId
  );

  if (!response) {
    customSnackbar(
      'Não foi possível retornar os detalhes do cancelamento! Tente novamente mais tarde.',
      'error'
    );
    return;
  }

  return response;
};

export const controlUpdateInterview = async (
  formRef,
  environmentType,
  jobId,
  candidateId,
  interviewId,
  data,
  resetModal
) => {
  const dataAdpt = scheduleInterviewPayload(data);

  const validationForm = {
    Online: onlineModeValidation,
    Presencial: presentialModeValidation,
  };

  const getUpdateInterview = {
    company: updateScheduleInterview,
    university: updateScheduleInterviewUniversity,
  };

  try {
    const schema = validationForm[data?.processFormat];
    await schema.validate(data, {
      abortEarly: false,
    });
    const response = await getUpdateInterview[environmentType](
      jobId,
      candidateId,
      interviewId,
      {
        ...dataAdpt,
        status: 'rescheduled',
      }
    );
    customSnackbar('Reagendamento solicitado com sucesso!', 'confirmation');
    resetModal();
    return response;
  } catch (err) {
    const validationErrors = {};
    if (err instanceof Yup.ValidationError) {
      err.inner.forEach((error) => {
        validationErrors[error.path] = error.message;
      });
      formRef.current.setErrors(validationErrors);
    }
    return err;
  }
};

export const returnSetOfButtons = (
  interview,
  reschedule,
  setReschedule,
  handleClick,
  setCancelInterviewModal,
  resetModal
) => {
  const controlCancelInterview = () => {
    resetModal();
    setCancelInterviewModal(true);
  };

  if (interview?.hasOwnProperty('status')) {
    if (interview?.status !== 'canceled' && !reschedule) {
      return (
        <ContainerSendButton>
          <CanceledButton onClick={() => controlCancelInterview()}>
            Cancelar entrevista
          </CanceledButton>
          <CallToActionButton outlined onClick={() => setReschedule(true)}>
            Reagendar
          </CallToActionButton>
        </ContainerSendButton>
      );
    } else {
      return (
        <ContainerSendButton>
          <CallToActionButton type="button" onClick={() => handleClick()}>
            Reagendar
          </CallToActionButton>
        </ContainerSendButton>
      );
    }
  } else {
    return (
      <ContainerSendButton>
        <CallToActionButton type="submit"> Enviar convite </CallToActionButton>
      </ContainerSendButton>
    );
  }
};

export const controlProcessFormat = (
  e,
  formRef,
  setOnlineForm,
  setInPersonForm
) => {
  if (typeof e?.value === 'boolean') {
    setOnlineForm(e.value);
    setInPersonForm(!e.value);
    formRef.current.setErrors({});
  }
};

export const returnTitleModalScheduleInterview = (interview, reschedule) => {
  if (interview?.hasOwnProperty('status')) {
    if (interview.status !== 'canceled' && !reschedule) {
      return 'Detalhes da entrevista';
    } else {
      return 'Reagendar entrevista';
    }
  } else {
    return 'Convite para entrevista';
  }
};
