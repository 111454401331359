import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const WrapperImage = styled.div`
  display: flex;
  margin-top: 38px;
  width: 100%;
  justify-content: space-between;
`;

export const TextImage = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
  max-width: 375px;
`;

export const Title = styled.p`
  font-size: 19px;
  color: #606062;
  margin-bottom: 9px;
  font-weight: 700;
`;

export const Info = styled.p`
  color: #606062;
  font-size: 14px;
  margin-bottom: 20px;
`;

export const ContainerImage = styled.div`
  width: 50%;

  display: flex;
  align-items: flex-end;
  flex-direction: column;

  img {
    width: 211px;
    height: 139px;
    border-radius: 3px;
  }
`;

export const InputImage = styled.div`
  width: 211px;
  height: 139px;
  background-color: #f9f9f9;
  border: 1px dashed #c4c4c4;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputImageSkeleton = styled(InputImage)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #dde9ef;
  background-image: linear-gradient(
    120deg,
    #dde9ef 30%,
    #fff 38%,
    #fff 40%,
    #dde9ef 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  border-radius: 5px;
  width: 211px;
  height: 139px;
`;

export const AddImage = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #009291;
  font-size: 14px;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  padding: 3px;
  gap: 3px;
  width: fit-content;
  background-color: transparent;
  border: none;
  font-weight: 700;
  margin-top: 14px;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  height: 500px;
  background-color: #ffffff;
  height: 100%;

  ${(props) => props.top && `margin-top: ${props.top}px;`}
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 26px;

  .preview-button {
    min-width: 155px;
    background-color: #009291;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    border: none;
    height: 35px;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;
    padding: 0px 17px;

    > img {
      width: 16px;
    }
  }
`;
