import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.jsx';

const applicationReturnStyle = (theme) => ({
  ...modalStyle(theme),
  modalReasons: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '700px',
      margin: '1.75rem auto',
    },
    borderRadius: '6px',
    overflow: 'auto',
    maxHeight: 'unset',
    position: 'relative',
    height: 'fit-content',
  },
  applicationModalHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& h4': {
      marginRight: 20,
    },
    '& button': {
      margin: 0,
    },
  },
  reasonCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 25,
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cancelButton: {
    color: '#2f9190',
    fontWeight: 500,
    '&:hover': {
      color: '#2f9190',
      fontWeight: 500,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  saveButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    color: '#2f9190',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  curriculumMenu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFF',
    padding: 20,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '20px 0',
    },
  },
  choicesMenu: {
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'inherit',
    },
  },
  choicesContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  applicationButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconGreen: {
    color: '#4caf50',
    width: 32,
    height: 32,
  },
  iconRed: {
    color: '#f44336',
    width: 32,
    height: 32,
  },
  choiceInfo: {
    textAlign: 'center',
    marginTop: 20,
  },
  loading: {
    margin: 20,
    color: '#009291',
  },
  talentPreview: {
    width: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: -1,
  },
});

export default applicationReturnStyle;
