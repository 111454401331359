import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const ChipButton = styled.button`
  border: none;
  background: ${(props) =>
    props.universityColor ? props.universityColor : '#caeaea'};
  height: 22px;
  border-radius: 5px;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  margin-bottom: 10px;

  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};

  p {
    line-height: 22px;
    color: #606062;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  @media (max-width: 820px) {
    height: 34px;

    p {
      line-height: 16px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 5px;
`;

export const TagLength = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  display: flex;
  justify-content: flex-end;
  color: #606062;
  margin-top: 5px;
`;
export const InputBox = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
  min-width: 260px;
  box-sizing: border-box;

  input {
    border: ${(props) =>
      props.error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
    padding: 12px;
    border-radius: 4px;
    background-color: ${(props) => (props.error ? '#FFEDED' : '#FFFF')};
  }
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-left: 2px;
`;
export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;
  color: #3c4858;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
