import React from 'react';
import {
  ContainerEmails,
  Emails,
  Mounth,
} from './styled';

export function SkeletonTimeline() {
  const numContainers = 4;

  return (
    <ContainerEmails>
      <Mounth />
      <div>
        {[...Array(numContainers)].map((_, index) => (
          <Emails key={index} />
        ))}
      </div>
    </ContainerEmails>
  );
}
