import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 633px;

  padding: 40px;

  background-color: #fff;

  border-radius: 12px;

  position: relative;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: #4b4b4d;
  margin-bottom: 22px;
`;

export const AreaVideo = styled.iframe`
  width: 100%;
  height: 300px;
  border-radius: 10px;
  border: none;
`;

export const Description = styled.p`
  text-align: flex-start;
  width: 100%;
  height: 100%;
  max-height: 250px;
  margin: 25px 0;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 20px;
  }
`;
