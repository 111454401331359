import React from 'react';

import { ContainerTitle } from './styled';
import { string } from 'prop-types';

export default function CardTitle({ id, title }) {
  return (
    <ContainerTitle>
      <span>CÓDIGO: {id}</span>
      <h5>{title}</h5>
    </ContainerTitle>
  );
}

CardTitle.prototype = {
  id: string,
  title: string,
};
