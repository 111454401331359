import React, { Fragment } from 'react';
import {
  Card,
  CardImage,
  CardSubTitles,
  CardTitle,
  CardButton,
  SimpleBar,
} from './styles';

export default function SkeletonJobsCard({ quantity }) {
  const numberOfCards = [];

  for (let i = 1; i <= quantity; i++) {
    numberOfCards.push(i);
  }

  const mapCards = () => {
    return numberOfCards.map((item) => (
      <Card>
        <CardTitle />
        <div className="container-subtitle">
          <CardImage />
          <CardSubTitles size="133px" />
        </div>
        <div className="container-subtitle">
          <CardImage />
          <CardSubTitles size="167px" />
        </div>
        <div className="container-button">
          <CardButton size="91px" />
          <CardButton size="118px" />
        </div>
        <SimpleBar />
        <div className="container-button">
          <CardButton size="133px" />
          <CardButton size="40px" />
        </div>
      </Card>
    ));
  };

  return <Fragment>{mapCards()}</Fragment>;
}

SkeletonJobsCard.defaultProps = {
  quantity: 3,
};
