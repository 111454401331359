import Select, { components } from 'react-select';
import React, { useContext, useMemo, useRef, useState, useEffect } from 'react';
import { ChipButton, SelectedContainer, SelectFilterBox } from './styles';
import { CloseIcon } from 'assets/JobOffer/Close';
import BaseLayoutContext from 'contexts/base-layout';
import { useField } from '@unform/core';

export function FilterMultiSelect({
  name,
  options,
  disabled,
  loading,
  listHeight,
}) {
  const { fieldName, defaultValue, registerField, error, clearError } =
  useField(name);
  const [value, setValue] = useState('teste');
  const [selectedOptions, setSelectedOptions] = useState(selectedOptions ?? []);
  const [unselectedOptions, setUnselectedOptions] = useState(options)
  const selectRef = useRef(null);
  const selectComponentRef = useRef(null);
  const { universityColor } = useContext(BaseLayoutContext);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectComponentRef.current,
      path: 'state.value',
      getValue: () => value,
    });
  }, [fieldName, registerField, value]);

  const selectStyles = {
    menuList: (base) => ({
      ...base,
      height: listHeight,
      maxHeight: '192px',
      '::-webkit-scrollbar': {
        width: '5px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#aaaaaa',
        borderRadius: '3px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#999999',
      },
    }),
  };

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: universityColor || '#009291',
      },
    };
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Não há mais opções para selecionar</span>
      </components.NoOptionsMessage>
    );
  };

  const offsetWidth = useMemo(() => selectRef.current?.offsetWidth, [
    selectRef.current,
  ]);


  const selectOption = (e) => {
    let selectedArray = [...selectedOptions];
    selectedArray.push(e);
    setSelectedOptions(selectedArray);
  }

  const removeOption = (e,v) => {
    e.preventDefault();
    let selectedArray = selectedOptions.filter(({value}) => value !== v)
    setSelectedOptions(selectedArray);
  }

  useEffect(() => {
    setUnselectedOptions(options.filter(
      (option) => {  
        return (selectedOptions.findIndex(({value}) => value === option.value) == -1)
      }
    ));
    setValue(selectedOptions.map(({value}) => value));
  }, [selectedOptions, options])

  return (
    <SelectFilterBox ref={selectRef}>
      <Select
        name={name}
        placeholder="Selecione"
        options={unselectedOptions}
        theme={selectTheme}
        components={{ NoOptionsMessage }}
        value={value}
        styles={selectStyles}
        isDisabled={disabled}
        isLoading={loading}
        onChange = {selectOption}
        ref={selectComponentRef}
      />
      {selectedOptions.length > 0 && (
        <SelectedContainer>
          {selectedOptions.map(({value}) => {
            const chipText = options?.find((option) => option?.value === value)
              .label;
            return (
              <ChipButton
                universityColor={universityColor}
                key={value}
                maxWidth={`${offsetWidth}px`}
                onClick = {(e) => removeOption(e,value)}
              >
                <p title={chipText}>{chipText}</p>
                <CloseIcon />
              </ChipButton>
            );
          })}
        </SelectedContainer>
      )}
    </SelectFilterBox>
  );
}
