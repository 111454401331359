import React from 'react';
import {
  ContainerButtons,
  NextButton,
  PrevsButton,
  SaveButton,
} from './styled';
import { useRegisterModalContext } from '../../Contexts/RegisterModalContext';

export default function StepController({ isButtonDisabled }) {
  const { isLastStep, isFirstStep, prevsFormStep } = useRegisterModalContext();

  const lastFormButtons = (
    <>
      <PrevsButton onClick={prevsFormStep}></PrevsButton>
      <SaveButton type="submit">SALVAR</SaveButton>
    </>
  );

  const firstFormButtons = (
    <>
      <NextButton disabled={isButtonDisabled} type="submit"></NextButton>
    </>
  );

  const middleFormButtons = (
    <>
      <PrevsButton onClick={prevsFormStep}></PrevsButton>
      <NextButton disabled={isButtonDisabled} type="submit"></NextButton>
    </>
  );

  const controllButtons = () => {
    if (isLastStep) {
      return lastFormButtons;
    }

    if (isFirstStep) {
      return firstFormButtons;
    }

    return middleFormButtons;
  };

  return <ContainerButtons>{controllButtons()}</ContainerButtons>;
}
