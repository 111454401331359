import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { func, object, bool } from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import notificationStyle from "assets/jss/material-dashboard-pro-react/components/notificationsStyle";

import {
  List,
  ListItem,
  Typography,
  Divider,
  Collapse,
  Button
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import useDefineColor from "constants/colors";
import BaseLayoutContext from "contexts/base-layout";

const NotificationsList = props => {
  const {
    classes,
    notifications,
    nextNotifications,
    showNextNotifications,
    handleShowNextNotifications,
    toggleNotificationDrawer,
  } = props;

  const {universityColor} = useContext(BaseLayoutContext)

  return (
    <div className={classes.notificationSidebar} role="presentation">
      <List>
        <ListItem className={classes[`${useDefineColor(universityColor)}SidebarHeader`]}>
          <h3>Notificações</h3>
          <Button
            color="transparent"
            round
            justIcon
            aria-label="close drawer"
            onClick={toggleNotificationDrawer}
          >
            <CloseIcon />
          </Button>
        </ListItem>
        {notifications.count ? (
          <Fragment>
            {notifications.results.map(notification => (
              <Fragment key={notification.id}>
                <Link
                  to={notification.link}
                  className={classes.notificationLink}
                >
                  <ListItem alignItems="flex-start">
                    <Typography component="span" variant="body1">
                      {notification.message}
                    </Typography>
                  </ListItem>
                </Link>
                <Divider />
              </Fragment>
            ))}
            {nextNotifications.results && (
              <Collapse in={showNextNotifications}>
                <Fragment>
                  {nextNotifications.results.map(notification => (
                    <Fragment key={notification.id}>
                      <Link to={notification.link}>
                        <ListItem
                          alignItems="flex-start"
                          className={classes.notificationLink}
                        >
                          <Typography component="span" variant="body1">
                            {notification.message}
                          </Typography>
                        </ListItem>
                      </Link>
                      <Divider />
                    </Fragment>
                  ))}
                </Fragment>
              </Collapse>
            )}
            {(nextNotifications.next ||
              (notifications.next && !showNextNotifications)) && (
              <ListItem
                alignItems="center"
                className={classes[`${useDefineColor(universityColor)}ShowMoreContainer`]}
              >
                <Button onClick={handleShowNextNotifications} simple>
                  Mostrar mais
                </Button>
              </ListItem>
            )}
          </Fragment>
        ) : (
          <ListItem
            alignItems="flex-start"
            style={{ justifyContent: "center" }}
          >
            <Typography component="span" variant="body1">
              Você não tem notificações!
            </Typography>
          </ListItem>
        )}
      </List>
    </div>
  );
};

NotificationsList.propTypes = {
  classes: object.isRequired,
  toggleNotificationDrawer: func,
  notifications: object,
  nextNotifications: object,
  showNextNotifications: bool,
  handleShowNextNotifications: func
};

export default withStyles(notificationStyle)(NotificationsList);
