const studentListAdapter = (student) => ({
  Nome: student.name,
  RA: student.course_registration_id,
  Curso: student.course_name,
  Periodo: student.course_semester,
  StatusMatricula: student.course_status,
  Telefone: student.home_phone,
  Email: student.email,
  Tipo: student.course_type,
  Modalidade: student.course_attendance_mode,
  Entrevistas: student.available_for_interviews ? 'Disponível' : 'Indisponível',
});

const studentFilterAdapter = (filters, isAttractions) => {
  if (Object.keys(filters).length > 0) {
    const queryString = Object.keys(filters)
      .filter((key) => {
        if (Array.isArray(filters[key])) {
          return filters[key].length > 0;
        }
         return filters[key].value !== undefined;
      })
      .map((key) => {
        const fieldData = filters[key];
        if (Array.isArray(fieldData)) {
          const joinedArray = fieldData
            .map((field) => {
              return `${encodeURIComponent(key)}=${encodeURIComponent(
                field.value
              )}`;
            })
            .join('&');
          return joinedArray;
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(
          fieldData.value
        )}`;
      })
      .join('&');
    if (isAttractions) return queryString.length > 1 ? `${queryString}` : '';
    return queryString.length > 1 ? `${queryString}` : '';
  }
  return '';
};

export { studentListAdapter, studentFilterAdapter };
