import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid, Button } from '@material-ui/core';
import CompanyLogo from 'assets/img/wl.png';
import { shape, bool, element, func } from 'prop-types';

const CustomModalDialog = ({
  visible,
  handleClose,
  children,
  setMessageFalse,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(visible);
  }, [visible]);

  return (
    <Dialog
      fullWidth
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
    >
      <DialogContent>
        <Grid container justify="center" spacing={2}>
          <Grid
            item
            xs={12}
            md={12}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <img width="300" src={CompanyLogo} alt="Logo da empresa" />
          </Grid>
          <h5
            style={{
              paddingRight: 0,
              fontSize: 20,
              fontWeight: 400,
              color: '#9a9999',
            }}
          >
            {children}
          </h5>
          <div
            style={{
              display: 'inline-flex',
              flexWrap: 'wrap',
              marginBottom: '7px',
            }}
          >
            <Grid item xs={6} style={{ padding: '3px 19px 9px' }}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setShowModal(false);
                  if (handleClose) handleClose();
                  setMessageFalse(false);
                }}
                style={{
                  color: '#fff',
                  border: 'none',
                  backgroundColor: '#009291',
                  height: '43px',
                  width: '202px',
                }}
              >
                Entendi
              </Button>
            </Grid>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

CustomModalDialog.propTypes = {
  visible: bool.isRequired,
  handleClose: func.isRequired,
  children: element.isRequired,
  setMessageFalse: func.isRequired,
};

export default CustomModalDialog;
