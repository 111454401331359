import React from 'react';

import { bool, shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle';

class SuggestionLink extends React.Component {
  getEnvironmentMessage() {
    const { isStudent, classes, isCompany } = this.props;
    if (!isStudent && !isCompany) {
      return (
        <p className={classes.suggestionLink}>
          Acesse a Central de Ajuda Workalove :)
          <p>
            <a
              style={{ color: '#3cacc1' }}
              href={'https://workalove1.octadesk.com/kb'}
              target="_blank"
              rel="noreferrer"
            >
              Clique aqui.
            </a>
          </p>
        </p>
      );
    }
    return null;
  }

  render() {
    return this.getEnvironmentMessage();
  }
}

SuggestionLink.defaultProps = {
  isCompany: false,
  isStudent: false,
};

SuggestionLink.propTypes = {
  classes: shape({}).isRequired,
  isCompany: bool,
  isStudent: bool,
};

export default withStyles(sidebarStyle)(SuggestionLink);
