import React from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Cell,
  Legend,
  Tooltip,
  Text,
  ResponsiveContainer,
} from 'recharts';
import { Bubble, Ul, Li, ContainerLegend, TotalStudents } from './style';

import './strokeStyle.css';
import { formatNumber } from '../../../../../utils/formatNumber';

export default function BarChartCustom({ config }) {
  const color = [
    '#CAEAEA',
    '#8BD3D3',
    '#7EEBEB',
    '#34BBBB',
    '#009291',
    '#125F5F',
  ];

  const {
    data,
    total,
    useLegendChart,
    barSize,
    margin,
    height,
    radius,
    yOrientation,
    xKey,
    keyAccessGraphic,
  } = config;

  const CustomizedLegend = (props) => {
    const { payload } = props;

    const items =
      payload && payload[0].payload && payload[0].payload.children
        ? payload[0].payload.children
        : [];

    return (
      <ContainerLegend>
        <Ul>
          {items.map((item) => (
            <Li color={item.props && item.props.fill}>
              {item.props && item.props.legend}
            </Li>
          ))}
          <TotalStudents>{`Total de estudantes: ${total}`}</TotalStudents>
        </Ul>
      </ContainerLegend>
    );
  };

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active) {
      if (payload[0].payload.values) {
        return (
          <Bubble>
            <label>
              {payload[0].payload.values.map((item) => (
                <div>
                  <strong> {formatNumber(item.value)} </strong>
                  {item.label}
                </div>
              ))}
            </label>
          </Bubble>
        );
      }
      return (
        <Bubble>
          <label>
            <strong>{`${formatNumber(payload[0].payload.value)}`} </strong>
            {`(${payload[0].payload.percentage}%)`}
          </label>
        </Bubble>
      );
    }
    return null;
  };

  const CustomizedXAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          y={10}
          textAnchor="middle"
          verticalAnchor="middle"
          fill="#666"
          width={100}
        >
          {payload.value}
        </Text>
      </g>
    );
  };
  return (
    <ResponsiveContainer width={'100%'} height={height ? height : '90%'}>
      <BarChart
        data={data}
        margin={
          margin
            ? margin
            : {
                top: 30,
                right: 80,
                left: 20,
                bottom: 5,
              }
        }
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xKey}
          interval={0}
          width={20}
          tick={CustomizedXAxisTick}
        />

        <YAxis orientation={yOrientation} />
        <Tooltip content={CustomizedTooltip} />

        <Bar
          dataKey={keyAccessGraphic || 'percentage'}
          key={239 * 392}
          barSize={barSize ? barSize : 15}
          radius={radius ? radius : [10, 50, 0, 0]}
        >
          {data &&
            data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={color[index]}
                legend={entry.label}
              />
            ))}
        </Bar>

        {useLegendChart && (
          <Legend
            layout="vertical"
            verticalAlign="top"
            align="right"
            content={CustomizedLegend}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
