//use this function when styling with native MUI class

const useDefineColor = (color) => {
  const returnUniversityColor = {
    '#F9A400': 'yellowIES',
    '#E23C2E': 'redIES',
    '#259444': 'greenIES',
    '#0B4295': 'darkBlueIES',
    '#0641D8': 'blueIES',
    '#2D7BED': 'lightBlueIES',
    '#fb7048': 'orangeIES',

    '#009291': 'dnaColor' || 'primary',
  };

  return returnUniversityColor[color];
};

export default useDefineColor;
