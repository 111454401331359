import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonCancel,
  WrapperButtons,
  SpanInformationList,
  ContainerInformationList,
  ButtonX,
} from './styled';
import React, { Fragment, useMemo } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import { InformationIcon } from 'assets/backoffice/information';
import Tooltip from 'components/NewTooltip';

import { IoClose } from 'react-icons/io5';

export default function WallGenericDialogModal({
  config,
  closeModalFunction,
  modalOpenState,
  buttonConfirmFunction,
  buttonCancelFunction,
  universityColor,
}) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            {config?.buttonCloseX && (
              <ButtonX
                colorBg={config?.style?.colorBgButtonX}
                onClick={closeModalFunction}
              >
                <IoClose size={25} color="#4B4B4D" />
              </ButtonX>
            )}
            <WrapperTitle alignIcon={config?.style?.alignIcon}>
              <IconPersonStyled
                universityColor={universityColor}
                sizeIconPerson={config?.style?.sizeIconPerson}
                src={config.icon || IconPerson}
              />
              <BubbleDialog
                universityColor={universityColor}
                bubbleWidth={config?.style?.bubbleWidth}
              >
                <H4Styled
                  bubbleFontWeight={config?.style?.bubbleFontWeight}
                  bubbleMargin={config?.style?.bubbleMargin}
                >
                  {config.bubbleText}
                </H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            <ParagraphStyled
              textAlign={config?.style?.textAlign}
              fontWeight={config?.style?.fontWeight}
              marginTop={config?.style?.marginTop}
              fontSize={config?.style?.fontSize}
              lineHeight={config?.style?.lineHeight}
              gapParagraph={config?.style?.gapParagraph}
              maxWidth={config?.style?.maxWidth}
            >
              <p>{ReactHtmlParser(config.ParagraphText)}</p>
              <p>{ReactHtmlParser(config.TwoParagraphText)}</p>
            </ParagraphStyled>

            <ContainerInformationList>
              {config.informationList?.map((item) => (
                <li>
                  <SpanInformationList>
                    {item.text}
                    <Tooltip
                      direction={'top'}
                      isMultiLine={true}
                      minWidth={item.toolTipWidth}
                      text={item.information}
                    >
                      <InformationIcon color={'#000'} size={15} />
                    </Tooltip>
                  </SpanInformationList>
                </li>
              ))}
            </ContainerInformationList>

            <WrapperButtons marginButtons={config?.style?.marginButtons}>
              {config.showButtonCancel && (
                <ButtonCancel
                  type="button"
                  universityColor={universityColor}
                  width={config.buttonCancelWidth}
                  height={config.buttonCancelHeight}
                  onClick={() => buttonCancelFunction && buttonCancelFunction()}
                  disabled={config.loading}
                >
                  {config.buttonCancelText}
                </ButtonCancel>
              )}

              <Button
                type="button"
                universityColor={universityColor}
                width={config.buttonConfirmWidth}
                height={config.buttonConfirmHeight}
                onClick={() => buttonConfirmFunction && buttonConfirmFunction()}
                disabled={config.loading}
              >
                {config.loading && <FilterLoading />}
                {config.buttonConfirmText}
              </Button>
            </WrapperButtons>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

WallGenericDialogModal.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    iconWidth: 95,
    iconHeight: 95,
    titleWidth: 100,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    TwoParagraphText: 'Adicione uma segunda frase a descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
    loading: false,
    icon: IconPerson,
    showButtonCancel: false,
    buttonCancelText: 'CANCELAR',
  },
};
