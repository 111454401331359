import styled from 'styled-components';
import { device } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/ModalShowEvidence/utils/devicesQuery';

export const Container = styled.div`
  color: #606062;

  > p {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    margin: 10px 0;
  }

  > strong {
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin: 20px 0;
    line-height: 18.75px;
  }
`;

export const DropArea = styled.div`
  width: 100%;
  border: 1px dashed #606062;
  height: 185px;
  border-radius: 8px;
  position: relative;
  display: ${({ selectedFile }) => (selectedFile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  > input {
    width: 100%;
    height: 185px;
    opacity: 0;
    z-index: 10;
  }

  > div {
    position: absolute;
    max-width: 450px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    gap: 14px;

    > img {
      margin-bottom: 1.2em;
    }

    .circle-area {
      width: 72px;
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 200px;

      background-color: ${(props) =>
        props.disabled ? '#E5E5E5' : props.universityColor || '#009291'};
    }
  }
`;

export const SelectedWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #f8f8f8;
  height: 40px;
  padding: 26px 20px;
  border-radius: 8px;

  p {
    margin: 0;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  button {
    border: 1px solid transparent;
    background-color: transparent;
    > svg {
      cursor: pointer;
    }
  }

  @media ${device.mobileL} {
    height: 56px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export const LabelContent = styled.div`
  display: flex;
  flex-direction: row;

  > p {
    max-width: ${(props) => props.labelMaxWidth || '100%'};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ActionButton = styled.button`
  cursor: pointer;
  width: 22px;
  height: 22px;
  padding: 0;

  > img {
    width: 100%;
    height: 100%;
  }
`;
