import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

const VisualizationStudentContext = createContext();

export const VisualizationStudentProvider = ({ children }) => {
  const [listing, setListing] = useState([]);

  const [listingPaginationData, setListingPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const searchQuery = useRef('');

  const [selectedStudent, setSelectedStudent] = useState({});

  const [checkedStudents, setCheckedStudents] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const [typeChecked, setTypeChecked] = useState(null);

  const [showScanQrCodeModal, setShowScanQrCodeModal] = useState(false);

  return (
    <VisualizationStudentContext.Provider
      value={{
        listing,
        setListing,
        selectedStudent,
        setSelectedStudent,
        checkedStudents,
        setCheckedStudents,
        checkedAll,
        setCheckedAll,
        typeChecked,
        setTypeChecked,
        showScanQrCodeModal,
        setShowScanQrCodeModal,
        listingPaginationData,
        setListingPaginationData,
        currentPage,
        setCurrentPage,
        limit,
        setLimit,
        searchQuery,
      }}
    >
      {children}
    </VisualizationStudentContext.Provider>
  );
};

export const useVisualizationStudentContext = () => {
  const context = useContext(VisualizationStudentContext);
  if (!context) {
    throw new Error(
      'VisualizationStudentContext must be used within a VisualizationStudentProvider'
    );
  }
  return context;
};
