import {
  Box,
  Button,
  Modal,
  ParagraphStyled,
  RocketWrapper,
  Wrapper,
} from './style';
import React, { Fragment, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';

export default function LoadingModal({ config, modalOpenState, edit }) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper edit={edit} visible={modalOpenState}>
        <Box edit={edit}>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            <RocketWrapper>
              <span>
                <i></i>
              </span>
            </RocketWrapper>
            <h4>{config.bubbleText}</h4>
            <ParagraphStyled>
              {ReactHtmlParser(config.ParagraphText)}
            </ParagraphStyled>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

LoadingModal.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    bubbleText: 'titulo modalx',
    ParagraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
  },
};
