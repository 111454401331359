import React, { useContext } from 'react';
import { ButtonReward } from './styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

const ButtonViewReward = ({ onClick }) => {
  const { universityColor } = useContext(ProfileContext);

  return (
    <ButtonReward onClick={onClick} universityColor={universityColor}>
      Minhas conquistas
    </ButtonReward>
  );
};

export default ButtonViewReward;
