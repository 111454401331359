import styled from 'styled-components';

export const BaseContainer = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.usingPreviewPdf ? 0 : 1300)};
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 10.1rem;
  height: auto;
`;

export const Card = styled.div`
  width: 45.5rem;
  background-color: #ffff;
  border-radius: 8px;
  position: relative;
  min-height: 200px;
  text-align: initial;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 55px;

  > h3 {
    font-size: 20px;
    font-weight: 500;
    color: #009291;
  }
`;

export const ContainerButtonTab = styled.div`
  border: 1px solid #eef2f4;
`;

export const ButtonTab = styled.button`
  cursor: pointer;
  color: ${(props) => (props.active ? '#009291' : '#949494')};
  background-color: ${(props) => (props.active ? '#FFF' : '#F6F6F6')};
  font-weight: ${(props) => (props.active ? '600' : '400')};
  border: none;
  padding: 10px;
`;
export const Status = styled.span`
  background-color: ${(props) => props.background};
  font-weight: bold;
  color: ${(props) => props.color};
  padding: 5px;
  margin-left: 10px;
  font-size: 10px;
  height: 15px;
  display: flex;
  align-items: center;
`;

export const WrapperHeader = styled.div`
  display: flex;
  margin-top: 4em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2em;
`;
export const ContainerFilter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 22px;
`;

export const ContainerTitleHeader = styled.div`
  > h3 {
    font-size: 18px;
    color: #009291;
    font-weight: bold;
  }

  > p {
    margin-top: -11px;
  }
`;
