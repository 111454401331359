import FairAndEventsService from '../Services/index';

export const ConvertImageHelper = {
  convert: async (eventCover) => {
    const { file_name, file_extension, file_content } = eventCover;

    const image = await FairAndEventsService.getImageFromBase64(
      file_content,
      file_name,
      file_extension
    );
    return image;
  },
};
