import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}

  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 20000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 500px;
  height: 500px;
  background-color: #fff;
  border-radius: 8px;

  padding: 30px;
  position: relative;
`;

export const ButtonSubmit = styled.button`
  height: 38px;
  border: none;
  background-color: #009291;
  color: #fff;
  font-weight: 600;
  border-radius: 4px;
  margin-top: 30px;
  width: 180px;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3.2em;
  width: 70%;
  height: 20%;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: #009291;
  margin-left: 1.5em;
  border-radius: 4px;
  width: 147px;
  height: 49px;
  position: relative;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid #009291;
    border-top: 4.5px solid #009291;
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 12px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #009291;
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 13px;
  font-weight: bold;
  color: #ffff;
`;

export const ParagraphStyled = styled.p`
  margin-top: 30px;
  max-width: 250px;
  font-size: 15px;
`;

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width + 'px' : '290px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: #009291;
  border: none;
  border-radius: 4px;
  color: #ffff;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
`;

export const StyledP = styled.p`
  margin: 14px 0px;
  font-size: 16px;
  font-weight: 400;
`;

export const CloseButton = styled.button`
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 10px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #4b4b4d;
  font-weight: 500;
  cursor: pointer;
`;
