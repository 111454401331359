import laptopIcon from 'assets/csm/laptopIcon.svg';
import graduationIcon from 'assets/csm/graduationIcon.svg';
import briefcaseIcon from 'assets/csm/briefcaseIcon.svg';
import bullseyeIcon from 'assets/csm/bullseyeIcon.svg';
import checkIcon from 'assets/csm/checkIcon.svg';

const StudentProfileContent = (dataStudent) => {

  const studentIsActive = () => {
    if(dataStudent.is_active === true){
      return 'Usuário acessou a plataforma'
    }

    if(dataStudent.is_active === false){
      return 'Usuário não acessou a plataforma'
    }
  }

  const studentMarketAvailabilitye = () => {
    if(dataStudent.market_availability === true){
      return 'Aceita propostas'
    }

    if(dataStudent.market_availability === false){
      return 'Não aceita propostas'
    }
  }

  const studentWorkingInFormationArea = () => {
    if(dataStudent.working_in_formation_area === true){
      return 'Na área de formação'
    }

    if(dataStudent.working_in_formation_area === false){
      return 'Fora de área de formação'
    }
  }

  return [
    {
      icon: laptopIcon,
      text: studentIsActive(),
    },
    {
      icon: graduationIcon,
      text: dataStudent.status,
    },
    {
      icon: briefcaseIcon,
      text: `${dataStudent.professional_status} - ${studentWorkingInFormationArea()}`,
    },
    {
      icon: bullseyeIcon,
      text: dataStudent.career_objective,
    },
    {
      icon: checkIcon,
      text: studentMarketAvailabilitye(),
    },
  ];
};

const AdditionalProfileContent = (dataStudent) => {
  const additionalInfo = [];

  if (dataStudent.course && dataStudent.course.length > 0) {
    const courseInfo = dataStudent.course.map(course => ({
      text: `${course.course__name} - ${course.semester}º semestre`,
    }));

    additionalInfo.push(...courseInfo);
  }

  additionalInfo.push(
    {
      text: dataStudent.unity,
    },
    {
      text: `${dataStudent.city} - ${dataStudent.state}`,
    },
    {
      text: dataStudent.email,
    },
    {
      text: dataStudent.mobile_phone,
    }
  );

  return additionalInfo;
};



export { StudentProfileContent, AdditionalProfileContent };
