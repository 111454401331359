import { useRef, useEffect } from 'react';

import { useField } from '@unform/core';
import {
  ContainerLabelInputTextArea,
  UnInputLabel,
  ErrorMessage,
} from './Style/BaseInputs';

export default function SimpleTextareaInput({
  name,
  label,
  rows = 5,
  cols = 80,
  ...rest
}) {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInputTextArea>
      {label && <UnInputLabel htmlFor={fieldName}>{label}</UnInputLabel>}
      <textarea
        ref={textareaRef}
        name={fieldName}
        defaultValue={defaultValue}
        rows={rows}
        cols={cols}
        {...rest}
      />

      <ErrorMessage marginTop={error && '0px'} order={error}>
        {error}
      </ErrorMessage>
    </ContainerLabelInputTextArea>
  );
}
