import React, { useContext, useEffect, useState } from 'react';
import { getJobsOpeningsCompany } from '../../../../../services';
import Pagination from '../../../../../../../components/CustomPagination';
import JobCards from '../../../components/Cards';
import { JobOfferContext } from '../../../../../Contexts/JobOfferContext';
import SkeletonJobsCard from '../../../components/JobsSkeleton';
import { Button, Container, Content, Title } from './styled';
import { isMasterLet } from 'views/StaffPages/jobOpeningsRebuild/JobController/PublishedJobsTabs';
import GenericMessage from 'components/GenericMessage';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { MyProfileModal } from '../../../components/MyProfileModal';
import ModalInformation from 'components/informationModal';

function JobsInProgress({ countJobs, seeMatch, setOpenModalPublishJob }) {
  const {
    setDataFunctions,
    setOffset,
    currentJobFilter,
    dataFunction,
    showCompleteProfileModal,
    setShowCompleteProfileModal,
    showInformationModalMyProfile,
    setShowInformationModalMyProfile,
  } = useContext(JobOfferContext);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [listJobs, setListJobs] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const loadJobsData = async (page) => {
    const data = await getJobsOpeningsCompany(page);
    const { results, count } = data;
    setCount(count);
    setListJobs(results);
  };

  const handlePageChange = async (page) => {
    const mathOffset = (page - 1) * limit || 0;
    setOffset(mathOffset);
    const newPage = `limit=${limit}&offset=${mathOffset}`;
    const queryParams = queryParamsFromFilterObject(currentJobFilter);
    await loadJobsData(`${newPage}&${queryParams}`);
    await countJobs();
  };

  const onPageChange = (page) => {
    setListJobs(null);
    setCurrentPage(page);
    handlePageChange(page);
  };

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    setListJobs(null);
    handlePageChange(`limit=${limit}`);
  }, [limit, currentJobFilter]);

  useEffect(() => {
    setDataFunctions({ handlePageChange });
  }, []);

  return listJobs === null ? (
    <Container>
      <Content>
        <SkeletonJobsCard quantity={randomIntFromInterval(1, 4)} />
      </Content>
    </Container>
  ) : listJobs?.length > 0 ? (
    <Container>
      {showCompleteProfileModal && (
        <MyProfileModal closeModal={() => setShowCompleteProfileModal(false)} />
      )}
      <ModalInformation
        modalOpenState={showInformationModalMyProfile}
        closeModalFunction={() => setShowInformationModalMyProfile(false)}
        config={{
          ParagraphText:
            'Você pode chamar mais atenção dos estudantes em seus processos seletivos ao ter um perfil completo.',
          buttonConfirmText: 'COMPLETAR PERFIL',
          buttonCancelText: 'AGORA NÃO',
          bubbleText: 'Destaque sua vaga!',
          showButtonCancel: true,
          buttonCloseX: false,
          buttonCancelWidth: 150,
        }}
        buttonConfirmFunction={() => {
          setShowInformationModalMyProfile(false);
          setShowCompleteProfileModal(true);
        }}
        buttonCancelFunction={() => setShowInformationModalMyProfile(false)}
      />
      <Content>
        {listJobs?.map((jobs) => (
          <JobCards
            isPartnerOffer={
              jobs.is_partner_offer ||
              (isMasterLet && !jobs.is_educational_group)
            }
            jobName={jobs.name}
            openedSince={jobs.opened_since}
            responsibleName={jobs.responsible_name}
            numberOfCandidates={jobs.number_of_candidates}
            jobs={jobs}
            jobID={jobs.id}
            limit={limit}
            seeMatch={seeMatch}
            setListJobsCompany={setListJobs}
            setCount={setCount}
          />
        ))}
      </Content>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={count}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
    </Container>
  ) : (
    <Container>
      <GenericMessage title="No momento, não há nenhuma vaga disponível. Cadastre sua vaga em menos de <strong>2 minutos</strong>." />
      <Button onClick={() => setOpenModalPublishJob(true)}>
        Cadastrar vaga
      </Button>
    </Container>
  );
}

export default JobsInProgress;
