import React from 'react';
import { DivAnimatedLoading, DivLoading, ParagraphLoading } from './styles'

export default function LoadingCards() {
  return (
    <DivLoading>
      <ParagraphLoading>Carregando desafios...</ParagraphLoading>
      <DivAnimatedLoading></DivAnimatedLoading>
    </DivLoading>
  );
}
