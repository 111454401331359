import React, { useContext } from 'react';
import { shape } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

const styles = {
  cssLabel: {
    color: `#606062 !important`,
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: dnaColor,
    },
  },

  cssFocused: {},
  notchedOutline: {},
};

function CustomTextField(props) {
  const { classes } = props;

  return (
    <TextField
      InputLabelProps={{
        FormLabelClasses: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
      InputProps={{
        classes: {
          root: classes.dnaColorOutlinedInput,
          focused: classes.dnaColorLabelFocused,
          notchedOutline: classes.notchedOutline,
        },
      }}
      {...props}
    />
  );
}

CustomTextField.propTypes = { classes: shape.isRequired };

export default withStyles(styles)(CustomTextField);
