import BaseLayoutContext from 'contexts/base-layout';
import React, { useContext } from 'react';
import { Container, Button } from './styled';

function SaveButtonsTests() {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Container>
      <Button universityColor={universityColor}>SALVAR</Button>
    </Container>
  );
}

export default SaveButtonsTests;
