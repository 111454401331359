export function User({ color }) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_23816_147)">
        <path
          d="M18.1636 19.9334H16.5087V15.7605C16.508 15.1117 16.25 14.4898 15.7913 14.031C15.3325 13.5723 14.7105 13.3143 14.0618 13.3136H7.37084C6.7221 13.3143 6.10011 13.5723 5.64138 14.031C5.18265 14.4898 4.92465 15.1117 4.92399 15.7605V19.9334H3.26904V15.7605C3.27036 14.673 3.70293 13.6305 4.47188 12.8615C5.24083 12.0926 6.28338 11.66 7.37084 11.6587H14.0618C15.1493 11.66 16.1918 12.0926 16.9608 12.8615C17.7297 13.6305 18.1623 14.673 18.1636 15.7605V19.9334Z"
          fill={color}
        />
        <path
          d="M10.7161 10.0034C9.73418 10.0034 8.77428 9.71226 7.95781 9.16671C7.14135 8.62117 6.50499 7.84576 6.12921 6.93855C5.75343 6.03135 5.65511 5.03308 5.84668 4.06999C6.03825 3.1069 6.51111 2.22225 7.20546 1.5279C7.8998 0.833557 8.78446 0.360701 9.74754 0.169131C10.7106 -0.022439 11.7089 0.0758817 12.6161 0.45166C13.5233 0.827438 14.2987 1.4638 14.8443 2.28026C15.3898 3.09673 15.681 4.05663 15.681 5.03859C15.6797 6.35495 15.1562 7.61701 14.2254 8.54782C13.2946 9.47862 12.0325 10.0021 10.7161 10.0034ZM10.7161 1.72868C10.0615 1.72868 9.42157 1.92281 8.87726 2.2865C8.33294 2.6502 7.90871 3.16714 7.65819 3.77194C7.40767 4.37675 7.34212 5.04226 7.46983 5.68432C7.59755 6.32638 7.91279 6.91614 8.37568 7.37904C8.83858 7.84194 9.42835 8.15718 10.0704 8.28489C10.7125 8.41261 11.378 8.34706 11.9828 8.09654C12.5876 7.84602 13.1045 7.42178 13.4682 6.87747C13.8319 6.33316 14.026 5.69322 14.026 5.03859C14.026 4.16075 13.6773 3.31886 13.0566 2.69813C12.4359 2.0774 11.594 1.72868 10.7161 1.72868Z"
          fill={color}
        />
        <rect
          x="3.62366"
          y="18.5149"
          width="14.1853"
          height="1.41853"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_23816_147">
          <rect
            width="19.8594"
            height="19.8594"
            fill="white"
            transform="translate(0.785706 0.0737305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
