import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const selectPagination = {
  selectLabel: {
    whiteSpace: 'nowrap',
  },

  formControl: {
    width: 120,
    right: 0,
    top: 0,
  },

  selectInput: {
    minWidth: 120,
    height: 40,
    textAlign: 'center',
  },

  cssLabel: {
    '&$cssFocused': {
      color: dnaColor,
    },
  },

  cssFocused: {},
};
