import styled from 'styled-components';

export const Divider = styled.div`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0')};
  width: ${({ width }) => (width ? width : '156px')};
  height: ${({ height }) => (height ? height : '1px')};
  background-color: ${({ color }) => (color ? color : '#C4C4C4')};
`;

export const Stepper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 45px;
  position: relative;
  margin-left: 40px;
  width: 70%;
  height: 220px;
`;
export const StepStatus = {
  PENDING: 'pending',
  CURRENT: 'current',
  DONE: 'done',
  SELECTED: 'selected',
};

const stepBackgroundColors = {
  [StepStatus.PENDING]: '#E5E5E5',
  [StepStatus.CURRENT]: '#8BD3D3',
  [StepStatus.DONE]: '#8BD3D3',
  [StepStatus.SELECTED]: '#009291',
};

const stepFontColors = {
  [StepStatus.DONE]: '#FFF',
  [StepStatus.PENDING]: '#AAB4BD',
  [StepStatus.CURRENT]: '#FFF',
  [StepStatus.SELECTED]: '#FFF',
};

export const Step = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ status }) => stepBackgroundColors[status]};
  font-size: 20px;
  color: ${({ status }) => stepFontColors[status]};
  position: relative;
  cursor: ${({ status }) =>
    status === StepStatus.PENDING ? 'not-allowed' : 'pointer'};
`;

export const InformationStep = styled.div`
  position: absolute;
  bottom: -130px;
  width: 165px;
  left: 0;

  > h3 {
    font-size: 16px;
    color: #606062;
    font-weight: bold;
  }

  p {
    font-size: 13px;
    color: #606062;
  }
`;
