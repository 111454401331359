import React from 'react';
import {
  CardSkeleton,
  ContainerCardsSkeleton,
  Header,
  InformationsJob,
  ContentButtons,
  MatchSkeleton,
  ParagraphSkeleton,
  InformationSkeleton,
  ButtonSkeleton,
} from '../../NewCards/styled';

const SkeletonCards = ({ isExternalJobs }) => {
  const num = 5;

  return (
    <ContainerCardsSkeleton divHeight={isExternalJobs ? '270px' : '390px'}>
      <div>
        {[...Array(num)].map((_, index) => (
          <div>
            <CardSkeleton
              key={index}
              style={{ height: isExternalJobs ? '270px' : '390px' }}
            >
              <Header>
                {!isExternalJobs && <MatchSkeleton />}
                <ParagraphSkeleton />
                <ParagraphSkeleton
                  style={{
                    marginTop: '5px',
                    width: '145px',
                    marginBottom: '24px',
                  }}
                />
              </Header>

              <InformationsJob>
                <InformationSkeleton />
                <InformationSkeleton />
                <InformationSkeleton />
                {!isExternalJobs && <InformationSkeleton />}
              </InformationsJob>

              <ContentButtons>
                <ButtonSkeleton />
                <ButtonSkeleton />
              </ContentButtons>
            </CardSkeleton>
          </div>
        ))}
      </div>
    </ContainerCardsSkeleton>
  );
};

export default SkeletonCards;
