import React, { Fragment } from 'react';
import { Title, SubContainer, ButtonAddTag } from './style';
import RegisterTagMainForm from '../Forms/registerTagMainForm';
import { useFormContext } from '../../contexts';

export default function HeaderAcademicInnovation() {
  const { setCurrentFormToShow } = useFormContext();

  const handleClickAddTag = () => {
    setCurrentFormToShow(<RegisterTagMainForm />);
  };

  return (
    <Fragment>
      <Title>Mapeamento de tags</Title>
      <SubContainer>
        <span>
          Confira aqui todas as tags cadastradas pelas Instituições de Ensino e
          relacione-as com os requisitos e as tags principais.
        </span>
        <ButtonAddTag onClick={handleClickAddTag}>
          Cadastrar TAG principal
        </ButtonAddTag>
      </SubContainer>
    </Fragment>
  );
}
