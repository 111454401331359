import { useEffect, useState, useContext, Fragment } from 'react';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import ListItems, { SearchName } from '../../components/ListItems';
import { Header } from '../../components/ListItems';
import GenericMessage from 'components/GenericMessage';
import BaseLayoutContext from 'contexts/base-layout';
import { ContainerMessage } from '../GeneratedLists/style';
import { useDebounce } from 'hooks/useDebounce';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';

export default function CreatedCampaigns() {
  const {
    listageCampaignList,
    setListageCampaignList,
    currentPropsPagination,
    activeEmailMass,
    setActiveEmailMass,
    listageGeneratedList,
  } = useSendEmail();
  const { egressed } = useCsm();
  const { metadata } = useContext(BaseLayoutContext);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 600);

  const listPaginationProps = currentPropsPagination[1];

  const getList = async (searchTerm, isFromSearchBar) => {
    setActiveEmailMass(metadata.has_send_mass_email);

    const { limit, currentPage } = listPaginationProps;
    const offset = (currentPage - 1) * limit || 0;
    let page;
    if (isFromSearchBar && searchTerm) {
      page = `&name=${searchTerm}`;
    } else {
      page = `limit=${limit}&offset=${offset}${
        searchTerm ? `&name=${searchTerm}` : ''
      }`;
    }
    const { results, count } = await sendEmailApi.getCampaign(page, egressed);
    listPaginationProps.setCount(count);
    setListageCampaignList(results);
  };
  useEffect(() => {
    getList(searchTerm);
  }, [debouncedSearchTerm]);

  const mappedList = listageCampaignList.map((item) => (
    <ListItems informations={item} isCampaign={true} />
  ));

  const controllComponents = () => {
    if (listageGeneratedList.length === 0) {
      return (
        <>
          <SearchName isCampaign={true} getList={getList} />
          <ContainerMessage>
            <p>
              Oops! Não é possivel criar uma campanha sem a existência de listas
              geradas.
            </p>
          </ContainerMessage>
        </>
      );
    }

    if (listageGeneratedList.length > 0 && listageCampaignList.length === 0) {
      return (
        <>
          <SearchName isCampaign={true} getList={getList} />
          <ContainerMessage>
            <p>Oops! No momento, não existem campanhas criadas</p>
          </ContainerMessage>
        </>
      );
    }

    return (
      <Fragment>
        <SearchName isCampaign={true} getList={getList} />
        <Header isCampaign={true} />
        {mappedList}
      </Fragment>
    );
  };

  return (
    <div>
      {!activeEmailMass && listageCampaignList.length === 0 ? (
        <GenericMessage
          title="Oops!"
          subtitle={
            'Essa ação ainda não está liberada. Entre em contato com o seu consultor através do e-mail sucesso@workalove.com para saber mais.'
          }
        />
      ) : (
        <div>{controllComponents()}</div>
      )}
    </div>
  );
}
