import React, { useState, useContext, useRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Container, FilterContainer, TalentsAppBar } from './style';
import { TalentsFilter } from '../TalentsFilter';
import { TalentsContext } from 'components/NewTalentList/contexts/TalentListContext';
import { IndicationsFilter } from '../IndicationsFilter';

export const TalentsTabs = (props) => {
  const {
    activeTab,
    handleTabChange,
    tabs,
    filterOptions = {},
    showFilter = true,
  } = props;
  const { setTabRef } = useContext(TalentsContext);
  const tabRef = useRef();
  setTabRef(tabRef);
  return (
    <Container ref={tabRef}>
      <TalentsAppBar position="static">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          scrollButtons="on"
          scrollable
          classes={{
            indicator: 'tabIndicator',
            scrollButtons: 'scrollButtons',
            flexContainer: 'borderContainer',
          }}
        >
          {tabs.map((tabName) => (
            <Tab key={tabName} label={tabName} className="tabButton" />
          ))}
        </Tabs>

        {showFilter && (
          <FilterContainer>
            {activeTab == 0 && <TalentsFilter />}
            {activeTab == 1 && <IndicationsFilter />}
          </FilterContainer>
        )}
      </TalentsAppBar>
    </Container>
  );
};

TalentsTabs.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};
