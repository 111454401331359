import React, { useState } from 'react';
import { getInsightSpreadsheet } from 'services/university/indicators';
import { ExportButton } from '../Styles/BaseComponent';
import GenericLoading from 'components/GenericLoading';

export const InsightsExport = () => {
  const [loading, setLoading] = useState(false);

  async function handleClick() {
    setLoading(true);
    const response = await getInsightSpreadsheet();
    setLoading(false);

    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'insights.xls');
    document.body.appendChild(link);
    link.click();
  }

  return (
    <ExportButton
      onClick={() => handleClick()}
      disabled={loading}
      title={loading && 'Aguarde, carregando...'}
    >
      {loading ? <GenericLoading color="#fff" /> : 'Exportar relatório'}
    </ExportButton>
  );
};
