import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Container,
  ContainerButton,
  Content,
  GreenButton,
  WhiteButton,
  WrapperMessage,
  WrapperTitle,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { ModalFinalization } from 'views/JobOfferRebuild/Components/CompanyJobsOpenings/components/Modal/FinalizationVacancy/modalFinalization';

export default function FinishModal(props) {
  const {
    isOpen,
    setModalFinish,
    jobID,
    isPartnerOffer,
    status,
    activeTab,
    setListJobs,
    setJobsInstitution,
    universityFilters,
    setJobsCountInstitution,
    setJobsCountPartners,
    countJobs,
    setCountProgressJobs,
    dataFunction,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef();

  const closeModalClick = (e) => {
    if (modalRef.current === e.target) {
      setModalFinish(false);
    }
  };

  const closeModal = () => {
    setModalFinish(false);
    setOpenModal(false);
  };

  const handleModal = () => {
    setOpenModal(true);
  };

  return isOpen ? (
    <Container isOpen={isOpen}>
      {openModal && (
        <ModalFinalization
          university={true}
          visible={openModal}
          controlModal={setOpenModal}
          setModalFinish={setOpenModal}
          jobID={jobID}
          status={status}
          isPartnerOffer={isPartnerOffer}
          closeModal={closeModal}
          activeTab={activeTab}
          setListJobs={setListJobs}
          setJobsInstitution={setJobsInstitution}
          universityFilters={universityFilters}
          setJobsCountInstitution={setJobsCountInstitution}
          setJobsCountPartners={setJobsCountPartners}
          countJobs={countJobs}
          setCountProgressJobs={setCountProgressJobs}
          dataFunction={dataFunction}
        />
      )}
      <Box ref={modalRef} onClick={closeModalClick}>
        <Content size="variant">
          <WrapperTitle>
            <div className="border-image">
              <img src={Wall} alt="Icone Wall" />
            </div>
            <div className="bubble-dialog">
              <span> Atenção! </span>
            </div>
          </WrapperTitle>
          <WrapperMessage>
            Tem certeza que deseja finalizar a vaga?
          </WrapperMessage>
          <ContainerButton>
            <WhiteButton onClick={closeModal} size="finish">
              NÃO
            </WhiteButton>
            <GreenButton closeModal={closeModal} onClick={handleModal}>
              SIM
            </GreenButton>
          </ContainerButton>
        </Content>
      </Box>
    </Container>
  ) : null;
}
