import { useRef, useState, useEffect } from 'react';
import BlockComponentForm from '../../../blockComponentForm';
import { Form } from '@unform/web';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import { ButtonArrowRight } from '../../../buttonArrowRight';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';

export default function StepOne({ fetchingOptions, options, engineRef }) {
  const formRef = useRef(null);

  const [cities, setCities] = useState([]);
  const [loadingCities, setLoadingCities] = useState(false);
  const statesRef = useRef(0);
  const citiesRef = useRef(0);

  const {
    setFormValues,
    handleClickArrowNext,
    formData,
    formMode,
  } = useSendEmail();

  useEffect(() => {
    const baseScroll = document.querySelectorAll('#scrollMainPanel')[0];
    baseScroll.scrollTop = 0;
  }, []);

  const isViwerOnly = formMode === 'viwerOnly';

  const propertiesIsViwerOnly = {
    isDisabled: true,
    isMulti: true,
    disabled: true,
  };

  const createProperties = {
    isDisabled: fetchingOptions,
    isLoading: fetchingOptions,
    isMulti: true,
  };

  const commonProperties = isViwerOnly
    ? propertiesIsViwerOnly
    : createProperties;

  const { unities, states } = options;

  useEffect(() => {
    if (Object.keys(options).length > 0 && Object.keys(formData).length > 0) {
      formRef.current.setData({ ...formData });
    }
  }, [options, formData]);

  useEffect(() => {
    if (cities.length > 0 && formData.cities?.length > 0) {
      formRef.current.setFieldValue('cities', formData.cities);
    }
  }, [cities]);

  const citiesProps = {
    isDisabled: cities.length === 0,
    options: cities,
    isLoading: loadingCities,
  };

  useEffect(() => {
    if (cities.length === 0) {
      formRef.current.setFieldValue('cities', []);
    }
  }, [cities]);

  const handleSelectState = async (event) => {
    const currentCities = formRef?.current?.getFieldValue('cities');
    setLoadingCities(true);
    const values = event.map((item) => item.value);
    const { cities } = await sendEmailApi.getCitiesOptions(
      'inactive-options',
      values
    );
    setCities(cities);
    setLoadingCities(false);

    if (currentCities.length > 0 && event.length < statesRef.current) {
    }
    statesRef.current = event.length;
  };

  const BlocksStepOne = [
    {
      blockTitle: '',
      components: [
        {
          type: 'select',
          name: 'unities',
          label: 'Unidade/Polo',
          props: { ...commonProperties, options: unities },
        },
        {
          type: 'betweenSelect',
          globalProps: { ...commonProperties },
          inputs: [
            {
              name: 'states',
              label: 'Estado',
              props: { options: states, onChange: handleSelectState },
            },
            { name: 'cities', label: 'Cidade', props: { ...citiesProps } },
          ],
        },
      ],
    },
  ];

  const genereteBlocks = () => {
    return BlocksStepOne.map((block) => (
      <BlockComponentForm
        blockTitle={block.blockTitle}
        components={block.components}
      />
    ));
  };

  const verifyValuesInFirstForm = () => {
    engineRef.current.setErrors({});
    const listName = engineRef.current.getFieldValue('listName');
    const error = listName ? false : true;
    if (error) {
      engineRef.current.setFieldError(
        'listName',
        'O nome da lista é obrigatório'
      );
    }
    return { listName, error };
  };

  const handleSubmit = (data) => {
    const { listName, error } = verifyValuesInFirstForm();
    const isActiveForm = engineRef.current.getFieldValue('studentSitu');
    if (error) {
      return;
    }
    setFormValues({ active: isActiveForm, listName, ...data });
    handleClickArrowNext();
  };

  return (
    <div>
      <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
        {genereteBlocks()}
        <ButtonArrowRight />
      </Form>
    </div>
  );
}
