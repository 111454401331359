export const scrollRigthEducationalGroupEvents = () => {
  document.getElementById(
    'educational-group-event-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupEvents = () => {
  document.getElementById(
    'educational-group-event-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupCourses = () => {
  document.getElementById(
    'educational-group-courses-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupCourses = () => {
  document.getElementById(
    'educational-group-courses-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupPrograms = () => {
  document.getElementById(
    'educational-group-programs-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupPrograms = () => {
  document.getElementById(
    'educational-group-programs-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupAnnouncements = () => {
  document.getElementById(
    'educational-group-announcements-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupAnnouncements = () => {
  document.getElementById(
    'educational-group-announcements-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupTrails = () => {
  document.getElementById(
    'educational-group-trails-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupTrails = () => {
  document.getElementById(
    'educational-group-trails-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupEbooks = () => {
  document.getElementById(
    'educational-group-ebooks-container'
  ).scrollLeft += 318;
};

export const scrollLefEducationalGroupEbooks = () => {
  document.getElementById(
    'educational-group-ebooks-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupArticle = () => {
  document.getElementById(
    'educational-group-article-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupArticle = () => {
  document.getElementById(
    'educational-group-article-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupChallenges = () => {
  document.getElementById(
    'educational-group-challenge-container'
  ).scrollLeft += 318;
};

export const scrollLeftEducationalGroupChallenges = () => {
  document.getElementById(
    'educational-group-challenge-container'
  ).scrollLeft += -318;
};

export const scrollRigthEducationalGroupSeries = () => {
  document.getElementById(
    'educational-group-series-container'
  ).scrollLeft += 318;
};

export const scrollLefEducationalGrouptSeries = () => {
  document.getElementById(
    'educational-group-series-container'
  ).scrollLeft += -318;
};
