import React, { useState, useEffect, useRef } from 'react';
import Input from 'components/Opportunities/Inputs/Input';
import InputDesc from 'components/Opportunities/Inputs/InputDescription';
import { Title, Button } from 'components/Opportunities/styles/styleForm';
import IconChallengeCard from 'assets/img/icons/IconChallengeCard.svg';
import SearchableSelectChallenges from 'components/Challenges/Inputs/SearchableSelectChallenges';
import LinkInput from 'components/Opportunities/Inputs/LinkInput';
import { Documents } from 'views/StaffPages/Opportunities/Styles/formStyled';
import FooterFormChallenges from '../FooterFormChallenges';
import Videos from 'components/Challenges/Inputs/Videos';
import ModalGenericChallenges from 'components/Challenges/Inputs/ModalGenericChallenges';
import FilePdfInputChallenges from 'components/Challenges/Inputs/PdfInput';

import * as Yup from 'yup';

import InformationModal from '../../../../../../components/Challenges/InformationModal/index';

import { PublicationFormat } from '../../../resources/SelectedOptionsChallenge';

import { Form } from '@unform/web';
import {
  ModalTitle,
  CardImageModal,
  CardTitleModal,
  InputsModalChallenge,
  BodyStepOne,
  DescImages,
} from './styles';
import FileInputChallenge from '../../../../../../components/Challenges/Inputs/FileInputChallenge';

function StepOneChallenges(props) {
  const {
    setCurrentStep,
    formData,
    setFormData,
    setFormValues,
    currentStep,
    ScrollToTop,
    ...rest
  } = props;
  const [publicationFormat, setPublicationFormat] = useState();
  const [
    publicationFormatCurrentStep,
    setPublicationFormatCurrentStep,
  ] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [title, setTitle] = useState('Adicionar vídeos');
  const formRef = useRef();
  const topDiv = useRef();
  const topModal = useRef();

  const validateChallengeData = async (data) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Você deve informar o nome do Desafio.').max(200, 'O limite de caracteres é 200.'),
        access_link:
          data.type_access === 'access_link' &&
          Yup.string()
            .url('Insira um link válido')
            .max(250, 'O limite de caracteres é de 250.'),
      });
      const validatedData = await schema.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});
      return validatedData;
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const handlePublicationFormat = (e) => {
    const event = e.value;

    setPublicationFormat(event);

    if (event) {
      setPublicationFormatCurrentStep('')
      setPublicationFormatCurrentStep(event)
    }

    if (formData.type_access) {
      formData.type_access = null;
    }
  };

  const handleModal = (e) => {
    e.preventDefault();
    setModalOpen(!modalOpen);
  };

  const handleSubmitStepOne = async (data) => {
    validateTypeAccess(data);
    setFormValues(data);

    const nextFormStep = () => {
      setCurrentStep(2);
    };

    const validateData = await validateChallengeData(data);
    if (validateData) {
      setFormData((prevsValues) => ({
        ...prevsValues,
        ...data,
      }));
      nextFormStep();
    }
    setPublicationFormatCurrentStep(data.type_access);
    ScrollToTop();
  };

  const validateTypeAccess = (data) => {
    if (data.type_access === 'access_link' && data.access_link === '') {
      data.access_link = '';
      data.type_access = null;
    }
    if (
      data.type_access === 'attraction_document' &&
      data.attraction_document === null
    ) {
      data.access_link = '';
      data.attraction_document = null;
      data.type_access = null;
    }

    if (
      data.type_access === 'video' &&
      formData.content_pages &&
      formData.content_pages.length < 1
    ) {
      data.access_link = '';
      data.content_pages = [];
      data.type_access = null;
    }
  };

  const ScrollToTopDiv = () => {
    if (topDiv.current) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      return;
    }
  };

  const ScrollToModalTop = () => {
    if (topModal.current) {
      topModal.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (modalInformationOpen === true) {
      ScrollToModalTop();
    }
  }, [modalInformationOpen]);

  useEffect(() => {
    if (modalOpen === true) {
      ScrollToTopDiv();
      return;
    }
  }, [modalOpen]);

  useEffect(() => {
    if (formData.type_access) {
      const typeAccess = PublicationFormat.find(
        (option) => option.value === formData.type_access.value
      );
      formRef.current.setFieldValue('type_access', typeAccess);
      setPublicationFormat(formData.type_access.value);
    }

    if (formData.content_pages) {
      setTitle('Editar vídeos');
    }
  }, [formData]);

  useEffect(() => {
    setPublicationFormatCurrentStep('');
    setPublicationFormatCurrentStep(formData.type_access);
  }, [publicationFormatCurrentStep]);

  return (
    <BodyStepOne>
      <div ref={topModal} />
      <InformationModal
        isOpen={modalInformationOpen}
        type={'error'}
        setModalOpen={setModalInformationOpen}
        informationHeader={
          'Ooops! Existe uma informação pendente no formulário.'
        }
        errorMsg={'Preencha o nome do desafio para seguir em frente'}
      />
      <ModalTitle>
        <CardImageModal src={IconChallengeCard} />
        <CardTitleModal>Sobre o Desafio</CardTitleModal>
      </ModalTitle>

      <Form ref={formRef} onSubmit={handleSubmitStepOne} initialData={formData}>
        <InputsModalChallenge>
          <Input name="name" placeholder="Digite aqui..." label="Nome *" />
          <InputDesc
            name="description"
            placeholder="Digite aqui..."
            label="Descrição"
          />

          <div
            style={{
              margin: '20px 0 10px 0',
            }}
          >
            <Title
              style={{
                color: '#6d6d6d',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              Imagem de destaque
            </Title>
            <DescImages>
              Adicione uma imagem em formato JPEG ou PNG com a dimensão de 1600
              X 838
            </DescImages>
            <FileInputChallenge
              name="attraction_image"
              accept="image/png, image/jpeg"
              formData={formData}
            />
          </div>
          <Documents>
            <SearchableSelectChallenges
              name="type_access"
              onChange={handlePublicationFormat}
              options={PublicationFormat}
              label="Formato de publicação"
              menuPlacement="top"
            />

            {(publicationFormat === 'access_link' || publicationFormatCurrentStep === 'access_link') ? (
              <LinkInput
                style={{ marginTop: '20px' }}
                name="access_link"
                placeholder="Digite seu link aqui"
              />
            ) : null}

            {(publicationFormat === 'attraction_document' ||
              publicationFormatCurrentStep === 'attraction_document') ? (
              <div style={{ marginTop: '8px' }}>
                <FilePdfInputChallenges
                  name="attraction_document"
                  accept=".pdf"
                  formData={formData}
                />
              </div>
            ) : null}

            {(publicationFormat === 'video' ||
              publicationFormatCurrentStep === 'video') ? (
              <div>
                {formData.Videos && (
                  <div>
                    {formData.content_pages.content_videos.map((value) => (
                      <div>{value.name}</div>
                    ))}
                  </div>
                )}

                <Button onClick={(e) => handleModal(e)}>{title}</Button>
                <ModalGenericChallenges
                  isOpen={modalOpen}
                  setModalOpen={setModalOpen}
                >
                  <Videos
                    setModalOpen={setModalOpen}
                    formData={formData}
                    setFormValues={setFormValues}
                  />
                </ModalGenericChallenges>
              </div>
            ) : null}
          </Documents>
        </InputsModalChallenge>

        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'end',
            paddingRight: '40px',
          }}
        >
          <FooterFormChallenges
            haveNext={true}
            haveBack={false}
            onClick={() => handleSubmitStepOne()}
          />
        </div>
      </Form>
    </BodyStepOne>
  );
}

StepOneChallenges.prototype = {};

export default StepOneChallenges;
