import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  margin: 1rem 2rem;

  @media (max-width: 768px) {
    margin: 0;
    padding: 1rem;
  }
`;

export const FilterRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const WhiteCard = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 6px;
  box-shadow: 0 0 34px 0 #0000000d;
`;

export const TitleCard = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.color || '#009291'};
`;

export const MentorsGrid = styled.div`
  background-color: #fff;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MentorListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const WrapperNoPermission = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 22px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    color: #606062;

    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 400;
    font-family: Roboto, sans-serif;
    color: #606062;
    margin: 8px 0 0 0;
  }
`;
export const Row = styled.div`
  display: flex;
  margin-top: ${({ marginTop }) => marginTop || '1rem'};
  width: 100%;
  gap: 1rem;
  position: relative;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 15px;
  border-radius: 50%;
  background-color: ${({ color }) => color || '#009291'};
`;

export const StepColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  span {
    font-size: 20px;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    color: #606062;
  }

  p {
    margin: 0;
    color: #949494;
  }
`;

export const VerticalLine = styled.div`
  height: 100%;
  width: 2px;
  background-color: #cdcdcd;
  position: absolute;
  margin-left: 24px;
  margin-top: 50px;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;
