import React, { useState, useRef, useEffect } from 'react';
import ModalStudentCancelInterview from './ModalStudentCancelInterview';
import { Form } from '@unform/web';
import {
  ContainerModalInterview,
  BackgroundModals,
  HeaderModalInterview,
  ButtonClose,
  ParagraphInterviewUnsheduled,
  ParagraphInterview,
  BodyModalInterview,
  ContainerInviteInterview,
  StatusImage,
  ContainerInput,
  Button,
  ContainerButtons,
} from '../../styled';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';
import SimpleInput from 'components/UnformFields/SimpleInput';
import SimpleTextareaInput from 'components/UnformFields/SimpleTextareaInput';
import SkeletonModalsInterview from '../../GenericComponents/SkeletonCards/SkeletonModalsInterview';

export default function ModalInterviewConfirmed(props) {
  const {
    interview,
    interviewId,
    universityColor,
    handleCloseModal,
    typeInterview,
    setInterview,
    applicationId,
    setTypeInterview,
    setApplication,
    fetchListApplicationJobOffers,
  } = props;
  const [cancelInterview, setCancelInterview] = useState(false);
  const [showUnscheduledInterview, setShowUnscheduledInterview] = useState(
    true
  );
  const { iconScheduled } = wrapperIconsJobOffers();
  const interviewRef = useRef('');

  const initialValues = {
    ...interview,
    is_online: 'Online',
    in_person: 'Presencial',
  };

  const openModalCancelInterview = () => {
    setCancelInterview(true);
    setShowUnscheduledInterview(false);
  };

  const closeCancelInterview = () => {
    setCancelInterview(false);
  };

  const isPersonModal = interview.in_person === true;

  return (
    <>
      {cancelInterview && (
        <ModalStudentCancelInterview
          universityColor={universityColor}
          handleCloseModal={closeCancelInterview}
          interview={interview}
          interviewId={interviewId}
          typeInterview={typeInterview}
          setInterview={setInterview}
          applicationId={applicationId}
          setTypeInterview={setTypeInterview}
          setApplication={setApplication}
          fetchListApplicationJobOffers={fetchListApplicationJobOffers}
        />
      )}
      {showUnscheduledInterview && (
        <BackgroundModals>
          <ContainerModalInterview isPerson={isPersonModal} isInterview>
            <HeaderModalInterview universityColor={universityColor}>
              <ParagraphInterview>Detalhes da entrevista</ParagraphInterview>

              <ButtonClose onClick={handleCloseModal}>X</ButtonClose>
            </HeaderModalInterview>

            <BodyModalInterview>
              <ContainerInviteInterview status={interview.status}>
                <StatusImage src={iconScheduled} />
                <ParagraphInterviewUnsheduled
                  isInterview
                  status={interview.status}
                >
                  Dados da entrevista
                </ParagraphInterviewUnsheduled>
              </ContainerInviteInterview>

              <Form ref={interviewRef} initialData={initialValues}>
                {interview.is_online === true && (
                  <>
                    <ContainerInput>
                      <SimpleInput
                        name="is_online"
                        label="Formato da entrevista"
                        disabled
                      />
                    </ContainerInput>

                    <ContainerInput>
                      <SimpleInput name="access_link" label="Link" disabled />
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_date"
                          label="Data"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_hour"
                          label="Horário"
                          disabled
                        />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput>
                      <SimpleTextareaInput
                        name="description"
                        label="Recomendações"
                        disabled
                      />
                    </ContainerInput>
                  </>
                )}

                {interview.in_person === true && (
                  <>
                    <ContainerInput>
                      <SimpleInput
                        name="in_person"
                        label="Formato da entrevista"
                        disabled
                      />
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput name="zipCode" label="CEP" disabled />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput name="district" label="Bairro" disabled />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput
                          name="street"
                          label="Rua/Avenida"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="addressNumber"
                          label="Número"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="complement"
                          label="Complemento"
                          disabled
                        />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput name="city" label="Cidade" disabled />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput name="addressState" label="UF" disabled />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput isData>
                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_date"
                          label="Data"
                          disabled
                        />
                      </ContainerInput>

                      <ContainerInput>
                        <SimpleInput
                          name="scheduling_hour"
                          label="Horário"
                          disabled
                        />
                      </ContainerInput>
                    </ContainerInput>

                    <ContainerInput>
                      <SimpleTextareaInput
                        name="description"
                        label="Recomendações"
                        disabled
                      />
                    </ContainerInput>
                  </>
                )}

                <ContainerButtons>
                  <Button
                    universityColor={universityColor}
                    onClick={() => openModalCancelInterview()}
                    type="button"
                    isCancel
                  >
                    Cancelar entrevista
                  </Button>
                </ContainerButtons>
              </Form>
            </BodyModalInterview>
          </ContainerModalInterview>
        </BackgroundModals>
      )}
    </>
  );
}
