import React from 'react';

export const DocIcon = ({ color = '#009291' }) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3516 5.42627C10.7244 3.71848 9.10172 2.00588 7.48361 0.288464C7.398 0.193189 7.29415 0.118246 7.17898 0.0686165C7.0638 0.018987 6.93994 -0.00419202 6.81563 0.000621856C5.60494 0.00617864 4.39426 0.00395593 3.18621 0.000621856C2.97575 -0.0018336 2.76562 0.0190265 2.55928 0.0628584C1.03908 0.396266 0.00526385 1.73267 0.00210554 3.37192C-0.000701847 4.91264 -0.000701847 6.45353 0.00210554 7.99461C0.00491292 9.5357 0.00491292 11.0764 0.00210554 12.6167C0.00526385 14.5238 1.39334 15.9942 3.19621 15.9975C5.27437 16.0008 7.35219 16.0008 9.42966 15.9975C11.2357 15.9947 12.6269 14.5283 12.6296 12.6229C12.6332 10.4646 12.6295 8.30579 12.6327 6.14754C12.6368 6.01317 12.6138 5.87942 12.5654 5.7551C12.5169 5.63077 12.4441 5.5187 12.3516 5.42627ZM7.57994 2.31947C8.53585 3.32914 9.48071 4.32602 10.4403 5.33903C9.95393 5.30902 9.47019 5.39459 8.99328 5.26457C8.5931 5.1555 8.23799 4.91053 7.98269 4.56742C7.72739 4.2243 7.58605 3.80208 7.58047 3.36581C7.5752 3.01962 7.57994 2.67344 7.57994 2.31947ZM11.3657 12.6484C11.362 13.7915 10.5351 14.6622 9.45124 14.665C7.36044 14.6705 5.26929 14.6705 3.17778 14.665C2.09606 14.6616 1.27017 13.787 1.26596 12.6451C1.26017 11.0975 1.26596 9.5494 1.26596 8.00128C1.26596 6.48243 1.26596 4.96228 1.26596 3.44083C1.26596 2.19222 2.07553 1.33758 3.25727 1.33647C4.21739 1.33647 5.17752 1.33981 6.13764 1.33258C6.28135 1.33258 6.32398 1.36259 6.32135 1.52207C6.31082 2.12554 6.31451 2.72956 6.31767 3.33358C6.31767 3.77142 6.39936 4.20497 6.55808 4.60948C6.7168 5.01398 6.94944 5.38153 7.24272 5.69113C7.83502 6.31639 8.63834 6.66765 9.47598 6.66765C10.0482 6.67154 10.6203 6.6721 11.1925 6.66765C11.3199 6.66765 11.3715 6.68377 11.371 6.84047C11.3657 8.77812 11.372 10.7119 11.3657 12.6484Z"
        fill={color}
      />
      <path
        d="M8.97762 9.98088C8.90014 9.99509 8.82144 10.0007 8.74285 9.99755H6.32148C5.47927 9.99755 4.63705 10.0003 3.79852 9.99755C3.64645 9.99516 3.50029 9.93492 3.38691 9.8279C3.27352 9.72089 3.20053 9.57427 3.18133 9.415C3.16212 9.25573 3.198 9.09449 3.28238 8.9609C3.36675 8.82731 3.49395 8.73034 3.64061 8.68781C3.69218 8.67377 3.74526 8.66686 3.79852 8.66725C5.48015 8.66725 7.16177 8.66725 8.84339 8.66725C8.99685 8.66983 9.14417 8.7313 9.25783 8.84018C9.37149 8.94907 9.44371 9.09791 9.46099 9.2589C9.47827 9.41989 9.43944 9.58202 9.35174 9.71499C9.26405 9.84796 9.13349 9.94267 8.98447 9.98143L8.97762 9.98088Z"
        fill={color}
      />
      <path
        d="M9.47316 12.0064C9.46947 12.391 9.18523 12.6655 8.78307 12.666C7.14075 12.666 5.49843 12.666 3.85611 12.666C3.4429 12.666 3.15602 12.3882 3.1597 11.9925C3.16339 11.5969 3.44553 11.3346 3.84927 11.3335C4.67674 11.3335 5.5044 11.3335 6.33222 11.3335C7.14707 11.3335 7.96173 11.3335 8.77623 11.3335C9.18944 11.3335 9.47632 11.6113 9.47316 12.0064Z"
        fill={color}
      />
      <path
        d="M5.68408 6.65537C5.68519 6.74368 5.66968 6.83135 5.63844 6.91328C5.60719 6.99521 5.56084 7.06977 5.50207 7.13264C5.44331 7.1955 5.3733 7.24541 5.29611 7.27948C5.21892 7.31354 5.13608 7.33108 5.05242 7.33108C4.84186 7.33608 4.63131 7.33108 4.42076 7.33108C4.2102 7.33108 3.99965 7.33552 3.78909 7.33108C3.7026 7.33754 3.61577 7.32514 3.53401 7.29465C3.45225 7.26415 3.37732 7.21621 3.31388 7.15381C3.25043 7.09142 3.19984 7.0159 3.16525 6.93196C3.13065 6.84802 3.11279 6.75747 3.11279 6.66593C3.11279 6.57439 3.13065 6.48384 3.16525 6.3999C3.19984 6.31596 3.25043 6.24044 3.31388 6.17805C3.37732 6.11565 3.45225 6.06771 3.53401 6.03721C3.61577 6.00671 3.7026 5.99431 3.78909 6.00078C4.2102 5.99486 4.63008 5.99486 5.04874 6.00078C5.21491 5.99973 5.37479 6.06785 5.49371 6.19038C5.61264 6.31291 5.68104 6.47997 5.68408 6.65537Z"
        fill={color}
      />
    </svg>
  );
};
