import ImageDefault from 'assets/img/imageDefault';
import {
  BoxLink,
  Container,
  Info,
  ContainerImage,
  LetterCounter,
  TextImage,
  Title,
  WrapperImage,
  InputImage,
  AddImage,
  ContainerButtons,
  BoxLinkSkeleton,
  InputImageSkeleton,
} from '../Styles/BaseComponent';
import { Form } from '@unform/web';
import Input from 'components/UnformFields/inputField';
import { useEffect, useRef, useState, useCallback } from 'react';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md';
import ModalUploadEvidences from 'components/UploadEvidences';
import { DividerResponsive } from 'views/Student/Learning/components/Screens/ExtensionProject/components/Modal/MoreInformationModalStudent/style';
import { IoEyeOutline } from 'react-icons/io5';
import { PreviewModal } from '../PreviewModal';
import {
  createLoginConfig,
  deleteImageLoginConfig,
  editLoginConfig,
  getImageLoginConfig,
  getLoginConfig,
  postImageSettings,
} from 'views/StaffPages/SettingsPage/services';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import CopyLinkButton from 'components/CopyLinkButton';
import InputWithCounter from 'components/UnformFields/InputWithCounter';

export function EnrolledTab({ setNoScroll }) {
  const formRef = useRef(null);
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [controlPreviewModal, setControlPreviewModal] = useState(false);
  const [image, setImage] = useState([]);
  const [dataEnrolled, setDataEnrolled] = useState({});
  const [skeleton, setSkeleton] = useState(false);
  const [backgroundImageSkeleton, setBackgroundImageSkeleton] = useState(false);

  const url = `https://${dataEnrolled.slug}.worka.love/#/login/matriculados`;

  const handleCloseUpdateModal = () => {
    setOpenModalUpload(false);
    getSettingsEnrolled('enrolled');
    setNoScroll(false);
  };

  const getSettingsEnrolled = useCallback(async () => {
    const response = await getLoginConfig('enrolled');
    setDataEnrolled(response);
    return response;
  }, []);

  const handleSubmitForm = async (
    { login_hint, password_hint },
    firstAccess = false
  ) => {
    let data = {
      login_type: 'enrolled',
      login_hint,
      password_hint,
    };

    if (firstAccess) {
      data = {
        login_type: 'enrolled',
      };
    }

    if (dataEnrolled.id) {
      data = {
        login_hint,
        password_hint,
      };
    }

    let response;

    try {
      if (dataEnrolled.id) {
        response = await editLoginConfig(data, 'enrolled');
        customSnackbar('Configurações salvas com sucesso!', 'confirmation');
      } else {
        response = await createLoginConfig(data);
      }

      setDataEnrolled(response);
      return response;
    } catch (error) {
      customSnackbar('Ocorreu um erro ao enviar o formulário:', 'error');
    }
  };

  const handleChangeUploadModal = async (id) => {
    setOpenModalUpload(true);
    let firstAccessId = id;

    if (!firstAccessId) {
      const response = await handleSubmitForm(_, true);
      firstAccessId = response.id;
    }
    if (firstAccessId && !id) {
      await getImageLoginConfig(firstAccessId);
    }

    if (id) {
      try {
        const response = await getImageLoginConfig(id);
        if (response && response !== undefined && response.image !== null) {
          setImage([response]);
        }
      } catch (err) {
        setImage([]);
        customSnackbar(
          `Ops! Houve um problema ao carregar a imagem.
          Tente novamente mais tarde!`,
          'error'
        );
      }
    }
  };

  useEffect(() => {
    getSettingsEnrolled();
  }, []);

  useEffect(() => {
    const timeoutSkeleton = setTimeout(() => {
      setSkeleton(true);
      setBackgroundImageSkeleton(true);
    }, 400);

    return () => clearTimeout(timeoutSkeleton);
  }, []);

  const returnBoxLink = () => {
    if (!skeleton) {
      return <BoxLinkSkeleton />;
    }

    return (
      <BoxLink>
        <strong> Link de acesso: </strong>
        <span>{url}</span>
        <CopyLinkButton textToCopy={url} tooltipMessage="Copiar Link" />
      </BoxLink>
    );
  };

  const returnBackgroundImage = (image) => {
    if (!backgroundImageSkeleton && image?.file) {
      return <InputImageSkeleton />;
    }

    if (!image) {
      return (
        <InputImage>
          <ImageDefault />
        </InputImage>
      );
    }

    return <img src={image.file} />;
  };

  return (
    <Container>
      <ModalUploadEvidences
        open={openModalUpload}
        evidenceFiles={image ?? []}
        evidenceLinks={[]}
        acceptFormats={['file']}
        acceptFileTypes={['PNG', 'JPEG']}
        functionCloseAndUpdate={handleCloseUpdateModal}
        headerTitle={'Adicionar Imagem'}
        buttonLabel={'SALVAR'}
        limitUpload={1}
        idPost={dataEnrolled.id}
        functionPostFile={postImageSettings}
        functionDeleteEvidence={deleteImageLoginConfig}
        uniqueUploadType={'file'}
        nameTypeDoc={false}
        ctaDownloadPreview={false}
        limitSizeDocument={2097152}
        messageLimitSize={'O tamanho máximo da imagem é de 2MB.'}
        closeUpOnly={true}
      />
      <PreviewModal
        control={controlPreviewModal}
        data={dataEnrolled}
        updatedData={formRef.current?.getData()}
        close={() => {
          setControlPreviewModal(false);
          setNoScroll(false);
        }}
      />
      {returnBoxLink()}
      <Info>
        Adicione orientações claras abaixo dos campos de acesso e senha para que
        os <strong> estudantes matriculados </strong> compreendam facilmente
        como acessar a plataforma.
      </Info>
      <Form
        ref={formRef}
        onSubmit={handleSubmitForm}
        initialData={dataEnrolled}
      >
        <Title> Usuário </Title>
        <Info>Informe como o estudante deverá inserir o nome de usuário.</Info>
        <InputWithCounter
          name="login_hint"
          placeholder="Exemplo: Insira seu registro acadêmico"
          maxLength={30}
          useCount={true}
        />
        <Title> Senha </Title>
        <Info>
          Informe qual é a senha padrão utilizada para realizar o acesso. Essa
          informação aparecerá para o estudante caso ele tente fazer o acesso e
          informe a senha incorreta.
        </Info>
        <InputWithCounter
          name="password_hint"
          placeholder="Exemplo: Data de nascimento invertida"
          maxLength={30}
          useCount={true}
        />
        <WrapperImage>
          <TextImage>
            <Title> Imagem da tela de acesso</Title>
            <Info marginTop="13px">
              Escolha uma imagem que represente a sua Instituição para
              apresenta-lá na tela de acesso dos seus estudantes.
            </Info>
          </TextImage>
          <ContainerImage>
            {returnBackgroundImage(dataEnrolled.image)}

            <AddImage
              type="button"
              onClick={() => {
                handleChangeUploadModal(dataEnrolled.id);
                setNoScroll(true);
              }}
            >
              <MdOutlineAddPhotoAlternate size={23} />
              {dataEnrolled.image?.file
                ? 'Substituir imagem'
                : 'Adicionar Imagem'}
            </AddImage>
          </ContainerImage>
        </WrapperImage>
        <DividerResponsive />
        <ContainerButtons>
          <button
            type="button"
            className="preview-button"
            onClick={() => {
              setControlPreviewModal(true);
              setNoScroll(true);
            }}
          >
            <IoEyeOutline size={23} />
            Pré-visualizar
          </button>
          <button type="submit" className="save-button">
            Salvar ajustes
          </button>
        </ContainerButtons>
      </Form>
    </Container>
  );
}
