import Tooltip from 'components/NewTooltip';
import { RiInformationLine } from 'react-icons/ri';
import { LegendBox } from '../../Styles/StyleSections';

export function LegendJourney(params) {
  return (
    <LegendBox>
      <div className="item">
        <span>
          <strong> Nível 3:</strong> Perfil Campeão{' '}
          <Tooltip
            text={`
              Aqui estão os estudantes que realizaram todas 
              as tarefas para alcançar o nível 3`
            }
            colorSchema="black"
            direction="top"
            minWidth="211px"
            isMultiLine
          >
            <RiInformationLine />
          </Tooltip>
        </span>
      </div>
      <div className="item">
        <span>
          <strong> Nível 2:</strong> Jornada do Protagonismo{' '}
          <Tooltip
            text={`
              Aqui estão os estudantes que realizaram todas 
              as tarefas para alcançar o nível 2`
            }
            colorSchema="black"
            direction="top"
            minWidth="211px"
            isMultiLine
          >
            <RiInformationLine />
          </Tooltip>
        </span>
      </div>
      <div className="item">
        <span>
          <strong> Nível 1:</strong> Jornada do Autoconhecimento{' '}
          <Tooltip
            text={`
              Aqui estão os estudantes que acabaram 
              de iniciar sua jornada no sistema`
            }
            colorSchema="black"
            direction="top"
            minWidth="211px"
            isMultiLine
          >
            <RiInformationLine />
          </Tooltip>
        </span>
      </div>
    </LegendBox>
  );
}
