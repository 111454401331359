import styled from 'styled-components';
import search from 'assets/academicModeling/search.svg';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 42px 80px;
  margin-top: 70px;
`;

export const WhiteCard = styled.div`
  width: 1024px;
  min-height: 500px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const GreenButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #009291;
  color: #fff;
  border-radius: 4px;
  min-width: ${(props) => (props.width ? props.width : '120px')};
  height: 35px;
`;
export const ContainerHeaderFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
  padding: 35px 45px;
`;

export const SearchInput = styled.input`
  padding-left: 10px;
  padding-right: 35px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 215px 9px;
  background-size: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 35px;
  width: 250px;
`;

export const WrapperLoadmore = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
`;

export const ContainerBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  ${(props) => (props.margin ? `margin-top: 40px` : '')}
`;

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
