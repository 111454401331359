import React, { useRef, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { useField } from '@unform/core';

const StyledSelect = styled(Select)`
  .react-select__menu {
    z-index: 999;
  }
`;

function SearchableLanguage({
  label,
  name,
  options,
  placeholder,
  isEdit,
  dataLanguage,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (!ref.state.value) {
          return '';
        } else if (ref.state.value.value) {
          return ref.state.value.value;
        }
        return ref.state.value;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Fragment>
      <StyledSelect
        getOptionLabel={(option) => option.display_name}
        defaultValue={dataLanguage && dataLanguage.language.value}
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        value={options.id}
        isDisabled={isEdit}
        {...rest}
      />
      <p style={{ color: '#f00', marginTop: '10px' }}>{error}</p>
    </Fragment>
  );
}

export default SearchableLanguage;
