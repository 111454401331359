import { useEffect, useContext } from 'react';

import AuthContext from 'contexts/authentication';

export const InternshipLogout = () => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    auth.routerOnUserLogout();
  }, []);

  return null;
};
