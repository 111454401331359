import styled, { keyframes } from 'styled-components';
const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 25rem;
  min-width: 16rem;
  max-height: 25rem;
  max-width: 16rem;
  background-color: transparent;
  border-radius: 10px;
  border: none;
  width: 278px;
  background-color: #f0f0f0;
  height: 181px;
`;

export const DivSkeleton = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -57px;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: transparent;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`;
export const FragmentColor = styled.div`
  width: 100%;
  border-radius: 8px;
  min-height: 80px;
  background: linear-gradient(to bottom, ${(props) => props.color}, white);
`;
