export function IconEmail() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47748)">
        <path
          d="M8.99981 0.265625C6.61365 0.268206 4.32597 1.21725 2.6387 2.90451C0.951429 4.59178 0.00238875 6.87947 -0.000192064 9.26562C-0.0946921 16.4454 8.36906 20.8374 14.1748 16.6284C14.2582 16.5729 14.3297 16.5012 14.385 16.4177C14.4403 16.3341 14.4782 16.2403 14.4966 16.1418C14.5151 16.0433 14.5136 15.9421 14.4922 15.8442C14.4709 15.7463 14.4301 15.6536 14.3724 15.5718C14.3147 15.4899 14.2411 15.4204 14.1561 15.3674C14.071 15.3144 13.9762 15.279 13.8773 15.2632C13.7783 15.2475 13.6772 15.2517 13.5799 15.2757C13.4826 15.2997 13.3911 15.3429 13.3108 15.4029C8.47481 18.9076 1.42481 15.2491 1.49981 9.26562C1.91156 -0.683875 16.0896 -0.681625 16.4998 9.26562V10.7656C16.4998 11.1634 16.3418 11.545 16.0605 11.8263C15.7792 12.1076 15.3976 12.2656 14.9998 12.2656C14.602 12.2656 14.2205 12.1076 13.9391 11.8263C13.6578 11.545 13.4998 11.1634 13.4998 10.7656V9.26562C13.3108 3.31888 4.68806 3.31962 4.49981 9.26562C4.50854 10.1776 4.7934 11.0656 5.31685 11.8124C5.84029 12.5593 6.57776 13.1301 7.43205 13.4494C8.28634 13.7688 9.21735 13.8219 10.1024 13.6016C10.9874 13.3813 11.7849 12.898 12.3898 12.2154C12.7152 12.7896 13.2208 13.2405 13.8284 13.4982C14.4359 13.756 15.1116 13.8062 15.7506 13.6411C16.3896 13.4759 16.9563 13.1047 17.3629 12.5848C17.7695 12.065 17.9934 11.4256 17.9998 10.7656V9.26562C17.9972 6.87947 17.0482 4.59178 15.3609 2.90451C13.6737 1.21725 11.386 0.268206 8.99981 0.265625ZM8.99981 12.2656C8.20416 12.2656 7.4411 11.9496 6.87849 11.3869C6.31588 10.8243 5.99981 10.0613 5.99981 9.26562C5.99981 8.46998 6.31588 7.70691 6.87849 7.1443C7.4411 6.5817 8.20416 6.26562 8.99981 6.26562C9.79546 6.26562 10.5585 6.5817 11.1211 7.1443C11.6837 7.70691 11.9998 8.46998 11.9998 9.26562C11.9998 10.0613 11.6837 10.8243 11.1211 11.3869C10.5585 11.9496 9.79546 12.2656 8.99981 12.2656Z"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47748">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.265625)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
