import React, { Fragment, useContext, useEffect, useState } from 'react';
import imgBackground from 'assets/JobOffer/bg-vagas.svg';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import PrincipalModal from '../components/principalModal';
import JobOfferPublicationTypeModal from '../components/publicationTypeModal';
import { JobOfferContext } from '../context/jobOfferContext';
import EngineSteps from '../engineSteps';
import { ButtonNewPublish, Container } from './style';
import PublishedJobsTabs from './PublishedJobsTabs';
import { getJobsCount } from '../services';
import LoadingModal from '../components/loadingModal';
import HistoryJobsTabs from './HistoryJobsTabs';
import { formatNumber } from '../../../../utils/formatNumber';
import { IModalConfigLoading } from 'views/JobOfferRebuild/Components/CompanyJobsOpenings/useCases/IModalConfig';
import IndicationJobsTabs from './IndicationJobsTabs';

export default function JobController({ role }) {
  const [activeTab, setActiveTab] = useState(0);
  const [openModalPublicationType, setOpenModalPublicationType] = useState(
    false
  );
  const [openModalPublishJob, setOpenModalPublishJob] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const {
    setFormValues,
    setDataFunctions,
    zIndex,
    setZIndex,
    isMaster,
  } = useContext(JobOfferContext);

  const [countHistoryInstitution, setCountHistoryInstitution] = useState(0);
  const [countHistoryPartners, setCountHistoryPartners] = useState(0);

  const [countMyJobs, setCountMyJobs] = useState(0);
  const [countJobsPartners, setCountJobsPartners] = useState(0);
  const [countIndication, setCountIndication] = useState(0);

  const [sumJobs, setSumJobs] = useState(0);
  const [sumHistory, setSumHistory] = useState(0);

  const countJobs = async () => {
    const data = await getJobsCount();
    const {
      jobs,
      jobs_partner,
      jobs_sum,
      jobs_history,
      jobs_history_partner,
      jobs_history_sum,
      educational_group_jobs,
      educational_group_jobs_history,
      educational_group_universities_jobs,
      educational_group_universities_jobs_history,
      indications,
    } = data;

    setSumHistory(jobs_history_sum);
    setCountHistoryInstitution(
      isMaster ? educational_group_jobs_history : jobs_history
    );
    setCountHistoryPartners(
      isMaster
        ? educational_group_universities_jobs_history
        : jobs_history_partner
    );

    setSumJobs(jobs_sum);
    setCountMyJobs(isMaster ? educational_group_jobs : jobs);
    setCountJobsPartners(
      isMaster ? educational_group_universities_jobs : jobs_partner
    );

    setCountIndication(indications);
  };

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <PublishedJobsTabs
          countJobs={countJobs}
          countMyJobs={countMyJobs}
          countJobsPartners={countJobsPartners}
          role={role}
          setCountMyJobs={setCountMyJobs}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <HistoryJobsTabs
          countJobs={countJobs}
          countHistoryPartners={countHistoryPartners}
          countHistoryInstitution={countHistoryInstitution}
          role={role}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <IndicationJobsTabs
          countJobs={countJobs}
          countHistoryPartners={countHistoryPartners}
          countHistoryInstitution={countHistoryInstitution}
          role={role}
        />
      ),
    },
  ];

  useEffect(() => {
    countJobs();
  }, []);

  const handleTabChange = (event, value) => {
    setActiveTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  const newJobOfferButton = () => {
    return (
      <ButtonNewPublish onClick={() => setOpenModalPublicationType(true)}>
        CADASTRAR NOVA VAGA
      </ButtonNewPublish>
    );
  };

  const handleOpenPublishModal = () => {
    return setOpenModalPublishJob(true);
  };

  useEffect(() => {
    setDataFunctions({
      handleOpenPublishModal,
      setLoadingModal,
      setOpenModalPublishJob,
    });
  }, []);

  return (
    <Fragment>
      <Container>
        <CompanyHeader
          title="Vagas"
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          backgroundImage={imgBackground}
          tabs={
            role !== 'company'
              ? isMaster
                ? [
                    `Vagas Publicadas (${formatNumber(
                      countMyJobs + countJobsPartners
                    )})`,
                    `Histórico de Vagas (${formatNumber(
                      countHistoryInstitution + countHistoryPartners
                    )})`,
                  ]
                : [
                    `Vagas Publicadas (${formatNumber(
                      countMyJobs + countJobsPartners
                    )})`,
                    `Histórico de Vagas (${formatNumber(
                      countHistoryInstitution + countHistoryPartners
                    )})`,
                    `Minhas indicações (${formatNumber(countIndication)})`,
                  ]
              : [
                  `Vagas Publicadas (${formatNumber(countMyJobs)})`,
                  `Histórico de Vagas (${formatNumber(
                    countHistoryInstitution
                  )})`,
                ]
          }
          headerElements={newJobOfferButton}
        />
        {renderPrincipalTabs(activeTab)}
        <JobOfferPublicationTypeModal
          openModal={openModalPublicationType}
          setOpenModal={setOpenModalPublicationType}
          setOpenPublishJobModal={setOpenModalPublishJob}
          openModalPublishJob={openModalPublishJob}
        />
      </Container>
      <LoadingModal
        modalOpenState={loadingModal}
        config={IModalConfigLoading}
      />
      <PrincipalModal
        zIndex={zIndex}
        openModal={openModalPublishJob}
        closeModal={() => setOpenModalPublishJob(false)}
      >
        <EngineSteps />
      </PrincipalModal>
    </Fragment>
  );
}
