const brDate = (date) => {
  const splitted = date.split('-');
  return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
};

const extraCoursesAdapter = ({
  area,
  id,
  level,
  institution,
  course,
  startedAt,
  endedAt,
  status,
  workload,
  acquiredKnowledges,
  description,
  analysisRequest,
}) => {
  const extraCourses = {
    area: area ? area.value : '',
    id,
    level: level.value,
    institution,
    course,
    started_at: brDate(startedAt),
    ended_at: status.value === 'finished' ? brDate(endedAt) : null,
    status: status.value,
    workload,
    acquired_knowledges: acquiredKnowledges
      ? acquiredKnowledges.map((item) => item.value)
      : [],
    description,
    analysis_request: analysisRequest,
  };

  return extraCourses;
};

export default extraCoursesAdapter;
