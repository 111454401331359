import React from 'react';

import { Container } from './styled';
import { node, string } from 'prop-types';

export default function FeedbackContent({ icon, text, color }) {
  return (
    <Container color={color}>
      {icon} {text}
    </Container>
  );
}

FeedbackContent.prototype = {
  icon: node,
  text: string,
  color: string,
};
