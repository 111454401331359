import React, { useContext, useEffect, useRef } from 'react';
import { OportunitiesContext } from '../Context/OportunitiesContext';
import {
  ModalBackground,
  ModalContainer,
  ModalOutside,
  ModalX,
} from '../Styles/Styled';

export default function Modal() {
  const {
    modalIsOpen,
    setModalIsOpen,
    modalContent,
    setStep,
    step,
    setOptionsId,
    setSelectOption,
    setFormData,
  } = useContext(OportunitiesContext);

  const outsideModalRef = useRef();

  const topModal = useRef(null);

  useEffect(() => {
    if (modalIsOpen === false) {
      setFormData({});
      setSelectOption('');
      setOptionsId('');
      setStep(0);
    }
  }, [modalIsOpen]);

  const ScrollToModalTop = () => {
    if (topModal.current) {
      topModal.current.scrollIntoView({ block: 'start', inline: 'nearest' });
    } else {
      return;
    }
  };

  useEffect(() => {
    ScrollToModalTop();
  }, [step]);

  return (
    <ModalOutside ref={outsideModalRef} isOpen={modalIsOpen}>
      <ModalBackground isOpen={modalIsOpen}>
        <ModalContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              textAlign: 'center',
            }}
          >
            <ModalX ref={topModal} onClick={() => setModalIsOpen(false)} />
          </div>
          {modalContent}
        </ModalContainer>
      </ModalBackground>
    </ModalOutside>
  );
}
