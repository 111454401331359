import styled from 'styled-components';

export const Span = styled.span`
font-weight: bold;
color: #606062;
}
`;

export const CountLists = styled.span`
  position: absolute;
  margin-top: -55px;
  margin-left: 4px;
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 77px;

  > p {
    text-decoration: underline;
    font-weight: bold;
    color: #009291;
    cursor: pointer;
  }
`;
