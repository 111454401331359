import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  ArrowButton,
  Container,
  Content,
  Divider,
  ListPublishedProjects,
  SubTitle,
  Title,
  Wrapper,
} from './style';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import {
  scrollRigthUpcomingActivities,
  scrollLeftUpcomingActivities,
  scrollRigthNotBeenSelected,
  scrollLeftNotBeenSelected,
  scrollRigthClosedProjects,
  scrollLeftClosedProjects,
  scrollRigthAwaitEvidence,
  scrollLeftAwaitEvidence,
} from './utils/logicScroll';
import { ExtensionProjectsStudentContext } from 'views/Student/Learning/Context/ExtensionProjectStudentContext';
import PublishedCarousel from './components/Caroussel';
import GenericMessage from 'components/GenericMessage';
import BaseLayoutContext from 'contexts/base-layout';

export default function ListSubscribeProject() {
  const {
    enrolledExtensionProject,
    notSelectedExtensionProject,
    finishedExtensionProject,
    setPgOneCurrentPage,
    setPgTwoCurrentPage,
    setPgThreeCurrentPage,
    setPgFourCurrentPage,
    pgFourCurrentPage,
    awaitEvidencesProjects,
    pgOneCurrentPage,
    pgOneCount,
    pgTwoCount,
    pgThreeCount,
    setCurrentTab,
    currentTab,
    pgFourCount,
    imagesEnrolled,
    extensionProjectId,
    setExtensionProjectId,
    setEnrolledExtensionProject,
    setAwaitEvidencesProject,
    getWatingEvidencesEndpoint,
  } = useContext(ExtensionProjectsStudentContext);

  const {universityColor} = useContext(BaseLayoutContext)

  const enrolledProjects = true;
  const notSelectedProjects = true;
  const finishedProjects = true;

  const enrolledProjectsRef = useRef();
  const notSelectedProjectsRef = useRef();
  const finishedProjectsRef = useRef();
  const awaitEvidenceProjectsRef = useRef();

  useEffect(() => {
    setEnrolledExtensionProject([]);
    setAwaitEvidencesProject([]);
  }, []);

  useEffect(() => {
    setPgOneCurrentPage(0);
    setPgTwoCurrentPage(0);
    setPgThreeCurrentPage(0);
    setPgFourCurrentPage(0);
  }, [currentTab]);

  const newImagesArray = imagesEnrolled.flat(Infinity);

  useEffect(() => {
    if (enrolledExtensionProject !== null) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (enrolledExtensionProject.length > 7) {
            setPgOneCurrentPage(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (enrolledExtensionProject.length === pgOneCount) {
        return () => intersectionObserver.disconnect();
      }

      if (enrolledExtensionProject.length > 0) {
        intersectionObserver.observe(enrolledProjectsRef.current);
      }
    }
  }, [enrolledExtensionProject, pgOneCount]);

  useEffect(() => {
    if (notSelectedExtensionProject !== null) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (notSelectedExtensionProject.length > 7) {
            setPgTwoCurrentPage(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (notSelectedExtensionProject.length === pgTwoCount) {
        return () => intersectionObserver.disconnect();
      }

      if (notSelectedExtensionProject.length > 0) {
        intersectionObserver.observe(notSelectedProjectsRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (awaitEvidencesProjects !== null) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (awaitEvidencesProjects.length === 0) {
          intersectionObserver.disconnect();
        }
        if (entries.some((entry) => entry.isIntersecting)) {
          if (awaitEvidencesProjects.length > 7) {
            intersectionObserver.disconnect();
            if (awaitEvidencesProjects.length < pgFourCount) {
              setPgFourCurrentPage(
                (currentPageInsideState) => currentPageInsideState + 8
              );
              getWatingEvidencesEndpoint(false, pgFourCurrentPage + 8);
              return;
            }
          }
        }
      });

      if (awaitEvidencesProjects.length === pgFourCount) {
        return intersectionObserver.disconnect();
      }

      if (
        awaitEvidencesProjects.length > 0 &&
        awaitEvidenceProjectsRef.current
      ) {
        intersectionObserver.observe(awaitEvidenceProjectsRef.current);
      }
    }
  }, [pgFourCount, awaitEvidencesProjects, awaitEvidenceProjectsRef]);

  useEffect(() => {
    if (finishedExtensionProject !== null) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (finishedExtensionProject.length > 7) {
            setPgThreeCurrentPage(
              (currentPageInsideState) => currentPageInsideState + 8
            );
          }
        }
      });

      if (finishedExtensionProject.length === pgThreeCount) {
        return () => intersectionObserver.disconnect();
      }

      if (finishedExtensionProject.length > 0) {
        intersectionObserver.observe(finishedProjectsRef.current);
      }
    }
  }, []);

  const viewProjects =
    enrolledExtensionProject !== null &&
    enrolledExtensionProject.length === 0 &&
    notSelectedExtensionProject !== null &&
    notSelectedExtensionProject.length === 0 &&
    finishedExtensionProject !== null &&
    finishedExtensionProject.length === 0 &&
    awaitEvidencesProjects !== null &&
    awaitEvidencesProjects.length === 0;

  return (
    <Container viewProjects={viewProjects}>
      {viewProjects ? (
        <GenericMessage
          title="Nenhum Projeto de Extensão encontrado"
          subtitle=" Oops! Você ainda não se inscreveu em nenhum projeto !"
          buttonLabel="Acessar Projetos de Extensão"
          buttonFunc={() => setCurrentTab(0)}
        />
      ) : (
        <Content>
          {awaitEvidencesProjects !== null &&
            awaitEvidencesProjects.length > 0 && (
              <div style={{ flexDirection: 'column' }}>
                <Wrapper>
                  <Title 
                    marginTop="14px"
                    universityColor={universityColor}
                  >
                    Aguardando evidências
                  </Title>
                  <SubTitle>
                    Aqui você pode ver as atividades de extensão que já
                    aconteceram e agora necessitam da adição de evidências.
                  </SubTitle>
                  <ListPublishedProjects id="await-evidences">
                    <PublishedCarousel
                      currentList={4}
                      images={newImagesArray}
                      awaitEvidences={true}
                      array={awaitEvidencesProjects}
                      extensionProjectId={extensionProjectId}
                      setExtensionProjectId={setExtensionProjectId}
                    />
                    <li ref={awaitEvidenceProjectsRef} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton
                      universityColor={universityColor}
                      onClick={scrollLeftAwaitEvidence}
                    >
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton 
                      universityColor={universityColor}
                      onClick={scrollRigthAwaitEvidence}
                    >
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Wrapper>
                <Divider></Divider>
              </div>
            )}
          {enrolledExtensionProject !== null &&
            enrolledExtensionProject.length > 0 && (
              <div style={{ flexDirection: 'column' }}>
                <Wrapper>
                  <Title 
                    marginTop="14px"
                    universityColor={universityColor}
                  >
                    Próximas atividades
                  </Title>
                  <SubTitle>
                    Aqui você pode ver as atividades de extensão que você se
                    candidatou e irá participar! Fique atento a data e ao
                    horário da ação.
                  </SubTitle>
                  <ListPublishedProjects id="upcoming-activities">
                    <PublishedCarousel
                      currentList={0}
                      images={newImagesArray}
                      enrolledProjects={enrolledProjects}
                      array={enrolledExtensionProject}
                      extensionProjectId={extensionProjectId}
                      setExtensionProjectId={setExtensionProjectId}
                    />
                    <li ref={enrolledProjectsRef} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton 
                      universityColor={universityColor}
                      onClick={scrollLeftUpcomingActivities}
                    >
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton 
                      universityColor={universityColor} 
                      onClick={scrollRigthUpcomingActivities}
                    >
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Wrapper>
                <Divider></Divider>
              </div>
            )}
          {notSelectedExtensionProject &&
            notSelectedExtensionProject.length > 0 && (
              <div style={{ flexDirection: 'column' }}>
                <Wrapper>
                  <Title 
                    marginTop="14px"
                    universityColor={universityColor}
                  >
                    Você não foi selecionado
                  </Title>
                  <SubTitle>
                    Não foi dessa vez! Aqui você pode ver os projetos nos quais
                    você não foi selecionado para participar pela sua
                    Instituição de Ensino.
                  </SubTitle>
                  <ListPublishedProjects id="not-been-selected">
                    <PublishedCarousel
                      currentList={1}
                      images={newImagesArray}
                      notSelectedProjects={notSelectedProjects}
                      array={notSelectedExtensionProject}
                      extensionProjectId={extensionProjectId}
                      setExtensionProjectId={setExtensionProjectId}
                    />
                    <li ref={notSelectedProjectsRef} />
                  </ListPublishedProjects>

                  <div className="scroll-buttons">
                    <ArrowButton 
                      universityColor={universityColor} 
                      onClick={scrollLeftNotBeenSelected}
                    >
                      <IoIosArrowBack size={25} color="#ffffff" />
                    </ArrowButton>
                    <ArrowButton 
                      universityColor={universityColor}
                      onClick={scrollRigthNotBeenSelected}
                    >
                      <IoIosArrowForward size={25} color="#ffffff" />
                    </ArrowButton>
                  </div>
                </Wrapper>
                <Divider></Divider>
              </div>
            )}
          {finishedExtensionProject && finishedExtensionProject.length > 0 && (
            <div style={{ flexDirection: 'column' }}>
              <Wrapper>
                <Title 
                  marginTop="14px"
                  universityColor={universityColor}
                >
                  Projetos encerrados
                </Title>
                <SubTitle>
                  Aqui você pode ver os projetos que foram encerrados por sua
                  instituição de ensino, seja por terem sidos despublicados ou
                  por já terem sido realizados.
                </SubTitle>
                <ListPublishedProjects id="closed-projects">
                  <PublishedCarousel
                    currentList={2}
                    images={newImagesArray}
                    finishedProjects={finishedProjects}
                    array={finishedExtensionProject}
                    extensionProjectId={extensionProjectId}
                    setExtensionProjectId={setExtensionProjectId}
                  />
                  <li ref={finishedProjectsRef} />
                </ListPublishedProjects>

                <div className="scroll-buttons">
                  <ArrowButton 
                    universityColor={universityColor}
                    onClick={scrollLeftClosedProjects}
                  >
                    <IoIosArrowBack size={25} color="#ffffff" />
                  </ArrowButton>
                  <ArrowButton 
                    universityColor={universityColor}
                    onClick={scrollRigthClosedProjects}
                  >
                    <IoIosArrowForward size={25} color="#ffffff" />
                  </ArrowButton>
                </div>
              </Wrapper>
            </div>
          )}
        </Content>
      )}
    </Container>
  );
}
