import styled from 'styled-components';
import nextIcon from 'assets/mentorship/next.svg';
import previousIcon from 'assets/mentorship/previous.svg';

export const ActionButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props) =>
    props.isNext ? `url(${nextIcon})` : `url(${previousIcon})`};
  background-repeat: no-repeat;
  background-position: center;
  height: 32px;
  width: 22px;
  background-size: contain;
  box-sizing: border-box;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;
