import styled from 'styled-components';

export const FilterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  > div {
    display: flex;
    gap: 1rem;
  }
`;

export const SpanError = styled.p`
  font-size: 12px;
  text-align: start;
  right: 0;
  color: #f00;
  margin: 0;
`;
