import BaseLayoutContext from 'contexts/base-layout';
import {
  Container,
  InsideInformations,
  Header,
  Content,
  Box,
  ContentInformation,
  Information,
  TicketInformations,
  TicketInformation,
  ContainerInformations,
} from './styled';
import { useContext, useState } from 'react';
import { BiCalendarAlt } from 'react-icons/bi';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import QRCode from 'qrcode.react';
import { BsTicket } from 'react-icons/bs';
import { IoTicketOutline } from 'react-icons/io5';
import useMediaQuery from 'hooks/useMediaQuery';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';
import { TicketHandler } from '../../Handlers/TicketHandler';
import { DateHelper } from '../../Helpers/DateHelper';
import Online from './Online';
import Presential from './Presential';

export default function TicketModal() {
  const visualizationContextInstance = useVisualizationContext();
  const {
    modalTicketData,
    modalTicketOpen,
    setModalTicketOpen,
  } = visualizationContextInstance;

  if (!modalTicketOpen) {
    return null;
  }

  const handleClickClose = () => {
    setModalTicketOpen(false);
  };

  const components = {
    online: (
      <Online
        modalTicketData={modalTicketData}
        visualizationContextInstance={visualizationContextInstance}
      />
    ),
    presential: <Presential modalTicketData={modalTicketData} />,
  };

  const currentPresentation = components[modalTicketData.event_modality];

  const isOnline = modalTicketData.event_modality === 'online';

  return (
    <Container>
      <Box>
        <Content isOnline={isOnline}>
          <CloseButton onClick={handleClickClose} />
          {currentPresentation}
        </Content>
      </Box>
    </Container>
  );
}
