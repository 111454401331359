export function IconLogout({ color }) {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1522 8.33681H4.24274C3.74759 8.33681 3.40218 8.05306 3.33904 7.60187C3.27747 7.16424 3.62967 6.72245 4.06952 6.68489C4.15352 6.67759 4.23857 6.67864 4.32309 6.67864H12.1125C12.1099 6.59048 12.0426 6.56179 12.0004 6.51954C11.4181 5.93378 10.8316 5.35168 10.2493 4.76539C9.97225 4.48634 9.9159 4.10192 10.0964 3.7827C10.1848 3.62169 10.3233 3.49386 10.4909 3.41856C10.6585 3.34327 10.846 3.32461 11.0252 3.36541C11.1658 3.39517 11.2942 3.46661 11.3935 3.57041C12.5112 4.68838 13.6286 5.80617 14.7459 6.92379C15.0798 7.25865 15.0887 7.73958 14.7558 8.07497C13.633 9.20684 12.5066 10.334 11.3768 11.4565C11.0638 11.7695 10.5587 11.7392 10.2394 11.4106C9.92008 11.082 9.90964 10.5927 10.2264 10.2725C10.8123 9.6815 11.4035 9.09469 11.992 8.50633C12.0358 8.46199 12.0781 8.41452 12.1522 8.33681Z"
        fill={color}
      />
      <path
        d="M0.002866 7.50872C0.002866 6.13639 0.000257176 4.76405 0.002866 3.39223C0.0059966 1.75023 1.11162 0.396146 2.71605 0.0732743C3.23781 -0.0310463 3.76741 -0.00235735 4.29178 0.0284172C4.68467 0.0513677 4.99408 0.440482 4.99773 0.835857C4.99889 1.03235 4.93065 1.22294 4.80505 1.37408C4.67944 1.52522 4.50452 1.6272 4.31109 1.66207C4.0142 1.71058 3.71366 1.67616 3.41521 1.68137C3.20713 1.67699 2.99935 1.69925 2.79692 1.74762C2.13532 1.93279 1.72 2.44552 1.67826 3.13247C1.67199 3.2368 1.66991 3.34112 1.66991 3.44544C1.66991 6.15777 1.66991 8.8701 1.66991 11.5824C1.66276 11.7904 1.68382 11.9983 1.73252 12.2005C1.81244 12.5116 1.9904 12.7887 2.24007 12.9909C2.48973 13.193 2.79785 13.3095 3.11885 13.323C3.44965 13.3413 3.78202 13.3303 4.11386 13.3345C4.62258 13.3408 5.00086 13.6996 4.99825 14.1748C4.99564 14.65 4.61319 15.0036 4.10238 15.0021C3.51226 15.0021 2.9211 15.036 2.34299 14.8518C0.980655 14.4179 0.0122575 13.1342 0.00338748 11.705C-0.00391724 10.305 0.002866 8.90662 0.002866 7.50872Z"
        fill={color}
      />
    </svg>
  );
}
