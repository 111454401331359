import GenericMessage from 'components/GenericMessage';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useState } from 'react';
import backOfficeApi from '../../services/backOfficeApi';
import EmailDropDown from './emailDropDown';
import { ContainerList, Header, SearchInput } from './style';

export default function Listage({ setUsingCreateForm }) {
  const [emails, setEmails] = useState([]);
  const [searchTeam, setSearchTeam] = useState('');

  const debouncedSearchTerm = useDebounce(searchTeam, 600);

  useEffect(() => {
    getListage(searchTeam);
  }, [debouncedSearchTerm]);

  const getListage = async (searchTeam) => {
    const { results } = await backOfficeApi.getEmails(searchTeam);
    setEmails(results);
  };

  const handleClickCreate = () => {
    setUsingCreateForm(true);
  };

  const hasEmails = emails.length > 0;

  const mapEmails = () => {
    if (hasEmails) {
      return emails.map((item) => (
        <EmailDropDown item={item} getListage={getListage} />
      ));
    }
    return (
      <GenericMessage
        title="Nenhum e-mail encontrado"
        subtitle={
          'Clique no botão "cadastrar e-mail” para vincular e-mails às instituições de ensino.'
        }
      />
    );
  };

  return (
    <div>
      <Header>
        <SearchInput
          placeholder="Busque pelo nome"
          onChange={(e) => setSearchTeam(e.target.value)}
        />
        <button onClick={handleClickCreate}>Cadastrar e-mail</button>
      </Header>

      <ContainerList>
        {hasEmails && (
          <div className="containerHeader">
            <span className="span1">INSTITUIÇÃO DE ENSINO</span>
            <span className="span2">E-MAIL</span>
            <span className="span3">STATUS</span>
            <span className="span4">AÇÃO</span>
          </div>
        )}

        {mapEmails()}
      </ContainerList>
    </div>
  );
}
