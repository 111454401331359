import React, { useState, useContext, useEffect } from 'react';

import ItemInfoList from './ItemInfoList';
import CurriculumApi from 'services/student/Curriculum';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import BaseLayoutContext from 'contexts/base-layout';

import { AiOutlineHeart } from 'react-icons/ai';

import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';
import ModalInformation from 'components/informationModal';

import BoardContext from './CurriculoModalContext';
import useMediaQuery from 'hooks/useMediaQuery';

import {
  Container,
  Box,
  CloseButton,
  Subtitle,
  Title,
  Content,
  TextContent,
  InfoList,
  PrimaryButton,
  CleanButton,
  NoBorderButton,
  FooterButtons,
} from './style';

import { Brain } from 'assets/curriculum/brain-circuit';
import { Translate } from 'assets/curriculum/translate';
import { Light } from 'assets/curriculum/light';
import { Like } from 'assets/curriculum/like';
import { List } from 'assets/curriculum/list';
import { Suitcase } from 'assets/curriculum/suitcase';
import { User } from 'assets/curriculum/user';
import { Verify } from 'assets/curriculum/verify';

function CurriculoModalDragAndDropEditing(props) {
  const mobile = useMediaQuery('(max-width: 425px)');
  const { universityColor } = useContext(BaseLayoutContext);
  const {
    isOpen,
    setOpenEditModal,
    curriculumData,
    updateInformationOrder,
    previewOrder,
    setPreviewOrder,
    setShowPreview,
  } = props;

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const configModalConfirm = {
    bubbleText: 'Atenção!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    buttonCancelWidth: 75,
    buttonCancelHeight: 38,
    ParagraphText: `Ao redefinir a ordem dos elementos apresentados no seu currículo, todas as alterações feitas por você serão desfeitas.`,
    TwoParagraphText: `Tem certeza que deseja redefinir a ordem do seu currículo?`,
    buttonConfirmText: 'SIM, REDEFINIR',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
  };

  const configModalSave = {
    bubbleText: 'Tudo certo!',
    modalWidth: 280,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    ParagraphText: `As informações do seu currículo foram reorganizadas com sucesso!`,
    buttonConfirmText: 'OK, ENTENDI',
  };

  const defaultListStudent = [
    {
      icon: <Like color={universityColor} size={24} />,
      id: 'interests',
      name: 'Interesses',
    },
    {
      icon: <User color={universityColor} size={24} />,
      id: 'personality',
      name: 'Personalidade',
    },
    {
      icon: <Brain color={universityColor} size={24} />,
      id: 'behavioral',
      name: 'Perfil Comportamental',
    },
    {
      icon: <AiOutlineHeart color={universityColor} size={24} />,
      id: 'values',
      name: 'Valores',
    },
    {
      icon: <Translate color={universityColor} size={24} />,
      id: 'languages',
      name: 'Idiomas',
    },
    {
      icon: <Suitcase color={universityColor} size={24} />,
      id: 'experiences',
      name: 'Experiencias',
    },
    {
      icon: <Verify color={universityColor} size={24} />,
      id: 'extra_courses',
      name: 'Formação e Capacitação',
    },
    {
      icon: <Light color={universityColor} size={24} />,
      id: 'projects',
      name: 'Portifólio de Projetos',
    },
    {
      icon: <List color={universityColor} size={24} />,
      id: 'competences',
      name: 'Competências',
    },
  ];

  const { IoClose } = useIcons();

  const [list, setList] = useState(defaultListStudent);

  const move = (from, to) => {
    const newList = [...list];

    if (from >= 0 && from < newList.length && to >= 0 && to < newList.length) {
      const dragged = newList[from];

      newList.splice(from, 1);
      newList.splice(to, 0, dragged);

      setList(newList);
    }
  };

  const handleSaveOrder = async (reset) => {
    const order = {};
    if (reset) {
      defaultListStudent.forEach((item, index) => {
        order[item.id] = index + 1;
      });
    } else {
      list.forEach((item, index) => {
        order[item.id] = index + 1;
      });
    }

    const response = await CurriculumApi.updateCurriculumOrder(
      order,
      curriculumData.curriculum_id
    );
    if (response.status === 200) {
      setOpenSaveModal(true);
      updateInformationOrder(order);
    }
  };

  useEffect(() => {
    const orderMap = Object.keys(previewOrder).length
      ? previewOrder
      : curriculumData?.information_order;
    const newList = [...defaultListStudent];
    newList.sort((a, b) => orderMap[a.id] - orderMap[b.id]);
    setList(newList);
  }, [curriculumData, previewOrder]);

  const controllBackendLibraryReactDnd = () => {
    if (mobile) {
      return (
        <DndProvider backend={TouchBackend}>
          <BoardContext.Provider value={{ list, move }}>
            <InfoList>
              {list.map((data, index) => {
                return <ItemInfoList key={data.id} data={data} index={index} />;
              })}
            </InfoList>
          </BoardContext.Provider>
        </DndProvider>
      );
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <BoardContext.Provider value={{ list, move }}>
          <InfoList>
            {list.map((data, index) => {
              return <ItemInfoList key={data.id} data={data} index={index} />;
            })}
          </InfoList>
        </BoardContext.Provider>
      </DndProvider>
    );
  };

  const handlePreview = () => {
    const order = {};
    list.forEach((item, index) => {
      order[item.id] = index + 1;
    });
    setShowPreview(true);
    setPreviewOrder(order);
    setOpenEditModal(false);
  };

  const handleClose = () => {
    setOpenEditModal(false);
    setPreviewOrder({});
  };

  return (
    <Container isOpen={isOpen}>
      <Box>
        <Content>
          <CloseButton onClick={handleClose}>
            <IoClose />
          </CloseButton>
          <TextContent>
            <Title>Reorganizar</Title>
            <Subtitle>
              Arraste os tópicos para reorganizar as informações do seu
              currículo na ordem de sua preferência. Caso você não tenha
              preenchido algum campo, ele não ficará visível em seu currículo.
            </Subtitle>
          </TextContent>
          {controllBackendLibraryReactDnd()}
          <FooterButtons>
            <NoBorderButton
              onClick={() => setOpenConfirmModal(true)}
              universityColor={universityColor}
            >
              Redefinir padrão
            </NoBorderButton>
            <div>
              <CleanButton
                universityColor={universityColor}
                onClick={handlePreview}
              >
                Pré-visualizar
              </CleanButton>
              <PrimaryButton
                onClick={() => handleSaveOrder()}
                universityColor={universityColor}
              >
                Salvar alterações
              </PrimaryButton>
            </div>
          </FooterButtons>
        </Content>
      </Box>
      <ModalInformation
        modalOpenState={openConfirmModal}
        buttonConfirmFunction={() => {
          setList(defaultListStudent);
          setOpenConfirmModal(false);
          handleSaveOrder(true);
          handleClose();
        }}
        buttonCancelFunction={() => setOpenConfirmModal(false)}
        config={configModalConfirm}
        universityColor={universityColor}
      />
      <ModalInformation
        modalOpenState={openSaveModal}
        buttonConfirmFunction={() => {
          handleClose();
          setOpenSaveModal(false);
        }}
        config={configModalSave}
        universityColor={universityColor}
      />
    </Container>
  );
}

export default CurriculoModalDragAndDropEditing;
