import styled, { createGlobalStyle } from 'styled-components';
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 0 18px 0 #0000001a;
  padding: 0 1rem 3rem 1rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: red;
`;

export const FilterStyled = styled.div`
  display: flex;
  justify-content: end;
  padding-top: 32px;
  position: absolute;
  right: 63px;

  @media (max-width: 768px) {
    margin-top: 0px;
    position: relative;
    right: 33px;
    margin-bottom: -15px;
  }
`;

export const ContainerCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerButton = styled.div`
  width: 100%;
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  > button {
    border: none;
    border-color: none;
    background-color: ${(props) => props.color};
    border-radius: 6px;
    height: 2.6rem;
    width: 8rem;
    color: white;
    font-weight: 700;
    font-size: 16px;
  }
`;
