import styled from 'styled-components';

export const ContinerCard = styled.div`
  border: 1px solid #cdcdcd;
  width: 280px;
  min-height: 425px;
  position: relative;
  border-radius: 12px;

  @media (max-width: 380px) {
    width: 230px;
  }
`;

export const Status = styled.div`
  display: flex;
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 100%;
  height: 40px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 10px;
  font-weight: 600;
`;

export const Image = styled.img`
  width: 278px;
  height: 200px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  @media (max-width: 380px) {
    width: 228px;
  }
`;

export const ContainerTitle = styled.div`
  padding: 10px;

  > h5 {
    margin: 0;
    color: #4b4b4d;
    font-size: 15px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 40px;
  }

  > span {
    display: inline-block;
    margin-bottom: 5px;
    color: #777777;
  }
`;

export const ContainerInformations = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

export const ContainerButtons = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
  flex-wrap: wrap;
`;

export const Information = styled.span`
  display: flex;
  gap: 4px;
  color: #767676;
  align-items: center;
  font-weight: 400;

  > div {
    display: flex;
    align-items: center;
    padding: 4px;
    background-color: #d9efef;
    border-radius: 4px;
  }

  > span {
    color: #009291;
    text-decoration: underline;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
  }
`;

export const ContaineWithoutImage = styled.div`
  background-color: #b8b8b833;
  width: 278px;
  height: 200px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 380px) {
    width: 228px;
  }
`;
