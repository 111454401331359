export default function ProgramIcon({ color, size }) {
  return (
    <svg
      width={size}
      height={size - 2}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.27679 12.6685L12.3245 12.6771"
        stroke={color}
        stroke-width="1.12406"
        stroke-linecap="round"
      />
      <path
        d="M6.37292 4.30233V7.58849C6.37292 7.69199 6.48505 7.75237 6.5713 7.70062L9.15883 6.06185C9.23645 6.0101 9.23645 5.88935 9.15883 5.8376L6.5713 4.19883C6.49368 4.13846 6.37292 4.19883 6.37292 4.30233Z"
        stroke={color}
        stroke-width="1.12406"
        stroke-linecap="round"
      />
      <path
        d="M13.7732 10.6071H1.82746C1.30996 10.6071 0.887329 10.1844 0.887329 9.66693V2.12861C0.887329 1.61111 1.30996 1.18848 1.82746 1.18848H13.7818C14.2993 1.18848 14.722 1.61111 14.722 2.12861V9.67556C14.7133 10.1931 14.2993 10.6071 13.7732 10.6071Z"
        stroke={color}
        stroke-width="1.12406"
        stroke-linecap="round"
      />
    </svg>
  );
}
