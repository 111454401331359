import React from 'react';
import OpportunitiesEngine from 'views/StaffPages/Opportunities/OpportunitiesEngine';

import { ModalContainer, Header, Body } from './styled';

function OpportunitiesModal({
  defaultCompetence,
  handleIsLoadingState,
  handleCloseModal,
  handleSubmitOportunity,
}) {
  return (
    <ModalContainer>
      <Header>
        <button onClick={handleCloseModal}>&#x2715;</button>
      </Header>
      <Body>
        <OpportunitiesEngine
          handleIsLoadingState={handleIsLoadingState}
          isOnModal={true}
          defaultCompetence={defaultCompetence}
          handleCloseModal={handleCloseModal}
          handleSubmitOportunity={handleSubmitOportunity}
        />
      </Body>
    </ModalContainer>
  );
}

export default OpportunitiesModal;
