import styled, { createGlobalStyle } from 'styled-components';

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  width: 280px;
  height: 306px;
  border-radius: 5px;
  border: solid 1px #eaeaea;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const TextTitle = styled.p`
  font-family: roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  min-height: 4rem;
  > b {
    font-family: roboto;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  align-content: center;
`;

export const ContainerLogo = styled.div`
  margin-top: -6rem;
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background-color: white;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid #cdcdcd;
  ${(props) => (props.img ? `background-image: url(${props.img});` : '')}
  background-size: cover;
  background-position: center;
`;

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #fff;
  border-radius: 9px;
  gap: 6px;
`;

export const TextRow = styled.p`
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  color: #767676;
  margin: 0;
  align-items: center;
  align-content: center;
  > b {
    color: ${(props) => props.color || 'transparent'};
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const ContainerQuickFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  width: 100%;
  justify-content: flex-start;
`;

export const ButtonQuickFilters = styled.button`
  align-items: center;
  align-content: center;
  color: ${(props) => props.color};
  font-weight: 700;
  font-family: Roboto;
  font-size: 16px;
  border: solid 1px #e5e5e5;
  border-radius: ${(props) => props.borderRadius};
  height: 3rem;
  width: 17rem;
  background-color: #fff;
  cursor: ${(props) => (props.touched ? 'pointer' : 'default')};

  &:disabled {
    background-color: #e5e5e5;
    color: #606062;
  }
  ${(props) =>
    props.touched
      ? `background-color: #E5E5E5;
     color:#606062;`
      : ''}
`;

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const ContainerCards = styled.div`
  width: 100%;
  padding-top: 1rem;

  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0px;
  }
`;

export const DetailJob = styled.button`
  background-color: ${(props) => props.color};
  height: 37px;
  color: white;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;
