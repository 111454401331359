import { Container, ComebackHeader } from './styled';
import PreviewModal from './universityProfile';
import { useEffect, useRef } from 'react';
import ArrowLeft from 'assets/SettingsPage/Profile/arrowLeft';
export default function FullScreenModal({
  id,
  closeModal,
  activeTab,
  setActiveTab,
}) {
  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current?.scrollIntoView();
  }, []);

  return (
    <Container>
      <ComebackHeader ref={scrollRef}>
        <button onClick={closeModal}>
          <ArrowLeft color={'#009291'} size={30} />
          Voltar
        </button>
      </ComebackHeader>

      <PreviewModal id={id} activeTab={activeTab} setActiveTab={setActiveTab} />
    </Container>
  );
}
