import React, { useEffect, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { Scope } from '@unform/core';

import Input from '../../Opportunities/Inputs/Input';
import InputDesc from '../../Opportunities/Inputs/InputDescription';
import InputReplaceLink from '../../Opportunities/Inputs/InputReplaceLink';

import usePrevious from '../../../views/Company/OpportunitiesCompany/hooks/usePrevious';

import {
  AppendButton,
  ButtonSave,
  ButtonDelet,
  Title,
  SubTitle,
  ContainerVideos,
} from './styles';

export default function Videos(props) {
  const formRef = useRef(null);
  const endDiv = useRef(null);
  const topDiv = useRef(null);

  const [videosArray, setVideosArray] = useState([
    { id: 0, Title: '', Description: '', link: '' },
  ]);
  const { formData, setModalOpen, setFormValues } = props;
  const prevsVideosArray = usePrevious(videosArray);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = formRef?.current?.getData();

    if (!data.content_pages) {
      setFormValues(data);
      setModalOpen(false);
      return;
    }

    data.content_pages &&
      data.content_pages.map(function (content_page, index) {
        const video_url = content_page.content_videos[0].url;
        if (video_url === undefined || video_url === '') {
          formRef.current.setFieldError(
            `content_pages[${index}].content_videos[0].url`,
            'Por gentileza, informe um link do Youtube'
          );
          setModalOpen(true);
        } else {
          setFormValues(data);
          setModalOpen(false);
        }
      });
  };

  const appendInput = (e) => {
    e.preventDefault();
    const data = formRef?.current?.getData();

    if (!data.content_pages) {
      const id = Math.floor(Math.random() * 1000);
      setVideosArray([
        ...videosArray,
        { id, url: '', name: '', description: '' },
      ]);
      setModalOpen(true);
      return;
    }

    data.content_pages.map(function (content_page, index) {
      const video_url = content_page.content_videos[0].url;

      if (video_url === undefined || video_url === '') {
        formRef.current.setFieldError(
          `content_pages[${index}].content_videos[0].url`,
          'Por gentileza, informe um link do Youtube'
        );
        setModalOpen(true);
      } else {
        const id = Math.floor(Math.random() * 1000);
        setVideosArray([
          ...videosArray,
          { id, url: '', name: '', description: '' },
        ]);
        setModalOpen(true);
      }
    });
  };

  const handleDelet = (e, id) => {
    e.preventDefault();
    if (videosArray.length > 1) {
      const filterArray = videosArray.filter((video) => video.id !== id);
      setVideosArray(filterArray);
    } else {
      const data = formRef?.current?.getData();
      data.content_pages = [];
      setFormValues(data);
    }
  };

  const ScrollToEndDiv = () => {
    if (endDiv) {
      endDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const ScrollToTopDiv = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (prevsVideosArray && prevsVideosArray < videosArray) {
      ScrollToEndDiv();
    }

    if (prevsVideosArray && prevsVideosArray > videosArray) {
      ScrollToTopDiv();
    }
  }, [videosArray]);

  useEffect(() => {
    if (formData.content_pages) {
      setVideosArray([]);
      formData.content_pages.map((item) =>
        setVideosArray((prevs) => [
          ...prevs,
          {
            id: Math.floor(Math.random() * 1000),
            url: item.url,
            name: item.name,
            description: item.description,
          },
        ])
      );
    }
  }, [formData]);

  const mapVideos = (array) => {
    return array.map((item, index) => (
      <Scope path={`content_pages[${index}]`} key={item.id}>
        <Scope path={`content_videos[0]`}>
          <InputReplaceLink
            name="url"
            label="Link do vídeo"
            style={{ marginBottom: '1em' }}
            setModalOpen={setModalOpen}
          />
          <Input
            name="name"
            label="Título do vídeo"
            style={{ marginBottom: '1em' }}
          />
          <InputDesc
            name="description"
            label="Descrição"
            style={{ marginBottom: '1em' }}
          />
          {item.id !== 0 && (
            <ButtonDelet
              style={{ marginLeft: '32em', marginTop: '10px' }}
              onClick={(e) => handleDelet(e, item.id)}
            />
          )}
          <hr />
        </Scope>
      </Scope>
    ));
  };

  return (
    <ContainerVideos ref={topDiv}>
      <Title style={{ fontWeight: 'bold', marginTop: '-1.2em' }}>
        Detalhes <br /> <SubTitle>Adicione até 5 vídeos</SubTitle>
      </Title>

      <Form ref={formRef} initialData={formData}>
        {mapVideos(videosArray)}

        <div ref={endDiv} />

        {videosArray.length !== 5 && (
          <AppendButton onClick={appendInput}>
            Adicionar outro vídeo
          </AppendButton>
        )}
        <ButtonSave onClick={handleSubmit} type="submit">
          SALVAR
        </ButtonSave>
      </Form>
    </ContainerVideos>
  );
}
