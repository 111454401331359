import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import { Formik, FastField } from 'formik';
import { maskCnpj } from 'utils/inputMasks';
import * as Yup from 'yup';
import { handleFieldMask, validateCNPJ, cnpjRegex } from 'utils/validation';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import { FormHelperText, TextField } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import { Redirect } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';

import { isCompanyRegistered } from 'services/companies';
import { object, func } from 'prop-types';

function companyBeginRegistrationForm(props) {
  const {
    classes,
    handleIsLoadingState,
    openSnackbar,
    openModalInformation,
  } = props;
  const [cardAnimaton, setCardAnimation] = useState('cardHidden');
  const [organizationNumber, setOrganizationNumber] = useState();

  useEffect(() => {
    setTimeout(function () {
      setCardAnimation('');
    }, 700);
  }, []);

  const CompanyBeginRegistrationSchema = Yup.object().shape({
    organizationNumber: Yup.string()
      .matches(cnpjRegex, 'Informe um CNPJ válido.')
      .test(
        'organizationNumberValidation',
        'Informe um CNPJ válido.',
        (value) => {
          if (value) {
            return validateCNPJ(value);
          }
          return false;
        }
      )
      .test(
        'organizationRegistered',
        'Esta empresa já é cadastrada.',
        async (value) => {
          if (value) {
            handleIsLoadingState(false);

            let isRegistered = await isCompanyRegistered(value);
            if (isRegistered.exists) {
              openModalInformation();
            }

            if (isRegistered.message) {
              this.props.openSnackbarFn(
                `Erro ${isRegistered.message} ao consultar CNPJ. Entre em contato com o suporte.`,
                true
              );
              return false;
            }

            return !isRegistered.exists;
          }
        }
      ),
  });

  return (
    <GridItem xs={12} sm={6} md={4}>
      {organizationNumber && (
        <Redirect
          to={{
            pathname: '/empresas/cadastro',
            state: {
              organizationNumber: organizationNumber,
              universityColor: universityColor,
            },
          }}
        />
      )}
      <Formik
        initialValues={{ organizationNumber: '' }}
        enableReinitialize={true}
        validateOnChange={false}
        validationSchema={CompanyBeginRegistrationSchema}
        onSubmit={async (values, actions) => {
          const { organizationNumber } = values;
          handleIsLoadingState(true);

          if (organizationNumber) {
            setOrganizationNumber(organizationNumber);
          } else {
            openSnackbar(`CNPJ já é cadastrado.`, true);
          }

          handleIsLoadingState(false);
          actions.setSubmitting(false);
        }}
        render={({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardBody>
                <h4 className="form-title">Cadastre-se</h4>
                <p>&nbsp;</p>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  fullWidth
                  name="organizationNumber"
                  id="organizationNumber"
                  label="CNPJ"
                  placeholder="21.765.360/0001-54"
                  onKeyUp={(event) => {
                    handleFieldMask(event, maskCnpj);
                    handleChange(event);
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    !!errors['organizationNumber'] &&
                    !!touched['organizationNumber']
                  }
                  value={values.organizationNumber}
                  component={TextField}
                />
                {!!errors.organizationNumber &&
                  !!touched.organizationNumber && (
                    <FormHelperText className={classes.helperText}>
                      {errors.organizationNumber}
                    </FormHelperText>
                  )}
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  className={classes.saveButton}
                  round
                >
                  Cadastrar
                </Button>
              </CardFooter>
            </Card>
          </form>
        )}
      />
    </GridItem>
  );
}

companyBeginRegistrationForm.propTypes = {
  classes: object,
  handleIsLoadingState: func,
  openSnackbar: func,
};

export default withStyles(loginPageStyle)(companyBeginRegistrationForm);
