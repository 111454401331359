import React, { Fragment, useContext } from 'react';
import { shape, arrayOf } from 'prop-types';

import { Document, Text, Link } from '@react-pdf/renderer';

import Phone from 'assets/img/icons/icoPhone.png';
import Email from 'assets/img/icons/icoContact.png';
import IcoComportamental from 'assets/img/icons/icoComportamental.png';
import IcoPersonalidade from 'assets/img/icons/icoPersonalidade.png';
import IcoExpProfissional from 'assets/img/icons/icoExpProfissional.png';
import IcoCursosAdicionais from 'assets/img/icons/icoCursosAdicionais.png';
import IcoPortfolioProjetos from 'assets/img/icons/icoPortfolioProjetos.png';
import IcoCompetenciasTecnicas from 'assets/img/icons/icoCompetenciasTecnicas.png';
import IcoInteresses from 'assets/img/icons/icoInteresses.png';
import IcoValores from 'assets/img/icons/icoValores.png';
import IcoLanguage2 from 'assets/img/icons/icoLanguage2.png';

import moment from 'moment';
import calculateTimeBetweenDates from 'utils/calculateTimeBetweenDates';
import { bool } from 'yup';
import {
  Body,
  Contact,
  ContactContainer,
  ContactIcon,
  ContentColumn,
  ContentList,
  DefaultSection,
  Divider,
  Footer,
  Header,
  Name,
  SectionColumn,
  SectionContainer,
  SectionContent,
  SectionContentColumn,
  SectionContentLanguage,
  SectionContentItemColumn,
  SectionContentItemRow,
  SectionIcon,
  SectionTitle,
  SubTitle,
  SubTitleExp,
  TextStrong,
  TextMargin,
} from './CurriculumStyle';

// Create Document Component
const Curriculum = (props) => {
  const {
    profile,
    behavioral,
    values,
    interests,
    lifeInterests,
    experiencesCareer,
    experiencesLife,
    extraCourses,
    showSpecialNeeds,
    projects,
    technicalCompetences,
    personality,
    showAverage,
    languages,
    canShowContact,
    role,
    universityColor,
    showColorOrRace,
    showGenderIdentity,
    specialNeeds,
  } = props;

  const currentDate = new Date();
  const formattedDate = `${currentDate
    .getDate()
    .toString()
    .padStart(2, '0')}/${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${currentDate.getFullYear()}`;
  const formattedTime = `${currentDate
    .getHours()
    .toString()
    .padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function addOrdinalSymbol(str) {
    return `${str.split(' ').join('º ')}`;
  }

  return (
    <Document
      title={`Currículo ${profile.name}`}
      author="Workalove"
      creator="Workalove"
      producer="Workalove"
    >
      <Body wrap>
        <Header>
          <Name>{profile.name}</Name>
          <Text>
            {profile.district &&
              `${capitalizeFirstLetter(profile.district)} - `}
            {profile.city} / {profile.state}
          </Text>
          {profile.courses.length > 0 &&
            profile.courses.map(
              ({ name, courseStatus, degreeType, courseSemester }, index) => {
                if (courseStatus === 'Matriculado') {
                  const formattedDegree = capitalizeFirstLetter(degreeType);
                  const formattedCourseSemester =
                    addOrdinalSymbol(courseSemester);
                  return (
                    <Text key={index}>
                      {`${name} (${formattedDegree}) - ${formattedCourseSemester}`}
                    </Text>
                  );
                }
              }
            )}
          {profile.courses.length > 0 &&
            profile.courses.map(
              ({ name, principalCourse, courseStatus, degreeLevel }, index) => {
                if (courseStatus === 'Concluído') {
                  const listDegreeLevel = {
                    técnico: 'Formação em',
                    profissionalizante: 'Formação em',
                    'pós graduação': 'Especialização em',
                    graduação: 'Graduação concluída em',
                  };
                  return (
                    <Text key={index}>
                      {`${
                        listDegreeLevel[degreeLevel]
                          ? listDegreeLevel[degreeLevel]
                          : ''
                      } ${principalCourse ? principalCourse : name}`}
                    </Text>
                  );
                }
              }
            )}
          {specialNeeds?.length > 0 && showSpecialNeeds && (
            <Text>PCD - {specialNeeds?.map((item) => ` ${item.label} `)}</Text>
          )}
          {!canShowContact && role !== 'university' ? null : (
            <ContactContainer>
              {profile.email ? (
                <Fragment>
                  <ContactIcon src={Email} />
                  <Contact>{profile.email}</Contact>
                </Fragment>
              ) : null}

              {profile.mobilePhone ? (
                <Fragment>
                  <ContactIcon src={Phone} />
                  <Contact>{profile.mobilePhone}</Contact>
                </Fragment>
              ) : null}
            </ContactContainer>
          )}

          {role === 'student' ? (
            <ContactContainer>
              {profile.email ? (
                <Fragment>
                  <ContactIcon src={Email} />
                  <Contact>{profile.email}</Contact>
                </Fragment>
              ) : null}

              {profile.mobilePhone ? (
                <Fragment>
                  <ContactIcon src={Phone} />
                  <Contact>{profile.mobilePhone}</Contact>
                </Fragment>
              ) : null}
            </ContactContainer>
          ) : null}

          {profile.portfolioUrl ? (
            <ContactContainer>
              <TextMargin>
                <Fragment>
                  <Contact style={{ color: '#999999', marginRight: '-0.5em' }}>
                    Portfólio Geral:
                  </Contact>
                  <Link
                    style={{
                      color: '#50B6C2',
                      textDecoration: 'underline',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {profile.portfolioUrl}
                  </Link>
                </Fragment>
              </TextMargin>
            </ContactContainer>
          ) : null}
        </Header>
        {interests.length > 0 && lifeInterests.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoInteresses}
              />
              <TextStrong>Interesses</TextStrong>
            </SectionTitle>
            <SectionContainer>
              <SectionContent>
                <SubTitle>Vida (Hobbies) :{'\n'}</SubTitle>
                {lifeInterests.map((interest) => interest.label).join(' | ')}
                <SubTitle>
                  {'\n\n'}Carreira :{'\n'}
                </SubTitle>
                {interests.map((interest) => interest.label).join(' | ')}
              </SectionContent>
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        {personality.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoPersonalidade}
              />
              <TextStrong>Personalidade</TextStrong>
            </SectionTitle>
            <SectionContainer>
              {personality.map(({ id, verbose_name, description }) => (
                <SectionContent key={id}>
                  <TextStrong>{verbose_name}:</TextStrong> {description}
                </SectionContent>
              ))}
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        {behavioral.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoComportamental}
              />
              <TextStrong>Comportamental</TextStrong>
            </SectionTitle>
            <SectionContainer>
              {behavioral.map(({ id, verbose_name, description }) => (
                <SectionContent key={id}>
                  <TextStrong>{verbose_name}:</TextStrong> {description}
                </SectionContent>
              ))}
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}

        {values.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon universityColor={universityColor} src={IcoValores} />
              <TextStrong>Valores</TextStrong>
            </SectionTitle>
            <SectionContainer>
              {values.map(({ id, verbose_name, description }) => (
                <SectionContent key={id}>
                  <TextStrong>{verbose_name}:</TextStrong> {description}
                </SectionContent>
              ))}
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        {languages.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoLanguage2}
              />
              <TextStrong>Idiomas</TextStrong>
            </SectionTitle>
            <SectionContainer>
              {languages.map((item) => (
                <SectionContentLanguage key={item.id}>
                  <SectionContentItemColumn>
                    <TextStrong>
                      {item.language.label}
                      {'\n'}
                    </TextStrong>
                    <Text>Nível: {item.fluence_level.label}</Text>
                  </SectionContentItemColumn>
                  <SectionContentItemRow>
                    {item.fluence_level.description}
                  </SectionContentItemRow>
                </SectionContentLanguage>
              ))}
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        {(experiencesCareer.length > 0 || experiencesLife.length > 0) && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoExpProfissional}
              />
              <TextStrong>Experiências</TextStrong>
            </SectionTitle>
            {experiencesCareer.length > 0 && (
              <SectionContainer>
                <SubTitleExp>Experiências de Carreiras{'\n'}</SubTitleExp>
                {experiencesCareer.map(
                  ({
                    id,
                    role,
                    started_at,
                    ended_at,
                    company,
                    section,
                    area,
                  }) => {
                    const startDate = moment(started_at, 'DD/MM/YYYY').format(
                      'MM/YYYY'
                    );

                    const endDate =
                      ended_at !== 'Atual'
                        ? moment(ended_at, 'DD/MM/YYYY').format('MM/YYYY')
                        : ended_at;

                    const calculatedTime = calculateTimeBetweenDates(
                      startDate,
                      endDate
                    );

                    return (
                      <ContentColumn key={id}>
                        <Text>
                          <TextStrong>{role}:</TextStrong> {startDate} -{' '}
                          {endDate} {endDate !== 'Atual' && calculatedTime} na{' '}
                          {company}
                        </Text>
                        <Text>
                          Principal conhecimento adquirido: {area.label}.
                        </Text>
                        <Text>
                          Empresa atuante do segmento de {section.label}.
                        </Text>
                      </ContentColumn>
                    );
                  }
                )}
                <Divider />
              </SectionContainer>
            )}
            {experiencesLife.length > 0 && (
              <SectionContainer>
                <SubTitleExp>Experiências de Vida{'\n'}</SubTitleExp>
                {experiencesLife.map(
                  ({
                    id,
                    role,
                    started_at,
                    ended_at,
                    knowledges,
                    city,
                    country,
                  }) => {
                    const startDate = moment(started_at, 'DD/MM/YYYY').format(
                      'MM/YYYY'
                    );

                    const endDate =
                      ended_at !== 'Atual'
                        ? moment(ended_at, 'DD/MM/YYYY').format('MM/YYYY')
                        : ended_at;

                    const calculatedTime = calculateTimeBetweenDates(
                      startDate,
                      endDate
                    );

                    return (
                      <ContentColumn key={id}>
                        <Text>
                          <TextStrong>{role}:</TextStrong> {startDate} -{' '}
                          {endDate} {endDate !== 'Atual' && calculatedTime}
                        </Text>
                        <Text>
                          Competências/especialidades adquiridas:{' '}
                          {knowledges
                            .map((element) => element.label)
                            .join(' | ')}
                        </Text>
                        <Text>
                          Local: {city} - {country.label}
                        </Text>
                      </ContentColumn>
                    );
                  }
                )}
                <Divider />
              </SectionContainer>
            )}
          </DefaultSection>
        )}

        {extraCourses.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoCursosAdicionais}
              />
              <TextStrong>Formação e Capacitação</TextStrong>
            </SectionTitle>
            <SectionContainer>
              <SectionColumn>
                {extraCourses.map(
                  ({ id, course, area, level, workload, institution }) => (
                    <SectionContentColumn key={id}>
                      <TextStrong>{course}</TextStrong>
                      <Text>
                        Principal conhecimento adquirido: {area.label}
                      </Text>
                      <Text>Nível: {level.label}</Text>
                      <Text>Duração: {workload}</Text>
                      <Text>Instituição: {institution}</Text>
                    </SectionContentColumn>
                  )
                )}
              </SectionColumn>
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        {projects.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoPortfolioProjetos}
              />
              <TextStrong>Portfólio</TextStrong>
            </SectionTitle>
            <SectionContainer>
              <SectionColumn>
                {projects.map(({ id, title, section, knowledge, link }) => (
                  <SectionContentColumn key={id}>
                    <TextStrong>{title}</TextStrong>
                    <Text>Área: {section.label}</Text>
                    {knowledge.length > 0 && (
                      <Text>
                        Principais conhecimentos adquiridos:{' '}
                        {knowledge.map((item) => item.label).join(' | ')}
                      </Text>
                    )}
                    <Text>Link: {link}</Text>
                  </SectionContentColumn>
                ))}
              </SectionColumn>
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        {technicalCompetences.values.length > 0 && (
          <DefaultSection wrap={false}>
            <SectionTitle>
              <SectionIcon
                universityColor={universityColor}
                src={IcoCompetenciasTecnicas}
              />
              <TextStrong>
                Competências{' '}
                {showAverage && `- Média geral ${technicalCompetences.average}`}
              </TextStrong>
            </SectionTitle>
            <SectionContainer>
              {technicalCompetences.top_5_values.map(({ id, name, value }) => (
                <ContentList key={id}>
                  <Text>{name}</Text>
                  {showAverage && <Text>{value}</Text>}
                </ContentList>
              ))}
              <Divider />
            </SectionContainer>
          </DefaultSection>
        )}
        <Footer fixed>
          <TextMargin>
            <Text>
              Gerado em {formattedDate} às {formattedTime}
            </Text>
          </TextMargin>
        </Footer>
      </Body>
    </Document>
  );
};

Curriculum.defaultProps = {
  behavioral: arrayOf(shape),
  personality: arrayOf(shape),
  specialNeeds: arrayOf(shape),
  showSpecialNeeds: bool,
  values: arrayOf(shape),
  interests: arrayOf(shape),
  lifeInterests: arrayOf(shape),
  experiencesCareer: arrayOf(shape),
  experiencesLife: arrayOf(shape),
  extraCourses: arrayOf(shape),
  projects: arrayOf(shape),
  technicalCompetences: shape,
  showAverage: bool,
};

Curriculum.propTypes = {
  profile: arrayOf(shape).isRequired,
  specialNeeds: arrayOf(shape),
  showSpecialNeeds: bool,
  behavioral: arrayOf(shape),
  personality: arrayOf(shape),
  values: arrayOf(shape),
  interests: arrayOf(shape),
  lifeInterests: arrayOf(shape),
  experiencesCareer: arrayOf(shape),
  experiencesLife: arrayOf(shape),
  extraCourses: arrayOf(shape),
  projects: arrayOf(shape),
  technicalCompetences: shape,
  showAverage: bool,
};

export default Curriculum;
