export default function Building({ size = 37, color }) {
  return (
    <div>
      <svg
        width={size - 0}
        height={size}
        viewBox="0 0 37 37"
        fill={color}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 20.1953H11V23.1953H6.5V20.1953ZM14 23.1953H18.5V20.1953H14V23.1953ZM6.5 29.1953H11V26.1953H6.5V29.1953ZM14 29.1953H18.5V26.1953H14V29.1953ZM6.5 11.1953H11V8.19531H6.5V11.1953ZM14 11.1953H18.5V8.19531H14V11.1953ZM6.5 17.1953H11V14.1953H6.5V17.1953ZM14 17.1953H18.5V14.1953H14V17.1953ZM36.5 12.6953V36.6953H0.5V5.19531C0.5 4.00184 0.974106 2.85725 1.81802 2.01333C2.66193 1.16942 3.80653 0.695313 5 0.695313L20 0.695312C21.1935 0.695313 22.3381 1.16942 23.182 2.01333C24.0259 2.85725 24.5 4.00184 24.5 5.19531V8.19531H32C33.1935 8.19531 34.3381 8.66942 35.182 9.51333C36.0259 10.3572 36.5 11.5018 36.5 12.6953ZM21.5 5.19531C21.5 4.79749 21.342 4.41596 21.0607 4.13465C20.7794 3.85335 20.3978 3.69531 20 3.69531H5C4.60218 3.69531 4.22064 3.85335 3.93934 4.13465C3.65804 4.41596 3.5 4.79749 3.5 5.19531V33.6953H21.5V5.19531ZM33.5 12.6953C33.5 12.2975 33.342 11.916 33.0607 11.6347C32.7794 11.3533 32.3978 11.1953 32 11.1953H24.5V33.6953H33.5V12.6953ZM27.5 23.1953H30.5V20.1953H27.5V23.1953ZM27.5 29.1953H30.5V26.1953H27.5V29.1953ZM27.5 17.1953H30.5V14.1953H27.5V17.1953Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
