import axios from 'axios';
import URLS from 'constants/urls';
import getCookieValue from 'utils/getCookieValue';
import qs from 'querystring';
import cleanCookies from './authenticationCookies';
import { logout } from './logout';

const getUniversityLogos = async (slug) => {
  const response = await axios.get(`${URLS.API_URL}/api/internal/${slug}/logo`);
  return response;
};

const getInfoStudent = async (university, type) => {
  try {
    const response = await axios.get(
      `${URLS.API_URL}/api/internal/${university}/login?type=${type}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const login = async (loginUrl, data) => {
  // We have this because we still connect to the CRSF validation in the backend
  // when we remove this dependency from the backend, we can remove this
  if (window.parent !== window) {
    await logout();
  } else {
    cleanCookies();
  }

  const response = await axios
    .post(`${URLS.API_URL}/api/${URLS.LOGIN_URLS[loginUrl]}`, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return { isLogged: true, data: res.data };
    })
    .catch((error) => {
      return {
        isLogged: false,
        data: error.response ? error.response.data : error,
      };
    });

  return response;
};

const loginByToken = async (urlWithQuery) => {
  // We have this because we still connect to the CRSF validation in the backend
  // when we remove this dependency from the backend, we can remove this
  if (window.parent !== window) {
    await logout();
  } else {
    cleanCookies();
  }

  const university = window.location.hash.split('/')[2];
  const query = urlWithQuery.split('?')[1];
  const queryObj = qs.parse(query);

  const data = {
    authentication_token: queryObj.authentication_token,
    university,
    origin_url: queryObj.origin_url,
  };

  const response = await axios
    .post(`${URLS.API_URL}/api/internal/new-authenticate/students`, data, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return { isLogged: true, data: res.data };
    })
    .catch((error) => {
      return { isLogged: false, data: error.response };
    });

  return response;
};

const isAuthenticated = async () => {
  const query = window.location.href.split('?')[1];
  const queryObj = qs.parse(query);
  if (queryObj.hasOwnProperty('authentication_token')) {
    return loginByToken(window.location.href);
  }
  const response = await axios
    .get(`${URLS.API_URL}/api/internal/me`, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
      },
    })
    .then((res) => {
      return { isLogged: true, data: res.data };
    })
    .catch((error) => {
      return { isLogged: false, data: error.response };
    });

  return response;
};

export { isAuthenticated, login, getUniversityLogos, getInfoStudent };
