import React from 'react';
import { HeaderMatchComponent } from './styled';
import Tooltip from 'components/NewTooltip';
import { CircularProgressBar } from './CircularProgress';

export default function MatchComponent(props) {
  const { match } = props;

  return (
    <HeaderMatchComponent>
      <Tooltip
        text={`Seu perfil combina ${match}% com a vaga`}
        colorSchema={'black'}
        direction={'top'}
        minWidth="135px"
        isMultiLine={true}
      >
        <CircularProgressBar match={match} totalWidth={110} />
      </Tooltip>
    </HeaderMatchComponent>
  );
}
