import React, { useEffect, useState, useContext } from 'react';
import { func, string, bool, objectOf } from 'prop-types';
import { Button, Icon } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import Slide from '@material-ui/core/Slide';

import walImg from 'assets/img/faces/wal.png';

import { Modal } from './style';
import BaseLayoutContext from 'contexts/base-layout';

const TalkModal = ({
  openTalk,
  config,
  onApprove,
  closeTalk,
  onDenied,
  reRender,
}) => {
  const [open, setOpen] = React.useState(false);
  const [dimmer, setDimmer] = React.useState(false);
  const talkModalRef = React.useRef();
  const [reRenderState, setReRenderState] = useState(false);

  const { universityColor } = useContext(BaseLayoutContext);

  const handleOpenModal = async () => {
    setOpen((prev) => !prev);
    setDimmer((prev) => !prev);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDimmer(false);
    closeTalk();
  };

  const handleClickOutside = (e) => {
    if (
      talkModalRef &&
      talkModalRef.current &&
      e.target.id === talkModalRef.current.id
    ) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (openTalk) {
      handleOpenModal();
      setReRenderState(reRender);
    }
  }, [openTalk]);
  return (
    <div>
      <Modal
        id="talkModal"
        universityColor={universityColor}
        className="modal"
        open={dimmer}
        ref={talkModalRef}
        noActions={config && config.noActions}
        onClick={config && !config.disableOutsideClick && handleClickOutside}
      >
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
          <div className="modalContent">
            <div className="modalHeader">
              <img src={walImg} alt="Avatar" />
              {config && !config.disableOutsideClick && (
                <span onClick={handleCloseModal} className="close">
                  &times;
                </span>
              )}
            </div>
            <div className="modalBody">
              <p>
                <strong>{config && config.title}</strong>
              </p>
              <p>{config && config.description}</p>
            </div>
            {config && !config.noActions && (
              <div className="modalActions">
                {onDenied && (
                  <Button
                    type="submit"
                    size="small"
                    color="primary"
                    className="deniedButton"
                    onClick={() => {
                      onDenied();
                      handleCloseModal();
                    }}
                  >
                    {config && config.buttonTextDenied}{' '}
                    {config && !config.RemoveIconX && (
                      <Icon className="iconTalk">clear</Icon>
                    )}
                  </Button>
                )}
                <Button
                  type="submit"
                  size="small"
                  color="primary"
                  onClick={() => {
                    if (onApprove) onApprove();
                    handleCloseModal();
                  }}
                >
                  {config && config.buttonText}{' '}
                  {config && !config.RemoveIconArrow && (
                    <Icon className="iconTalk">east</Icon>
                  )}
                </Button>
              </div>
            )}
          </div>
        </Slide>
      </Modal>
    </div>
  );
};

TalkModal.defaultProps = {
  config: {
    RemoveIconX: false,
    RemoveIconArrow: false,
  },
};

TalkModal.propTypes = {
  openTalk: bool.isRequired,
  config: objectOf({
    title: string.isRequired,
    description: string.isRequired,
    buttonText: string,
    disableOutsideClick: bool,
    noActions: bool,
    RemoveIconX: bool,
    RemoveIconArrow: bool,
  }).isRequired,
  onApprove: func.isRequired,
  onDenied: func.isRequired,
  closeTalk: func.isRequired,
};

TalkModal.defaultProps = {
  config: {
    RemoveIconX: false,
    RemoveIconArrow: false,
  },
};

export default withStyles(ProfessionalExperienceFormStyles)(TalkModal);
