import React from 'react';
import { Background, CircularProgressWrapper, Progress } from './style';

export const CircularProgress = ({ progress = 0 }) => {
  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <CircularProgressWrapper>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <Background
          d={`M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${
            2 * radius
          } a ${radius} ${radius} 0 1 1 0 -${2 * radius}`}
        />
        <Progress
          d={`M 50 50 m 0 -${radius} a ${radius} ${radius} 0 1 1 0 ${
            2 * radius
          } a ${radius} ${radius} 0 1 1 0 -${2 * radius}`}
          circumference={circumference}
          offset={offset}
        />
      </svg>
      <p>{progress}%</p>
    </CircularProgressWrapper>
  );
};
