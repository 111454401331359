import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  z-index: 100;

  @media (max-width: 1065px) {
    margin-left: auto;
  }
`;
export const FilterGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 20px;
  width: 627px;

  @media (max-width: 705px) {
    grid-template-columns: 1fr;
  }
`;
export const FilterDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: 705px) {
    grid-template-columns: 1fr;
  }
`;

export const SpanError = styled.p`
  font-size: 12px;
  text-align: start;
  right: 0;
  color: #f00;
  margin-left: 10;
`;
