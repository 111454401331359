import React, { Component } from 'react';
import { func, object } from 'prop-types';
import { getStudentCurriculumData } from 'services/university/curriculum';
import { profileAdapter } from 'adapters/student/profileAdapter';

import Curriculum from 'components/Curriculum/Curriculum';

class StudentCurriculum extends Component {
  state = {
    behavioralProfiles: [],
    personalityProfiles: [],
    values: [],
    interestList: [],
    professionalExperienceState: [],
    extraCourseState: [],
    projectsState: [],
    technicalCompetences: {},
    studentProfileState: {},
    lifeExperiences: [],
  };

  async componentDidMount() {
    const fetchStudentData = async () => {
      const response = await getStudentCurriculumData(
        this.props.match.params.id
      );

      if (!response.message) {
        const {
          academic_profile,
          quizzes,
          interests,
          professional_experience,
          extra_courses,
          projects,
          life_experience,
          technical_competences,
        } = response;

        this.setState({
          studentProfileState: profileAdapter(academic_profile),
          behavioralProfiles: quizzes.behavioral.results,
          personalityProfiles: quizzes.personality.results,
          values: quizzes.values.results,
          interestList: interests,
          professionalExperienceState: professional_experience,
          extraCourseState: extra_courses,
          projectsState: projects,
          technicalCompetences: technical_competences,
          lifeExperiences: life_experience,
        });
      } else if (response.message) {
        this.props.openDnaDialog(
          'Error!',
          'Error ao obter os dados! Tente novamente mais tarde',
          'OK',
          () => this.props.history.push('/'),
          '',
          () => this.props.history.push('/')
        );
      }
    };

    await fetchStudentData();
    this.props.handleIsLoadingState(false);
  }

  render() {
    const {
      studentProfileState,
      interestList,
      behavioralProfiles,
      personalityProfiles,
      values,
      professionalExperienceState,
      extraCourseState,
      projectsState,
      technicalCompetences,
      lifeExperiences,
    } = this.state;

    return studentProfileState.name ? (
      <Curriculum
        profile={studentProfileState}
        interests={interestList}
        behavioral={behavioralProfiles}
        personality={personalityProfiles}
        experiencesCareer={professionalExperienceState}
        experiencesLife={lifeExperiences}
        values={values}
        extraCourses={extraCourseState}
        projects={projectsState}
        technicalCompetences={technicalCompetences}
        handleIsLoadingState={this.props.handleIsLoadingState}
        companyPreview
      />
    ) : null;
  }
}

StudentCurriculum.propTypes = {
  match: object,
  history: object,
  handleIsLoadingState: func,
  openDnaDialog: func,
};

export default StudentCurriculum;
