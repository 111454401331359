import React, { Fragment } from 'react';
import {
  Container,
  Wrapper,
  ButtonEdit,
  Title,
  Content,
  ContainerButton,
  ListCourses,
  Subtitle,
  SimpleText,
  SimpleLink,
} from './style';

import iconEdit from 'assets/JobOffer/edit.svg';
import { currency, jobType, modalityType } from '../useCases/SelectOptions';

import ReactHtmlParser from 'react-html-parser';

import moment from 'moment';
import { disability } from '../../../../../JobOfferRebuild/Components/ExternalJobOffer/useCases/SelectOptions';

import { isMasterLet } from 'views/StaffPages/jobOpeningsRebuild/JobController/PublishedJobsTabs';

export default function PreviewJobExternal({
  formRef,
  externalJobOffer,
  courses,
  closeModal,
  handleSubmit,
  selectedUniversities,
}) {
  const data = formRef?.current?.getData();

  const mapItems = (arrayOfItems) => {
    if (arrayOfItems.length) {
      return arrayOfItems.map((item) => (
        <ul>
          <li> {item.label} </li>
        </ul>
      ));
    }

    return (
      <ul>
        <li> Não informado </li>
      </ul>
    );
  };

  const returnCourseLabel = (dataCourses) => {
    const labels = dataCourses.map((item, index) => {
      return courses.find((findItem) => findItem.value === item);
    });

    return mapItems(labels);
  };

  const returnLabelCurrency = () => {
    const foundCurrency = currency.find(
      (item) => item.value === data.salary_coin
    );

    return foundCurrency.previewText;
  };

  const returnLabelModalityType = () => {
    if (data.modality_type) {
      const foundModality = modalityType.find(
        (item) => item.value === data.modality_type
      );

      if (data.modality_type.label === 'remote') {
        return foundModality.label;
      }

      return foundModality.label;
    }

    return 'Não informado';
  };

  const returnLabels = (arrayToFind, itemToFind) => {
    if (itemToFind.length) {
      if (Array.isArray(itemToFind) && itemToFind.length > 1) {
        const found = arrayToFind.filter((item) => {
          return itemToFind.find((itemTwo) => item.value === itemTwo);
        });
        return mapItems(found);
      }

      const foundLabel = arrayToFind.find(
        (item) => item.value === itemToFind[0]
      );
      return mapItems([foundLabel]);
    }

    return mapItems(itemToFind);
  };

  const returnLinkOrEmail = () => {
    if (data.divulgation_link) {
      return (
        <SimpleLink href={data.divulgation_link} target="_blank">
          {data.divulgation_link}
        </SimpleLink>
      );
    }
    if (data.job_email) {
      return (
        <SimpleLink href={`mailto:${data.job_email}`} target="_blank">
          {data.job_email}
        </SimpleLink>
      );
    }
    return <SimpleText>{'Não informado'}</SimpleText>;
  };

  const formatDate = () => {
    const startOffer = moment(data.start_offer_at).format('DD/MM/YYYY');
    const endOffer = moment(data.end_offer_at).format('DD/MM/YYYY');

    return `${startOffer} a ${endOffer}`;
  };

  const handleSubmitInsidePreview = () => {
    closeModal();

    setTimeout(() => {
      formRef.current.submitForm();
    }, 1000);
  };

  return (
    <Container>
      <Wrapper>
        <div className="header">
          <h1>Pré-Visualização da Vaga</h1>
        </div>
        <Title style={{ marginTop: '30px' }}>
          <strong> Dados Básicos </strong>
        </Title>
        <Content>
          <Subtitle> Vaga </Subtitle>
          <SimpleText>{data.name}</SimpleText>
          <Subtitle spaceBottom="18px"> Descrição da Vaga </Subtitle>
          <div className="description-job">
            {data.description
              ? ReactHtmlParser(data.description.replace(/style="[^"]*"/g, ''))
              : 'Não informado'}
          </div>
          <Subtitle spaceBottom="25px"> Cursos relacionados </Subtitle>
          <ListCourses>{returnCourseLabel(data.courses)}</ListCourses>

          {data.required_attributes && (
            <Fragment>
              <Subtitle> Pré-requisitos obrigatórios </Subtitle>
              <SimpleText>{data.required_attributes}</SimpleText>
            </Fragment>
          )}
        </Content>

        <Title>
          <strong> Dados Específicos da Vaga </strong>
        </Title>
        <Content>
          <Subtitle spaceBottom="25px"> Tipo de Vaga </Subtitle>
          <ListCourses>
            {returnLabels(jobType, data.application_type)}
          </ListCourses>

          <Subtitle spaceBottom="25px"> Deficiências </Subtitle>
          <ListCourses>
            {returnLabels(disability, data.disability ? data.disability : [])}
          </ListCourses>

          <Subtitle> Modalidade de Trabalho </Subtitle>
          <SimpleText>{returnLabelModalityType()}</SimpleText>
        </Content>

        <Title>
          <strong> Dados Específicos da Vaga </strong>
        </Title>
        <Content>
          <Subtitle> Período de Divulgação: </Subtitle>
          <SimpleText>
            {data.start_offer_at && data.end_offer_at
              ? formatDate()
              : 'Não informado'}
          </SimpleText>
          {isMasterLet && (
            <>
              <Subtitle>Divulgando para</Subtitle>
              <ListCourses>
                {selectedUniversities.length > 0 ? (
                  mapItems(selectedUniversities)
                ) : (
                  <ul>
                    <li>Todas as instituições do grupo</li>
                  </ul>
                )}
              </ListCourses>
            </>
          )}
          {externalJobOffer && (
            <Fragment>
              <Subtitle>
                {' '}
                Link ou e-mail para direcionamento dos candidatos{' '}
              </Subtitle>
              {returnLinkOrEmail()}
            </Fragment>
          )}
        </Content>

        <ContainerButton>
          <button onClick={handleSubmitInsidePreview}> Publicar </button>
        </ContainerButton>
      </Wrapper>
    </Container>
  );
}
