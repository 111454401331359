import React, { Fragment, useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { UnInput, UnInputLabel } from '../../Styles/unformComponentsStyled';
import { ContainerLabelInput, ErrorWrapper } from '../../Styles/formStyled';
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai';
import { IconSearchAddress } from 'views/CompanyRebuild/Components/RegisterCompany/style';

export default function Input({
  label,
  name,
  maxlength,
  onChange,
  onSearchIcon,
  marginTop,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,

      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput
        ref={inputRef}
        maxLength={maxlength}
        defaultValue={defaultValue}
        onChange={onChange}
        error={error}
        onFocus={clearError}
        {...rest}
      />
      {onSearchIcon && (
        <IconSearchAddress marginTop={marginTop}>
          <AiOutlineSearch size={24} />
        </IconSearchAddress>
      )}
      <ErrorWrapper>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
