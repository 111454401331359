import React, { useContext, useEffect, useRef, useState } from 'react';

import { useField } from '@unform/core';
import { UnInputLabel, ContainerLabelInput, TextArea, Count } from './style';
import { Error } from '../inputField/style';
import BaseLayoutContext from '../../../contexts/base-layout';

export default function NoteInput({
  label,
  name,
  maxLength,
  onChange,
  useCount,
  textAreaHeight,
  ...rest
}) {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  const { universityColor } = useContext(BaseLayoutContext);

  const [count, setCount] = useState(0);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref, value) {
        if (value) {
          ref.value = value;
          setCount(value.length);
        }
      },
    });
  }, [fieldName, registerField]);

  const lengthRemaing = maxLength - count;

  const handleChange = (event, useDefault) => {
    const value = useDefault ? event : event.target.value;

    if (onChange) {
      onChange(event);
    }
    if (useCount) {
      setCount(value.length);
    }
  };

  const renderCount = useCount && (
    <Count>{lengthRemaing} caracteres restantes</Count>
  );

  useEffect(() => {
    if (defaultValue) {
      handleChange(defaultValue, true);
    }
  }, []);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <TextArea
        error={error}
        ref={inputRef}
        maxLength={maxLength}
        defaultValue={defaultValue}
        onChange={handleChange}
        onFocus={clearError}
        textAreaHeight={textAreaHeight}
        borderColor={universityColor}
        {...rest}
      />
      {renderCount}
      {error && <Error className={'error-label'}>{error}</Error>}
    </ContainerLabelInput>
  );
}
