import {
  ChipButton,
  CircleRadio,
  CloseButton,
  Container,
  Content,
  DateInvisibleWrapper,
  DaySelector,
  FieldLabel,
  MentorImage,
  NameWrapper,
  Overlay,
  Row,
  SubmitButton,
  TimeField,
  WrapperButton,
  WrapperItem,
  WrapperTimes,
} from './styled';
import { IconCloseModal } from 'assets/img/iconsCurriculum/iconCloseModal';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { CalendarIcon } from 'assets/mentorship/CalendarIcon';
import { AddIcon } from 'assets/mentorship/AddIcon';
import { ChevronIcon } from 'assets/mentorship/ChevronIcon';
import { SelectField } from '../../../../../../StaffPages/Mentorship/components/SelectField';
import { Form } from '@unform/web';
import NoteInput from 'components/UnformFields/noteInput';
import DatePickerCustom from '../../../../../../StaffPages/Opportunities/Components/Form/datePicker';
import { mentorshipApi } from '../../../../services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import userIcon from 'assets/img/userIcon.png';

export const ScheduleMentorshipModal = (props) => {
  const {
    isOpen,
    onClose,
    mentor,
    setMentor,
    openMentorModal,
    goToScheduledTab,
  } = props;
  const formRef = useRef(null);
  const { universityColor } = useContext(BaseLayoutContext);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedModality, setSelectedModality] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [options, setOptions] = useState();
  const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
  const datePickerRef = useRef(null);
  const otherRef = useRef(null);
  const [selectDateMarginLeft, setSelectDateMarginLeft] = useState(
    otherRef.current?.offsetLeft
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkIfIsAllFieldsFilled = useCallback(
    (data = formRef.current?.getData()) => {
      setIsAllFieldsFilled(
        selectedDate &&
          selectedModality &&
          selectedTime !== null &&
          data.career_goals
      );
    },
    [formRef, selectedDate, selectedModality, selectedTime]
  );

  useEffect(() => {
    checkIfIsAllFieldsFilled();
  }, [checkIfIsAllFieldsFilled]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await mentorshipApi.getMentorshipOptions(mentor.id);
      if (response?.mentorship_modality?.length === 1) {
        setSelectedModality(response?.mentorship_modality[0].value);
      }
      setOptions(response);
    };
    if (mentor?.id) {
      fetchOptions();
    }
  }, [mentor]);

  const handleClose = () => {
    setSelectedDate(null);
    setSelectedModality(null);
    setSelectedTime(null);
    formRef.current?.reset();
    onClose();
  };

  function getWeekDay(date) {
    const days = [
      'domingo',
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado',
    ];
    return days[date.getDay()];
  }

  const buildDaySelector = (nextDate) => {
    let dateParts = nextDate.date.split('-');
    const date = new Date(`${dateParts[0]}/${dateParts[1]}/${dateParts[2]}`);
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isSelected = selectedDate && selectedDate === formattedDate;
    return (
      <DaySelector
        key={nextDate.id}
        color={isSelected ? universityColor : '#949494'}
        onClick={() => setSelectedDate(formattedDate)}
        textColor={isSelected ? universityColor : '#606062'}
      >
        <CalendarIcon
          size={'20px'}
          color={isSelected ? universityColor : '#949494'}
        />
        <span>{moment(date).format('DD/MM')}</span>
        <p>{getWeekDay(date)}</p>
      </DaySelector>
    );
  };

  const weekdaysNumber = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  function getWeekdayKey(number) {
    return Object.entries(weekdaysNumber).find(
      ([_, value]) => value === number
    )?.[0];
  }

  const isAvailableDate = useCallback(
    (date) => {
      const dateFormatted = moment(date).format('YYYY-MM-DD');
      const currentDateFormatted = moment(new Date()).format('YYYY-MM-DD');
      if (
        !mentor?.available_days ||
        !date ||
        dateFormatted === currentDateFormatted
      )
        return false;
      const availableWeekDays = Object.keys(mentor.available_days)?.map(
        (availableDay) => weekdaysNumber[availableDay]
      );
      const isWeekdayAvailable = availableWeekDays.includes(date.getDay());

      const specificDates = mentor.specific_dates.map((specificDate) =>
        moment(specificDate.date).format('YYYY-MM-DD')
      );
      const isASpecificDate = specificDates.includes(dateFormatted);

      const absencesPeriods = mentor.absences.map((absence) => {
        return {
          start_date: new Date(absence.start_date),
          end_date: new Date(absence.end_date),
        };
      });

      const isDateInAbsencePeriod = absencesPeriods.some((absencesPeriod) => {
        return (
          date >= absencesPeriod.start_date && date <= absencesPeriod.end_date
        );
      });

      const dateWeekDay = getWeekdayKey(date.getDay());
      let allTimes = mentor.available_days[dateWeekDay] || [];

      if (isASpecificDate) {
        const specificDate = mentor.specific_dates.find(
          (item) =>
            moment(new Date(item.date)).format('YYYY-MM-DD') === dateFormatted
        );
        allTimes = [...allTimes, ...specificDate.schedules];
      }
      const scheduledMentorshipOnDay = mentor.scheduled_days.filter(
        (item) =>
          moment(new Date(item.date)).format('YYYY-MM-DD') === dateFormatted
      );

      const hasAvailableTimesOnDay =
        allTimes.length > scheduledMentorshipOnDay.length;

      return (
        (isWeekdayAvailable || isASpecificDate) &&
        hasAvailableTimesOnDay &&
        !isDateInAbsencePeriod
      );
    },
    [mentor]
  );

  const updateMentorNextDays = async (date) => {
    const nextDays = await mentorshipApi.getMentorNextDays(mentor.id, date);
    const newMentor = { ...mentor, next_days: nextDays };
    setMentor(newMentor);
    return nextDays;
  };

  const availableTimes = useMemo(() => {
    if (!mentor || !selectedDate) return null;
    const result = mentor.next_days.find(
      (nextDate) => nextDate.date === selectedDate
    )?.times;
    if (result) {
      return result;
    }
  }, [mentor, selectedDate]);

  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    const payloadData = {
      mentor: mentor.id,
      date: selectedDate,
      start_hour: availableTimes[selectedTime].start_hour,
      end_hour: availableTimes[selectedTime].end_hour,
      mentorship_modality: selectedModality,
      career_goals: data.career_goals.value,
      expected_by_student: data.expected_by_student,
    };
    const response = await mentorshipApi.createMentorship(payloadData);
    if (response) {
      handleClose();
      customSnackbar('Mentoria agendada!', 'confirmation');
      goToScheduledTab();
    } else {
      customSnackbar('Error ao agendar mentoria', 'error');
    }
    setIsSubmitting(false);
  };

  return (
    <Overlay isOpen={isOpen}>
      <Container>
        <Content>
          <CloseButton type={'button'} onClick={handleClose}>
            <IconCloseModal color={'#4B4B4D'} />
          </CloseButton>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row className="customDateSelector" maxWidth={'654px'}>
              {mentor?.next_days?.map((day) => buildDaySelector(day))}
              <DaySelector
                ref={otherRef}
                color={'#949494'}
                textColor={'#606062'}
                onClick={() => {
                  setSelectDateMarginLeft(otherRef.current?.offsetLeft + 150);
                  datePickerRef.current?.setOpen(true);
                }}
              >
                <AddIcon color={'#606062'} size={'20'} />
                <p>Outro dia</p>
              </DaySelector>
              <DateInvisibleWrapper marginLeft={`${selectDateMarginLeft}px`}>
                <DatePickerCustom
                  minDate={new Date()}
                  name="date"
                  customRef={datePickerRef}
                  filterDate={isAvailableDate}
                  handleUpdate={async (date) => {
                    if (!date) return;
                    const dateFormatted = moment(date).format('YYYY-MM-DD');
                    const nextDays = await updateMentorNextDays(dateFormatted);
                    if (nextDays[0].times.length >= 1) {
                      setSelectedDate(dateFormatted);
                    } else {
                      customSnackbar(
                        'Todos os horários para este dia foram ocupados.',
                        'error'
                      );
                    }
                  }}
                />
              </DateInvisibleWrapper>
            </Row>
            <Row marginTop={'2rem'}>
              <MentorImage
                src={mentor?.avatar || userIcon}
                onError={(e) => {
                  e.target.src = userIcon;
                  e.target.onerror = null;
                }}
              />
              <NameWrapper color={universityColor}>
                <span>{mentor?.name.name}</span>
                <div>
                  <a onClick={() => openMentorModal(mentor)}>
                    Visualizar perfil
                  </a>
                  <ChevronIcon color={universityColor} />
                </div>
              </NameWrapper>
            </Row>
            <Row marginTop={'2rem'} wrap>
              {options?.mentorship_modality?.find(
                (option) => option.value === 'online'
              ) && (
                <ChipButton
                  type={'button'}
                  color={universityColor}
                  selected={selectedModality === 'online'}
                  onClick={() => setSelectedModality('online')}
                  disabled={
                    !options?.mentorship_modality?.find(
                      (option) => option.value === 'online'
                    )
                  }
                >
                  Mentoria online
                </ChipButton>
              )}
              {options?.mentorship_modality?.find(
                (option) => option.value === 'in_person'
              ) && (
                <ChipButton
                  type={'button'}
                  color={universityColor}
                  selected={selectedModality === 'in_person'}
                  onClick={() => setSelectedModality('in_person')}
                  disabled={
                    !options?.mentorship_modality?.find(
                      (option) => option.value === 'in_person'
                    )
                  }
                >
                  Mentoria presencial
                </ChipButton>
              )}
            </Row>
            {selectedModality && (
              <WrapperTimes>
                {availableTimes?.map((time, index) => (
                  <WrapperItem
                    selected={selectedTime === index}
                    color={universityColor}
                    onClick={() => setSelectedTime(index)}
                  >
                    <CircleRadio
                      selected={selectedTime === index}
                      color={universityColor}
                    />
                    <TimeField>{time.start_hour}</TimeField>
                    <span>às</span>
                    <TimeField>{time.end_hour}</TimeField>
                  </WrapperItem>
                ))}
              </WrapperTimes>
            )}
            <FieldLabel>Objetivo de carreira*</FieldLabel>
            <SelectField
              name={'career_goals'}
              options={options?.career_goals}
              onChange={(option) => {
                const data = formRef.current.getData();
                data['career_goals'] = option;
                checkIfIsAllFieldsFilled(data);
              }}
            />
            <FieldLabel>
              Explique aqui as suas expectativas com a mentoria:
            </FieldLabel>
            <NoteInput
              name={'expected_by_student'}
              placeholder={'Digite aqui'}
              maxLength={250}
              useCount={true}
            />
            <WrapperButton>
              <SubmitButton
                color={universityColor}
                disabled={!isAllFieldsFilled}
              >
                {isSubmitting ? <FilterLoading /> : ' Solicitar agendamento'}
              </SubmitButton>
            </WrapperButton>
          </Form>
        </Content>
      </Container>
    </Overlay>
  );
};
