import React, { useContext, useEffect, useState } from 'react';
import EditIcon from 'assets/ComponentIcons/EditIcon';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ButtonsStudentsInfos from './ButtonsStudentsInfos';
import CoverLetterForm from 'views/Student/Docs/CoverLetter/CoverLetterForm';
import CoverLetterContainer from 'views/Student/Docs/CoverLetter/CoverLetterContainer';
import ModalInformation from 'components/informationModal';
import BaseLayoutContext from 'contexts/base-layout';

import {
  ContentInfos,
  StudentName,
  ContainerInfos,
  Paragraph,
  ContainerNameAndButton,
  ButtonEditInfos,
  NameAndParagraph,
  ListParagraph,
  ButtonsDiv,
  InformationsContainer,
  ContainerCurriculumButton,
  ContainerFlexButtons,
  FlexParagraph,
  ButtonMobileEditInfos,
} from './styled';
import ProfessionalStatusEngine from 'views/ProfessionalStatus/ProfessionalStatusEngine';

const InformationsProfile = (props) => {
  const {
    universityColor,
    gamificationLevel,
    openModalWithContent,
    handleIsLoadingState,
    openModal,
    setOpenModal,
    handleCurriculum,
  } = useContext(ProfileContext);

  const {
    profileData,
    professionalStatus,
    closeModal,
    openSnackbar,
    metadata,
    globalState,
    setGlobalState,
    setProfileData,
  } = props;

  const [hasCoverLetter, setHasCoverLetter] = useState(false);
  const { academic_profile } = useContext(BaseLayoutContext);

  const coursesVerify = academic_profile.courses.length === 0 ? true : false;

  useEffect(() => {
    setGlobalState(hasCoverLetter);
  }, [hasCoverLetter]);

  const updateHasCoverLetter = (state) => {
    setHasCoverLetter(state);
  };

  const disabledButton = gamificationLevel < 2 || coursesVerify;

  const configModal = {
    bubbleText: 'Atenção!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    ParagraphText: `Em breve, você poderá gravar seu vídeo pitch contando, 
    em 1 minuto, por que o recrutador deve te contratar. 
    Essa é a oportunidade para você vender seu peixe.`,
    buttonConfirmText: 'OK!',
  };

  const onClickButton = () => {
    openModalWithContent(
      <ProfessionalStatusEngine
        academicProfile={profileData}
        metadata={metadata}
        universityColor={universityColor}
        professional_status={professionalStatus}
        globalState={globalState}
        setProfileData={setProfileData}
        {...props}
      />,
      'Meu Status Profissional'
    );
  };

  const onClickCoverLetter = () => {
    handleIsLoadingState(true);
    if (profileData.has_cover_letter) {
      openModalWithContent(
        <CoverLetterContainer
          universityColor={universityColor}
          handleLoadingState={handleIsLoadingState}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
          {...props}
        />
      );
    } else {
      openModalWithContent(
        <CoverLetterForm
          universityColor={universityColor}
          openSnackbar={openSnackbar}
          closeModal={closeModal}
          handleLoadingState={handleIsLoadingState}
          updateHasCoverLetter={updateHasCoverLetter}
          openModalWithContent={openModalWithContent}
        />
      );
    }
  };

  return (
    <ContentInfos>
      <ModalInformation
        modalOpenState={openModal}
        buttonConfirmFunction={() => setOpenModal(false)}
        config={configModal}
        universityColor={universityColor}
      />
      <InformationsContainer>
        <ContainerInfos>
          <ContainerNameAndButton>
            <NameAndParagraph>
              <StudentName>{profileData?.name}</StudentName>

              {professionalStatus.is_open_to_offers ? (
                <Paragraph>(Aceito propostas)</Paragraph>
              ) : (
                <Paragraph>(Não aceito propostas)</Paragraph>
              )}
            </NameAndParagraph>

            <ButtonEditInfos
              universityColor={universityColor}
              onClick={() => onClickButton()}
            >
              <EditIcon color={universityColor} />
              Editar informações
            </ButtonEditInfos>
          </ContainerNameAndButton>

          <FlexParagraph>
            <Paragraph>{professionalStatus.status.label}</Paragraph>
            <ListParagraph>
              {professionalStatus.open_to_offers.label}
            </ListParagraph>
          </FlexParagraph>

          <ButtonMobileEditInfos
            universityColor={universityColor}
            onClick={() => onClickButton()}
          >
            <EditIcon color={universityColor} />
            Editar informações
          </ButtonMobileEditInfos>
        </ContainerInfos>
      </InformationsContainer>

      <ButtonsDiv>
        <ContainerFlexButtons>
          <ButtonsStudentsInfos
            title={'Carta de Apresentação'}
            universityColor={universityColor}
            onClick={() => onClickCoverLetter()}
          />

          <ButtonsStudentsInfos
            title={'Gravar Vídeo Pitch'}
            universityColor={universityColor}
            onClick={() => setOpenModal(true)}
          />
        </ContainerFlexButtons>

        <ContainerCurriculumButton>
          <ButtonsStudentsInfos
            title={'Gerar currículo do futuro'}
            universityColor={universityColor}
            onClick={() => handleCurriculum()}
            disabled={disabledButton}
            blocked={disabledButton}
          />
        </ContainerCurriculumButton>
      </ButtonsDiv>
    </ContentInfos>
  );
};

export default InformationsProfile;
