import React, { useEffect, useState, Fragment } from 'react';
import { shape } from 'prop-types';

import {
  disableNotifications,
  enableNotifications,
} from 'services/student/notifications';

import { Loading } from 'components/Styled';
import * as S from './styled';

function Unsubscribe({ match }) {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const handleEffect = async() => {
      const response = await disableNotifications(match.params.id);
      if (response.status === 204) {
        setSuccess(true);
      }  
      setLoading(false);
    }
    handleEffect();
  }, []);

  const handleEnable = async () => {
    setLoading(true);

    const response = await enableNotifications(match.params.id);

    if (response.status === 200) {
      setEnabled(true);
    }

    setLoading(false);
  };

  return (
    <S.GridWrapper container direction="column" alignItems="center">
      <S.ItemContainer item xs md={12}>
        {loading ? (
          <Loading />
        ) : (
          success &&
          !enabled && (
            <Fragment>
              <h1>Cancelamento de notificações concluído</h1>
              <S.Subtitle>
                Você não receberá mais as notificações da plataforma Workalove
              </S.Subtitle>
              <h5>
                Você desabilitou as notificações por engano?{' '}
                <S.Link onClick={handleEnable}>
                  Clique aqui para habilitar novamente
                </S.Link>
              </h5>
              <h5>
                Você também pode gerenciar todas as notificações da Workalove em
                configurações dentro da plataforma.
              </h5>
            </Fragment>
          )
        )}

        {enabled && (
          <S.Success>
            Notificações habilitadas! Você receberá novamente as nossas
            notificações.
          </S.Success>
        )}

        {!loading && !success && (
          <S.Error>
            Falha ao cancelar as notificações! Tente novamente mais tarde
          </S.Error>
        )}
      </S.ItemContainer>
    </S.GridWrapper>
  );
}

Unsubscribe.propTypes = {
  match: shape.isRequired,
};

export default Unsubscribe;
