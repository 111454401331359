import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SubContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border-bottom: 2px solid #eef2f4;
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 9px;
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;
  padding: 2rem;
  flex-direction: column;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  width: 100%;
  justify-content: space-between;
`;

export const SearchFilter = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  height: 42px;

  input {
    border: none;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
  }

  > button {
    border-radius: 4px;
    border: none;
    background: #009291;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  svg {
    color: #ffffff;
  }
`;

export const OrderFilter = styled.div`
  display: flex;
  align-items: center;
  z-index: 10;
`;

export const StyledSelect = styled(Select)`
  width: 130px;

  span {
    display: none;
    align-items: center;
  }

  .react-select__control {
    box-shadow: rgb(255, 255, 255) 0 3px 8px;
    border-color: #c4c4c4;
    height: 42px;
    z-index: 1;
    cursor: pointer;
    background-color: #fff;
    &:hover {
      border-color: #009291;
      box-shadow: 0 0 0 1px #009291;
    }
  }

  .react-select__control--is-focused {
    border-color: #009291;
    box-shadow: 0 0 0 1px #009291;
  }

  .react-select__menu {
    width: 260px;
    margin-left: -130px;
  }

  .react-select__placeholder {
    color: #606062;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .react-select__option {
    color: #333;
  }

  .react-select__option--is-selected {
    background-color: #009291;
    color: #fff;
  }

  .react-select__option--is-focused {
    background-color: #e0f5f5;
    color: #009291;
  }
`;
