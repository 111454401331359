export const StudentCategory = {
  ACTIVE: 'active',
  WITH_CURRICULUM: 'curriculum',
  APPLICATIONS: 'applications',
  INACTIVE: 'inactive',
  WITHOUT_CURRICULUM: 'not_curriculum',
  DIRECT_HIRIGN: 'finished_system',
};

export const defaultStudentCategories = [
  {
    name: 'Ativos',
    description: 'Identifique os estudantes que acessaram o sistema.',
    value: StudentCategory.ACTIVE,
  },
  {
    name: 'Com Currículo',
    description:
      'Identifique os estudantes mais adequados para suas oportunidades de aprendizagem e ' +
      'acione-os para as vagas publicadas.',
    value: StudentCategory.WITH_CURRICULUM,
  },
  {
    name: 'Candidatura',
    description:
      'Identifique os estudantes em fase de candidatura e convide-os para' +
      ' mentorias.',
    value: StudentCategory.APPLICATIONS,
  },
  {
    name: 'Contratações diretas',
    description:
      'Identifique os estudantes que foram contratados dentro do sistema.',
    value: StudentCategory.DIRECT_HIRIGN,
  },
  {
    name: 'Inativos',
    description:
      'Identifique os estudantes que ainda não acessaram o sistema e convide-os' +
      ' para participar.',
    value: StudentCategory.INACTIVE,
  },
  {
    name: 'Sem currículo',
    description: 'Identifique os estudantes que ainda não geraram o currículo.',
    value: StudentCategory.WITHOUT_CURRICULUM,
  },
];
