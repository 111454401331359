import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Container, InputRadio, LabelInput, ContentImg } from './styled';
import Tooltip from 'components/NewTooltip';

import { RiEyeLine } from "react-icons/ri";
import { RiEyeCloseLine } from "react-icons/ri";

const RadioButton = ({ name, options, optionValue, setOptionValue, ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = '' } = useField(name);

  const [checked, setChecked] = useState('');

  useEffect(() => {
    setChecked(optionValue);
  }, [optionValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return (
          (refs.find((ref) => ref.checked) &&
            refs.find((ref) => ref.checked).value) ||
          ''
        );
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);
        setChecked(item?.value);
        if (item) {
          item.checked = true;
        }
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const returnImage = () => {
    if (optionValue === 'enabled') {
      return <RiEyeLine size={21} color="#009291" />
    } else {
      return <RiEyeCloseLine size={21} color="#009291" />
    }
  }

  return (
    <Fragment>
      {options.map((option, index) => (
        <Tooltip
          key={option.id}
          direction="top"
          colorSchema="black"
          text={option.bubble}
          minWidth="100px"
          isMultiLine={true}
        >
          <Container>
            <LabelInput
              htmlFor={option.id}
              checked={option.value === checked}
              color={option.color}
            >
              <ContentImg>
                {returnImage()}
              </ContentImg>
              <InputRadio
                ref={(ref) => ref && (inputRefs.current[index] = ref)}
                id={option.id}
                type={option.type}
                name={name}
                defaultChecked={defaultValue === option.value}
                value={option.value}
                onChange={(e) => {
                  setChecked(e.target.value);
                  setOptionValue(e.target.value);
                  if (rest.onChange) rest.onChange(e);
                }}
                {...rest}
              />
            </LabelInput>
          </Container>
        </Tooltip>
      ))}
    </Fragment>
  );
};

export default RadioButton;
