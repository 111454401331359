import React, { useEffect, useRef, Fragment } from 'react';

import { useField } from '@unform/core';
import {
  UnInputLink,
  UnInputLabel,
  UnImageLink,
  ContainerLabelInput,
  Error,
} from './style';
import { LinkIcon } from 'assets/ComponentIcons/evidencesIcon';


export default function LinkInput({ label, name, universityColor, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <LinkIcon color={universityColor} size={20} />
      <UnInputLink 
        ref={inputRef}
        universityColor={universityColor}
        defaultValue={defaultValue} 
        {...rest} 
      />
      {error && <Error>{error}</Error>}
    </ContainerLabelInput>
  );
}
