import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

class BackOfficeApi {
  getOptionsUniversitys = async () => {
    return await authenticatedAPI
      .get('api/internal/backoffice/email-list/universities')
      .then((response) => response.data)
      .catch((error) => error);
  };

  deleteEmail = async (id) => {
    return await authenticatedAPI
      .delete(`api/internal/backoffice/email-list/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  postCreateEmail = async (data) => {
    try {
      const response = await authenticatedAPI.post(
        'api/internal/backoffice/email-list',
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  getEmails = async (term = '') => {
    return await authenticatedAPI
      .get(`api/internal/backoffice/email-list?name=${term}`)
      .then((response) => response.data)
      .catch((error) => error);
  };
}

export default new BackOfficeApi();
