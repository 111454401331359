import styled from 'styled-components';

export const CardDiv = styled.div`
  width: 286px;
  min-height: 535px;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 0px 13px rgb(0 0 0 / 6%);

  position: relative;

  @media (max-width: 936px) {
    width: 246px;
    height: auto;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 209px;

  background-color: ${(props) => props.universityColor || '#009291'};

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    z-index: 1;
  }

  img {
    width: 75%;
    z-index: 2;
  }
`;

export const Title = styled.h5`
  max-width: 235px;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 3;
  margin-bottom: 22px;
`;

export const ContainerInformations = styled.div`
  padding: 15px 20px;
`;

export const ContainerDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperProgressBar = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17px;
  margin-top: 20px;

  @media (max-width: 936px) {
    flex-direction: column;
    gap: 10px;

    > button {
      width: 100%;
    }
  }
`;

export const WrapperIcons = styled.div`
  display: flex;
  gap: 8px;
`;

export const SubscribeButton = styled.button`
  border: none;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  color: #ffff;
  padding: 7px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 11px;
  width: 100px;
  cursor: pointer;
`;

export const DetailsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) =>
    !props.changeStyle
      ? 'unset'
      : `1px solid ${
          props.universityColor ? props.universityColor : '#009291'
        }`};
  padding: 7px 8px;
  ${(props) => (!props.changeStyle ? 'width: 100%' : '')};
  border-radius: 4px;
  color: ${(props) =>
    !props.changeStyle
      ? '#FFF'
      : `${props.universityColor ? props.universityColor : '#009291'}`};
  background-color: ${(props) =>
    !props.changeStyle ? props.universityColor : 'transparent'};
  font-weight: bold;
  cursor: pointer;
  font-size: 11px;
  height: 26px;
  min-width: 100px;
`;

export const Quantity = styled.div`
  position: absolute;
  width: auto;
  height: 22px;
  bottom: 20px;
  left: 17px;
  color: ${(props) => props.universityColor};
  background-color: #fff;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 12px;
  padding-top: 2px;
  z-index: 2;
`;

export const ProgressBar = styled.div`
  width: 138px;
  height: 8px;
  background: #eaeaea;
  border-radius: 20px;
  position: relative;

  margin-bottom: 8px;
`;

export const ProgressBarPercentual = styled.div`
  width: ${(props) => props.percentual};
  height: 8px;
  background: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 20px;
  position: absolute;
  left: 0;
`;

export const Information = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: #606062;
  text-align: start;
  margin: 0;
`;

export const InformationP = styled.p`
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
`;
