export function formatWorkload(event, setValue) {
  function isValidPastValue(value) {
    let error = false;
    const valueSplit = value.split(':');
    if (value === ':') {
      error = true;
    }
    if (valueSplit.length > 2) {
      error = true;
    }
    if (isNaN(valueSplit[0])) {
      error = true;
    }
    if (valueSplit.length === 2 && isNaN(valueSplit[1])) {
      error = true;
    }
    return !error;
  }

  const targetValue = event.target.value;
  const lastChar = targetValue[targetValue.length - 1];
  if (!isValidPastValue(targetValue)) return;
  const onlyNums = targetValue.replace(/[^0-9]/g, '');
  const hasSeparator = targetValue.indexOf(':') > -1;
  if (targetValue === '') {
    setValue('');
    return;
  }
  if (hasSeparator) {
    const [hours, minutes] = targetValue.split(':');
    if (minutes.length === 2) {
      if (hours.length > 3) {
        setValue(`${hours.slice(0, 3)}:00`);
      }
      if (hours.length === 0) {
        setValue('');
      } else {
        setValue(`${hours.slice(0, hours.length)}:00`);
      }
    } else if (minutes.length < 2) {
      if (hours.length === 1) {
        setValue('');
      } else if (hours.length > 3) {
        setValue(`${hours.slice(0, 3)}:00`);
      } else {
        setValue(`${hours.slice(0, hours.length - 1)}:00`);
      }
    } else {
      if (hours.length >= 3) {
        setValue(`${hours.slice(0, 3)}:00`);
      } else {
        setValue(`${hours !== '0' ? hours : ''}${lastChar}:00`);
      }
    }
  } else {
    setValue(`${onlyNums.slice(0, 3)}:00`);
  }
}
