import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Container, InputRadio, LabelInput, SubTitle } from './style';

const RadioFeedBack = ({
  name,
  options,
  optionValue,
  setOptionValue,
  ...rest
}) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = '' } = useField(name);

  const [checked, setChecked] = useState('');

  useEffect(() => {
    setChecked(optionValue);
  }, [optionValue]);

  useEffect(() => {}, [optionValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return (
          (refs.find((ref) => ref.checked) &&
            refs.find((ref) => ref.checked).value) ||
          ''
        );
      },
      setValue(refs, value) {
        const item = refs.find((ref) => ref.value === value);
        setChecked(item.value);
        if (item) {
          item.checked = true;
        }
      },
      clearValue: (refs) => {
        const inputRef = refs.find((ref) => ref.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Fragment>
      {options.map((option, index) => (
        <Container>
          <LabelInput
            htmlFor={option.id}
            key={option.id}
            checked={option.value === checked}
            color={option.color}
            primaryImg={option.imgPrimary}
            SecundaryImg={option.imgSecundary}
            bubble={option.bubble}
          >
            <InputRadio
              ref={(ref) => ref && (inputRefs.current[index] = ref)}
              id={option.id}
              type={option.type}
              name={name}
              defaultChecked={defaultValue === option.value}
              value={option.value}
              {...rest}
            />
          </LabelInput>
          {option.description && <SubTitle>{option.description}</SubTitle>}
        </Container>
      ))}
    </Fragment>
  );
};

export default RadioFeedBack;
