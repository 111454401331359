import FilterModal from 'components/FilterModal';
import { FilterGrid, ContainerBetween } from './styles';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { handleFieldMask } from 'utils/validation';
import { talentsFilterService } from 'components/NewTalentList/services/filters.service';
import { useContext, useState } from 'react';
import { TalentsContext } from 'components/NewTalentList/contexts/TalentListContext';

export function IndicationsFilter({}) {
  const [citiesOption, setCitiesOptions] = useState([]);
  const [loadCities, setLoadCities] = useState(false);
  const { filterOptions, getCities } = talentsFilterService;
  const { getIndications, indicationFilterState, setIndicationFilterState } =
    useContext(TalentsContext);

  async function onApplyFilters(filters) {
    const params = talentsFilterService.getUrlParamsFilters(filters);
    getIndications(params);
  }

  function onClearFilters() {
    getIndications();
  }

  /**
   *
   * @param {Array} states
   * @returns
   */
  async function getCitiesOptions(states) {
    if (!states.length) {
      setCitiesOptions([]);
      setIndicationFilterState({
        ...indicationFilterState,
        cities: [],
        states: [],
      });
      return;
    }
    setLoadCities(true);
    const cities = await getCities(states);
    if (cities?.error) {
      setLoadCities(false);
      return;
    }
    setCitiesOptions(cities);
    setLoadCities(false);
  }

  const renderFilterContent = () => [
    <FilterGrid>
      <Filter
        name={'degree_level'}
        label={'Tipo do curso'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.degreeLevel}
      />
      <Filter
        name={'courses'}
        label={'Curso'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.courses}
      />
      <Filter
        name={'student_status'}
        label={'Status acadêmico'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.studentStatus}
      />
      <Filter
        name={'periods'}
        label={'Período'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.periods}
      />
      <Filter
        name={'universities'}
        label={'Instituição de ensino'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.universities}
      />
      <Filter
        name={'course_modality'}
        label={'Modalidade'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.modality}
      />
    </FilterGrid>,
    <FilterGrid>
      <Filter
        name={'name'}
        label={'Nome do estudante'}
        type={FilterType.TEXT}
        settings={{
          placeholder: 'Digite aqui...',
        }}
      />
      <Filter
        name={'professional_status'}
        label={'Status profissional'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.professionalStatus}
      />
      <Filter
        name={'working_area'}
        label={'Atuando na área de formação'}
        type={FilterType.SINGLE_SELECT}
        options={filterOptions.workingArea}
      />
      <ContainerBetween>
        <Filter
          label="Estado"
          name={'states'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.states}
          settings={{
            onChange: (event) => {
              getCitiesOptions(event);
            },
          }}
        />
        <Filter
          label="Cidade"
          name={'cities'}
          type={FilterType.MULTI_SELECT}
          options={citiesOption}
          settings={{
            loading: loadCities,
          }}
        />
      </ContainerBetween>
      <Filter
        label="Requisitos"
        name={'requirements'}
        type={FilterType.CHECKBOX}
        options={[
          {
            label: 'Apenas estudantes com perfil campeão',
            value: 'is_champion_profile',
          },
          {
            label: 'Apenas estudantes com currículo',
            value: 'has_curriculum',
          },
          {
            label: 'Apenas estudantes com experiência de carreira',
            value: 'has_experience',
          },
        ]}
      />
    </FilterGrid>,
    <FilterGrid>
      <Filter
        name={'professional_experience_areas'}
        label={'Competências técnicas '}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.professionalExperienceAreas}
      />
      <Filter
        name={'behavioral_attributes'}
        label={'Competências comportamentais'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.behavioralAttributes}
      />
      <Filter
        name={'personality_attributes'}
        label={'Personalidade'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.personalityAttributes}
      />
      <Filter
        name={'professional_offers'}
        label={'Objetivo de carreira'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.professionalOffers}
      />
      <Filter
        name={'professional_experience'}
        label={'Experiência profissional'}
        type={FilterType.TEXT}
      />
      <Filter
        name={'curriculum_updated'}
        label={'Currículo atualizado nos últimos'}
        type={FilterType.SINGLE_SELECT}
        options={filterOptions.curriculumUpdated}
      />
    </FilterGrid>,
    <FilterGrid>
      <Filter
        name={'special_needs'}
        label={'PCD - Pessoa com deficiência'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.specialNeeds}
      />
      <Filter
        name={'color_or_race'}
        label={'Cor/Raça'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.colorOrRace}
      />
      <Filter
        name={'gender_identities'}
        label={'Identidade de gênero'}
        type={FilterType.MULTI_SELECT}
        options={filterOptions.genderIdentities}
      />
    </FilterGrid>,
  ];
  return (
    <FilterModal
      filterState={indicationFilterState}
      setFilterState={setIndicationFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={() => {}}
      setAppliedFilters={() => {}}
      zIndex={9999}
      tabs={['Dados acadêmicos', 'Dados pessoais', 'Requisitos', 'Diversidade']}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}
