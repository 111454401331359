import Input from '../../../../../../Form/input';
import { useContext, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { EnrolledStudentsList } from '../../../ModalTrackEnrollments/components/EnrolledStudentsList';
import { getListExtensionProjectStudentsFinish } from '../../../../../../../services';
import { PaginationContainer } from '../../../ModalTrackEnrollments/styled';
import Pagination from 'components/CustomPagination';
import LimitSelect from '../../../ModalTrackEnrollments/components/SelectLimit';
import { Container, ContainerHeader } from './styled';
import SelectOrdering from '../../components/SelectOrdering';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';
import { handleFieldMask } from 'utils/validation';

export function StepTwo({ refStepTwo, setHasRegister }) {
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [students, setStudents] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [studentCount, setStudentCount] = useState(0);
  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState('name');

  const {
    formDataFinishProject,
    extensionProjectId,
    informationItem,
  } = useContext(ExtensionProjectsContext);

  useEffect(() => {
    if (students.length > 0) {
      setHasRegister(true);
    }
  }, [students]);

  async function getStudents({ limitPerPage, offset, ordering }) {
    setRequestInProgress(true);
    const data = await getListExtensionProjectStudentsFinish(
      extensionProjectId,
      limitPerPage,
      offset,
      ordering
    );
    const { results, count } = data;
    setStudentCount(count);
    setStudents(results);
    setRequestInProgress(false);
  }

  const maskScore = (inputValue) => {
    if (!inputValue) {
      return '';
    }
    let onlyDigits = inputValue.replace(/\D/g, '');
    if (onlyDigits.length > 2) {
      return `${onlyDigits.slice(0, onlyDigits.length - 2)},${onlyDigits.slice(
        onlyDigits.length - 2,
        onlyDigits.length
      )}`;
    } else {
      return onlyDigits;
    }
  };

  return (
    <Container>
      <h1>Avaliar estudantes</h1>
      <p>
        Aqui você pode avaliar os estudantes que se inscreveram no projeto,
        aprovando-os ou não e deixando uma nota e carga horária para os
        estudantes que foram aprovados.
      </p>
      {informationItem.exist_disciplines ? (
        <ContainerHeader>
          <strong>Nota</strong>
          <span>
            Defina uma nota para os estudantes que participaram do projeto.
          </span>
        </ContainerHeader>
      ) : null}
      <header
        style={{
          marginTop: '16px',
        }}
      >
        <Form ref={refStepTwo}>
          {informationItem.exist_disciplines ? (
            <Input
              name="score"
              placeholder="Digite aqui..."
              type="text"
              maxlength={6}
              onKeyUp={async (event) => {
                handleFieldMask(event, maskScore);
              }}
            />
          ) : null}
        </Form>
        <div>
          <SelectOrdering
            ordering={ordering}
            setOrdering={setOrdering}
            onChange={(ordering) => {
              getStudents({
                limitPerPage,
                offset: (page - 1) * limitPerPage,
                ordering,
              });
            }}
          />
          <LimitSelect
            setLimit={setLimitPerPage}
            pageSize={limitPerPage}
            ordering={ordering}
            onChange={(e) => {
              setPage(1);
              getStudents(e);
            }}
            setPage={setPage}
          />
        </div>
      </header>
      <EnrolledStudentsList
        ordering={ordering}
        status={true}
        limitPerPage={limitPerPage}
        page={page}
        getStudents={getStudents}
        students={students}
        requestInProgress={requestInProgress}
        showStatus={false}
        finalization={true}
      />
      <PaginationContainer>
        <Pagination
          className="pagination-bar"
          currentPage={page}
          totalCount={studentCount}
          limit={limitPerPage}
          onPageChange={(page) => {
            setPage(page);
            getStudents({
              limitPerPage,
              offset: (page - 1) * limitPerPage,
              ordering,
            });
          }}
          setLimit={setLimitPerPage}
          setCurrentPage={setPage}
          showLimit={false}
        />
        <div
          style={{
            display: 'flex',
            marginTop: '-1rem',
          }}
        >
          <LimitSelect
            setLimit={setLimitPerPage}
            pageSize={limitPerPage}
            ordering={ordering}
            onChange={(e) => {
              setPage(1);
              getStudents(e);
            }}
            setPage={setPage}
          />
        </div>
      </PaginationContainer>
    </Container>
  );
}
