import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-left: 2px;
`;

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 8px;
  position: relative;
`;

export const SelectTime = styled.div`
  overflow-y: auto;
  max-height: 100px;
  position: absolute;
  top: 65px;
  z-index: 2;
  background-color: #fff;
  width: 100%;
  border: 1px solid #d0d0d0;
  padding: 10px 0px;
  border-radius: 4px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaaaaa;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #999999;
  }
`;

export const UnInput = styled.input`
  border: ${(props) =>
    props.error ? '1px solid #FFCECE' : ' 1px solid #d0d0d0'};
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  background-color: ${(props) => (props.error ? '#FFEDED' : '#FFFF')};
  &:focus {
    border: 2px solid #2784ff;
  }
`;

export const NumberDiv = styled.div`
  padding: 10px;

  &:hover {
    background-color: #80c9c8;
  }
  background-color: ${(props) => (props.selected ? '#009291' : '#FFF')};
  color: ${(props) => (props.selected ? '#FFF' : '#000')};
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;
