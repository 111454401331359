import styled from 'styled-components';
import StarActivity from '../../../../../assets/Oportutinites/StarActivity.svg';
import leftArrow from '../../../../../assets/Oportutinites/leftArrow.svg';
import rightArrow from '../../../../../assets/Oportutinites/rightArrow.svg';
import GreenPlus from '../../../../../assets/Oportutinites/GreenPlus.svg';

export const WrapperPublished = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 4em 5em;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-weight: 500;
  color: #009291;
`;

export const WrapperDiv = styled.div`
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow-x: auto;
  overflow-y: overlay;
  background-color: #ffffff;
  padding: 4.5em 2em;
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

export const CardTitle = styled.h4`
  font-weight: 400;
  color: #009291;
  font-size: 1.5rem;
`;
export const CardSubTitle = styled.p`
  color: #606062;
  font-size: 0.9rem;
`;

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
`;

export const Card = styled.div`
  width: 260px;
  height: 365px;
  background-color: #ffff;
  border-radius: 16px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-right: 20px;
`;

export const CardImg = styled.img`
  position: absolute;
  top: 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 260px;
  height: 181px;
`;

export const ActivityType = styled.div`
  width: auto;
  padding-right: 10px;
  height: 23px;
  background-color: #ffff;
  border-radius: 16px;
  position: absolute;
  z-index: 2;
  top: 14em;
  margin-left: 10px;
  font-size: 10px;
`;
export const StarActivityImgDiv = styled.div`
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 4px;
`;

export const StarActivityImg = styled.img`
  margin-top: 6px;
`;

export const StarActivityWrapper = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  text-align: center;
`;

export const StarActivityP = styled.p`
  color: #009291;
  font-weight: 400;
  margin-left: 8px;
  font-size: 12px;
  margin-top: 1px;
`;
export const TitleH4 = styled.h4`
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold;
  font-size: 14.5px;
  position: absolute;
  top: 13em;
  z-index: 2;
  left: 10px;
`;

export const Informations = styled.div`
  width: 100%;
  height: 20%;
  position: absolute;
  margin-top: 17em;
`;

export const TagIcons = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const IconImage = styled.img`
  width: 7%;
  margin-left: 10px;
  margin-right: 10px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;
`;

export const ButtonLeft = styled.button`
  background-image: url(${leftArrow});
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:active {
    transform: scale(0.8);
  }
`;
export const ButtonRight = styled.button`
  background-image: url(${rightArrow});
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:active {
    transform: scale(0.8);
  }
`;

export const MoreInfo = styled.div`
  margin-top: 11px;
  margin-right: 20px;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button {
    color: #009291;
    font-weight: 700;
    font-size: 12px;
    text-decoration: underline;
    text-decoration-color: #009291;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
`;

export const DashedBorder = styled.div`
  width: 580px;
  height: 150px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='2' stroke-dasharray='13' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PlusButton = styled.button`
  width: 214px;
  min-height: 42.48px;
  height: auto;
  border-radius: 8px;
  background-color: #009291;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #ffff;
  font-weight: bold;
  font-size: 15px;
`;

export const PlusImg = styled.img`
  background-image: url(${GreenPlus});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  width: 27.25px;
  height: 27.25px;
  background-color: #ffff;
  border-radius: 8px;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  width: 250px;

  margin-bottom: 8px;

  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
