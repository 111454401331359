import React, { useState, useContext, useEffect, useRef } from 'react';

// Header Components
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import HeaderBackground from 'assets/SettingsPage/HeaderBackground.png';

// Style
import {
  Container,
  Wrapper,
  SelectWrapper,
  MainTitle,
  WrapperColor,
  Title,
  SubTitle,
  Input,
  ColorInput,
  PreviewButton,
  SubmitButton,
  InputWrapper,
  ErrorMessage,
  Content,
} from './styled';

import {
  getIesEmailConfig,
  generatePreview,
  updateIesEmailConfig,
} from './services';

import Select from './SearchableSelect';

import * as Yup from 'yup';

import PreviewModal from './PreviewModal';
import BaseLayoutContext from 'contexts/base-layout';

function SettingsPage({ setNoScroll }) {
  const { handleIsLoadingState, openSnackbar, metadata } = useContext(
    BaseLayoutContext
  );
  const [color, setColor] = useState('');
  const [html, setHtml] = useState();
  const [previewModalVisibility, setPreviewModalVisibility] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: 'Link de direcionamento',
    value: 'link',
  });
  const [error, setError] = useState();
  const [universityName, setUniversityName] = useState();
  const [colorError, setColorError] = useState('');

  useEffect(() => {
    setUniversityName(metadata.university);
  }, []);

  const InputRef = useRef(null);

  useEffect(() => {
    getCurrentConfig();
  }, []);

  const getCurrentConfig = async () => {
    const config = await getIesEmailConfig();

    setColor(config.email_color, handleIsLoadingState(false));

    if (config.contact_email !== '') {
      setSelectedOption({ label: 'E-mail de contato', value: 'email' });
      InputRef.current.value = config.contact_email;
    } else if (config.contact_url !== '') {
      setSelectedOption({ label: 'Link de direcionamento', value: 'link' });
      InputRef.current.value = config.contact_url;
    }
  };

  const setDataToPreview = async () => {
    setHtml(await generatePreview(color.replace('#', '')));
    setPreviewModalVisibility(true);
    setNoScroll(true);
  };

  const submitConfig = async () => {
    const isColorValid = await validateColor();

    if (!isColorValid) {
      return;
    }

    if (selectedOption.value === 'email') {
      return validateEmailAndSubmit();
    }
    return validateLinkAndSubmit();
  };

  const validateEmailAndSubmit = async () => {
    try {
      await validations.emailValidation.validate(InputRef.current.value);
      setError();
      openSnackbar('Configuração salva com sucesso', false);

      const formData = new FormData();

      formData.append('email_color', color);
      formData.append('contact_email', InputRef.current.value);
      formData.append('contact_url', '');

      await updateIesEmailConfig(formData);
    } catch (err) {
      setError(err.errors[0]);
    }
  };

  const validateLinkAndSubmit = async () => {
    try {
      await validations.linkValidation.validate(InputRef.current.value);
      setError();
      openSnackbar('Configuração salva com sucesso', false);

      const formData = new FormData();

      formData.append('email_color', color);
      formData.append('contact_email', '');
      formData.append('contact_url', InputRef.current.value);

      await updateIesEmailConfig(formData);
    } catch (err) {
      setError(err.errors[0]);
    }
  };

  const validations = {
    emailValidation: Yup.string()
      .email('Insira um e-mail válido')
      .max(255, 'Máximo de 255 caracteres'),
    linkValidation: Yup.string()
      .url('Insira um link válido')
      .max(500, 'Máximo de 500 caracteres'),
    colorValidation: Yup.string()
      .notRequired()
      .test(
        'colorValidation',
        'Informe uma cor hexadecimal válida',
        function (value) {
          if (!value || value.length === 0) {
            return true;
          }
          return /^#([0-9a-f]{3}){1,2}\b/i.test(value);
        }
      ),
  };

  const handleFieldTypeChange = (e) => {
    InputRef.current.value = '';
    setSelectedOption(e);
  };

  const selectOptions = [
    { label: 'Link de direcionamento', value: 'link' },
    { label: 'E-mail de contato', value: 'email' },
  ];

  const validateColor = async () => {
    try {
      await validations.colorValidation.validate(color);
      setColorError('');
      return true;
    } catch (err) {
      setColorError('Informe uma cor hexadecimal válida para a cor principal');
      return false;
    }
  };

  return (
    <Content>
      {previewModalVisibility && (
        <PreviewModal
          html={html}
          closeModal={() => {
            setPreviewModalVisibility(false);
            setNoScroll(false);
          }}
        />
      )}
      <Container>
        <Wrapper>
          <SubTitle>
            Aqui você pode personalizar os e-mails de notificações que serão
            enviados automaticamente para os estudantes e para as empresas. Isso
            é importante para fortalecer a marca da sua Instituição de Ensino.
          </SubTitle>
        </Wrapper>
        <Wrapper row={true}>
          <Wrapper half={true}>
            <Title>Cor principal</Title>
            <SubTitle>
              Aplicada no cabeçalho e na cor do botão de ação.
            </SubTitle>
          </Wrapper>
          <WrapperColor row={true}>
            <ColorInput
              style={
                !colorError
                  ? {
                      marginTop: '-5px',
                    }
                  : {}
              }
              type="color"
              value={color}
              onChange={(e) => {
                setColor(e.target.value);
              }}
            />
            <div
              style={
                colorError
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: '5px',
                    }
                  : {}
              }
            >
              <Input
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                }}
                isColorInput={true}
                maxLength={7}
              />
              {colorError && <ErrorMessage>{colorError}</ErrorMessage>}
            </div>
          </WrapperColor>
        </Wrapper>
        <Wrapper>
          <Wrapper>
            <Title>Contato da Instituição</Title>
            <SubTitle>
              Insira aqui um link (como whatsapp e chat de suporte) ou um e-mail
              para que os seus estudantes entrem em contato com a Instituição ao
              clicar no botão de ”Contatar equipe {universityName}”. Você pode
              visualizar o botão ao clicar em "pré-visualizar e-mail”.
            </SubTitle>
          </Wrapper>
          <Wrapper row={true}>
            <SelectWrapper>
              <Select
                placeholder="Tipo"
                options={selectOptions}
                value={selectedOption}
                onChange={(e) => {
                  if (e) {
                    handleFieldTypeChange(e);
                  }
                }}
              />
            </SelectWrapper>
            <InputWrapper>
              <Input
                error={error}
                placeholder={
                  selectedOption.value === 'link'
                    ? 'https://www.example.com'
                    : 'email@example.com'
                }
                ref={InputRef}
              />
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </InputWrapper>
          </Wrapper>
        </Wrapper>
        <Wrapper row={true}>
          <Wrapper half={true}>
            <Title>Simulação</Title>
            <PreviewButton onClick={setDataToPreview}>
              Pré-visualizar e-mail
            </PreviewButton>
            <SubTitle>
              Verifique se a cor escolhida é de fácil legibilidade com o branco.
            </SubTitle>
          </Wrapper>
        </Wrapper>
        <SubmitButton onClick={submitConfig}>Salvar ajustes</SubmitButton>
      </Container>
    </Content>
  );
}

export default SettingsPage;
