import styled from "styled-components";
import closeIcon from 'assets/Oportutinites/X.svg';

export const BodyChallenges = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`
export const ModalRegisterChallengesSTwo = styled.div`
    width: 520px;
    height: 740px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`
export const ModalHeader = styled.div`
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
`;
export const ModalTitle = styled.div`
    width: 80%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 0 0 40px;
`
export const CardImageModal = styled.img`
    width: 40px;
    height: 40px;
`
export const CardTitleModal = styled.h3`
    color: #009291;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 0 15px;
`
export const CloseButton = styled.span`
    background-color: #f0f0f0;
    width: 30px;
    height: 30px;
    background-position: center;
    background-size: 40%;
    background-repeat: no-repeat;
    cursor: pointer !important;
    border-radius: 50%;
    transition: filter 0.2s;
    background-image: url(${closeIcon});

    &:hover {
        filter: brightness(0.9);
    }
`;
export const InputsModalChallenge = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    margin-top: 30px;
`
export const ButtonPublishChallenge = styled.div`
    border-radius: 10px;
    width: 120px;
    height: 34px;
    align-items: center;
    display: flex;
    justify-content: center;
    background-color: #009291;
    color: #fff;
`
export const Divider = styled.div`
    width: 100%;
    height: 2px;
    margin-top: 22px;
    background-color: #E3E3E3;
`
export const DivScroll = styled.div`
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 6px;
    };

    ::-webkit-scrollbar-track {
        box-shadow: none; 
        background-color: transparent;
    };

    ::-webkit-scrollbar-thumb {
        background-color: #c2bdbd;
        border-radius: 6px;
    };

    ::-webkit-scrollbar-thumb:hover {
        background-color: #aaa; 
    };

    ::-webkit-transition{ 
        background-color: .3s linear ease-in-out}
    ;
`