import styled from 'styled-components';

const dnaGreen = '#009291';

export const Container = styled.div`
  margin-top: 15px;
  background-color: white;
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Wrapper = styled.div`
  display: flex;
  ${(props) => props.half && 'width: 50%'};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  gap: ${(props) => (props.row ? '30px' : '2px')};
`;

export const WrapperColor = styled.div`
  display: flex;
  ${(props) => props.half && 'width: 50%'};
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  gap: 5px;
`;

export const SelectWrapper = styled.div`
  width: 35%;
`;

export const MainTitle = styled.h2`
  color: ${dnaGreen};
  font-size: 22px;
  font-weight: 700;
  margin: 0;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  color: #4b4b4d;
`;

export const SubTitle = styled.h5`
  font-size: 14px;
  font-weight: 300;
  color: #606062;

  margin: 0 0 15px;
`;

export const ColorInput = styled.input`
  -webkit-appearence: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
  background-color: transparent;

  ::-webkit-color-swatch {
    border-radius: 3px;
    border: none;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  max-height: 50px;
  width: ${(props) => (props.isColorInput ? '40%' : '75%')};
  border: ${(props) =>
    props.error ? '2px solid #ff0000' : '1px solid #c4c4c4'};
  padding: 10px 10px;
  border-radius: 4px;
  margin: auto 0;

  ::placeholder {
    color: #c4c4c4;
  }
`;

export const PreviewButton = styled.button`
  margin: 10px 0;
  background-color: ${dnaGreen};
  padding: 8px 25px;
  color: white;
  font-weight: 700;
  width: fit-content;
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  margin: 0 0 10px auto;
  background-color: ${dnaGreen};
  padding: 10px 15px;
  color: white;
  font-weight: 700;
  width: fit-content;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 400;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 85%;
  height: 500px;

  background-color: #ffffff;
  height: 100%;
`;
