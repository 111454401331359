export function IconPersonality({ color }) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16960_53821)">
        <path
          d="M24.7472 28.282H22.3905V22.3394C22.3895 21.4155 22.0221 20.5297 21.3688 19.8765C20.7156 19.2232 19.8298 18.8558 18.9059 18.8548H9.37744C8.45357 18.8558 7.56782 19.2232 6.91455 19.8765C6.26128 20.5297 5.89386 21.4155 5.89292 22.3394V28.282H3.53613V22.3394C3.538 20.7907 4.15403 19.306 5.24908 18.211C6.34413 17.1159 7.8288 16.4999 9.37744 16.498H18.9059C20.4546 16.4999 21.9393 17.1159 23.0343 18.211C24.1294 19.306 24.7454 20.7907 24.7472 22.3394V28.282Z"
          fill={color}
        />
        <path
          d="M14.1417 14.1407C12.7433 14.1407 11.3763 13.7261 10.2136 12.9492C9.05086 12.1723 8.14463 11.068 7.60949 9.77609C7.07435 8.48415 6.93434 7.06253 7.20715 5.69102C7.47996 4.3195 8.15335 3.05968 9.14216 2.07087C10.131 1.08206 11.3908 0.40867 12.7623 0.135858C14.1338 -0.136953 15.5554 0.00306349 16.8474 0.538203C18.1393 1.07334 19.2436 1.97957 20.0205 3.14229C20.7974 4.305 21.212 5.67199 21.212 7.07038C21.2102 8.94498 20.4647 10.7423 19.1391 12.0678C17.8136 13.3934 16.0163 14.1389 14.1417 14.1407ZM14.1417 2.35679C13.2094 2.35679 12.2981 2.63324 11.5229 3.15118C10.7478 3.66911 10.1436 4.40527 9.78688 5.26657C9.43012 6.12786 9.33678 7.0756 9.51865 7.98995C9.70053 8.9043 10.1495 9.74418 10.8087 10.4034C11.4679 11.0626 12.3077 11.5115 13.2221 11.6934C14.1364 11.8753 15.0842 11.7819 15.9455 11.4252C16.8068 11.0684 17.5429 10.4642 18.0609 9.6891C18.5788 8.91396 18.8552 8.00264 18.8552 7.07038C18.8552 5.82026 18.3586 4.62134 17.4747 3.73737C16.5907 2.8534 15.3918 2.35679 14.1417 2.35679Z"
          fill={color}
        />
        <rect
          x="4.04102"
          y="26.2617"
          width="20.2011"
          height="2.02011"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_16960_53821">
          <rect width="28.2815" height="28.2815" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
