import { useEffect, useContext, useState, Fragment } from 'react';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';

import { renderItemsAccordion } from 'views/StaffPages/CompaniesManagement/Utils/renderItemsAccordion.js';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext.js';

import { AccordionContent } from '../../../UIComponents/AccordionContent';
import AccordionSkelleton from '../../../UIComponents/AccordionSkelleton';
import GenericAccordion from '../../../UIComponents/GenericAccordion';
import { WhiteBox } from '../../../Styles/BaseComponent';

import Pagination from 'components/CustomPagination';
import GenericMessage from 'components/GenericMessage';

import {
  getInviteSentFilter,
  postDocumentOneCreateFile,
  postDocumentTwoCreateFile,
  sendInvitation,
  unInvite,
  deleteDocument,
} from 'views/StaffPages/CompaniesManagement/Services';
import SimpleModalWal from '../../../../../../../components/SimpleModalWall';
import BaseLayoutContext from 'contexts/base-layout';
import ModalUploadEvidences from 'components/UploadEvidences';
import ViewStageDataModal from '../../../UIComponents/Modals/ViewStageDataModal';
import DocumentsUploadModal from '../../../UIComponents/Modals/DocumentsUploadModal';

export default function InvitationsSentTab({
  setOpenModalConnectedCompanies,
  activeInternalTab,
  setFilterOptions,
  queryFilter,
  getCountTabs,
  setLoading,
  documents,
  setDocuments,
  setTab,
  scrollToTopDiv,
}) {
  const {
    returnStatus,
    returnIcons,
    returnIconSessions,
  } = renderItemsAccordion(false);

  const {
    inviteSent,
    setInviteSent,
    inviteSentCount,
    usingModalUpload,
    setUsingModalUpload,
    idPostDocument,
    currentPage,
    setCurrentPage,
    limit,
    setLimit,
  } = useContext(CompaniesManagementContext);
  const { getInviteSent } = useAPICalls();

  const [openModalSendInvite, setOpenModalSendInvite] = useState(false);

  const [companyId, setCompanyId] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  const { openSnackbar } = useContext(BaseLayoutContext);

  const [loadingButton, setLoadingButton] = useState(false);
  const [buttonID, setButtonID] = useState(null);

  const [openModalViewStageData, setOpenModalViewStageData] = useState(false);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState();

  useEffect(() => {
    if (activeInternalTab === 1) {
      setTab('invited');
      getCountTabs();
      setLoading(true);
      setInviteSent(null);

      getInviteSentFilter().then((data) => {
        setFilterOptions(data);
        setLoading(false);
      });
    }
  }, [activeInternalTab]);

  const handlePageChange = async (page) => {
    if (queryFilter === null) {
      const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
      setInviteSent(null);
      await getInviteSent(newPage);
    }
    if (queryFilter !== null) {
      const query = `${queryFilter}&limit=${limit}&offset=${
        (page - 1) * limit || 0
      }`;
      setInviteSent(null);
      await getInviteSent(query);
    }
  };

  const functionCloseUpdateModal = () => {
    setUsingModalUpload(false);
  };

  const onPageChange = (page) => {
    scrollToTopDiv();
    setCurrentPage(page);
    handlePageChange(page);
  };

  const refreshLimit = (page) => {
    handlePageChange(page);
  };

  useEffect(() => {
    refreshLimit(currentPage);
  }, [limit]);

  const getIDAndOpenModal = (company) => {
    setCompanyId(company.id);
    setCompanyName(company.legal_name);
    setOpenModalSendInvite(true);
  };

  const funcSendInvite = async (id) => {
    setButtonID(id);

    if (id !== null) {
      setLoadingButton(true);
      await sendInvitation(id);
      await getCountTabs();
      setLoadingButton(false);
      openSnackbar('Convite reenviado com sucesso!');
    }
  };

  const funcCancelInvitation = async () => {
    if (companyId !== null) {
      await unInvite(companyId);
      await getCountTabs();
      setOpenModalSendInvite(false);
      setInviteSent(null);
      getInviteSent('limit=20&offset=0');
    }
  };

  const renderItems = () => {
    if (inviteSent?.length === 0) {
      return (
        <GenericMessage
          title="Nenhuma empresa encontrada"
          subtitle="
            Clique no botão ”convidar empresa”
            para enviar um convite de conexão.
          "
        />
      );
    }

    if (inviteSent === null) {
      return Array(8)
        .fill(1)
        .map((_, index) => <AccordionSkelleton />);
    }

    if (inviteSent?.length > 0) {
      return inviteSent?.map((companies) => (
        <GenericAccordion
          companyId={companies.id}
          data={companies}
          greenRightButton={true}
          grayRightButton={true}
          greenRightButtonLabel={'Reenviar convite'}
          grayRightButtonLabel={'Cancelar envio'}
          greenRightButtonFunc={() => funcSendInvite(companies.id)}
          grayRightButtonFunc={() => getIDAndOpenModal(companies)}
          checkbox={false}
          buttonEdit={true}
          buttonId={buttonID}
          loadingButton={loadingButton}
          flagStatus={true}
          textStatus={returnStatus}
          iconStatus={returnIcons}
          colorStatus={companies?.agreement_status}
          textTitle={companies?.legal_name || 'Não informado'}
          textSubtitle={`${companies?.organization_number_type}:`}
          contentSubtitle={companies?.organization_number}
          iconSession={() => returnIconSessions(companies, false)}
          secondCTA={true}
          labelSecondCTA={
            companies.documents_quantity === 0
              ? 'Adicionar documentos de estágio'
              : `Visualizar documentos de estágio (${companies.documents_quantity})`
          }
          getCountTabs={getCountTabs}
          setDocuments={setDocuments}
        >
          <AccordionContent
            companies={companies}
            setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
            responsibleForTheInvitation={true}
            setDocuments={setDocuments}
            setOpenModalViewStageData={setOpenModalViewStageData}
          />
        </GenericAccordion>
      ));
    }
  };

  const configs = {
    title: 'Atenção',
    message: `
      Tem certeza que deseja cancelar o envio do convite de conexão
      com a empresa ${companyName}? Essa ação não poderá ser desfeita.`,
    labelGrayButton: 'NÃO',
    labelGreenButton: 'SIM, CANCELAR',

    styles: {
      widthBubble: '126px',
      heightBubble: '42px',

      fontMessage: '18px',
      lineHeigthMessage: '1.6rem',
      positionMessage: 'start',

      marginTopGrayButton: '0px',
      marginTopGreenButton: '0px',
    },
  };

  const closeModalUpload = () => {
    setUsingModalUpload(false);
  };

  return (
    <Fragment>
      <WhiteBox internalWhiteBox={true}>{renderItems()}</WhiteBox>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={inviteSentCount}
        limit={limit}
        onPageChange={onPageChange}
        setLimit={setLimit}
        setCurrentPage={setCurrentPage}
      />
      {openModalSendInvite && (
        <SimpleModalWal
          isOpen={openModalSendInvite}
          configs={configs}
          funcGrayButton={() => setOpenModalSendInvite(false)}
          funcGreenButton={() => funcCancelInvitation()}
        />
      )}

      {usingModalUpload && (
        <DocumentsUploadModal
          companyId={idPostDocument}
          closeModal={closeModalUpload}
          handlePageChange={handlePageChange}
          selectedCompanyId={selectedCompanyId}
          setSelectedCompanyId={setSelectedCompanyId}
        />
      )}
      {openModalViewStageData && (
        <ViewStageDataModal
          isOpen={openModalViewStageData}
          setOpenModalViewStageData={setOpenModalViewStageData}
          documents={documents}
          usingPreviewPdf={usingPreviewPdf}
          setUsingPreviewPdf={setUsingPreviewPdf}
        />
      )}
    </Fragment>
  );
}
