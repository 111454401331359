import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 512px;

  padding: 40px;

  background-color: #fff;

  border-radius: 12px;

  position: relative;

  @media (max-width: 397px) {
    padding: 23px;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 15px;

  color: #4b4b4d;

  margin-top: ${(props) => (props.marginTop ? '24px' : '10px')};
`;

export const Description = styled.div`
  width: 100%;
  font-size: 13px;
  color: #606062;
  margin-top: 34px;
  margin-bottom: 10px;
  max-height: 200px;
  overflow-y: scroll;

  p {
    word-break: break-word;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 20px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: flex;

  .left-side {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .right-side {
    width: 50%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: 508px) {
    flex-direction: column;

    .left-side {
      width: 100%;
    }

    .right-side {
      width: 100%;
    }
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 60px;
  }

  svg {
    flex: none !important;
  }

  .item p {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.universityColor ? props.universityColor : "#009291"};
    margin: 0;
  }

  .item span {
    font-size: 11px;
    color: #4b4b4d;
  }

  .item button {
    width: fit-content;
    padding: 0;

    border: none;
    background-color: transparent;

    color: #4b4b4d;
    text-decoration: underline;
    font-size: 11px;

    cursor: pointer;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;

  gap: 15px;

  .buttons-access {
    display: flex;
    width: 100%;
    gap: 15px;
  }

  .buttons-react {
    display: flex;
    gap: 5px;
  }

  @media (max-width: 442px) {
    gap: 15px;
    flex-direction: column;
    
    align-items: flex-end;
    justify-content: flex-end;

    .buttons-access {
      justify-content: space-between;
    }

    .buttons-react {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      margin-top: 10px;
    }
  }
`;

export const AdvanceButton = styled.button`
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? '131px' : '96px')};
  height: 26px;
  border-radius: 4px;
  background-color: ${props => props.universityColor ? props.universityColor : "#009291"};
  color: #fff;
  font-size: 12px;
  border: none;
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 442px) {
    max-width: 100%;
    height: 30px;
  }
`;

export const WhiteButton = styled.button`
  width: 100%;
  max-width: 146px;
  height: 26px;
  border-radius: 4px;
  background-color: transparent;
  color: ${props => props.universityColor ? props.universityColor : "#009291"};
  font-size: 12px;
  border: 1px solid ${props => props.universityColor ? props.universityColor : "#009291"};
  font-weight: 600;
  cursor: pointer;

  @media (max-width: 442px) {
    max-width: 100%;
    height: 30px;
  }
`;

export const ReactButton = styled.button`
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  border-radius: 4px;
  border: none;
  cursor: pointer;
`;

export const ReactLike = styled(ReactButton)`
  background-color: ${(props) => (props.active ? '#009291' : '#F6F6F6')};
`;

export const ReactDislike = styled(ReactButton)`
  background-color: ${(props) => (props.active ? '#D26464' : '#F6F6F6')};
`;

export const FavoriteButton = styled(ReactButton)`
  background-color: ${(props) => (props.active ? '#FFC328' : '#F6F6F6')};
`;
