import { profileTexts } from 'views/Profile/Utils/profileTexts';
import { ColorInfo } from '../styled';
import { useState, useEffect } from 'react';
import { IconLanguage } from 'assets/img/iconsCurriculum/iconLanguage';
import { IconCapacitation } from 'assets/img/iconsCurriculum/iconCapacitation';
import { IconExperiences } from 'assets/img/iconsCurriculum/iconExperiences';
import { IconProjectsPortfolio } from 'assets/img/iconsCurriculum/iconProjectsPortfolio';
import { IconValues } from 'assets/img/iconsCurriculum/iconValues';

const useStepTwoSorting = (stepTwoData) => {
  const [iconsData, setIconsData] = useState([]);
  const { getIconColorTwo, getInfoColorTwo } = profileTexts();

  const getSortedIcons = () => {
    const {
      languages,
      experiences,
      projects,
      additional_courses,
      values_attributes,
    } = stepTwoData;

    const items = [
      {
        key: 'values_attributes',
        title: 'Valores',
        icon: <IconValues color={getIconColorTwo('values_attributes')} />,
        completed: values_attributes === 1,
      },
      {
        key: 'languages',
        title: 'Idiomas',
        icon: <IconLanguage color={getIconColorTwo('languages')} />,
        completed: languages >= 1,
      },
      {
        key: 'additional_courses',
        title: 'Formação e Capacitação',
        icon: (
          <IconCapacitation color={getIconColorTwo('additional_courses')} />
        ),
        completed: additional_courses >= 1,
      },
      {
        key: 'experiences',
        title: 'Experiências',
        icon: <IconExperiences color={getIconColorTwo('experiences')} />,
        completed: experiences >= 1,
      },
      {
        key: 'projects',
        title: 'Portfólio de Projetos',
        icon: <IconProjectsPortfolio color={getIconColorTwo('projects')} />,
        completed: projects >= 1,
      },
    ];

    items.sort((a, b) =>
      a.completed === b.completed ? 0 : a.completed ? -1 : 1
    );

    return items.map((item) => ({
      ...item,
      info: (
        <ColorInfo color={getInfoColorTwo(item.key)}>
          <i>{stepTwoData[item.key]} preenchido</i>
        </ColorInfo>
      ),
    }));
  };

  useEffect(() => {
    setIconsData(getSortedIcons());
  }, [stepTwoData]);

  return iconsData;
};

export default useStepTwoSorting;
