import React, { useContext } from 'react';

import StepOneCompany from './Steps/CoursesAndEventsCompany/StepOne';
import StepTwoCompany from './Steps/CoursesAndEventsCompany/StepTwo'
import StepThreeCompany from './Steps/CoursesAndEventsCompany/StepThree'

import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';

export default function CoursesAndEventsCompany ({ setActiveTab }) {

  const { step, setStep, setFormValues } = useContext(OpportunitiesCompanyContext);

  const nextFormStep = () => {
    setStep((prevsStep) => prevsStep + 1);
  };

  const prevsFormStep = () => {
    setStep((prevsStep) => prevsStep - 1);
  };

  const handleSubmit = (data) => {
    setFormValues(data);
    nextFormStep();
  };

  return (
    <div>
      {step === 0 && (
        <StepOneCompany 
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
        />
      )}
      {step === 1 && (
        <StepTwoCompany 
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
        />
      )}
      {step === 2 && (
        <StepThreeCompany 
          prevsFormStep={prevsFormStep}
          nextFormStep={nextFormStep}
          handleSubmit={handleSubmit}
          setActiveTab={setActiveTab}
        />
      )}
    </div>
  );
}
