import React from 'react';
import { func, shape } from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import ViewsHeader from 'components/ViewsHeader/ViewsHeader';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import headerBackgroundImage from 'assets/img/backgroundFilter.png';

import mergeContextsHelper from 'utils/mergeContextsHelper';
import { NewTalentList } from 'components/NewTalentList';
import TalentsListProvider from 'components/NewTalentList/contexts/TalentListContext';

class Talents extends React.Component {
  render() {
    const propsCopy = { ...this.props };
    delete propsCopy.classes;
    const { name: componentTitle } = propsCopy;

    return (
      <div id="container">
        <ViewsHeader
          title={componentTitle}
          backgroundImage={headerBackgroundImage}
        />
        <GridContainer style={{ marginTop: '0px' }}>
          <Grid item xs md={12} sm={12}>
            {mergeContextsHelper(
              <TalentsListProvider
                handleIsLoadingState={this.props.handleIsLoadingState}
              >
                <NewTalentList />
              </TalentsListProvider>
            )}
          </Grid>
        </GridContainer>
      </div>
    );
  }
}

Talents.propTypes = {
  classes: shape({}).isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  openModalWithContent: func.isRequired,
  closeModal: func.isRequired,
  openTalkModal: func.isRequired,
  history: shape({}).isRequired,
};

export default withStyles(dashboardStyle)(Talents);
