import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: 0 24px 0 56px;
`;

export const ProfileImage = styled.div`
  width: 76px;
  height: 75px;
  background-color: #e5e5e5;
  background-image: linear-gradient(
    120deg,
    #e5e5e5 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e5e5e5 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  border-radius: 40px;
  background-position: center;
  animation: ${shimmerAnimation} 1.2s linear infinite;
`;

export const Infos = styled.div`
  margin-left: 24px;
  flex: 1;

  .row {
    display: flex;
  }

  .title {
    width: 120px;
    height: 20px;
    margin-bottom: 18px;
    margin-top: 8px;
    background-color: #e5e5e5;
    background-image: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${shimmerAnimation} 1.2s linear infinite;
  }

  .job {
    width: 250px;
    height: 12px;
    margin-bottom: 8px;
    background-image: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${shimmerAnimation} 1.2s linear infinite;
  }
  .course {
    width: 180px;
    height: 12px;
    margin-bottom: 8px;
    background-image: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${shimmerAnimation} 1.2s linear infinite;
  }

  .withIcon {
    width: 150px;
    height: 14px;
    margin-right: 1rem;
    background-image: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${shimmerAnimation} 1.2s linear infinite;
  }

  .hasItem {
    width: 150px;
    height: 16px;
    margin-top: 18px;
    background-image: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${shimmerAnimation} 1.2s linear infinite;
  }
  .hasItem + .hasItem {
    width: 220px;
    margin-left: 22px;
  }
`;

export const ButtonContainer = styled.div`
  float: right;

  .button {
    width: 110px;
    height: 44px;
    background-image: linear-gradient(
      120deg,
      #e5e5e5 30%,
      #f0f0f0 38%,
      #f0f0f0 40%,
      #e5e5e5 48%
    );
    background-color: #e5e5e5;
    background-repeat: no-repeat;
    background-size: 200% 100%;
    background-position: 100% 0;
    animation: ${shimmerAnimation} 1.2s linear infinite;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  margin: 40px 0px;
  background: #e6e6e6;
  height: 1px;
`;
