import React, { Fragment } from 'react';
import { bool, shape, func } from 'prop-types';
import { FastField } from 'formik';
import { FormHelperText } from '@material-ui/core';
import CustomTextField from 'components/CustomTextField';
import { InfoIcon, AlignCenterView } from 'components/Styled';

function QuestionOne({
  isSubmitting,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
}) {
  return (
    <Fragment>
      <p>
        Seu storytelling (narração de histórias) pode ser elaborado por meio do
        relato de eventos marcantes em sua vida e que representam quem você se
        tornou ao longo de sua história. Para isso, tente se lembrar de momentos
        críticos, de grande mudança em sua vida, em que você teve que tomar
        decisões difíceis, viver desafios, aceitar o fracasso, aprender
        intensamente, ou viver uma conquista e superação.
      </p>
      <AlignCenterView>
        <InfoIcon color="#eb9b00" height="25px" /> Dica: Lembre-se de que o
        recrutador quer conhecer o caminho que te levou até onde você está.
      </AlignCenterView>
      <FastField
        name="questionOne"
        label="Conte sua história"
        variant="outlined"
        id="questionOne"
        placeholder="Resposta"
        multiline
        rowsMax="15"
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.questionOne && touched.questionOne}
        value={values.questionOne}
        component={CustomTextField}
        fullWidth
        disabled={isSubmitting}
      />
      <FormHelperText>
        {values.questionOne &&
          `Sua resposta tem ${values.questionOne.length} caracteres.`}
      </FormHelperText>
      {errors.questionOne && touched.questionOne ? (
        <FormHelperText>{errors.questionOne}</FormHelperText>
      ) : null}
    </Fragment>
  );
}

QuestionOne.propTypes = {
  isSubmitting: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
};

export default QuestionOne;
