import { jobScoreAdapter } from 'adapters/job/jobScoreAdapter';
import React, { useEffect, useState } from 'react';
import { HiStar } from 'react-icons/hi';
import { setCompanyCurriculumScore } from 'services/company/jobs';
import { setCurriculumScore } from 'services/university/jobs';
import { StarWrapper } from './styled';
import { loadJobsData } from 'components/ListCandidates/Utils/mainFunctions';

const Star = ({ marked, starId, onStarClick, onStarHover, onStarLeave }) => {
  return (
    <span style={{ marginRight: '3px' }}>
      <HiStar
        size={33}
        color={marked ? '#FED607' : '#dedede'}
        onMouseEnter={() => onStarHover(starId)}
        onMouseLeave={() => onStarLeave()}
        onClick={() => onStarClick(starId)}
        style={{ cursor: 'pointer' }}
      />
    </span>
  );
};

const StarRating = ({
  score,
  studentData,
  role,
  updateCandidates,
  jobId,
  isPartner,
  selectedTab,
  setCandidates,
  company,
}) => {
  const [rating, setRating] = useState(score);
  const [hoveredStar, setHoveredStar] = useState(0);
  const [student, setStudent] = useState([]);
  let environmentType = company ? 'company' : 'university';

  useEffect(() => {
    setStudent(studentData);
  }, [rating]);

  const handleSubmitScore = async (score) => {
    if (updateCandidates) {
      if (role === 'university') {
        const response = await setCurriculumScore(
          jobScoreAdapter(student.id, score)
        );
        setRating(response.score);
        updateCandidates(student, score);
      } else {
        const response = await setCompanyCurriculumScore(
          jobScoreAdapter(student.id, score)
        );
        setRating(response.score);
        updateCandidates(student, score);
      }
    }
    await loadJobsData(
      environmentType,
      jobId,
      isPartner,
      selectedTab,
      setCandidates
    );
  };

  const handleStarHover = (starId) => {
    setHoveredStar(starId);
  };

  const handleStarLeave = () => {
    setHoveredStar(0);
  };

  const renderStars = () => {
    return Array.from({ length: 5 }, (_, i) => (
      <Star
        key={`star_${i + 1}`}
        starId={i + 1}
        onStarHover={handleStarHover}
        onStarLeave={handleStarLeave}
        onStarClick={handleSubmitScore}
        marked={hoveredStar ? hoveredStar >= i + 1 : rating >= i + 1}
      />
    ));
  };

  return (
    <StarWrapper>
      <div className="StarContainer">{renderStars()}</div>
    </StarWrapper>
  );
};

export default StarRating;
