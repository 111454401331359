import React from 'react';
import { TitleInfos, ContainerTexts } from './styled';

function HeaderReports() {
  return (
    <ContainerTexts>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <TitleInfos>TIPO</TitleInfos>
        <TitleInfos>QUANTIDADE</TitleInfos>
      </div>
    </ContainerTexts>
  );
}

export default HeaderReports;
