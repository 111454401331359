export default function GraduationCap({ color, size }) {
  return (
    <svg
      width={size}
      height={size - 6}
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.36 8.11052V8.54041C31.0635 8.79242 30.8115 9.10372 30.4854 9.31126C26.0679 12.0389 21.6355 14.7368 17.218 17.4644C16.1803 18.1018 15.1723 18.1018 14.1346 17.4644C9.70225 14.7368 5.25507 12.0092 0.807898 9.28161C0.659659 9.19266 0.511425 9.10372 0.37801 8.99995C-0.126003 8.61453 -0.126003 8.05122 0.37801 7.6658C0.481777 7.57685 0.600367 7.51756 0.718958 7.45827C5.29955 5.16056 9.89496 2.87768 14.4756 0.579969C15.276 0.179724 16.0914 0.179724 16.8918 0.579969C21.4724 2.86285 26.0679 5.16056 30.6485 7.47309C30.9153 7.59168 31.1228 7.88816 31.36 8.11052Z"
        fill={color}
      />
      <path
        d="M26.5894 22.0749C25.6407 23.1274 24.4548 23.8389 23.1651 24.3874C20.1559 25.6771 17.0132 26.0773 13.7667 25.7809C11.5283 25.5733 9.3937 25.0248 7.39247 24.002C6.2362 23.409 5.16888 22.6678 4.39804 21.5857C3.98297 20.9927 3.74579 20.3553 3.74579 19.6141C3.74579 17.8649 3.74579 16.1009 3.74579 14.3516C3.74579 14.1589 3.76061 13.9662 3.80508 13.7735C3.8792 13.4918 4.10156 13.3881 4.38322 13.4918C4.51663 13.5363 4.63522 13.6104 4.76863 13.6846C7.6593 15.4486 10.55 17.2275 13.4258 18.9915C14.9378 19.9106 16.4499 19.9106 17.9619 18.9915C20.9415 17.1682 23.9063 15.3448 26.8859 13.5215C26.96 13.477 27.0341 13.4177 27.1231 13.3881C27.4047 13.2547 27.5678 13.3288 27.6271 13.6401C27.6568 13.7735 27.6419 13.9069 27.6419 14.0403C27.6419 14.9742 27.6419 15.8933 27.6419 16.8272C27.6419 17.7018 27.6271 18.5764 27.6419 19.4659C27.6716 20.4887 27.2565 21.3337 26.5894 22.0749Z"
        fill={color}
      />
      <path
        d="M31.109 23.8986C30.8866 24.5212 30.2344 24.9659 29.5673 24.9511C28.8409 24.9363 28.248 24.4619 28.0404 23.7652C27.8625 23.1426 28.0849 22.4607 28.6334 22.0604C28.8557 21.8974 28.9299 21.7195 28.9299 21.4527C28.915 18.7547 28.9299 16.0419 28.915 13.344C28.915 12.8844 29.0485 12.5583 29.4635 12.3508C29.5969 12.2915 29.7155 12.2025 29.8341 12.1284C30.1751 11.9357 30.264 11.995 30.264 12.3804C30.264 13.9221 30.264 15.4638 30.264 17.0055C30.264 18.4879 30.264 19.9703 30.264 21.4527C30.264 21.7195 30.3381 21.8974 30.5605 22.0604C31.1534 22.4755 31.361 23.2167 31.109 23.8986Z"
        fill={color}
      />
    </svg>
  );
}
