import React, { createContext, useContext, useEffect, useState } from 'react';
import EngineSteps from '../components/EngineSendEmail/components/StepsForm/List/EngineSteps';
import VisualizationEmail from '../components/EngineSendEmail/components/visualizationEmail';
import { detailsAdapter } from '../helpers/detailsAdapter';
import sendEmailApi from '../services/sendEmailApi';

export const SendEmailContext = createContext({});

export function SendEmailProvider({
  children,
  FullModalActive,
  setFullModalActive,
  currentPropsPagination,
  listageGeneratedList,
  setListageGeneratedList,
  FullScreenModalChildren,
  setFullScreenModalChildren,
  listageCampaignList,
  setListageCampaignList,
  egressed,
}) {
  const [activeStudentsForm, setActiveStudentsForm] = useState(true);
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [formMode, setFormMode] = useState('create');
  const [configFullScreenModal, setConfigFullScreenModal] = useState({});
  const [activeEmailMass, setActiveEmailMass] = useState(false);

  const stepsQuantity = 2;

  const getDetailsItem = async (id, mode) => {
    const response = await sendEmailApi.getListageDetails(id);
    const { filters } = response;
    const { active } = filters;

    setFullScreenModalChildren(<EngineSteps />);
    setActiveStudentsForm(active);
    setFormData(detailsAdapter(response));
    setFormMode(mode);
    setConfigFullScreenModal({ width: '700px' });
    setFullModalActive(true);
  };

  const getDetailsCampaign = async (id) => {
    const response = await sendEmailApi.getListageDetailsCampaign(id);
    setFullScreenModalChildren(<VisualizationEmail item={response} />);
    setConfigFullScreenModal({ width: '1050px' });
    setFullModalActive(true);
  };
  const getCampaignMetrics = async (id) => {
    const response = await sendEmailApi.getCampaignMetrics(id);
    setFullScreenModalChildren(<VisualizationEmail item={response} />);
    setConfigFullScreenModal({ width: '1050px' });
    setFullModalActive(true);
  };
  const onCloseModal = () => {
    setActiveStudentsForm(true);
    setStep(1);
    setFormData({});
    setFullModalActive(false);
  };

  const setFormValues = (data) => {
    setFormData((prevs) => ({
      ...prevs,
      ...data,
    }));
  };

  const handleClickArrowNext = () => {
    if (step < stepsQuantity) {
      setStep((prevs) => prevs + 1);
    }
  };

  const handleClickArrowPrevs = (e) => {
    e.preventDefault();
    if (step !== 1) {
      setStep((prevs) => prevs - 1);
    }
  };

  return (
    <SendEmailContext.Provider
      value={{
        FullModalActive,
        setFullModalActive,
        activeStudentsForm,
        setActiveStudentsForm,
        step,
        setStep,
        setFormValues,
        formData,
        handleClickArrowNext,
        handleClickArrowPrevs,
        listageGeneratedList,
        setListageGeneratedList,
        getDetailsItem,
        currentPropsPagination,
        onCloseModal,
        formMode,
        setFormMode,
        FullScreenModalChildren,
        setFullScreenModalChildren,
        configFullScreenModal,
        setConfigFullScreenModal,
        getDetailsCampaign,
        listageCampaignList,
        setListageCampaignList,
        activeEmailMass,
        setActiveEmailMass,
        getCampaignMetrics,
        egressed,
      }}
    >
      {children}
    </SendEmailContext.Provider>
  );
}

export function useSendEmail() {
  return useContext(SendEmailContext);
}
