import React from 'react';
import {
  CloseButton,
  ModalBackground,
  ModalContainer,
  ModalContent,
  ModalHeader,
} from './styles';

export default function MentorshipModal({
  isOpen,
  onClose,
  children,
  header,
  maxHeight,
  zIndex,
}) {
  return (
    <ModalBackground isOpen={isOpen} zIndex={zIndex}>
      <ModalContainer>
        <ModalHeader>
          <CloseButton onClick={onClose} />
          {header}
        </ModalHeader>
        <ModalContent maxHeight={maxHeight}>{children}</ModalContent>
      </ModalContainer>
    </ModalBackground>
  );
}
