import styled from 'styled-components';
import { Form as UnformForm } from '@unform/web';

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  display: ${(props) => (props.isOpen ? `flex` : `none`)};
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.5);
  color: #606062;
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: calc(100vw - 260px);
  height: 100%;
  background-color: #eee;
  padding: 0 2rem 2rem 2rem;
  align-items: center;
  overflow-y: scroll;

  @media screen and (max-width: 963px) {
    left: 0;
    width: 100vw;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 740px;
  padding: 2rem;
  margin: 6rem 0 2rem 0;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
`;

export const StepIndicatorWrapper = styled.div`
  width: 100%;
  display: flex;
  top: 0;
  background-color: #fff;
  z-index: 1;
`;

export const CloseButton = styled.button`
  width: 50px;
  height: 50px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  align-items: center;
  justify-content: center;
  display: flex;
  border: none;
  border-radius: 100%;
  position: absolute;
  right: 2rem;
  top: 2rem;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const TextTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #009291;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};

  > strong {
    font-weight: 400;
  }
`;

export const TextSubtitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  color: #606062;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
`;

export const FieldLabel = styled.h4`
  font-size: 18px;
  font-weight: 700;
  color: #606062;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : '0.25rem'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 2rem 0;
`;

export const FooterNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
`;

export const NavigationButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  background-color: #009291;
  cursor: pointer;
  transition: filter 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
`;

export const SubmitButton = styled.button`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  background-color: #009291;
  color: #fff;
  cursor: pointer;
  transition: filter 0.2s;
  font-size: 14px;
  padding: 0 1rem;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Form = styled(UnformForm)`
  .error-label {
    font-size: 12px;
    font-weight: 400;
    color: #4f4f4f;
  }
`;
