const IModalConfigExternalJobEmail = {
  ParagraphText:
    'Para participar desse processo seletivo será necessário encaminhar o seu currículo para o e-mail da empresa.',
  buttonText: 'OK, ENVIAR E-MAIL!',
  buttonConfirmText: 'OK, ENVIAR E-MAIL',
  bubbleText: 'Atenção!',
  showButton: true,
  buttonCloseX: true,

  style: {
    fontSize: '17px',
    lineHeight: '22px',
    marginButtons: '18px',
    textAlign: 'center',
  },
};

export const returnConfigContracted = (job) => {
  const configConfirmContracted = {
    ParagraphText: `Tem certeza que deseja confirmar a sua contratação 
        para a vaga <strong>${job?.profession || job?.name}</strong> 
        da empresa <strong>${
          job?.company?.label || job?.companyName || job?.company_name
        }</strong> ?
      `,
    buttonConfirmText: 'SIM',
    buttonCancelText: 'NÃO',
    bubbleText: 'Atenção!',
    showButtonCancel: true,
    buttonConfirmWidth: 100,
    showButton: true,
    buttonCloseX: true,

    style: {
      fontSize: '17px',
      lineHeight: '22px',
      marginButtons: '18px',
      textAlign: 'center',
      maxWidth: '326px',
    },
  };

  return configConfirmContracted;
};

const IModalConfigExternalJobLink = {
  ParagraphText:
    'O processo de candidatura a essa vaga será realizado pela empresa parceira. Sucesso!',
  buttonText: 'Vamos lá!',
  buttonConfirmText: 'Vamos lá!',
  bubbleText: 'Atenção!',
  showButton: true,
  buttonCloseX: true,

  style: {
    fontSize: '17px',
    lineHeight: '22px',
    marginButtons: '18px',
    textAlign: 'center',
  },
};

const IModalConfigConfirmApplication = {
  ParagraphText: 'Tem certeza que deseja se candidatar a essa vaga?',
  buttonConfirmText: 'SIM, QUERO CANDIDATAR!',
  buttonCancelText: 'NÃO, CANCELAR A CANDIDATURA!',
  bubbleText: 'Atenção!',
  showButton: true,
  showButtonCancel: true,
  buttonCloseX: true,

  buttonCancelWidth: 288,
  modalWidth: 451,
  style: {
    maxWidth: '342px',
    fontSize: '18px',
    lineHeight: '27px',
  },
};

const IModalConfigCoverLetter = {
  ParagraphText: `Você ainda não criou a sua Carta de Apresentação, 
     com ela você poderá se destacar dentre outros candidatos.`,
  buttonConfirmText: 'CANDIDATAR ASSIM MESMO!',
  buttonCancelText: 'QUERO CRIAR MINHA CARTA!',
  bubbleText: 'Atenção!',
  showButton: true,
  showButtonCancel: true,
  buttonCloseX: true,
  buttonCancelWidth: 288,
  modalWidth: 451,
  style: {
    maxWidth: '342px',
    fontSize: '18px',
    lineHeight: '27px',
  },
};

const coverLetterConfig = {
  modalWidth: '366px',
  bubbleText: `Atenção!`,
  ParagraphText: `Você ainda não criou a sua carta de apresentação. Com ela, você poderá se destacar dentre outros candidatos.`,
  buttonConfirmText: 'CRIAR CARTA',
  buttonConfirmWidth: '100%',
  buttonConfirmOrder: '1',
  showButtonCancel: true,
  showButtonConfirm: true,
  buttonCancelText: 'CANDIDATAR MESMO ASSIM',
  buttonCancelWidth: '100%',
  buttonCancelOrder: '2',
  buttonCloseX: true,
  style: {
    fontSize: '17px',
    lineHeight: '23px',
    maxWidth: '92%',
    textAlign: 'justify',
    buttonsOrientation: 'column',
    marginButtons: '10px',
  },
};

export {
  IModalConfigExternalJobEmail,
  IModalConfigExternalJobLink,
  IModalConfigConfirmApplication,
  IModalConfigCoverLetter,
  coverLetterConfig,
};
