import {
  Column,
  Container,
  Content,
  CourseGrid,
  DataRow,
  Divider,
  GreenBox,
  InfoIconWrapper,
  OutlinedBox,
  PedagogicalAdherenceButton,
  Row,
  Text,
  ContainerLoading,
} from './styles';
import { Fragment, useMemo, useState, useContext } from 'react';
import { InfoCycleIcon } from 'assets/ComponentIcons/InfoCycleIcon';
import { CursorIcon } from 'assets/ComponentIcons/CursorIcon';
import { CircleUserIcon } from 'assets/ComponentIcons/CircleUserIcon';
import { DataBox } from './components/DataBox';
import { SearchInputWithDebounce } from 'components/SearchInputWithDebounce';
import { CourseCard } from './components/CourseCard';
import { InfoWorkabilityIndicatorModal } from './components/InfoWorkabilityIndicatorModal';
import { PedagogicalPlanAdherenceModal } from './components/PedagogicalPlanAdherenceModal';
import { StudentType, useWorkabilityIndicator } from './context';
import GenericMessage from '../../../../../components/GenericMessage';
import { Tooltip } from './components/Tooltip';
import { LoadingContainer } from 'components/Styled';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';
import { formatNumber } from 'utils/formatNumber';
import BaseLayoutContext from 'contexts/base-layout';
import { getAdherenceData, getAdherenceCourseData } from '../../services';
import { genericAdapter } from 'adapters/genericAdapter';
import GenericInternalTab from 'components/GenericInternalTab';

export function WorkabilityIndicator({ data, dataCourses }) {
  const [activeTab, setActiveTab] = useState(StudentType.IN_PROGRESS);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [infoIndicatorModalOpen, setInfoIndicatorModalOpen] = useState(false);
  const [loadingAdherence, setLoadingAdherence] = useState(false);
  const [codeCourse, setCodeCourse] = useState(null);
  const { metadata } = useContext(BaseLayoutContext);
  const { setSelectedAdherence } = useWorkabilityIndicator();
  const [
    pedagogicalPlanAdherenceModalOpen,
    setPedagogicalPlanAdherenceModalOpen,
  ] = useState(false);

  let searchCourse;

  const handleTypeSearchTerm = (event) => {
    searchCourse = event.target.value;
    setSearchTerm(searchCourse);
    setCurrentPage(1);
  };

  const handleAdherence = async (course) => {
    setLoadingAdherence(true);
    if (course) {
      setCodeCourse(course);
    } else {
      setCodeCourse('general');
    }

    const analyticsData = course
      ? await getAdherenceCourseData(course)
      : await getAdherenceData();
    const { result, code } = analyticsData;
    setSelectedAdherence(genericAdapter(result));
    setPedagogicalPlanAdherenceModalOpen(true);
    setCodeCourse(null);
    setLoadingAdherence(false);
  };

  function handleOpenInfoIndicatorModal() {
    setInfoIndicatorModalOpen(true);
  }

  const generalData = useMemo(() => {
    if (!data) {
      return '';
    }
    if (activeTab === StudentType.IN_PROGRESS) {
      return data?.enrolled?.generalData;
    }
    if (activeTab === StudentType.EGRESS) {
      return data?.done?.generalData;
    }
  }, [activeTab, data]);

  const coursesData = useMemo(() => {
    let courses = [];
    if (!dataCourses) {
      return '';
    }
    if (activeTab === StudentType.IN_PROGRESS) {
      courses = dataCourses?.enrolled?.courses;
    } else if (activeTab === StudentType.EGRESS) {
      courses = dataCourses?.done?.courses;
    }
    if (!searchTerm || searchTerm === '') {
      return courses;
    } else {
      return courses.filter((course) => {
        return course.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase()
          .includes(
            searchTerm
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toUpperCase()
          );
      });
    }
  }, [activeTab, dataCourses, searchTerm]);

  const numberString = (data, value) => {
    if (data && data[value]) {
      return formatNumber(data[value]);
    } else {
      return '';
    }
  };

  const insufficientDataCourses =
    !generalData?.percentActiveStudents ||
    generalData?.percentActiveStudents < 10;
  const hasPedagogicalAdherenceAccess =
    metadata.university_plan.toUpperCase() === 'PREMIUM';

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  return !data ? (
    <ContainerLoading>
      <LoadingContainer>
        <LoadingUpdate />
        <p
          style={{
            fontSize: '16px',
            fontWeight: '400',
          }}
        >
          Carregando...
        </p>
      </LoadingContainer>
    </ContainerLoading>
  ) : (
    <Container>
      <div style={{ width: '100%' }}>
        <GenericInternalTab
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={['Estudantes em formação', 'Estudantes formados']}
          zIndex={0}
        />
      </div>

      <Content>
        {insufficientDataCourses ? (
          <GenericMessage
            title="Nenhum dado encontrado"
            subtitle="Oops! Nenhum resultado foi encontrado em nosso banco de
             dados, porque a sua média de trabalhabilidade é inferior a 10%.
              Para aumentar essa média, convide seus estudantes para acessarem
               o sistema e se candidatarem as nossas vagas exclusivas!"
          />
        ) : (
          <Fragment>
            <strong>Indicador de Trabalhabilidade</strong>
            <p>
              Confira abaixo a <b>média geral</b> de trabalhabilidade da sua
              instituição de ensino em comparação com outras instituições de
              ensino.
            </p>
            <OutlinedBox marginTop={'36px'} height={'609px'}>
              <InfoIconWrapper onClick={handleOpenInfoIndicatorModal}>
                <InfoCycleIcon />
              </InfoIconWrapper>
              <GreenBox>
                <div>
                  <CursorIcon />
                  <p>{`${numberString(
                    generalData,
                    'activeStudents'
                  )} (${numberString(
                    generalData,
                    'percentActiveStudents'
                  )}%) estudantes ativos`}</p>
                </div>
                <div>
                  <CircleUserIcon />
                  <p>{`${numberString(
                    generalData,
                    'integratedStudents'
                  )} estudantes integrados`}</p>
                </div>
              </GreenBox>
              <DataRow>
                <DataBox
                  title={'Estudantes trabalhando'}
                  percent={numberString(generalData, 'percentWorkingStudents')}
                  studentAmount={numberString(generalData, 'workingStudents')}
                  mediaPercent={numberString(
                    generalData,
                    'percentWorkingStudentsMedia'
                  )}
                  tooltipWidth={'183px'}
                  icon
                />
                <DataBox
                  title={'Na área'}
                  percent={numberString(
                    generalData,
                    'percentWorkingStudentsInArea'
                  )}
                  studentAmount={numberString(
                    generalData,
                    'valueWorkingInArea'
                  )}
                  mediaPercent={numberString(
                    generalData,
                    'percentWorkingStudentsInAreaMedia'
                  )}
                  tooltipWidth={'183px'}
                  minor
                  icon
                />
                <DataBox
                  title={'Fora da área'}
                  width={'194px'}
                  percent={numberString(
                    generalData,
                    'percentWorkingStudentsOutArea'
                  )}
                  studentAmount={numberString(
                    generalData,
                    'valueWorkingOutArea'
                  )}
                  mediaPercent={numberString(
                    generalData,
                    'percentWorkingStudentsOutAreaMedia'
                  )}
                  minor
                />
              </DataRow>
              <Divider marginTop={'3.75rem'} maxWidth={'630px'} />
              <DataRow>
                <DataBox
                  title={'Estudantes não trabalhando'}
                  percent={numberString(
                    generalData,
                    'percentNotWorkingStudents'
                  )}
                  studentAmount={numberString(
                    generalData,
                    'notWorkingStudents'
                  )}
                  mediaPercent={numberString(
                    generalData,
                    'percentNotWorkingStudentsMedia'
                  )}
                  minor
                  row
                />
              </DataRow>
              <Tooltip
                text={`Disponível para o plano Premium. Entre em contato
                   com o seu consultor através do e-mail
                   sucesso@workalove.com para saber mais.`}
                width={'247px'}
                showTooltip={!hasPedagogicalAdherenceAccess}
                marginBottom={'-2rem'}
              >
                {loadingAdherence && codeCourse === 'general' ? (
                  <LoadingContainer style={{ height: '104px' }}>
                    <LoadingUpdate />
                  </LoadingContainer>
                ) : (
                  <PedagogicalAdherenceButton
                    disabled={
                      !hasPedagogicalAdherenceAccess ||
                      (codeCourse !== 'general' && codeCourse !== null)
                    }
                    onClick={() => handleAdherence()}
                  >
                    Ver aderência do plano pedagógico
                  </PedagogicalAdherenceButton>
                )}
              </Tooltip>
            </OutlinedBox>
            <Row justifyContent={'space-between'} marginTop={'2rem'}>
              <Column alignItems={'flex-start'} gap={'0.5rem'}>
                <Text fontSize={'20px'} fontWeight={'700'}>
                  Indicador de Trabalhabilidade por Cursos
                </Text>
                <Text fontSize={'16px'}>
                  Confira abaixo a <b>média</b> de trabalhabilidade por cursos.
                </Text>
              </Column>
              <SearchInputWithDebounce
                placeholder={'Buscar por curso'}
                setSearchTerm={(e) => handleTypeSearchTerm(e)}
                searchTerm={searchCourse}
              />
            </Row>
            <CourseGrid>
              {coursesData &&
                coursesData?.map((course, index) => (
                  <CourseCard
                    handleOpenInfoIndicatorModal={handleOpenInfoIndicatorModal}
                    course={course}
                    handleAdherence={handleAdherence}
                    loadingAdherence={loadingAdherence}
                    index={index}
                    codeCourse={codeCourse}
                    hasPedagogicalAdherenceAccess={
                      hasPedagogicalAdherenceAccess
                    }
                  />
                ))}
            </CourseGrid>
            {coursesData &&
              data &&
              coursesData.length === 0 &&
              searchTerm !== '' && (
                <GenericMessage
                  title="Curso não encontrado"
                  subtitle="Oops! Não encontramos nenhum curso com esse nome, verifique se o nome foi digitado corretamente."
                />
              )}
          </Fragment>
        )}
      </Content>
      <InfoWorkabilityIndicatorModal
        isOpen={infoIndicatorModalOpen}
        onClose={() => setInfoIndicatorModalOpen(false)}
      />
      <PedagogicalPlanAdherenceModal
        isOpen={pedagogicalPlanAdherenceModalOpen}
        onClose={() => setPedagogicalPlanAdherenceModalOpen(false)}
      />
    </Container>
  );
}
