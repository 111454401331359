import React, { useState, useRef } from 'react';
import { Container, SubContainer } from './styled';
import GenericInternalTab from 'components/GenericInternalTab';
import { PendingSubTab } from './PendingSubTab';
import { ConfirmedSubTab } from './ConfirmedSubTab';
import { useScheduledMentorshipContext } from '../context';
import { useMentorship } from 'views/Student/Mentorship/context/MentorshipContext';

export default function InternalTab({ currentTab }) {
  const [activeInternalTab, setActiveInternalTab] = useState(0);
  const { setMentorships, setPage } = useScheduledMentorshipContext();
  const {
    mentorshipCounts,
    updateMentorshipsCount,
    fetchMentorshipsCount,
  } = useMentorship();
  const handleTabChange = (_, value) => {
    if (activeInternalTab !== value) {
      setMentorships(null);
    }
    setPage(1);
    setActiveInternalTab(value);
  };

  const tabs = [
    `Pendentes  (${mentorshipCounts.pending ?? 0})`,
    `Confirmadas (${mentorshipCounts.confirmed ?? 0})`,
  ];

  const components = {
    0: (
      <PendingSubTab
        updateMentorshipsCount={updateMentorshipsCount}
        fetchMentorshipsCount={fetchMentorshipsCount}
        currentTab={currentTab}
      />
    ),
    1: (
      <ConfirmedSubTab
        updateMentorshipsCount={updateMentorshipsCount}
        fetchMentorshipsCount={fetchMentorshipsCount}
        currentTab={currentTab}
      />
    ),
  };

  const returnCurrentComponentTab = () => {
    return components[activeInternalTab];
  };

  return (
    <Container>
      <SubContainer>
        <div style={{ width: '100%' }}>
          <GenericInternalTab
            tabs={tabs}
            activeTab={activeInternalTab}
            handleTabChange={handleTabChange}
            zIndex={'100'}
          />
        </div>
      </SubContainer>
      {returnCurrentComponentTab()}
    </Container>
  );
}
