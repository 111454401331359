import { Form } from '@unform/web';
import moment from 'moment';
import React, { useState, useContext, useEffect } from 'react';
import DatePickerCustom from '../../../../StaffPages/Opportunities/Components/Form/datePicker';
import {
  Title,
  WrapperDates,
  WrapperMail,
  WrapperSelectiveProcess,
  ContainerSelectiveProcess,
  FooterForm,
  ContainerButtons,
  CompletedButton,
} from './style';

import { PreviewButton } from './Buttons/PreviewButton';
import Input from '../../../../StaffPages/Opportunities/Components/Form/input';
import Radio from '../../Form/radioInput';
import SearchableSelectUn from '../../Form/searchableSelect';
import {
  colorOrRace,
  disability,
  genderIndentity,
} from '../../ExternalJobOffer/useCases/SelectOptions';

import * as Yup from 'yup';
import { schemaValidationStepThree } from './useCases/SchemaValidation';
import { JobOfferContext } from '../../../Contexts/JobOfferContext';
import { PrevsButton } from '../../../../StaffPages/Opportunities/Styles/formStyled';

import { login } from '../../../../../services/login/authenticate';
import { createJobOffer, editJobOffer } from '../../../services';
import { internalJobOfferPayload } from '../helpers/createInternalJobOfferPayload';
import {
  IModalConfigCreateCompany,
  IModalConfigCreateCompanyError,
  IModalConfigCreateJob,
  IModalConfigCreateJobError,
  IModalConfigCreateJobErrorInternal,
  IModalConfigCreateJobInternal,
  IModalConfigEditJobErrorInternal,
  IModalConfigEditJobInternal,
} from './useCases/IModalConfig';

import LoadingModal from '../../LoadingModal';

export default function StepThreeJobOffer() {
  const {
    prevsJobOfferStep,
    data: contextData,
    companyData,
    setData,
    stepThreeRef,
    dataFunction,
    handleTabChange,
    metadata,
    setShowInformationModalMyProfile,
  } = useContext(JobOfferContext);

  const [selectiveProcess, setSelectiveProcess] = useState(false);

  const returnDataStepThree = () => {
    return stepThreeRef.current ? stepThreeRef?.current?.getData() : {};
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentModalConfig, setCurrentModalConfig] = useState(
    IModalConfigCreateCompany
  );
  const [onUserAuthenticate, setOnUserAuthenticate] = useState({});

  const radioOptions = [
    {
      id: '1',
      value: true,
      label: `Quero promover a diversidade e inclusão de pessoas
        levando em consideração raça, identidade de
        gênero e pessoa com deficiência.`,
      type: 'checkbox',
    },
  ];

  const handleCheckSelectiveProcess = (e) => {
    setData((prev) => ({
      ...prev,
      administrative_selection_process: e.target ? e.target.checked : false,
      disability: [],
      color_or_race: [],
      gender_identity: [],
    }));
    stepThreeRef.current.setFieldValue('disability', []);
    stepThreeRef.current.setFieldValue('color_or_race', []);
    stepThreeRef.current.setFieldValue('gender_identity', []);
    if (e.target.checked) {
      return setSelectiveProcess(true);
    }
    setSelectiveProcess(false);
  };

  useEffect(() => {
    if (
      contextData.administrative_selection_process &&
      contextData.administrative_selection_process === true
    ) {
      setSelectiveProcess(true);
    }
  }, []);

  const handleUserLogin = (loginUser) => {
    if (onUserAuthenticate.isLogged || loginUser.isLogged) {
      companyData.functionToLogin(
        onUserAuthenticate.data ? onUserAuthenticate.data : loginUser.data
      );
    }
  };

  const handleSetModalState = () => {
    setModalOpen(false);
  };

  const returnCurrentFunction = () => {
    if (currentModalConfig === IModalConfigCreateCompanyError) {
      return handleSetModalState();
    }
    if (currentModalConfig === IModalConfigCreateJobError) {
      return handleUserLogin();
    }
    if (currentModalConfig === IModalConfigCreateJobErrorInternal) {
      return handleSetModalState();
    }

    if (currentModalConfig === IModalConfigEditJobErrorInternal) {
      return handleSetModalState();
    }

    return () => {};
  };

  const editJob = async (data) => {
    let responseProfession = {};

    setModalOpen(true);
    setCurrentModalConfig(IModalConfigEditJobInternal);

    const responseEdit = await editJobOffer(
      contextData.jobId,
      internalJobOfferPayload(data, contextData, responseProfession)
    );

    if (responseEdit.message) {
      return setCurrentModalConfig(IModalConfigEditJobErrorInternal);
    }

    if (contextData.isInternalCompany) {
      setModalOpen(false);
      dataFunction.handlePageChange('limit=0');
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }
  };

  const createJob = async (data, loginUser) => {
    let responseProfession = {};

    setModalOpen(true);
    setCurrentModalConfig(
      contextData.isInternalCompany
        ? IModalConfigCreateJobInternal
        : IModalConfigCreateJob
    );

    const responseJOB = await createJobOffer(
      internalJobOfferPayload(data, contextData, responseProfession)
    );

    if (responseJOB.message) {
      return setCurrentModalConfig(
        contextData.isInternalCompany
          ? IModalConfigCreateJobErrorInternal
          : IModalConfigCreateJobError
      );
    }

    if (contextData.isInternalCompany) {
      await dataFunction.handlePageChange('limit=0');
      setModalOpen(false);
      setData({});
      dataFunction.setOpenModalPublishJob(false);
    }

    if (!contextData.isInternalCompany) {
      handleUserLogin(loginUser);
    }
  };

  const createCompanyAndJob = async (data) => {
    setModalOpen(true);

    const loginUrl = '/login/empresa';
    const userData = {
      username: companyData.staff_email,
      password: companyData.staff_password,
    };

    const loginUser = await login(loginUrl, userData);

    setOnUserAuthenticate(loginUser);

    await createJob(data, loginUser);
  };

  const handleSubmit = async (data) => {
    const formattedData = {
      ...data,
      start_offer_at: moment(data.start_offer_at).format('YYYY-MM-DD'),
      end_offer_at: moment(data.end_offer_at).format('YYYY-MM-DD'),
    };
    stepThreeRef.current.setErrors({});

    try {
      const schema = schemaValidationStepThree;
      await schema.validate(formattedData, {
        abortEarly: false,
      });

      if (!contextData.isInternalCompany) {
        return createCompanyAndJob(formattedData);
      }
      if (!contextData.isEditJob) {
        await createJob(formattedData);

        handleTabChange(_, 0);
        if (metadata.is_profile_incomplete) {
          setShowInformationModalMyProfile(true);
        }
        return;
      }

      return editJob(formattedData);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        stepThreeRef.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <div>
      <LoadingModal
        modalOpenState={modalOpen}
        config={currentModalConfig}
        buttonFunction={returnCurrentFunction}
      />
      <Form
        ref={stepThreeRef}
        onSubmit={handleSubmit}
        initialData={contextData}
      >
        <Title>Período de Divulgação*</Title>
        <WrapperDates>
          <DatePickerCustom
            minDate={new Date()}
            name="start_offer_at"
            placeholder="De"
          />
          <DatePickerCustom
            minDate={new Date()}
            name="end_offer_at"
            placeholder="Até"
          />
        </WrapperDates>

        <WrapperMail>
          <Input
            name="contact_email"
            label="E-mail para Notificação de Novas Candidaturas"
            placeholder="Insira o e-mail de uma pessoa que tenha acesso a plataforma Workalove"
          />
        </WrapperMail>

        <WrapperSelectiveProcess>
          <h5>Processo Seletivo Afirmativo </h5>
          <Radio
            name="administrative_selection_process"
            options={radioOptions}
            onChange={handleCheckSelectiveProcess}
          />

          {selectiveProcess && (
            <ContainerSelectiveProcess>
              <SearchableSelectUn
                name="disability"
                isClearable
                isMulti
                label="PCD - Selecione possíveis deficiências"
                options={disability}
              />
              <SearchableSelectUn
                name="color_or_race"
                isClearable
                label="Raça/Cor"
                isMulti
                options={colorOrRace}
              />
              <SearchableSelectUn
                name="gender_identity"
                isClearable
                isMulti
                label="Identidade de Gênero"
                options={genderIndentity}
              />
            </ContainerSelectiveProcess>
          )}
        </WrapperSelectiveProcess>

        <FooterForm>
          <PrevsButton onClick={prevsJobOfferStep} />
          <ContainerButtons>
            <PreviewButton
              stepThreeData={returnDataStepThree()}
              handleSubmit={handleSubmit}
            >
              Pré-Visualizar Vaga
            </PreviewButton>
            <CompletedButton> Publicar </CompletedButton>
          </ContainerButtons>
        </FooterForm>
      </Form>
    </div>
  );
}
