import styled from 'styled-components';

export const ButtonStyled = styled.button`
  width: ${({ width }) => (width ? width : '120px')};
  height: ${({ height }) => (height ? height : '34px')};
  border: ${({ border, color }) => (border ? `1px solid ${color}` : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : '#000')};
  background-color: ${({ background }) => (background ? background : '#FFF')};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};
  cursor: pointer;
  ${({ theme }) =>
    theme === 'flatUniversityColor' &&
    `
  margin: 0;
  padding: 0;
  width: fit-content;
  height: fit-content;
`}

  &:disabled {
    background-color: #f5f5f5;
    color: #949494;
    cursor: not-allowed;
  }
`;
