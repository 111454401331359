import React, { useContext, useState } from 'react';
import {
  CardDiv,
  ContainerButtons,
  ContainerDetails,
  ContainerInformations,
  DetailsButton,
  ImageContainer,
  Information,
  InformationP,
  ProgressBar,
  ProgressBarPercentual,
  Quantity,
  SubscribeButton,
  Title,
  WrapperIcons,
} from './style';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoLocationSharp } from 'react-icons/io5';
import { BiCalendar } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import {
  patchSubscribe,
  postSubscribe,
} from 'services/student/extensionProject';
import moment from 'moment';
import Reactions from '../reactions';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';
import { findImage } from 'views/Student/Extracurricular/utils/findImage';
import BaseLayoutContext from 'contexts/base-layout';
import { CurriculumContext } from 'contexts/CurriculumContext';

export default function Card({
  item,
  getExtesionProject,
  imagesList,
  setModalInformationOpen,
  limit,
  handleClickDetails,
  setModalDetailsItem,
}) {
  const [loadingDetails, setLoadingDetails] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);
  const valueIcons = { color: '#009291', size: '1.5em' };

  const { setIsOpenCurriculumModal, profile } = useContext(CurriculumContext);

  const handleClickSubscribe = async () => {
    if (!profile.academic_profile?.curriculum_filled) {
      return setIsOpenCurriculumModal(true);
    }
    setModalInformationOpen(true);
    if (item.registration_id) {
      await patchSubscribe(item.extension_project_id, item.registration_id);
      return;
    }
    await postSubscribe(item.extension_project_id);
  };

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const formatWorkload = (workload) => {
    if (workload) {
      const split = workload?.split('.');
      if (split[1] === '00') {
        return `${split[0]}h`;
      }
      return `${split[0]}h${split[1]}`;
    }
  };

  const percentEnrolled = item.enrolled_students_percentual.replace('%', '');

  const findCardImage = (item) => {
    const find = findImage(imagesList, item.id);
    if (find && find.logo) {
      return (
        <ImageContainer universityColor={universityColor} image={true}>
          <img src={find.image} />
          <Quantity universityColor={universityColor}>
            {item.enrolled_students_quantity} vagas preenchidas
          </Quantity>
        </ImageContainer>
      );
    } else {
      return (
        <ImageContainer universityColor={universityColor} image={true}>
          <img
            src={find && find.image}
            style={{
              width: '100%',
              height: '209px',
              borderTopLeftRadius: '11.5652px',
              borderTopRightRadius: '11.5652px',
              position: 'absolute',
              top: 0,
            }}
          />
          <Quantity universityColor={universityColor}>
            {item.enrolled_students_quantity} vagas preenchidas
          </Quantity>
        </ImageContainer>
      );
    }
  };

  const isNotFullEnrroled = item.enrolled_students_percentual !== '100%';

  return (
    <CardDiv>
      {findCardImage(item)}

      <ContainerInformations>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <ProgressBar>
            <ProgressBarPercentual
              universityColor={universityColor}
              percentual={String(item.enrolled_students_percentual)}
            />
          </ProgressBar>
          <Information>
            {item.enrolled_students_percentual} das vagas preenchidas
          </Information>
        </div>
        <Title>{item.name}</Title>

        <IconContext.Provider value={valueIcons}>
          <ContainerDetails>
            <WrapperIcons>
              <i>
                <BiCalendar
                  color={universityColor ? universityColor : '#009291'}
                />
              </i>
              <p>{`De ${formatDate(item.start_at)} a ${formatDate(
                item.end_at
              )}`}</p>
            </WrapperIcons>
            <WrapperIcons>
              <i>
                <AiOutlineClockCircle
                  color={universityColor ? universityColor : '#009291'}
                />
              </i>
              <p>Carga horária de {formatWorkload(item.workload)}</p>
            </WrapperIcons>
            <WrapperIcons>
              <i>
                <IoLocationSharp
                  color={universityColor ? universityColor : '#009291'}
                />
              </i>
              <p>
                {item.address &&
                  item.address.street &&
                  `${item.address.street},`}{' '}
                {item.address &&
                  item.address.number &&
                  ` ${item.address.number},`}{' '}
                {item.address &&
                  item.address.complement &&
                  ` ${item.address.complement},`}{' '}
                {item.address && item.address.city && ` ${item.address.city},`}{' '}
                {item.address &&
                  item.address.zip_code &&
                  `${item.address.zip_code}`}
              </p>
            </WrapperIcons>
          </ContainerDetails>
        </IconContext.Provider>

        <ContainerButtons>
          {isNotFullEnrroled && (
            <SubscribeButton
              universityColor={universityColor}
              onClick={handleClickSubscribe}
            >
              Inscrever-se
            </SubscribeButton>
          )}

          <DetailsButton
            universityColor={universityColor}
            changeStyle={isNotFullEnrroled}
            onClick={async () => {
              setLoadingDetails(true);
              await handleClickDetails({
                ...item,
                isNotFullEnrroled,
                handleClickSubscribe,
                getExtesionProject,
                limit,
                setModalDetailsItem,
              });
              setLoadingDetails(false);
            }}
          >
            {loadingDetails ? (
              <Loading color={universityColor} />
            ) : (
              'Visualizar Detalhes'
            )}
          </DetailsButton>
        </ContainerButtons>

        <hr />

        {isNotFullEnrroled ? (
          <div
            style={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          >
            <Reactions
              id={item.id}
              item={item}
              getExtesionProject={getExtesionProject}
              limit={limit}
            />
          </div>
        ) : (
          <InformationP>
            No momento as vagas estão todas preenchidas. Caso haja alguma
            desistência, o projeto ficará disponível novamente para inscrição.
          </InformationP>
        )}
      </ContainerInformations>
    </CardDiv>
  );
}
