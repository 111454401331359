import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SubContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border-bottom: 2px solid #eef2f4;
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 9px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;
  padding: 1rem 2rem 2rem;

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;
export const MentorshipListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;

  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const SeeMoreButton = styled.button`
  display: flex;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => props.color || '#009291'};
  margin: 1rem auto 1rem;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    filter: brightness(0.9);
  }
`;
