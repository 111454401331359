const updateStateOfObjects = (objects, newObject) => {
  /* Update state of objects.

    Receive a list a of objects, search for a matching id to update or
    add a new item on the top of the list.
    */
  let matchingPosition = null;

  objects.forEach((object, index) => {
    if (newObject.id === object.id) {
      delete objects[index];
      matchingPosition = index;
    }
  });

  // if object matches we only update it, otherwise we add the new object
  // on the top of the list
  if (matchingPosition !== null) {
    objects[matchingPosition] = newObject;
  } else {
    objects.unshift(newObject);
  }

  return objects;
};

export default updateStateOfObjects;
