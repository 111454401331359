import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  padding: 50px;

  @media (max-width: 425px) {
    padding: 20px;
  }
`;

export const LogoArea = styled.div`
  width: 112px;
  height: 112px;

  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  position: relative;

  svg {
    width: 117px;
    z-index: ${(props) => (props.resetIndex ? 0 : 2)};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 112px;
    height: 112px;
    background-color: #fff;
    border-radius: 50%;
    opacity: 0.8;
    z-index: ${(props) => (props.resetIndex ? 0 : 1)};
  }
`;

export const Title = styled.h1`
  color: #606062;
  font-size: 22px;
  font-weight: 400;

  text-align: center;

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const Subtitle = styled.span`
  font-size: 18px;
  color: #606062;

  max-width: 670px;

  text-align: center;

  @media (max-width: 425px) {
    font-size: 16px;
  }
`;

export const GenericButton = styled.button`
  width: fit-content;
  padding: 9px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  margin-top: 20px;
  color: #fff;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
`;
