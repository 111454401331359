import React, { Fragment } from 'react';
import { shape } from 'prop-types';

import { Document, Text } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';

import HistoryEdu from 'assets/img/icons/history.png';
import Trophy from 'assets/img/icons/trophy.png';
import Star from 'assets/img/icons/star.png';
import Phone from 'assets/img/icons/icoPhone.png';
import Email from 'assets/img/icons/icoContact.png';

// Create Styled Components
const Body = styled.Page`
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  font-weight: normal;
  font-size: 10px;
  font-family: 'Helvetica';
`;

const Header = styled.View`
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-bottom: 20px;
`;

const Name = styled.Text`
  font-size: 30px;
`;

const ContactContainer = styled.View`
  color: #999;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContactIcon = styled.Image`
  margin-right: 10px;
  color: #999;
  width: 14px;
  height: auto;
`;

const Contact = styled.Text`
  margin-right: 60px;
`;

const DefaultSection = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-right: 60px;
`;

const SectionTitle = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
`;

const SectionContainer = styled.View`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
`;

const SectionContent = styled.Text`
  margin-bottom: 10px;
`;

const TextStrong = styled.Text`
  font-family: 'Helvetica-Bold';
`;

const Divider = styled.View`
  margin-top: 10px;
  background-color: #efeeec;
  height: 1px;
  width: 100%;
`;

const Footer = styled.View`
  color: #999;
  position: absolute;
  bottom: 20px;
  width: 100vw;
  text-align: center;
`;

const SectionIcon = styled.Image`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-right: 25px;
  border-radius: 5px;
  padding: 5px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
`;

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function addOrdinalSymbol(str) {
  return `${str.split(' ').join('º ')}`;
}

// Create Document Component
const CoverLetter = ({ coverLetter, universityColor }) => (
  <Document
    title={`Carta de apresentação ${coverLetter.student.name}`}
    author="Workalove"
    creator="Workalove"
    producer="Workalove"
  >
    <Body>
      <Header>
        <Name>{coverLetter.student.name}</Name>
        <Text>
          {coverLetter.student.city} / {coverLetter.student.state}
        </Text>
        {coverLetter.student.courses.length > 0 &&
          coverLetter.student.courses.map(
            ({ name, course_status, degree_type, course_semester }, index) => {
              if (course_status === 'Matriculado') {
                const formattedDegree = capitalizeFirstLetter(degree_type);
                const formattedCourseSemester =
                  addOrdinalSymbol(course_semester);
                return (
                  <Text key={index}>
                    {`${name} (${formattedDegree}) - ${formattedCourseSemester}`}
                  </Text>
                );
              }
            }
          )}
        {coverLetter.student.courses.length > 0 &&
          coverLetter.student.courses.map(
            (
              { name, principal_course, course_status, degree_level },
              index
            ) => {
              if (course_status === 'Concluído') {
                const listDegreeLevel = {
                  técnico: 'Formação em',
                  profissionalizante: 'Formação em',
                  'pós graduação': 'Especialização em',
                  graduação: 'Graduação concluída em',
                };
                return (
                  <Text key={index}>
                    {`${
                      listDegreeLevel[degree_level]
                        ? listDegreeLevel[degree_level]
                        : ''
                    } ${principal_course ? principal_course : name}`}
                  </Text>
                );
              }
            }
          )}
        <ContactContainer>
          <Fragment>
            <ContactIcon src={Email} />
            <Contact>
              {coverLetter.student.email || 'E-mail não informado'}
            </Contact>
          </Fragment>

          <Fragment>
            <ContactIcon src={Phone} />
            <Contact>
              {coverLetter.student.mobile_phone || 'Telefone não informado'}
            </Contact>
          </Fragment>
        </ContactContainer>
      </Header>
      <DefaultSection wrap={false}>
        <SectionTitle>
          <SectionIcon universityColor={universityColor} src={HistoryEdu} />
          <TextStrong>Minha história de vida</TextStrong>
        </SectionTitle>
        <SectionContainer>
          <SectionContent>{coverLetter.answers.questionOne}</SectionContent>
          <Divider />
        </SectionContainer>
      </DefaultSection>
      <DefaultSection wrap={false}>
        <SectionTitle>
          <SectionIcon universityColor={universityColor} src={Star} />
          <TextStrong>Suas expectativas relacionadas a formação</TextStrong>
        </SectionTitle>
        <SectionContainer>
          <SectionContent>{coverLetter.answers.questionTwo}</SectionContent>
          <Divider />
        </SectionContainer>
      </DefaultSection>
      <DefaultSection wrap={false}>
        <SectionTitle>
          <SectionIcon universityColor={universityColor} src={Trophy} />
          <TextStrong>Onde você quer chegar</TextStrong>
        </SectionTitle>
        <SectionContainer>
          <SectionContent>{coverLetter.answers.questionThree}</SectionContent>
        </SectionContainer>
      </DefaultSection>
      <Footer fixed>
        <Text>Carta de apresentação - www.workalove.com</Text>
      </Footer>
    </Body>
  </Document>
);

CoverLetter.propTypes = {
  coverLetter: shape.isRequired,
};

export default CoverLetter;
