import styled from "styled-components";

export const BodyStepOne = styled.div`
    margin-top: 25px;
    overflow-y: auto;
    height: 100%;
`
export const ModalTitle = styled.div`
    width: 80%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2px 0 0 40px;
`
export const CardImageModal = styled.img`
    width: 40px;
    height: 40px;
`
export const CardTitleModal = styled.h3`
    color: #009291;
    font-size: 1rem;
    font-weight: 700;
    margin: 0 0 0 15px;
`
export const InputsModalChallenge = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    margin-top: 30px;
`
export const DescImages = styled.p`
    color: #4B4B4D;
    font-size: 12px;
    margin: -10px 0 16px 0;
`