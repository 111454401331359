import { MdLocationPin } from 'react-icons/md';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { BsPersonFill, BsStarFill } from 'react-icons/bs';
import {
  IoExtensionPuzzleOutline,
  IoCalendarOutline,
  IoClose,
} from 'react-icons/io5';
import { IoIosCalendar } from 'react-icons/io';
import { HiOutlineStar, HiFlag, HiWifi } from 'react-icons/hi';
import { BiLike, BiDislike, BiWifi } from 'react-icons/bi';
import DigitalBookIcon from 'assets/StudentLearning/DigitalBook';
import DialogIcon from 'assets/StudentLearning/Dialog';
import { TbSpeakerphone } from 'react-icons/tb';
import {BrainIcon} from 'assets/StudentLearning/brainIcon'
import Dolar from 'assets/StudentLearning/dolar.svg';
import CalendarIcon from 'assets/StudentLearning/Calendar';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
} from 'react-icons/md';
import { CertificateIcon } from 'assets/StudentLearning/CertificateIcon';
import ArrowUpIcon from 'assets/StudentLearning/arrowUp';

export const useLearningIcons = () => {
  return {
    MdLocationPin,
    RiMoneyDollarCircleFill,
    BsPersonFill,
    IoExtensionPuzzleOutline,
    IoCalendarOutline,
    HiOutlineStar,
    BiLike,
    BiDislike,
    DigitalBookIcon,
    TbSpeakerphone,
    IoIosCalendar,
    HiFlag,
    DialogIcon,
    BsStarFill,
    Dolar,
    CalendarIcon,
    IoClose,
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    CertificateIcon,
    BiWifi,
    MdKeyboardArrowUp,
    ArrowUpIcon,
    HiWifi,
    BrainIcon
  };
};
