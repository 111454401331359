// Source: https://www.geradorcnpj.com/javascript-validar-cnpj.htm
function validateCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj == '') return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == '00000000000000' ||
    cnpj == '11111111111111' ||
    cnpj == '22222222222222' ||
    cnpj == '33333333333333' ||
    cnpj == '44444444444444' ||
    cnpj == '55555555555555' ||
    cnpj == '66666666666666' ||
    cnpj == '77777777777777' ||
    cnpj == '88888888888888' ||
    cnpj == '99999999999999'
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

function handleFieldMask(event, mask, pastedValue) {
  if (!pastedValue) {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      return event.target.value;
    }
    event.target.value = mask(event.target.value);
    return event.target.value;
  }

  return (event.target.value = mask(pastedValue));
}

function isValidDate(fieldId) {
  return document.querySelector(`input#${fieldId}`).value.length === 10;
}

const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/;
const hoursRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
const hoursRegexEvent = /^(?=.*\d)([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
const mobilePhoneNumberRegex =
  /^(\([0-9]{2}\) [9]?[0-9]{4}-[0-9]{4})|(\(1[2-9]\))$/;
const homePhoneNumberRegex =
  /^(\([0-9]{2}\) [0-9]{4}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$/;

const isYoutubeVideo = (url) => {
  let validationUrl =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return url.match(validationUrl) ? RegExp.$1 : false;
};

function validationCurrency(value) {
  if (value) {
    value = value.replace(/\D/g, '');
    value = (value / 100).toFixed(2) + '';
    value = value.replace('.', ',');
    value = value.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    value = value.replace(/(\d)(\d{3}),/g, '$1.$2,');

    return value.substring(0, 10);
  }
  return '';
}

export {
  validateCNPJ,
  cnpjRegex,
  homePhoneNumberRegex,
  mobilePhoneNumberRegex,
  hoursRegex,
  hoursRegexEvent,
  handleFieldMask,
  isValidDate,
  isYoutubeVideo,
  validationCurrency,
};
