// Self knowledge
import RichardJohnThumb from "assets/img/thumbs/selfknowledge-video-8-segredos.jpg";
import ElizabethGilbertThumb from "assets/img/thumbs/selfknowledge-video-sucesso-fracasso.jpg";
import AmyCuddyThumb from "assets/img/thumbs/selfknowledge-video-linguagem-corporal.jpg";

import AriannaHuffingtonThumb from "assets/img/thumbs/selfknowledge-livro-a-terceira-medida.jpg";
import PauloYazigiSabbagThumb from "assets/img/thumbs/selfknowledge-livro-resiliencia.jpg";
import PauloVieiraThumb from "assets/img/thumbs/selfknowledge-livro-poder-acao.jpg";

import MovieSrThumb from "assets/img/thumbs/selfknowledge-filme-um-senhor.jpg";
import MovieLostMarsThumb from "assets/img/thumbs/selfknowledge-filme-perdido-marte.jpg";
import MovieLalalandThumb from "assets/img/thumbs/selfknowledge-filme-lalaland.jpg";

// SoftSkills
import KioStarkThumb from "assets/img/thumbs/soft-skills-video-falar-com-estranhos.jpg";
import SherryTurkleThumb from "assets/img/thumbs/soft-skills-video-revolucao-colaborativa.jpg";
import TatianaLeiteThumb from "assets/img/thumbs/soft-skills-video-conectado-e-so.jpg";

import ClayShirkyThumb from "assets/img/thumbs/soft-skills-livro-cultura-participacao.jpg";
import MarshallRosenbergThumb from "assets/img/thumbs/soft-skills-livro-comunicacao-nao-violenta.jpg";
import DanielGolemanThumb from "assets/img/thumbs/soft-skills-livro-inteligencia-emocional.jpg";

import MoviePatchAdmsThumb from "assets/img/thumbs/soft-skills-filme-patch-adams.jpg";
import MovieKingSpeechThumb from "assets/img/thumbs/soft-skills-filme-discurso-do-rei.jpg";
import MovieCoachCarterThumb from "assets/img/thumbs/soft-skills-filme-coach-carter.jpg";

// Leadership

import VideoThumb01 from "assets/img/thumbs/protagonismo-01.jpg";
import VideoThumb02 from "assets/img/thumbs/protagonismo-02.jpg";
import VideoThumb03 from "assets/img/thumbs/protagonismo-03.jpg";

import BooksThumb01 from "assets/img/thumbs/protagonismo-04.jpg";
import BooksThumb02 from "assets/img/thumbs/protagonismo-05.jpg";
import BooksThumb03 from "assets/img/thumbs/protagonismo-06.jpg";

import MoviesThumb01 from "assets/img/thumbs/protagonismo-07.jpg";
import MoviesThumb02 from "assets/img/thumbs/protagonismo-08.jpg";
import MoviesThumb03 from "assets/img/thumbs/protagonismo-09.jpg";

const data = {
  selfknowledge: {
    id: "selfknowledge",
    whatIs: {
      description:
        "Autoconhecimento representa ter clareza de nosso perfil e de nossa concepção de sucesso na vida e na carreira.",
      videoSrc: "https://www.youtube.com/embed/jMGgIBhdfvo?rel=0",
      cite: [
        {
          description:
            "Eu sei o preço do sucesso: dedicação, trabalho duro e uma incessante devoção às coisas que você quer ver acontecer.",
          author: "(Frank Lloyd Wright - Arquiteto)"
        },
        {
          description:
            "O insucesso é apenas uma oportunidade para recomeçar com mais inteligência",
          author: "(Henry Ford – empreendedor e criador do fordismo)"
        },
        {
          description:
            "Se você ultrapassar aquela sensação de medo, aquele sentimento de estar correndo risco, coisas realmente maravilhosas podem acontecer.",
          author: "(Marissa Mayer - CEO da Yahoo)"
        }
      ]
    },
    howImportant: {
      description:
        "O volume de informações e de padrões a serem seguidos podem nos deixar meio perdidos e nos afastar do que é realmente importante para nós. Para não cair na armadilha de viver sem coerência e foco, é preciso se especializar em si mesmo. Entenda melhor assistindo ao vídeo abaixo:",
      videoSrc: "https://www.youtube.com/embed/fKT__hXP6RQ?rel=0"
    },
    howAdvance: {
      videoSrc: "https://www.youtube.com/embed/4JcHDYEhxYI?rel=0",
      steps: [
        "Mantenha o foco no que importa: saiba o que você quer e se envolva em projetos e atividades que reflitam seus reais interesses e paixões.",
        "Erros promovem aprendizado: o caminho rumo ao êxito há falhas e se dispor ao sucesso é também se dispor ao fracasso.",
        "Faça da determinação seu mantra: sua determinação representa o seu comprometimento com a vida que de fato se quer viver e com sua concepção de sucesso."
      ]
    },
    videos: {
      trail: [
        {
          img: RichardJohnThumb,
          title: "Richard John – 8 segredos para o sucesso",
          description:
            "Questionado por uma estudante sobre os motivos que levam ao sucesso, Richard John entrevistou 500 participantes do TED Talks e descobriu os 8 segredos que levaram essas pessoas a serem bem sucedidas em seus projetos: trabalhar com nossas paixões, trabalhar duro, focar, persistir, desenvolver boas ideias, se tornar muito bom em algo, buscar ir sempre além e servir aos outros."
        },
        {
          img: ElizabethGilbertThumb,
          title:
            "Elizabeth Gilbert – Sucesso, fracasso e motivação para continuar criando",
          description:
            "A famosa autora do livro “Comer, Rezar e Amar” nos conta os momentos de grande angústia vividos logo após o seu grande sucesso com o best seller e seu medo de fracassar com um novo livro. Nesse processo, descobre algo fundamental sobre a relação entre sucesso e fracasso."
        },
        {
          img: AmyCuddyThumb,
          title: "Amy Cuddy – Sua linguagem corporal molda quem você é",
          description:
            "A linguagem corporal afeta não só a maneira como somos vistos, mas também como vemos a nós mesmo. A psicóloga social Amy Cuddy mostra que ficar numa postura confiante, mesmo sem efetivamente se sentir assim, pode afetar nossos níveis hormonais, nossa autoconfiança e também aumentar nossas chances de sucesso."
        }
      ]
    },
    books: {
      trail: [
        {
          img: AriannaHuffingtonThumb,
          title: "A Terceira Medida do Sucesso - Arianna Huffington",
          description:
            "Uma das mulheres de maior influência no mundo explica que nossa atual definição de sucesso está literalmente nos matando e nos ajuda a redefinir o que é uma vida realmente bem sucedida."
        },
        {
          img: PauloYazigiSabbagThumb,
          title:
            "Resiliência - Competência para enfrentar situações extraordinárias na sua vida profissional - Paulo Yazigi Sabbag",
          description:
            "Integrando saber ancestral, científico recente e tácito, o livro apresenta e ilustra a resiliência no dia a dia das pessoas e de grandes líderes."
        },
        {
          img: PauloVieiraThumb,
          title: "O Poder da Ação - Paulo Vieira",
          description:
            "Esse livro é um convite para começar a percorrer um caminho de realizações baseado em desafios e atitudes (acreditar, ter foco e agir) capazes de promover a mudança rumo à vida que queremos viver."
        }
      ]
    },
    movies: {
      trail: [
        {
          img: MovieSrThumb,
          title: "Um senhor estagiário",
          description:
            "Ben é um viúvo aposentado que para preencher seu tempo livre aceita o desafio de se tornar estagiário de uma empresa virtual de loja de roupas femininas. Ben passa a trabalhar com a dona do negócio, Jules, uma jovem com fama de difícil e que vê sua vida familiar ser cada vez mais negligenciada com o crescimento do negócio. Mas, a proximidade de Ben e Julie transforma suas visões sobre ser bem sucedido."
        },
        {
          img: MovieLostMarsThumb,
          title: "Perdido em Marte",
          description:
            "Um astronauta é enviado a uma missão em Marte. Após uma severa tempestade, ele é dado como morto, abandonado pelos colegas e acorda sozinho no misterioso planeta com escassos suprimentos. Sem saber como reencontrar os companheiros ou retornar à Terra decide usar todas as combinações de recursos possíveis para alcançar seu único objetivo de sucesso: sobreviver."
        },
        {
          img: MovieLalalandThumb,
          title: "La La Land",
          description:
            "O musical traz a história do pianista Sebastian que ganha a vida tocando em festas e bares e que se apaixona perdidamente pela atriz iniciante Mia. Em busca de oportunidades numa cidade competitiva, as carreiras dos jovens finalmente começam a ascender e os pressionam a tomar decisões que podem ameaçar seu relacionamento. Um filme leve e inspirador sobre como escolher e viver o sucesso!"
        }
      ]
    }
  },

  softSkills: {
    id: "softskills",
    whatIs: {
      description:
        "Soft Skills são habilidades emocionais e sociais que nos permitem usar nosso potencial máximo e promover colaboração.",
      videoSrc: "https://www.youtube.com/embed/bHL42VaO21Q?rel=0",
      cite: [
        {
          description:
            "Uma máquina consegue fazer o trabalho de 50 homens ordinários. Nenhuma máquina consegue fazer o trabalho de um homem extraordinário.",
          author: "(Elbert Hubbard – filósofo e escritor)"
        },
        {
          description:
            "O espírito humano precisa prevalecer sobre a tecnologia.",
          author:
            "(Albert Einstein – cientista criador da Teoria da Relatividade)"
        },
        {
          description:
            "O mais importante é a mudança, o movimento, o dinamismo, a energia. Só o que está morto não muda!",
          author: "(Clarice Lispector - escritora e jornalista)"
        }
      ]
    },
    howImportant: {
      description:
        "Na nova dinâmica do mundo do trabalho deixa de ser suficiente a formação técnica e especialista capaz de atender sistemas mecanicistas. Os novos tempos pressionam para a ampliação de capacidades sociais e emocionais, as chamadas Soft Skills. Entenda melhor assistindo ao vídeo abaixo:",
      videoSrc: "https://www.youtube.com/embed/rsWptj35naM?rel=0"
    },
    howAdvance: {
      videoSrc: "https://www.youtube.com/embed/4mkqZK8maVk?rel=0",
      steps: [
        "Pense antes de agir: racionalize sobre os fatos e os interesses envolvidos em situações críticas para agir com objetividade e focado na solução.",
        "Peça por feedbacks: peça a opinião das pessoas sobre suas entregas e resultados para que você tenha a oportunidade de se desenvolver.",
        "Comunique-se: explique o que entendeu das informações que tem acesso e do que acredita que é esperado de você para evitar falhas de comunicação."
      ]
    },
    videos: {
      trail: [
        {
          img: KioStarkThumb,
          title: "Porque devemos falar com estranhos - Kio Stark",
          description:
            "Falando sobre intimidade passageira, vieses e as categorias que criamos em nossos relacionamentos, Kio Stark mostra como isso pode ser interessante para as relações, nos tirar da zona de conforto e nos conectar com novas pessoas."
        },
        {
          img: SherryTurkleThumb,
          title: "Conectado, mas só? – Sherry Turkle",
          description:
            "Esperamos mais da tecnologia, mas será que esperamos menos um do outro? Sherry Turkle analisa como os nossos dispositivos online estão redefinindo conexão humana e comunicação — e nos pede para pensar profundamente sobre a conexão que de fato queremos ter com os outros."
        },
        {
          img: TatianaLeiteThumb,
          title: "TED – O próximo passo da revolução colaborativa",
          description:
            "A revolução colaborativa está mudando de forma estrutural, irreversível e inevitável a forma como as pessoas se relacionam, aprendem, produzem e consomem no mundo todo e pode ser uma grande oportunidade para o Brasil."
        }
      ]
    },
    books: {
      trail: [
        {
          img: ClayShirkyThumb,
          title: "A cultura da participação – Clay Shirky",
          description:
            "As novas tecnologias de comunicação transformaram a forma como as pessoas produzem, compartilham e consomem informações, gerando o 'excedente cognitivo' e novas vias de colaboração."
        },
        {
          img: MarshallRosenbergThumb,
          title:
            "Comunicação Não - Violenta : Técnicas Para Aprimorar Relacionamentos Pessoais e Profissionais – Marshall Rosenberg",
          description:
            "De forma prática é possível aprender a resolver conflitos pacificamente, criar relacionamentos baseados em respeito mútuo, compaixão e cooperação para diminuir a violência no mundo."
        },
        {
          img: DanielGolemanThumb,
          title: "Inteligência emocional — Daniel Goleman",
          description:
            "Baseado em pesquisas e usando exemplos cotidianos, Daniel Goleman justifica como a consciência sobre as próprias emoções é determinante no desenvolvimento da inteligência."
        }
      ]
    },
    movies: {
      trail: [
        {
          img: MoviePatchAdmsThumb,
          title: "Patch Adams",
          description:
            "Após tentar suicídio, Hunter Adams se interna em uma clínica psiquiátrica. Ao ajudar outros internos, descobre que deseja ser médico. Durante a faculdade cria métodos não convencionais de tratamento que refletem seu propósito da forma mais verdadeira possível e inspiradora para todos."
        },
        {
          img: MovieKingSpeechThumb,
          title: "O Discurso do Rei",
          description:
            "Desde os 4 anos, George (Colin Firth) é gago. Este é um sério problema para um integrante da realiza britânica, que frequentemente precisa fazer discursos. Um terapeuta de fala de método pouco convencional atua como seu psicólogo e amigo, ajudando-o a adquirir autoconfiança e cumprir com seus desafios."
        },
        {
          img: MovieCoachCarterThumb,
          title: "COACH CARTER",
          description:
            "Ken Carter é convidado para ser técnico de basquete de sua antiga escola onde conseguiu ter recordes. A escola fica em uma área pobre da cidade e para conseguir fazer do time de basquete vencedor, ele impõe um sistema rígido de revisão de crenças com estudos, disciplina e bom comportamento. O resultado mobiliza o time, o colégio e toda a comunidade."
        }
      ]
    }
  },

  leadership: {
    id: "leadership",
    whatIs: {
      description:
        "Protagonismo representa a decisão por se tornar o responsável por sua vida e por construir seu próprio plano de carreira.",
      videoSrc: "https://www.youtube.com/embed/DpOjpjeuv70?rel=0",
      cite: [
        {
          description: "A melhor maneira de prever o futuro é criá-lo.",
          author: "(Peter Drucker - Escritor e Professor de Administração)"
        },
        {
          description:
            "Ou você tem uma estratégia, ou faz parte da estratégia de alguém.",
          author: "(Alvim Toffler - Escritor e Futurista)"
        },
        {
          description:
            "Seu tempo é limitado então não o desperdice vivendo a vida de outra pessoa.",
          author: "(Steve Jobs - Steve Jobs – Empreendedor)"
        }
      ]
    },
    howImportant: {
      description:
        "O crescente aumento do dinamismo do mercado de trabalho gera a necessidade de profissionalizarmos a forma como gerenciamos nossas próprias carreias. É nesse contexto que os Planos de Carreira ganham espaço e nos orientam na construção de nossas histórias de vida e carreira. Entenda melhor assistindo ao vídeo abaixo:",
      videoSrc: "https://www.youtube.com/embed/xPUA33ifxc0?rel=0"
    },
    howAdvance: {
      videoSrc: "https://www.youtube.com/embed/hp1RAk8tQC4?rel=0",
      steps: [
        "Se diferencie: construa seu futuro agora ao investir no desenvolvimento de suas capacidades, conhecimentos, experiência e contatos.",
        "Mantenha a mente aberta: um plano de carreira é um orientador que deve ser revisado à medida que novas oportunidades surgem e os horizontes se ampliam.",
        "Fortaleça suas conexões: busque se relacionar com pessoas que te acrescentem e estejam dispostas a contribuir com seu desenvolvimento profissional."
      ]
    },
    videos: {
      trail: [
        {
          img: VideoThumb01,
          title:
            "Larry Smith - Por que você falhará em ter uma grande carreira",
          description:
            "Nesse divertido TED Talk, Larry Smith nos mostra como usaremos todas as desculpas possíveis (e impossíveis) para justificar não sermos bem sucedidos. Será que você identifica alguma que costuma usar para não ser protagonista de sua carreira?"
        },
        {
          img: VideoThumb02,
          title: "O Emprego",
          description:
            "Esse premiado curta metragem de animação é uma crítica sobre como o trabalho pode se tornar desumano e utilitarista. É um convite para refletirmos coletivamente sobre a realidade que vivemos e sobre alternativas que valorizem o trabalho como identidade e fonte de realização."
        },
        {
          img: VideoThumb03,
          title:
            "Daniel Goldstein - A Batalha sobre o Eu presente e o Eu futuro",
          description:
            "Todo dia nosso Eu presente toma decisões que podem ter consequências boas ou ruins para o nosso Eu futuro. Nesse TED, o psicólogo Daniel Goldstein nos mostra como poderíamos fazer melhores escolhas para o nosso Eu futuro e gerar mais sustentabilidade para nossas próprias vidas."
        }
      ]
    },
    books: {
      trail: [
        {
          img: BooksThumb01,
          title: "Business Model You: O Modelo de Negócios Pessoal",
          description:
            "O Business Model You usa de um método visual poderoso de uma página para ensinar como desenhar modelos de negócios pessoais que revelem novas e mais gratificantes possibilidades de carreira."
        },
        {
          img: BooksThumb02,
          title: "Sua vida em primeiro lugar - Cheryl Richardson",
          description:
            "Um dos primeiros livros de coaching pessoal traduzidos para o português aborda os obstáculos mais comuns à realização profissional e pessoal. A autora aborda como colocar-se em primeiro lugar na sua lista de prioridades e, assim, criar a vida que de fato quer viver."
        },
        {
          img: BooksThumb03,
          title: "A Autobiografia de Martin Luther King - Clayborne Carson",
          description:
            "Um retrato em primeira pessoa do líder do movimento revolucionário contra a segregação racial. O livro retrata a vida de Martin Luther King desde sua infância até seu assassinato, revelando sua busca por equilibrar as necessidades de sua família às demandas crescentes do ideal de uma nova nação."
        }
      ]
    },
    movies: {
      trail: [
        {
          img: MoviesThumb01,
          title: "A Teoria de Tudo",
          description:
            "Baseado na biografia de Stephen Hawking, um dos maiores físicos de todos os tempos, o filme retrata sua vida pessoal a partir da descoberta de uma doença motora degenerativa aos 21 anos. O filme vai muito além de suas importantes descobertas sobre o tempo e retrata a riqueza da vida que esse gênio escolheu viver, seus aprendizados, desencontros e reencontros."
        },
        {
          img: MoviesThumb02,
          title: "Estrelas Além do Tempo",
          description:
            "No auge da corrida espacial travada entre Estados Unidos e Rússia durante a Guerra Fria, uma equipe de cientistas mulheres afro-americanas da NASA liderou uma das maiores operações tecnológicas registradas à época, tornando-se verdadeiras heroínas nacionais. Uma história de protagonismo, superação e competência."
        },
        {
          img: MoviesThumb03,
          title: "Clube de Compras Dallas",
          description:
            "O caubói texano Ron Woodroof leva uma vida sem limites. Depois de um acidente de trabalho, seus exames indicam que Ron é soropositivo. Com a chegada dos sintomas inicia sua busca por tratamentos alternativos. Descobre o contestável monopólio da indústria farmacêutica e desafia fronteiras perigosas que levaram a mudanças significativas no tratamento contra o HIV."
        }
      ]
    }
  }
};

export default data;
