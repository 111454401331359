export default function DialogIcon ({color = "#009291", size = 13}) {
  return (
    <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8372 5.63536C12.765 4.46654 12.3523 3.34441 11.65 2.4073C10.9477 1.4702 9.98656 0.759086 8.88502 0.361635C7.78347 -0.0358163 6.5897 -0.102232 5.45087 0.170576C4.31204 0.443385 3.27794 1.04349 2.47604 1.89691C1.67415 2.75033 1.13953 3.81976 0.938079 4.97335C0.736631 6.12695 0.877167 7.31428 1.34236 8.38896C1.80756 9.46365 2.57707 10.3787 3.55604 11.0214C4.53501 11.664 5.68064 12.0061 6.85169 12.0055H10.3397C11.0019 12.0048 11.6367 11.7415 12.105 11.2733C12.5732 10.805 12.8365 10.1702 12.8372 9.50799V5.63536ZM11.8382 9.50799C11.8382 9.90542 11.6803 10.2866 11.3993 10.5676C11.1183 10.8486 10.7371 11.0065 10.3397 11.0065H6.85169C6.14686 11.0062 5.45002 10.8573 4.80659 10.5696C4.16316 10.2819 3.58759 9.86176 3.11743 9.33666C2.64499 8.81182 2.29022 8.19204 2.07692 7.51887C1.86361 6.8457 1.7967 6.13471 1.88067 5.43356C2.01325 4.32764 2.51013 3.2973 3.29297 2.50496C4.07582 1.71262 5.1001 1.20335 6.20434 1.05744C6.41974 1.03044 6.6366 1.01676 6.85369 1.01648C8.01776 1.01331 9.1458 1.42003 10.04 2.16533C10.5622 2.59937 10.9909 3.13494 11.3 3.73957C11.6091 4.34421 11.7922 5.00531 11.8382 5.68282V9.50799Z" fill={color}/>
      <path d="M4.84472 4.51316H6.84272C6.97519 4.51316 7.10224 4.46054 7.19592 4.36686C7.28959 4.27319 7.34222 4.14614 7.34222 4.01366C7.34222 3.88118 7.28959 3.75414 7.19592 3.66046C7.10224 3.56679 6.97519 3.51416 6.84272 3.51416H4.84472C4.71224 3.51416 4.58519 3.56679 4.49152 3.66046C4.39784 3.75414 4.34521 3.88118 4.34521 4.01366C4.34521 4.14614 4.39784 4.27319 4.49152 4.36686C4.58519 4.46054 4.71224 4.51316 4.84472 4.51316Z" fill={color}/>
      <path d="M8.84072 5.51208H4.84472C4.71224 5.51208 4.58519 5.56471 4.49152 5.65839C4.39784 5.75206 4.34521 5.87911 4.34521 6.01159C4.34521 6.14406 4.39784 6.27111 4.49152 6.36479C4.58519 6.45846 4.71224 6.51109 4.84472 6.51109H8.84072C8.9732 6.51109 9.10025 6.45846 9.19392 6.36479C9.28759 6.27111 9.34022 6.14406 9.34022 6.01159C9.34022 5.87911 9.28759 5.75206 9.19392 5.65839C9.10025 5.56471 8.9732 5.51208 8.84072 5.51208Z" fill={color}/>
      <path d="M8.84072 7.51013H4.84472C4.71224 7.51013 4.58519 7.56276 4.49152 7.65643C4.39784 7.75011 4.34521 7.87716 4.34521 8.00963C4.34521 8.14211 4.39784 8.26916 4.49152 8.36283C4.58519 8.45651 4.71224 8.50913 4.84472 8.50913H8.84072C8.9732 8.50913 9.10025 8.45651 9.19392 8.36283C9.28759 8.26916 9.34022 8.14211 9.34022 8.00963C9.34022 7.87716 9.28759 7.75011 9.19392 7.65643C9.10025 7.56276 8.9732 7.51013 8.84072 7.51013Z" fill={color}/>
    </svg>

  )
}