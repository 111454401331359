export const AcademicCheckIcon = ({ size = 18, color = 'white' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9091 2L6.34659 11.5625L2 7.21591"
        stroke={color}
        stroke-width="2.56311"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
