import styled from 'styled-components';
import { Container, Content, Box } from 'components/modals/styles/BaseModal';
import JobCards from 'views/StaffPages/jobOpeningsRebuild/components/Cards';
import { JobDetailButton } from 'views/StaffPages/jobOpeningsRebuild/components/JobDetails';
import { Button } from '../../../../JobDetails/components/Buttons/buttonJobDetail';
import { ContentWrapper } from 'views/StaffPages/jobOpeningsRebuild/components/Cards/style';

export const Header = styled.div`
  background-color: #009291;
  width: 100%;
  height: 84px;
  border-radius: 12px 12px 0px 0px;
  padding: 20px 24px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    border: none;
    outline: none;
    background: #fff;
    color: #4b4b4d;
    cursor: pointer;
  }
`;

export const CustomContainer = styled(Container)`
  align-items: baseline;
`;

export const CustomBox = styled(Box)`
  align-items: baseline;
`;

export const ModalContent = styled(Content)`
  padding: 0px;
  border-radius: 12px;
  min-height: 416px;
  min-width: 674px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 70vh;
  overflow-y: auto;
  padding: 38px 52px 56px 52px;
  width: 674px;
  gap: 40px;

  ::-webkit-scrollbar {
    width: 0.4em;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #fff;
    background-color: #80808069;
    border-radius: 4px;
  }
`;

export const Card = styled(JobCards)`
  justify-content: stretch;
  height: auto;
  & ${ContentWrapper} {
    span:last-child {
      padding-bottom: 0px;
      margin-bottom: 20px;
    }
  }

  & ${Button} {
    background-color: #009291 !important;
    color: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
    margin-top: 20px;
  }
`;
