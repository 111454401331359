import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: flex-start;
  justify-content: center;
  z-index: 2000;
  overflow-y: auto;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  overflow-y: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 673px;

  background-color: #ffffff;
  border-radius: 10px;

  position: relative;
`;

export const TopBar = styled.div`
  height: 85px;
  width: 100%;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background-color: #009291;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 46px;

  .title {
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;

  border-radius: 200px;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 49px 45px 15px;

  p {
    font-size: 18px;
    font-weight: 700;
    color: #606062;
  }

  span {
    font-size: 13x;
    font-weight: 400;
    line-height: 20px;
    color: #606062;
  }

  .checkbox-group {
    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding-top: 30px;
  }

  .send-button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 146px;
    height: 38px;
    border-radius: 3px;
    border: none;

    background-color: #009291;

    font-size: 15px;
    font-weight: 700;
    color: #fff;

    cursor: pointer;
  }

  .cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 38px;
    border-radius: 3px;
    border: none;

    background-color: transparent;

    font-size: 15px;
    font-weight: 700;
    color: #009291;

    cursor: pointer;
  }

  .container-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;

    margin-top: 33px;
    gap: 25px;
  }

  .group-span-icon {
    display: flex;
    align-items: center;
    gap: 11px;

    font-size: 15px;
    color: #606062;
    font-weight: 400;
    margin-bottom: 16px;
  }
`;

export const FakeCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 2px;

  background-color: #009291;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
