import { finishedLearning } from 'views/Student/Learning/Services';
import { Container, Box, ButtonX } from '../../../Styles/BaseModal';
import {
  BubbleDialog,
  ButtonReturn,
  ContainerButton,
  Content,
  AdvanceButton,
  WrapperMessage,
  WrapperTitle,
} from './style';
import Wall from 'assets/JobOffer/IconPerson.svg';
import { useState, useContext, useEffect } from 'react';
import { useLearningIcons } from 'views/Student/Learning/Hooks/useLearningIcons';

import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import BaseLayoutContext from 'contexts/base-layout';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import ExtraCoursesForm from 'views/MyProfile/components/ExtraCourse/ExtraCoursesForm';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';
import { getStudentGamification, visibleItems } from 'services/student/profile';

export default function InformationConclusionModal({
  data,
  resetData,
  controlOpenModal,
  setControlOpenModal,
  setModalViewDetails,
  listingType,
  availableListRef,
  learningCountControl,
  updatedData,
}) {
  const [completionModalControl, setCompletionModalControl] = useState(false);
  const { setLearnings, setFavoritesLearning, setFinalizedLearning } =
    useContext(LearningContext);
  const { openModalWithContent, universityColor, profile_id, openSnackbar } =
    useContext(BaseLayoutContext);
  const [gamificationData, setGamificationData] = useState(null);
  const [visibleInCurriculum, setVisibleInCurriculum] = useState([]);

  const {
    getAvailableLearnings,
    getFinalizedLearnings,
    getFavoritesLearnings,
  } = useAPICallsLearning();

  const fetchGamificationData = async () => {
    const id = profile_id;
    const data = await getStudentGamification(id);
    setGamificationData && setGamificationData(data);
  };

  const fetchVisibleItems = async () => {
    const response = await visibleItems();
    setVisibleInCurriculum(response);
  };

  useEffect(() => {
    fetchGamificationData();
    fetchVisibleItems();
  }, []);

  const handleCloseModal = () => {
    setControlOpenModal(false);
    resetData(null);
    setModalViewDetails && setModalViewDetails(false);
    openModalWithContent(
      mergeContextsHelper(
        <ExtraCoursesForm
          isAttractionCompletion
          attractionInfo={data}
          concludeAttraction={finishedLearning}
          fetchGamificationData={fetchGamificationData}
        />
      )
    );
  };

  const onCloseModal = () => {
    setControlOpenModal(false);
    setModalViewDetails && setModalViewDetails(false);
    resetData(null);
  };

  const handleReportCompletion = async (id) => {
    try {
      await finishedLearning(id);

      setCompletionModalControl(true);

      if (listingType && listingType === 'Available') {
        setLearnings(null);
        learningCountControl();
        getAvailableLearnings(true);
        return;
      }

      if (listingType && listingType === 'Favorite') {
        setFavoritesLearning(null);
        learningCountControl();
        getFavoritesLearnings(true);
        return;
      }

      if (listingType && listingType === 'Finalized') {
        setFinalizedLearning(null);
        learningCountControl();
        getFinalizedLearnings(true);
        return;
      }
      updatedData && updatedData();
    } catch (error) {
      openSnackbar('Ocorreu um erro, tente novamente mais tarde.', true);
    }
  };

  const { IoClose } = useLearningIcons();

  const WarningModal = () => {
    return (
      <Container isOpen={controlOpenModal}>
        <Box centerModal>
          <Content>
            <ButtonX colorBg="#EFEEEC" onClick={onCloseModal}>
              <IoClose size={25} color="#4B4B4D" />
            </ButtonX>
            <WrapperTitle universityColor={universityColor}>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog
                universityColor={universityColor}
                widthBubble="123px"
                heightBubble="48px"
              >
                <span>Atenção!</span>
              </BubbleDialog>
            </WrapperTitle>
            <WrapperMessage
              fontMessage="18px"
              lineHeigthMessage="1.6rem"
              positionMessage="start"
            >
              <p>
                Ao informar a conclusão deste conteúdo ele ficará disponível na
                aba <strong> Finalizados </strong>.
              </p>
              <p>
                Tem certeza que deseja informar a conclusão do conteúdo{' '}
                <strong> {data.name} </strong>?
              </p>
            </WrapperMessage>
            <ContainerButton>
              <ButtonReturn
                universityColor={universityColor}
                onClick={onCloseModal}
                marginTopButton="0"
              >
                NÃO
              </ButtonReturn>
              <AdvanceButton
                universityColor={universityColor}
                marginTopButton="0"
                onClick={() => handleReportCompletion(data.id)}
              >
                SIM, FINALIZAR
              </AdvanceButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    );
  };

  const CompletionModal = () => {
    return (
      <Container isOpen={controlOpenModal}>
        <Box centerModal>
          <Content>
            <ButtonX colorBg="#EFEEEC" onClick={onCloseModal}>
              <IoClose size={25} color="#4B4B4D" />
            </ButtonX>
            <WrapperTitle universityColor={universityColor}>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog
                universityColor={universityColor}
                widthBubble="123px"
                heightBubble="48px"
              >
                <span>Parabéns!</span>
              </BubbleDialog>
            </WrapperTitle>
            <WrapperMessage
              fontMessage="18px"
              lineHeigthMessage="1.6rem"
              positionMessage="center"
            >
              <p>
                Você finalizou o conteúdo <strong>{data.name}</strong> com
                sucesso. Você pode cadastrar essa aprendizagem no seu currículo
                do futuro para o deixar ainda mais completo!
              </p>
            </WrapperMessage>
            <ContainerButton>
              <AdvanceButton
                universityColor={universityColor}
                marginTopButton="0"
                widthButton="211px"
                onClick={() => handleCloseModal()}
              >
                VAMOS LÁ!
              </AdvanceButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    );
  };

  return completionModalControl === false ? (
    <WarningModal />
  ) : (
    <CompletionModal />
  );
}
