export function IconSocialMeta() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13782_47755)">
        <path
          d="M12.9188 3.01953C11.4428 3.01953 10.2893 4.13103 9.2445 5.54328C7.809 3.71553 6.609 3.01953 5.17275 3.01953C2.244 3.01953 0 6.83028 0 10.8645C0 13.389 1.221 14.9813 3.267 14.9813C4.73925 14.9813 5.79825 14.2875 7.68075 10.9965C7.68075 10.9965 8.46525 9.61053 9.00525 8.65653C9.19425 8.96178 9.393 9.29028 9.603 9.64353L10.4858 11.1285C12.2055 14.0055 13.1633 14.9813 14.8995 14.9813C16.8923 14.9813 18.0015 13.3673 18.0015 10.7903C18 6.56628 15.7058 3.01953 12.9188 3.01953ZM6.2445 10.1055C4.71825 12.498 4.19025 13.0343 3.3405 13.0343C2.466 13.0343 1.94625 12.2663 1.94625 10.8975C1.94625 7.96878 3.4065 4.97478 5.14725 4.97478C6.09 4.97478 6.8775 5.51928 8.08425 7.24653C6.939 9.00303 6.2445 10.1055 6.2445 10.1055ZM14.9393 13.0343C14.07 13.0343 13.6545 12.4605 12.0045 9.80478L10.9493 8.04453C10.6635 7.58028 10.3898 7.15353 10.1258 6.76203C11.0768 5.29428 11.8613 4.56228 12.795 4.56228C14.7338 4.56228 16.2848 7.41678 16.2848 10.9223C16.284 12.2588 15.8468 13.0343 14.9393 13.0343Z"
          fill="#606062"
        />
      </g>
      <defs>
        <clipPath id="clip0_13782_47755">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
