import { format, parse } from 'date-fns';

const portfolioProjectsAdapter = ({
  id,
  knowledge,
  title,
  link,
  visible,
  section,
  dedicatedTime,
  classification,
  finishedAt,
  companyChallenge,
}) => {
  const portfolioProjects = {
    id,
    title,
    visible,
    company_challenge: companyChallenge && companyChallenge.value,
    section: section && section.value,
    dedicated_time: dedicatedTime,
    portfolio_classification: classification && classification.value,
    finished_at: format(
      parse(finishedAt, 'yyyy-MM-dd', new Date()),
      'dd/MM/yyyy'
    ),
  };

  if (knowledge && knowledge.length > 0) {
    portfolioProjects.knowledge = knowledge.map((item) => item.value);
  }

  if (!link.match(/(ftp|http|https)/g)) {
    const linkUrl = link.replace(/\:?\/\//g, '');
    portfolioProjects.link = `http://${linkUrl}`;
  } else {
    portfolioProjects.link = link;
  }

  return portfolioProjects;
};

export default portfolioProjectsAdapter;
