import { Fragment, useContext, useEffect } from 'react';
import { ListArea, Sentinel } from '../../../Styles/BaseComponent';
import LearningCard from '../../../UIComponents/Card';
import { useIntersectionPagination } from 'views/Student/Learning/Hooks/useIntersectionPagination';
import { useAPICallsLearning } from 'views/Student/Learning/Hooks/apiCallsLearning';
import { LearningContext } from 'views/Student/Learning/Context/LearningContext';
import { getLearningFilters } from 'views/Student/Learning/Services';

import { profileAdapter } from 'adapters/student/profileAdapter';
import { CurriculumModal } from 'views/MyProfile/components/CurriculumModal/CurriculumModal';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import SkeletonCard from '../../../UIComponents/SkeletonCard';
import { CurriculumContext } from 'contexts/CurriculumContext';

export default function FavoritesTab({
  activeInternalTab,
  setFilterOptions,
  queryFilter,
  learningCountControl,
  scrollUp,
}) {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  };
  const limit = 25;

  const { getFavoritesLearnings } = useAPICallsLearning();

  const {
    profile,
    updateBehavioralState,
    updatePersonalityState,
    updateProfileState,
    updateAvatarState,
    updateInterestState,
    isOpenCurriculumModal,
    closeCurriculumModal,
    handleShowSpecialNeeds,
  } = useContext(CurriculumContext);

  const {
    favoritesLearning,
    setFavoritesLearning,
    favoritesCount,
    currentPageFavorites,
    setLoadingFilters,
    loading,
  } = useContext(LearningContext);

  useEffect(() => {
    if (activeInternalTab === 2) {
      setFavoritesLearning(null);
      getFavoritesLearnings(true);
    }
  }, [activeInternalTab]);

  useEffect(() => {
    if (activeInternalTab === 2) {
      setLoadingFilters(true);
      setFavoritesLearning(null);

      getLearningFilters('is_recommendation=true').then((data) => {
        setFilterOptions(data);
        setLoadingFilters(false);
      });
    }
  }, [activeInternalTab]);

  const { sentinelRef } = useIntersectionPagination(
    options,
    favoritesLearning,
    favoritesCount,
    getFavoritesLearnings,
    currentPageFavorites,
    limit,
    queryFilter
  );

  return (
    <Fragment>
      <ListArea>
        <LearningCard
          array={favoritesLearning}
          isFavorite
          listingType="Favorite"
          learningCountControl={learningCountControl}
          scrollUp={scrollUp}
        />
        {loading ? <SkeletonCard quantity={3} /> : null}
      </ListArea>
      {isOpenCurriculumModal &&
        mergeContextsHelper(
          <CurriculumModal
            personalityTestIsDone={profile?.quizzes?.personality.is_done}
            behavioralTestIsDone={profile?.quizzes?.behavioral.is_done}
            lifeInterestList={profile?.life_interests}
            interestList={profile?.interests}
            studentProfileState={profileAdapter(profile?.academic_profile)}
            updateBehavioralState={updateBehavioralState}
            updatePersonalityState={updatePersonalityState}
            updateProfileState={updateProfileState}
            updateInterestState={updateInterestState}
            setClose={closeCurriculumModal}
            isOpen={isOpenCurriculumModal}
            updateAvatarState={updateAvatarState}
            handleShowSpecialNeeds={handleShowSpecialNeeds}
          />
        )}
      <Sentinel ref={sentinelRef} />
    </Fragment>
  );
}
