import React, { useEffect, useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import { shape, func, bool, number } from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import * as S from './styled';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const HtmlEditor = ({
  toolbarOptions,
  changeValue,
  loadValue,
  errors,
  getCharCount,
  readOnly,
  touched,
  isEdit,
  isJobDescription,
  isDecrement,
  limitCharCount,
}) => {
  const [editorState, setEditorState] = useState();
  const [hasDescription, setHasDescription] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [decCharCount, setDecCharCount] = useState(limitCharCount);
  const [errorActive, setErrorActive] = useState('editorStyled');
  const onEditorStateChange = (editorStates) => {
    setEditorState(editorStates);
  };
  function loadHtml(html) {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editState = EditorState.createWithContent(contentState);
      setEditorState(editState);
    }
  }

  useEffect(() => {
    if (!isJobDescription) {
      if (loadValue) {
        loadHtml(loadValue);
      }
    }
  }, []);

  useEffect(() => {
    if (isJobDescription) {
      if (loadValue && !hasDescription && isEdit) {
        loadHtml(loadValue);
        setHasDescription(true);
      }
    }
  }, [loadValue]);

  useEffect(() => {
    if (editorState) {
      const regexReplaceHtml = /(<([^>]+)>)/gi;

      const htmlParsed = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );
      const plainText = htmlParsed
        .replace(regexReplaceHtml, '')
        .replaceAll('&nbsp;', ' ');

      const countChar = plainText.length
        ? plainText.length - 1
        : plainText.length;
      if (getCharCount) {
        getCharCount(countChar);
      }
      if (changeValue) {
        if (countChar) changeValue(htmlParsed);
        else changeValue('');
      }

      setCharCount(countChar);
      if (isDecrement) {
        setDecCharCount(limitCharCount - countChar);
      }
    }
  }, [editorState]);

  const charCountPhraseFooter = isDecrement
    ? `${new Intl.NumberFormat('pt-BR').format(decCharCount)} Caracteres`
    : `Quantidade de Caracteres: ${charCount}`;

  return (
    <S.HtmlEditorStyled readOnly={readOnly}>
      <Editor
        toolbarHidden={readOnly}
        readOnly={readOnly}
        editorState={editorState}
        toolbar={{
          ...toolbarOptions,
          options: ['inline', 'fontFamily', 'fontSize', 'link'],
          inline: { options: ['bold', 'italic', 'underline'] },
          fontSize: {
            options: [12, 14, 16, 18, 24],
          },
        }}
        stripPastedStyles
        editorClassName={errorActive}
        toolbarClassName="toolBarStyled"
        onEditorStateChange={onEditorStateChange}
        onBlur={() => {
          if (errors) {
            setErrorActive('editorStyled editorError');
          } else {
            setErrorActive('editorStyled');
          }
        }}
        onFocus={() => {
          if (errors) {
            setErrorActive('editorStyled editorError');
          } else {
            setErrorActive('editorStyled');
          }
        }}
      />
      <p className="charCountStyled">{charCountPhraseFooter}</p>
      {errors && touched ? <p className="error">{errors}</p> : null}
    </S.HtmlEditorStyled>
  );
};

HtmlEditor.defaultProps = {
  isJobDescription: false,
  isEdit: false,
  isDecrement: false,
  limitCharCount: undefined,
};

HtmlEditor.propTypes = {
  toolbarOptions: shape.isRequired,
  changeValue: func.isRequired,
  loadValue: shape.isRequired,
  errors: shape.isRequired,
  getCharCount: func.isRequired,
  readOnly: bool.isRequired,
  isEdit: bool,
  isJobDescription: bool,
  touched: shape.isRequired,
  isDecrement: bool,
  limitCharCount: number,
};

export default HtmlEditor;
