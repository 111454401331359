import React from 'react';
import Wall from 'assets/JobOffer/IconPerson.svg';
import {
  Container,
  Box,
  Content,
  WrapperTitle,
  BubbleDialog,
  WrapperMessage,
} from 'components/modals/styles/BaseModal';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

export default function ModalIconPerson({
  className,
  children,
  isOpen,
  bubbleText = '',
  widthBubble = '126px',
  heightBubble = '42px',
  bubbleClass = '',
  alignWrapper = '',
  closeModalFunc,
  ...rest
}) {
  return (
    <Dialog
      tabIndex={1}
      open={true}
      PaperProps={{
        tabIndex: 1,
        fullScreen: true,
      }}
    >
      <Container isOpen={isOpen} className={className}>
        <Box>
          <Content>
            <button
              className="closeButton"
              onClick={() => {
                closeModalFunc();
              }}
            >
              <CloseIcon />
            </button>
            <WrapperTitle alignWrapper={alignWrapper}>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog
                widthBubble={widthBubble}
                heightBubble={heightBubble}
                className={bubbleClass}
              >
                <span> {bubbleText} </span>
              </BubbleDialog>
            </WrapperTitle>
            <WrapperMessage>{children}</WrapperMessage>
          </Content>
        </Box>
      </Container>
    </Dialog>
  );
}

ModalIconPerson.defaultProps = {
  className: '',
  isOpen: false,
  bubbleText: '',
  widthBubble: '126px',
  heightBubble: '42px',
  bubbleClass: '',
  alignWrapper: '',
  closeModalFunc: () => {},
};
