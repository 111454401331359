import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WhiteBox = styled.div`
  width: ${(props) => (props.form ? '50em' : '992px')};
  min-height: ${(props) => (props.form ? '500px' : '200px')};
  background-color: #fff;
  margin-top: 20px;
  border-radius: 12px;
  position: relative;
`;
