export function IconLocation({ color }) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.97316 17.8632L7.46045 17.4237C6.75364 16.8319 0.582031 11.5018 0.582031 7.60304C0.582031 3.52104 3.89116 0.211914 7.97316 0.211914C12.0552 0.211914 15.3643 3.52104 15.3643 7.60304C15.3643 11.5019 9.19267 16.8319 8.48879 17.4266L7.97316 17.8632ZM7.97316 1.81009C4.77532 1.81371 2.18386 4.40517 2.18024 7.60301C2.18024 10.0523 5.97725 13.965 7.97316 15.7559C9.96909 13.9643 13.7661 10.0493 13.7661 7.60301C13.7624 4.40517 11.171 1.81375 7.97316 1.81009Z"
        fill={color}
      />
      <path
        d="M7.97372 10.5329C6.35565 10.5329 5.04395 9.22119 5.04395 7.60312C5.04395 5.98505 6.35565 4.67334 7.97372 4.67334C9.59179 4.67334 10.9035 5.98505 10.9035 7.60312C10.9035 9.22119 9.59183 10.5329 7.97372 10.5329ZM7.97372 6.13819C7.16469 6.13819 6.50883 6.79405 6.50883 7.60308C6.50883 8.41212 7.16469 9.06797 7.97372 9.06797C8.78276 9.06797 9.43861 8.41212 9.43861 7.60308C9.43861 6.79405 8.78279 6.13819 7.97372 6.13819Z"
        fill={color}
      />
    </svg>
  );
}
