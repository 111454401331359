export default function UserIconDefault({ color, size }) {
  return (
    <svg
      width={size}
      height={size + 2}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8298 15.088C12.785 15.1328 12.7402 15.1686 12.6775 15.1955C12.6685 15.2044 12.6595 15.2044 12.6506 15.2134C12.6327 15.2224 12.6148 15.2313 12.5879 15.2403C12.5431 15.2582 12.4983 15.2672 12.4535 15.2761C12.4445 15.2761 12.4266 15.2761 12.4176 15.2851C12.3908 15.2851 12.3639 15.294 12.346 15.294C12.337 15.294 12.3191 15.294 12.3101 15.294C10.366 15.294 8.42178 15.294 6.48656 15.294H0.761544C0.519642 15.294 0.340452 15.2492 0.215021 15.1507C0.197102 15.1417 0.188146 15.1328 0.179187 15.1149C0.143349 15.079 0.107509 15.0342 0.0806308 14.9805C0.0627121 14.9536 0.053756 14.9177 0.0447967 14.8819C0.00895934 14.7834 0 14.6579 0 14.5056C0.026878 12.3285 1.22743 10.3126 3.01034 9.16585C3.09097 9.11209 3.16264 9.0673 3.24328 9.0225C3.40454 8.93291 3.56581 8.84331 3.73604 8.76268C4.23776 8.52078 4.77533 8.35055 5.33081 8.26096C6.66575 8.03697 7.95589 8.2072 9.09373 8.691C9.26396 8.76268 9.42523 8.84331 9.58649 8.93291C9.90903 9.10313 10.2136 9.30024 10.5003 9.52422C10.7154 9.69445 10.9214 9.87364 11.1096 10.0707C11.9786 10.9488 12.6058 12.0956 12.8746 13.4215C12.9552 13.8068 12.9821 14.21 13 14.6042C13 14.7923 12.9462 14.9625 12.8298 15.088Z"
        fill={color}
      />
      <path
        d="M10.0524 3.64645C10.0345 4.92764 9.34461 6.0386 8.31429 6.64783C8.24261 6.69263 8.16198 6.72846 8.0903 6.77326C8.03655 6.80014 7.98279 6.82702 7.93799 6.84493C7.88424 6.87181 7.83048 6.88973 7.77673 6.90765C7.72297 6.92557 7.66922 6.95245 7.61546 6.97037C7.53483 6.99725 7.44523 7.02412 7.36459 7.04204C7.05998 7.11372 6.7464 7.15851 6.42386 7.14955C6.30739 7.14955 6.19092 7.14059 6.07445 7.12267C6.0207 7.11372 5.95798 7.10476 5.90422 7.0958C5.81463 7.07788 5.734 7.05996 5.65336 7.04204C5.57273 7.02412 5.48314 6.99725 5.4025 6.97037C5.34875 6.95245 5.29499 6.93453 5.24124 6.91661C5.18748 6.89869 5.13372 6.87181 5.07996 6.85389C5.05309 6.84494 5.02621 6.82702 4.99933 6.81806C4.9187 6.78222 4.84702 6.74638 4.77535 6.70159C4.70367 6.65679 4.632 6.61199 4.56032 6.5672C4.48865 6.5224 4.41697 6.46864 4.35426 6.42385C4.30946 6.38801 4.26467 6.35217 4.21987 6.31633C4.17507 6.2805 4.13027 6.24466 4.09444 6.20882C4.04964 6.17299 4.0138 6.12819 3.96901 6.09235C3.32394 5.43832 2.92077 4.54238 2.93868 3.57478C2.94764 3.13577 3.02828 2.72364 3.17162 2.33839C3.18954 2.28463 3.21642 2.23088 3.23434 2.17712C3.39561 1.80083 3.61063 1.46037 3.87941 1.16471C3.91525 1.11992 3.96005 1.08408 3.99588 1.03928C4.00484 1.03032 4.0138 1.02137 4.02276 1.01241C4.04068 0.994488 4.06756 0.967609 4.08548 0.949691C4.3005 0.743626 4.55136 0.573399 4.81118 0.43005C4.86494 0.403172 4.90974 0.376292 4.96349 0.349414C5.09788 0.286698 5.23227 0.232943 5.36666 0.188147C5.43834 0.161269 5.51898 0.143351 5.59961 0.116473C5.61753 0.116473 5.62649 0.107513 5.64441 0.107513C5.94006 0.0358385 6.25364 0 6.57617 0C6.81808 0 7.05102 0.0358368 7.28396 0.0806335C7.54378 0.13439 7.78569 0.223985 8.01863 0.331497C8.04551 0.340456 8.07238 0.358374 8.09926 0.367333C8.15302 0.394211 8.19782 0.421089 8.25157 0.447967C8.35012 0.501723 8.44868 0.564438 8.53827 0.627154C8.56515 0.645072 8.58307 0.662992 8.60995 0.68091C8.74434 0.779463 8.87872 0.895936 8.9952 1.01241C9.64923 1.68436 10.0614 2.61613 10.0524 3.64645Z"
        fill={color}
      />
    </svg>
  );
}
