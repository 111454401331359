import styled, { keyframes } from 'styled-components';

const shimmerAnimation = keyframes`
  100% { background-position: -100% 0}
`;

export const BaseAnimation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  background-color: #e7e7e7;
  background-image: linear-gradient(
    120deg,
    #e7e7e7 30%,
    #f0f0f0 38%,
    #f0f0f0 40%,
    #e7e7e7 48%
  );
  background-repeat: no-repeat;
  background-size: 200% 100%;
  background-position: 100% 0;
  animation: ${shimmerAnimation} 1.2s linear infinite;
  height: 38px;
  border-radius: 5px;
  width: 100%;
  margin: 16px 0px;
`;
