import styled from 'styled-components';
import Button from 'components/CustomButtons/Button.jsx';

export const Container = styled.div``;

export const Card = styled.div`
  min-height: 284px;
  width: 262px;
  border-radius: 12px;
  border: 0.8px solid #eaeaea;

  .flex {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }

  .cardBody {
    padding: 28px 22px 12px 22px;
    min-height: 234px;
    display: flex;
    flex-direction: column;
  }
  .title {
    display: -webkit-box;
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #4b4b4d;
    max-height: 40px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .info {
    font-size: 11px;
    font-family: 'Roboto', sans-serif;
    color: #949494;
    margin-bottom: 8px;
    line-height: 12px;
    font-family: Roboto;
    font-weight: 400;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #009291;
  height: 44px;
  display: flex;
  border-radius: 12px 12px 0px 0px;
  p {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  &.curriculumSent {
    background: #e5e5e5 !important;
    p {
      color: #606062 !important;
    }
    svg {
      fill: #606062 !important;
    }
  }

  &.jobExpired {
    background: #ababab !important;
    p {
      color: #ffffff !important;
    }
    svg {
      fill: #ffffff !important;
    }
  }

  &.jobFinished {
    background: #ababab !important;
    p {
      color: #ffffff !important;
    }
    svg {
      fill: #ffffff !important;
    }
  }
  &.unscheduled {
    background: #f8f0e7 !important;
    p {
      color: #f09d51 !important;
    }
    svg {
      fill: #f09d51 !important;
    }
  }
  &.scheduled {
    background: #d0f3e2 !important;
    p {
      color: #1cab66 !important;
    }
    svg {
      fill: #1cab66 !important;
    }
  }
  &.rescheduled {
    background: #f8f2e7 !important;
    p {
      color: #e7b855 !important;
    }
    svg {
      fill: #e7b855 !important;
    }
  }
  &.canceled {
    background: #ffeded !important;
    p {
      color: #c11717 !important;
    }
    svg {
      fill: #c11717 !important;
    }
  }
  &.liked {
    background: #e3ebf7 !important;
    p {
      color: #558fe7 !important;
    }
    svg {
      fill: #558fe7 !important;
    }
  }
  &.notLiked {
    background: #f8eef3 !important;
    p {
      color: #c11769 !important;
    }
    svg {
      fill: #c11769 !important;
    }
  }

  &.studentContracted {
    background: #009291 !important;
    p {
      color: #fff !important;
    }
    svg {
      fill: #fff !important;
    }
  }
`;

export const VisualIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 0.5rem;

  .icon {
    width: 22px;
    height: 22px;
    padding: 4px;
    border-radius: 3px;
    background: #e0ffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon img {
    width: 20px;
  }

  p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 11.5px;
    color: #606062;
    margin: 0 !important;
    line-height: 0.89rem;
  }
`;

export const SubmitButton = styled(Button)`
  height: 32px;
  margin-top: auto !important;
  margin-bottom: 12px;
  width: 100%;
`;

export const DateLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.92px;
  color: #949494;
  padding-top: 10px;
`;
