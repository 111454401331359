import * as Yup from 'yup';

export const schemaStepThree = Yup.object().shape({
  interests: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher ao menos 1 interesse.')
    .max(5, 'Você deve escolher ao menos 5 interesses.'),
  values: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher ao menos um valor.')
    .max(3, 'Você deve escolher ao menos 3 valores.'),
  macro_areas: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher ao menos 1 área.')
    .max(4, 'Você deve escolher ao menos 4 áreas.'),
  use_integration_agent: Yup.string().required(
    'Você deve informar se utiliza algum Agente Integrador para vagas de estágio.'
  ),
  macro_areas: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher ao menos 1 área.')
    .max(4, 'Você deve escolher ao menos 4 áreas.'),
  skills: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher ao menos 1 habilidade.')
    .max(4, 'Você deve escolher ao menos 4 habilidades.'),
});

export const schemaStepTwo = Yup.object().shape({
  legal_name: Yup.string().required(
    'Você deve informar a razão social da empresa.'
  ),
  activities_sectors: Yup.array()
    .of(Yup.string())
    .min(1, 'Você deve escolher um setor.'),
  size: Yup.string().required('Você deve informar o tamanho da empresa.'),
  type: Yup.string().required('Você deve informar o tipo da sua empresa.'),
  description: Yup.string().required('Informe uma descrição para sua empresa.'),
  website: Yup.string().url('Informe um site válido.'),
});
