import { Container, DisciplinesList, SearchInput } from './style';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAcademicModeling } from '../../../../contexts/AcademicModelingContext';
import AcademicModelingApi from 'services/university/academicModeling';
import { FinishedDisciplineCard } from '../FinishedDisciplineCard';
import { FinishedDisciplineCardSkeleton } from '../FinishedDisciplineCardSkeleton';
import { SeeMoreButton } from '../../../../style';

export const FinishedTab = ({ setSelectedTab, setSelectedDiscipline }) => {
  const [searchValue, setSearchValue] = useState('');
  const [finishedDisciplines, setFinishedDisciplines] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { selectedCourse } = useAcademicModeling();

  async function handleSearchValue(e) {
    setFinishedDisciplines([]);
    setPage(1);
    setLoading(true);
    const value = e.target.value;
    setSearchValue(value);
    const response = await AcademicModelingApi.getFinishedDisciplines(
      selectedCourse.id,
      1,
      value
    );
    if (response.status === 200) {
      setFinishedDisciplines(response.data.results);
      setHasNextPage(!!response.data.next);
      setLoading(false);
    }
  }

  useEffect(() => {
    async function fetchFinishedDisciplines() {
      const response = await AcademicModelingApi.getFinishedDisciplines(
        selectedCourse.id,
        1
      );
      if (response.status === 200) {
        setFinishedDisciplines([
          ...finishedDisciplines,
          ...response.data.results,
        ]);
        setHasNextPage(response.data.next);
      }
      setLoading(false);
    }

    fetchFinishedDisciplines();
  }, []);

  const handleNextPage = useCallback(async () => {
    setLoading(true);
    const response = await AcademicModelingApi.getFinishedDisciplines(
      selectedCourse.id,
      page + 1,
      searchValue
    );
    if (response.status === 200) {
      setFinishedDisciplines([
        ...finishedDisciplines,
        ...response.data.results,
      ]);
      setHasNextPage(!!response.data.next);
      setPage(page + 1);
    }
    setLoading(false);
  }, [finishedDisciplines, page, searchValue, selectedCourse.id]);

  const randomSkeletonQuantity = useMemo(() => {
    return Math.floor(Math.random() * (20 - 3 + 1)) + 3;
  }, []);

  return (
    <Container>
      <header>
        <span>
          Disciplinas já mapeadas com as tags de conteúdo, caso necessário
          acesse a disciplina para editar as informações inseridas.
        </span>
        <SearchInput
          type="text"
          id="tag-name"
          placeholder="Digite aqui..."
          value={searchValue}
          onChange={handleSearchValue}
          width={'286px'}
        />
      </header>
      <DisciplinesList>
        {finishedDisciplines.map((discipline) => (
          <FinishedDisciplineCard
            discipline={discipline}
            setSelectedTab={setSelectedTab}
            setSelectedDiscipline={setSelectedDiscipline}
          />
        ))}
        {loading &&
          Array.from({ length: randomSkeletonQuantity }).map((_, index) => (
            <FinishedDisciplineCardSkeleton key={index} />
          ))}
      </DisciplinesList>
      {hasNextPage && !loading && (
        <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
      )}
    </Container>
  );
};
