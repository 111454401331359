export function StudentTrophy({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        verticalAlign: 'middle',
      }}
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#FEE489" />
      <path
        d="M13.8031 14.331C17.6362 14.3117 19 12.2817 19 10.5411C19 9.56283 18.307 8.74442 17.3871 8.54667C17.4664 8.3215 17.5353 8.11442 17.5813 7.9575C17.7873 7.26158 17.6543 6.52658 17.2162 5.94033C16.7693 5.343 16.0863 5 15.3413 5H8.65808C7.91317 5 7.2295 5.343 6.78267 5.94033C6.34458 6.52658 6.21158 7.26217 6.4175 7.9575C6.46417 8.11442 6.53242 8.3215 6.61233 8.54725C5.69242 8.745 5 9.56342 5 10.5417C5 12.2823 6.36442 14.3123 10.1969 14.3316C10.2296 14.5078 10.25 14.6874 10.25 14.8706V16.6667C10.25 17.7318 9.354 17.8287 9.08333 17.8333H8.5C8.17742 17.8333 7.91667 18.0941 7.91667 18.4167C7.91667 18.7393 8.17742 19 8.5 19H15.5C15.8226 19 16.0833 18.7393 16.0833 18.4167C16.0833 18.0941 15.8226 17.8333 15.5 17.8333H14.9213C14.646 17.8287 13.75 17.7318 13.75 16.6667V14.87C13.75 14.6874 13.7704 14.5078 13.8031 14.3316V14.331ZM16.9303 9.66025C16.9402 9.66025 16.9484 9.66608 16.9583 9.66608C17.4408 9.66608 17.8333 10.0587 17.8333 10.5411C17.8333 11.7276 16.8947 12.9893 14.3123 13.1486C14.4412 12.9742 14.5888 12.8126 14.7568 12.6697C15.7712 11.8075 16.4677 10.6554 16.9303 9.66025ZM6.16667 10.5411C6.16667 10.0587 6.55925 9.66608 7.04167 9.66608C7.05158 9.66608 7.05975 9.66083 7.06908 9.66025C7.53225 10.6554 8.22875 11.8075 9.24317 12.6697C9.41117 12.8126 9.55875 12.9736 9.68767 13.1486C7.10525 12.9893 6.16667 11.7276 6.16667 10.5411ZM10.712 10.6945C10.558 10.5883 10.4932 10.3923 10.5545 10.215L10.8573 9.27817L10.0604 8.69483C9.86967 8.55483 9.82825 8.28708 9.96825 8.09633C10.0487 7.98667 10.1771 7.92133 10.313 7.92133H11.2936L11.5917 6.99208C11.6669 6.76633 11.9108 6.645 12.1365 6.72025C12.2648 6.76283 12.3657 6.86375 12.4083 6.99208L12.7058 7.92133H13.6864C13.9227 7.92133 14.1146 8.11267 14.1146 8.3495C14.1146 8.48658 14.0493 8.61492 13.939 8.69542L13.1422 9.27875L13.4449 10.2156C13.5173 10.4408 13.3936 10.6822 13.1678 10.7552C13.0372 10.7972 12.8943 10.7738 12.7834 10.6927L12 10.1164L11.216 10.6922C11.0667 10.8036 10.8625 10.8042 10.712 10.6945Z"
        fill="url(#paint0_linear_25561_68375)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_25561_68375"
          x1="5.93333"
          y1="5"
          x2="16.2"
          y2="19"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DA" />
          <stop offset="0.53125" stop-color="#FBD12E" />
          <stop offset="1" stop-color="#F47929" />
        </linearGradient>
      </defs>
    </svg>
  );
}
