import styled from 'styled-components';
import error from 'assets/Matriz/error.svg';

export const ChipButton = styled.button`
  border: none;
  background: #caeaea;
  height: auto;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 4px;

  p {
    min-height: 22px;
    max-width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    color: #777777;
    font-size: 12px;
    padding: 5px 5px;
    margin: 0;
    line-height: 15px;
  }
`;

export const InputLabel = styled.label`
  color: #6d6d6d;
  font-weight: bold;
  font-size: 13px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin: 5px 0;

  > span {
    color: #949494;
    font-size: 10px;
    margin-left: 5px;
  }
`;
export const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem;
`;

export const Error = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 5px 0px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
  }
`;

export const Button = styled.span`
  background-color: transparent;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #009291;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  margin-top: 10px;
`;
