export default function RocketIcon({ size = 23 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_20108_22058)">
        <path
          d="M17.1359 8.76922C17.1359 9.22247 17.0015 9.66554 16.7497 10.0424C16.4979 10.4193 16.14 10.713 15.7212 10.8864C15.3025 11.0599 14.8417 11.1053 14.3972 11.0169C13.9526 10.9284 13.5443 10.7102 13.2238 10.3897C12.9033 10.0692 12.685 9.66085 12.5966 9.21631C12.5082 8.77177 12.5536 8.31099 12.727 7.89224C12.9005 7.47349 13.1942 7.11558 13.5711 6.86377C13.9479 6.61196 14.391 6.47756 14.8442 6.47756C15.452 6.47756 16.0349 6.719 16.4647 7.14877C16.8945 7.57854 17.1359 8.16144 17.1359 8.76922ZM15.9067 17.2145C15.7275 17.9187 15.4731 18.6016 15.1477 19.2513C14.5468 20.3876 13.6446 21.3364 12.54 21.9937C11.4355 22.651 10.1712 22.9914 8.88591 22.9776H7.96924V18.5253C7.95265 17.7664 7.64378 17.0432 7.10703 16.5064C6.57028 15.9697 5.84706 15.6608 5.08816 15.6442H0.635906V14.7276C0.622029 13.4423 0.962417 12.178 1.61973 11.0734C2.27704 9.96885 3.22588 9.06665 4.36216 8.46581C5.01188 8.1404 5.69476 7.88593 6.39899 7.70681C9.76591 3.15922 13.6718 1.14072 19.3891 0.977557H19.4276C19.8532 0.976147 20.2749 1.05973 20.6679 1.2234C21.0608 1.38707 21.4171 1.62753 21.716 1.9307C22.0148 2.23387 22.2501 2.59364 22.408 2.98892C22.566 3.38419 22.6435 3.80703 22.6359 4.23264C22.4709 10.0315 20.5147 13.8109 15.9067 17.2145ZM19.8859 4.19322C19.8879 4.07275 19.8423 3.95634 19.7591 3.86919C19.6759 3.78204 19.5618 3.73116 19.4413 3.72756C14.4354 3.87606 11.3389 5.52972 8.40649 9.62264C7.64618 10.7113 6.97015 11.8565 6.38432 13.0482C7.39386 13.3025 8.31567 13.8255 9.05182 14.5616C9.78797 15.2978 10.311 16.2196 10.5652 17.2291C11.757 16.6435 12.9023 15.9675 13.9908 15.207C18.0911 12.2681 19.7447 9.18081 19.8859 4.19322ZM2.13099 18.1449C1.42149 18.8526 0.984239 20.9893 0.829323 21.8776L0.635906 22.9776L1.73591 22.7832C2.61682 22.6274 4.74074 22.1883 5.45024 21.477C5.88994 21.034 6.13669 20.4351 6.13669 19.8109C6.13669 19.1868 5.88994 18.5879 5.45024 18.1449C5.00409 17.7162 4.40936 17.4767 3.79061 17.4767C3.17187 17.4767 2.57713 17.7162 2.13099 18.1449Z"
          fill="url(#paint0_linear_20108_22058)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_20108_22058"
          x1="11.636"
          y1="0.977539"
          x2="11.636"
          y2="22.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FBE6AF" />
          <stop offset="0.145833" stop-color="#FBD12E" />
          <stop offset="0.875" stop-color="#F47929" />
        </linearGradient>
        <clipPath id="clip0_20108_22058">
          <rect
            width="22"
            height="22"
            fill="none"
            transform="translate(0.63623 0.977539)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
