import React from 'react';

import { ContainerInformations } from './styled';

export default function CardContainerInformation({
  children,
  isFinished = false,
}) {
  return (
    <ContainerInformations isFinished={isFinished}>
      {children}
    </ContainerInformations>
  );
}
