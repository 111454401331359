import React, { useState, Fragment } from "react";
import { object, array, func, bool } from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  TextField
} from "@material-ui/core";
import classNames from "classnames";

import { FastField } from "formik";
import * as Yup from "yup";

import professionalExperienceFormStyles from "assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles";

const ReasonsForm = props => {
  const [showOthersField, setShowOtherField] = useState(false);
  const {
    classes,
    options,
    values,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    ReasonsFormSchema
  } = props;

  const handleCheckBox = (
    selectedReasons,
    event,
    setFieldValue,
    setFieldTouched
  ) => {
    if (event.target.checked) {
      setFieldValue("selectedReasons", [
        ...selectedReasons,
        JSON.parse(event.target.value)
      ]);
    } else {
      let filteredJobsType = selectedReasons.filter(job => {
        return (
          job.label.toLocaleLowerCase() !=
          JSON.parse(event.target.value).label.toLocaleLowerCase()
        );
      });
      setFieldValue("selectedReasons", filteredJobsType);
    }
    setFieldTouched("selectedReasons");
  };

  const handleOthers = () => {
    if (!showOthersField) {
      ReasonsFormSchema["fields"].selectedReasons = Yup.array().of(
        Yup.object().shape({
          label: Yup.string(),
          value: Yup.string()
        })
      );

      ReasonsFormSchema["fields"].otherReason = Yup.string()
        .max(150, "Máximo de 150 caracteres.")
        .required("Este campo não pode ser vazio.");
    } else {
      ReasonsFormSchema["fields"].selectedReasons = Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string()
          })
        )
        .min(
          1,
          "Você deve selecionar ao menos um motivo para não aceitar a candidatura."
        );

      ReasonsFormSchema["fields"].otherReason = Yup.string();
    }

    setShowOtherField(!showOthersField);
  };

  return (
    <Fragment>
      <FormControl
        component="fieldset"
        className={(classes.formControl, classes.fullWidth)}
      >
        <FormGroup className={classes.group}>
          {options.map((reason, index) => (
            <FormControlLabel
              key={index}
              className={classes.optionsLabelFluid}
              classes={{
                root: classes.radioLabelControl,
                label: classes.radioLabel
              }}
              label={reason.label}
              labelPlacement="end"
              color="default"
              tabIndex={-1}
              control={
                <Checkbox
                  classes={{
                    checked: classes.checkBox
                  }}
                  disabled={isSubmitting}
                  checked={
                    values.selectedReasons
                      ? values.selectedReasons
                          .flatMap(el => el.label.toLocaleLowerCase())
                          .indexOf(reason.label.toLocaleLowerCase()) !== -1
                      : false
                  }
                  onClick={event =>
                    handleCheckBox(
                      values.selectedReasons,
                      event,
                      setFieldValue,
                      setFieldTouched
                    )
                  }
                  value={JSON.stringify(reason)}
                />
              }
            />
          ))}
          <FormControlLabel
            className={classes.optionsLabelFluid}
            classes={{
              root: classes.radioLabelControl,
              label: classes.radioLabel
            }}
            label="Outros"
            labelPlacement="end"
            color="default"
            tabIndex={-1}
            control={
              <Checkbox
                classes={{
                  checked: classes.checkBox
                }}
                disabled={isSubmitting}
                onClick={handleOthers}
              />
            }
          />
          {showOthersField && (
            <Fragment>
              <FastField
                autoFocus
                className={classes.inputFieldApplicationReason}
                InputProps={{
                  className: classes.inputProps
                }}
                InputLabelProps={{
                  FormLabelClasses: {
                    focused: classes.inputFieldApplicationReason
                  },
                  shrink: values.profession
                }}
                name="otherReason"
                id="otherReason"
                label="Outros"
                placeholder="Até 150 caracteres"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors["otherReason"] && touched["otherReason"]}
                value={values.otherReason}
                defaultValue={values.otherReason}
                component={TextField}
                multiline
                disabled={isSubmitting}
              />
              <small
                className={classNames({
                  [classes.textAlgR]: true,
                  [classes.error]:
                    values.otherReason && values.otherReason.length > 150
                })}
              >
                {values.otherReason ? values.otherReason.length : "0"}
                /150
              </small>
              {errors.otherReason && touched.otherReason ? (
                <FormHelperText className={classes.helperText}>
                  {errors.otherReason}
                </FormHelperText>
              ) : null}
            </Fragment>
          )}
        </FormGroup>
      </FormControl>
      {!!errors["selectedReasons"] &&
        touched["selectedReasons"] && (
          <FormHelperText className={classes.helperText}>
            {errors.selectedReasons}
          </FormHelperText>
        )}
    </Fragment>
  );
};

ReasonsForm.propTypes = {
  classes: object,
  options: array,
  errors: object,
  handleBlur: func,
  handleChange: func,
  touched: object,
  setFieldTouched: func,
  setFieldValue: func,
  values: object,
  isSubmitting: bool,
  ReasonsFormSchema: object
};

export default withStyles(professionalExperienceFormStyles)(ReasonsForm);
