import { useState, useEffect } from 'react';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import backgroundDashboard from 'assets/DashBoard/background.png';
import { DashboardView } from './Views/DashboardView';
import CustomReportsView from './Views/CustomReportsView';
import { DashboardProvider } from './Context';
import { Content } from './Styles/BaseComponent';

export function DashboardEngine(props) {
  const { handleIsLoadingState } = props;
  const [activeExternalTab, setActiveExternalTab] = useState(0);

  const Tabs = [
    {
      activeTab: 0,
      component: <DashboardView {...props}/>,
    },
    {
      activeTab: 1,
      component: <CustomReportsView {...props} />,
    },
  ];

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  const handleTabChange = (event, value) => {
    setActiveExternalTab(value);
  };

  return (
    <DashboardProvider>
      <CompanyHeader
        title="Dashboard"
        activeTab={activeExternalTab}
        handleTabChange={handleTabChange}
        backgroundImage={backgroundDashboard}
        tabs={['Dashboard', 'Relatórios personalizados']}
      />
      <Content> {renderPrincipalTabs(activeExternalTab)}</Content>
    </DashboardProvider>
  );
}
