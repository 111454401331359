import styled from 'styled-components';

export const FilterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  > div {
    display: flex;
    margin-left: 0;
  }
`;

export const ContainerBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  ${(props) => (props.margin ? `margin-top: 40px` : '')}
`;
