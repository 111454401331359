import React from 'react';
import { Container, ContainerTitle } from './style';
import ReactHtmlParser from 'react-html-parser';

export default function Card({ children, title, subTitle, height }) {
  const parsedSubTitle = () => {
    return ReactHtmlParser(subTitle.replace(/style="[^"]*"/g, ''));
  };

  return (
    <Container height={height}>
      <ContainerTitle>
        <h4>{title}</h4>
        <p>{subTitle && parsedSubTitle(subTitle)}</p>
      </ContainerTitle>

      {children}
    </Container>
  );
}
