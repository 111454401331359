import React from 'react';

import { Container, Card, WrapperCards } from './styled';

import registerIcon from 'assets/FairAndEvents/registerIcon.svg';
import { useRegisterModalContext } from '../../Contexts/RegisterModalContext';
import { useVisualizationContext } from '../../Contexts/VisualizationContext';

import Location from 'assets/FairAndEvents/Location.svg';

export default function HomeCards({ handleClickSeeFairAndEvents }) {
  const { setModalOpen } = useRegisterModalContext();

  const { setCurrentTab, onGoing } = useVisualizationContext();

  const handleClickCheckIn = () => {
    setCurrentTab(1);
    handleClickSeeFairAndEvents();
  };

  const CardsObject = [
    {
      title: 'Cadastrar feiras e eventos',
      subTitle: 'Cadastre feiras e eventos para seus estudantes participarem.',
      icon: registerIcon,
      action: () => setModalOpen(true),
    },
    ...(onGoing?.value?.results?.length > 0
      ? [
          {
            title: 'Realizar check-in dos estudantes',
            subTitle:
              'Realize o check-in dos estudantes presentes no dia do evento.',
            icon: Location,
            action: () => handleClickCheckIn(),
          },
        ]
      : []),
  ];

  const createCards = () => {
    return CardsObject.map((item) => (
      <Card onClick={item.action}>
        <img src={item.icon} alt="icon" />
        <h4>{item.title}</h4>
        <p>{item.subTitle}</p>
      </Card>
    ));
  };

  return (
    <Container>
      <h3>O que você deseja fazer?</h3>

      <WrapperCards>{createCards()}</WrapperCards>

      <span onClick={handleClickSeeFairAndEvents}>
        Visualizar feiras e eventos publicados
      </span>
    </Container>
  );
}
