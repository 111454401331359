import React, { Fragment } from 'react';

import { ModalX } from 'components/Challenges/InformationModal/styled';

import {
  ModalBackground,
  DivModal,
  HeaderModal,
  Title,
  Divider,
  DividerTitle,
  DivCourses,
  TitleCourses,
  Courses,
} from './styles';

export default function ModalTargetAudience({
  item,
  setIsOpenModalTargetAudience,
}) {
  const setState = () => {
    setIsOpenModalTargetAudience(false);
  };

  const returnCourses = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].courses &&
        item.target_audiences[0].courses.length
      ) {
        const result = item.target_audiences[0].courses.map(
          (item) => item.label
        );
        return result.join(' | ');
      }
    }
    return 'Todos os cursos';
  };

  return (
    <Fragment>
      <ModalBackground>
        <DivModal>
          <HeaderModal>
            <DividerTitle>
              <Title>Público-Alvo</Title>
              <Divider />
            </DividerTitle>
            <ModalX onClick={setState} />
          </HeaderModal>

          <DivCourses>
            <TitleCourses>Cursos relacionados:</TitleCourses>

            <Courses>{returnCourses()}</Courses>
          </DivCourses>
        </DivModal>
      </ModalBackground>
    </Fragment>
  );
}
