import * as talentsService from './talents.service';
import { createFilterObject } from 'views/StaffPages/Csm/helpers/createFilterObject';

/**
 * @class TalentsFilterService
 * @property {Object} selectedFilters
 * @property {Object} filterOptions
 */
class TalentsFilterService {
  yesOrNot = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  curriculumUpdatedOptions = [
    { label: '1 mês', value: 1 },
    { label: '2 meses', value: 2 },
    { label: '3 meses', value: 3 },
    { label: '4 meses', value: 4 },
    { label: '5 meses', value: 5 },
    { label: '6 meses', value: 6 },
  ];
  selectedFilters = {};
  filterOptions = {
    degreeLevel: [],
    courses: [],
    studentStatus: [],
    periods: [],
    universities: [],
    modality: [],
    academicProgress: [],
    professionalStatus: [],
    workingArea: this.yesOrNot,
    states: [],
    cities: [],
    professionalExperienceAreas: [],
    behavioralAttributes: [],
    personalityAttributes: [],
    professionalOffers: [],
    curriculumUpdated: this.curriculumUpdatedOptions,
    specialNeeds: [],
    colorOrRace: [],
    genderIdentities: [],
  };

  filterUrl = '';

  filterObjectService;

  constructor() {
    this.filterObjectService = new createFilterObject();
  }

  setOptionsFilters(options) {
    const filters = {
      degreeLevel: options?.degree_level,
      courses: options?.courses ?? [],
      studentStatus: options?.student_status ?? [],
      periods: options?.periods ?? [],
      universities: options?.universities ?? [],
      modality: options?.course_modality,
      professionalStatus: options?.professional_status ?? [],
      states: options?.states ?? [],
      cities: options?.cities ?? [],
      professionalExperienceAreas: options?.professional_experience_areas ?? [],
      behavioralAttributes: options?.behavioral_attributes ?? [],
      personalityAttributes: options?.personality_attributes ?? [],
      professionalOffers: options?.professional_offers ?? [],
      curriculumUpdated: this.curriculumUpdatedOptions,
      specialNeeds: options?.special_needs,
      colorOrRace: options?.color_or_race,
      genderIdentities: options?.gender_identities,
    };

    this.filterOptions = {
      ...this.filterOptions,
      ...filters,
    };
  }

  /**
   *
   * @param {Array} states
   * @returns Array
   */
  getCities = async (states) => {
    try {
      const [params, _] = this.filterObjectService.convert({ states: states });
      const response = await talentsService.getCities(params.toString());
      const { data } = response;
      return data.cities;
    } catch (e) {
      return { message: e, error: true };
    }
  };

  getUrlParamsFilters(filters = {}) {
    const [params, _] = this.filterObjectService.convert(filters);
    this.filterUrl = params;
    return params;
  }
}

export const talentsFilterService = new TalentsFilterService();
