import styled, { keyframes } from 'styled-components';
import search from 'assets/Matriz/search.svg';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

export const ContainerList = styled.div`
  padding: 30px 40px;
  width: 70%;
  border-right: 1px solid #eef2f4;
`;
export const ContainerMatrix = styled.div`
  width: 30%;
  padding: 10px;
`;

export const Bar = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  border-top: ${(props) => (props.useBorder ? '1px solid #eef2f4' : '')};
  align-items: baseline;
  padding: 10px 0.5rem;
  min-height: 65px;
  height: auto;
  border-top: ${(props) => (props.checked ? '1px solid #009291' : '')};
  border-left: ${(props) => (props.checked ? '1px solid #009291' : '')};
  border-right: ${(props) => (props.checked ? '1px solid #009291' : '')};
  border-bottom: ${(props) =>
    props.checked && !props.openMore ? '1px solid #009291' : ''};
  background-color: ${(props) => (props.checked ? '#00929117' : '')};

  input {
    height: 18px;
    width: 18px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: #009291;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const SPAN = styled.span`
  width: ${(props) => props.width};
  font-weight: bold;
  color: #b8b8b8;
`;

export const SpanItems = styled.span`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

export const FlagIconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-color: #caeaea;
`;

export const WrapperActualMatrix = styled.div`
  margin-top: 8px;
  margin-bottom: 45px;
`;

export const WrapperCreatedMatrix = styled.div`
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const H4Matrix = styled.h4`
  margin: 30px 0px;
  font-size: 18px;
  font-weight: bold;
`;

export const TitleSpan = styled.span`
  font-size: 17px;
  color: #009291;
  font-weight: 400;
`;

export const WrapperPagination = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;

  > div {
    .wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const SearchInput = styled.input`
  padding-left: 40px;
  background-image: url(${search});
  background-repeat: no-repeat;
  background-position: 12px 9px;
  background-size: 16px;
  border: 1px solid #777777;
  border-radius: 4px;
  height: 35px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 332px;
`;

const shimmer = keyframes`
  0% {
    background-position: 0% 0%
  }
  100% {
    background-position: -135% 0%
  }
`;

export const MatricesSkeleton = styled.div`
  background-image: linear-gradient(
    -90deg,
    #e7edf1 0%,
    #f8f8f8 50%,
    #e7edf1 100%
  );
  background-size: 400% 400%;
  border-radius: 4px;
  width: 270px;
  height: 122px;
  animation: ${shimmer} 1.9s ease-in-out infinite;
`;
