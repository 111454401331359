import React from 'react';
import { useCsm } from 'views/StaffPages/Csm/contexts/CsmContext';
import { CategoryImage } from '../../CategoryImage';

import { CategoryButton, CategoryGrid, SelectCategoryContainer } from './style';
import CarrearService from 'assets/csm/CarrearService.svg';
import Activies from 'assets/csm/Activies.svg';

export default function CardSelect({ category, setSelectedHomeCategory }) {
  const { setActiveTab } = useCsm();

  const handleSelect = () => {
    setActiveTab(category.index);
    setSelectedHomeCategory(true);
  };

  const images = {
    0: CarrearService,
    1: Activies,
  };

  return (
    <CategoryButton key={category.category} onClick={handleSelect}>
      <img src={images[category.index]} />
      <span>
        <strong>{category.title}</strong>
      </span>
      <p>{category.subTitle}</p>
    </CategoryButton>
  );
}
