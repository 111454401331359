import React, { Fragment } from 'react';
import { bool, object, func, arrayOf } from 'prop-types';
import { Grid } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import BehavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import useDefineColor from 'constants/colors';

const InterestHelpContent = (props) => {
  const { classes, universityColor } = props;
  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Interesses
          </h2>
          <p className={classes.cardCategory}>
            Os interesses refletem seus assuntos favoritos e que normalmente
            estão relacionados a suas paixões e propósito. É interessante
            utilizá-los em seus projetos acadêmicos para evidenciar seus
            conhecimentos e capacidade de atuação nessas áreas.
          </p>
          <br />
          <iframe
            className={classes.videos}
            src="https://www.youtube.com/embed/i_RoMNg53IQ"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

InterestHelpContent.propTypes = {
  classes: object,
  isEdit: bool,
  interests: arrayOf(object),
  openModalWithContent: func,
  updateInterestState: func,
  openSnackbar: func,
  closeModal: func,
  handleIsLoadingState: func,
};

export default withStyles(BehavioralTestStyle)(InterestHelpContent);
