export const colorsStatus = {
  refused: {
    label: 'RECUSADO',
    color: '#DA0505',
    background: '#FFEDED',
  },
  accepted: {
    label: 'ACEITO',
    color: '#068E2C',
    background: '#D9FFE3',
  },
  new: {
    label: 'NOVO',
    color: '#009291',
    background: '#A4DEDD',
  },
};
