import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { UnInput, UnInputLabel } from '../styles/unformCompany';
import { ContainerLabelInput, ErrorMessage } from '../styles/styleForm';

import { isYoutubeVideo } from "utils/validation";

export default function InputReplaceLink({ label, name, setModalOpen, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        let url = inputRef.current.value; 
        let codVideo = isYoutubeVideo(url); 
        let urlValid = 'https://youtu.be/' + codVideo

        if (url === '') {
          return ''
        } 
        if (codVideo === false ) {
          setModalOpen(true)
        }
        else {
          setModalOpen(false)
          return urlValid
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput ref={inputRef} onFocus={clearError} defaultValue={defaultValue}  {...rest} />
      {error && <ErrorMessage marginTop="-10px">{error}</ErrorMessage>}
    </ContainerLabelInput>
  );
}
