import Tooltip from 'components/NewTooltip';
import {
  BoxIcon,
  IconGrid,
  IconSession,
  OpenVacancies,
} from '../Components/UIComponents/GenericAccordion/style';
import { useIcons } from '../Hooks/useIcons';

export const renderItemsAccordion = () => {
  const {
    BsFillCheckCircleFill,
    AiFillCloseCircle,
    IoLocationSharp,
    JobBagIcon,
    HiClock,
    InfoIcon,
  } = useIcons();

  const returnStatus = (data) => {
    const status = {
      current: <span>Termo de convênio vigente</span>,
      no_term: <span>Sem termo de convênio</span>,
      close_expiration: <span>Termo de convênio próximo ao vencimento</span>,
      expired: <span>Sem termo de convênio</span>,
    };

    return status[data.agreement_status];
  };

  const returnIcons = (data) => {
    const icons = {
      current: <BsFillCheckCircleFill color="#009291" size={16} />,
      no_term: <AiFillCloseCircle color="#DA0505" size={16} />,
      close_expiration: <HiClock color="#F09D51" size={16} />,
      expired: <AiFillCloseCircle color="#DA0505" size={16} />,
    };

    return icons[data.agreement_status];
  };

  const returnCityAndState = (data) => {
    if (data.address.city !== '' && data.address.state !== '') {
      return `${data?.address.city} - ${data?.address.state}`;
    } else {
      return 'Não informado';
    }
  };

  const returnIconSessions = (
    data,
    vacanciesArea,
    toggleVacanciesModal = null
  ) => {
    return (
      <IconSession gap="36px">
        <IconGrid>
          <BoxIcon>
            <IoLocationSharp color="#009291" size={15} />
          </BoxIcon>
          <span>{returnCityAndState(data)}</span>
        </IconGrid>
        {vacanciesArea && (
          <IconGrid>
            <BoxIcon>
              <JobBagIcon color="#009291" />
            </BoxIcon>
            <OpenVacancies
              {...(toggleVacanciesModal &&
                data?.company_vacancies > 0 && {
                  onClick: () => toggleVacanciesModal(data),
                })}
              hasToggleVacanciesModal={toggleVacanciesModal}
              hasVacancies={data?.company_vacancies > 0}
            >
              Vagas Publicadas: {data?.company_vacancies}
              {data?.company_vacancies > 0 && !toggleVacanciesModal && (
                <Tooltip
                  text="Não é possível visualizar as vagas
porque a empresa está desconectada.
Reconecte-se para ter acesso as vagas."
                  direction="top"
                  colorSchema="black"
                  isMultiLine={true}
                  minWidth="300px"
                >
                  <InfoIcon color="#606062" size={18} />
                </Tooltip>
              )}
            </OpenVacancies>
          </IconGrid>
        )}
      </IconSession>
    );
  };

  return { returnStatus, returnIcons, returnCityAndState, returnIconSessions };
};
