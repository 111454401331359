import React, { Fragment, useEffect, useState } from 'react';
import { array } from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ListItem } from '@material-ui/core';

import Chart from 'react-google-charts';

import withStyles from '@material-ui/core/styles/withStyles';

import Button from 'components/CustomButtons/Button';

import ProfessionalStatusReportPDF from './ProfessionalStatusReportPDF';

const style = () => {
  return {
    buttonGenerator: {
      position: 'absolute',
      zIndex: 99,
      top: 0,
      width: 300 ,
      right: 0,
      justifyContent: 'space-around',
    },
  };
};

const ProfessionalStatusReportPDFGenerationButton = ({
  children,
  classes,
  chartsData,
  activeFilters,
  summaryData,
}) => {
  const [chartUris, setChartUris] = useState([]);
  const [allowPDFLoading, setAllowPDFLoading] = useState(false);

  const createChartListener = (index) => [
    {
      eventName: 'ready',
      callback: ({ chartWrapper }) => {
        chartUris[index] = chartWrapper.getChart().getImageURI();
        setChartUris(chartUris);
      },
    },
  ];

  useEffect(() => {
    setChartUris([]);
  }, [chartsData.length]);

  return (
    <Fragment>
      <ListItem className={classes.buttonGenerator}>
        <Button
          round
          disabled={allowPDFLoading || chartsData.some((c) => c.loading)}
          onClick={() => setAllowPDFLoading(true)}
        >
          {children}
        </Button>
      </ListItem>

      <div style={{ position: 'fixed', zIndex: -1, opacity: 0 }}>
        {chartsData.map((item, index) => (
          <Chart
            {...item.chartData}
            height="275px"
            width="1000px"
            options={{
              ...item.chartData.options,
              legend: { textStyle: { fontSize: 17, color: '#696969' } },
              hAxis: { textStyle: { fontSize: 17, color: '#696969' } },
              vAxis: { textStyle: { fontSize: 17, color: '#696969' } },
            }}
            chartEvents={createChartListener(index)}
          />
        ))}

        {allowPDFLoading && (
          <PDFDownloadLink
            document={
              <ProfessionalStatusReportPDF
                chartsData={chartsData.map((item, index) => ({
                  ...item,
                  uri: chartUris[index],
                }))}
                activeFilters={activeFilters}
                summaryData={summaryData}
              />
            }
            fileName="Perfil do Estudante.pdf"
          >
            {({ url, loading }) => {
              if (!loading) {
                setAllowPDFLoading(false);
                window.open(url);
              }
            }}
          </PDFDownloadLink>
        )}
      </div>
    </Fragment>
  );
};

ProfessionalStatusReportPDFGenerationButton.propTypes = {
  children: array.isRequired,
  chartsData: array.isRequired,
  activeFilters: array.isRequired,
};

export default withStyles(style)(ProfessionalStatusReportPDFGenerationButton);
