import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  width: 88%;
  height: 100%;

  border-radius: 8px;
  background-color: #ffffff;
  height: 100%;
  margin-top: 32px;
`;

export const Title = styled.p`
  color: #009291;
  font-weight: 500;
  font-size: 22px;

  width: 100%;
  text-align: start;
  margin-bottom: 18px;
  margin-top: ${(props) => props.marginTop || '54px'};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
`;

export const SeeMoreButton = styled.button`
  border: none;
  background-color: #009291;
  height: 40px;
  width: 130px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 700;
  margin: 20px auto 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;

  cursor: pointer;

  &:hover:not(:disabled) {
    filter: brightness(0.9);
  }
`;

export const ListExtensionProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
`;

export const SubTitle = styled.p`
  font-size: 16px;
  text-align: start;
  margin-bottom: 26px;
`;

export const ArrowButton = styled.button`
  width: 35px;
  height: 35px;

  background: #009291;

  border: none;
  border-radius: 200px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  :active {
    transform: scale(0.8);
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
`;

export const DetailsButton = styled.button`
  width: 100%;
  border: none;
  background-color: white;
  padding: 16px;
  font-size: 13px;
  color: #009291;
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  display: flex; ;
`;

export const ContainerDashed = styled.div`
  width: 100%;
  max-width: 620px;
  height: ${(props) => props.height || '108px'};

  border: 1px dashed #bdbdbd;
  border-radius: 4px;
  justify-content: center;

  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 0 auto;

  p {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
`;

export const ButtonAddProject = styled.button`
  width: 100%;
  max-width: 285px;
  height: 42px;

  background-color: #009291;
  border: none;
  border-radius: 8px;

  display: flex;
  align-items: center;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  margin-top: 13px;

  cursor: pointer;

  .white-box {
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;
  }
`;
