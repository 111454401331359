import { useEffect, useRef, useState } from 'react';
import {
  ContainerForm,
  Title,
  ContainerButton,
  ContainerInfo,
  SubTitle,
  CloseButton,
} from 'views/Backoffice/academicInnovation/Styles/FormStyles';
import { Form } from '@unform/web';
import bookShelf from 'assets/backoffice/bookShelf.svg';
import book from 'assets/backoffice/book.svg';
import UnformSelect from 'components/UnformFields/Select/SearchableSelect';
import academicInnovationApi from 'views/Backoffice/academicInnovation/services/academicInnovationApi';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';
import RegisterTagMainForm from '../registerTagMainForm';
import { ContainerSelectCreateTag } from './style';
import Tooltip from 'components/NewTooltip';
import add from 'assets/backoffice/add.svg';
import edit from 'assets/Matriz/edit.svg';
import FormLoading from 'views/Backoffice/academicInnovation/components/FormLoading';

export default function AssociateTagUniversityForm({
  item,
  selectedTag,
  nameFromEdit,
}) {
  const formRef = useRef(null);

  const { setCurrentFormToShow } = useFormContext();

  const [principalTags, setPrincipalTags] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [marketRequirements, setMarketRequirements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [showRestForm, setShowRestForm] = useState(false);

  const handleClickClose = () => {
    setCurrentFormToShow(null);
  };

  const getSelectOptions = async () => {
    const response = await academicInnovationApi.getSelectUniversityAssociateForm();
    setPrincipalTags(response);
  };

  useEffect(() => {
    if (selectedTag) {
      const tag = principalTags.find((item) => item.value === selectedTag);
      if (tag) {
        formRef.current.setFieldValue('principal_tags', tag);
      }
    }

    if (nameFromEdit) {
      const tag = principalTags.find((item) => item.label === nameFromEdit);
      {
        if (tag) {
          formRef.current.setFieldValue('principal_tags', tag);
        }
      }
    }
  }, [principalTags]);

  useEffect(() => {
    getSelectOptions();
  }, []);

  const handleSelectTag = async (event) => {
    if (event.value) {
      const value = event.value;
      setShowRestForm(true);

      const {
        keywords,
        market_requirements,
      } = await academicInnovationApi.getDetailsPrincipalTag(value);
      setKeywords(keywords);
      setMarketRequirements(market_requirements);
    }
  };

  const handleClickEdit = () => {
    const selectedTag = formRef.current.getFieldValue('principal_tags');

    setCurrentFormToShow(
      <RegisterTagMainForm
        selectedTag={selectedTag}
        openRegisterAfterClose={true}
        itemCallBack={item}
      />
    );
  };

  const handleClickCreate = () => {
    setCurrentFormToShow(
      <RegisterTagMainForm isFromRegister itemCallBack={item} />
    );
  };

  useEffect(() => {
    if (marketRequirements?.length > 0) {
      formRef?.current.setFieldValue('market_requirements', marketRequirements);
    }

    if (keywords.length > 0) {
      formRef?.current.setFieldValue('keywords', keywords);
    }
  }, [marketRequirements]);

  const handleSubmit = async (data) => {
    setIsLoading(true);

    const principal_tag = data.principal_tags;

    await academicInnovationApi.putAssociateTag(item.id, {
      principal_tag: principal_tag,
    });
    setIsLoading(false);

    handleClickClose();
  };

  const controllRenderToolTip = () => {
    const selectedTag = formRef?.current?.getFieldValue('principal_tags');

    if (selectedTag) {
      return (
        <Tooltip text="Editar tag principal" direction="top">
          <img
            src={edit}
            onClick={handleClickEdit}
            style={{ cursor: 'pointer', marginTop: '-10px' }}
          />
        </Tooltip>
      );
    }

    return (
      <Tooltip text="Cadastrar tag principal" direction="top">
        <img
          src={add}
          onClick={handleClickCreate}
          style={{ cursor: 'pointer', marginTop: '-10px' }}
        />
      </Tooltip>
    );
  };

  return (
    <ContainerForm>
      <CloseButton onClick={() => handleClickClose()} />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Title weight={600} margin={'25px 0px 0px'} size={'22px'}>
          {item.name}
        </Title>

        <ContainerInfo>
          <div>
            <span>
              <img src={bookShelf} />
              <p>Instituição: {item.university}</p>
            </span>
            <span>
              <img src={book} />
              <p>Disciplina relacionada: {item.discipline}</p>
            </span>
          </div>

          <div>
            <span>
              <img src={bookShelf} />
              <p>Curso relacionado: {item.course}</p>
            </span>
          </div>
        </ContainerInfo>
        <SubTitle>Associação de TAGS</SubTitle>
        <ContainerSelectCreateTag>
          <UnformSelect
            name="principal_tags"
            options={principalTags}
            label="Nome da TAG Principal*"
            onChange={handleSelectTag}
          />
          {controllRenderToolTip()}
        </ContainerSelectCreateTag>
        {showRestForm && (
          <>
            <UnformSelect
              name="keywords"
              options={keywords}
              label="Palavras-chave*"
              onChange={handleSelectTag}
              isMulti
              isDisabled
            />
            <UnformSelect
              name="market_requirements"
              options={marketRequirements}
              label="Requisito/tendência de mercado"
              onChange={handleSelectTag}
              isMulti
              isDisabled
            />
          </>
        )}

        <ContainerButton>
          <button type="submit">
            {isLoading ? <FormLoading /> : 'Salvar'}
          </button>
        </ContainerButton>
      </Form>
    </ContainerForm>
  );
}
