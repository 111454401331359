import { Container, WhiteBox } from './styled';
import { ContextProvider } from './context';
import InternalTab from './screens/Tabs';

export function HistoricTab() {
  return (
    <ContextProvider>
      <Container>
        <WhiteBox>
          <InternalTab />
        </WhiteBox>
      </Container>
    </ContextProvider>
  );
}
