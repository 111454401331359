import styled from 'styled-components';

export const ContainerCard = styled.div`
  width: 100%;
  max-width: 286px;

  background: #ffffff;
  box-shadow: 0px 0px 13px rgb(0 0 0 / 6%);
  border-radius: 11.5652px;

  flex: none;

  position: relative;

  @media (max-width: 425px) {
    scroll-snap-align: center;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 209px;

  background-color: ${(props) => props.universityColor || '#009291'};

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${(props) => (props.image ? '80%' : '100%')};
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    z-index: 2;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
    z-index: 1;
  }
`;

export const TimeView = styled.div`
  height: 32px;
  width: fit-content;

  padding: 0 8px;

  border-radius: 4px;
  background: ${(props) => props.bgColor};

  display: flex;
  align-items: center;
  justify-content: space-around;

  color: ${(props) => props.viewColor};
  font-size: 12px;
  font-weight: 500;

  p {
    margin: 0;
  }

  @media (max-width: 425px) {
    font-size: 11px;
    height: 37px;
    text-align: center;
    line-height: 16px;

    p {
      margin: 0;
      text-align: center;
    }
  }
`;

export const DividerCard = styled.div`
  height: 1px;
  width: 100%;
  background-color: #e5e5e5;
  margin: 14px 0;
`;

export const BottomDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  svg {
    flex: none;
    margin-top: 2px;
  }

  width: 100%;

  span {
    font-size: 12px;
    color: #606062;
    margin-left: 5px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 50%;
    background-color: #009291;
    margin: 0;

    > svg {
      margin: 0;
    }
  }
`;

export const BgColorIcons = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 3px;

  background-color: ${(props) => props.boxBgColor};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 8px;
`;

function getBackgroundColor(status) {
  switch (status) {
    case 'Aguardando avaliação':
      return '#FFFDED';
    case 'Aguardando evidência':
      return '#FFF2E3';
    case 'Aguardando análise':
      return '#FFFDED';
    case 'Evidências analisadas':
      return '#CAEAEA';
    case 'Projeto despublicado':
      return '#FFF8ED';
    case 'Parabéns! Você foi aprovado!':
      return '#D9FFE3';
    case 'Você foi removido':
      return '#FFEDED';
  }
  if (status.includes('realizadas')) {
    return '#EEF4FF';
  }
}

export const TimeViewFinished = styled(TimeView)`
  background: ${(props) => getBackgroundColor(props.status)};
  color: ${(props) => getColor(props.status)};
`;

function getColor(status) {
  switch (status) {
    case 'Aguardando avaliação':
      return '#E9B600';
    case 'Aguardando evidência':
      return '#FBA92E';
    case 'Aguardando análise':
      return '#E9B600';
    case 'Evidências analisadas':
      return '#009291';
    case 'Projeto despublicado':
      return '#F09D51';
    case 'Parabéns! Você foi aprovado!':
      return '#068E2C';
    case 'Você foi removido':
      return '#DA0505';
  }

  if (status.includes('realizadas')) {
    return '#7BA0E9';
  }
}

export const BgColorIconsFinished = styled(BgColorIcons)`
  background: ${(props) => getColor(props.status)};
`;

export const Like = styled.div`
  width: 100%;
  max-width: 45px;
  height: 25px;

  display: flex;
  align-items: center;
  justify-content: space-around;

  box-shadow: 0px 0px 11.6458px rgba(0, 0, 0, 0.4);

  position: absolute;
  top: 10px;
  right: 10px;

  background: #ffffff;
  border-radius: 4px;

  p {
    margin: 0;
    padding-right: 3px;
  }
`;

export const MissingTime = styled.div`
  height: 5%;
  padding: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  text-align: center;

  background: #009291;

  position: absolute;
  top: 10px;
  left: 15px;

  border-radius: 4px;
  border: ${(props) => (props.border ? '1px solid #ffffff' : 'unset')};

  svg {
    margin-right: 3px;
  }

  p {
    margin: unset;
  }
`;

export const Information = styled.p`
  font-size: 11px;
  font-weight: 400;
  margin-top: 2px;
  color: #606062;
  text-align: start;
`;

export const Title = styled.p`
  font-size: 17px;
  font-weight: 700;
  color: #4b4b4d;
  margin-bottom: 10px;
  text-align: start;
  margin-top: 15px;
  max-width: 242px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 425px) {
    font-size: 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;

  .wrapper-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px;
  }

  .wrapper-content:last-child {
    > svg {
      margin-top: -24px;
    }

    p {
      height: 42px;
    }
  }

  .wrapper-content img {
    width: 17px;
  }

  .wrapper-content svg {
    flex: none;
  }

  .wrapper-content p {
    padding-left: ${(props) => props.paddingLeft || '10px'};
    margin: 0;
    text-align: start;
    font-size: 14px;
    word-break: break-word;
    text-overflow: ellipsis;
    max-width: 240px;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 425px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ButtonGreen = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${(props) =>
    props.finishedProjects === true ? 'unset' : '117px'};
  height: 30px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  color: #ffffff;
  font-size: 11px;
  padding: 0;
  font-weight: 600;

  border: none;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 425px) {
    max-width: unset;
  }
`;

export const ButtonWhite = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 117px;
  height: 29px;

  background-color: #ffffff;

  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 11px;
  padding: 0;
  font-weight: 600;

  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  border-radius: 4px;
  margin-left: ${(props) => (props.awaitEvidences ? '0px' : '8px')};
  margin-right: ${(props) => (props.awaitEvidences ? '8px' : '0px')};
  cursor: pointer;

  @media (max-width: 425px) {
    max-width: unset;
    margin-top: 8px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const ViewStudents = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  color: #009291;
  font-weight: 600;
  cursor: pointer;
`;

export const DataContent = styled.div`
  width: 100%;
  padding: 22px 20px;
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;

  background-color: ${(props) => props.bgColor || '#009291'};
  border-radius: 200px;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: none;
`;

export const ContainerDashed = styled.div`
  width: 100%;
  max-width: 620px;
  height: ${(props) => props.height || '168px'};

  border: 1px dashed #bdbdbd;
  border-radius: 4px;
  padding-top: 36px;

  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 0 auto;

  p {
    font-size: 20px;
    font-weight: 400;
    text-align: center;

    margin-bottom: 17px;
  }
`;

export const ButtonAddProject = styled.button`
  width: 100%;
  max-width: 285px;
  height: 42px;

  background-color: #009291;
  border: none;
  border-radius: 8px;

  display: flex;
  align-items: center;

  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  margin-top: 13px;

  cursor: pointer;

  .white-box {
    width: 27px;
    height: 27px;
    background-color: #fff;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 10px;
  }
`;
