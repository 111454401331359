import React, { useState } from 'react';

import {
  Container,
  WrapperContent,
  ContainerHeader,
  Title,
  FilterContainer,
  LoadingStyle,
} from './styled';
import LoadingUpdate from 'components/LoadingUpdate/LoadigUpdate';

import InternalTabInsights from './InternalTabInsights';
import { shape, func, bool } from 'prop-types';

import ReportsStudents from './CustomContainerReports/reportsStudents/ReportsStudents';
import ReportsInfosJobs from './CustomContainerReports/reportsInfoJobs/ReportsInfosJobs';
import ReportsMarket from './CustomContainerReports/reportsMarket/ReportsMarket';

import { FilterCourseDashboard } from './Filter';
import InsightsExport from './Components/InsightsExport';

export default function CustomTabInsights(props) {
  const {
    insightOptions,
    InsightList,
    tabs,
    handleIsLoadingState,
    handleQueryParams,
  } = props;

  const [activeTab, setActiveTab] = useState(0);
  const [status, setStatus] = useState();
  const [filters, setFilters] = useState({});
  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const hasFilters = Object.keys(filterState).length > 0;
  const [loading, setLoading] = useState(false);

  const handleParams = async (filters) => {
    setFilters(filters);
    setLoading(true);
    await handleQueryParams(filters);
    setLoading(false);
  };

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <ReportsStudents
          InsightList={InsightList}
          setStatus={setStatus}
          activeTab={activeTab}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <ReportsInfosJobs
          InsightList={InsightList}
          setStatus={setStatus}
          activeTab={activeTab}
          filterState={filterState}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <ReportsMarket
          InsightList={InsightList}
          setStatus={setStatus}
          activeTab={activeTab}
        />
      ),
    },
  ];

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };
  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);

    return returnTabs.component;
  };
  return (
    <Container>
      <Title>Relatórios</Title>
      <ContainerHeader>
        <span>
          Neste espaço é possível obter dados para analisar a situação de seus
          estudantes matriculados, egresso, as vagas e as empresas cadastradas.
          Você poderá escolher o curso no qual deseja verificar as métricas
          utilizando o filtro ao lado.
        </span>

        {insightOptions && (
          <FilterContainer hasFilters={hasFilters}>
            <FilterCourseDashboard
              handleQueryParams={handleParams}
              insightOptions={insightOptions}
              filterState={filterState}
              setFilterState={setFilterState}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
            ></FilterCourseDashboard>
          </FilterContainer>
        )}

        <InsightsExport
          handleIsLoadingState={handleIsLoadingState}
          queryFilters={filterState}
        />
      </ContainerHeader>
      <WrapperContent>
        {loading || !InsightList ? (
          <LoadingStyle>
            <LoadingUpdate />
            <p
              style={{
                fontSize: '16px',
                fontWeight: '400',
              }}
            >
              Carregando...
            </p>
          </LoadingStyle>
        ) : (
          <div>
            <InternalTabInsights
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabs={[`Estudantes`, `Vagas`, `Empresas`]}
            />
            {InsightList && insightOptions && renderPrincipalTabs(activeTab)}
          </div>
        )}
      </WrapperContent>
    </Container>
  );
}

CustomTabInsights.propTypes = {
  classes: shape({}).isRequired,
  InsightList: shape.isRequired,
  classes: shape.isRequired,
  handleIsLoadingState: func.isRequired,
  insightOptions: shape.isRequired,
};
