import React, {
  Fragment,
  useRef,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import {
  ButtonDelet,
  ButtonPickFile,
  ErrorMessage,
  FileContainer,
  FileWrapper,
} from '../styleForm';

import InformationModal from '../InformationModal/index';

export default function FileInputChallenge(props) {
  const { formData, name, formRef, ...rest } = props;
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [existFile, setExistFile] = useState(defaultValue);
  const [key, setKey] = useState(Date.now());
  const [informationModalOpen, setInformationModalOpen] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [currentError, setCurrentError] = useState('oversizedFile');

  const errorMessages = {
    oversizedFile: {
      header: 'Ooops! Tamanho da imagem excedido.',
      message: 'O tamanho da imagem não pode superar 2mb.',
    },
    oversizedName: {
      header: 'Ooops! O nome do arquivo excede o limite.',
      message: 'O nome do arquivo não pode superar 100 caracteres.',
    },
  };

  const handlePreview = (e) => {
    const file = e.target.files && e.target.files[0];

    if (!file) {
      setExistFile(null);
      setPreview(null);
      return;
    }

    if (file.type === 'image/png' || file.type === 'image/jpeg') {
      setAlertType(false);
    } else {
      setAlertType(true);
      setPreview(null);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    if (file.size > 2097152) {
      setCurrentError('oversizedFile');
      setInformationModalOpen(true);
      e.target.value = null;
      return;
    }

    if (file.name.length > 100) {
      setCurrentError('oversizedName');
      setInformationModalOpen(true);
      setPreview(null);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    setExistFile(file);
  };

  useEffect(() => {
    if (defaultValue) {
      setExistFile(defaultValue);
    }
  }, [defaultValue]);

  const handleDelet = useCallback((e) => {
    delete formData.attraction_image;
    e.preventDefault();
    inputRef.current.value = '';
    setExistFile(null);
    setPreview(null);
    setExistFile(null);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (formData.attraction_image) {
          return formData.attraction_image;
        }
        if (ref.files[0]) {
          const file = ref.files[0];

          if (file.size > 2097152) {
            return null;
          } else {
            return ref.files[0];
          }
        }
        return null;
      },

      clearValue(ref) {
        ref.value = '';

        setPreview(null);
        setExistFile(null);
      },
      setValue(_, value) {
        setPreview(value);
        setExistFile(null);
      },
    });
  }, [fieldName, registerField, formData]);

  return (
    <Fragment>
      <InformationModal
        isOpen={informationModalOpen}
        type={'error'}
        setModalOpen={setInformationModalOpen}
        informationHeader={errorMessages[currentError].header}
        errorMsg={errorMessages[currentError].message}
      />
      {existFile && (
        <FileWrapper>
          <FileContainer>
            {existFile && (
              <span style={{ wordBreak: 'break-all' }}>{existFile.name}</span>
            )}
          </FileContainer>
          <ButtonDelet onClick={handleDelet} />
        </FileWrapper>
      )}

      {!existFile && (
        <ButtonPickFile onClick={handleClick}>
          {' '}
          Selecionar arquivo
        </ButtonPickFile>
      )}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <input
          style={{ display: 'none' }}
          type="file"
          key={key}
          ref={inputRef}
          onChange={handlePreview}
          {...rest}
        />
        {alertType && (
          <ErrorMessage paddingBottom="5px">
            Desculpe, somente arquivos do tipo Imagem são permitidos.
          </ErrorMessage>
        )}
      </div>
    </Fragment>
  );
}
