import styled from 'styled-components';

import StarActivity from 'assets/Oportutinites/StarActivity.svg';
import leftArrow from 'assets/Oportutinites/leftArrow.svg';
import rightArrow from 'assets/Oportutinites/rightArrow.svg';
import GreenPlus from 'assets/Oportutinites/GreenPlus.svg';

import X from 'assets/Oportutinites/X.svg';

export const ButtonPagination = styled.button`
  border: none;
  border-radius: 4px;
  background-color: #009291;

  width: 200px;
  height: 40px;

  padding: 12px;

  font-size: 14px;
  color: #fff;
  font-weight: bold;

  cursor: pointer;
  transition: 0.8s;

  &:hover{
    background-color: #25a3a2;
    transition: 0.8s;
  }

  &:disabled{
    display: none;
    cursor: default;
    background-color: #ababab;
  }
`
export const DivButton = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;
`
export const ModalContainer = styled.div`
  width: 512px;
  min-height: 428px;
  margin-bottom: 5em;

  max-height: auto;
  height: auto;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const ModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 4.5em;
  z-index: 1105;
  justify-content: center;
  align-items: center;
`;

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: center;
`;

export const DivTitles = styled.div`
  width: auto;
  height: auto;

  display: flex;
  flex-direction: column;
`;
export const Title = styled.h1`
  color: #009291;
  font-weight: 400;
  font-size: 32px;

  margin: 20px 0 0 0px;
`;
export const SubTitle = styled.p`
  color: #606062;
  font-size: 14px;
  font-weight: 400;
`;
export const WrapperDiv = styled.div`
  width: 100%;
  min-height: 300px;
  height: auto;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 0;
  display: flex;
  align-items: start;
  padding: 40px;
  flex-direction: column;

  @media(max-width: 1370px){
    padding: 40px 2px 20px 40px;
  }
`;
export const DivTitlePagination = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const WrapperPublished = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 2em 3em;
  flex-direction: column;

  @media(max-width: 1370px){
    padding: 2em 2em;
  }
`;
export const DivCards = styled.div`
  width: 100%;
  height: auto;

  margin-top: 50px;

  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const CardPublishedChallenge = styled.div`
  width: 260px;
  height: 365px;
  overflow: hidden;

  background-color: #ffff;
  border-radius: 16px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-right: 20px;
`;
export const CardImg = styled.img`
  top: 0px;
  border-radius: 16px 16px 0 0;
  width: 260px;
  height: 181px;
  background-color: #009291;
`;
export const ContentCard = styled.div`
  background-color: none;
  margin-left: 16px;
`;
export const TitleCard = styled.h4`
  color: #4b4b4d;
  font-weight: bold;
`;
export const ContentImageP = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;
export const ImageChallenge = styled.img`
  width: 25px;
  height: 25px;
`;
export const ParagraphCard = styled.p`
  color: #4b4b4d;
  font-size: 1em;
  font-weight: 400;
  margin: 0 0 0 10px;
`;
export const CardButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
`;
export const ButtonAddChallenge = styled.button`
  background-color: #009291;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 8px;
`
export const ParagraphAddChallenge = styled.p`
  margin: 0;
  color: #0000;
  font-size: 14px;
`
export const DivAddChallenges = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const ButtonCardInfo = styled.div`
  background-color: #fff;
  color: #009291;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  margin-right: 20px;
`;
export const DivPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
export const ContainerTitleSubTitle = styled.div`
  display: flex;
  width: 100% !important;
  justify-content: start;
  flex-direction: column;
  text-align: start;
  align-items: start;
  margin-left: 24px;
`;
export const CardTitle = styled.h4`
  font-weight: 400;
  color: #009291;
  font-size: 1.5rem;
`;
export const CardSubTitle = styled.p`
  color: #606062;
  font-size: 0.9rem;
`;

export const ContainerCards = styled.div`
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
`;

export const Card = styled.div`
  width: 260px;
  height: 380px;
  background-color: #ffff;
  border-radius: 16px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 25px;

  @media screen and (max-width: 1453px) {
    margin: 14px;
  }
`;

export const ActivityType = styled.div`
  width: 42px;
  padding: 6px;
  height: 23px;
  background-color: #ffff;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 1em;
  right: 1em;
  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageActivity = styled.img`
  width: 16px;
  height: 16px;

  margin-right: 3px;
`

export const ActivityTypeTwo = styled.div`
  width: 42px;
  padding: 6px;
  height: 23px;
  background-color: #ffff;
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  top: 4em;
  right: 1em;
  font-size: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StarActivityImgDiv = styled.div`
  background-color: #009291;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-top: -9px;
  margin-left: 4px;
`;

export const StarActivityImg = styled.img`
  margin-top: 6px;
`;

export const StarActivityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
  }
`;

export const DivItemsStudents = styled.div`
  display: flex;
  flex-direction: column;
`

export const StarActivityP = styled.p`
  color: #4B4B4D;
  font-size: 12px;
  font-weight: 500;

  margin: 0px;
  margin-top: 2px;
`;

export const TitleH4 = styled.h4`
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  font-weight: bold;
  font-size: 14.5px;
  position: absolute;
  top: 14em;
  z-index: 2;
  left: 10px;
  text-align: start;
`;

export const Informations = styled.div`
  width: 100%;
  height: 20%;
  position: absolute;
  margin-top: 5em;
`;

export const TagIcons = styled.p`
  display: flex;
  align-items: center;
  text-align: center;
`;

export const IconImage = styled.img`
  width: 7%;
  margin-left: 10px;
  margin-right: 10px;
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2em;
`;

export const ButtonLeft = styled.button`
  background-image: url(${leftArrow});
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:active {
    transform: scale(0.8);
  }
`;

export const ButtonRight = styled.button`
  background-image: url(${rightArrow});
  background-position: center;
  background-size: 200%;
  background-repeat: no-repeat;
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
  margin: 10px;
  &:active {
    transform: scale(0.8);
  }
`;

export const MoreInfo = styled.p`
  margin-top: 26px;
  color: #009291;
  text-decoration: underline;
  text-decoration-color: #009291;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  width: 100%;
  display: flex;
  padding-right: 20px;
  justify-content: end;
`;

export const DashedBorder = styled.div`
  width: 580px;
  height: 150px;
  border-width: 2px;
  border-color: #c4c4c4;
  border-style: dashed;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin-left: 23px;
  margin-top: 20px;
`;

export const PlusButton = styled.button`
  width: 214px;
  min-height: 42.48px;
  height: auto;
  border-radius: 8px;
  background-color: #009291;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  color: #ffff;
  font-weight: bold;
  font-size: 15px;
`;

export const PlusImg = styled.img`
  background-image: url(${GreenPlus});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 15px;
  width: 27.25px;
  height: 27.25px;
  background-color: #ffff;
  border-radius: 8px;
`;

export const Information = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  width: 250px;

  margin-bottom: 8px;

  i {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px 0 10px;
  }

  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const WrapperChallenges = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`;

export const ContainerChallenges = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerButtons = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const ButtonValidation = styled.button`
  width: 100%;
  max-width: 110px;
  height: 30px;

  background: #009291;

  border: none;
  border-radius: 5px;

  font-size: 12px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;

  cursor: pointer;
`;
