import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 0 2px;
`;

export const Tab = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: 2;
  font-size: 16px;
  color: #606062;
  white-space: nowrap;
  cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
  position: relative;
  font-weight: ${(props) => (props.selected ? '700' : '400')};
  transition: 0.5s background-color ease-in-out;

  ${(props) =>
    !props.selected &&
    `&:hover {
      background-color: #eee;
    }
  `}

  ${(props) =>
    `&:after {
      content: '';
      display: block;
      width: 100%;
      height: ${props.selected ? '2px' : '0'};
      background-color: ${
        props.selected ? props.color || '#009291' : 'transparent'
      };
      position: absolute;
      z-index: 2;
      bottom: 0;
    }
  `}
`;

export const ChevronWrapper = styled.div`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: fit-content;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  box-sizing: content-box;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  border: 1px solid #fff;
  margin-left: ${(props) => props.marginLeft || '0'};
  z-index: 2;

  &:hover {
    filter: brightness(0.9);
    border: 1px solid #c4c4c4;
    border-radius: 3px;
  }
`;

const animateMargin = (initial, final) => keyframes`
  0% {
    margin-left: ${initial};
  }
  100% {
    margin-left: ${final};
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-x: clip;
  scroll-behavior: smooth;
  position: relative;
  margin-left: ${(props) => props.marginLeft || '0'};
  animation: ${({ initialMarginLeft, marginLeft }) =>
      animateMargin(initialMarginLeft, marginLeft)}
    0.5s ease-in-out;
`;

export const TabsContainer = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: clip;
  z-index: 1;
`;
