import styled from 'styled-components';
import closeIcon from 'assets/Oportutinites/X.svg';

export const BodyChallenges = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  top: 0;
  bottom: 0;

  z-index: 10000 !important;


`;
export const ModalRegisterChallengesSTwo = styled.div`
  width: 520px;
  height: 720px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ModalRegisterChallenges = styled.div`
  width: ${(props) => (props.editChallenge ? '100%' : '512px')};
  top: ${(props) => (props.editChallenge ? '25px' : '25px')};;
  position: absolute;
  border-radius: 14px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media(max-height: 750px) {
    top: 0;
  }
`;
export const ModalHeader = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;
export const CloseButton = styled.span`
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  transition: filter 0.2s;
  background-image: url(${closeIcon});

  &:hover {
    filter: brightness(0.9);
  }
`;
