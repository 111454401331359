import { createContext, useContext, useState } from 'react';

export const WorkabilityIndicatorContext = createContext({});

export const CourseStatus = {
  BELOW_AVERAGE: 1,
  ABOVE_AVERAGE: 2,
  EQUAL_AVERAGE: 3,
  MAXIMUM_AVERAGE: 4,
};

export const GeneralStatus = {
  BELOW_AVERAGE: 1,
  ABOVE_AVERAGE: 2,
  EQUAL_AVERAGE: 3,
};

export const StudentType = {
  IN_PROGRESS: 0,
  EGRESS: 1,
};

export function WorkabilityIndicatorContextProvider({ children }) {
  const [selectedAdherence, setSelectedAdherence] = useState(null);
  return (
    <WorkabilityIndicatorContext.Provider
      value={{ selectedAdherence, setSelectedAdherence }}
    >
      {children}
    </WorkabilityIndicatorContext.Provider>
  );
}

export function useWorkabilityIndicator() {
  return useContext(WorkabilityIndicatorContext);
}
