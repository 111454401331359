import GenericInternalTab from 'components/GenericInternalTab';
import AppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 40px 30px;
`;

export const ContainerVisualization = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  min-height: 30vh;
  margin-top: 40px;

  & > div {
    z-index: 1;
  }

  .loadMoreButton {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerCards = styled.div`
  padding: 50px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 911px) {
    align-items: center;
    justify-content: center;
    padding: 30px;
    overflow: hidden;
    display: block;
  }

  @media (max-width: 375px) {
    align-items: center;
    justify-content: unset;
  }
`;

export const SearchAndCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  padding: 20px 40px;

  > div {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .groupFilters {
    flex-direction: row;
  }
  @media screen and (max-width: 640px) {
    .groupFilters {
      flex-direction: column;
      width: calc(100vw - 48px);
    }
  }
`;

export const ContainerMobileButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CustomGenericTabs = styled(GenericInternalTab)`
  & > header {
    flex-direction: row;
    flex: 1;
    padding-right: 24px;
    @media screen and (max-width: 800px) {
      padding-right: 16px;
    }
  }
  ${AppBar} {
    header {
      flex: 1;
      z-index: 0;
    }
    .genericTabs {
      flex: 1;
      div:first-child {
        flex: 0;
      }
    }
  }
`;
