import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from 'components/CustomButtons/Button.jsx';
import defaultAvatar from 'assets/img/companyAvatarDefault.png';
import { FormHelperText } from '@material-ui/core';

const styles = (theme) => ({
  fileinput: {
    '& .thumbnail': {
      boxShadow: 'initial',
    },
  },
  'img-circle': {
    borderRadius: '3%',
    border: '1px dashed',
  },
});

const CompanyLogoUpload = ({
  avatar,
  classes,
  addButtonProps,
  changeButtonProps,
  removeButtonProps,
  validateToNextStepOnForm,
  onRemove,
  onChange,
}) => {
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(avatar);
  const [imageError, setImageError] = useState('');

  const handleMensageError = (string) => {
    validateToNextStepOnForm(string);
    return setImageError(string);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      if (file.size > 1000000) {
        return handleMensageError('O tamanho da imagem deve ser inferior a 1 Mb');
      }
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        return handleMensageError('Extensão não permitida, utilize: PNG/JPG');
      }
      reader.onloadend = () => {
        const image = new Image();
        image.onload = () => {
          if (image.width > 500 || image.height > 500) {
            return handleMensageError('A imagem deve ter no máximo 500x500 pixels');
          } else {
            setFile(file);
            setImagePreviewUrl(reader.result);
            handleMensageError('');
          }
        };
        image.src = reader.result;
      };
      reader.readAsDataURL(file);
      onChange(e);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(avatar ? avatar : defaultAvatar);
    fileInputRef.current.value = null;
    onRemove();
  };

  const fileInputRef = React.useRef();

  return (
    <div className={`fileinput text-center ${classes.fileinput}`}>
      <input
        id="avatar"
        name="avatar"
        type="file"
        onChange={handleImageChange}
        ref={fileInputRef}
      />
      <div
        className={`thumbnail ${imageError ? 'img-error' : ''} ${
          classes['img-circle']
        }`}
      >
        <img src={imagePreviewUrl || avatar || defaultAvatar} alt="..." />
      </div>
      <div>
        {file === null ? (
          <span>
            {!!imageError && (
              <FormHelperText style={{ color: 'red', fontSize: '0.675rem' }}>
                {imageError}
              </FormHelperText>
            )}
            <Button {...addButtonProps} onClick={() => handleClick()}>
              {avatar ? 'Adicionar logo' : 'Selecionar imagem'}
            </Button>
          </span>
        ) : (
          <span>
            {!!imageError && (
              <FormHelperText style={{ color: 'red', fontSize: '0.675rem' }}>
                {imageError}
              </FormHelperText>
            )}
            <Button {...changeButtonProps} onClick={() => handleClick()}>
              Trocar Upload
            </Button>
            {avatar ? <br /> : null}
            <Button link {...removeButtonProps} onClick={() => handleRemove()}>
              Remover Upload
            </Button>
          </span>
        )}
      </div>
    </div>
  );
};

CompanyLogoUpload.propTypes = {
  avatar: PropTypes.string,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

export default withStyles(styles)(CompanyLogoUpload);
