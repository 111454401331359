import httpRequest from 'services/university/HttpRequest';

class MentorshipApi {
  baseUrl = `api/internal/university/mentorships`;
  baseGlobalUrl = `api/internal/university-student/mentorships`;

  async getMentorsOptions(queryParams = null) {
    let url = `${this.baseUrl}/mentor/options`;
    if (queryParams) {
      url = `${url}?${queryParams}`;
    }
    return await httpRequest.get(url);
  }

  async getMentorsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/mentor/list-create?${queryParams}`
    );
  }

  async getMentorDetails(mentorId) {
    return await httpRequest.get(`${this.baseUrl}/mentor/detail/${mentorId}`);
  }

  async createMentor(mentor) {
    return await httpRequest.post(`${this.baseUrl}/mentor/list-create`, mentor);
  }

  async updateMentor(mentor) {
    return await httpRequest.patch(
      `${this.baseUrl}/mentor/update/${mentor.id}`,
      mentor
    );
  }

  async cancelAllMentorships(mentorId) {
    return await httpRequest.put(
      `${this.baseUrl}/mentor/cancel-all-schedulings/${mentorId}`
    );
  }

  async updateMentorImage(mentorId, image) {
    return await httpRequest.patch(
      `${this.baseUrl}/mentor/update/avatar/${mentorId}`,
      image,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  }

  async getMentorSpecificDates(mentorId) {
    return await httpRequest.get(
      `${this.baseUrl}/mentor/${mentorId}/list/specific_dates`
    );
  }

  async createSpecificDates(data) {
    return await httpRequest.post(
      `${this.baseUrl}/mentor/create/specific_dates`,
      data
    );
  }

  async deleteSpecificDate(mentorId, id) {
    return await httpRequest.delete(
      `${this.baseUrl}/mentor/${mentorId}/delete/specific_dates/${id}`
    );
  }

  async verifyIfMentorHasScheduledMentorshipInDateRange(
    mentorId,
    startDate,
    endDate
  ) {
    return await httpRequest.get(
      `${this.baseUrl}/mentor/verify/scheduling-in-range/${mentorId}?start_date=${startDate}&end_date=${endDate}`
    );
  }

  async getMentorAbsences(mentorId) {
    return await httpRequest.get(
      `${this.baseUrl}/mentor/${mentorId}/list/absences`
    );
  }

  async createAbsences(data) {
    return await httpRequest.post(
      `${this.baseUrl}/mentor/create/absences`,
      data
    );
  }

  async deleteAbsences(absencesIds) {
    return await httpRequest.delete(
      `${this.baseUrl}/mentor/delete/absences/list`,
      { absences: absencesIds }
    );
  }

  async getPendingMentorshipsList({ queryParams }) {
    return await httpRequest.get(`${this.baseUrl}/pending?${queryParams}`);
  }

  async getConfirmedMentorshipsList({ queryParams }) {
    return await httpRequest.get(`${this.baseUrl}/confirmed?${queryParams}`);
  }

  async getRealizedMentorshipsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/historic/realized?${queryParams}`
    );
  }

  async getCanceledMentorshipsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/historic/canceled?${queryParams}`
    );
  }

  async getExpiredMentorshipsList({ queryParams }) {
    return await httpRequest.get(
      `${this.baseUrl}/historic/expired?${queryParams}`
    );
  }

  async getMentorshipsCount() {
    return await httpRequest.get(`${this.baseUrl}/count`);
  }

  async getStudentProfile(id) {
    return await httpRequest.get(`${this.baseUrl}/${id}/student/detail`);
  }

  async getMentorshipDetails(id) {
    return await httpRequest.get(`${this.baseUrl}/detail/${id}`);
  }

  async cancelMentorship(mentorshipId, cancelReason) {
    return await httpRequest.put(
      `${this.baseGlobalUrl}/cancel/${mentorshipId}`,
      {
        cancel_reason: cancelReason,
      }
    );
  }

  async getFilterOptions() {
    return await httpRequest.get(`${this.baseUrl}/filters`);
  }

  async getStudentExternalIdOptions(value) {
    return await httpRequest.get(
      `${this.baseUrl}/filters?external_id=${value}`
    );
  }

  async getRescheduleOptions(mentorshipId, mentorId) {
    return await httpRequest.get(
      `${this.baseUrl}/mentor/rescheduling/options/${mentorshipId}?mentor=${mentorId}`
    );
  }

  async confirmMentorship(id, addressData) {
    return await httpRequest.put(
      `${this.baseGlobalUrl}/confirm/${id}`,
      addressData
    );
  }

  async getMentorNextDays(mentorId, dateReference = null, daysRange = 1) {
    const dateQueryParam =
      dateReference === null ? '' : `?reference_date=${dateReference}`;
    return await httpRequest.get(
      `${this.baseGlobalUrl}/mentor/next-days/${mentorId}${dateQueryParam}&days_range=${daysRange}`
    );
  }

  async getMentorMentorshipScheduled(mentorId) {
    return await httpRequest.get(
      `${this.baseGlobalUrl}/mentor/list-scheduling/${mentorId}`
    );
  }

  async finishMentorship(mentorshipId, data) {
    return await httpRequest.put(
      `${this.baseUrl}/mentor/finish/${mentorshipId}`,
      data
    );
  }

  async uploadMentorshipDocument(mentorshipId, formData) {
    return await httpRequest.post(
      `${this.baseUrl}/${mentorshipId}/mentor/document/registration`,
      formData
    );
  }

  async rescheduleMentorship(mentorshipId, data) {
    return await httpRequest.patch(
      `${this.baseGlobalUrl}/reschedule/${mentorshipId}`,
      data
    );
  }
}

export const mentorshipApi = new MentorshipApi();
