import React, { useContext, useEffect, useState } from 'react';
import {
  ContainerInformations,
  CardHeader,
  CardBody,
  HeaderTitle,
  ContainerAlert,
  ContainerTitleAndIcon,
  PublicProfileContentCard,
  ButtonSeeMore,
  Divider,
  CardBodyMobile,
} from './styled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ModalInformation from 'components/informationModal';
import defineUrlPublicProfile from 'utils/defineUrlPublicProfile';
import BaseLayoutContext from 'contexts/base-layout';
import { Lock } from '@material-ui/icons';
import { IconBlocked } from 'assets/img/iconsCurriculum/iconBlocked';
import { generatePublicProfileLog } from 'services/student/profile';
import GenericModalInformations from './utils/ModalInformations';
import { IoIosArrowForward } from 'react-icons/io';

export function PublicProfileLink(props) {
  const { universityColor, gamificationLevel } = useContext(ProfileContext);
  const { metadata, openSnackbar } = useContext(BaseLayoutContext);
  const [blocked, setBlocked] = useState(true);
  const [openMobileModal, setOpenMobileModal] = useState(false);

  useEffect(() => {
    setBlocked(() => {
      if (gamificationLevel && gamificationLevel > 1) {
        return false;
      }
      return true;
    });
  }, [gamificationLevel]);

  const [openModal, setOpenModal] = useState(false);

  const configModal = {
    bubbleText: 'Link gerado com sucesso!',
    modalWidth: 370,
    buttonConfirmWidth: 170,
    buttonConfirmHeight: 40,
    ParagraphText: `Clique no botão abaixo para copiar o link e compartilhar com quem você quiser.`,
    buttonConfirmText: 'COPIAR LINK',
  };

  const copyText = () => {
    const url = `${defineUrlPublicProfile(metadata.university_slug)}/${
      metadata.public_profile
    }`;
    navigator.clipboard.writeText(url);
    setOpenModal(false);
    openSnackbar('Link copiado com sucesso!', false, 2000);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
    generatePublicProfileLog();
  };

  const openModalMobile = () => {
    setOpenMobileModal(true);
  };

  const closeModalMobile = () => {
    setOpenMobileModal(false);
  };

  const returnContentMobile = () => {
    return (
      <>
        <Divider />
        <ButtonSeeMore onClick={() => openModalMobile()}>
          Visualizar <IoIosArrowForward />
        </ButtonSeeMore>
      </>
    );
  };

  const content = () => (
    <PublicProfileContentCard universityColor={universityColor}>
      <span>
        Gere o link do seu perfil público e compartilhe-o com quem desejar
      </span>
      <button
        onClick={handleOpenModal}
        disabled={blocked}
        style={{ cursor: blocked ? 'not-allowed' : 'pointer' }}
      >
        Gerar link {blocked && <IconBlocked color={'#FFF'} />}
      </button>
    </PublicProfileContentCard>
  );

  return (
    <>
      {openMobileModal && (
        <GenericModalInformations
          isOpen={openMobileModal}
          closeModal={closeModalMobile}
          title={'Perfil público'}
          isPersonalInfo={false}
          handleEditInfo={() => handleOpenModal()}
        >
          {content()}
        </GenericModalInformations>
      )}
      <ModalInformation
        modalOpenState={openModal}
        buttonConfirmFunction={() => copyText()}
        config={configModal}
        universityColor={universityColor}
      />
      <ContainerInformations>
        <CardHeader>
          <ContainerAlert>
            <ContainerTitleAndIcon>
              <HeaderTitle hasMarginRight>Perfil público</HeaderTitle>
            </ContainerTitleAndIcon>
          </ContainerAlert>
        </CardHeader>

        <CardBody>{content()}</CardBody>
        <CardBodyMobile>{returnContentMobile()}</CardBodyMobile>
      </ContainerInformations>
    </>
  );
}
