import React from 'react';
import { Content, ProfileImage, Infos, ButtonContainer } from './style'
import { Divider } from './style';

export default function StudentItemSkeleton({ quantity }) {
  const renderSkeleton = () => {

    return (Array.from({ length: quantity }, (_, i) => i + 1))
      .map((item, index) => (
        <>
          <Content>
            <ProfileImage />
            <Infos>
              <div className="title"></div>
              <div className="row">
                <div className="job"></div>
              </div>
              <div className="row">
                <div className="course"></div>
              </div>
              <div className="row">
                <div className="withIcon"></div>
                <div className="withIcon"></div>
              </div>
              <div className="row">
                <div className="hasItem"></div>
                <div className="hasItem"></div>
              </div>
            </Infos>
            <ButtonContainer>
              <div className="button"></div>
            </ButtonContainer>
          </Content>
          {item < quantity && <Divider />}
        </>
      ))
  }

  return (
    <>{renderSkeleton()}</>
  )
}

StudentItemSkeleton.defaultProps = {
  quantity: 3,
};

