import React, { Fragment } from 'react';
import { object } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';
import useDefineColor from 'constants/colors';

const LanguageHelpContent = (props) => {
  const { classes, universityColor } = props;

  return (
    <Fragment>
      <Grid container className={classes.fullScreenGridContainer}>
        <Grid item xs md={12} sm={12} className={classes.fullScreenGrid}>
          <h2
            className={
              classes[`${useDefineColor(universityColor)}cardTitleHelpContent`]
            }
          >
            Idiomas
          </h2>
          <p className={classes.cardCategory}>
            Aqui é importante que você destaque seu conhecimento em idioma(s).
            Essa é uma das etapas que vai te ajudar a se destacar ainda mais em
            uma seleção de trabalho. Existem 6 níveis disponíveis e você poderá
            visualizá-los quando adicionar algum idioma ao seu currículo. Mas
            lembre-se: seja sincero nessa etapa.
          </p>
          <br />
        </Grid>
      </Grid>
    </Fragment>
  );
};

LanguageHelpContent.propTypes = {
  classes: object,
};

export default withStyles(behavioralTestStyle)(LanguageHelpContent);
