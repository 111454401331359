import { shape } from 'prop-types';
import React, { Fragment } from 'react';

export default function TabContainer({ children }) {
  return <Fragment>{children}</Fragment>;
}

TabContainer.propTypes = {
  children: shape.isRequired,
};
