import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
} from './style';
import React, { Fragment, useMemo } from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import ReactHtmlParser from 'react-html-parser';

export default function ModalInformation({
  config,
  setModalOpen,
  modalOpenState,
  buttonFunction,
}) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Modal width={config.modalWidth} height={config.modalHeight}>
          <WrapperTitle>
            <IconPersonStyled src={IconPerson} />
            <BubbleDialog>
              <H4Styled>{config.bubbleText}</H4Styled>
            </BubbleDialog>
          </WrapperTitle>
          <ParagraphStyled>
            {ReactHtmlParser(config.ParagraphText)}
          </ParagraphStyled>
          {config.showButton && (
            <Button
              width={config.buttonWidth}
              height={config.buttonHeight}
              onClick={() => buttonFunction && buttonFunction()}
            >
              {config.buttonText}
            </Button>
          )}
        </Modal>
      </Wrapper>
    ),
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

ModalInformation.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
  },
};
