import { api, authenticatedAPI } from 'services/api';
import axios from 'axios';
import URLS from 'constants/urls';
import qs from 'querystring';
import { captcha } from 'constants/configs';

const isCompanyRegistered = async (organizationNumber) => {
  const data = { organization_number: organizationNumber };

  const response = api
    .post(`/api/internal/companies/exists`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const companyPreRegisterStatus = async (organizationNumber) => {
  const response = api
    .get(
      `/api/internal/companies/registry-status?organization_number=${organizationNumber}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const isUniversityExists = async (universitySlug) => {
  const response = api
    .get(`/api/internal/companies/university-exists/${universitySlug}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const isStaffEmailExists = async (staffEmail) => {
  const data = { staff_email: staffEmail };

  const response = api
    .post(`/api/internal/companies/staff/email-exists`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getFormOptions = async () => {
  const response = api
    .options(`/api/internal/companies`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanyPreRegisterData = async (id) => {
  const query = window.location.hash.split('?')[1];
  const queryObj = qs.parse(query);

  const response = api
    .get(`/api/internal/companies-invites/${id}`, {
      params: { validation_token: queryObj.token },
    })
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const saveCompany = async (companyData) => {
  const response = api
    .post(`/api/internal/companies`, companyData)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const updateCompany = async (companyData) => {
  const response = authenticatedAPI
    .put(`/api/internal/companies/me`, companyData)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getIesInvites = async () => {
  const response = authenticatedAPI
    .get(`api/internal/companies/ies-invites`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const toggleIesInviteById = async (id, checked) => {
  const data = { connect: !!checked };
  const response = authenticatedAPI
    .post(`/api/internal/companies/ies-invites/${id}/answer`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const cancelInviteById = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/ies-invites/${id}/cancel`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanyJobsOpenings = async () => {
  const response = api
    .get(`${URLS.MOCK_SERVER}/jobs-openings/`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getJobPositionDetails = async (jobId) => {
  const response = api
    .get(`${URLS.MOCK_SERVER}/jobs-details/${jobId}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const uploadLogo = async (fileObj) => {
  const dataFile = new FormData();
  dataFile.append('file', fileObj);
  const response = authenticatedAPI
    .post(`${URLS.API_URL}/api/internal/companies/me/logo`, dataFile)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const companyPreRegister = async (data) => {
  const response = await authenticatedAPI
    .post(`api/internal/companies/company-pre-registration`, data)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCompanyLogged = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/me`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const getUniversitiesToConnect = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/ies/connect`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

const connectToUniversity = async (id) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/ies/connect/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

// IesCitiesOptions
const getCitiesFilters = async (state) => {
  const response = authenticatedAPI
    .options(`/api/internal/companies/ies/connect?${state}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};
// StatesFiltersOptions
const getStatesFilters = async () => {
  const response = authenticatedAPI
    .options(`/api/internal/companies/ies/connect`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};
const getFilteredIesConnection = async (filterQS) => {
  const response = authenticatedAPI
    .get(`api/internal/companies/ies/connect?${filterQS}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const verifyRecaptchaV3 = async (token) => {
  try {
    const response = await axios.post(
      `https://recaptchaenterprise.googleapis.com/v1/projects/my-project-7824-1716553645953/assessments?key=${captcha.apiKey}`,
      {
        event: {
          token,
          siteKey: captcha.siteKey,
          expectedAction: 'signup',
        },
      }
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};

export {
  cancelInviteById,
  companyPreRegister,
  isCompanyRegistered,
  isUniversityExists,
  isStaffEmailExists,
  getFormOptions,
  getCompanyPreRegisterData,
  getCompanyJobsOpenings,
  getJobPositionDetails,
  getIesInvites,
  toggleIesInviteById,
  saveCompany,
  uploadLogo,
  getCompanyLogged,
  updateCompany,
  getUniversitiesToConnect,
  connectToUniversity,
  getCitiesFilters,
  getStatesFilters,
  getFilteredIesConnection,
  verifyRecaptchaV3,
  companyPreRegisterStatus,
};
