import axios from 'axios';
import URLS from 'constants/urls';
import getCookieValue from 'utils/getCookieValue';

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

class ProfessionalStatusApi {
  getFirstAccess = async () => {
    return await authenticatedAPI
      .get('api/internal/students/first-access/')
      .then((response) => response.data)
      .catch((error) => error);
  };

  getProfile = async () => {
    return await authenticatedAPI
      .get('api/internal/students/first-access/profile')
      .then((response) => response.data)
      .catch((error) => error);
  };

  getFirstAccessOptions = async () => {
    return await authenticatedAPI
      .get('api/internal/students/first-access/options')
      .then((response) => response.data)
      .catch((error) => error);
  };

  postFirstAccess = async (data) => {
    try {
      const response = await authenticatedAPI.post(
        'api/internal/students/first-access/',
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  putFirstAccess = async (data) => {
    try {
      const response = await authenticatedAPI.put(
        'api/internal/students/first-access/',
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
}

export default new ProfessionalStatusApi();
