import { Fragment, useMemo, useState } from 'react';
import {
  Container,
  ContainerHeaderButtons,
  HeaderButtons,
  InAnalysesWrapper,
  PublishButton,
} from './style';
import { MarketRequirements } from './MarketRequirements';
import { MarketTendencies } from './MarketTendencies';
import inAnalysesImg from 'assets/academicModeling/inAnalyses.png';
import { useAcademicModeling } from '../../../contexts/AcademicModelingContext';
import AcademicModelingApi from 'services/university/academicModeling';

export default function StepFour() {
  const [activeTab, setActiveTab] = useState(1);
  const {
    selectedCourse,
    setSelectedCourse,
    isAcademicAssociationRole,
    updateCourse,
  } = useAcademicModeling();
  const [isPublishing, setIsPublishing] = useState(false);

  const canPublish = useMemo(() => {
    return (
      isAcademicAssociationRole && !selectedCourse.analyzed && !isPublishing
    );
  }, [selectedCourse.analyzed, isAcademicAssociationRole]);

  const tabs = useMemo(
    () => ({
      1: <MarketRequirements />,
      2: <MarketTendencies />,
    }),
    []
  );

  const canEdit = useMemo(() => {
    return isAcademicAssociationRole || selectedCourse.analyzed;
  }, [selectedCourse.analyzed, isAcademicAssociationRole]);

  async function handlePublish() {
    setIsPublishing(true);
    const response = await AcademicModelingApi.publishCourseAnalysis(
      selectedCourse.id
    );
    if (response.status === 201) {
      const newSelectedCourse = { ...selectedCourse, analyzed: true };
      updateCourse({ ...selectedCourse, analyzed: newSelectedCourse });
      setSelectedCourse(newSelectedCourse);
    }
    setIsPublishing(false);
  }

  return (
    <Container>
      {canEdit ? (
        <Fragment>
          <ContainerHeaderButtons>
            <HeaderButtons
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
              disabled={activeTab === 1}
              roundLeft
            >
              Requisitos de mercado
            </HeaderButtons>
            <HeaderButtons
              active={activeTab === 2}
              onClick={() => setActiveTab(2)}
              disabled={activeTab === 2}
              roundRight
            >
              Tendências de mercado
            </HeaderButtons>
          </ContainerHeaderButtons>
          {tabs[activeTab]}

          {canPublish && (
            <PublishButton onClick={handlePublish}>Publicar</PublishButton>
          )}
        </Fragment>
      ) : (
        <InAnalysesWrapper>
          <img src={inAnalysesImg} alt="" />
          <div>
            <h1>Em análise!</h1>
            <span>
              Seus dados estão em análise. Em breve, você receberá em seu e-mail
              uma notificação sobre os resultados.
            </span>
          </div>
        </InAnalysesWrapper>
      )}
    </Container>
  );
}
