import React, { useLayoutEffect, useState, useContext } from 'react';
import {
  Container,
  Title,
  ButtonSeeMoreJobs,
  ContainerFavoriteCompanies,
} from '../styled';
import { wrapperIconsJobOffers } from '../../utils/wrapperIconsJobOffers';
import FavoriteCompanyJobOffers from './FavoriteCompanyJobs';
import MoreFavoriteCompanyJobs from './MoreFavoriteCompanyJobs';
import { JobOffersContext } from 'views/JobOffers/context/JobOffersContext';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';

export default function FavoriteCardsCompanyJobs(props) {
  const {
    universityColor,
    listFavoriteJobOffers,
    search,
    isGamificationLevelOneIncomplete,
    gamificationLevelOneIncomplete,
    isCoverLetterMissing,
    applyToJobOffer,
    fetchListFavoriteJobOffers,
    hasCoverLetter,
  } = props;
  const {
    filterData,
    closeModal,
    handleIsLoadingState,
    openModalWithContent,
    updateAvatarState,
    fetchGamificationData,
    profileData,
    setProfileData,
    updateProfileState,
    handleShowSpecialNeeds,
    zipCodeStudent,
    stateAndCityStudent,
  } = useContext(JobOffersContext);
  const { IconArrowEdit } = wrapperIconsJobOffers();
  const countFavoriteJobOffers = listFavoriteJobOffers?.data?.count;
  const dataFavoriteJobOffers = listFavoriteJobOffers?.data?.results;
  const [openMoreCards, setOpenMoreCards] = useState(false);
  const [
    jobIdCandidatureBeingProcessed,
    setJobIdCandidatureBeingProcessed,
  ] = useState(null);
  const [showSeeAllJobOffersButton, setShowSeeAllJobOffersButton] = useState(
    false
  );
  const [openModalLocalization, setOpenModalLocalization] = useState(false);
  const [selectJobId, setSelectJobId] = useState(null);
  const [location, setLocation] = useState(null);

  const handleOpenMoreCards = () => {
    setOpenMoreCards(true);
  };

  const handleCloseModal = () => {
    setOpenMoreCards(false);
  };

  const handleCloseModalLocalization = () => {
    setOpenModalLocalization(false)
  };

  const openLocalizationModal = async (jobId, location) => {
    setSelectJobId(jobId);
    setLocation(location)

    if (!gamificationLevelOneIncomplete &&
      location && location?.length !== 0 &&
      Array.isArray(location) && !location?.includes(stateAndCityStudent)) {
      setOpenModalLocalization(true);
    } else {
      await candidateJobOffer(jobId);
    }
  };

  async function candidateJobOffer(jobId) {
    setOpenModalLocalization && setOpenModalLocalization(false)
    try {
      if (isGamificationLevelOneIncomplete()) {
        return;
      }

      if (isCoverLetterMissing(jobId)) {
        return;
      }

      setJobIdCandidatureBeingProcessed(jobId);

      await applyToJobOffer(jobId);
    } finally {
      setJobIdCandidatureBeingProcessed(null);
    }
  }

  const onClickEdit = (jobId) => {
    openModalWithContent(
      <ProfileForm
        handleShowSpecialNeeds={handleShowSpecialNeeds}
        handleIsLoadingState={handleIsLoadingState}
        universityColor={universityColor}
        updateAvatarState={updateAvatarState}
        updateProfileState={updateProfileState}
        closeModal={closeModal}
        profileData={profileData}
        setProfileData={setProfileData}
        fetchGamificationData={fetchGamificationData}
        isCandidate={true}
        jobId={jobId}
        applyToJobOffer={applyToJobOffer}
        zipCodeStudent={zipCodeStudent}
        {...props}
      />,
      'Edição Dados Pessoais'
    );

    setOpenModalLocalization && setOpenModalLocalization(false)
  };

  useLayoutEffect(
    function () {
      const isThereAnyHiddenCard = () => {
        const favoriteCompaniesCardsContainer = document.getElementById(
          'favorite-companies'
        );

        if (!favoriteCompaniesCardsContainer) return;
        if (!favoriteCompaniesCardsContainer.lastChild) return;

        let containersBottom = favoriteCompaniesCardsContainer.getBoundingClientRect()
          .bottom;
        let lastCardsTop = favoriteCompaniesCardsContainer.lastChild.getBoundingClientRect()
          .top;
        setShowSeeAllJobOffersButton(containersBottom < lastCardsTop);
      };
      window.addEventListener('resize', isThereAnyHiddenCard);
      isThereAnyHiddenCard();
      return () => window.removeEventListener('resize', isThereAnyHiddenCard);
    },
    [countFavoriteJobOffers]
  );

  return (
    <>
      {dataFavoriteJobOffers?.length > 0 ? (
        <Container style={{ paddingTop: '0px' }}>
          <ContainerFavoriteCompanies>
            <Title style={{ position: 'absolute', top: '30px' }}>
              Vagas de empresas que você favoritou ({countFavoriteJobOffers})
            </Title>
            <FavoriteCompanyJobOffers
              universityColor={universityColor}
              dataFavoriteJobOffers={dataFavoriteJobOffers}
              search={search}
              filterData={filterData}
              candidateJobOffer={candidateJobOffer}
              onClickEdit={onClickEdit}
              jobIdCandidatureBeingProcessed={jobIdCandidatureBeingProcessed}
              fetchListFavoriteJobOffers={fetchListFavoriteJobOffers}
              openLocalizationModal={openLocalizationModal}
              handleCloseModalLocalization={handleCloseModalLocalization}
              selectJobId={selectJobId}
              location={location}
              setLocation={setLocation}
              openModalLocalization={openModalLocalization}
            />
            {showSeeAllJobOffersButton && (
              <ButtonSeeMoreJobs
                universityColor={universityColor}
                onClick={handleOpenMoreCards}
              >
                Ver todas as vagas
                <IconArrowEdit color={universityColor} />
              </ButtonSeeMoreJobs>
            )}
          </ContainerFavoriteCompanies>

          {openMoreCards && (
            <MoreFavoriteCompanyJobs
              handleCloseModal={handleCloseModal}
              search={search}
              universityColor={universityColor}
              dataFavoriteJobOffers={dataFavoriteJobOffers}
              candidateJobOffer={candidateJobOffer}
              jobIdCandidatureBeingProcessed={jobIdCandidatureBeingProcessed}
              fetchListFavoriteJobOffers={fetchListFavoriteJobOffers}
              openLocalizationModal={openLocalizationModal}
              handleCloseModalLocalization={handleCloseModalLocalization}
              selectJobId={selectJobId}
              setLocation={setLocation}
              location={location}
              openModalLocalization={openModalLocalization}
              onClickEdit={onClickEdit}
              filterData={filterData}
            />
          )}
        </Container>
      ) : null}
    </>
  );
}
