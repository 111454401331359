import Arrow from 'views/StaffPages/FairAndEvents/UiComponents/Arrow';
import {
  Box,
  Container,
  ContainerArrow,
  ContainerDownload,
  Content,
  StyledDocument
} from './styled';
import { BackButton } from 'components/BackButton/BackButton';
import { certificateHandlers } from '../../Handlers/CertificateHandlers';
import { useContext, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from '../Button';
import { IoIosArrowBack } from 'react-icons/io';
import BaseLayoutContext from 'contexts/base-layout';

export default function CertificateModal({ event, open, onClose }) {
  const certificateHandlersFactory = certificateHandlers(event);
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { universityColor } = useContext(BaseLayoutContext);

  const getUrl = async () => {
    const pdfUrl = await certificateHandlersFactory.getCertificate();
    setUrl(pdfUrl);
  };

  const download = async () => {
    setLoading(true);
    await certificateHandlersFactory.downloadCertificate();
    setLoading(false);
  };

  const print = async () => {
    setLoading(true);
    await certificateHandlersFactory.printCertificate();
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      getUrl();
    }
  }, [open]);

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  if (!open) {
    return null;
  }
  return (
    <Container>
      <Box>
        <Content>
          <BackButton
            onClick={onClose}
            color={universityColor}
            marginBotton={16}
            marginLeft="0"
          >
            <IoIosArrowBack />
            <p>Voltar</p>
          </BackButton>

          <div className="container">
            <div className="WrapperPdf">
              {url && (
                <StyledDocument file={url}>
                  <Page pageNumber={1} />
                </StyledDocument>
              )}
            </div>

            <ContainerDownload>
              <h4>Ações</h4>
              <span>Imprima ou baixe o seu certificado.</span>

              <div className="actions">
                <Button
                  onClick={download}
                  width="100%"
                  disabled={loading}
                  className={`${loading && 'disabled'}`}
                >
                  Baixar certificado
                </Button>
                <Button
                  onClick={print}
                  theme="unfilled"
                  width="100%"
                  disabled={loading}
                  className={`${loading && 'disabled'}`}
                >
                  Imprimir
                </Button>
              </div>
            </ContainerDownload>
          </div>
        </Content>
      </Box>
    </Container>
  );
}
