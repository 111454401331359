import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: ${(props) => (props.width ? props.width + 'px' : '421px')};
  height: ${(props) => (props.height ? props.height + 'px' : '368px')};
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const WrapperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3.5em;
  width: 70%;
  height: 20%;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 1.5em;
  border-radius: 4px;
  width: 110px;
  height: 49px;
  position: relative;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 10.5px solid transparent;
    border-right: 10.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-top: 10.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-bottom: 10.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 13px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #ffff;
`;

export const ParagraphStyled = styled.p`
  margin-top: 30px;
  max-width: 330px;
  line-height: 25px;
  font-size: 20px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  hyphens: auto;
`;

export const Button = styled.button`
  width: ${(props) => (props.width ? props.width + 'px' : '215px')};
  height: ${(props) => (props.height ? props.height + 'px' : '50px')};
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-top: 30px;
  font-size: 18px;
  font-weight: 500;
  font-family: 'Roboto';
  border: none;
  border-radius: 4px;
  color: #ffff;
  cursor: pointer;

  &:active {
    transform: scale(0.9);
  }
`;
