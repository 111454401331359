import styled from 'styled-components';

export const ContainerCards = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

export const SearchAndCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
  }
`;
