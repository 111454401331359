import styled from 'styled-components';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';

export const WrapperSteps = styled.div`
  width: 70%;
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 10rem;
`;

export const WrapperStepIndicator = styled.div`
  height: 40px;
  width: 70%;
  display: flex;
  margin-top: 60px;
`;

export const HeaderStep = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 50px;
  margin-bottom: 10px;
`;

export const TitleRegister = styled.h5`
  font-weight: 500;
  color: #009291;
`;

export const InputSearch = styled.input`
  width: 100%;

  border: 1px solid #c4c4c4;
  padding: 14px;
  border-radius: 4px;
`;

export const ContainerProfessions = styled.div`
  width: 100%;
  margin-top: 10px;
  max-height: 150px;
  border-radius: 4px 4px 0 0;
  overflow: scroll;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  overflow-x: hidden;
`;

export const ProfessionsP = styled.p`
  padding: 10px;
  cursor: pointer;
  margin: 0;
  &:hover {
    background-color: #f5f5f5;
  }
`;

export const ContainerCreateNewProfession = styled.p`
  border-top: 1px solid #efeeec;
  background-color: #ffff;
  cursor: pointer;
  border-radius: 0 0 4px 4px;
  padding: 10px;
  color: #009291;
  &:hover {
    background-color: #009291;
    color: #ffff;
  }
`;

export const WrapperCourses = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px;
  min-width: 100%;
  min-height: auto;
  margin-top: 10px;

  button {
    border: 1px solid #c4c4c4;
    border-radius: 16px;
    padding: 3px 5px;
    max-height: 31px;
    color: #009291;
    background-color: #ffff;
    cursor: pointer;
  }
`;

export const P = styled.p`
  border: 1px solid #c4c4c4;
  border-radius: 16px;
  padding: 3px 5px;
  width: auto;
  height: auto;
`;

export const SpanRemove = styled.span`
  margin-left: 5px;
  color: #009291;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
`;
export const WrapperDates = styled.div`
  display: flex;
  gap: 8px;
`;
export const Title = styled.h5`
  color: #6d6d6d;
  font-size: 15px;
  margin-left: 2px;
  margin-bottom: -5px;
  font-weight: 600;
`;

export const WrapperMail = styled.div`
  margin-top: 30px;
`;

export const WrapperSelectiveProcess = styled.div`
  margin-top: 30px;

  h5 {
    color: #6d6d6d;
    font-size: 15px;
    font-weight: 600;
  }
`;

export const ContainerSelectiveProcess = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const WrapperStepOne = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const WrapperGlobal = styled.div`
  margin-top: 30px;
`;
export const SalaryWrapper = styled.div`
  display: flex;
  gap: 12px;
`;

export const ContainerCity = styled.div`
  display: flex;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;

  > label {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.2;
    color: #6d6d6d;
    box-sizing: border-box;
    cursor: pointer;
  }

  > input[type='checkbox'] {
    height: 14px;
    width: 14px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const ContainerSpecificsUniversities = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  gap: 10px;

  > p {
    margin: 0;
    color: #666666;
    font-size: 14px;
  }
`;
export const FooterForm = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  > div:only-child,
  > button:only-child {
    margin-left: auto;
  }
`;

export const CompletedButton = styled.button`
  width: 100%;
  max-width: 116px;

  height: 45px;

  font-size: 18px;
  font-weight: 700;
  text-align: center;

  border: none;
  border-radius: 4px;

  color: #ffffff;
  background-color: #009291;

  cursor: pointer;

  &:disabled {
    background-color: #c8c8c8;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  max-width: 340px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputDescNewJobText = styled.div`
  label {
    font-size: 15px;
  }
  margin-bottom: 32px;
`;
