export function ExclamationIcon({ size = 17, color = '#FFF', ...props }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 5 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66893 14.2111C4.66893 15.2991 3.78595 16.1821 2.69799 16.1821C1.61003 16.1821 0.727051 15.2991 0.727051 14.2111C0.727051 13.1232 1.61003 12.2402 2.69799 12.2402C3.78595 12.2402 4.66893 13.1232 4.66893 14.2111ZM4.66893 2.38549V8.9553C4.66893 10.0433 3.78727 10.9262 2.69799 10.9262C1.60872 10.9262 0.727051 10.0433 0.727051 8.9553V2.38549C0.727051 1.29753 1.60872 0.414551 2.69799 0.414551C3.78727 0.414551 4.66893 1.29753 4.66893 2.38549Z"
        fill={color}
      />
    </svg>
  );
}
