import React, { useContext, useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import Input from '../../Form/input';
import {
  Wrapper,
  DescImg,
  MultiStepContainer,
  Img,
  Title,
  SubTitle,
  AboutTitle,
  Abount,
  FormRow,
  Documents,
  Button,
} from '../../../Styles/formStyled';
import { Form } from '@unform/web';
import OportunidadesGroup from '../../../../../../assets/img/OportunidadesGroup.svg';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import FooterForm from '../../FooterForm';
import InputDesc from '../../Form/inputDesc';
import FileInput from '../../Form/fileInput';
import DatePickerCustom from '../../Form/datePicker';

import {
  modalityOptions,
  conditionOptions,
  ExtesionOptions,
  PublicationFormat,
} from '../../../Resources/SelectedOptions';
import SearchableSelectUn from '../../Form/searchableSelect';
import LinkInput from '../../Form/linkInput';
import RModal from '../../reutilizableModal';
import Videos from '../../Form/componentVideos';
import StepIndicator from '../../StepIndicator';
import InformationModal from '../../InformationModal';
import InputWorkload from '../../Form/inputWorkload';
import FileInputPdf from '../../Form/pdfInput';

export default function StepTwo({ nextFormStep, prevsFormStep }) {
  const formRef = useRef();

  const Titles = [
    { id: 'event', title: 'Sobre o Evento' },
    { id: 'course', title: 'Sobre o Curso' },
    { id: 'program', title: 'Sobre o Programa' },
  ];

  const { formData, setFormValues, modalIsOpen } = useContext(
    OportunitiesContext
  );
  const [condition, setCondition] = useState(true);
  const [validate, setValidate] = useState(false);
  const [publicationFormat, setPublicationFormat] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [noHasName, setNoHasName] = useState(false);
  const [title, setTitle] = useState('Adicionar vídeos');
  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const handleSubmit = (data, { reset }) => {
    if (data.name === '') {
      setModalInformationOpen(true);
      setNoHasName(true);

      return;
    } else {
      setNoHasName(false);
    }
    setFormValues({
      ...data,
      workload: data.workload ? data.workload.replace(':', '.') : null,
    });
    nextFormStep();
    reset();
  };

  const topDiv = useRef();
  const topModal = useRef();

  const handleCondition = (e) => {
    const event = e.value;

    setCondition(event);
  };

  const handleValidation = (e) => {
    const event = e.value;
    setValidate(event);
  };

  const handlePublicationFormat = (e) => {
    const event = e.value;
    setPublicationFormat(event);
    if (formData.type_access) {
      delete formData.type_access;
    }
  };

  const returnTitle = () => {
    const Title = Titles.filter((Title) => Title.id === formData.type);
    return (Title[0] && Title[0].title) || '';
  };

  const handleModal = (e) => {
    e.preventDefault();
    setModalOpen(!modalOpen);
  };

  const ScrollToModalTop = () => {
    if (topModal.current) {
      topModal.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      return;
    }
  };

  const ScrollToTopDiv = () => {
    if (topDiv.current) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else {
      return;
    }
  };

  useEffect(() => {
    if (modalOpen === true) {
      ScrollToTopDiv();
      return;
    }
  }, [modalOpen]);

  useEffect(() => {
    if (formData.type_access) {
      setPublicationFormat(formData.type_access);
    }
    if (formData) {
      setCondition(formData.is_free);
    }
    if (formData.validity) {
      setValidate(formData.validity);
    }

    if (formData.content_pages) {
      setTitle('Editar vídeos');
    }
  }, [formData]);

  useEffect(() => {
    if (modalInformationOpen === true) {
      ScrollToModalTop();
    }
  }, [modalInformationOpen]);

  const returnConditionArray = [{ validity: false }, { validity: 'false' }];

  const returnCondition = () => {
    return returnConditionArray.find((item) => item.validity === condition);
  };

  return (
    <Fragment>
      <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
        <div ref={topModal} />
        <InformationModal
          isOpen={modalInformationOpen}
          type={'error'}
          setModalOpen={setModalInformationOpen}
          informationHeader={
            'Ooops! Existe uma informação pendente no formulário.'
          }
          errorMsg={
            'Preencha o nome da oportunidade de aprendizagem para seguir em frente'
          }
        />
        <MultiStepContainer>
          <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
        </MultiStepContainer>
        <Wrapper ref={topDiv}>
          <DescImg>
            <Img src={OportunidadesGroup} />
            <Title>Eventos e cursos</Title>
          </DescImg>

          <Abount>
            <AboutTitle>{returnTitle()}</AboutTitle>
            <Input name="name" placeholder="Digite aqui..." label="Nome *" />
            {noHasName && (
              <span style={{ color: '#f00' }}>
                Responda para seguir em frente
              </span>
            )}
            <InputDesc
              name="description"
              placeholder="Digite aqui..."
              label="Descrição"
              modalIsOpen={modalIsOpen}
            />

            <Title
              style={{ color: '#6d6d6d', fontWeight: 'bold', fontSize: '18px' }}
            >
              Imagem de destaque <br />{' '}
              <SubTitle>
                Adicione uma imagem em formato JPEG ou PNG com dimensão de 1600
                X 838
              </SubTitle>
            </Title>
            <FileInput
              name="attraction_image"
              accept="image/png, image/jpeg"
              formRef={formRef}
            />
          </Abount>
        </Wrapper>

        <hr />
        <FormRow>
          <SearchableSelectUn
            name="modality"
            options={modalityOptions}
            label="Modalidade"
            style={{ padding: '0px 16px', height: '2.3em' }}
          />

          <DatePickerCustom
            name="deadline_registration"
            label="Data inicial"
            placeholder="Ex: 15/02/2022"
            minDate={new Date()}
          />
        </FormRow>
        <FormRow>
          <SearchableSelectUn
            name="is_free"
            options={conditionOptions}
            label="Condição"
            onChange={handleCondition}
            style={{ padding: '0px 16px', height: '2.3em' }}
          />
          {returnCondition() && (
            <Input
              name="discount_ticket"
              label="Cupom de desconto"
              style={{ padding: '10px 16px' }}
            />
          )}
        </FormRow>

        <FormRow>
          <SearchableSelectUn
            name="validity"
            options={ExtesionOptions}
            label="Validade"
            onChange={handleValidation}
            style={{ padding: '0px 16px', height: '2.3em' }}
          />

          {validate && (
            <InputWorkload
              name="workload"
              label="Carga horaria"
              placeholder={'Ex: 40:00'}
              style={{ padding: '10px 16px' }}
            />
          )}
        </FormRow>

        <Documents>
          <SearchableSelectUn
            name="type_access"
            onChange={handlePublicationFormat}
            options={PublicationFormat}
            label="Formato de publicação"
          />
          {publicationFormat === 'access_link' ? (
            <LinkInput
              style={{ marginTop: '20px' }}
              name="access_link"
              placeholder="Digite seu link aqui"
            />
          ) : publicationFormat === 'attraction_document' ? (
            <div style={{ marginTop: '20px' }}>
              <FileInputPdf name="attraction_document" accept=".pdf" />
            </div>
          ) : (
            publicationFormat === 'video' && (
              <div>
                {formData.Videos && (
                  <div>
                    {formData.content_pages.content_videos.map((value) => (
                      <div>{value.name}</div>
                    ))}
                  </div>
                )}

                <Button onClick={(e) => handleModal(e)}>{title}</Button>
                <RModal isOpen={modalOpen} setModalOpen={setModalOpen}>
                  <Videos setModalOpen={setModalOpen} />
                </RModal>
              </div>
            )
          )}
        </Documents>
        <FooterForm
          haveNext={true}
          haveBack={true}
          prevsState={prevsFormStep}
        />
      </Form>
    </Fragment>
  );
}
