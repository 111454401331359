import styled from 'styled-components';

export const MultiStepDivActive = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  color: ${(props) => props.color};
  font-weight: bold;
  border-radius: 50%;
  z-index: 2;
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => props.opacity};

  @media (max-width: 769px) {
    width: 16px;
    height: 16px;
    margin-left: 0;
  }
`;

export const Container = styled.div`
  width: 100px;
  height: 5px;
  margin-left: ${(props) => props.marginLeft};
  margin-top: 50px;
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => props.opacity};

  @media (max-width: 769px) {
    display: none;
  }
`;

export const WrapperStep = styled.div`
  min-width: 425px;
  display: flex;
  justify-content: 'start';
  align-items: start;
  position: relative;
  margin-bottom: 20px;
  padding-left: 20px;

  @media (max-width: 769px) {
    min-width: 60px;
    padding-left: 0;
    margin-bottom: 0;
    gap: 12px;
  }
`;
