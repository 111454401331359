import React, { Fragment, useRef, useEffect } from 'react';

import {
  ModalBackground,
  DivModal,
  Header,
  ButtonX,
  DivX,
  Contect,
} from './styles';

import { RiCloseFill } from 'react-icons/ri';

export default function ImpactedPeopleModal({ item, setIsOpenModal }) {
  const ImpactedPeopleModalUseRef = useRef();

  const closeModalClickOutside = (e) => {
    if (ImpactedPeopleModalUseRef.current === e.target) {
      setIsOpenModal(false);
    }
  };

  const closeModalX = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <ModalBackground
        ref={ImpactedPeopleModalUseRef}
        onClick={closeModalClickOutside}
      >
        <DivModal>
          <div className="container-wrapper ">
            <Header>
              <p>Público Impactado</p>
            </Header>

            <DivX>
              <ButtonX onClick={closeModalX}>
                <RiCloseFill size={25} />
              </ButtonX>
            </DivX>
            <Contect>
              {item.extension_project.finished_information.external_public && (
                <>
                  <h4>Público externo</h4>
                  {item.extension_project.finished_information.external_public}
                </>
              )}
              {item.extension_project.finished_information.impacted_public && (
                <>
                  <h4>Público Impactado</h4>
                  {item.extension_project.finished_information.impacted_public}
                </>
              )}
            </Contect>
          </div>
        </DivModal>
      </ModalBackground>
    </Fragment>
  );
}
