import React from 'react';

import { Wrapper } from './style';

import arrowLeft from 'assets/img/arrowLeft.svg';
import arrowRight from 'assets/img/arrowRight.svg';

export default function PaginationTotal(props) {
  const {
    onPageChange,
    totalCount,
    currentPage,
    pageSize,
    setLimit,
    showLimit = true,
    pageRef,
    limit,
  } = props;

  const handeClickNext = () => {
    onPageChange(currentPage + 1);
    scrollToTop();
  };

  const handeClickPrevs = () => {
    onPageChange(currentPage - 1);
    scrollToTop();
  };

  const scrollToTop = () => {
    if (pageRef) {
      pageRef.current.scrollIntoView();
    }
  };

  const totalPages = Math.ceil(totalCount / limit);

  const visible = totalPages > 1;

  return (
    <Wrapper visible={visible}>
      <div className="pagination-total-container">
        {currentPage > 1 && (
          <span
            className="pagination-total-arrow-left"
            onClick={handeClickPrevs}
          >
            <img src={arrowLeft} />
          </span>
        )}

        <span className="pagination-total-active-page">{currentPage}</span>
        <span className="pagination-total-pipe">/</span>
        <span className="pagination-total-total">{totalPages}</span>

        {currentPage < totalPages && (
          <span
            className="pagination-total-arrow-right"
            onClick={handeClickNext}
          >
            <img src={arrowRight} />
          </span>
        )}
      </div>
    </Wrapper>
  );
}
