export const jobType = [
  { value: 'full_time_job', label: 'Emprego em tempo integral' },
  { value: 'part_time_job', label: 'Emprego de meio período' },
  { value: 'consultant', label: 'Prestador de Serviços' },
  { value: 'mandatory_internship', label: 'Estágio obrigatório' },
  { value: 'non_mandatory_internship', label: 'Estágio não obrigatório' },
  { value: 'apprentice', label: 'Jovem Aprendiz' },
  { value: 'trainee', label: 'Trainee' },
  { value: 'exchange_program', label: 'Intercâmbio' },
  { value: 'scientific_program', label: 'Bolsa de iniciação científica' },
  { value: 'cooperative', label: 'Cooperado' },
];

export const jobBenefits = [
  { value: 'meal_allowance', label: 'Vale refeição' },
  { value: 'snack_allowance', label: 'Vale alimentação' },
  { value: 'kids_school', label: 'Auxílio-creche' },
  { value: 'transportation', label: 'Vale transporte' },
  { value: 'profit_participation', label: 'PLR' },
  { value: 'private_pension', label: 'Previdência privada' },
];

export const modalityType = [
  { value: 'hybrid', label: 'Híbrido' },
  { value: 'presential', label: 'Presencial' },
  { value: 'remote', label: 'Remoto' },
];

export const currency = [
  { value: 'real', label: 'Brasil - BRL R$', previewText: 'R$' },
  { value: 'dollar', label: 'EUA - USD $', previewText: '$' },
  { value: 'euro', label: 'Europa - EUR €', previewText: '€' },
  { value: 'libra', label: 'GB - GBP £', previewText: '£' },
];

export const courseProgress = [
  {
    label: 'Curso concluido',
    value: 100,
    previewText: 'Curso concluido',
  },
  {
    label: 'Acima de 80%',
    value: 80,
    previewText: 'A partir de 80% do curso realizado',
  },
  {
    label: 'Acima de 60%',
    value: 60,
    previewText: 'A partir de 60% do curso realizado',
  },
  {
    label: 'Acima de 30%',
    value: 30,
    previewText: 'A partir de 30% do curso realizado',
  },
  {
    label: 'Indiferente',
    value: 0,
    previewText: 'Indiferente',
  },
];
