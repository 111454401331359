import styled from 'styled-components';

export const Ul = styled.ul`
  list-style: none;
`;

export const Li = styled.li`
  display: flex;
  margin-bottom: 8px;
  max-width: 230px;

  &:before {
    content: '•';
    font-size: 55px;
    border-radius: 50%;
    color: ${(props) => props.color};
    padding-right: 5px;
  }
`;

export const CustomToolTip = styled.div`
  background-color: #ffff;
  border-radius: 4px;
  width: 60px;
  text-align: center;
  min-height: 30px;
  height: auto;
  padding: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  label {
    color: #009291;
    font-size: 15px;
    font-weight: 500;

    span {
      font-size: 12px;
      font-weight: 300;
    }
  }
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TotalStudents = styled.p`
  margin-top: 22px;
  font-weight: bold;
`;
