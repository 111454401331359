import styled from 'styled-components';
import selectedStar from 'assets/JobOffer/star-filled.svg';
import unselectedStar from 'assets/JobOffer/star-unfilled.svg';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #cdcdcd;
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;

  .checkbox {
    margin-left: 6px;
    padding-top: 10px;
    display: flex;
    justify-content: flex-start;
    border-radius: 6px;
    accent-color: #009291;
    color: #4b4b4d;
    font-size: 13px;
    gap: 8px;
    row-gap: 8px;
    white-space: nowrap;
    z-index: 2;
  }

  .checkbox input[type='checkbox'] {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const BoxProgressBar = styled.div`
  margin-left: 20px;
  max-width: 90px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxInformationArea = styled.div`
  margin: 20px 23px;
  width: 100%;
  max-width: 440px;
  color: #000;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  ${({ margin }) => (margin ? 'margin-top: 20px' : 'margin-top: 27px')};
  gap: 4px;

  strong {
    font-size: 19px;
    color: #009291;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #606062;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  p {
    font-size: 12px;
    color: #949494;
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 0;
    font-weight: 400;
  }

  .liked-company {
    background: #efefef;
    color: #606062;
    font-size: 11.5px;
    padding: 2px 6px;
    gap: 3px;

    display: flex;
    align-items: center;

    border-radius: 4px;

    margin-bottom: 9px;
  }

  .indicationMessage {
    background: #0092911c;
    color: #009291;
    display: flex;
    padding: 8px 10px;
    span {
      font-size: 14px;
      line-height: 15px;
      color: #009291;
      font-weight: 400;
    }
    svg {
      color: #009291;
      font-size: 20px;
      margin-right: 4px;
    }
  }
`;

const statusBackgroundColor = {
  unscheduled: '#F8F0E7',
  scheduled: '#0092911C',
  rescheduled: '#F8F2E7',
  canceled: '#FFEDED',
};

const statusColor = {
  unscheduled: '#F09D51',
  scheduled: '#009291',
  rescheduled: '#E7B855',
  canceled: '#DA0505',
};

export const StatusArea = styled.div`
  background-color: ${({ color }) => statusBackgroundColor[color]};
  color: ${({ color }) => statusColor[color]};

  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 11px;
  padding: 2px 10px;
  border-radius: 3px;
  font-weight: 400;

  @media (max-width: 990px) {
    font-size: 10px;
  }
`;

export const WrapperInformation = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
  text-transform: capitalize;

  .box-icon {
    background: #00929126;
    width: 24px;
    height: 24px;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
  }

  > p {
    font-size: 13px;
    font-weight: 400;
    color: #606062;
    margin: 0;
    line-height: 16px;
  }

  @media (max-width: 1505px) {
    font-size: 11px;
  }
`;

export const WrapperReactions = styled(WrapperInformation)`
  .box-icon {
    background-color: ${({ liked }) => (liked ? '#0092911c' : '#cf00001c')};
  }
  p {
    text-transform: math-auto;
    display: block;
  }

  button {
    background-color: transparent;
    border: none;
    text-decoration: underline;
    color: #009291;
    font-weight: 700;
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 1px;
  background-color: #cdcdcd;
  height: 110px;
`;

export const ContainerButtons = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px;
    gap: 15px;

    height: 100%;
  }

  .button-area {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
  }

  .align-divider {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;
  }

  @media (max-width: 1238px) {
    .button-area {
      flex-direction: column;
    }

    .content {
      padding: 13px 32px;
    }
  }

  @media (max-width: 1055px) {
    .content {
      padding: 13px 20px;
    }
  }
`;
export const ContainerInfos = styled.div`
  display: flex;
  width: 50%;

  @media (max-width: 1220px) {
    width: auto;
  }
`;

const getBgColor = {
  curriculum: '#009291',
  interview: '#fff',
};

const getColor = {
  curriculum: '#fff',
  interview: '#009291',
};

export const SimpleButton = styled.button`
  height: 40px;

  background-color: ${({ type }) => getBgColor[type]};
  padding: 10px;
  color: ${({ type }) => getColor[type]};
  border: 1px solid ${({ type }) => type === 'interview' && '#009291'};
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  :disabled {
    border: 1px solid #cecece;
    color: #cecece;
    cursor: default;
  }

  @media (max-width: 1238px) {
    width: 179px;
  }
`;

export const RatingContainer = styled.div`
  display: flex;
  gap: 6px;
  color: #606062;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  span {
    display: flex;
    align-items: center;
    gap: 5px;
    text-align: center;
  }

  @media (max-width: 1238px) {
    span {
      font-size: 12px;
    }
  }
`;

export const StarIcon = styled.div`
  height: 14px;
  width: 14px;
  background-image: url(${(props) =>
    props.selected ? selectedStar : unselectedStar});
  background-repeat: no-repeat;
  background-position: center;
`;
