import React from "react";
import { func, bool, object } from "prop-types";

import { Drawer } from "@material-ui/core";
import NotificationsList from "./NotificationsList";

const Notifications = props => {
  const {
    state,
    toggleNotificationDrawer,
    notifications,
    nextNotifications,
    showNextNotifications,
    handleShowNextNotifications
  } = props;

  return (
    <Drawer anchor="right" open={state} onClose={toggleNotificationDrawer}>
      <NotificationsList
        notifications={notifications}
        nextNotifications={nextNotifications}
        showNextNotifications={showNextNotifications}
        handleShowNextNotifications={handleShowNextNotifications}
        toggleNotificationDrawer={toggleNotificationDrawer}
      />
    </Drawer>
  );
};

Notifications.propTypes = {
  state: bool,
  toggleNotificationDrawer: func,
  notifications: object,
  nextNotifications: object,
  showNextNotifications: bool,
  handleShowNextNotifications: func
};

export default Notifications;
