import { ContentCircularProgress } from './styled';

export function CircularProgressBar({ match, totalWidth = 160 }) {
  const radius = 60;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * match) / 100;
  const circleWidth = 200;

  return (
    <ContentCircularProgress match={match} totalWidth={totalWidth}>
      <svg
        width={circleWidth}
        height={circleWidth}
        viewBox={`0 0 ${circleWidth} ${circleWidth}`}
      >
        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="15px"
          r={radius}
          className="circle-background"
        />

        <circle
          cx={circleWidth / 2}
          cy={circleWidth / 2}
          strokeWidth="15px"
          r={radius}
          className="circle-progress"
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
          transform={`rotate(90 ${circleWidth / 2} ${circleWidth / 2})`}
        />

        {match === 100 && (
          <svg
            width="23px"
            height="24px"
            x="44%"
            y="33%"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.70556 3.13022C9.0491 2.57264 8.3704 1.99692 7.68937 1.31647L7.0645 0.693359L6.4449 1.31706C5.51677 2.33077 4.85053 3.55599 4.50418 4.88606C4.4954 4.85563 4.48721 4.82755 4.47902 4.7942L4.10808 3.35665L3.02743 4.37118C1.94211 5.38864 0.939278 6.6062 0.938693 8.61069C0.92728 9.96408 1.36845 11.2825 2.19212 12.3564C3.01579 13.4304 4.17471 14.1983 5.48478 14.5381C5.99925 14.6686 6.52789 14.7348 7.05865 14.7353C7.86352 14.7366 8.66074 14.5791 9.40469 14.2719C10.1486 13.9647 10.8247 13.5138 11.3942 12.945C11.9637 12.3763 12.4155 11.7008 12.7237 10.9573C13.0319 10.2137 13.1905 9.41673 13.1903 8.61186C13.1903 6.08898 11.5749 4.71755 9.70556 3.13022ZM9.74593 12.0592C9.68215 12.1083 9.61428 12.1493 9.54875 12.1949C9.68366 11.8698 9.75324 11.5213 9.75353 11.1692C9.75353 9.68373 8.40785 9.09104 7.06216 7.74652C5.59536 9.21332 4.37078 9.68373 4.37078 11.1692C4.37345 11.542 4.45396 11.9101 4.60716 12.2499C4.01482 11.8464 3.53081 11.3034 3.19779 10.6688C2.86476 10.0342 2.69293 9.32737 2.69745 8.61069C2.68259 7.88186 2.91731 7.16985 3.36269 6.59274C3.42646 6.69337 3.49374 6.7905 3.56512 6.88528C3.71805 7.09181 3.928 7.24914 4.16917 7.33791C4.41033 7.42669 4.67218 7.44303 4.92251 7.38494C5.17645 7.32826 5.40867 7.19962 5.5914 7.01441C5.77412 6.82919 5.89961 6.59524 5.95284 6.34057C6.15972 5.23999 6.56473 4.18613 7.14816 3.23027C7.64197 3.6843 8.12525 4.09444 8.56757 4.47065C10.4504 6.06851 11.4345 6.97889 11.4345 8.61186C11.436 9.27743 11.2845 9.93446 10.9917 10.5322C10.699 11.1299 10.2727 11.6524 9.74593 12.0592Z"
              className="fire-icon"
            />
          </svg>
        )}

        <g
          transform={match === 100 ? 'translate(0, 0)' : 'translate(0, -13)'}
          className="text-group"
        >
          <text
            x="50%"
            y="54%"
            dy="0.3em"
            textAnchor="middle"
            className="circle-text"
          >
            {match}%
          </text>
          <text
            x="50%"
            y="63%"
            dy="0.3em"
            textAnchor="middle"
            className="circle-text-match"
          >
            MATCH
          </text>
        </g>
      </svg>
    </ContentCircularProgress>
  );
}
