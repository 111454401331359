import React, { Fragment, useState, useEffect } from 'react';
import {
  HeaderContainer,
  HeaderInfos,
  ListContainer,
  ButtonEdit,
  PaginationStyle,
} from '../style';
import DropDownAssociated from './dropDown';
import academicInnovationApi from 'views/Backoffice/academicInnovation/services/academicInnovationApi';
import { useDebounce } from 'hooks/useDebounce';
import { useFormContext } from 'views/Backoffice/academicInnovation/contexts';
import Pagination from 'components/CustomPagination';
import {
  searchQuery,
  searchPage,
} from 'views/Backoffice/academicInnovation/Screens/Tabs/utils/queryString';
import AssociateTagUniversityForm from '../../Forms/AssociateTagUniversityForm';
import GenericMessage from 'components/GenericMessage';
import { CircularProgress } from '@material-ui/core';

export default function AssociatedComponent({
  countAssociatedTag,
  setCountAssociatedTag,
}) {
  const {
    queryFilter,
    searchTeam,
    associatedTags,
    setAssociatedTags,
    setCurrentFormToShow,
    limit,
    setLimit,
    isListEmpty,
    setListEmpty,
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
  } = useFormContext();
  const debouncedSearchTerm = useDebounce(searchTeam, 600);

  const listAssociatedTags = async (searchTeam) => {
    setIsLoading(true);

    const {
      results,
      count,
    } = await academicInnovationApi.getAssociatedTagsList(searchTeam);
    setAssociatedTags(results);
    setCountAssociatedTag(count);
    if (results.length === 0) {
      setListEmpty(true);
    } else {
      setListEmpty(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const search = searchQuery(limit, queryFilter, debouncedSearchTerm);
    listAssociatedTags(search);
    setCurrentPage(1);
  }, [debouncedSearchTerm]);

  const handlePageChange = async (page) => {
    const newPage = searchPage(page, limit, queryFilter, debouncedSearchTerm);
    await listAssociatedTags(newPage);
    setCurrentPage(page);
  };

  const handleLimitChange = async (newLimit) => {
    const query = searchQuery(newLimit, queryFilter, debouncedSearchTerm);
    setCurrentPage(1);
    await listAssociatedTags(query);
    setLimit(newLimit);
  };

  const handleClickEdit = (item) => {
    setCurrentFormToShow(
      <AssociateTagUniversityForm
        item={item}
        nameFromEdit={item.principal_tag}
      />
    );
  };

  return (
    <>
      <Fragment>
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '50px',
            }}
          >
            <CircularProgress style={{ color: '#009291' }} />
          </div>
        ) : countAssociatedTag === 0 ? (
          <GenericMessage
            title="Nenhuma TAG Associada foi encontrada"
            subtitle="Associe novas TAGs. "
          />
        ) : (
          <div>
            <HeaderContainer>
              <HeaderInfos>
                <span className="header-name-tag">NOME DA TAG</span>
                <span className="header-institution"> INSTITUIÇÃO</span>
              </HeaderInfos>
            </HeaderContainer>

            {associatedTags.map((item, index) => (
              <ListContainer key={index}>
                <DropDownAssociated item={item} />
                <span className="list-institution">{item.university}</span>
                <ButtonEdit onClick={() => handleClickEdit(item)}>
                  Editar
                </ButtonEdit>
              </ListContainer>
            ))}
          </div>
        )}
      </Fragment>
      <PaginationStyle>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={countAssociatedTag}
          onPageChange={handlePageChange}
          setLimit={handleLimitChange}
          showLimit={countAssociatedTag > 10}
          setCurrentPage={setCurrentPage}
          pageRef={null}
          limit={limit}
        ></Pagination>
      </PaginationStyle>
    </>
  );
}
