import React from 'react';
import GenericContainerReportsStudents from './GenericContainerReportsStudents';

import HeaderReports from '../HeaderReports';

function ReportsStudents(props) {
  const { InsightList } = props;

  return (
    <div>
      <HeaderReports />

      {InsightList.studentsPlatformActivesCurriculum.map((item) => (
        <GenericContainerReportsStudents
          title={item.name}
          enrolled={item.enrolled}
          done={item.done}
          total={item.total}
          careerObjective={false}
        />
      ))}

      {InsightList.studentActuationWorkCareer.map((item) => (
        <GenericContainerReportsStudents
          title={item.name}
          enrolled={item.enrolled}
          notWorkingInFormationArea={item.notWorkingInFormationArea}
          workingInFormationArea={item.workingInFormationArea}
          done={item.done}
          total={item.total}
          careerObjective={item.career_objectives}
        />
      ))}
    </div>
  );
}

export default ReportsStudents;
