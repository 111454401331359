import { Container, WhiteBox } from './styled';
import InternalTab from './screens/Tabs';
import { ContextProvider } from './context';

export function NextMentorshipTab() {
  return (
    <ContextProvider>
      <Container>
        <WhiteBox>
          <InternalTab />
        </WhiteBox>
      </Container>
    </ContextProvider>
  );
}
