import React, { Fragment, useEffect, useState } from 'react';
import { number, object, func } from 'prop-types';
import CardComposition from 'components/CardComposition/CardComposition';
import { getCourseDetail } from 'services/backoffice/courses';
import { withStyles } from '@material-ui/core/styles';
import jobsPositionDetailsStyles from 'assets/jss/material-dashboard-pro-react/views/components/jobsPositionDetailsStyles';

function CourseDetail(props) {
  const { classes, courseId, loadingState, openSnackbar, data } = props;
  const [courseDetail, setCourseDetail] = useState({});

  useEffect(() => {
    if (data) {
      setCourseDetail(data);
      loadingState(false);
    } else {
      loadingState(true);
      fetchCourseDetail();
    }
  }, []);

  const fetchCourseDetail = async () => {
    const response = await getCourseDetail(courseId);
    if (!response.message) {
      setCourseDetail(response);
      loadingState(false);
    } else {
      openSnackbar(
        'Falha ao carregar os detalhes do curso! Tente novamente mais tarde.',
        true
      );
    }
  };

  return (
    <div style={{ margin: '0 auto', maxWidth: 860 }}>
      {courseDetail.name ? (
        <CardComposition
          CardContent={() => (
            <Fragment>
              <strong
                style={{
                  color: '#009291',
                  fontSize: '20px',
                  margin: '20px 0',
                  display: 'block',
                }}
              >
                Dados do curso:
              </strong>

              <p className={classes.paragraph}>
                <strong>Nome do curso:</strong>
                <br />
                {courseDetail.name}
              </p>
              {courseDetail.macro_area.label && (
                <p className={classes.paragraph}>
                  <strong>Macro área:</strong>
                  <br />
                  {courseDetail.macro_area.label}
                </p>
              )}
              {courseDetail.course_level.label && (
                <p className={classes.paragraph}>
                  <strong>Nível do Curso:</strong>
                  <br />
                  {courseDetail.course_level.label}
                </p>
              )}

              <p className={classes.paragraph}>
                <strong
                  style={{
                    color: '#009291',
                    fontSize: '20px',
                    marginTop: '20px',
                    display: 'block',
                  }}
                >
                  Dados de estágio:
                </strong>
                <br />
                <strong>Carga horária diária máxima:</strong>
                <br />
                {courseDetail.max_daily_internship_workload}h
                <br />
                <strong>Carga horária semanal máxima:</strong>
                <br />
                {courseDetail.max_weekly_internship_workload}h
              </p>

              {courseDetail.areas.additional_courses.length > 0 && (
                <p className={classes.paragraph}>
                  <strong
                    style={{
                      color: '#009291',
                      fontSize: '20px',
                      marginTop: '20px',
                      display: 'block',
                    }}
                  >
                    Requisitos de Mercado:
                  </strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {courseDetail.areas.additional_courses.map((area) => (
                        <li key={area.value}>{area.label}</li>
                      ))}
                    </ul>
                  </span>
                </p>
              )}
              {courseDetail.areas.market_tendencies.length > 0 && (
                <p className={classes.paragraph}>
                  <strong
                    style={{
                      color: '#009291',
                      fontSize: '20px',
                      marginTop: '20px',
                      display: 'block',
                    }}
                  >
                    Tendências de Mercado:
                  </strong>
                  <br />
                  <span className={classes.capitalize}>
                    <ul className={classes.behaviorList}>
                      {courseDetail.areas.market_tendencies.map((area) => (
                        <li key={area.value}>{area.label}</li>
                      ))}
                    </ul>
                  </span>
                </p>
              )}
            </Fragment>
          )}
        />
      ) : (
        <CardComposition
          CardContent={() => <div>Não há detalhes para esta área.</div>}
        />
      )}
    </div>
  );
}
CourseDetail.propTypes = {
  classes: object.isRequired,
  courseId: number.isRequired,
  loadingState: func.isRequired,
  openSnackbar: func.isRequired,
  data: object,
};
export default withStyles(jobsPositionDetailsStyles)(CourseDetail);
