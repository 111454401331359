import React, { createContext, useState, useEffect } from 'react';

export const LearningContext = createContext();

export default function LearningProvider({
  children,
  setFavoritesTabCount,
  setFinishedTabCount,
  setAvailableTabCount,
  setFilterState,
  setFilterOptions,
}) {
  const [learnings, setLearnings] = useState(null);
  const [learningsCount, setLearningsCount] = useState(null);
  const [currentPageLearnings, setCurrentPageLearnings] = useState(null);
  const [loading, setLoading] = useState(false);

  const [finalizedLearning, setFinalizedLearning] = useState(null);
  const [finalizedCount, setFinalizedCount] = useState(null);
  const [currentPageFinalized, setCurrentPageFinalized] = useState(null);

  const [favoritesLearning, setFavoritesLearning] = useState(null);
  const [favoritesCount, setFavoritesCount] = useState(null);
  const [currentPageFavorites, setCurrentPageFavorites] = useState(null);

  const [loadingFilters, setLoadingFilters] = useState(false);

  const [queryFilter, setQueryFilter] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({});

  return (
    <LearningContext.Provider
      value={{
        learnings,
        setLearnings,
        learningsCount,
        setLearningsCount,
        currentPageLearnings,
        setCurrentPageLearnings,
        loading,
        setLoading,
        finalizedLearning,
        setFinalizedLearning,
        finalizedCount,
        setFinalizedCount,
        currentPageFinalized,
        setCurrentPageFinalized,
        favoritesLearning,
        setFavoritesLearning,
        favoritesCount,
        setFavoritesCount,
        currentPageFavorites,
        setCurrentPageFavorites,
        loadingFilters,
        setLoadingFilters,
        setFavoritesTabCount,
        setFinishedTabCount,
        setAvailableTabCount,
        setQueryFilter,
        setAppliedFilters,
        setFilterState,
        setFilterOptions,
        queryFilter,
        appliedFilters,
      }}
    >
      {children}
    </LearningContext.Provider>
  );
}
