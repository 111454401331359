import styled from "styled-components";


export const MultiStepDivActive = styled.div`
  width: 30px;
  height: 30px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => props.color} ;
  font-weight: bold;
  
  border-radius: 50%;
  
  margin-left: 55px;
  
  z-index: 2;

  background-color: ${props => props.backgroundColor} ;
`;


export const Container = styled.div`
  width: 34%;
  height: 5px;
  
  margin-left: ${props => props.marginLeft};
  margin-top: 50px;
  
  background-color: ${props => props.backgroundColor} ;
`;

export const WrapperStep = styled.div`
  width: 100%;
 
  display: flex;
  justify-content: space-evenly;
 
  position: relative;

  margin-top: -50px;
`;