import React, { useContext, useState } from 'react';
import { ContainerExternalCards } from '../styled';

import GenericCardsJobOffers from '../Cards';
import { DetailsJobOffer } from '../../JobDetails';
import { ButtonSeeMore, ContainerButtonsPagination } from '../../styled';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

const ExternalOtherJobsComponent = ({
  universityColor,
  dataExternalOtherJobOffers,
  candidateJobOffer,
  jobIdCandidatureBeingProcessed,
  fetchListExternaOtherlJobs,
  listExternalOtherJobs,
  openDetailsModal,
  setOpenDetailsModal,
  selectJobId,
  setSelectJobId,
  onClickDetails,
  handleCloseModalExternal,
  selectUrl,
  selectJobEmail,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleMoreItems = async () => {
    try {
      setIsLoading(true);
      const query = listExternalOtherJobs.data.next.split('?');
      await fetchListExternaOtherlJobs(query[1], true);
    } finally {
      setIsLoading(false);
    }
  };

  const returnCardJobs = (dataExternalOtherJobOffers) => {
    if (dataExternalOtherJobOffers?.length >= 1) {
      return dataExternalOtherJobOffers.map((job) => (
        <div key={job}>
          <GenericCardsJobOffers
            code={job.id}
            name={job.name}
            companyName={job.company_name}
            location={job.location ? job.location : 'Não informado'}
            jobModality={job.modality}
            onClickDetailsButton={() =>
              onClickDetails(job.id, job.divulgation_link, job.job_email)
            }
            labelTwo={'Detalhes'}
            onClickButton={() =>
              candidateJobOffer(job.id, job.divulgation_link, job.job_email)
            }
            label={'Candidatar'}
            universityColor={universityColor}
            isExternalJobs={true}
            processingCandidature={job.id === jobIdCandidatureBeingProcessed}
          />
        </div>
      ));
    }
  };

  return (
    <>
      <ContainerExternalCards>
        <div id="external">{returnCardJobs(dataExternalOtherJobOffers)}</div>
      </ContainerExternalCards>

      {openDetailsModal && (
        <DetailsJobOffer
          handleCloseModalExternal={handleCloseModalExternal}
          universityColor={universityColor}
          jobId={selectJobId}
          onClickApplyToJobOffer={() =>
            candidateJobOffer(selectJobId, selectUrl, selectJobEmail)
          }
          candidatureBeingProcessed={jobIdCandidatureBeingProcessed !== null}
          isExclusive={true}
          isOtherExternal={true}
        />
      )}

      <ContainerButtonsPagination style={{ marginTop: '30px' }}>
        {listExternalOtherJobs?.data?.next && (
          <ButtonSeeMore
            universityColor={universityColor}
            onClick={handleMoreItems}
            filled
          >
            {isLoading ? (
              <FilterLoading width={'88px'} height={'16px'} />
            ) : (
              'Ver mais'
            )}
          </ButtonSeeMore>
        )}
      </ContainerButtonsPagination>
    </>
  );
};

export default ExternalOtherJobsComponent;
