import axios from 'axios';
import getCookieValue from '../../../utils/getCookieValue';
import URLS from '../../../constants/urls';
const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

const saveCompanyNew = async (companyData) => {
  const response = authenticatedAPI
    .post(`/api/internal/new-companies`, companyData)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });

  return response;
};

const postCreatePartialCompany = (companyData) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/partial/create`, companyData)
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const getPartialRegisterStatus = (organizationNumber) => {
  const response = authenticatedAPI
    .get(
      `/api/internal/companies/partial?organization_number=${organizationNumber}`
    )
    .then((res) => res)
    .catch((error) => error);

  return response;
};

const optionsMacroAreas = () => {
  let response = authenticatedAPI
    .get(`/api/internal/macro-areas/options`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getOptions = () => {
  let response = authenticatedAPI
    .options(`/api/internal/companies/staff/registration-options`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getPartialRegister = (organizationNumber) => {
  let response = authenticatedAPI
    .get(
      `/api/internal/companies/partial?organization_number=${organizationNumber}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getRegisterStatus = (organizationNumber) => {
  let response = authenticatedAPI
    .get(
      `/api/internal/companies/registry-status?organization_number=${organizationNumber}`
    )
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  saveCompanyNew,
  optionsMacroAreas,
  getOptions,
  postCreatePartialCompany,
  getPartialRegister,
  getRegisterStatus,
  getPartialRegisterStatus,
};
