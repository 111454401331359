import React, { useContext } from 'react';
import {
  ContainerInformations,
  ContentInformation,
  Information,
} from '../styled';
import { FaLink } from 'react-icons/fa';
import BaseLayoutContext from 'contexts/base-layout';
import { BiCalendarAlt } from 'react-icons/bi';
import { DateHelper } from '../../../Helpers/DateHelper';
import useMediaQuery from 'hooks/useMediaQuery';
import { LivestreamHelper } from '../../../Helpers/LivestreamHelper';
import { VisualizationHandler } from '../../../Handlers/VisualizationHandler';

function Online({ modalTicketData, visualizationContextInstance }) {
  const isMobile = useMediaQuery('(max-width: 960px)');
  const livestreamHelperFactory = LivestreamHelper();
  const { universityColor } = useContext(BaseLayoutContext);

  const { event_start_at, event_end_at, event_url, name } = modalTicketData;
  const isYoutubeUrl = livestreamHelperFactory.isYouTubeLink(event_url);

  const title = isMobile ? (
    <h5>{name}</h5>
  ) : (
    <h5>
      Ingresso: <span>{name}</span>
    </h5>
  );

  const informations = [
    {
      icon: (
        <div>
          <BiCalendarAlt color={universityColor} size={18} />
        </div>
      ),
      information: DateHelper.formatRange(event_start_at, event_end_at),
    },
  ];

  const createInformations = informations.map((item) => (
    <Information color={universityColor}>
      {item.icon}
      {item.information}
    </Information>
  ));

  const handleClickLink = () => {
    if (isYoutubeUrl) {
      VisualizationHandler.changeLivestreamModalOpen(
        visualizationContextInstance
      );
      return;
    }
    window.open(event_url, '_blank');
  };

  return (
    <>
      <ContentInformation align="start">
        {title}
        <ContainerInformations>{createInformations}</ContainerInformations>
      </ContentInformation>

      <ContentInformation>
        <Information color={universityColor}>
          <div>
            <FaLink color={universityColor} />
          </div>
          <p onClick={handleClickLink}>{event_url}</p>
        </Information>
      </ContentInformation>
    </>
  );
}

export default Online;
