import React, { useContext, useEffect, useState } from 'react';
import { ButtonsContainer, CallToAction, Container, ContainerRole, ContainerRow, Section, TableTitle } from './style';
import { IoIosArrowForward } from 'react-icons/io';
import mergeContextsHelper from 'utils/mergeContextsHelper';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import PortfolioProjectsForm from 'views/MyProfile/components/PortfolioProjects/PortfolioProjectsForm';
import { triggerEvent } from 'utils/analytics';
import { GenericButtons } from '../GenericButtons';
import { useDataManagement } from 'views/Profile/Hooks/useDataManagement';
import { deletePortfolioProject, getProjects } from 'services/student/portfolioProjects';
import ModalInformation from 'components/informationModal';
import { profileTexts } from 'views/Profile/Utils/profileTexts';
import { format, parse } from 'date-fns';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { actionsProfileStore } from 'stores/profile.store';

export function PortfolioContent(props) {
  const {
    cardData,
    cardDataKey,
    openModalWithContent,
    setUserData,
    handleIsLoadingState,
    shouldHideItem,
  } = props;

  const { updateCard } = actionsProfileStore;
  const { universityColor, fetchGamificationData, visibleInCurriculum } =
    useContext(ProfileContext);
  const [visibleProjects, setVisibleProjects] = useState([]);
  const [controlInformationModal, setControlInformationModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { handleVisibleItem } = useDataManagement(
    setUserData,
    setDisabled,
    disabled
  );

  const { configExcludeProjects } = profileTexts();

  useEffect(() => {
    setVisibleProjects(visibleInCurriculum.portfolio);
  }, [visibleInCurriculum]);

  const handleToggleVisibility = (item) => {
    let updatedVisibleProjects = [...visibleProjects];

    if (visibleProjects.includes(item.id)) {
      updatedVisibleProjects = updatedVisibleProjects.filter(
        (id) => id !== item.id
      );
    }

    if (!visibleProjects.includes(item.id)) {
      if (visibleProjects.length === 3) {
        return customSnackbar(
          `Seus três projetos relevantes já estão selecionados!`,
          'alert'
        );
      }
      updatedVisibleProjects = [...visibleProjects, item.id];
    }

    const visibilityItemConfig = {
      items: updatedVisibleProjects,
      item: item,
      reqKey: 'portfolio',
      isUpdate: true,
      cardData,
      dataKey: cardDataKey,
      updateKey: 'portfolio',
      setVisibleItems: setVisibleProjects,
    };
    handleVisibleItem(visibilityItemConfig);
  };

  const excludeProjects = async (projectId) => {
    setControlInformationModal(false);
    handleIsLoadingState(true);
    await deletePortfolioProject(projectId);
    const response = await getProjects();

    updateCard(cardDataKey, response);
    await fetchGamificationData();
    if (visibleProjects.includes(projectId)) {
      const updatedProjects = visibleProjects.filter((id) => id !== projectId);
      setVisibleProjects(updatedProjects);
      setUserData({ portfolio: updatedProjects });
    }

    handleIsLoadingState(false);
    customSnackbar('Curso extra excluído com sucesso!', 'confirmation');
  };

  const loadProject = ({
    knowledge,
    section,
    id,
    title,
    link,
    dedicated_time,
    finished_at,
    portfolio_classification,
    company_challenge,
  }) => ({
    knowledge,
    section,
    id,
    title,
    link,
    dedicatedTime: dedicated_time,
    finishedAt: finished_at
      ? format(parse(finished_at, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
      : '',
    classification: portfolio_classification,
    companyChallenge: company_challenge,
  });

  const callToPortfolioForm = () => {
    openModalWithContent(
      mergeContextsHelper(
        <PortfolioProjectsForm
          setVisibleProjects={setVisibleProjects}
          visibleProjects={visibleProjects}
          cardData={cardData}
          cardDataKey={cardDataKey}
          handleVisibleItem={handleVisibleItem}
          fetchGamificationData={fetchGamificationData}
          {...props}
        />
      ),
      'Adicionar Projeto',
      null,
      () => null,
      false,
      'portfolio'
    );
    triggerEvent('click', 'Adicionar Portfólio de Projetos', 'aluno');
  };

  const callToEditPortfolioForm = (item) => {
    openModalWithContent(
      mergeContextsHelper(
        <PortfolioProjectsForm
          isEdit
          projects={loadProject(item)}
          setVisibleProjects={setVisibleProjects}
          visibleProjects={visibleProjects}
          cardData={cardData}
          cardDataKey={cardDataKey}
          handleVisibleItem={handleVisibleItem}
          fetchGamificationData={fetchGamificationData}
        />
      ),
      'Editar Projeto',
      null,
      () => null,
      false,
      'portfolio'
    );
    triggerEvent('click', 'Adicionar Portfólio de Projetos', 'aluno');
  };

  return (
    <Container>
      <Section>
        {controlInformationModal && (
          <ModalInformation
            universityColor={universityColor}
            modalOpenState={controlInformationModal}
            config={configExcludeProjects}
            buttonConfirmFunction={() =>
              excludeProjects(controlInformationModal)
            }
            buttonCancelFunction={() => setControlInformationModal(false)}
          />
        )}
        {cardData[cardDataKey]?.length > 0 && (
          <TableTitle>
            <p>Projeto</p>
          </TableTitle>
        )}
        {cardData[cardDataKey]?.map((item) => (
          <ContainerRow>
            <ContainerRole projects>
              <h3> {item.title} </h3>
              <p>{item.knowledge.map((item) => item.label).join(' | ')}</p>
            </ContainerRole>
            <ButtonsContainer>
              <GenericButtons
                showEdit={true}
                showDelet={true}
                disabled={disabled}
                showEye={true}
                visible={item.visible}
                shouldHideItem={shouldHideItem}
                handleFuncVisibility={() => handleToggleVisibility(item)}
                handleFuncEdit={() => callToEditPortfolioForm(item)}
                handleFuncDelet={() => setControlInformationModal(item.id)}
              />
            </ButtonsContainer>
          </ContainerRow>
        ))}
        <CallToAction
          showButton
          disabled={shouldHideItem}
          universityColor={universityColor}
          onClick={callToPortfolioForm}
        >
          Adicionar <IoIosArrowForward />
        </CallToAction>
      </Section>
    </Container>
  );
}
