import React, { useContext } from 'react';
import moment from 'moment';
import { string, func, bool } from 'prop-types';
import { TextField } from '@material-ui/core';
import { CustomDateTime } from './styles';
import BaseLayoutContext from 'contexts/base-layout';

const dateTimeFormatTypes = {
  date: 'YYYY-MM-DD',
  time: 'HH:mm',
};

const DateTimePicker = ({
  type,
  name,
  value,
  onChange,
  jobsCalendar,
  ...rest
}) => {
  const dateTimeFormat = dateTimeFormatTypes[type];
  const { universityColor } = useContext(BaseLayoutContext);

  const maskDate = (date) => {
    if (date.match(/^\d{2}$/) !== null) {
      date += '/';
    } else if (date.match(/^\d{2}\/\d{2}$/) !== null) {
      date += '/';
    }

    return date;
  };

  const maskTime = (time) => {
    if (time.length === 2) {
      time = time.concat(':');
    }
    return time;
  };

  function mask(value) {
    if (type === 'date') {
      return maskDate(value);
    }
    return maskTime(value);
  }

  const parsedValue =
    (value || '').length === dateTimeFormat.length
      ? moment(value, dateTimeFormat)
      : value;

  return (
    <CustomDateTime
      universityColor={universityColor}
      jobsCalendar={jobsCalendar}
      dateFormat={['date'].includes(type) && 'DD/MM/YYYY'}
      timeFormat={['time'].includes(type)}
      value={parsedValue}
      onChange={(dateTime) =>
        onChange({
          target: {
            name,
            value:
              typeof dateTime !== 'string'
                ? dateTime.format(dateTimeFormat)
                : mask(dateTime),
          },
        })
      }
      renderInput={(props) => (
        <TextField
          autoComplete="off"
          inputProps={{
            maxLength: type === 'date' ? 10 : 5,
            display: 'flex',
          }}
          {...rest}
          {...props}
        />
      )}
    />
  );
};

DateTimePicker.defaultProps = {
  jobsCalendar: false,
};
DateTimePicker.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
  jobsCalendar: bool,
};

export default DateTimePicker;
