import React, { useRef, useContext, useState } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import {
  Container,
  ActionButtons,
  WrapperInformations,
  ConfirmButton,
  WrapperForm,
  WrapperFormContainer,
} from './styled';
import * as Yup from 'yup';
import { rescheduleValidationSchema } from './schema';
import GenericModal from '../../../../components/GenericModal';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import { SelectField } from 'views/StaffPages/Mentorship/components/SelectField';
import BaseLayoutContext from 'contexts/base-layout';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import { mentorshipApi } from '../../../../services/api';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { useScheduledMentorshipContext } from '../../context';

const RescheduleModal = ({
  item,
  title,
  onClose,
  isOpen,
  fetchMentorships,
}) => {
  const { universityColor } = useContext(BaseLayoutContext);
  const formRef = useRef();
  const [hours, setHours] = useState([]);
  const [hoursOptions, setHoursOptions] = useState([]);
  const { setPage, closeDetailsModal } = useScheduledMentorshipContext();

  const handleSubmit = async (data) => {
    try {
      const validatedData = await rescheduleValidationSchema.validate(data, {
        abortEarly: false,
      });
      delete validatedData.hours;
      const formData = {
        ...validatedData,
        date: new Date(data.date),
        start_hour: hours[data.hours.value]?.start_hour,
        end_hour: hours[data.hours.value]?.end_hour,
      };
      formRef.current.setErrors({});
      const response = await mentorshipApi.rescheduleMentorship(
        item.id,
        formData
      );
      if (response) {
        handleClose();
        setPage(1);
        fetchMentorships(true);
        closeDetailsModal();
        customSnackbar('Mentoria reagendada com sucesso!', 'confirmation');
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const isAvailableDate = (date) => {
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    const currentDateFormatted = moment(new Date()).format('YYYY-MM-DD');
    if (
      !item?.available_days ||
      !date ||
      dateFormatted === currentDateFormatted
    )
      return false;
    const weekdaysNumber = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    function getWeekdayKey(number) {
      return Object.entries(weekdaysNumber).find(
        ([_, value]) => value === number
      )?.[0];
    }

    const availableWeekDays = Object.keys(item.available_days)?.map(
      (availableDay) => weekdaysNumber[availableDay]
    );
    const isWeekdayAvailable = availableWeekDays.includes(date.getDay());

    const specificDates = item.specific_dates.map((specificDate) =>
      moment(specificDate.date).format('YYYY-MM-DD')
    );
    const isASpecificDate = specificDates.includes(dateFormatted);

    const absencesPeriods = item.absences.map((absence) => {
      return {
        start_date: new Date(absence.start_date),
        end_date: new Date(absence.end_date),
      };
    });

    const isDateInAbsencePeriod = absencesPeriods.some((absencesPeriod) => {
      return (
        date >= absencesPeriod.start_date && date <= absencesPeriod.end_date
      );
    });

    const dateWeekDay = getWeekdayKey(date.getDay());

    let allTimes = item.available_days[dateWeekDay] || [];

    if (isASpecificDate) {
      const specificDate = item.specific_dates.find(
        (item) =>
          moment(new Date(item.date)).format('YYYY-MM-DD') === dateFormatted
      );
      allTimes = [...allTimes, ...specificDate.schedules];
    }
    const scheduledMentorshipOnDay = item.scheduled_days.filter(
      (item) =>
        moment(new Date(item.date)).format('YYYY-MM-DD') === dateFormatted
    );

    const hasAvailableTimesOnDay =
      allTimes.length > scheduledMentorshipOnDay.length;

    return (
      (isWeekdayAvailable || isASpecificDate) &&
      hasAvailableTimesOnDay &&
      !isDateInAbsencePeriod
    );
  };

  const fetchHoursOptions = async (date) => {
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    const nextDays = await mentorshipApi.getMentorNextDays(
      item.mentor_id,
      dateFormatted
    );
    if (nextDays) {
      const newHours = nextDays[0]?.times;
      setHours(newHours);
      if (!newHours) return [];
      const newHourOptions = newHours?.map((hour, index) => {
        return {
          label: `${hour.start_hour} - ${hour.end_hour}`,
          value: index,
        };
      });
      setHoursOptions(newHourOptions);
      return newHourOptions;
    }
  };

  const handleClose = () => {
    formRef.current?.reset();
    formRef.current?.setErrors({});
    setHours([]);
    onClose();
  };

  return (
    <GenericModal isOpen={isOpen} onClose={handleClose} title={title}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Container>
          <WrapperInformations color={universityColor}>
            Sessão de mentoria com <span>{item?.name}</span>
          </WrapperInformations>

          <WrapperFormContainer>
            <WrapperForm>
              <p>Nova data</p>
              <DatePickerCustom
                minDate={new Date()}
                name="date"
                placeholder="Selecione"
                filterDate={isAvailableDate}
                handleUpdate={async (date) => {
                  if (formRef?.current) {
                    formRef.current.setFieldValue('hours', '');
                  }
                  if (!date) {
                    setHoursOptions([]);
                    return;
                  }
                  fetchHoursOptions(date);
                }}
              />
            </WrapperForm>
            <WrapperForm>
              <p>Horário</p>
              <SelectField
                name="hours"
                placeholder="Selecione"
                options={hoursOptions}
              />
            </WrapperForm>
          </WrapperFormContainer>

          <WrapperForm>
            <p>Explique o motivo do reagendamento da mentoria</p>
            <DescriptionInput
              name="rescheduling_reason"
              placeholder="Digite aqui..."
            />
          </WrapperForm>

          <ActionButtons>
            <ConfirmButton type="submit" color={universityColor}>
              Salvar
            </ConfirmButton>
          </ActionButtons>
        </Container>
      </Form>
    </GenericModal>
  );
};

export default RescheduleModal;
