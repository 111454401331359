import React, { useEffect, useState, useRef } from 'react';

import { getUniversityLogos } from 'services/login/authenticate';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import {
  Container,
  ContainerLogin,
  ContainerAllLogos,
  Titles,
  Paragraph,
  ButtonLogin,
  ContainerWorkalove,
  FragmentContainer,
  ContainerInCenter,
  BoxLogos,
  Footer,
  SubTitle,
} from './styles';

import LogosAnimated from 'views/LoginPage/Login/UIComponents/LogosAnimated';
import InputPasswordLogin from './InputPassword';
import { confirmNewPassword } from '../../services/login/recoverPassword';

function NewPassword({
  location,
  handleIsLoadingState,
  openSnackbar,
  history,
}) {
  const [universityData, setUniversityData] = useState({});
  const [urlInfo, setUrlInfo] = useState(null);
  const formRef = useRef(null);

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      await schema.validate(data, {
        abortEarly: false,
      });

      const query = new URLSearchParams(location.search);

      await confirmNewPassword({
        token: query.get('token'),
        password: data.password,
      })
        .then((res) => {
          handleIsLoadingState(false);
          const slug = query.get('slug');
          const path = slug ? '/login/' + slug + '/aluno' : '/login/empresa';
          setTimeout(() => {
            history.push({
              pathname: path,
            });
          }, 1000);
          openSnackbar('A sua nova senha foi definida com sucesso!');
        })
        .catch((err) => {
          if (err.response.data.password.length > 0) {
            openSnackbar(err.response.data.password[0], true);
          } else {
            openSnackbar('Algo deu errado, tente novamente mais tarde!', true);
          }
          handleIsLoadingState(false);
        });
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  };

  const getLogo = async (param) => {
    if (param) {
      const response = await getUniversityLogos(param);
      setUniversityData(response.data);
    }
  };

  const schema = Yup.object().shape({
    password: Yup.string()
      .required('Campo obrigatório')
      .min(8, 'Sua senha precisa ter no mínimo 8 caracteres.')
      .matches(
        new RegExp('([A-Za-z][0-9]|[0-9][A-Za-z])'),
        'Sua senha deve conter letras e números'
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'As senhas digitadas são diferentes'
    ),
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const regex = /slug=([^&]*)/;
    const match = url.hash.match(regex);
    const IES = match ? match[1] : null;

    setUrlInfo(IES);
  }, []);

  useEffect(() => {
    getLogo(urlInfo);
  }, [urlInfo]);

  return (
    <Container color={universityData.university_color}>
      <BoxLogos>
        <ContainerAllLogos>
          <LogosAnimated color={universityData.university_color} />
          <div>
            <Titles isLogo>
              <strong>Prepare-se para o novo mundo do trabalho.</strong>
            </Titles>
            <Paragraph>
              Conquiste o autoconhecimento, amplie seu potencial e independência
              para fazer escolhas em sua carreira.
            </Paragraph>
          </div>
        </ContainerAllLogos>
      </BoxLogos>
      <ContainerLogin>
        <FragmentContainer>
          <ContainerInCenter isStudent>
            <img src={universityData.logo} alt="" />
          </ContainerInCenter>
          <Titles isContainerLogin>
            <p> Nova senha </p>
            <SubTitle>Preencha os campos abaixo com a sua nova senha.</SubTitle>
          </Titles>
          <div className="form">
            <Form ref={formRef} onSubmit={handleSubmit}>
              <InputPasswordLogin
                name="password"
                label="Senha"
                universityColor={universityData.university_color}
                placeholder="Digite aqui..."
                buttonShowPassword
              />
              <InputPasswordLogin
                name="confirmPassword"
                label="Confirme sua senha"
                universityColor={universityData.university_color}
                placeholder="Digite aqui..."
                buttonShowPassword
              />
              <ContainerInCenter>
                {universityData.university_color ? (
                  <ButtonLogin
                    color={universityData.university_color}
                    type="submit"
                  >
                    Confirmar
                  </ButtonLogin>
                ) : (
                  <ButtonLogin color={'#009291'} type="submit">
                    Confirmar
                  </ButtonLogin>
                )}
              </ContainerInCenter>
            </Form>
          </div>
        </FragmentContainer>
        <ContainerWorkalove>
          <Footer>
            By <strong>Workalove</strong>
          </Footer>
        </ContainerWorkalove>
      </ContainerLogin>
    </Container>
  );
}

export default NewPassword;
