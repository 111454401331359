import * as Yup from 'yup';
import { isValidDate } from 'utils/validation';
import { format } from 'date-fns';

export async function generateExtraCoursesSchema(
  isAttractionCompletion,
  attractionInfo
) {
  const hoursRegexEvent = /^(?=.*\d)([0-9]{2,})[0-5][0-9]$/;

  if (isAttractionCompletion) {
    const AttractionsExtraCoursesSchema = Yup.object().shape({
      level: Yup.object()
        .shape({
          value: Yup.string().required('Escolha o nível do curso.'),
        })
        .typeError('Escolha o nível do curso.'),
      area: Yup.object()
        .shape({
          value: Yup.number().nullable(),
        })
        .typeError('Escolha uma das opções de conhecimento.')
        .required('Escolha uma das opções de conhecimento.'),
      status: Yup.string().required('Por favor, informe o status do curso.'),
      institution: Yup.string().required('Informe o nome da instituição.'),
      course: Yup.string().required('Por gentileza, informe o curso.'),
      startedAt: Yup.date()
        .max(new Date(), 'A "data de início" deve ser anterior a hoje.')
        .required('Escolha a data de início.')
        .test(
          'isValidStartedAt',
          'Preencha a data no formato dia/mês/ano',
          (value) => {
            if (value) return isValidDate('startedAt');
            return false;
          }
        )
        .test(
          'isStartBiggerThanCreatedAt',
          'A data de início deve ser maior que a data de publicação da aprendizagem',
          (value) => {
            if (
              value &&
              isAttractionCompletion &&
              format(new Date(value), 'yyyy-MM-dd') <
                format(new Date(attractionInfo.created_at), 'yyyy-MM-dd')
            ) {
              return false;
            }
            return true;
          }
        )
        .typeError('Preencha a data no formato dia/mês/ano'),
      endedAt: Yup.date().when('isFinished', {
        is: true,
        then: Yup.date()
          .min(
            Yup.ref('startedAt'),
            'A "data de conclusão" precisa ser maior do que a "Data de início".'
          )
          .max(new Date(), 'A "data de conclusão" não pode ser maior que hoje.')
          .required('Escolha uma data de conclusão.')
          .test(
            'isValidEndedAt',
            'Preencha a data no formato dia/mês/ano',
            (value) => {
              if (value) return isValidDate('endedAt');
              return false;
            }
          )
          .test(
            'isBiggerThanCreatedAt',
            'A data de conclusão deve ser maior que a data de publicação da aprendizagem',
            (value) => {
              if (
                value &&
                isAttractionCompletion &&
                format(new Date(value), 'yyyy-MM-dd') <
                  format(new Date(attractionInfo.created_at), 'yyyy-MM-dd')
              ) {
                return false;
              }
              return true;
            }
          )
          .typeError('Preencha a data no formato dia/mês/ano'),
      }),
      workload: Yup.number()
        .positive('Horas negativas?!')
        .required('Por favor, informe a carga horária.'),
      acquiredKnowledge: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
          })
        )
        .max(5, 'Selecione no máximo 5 opções')
        .typeError('Escolha o nível do curso.')
        .nullable(),
    });

    return AttractionsExtraCoursesSchema;
  }

  const CurriculumExtraCoursesSchema = Yup.object().shape({
    level: Yup.object()
      .shape({
        value: Yup.string().required('Escolha o nível do curso.'),
      })
      .typeError('Escolha o nível do curso.'),
    area: Yup.object()
      .shape({
        value: Yup.number().nullable(),
      })
      .typeError('Escolha uma das opções de conhecimento.')
      .required('Escolha uma das opções de conhecimento.'),
    status: Yup.string().required('Por favor, informe o status do curso.'),
    institution: Yup.string().required('Informe o nome da instituição.'),
    course: Yup.string().required('Por gentileza, informe o curso.'),
    startedAt: Yup.date()
      .max(new Date(), 'A "data de início" deve ser anterior a hoje.')
      .required('Escolha a data de início.')
      .test(
        'isValidStartedAt',
        'Preencha a data no formato dia/mês/ano',
        (value) => {
          if (value) return isValidDate('startedAt');
          return false;
        }
      )
      .typeError('Preencha a data no formato dia/mês/ano'),
    endedAt: Yup.date().when('isFinished', {
      is: true,
      then: Yup.date()
        .min(
          Yup.ref('startedAt'),
          'A "data de conclusão" precisa ser maior do que a "Data de início".'
        )
        .max(new Date(), 'A "data de conclusão" não pode ser maior que hoje.')
        .required('Escolha uma data de conclusão.')
        .test(
          'isValidEndedAt',
          'Preencha a data no formato dia/mês/ano',
          (value) => {
            if (value) return isValidDate('endedAt');
            return false;
          }
        )
        .typeError('Preencha a data no formato dia/mês/ano'),
    }),
    workload: Yup.string()
      .required('Por favor, informe a carga horária.')
      .test('isValidWorkload', 'Insira um valor válido.', (value) => {
        if (value && hoursRegexEvent.test(value.replace(':', ''))) {
          return true;
        }
        return false;
      }),
  });
  return CurriculumExtraCoursesSchema;
}
