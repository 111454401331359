export function IconInfoAcademicStatus() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 30.2666C23.2843 30.2666 30 23.5509 30 15.2666C30 6.98233 23.2843 0.266602 15 0.266602C6.71573 0.266602 0 6.98233 0 15.2666C0 23.5509 6.71573 30.2666 15 30.2666Z"
        fill="#D9FFE3"
      />
      <path
        d="M15.0006 7.03223C13.3719 7.03223 11.7797 7.5152 10.4255 8.42007C9.07127 9.32495 8.01577 10.6111 7.39248 12.1158C6.76919 13.6206 6.60611 15.2764 6.92386 16.8738C7.24161 18.4712 8.02592 19.9386 9.1776 21.0902C10.3293 22.2419 11.7966 23.0262 13.3941 23.344C14.9915 23.6617 16.6473 23.4987 18.152 22.8754C19.6568 22.2521 20.9429 21.1966 21.8478 19.8423C22.7527 18.4881 23.2356 16.896 23.2356 15.2672C23.2356 13.0832 22.368 10.9886 20.8237 9.4442C19.2793 7.89984 17.1847 7.03223 15.0006 7.03223V7.03223ZM13.7235 19.0665L9.93491 15.2822L11.3899 13.8219L13.7235 16.1554L18.6724 11.2108L20.1274 12.6658L13.7235 19.0665Z"
        fill="#068E2C"
      />
    </svg>
  );
}
