import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 4rem;

  span {
    font-size: 16px;
    line-height: 1.5;
    color: #777;
    max-width: 830px;
    text-align: center;
  }

  strong {
    font-size: 20px;
    font-weight: 700;
    color: #777;
    margin-top: 2rem;
  }
`;

export const CourseList = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 3rem;
`;

export const Divider = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '1px')};
  background-color: ${({ color }) => (color ? color : '#C4C4C4')};
`;

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const SkeletonBox = styled(Skeleton)`
  border-radius: 4px;
  margin: 0 !important;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
`;

export const CircularSkeleton = styled(Skeleton)`
  height: ${(props) => (props.size ? props.size : '100%')};
  width: ${(props) => (props.size ? props.size : '100%')};
  border-radius: 50%;
`;

export const SeeMoreButton = styled.button`
  display: flex;
  width: 130px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #009291;
  margin: 2rem auto 0;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    filter: brightness(0.9);
  }
`;
