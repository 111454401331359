import { Container, EditButton } from './style';
import clockImg from 'assets/academicModeling/clock.svg';
import tagsImg from 'assets/academicModeling/tags.svg';
import { Divider } from '../../../../style';

export const FinishedDisciplineCard = ({
  discipline,
  setSelectedTab,
  setSelectedDiscipline,
}) => {
  function handleClickEditDiscipline() {
    setSelectedTab(1);
    setSelectedDiscipline({
      label: `${discipline.code} - ${discipline.name}`,
      value: discipline.id,
    });
  }

  return (
    <Container>
      <span>{discipline.code}</span>
      <h1 title={discipline.name}>{discipline.name} </h1>
      <ul>
        <li>
          <img src={clockImg} alt="" />
          <p>Carga horária total: {Number(discipline.workload)}h</p>
        </li>

        <li>
          <img src={tagsImg} alt="" />
          <p>
            {discipline.tags_quantity}{' '}
            {discipline.tags_quantity > 1 ? 'tags mapeadas' : 'tag mapeada'}{' '}
          </p>
        </li>
      </ul>
      <Divider
        color={'#E5E5E5'}
        style={{
          marginTop: 'auto',
        }}
      />
      <EditButton onClick={handleClickEditDiscipline}>
        EDITAR DISCIPLINA
      </EditButton>
    </Container>
  );
};
