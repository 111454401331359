export const cardStructure = {
  event: {
    type: true,
    category: true,
    modality: true,
    publishedBy: true,
    coupon: true,
  },
  program: {
    type: true,
    category: true,
    modality: true,
    publishedBy: true,
    coupon: true,
  },
  course: {
    type: true,
    category: true,
    modality: true,
    publishedBy: true,
    coupon: true,
  },
  trail: {
    type: true,
    publishedBy: true,
    publicationType: true,
    postedIn: true,
  },
  serie: {
    type: true,
    publishedBy: true,
    publicationType: true,
    postedIn: true,
  },
  e_book: {
    type: true,
    publishedBy: true,
    publicationType: true,
    postedIn: true,
  },
  article: {
    type: true,
    publishedBy: true,
    publicationType: true,
    postedIn: true,
  },
  challenge: {
    type: true,
    publishedBy: true,
    publicationType: true,
    postedIn: true,
  },
  announcements: {
    description: true,
    type: true,
    publishedBy: true,
    postedIn: true,
  },
};

export const modalViewDetailsStructure = {
  event: {
    publishedBy: true,
    relatedKnowledge: true,
    dateOfTheEvent: true,
    modality: true,
    coupon: true,
  },
  program: {
    publishedBy: true,
    relatedKnowledge: true,
    dateOfTheEvent: true,
    modality: true,
    coupon: true,
  },
  course: {
    publishedBy: true,
    relatedKnowledge: true,
    dateOfTheEvent: true,
    modality: true,
    coupon: true,
  },
  trail: {
    type: true,
    publishedBy: true,
    relatedKnowledge: true,
  },
  serie: {
    type: true,
    publishedBy: true,
    relatedKnowledge: true,
  },
  e_book: {
    type: true,
    publishedBy: true,
    relatedKnowledge: true,
  },
  article: {
    type: true,
    publishedBy: true,
    relatedKnowledge: true,
  },
  challenge: {
    publishedBy: true,
    relatedKnowledge: true,
  },
  announcements: {
    publishedBy: true,
    postedIn: true,
  },
};

export const returnTypes = {
  event: 'Evento',
  program: 'Programa',
  course: 'Curso',

  trail: 'Trilhas',
  serie: 'Séries',
  e_book: 'E-book',
  article: 'Artigo',

  challenge: 'Desafio',
  announcements: 'Comunicado',
};

export const returnModality = (item) => {
  if (['event', 'program', 'course'].includes(item?.type)) {
    return item?.modality === 'online' ? 'Online' : 'Presencial';
  }
  return 'Não informado';
};

export const returnPublicationType = {
  access_link: 'Link de acesso',
  video: 'Vídeos sequenciais',
  attraction_document: 'Arquivo PDF'
};

export const returnCoupon = (item) => {
  if (item?.is_free) {
    return 'Gratuito';
  }

  if (
    (item?.is_free === false && item?.has_discount_ticket === null) ||
    item?.has_discount_ticket === false
  ) {
    return 'Pago';
  }

  if (item?.is_free === false && item?.has_discount_ticket === true) {
    return 'Pago - Possui cupom de desconto';
  }
};

export const returnCouponDetails = (item) => {
  if (item?.is_free) {
    return 'Gratuito';
  }

  if (
    (item?.is_free === false && item?.has_discount_ticket === null) ||
    item?.has_discount_ticket === false
  ) {
    return 'Pago';
  }

  if (item?.is_free === false && item?.has_discount_ticket === true) {
    return 'Pago';
  }
};

export const getEmbed = (url) => {
  if (url.includes('?v=')) {
    const embed = `https://www.youtube.com/embed/${
      url.split('?v=')[1].split('&')[0]
    }`;
    return embed;
  }
  if (url.includes('youtu.be')) {
    const embed = `https://www.youtube.com/embed/${url.split('youtu.be/')[1]}`;
    return embed;
  }
  return 'https://www.youtube.com/embed/';
};
