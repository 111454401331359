
export const scrollRigthOpenForSubscriptions = () => {
  document.getElementById("open-for-subscriptions").scrollLeft += 318;
};

export const scrollLeftOpenForSubscriptions = () => {
  document.getElementById("open-for-subscriptions").scrollLeft += -318;
};


export const scrollRigthWaitingForStart = () => {
  document.getElementById("waiting-for-start").scrollLeft += 318;
};

export const scrollLeftWaitingForStart = () => {
  document.getElementById("waiting-for-start").scrollLeft += -318;
};


export const scrollRigthInProgress = () => {
  document.getElementById("in-progress").scrollLeft += 318;
};

export const scrollLeftInProgress = () => {
  document.getElementById("in-progress").scrollLeft += -318;
};