import React, { useContext, useEffect, useState } from 'react';
import { CloseButton, Container, Wrapper } from './style';

import X from 'assets/Oportutinites/X.svg';
import { JobOfferContext } from '../../context/jobOfferContext';

export default function PrincipalModal({
  children,
  openModal,
  closeModal,
  zIndex,
}) {
  const { resetAllData } = useContext(JobOfferContext);
  const [style, setStyle] = useState('show');

  useEffect(() => {
    if (openModal === false) {
      resetAllData();
    }
  }, [openModal]);

  const handleClickClose = () => {
    closeModal();
  };

  return (
    <Container openModal={openModal} zIndex={zIndex}>
      <CloseButton onClick={handleClickClose}>
        <img src={X} />
      </CloseButton>
      <Wrapper>{children}</Wrapper>
    </Container>
  );
}
