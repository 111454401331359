export function IconValues({ color }) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16960_53830)">
        <path
          d="M20.6218 2.25879C19.2948 2.27943 17.9968 2.64984 16.8589 3.33263C15.7209 4.01541 14.7832 4.98638 14.1406 6.14749C13.4979 4.98638 12.5603 4.01541 11.4223 3.33263C10.2843 2.64984 8.98635 2.27943 7.65942 2.25879C5.54413 2.35069 3.55118 3.27604 2.11599 4.83268C0.680792 6.38931 -0.0799916 8.4507 -0.000154641 10.5665C-0.000154641 15.9246 5.63965 21.7766 10.3697 25.7442C11.4258 26.6317 12.7611 27.1182 14.1406 27.1182C15.5201 27.1182 16.8554 26.6317 17.9115 25.7442C22.6415 21.7766 28.2813 15.9246 28.2813 10.5665C28.3612 8.4507 27.6004 6.38931 26.1652 4.83268C24.73 3.27604 22.7371 2.35069 20.6218 2.25879ZM16.3972 23.9413C15.7656 24.4732 14.9664 24.7649 14.1406 24.7649C13.3148 24.7649 12.5156 24.4732 11.884 23.9413C5.82937 18.8612 2.35664 13.9874 2.35664 10.5665C2.27608 9.07547 2.78836 7.6131 3.78174 6.4983C4.77513 5.3835 6.16902 4.70673 7.65942 4.61558C9.14981 4.70673 10.5437 5.3835 11.5371 6.4983C12.5305 7.6131 13.0428 9.07547 12.9622 10.5665C12.9622 10.879 13.0863 11.1787 13.3073 11.3997C13.5283 11.6207 13.8281 11.7449 14.1406 11.7449C14.4531 11.7449 14.7529 11.6207 14.9738 11.3997C15.1948 11.1787 15.319 10.879 15.319 10.5665C15.2384 9.07547 15.7507 7.6131 16.7441 6.4983C17.7375 5.3835 19.1314 4.70673 20.6218 4.61558C22.1122 4.70673 23.5061 5.3835 24.4994 6.4983C25.4928 7.6131 26.0051 9.07547 25.9245 10.5665C25.9245 13.9874 22.4518 18.8612 16.3972 23.9366V23.9413Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_16960_53830">
          <rect width="28.2815" height="28.2815" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
