import styled from 'styled-components';

export const Container = styled.div`
  border: 1.5px solid #009291;
  min-height: 30px;
  min-width: 120px;
  width: auto;
  height: auto;
  border-radius: 8px;
  background-color: #eaf5f5;
  color: #000;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const Circle = styled.div`
  display: flex;
  min-width: 25px;
  max-width: 25px;
  max-height: 25px;
  background-color: #009291;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
`;
