import React from 'react';
import ReactSelect from 'react-select';

function Select(props) {
  const selectStyle = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return <ReactSelect styles={selectStyle} {...props} />;
}

export default Select;
