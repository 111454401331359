import {
  SkeletonCard,
  SkeletonImage,
  Container,
  SkeletonTitle,
} from './styled';

export default function CardSkeleton() {
  return (
    <Container>
      <SkeletonCard>
        <SkeletonImage />
        <SkeletonTitle />
      </SkeletonCard>
    </Container>
  );
}
