import React, { Fragment, useReducer, useEffect } from 'react';
import { func, object, bool } from 'prop-types';
import { FormHelperText, Grid, TextField, List } from '@material-ui/core';
import { maskZipCode } from 'utils/inputMasks';
import addressAdapter from 'adapters/addressAdapter';
import { FastField } from 'formik';
import withStyles from '@material-ui/core/styles/withStyles';
import GridContainer from 'components/Grid/GridContainer';
import { zipCodeRequest } from 'services/zipCode';
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

function ZipCodeForm({
  classes,
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldError,
  readOnly,
}) {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      zipCode: '',
    }
  );

  useEffect(() => {
    if (values.zipCode) {
      setState({ zipCode: values.zipCode });
    }
  }, [values]);

  useEffect(() => {
    const fetchZipCodeData = async () => {
      if (state.zipCode.length === 9) {
        try {
          const data = await zipCodeRequest(state.zipCode);
          if (data.erro) {
            setFieldError('zipCode', 'CEP inválido');
            return;
          }
          setFieldError('zipCode', '');
          const address = addressAdapter(data);
          setFieldValue('street', address.street);
          setFieldValue('complement', address.complement);
          setFieldValue('district', address.district);
          setFieldValue('addressState', address.addressState);
          setFieldValue('city', address.city);
        } catch (e) {
          setFieldError('zipCode', 'CEP inválido');
        }
      } else if (state.zipCode.length < 9) {
        setFieldValue('street', '');
        setFieldValue('addressNumber', '');
        setFieldValue('complement', '');
        setFieldValue('district', '');
        setFieldValue('addressState', '');
        setFieldValue('city', '');
      }
    };

    fetchZipCodeData();
  }, [state.zipCode, setFieldValue]);

  const handleFieldMask = (event, mask) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      setState({
        ...state,
        zipCode: event.target.value,
      });
      return event.target.value;
    }
    event.target.value = mask(event.target.value);
    setState({
      ...state,
      zipCode: event.target.value,
    });
  };

  return (
    <Fragment>
      <List className={(classes.list, classes.zipCodeList)}>
        <GridContainer
          spacing={16}
          style={{
            margin: '10px -10px 0',
            padding: '0px',
            width: '710px',
          }}
        >
          <Grid item xs={12} md={4} style={{ position: 'relative' }}>
            <FastField
              className={classes.inputField}
              InputProps={{
                readOnly,
                className: !!errors.zipCode
                  ? classes.inputErrorCep
                  : classes.input,
                endAdornment: (
                  <div
                    style={{
                      position: 'absolute',
                      top: '55%',
                      right: '20px',
                      transform: 'translateY(-50%)',
                      pointerEvents: 'none',
                    }}
                  >
                    <AiOutlineSearch size={24} />
                  </div>
                ),
              }}
              maxLength={9}
              name="zipCode"
              variant="filled"
              id="zipCode"
              label="CEP *"
              placeholder="Ex.: 02330-001"
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyUp={(event) => {
                handleFieldMask(event, maskZipCode);
                handleChange(event);
              }}
              error={!!errors.zipCode && !!touched.zipCode}
              value={values.zipCode}
              component={TextField}
            />

            {errors.zipCode && touched.zipCode && (
              <FormHelperText className={classes.newHelperText}>
                <AiOutlineCloseCircle size={18} color={'#f10909'} />
                {errors.zipCode}
              </FormHelperText>
            )}
          </Grid>

          {state.zipCode.length === 9 && !errors.zipCode ? (
            <Fragment>
              <Grid item xs={12} md={8}>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    readOnly,
                    className: !!errors.street
                      ? classes.inputErrorCep
                      : classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  name="street"
                  variant="filled"
                  id="street"
                  label="Rua/Avenida"
                  placeholder="Ex.: Rua Direita, Rua Soledade"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.street && !!touched.street}
                  value={values.street}
                  component={TextField}
                  required
                />
                {errors.street && touched.street && (
                  <FormHelperText className={classes.newHelperText}>
                    <AiOutlineCloseCircle size={18} color={'#f10909'} />
                    {errors.street}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    readOnly,
                    className: !!errors.addressNumber
                      ? classes.inputErrorCep
                      : classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  name="addressNumber"
                  variant="filled"
                  id="addressNumber"
                  label="Número"
                  placeholder="Ex.: 788, 1484..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="number"
                  min="0"
                  value={values.addressNumber}
                  component={TextField}
                  required
                />
                {errors.addressNumber && touched.addressNumber && (
                  <FormHelperText className={classes.newHelperText}>
                    <AiOutlineCloseCircle size={18} color={'#f10909'} />
                    {errors.addressNumber}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    readOnly,
                    className: classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  name="complement"
                  variant="filled"
                  id="complement"
                  label="Complemento"
                  readOnly
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.complement && !!touched.complement}
                  value={values.complement}
                  component={TextField}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    readOnly,
                    className: !!errors.district
                      ? classes.inputErrorCep
                      : classes.input,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  name="district"
                  variant="filled"
                  id="district"
                  label="Bairro"
                  placeholder="Ex.: Nome do Bairro"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.district && !!touched.district}
                  value={values.district}
                  component={TextField}
                  required
                />
                {errors.district && touched.district && (
                  <FormHelperText className={classes.newHelperText}>
                    <AiOutlineCloseCircle size={18} color={'#f10909'} />
                    {errors.district}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    readOnly,
                    className: classes.input,
                    style: { backgroundColor: '#fff' },
                    disabled: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  disabled
                  name="addressState"
                  variant="filled"
                  id="addressState"
                  label="UF"
                  placeholder="Ex.: Minas Gerais, São Paulo, Pernambuco..."
                  readOnly
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.addressState && !!touched.addressState}
                  value={values.addressState}
                  component={TextField}
                  required
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <FastField
                  className={classes.inputField}
                  InputProps={{
                    readOnly,
                    className: classes.input,
                    style: { backgroundColor: '#fff' },
                    disabled: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    FormLabelClasses: {
                      focused: classes.inputField,
                    },
                  }}
                  disabled
                  name="city"
                  variant="filled"
                  id="city"
                  label="Cidade"
                  placeholder="Ex.: Recife, Sete Lagoas, São Paulo..."
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.city && !!touched.city}
                  value={values.city}
                  component={TextField}
                  required
                />
              </Grid>
            </Fragment>
          ) : null}
        </GridContainer>
      </List>
    </Fragment>
  );
}

ZipCodeForm.propTypes = {
  classes: object,
  values: object,
  errors: object,
  touched: func,
  handleBlur: func,
  handleChange: func,
  setFieldValue: func,
  setFieldError: func,
  readOnly: bool,
};

export default withStyles(ProfessionalExperienceFormStyles)(ZipCodeForm);
