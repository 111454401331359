import React, { useState, useEffect } from 'react';

const iconChallenge = () => {
  const [color, setColor] = useState('#3c4858');

  useEffect(() => {
    if (window.location.href.includes('desafios')) {
      setColor('white');
      return;
    }
    setColor('#3c4858');
  }, [window.location]);

  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        color: 'inherit',
        width: '30px',
        height: '24px',
        float: 'left',
        position: 'inherit',
        top: '3px',
        marginRight: '15px',
        textAlign: 'center',
        verticalAlign: 'middle',
        opacity: '0.8',
      }}
    >
      <path
        d="M22 2.48852C21.2813 3.20494 20.5629 3.92193 19.845 4.6395C19.8071 4.68051 19.7612 4.71341 19.7102 4.7362C19.6592 4.75899 19.6041 4.77121 19.5482 4.7721C18.7828 4.80808 18.0189 4.85075 17.2566 4.90012C17.1205 4.9093 17.0884 4.87601 17.097 4.74053C17.1471 3.95599 17.1908 3.17145 17.2279 2.38691C17.2331 2.2767 17.2968 2.22159 17.3611 2.15729C18.0763 1.43819 18.7922 0.719097 19.5086 0H19.5517C19.6386 0.0490749 19.7177 0.11079 19.7864 0.183123C20.4619 0.860888 21.1387 1.53789 21.8169 2.21412C21.8895 2.28238 21.9513 2.36137 22 2.44833V2.48852Z"
        fill={color}
      />
      <path
        d="M15.8437 4.10124C15.8241 4.42965 15.7954 4.75806 15.7862 5.08704C15.7878 5.16058 15.7732 5.23356 15.7434 5.30082C15.7136 5.36808 15.6695 5.42799 15.614 5.47631C15.422 5.64809 15.2401 5.83078 15.0691 6.02347C14.9543 6.15265 14.8808 6.13026 14.7574 6.04012C13.8152 5.35115 12.7967 4.80284 11.6484 4.55538C9.15028 4.01684 6.94729 4.65644 5.13931 6.42767C3.1298 8.39297 2.47183 10.8233 3.18721 13.5327C3.92212 16.307 5.75881 18.1104 8.52676 18.8338C12.6606 19.9138 16.7692 17.3083 17.6384 13.1078C18.0978 10.8888 17.5374 8.91659 16.0423 7.21138C15.9085 7.0598 15.8867 6.97426 16.0532 6.84967C16.157 6.77246 16.2491 6.6806 16.3265 6.57695C16.5441 6.28069 16.8341 6.18883 17.1935 6.18423C17.7676 6.17677 18.3372 6.13256 18.9084 6.09466C19.0457 6.08548 19.1255 6.11935 19.199 6.24279C20.3289 8.13345 20.8605 10.1763 20.6693 12.3729C20.3249 16.2949 18.3567 19.1519 14.8757 20.9484C13.2342 21.7952 11.4566 22.1202 9.61476 21.9548C5.70369 21.604 2.84904 19.6451 1.05312 16.1726C0.201089 14.5254 -0.117564 12.7427 0.0380288 10.8905C0.334287 7.3618 2.01883 4.66447 4.99979 2.78243C6.39496 1.90226 7.93884 1.43606 9.5872 1.31836C11.7938 1.16066 13.8447 1.6504 15.7397 2.78759C15.8689 2.86453 15.912 2.94721 15.8999 3.09246C15.8752 3.43006 15.862 3.76651 15.8437 4.10124Z"
        fill={color}
      />
      <path
        d="M14.9715 7.93423C16.2874 9.38739 16.7818 11.0559 16.3563 12.9626C15.7914 15.4986 13.7618 17.36 11.1965 17.728C7.91469 18.2011 4.8235 15.9108 4.29357 12.6095C4.05094 11.1518 4.35669 9.65593 5.15186 8.41039C5.94702 7.16484 7.17526 6.25787 8.59965 5.86444C10.1671 5.42637 11.6426 5.70023 13.0452 6.47073C13.3272 6.62575 13.597 6.80087 13.8651 6.9777C13.9731 7.0489 13.9995 7.09253 13.8927 7.19588C13.3358 7.74208 12.784 8.29364 12.2374 8.85056C12.1536 8.93553 12.0991 8.92692 12.0037 8.87295C10.8876 8.24541 9.75597 8.22934 8.66854 8.90281C7.58111 9.57628 7.07644 10.596 7.14362 11.8752C7.22515 13.4253 8.5319 14.7338 10.0964 14.8544C11.6799 14.9761 13.1394 13.9174 13.4925 12.3907C13.6919 11.5749 13.5602 10.7133 13.1262 9.99426C13.082 9.9179 13.0395 9.8645 13.1262 9.77609C13.7446 9.16979 14.3532 8.55431 14.9715 7.93423Z"
        fill={color}
      />
      <path
        d="M11.184 9.91329C10.7936 10.2996 10.4119 10.686 10.0192 11.0614C9.91415 11.1624 9.92391 11.2164 10.0232 11.31C10.2574 11.5312 10.4851 11.7589 10.7063 11.9931C10.7925 12.0844 10.8412 12.0763 10.9239 11.9931C11.308 11.5993 11.6983 11.2112 12.0904 10.8168C12.5123 11.6142 12.2471 12.6584 11.4985 13.2009C11.1235 13.4777 10.6609 13.609 10.1964 13.5706C9.7319 13.5322 9.29712 13.3266 8.97271 12.9919C8.64404 12.6566 8.44904 12.2129 8.42431 11.7441C8.39958 11.2752 8.54681 10.8134 8.83837 10.4454C9.39234 9.73591 10.4417 9.49136 11.184 9.91329Z"
        fill={color}
      />
    </svg>
  );
};

export default iconChallenge;
