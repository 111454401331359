
import Wall from 'assets/JobOffer/IconPerson.svg';
import { 
  Container, 
  Box, 
  Content,
  WrapperTitle, 
  BubbleDialog, 
  WrapperMessage,
  ContainerButton,
  GreenButton,
  ButtonReturn
} from '../../../Styles/BaseModal'

import {useEffect, useContext, useState} from 'react'
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import { connectionAndDisconnection, disconnectCompany } from 'views/StaffPages/CompaniesManagement/Services';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';
import Loading from 'views/StaffPages/Opportunities/Components/ExtensionProjects/components/Loading';

export default function DisconnectModal(props) {

  const {
    isOpen, setOpenModalDisconnect, 
    disconnectAll, selectedCompanies, 
    id, setSelectedCompanies,
    setFilterState, getCountTabs, disconnectOne, setDisconnectOne,
    limit, currentPage
  } = props;

  const {
    modalDisconnect, 
    setModalDisconnect, 
    setListConnectedCompanies
  } = useContext(CompaniesManagementContext)

  const {getConnectedCompanies} = useAPICalls();

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setModalDisconnect(false)
  }, [])

  const closeModalClick = () => {
    setFilterState({})
    setSelectedCompanies([])
    setModalDisconnect(false)
    setOpenModalDisconnect(false)
    setDisconnectOne(false)
  }

  const closeModal = async () => {
    if (selectedCompanies.length > 1) {
      const data = {
        ids: selectedCompanies,
        status: 'rejected' 
      }
      setLoading(true)
      await connectionAndDisconnection(data);
      await getCountTabs()
      setListConnectedCompanies(null);
      setFilterState({})
      setSelectedCompanies([])
      setModalDisconnect(false)
      setOpenModalDisconnect(false)
      setDisconnectOne(false)
      setLoading(false)
      await getConnectedCompanies(
        `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
      );
    }

    if (id !== null && selectedCompanies.length <= 1 ) {
      setLoading(true)
      await disconnectCompany(id);
      await getCountTabs()
      setListConnectedCompanies(null);
      setFilterState({})
      setSelectedCompanies([])
      setModalDisconnect(false)
      setOpenModalDisconnect(false)
      setDisconnectOne(false)
      setLoading(false)
      await getConnectedCompanies(
        `limit=${limit}&offset=${(currentPage - 1) * limit || 0}`
      );
      

    }
  }

  const handleDisconnect = async () => {
    setModalDisconnect(true)
  }

  const ModalDisconnect = () => {
    return (
      <Container isOpen={isOpen}>
        <Box>
          <Content>
            <WrapperTitle>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog widthBubble="126px" heightBubble="42px">
                <span> Atenção! </span>
              </BubbleDialog>
            </WrapperTitle>
            <WrapperMessage
              fontMessage="18px"
              lineHeigthMessage="1.6rem"
              positionMessage="start"
            >
              {
                disconnectAll === true && disconnectOne === false && (
                  <div>
                    <p> 
                      Tem certeza que deseja se desconectar de {' '}
                      {selectedCompanies.length}{' '}
                      {selectedCompanies.length > 1 ? "empresas" : "empresa"} ? 
                    </p>
                    <p> 
                      Ao realizar essa ação, seus estudantes não terão mais
                      acesso as vagas e desafios publicados por ela. </p>
                  </div>
                )
              }

              {
                disconnectOne === true && disconnectAll === true && (
                  <div>
                    <p> 
                      Tem certeza que deseja se desconectar dessa empresa? 
                    </p>
                    <p> 
                      Ao realizar essa ação, seus estudantes não terão mais 
                      acesso as vagas e desafios publicados por ela. 
                    </p>
                  </div>
                )
              }

            {
                disconnectOne === true && disconnectAll === false && (
                  <div>
                    <p> 
                      Tem certeza que deseja se desconectar dessa empresa? 
                    </p>
                    <p> 
                      Ao realizar essa ação, seus estudantes não terão 
                      mais acesso as vagas e desafios publicados por ela.
                    </p>
                  </div>
                )
              }
              
          
              
            </WrapperMessage>
            <ContainerButton>
              <ButtonReturn
                onClick={closeModalClick}
                marginTopButton="0"
              >
                NÃO
              </ButtonReturn>
              <GreenButton
                marginTopButton="0"
                onClick={() => handleDisconnect()}
              >
                SIM, DESCONECTAR
              </GreenButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    )
  }

  const TradeModalDisconnect = () => {
    return (
      <Container isOpen={isOpen}>
        <Box>
          <Content>
            <WrapperTitle>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <BubbleDialog widthBubble="253px" heightBubble="60px">
                  <span>  
                  {
                    selectedCompanies.length > 1 ? 
                      "Empresas desconectadas com sucesso" : 
                      "Empresa desconectada com sucesso"
                  }
                  </span>
              </BubbleDialog>
            </WrapperTitle>
            <WrapperMessage
              fontMessage="18px"
              lineHeigthMessage="1.6rem"
              positionMessage="center"
            >
              <span> 
                {
                  selectedCompanies.length > 1 ? 
                  "Caso queira, você poderá se reconectar com as empresas" : 
                  "Caso queira, você poderá se reconectar com a empresa"
                }
              </span> 
              <br/> <strong> "Empresas Desconectadas" </strong>
            </WrapperMessage>
            <ContainerButton>
              <GreenButton
                marginTopButton="0"
                widthButton="168px"
                onClick={closeModal}
              >
               { 
                loading ? 
                  <Loading color={'#fff'} /> : 
                  'OK, ENTENDI'
                }
              </GreenButton>
            </ContainerButton>
          </Content>
        </Box>
      </Container>
    )
  }

  return modalDisconnect === false ? <ModalDisconnect /> : <TradeModalDisconnect />

}