export const Themes = {
  light: {
    background: '#E5F4F4',
    color: '#009291',
  },
  dark: {
    background: '#009291',
    color: '#FFF',
  },
  unfilled: {
    background: 'transparent',
    color: '#009291',
    border: true,
  },
  flat: {
    background: 'transparent',
    color: '#606062',
    border: false,
    underline: true,
  },
  flatUniversityColor: {
    background: 'transparent',
    color: '#009291',
    border: false,
    underline: true,
  },
};
