import { FaUserCircle } from 'react-icons/fa';
import { AiOutlineMessage } from 'react-icons/ai';
import { RiFileList3Line, RiRoadMapLine } from 'react-icons/ri';
import { BsGear, BsPeople } from 'react-icons/bs';
import { HiOutlineBookOpen } from 'react-icons/hi';
import { CgBriefcase } from 'react-icons/cg';

import BaseLayout from 'layouts/BaseLayout';
import BaseProfessionalStatus from 'layouts/ProfessionalStatus';
import OpenBase from 'layouts/OpenBase';

import Configurations from 'views/Configurations/Configurations';
import LearningTrails from 'views/LearningTrails/LearningTrails';
import MyCareer from 'views/MyCareer/MyCareer';
import Videos from 'views/Contents/Contents';
import Unsubscribe from 'views/Student/Unsubscribe';
import { InternshipRedirect } from '../components/InternshipRedirect';
import { InternshipLogout } from '../components/InternshipLogout';
import ReceiveJob from '../views/JobOffers/externalJobDetail';
import LearningEngine from 'views/Student/Learning/LearningEngine';
import ProfileEngine from 'views/Profile/ProfileEngine';
import NetWorkStudentEngine from 'views/Student/Network/NetworStudentEngine';
import ProfessionalStatusEngine from 'views/ProfessionalStatus/ProfessionalStatusEngine';
import NewJobOffers from 'views/JobOffers/newJobOffers';
import PublicValidateCertificate from 'views/Student/PublicValidateCertificate';
import MentorshipEngine from 'views/Student/Mentorship';
import { actionsProfileStore } from 'stores/profile.store';

const baseRoutes = [
  {
    redirect: true,
    path: '*',
    pathTo: '/',
  },
];

const studentOpenRoutes = [
  {
    path: '/unsubscribe/:id?',
    name: 'Sair da lista de emails',
    hide: true,
    baseComponent: OpenBase,
    component: Unsubscribe,
  },
  {
    path: '/share/certificate/event/:registrationId',
    name: 'Validar certificado',
    hide: true,
    baseComponent: OpenBase,
    component: PublicValidateCertificate,
  },
];

function displayJobOffersMenu(userData) {
  if (userData.metadata.display_job_offer === true) {
    return [
      {
        path: '/vagas',
        pathTo: '/vagas',
        name: 'Vagas',
        icon: CgBriefcase,
        baseComponent: BaseLayout,
        component: NewJobOffers,
      },
    ];
  }
  return [];
}

function displayNetworkMenu(userData) {
  if (
    userData.metadata.features &&
    userData.metadata.features.includes('student_network')
  ) {
    return [
      {
        path: '/networking',
        pathTo: '/networking',
        name: 'Networking',
        icon: BsPeople,
        baseComponent: BaseLayout,
        component: NetWorkStudentEngine,
      },
    ];
  }
  return [];
}

function displayLearningsMenu(userData) {
  if (
    userData.metadata.features &&
    userData.metadata.features.includes('student_learning')
  ) {
    return [
      {
        path: '/aprendizagem',
        name: 'Aprendizagem',
        icon: HiOutlineBookOpen,
        baseComponent: BaseLayout,
        component: (props) => <LearningEngine {...props} userData={userData} />,
      },
    ];
  }
  return [];
}

const studentSwitcher = (userData) => {
  let routes;
  const internshipEnabled = userData.metadata.internship_enabled;
  const mentoringEnabled = userData.metadata.has_mentoring;
  const marketEnabled =
    userData.metadata.features &&
    userData.metadata.features.includes('student_market_analysis');

  const cardData = {};
  Object.assign(cardData, {
    careerInterests: userData.interests ?? [],
    lifeInterests: userData.life_interests ?? [],
    personality: userData.quizzes.personality ?? [],
    behavioral: userData.quizzes.behavioral ?? [],
    languages: userData.languagesState ?? [],
    values: userData.quizzes.values ?? [],
    extraCourses: userData.extraCourses ?? [],
    lifeExperience: userData.life_experience ?? [],
    careerExperience: userData.professional_experience ?? [],
    portfolio: userData.projects ?? [],
    technicalCompetences: userData.technical_competences ?? [],
  });

  userData.metadata.cardData = cardData;
  const { changeCardData } = actionsProfileStore;
  changeCardData(cardData);
  // When user doesn't have the profession status defined,
  // we have to redirect it to the ProfessionlStatus page
  if (!userData.metadata.professional_status_defined) {
    routes = [
      {
        path: '/',
        name: 'Status Profissional',
        component: ProfessionalStatusEngine,
        baseComponent: BaseProfessionalStatus,
      },
    ];
  } else {
    routes = [
      {
        path: '/',
        name: 'Meu Perfil',
        icon: FaUserCircle,
        baseComponent: BaseLayout,
        component: ProfileEngine,
      },
      ...displayLearningsMenu(userData),
      {
        path: '/aprendizagem/:universidade/compartilhar/evento/:eventId/:registerId?',
        name: 'Compartilhar Evento',
        icon: HiOutlineBookOpen,
        baseComponent: BaseLayout,
        component: LearningEngine,
        hide: true,
      },

      {
        path: '/mentoria',
        name: 'Mentoria',
        icon: AiOutlineMessage,
        baseComponent: BaseLayout,
        component: MentorshipEngine,
        hide: !mentoringEnabled,
      },
      {
        path: '/trilhas-aprendizagem',
        pathTo: '/trilhas-aprendizagem',
        name: 'Aprendizagem',
        icon: HiOutlineBookOpen,
        baseComponent: BaseLayout,
        component: LearningTrails,
        hide: true,
      },
      ...displayNetworkMenu(userData),
      ...displayJobOffersMenu(userData),
      {
        path: '/seu-sucesso',
        pathTo: '/seu-sucesso',
        name: 'Aprendizagem',
        icon: HiOutlineBookOpen,
        baseComponent: BaseLayout,
        component: Videos,
        hide: true,
      },
      {
        path: '/estagio',
        name: 'Gestão de Estágio',
        icon: RiFileList3Line,
        baseComponent: BaseLayout,
        component: InternshipRedirect,
        hide: !internshipEnabled,
      },
      {
        path: '/configuracoes',
        pathTo: '/configuracoes',
        name: 'Configurações',
        icon: BsGear,
        baseComponent: BaseLayout,
        component: Configurations,
      },
      {
        path: '/vaga/:universidade/:id',
        name: 'Vagas',
        icon: CgBriefcase,
        baseComponent: BaseLayout,
        component: ReceiveJob,
        hide: true,
      },
      ...studentOpenRoutes,
    ];
    if (marketEnabled) {
      routes.splice(1, 0, {
        path: '/mapa-de-carreiras',
        pathTo: '/mapa-de-carreiras',
        name: 'Mapa de Carreiras',
        icon: RiRoadMapLine,
        baseComponent: BaseLayout,
        component: MyCareer,
      });
    }
  }

  if (internshipEnabled) {
    routes.push({
      path: '/estagio/logout',
      baseComponent: BaseLayout,
      component: InternshipLogout,
      hide: true,
    });
  }

  return [...routes, ...baseRoutes];
};

export { studentSwitcher, studentOpenRoutes };
