import React, { Fragment } from 'react';
import { func, shape, string, number, bool } from 'prop-types';
import { ArrowRight, CardMembership } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { CtaButton, ContainedButton } from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/pt-br';

import CertificateContainer from 'components/Certificate/CertificateContainer';

import cardChartStyle from 'assets/jss/material-dashboard-pro-react/views/cardChartStyle';

const TrailProgressCard = (props) => {
  const {
    classes,
    colorScheme,
    trailProgress,
    openModalWithContentFn,
    TrailContent,
    trailTitle,
    trailEnabled,
    handleIsLoadingState,
    getTrailCertificateData,
    trail,
    openSnackbar,
    metadata,
  } = props;
  const propsCopy = { ...props };
  delete propsCopy.classes;
  const showCertificate = async () => {
    const response = await getTrailCertificateData(trail);

    if (!response.message) {
      const { student, total_hours: totalHours, completion_date } = response;

      const completionDate = moment(completion_date)
        .locale('pt-br')
        .format('LL');

      openModalWithContentFn(
        <CertificateContainer
          trailTitle={trailTitle}
          handleIsLoadingState={handleIsLoadingState}
          certificateData={{ student, totalHours, completionDate }}
        />,
        `Certificado ${trailTitle}`
      );
    } else {
      openSnackbar(
        'Falha ao tentar gerar o certificado! Tente novamente mais tarde.',
        true
      );
    }
  };

  return (
    <Fragment>
      <div className={classes[colorScheme]}>
        <Grid container justify="center">
          <Grid
            container
            item
            xs={12}
            alignContent="center"
            className={classes[colorScheme]}
          >
            <div className={classes.progressArea}>
              <CircularProgress
                className={classes.progress}
                color="inherit"
                variant="static"
                value={trailProgress}
                size={150}
              />
              <CircularProgress
                className={classes.progressFixed}
                color="inherit"
                variant="static"
                value={100}
                size={150}
              />
              {metadata.role === 'student' ? (
                <div className={classes.progressNumber}>{trailProgress}%</div>
              ) : (
                <div
                  style={{ top: '-30px' }}
                  className={classes.progressNumber}
                >
                  {trailProgress}%
                </div>
              )}
            </div>
          </Grid>
          <Grid
            container
            item
            xs={6}
            alignItems="center"
            justify="center"
            direction="column"
            className={classes.gridReset}
          >
            <CtaButton
              onClick={() =>
                openModalWithContentFn(
                  <TrailContent
                    openModalWithContentFn={openModalWithContentFn}
                    {...propsCopy}
                  />,
                  trailTitle,
                  colorScheme
                )
              }
              disabled={!trailEnabled}
              className={classnames({
                [classes.buttonFluid]: true,
                [classes.disabled]: !trailEnabled,
              })}
            >
              {trailProgress === 0 && 'Iniciar'}
              {trailProgress > 0 && trailProgress < 100 && 'Continuar'}
              {trailProgress === 100 && 'Visualizar'}
              <ArrowRight />
            </CtaButton>
            {metadata.role === 'student' && (
              <ContainedButton
                variant="cotained"
                className={classnames({
                  [classes.certButton]: true,
                  [classes.disabled]: trailProgress !== 100,
                  [classes.hidden]: !trail,
                })}
                disabled={trailProgress !== 100}
                onClick={showCertificate}
              >
                <CardMembership color="action" className={classes.progress} />
                Emitir Certificado
              </ContainedButton>
            )}
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

TrailProgressCard.propTypes = {
  metadata: shape({}).isRequired,
  classes: shape.isRequired,
  colorScheme: string.isRequired,
  iconPath: string.isRequired,
  iconDescription: string.isRequired,
  trailProgress: number.isRequired,
  openModalWithContentFn: func.isRequired,
  TrailContent: func.isRequired,
  trailTitle: string.isRequired,
  trailEnabled: bool.isRequired,
  handleIsLoadingState: func.isRequired,
  getTrailCertificateData: func.isRequired,
  trail: string.isRequired,
  openSnackbar: func.isRequired,
};

export default withStyles(cardChartStyle)(TrailProgressCard);
