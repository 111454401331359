import styled from 'styled-components';

export const Overlay = styled.div`
  align-items: ${(props) => (props.centerModal ? 'center' : 'flex-start')};
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isOpen ? `flex` : `none`)};
  background-color: rgba(0, 0, 0, ${({ alpha }) => alpha || '0.45'});
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: 2rem;
  justify-content: center;
  z-index: 1300;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  left: 130px;
  width: 680px;
  max-width: 100vw;
  background-color: #fff;
  border-radius: 12px;

  @media screen and (max-width: 963px) {
    left: 0;
  }
`;

export const Header = styled.div`
  border-radius: 11px 11px 0 0;
  display: flex;
  width: 100%;
  height: 85px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : '#009291'};
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  > span {
    font-size: 22px;
    color: #fff;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
  }
`;

export const CloseButton = styled.button`
  height: 44px;
  width: 44px;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

  &:hover {
    filter: brightness(0.9);
  }
`;
