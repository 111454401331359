import React from 'react';
import { shape, func, bool } from 'prop-types';

import { FastField } from 'formik';

import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import professionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';

const StepTwoForm = (props) => {
  const {
    classes,
    answered,
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    metadata,
  } = props;

  return (
    <List className={classes.list}>
      {/* Question 1 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>1</strong> - Como acredita que sua geração influencia na sua
          concepção de sucesso?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageTwoQuestionOne && touched.pageTwoQuestionOne,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageTwoQuestionOne"
          variant="filled"
          id="pageTwoQuestionOne"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageTwoQuestionOne && touched.pageTwoQuestionOne}
          value={values.pageTwoQuestionOne}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageTwoQuestionOne &&
            `Sua resposta tem ${values.pageTwoQuestionOne.length} caracteres.`}
        </FormHelperText>
        {errors.pageTwoQuestionOne && touched.pageTwoQuestionOne ? (
          <FormHelperText>{errors.pageTwoQuestionOne}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 2 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>2</strong> - Como acredita que sua família influencia na sua
          concepção de sucesso?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageTwoQuestionTwo && touched.pageTwoQuestionTwo,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageTwoQuestionTwo"
          variant="filled"
          id="pageTwoQuestionTwo"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageTwoQuestionTwo && touched.pageTwoQuestionTwo}
          value={values.pageTwoQuestionTwo}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageTwoQuestionTwo &&
            `Sua resposta tem ${values.pageTwoQuestionTwo.length} caracteres.`}
        </FormHelperText>
        {errors.pageTwoQuestionTwo && touched.pageTwoQuestionTwo ? (
          <FormHelperText>{errors.pageTwoQuestionTwo}</FormHelperText>
        ) : null}
      </ListItem>
      {/* Question 3 */}
      <ListItem className={classes.professionListItem}>
        <p className={`${classes.textJustify} ${classes.alignSelfStart}`}>
          <strong>3</strong> - Como acredita que o local onde atua influencia na
          sua concepção de sucesso?
        </p>
        <FastField
          className={classNames({
            [classes.inputError]:
              errors.pageTwoQuestionThree && touched.pageTwoQuestionThree,
            [classes.inputField]: true,
          })}
          InputProps={{
            className: [classes.input, classes.padding20],
          }}
          name="pageTwoQuestionThree"
          variant="filled"
          id="pageTwoQuestionThree"
          placeholder="Resposta"
          multiline
          rowsMax="15"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.pageTwoQuestionThree && touched.pageTwoQuestionThree}
          value={values.pageTwoQuestionThree}
          component={TextField}
          disabled={isSubmitting || answered || metadata.role === 'university'}
        />
        <FormHelperText className={classes.helperText}>
          {values.pageTwoQuestionThree &&
            `Sua resposta tem ${values.pageTwoQuestionThree.length} caracteres.`}
        </FormHelperText>
        {errors.pageTwoQuestionThree && touched.pageTwoQuestionThree ? (
          <FormHelperText>{errors.pageTwoQuestionThree}</FormHelperText>
        ) : null}
      </ListItem>
    </List>
  );
};

StepTwoForm.propTypes = {
  classes: shape.isRequired,
  answered: bool.isRequired,
  values: shape.isRequired,
  errors: shape.isRequired,
  touched: shape.isRequired,
  handleBlur: func.isRequired,
  handleChange: func.isRequired,
  isSubmitting: bool.isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(professionalExperienceFormStyles)(StepTwoForm);
