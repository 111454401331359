import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
`;

export const WrapperButton = styled.div`
  display: flex;
  padding: 30px 0px;
  align-items: center;
  justify-content: center;

  > button {
    color: #fff;
    border: none;
    padding: 10px 27px;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    &:active {
      transform: scale(0.9);
    }
  }
`;
