import styled from 'styled-components';
import X from 'assets/Oportutinites/X.svg';

export const ContainerForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: Roboto;
  padding: 0px 40px;

  > form {
    width: 100%;
    position: relative;
  }

  p {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: right;
    color: #606062;
  }
  .react-select__multi-value__remove {
    display: none;
  }
`;
export const ContainerInfo = styled.div`
  display: flex;
  width: 100%;
  font-family: Roboto;
  flex-wrap: nowrap;
  margin-bottom: 40px;

  span {
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding-top: 10px;

    img {
      margin-right: 5px;
    }

    p {
      margin: 0;
      text-align: left;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      color: #606062;
    }
  }
`;

export const TagLength = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: right;
  color: #606062;
  margin-top: 5px;
`;

export const ContainerButton = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: end;

  > button {
    background-color: #009291;
    border: none;
    color: #fff;
    border-radius: 4px;
    right: 0;
    cursor: pointer;
    height: 40px;
    width: 80px;

    padding: 13px;
    font-weight: bold;
  }
  > button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const Title = styled.div`
  font-size: ${(props) => (props.size ? props.size : '18px')};
  font-weight: ${(props) => (props.weight ? props.weight : 400)};
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #009291;
  margin: ${(props) => (props.margin ? props.margin : '20px 0 0')};
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  color: #009291;
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
  color: #606062;
`;
export const LabelSelect = styled.div`
  color: #6d6d6d;
  margin-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  margin-left: 2px;
`;
export const CloseButton = styled.span`
  background-color: #f0f0f0;
  width: 30px !important;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 1;
  position: absolute;
  top: 20px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
