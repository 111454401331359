import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;

  ${({ fromCard }) =>
    fromCard && 'position: absolute; bottom: 0; width: 100%;'};

  > p {
    max-width: 180px;
    text-align: center;
    word-break: break-word;
  }
`;

export const ActionContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ active, color }) => (active ? color : '#eef2f4')};
  border-radius: 4px;
  cursor: pointer;

  > svg {
    ${({ active }) => active && 'color: #FFF !important'};
  }
`;

export const InformationP = styled.p`
  font-size: 12px;
  text-align: center;
  line-height: 1.2;
`;
