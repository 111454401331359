import moment from 'moment';

export const payload = (data, requestType) => {
  if (requestType === 'patchEdit') {
    if (data.modality === 'online') {
      if (data.cep) {
        delete data.cep;
      }
      if (data.street) {
        delete data.street;
      }

      if (data.district) {
        delete data.district;
      }

      if (data.city) {
        delete data.city;
      }

      if (data.state) {
        delete data.state;
      }
    }

    if (data.modality === 'presential') {
      if (data.event_url) {
        delete data.event_url;
      }
    }
  }

  return {
    ...data,
    registration_start_date: moment(data.registration_start_date).format(
      'YYYY-MM-DD'
    ),
    registration_end_date: moment(data.registration_end_date).format(
      'YYYY-MM-DD'
    ),
    event_start_at: moment(data.event_start_at).format('YYYY-MM-DD'),
    event_end_at: moment(data.event_end_at).format('YYYY-MM-DD'),
    limit_students: String(data.limit_students)?.replace(/\D/g, ''),
  };
};
