import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: ${(props) =>
    props.miniActive ? 'calc(100vw - 80px)' : 'calc(100vw - 260px)'};
  min-height: 100%;
  height: 100%;
  background-color: #eeeeee;
  z-index: 1100;
  top: 0;
  transition: all 0.1s ease-in-out;
  position: fixed;
  flex-direction: column;
  overflow-y: ${(props) => (props.overflow ? '' : 'auto')};
  left: ${(props) => (props.miniActive ? '80px' : '260px')};

  @media (max-width: 960px) {
    width: 100%;
    left: 0;
  }

  align-items: center;
  justify-content: flex-start;
`;

export const CloseButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  display: ${({ showPreview }) => (showPreview ? 'none' : 'flex')};
`;

export const Header = styled.div`
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  width: 100%;
  min-height: 186px;

  display: flex;

  flex-direction: column;
  gap: 1em;

  padding: 0 2rem;
  justify-content: center;

  > p {
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    color: #ffffff;
  }
`;

export const LocalActions = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Content = styled.div`
  display: flex;
  max-width: ${({ showPreview }) => (showPreview ? '780px' : '1200px')};
  width: 100%;
  justify-content: center;
  margin: 2rem auto;
  gap: 2rem;
  position: relative;
  flex-direction: ${({ showPreview }) => (showPreview ? 'column' : 'row')};

  padding: 0 22px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PreviewHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  > strong {
    font-size: 24px;
    font-weight: 700px;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }

  > button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    border: none;
  }
`;

export const CurriculumSection = styled.div`
  height: 1000px;
  display: flex;
  flex: 1;
  user-select: none;
  position: relative;

  .pageCurriculum {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    order: 1;
  }
`;

export const AsideColumn = styled.div`
  width: 350px;

  display: flex;
  flex-direction: column;

  gap: 2rem;

  @media (max-width: 768px) {
    width: 100%;
    order: 0;
  }
`;

export const Actions = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;

  > h1 {
    font-size: 20px;
    font-weight: 700;
    color: #606062;
    margin-top: auto;
  }

  > p {
    font-size: 15px;
    font-weight: 400;
    color: #606062;
  }

  .phrase {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: #606062;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    gap: 10px;
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  width: 100%;

  .feedback {
    font-size: 13px;
    font-weight: 400;
    color: #949494;

    text-align: start;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    width: 100%;

    margin: 16px 0;
  }
`;

export const ActionButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 400;
  gap: 6px;
  border: none;
  border-radius: 3px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  background-color: ${({ liked }) => (liked ? '#0092911c' : '#cf00001c')};
  color: ${({ liked }) => (liked ? '#009291' : '#cf0000')};

  :disabled {
    font-size: 14px;
    cursor: default;
    transition: 0.5s;
  }
`;

export const Preferences = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;

  > h1 {
    font-size: 20px;
    font-weight: 700;
    color: #606062;
    margin-top: auto;
  }

  > p {
    font-size: 15px;
    font-weight: 400;
    color: #606062;
  }
`;

export const PrimaryButton = styled.button`
  width: 100%;
  height: 44px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  border-radius: 5px;
  border: none;
  outline: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
`;

export const CleanButton = styled.button`
  width: 100%;
  height: 44px;
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
`;

export const SimpleButton = styled.button`
  border: none;
  background-color: #ffffff;
  text-decoration: underline;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;

  &:disabled {
    color: #e3e3e3;
    cursor: default;
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 10px;

  > input {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    cursor: pointer;

    background-color: #ccc;
    border: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};

    &:checked {
      background-color: ${(props) =>
        props.universityColor ? props.universityColor : '#009291'};
      border: 2px solid
        ${(props) =>
          props.universityColor ? props.universityColor : '#009291'};
    }
  }
`;

export const HeaderButton = styled.button`
  position: relative;
  border: none;
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #949494;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  margin-right: 16px;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ImageIcon = styled.img`
  width: 20px;
  height: 36px;
`;

export const ImageExitIcon = styled.img`
  width: 15px;
  height: 15px;
`;

export const ButtonLogout = styled.button`
  width: 89px;
  height: 40px;
  border-radius: 24px;
  background-color: #fff;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 16px;
  font-weight: 400;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  margin-left: 20px;
`;

export const ButtonNotification = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const SpanNew = styled.div`
  width: 46px;
  height: 23px;
  font-size: 10px;
  color: #fff;
  background-color: #3492e9;
  font-weight: 400;
  border: none;
  border-radius: 3.3px;
  display: flex;
  align-items: center;
  margin-left: 8px;
  justify-content: center;
`;

export const HeaderPreference = styled.div`
  display: flex;

  > h1 {
    font-size: 20px;
    font-weight: 700;
    color: #606062;
    margin-top: auto;
  }
`;
