export const mentorAdapter = (mentor) => {
  let availableDays = (availableDays = {});
  if (mentor.available_days) {
    if (mentor.available_days instanceof Array) {
      mentor.available_days?.forEach((item) => {
        availableDays[item.day] = item.times;
      });
    } else {
      availableDays[mentor.available_days.day] = mentor.available_days.times;
    }
  }

  return {
    ...mentor,
    available_days: availableDays,
  };
};
