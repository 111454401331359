const FinishProjectAdapterForm = ({
  externalPublic,
  impactedPublic,
  score,
}) => ({
  external_public: externalPublic,
  impacted_public: impactedPublic,
  scores: {
    score: Number(score),
  },
});

export { FinishProjectAdapterForm };
