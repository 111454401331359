import styled from 'styled-components';

export const Dots = styled.button`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) =>
    props.isActive ? props.universityColor : '#c4c4c4'};
  cursor: pointer;
`;

export const ContainerDots = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
