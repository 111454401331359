import React, { useContext } from 'react';
import { OportunitiesContext } from '../../Context/OportunitiesContext';
import { FormExtensionProjectContainer } from '../../Styles/Styled';
import { StepOne } from '../Steps/ExtensionProject/StepOne';
import { StepTwo } from '../Steps/ExtensionProject/StepTwo';
import { StepThree } from '../Steps/ExtensionProject/StepThree';

export default function FormExtensionProject() {
  const { step, setStep } = useContext(OportunitiesContext);

  const prevFormStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  function renderStep() {
    switch (step) {
      case 0:
        return <StepOne />;
      case 1:
        return <StepTwo prevFormStep={prevFormStep} />;
      case 2:
        return <StepThree prevFormStep={prevFormStep} />;
    }
  }

  return (
    <FormExtensionProjectContainer>
      {renderStep()}
    </FormExtensionProjectContainer>
  );
}
