import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';
import { ContainerLabelInput, ErrorWrapper } from './style';
import { UnInputLabel } from './style';
import { JobOfferContext } from '../../context/jobOfferContext';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function SearchableSelectCitiesUn({
  label,
  name,
  options,
  placeholder,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  const { cacheContext } = useContext(JobOfferContext);
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderColor: error ? '#FFCECE' : '#ced4da',
      backgroundColor: error ? '#FFEDED' : '#fff',
    }),
  };
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.id);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value.id;
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <Select
        defaultValue={
          defaultValue &&
          defaultValue
            .map((item) =>
              cacheContext.filter((itemF) => String(itemF.id) === String(item))
            )
            .flat()
        }
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        onFocus={clearError}
        classNamePrefix="react-select"
        styles={selectStyles}
        {...rest}
      />
      <ErrorWrapper style={{ marginTop: '5px' }}>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
