import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;

  margin-bottom: 25px;

  position: relative;

  @media(max-width: 1356px) {
    flex-direction: column;
  }
`



export const Wrapper = styled.div`
  width: 100%;

  svg {
    flex: none;
    margin-right: 14px;
  }

  .box-info {
    width: 100%;

    display: flex;
    align-items: flex-start;

    margin-top: 20px;
  }

  .box-info:first-child {
    margin-right: 120px;
  }

  .box-info button {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-size: 12px;
    color: #4B4B4D;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .column {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .column span {
    font-size: 14px;
    font-weight: 400;
    color: #009291;
  }

  .column p {
    font-size: 12px;
    font-weight: 400;
    color: #4B4B4D;
    margin: 0;
  }




`