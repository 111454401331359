import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 65px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 100%;
  gap: 20px;
  margin-bottom: 80px;
`;

export const Title = styled.span`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  font-size: 20px;
`;

export const Button = styled.button`
  border-radius: 4px;
  border: none;
  height: 40px;
  width: 20%;
  background-color: #009291;
  color: #fff;
  margin-top: -30px;
  font-size: 15px;
  cursor: pointer;

  @media screen and (max-width: 959px) {
    width: auto;
  }
`;
