export default function ResultValuesIcon({ color }) {
  return (
    <svg
      width="27"
      height="14"
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9647 8.94363C18.3585 6.55665 20.7373 4.18477 23.1611 1.75247C22.9965 1.75247 22.9067 1.75247 22.817 1.75247C21.261 1.75247 19.705 1.75247 18.149 1.75247C17.4907 1.75247 17.0718 1.13306 17.3112 0.54387C17.4608 0.196398 17.7301 0 18.1191 0C20.5129 0 22.8918 0 25.2856 0C25.7793 0 26.1533 0.37769 26.1533 0.876236C26.1533 3.29343 26.1533 5.69552 26.1533 8.11272C26.1533 8.61126 25.7643 8.98895 25.3005 8.98895C24.8218 8.98895 24.4328 8.61126 24.4178 8.09761C24.4178 6.51132 24.4178 4.94015 24.4178 3.35386C24.4178 3.24811 24.4178 3.15747 24.4178 2.99128C24.3131 3.08193 24.2532 3.14236 24.1784 3.2179C21.7697 5.61998 19.3609 8.02207 16.9671 10.4091C16.8923 10.4846 16.8175 10.5601 16.7427 10.6357C16.3537 11.0134 15.9497 11.0587 15.5009 10.7414C14.1843 9.78965 12.8677 8.85299 11.5511 7.90121C10.7133 7.29691 9.87543 6.69262 9.0376 6.10343C8.8431 6.29982 8.6486 6.466 8.46907 6.6624C6.20991 8.98895 3.96572 11.3306 1.72152 13.6723C1.43726 13.9593 1.12307 14.0802 0.734078 13.9442C0.120665 13.7327 -0.0439101 12.9773 0.41989 12.4939C1.34749 11.527 2.27509 10.5752 3.20269 9.60836C4.84844 7.90121 6.47922 6.20918 8.12496 4.50203C8.66357 3.94305 8.99271 3.91284 9.60613 4.36607C11.6708 5.8466 13.7354 7.34224 15.8151 8.82277C15.845 8.85299 15.8899 8.8832 15.9647 8.94363Z"
        fill={color}
      />
    </svg>
  );
}
