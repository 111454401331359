import React from 'react';

import {Container, Wrapper} from './styled'

export default function MacroAreas({ item }) {
  const returnMacroAreas = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].macro_areas &&
        item.target_audiences[0].macro_areas.length
      ) {
        const result = item.target_audiences[0].macro_areas.map(
          (item) => item.label
        );
        return result.join(', ');
      }
    }
    return 'Todas as áreas de atuação';
  };

  const returnCourses = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].courses &&
        item.target_audiences[0].courses.length
      ) {
        const result = item.target_audiences[0].courses.map(
          (item) => item.label
        );
        return result.join(', ');
      }
    }
    return 'Todos os cursos';
  };

  const returnPeriods = () => {
    if (item.target_audiences && item.target_audiences.length) {
      if (
        item.target_audiences[0].semesters &&
        item.target_audiences[0].semesters.length
      ) {
        let result = item.target_audiences[0].semesters.map((item) => item);
        if (result.length <= 1) {
          return (result = result + 'º Semestre');
        }
        const arrayLength = result.length;
        result[arrayLength - 1] = `${result[arrayLength - 1] + 'º Semestre'}`;

        return result.join('º Semestre, ');
      }
    }
    return 'Todos os períodos';
  };

  return (
    <Container>
      <div className="container-wrapper ">
        <h3> Público-Alvo </h3>
        <div className="container-box">
          <Wrapper>
            <h6> Áreas de atuação relacionadas: </h6>
            <p> {returnMacroAreas()} </p>
          </Wrapper>
          <Wrapper>
            <h6> Cursos relacionados: </h6>
            <p> {returnCourses()} </p>
          </Wrapper>
          <Wrapper>
            <h6> Período: </h6>
            <p> {returnPeriods()} </p>
          </Wrapper>
        </div>
      </div>
    </Container>
  );
}
