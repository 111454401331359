import {
  AreaAmount,
  Container,
  GrayGradient,
  IconWrapper,
  MentorImage,
  Row,
  ScheduleButton,
  ShowDetailsButton,
  TextName,
} from './styled';
import { HouseLaptopIcon } from 'assets/mentorship/HouseLaptopIcon';
import { useContext, useMemo } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { UniversityHatIcon } from 'assets/mentorship/UniversityHatIcon';
import userIcon from 'assets/img/userIcon.png';
import { BrainIcon } from 'assets/mentorship/BrainIcon';

export function MentorCard(props) {
  const { mentor, onSchedule, openMentorModal } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  const modalityDescription = useMemo(() => {
    const modalities = mentor.mentorship_modality?.map(
      (modality) => modality.value
    );

    if (modalities?.length === 1) {
      if (modalities[0] === 'in_person') {
        return 'Mentorias presenciais';
      } else if (modalities[0] === 'online') {
        return 'Mentorias online';
      }
    } else if (
      modalities?.includes('in_person') &&
      modalities?.includes('online')
    ) {
      return 'Presencial e online';
    }

    return '';
  }, [mentor]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <Container>
      <GrayGradient />
      <MentorImage
        src={mentor?.avatar || userIcon}
        alt=""
        onError={(e) => {
          e.target.src = userIcon;
          e.target.onerror = null;
        }}
        margin={'13px auto 0 auto'}
      />
      <TextName color={universityColor} onClick={() => openMentorModal(mentor)}>
        {mentor.name}
      </TextName>
      <Row>
        <IconWrapper color={universityColor}>
          <UniversityHatIcon color={universityColor} />
        </IconWrapper>
        <span>{mentor?.macro_areas?.[0]?.label}</span>
        {mentor?.macro_areas_count > 1 && (
          <AreaAmount
            backgroundColor={universityColor}
            onClick={() => openMentorModal(mentor)}
          >
            {`+${mentor.macro_areas_count - 1}`}
          </AreaAmount>
        )}
      </Row>
      <Row>
        <IconWrapper color={universityColor}>
          <BrainIcon color={universityColor} />
        </IconWrapper>
        <span>{capitalizeFirstLetter(mentor?.specialities[0])}</span>
        {mentor?.specialities_count > 1 && (
          <AreaAmount
            backgroundColor={universityColor}
            onClick={() => openMentorModal(mentor)}
          >
            {`+${mentor.specialities_count - 1}`}
          </AreaAmount>
        )}
      </Row>
      <Row>
        <IconWrapper color={universityColor}>
          <HouseLaptopIcon color={universityColor} />
        </IconWrapper>
        <span className="modality-description">{modalityDescription}</span>
      </Row>
      <Row marginBottom={'0.5rem'}>
        <ShowDetailsButton
          color={universityColor}
          onClick={() => openMentorModal(mentor)}
        >
          Detalhes
        </ShowDetailsButton>
        <ScheduleButton
          color={universityColor}
          onClick={() => {
            onSchedule(mentor);
          }}
        >
          Agendar
        </ScheduleButton>
      </Row>
    </Container>
  );
}
