const defineDataProductsUrl = () => {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'http://localhost:5000';
  }

  if (hostname.includes('worka.local')) {
    return 'http://localhost:5000';
  }

  if (hostname.includes('-dev') || hostname.includes('.dev')) {
    return 'https://data-products-dev.worka.love';
  }

  if (hostname.includes('-stg') || hostname.includes('.stg')) {
    return 'https://data-products-stg.worka.love';
  }

  if (hostname.includes('-hmg') || hostname.includes('.hmg')) {
    return 'https://data-products-hmg.worka.love';
  }

  return 'https://data-products-prd.worka.love';
};

export default defineDataProductsUrl;
