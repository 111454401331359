export default function AdvancedLanguageIcon({ color }) {
  return (
    <svg
      width="29"
      height="25"
      viewBox="0 0 29 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9486 8.51129V8.96122C28.7325 9.51185 28.323 9.69985 27.7356 9.69844C20.6337 9.68811 13.5315 9.68577 6.4291 9.6914C5.83184 9.6914 5.46555 9.43932 5.34794 8.95066C5.31996 8.82978 5.3167 8.70455 5.33835 8.58239C5.36001 8.46023 5.40614 8.34364 5.474 8.23955C5.54187 8.13545 5.6301 8.04597 5.73344 7.97641C5.83677 7.90685 5.95312 7.85864 6.07557 7.83463C6.23373 7.81019 6.39378 7.79983 6.5538 7.80365C13.5991 7.80365 20.6448 7.80083 27.691 7.7952C28.2755 7.79449 28.7119 7.95503 28.9486 8.51129Z"
        fill={color}
      />
      <path
        d="M28.9486 1.03798V1.48721C28.7183 2.05051 28.2812 2.21387 27.6959 2.21316C20.6313 2.20331 13.567 2.20143 6.50283 2.20753C6.25628 2.20753 6.01326 2.20119 5.79434 2.06671C5.45851 1.8611 5.29839 1.55974 5.32886 1.17247C5.34528 0.984713 5.4187 0.806347 5.53941 0.660992C5.66011 0.515636 5.82239 0.410148 6.00476 0.358498C6.16971 0.318523 6.33967 0.302864 6.50921 0.312026C13.5828 0.312026 20.6564 0.309914 27.73 0.305689C28.3123 0.304985 28.7275 0.488761 28.9486 1.03798Z"
        fill={color}
      />
      <path
        d="M28.9488 23.5183V23.9675C28.7115 24.5238 28.2757 24.6822 27.6905 24.6808C20.6448 24.6695 13.5988 24.6672 6.55265 24.6738C6.35498 24.6738 6.15589 24.6815 5.9653 24.6188C5.76305 24.5496 5.59016 24.4147 5.47448 24.2358C5.3588 24.057 5.30708 23.8447 5.32766 23.6331C5.35062 23.4168 5.44758 23.2149 5.60232 23.0611C5.75706 22.9073 5.96022 22.8109 6.17785 22.7881C6.28979 22.7776 6.40386 22.7818 6.51722 22.7818C13.5908 22.7818 20.6646 22.7794 27.7387 22.7747C28.3239 22.779 28.7334 22.9648 28.9488 23.5183Z"
        fill={color}
      />
      <path
        d="M28.949 16.0427V16.4363C28.7499 16.9841 28.3574 17.1841 27.7743 17.1834C20.661 17.1731 13.548 17.1705 6.4352 17.1757C5.8344 17.1757 5.48583 16.9468 5.3583 16.4715C5.32274 16.3404 5.31661 16.2032 5.34034 16.0694C5.36407 15.9357 5.41708 15.8088 5.49562 15.6977C5.57416 15.5866 5.67632 15.494 5.79485 15.4265C5.91339 15.359 6.0454 15.3181 6.18157 15.3069C6.28501 15.2992 6.38916 15.2999 6.4926 15.2999C13.5865 15.2999 20.6809 15.2975 27.7757 15.2928C28.3581 15.2936 28.7513 15.4928 28.949 16.0427Z"
        fill={color}
      />
      <path
        d="M2.55885 16.2769C2.55565 16.4451 2.51848 16.6109 2.44953 16.7646C2.38058 16.9182 2.28126 17.0565 2.15748 17.1712C2.03369 17.286 1.88798 17.3748 1.72897 17.4325C1.56996 17.4902 1.40091 17.5155 1.23186 17.507C0.894713 17.4945 0.576174 17.3502 0.345565 17.1054C0.114956 16.8607 -0.00905883 16.5353 0.000515941 16.2001C0.00541339 16.0319 0.0439855 15.8663 0.113975 15.713C0.183966 15.5597 0.283973 15.4219 0.408163 15.3074C0.532353 15.193 0.678239 15.1042 0.837301 15.0464C0.996363 14.9886 1.16541 14.9629 1.33459 14.9707C1.50146 14.976 1.66565 15.014 1.81767 15.0826C1.96969 15.1512 2.10654 15.2491 2.22034 15.3705C2.33414 15.4919 2.42264 15.6345 2.48074 15.7901C2.53884 15.9456 2.56539 16.1111 2.55885 16.2769Z"
        fill={color}
      />
      <path
        d="M2.55943 8.79035C2.55033 9.12504 2.40908 9.44273 2.1662 9.67475C1.92332 9.90678 1.59834 10.0345 1.26147 10.0303C0.918827 10.0218 0.593571 9.87854 0.357124 9.63193C0.120677 9.38533 -0.00763812 9.05555 0.000351994 8.71501C0.0110971 8.38038 0.153975 8.06342 0.398075 7.83268C0.642174 7.60195 0.967869 7.47601 1.30469 7.4821C1.47426 7.48367 1.64178 7.51904 1.79735 7.58611C1.95291 7.65319 2.09335 7.75061 2.21035 7.8726C2.32735 7.99459 2.41853 8.13867 2.47849 8.29631C2.53845 8.45395 2.56598 8.62195 2.55943 8.79035Z"
        fill={color}
      />
      <path
        d="M2.56104 1.25093C2.5615 1.58636 2.42975 1.90863 2.19402 2.14869C1.95829 2.38875 1.63733 2.52752 1.2999 2.53525C0.602737 2.55144 0.000513458 1.95998 0.000513458 1.25585C-0.00054546 1.08968 0.031809 0.92497 0.095675 0.771392C0.159541 0.617813 0.253629 0.478462 0.372425 0.361516C0.49122 0.24457 0.632326 0.152386 0.787458 0.0903743C0.94259 0.028363 1.10863 -0.00222577 1.27582 0.000403396C1.44349 -0.00383842 1.61031 0.0254093 1.7664 0.086414C1.92249 0.147419 2.06468 0.238939 2.18452 0.355553C2.30437 0.472167 2.39945 0.6115 2.46413 0.7653C2.5288 0.9191 2.56175 1.08424 2.56104 1.25093Z"
        fill={color}
      />
      <path
        d="M2.56099 23.7328C2.55987 24.0686 2.42515 24.3903 2.18623 24.6278C1.94731 24.8652 1.62358 24.9991 1.2857 25.0002C1.11648 25.0003 0.948909 24.9672 0.792614 24.9027C0.63632 24.8383 0.494383 24.7437 0.374958 24.6246C0.255532 24.5054 0.160978 24.364 0.0967161 24.2084C0.0324543 24.0529 -0.000245934 23.8862 0.000500787 23.718C0.00188147 23.5517 0.0363861 23.3872 0.102026 23.2342C0.167666 23.0811 0.263143 22.9425 0.382949 22.8263C0.502755 22.7102 0.64452 22.6187 0.800069 22.5572C0.955618 22.4958 1.12187 22.4655 1.28924 22.4682C1.45649 22.4675 1.62222 22.4998 1.77686 22.5631C1.93149 22.6265 2.07198 22.7196 2.19021 22.8372C2.30845 22.9547 2.40209 23.0944 2.46572 23.2481C2.52936 23.4019 2.56174 23.5666 2.56099 23.7328Z"
        fill={color}
      />
    </svg>
  );
}
