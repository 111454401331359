import React, { Fragment, useContext, useEffect, useState } from 'react';

import {
  FooterButtons,
  ButtonX,
  Container,
  Description,
  DividerResponsive,
  DivX,
  GridSide,
  Image,
  ItemWrapper,
  Title,
  WrapperContent,
  WrapperHeader,
  WrapperInformartions,
  WrapperMoreInfo,
  RegisterButton,
  ReactionsButton,
  FooterEvidence,
  AlignedItemWrapper,
  ListItem,
} from './style';
import { RiCloseFill } from 'react-icons/ri';
import { IoCalendarOutline, IoExtensionPuzzleOutline } from 'react-icons/io5';
import ReactHtmlParser from 'react-html-parser';

import { AiOutlineClockCircle, AiOutlineStar } from 'react-icons/ai';
import { IoMdPin } from 'react-icons/io';

import Alvo from 'assets/Oportutinites/Alvo.svg';

import { BiDislike, BiLike } from 'react-icons/bi';
import Reactions from '../../ListProjectExtesion/components/reactions';
import { getDetails } from 'views/Student/Extracurricular/services';
import moment from 'moment';
import ScoreIcon from 'assets/Oportutinites/ScoreIcon';
import { ExtensionProjectsStudentContext } from 'views/Student/Learning/Context/ExtensionProjectStudentContext';
import {
  ButtonGreen,
  ButtonWhite,
} from '../../ListSubscribeProjects/components/Card/style';
import BaseLayoutContext from 'contexts/base-layout';
import TargetIcon from 'assets/ComponentIcons/TargetIcon';
import { GraduationCapIcon } from 'assets/ImgsLogin/IconsLogin';
import { GraduationIcon } from 'assets/ComponentIcons/GraduationIcon';
import GraduationCap from 'assets/Oportutinites/Icons/GraduationCap';

export function MoreInformationModalStudent({
  isOpen,
  informations,
  refCloseModal,
  setModalDetailsOpen,
  setModalDetailsItem,
}) {
  const { usingPreview, setUsingPreview } = useContext(
    ExtensionProjectsStudentContext
  );

  const {universityColor} = useContext(BaseLayoutContext)

  const closeModalClickOutside = (e) => {
    if (refCloseModal.current === e.target) {
      setModalDetailsOpen(false), setModalDetailsItem({});
    }
  };

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
  };

  const formatWorkload = (workload) => {
    if (workload) {
      const split = workload?.split('.');
      if (split[1] === '00') {
        return `${split[0]}h`;
      }
      return `${split[0]}h${split[1]}`;
    }
  };

  const handleClickSubscribe = () => {
    setModalDetailsOpen(false), setModalDetailsItem({});
    informations.handleClickSubscribe();
  };

  const closeModalX = () => {
    setModalDetailsOpen(false), setModalDetailsItem({});
  };

  return (
    <Container isOpen={isOpen} usingPreviewPdf={usingPreview}>
      <div className="box" ref={refCloseModal} onClick={closeModalClickOutside}>
        <WrapperMoreInfo isOpen={isOpen}>
          <WrapperHeader>
            {informations.attraction_image &&
            informations.attraction_image.logo ? (
              <Image universityColor={universityColor}>
                <img src={informations.attraction_image.image} />
              </Image>
            ) : (
              <Image>
                {informations.attraction_image && (
                  <img
                    src={informations.attraction_image.image}
                    style={{
                      width: '100%',
                      height: '100%',
                      borderTopLeftRadius: '11.5652px',
                      borderTopRightRadius: '11.5652px',
                      position: 'absolute',
                      objectFit: 'cover',
                      top: 0,
                    }}
                  />
                )}
              </Image>
            )}
          </WrapperHeader>
          <DivX>
            <ButtonX onClick={closeModalX}>
              <RiCloseFill size={25} />
            </ButtonX>
          </DivX>
          <WrapperContent>
            <Title> {informations.name} </Title>
            <Description>
              {informations.description &&
                ReactHtmlParser(
                  informations.description.replace(/style="[^"]*"/g, '')
                )}
            </Description>
            <WrapperInformartions>
              <div className="box-view">
                <GridSide>
                  <ItemWrapper universityColor={universityColor}>
                    <div className="container">
                      <GraduationCap size={34} color={universityColor || '#009291'}/>
                      <div className="text-wrapper">
                        <strong> Postado por: </strong>
                        <p>{informations.university}</p>
                      </div>
                    </div>
                  </ItemWrapper>

                  <ItemWrapper universityColor={universityColor}>
                    <div className="container">
                      <i>
                        {' '}
                        <AiOutlineClockCircle size={30} color={universityColor || '#009291'} />{' '}
                      </i>
                      <div className="text-wrapper">
                        <strong> Carga horária de extensão: </strong>
                        <p>{formatWorkload(informations.workload)}</p>
                      </div>
                    </div>
                  </ItemWrapper>

                  <ItemWrapper
                    universityColor={universityColor}
                    style={{ marginLeft: '-2px', maxWidth: '220px' }}
                  >
                    <div className="container">
                      <i>
                        <IoMdPin size={30} color={universityColor || '#009291'} />
                      </i>
                      <div className="text-wrapper">
                        <strong> Local do projeto: </strong>
                        <p>
                          {informations.address &&
                            informations.address.street &&
                            `${informations.address.street},`}
                          {informations.address &&
                            informations.address.number &&
                            ` ${informations.address.number},`}
                          {informations.address &&
                            informations.address.complement &&
                            ` ${informations.address.complement},`}
                          {informations.address &&
                            informations.address.city &&
                            ` ${informations.address.city},`}
                          {informations.address &&
                            informations.address.state &&
                            ` ${informations.address.state},`}
                          {informations.address &&
                            informations.address.zip_code &&
                            ` ${informations.address.zip_code}`}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>
                </GridSide>

                <GridSide height="150px">
                  <ItemWrapper universityColor={universityColor}>
                    <div className="container" style={{ alignItems: 'center' }}>
                      <i style={{ marginTop: '10px' }}>
                        <IoCalendarOutline size={30} color={universityColor || '#009291'} />
                      </i>
                      <div className="text-wrapper">
                        <strong style={{ marginLeft: '-4px' }}>
                          Data de realização:
                        </strong>
                        <p style={{ marginLeft: '-4px' }}>
                          De {formatDate(informations.start_at)} a{' '}
                          {formatDate(informations.end_at)}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>
                  <ItemWrapper universityColor={universityColor}>
                    <div className="container">
                      <i style={{ marginTop: '10px' }}>
                        <IoExtensionPuzzleOutline size={28} color={universityColor || '#009291'} />
                      </i>
                      <div className="text-wrapper">
                        <strong> Modalidade: </strong>
                        <p>{informations.type}</p>
                      </div>
                    </div>
                  </ItemWrapper>

                  {informations.score && (
                    <ItemWrapper universityColor={universityColor}>
                      <div className="container">
                        <ScoreIcon size={30} />
                        <div className="text-wrapper">
                          <strong> Nota recebida: </strong>
                          <p>{informations.score}</p>
                        </div>
                      </div>
                    </ItemWrapper>
                  )}
                  <ItemWrapper universityColor={universityColor}>
                    <div className="container" style={{ alignItems: 'center' }}>
                      <i style={{ marginTop: '10px' }}>
                        <IoCalendarOutline size={30} color={universityColor || '#009291'} />
                      </i>
                      <div className="text-wrapper">
                        <strong style={{ marginLeft: '-4px' }}>
                          Publicado em:
                        </strong>
                        <p style={{ marginLeft: '-4px' }}>
                          {formatDate(informations.created_at)}
                        </p>
                      </div>
                    </div>
                  </ItemWrapper>
                </GridSide>
              </div>
              {informations.development_objective &&
                informations.development_objective.length > 0 && (
                  <AlignedItemWrapper universityColor={universityColor}>
                    <div className="container">
                      <div className="title-wrapper">
                        <TargetIcon color={universityColor}/>
                        <strong>
                          {' '}
                          Objetivos de Desenvolvimento Sustentável:{' '}
                        </strong>
                      </div>
                      <div className="text-wrapper">
                        <ul
                          className="list"
                          style={{
                            width: '100%',
                            flexWrap: 'wrap',
                          }}
                        >
                          {(informations.development_objective &&
                            informations.development_objective.map((item) => (
                              <ListItem universityColor={universityColor}>{item}</ListItem>
                            ))) || (
                            <p style={{ fontSize: '12px' }}>Não informado</p>
                          )}
                        </ul>
                      </div>
                    </div>
                  </AlignedItemWrapper>
                )}
            </WrapperInformartions>
            {informations.orientation && (
              <ItemWrapper universityColor={universityColor}>
                <div className="container">
                  <div className="text-wrapper">
                    <strong style={{ marginBottom: '10px' }}>
                      Orientações do passo a passo:
                    </strong>
                    <Description>
                      {(informations.orientation &&
                        ReactHtmlParser(informations.orientation)) || (
                        <p style={{ fontSize: '12px' }}>Não informado</p>
                      )}
                    </Description>
                  </div>
                </div>
              </ItemWrapper>
            )}
          </WrapperContent>
          {informations.isNotFullEnrroled && (
            <Fragment>
              <div style={{ padding: '0 32px' }}>
                <DividerResponsive></DividerResponsive>
              </div>
              <FooterButtons>
                <RegisterButton
                  universityColor={universityColor}
                  onClick={handleClickSubscribe}
                >
                  Inscreva-se
                </RegisterButton>
                <Reactions
                  id={informations.id}
                  item={informations}
                  getExtesionProject={informations.getExtesionProject}
                  limit={informations.limit}
                />
              </FooterButtons>
            </Fragment>
          )}
          {informations.evidences_quantity > 0 && (
            <Fragment>
              <DividerResponsive></DividerResponsive>
              <FooterEvidence universityColor={universityColor}>
                <button
                  className="buttonGreen"
                  onClick={() => informations.handleClickShowEvidence()}
                >
                  Visualizar evidências ({informations.evidences_quantity})
                </button>
                {informations.publish_evidence && (
                  <button
                    className="buttonWhite"
                    onClick={() => informations.handleClickUploadEvidence(true)}
                  >
                    Adicionar evidências
                  </button>
                )}
              </FooterEvidence>
            </Fragment>
          )}
        </WrapperMoreInfo>
      </div>
    </Container>
  );
}
