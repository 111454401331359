import mixpanel from 'mixpanel-browser';

mixpanel.init('edeebf14b95b80a08c90a6a40cc428e0');

const env_check = process.env.NODE_ENV === 'production';

const actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export function onExitingModalEvent(modalName) {
  const exitModalAction = {
    lifeInterest: () => {
      actions.track('Abandonou Formulário Interesses de Vida');
    },
    careerInterest: () => {
      actions.track('Abandonou Formulário Interesses de Carreira');
    },
    personality: () => {
      actions.track('Abandonou Teste de Personalidade');
    },
    behavioral: () => {
      actions.track('Abandonou Teste Comportamental');
    },
    valuesTest: () => {
      actions.track('Abandonou Teste de Valores');
    },
    extracourse: () => {
      actions.track('Abandonou Formulário de Formação e Capacitação');
    },
    portfolio: () => {
      actions.track('Abandonou Formulário de Portfólio e Projetos');
    },
    coverletter: () => {
      actions.track('Abandonou - Criação da Carta de apresentação');
    },
    careerExperience: () => {
      actions.track('Abandonou Formulário de Experiência de Carreira');
    },
    lifeExperience: () => {
      actions.track('Abandonou Formulário de Experiência de Vida');
    },
    default: () => {
      return null;
    },
  };

  return (exitModalAction[modalName] || exitModalAction.default)();
}

export const Mixpanel = actions;
