import styled from 'styled-components';

export const ContainerPdf = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DownloadButton = styled.button`
  margin-bottom: 5em;
  width: 193px;
  height: 42px;
  font-weight: bold;
  color: #ffff;
  border: none;
  border-radius: 4px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;
`;
