import DownBuilding from '../../../../../../../assets/FairAndEvents/downBuilding';
import moment from 'moment';
import { BiSolidTagX } from 'react-icons/bi';
import { BsFillPersonXFill, BsPersonCheckFill } from 'react-icons/bs';
import { FaHourglassEnd } from 'react-icons/fa';
import {
  FaHourglass,
  FaRegCalendarCheck,
  FaRegCalendarXmark,
} from 'react-icons/fa6';
import { IoPersonSharp } from 'react-icons/io5';
import { LuUserCheck } from 'react-icons/lu';

const verifyRegistrationDateLimit = (
  startDate,
  endDate,
  hasStudentLimit,
  studentLimit,
  registredStudents,
  tab
) => {
  const newStartDate = new Date(startDate + 'T00:00:00').getTime();
  const newEndDate = new Date(endDate + 'T23:59:59').getTime();
  const currentDate = new Date().getTime();

  if (currentDate < newStartDate) {
    return 'awatingStartRegistrationPeriod';
  }

  if (currentDate >= newStartDate && currentDate <= newEndDate) {
    if (
      hasStudentLimit &&
      studentLimit > 0 &&
      registredStudents >= studentLimit
    ) {
      return 'noVocanciesAvaiable';
    }
    return 'withinRegistrationPeriod';
  }

  if (currentDate > newStartDate) {
    return 'AwatingEventStart';
  }
};

export const CardFormatHelper = {
  formatStatus: (item) => {
    const staticStatus = {
      awatingStartRegistrationPeriod: {
        label: 'Aguardando início das inscrições',
        background: '#8D8D8D',
        icon: <FaHourglass />,
      },
      noVocanciesAvaiable: {
        label: 'Vagas esgotadas',
        background: '#8D8D8D',
        icon: <FaRegCalendarXmark />,
      },
      AwatingEventStart: {
        label: 'Aguardando início do evento',
        background: '#B8B8B8',
        icon: <FaHourglass />,
      },
      withinRegistrationPeriod: {
        label: 'Dentro do período de inscrição',
        background: '#323232',
        icon: <LuUserCheck />,
      },
    };

    const {
      registration_end_date,
      registration_start_date,
      limit_students,
      registred_students,
    } = item;
    const status = verifyRegistrationDateLimit(
      registration_start_date,
      registration_end_date,
      true,
      limit_students,
      registred_students
    );

    return staticStatus[status];
  },

  formatLabelDate: (item) => {
    const {
      registration_end_date,
      event_start_at,
      registration_start_date,
      limit_students,
      registred_students,
      event_end_at,
    } = item;
    const status = verifyRegistrationDateLimit(
      registration_start_date,
      registration_end_date,
      true,
      limit_students,
      registred_students
    );

    if (status === 'noVocanciesAvaiable') {
      return {
        subLabel: `De ${moment(event_start_at).format('DD/MM/YYYY')} a ${moment(
          event_end_at
        ).format('DD/MM/YYYY')}`,
        canSubscribe: false,
      };
    }

    if (status === 'withinRegistrationPeriod') {
      return {
        label: `Inscrições abertas até: ${moment(registration_end_date).format(
          'DD/MM/YYYY'
        )}`,
        canSubscribe: true,
        subLabel: `De ${moment(event_start_at).format('DD/MM/YYYY')} a ${moment(
          event_end_at
        ).format('DD/MM/YYYY')}`,
      };
    }
    if (status === 'AwatingEventStart') {
      return {
        label: `Início do evento: ${moment(event_start_at).format(
          'DD/MM/YYYY'
        )}`,
        canSubscribe: true,
        subLabel: `De ${moment(event_start_at).format('DD/MM/YYYY')} a ${moment(
          event_end_at
        ).format('DD/MM/YYYY')}`,
      };
    }
    if (status === 'awatingStartRegistrationPeriod') {
      return {
        label: `Início das inscrições: ${moment(registration_start_date).format(
          'DD/MM/YYYY'
        )}`,
        canSubscribe: true,
        subLabel: `De ${moment(event_start_at).format('DD/MM/YYYY')} a ${moment(
          event_end_at
        ).format('DD/MM/YYYY')}`,
      };
    }
  },

  formatDate: (item) => {
    const { event_start_at, event_end_at } = item;
    return `De ${moment(event_start_at).format('DD/MM/YYYY')} a ${moment(
      event_end_at
    ).format('DD/MM/YYYY')}`;
  },

  imageStatus: (item) => {
    const { limit_students, registred_students } = item;
    if (registred_students === 0) {
      return 'Seja o primeiro a se inscrever!';
    }
    if (limit_students > 0 && registred_students === limit_students) {
      return `${registred_students}/${limit_students} vagas preenchidas`;
    }
    if (registred_students > 0) {
      return `${registred_students} ${
        registred_students > 1 ? 'estudantes ' : 'estudante '
      } ${registred_students > 1 ? 'inscritos' : 'inscrito'} `;
    }
  },

  formatFinishedStatus: (item, statustest) => {
    const { event_status } = item || {};

    const status = {
      presence_confirmed: {
        label: 'Presença confirmada',
        icon: <BsPersonCheckFill />,
        background: '#323232',
      },
      presence_not_confirmed: {
        label: 'Presença não confirmada',
        icon: <BsFillPersonXFill />,
        background: '#8D8D8D',
      },
      awaiting_presence_confirmation: {
        label: 'Aguardando confirmação de presença',
        icon: <FaHourglassEnd />,
        background: '#B8B8B8',
      },
      cancelled_by_institution: {
        label: 'Evento cancelado pela instituição',
        icon: <DownBuilding size="20" color="#FFF" />,
        background: '#323232',
      },
      presence_cancelled_by_you: {
        label: 'Presença cancelada por você',
        icon: <IoPersonSharp />,
        background: '#8D8D8D',
      },
      removed_from_event: {
        label: 'Você foi removido do evento',
        icon: <BiSolidTagX size="18" />,
        background: '#B8B8B8',
      },
    };

    const currentStatus =
      status[event_status] || status[statustest] || 'Status não definido';

    return currentStatus;
  },
};
