import React, { useRef, useEffect, useState, Fragment } from 'react';
import { useField } from '@unform/core';
import { AiOutlineSearch, AiOutlineCloseCircle } from 'react-icons/ai';
import {
  StyledLabel,
  InputWrapper,
  InputField,
  ErrorWrapper,
} from 'views/ProfessionalStatus/styles/styles';
import FormLoading from 'views/ProfessionalStatus/utils/Loading';

const Input = ({
  label,
  name,
  placeholder,
  showSearchIcon = false,
  loadingCEP,
  onPaste,
  ...rest
}) => {
  const inputRef = useRef(null);
  const {
    fieldName,
    registerField,
    defaultValue,
    error,
    clearError,
  } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const hasError = !!error;

  return (
    <InputWrapper style={{ marginBottom: hasError ? '34px' : '20px' }}>
      <StyledLabel>{label}</StyledLabel>
      <div style={{ position: 'relative' }}>
        <InputField
          ref={inputRef}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onFocus={clearError}
          onPaste={onPaste}
          className={hasError ? 'error' : ''}
          {...rest}
        />
        {showSearchIcon &&
          (loadingCEP ? (
            <FormLoading
              background="#7b7b7b"
              position="absolute"
              justifyContent="flex-end"
              marginTop="-20px"
              marginLeft="-20px"
            />
          ) : (
            <AiOutlineSearch
              style={{
                position: 'absolute',
                size: 24,
                top: '50%',
                right: '10px',
                transform: 'translateY(-50%)',
              }}
              size={24}
            />
          ))}
      </div>
      <ErrorWrapper marginTop={'0px'}>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </InputWrapper>
  );
};

export default Input;
