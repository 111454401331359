import styled from 'styled-components';
//button settings
export const ButtonConfig = styled.button`
  border: none;
  color: #fff;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 5px;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 2px 2px 5px 0px #cecece;
`;
