import { useState, useEffect, useCallback } from 'react';
import {
  Filters,
  WhiteBox,
  Container,
  OrderFilter,
  StyledSelect,
  SearchFilter,
} from '../styled';
import GenericMessage from 'components/GenericMessage';
import { StudentCard } from '../../../components/StudentCard';
import { useHistoricMentorshipContext } from '../../../context';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';
import SkeletonCardMentorship from 'views/StaffPages/Mentorship/components/SkeletonCard';
import { MentorshipListContainer } from '../../../../NextMentorshipsTab/screens/Tabs/styled';
import { SeeMoreButton } from '../../../../MentorsTab/styled';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { SearchInputWithDebounce } from 'components/SearchInputWithDebounce';
import DetailsModal from 'views/StaffPages/Mentorship/components/DetailsModal';

export function CancelTab({
  updateMentorshipsCount,
  fetchMentorshipsCount,
  setMentorshipCounts,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrderOption, setSelectedOrderOption] = useState({
    value: '-date',
    label: 'Mais recentes para os mais antigos',
  });
  const {
    page,
    setPage,
    modalData,
    detailsModalOpen,
    openDetailsModal,
    closeDetailsModal,
    mentorships,
    setMentorships,
    loading,
    setLoading,
    hasNextPage,
    setHasNextPage,
    appliedFilters,
    setAppliedFilters,
    filterState,
    setFilterState,
  } = useHistoricMentorshipContext();

  async function fetchCanceledMentorships(
    reset = false,
    order = selectedOrderOption.value
  ) {
    setLoading(true);
    const offset = reset ? 0 : (page - 1) * 20;
    const queryString = queryParamsFromFilterObject(appliedFilters);
    let queryParams = `limit=20&offset=${offset}&ordering=${order}`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    const response = await mentorshipApi.getCanceledMentorshipsList({
      queryParams,
    });
    const updatedMentorships = mentorships && !reset ? [...mentorships] : [];
    response.results.forEach((mentorship) => {
      const mentorshipIndex = updatedMentorships.findIndex(
        (element) => element.id === mentorship.id
      );
      if (mentorshipIndex > -1) {
        updatedMentorships[mentorshipIndex] = mentorship;
      } else {
        updatedMentorships.push(mentorship);
      }
    });
    setMentorships(updatedMentorships);
    setHasNextPage(response.next !== null);
    updateMentorshipsCount('canceled', response.count);
    const countsResponse = await fetchMentorshipsCount();
    setMentorshipCounts({ ...countsResponse, canceled: response.count });
    setLoading(false);
  }

  useEffect(() => {
    fetchCanceledMentorships();
  }, []);

  const orderOptions = [
    { value: '-date', label: 'Mais recentes para os mais antigos' },
    { value: 'date', label: 'Mais antigos para os mais recentes' },
  ];

  const handleOrderChange = (selected) => {
    setSelectedOrderOption(selected);
    setPage(1);
    fetchCanceledMentorships(true, selected.value);
  };

  const studentName =
    mentorships && modalData?.id
      ? mentorships.find((m) => m.id === modalData.id)?.student
      : '';

  const handleNextPage = useCallback(async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const queryString = queryParamsFromFilterObject(appliedFilters);
    let queryParams = `limit=20&offset=${offset}&ordering=${selectedOrderOption.value}`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    const response = await mentorshipApi.getCanceledMentorshipsList({
      queryParams,
    });
    if (response) {
      setMentorships([...mentorships, ...response.results]);
      setHasNextPage(!!response.next);
    }
    setLoading(false);
  }, [mentorships, page, appliedFilters, selectedOrderOption]);

  const handleSearchNameChange = (event) => {
    const newName = event.target.value;
    setSearchTerm(newName);
    setPage(1);
    setAppliedFilters({ ...appliedFilters, name: newName });
    setFilterState({ ...filterState, name: newName });
  };

  const onSearchName = () => {
    fetchCanceledMentorships(true);
  };

  return (
    <Container>
      <Filters>
        <SearchFilter>
          <SearchInputWithDebounce
            placeholder={'Buscar'}
            setSearchTerm={(e) => handleSearchNameChange(e)}
            searchTerm={searchTerm}
            onSearch={onSearchName}
          />
        </SearchFilter>
        <OrderFilter>
          <StyledSelect
            value={selectedOrderOption}
            options={orderOptions}
            placeholder="Ordenar por"
            onChange={handleOrderChange}
            classNamePrefix="react-select"
          />
        </OrderFilter>
      </Filters>
      <WhiteBox>
        {mentorships?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhuma mentoria cancelada foi encontrada"
            subtitle={`No momento não existe mentorias disponíveis`}
          />
        ) : (
          <MentorshipListContainer>
            {mentorships?.map((item) => (
              <StudentCard
                key={item.id}
                item={item}
                detailsButtonText="Detalhes da mentoria"
                showDetailsButton={true}
                showCanceledInfo={true}
                showCareerGoals={true}
                buttonDetailsFunction={() => openDetailsModal(item)}
              />
            ))}
            {loading && (
              <SkeletonCardMentorship
                quantity={3}
                subTitleQuantity={4}
                cardHeight="372px"
                cardMargin="0"
              />
            )}
          </MentorshipListContainer>
        )}
        {hasNextPage && !loading && (
          <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
        )}
      </WhiteBox>
      <DetailsModal
        isOpen={detailsModalOpen}
        onClose={closeDetailsModal}
        item={modalData}
        studentName={studentName}
        title="Detalhes"
        showMentorName={true}
        showmodalityInfo={true}
        showReasonCancellation={true}
        showTypeLabel={true}
        showFlagStatus={true}
        showSimpleDateFormat={true}
        mentoringStatus="Cancelado"
        setPage={setPage}
        fetchMentorships={fetchCanceledMentorships}
      />
    </Container>
  );
}
