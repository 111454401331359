import React from 'react';

function LogoCompanyNetwork({ color = '#009291', width = 16, height = 17 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.37223 9.16667H5.37223V10.5H3.37223V9.16667ZM6.70557 10.5H8.70557V9.16667H6.70557V10.5ZM3.37223 13.1667H5.37223V11.8333H3.37223V13.1667ZM6.70557 13.1667H8.70557V11.8333H6.70557V13.1667ZM3.37223 5.16667H5.37223V3.83333H3.37223V5.16667ZM6.70557 5.16667H8.70557V3.83333H6.70557V5.16667ZM3.37223 7.83333H5.37223V6.5H3.37223V7.83333ZM6.70557 7.83333H8.70557V6.5H6.70557V7.83333ZM16.7056 5.83333V16.5H0.705566V2.5C0.705566 1.96957 0.91628 1.46086 1.29135 1.08579C1.66643 0.710714 2.17513 0.5 2.70557 0.5L9.37223 0.5C9.90267 0.5 10.4114 0.710714 10.7864 1.08579C11.1615 1.46086 11.3722 1.96957 11.3722 2.5V3.83333H14.7056C15.236 3.83333 15.7447 4.04405 16.1198 4.41912C16.4949 4.79419 16.7056 5.3029 16.7056 5.83333ZM10.0389 2.5C10.0389 2.32319 9.96866 2.15362 9.84364 2.0286C9.71861 1.90357 9.54904 1.83333 9.37223 1.83333H2.70557C2.52876 1.83333 2.35919 1.90357 2.23416 2.0286C2.10914 2.15362 2.0389 2.32319 2.0389 2.5V15.1667H10.0389V2.5ZM15.3722 5.83333C15.3722 5.65652 15.302 5.48695 15.177 5.36193C15.0519 5.2369 14.8824 5.16667 14.7056 5.16667H11.3722V15.1667H15.3722V5.83333ZM12.7056 10.5H14.0389V9.16667H12.7056V10.5ZM12.7056 13.1667H14.0389V11.8333H12.7056V13.1667ZM12.7056 7.83333H14.0389V6.5H12.7056V7.83333Z"
        fill={color}
      />
    </svg>
  );
}

export default LogoCompanyNetwork;
