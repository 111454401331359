import styled from 'styled-components';

export const HeaderInformation = styled.div`
  margin: 1rem 0 0 0;
  display: flex;
  gap: 3rem;

  > span {
    display: inline-block;
    color: #b8b8b8;
    font-weight: bold;
  }

  > .HeaderOne {
    flex: 10;
  }

  > .HeaderTwo {
    flex: 1;
    text-align: center;
  }

  > .HeaderThree {
    flex: 1;
  }
`;
