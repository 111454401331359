import axios from 'axios';
import URLS from 'constants/urls';
import cleanCookies from './authenticationCookies';

const logout = async () => {
  const response = await axios
    .post(
      `${URLS.API_URL}/api/internal/me/logout`,
      {},
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => error);

  cleanCookies();
  return response;
};

export { logout };
