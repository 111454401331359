import React, { useContext } from 'react';
import { shape, func, bool } from 'prop-types';

import { Formik, FastField } from 'formik';
import * as Yup from 'yup';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import { FormHelperText, List, ListItem, TextField } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ProfessionalExperienceFormStyles from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import BaseLayoutContext from 'contexts/base-layout';

const LiveSchema = Yup.object().shape({
  questionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
});

const LiveNineForm = (props) => {
  const { classes, answered, initialValues, applyLiveQuiz, metadata } = props;
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <GridContainer justify="center" className={classes.paddingReset}>
      <GridItem className={`${classes.paddingReset} ${classes.textAlgC}`}>
        <Formik
          enableReinitialize
          validationSchema={LiveSchema}
          validateOnChange={false}
          validade
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            await applyLiveQuiz(values);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <List className={classes.list}>
                {/* Question 1 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>1 -</strong> Se você quiser competir em uma
                    maratona, vai precisar se preparar para valer. Só assim terá
                    chances de alcançar seus objetivos. Ou seja, a preparação é
                    o caminho mais curto para se tornar um campeão.
                    <br />
                    <br />E pensando que na sua maratona de carreira seu
                    objetivo é conquistar o trabalho dos sonhos, é hora de
                    definir seu Plano de Preparação de Carreira. Para isso, a
                    primeira coisa a se fazer é definir quais são os tópicos de
                    estudo que você tem como interesses principais.
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionOne && touched.questionOne,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionOne"
                    variant="filled"
                    id="questionOne"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionOne && touched.questionOne}
                    value={values.questionOne}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionOne &&
                      `Sua resposta tem ${values.questionOne.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionOne && touched.questionOne ? (
                    <FormHelperText>{errors.questionOne}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 2 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>2 -</strong> O segundo passo é você criar o seu
                    plano de estudos: Qual horário vai conseguir se dedicar a
                    cada um dos seus temas de interesse principais e como você
                    vai se dedicar a eles (livros, vídeos na internet, avaliação
                    de projetos, etc.)?
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionTwo && touched.questionTwo,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionTwo"
                    variant="filled"
                    id="questionTwo"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionTwo && touched.questionTwo}
                    value={values.questionTwo}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionTwo &&
                      `Sua resposta tem ${values.questionTwo.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionTwo && touched.questionTwo ? (
                    <FormHelperText>{errors.questionTwo}</FormHelperText>
                  ) : null}
                </ListItem>
                {/* Question 3 */}
                <ListItem className={classNames([classes.professionListItem])}>
                  <p className={classes.textJustify}>
                    <strong>3 -</strong> Depois de definido seu plano de
                    preparação, é hora de pensar em como você vai medir seus
                    resultados. Para isso, estabeleça suas metas diárias de
                    estudo e algum tipo de evidência que possa te comprovar que
                    o que você aprendeu de novo já pode ser aplicado na sua vida
                    e carreira.
                  </p>
                  <FastField
                    className={classNames({
                      [classes.inputError]:
                        errors.questionThree && touched.questionThree,
                      [classes.inputField]: true,
                    })}
                    InputProps={{
                      className: [classes.input, classes.padding20],
                    }}
                    name="questionThree"
                    variant="filled"
                    id="questionThree"
                    placeholder="Resposta"
                    multiline
                    rowsMax="15"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.questionThree && touched.questionThree}
                    value={values.questionThree}
                    component={TextField}
                    disabled={
                      isSubmitting || answered || metadata.role === 'university'
                    }
                  />
                  <FormHelperText className={classes.helperText}>
                    {values.questionThree &&
                      `Sua resposta tem ${values.questionThree.length} caracteres.`}
                  </FormHelperText>
                  {errors.questionThree && touched.questionThree ? (
                    <FormHelperText>{errors.questionThree}</FormHelperText>
                  ) : null}
                </ListItem>
                {!answered && (
                  <ListItem
                    className={classNames([classes.professionListItem])}
                  >
                    <div className={classes.buttonContainer}>
                      <Button
                        disabled={
                          isSubmitting || Object.keys(values).length !== 3
                        }
                        type="submit"
                        style={{
                          width: '250px',
                          borderRadius: '5px',
                          backgroundColor: universityColor,
                        }}
                        round
                      >
                        Enviar respostas
                      </Button>
                    </div>
                  </ListItem>
                )}
              </List>
            </form>
          )}
        />
      </GridItem>
    </GridContainer>
  );
};

LiveNineForm.propTypes = {
  classes: shape({}).isRequired,
  answered: bool.isRequired,
  applyLiveQuiz: func.isRequired,
  initialValues: shape({}).isRequired,
  metadata: shape({}).isRequired,
};

export default withStyles(ProfessionalExperienceFormStyles)(LiveNineForm);
