import React, { useEffect, useRef, useState } from 'react';

import { Container, Box, Content } from './styled';
import StepIndicator from 'components/StepIndicator';
import StepOne from '../Forms/StepOne';
import StepTwo from '../Forms/StepTwo';
import StepThree from '../Forms/StepThree';
import StepFour from '../Forms/StepFour';
import { useRegisterModalContext } from '../../Contexts/RegisterModalContext';
import StepController from '../StepController';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import ModalInformation from 'components/informationModal';

export default function RegisterModal() {
  const {
    currentStep,
    modalOpen,
    setModalOpen,
    formRefs,
    setCurrentStep,
  } = useRegisterModalContext();

  const ref = useRef(null);

  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const steps = {
    1: <StepOne />,
    2: <StepTwo />,
    3: <StepThree />,
    4: <StepFour />,
  };

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [currentStep]);

  if (!modalOpen) {
    return null;
  }

  const modalConfig = {
    bubbleText: 'Atenção',
    ParagraphText:
      'Ao fechar o formulário de cadastro de eventos, você irá perder todas as informações já registradas.',
    TwoParagraphText: 'Tem certeza de que deseja seguir em frente?',
    buttonConfirmText: 'SIM, CONTINUAR',
    showButtonCancel: true,
    buttonCancelText: 'NÃO',
  };

  const handleClickStepChange = (value) => {
    if (value < currentStep) {
      return setCurrentStep(value);
    }
    formRefs[currentStep]?.current?.submitForm();
  };

  const handleConfirmClose = () => {
    setModalOpen(false);
    setModalInformationOpen(false);
  };

  return (
    <Container>
      <ModalInformation
        config={modalConfig}
        modalOpenState={modalInformationOpen}
        buttonConfirmFunction={handleConfirmClose}
        buttonCancelFunction={() => setModalInformationOpen(false)}
      />

      <Box>
        <Content>
          <div className="div-ref-scroll" ref={ref} />
          <CloseButton onClick={() => setModalInformationOpen(true)} />
          <StepIndicator
            count={4}
            currentStep={currentStep}
            onStepChange={handleClickStepChange}
          />
          {steps[currentStep]}
        </Content>
      </Box>
    </Container>
  );
}
