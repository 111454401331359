import styled from 'styled-components';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';
import Input from 'components/UnformFields/inputField';
import error from 'assets/Matriz/error.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WrapperLabel = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-end;
`;

export const WeeklySchedulesContainer = styled.div`
  display: flex;
  border-radius: 5px;
  padding: 1rem;
  flex-direction: column;
  border: 1px solid ${(props) => (props.error ? '#da0505' : '#c4c4c4')};
`;

export const ScheduleError = styled.div`
  font-size: 16px;
  margin: ${({ margin }) => (margin ? margin : 'auto 0 6px 0')};
  display: flex;
  gap: 12px;

  &:before {
    content: url(${error});
    width: 10px;
    height: 15px;
    margin: auto 0;
  }
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  margin-left: 0.5rem;

  > svg {
    border: 1px solid #fff;
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-top: ${(props) => props.marginTop || '0'};
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      border: 1px solid #009291;
    }
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 0.5rem;

  > label {
    display: inline-block;
    font-size: 16px;
    line-height: 25px;
    color: #606062;
    text-align: center;
    cursor: pointer;
    height: fit-content;
  }

  > input {
    height: 18px;
    width: 18px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;

    &:checked {
      border: none;
      background-color: #009291;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const Dash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5px;
  height: 2px;
  border-radius: 2px;
  background-color: #606062;
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const DeleteScheduleButton = styled.button`
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
  border: 1px solid #fff;
  background-color: #fff;
  cursor: pointer;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  margin-top: auto;

  > svg {
    fill: #606062;
    margin-top: 4px;
    transition: all 0.2s ease-in-out;

    > path {
      transition: all 0.2s ease-in-out;
      fill: #606062;
    }
  }

  &:hover {
    background-color: #ffeded;
    border: 1px solid #da0505;

    > svg {
      fill: #da0505;

      > path {
        fill: #da0505;
      }
    }
  }
`;

export const HourInput = styled(Input)`
  height: 40px;
  width: 80px;
  text-align: center;
  font-size: 16px;
  color: #606062;
  margin-top: -8px;
  transition: border 0.2s ease-in-out;

  &:focus {
    border: 2px solid #009291;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ccc;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ccc;
  }

  :disabled {
    background-color: #f5f5f5;
    color: #ccc;
  }
`;

export const HourInputWrapper = styled.div`
  display: flex;
  width: 80px;
`;

export const HoursContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 229px;
  gap: 0.5rem;
  margin: 0 !important;
`;

export const ScheduleFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;

export const SchedulesContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  min-height: 56px;
  align-items: center;
  margin-top: ${(props) => props.marginTop || '0'};
`;

export const WithoutScheduleMessage = styled.p`
  display: flex;
  font-size: 16px;
  color: #949494;
  align-items: flex-start;
  margin: 0;
`;

export const AddSpecificSchedulingButton = styled.button`
  margin-top: 1rem;
  border: none;
  background-color: transparent;
  color: #009291;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
`;

export const ScheduleListContainer = styled.div`
  display: flex;
  gap: 1rem;
`;
