export default function IntermediaryLanguageIcon({ color }) {
  return (
    <svg
      width="43"
      height="22"
      viewBox="0 0 43 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.9705 5.65107C40.0082 4.40012 39.4082 3.54218 38.2701 3.1033C37.1163 2.65504 35.9625 2.74678 34.943 3.52028C34.6491 3.73452 34.4112 4.01569 34.2493 4.3402C34.0874 4.66472 34.0062 5.02309 34.0126 5.38524C34.0042 8.9296 34.0042 12.4767 34.0126 16.0267C34.0126 17.0556 34.537 17.8197 35.4464 18.244C36.4954 18.7329 37.5883 18.7464 38.6247 18.1679C39.49 17.6852 39.9694 16.9471 39.9694 15.9328C39.9694 15.5325 39.9327 15.1291 40.025 14.7319C40.2715 13.6749 41.3278 13.2235 42.2718 13.7583C42.6547 13.9761 42.769 14.3264 42.8487 14.7111V16.7084C42.6389 17.3714 42.4942 18.0636 42.1145 18.662C40.7928 20.7292 38.8628 21.5611 36.4586 21.3953C33.9884 21.2233 32.028 19.7138 31.3535 17.5132C31.1888 16.9403 31.1113 16.3461 31.1238 15.7504C31.1238 12.4093 31.1868 9.06721 31.107 5.72821C31.0326 2.58416 33.6161 -0.189825 37.2957 0.0207515C39.8131 0.164611 41.6099 1.3478 42.5487 3.71314C42.6882 4.06445 42.7585 4.44286 42.8634 4.81085V6.55801C42.6777 7.32005 42.1365 7.77039 41.439 7.74433C40.7614 7.72035 40.1467 7.15429 40.0177 6.43604C39.9749 6.17668 39.9591 5.91364 39.9705 5.65107Z"
        fill={color}
      />
      <path
        d="M25.7634 9.56277C25.6239 9.46999 25.4844 9.41474 25.6585 9.1979C26.6162 8.0366 26.9235 6.68453 26.8658 5.20424C26.7609 2.54909 24.597 0.261931 21.9306 0.0565666C20.609 -0.0476795 19.2831 0.0263351 17.9583 0.014868C16.7227 0.00548587 16.2266 0.500655 16.2266 1.71512C16.2266 4.72297 16.2266 7.73012 16.2266 10.7366C16.2266 13.7722 16.2266 16.8075 16.2266 19.8425C16.2266 20.8849 16.7647 21.4249 17.8241 21.4291C19.2747 21.4291 20.7244 21.4468 22.175 21.4291C24.7396 21.3937 26.6697 20.2657 27.8518 17.9963C29.1525 15.4975 28.777 11.5622 25.7634 9.56277ZM18.981 5.61809C18.981 4.7737 18.9894 3.92931 18.981 3.08491C18.981 2.83055 19.0198 2.71797 19.3146 2.73361C20.1611 2.77843 21.0139 2.6721 21.8582 2.79615C22.4537 2.88714 23.0005 3.17601 23.4096 3.61563C23.8186 4.05525 24.0656 4.61965 24.1103 5.21675C24.2215 6.67619 23.7211 7.66757 22.6019 8.22946C22.1989 8.41961 21.7567 8.51316 21.3107 8.50258C20.6425 8.50258 19.9733 8.49112 19.3052 8.50884C19.0534 8.51614 18.9737 8.45359 18.9789 8.1961C18.9915 7.33398 18.981 6.47604 18.981 5.61809ZM24.404 17.9827C23.7652 18.4831 23.0257 18.6906 22.2285 18.6999C21.253 18.7124 20.2765 18.6937 19.301 18.7093C19.0471 18.7093 18.9705 18.652 18.9747 18.3914C18.9894 17.2447 18.981 16.0917 18.981 14.9419C18.981 13.8202 18.9873 12.6974 18.981 11.5758C18.981 11.3224 19.0177 11.2099 19.3135 11.2213C20.4548 11.2682 21.6002 11.1515 22.7383 11.2839C23.4849 11.3634 24.1836 11.6874 24.7246 12.2049C25.2656 12.7224 25.6181 13.4039 25.7267 14.1423C25.949 15.633 25.6784 16.9861 24.404 17.9827Z"
        fill={color}
      />
      <path
        d="M13.8476 19.5056C12.9036 16.47 12.013 13.4166 11.0994 10.3706C10.1858 7.32451 9.26903 4.27949 8.35437 1.23447C8.23689 0.842511 8.07221 0.479736 7.71033 0.249352C6.87119 -0.286471 5.90933 0.145107 5.58941 1.20529C4.36216 5.28338 3.13842 9.36147 1.91817 13.4396C1.29511 15.5182 0.659462 17.5948 0.0605263 19.6797C-0.00267739 19.8825 -0.0166279 20.0972 0.0198343 20.3063C0.0562965 20.5155 0.142132 20.713 0.270286 20.8828C0.398439 21.0526 0.56526 21.1898 0.757078 21.2831C0.948896 21.3764 1.16024 21.4232 1.37377 21.4196C1.98949 21.4196 2.48144 21.0485 2.68598 20.3948C3.02583 19.3128 3.35309 18.2272 3.66777 17.1382C3.737 16.8953 3.81777 16.7827 4.10517 16.7848C6.01562 16.7994 7.92641 16.7994 9.83755 16.7848C10.0882 16.7848 10.1973 16.8526 10.2676 17.0976C10.5697 18.1619 10.897 19.22 11.2116 20.2791C11.3721 20.815 11.6732 21.233 12.2469 21.3664C13.3724 21.6312 14.1885 20.5992 13.8476 19.5056ZM4.59608 14.0452C5.37962 11.4391 6.15687 8.84754 6.96559 6.1507C7.77432 8.84233 8.54947 11.4255 9.33616 14.0452H4.59608Z"
        fill={color}
      />
    </svg>
  );
}
