import {
  Container,
  FilterRow,
  MentorListContainer,
  MentorsGrid,
  Row,
  StepCircle,
  StepColumn,
  TitleCard,
  VerticalLine,
  WhiteCard,
  WrapperNoPermission,
} from './styled';
import { MentorsTabFilter } from './components/MentorsTabFilter';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { MentorCard } from '../../components/MentorCard';
import { ScheduleMentorshipModal } from './components/ScheduleMentorshipModal';
import { MentorModal } from '../../../../StaffPages/Mentorship/screens/MentorsTab/components/MentorModal';
import { FaCheck } from 'react-icons/fa';
import { IoEllipsisHorizontal } from 'react-icons/io5';
import SkeletonCardMentorship from 'views/StaffPages/Mentorship/components/SkeletonCard';
import GenericMessage from 'components/GenericMessage';
import { mentorAdapter } from 'views/StaffPages/Mentorship/screens/MentorsTab/adapters/MentorAdapter';
import { mentorshipApi } from '../../services/api';
import { adapterSpecificDates } from 'views/StaffPages/Mentorship/screens/MentorsTab/adapters/SpecificDatesAdapter';
import { getStudentGamification } from 'services/student/profile';
import { useMentorship } from '../../context/MentorshipContext';
import { CurriculumContext } from 'contexts/CurriculumContext';

export const MentorsTab = ({ goToScheduledTab }) => {
  const { universityColor } = useContext(BaseLayoutContext);
  const { mentorshipCounts, setMentorshipCounts } = useMentorship();
  const { profile } = useContext(CurriculumContext);
  const [mentors, setMentors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [showMentorModal, setShowMentorModal] = useState(false);
  const [
    scheduleMentorshipModalOpen,
    setScheduleMentorshipModalOpen,
  ] = useState(false);
  const [seeMentorsRequirements, setSeeMentorsRequirements] = useState(null);

  const fetchMentors = async (queryString = '', showLoading = true) => {
    showLoading && setLoading(true);
    const response = await mentorshipApi.getMentorsList(queryString);
    setMentors(response);
    setMentorshipCounts({
      ...mentorshipCounts,
      mentors: response.count,
    });
    showLoading && setLoading(false);
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      const response = await getStudentGamification(profile.profile_id);
      await fetchMentors('', false);
      setSeeMentorsRequirements({
        profileLevelGreaterThan1: response.profile_level > 1,
        hasLikedProfession: profile.has_liked_profession,
      });
      setLoading(false);
    };
    if (profile.profile_id) {
      loadData();
    }
  }, [profile]);

  const canSeeMentors = useMemo(() => {
    if (!seeMentorsRequirements) return false;
    const {
      profileLevelGreaterThan1,
      hasLikedProfession,
    } = seeMentorsRequirements;
    return profileLevelGreaterThan1 && hasLikedProfession;
  }, [seeMentorsRequirements]);

  const handleCloseScheduleMentorshipModal = () => {
    setSelectedMentor(null);
    setScheduleMentorshipModalOpen(false);
  };

  const fetchMentorDetails = async (mentor) => {
    const [
      mentorDetailsResponse,
      specificDatesResponse,
      absences,
      nextDays,
      scheduledDays,
    ] = await Promise.all([
      mentorshipApi.getMentorDetails(mentor.id),
      mentorshipApi.getMentorSpecificDates(mentor.id),
      mentorshipApi.getMentorAbsences(mentor.id),
      mentorshipApi.getMentorNextDays(mentor.id),
      mentorshipApi.getMentorMentorshipScheduled(mentor.id),
    ]);
    const newSelectedMentor = mentorAdapter(mentorDetailsResponse);
    newSelectedMentor.specific_dates = adapterSpecificDates(
      specificDatesResponse
    );
    newSelectedMentor.absences = absences;
    newSelectedMentor.next_days = nextDays;
    newSelectedMentor.scheduled_days = scheduledDays;
    setSelectedMentor(newSelectedMentor);
  };

  const handleOpenMentorModal = async (mentor) => {
    await fetchMentorDetails(mentor);
    setShowMentorModal(true);
  };

  const handleOpenScheduleMentorshipModal = async (mentor) => {
    await fetchMentorDetails(mentor);
    setScheduleMentorshipModalOpen(true);
  };
  return (
    <Container>
      <FilterRow>
        {canSeeMentors && <MentorsTabFilter fetchMentors={fetchMentors} />}
      </FilterRow>
      <WhiteCard>
        {canSeeMentors || loading ? (
          <>
            <TitleCard color={universityColor}>
              Mentores de carreira relacionados ao seu curso (
              {mentorshipCounts.mentors ?? 0})
            </TitleCard>
            <MentorListContainer>
              {loading ? (
                <SkeletonCardMentorship quantity={3} subTitleQuantity={4} />
              ) : mentors?.count === 0 ? (
                <GenericMessage
                  resetIndex={true}
                  title="Nenhum mentor encontrado"
                  subtitle="No momento não existe mentores disponíveis para o seu curso"
                />
              ) : (
                <MentorsGrid>
                  {mentors?.results?.map((mentor) => (
                    <MentorCard
                      key={mentor.id}
                      mentor={mentor}
                      onSchedule={() =>
                        handleOpenScheduleMentorshipModal(mentor)
                      }
                      openMentorModal={() => handleOpenMentorModal(mentor)}
                    />
                  ))}
                </MentorsGrid>
              )}
            </MentorListContainer>
          </>
        ) : (
          <WrapperNoPermission>
            <span>Estamos quase lá!</span>
            <p>
              Antes de visualizar os mentores e agendar sua mentoria, você deve
              concluir as etapas abaixo:
            </p>
            <Row>
              <VerticalLine />
              <StepCircle
                color={
                  seeMentorsRequirements?.profileLevelGreaterThan1
                    ? universityColor
                    : '#EFEEEC'
                }
              >
                {seeMentorsRequirements?.profileLevelGreaterThan1 ? (
                  <FaCheck color={'#FFF'} size={19} />
                ) : (
                  <IoEllipsisHorizontal color={'#606062'} size={19} />
                )}
              </StepCircle>
              <StepColumn>
                <span>Complete a Jornada do autoconhecimento</span>
                <p>
                  No menu ”Meu perfil", complete o nível 1 para poder gerar o
                  seu currículo
                </p>
              </StepColumn>
            </Row>
            <Row marginTop={'2rem'}>
              <StepCircle
                color={
                  seeMentorsRequirements?.hasLikedProfession
                    ? universityColor
                    : '#EFEEEC'
                }
              >
                {seeMentorsRequirements?.hasLikedProfession ? (
                  <FaCheck color={'#FFF'} size={19} />
                ) : (
                  <IoEllipsisHorizontal color={'#606062'} size={19} />
                )}
              </StepCircle>
              <StepColumn>
                <span>Favorite ao menos uma atuação</span>
                <p>No menu “Mapa de Carreiras”</p>
              </StepColumn>
            </Row>
          </WrapperNoPermission>
        )}
      </WhiteCard>
      <ScheduleMentorshipModal
        mentor={selectedMentor}
        setMentor={setSelectedMentor}
        isOpen={scheduleMentorshipModalOpen}
        onClose={handleCloseScheduleMentorshipModal}
        openMentorModal={handleOpenMentorModal}
        goToScheduledTab={goToScheduledTab}
      />
      <MentorModal
        mentor={selectedMentor}
        isOpen={showMentorModal}
        onClose={() => setShowMentorModal(false)}
      />
    </Container>
  );
};
