import styled from 'styled-components';
import X from 'assets/Oportutinites/X.svg';
import plus from 'assets/Oportutinites/plus.svg';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .create-opportunities-subtitle {
    font-size: 16px;
    text-align: center;
    max-width: 616px;
    color: #606062;
    margin-top: -16px;
    margin-bottom: 8px;
  }
`;

export const Wrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-top: 5em;
  align-items: center;
  justify-content: center;
`;

export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -5em;
`;
export const Cards = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${({ disabled }) => (disabled ? '#c4c4c4' : 'black')};
  border-radius: 8px;
  margin: 2em;
  width: 223px;
  height: 206px;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  > svg {
    margin-bottom: 1em;
    width: 35%;
    position: absolute;
    top: 2.3em;
  }

  > h4,
  p {
    color: ${({ disabled }) => (disabled ? '#c4c4c4' : 'black')};
  }
`;

export const CardImg = styled.img`
  margin-bottom: 1em;
  width: 35%;
  color: white;
  position: absolute;
  top: 2.3em;
`;

export const CardTitle = styled.h4`
  font-size: 1.5em;
  font-weight: bold;
  position: absolute;
  top: 5.3em;
`;

export const CardSubTitle = styled.p`
  max-width: 80%;
  word-break: break-word;
  text-align: center;
  margin-top: -1em;
  font-size: 12.5px;
  position: absolute;
  top: 13em;
  color: ${({ disabled }) => (disabled ? '#c4c4c4' : 'black')};
`;

export const PublishedOportunities = styled.span`
  cursor: pointer;
  margin: 1em 0;
  font-size: 1.5em;
  font-weight: 400;
  color: #0f7776;
`;

export const ModalOutside = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  padding-left: 260px;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.25);

  @media (max-width: 960px) {
    padding: 0;
  }
`;
export const ModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 4.5em;
  z-index: 99999999999999;
  justify-content: center;
  align-items: center;
`;

export const RModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100000000000000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(10px);
`;

export const ModalContainer = styled.div`
  width: 512px;
  min-height: 428px;
  height: auto;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  text-align: initial;
`;

export const RModalContainer = styled.div`
  width: 512px;
  max-height: 1020px;
  margin-bottom: 5em;
  overflow: scroll;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const InfoModalBackground = styled.div`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000000000;
  justify-content: center;
  align-items: center;
  height: 100%;
  backdrop-filter: blur(10px);

  .center-modal {
    padding-top: 65px;
  }
`;

export const InfoModalContainer = styled.div`
  width: 512px;
  min-height: 321px;
  margin-bottom: 5em;
  overflow: hidden;
  border-radius: 14px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  padding: 25px;
`;

export const ModalX = styled.span`
  background-color: #f0f0f0;
  width: 30px !important;
  height: 30px;
  background-image: url(${X});
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  cursor: pointer;
  border-radius: 50%;
  margin-bottom: 10px;
  z-index: 1;
`;

export const ButtonSave = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  color: #0f7776;
  font-size: 14px;
  margin-top: 5em;
  cursor: pointer;
`;
export const AppendButton = styled.button`
  border: none;
  background-color: #0f7776;
  border-radius: 10px;
  color: #ffff;
  padding: 5px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  &:before {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    content: '';
    background: url(${plus}) no-repeat 0 0;
    background-position: center;
    background-size: 15px;
    background-color: #fff;
    border-radius: 50%;
  }
`;

export const ButtonX = styled.button`
  background-color: transparent;
  border: none;
  width: 8px;
  height: 8px;
  background-image: url(${X});
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  margin: 0 0 0 5px;
  cursor: pointer;
`;

export const FormExtensionProjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -24px;
`;
