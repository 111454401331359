import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background-color: #fff;
  transition: width 2s linear 1s;
  width: fit-content;
  height: 30px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;

  > p {
    display: none;
  }

  &:hover {
    > p {
      display: block;
      padding: 0px 30px;
      margin: 0;
    }
  }
`;
