import React, { Fragment, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import {
  MultiStepContainer,
  WrapperContainer,
  WrapperTitle,
  SubTitle,
  Content,
  Title,
} from 'views/ProfessionalStatus/styles/styles';
import Wall from 'assets/JobOffer/IconPerson.svg';
import StepHeader from '../../../components/StepsHeader';
import { useFormContext } from 'views/ProfessionalStatus/context';
import FooterForm from '../../../components/StepsFooter/FooterForm';

export default function StepOne({
  isSurveysDefinedData,
  academicProfile,
  universityColor,
  prevsFormStep,
  nextFormStep,
}) {
  const { formData, setFormValues } = useFormContext();
  const formRef = useRef();
  const topDiv = useRef(null);

  const scrollToTopDiv = () => {
    if (topDiv) {
      topDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToTopDiv();
  }, []);

  const handleSubmit = async () => {
    setFormValues(formData);
    nextFormStep();
  };

  const HeaderControll = () => {
    if (isSurveysDefinedData) {
      return (
        <MultiStepContainer>
          <StepHeader
            Steps={['StepOne', 'StepTwo', 'StepThree', 'StepFour']}
            universityColor={universityColor}
          />
        </MultiStepContainer>
      );
    }

    return (
      <MultiStepContainer>
        <StepHeader
          Steps={['StepOne', 'StepTwo', 'StepThree']}
          universityColor={universityColor}
        />
      </MultiStepContainer>
    );
  };

  return (
    <Fragment>
      <div ref={topDiv}>
        <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
          <WrapperContainer>
            {HeaderControll()}
            <WrapperTitle universityColor={universityColor}>
              <div className="border-image">
                <img src={Wall} alt="Icone Wall" />
              </div>
              <Title universityColor={universityColor}>Parabéns!</Title>
              <SubTitle>
                {academicProfile.name}, aqui começa um novo capítulo da sua
                história.
              </SubTitle>
            </WrapperTitle>

            <Content>
              <p>
                Sou sua mentora de carreira digital e queremos te ajudar a{' '}
                <strong>explorar todo o seu potencial</strong> durante e após a
                sua jornada acadêmica até o dia que quiser se aposentar.
              </p>
              <p>
                Para isso, precisamos entender qual a sua situação profissional
                neste momento e suas expectativas a curto prazo. Suas respostas
                serão totalmente confidenciais e serão usadas para o seu{' '}
                <strong>
                  processo de autogestão e desenvolvimento de carreira
                </strong>
                .
              </p>
              <p>
                Reserve um tempinho para{' '}
                <strong>
                  fazer os questionários, completar seu perfil profissional
                </strong>
                , que será transformado no{' '}
                <strong>currículo do futuro (em formato de pdf).</strong> Você
                também receberá recomendações das trilhas de aprendizagem que
                irão acelerar sua entrada no mercado de trabalho, além de
                receber as{' '}
                <strong>
                  oportunidades de trabalho (estágios, emprego e trainee) mais
                  alinhadas ao seu perfil
                </strong>
                .
              </p>
            </Content>
            <FooterForm
              haveNext={true}
              haveBack={false}
              prevsState={prevsFormStep}
              universityColor={universityColor}
            />
          </WrapperContainer>
        </Form>
      </div>
    </Fragment>
  );
}
