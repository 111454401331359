import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.usingPreviewPdf ? '0' : '2000')};
  background-color: rgba(0, 0, 0, 0.25);
  overflow-y: auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-height: 768px) {
    align-items: flex-start;
  }
`;

export const Content = styled.div`
  position: relative;

  width: 100%;
  max-width: 700px;

  border-radius: 10px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 10px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #4b4b4d;
  font-weight: 500;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
`;

export const Title = styled.p`
  font-size: 20px;

  font-weight: 700;
  color: #606062;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;

  color: #606062;
`;
export const TextContent = styled.div``;

export const InfoList = styled.div`
  padding-left: 0;
  width: 100%;
`;

export const ContainerInfoItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: solid 0.5px #d0d0d0;
  transition: all 0.1s;
  border-radius: 0.2em;
  padding: 0.5em 0.8em 0.5em 0.5em;
  cursor: grab;
  justify-content: space-between;

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      padding-top: 31px;
      background: transparent;
      box-shadow: none;
      cursor: grabbing;
    `}
`;

export const IconItem = styled.div`
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  border: solid 0.5px
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  padding: 0.5em;
  margin-right: 0.5em;
  align-items: center;
  justify-content: center;
`;

export const RightIcon = styled.div`
  overflow: hidden;
  display: flex;
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  border-radius: 10px;
  padding: 0.5em;
  margin-right: 0.5em;
  align-items: center;
  justify-content: center;
`;

export const ItemName = styled.div`
  max-width: none;
  font-weight: 400;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin: 0;
`;

export const PrimaryButton = styled.button`
  width: 161px;
  height: 44px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  border-radius: 5px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  color: #ffffff;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
`;

export const CleanButton = styled.button`
  width: 161px;
  height: 44px;
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
`;

export const NoBorderButton = styled.button`
  width: 161px;
  height: 44px;
  background-color: #ffffff;
  border: none;
  outline: none;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
`;

export const FooterButtons = styled.div`
  width: 100%;
  min-height: 44px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  > div {
    display: flex;
    gap: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    > div {
      order: 1;
    }

    > ${NoBorderButton} {
      order: 2;
    }
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
