import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem 3rem 3rem;
`;

export const InAnalysesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > img {
    width: 300px;
    height: 300px;
  }

  > div {
    display: flex;
    flex-direction: column;
    max-width: 260px;

    h1 {
      color: #009291;
      font-size: 20px;
      font-weight: bold;
    }
    span {
      color: #4b4b4d;
      font-size: 16px;
      line-height: 1.5;
      text-align: justify;
    }
  }
`;

export const HeaderButtons = styled.div`
  width: 235px;
  color: ${(props) => (props.active ? '#009291' : '#949494')};
  font-weight: bold;
  font-size: 16px;
  height: 50px;
  background-color: ${(props) => (props.active ? '#FFF' : '#F6F6F6')};
  border: ${(props) => props.active && '1px solid #C4C4C4'};
  border-bottom: none;
  ${(props) => props.roundLeft && 'border-top-left-radius: 4px;'}
  ${(props) => props.roundRight && 'border-top-right-radius: 4px;'}
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ContainerHeaderButtons = styled.div`
  display: flex;
`;

export const PublishButton = styled.button`
  display: flex;
  width: 147px;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #009291;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin: 2rem 2rem 0 auto;

  &:hover {
    transform: scale(1.1);
    filter: brightness(0.9);
  }
`;

export const GenerateReportButton = styled.button`
  background-color: #ffffff;
  height: 40px;
  width: 155px;
  color: #009291;
  border: 1px solid #009291;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const ContainerInsideTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c4c4c4;
  padding: 0;
  position: relative;
`;

export const ButtonInsideTabs = styled.button`
  background-color: transparent;
  border: none;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: #606062;
  font-size: 14px;
  padding: 2rem 2rem 1rem 2rem;
  cursor: pointer;
  margin: 0;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;

  &:hover {
    background-color: #f0f0f0;
  }

  &:after {
    content: '';
    display: flex;
    width: 120%;
    height: 5px;
    background-color: ${(props) => (props.active ? '#009291' : 'transparent')};
    bottom: calc(-1rem - 1px);
    left: -10%;
    position: relative;
    border-radius: 2px 2px 0 0;
  }
`;

export const InvisibleLine = styled.div`
  border: 1px solid #fff;
  position: absolute;
  width: 233px;
  top: -2px;
  left: ${(props) => (props.isTendencies ? '235px' : '0')};
`;

export const HeaderInformation = styled.div`
  margin: 2rem 0 0 0;
  display: flex;
  gap: 3rem;

  > span {
    display: inline-block;
    color: #b8b8b8;
    font-weight: bold;
  }

  > .HeaderOne {
    flex: 9;
  }

  > .HeaderTwo,
  .HeaderThree,
  .HeaderFour {
    flex: 1;
  }
`;

export const ChildrenContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: 1px solid #c4c4c4;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  > header {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;

      > span {
        font-weight: bold;
        font-size: 19px;
        color: #009291;
      }

      > p {
        font-size: 16px;
        font-weight: bold;
        color: #606062;
        margin-top: 1rem;
        max-width: 80%;
      }
    }
  }
`;

export const CircularProgressWrapper = styled.div`
  display: flex;
`;
