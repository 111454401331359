import { GraphicContainer } from '../../Styles/StyleGraphics';
import { IoCloseOutline } from 'react-icons/io5';
import {
  Description,
  FooterMessage,
  SubTitle,
} from '../../Styles/StyleSections';
import { SectionSkeleton } from '../Skeletons/SectionSkeleton';
import { SkeletonLegend } from '../../Styles/StyleSkeleton';
import {
  returnAdapter,
  verifyValuesChartAreZero,
} from '../../Utils/formatDataGraphics';

import Tooltip from 'components/NewTooltip';
import { RiInformationLine } from 'react-icons/ri';

export const GraphicSection = ({
  title,
  subtitle,
  children,
  separateTitles,
  noStyle = true,
  messageFooter = '',
  data,
  tooltipMessage,
}) => {
  const dataAdapter = returnAdapter(data);

  return (
    <GraphicContainer loading={dataAdapter.result === null}>
      {dataAdapter.result === null ? (
        <>
          <SectionSkeleton
            title={title || separateTitles}
            subtitle={subtitle}
          />
          {noStyle ? children : <div className="content">{children}</div>}
          {messageFooter && (
            <SkeletonLegend mgLeft="31px" widthLegend={450} heightLegend={15} />
          )}
        </>
      ) : (
        <>
          {separateTitles &&
            separateTitles?.map((title, index) => (
              <>
                <SubTitle pdLeft="31px">{title}</SubTitle>{' '}
                {index !== separateTitles.length - 1 && (
                  <IoCloseOutline size={18} />
                )}
              </>
            ))}

          <div className="box-title">
            {title && <SubTitle pdLeft="31px"> {title} </SubTitle>}
            {tooltipMessage && (
              <Tooltip
                text={tooltipMessage}
                colorSchema="black"
                direction="top"
                minWidth="211px"
                isMultiLine
              >
                <RiInformationLine size={16} />
              </Tooltip>
            )}
          </div>
          {subtitle && <Description pdLeft="31px">{subtitle}</Description>}
          {noStyle ? children : <div className="content">{children}</div>}

          {messageFooter && verifyValuesChartAreZero(dataAdapter) === false && (
            <FooterMessage pdLeft="31px"> {messageFooter} </FooterMessage>
          )}
        </>
      )}
    </GraphicContainer>
  );
};
