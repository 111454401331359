import React, { useEffect, useState } from 'react';
import { Form } from '@unform/web';
import moment from 'moment';
import {
  Container,
  TimeElapsedInfo,
  WrapperForm,
  WrapperFormContainer,
  WrapperInformations,
} from '../styled';
import DatePickerCustom from 'views/StaffPages/Opportunities/Components/Form/datePicker';
import { SelectField } from 'views/StaffPages/Mentorship/components/SelectField';
import { useNextMentorshipContext } from '../../../context';
import StepController from '../../StepController';
import { mentorshipApi } from '../../../../../services/api';

const StepOne = (props) => {
  const {
    item,
    studentName,
    openCancelModal,
    options,
    setHours,
    handleSubmit,
    countDays,
  } = props;
  const { formRefStepOne, rescheduleFormData } = useNextMentorshipContext();
  const [hoursOptions, setHoursOptions] = useState(null);

  useEffect(() => {
    const loadInitialValues = async () => {
      if (formRefStepOne?.current) {
        let newData = {
          mentor: options?.mentors?.find(
            (mentor) => mentor.value === rescheduleFormData?.mentor
          ),
          mentorship_modality: options?.mentorship_modality?.find(
            (modality) =>
              modality.value === rescheduleFormData?.mentorship_modality
          ),
        };
        let selectedHour;
        const newDate = rescheduleFormData?.date;
        if (newDate) {
          const newHoursOptions = await fetchHoursOptions(newDate);
          selectedHour = newHoursOptions?.find((option) => {
            if (
              !rescheduleFormData?.start_hour ||
              !rescheduleFormData?.end_hour
            )
              return;
            const splitOption = option.label.split(' - ');
            return (
              rescheduleFormData?.start_hour === splitOption[0] &&
              rescheduleFormData?.end_hour === splitOption[1]
            );
          });
          newData = {
            ...newData,
            date: newDate,
            hours: selectedHour,
          };
        }
        formRefStepOne.current?.setData(newData);
      }
    };
    loadInitialValues();
  }, [options, rescheduleFormData, formRefStepOne]);

  const isAvailableDate = (date) => {
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    const currentDateFormatted = moment(new Date()).format('YYYY-MM-DD');
    if (
      !item?.available_days ||
      !date ||
      dateFormatted === currentDateFormatted
    )
      return false;
    const weekdaysNumber = {
      sunday: 0,
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
    };

    function getWeekdayKey(number) {
      return Object.entries(weekdaysNumber).find(
        ([_, value]) => value === number
      )?.[0];
    }

    const availableWeekDays = Object.keys(item.available_days)?.map(
      (availableDay) => weekdaysNumber[availableDay]
    );
    const isWeekdayAvailable = availableWeekDays.includes(date.getDay());

    const specificDates = item.specific_dates.map((specificDate) =>
      moment(specificDate.date).format('YYYY-MM-DD')
    );
    const isASpecificDate = specificDates.includes(dateFormatted);

    const absencesPeriods = item.absences.map((absence) => {
      return {
        start_date: new Date(absence.start_date),
        end_date: new Date(absence.end_date),
      };
    });

    const isDateInAbsencePeriod = absencesPeriods.some((absencesPeriod) => {
      return (
        date >= absencesPeriod.start_date && date <= absencesPeriod.end_date
      );
    });

    const dateWeekDay = getWeekdayKey(date.getDay());

    let allTimes = item.available_days[dateWeekDay] || [];

    if (isASpecificDate) {
      const specificDate = item.specific_dates.find(
        (item) =>
          moment(new Date(item.date)).format('YYYY-MM-DD') === dateFormatted
      );
      allTimes = [...allTimes, ...specificDate.schedules];
    }
    const scheduledMentorshipOnDay = item.scheduled_days.filter(
      (item) =>
        moment(new Date(item.date)).format('YYYY-MM-DD') === dateFormatted
    );

    const hasAvailableTimesOnDay =
      allTimes.length > scheduledMentorshipOnDay.length;

    return (
      (isWeekdayAvailable || isASpecificDate) &&
      hasAvailableTimesOnDay &&
      !isDateInAbsencePeriod
    );
  };

  const fetchHoursOptions = async (date) => {
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    const nextDays = await mentorshipApi.getMentorNextDays(
      item.mentor_id,
      dateFormatted
    );
    if (nextDays) {
      const newHours = nextDays[0]?.times;
      setHours(newHours);
      if (!newHours) return [];
      const newHourOptions = newHours?.map((hour, index) => {
        return {
          label: `${hour.start_hour} - ${hour.end_hour}`,
          value: index,
        };
      });
      setHoursOptions(newHourOptions);
      return newHourOptions;
    }
  };

  return (
    <Container>
      <Form ref={formRefStepOne} onSubmit={handleSubmit}>
        <WrapperInformations>
          <TimeElapsedInfo>
            Solicitada{' '}
            {countDays === 0
              ? 'hoje'
              : countDays === 1
              ? 'há 1 dia'
              : `há ${countDays} dias`}
          </TimeElapsedInfo>
          Sessão de mentoria com <span>{studentName}</span>
        </WrapperInformations>

        <WrapperForm>
          <p>Mentor</p>
          <SelectField
            name="mentor"
            placeholder="Selecione"
            options={options?.mentors}
          />
        </WrapperForm>

        <WrapperForm>
          <p>Modalidade</p>
          <SelectField
            name="mentorship_modality"
            placeholder="Selecione"
            options={options?.mentorship_modality}
          />
        </WrapperForm>

        <WrapperFormContainer>
          <WrapperForm>
            <p>Nova data</p>
            <DatePickerCustom
              minDate={new Date()}
              name="date"
              placeholder="Selecione"
              filterDate={isAvailableDate}
              handleUpdate={async (date) => {
                if (formRefStepOne?.current) {
                  formRefStepOne.current.setFieldValue('hours', '');
                }
                if (!date) {
                  setHoursOptions([]);
                  return;
                }
                fetchHoursOptions(date);
              }}
            />
          </WrapperForm>
          <WrapperForm>
            <p>Horário</p>
            <SelectField
              name="hours"
              placeholder="Selecione"
              options={hoursOptions || []}
            />
          </WrapperForm>
        </WrapperFormContainer>

        <StepController item={item} openCancelModal={openCancelModal} />
      </Form>
    </Container>
  );
};

export default StepOne;
