import URLS from 'constants/urls';

export function getPDFname(pdfURL) {
  const regExp = new RegExp(URLS.API_URL, 'g');
  const pdfSplitedLength = pdfURL.split('/');
  let pdfName;
  if (regExp.test(pdfURL)) {
    pdfName = decodeURI(pdfURL.split('/')[pdfSplitedLength.length - 1]);
  } else {
    pdfName = decodeURI(pdfURL.split('/')[pdfSplitedLength.length - 1]);
  }
  return pdfName;
}
