import { useState, useEffect, useContext } from 'react';
import {
  BoxContent,
  BoxMain,
  DataArea,
  DataContent,
  GridAreaJobs,
  GridArea,
  Title,
} from 'views/StaffPages/Dashboard/Styles/BaseComponent';
import { GraphicSection } from 'views/StaffPages/Dashboard/UiComponents/Sections/GraphicSection';
import { CustomHorizontalBarChart } from 'views/StaffPages/Dashboard/UiComponents/Graphics/HorizontalBarChart';
import { DashboardContext } from 'views/StaffPages/Dashboard/Context';
import { BigStatWidget } from 'views/StaffPages/Dashboard/UiComponents/Sections/BigStatWidget';
import { StatWidget } from 'views/StaffPages/Dashboard/UiComponents/Sections/StatWidget';
import { formatNumber } from 'utils/formatNumber';

import {
  getJobsWidget,
  getJobsArea,
} from 'views/StaffPages/Dashboard/Services';

import {
  returnCircularProgressItens,
  returnGraphic,
} from 'views/StaffPages/Dashboard/Utils/componentRendering';
import { HorizontalGraphSkeleton } from 'views/StaffPages/Dashboard/UiComponents/Skeletons/HorizontalGraphSkeleton';
import { LegendFinishedJobs } from '../../UiComponents/Legends/LegendFinishedJobs';

export function JobsTab(params) {
  const [jobWidget, setjobWidget] = useState(null);

  const [jobsBarWidget, setJobsBarWidget] = useState(null);
  const [finishedJobs, setFinishedJobs] = useState(null);

  const [jobArea, setJobArea] = useState(null);
  const [graphicSize, setgraphicSize] = useState(850);
  const [jobgraphicSize, setJobgraphicSize] = useState(850);

  const { fetchMe, queryFilter } = useContext(DashboardContext);

  const fatchJobsWidget = async (filter) => {
    const response = await getJobsWidget(filter);
    if (response.code === 200) {
      setFinishedJobs(response?.result.charts.finished_jobs_chart);
    }
  };

  const fatchJobsArea = async (filter) => {
    const response = await getJobsArea(filter);
    setJobsBarWidget(response?.result?.jobs_bar_chart);
    setJobArea(response);
  };

  useEffect(() => {
    const chartLength =
      jobArea?.result?.application_by_area_charts.chart.length || 0;
    const newSize = chartLength > 0 ? 100 + (chartLength - 1) * 80 : 850;
    setgraphicSize(newSize);
    const jobChartLength =
      jobArea?.result?.active_job_offers.chart.length || 0;
    const newJobSize = jobChartLength > 0 ? 100 + (jobChartLength - 1) * 80 : 850;
    setJobgraphicSize(newJobSize);
  }, [jobArea]);

  useEffect(() => {
    if (fetchMe) {
      fatchJobsWidget(queryFilter);
      fatchJobsArea(queryFilter);
    }
  }, [fetchMe, queryFilter]);

  return (
    <BoxMain>
      <BoxContent>
        <Title>Vagas</Title>
        <DataContent>
          <GridAreaJobs>
            <StatWidget
              padding={'14px'}
              data={jobsBarWidget || null}
              countSkeleton={[1, 2, 3]}
            />
          </GridAreaJobs>
        </DataContent>
        <GraphicSection
          data={jobArea}
          title="Vagas ativas por área de atuação"
          tooltipMessage={
            'Aqui estão todas as vagas ativas e suas áreas de atuação'
          }
        >
          {returnGraphic(
            jobArea?.result?.active_job_offers?.chart,
            CustomHorizontalBarChart,
            HorizontalGraphSkeleton,
            'value',
            jobgraphicSize
          )}
        </GraphicSection>
        <GridArea>
          <BigStatWidget
            padding={'20px'}
            data={jobArea?.result?.application_charts?.chart}
            countSkeleton={[1, 2]}
          />
        </GridArea>
        <GraphicSection
          data={jobArea}
          title="Candidaturas por área de atuação"
          tooltipMessage={
            'Aqui está a quantidade de candidaturas por área de atuação'
          }
        >
          {returnGraphic(
            jobArea?.result?.application_by_area_charts?.chart,
            CustomHorizontalBarChart,
            HorizontalGraphSkeleton,
            'value',
            graphicSize
          )}
        </GraphicSection>
        <GraphicSection data={finishedJobs?.charts} title={'Vagas'}>
          {returnGraphic(
            finishedJobs?.charts,
            CustomHorizontalBarChart,
            HorizontalGraphSkeleton,
            'value',
            190,
            LegendFinishedJobs
          )}
        </GraphicSection>
      </BoxContent>
    </BoxMain>
  );
}
