import { Container } from './styles';
import underConstructionImage from 'assets/indicators/under-construction-icon.png';

/**
 * Use this component to display the future construction message, pass the
 * desired text as a parameter only with bold markings
 *
 * @param {JSX.Element} style - Children
 * @returns {JSX.Element} Returns a document viewer component.
 */
export function FutureConstruction({ children }) {
  return (
    <Container>
      <img src={underConstructionImage} alt="Em construção" />
      {children}
    </Container>
  );
}
