import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import OportunidadesGroup from '../../../../../../assets/img/OportunidadesGroup.svg';
import Group4 from '../../../../../../assets/Oportutinites/Group4.svg';
import Group3 from '../../../../../../assets/Oportutinites/Group3.svg';
import Group6 from '../../../../../../assets/Oportutinites/Group6.svg';
import pp from '../../../../../../assets/Oportutinites/pp.svg';
import Group5 from '../../../../../../assets/Oportutinites/Group5.svg';
import Group2 from '../../../../../../assets/Oportutinites/Group2.svg';
import {
  selectOptionsCourses,
  selectOptionsProgram,
} from '../../../Resources/SelectedOptions';

import { Form } from '@unform/web';
import Radio from '../../Form/inputRadio';
import { OportunitiesContext } from '../../../Context/OportunitiesContext';
import {
  Wrapper,
  DescImg,
  MultiStepContainer,
  Img,
  Title,
  SubTitle,
  Choices,
  ChoicesTitle,
  SelectOptions,
  Text,
} from '../../../Styles/formStyled';
import FooterForm from '../../FooterForm';
import StepIndicator from '../../StepIndicator';
import SearchableSelectUn from '../../Form/searchableSelect';

export default function StepOne({ nextFormStep, prevsFormStep }) {
  const {
    formData,
    setFormValues,
    selectOption,
    setSelectOption,
    stepOneRef,
  } = useContext(OportunitiesContext);

  const [courseCategory, setCourseCategory] = useState('');
  const [noSelect, setNoSelect] = useState(false);
  const [errorCategory, setErrorCategory] = useState(false);
  const [programCategory, setProgramCategory] = useState('');

  const formRef = useRef();

  const radioOptions = [
    {
      id: 'event',
      value: 'event',
      label: 'Eventos',
      imageOne: Group5,
      imageTwo: Group6,
    },
    {
      id: 'program',
      value: 'program',
      label: 'Programas',
      imageOne: Group2,
      imageTwo: Group3,
    },
    {
      id: 'course',
      value: 'course',
      label: 'Cursos',
      imageOne: Group4,
      imageTwo: pp,
    },
  ];

  const handleSubmit = async (data) => {
    if (selectOption === '') {
      setNoSelect(true);
      return;
    }

    if (selectOption === 'course') {
      if (courseCategory === '') {
        setErrorCategory(true);
        return;
      }
    }

    if (selectOption === 'program') {
      if (programCategory === '') {
        setErrorCategory(true);
        return;
      }
    }

    if (data) {
      if (data.type !== '') {
        setFormValues(data);
        nextFormStep();
      }
    }
  };

  useEffect(() => {
    if (selectOption !== '') {
      setNoSelect(false);
    }
  }, [selectOption]);

  useEffect(() => {
    if (courseCategory !== '') {
      setErrorCategory(false);
    }
  }, [courseCategory]);

  const handleFormSelect = (data) => {
    setSelectOption(data.target.value);
  };

  const handleSelectType = (data) => {
    setCourseCategory(data.value);
  };

  const handleSelectTypeProgram = (data) => {
    setProgramCategory(data.value);
  };

  const mapSelectOptions = (list) => {
    return list.map((item) => (
      <option key={item.id} value={item.value}>
        {item.label}
      </option>
    ));
  };

  useEffect(() => {
    if (formData.course_category) {
      setCourseCategory(formData.course_category);
    }
    if (formData.category) {
      setProgramCategory(formData.category);
    }
    if (formData.type) {
      setSelectOption(formData.type);
    }
  }, [formData]);

  return (
    <Form ref={stepOneRef} initialData={formData} onSubmit={handleSubmit}>
      <MultiStepContainer>
        <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
      </MultiStepContainer>

      <Wrapper>
        <DescImg>
          <Img src={OportunidadesGroup} />
          <Title>
            Eventos e cursos <br />{' '}
            <SubTitle>Eventos, Cursos e Programas</SubTitle>
          </Title>
        </DescImg>

        <Choices>
          <ChoicesTitle>
            Selecione a <strong>oportunidade de aprendizagem</strong> que deseja
            cadastrar:
          </ChoicesTitle>
        </Choices>

        <Radio
          name="type"
          options={radioOptions}
          onChange={handleFormSelect}
          defaultValueBeta={selectOption}
        />
        {noSelect && selectOption === '' && (
          <span style={{ color: '#f00', marginTop: '10px' }}>
            Para seguir em frente selecione uma oportunidade de aprendizagem
          </span>
        )}

        {selectOption === 'course' && (
          <Fragment>
            <Text>
              Agora selecione o <strong>tipo de curso</strong> *
            </Text>
            <SelectOptions>
              <SearchableSelectUn
                options={selectOptionsCourses}
                name="course_category"
                onChange={handleSelectType}
              />

              {errorCategory && courseCategory === '' && (
                <span style={{ color: '#f00', marginTop: '10px' }}>
                  Responda para seguir em frente
                </span>
              )}
            </SelectOptions>
          </Fragment>
        )}

        {selectOption === 'program' && (
          <Fragment>
            <Text>
              Agora selecione o <strong>tipo de programa </strong> *
            </Text>
            <SelectOptions>
              <SearchableSelectUn
                options={selectOptionsProgram}
                name="category"
                onChange={handleSelectTypeProgram}
              />

              {errorCategory && programCategory === '' && (
                <span style={{ color: '#f00', marginTop: '10px' }}>
                  Responda para seguir em frente
                </span>
              )}
            </SelectOptions>
          </Fragment>
        )}
        <FooterForm
          haveNext={true}
          haveBack={false}
          prevsState={prevsFormStep}
        />
      </Wrapper>
    </Form>
  );
}
