export function NoResumeIcon({ color = '#009291', size = 21 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83333 19.6667C8.83333 20.1275 8.46083 20.5 8 20.5H4.66667C2.36917 20.5 0.5 18.6308 0.5 16.3333V4.66667C0.5 2.36917 2.36917 0.5 4.66667 0.5H11.3333C13.6308 0.5 15.5 2.36917 15.5 4.66667V6.33333C15.5 6.79417 15.1275 7.16667 14.6667 7.16667C14.2058 7.16667 13.8333 6.79417 13.8333 6.33333V4.66667C13.8333 3.28833 12.7117 2.16667 11.3333 2.16667H4.66667C3.28833 2.16667 2.16667 3.28833 2.16667 4.66667V16.3333C2.16667 17.7117 3.28833 18.8333 4.66667 18.8333H8C8.46083 18.8333 8.83333 19.2058 8.83333 19.6667ZM12.1667 5.5C12.1667 5.03917 11.7942 4.66667 11.3333 4.66667H4.66667C4.20583 4.66667 3.83333 5.03917 3.83333 5.5C3.83333 5.96083 4.20583 6.33333 4.66667 6.33333H11.3333C11.7942 6.33333 12.1667 5.96083 12.1667 5.5ZM8.83333 9.66667C8.83333 9.20583 8.46083 8.83333 8 8.83333H4.66667C4.20583 8.83333 3.83333 9.20583 3.83333 9.66667C3.83333 10.1275 4.20583 10.5 4.66667 10.5H8C8.46083 10.5 8.83333 10.1275 8.83333 9.66667ZM4.66667 13C4.20583 13 3.83333 13.3725 3.83333 13.8333C3.83333 14.2942 4.20583 14.6667 4.66667 14.6667H6.33333C6.79417 14.6667 7.16667 14.2942 7.16667 13.8333C7.16667 13.3725 6.79417 13 6.33333 13H4.66667ZM20.5 14.6667C20.5 17.8825 17.8825 20.5 14.6667 20.5C11.4508 20.5 8.83333 17.8825 8.83333 14.6667C8.83333 11.4508 11.4508 8.83333 14.6667 8.83333C17.8825 8.83333 20.5 11.4508 20.5 14.6667ZM18.8333 14.6667C18.8333 12.3692 16.9642 10.5 14.6667 10.5C12.3692 10.5 10.5 12.3692 10.5 14.6667C10.5 16.9642 12.3692 18.8333 14.6667 18.8333C16.9642 18.8333 18.8333 16.9642 18.8333 14.6667Z"
        fill={color}
      />
      <g clip-path="url(#clip0_34256_5055)">
        <path
          d="M16.7387 13.439L15.4777 14.7001L16.7387 15.9612C16.9538 16.1762 16.9538 16.5238 16.7387 16.7389C16.6315 16.8461 16.4907 16.9 16.3499 16.9C16.2091 16.9 16.0683 16.8461 15.9611 16.7389L14.7 15.4777L13.4389 16.7389C13.3317 16.8461 13.1909 16.9 13.0501 16.9C12.9093 16.9 12.7685 16.8461 12.6613 16.7389C12.4462 16.5238 12.4462 16.1762 12.6613 15.9612L13.9223 14.7001L12.6613 13.439C12.4462 13.2239 12.4462 12.8763 12.6613 12.6613C12.8763 12.4462 13.2239 12.4462 13.4389 12.6613L14.7 13.9224L15.9611 12.6613C16.1761 12.4462 16.5237 12.4462 16.7387 12.6613C16.9538 12.8763 16.9538 13.2239 16.7387 13.439Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_34256_5055">
          <rect
            width="4.4"
            height="4.4"
            fill="white"
            transform="translate(12.5 12.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
