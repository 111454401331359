import styled from 'styled-components';

export const Container = styled.div`
  ${(props) => (props.openModal ? `display: flex;` : `display: none;`)}
  width: calc(100% - 260px);
  min-height: 100%;
  height: auto;
  background-color: #eeeeee;
  z-index: ${(props) => props.zIndex};
  align-items: center;
  top: 0;
  left: 260px;
  justify-content: center;
  transition: all 1s ease-in-out;
  position: fixed;
  overflow-y: auto;

  @media (max-width: 960px) {
    width: 100%;
    left: 0px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;

  display: flex;

  justify-content: center;
  position: absolute;
  top: 10em;
`;

export const CloseButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  top: 40px;
  background-color: #e5e5e5;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
