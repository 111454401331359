import styled from 'styled-components';

export const ContainerTitle = styled.div`
  margin-top: 3.8em;

  > h3 {
    font-size: 19px;
    font-weight: bold;
    color: #009291;
    margin: 20px 0 0px;
  }
`;
