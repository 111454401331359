import React, { Fragment, useContext } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { array, func, number, object, string } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import JobHeaderTab, {
  Container,
  FilterContainer,
  ButtonCreate,
} from './style';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import EngineStepsCampaign from '../StepsForm/Campaign/EngineCampaign';
import EngineSteps from '../StepsForm/List/EngineSteps';

const InsideTabs = (props) => {
  const { classes, activeTab, handleTabChange, tabs, openSnackbar } = props;
  const {
    setFullModalActive,
    setFullScreenModalChildren,
    setConfigFullScreenModal,
    activeEmailMass,
    setFormMode,
    listageGeneratedList,
    listageCampaignList,
  } = useSendEmail();

  const handleClickCreateCampaign = () => {
    setFullModalActive(true);
    setFullScreenModalChildren(<EngineStepsCampaign />);
    setConfigFullScreenModal({});
  };

  const handleClickCreateList = () => {
    setFullModalActive(true);
    setFullScreenModalChildren(<EngineSteps />);
    setConfigFullScreenModal({});
    setFormMode('create');
  };

  const currentButton = () => {
    if (activeTab === 0) {
      return (
        <ButtonCreate onClick={handleClickCreateList}>
          Gerar nova lista
        </ButtonCreate>
      );
    }

    if (listageGeneratedList.length > 0) {
      return (
        <ButtonCreate
          onClick={handleClickCreateCampaign}
          disabled={!activeEmailMass}
        >
          Criar campanha
        </ButtonCreate>
      );
    }
  };

  return (
    <Fragment>
      <Container>
        <AppBar position="static" className={classes.appBarHolder}>
          {tabs && (
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              scrollButtons="on"
              scrollable
              classes={{
                indicator: classes.tabIndicator,
                scrollButtons: classes.scrollButtons,
                flexContainer: classes.borderContainer,
              }}
            >
              {tabs.map((tabName) => (
                <Tab
                  style={{ zIndex: 2 }}
                  key={tabName}
                  label={tabName}
                  className={classes.tabButton}
                />
              ))}
            </Tabs>
          )}

          <FilterContainer>{currentButton()}</FilterContainer>
        </AppBar>
      </Container>
    </Fragment>
  );
};

InsideTabs.propTypes = {
  classes: object.isRequired,
  title: string,
  activeTab: number,
  tabs: array,
  backgroundImage: string,
  handleTabChange: func,
  headerElements: func,
};

export default withStyles(JobHeaderTab)(InsideTabs);
