import React, { Fragment, useMemo } from 'react';
import ReactHtmlParser from 'react-html-parser';
import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonCancel,
  WrapperButtons,
  ButtonX,
  ParagraphStyled,
} from './style';
import IconPerson from 'assets/JobOffer/IconPerson.svg';
import FilterLoading from 'components/FilterModal/components/FilterLoading';
import { IoClose } from 'react-icons/io5';

export default function BlankModalInformation({
  children,
  config,
  closeModalFunction,
  modalOpenState,
  buttonConfirmFunction,
  buttonCancelFunction,
  universityColor,
}) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalOpenState}>
        <Box>
          <Modal width={config.modalWidth} height={config.modalHeight}>
            {config?.buttonCloseX && (
              <ButtonX
                colorBg={config?.style?.colorBgButtonX}
                onClick={closeModalFunction}
              >
                <IoClose size={25} color="#4B4B4D" />
              </ButtonX>
            )}
            <WrapperTitle alignIcon={config?.style?.alignIcon}>
              <IconPersonStyled
                universityColor={universityColor}
                sizeIconPerson={config?.style?.sizeIconPerson}
                src={config.icon || IconPerson}
              />
              <BubbleDialog universityColor={universityColor}>
                <H4Styled>{config.bubbleText}</H4Styled>
              </BubbleDialog>
            </WrapperTitle>
            {(config?.ParagraphText || config?.TwoParagraphText) && (
              <ParagraphStyled
                textAlign={config?.style?.textAlign}
                fontWeight={config?.style?.fontWeight}
                marginTop={config?.style?.marginTop}
                fontSize={config?.style?.fontSize}
                lineHeight={config?.style?.lineHeight}
                gapParagraph={config?.style?.gapParagraph}
                maxWidth={config?.style?.maxWidth}
              >
                {config?.ParagraphText && (
                  <p>{ReactHtmlParser(config.ParagraphText)}</p>
                )}
                {config?.TwoParagraphText && (
                  <p>{ReactHtmlParser(config.TwoParagraphText)}</p>
                )}
              </ParagraphStyled>
            )}

            {children}

            <WrapperButtons
              marginButtons={config?.style?.marginButtons}
              flexOrientation={config?.style?.buttonsOrientation}
            >
              {config.showButtonCancel && (
                <ButtonCancel
                  universityColor={universityColor}
                  width={config.buttonCancelWidth}
                  height={config.buttonCancelHeight}
                  onClick={() => buttonCancelFunction && buttonCancelFunction()}
                  disabled={config.loading}
                  order={config.buttonCancelOrder}
                >
                  {config.buttonCancelText}
                </ButtonCancel>
              )}

              {config.showButtonConfirm && (
                <Button
                  type="button"
                  universityColor={universityColor}
                  width={config.buttonConfirmWidth}
                  height={config.buttonConfirmHeight}
                  onClick={() =>
                    buttonConfirmFunction && buttonConfirmFunction()
                  }
                  disabled={config.loading}
                  order={config.buttonConfirmOrder}
                >
                  {config.loading && <FilterLoading />}
                  {config.buttonConfirmText}
                </Button>
              )}
            </WrapperButtons>
          </Modal>
        </Box>
      </Wrapper>
    ),
    [modalOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

BlankModalInformation.defaultProps = {
  config: {
    modalWidth: '415px',
    modalHeight: '418px',
    iconWidth: '95px',
    iconHeight: '95px',
    titleWidth: '100px',
    bubbleText: '...',
    ParagraphText: 'Adicione sua Descrição',
    TwoParagraphText: 'Adicione uma segunda frase a descrição',
    buttonWidth: '290px',
    buttonHeight: '50px',
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
    loading: false,
    icon: IconPerson,
    showButtonCancel: false,
    buttonCancelText: 'CANCELAR',
  },
};
