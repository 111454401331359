import React from 'react';
import BodyCards from '../components/Cards/BodyCards';

const ChallengesPageTwo = (props) => {
  const {
    activeTab,
    setActiveTab,
    isOpenModal,
    setIsOpenModal,
    formData,
    setFormData,
    currentStep,
    setCurrentStep,
    cacheOptions,
    setCacheOptions,
    setFormValues,
    challenges,
    count,
    loadChallenges,
    setChallenges,
    modalInfoOpen,
    setModalInfoOpen,
  } = props;
  return (
    <BodyCards
      setActiveTab={setActiveTab}
      isOpenModal={isOpenModal}
      activeTab={activeTab}
      setCurrentStep={setCurrentStep}
      currentStep={currentStep}
      formData={formData}
      setFormData={setFormData}
      setFormValues={setFormValues}
      cacheOptions={cacheOptions}
      setCacheOptions={setCacheOptions}
      setIsOpenModal={setIsOpenModal}
      challenges={challenges}
      count={count}
      loadChallenges={loadChallenges}
      setChallenges={setChallenges}
      modalInfoOpen={modalInfoOpen}
      setModalInfoOpen={setModalInfoOpen}
    />
  );
};

export default ChallengesPageTwo;
