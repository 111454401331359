import React, { useContext } from 'react';

import AuthContext from 'contexts/authentication';
import { func } from 'prop-types';
import { ButtonNotification, ImageIcon } from './styled';
import iconNotification from 'assets/img/iconsCurriculum/iconNotification.svg';

const ButtonNotifications = (props) => {
  const auth = useContext(AuthContext);
  const { toggleNotificationDrawer } = props;

  return (
    <>
      {auth.isLogged ? (
        <ButtonNotification
          round
          justIcon
          aria-label="open drawer"
          onClick={toggleNotificationDrawer}
        >
          <ImageIcon src={iconNotification} />
        </ButtonNotification>
      ) : null}
    </>
  );
};

ButtonNotifications.propTypes = {
  toggleNotificationDrawer: func,
};

export default ButtonNotifications;
