import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import {
  getSubjectsCourse,
  removeDisciplinesInMatrix,
  updateDiscipline,
} from '../../../services/matrixServices';
import { Bar, FlagIconWrapper, SpanItems } from '../style';
import {
  WrapperBar,
  WrapperList,
  Title,
  ContainerName,
  ActionArrow,
  ExpandedContent,
  FlagsWrapper,
  ExpandedContainer,
} from './style';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

import upArrow from 'assets/Matriz/upArrow.svg';
import downArrow from 'assets/Matriz/downArrow.svg';
import ModalInformation from '../../informationModal';
import useDragScrolling from './useDragScrolling';
import JobBagIcon from 'assets/ComponentIcons/JobBagIcon';
import { GraduationIcon } from 'assets/ComponentIcons/GraduationIcon';
import Tooltip from '../../../../../../../../components/NewTooltip';
import BaseLayoutContext from 'contexts/base-layout';

export default function DragItem({
  code,
  id,
  name,
  matrix,
  selectedSubjects,
  selectedCourse,
  setSelectedSubjects,
  getCourseSubjects,
  setSubjectsCourse,
  showMoreId,
  setShowMoreId,
  setAllDisciplinesCourse,
  getMatrices,
  internshipDiscipline,
  extensionProjectDiscipline,
  updateList,
}) {
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [dataToRemove, setDataToRemove] = useState({});
  const [configModal, setConfigModal] = useState({});
  const { openSnackbar } = useContext(BaseLayoutContext);

  const {
    addEventListenerForWindow,
    removeEventListenerForWindow,
  } = useDragScrolling();

  const [{ collected, isDragging }, dragRef, preview] = useDrag({
    type: 'Card',
    collect: (monitor) => ({
      isDragging: monitor.isDragging,
    }),
    item: (item, monitor) => {
      addEventListenerForWindow();
      return { ids: selectedSubjects };
    },

    end: () => {
      removeEventListenerForWindow();
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  const openMore = showMoreId === code;

  const handleClickShowMore = async () => {
    if (openMore) {
      setShowMoreId('');
      return;
    }
    setShowMoreId(code);
  };

  const checkboxChecked = selectedSubjects.includes(id);

  const handleClickCheckBox = (e) => {
    if (e.target.checked) {
      setSelectedSubjects((prevs) => [...prevs, id]);
      return;
    }

    const removed = selectedSubjects.filter((ids) => ids !== id);
    return setSelectedSubjects(removed);
  };

  const configModalConfirm = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja remover esta disciplina? Ao
     realizar essa alteração o indicador de trabalhabilidade poderá sofrer alterações`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
  };
  const configModalConfirmLoading = {
    bubbleText: 'Atenção!',
    ParagraphText: `Tem certeza de que deseja remover esta disciplina? Ao
     realizar essa alteração o indicador de trabalhabilidade poderá sofrer alterações`,
    buttonConfirmText: 'SIM, TENHO CERTEZA',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    icon: IconPerson,
    loading: true,
  };

  const handleConfirm = async () => {
    setConfigModal(configModalConfirmLoading);
    await removeDisciplinesInMatrix(dataToRemove.matrixId, dataToRemove.id);
    const { results } = await getCourseSubjects(selectedCourse);
    const output = await getSubjectsCourse(selectedCourse);
    setAllDisciplinesCourse(output);
    setSubjectsCourse(results);
    getMatrices(selectedCourse);
  };

  const handleCancel = () => {
    setModalConfirmOpen(false);
  };

  const handleRemoveDisciplineFromMatrix = async (matrixId, id) => {
    setModalConfirmOpen(true);
    setConfigModal(configModalConfirm);
    setDataToRemove({ matrixId, id: [id] });
  };

  const mapMatricesRelated = matrix.map((item) => (
    <ContainerName>
      {item.name || 'Matriz atual (renomeie)'}
      <span onClick={() => handleRemoveDisciplineFromMatrix(item.id, id)}>
        X
      </span>
    </ContainerName>
  ));

  const returnQuantityMatrix = () => {
    if (matrix.length > 1) {
      return `${matrix.length} Selecionadas`;
    }
    if (!matrix.length) {
      return '';
    }

    return matrix[0].name || 'Matriz atual (renomeie)';
  };

  async function handleCheckIsInternshipDiscipline() {
    updateList(id, { internship_discipline: !internshipDiscipline });
    const response = await updateDiscipline(id, {
      internship_discipline: !internshipDiscipline,
    });

    if (response.status !== 200) {
      updateList(id, { internship_discipline: !internshipDiscipline });
      openSnackbar('Erro ao atualizar disciplina', true);
    }
  }

  async function handleCheckIsExtensionProjectDiscipline() {
    updateList(id, {
      extension_project_discipline: !extensionProjectDiscipline,
    });
    const response = await updateDiscipline(id, {
      extension_project_discipline: !extensionProjectDiscipline,
    });

    if (response.status !== 200) {
      updateList(id, {
        extension_project_discipline: !extensionProjectDiscipline,
      });
      openSnackbar('Erro ao atualizar disciplina', true);
    }
  }

  return (
    <WrapperBar openMore>
      <ModalInformation
        modalOpenState={modalConfirmOpen}
        buttonConfirmFunction={handleConfirm}
        buttonCancelFunction={handleCancel}
        config={configModal}
      />
      <Bar
        ref={dragRef}
        checked={checkboxChecked}
        useBorder
        openMore={openMore}
      >
        <Tooltip text={'selecionar'} direction={'top'}>
          <input
            type="checkbox"
            checked={checkboxChecked}
            onClick={handleClickCheckBox}
          />
        </Tooltip>
        <SpanItems width="120px">{code}</SpanItems>
        <SpanItems width="230px">
          {name}
          {internshipDiscipline && (
            <Tooltip
              text={'disciplina de estágio obrigatório'}
              direction={'top'}
            >
              <FlagIconWrapper>
                <JobBagIcon color={'#009291'} size={14} />
              </FlagIconWrapper>
            </Tooltip>
          )}
          {extensionProjectDiscipline && (
            <Tooltip
              text={'disciplina do projeto de extensão'}
              direction={'top'}
            >
              <FlagIconWrapper>
                <GraduationIcon color={'#009291'} size={16} />
              </FlagIconWrapper>
            </Tooltip>
          )}
        </SpanItems>
        <SpanItems width="140px">{returnQuantityMatrix()}</SpanItems>
        <ActionArrow onClick={handleClickShowMore}>
          <img
            onClick={handleClickShowMore}
            src={openMore ? upArrow : downArrow}
          />
        </ActionArrow>
      </Bar>
      {openMore && (
        <ExpandedContainer>
          <ExpandedContent checked={checkboxChecked}>
            {matrix.length > 0 && (
              <Fragment>
                <Title>Matrizes relacionadas:</Title>
                <WrapperList>{mapMatricesRelated}</WrapperList>
              </Fragment>
            )}
            <FlagsWrapper>
              <div>
                <input
                  type="checkbox"
                  checked={internshipDiscipline}
                  id={'internshipDiscipline'}
                  onClick={handleCheckIsInternshipDiscipline}
                />
                <label htmlFor="internshipDiscipline">
                  Definir como disciplina de <b>estágio obrigatório</b>
                </label>
              </div>
              <div>
                <input
                  type="checkbox"
                  checked={extensionProjectDiscipline}
                  id={'extensionProjectDiscipline'}
                  onClick={handleCheckIsExtensionProjectDiscipline}
                />
                <label htmlFor="extensionProjectDiscipline">
                  Definir como disciplina do <b>projeto de extensão</b>
                </label>
              </div>
            </FlagsWrapper>
          </ExpandedContent>
        </ExpandedContainer>
      )}
    </WrapperBar>
  );
}
