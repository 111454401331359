import BaseModalAcademic from '../base';
import { Form } from '@unform/web';
import { useContext, useEffect, useRef, useState } from 'react';
import AcademicModelingApi from 'services/university/academicModeling';
import MultiSelect from './MultiSelect';
import { SaveButtonWrapper } from '../base/style';
import { useAcademicModeling } from '../../../../../contexts/AcademicModelingContext';
import BaseLayoutContext from 'contexts/base-layout';
import ModalInformation from 'components/informationModal';

export default function CreateTagModal({
  item,
  courseId,
  openModal,
  setOpenModal,
  removeItemFromList,
  isTendencies,
  defaultDisciplines,
  defaultTags,
  contemplated,
  refetchMarket,
}) {
  const formRef = useRef(null);
  const [optionsDisciplines, setOptionsDisciplines] = useState([]);
  const [optionsTag, setOptionsTag] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const {
    updateMarketRequirementsMetrics,
    updateMarketTendenciesMetrics,
  } = useAcademicModeling();
  const [responseData, setResponseData] = useState({});
  const { openSnackbar } = useContext(BaseLayoutContext);

  const [modalInformationOpen, setModalInformationOpen] = useState(false);

  const messageControll = (quantity) => {
    if (quantity > 1) {
      return 'tags de conteúdos foram relacionadas';
    }
    return 'tag de conteúdo foi relacionada';
  };

  const handleClickCloseInformationModal = () => {
    setOpenModal(false);
    setTags([]);
    setModalInformationOpen(false);
    if (contemplated) {
      refetchMarket(responseData.data);
    } else {
      removeItemFromList(item.id);
      if (isTendencies) {
        updateMarketTendenciesMetrics();
      } else {
        updateMarketRequirementsMetrics();
      }
    }
  };

  const configModalInformation = {
    bubbleText: 'Sucesso!',
    ParagraphText: `<strong>${tags.length}</strong> ${messageControll(
      tags.length
    )} ao requisito de mercado ${
      item.name
    }. <br/> <br/> Agora esse requisito está disponível na aba "Contemplados".`,
    buttonConfirmText: 'OK, ENTENDI',
    showButtonCancel: false,
    modalHeight: 380,
    buttonConfirmFunction: () => handleClickCloseInformationModal(),
  };

  useEffect(() => {
    if (optionsTag.length === 0) {
    }
  }, []);

  useEffect(() => {
    setDisciplines(defaultDisciplines);
  }, [defaultDisciplines]);

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  const getTagsFilter = async (event) => {
    const values = event.map((item) => item.value).join('&disciplines=');
    const mountedQuery = event.length > 0 ? `?disciplines=${values}` : '';
    const response = await AcademicModelingApi.getTagsCreateTag(
      courseId,
      mountedQuery
    );
    setOptionsTag(response);
  };

  const getOptions = async () => {
    if (!openModal) {
      return null;
    }
    let query;
    if (defaultDisciplines && defaultDisciplines.length > 0) {
      query = `?disciplines=${defaultDisciplines
        .map((item) => item.value)
        .join('&disciplines=')}`;
    }

    const promises = [
      AcademicModelingApi.getDisciplinesCreateTag(courseId),
      AcademicModelingApi.getTagsCreateTag(courseId, query),
    ];

    setIsLoading(true);

    const [disciplines, tags] = await Promise.all(promises);
    setOptionsDisciplines(disciplines);
    setOptionsTag(tags);
    setIsLoading(false);
  };

  useEffect(() => {
    getOptions();
  }, [openModal, defaultDisciplines]);

  const handleSubmit = async () => {
    setRequestInProgress(true);
    const tagsIds = tags.map((item) => item.value);
    let response;
    if (item.association_id) {
      response = await AcademicModelingApi.updateAssociation(
        item.association_id,
        { tags: tagsIds }
      );
    } else {
      response = await AcademicModelingApi.createAssociation({
        area: item.id,
        tags: tags.map((item) => item.value),
        university_course: courseId,
      });
    }
    if (response.status === 201 || response.status === 200) {
      setModalInformationOpen(true);
      setResponseData(response);
    } else {
      openSnackbar('Ocorreu um erro, tente novamente.', true);
    }
    setRequestInProgress(false);
  };

  const hasTags = tags?.length > 0;

  return (
    <BaseModalAcademic
      openModal={openModal}
      setOpenModal={setOpenModal}
      headerTitle={item.name}
    >
      <p>
        Selecionar tags relacionadas ao requisito de mercado{' '}
        <strong>{item.name}</strong>. Caso deseje, filtre as tags por
        disciplinas para facilitar o processo.
      </p>

      <ModalInformation
        modalOpenState={modalInformationOpen}
        config={configModalInformation}
        buttonConfirmFunction={configModalInformation.buttonConfirmFunction}
      />

      <Form ref={formRef} onSubmit={handleSubmit}>
        <MultiSelect
          onChange={getTagsFilter}
          selectedOptions={disciplines}
          setSelectedOptions={setDisciplines}
          name="discipline"
          options={optionsDisciplines}
          label="Filtrar por disciplina"
          placeholder={'Digite ou selecione as disciplinas'}
          isLoading={isLoading}
          isMulti
          isOptional
        />
        <MultiSelect
          name="tags"
          selectedOptions={tags}
          setSelectedOptions={setTags}
          options={optionsTag}
          label="Selecione Tag*"
          placeholder={'Digite ou selecione as tags'}
          isLoading={isLoading}
          isMulti
        />
        <SaveButtonWrapper>
          <button
            disabled={!hasTags || requestInProgress}
            type={'submit'}
            title={!hasTags ? 'Selecione ao menos uma tag' : ''}
          >
            Salvar
          </button>
        </SaveButtonWrapper>
      </Form>
    </BaseModalAcademic>
  );
}
