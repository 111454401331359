import React, { useState } from 'react';
import styled from 'styled-components';
import { bool, func, shape } from 'prop-types';

const Star = styled.span`
  color: ${(props) => (props.marked ? '#f2994a' : '#C5C5C5')};
  cursor: pointer;
  font-size: 40px;
  margin-right: 10px;
  cursor: pointer;

  @media screen and (max-width: 430px) {
    font-size: 35px;
  }
`;

function StarRate({ marked, favoriteHandler, profession }) {
  const [mark, setMark] = useState(marked);

  const handleClick = () => {
    setMark(!mark);

    if (favoriteHandler) favoriteHandler(profession);
  };

  return (
    <Star className="material-icons" marked={mark} onClick={handleClick}>
      star
    </Star>
  );
}
StarRate.propTypes = {
  marked: bool.isRequired,
  favoriteHandler: func.isRequired,
  profession: shape.isRequired,
};

export default StarRate;
