import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { dnaColor } from 'assets/jss/material-dashboard-pro-react';

export const Card = styled.div`
  padding: 2px 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 14%);
  margin-bottom: 25px;
`;

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.div`
  margin-left: 7px;
`;

export const ButtonEdit = styled.button`
  font-size: 14px;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 700;

  width: 72px;
  height: 32px;

  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  background-color: transparent;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ContainerAlert = styled.div`
  display: flex;
  align-items: center;
`;

export const CardProgress = styled.div`
  padding: 2px 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 14%);
  margin-bottom: 25px;

  @media (max-width: 600px) {
    margin-bottom: 45px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 20px 0;
`;

export const HeaderTitle = styled.h3`
  color: #3c4858;
  font-size: 20px;
  font-weight: 500;
  margin: ${(props) => (props.hasMarginRight ? '0 8px 0 0' : '')};
`;

export const TitleDiversity = styled.h3`
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 20px;
  font-weight: 400;
`;

export const SubtitleDiversity = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 1px 26px;
  flex-direction: column;
  align-items: baseline;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #828282;
`;

export const GenderLegend = styled.div`
  width: 100%;
  max-width: 800px;
  padding: 12px 26px;
  flex-direction: column;
  align-items: baseline;
  font-family: Roboto;
  font-style: italic;
  font-size: 14px;
  line-height: 30px;
  color: #828282;

  p {
    margin: 0px;
  }
`;

export const GenderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid #009291;
  width: 100%;
  padding: 8px;
`;

export const ImageTip = styled(GenderLegend)`
  text-align: center;
  max-width: 360px;
  margin-top: 10px;
  line-height: 20px;
`;

export const CardDiversity = styled.div`
  display: flex;
  border-radius: 20px;
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  background-color: #fff;
  width: 100%;
  padding: 10px;
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #999999;
    margin: 0;
  }
`;
export const CardBody = styled.div``;

export const CardBodyTitle = styled.h4`
  color: #3c4858;
  font-weight: 700;
  font-size: 14px;
  margin-left: 6px;
  line-height: 15px;
`;

export const CardBodySubTitle = styled.h5`
  color: #3c4858;
  display: flex;
  font-weight: 400;
  font-size: 14px;
  flex-direction: row;
  gap: 2%;
  margin-top: 0;
  a {
    color: #5578ac;
    &:visited &:hover &:active {
      color: #5578ac;
    }
  }
  ${(props) => (props.capitalize ? 'text-transform: capitalize' : '')}
  ${(props) => (props.hasMarginBottom ? 'margin-bottom: 30px' : '')}
`;
export const CardBodyProgress = styled.div`
  color: #3c4858;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  gap: 2%;

  @media (max-width: 600px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
`;
export const Img = styled.img`
  width: ${(props) => (props.hands ? '36px' : '32px')};
  margin-top: -2px;
  margin-right: ${(props) => props.hands && '-2px'};
`;

export const ImgIcon = styled.img`
  width: 15%;
  height: 60%;
  margin-right: 10px;
  margin-top: 5px;
`;

export const ImgIconDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 0 10px;
`;

export const ImgStar = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  z-index: 200;
  background: #ffffff;
  right: -10px;
  top: -10px;
  box-shadow: 0px 2.10526px 5.26316px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  margin-bottom: 0;
  margin-left: 15px;
  text-transform: inherit;
  ${(props) => (props.active ? 'color: #009291;' : 'color: #c3c3c3')}
`;

export const TextProgress = styled.p`
  margin-top: 10px;
  margin-bottom: 5px;
  text-transform: inherit;
  color: #000;
`;

export const ValueProgress = styled.div`
  position: absolute;
  background: ${(props) => props.curriculumComplete && props.universityColor};
  height: 134px;
  width: 134px;
  border-radius: 50%;

  > h1 {
    font-weight: bold;
    color: ${(props) => (props.curriculumComplete ? 'white' : '#4B4B4D')};
    margin: 39px 0 0 0;
  }

  > p {
    color: ${(props) => (props.curriculumComplete ? 'white' : '#8B8B8B')};
  }
`;
export const ExpandButton = styled(IconButton)`
  width: 100%;
  @media (min-width: 600px) {
    display: none !important;
  }
  span {
    transition: 0.1s linear;
    ${(props) =>
      props.active
        ? `
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);`
        : ``};
  }
`;
export const EmptyText = styled.h4`
  text-align: center;
  color: #999999;
`;

export const ButtonsDiv = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 600px) {
    flex-direction: column;

    button {
      width: 75%;
      margin: 0 auto;
    }
  }

  button {
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    color: #fff;
    border: none;
    border-radius: 5px;
    width: auto;
    height: 48px;
    padding: 14px;
    text-transform: uppercase;
    font-weight: 750;
    cursor: pointer;
    font-size: 14px;
  }
`;

export const ButtonsDivFooter = styled.div`
  margin: 0 0 0 2.5%;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media (max-width: 600px) {
    overflow: hidden;
    display: none;
  }
`;

export const ButtonsDivStatus = styled.div`
  overflow: hidden;
  display: none;

  @media (max-width: 600px) {
    display: inline;
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: -4%;
  }
`;

export const ButtonCurriculum = styled.button`
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  background-color: white;
  box-shadow: -1px 4px 5px 0px #979797;
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 750;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 14px;
  @media (max-width: 600px) {
    position: absolute;
    left: 12%;
    width: 75%;
  }
`;

export const ButtonCoverLetter = styled.button`
  border: 1px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  background-color: white;
  box-shadow: -1px 4px 5px 0px #979797;
  padding: 10px 15px;

  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 750;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 14px;
  @media (max-width: 600px) {
    width: 75%;
    position: absolute;
    left: 12%;
    top: 40%;
  }
`;

export const ButtonSaveCoverLetterAndCurriculum = styled.button`
  color: #fff;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  padding: 10px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 750;
  cursor: pointer;
  font-family: 'Roboto';
  font-size: 14px;
  border: none;
  &:hover {
    color: #fff;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
  }
`;

export const Main = styled.span`
  font-weight: 400;
  div {
    margin: 10px 0;
  }
  li {
    margin: 5px 0;
  }
  strong {
    font-weight: bold;
  }
`;

export const Header = styled.div`
  p {
    ${(props) =>
      props.noMarginTop ? 'margin: 0px 0px 15px' : 'margin: 15px 0'}
  }
  div {
    margin: 10px 0;
  }
`;
export const Tips = styled.p`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

export const IncomeDiv = styled.div`
  color: ${(props) => (props.indicator === 'income' ? '#009291' : '#3c4858')};
`;

export const MarketDiv = styled.div`
  color: ${(props) => (props.indicator === 'market' ? '#009291' : '#3c4858')};
`;

export const OportunitiesDiv = styled.div`
  color: ${(props) =>
    props.indicator === 'oportunities' ? '#009291' : '#3c4858'};
`;

export const TendencesDiv = styled.div`
  color: ${(props) =>
    props.indicator === 'tendences' ? '#009291' : '#3c4858'};
`;
