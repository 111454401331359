export function IconGiftReward({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 5.83333H14.8883C15.4659 5.30673 15.9236 4.66218 16.2304 3.94331C16.5372 3.22444 16.686 2.44803 16.6667 1.66667H15C15 4.0675 13.3575 5.25583 11.54 5.66667C12.0857 4.69317 12.4133 3.61266 12.5 2.5C12.5 1.83696 12.2366 1.20107 11.7678 0.732233C11.2989 0.263392 10.663 0 10 0C9.33696 0 8.70107 0.263392 8.23223 0.732233C7.76339 1.20107 7.5 1.83696 7.5 2.5C7.58671 3.61266 7.91427 4.69317 8.46 5.66667C6.6425 5.25583 5 4.0675 5 1.66667H3.33333C3.31399 2.44803 3.46277 3.22444 3.76958 3.94331C4.0764 4.66218 4.53409 5.30673 5.11167 5.83333H2.5C1.83696 5.83333 1.20107 6.09673 0.732233 6.56557C0.263392 7.03441 0 7.67029 0 8.33333L0 11.6667H1.66667V20H18.3333V11.6667H20V8.33333C20 7.67029 19.7366 7.03441 19.2678 6.56557C18.7989 6.09673 18.163 5.83333 17.5 5.83333ZM10 1.66667C10.221 1.66667 10.433 1.75446 10.5893 1.91074C10.7455 2.06702 10.8333 2.27899 10.8333 2.5C10.7327 3.38569 10.4489 4.24076 10 5.01083C9.55106 4.24076 9.26726 3.38569 9.16667 2.5C9.16667 2.27899 9.25446 2.06702 9.41074 1.91074C9.56702 1.75446 9.77899 1.66667 10 1.66667ZM1.66667 8.33333C1.66667 8.11232 1.75446 7.90036 1.91074 7.74408C2.06702 7.5878 2.27899 7.5 2.5 7.5H9.16667V10H1.66667V8.33333ZM3.33333 11.6667H9.16667V18.3333H3.33333V11.6667ZM16.6667 18.3333H10.8333V11.6667H16.6667V18.3333ZM18.3333 10H10.8333V7.5H17.5C17.721 7.5 17.933 7.5878 18.0893 7.74408C18.2455 7.90036 18.3333 8.11232 18.3333 8.33333V10Z"
        fill="white"
      />
    </svg>
  );
}
