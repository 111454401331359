import styled from 'styled-components';

export const ListCaroussel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  padding: 29px;
  gap: 36px;
  overflow: hidden;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;

  @media (max-width: 375px) {
    padding: 20px;
  }

  @media (max-width: 320px) {
    padding: 6px;
  }
`;

export const ContainerSelfdevelopment = styled.div`
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;
