import React, {
  Fragment,
  useRef,
  useEffect,
  useCallback,
  useState,
  useContext,
} from 'react';
import { useField } from '@unform/core';

import InformationModal from '../../../views/Company/OpportunitiesCompany/components/InformationModal';
import {
  ButtonDelet,
  ButtonPickFile,
  ErrorMessage,
  FileContainer,
  FileWrapper,
} from '../styles/styleForm';

import { OpportunitiesCompanyContext } from '../../../views/Company/OpportunitiesCompany/context/OpportunitiesCompanyContext';

export default function FileInputPdf({ name, formRef, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const [existFile, setExistFile] = useState(defaultValue);
  const [alertType, setAlertType] = useState(false);
  const { formData, setFormValues } = useContext(OpportunitiesCompanyContext);
  const [key, setKey] = useState(Date.now());
  const [informationModalOpen, setInformationModalOpen] = useState(false);
  const [currentError, setCurrentError] = useState('oversizedFile');

  const errorMessages = {
    oversizedFile: {
      header: 'Ooops! Tamanho do documento excedido.',
      message: 'O tamanho do documento não pode superar 20mb',
    },
    oversizedName: {
      header: 'Ooops! O nome do arquivo excede o limite.',
      message: 'O nome do arquivo não pode superar 100 caracteres.',
    },
  };

  const handlePreview = (e) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      setPreview(null);
      setExistFile(null);
    }

    if (file.type !== 'application/pdf') {
      setExistFile(null);
      setAlertType(true);
      e.target.value = null;
      return;
    } else {
      setAlertType(false);
    }

    if (file.size > 20971520) {
      setCurrentError('oversizedFile');
      setInformationModalOpen(true);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    if (file.name.length > 100) {
      setCurrentError('oversizedName');
      setInformationModalOpen(true);
      setExistFile(null);
      e.target.value = null;
      return;
    }

    setExistFile(file);
  };

  const handleDelet = useCallback((e) => {
    delete formData.attraction_document;
    e.preventDefault();
    inputRef.current.value = '';
    setExistFile(null);
    setPreview(null);
    setExistFile(null);
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        if (formData.attraction_document) {
          return formData.attraction_document;
        }
        if (ref.files[0]) {
          const file = ref.files[0];

          if (file.size > 20971520) {
            return null;
          } else {
            return ref.files[0];
          }
        }
        return null;
      },

      clearValue(ref) {
        ref.value = '';

        setPreview(null);
        setExistFile(null);
      },
      setValue(_, value) {
        setPreview(value);
        setExistFile(null);
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (defaultValue) {
      setExistFile(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Fragment>
      <InformationModal
        isOpen={informationModalOpen}
        type={'error'}
        setModalOpen={setInformationModalOpen}
        informationHeader={errorMessages[currentError].header}
        errorMsg={errorMessages[currentError].message}
      />
      {existFile && (
        <FileWrapper>
          <FileContainer>
            {existFile && (
              <span style={{ wordBreak: 'break-all' }}>{existFile.name}</span>
            )}
          </FileContainer>
          <ButtonDelet onClick={handleDelet} />
        </FileWrapper>
      )}
      {alertType && (
        <ErrorMessage paddingBottom="5px">
          Desculpe, somente arquivos do tipo PDF são permitidos.
        </ErrorMessage>
      )}
      {!existFile && (
        <ButtonPickFile style={{ marginTop: '8px' }} onClick={handleClick}>
          {' '}
          Selecionar arquivo
        </ButtonPickFile>
      )}
      <input
        style={{ display: 'none' }}
        type="file"
        key={key}
        ref={inputRef}
        onChange={handlePreview}
        {...rest}
      />
    </Fragment>
  );
}
