import styled from 'styled-components';

export const CloseButtonStyle = styled.div`
  width: 35px;
  height: 35px;
  background-color: #e5e5e5;
  position: absolute;
  top: 20px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  > img {
    filter: invert(0%) sepia(4%) saturate(0%) hue-rotate(224deg)
      brightness(107%) contrast(103%);
    max-width: 50%;
    cursor: pointer;
  }
`;
