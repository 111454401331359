import React, { Component } from 'react';
import { shape, func, bool } from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { getCoverLetter } from 'services/student/coverLetter';

import { Grid } from '@material-ui/core';
import { BlobProvider } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf';
import { Loading, AbsolutWrapper, Header } from 'components/Styled';
import CoverLetter from './components/CoverLetter';
import CoverLetterForm from '../CoverLetterForm';
import * as S from './styled';
import { ButtonSaveCoverLetterAndCurriculum } from '../../../../MyProfile/components/Styled';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = {
  document: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  page: {
    marginBottom: 20,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
};

class CoverLetterContainer extends Component {
  constructor() {
    super();
    this.documentDiv = React.createRef();
  }

  state = {
    pdfLink: null,
    pdfPages: 0,
    coverLetter: null,
  };

  async componentDidMount() {
    const {
      openSnackbar,
      closeModal,
      notStudent,
      studentId,
      getApplicationCoverLetter,
    } = this.props;
    let response;

    if (notStudent) response = await getApplicationCoverLetter(studentId);
    else response = await getCoverLetter();

    if (response.message) {
      openSnackbar(
        'Falha ao carregar sua carta. Tente novamente mais tarde!',
        true
      );
      closeModal();
    } else {
      this.setState({ coverLetter: response });
    }
  }

  componentWillUnmount() {
    this.setState({
      coverLetter: null,
      pdfPages: null,
      pdfLink: null,
    });
  }

  loadPages = () => {
    const { pdfPages } = this.state;
    const { classes } = this.props;

    const pages = [];
    for (let i = 1; i <= pdfPages; i++) {
      pages.push(
        <Page
          width={this.documentDiv.current.offsetWidth}
          className={classes.page}
          pageNumber={i}
        />
      );
    }

    return pages;
  };

  updateCoverLetter = () => {
    const {
      openModalWithContent,
      openSnackbar,
      closeModal,
      updateHasCoverLetter,
      handleLoadingState,
      universityColor,
    } = this.props;

    const { coverLetter } = this.state;

    openModalWithContent(
      <CoverLetterForm
        universityColor={universityColor}
        openSnackbar={openSnackbar}
        closeModal={closeModal}
        handleLoadingState={handleLoadingState}
        updateHasCoverLetter={updateHasCoverLetter}
        initialValues={coverLetter}
        openModalWithContent={openModalWithContent}
      />
    );
  };

  render() {
    const {
      handleLoadingState,
      classes,
      notStudent,
      universityColor,
    } = this.props;
    const { coverLetter, pdfLink } = this.state;

    return (
      <AbsolutWrapper>
        <Header title="Carta de Apresentação" />
        <Grid container>
          <Grid item xs md={12}>
            {coverLetter && (
              <S.CoverLetterContainer container>
                <Grid item xs={12} sm={12} md={8}>
                  <div ref={this.documentDiv}>
                    <BlobProvider
                      document={
                        <CoverLetter
                          universityColor={universityColor}
                          coverLetter={coverLetter}
                        />
                      }
                    >
                      {({ blob, url, loading, error }) =>
                        !loading ? (
                          <Document
                            className={classes.document}
                            file={url}
                            loading={<Loading color={universityColor} />}
                            noData={null}
                            onLoadSuccess={(pdf) => {
                              handleLoadingState(false);
                              this.setState({
                                pdfPages: pdf.numPages,
                                pdfLink: url,
                              });
                            }}
                          >
                            {this.loadPages().map((page) => page)}
                          </Document>
                        ) : null
                      }
                    </BlobProvider>
                  </div>
                </Grid>
                <S.MenuContainer item xs={12} sm={12} md={4}>
                  <S.Menu>
                    <a
                      href={pdfLink}
                      download={`Carta de apresentação ${coverLetter.student.name}`}
                    >
                      <ButtonSaveCoverLetterAndCurriculum
                        universityColor={universityColor}
                      >
                        Baixar carta de apresentação
                      </ButtonSaveCoverLetterAndCurriculum>
                    </a>
                    <S.PrintButton
                      style={{ width: '200px', borderRadius: '5px' }}
                      universityColor={universityColor}
                      onClick={() => {
                        const win = window.open(pdfLink);
                        win.print();
                      }}
                    >
                      Imprimir
                    </S.PrintButton>
                    {!notStudent && (
                      <S.PrintButton
                        universityColor={universityColor}
                        style={{ width: '200px', borderRadius: '5px' }}
                        onClick={this.updateCoverLetter}
                      >
                        Editar
                      </S.PrintButton>
                    )}
                  </S.Menu>
                </S.MenuContainer>
              </S.CoverLetterContainer>
            )}
          </Grid>
        </Grid>
      </AbsolutWrapper>
    );
  }
}

CoverLetterContainer.propTypes = {
  classes: shape.isRequired,
  notStudent: bool.isRequired,
  handleLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  closeModal: func.isRequired,
  openModalWithContent: func.isRequired,
  updateHasCoverLetter: func.isRequired,
};

export default withStyles(styles)(CoverLetterContainer);
