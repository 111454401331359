import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  BackButton,
  CloseButton,
  Container,
  FinishProjectButton,
  Footer,
  NextButton,
  StepIndicatorContainer,
  Wrapper,
} from './styled';
import { ExtensionProjectsContext } from '../../../../../Context/ExtensionProjectsContext';
import { RiCloseFill } from 'react-icons/ri';
import StepIndicator from 'components/StepIndicator';
import { StepOne } from './Steps/StepOne';
import { StepTwo } from './Steps/StepTwo';
import * as Yup from 'yup';
import { finalizeProjectForm } from 'views/StaffPages/Opportunities/services';
import { FinishProjectAdapterForm } from './Steps/StepOne/utils/FinishProjectAdapter';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';

export default function ModalFinishProject() {
  const {
    extensionProjectId,
    setModalFinishProject,
    setExtensionProjectId,
    refModalExtensionProject,
    formDataFinishProject,
    setFormValues,
    getAllToFinishProjects,
    informationItem,
    setInformationItem,
  } = useContext(ExtensionProjectsContext);

  const [step, setStep] = useState(1);
  const [hasRegister, setHasRegister] = useState(false);

  const refStepOne = useRef();
  const refStepTwo = useRef();

  useEffect(() => {
    if (refModalExtensionProject.current) {
      refModalExtensionProject.current.scrollIntoView();
    }
  }, [refModalExtensionProject]);

  const handleBackHome = () => {
    setModalFinishProject(false);
    setExtensionProjectId(null);
    setInformationItem(null);
    setFormValues({
      externalPublic: null,
      impactedPublic: null,
      scores: {
        score: null,
      },
    });
  };

  function renderStep() {
    switch (step) {
      case 1:
        return <StepOne refStepOne={refStepOne} />;
      case 2:
        return (
          <StepTwo setHasRegister={setHasRegister} refStepTwo={refStepTwo} />
        );
    }
  }

  function handleBack() {
    setStep(1);
  }

  function handleSubmitForm() {
    if (refStepOne.current && step === 1) {
      const data = refStepOne?.current?.getData();
      setFormValues(data);
    }
    setStep(2);
  }

  function handleChangeStep(step) {
    switch (step) {
      case 1:
        handleBack();
        break;
      case 2:
        handleSubmitForm();
        break;
    }
  }

  const FinishedForm = async () => {
    const data = refStepTwo?.current?.getData();

    if (refStepTwo.current && step === 2) {
      let formatData = {};

      if (data.score) {
        formatData = {
          score: data.score?.replace(',', '.'),
        };

        try {
          const schema = Yup.object().shape({
            score: Yup.string()
              .required('Campo obrigatório')
              .test('len', 'A nota não pode ser superior que 100', (val) =>
                Number(val.replace(',', '.')) > 100 ? false : true
              ),
          });

          await schema.validate(formatData, {
            abortEarly: false,
          });

          refStepTwo.current.setErrors({});
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const validationErrors = {};
            err.inner.forEach((error) => {
              validationErrors[error.path] = error.message;
            });
            refStepTwo.current.setErrors(validationErrors);
            return;
          }
        }
      }

      try {
        await finalizeProjectForm(
          extensionProjectId,
          FinishProjectAdapterForm({
            ...formDataFinishProject,
            ...formatData,
          })
        );
      } catch (err) {
        if (err.response.status === 400) {
          return customSnackbar(
            err.response?.data?.length > 0
              ? err.response.data[0]
              : 'Ocorreu um erro ao finalizar o projeto, tente novamente mais tarde',
            'error'
          );
        }
        return customSnackbar(
          'Ocorreu um erro ao finalizar o projeto, tente novamente mais tarde',
          'error'
        );
      }

      await getAllToFinishProjects(null, true);

      setModalFinishProject(false);
      setExtensionProjectId(null);
      setFormValues({
        externalPublic: null,
        impactedPublic: null,
        scores: {
          score: '',
        },
      });
    }
  };

  return (
    <Container>
      <Wrapper>
        <CloseButton onClick={handleBackHome}>
          <RiCloseFill size={25} />
        </CloseButton>
        <StepIndicatorContainer>
          <StepIndicator
            count={2}
            currentStep={step}
            onStepChange={handleChangeStep}
          />
        </StepIndicatorContainer>
        {renderStep()}
      </Wrapper>
      <Footer>
        {step === 1 && (
          <NextButton type={'button'} onClick={handleSubmitForm} />
        )}
        {step === 2 && (
          <>
            <BackButton type={'button'} onClick={handleBack} />
            <div>
              <FinishProjectButton
                disabled={hasRegister === false}
                onClick={FinishedForm}
              >
                Finalizar projeto
              </FinishProjectButton>
            </div>
          </>
        )}
      </Footer>
    </Container>
  );
}
