import {
  Box,
  Container,
  Content,
  HeaderGradient,
  ContainerImage,
  ContainerHeader,
  ContainerInformations,
  SkeletonCard,
  SkeletonImage,
  SkeletonTitle,
  SkeletonInformations,
} from './styled';

import location from 'assets/SettingsPage/Profile/location.svg';
import building from 'assets/SettingsPage/Profile/building.svg';
import university from 'assets/SettingsPage/Profile/university.svg';

import { useCallback, useEffect, useRef, useState } from 'react';
import About from './About';
import Courses from './Courses';
import Gallery from './Gallery';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import IESCompanyAPI from '../../../Service/API';
import TabJobCompany from 'views/JobOfferRebuild/Components/CompanyJobsOpenings/components/Tabs/index.jsx';

export default function PreviewModal({ id, activeTab, setActiveTab }) {
  const [data, setData] = useState({});

  const getDataPreview = async () => {
    const data = await IESCompanyAPI.getUniversityData(id);
    setData(data);
  };

  useEffect(() => {
    getDataPreview();
  }, []);

  const {
    color,
    logo,
    educational_group,
    city,
    state,
    unities,
    courses,
    initials,
  } = data;

  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const tabs = {
    0: <About data={data} />,
    1: <Courses id={id} />,
    2: <Gallery data={data} />,
  };

  if (Object.keys(data).length > 0) {
    return (
      <Content>
        <HeaderGradient color={color}></HeaderGradient>
        <ContainerImage>
          <img src={logo?.file} />
        </ContainerImage>
        <ContainerHeader>
          <h3>{initials}</h3>
          <ContainerInformations>
            <span>
              <div>
                <img src={building} />
              </div>
              <p>
                {unities} {unities > 1 ? 'unidades' : 'unidade'}
              </p>
            </span>
            <span>
              <div>
                <img src={location} />
              </div>
              <p>
                {city} / {state}
              </p>
            </span>
            {educational_group && (
              <span>
                <div>
                  <img src={university} />
                </div>
                <p>{educational_group}</p>
              </span>
            )}
          </ContainerInformations>
        </ContainerHeader>

        <div
          style={{
            marginBottom: '30px',
            border: '1px solid #D9D9D9',
            margin: '35px 40px',
          }}
        >
          <div style={{ width: '100%' }}>
            <TabJobCompany
              activeTab={activeTab}
              handleTabChange={handleTabChange}
              tabs={[
                `Sobre a instituição`,
                `Cursos ofertados (${courses})`,
                `Galeria de fotos`,
              ]}
              showFilter={false}
            />
          </div>

          {tabs[activeTab]}
        </div>
      </Content>
    );
  }

  return (
    <SkeletonCard>
      <SkeletonImage />
      <SkeletonTitle />
    </SkeletonCard>
  );
}
