import * as Yup from 'yup';

const formValidationHandler = {
  validateForm: async ({ schemaValidation, data, formRef }) => {
    try {
      await schemaValidation.validate(data, {
        abortEarly: false,
      });
      formRef.current.setErrors({});
    } catch (error) {
      const validationErrors = {};
      if (error instanceof Yup.ValidationError) {
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      throw error;
    }
  },

  convertSchema: (schema) => {
    const newSchema = {};

    const typeMap = {
      string: Yup.string(),
      number: Yup.number(),
      boolean: Yup.boolean(),
      date: Yup.date(),
    };

    Object.keys(schema.fields).forEach((key) => {
      const field = schema.fields[key];

      if (field._exclusive && field._exclusive.required) {
        const fieldType = typeMap[field._type];
        if (fieldType) {
          newSchema[key] = fieldType.required();
        }
      }
    });

    return Yup.object().shape(newSchema);
  },
};

export default formValidationHandler;
