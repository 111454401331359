import React, { useState } from 'react';
import { Card } from '../../Card';
import { useVisualizationContext } from 'views/StaffPages/FairAndEvents/Contexts/VisualizationContext';
import { BsBuilding, BsCalendar } from 'react-icons/bs';
import { RiGraduationCapLine } from 'react-icons/ri';
import { CardFormatHelper } from 'views/StaffPages/FairAndEvents/Helpers/CardFormatterHelper';
import { IoIosLaptop } from 'react-icons/io';
import { MdQrCodeScanner } from 'react-icons/md';
import Button from '../../../Button';
import { VisualizationHandlers } from 'views/StaffPages/FairAndEvents/Handlers/visualizationHandlers';
import { ContainerCards, SearchAndCardsContainer } from '../styled';
import Search from '../../../SearchBar';
import { useRegisterModalContext } from 'views/StaffPages/FairAndEvents/Contexts/RegisterModalContext';
import { CoreHandlers } from 'views/StaffPages/FairAndEvents/Handlers/CoreHandlers';
import { FilterHandlersFactory } from 'views/StaffPages/FairAndEvents/Handlers/FilterHandlers';
import GenericMessage from 'components/GenericMessage';
import CardSkeleton from '../../Card/Skeleton';

export default function OnGoing() {
  const visualizationContextInstance = useVisualizationContext();
  const registerModalContextInstance = useRegisterModalContext();

  const filterHandlerInstanceFactory = FilterHandlersFactory(
    visualizationContextInstance
  );

  const CoreHandlersFactory = CoreHandlers(
    visualizationContextInstance,
    registerModalContextInstance
  );

  const {
    onGoing,
    setStudentViewModalOpen,
    setSelectedEvent,
  } = visualizationContextInstance;

  const handleClickSeeMoreStudents = (event) => {
    setSelectedEvent(event);
    setStudentViewModalOpen(true);
  };

  const [searchText, setSearchText] = useState('');

  const handleChangeSearch = (event) => {
    const value = event.target.value;

    setSearchText(value);
    filterHandlerInstanceFactory.handleChangeSearchParam(value);
  };

  const results = onGoing?.value?.results;

  const widthButtons = `calc(50% - 7.5px)`;

  const card = results?.map((item) => (
    <Card.Root key={item.code}>
      <Card.Status status={CardFormatHelper.formatStatus(item, 1)} />
      <Card.Image src={item.event_cover.file} />
      <Card.Title id={item.code} title={item.name} />
      <Card.ContainerInformation>
        <Card.Information
          icon={<BsCalendar color="#009291" />}
          text={CardFormatHelper.formatLabelDate(item)}
        />
        <Card.Information
          icon={
            item.event_modality === 'presential' ? (
              <BsBuilding color="#009291" />
            ) : (
              <IoIosLaptop color="#009291" />
            )
          }
          text={item.event_modality === 'presential' ? 'Presencial' : 'Online'}
        />
        <Card.Information
          icon={<RiGraduationCapLine color="#009291" />}
          text={CardFormatHelper.formatQuantityStudents(item)}
          seeMore={item.registred_students > 0 && 'ver'}
          onClickSeeMore={() => handleClickSeeMoreStudents(item)}
        />
      </Card.ContainerInformation>

      <Card.ContainerButton>
        <Button
          width={widthButtons}
          theme="unfilled"
          onClick={() =>
            VisualizationHandlers.handleClickDetails(
              visualizationContextInstance,
              item.id
            )
          }
        >
          Detalhes
        </Button>
        <Button
          width={widthButtons}
          theme="dark"
          onClick={() => CoreHandlersFactory.handleClickEdit(item.id)}
        >
          Editar
        </Button>
        <Button
          width="100%"
          icon={<MdQrCodeScanner />}
          onClick={() => CoreHandlersFactory.handleClickCheckIn(item.id)}
        >
          Realizar check-in
        </Button>
      </Card.ContainerButton>
    </Card.Root>
  ));

  if (results?.length === 0 && searchText.length === 0) {
    return (
      <GenericMessage
        resetIndex={true}
        title="Nenhum evento encontrado"
        subtitle="No momento não existem eventos em andamento."
      />
    );
  }

  if (!card) {
    return <CardSkeleton />;
  }

  return (
    <SearchAndCardsContainer>
      <Search onChange={handleChangeSearch} value={searchText} />
      <ContainerCards>{card}</ContainerCards>
    </SearchAndCardsContainer>
  );
}
