import { Container, SubTitle, Title } from './style';
import { Form } from '@unform/web';
import { useRef, useContext, useState, useEffect } from 'react';
import SimpleInput from 'components/UnformFields/SimpleInput';
import { CloseButtonInternal, SaveButton } from '../../Styles/BaseModalForm';
import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';
import { CompaniesManagementContext } from 'views/StaffPages/CompaniesManagement/Context/CompaniesManagementContext';
import SimpleReactSelect from 'components/UnformFields/SimpleReactSelect';
import {
  PayloadDocuments,
  PayloadPreRegistrationComapany,
} from 'views/StaffPages/CompaniesManagement/Utils/FormPreRegistrationPayload';
import DragAndDropFile from '../DragAndDropFile';
import { cnpjRegex, handleFieldMask, validateCNPJ } from 'utils/validation';
import { maskCnpj, maskInputPhone } from 'utils/inputMasks';
import states from 'variables/states';
import { getCities } from 'services/ibge';
import * as Yup from 'yup';
import SimpleCheckbox from 'components/UnformFields/SimpleCheckbox';
import {
  companyDocumentsPreRegistration,
  companyPreRegistration,
  isComapanyAndInviteRegistered,
  updateForm,
} from 'views/StaffPages/CompaniesManagement/Services';
import { useAPICalls } from 'views/StaffPages/CompaniesManagement/Hooks/apiCalls';
import { isStaffEmailExists } from 'services/companies';

export default function CompanyPreRegistrationForm({
  isEdit,
  isCreated,
  setOpenModalFormEdit,
  companyId,
  setUsingPreviewPdf,
  getCountTabs,
}) {
  const companyPreRegistrationForm = useRef();

  const {
    setOpenModalForm,
    formData,
    setFormData,
    setInviteCreated,
    setInviteSent,
    documentFiles,
  } = useContext(CompaniesManagementContext);

  const [arrayFiles, setArrayFiles] = useState([]);
  const [cities, setCities] = useState([]);

  const { getInviteCreated, getInviteSent } = useAPICalls();

  const [modalInformationOpen, setModalInformationOpen] = useState(false);
  const [modalInformationConfig, setModalInformationConfig] = useState({});
  const [isValidated, setIsValidated] = useState(false);

  const schemaPreRegistration = Yup.object().shape({
    organizationNumber: Yup.string()
      .matches(cnpjRegex, { message: 'Informe um CNPJ válido.' })
      .test(
        'organizationNumberValidation',
        'Informe um CNPJ válido.',
        (value) => {
          if (value) {
            return validateCNPJ(value);
          }
          return false;
        }
      )
      .test(
        'organizationRegisteredIsEdit',
        'Esta empresa já é cadastrada.',
        async (value) => {
          if (isEdit) {
            if (value !== formData.organizationNumber) {
              const isRegistered = await isComapanyAndInviteRegistered(value);
              return !isRegistered.exists;
            }

            return true;
          }
          if (isCreated) {
            if (value) {
              const isRegistered = await isComapanyAndInviteRegistered(value);
              return !isRegistered.exists;
            }
          }
        }
      )
      .required('Este campo é obrigatório.'),
    staffName: Yup.string().required(
      'Por gentileza, informe o nome do responsável pela empresa.'
    ),
    corporateName: Yup.string().max(
      255,
      'O nome da empresa passou o limite de caracteres.'
    ),
    staffEmail: Yup.string()
      .required('Este campo é obrigatório')
      .email('Você deve informar um e-mail válido.')
      .test('staff_email', 'E-mail já está em uso', async (value) => {
        if (value) {
          const output = await isStaffEmailExists(value);
          const { exists } = output;

          if (exists) {
            return false;
          }
          return true;
        }
      }),
  });

  useEffect(() => {
    if (Object.keys(formData).length > 0 && isEdit === true) {
      companyPreRegistrationForm.current?.setFieldValue(
        'state',
        formData.state
      );
    }
  }, [formData]);

  useEffect(() => {
    setArrayFiles(documentFiles);
  }, [documentFiles]);

  useEffect(() => {
    if (isCreated) {
      companyPreRegistrationForm.current.reset();
      setArrayFiles([]);
    }
  }, [isCreated]);

  useEffect(() => {
    if (cities.length > 0) {
      companyPreRegistrationForm.current?.setFieldValue('city', formData.city);
    }
  }, [cities, formData]);

  const closeModal = () => {
    if (isEdit) {
      setOpenModalFormEdit(false);
      setArrayFiles([]);
    }
    setOpenModalForm(false);
    setArrayFiles([]);
    setFormData({});
  };
  const { IoClose } = useIcons();

  const handleSubmit = async (data) => {
    const adpt = PayloadPreRegistrationComapany(data);
    const documents = PayloadDocuments(arrayFiles);

    try {
      companyPreRegistrationForm.current.setErrors({});

      await schemaPreRegistration.validate(data, {
        abortEarly: false,
      });
      setIsValidated(true);

      if (Object.keys(formData).length > 0 && isEdit === true) {
        const response = await updateForm(companyId, adpt);

        function isFile(obj) {
          return obj && obj.name && obj.type;
        }

        if (response.data.status === 'created') {
          setInviteCreated(null);
          getCountTabs();
          await getInviteCreated('limit=20&offset=0');
        }

        if (response.data.status === 'invited') {
          setInviteSent(null);
          getCountTabs();
          await getInviteSent('limit=20&offset=0');
        }

        setOpenModalFormEdit(false);
      }

      if (isCreated) {
        const response = await companyPreRegistration(adpt);

        if (response.data.status === 'created') {
          setInviteCreated(null);
          getCountTabs();
          await getInviteCreated('limit=20&offset=0');
        }

        if (response.data.status === 'invited') {
          setInviteSent(null);
          getCountTabs();
          await getInviteSent('limit=20&offset=0');
        }

        setOpenModalForm(false);
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        companyPreRegistrationForm.current.setErrors(validationErrors);
      }
    }
  };

  const getCurrentCities = async (UF) => {
    const response = await getCities(UF);
    const arrayCities = response?.map((item) => ({
      label: item.nome,
      value: item.id,
    }));
    setCities(arrayCities);
  };

  const handleSelectUf = (e) => {
    const currentUf = e?.value;
    getCurrentCities(currentUf);
  };

  const handleBlurEmail = async (event) => {
    companyPreRegistrationForm.current.setErrors({});
    const value = event.target.value;
    const email = value.toLowerCase();

    if (isValidated) {
      return;
    }
    if (email !== '') {
      const output = await isStaffEmailExists(email);
      const { exists } = output;

      if (exists) {
        return companyPreRegistrationForm.current.setFieldError(
          'staffEmail',
          'E-mail já está em uso'
        );
      }
    }
  };

  const radioOptions = [{ id: false, value: false }];

  return (
    <Container>
      <CloseButtonInternal onClick={closeModal}>
        <IoClose />
      </CloseButtonInternal>
      <Title>Formulário de pré-cadastro de empresa</Title>
      <SubTitle>
        Preencha os dados abaixo para realizar o pré-cadastro da empresa.
      </SubTitle>

      <Form
        initialData={formData}
        ref={companyPreRegistrationForm}
        onSubmit={handleSubmit}
      >
        <SimpleInput
          name="organizationNumber"
          label="CNPJ *"
          placeholder="Digite aqui..."
          onKeyUp={(event) => {
            handleFieldMask(event, maskCnpj);
          }}
          marginTop="20px"
        />
        <SimpleInput
          name="corporateName"
          label="Razão social"
          placeholder="Digite aqui..."
          marginTop="10px"
        />

        <div className="state-city">
          <SimpleReactSelect
            name="state"
            label="Estado"
            placeholder="Selecione"
            options={states}
            maxWidth="40%"
            onChange={handleSelectUf}
          />
          <SimpleReactSelect
            name="city"
            label="Cidade"
            options={cities}
            placeholder="Selecione"
            maxWidth={'100%'}
            isDisabled={cities.length === 0}
          />
        </div>
        <SimpleInput
          name="staffName"
          label="Responsável legal da empresa *"
          placeholder="Digite aqui..."
        />
        <SimpleInput
          name="staffEmail"
          label="E-mail do responsável legal da empresa *"
          placeholder="Digite aqui..."
          marginTop="20px"
          onBlur={handleBlurEmail}
        />
        <SimpleInput
          name="staffPhone"
          label="Celular ou telefone fixo"
          placeholder="Digite aqui..."
          marginTop="20px"
          onKeyUp={(event) => {
            handleFieldMask(event, maskInputPhone);
          }}
        />
        {isEdit === true ? null : (
          <SimpleCheckbox
            name="sendInviteEmail"
            type="radio"
            label={
              'Desejo enviar o convite para a empresa ao finalizar o pré-cadastro'
            }
            marginTop="20px"
            options={radioOptions}
          />
        )}

        <div className="container-button">
          <SaveButton type="submit">Salvar</SaveButton>
        </div>
      </Form>
    </Container>
  );
}
