import {
  CloseButton,
  Container,
  Content,
  FooterNavigation,
  NavigationButton,
  Overlay,
  StepIndicatorWrapper,
  SubmitButton,
} from './styled';
import { IconCloseModal } from 'assets/img/iconsCurriculum/iconCloseModal';
import StepIndicator from 'components/StepIndicator';
import React, { useEffect, useRef } from 'react';
import { CreateMentorStepOne } from './steps/CreateMentorStepOne';
import { CreateMentorStepTwo } from './steps/CreateMentorStepTwo';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { useCreateMentor } from './context';
import FilterLoading from 'components/FilterModal/components/FilterLoading';

export function CreateMentorModal() {
  const refContainer = useRef(null);
  const {
    isOpen,
    handleClose,
    handleChangeStep,
    currentStep,
    handleSubmit,
    isSubmitting,
  } = useCreateMentor();

  useEffect(() => {
    if (refContainer.current && isOpen) {
      refContainer.current.scrollTo(0, 0);
    }
  }, [currentStep, refContainer, isOpen]);

  const stepsBuilder = {
    1: <CreateMentorStepOne />,
    2: <CreateMentorStepTwo />,
  };

  return (
    <Overlay isOpen={isOpen}>
      <Container ref={refContainer}>
        <Content>
          <CloseButton type={'button'} onClick={handleClose}>
            <IconCloseModal color={'#4B4B4D'} />
          </CloseButton>
          <StepIndicatorWrapper>
            <StepIndicator
              count={2}
              currentStep={currentStep}
              onStepChange={async (step) => {
                handleChangeStep(step);
              }}
            />
          </StepIndicatorWrapper>
          {stepsBuilder[currentStep]}
          <FooterNavigation>
            {currentStep === 1 ? (
              <NavigationButton
                type={'button'}
                onClick={() => handleChangeStep(2)}
                marginLeft={'auto'}
              >
                <BsArrowRight size={22} color={'#fff'} />
              </NavigationButton>
            ) : (
              <>
                <NavigationButton
                  type={'button'}
                  onClick={() => handleChangeStep(1)}
                >
                  <BsArrowLeft size={22} color={'#fff'} />
                </NavigationButton>
                <SubmitButton type={'button'} onClick={handleSubmit}>
                  {isSubmitting ? <FilterLoading /> : 'Salvar informações'}
                </SubmitButton>
              </>
            )}
          </FooterNavigation>
        </Content>
      </Container>
    </Overlay>
  );
}
