import React, { useState, useEffect, useContext } from 'react';
import TabJobCompany from '../components/Tabs';

import { Container, Content } from './styled';
import JobsInProgress from './components/Tabs/JobsInProgress';
import PublishedByPartners from './components/Tabs/PublishedByPartners';
import { formatNumber } from '../../../../../utils/formatNumber';
import { getPublishedJobsFilters } from 'views/JobOfferRebuild/services';
import { JobOfferContext } from 'views/JobOfferRebuild/Contexts/JobOfferContext';

export default function PublishedJobsTabs(props) {
  const {
    countMyJobs,
    countJobsPartners,
    countJobs,
    role,
    seeMatch,
    setOpenModalPublishJob,
  } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [filterOptions, setFilterOptions] = useState({});
  const { setCurrentJobFilter } = useContext(JobOfferContext);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <JobsInProgress
          countJobs={countJobs}
          seeMatch={seeMatch}
          setOpenModalPublishJob={setOpenModalPublishJob}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <PublishedByPartners countJobs={countJobs} seeMatch={seeMatch} />
      ),
    },
  ];
  const handleTabChange = (_, value) => {
    setActiveTab(value);
  };

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);

    return returnTabs.component;
  };

  useEffect(() => {
    setCurrentJobFilter(null);
    if (activeTab === 0) {
      getPublishedJobsFilters().then((data) => {
        setFilterOptions(data);
      });
    }
  }, [activeTab]);

  return (
    <Container>
      <Content>
        <TabJobCompany
          activeTab={activeTab}
          handleTabChange={handleTabChange}
          tabs={
            role !== 'company'
              ? [
                  `Minhas Vagas (${formatNumber(countMyJobs)})`,
                  `Vagas Publicadas por Parceiros (${formatNumber(
                    countJobsPartners
                  )})`,
                ]
              : [`Minhas Vagas (${formatNumber(countMyJobs)})`]
          }
          filterOptions={filterOptions}
        />
        {renderPrincipalTabs(activeTab)}
      </Content>
    </Container>
  );
}
