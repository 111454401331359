import { Divider, FieldLabel, Form, TextTitle } from '../../styled';
import React, { useContext, useEffect, useState } from 'react';
import { SelectField } from 'views/StaffPages/Mentorship/components/SelectField';
import NoteInput from 'components/UnformFields/noteInput';
import { ImageInput } from './ImageInput';
import { useCreateMentor } from '../../context';
import ModalInformation from 'components/informationModal';
import AuthContext from 'contexts/authentication';
import {
  Column,
  InfoIconWrapper,
  Row,
  SpecialitiesInput,
  SpecialitiesWrapper,
  SpecialityChipButton,
  Tooltip,
} from './styles';
import { FaPlus } from 'react-icons/fa';
import { AddButton } from 'views/StaffPages/Academic/components/AcademicModeling/components/Steps/StepTwo/TodoTab/style';
import {
  customSnackbar,
  SnackbarType,
} from 'components/CustomSnackBar/customSnackbar';
import DefaultTrash from 'assets/ComponentIcons/DefaultTrash';
import { InfoCycleIcon } from 'assets/ComponentIcons/InfoCycleIcon';
import { Switch } from './Switch';
import { mentorshipApi } from '../../../../../../services/api';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';

export function CreateMentorStepOne() {
  const [showInactiveStatusAlert, setShowInactiveStatusAlert] = useState(false);
  const {
    isOpen,
    stepOneRef,
    formData,
    validateStepOne,
    options,
    specialities,
    setSpecialities,
    noSpecialitiesError,
    setNoSpecialitiesError,
    isEdit,
    mentor,
    setMentor,
    finalPayload,
  } = useCreateMentor();
  const formDataAmount = Object.keys(formData).length;
  const { metadata } = useContext(AuthContext);
  const staffRole = metadata?.staff_role;
  const shouldRenderForStaff = ['teacher', 'course_coordinator'].includes(
    staffRole
  );
  const [specialitiesInputValue, setSpecialitiesInputValue] = useState('');
  const [filteredCoursesOptions, setFilteredCoursesOptions] = useState(
    options?.courses || []
  );
  const [status, setStatus] = useState(mentor?.status === 'Ativo');

  useEffect(() => {
    if (mentor) {
      setStatus(mentor?.status === 'Ativo' || mentor?.status === 'active');
    }
  }, [mentor]);

  useEffect(() => {
    if (isOpen && stepOneRef.current) {
      stepOneRef.current.setErrors({});
    }
  }, [stepOneRef, isOpen]);

  const configModalInactiveStatus = {
    bubbleText: 'Atenção!',
    ParagraphText: `Ao inativar este mentor, ele não ficará mais visível para os estudantes e todas as mentorias solicitadas ou agendadas serão canceladas.`,
    TwoParagraphText: 'Tem certeza de que deseja prosseguir?',
    buttonConfirmText: 'SIM, INATIVAR MENTOR',
    buttonCancelText: 'NÃO',
    showButtonCancel: true,
    style: {
      fontSize: '18px',
      lineHeight: '24px',
      bubbleWidth: '120px',
      sizeIconPerson: '78px',
      bubbleMargin: '4px 0',
      bubbleFontWeight: '400',
    },
  };

  const handleAddSpeciality = () => {
    const trimmedInputValue = specialitiesInputValue.trim();
    if (trimmedInputValue) {
      const specialityExists = specialities.find(
        (speciality) => speciality === trimmedInputValue
      );
      if (specialityExists) {
        customSnackbar('Especialidade já adicionada', 'error');
        return;
      }
      setSpecialities([...specialities, trimmedInputValue]);
      setNoSpecialitiesError(false);
      setSpecialitiesInputValue('');
    }
  };

  const handleRemoveSpeciality = (speciality) => {
    const newSpecialities = specialities.filter(
      (currentSpeciality) => currentSpeciality !== speciality
    );
    setSpecialities(newSpecialities);
    if (newSpecialities.length === 0) {
      setNoSpecialitiesError(true);
    }
  };

  const handleChangeMacroAreas = async (areas) => {
    if (!filteredCoursesOptions) return;
    let queryParams = null;
    if (areas?.length > 0) {
      const areasValues = areas.map((area) => area.value);
      queryParams = queryParamsFromFilterObject({ macro_areas: areasValues });
      const response = await mentorshipApi.getMentorsOptions(queryParams);
      setFilteredCoursesOptions(response.courses);
      const selectedCourses = stepOneRef?.current?.getData()?.courses || [];
      const newSelectedCourses = selectedCourses.filter((course) =>
        response.courses.some((option) => option.value === course.value)
      );
      stepOneRef?.current?.setFieldValue('courses', newSelectedCourses);
    } else {
      setFilteredCoursesOptions(options?.courses || []);
    }
  };

  const handleInactiveMentor = () => {
    const payload = finalPayload(formData);
    payload.id = mentor.id;
    payload.status = 'inactive';
    setShowInactiveStatusAlert(false);
    Promise.all([
      mentorshipApi.updateMentor(payload),
      mentorshipApi.cancelAllMentorships(mentor.id),
    ]).then(([responseUpdate, responseCancel]) => {
      if (
        !(responseUpdate instanceof Error) &&
        !(responseCancel instanceof Error)
      ) {
        setMentor({ ...mentor, status: 'inactive' });
        customSnackbar(
          'Mentor inativado com sucesso',
          SnackbarType.CONFIRMATION
        );
      } else {
        customSnackbar(
          'Ocorreu um erro ao inativar o mentor',
          SnackbarType.ERROR
        );
        setStatus(true);
      }
    });
  };

  const handleToggleStatus = async (value) => {
    if (value) {
      const payload = finalPayload(formData);
      payload.id = mentor.id;
      payload.status = 'active';
      mentorshipApi.updateMentor(payload).then((response) => {
        if (response) {
          setMentor({ ...mentor, status: 'active' });
          customSnackbar(
            'Mentor ativado com sucesso',
            SnackbarType.CONFIRMATION
          );
        }
      });
    } else {
      setShowInactiveStatusAlert(true);
    }
  };

  return (
    <Form ref={stepOneRef}>
      <TextTitle marginTop={'2rem'}>
        {shouldRenderForStaff
          ? 'Para se tornar um mentor, preencha as informações abaixo para' +
            ' completar o seu perfil.'
          : 'Para cadastrar um mentor, preencha as informações abaixo.'}
      </TextTitle>
      {isEdit && (
        <Row marginTop={'2rem'} alignItems={'center'}>
          <FieldLabel marginBottom={'0'}>Status do mentor</FieldLabel>
          <Switch
            onChange={handleToggleStatus}
            value={status}
            setValue={setStatus}
          />
        </Row>
      )}
      <Row alignItems={'flex-end'} gap={'0.5rem'}>
        <FieldLabel marginTop={'2rem'}>Nome*</FieldLabel>
        <Tooltip
          label="Selecione o nome do mentor no qual você deseja realizar o cadastro"
          width={'220px'}
          isTwoLines
        >
          <InfoIconWrapper>
            <InfoCycleIcon color={'#606062'} size={16} />
          </InfoIconWrapper>
        </Tooltip>
      </Row>
      <SelectField
        name={'staff'}
        placeholder={'Digite aqui'}
        options={options?.staff}
        onChange={(option) => {
          if (
            formDataAmount !== 0 ||
            (formDataAmount === 0 && !!option?.value)
          ) {
            validateStepOne('staff', option?.value);
          }
        }}
        isLoading={!options}
        defaultValue={formData.staff}
        disabled={isEdit}
      />
      <Divider />
      <Row alignItems={'flex-end'} gap={'0.5rem'}>
        <FieldLabel>Áreas de atuação</FieldLabel>
        <Tooltip
          label="Selecione as áreas de atuação nas quais o mentor tem conhecimento"
          width={'220px'}
          isTwoLines
        >
          <InfoIconWrapper>
            <InfoCycleIcon color={'#606062'} size={16} />
          </InfoIconWrapper>
        </Tooltip>
      </Row>
      <SelectField
        name={'macro_areas'}
        placeholder={'Todos'}
        options={options?.macro_areas}
        defaultValue={formData.macro_areas}
        onChange={handleChangeMacroAreas}
        isLoading={!options}
        isMulti
      />
      <Row alignItems={'flex-end'} gap={'0.5rem'}>
        <FieldLabel marginTop={'1rem'}>Cursos</FieldLabel>
        <Tooltip
          label="Os cursos são selecionados automaticamente de acordo com as áreas de atuação selecionadas"
          width={'220px'}
          isThreeLines
        >
          <InfoIconWrapper>
            <InfoCycleIcon color={'#606062'} size={16} />
          </InfoIconWrapper>
        </Tooltip>
      </Row>
      <SelectField
        name={'courses'}
        placeholder={'Todos'}
        options={filteredCoursesOptions}
        defaultValue={formData.courses}
        isLoading={!options}
        isMulti
      />
      <Row alignItems={'flex-end'} gap={'0.5rem'}>
        <FieldLabel marginTop={'1rem'}>Especialidades*</FieldLabel>
        <Tooltip
          label='Digite as especialidades nas quais o mentor domina e clique no "+” para adicioná-la ao perfil'
          width={'220px'}
          isThreeLines
        >
          <InfoIconWrapper>
            <InfoCycleIcon color={'#606062'} size={16} />
          </InfoIconWrapper>
        </Tooltip>
      </Row>
      <Row>
        <Column>
          <SpecialitiesInput
            name={'specialities'}
            type="text"
            placeholder={'Digite aqui...'}
            value={specialitiesInputValue}
            onChange={(e) =>
              setSpecialitiesInputValue(e.target.value?.toUpperCase())
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleAddSpeciality();
              }
            }}
            error={noSpecialitiesError}
            maxLength={50}
          />
          <Row justifyContent={'space-between'}>
            {noSpecialitiesError && (
              <p className={'error error-label'}>Este campo é obrigatório</p>
            )}
            <span>CARACTERES: {specialitiesInputValue.length}/50</span>
          </Row>
        </Column>
        <AddButton
          type={'button'}
          title={
            !specialitiesInputValue
              ? 'Preencha os campos para adicionar'
              : 'Adicionar'
          }
          formValid={!!specialitiesInputValue}
          disabled={!specialitiesInputValue}
          onClick={handleAddSpeciality}
        >
          <FaPlus
            color={specialitiesInputValue ? '#FFF' : '#949494'}
            size={18}
          />
        </AddButton>
      </Row>
      <SpecialitiesWrapper show={specialities?.length > 0}>
        {specialities.map((speciality, index) => (
          <Tooltip label="Remover" key={index}>
            <SpecialityChipButton
              className={'speciality-chip-button'}
              type={'button'}
              onClick={() => {
                handleRemoveSpeciality(speciality);
              }}
            >
              {speciality}
              <DefaultTrash color={'#009291'} size={14} />
            </SpecialityChipButton>
          </Tooltip>
        ))}
      </SpecialitiesWrapper>
      <Divider />
      <Row alignItems={'flex-end'} gap={'0.5rem'}>
        <FieldLabel>Insira a bio do mentor*</FieldLabel>
        <Tooltip
          label="Escreva uma pequena biografia sobre o mentor"
          width={'200px'}
          isTwoLines
        >
          <InfoIconWrapper>
            <InfoCycleIcon color={'#606062'} size={16} />
          </InfoIconWrapper>
        </Tooltip>
      </Row>
      <NoteInput
        name={'biography'}
        placeholder={'Digite aqui'}
        maxLength={500}
        useCount={true}
        onChange={(e) => validateStepOne('biography', e.target.value)}
        defaultValue={formData.biography || ''}
      />
      <ImageInput
        name={'avatar'}
        formRef={stepOneRef}
        fileSizeMBLimit={20}
        defaultValue={formData.avatar}
      />
      <ModalInformation
        modalOpenState={showInactiveStatusAlert}
        config={configModalInactiveStatus}
        buttonConfirmFunction={handleInactiveMentor}
        buttonCancelFunction={() => {
          setShowInactiveStatusAlert(false);
          setStatus(true);
        }}
      />
    </Form>
  );
}
