import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  right: ${(props) => (props.hasFilters ? '8rem' : '6rem')};
  top: 1rem;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  @media (max-width: 705px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerExtensionProject = styled.div`
  width: 100%;
`;

export const SpanError = styled.p`
  font-size: 12px;
  text-align: start;
  right: 0;
  color: #f00;
  margin: 0;
`;

export const ContainerFlexDirection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;

  > strong {
    font-size: 17px;
    font-weight: 700;
    color: #4b4b4d;
  }
`;
