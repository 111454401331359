import React, { Fragment } from 'react';

import { HeaderH4, InformationParagraph } from './style';

export default function Header() {
  return (
    <Fragment>
      <HeaderH4>Matrizes desativadas</HeaderH4>
      <InformationParagraph>
        Aqui você pode visualizar todas as matrizes que foram desativadas, e se
        necessário, reativá-las.
      </InformationParagraph>
      <br />
    </Fragment>
  );
}
