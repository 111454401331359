import { useCallback, useEffect, useState } from 'react';
import {
  Container,
  Filters,
  MentorshipListContainer,
  OrderFilter,
  SearchFilter,
  StyledSelect,
  WhiteBox,
} from '../styled';
import GenericMessage from 'components/GenericMessage';
import { useNextMentorshipContext } from '../../../context';
import { StudentCard } from '../../../components/StudentCard';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';
import { CancelModalContent } from '../../../components/CancelModalContent';
import RescheduleModalContent from '../../../components/RescheduleModalContent';
import SkeletonCardMentorship from 'views/StaffPages/Mentorship/components/SkeletonCard';
import { SeeMoreButton } from '../../../../MentorsTab/styled';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { SearchInputWithDebounce } from 'components/SearchInputWithDebounce';
import DetailsModal from 'views/StaffPages/Mentorship/components/DetailsModal';
import { useMentorship } from '../../../../../contexts/MentorshipContext';

export function ConfirmedStudents({ updateMentorshipsCount }) {
  const [selectedOrderOption, setSelectedOrderOption] = useState({
    value: '-date',
    label: 'Mais recentes para os mais antigos',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const { fetchMentorshipsCount, setMentorshipCounts } = useMentorship();
  const {
    page,
    setPage,
    hasNextPage,
    setHasNextPage,
    detailsModalOpen,
    rescheduleModalOpen,
    cancelModalOpen,
    modalData,
    openDetailsModal,
    closeDetailsModal,
    openRescheduleModal,
    closeRescheduleModal,
    mentorships,
    setMentorships,
    loading,
    setLoading,
    setModalData,
    setCancelModalOpen,
    appliedFilters,
    setAppliedFilters,
    filterState,
    setFilterState,
  } = useNextMentorshipContext();

  const fetchConfirmedMentorships = async (
    reset = false,
    order = selectedOrderOption.value
  ) => {
    setLoading(true);
    const offset = reset ? 0 : (page - 1) * 20;
    const queryString = queryParamsFromFilterObject(appliedFilters);
    let queryParams = `limit=20&offset=${offset}&ordering=${order}`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    const response = await mentorshipApi.getConfirmedMentorshipsList({
      queryParams,
    });
    const updatedMentorships = mentorships && !reset ? [...mentorships] : [];
    response.results.forEach((mentorship) => {
      const mentorshipIndex = updatedMentorships.findIndex(
        (element) => element.id === mentorship.id
      );
      if (mentorshipIndex > -1) {
        updatedMentorships[mentorshipIndex] = mentorship;
      } else {
        updatedMentorships.push(mentorship);
      }
    });
    setMentorships(updatedMentorships);
    setHasNextPage(response.next !== null);
    updateMentorshipsCount('confirmed', response.count);
    const countsResponse = await fetchMentorshipsCount();
    setMentorshipCounts({ ...countsResponse, confirmed: response.count });
    setLoading(false);
  };

  useEffect(() => {
    fetchConfirmedMentorships();
  }, []);

  const orderOptions = [
    { value: '-date', label: 'Mais recentes para os mais antigos' },
    { value: 'date', label: 'Mais antigos para os mais recentes' },
  ];

  const handleOrderChange = (selected) => {
    setSelectedOrderOption(selected);
    setPage(1);
    fetchConfirmedMentorships(true, selected.value);
  };

  const studentName =
    mentorships && modalData?.id
      ? mentorships.find((m) => m.id === modalData.id)?.student
      : '';

  const countDays =
    mentorships && modalData?.id
      ? mentorships.find((m) => m.id === modalData.id)?.count_days
      : '';

  const handleNextPage = useCallback(async () => {
    setLoading(true);
    const newPage = page + 1;
    setPage(newPage);
    const offset = (newPage - 1) * 20;
    const queryString = queryParamsFromFilterObject(appliedFilters);
    let queryParams = `limit=20&offset=${offset}&ordering=${selectedOrderOption.value}`;
    if (queryString) {
      queryParams = `${queryParams}&${queryString}`;
    }
    const response = await mentorshipApi.getConfirmedMentorshipsList({
      queryParams,
    });
    if (response) {
      setMentorships([...mentorships, ...response.results]);
      setHasNextPage(!!response.next);
    }
    setLoading(false);
  }, [mentorships, page, appliedFilters, selectedOrderOption]);

  const openCancelModal = (data) => {
    setModalData(data);
    setCancelModalOpen(true);
  };

  const closeCancelModal = () => {
    setCancelModalOpen(false);
    fetchConfirmedMentorships();
  };

  const handleSearchNameChange = (event) => {
    const newName = event.target.value;
    setSearchTerm(newName);
    setPage(1);
    setAppliedFilters({ ...appliedFilters, name: newName });
    setFilterState({ ...filterState, name: newName });
  };

  const onSearchName = () => {
    fetchConfirmedMentorships(true);
  };

  return (
    <Container>
      <Filters>
        <SearchFilter>
          <SearchInputWithDebounce
            placeholder={'Buscar'}
            setSearchTerm={(e) => handleSearchNameChange(e)}
            searchTerm={searchTerm}
            onSearch={onSearchName}
          />
        </SearchFilter>
        <OrderFilter>
          <StyledSelect
            value={selectedOrderOption}
            options={orderOptions}
            onChange={handleOrderChange}
            placeholder="Ordenar por"
            classNamePrefix="react-select"
          />
        </OrderFilter>
      </Filters>
      <WhiteBox>
        {mentorships?.length === 0 ? (
          <GenericMessage
            resetIndex={true}
            title="Nenhuma mentoria confirmada foi encontrada"
            subtitle={`No momento não existe mentorias disponíveis`}
          />
        ) : (
          <MentorshipListContainer>
            {mentorships?.map((item) => (
              <StudentCard
                key={item.id}
                item={item}
                buttonText="Cancelar mentoria"
                detailsButtonText="Detalhes"
                onOpenCompletedDetailsModal={() => openDetailsModal(item)}
                onOpenCancelMentorshipModal={() => openCancelModal(item)}
                openRescheduleModal={() => openRescheduleModal(item)}
                modalType="confirmed"
              />
            ))}
            {loading && (
              <SkeletonCardMentorship
                quantity={3}
                subTitleQuantity={4}
                cardHeight="350px"
                cardMargin="0"
              />
            )}
          </MentorshipListContainer>
        )}
        {hasNextPage && !loading && (
          <SeeMoreButton onClick={handleNextPage}>Ver mais</SeeMoreButton>
        )}
      </WhiteBox>
      <DetailsModal
        isOpen={detailsModalOpen}
        onClose={closeDetailsModal}
        item={modalData}
        studentName={studentName}
        title="Detalhes"
        openCancelModal={() => openCancelModal(modalData)}
        openRescheduleModal={() => openRescheduleModal(modalData)}
        showRejectButton={true}
        showChangeButton={true}
        showStudentNotes={true}
        showMentorName={true}
        showmodalityInfo={true}
        showCountDays={true}
        showFullDateFormat={true}
        setPage={setPage}
        fetchMentorships={fetchConfirmedMentorships}
      />
      <RescheduleModalContent
        isOpen={rescheduleModalOpen}
        onClose={closeRescheduleModal}
        item={modalData}
        countDays={countDays}
        studentName={studentName}
        title="Reagendar mentoria"
        openCancelModal={() => openCancelModal(modalData)}
        fetchMentorships={fetchConfirmedMentorships}
      />
      <CancelModalContent
        isOpen={cancelModalOpen}
        onClose={closeCancelModal}
        item={modalData}
        title="Cancelar Mentoria"
        fetchMentorships={fetchConfirmedMentorships}
      />
    </Container>
  );
}
