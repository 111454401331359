import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { func, shape } from 'prop-types';

import CardComposition from 'components/CardComposition/CardComposition';
import { Grid } from '@material-ui/core';

import { iesInviteAdapter } from 'adapters/company/iesInviteAdapter';
import {
  cancelInviteById,
  getIesInvites,
  toggleIesInviteById,
  getUniversitiesToConnect,
  connectToUniversity,
} from 'services/companies';
import updateStateOfObjects from 'utils/updateStateObjects';
import InvitesAccordion from 'components/InvitesAccordion/InvitesAccordion';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import headerBackGroundImage from 'assets/img/ies-company-invites.jpg';
import TabContainer from 'components/TabContainer';
import IesFilter from './components/IesFilter/IesFilter';

function IesInvites({
  handleIsLoadingState,
  openSnackbar,
  openDnaDialog,
  closeModal,
  metadata,
  classes,
  requesting,
}) {
  const [iesInvites, setIesInvites] = useState({
    received: [],
    sent: [],
  });
  const [universities, setUniversities] = useState([]);
  const [expanded, setExpanded] = useState('');
  const [activeTabContent, setActiveTabContent] = useState(0);
  const [connecting, setConnecting] = useState(false);

  async function fetchUniversities() {
    const response = await getUniversitiesToConnect();

    if (!response.message) {
      setUniversities(response);
    } else {
      openSnackbar(
        `Erro ao carregar as universidades, tente novamente em
           alguns minutos.`,
        true
      );
    }
  }
  async function fetchInvites() {
    const response = await getIesInvites();

    if (!response.message) {
      setIesInvites({
        received: response.received.map((ies) => iesInviteAdapter(ies)),
        sent: response.sent.map((ies) => iesInviteAdapter(ies)),
      });
    } else {
      openSnackbar(`Erro ao carregar os convites.`, true);
    }
  }

  const totalUnities = useMemo(() => {
    return iesInvites.sent.concat(iesInvites.received).reduce((total, current) => {
      return total + current.unities;
    }, 0);
  }, [iesInvites]);
  const handleExpansivePanel = (panel) => (event, isExpanded) => {
    if (isExpanded) {
      return setExpanded(panel);
    }
    return setExpanded('');
  };

  async function iesAcceptConnection(event, checked) {
    const { id } = event.target;
    const inviteStatus = await toggleIesInviteById(id, checked);

    const updateInvites = updateStateOfObjects(
      iesInvites.received,
      iesInviteAdapter(inviteStatus)
    );
    setIesInvites((prevValues) => ({
      ...prevValues,
      received: updateInvites,
    }));
  }

  async function handleCancelInvite(id) {
    const invite = await cancelInviteById(id);
    const updateInvites = updateStateOfObjects(
      iesInvites.sent,
      iesInviteAdapter(invite)
    );
    setIesInvites((prevValues) => ({
      ...prevValues,
      sent: updateInvites,
    }));
  }

  function handleTabChange(event, value) {
    setActiveTabContent(value);
  }

  async function handleConnect(universityId) {
    setConnecting(true);
    const response = await connectToUniversity(universityId);

    if (!response.message) {
      const universityIndex = universities.findIndex(
        (university) => university.id === universityId
      );
      const newUniversities = universities;
      newUniversities.splice(universityIndex, 1);
      const newIesInvites = iesInvites;
      newIesInvites.sent.push(response);

      fetchInvites();
      setUniversities(newUniversities);
      setIesInvites(newIesInvites);
    } else {
      openSnackbar(
        `Erro ao conectar a universidade, tente novamente mais tarde.`,
        true
      );
    }
    setConnecting(false);
  }

  useEffect(() => {
    async function fetchComponentData() {
      await Promise.all([fetchInvites(), fetchUniversities()]);
      handleIsLoadingState(false);
    }
    fetchComponentData();
  }, []);

  return (
    <Fragment>
      <CompanyHeader
        title="Conexões"
        activeTab={activeTabContent}
        handleTabChange={handleTabChange}
        backgroundImage={headerBackGroundImage}
        tabs={[
          `Unidades (${new Intl.NumberFormat('pt-BR').format(totalUnities)})`,
        ]}
      />
      <Grid container justify="space-around">
        <Grid item xs={11} md={11} sm={8} lg={activeTabContent === 11}>
          {activeTabContent === 0 && (
            <CardComposition
              customStyle={{marginTop: 75 }}
              CardContent={() => (
                <InvitesAccordion
                  data={iesInvites.sent.concat(iesInvites.received)}
                  openDnaDialog={openDnaDialog}
                  closeModal={closeModal}
                  openSnackbar={openSnackbar}
                  handleCancelInvite={handleCancelInvite}
                  setExpansivePanel={handleExpansivePanel}
                  expandedState={expanded}
                  role={metadata.role}
                  currentTab={activeTabContent} 
                />
              )}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
}

IesInvites.propTypes = {
  handleIsLoadingState: func.isRequired,
  closeModal: func.isRequired,
  openSnackbar: func.isRequired,
  openDnaDialog: func.isRequired,
  metadata: shape({}).isRequired,
};

export default IesInvites;
