export function Like({ color }) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5346 6.51395C19.1478 6.06819 18.6697 5.71075 18.1328 5.46583C17.5958 5.22091 17.0125 5.09422 16.4223 5.09435H13.1319L13.4091 3.41078C13.5072 2.81792 13.3888 2.20955 13.0757 1.69665C12.7626 1.18375 12.2756 0.800488 11.7034 0.616772C11.1313 0.433056 10.5121 0.461126 9.95896 0.695863C9.40578 0.930601 8.95541 1.35636 8.69 1.89549L7.1112 5.09435H4.87414C3.7807 5.09566 2.73241 5.53061 1.95923 6.30379C1.18605 7.07697 0.751096 8.12526 0.749786 9.2187L0.749786 13.3431C0.751096 14.4365 1.18605 15.4848 1.95923 16.258C2.73241 17.0312 3.7807 17.4661 4.87414 17.4674H15.8449C16.8376 17.4634 17.7959 17.1031 18.5454 16.4521C19.2949 15.8012 19.7858 14.9028 19.9289 13.9205L20.5104 9.79611C20.5923 9.21106 20.5476 8.61524 20.3792 8.04898C20.2109 7.48272 19.9228 6.95923 19.5346 6.51395ZM2.39953 13.3431V9.2187C2.39953 8.56239 2.66025 7.93297 3.12433 7.46889C3.58841 7.00481 4.21784 6.74409 4.87414 6.74409H6.52389V15.8177H4.87414C4.21784 15.8177 3.58841 15.557 3.12433 15.0929C2.66025 14.6288 2.39953 13.9994 2.39953 13.3431ZM18.873 9.56432L18.2907 13.6887C18.2055 14.2776 17.9118 14.8165 17.463 15.2072C17.0142 15.5979 16.44 15.8145 15.8449 15.8177H8.17363V6.52467C8.25137 6.45695 8.31572 6.37525 8.36335 6.28381L10.169 2.62551C10.2367 2.50337 10.3324 2.39904 10.4483 2.3211C10.5642 2.24315 10.6969 2.19382 10.8356 2.17715C10.9742 2.16049 11.1149 2.17696 11.2459 2.22522C11.377 2.27348 11.4947 2.35214 11.5894 2.45476C11.6705 2.549 11.7297 2.65996 11.7629 2.77973C11.7962 2.8995 11.8025 3.02513 11.7816 3.14765L11.3461 5.78724C11.327 5.90518 11.3337 6.02586 11.3658 6.14095C11.3979 6.25603 11.4547 6.36276 11.5321 6.45376C11.6095 6.54476 11.7058 6.61785 11.8142 6.66798C11.9227 6.7181 12.0408 6.74407 12.1602 6.74409H16.4223C16.7765 6.74404 17.1266 6.82003 17.4489 6.96692C17.7712 7.11382 18.0583 7.32819 18.2906 7.59555C18.5229 7.86291 18.6951 8.17703 18.7956 8.51668C18.8961 8.85633 18.9225 9.21359 18.873 9.56432Z"
        fill={color}
      />
    </svg>
  );
}
