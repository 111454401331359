import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 1rem 2rem;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    margin: 0;
  }
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  border-radius: 9px;
  margin-top: 1rem;
  width: 100%;
  box-shadow: 0 0 18px 0 #0000001a;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  row-gap: 1rem;
`;
