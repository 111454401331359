import { GrayBox, Row, StatusIconWrapper, Text } from '../../styles';
import { ExclamationIcon } from 'assets/ComponentIcons/ExclamationIcon';
import { Container } from './styles';
import { useMemo, useCallback } from 'react';
import { Tooltip } from '../Tooltip';
import { GeneralStatus } from '../../context';
import { GrowthIcon } from 'assets/ComponentIcons/GrowthIcon';
import { FaCheck } from 'react-icons/fa';

export function DataBox({
  title,
  percent,
  studentAmount,
  mediaPercent,
  minor,
  row,
  icon = false,
  tooltipWidth,
}) {
  const studentAmountStyled = useMemo(() => {
    return studentAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }, [studentAmount]);

  const status = useMemo(() => {
    const statusRatio = percent / mediaPercent;
    if (statusRatio === 1) {
      return GeneralStatus.EQUAL_AVERAGE;
    } else if (statusRatio < 1) {
      return GeneralStatus.BELOW_AVERAGE;
    } else {
      return GeneralStatus.ABOVE_AVERAGE;
    }
  }, [percent, mediaPercent]);

  const statusIcon = useMemo(() => {
    const icons = {
      [GeneralStatus.EQUAL_AVERAGE]: <GrowthIcon />,
      [GeneralStatus.ABOVE_AVERAGE]: <FaCheck size={19} color={'#fff'} />,
      [GeneralStatus.BELOW_AVERAGE]: <ExclamationIcon size={19} />,
    };
    return icons[status] || <ExclamationIcon size={15} />;
  }, [status]);

  const tooltipText = useCallback(
    (inArea = false) => {
      return {
        [GeneralStatus.EQUAL_AVERAGE]: `Sua média de estudantes trabalhando ${
          (inArea && 'na área') || ''
        } é
        igual a de outras instituições`,
        [GeneralStatus.ABOVE_AVERAGE]: `Parabéns! Sua média de estudantes trabalhando ${
          (inArea && 'na área') || ''
        } é maior do que a de  outras instituições.`,
        [GeneralStatus.BELOW_AVERAGE]: `Você possui menos estudantes  trabalhando ${
          (inArea && 'na área') || ''
        } do que a média das outras instituições`,
      };
    },
    [status]
  );
  const toolTipStatusText = useMemo(() => tooltipText(minor)[status], [status]);

  return (
    <Container row={row}>
      <Text fontWeight={500} fontSize={minor && !row ? '13px' : '16px'}>
        {title}
      </Text>
      <GrayBox
        width={minor ? '150px' : '194px'}
        padding={minor ? '1rem' : '2rem'}
      >
        <Row
          gap={'0.5rem'}
          alignItems={'flex-start'}
          justifyContent={'flex-start'}
        >
          <Text
            fontSize={minor ? '20px' : '40px'}
            fontWeight={700}
            width={'fit-content'}
          >
            {percent}%
          </Text>
          {icon && (
            <Tooltip text={toolTipStatusText} width={tooltipWidth}>
              <StatusIconWrapper size={minor ? '25px' : '36px'} status={status}>
                {statusIcon}
              </StatusIconWrapper>
            </Tooltip>
          )}
        </Row>
        <Text fontSize={minor ? '16px' : '18px'}>
          {studentAmountStyled} estudantes
        </Text>
        <Text fontSize={minor ? '15px' : '16px'}>
          <b>Média:</b> {mediaPercent}%
        </Text>
      </GrayBox>
    </Container>
  );
}
