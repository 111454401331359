import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
  position: fixed;
  top: 0;
  left: 0;
  ${(props) => (props.visible ? `display: flex;` : `display: none;`)}
  align-items: center;
  justify-content: center;
  z-index: 1300;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  margin-left: auto;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  @media (max-width: 959px) {
    min-width: 100%;
  }
`;

export const ContainerIcon = styled.div`
  width: 47px;
  height: 47px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  background-color: #ffff;
  width: fit-content;
  height: fit-content;
  max-width: 564px;
  max-height: calc(100vh - 10px);
  overflow-y: auto;
  padding: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  @media (max-width: 768px) {
    min-width: calc(100vw - 28px);
  }
`;

export const WrapperTitle = styled.div`
  display: flex;

  align-items: center;
  height: 20%;
  width: 100%;
`;

export const BubbleDialog = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  margin-left: 1.5em;
  border-radius: 4px;
  max-width: 180px;
  min-height: 49px;
  position: relative;
  padding: 0px 20px;

  &:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4.5px solid transparent;
    border-right: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-top: 4.5px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    border-bottom: 4.5px solid transparent;
    border-radius: 1px;
    left: -4px;
    top: 30px;
    transform: rotate(-135deg);
  }
`;

export const IconPersonStyled = styled.img`
  background-color: #ffff;
  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  width: 95px;
  height: 95px;
  border-radius: 50%;
`;

export const H4Styled = styled.h4`
  margin-top: 13px;
  font-weight: bold;
  color: #ffff;
`;

export const ParagraphStyled = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 17px;
  text-align: start;
  font-weight: 400;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  gap: 18.14px;
  width: 100%;
  height: fit-content;
  left: 29px;
  top: 211.74px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-right: auto;
  }
`;

export const IconCards = styled.img`
  width: 50px;
  height: 50px;
`;

export const CardItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  span {
    font-size: 12px;
  }

  i {
    width: 48px;
    height: 48px;
    border-radius: 3px;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CardText = styled.p`
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
`;
export const MissingCourseAlert = styled.p`
  margin: 15px 0 0 0;
  color: red;
`;

export const CloseButton = styled.button`
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 10px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #4b4b4d;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
