import React, { Fragment, useContext, useEffect } from 'react';

import {
  getArticles,
  getChallenges,
  getCourses,
  getEbooks,
  getEvents,
  getPrograms,
  getSeries,
  getTrails,
  getAnnouncements,
} from '../../../../../services/company/events';

import { OpportunitiesCompanyContext } from '../../context/OpportunitiesCompanyContext';
import PublishedOpportunitiesCompany from '../../components/PublishedOpportunitiesCompany';
export default function OpportunityList({
  handleIsLoadingState,
  setActiveTab,
  activeTab,
}) {
  const {
    setEvents,
    setCourses,
    setPrograms,
    setCommunicate,
    setTrails,
    setEbooks,
    setArticle,
    setChallenge,
    setSerie,
    setFormData,
    setSelectOption,
    setOptionsId,
    setStep,
    setModalIsOpen,
    setModalContent,
  } = useContext(OpportunitiesCompanyContext);

  useEffect(() => {
    setModalIsOpen(false);
    setModalContent();
    setFormData({});
    setSelectOption('');
    setOptionsId('');
    setStep(0);
  }, [activeTab]);

  useEffect(() => {
    handleIsLoadingState(true);

    const getAllOpportunities = async () => {
      const events = await getEvents(setEvents);
      setEvents(events);
      handleIsLoadingState(false);

      const courses = await getCourses(setCourses);
      setCourses(courses);

      const program = await getPrograms(setPrograms);
      setPrograms(program);

      const trails = await getTrails(setTrails);
      setTrails(trails);

      const articles = await getArticles(setArticle);
      setArticle(articles);

      const series = await getSeries(setSerie);
      setSerie(series);

      const ebooks = await getEbooks(setEbooks);
      setEbooks(ebooks);

      const challenges = await getChallenges(setChallenge);
      setChallenge(challenges);

      const announcements = await getAnnouncements(setCommunicate);
      setCommunicate(announcements);
    };
    getAllOpportunities();
  }, []);

  return (
    <Fragment>
      <PublishedOpportunitiesCompany setActiveTab={setActiveTab} />
    </Fragment>
  );
}
