import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';
import {
  Content,
  Container,
  ProfileHeader,
  ProfileContent,
  HighlightsArea,
  TitleArea,
  ContentArea,
  ListingBalloons,
  ViewInformation,
  ContainerButton,
  ButtonX,
  Box,
  FileButton,
} from './style';
import { useState, useRef } from 'react';
import ViewStageDataModal from '../ViewStageDataModal';

export default function CompanyProfileModal({
  isOpen,
  details,
  setOpenModalConnectedCompanies,
  setOpenModalViewStageData,
  openModalViewStageData,
  openModalUpload,
  selectedCompanyId,
}) {
  const {
    ImLocation,
    FlagIcon,
    AiOutlineHeart,
    AiOutlineUnorderedList,
    IoClose,
    MdOutlineImageSearch,
    BsFolder2Open,
  } = useIcons();

  const CompanyProfileRef = useRef();

  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);

  const closeModalX = () => {
    setOpenModalConnectedCompanies(false);
  };

  return (
    <Container usingPreviewPdf={usingPreviewPdf} isOpen={isOpen}>
      <Box ref={CompanyProfileRef}>
        <Content paddingBottom={details.documents?.length > 0}>
          <ProfileHeader>
            {details?.logo !== null ? (
              <div className="company-logo">
                <img src={details?.logo} />
              </div>
            ) : (
              <div className="company-logo">
                <MdOutlineImageSearch color="#949494" size={30} />
              </div>
            )}
            <strong> {details?.legal_name} </strong>
            {details?.organization_number && (
              <div className="wrapper-text">
                <span> CNPJ: </span> {details?.organization_number}
              </div>
            )}
            {details.type && (
              <div className="wrapper-text">
                <span>Tipo: </span> {details.type}
              </div>
            )}
          </ProfileHeader>
          <ButtonX onClick={closeModalX}>
            <IoClose />
          </ButtonX>
          <ProfileContent>
            {details.address.city !== '' && details.address.state !== '' && (
              <div className="adress">
                <ImLocation color="#009291" size={14} />
                <p style={{ marginLeft: '-2px' }}>
                  {details.address &&
                    details.address.address &&
                    `${details.address.address},`}{' '}
                  {details.address &&
                    details.address.number &&
                    `${details.address.number},`}{' '}
                  {details.address &&
                    details.address.complement &&
                    `${details.address.complement},`}{' '}
                  {details.address &&
                    details.address.city &&
                    `${details.address.city},`}{' '}
                  {details.address &&
                    details.address.state &&
                    `${details.address.state},`}{' '}
                  {details.address &&
                    details.address.zip_code &&
                    `${details.address.zip_code}`}
                </p>
              </div>
            )}
            {details.description && <span>{details.description}</span>}
          </ProfileContent>
          {details.challenges && (
            <HighlightsArea>
              <TitleArea>
                <div className="icon">
                  <FlagIcon color="#FFFF" />
                </div>
                <span> Desafios profissionais da empresa </span>
              </TitleArea>
              <ContentArea>
                <span>{details.challenges}</span>
              </ContentArea>
            </HighlightsArea>
          )}
          {details.values?.length > 0 && (
            <HighlightsArea>
              <TitleArea>
                <div className="icon">
                  <AiOutlineHeart size={18} color="#FFFF" />
                </div>
                <span> Principais valores </span>
              </TitleArea>
              <ContentArea>
                <div className="balloons-box">
                  {details.values?.map((data) => (
                    <ListingBalloons> {data} </ListingBalloons>
                  ))}
                </div>
              </ContentArea>
            </HighlightsArea>
          )}
          {details.skills?.length > 0 && (
            <HighlightsArea>
              <TitleArea>
                <div className="icon">
                  <AiOutlineUnorderedList size={18} color="#FFFF" />
                </div>
                <span>
                  {' '}
                  Principais competências comportamentais dos profissionais{' '}
                </span>
              </TitleArea>
              <ContentArea>
                <div className="balloons-box">
                  {details.skills?.map((data) => (
                    <ListingBalloons> {data} </ListingBalloons>
                  ))}
                </div>
              </ContentArea>
            </HighlightsArea>
          )}
        </Content>
      </Box>
    </Container>
  );
}
