import styled from 'styled-components';
import CheckboxIcon from 'assets/JobOffer/CheckboxCheck.svg';

export const Container = styled.div`
  padding: 1rem 3rem 3rem;

  input[type='checkbox'] {
    height: 14px;
    width: 14px;
    border: 1px solid #dcdcdc;
    background-color: #fff;
    vertical-align: middle;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    flex-shrink: 0;
    margin: 3px 0 0 0;

    &:checked {
      border: none;
      background-color: #009291;
      background-image: url(${CheckboxIcon});
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  > header {
    > span {
      display: flex;
      gap: 0.5rem;

      > img {
        cursor: pointer;
      }

      > h3 {
        font-size: 18px;
        font-weight: bold;
        color: #606062;
        margin: 0;
      }

      > p {
        max-width: 695px;
        margin: 1rem 0 0 0 !important;
        font-size: 14px;
      }
    }
  }

  .descriptionStepThree {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 650px;

    > textarea {
      width: 650px;
      height: 150px;
      resize: none;
      border: 1px solid #c4c4c4;
      border-bottom: none;
      border-radius: 4px 4px 0 0;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      margin: 1rem 0;
      padding: 1rem;

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }

      ::-webkit-scrollbar-thumb {
        background: #aaaaaa;
        border-radius: 3px;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #999999;
      }
    }

    > span {
      width: 650px;
      border: 1px solid #c4c4c4;
      position: absolute;
      bottom: -18px;
      right: 0;
      padding: 0.5rem;
      font-size: 12px;
      color: #949494;
      background-color: #fff;
      border-radius: 0 0 4px 4px;
      border-top: none;
      text-align: right;
      margin: 0 0 -4px 0;
      left: 0;
    }

    > p {
      margin-bottom: -3.4em;
      font-size: 14px;
    }
  }
`;

export const WrapperProfessions = styled.div`
  display: flex;
  gap: 5rem;
  padding: 20px 0;

  &:not(:nth-last-child(2)) {
    border-bottom: 1px solid #eef2f4;
  }

  &:nth-last-child(2) {
    margin-bottom: 2rem;
  }

  .firstPair,
  .secondPair {
    display: flex;
    flex: 1;
    max-width: calc(50% - 3rem);
  }

  .firstPair,
  .secondPair {
    > input,
    > label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .firstPair {
    display: flex;
    gap: 10px;
    width: 310px;

    > input {
      &:checked {
        color: red;
      }
    }
  }

  .secondPair {
    display: flex;
    gap: 10px;
  }
`;

export const ContainerSearch = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  span {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1.5rem;
    align-items: center;

    > p {
      max-width: 500px;
    }
  }
`;

export const ProfessionsHeader = styled.header`
  display: flex;
  flex-direction: row;
  gap: 10px;

  > p {
    width: 100%;
    font-weight: bold;
    color: #b8b8b8;
  }
`;

export const ContainerSkeleton = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eef2f4;
  padding: 30px 0;
  gap: 5rem;

  > .checkboxContainer {
    display: flex;
    gap: 10px;
    flex: 1;
  }
`;
