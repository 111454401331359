import React, { useContext } from 'react';
import IcoHelpWhite from 'assets/img/icons/icoHelpWhite.svg';
import withStyles from '@material-ui/core/styles/withStyles';
import { IconButton, Fragment } from './stylesButton';
import { IconHelp } from 'assets/img/iconsCurriculum/iconHelp';

import { func, object, string } from 'prop-types';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import BaseLayoutContext from 'contexts/base-layout';

const HelpButtonStudent = (props) => {
  const { classes, openModalWithContent, HelpModalContent, helpModalTitle } =
    props;

  const { universityColor } = useContext(BaseLayoutContext);

  let propsCopy = Object.assign({}, props);
  delete propsCopy.classes;
  return (
    <Fragment>
      <IconButton
        footerText={props.footerText}
        className={classes.buttonHelpIcon}
        universityColor={universityColor}
        onClick={() =>
          openModalWithContent(
            <HelpModalContent {...propsCopy} />,
            helpModalTitle
          )
        }
      >
        <IconHelp color={universityColor} alt="Ajuda" />O que é?
      </IconButton>
    </Fragment>
  );
};

HelpButtonStudent.propTypes = {
  classes: object.isRequired,
  openModalWithContent: func,
  helpContent: object,
  helpTitle: string,
};

export default withStyles(dashboardStyle)(HelpButtonStudent);
