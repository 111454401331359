import { Fragment, useEffect, useState } from 'react';
import { useSendEmail } from 'views/StaffPages/Csm/contexts/SendEmailContext';
import sendEmailApi from 'views/StaffPages/Csm/services/sendEmailApi';
import HeaderForm from '../../HeaderForm';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

export default function EngineStepsCampaign() {
  const { step, egressed } = useSendEmail();
  const [options, setOptions] = useState({});

  const getOptions = async () => {
    const response = await sendEmailApi.getOptionsCampaign(egressed);
    setOptions(response);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const steps = {
    1: <StepOne />,
    2: <StepTwo options={options} />,
  };

  const currentStep = steps[step];

  return (
    <Fragment>
      <HeaderForm
        title={'Criar campanha'}
        subTitle={
          egressed ? (
            <>
              Selecione as opções de sua preferência para gerar campanha de{' '}
              <strong>Egresso</strong>.
            </>
          ) : (
            <>
              Selecione as opções de sua preferência para gerar a campanha de
              estudantes <strong>Matriculados</strong>.
            </>
          )
        }
      />
      {currentStep}
    </Fragment>
  );
}
