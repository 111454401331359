export function LetterIcon({ color = '#009291' }) {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 0.833328H4.16667C3.062 0.834651 2.00296 1.27406 1.22185 2.05518C0.440735 2.83629 0.00132321 3.89533 0 5L0 15C0.00132321 16.1047 0.440735 17.1637 1.22185 17.9448C2.00296 18.7259 3.062 19.1653 4.16667 19.1667H15.8333C16.938 19.1653 17.997 18.7259 18.7782 17.9448C19.5593 17.1637 19.9987 16.1047 20 15V5C19.9987 3.89533 19.5593 2.83629 18.7782 2.05518C17.997 1.27406 16.938 0.834651 15.8333 0.833328ZM4.16667 2.49999H15.8333C16.3323 2.50098 16.8196 2.65126 17.2325 2.9315C17.6453 3.21174 17.9649 3.60912 18.15 4.0725L11.7683 10.455C11.2987 10.9228 10.6628 11.1854 10 11.1854C9.33715 11.1854 8.70131 10.9228 8.23167 10.455L1.85 4.0725C2.03512 3.60912 2.35468 3.21174 2.76754 2.9315C3.1804 2.65126 3.66768 2.50098 4.16667 2.49999ZM15.8333 17.5H4.16667C3.50363 17.5 2.86774 17.2366 2.3989 16.7678C1.93006 16.2989 1.66667 15.663 1.66667 15V6.25L7.05333 11.6333C7.83552 12.4135 8.89521 12.8517 10 12.8517C11.1048 12.8517 12.1645 12.4135 12.9467 11.6333L18.3333 6.25V15C18.3333 15.663 18.0699 16.2989 17.6011 16.7678C17.1323 17.2366 16.4964 17.5 15.8333 17.5Z"
        fill={color}
      />
    </svg>
  );
}
