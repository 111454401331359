import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: block;

  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  overflow-y: auto;
  z-index: 2000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => (props.margin ? `margin-top: ${props.margin}px` : '')};

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  background-color: #fff;
  width: 900px;
  border-radius: 4px;
`;

export const HeaderGradient = styled.div`
  background: linear-gradient(${(props) => props.color}, #fff);
  height: 120px;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  position: relative;
`;

export const Logo = styled.img``;

export const ContainerHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 10px 0px;

  > h3 {
    color: #606062;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

export const ContainerInformations = styled.div`
  background-color: #f8fafc;
  border-radius: 4px;
  display: flex;
  gap: 20px;
  padding: 5px 10px;

  > span {
    display: flex;
    align-items: center;
    gap: 10px;

    > p {
      margin: 0;
      font-weight: 400;
      color: #606062;
    }

    > div {
      background-color: #00929126;
      padding: 4px;
      border-radius: 4px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;

      > img {
        width: 13px;
      }
    }
  }
`;

export const ContainerImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -70px;

  > img {
    width: 130px;
    height: 130px;
    object-fit: contain;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    background-color: #fff;
    z-index: 1;
    padding: 0px 5px;
  }
`;

export const Descriptions = styled.div`
  background-color: #f8fafc;
  padding: 10px 40px;
  border-radius: 4px;
  word-break: break-word;

  > h2 {
    font-size: 1.5em;
    color: #606062;
    font-weight: bold;
    margin: 0;
  }
`;

export const ContainerDescriptions = styled.div`
  min-height: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 41px 30px;

  > iframe {
    border: none;
  }
`;

// Cursos ofertados

export const Container2 = styled.div`
  padding: 46px 40px;
`;

export const ContainerCourse = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

export const Course = styled.div`
  border: 1px solid #c4c4c4;
  background-color: #f6f6f6;
  padding: 10px 14px;
  border-radius: 4px;
  width: 200px;
`;

export const ContainerHeaderAndCourses = styled.div`
  color: #606062;
  font-weight: bold;

  > span {
    color: #009291;
    font-weight: bold;
    cursor: pointer;
  }
`;

const loadingAnimation = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const SkeletonBase = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${loadingAnimation} 1.5s infinite;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const SkeletonCard = styled(SkeletonBase)`
  height: 500px;
  width: 900px;
  position: relative;
`;

export const SkeletonImage = styled(SkeletonBase)`
  height: 130px;
  width: 130px;
  position: absolute;
  border-radius: 50%;
  left: 42%;

  top: 41px;
`;

export const SkeletonTitle = styled(SkeletonBase)`
  width: 200px;
  position: absolute;
  left: 38%;
  top: 200px;
`;

export const ContainerStars = styled.div`
  width: 230px;
  background-color: #eef2f4;
  height: 40px;

  display: flex;
  align-items: center;
  color: #606062;
  font-weight: bold;
  padding: 0px 20px;
`;

export const Star = styled.span`
  margin-left: 10px;
  color: ${(props) => (props.active ? '#F2CB1D' : '#C4C4C4')};
`;
