import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 747px;

  height: 100%;

  border-radius: 10px;

  background-color: #fff;
  padding: 50px 60px;

  .state-city {
    display: flex;
    margin-top: 10px;

    gap: 20px;
  }

  .fields-container {
    display: flex;
    gap: 2px;
  }
  position: relative;
`;

export const Title = styled.p`
  font-size: 20px;
  color: #009291;

  font-weight: 700;

  margin-bottom: 10px;
`;

export const SubTitle = styled.p`
  font-size: 16px;
  color: #606062;

  font-weight: 400;

  margin-bottom: 34px;
`;
