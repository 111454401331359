import FilterModal from 'components/FilterModal';
import React, { useState } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, ContainerFlexDirection, SpanError } from '../styled';
import { shape } from 'prop-types';
import * as Yup from 'yup';

export function FilterCourseDashboard({
  handleQueryParams,
  appliedFilters,
  setAppliedFilters,
  insightOptions,
  filterState,
  setFilterState,
  dashboard = false,
}) {
  const [errorDate, setErrorDate] = useState(false);

  const validateData = async (data) => {
    try {
      const schema = Yup.object().shape({
        start_date: Yup.date(),
        end_date: Yup.date().min(
          Yup.ref('start_date'),
          `A data final precisa ser maior do que a data inicial.`
        ),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      return true;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        setErrorDate(true);
      }
      return false;
    }
  };

  function renderFilterContent() {
    return [
      <FilterGrid>
        {!dashboard && (
          <Filter
            name={'academic_courses'}
            label={'Filtrar por curso'}
            type={FilterType.MULTI_SELECT}
            options={insightOptions?.academicCourse}
          />
        )}
        <Filter
          name={['start_date', 'end_date']}
          label={
            dashboard
              ? 'Filtrar informações por data:'
              : 'Período de publicação'
          }
          type={FilterType.DATE_RANGE}
          settings={{
            placeholder: ['De', 'Até'],
            onChange: () => {
              setErrorDate(false);
            },
          }}
          startYear={2019}
        />
        {errorDate && (
          <SpanError>
            A data final precisa ser maior do que a data inicial.
          </SpanError>
        )}
        {insightOptions?.unities?.length > 0 && !dashboard ? (
          <Filter
            name={'unity'}
            label={'Unidade'}
            type={FilterType.MULTI_SELECT}
            options={insightOptions.unities}
          />
        ) : null}
      </FilterGrid>,
    ];
  }

  async function onApplyFilters(filters) {
    const isValid = await validateData(filters);
    if (isValid === false) {
      return false;
    } else {
      handleQueryParams(filters);
    }
  }

  function onClearFilters() {
    handleQueryParams({});
    setErrorDate(false);
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}

FilterCourseDashboard.propTypes = {
  insightOptions: shape.isRequired,
};
