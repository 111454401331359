import styled from 'styled-components';
import { linkColor } from 'assets/jss/material-dashboard-pro-react';

export const UploadButton = styled.button`
  border: none;
  font-size: 1.2rem;
  color: ${(props) =>
    props.universityColor ? props.universityColor : linkColor};
  background: transparent;
  cursor: pointer;
  svg {
    height: 32px;
    width: 32px;
    margin-right: 0.5rem;
    color: #4f4f4f;
  }
  span {
    display: inline-block;
    transform: translateY(-35%);
    text-decoration: underline;
  }
`;

export const ParagraphButton = styled.button`
  margin-top: 1rem;
  border: none;
  display: block;
  background: transparent;
  cursor: ${(props) => (props.pointer ? 'pointer' : 'default')};
`;
