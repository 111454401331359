export default function CalendarIcon ({color = '#009291', size = 29}) {
  return (
    <svg width={size} height={size} viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M28.3612 7.8845C28.3469 5.05384 26.0452 2.50911 22.8143 2.52341C22.514 2.52341 22.2138 2.52341 21.8707 2.52341C21.8707 2.1803 21.8707 1.86579 21.8707 1.55127C21.8707 0.865049 21.3989 0.364679 20.7699 0.364679C20.1409 0.364679 19.6834 0.865046 19.6834 1.56556C19.6834 1.88008 19.6834 2.1803 19.6834 2.50911H8.90402C8.90402 2.1803 8.90402 1.86579 8.90402 1.53697C8.90402 0.850752 8.41795 0.350382 7.78892 0.364679C7.15988 0.378975 6.7167 0.865049 6.7024 1.53697C6.7024 1.85149 6.7024 2.16601 6.7024 2.53771C6.20203 2.53771 5.71596 2.50912 5.24419 2.53771C2.68516 2.70926 0.597911 4.68214 0.254801 7.22687C0.254801 7.28406 0.22621 7.32695 0.211914 7.38413V23.5961C0.254803 23.8105 0.297691 24.0249 0.340579 24.2394C0.855244 26.4982 2.69946 28.1566 5.00115 28.4425C5.05834 28.4425 5.10122 28.4711 5.15841 28.4854H23.4147C23.672 28.4282 23.9294 28.3853 24.1867 28.3281C26.5885 27.7706 28.3469 25.5975 28.3612 23.1386C28.3755 18.0777 28.3755 12.974 28.3612 7.8845ZM26.1596 11.5586C26.1596 15.3471 26.1596 19.1356 26.1596 22.9384C26.1596 24.9113 24.7728 26.3123 22.7857 26.3123C17.1101 26.3123 11.4488 26.3123 5.77315 26.3123C3.80027 26.3123 2.39924 24.9113 2.39924 22.9384C2.39924 19.1499 2.39924 15.3614 2.39924 11.5586V11.2298H26.1453V11.5586H26.1596ZM26.1453 8.9996H2.44212C2.05612 6.09747 3.91464 4.38192 6.7024 4.76792C6.7024 5.46844 6.7024 6.16895 6.7024 6.86947C6.7167 7.56998 7.30285 8.05605 7.98906 7.95598C8.50373 7.8702 8.88973 7.42702 8.88973 6.86947C8.90402 6.26902 8.88973 5.65429 8.88973 5.05385V4.73933H19.6548C19.6548 4.82511 19.6691 4.92518 19.6691 5.01096C19.6691 5.6114 19.6691 6.22614 19.6691 6.82658C19.6691 7.4985 20.1409 7.97028 20.7842 7.97028C21.4132 7.97028 21.8707 7.48421 21.8707 6.84088C21.8707 6.15466 21.8707 5.46843 21.8707 4.78221C24.2725 4.39622 26.517 5.74006 26.1453 8.9996Z" fill={color}/>
      <path d="M9.97656 22.0091C9.97656 22.5953 9.51908 23.0956 8.90435 23.1099C8.16094 23.1242 7.41754 23.1242 6.68843 23.1099C6.08799 23.0956 5.61621 22.5953 5.61621 22.0091C5.61621 21.423 6.08799 20.9369 6.70273 20.9226C7.07443 20.9083 7.44613 20.9226 7.83213 20.9226C8.18953 20.9226 8.54694 20.9083 8.90435 20.9226C9.50479 20.9369 9.97656 21.4087 9.97656 22.0091Z" fill={color}/>
      <path d="M22.9577 22.0234C22.9577 22.6096 22.4859 23.0956 21.8711 23.1099C21.142 23.1242 20.4129 23.1242 19.6695 23.1099C19.0548 23.0956 18.5973 22.6096 18.583 22.0234C18.583 21.423 19.0548 20.9369 19.6838 20.9226C20.0555 20.9083 20.4129 20.9226 20.7846 20.9226C21.142 20.9226 21.4994 20.9226 21.8568 20.9226C22.4859 20.9369 22.9719 21.423 22.9577 22.0234Z" fill={color}/>
      <path d="M22.957 15.5189C22.9713 16.1193 22.4853 16.6197 21.8705 16.634C21.1414 16.6483 20.4266 16.6483 19.6975 16.634C19.0685 16.6197 18.5967 16.1336 18.5967 15.5332C18.5967 14.9327 19.0828 14.4467 19.6975 14.4324C20.0692 14.4324 20.4266 14.4324 20.7983 14.4324C21.1557 14.4324 21.5131 14.4324 21.8705 14.4324C22.471 14.461 22.957 14.9327 22.957 15.5189Z" fill={color}/>
      <path d="M9.97687 15.5331C9.97687 16.1335 9.50509 16.6196 8.87606 16.6339C8.51865 16.6482 8.16125 16.6339 7.80384 16.6339C7.44643 16.6339 7.08903 16.6339 6.73162 16.6339C6.08829 16.6196 5.60222 16.1335 5.61652 15.5188C5.63081 14.9183 6.11688 14.4466 6.73162 14.4323C7.44643 14.4323 8.16125 14.418 8.87606 14.4323C9.50509 14.4466 9.97687 14.9326 9.97687 15.5331Z" fill={color}/>
      <path d="M16.4663 15.5474C16.4663 16.1478 15.9945 16.6339 15.3941 16.6339C14.665 16.6482 13.9359 16.6482 13.1925 16.6339C12.592 16.6196 12.106 16.1335 12.106 15.5474C12.106 14.9469 12.5777 14.4608 13.2068 14.4465C13.5785 14.4322 13.9359 14.4465 14.3076 14.4465C14.665 14.4465 15.0224 14.4465 15.3798 14.4465C15.9945 14.4465 16.4663 14.9326 16.4663 15.5474Z" fill={color}/>
      <path d="M16.4665 22.0091C16.4665 22.6096 15.9947 23.0956 15.3657 23.1099C15.237 23.1099 15.1083 23.1099 14.9797 23.1099C14.8367 23.1099 14.6794 23.1099 14.5365 23.1099C14.4507 23.1099 14.3649 23.1099 14.2791 23.1099C14.1791 23.1099 14.0647 23.1099 13.9646 23.1099C13.7216 23.1099 13.4786 23.1099 13.2355 23.1099C12.6065 23.0956 12.1061 22.6096 12.1204 21.9948C12.1347 21.3944 12.6065 20.9226 13.2355 20.9083C13.9503 20.894 14.6651 20.894 15.38 20.9083C15.9804 20.9369 16.4522 21.4087 16.4665 22.0091Z" fill={color}/>
    </svg>
  )
}