import styled from 'styled-components';

export const FormContainer = styled.div`
  .header {
    margin-bottom: 36px;
    h3 {
      margin: 0px;
      font-weight: 700;
      font-size: 1.5rem;
      color: #009291;
    }
    p {
      font-size: 1.125rem;
      margin-top: 4px;
    }
  }
  .buttonRight {
    float: right;
    text-transform: none;
    padding: 10px 20px;
  }
`;

export const ContainerInput = styled.div`
  margin-bottom: 18px;
  label {
    font-size: 1.125rem;
    font-weight: 400;
    color: #606062;
  }
  & > div {
    margin-top: 4px;
  }
`;
