export default function Trash({ color, size }) {
  return (
    <svg
      width={size}
      height={size + 1}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75 2.48585H9V3.51442H8.1V10.2001C8.1 10.3365 8.05259 10.4673 7.9682 10.5638C7.88381 10.6602 7.76935 10.7144 7.65 10.7144H1.35C1.23065 10.7144 1.11619 10.6602 1.0318 10.5638C0.947411 10.4673 0.9 10.3365 0.9 10.2001V3.51442H0V2.48585H2.25V0.942997C2.25 0.8066 2.29741 0.675789 2.3818 0.579342C2.46619 0.482894 2.58065 0.428711 2.7 0.428711H6.3C6.41935 0.428711 6.53381 0.482894 6.6182 0.579342C6.70259 0.675789 6.75 0.8066 6.75 0.942997V2.48585ZM7.2 3.51442H1.8V9.68585H7.2V3.51442ZM3.15 1.45728V2.48585H5.85V1.45728H3.15Z"
        fill={color}
      />
    </svg>
  );
}
