import React, { useRef, useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';
import { UnSelectLabel, ContainerLabelSelect } from './Style/BaseInputs';

export default function SimpleReactSelect({
  label,
  name,
  options,
  placeholder,
  default: haveDefault,
  marginTop,
  maxWidth,
  orderLabelSelect,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return Array.isArray(ref.state.value)
            ? ref.state.value.map((option) => option.value)
            : [ref.state.value].map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value.label;
        }
      },
      setValue: (ref, value) => {
        const currentOptions = ref.props.options;
        if (Array.isArray(value) && currentOptions.length > 0) {
          const newArray = value.map((value) =>
            currentOptions.find((option) => option.value === value)
          );
          return ref.onChange(newArray);
        }
        if (!Array.isArray(value)) {
          const valueOption = currentOptions.find(
            (option) => option.label === value || option.value === value
          );
          return ref.onChange(valueOption);
        }
        ref.onChange(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ContainerLabelSelect maxWidth={maxWidth} marginTop={marginTop}>
      {label && <UnSelectLabel>{label}</UnSelectLabel>}
      <Select
        defaultValue={
          defaultValue && Array.isArray(defaultValue)
            ? defaultValue.map(
                (item) =>
                  options &&
                  options.find(
                    (option) =>
                      option.value === item || option.value === item.value
                  )
              )
            : options &&
              options.find(
                (option) =>
                  String(option.value) === String(defaultValue) ||
                  (defaultValue &&
                    String(option.value) === String(defaultValue.value))
              )
        }
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        classNamePrefix="react-select"
        {...rest}
      />
      <p style={{ color: '#f00', marginTop: '10px' }}>{error}</p>
    </ContainerLabelSelect>
  );
}
