import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Container = styled.div`
  max-width: 300px;
  padding-right: 6px;
  padding-bottom: 6px;
  padding: 12px;
  border-radius: 8px;
`;
const LargeContainer = styled.div`
  max-width: 500px;
  padding-right: 6px;
  padding-bottom: 6px;
  padding: 12px;
  border-radius: 8px;
`;

export function AcademicDescription() {
  return (
    <Container>
      <p>
        O indicador acadêmico considera as formações acadêmicas mais exigidas
        pelo mercado de trabalho e o andamento do seu curso para a atuação
        apresentada.
      </p>
    </Container>
  );
}
export function BehavialDescription() {
  return (
    <Container>
      <p>
        O indicador comportamental considera o nível de alinhamento entre seu
        perfil comportamental (resultado do teste comportamental no Currículo do
        Futuro) e o perfil exigido para a atuação apresentada.
      </p>
    </Container>
  );
}
export function ProfessionalDescription() {
  return (
    <LargeContainer>
      <p>
        O indicador profissional considera as suas experiências profissionais,
        disciplinas concluídas e cursos extracurriculares associados às áreas
        correlacionadas ao seu curso atual e que são exigidas pelo mercado de
        trabalho para a atuação apresentada
      </p>
      <p>
        Os requisitos de mercado são os conhecimentos mais recorrentes exigidos
        pelo mercado de trabalho nas vagas semelhantes para a atuação
        apresentada.
      </p>
    </LargeContainer>
  );
}

export function MatchPercentageDescription() {
  return (
    <LargeContainer>
      <Typography>
        Dicas para melhorar o seu Potencial de Empregabilidade
      </Typography>
      <ul>
        <li>
          É super importante manter o Currículo do Futuro atualizado para que
          você possa acompanhar a evolução do seu potencial de empregabilidade
          para as atuações profissionais desejadas.
        </li>
        <li>
          Identifique oportunidades de trabalho relacionadas as áreas
          relacionadas às atuações de seu interesse.
        </li>
        <li>
          Procure fazer cursos extracurriculares alinhados aos seus interesses e
          áreas de atuação.
        </li>
        <li>
          Faça no mínimo 1 curso a cada 6 meses que seja relevante para sua
          formação
        </li>
        <li>
          Não esqueça de adicionar sua participação nos eventos profissionais,
          horas complementares, palestras, como cursos adicionais.
        </li>
      </ul>
    </LargeContainer>
  );
}
