import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const GridWrapper = styled(Grid)`
  height: 100vh;
`;

export const ItemContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-size: 2em;
`;

export const Link = styled.strong`
  cursor: pointer;
  color: #009291;
`;

export const Error = styled.h1`
  color: #ff0000;
`;

export const Success = styled.h1`
  color: #1cb45e;
`;
