import React, {
  useRef,
  useEffect,
  useState,
  useContext,
  Fragment,
} from 'react';
import Select from 'react-select';
import { useField } from '@unform/core';
import { ContainerLabelInput, ErrorWrapper } from './style';
import { UnInputLabel } from './style';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default function SearchableSelectUn({
  label,
  name,
  options,
  placeholder,
  default: haveDefault,
  ...rest
}) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);
  const selectStyles = {
    control: (base) => ({
      ...base,
      borderColor: error ? '#FFCECE' : '#ced4da',
      backgroundColor: error ? '#FFEDED' : '#fff',
    }),
  };
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return Array.isArray(ref.state.value)
            ? ref.state.value.map((option) => option.value)
            : [ref.state.value].map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return '';
          }
          return ref.state.value.value;
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel>{label}</UnInputLabel>}
      <Select
        defaultValue={
          defaultValue && Array.isArray(defaultValue)
            ? defaultValue.map(
                (item) =>
                  options &&
                  options.find(
                    (option) =>
                      option.value === item || option.value === item.value
                  )
              )
            : options &&
              options.find(
                (option) =>
                  String(option.value) === String(defaultValue) ||
                  (defaultValue &&
                    String(option.value) === String(defaultValue.value))
              )
        }
        placeholder={placeholder ? placeholder : 'Selecione'}
        options={options}
        ref={selectRef}
        onFocus={clearError}
        classNamePrefix="react-select"
        styles={selectStyles}
        {...rest}
      />
      <ErrorWrapper style={{ marginTop: '5px' }}>
        {error && (
          <Fragment>
            <AiOutlineCloseCircle size={18} color={'#f10909'} />
            {error}
          </Fragment>
        )}
      </ErrorWrapper>
    </ContainerLabelInput>
  );
}
