import styled from 'styled-components';

export const Input = styled.input`
  width: 370px;
  border-radius: 6px;
  border: 1px solid #c9c5c5;
  padding: 16px 10px;

  ::-webkit-input-placeholder {
    font-size: 14px;
    font-family: Roboto;
  }
  input:-moz-placeholder {
    color: #4c4;
  }

  :focus,
  :not(:placeholder-shown) {
    border: 1px solid #8b8b8b;
  }

  ::-ms-reveal,
  ::-ms-clear {
    display: none !important;
  }

  @media only screen and (max-width: 850px) {
    width: 90vw;
    max-width: 482px;
  }

  position: relative;
`;

export const Label = styled.label`
  margin: 0;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: ${(props) => (props.universityColor ? '16px' : '13px')};
  font-weight: 400;
`;

export const ContainerLabel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`;

export const ButtonEye = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: none;
  background-color: transparent;
  width: fit-content;
  padding: 0;
  cursor: pointer;
`;
