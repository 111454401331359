import styled from 'styled-components';
import Input from 'components/UnformFields/inputField';
import error from 'assets/Matriz/error.svg';

export const SpecialitiesInput = styled(Input)`
  width: 100%;
  height: 38px;
  margin: 0;

  &.error {
    border: 2px solid #ffd8d8;
    background-color: #fff5f5;
  }

  &:focus:not(.error) {
    border: 2px solid #009291;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: ${(props) => (props.gap ? props.gap : '1rem')};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};

  ${(props) =>
    props.justifyContent ? `justify-content: ${props.justifyContent};` : ''}
  > button {
    margin-top: 8px;
  }

  > span {
    margin-left: auto;
  }

  > b {
    margin-bottom: 9px;
  }

  > p.error {
    display: flex;
    margin: 0;
    gap: 12px;

    &:before {
      content: url(${error});
      width: 10px;
      height: 15px;
      margin: auto 0;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  > span {
    margin-left: auto;
    color: #949494;
    font-size: 14px;
  }
`;

export const SpecialitiesWrapper = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  gap: 1rem;
  width: 100%;
  margin-top: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

export const SpecialityChipButton = styled.button`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  height: 28px;
  border-radius: 2px;
  background-color: #eef2f4;
  padding: 0 0.5rem;
  border: none;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  color: #606062;
  cursor: pointer;
`;

export const Tooltip = styled.div`
  position: relative;

  &:hover {
    &:before {
      display: block;
    }

    &:after {
      display: block;
    }
  }

  &:before {
    background-color: #000000bf;
    top: ${({ isTwoLines, isThreeLines }) =>
      isThreeLines ? '-71px' : isTwoLines ? '-50px' : '-38px'};
    right: unset;
    left: calc(50% - 16px);
    content: '${(props) => props.label}';
    width: ${({ width }) => (width ? width : '56px')};
    position: absolute;
    padding: 5px 7px;
    text-align: center;
    color: #ffff;
    border-radius: 4px;
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    z-index: 1;
    border-style: solid;
    bottom: 28px;
    margin-left: -10px;
    display: none;
    border-color: #000000bf transparent;
    border-width: 7px 7px 0;
    left: 50%;
  }
`;

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  > svg {
    margin-bottom: 4px;
  }
`;
