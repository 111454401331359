export default function PadLock({
  colorCircle = null,
  colorPadLock = null,
  width = null,
  height = null,
}) {
  return (
    <svg
      width={width ? width : '55'}
      height={height ? height : '53'}
      viewBox="0 0 55 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="27.6042" cy="26.0117" r="26" fill={colorCircle} />
      <g clip-path="url(#clip0_20102_21852)">
        <path
          d="M26.5874 26.3027C25.1295 26.3027 23.9436 27.4887 23.9436 28.9465C23.9436 30.0961 24.6819 31.0764 25.7084 31.4396V33.6557H27.4663V31.4396C28.4934 31.0764 29.2311 30.0961 29.2311 28.9465C29.2311 27.4887 28.0452 26.3027 26.5874 26.3027ZM26.5874 29.833C26.0987 29.833 25.7014 29.4352 25.7014 28.9471C25.7014 28.459 26.0993 28.0611 26.5874 28.0611C27.0754 28.0611 27.4733 28.459 27.4733 28.9471C27.4733 29.4352 27.0754 29.833 26.5874 29.833Z"
          fill="#F09D51"
        />
        <path
          d="M37.174 29.3387V22.0232H34.5262V19.3701C34.5268 14.9926 30.9648 11.4307 26.5873 11.4307C22.2098 11.4307 18.6479 14.992 18.6479 19.3701V22.0232H16V37.9004H29.834C30.8266 39.9857 32.9535 41.4307 35.4127 41.4307C38.817 41.4307 41.5873 38.6609 41.5873 35.2561C41.5873 32.4635 39.7234 30.098 37.1746 29.3381L37.174 29.3387ZM20.4057 19.3701C20.4057 15.9617 23.1789 13.1885 26.5873 13.1885C29.9957 13.1885 32.7689 15.9617 32.7689 19.3701V22.0232H30.9965V19.3701C30.9965 16.9391 29.0184 14.9609 26.5873 14.9609C24.1562 14.9609 22.1781 16.9391 22.1781 19.3701V22.0232H20.4057V19.3701ZM29.2387 22.0232H23.9359V19.3701C23.9359 17.9082 25.1254 16.7188 26.5873 16.7188C28.0492 16.7188 29.2387 17.9082 29.2387 19.3701V22.0232ZM17.7578 36.1426V23.7811H35.4168V29.082C35.4168 29.082 35.4145 29.082 35.4133 29.082C32.009 29.082 29.2387 31.8518 29.2387 35.2566C29.2387 35.5578 29.2609 35.8531 29.3025 36.1426H17.7578ZM35.4127 39.6729C32.9775 39.6729 30.9959 37.6918 30.9959 35.2561C30.9959 32.8203 32.977 30.8393 35.4127 30.8393C37.8484 30.8393 39.8295 32.8203 39.8295 35.2561C39.8295 37.6918 37.8484 39.6729 35.4127 39.6729Z"
          fill="#F09D51"
        />
        <path
          d="M36.8651 32.5605L35.4126 34.0131L33.9601 32.5605L32.7173 33.8033L34.1698 35.2559L32.7173 36.7084L33.9601 37.9512L35.4126 36.4986L36.8651 37.9512L38.1079 36.7084L36.6554 35.2559L38.1079 33.8033L36.8651 32.5605Z"
          fill={colorPadLock}
        />
      </g>
      <path
        d="M3.31185 40.2612C2.07616 40.0346 0.945933 40.6531 0.474174 41.8104C0.422865 41.9387 0.358728 42.0613 0.300293 42.1867V43.2528C0.360154 43.3782 0.42429 43.4994 0.477024 43.6262C0.951634 44.7849 2.08186 45.4006 3.31755 45.1726C4.44635 44.9645 5.31006 43.9141 5.31861 42.7397C5.32574 41.5339 4.46631 40.4721 3.31185 40.2612ZM2.81159 43.6134C2.30705 43.6134 1.89943 43.2015 1.90798 42.7026C1.9151 42.2238 2.30562 41.8304 2.78593 41.8176C3.29047 41.8019 3.70665 42.201 3.71377 42.7026C3.7209 43.2086 3.3204 43.6134 2.81159 43.6134Z"
        fill="#F09D51"
      />
      <path
        d="M52.4299 11.1406C51.036 11.1349 49.9129 12.2409 49.9058 13.6277C49.8987 15.0159 51.0118 16.1476 52.3929 16.1561C53.7725 16.1661 54.9141 15.0401 54.9227 13.6633C54.9298 12.278 53.8138 11.1478 52.4299 11.1406ZM52.3786 14.5513C51.884 14.5328 51.4864 14.0966 51.5106 13.6006C51.5334 13.1217 51.9368 12.7412 52.4171 12.7441C52.9145 12.7469 53.3278 13.1702 53.3179 13.6648C53.3065 14.1565 52.8689 14.5698 52.3786 14.5513Z"
        fill="#F09D51"
      />
      <path
        d="M46.3806 8.44771V10.1765H44.7886V8.47479H43.0413V6.86283H44.7644V5.12402H46.3749V6.85713H48.0952V8.44771H46.3821H46.3806Z"
        fill={colorPadLock}
      />
      <path
        d="M12.9897 45.3261H14.7484V46.9124H13.0282V48.6711H11.4148V46.9366H9.69165V45.3489H11.3849V43.6172H12.9897V45.3261Z"
        fill={colorPadLock}
      />
      <defs>
        <clipPath id="clip0_20102_21852">
          <rect
            width="25.5873"
            height="30"
            fill="white"
            transform="translate(16 11.4307)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
