import { authenticatedAPI } from 'services/api';

const connectStudent = async (token) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/students/${token}/connect`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTalentsFilters = async (qs) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/students/options`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getTalentsCitiesFilters = async (state) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/students/options?${state}`)
    .then((res) => res.data.cities)
    .catch((error) => error);

  return response;
};

const getFilteredTalents = async (params) => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/enrolled-students?${params}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getConnections = async () => {
  const response = authenticatedAPI
    .get(`/api/internal/companies/students/connections`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const viewStudent = async (student) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/students/${student}/view`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const handleLike = async (student) => {
  const response = authenticatedAPI
    .post(`/api/internal/companies/students/${student}/like`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

export {
  connectStudent,
  getTalentsFilters,
  getFilteredTalents,
  getConnections,
  viewStudent,
  handleLike,
  getTalentsCitiesFilters,
};
