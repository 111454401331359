import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}

  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000000000;
`;

export const Box = styled.div`
  width: calc(100% - 259px);
  height: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 450px;
  border-radius: 12px;
  background: #ffffff;
  padding: 44px 35px;
  display: flex;
  flex-direction: column;

  .styled-form {
    margin: 25px 0 31px 0;

  }
`

export const Header = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  .buttonClose {
    margin-top: -107px;
    margin-right: -11px;
  }
`

export const BubbleWal = styled.div`
  width: 77px;
  border: 2px solid #009291;
  background: #f6f6f6;
  border-radius: 200px;

  img {
    border-radius: 200px;
  }
`

export const Title = styled.span`
  font-size: 22px !important;
  font-weight: 400;
  padding-left: 24px;
  color: #009291;
  text-align: start;
`

export const Wrapper = styled.div `
  font-size: 17px;
  line-height: 23px;
  
  width: 100%;
  
  margin-top: 21px;
  
  text-align: start;
  font-weight: 400;
  color: #606062;
`

export const ValidatedButton = styled.button`
  width: 100%;
  height: 40px;

  background-color: #009291;
  border-radius: 4px;

  border: none;

  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;

  cursor: pointer;
`

export const ContainerButtons = styled.div`
  width: 100%;
  max-width: 379px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const EditButtons = styled.button`
  width: 100%;
  max-width: 165px;
  height: 41px;
  border: none;

  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};

  font-size: 14px;
  font-weight: 500;

  border-radius: 3px;

  cursor: pointer;

`

export const ButtonX = styled.button`
  width: 100%;
  max-width: 25px;
  height: 25px;

  background-color: #EDEDED;
  border: none;
  border-radius: 200px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`