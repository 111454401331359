import React from 'react';
import { FlagContainer, SkeletonBox, Container } from './style';

export default function AccordionSkelleton() {
  return (
    <Container>
      <FlagContainer>
        <SkeletonBox width="262px" height={'26px'} />
      </FlagContainer>
      <div className="text-area">
        <SkeletonBox width="25px" height={'25px'} />
        <SkeletonBox width="262px" height={'24px'} />
      </div>

      <div className="content-information">
        <div>
          <SkeletonBox width="20px" height={'20px'} />
          <SkeletonBox width="126px" height={'24px'} />
        </div>
        <div>
          <SkeletonBox width="20px" height={'20px'} />
          <SkeletonBox width="126px" height={'24px'} />
        </div>
      </div>
      <div className="button-area">
        <SkeletonBox width="264px" height={'33px'} />
      </div>
      <div className="button-flutuant">
        <SkeletonBox width="97px" height={'40px'} />

      </div>
    </Container>
  );
}
