import React, { useContext, useEffect,useRef, useState } from 'react';
import { Form } from '@unform/web';

import {
  Wrapper, DescImg, MultiStepContainer, Img, Title, Choices, ChoicesTitle, ErrorMessage
} from '../../../../../../../components/Opportunities/styles/styleForm';

import Group from '../../../../../../../assets/Oportutinites/Group.svg';
import Series from '../../../../../../../assets/Oportutinites/Series.svg';
import Group7 from '../../../../../../../assets/Oportutinites/Group7.svg';
import Group1 from '../../../../../../../assets/Oportutinites/Group1.svg';
import Group448 from '../../../../../../../assets/Oportutinites/Group448.svg';
import Group10 from '../../../../../../../assets/Oportutinites/Group10.svg';
import Group11 from '../../../../../../../assets/Oportutinites/Group11.svg';
import Group12 from '../../../../../../../assets/Oportutinites/Group12.svg';
import Group13 from '../../../../../../../assets/Oportutinites/Group13.svg';
import Group14 from '../../../../../../../assets/Oportutinites/Group14.svg';
import Group15 from '../../../../../../../assets/Oportutinites/Group15.svg';
import SeriesWhite from '../../../../../../../assets/Oportutinites/SeriesWhite.svg';
import ChallangeWhite from '../../../../../../../assets/Oportutinites/ChallangeWhite.svg';


import FooterForm from '../../../../../../../components/Opportunities/Footer';
import InputRadio from '../../../../../../../components/Opportunities/Inputs/InputRadio';
import StepIndicator from '../../../../../../../components/Opportunities/StepIndicator';

import { OpportunitiesCompanyContext } from '../../../../context/OpportunitiesCompanyContext';


export default function StepOneCompany({ nextFormStep, prevsFormStep }) {
  const { formData, setFormValues, selectOption, setSelectOption } = useContext(OpportunitiesCompanyContext);

  const [noSelect, setNoSelect] = useState(false);

  const formRef = useRef();

  const radioOptions = [
    {
      id: 'article',
      value: 'article',
      label: 'Artigos',
      imageOne: Group14,
      imageTwo: Group15,
    },
    {
      id: 'e_book',
      value: 'e_book',
      label: 'E-books',
      imageOne: Group,
      imageTwo: Group1,
    },
    {
      id: 'trail',
      value: 'trail',
      label: 'Trilhas',
      imageOne: Group12,
      imageTwo: Group13,
    },
    {
      id: 'serie',
      value: 'serie',
      label: 'Séries',
      imageOne: Series,
      imageTwo: SeriesWhite,
    },
    {
      id: 'announcements',
      value: 'announcements',
      label: 'Comunicados',
      imageOne: Group10,
      imageTwo: Group11,
    },
    {
      id: 'challenge',
      value: 'challenge',
      label: 'Desafios',
      imageOne: Group7,
      imageTwo: ChallangeWhite,
    },
  ];

  const handleSubmit = async (data) => {
    if (selectOption === '') {
      setNoSelect(true);
      return;
    }

    setFormValues(data);
    nextFormStep();
  };

  const handleFormSelect = (data) => {
    setSelectOption(data.target.value);
  };

  useEffect(() => {
    if (selectOption !== '') {
      setNoSelect(false);
    }
  }, [selectOption]);
  return (
    <Form ref={formRef} initialData={formData} onSubmit={handleSubmit}>
      <MultiStepContainer>
        <StepIndicator Steps={['StepOne', 'StepTwo', 'StepTree']} />
      </MultiStepContainer>

      <Wrapper>
        <DescImg>
          <Img src={Group448} />
          <Title>
            Conteúdos
            <br />{' '}
          </Title>
        </DescImg>

        <Choices>
          <ChoicesTitle>
            Selecione a <strong>oportunidade de aprendizagem</strong> que deseja cadastrar:
          </ChoicesTitle>
        </Choices>

        <InputRadio name="type" options={radioOptions} onChange={handleFormSelect} />
        {noSelect && selectOption === '' && (
          <ErrorMessage marginTop="10px">
            Para seguir em frente selecione uma oportunidade de aprendizagem
          </ErrorMessage>
        )}
        <FooterForm
          haveNext={true}
          haveBack={false}
          prevsState={prevsFormStep}
        />
      </Wrapper>
    </Form>
  );
}
