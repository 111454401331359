import React from 'react';

import { Title } from './style';
import ReactHtmlParser from 'react-html-parser';

export default function HeaderReports({ title, subTitle, config }) {
  const parsedSubTitle = () => {
    return ReactHtmlParser(subTitle.replace(/style="[^"]*"/g, ''));
  };

  return (
    <div>
      <Title>{title}</Title>
      {subTitle && <p>{parsedSubTitle()}</p>}
    </div>
  );
}
