import styled, { keyframes } from 'styled-components';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

const Skeleton = styled.div`
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  animation: ${skeletonKeyframes} 1300ms ease-in-out infinite;
  background-size: 200px 100%;
`;

export const FlagContainer = styled.div`
  width: 100%;
  max-width: 262px;
  height: 28px;

  border-radius: 4px;

  margin-bottom: 18px;
`
export const Container = styled.div`
  display: flex;
  justify-content: flex-start;

  flex-direction: column;

  padding: 27px 0 ;
  margin: 0 49px;

  border-bottom: 1px solid #E6E6E6;

  position: relative;

  .text-area {
    display: flex;
    flex-direction: row;
    gap: 7px;
  }

  .content-information {
    margin-top: 20px;

    display: flex;
    flex-direction: row;
    gap: 35px;
    
  }

  .content-information div {

    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 7px;
  }

  .button-area {
    margin-top: 20px;
  }

  .button-flutuant {
    position: absolute;
    right: 0;
    top: 70px;
  }

`




export const SkeletonBox = styled(Skeleton)`
  border-radius: 4px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : '100%')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.5')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0')};
`;
