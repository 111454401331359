import React, { useRef, useState, useEffect } from 'react';
import {
  Container,
  WrapperLink,
  WrapperForm,
  WrapperButton,
  SubmitButton,
} from './styled';
import { Form } from '@unform/web';
import { mentorshipApi } from 'views/StaffPages/Mentorship/services/api';
import { useHistoricMentorshipContext } from '../../context';
import DescriptionInput from 'views/StaffPages/Mentorship/components/DescriptionInput';
import FileInput from 'views/StaffPages/Mentorship/components/FileInput';
import GenericModal from 'views/StaffPages/Mentorship/components/GenericModal';

const MentoringDoneModal = ({
  item,
  title,
  onClose,
  isOpen,
  buttonTitle,
  fetchMentorships,
}) => {
  const formRef = useRef();
  const [documentFile, setDocumentFile] = useState(null);
  const {
    submitMentoringFeedback,
    closeDetailsModal,
    setPage,
  } = useHistoricMentorshipContext();

  useEffect(() => {
    if (isOpen) {
      formRef.current.setData({
        feedback: item.feedback,
      });

      if (item?.document?.file) {
        const fetchFile = async () => {
          try {
            const res = await fetch(item.document.file);
            const blob = await res.blob();
            const file = new File([blob], item.document.name, {
              type: 'application/pdf',
            });
            setDocumentFile(file);
            formRef.current.setFieldValue('document', file);
          } catch (error) {
            console.error('Erro ao carregar o arquivo:', error);
          }
        };

        fetchFile();
      }
    }
  }, [item, isOpen]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type === 'application/pdf') {
        setDocumentFile(file);
        formRef.current.setFieldValue('document', file);
      } else {
        console.error('Tipo de arquivo não suportado');
      }
    }
  };

  const handleSubmit = async (data) => {
    try {
      const feedbackData = {
        feedback: data.feedback,
        not_realize_reason: '',
      };
      const formData = new FormData();
      if (documentFile) {
        formData.append('document', documentFile);
      }
      await Promise.all([
        submitMentoringFeedback(item.id, feedbackData),
        documentFile
          ? mentorshipApi.uploadMentorshipDocument(item.id, formData)
          : Promise.resolve(),
      ]);
      onClose();
      closeDetailsModal();
      setPage(1);
      fetchMentorships(true);
    } catch (error) {
      console.error('Erro ao finalizar a mentoria:', error);
    }
  };

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <GenericModal isOpen={isOpen} onClose={onClose} title={title}>
        <Container>
          <WrapperForm>
            <WrapperForm>
              <strong>Comentários sobre a mentoria</strong>
              <p>
                Caso queira, adicione comentários sobre como foi a mentoria e
                possíveis observações. Esse comentário não ficará disponível
                para o estudante.
              </p>
              <DescriptionInput name="feedback" placeholder="Digite aqui..." />
            </WrapperForm>

            <WrapperLink>
              <FileInput
                name="document"
                showFileSelectedContent={true}
                defaultValue={documentFile}
                onChange={handleFileChange}
              />
            </WrapperLink>
          </WrapperForm>
          <WrapperButton>
            <SubmitButton type="submit">{buttonTitle}</SubmitButton>
          </WrapperButton>
        </Container>
      </GenericModal>
    </Form>
  );
};

export default MentoringDoneModal;
