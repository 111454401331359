import styled from 'styled-components';

import arrowRight from 'assets/Forms/arrowRight.svg';
import arrowLeft from 'assets/Forms/arrowLeft.svg';

export const ContainerButtons = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 30px;
  padding-bottom: 20px;
`;

const BaseButton = styled.button`
  position: absolute;
  background-color: #009291;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  cursor: pointer;
`;

export const NextButton = styled(BaseButton)`
  right: 8px;
  background-image: url(${arrowRight});

  &:disabled {
    background-color: #949494;
    cursor: not-allowed;
  }
`;

export const PrevsButton = styled(BaseButton)`
  left: 8px;
  background-image: url(${arrowLeft});
`;

export const SaveButton = styled.button`
  position: absolute;
  background-color: #009291;
  color: #fff;
  border: none;
  right: 8px;
  height: 29px;
  border-radius: 2px;
  padding: 15px;
  display: flex;
  align-items: center;
  top: 7px;
  cursor: pointer;

  &:disabled {
    background-color: #949494;
    color: #fff;
  }
`;

export const ActionButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin-top: 10px;
`;

export const RejectButton = styled.div`
  display: flex;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: #da0505;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const ConfirmButton = styled.button`
  width: 79px;
  height: 39px;
  padding: 10px;
  border-radius: 3px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #009291;
  background-color: #009291;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: center;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;
