import styled from 'styled-components';
import { DialogContent } from '@material-ui/core';

export const ContentDialog = styled(DialogContent)`
  padding: '24px 40px';

  .headerContent {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 1.5em;
    max-height: 97px;
    border-radius: 50%;
    border: 3px solid #309190;
    width: 97px;
    height: 97px;
  }

  .textContent {
    background: #fff;
    padding: 9px 19px;
    border-radius: 20px;
  }

  .textContent:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-left: 20px solid transparent;
    transform: rotate(-90deg);
    border-right: 20px solid transparent;
    border-bottom: 20px solid #fff;
    top: 67px;
    left: 117px;
  }

  .load {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 513px) {
    .headerContent {
      flex-wrap: wrap;
      justify-content: center;
      img {
        margin-bottom: 25px;
      }
    }

    .textContent:after {
      top: 132px;
      left: 41%;
      position: absolute;
      transform: none;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    margin-top: 23px;
    button {
      border-radius: 140px;
      font-size: 14px;
      background-color: rgb(47, 145, 144);
    }
    .saveIcon {
      font-size: 20px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }
`;

export const Form = styled.form`
  margin-top: 30px;

  .MuiFormLabel-focused-4365 {
    color: #309190;
  }
`;
