import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
} from './style';
import React, { Fragment, useMemo } from 'react';
import walWhat from '../../../../assets/img/walWhat.svg';
import ReactHtmlParser from 'react-html-parser';

export default function ModalFail({
  config,
  setModalOpen,
  modalFailOpenState,
  buttonFunction,
  universityColor,
}) {
  const memorizedModal = useMemo(
    () => (
      <Wrapper visible={modalFailOpenState}>
        <Modal width={config.modalWidth} height={config.modalHeight}>
          <WrapperTitle>
            <IconPersonStyled universityColor={universityColor} src={walWhat} />
            <BubbleDialog universityColor={universityColor}>
              <H4Styled>{config.bubbleText}</H4Styled>
            </BubbleDialog>
          </WrapperTitle>
          <ParagraphStyled textAlign={config.textAlign}>
            {ReactHtmlParser(config.ParagraphText)}
          </ParagraphStyled>
          {config.showButton && (
            <Button
              universityColor={universityColor}
              width={config.buttonWidth}
              height={config.buttonHeight}
              onClick={() => buttonFunction && buttonFunction()}
            >
              {config.buttonText}
            </Button>
          )}
        </Modal>
      </Wrapper>
    ),
    [modalFailOpenState, config]
  );
  return <Fragment>{memorizedModal}</Fragment>;
}

ModalFail.defaultProps = {
  config: {
    modalWidth: 415,
    modalHeight: 418,
    bubbleText: '...',
    paragraphText: 'Adicione sua Descrição',
    buttonWidth: 290,
    buttonHeight: 50,
    buttonFuction: () => {},
    buttonText: 'Botão',
    visible: true,
  },
};
