import React, { useEffect, useState, useContext } from 'react';
import { useFilterContext } from '../contexts/FilterContext';
import { ChipButton, SelectedContainer, SelectFilterBox } from '../styles';
import { FilterType } from './Filter';
import { CloseIcon } from 'assets/JobOffer/Close';
import BaseLayoutContext from 'contexts/base-layout';

export function FilterMultiText({ name, placeholder, ...props }) {
  const {
    filterState,
    updateFilterState,
    isClickInsideFilter,
  } = useFilterContext();
  const selectedOptions = filterState[name] || [];
  const [value, setValue] = useState('');
  const {universityColor} = useContext(BaseLayoutContext)

  useEffect(() => {
    document.addEventListener('mousedown', handleSubmit, false);
    return () => {
      document.removeEventListener('mousedown', handleSubmit, false);
    };
  }, [selectedOptions, value]);

  function handleSubmit(e) {
    const valueIsSelected = selectedOptions.includes(value);
    if (
      !valueIsSelected &&
      value.trim() !== '' &&
      isClickInsideFilter(e.target)
    ) {
      updateFilterState(value, name, FilterType.MULTI_TEXT);
    }
    setValue('');
  }

  function handleChange(e) {
    setValue(e.target.value);
  }

  return (
    <SelectFilterBox>
      <input
        className={`filter-text`}
        name={name}
        type="text"
        value={value}
        onChange={handleChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleSubmit(e);
          }
        }}
        placeholder={placeholder}
        {...props}
      />
      <SelectedContainer>
        {selectedOptions.map((option) => (
          <ChipButton
            universityColor={universityColor}
            key={option}
            onClick={() =>
              updateFilterState(option, name, FilterType.MULTI_SELECT)
            }
          >
            <p>{option}</p>
            <CloseIcon />
          </ChipButton>
        ))}
      </SelectedContainer>
    </SelectFilterBox>
  );
}
