import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonBack,
  Container,
  ContainerButton,
  PaginationContainer,
  Score,
  Subtitle,
  Title,
  Wrapper,
} from './styled';

import { HiArrowNarrowLeft } from 'react-icons/hi';
import { ExtensionProjectsContext } from '../../../../../Context/ExtensionProjectsContext';
import LimitSelect from './components/SelectLimit';
import Pagination from '../../../../../../../../components/CustomPagination';
import { EnrolledStudentsList } from './components/EnrolledStudentsList';
import { getListExtensionProjectStudents } from '../../../../../services';
import { CloseButton } from '../ModalStylingPattern/styles';
import { IoClose } from 'react-icons/io5';

export default function ModalTrackEnrollments({ status }) {
  const [page, setPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(20);
  const [studentCount, setStudentCount] = useState(0);
  const [students, setStudents] = useState([]);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [score, setScore] = useState(null);

  const {
    setModalEnrolledStudents,
    extensionProjectId,
    setExtensionProjectId,
    refModalExtensionProject,
    getAllFutureProjects,
    getAllOpenForSubscription,
    getAllInProgressProjects,

    studentScore,
    setStudentScore,
  } = useContext(ExtensionProjectsContext);

  const handleBackHome = () => {
    setModalEnrolledStudents(false);
    setExtensionProjectId(null);

    getAllFutureProjects(true);
    getAllOpenForSubscription(true);
    getAllInProgressProjects(true);

    setStudentScore(false);
  };

  async function getStudents({ limitPerPage, offset }) {
    setRequestInProgress(true);
    const data = await getListExtensionProjectStudents(
      extensionProjectId,
      limitPerPage,
      offset
    );
    const { results, count } = data;
    setStudentCount(count);
    setStudents(results);
    setRequestInProgress(false);
  }

  useEffect(() => {
    if (refModalExtensionProject) {
      refModalExtensionProject.current.scrollIntoView();
    }
  }, [refModalExtensionProject]);

  return (
    <Container>
      <ContainerButton>
        <div className="wrapper-pagination">
          <LimitSelect
            setLimit={setLimitPerPage}
            pageSize={limitPerPage}
            onChange={(e) => {
              setPage(1);
              getStudents(e);
            }}
            setPage={setPage}
          />
        </div>
      </ContainerButton>
      <Wrapper>
        <CloseButton onClick={handleBackHome}>
          <IoClose size={22} />
        </CloseButton>
        <Title>Lista de estudantes</Title>
        <Subtitle>
          Confira os estudantes que se inscreveram para participar do projeto de
          extensão.
        </Subtitle>
        {score !== null && <Score>Nota do projeto: {score}</Score>}

        <EnrolledStudentsList
          status={status}
          setStudentCount={setStudentCount}
          getStudents={getStudents}
          students={students}
          requestInProgress={requestInProgress}
          limitPerPage={limitPerPage}
          setScore={setScore}
        />
      </Wrapper>
      <footer>
        <PaginationContainer>
          <Pagination
            className="pagination-bar"
            currentPage={page}
            totalCount={studentCount}
            limit={limitPerPage}
            onPageChange={(page) => {
              setPage(page);
              getStudents({ limitPerPage, offset: (page - 1) * limitPerPage });
            }}
            setLimit={setLimitPerPage}
            setCurrentPage={setPage}
            showLimit={false}
          />
        </PaginationContainer>
        <LimitSelect
          setLimit={setLimitPerPage}
          pageSize={limitPerPage}
          onChange={(e) => {
            setPage(1);
            getStudents(e);
          }}
          setPage={setPage}
        />
      </footer>
    </Container>
  );
}
