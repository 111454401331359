import styled from 'styled-components';

export const ContainerList = styled.div`
  display: flex;
  border-bottom: 2px solid #eef2f4;
  margin-bottom: 10px;
  padding: 0px 40px;

  .span2 {
    width: 20em;
    font-weight: 400;
    color: #606062;
  }

  .span4 {
    font-weight: 400;
    color: #606062;

    > div > img {
      cursor: pointer;
    }
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${(props) => (props.open ? 'auto' : '36px')};
  overflow: hidden;
  .span1 {
    width: 31em;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    font-weight: 400;
    color: #606062;
  }
`;

export const ActionArrow = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  cursor: pointer;

  > img {
    ${(props) =>
      props.open ? 'transform: rotate(-180deg)' : 'transform: rotate(0deg)'};
  }
`;

export const Information = styled.p`
  display: flex;
  gap: 10px;
  color: #777777;
  font-weight: 400;
`;

export const StatusSpan = styled.span`
  width: 12em;
  font-weight: 400;

  > span {
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    padding: 3px;
    border-radius: 4px;
    font-size: 11px;
    display: inline-flex;
    max-width: 128px;
    width: auto;
    align-items: center;
    gap: 6px;

    > div {
      height: 17px;
    }
  }
`;
