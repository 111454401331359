import React, { useState, useEffect } from 'react';
import { GenericInternalTabs } from 'components/GenericNavegation';
import AssociatedComponent from './associatedComponent';
import { FilterAcademicInnovation } from '../../components/Filter';
import { Container, SubContainer, SearchFilter } from './style';
import { AiOutlineSearch } from 'react-icons/ai';
import PendingComponent from './pendingComponent';
import MainComponent from './mainComponent';
import { formatNumber } from 'utils/formatNumber';
import academicInnovationApi from '../../services/academicInnovationApi';
import { useFormContext } from '../../contexts';

export default function InternalTab() {
  const [countPendingTag, setCountPendingTag] = useState(0);
  const [countAssociatedTag, setCountAssociatedTag] = useState(0);
  const [countMainTag, setCountMainTag] = useState(0);
  const {
    searchTeam,
    setSearchTeam,
    setQueryFilter,
    activeSubTab,
    setActiveSubTab,
  } = useFormContext();

  const getCountTabs = async () => {
    const data = await academicInnovationApi.getCountTags();
    const { pending, associated, principal } = data;
    setCountPendingTag(pending);
    setCountAssociatedTag(associated);
    setCountMainTag(principal);
  };

  useEffect(() => {
    getCountTabs();
  }, [activeSubTab]);

  const handleChangeTab = (value) => {
    setActiveSubTab(value);
    setAppliedFilters({});
    setFilterState({});
    setQueryFilter('');
    setSearchTeam('');
  };

  const Tabs = [
    {
      activeSubTab: 0,
      component: (
        <PendingComponent
          setCountPendingTag={setCountPendingTag}
          countPendingTag={countPendingTag}
        />
      ),
    },
    {
      activeSubTab: 1,
      component: (
        <AssociatedComponent
          setCountAssociatedTag={setCountAssociatedTag}
          countAssociatedTag={countAssociatedTag}
        />
      ),
    },
    {
      activeSubTab: 2,
      component: (
        <MainComponent
          setCountMainTag={setCountMainTag}
          countMainTag={countMainTag}
        />
      ),
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeSubTab === step);
    return returnTabs.component;
  };

  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  return (
    <Container>
      <SubContainer>
        <GenericInternalTabs
          activeTab={activeSubTab}
          handleChange={handleChangeTab}
          tabs={[
            `Pendentes (${formatNumber(countPendingTag)})`,
            `Associadas (${formatNumber(countAssociatedTag)})`,
            `Tags Principais (${formatNumber(countMainTag)})`,
            ,
          ]}
        />

        <SearchFilter>
          <input
            type="text"
            placeholder="Pesquise por nome"
            onChange={(e) => setSearchTeam(e.target.value)}
            value={searchTeam}
          />
          <AiOutlineSearch size={24} />
        </SearchFilter>

        <FilterAcademicInnovation
          filterState={filterState}
          setFilterState={setFilterState}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          activeSubTab={activeSubTab}
          setCountPendingTag={setCountPendingTag}
          setCountAssociatedTag={setCountAssociatedTag}
          setCountMainTag={setCountMainTag}
        />
      </SubContainer>
      {renderPrincipalTabs(activeSubTab)}
    </Container>
  );
}
