import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TimeElapsedInfo = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #949494;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin: 40px 0 20px;
`;

export const WrapperInformations = styled.div`
  width: 610px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.31px;
  margin: 20px 0;

  > span {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    color: #009291;
    margin-left: 5px;
  }

  > div {
    flex-direction: column;
  }
`;
export const WrapperLink = styled.div`
  width: 100%;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 25.31px;
  margin-top: 10px;
`;

export const RejectButton = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  margin-bottom: 20px;
  color: #da0505;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

export const ConfirmButton = styled.button`
  width: 79px;
  height: 39px;
  gap: 10px;
  padding: 10px;
  border-radius: 3px;
  border: 1px;
  opacity: 0px;
  border: 1px solid #009291;
  margin-bottom: 20px;
  background-color: #009291;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.58px;
  text-align: center;
  color: #ffff;

  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.01);
  }
`;
export const WrapperForm = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  margin: 20px 0;

  width: 100%;

  > p {
    margin: 5px 0;
  }
`;

export const WrapperFormContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: -15px;
`;
