import React, { useContext, useState } from 'react';
import {
  CardWithStatus,
  ContentStatus,
  Paragraph,
  Header,
  ContentIcon,
  InformationsJob,
  Information,
  ParagraphTitle,
  ButtonJobOffers,
  ButtonDetailsJobOffersWithStatus,
  ContentButtonsApply,
  ContentWithStatus,
  JobStatus,
  JobStatusParagraph,
  StatusImage,
  ContainerParagraphStatus,
  JobStatusParagraphFinished,
  JobStatusFinished,
  FeedbackParagraph,
  ButtonContractedJob,
} from './styled';
import { wrapperIconsJobOffers } from '../utils/wrapperIconsJobOffers';
import moment from 'moment';
import { jobOffersTexts } from '../utils/jobOffersTexts';
import { Star } from '@material-ui/icons/';
import ModalInformation from 'components/informationModal';
import { returnConfigContracted } from '../Card/useCases/IModalConfig';
import { studentContracted } from 'services/student/jobOffers';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import BaseLayoutContext from 'contexts/base-layout';
import ModalFeedbackJobOffer from '../Modals/ModalFeedbackJobOffer';

export default function GenericCardsJobOffersWithStatus(props) {
  const {
    universityColor,
    code,
    name,
    dataPublish,
    companyName,
    finallyDate,
    jobModality,
    shift,
    location,
    onClickButton,
    feedback,
    feedbackName,
    feedbackFinished,
    interview,
    isFinishedJobs,
    onClickButtonTwo,
    isOnGoingJobs,
    status,
    statusFinished,
    returnLabelFinished,
    is_indication,
    job,
    data,
  } = props;

  const [confirmContracted, setConfirmContracted] = useState(false);
  const [openModalFeedback, setOpenModalFeedback] = useState(false);
  const {
    IconSuitcase,
    IconLocation,
    IconCompany,
    IconHouse,
    IconDate,
  } = wrapperIconsJobOffers();
  const {
    returnParagraph,
    returnStatus,
    getStatusIcon,
    getButtonLabelOne,
    getButtonLabelTwo,
    getFinishedStatusIcon,
    returnParagraphFinished,
  } = jobOffersTexts();
  const paragraphText = returnParagraph(status, data);
  const statusText = returnStatus(status);
  const statusIcon = getStatusIcon(status);
  const returnLabelOne = getButtonLabelOne(status);
  const returnLabelTwo = getButtonLabelTwo(status);
  const returnFinishedStatus = returnParagraphFinished(statusFinished);
  const returnFinishedIcon = getFinishedStatusIcon(statusFinished);
  const { history } = useContext(BaseLayoutContext);

  const handleCloseModal = () => {
    setOpenModalFeedback(false);
  };

  const handleContracted = async (id) => {
    const response = await studentContracted(id);
    if (!response.message) {
      setTimeout(() => {
        const searchParams =
          window.location.href.includes('?') &&
          window.location.href.split('?')[1];
        history.push({
          pathname: '/',
          search: searchParams,
          state: { addExperience: true },
        });
      }, 1000);
      customSnackbar(
        'Parabéns! O informe de contratação foi realizado com sucesso.',
        'confirmation'
      );
      setConfirmContracted(false);
    } else {
      customSnackbar('Erro ao informar contratação!', 'error');
    }
  };

  return (
    <CardWithStatus isFinishedJobs={isFinishedJobs}>
      {openModalFeedback && (
        <ModalFeedbackJobOffer
          handleCloseModal={handleCloseModal}
          universityColor={universityColor}
          feedback={feedback}
          feedbackName={feedbackName}
          data={feedbackFinished}
          modalOpenState={openModalFeedback}
        />
      )}

      {confirmContracted && (
        <ModalInformation
          modalOpenState={confirmContracted}
          buttonConfirmFunction={() => handleContracted(code)}
          universityColor={universityColor}
          setModalOpen={setConfirmContracted}
          config={returnConfigContracted(job)}
          closeModalFunction={() => setConfirmContracted(false)}
        />
      )}

      {status && (
        <>
          <JobStatus status={status}>
            {statusIcon && <StatusImage src={statusIcon} />}
            <JobStatusParagraph isStatus status={status}>
              {statusText}
            </JobStatusParagraph>
          </JobStatus>
        </>
      )}

      {isFinishedJobs && (
        <JobStatusFinished
          statusFinished={statusFinished}
          universityColor={universityColor}
        >
          {returnFinishedIcon && <StatusImage src={returnFinishedIcon} />}
          <JobStatusParagraphFinished>
            {returnFinishedStatus}
          </JobStatusParagraphFinished>
        </JobStatusFinished>
      )}

      <ContentWithStatus>
        {is_indication && (
          <div className="indicationStatus">
            <Star />
            <span>Você foi indicado para a vaga</span>
          </div>
        )}
        <Header
          isIndication={is_indication ?? false}
          isFinishedJobs={isFinishedJobs}
          isCandidature
        >
          {code && !isFinishedJobs && <Paragraph>CÓDIGO: {code}</Paragraph>}
          <ParagraphTitle>{name}</ParagraphTitle>
        </Header>

        {dataPublish && (
          <ContentStatus universityColor={universityColor}>
            <Paragraph universityColor={universityColor}>
              {dataPublish}
            </Paragraph>
          </ContentStatus>
        )}

        <InformationsJob>
          {companyName && (
            <Information>
              <ContentIcon universityColor={universityColor}>
                <IconCompany color={universityColor} />
              </ContentIcon>
              <ParagraphTitle isName universityColor={universityColor} bold>
                {companyName}
              </ParagraphTitle>
            </Information>
          )}

          {finallyDate && (
            <Information>
              <ContentIcon universityColor={universityColor}>
                <IconDate color={universityColor} />
              </ContentIcon>
              <Paragraph>
                Inscreva-se até {moment(finallyDate).format('DD/MM/YYYY')}
              </Paragraph>
            </Information>
          )}

          {jobModality && (
            <Information>
              <ContentIcon universityColor={universityColor}>
                <IconHouse color={universityColor} />
              </ContentIcon>
              <Paragraph>{jobModality}</Paragraph>
            </Information>
          )}

          {shift && (
            <Information>
              <ContentIcon universityColor={universityColor}>
                <IconSuitcase color={universityColor} />
              </ContentIcon>
              <Paragraph>{shift}</Paragraph>
            </Information>
          )}

          {location && (
            <Information>
              <ContentIcon universityColor={universityColor}>
                <IconLocation color={universityColor} />
              </ContentIcon>
              <Paragraph>{location}</Paragraph>
            </Information>
          )}
        </InformationsJob>

        {isOnGoingJobs && (
          <ContainerParagraphStatus>
            <JobStatusParagraph>{paragraphText}</JobStatusParagraph>
          </ContainerParagraphStatus>
        )}

        {statusFinished === 'student_contracted' && (
          <ContainerParagraphStatus>
            <FeedbackParagraph universityColor={universityColor}>
              <p className="paragraph">
                <span className="paragraphSpan">Parabéns!</span> Você foi
                selecionado(a)
              </p>
              <p>
                para a vaga.{' '}
                {feedback !== null && feedback !== '' && (
                  <ButtonContractedJob
                    universityColor={universityColor}
                    onClick={() => setOpenModalFeedback(true)}
                  >
                    Ver feedback
                  </ButtonContractedJob>
                )}
              </p>
            </FeedbackParagraph>
          </ContainerParagraphStatus>
        )}

        {statusFinished === 'job_expired' && (
          <ContainerParagraphStatus>
            <ButtonContractedJob
              universityColor={universityColor}
              onClick={() => setConfirmContracted(true)}
            >
              Fui contratado(a) pela empresa
            </ButtonContractedJob>
          </ContainerParagraphStatus>
        )}

        {isFinishedJobs ? (
          <ContentButtonsApply isFinishedJobs={isFinishedJobs}>
            <ButtonJobOffers
              universityColor={universityColor}
              onClick={onClickButton}
              isOnGoingJobs
            >
              {returnLabelFinished}
            </ButtonJobOffers>
          </ContentButtonsApply>
        ) : (
          <ContentButtonsApply isOnGoing>
            <ButtonJobOffers
              universityColor={universityColor}
              onClick={onClickButton}
              isOnGoingJobs
            >
              {returnLabelOne}
            </ButtonJobOffers>

            <ButtonDetailsJobOffersWithStatus
              universityColor={universityColor}
              onClick={onClickButtonTwo}
            >
              {returnLabelTwo}
            </ButtonDetailsJobOffersWithStatus>
          </ContentButtonsApply>
        )}
      </ContentWithStatus>
    </CardWithStatus>
  );
}
