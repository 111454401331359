import React, { useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';
import {
  CheckboxContainer,
  CheckboxGroupInput,
  CheckboxInput,
  CheckboxText,
  ErrorMessage,
} from '../Style/BaseInputs';
import { Error } from '../inputField/style';

/**
 * CheckboxGroup component creates a group of checkboxes allowing multiple selections.
 *
 * @component
 * @param {string} name - The name of the input field. Should be unique within the form.
 * @param {Array} options - An array of objects representing the options for checkboxes. Each object should have 'value' and 'label' properties.
 * @param {string} [spacing] - Optional. Specifies the spacing between checkboxes.
 * @param {Object} rest - Additional props to be spread on the checkboxes.
 * @returns {JSX.Element} CheckboxGroup component

 */

export const CheckboxGroup = ({
  name,
  options,
  spacing,
  onCheckboxChange,
  ...rest
}) => {
  const inputRefs = useRef({});
  const {
    fieldName,
    registerField,
    error,
    clearError,
    defaultValue = [],
  } = useField(name);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return Object.values(refs)
          .filter((ref) => ref.checked)
          .map((ref) => ref.value);
      },
      clearValue: (refs) => {
        Object.values(refs).forEach((ref) => {
          ref.checked = false;
        });
        setSelectedValues([]);
      },
      setValue: (refs, value) => {
        Object.values(refs).forEach((ref) => {
          if (value.includes(ref.value)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [fieldName, registerField]);

  const handleCheckboxChange = (event, checkboxValue) => {
    const isChecked = event.target.checked;

    let newSelectedValues;
    if (isChecked) {
      newSelectedValues = [...selectedValues, checkboxValue];
    } else {
      newSelectedValues = selectedValues.filter(
        (value) => value.value !== checkboxValue.value
      );
    }
    setSelectedValues(newSelectedValues);

    if (onCheckboxChange) {
      onCheckboxChange(newSelectedValues);
    }
  };

  return (
    <div>
      {options.map((option, index) => (
        <CheckboxContainer spacing={spacing} key={index}>
          <CheckboxGroupInput
            type="checkbox"
            ref={(ref) => {
              inputRefs.current[option.value] = ref;
            }}
            value={option.value}
            defaultChecked={defaultValue.includes(option.value)}
            onChange={(event) => handleCheckboxChange(event, option)}
            data-label={option.label}
            onFocus={clearError}
            {...rest}
          />
          <CheckboxText>{option.label}</CheckboxText>
        </CheckboxContainer>
      ))}
      {error && <Error>{error}</Error>}
    </div>
  );
};
