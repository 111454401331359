import React, { Fragment, useContext } from 'react';
import { MatrixContext } from '../../../contexts/MatrixContext';
import { Circle, Container } from './style';
export default function DragItemTemplate({ fields }) {
  const { allDisciplinesCourse } = useContext(MatrixContext);

  const firstElement = allDisciplinesCourse.find(
    (item) => item.id === fields[0]
  );

  return (
    <Fragment>
      {firstElement && firstElement.name && (
        <Container>
          {firstElement.name}

          {fields.length - 1 > 0 && <Circle>+{fields.length - 1}</Circle>}
        </Container>
      )}
    </Fragment>
  );
}
