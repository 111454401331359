// ##############################
// // // LoginPage view styles
// #############################

import {
  container,
  cardTitle,
  dnaGreen,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const loginPageStyle = (theme) => ({
  container: {
    ...container,
    zIndex: '4',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '100px',
    },
  },
  cardTitle: {
    ...cardTitle,
    color: '#FFFFFF',
  },
  loginContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100vh',
  },
  textCenter: {
    textAlign: 'center',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  customButtonClass: {
    '&,&:focus,&:hover': {
      color: '#FFFFFF',
    },
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputAdornment: {
    marginRight: '18px',
  },
  inputAdornmentIcon: {
    color: '#555',
  },
  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  cardHeader: {
    marginBottom: '20px',
  },
  socialLine: {
    padding: '0.9375rem 0',
  },
  input: {
    '&:after': {
      borderBottomColor: dnaGreen,
    },
  },
  inputField: {
    color: `${dnaGreen} !important`,
  },
  recoverPassLink: {
    marginTop: 5,
    fontSize: 12,
    cursor: 'pointer',
    color: dnaGreen,
    width: 123,
  },

  inputConfirm: {
    marginTop: 14,
  },
});

export default loginPageStyle;
