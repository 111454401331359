import React, { useState, useEffect, useContext } from 'react';
import Dropzone from 'react-dropzone';
import AvatarEditor from 'react-avatar-editor';
import BaseLayoutContext from 'contexts/base-layout';
import { FaRegTrashAlt } from 'react-icons/fa';

import {
  DivInsertImg,
  EditImgButton,
  RemoveImageButton,
  ImgUrl,
  ModalWrapper,
  TextInput,
  FragmentModal,
  Title,
  FrangmentAvatar,
  Actions,
  Buttons,
  FragmentZoom,
  Row,
  LabelZomm,
  InputZomm,
  Button,
  Text,
  DivAlterImg,
  ImageError,
} from './styles';

const UploadedStudentAvatarCustom = ({
  setFile,
  removeButton,
  borderRadiusImageDisplay = '50%',
  widthImageDisplay = '500px',
  heightImageDisplay = '500px',
  cutWidht = 500,
  cutHeight = 500,
  cutBorderRadius = 250,
  zIndez = '9999',
  imageUrl,
  handleChange,
}) => {
  const dropzoneStyle = {
    width: widthImageDisplay,
    height: heightImageDisplay,
    border: '2px dashed #ccc',
    borderRadius: borderRadiusImageDisplay,
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: zIndez,
    display: 'flex',
  };
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [editor, setEditor] = useState(null);
  const [zoom, setZoom] = useState(1.0);
  const [openModal, setOpenModal] = useState(false);
  const [savedImageUrl, setSavedImageUrl] = useState(null);
  const [nowImage, setNowImage] = useState(null);

  const { deactivateScrollModalWithContent, universityColor } = useContext(
    BaseLayoutContext
  );

  useEffect(() => {
    deactivateScrollModalWithContent(openModal);
  }, [openModal]);

  useEffect(() => {
    setNowImage(imageUrl);
  }, [imageUrl]);

  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.size > 100000) {
      setImageError('O tamanho da imagem deve ser inferior a 1 Mb');
    } else if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      setImageError('Extensão não permitida, utilize: PNG/JPG');
    } else {
      setImageError('');
      setImage(file);
      removeButton(false);
      setOpenModal(true);
    }
  };

  const hasImage = () => {
    return Boolean(nowImage) || Boolean(savedImageUrl);
  };

  const handleZoomChange = (value) => {
    setZoom(value);
  };

  const handleSave = (e) => {
    e && e.preventDefault();
    if (editor) {
      const { props } = editor;
      const canvas = editor?.getImage();
      canvas.toBlob((blob) => {
        const file = new File([blob], props?.image?.name, {
          type: props?.image?.type,
        });
        setFile(file);
        handleChange && handleChange(file);
        setSavedImageUrl(URL.createObjectURL(blob));
      }, props?.image?.type);
      setOpenModal(false);
      setZoom(1.0);
    }
  };

  const handleRemove = () => {
    setImage(null);
    setFile(null);
    handleChange && handleChange(null);
    setSavedImageUrl(null);
    setNowImage(null);
    setZoom(1.0);
    removeButton(true);
  };

  const handleCancel = (e) => {
    e && e.preventDefault();
    setImage(null);
    setOpenModal(false);
    setZoom(1.0);
  };

  return (
    <div style={{ zIndex: zIndez }}>
      {!openModal && (
        <DivInsertImg>
          {!hasImage() ? (
            <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} style={dropzoneStyle}>
                  <input {...getInputProps()} />
                  <TextInput>
                    Arraste e solte uma imagem aqui ou clique para selecionar.
                  </TextInput>
                </div>
              )}
            </Dropzone>
          ) : (
            <>
              <ImgUrl
                id="avatar"
                src={savedImageUrl || nowImage}
                width={widthImageDisplay}
                height={heightImageDisplay}
                radius={borderRadiusImageDisplay}
              />
              <RemoveImageButton
                width={widthImageDisplay}
                height={heightImageDisplay}
                radius={borderRadiusImageDisplay}
                onClick={() => handleRemove()}
                universityColor={universityColor}
              >
                {removeButton && <FaRegTrashAlt />}
              </RemoveImageButton>
            </>
          )}
          <div style={{ display: 'flex' }}>
            <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <div
                  {...getRootProps()}
                  style={{
                    width: '100%',
                    height: '35px',
                    borderRadius: '25px',
                  }}
                >
                  <input {...getInputProps()} />
                  <EditImgButton universityColor={universityColor}>
                    {`${hasImage() ? 'ALTERAR' : 'INSERIR'} IMAGEM`}
                  </EditImgButton>
                </div>
              )}
            </Dropzone>
          </div>
        </DivInsertImg>
      )}

      {image && openModal && (
        <ModalWrapper>
          <FragmentModal>
            <Title universityColor={universityColor}>Editar foto</Title>
            <FrangmentAvatar>
              <AvatarEditor
                style={{ zIndex: zIndez }}
                ref={(editorRef) => setEditor(editorRef)}
                image={image}
                width={cutWidht}
                height={cutHeight}
                border={0}
                borderRadius={cutBorderRadius}
                color={[0, 0, 0, 0.5]}
                scale={zoom}
                rotate={0}
              />
            </FrangmentAvatar>
            <Actions>
              <FragmentZoom>
                <Row>
                  <LabelZomm>Ajuste de zoom:</LabelZomm>
                  <InputZomm
                    type="range"
                    min="1"
                    max="2"
                    step="0.01"
                    value={zoom}
                    universityColor={universityColor}
                    onChange={(e) =>
                      handleZoomChange(parseFloat(e.target.value))
                    }
                  />
                </Row>
                <Text>
                  Caso queira, ajuste o zoom para deixar sua foto mais próxima.
                </Text>
              </FragmentZoom>
              <Buttons>
                <Button
                  background={'#fff'}
                  color={universityColor ? universityColor : '#009291'}
                  onClick={(e) => handleCancel(e)}
                >
                  Cancelar
                </Button>

                <Button
                  background={universityColor ? universityColor : '#009291'}
                  color={'#fff'}
                  onClick={(e) => handleSave(e)}
                >
                  Concluir
                </Button>
              </Buttons>
            </Actions>

            <DivAlterImg>
              <Dropzone onDrop={handleDrop} accept="image/*" multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      width: '100%',
                      height: '30px',
                      border: '2px dashed #ccc',
                      borderRadius: '5px',
                      padding: '1rem',
                      textAlign: 'center',
                      cursor: 'pointer',
                      zIndex: zIndez,
                      display: 'flex',
                    }}
                  >
                    <input {...getInputProps()} />
                    <TextInput>
                      Clique aqui ou arraste uma nova imagem para substituir
                    </TextInput>
                  </div>
                )}
              </Dropzone>
            </DivAlterImg>
          </FragmentModal>
        </ModalWrapper>
      )}
      {imageError && <ImageError>{imageError}</ImageError>}
    </div>
  );
};

export default UploadedStudentAvatarCustom;
