import styled from 'styled-components';

export const FilterContainer = styled.div`
  display: flex;
  right: ${(props) => (props.hasFilters ? '8rem' : '6rem')};
  top: 1rem;
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FilterGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  > div {
    margin-top: 26px;
    display: flex;
  }
`;

export const ContainerExtensionProject = styled.div`
  width: 100%;
`;

export const SpanError = styled.p`
  font-size: 12px;
  text-align: start;
  right: 0;
  color: #f00;
  margin: 0;
`;

export const ContainerFlexDirection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isRow ? 'row' : 'column')};
  align-items: center;
  justify-content: center;

  width: 100%;
`;
