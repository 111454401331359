const companyPreRegisteredDataAdapter = ({
  id,
  legal_name,
  organization_number,
  address,
  staff,
  validation_token,
}) => {
  return {
    inviteId: id,
    companyName: legal_name,
    organizationNumber: organization_number,
    street: address.address,
    addressNumber: address.number,
    addressState: address.state,
    city: address.city,
    district: address.district,
    complement: address.complement,
    zipCode: address.zip_code,
    staffName: staff.name,
    staffEmail: staff.email,
    staffPhone: staff.phone,
    validationToken: validation_token,
  };
};

const loadCompanyAdapter = ({
  name,
  legal_name,
  organization_number,
  website,
  description,
  address,
  staff,
  interests,
  activities_sectors,
  challenges,
  type,
  size,
  skills,
  values,
  invite_id,
  invite_validation_token,
  logo,
  company_color,
  macro_areas,
  origin,
  use_integration_agent,
}) => {
  return {
    companyAlias: name,
    companyName: legal_name,
    organizationNumber: organization_number,
    website,
    companyDescription: description,
    street: address.address,
    addressNumber: address.number,
    complement: address.complement,
    district: address.district,
    city: address.city,
    addressState: address.state,
    zipCode: address.zip_code,
    staffName: staff.name,
    staffEmail: staff.email,
    staffPhone: staff.phone,
    staffRole: staff.role,
    staffSector: staff.staff_sector,
    staffAnotherRole: staff.another_role,
    companyInterests: interests,
    activityType: activities_sectors,
    companyChallengers: challenges,
    companyType: type,
    companySize: size,
    companySkills: skills,
    companyValues: values,
    inviteId: invite_id,
    validationToken: invite_validation_token,
    origin,
    logo,
    macroAreas: macro_areas,
    companyColor: company_color,
    useIntegrationAgent: use_integration_agent,
  };
};

const companyAPIAdapter = ({
  companyAlias,
  companyName,
  companyDescription,
  organizationNumber,
  website,
  street,
  addressNumber,
  complement,
  district,
  city,
  addressState,
  zipCode,
  companyInterests,
  activityType,
  companyType,
  companySize,
  companyChallengers,
  companySkills,
  companyValues,
  staffName,
  staffEmail,
  staffPhone,
  staffRole,
  staffAnotherRole,
  staffPassword,
  staffPasswordConfirmation,
  inviteId,
  validationToken,
  origin,
  companyColor,
  macroAreas,
  useIntegrationAgent,
  staffSector,
  ...rest
}) => {
  return {
    name: companyAlias,
    legal_name: companyName,
    organization_number: organizationNumber,
    website,
    description: companyDescription,

    // address
    address: street,
    address_number: addressNumber,
    address_complement: complement,
    district,
    city,
    state: addressState,
    zip_code: zipCode,

    // staff
    staff_name: staffName,
    staff_email: staffEmail ? staffEmail.toLowerCase() : staffEmail,
    staff_phone: staffPhone,
    staff_role: staffRole.value,
    staff_another_role: staffAnotherRole,
    staff_password: staffPassword,
    staff_password_confirmation: staffPasswordConfirmation,
    staff_sector: staffSector ? staffSector.value : null,

    interests: companyInterests.map((item) => item.value),
    activities_sectors: activityType.map((item) => item.value),
    type: companyType.value,
    size: companySize.value,
    skills: companySkills.map((item) => item.value),
    values: companyValues.map((item) => item.value),

    invite_id: inviteId,
    invite_validation_token: validationToken,

    origin,

    company_color: companyColor,
    macro_areas: macroAreas.map((item) => item.value),
    use_integration_agent: useIntegrationAgent
      ? useIntegrationAgent.value
      : null,
  };
};

export {
  companyPreRegisteredDataAdapter,
  companyAPIAdapter,
  loadCompanyAdapter,
};
