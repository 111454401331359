import { IconPersonality } from 'assets/img/iconsCurriculum/iconPersonality';
import { IconBehavioral } from 'assets/img/iconsCurriculum/iconBehavioral';
import { IconValues } from 'assets/img/iconsCurriculum/iconValues';
import { IconInterests } from 'assets/img/iconsCurriculum/iconInterests';
import { profileTexts } from 'views/Profile/Utils/profileTexts';

import { ColorInfo } from '../styled';
import { useState, useEffect } from 'react';
import { PersonalInfo } from 'assets/img/iconsCurriculum/personalInfo';

const useStepOneSorting = (stepOneData) => {
  const [iconsData, setIconsData] = useState([]);
  const { getInfoColorOne, getIconColorOne } = profileTexts();

  const getSortedIcons = () => {
    const {
      interests,
      behavioral_attributes,
      personality_attributes,
      personal_info,
    } = stepOneData;

    const items = [
      {
        key: 'personal_info',
        title: 'Dados Pessoais',
        icon: (
          <PersonalInfo size={28} color={getIconColorOne('personal_info')} />
        ),
        completed: personal_info === 1,
      },
      {
        key: 'interests',
        title: 'Interesses',
        icon: <IconInterests color={getIconColorOne('interests')} />,
        completed: interests <= 2 && interests !== 0,
      },
      {
        key: 'personality_attributes',
        title: 'Personalidade',
        icon: (
          <IconPersonality color={getIconColorOne('personality_attributes')} />
        ),
        completed: personality_attributes === 1,
      },
      {
        key: 'behavioral_attributes',
        title: 'Perfil Comportamental',
        icon: (
          <IconBehavioral color={getIconColorOne('behavioral_attributes')} />
        ),
        completed: behavioral_attributes === 1,
      },
    ];

    items.sort((a, b) =>
      a.completed === b.completed ? 0 : a.completed ? -1 : 1
    );

    let totalItems = {
      interests: 2,
      behavioral_attributes: 1,
      personality_attributes: 1,
      personal_info: 1,
    };
    return items.map((item) => ({
      ...item,
      info: (
        <ColorInfo color={getInfoColorOne(item.key)}>
          <i>
            {stepOneData[item.key]}/{totalItems[item.key]} concluído
          </i>
        </ColorInfo>
      ),
    }));
  };

  useEffect(() => {
    if (stepOneData) {
      setIconsData(getSortedIcons());
    } else {
      setIconsData([]);
    }
  }, [stepOneData]);

  return iconsData;
};

export default useStepOneSorting;
