import React from 'react';
import StepIndicatorChallenges from './StepIndicatorChallenges';

import { ModalHeader, CloseButton } from './styles';

function HeaderModal({
  currentStep,
  setCurrentStep,
  setIsOpenModal,
}) {
  return (
    <ModalHeader>
      <StepIndicatorChallenges
        count={2}
        currentStep={currentStep}
        onStepChange={(value) => {
          setCurrentStep(value);
        }}
      />
      <CloseButton onClick={() => setIsOpenModal(false)} />
    </ModalHeader>
  );
}

export default HeaderModal;
