import styled from 'styled-components';

export const Dots = styled.button`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  background-color: ${(props) => (props.isActive ? props.universityColor : '#fff')};
  border: 1px solid ${props => props.universityColor ? props.universityColor : "#009291"};

  font-size: 18px;
  color: ${(props) => (props.isActive ? '#fff' : props.universityColor)};

  cursor: pointer;
`;

export const ContainerDots = styled.div`
  display: flex;
  gap: 15px;

  width: 100%;

  align-items: center;
  justify-content: center;
`;

export const ArrowButton = styled.button`
  width: fit-content;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
