export function IconArrowLeftFlag({ color, size = 20 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_32607_28710)">
        <path
          d="M15.8329 1.66604H8.29627C7.66145 1.66407 7.03469 1.80819 6.46448 2.08722C5.89428 2.36626 5.39591 2.77273 5.00794 3.2752L0.174602 9.4877C0.0608062 9.63399 -0.000976562 9.81404 -0.000976562 9.99937C-0.000976563 10.1847 0.0608062 10.3648 0.174602 10.511L5.00794 16.7235C5.39591 17.226 5.89428 17.6325 6.46448 17.9115C7.03469 18.1906 7.66145 18.3347 8.29627 18.3327H15.8329C16.9376 18.3314 17.9966 17.892 18.7778 17.1109C19.5589 16.3297 19.9983 15.2707 19.9996 14.166V5.8327C19.9983 4.72804 19.5589 3.669 18.7778 2.88789C17.9966 2.10677 16.9376 1.66736 15.8329 1.66604Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_32607_28710">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
