import React, { useContext } from 'react';
import { Container, ButtonFinnalyAndNext } from './styled';
import BaseLayoutContext from 'contexts/base-layout';

export function FinallyButtonTest({ onClick }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Container>
      <ButtonFinnalyAndNext universityColor={universityColor} onClick={onClick}>
        FINALIZAR
      </ButtonFinnalyAndNext>
    </Container>
  );
}

export function NextStepTest({ onClick }) {
  const { universityColor } = useContext(BaseLayoutContext);

  return (
    <Container>
      <ButtonFinnalyAndNext universityColor={universityColor} onClick={onClick}>
        CONTINUAR
      </ButtonFinnalyAndNext>
    </Container>
  );
}
