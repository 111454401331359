import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { selectPagination } from './styles';

function SelectOrdering({ classes, onChange, ordering, setOrdering }) {
  const selectPagination = (e) => {
    if (e.target.value) {
      setOrdering(e.target.value);
    }
    onChange && onChange(e.target.value);
  };

  const options = [
    { value: 'name', label: 'Nome (A-Z)' },
    { value: '-name', label: 'Nome (Z-A)' },
    {
      value: 'extensionprojectregistrationcontrol__created_at',
      label: 'Data de inscrição (mais antiga)',
    },
    {
      value: '-extensionprojectregistrationcontrol__created_at',
      label: 'Data de inscrição (mais recente)',
    },
  ];

  return (
    <FormControl
      variant="outlined"
      classes={{
        root:
          ordering.length < 6
            ? classes.formControlShort
            : classes.formControlLong,
      }}
    >
      <InputLabel
        htmlFor="outlined-age-native-simple"
        className={classes.selectLabel}
        classes={{
          root: classes.cssLabel,
        }}
      >
        Ordenar por
      </InputLabel>
      <Select
        value={ordering}
        fullWidth
        className={classes.selectInput}
        onChange={selectPagination}
        input={
          <OutlinedInput name="pageSelect" labelWidth={120} id="page-select" />
        }
      >
        {options.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default withStyles(selectPagination)(SelectOrdering);
