import axios from 'axios';
import getCookieValue from 'utils/getCookieValue';
import URLS from 'constants/urls';

const authenticatedAPI = axios.create({
  baseURL: URLS.API_URL,
});

authenticatedAPI.interceptors.request.use(async (config) => {
  config.withCredentials = true;
  config.headers = {
    'X-CSRFTOKEN': getCookieValue('csrftoken', document.cookie),
    ...config.headers,
  };
  return config;
});

class IESCompanyAPI {
  getUniversities = (query) => {
    return authenticatedAPI
      .get(`api/internal/companies/ies${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getUniversityData = (id) => {
    return authenticatedAPI
      .get(`api/internal/companies/ies/${id}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getUniversityCourses = (id, query) => {
    return authenticatedAPI
      .get(`api/internal/companies/ies/${id}/courses${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };

  getFilters = (query) => {
    return authenticatedAPI
      .options(`api/internal/companies/ies${query}`)
      .then((response) => response.data)
      .catch((error) => error);
  };
}

export default new IESCompanyAPI();
