// ##############################
// // // FullScreenDialog styles
// #############################

import {
  dnaGreen,
  grayColor,
  grayLightColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

export const fullScreenDialogStyle = (theme) => ({
  dialogWindow: {
    transition: 'all 350ms ease 0s',
    marginLeft: 260,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    backgroundColor: grayLightColor,
    '&:root': {
      backgroundColor: grayLightColor,
    },
  },
  paperFullScreen: {
    backgroundColor: grayLightColor,
  },
  withSidebarMinimized: {
    marginLeft: 80,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  sidebarMinimizeButton: {
    color: grayColor,
    marginTop: 16,
    marginLeft: 30,
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: grayLightColor,
  },
  container: {
    display: 'initial',
  },
  content: {
    backgroundColor: grayLightColor,
  },
  dialogFullScreenTitle: {
    color: '#5b0e62',
    fontSize: 35,
    marginTop: 115,
    marginLeft: 100,
    marginRight: 162,
    textAlign: 'center',
    width: 'calc(100% - 162px)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
      marginTop: '60px',
      marginLeft: '16px',
      marginRight: '16px',
      textAlign: 'center',
      width: 'auto',
    },
  },
  closeFullScreenDialog: {
    position: 'absolute',
    right: 30,
    top: 20,
    '& svg': {
      color: grayColor,
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 0,
      '& svg': {
        width: 25,
        height: 25,
      },
    },
  },
  green: {
    '& $dialogFullScreenTitle': {
      color: '#1cb45e !important',
    },
  },
  purple: {
    '& $dialogFullScreenTitle': {
      color: '#692a71',
    },
  },
  blue: {
    '& $dialogFullScreenTitle': {
      color: '#0183ad',
    },
  },
  yellow: {
    '& $dialogFullScreenTitle': {
      color: '#eb9b00',
    },
  },
});

export const newFullScreenDialogStyle = (theme) => ({
  dialogWindow: {
    transition: 'all 350ms ease 0s',
    marginLeft: 260,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    backgroundColor: '#FFF',
    '&:root': {
      backgroundColor: '#FFF',
    },
  },
  paperFullScreen: {
    backgroundColor: '#FFF',
    '&::-webkit-scrollbar': {
      width: '0.6em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'white',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#80808069',
      borderRadius: '4px',
    },
  },
  withSidebarMinimized: {
    marginLeft: 80,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  sidebarMinimizeButton: {
    color: grayColor,
    marginTop: 16,
    marginLeft: 30,
    '& svg': {
      width: 32,
      height: 32,
    },
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#fff',
    height: '100px',
  },
  container: {
    display: 'block',
    height: '100%',
  },
  content: {
    backgroundColor: '#FFF',
  },
  dialogFullScreenTitle: {
    color: '#5b0e62',
    fontSize: 35,
    marginTop: 115,
    marginLeft: 100,
    marginRight: 162,
    textAlign: 'center',
    width: 'calc(100% - 162px)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
      marginTop: '60px',
      marginLeft: '16px',
      marginRight: '16px',
      textAlign: 'center',
      width: 'auto',
    },
  },
  closeFullScreenDialog: {
    position: 'absolute',
    right: 30,
    top: 20,
    '& svg': {
      color: grayColor,
      width: 40,
      height: 40,
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 0,
      '& svg': {
        width: 25,
        height: 25,
      },
    },
  },
  green: {
    '& $dialogFullScreenTitle': {
      color: '#1cb45e !important',
    },
  },
  purple: {
    '& $dialogFullScreenTitle': {
      color: '#692a71',
    },
  },
  blue: {
    '& $dialogFullScreenTitle': {
      color: '#0183ad',
    },
  },
  yellow: {
    '& $dialogFullScreenTitle': {
      color: '#eb9b00',
    },
  },
});
