import React, { Fragment, useContext, useEffect, useState } from 'react';
import Button from 'components/CustomButtons/Button';
import BaseLayoutContext from 'contexts/base-layout';
import { FastField, Formik } from 'formik';
import { format } from 'date-fns';
import MaskedInput from 'react-maskedinput';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import classNames from 'classnames';
import SearchableSelect from 'react-select';

import extraCoursesAdapter from 'adapters/student/extraCoursesAdapter';
import {
  getExtraCourses,
  getExtraCoursesOptions,
  saveExtraCourses,
  updateExtraCoursePDF,
  updateExtraCourses,
} from 'services/student/extraCourses';
import withStyles from '@material-ui/core/styles/withStyles';
import ProfessionalExperienceFormStyles
  from 'assets/jss/material-dashboard-pro-react/views/components/professionalExperienceFormStyles';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import DateTimePicker from 'components/DateTimePicker';
import { triggerHotjarEvent } from 'utils/hotjar';
import { Mixpanel } from 'utils/mixpanel';
import { generateExtraCoursesSchema } from './ExtraCoursesSchema';
import HtmlEditor from '../../../../components/HtmlEditor';
import { PDFUpload } from '../../../../components/CustomUpload/PDFUpload';
import { triggerEvent } from '../../../../utils/analytics';
import { FeedbackCard } from './components/FeedbackCard';
import { approvedRequest, awaitingRequest, failedRequest } from './ExtracourseTalkModalOptions';
import useDefineColor from 'constants/colors';
import { customSnackbar } from 'components/CustomSnackBar/customSnackbar';
import { actionsProfileStore } from 'stores/profile.store';

const formatDate = (date) => {
  const splitted = date.split('/');
  return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
};

const maskHours = (value) => {
  let varTemp = value;
  if (varTemp >= 0 && typeof varTemp === 'number') {
    varTemp = String(varTemp);
  }
  return varTemp.replace(/\D/g, '').replace(/(\d{1,6})([0-9][0-9]$)/, '$1:$2');
};

function ExtraCoursesForm({
  classes,
  isEdit,
  closeModal,
  updateExtraCourseState,
  openSnackbar,
  handleIsLoadingState,
  isAttractionCompletion,
  attractionInfo,
  extraCourse,
  metadata,
  visibleExtraCourses,
  universityColor,
  cardDataKey,
  setVisibleCourses,
  fetchGamificationData,
  handleVisibleItem,
  cardData,
}) {
  const { updateCard } = actionsProfileStore;
  const [radioValues, setRadioValues] = useState('');
  const [professionalAreas, setProfessionalAreas] = useState([]);
  const [checkedRequestAnalyze, setcheckedRequestAnalyze] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [filteredAnalysis, setFilteredAnalysis] = useState([]);
  const [courseLevels, setCourseLevels] = useState([]);
  const [courseStatus, setCourseStatus] = useState([]);
  const [acquiredKnowledgeOptions, setAcquiredKnowledgeOptions] = useState([]);
  const [extraCoursesSchema, setExtraCoursesSchema] = useState(null);
  const { openTalkModal, academic_profile } = useContext(BaseLayoutContext);
  const [initialValues, setInitialValues] = useState({
    acquiredKnowledges: null,
    area: null,
    id: '',
    level: null,
    institution: '',
    course: '',
    startedAt: '',
    endedAt: '',
    status: '',
    workload: '',
    isArea: false,
    isFinished: false,
    pdfFile: null,
    description: '',
  });

  function validateDescription() {
    let error;

    if (charCount > 1500) {
      error = 'Limite máximo de 1500 caracteres.';
    }
    return error;
  }

  async function handleGetExtraCoursesOptions() {
    handleIsLoadingState(true);
    const extraCoursesOptions = await getExtraCoursesOptions();
    setProfessionalAreas(extraCoursesOptions.area);
    setCourseLevels(extraCoursesOptions.level);
    setCourseStatus(extraCoursesOptions.status);
    setAcquiredKnowledgeOptions(extraCoursesOptions.area.slice(1));

    if (isEdit) {
      const {
        area,
        id,
        level,
        institution,
        course,
        started_at: startedAt,
        ended_at: endedAt,
        status,
        workload,
        file,
        description,
        analysis_requests: analysisRequests,
      } = extraCourse;
      setInitialValues({
        area,
        acquiredKnowledges: extraCourse.acquired_knowledges,
        id,
        level,
        institution,
        course,
        startedAt: formatDate(startedAt),
        endedAt: endedAt ? formatDate(endedAt) : '',
        status,
        workload: maskHours(workload),
        isArea: area !== 'others',
        isFinished: !!(status && status.value === 'finished'),
        pdfFile: file,
        description: !description ? '' : description,
      });

      const filterAnalysis = analysisRequests.filter(
        (item) => item.feedback !== null || undefined
      );
      setFilteredAnalysis(filterAnalysis);
    }

    if (isAttractionCompletion) {
      const {
        name,
        type,
        created_by,
        competences_specialities,
        workload,
        created_at,
        end_at,
      } = attractionInfo;
      const allCompetences = [...competences_specialities];
      const principalKnowlegde = allCompetences[0];
      const otherKnowlegde = allCompetences.slice(1);
      const level = { label: 'Outro', value: 'other' };
      const status = { label: 'Concluído', value: 'finished' };
      const institution = created_by.name || '';
      const startedAt = !created_at;
      const endedAt = !end_at ? '' : format(new Date(end_at), 'yyyy-MM-dd');

      setInitialValues({
        course: name,
        area: principalKnowlegde,
        acquiredKnowledges: otherKnowlegde,
        id: '',
        level,
        institution,
        startedAt,
        endedAt,
        status,
        workload: workload || '',
        isArea: false,
        isFinished: !!(status && status.value === 'finished'),
        description: '',
      });
    }
    handleIsLoadingState(false);
  }

  useEffect(() => {
    async function initialRequest() {
      const schema = await generateExtraCoursesSchema(
        isAttractionCompletion,
        attractionInfo
      );
      setExtraCoursesSchema(schema);
      await handleGetExtraCoursesOptions();

      if (metadata.university_slug !== 'uniexemplo') {
        triggerHotjarEvent('extracourse');
      }
    }
    Mixpanel.track('Abriu Formulário de Formação e Capacitação');
    initialRequest();
  }, []);

  async function verifyPDFAndDescription(description, pdf) {
    if (isAttractionCompletion && !description && !pdf) {
      return false;
    }

    return true;
  }

  async function submitForm(values, isAnalysis) {
    const newValues = {
      ...values,
      analysisRequest: isAnalysis,
    };
    const newExtraCourse = isEdit
      ? await updateExtraCourses(extraCoursesAdapter(newValues))
      : await saveExtraCourses(extraCoursesAdapter(newValues));

    if (!isEdit && visibleExtraCourses?.length < 3) {
      const updatedVisibleCourses = [...visibleExtraCourses, newExtraCourse.id];

      const visibilityItemConfig = {
        items: updatedVisibleCourses,
        item: newExtraCourse,
        reqKey: 'additional_course',
        isUpdate: true,
        cardData,
        dataKey: cardDataKey,
        updateKey: 'extra_courses',
        setVisibleItems: setVisibleCourses,
      };
      handleVisibleItem(visibilityItemConfig);
    }

    if (values.id) {
      if (values.pdfFile && !(typeof values.pdfFile === 'string')) {
        const pdfResponse = await updateExtraCoursePDF(
          values.id,
          values.pdfFile
        );
        newExtraCourse.file = pdfResponse.file;
      }
      newExtraCourse.id = values.id;
    } else if (values.pdfFile) {
      const pdfResponse = await updateExtraCoursePDF(
        newExtraCourse.id,
        values.pdfFile
      );
      newExtraCourse.file = pdfResponse.file;
    }

    return newExtraCourse;
  }

  function handleCheckAnalysisAvailability(errors, values) {
    if (
      values.status.value === 'enrolled' ||
      values.status.value === 'abandoned'
    ) {
      return true;
    }
    if (!values.description && !values.pdfFile) {
      return true;
    }
    if (
      isAttractionCompletion &&
      !attractionInfo.is_complementary_activity &&
      !attractionInfo.is_extension_activity
    ) {
      return true;
    }
    if (Object.keys(errors).length) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    setRadioValues;
  }, [isEdit]);

  async function finishSubmit(values, actions, isAnalysis) {
    const isDescriptionOrPDFValid = await verifyPDFAndDescription(
      values.description,
      values.pdfFile
    );
    if (!isDescriptionOrPDFValid) {
      actions.setFieldError(
        'pdfAndDescription',
        'É necessário informar os conhecimentos adquiridos ou fazer upload do certificado em PDF'
      );
      actions.setSubmitting(false);
      return;
    }
    handleIsLoadingState(true);
    actions.setSubmitting(true);
    values.workload = maskHours(values.workload).replace(':', '.');
    const newExtraCourse = await submitForm(values, isAnalysis);
    const visibleExtraCourse = await getExtraCourses();

    if (newExtraCourse.message) {
      customConfirmation(
        `Erro ao gravar seus dados. Se o erro persistir,
         entre em contato com o suporte avisando sobre o
         erro: ${newExtraCourse.message}
        `,
        'error'
      );
      actions.setSubmitting(false);
      handleIsLoadingState(false);

      return;
    }
    if (!isAttractionCompletion) {
      cardDataKey && updateCard(cardDataKey, visibleExtraCourse);
    }
    await fetchGamificationData();
    if (isAttractionCompletion) {
      triggerEvent('click', 'Oportunidade Concluída', 'aluno');
      Mixpanel.track('Salvou - Conclusão de Oportunidade');
    }
    actions.setSubmitting(false);
    handleIsLoadingState(false);
    Mixpanel.track('Salvou Formulário de Formação e Capacitação');
    setTimeout(() => {
      closeModal();
      customSnackbar('Curso extra salvo com sucesso!', 'confirmation');
    }, 700);
  }

  async function checkEditApprovedAnalysis(values, actions) {
    if (isEdit) {
      const { analysis_requests: analysisRequests } = extraCourse;

      const lastRequest =
        analysisRequests.length > 0
          ? analysisRequests[analysisRequests.length - 1].status.value
          : [];

      const userGreeting = `Olá, ${academic_profile.name}!`;

      if (analysisRequests.length > 0) {
        openTalkModal(
          lastRequest === 'approved_analysis'
            ? { title: userGreeting, ...approvedRequest }
            : lastRequest === 'failed_analysis'
            ? { title: userGreeting, ...failedRequest }
            : lastRequest === 'awaiting_analysis'
            ? { title: userGreeting, ...awaitingRequest }
            : null,
          () => finishSubmit(values, actions, true),
          () => {
            if (lastRequest === 'failed_analysis') {
              finishSubmit(values, actions, false);
            } else closeModal();
          }
        );
        return;
      }
    }
    if (checkedRequestAnalyze) {
      openTalkModal(
        {
          title: `Olá, ${academic_profile.name}`,
          description: (
            <span>
              <p>
                Vi que você solicitou a{' '}
                <u>Análise de Atividade Extracurricular</u>, após a confirmação
                vou encaminhar a solicitação para a sua Instituição de Ensino.
                Ah, lembre-se de acompanhar o andamento da sua solicitação por
                aqui!
              </p>
            </span>
          ),
          buttonText: 'Ok, Solicitar Análise!',
          buttonTextDenied: 'Não Solicitar Análise!',
        },
        () => finishSubmit(values, actions, true),
        () => {
          setcheckedRequestAnalyze(false);
          finishSubmit(values, actions, false);
        }
      );
    } else {
      finishSubmit(values, actions, false);
    }
  }

  return (
    <Grid
      container
      className={classes.fullScreenGridContainer}
      justify="center"
    >
      <Grid item xs md={12} className={classes.fullScreenGrid}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={extraCoursesSchema}
          onSubmit={(values, actions) => {
            checkEditApprovedAnalysis(values, actions);
            actions.setSubmitting(false);
          }}
          render={({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className={classes.formContainer}>
              {isEdit && (
                <input type="hidden" id="id" name="id" value={values.id} />
              )}

              <List className={classes.list}>
                {/* Course name */}
                <ListItem className={classes.listItem}>
                  <FastField
                    autoFocus
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    name="course"
                    variant="filled"
                    id="course"
                    label="Curso *"
                    placeholder="Ex.: Curso de oratória"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.course && touched.course}
                    value={values.course}
                    component={TextField}
                  />
                  {errors.course && touched.course ? (
                    <FormHelperText className={classes.helperText}>
                      {errors.course}
                    </FormHelperText>
                  ) : null}
                </ListItem>

                {/* Knowledge */}
                <ListItem className={classes.listItem}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="area"
                      shrink
                      error={errors.area && touched.area}
                    >
                      Principal Conhecimento/Especialidade *
                    </InputLabel>

                    {((isEdit && values.area) || !isEdit) && (
                      <SearchableSelect
                        name="area"
                        id="area"
                        isClearable={false}
                        InputLabel={{
                          shrink: true,
                        }}
                        menuPlacement="bottom"
                        isSearchable
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                        ])}
                        onBlur={(event) => setFieldTouched('area', event.label)}
                        onChange={(event) => {
                          if (event) {
                            setFieldValue('area', event);
                          }
                        }}
                        options={professionalAreas || []}
                        value={values.area}
                        placeholder="Digite ou Escolha"
                      />
                    )}
                  </FormControl>
                  {!!errors.area && !!touched.area && (
                    <FormHelperText className={classes.helperText}>
                      {errors.area}
                    </FormHelperText>
                  )}
                </ListItem>
                {/** Acquired Knowledge */}
                <ListItem className={classes.listItem}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="acquiredKnowledges"
                      shrink
                      error={
                        errors.acquiredKnowledges && touched.acquiredKnowledges
                      }
                    >
                      Outros conhecimentos
                    </InputLabel>
                    {((isEdit && values.acquiredKnowledges) || !isEdit) && (
                      <SearchableSelect
                        name="acquiredKnowledges"
                        id="acquiredKnowledges"
                        InputLabel={{
                          shrink: true,
                        }}
                        isMulti
                        multiline
                        isClearable
                        rows={3}
                        menuPlacement="top"
                        isSearchable
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                        ])}
                        onBlur={(event) =>
                          setFieldTouched('acquiredKnowledges', event.label)
                        }
                        onChange={(event) => {
                          if (event.length <= 5) {
                            setFieldValue('acquiredKnowledges', event);
                          }
                        }}
                        options={acquiredKnowledgeOptions || []}
                        value={values.acquiredKnowledges}
                        placeholder="Digite ou Escolha"
                      />
                    )}
                  </FormControl>
                  {!!errors.acquiredKnowledge ? (
                    !!touched.acquiredKnowledge && (
                      <FormHelperText className={classes.helperText}>
                        {errors.acquiredKnowledge}
                      </FormHelperText>
                    )
                  ) : (
                    <FormHelperText className={classes.helperText}>
                      Até 5 conhecimentos
                    </FormHelperText>
                  )}
                </ListItem>

                {/** Course Level */}
                <ListItem className={classes.listItem}>
                  <FormControl variant="filled" fullWidth>
                    <InputLabel
                      htmlFor="level"
                      shrink
                      error={errors.level && touched.level}
                    >
                      Nível do Curso *
                    </InputLabel>
                    {((isEdit && values.level) || !isEdit) && (
                      <SearchableSelect
                        name="level"
                        id="level"
                        isClearable={false}
                        InputLabel={{
                          shrink: true,
                        }}
                        menuPlacement="top"
                        isSearchable
                        classNamePrefix="react-select"
                        className={classNames([
                          classes.selectSection,
                          classes.selectSectionAutoHeight,
                          classes.selectSectionCard,
                        ])}
                        onBlur={(event) =>
                          setFieldTouched('level', event.label)
                        }
                        onChange={(event) => {
                          if (event) {
                            setFieldValue('level', event);
                          } else {
                            setFieldValue('level', '');
                          }
                        }}
                        options={courseLevels || []}
                        value={values.level}
                        pdfFile
                        placeholder="Selecione o nível do curso"
                      />
                    )}
                  </FormControl>
                  {!!errors.level && !!touched.level && (
                    <FormHelperText className={classes.helperText}>
                      {errors.level}
                    </FormHelperText>
                  )}
                </ListItem>

                {/* Workload */}
                <ListItem className={classes.listItem}>
                  <FastField
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    error={errors.workload && touched.workload}
                    name="workload"
                    id="workload"
                    variant="filled"
                    component={TextField}
                    label="Carga horária (em horas) *"
                    placeholder="Ex: 40:00"
                    value={maskHours(values.workload)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                  />
                  {errors.workload && touched.workload && (
                    <FormHelperText className={classes.helperText}>
                      {errors.workload}
                    </FormHelperText>
                  )}
                </ListItem>

                {/* Institution */}
                <ListItem className={classes.listItem}>
                  <FastField
                    className={classes.inputField}
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    name="institution"
                    variant="filled"
                    id="institution"
                    label="Instituição *"
                    placeholder="Ex.: DNA da Educação"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.institution && touched.institution}
                    value={values.institution}
                    component={TextField}
                  />
                  {errors.institution && touched.institution ? (
                    <FormHelperText className={classes.helperText}>
                      {errors.institution}
                    </FormHelperText>
                  ) : null}
                </ListItem>

                {/* Started At */}
                <ListItem className={classes.listItem}>
                  <DateTimePicker
                    className={
                      (classes.textFieldDatePicker, classes.inputField)
                    }
                    InputProps={{
                      className: classes.input,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      FormLabelClasses: {
                        focused: classes.inputField,
                      },
                    }}
                    variant="filled"
                    name="startedAt"
                    id="startedAt"
                    type="date"
                    label="Data de início *"
                    placeholder={`Ex: ${format(new Date(), 'dd/MM/yyyy')}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={
                      isAttractionCompletion && attractionInfo.type === 'event'
                        ? initialValues.startedAt
                        : values.startedAt
                    }
                    error={errors.startedAt && touched.startedAt}
                  />
                  {errors.startedAt && touched.startedAt ? (
                    <FormHelperText className={classes.helperText}>
                      {errors.startedAt}
                    </FormHelperText>
                  ) : null}
                </ListItem>

                {/* Course status */}
                <ListItem className={classes.listItem}>
                  <FormControl
                    component="fieldset"
                    className={(classes.formControl, classes.fullWidth)}
                  >
                    <RadioGroup
                      aria-label="Status"
                      name="status"
                      className={(classes.group, classes.inputField)}
                      value={
                        values.status && values.status.value
                          ? values.status.value
                          : ''
                      }
                      error={errors.status}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === 'finished') {
                          setFieldValue('isFinished', true);
                        } else {
                          setFieldValue('isFinished', false);
                        }
                        if (e) {
                          if (
                            e.target.value === 'abandoned' ||
                            e.target.value === 'enrolled'
                          ) {
                            setcheckedRequestAnalyze(false);
                          }
                          setFieldValue('status', {
                            label: e.target.name,
                            value: e.target.value,
                          });
                          setRadioValues(e.target.value);
                        }
                      }}
                    >
                      {courseStatus.map((course) => (
                        <FormControlLabel
                          selected={course.value === values.status}
                          className={classes.radioLabelFluid}
                          classes={{
                            root: classes.radioLabelControl,
                            label: classes.radioLabel,
                          }}
                          key={course.value}
                          label={course.label}
                          labelPlacement="start"
                          value={course.value}
                          control={
                            <Radio
                              classes={{
                                checked:
                                  classes[
                                    `${useDefineColor(universityColor)}radio`
                                  ],
                              }}
                              color="default"
                            />
                          }
                        />
                      ))}
                    </RadioGroup>

                    {errors.status && touched.status ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.status}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </ListItem>

                {/* Finished At */}
                {values.isFinished && (
                  <ListItem className={classes.listItem}>
                    <DateTimePicker
                      className={
                        (classes.textFieldDatePicker, classes.inputField)
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        FormLabelClasses: {
                          focused: classes.inputField,
                        },
                      }}
                      val
                      variant="filled"
                      name="endedAt"
                      id="endedAt"
                      type="date"
                      label="Data de conclusão"
                      placeholder={`Ex: ${format(new Date(), 'dd/MM/yyyy')}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      customInput={
                        <MaskedInput
                          value={
                            isAttractionCompletion &&
                            attractionInfo.type === 'event'
                              ? initialValues.endedAt
                              : values.endedAt
                          }
                          placeholder="dd/MM/yyyy"
                          mask="11/11/1111"
                          maskstring="dd/mm/yyyy"
                        />
                      }
                      value={
                        isAttractionCompletion &&
                        attractionInfo.type === 'event'
                          ? initialValues.endedAt
                          : values.endedAt
                      }
                      error={
                        errors.endedAt && touched.endedAt && errors.endedAt
                      }
                    />
                    {errors.endedAt && touched.endedAt ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.endedAt}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                )}
                <div
                  style={{ width: '100%' }}
                  className={
                    errors.pdfAndDescription ? classes.inputError : null
                  }
                >
                  <ListItem className={classes.listItem}>
                    <h5
                      className={classes.listTitle}
                      style={{ color: '#4F4F4F', fontWeight: 400 }}
                    >
                      Disserte sobre o conteúdo e conhecimentos adquiridos
                    </h5>

                    <HtmlEditor
                      changeValue={(value) => {
                        setFieldValue('description', value);
                      }}
                      getCharCount={(value) => {
                        setCharCount(value);
                        if (value > 1500)
                          setFieldError(
                            'description',
                            'Limite máximo de 1500 caracteres.'
                          );
                        setFieldTouched('description', value);
                      }}
                      loadValue={extraCourse && extraCourse.description}
                      errors={errors.description}
                      touched={touched.description}
                      isDecrement
                      limitCharCount={1500}
                    />

                    <FastField
                      name="description"
                      id="description"
                      type="hidden"
                      validate={validateDescription}
                    />
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <PDFUpload
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      hasPDFChosen={initialValues.pdfFile}
                      classes={classes}
                    >
                      <div
                        className={errors.pdfFile ? classes.inputError : null}
                      >
                        <PublishIcon /> <span>Upload do Certificado PDF</span>
                      </div>
                      {errors.pdfFile ? (
                        <FormHelperText className={classes.helperText}>
                          {errors.pdfFile}
                        </FormHelperText>
                      ) : null}
                    </PDFUpload>
                    {errors.pdfAndDescription ? (
                      <FormHelperText className={classes.helperText}>
                        {errors.pdfAndDescription}
                      </FormHelperText>
                    ) : null}
                  </ListItem>
                </div>
                {isEdit && filteredAnalysis.length > 0 ? (
                  <Fragment>
                    <ListItem>
                      <h4 style={{ color: '#4F4F4F', fontWeight: 400 }}>
                        Feedbacks Anteriores
                      </h4>
                    </ListItem>
                    <FeedbackCard analysisRequest={filteredAnalysis} />
                  </Fragment>
                ) : null}

                <ListItem
                  className={classes.listItem}
                  style={{ alignItems: 'flex-start' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedRequestAnalyze}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setcheckedRequestAnalyze(true);
                          } else {
                            setcheckedRequestAnalyze(false);
                          }
                        }}
                        name="requestAnalyze"
                        id="requestAnalyze"
                        disabled={
                          isEdit
                            ? handleCheckAnalysisAvailability(errors, values) ||
                              (!extraCourse.available_analysis &&
                                (values.status.value === 'enrolled' ||
                                  values.status.value === 'abandoned'))
                            : handleCheckAnalysisAvailability(errors, values) &&
                              (values.status.value === 'enrolled' ||
                                values.status.value === 'abandoned')
                        }
                        classes={{
                          checked:
                            classes[
                              `${useDefineColor(universityColor)}checkBox`
                            ],
                        }}
                      />
                    }
                    label={
                      <h4 style={{ fontWeight: 400, color: '#afafaf' }}>
                        Solicitar análise de atividade extracurricular
                      </h4>
                    }
                  />
                  <p>
                    Para realizar essa solicitação é necessário fazer o{' '}
                    <strong>upload do certificado em PDF e/ou dissertar</strong>{' '}
                    sobre a Formação/Capacitação realizada e possuir o status{' '}
                    <strong>Concluído</strong>, além dos demais campos
                    obrigatórios sinalizados com o <b>*</b>.
                  </p>
                </ListItem>
                {isEdit && extraCourse && extraCourse.updated_at ? (
                  <ListItem
                    className={classes.listItem}
                    style={{ alignItems: 'flex-end' }}
                  >
                    <p>
                      <small>
                        Última Edição:{' '}
                        {format(new Date(extraCourse.updated_at), 'dd/MM/yyyy')}
                      </small>
                    </p>
                  </ListItem>
                ) : null}

                {/* Save Button */}
                <ListItem className={classes.listItem}>
                  <Button
                    disabled={isSubmitting}
                    color={`${useDefineColor(universityColor)}Button`}
                    type="submit"
                    className={classes.saveButton}
                    round
                  >
                    Salvar
                  </Button>
                </ListItem>
              </List>
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
}

ExtraCoursesForm.propTypes = {
  informConclusion: func,
  concludeAttraction: func,
  classes: shape.isRequired,
  closeModal: func.isRequired,
  updateExtraCourseState: func.isRequired,
  handleIsLoadingState: func.isRequired,
  openSnackbar: func.isRequired,
  isEdit: bool,
  visibleExtraCourses: arrayOf(shape).isRequired,
  handleVisibleExtraCourses: func.isRequired,
  setVisibleCourses: func.isRequired,
  extraCourse: shape,
  isAttractionCompletion: bool,
  attractionInfo: shape({
    name: string,
    competences_specialities: arrayOf({
      label: string,
      value: number,
    }),
    estimated_hours: number,
  }),
  metadata: shape.isRequired,
};

ExtraCoursesForm.defaultProps = {
  informConclusion: undefined,
  isAttractionCompletion: false,
  isEdit: false,
  extraCourse: null,
  attractionInfo: null,
  concludeAttraction: undefined,
};

export default withStyles(ProfessionalExperienceFormStyles)(ExtraCoursesForm);
