import { useState, useContext, useRef, useEffect } from 'react';

import {
  Container,
  Title,
  Header,
  Subtitle,
  WhiteBox,
  FilterContainer,
  InviteCompanyButton,
} from '../../Styles/BaseComponent';

import GenericInternalTab from 'components/GenericInternalTab';
import { CompaniesManagementContext } from '../../../Context/CompaniesManagementContext';

import PreRegisteredTab from './InternalTabs/PreRegisteredTab';
import InvitationsSentTab from './InternalTabs/InvitationsSentTab';
import AcceptedInvitationsTab from './InternalTabs/AcceptedInvitationsTab';
import CanceledInvitationTab from './InternalTabs/CanceledInvitationsTab';

import { useIcons } from 'views/StaffPages/CompaniesManagement/Hooks/useIcons';

import GenericModalForm from '../../UIComponents/Modals/GenericModalForm';
import CompanyProfileModal from '../../UIComponents/Modals/CompanyProfileModal';
import { FilterInvitationSent } from '../../UIComponents/FilterCompaniesManagement/FilterInvitationSent';
import CompanyPreRegistrationForm from '../../UIComponents/CompanyPreRegistrationForm';

import { getCitiesOptions } from 'views/StaffPages/CompaniesManagement/Services';
import { formatNumber } from 'utils/formatNumber';

export default function InvitationsSent(props) {
  const {
    activeTab,
    countTabInvitesCreated,
    countTabInvitesSent,
    countTabInvitesAnswered,
    countTabInvitesCanceled,
    getCountTabs,
    setCountTabInviteCreated,
    setCountTabInviteSent,
    setCountTabInviteAnswered,
    setCountTabInviteCanceled,
  } = props;

  const { RiAddLine } = useIcons();

  const {
    openModalForm,
    setOpenModalForm,
    limit,
    setLimit,
    setCurrentPage,
    setUsingModalUpload,
  } = useContext(CompaniesManagementContext);
  const [openModalConnectedCompanies, setOpenModalConnectedCompanies] =
    useState(false);
  const [openModalViewStageData, setOpenModalViewStageData] = useState(false);

  const [filterState, setFilterState] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const [queryFilter, setQueryFilter] = useState(null);

  const [details, setDetails] = useState(null);

  const [documents, setDocuments] = useState([]);
  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);

  const [isFetchingCities, setIsFetchingCities] = useState(false);

  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState();
  const [activeInternalTab, setActiveInternalTab] = useState(0);

  const invitationSentDiv = useRef();

  const scrollToTopDiv = () => {
    if (invitationSentDiv) {
      invitationSentDiv.current.scrollIntoView();
    }
  };

  const handleTabChange = (_, value) => {
    setCurrentPage(1);
    setActiveInternalTab(value);
  };

  async function handleChangeStates(states) {
    if (states && states.length > 0) {
      setIsFetchingCities(true);
      const data = await getCitiesOptions(states, tab);

      if (filterState['cities']) {
        const newFilterState = { ...filterState };
        const responseCitiesValues = data.cities.map((city) => city.value);
        const newCities = newFilterState.cities.filter((city) =>
          responseCitiesValues.includes(city)
        );
        if (newCities.length > 0) {
          newFilterState.cities = newCities;
        } else {
          delete newFilterState.cities;
        }
        newFilterState.states = states;
        setFilterState(newFilterState);
      }
      setFilterOptions((prevState) => {
        return {
          ...prevState,
          cities: data.cities,
        };
      });
      setIsFetchingCities(false);
    } else {
      setFilterState((prevState) => {
        const newFilterState = { ...prevState };
        delete newFilterState.states;
        delete newFilterState.cities;
        return newFilterState;
      });
    }
  }

  const formInviteCompany = () => {
    setOpenModalForm(true);
  };

  useEffect(() => {
    setQueryFilter(null);
  }, [activeInternalTab, queryFilter]);

  useEffect(() => {
    setLimit(20);
  }, [activeInternalTab]);

  const Tabs = [
    {
      activeTab: 0,
      component: (
        <PreRegisteredTab
          activeInternalTab={activeInternalTab}
          setFilterOptions={setFilterOptions}
          queryFilter={queryFilter}
          getCountTabs={getCountTabs}
          setQueryFilter={setQueryFilter}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          setLoading={setLoading}
          setDocuments={setDocuments}
          documents={documents}
          setTab={setTab}
          setFilterState={setFilterState}
          scrollToTopDiv={scrollToTopDiv}
        />
      ),
    },
    {
      activeTab: 1,
      component: (
        <InvitationsSentTab
          activeInternalTab={activeInternalTab}
          setFilterOptions={setFilterOptions}
          queryFilter={queryFilter}
          getCountTabs={getCountTabs}
          setQueryFilter={setQueryFilter}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          setLoading={setLoading}
          setDocuments={setDocuments}
          documents={documents}
          setTab={setTab}
          setFilterState={setFilterState}
          scrollToTopDiv={scrollToTopDiv}
        />
      ),
    },
    {
      activeTab: 2,
      component: (
        <AcceptedInvitationsTab
          activeInternalTab={activeInternalTab}
          setDetails={setDetails}
          queryFilter={queryFilter}
          setFilterOptions={setFilterOptions}
          getCountTabs={getCountTabs}
          setQueryFilter={setQueryFilter}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          setLoading={setLoading}
          setDocuments={setDocuments}
          documents={documents}
          setTab={setTab}
          setFilterState={setFilterState}
          scrollToTopDiv={scrollToTopDiv}
        />
      ),
    },
    {
      activeTab: 3,
      component: (
        <CanceledInvitationTab
          activeInternalTab={activeInternalTab}
          setFilterOptions={setFilterOptions}
          getCountTabs={getCountTabs}
          setQueryFilter={setQueryFilter}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          setLoading={setLoading}
          setDocuments={setDocuments}
          documents={documents}
          setTab={setTab}
          queryFilter={queryFilter}
          setFilterState={setFilterState}
          scrollToTopDiv={scrollToTopDiv}
        />
      ),
    },
  ];

  const renderPrincipalTabs = (step) => {
    const returnTabs = Tabs.find((tab) => tab.activeTab === step);
    return returnTabs.component;
  };

  return (
    <Container>
      <Title>Convites enviados</Title>
      <Header>
        <Subtitle>
          Confira aqui as empresas pré-cadastradas, os convites que foram
          enviados por você e seus respectivos status.
        </Subtitle>
        <FilterContainer>
          <FilterInvitationSent
            activeTab={activeTab}
            filterOptions={filterOptions}
            filterState={filterState}
            setFilterState={setFilterState}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            activeInternalTab={activeInternalTab}
            setQueryFilter={setQueryFilter}
            setLimit={setLimit}
            isFetchingCities={isFetchingCities}
            handleChangeStates={handleChangeStates}
            loading={loading}
            getCountTabs={getCountTabs}
            setCountTabInviteCreated={setCountTabInviteCreated}
            setCountTabInviteSent={setCountTabInviteSent}
            setCountTabInviteAnswered={setCountTabInviteAnswered}
            setCountTabInviteCanceled={setCountTabInviteCanceled}
            limit={limit}
            setCurrentPage={setCurrentPage}
          />
        </FilterContainer>

        <InviteCompanyButton
          marginRight={Object.keys(filterState).length > 0}
          onClick={formInviteCompany}
        >
          <div className="base-icon">
            <RiAddLine color="#009291" size={18} />
          </div>
          Convidar empresa
        </InviteCompanyButton>
      </Header>
      <WhiteBox ref={invitationSentDiv}>
        <GenericInternalTab
          activeTab={activeInternalTab}
          zIndex={900}
          handleTabChange={handleTabChange}
          tabs={[
            `Empresas pré-cadastradas (${formatNumber(
              countTabInvitesCreated
            )})`,
            `Convites enviados (${formatNumber(countTabInvitesSent)})`,
            `Convites aceitos (${formatNumber(countTabInvitesAnswered)})`,
            `Convites cancelados (${formatNumber(countTabInvitesCanceled)})`,
          ]}
        />
      </WhiteBox>
      {renderPrincipalTabs(activeInternalTab)}
      {openModalForm && (
        <GenericModalForm
          openModal={openModalForm}
          buttonCloseModal={false}
          zIndex={9999999}
          setOpenModalForm={setOpenModalForm}
          usingPreviewPdf={usingPreviewPdf}
        >
          <CompanyPreRegistrationForm
            isCreated={true}
            documents={documents}
            getCountTabs={getCountTabs}
            setOpenModalForm={setOpenModalForm}
            setUsingPreviewPdf={setUsingPreviewPdf}
          />
        </GenericModalForm>
      )}

      {openModalConnectedCompanies && (
        <CompanyProfileModal
          isOpen={openModalConnectedCompanies}
          details={details}
          isEdit={false}
          documents={documents}
          setDocuments={setDocuments}
          openModalViewStageData={openModalViewStageData}
          setOpenModalConnectedCompanies={setOpenModalConnectedCompanies}
          setOpenModalViewStageData={setOpenModalViewStageData}
          openModalUpload={setUsingModalUpload}
        />
      )}
    </Container>
  );
}
