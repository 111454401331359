import { authenticatedAPI } from 'services/api';

const getApplicationCoverLetter = async (id) => {
  const response = authenticatedAPI
    .get(`api/internal/university/students-docs/cover-letter/${id}`)
    .then((res) => res.data)
    .catch((error) => error);

  return response;
};

const getCoverLetterForDownload = async (queryString) => {
  const response = authenticatedAPI
    .get(`/api/internal/university/students/cover-letters?${queryString}`)
    .then((res) => res.data)
    .catch((error) => error);
  return response;
};

export { getApplicationCoverLetter, getCoverLetterForDownload };
