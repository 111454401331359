import React, { Fragment, useState, useEffect, useContext } from 'react';
import { func, string, shape } from 'prop-types';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  IconButton,
  Grid,
  TextField,
  List,
  ListItem,
  Button,
} from '@material-ui/core';
import IcoEdit from 'assets/img/icons/icoEdit.svg';
import ViewsHeader from 'components/ViewsHeader/ViewsHeader';
import headerBackgroundImage from 'assets/img/company-robot.png';
import GridContainer from 'components/Grid/GridContainer';
import DefaultAvatar from 'assets/img/companyAvatarDefault.png';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import CompanyRegistrationForm from 'views/Company/components/CompanyRegistrationForm/CompanyRegistrationForm';
import BaseLayoutContext from 'contexts/base-layout';

import { Check, Business } from '@material-ui/icons';

import { getCompanyLogged } from 'services/companies';
import SendEmail from '../RecoverPassword/SendEmail';
import EditAvatarIcon from 'assets/ComponentIcons/EditAvatarIcon';
import { CompanyDetail } from 'components/CompanyDetail/CompanyDetail';

const CompanyConfig = (props) => {
  const {
    classes,
    handleIsLoadingState,
    name: componentTitle,
    openModalWithContent,
  } = props;
  const [company, setCompany] = useState({});
  const [companyId, setCompanyId] = useState(null);
  async function getCompany() {
    const response = await getCompanyLogged();
    if (!response.message) {
      setCompany(response);
      handleIsLoadingState(false);
    } else {
      handleIsLoadingState(false);
    }
  }
  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (props.location.state && props.location.state.updateConfig) {
      if (Object.keys(company).length > 0) {
        openModalWithContent(
          <BaseLayoutContext.Consumer>
            {(values) => {
              return (
                <CompanyRegistrationForm
                  {...values}
                  openModalWithContent={openModalWithContent}
                  universityColor={universityColor}
                  companyUpdate={company}
                />
              );
            }}
          </BaseLayoutContext.Consumer>,
          `Editar configurações`
        );
      }
    }
  }, [company]);

  const { universityColor } = useContext(BaseLayoutContext);

  const openModal = (e) => {
    e && e.preventDefault();
    setCompanyId(company.id);
  };
  const closeModal = () => {
    setCompanyId(null);
  };

  return (
    <Fragment>
      {companyId !== null && (
        <CompanyDetail id={companyId} closeModal={closeModal} />
      )}
      <ViewsHeader
        title={componentTitle}
        backgroundImage={headerBackgroundImage}
      />
      <GridContainer justify="center" spacing={24} style={{ padding: 0 }} wrap>
        <Grid item xs={9}>
          <Card>
            <CardHeader
              className={classes.cardHeader}
              style={{ margin: '2px 17px -34px' }}
            >
              <h3 className={classes.cardTitle}>
                <b>Sobre a empresa</b>
              </h3>
              <IconButton
                style={{ right: 12, top: 5 }}
                className={classes.buttonHelpIcon}
                onClick={() => {
                  openModalWithContent(
                    <BaseLayoutContext.Consumer>
                      {(values) => {
                        return (
                          <CompanyRegistrationForm
                            {...values}
                            openModalWithContent={openModalWithContent}
                            universityColor={universityColor}
                            companyUpdate={company}
                          />
                        );
                      }}
                    </BaseLayoutContext.Consumer>,

                    `Editar configurações`
                  );
                }}
              >
                <EditAvatarIcon color={'#009291'} />
              </IconButton>
            </CardHeader>

            <CardBody style={{ padding: 0 }}>
              <GridContainer
                style={{ padding: 0, margin: 0 }}
                spacing={16}
                wrap
              >
                <Grid item xs={12} md={8} style={{ marginBottom: '-27px' }}>
                  <List className={classes.list}>
                    <ListItem>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="companyAlias"
                        id="companyAlias"
                        type="text"
                        label="Nome fantasia"
                        value={company.name}
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="companyName"
                        id="companyName"
                        type="text"
                        label="Razão Social"
                        value={company.legal_name}
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="cnpj"
                        id="cnpj"
                        type="text"
                        label="CNPJ"
                        value={company.organization_number}
                      />
                    </ListItem>
                    <ListItem>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="website"
                        id="website"
                        type="text"
                        label="Web Site"
                        placeholder="www.workalove.com"
                        value={company.website || 'Não informado'}
                      />
                    </ListItem>
                    <ListItem>
                      <div
                        className={classes.chipOptions}
                        style={{ padding: '5px 8px' }}
                      >
                        <div className={classes.optionsLabel}>
                          <label>Cor principal da marca</label>
                        </div>
                        {company.company_color && (
                          <div className={classes.rowColorCompanyProfile}>
                            <div
                              style={{ backgroundColor: company.company_color }}
                              className={classes.squareCompanyColor}
                            />
                            <p
                              className={classes.inputField}
                              style={{ margin: '0', alignSelf: 'center' }}
                            >
                              {company.company_color}
                            </p>
                          </div>
                        )}
                      </div>
                    </ListItem>
                    <ListItem>
                      <Button
                        style={{ color: '#009291' }}
                        onClick={() => {
                          openModalWithContent(
                            <SendEmail
                              isCompanyConfig
                              companyUsername={company.staff.email}
                            />
                          );
                        }}
                      >
                        TROCAR SENHA
                      </Button>
                    </ListItem>
                  </List>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={12}
                  style={{
                    padding: '27px 9px',
                  }}
                >
                  <di
                    style={{
                      gap: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <img
                      src={company.logo ? company.logo : DefaultAvatar}
                      alt="Avatar"
                      style={{
                        border: '1px dashed',
                        borderRadius: '9px',
                        width: '90%',
                      }}
                    />
                    <Button
                      style={{
                        color: '#fff',
                        backgroundColor: '#009291',
                        width: '90%',
                      }}
                      onClick={(e) => openModal(e)}
                    >
                      Visualizar prévia do perfil
                    </Button>
                  </di>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <div className={classes.chipOptions}>
                        <div className={classes.optionsLabel}>
                          <label>Tipo de atividade</label>
                        </div>
                        {company.activities_sectors?.length > 0 ? (
                          company.activities_sectors.map((element) => (
                            <Chip
                              key={element.value}
                              label={element.label}
                              className={classes.chip}
                              variant="outlined"
                            />
                          ))
                        ) : (
                          <Chip
                            label="Não informado"
                            className={classes.chip}
                            variant="outlined"
                          />
                        )}
                      </div>
                    </ListItem>
                    <ListItem>
                      <div className={classes.chipOptions}>
                        <div className={classes.optionsLabel}>
                          <label>Tipo da empresa</label>
                        </div>
                        {company.type?.label ? (
                          <Chip
                            label={company.type.label}
                            className={classes.chip}
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            label="Não informado"
                            className={classes.chip}
                            variant="outlined"
                          />
                        )}
                      </div>
                    </ListItem>
                    <ListItem>
                      <div className={classes.chipOptions}>
                        <div className={classes.optionsLabel}>
                          <label>Porte da empresa</label>
                        </div>
                        {company.size?.label ? (
                          <Chip
                            label={company.size.label}
                            className={classes.chip}
                            variant="outlined"
                          />
                        ) : (
                          <Chip
                            label="Não informado"
                            className={classes.chip}
                            variant="outlined"
                          />
                        )}
                      </div>
                    </ListItem>

                    <ListItem>
                      <div className={classes.chipOptions}>
                        <div className={classes.optionsLabel}>
                          <label>Áreas com mais contratação</label>
                        </div>
                        {company?.macro_areas?.length > 0 ? (
                          company?.macro_areas?.map((element) => (
                            <Chip
                              key={element.value}
                              label={element.label}
                              className={classes.chip}
                              variant="outlined"
                            />
                          ))
                        ) : (
                          <Chip
                            label="Não informado"
                            className={classes.chip}
                            variant="outlined"
                          />
                        )}
                      </div>
                    </ListItem>

                    <ListItem>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="description"
                        id="description"
                        type="text"
                        multiline
                        label="Descrição da empresa"
                        value={
                          company.description?.replace(/<\/?[^>]+(>|$)/g, '') ||
                          'Não informado'
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} style={{ padding: '0px 34px' }}>
                  <Divider variant="middle" />
                </Grid>
                {/* ENDEREÇO */}
                <CardHeader
                  className={classes.cardHeader}
                  style={{ margin: '2px 17px -34px' }}
                >
                  <h3 className={classes.cardTitle}>
                    <b>Endereço</b>
                  </h3>
                </CardHeader>
                {company.address && (
                  <GridContainer spacing={24} style={{ margin: '21px 0 0' }}>
                    <Grid item xs={12} md={4}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="cep"
                        id="cep"
                        type="text"
                        multiline
                        label="CEP"
                        value={company.address.zip_code}
                      />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="address"
                        id="address"
                        type="text"
                        label="Rua/Avenida"
                        value={company.address.address}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="number"
                        id="number"
                        type="text"
                        label="Número"
                        value={company.address.number}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="complement"
                        id="complement"
                        type="text"
                        label="Complemento"
                        value={company.address.complement || 'Não informado'}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="district"
                        id="district"
                        type="text"
                        label="Bairro"
                        value={company.address.district}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="state"
                        id="state"
                        type="text"
                        label="UF"
                        value={company.address.state}
                      />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="city"
                        id="city"
                        type="text"
                        label="Cidade"
                        value={company.address.city}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  </GridContainer>
                )}
                {/* Responsável */}
                <CardHeader
                  className={classes.cardHeader}
                  style={{ margin: '2px 17px -34px' }}
                >
                  <h3 className={classes.cardTitle}>
                    <b>Responsável</b>
                  </h3>
                </CardHeader>
                {company.staff && (
                  <GridContainer spacing={24} style={{ margin: '21px 0 0' }}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="staffName"
                        id="staffName"
                        type="text"
                        multiline
                        label="Nome do Responsável"
                        value={company.staff.name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="staffRole"
                        id="staffRole"
                        type="text"
                        label="Cargo"
                        value={company.staff.role.label}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="staffEmail"
                        id="staffEmail"
                        type="text"
                        label="E-mail"
                        value={company.staff.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className={classes.inputField}
                        InputProps={{
                          readOnly: true,
                          className: classes.input,
                          style: { backgroundColor: '#f5f5f5' },
                        }}
                        InputLabelProps={{
                          shrink: true,
                          FormLabelClasses: {
                            focused: classes.inputField,
                          },
                        }}
                        variant="filled"
                        name="staffContact"
                        id="staffContact"
                        type="text"
                        label="Celular/Telefone Fixo"
                        value={company.staff.phone}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant="middle" />
                    </Grid>
                  </GridContainer>
                )}
                {/* Negócio */}
                <CardHeader
                  className={classes.cardHeader}
                  style={{ margin: '2px 17px -34px' }}
                >
                  <h3 className={classes.cardTitle}>
                    <b>Sobre seu negócio</b>
                  </h3>
                </CardHeader>
                <GridContainer spacing={24} style={{ margin: '21px 0 18px' }}>
                  <Grid item xs={12}>
                    <div className={classes.chipOptions}>
                      <div className={classes.optionsLabel}>
                        <label>Áreas de interesse</label>
                      </div>
                      {company.interests?.length > 0 ? (
                        company.interests.map((element) => (
                          <Chip
                            key={element.value}
                            label={element.label}
                            onDelete={() => {}}
                            className={classes.chip}
                            deleteIcon={<Business />}
                            variant="outlined"
                          />
                        ))
                      ) : (
                        <Chip
                          label="Não informado"
                          className={classes.chip}
                          variant="outlined"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.chipOptions}>
                      <div className={classes.optionsLabel}>
                        <label>Competências da empresa</label>
                      </div>
                      {company.skills?.length > 0 ? (
                        company.skills.map((element) => (
                          <Chip
                            key={element.value}
                            label={element.label}
                            onDelete={() => {}}
                            className={classes.chip}
                            deleteIcon={<Check />}
                            variant="outlined"
                          />
                        ))
                      ) : (
                        <Chip
                          label="Não informado"
                          className={classes.chip}
                          variant="outlined"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.chipOptions}>
                      <div className={classes.optionsLabel}>
                        <label>Valores</label>
                      </div>
                      {company.values?.length > 0 ? (
                        company.values.map((element) => (
                          <Chip
                            key={element.value}
                            label={element.label}
                            onDelete={() => {}}
                            className={classes.chip}
                            deleteIcon={<Check />}
                            variant="outlined"
                          />
                        ))
                      ) : (
                        <Chip
                          label="Não informado"
                          className={classes.chip}
                          variant="outlined"
                        />
                      )}
                    </div>
                  </Grid>
                </GridContainer>
              </GridContainer>
            </CardBody>
          </Card>
        </Grid>
      </GridContainer>
    </Fragment>
  );
};

CompanyConfig.propTypes = {
  classes: shape({}).isRequired,
  handleIsLoadingState: func.isRequired,
  name: string.isRequired,
  openModalWithContent: func.isRequired,
};

export default withStyles(dashboardStyle)(CompanyConfig);
