import React from 'react';
import {
  Container,
  StudentInfo,
  Information,
  InformationAvatar,
  Divider
} from './styled';

export function SkeletonStudentData() {
  const numContainers = 4;

  return (
    <StudentInfo>
      <InformationAvatar />

      <div>
        {[...Array(numContainers)].map((_, index) => (
          <Information key={index} />
        ))}
      </div>

    <Divider />

      <div>
        {[...Array(numContainers)].map((_, index) => (
          <Information key={index} />
        ))}
      </div>

    </StudentInfo>
  );
}
