import styled from "styled-components";
import ReactSelect from 'react-select';

export const ContainerLabelInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  position: relative;
  margin-top: 8px;
`;

export const UnInputLabel = styled.label`
  color: #6d6d6d;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: 15px;
  margin-left: 2px;
  text-align: start;
`;

export const SelectValidate = styled(ReactSelect)`

  .react-select-container {
    width: 100%;
  }

  .react-select__option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
`