import React, { useState, useEffect, useContext } from 'react';
import { ModalContainer, OutlineButton } from './style';
import { Loading } from '../../../../style';
import CustomButton from 'components/CustomButtons/Button.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import { createIndication } from '../../../../services/IndicationStudent.service';
import { ModalsContext } from 'components/modals/contexts/modalContext';
import BaseLayoutContext from 'contexts/base-layout';

export default function ConfirmIndicationModal({
  isOpen = false,
  closeModalFunc,
  student,
  job,
  studentStatus = 'default',
}) {
  const statusOptions = {
    cannotBeIndicated: 'cannotBeIndicated',
    doenstAccessPlatform: 'doenstAccessPlatform',
    dontHaveCurriculum: 'dontHaveCurriculum',
    default: 'default',
  };
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalText, setModalText] = useState(studentStatus);
  const { getModals } = useContext(ModalsContext);
  const { openSnackbar } = useContext(BaseLayoutContext);

  useEffect(() => {
    setModalText(studentStatus);
  }, [studentStatus]);

  const defineModalText = () => {
    const texts = {
      cannotBeIndicated:
        'No momento, este estudante optou por não ser indicado à vagas pela instituição de ensino.',
      doenstAccessPlatform:
        'Você está indicando um estudante que ainda não acessou a plataforma. ' +
        'Enviaremos um e-mail notificando o estudante da sua indicação.',
      dontHaveCurriculum:
        'Você está indicando um estudante que não possui currículo. ' +
        'Por esse motivo, o perfil do estudante não ficará disponível imediatamente para a empresa. ' +
        'Enviaremos um e-mail notificando o estudante da sua indicação.',
      default: (
        <>
          Tem certeza de que deseja indicar {student.name} para esta vaga?
          <br />
          <br />
          <br />
          Esta ação não poderá ser desfeita.
        </>
      ),
    };
    return texts[modalText] ?? texts['default'];
  };

  const handleCheckbox = (event) => {
    setAcceptTerms(event.target.checked);
  };

  const nextStep = () => {
    setModalText(statusOptions.default);
  };

  const confirmIndication = async () => {
    try {
      setLoading(true);
      await createIndication({ student: student.id, job_offer: job.id });
      openSnackbar('Indicado com sucesso');
      setLoading(false);
      const modals = getModals();
      modals.map((modal) => modal.closeModal());
    } catch (error) {
      setLoading(false);
      openSnackbar(
        'Não foi possivel indicar o estudante, tente novamente!',
        true
      );
    }
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      bubbleText={
        modalText === statusOptions.cannotBeIndicated ? 'Ops!!' : 'Atenção!'
      }
      widthBubble="123px"
      closeModalFunc={closeModalFunc}
    >
      <p className="message">{defineModalText()}</p>

      {modalText !== statusOptions.default &&
        modalText !== statusOptions.cannotBeIndicated && (
          <div className="acceptTerms">
            <Checkbox
              onChange={handleCheckbox}
              classes={{
                root: 'customCheck',
                checked: 'customChecked',
              }}
            />
            <span>Estou ciente e quero prosseguir.</span>
          </div>
        )}
      {modalText === statusOptions.default ? (
        <div className="flex">
          <OutlineButton onClick={closeModalFunc} disabled={loading}>
            NÃO
          </OutlineButton>
          <CustomButton
            onClick={confirmIndication}
            fullWidth
            disabled={loading}
          >
            {loading ? <Loading /> : 'SIM, QUERO INDICAR'}
          </CustomButton>
        </div>
      ) : modalText === statusOptions.cannotBeIndicated ? (
        <CustomButton onClick={closeModalFunc} fullWidth>
          OK, ENTENDI
        </CustomButton>
      ) : (
        <CustomButton
          onClick={nextStep}
          className={!acceptTerms && 'customDisabled'}
          disabled={!acceptTerms}
          fullWidth
        >
          Avançar
        </CustomButton>
      )}
    </ModalContainer>
  );
}
