import styled from 'styled-components';

const getBackgroundColor = (props) => {
  return props.universityColor ? props.universityColor : '#009291';
};

const getLinearBackground = (props) => {
  return props.levelThree
    ? `linear-gradient(
    0deg,
    rgba(255, 245, 218, 1) 0%,
    rgba(251, 209, 46, 1) 50%,
    rgba(244, 121, 41, 1) 100%
    )`
    : '#FFF';
};

export const ContainerButtonsProfile = styled.div`
  position: absolute;
  margin-top: -40px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    width: 100vw;
    display: flex;
    margin-top: -130px;
    align-items: center;
    justify-content: center;
  }
`;

export const ContentInfos = styled.div`
  margin-top: 40px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    margin-top: 190px;
  }
`;

export const ContainerNameAndParagraph = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
`;

export const CircleIcon = styled.div`
  background-color: #fff;
  width: 48px;
  height: 48px;
  padding-left: 3px;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 0;
  right: 10px;
`;

export const ContainerEditIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ButtonEditIcon = styled.button`
  background-color: #fff;
  width: 48px;
  height: 48px;
  cursor: pointer;

  border-radius: 50%;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  margin: ${(props) =>
    props.levelThree ? '0 0 145px 145px' : '145px 0 0 145px'};
`;

export const ButtonGamification = styled.button`
  background-color: #fff;
  width: 48px;
  height: 48px;
  cursor: unset;

  border-radius: 50%;
  border: none;

  display: ${(props) => (props.levelThree ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;

  position: absolute;
  margin: 190px 0 0 80px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    margin: 200px 0 0 0;
  }
`;

export const ContainerBackground = styled.div`
  height: auto;
  position: relative;
  top: auto;
  width: 100%;
`;

export const BackgroundColor = styled.div`
  height: 255px;
  width: 100%;
  background-color: ${getBackgroundColor};
`;

export const ContainerAvatar = styled.div``;

export const ContainerButtonAndInformations = styled.div`
  position: relative;
  z-index: 10;
  margin: -106px 0 0 50px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    margin: -106px 0 0 0;
  }
`;

export const ButtonImage = styled.button`
  position: absolute;

  width: ${(props) => (props.levelThree ? '214px' : '206px')};
  height: ${(props) => (props.levelThree ? '214px' : '206px')};

  background: ${getLinearBackground};

  border: none;
  border-radius: 50%;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
`;

export const ImageIcon = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
`;

export const StudentName = styled.h1`
  display: flex;
  font-weight: 700;
  font-size: 2.5em;
  margin: 0 8px 0 0;
  color: #fff;
  white-space: nowrap;
  transition: 0.5s;

  @media (max-width: 1070px) {
    transition: 0.5s;
    font-size: 2em;
  }

  @media (max-width: 700px) {
    white-space: break-spaces;
    text-align: center;
    transition: 0.5s;
    color: #606062;
    width: 100%;
    justify-content: center;
  }
`;

export const InformationsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 230px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    flex-direction: column;
    margin: 20px 0;
    justify-content: center;
  }
`;

export const ContainerInfos = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: auto;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    align-items: center;
  }
`;

export const Paragraph = styled.p`
  font-size: 1em;
  font-weight: 400;
  color: #fff;
  margin: 0;
  white-space: nowrap;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    color: #606062;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NameAndParagraph = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
`;

export const FlexParagraph = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  white-space: nowrap;
  transition: 0.5s;

  @media (max-width: 430px) {
    transition: 0.5s;
    flex-direction: column;
  }
`;

export const ContainerButtonEditInfos = styled.div`
  display: flex;
  justify-content: start;
  margin: 0 0 40px 20px;
  transition: 0.5s;

  @media (min-width: 700px) and (max-width: 1070px) {
    transition: 0.5s;
    margin: 0 0 40px 0;
  }

  @media (max-width: 700px) {
    transition: 0.5s;
    margin: 15px 0 0 0;
  }
`;

export const ContainerNameAndButton = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    width: 100%;
  }
`;

export const ButtonEditInfos = styled.button`
  align-items: center;
  text-transform: uppercase;
  gap: 7px;

  border: 2px solid #fff;
  border-radius: 5px;

  background-color: #fff;
  color: ${getBackgroundColor};
  padding: 2px 10px;

  font-weight: 500;
  font-size: 12px;
  font-family: Roboto;
  white-space: nowrap;

  cursor: pointer;

  display: flex;
  align-items: center;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    display: none;
  }

  @media (min-width: 700px) and (max-width: 1360px) {
    transition: 0.5s;
    font-size: 0;
    padding: 2px 2px 2px 8px;
  }
`;

export const ButtonMobileEditInfos = styled.button`
  align-items: center;
  text-transform: uppercase;
  gap: 7px;

  border: 2px solid #fff;
  border-radius: 5px;

  background-color: #fff;
  color: ${getBackgroundColor};
  padding: 5px 10px;

  font-weight: 500;
  font-size: 12px;
  font-family: Roboto;
  white-space: nowrap;

  cursor: pointer;

  align-items: center;
  margin-top: 15px;
  display: none;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    display: flex;
  }
`;

export const ContainerProfessionalStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
  max-width: 740px;
  margin-bottom: 10px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    flex-direction: column;
    white-space: nowrap;
    justify-content: center;
    width: auto;
    align-items: center;
  }
`;

export const ListParagraph = styled.li`
  font-size: 1em;
  font-weight: 400;
  margin-left: 20px;
  color: #fff;
  white-space: nowrap;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    color: #606062;
    width: 100%;
    justify-content: center;
    white-space: break-spaces;
    text-align: center;
  }
`;

export const ContainerBaseInfos = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
`;

export const ButtonsDiv = styled.div`
  margin: 16px 0 0 230px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    flex-direction: column;
    margin: 0;
  }

  @media (min-width: 700px) and (max-width: 1200px) {
    transition: 0.5s;
    flex-direction: column;
  }
`;

export const ButtonsStudentInfos = styled.button`
  border: 2px solid
    ${(props) => (props.disabled ? '#949494' : getBackgroundColor(props))};
  color: #fff;

  background-color: transparent;
  border-radius: 5px;

  width: auto;
  height: 48px;
  padding: 14px;

  text-transform: uppercase;
  font-weight: 750;
  font-size: 14px;
  white-space: nowrap;
  color: ${(props) => (props.disabled ? '#949494' : getBackgroundColor(props))};

  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    width: 75%;
    margin: 0 auto;
  }

  @media (max-width: 1070px) {
    transition: 0.5s;
    font-size: 12px;
  }
`;

export const ButtonImgBlocked = styled.div`
  display: ${(props) => (props.disabled ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContainerFlexButtons = styled.div`
  gap: 14px;
  display: flex;
  transition: 0.5s;

  @media (max-width: 700px) {
    transition: 0.5s;
    padding: 0 13%;
  }

  @media (max-width: 550px) {
    transition: 0.5s;
    flex-direction: column;
    width: 100%;
    padding: 0;
  }
`;

export const ContainerCurriculumButton = styled.div`
  transition: 0.5s;
  @media (min-width: 700px) and (max-width: 1200px) {
    transition: 0.5s;
    width: 100%;
  }
`;
