import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
export const HeaderTitle = styled.h4`
  color: #009291;
  font-weight: 400;
`;

export const WrapperPeriod = styled.div`
  margin-top: 40px;

  h5 {
    font-weight: 600;
    font-size: 15px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

export const WrapperLink = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;

  h5 {
    font-weight: 600;
    font-size: 15px;
  }
`;

export const SelectiveProcessWrapper = styled.div`
  margin-top: 30px;

  input {
    margin-left: -10px;
    margin-bottom: 20px;
  }

  label {
    display: flex;
  }

  h5 {
    color: #6d6d6d;
    font-size: 15px;
    font-weight: 600;
  }
`;

export const WrapperJobInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const ContainerSelectiveProcess = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
  margin-bottom: 20px;
`;
export const InputDescNewJobText = styled.div`
  label {
    font-size: 15px;
  }
`;
