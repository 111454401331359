import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 27px;

  font-weight: 700;
  font-size: 12px;
  color: #009291;
  background-color: #ffffff;

  border: 1px solid #009291;
  border-radius: 4px;

  padding: 0;
  margin-top: 12px;
  margin-bottom: 15px;
  margin-left: 5px;

  cursor: pointer;
`;
