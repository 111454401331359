import React, { Component } from 'react';
import { func } from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { getCoverLetter, saveCoverLetter } from 'services/student/coverLetter';

import HistoryEdu from 'assets/img/icons/history.png';
import Trophy from 'assets/img/icons/trophy.png';
import Star from 'assets/img/icons/star.png';

import { Loading, AbsolutWrapper, Header } from 'components/Styled';
import { Mixpanel } from 'utils/mixpanel';
import * as S from './styled';
import QuestionOne from './components/QuestionOne';
import QuestionTwo from './components/QuestionTwo';
import QuestionThree from './components/QuestionThree';
import CoverLetterContainer from '../CoverLetterContainer';
import { triggerPageView } from '../../../../../utils/analytics';

const FormSchema = Yup.object().shape({
  questionOne: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionTwo: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
  questionThree: Yup.string()
    .required('Você deve responder essa pergunta antes de enviar.')
    .min(50, 'Você deve escrever ao menos 50 caracteres.'),
});

class CoverLetterForm extends Component {
  state = {
    isEdit: false,
    loading: true,
    initialValues: this.props.coverLetter || {},
  };

  async componentDidMount() {
    const { openSnackbar, closeModal, handleLoadingState } = this.props;
    const response = await getCoverLetter();

    if (response.message) {
      openSnackbar(
        'Falha ao carregar sua carta de apresentação. Tente novamente mais tarde!',
        true
      );
      closeModal();
    } else if (response.answers) {
      this.setState({
        isEdit: true,
        initialValues: response.answers,
      });
    }

    this.setState({ loading: false });
    handleLoadingState(false);
  }

  updateCoverLetter = async (answers) => {
    const { openSnackbar, updateHasCoverLetter } = this.props;
    const response = await saveCoverLetter({ answers });

    if (response.message) {
      openSnackbar(
        'Falha ao salvar sua carta de apresentação. Tente novamente mais tarde!',
        true
      );
    } else {
      updateHasCoverLetter(true);
      openSnackbar('Carta de apresentação salva com sucesso!');
      Mixpanel.track('Gerou Carta de Apresentação');
      triggerPageView('student/carta-apresentacao/gerar');
      this.setState({ isEdit: true });
    }
  };

  openCoverLetter = () => {
    const {
      openModalWithContent,
      handleLoadingState,
      openSnackbar,
      closeModal,
      updateHasCoverLetter,
      universityColor,
    } = this.props;

    openModalWithContent(
      <CoverLetterContainer
        handleLoadingState={handleLoadingState}
        openSnackbar={openSnackbar}
        closeModal={closeModal}
        updateHasCoverLetter={updateHasCoverLetter}
        openModalWithContent={openModalWithContent}
        universityColor={universityColor}
      />
    );
  };

  render() {
    const { isEdit, loading, initialValues } = this.state;
    const { handleLoadingState, universityColor } = this.props;
    return (
      <AbsolutWrapper>
        <Header title="Carta de Apresentação" />
        <S.FromWrapper
          container
          direction="column"
          justify="center"
          alignItems="center"
          lg={8}
          md={10}
          sm={12}
        >
          <Formik
            enableReinitialize
            validationSchema={FormSchema}
            validateOnChange={false}
            validade
            initialValues={initialValues}
            onSubmit={async (values) => {
              handleLoadingState(true);
              await this.updateCoverLetter(values);
              this.openCoverLetter();
            }}
            render={({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <S.Form onSubmit={handleSubmit}>
                {/* Question 1 */}
                <S.FormItem>
                  <S.FormCard
                    iconPath={HistoryEdu}
                    cardTitleText="MINHA HISTÓRIA DE VIDA"
                    CardContent={!loading && QuestionOne}
                    isSubmitting={isSubmitting}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    emptyStateComponent={
                      <Loading universityColor={universityColor} />
                    }
                  />
                </S.FormItem>
                {/* Question 2 */}
                <S.FormItem>
                  <S.FormCard
                    iconPath={Star}
                    cardTitleText="SUAS EXPECTATIVAS RELACIONADAS A FORMAÇÃO"
                    CardContent={!loading && QuestionTwo}
                    isSubmitting={isSubmitting}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    emptyStateComponent={
                      <Loading universityColor={universityColor} />
                    }
                  />
                </S.FormItem>
                {/* Question 3 */}
                <S.FormItem>
                  <S.FormCard
                    iconPath={Trophy}
                    cardTitleText="ONDE VOCÊ QUER CHEGAR"
                    CardContent={!loading && QuestionThree}
                    isSubmitting={isSubmitting}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    emptyStateComponent={
                      <Loading universityColor={universityColor} />
                    }
                  />
                </S.FormItem>
                <S.FormItem container justify="center">
                  <S.FormButton
                    universityColor={universityColor}
                    disabled={
                      isSubmitting ||
                      Object.keys(values).length !== 3 ||
                      initialValues === values
                    }
                    type="submit"
                  >
                    {isEdit
                      ? 'Salvar carta de apresentação'
                      : 'Gerar carta de apresentação'}
                  </S.FormButton>
                </S.FormItem>
              </S.Form>
            )}
          />
        </S.FromWrapper>
      </AbsolutWrapper>
    );
  }
}

CoverLetterForm.propTypes = {
  openSnackbar: func.isRequired,
  closeModal: func.isRequired,
  universityColor: PropTypes.string,
  updateHasCoverLetter: func.isRequired,
  handleLoadingState: func.isRequired,
  openModalWithContent: func.isRequired,
};

export default CoverLetterForm;
