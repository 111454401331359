import GenericInternalTab from 'components/GenericInternalTab';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import JobsPositionDetails from 'components/JobDetails/JobDetails';
import BaseLayoutContext from 'contexts/base-layout';

import { genericAdapter } from 'adapters/genericAdapter';
import {
  ContainerCard,
  TextTitle,
  ContainerInfo,
  ContainerLogo,
  Row,
  TextRow,
  Container,
  ButtonQuickFilters,
  ContainerCards,
  ContainerQuickFilters,
  DetailJob,
} from './styles';
import { ContainerMiniLogo } from 'components/CompanyDetail/styles';
import LogoTarget from 'assets/Network/target';
import { ModalDetailChallenge } from '../ModalDetailChallenge/ModalDetailChallenge';
import GenericMessage from 'components/GenericMessage';
export function DisplayChallengesNetwork({
  challengesWithParticipation,
  challengesAvailable,
  callDataChalenges,
}) {
  const { universityColor } = useContext(BaseLayoutContext);
  const [switchButton, setSwitchButton] = useState(1);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [challengeId, setChallengeId] = useState(null);

  const generalData = useMemo(() => {
    if (switchButton === 1) {
      return challengesAvailable;
    }
    if (switchButton === 2) {
      return challengesWithParticipation;
    }
  }, [switchButton, challengesWithParticipation, challengesAvailable]);

  const typeAccessDocument = {
    access_link: 'Link de acesso',
    attraction_document: 'Documento',
    video: 'Videos sequenciais',
  };
  const openModalDetail = (challengeId) => {
    setChallengeId(challengeId);
    setShowModalDetail(true);
  };
  const closeModalDetail = () => {
    setShowModalDetail(false);
  };

  return (
    <Container>
      <ContainerQuickFilters>
        <ButtonQuickFilters
          color={universityColor}
          borderRadius={'8px 0 0 8px'}
          touched={switchButton !== 1}
          onClick={() => {
            setSwitchButton(1);
          }}
        >
          Disponíveis ({challengesAvailable.length})
        </ButtonQuickFilters>
        <ButtonQuickFilters
          color={universityColor}
          borderRadius={'0 8px 8px 0'}
          touched={switchButton !== 2}
          onClick={() => {
            setSwitchButton(2);
          }}
        >
          Finalizados ({challengesWithParticipation.length})
        </ButtonQuickFilters>
      </ContainerQuickFilters>
      <ContainerCards>
        {generalData?.length > 0
          ? generalData?.map((data, index) => (
              <ContainerCard>
                <TextTitle>
                  <b>
                    {data.name.length < 40
                      ? data.name
                      : `${data.name.substring(0, 40)}...`}
                  </b>
                </TextTitle>
                <ContainerInfo>
                  <Row>
                    <ContainerMiniLogo color={universityColor}>
                      <LogoTarget color={universityColor} />
                    </ContainerMiniLogo>
                    {typeAccessDocument[data?.typeAccess] || 'Não informado'}
                  </Row>
                </ContainerInfo>
                <DetailJob
                  color={universityColor}
                  onClick={() => {
                    openModalDetail(data.id);
                  }}
                >
                  Visualizar detalhes
                </DetailJob>
              </ContainerCard>
            ))
          : generalData?.length === 0 && (
              <GenericMessage
                title="Nenhum desafio encontrado"
                subtitle="No momento, não existem desafios disponíveis nessa seção."
                subtitleResponsible="No momento, não existem desafios disponíveis nessa seção."
              />
            )}
      </ContainerCards>
      {showModalDetail && (
        <ModalDetailChallenge
          closeModalDetail={closeModalDetail}
          challengeId={challengeId}
          showModalDetail
          finished={switchButton === 2}
          callDataChalenges={callDataChalenges}
        />
      )}
    </Container>
  );
}
