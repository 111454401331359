import styled from 'styled-components';

export const Main = styled.div`
  margin: 0 auto;

  background: #fff;
  box-shadow: 0 1px 20px 0 rgb(0 0 0 / 14%);

  width: 100%;
  max-width: 860px;

  padding: 0.9375rem 20px;
  margin-bottom: 55px;

  border-radius: 10px;
`;

export const Title = styled.h3`
  font-size: 25px;
  line-height: 1.4em;
  margin: 0;
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-weight: 400;
`;

export const TitleJobDetails = styled.h1`
  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  text-align: center;
  font-size: 35px;

  @media (max-width: 1138px) {
    font-size: 2.4em;
    margin: 60px 16px 0;
    width: auto;
  }
`;

export const DivButtonsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media screen and (max-width: 430px) {
    justify-content: center;
    margin-bottom: 30px;
  }
`;

export const ButtonCancelApplication = styled.button`
  border: 2px solid
    ${(props) => (props.universityColor ? props.universityColor : '#009291')};
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;

  margin-right: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 36px;
  width: 170px;

  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  font-size: 1em;
  font-weight: 500;
  font-weight: 750;

  transition: 0.5s;
`;

export const SimpleButton = styled.button`
  background-color: transparent;

  color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  font-size: 16px;

  cursor: pointer;
  padding: 10px 2px;

  border: none !important;
  border-radius: 5px;

  :hover {
    background-color: #e3e1e1;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Paragraph = styled.div`
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 15px;

  > strong {
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'} !important;
  }
  p {
    color: #4b4b4d !important;
  }
`;

export const DivHeader = styled.div`
  width: 100%;

  margin: 50px 0 16px 0;
  padding: 0 5px;

  display: flex;

  justify-content: ${(props) =>
    props.isApplicationJob ? 'flex-end' : 'space-between'};

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
  @media screen and (min-width: 431px) {
    flex-direction: row-reverse;
  }
`;

export const ContainerButton = styled.div`
  @media screen and (max-width: 430px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }
`;

export const DivHeaderExternal = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
  padding: 0 18% 0 0;

  @media screen and (max-width: 1370px) {
    padding: 0 115px 0 0;
  }
`;
export const DivFooterExternal = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 16px 0;
  padding: 0 18% 0 0;

  @media screen and (max-width: 1370px) {
    padding: 0 115px 0 0;
  }
`;

export const DivFooter = styled.div`
  width: 100%;

  margin-bottom: 30px;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 430px) {
    justify-content: center;
  }
`;

export const ButtonJobs = styled.button`
  border: none;
  border-radius: 4px;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  cursor: pointer;

  height: 36px;
  width: 164px;

  margin-bottom: ${(props) => (props.isFooter ? '30px' : '0px')};

  color: #fff;

  font-size: 1em;
  font-weight: 500;
  font-weight: 750;

  transition: 0.5s;

  @media screen and (max-width: 430px) {
    margin: ${(props) => (props.isInfoJobsDetails ? '30px 0' : '0px')};
  }
`;

export const AppliedRoundedButton = styled.button`
  color: #fff;
  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};
  border: none;

  height: 36px;
  width: 150px;

  display: flex;
  align-items: center;

  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;

  font-weight: 750;
  font-size: 1em;

  transition: 0.5s;
`;
