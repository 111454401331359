import React, { useEffect, useContext, useState } from 'react';
import {
  ContainerInformations,
  CardHeader,
  CardBody,
  HeaderTitle,
  CardBodyTitle,
  CardBodySubTitle,
  ContainerIcon,
  ContainerAlert,
  IconImg,
  Content,
  CardBodyMobile,
  Divider,
  ButtonSeeMore,
  CardBodySubTitleLink,
  ButtonPersonalInfo,
  ContainerTitleAndIcon,
  MobileButtonEdit,
} from './styled';
import UtilsPersonal from './utils/UtilsPersonal';
import UtilsLinkPersonal from './utils/UtilsLinkPersonal';
import { wrapperIconsProfile } from 'views/Profile/Utils/wrapperIcons';
import GenericModalInformations from './utils/ModalInformations';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';
import ProfileForm from 'views/MyProfile/components/Profile/ProfileForm';
import useMediaQuery from 'hooks/useMediaQuery';
import { IoIosArrowForward } from 'react-icons/io';

export function PersonalInformations(props) {
  const {
    academic_profile,
    metadata,
    handleIsLoadingState,
    setProfileData,
    profileData,
    setUserData,
  } = props;
  const infosData = UtilsPersonal(profileData, metadata);
  const linksData = UtilsLinkPersonal(profileData);
  const { IconAlert, IconInfoAcademicStatus } = wrapperIconsProfile();
  const {
    universityColor,
    openModalWithContent,
    isOpen,
    setIsOpen,
    fetchGamificationData,
    statusIcon,
    setStatusIcon,
  } = useContext(ProfileContext);
  const [showDisability, setShowDisability] = useState(false);
  const [showGenderIdentity, setShowGenderIdentity] = useState(false);
  const [showColorOrRace, setShowColorOrRace] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [studentProfileState, setStudentProfileState] = useState({});
  const editButtonMobile = useMediaQuery('(max-width: 1313px)');

  const updateAvatarState = (newAvatar) => {
    setAvatar(newAvatar.avatar);
  };

  const handleShowSpecialNeeds = (value) => {
    setShowDisability(value);
    setShowGenderIdentity(value);
    setShowColorOrRace(value);
  };

  const updateProfileState = (newProfile, profileUpdated) => {
    setStudentProfileState(newProfile);
    setUserData(profileUpdated);

    if (newProfile) {
      const personalData = [
        newProfile.zipCode,
        newProfile.mobilePhone,
        newProfile.name,
        newProfile.genderIdentity,
        newProfile.colorOrRace,
        newProfile.birth_date,
        newProfile.email,
      ];

      const requiredFields = personalData.every((field) => !!field);
      setStatusIcon(requiredFields);
    }
  };

  useEffect(() => {
    const personalData = [
      academic_profile.zip_code,
      academic_profile.mobile_phone,
      academic_profile.name,
      academic_profile.gender_identity?.value,
      academic_profile.color_or_race?.value,
      academic_profile.birth_date,
      academic_profile.email,
    ];
    const requiredFields = personalData.every((field) => !!field);
    setStatusIcon(requiredFields);
  }, []);

  const returnIconInformations = (status) => {
    if (status === false) {
      return <IconAlert />;
    }

    if (status === true) {
      return <IconInfoAcademicStatus />;
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onClickButton = () => {
    openModalWithContent(
      <ProfileForm
        handleShowSpecialNeeds={handleShowSpecialNeeds}
        handleIsLoadingState={handleIsLoadingState}
        universityColor={universityColor}
        updateAvatarState={updateAvatarState}
        updateProfileState={updateProfileState}
        closeModal={closeModal}
        profileData={profileData}
        setProfileData={setProfileData}
        fetchGamificationData={fetchGamificationData}
        {...props}
      />,
      'Edição Dados Pessoais'
    );
  };

  const returnContent = () => {
    return (
      <>
        {linksData.map((item, index) => (
          <Content key={index}>
            <ContainerIcon>
              <IconImg>{item.icon}</IconImg>
              <CardBodyTitle>{item.title}</CardBodyTitle>
            </ContainerIcon>

            <CardBodySubTitle>
              <CardBodySubTitleLink href={item.info}>
                {item.info}
              </CardBodySubTitleLink>
            </CardBodySubTitle>
          </Content>
        ))}

        {infosData.map((item, index) => (
          <Content key={index}>
            <ContainerIcon>
              <IconImg>{item.icon}</IconImg>
              <CardBodyTitle>{item.title}</CardBodyTitle>
            </ContainerIcon>

            <CardBodySubTitle>
              <CardBodySubTitle>{item.info}</CardBodySubTitle>
            </CardBodySubTitle>
          </Content>
        ))}
      </>
    );
  };

  const returnContentMobile = () => {
    return (
      <>
        <Divider />
        <ButtonSeeMore onClick={() => openModal()}>
          Visualizar <IoIosArrowForward />
        </ButtonSeeMore>
      </>
    );
  };

  return (
    <>
      {isOpen && (
        <GenericModalInformations
          isOpen={isOpen}
          closeModal={closeModal}
          title={'Dados pessoais'}
          isPersonalInfo={true}
          handleEditInfo={() => onClickButton()}
        >
          {returnContent()}
        </GenericModalInformations>
      )}
      <ContainerInformations>
        <CardHeader>
          <ContainerAlert>
            <ContainerTitleAndIcon>
              <HeaderTitle hasMarginRight>Dados pessoais</HeaderTitle>

              {returnIconInformations(statusIcon)}
            </ContainerTitleAndIcon>

            <ButtonPersonalInfo
              universityColor={universityColor}
              onClick={() => onClickButton()}
            >
              Editar <IoIosArrowForward />
            </ButtonPersonalInfo>
          </ContainerAlert>
        </CardHeader>

        <CardBody>{returnContent()}</CardBody>
        <MobileButtonEdit
          show={editButtonMobile}
          universityColor={universityColor}
          onClick={() => onClickButton()}
        >
          Editar <IoIosArrowForward />
        </MobileButtonEdit>

        <CardBodyMobile>{returnContentMobile()}</CardBodyMobile>
      </ContainerInformations>
    </>
  );
}
