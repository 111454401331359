import React, { useEffect } from 'react';
import urls from '../../constants/urls';
import { JobDetailsModal } from 'components/JobDetailsModal';

function ReceiveJob({ handleIsLoadingState, match }) {
  const returnAllJobs = () => {
    handleIsLoadingState(true);
    window.open(`${urls.URL_FRONT}/#/vagas`, '_parent');
  };

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  return (
    <JobDetailsModal
      onClose={() => returnAllJobs()}
      jobId={match.params.id}
      externalJob
    />
  );
}

export default ReceiveJob;
