import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ActionButton = styled.div`
  background-color: #ffffff;
  padding: 5px;
  height: 250px;
  width: 336px;
  border-radius: 8px;
  margin-top: -2px;

  > div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    margin: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      width: 278.63px;
      border-radius: 5px;
      border: 1px solid;
      border-color: #009291;
      svg {
        margin-right: 10px;
      }
    }

    .buttonCurriculum {
      background-color: #009291;
      color: #ffffff;
      cursor: pointer;
    }

    .buttonLetter {
      background-color: #ffffff;
      color: #009291;
      cursor: pointer;
    }
  }
  margin-bottom: 20px;
`;

export const Timeline = styled.div`
  width: auto;
  height: auto;
  padding: 20px 0 0;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const TimelineContent = styled.div`
  margin-left: 20px;
  border-left: 1px solid #bdbdbd;

  :first-child {
    border: none;
  }
`;

export const Circle = styled.div`
  display: none;

  :last-child {
    display: flex;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #bdbdbd;
    margin-left: 17px;
  }
`

export const InfoDate = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0 0 20px;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      width: 188px;
      height: 40px;
      border-radius: 18px;
      background-color: #eef2f4;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      color: #606062;
    }
  }
`;

export const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #caeaea;
  float: left;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const Containers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding-left: 20px;
  margin-top: 20px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  p {
    width: 489px;
    height: 65px;
    border-radius: 0px 10px 10px 10px;
    border: 1px solid #ccc;
    border-color: #c4c4c4;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-top: 20px;
  }
`;

export const InfoContent = styled.div`
  width: 489px;
  height: 65px;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid #ccc;
  border-color: #c4c4c4;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  padding: 25px;
  margin-top: 20px;
  position: relative;

  .infoDate {
    color: #949494;
    font-size: 13px;
    font-weight: 400;
    line-height: 22.5px;
  }
  .infoEmail {
    color: #606062;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const SpanPopUp = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 20px;
    margin: 20px -40px 0px;
  }

  &:hover {
    > div {
      display: block;
    }
  }

  > div {
    background-color: #009291;
    color: #fff;
    width: 125px;
    padding: 5px;
    position: absolute;
    top: -25px;
    border-radius: 4px;
    display: none;
    left: -98px;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      z-index: 1;
      border-style: solid;
      border-color: #009291 transparent;
      border-width: 12px 8px 0;
      left: 60px;
      bottom: -8px;
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const ButtonPagination = styled.button`
  border: none;
  border-radius: 4px;
  background-color: #009291;

  width: 200px;
  height: 40px;

  padding: 12px;

  font-size: 14px;
  color: #fff;
  font-weight: bold;

  cursor: pointer;
  transition: 0.8s;

  &:hover{
    background-color: #25a3a2;
    transition: 0.8s;
  }

  &:disabled{
    display: none;
    cursor: default;
    background-color: #ababab;
  }
`

export const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > p{
    font-size: 16px;
    color: #4b4b4d;
    margin: 10px 0 0 0;
  }
`