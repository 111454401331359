import React, { useContext } from 'react';
import {
  ContainerBottomInformations,
  ContainerButtons,
  ContainerFeedBacks,
  ContainerInformationActivities,
  ContainerMiddleInformations,
  CreateByAndDate,
  Divider,
  InformationActivities,
  ToDateHeader,
} from '../styled';
import { TbCalendarStats } from 'react-icons/tb';
import Button from '../../Button';
import { IoStarOutline } from 'react-icons/io5';
import { BiDislike, BiLike } from 'react-icons/bi';
import ReactionActions from '../../ReactionActions';
import { useVisualizationContext } from '../../../Contexts/VisualizationContext';
import { MdStars } from 'react-icons/md';
import { HourHelper } from '../../../Helpers/HourHelper';
import { GrCertificate } from 'react-icons/gr';
import { DateHelper } from '../../../Helpers/DateHelper';
import BaseLayoutContext from 'contexts/base-layout';
import { BsBuildings } from 'react-icons/bs';
import ReactHtmlParser from 'react-html-parser';
import CalendarArrow from 'assets/FairAndEvents/CalendarArrow';
import moment from 'moment';
import Building from 'assets/FairAndEvents/Building';

export default function AboutEvent() {
  const { detailsObject } = useVisualizationContext();

  const { universityColor } = useContext(BaseLayoutContext);

  const {
    name,
    registration_end_date,
    description,
    event_modality,
    event_start_at,
    event_start_time,
    event_end_at,
    complementary_activity,
    complementary_activity_time,
    certification,
    certification_time,
    created_at,
    created_by,
    event_end_time,
    location_name,
    street,
    zip_code,
    number,
    registred_students,
    limit_students,
    registration_start_date,
    complement,
  } = detailsObject;

  const labelModality =
    event_modality === 'presential' ? 'Presencial' : 'Online';

  return (
    <>
      {new Date(registration_start_date).getTime() <= new Date().getTime() && (
        <ToDateHeader color={universityColor}>
          Inscrições abertas até o dia{' '}
          <strong>{moment(registration_end_date).format('DD/MM/YYYY')}!</strong>
        </ToDateHeader>
      )}

      <ContainerInformationActivities>
        {complementary_activity && (
          <InformationActivities>
            <MdStars color={universityColor} />
            Atividade complementar -{' '}
            {HourHelper.formatDefault(complementary_activity_time)}
          </InformationActivities>
        )}
        {registred_students < limit_students && (
          <InformationActivities>
            <CalendarArrow color={universityColor} size={13} />
            {limit_students - registred_students == 1
              ? `1 vaga restante`
              : `${limit_students - registred_students} vagas restantes`}
          </InformationActivities>
        )}
        {certification && (
          <InformationActivities>
            <GrCertificate color={universityColor} />
            Com certificado - {HourHelper.formatDefault(certification_time)}
          </InformationActivities>
        )}
      </ContainerInformationActivities>

      <CreateByAndDate>
        <span>
          Publicado em {DateHelper.format(created_at)} por {created_by}
        </span>
        <h4>{name}</h4>
        <p>{ReactHtmlParser(description)}</p>
      </CreateByAndDate>

      <ContainerMiddleInformations>
        <div>
          <TbCalendarStats size={30} color={universityColor} />

          <span>
            <h6>Início</h6>
            {DateHelper.format(event_start_at)} às{' '}
            {HourHelper.formatDefault(event_start_time)}
          </span>
        </div>
        <div>
          <Building size={30} color={universityColor} />

          <span>
            <h6>Modalidade</h6>
            {labelModality}
            {event_modality !== 'online' && (
              <>
                - {location_name}
                <br />
                {street}, {number} - {zip_code}
                {complement && ` - ${complement}`}
              </>
            )}
          </span>
        </div>
        <div>
          <TbCalendarStats size={30} color={universityColor} />

          <span>
            <h6>Término</h6>
            {DateHelper.format(event_end_at)}
            {event_end_time &&
              ` às ${HourHelper.formatDefault(event_end_time)}`}
          </span>
        </div>
      </ContainerMiddleInformations>
    </>
  );
}
