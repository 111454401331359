import qs from 'querystring';

const getGenderUTM = (gender) => {
  switch (gender?.value) {
    case 'male':
      return 'G1';
    case 'female':
      return 'G2';
    default:
      return 'G3';
  }
};

const getStudentUTMParams = (metadata, academic_profile) => {
  if (
    metadata?.role == 'student' &&
    process.env.NODE_ENV === 'production' &&
    (metadata.university_id === 134 || metadata.university_id === 136) &&
    academic_profile
  ) {
    const gender = getGenderUTM(academic_profile.gender);
    const params = {
      utm_external_id: academic_profile.external_id,
      utm_age: metadata.age,
      utm_city: academic_profile.city,
      utm_state: academic_profile.state,
      utm_gender: gender,
    };
    return qs.stringify(params);
  }
  return '';
};

const updateUTMParams = (metadata, academic_profile, force_update) => {
  const utmParams = getStudentUTMParams(metadata, academic_profile);
  if (utmParams && !window.location.href.includes(utmParams)) {
    if (force_update) {
      const path = window.location.href.split('?')[0];
      window.location.assign(`${path}?${utmParams}`);
    } else {
      const operator = window.location.href.includes('?') ? '&' : '?';
      window.location.assign(window.location.href + operator + utmParams);
    }
    return true;
  }
  return false;
};

const clearUTMParams = () => {
  if (window.location.href.includes('?')) {
    const path = window.location.href.split('?')[0];
    window.location.assign(path);
  }
};

export { getStudentUTMParams, updateUTMParams, clearUTMParams };
