import FilterModal from 'components/FilterModal';
import React, { useState, useEffect, useContext } from 'react';
import { Filter, FilterType } from 'components/FilterModal/components/Filter';
import { FilterGrid, ContainerFlexDirection } from './styled';
import { getExtensionProjectFilterOptions } from 'views/StaffPages/Opportunities/services';
import { shape } from 'prop-types';
import * as Yup from 'yup';
import { queryParamsFromFilterObject } from 'components/FilterModal/utils';
import { ExtensionProjectsContext } from 'views/StaffPages/Opportunities/Context/ExtensionProjectsContext';

export function FilterPublishedExtensionProjects({
  appliedFilters,
  setAppliedFilters,
  filterState,
  setFilterState,
  filterOptions,
  setFilterOptions,
  activeTab,
}) {
  const {
    setFutureExtension,
    setInProgressExtension,
    setOpenForSubscriptionsExtension,
    getAllOpenForSubscription,
    getAllFutureProjects,
    getAllInProgressProjects,
  } = useContext(ExtensionProjectsContext);

  const [loading, setLoading] = useState(false);

  const setExtensionProjects = () => {
    setFutureExtension(null);
    setInProgressExtension(null);
    setOpenForSubscriptionsExtension(null);
  };

  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        setLoading(true);
        const response = await getExtensionProjectFilterOptions();
        setFilterOptions(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchFilterOptions();
  }, []);

  function renderFilterContent() {
    return [
      <ContainerFlexDirection>
        <Filter
          name={'name'}
          label={'Filtrar por nome'}
          type={FilterType.TEXT}
        />

        <Filter
          name={'macro_areas'}
          label={'Área de atuação'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.macro_areas}
        />

        <FilterGrid>
          <Filter
            name={'courses'}
            label={'Curso'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.courses}
          />

          <Filter
            name={'disciplines'}
            label={'Disciplina'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.disciplines}
          />
        </FilterGrid>

        <FilterGrid>
          <Filter
            name={'periods'}
            label={'Período'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.periods}
          />

          <Filter
            name={'unities'}
            label={'Unidade/Polo'}
            type={FilterType.MULTI_SELECT}
            options={filterOptions.unities}
          />
        </FilterGrid>

        <Filter
          name={'development_objective'}
          label={'ODS (Objetivos de Desenvolvimento Sustentável)'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.ods}
        />

        <Filter
          name={'type_project'}
          label={'Tipo de projeto'}
          type={FilterType.MULTI_SELECT}
          options={filterOptions.type}
        />
      </ContainerFlexDirection>,
    ];
  }

  function onApplyFilters() {
    setExtensionProjects();
    getAllOpenForSubscription(filterState, true);
    getAllFutureProjects(filterState, true);
    getAllInProgressProjects(filterState, true);

    setAppliedFilters(filterState);
  }

  function onClearFilters() {
    setExtensionProjects();
    getAllOpenForSubscription(true);
    getAllFutureProjects(true);
    getAllInProgressProjects(true);
    setAppliedFilters({});
  }

  return (
    <FilterModal
      filterState={filterState}
      setFilterState={setFilterState}
      onApply={onApplyFilters}
      onClean={onClearFilters}
      appliedFilters={appliedFilters}
      setAppliedFilters={setAppliedFilters}
      loading={loading}
      disabled={loading}
    >
      {renderFilterContent()}
    </FilterModal>
  );
}

FilterPublishedExtensionProjects.propTypes = {
  filterOptions: shape.isRequired,
};
