import React, { useState, Fragment } from 'react';

import { CsmContextProvider } from './contexts/CsmContext';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import headerBackgroundImage from 'assets/img/companies-jobs.jpg';
import headerEgressedBackgroundImage from 'assets/img/headerEgressedBackgroundImage.png';
import HomeCategories from './components/Home/HomeCategories';
import ControllerCarrearService from './components/ControllerCarrearService';
import EngineSendEmail from './components/EngineSendEmail';

export function Csm({ egressed }) {
  const [activeCategory, setActiveCategory] = useState(undefined);
  const [selectedHomeCategory, setSelectedHomeCategory] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const conditionalTabs = {
    ...(selectedHomeCategory && {
      tabs: ['Gestão de Serviços de Carreira', 'Gestão de Atividades'],
    }),
  };

  const components = {
    0: <ControllerCarrearService />,
    1: <EngineSendEmail />,
  };

  const controlHomeComponents = () => {
    if (selectedHomeCategory) {
      return components[activeTab];
    }

    return <HomeCategories setSelectedHomeCategory={setSelectedHomeCategory} />;
  };

  const handleTabChange = (_, value) => {
    setActiveTab(value);
    setActiveCategory(null);
  };

  return (
    <CsmContextProvider
      egressed={egressed ?? false}
      activeCategory={activeCategory}
      setActiveCategory={setActiveCategory}
      setActiveTab={setActiveTab}
    >
      <CompanyHeader
        title={egressed ? 'Egressos' : 'CSM'}
        backgroundImage={
          egressed ? headerEgressedBackgroundImage : headerBackgroundImage
        }
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        {...conditionalTabs}
      />
      {controlHomeComponents()}
    </CsmContextProvider>
  );
}
