import styled from 'styled-components';

export const WrapperMoreInfo = styled.div`
  ${(props) => (props.isOpen ? `display: block;` : `display: none;`)}
  z-index: 3000;
  width: 100%;
  max-width: 642px;
  background-color: #ffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;

  position: absolute;
  top: 35px;

  @media (max-height: 830px) {
    top: 0;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

    .box {
      width: calc(100% - 259px);
      height: 100%;
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const WrapperHeader = styled.div`
  width: 100%;
  height: 207px;
  position: relative;

  @media (max-width: 425px) {
    display: none;
  }
`;

export const Image = styled.div`
  width: 100%;
  height: 209px;

  background-color: ${(props) =>
    props.universityColor ? props.universityColor : '#009291'};

  border-top-left-radius: 11.5652px;
  border-top-right-radius: 11.5652px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 75%;
    z-index: 2;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    opacity: 0.8;
    z-index: 1;
  }
`;

export const WrapperContent = styled.div`
  margin-top: 20px;
  padding: 0px 25px;
`;

export const DivX = styled.div`
  width: 100%;

  display: flex;
  justify-content: flex-end;

  text-align: center;

  position: absolute;
  top: 20px;
  right: 10px;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 10px;

  text-align: start;
`;

export const Description = styled.p`
  word-break: break-word;
  font-size: 13px;
  color: #606062;
  height: auto;
  max-height: 207px;
  width: 100%;
  overflow-y: scroll;
  text-align: start;

  strong {
    color: #606062 !important;
  }

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
`;

export const WrapperInformartions = styled.div`
  margin-top: 20px;
  margin-bottom: 50px;

  width: 100%;

  .box-view {
    display: flex;
  }

  @media (max-width: 425px) {
    .box-view {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: auto;
    }

    .box-view::-webkit-scrollbar {
      width: 7px;
    }

    .box-view::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    .box-view::-webkit-scrollbar-thumb {
      background: #ededed;
      border-radius: 10px;
    }

    .box-view::-webkit-scrollbar-thumb:hover {
      background: #ededed;
    }
  }
`;

export const GridSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.marginTop};

  width: 50%;

  justify-content: space-between;
  height: ${(props) => props.height};

  @media (max-width: 425px) {
    margin: 0 !important;
    width: 100%;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  text-align: start;

  .container {
    display: flex;
    align-items: center;
    width: 100%;

    > svg {
      margin-top: 20px;
    }
  }

  .container i {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
  }

  .container svg {
    flex: none;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: 100%;
  }

  .text-wrapper strong {
    margin-top: 18px;
    color: ${(props) => props.universityColor || '#009291'};
    width: 100%;
    font-size: 12px;
  }

  .text-wrapper p {
    font-size: 11px;
    width: 100%;
    border: 1px;
  }

  .text-wrapper button {
    width: 100%;
    max-width: 95px;

    padding: 0;
    margin-bottom: 13px;

    color: #7b848e;
    font-size: 12px;
    text-align: start;
    text-decoration: underline;

    background: none;
    border: none;
    cursor: pointer;
  }
`;

export const AlignedItemWrapper = styled(ItemWrapper)`
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start ;
    width: 100%;

    gap: 15px;
    margin-top: 18px;

    strong {
      line-height: 39px;
      color: ${(props) => props.universityColor || '#009291'};
      width: 100%;
      font-size: 12px;
    }
  }
  .container {
    flex-direction: column;
    justify-content: flex-start ;

  }
  .list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 960px) {
      width: 100%;
      flex-direction: column;
    }
  }
`;

export const ListItem = styled.li`
  font-size: 11px;
  width: 50% !important;
  margin-bottom: 5px;
  display: flex;
  position: relative;
  margin-top: 0 !important;
  height: fit-content !important;

  @media (max-width: 960px) {
    width: 100% !important;
  }

  ::before {
    content: ' ';
    background-color: ${(props) => props.universityColor || '#009291'};
    width: 5px;
    height: 5px;
    position: absolute;
    left: -5%;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 50px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  ${(props) => (props.usingPreviewPdf ? `z-index: 0;` : `z-index: 2000;`)}


  align-items: center;
  justify-content: center;

  position: fixed;
  left: 0;
  top: 0;

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.25);

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .box {
    width: calc(100% - 259px);
    height: 100%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 960px) {
    .box {
      width: 100%;
    }
  }
`;

export const ButtonX = styled.button`
  width: 100%;
  max-width: 25px;
  height: 25px;

  background-color: #ededed;
  border: none;
  border-radius: 200px;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 2;
`;

export const DividerResponsive = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eef2f4;
  margin-top: 20px;
  margin-bottom: 37px;
  padding: 0 37px;

  @media (max-width: 425px) {
    margin-bottom: 27px;
  }
`;

export const RegisterButton = styled.button`
  width: 100%;
  max-width: 100px;
  border: none;
  background-color: ${(props) => props.universityColor || '#009291'};

  color: #fff;
  font-size: 13px;
  cursor: pointer;

  padding: 8px;

  border-radius: 4px;
`;

export const FooterButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  .box-reactions {
    width: 100%;
    max-width: 150px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 1s;
    margin-left: 10px;
  }

  margin-bottom: 40px;

  @media (max-height: 778px) {
    .box-reactions {
      margin-right: 30px;
      transition: 1s;
      gap: 5px;
    }
  }
`;

export const ReactionsButton = styled.button`
  width: 100%;
  max-width: 33px;
  background: #f6f6f6;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
`;

export const FooterEvidence = styled.div`
  padding: 30px;
  padding-top: 0px;
  display: flex;
  gap: 20px;

  .buttonGreen {
    border: none;
    background-color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    color: #ffff;
    font-weight: bold;
    width: 157px;
    height: 35px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
  }

  .buttonWhite {
    border: 1px solid
      ${(props) => (props.universityColor ? props.universityColor : '#009291')};
    background-color: transparent;
    color: ${(props) =>
      props.universityColor ? props.universityColor : '#009291'};
    border-radius: 4px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }
`;
