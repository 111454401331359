import { useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import { Form } from '@unform/web'
import { ContainerLabelInput, UnInput, UnInputLabel } from './Style/BaseInputs'

export default function SimpleCheckbox({ name, value, label, marginTop, type, ...rest }) {
  const inputRef = useRef()
  const { fieldName, defaultValue, registerField, error } = useField(name)

  const defaultChecked = defaultValue === value

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.checked
      },
      clearValue: ref => {
        ref.current.checked = defaultChecked
      },
      setValue: (ref, value) => {
        ref.current.checked = value
      },
    })
  }, [defaultValue, fieldName, registerField, defaultChecked])

  const orderInput = type === 'radio' || type === 'checkbox' ? true : false;

  return (
    <ContainerLabelInput order={orderInput} marginTop={marginTop}>
      {
        label && 
          <UnInputLabel 
            htmlFor={fieldName} 
            key={fieldName}
            order={orderInput}
          > 
            {label} 
          </UnInputLabel>
      }
      <UnInput
        order={orderInput}
        defaultChecked={defaultChecked}
        ref={inputRef}
        value={value}
        type="checkbox"
        id={fieldName}
        {...rest}
      />



      {error && <span>{error}</span>}
    </ContainerLabelInput>
  )
}


