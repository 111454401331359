// bairro:"Tucuruvi"
// cep:"02330-001"
// complemento:"de 0646 a 1502 - lado par"
// gia:"1004"
// ibge:"3550308"
// localidade:"São Paulo"
// logradouro:"Avenida Nova Cantareira"
// uf:"SP"

const addressAdapter = address => ({
  district: address.bairro,
  zipCode: address.cep,
  city: address.localidade,
  street: address.logradouro,
  addressState: address.uf
});

export default addressAdapter;
