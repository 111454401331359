import React, { Fragment } from "react";
import { IconButton } from "@material-ui/core";
import IcoHelp from "assets/img/icons/icoHelp.svg";
import IcoHelpWhite from "assets/img/icons/icoHelpWhite.svg";
import withStyles from "@material-ui/core/styles/withStyles";

import { func, object, string } from "prop-types";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const HelpButton = props => {
  const {
    classes,
    openModalWithContent,
    HelpModalContent,
    helpModalTitle,
    whiteIcon
  } = props;
  let propsCopy = Object.assign({}, props);
  delete propsCopy.classes;
  return (
    <Fragment>
      <IconButton
        className={classes.buttonHelpIcon}
        onClick={() =>
          openModalWithContent(
            <HelpModalContent {...propsCopy} />,
            helpModalTitle
          )
        }
      >
        {whiteIcon ? (
          <img src={IcoHelpWhite} alt="Ajuda" />
        ) : (
          <img src={IcoHelp} alt="Ajuda" />
        )}
      </IconButton>
    </Fragment>
  );
};

HelpButton.propTypes = {
  classes: object.isRequired,
  openModalWithContent: func,
  helpContent: object,
  helpTitle: string
};

export default withStyles(dashboardStyle)(HelpButton);
