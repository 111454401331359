import React, { useEffect, useRef } from 'react';

import { useField } from '@unform/core';
import { UnInput, UnInputLabel } from '../styles/unformCompany';
import { ContainerLabelInput, ErrorMessage } from '../styles/styleForm';

export default function Input({ label, name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <ContainerLabelInput>
      {label && <UnInputLabel for={name}>{label || name}</UnInputLabel>}
      <UnInput ref={inputRef} defaultValue={defaultValue} {...rest} />
      <ErrorMessage>{error}</ErrorMessage>
    </ContainerLabelInput>
  );
}
