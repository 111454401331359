import { SkeletonBox } from '../../../style';
import { ContainerSkeleton } from './style';

export default function SkeletonStepOne() {
  function randomNameWithLength() {
    return Math.floor(Math.random() * (300 - 170 + 1) + 170);
  }

  return (
    <ContainerSkeleton>
      <span className="checkbox">
        <SkeletonBox width={'14px'} height={'14px'} />
      </span>
      <span className="code">
        {' '}
        <SkeletonBox width={'46px'} height={'14px'} />{' '}
      </span>
      <span className="discipline">
        <SkeletonBox width={`${randomNameWithLength()}px`} height={'14px'} />{' '}
      </span>
      <span className="workloadList">
        <SkeletonBox width={'80px'} height={'14px'} />{' '}
      </span>
      <span className="e3dit">
        <SkeletonBox width={'14px'} height={'14px'} />{' '}
      </span>
    </ContainerSkeleton>
  );
}
