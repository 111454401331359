import CircularProgress from '../UiComponents/Graphics/CircularProgress';
import { CircularProgressGraphSkeleton } from '../UiComponents/Skeletons/CircularGraphSkeleton';
import GenericMessage from 'components/GenericMessage/index';
import { returnAdapter, verifyValuesChartAreZero } from './formatDataGraphics';

export const returnGraphic = (
  graphicalData,
  Graphic,
  Skeleton,
  dataKey,
  heightGraphic,
  LegendBoxComponent
) => {
  const chartsAreZero =
    graphicalData?.total_actives === 0 && graphicalData?.total_curriculum === 0;

  const dataAdapter = returnAdapter(graphicalData);

  if (dataAdapter.result === null) {
    return <Skeleton />;
  }

  if (verifyValuesChartAreZero(dataAdapter) || chartsAreZero) {
    return (
      <GenericMessage
        title="Dados Indisponíveis"
        subtitle="No momento, esses dados não estão cadastrados e por isso estão indisponíveis."
      />
    );
  }

  if (dataAdapter?.result) {
    return (
      <Graphic
        graphicalData={dataAdapter.result}
        LegendBoxComponent={LegendBoxComponent}
        dataKey={dataKey}
        heightGraphicValue={heightGraphic}
      />
    );
  }
};

export const returnCircularProgressItens = (data) => {
  const dataAdapter = returnAdapter(data);

  if (dataAdapter.result === null) {
    return [1, 2, 3, 4, 5, 6, 7].map(() => <CircularProgressGraphSkeleton />);
  }

  if (verifyValuesChartAreZero(dataAdapter)) {
    return (
      <GenericMessage
        title="Dados Indisponíveis"
        subtitle="No momento, esses dados não estão cadastrados e por isso estão indisponíveis."
      />
    );
  }

  if (data?.result?.chart.length > 0) {
    return data?.result?.chart?.map((item) => (
      <CircularProgress progress={item.percentage} label={item.label} />
    ));
  }
};
