import React, { useState, useRef, useContext } from 'react';
import { BaseContainer, Box, Card, Wrapper } from './style';

import Pagination from 'components/CustomPagination';
import ListLinks from './ListLinks';
import ListFiles from './ListFiles';
import CloseButton from 'views/StaffPages/Academic/components/CurriculumMatrix/components/activeMatrices/closeButtonModal';
import BaseLayoutContext from 'contexts/base-layout';

export default function ModalShowEvidence({
  open,
  setOpen,
  loadEvidences,
  id,
  arrayLinks,
  arrayFiles,
  count,
  deleteEvidenceStudent,
  setEvidenceFiles,
  setUsingPreview,
  usingPreview,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);

  const [usingPreviewPdf, setUsingPreviewPdf] = useState(false);

  const modalContainer = useRef(null);

  const handlePageChange = async (page) => {
    const newPage = `limit=${limit}&offset=${(page - 1) * limit || 0}`;
    await loadEvidences(newPage);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    handlePageChange(page);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  const closeClickingOutside = (e) => {
    if (modalContainer.current === e.target) {
      setOpen(false);
    }
  };

  const { universityColor } = useContext(BaseLayoutContext);
  return (
    <BaseContainer
      ref={modalContainer}
      onClick={closeClickingOutside}
      usingPreviewPdf={usingPreview}
    >
      <Box>
        <Wrapper>
          <Card universityColor={universityColor}>
            <CloseButton onClick={handleClickClose} />
            <h3>Visualizar evidências</h3>

            {arrayLinks?.map((item) => (
              <ListLinks item={item} canUseActions />
            ))}
            {arrayFiles?.map((item) => (
              <ListFiles
                setUsingPreviewPdf={setUsingPreviewPdf}
                idPost={id}
                item={item}
                canUseActions
                filesList={arrayFiles}
                setFilesList={setEvidenceFiles}
                functionDeleteEvidence={deleteEvidenceStudent}
                setUsingPreview={setUsingPreview}
              />
            ))}

            <Pagination
              showLimit={false}
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={count}
              limit={limit}
              onPageChange={onPageChange}
              setLimit={setLimit}
              setCurrentPage={setCurrentPage}
            />
          </Card>
        </Wrapper>
      </Box>
    </BaseContainer>
  );
}
