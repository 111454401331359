// ##############################
// // // behavioralTestsStyle component styles
// #############################
import {
  blueIE,
  dnaGreen,
  dnaLightGreen,
  lightBlueIE,
  greenIE,
  darkBlueIE,
  redIE,
  yellowIE,
  orangeIE,
} from 'assets/jss/material-dashboard-pro-react';

import behavioralTestStyle from 'assets/jss/material-dashboard-pro-react/views/behavioralTestStyle';

const learningTrailsStyle = (theme) => ({
  ...behavioralTestStyle(theme),
  fullScreenGridContainer: {
    margin: 'auto',
    '& > div': {
      width: '100%',
    },
    '@media only screen and (max-width: 760px)': {
      padding: 10,
    },
  },
  thumbnail: {
    paddingRight: '1.5em',
  },
  learningTrails: {
    color: '#000',
    padding: '1em 0',
    [theme.breakpoints.down('sm')]: {
      padding: '1em 2em',
    },
    '& p': {
      fontSize: '1.2em',
      margin: '0 0 1.2em',
    },
    '& cite': {
      margin: '.5em 0 .3em',
      fontSize: '1em',
      fontStyle: 'italic',
      lineHeight: '1.5em',
    },
  },
  blockMargin: {
    marginBottom: '3em',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      marginBottom: '10px',
    },
  },
  buttonFluid: {
    maxWidth: '300px',
    justifyContent: 'center',
    borderRadius: '2em',
    color: '#FFF',
    width: '100%',
    '&:hover': {
      backgroundColor: dnaLightGreen,
    },
  },
  cardAuthor: {
    display: 'block',
  },
  cardSubtitle: {
    fontSize: '1.5em',
    fontWeight: 400,
    marginBottom: '.5em',
  },
  cardSubtitleDescription: {
    fontWeight: 400,
  },
  dnaColortrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: dnaGreen,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  redIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: redIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  yellowIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: yellowIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  greenIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: greenIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  darkBlueIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: darkBlueIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  blueIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: blueIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  lightBlueIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: lightBlueIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  orangeIEStrailsList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'flex',
      alignItems: 'start',
    },
    '& .index': {
      width: '30px',
      display: 'inline-flex',
      height: '30px',
      textAlign: 'center',
      background: orangeIE,
      borderRadius: '50%',
      color: '#fff',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .text': {
      display: 'inline-flex',
      padding: '0 1em',
      width: 'calc(100% - 40px)',
    },
  },

  itemTitle: {
    color: '#000',
    fontWeight: 500,
    marginTop: 0,
    '@media only screen and (max-width: 600px)': {
      display: 'none',
    },
  },
  videosTitle: {
    color: '#000',
    fontWeight: 500,
    marginTop: 0,
  },
  itemDescrpt: {
    color: '#000',
    fontSize: '1.2em',
    lineHeight: 1.4,
  },
  trailMovieImage: {
    width: 158,
  },
  dnaColorstepActive: {
    color: '#fff',
    border: `2px solid ${dnaGreen}`,
    backgroundColor: dnaGreen,
  },

  yellowIESstepActive: {
    color: '#fff',
    border: `2px solid ${yellowIE}`,
    backgroundColor: yellowIE,
  },

  redIESstepActive: {
    color: '#fff',
    border: `2px solid ${redIE}`,
    backgroundColor: redIE,
  },

  greenIESstepActive: {
    color: '#fff',
    border: `2px solid ${greenIE}`,
    backgroundColor: greenIE,
  },

  darkBlueIESstepActive: {
    color: '#fff',
    border: `2px solid ${darkBlueIE}`,
    backgroundColor: darkBlueIE,
  },

  blueIESstepActive: {
    color: '#fff',
    border: `2px solid ${blueIE}`,
    backgroundColor: blueIE,
  },

  lightBlueIESstepActive: {
    color: '#fff',
    border: `2px solid ${lightBlueIE}`,
    backgroundColor: lightBlueIE,
  },

  orangeIESstepActive: {
    color: '#fff',
    border: `2px solid ${orangeIE}`,
    backgroundColor: orangeIE,
  },

  dnaColorDefaultButton: {
    color: dnaGreen,
  },

  yellowIESDefaultButton: {
    color: yellowIE,
  },

  redIESDefaultButton: {
    color: redIE,
  },

  greenIESDefaultButton: {
    color: greenIE,
  },

  darkBlueIESDefaultButton: {
    color: darkBlueIE,
  },

  blueIESDefaultButton: {
    color: blueIE,
  },

  lightBlueIESDefaultButton: {
    color: lightBlueIE,
  },

  orangeIESDefaultButton: {
    color: orangeIE,
  },

  green: {
    '& $learningTrailsNav': {
      backgroundColor: '#1cb45e',
    },
    '& $defaultButton': {
      backgroundColor: '#1cb45e',
      '&:hover': {
        color: '#fff',
      },
    },
    '& $active': {
      backgroundColor: '#1cb45e',
      borderColor: '#1cb45e',
    },
    '& $buttonFluid': {
      backgroundColor: '#1cb45e !important',
    },
  },
  purple: {
    '& $learningTrailsNav': {
      backgroundColor: '#692a71',
    },
    '& $defaultButton': {
      backgroundColor: '#692a71',
      '&:hover': {
        color: '#fff',
      },
    },
    '& $active': {
      backgroundColor: '#692a71',
      borderColor: '#692a71',
    },
  },
  blue: {
    '& $learningTrailsNav': {
      backgroundColor: '#0183ad',
    },
    '& $defaultButton': {
      backgroundColor: '#0183ad',
      '&:hover': {
        color: '#fff',
      },
    },
    '& $active': {
      backgroundColor: '#0183ad',
      borderColor: '#0183ad',
    },
  },
  yellow: {
    '& $learningTrailsNav': {
      backgroundColor: '#eb9b00',
    },
    '& $defaultButton': {
      backgroundColor: '#eb9b00',
      '&:hover': {
        color: '#fff',
      },
    },
    '& $active': {
      backgroundColor: '#eb9b00',
      borderColor: '#eb9b00',
    },
    '& $checkBox': {
      color: '#eb9b00 !important',
    },
  },
  videos: {
    width: '90%',
    height: '800px',
    '@media only screen and (max-width: 1920px) and (min-width: 1280px)': {
      height: '500px',
    },
    '@media only screen and (max-width: 1279px) and (min-width: 768px)': {
      height: '350px',
    },
    '@media only screen and (max-width: 760px) and (min-width: 420px)': {
      width: '100%',
      height: '280px',
    },
    '@media only screen and (max-width: 419px) and (min-width: 370px)': {
      width: '100%',
      height: '250px',
    },
    '@media only screen and (max-width: 369px) and (min-width: 300px)': {
      width: '100%',
      height: '200px',
    },
  },
  dotsField: {
    textAlign: 'center',
    '& span': {
      '@media only screen and (max-width: 330px)': {
        heigh: '35px',
        width: '35px',
      },
    },
  },
  divider: {
    marginBottom: '52px',
  },
  itemTitleMobile: {
    color: '#000',
    fontWeight: 500,
    marginTop: 0,
    '@media only screen and (max-width: 599px)': {
      display: 'block',
    },
    '@media only screen and (min-width: 600px)': {
      display: 'none',
    },
  },
  trailDataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  marginB30: {
    marginBottom: 30,
  },
  intro: {
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export default learningTrailsStyle;
