import React from 'react';

import HeaderReports from '../HeaderReports';
import GenericContainerReportsMarket from './GenericContainerReportsMarket';

function ReportsMarket(props) {
  const { InsightList } = props;

  return (
    <div>
      <HeaderReports />

      {InsightList.companiesConectedDisconectedJobs.map((item) => (
        <GenericContainerReportsMarket
          title={item.name}
          directJobsCourses={item.directJobsCourses}
          indirectJobsCourses={item.indirectJobsCourses}
          inviteBySystemCompany={item.inviteBySystemCompany}
          inviteByUniversity={item.inviteByUniversity}
          totalCompanies={item.totalCompanies}
        />
      ))}
    </div>
  );
}

export default ReportsMarket;
