import styled from 'styled-components';

export const BackButton = styled.button`
  width: 35px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-radius: 6px;
  border: none;

  font-family: Roboto;
  font-size: 18px;

  margin-top: 1.5rem;
  margin-left: ${(props) => props.marginLeft || '1.5rem'};
  padding: 0.3rem;
  gap: 0.5rem;

  margin-bottom: ${(props) => props.marginBotton + 'px' || '0px'};

  cursor: pointer;
  position: relative;

  > p {
    position: absolute;
    left: 35px;

    margin: 0;
    color: ${(props) => props.color || '#009291'};
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    width: 100px;

    > p {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }
    transition: 0.3s;
  }

  > svg {
    fill: ${(props) => props.color || '#009291'};
    width: 25px;
    height: 25px;
    transition: fill 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    > p {
      display: inherit;
      opacity: 1;
    }

    width: 100px;
  }
`;
