import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { func, string } from 'prop-types';
import { Container } from './ReportAlreadyExistStyle';

const ReportAlreadyExistModal = ({ reportTitle, isOpen, setClose }) => {
  return (
    <Dialog open={isOpen}>
      <Container>
        <header>
          <button
            type="button"
            onClick={() => {
              setClose();
            }}
          >
            +
          </button>
        </header>
        <p>
          {`Identificamos que o Público Alvo informado está utilizando o Relatório - ${reportTitle}.
          É necessário despublicá-lo para inserir um novo relatório.`}
        </p>
        <div>
          <button
            type="button"
            onClick={() => {
              setClose();
            }}
          >
            OK, ENTENDI!
          </button>
        </div>
      </Container>
    </Dialog>
  );
};

export default ReportAlreadyExistModal;
