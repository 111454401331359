import React, { useEffect, useRef } from 'react';
import CardChallenges from './components/Cards';
import { Container, WrapperContent } from './styled';

export default function ChallengesEngine(props) {
  const {
    loading,
    challenges,
    setChallenge,
    loadMore,
    getAllChallenges,
    topDiv,
    ScrollToTop,
    count,
  } = props;

  return (
    <Container>
      <span>
        Aqui você pode analisar os desafios que as empresas publicaram para os
        seus estudantes. E poderão também validar os desafios como atividades
        complementares.
      </span>
      <WrapperContent ref={topDiv}>
        <CardChallenges
          challenges={challenges}
          setChallenge={setChallenge}
          loadMore={loadMore}
          getAllChallenges={getAllChallenges}
          loading={loading}
          ScrollToTopDiv={ScrollToTop}
          count={count}
        />
      </WrapperContent>
    </Container>
  );
}
