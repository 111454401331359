import React, { useState, useContext, useEffect } from 'react';
import {
  PrincipalContainer,
  BaseContainer,
} from './Components/styles/BaseLayoutStyled';
import { ProfileContext } from 'views/Profile/Context/ProfileContext';

import StepOne from './Components/Cards/stepOne';
import StepTwo from './Components/Cards/stepTwo';
import StepThree from './Components/Cards/stepThree';

import Pagination from './Components/Pagination';
import SkeletonGamification from './Components/Skeleton';

export default function Gamification(props) {
  const { gamificationLevel, gamificationData } = useContext(ProfileContext);
  const [activeStep, setActiveStep] = useState(1);

  const [journeyOfSelfKnowledge, setJourneyOfSelfKnowledge] = useState(false);
  const [journeyOfProtagonism, setJourneyOfProtagonism] = useState(false);

  const handleStepChange = (newStep) => {
    setActiveStep(newStep);
  };

  useEffect(() => {
    setActiveStep(gamificationLevel);
  }, [gamificationLevel]);

  let currentStepComponent;

  if (activeStep === 1) {
    currentStepComponent = (
      <StepOne
        journeyOfSelfKnowledge={journeyOfSelfKnowledge}
        setJourneyOfSelfKnowledge={setJourneyOfSelfKnowledge}
      />
    );
  } else if (activeStep === 2) {
    currentStepComponent = (
      <StepTwo
        journeyOfProtagonism={journeyOfProtagonism}
        setJourneyOfProtagonism={setJourneyOfProtagonism}
      />
    );
  } else if (activeStep === 3) {
    currentStepComponent = <StepThree />;
  }

  return (
    <PrincipalContainer>
      {gamificationData === null ? (
        <SkeletonGamification />
      ) : (
        <BaseContainer>
          <Pagination
            activeStep={activeStep}
            handleStepChange={handleStepChange}
            gamificationLevel={gamificationLevel}
            props={props}
          />
          {currentStepComponent}
        </BaseContainer>
      )}
    </PrincipalContainer>
  );
}
