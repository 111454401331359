import styled from 'styled-components';

export const Input = styled.input`
  width: ${({ width }) => width || '180px'};
  height: 35px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 0px 30px 0px 10px;
`;

export const Container = styled.div`
  width: ${({ width }) => width || '170px'};
  position: relative;

  > div {
    border-radius: 4px;
    cursor: pointer;
    background-color: ${({ color }) => color};
    width: 25px;
    height: 25px;
    position: absolute;
    right: 7px;
    top: 50%;
    bottom: 50%;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
