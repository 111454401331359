import {
  BoxInformationArea,
  BoxProgressBar,
  Container,
  ContainerButtons,
  ContainerInfos,
  Divider,
  SimpleButton,
  WrapperInformation,
} from '../../Styles/CardComponent';
import { CircularProgressBar } from 'views/JobOffers/components/NewCards/CircularProgress';
import { IoIosHeart } from 'react-icons/io';

import { GoClock } from 'react-icons/go';
import DigitalBookIcon from 'assets/StudentLearning/DigitalBook';
import { capitalizeFirstLetterWithExceptions } from 'utils/stringUtils';

import { useContext, useState, useEffect } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import {
  fetchCandidateCurriculumInternalJob,
  fetchCandidateCurriculumJobHistory,
  isCandidateSuitable,
  returnRatingStars,
  returnReactionInformation,
  returnStatusInterview,
} from 'components/ListCandidates/Utils/cardFunctions';
import {
  isCandidatesSelected,
  toggleSelectCandidate,
} from 'components/ListCandidates/Utils/mainFunctions';
import CurriculumModalGenerate from 'components/CurriculumModalGenerate';
import { ScheduleInterviewModal } from '../Modal/ScheduleInterviewModal';
import { StudentTrophy } from 'assets/JobOffer/StudentTrophy';
import { CancelInterviewModal } from '../Modal/CancelInterviewModal';
import { ViewFeedback } from '../Modal/ViewFeedback';
import { CancellationDetails } from '../Modal/CancellationDetails';
import { Star } from '@material-ui/icons/';
import Tooltip from 'components/NewTooltip';

export function CardCandidates({
  jobId,
  candidate,
  selectedCandidates,
  setSelectedCandidates,
  history,
  publishedJobs,
  university,
  company,
  isPartner,
  setCandidates,
  conditionSeeMatch,
}) {
  const {
    score,
    match,
    is_champion,
    has_curriculum,
    application_time,
    course,
    student,
    interview,
    is_interesting,
    liked_company,
    is_answered,
    id,
    has_cover_letter,
    reasons,
    reason_description,
    is_indication,
    is_company_indication,
  } = candidate;

  const [studentDetails, setStudentDetails] = useState(null);
  const [applicationsOptions, setApplicationsOptions] = useState(null);
  const [generateCurriculumModal, setGenerateCurriculumModal] = useState(false);

  const { handleIsLoadingState } = useContext(BaseLayoutContext);
  const [scheduleInterviewModal, setScheduleInterviewModal] = useState(false);
  const [cancelInterviewModal, setCancelInterviewModal] = useState(false);
  const [viewFeedbackModal, setViewFeedbackModal] = useState(false);
  const [cancellationModal, setCancellationModal] = useState(false);
  let environmentType = company ? 'company' : 'university';
  let selectedTab = history ? 'history' : 'publishedJobs';

  const controlOpenStudentCurriculum = (id, university, company) => {
    if (university) {
      return fetchCandidateCurriculumInternalJob(
        jobId,
        id,
        handleIsLoadingState,
        setStudentDetails,
        setApplicationsOptions,
        setGenerateCurriculumModal,
        publishedJobs
      );
    }

    if (company) {
      return fetchCandidateCurriculumJobHistory(
        jobId,
        id,
        handleIsLoadingState,
        setStudentDetails,
        setApplicationsOptions,
        history,
        isPartner,
        setGenerateCurriculumModal
      );
    }
  };

  const returnJobApplication = async (data, isPartner, company) => {
    return await isCandidateSuitable(
      environmentType,
      handleIsLoadingState,
      jobId,
      id,
      data,
      isPartner
    );
  };

  const returnLabelButton = () => {
    if (interview?.hasOwnProperty('status')) {
      const statusLabel = {
        unscheduled: 'Detalhes da entrevista',
        scheduled: 'Detalhes da entrevista',
        rescheduled: 'Detalhes da entrevista',
        canceled: 'Detalhes do cancelamento',
      };

      return statusLabel[interview.status];
    }
    return 'Agendar entrevista';
  };

  const controlOpenModal = () => {
    if (interview?.hasOwnProperty('status')) {
      if (interview.status !== 'canceled') {
        setScheduleInterviewModal(true);
      } else {
        setCancellationModal(true);
      }
    } else {
      setScheduleInterviewModal(true);
    }
  };

  return (
    <Container>
      {generateCurriculumModal && (
        <CurriculumModalGenerate
          isOpen={generateCurriculumModal}
          onClose={() => setGenerateCurriculumModal(false)}
          curriculumData={studentDetails.student}
          studentData={studentDetails}
          setCurriculumData={setStudentDetails}
          updateCandidates={() => {}}
          returnJobApplication={returnJobApplication}
          studentId={student.id}
          options={applicationsOptions}
          fetchStudentData={() => {
            return true;
          }}
          showApplicationReturn
          hasCoverLetter={has_cover_letter}
          university={university}
          company={company}
          candidateId={id}
          jobId={jobId}
          interview={interview}
          partnerJob={isPartner}
          history={history}
          setCandidates={setCandidates}
          selectedTab={selectedTab}
        />
      )}
      {scheduleInterviewModal && (
        <ScheduleInterviewModal
          visible={scheduleInterviewModal}
          controlModal={setScheduleInterviewModal}
          candidateId={id}
          jobId={jobId}
          company={company}
          university={university}
          interview={interview}
          setCancelInterviewModal={setCancelInterviewModal}
          isPartner={isPartner}
          setCandidates={setCandidates}
          history={history}
          selectedTab={selectedTab}
        />
      )}
      {cancellationModal && (
        <CancellationDetails
          openModal={cancellationModal}
          controlModal={setCancellationModal}
          jobId={jobId}
          interviewId={interview.id}
          candidateId={id}
          company={company}
        />
      )}
      {cancelInterviewModal && (
        <CancelInterviewModal
          cancelInterviewModal={cancelInterviewModal}
          setCancelInterviewModal={setCancelInterviewModal}
          jobId={jobId}
          candidateId={id}
          interview={interview}
          company={company}
          toView={interview.status === 'canceled'}
          history={history}
          setCandidates={setCandidates}
          isPartner={isPartner}
        />
      )}
      {viewFeedbackModal && (
        <ViewFeedback
          controlModal={viewFeedbackModal}
          setControlModal={setViewFeedbackModal}
          reasonsList={reasons}
          reasonsDescription={reason_description}
        />
      )}

      <ContainerInfos>
        <div className="checkbox">
          <input
            type="checkbox"
            id={`studentTile${id}`}
            checked={isCandidatesSelected(id, selectedCandidates)}
            onChange={() =>
              toggleSelectCandidate(
                candidate,
                selectedCandidates,
                setSelectedCandidates
              )
            }
          />
        </div>
        {conditionSeeMatch ? (
          <BoxProgressBar>
            <CircularProgressBar match={match} totalWidth={150} />
          </BoxProgressBar>
        ) : null}

        <BoxInformationArea margin={liked_company}>
          {is_company_indication && (
            <div className="indicationMessage">
              <Star />
              <span>Indicado pela Workalove</span>
            </div>
          )}
          {is_indication && !is_company_indication && (
            <div className="indicationMessage">
              <Star />
              <span>Indicado pela instituição de ensino</span>
            </div>
          )}
          {liked_company && (
            <div className="liked-company">
              <IoIosHeart size={12} /> Curtiu sua empresa
            </div>
          )}
          <strong>
            {student.name}{' '}
            {is_champion && (
              <Tooltip
                direction="top"
                colorSchema="black"
                text="O estudante possui o perfil campeão"
                textColor="#ffff"
              >
                <StudentTrophy />
              </Tooltip>
            )}
          </strong>

          <p>
            <GoClock size={14} /> {application_time}
          </p>
          {is_answered &&
            returnReactionInformation(is_interesting, setViewFeedbackModal)}
          {course.map(({ course__name, semester }) => (
            <WrapperInformation>
              <div className="box-icon">
                <DigitalBookIcon size={16} />
              </div>
              <p>
                {capitalizeFirstLetterWithExceptions(course__name)} - {''}
                {semester}º semestre
              </p>
            </WrapperInformation>
          ))}
        </BoxInformationArea>
      </ContainerInfos>
      <ContainerButtons>
        <div className="align-divider">
          <Divider />
        </div>
        <div className="content">
          {returnStatusInterview(interview)}

          <div className="button-area">
            <SimpleButton
              type="curriculum"
              onClick={() =>
                controlOpenStudentCurriculum(id, university, company)
              }
            >
              Visualizar currículo
            </SimpleButton>
            {!isPartner && (
              <SimpleButton type="interview" onClick={() => controlOpenModal()}>
                {returnLabelButton()}
              </SimpleButton>
            )}
          </div>
          {returnRatingStars(score)}
        </div>
      </ContainerButtons>
    </Container>
  );
}
