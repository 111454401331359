import styled from 'styled-components';

export const Container = styled.div`
  width: ${({ noScroll }) => (noScroll ? '90%' : '100%')};
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 85%;
  height: 500px;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #ffffff;
  margin-top: 80px;
  height: 100%;
`;
