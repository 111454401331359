import { useEffect, Fragment, useState } from 'react';
import CompanyHeader from 'components/ViewsHeader/CompanyViewsHeader';
import headerImage from 'assets/backoffice/headerImage.svg';
import { WhiteBox, Wrapper } from './style';
import RegisterEmailForm from './components/Form';
import Listage from './components/List';

export default function emailManagementEngine({ handleIsLoadingState }) {
  const [usingCreateForm, setUsingCreateForm] = useState(false);

  useEffect(() => {
    handleIsLoadingState(false);
  }, []);

  const currentComponent = usingCreateForm ? (
    <RegisterEmailForm setUsingCreateForm={setUsingCreateForm} />
  ) : (
    <Listage setUsingCreateForm={setUsingCreateForm} />
  );

  return (
    <Fragment>
      <CompanyHeader title="Gestão de e-mails" backgroundImage={headerImage} />

      <Wrapper>
        <WhiteBox form={usingCreateForm}>
          <header>{currentComponent}</header>
        </WhiteBox>
      </Wrapper>
    </Fragment>
  );
}
