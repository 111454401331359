import { CompaniesManagementContext } from "../Context/CompaniesManagementContext";
import { 
  countTabs, getConnectedCompaniesFilters, getListCanceledInvite, 
  getListConnectedCompanies, getListDisconnectCompanies, 
  getListInviteAnswered, getListInviteCreated, getListInviteSent 
} from "../Services";
import {useContext} from 'react'

export const useAPICalls = () => {

  const {
    setListConnectedCompanies, 
    setCompaniesConnectCount,
    setListDisconnectCompanies,
    setCompaniesDisconnectCount,
    setInviteCreated,
    setInviteCreatedCount,
    setInviteSent,
    setInviteSentCount,
    setInviteAnswered,
    setInviteAnsweredCount,
    setCanceledInvite,
    setCanceledInviteCount,
  } = useContext(CompaniesManagementContext)

  const getConnectedCompanies = async (query) => {
    const data = await getListConnectedCompanies(query);
    const {results, count} = data;
    setListConnectedCompanies(results);
    setCompaniesConnectCount(count)
  }

  const getDisconnectCompanies = async (query) => {
    const data = await getListDisconnectCompanies(query);
    const {results, count} = data;
    setListDisconnectCompanies(results);
    setCompaniesDisconnectCount(count)
  }

  const getInviteCreated = async (query) => {
    const data = await getListInviteCreated(query);
    const {results, count} = data;
    setInviteCreated(results);
    setInviteCreatedCount(count)
  }

  const getInviteSent = async (query) => {
    const data = await getListInviteSent(query);
    const {results, count} = data;
    setInviteSent(results);
    setInviteSentCount(count)
  }

  const getInviteAnswered = async (query) => {
    const data = await getListInviteAnswered(query);
    const {results, count} = data;
    setInviteAnswered(results);
    setInviteAnsweredCount(count)
  }

  const getCanceledInvite = async (query) => {
    const data = await getListCanceledInvite(query);
    const {results, count} = data;
    setCanceledInvite(results);
    setCanceledInviteCount(count)
  }

  return { 
    getConnectedCompanies,
    getDisconnectCompanies,
    getInviteCreated,
    getInviteSent,
    getInviteAnswered,
    getCanceledInvite,
  }
 }