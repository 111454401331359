import React, { useRef, useEffect, useContext } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import {
  ContainerSelect,
  InputLabel,
  InputSubLabel,
  InputIcon,
  ContainerHeader,
  StyledReactSelect,
} from './style';

export default function CustomSearchableSelect({
  label,
  subLabel,
  icon,
  fieldName,
  options,
  placeholder,
  universityColor,
  ...rest
}) {
  const selectReference = useRef(null);
  const { registerField, defaultValue } = useField(fieldName);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectReference.current,
      path: 'state.value',
      getValue: (ref) => {
        if (rest.isMulti) {
          return ref.state.value
            ? ref.state.value.map((option) => option.value)
            : [];
        } else {
          return ref.state.value ? ref.state.value.value : '';
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  function selectTheme(theme) {
    return {
      ...theme,
      colors: {
        ...theme.colors,
        primary: universityColor,
      },
    };
  }

  return (
    <div>
      <ContainerSelect>
        <ContainerHeader>
          {icon && (
            <InputIcon universityColor={universityColor}>{icon}</InputIcon>
          )}
          {label && <InputLabel>{label}</InputLabel>}
          {subLabel && <InputSubLabel>{subLabel}</InputSubLabel>}
        </ContainerHeader>

        <StyledReactSelect
          defaultValue={
            Array.isArray(options) && options.length > 0
              ? options.find(
                  (option) => String(option.value) === String(defaultValue)
                )
              : null
          }
          placeholder={placeholder || 'Selecione'}
          options={options}
          ref={selectReference}
          classNamePrefix="react-select"
          theme={selectTheme}
          {...rest}
        />
      </ContainerSelect>
    </div>
  );
}
