import React, { useEffect } from 'react';
import { func, shape } from 'prop-types';
import { getInternshipAuthData } from 'services/internship';
import getCookieValue from 'utils/getCookieValue';

export const InternshipRedirect = ({ openSnackbar, history }) => {
  useEffect(() => {
    try {
      const fetchInternshipAuth = async () => {
        const response = await getInternshipAuthData();
        if (response.message) {
          openSnackbar(
            'Falha ao redirecionar para o módulo de estágio. Tente novamente mais tarde.',
            true
          );
        } else {
          const { url } = response;
          const token = getCookieValue('csrftoken', document.cookie);
          window.open(`${url}/login/?wlt=${token}`, '_parent');
        }
      };

      fetchInternshipAuth();
    } catch (err) {
      openSnackbar(
        'Falha ao redirecionar para o módulo de estágio. Tente novamente mais tarde.',
        true
      );
    }
  }, []);

  return <div />;
};

InternshipRedirect.propTypes = {
  openSnackbar: func.isRequired,
  history: shape.isRequired,
};
