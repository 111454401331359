import React, { useRef } from 'react';

import IconChallengeCard from 'assets/img/icons/IconChallengeCard.svg';
import BodyModalChallenges from '../components/ModalBodyChallenges';

import { func } from 'prop-types';

import {
  Fragment,
  TitleChallenges,
  CardBody,
  CardImage,
  CardTitle,
  CardParagraph,
  CardButton,
  DivCardButton,
  CardParagraphButton,
  SubtitleCard,
  ParagraphChallenges,
  TitleParagraphChallenges,
  CardContainer
} from './styles';

function PageOneChallenges(props) {
  const {
    setActiveTab,
    isOpenModal,
    setIsOpenModal,
    formData,
    setFormData,
    currentStep,
    setCurrentStep,
    cacheOptions,
    setCacheOptions,
    setFormValues,
    loadChallenges,
    setChallenges,
    setModalInfoOpen,
  } = props;

  const modalRef = useRef(null);

  const handleModal = () => {
    setIsOpenModal(true)
  }

  return (
    <Fragment>
      {isOpenModal && (
        <BodyModalChallenges
          setIsOpenModal={setIsOpenModal}
          formData={formData}
          setFormData={setFormData}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          cacheOptions={cacheOptions}
          setCacheOptions={setCacheOptions}
          setFormValues={setFormValues}
          isOpenModal={isOpenModal}
          loadChallenges={loadChallenges}
          setChallenges={setChallenges}
          setModalInfoOpen={setModalInfoOpen}
          modalRef={modalRef}
        />
      )}

      <TitleParagraphChallenges>
        <TitleChallenges>Publicar Desafios</TitleChallenges>
        <ParagraphChallenges>
          Aqui você pode desafiar os estudantes a buscar soluções para os
          problemas reais da sua empresa.
        </ParagraphChallenges>
      </TitleParagraphChallenges>

      <CardContainer>
        <CardBody>
          <CardImage src={IconChallengeCard} />

          <CardTitle>Desafios</CardTitle>

          <CardParagraph>
            Conte seu desafio para milhares de <br /> estudantes de uma só vez.
          </CardParagraph>
        </CardBody>

        <DivCardButton>
          <CardButton onClick={handleModal}>
            <CardParagraphButton>Cadastrar</CardParagraphButton>
          </CardButton>
        </DivCardButton>
      </CardContainer>

      <SubtitleCard onClick={() => setActiveTab(1)}>
        Ver conteúdos publicados
      </SubtitleCard>
    </Fragment>
  );
}
PageOneChallenges.propTypes = {
  openModalWithContent: func,
};

export default PageOneChallenges;
