import {
  BubbleDialog,
  Button,
  H4Styled,
  IconPersonStyled,
  Modal,
  ParagraphStyled,
  Wrapper,
  WrapperTitle,
  Box,
  ButtonX,
  WrapperStep,
  Error,
} from '../style';
import React, {
  Fragment,
  useMemo,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import IconPerson from 'assets/JobOffer/IconPerson.svg';

import { Form } from '@unform/web';

import CustomSearchableSelect from '../CustomSelect';
import {
  getOptionsProfessionalStatus,
  getOptionsProfile,
} from 'services/student/profile';
import { updateProfessionalStatusModal } from 'services/student/professionalStatus';

export default function ProfessionalStatusModal({
  config,
  modalOpenState,
  universityColor,
  metadata,
  professionalStatus,
  setOpenModalProfessionalStatus,
  setOpenModalSatisfaction,
  setOpenModalDissatisfaction,
  hasFirstStep,
}) {
  const formRef = useRef(null);
  const [occupationArea, setOccupationArea] = useState([]);
  const [showQuestions, setShowQuestions] = useState(true);
  const [
    hasAnsweredWorkingInFormationArea,
    setHasAnsweredWorkingInFormationArea,
  ] = useState(true);
  const [hasAnsweredOccupationArea, setHasAnsweredOccupationArea] = useState(
    true
  );
  const [optionsProfessionalStatus, setOptionsProfessionalStatus] = useState(
    []
  );
  const [isLoadingOptions, setIsLoadingOptions] = useState(false);

  const workingInFormationAreaOptions = [
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];

  const satisfactionOptions = [
    { label: 'Satisfeito', value: true },
    { label: 'Insatisfeito', value: false },
  ];

  useEffect(() => {
    async function getOptions() {
      setIsLoadingOptions(true);

      const [
        optionsOccupationArea,
        optionsProfessionalStatus,
      ] = await Promise.all([
        getOptionsProfile(),
        getOptionsProfessionalStatus(),
      ]);

      setOptionsProfessionalStatus(
        optionsProfessionalStatus.student.professional_status_options
      );
      setOccupationArea(optionsOccupationArea.occupation_area);
      setIsLoadingOptions(false);
    }

    getOptions();
  }, []);

  useEffect(() => {
    if (formRef.current) {
      if (!professionalStatus.status) {
        setShowQuestions(true);
      }
      const isWorkingInFormationArea = professionalStatus.is_working_in_formation_area
        ? workingInFormationAreaOptions[0]
        : workingInFormationAreaOptions[1];

      const professionalSatisfaction = professionalStatus.professional_satisfaction
        ? satisfactionOptions[0]
        : satisfactionOptions[1];

      formRef.current.setData({
        professional_status: professionalStatus.status,
        professional_occupation_area: professionalStatus.occupation_area,
        professional_working_in_formation_area: isWorkingInFormationArea,
        professional_satisfaction: professionalSatisfaction,
      });
      const data = formRef?.current?.getData();
      const unemployedStatus = data.professional_status;
      if (unemployedStatus === 'unemployed') {
        setShowQuestions(false);
      } else {
        setShowQuestions(true);
      }
    }
  }, [professionalStatus]);

  function handleCustomSelectChange(selectedOption) {
    if (selectedOption && selectedOption.value === 'unemployed') {
      setShowQuestions(false);
    } else {
      setShowQuestions(true);
    }
  }

  function handleCheckErrorAtuation(selectedOption) {
    if (selectedOption && selectedOption.value === '') {
      setHasAnsweredOccupationArea(false);
    } else {
      setHasAnsweredOccupationArea(true);
    }
  }

  function handleCheckErrorWorkingInFormationArea(selectedOption) {
    if (selectedOption && selectedOption.value === '') {
      setHasAnsweredWorkingInFormationArea(false);
    } else {
      setHasAnsweredWorkingInFormationArea(true);
    }
  }

  function onSubmit(data) {
    if (data.professional_status !== 'unemployed') {
      let shouldPreventSubmission = false;

      if (!data.professional_occupation_area) {
        setHasAnsweredOccupationArea(false);
        shouldPreventSubmission = true;
      }

      if (data.professional_working_in_formation_area === '') {
        setHasAnsweredWorkingInFormationArea(false);
        shouldPreventSubmission = true;
      }

      if (shouldPreventSubmission) {
        return;
      }
    }
    updateProfessionalStatusModal(data);
    setOpenModalProfessionalStatus(false);
    if (data.professional_satisfaction) {
      setOpenModalSatisfaction(true);
    } else {
      setOpenModalDissatisfaction(true);
    }
  }

  return (
    <Wrapper visible={modalOpenState}>
      <Box>
        <Modal>
          {hasFirstStep && (
            <WrapperStep>
              <ButtonX color={universityColor} />
              <ButtonX color={universityColor} />
            </WrapperStep>
          )}

          <WrapperTitle alignIcon={config?.style?.alignIcon}>
            <IconPersonStyled
              universityColor={universityColor}
              src={IconPerson}
            />
            <BubbleDialog universityColor={universityColor}>
              <H4Styled>
                Me conte mais sobre sua situação profissional:
              </H4Styled>
            </BubbleDialog>
          </WrapperTitle>

          <Form ref={formRef} style={{ width: '100%' }} onSubmit={onSubmit}>
            <CustomSearchableSelect
              fieldName="professional_status"
              options={optionsProfessionalStatus}
              label={
                'Qual opção melhor descreve sua situação profissional atual?*'
              }
              placeholder="Escolha uma opção"
              universityColor={universityColor}
              onChange={handleCustomSelectChange}
              isMulti={false}
              isLoading={isLoadingOptions}
              isDisabled={isLoadingOptions}
            />
            {showQuestions && (
              <Fragment>
                <CustomSearchableSelect
                  fieldName="professional_occupation_area"
                  options={occupationArea}
                  label={
                    'Qual atuação melhor descreve sua principal função atual:*'
                  }
                  placeholder="Escolha uma opção"
                  universityColor={universityColor}
                  isMulti={false}
                  isLoading={isLoadingOptions}
                  isDisabled={isLoadingOptions}
                  onChange={handleCheckErrorAtuation}
                  error={!hasAnsweredOccupationArea}
                />
                {!hasAnsweredOccupationArea && (
                  <Error>Informação obrigatória</Error>
                )}
                <CustomSearchableSelect
                  fieldName="professional_working_in_formation_area"
                  options={workingInFormationAreaOptions}
                  label={'Você está trabalhando na sua área de formação?*'}
                  placeholder="Escolha uma opção"
                  universityColor={universityColor}
                  isMulti={false}
                  isLoading={isLoadingOptions}
                  isDisabled={isLoadingOptions}
                  onChange={handleCheckErrorWorkingInFormationArea}
                  error={!hasAnsweredWorkingInFormationArea}
                />
                {!hasAnsweredWorkingInFormationArea && (
                  <Error>Informação obrigatória</Error>
                )}
              </Fragment>
            )}

            <CustomSearchableSelect
              fieldName="professional_satisfaction"
              options={satisfactionOptions}
              label={
                'Como você avalia a sua satisfação na atual situação profissional?*'
              }
              placeholder="Escolha uma opção"
              universityColor={universityColor}
              isMulti={false}
              isLoading={isLoadingOptions}
              isDisabled={isLoadingOptions}
            />
            <Button
              type="submit"
              universityColor={universityColor}
              disabled={isLoadingOptions}
            >
              ENVIAR INFORMAÇÕES
            </Button>
          </Form>
        </Modal>
      </Box>
    </Wrapper>
  );
}
