import React, { Fragment, useContext, useEffect, useState } from 'react';

import {
  Button,
  ButtonDelete,
  ButtonEdit,
  Container,
  ContainerButton,
  SimpleBar,
  VisualIndicator,
  WrapperInfos,
  ListButton,
  ContentWrapper,
} from './style';

import Edifice from 'assets/JobOffer/edifice.svg';
import User from 'assets/JobOffer/user.svg';
import UserIcon from 'assets/JobOffer/userIcon.svg';
import starWithBackground from 'assets/JobOffer/starWithBackground.svg';

import { JobOfferContext } from '../../context/jobOfferContext';
import { getJobDetails, getJobPartnersDetails } from '../../services';
import { JobDetailButton } from '../JobDetails';
import { IndicateStudentButton } from '../IndicationStudent';
import { jobOfferAdapterExternal } from '../../ExternalJobOffer/helpers/jobOfferAdapterExternal';
import { jobOfferAdapter } from '../steps/helpers/jobOfferAdapter';
import FinishModal from '../Modal/FinishModal';
import UnpublishModal from '../Modal/UnpublishModal';
import FeedBackButtons from '../Buttons/feedBackButtons';
import { EngineListCandidates } from 'components/ListCandidates/EngineListCandidates';
export default function JobCards(props) {
  const {
    className = null,
    jobName,
    openedSince,
    responsibleName,
    numberOfCandidates,
    jobs,
    jobID,
    JobPartners,
    isPartnerOffer,
    role,
    isPartner,
    isIndicationTab = false,
    closeIndicationModalFunc = null,
    activeTab,
    setListJobs,
    universityFilters,
    countJobs,
    setCountMyJobs,
  } = props;

  const {
    dataFunction,
    setExternalJobOffer,
    setFormValues,
    setCacheContext,
    data,
    setCompanys,
    isMaster,
  } = useContext(JobOfferContext);

  const [expandedResponsible, setExpandedResponsible] = useState(false);
  const [modalFinish, setModalFinish] = useState(false);
  const [modalUnpublish, setModalUnpublish] = useState(false);
  const [zIndex, setZIndex] = useState(1164);

  const strReduced = (str) => {
    if (str?.length > 17) {
      return `${str.substr(0, 17)}...`;
    } else {
      return str;
    }
  };

  useEffect(() => {
    if (data.isEditJob) {
      dataFunction.setLoadingModal(false);
      dataFunction.handleOpenPublishModal();
    }
  }, [data]);

  const handleClickEdit = async () => {
    dataFunction.setLoadingModal(true);

    const jobDetails = jobs.is_partner_offer
      ? await getJobPartnersDetails(jobs.id)
      : await getJobDetails(jobs.id);
    if (jobs.is_external === true) {
      setExternalJobOffer(true);
      setFormValues(await jobOfferAdapterExternal(jobDetails, setCacheContext));
    } else {
      setFormValues(
        await jobOfferAdapter(jobDetails, setCacheContext, setCompanys)
      );
    }
  };

  const handleModalFinish = () => {
    setModalFinish(true);
  };

  const handleModalUnpublish = () => {
    setModalUnpublish(true);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleModalListContent = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const returnResponsibleName = () => {
    if (jobs.is_educational_group) {
      return `Grupo educacional ${responsibleName}`;
    }
    if (role === 'university' && isPartner && !isMaster) {
      return `Empresa ${responsibleName}`;
    }
    return `Instituição ${responsibleName}`;
  };

  const returnButtonWidth = () => {
    if (isMaster) {
      return jobs.is_educational_group ? '111px' : '100%';
    }

    return jobs.is_educational_group ? '100%' : '111px';
  };

  const isReadOnly = () => {
    if (isMaster) {
      return !jobs.is_educational_group;
    }
    return !!jobs.is_educational_group;
  };

  return (
    <Container>
      {openModal && (
        <EngineListCandidates
          controlModal={openModal}
          setControlModal={setOpenModal}
          jobs={jobs}
          isPartner={isPartner}
          publishedJobs={true}
          university={true}
        />
      )}

      <FinishModal
        jobs={jobs}
        setModalFinish={setModalFinish}
        isOpen={modalFinish}
        jobID={jobID}
        isPartnerOffer={isPartnerOffer}
        activeTab={activeTab}
        setListJobs={setListJobs}
        universityFilters={universityFilters}
        countJobs={countJobs}
        setCountProgressJobs={setCountMyJobs}
        dataFunction={dataFunction}
      />
      <UnpublishModal
        jobID={jobID}
        setModalUnpublish={setModalUnpublish}
        isOpen={modalUnpublish}
      />
      <WrapperInfos>
        <ContentWrapper isTitle={true}>
          <strong> {jobName} </strong>
          <span> {openedSince} </span>
          <span> {`CÓDIGO: ${jobID}`} </span>
        </ContentWrapper>
        <ContentWrapper isIcons={true} isMaster={isMaster}>
          <VisualIndicator>
            <div className="icon">
              <img src={Edifice} alt="Icone Edifício" />
            </div>
            <p title={responsibleName}>
              {expandedResponsible || responsibleName?.length <= 12
                ? returnResponsibleName()
                : strReduced(responsibleName)}
            </p>
            {!expandedResponsible && responsibleName?.length > 12 && (
              <button
                onClick={() => {
                  setExpandedResponsible(true);
                }}
              >
                ver mais
              </button>
            )}
          </VisualIndicator>
          {isMaster && (
            <VisualIndicator>
              <div className="icon">
                <img src={UserIcon} alt="Ícone usuário" style={{ width: 30 }} />
              </div>
              <p>{jobs.responsible_user_name || 'Não informado'}</p>
            </VisualIndicator>
          )}
          <VisualIndicator>
            <div className="icon">
              <img src={User} alt="Icone Usuário" />
            </div>
            <p>
              {numberOfCandidates === 0 ? 'Nenhum estudante candidatado' : null}
              {numberOfCandidates === 1
                ? `${numberOfCandidates} estudante candidatado`
                : null}
              {numberOfCandidates > 1
                ? `${numberOfCandidates} estudantes candidatados`
                : null}
            </p>
            {numberOfCandidates ? (
              <ListButton onClick={handleModalListContent}>ver</ListButton>
            ) : null}
          </VisualIndicator>
          {jobs.to_university && (
            <VisualIndicator>
              <div className="icon">
                <img
                  src={starWithBackground}
                  alt="Ícone de estrela"
                  style={{ width: 30 }}
                />
              </div>
              <p>Vaga indicada para sua instituição </p>
            </VisualIndicator>
          )}
        </ContentWrapper>
        <ContentWrapper>
          {isIndicationTab ? (
            <>
              <ContainerButton>
                <JobDetailButton
                  jobID={jobID}
                  isFromPartners={jobs.is_partner_offer}
                  externalJobOffer={jobs.is_external}
                  buttonFunction={closeIndicationModalFunc}
                >
                  Detalhes da Vaga
                </JobDetailButton>
              </ContainerButton>
            </>
          ) : (
            <>
              <ContainerButton>
                {!isReadOnly() && !JobPartners ? (
                  <Fragment>
                    <Button onClick={handleModalFinish}>Finalizar Vaga</Button>
                    <JobDetailButton
                      jobID={jobID}
                      isFromPartners={jobs.is_partner_offer}
                      externalJobOffer={jobs.is_external}
                      fullWidth
                    >
                      Detalhes da Vaga
                    </JobDetailButton>
                  </Fragment>
                ) : (
                  <Fragment>
                    {!isReadOnly() && (
                      <FeedBackButtons jobID={jobID} jobs={jobs} />
                    )}
                    <JobDetailButton
                      jobID={jobID}
                      isFromPartners={jobs.is_partner_offer}
                      externalJobOffer={jobs.is_external}
                      style={{
                        backgroundColor: '#009291',
                        color: '#FFFF',
                        width: returnButtonWidth(),
                        height: '33px',
                      }}
                      canIndication={jobs.receives_students_indications}
                      fullWidth
                    >
                      Detalhes da Vaga
                    </JobDetailButton>
                  </Fragment>
                )}
              </ContainerButton>
              <SimpleBar hasMarginTop={isReadOnly()} />
              <ContainerButton>
                {!isReadOnly() && !JobPartners && !isIndicationTab ? (
                  <Fragment>
                    <ButtonDelete onClick={handleModalUnpublish}>
                      DESPUBLICAR VAGA
                    </ButtonDelete>
                    <ButtonEdit onClick={handleClickEdit}>EDITAR</ButtonEdit>
                  </Fragment>
                ) : (
                  <Fragment>
                    {jobs.receives_students_indications && (
                      <IndicateStudentButton jobs={jobs}>
                        INDICAR ESTUDANTE
                      </IndicateStudentButton>
                    )}
                  </Fragment>
                )}
              </ContainerButton>
            </>
          )}
        </ContentWrapper>
      </WrapperInfos>
    </Container>
  );
}
