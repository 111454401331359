import styled from 'styled-components';
import Header from './UiComponents/Header';
import GenericInternalTab from 'components/GenericInternalTab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';

export const Container = styled.div`
  padding: 15px 60px;

  @media (max-width: 671px) {
    padding: 15px 30px;
  }
`;

export const WhiteBox = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 300px;
  height: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 40px 60px;

  @media (max-width: 671px) {
    padding: 40px 30px;
  }

  > .filter-modal {
    width: 100%;
    margin: 0px auto 20px auto;
    display: flex;
    justify-content: center;
    div.filterContainer {
      z-index: ${props => props.openFilters ? '999999' : '0'};
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      button {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;

export const ContainerNavigation = styled.div`
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 40px;
  background-color: #fff;
`;

export const ContainerFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > div {
      width: 100%;
    }
  }
`;

export const ContainerMobileButtons = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CustomGenericTabs = styled(GenericInternalTab)`
  & > header {
    flex-direction: row;
    flex: 1;
    padding-right: 24px;
    @media screen and (max-width: 800px) {
      padding-right: 16px;
    }
  }
  ${AppBar} {
    header {
      flex: 1;
      z-index: 0;
    }
    .genericTabs {
      flex: 1;
      div:first-child {
        flex: 0;
      }
    }
  }
`;
