import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import AcademicModelingApi from 'services/university/academicModeling';
import AuthContext from 'contexts/authentication';

export const AcademicModelingContext = createContext({});

export function AcademicModelingContextProvider({ children }) {
  const [courses, setCourses] = useState([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [hasCoursesNextPage, setHasCoursesNextPage] = useState(false);
  const [fullScreenModalOpen, setFullScreenModalOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [checkedDisciplines, setCheckedDisciplines] = useState([]);
  const [selectedStep, setSelectedStep] = useState(1);
  const { metadata } = useContext(AuthContext);

  const isAcademicAssociationRole =
    metadata?.staff_role === 'academic_association';
  const initialMetrics = useMemo(() => {
    return {
      contemplated: 0,
      percent_contemplated: 0,
      percent_not_contemplated: 0,
      not_contemplated: 0,
    };
  }, []);
  const [marketRequirementsMetrics, setMarketRequirementsMetrics] = useState(
    initialMetrics
  );
  const [marketTendenciesMetrics, setMarketTendenciesMetrics] = useState(
    initialMetrics
  );

  const handleSelectCourse = useCallback((course) => {
    setSelectedCourse(course);
    setFullScreenModalOpen(true);
  }, []);

  async function fetchCourses() {
    const response = await AcademicModelingApi.getCourses();
    if (response.status === 200) {
      setHasCoursesNextPage(response.data.next !== null);
      setCourses(response.data.results);
    }
    setIsLoadingCourses(false);
  }

  useEffect(() => {
    if (fullScreenModalOpen === false) {
      const courseIndex = courses.findIndex(
        (course) => course.id === selectedCourse.id
      );
      const newCourses = [...courses];
      newCourses[courseIndex] = selectedCourse;
      setCourses(newCourses);
    }
  }, [fullScreenModalOpen]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const currentStep = useMemo(() => {
    if (!selectedCourse) return 1;
    const { steps, exists_association } = selectedCourse;
    const keys = Object.keys(steps);
    if (keys.every((key) => steps[key]) || exists_association) return 4;
    return keys.findIndex((key) => !steps[key]) + 1;
  }, [selectedCourse]);

  useEffect(() => {
    if (fullScreenModalOpen) {
      setSelectedStep(currentStep);
    }
  }, [fullScreenModalOpen]);

  useEffect(() => {
    if (selectedStep > currentStep) {
      setSelectedStep(currentStep);
    }
  }, [currentStep]);

  async function updateMarketRequirementsMetrics() {
    const response = await AcademicModelingApi.getMarketRequirementsMetrics(
      selectedCourse.id
    );
    if (response.status === 200) {
      setMarketRequirementsMetrics(response.data);
    }
  }

  async function updateMarketTendenciesMetrics() {
    const response = await AcademicModelingApi.getMarketTendenciesMetrics(
      selectedCourse.id
    );
    if (response.status === 200) {
      setMarketTendenciesMetrics(response.data);
    }
  }

  function updateCourse(course) {
    const newCourses = courses.map((c) => {
      if (c.id === course.id) {
        return course;
      }
      return c;
    });
    setCourses(newCourses);
  }

  const checkCurrentStep = async () => {
    const response = await AcademicModelingApi.updateCurrentStep(
      selectedCourse.id
    );
    if (response) {
      const newSelectedCourse = {
        ...selectedCourse,
        ...response,
      };
      setSelectedCourse(newSelectedCourse);
    }
  };

  return (
    <AcademicModelingContext.Provider
      value={{
        courses,
        setCourses,
        isLoadingCourses,
        setIsLoadingCourses,
        hasCoursesNextPage,
        setHasCoursesNextPage,
        fullScreenModalOpen,
        setFullScreenModalOpen,
        selectedCourse,
        setSelectedCourse,
        handleSelectCourse,
        checkedDisciplines,
        setCheckedDisciplines,
        currentStep,
        selectedStep,
        setSelectedStep,
        isAcademicAssociationRole,
        updateMarketRequirementsMetrics,
        updateMarketTendenciesMetrics,
        marketRequirementsMetrics,
        marketTendenciesMetrics,
        updateCourse,
        checkCurrentStep,
      }}
    >
      {children}
    </AcademicModelingContext.Provider>
  );
}

export function useAcademicModeling() {
  return useContext(AcademicModelingContext);
}
