import React, { forwardRef, Fragment } from 'react';
import { arrayOf, bool, string } from 'prop-types';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';

const ListTargetAudienceItem = forwardRef(
  ({ target, title, isItemlabel, showMore }, ref) => {
    return (
      <div>
        <p>
          <LabelImportantIcon />
        </p>
        <p>{title}</p>
        {title === 'Cursos:' ? (
          <p
            style={
              showMore
                ? {
                    maxHeight: '10000px',
                    transition: 'max-height 1s ease',
                  }
                : {
                    maxHeight: '150px',
                    transition: 'max-height 1s ease',
                  }
            }
            ref={ref}
          >
            {target.map((item) => (
              <Fragment>
                {isItemlabel ? item.label : `${item}º`}
                <p style={{ display: 'inline-block', margin: 0 }} />
              </Fragment>
            ))}
          </p>
        ) : (
          <p style={{ height: 'fit-content' }}>
            {target.map((item) => (
              <Fragment>
                {isItemlabel ? item.label : `${item}º`}
                <p style={{ display: 'inline-block', margin: 0 }} />
              </Fragment>
            ))}
          </p>
        )}
      </div>
    );
  }
);

ListTargetAudienceItem.defaultProps = {
  isItemlabel: false,
  showMore: false,
};

ListTargetAudienceItem.propTypes = {
  target: arrayOf(string).isRequired,
  title: string.isRequired,
  isItemlabel: bool,
  showMore: bool,
};

export default ListTargetAudienceItem;
