import useDefineColor from "constants/colors";
import BaseLayoutContext from "contexts/base-layout";
import React, {useContext} from "react";

const Nav = props => {
  const { classes } = props;

  const {universityColor} = useContext(BaseLayoutContext)

  const dots = [];
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i;
    dots.push(
      <span
        key={`step-${i}`}
        className={`${classes.dot} ${isActive ? classes[`${useDefineColor(universityColor)}stepActive`] : "" }`}
        onClick={() => props.goToStep(i)}
      >
        {i}
      </span>
    );
  }

  return <div className={classes.paginationBulletsHolder}>{dots}</div>;
};

export default Nav;
