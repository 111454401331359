import {
  yellowIESCardHeader,
  redIESCardHeader,
  greenIESCardHeader,
  darkBlueIESCardHeader,
  lightBlueIESCardHeader,
  blueIESCardHeader,
  dnaColorCardHeader,
  orangeIESCardHeader,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const cardIconStyle = (theme) => ({
  cardIcon: {
    '&$yellowIESCardHeader, &$redIESCardHeader, &$greenIESCardHeader, &$darkBlueIESCardHeader, &$lightBlueIESCardHeader, &$blueIESCardHeader, &$dnaColorCardHeader, &$primaryCardHeader, &$orangeIESCardHeader':
      {
        [theme.breakpoints.down('sm')]: {
          padding: '4px',
        },
        borderRadius: '5px',
        backgroundColor: '#999',
        padding: '15px',
        marginRight: '15px',
        justifyContent: 'center',
      },
  },
  yellowIESCardHeader,
  redIESCardHeader,
  greenIESCardHeader,
  darkBlueIESCardHeader,
  lightBlueIESCardHeader,
  orangeIESCardHeader,
  blueIESCardHeader,
  dnaColorCardHeader,
});

export default cardIconStyle;
