import { useEffect } from 'react';
import { ContainerDescriptions, Descriptions } from '../styled';
import ReactHtmlParser from 'react-html-parser';
import GenericMessage from 'components/GenericMessage';

export default function About({ data, calculateMargin }) {
  const { about_us, vision, values, mission, video_url } = data;

  const youTubeIdFromLink = (url) =>
    url.match(
      /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/
    )[1];

  useEffect(() => {
    setTimeout(() => {
      calculateMargin();
    }, 100);
  }, []);

  if (!about_us && !vision && !values && !mission && !video_url) {
    return (
      <GenericMessage
        title="Nenhuma informação encontrada"
        subtitle="A instituição de ensino ainda não realizou o preenchimento desta informação."
      />
    );
  }

  return (
    <ContainerDescriptions>
      {video_url && (
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${youTubeIdFromLink(video_url)}`}
        ></iframe>
      )}

      {about_us && (
        <Descriptions>
          <h2>Sobre nós</h2>
          {ReactHtmlParser(about_us)}
        </Descriptions>
      )}

      {mission && (
        <Descriptions>
          <h2>Missão</h2>
          {ReactHtmlParser(mission)}
        </Descriptions>
      )}
      {vision && (
        <Descriptions>
          <h2>Visão</h2>
          {ReactHtmlParser(vision)}
        </Descriptions>
      )}
      {values && (
        <Descriptions>
          <h2>Valores</h2>
          {ReactHtmlParser(values)}
        </Descriptions>
      )}
    </ContainerDescriptions>
  );
}
