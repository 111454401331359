import React, { useContext } from 'react';
import BaseLayoutContext from 'contexts/base-layout';
import { Button } from './components/Buttons/buttonJobDetail';
import JobDetail from './components/JobDetailModal';
import mergeContextsHelper from '../../../../../utils/mergeContextsHelper';
import { JobDetailsApplications } from 'views/JobOffers/components/JobDetails/jobDetailsApplications';

export const JobDetailButton = (props) => {
  const {
    children,
    jobID,
    isFromPartners,
    externalJobOffer,
    buttonFunction = null,
    closeFunction = () => {},
    canIndication = false,
    fullWidth = false,
    buttonRef = null,
    isUniversityScope = true,
    isCompanyScope = false,
    ...rest
  } = props;

  const { openModalWithContent, closeModal } = useContext(BaseLayoutContext);
  const handleClickList = (e) => {
    e.preventDefault();
    buttonFunction && buttonFunction();
    openModalWithContent(
      mergeContextsHelper(
        <JobDetailsApplications
          jobID={jobID}
          jobId={jobID}
          externalJobOffer={externalJobOffer}
          isFromPartners={isFromPartners}
          isUniversityScope={isUniversityScope}
          handleCloseModal={closeModal}
          canIndication={canIndication}
          fullWidth={fullWidth}
          isCompanyScope={isCompanyScope}
        />
      ),
      null,
      null,
      closeFunction
    );
  };

  return (
    <Button {...rest} onClick={handleClickList} ref={buttonRef}>
      {children}
    </Button>
  );
};
