import React, { useContext, useState, useEffect } from 'react';
import { Container, ContainerHeader, ContainerBody, Divider } from './style';
import StudentItem from '../StudentItem';
import StudentItemSkeleton from '../StudentItem/components/StudentItemSkeleton';
import { IndicationStudentContext } from '../../context/indicationStudentContext';
import GenericMessage from 'components/GenericMessage';
import Button from 'components/CustomButtons/Button';

export default function StepTwo() {
  const {
    job,
    students,
    showSkeletonItens,
    baseModalInstance,
    loadNext,
    nextUrl,
  } = useContext(IndicationStudentContext);

  return (
    <Container>
      <ContainerHeader>
        <span>CÓDIGO DA VAGA: {job.id}</span>
        <h3>{job.name}</h3>
      </ContainerHeader>
      <ContainerBody>
        {showSkeletonItens ? (
          <StudentItemSkeleton />
        ) : students.length ? (
          <>
            {students.map((student, index) =>
              index === students.length - 1 ? (
                <StudentItem student={student} />
              ) : (
                <>
                  <StudentItem student={student} />
                  <Divider />
                </>
              )
            )}

            {!!nextUrl && (
              <div className="seeMoreRow">
                <Button
                  style={{
                    width: '112px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  onClick={loadNext}
                >
                  Ver mais
                </Button>
              </div>
            )}
          </>
        ) : (
          <GenericMessage
            title={'Nenhum estudante encontrado'}
            subtitle={'Nenhum estudante foi encontrado com esses filtros.'}
          />
        )}
      </ContainerBody>
    </Container>
  );
}
